export class EstimateTariffDiscount {
    public id: string;
    public type: string;
    public value: number;
    public total_discount: number;
    public total_discount_percentage:number;

    constructor() {
        this.id = null;
        this.type = null;
        this.value = null;
        this.total_discount = 0;
        this.total_discount_percentage = 0;
    }
}
