import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TariffAdditionalConcept } from 'src/app/entities/workspace/tariff-additional-concept';



@Injectable({
    providedIn: 'root'
})
export class TariffAdditionalConceptsService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<TariffAdditionalConcept>> {
        return this.http.get<Array<TariffAdditionalConcept>>(environment.api.workspace + '/tariff-additional-concepts').toPromise();
    }

    getById(id: string): Promise<TariffAdditionalConcept> {
        return this.http.get<TariffAdditionalConcept>(environment.api.workspace + '/tariff-additional-concepts/' + id).toPromise();
    }

    save(dataForm: TariffAdditionalConcept) {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: TariffAdditionalConcept) {
        return this.http.post(environment.api.workspace + '/tariff-additional-concepts', dataForm).toPromise();
    }

    edit(dataForm: TariffAdditionalConcept) {
        return this.http.put(environment.api.workspace + '/tariff-additional-concepts/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/tariff-additional-concepts/' + id).toPromise();
    }

    behavior(type: string, serviceType:string) {
        let service = '';
        switch (serviceType) {
            case 'LOCAL': {
                service = 'display_on_local_distance';
                break;
            }
            case 'LONG': {
                service = 'display_on_long_distance';
                break;
            }
            case 'AUTO_TRANSPORT': {
                service = 'display_on_auto_transport';
                break;
            }
            case 'FREIGHT': {
                service = 'display_on_freight';
                break;
            }
        }
        const params: any = {
            "service_type":service
        };
        if (type === 'DISCOUNT') {
            return this.http.get<Array<TariffAdditionalConcept>>(environment.api.workspace + '/tariff-additional-concepts-discounts', { params: params }).toPromise();
        }
        if (type === 'CHARGE') {
            return this.http.get<Array<TariffAdditionalConcept>>(environment.api.workspace + '/tariff-additional-concepts-charges', { params: params }).toPromise();
        }
    }
}
