import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-jobs-completed',
  templateUrl: './jobs-completed.component.html',
  styleUrls: ['./jobs-completed.component.scss']
})
export class JobsCompletedComponent implements OnInit {
    jobsCompletedInTheLast7Days: any;
   
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) { 
    this.jobsCompletedInTheLast7Days = [];

  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadJobsCompletedInTheLast7DaysCompany();
    } else {
        this.loadJobsCompletedInTheLast7DaysWorkspace();
    }
  }

  
  loadJobsCompletedInTheLast7DaysWorkspace(){
        
    this.dashboardWorkspaceService
        .getJobsCompletedInTheLast7Days()
        .then((response) => {
            this.jobsCompletedInTheLast7Days = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadJobsCompletedInTheLast7DaysCompany(){
        
    this.dashboardComopanyService
        .getJobsCompletedInTheLast7Days()
        .then((response) => {
            this.jobsCompletedInTheLast7Days = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


}
