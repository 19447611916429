import { WorkDepartment } from './work-department';
import { Customer } from './customer';
import { Estimate } from './estimate';
import { Employee } from './employee';
import { Advertiser } from './advertiser';
import { LeadCompany } from './lead-company';
import { EstimateNote } from './estimate-note';


export class EstimateView extends Estimate {
    public advertiser: Advertiser;
    public customer: Customer;
    public employee: Employee;
    public contacts: Array<Customer>;
    public work_department: WorkDepartment;
    public number_vehicles: number;
    public lead_company: LeadCompany;
    public notes: EstimateNote[];
    constructor() {
        super();
        this.advertiser = new Advertiser();
        this.customer = new Customer();
        this.employee = new Employee();
        this.contacts = new Array<Customer>();
        this.work_department = new WorkDepartment();
        this.number_vehicles = null;
        this.lead_company = null;
        this.notes = [];
    }
}
