import { Plan } from "./plan";
export class Workspace {

    public id: string;
    public name: string;
    public status: 'ACTIVE' | 'OVERDUE' | 'BLOCKED' | 'CANCELED';
    public max_accounts: number;
    public max_companies: number;
    public plan: Plan;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.status = null;
        this.max_accounts = null;
        this.max_companies = null;
        this.plan = null;
        this.created = null;
        this.updated = null;
    }
}
