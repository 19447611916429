import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { SalesmanPerformView } from 'src/app/entities/workspace/salesman-perform-view';
import { AuthService } from 'src/app/services/auth.service';
import { SalesmanPerformsService } from 'src/app/services/workspaces/salesman-performs.service';
import { HelperService } from 'src/app/services/helper.service';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';

declare const jQuery;
declare const moment;
@Component({
  selector: 'app-company-sales-performence',
  templateUrl: './company-sales-performence.component.html',
  styleUrls: ['./company-sales-performence.component.scss']
})
export class CompanySalesPerformenceComponent implements OnInit {

    @ViewChild('estimatesModal') estimatesModal: ElementRef;
    @ViewChild('modal') modal: ElementRef;
    titleModal: string;
    public betweenDates: BetweenDates;
    public salesmanPerforms: Array<SalesmanPerformView>;
    public salesmanPerformsCustom: Array<any>;
    public estimates: Array<Estimate>;
    typeService: string;
    totalQuotes: number;
    totalEmailsSent: number;
    totalQuotesSent: number;
    totalQuotesBooked: number;
    totalAmountQuotesBooked: number;
    totalCustomerPayments: number;

    public totalLeads: number;
  
    constructor(
      private helperService: HelperService,
      private salesmanPerformsService: SalesmanPerformsService,
      public authService: AuthService,
      private companiesService: CompaniesService
    ) {
      this.betweenDates = new BetweenDates();
      this.salesmanPerforms = [];
      this.salesmanPerformsCustom = [];
      this.estimates = [];
      this.titleModal = '';
      this.typeService = 'MOVING';
      this.totalQuotes = 0;
      this.totalEmailsSent = 0;
      this.totalQuotesSent = 0;
      this.totalQuotesBooked = 0;
      this.totalAmountQuotesBooked = 0;
      this.totalCustomerPayments = 0;
      this.totalLeads = 0;
    }
  
    ngOnInit(): void {
    }
  
    ngAfterViewInit() {
        this.search();
    }
    
    /**
     * recibe y asigna fechas para filtro
     *
     * @param date Filtro de fechas a buscar
     */
    getFilterDates(date: BetweenDates): void {
      this.betweenDates = date;
    }
  
    /**
     * Busca los datos por el filtro de fecha
     */
    search(): void {
  
      if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
        this.betweenDates.dateStart = 1609459200;
        this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
      }
  
      // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
      this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
      this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;
  
      const data: any = {
        start: this.betweenDates.dateStart,
        end: this.betweenDates.dateEnd,
        type_service: this.typeService
      };
  
      this.helperService.showLoadingMxpro360();
      this.salesmanPerformsService
        .getSalesPerforms(data)
        .then((response) => {
          this.clearVar();
          this.salesmanPerforms = response;
          for (const totals of this.salesmanPerforms) {
            this.totalLeads = this.totalLeads + Number(totals.total_leads);
            this.totalQuotes = this.totalQuotes + Number(totals.total_quotes);
            this.totalEmailsSent = this.totalEmailsSent + Number(totals.emails_sent);
            this.totalQuotesSent = this.totalQuotesSent + Number(totals.quotes_sent);
            this.totalQuotesBooked = this.totalQuotesBooked + Number(totals.quotes_booked);
            this.totalAmountQuotesBooked = this.totalAmountQuotesBooked + Number(totals.amount_quotes_booked);
            this.totalCustomerPayments = this.totalCustomerPayments + Number(totals.customer_payments);
          }
  
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }
  
    clearVar(){
      this.totalLeads = 0;
      this.totalQuotes = 0;
      this.totalEmailsSent = 0;
      this.totalQuotesSent = 0;
      this.totalQuotesBooked = 0;
      this.totalAmountQuotesBooked = 0;
      this.totalCustomerPayments = 0;
    }
  
    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimate) {
      // Redirige a la ventana de estmate
      let estimateId: string;
      if (estimate.hasOwnProperty('id')) {
        estimateId = estimate.id;
      }
      if (estimate.hasOwnProperty('_id')) {
        estimateId = estimate._id.$oid;
      }
      this.helperService.showLoadingMxpro360();
      this.companiesService.getCompanyByJobId(estimateId)
      .then((company) => {
  
        this.authService.setCompanySession(company);
        this.helperService.hideLoadingMxpro360();
        this.helperService.openNewTab(estimateId, 'estimate');
      });
    }
    

    // Redirige a la ventana de estmate
  
    openModalEstimates(estimates: Array<Estimate>): void {
      this.estimates = estimates;
      setTimeout(() => {
        jQuery(this.estimatesModal.nativeElement).modal('show');
      }, 10);
    }
  
    closeModalEstimates(): void {
      jQuery(this.estimatesModal.nativeElement).modal('hide');
    }
  
    getTotalQuotes(id,totals){
      this.titleModal = 'TOTAL QUOTES';
      if(totals){
          this.filterSearchTotals('TOTAL_QUOTES');
      } else {
          this.filterSearch('TOTAL_QUOTES', id);
      };
      jQuery(this.modal.nativeElement).modal('show');
        
    }
  
    getEmailSent(id,totals){
      this.titleModal = 'EMAILS SENT';
      if(totals){
          this.filterSearchTotals('EMAILS_SENT');
      } else {
          this.filterSearch('EMAILS_SENT', id);
      }
      jQuery(this.modal.nativeElement).modal('show');
  
    }
  
    getQuotesSent(id,totals){
      this.titleModal = 'QUOTES SENT';
      if(totals){
          this.filterSearchTotals('QUOTES_SENT');
      } else {
          this.filterSearch('QUOTES_SENT', id);
      }
      jQuery(this.modal.nativeElement).modal('show');
  
    }
  
    getQuotesBooked(id,totals){
      this.titleModal = 'QUOTES BOOKED';
      if(totals){
          this.filterSearchTotals('QUOTES_BOOKED');
      } else {
          this.filterSearch('QUOTES_BOOKED', id);
      }
      
      jQuery(this.modal.nativeElement).modal('show');
  
    }
  
    getCustomerPayments(id, totals) {
        this.titleModal = 'CUSTOMER PAYMENTS';
        if (totals) {
          this.filterSearchTotals('CUSTOMER_PAYMENTS');
        } else {
          this.filterSearch('CUSTOMER_PAYMENTS', id);
        }
    
        jQuery(this.modal.nativeElement).modal('show');
    
      }
    
    
    /**
     * Busca los datos por el filtro de fecha
     */
    filterSearch(type, id): void {
      
      this.salesmanPerformsCustom = [];
      const data: any = {
        start: this.betweenDates.dateStart,
        end: this.betweenDates.dateEnd,
        type_service: this.typeService
      };
  
      this.helperService.showLoadingMxpro360();
      this.salesmanPerformsService
        .getSalesPerformsCustom(data, type, id)
        .then((response) => {
  
          this.salesmanPerformsCustom = response;
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }
  
    
    /**
     * Busca los datos por el filtro de fecha
     */
    filterSearchTotals(type): void {
      this.salesmanPerformsCustom = [];
      const data: any = {
        start: this.betweenDates.dateStart,
        end: this.betweenDates.dateEnd,
        type_service: this.typeService
      };
  
      this.helperService.showLoadingMxpro360();
      this.salesmanPerformsService
        .getSalesPerformsTotals(data, type)
        .then((response) => {
  
          this.salesmanPerformsCustom = response;
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }
  
  }
  