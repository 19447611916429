import { EstimateFollowUp } from 'src/app/entities/workspace/estimate-follow-up';
import { EstimateFollowUpView } from 'src/app/entities/workspace/estimate-follow-up-view';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FollowUpReminderCalendar } from '../../entities/workspace/follow-up-reminder-calendar';

@Injectable({
  providedIn: 'root'
})
export class FollowUpsRemindersService {

  constructor(
    private http: HttpClient
  ) { }

  saveChanges(id: string, data: EstimateFollowUp): Promise<any> {
    if (data.id === null) {
      return this.createdFollowUp(data);
    } else {
      return this.editFollowUp(id, data);
    }
  }

  createdFollowUp(data: EstimateFollowUp) {
    return this.http.post(environment.api.company + '/follow-up-reminders', data).toPromise();
  }

  loadFollowUp(data): Promise<Array<FollowUpReminderCalendar>> {
    return this.http.get<Array<FollowUpReminderCalendar>>(environment.api.company + '/follow-up-reminders',
      { params: data }).toPromise();
  }

  loadCalendarFollowUp(data): Promise<Array<FollowUpReminderCalendar>> {
    return this.http.get<Array<FollowUpReminderCalendar>>(environment.api.company + '/follow-up-reminders',
      { params: data }).toPromise();
  }

  loadFollowUpByEstimateId(estimateId: string): Promise<Array<EstimateFollowUpView>> {
    return this.http.get<Array<EstimateFollowUpView>>(environment.api.company + '/follow-up-reminders/' +
      estimateId + '/estimate').toPromise();
  }

  editFollowUp(id: string, data: EstimateFollowUp): Promise<EstimateFollowUp> {
    return this.http.put<EstimateFollowUp>(environment.api.company + '/follow-up-reminders/' + id, data).toPromise();
  }

  deleteFollowUp(id: string) {
    return this.http.delete(environment.api.company + '/follow-up-reminders/' + id).toPromise();
  }
}
