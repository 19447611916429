import { CommissionCondition } from "src/app/entities/workspace/commission-condition";

type CommissionFilterValues = 'ADDITIONAL_BOL' | 'ADDITIONAL_STOP_FEE' | 'BINDING_FEE' | 'DISCOUNT' | 'DISCOUNT_FULL_PACKING_SERVICE' | 'DISCOUNT_PER_MILE' | 'ELEVATOR_DELIVERY' | 'ELEVATOR_PICKUP' | 'FULL_PACKING_SERVICE' | 'JOB_DEPOSIT' | 'JOB_TOTAL' | 'JOB_SUB_TOTAL' | 'LONG_CARRY_DELIVERY' | 'LONG_CARRY_PICKUP' | 'SHUTTLE_DELIVERY' | 'SHUTTLE_PICKUP' | 'SPLIT_DELIVERY' | 'SPLIT_PICKUP' | 'TOTAL_BOL' | 'TOTAL_BOL_AFTER_DISCOUNTS';

export class CommissionSetting {
    id: string;
    employee_id: string;
    type: 'PERCENTAGE' | 'FIXED';
    value: number;
    value_base_on: CommissionFilterValues;
    sold_by: string;
    sold_by_employee_id: string;
    sold_by_work_department_id: string;
    condition: CommissionCondition;
    company_id: string;
    payment_threshold: number;
    filter_for: CommissionFilterValues;
    job_type: 'LONG_DISTANCE' | 'LOCAL_DISTANCE' |'AUTO_TRANSPORT' |'FREIGHT'; 
    trigger_type: 'SALES' |  'QA' | 'FOREMAN';
    name: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.employee_id = null;
        this.type = null;
        this.value = null;
        this.value_base_on = null;
        this.sold_by = null;
        this.sold_by_employee_id = null;
        this.sold_by_work_department_id = null;
        this.condition = new CommissionCondition();
        this.company_id = null;
        this.payment_threshold = null;
        this.filter_for = null;
        this.job_type = null;
        this.trigger_type = 'SALES';
        this.name = null;
        this.created = null;
        this.updated = null;
    }
}
