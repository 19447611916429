import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CampaignFilter } from '../../../entities/workspace/campaign-filter';
import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from '../../../constants-messages';
import { EmailCampaign } from '../../../entities/workspace/email-campaign';
import { EmailCampaignsService } from '../../../services/companies/email-campaigns.service';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { HelperService } from '../../../services/helper.service';


declare const jQuery;

@Component({
  selector: 'app-email-campaigns-form',
  templateUrl: './email-campaigns-form.component.html',
  styleUrls: ['./email-campaigns-form.component.scss']
})
export class EmailCampaignsFormComponent implements OnInit {

  public agents: Employee[];
  public campaignFilter: CampaignFilter;
  public emailCampaign: EmailCampaign;
  public employeesEmailsList: any[];

  constructor(
    public authService: AuthService, 
    private currentRoute: ActivatedRoute,
    private emailCampaignsService: EmailCampaignsService,
    private employeesService: EmployeesCompanyService,
    private helperService: HelperService
  ) {
    this.agents = [];
    this.campaignFilter = new CampaignFilter();
    this.emailCampaign = new EmailCampaign();
  }

  ngOnInit(): void {
    this.employeesService.getAll()
    .then((agents) => {
      this.agents = agents;
    });
    this.employeesService.getEmployeesEmailsCompanyList()
    .then((emailsList) => {
      this.employeesEmailsList = emailsList;
      console.log(emailsList);
    });

  }

  ngAfterViewInit(): void{
    this.currentRoute.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
          this.loadCampaign(params.id);
        }
    });
  }

  private loadCampaign(idCampaign: string): void {
    this.helperService.showLoadingMxpro360();
    this.emailCampaignsService
      .getCampaign(idCampaign)
      .then((response) => {
        this.emailCampaign = response;
        this.campaignFilter = this.emailCampaign.campaign_filter;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  changeDateStart(event) {
    this.campaignFilter.range_start = event;
  }

  changeDateEnd(event) {
    this.campaignFilter.range_end = event;
  }

  public saveCampaign(): void {

    const data = {
      campaign_filter: this.campaignFilter,
      email_campaign: this.emailCampaign
    };

    if (this.emailCampaign.id) {

      this.helperService.showLoadingMxpro360();
      this.emailCampaignsService
        .updateCampaign(data)
        .then(() => {
          this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
          this.helperService.goToCompanyRouterLink('/email-campaigns');
        })
        .catch((error) => {
          this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
          console.error('Error: ', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });

    } else {

      this.helperService.showLoadingMxpro360();
      this.emailCampaignsService
        .saveCampaign(data)
        .then(() => {
          this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
          this.helperService.goToCompanyRouterLink('/email-campaigns');
        })
        .catch((error) => {
          this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
          console.error('Error: ', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });

    }
  }

}
