import { Component, OnInit } from '@angular/core';
import { StorageInvoice } from 'src/app/entities/workspace/storage-invoice';
import { AuthService } from 'src/app/services/auth.service';
import { StorageInvoicesService } from 'src/app/services/companies/storage-invoices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-storage-invoices-workdpace',
  templateUrl: './storage-invoices-workdpace.component.html',
  styleUrls: ['./storage-invoices-workdpace.component.scss']
})
export class StorageInvoicesWorkdpaceComponent implements OnInit {

  public actualPage: number;
  public filterSearch: string
  public storageInvoices: StorageInvoice[];
  constructor(
    private authService: AuthService,
    private storageInvoicesService: StorageInvoicesService
  ) {
    this.actualPage = 1;
    this.filterSearch = '';
    this.storageInvoices = [];
  }

  ngOnInit(): void {
    this.storageInvoicesService.getAll()
    .then((response) => {
      this.storageInvoices = response;
    })
  }

  exportPdf(storageInvoiceId: string, jobId: string) {
    window.open(environment.api.companies + this.authService.workspaceSession.company.id
      + '/estimates/' + jobId + '/export-storage-invoice/' + storageInvoiceId + '?Authorization=' +
      this.authService.session.token + '&workspace_id=' +  this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
  }

}
