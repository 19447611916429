import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeCompanyPermission } from 'src/app/entities/workspace/employee-company-permission';

@Injectable({
  providedIn: 'root'
})
export class EmployeesCompanyService {

  constructor(private http: HttpClient) {

  }

  getAll(): Promise<Array<EmployeeView>> {
    return this.http.get<Array<EmployeeView>>(environment.api.company + '/employees?sales_employees').toPromise();
  }

  getAllEmployeeAccount(): Promise<Array<any>> {
    return this.http.get<Array<any>>(environment.api.company + '/employees-company').toPromise();
  }

  getAllCompanies(id): Promise<Array<EmployeeView>> {
    return this.http.get<Array<EmployeeView>>(environment.api.workspace + '/employees-acount/'+id).toPromise();
  }

  getAllSellers():  Promise<Array<EmployeeView>> {
    return this.http.get<Array<EmployeeView>>(environment.api.company + '/employees?sales_employees=1').toPromise();
  }

  getAllForLeadAssignment(): Promise<Array<EmployeeView>> {
    return this.http.get<Array<EmployeeView>>(environment.api.company + '/employees-leads').toPromise();
  }

  getCompanyPermisions(id): Promise<EmployeeCompanyPermission> {
    return this.http.get<EmployeeCompanyPermission>(environment.api.company + '/employees/' + id + '/company-permisions').toPromise();
  }

  getEmployeesEmailsCompanyList(): Promise<Array<any>> {
    return this.http.get<Array<any>>(environment.api.company + '/employees-emails-list').toPromise();
  }

  togleStatus(id: string) {
    return this.http.patch(environment.api.company + '/employees/' + id + '/allow_access', {}).toPromise();
}
}
