import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SaleCommissionView } from "src/app/entities/workspace/sale-commission-view";
import { Paginator } from 'src/app/entities/helpers/paginator';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {


    constructor(private http: HttpClient) {

    }

    getAll(paginator: Paginator): Promise<Paginator> {
        const params: any = {
            "full-view": "1",
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start": paginator.paginator.start,
            "end": paginator.paginator.end,
            "order_by": "created",
            "order_type": "DESC"
        };
        return this.http.get<Paginator>(environment.api.company + '/sales-commissions', { params: params }).toPromise();
    }

    getAllParam(data, paginator): Promise<Paginator> {
        data.use_paginator = 1;
        data.page = paginator.paginator.page;
        data.per_page = paginator.paginator.per_page;
        data.start = paginator.paginator.start;
        data.end = paginator.paginator.end;
        data.order_by = "created";
        data.order_type = "DESC";
        return this.http.get<Paginator>(environment.api.company + '/sales-commissions', { params: data }).toPromise();
    }

    getById(id: string) {
        return this.http.get<SaleCommissionView>(environment.api.company + '/sales-commissions/' + id).toPromise();
    }

    save(dataForm: SaleCommissionView): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: SaleCommissionView) {
        return this.http.post(environment.api.app + '/sales-commissions', dataForm).toPromise();
    }

    edit(dataForm: SaleCommissionView) {
        return this.http.put(environment.api.app + '/sales-commissions/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.app + '/sales-commissions/' + id).toPromise();
    }
}
