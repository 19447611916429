import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CampaignFilter } from '../../../entities/workspace/campaign-filter';
import { SmsCampaign } from '../../../entities/workspace/sms-campaign';
import { SmsCampaignsService } from '../../../services/companies/sms-campaigns.service';

declare const jQuery;
@Component({
    selector: 'app-sms-campaigns-form',
    templateUrl: './sms-campaigns-form.component.html',
    styleUrls: ['./sms-campaigns-form.component.scss']
})
export class SmsCampaignsFormComponent implements OnInit {

    public smsCampaing: SmsCampaign;

    public campaignFilter: CampaignFilter;
    constructor(
        private smsCampaignsService: SmsCampaignsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private router: Router
    ) {
        this.smsCampaing = new SmsCampaign();
        this.campaignFilter = new CampaignFilter();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadCampaign(params.id);
            }
        });
    }


    private loadCampaign(idCampaign: string): void {
        this.helperService.showLoadingMxpro360();
        this.smsCampaignsService
            .getCampaign(idCampaign)
            .then((response) => {
                this.smsCampaing = response;
                this.campaignFilter = this.smsCampaing.campaign_filter;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeDateStart(event) {
        this.campaignFilter.range_start = event;
    }

    changeDateEnd(event) {
        this.campaignFilter.range_end = event;
    }

    public saveCampaign(): void {

        const data = {
            campaign_filter: this.campaignFilter,
            sms_campaign: this.smsCampaing
        };

        if (this.smsCampaing.id) {

            this.helperService.showLoadingMxpro360();
            this.smsCampaignsService
                .updateCampaign(data)
                .then(() => {
                    this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
                    this.helperService.goToCompanyRouterLink('/sms-campaigns');
                })
                .catch((error) => {
                    this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                    console.error('Error: ', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });

        } else {

            this.helperService.showLoadingMxpro360();
            this.smsCampaignsService
                .saveCampaign(data)
                .then(() => {
                    this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
                    this.helperService.goToCompanyRouterLink('/sms-campaigns');
                })
                .catch((error) => {
                    this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                    console.error('Error: ', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });

        }
    }


}
