import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from 'src/app/entities/global/address';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierCoverage } from 'src/app/entities/workspace/carrier-coverage';
import { CarrierCoverageView } from 'src/app/entities/workspace/carrier-coverage-view';
import { CarrierReportView } from 'src/app/entities/workspace/carrier-report-view';
import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { JobOutsourcingView } from 'src/app/entities/workspace/job-outsourcing-view';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarriersService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<Carrier>> {
        return this.http.get<Array<Carrier>>(environment.api.workspace + '/carriers').toPromise();
    }

    getReportCarriers(data): Promise<Array<CarrierReportView>> {
        return this.http.get<Array<CarrierReportView>>(environment.api.workspace + '/report-carriers', { params: data }).toPromise();
    }

    getById(id: string) {
        return this.http.get<Carrier>(environment.api.workspace + '/carriers/' + id).toPromise();
    }

    getCarrierCoverages(id: string) {
        return this.http.get<Array<CarrierCoverage>>(environment.api.workspace + '/carriers/' + id + '/coverages').toPromise();
    }

    saveCarrierCoverage(id: string, carrierCoverage: CarrierCoverage) {
        if (carrierCoverage.id === null) {
            return this.saveNewCarrierCoverage(id, carrierCoverage);
        } else {
            return this.editCarrierCoverage(id, carrierCoverage);
        }
    }

    saveNewCarrierCoverage(id: string, carrierCoverage: CarrierCoverage) {
        return this.http.post<Array<CarrierCoverage>>(environment.api.workspace + '/carriers/' + id + '/coverages', carrierCoverage).toPromise();
    }

    getCarrierCoveragesMap(data) {
        return this.http.get<Array<CarrierCoverageView>>(environment.api.workspace + '/carriers-coverages', { params: data }).toPromise();
    }

    getPoligonsStates() {
        let urlBase = environment.app.base;
        // si estamos en localhost se debe consultar el archivo local
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            urlBase = environment.local.base;
        }
        return this.http.get(urlBase + '/assets/json/states.json').toPromise();
    }

    deleteCarrierCoverage(id: string, carrierCoverageId: string) {
        return this.http.delete<Array<CarrierCoverage>>(environment.api.workspace + '/carriers/' + id + '/coverages/' + carrierCoverageId).toPromise();
    }

    editCarrierCoverage(id: string, carrierCoverage: CarrierCoverage) {
        return this.http.put(environment.api.workspace + '/carriers/' + id + '/coverages/' + carrierCoverage.id, carrierCoverage).toPromise();
    }

    save(dataForm: Carrier): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Carrier) {
        return this.http.post(environment.api.workspace + '/carriers', dataForm).toPromise();
    }

    edit(dataForm: Carrier) {
        return this.http.put(environment.api.workspace + '/carriers/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/carriers/' + id).toPromise();
    }

    getAllJob(id: string): Promise<Array<JobOutsourcingView>> {
        return this.http.get<Array<JobOutsourcingView>>(environment.api.workspace + '/outsourcings/carrier/' + id + '/jobs').toPromise();
    }

    getJobByCompany(id: string, companyId: string): Promise<Array<JobOutsourcingView>> {
        return this.http.get<Array<JobOutsourcingView>>(environment.api.workspace + '/outsourcings/carrier/'
            + id + '/jobs/' + companyId).toPromise();
    }

    sendEmails(driverInvoiceId: string, carrierId: string, email: string){
        const params = {
            driverInvoiceId, carrierId, email
        }
        return this.http.post(environment.api.workspace + '/send-email-driver-invoice', params).toPromise();
    }
}
