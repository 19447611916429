import { environment } from 'src/environments/environment';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';

@Injectable({
  providedIn: 'root'
})
export class ProfitReportService {

  constructor(private http: HttpClient) { }

  get(paginator: Paginator, filterData: FilterData) {
    
    const params: any = {
      "use_paginator": 1,
      "page": paginator.paginator.page,
      "per_page": paginator.paginator.per_page,
      "start": paginator.paginator.start,
      "end": paginator.paginator.end,
      "order_by": paginator.paginator.order_by,
      "order_type": paginator.paginator.order_type,
      "pickup_state": filterData.pickup_state,
      "delivery_state": filterData.delivery_state,
      "service": filterData.service,
      "filter_date_for": filterData.filter_date_for,
      "allocation": filterData.allocation,
      "carrier": filterData.carrier
    };

    return this.http.get<Paginator>(`${environment.api.company}/profit-report`, { params: params }).toPromise();
  }
}
