
import { Address } from '../global/address';
import { Balance } from './balance';
import { Carrier } from './carrier';
import { Customer } from './customer';
import { Document } from './document';
import { Employee } from './employee';
import { Job } from './job';
import { Payment } from './payment';
import { PaymentMethod } from './payment-method';
import { PaymentTransaction } from './payment-transaction';

export class BalanceView extends Balance{

    public estimate_document: Document;
    public customer: Customer;
    public employee: Employee;
    public job: Job;
    public estimate_service: string;
    public from: Address;
    public to: Address;
    public payment: Payment;
    public payment_transaction: PaymentTransaction;
    public payment_method: Array<PaymentMethod>;
    public carrier: Carrier;
    public service_job: string;
    public pickup_address: string;
    public pickup_range_start: number;
    public pickup_range_end: number;
    public delivery_address: string;
    public fadd: number;
    public special_conditions: string;
    public libs: number;
    public cf: number;
    public miles: number;
    public offer_value: number;

    public job_number: string;
    public pickup_date: number;

    constructor() {
        super();
        this.estimate_document = new Document();
        this.customer = new Customer();
        this.employee = new Employee();
        this.job = new Job();
        this.estimate_service = null;
        this.from = new Address();
        this.to = new Address();
        this.payment = new Payment();
        this.payment_transaction = new PaymentTransaction();
        this.payment_method = [];
        this.carrier = new Carrier();
        this.service_job = null;
        this.pickup_address = null;
        this.pickup_range_start = null;
        this.pickup_range_end = null;
        this.delivery_address = null;
        this.fadd = null;
        this.special_conditions = null;
        this.libs = null;
        this.cf = null;
        this.miles = null;
        this.offer_value = null;

        this.job_number = null;
        this.pickup_date = null;
    }
}
