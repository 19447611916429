<!--Por aca empieza el formulario de listado-->

<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        External Storages
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                                    Dashboard</a></li>
                            <li class="breadcrumb-item active" aria-current="page">External Storages</li>
                        </ol>
                    </nav>
                    <br>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                            <input type="text" name="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appWorkspaceRouterLink="/storage-companies/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New External Storages
                </a>
            </div>


        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Location</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td *ngIf="row.name">{{i+1}}</td>
                                <td *ngIf="row.name">{{row.name}}</td>
                                <td *ngIf="row.phone">{{row.phone}}</td>
                                <td *ngIf="row.name">{{row.address.city}}</td>
                                <td *ngIf="row.name">
                                    <a appWorkspaceRouterLink="/storage-companies/{{row.id}}/view" class="btn btn-info">
                                        <i class="fa-solid fa-eye"></i>
                                    </a>      
                                    <a appWorkspaceRouterLink="/storage-companies/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>                                                                  
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>
