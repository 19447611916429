import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZonesRatesMapService {

  constructor(private http: HttpClient) { }

    getAll(): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/zones').toPromise();
    }

    getAllByTariffVersionId(tariffVersionId: string): Promise<Array<Zone>> {
        return this.http.get<Array<Zone>>(environment.api.workspace + '/zones/tariff-version-id/' + tariffVersionId).toPromise();
    }

    getById(id: string) {
        return this.http.get<any>(environment.api.workspace + '/zones/' + id).toPromise();
    }

    save(dataForm: any): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: any) {
        return this.http.post(environment.api.workspace + '/zones-rates-map', dataForm).toPromise();
    }

    edit(dataForm: any) {
        return this.http.put(environment.api.workspace + '/zones-rates-map/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/zones/' + id).toPromise();
    }
}
