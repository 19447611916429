import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-jobs-booked-last7-days',
  templateUrl: './jobs-booked-last7-days.component.html',
  styleUrls: ['./jobs-booked-last7-days.component.scss']
})
export class JobsBookedLast7DaysComponent implements OnInit {
    JobsBooked: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) { 
    this.JobsBooked = [];

  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadJobsBookedCompany();
    } else {
        this.loadJobsBookedWorkspace();
    }

  }

  
  loadJobsBookedWorkspace(){
    this.dashboardWorkspaceService
        .getJobsBooked()
        .then((response) => {
            this.JobsBooked = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

  
loadJobsBookedCompany(){
    this.dashboardComopanyService
        .getJobsBooked()
        .then((response) => {
            this.JobsBooked = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

  

}
