import { PhoneIvrEntry } from "./phone-ivr-entry";
import { File } from '../global/file';

export class PhoneIvr {

    public id: number;
    public name: string;
    public description: string;
    public announcement: File;
    public timeout_duration: number;
    public entries: PhoneIvrEntry[];
    public company_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.description = null;
        this.announcement = new File();
        this.timeout_duration = null;
        this.entries = [];
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
