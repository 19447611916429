import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { PackingMaterial } from 'src/app/entities/workspace/packing-material';
import { HelperService } from 'src/app/services/helper.service';
import { MaterialsService } from 'src/app/services/workspaces/materials.service';

declare const jQuery;
@Component({
    selector: 'app-packing-materials-form',
    templateUrl: './packing-materials-form.component.html',
    styleUrls: ['./packing-materials-form.component.scss']
})
export class PackingMaterialsFormComponent implements OnInit {
    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    material: PackingMaterial;

    constructor(
        private materialsService: MaterialsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) { 
        this.material = new PackingMaterial();
    }


    ngOnInit(): void {
    }
    
    ngAfterViewInit() {
        const that = this;
        let idTimeOut = null;
        jQuery('.select-service-type').css('width', '460px');
        const select = jQuery('.service-type');

        select.select2({
            tags: true
        })
        .on('select2:select', (e) => {
            that.setServiceType(e.params.data, 'select');
        })
        .on('select2:unselect', (e) => {
            that.setServiceType(e.params.data, 'unselect');
        });
        // si hay cambios en el dom refrescamos el plugin
        select.bind('DOMSubtreeModified', () => {

            if (idTimeOut !== null) {
                clearTimeout(idTimeOut);
            }

            idTimeOut = setTimeout(() => {
                select.trigger('change');
            }, 1000);
        });
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    setServiceType(position, type: string) {
        const nameJobPosition = position.id.split(':')[1].trim().replace(/'/g, '');
        if (this.material.service_type.length > 0) {
            const indexJobPosition = this.material.service_type.indexOf(nameJobPosition);
            if (type === 'select') {
                if (indexJobPosition === -1) {
                    this.material.service_type.push(nameJobPosition);
                }
            }

            if (type === 'unselect') {
                if (indexJobPosition >= 0) {
                    this.material.service_type.splice(nameJobPosition, 1);
                }
            }
        } else {
            if (type === 'select') {
                this.material.service_type.push(nameJobPosition);
            }
        }
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.materialsService
            .getById(id)
            .then((response) => {
                this.material = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.materialsService
            .save(this.material)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/packing-materials');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
