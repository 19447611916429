import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';
import { TariffVersion } from 'src/app/entities/workspace/tariff-version';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';
import { TariffVersionsService } from 'src/app/services/workspaces/tariff-versions.service';

declare const swal: any;
declare const jQuery: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  private constantsMessages = ConstantsMessages;
  public auto_local_tariff_active: 'YES' | 'NO';
  public block_duplicate_leads: 'YES' | 'NO';
  public cover_stop_fees: 'YES' | 'NO';
  public lock_the_rate: 'YES' | 'NO';
  public reset_discount_after_inventory_changes: 'YES' | 'NO';
  public delivery_date_required_to_book: 'YES' | 'NO';
  public show_pricing_local_estimate_pdf: 'YES' | 'NO';
  public show_weight_local_estimate_pdf: 'YES' | 'NO';
  public setting: WorkspaceSetting;
  public time_per_mile_active: 'YES' | 'NO';

  public currentTab: string;

  public tariffVersions: TariffVersion[];
  public dataForm: TariffVersion;
  public actualPage: number;

  constructor(
    private helperService: HelperService,
    private settingsService: SettingsService,

    private tariffVersionsService: TariffVersionsService,
    public tariffVersionFactory: TariffVersionService

  ) {
    this.auto_local_tariff_active = 'YES';
    this.cover_stop_fees = 'YES'
    this.block_duplicate_leads = 'NO';
    this.lock_the_rate = 'NO';
    this.reset_discount_after_inventory_changes = 'YES';
    this.delivery_date_required_to_book = 'NO';
    this.show_pricing_local_estimate_pdf = 'YES';
    this.show_weight_local_estimate_pdf = 'YES';
    this.setting = new WorkspaceSetting();
    //this.time_per_mile_active = 'NO';
    this.currentTab = 'GENERAL';

    this.tariffVersions = [];
    this.actualPage = 1;
    this.dataForm = new TariffVersion();
  }

  ngOnInit(): void {
    this.settingsService.get()
    .then((settingResponse) => {
      
      this.setting = settingResponse;

      // this.auto_local_tariff_active = this.setting.auto_local_tariff.active ? 'YES' : 'NO';
      this.auto_local_tariff_active = 'YES';
      this.block_duplicate_leads = this.setting.block_duplicate_leads ? 'YES' : 'NO';
      this.cover_stop_fees = this.setting.cover_stop_fees ? 'YES' : 'NO';
      this.lock_the_rate = this.setting.lock_the_rate ? 'YES' : 'NO';
      this.reset_discount_after_inventory_changes = this.setting.reset_discount_after_inventory_changes ? 'YES' : 'NO';
      this.delivery_date_required_to_book = this.setting.delivery_date_required_to_book ? 'YES' : 'NO';
      this.show_pricing_local_estimate_pdf = this.setting.show_pricing_local_estimate_pdf ? 'YES': 'NO';
      this.show_weight_local_estimate_pdf = this.setting.show_weight_local_estimate_pdf ? 'YES': 'NO';
      //this.time_per_mile_active = this.setting.auto_local_tariff.time_per_mile_active? 'YES' : 'NO';
    });

    this.loadTariffVersions();

  }

  save() {
    this.helperService.showLoadingMxpro360();
    // this.setting.auto_local_tariff.active = this.auto_local_tariff_active == 'YES';
    this.setting.auto_local_tariff.active = true;
    //this.setting.auto_local_tariff.time_per_mile_active = this.time_per_mile_active == 'YES';
    this.setting.block_duplicate_leads = this.block_duplicate_leads == 'YES';
    this.setting.cover_stop_fees = this.cover_stop_fees == 'YES';
    this.setting.lock_the_rate = this.lock_the_rate == 'YES';
    this.setting.reset_discount_after_inventory_changes = this.reset_discount_after_inventory_changes == 'YES';
    this.setting.delivery_date_required_to_book = this.delivery_date_required_to_book == 'YES';
    this.setting.show_pricing_local_estimate_pdf = this.show_pricing_local_estimate_pdf == 'YES';
    this.setting.show_weight_local_estimate_pdf = this.show_weight_local_estimate_pdf == 'YES';

    if (this.setting.auto_local_tariff.travel_charge_base_on === 'MILES') {
      this.setting.auto_local_tariff.time_per_mile = null;
      this.setting.auto_local_tariff.cost_per_hour = null;
      this.setting.auto_local_tariff.minimum_travel_hours = null;
    }

    this.settingsService.save(this.setting)
    .then(() => {
      this.helperService.hideLoadingMxpro360();
      this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
    });
  }

  changeTab(tab) {
    this.currentTab = tab;
  }



  loadTariffVersions() {
    this.helperService.showLoadingMxpro360();
    this.tariffVersionsService.getAll().then(
      (response) => {
        this.tariffVersions = response
        for (const tariffVersion of this.tariffVersions) {
          if (tariffVersion.is_active) {
            this.tariffVersionFactory.setTariffVersionActive(tariffVersion);
            break;
          } else {
            this.tariffVersionFactory.removeTariffVersionActive();
          }
        }
      }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  remove(id: string) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.tariffVersionsService
            .remove(id)
            .then((response) => {
              this.loadTariffVersions();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

  versionIsActive(version){    
      this.helperService.showLoadingMxpro360();
      this.tariffVersionsService
      .save(version)
      .then(() => {
          this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
          this.loadTariffVersions();
      })
      .catch((error) => {
          this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
          console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    
  }


}
