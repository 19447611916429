import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class BalancesWorkspaceService {

    constructor(
        private http: HttpClient
    ) {
    }

    getBalancesByCarrier(idCarrier: string): Promise<Array<BalanceView>> {
        return this.http.get<Array<BalanceView>>(environment.api.workspace + '/balances-by-carrier/' + idCarrier).toPromise();
    }

    
    /**
     * Metodo que obtiene los balances por id de jobs
     *
     * @param id Id del job
     */
    getById(id, data) {
        return this.http.get<Array<BalanceView>>(environment.api.workspace + '/estimates/' + id + '/balances', { params: data }).toPromise();
    }

    getAllExpenses(): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/expenses').toPromise();
    }

}
