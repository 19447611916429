import { Component, OnInit } from '@angular/core';
import { LeadForwardingLink } from 'src/app/entities/workspace/lead-forwarding-link';
import { HelperService } from 'src/app/services/helper.service';
import { LeadForwardingLinksService } from 'src/app/services/workspaces/lead-forwarding-links.service';


declare const swal;
declare const jQuery;

@Component({
  selector: 'app-lead-forwarding-links',
  templateUrl: './lead-forwarding-links.component.html',
  styleUrls: ['./lead-forwarding-links.component.scss']
})
export class LeadForwardingLinksComponent implements OnInit {

  public rows: Array<LeadForwardingLink>;
  public filterSearch: string;
  public actualPage: number;


  constructor(
    private leadForwardingLinksService: LeadForwardingLinksService,
    private helperService: HelperService
  ) { 
    this.filterSearch = '';
    this.actualPage = 1;
  }

  ngOnInit(): void {
    
  }

  
  ngAfterViewInit() {
    this.load();
  }

  private load() {
    this.helperService.showLoadingMxpro360();
    this.leadForwardingLinksService
      .getAll()
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  remove(id: string) {
    swal({
      title: 'Are you sure?',
      text: "Are you sure do you want to delete this record?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.leadForwardingLinksService
            .remove(id)
            .then(() => {
              this.load();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

}
