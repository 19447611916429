import { Period } from 'src/app/entities/workspace/period';
import { RouteRateView } from 'src/app/entities/workspace/route-rate-view';
import { RouteVolumeRate } from 'src/app/entities/workspace/route-volume-rate';
import { Volume } from 'src/app/entities/workspace/volume';
import { Zone } from 'src/app/entities/workspace/zone';
import { PeriodsService } from 'src/app/services/workspaces/periods.service';
import { RouteRatesService } from 'src/app/services/workspaces/route-rates.service';
import { VolumesService } from 'src/app/services/workspaces/volumes.service';
import { ZonesService } from 'src/app/services/workspaces/zones.service';

import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionsSettingsService } from 'src/app/services/workspaces/tariff-versions-settings.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare var swal;
declare const jQuery;

@Component({
    selector: 'app-route-rates',
    templateUrl: './route-rates.component.html',
    styleUrls: ['./route-rates.component.scss']
})
export class RouteRatesComponent implements OnInit {

    periods: Array<Period>;
    zones: Array<Zone>;
    volumes: Array<Volume>;
    zoneOutId: string;
    periodId: string;
    tariffVersionId: string;

    routesRates: Array<RouteRateView>;

    constructor(
        private routeRatesService: RouteRatesService,
        private zonesService: ZonesService,
        private periodsService: PeriodsService,
        private volumesService: VolumesService,
        private helperService: HelperService,
        public tariffVersionsSettingsService: TariffVersionsSettingsService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.periods = [];
        this.zones = [];
        this.volumes = [];
        this.zoneOutId = null;
        this.periodId = null;
        this.routesRates = [];
        this.tariffVersionId = '';
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.helperService.showLoadingMxpro360();
        this.tariffVersionsSettingsService.getZones();

        this.getVolumes();

        this.helperService.showLoadingMxpro360();
        this.periodsService
            .getAllPeriodsByTariffVersionId(this.tariffVersionFactory.getTariffVersionId())
            .then((response) => {
                this.periods = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    getVolumes() {
        this.helperService.showLoadingMxpro360();
        this.volumesService
            .getAll(this.tariffVersionFactory.getTariffVersionId())
            .then((response) => {
                this.volumes = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    load() {
        this.helperService.showLoadingMxpro360();
        this.getVolumes();
        this.tariffVersionId = this.tariffVersionFactory.getTariffVersionId();
        this.routeRatesService
            .getAll(this.zoneOutId, this.periodId, this.tariffVersionId)
            .then((response) => {                
                this.routesRates = response;
                this.checkZones();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                
            });
    }

    // verifica si hay valores incoherentes en la configuacion de tarifas
    validateRates(): void {
        // recorre las configuraciones por zonas
        for (const rr of this.routesRates) {
            let startRow = true;
            let currentCost: number;
            // recorre los valores de configuraciones por cubic_feets
            for (const vr of rr.volume_rates) {
                // setea valor de la primer valor de cubic feets
                if (startRow) {
                    currentCost = Number(vr.cf_cost);
                    startRow = false;
                } else {
                    // obtiene el id para validar el color de background
                    const input_name = rr.id + "_" + vr.id;
                    // validar si el costo actual es mayor que el costo anterior
                    if (Number(vr.cf_cost) == currentCost) {
                        jQuery("#" + input_name).css({ 'background-color': 'orange', 'color': '#ffffff' });
                    } else if (Number(vr.cf_cost) > currentCost) {
                        jQuery("#" + input_name).css({ 'background-color': 'red', 'color': '#ffffff' });
                    } else {
                        jQuery("#" + input_name).css({ 'background-color': '', 'color': '' });
                    }
                    // reemplaza el current cost para hacer la siguiente validas
                    currentCost = Number(vr.cf_cost);
                }
            }
        }
    }

    save() {
        for (const routesRate of this.routesRates) {
            routesRate.tariff_version_id = this.tariffVersionId;
        }
        this.helperService.showLoadingMxpro360();
        this.routeRatesService
            .save(this.routesRates)
            .then(response => {
                const toast = swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    padding: '2em'
                });

                toast({
                    type: 'success',
                    title: 'Save successfull',
                    padding: '2em',
                });
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private checkVolumeRate(index) {
        const volumesNoExists = [];
        for (const volume of this.volumes) {
            let existVol = false;
            for (let routeVolumeRate of this.routesRates[index].volume_rates) {
                if (routeVolumeRate.volume_id === volume.id) {
                    existVol = true;
                    break;
                }
            }

            if (!existVol) {
                volumesNoExists.push(volume.id);
            }
        }

        // recorremos los que no existen, le creamos la entidad y lo agergamos a la lista del route Rate
        for (let vol_id of volumesNoExists) {

            const tmpRVR = new RouteVolumeRate();
            tmpRVR.volume_id = vol_id;
            tmpRVR.route_rate_id = this.routesRates[index].id;
            tmpRVR.cf_cost = null;

            this.routesRates[index].volume_rates.push(tmpRVR);
        }


    }

    /**
     * permite revisar si estan todas las zonas existentes con la zona saliente
     */
    private checkZones() {
        const zonesNoExists: Array<Zone> = [];

        for (let zone of this.tariffVersionsSettingsService.zones) {
            let existZone = false;
            for (let index in this.routesRates) {
                if (this.routesRates[index].zone_in_id === zone.id) {
                    existZone = true;
                    this.checkVolumeRate(index);
                    this.helperService.hideLoadingMxpro360();
                    break;
                }
            }

            if (!existZone) {
                zonesNoExists.push(zone);
            }
        }

        for (const zone of zonesNoExists) {

            const tmpRRV = new RouteRateView();
            tmpRRV.period_id = this.periodId;
            tmpRRV.zone_out_id = this.zoneOutId;
            tmpRRV.zone_in_id = zone.id;
            tmpRRV.zone_in = zone;
            tmpRRV.cf_discount = null;
            tmpRRV.packing_cost = null;
            tmpRRV.packing_discount = null;


            for (const volume of this.volumes) {
                const tmpRVR = new RouteVolumeRate();
                tmpRVR.volume_id = volume.id;
                tmpRVR.cf_cost = null;
                tmpRRV.volume_rates.push(tmpRVR);
            }

            this.routesRates.push(tmpRRV);
        }

        setTimeout(() => {
            this.sortVolumeRates();
        }, 1000);

    }

    private sortVolumeRates() {
        for (let index in this.routesRates) {
            const tmpVRs = [];
            for (const volume of this.volumes) {
                for (const vr of this.routesRates[index].volume_rates) {
                    if (vr.volume_id === volume.id) {
                        tmpVRs.push(vr);
                        break;
                    }
                }
            }
            this.routesRates[index].volume_rates = tmpVRs;
        }
    }

}
