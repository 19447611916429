export class StorageNotes {

    public id: string;
    public job_id: string;
    public employee_id: string;
    public note: number;
    public created: number;

    constructor() {
        this.id = null;
        this.job_id = null;
        this.employee_id = null;
        this.note = null;
        this.created = null;
    }

}
