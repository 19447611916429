import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Employee } from 'src/app/entities/workspace/employee';
import { SaleCommission } from 'src/app/entities/workspace/sale-commission';
import { SaleCommissionView } from 'src/app/entities/workspace/sale-commission-view';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { AuthService } from 'src/app/services/auth.service';
import { ReportsService } from 'src/app/services/companies/reports.service';
import { SalesCommissionsService } from 'src/app/services/companies/sales-commissions.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';

declare const swal;
declare const jQuery;

@Component({
    selector: 'app-report-sales-commissions',
    templateUrl: './report-sales-commissions.component.html',
    styleUrls: ['./report-sales-commissions.component.scss']
})
export class ReportSalesCommissionsComponent implements OnInit {

    rows: Array<SaleCommissionView>;

    public option: string;
    public saleCommission: SaleCommission;
    actualPage = 1;
    filterSearch = '';
    remarks = '';

    public betweenDates: BetweenDates;
    public filterEmployeeSelected: string;
    public employees: Employee[];
    public workDepartments: WorkDepartment[];
    public teamSelected: string;
    public teamSelectedText: string;
    public employeeSelected: string;
    public workDepartmentSelected: string;
    public swichEmployeeWD: boolean;
    public paginator: Paginator;
    public jobNumber: string;

    totalValue: number;

    @ViewChild('modalDeduction')
    modalDeduction: ElementRef;

    constructor(
        private reportsService: ReportsService,
        private employeesService: EmployeesService,
        private workDepartmentsService: WorkDepartmentsService,
        private authService: AuthService,
        private salesCommissionsService: SalesCommissionsService,
        private helperService: HelperService,
    ) {
        this.rows = [];
        this.workDepartments = [];
        this.betweenDates = new BetweenDates();
        this.filterEmployeeSelected = '';
        this.initFilterEmployeeTeam();
        this.swichEmployeeWD = false;
        this.totalValue = 0;
        this.option = '';
        this.remarks = '';
        this.saleCommission = new SaleCommission();
        this.paginator = new Paginator();
        this.jobNumber = ''
    }

    initFilterEmployeeTeam() {
        this.teamSelected = '';
        this.teamSelectedText = '';
        this.employeeSelected = '';
        this.workDepartmentSelected = '';
    }

    ngOnInit(): void {
     
    }
    
    ngAfterViewInit(): void{
        this.load();
        this.loadEmployees();
        this.loadWorkDepartments();
    }

    openDeduction(saleCommission: SaleCommission) {
      
        this.saleCommission = saleCommission;
        jQuery(this.modalDeduction.nativeElement).modal('show');
    }

    openWaiveDeduction(salesman_id){
        swal({
            title: 'Are you sure?',
            text: 'Do you want to Waive Deduction?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Waive Deduction!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.salesCommissionsService
                        .waiveDeduction(salesman_id)
                        .then(() => {
                            this.load();
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    openCancelation(salesman_id){
        swal({
            title: 'Are you sure?',
            text: 'Do you want Apply Cancellation?',
            type: 'error',
            confirmButtonClass: "btn-danger",
            showCancelButton: true,
            confirmButtonText: 'Yes, Apply Cancellation!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.salesCommissionsService
                        .applyCancellation(salesman_id)
                        .then(() => {
                            this.load();
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }


    saveRemark() {
        this.helperService.showLoadingMxpro360();
        this.salesCommissionsService
            .createPayrollBalances(this.saleCommission.id, this.remarks)
            .then(() => {
                this.load();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.reportsService
            .getAll(this.paginator)
            .then((response) => {
                this.rows = response.data;
                this.paginator.paginator.total = response.paginator.total;

                this.totalValue = 0;
                for (let comission of this.rows) {
                    this.totalValue = this.totalValue + Number(comission.total_commission);

                }
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    refund() {
        this.helperService.showLoadingMxpro360();
        this.salesCommissionsService
            .createRefund(this.saleCommission.id, this.saleCommission.remarks)
            .then(() => {
                this.load();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    pendingRefund() {
        this.helperService.showLoadingMxpro360();
        this.salesCommissionsService
            .createPendingRefund(this.saleCommission.id, this.saleCommission.remarks)
            .then(() => {
                this.load();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadWorkDepartments() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.workDepartments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * recibe y asigna fechas para filtro
     * @param dataReturn
     */
    getFilterDates(betweenDates: BetweenDates) {
        this.betweenDates = betweenDates;
    }

    /**
     * Metodo que realiza los filtros de busqueda
     */
    search(): void {
        const data: any = {};
        this.paginator.paginator.start = this.betweenDates.dateStart;
        this.paginator.paginator.end = this.betweenDates.dateEnd;

        if (this.workDepartmentSelected != '' && !this.swichEmployeeWD) {
            data.work_department_id = this.workDepartmentSelected;
        }
        if (this.employeeSelected != '' && this.swichEmployeeWD) {
            data.employee_id = this.employeeSelected;
        }

        if (this.jobNumber !== '') {
            data.job_number = this.jobNumber;
        }

        this.helperService.showLoadingMxpro360();
        // Si no hay datos en el filtro, se realiza la busqueda de todos los datos
        if (data.start === 0 && data.end === 0) {
            this.load();
        } else {
            this.reportsService
                .getAllParam(data, this.paginator)
                .then((response) => {
                    this.rows = response.data;
                    this.paginator.paginator.total = response.paginator.total;
                    this.totalValue = 0;
                    for (let comission of this.rows) {
                        this.totalValue = this.totalValue + Number(comission.total_commission);

                    }
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });

        }
    }

    downloadXml() {
        const data = JSON.stringify(this.rows);
        this.helperService.downloadReport('SALES_COMMISSIONS_XML', null, data);
    }

    downloadPDF() {
        let data: any = {
            'start': this.betweenDates.dateStart,
            'end': this.betweenDates.dateEnd
        };


        if (this.workDepartmentSelected != '' && !this.swichEmployeeWD) {
            data.work_department_id = this.workDepartmentSelected;
        }
        if (this.employeeSelected != '' && this.swichEmployeeWD) {
            data.employee_id = this.employeeSelected;
        }

        const dataRows = JSON.stringify(this.rows);
        data = JSON.stringify(data);
        this.helperService.downloadReport('SALES_COMMISSIONS', null, {dataRows, data});
    }

    
    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimateId) {
        // Redirige a la ventana de estmate
        this.helperService.openNewTab(estimateId, 'estimate');
    }

    setCurrentPage(currentpage: number): void {
        if (this.paginator.paginator.page != currentpage) {
          this.paginator.paginator.page = currentpage;
          this.load();
        }
    }
}
