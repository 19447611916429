import { File } from '../global/file';
export class ClaimRemark {
    public id: string;
    public claim_id: string;
    public employee_id: string;
    public remarks: string;
    public files: Array<File>;
    public created: string;
    public updated: string;

    constructor() {
        this.id = null;
        this.claim_id = null;
        this.employee_id = null;
        this.remarks = null;
        this.files = [];
        this.created = null;
        this.updated = null;
    }
}
