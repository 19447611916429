import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountRecovery } from 'src/app/entities/helpers/account-recovery';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

    accountRecoveryForm: AccountRecovery;
    sendToken: boolean;
    confirmationChangedPassword: boolean;

    constructor(
        private router: Router,
        private authService: AuthService,
        private helperService: HelperService
    ) {
        this.accountRecoveryForm = new AccountRecovery();
        this.sendToken = true;
        this.confirmationChangedPassword = false;
    }

    ngOnInit(): void {
    }

    sendTokenVerification(): void {
        this.helperService.showLoadingMxpro360();
        this.authService
            .sendToken(this.accountRecoveryForm)
            .then((response) => {
                this.sendToken = false;
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    verifyToken() {
        this.helperService.showLoadingMxpro360();
        this.authService
            .verifyTokenOfAccountRecovery(this.accountRecoveryForm)
            .then((response) => {
                this.confirmationChangedPassword = response['password'];
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeViewSendToken(): void {
        this.sendToken = true;
    }

    redirectToLogin(): void {
        this.router.navigateByUrl('/');
    }

}
