<div class=" layout-spacing layout-top-spacing">

  <div class="row">

      <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
          <h3>
            Liability Amounts
          </h3>
          <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                      <a appWorkspaceRouterLink="/dashboard">
                          <i class="fas fa-home"></i>
                          Dashboard
                      </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Liability Amounts</li>
              </ol>
          </nav>
      </div>


      <div class="col-xl-7 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
          <div class="d-flex justify-content-sm-end justify-content-center">
              <a appWorkspaceRouterLink="/liability-amounts/add" class="btn btn-new">
                  <i class="fas fa-plus-square"></i> &nbsp; New Liability Amount
              </a>
          </div>


      </div>
  </div>
  <!-- header -->

  <div class="row">
      <div class="col-lg-12">
          <div class="widget-content widget-content-area">
              <div class="table-responsive">
                  <table class="table table-bordered mb-4">
                      <thead>
                          <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr
                              *ngFor="let row of rows|search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage} index as i;">
                              <td>{{i+1}}</td>
                              <td>{{row.up_to | currency}}</td>
                              <td>
                                  <a appWorkspaceRouterLink="/liability-amounts/{{row.id}}/edit" class="btn btn-success">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                  </a>
                                  <button (click)="remove(row.id)" class="btn btn-danger">
                                    <i class="fa-solid fa-trash-can"></i>
                                  </button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <pagination-controls class="paginating-container pagination-solid"
                      (pageChange)="actualPage = $event"></pagination-controls>
              </div>
          </div>
      </div>
  </div>
  <!-- body -->
</div>
