import { Advertiser } from 'src/app/entities/workspace/advertiser';

import { Customer } from './customer';
import { Employee } from './employee';
import { Lead } from './lead';

export class LeadView extends Lead  {

    customer: Customer;
    advertiser: Advertiser;
    employee: Employee;
    endWindowDate: number;


    constructor() {
        super();
        this.customer = new Customer();
        this.advertiser = new Advertiser();
        this.employee = new Employee();
        this.endWindowDate = null;
    }

}
