import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConstantsMessages } from '../constants-messages';;
import { DocumentsService } from './companies/documents.service';
import { JobOutsourcingsService } from './companies/job-outsourcings.service';
import { PaymentMethod } from '../entities/workspace/payment-method';
import { EstimateView } from '../entities/workspace/estimate-view';
import { LeadView } from '../entities/workspace/lead-view';
import { JobItemQrCodesService } from './companies/job-item-qr-codes.service';
import { infoEstimateLabelQrView } from '../entities/info-estimate-label-qr-view';

declare const Snackbar: any;
declare const swal: any;
declare const QRCode: any;
@Injectable({
    providedIn: 'root'
})
export class HelperService {

    // Constantes que contiene el tipo de mensaje a mostrar
    constantsMessages = ConstantsMessages;

    public responseTransaction: any;
    public typePayment: PaymentMethod;
    public adviceChangesBeforeSave: boolean;
    public resultSearch: { estimates: EstimateView[], leads: LeadView[] } | { message: string };
    public onReSearch: EventEmitter<number>;
    public calculateTotalDueBalance: EventEmitter<string>;
    public setNumberDialer: EventEmitter<string>;
    public uploadFile: EventEmitter<boolean>;
    public ckeditor: string;
    public editEstimateReference: EventEmitter<string>;
    public phoneNumber:EventEmitter<string>;

    constructor(
        private authService: AuthService,
        private router: Router,
        private jobItemQrCodesService: JobItemQrCodesService,
        private jobOutsourcingsService: JobOutsourcingsService,
        private documentsService: DocumentsService,
    ) {
        this.resultSearch = { estimates: [], leads: [] };
        this.typePayment = new PaymentMethod();
        this.adviceChangesBeforeSave = false;
        this.ckeditor = '';
        this.onReSearch = new EventEmitter();
        this.calculateTotalDueBalance = new EventEmitter();
        this.setNumberDialer = new EventEmitter();
        this.uploadFile = new EventEmitter();
        this.editEstimateReference = new EventEmitter();
        this.phoneNumber = new EventEmitter();
    }

    /**
     * Muestra un Snackbar indicando la accion realizada
     */
    showMessageSnackbar(message: string, type: "SUCESS" | "ERROR" | "WARNING" = null, time: 'LONG' | 'SHORT' = 'SHORT'): void {

        // Se elije el color del Snackbar
        let backgroundColor: string;
        if (type == null) {
            switch (message) {
                case this.constantsMessages.SAVED:
                case this.constantsMessages.DELETED:
                case this.constantsMessages.CHANGE_COMPANY_SUCCESS:
                case this.constantsMessages.COPIED:
                case this.constantsMessages.SEND_CAMPAIGN:
                case this.constantsMessages.EMAIL_SENT:
                case this.constantsMessages.UPDATE:
                case this.constantsMessages.REDIRECTING_TO_LOGIN:
                    backgroundColor = '#8dbf42';
                    break;
                default:
                    backgroundColor = '#e7515a';
                    break;
            }
        } else {
            switch (type) {
                case "WARNING":
                    backgroundColor = '#ff9b05';
                    break;
                case "SUCESS":
                    backgroundColor = '#8dbf42';
                    break;
                case "ERROR":
                default:
                    backgroundColor = '#e7515a';
                    break;
            }
        }

        const duration = time == 'SHORT' ? 2000: 3500;
        Snackbar.show({
            text: message,
            pos: 'top-center',
            actionTextColor: '#fff',
            backgroundColor,
            duration: duration,
            showAction: false
        });
    }



    /**
     * Muestra el loading de Moverxpro360
     */
    showLoadingMxpro360(): void {
        let mxpro360Loading = document.getElementById('mxpro360-loading');
        mxpro360Loading.style.display = 'flex';
        /* document.querySelector('body').classList.add('modal-open'); */
    };

    /**
    * Oculta el loading de Moverxpro360
    */
    hideLoadingMxpro360(): void {
        let mxpro360Loading = document.getElementById('mxpro360-loading');
        mxpro360Loading.style.display = 'none';
        /* document.querySelector('body').classList.remove('modal-open'); */
    }

    /**
     * Muestra un Snackbar indicando la accion realizada
     */
    showMessageError(error, data): void {
        // Se elije el color del Snackbar
        swal({
            title: 'Error',
            html: '<table class="table table-sm "> <tbody><tr><th>Url: ' + error.url + '</th></tr><tr><th>Request: ' + JSON.stringify(data) + '</th></tr><tr><th>Response: ' + JSON.stringify(error) + '</th></tr></tbody></table>',
            type: 'error',
            customClass: "swal-message-error"
        });
    }

    /**
     * Metodo que realiza la descarga de los reportes
     *
     * @param routeDownload Ruta para descargar el archivo que contiene el reporte. El archivo puede ser pdf o xml
     * @param id Id del estimate o del job
     * @param data Datos que puede contener el reporte
     */
    downloadReport(routeDownload: string, id?: string, data?: any): void {

        try {
            switch (routeDownload) {
                case 'DRIVER_INVOICE': {
                    window.open(`${environment.api.workspace}/export-driver-invoice?Authorization=` +
                        `${this.authService.session.token}&workspace_id=${this.authService.workspaceSession.workspace.id}&auth_employee_id=${this.authService.workspaceSession.employee.id}` +
                        `&email=${data.email}&carrierId=${data.carrier.id}&driverInvoiceId=${data.driverInvoice.id}`, '_blank');
                    break;
                }
                case 'PRINT-VOULT-STICKER': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id
                        + '/job-storages/' + id + '/print-vault-sticker/' + data +'?Authorization=' +
                        this.authService.session.token + '&workspace_id=' + this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
                    break;
                }
                case 'AUTO_TRANSPORT_ESTIMATES': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id
                        + '/estimates/' + id + '/export-pdf-autotransport?Authorization=' +
                        this.authService.session.token + '&workspace_id=' + this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
                    break;
                }

                case 'LONG_ESTIMATES': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id
                        + '/estimates/' + id + '/export-pdf-quote?Authorization=' +
                        this.authService.session.token + '&workspace_id=' + this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
                    break;
                }

                case 'LOCAL_ESTIMATES': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id +
                        '/estimates/' + id + '/export-pdf-estimate-local?Authorization=' +
                        this.authService.session.token + '&workspace_id=' + this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
                    break;
                }

                case 'FREIGHT': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id +
                        '/estimates/' + id + '/export-pdf-freight?Authorization=' +
                        this.authService.session.token + '&workspace_id=' + this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
                    break;
                }

                case 'SIGNATURE': {
                    const displayName = typeof data.document.file === 'string' ? data.document.file : data.document.file.display_name;
                    window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' + displayName);
                    break;
                }

                case 'SALES_COMMISSIONS': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id +
                        '/sales-commissions/export-pdf?Authorization=' + this.authService.session.token +
                        '&workspace_id=' + this.authService.workspaceSession.id +
                        '&auth_employee_id=' + this.authService.workspaceSession.employee.id +
                        '&report_data=' + data.dataRows + '&filter_data=' + data.data, '_blank');
                    break;
                }

                case 'AUTO_TRANSPORT_CUSTOMERS': {
                    window.open(environment.api.base + '/customers/estimates/' + id +
                        '/export-pdf-autotransport?company_id=' + this.authService.workspaceSession.company.id + '&Authorization=' +
                        this.authService.session.token, '_blank');
                    break;
                }

                case 'LONG_ESTIMATES_CUSTOMER': {
                    window.open(environment.api.base + '/customers/estimates/' + id +
                        '/export-pdf-quote?company_id=' + this.authService.workspaceSession.company.id + '&Authorization=' +
                        this.authService.session.token, '_blank');
                    break;
                }

                case 'LOCAL_ESTIMATES_CUSTOMER': {
                    window.open(environment.api.base + '/customers/estimates/' + id +
                        '/export-pdf-estimate-local?company_id=' + this.authService.workspaceSession.company.id + '&Authorization=' +
                        this.authService.session.token, '_blank');
                    break;
                }

                case 'SALES_COMMISSIONS_XML': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id +
                        '/sales-commissions/export-excel?Authorization=' + this.authService.session.token +
                        '&workspace_id=' + this.authService.workspaceSession.id +
                        '&auth_employee_id=' + this.authService.workspaceSession.employee.id +
                        '&report_data=' + data, '_blank');
                    break;
                }

                case 'CONTRACT_INFORMATION': {
                    window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' + data.file.display_name);
                    break;
                }

                case 'CARRIERS': {
                    window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' +
                        data.display_name);
                    break;
                }

                case 'ESTIMATE_CARRIERS': {
                    let downloadReport = false;
                    if (data.estimate.document) {
                        if (data.estimate.document.file) {
                            downloadReport = true;
                            window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' +
                                data.estimate.document.file.display_name);
                        }
                    }

                    if (!downloadReport) {

                        this.jobOutsourcingsService
                            .downloadEstimate(data.id)
                            .then((response) => {

                                if (response && response !== null && typeof response !== 'undefined' && response !== '') {
                                    window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' +
                                        response);
                                } else {
                                    this.showMessageSnackbar(this.constantsMessages.ERROR_DOWNLOAD);
                                }
                            })
                            .catch((error) => {
                                this.showMessageSnackbar(this.constantsMessages.ERROR_DOWNLOAD);
                                console.error('Error', error);
                            })
                            .finally(() => {
                                this.hideLoadingMxpro360();
                            });
                    }

                    break;
                }

                case 'DOCUMENTS_CARRIERS': {
                    window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' + data);
                    break;
                }

                case 'HISTORY_ACCEPTANCE': {

                    this.showLoadingMxpro360();
                    this.documentsService
                        .getDocumentById(data.job_acceptance_doc_id)
                        .then((response) => {
                            window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' +
                                response.file.display_name);
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.hideLoadingMxpro360();
                        });
                    break;
                }

                case 'EXPORT_LEADS': {
                    const exportUrl = environment.api.workspace + '/export-leads?Authorization=' + this.authService.session.token
                        + '&workspace_id=' + this.authService.workspaceSession.workspace.id
                        + '&auth_employee_id=' + this.authService.workspaceSession.employee.id
                        + '&report_data=' + JSON.stringify(data) + '&export=1';
                    window.open(exportUrl, '_blank');
                    break;
                }

                case 'EXPORT_BAD_LEADS': {
                    const exportUrl = environment.api.workspace + '/export-bad-leads?Authorization=' + this.authService.session.token
                        + '&workspace_id=' + this.authService.workspaceSession.workspace.id
                        + '&auth_employee_id=' + this.authService.workspaceSession.employee.id
                        + '&report_data=' + JSON.stringify(data) + '&export=1';
                    window.open(exportUrl, '_blank');
                    break;
                }
                case 'INVOICE_COMPLETIONS': {
                    window.open(environment.api.companies + this.authService.workspaceSession.company.id
                        + '/jobs/' + id + '/invoice/false?Authorization=' +
                        this.authService.session.token + '&workspace_id=' + this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
                    break;
                }
            }
        } catch (error) {
            this.showMessageSnackbar(this.constantsMessages.ERROR_DOWNLOAD);
        }
    }

    /**
     * Metodo que detecta el navegador en el que se esta trabajando
     */
    detectBrowser(): string {
        // Verifica si el navegador es internet explorer
        if (navigator.userAgent.search('MSIE') >= 0) {
            return 'MSIE';
        } else if (navigator.userAgent.search('Chrome') >= 0) {
            // Verifica si el navegador es internet Chrome
            return 'Chrome';
        } else if (navigator.userAgent.search('Firefox') >= 0) {
            // Verifica si el navegador es Firefox
            return 'Firefox';
        } else if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
            // Verifica si el navegador es Safari
            return 'Safari';
        } else if (navigator.userAgent.search('Opera') >= 0) {
            // Verifica si el navegador es Opera
            return 'Opera';
        }
    }



    /********************************************
     * Metodos de Navegación
     * **************************************** */

    /**
     * Permite construir la url para el workspace pasandole el router link
     * @param link
     * @returns
     */
    buildWorkspaceRouterLink(link: string) {

        // creamos la url base
        let urlBase = '/workspace/' + this.authService.workspaceSession.workspace.id

        // devovlemos la url final
        return urlBase + link;
    }

    /**
     * permite redireccionar al router link dado de un workspace
     * @param link
     * @returns
     */
    goToWorkspaceRouterLink(link: string) {

        // ejecutamos el metodo que redirecciona
        return this.router.navigateByUrl(this.buildWorkspaceRouterLink(link));
    }

    /**
     * Permite construir una url para el area de company
     * @param link
     * @returns
     */
    buildCompanyRouterLink(link: string) {
        if (this.authService.workspaceSession.company === null) {
            return;
        }

        // creamos la url base
        let urlBase = this.buildWorkspaceRouterLink('/companyspace/') + this.authService.workspaceSession.company.id;

        // devolvemos la url final
        return urlBase + link;
    }

    /**
     * permite redireccionar al router link dado de un company
     *
     * @param link
     * @returns
     */
    goToCompanyRouterLink(link: string) {

        // ejecutamos el metodo que redirecciona
        return this.router.navigateByUrl(this.buildCompanyRouterLink(link));
    }

    /********************************************
     * Codigo pendiente por revisar
     * **************************************** */


    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param id Id de la pagina que se va a abrir
     * @param page Pagina la cual se va a abrir
     *
     */
    openNewTab(id: string, page: string, companyId?: string) {
        // Obtiene el dominio base de la url
        const baseUrl = window.location.href.replace(this.router.url, '');
        // Url a la cual se va a redirigir dentro de la company
        let url = '#';
        switch (page) {
            case 'estimate':
            case 'payments':
            case 'operations':
            case 'claims':
            {
                if (companyId) {

                    url = this.router.serializeUrl(
                        this.router.createUrlTree([`/company/${companyId}/moving/${id}/${page}`])
                    );
                } else {

                    url = this.router.serializeUrl(
                        this.router.createUrlTree([`/moving/${id}/${page}`])
                    );
                }
                break;
            }

            case 'leads-estimates-results': {

                url = this.router.serializeUrl(
                    this.router.createUrlTree(['/' + page])
                );
                break;
            }
        }

        // Abre el nuevo tab
        window.open(baseUrl + this.buildCompanyRouterLink(url), '_blank');
    }

    openNewEstimateCalendar(id: string, companyId: string) {

        // Obtiene el dominio base de la url
        const baseUrl = window.location.href.replace(this.router.url, '');

        // Url a la cual se va a redirigir dentro de la company
        let url = '#';

        url = this.router.serializeUrl(
            this.router.createUrlTree(['/moving/' + id + '/estimate'])
        );
        // Abre el nuevo tab
        window.open(baseUrl + this.buildCompanyRouterLink(url), '_blank');
    }

    openOutsourcingEstimate(id: string, companyId: string) {
        // Obtiene el dominio base de la url
        const baseUrl = window.location.href.replace(this.router.url, '');

        // Url a la cual se va a redirigir dentro de la company
        let url = '#';

        url = this.router.serializeUrl(
            this.router.createUrlTree(['/moving/' + id + '/outsourcings'])
        );
        // Abre el nuevo tab
        window.open(baseUrl + this.buildCompanyRouterLink(url), '_blank');
    }

    /**
     * Get de la respuesta de la transaccion
     */
    public get transaction() {
        return this.responseTransaction;
    }

    /**
     * Set de la respuesta de la transaccion
     */
    public set transaction(responseTransaction) {
        this.responseTransaction = responseTransaction;
    }

    /**
     * Get de la respuesta de la transaccion
     */
    public get paymentMethod(): PaymentMethod {
        return this.typePayment;
    }

    /**
     * Set de la respuesta de la transaccion
     */
    public set paymentMethod(typePayment: PaymentMethod) {
        this.typePayment = typePayment;
    }

    /**
     * Get de la respuesta de la transaccion
     */
    public get changesBeforeSave(): boolean {
        return this.adviceChangesBeforeSave;
    }

    /**
     * Set de la respuesta de la transaccion
     */
    public set changesBeforeSave(adviceChangesBeforeSave: boolean) {
        this.adviceChangesBeforeSave = adviceChangesBeforeSave;
    }

    public set resultsearch(resultSearch: any) {
        this.resultSearch = resultSearch;
        this.onReSearch.emit(1);
    }

    public get resultsearch() {
        return this.resultSearch;
    }

    /**
   * pinta los codigos qr en el div expecificado
   * @param width Alto del qr en px
   * @param height Ancho del qr en px
   * @param text texto que lleleta el qr como info
   * @param ids id del elemento html donde se pintara el qr
   */
    public generateQRcode(width: number, height: number, text: string, ids: string) {
        let config = {
            text: text, // Content
            width: width, // Widht
            height: height, // Height
            colorDark: "#000000", // Dark color
            colorLight: "#ffffff", // Light color
            // quietZone: 15,
            logo: "/assets/img/logotipo_moverxpro360.png",
            correctLevel: QRCode.CorrectLevel.H // L, M, Q, H
        };

        return new QRCode(document.getElementById(ids), config);
    }

    /**
   * Trae la informacion necesaria para el sticker del qr
   */
    getInfoEstimateLabelQr(estimateId: string) {
        this.showLoadingMxpro360();
        return new Promise<infoEstimateLabelQrView>((resolve, reject) => {
            this.jobItemQrCodesService
                .getInfoEstimateLabelQr(estimateId)
                .then(response => {
                    resolve(response[0]);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    this.hideLoadingMxpro360();
                });
        })
    }

    roundDecimals(myNumber: number, myDecimals: number): number {
        const rounder = Math.pow(10, myDecimals);
        return Math.round(myNumber * rounder) / rounder;
    }
}
