<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span> Tariff Version
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a>
                    </li>

                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/settings">Settings</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">

                <!-- <div class="widget-header">
                    <h4>
                        Form
                    </h4>
                </div> -->

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">Tariff Version</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                            </span>
                                        </div>
                                        <input type="text" alphaNumeric [(ngModel)]="dataForm.name" name="wd_plate"
                                            class="form-control" required>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Tariff Version</small>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">Measure Unit</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                            </span>
                                        </div>
                                        <select name="storage_type" [(ngModel)]="dataForm.measure_unit" class="form-control select-storage-type" style="width: 90%;" [disabled]="dataForm.id != null">
                                            <option value="CUBIC_FEET">CUBIC FEET</option>
                                            <option value="POUNDS">POUNDS</option>
                                        </select>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Measure Unit</small>
                                </div>
                            </div>                                
                        </div>

                        <div class="text-center">
                            <button class="btn btn-save ml-1 mt-3" type="submit">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>