<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Clock Users
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Clock Users</li>
                        </ol>
                    </nav>
                    <br>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appCompanyRouterLink="/payroll-clock-users/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; Add User
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th class="text-center">Geo</th>
                                <th class="text-center">Off-Site Allowed</th>
                                <th class="text-center">Hourly Rate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of clockEmployees; let i = index">
                                <td> {{ row.employee.name | titlecase}}</td>
                                <td class="text-center">
                                    <span *ngIf="row.geo_requerid" class="checked">
                                        <i class="fa-solid fa-square-check"></i>
                                    </span>
                                    <span *ngIf="!row.geo_requerid" class="not-checked">
                                        <i class="fa-solid fa-square-minus"></i>
                                    </span>
                                </td> 
                                <td class="text-center">
                                    <span *ngIf="row.offside_allowed" class="checked">
                                        <i class="fa-solid fa-square-check"></i>
                                    </span>
                                    <span *ngIf="!row.offside_allowed" class="not-checked">
                                        <i class="fa-solid fa-square-minus"></i>
                                    </span>
                                </td> 
                                <td class="text-center"> 
                                    <span *ngIf="showHourlyRate[i]">{{ row.hourly_rate }}</span>
                                    <span *ngIf="!showHourlyRate[i]"> ****** </span>
                                </td>
                                <td>
                                    <a class="btn btn-success" appCompanyRouterLink="/payroll-clock-users/{{row.id}}/edit">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button class="btn btn-secondary" (click)="setHourlyRate(i)">
                                        <i *ngIf="!showHourlyRate[i]" class="fa fa-lock" aria-hidden="true"></i>
                                        <i *ngIf="showHourlyRate[i]" class="fa fa-unlock" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>