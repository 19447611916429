import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailUnsuscribe } from '../entities/companies/email-unsuscribe';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  constructor(private http: HttpClient) {

  }
  unsubscribeEmails(dataForm: EmailUnsuscribe, idWorkspace) {
    return this.http.post(environment.api.base + '/unsubscribe-email-campaigns?workspace=' + idWorkspace, dataForm).toPromise();
  }
}
