<div class="layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="widget-heading d-flex justify-content-between">
            <h3 class="title-content-area">
                Labels
            </h3>
            <div class="btns-options">                
                <button class="btn btn-success" (click)="imprSelec()" *ngIf="itemsQrNotAssigned.length > 0">
                    <i class="fa-solid fa-print mr-2"></i>
                    <span>Print</span>
                </button>
                <button class="btn btn-new" (click)="openModalAddQr()">
                    <i class="fa-solid fa-tag mr-2"></i>
                    <span>Generate labels</span>
                </button>                    
                <button class="btn btn-danger" (click)="removeQr()" *ngIf="itemsQrNotAssigned.length > 0" style="float: right;">
                    <i class="fa-solid fa-trash-can mr-2"></i>
                    <span>Remove Labels</span>
                </button>
            </div>                    
        </div>

    </div>
</div>

<div class="layout-spacing layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="label-columns" *ngIf="itemsQrNotAssigned.length > 0">
            <label for="" class="title-label-columns">Label Columns</label>
            <div class="n-chk">
                <label class="new-control new-radio radio-warning">
                    <input type="radio" class="new-control-input" name="two_columns" [value]="false" [(ngModel)]="labelColumns">
                    <span class="new-control-indicator"></span>
                    <span class="option-label">1" x 2-5/8" 3 Columns</span>
                </label>
            </div>
            <div class="n-chk">
                <label class="new-control new-radio radio-warning">
                    <input type="radio" class="new-control-input" name="two_columns" [value]="true" [(ngModel)]="labelColumns">
                    <span class="new-control-indicator"></span>
                    <span class="option-label">1" x 2-5/8" 2 Columns</span>
                </label>
            </div>                        
        </div>

        <div class='qr_page page_1'>                        
                        
            <div class="print-estimate-label labels-assigned" *ngIf="itemsQrAssigned.length > 0">
                <h3 class="text-center">Assigned Labels</h3>
                <div class="row labels-container">
                    <ng-container *ngFor="let item of itemsQrAssigned">
                        <div class="item-label">
                            <app-qr-labels
                                [selectionBehavior]="false"
                                [infoEstimateLabelQr]="infoEstimateLabelQr" 
                                [itemQr]="item">
                            </app-qr-labels>
                        </div>
                    </ng-container>
                </div>
            </div>
            
            <!-- Hoja de Impresión -->
            <div id='print'>      
                
                <div id="page-header" class="page-header">

                </div>

                <div class="print-estimate-label labels-not-assigned" *ngIf="itemsQrNotAssigned.length > 0" [ngClass]="{'two-columns': labelColumns}">
                    <h3 id="title-unassigned-labels" class="text-center">Unassigned Labels</h3>

                    <div class="row labels-container">
                        <ng-container *ngFor="let item of itemsQrNotAssigned">
                            <div class="page-break">
                                <div class="item-label">
                                    <app-qr-labels 
                                        [selectionBehavior]="false"
                                        [infoEstimateLabelQr]="infoEstimateLabelQr" 
                                        [itemQr]="item">
                                    </app-qr-labels>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>

            </div>


        </div>

    </div>
</div>
    

<!-- Modal add additional concept-->
<div class="modal fade register-modal generate-labels-modal" #addQr tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Generate Labels
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-6">
                        <div class="form-group mb-4">
                            <label class="control-label">Quantity</label>
                            <input name="quantity" type="number" class="form-control" step="1" [(ngModel)]="quantity" />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group mb-4">
                            <label class="control-label">Color Code</label>
                            <input name="dataForm_color_hex" type="color" class="form-control label-color" [(ngModel)]="dataForm.color_hex" />
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="generateLabels()" data-dismiss="modal" class="btn btn-save">
                    Generate
                </button>
            </div>
        </div>

    </div>
</div>