<!-- <div class="widget widget-table-one">
    <div class="widget-heading">
        <h5 class="">Picked Up Tomorrow</h5>
    </div>

    <div class="widget-content" *ngIf="jobsPickedUpTomorrow.length > 0">
        <div class="transactions-list" *ngFor="let jobPickedUpTomorrow of jobsPickedUpTomorrow">
            <div class="t-item">
                <div class="t-company-name">
                    
                    <div class="t-name">
                        <h6>{{jobPickedUpTomorrow}}</h6>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div> -->




<div class="widget widget-table-one">
    <div class="widget-heading">
        <div class="title-picked">Picked Up Tomorrow</div>
        <div class="w-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
        </div>
    </div>

    <div class="widget-content">
        <ng-container *ngFor="let jobPickedUpTomorrow of jobsPickedUpTomorrow">
            <div class="row job-content">
                <div class="col-md-7 info-customer">
                    <div class="job">{{jobPickedUpTomorrow.job}}</div>
                    <div class="name-customer">{{jobPickedUpTomorrow.customer.name}}</div>                
                </div>
                <div class="col-md-5">

                    <div class="moving">
                        <span class="from">{{jobPickedUpTomorrow.from}}</span> 
                        <span class="icon-arrow">
                            <svg viewBox="0 0 24 24" stroke="currentColor" stroke-width="3" fill="none"
                                stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1" height="16">
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                                <polyline points="12 5 19 12 12 19"></polyline>
                            </svg>
                        </span> 
                        <span class="to">{{jobPickedUpTomorrow.to}}</span>
                    </div>                
                
                    <div class="price">
                        {{jobPickedUpTomorrow.total | currency}}
                    </div>
                </div>
            </div>
            <hr>
        </ng-container>
    </div>
</div>