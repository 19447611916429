<div class="call-report-section">

    <div class="d-flex">

  <!--       <div class="phone-widget widget-one col-3 p-0 mb-3">
            <div class="widget-content widget-content-area">
                <div class="justify-content-between widget-heading">
                    <h3 class="title-content-area">
                        Today
                    </h3>
                    <i class="fa-solid fa-clock" style="color: #ffb200"></i>
                </div>
                <div class="widget-body">
                    <div>00:00:00</div>
                    <div class="widget-info">Time Online</div>
                </div>
            </div>
        </div> -->

        <div class="phone-widget widget-two col-3 pr-0 mb-3">
            <div class="widget-content widget-content-area">
                <div class="justify-content-between widget-heading">
                    <h3 class="title-content-area">
                        Today
                    </h3>
                    <i class="fa-solid fa-phone"></i>
                </div>
                <div class="widget-body">
                    <div class="phone-count">{{outboundCalls}}</div>
                    <div class="widget-info">Outgoing Calls</div>
                </div>
            </div>
        </div>

        <div class="phone-widget widget-three col-3 pr-0 mb-3">
            <div class="widget-content widget-content-area">
                <div class="justify-content-between widget-heading">
                    <h3 class="title-content-area">
                        Today
                    </h3>
                    <i class="fa-solid fa-phone-volume"></i>
                </div>
                <div class="widget-body">
                    <div class="phone-count">{{andsweredCalls}}</div>
                    <div class="widget-info">Answered Calls</div>
                </div>
            </div>
        </div>

        <div class="phone-widget widget-four col-3 pr-0 mb-3">
            <div class="widget-content widget-content-area">
                <div class="justify-content-between widget-heading">
                    <h3 class="title-content-area">
                        Today
                    </h3>
                    <i class="fa-solid fa-phone-slash"></i>
                </div>
                <div class="widget-body">
                    <div class="phone-count">{{missedCalls}}</div>
                    <div class="widget-info">Missed Calls</div>
                </div>
            </div>
        </div>

    </div>

    <!-- Call History -->
    <div class="logs-section">
        <div class="widget-content widget-content-area">
            <div class="d-flex justify-content-between widget-heading">

                <div class="col-3">
                    <h3 class="title-content-area">
                        Call History
                    </h3>
                </div>

                <!-- <button class="btn btn-secondary">
                    Options
                </button> -->

                <div class="col-6 d-flex justify-content-center">
                    <!-- <div class="filtered-list-search align-self-center px-0">
                        <form class="form-inline my-2 my-lg-0">
                            <div class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                                <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                                    class="form-control filter-search" id="input-search" placeholder="Search...">
                            </div>
                        </form>
                    </div> -->
                </div>

                <div class="col-3">

                </div>
            </div>
            <div class="widget-body">
                <ul class="nav nav-tabs mb-3 mt-3" id="simpletab" role="tablist">
                    <li class="nav-item">
                        <a (click)="filterCalls('All')"class="nav-link active" id="home-tab" data-toggle="tab" href="#recent" role="tab" aria-controls="recent" aria-selected="true">
                            All
                        </a>
                    </li>
                    <li class="nav-item">
                        <a (click)="filterCalls('Outgoing')"class="nav-link" id="home-tab" data-toggle="tab" href="#recent" role="tab" aria-controls="recent" aria-selected="true">
                            Outgoing
                        </a>
                    </li>
                    <li class="nav-item">
                        <a (click)="filterCalls('Incoming')"class="nav-link" id="home-tab" data-toggle="tab" href="#recent" role="tab" aria-controls="recent" aria-selected="true">
                            Incoming
                        </a>
                    </li>
                    <li class="nav-item">
                        <a (click)="filterCalls('Missed')"class="nav-link" id="home-tab" data-toggle="tab" href="#recent" role="tab" aria-controls="recent" aria-selected="true">
                            Missed
                        </a>
                    </li>
                    <li class="nav-item">
                        <a (click)="filterVoiceMail()"class="nav-link" id="home-tab" data-toggle="tab" href="#recent" role="tab" aria-controls="recent" aria-selected="true">
                            Voice Mail
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contacts" role="tab" aria-controls="contacts" aria-selected="false">Contacts</a>
                    </li> -->
                </ul>
                <div class="tab-content" id="simpletabContent">
                    <div class="tab-pane fade show active" id="recent" role="tabpanel" aria-labelledby="home-tab">

                        <div class="logs-table">

                            <div class="table-responsive">
                                <table class="table table-hover m-0">

                                    <tbody>
                                        <tr class="d-flex justify-content-between align-items-center" *ngFor="let phonecall of paginator.data">
                                            <!-- <td>
                                                <div class="customer-name"> {{phonecall.employee.name}} </div>
                                                <div class="caller-id">
                                                    <span>Restaurante</span>
                                                    <a class="tooltipmxp" title="Caller ID">
                                                        <i class="fa-solid fa-circle-info"></i>
                                                    </a>
                                                </div>
                                                <div>{{phonecall.direction}}</div>
                                            </td> -->
                                            <td class="w150"   >
                                                <div class="customer-phone">

                                                    <!-- Llamada Entrante - Conectada -->
                                                    <span class="phone-icon incoming-call" *ngIf="phonecall.direction == 'INCOMING' && phonecall.disposition == 'ANSWERED'">
                                                        <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                                            <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.036,16.036,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.94874,15.94874,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791ZM152.08594,97.10889c.021.15283.03955.30566.06933.45752.02491.125.05957.24609.09034.36914.03271.13037.06152.26123.10058.39013.03662.12061.082.23682.124.355.04541.12891.08838.25782.14063.38477.04492.10742.09766.21094.147.31641.06153.13183.12061.26464.18994.39355.05176.09717.11133.189.167.2832.07666.13037.15087.26172.23584.38819.0664.09863.14062.1914.21093.28662.084.11474.16407.231.25537.3418.11622.14111.2417.27343.36719.40625.0542.0581.10205.11914.15869.17578.0542.0542.11377.10058.16895.15283.13525.12744.27.25537.41406.37353.10645.08789.21826.16455.32813.24561.10009.07422.19726.15185.30078.22119.12207.08154.248.15234.37353.22656.09912.05909.19629.12159.29834.17627.124.0669.25147.12305.37842.18262.11035.05176.21875.10693.332.15381.1211.05029.24463.09082.36768.13476.12353.04444.2456.0918.37207.13038.12158.03662.24512.06347.36816.09472.13037.03272.25879.06885.3916.09522.14063.02832.28321.04492.42481.06494.1167.0166.231.03857.34912.05029.26221.02588.52588.04.78955.04h40a8,8,0,0,0,0-16H179.31445l34.34278-34.34277a8.00053,8.00053,0,0,0-11.31446-11.31446L168,76.68555V56a8,8,0,0,0-16,0V95.99512c0,.26562.01367.53174.04.79687C152.05078,96.89941,152.07129,97.00293,152.08594,97.10889Z"/>
                                                        </svg>
                                                    </span>

                                                    <!-- Llamada Saliente - Conectada -->
                                                    <span class="phone-icon outgoing-call" *ngIf="phonecall.direction == 'OUTGOING' && phonecall.disposition == 'ANSWERED'">
                                                        <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                                            <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.02839,16.02839,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.95139,15.95139,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791ZM160,104a7.97772,7.97772,0,0,0,5.65723-2.34277L200,67.31445V88a8,8,0,0,0,16,0V48.00488c0-.26562-.01367-.53174-.04-.79687-.01074-.10742-.03125-.21094-.0459-.3169-.021-.15283-.03955-.30566-.06933-.45752-.02491-.125-.05957-.24609-.09034-.36914-.03271-.13037-.06152-.26123-.10058-.39013-.03662-.12061-.082-.23682-.124-.355-.04541-.12891-.08838-.25782-.14063-.38477-.04492-.10742-.09766-.21094-.147-.31641-.06153-.13183-.12061-.26464-.18994-.39355-.05176-.09717-.11133-.189-.167-.2832-.07666-.13037-.15087-.26172-.23584-.38819-.0664-.09863-.14062-.1914-.21093-.28662-.084-.11474-.16407-.231-.25537-.3418-.11622-.14111-.2417-.27343-.36719-.40625-.0542-.0581-.10205-.11914-.15869-.17578-.05323-.05322-.11133-.09863-.166-.1499-.13623-.12891-.27246-.25781-.417-.377-.10449-.08545-.21436-.16065-.32178-.24024-.10254-.07617-.20166-.15527-.3081-.22656-.11817-.0791-.24073-.148-.36231-.22021-.10351-.06153-.2041-.12647-.31055-.1836-.11865-.063-.24023-.1167-.36084-.17334-.11669-.05517-.23144-.11328-.35107-.16308-.1123-.04639-.22754-.084-.3418-.125-.13281-.04834-.26367-.09864-.39941-.13965-.10938-.0332-.2207-.05713-.33106-.08545-.14257-.03613-.2832-.07568-.42822-.10449-.12158-.02393-.24414-.0376-.3667-.05567-.13574-.02051-.27-.04541-.4082-.05908-.20215-.01953-.40479-.02637-.60742-.03076C208.11768,40.00781,208.05957,40,208,40H168a8,8,0,0,0,0,16h20.68555L154.34277,90.34277A8,8,0,0,0,160,104Z"/>
                                                        </svg>
                                                    </span>

                                                    <!-- Llamada Entrante - No Conectada -->
                                                    <span class="phone-icon missed-call" *ngIf="phonecall.direction == 'INCOMING' && phonecall.disposition == 'NO_ANSWERED'">
                                                        <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                                            <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.03093,16.03093,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.94874,15.94874,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791Zm-77.21289-73.42187a8.00181,8.00181,0,0,0,11.31446,0L184,83.31445l18.34277,18.34278a8.00053,8.00053,0,0,0,11.31446-11.31446L195.31445,72l18.34278-18.34277a8.00053,8.00053,0,0,0-11.31446-11.31446L184,60.68555,165.65723,42.34277a8.00053,8.00053,0,0,0-11.31446,11.31446L172.68555,72,154.34277,90.34277A8.00122,8.00122,0,0,0,154.34277,101.65723Z"/>
                                                        </svg>
                                                    </span>

                                                    <!-- Llamada Saliente - No Conectada - missed-outgoing-call -->
                                                    <span class="phone-icon missed-call" *ngIf="phonecall.direction == 'OUTGOING' && phonecall.disposition == 'NO_ANSWERED'">
                                                        <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                                            <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.03093,16.03093,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.94874,15.94874,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791Zm-77.21289-73.42187a8.00181,8.00181,0,0,0,11.31446,0L184,83.31445l18.34277,18.34278a8.00053,8.00053,0,0,0,11.31446-11.31446L195.31445,72l18.34278-18.34277a8.00053,8.00053,0,0,0-11.31446-11.31446L184,60.68555,165.65723,42.34277a8.00053,8.00053,0,0,0-11.31446,11.31446L172.68555,72,154.34277,90.34277A8.00122,8.00122,0,0,0,154.34277,101.65723Z"/>
                                                        </svg>
                                                    </span>

                                                    <!-- Llamada - Voicemail -->
                                                    <span class="phone-icon voicemail" *ngIf="phonecall.disposition == 'VOICEMAIL'">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                                                            <path d="M144 320a80 80 0 1 1 0-160 80 80 0 1 1 0 160zm119.8 0c15.3-22.9 24.2-50.4 24.2-80c0-79.5-64.5-144-144-144S0 160.5 0 240s64.5 144 144 144H496c79.5 0 144-64.5 144-144s-64.5-144-144-144s-144 64.5-144 144c0 29.6 8.9 57.1 24.2 80H263.8zM496 160a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/>
                                                        </svg>
                                                    </span>

                                                    <span (click)="numberCall(phonecall.to);" *ngIf="phonecall.direction === 'OUTGOING'" class="phone phone-number" [ngClass]="{'missed-call-phone' : phonecall.disposition == 'NO_ANSWERED'}"> {{phonecall.to | phone}} </span>
                                                    <span (click)="numberCall(phonecall.from);" *ngIf="phonecall.direction === 'INCOMING'" class="phone phone-number" [ngClass]="{'missed-call-phone' : phonecall.disposition == 'NO_ANSWERED'}"> {{phonecall.from | phone}} </span>

                                                </div>
                                            </td>
                                            <td class="mw100">
                                                <div class="call-duration">
                                                    <i class="fa-solid fa-clock"></i>
                                                    <span *ngIf="phonecall.duration"> {{phonecall.duration | time}} </span>
                                                    <span *ngIf="!phonecall.duration">00:00:00</span>
                                                </div>
                                            </td>
                                            <td class="w220">
                                                <ng-container *ngIf="phonecall.recording">
                                                    <ng-container *ngIf="phonecall.direction == 'INCOMING' && phonecall.disposition == 'ANSWERED'">
                                                        <audio src="{{environment.az_api.cdn}}/{{authServices.workspaceSession.id}}/dialer/recording/{{phonecall.recording.display_name}}" controls></audio>
                                                    </ng-container>
                                                    <ng-container *ngIf="phonecall.direction == 'OUTGOING' && phonecall.disposition == 'ANSWERED'">
                                                        <audio src="{{environment.az_api.cdn}}/{{authServices.workspaceSession.id}}/dialer/recording/{{phonecall.recording.display_name}}" controls></audio>
                                                    </ng-container>

                                                    <ng-container *ngIf="phonecall.direction == 'INCOMING' && phonecall.disposition == 'NO_ANSWERED'">
                                                        <audio src="{{environment.az_api.cdn}}/{{authServices.workspaceSession.id}}/dialer/voicemail/{{phonecall.recording.display_name}}" controls></audio>
                                                    </ng-container>
                                                    <ng-container *ngIf="phonecall.disposition == 'VOICEMAIL'">
                                                        <audio src="{{environment.az_api.cdn}}/{{authServices.workspaceSession.id}}/dialer/voicemail/{{phonecall.recording.display_name}}" controls></audio>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <span> {{phonecall.created | dateTime}} </span>
                                            </td>
                                            <td class="d-flex justify-content-center">
                                                <!-- <div class="action-btn-call-logs">
                                                    <button class="btn btn-call-log rounded-circle ">
                                                        <i class="fa-solid fa-phone"></i>
                                                    </button>
                                                    <button class="btn btn-sms-text-log rounded-circle">
                                                        <i class="fa-solid fa-comment"></i>
                                                    </button>
                                                </div> -->
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <app-custom-paginator [currentPage]="paginator.paginator.page"
                                [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page"
                                (pageChange)="setCurrentPage($event)">
                            </app-custom-paginator>
                        </div>

                    </div>
                    <div class="tab-pane fade" id="contacts" role="tabpanel" aria-labelledby="profile-tab2">
                        <p class="">
                            Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
