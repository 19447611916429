import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { File as EntityFile } from 'src/app/entities/global/file';
import { HelperService } from 'src/app/services/helper.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare const FileUploadWithPreview;
declare const jQuery;

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @Input()
    multiple: boolean;

    @Input()
    accept: string;

    @Output()
    onChange: EventEmitter<Array<EntityFile>>;

    instanceId: string;

    files: Array<File>;

    private instanceFileUpload;

    public uploadFile: boolean;

    constructor(
        private uploadFilesService: UploadFilesService,
        private helperService: HelperService
    ) {
        this.multiple = null;
        this.accept = null;
        this.onChange = new EventEmitter<Array<EntityFile>>();
        this.instanceFileUpload = null;
        this.instanceId = this.generateId(10);
        this.files = [];
        this.uploadFile = false;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        // evento para cuando se agregue imagenes
        window.addEventListener("fileUploadWithPreview:imagesAdded", (e) => {
            if (e['detail']['uploadId'] == this.instanceId) {
                this.files = e['detail']['cachedFileArray'];
            }
        });

        // evento para cuando se elimine imagenes
        window.addEventListener("fileUploadWithPreview:imageDeleted", (e) => {
            if (e['detail']['uploadId'] == this.instanceId) {
                this.files = e['detail']['cachedFileArray'];
            }
        });
        // this.instanceFileUpload = new FileUploadWithPreview(this.instanceId);
    }

    private generateId(length: number): string {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    uploadFiles() {
        this.helperService.showLoadingMxpro360();
        this.uploadFilesService
            .send(this.files)
            .then((response) => {

                // creamos el arreglo a devolver
                const tmpFiles: Array<EntityFile> = [];

                // procesamos las imagenes subidas
                for (let index in response['files']) {
                    let fileUploaded = new EntityFile();
                    fileUploaded.display_name = response['files'][index];
                    fileUploaded.name = this.files[index].name;
                    fileUploaded.mime_type = this.files[index].type;

                    // agregamos la imagen subida a la lista para reportar
                    tmpFiles.push(fileUploaded);
                }

                // emitimos el reporte al contenedor padre
                this.onChange.emit(tmpFiles);
                this.helperService.uploadFile.emit(true);
                this.uploadFile = true;

                // limpiamos el component
                // this.instanceFileUpload.clearPreviewPanel();
                this.files = [];

                this.helperService.hideLoadingMxpro360();
            })
            .catch((error) => {
                console.error("error: ", error);
            });
    }
    onSelect(event) {
        this.files.push(...event.addedFiles);
        this.uploadFile = true;
        this.helperService.uploadFile.emit(false)
        this.uploadFiles();
    }
    
    onRemove(event) {
        this.files.splice(this.files.indexOf(event), 1);
    }

}
