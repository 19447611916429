<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Time Clock
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Time Clock</li>
                        </ol>
                    </nav>
                    <br>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <div class="widget-content widget-content-area">
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <label class="control-label">Employee</label>
                        <select name="employee_id" [(ngModel)]="employee.id" id="employee_id" class="form-control">
                            <option value="">All</option>
                            <ng-container *ngFor="let user of employees">
                                <option value="{{user.id}}">
                                    {{user.name | titlecase}}
                                </option>
                            </ng-container>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Select a Employee.
                        </small>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group app-date-picker-clock-time">
                        <label class="control-label">Pay Period</label>
                        <app-date-range-picker [placeholder]="'Not Assigned'" (onChange)="onRange($event)"
                            [valueStart]="betweenFilter.dateStart" [valueEnd]="betweenFilter.dateEnd">
                        </app-date-range-picker>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Select Pay Period.
                        </small>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-end align-self-center pl-5">
                    <button class="btn btn-secondary btn-search" (click)="search()">
                        Search
                    </button>      
                    
                    <a *ngIf="employee.id && listTimeClocks.length == 0" appCompanyRouterLink="/payroll-clock-manual/{{employee.id}}/add">
                        <button class="btn btn-new">
                            Add Record
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <br>

        <div class="item-clock-time" *ngFor="let row of listTimeClocks">
            <div class="widget-content widget-content-area">
                <div class="employee-info">
                    <div class="row">
                        <div class="col-4 form-group mb-3">
                            <label for="hourly_rate" class="control-label">Employee</label>
                            <div class="input-group mb-1">
                                <input type="text" class="form-control" value="{{ row.name | titlecase}}" name="hourly_rate" disabled>
                            </div>
                        </div>
        
                        <div class="col-4 form-group mb-3">
                            <label for="hourly_rate" class="control-label">Position</label>
                            <div class="input-group mb-1">
                                <input type="text" class="form-control" value="{{ row.job_position | filterReplace }}" name="hourly_rate" disabled>
                            </div>
                        </div>

                        <div class="col-4 form-group mb-3">
                                <div class="d-flex justify-content-sm-end justify-content-center">
                                    <a appCompanyRouterLink="/payroll-clock-manual/{{row.employeeId}}/add">
                                        <button class="btn btn-new ">
                                            Add Record
                                        </button>
                                    </a>
                                </div>  
                        </div>

                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Clock In</th>
                                <th>Clock Out</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let time_clock of row.time_clock">
                                <td>
                                    {{ time_clock.date | date }}
                                </td>
                                <td class="clock-in">
                                    <i class="fa-solid fa-clock mr-2"></i>
                                    {{ time_clock.clock_in | timeFromDate }}
                                </td>
                                <td class="clock-out">
                                    <i class="fa-solid fa-clock mr-2"></i>
                                    {{ time_clock.clock_out | timeFromDate }}
                                </td>
                                <td>
                                    <a class="btn btn-success" (click)="openEditClockTimeModal(time_clock, row.name)">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br>
        </div>

    </div>

</div>


<!-- Modal - Edit Clock Time -->
<div class="modal fade" #editClockTimeModal id="editClockTimeModal" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Edit Time Clock                   
                </h5>

            </div>
            <div class="modal-body">   

                <div class="row">

                    <div class="col-12 form-group mb-3">
                        <label for="employee_name" class="control-label">Employee</label>
                        <div class="input-group mb-1">
                            <input type="text" class="form-control" id="employee_name" value="{{nameEmployee | titlecase}}" name="employee_name" disabled>
                        </div>
                    </div>

                    <div class="col-6 form-group current-clock-in mb-3">
                        <label for="current_clock_in" class="control-label">Current Clock In</label>
                        <div class="input-group mb-1">
                            <input type="text" class="form-control" id="current_clock_in" value="{{timeClock.clock_in | timeFromDate}}" name="current_clock_in" disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                        </div>
                    </div>

                    <div class="col-6 form-group current-clock-out mb-3">
                        <label for="current_clock_out" class="control-label">Current Clock Out</label>
                        <div class="input-group mb-1">
                            <input type="text" class="form-control" id="current_clock_out" value="{{timeClock.clock_out | timeFromDate}}" name="current_clock_out" disabled>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                        </div>
                    </div>                    

                </div>
                
                <div class="row edit-time-clock mt-4">

                    <div class="col-6 form-group mb-3">
                        <label for="timeClock_time_in" class="control-label">New Clock In</label>
                        <div class="input-group mb-1">
                            <input type="time" class="form-control" name="timeClock_time_in" onfocus="this.showPicker()" [(ngModel)]="timeClock.time_in" id="timeClock_time_in">
                        </div>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label for="timeClock_time_out" class="control-label">New Clock Out</label>
                        <div class="input-group mb-1">
                            <input type="time" class="form-control" name="timeClock_time_out"  onfocus="this.showPicker()" [(ngModel)]="timeClock.time_out" id="timeClock_time_out">
                        </div>
                    </div>

                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" type="button"  data-dismiss="modal" (click)="saveTimeClock()">
                    Update
                </button>
            </div>
        </div>
    </div>
 </div>