import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';
import { ZonesService } from './zones.service';
import { Zone } from "src/app/entities/workspace/zone";
import { ZoneStatesService } from './zone-states.service';
import { ZoneState } from 'src/app/entities/workspace/zone-state';
import { VolumesService } from './volumes.service';
import { Volume } from 'src/app/entities/workspace/volume';
import { PeriodsService } from './periods.service';
import { Period } from 'src/app/entities/workspace/period';
import { TariffVersionService } from '../tariff-version.service';


@Injectable({
  providedIn: 'root'
})
export class TariffVersionsSettingsService {

  public zones: Zone[];
  public zoneState: ZoneState[];
  public volumes: Volume[];
  public periods: Period[];

  constructor(
    private zonesService: ZonesService,
    private zoneStatesService: ZoneStatesService,
    private helperService: HelperService,
    private volumesService: VolumesService,
    private periodsService: PeriodsService,
    public tariffVersionFactory: TariffVersionService
  ) { 
    this.zones = [];
    this.zoneState = [];
    this.volumes = [];
    this.periods = [];
  }

  getZones(){
    this.helperService.showLoadingMxpro360();
    this.zonesService
    .getAllByTariffVersionId(this.tariffVersionFactory.getTariffVersionId())
    .then((response) => {
        this.zones = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }

  getAllWithFullView(tariffVersionId) {
    this.helperService.showLoadingMxpro360();
    this.zoneStatesService
    .getAllWithFullViewByTariffVersionId(tariffVersionId)
    .then((response) => {
      this.zoneState = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }

  getAllVolumesByTariffVersionId(tariffVersionId){
    this.helperService.showLoadingMxpro360();
    this.volumesService
    .getAllByTariffVersionId(this.tariffVersionFactory.getTariffVersionId())
    .then((response) => {
      this.volumes = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }

  getAllPeriodsByTariffVersionId(tariffVersionId){
    this.helperService.showLoadingMxpro360();
    this.periodsService
    .getAllPeriodsByTariffVersionId(this.tariffVersionFactory.getTariffVersionId())
    .then((response) => {
      this.periods = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }


}
