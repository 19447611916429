import { Component, OnInit } from '@angular/core';
import { PaymentGatewaySettingsService } from 'src/app/services/companies/payment-gateway-settings.service';
import { PaymentGatewaySetting } from "src/app/entities/workspace/payment-gateway-setting";
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-payment-gateway-settings',
    templateUrl: './payment-gateway-settings.component.html',
    styleUrls: ['./payment-gateway-settings.component.scss']
})
export class PaymentGatewaySettingsComponent implements OnInit {

    rows: Array<PaymentGatewaySetting>;
    actualPage = 1;
    filterSearch = '';

    constructor(
        private paymentGatewaySettingsService: PaymentGatewaySettingsService,
        private helperService: HelperService
    ) {
        this.rows = [];
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void{
        this.load();
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.paymentGatewaySettingsService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.paymentGatewaySettingsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'Record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    toggle(id: string, paymentType: string) {
        this.helperService.showLoadingMxpro360();
        this.paymentGatewaySettingsService
            .toggle(id, paymentType)
            .then(() => {
                this.load();
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    disabledAch(id: string, paymentType: string) {
        this.helperService.showLoadingMxpro360();
        this.paymentGatewaySettingsService
            .disabledAch(id, paymentType)
            .then(() => {
                this.load();
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * setea un gateay setting como disponible para pagos de storages
     * @param rowId id de la fila que se va a enviar a activar o desactivar
     */
    toggleStorage(rowId: string) {
        this.helperService.showLoadingMxpro360();
        // filtra la fila del id
        const selectedGatewaySetting = this.rows.find((method) => method.id == rowId);
        this.paymentGatewaySettingsService
            .setStrageCC(rowId, selectedGatewaySetting.storage_cc)
            .then(() => {
                this.load();
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
}
