import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
    selector: 'app-sales-leader',
    templateUrl: './sales-leader.component.html',
    styleUrls: ['./sales-leader.component.scss']
})
export class SalesLeaderComponent implements OnInit {
    jobsSalesLeader: any;
    @Input()
    companyId: boolean = false;
    colorGradient: Array<string>;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.jobsSalesLeader = [];
        this.colorGradient = ['linear-gradient(90deg, rgba(6,112,101,1) 0%, rgba(46,215,229,1) 100%)', 
                          'linear-gradient(90deg, rgba(245,15,45,1) 0%, rgba(250,155,76,1) 100%)', 
                          'linear-gradient(90deg, rgba(104,0,129,1) 0%, rgba(255,0,181,1) 100%)',
                          'linear-gradient(90deg, rgba(255,55,0,1) 0%, rgba(255,207,0,1) 100%)',
                          'linear-gradient(90deg, rgba(0,53,95,1) 0%, rgba(0,157,250,1) 100%)',
                          'linear-gradient(90deg, rgba(32,191,85,1) 0%, rgba(1,186,239,1) 100%)',
                          'linear-gradient(90deg, rgba(107,15,26,1) 0%, rgba(185,19,114,1) 100%)',
                          'linear-gradient(90deg, rgba(0,183,18,1) 0%, rgba(90,255,21,1) 100%)',
                          'linear-gradient(90deg, rgba(4,93,233,1) 0%, rgba(9,198,249,1) 100%)',
                          'linear-gradient(90deg, rgba(251,176,52,1) 0%, rgba(255,221,0,1) 100%)'                        
                        ];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.loadSalesLeaderCompany();
        } else {
            this.loadSalesLeaderWorkspace();
        }
    }

    loadSalesLeaderWorkspace() {
        this.dashboardWorkspaceService
            .getSalesLeader()
            .then((response) => {
                this.jobsSalesLeader = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    loadSalesLeaderCompany() {
        this.dashboardComopanyService
            .getSalesLeader()
            .then((response) => {
                this.jobsSalesLeader = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }
}
