import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/entities/workspace/employee';
import { Workspace } from 'src/app/entities/admin/workspace';
import { AuthService } from 'src/app/services/auth.service';
import { HttpCancelService } from 'src/app/http-cancel.service';

@Component({
    selector: 'app-dashboard-workspace',
    templateUrl: './dashboard-workspace.component.html',
    styleUrls: ['./dashboard-workspace.component.scss']
})
export class DashboardWorkspaceComponent implements OnInit {

    dateToday: number;
    employee: Employee;
    workspace: Workspace;
    jobPosition: string;

    constructor(
        public authService: AuthService,
        private htttpCancelService: HttpCancelService
    ) {
        this.dateToday = Date.now();
        this.employee = null;
        this.workspace = null;
        this.jobPosition = null;
    }

    ngOnInit(): void {

        let workspace = this.authService.getWorkspaceSession();
        this.jobPosition = workspace.employee.job_position;
    }

    ngOnDestroy() {
        this.htttpCancelService.cancelPendingRequest();
    }

    ngAfterContentInit(): void {
        this.employee = this.authService.workspaceSession.employee;
        this.workspace = this.authService.workspaceSession.workspace;
    }

}
