import { Component, OnInit } from '@angular/core';
import { DispatchContacts } from 'src/app/entities/workspace/dispatch-contacts';
import { HelperService } from 'src/app/services/helper.service';
import { DispatchContactsService } from 'src/app/services/workspaces/dispatch-contacts.service';

declare var jQuery;
declare var swal;
@Component({
  selector: 'app-dispatch-contacts',
  templateUrl: './dispatch-contacts.component.html',
  styleUrls: ['./dispatch-contacts.component.scss']
})
export class DispatchContactsComponent implements OnInit {

  public rows: DispatchContacts[];
  public actualPage: number ;
  public filterSearch: string;

  constructor(
    private dispatchContactsService: DispatchContactsService,
    private helperService: HelperService
  ) {
    this.actualPage = 1;
    this.filterSearch = '';
    this.rows = [];
  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.load();
  }


  private load() {
    this.helperService.showLoadingMxpro360();
    this.dispatchContactsService
      .getAll()
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.dispatchContactsService
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Deleted!',
                'The record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }
}
