<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-4">
                <strong>Pickup Zone: </strong>
                <select name="zone_out" id="zone_out" [(ngModel)]="zoneOutId" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let zone of zonesSelect" [value]="zone.id">{{zone.name}}</option>
                </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-4">
                <strong>Period</strong>
                <select id="period_field" [(ngModel)]="periodId" name="wp_period" class="form-control">
                    <option *ngFor="let period of periods" value="{{period.id}}">{{period.name}}
                    </option>
                </select>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 mb-4">
                <button class="btn btn-main-action" style="margin-top: 23px;" (click)="loadData(true)">
                    Filter
                </button>
            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">

            <div class="widget-content widget-content-area">
                <div class="row form">
                    <div class="col-lg-{{sizeMap}}">
                        <div class="map">
                            <div id="map" #mapView class="map"></div>
                        </div>
                        <div class="content">
                        </div>
                    </div>
                    <div class="col-lg-3" [hidden]="sizeMap == 12">
                        <div>
                            <button type="button" class="btn btn-danger margin-botton-16" (click)="setShapesOnMap(null); sizeMap = 12">X</button>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="box-space-between">
                                    <div class="text-bold">
                                        Pickup Zone:
                                    </div>
                                    <div>
                                        {{zoneOut?.name}}
                                    </div>
                                </div>
                                <div class="box-space-between">
                                    <div class="text-bold">
                                        Delivery Zone:
                                    </div>
                                    <div>
                                        {{routesRates[0]?.zone_in?.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col">
                                <div class="box-space-between">
                                    <div class="text-bold">Min Of Cubic Feets</div>
                                    <div> {{routesRates[0]?.min_cf}} CF</div>
                                </div>
                                <div class="box-space-between">
                                    <div class="text-bold">Discount By CF</div>
                                    <div> {{routesRates[0]?.cf_discount | currency}}</div>
                                </div>
                                <div class="box-space-between">
                                    <div class="text-bold">Packing Cost By CF</div>
                                    <div> {{routesRates[0]?.packing_cost | currency}}</div>
                                </div>
                                <div class="box-space-between">
                                    <div class="text-bold">Packing Discount By CF</div>
                                    <div> {{routesRates[0]?.packing_discount | currency}}</div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered mb-4">
                                        <thead>
                                            <tr>
                                                <th colspan="2" class="text-center">RATES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let volumeRate of routesRates[0]?.volume_rates">
                                                <td>Up to {{volumeRate?.name}}:</td>
                                                <td class="text-right"> {{volumeRate.cf_cost | currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
