export class RouteVolumeRate {
    id: string;
    volume_id: string;
    route_rate_id: string;
    cf_cost: number;
    created: number;
    updated: number;

     constructor(){
        this.id = null;
        this.volume_id = null;
        this.route_rate_id = null;
        this.cf_cost = null;
        this.created = null;
        this.updated = null;
     }
}
