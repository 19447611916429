import { Injectable } from '@angular/core';
import { TariffVersion } from '../entities/workspace/tariff-version';
import { TariffVersionsService } from './workspaces/tariff-versions.service';

@Injectable({
  providedIn: 'root'
})
export class TariffVersionService {

  public tariffVersion: TariffVersion
  constructor(
    private tariffVersionsService: TariffVersionsService
  ) { }

  /**
   * guarda el id de la version selecionada en settigns tariff version
   * @param tariffVersionId 
   */
  setTariffVersionId(tariffVersionId) {
    localStorage.setItem('settignsTariffVersionId', JSON.stringify(tariffVersionId));
  }

  /**
   * Trae el id de la version selecionada en settigns tariff version
   * @returns 
   */
  getTariffVersionId() {
    let currentTariffVersion = JSON.parse(localStorage.getItem('settignsTariffVersionId'));
    if (!currentTariffVersion) {
      this.tariffVersionsService.getAll().then(
          (response) => {
              for (const tariffVersion of response) {
                  if (tariffVersion.is_active) {
                      this.setTariffVersionActive(tariffVersion);
                      currentTariffVersion = tariffVersion; 
                      break;
                  } else {
                      this.removeTariffVersionActive();
                  }
              }
          }
      );
    }
    return currentTariffVersion;
  }

  /**
   * Guarda la version activa en el sistema
   * @param tariffVersion 
   */
  setTariffVersionActive(tariffVersion) {
    this.tariffVersion = tariffVersion;
    localStorage.setItem('tariffVersionActive', JSON.stringify(tariffVersion));
  }

  /**
   * Remueve la version activa en el sistema
   * @param tariffVersionId 
   */
  removeTariffVersionActive() {
    localStorage.removeItem('tariffVersionActive');
  }

  /**
   * Trae el id de la version Activa en el sistema
   * @returns 
   */
  getTariffVersionActive() {
    this.tariffVersion = JSON.parse(localStorage.getItem('tariffVersionActive'));
    return this.tariffVersion;
  }
}
