<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <div class="chat-system">
            <div class="hamburger"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-menu mail-menu d-lg-none">
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg></div>
            <div class="user-list-box">
                <div class="search">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                    <input type="text" class="form-control" placeholder="Search" /> -->
                    <app-search-address label="Search Address" [useCustomAddress]="false"
                        (onChange)="getAddress($event)">
                    </app-search-address>
                    <div class="form-group" style="margin-top: 12px;">
                        <label class="control-label" style="color: #6e6d6d">Service Type: </label>
                        <select (change)="validateAddresToSendRequest()" class="form-control"
                            [(ngModel)]="coverageType">
                            <option value="DELIVERY">Delivery</option>
                            <option value="PICKUP">Pickup</option>
                        </select>
                    </div>
                </div>
                <div class="people">
                    <div class="person" data-chat="person1" *ngFor="let carrierCoverage of carrierCoverageList"
                        (click)="highlightZone(carrierCoverage)">
                        <div class="user-info">
                            <div class="f-head">
                                <img src="{{carrierCoverage.carrier.logo}}" alt="avatar">
                            </div>
                            <div class="f-body">
                                <div class="meta-info">
                                    <span class="user-name"
                                        data-name="Sean Freeman">{{carrierCoverage.carrier.name}}</span>
                                </div>
                                <span class="preview">State: {{carrierCoverage.state}}</span>
                                <span class="preview" *ngIf="carrierCoverage.zip_code">Zip Code:
                                    {{carrierCoverage.zip_code}}, Radius: {{carrierCoverage.radius}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-box">
                <div id="map" #mapView class="map"></div>
            </div>
        </div>
        <!-- <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3> Carriers Coverages Map
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item">Carriers Coverages Map</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
        
            </div>
        </div> -->
        <!-- header -->

        <!-- <div class="statbox widget box box-shadow">

            <div class="widget-content widget-content-area">
                <div class="row form">
                    <div class="map" [hidden]="false">
                        <div id="map" #mapView class="map"></div>
                    </div>
                    <div class="content">
                        <div class="d-flex justify-content-sm-end justify-content-center">
                            <app-search-address label="Search Address" [useCustomAddress]="false"
                                (onChange)="getAddress($event)">
                            </app-search-address>
                        </div>
                        
                        <div class="row">
                            <div class="period">
                                <label for="period_field">Carriers In Coverage</label>
                                <select id="period_field" (change)="getCoverageRadius()" [(ngModel)]="carrierCoverageSelected" name="wp_period">
                                    <option *ngFor="let carrierCoverage of carrierCoverageList"
                                        value="{{carrierCoverage.carrier.id}}">{{carrierCoverage.carrier.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>

<!-- Modal for show the basic information about the workspace -->
<div class="modal fade" id="carrierInformation" #carrierInformation tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="max-width: 660px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Carrier Information
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row" *ngIf="carrier != null">
                            <div class="col-md-12">
                                <ul class="list-group">
                                    <li class="list-group-item"><b>Name:</b> {{ carrier.name }}</li>
                                    <li class="list-group-item"><b>Address:</b> {{ carrier.address.full_address }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg> Discard</button>
            </div>
        </div>
    </div>
</div>