import { Address } from "../global/address";

export class ClockLocations {

    public id: string;
    public name: number;
    public location: Address;
    public allowed_ip_address: string[]
    public radius: number;
    public company_id: boolean;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.name = null;
        this.location = new Address();
        this.allowed_ip_address = [];
        this.radius = null;
        this.company_id = null;
    }
}
