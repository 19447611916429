export class Account {

    public id: string;
    public name: string;
    public email: string;
    public phone: string;
    public password: string;
    public workspace_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.password = null;
        this.workspace_id = null;
        this.created = null;
        this.updated = null;
    }
}
