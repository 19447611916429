import { Document } from './document';
export class CarrierCandidate {
    public id: string;
    public carrier_id: string;
    public comments: string;
    public bid: number;
    public board_job_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.carrier_id = null;
        this.comments = null;
        this.bid = null;
        this.board_job_id = null;
        this.created = null;
        this.updated = null;
    }
}
