<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Balances
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>

                    <li class="breadcrumb-item active" aria-current="page">
                        Balances
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>
    <!-- header -->

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
            
            <div class="row col-lg-12 col-md-12 block-filter mb-1">
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label>Concept</label>
                    <select name="level" id="state" [(ngModel)]="concept" class="form-control"  (change)="load()">
                        <option  value="">All Default</option>
                        <option  value="Deposit">Deposit</option>
                        <option  value="Pick up payment">Pick up payment</option>
                        <option  value="Delivery payment">Delivery payment</option>
                        <option  value="Storage payment">Storage payment</option>
                        <option  value="Refund">Refund</option>
                        <option  value="Misc payment">Misc payment</option>
                   </select>
                </div>  
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label>Payment Method</label>
                    <select name="level" id="payment_method" [(ngModel)]="payment_method" class="form-control"  (change)="load()">
                        <option  value="">All</option>
                        <option *ngFor="let paymentMethod of paymentMethods" value="{{paymentMethod.id}}">{{paymentMethod.method}} - {{paymentMethod.name}}</option>
                   </select>
                </div>
                <div class="col-lg-4 col-xl-3 pl-0 app-date-picker-balances">
                    <label>Date</label>
                    <div>
                        <app-date-range-picker [placeholder]="'Not Assigned'"
                            (onChange)="setDates($event)" theme="WIDGET" >
                        </app-date-range-picker>
                    </div>
                </div>                
            </div>

            <div class="row col-lg-12 col-md-12 block-filter filter-bar mt-4 mb-3">

                <div class="toggle-switch">                
                    <label class="switch s-icons s-outline  s-outline-secondary">
                        <input type="checkbox" id="checkPendingReview" [(ngModel)]="checkPendingReview" name="checkPendingReview" (change)="load()">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="checkPendingReview" class="checkbox-label" [ngClass]="{'filter-disabled': !checkPendingReview}">
                        Pending Review
                    </label>
                </div>

                <div class="toggle-switch">                
                    <label class="switch s-icons s-outline  s-outline-secondary">
                        <input type="checkbox" id="checkProcessing" [(ngModel)]="checkProcessing" name="checkProcessing" (change)="load()">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="checkProcessing" class="checkbox-label" [ngClass]="{'filter-disabled': !checkProcessing}">
                        Processing
                    </label>
                </div>

                <div class="toggle-switch">                
                    <label class="switch s-icons s-outline  s-outline-secondary">
                        <input type="checkbox" id="checkPending" [(ngModel)]="checkPending" name="checkPending" (change)="load()">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="checkPending" class="checkbox-label" [ngClass]="{'filter-disabled': !checkPending}">
                        Pending
                    </label>
                </div>

                <div class="toggle-switch">                
                    <label class="switch s-icons s-outline  s-outline-secondary">
                        <input type="checkbox" id="checkPaid" [(ngModel)]="checkPaid" name="checkPaid" (change)="load()">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="checkPaid" class="checkbox-label" [ngClass]="{'filter-disabled': !checkPaid}">
                        Pay
                    </label>
                </div>
                
            </div>

            <!-- <div class="toggle-switch">                
               <select name="level" id="state" [(ngModel)]="concept" class="form-control"  (change)="load()">
                    <option  value="">All Default</option>
                    <option  value="Deposit">Deposit</option>
                    <option  value="Pick up payment">Pick up payment</option>
                    <option  value="Delivery payment">Delivery payment</option>
                    <option  value="Storage payment">Storage payment</option>
                    <option  value="Refund">Refund</option>
                    <option  value="Misc payment">Misc payment</option>
               </select>
               <label for="state">
                    &nbsp;state
                </label>
            </div>

            <div class="toggle-switch">                
                <select name="level" id="payment_method" [(ngModel)]="payment_method" class="form-control"  (change)="load()">
                     <option  value="">All</option>
                     <option *ngFor="let paymentMethod of paymentMethods" value="{{paymentMethod.id}}">{{paymentMethod.method}} - {{paymentMethod.name}}</option>
                     <option  value="Pick up payment">Pick up payment</option>
                     <option  value="Delivery payment">Delivery payment</option>
                     <option  value="Storage payment">Storage payment</option>
                     <option  value="Refund">Refund</option>
                     <option  value="Misc payment">Misc payment</option>
                </select>

               <label for="payment_method">
                    &nbsp;Concept
                </label>
             </div>

            <div class="toggle-switch app-date-picker-estimate-detail">                
                <app-date-range-picker [placeholder]="'Not Assigned'"
                    (onChange)="setDates($event)" theme="WIDGET" >
                </app-date-range-picker>
            </div> -->
        
        
    </div>

    <div class="col-lg-12 col-md-12 label-block text-center">
        <div class="label-block-autotransport">
            <div class="box"></div>
            <div>Auto Transport Estimate</div>        
        </div>        
    </div>

    <!-- Table -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered mb-4 table-hover">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Job Number</th>
                                <th>Concept</th>
                                <th>Payment Method</th>
                                <th>Amount</th>
                                <th class="text-center">Status</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of balances |search:filterSearch | async" [ngClass]="{'auto-transport-estimate': row.estimate_service == 'AUTO_TRANSPORT'}">
                                <td>
                                    <strong>
                                        {{row.customer?.name | titlecase}} {{row.customer?.last_name | titlecase}}
                                    </strong> 
                                    <br>                                   
                                     {{row.created | dateTime}}
                                </td>
                                <td>
                                    {{row.estimate_document?.code}}
                                </td>
                                <td>
                                    {{row.concept}}
                                </td>
                                <td>
                                    {{row.payment_method?.method}} - {{row.payment_method?.name}}
                                </td>
                                <td>
                                    {{row.amount | currency}}
                                </td>

                                <td class="text-center">
                                    <span *ngIf="row.status === 'PROCESSING'" class="badge badge-warning">
                                        PROCESSING
                                    </span>
                                    <span *ngIf="row.status === 'PAID'" class="badge badge-success">
                                        PAID
                                    </span>
                                    <span *ngIf="row.status === 'PENDING'" class="badge badge-danger">
                                        PENDING
                                    </span>
                                    <span *ngIf="row.status === 'PENDING_REVIEW'" class="badge badge-info">
                                        PENDING REVIEW
                                    </span>
                                </td>

                                <td>
                                    <a appCompanyRouterLink="/payments/{{row.id}}/view" class="btn btn-info">
                                        <i class="fa-solid fa-eye"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls> -->
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
