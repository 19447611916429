import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';


declare var ApexCharts;

@Component({
    selector: 'app-total-bad-leads',
    templateUrl: './total-bad-leads.component.html',
    styleUrls: ['./total-bad-leads.component.scss']
})
export class TotalBadLeadsComponent implements OnInit {
    jobsTotalBadLeads: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.jobsTotalBadLeads = {
            "total": 0
        };
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.loadTotalBadLeadsCompany();
        } else {
            this.loadTotalBadLeadsWorkspace();
        }
    }

    loadTotalBadLeadsWorkspace() {

        this.dashboardWorkspaceService
            .getTotalBadLeads()
            .then((response) => {
                this.jobsTotalBadLeads = response;
                setTimeout(() => {
                    this.totalBadLeads();
                }, 600);
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    loadTotalBadLeadsCompany() {

        this.dashboardComopanyService
            .getTotalBadLeads()
            .then((response) => {
                this.jobsTotalBadLeads = response;
                setTimeout(() => {
                    this.totalBadLeads();
                }, 600);
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }




    totalBadLeads() {

        const that = this;
      
        var options = {
          chart: {
            id: 'sparkline1',
            group: 'sparklines',
            type: 'area',
            height: 190,
            sparkline: {
              enabled: true
            },
          },
          stroke: {
              curve: 'smooth',
              width: 2
          },
          fill: {
            type:"gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                inverseColors: !1,
                opacityFrom: .80,
                opacityTo: .5,
                stops: [100, 100]
            }
          },
          series: [{
            name: 'Leads',
            data: this.jobsTotalBadLeads['arrayTotals']
          }],
          labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
          yaxis: {
            min: 0
          },
          grid: {
            padding: {
              top: 125,
              right: 0,
              bottom: 0,
              left: 0
            }, 
          },
          tooltip: {
            x: {
              show: false,
            },
            theme: 'dark'
          },
          colors: ['#ffffff']
        }
      
      
        var chart = new ApexCharts(
            document.querySelector("#total-bad-leads"),
            options
        );
      
        chart.render();
    }


}
