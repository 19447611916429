import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-jobs-picked-today',
  templateUrl: './jobs-picked-today.component.html',
  styleUrls: ['./jobs-picked-today.component.scss']
})
export class JobsPickedTodayComponent implements OnInit {
    @Input()
    companyId: boolean = false;
    pickedUpTodays: any;

  constructor(
    private dashboardWorkspaceService: DashboardWorkspaceService,
    private dashboardComopanyService: DashboardComopanyService,
  ) {
    this.pickedUpTodays = [];

   }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadPickedUpTodaysCompany();
    } else {
        this.loadPickedUpTodaysWorkspace();
    }
  }

  
  loadPickedUpTodaysWorkspace(){
        
    this.dashboardWorkspaceService
        .getpickedUpTodays()
        .then((response) => {
            this.pickedUpTodays = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
loadPickedUpTodaysCompany(){
        
    this.dashboardComopanyService
        .getpickedUpTodays()
        .then((response) => {
            this.pickedUpTodays = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

}
