import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Customer } from 'src/app/entities/workspace/customer';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { LeadsService } from 'src/app/services/leads.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeadCompanyView } from 'src/app/entities/workspace/lead-company-view';



declare const jQuery;
declare const swal;
@Component({
    selector: 'app-leads-form',
    templateUrl: './leads-form.component.html',
    styleUrls: ['./leads-form.component.scss']
})
export class LeadsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: LeadCompanyView;
    rowsCustomerDepartment: Array<Customer>;
    rowsWorkDepartment: Array<WorkDepartment>;
    customer: Customer;

    constructor(
        private leadsService: LeadsService,
        public router: Router,
        private customersService: CustomersService,
        private currentRoute: ActivatedRoute,
        private workDepartmentsService: WorkDepartmentsService,
        private helperService: HelperService
    ) {
        this.customer = new Customer();
        this.dataForm = new LeadCompanyView();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.helperService.showLoadingMxpro360();
        this.dataForm = new LeadCompanyView();
        this.customersService
            .getAll()
            .then((response) => {
                this.rowsCustomerDepartment = response;
            })
            .catch((error) => {
                this.helperService.hideLoadingMxpro360();
            });


        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.rowsWorkDepartment = response;
            })
            .catch((error) => {

            });

        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.leadsService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                this.customer = this.dataForm.lead.customer;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        const dataToSave: any = this.dataForm;
        if (this.dataForm.id !== null) {
            dataToSave.customer = this.customer;
        }
        this.helperService.showLoadingMxpro360();
        this.leadsService
            .save(dataToSave)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/leads');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeDate(date: number) {
        this.dataForm.lead.move_date = date;
    }

}
