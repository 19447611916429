<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                Company Salesman Performs
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Company Salesman Performs</li>
                </ol>
            </nav>
            <br>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>                   
        </div>
        <div class="row col-lg-12 col-md-12 block-search">
            <div class="col-lg-3 col-xl-4">

            </div>
            <div class="col-lg-4 col-xl-3">
                <label>Service</label>
                <select name="month" [(ngModel)]="typeService" class="form-control">
                    <option value="AUTO_TRANSPORT"> Auto Transport</option>
                    <option value="MOVING"> Moving </option>
                </select>
            </div>
            <div class="col-4">
                <button class="btn btn-warning btn-search" (click)="search()">
                    Search
                </button>
            </div>          
            <div class="col-1">

            </div>  
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Companies</th>
                                <th>Total Leads</th>
                                <th>Total Quotes</th>
                                <th>Emails Sent</th>
                                <th>Quotes Sent</th>
                                <th># Booked</th>
                                <th>$ Booked</th>
                                <th>Customer Payments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let salesmanPerform of salesmanPerforms">
                                <td>{{salesmanPerform.name}}</td>
                                <td>{{salesmanPerform.total_leads}}</td>
                                <td style="cursor: pointer;" (click)="getTotalQuotes(salesmanPerform.id, false)">
                                    {{salesmanPerform.total_quotes}}</td>
                                <td style="cursor: pointer;" (click)="getEmailSent(salesmanPerform.id, false)">
                                    {{salesmanPerform.emails_sent}}</td>
                                <td style="cursor: pointer;" (click)="getQuotesSent(salesmanPerform.id, false)">
                                    {{salesmanPerform.quotes_sent}}</td>
                                <td style="cursor: pointer;" (click)="getQuotesBooked(salesmanPerform.id, false)">
                                    {{salesmanPerform.quotes_booked}}</td>
                                <td>{{salesmanPerform.amount_quotes_booked | currency}}</td>
                                <td style="cursor: pointer;" (click)="getCustomerPayments(salesmanPerform.id, false)">
                                    {{salesmanPerform.customer_payments | currency}}</td>
                            </tr>
                            <tr style="font-weight: bolder;" *ngIf="salesmanPerforms.length > 0">
                                <td>Total</td>
                                <td >{{totalLeads}} </td>
                                <td style="cursor: pointer;" (click)="getTotalQuotes(null, true)">{{totalQuotes}} </td>
                                <td style="cursor: pointer;" (click)="getEmailSent(null, true)">{{totalEmailsSent}}
                                </td>
                                <td style="cursor: pointer;" (click)="getQuotesSent(null, true)">{{totalQuotesSent}}
                                </td>
                                <td style="cursor: pointer;" (click)="getQuotesBooked(null, true)">{{totalQuotesBooked}}
                                </td>
                                <td>{{totalAmountQuotesBooked | currency}}</td>
                                <td>{{totalCustomerPayments | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- Modal carrier note -->
<div class="modal fade" #modal tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog max-width-modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title ml-3" id="newConceptTitle">
                    {{titleModal | titlecase }}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">

                <div class="col-md-12 mt-4 carrier--type-notes">
                    <div class="list-title">
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Pickup Date</th>
                                    <th>Created</th>
                                    <th>To</th>
                                    <th>From</th>
                                    <th>Amount Quotes Booked</th>
                                    <th>Customer Payments</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of salesmanPerformsCustom">
                                    <td>{{row.document.code}}</td>

                                    <td>{{row.pickup.range_start | date}} to {{row.pickup.range_start | date}}</td>
                                    <td>{{row.created | date}}</td>
                                    <td>{{row.pickup.address.state}} {{row.pickup.address.zip_code}}</td>
                                    <td>{{row.delivery.address.state}} {{row.delivery.address.zip_code}}</td>
                                    <td>{{row.total | currency}}</td>
                                    <td>{{row.total_payments | currency}}</td>
                                    <td>
                                        <button class="btn btn-info" (click)="openNewTab(row)">
                                            <i class="far fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn mr-4" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal carrier note -->