<div class="layout-top-spacing">


    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="material.id == null">
                    New
                </span>
                <span *ngIf="material.id != null">
                    Update
                </span> Package Materials
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/packing-materials">Package Materials</a></li>
                    <li *ngIf="material.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="material.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>


        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">
                <!-- <div class="widget-header">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                            <h4>Add Packing Material</h4>
                        </div>
                    </div>
                </div> -->
                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="form-group mb-4">
                            <label class="control-label">Name</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg></span>
                                </div>
                                <input type="text" [(ngModel)]="material.name" name="wd_name" class="form-control" required>
                            </div>

                            <small id="nameCustomer" class="form-text text-muted mb-4">Package Materials Name.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label class="control-label">Price</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                </div>
                                <input type="number" step="0.01" ng-step="0.01" [(ngModel)]="material.price" name="wd_price_local" class="form-control" required>
                            </div>

                            <small id="nameCustomer" class="form-text text-muted mb-4">Package Materials Price.</small>
                        </div>

                        <div class="form-group mb-4" id="service_type_package_materials">
                            <label class="control-label">Service Type</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><i class="fa-solid fa-boxes-packing fa-xl"></i></span>
                                </div>
                                <select placeholder="Not Assigned" multiple="multiple"
                                        [(ngModel)]="material.service_type" 
                                        name="wd_service_type" 
                                        class="form-control service-type select-service-type" required>
                                    <option value="LONG_DISTANCE">Long Distance</option>
                                    <option value="LOCAL_DISTANCE">Local Distance</option>
                                    <option value="AUTO_TRANSPORT">Auto Transport</option>
                                    <option value="FREIGHT_TRANSPORT">Freight Transport</option>
                                </select>
                            </div>

                            <small id="nameCustomer" class="form-text text-muted mb-4">Package Materials Type Service.</small>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-save ml-1 mt-3" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>


</div>
