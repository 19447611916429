export class DispatchContacts {

    id: string;
    name: string;
    phone: string;
    email: string;
    created: number;
    updated: number;

    constructor(){
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.created = null;
        this.updated = null;
     }
}
