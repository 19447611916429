import { Component, OnInit } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { ClaimView } from 'src/app/entities/workspace/claim-view';
import { ClaimsService } from 'src/app/services/companies/claims.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.scss']
})
export class ClaimsListComponent implements OnInit {

  private betweenFilter: BetweenDates;
  public actualPage: number;
  public allocation: 'ALL' | 'IN_HOUSE' | 'CARRIER';
  public claimStatusArray = ['ALL', 'OPEN', 'PENDING', 'RESOLVED', 'WAITING_COSTUMER', 'CLOSED'];
  public filterDateFor: 'OPEN_DATE' | 'MOVE_DATE';
  public filterSearch: string;
  public paginator: Paginator;
  public rows: ClaimView[];
  public statusclaim: 'ALL' | 'OPEN' | 'PENDING' | 'RESOLVED' | 'WAITING_COSTUMER' | 'CLOSED';
  constructor(
    private claimsService: ClaimsService,
    private helperService: HelperService
  ) {
    this.actualPage = 1;
    this.allocation = 'ALL';
    this.betweenFilter = new BetweenDates();
    this.filterDateFor = 'OPEN_DATE';
    this.filterSearch = '';
    this.paginator = new Paginator();
    this.rows = [];
    this.statusclaim = 'ALL';
  }

  ngOnInit(): void {}

  loadClaims(fromSearch: boolean) {
    const data = {
      ...this.betweenFilter,
      actualPage: this.actualPage,
      allocation: this.allocation,
      end: this.betweenFilter.dateEnd,
      filterDateFor: this.filterDateFor,
      start: this.betweenFilter.dateStart,
      status: this.statusclaim
    }
    this.paginator.paginator.start = this.betweenFilter.dateStart;
    this.paginator.paginator.end = this.betweenFilter.dateEnd;
    if (fromSearch) {
      this.paginator.paginator.page = 1;
    }
    this.helperService.showLoadingMxpro360();
    this.claimsService.getAll(this.paginator, data)
    .then(response => {
      this.rows = response.data;
      this.paginator.paginator.total = response.paginator.total;
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  getFilterDates(dataReturn: BetweenDates) {
    this.betweenFilter = dataReturn;
    this.loadClaims(false);
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.loadClaims(false);
    }
  }

  openNewTab(estimateId: string) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(estimateId, 'claims');
  }
}
