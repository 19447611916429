import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService {

  private cancelPendingRequestSubject=new Subject<void>()
  cancelPendingRequest$ = this.cancelPendingRequestSubject.asObservable();

  cancelPendingRequest(){
    this.cancelPendingRequestSubject.next();
  }
}
