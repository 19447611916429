import { Directive, Input, ElementRef, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';

declare var jQuery;

@Directive({
    selector: '[appSelect2]'
})
export class Select2Directive implements AfterViewInit, OnChanges {

    @Input()
    appSelect2: string;

    @Input('place')
    placeholder: string;

    @Input('theme')
    theme: string;


    private idTimeOut;

    /*
    Variable que indica si se debe reordenar la lista de opciones
    */
    @Input('reorder') reorder: boolean;

    constructor(
        private element: ElementRef,
        private ngControl: NgControl
    ) {
        this.theme = 'mxp360'; // mxp360, mxp360-form
        this.idTimeOut = null;
    }

    ngAfterViewInit() {
        // cargamos el plugin
        this.loadPlugin();
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    private loadPlugin() {

        // verificamos si ya ha sido inicializado previamente
        if (jQuery(this.element.nativeElement).hasClass('select2-hidden-accessible')) {
            jQuery(this.element.nativeElement).select2('destroy');
        }
        jQuery(this.element.nativeElement).select2({
            placeholder: this.placeholder,
            theme: this.theme,
        });

        jQuery(this.element.nativeElement).on('select2:select', (e) => {
            const ctrl = this.ngControl.control;
            ctrl.setValue(this.element.nativeElement.value);
        });

        // si hay cambios en el dom refrescamos el plugin
        jQuery(this.element.nativeElement).bind('DOMSubtreeModified', () => {

            if (this.idTimeOut !== null) {
                clearTimeout(this.idTimeOut);
            }

            this.idTimeOut = setTimeout(() => {
                jQuery(this.element.nativeElement).trigger('change');
            }, 1000);
        });

        jQuery(this.element.nativeElement).hide();
    }

}
