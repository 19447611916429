<div class="layout-top-spacing">

    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

        <div class="row">
            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-5">
                <h3>Settings
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                                Dashboard</a>
                        </li>

                        <li class="breadcrumb-item active" aria-current="page">Settings</li>
                    </ol>
                </nav>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right align-self-center mb-5">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button class="btn btn-main-action mb-2" type="submit">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-12">

                <!-- MENU TAB -->
                <div class="tab-menu">
                    <div class="btn-group block-general-settings-sections" role="group" aria-label="Basic example">
                        <button type="button" [ngClass]="{'opt-active' : currentTab == 'GENERAL' }" class="btn opt-disabled btn-lg" (click)="changeTab('GENERAL')">
                            General
                        </button>
                        <button type="button" [ngClass]="{'opt-active' : currentTab == 'AUTO_LOCAL_TARIFF' }" class="btn opt-disabled btn-lg" (click)="changeTab('AUTO_LOCAL_TARIFF')">
                            Auto Local Travel Tariff
                        </button>
                        <button type="button" [ngClass]="{'opt-active' : currentTab == 'TARIFF_VERSIONS' }" class="btn opt-disabled btn-lg" (click)="changeTab('TARIFF_VERSIONS')">
                            Tariff Versions
                        </button>
                    </div>
                </div>
                <!-- END MENU TAB -->

                <div class="statbox widget box box-shadow">

                    <div class="widget-content widget-content-area">                   

                        <section *ngIf="currentTab == 'GENERAL'">
                            
                            <h3>
                                General
                            </h3>

                            <div class="row">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                                    <div class="form-group mb-1">
                                        <label class="control-label">Lock Duplicate Lead</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-lock">
                                                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.duplicate" [(ngModel)]="block_duplicate_leads" id="setting_duplicate" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="setting_duplicate_tx" class="form-text text-muted mb-4">Define If When There is a Duplicate Lead It Will Not Be Saved On the Database.</small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                                    <div class="form-group mb-1">
                                        <label class="control-label">Auto Calculate Stop Fees</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-map-pin">
                                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                        <circle cx="12" cy="10" r="3"></circle>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.cover_stop_fees" [(ngModel)]="cover_stop_fees" id="cover_stop_fees" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define If to Auto Charge or Not On an Additional Stop.</small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Reset Discount After Inventory Changes</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-package">
                                                        <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                                                        <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1
                                                        1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.reset_discount_after_inventory_changes" [(ngModel)]="reset_discount_after_inventory_changes"
                                                id="setting.reset_discount_after_inventory_changes" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Define If When There is a Change in the Inventory, the System Deletes the Discounts Added in a Estimate.</small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Lock the CF/LBS Rate</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-lock">
                                                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.lock_the_rate" [(ngModel)]="lock_the_rate"
                                                id="setting.lock_the_rate" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Define If to Lock the Cubic Feet or Weight Tariff After it is Set in an Estimate.</small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Require FADD to click book?</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-lock">
                                                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.delivery_date_required_to_book" [(ngModel)]="delivery_date_required_to_book"
                                                id="setting.delivery_date_required_to_book" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Define If to require a saved first available day for delivery on an estimate file in order for user to click book.</small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Show Pricing Local Estimate PDF</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-lock">
                                                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.show_pricing_local_estimate_pdf" [(ngModel)]="show_pricing_local_estimate_pdf"
                                                id="setting.show_pricing_local_estimate_pdf" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Define If to display quoted hours and pricing on a local estimate PDF.</small>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Show Weight on Local Estimate PDF</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-lock">
                                                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="setting.show_weight_local_estimate_pdf" [(ngModel)]="show_weight_local_estimate_pdf"
                                                id="setting.show_weight_local_estimate_pdf" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Define If to display the weight on a local estimate PDF.</small>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section *ngIf="currentTab == 'AUTO_LOCAL_TARIFF'">
                            <h3>
                                Auto Local Travel Tariff
                            </h3>

                            <div class="row">
                                <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                                    <div class="form-group mb-1">
                                        <label class="control-label">Active</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-power">
                                                        <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                                        <line x1="12" y1="2" x2="12" y2="12"></line>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="auto_local_tariff.active" [(ngModel)]="auto_local_tariff_active"
                                                id="auto_local_tariff.active" class="form-control">
                                                <option value="YES">Yes</option>
                                                <option value="NO">No</option>
                                            </select>
                                        </div>
                                        <small id="setting_duplicate_tx" class="form-text text-muted mb-4">Define If the Feature is Active or Not.</small>
                                    </div>
                                </div> -->

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Round Up Time</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-clock">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <polyline points="12 6 12 12 16 14"></polyline>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="round_up_time" [(ngModel)]="setting.auto_local_tariff.round_up_time"
                                                id="round_up_time" class="form-control">
                                                <option value="ONE_HOUR">One Hour</option>
                                                <option value="THIRTY_MINUTES">Thirty Minutes</option>
                                                <option value="FIFTEEN_MINUTES">Fifteen Minutes</option>
                                            </select>
                                        </div>
                                        <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define How Many Time You Round Local Jobs Time.</small>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                    <div class="form-group mb-1">
                                        <label class="control-label">Travel Charge Based On (Time Or Miles)</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-clock">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <polyline points="12 6 12 12 16 14"></polyline>
                                                    </svg>
                                                </span>
                                            </div>
                                            <select name="travel_charge_base_on" [(ngModel)]="setting.auto_local_tariff.travel_charge_base_on"
                                                id="travel_charge_base_on" class="form-control">
                                                <option value="TIMES">Time</option>
                                                <option value="MILES">Miles</option>
                                            </select>
                                        </div>
                                        <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define How Many Time You Round Local Jobs Time.</small>
                                    </div>
                                </div>

                                <ng-container *ngIf="setting.auto_local_tariff.travel_charge_base_on == 'TIMES'">

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label">Minimum Work Hours</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-clock">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="12 6 12 12 16 14"></polyline>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="number" name="minimum_work_hours" [(ngModel)]="setting.auto_local_tariff.minimum_work_hours"
                                                    id="minimum_work_hours" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Minimun Work Hours.</small>
                                        </div>
                                    </div>
    
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label">Minimum Travel Hours</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-clock">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="12 6 12 12 16 14"></polyline>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="number" name="minimum_travel_hours" [(ngModel)]="setting.auto_local_tariff.minimum_travel_hours"
                                                    id="minimum_travel_hours" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Minimun Travel Hours.</small>
                                        </div>
                                    </div>
    
                                    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label">Time per Mile (seconds)</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-clock">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="12 6 12 12 16 14"></polyline>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input name="time_per_mile" [(ngModel)]="setting.auto_local_tariff.time_per_mile" id="time_per_mile" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Time per Mile in Secods.</small>
                                        </div>
                                    </div> -->
    
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label"> Cost Per Hours </label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                                    </span>
                                                </div>
                                                <input type="number" name="cost_per_hour"  step="0.01" [(ngModel)]="setting.auto_local_tariff.cost_per_hour" id="time_per_mile" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Cost Per Hours.</small>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="setting.auto_local_tariff.travel_charge_base_on == 'MILES'">

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label">Minimum Work Hours</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-clock">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="12 6 12 12 16 14"></polyline>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="number" name="minimum_work_hours" [(ngModel)]="setting.auto_local_tariff.minimum_work_hours"
                                                    id="minimum_work_hours" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Minimun Work Hours.</small>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label">Minimum Travel Miles</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-clock">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <polyline points="12 6 12 12 16 14"></polyline>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="number" name="minimum_travel_miles" [(ngModel)]="setting.auto_local_tariff.minimum_travel_miles"
                                                    id="minimum_travel_miles" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Minimun Travel Miles.</small>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                        <div class="form-group mb-1">
                                            <label class="control-label"> Cost Per Mile </label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <i class="fa-solid fa-dollar-sign fa-xl"></i>
                                                    </span>
                                                </div>
                                                <input type="number" name="time_per_mile" [(ngModel)]="setting.auto_local_tariff.cost_per_mile" id="cost_per_mile" class="form-control"/>
                                            </div>
                                            <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Define the Cost per Mile.</small>
                                        </div>
                                    </div>
    
                                </ng-container>
                            </div>
                        </section>

                        <section *ngIf="currentTab == 'TARIFF_VERSIONS'">

                            <div class="row">

                                <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                                    <h3>
                                        Tariff Versions
                                    </h3>
                                </div>
                                
                                <div class="col-xl-7 col-lg-4 col-md-6 col-sm-8 align-self-center mb-3">
                                    <div class="d-flex justify-content-sm-end justify-content-center float-right">
                                        <a appWorkspaceRouterLink="/tariff-versions/add" class="btn btn-new">
                                            <i class="fas fa-plus-square"></i> &nbsp; New Tariff Version
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                        
                                    <div class="widget-content">
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover mb-4">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Measure Unit</th>
                                                        <th>Is Active</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let version of tariffVersions | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                                        <td>{{ version.name }}</td>
                                                        <td>{{ version.measure_unit }}</td>
                                                        <td>
                                                            <label class="container">
                                                                <input type="checkbox" [(ngModel)]="version.is_active"
                                                                (change)="versionIsActive(version)"
                                                                [checked]="version.is_active">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <a appWorkspaceRouterLink="/tariff-versions/{{version.id}}/edit" class="btn btn-success">
                                                                <i class="fa-solid fa-pen-to-square"></i>
                                                            </a>
                                                            <button (click)="remove(version.id)" class="btn btn-danger">
                                                                <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="paginating-container pagination-solid"
                                                (pageChange)="actualPage = $event"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        
                    </div>
                </div>

            </div>
        </div>

    </form>

</div>