import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GeneralSettingsService {

    constructor(private http: HttpClient) {

    }

    get(): Promise<GeneralSettings> {
        return this.http.get<GeneralSettings>(environment.api.company + '/general-settings').toPromise();
    }

    set(dataForm: GeneralSettings) {
        return this.http.post(environment.api.company + '/general-settings', dataForm).toPromise();
    }
}
