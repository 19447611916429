<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span> Vehicle
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a>
                    </li>

                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/vehicles">Vehicles</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="row">
                            <div class="col-md-6 col-sm-12">

                                <div class="form-group mb-1">
                                    <label class="control-label">Name</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle Name
                                    </small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Type</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-truck">
                                                    <rect x="1" y="3" width="15" height="13"></rect>
                                                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <select [(ngModel)]="dataForm.type" name="wd_type" class="form-control">
                                            <option value="BOX_TRUCK">
                                                BOX TRUCK
                                            </option>
                                            <option value="PICKUP_TRUCK">
                                                PICKUP TRUCK
                                            </option>
                                            <option value="CARGO_VAN">
                                                CARGO VAN
                                            </option>
                                            <option value="FREIGTHLINERS">
                                                FREIGTHLINERS
                                            </option>
                                            <option value="CAR_CARRIER_TRAILER">
                                                CAR CARRIER TRAILER
                                            </option>
                                        </select>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle type.
                                    </small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Plate</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" alphaNumeric [(ngModel)]="dataForm.plate" name="wd_plate" class="form-control">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle plate
                                    </small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Description</label>
                                    <div class="input-group mb-1">
                                        <textarea class="form-control" rows="5" name="wd_description"
                                            [(ngModel)]="dataForm.description">
                                        </textarea>
                                    </div>
                                    <small id="descriptionHelp" class="form-text text-muted mb-4">
                                        Vehicle description.
                                    </small>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">VIN</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" maxlength="17" minlength="17" alphaNumeric [(ngModel)]="dataForm.vin" name="wd_vin" class="form-control">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle VIN.
                                    </small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Model</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" alphaNumeric [(ngModel)]="dataForm.model" name="wd_model" class="form-control">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle model.
                                    </small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Year</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-calendar">
                                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" onlyNumbers minlength="4" maxlength="4" [(ngModel)]="dataForm.year" name="wd_year" min="0" max="9999"
                                            class="form-control" placeholder="yyyy">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle year.
                                    </small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Tire Size</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg _ngcontent-fpv-c264="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather"><line _ngcontent-fpv-c264="" x1="5" y1="12" x2="19" y2="12"></line>
                                                    <line _ngcontent-fpv-c264="" x1="7" y1="17" x2="17" y2="7"></line>
                                                    <line _ngcontent-fpv-c264="" x1="7" y1="7" x2="17" y2="17"></line>
                                                    <line _ngcontent-fpv-c264="" x1="12" y1="5" x2="12" y2="19"></line><circle _ngcontent-fpv-c264="" cx="12" cy="12" r="10"></circle><circle _ngcontent-fpv-c264="" r="7" cx="12" cy="12"></circle><circle cx="12" cy="12" r="1"></circle></svg>
                                            </span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.tire_size" name="wd_tire_size" class="form-control" autocomplete="off">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Vehicle Tire Size.
                                    </small>
                                </div>

                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-save ml-1 mt-3" type="submit">
                                Submit
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>

</div>