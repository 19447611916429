import { DriverInvoiceItemEventuality } from './driver-invoice-item-eventuality';

export class DriverInvoiceItem {

    id: number;
    job_id: string;
    to: string;
    from: string;
    is_paid: boolean;
    pads: number;
    cf: number;
    rate_cf: number;
    amount: number;
    moving_balance: number;
    comments: string;
    eta_start: number;
    eta_end: number;
    delivery_status: 'PENDING' | 'DELIVERED' | 'REFUSED';
    item_eventualities: Array<DriverInvoiceItemEventuality>;

    constructor() {
        this.id = null;
        this.job_id = null;
        this.to = null;
        this.from = null;
        this.is_paid = null;
        this.pads = null;
        this.cf = null;
        this.rate_cf = null;
        this.amount = null;
        this.moving_balance = null;
        this.comments = null;
        this.eta_start = null;
        this.eta_end = null;
        this.delivery_status = 'PENDING';
        this.item_eventualities = [];
    }
}
