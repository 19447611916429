import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { GeneralSettingsleadRotation } from 'src/app/entities/workspace/general-settings-lead-rotation';
import { GeneralSettingLeadRotationService } from 'src/app/services/companies/general-setting-lead-rotation.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';

declare const swal;
@Component({
  selector: 'app-general-setting-lead-rotation',
  templateUrl: './general-setting-lead-rotation.component.html',
  styleUrls: ['./general-setting-lead-rotation.component.scss']
})
export class GeneralSettingLeadRotationComponent implements OnInit {

  public GeneralSettingsleadRotation: GeneralSettingsleadRotation;
  public editGeneralSettingsleadRotation: GeneralSettingsleadRotation;
  public listGeneralSettingsleadRotation: GeneralSettingsleadRotation[];
  public activeOption: string;
  public isEdit: boolean;
  public employees: Array<EmployeeView>;  
  public employeeId: string;
  public employee: string;

  constructor(
    private employeesService: EmployeesService,
    private helperService: HelperService,
    private currentRoute: ActivatedRoute,
    private generalSettingLeadRotationService: GeneralSettingLeadRotationService
  ) { 
    this.GeneralSettingsleadRotation = new GeneralSettingsleadRotation();
    this.editGeneralSettingsleadRotation = new GeneralSettingsleadRotation();
    this.listGeneralSettingsleadRotation = [];
    this.employeeId = "";
    this.employee = "";
    this.activeOption = "LIST";
    this.isEdit = false;
  }

  ngOnInit(): void {
    this.loadCompanyId();
  }

  ngAfterViewInit(): void {
    this.load();
    this.loadEmployees();
  }

  loadCompanyId(){
      this.currentRoute.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
            this.GeneralSettingsleadRotation.company_id = params.id;
        } else {
          swal(
            'error',
            'company?',
            'warning'
        );
        }
    });
  }

  save(){    
    this.helperService.showLoadingMxpro360();
    console.log('this.GeneralSettingsleadRotation', this.GeneralSettingsleadRotation);
    
    this.generalSettingLeadRotationService
        .set(this.GeneralSettingsleadRotation)
        .then(() => {
            swal(
                'Done!',
                'Changes have been successfully saved',
                'success'
            );
            this.GeneralSettingsleadRotation = new GeneralSettingsleadRotation();
            this.activeOption = "LIST";
            this.isEdit = false;
            this.load();
            this.loadCompanyId();
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  }

  load() {
    this.helperService.showLoadingMxpro360();
    this.generalSettingLeadRotationService
      .get()
      .then((response) => {
        this.listGeneralSettingsleadRotation = response;
      })
      .catch((error) => {
          console.error(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();

      });
  }

  loadEmployees(){
    this.helperService.showLoadingMxpro360();
    this.employeesService
    .getAll()
    .then((response) => {      
      this.employees = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  new(){
    this.activeOption = "NEW";
  }

  edit(row){
    this.activeOption = "NEW";
    this.isEdit = true;
    console.log("this.isEdit", this.isEdit);
    
    console.log("edit row", row);
    this.GeneralSettingsleadRotation = {... row };
    console.log('this.GeneralSettingsleadRotation ', this.GeneralSettingsleadRotation);
    console.log('row.employee', row.employee);
    this.employee = row.employee.name;
    
    
  }
}
