import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from 'src/app/entities/workspace/employee';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { HelperService } from 'src/app/services/helper.service';
import { ConstantsMessages } from 'src/app/constants-messages';
import { NgForm, NgModel } from '@angular/forms';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-employees-form',
    templateUrl: './employees-form.component.html',
    styleUrls: ['./employees-form.component.scss'],
})
export class EmployeesFormComponent implements OnInit {

    // Constantes que contiene el tipo de mensaje a mostrar
    constantsMessages = ConstantsMessages;
    public accountEmail: string;
    public accountPassword: string;
    public accountPhone: string;
    public dataForm: Employee;
    public requestPassword: boolean;
    constructor(
        private currentRoute: ActivatedRoute,
        private employeesService: EmployeesService,
        public authService: AuthService,
        public router: Router,
        private helperService: HelperService
    ) {
        this.accountEmail = '';
        this.accountPassword = '';
        this.accountPhone = '';
        this.dataForm = new Employee();
        this.requestPassword = false;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.currentRoute.params.subscribe(async (params) => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getById(id)
            .then((response) => {
                this.dataForm = response['employee'];
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save(form: NgForm) {
        if(form.form.status !== 'VALID'){
            return;
        }
        this.helperService.showLoadingMxpro360();
        if (this.requestPassword && this.accountPassword == '') {
            this.requestPassword = true;
            swal({
                type: 'error',
                title: 'Password',
                text: 'Password is required',
            });
            this.helperService.hideLoadingMxpro360();
            return;
        }
        if (this.dataForm.account_id == null && this.accountEmail !== '' && this.accountPassword == '') {
            this.searchAccout();
            this.helperService.hideLoadingMxpro360();
            return;
        }
        const dataPost: any = { ...this.dataForm };
        dataPost.accountEmail = this.accountEmail;
        dataPost.accountPassword = this.accountPassword;
        dataPost.accountPhone = this.accountPhone;
        this.employeesService
            .save(dataPost)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.UPDATE);
                this.helperService.goToWorkspaceRouterLink('/employees/' + response.row_id + '/view');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message,
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    capitalizeWords(str: string) {
        let words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(' ');
    }



    searchAccout() {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .searchAccouts(this.accountEmail)
            .then((response: any) => {
                console.log(response);
                this.helperService.hideLoadingMxpro360();
                swal({
                    title: 'Are you sure you want to associate this account?',
                    html: '<div> <table class="table"> <tr><th>Name</th><th>Email</th></tr>  <tr><td>' + response.name + '</td><td>' + response.email + '</td></tr> </table> </div>',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Assign'
                }).then((res) => {
                    if (res.value) {
                        this.dataForm.account_id = response.id;
                        this.accountEmail = response.email;
                        this.requestPassword = false;

                        // asignamos los datos de la cuenta
                        this.dataForm.name = this.capitalizeWords(response.name);
                    }
                })
            })
            .catch((error) => {
                swal({
                    title: 'Attention',
                    text: 'This is a new Account, please assign a new password',
                    type: 'warning',
                });
                this.requestPassword = true;
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.accountPassword = '';
            });
    }
}
