<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">New</span><span *ngIf="dataForm.id != null">Edit</span> Additional Concepts
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/tariff-additional-concepts">Additional Concepts</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">

           

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">
                        <div class="col-12">

                            <div class="form-group mb-4">
                                <label for="Name">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg></span>
                                    </div>
                                    <input type="text" class="form-control" name="Name" placeholder="Enter here the name" [(ngModel)]="dataForm.name" required>
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Name Additional Concepts.</small>
                            </div>


                            <div class="form-group">
                                <label for="Behavior">Behavior</label>
                                <select id="Behavior" placeholder="Enter here the behavior" [(ngModel)]="dataForm.behavior" name="Behavior" class="form-control" required>                                    
                                    <option selected value="DISCOUNT">Discount</option>
                                    <option value="ADDITIONAL_STOP_FEE">Additional Stop Fee</option>
                                    <option value="BINDING_FEE"> Binding Fee </option>
                                    <option value="CHARGE">Charge</option>
                                    <option value="DISCOUNT_FULL_PACKING_SERVICE">Discount full packing service</option>
                                    <option value="DISCOUNT_PER_MILE">Discount Per Mile</option>
                                    <option value="FULL_PACKING_SERVICE">Full Packing Service</option>
                                    <option value="LONG_CARRY_DELIVERY">Long Carry on Delivery</option>
                                    <option value="LONG_CARRY_PICKUP">Long Carry on Pickup</option>
                                    <option value="ELEVATOR_DELIVERY">Stairs/Elevator on Delivery</option>
                                    <option value="ELEVATOR_PICKUP">Stairs/Elevator on Pickup</option>
                                    <option value="SHUTTLE_DELIVERY">Shuttle on Delivery</option>
                                    <option value="SHUTTLE_PICKUP">Shuttle on Pickup</option>
                                    <option value="SPLIT_DELIVERY">Split Delivery</option>
                                    <option value="SPLIT_PICKUP">Split Pickup</option>
                                    <option value="OPEN_QUICK_QUOTE_QUESTION">Quick Quote Question (Enter/Value)</option>
                                    <option value="CLOSED_QUICK_QUOTE_QUESTION">Quick Quote Question (Yes/No)</option>
                                </select>
                                <small id="nameCustomer" class="form-text text-muted mb-4">Select Behavior Additional Concepts.</small>
                            </div>

                            <ng-container *ngIf="dataForm.behavior != 'DISCOUNT_FULL_PACKING_SERVICE' && dataForm.behavior != 'FULL_PACKING_SERVICE' && dataForm.behavior != 'CLOSED_QUICK_QUOTE_QUESTION' && dataForm.behavior != 'OPEN_QUICK_QUOTE_QUESTION'">

                                <div class="form-group mt-5">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="byPercentage" name="byPercentage" (ngModelChange)="changeByPercentage($event)" [(ngModel)]="dataForm.by_percentage">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="byPercentage" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.by_percentage}">
                                            By Percentage
                                        </label>
                                    </div>
                                    <div *ngIf="dataForm.by_percentage">
                                        <label >Percentage Applied to</label> <br>
                                        <select name="month" [(ngModel)]="dataForm.apply_percentage_to"  class="form-control" style="padding-left: 40px;">
                                            <option value="LINEHAUL"> LINEHAUL </option>
                                            <option value="SUBTOTAL"> SUBTOTAL </option>
                                        </select>
                                    </div>
                                </div>
                              
                                <div class="form-group mb-5">
                                    <label for="Value">Value</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg *ngIf="!dataForm.by_percentage" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign" ><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                                <svg *ngIf="dataForm.by_percentage" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="19" y1="5" x2="5" y2="19"></line><circle cx="6.5" cy="6.5" r="2.5"></circle><circle cx="17.5" cy="17.5" r="2.5"></circle></svg>
                                            </span>
                                        </div>
                                        <input type="number" pattern="^\d*(\.\d{0,2})?$" onlyNumbers class="form-control" name="Value" placeholder="Enter here the value" [(ngModel)]="dataForm.value" [required]="dataForm.by_percentage">
                                    </div>    
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Value Additional Concepts. 
                                        <span class="text-red" *ngIf="!dataForm.by_percentage">Format: 0.00</span>
                                        <span class="text-red" *ngIf="dataForm.by_percentage">Format Percentage: 0.00</span>
                                    </small>
                                </div>

                                <div class="form-group mb-4">
                                    <div class="toggle-switch">
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="make_maximum" name="make_maximum" [(ngModel)]="dataForm.make_maximum">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="make_maximum" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.make_maximum}">
                                            Make Maximum
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group mb-4" *ngIf="!dataForm.by_percentage">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="byCubicFeet" name="byCubicFeet" [(ngModel)]="dataForm.by_cubic_feet">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="byCubicFeet" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.by_cubic_feet}">
                                            By Cubic Feet
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="valueEditableUser" name="valueEditableUser" [(ngModel)]="dataForm.editable_by_user">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="valueEditableUser" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.editable_by_user}">
                                            Value Editable by User
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group mb-4">
                                    <div class="toggle-switch">
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="add_upon_estimate_creation" name="add_upon_estimate_creation" [(ngModel)]="dataForm.add_upon_estimate_creation">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="add_upon_estimate_creation" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.add_upon_estimate_creation}">
                                            Populate Upon Estimate Creation
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group mt-5">
                                    <label >Stop Type</label> <br>
                                    <select name="month" [(ngModel)]="dataForm.stop_type"  class="form-control" style="padding-left: 40px;">
                                        <option value="PICKUP"> Pickup </option>
                                        <option value="DELIVERY"> Delivery </option>
                                        <option value="NOT_APPLY"> Not Apply </option>
                                    </select>
                                </div>

                            </ng-container>

                            <ng-container *ngIf="dataForm.behavior == 'FULL_PACKING_SERVICE'">

                                <div class="form-group mt-5">
                                    <label > Full Packing Mode </label> <br>
                                    <select name="full_packing_mode" [(ngModel)]="dataForm.full_packing_mode"  class="form-control" style="padding-left: 40px;">
                                        <option value="TARIFF"> Tariff </option>
                                        <option value="CUSTOM"> Custom </option>
                                    </select>
                                </div>

                                <ng-container *ngIf="dataForm.full_packing_mode == 'CUSTOM'">
                                    <div class="form-group mb-5">
                                        <label for="Value">Value</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5">
                                                    <svg *ngIf="!dataForm.by_percentage" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign" ><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                                    <svg *ngIf="dataForm.by_percentage" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="19" y1="5" x2="5" y2="19"></line><circle cx="6.5" cy="6.5" r="2.5"></circle><circle cx="17.5" cy="17.5" r="2.5"></circle></svg>
                                                </span>
                                            </div>
                                            <input type="number" pattern="^\d*(\.\d{0,2})?$" onlyNumbers class="form-control" name="value" placeholder="Enter here the value" [(ngModel)]="dataForm.value" required>
                                        </div>
        
                                        <small id="nameCustomer" class="form-text text-muted mb-4">Value Additional Concepts. 
                                            <span class="text-red" *ngIf="!dataForm.by_percentage">Format: 0.00</span>
                                            <span class="text-red" *ngIf="dataForm.by_percentage">Format Percentage: 0.00</span>
                                        </small>
                                    </div>
    
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="dataForm.behavior == 'CLOSED_QUICK_QUOTE_QUESTION'">
                                <div class="form-group mb-5">
                                    <label for="Value">Additional Minutes Per "yes" Per Room</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-clock">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <polyline points="12 6 12 12 16 14"></polyline>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" pattern="^\d*(\.\d{0,2})?$" onlyNumbers class="form-control" name="value" placeholder="Enter here the value" [(ngModel)]="dataForm.value" required>
                                    </div>
    
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Value Additional Concepts.</small>
                                </div>    
                            </ng-container>

                            <ng-container *ngIf="dataForm.behavior == 'OPEN_QUICK_QUOTE_QUESTION'">
                                <div class="form-group mb-5">
                                    <label for="Value">Additional Minutes Per Value Per Room</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-clock">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <polyline points="12 6 12 12 16 14"></polyline>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" pattern="^\d*(\.\d{0,2})?$" onlyNumbers class="form-control" name="value" placeholder="Enter here the value" [(ngModel)]="dataForm.value" required>
                                    </div>
    
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Value Additional Concepts.</small>
                                </div>    
                            </ng-container>

                            <div class="d-flex mt-5" *ngIf="dataForm.behavior !== 'CLOSED_QUICK_QUOTE_QUESTION' && dataForm.behavior !== 'OPEN_QUICK_QUOTE_QUESTION'">                                
                            
                                <div class="form-group mb-4">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="display_on_long_distance" name="display_on_long_distance" [(ngModel)]="dataForm.display_on_long_distance">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="display_on_long_distance" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.display_on_long_distance}">
                                            Display On Long Distance
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="display_on_local_distance" name="display_on_local_distance" [(ngModel)]="dataForm.display_on_local_distance">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="display_on_local_distance" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.display_on_local_distance}">
                                            Display On Local Distance
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="display_on_auto_transport" name="display_on_auto_transport" [(ngModel)]="dataForm.display_on_auto_transport">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="display_on_auto_transport" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.display_on_auto_transport}">
                                            Display On Auto Transport
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group mb-4">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="display_on_freight" name="display_on_freight" [(ngModel)]="dataForm.display_on_freight">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="display_on_freight" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.display_on_freight}">
                                            Display On Freight
                                        </label>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-save ml-1 mt-5" type="submit">
                            Submit
                        </button>
                    </div>

                </form>


            </div>
        </div>

    </div>
</div>