import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

import DOMPurify from 'dompurify';
import * as CustomCKEditor from '../../../assets/ckeditor5/ckeditor.js';



@Component({
    selector: 'app-ck-editor',
    templateUrl: './ck-editor.component.html',
    styleUrls: ['./ck-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CkEditorComponent
        }
    ]
})
export class CkEditorComponent implements OnInit, ControlValueAccessor {

    timeoutId: any;
    mode: "TEXT" | "HTML";
    content: string;
    newContent: string;

    // indica si el componente debe comportarse en modo lectura
    isDisabled: boolean;

    customEditor = CustomCKEditor;

    config = {
        toolbar: {
            items: [
                'undo',
                'redo',
                'heading',
                '|',
                'fontSize',
                'fontFamily',
                'bold',
                'italic',
                'underline',
                'highlight',
                'fontColor',
                'fontBackgroundColor',
                'todoList',
                'horizontalLine',
                'alignment',
                '|',
                'outdent',
                'indent',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'imageInsert',
                'imageUpload',
                'mediaEmbed',
                'insertTable',
                'pageBreak',
                'findAndReplace',
                '|',
                'FullPage',
                'ImageResize',
            ]
        },
        language: 'en',
        image: {
            toolbar: [
                'imageTextAlternative',
                'toggleImageCaption',
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:side',
                'linkImage'
            ]
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells'
            ]
        },
        simpleUpload: {

            // The URL that the images are uploaded to.
            uploadUrl: environment.api.base + '/files?Authorization=' + this.authService.session.token + "&workspace=" + this.authService.workspaceSession.workspace.id,

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                //'X-CSRF-TOKEN': 'CSRF-Token',
                Authorization: this.authService.session.token,
            }
        }

    };

    @Input()
    ckEditorTheme: 'CLASSIC' | 'DOCUMENT';

    constructor(private authService: AuthService, private sanitizer: DomSanitizer) {
        this.timeoutId = null;
        this.content = null;
        this.ckEditorTheme = 'DOCUMENT';
        this.isDisabled = false;
        this.mode = 'TEXT';
    }

    /**
     * Se ejecuta despues del constructor
     */
    ngOnInit(): void {

    }

    /**
     * Permite escuchar los cambios en los @inputs que hay en el component
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {

    }

    /**
     * Se ejecuta cuando la vista del component se ha renderizado
     */
    ngAfterViewInit() {

    }


    ngOnDestroy() {
        this.onChange(this.newContent);
    }

    onTextEditorChange(event: any) {
        if (!!event.editor) {
            let tmpContent = event.editor.getData();
            this.newContent = this.applyImageStyles(tmpContent);
            this.onTouched();
            this.notifyChange();
        }
    }

    onHtmlEditorChange(html: string) {
        this.newContent = html;
        this.onTouched();
        this.notifyChange();
    }

    /**
     * Permite verificar si el texto a asignar es nuevo texto
     * @param newText 
     * @returns 
     */
    private isNewText(newText: string) {

        // convertimos a base 64 para comparar que lo que se esta reportando en el evento no sea el mismo texto que acaba de ingresar por NGmodel
        newText = btoa(unescape(encodeURIComponent(newText)));
        const oldText = btoa(unescape(encodeURIComponent(this.newContent)));

        // si son los mismo no se debe reportar el cambio
        return newText !== oldText;
    }

    applyImageStyles(content: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');

        const figureElements = doc.querySelectorAll('figure');

        figureElements.forEach((figure: HTMLElement) => {
            const pElement = doc.createElement('p');
            pElement.innerHTML = figure.innerHTML;

            // Reemplazar la etiqueta <figure> por <p>
            figure.parentNode.replaceChild(pElement, figure);
        });

        const imgElements = doc.querySelectorAll('img');

        imgElements.forEach((img: HTMLImageElement) => {
            img.style.height = 'auto';
            img.style.maxWidth = '100%';
        });

        return doc.body.innerHTML;
    }

    changeMode(newMode: "TEXT" | "HTML") {
        this.content = this.newContent;
        this.mode = newMode;
    }

    notifyChange() {

        if (this.timeoutId != null) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            this.onChange(DOMPurify.sanitize(this.newContent));
            this.timeoutId = null;
        }, 600);
    }


    /*****************************************
     * METODOS PROPIOS DE LA API DE NG-MODEL *
     *****************************************/

    /**
     * este metodo lo llaman desde afuera para establecer un valor en el componente
     * 
     * @param obj 
     */
    writeValue(contentFromModel: string): void {
        if (this.isNewText(contentFromModel)) {
            this.content = contentFromModel;
        }
    }

    onChange = (content: string) => { };

    onTouched = () => { };

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * este metodo lo llaman desde afuera para establecer si el comisDisabledponente debe estar componente deshabilitado
     * 
     * @param isDisabled 
     */
    setDisabledState?(isDisabled: boolean): void {

        // guardamos el valor localemnte
        this.isDisabled = isDisabled;
    }


}