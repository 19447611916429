import { Address } from 'src/app/entities/global/address';
export class JobDeliveryFee {

    public id: string;
    public address: Address;
    public rate_cf: number;
    public total_price_cf: number;
    public route_rate_id: string;

    constructor() {
        this.id = null;
        this.address = new Address();
        this.rate_cf = null;
        this.total_price_cf = null;
        this.route_rate_id = null;
    }
}
