<div class="widget-heading">

    <!-- Call Status Indicator -->
    <div class="call-status" [ngClass]="{'active-phone' : (status == 'REST'), 'online-call' : isDialerOnCall() }" >
        <i class="fa-solid fa-circle"></i>
    </div>

    <!-- Display - Status de la Llamada -->
    <div class="phone-display-call">
        <div class="message-call-status">

            <!-- Telefono apagado -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('OFF')">
                <span class="caller-name" >
                    Phone is Off
                </span>
            </div>

            <!-- Telefono no Conectado -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('CONNECTING') ">
                <span class="caller-name" >
                    Connecting Phone
                </span>
            </div>

            <!-- Telefono Connecting -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('NOT_CONNECTED') ">
                <span class="caller-name" >
                    Phone not Connected
                </span>
            </div>

             <!-- Conectando Llamada -->
             <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('LOADING') ">
                <span class="calling-message">
                    Loading...
                </span>
            </div>

            <!-- Llamada Saliente -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('OUTGOING_CALL') ">
                <span class="calling-message">
                    Calling...
                </span>
                <span class="caller-name" >
                    {{io_call.contact_name}}
                </span>
                <span class="caller-phone">
                    {{io_call.phone_number | phone:'US' }}
                </span>
            </div>

            <!-- Llamada Entrante -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('INCOMING_CALL') ">
                <span class="incoming-call-message">
                    Incoming Call...
                </span>
                <span class="caller-name">
                    {{io_call.contact_name}}
                </span>
                <span class="caller-phone">
                    {{io_call.phone_number | phone:'US' }}
                </span>
            </div>

            <!-- Llamada ACTIVA -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('ACTIVE_CALL') ">
                <span class="incoming-call-message">
                    Connected
                </span>
                
                <span class="caller-name">
                    {{active_call.contact_name}}
                </span>    
                <span class="caller-phone">
                    {{active_call.phone_number | phone:'US' }}
                </span>
                <span class="call-time">
                    {{active_call.pairing_date | callTimer | async }}
                </span>
                
            </div>
           
            <!-- Llamada en Espera -->
            <div class="caller-info-incoming-call" *ngIf=" compareViewDialerStatus('CALL_ON_HOLD') " >
                <span class="incoming-call-message">
                    Call on HOLD
                </span>
                <span class="caller-name">
                    {{hold_on_call.contact_name}}
                </span>
                <span class="caller-phone">
                    {{hold_on_call.phone_number | phone:'US' }}
                </span>
                <span>
                    {{hold_on_call.pairing_date | callTimer | async }}
                </span>
            </div>
            
            <!-- 1 Llamada Conectada y LLamada Entrante 2 -->
            <div class="caller-info-incoming-call caller-info-incoming-call-on-hold" *ngIf=" compareViewDialerStatus('ACTIVE_CALL___INCOMING_CALL')">

                <!-- Llamada Actual -->
                <div class="info-incoming-call-on-hold call-on-hold" >
                    <span class="caller-name">
                        {{active_call.contact_name}}
                    </span>
                    <span>
                        {{active_call.pairing_date | callTimer | async }}
                    </span>
                </div>

                <!-- Llamada Entrante -->
                <div class="info-incoming-call-on-hold" >
                    <span class="caller-name">
                        {{io_call.contact_name}}
                    </span>
                    <span>
                        Incoming Call
                    </span>
                </div>

            </div>

            <!-- Llamada en Espera y LLmada saliente-->
            <div class="caller-info-incoming-call-on-hold" *ngIf=" compareViewDialerStatus('CALL_ON_HOLD___OUTGOING_CALL') " >

                <!-- Llamada Actual en Espera -->
                <div class="info-incoming-call-on-hold call-on-hold" >
                    <span class="caller-name">
                        {{hold_on_call.contact_name}}
                    </span>
                    <span>
                        HOLD
                    </span>
                </div>

                <!-- Llamada Saliente-->
                <div class="info-incoming-call-on-hold" >
                    <span class="caller-name">
                        {{io_call.contact_name}}
                    </span>
                    <span>
                        Calling
                    </span>
                </div>

            </div>

            <!-- Llamada en Espera y LLmada activa-->
            <div class="caller-info-incoming-call-on-hold" *ngIf=" compareViewDialerStatus('CALL_ON_HOLD___ACTIVE_CALL') " >

                <!-- Llamada ACTIVA -->
                <div class="info-incoming-call-on-hold" >
                    <span class="caller-name">
                        {{active_call.contact_name}}
                    </span>
                    <span>
                        {{active_call.pairing_date | callTimer | async }}
                    </span>
                </div>
                
                <!-- Llamada Actual en Espera -->
                <div class="info-incoming-call-on-hold call-on-hold" >
                    <span class="caller-name">
                        {{hold_on_call.contact_name}}
                    </span>
                    <span>
                        HOLD
                    </span>
                </div>

            </div>
            
            <!-- Llamada en Espera y LLmada entrante-->
            <div class="caller-info-incoming-call-on-hold" *ngIf=" compareViewDialerStatus('CALL_ON_HOLD___INCOMING_CALL') " >

                <!-- Llamada Actual en Espera -->
                <div class="info-incoming-call-on-hold call-on-hold" >
                    <span class="caller-name">
                        {{hold_on_call.contact_name}}
                    </span>
                    <span>
                        HOLD
                    </span>
                </div>
                
                <!-- Llamada Entrante-->
                <div class="info-incoming-call-on-hold" >
                    <span class="caller-name">
                        {{io_call.contact_name}}
                    </span>
                    <span> 
                        Incoming Call
                    </span>
                </div>

            </div>

             <!-- Ambas Llamadas estan en espera -->
             <div class="caller-info-incoming-call-on-hold" *ngIf=" compareViewDialerStatus('CALL_ON_HOLD___CALL_ON_HOLD') " >

                <div class="info-incoming-call-on-hold call-on-hold" >
                    <span class="caller-name">
                        {{hold_on_call.contact_name}}
                    </span>
                    <span>
                        HOLD
                    </span>
                </div>
                
                <div class="info-incoming-call-on-hold" >
                    <span class="caller-name">
                        {{active_call.contact_name}}
                    </span>
                    <span> 
                        HOLD
                    </span>
                </div>

            </div>


            <!-- Conferencia *ngIf="counterMerge" 
            <div class="caller-info-incoming-call-on-hold" >

                <span class="caller-name" >
                    Milena Ramirez
                </span>
                <span>
                    (315) 445 6987
                </span>


                <span class="caller-name">
                    Sandra Quiceno
                </span>
                <span>
                    (318) 645 2981
                </span>

                <span>00:32</span>
            </div>
            -->

        </div>
    </div>

     <!-- Input - Outgoing Call -->
     <div  class="phone-display-input" *ngIf="keypadEnabled == 'NUMERIC' && !isDialerOffline()" >
        <span class="country-code">+1</span>
        <input type="text" class="phone-input" maxlength="10" placeholder="(000) 000-0000" value="(***) ***-****" [(ngModel)]="phoneNumberEntry">
        <button class="delete-number" (click)="deletedDigit()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-delete">
                <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                <line x1="18" y1="9" x2="12" y2="15"></line>
                <line x1="12" y1="9" x2="18" y2="15"></line>
            </svg>
        </button>
    </div>

</div>

<div class="widget-body">

    <!---  'incoming-call-on-hold' : --->
    <div class="phone-keypad" *ngIf=" !isDialerOffline() " [ngClass]="{'active-call' : isDialerOnActiveCall(), 'inactive-call' : (status == 'REST' ), 'call-on-hold' : (compareViewDialerStatus('CALL_ON_HOLD')) }" >
    
        <!-- Usado para mostrar cargando conexion con la llamada -->
        <div class="loader" *ngIf=" compareViewDialerStatus('LOADING') "></div>

        <!-- Numeric Keypad  -->
        <div  class="numeric-keypad" *ngIf="keypadEnabled == 'NUMERIC'" >

            <div class="col-4 key" (click)="dialedDigit(1)">
                <div class="number"> 1 </div>
                <div class="letters">&nbsp;</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(2)">
                <div class="number"> 2 </div>
                <div class="letters">ABC</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(3)">
                <div class="number"> 3 </div>
                <div class="letters">DEF</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(4)">
                <div class="number"> 4 </div>
                <div class="letters">GHI</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(5)">
                <div class="number"> 5 </div>
                <div class="letters">JKL</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(6)">
                <div class="number"> 6 </div>
                <div class="letters">MNO</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(7)">
                <div class="number"> 7 </div>
                <div class="letters">PQRS</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(8)">
                <div class="number"> 8 </div>
                <div class="letters">TUV</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(9)">
                <div class="number"> 9 </div>
                <div class="letters">WXYZ</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit('*')">
                <div class="number"> * </div>
                <div class="letters">&nbsp;</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit(0)">
                <div class="number"> 0 </div>
                <div class="letters">+</div>
            </div>
            <div class="col-4 key" (click)="dialedDigit('#')">
                <div class="number"> # </div>
                <div class="letters">&nbsp;</div>
            </div>

        </div>

        <!-- Call Options Keypad -->
        <div  class="call-options-keypad" *ngIf="keypadEnabled == 'ACTIVE_CALL_OPTIONS' " >

            <div class="d-flex mt-5">

                <!-- Silence Button cuando no esta en conferencia  *ngIf="!activeSilenceConference" -->
                <div class="call-option" [ngClass]="{'active' : isMicMuted()}">
                    <button class="btn-silence" (click)="toogleMute()">
                        <i class="fa-solid fa-microphone-slash"></i>
                    </button>
                    <div class="call-option-name text-center">Silence</div>
                </div>

                <!-- hold Button" -->
                <div class="call-option" *ngIf="!IsCallOnHold()" >
                    <button class="btn-pause" (click)="putCallOnHold()">
                        <i class="fa-solid fa-pause"></i>
                    </button>
                    <div class="call-option-name text-center">Hold</div>
                </div>

                <!-- resume Button from Hold On" -->
                <div class="call-option active" *ngIf="IsCallOnHold()" >
                    <button class="btn-pause" (click)="resumeCall()">
                        <i class="fa-solid fa-pause"></i>
                    </button>
                    <div class="call-option-name text-center">Resume</div>
                </div>

                <!-- Keypad Button -->
                <div class="call-option">
                    <button class="btn-keypad" (click)="toogleNumberKeypad()">
                        <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>ionicons-v5-g</title>
                            <path d="M256,400a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M256,272a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M256,144a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M256,16a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M384,272a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M384,144a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M384,16a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M128,272a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M128,144a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M128,16a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                        </svg>
                    </button>
                    <div class="call-option-name text-center">Keypad</div>
                </div>

            </div>

            <div class="d-flex">

                <!-- Add Call Button -->
                <div class="call-option">
                    <button class="btn-add-call" (click)="toogleContactsKeypad()" >
                        <i class="fa-solid fa-plus"></i>
                    </button>
                    <div class="call-option-name text-center">Contacts</div>
                </div>

                <!-- 
                    Call Transfer Button 
                    <div class="call-option">
                        <button class="btn-add-call">
                            <i class="fa-solid fa-arrow-right-arrow-left"></i>
                        </button>
                        <div class="call-option-name text-center">Transfer</div>
                    </div>
                -->

                <!-- Search Button -->
                <div class="call-option">
                    <button class="btn-search">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                    <div class="call-option-name text-center">Search</div>
                </div>

            </div>

            <div class="d-flex" *ngIf="haveDialerBothCallsActive()">

                <div class="call-option">
                    <button class="btn-merge-calls">
                        <svg class="svg-icon" style="width: 1em; height: 1em;vertical-align: middle;fill: #ffffff;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M832.984615 866.461538c-110.276923-53.169231-189.046154-147.692308-228.430769-256-15.753846-39.384615-25.6-84.676923-29.538461-124.061538v-68.923077H787.692308c15.753846 0 27.569231-17.723077 15.753846-35.446154l-279.630769-334.769231c-9.846154-11.815385-31.507692-11.815385-39.384616 0l-271.753846 334.769231c-9.846154 11.815385 0 35.446154 15.753846 35.446154h214.646154v68.923077c-5.907692 41.353846-15.753846 86.646154-29.538461 124.061538-39.384615 108.307692-118.153846 202.830769-228.43077 256-15.753846 5.907692-21.661538 25.6-15.753846 39.384616l25.6 61.046154c7.876923 15.753846 25.6 21.661538 41.353846 11.815384 118.153846-57.107692 212.676923-147.692308 269.784616-256 59.076923 108.307692 151.630769 198.892308 271.753846 256 15.753846 7.876923 35.446154 5.907692 41.353846-11.815384l25.6-61.046154c11.815385-13.784615 3.938462-31.507692-11.815385-39.384616z" />
                        </svg>
                    </button>
                    <div class="call-option-name text-center">Merge Calls</div>
                </div>

                <div class="call-option">
                    <button class="btn-swap" (click)="callSwap()">
                        <i class="fa-solid fa-shuffle"></i>
                    </button>
                    <div class="call-option-name text-center">Swap</div>
                </div>

                <!-- Borrar -->
                <div class="empty-box"></div>

            </div>

        </div>

        <!-- Options Incoming Call  *ngIf="showIncomingCallOptions" -->
        <div class="incoming-call-options" *ngIf="keypadEnabled == 'INCOMING_CALL_OPTIONS' " >
            <div class="d-flex mt-5">

                <!-- Search Button -->
                <div class="call-option">
                    <button class="btn-search">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                    <div class="call-option-name text-center">Search</div>
                </div>

                <!-- Forward Button -->
                <div class="call-option">
                    <button class="btn-forward">
                        <i class="fa-solid fa-share"></i>
                    </button>
                    <div class="call-option-name text-center">Forward</div>
                </div>

            </div>
        </div>

        <!-- Options Outgoing Call  *ngIf="showOutgoingCallOptions"  -->
        <div class="incoming-call-options" *ngIf="keypadEnabled == 'OUTGOING_CALL_OPTIONS' ">
            <div class="d-flex mt-5">

                <!-- Search Button (click)="showModalIncomingCallInfo()" -->
                <div class="call-option">
                    <button class="btn-search" >
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                    <div class="call-option-name text-center">Search</div>
                </div>

            </div>
        </div>

        <!-- Options of Contacts -->
        <div class="contacts-list-options" *ngIf="keypadEnabled == 'CONTACTS'" >

            <div class="input-group mb-3">
                <input type="text" class="form-control" name="search_phone_line" [(ngModel)]="searchPhoneLine" placeholder="Search" aria-label="notification" aria-describedby="basic-addon1" style="margin-right: -3px;" autocomplete="off"  >
                <span class="input-group-text" id="basic-addon1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </span>
            </div>

            <!-- Icons -->
            <ul class="list-group list-group-icons-meta">
                <ng-container *ngFor="let pl of phoneLines | search : searchPhoneLine | async ">

                    <li class="list-group-item " [ngClass]="{'active' : isPhoneLineSelected(pl) }" (click)="doPhoneLineSelect(pl)" >
                        <div class="media">
                            <div class="media-body">
                                <h6 class="tx-inverse">{{ pl.name }} </h6>
                                <p class="mg-b-0"> 
                                    {{pl.phone_number | phone:'US' }}
                                </p>
                            </div>
                        </div>
                    </li>

                </ng-container>
            </ul>

        </div>

    </div>

    <div class="call-actions-btns">

        <!-- Iniciar Llamada  -->
        <div class="call-btn" *ngIf=" keypadEnabled == 'NUMERIC' || keypadEnabled == 'CONTACTS' " [ngClass]="{'phone-disconnected' : (phoneNumberEntry.length < 10) }">
            <div class="outgoing-call-btn">

                <!-- BOTON DE REALIZAR LLAMADA -->
                <button class="btn rounded-circle" (click)="makeOutgoingCall()" [disabled]="phoneNumberEntry.length < 10" >
                    <i class="fa-solid fa-phone"></i>
                </button>

                <!-- BOTON DE GO BACK ACTION  -->
                <button class="btn rounded-circle go-back-action" *ngIf=" keypadEnabled == 'NUMERIC' && !compareViewDialerStatus('REST') " (click)="toogleNumberKeypad()" >
                    <i class="fas fa-times"></i>
                </button>

                <!-- BOTON DE GO BACK ACTION  -->
                <button class="btn rounded-circle go-back-action" *ngIf=" keypadEnabled == 'CONTACTS' " (click)="toogleContactsKeypad()" >
                    <i class="fas fa-times"></i>
                </button>

                <!-- BOTON DE TRANSFERIR LLAMADA  -->
                <button class="btn rounded-circle transfer-action" *ngIf=" !compareViewDialerStatus('REST') " (click)="transferCall()" [disabled]="phoneNumberEntry.length < 10" >
                    <i class="fa-solid fa-arrow-right-arrow-left"></i>
                </button>

            </div>
        </div>

        <!-- Contestar Llamada - Llamada Entrante -->
        <div  class="call-btn" *ngIf="compareViewDialerStatus('INCOMING_CALL') || compareViewDialerStatus('CALL_ON_HOLD___INCOMING_CALL')" >
            <div class="outgoing-call-btn">

                <!-- Modal con Menú de Opciones
                      <button class="btn rounded-circle btn-answer-call" (click)="showModalAnswerIncomingCall()">
                        <i class="fa-solid fa-phone"></i>
                      </button> 
                -->

                <!-- Contestar llamada entrante -->
                <button class="btn rounded-circle btn-answer-call" (click)="answerIncomingCall()">
                    <i class="fa-solid fa-phone"></i>
                </button>

                <!-- Rechazar Llamada entrante y Enviar al Buzon de Voz -->
                <button class="btn rounded-circle voicemail-action" (click)="transferCallToVoicemail()">
                    <i class="fa-solid fa-voicemail"></i>
                </button>

            </div>
        </div>

        <!-- Deterner Llamada [ngClass]="{'btn-reject-incoming-call': compareViewDialerStatus('INCOMING_CALL')}" -->
        <div class="call-btn" *ngIf=" isTheCallEnabledToHangUp() && (keypadEnabled == 'ACTIVE_CALL_OPTIONS' || keypadEnabled == 'OUTGOING_CALL_OPTIONS' )"  >

            <div class="cancel-call-btn">

                <!-- 
                    Modal con Menú de Opciones
                    <button *ngIf="!connectedCall" class="btn rounded-circle modal-action" (click)="showModalCancelIncomingCall()">
                        <i class="fa-solid fa-phone-slash"></i>
                    </button> 
                -->

                <!-- Colgar Llamada -->
                <button class="btn rounded-circle hangup-action" (click)="hangUpCall()" >
                    <i class="fa-solid fa-phone-slash"></i>
                </button>

            </div>
        </div>

        <!--- LLamada activa y llamada entrante -->
        <div class="active-call--incoming-call" *ngIf="compareViewDialerStatus('ACTIVE_CALL___INCOMING_CALL')">

             <!-- Finalizar y Contestar - Llamada Conectada - Llamada en Espera  -->
            <div class=" btn-finish-answer-call" (click)="endCallAndAnswerIncomingCall()" >

                <div  class="btn rounded-circle hold-call-btn-end">
                    <i class="fa-solid fa-phone"></i>
                </div>

                <div  class="btn rounded-circle hold-call-btn-answer ">
                    <i class="fa-solid fa-phone"></i>
                </div>

                <div class="desc-btn">
                    End & Accept
                </div>

            </div>
            
            <!-- Poner en Espera y Contestar llamada entrante- Llamada Conectada - Llamada en Espera -->
            <div class=" btn-hold-answer-call"  (click)="holdCurrentCallAndAnswerIncomingCall()" >

                <div class="btn rounded-circle hold-call-btn-hold">
                    <i class="fa-solid fa-pause"></i>
                </div>

                <div class="btn rounded-circle hold-call-btn-answer ">
                    <i class="fa-solid fa-phone"></i>
                </div>

                <div class="desc-btn">
                    Hold & Accept
                </div>
            </div>

            <!-- Rechazar Llamada entrante y Enviar al Buzon de Voz -->
            <button class="btn rounded-circle voicemail-action" (click)="transferCallToVoicemail()">
                <i class="fa-solid fa-voicemail"></i>
            </button>

        </div>
        
    
    </div>

    <div class="widget-footer">
        XPhone 360
    </div>

</div>
