<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-8 align-self-center mb-4">
            <div class="d-flex">
                <a appWorkspaceRouterLink="/additional-stop-rules/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New LD Additional Stop Rules
                </a>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-7 filtered-list-search align-self-center mb-4">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center mb-3">
            
        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Maxed Distance Traveled</th>
                                <th>Price Per Stop</th>
                                <th>Price Per Mile</th>
                                <th>Max Miles Free</th>
                                <th>Created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage} index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.type}}</td>
                                <td>{{row.maxed_distance_traveled}}</td>
                                <td>{{row.price_per_stop}}</td>
                                <td>{{row.price_per_mile}}</td>
                                <td>{{row.max_miles_free}}</td>
                                <td>{{row.created | date}}</td>
                                <td>
                                    <a appWorkspaceRouterLink="/additional-stop-rules/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
