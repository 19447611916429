import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(seconds: string): string {
    if(seconds == null){
      return null;
    }
    
    // const hour = Math.floor(seconds / 3600);
    // const minute = Math.floor((seconds % 3600) / 60);
    // const second = seconds % 60;

    // const hh = hour.toString().padStart(2, '0');
    // const mm = minute.toString().padStart(2, '0');
    // const ss = second.toString().padStart(2, '0');

    // return `${hh}:${mm}:${ss}`;

    // Extraer el número de segundos del string
    var minutos = 0;
    var segundos = 0;

    if (seconds.includes('M')) { // Si la duración contiene minutos
        var minutosStr = seconds.match(/\d+(\.\d+)?M/)[0];
        minutos = parseFloat(minutosStr.slice(0, -1));
    }

    if (seconds.includes('S')) { // Si la duración contiene segundos
        var segundosStr = seconds.match(/\d+(\.\d+)?S/)[0];
        segundos = parseFloat(segundosStr.slice(0, -1));
    }

    // Convertir minutos a segundos
    var totalSegundos = minutos * 60 + segundos;

    // Calcular las horas, minutos y segundos
    var horas = Math.floor(totalSegundos / 3600);
    var minutosRestantes = Math.floor((totalSegundos % 3600) / 60);
    var segundosRestantes = Math.floor(totalSegundos % 60);
    
    // Formatear los valores para que tengan dos dígitos
    var horasFormateadas = ('0' + horas).slice(-2);
    var minutosFormateados = ('0' + minutosRestantes).slice(-2);
    var segundosFormateados = ('0' + segundosRestantes).slice(-2);
    
    // Devolver el tiempo formateado
    return horasFormateadas + ':' + minutosFormateados + ':' + segundosFormateados;

  }

}
