import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';
import { PaymentGatewaySetting } from 'src/app/entities/workspace/payment-gateway-setting';
import { PaymentGatewaySettingsService } from 'src/app/services/companies/payment-gateway-settings.service';

declare const jQuery: any;
@Component({
    selector: 'app-payment-gateway-settings-form',
    templateUrl: './payment-gateway-settings-form.component.html',
    styleUrls: ['./payment-gateway-settings-form.component.scss']
})
export class PaymentGatewaySettingsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: PaymentGatewaySetting;

    constructor(
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private paymentGatewaySettingsService: PaymentGatewaySettingsService,
    ) {
        this.dataForm = new PaymentGatewaySetting();
    }

    ngOnInit() {
       
    }

    ngAfterViewInit(): void{
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        }); 
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.paymentGatewaySettingsService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.paymentGatewaySettingsService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/payment-gateway-settings');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Realiza la inicializacion de objetos
     */
    changeGateway(): void {
        // obtiene datos que no se deben resetear
        const name = this.dataForm.name;
        const id = this.dataForm.id;
        // limpia el objeto
        this.dataForm = new PaymentGatewaySetting();
        // re asigna datos que se deben conservar
        this.dataForm.name = name;
        this.dataForm.id = id;
    }

}
