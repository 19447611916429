import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PhoneExtensionsService {

    constructor(private http: HttpClient) {

    };


    getAllExtensions(): Promise<Array<PhoneExtension>> {
        return this.http.get<Array<PhoneExtension>>(environment.api.workspace + '/phone-extension').toPromise();
    }

    getAllByCompany(companyId): Promise<Array<PhoneExtension>> {
        return this.http.get<Array<PhoneExtension>>(environment.api.workspace + '/pbx-phone-extensions-company/' + companyId).toPromise();
    }


    getById(id: string) {
        return this.http.get<PhoneExtension>(environment.api.workspace + '/phone-extension/' + id).toPromise();
    }

    save(dataForm: PhoneExtension): Promise<any> {
        console.log('dataForm save', dataForm);

        if (dataForm.id === null) {


            return this.add(dataForm);
        }
        return this.edit(dataForm);
    };

    add(dataForm: PhoneExtension) {
        console.log('this.dataform', dataForm);

        return this.http.post(environment.api.workspace + '/phone-extension', dataForm).toPromise();
    };

    edit(dataForm: PhoneExtension) {
        return this.http.put(environment.api.workspace + '/phone-extension/' + dataForm.id, dataForm).toPromise();

    };

    remove(id: string): Promise<Array<PhoneExtension>> {
        return this.http.delete<Array<PhoneExtension>>(environment.api.workspace + '/phone-extension/' + id).toPromise();
    };

    getPhonesWorkspace(): Promise<any> {
        return this.http.get(environment.api.workspace + '/employeed').toPromise();
    }

}
