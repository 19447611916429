export class EstimateWorker {
    public id: string;
    public hours:number;
    public cost_per_hour:number;
    public miles: number;
    public cost_per_mile:number;
    public total:number;
    public flat_rate_cost:number;
    public look_data?:boolean;
    public workers:number;
    public trucks:number;
    public manual_hours: number;
    public manual_miles: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.hours = null;
        this.cost_per_hour = null;
        this.miles = null;
        this.cost_per_mile = null;
        this.total = null;
        this.flat_rate_cost = null;
        this.look_data = null;
        this.workers = null;
        this.trucks = null;
        this.manual_hours = null;
        this.manual_miles = null;
    }

}
