import { DocumentType } from 'src/app/entities/workspace/document-type';
import { DocumentsTypesService } from 'src/app/services/companies/documents-types.service';
import { HelperService } from 'src/app/services/helper.service';

import { Component, OnInit } from '@angular/core';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-documents-types',
  templateUrl: './documents-types.component.html',
  styleUrls: ['./documents-types.component.scss']
})
export class DocumentsTypesComponent implements OnInit {
  rows: Array<DocumentType>;

  constructor(
    private documentsTypes: DocumentsTypesService,
    public helperService: HelperService,
  ) {
    this.rows = [];
  }
  actualPage = 1;


  ngOnInit(): void {
  }

    ngAfterViewInit(): void{
        this.load();
    }


  private load() {
    this.helperService.showLoadingMxpro360();
    this.documentsTypes
      .getAll()
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure do you want to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.documentsTypes
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Attention!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

  /**
   * setea el default para un document type
   * @param row 
   */
  setDefault(row: DocumentType) {
    this.helperService.showLoadingMxpro360();
    if (row.default) {
      const dataDefault = {
        id: row.id,
        usage_context: row.usage_context
      };
      this.setDefaultApi(dataDefault);
    } else {
      this.deleteDefaultApi(row.id);
    }
    this.helperService.hideLoadingMxpro360();
  }

  /**
   * envía el default seleccionado a la api
   * @param dataDefault 
   */
  setDefaultApi(dataDefault) {
    this.helperService.showLoadingMxpro360();
    this.documentsTypes
      .setDefault(dataDefault)
      .then(() => {
        this.load();
        swal(
          'Updated!',
          'Default prefix for ' + dataDefault.usage_context + ' module was updated',
          'success'
        );
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  /**
   * elimina un default en la api
   */
  deleteDefaultApi(id: string) {
    this.helperService.showLoadingMxpro360();
    this.documentsTypes
      .removeDefault(id)
      .then((response) => {
        this.load();
        swal(
          'Updated!',
          'Default prefix removed',
          'success'
        );
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
}
