<!-- <label class="control-label">Payperiods {{ payPeriod }} </label> -->


<!-- <select [(ngModel)]="payPeriod" (change)="actualizarFechas()">
    <option *ngFor="let option of payPeriodOptions" [value]="option">{{ option }}</option>
</select> -->

<div class="form-group">
    <select [(ngModel)]="selectedPeriod" name="selectedPeriod" class="form-control"
        (change)="listenChangesSelectedPeriod()">
        <option *ngFor="let period of periods" [ngValue]="period">
            {{ formatFullDate(period.start) }} - {{ formatFullDate(period.end) }}
        </option>
    </select>
</div>