import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-last5-tickets-global',
  templateUrl: './last5-tickets-global.component.html',
  styleUrls: ['./last5-tickets-global.component.scss']
})
export class Last5TicketsGlobalComponent implements OnInit {
    lastTicketsGlobal: any;

    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.lastTicketsGlobal = [];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.getLastTicketsGlobalCompany();
        } else {
            this.getLastTicketsGlobalWorkspace();
        }
    }


    getLastTicketsGlobalWorkspace() {

        this.dashboardWorkspaceService
            .getLastTicketsGlobal()
            .then((response) => {
                this.lastTicketsGlobal = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


    getLastTicketsGlobalCompany() {

        this.dashboardComopanyService
            .getLastTicketsGlobal()
            .then((response) => {
                this.lastTicketsGlobal = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }



}
