import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { HelperService } from 'src/app/services/helper.service';
import { StorageCompaniesService } from 'src/app/services/workspaces/storage-companies.service';

declare var google;
declare const jQuery;
declare const swal;


@Component({
  selector: 'app-storage-companies',
  templateUrl: './storage-companies.component.html',
  styleUrls: ['./storage-companies.component.scss']
})
export class StorageCompaniesComponent implements OnInit {
  dataForm: StorageCompany;
  name: string;
  phone: string;
  rows: Array<StorageCompany>;

  @Output()
  onChange: EventEmitter<any>;

  @ViewChild('inputSearch')
  inputSearch: ElementRef;

  constructor(
    private storageCompaniesService: StorageCompaniesService,
    private helperService: HelperService
  ) {
    this.dataForm = new StorageCompany();
    this.rows = [];
  }
  actualPage = 1;
  filterSearch = '';

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.load();
  }

  private load() {
    this.helperService.showLoadingMxpro360();
    this.storageCompaniesService
      .getAll()
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  setFrom(from) {
    this.dataForm.address = from;
  }

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.storageCompaniesService
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

}
