<div class="layout-spacing layout-top-spacing">

    <div #contenedor class="top-container-estimate row">

        <div class="title-estimate-details">
            <h3>
                Estimate Details
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/estimates">
                            Estimates
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <span title="Copy Job Number" class="tooltipmxp clickable copy-job-number" (click)="copyText(estimate.document.code)">{{estimate.document.code}}</span>
                    </li>
                    <li class="breadcrumb-item estimate-reference active" aria-current="page">
                        REF: {{estimate.reference}}

                        <a (click)="editEstimateReference()" class="btn btn-success btn-edit-reference">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </a>
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col top-widgets-estimate-details">
            <div class="d-flex follow-ups-section">

                <a class="btn btn-secondary" (click)="openDuplicateLeadModal()" *ngIf="estimate.cancellation != null">
                    <i class="fa-solid fa-copy mr-2"></i>
                    Duplicate
                </a>

                <ng-container *ngIf="estimate.broker_id == null">
                    <div class="widget-content widget-content-area widget-follow-ups p10-22">
                        <div class="d-flex justify-content-between">
                            <div class="title">Follow-Ups</div>
                            <div class="btn-actions">
                                <button class="btn btn-add-follow-up" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                (click)="openModalFollowUpReminders()">
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                                <button class="btn btn-view-follow-up" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                (click)="openModalFollowUpRemindersView()" [disabled]="followUpReminders.length == 0">
                                    <i class="fa-solid fa-eye"></i>
                                </button>
                                <button class="btn btn-calendar-follow-up" appCompanyRouterLink="/follow-ups-reminders">
                                    <i class="fa-solid fa-calendar"></i>
                                </button>
                            </div>
                            <div class="next-follow-up-date tooltipmxp" title="Next Follow-up Date">
                                <span *ngIf="nextFollowUps.id !== null">{{nextFollowUps.date | dateTime}}</span>
                                <span *ngIf="nextFollowUps.id == null">None Upcoming</span>
                            </div>
                        </div>  
                    </div>
                </ng-container>

                <!-- <div id="myDiv" style="width: 100%; height: 200px; background-color: lightblue;">
                    Este es el div cuyo tamaño se va a monitorear.
                </div> -->

                <div>
                    <div class="widget-content widget-content-area widget-category">

                        <div *ngIf="!estimate.booked" class="d-flex">
                            <div class="title">Category</div>
                            <select name="category" id="category" class="form-control estimate-details-select"
                                [(ngModel)]="estimate.category" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE' && estimate.broker_id">
                                <option [selected]="estimate.category == 'NEW'" value="NEW">New</option>
                                <option [selected]="estimate.category == 'HOT'" value="HOT">Hot</option>
                                <option [selected]="estimate.category == 'WARM'" value="WARM">Warm</option>
                                <option [selected]="estimate.category == 'COLD'" value="COLD">Cold</option>
                                <option [selected]="estimate.category == 'OPEN'" value="OPEN">Open</option>
                            </select>
                            <button *ngIf="estimate.category != 'NEW'" class="tooltipmxp btn btn-save-category" (click)="saveCategory()"
                                title="Save Basic Information"[hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </button>
                        </div>

                        <div *ngIf="estimate.booked" class="d-flex">
                            <div class="title">Operation</div>
                            <div class="operation-status tooltipmxp" title="Operation Status">
                                <span>{{estimate.status | filterReplace}}</span>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>        

    </div>

    <div *ngIf="showEstimateButtons" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-spacing align-self-center">
        <div class="rounded-pills-icon text-center">
            <ul class="nav nav-pills" id="rounded-pills-icon-tab" role="tablist">

                <li class="nav-item" [appCanUserCompany]="['ESTIMATE_DETAIL', 'ESTIMATE_CREATE_MOVING', 'ESTIMATE_CREATE_AUTOTRANSPORT', 'ESTIMATE_UPSALES']">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/estimate">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-file-text">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                        </svg>
                        Estimate
                    </a>
                </li>

                <li class="nav-item cursor-pointer" appCanUserCompany="ESTIMATE_BALANCE_VIEW" >
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/payments">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-dollar-sign">
                            <line x1="12" y1="1" x2="12" y2="23"></line>
                            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>
                        Payments
                    </a>
                </li>

                <li class="nav-item" appCanUserCompany="ESTIMATE_EMAILS_VIEW">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/emails">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-mail">
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                            </path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                        Emails
                    </a>
                </li>

                <li class="nav-item cursor-pointer" appCanUserCompany="ESTIMATE_SIGNATURE_VIEW">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/estimates-signatures">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" aria-hidden="true"
                            focusable="false" data-prefix="fas" data-icon="file-signature"
                            class="svg-inline--fa fa-file-signature fa-w-18 icon-signature" role="img"
                            viewBox="0 0 576 512">
                            <path fill="currentColor"
                                d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z" />
                        </svg>
                        Signatures
                    </a>
                </li>

                <li class="nav-item cursor-pointer" appCanUserCompany="JOB_OPERATION_VIEW" *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT'">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/operations">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-truck">
                            <rect x="1" y="3" width="15" height="13"></rect>
                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                        </svg>
                        Operations
                    </a>
                </li>
<!--
                <li class="nav-item cursor-pointer"
                    *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT'">
                    <a class="nav-link cursor-pointer" appCompanyRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/invoices">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-clipboard">
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2">
                            </path>
                            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                        </svg>
                        Invoices
                    </a>
                </li> -->

                <li class="nav-item cursor-pointer" [appCanUserCompany]="['JOB_STORAGE_VIEW', 'JOB_STORAGE_MANAGER']"
                    *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT' && estimate.status !== 'WORK_CARRIED_OUT'">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/storage">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-home">
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        Storage
                    </a>
                </li>

                <li class="nav-item cursor-pointer" [appCanUserCompany]="['JOB_STORAGE_VIEW', 'JOB_STORAGE_MANAGER']"
                    *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT' && estimate.status !== 'WORK_CARRIED_OUT'">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/storage-invoices">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-home">
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        Storage Invoices
                    </a>
                </li>

                <li class="nav-item cursor-pointer" [appCanUserCompany]="['JOB_OUTSOURCINGS_VIEW', 'Job_OUTSOURCINGS_MANAGER']"
                    *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT' && estimate.status !== 'WORK_CARRIED_OUT'">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active" appCompanyRouterLink="/moving/{{estimate.id}}/outsourcings">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-merge"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M6 21V9a9 9 0 0 0 9 9"></path></svg>
                        Outsourcings
                    </a>
                </li>

                <li class="nav-item cursor-pointer" [appCanUserCompany]="['JOB_COMPLETIONS_VIEW', 'JOB_COMPLETIONS_MANAGER']"
                    *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT' && estimate.status !== 'WORK_CARRIED_OUT'">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/completions">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                        Completions
                    </a>
                </li>

                <li class="nav-item cursor-pointer" *ngIf="estimate.booked" [appCanUserCompany]="['JOB_TICKETS_VIEW', 'JOB_TICKETS_MANAGER']" >
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/tickets">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-bookmark">
                            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        Tickets
                    </a>
                </li>

                <li class="nav-item cursor-pointer" *ngIf="estimate.booked && estimate.service !== 'AUTO_TRANSPORT' && estimate.status !== 'WORK_CARRIED_OUT'">
                    <a class="nav-link cursor-pointer" appRouterLinkActive="active"
                        appCompanyRouterLink="/moving/{{estimate.id}}/labels">
                        <i class="fa-solid fa-qrcode icon-wh-24"></i>
                        Labels
                    </a>
                </li>
                <li class="nav-item cursor-pointer" appCanUserCompany="ESTIMATE_LOG_VIEW">
                    <a class="nav-link cursor-pointer" appCompanyRouterLink="/moving/{{estimate.id}}/logs" appRouterLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-book-open">
                            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                        </svg>
                        Logs
                    </a>
                </li>
                <li class="nav-item cursor-pointer" [appCanUserCompany]="['UPLOAD_MEDIA_FILES']">
                    <a class="nav-link cursor-pointer" appCompanyRouterLink="/moving/{{estimate.id}}/media-files" appRouterLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-book-open">
                            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                        </svg>
                        Media Files
                    </a>
                </li>
                <li class="nav-item cursor-pointer" [appCanUserCompany]="['CREATE_A_CLAIM']" *ngIf="estimate.booked">
                    <a class="nav-link cursor-pointer" appCompanyRouterLink="/moving/{{estimate.id}}/claims" appRouterLinkActive="active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-bookmark">
                            <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                        </svg>
                        Claims
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <!-- header -->

    <div class="row mb-2">

        <div class="col-5">
            <div class="alert alert-arrow-right alert-icon-right alert-light-danger text-title-alert m-0" role="alert">
                <span>
                    <span>Due Balance {{totalBalance | currency}}</span>             
                   
                    <ng-container *ngIf="targetStorage || targetOther">
                        <button title="Storage Invoice Due" data-html="true" class="info-due-balance tooltipmxp" *ngIf="targetStorage">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-alert-circle" style="animation: zoom-in-zoom-out 2s ease-out infinite;">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12" y2="16"></line>
                            </svg>
                        </button>

                        <button title="Job Due" data-html="true" class="info-due-balance tooltipmxp"  *ngIf="targetOther">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-alert-circle" style="animation: zoom-in-zoom-out 2s ease-out infinite;">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12" y2="16"></line>
                            </svg>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="targetStorage && targetOther">
                        <button title="Job Due &#013;&#010;Storage Invoice Due" data-html="true" class="info-due-balance tooltipmxp" *ngIf="targetStorage">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-alert-circle" style="animation: zoom-in-zoom-out 2s ease-out infinite;">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="12"></line>
                                <line x1="12" y1="16" x2="12" y2="16"></line>
                            </svg>
                        </button>
                    </ng-container>

                    <!-- <button class="info-due-balance" *ngIf="!targetStorage">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-alert-circle">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="12"></line>
                            <line x1="12" y1="16" x2="12" y2="16"></line>
                        </svg>
                    </button> -->
                </span>
            </div>
        </div>

        <div class="col-7">

            <div class="schedule-buttons">

                <!-- Book -->
                <button class="btn btn-book action-button" (click)="bookLead()" *ngIf="!estimate.booked">                    
                    <span>Book Estimate</span>
                    <i class="fa-solid fa-briefcase ml-3"></i>
                </button>

                <!-- Release -->
                <button
                    appCanUserCompany="ESTIMATE_RELEASE"
                    class="btn btn-release action-button mb-2"
                    *ngIf="estimate.booked && estimate.status !== 'WORK_CARRIED_OUT'"
                    (click)="bookOrReleaseLead()"
                    [disabled]="estimate.cancellation != null">

                    <span>Job Release</span>                    
                    <i class="fa-solid fa-boxes-packing ml-3"></i>
                </button>

                <!-- QA Release -->
                <button
                    class="btn btn-qa-release action-button mb-2"
                    [ngClass]="{'btn-qa-release' : estimate.qa_status === 'COMPLETE', 'btn-qa-job': estimate.qa_status === 'PENDING'}"
                    *ngIf="estimate.booked && estimate.status !== 'WORK_CARRIED_OUT' && estimate.qa_status !== 'RELEASE'"
                    (click)="QAStatus('RELEASE')"
                    [disabled]="estimate.cancellation != null">                 

                    <ng-container *ngIf='estimate.qa_status === "PENDING"'>
                        <span>QA Job</span>
                        <i class="fa-solid fa-file-pen ml-3"></i>
                    </ng-container>

                    <ng-container *ngIf='estimate.qa_status === "COMPLETE"'>
                        <span>QA Release</span>
                        <i class="fa-solid fa-file-arrow-up ml-3"></i>
                    </ng-container>
                </button>

                <!-- Complete QA -->
                <button
                    class="btn btn-complete-qa action-button mb-2"
                    *ngIf="estimate.booked && estimate.status !== 'WORK_CARRIED_OUT' && estimate.qa_status === 'RELEASE'"
                    (click)="QAStatus('COMPLETE')"
                    [disabled]="estimate.cancellation != null">

                    <span>Complete QA</span>
                    <i class="fa-solid fa-clipboard-check ml-3"></i>
                </button>

                <!-- Cancel -->
                <button
                    appCanUserCompany="CANCEL_JOB"
                    class="btn btn-cancel action-button mb-2"
                    *ngIf="estimate.booked && estimate.status !== 'WORK_CARRIED_OUT'"
                    (click)="openCancelJobModal()"
                    [disabled]="estimate.cancellation != null">

                    <span>Job Cancel</span>
                    <i class="fa-solid fa-truck-ramp-box ml-3"></i>
                </button>

            </div>

        </div>

    </div>

    <div class="estimate-details-view" [ngClass]="{'job-cancelled': estimate.cancellation}">
        <router-outlet></router-outlet>
    </div>

</div>

<!-- Modal Adjust Max Discount -->
<div class="modal fade" #cancelJobModal tabindex="-1" role="dialog" aria-labelledby="modalCancelJob"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Cancel Job
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="form-group">
                        <label>
                            Please select a reason for the cancelation
                        </label>
                        <div class="list-title">
                            <select  [(ngModel)]="cancelReason"
                                placeholder="Type" class="form-control" name="load_options">                                
                                <option value="COMPANY_CANCELED_CREW_NO_SHOW">Company canceled crew no show</option>
                                <option value="COMPANY_CANCELED_WORKSITE_HAZARD">Company canceled worksite hazard</option>
                                <option value="CUSTOMER_CANCELED_CUSTOMER_NOT_AVAILABLE_READY_FOR_PICKUP">Customer canceled not available ready for pickup</option>
                                <option value="CUSTOMER_CANCELED_PRIOR_TO_PICKUP_DATES">Customer canceled prior to pickup dates</option>
                                <option value="CUSTOMER_CANCELED_REFUSED_CREW">Customer canceled refused crew</option>
                                <option value="CUSTOMER_CANCELED_REFUSED_ORIGINAL_ORDER_REFUSED_REVISED_ORDER">Customer canceled refused original order refunded revised order</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div class="toggle-switch mt-3">                
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="makeRefund" name="makeRefund" [(ngModel)]="makeRefund">
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="makeRefund" class="checkbox-label" [ngClass]="{'filter-disabled': !makeRefund}">
                            Refund
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveCancelation()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Duplicate Moving Options -->
<div class="modal fade" #duplicateLeadModal tabindex="-1" role="dialog" aria-labelledby="duplicateLeadModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-duplicate-moving" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followupsremarksTitle">
                    Duplicate Moving Options
                </h5>
            </div>
            <div class="modal-body">
                <div>
                    <label for="inputState">
                        Moving Type
                    </label>
                    <div class="list-title">
                        <select id="autotransport_type" placeholder="Type" class="form-control" [(ngModel)]="movingDuplicateType">
                            <option value="" disabled>Select a Moving Type</option>
                            <option value="LONG">Long</option>
                            <option value="LOCAL">Local</option>
                            <option value="AUTO_TRANSPORT">Auto Transport</option>
                            <option value="FREIGHT">Freight</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="duplicateLead()" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Adjust REf -->
<div class="modal fade" #editEstimateReferenceModal tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Set Estimate Reference
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="reference">
                        Reference
                    </label>
                    <div class="list-title">
                        <input type="text" name="reference" class="form-control" [(ngModel)]="editReference">
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveEstimateReference()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add Follow Up -->
<div class="modal fade" #modalfollowUpsReminders id="modalfollowUpsReminders" tabindex="-1" role="dialog"
    aria-labelledby="customerremarksTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content mt-140">
            <div class="modal-header">
                <h5 class="modal-title" id="customerremarksTitle">
                    Add Follow Up
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="form-group col-md-6 p-0">
                        <label for="inputState">
                            Date
                        </label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                </span>
                            </div>
                            <app-date-picker class="width-date-picker follow-up-date-picker" [enableTime]="true"
                                [value]="followUpReminder.date" [placeholder]="'Not Assigned'"
                                (onChange)="changeDate($event)">
                            </app-date-picker>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">select Date.</small>
                    </div>
                </div>
                <div class="">
                    <label for="inputState">
                        Comment
                    </label>
                    <div class="input-group mb-1">
                        <textarea class="form-control" name="estimate_customer_remarks" id="estimate_customer_remarks"
                            [(ngModel)]="followUpReminder.remarks" placeholder="Write your comment here" cols="30"
                            rows="5">
                        </textarea>
                    </div>
                    <small id="emailHelp1" class="form-text text-muted mb-4">Write Comment.</small>
                </div>
                <div class="">
                    <div class="toggle-switch mt-3">
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="qa_appointment" name="qa_appointment" [(ngModel)]="followUpReminder.qa_appointment">
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="qa_appointment" class="checkbox-label" [ngClass]="{'filter-disabled': !followUpReminder.qa_appointment}">
                            Set as QA Appointment
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addFollowUpReminder()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal View Follow Up -->
<div class="modal fade" #modalfollowUpsRemindersView id="modalfollowUpsRemindersView" tabindex="-1" role="dialog"
    aria-labelledby="customerremarksTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content mt-140">
            <div class="modal-header">
                <h5 class="modal-title" id="customerremarksTitle">
                    Follow-ups
                </h5>
                <a title="Follow-ups" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                    (click)="openModalFollowUpReminders()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-plus-circle">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                </a>
            </div>
            <div class="modal-body">     

                <div class="timeline-alter" *ngIf="followUpReminders.length > 0">
                    <div class="table-responsive">
                        <table class="table table-bordered mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Remark</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of followUpReminders; index as i;">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="follow-up-date">
                                            {{row.date | dateTime}}
                                        </div>
                                    </td>
                                    <td><p [innerHTML]="row.remarks"></p></td>
                                    <td>
                                        <div class="btn-actions-follow-up">
                                            <button (click)="editFollowUpReminders(row)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                class="btn btn-success rounded-circle edit-btn"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button (click)="deleteFollowUpReminders(row.id)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash-can"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                        

            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>