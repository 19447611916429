export class PhoneSystemCallDTO {

    call_id: string;
    phone_number: string;
    contact_name: string;
    pairing_date: number;
    participant_id: string;
    participant_token: string;
    direction: "INCOMING" | "OUTGOING";
    mute: boolean;

    constructor() {

        this.call_id = null;
        this.phone_number = null;
        this.contact_name = null;
        this.pairing_date = null;
        this.participant_id = null;
        this.participant_token = null;
        this.direction = null;
        this.mute = false;
    }
}
