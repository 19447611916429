<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Tariff Versions
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Tariff Versions
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-7 col-lg-4 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/tariff-versions/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Tariff Version
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">

            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Measure Unit</th>
                                <th>Is Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let version of tariffVersions | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{ version.name }}</td>
                                <td>{{ version.measure_unit }}</td>
                                <td>
                                    <label class="container">
                                        <input type="checkbox" [(ngModel)]="version.is_active"
                                        (change)="versionIsActive(version)"
                                        [checked]="version.is_active">
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td>
                                    <a appWorkspaceRouterLink="/tariff-versions/{{version.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(version.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
