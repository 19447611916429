import { BuyNumbersService } from 'src/app/services/workspaces/buy-numbers.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-buy-numbers',
  templateUrl: './buy-numbers.component.html',
  styleUrls: ['./buy-numbers.component.scss']
})
export class BuyNumbersComponent implements OnInit {

  numbers: { number: number, selected: boolean }[];

  // Modals
  @ViewChild('buyNumbersTollFreeModal') buyNumbersTollFreeModal: ElementRef;
  @ViewChild('disconnectNumbersModal') disconnectNumbersModal: ElementRef;

  @ViewChild('modalBuyNumbersTollFree') modalBuyNumbersTollFree: ElementRef;
  @ViewChild('buynumber') buynumber: ElementRef;

  numberTollFree: { number: number, selected: boolean }[];
  phones: Array<any>;
  phonesTollFree: Array<any>;
  disconnectPhones:Array<any>;
  numbersPhones: any;
  codeArea: string;
  quantity: string;
  indexSave;
  indexSaveTollFree;
  buyNumbersDisable: boolean = false;
  buyNumbersTollFreeDisable: boolean = false;
  codeAreaTollFreewildcardPattern: string;
  quantityTollFree: string;
  typeModal: string;
  successfull: boolean = false;
  actualPage: number;
  filterSearch: string;

  constructor(
    private buyNumbersService: BuyNumbersService,
    private helperService: HelperService
  ) {
    this.phones = [];
    this.phonesTollFree = [];
    this.disconnectPhones = [];
    this.quantity = '';
    this.codeArea = '';
    this.quantityTollFree = '';
    this.codeAreaTollFreewildcardPattern = '';
    this.actualPage = 1;
    this.filterSearch = '';
  }

  ngOnInit(): void {
    //this.getPhoneNumbers();

    this.listNumbers();


  }

  public searchNumber() {


       this.helperService.showLoadingMxpro360();

    if (this.quantity.length === 0) {
      swal({
        title: 'Attention!',
        text: 'Enter the quantity of numbers to search for',
        type: 'warning',
        confirmButtonText: 'OK'
      })
      return this.helperService.hideLoadingMxpro360();
    }

    if (this.codeArea.length === 3) {

      this.buyNumbersService
        .getAll(this.codeArea, this.quantity)
        .then((response:any) => {
          this.numbers = this.proccessNumbersRegular(response);
          this.buyNumbersDisable = true;



        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();

        });
      return;
    }
    swal({
      title: 'Attention!',
      text: 'Enter the area code',
      type: 'warning',
      confirmButtonText: 'OK'
    })
    this.helperService.hideLoadingMxpro360();


  }
  public searchNumberTollFreewildcardPattern() {
    this.helperService.showLoadingMxpro360();

    if (this.quantityTollFree.length === 0) {
      swal(
        'Error!',
        'please enter the number of numbers to see.',
        'error'
      );
      return this.helperService.hideLoadingMxpro360();
    }

    switch (this.codeAreaTollFreewildcardPattern.length) {
      case 0:
        swal(
          'Error!',
          'enter the correct area code.',
          'error'
        );
        break;
      case 1:
        this.codeAreaTollFreewildcardPattern = this.codeAreaTollFreewildcardPattern + '**';
        break;
      case 2:
        this.codeAreaTollFreewildcardPattern = this.codeAreaTollFreewildcardPattern + '*';
        break;
    }
    this.buyNumbersService
      .getAllTollFreewildcardPattern(this.codeAreaTollFreewildcardPattern.toString(), this.quantityTollFree.toString())
      .then((response) => {

        this.numberTollFree = this.proccessResponseNumbersTollFreewildcardPattern(response);
        this.buyNumbersTollFreeDisable = true;

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private proccessNumbersRegular(numbers) {
    let numbersResponse = [];

    for (let number of numbers) {

          numbersResponse.push(
        {
          number: number,
          selected: false
        }
      );
    }

    return numbersResponse;
  }


  private proccessResponseNumbersRegular(numbers) {
    let numbersResponse = [];


    for (let number of numbers) {

          numbersResponse.push(
        {
          number: number.number,
          selected: false
        }
      );
    }
    return numbersResponse;
  }
  private proccessResponseNumbersTollFreewildcardPattern(numbersTollFree) {
    let numbersResponse = [];

    for (let numberTollFree of numbersTollFree) {
     numbersResponse.push(
        {
          number: numberTollFree,
          selected: false
        }
      );
    }
    return numbersResponse;
  }

  public aTNumbers(tmpPhone) {

    tmpPhone.selected = true;
    this.indexSave = this.phones.includes(tmpPhone.number)
    if (this.indexSave === false) {
      this.phones.push(tmpPhone.number);
    } else {
      let indice = this.phones.indexOf(tmpPhone.number);
      this.phones.splice(indice, 1)
    }

    console.log('this.phones : ', this.phones);

  }


  public aTNumbersTollFree(tmpPhoneTollFree) {
    tmpPhoneTollFree.selected = true;
    this.indexSaveTollFree = this.phonesTollFree.includes(tmpPhoneTollFree.number)

    if (this.indexSaveTollFree === false) {
      this.phonesTollFree.push(tmpPhoneTollFree.number);
    } else {
      let indice = this.phonesTollFree.indexOf(tmpPhoneTollFree.number);
      this.phonesTollFree.splice(indice, 1)
    }
  }

  public aTNumbersDisconnect(tmpPhone) {

    tmpPhone.selected = true;
    this.indexSave = this.disconnectPhones.includes(tmpPhone)

    if (this.indexSave === false) {
      this.disconnectPhones.push(tmpPhone);
    } else {
      let indice = this.disconnectPhones.indexOf(tmpPhone);
      this.disconnectPhones.splice(indice, 1)
    }
  }


  public buyNumbers() {

    if (this.phones.length) {

      swal({
        title: 'Buy Numbers',
        text: 'you sure you want to buy the selected numbers ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Select!'
      })
        .then((result) => {
          if (result.value) {
            this.successfulPurchase();
          }
        });
    } else{
      swal(
        'Decline!',
        'your number was not bought.',
        'error'
      );

      }
  }

  public successfulPurchaseTollFree(){

    if (this.phonesTollFree.length) {

      jQuery(this.buyNumbersTollFreeModal.nativeElement).modal('show');
    } else{
      swal(
        'Decline!',
        'your number was not bought.',
        'error'
      );

      }
  }
  public buyNumbersTollFree() {
    this.helperService.showLoadingMxpro360();
    this.buyNumbersService
      .addTollFree(this.phonesTollFree)
      .then((response) => {



        this.buyNumbersService
          .saveNumbers(response)
          .then((res) => {

            this.listNumbers();


          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });


      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  public successfulPurchase() {

    this.helperService.showLoadingMxpro360();
    this.buyNumbersService
      .add(this.phones)
      .then((response) => {

    console.log('response to string: ', response);

        this.buyNumbersService
          .saveNumbers(response)
          .then((res) => {

            this.listNumbers();

            console.log('guardado numero response :',res);
            this.closeBuyNumber();
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  public listNumbers() {

    this.helperService.showLoadingMxpro360();
    this.buyNumbersService
      .listNumbers()
      .then((response) => {


        this.numbersPhones = response;
        console.log('number phones aqui :',this.numbersPhones);

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }





  public buyDecline() {
    swal(
      'Decline!',
      'your number was not bought.',
      'error'
    );
  }


  public disconnectNumbers(phoneNumber) {

    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the number?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Select!'
    })
      .then((result) => {
        if (result.value) {
          let cleanPhones =[];
          let cleanIds = [];

          // for (const phones of this.disconnectPhones) {
          //   let ids = undefined;
          //   let newnumber = undefined;
          //   newnumber = phones.number.number;
          //   ids = phones.number.id;
            cleanIds.push(phoneNumber.id);
            cleanPhones.push(phoneNumber.number);

            console.log('phoneNumber !!!!!!!!',phoneNumber);

          // }

          this.helperService.showLoadingMxpro360();
          this.buyNumbersService
            .remove(cleanPhones)
            .then((response) => {


            this.helperService.showLoadingMxpro360();
            this.buyNumbersService

            .deleteNumbers(cleanIds[0])

            .then((res) => {
              this.listNumbers();
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
        }
      });



  }


  public removeNumbers(phoneNumber) {

    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the number?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Select!'
    })
      .then((result) => {


          this.helperService.showLoadingMxpro360();
          this.buyNumbersService
            .removeNumbers(phoneNumber)
            .then((response) => {

              console.log('response de put remove number ', response);




      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });

      });



  }




public disconnectDeleteNumber(){
  jQuery(this.disconnectNumbersModal.nativeElement).modal('show');
}

openBuyNumber() {
  jQuery(this.buynumber.nativeElement).modal('show');
  setTimeout(() => {
      jQuery('#companyInformation').css('background', '#00000069')
  }, 200);
}

closeBuyNumber() {
  jQuery(this.buynumber.nativeElement).modal('hide');
}


openBuyNumberTollFree() {
  jQuery(this.modalBuyNumbersTollFree.nativeElement).modal('show');
  setTimeout(() => {
      jQuery('#companyInformation').css('background', '#00000069')
  }, 200);
}
}
