import { Email } from "./email";
import { Role } from "./role";

export class EmployeeCompanyPermission {

    public id: string;
    public employee: string;
    public company_id: string;
    public role: Role;
    public email: Email;
    public email_work_departments_id: string[];
    public phone_number: string;
    public created: number;
    public updated: number;

    public constructor () { 
        this.id = null;
        this.employee = null;
        this.company_id = null;
        this.role = new Role();
        this.email = new Email();
        this.email_work_departments_id = [];
        this.phone_number = null;
        this.created = null;
        this.updated = null;
    }

}
