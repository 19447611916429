import { Injectable } from '@angular/core';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FeesInformationService {

  constructor(
    private http: HttpClient
  ) { }

  setCubicFeets(idEstimate: string, data) {
    return this.http.put<any>(environment.api.company + '/estimates/' + idEstimate + '/fees-information', data).toPromise();
  }
  setManualDiscountCf(idEstimate: string, data) {
    return this.http.put<any>(environment.api.company + '/estimates/' + idEstimate + '/manual-discount-cf', data).toPromise();
  }
}
