import { Estimate } from './estimate';
import { EstimateFollowUp } from './estimate-follow-up';



export class EstimateFollowUpView extends EstimateFollowUp {

    public estimate: Estimate;

    constructor() {
        super();
        this.estimate = new Estimate();
    }
}
