import { DepositDueBalance } from './deposit-due-balance';
import { Document } from './document';
import { ElectronicSignature } from './electronic-signature';
import { EstimateCancallation } from './estimate-cancallation';
import { EstimateCharges } from './estimate-charges';
import { EstimateDelivery } from './estimate-delivery';
import { EstimateDocument } from './estimate-document';
import { EstimateInsurance } from './estimate-insurance';
import { EstimateLocalTariff } from './estimate-local-tariff';
import { EstimateOperator } from './estimate-operator';
import { EstimatePickup } from './estimate-pickup';
import { EstimateRouteTariff } from './estimate-route-tariff';
import { EstimateStorage } from './estimate-storage';
import { EstimateTariffDiscount } from './estimate-tariff-discount';
import { EstimateVolume } from './estimate-volume';
import { EstimateWorker } from './estimate-worker';

export class Estimate {
    public id: string;
    public document: Document;
    public document_bol: EstimateDocument;
    public cancellation: EstimateCancallation;
    public reference: string;
    public status: 'FOLLOW_UP' | 'UNSUCCESSFUL_SALE' | 'IN_OPERATION' | 'WORK_CARRIED_OUT' | 'CUSTOMER_CANCELED';
    public qa_status: 'PENDING' | 'RELEASE' | 'COMPLETE';
    public qa_released: number;
    public qa_completed: number;
    public qa_starting: number;
    public category: 'NEW' | 'OPEN' | 'HOT' | 'WARM' | 'COLD';
    public urgent: boolean;
    public move_type: 'RESIDENTIAL' | 'COMMERCIAL' | 'MILITARY';
    public binding_type: 'NO_EXCEED' | 'BINDING' | 'NO_BINDING';
    public visual_type: 'ON_SITE' | 'VIRTUAL' | 'WAIVED' | 'PHOTOS';
    public booked: boolean;
    public booked_date: number;
    public confirmed: boolean;
    public request_signature: boolean;
    public service: 'LOCAL' | 'LONG' | 'AUTO_TRANSPORT' | 'FREIGHT';
    public additional_agreement: string;
    public operation_details: string;
    public inventory_editable_by_customer: boolean;
    public sub_total: number;
    public total: number;
    public estimate_labor: EstimateWorker;
    public estimate_travel: EstimateWorker;
    public customer_id: string;
    public contacts_id: string[];
    public volume: EstimateVolume;
    public delivery: EstimateDelivery;
    public pickup: EstimatePickup;
    public packers_packing: EstimateWorker;
    public packers_unpacking: EstimateWorker;
    public operator: EstimateOperator;
    public charges: EstimateCharges;
    public insurance: EstimateInsurance;
    public estimate_route_tariff: EstimateRouteTariff;
    public estimate_local_tariff: EstimateLocalTariff;
    public esignature: ElectronicSignature;
    public company_id: string;
    public lead_company_id: string;
    public broker_id: string;
    public broker_pay: number;
    public deposit_due_balance: DepositDueBalance;
    public walkthrough_status: null | 'STARTED' | 'FINISHED' | 'CONFIRM_INVENTORY' | 'FINISHED_SINED_BOL' | 'FINISHED_VERIFY_IDENTITY' | 'GO_TO_SUMMARY' | 'BY_VOLUME';
    public storages: EstimateStorage;
    public tariff_discount: EstimateTariffDiscount;
    public created: number;
    public updated: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.document = new Document();
        this.document_bol = null;
        this.cancellation = null;
        this.reference = null;
        this.status = 'FOLLOW_UP';
        this.qa_status = 'PENDING';
        this.qa_released = null;
        this.qa_completed = null;
        this.qa_starting = null;
        this.category = 'OPEN';
        this.urgent = false;
        this.move_type = 'RESIDENTIAL';
        this.sub_total = 0;
        this.total = 0;
        this.binding_type = 'BINDING';
        this.visual_type = null;
        this.booked = false;
        this.request_signature = false;
        this.booked_date = null;
        this.confirmed = false;
        this.service = null;
        this.additional_agreement = null;
        this.operation_details = null;
        this.inventory_editable_by_customer = false;
        this.customer_id = null;
        this.contacts_id = [];
        this.volume = new EstimateVolume();
        this.delivery = new EstimateDelivery();
        this.pickup = new EstimatePickup();
        this.estimate_labor = new EstimateWorker();
        this.estimate_travel = new EstimateWorker();
        this.packers_packing = new EstimateWorker();
        this.packers_unpacking = new EstimateWorker();
        this.operator = new EstimateOperator();
        this.charges = new EstimateCharges();
        this.insurance = new EstimateInsurance();
        this.lead_company_id = null;
        this.esignature = null;
        this.estimate_route_tariff = new EstimateRouteTariff();
        this.estimate_local_tariff = new EstimateLocalTariff();
        this.company_id = null;
        this.broker_id = null;
        this.broker_pay = null;
        this.deposit_due_balance = new DepositDueBalance();
        this.walkthrough_status = null;
        this.storages = new EstimateStorage();
        this.tariff_discount = new EstimateTariffDiscount();
        this.created = null;
        this.updated = null;
    }
}
