import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Employee } from 'src/app/entities/workspace/employee';
import { PayrollSetting } from 'src/app/entities/workspace/payroll-setting';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PayrollSettingsService } from 'src/app/services/workspaces/payroll-settings.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';

declare var jQuery;

@Component({
  selector: 'app-payroll-settings',
  templateUrl: './payroll-settings.component.html',
  styleUrls: ['./payroll-settings.component.scss']
})
export class PayrollSettingsComponent implements OnInit {

  @ViewChild('newEmployeePayrollModal') newEmployeePayrollModal: ElementRef;

  public filterSearch: string;  
  public actualPage: number;
  public payrollSettings: PayrollSetting[];
  public employees: Employee[];
  public work_departments: WorkDepartment[];

  constructor(
    private payrollSettingsService: PayrollSettingsService,
    private employeesService: EmployeesService,
    private workDepartmentService: WorkDepartmentsService,
    private helperService: HelperService
  ) {
    this.filterSearch = '';
    this.actualPage = 1;
    this.payrollSettings = [];
    this.employees = [];
    this.work_departments = [];
  }

  ngOnInit(): void {
    this.load();
    this.loadEmployees();
    this.loadworkDepartments();
  }


  public openNewEmployeeModal() {
    jQuery(this.newEmployeePayrollModal.nativeElement).modal('show');
  }


  private load() {
    this.helperService.showLoadingMxpro360();
    this.payrollSettingsService
        .getAll()
        .then((response) => {
            this.payrollSettings = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }


    private loadEmployees() {
      this.helperService.showLoadingMxpro360();

      this.employeesService
          .getAll()
          .then((response) => {
              this.employees = response;
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }


  private loadworkDepartments() {
    this.helperService.showLoadingMxpro360();

    this.workDepartmentService
        .getAll()
        .then((response) => {
            this.work_departments = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}


}
