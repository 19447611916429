import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { Component, OnInit } from '@angular/core';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { Company } from 'src/app/entities/workspace/company';
import { HelperService } from 'src/app/services/helper.service';
import { AuthService } from 'src/app/services/auth.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-employees',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

    employees: Array<EmployeeView>;
    public companies: Array<Company>;
    public maxAccountsDone: boolean;

    constructor (
        private authService: AuthService,
        private employeesService: EmployeesService, 
        private companiesService: CompaniesService,
        private helperService: HelperService
    ) {
        this.employees = [];
        this.companies = [];
        this.maxAccountsDone = false;
    }

    actualPage = 1;
    filterSearch = '';

    ngOnInit(): void {
        this.getCompaniesWorkspace();
    }

    ngAfterViewInit() {
        this.load();
    }
 
    private getCompaniesWorkspace() {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll()
            .then((response) => {
                this.companies = response;
            });
    }

    load() {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getAll()
            .then((response) => {
                // valida si ya se completo el maximo de empleados
                this.maxAccountsDone = this.authService.workspaceSession.workspace.max_accounts !== null && response.length >= this.authService.workspaceSession.workspace.max_accounts;
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    togleStatus(id) {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .togleStatus(id)
            .then((response) => {
                this.load();
                swal(
                    'Status Changed!',
                    'The record has been updated.',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    deleteEmployee(id: string) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the employee?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
          })
            .then((result) => {
              if (result.value) {
                this.helperService.showLoadingMxpro360();
                this.employeesService
                .deleteEmployee(id)
                .then(() => {
                    this.load();
                    swal(
                        'Status Changed!',
                        'The record has been updated.',
                        'success'
                    );
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
              }
            });
    }

    companyName(companyId){
        let companiesName = '';
        for (let company of this.companies) {
            if (companyId == company.id) {
                companiesName = company.name.trim();
            }
        }
        return companiesName;
    }
    showMaxAccountsMessage() {
        swal({
            title: 'Max Accounts',
            text: "Please contact your MoverXpro 360 representative to upgrade to more users! (Max Users " + this.authService.workspaceSession.workspace.max_accounts + ")",
            type: 'warning',
            confirmButtonText: 'OK'
        });
    }
}
