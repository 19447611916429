import { JobInsurance } from '../companies/job-insurance';
import { BoardJob } from './board-job';
import { Document } from './document';
import { JobAppointmentView } from './job-appointment-view';
import { JobCharges } from './job-charges';
import { JobDeliveryFee } from './job-delivery-fee';
import { JobFile } from './job-file';
import { JobOutsourcing } from './job-outsourcing';
import { JobStatus } from './job-status';
import { JobStorage } from './job-storage';
import { JobStorageRates } from './job-storage-rates';

export class Job {
    public allocation: 'IN_HOUSE' | 'CARRIER'; 
    public id: string;
    public board_job: BoardJob;
    public booked: boolean;
    public box_delivery_day: JobAppointmentView;
    public charges: JobCharges;
    public company_id: string;
    public cubic_feets: number;
    public delivery_day: JobAppointmentView;
    public delivery_fee: JobDeliveryFee;
    public document: Document;
    public duration: number;
    public files: JobFile;
    public internal_remarks: string;//internal comme
    public is_driver_invoice: boolean;
    public job_outsourcing: JobOutsourcing;
    public job_storage: JobStorage;
    public overload_cf: number;
    public pack_day: JobAppointmentView;
    public pickup_day: JobAppointmentView;
    public salesman_id: string;
    public slip_remarks: string;//special con
    public statuses: JobStatus;
    public insurance: JobInsurance;
    public storage_rate: JobStorageRates;
    public tips: number;
    public total: number;
    public pre_bol_total: number;
    public created: number;
    public updated: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.allocation = null;
        this.board_job = null;
        this.booked = false;
        this.box_delivery_day = new JobAppointmentView();
        this.charges = new JobCharges();
        this.company_id = null;
        this.cubic_feets = 0;
        this.delivery_day = new JobAppointmentView();
        this.delivery_fee = new JobDeliveryFee();
        this.document = new Document();
        this.duration = 0;
        this.files = new JobFile();
        this.internal_remarks = null;
        this.is_driver_invoice = false;
        this.job_storage = new JobStorage();
        this.overload_cf = 0;
        this.pack_day = new JobAppointmentView();
        this.pickup_day = new JobAppointmentView();
        this.salesman_id = null;
        this.slip_remarks = null;
        this.statuses = new JobStatus();
        this.insurance = new JobInsurance();
        this.storage_rate = null;
        this.tips = 0;
        this.pre_bol_total = 0;
        this.created = null;
        this.updated = null;
    }
}
