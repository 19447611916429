
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Zone } from 'src/app/entities/workspace/zone';
import { ZonesService } from 'src/app/services/workspaces/zones.service';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare const jQuery;

@Component({
    selector: 'app-zones-form',
    templateUrl: './zones-form.component.html',
    styleUrls: ['./zones-form.component.scss']
})
export class ZonesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: Zone;
    tariffVersionId: string;

    constructor(
        private zonesService: ZonesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.dataForm = new Zone();
        this.tariffVersionId = '';
    }

    ngOnInit(): void {

    }


    ngAfterViewInit() {
        this.tariffVersionId = this.tariffVersionFactory.getTariffVersionId();
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }


    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.zonesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.dataForm.tariff_version_id = this.tariffVersionId;
        this.helperService.showLoadingMxpro360();
        this.zonesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/settings/zones');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


}
