import { Customer } from 'src/app/entities/workspace/customer';
import { EmailPerson } from 'src/app/entities/workspace/email-person';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { Employee } from 'src/app/entities/workspace/employee';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { EstimateEmail } from 'src/app/entities/workspace/estimate-email';
import { EstimateTransportVehicles } from 'src/app/entities/workspace/estimate-transport-vehicles';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { AuthService } from 'src/app/services/auth.service';
import {
    EstimatesEmailTemplatesService
} from 'src/app/services/companies/estimates-email-templates.service';
import { EmailsService } from 'src/app/services/companies/estimates-emails.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BalancesService } from 'src/app/services/companies/balances.service';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { Job } from 'src/app/entities/workspace/job';
import { JobsService } from 'src/app/services/workspaces/jobs.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { DocumentsTypesService } from 'src/app/services/companies/documents-types.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { JobStorage } from 'src/app/entities/workspace/job-storage';
import { JobStorageService } from 'src/app/services/companies/job-storage.service';

declare const moment;
declare const jQuery;
declare const swal;

// variable global para usar las funciones de auth de google
declare const gapi;

@Component({
    selector: 'app-estimates-email',
    templateUrl: './estimates-email.component.html',
    styleUrls: ['./estimates-email.component.scss']
})
export class EstimatesEmailComponent implements OnInit, AfterViewInit {

    private salesMan: any;
    private vehicle: string;
    private vehicles: Array<EstimateTransportVehicles>;
    private jobStorage: JobStorage;
    public balanceAmount: number;
    public balances: Array<BalanceView>;
    public carrier: Carrier;
    public customer: Customer;
    public dataForm: EstimateEmail;
    public documentsPPW: Array<any>;
    public emailPlatform: 'google' | 'company';
    public emails: Array<EstimateEmail>;
    public emailTemplates: Array<EmailTemplate>;
    public estimate: Estimate;
    public filterSection: 'ALL' | 'IN' | 'OUT' | 'SEARCHING';
    public generalSetting: GeneralSettings;
    public infomessagege;
    public isEmailValid: boolean;
    public job: Job;
    public search: string;
    public storagBalance: number;
    public viewEmail: EstimateEmail;
    public viewSection: 'LIST' | 'VIEW';

    /*
    Variable usada para informar que no se puede enviar el correo hasta qye se completen todos los campos
    */
    public disableButton: boolean;

    @ViewChild('composeMailModal')
    composerModal: ElementRef;

    constructor(
        public authService: AuthService,
        private customersService: CustomersService,
        private estimatesService: EstimatesService,
        private jobsService: JobsService,
        private carriersService: CarriersService,
        private emailsService: EmailsService,
        private estimatesEmailTemplatesService: EstimatesEmailTemplatesService,
        private generalSettingsService: GeneralSettingsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private employeesService: EmployeesService,
        private balancesService: BalancesService,
        private documentsTypes: DocumentsTypesService,
        private sanitizer: DomSanitizer,
        private jobStorageService: JobStorageService
    ) {
        this.balanceAmount = 0;
        this.carrier = new Carrier();
        this.dataForm = new EstimateEmail();
        this.disableButton = false;
        this.documentsPPW = [];
        this.emailPlatform = 'google';
        this.emails = [];
        this.emailTemplates = [];
        this.estimate = new Estimate();
        this.filterSection = 'ALL';
        this.generalSetting = new GeneralSettings();
        this.infomessagege = null;
        this.isEmailValid = false;
        this.job = new Job();
        this.jobStorage = null;
        this.salesMan = {};
        this.search = null;
        this.storagBalance = 0;
        this.vehicle = '';
        this.vehicles = [];
        this.viewEmail = null;
        this.viewSection = 'LIST';
    }

    ngOnInit(): void {
       this.loadSettings();
    }

    ngAfterViewInit() {
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.dataForm.estimate_id = params.id;
                this.load(params.id);
                this.loadEstimate(params.id);
                this.loadBalances(params.id);
                this.loadDocuments();

               // this.loadSetting();
                // this.loadConfigInfoMessage();
            }
        });
        this.helperService.ckeditor = 'estimatesEmail';
        this.loadEmailTemplates();
        const that = this;

        jQuery('.file-type').css('width', '460px');
        const select = jQuery('.file-type');
        let idTimeOut = null;

        select.select2({
            tags: true
        })
        .on('select2:select', (e) => {
            that.setFileType(e.params.data, 'select');
        })
        .on('select2:unselect', (e) => {
            that.setFileType(e.params.data, 'unselect');
        });

        // si hay cambios en el dom refrescamos el plugin
        select.bind('DOMSubtreeModified', () => {

            if (idTimeOut !== null) {
                clearTimeout(idTimeOut);
            }

            idTimeOut = setTimeout(() => {
                select.trigger('change');
            }, 1000);
        });
    }

    setFileType(position, type: string) {
        const fileType = position.id.split(':')[1].trim().replace(/'/g, '');
        if (this.dataForm.attach_file_types.length > 0) {
            const indexJobPosition = this.dataForm.attach_file_types.indexOf(fileType);
            if (type === 'select') {
                if (indexJobPosition === -1) {
                    this.dataForm.attach_file_types.push(fileType);
                }
            }

            if (type === 'unselect') {
                if (indexJobPosition >= 0) {
                    this.dataForm.attach_file_types.splice(fileType, 1);
                }
            }
        } else {
            if (type === 'select') {
                this.dataForm.attach_file_types.push(fileType);
            }
        }
    }


    private loadSettings() {
        this.generalSettingsService
            .get()
            .then((responseSettings) => {
                this.generalSetting = responseSettings;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
            });
    }

    load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.emailsService
            .getByEstimateId(id)
            .then((response) => {
                this.emails = response;
            })
            .catch((error) => {
                console.error('error', error);

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadEmailTemplates() {
        const data = {
            service: this.estimate.service
        };

        this.estimatesEmailTemplatesService
            .getAll(data)
            .then((response) => {
                this.emailTemplates = response;
            });
    }

    private loadVehicle() {
        this.vehicle = '';
        return new Promise((resolve) => {
            this.estimatesService
                .getAutoTransport(this.estimate.id)
                .then((response) => {
                    this.vehicles = response;

                    if (this.vehicles.length > 1) {
                        for (const iterator of this.vehicles) {
                            this.vehicle += iterator.year + ' ' + iterator.brand + ' ' + iterator.model + ', ';
                        }
                        this.vehicle = this.vehicle.substring(0, this.vehicle.length - 2);
                    } else if (this.vehicles.length === 1) {
                        this.vehicle += this.vehicles[0].year + ' ' + this.vehicles[0].brand + ' ' + this.vehicles[0].model;
                    }
                    resolve(true);
                })
                .catch((error) => {
                    const person = new EmailPerson();
                    this.dataForm.to.push(person);
                });
        });
    }

    loadEstimate(id: string) {
        this.estimatesService
            .getById(id)
            .then((response) => {
                this.estimate = response;
                this.loadEmployees();
                this.loadCustomer(this.estimate.customer_id);
                if(this.estimate.booked){
                    this.loadJob(this.estimate.id);
                }
            });
    }

    loadJob(id: string) {
        this.jobsService
        .getById(id)
        .then((response) => {
            this.job = response;
            if(this.job.job_outsourcing.carrier_id){
                this.loadCarrier(this.job.job_outsourcing.carrier_id);
            }
        });
        this.jobStorageService
        .getDateOut(id)
        .then((jobStorage) => {
            this.jobStorage = jobStorage;
        });
    }

    loadCarrier(id: string) {
        this.carriersService
            .getById(id)
            .then((response) => {
                this.carrier = response;
            });
    }

    loadCustomer(id: string) {
        this.customersService
            .getById(id)
            .then((response) => {
                this.customer = response;
                const person = new EmailPerson();
                this.resetFormComposer();
            })
            .catch((error) => {
                const person = new EmailPerson();
                this.dataForm.to.push(person);
            });
    }

    private loadBalances(id) {
        this.balanceAmount = 0;
        this.helperService.showLoadingMxpro360();
        this.balancesService
          .getById(id, {})
          .then((response) => {
              this.balances = response;
              for (const balance of this.balances) {
                  if (balance.status === 'PAID') {
                      this.balanceAmount += balance.amount;
                  }

                  if (balance.target == 'STORAGE') {
                      this.storagBalance += balance.amount;
                  }
              }    
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    private loadDocuments() {
        this.helperService.showLoadingMxpro360();
        this.documentsTypes
          .getAll()
          .then((response) => {
            this.documentsPPW = response;
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
    }
    

    /**
     * permite limpiar html de codigo malicioso.. esperemos que si funcione
     * @param html 
     * @returns 
     */
    sanitizeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }


    /**
     * Permite cambiar variables por sus respectivas values
     */
    private replaceKeyWords() {

        const date = new Date(this.estimate.pickup.range_start);
        const dateEnd = new Date(this.estimate.pickup.range_end);
        const FADD = new Date(this.estimate.delivery.range_start);
        const DOdate = new Date(this.jobStorage?.date_out);

        const day = (date.getDate()).toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = (date.getFullYear()).toString().padStart(2, '0');

        const dayEnd = (dateEnd.getDate()).toString().padStart(2, '0');
        const monthEnd = (dateEnd.getMonth() + 1).toString().padStart(2, '0');
        const yearEnd = (dateEnd.getFullYear()).toString().padStart(2, '0');

        const FADDday = (FADD.getDate()).toString().padStart(2, '0');
        const FADDmonth = (FADD.getMonth() + 1).toString().padStart(2, '0');
        const FADDyear = (FADD.getFullYear()).toString().padStart(2, '0');

        const DOday = (DOdate.getDate()).toString().padStart(2, '0');
        const DOmonth = (DOdate.getMonth() + 1).toString().padStart(2, '0');
        const DOyear = (DOdate.getFullYear()).toString().padStart(2, '0');
        
        let time_frame_start = '';
        let time_frame_end = '';

        if(this.estimate.pickup.use_time_frame) {

            time_frame_start = this.estimate.pickup.time_frame_start == null ? ' - ' : moment(this.estimate.pickup.time_frame_start, 'HH:mm').format('hh:mm A') + ' - ';
            time_frame_end = this.estimate.pickup.time_frame_end == null ? '' : moment(this.estimate.pickup.time_frame_end, 'HH:mm').format('hh:mm A');
        } 


        const items = {

            '@COMPANY_NAME': this.authService.workspaceSession.company.name,
            '@COMPANY_PHONE': this.authService.workspaceSession.company.phone,
            '@COMPANY_EMAIL': this.authService.workspaceSession.company.email,
            '@COMPANY_WEB': this.authService.workspaceSession.company.website,
            '@COMPANY_DOT': this.authService.workspaceSession.company.dot,
            '@COMPANY_LICENSE': this.authService.workspaceSession.company.license,

            '@CUSTOMER_FULL_NAME': this.customer.name+ ' '+ this.customer.last_name,
            '@CUSTOMER_NAME': this.customer.name,
            '@CUSTOMER_PHONE': this.customer.phone,
            '@CUSTOMER_EMAIL': this.customer.email,
            '@REPRESENTATIVE_NAME': this.salesMan.employee.name,

            '@REPRESENTATIVE_PHONE': this.authService.workspaceSession.employee_company_permissions.phone_number,
            '@REPRESENTATIVE_EMAIL': this.authService.workspaceSession.employee_company_permissions.email.address,

            '@JOB_NUMBER': this.estimate.document.code,
            '@VEHICLE': this.vehicle == null ? '' : this.vehicle,
            '@MOVE_TYPE': this.estimate.move_type,
            '@MOVE_DATE': this.estimate.pickup.range_start == null? '': month + '-' + day + '-' + year,
            '@MOVE_SIZE': this.estimate.volume.move_size == null? '': this.estimate.volume.move_size,
            '@TOTAL_JOB': this.estimate.total.toFixed(2) == null ? '' : '$' + this.estimate.total.toFixed(2),
            '@TOTAL_CF': this.job.id ? this.job.cubic_feets : this.estimate.volume.cubic_feets_selled,
            '@TOTAL_LBS': this.estimate.volume.libs == null ? '' : this.estimate.volume.libs,
            '@TOTAL_MILES': this.estimate.volume.miles.toFixed(2) == null ? '' : this.estimate.volume.miles.toFixed(2),
            '@JOB_PICKUP_START': this.estimate.pickup.range_start == null ? '' : month + '-' + day + '-' + year + ' ',

            '@FROM_CITY': this.estimate.pickup.address.city == null ? '' : this.estimate.pickup.address.city,
            '@FROM_STATE': this.estimate.pickup.address.state,
            '@FROM_COUNTRY': this.estimate.pickup.address.country,
            '@TO_CITY': this.estimate.delivery.address.city == null ? '' : this.estimate.delivery.address.city,
            '@TO_STATE': this.estimate.delivery.address.state,
            '@TO_COUNTRY': this.estimate.delivery.address.country,
            '@MOVING_ESTIMATE_SIGNATURE_TEXT': this.generalSetting.settings.policy_setting.enable_move_siganture_text == null ? '' : this.generalSetting.settings.policy_setting.enable_move_siganture_text,
            '@SIGNATURE_IP': this.estimate.esignature ? '<p>Customer Email: ' + this.customer.email + '<br> Ip: ' + this.estimate.esignature.ip_address + ' <br> Browser: ' + this.estimate.esignature.internet_browser + ' </p>' : '' ,
            '@CUSTOMER_SIGNATURE': this.estimate.esignature ? "<img  src='" + environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/e-signatures/' + this.estimate.esignature.esignature_file.display_name + "'   alt='signature'  height='15px'  class='img-fluid' />" : '' ,
            '@CUSTOMER_DATE_SIGNATURE': this.estimate.esignature ? moment(this.estimate.esignature.created).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) : '' ,

            '@PU_TIME': time_frame_start + time_frame_end,
            '@CUSTOMER_DEPOSIT': '$' + this.balanceAmount,

            '@CARRIER_DOT': this.carrier.id ? this.carrier.dot : '',
            '@CARRIER': this.carrier.id ? this.carrier.name : '',
            
            '@JOB_RATE': this.job.id ? this.job.charges.rate_cf : this.estimate.estimate_route_tariff.cf_cost,
            '@DEPOSIT': '$' + this.balanceAmount,
            '@INITIAL_DEPOSIT' : '$' + (this.estimate.total * this.generalSetting.settings.price_setting.initial_payment_percentage) / 100,
            '@JOB_PICKUP_END': this.estimate.pickup.range_end == null ? '': monthEnd + '-' + dayEnd + '-' + yearEnd,
            '@JOB_FADD': this.estimate.delivery.range_start == null ? '': FADDmonth + '-' + FADDday + '-' + FADDyear,
            '@DATE_OUT': this.jobStorage == null ? '': DOmonth + '-' + DOday + '-' + DOyear,
            '@CUSTOMER_BALANCE': this.job.id ? this.job.total + this.storagBalance - this.balanceAmount: this.estimate.total + this.storagBalance - this.balanceAmount
        };

        for (let entry in items) {
            this.dataForm.content = this.dataForm.content.split(entry).join(items[entry]);
        }

    }

    /**
     * Permite establecer una plantilla al correo
     */
    async setTemplate(event) {

        const select = event.target;
        const index = select.options[select.selectedIndex].value;

        this.dataForm.subject = '';
        this.dataForm.content = '';

        if (index !== '-1') {

            const emailTemplate = this.emailTemplates[index];
            this.dataForm.subject = emailTemplate.subject;

            this.dataForm.content = emailTemplate.body;
            this.dataForm.attach_inventory = emailTemplate.attach_inventory;
            this.dataForm.request_esignature = emailTemplate.request_esignature;
            this.dataForm.request_poa = emailTemplate.request_poa;
            this.dataForm.request_inventory_update = emailTemplate.request_inventory_update;
            this.dataForm.request_bol = emailTemplate.request_bol;
            this.dataForm.bol_signature_requested = emailTemplate.bol_signature_requested;
            this.dataForm.request_token = emailTemplate.request_token;
            this.dataForm.attach_files = emailTemplate.attach_files;
            this.dataForm.attach_file_types = emailTemplate.attach_file_types;
        }
        await this.loadVehicle();
        this.replaceKeyWords();
        setTimeout(() => {
            const select = jQuery('.file-type');
            select.trigger('change');
        }, 2000);
    }

    /**
     * Permite resetear el formulario de envio de email
     */
    private resetFormComposer() {
        const person = new EmailPerson();
        person.name = this.customer.name;
        person.email = this.customer.email;

        this.dataForm = new EstimateEmail();
        this.dataForm.estimate_id = this.estimate.id;
        this.dataForm.to.push(person);


        // asignamos la informacion del usuario que esta enviando el correo
        // this.dataForm.from = new EmailPerson();
        // if (this.emailPlatform === 'google') {
        //     this.dataForm.from.name = this.googleAuthService.session.name;
        //     this.dataForm.from.email = this.googleAuthService.session.email;
        // }
    }

    /**
     * Muestra la vista de detalle de un correo y a la ves recibe el correo que se desea ver el detalle
     * @param email
     */
    openViewEmail(email: EstimateEmail) {
        this.viewEmail = email;
        this.viewSection = 'VIEW';
    }

    /**
     * Permite cerrar la vista que muestra el detalle del email
     */
    closeViewEmail() {
        this.viewSection = 'LIST';
        this.viewEmail = null;
    }

    /**
     * permite abrir el formulario de componer correos para poder responder a un correo
     */
    toComposeReply() {

        // reseteamos el correo
        this.resetFormComposer();

        // asignamos el mismo  destinatario
        this.dataForm.to = [this.viewEmail.from];

        // asignamos el identificador del hilo que estamos conversando
        this.dataForm.thread_id = this.viewEmail.thread_id;

        // asignamos el mismo asunto
        this.dataForm.subject = this.viewEmail.subject;

        // abrimos la modal
        jQuery(this.composerModal.nativeElement).modal('show');
    }

    /**
     * Permite abrir la modal para elaborar en ella el correo a enviar
     * @param platform: 'google', 'company'
     */
    toCompose(emailPlatform: 'google' | 'company') {
        this.emailPlatform = emailPlatform;
        // this.resetFormComposer();
        jQuery(this.composerModal.nativeElement).modal('show');
    }

    /**
     * Permite cerrar la modal del fomrulario de correo
     */
    toCancelCompose() {
        jQuery(this.composerModal.nativeElement).modal('hide');
    }

    verifyCompanyEmail() {
        let hasEmail = false
        const currentCompanyId = this.authService.workspaceSession.company.id;
        const companyRoles = this.authService.workspaceSession.employee_companies_permissions;
        // verifica si existe email para el empleado en esta compania
        for (const company of companyRoles) {
            if (company.company_id === currentCompanyId) {
                if (company.email.address) {
                    hasEmail = true;
                }
            }
        }
        return hasEmail;
    }

    /**
     * Permite enviar el email elaborado
     */
    sendEmail() {
        this.helperService.showLoadingMxpro360();
        if (!this.dataForm.request_bol) {
            this.dataForm.bol_signature_requested = null;
        }
        // obtenemos variables para minimizar código
        const hasEmail = this.verifyCompanyEmail();       

        if (this.emailPlatform === 'google') {
            if (!hasEmail) {
                this.askSendInfoEmail();
            } else {
                this.sendByEmployee();
            }
        }
        if (this.emailPlatform === 'company') {
            this.sendBySettings();
        }   
    }

    /**
     * pregunta si desea enviar la email por info email
     */
    askSendInfoEmail() {
        this.helperService.hideLoadingMxpro360();
        swal({
            title: 'You Haven\'t Set Up an Email',
            text: 'Do you want to send this email from COMPANY INFO EMAIL?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Send It!'
        }).then((result) => {
            if (result.value) {
                this.helperService.showLoadingMxpro360();
                this.sendBySettings();
            }
        });
    }

    sendBySettings() {
        this.emailsService
            .sendInfoMessage(this.dataForm)
            .then((response) => {
                // notificamos que se envió
                swal(
                    'Done!',
                    'The Email has been sent successfully',
                    'success'
                );

                // cerramos
                jQuery(this.composerModal.nativeElement).modal('hide');
                this.resetFormComposer()
                if (this.emailTemplates.length > 0) {
                    this.dataForm.content = this.emailTemplates[0].body;
                    this.dataForm.subject = this.emailTemplates[0].subject;
                }
                this.loadEmailTemplates();
                // volvemos a cargar los emails, junto con el que se acabo de enviar
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error email', error);
                swal(
                    'ups!',
                    'An error has occurred',
                    'error'
                );
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    sendByEmployee() {
        // this.googleAuthService.refresAuthSessions();
        this.emailsService
            .send(this.dataForm)
            .then((response) => {
                // notificamos que se envió
                swal(
                    'Done!',
                    'The Email has been sent successfully',
                    'success'
                );

                // cerramos
                jQuery(this.composerModal.nativeElement).modal('hide');
                this.resetFormComposer()
                // if (this.emailTemplates.length > 0) {
                //     this.dataForm.content = this.emailTemplates[0].body;
                //     this.dataForm.subject = this.emailTemplates[0].subject;
                // }
                this.loadEmailTemplates();
                // volvemos a cargar los emails, junto con el que se acabo de enviar
                this.load(this.estimate.id);
            })
            .catch((error) => {
                if(error.status == 422){
                    swal(
                        'ups!',
                        error.error.message,
                        'error'
                    );
                } else {
                    console.error('error email', error);
                    swal(
                        'ups!',
                        'An error has occurred',
                        'error'
                    );
                }
               
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite cambiar el tipo de filtro
     * @param typeFilter
     */
    toFilterMails(typeFilter: 'ALL' | 'IN' | 'OUT' | 'SEARCHING') {
        this.viewSection = 'LIST';
        this.viewEmail = null;
        this.filterSection = typeFilter;
    }

    /**
     * Permite organizar la vista para saber si se esta buscando o no
     */
    toSearch() {
        if (this.search === '' || this.search == null) {
            this.toFilterMails('ALL');
        } else {
            this.toFilterMails('SEARCHING');
        }
    }


    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getById(this.estimate.operator.salesman_id)
            .then((response) => {
                this.salesMan = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite agregar mas destinatarios
     */
    addRecipient() {
        const person = new EmailPerson();
        this.dataForm.to.push(person);
    }

    /**
     * Permite eliminar destinatarios
     * @param index
     */
    deleteRecipient(index) {
        this.dataForm.to.splice(index, 1);
    }

    /**
     * Metodo que valida si se puede enviar un email
     */
    public validateSendEmail(): void {
        // Se inicializa la variable
        let enableButton = false;

        // Se recorren los campos creados para los correos destinatarios
        for (const iterator of this.dataForm.to) {
            // Valida si los campos de nombre y email se encuentan con datos
            if ((iterator.name === null || iterator.name === '') &&
                (iterator.email === null || iterator.email === '')) {

                enableButton = true;
                break;
            } else {
                enableButton = false;
            }
        }

        if (this.dataForm.subject === '') {
            enableButton = true;
        }

        this.dataForm.content = this.dataForm.content ? this.dataForm.content : '';

        if (enableButton) {
            this.disableButton = enableButton;
            swal(
                'Please',
                'Complete the information!',
                'warning'
            );
        } else {
            this.sendEmail();
        }

    }
}
