    <div class="row">

        <!-- Storage -->
        <div class="col-12 mb-4">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between widget-header m-0">
                    <h3>Storage</h3>
                    <button class="btn btn-new add-storage-job" (click)="addStorage()">
                        Add New Storage
                    </button>
                </div>
            </div>
        </div>

        <!-- Job Storage -->
        <div class="col-12 storage-job mb-4" *ngFor="let row of jobs; let j = index">
            <div class="widget-content widget-content-area">


                <!-- Section - Job Storage -->
                <div class="section-job-storage" [ngClass]="{'js-internal': row.type == 'INTERNAL', 'js-external': row.type == 'EXTERNAL'}">

                    <div class="d-flex justify-content-between">
                        <div class="d-flex widget-header">
                            <div class="section-title-icon">
                                <i class="fa-solid fa-warehouse"></i>
                            </div>
                            <h4 *ngIf="row.type == 'INTERNAL'">
                                <strong>Warehouse</strong>
                                <ng-container *ngFor="let rowWarehous of rowWarehouse">
                                    <ng-container *ngIf="rowWarehous.id == row.warehouse_id">
                                        {{rowWarehous.name}}
                                    </ng-container>
                                </ng-container>
                            </h4>
                            <h4 *ngIf="row.type == 'EXTERNAL'">
                                <strong>Storage Company</strong>
                                <ng-container  *ngFor="let storageCompany of rowStorageCompanies">
                                    <ng-container *ngIf="storageCompany.id == row.storage_company_id">
                                        {{storageCompany.name}}
                                    </ng-container>
                                </ng-container>
                            </h4>

                            <h4 *ngIf="row.type == 'EXTERNAL'">
                                  <ng-container *ngIf="row.storage_company.length > 0">
                                    &nbsp; {{ row.storage_company[0].address.full_address }} &nbsp; {{ row.storage_company[0].phone }}
                                  </ng-container>
                          </h4>
                        </div>

                        <div class="btn-actions">
                            <a class="btn btn-success" (click)="editStorage(row)">
                                <i class="fas fa-edit"></i>
                            </a>
                            <button class="btn btn-danger" (click)="remove(row.id)">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                        </div>
                    </div>

                    <!-- Job Storage - Table -->
                    <table class="table table-bordered table-hover storage-job-table mb-4">
                        <thead>
                            <tr class="text-center">
                                <th>Date In</th>
                                <th>Date Out</th>
                                <th>Moving Pads</th>
                                <th>Next Bill Date</th>
                                <th>Oversize Items</th>
                                <th>Storage Bill Month</th>
                                <th>Total Cf</th>
                                <th>Type</th>
                                <th>Passed Due</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center">
                                <td>{{row.date_in | date}}</td>
                                <td>{{row.date_out | date}}</td>
                                <td>{{row.moving_pads}}</td>
                                <td>{{row.next_bill_date | date}}</td>
                                <td>{{row.oversize_items}}</td>
                                <td>{{row.storage_bill_month}}</td>
                                <td>{{row.total_cf}}</td>
                                <td>
                                    <span *ngIf="row.type == 'INTERNAL'" class="badge badge-success">INTERNAL</span>
                                    <span *ngIf="row.type == 'EXTERNAL'"class="badge badge-info">EXTERNAL</span>
                                </td>
                                <td class="passed-due">
                                    <span *ngIf="row.passed_due" class="icon-check">
                                        <i class="fa-solid fa-square-check"></i>
                                    </span>
                                    <span *ngIf="!row.passed_due" class="icon-cancel">
                                        <i class="fa-solid fa-rectangle-xmark"></i>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>



                <!-- Job Storage Internals -->
                <div class="job-storage-internals" *ngIf="row.type == 'INTERNAL'">
                    <div class="widget-header d-flex">
                        <div class="section-title-icon">
                            <!-- <i class="fa-solid fa-warehouse"></i> -->
                            <i class="fa-solid fa-boxes-stacked"></i>
                        </div>
                        <h4>Job Storage Internals</h4>
                    </div>
                    <!-- Job Storage Internals - Table -->
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr class="text-center">
                                <th class="table-no">No.</th>
                                <th>Vaults</th>
                                <th>Lot</th>
                                <th>Color</th>
                                <th>Sticker</th>
                                <th>Location In Storage</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center" *ngFor="let jobStorageInterna of row.job_storage_internal; let i = index">
                                <td class="text-left">{{i + 1}}</td>
                                <td>{{jobStorageInterna.vaults}}</td>
                                <td>{{jobStorageInterna.lot}}</td>
                                <td>{{jobStorageInterna.color}}</td>
                                <td>{{jobStorageInterna.sticker}}</td>
                                <td>{{jobStorageInterna.location_in_storage}}</td>
                                <td class="text-right">
                                    <!-- <a (click)="editStorage(row)" class="btn btn-success">
                                        <i class="fas fa-edit"></i>
                                    </a>   -->
                                    <button class="btn btn-secondary" (click)="printVoultSticker(row.id, jobStorageInterna.id)">
                                        <i class="fa-solid fa-print"></i>
                                    </button>
                                    <button class="btn btn-danger" (click)="removeInternalVoultSticker(j, i)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Job Storage External -->
                <div class="job-storage-external" *ngIf="row.type == 'EXTERNAL'">
                    <div class="widget-header d-flex">
                        <span class="section-title-icon">
                            <!-- <i class="fa-solid fa-warehouse"></i> -->
                            <i class="fa-solid fa-boxes-stacked"></i>
                        </span>
                        <h4>Job Storage External</h4>
                    </div>
                    <!-- Job Storage External - Table -->
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr class="text-center">
                                <th class="table-no">No.</th>
                                <th>Key Location</th>
                                <th>Crates</th>
                                <th>Cost To Company</th>
                                <th>Entrance Code</th>
                                <th>First Payment Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center" *ngFor="let jobStorageExterna of row.job_storage_external; let i = index">
                                <td class="text-left">{{i + 1}}</td>
                                <td>{{jobStorageExterna.key_location}}</td>
                                <td>{{jobStorageExterna.crates}}</td>
                                <td>{{jobStorageExterna.cost_to_company}}</td>
                                <td>{{jobStorageExterna.entrance_code}}</td>
                                <td>{{jobStorageExterna.first_payment_date | dateTime}}</td>
                                <td class="text-right">
                                    <button class="btn btn-danger" (click)="removeExternalVoultSticker(j, i)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <!-- Job Storage Notes-->
        <div class="col-12 storage-job work-experience mb-4">
            <div class="widget-content widget-content-area">
                <div class=" widget-heading">
                    <h3 class="title-content-area">
                        Storage Notes
                    </h3>
                    <a title="Add Estimate Notes" class="tooltipmxp btn-call-action btn-g-action" data-toggle="modal"
                        data-target="#storageNotes" appCanUserCompany="ESTIMATE_SET_NOTES">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-plus-circle">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="16"></line>
                            <line x1="8" y1="12" x2="16" y2="12"></line>
                        </svg>
                    </a>
                </div>

                <br>

                <div class="timeline-alter" *ngIf="storageNotes.length > 0" [ngClass]="divCompleteClass">
                    <div *ngFor="let storageNote of storageNotes; index as i;" class="item-timeline">
                        <div class="t-meta-date">
                            <p *ngIf="storageNote.employee">
                                {{storageNote.created | dateTime}} - {{storageNote.employee.name}}
                            </p>
                            <p *ngIf="!storageNote.employee">
                                {{storageNote.created | dateTime}} - MoverXpro360
                            </p>
                        </div>
                        <div class="t-dot">
                        </div>
                        <div class="t-text">
                            <p [innerHTML]="storageNote.note"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </div>


    <!-- Modal follow -->
    <div class="modal fade new-storage-modal" #modalStorage tabindex="-1" role="dialog" aria-labelledby="companyTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl max-width-modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">

                <form class="form row" #estimateForm="ngForm" ngNativeValidate>

                    <div class="col-6">
                        <div class="modal-header">
                            <h5 class="modal-title" id="storageNotesTitle">
                                Job Storage
                            </h5>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="job_type" class="control-label">Storage Type</label>
                                    <select [(ngModel)]="job.type" (ngModelChange)="checkNewCompany()" name="storage_type" id="job_type" class="form-control" (click)="setJobStorage($event.target.value);">
                                        <option value="INTERNAL">Internal</option>
                                        <option value="EXTERNAL">External</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="" class="control-label">Date In</label>
                                    <app-date-picker [value]="job.date_in" [placeholder]="'Not Assigned'" (onChange)="changeDateIn($event)">
                                    </app-date-picker>
                                </div>

                                <div for="job_total_cf" class="form-group">
                                    <label *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'CUBIC_FEET'">Total CF</label>
                                    <label *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'POUNDS'">Total Lbs</label>
                                    <input type="number" class="form-control" name="job_total_cf" id="job_total_cf" [(ngModel)]="job.total_cf" />
                                </div>

                                <div class="form-group">
                                    <label form="job_storage_bill_month">Storage Bill Month</label>
                                    <input type="number" class="form-control" name="job_storage_bill_month" id="job_storage_bill_month" [(ngModel)]="job.storage_bill_month" />
                                </div>

                                <div class="form-group">
                                    <label for="" class="control-label">Next Bill Date</label>
                                    <app-date-picker [value]="job.next_bill_date" [placeholder]="'Not Assigned'" (onChange)="changeDateNextBill($event)">
                                    </app-date-picker>
                                </div>
                            </div>

                            <div class="col-6">

                                <div class="form-group" [hidden]="job.type == 'EXTERNAL'">
                                    <label for="" class="control-label">Warehouse</label>
                                    <select [(ngModel)]="job.warehouse_id" (ngModelChange)="checkNewCompany()" name="job_warehouse_id" id="payment_methods_enabled" class="form-control">
                                        <option value="NEW">
                                            New
                                        </option>
                                        <option *ngFor="let rowWarehous of rowWarehouse" value="{{rowWarehous.id}}">
                                            {{rowWarehous.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group" [hidden]="job.type == 'INTERNAL' || job.type == null">
                                    <label for="payment_methods_enabled" class="control-label">Storage Company</label>
                                    <select [(ngModel)]="job.storage_company_id" (ngModelChange)="checkNewCompany()" name="job_storage_company_id" id="payment_methods_enabled" class="form-control">
                                        <option value="CUSTOM">Custom</option>
                                        <option *ngFor="let storageCompany of rowStorageCompanies" value="{{storageCompany.id}}">
                                            {{storageCompany.name}}
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="date_out" class="control-label">Date Out</label>
                                    <div id="date_out" class="output-box">
                                        {{estimate.delivery.range_start | date}}
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="job_moving_pads" class="control-label">Moving Pads</label>
                                    <input type="number" class="form-control" name="job_moving_pads" id="job_moving_pads" [(ngModel)]="job.moving_pads" />
                                </div>

                                <div class="form-group">
                                    <label for="job_oversize_items" class="control-label">Oversize Items</label>
                                    <input type="number" name="job_oversize_items" class="form-control" id="job_oversize_items" [(ngModel)]="job.oversize_items" />
                                </div>

                                <div class="form-group toggle-switch-passed-due">
                                    <div class="toggle-switch">
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="job_passed_due" name="job_passed_due" [(ngModel)]="job.passed_due">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="job_passed_due" class="checkbox-label" [ngClass]="{'filter-disabled': !job.passed_due}">
                                            Passed Due
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12" [hidden]="!(job.type == 'EXTERNAL' && showCustom)">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="storageNotesTitle">
                                        Custom External Storage Company
                                    </h5>
                                </div>
                                <div class="form-group">
                                    <label for="" class="control-label">Storage Company Name</label>
                                    <input type="text" class="form-control" name="storageCompany.name" id="storageCompany.name" [(ngModel)]="storageCompany.name" />
                                </div>
                                <div class="form-group">
                                    <label for="" class="control-label">Storage Company Phone</label>
                                    <input type="number" class="form-control" name="storageCompany.phone" id="storageCompany.phone" [(ngModel)]="storageCompany.phone" />
                                </div>
                                <div class="form-group">
                                    <label>Storage Company Address</label>
                                    <app-search-address [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'"
                                        [addressFull]="false" (onChange)="setFrom($event)">
                                    </app-search-address>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-6">

                        <!-- Job Storage Internals -->
                        <div class="row" [hidden]="job.type == 'EXTERNAL' || job.type == null">
                            <div class="col-12">
                                <div class="modal-header d-flex justify-content-between">
                                    <h5 class="modal-title" id="storageNotesTitle">
                                        Job Storage Internals
                                    </h5>
                                    <button class="btn btn-new btn-clone" (click)="addNewInternal()">
                                        <i class="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                                <div class="scroll-storage-internal">
                                    <div class="row storage-internal"*ngFor="let jobStorageInternal of job.job_storage_internal; let i = index">

                                        <div class="col-6">

                                            <div class="form-group">
                                                <label for="" class="control-label">Vaults</label>
                                                <input type="number" name="job_storage_internal-vaults{{i}}" class="form-control" [(ngModel)]="jobStorageInternal.vaults" />
                                            </div>

                                            <div class="form-group">
                                                <label for="" class="control-label">Lot</label>
                                                <input type="text" name="job_storage_internal.lot{{i}}" class="form-control" [(ngModel)]="jobStorageInternal.lot" />
                                            </div>

                                            <div class="form-group">
                                                <label for="" class="control-label">Color</label>
                                                <input type="text" name="job_storage_internal-color{{i}}" class="form-control" [(ngModel)]="jobStorageInternal.color" />
                                            </div>

                                        </div>

                                        <div class="col-6">

                                            <div class="form-group">
                                                <label for="" class="control-label">Sticker</label>
                                                <input type="text" name="job_storage_internal-sticker{{i}}" class="form-control" [(ngModel)]="jobStorageInternal.sticker" />
                                            </div>

                                            <div class="form-group">
                                                <label for="" class="control-label">Location In Storage</label>
                                                <input type="text" name="job_storage_internal-locationinstorage{{i}}" class="form-control" [(ngModel)]="jobStorageInternal.location_in_storage" />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Job Storage External -->
                        <div class="row" [hidden]="job.type == 'INTERNAL' || job.type == null">
                            <div class="col-12">
                                <div class="modal-header d-flex justify-content-between">
                                    <h5 class="modal-title" id="storageNotesTitle">
                                        Job Storage External
                                    </h5>
                                    <button class="btn btn-new btn-clone" (click)="addNewExternal()">
                                        <i class="fa-solid fa-plus"></i>
                                    </button>
                                </div>
                                <div class="row storage-external" *ngFor="let jobStorageExternal of job.job_storage_external; let i = index">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-6">

                                                <div class="form-group">
                                                    <label for="job_storage_external_crates" class="control-label">Crates</label>
                                                    <input type="number" name="job_storage_external_crates{{i}}" class="form-control" id="job_storage_external_crates" [(ngModel)]="jobStorageExternal.crates" />
                                                </div>

                                                <div class="form-group">
                                                    <label for="job_storage_external_code" class="control-label">Entrance Code</label>
                                                    <input type="text" name="job_storage_external_code{{i}}" class="form-control" id="job_storage_external_code" [(ngModel)]="jobStorageExternal.entrance_code" />
                                                </div>

                                                <div class="form-group">
                                                    <label for="" class="control-label">Key Location</label>
                                                    <input type="text" name="job-job_storage_internal-location{{i}}" class="form-control" [(ngModel)]="jobStorageExternal.key_location" />
                                                </div>

                                            </div>
                                            <div class="col-12 col-xl-6 col-lg-6">

                                                <div class="form-group">
                                                    <label for="" class="control-label">Cost To Company</label>
                                                    <input type="number" name="job-job_storage_internal-company{{i}}" class="form-control" [(ngModel)]="jobStorageExternal.cost_to_company" />
                                                </div>

                                                <div class="form-group">
                                                    <label for="" class="control-label">First Payment Date</label>
                                                    <app-date-picker [value]="jobStorageExternal.first_payment_date"
                                                        [placeholder]="'Not Assigned'" (onChange)="changeDateFirstPaymentDate($event)">
                                                    </app-date-picker>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>

            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveInfoJobStorage()" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>


    </div>

    <!-- Modal Add New Storage Note -->
    <div class="modal fade" id="storageNotes" tabindex="-1" role="dialog" aria-labelledby="storageNotesTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="storageNotesTitle">
                    Add New Storage Note
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="inputState">
                        Note
                    </label>
                    <div class="list-title">
                        <textarea class="form-control textarea-force" name="storage_note"
                            id="storage_note" [(ngModel)]="storageNote.note"
                            placeholder="Write the note here" cols="30" rows="5">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addstorageNotes()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
    </div>
