import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { BalancesWorkspaceService } from './../../../services/workspaces/balances-workspace.service';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';
import { CarrierDocument } from 'src/app/entities/workspace/carrier-document';
import { CarrierDriver } from 'src/app/entities/workspace/carrier-driver';
import { CarrierFile } from 'src/app/entities/workspace/carrier-file';
import { Company } from 'src/app/entities/workspace/company';
import { ElectronicSignature } from 'src/app/entities/workspace/electronic-signature';
import { EstimateDocument } from 'src/app/entities/workspace/estimate-document';
import { JobOutsourcingView } from 'src/app/entities/workspace/job-outsourcing-view';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { CarriersContactsService } from 'src/app/services/workspaces/carriers-contacts.service';
import { CarriersDocumentsService } from 'src/app/services/workspaces/carriers-documents.service';
import { CarriersDriversService } from 'src/app/services/workspaces/carriers-drivers.service';
import { CarriersFilesService } from 'src/app/services/workspaces/carriers-files.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { environment } from 'src/environments/environment';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CompaniesService } from '../../../services/workspaces/companies.service';
import { CarrierCoverage } from 'src/app/entities/workspace/carrier-coverage';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { CarrierSetting } from 'src/app/entities/workspace/carrier-setting';
import { CarriersSettingsService } from 'src/app/services/workspaces/carriers-settings.service';
import { Expense } from 'src/app/entities/workspace/expense';
import { ExpenseService } from 'src/app/services/companies/expense.service';

declare const swal;
declare const jQuery;
declare const Snackbar;
@Component({
    selector: 'app-carriers-view',
    templateUrl: './carriers-view.component.html',
    styleUrls: ['./carriers-view.component.scss']
})
export class CarriersViewComponent implements OnInit {

    @ViewChild('addModal') addModal: ElementRef;
    @ViewChild('addModalContact') addModalContact: ElementRef;
    @ViewChild('addModalDocument') addModalDocument: ElementRef;
    @ViewChild('addModalDriver') addModalDriver: ElementRef;
    @ViewChild('addModalFile') addModalFile: ElementRef;
    @ViewChild('signatureModal') signatureModal: ElementRef;
    @ViewChild('modalCarrierCoverage') modalCarrierCoverage: ElementRef;
    @ViewChild('selectStates') selectStates: ElementRef;
    @ViewChild('modalDisableCarrier') modalDisableCarrier: ElementRef;

    totalPercentageBalance: number;
    carrierDocumentType: string;
    selectTap: string;
    readonly environment = environment;
    electronicSignature: ElectronicSignature;

    carrierId: string;
    carrier: Carrier;
    carrierModal: Carrier;
    carriersDrivers: Array<CarrierDriver>;
    carrierDriver: CarrierDriver;
    carriersDocuments: Array<CarrierDocument>;
    carrierDocument: CarrierDocument;
    carriersContacts: Array<CarrierContact>;
    carrierContact: CarrierContact;
    carriersFiles: Array<CarrierFile>;
    carrierFile: CarrierFile;
    carrierJobs: Array<JobOutsourcingView>;
    balances: Array<BalanceView>;
    actualPage = 1;
    company: string;
    carrierSettings: CarrierSetting;
    public expenses: Array<Expense>;

    public companies: Array<Company>;

    private urlLogo: string;
    public newCarrierCoverage: CarrierCoverage;
    public carrierCoverageList: Array<CarrierCoverage>;
    public useZipCode: boolean;
    public carrierAction: string;
    public actionCarrierCoverage: string;

    public readonlyCk: boolean;
    public accountEmail: string;


    constructor(
        private currentRoute: ActivatedRoute,
        private carriersFilesService: CarriersFilesService,
        private carriersDriversService: CarriersDriversService,
        private carriersDocumentsService: CarriersDocumentsService,
        private carriersContactsService: CarriersContactsService,
        private carriersService: CarriersService,
        private carriersSettingsService: CarriersSettingsService,
        public authService: AuthService,
        public helperService: HelperService,
        public companiesService: CompaniesService,
        private carrierService: CarriersService,
        private router: Router,
        private expenseService: ExpenseService,
        private balancesWorkspaceService: BalancesWorkspaceService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.carrierId = '';
        this.carrier = new Carrier();
        this.carrierModal = new Carrier();
        this.carriersDrivers = [];
        this.carrierDriver = new CarrierDriver();
        this.carriersDocuments = [];
        this.carrierDocument = new CarrierDocument();
        this.carriersContacts = [];
        this.carrierContact = new CarrierContact();
        this.carriersFiles = [];
        this.carrierFile = new CarrierFile();
        this.totalPercentageBalance = 0;
        this.carrierDocumentType = '';
        this.selectTap = 'contacts';
        this.carrierJobs = [];
        this.electronicSignature = new ElectronicSignature();
        this.companies = [];
        this.company = '';
        this.balances = [];
        this.newCarrierCoverage = new CarrierCoverage();
        this.carrierCoverageList = [];
        this.useZipCode = false;
        this.carrierAction = '';
        this.actionCarrierCoverage = '';
        this.expenses = [];

        this.readonlyCk = true;
        this.carrierSettings = new CarrierSetting();

        this.carrierSettings.bol_terms_metas = [
            {
                id: null,
                key: 'PAYMENT_TERMS',
                value: ''
            },
            {
                id: null,
                key: 'TOTAL_MINIMUM_CHARGES',
                value: ''
            },
            {
                id: null,
                key: 'DISCLOSURES_AGREED_TERMS_DELIVERY',
                value: ''
            },
            {
                id: null,
                key: 'AGREED_PICKUP_DELIVERY_PERIODS_NOTICE',
                value: ''
            },
            {
                id: null,
                key: '3_DAYS_BEFORE_PICKUP_SIGN',
                value: ''
            },
            {
                id: null,
                key: 'STORAGE_CHARGES',
                value: ''
            },
            {
                id: null,
                key: 'DELIVERY_ACKNOWLEDGEMENT',
                value: ''
            },
            {
                id: null,
                key: 'MAIN_TERMS',
                value: ''
            }
        ];
        this.accountEmail = '';

    }

    ngOnInit(): void {
     
    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.carrierId = params.id;
                this.loadCarrier(params.id);
                this.loadCarriersContacts(params.id);
                this.loadCarriersDocuments(params.id);
                this.loadCarriersDrivers(params.id);
                this.loadCarriersFiles(params.id);
                this.loadCarriersJobs();
                this.loadCompanies();
                this.loadBalances();
                this.loadCarrierSettings();
                this.loadExpenses();
            }
        });
        // Se realiza la modificacion de la barra de progreso de pagos
        (document.querySelector('.progress-bar') as HTMLElement);

        

        jQuery('#btn-nav-previous').click(function(){
            jQuery(".menu-top").animate({scrollLeft: "-=200px"});
        });

        
        jQuery('#btn-nav-next').click(function(){
            jQuery(".menu-top").animate({scrollLeft: "+=200px"});
        });


    }

    // obtiene el nombre del estado del text del select
    getDataTextFromState() {
        const select = this.selectStates.nativeElement;
        this.newCarrierCoverage.state = select.options[select.selectedIndex].innerText;
    }

    loadCarrier(id: string) {
        this.helperService.showLoadingMxpro360();
        this.carriersService
            .getById(id)
            .then((response) => {
                this.carrier = response;
                this.urlLogo = this.carrier.logo ? environment.az_api.cdn + '/' + this.authService.workspaceSession.workspace.id + '/media-files/' + this.carrier.logo : '/assets/img/200x200.jpg';
                jQuery('#input-file-max-fs').attr("data-default-file", this.urlLogo);

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadCarriersDrivers(id: string) {
        this.helperService.showLoadingMxpro360();
        this.carriersDriversService
            .getAll(id)
            .then((response) => {
                this.carriersDrivers = response;

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadCarriersDocuments(id: string) {
        this.helperService.showLoadingMxpro360();
        this.carriersDocumentsService
            .getAll(id)
            .then((response) => {
                this.carriersDocuments = response;

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
    loadCarriersContacts(id: string) {
        this.helperService.showLoadingMxpro360();
        this.carriersContactsService
            .getAll(id)
            .then((response) => {
                this.carriersContacts = response;

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
    loadCarriersFiles(id: string) {
        this.helperService.showLoadingMxpro360();
        this.carriersFilesService
            .getAll(id)
            .then((response) => {
                this.carriersFiles = response;

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadCarriersJobs() {

        this.helperService.showLoadingMxpro360();
        this.carriersService
            .getAllJob(this.carrierId)
            .then((response) => {
                this.carrierJobs = response;
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadBalances(): void {
        this.helperService.showLoadingMxpro360();
        this.balancesWorkspaceService
            .getBalancesByCarrier(this.carrierId)
            .then((response) => {
                this.balances = response;
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadExpenses() {
        this.helperService.showLoadingMxpro360();
        this.balancesWorkspaceService
            .getAllExpenses()
            .then((response) => {
                this.expenses = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    private loadCarrierSettings(): void {
        this.helperService.showLoadingMxpro360();
        this.carriersSettingsService
            .get(this.carrierId)
            .then((response) => {
                if (response){
                    this.carrierSettings = response;
                    console.log(this.carrierSettings);
                }
                
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadCarriersJobsByCompany(companyId: string) {

        this.helperService.showLoadingMxpro360();
        this.carriersService
            .getJobByCompany(this.carrierId, companyId)
            .then((response) => {
                this.carrierJobs = response;
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveCarrierContact() {
        this.carrierContact.carrier_id = this.carrierId;
        this.helperService.showLoadingMxpro360();
        this.carriersContactsService
            .save(this.carrierContact, this.carrierId, this.carrierContact.id)
            .then((response) => {
                this.loadCarriersContacts(this.carrierId);
                jQuery(this.addModalContact.nativeElement).modal('hide');

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    saveCarrierSettings(): void {
        this.helperService.showLoadingMxpro360();
        this.carriersSettingsService
            .save( this.carrierSettings, this.carrierId)
            .then((response) => {
              this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    searchAccout() {
        this.helperService.showLoadingMxpro360();
        this.carriersDriversService
            .searchAccouts(this.accountEmail)
            .then((response: any) => {
                console.log(response);
                this.helperService.hideLoadingMxpro360();
                swal({
                    title: 'Are you sure you want to associate this account?',
                    html: '<div> <table class="table"> <tr><th>Name</th><th>Email</th></tr>  <tr><td>' + response.name + '</td><td>' + response.email + '</td></tr> </table> </div>',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Assign'
                }).then((res) => {
                    if (res.value) {
                        this.carrierDriver.account_id = response.id;
                        this.carrierDriver.email = response.email;

                        // asignamos los datos de la cuenta
                        this.carrierDriver.name = this.capitalizeWords(response.name);
                    }
                })
            })
            .catch((error) => {
                swal({
                    title: 'Attention',
                    text: 'This is a new Account, Fill all the fields',
                    type: 'warning',
                });
                this.carrierDriver.email = this.accountEmail;
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    capitalizeWords(str: string) {
        let words = str.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(' ');
    }


    saveCarrierDriver() {
        this.carrierDriver.carrier_id = this.carrierId;
        if (this.carrierDriver.license.display_name == null) {
            swal(
                'Attention!!',
                'Upload License is Required',
                'warning'
            );
            return;
        }
        this.helperService.showLoadingMxpro360();
        this.carriersDriversService
            .save(this.carrierDriver)
            .then((response) => {
                this.loadCarriersDrivers(this.carrierId);
                jQuery(this.addModalDriver.nativeElement).modal('hide');

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveCarrierDocument() {
        this.carrierDocument.carrier_id = this.carrierId;
        this.carrierDocument.employee_id = this.authService.workspaceSession.employee.id;
        this.helperService.showLoadingMxpro360();
        this.carriersDocumentsService
            .save(this.carrierDocument, this.carrierId, null, this.carrierDocumentType)
            .then(() => {
                // muestra notificacion de exito
                let backgroundColor: string;
                Snackbar.show({
                    text: 'Document sent',
                    pos: 'top-center',
                    actionTextColor: '#fff',
                    backgroundColor: '#40CA4D',
                    duration: 2000,
                    showAction: false
                });

            })
            .catch((error) => {
                swal({
                    title: 'Attention!!',
                    text: error.error.message
                });
                console.error('Error:', error);
            })
            .finally(() => {
                this.loadCarriersDocuments(this.carrierId);
                // cierra modal
                jQuery(this.addModalDocument.nativeElement).modal('hide');
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveCarrierFile() {
        this.carrierFile.carrier_id = this.carrierId;
        this.helperService.showLoadingMxpro360();
        this.carriersFilesService
            .save(this.carrierFile, this.carrierId, null)
            .then((response) => {
                this.loadCarriersFiles(this.carrierId);
                jQuery(this.addModalFile.nativeElement).modal('hide');

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    deleteCarrierContact(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the Contac?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carriersContactsService
                        .remove(this.carrierId, id)
                        .then((response) => {
                            Snackbar.show({
                                text: 'Contact Deleted',
                                pos: 'top-center',
                                actionTextColor: '#fff',
                                backgroundColor: '#40CA4D',
                                duration: 2000,
                                showAction: false
                            });
                            this.loadCarriersContacts(this.carrierId);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    deleteCarrierDriver(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the Driver?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carriersDriversService
                        .remove(this.carrierId, id)
                        .then((response) => {
                            this.loadCarriersDrivers(this.carrierId);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    deleteCarrierDocument(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the Document?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carriersDocumentsService
                        .remove(this.carrierId, id)
                        .then((response) => {
                            Snackbar.show({
                                text: 'Document Deleted',
                                pos: 'top-center',
                                actionTextColor: '#fff',
                                backgroundColor: '#40CA4D',
                                duration: 2000,
                                showAction: false
                            });
                            this.loadCarriersDocuments(this.carrierId);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    deleteCarrierFile(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the File?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carriersFilesService
                        .remove(this.carrierId, id)
                        .then((response) => {
                            Snackbar.show({
                                text: 'File Deleted',
                                pos: 'top-center',
                                actionTextColor: '#fff',
                                backgroundColor: '#40CA4D',
                                duration: 2000,
                                showAction: false
                            });
                            this.loadCarriersFiles(this.carrierId);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    onChangeDriverLicense(img) {
        let imagen = img[0].split('.');
        this.carrierDriver.license.display_name = img[0];
        this.carrierDriver.license.name = img[0];
        this.carrierDriver.license.mime_type = 'image/' + imagen[1];
    }

    onChangeDriverFile(img) {
        let imagen = img[0].split('.');
        this.carrierFile.file.display_name = img[0];
    }

    openAddModal() {
        jQuery(this.addModal.nativeElement).modal('show');
    }

    openAddModalContact(contact) {
        this.carrierContact = contact === null ? new CarrierContact() : {...contact};
        jQuery(this.addModalContact.nativeElement).modal('show');
    }

    openAddModalDocument() {
        jQuery(this.addModalDocument.nativeElement).modal('show');
    }

    openAddModalDriver(carrierDriver: CarrierDriver | null) {
        if (carrierDriver === null) {
            this.carrierAction = 'Add';
            this.carrierDriver = new CarrierDriver();
            jQuery('.form-group').find('span.dropify-render>img').attr('src', 'assets/img/200x200.jpg');
        } else {
            this.carrierAction = 'Edit';
            this.carrierDriver = {...carrierDriver};
            jQuery('.form-group').find('span.dropify-render>img').attr('src', `${environment.az_api.cdn}/${this.authService.workspaceSession.id}/carrier-files/${carrierDriver.license.display_name}`);
        }
        jQuery(this.addModalDriver.nativeElement).modal('show');
    }
    openAddModalFile() {
        jQuery(this.addModalFile.nativeElement).modal('show');
    }

    // abre modal para agregar carrier coverage
    openModalCoverage(carrierCoverage: CarrierCoverage | null) {
        // inicializa el new coverage
        this.newCarrierCoverage = carrierCoverage == null ? new CarrierCoverage(): { ...carrierCoverage };
        this.actionCarrierCoverage = carrierCoverage == null ? 'New': 'Edit';
        jQuery(this.modalCarrierCoverage.nativeElement).modal('show');
    }

    // guarda nuevo carrier coverage
    saveCarrierCoverage() {
        // valida campos para la creacion del coverage
        let msg = '';
        if (!this.useZipCode) {
            this.newCarrierCoverage.zip_code = null;
            this.newCarrierCoverage.radius = null;
        } else {
            if (this.isEmpty(this.newCarrierCoverage.zip_code)) {
                msg += "**Zipcode Must Be Filled Out**<br>"
            }
            if (this.isEmpty(this.newCarrierCoverage.radius)) {
                msg += "**Radius Must Be Filled Out**<br>"
            }
        }

        if (msg !== '') {
            swal(
                'Attention!',
                msg,
                'warning'
            );
            return;
        }
        // envia datos de coverage a la base de datos
        this.carriersService.saveCarrierCoverage(this.carrierId, this.newCarrierCoverage)
            .then((carrierCoverages: CarrierCoverage[]) => {
                if (this.actionCarrierCoverage == 'New') {
                    this.carrierCoverageList = carrierCoverages;
                } else {
                    this.loadCarriersCoverage();
                }
                jQuery(this.modalCarrierCoverage.nativeElement).modal('hide');
            }).catch((error: any) => {
                swal(
                    'Attention!',
                    'Please verfy the data entered',
                    'warning'
                );
            });
    }

    isEmpty(variable: any): boolean {
        if (this.newCarrierCoverage.zip_code == null) {
            return true;
        } else if (this.newCarrierCoverage.zip_code.trim() == '') {
            return true;
        } else {
            return false;
        }
    }

    // trae los carrier coverages de base de datos
    loadCarriersCoverage() {
        this.helperService.showLoadingMxpro360();
        this.carriersService.getCarrierCoverages(this.carrierId)
            .then((carrierCoverages: CarrierCoverage[]) => {
                this.carrierCoverageList = carrierCoverages;
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Abre el modal del detalle de la firma
     *
     * @param signature Objeto que contiene el detalle de la firma
     */
    openSignatureModal(signature: EstimateDocument): void {
        this.electronicSignature = new ElectronicSignature();
        if (signature.document.esignatures.main) {
            this.electronicSignature = signature.document.esignatures.main;
        }
        jQuery(this.signatureModal.nativeElement).modal('show');
    }

    editContact(contact) {
        contact.main = true;
        this.helperService.showLoadingMxpro360();
        this.carriersContactsService
            .edit(contact, this.carrierId, contact.id)
            .then((response) => {
                this.loadCarriersContacts(this.carrierId);

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    public loadCompanies(): void {
        // Se realiza la consulta de las companies asociadas al empleado
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll()
            .then((response) => {
                this.companies = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    filterByCompany() {
        this.loadCarriersJobsByCompany(this.company);
    }

    copyText(text: string) {

        let url = environment.app.carriers + '/#/login?workspace=' + this.authService.workspaceSession.id + '&carrier_id=' + text;

        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (url));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
    }

    deleteCarrierCoverage(carrierCoverageId: string) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carrierService
                        .deleteCarrierCoverage(this.carrierId, carrierCoverageId)
                        .then((response) => {
                            swal(
                                'Deleted!',
                                'Record has been deleted.',
                                'success'
                            );
                            this.loadCarriersCoverage();
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carrierService
                        .remove(id)
                        .then((response) => {
                            swal(
                                'Deleted!',
                                'Record has been deleted.',
                                'success'
                            );
                            this.helperService.goToWorkspaceRouterLink('/carriers');
                        })
                        .catch((error) => {

                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    // decide si muestra popup de comentario para deshabilitar carrier
    changeStatusCarrier() {
        // determina el valor actual de carrier.active
        this.carrier.active = this.carrier.active === null ? false : this.carrier.active;
        this.carrierModal =  {...this.carrier};
        
        // decide si muestra popup o continua con el proceso
        if (this.carrier.active) {
            jQuery(this.modalDisableCarrier.nativeElement).modal('show');
        } else {
            this.continueChangeStatus();
        }
    }

    // guarda el carrier con cambio de status en la base de datos
    continueChangeStatus() {
        // setea nuevo carrier.active
        this.carrierModal.active = !this.carrierModal.active;
        // abre fancy de loading
        this.helperService.showLoadingMxpro360();
        // consume servicio de bd
        this.carrierService
            .save(this.carrierModal)
            .then(() => {
                // muestra mensaje de guardado exitoso
                this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            })
            .catch((error) => {
                // muestra mensaje de guardado fallido
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                // cierra fancy de loading
                this.helperService.hideLoadingMxpro360();
            });
    }

    onChangeLogo(files: Array<string>) {
        if (files.length > 0) {
            this.carrier.logo = files[0];
            this.saveCarrier();
        }
    }

    saveCarrier() {
        this.helperService.showLoadingMxpro360();
        this.carrierService
            .save(this.carrier)
            .then(() => {
                this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    showPreBol() {
        window.open(environment.api.workspace + '/carriers/' + this.carrierId + '/document-bol-preview?Authorization=' +
            this.authService.session.token + '&workspace_id=' +  this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
      
    }

}