<header class="header navbar navbar-expand-sm fixed-top">
    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
    </a>

    <!-- <ul class="navbar-item flex-row">
        <li class="nav-item align-self-center page-heading">
            <div class="page-header">
                <div class="page-title">
                    <img src="assets/img/logo_moverxpro360.png" alt="">
                </div>
            </div>
        </li>
    </ul> -->

    <div class="nav-logo align-self-center">
        <a class="navbar-brand" href="#">
            <img alt="logo" src="assets/img/logo_moverxpro360.png" height="60px">
        </a>
    </div>

    <ul class="navbar-item flex-row navbar-dropdown">

        <li class="nav-item dropdown language-dropdown more-dropdown">
            <div class="dropdown  custom-dropdown-icon">
                <a class="dropdown-toggle btn" href="#" role="button" id="customDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img src="assets/img/usa.png" class="flag-width" alt="flag">
                    <span>English</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-down">
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customDropdown">
                    <a class="dropdown-item" data-img-value="de" data-value="German" href="javascript:void(0);"><img
                            src="assets/img/de.png" class="flag-width" alt="flag"> German</a>
                    <a class="dropdown-item" data-img-value="jp" data-value="Japanese" href="javascript:void(0);"><img
                            src="assets/img/jp.png" class="flag-width" alt="flag"> Japanese</a>
                    <a class="dropdown-item" data-img-value="fr" data-value="French" href="javascript:void(0);"><img
                            src="assets/img/fr.png" class="flag-width" alt="flag"> French</a>
                    <a class="dropdown-item" data-img-value="usa" data-value="English" href="javascript:void(0);"><img
                            src="assets/img/usa.png" class="flag-width" alt="flag"> English</a>
                </div>
            </div>
        </li>

        <li class="nav-item dropdown message-dropdown">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="messageDropdown" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-message-circle">
                    <path
                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                    </path>
                </svg><span class="badge badge-primary"></span>
            </a>
            <div class="dropdown-menu p-0 position-absolute" aria-labelledby="messageDropdown">
                <div>
                    <a class="dropdown-item">
                        <div>

                            <div class="media">
                                <div class="user-img">
                                    <img class="usr-img rounded-circle" src="assets/img/90x90.jpg" alt="profile">
                                </div>
                                <div class="media-body">
                                    <div>
                                        <h5 class="usr-name">Kara Young</h5>
                                        <p class="msg-title">ACCOUNT UPDATE</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </a>
                    <a class="dropdown-item">
                        <div>

                            <div class="media">
                                <div class="user-img">
                                    <img class="usr-img rounded-circle" src="assets/img/90x90.jpg" alt="profile">
                                </div>
                                <div class="media-body">
                                    <div>
                                        <h5 class="usr-name">Daisy Anderson</h5>
                                        <p class="msg-title">ACCOUNT UPDATE</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </a>
                    <a class="dropdown-item">
                        <div>

                            <div class="media">
                                <div class="user-img">
                                    <img class="usr-img rounded-circle" src="assets/img/90x90.jpg" alt="profile">
                                </div>
                                <div class="media-body">
                                    <div>
                                        <h5 class="usr-name">Oscar Garner</h5>
                                        <p class="msg-title">ACCOUNT UPDATE</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </a>
                </div>
            </div>
        </li>

        <li class="nav-item dropdown notification-dropdown">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="notificationDropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-bell">
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg><span class="badge badge-success"></span>
            </a>
            <div class="dropdown-menu position-absolute" aria-labelledby="notificationDropdown">
                <div class="notification-scroll">

                    <div class="dropdown-item">
                        <div class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-heart">
                                <path
                                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                                </path>
                            </svg>
                            <div class="media-body">
                                <div class="notification-para"><span class="user-name">Shaun Park</span> likes your
                                    photo.</div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown-item">
                        <div class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-share-2">
                                <circle cx="18" cy="5" r="3"></circle>
                                <circle cx="6" cy="12" r="3"></circle>
                                <circle cx="18" cy="19" r="3"></circle>
                                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                            </svg>
                            <div class="media-body">
                                <div class="notification-para"><span class="user-name">Kelly Young</span> shared your
                                    post</div>
                            </div>
                        </div>
                    </div>

                    <div class="dropdown-item">
                        <div class="media">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-tag">
                                <path
                                    d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z">
                                </path>
                                <line x1="7" y1="7" x2="7" y2="7"></line>
                            </svg>
                            <div class="media-body">
                                <div class="notification-para"><span class="user-name">Kelly Young</span> mentioned you
                                    in comment.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                </svg>
            </a>
            <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                <div>
                <!--
                    <div class="dropdown-item">
                        <a href="user_profile.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg> My Profile</a>
                    </div>
                -->
                    <div class="dropdown-item">
                        <a routerLink="/my-profile/workspaces">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="3" y1="9" x2="21" y2="9"></line><line x1="9" y1="21" x2="9" y2="9"></line></svg>
                            My Workspaces
                        </a>
                    </div>
                    <div class="dropdown-item">
                        <a class="cursor-pointer" (click)="backTo()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="12 8 8 12 12 16"></polyline><line x1="16" y1="12" x2="8" y2="12"></line></svg>
                            Back
                        </a>
                    </div>
                    <div class="dropdown-item">
                        <a class="cursor-pointer" (click)="logout()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out">
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            Log Out
                        </a>
                    </div>
                </div>
            </div>
        </li>
    </ul>

</header>


<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">
        <div class="layout-px-spacing">
            
            <div class="row">
                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mt-3">
                    <h3>
                        Account Settings
                    </h3>
                </div>
            </div>
            <!-- header -->
                
            <div class="account-settings-container layout-top-spacing">

                <div class="account-content">
                    <div class="scrollspy-example" data-spy="scroll" data-target="#account-settings-scroll" data-offset="-100">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 layout-spacing form-update-profile">
                                <form id="update-form" autocomplete="off"  #formSignin="ngForm" class="section general-info" (ngSubmit)="save()" ngNativeValidate>
                                    <div class="info">
                                        <h6 class="m-0 mb-4">Update Profile</h6>
                                        <div class="row">
                                            <div class="col-sm-11">

                                                <div class="form-group mb-3">
                                                    <label for="name">Full Name</label>
                                                    <div class="input-group mb-1">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-user">
                                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2">
                                                                    </path>
                                                                    <circle cx="12" cy="7" r="4"></circle>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" name="name" required
                                                            placeholder="Enter here the name" value="" [(ngModel)]="dataForm.name">
                                                    </div>
                                                </div>
                    
                                                <div class="form-group mb-3">
                                                    <label for="email">Email</label>
                                                    <div class="input-group mb-1">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-mail">
                                                                    <path
                                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                                    </path>
                                                                    <polyline points="22,6 12,13 2,6">
                                                                    </polyline>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input type="text" readonly class="form-control" value="" name="email" role="presentation" autocomplete="off"
                                                            placeholder="Enter here the email" [(ngModel)]="dataForm.email">
                                                    </div>
                                                </div>

                                                <div class="form-group mb-3">
                                                    <label for="phone">Phone</label>
                                                    <div class="input-group mb-1">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-phone">
                                                                    <path
                                                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input type="text" class="form-control" name="phone" value=""
                                                            minlength="10" maxlength="10" required onlyNumbers
                                                            placeholder="Enter here the phone number" [(ngModel)]="dataForm.phone">
                                                    </div>
                                                </div>

                                                <div class="form-group mb-3">
                                                    <label for="Password">Password</label>
                                                    <div class="input-group mb-1">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg 
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-lock">
                                                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input type="password" class="form-control" name="password" value=""
                                                            minlength="6" maxlength="20" role="presentation" autocomplete="new-password"
                                                            placeholder="Enter here the new password" [(ngModel)]="dataForm.password">
                                                    </div>
                                                </div>

                                                <div class="form-group mb-3" [hidden]="!dataForm.password">
                                                    <label for="Password">Confirm Password</label>
                                                    <div class="input-group mb-1">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg 
                                                                    xmlns="http://www.w3.org/2000/svg" width="24"
                                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                                    stroke="currentColor" stroke-width="2"
                                                                    stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-lock">
                                                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input type="password" class="form-control" value="" minlength="6" maxlength="20" name="confirm-password"
                                                            placeholder="Confirm here the new password" [(ngModel)]="confirmPassword">
                                                    </div>
                                                </div>

                                                <div class="text-right">
                                                    <button class="btn btn-warning mb-2" type="submit">
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 layout-spacing img-update-profile">
                                <img src="assets/img/update-profile.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            </div>
    </div>
    <!--  END CONTENT AREA  -->

</div>
<!-- END MAIN CONTAINER -->