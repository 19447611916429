import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CapabilityResponse } from 'src/app/entities/helpers/capability-response';

@Injectable({
    providedIn: 'root'
})
export class CapabilitiesService {

    constructor(private http: HttpClient) {

    }

    loadCapabilities(): Promise<CapabilityResponse> {
        return this.http.get<CapabilityResponse>(environment.api.workspace + '/capabilities').toPromise();
    }
}
