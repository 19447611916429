import { EmployeeView } from 'src/app/entities/workspace/employee-view';
//import { EmployeesService } from 'src/app/services/workspaces/employees.service';  se comenta porque estamos en un componente de una compañia no en workspaces
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

declare var jQuery;
declare var swal;

@Component({
    selector: 'app-employees-companies',
    templateUrl: './employees-companies.component.html',
    styleUrls: ['./employees-companies.component.scss']
})
export class EmployeesCompaniesComponent implements OnInit, AfterViewInit {

    employees: Array<any>;
    actualPage = 1;
    filterSearch = '';
    accounts: any[];

    constructor(
      //  private employeesService: EmployeesService,
        private employeesCompanyService: EmployeesCompanyService,
        private helperService: HelperService
    ) {
        this.employees = [];
        this.accounts = [];
    }

    ngOnInit(): void {
      this.load();



    }

    ngAfterViewInit(): void{
    }

    load() {
        this.helperService.showLoadingMxpro360();
        this.employeesCompanyService
            .getAllEmployeeAccount()
            .then((response) => {

                this.employees = response;

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    togleStatus(id) {
        this.helperService.showLoadingMxpro360();
        this.employeesCompanyService
            .togleStatus(id)
            .then((response) => {
                this.load();
                swal(
                    'Status Changed!',
                    'The record has been updated.',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }
}
