import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';
import { TimeClock } from 'src/app/entities/companies/time-clock';
import { Company } from 'src/app/entities/workspace/company';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
declare const moment

@Component({
  selector: 'app-payroll-time-clock-manual',
  templateUrl: './payroll-time-clock-manual.component.html',
  styleUrls: ['./payroll-time-clock-manual.component.scss']
})
export class PayrollTimeClockManualComponent implements OnInit {

  public dataForm: TimeClock;
  public companies: Array<Company>;
  public employees: EmployeeView[]
  private idEmployee: any;
  private companyId: number;



  constructor(
    private currentRoute: ActivatedRoute,
    private clockService: PayrollTimeClockService,
    private helperService: HelperService,
  ) {
    this.dataForm = new TimeClock();
  }

  ngOnInit(): void {

    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.dataForm.employee_id = params.id;
      }
    })

  }

  save() {
    
    const clockIn = moment();
    const clockOut = moment();
    const date  = this.dataForm.date.split('-');
    const timeIn = this.dataForm.time_in.split(":");
    const timeOut = this.dataForm.time_out.split(":");
    
    clockIn.set({
      year: date[0],     // Establece el año
      month: Number(date[1])-1,       // Establece el mes (0-11, donde 5 representa junio)
      date: date[2],       // Establece el día del mes
      hour: timeIn[0],       // Establece la hora
      minute: timeIn[1],     // Establece los minutos
    });

    clockOut.set({
      year: date[0],     // Establece el año
      month: Number(date[1])-1,       // Establece el mes
      date: date[2],       // Establece el día del mes
      hour: timeOut[0],       // Establece la hora
      minute: timeOut[1],     // Establece los minutos
    });
    
    this.dataForm.clock_in = moment(clockIn, moment.HTML5_FMT.DATE).unix() * 1000;
    this.dataForm.clock_out = moment(clockOut, moment.HTML5_FMT.DATE).unix() * 1000;

    this.helperService.showLoadingMxpro360();
    this.clockService
      .addManualClock(this.dataForm).then((response) => {
        this.dataForm = new TimeClock();
        this.helperService.goToCompanyRouterLink('/time-clock');
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


}
