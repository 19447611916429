import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';
import { LocalRoomCategory } from 'src/app/entities/workspace/local-room-category';
import { HelperService } from 'src/app/services/helper.service';
import { LocalRoomCategoriesService } from 'src/app/services/workspaces/local-room-categories.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';

declare const swal;
@Component({
  selector: 'app-local-room-categories',
  templateUrl: './local-room-categories.component.html',
  styleUrls: ['./local-room-categories.component.scss']
})
export class LocalRoomCategoriesComponent implements OnInit {

  private constantsMessages = ConstantsMessages;
  public localRoomCategories: LocalRoomCategory[];
  public filterSearch: string;
  public workspaceSettings: WorkspaceSetting;

  constructor(
    private localRoomCategoriesService: LocalRoomCategoriesService,
    private helperService: HelperService,
    private settingsService: SettingsService,
    ) {
    this.localRoomCategories = [];
    this.filterSearch = '';
  }

  ngOnInit(): void {
    this.load();
    this.loadWorkspaceSetting();

  }

  private load() {
    this.helperService.showLoadingMxpro360();
    this.localRoomCategoriesService
        .getAll()
        .then((response) => {
            this.localRoomCategories = response;
            console.log('localRoomCategories => ', this.localRoomCategories);
        })
        .catch((error) => {

        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}


loadWorkspaceSetting() {
  this.settingsService
  .get()
  .then((responseSettings) => {
      this.workspaceSettings = responseSettings;
  });
}

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.localRoomCategoriesService
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Deleted!',
                'The record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('Error: ', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

  saveExtra() {
    this.helperService.showLoadingMxpro360();
    this.settingsService.save(this.workspaceSettings)
      .then(() => {

      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);

      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });

    }
      
  isActiveChanged(e, row) {
    row.is_active = e;    
    console.log('this.localRoomCategory => ', row);
    this.helperService.showLoadingMxpro360();
    this.helperService.showLoadingMxpro360();
    this.localRoomCategoriesService
      .save(row)
      .then(response => {
        console.log('response => ', response);
        
        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        this.helperService.goToWorkspaceRouterLink('/local-room-categories');
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
