<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">New</span><span *ngIf="dataForm.id != null">Edit</span> Automation
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appCompanyRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/automations">Automation</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                </div>
            </div>
        </div>
        <!-- header -->

        <!-- Automation Progress Bar -->
        <div class="row layout-top-spacing auto-progress-bar-container">
            <div class="col-12">            
                <div class="widget-content widget-content-area pad-progress-bar">

                    <div class="auto-progress-bar">

                        <div class="auto-step-icon" [ngClass]="{'active-step': dataForm.action != null}">
                            <i class="fa-solid fa-envelope" *ngIf="dataForm.action == 'EMAIL'"></i>
                            <i class="fa-solid fa-comment-dots" *ngIf="dataForm.action == 'TEXT'"></i>
                            <i class="fa-solid fa-tags" *ngIf="dataForm.action == 'CREATE_TICKET'"></i>
                            <!-- Step Label -->
                            <div class="step-label">Action</div>
                        </div>

                        <div class="arrow" [ngClass]="{'active-arrow': dataForm.action != null}">
                            <i class="fa-solid fa-chevron-right"></i>                            
                        </div>

                        <div class="auto-step-txt" [ngClass]="{'active-step': dataForm.trigger_event != null}">
                            {{dataForm.trigger_event | filterReplace}}
                            <!-- Step Label -->
                            <div class="step-label">Trigger Event</div>
                        </div>

                        <div class="arrow" [ngClass]="{'active-arrow': dataForm.trigger_event != null}">
                            <i class="fa-solid fa-chevron-right"></i>
                        </div>

                        <div *ngIf="typeTriggerEvent == 'TIME'" class="auto-step-txt" [ngClass]="{'active-step': dataForm.action_time != null}">
                            <span *ngIf="dataForm.action_time == 'AT_TRIGGER_EVENT'">At Trigger Event</span>
                            <span *ngIf="dataForm.action_time == 'SET_TIME'">Set Time</span>
                            <!-- Step Label -->
                            <div class="step-label">Action Time</div>
                        </div>

                        <div *ngIf="typeTriggerEvent == 'TIME'" class="arrow" [ngClass]="{'active-arrow': dataForm.action_time != null}"> 
                            <i class="fa-solid fa-chevron-right"></i>
                        </div>

                        <div class="auto-step-txt" [ngClass]="{'active-step': dataForm.time != null}">
                            <span *ngIf="dataForm.time == 'WHEN'">When</span>
                            <span *ngIf="dataForm.time == 'SET_TIME'">Set Time</span>
                            <!-- Step Label -->
                            <div class="step-label">Time</div>
                        </div>

                        <div class="arrow" [ngClass]="{'active-arrow': dataForm.time != null}"> 
                            <i class="fa-solid fa-chevron-right"></i>
                        </div>

                        <ng-container *ngIf="showClassAndCategory">
                            <div class="auto-step-icon" [ngClass]="{'active-step': dataForm.status != null}">
                                <i class="fa-solid fa-users-viewfinder" *ngIf="dataForm.status == 'LEAD'"></i>
                                <i class="fa-solid fa-file-lines" *ngIf="dataForm.status == 'ESTIMATE'"></i>
                                <i class="fa-solid fa-briefcase" *ngIf="dataForm.status == 'JOB'"></i>
                                <!-- Step Label -->
                                <div class="step-label">Status</div>
                            </div>
    
                            <div class="arrow" [ngClass]="{'active-arrow': dataForm.status != null}"> 
                                <i class="fa-solid fa-chevron-right"></i>
                            </div>
    
                            <div class="auto-step-txt" [ngClass]="{'active-step': dataForm.category != null}">
                                {{dataForm.category | titlecase}}
                                <!-- Step Label -->
                                <div class="step-label">Category</div>
                            </div>
    
                            <div class="arrow" [ngClass]="{'active-arrow': dataForm.category != null}">
                                <i class="fa-solid fa-chevron-right"></i>
                            </div>
                        </ng-container>

                        <div class="auto-step-txt" [ngClass]="{'active-step': dataForm.filters.price_range != null}">
                            <span *ngIf="dataForm.filters.price_range == 'ALL'">All</span>
                            <span *ngIf="dataForm.filters.price_range == 'SET'">Set</span>
                            <!-- Step Label -->
                            <div class="step-label">Price Range</div>
                        </div>

                        <div class="arrow" [ngClass]="{'active-arrow': dataForm.filters.price_range != null}"> 
                            <i class="fa-solid fa-chevron-right"></i>
                        </div>

                        <div class="auto-step-txt" [ngClass]="{'active-step': dataForm.filters.size_range != null}">
                            <span *ngIf="dataForm.filters.size_range == 'ALL'">All</span>
                            <span *ngIf="dataForm.filters.size_range == 'SET'">Set</span>
                            <!-- Step Label -->
                            <div class="step-label">Size Range</div>
                        </div>

                        <div class="arrow" [ngClass]="{'active-arrow': dataForm.filters.size_range != null}">
                            <i class="fa-solid fa-chevron-right"></i>
                        </div>

                        <div class="auto-step-icon" [ngClass]="{'active-step': filterPickupState.length > 0}">
                            <!-- {{filterPickupState | json}} -->
                            <i *ngIf="filterPickupState.length > 0" class="fa-solid fa-location-dot"></i>
                            <!-- Step Label -->
                            <div class="step-label">Pickup State</div>
                        </div>

                        <div class="arrow" [ngClass]="{'active-arrow': filterPickupState.length > 0}"> 
                            <i class="fa-solid fa-chevron-right"></i>
                        </div>

                        <div class="auto-step-icon" [ngClass]="{'active-step': filterDeliveryState.length > 0}">
                            <!-- {{filterDeliveryState | json}} -->
                            <i *ngIf="filterDeliveryState.length > 0" class="fa-solid fa-location-dot"></i>
                            <!-- Step Label -->
                            <div class="step-label">Delivery State</div>
                        </div>

                    </div>                  

                </div>
            </div>     
        </div>  

        <!-- Step by Step -->
        <div class="row layout-top-spacing mb-5">
            <div class="col-12">            
                <div class="widget-content widget-content-area"> 

                    <form class="form" id="regForm" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>                   
                                                   
                        <!-- ACTION -->
                        <div class="tab">
                            <div class="tab-title">ACTION</div>
                            <div class="row">
                                
                                <!-- Automation Options -->
                                <div class="col-5 col-action">

                                    <!-- Email -->
                                    <div class="action email" (click)="dataForm.action = 'EMAIL'; resetActionForm()" [ngClass]="{'active': dataForm.action == 'EMAIL'}">
                                        <div class="icon">
                                            <i class="fa-solid fa-envelope"></i>
                                        </div>
                                        <div class="content-action">
                                            <div class="action-name">Email</div>
                                            <div class="">Select the Email action to create a new event</div>
                                        </div>                                                
                                    </div>

                                    <!-- Text SMS -->
                                    <div class="action text-sms" (click)="dataForm.action = 'TEXT'; resetActionForm()" [ngClass]="{'active': dataForm.action == 'TEXT'}">
                                        <div class="icon">
                                            <i class="fa-solid fa-comment-dots"></i>
                                        </div>
                                        <div class="content-action">
                                            <div class="action-name">Text SMS</div>
                                            <div>Select the Text SMS action to create a new event</div>
                                        </div>                                                
                                    </div>

                                    <!-- Create Ticket -->
                                    <div class="action create-ticket" (click)="dataForm.action = 'CREATE_TICKET'; resetActionForm()" [ngClass]="{'active': dataForm.action == 'CREATE_TICKET'}">
                                        <div class="icon">
                                            <i class="fa-solid fa-tags"></i>
                                        </div>
                                        <div class="content-action">
                                            <div class="action-name">Create Ticket</div>
                                            <div>Select the Create Ticket action to create a new event</div>
                                        </div>                                                
                                    </div>
                                </div>
                                
                                <!-- Form -->
                                <div class="col-7 col-action-form">

                                    <!-- Email Section -->
                                    <div class="section-email" *ngIf="dataForm.action == 'EMAIL' || dataForm.action == null">

                                        <!-- Title Section -->
                                        <div class="title-action-section">
                                            <i class="fa-solid fa-envelope"></i>
                                            <div class="title">Send an Email</div>
                                        </div>

                                        <!-- From - Select -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="from_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">From</span>
                                                    {{dataForm.from | filterReplace}}
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" (click)="dataForm.from = 'ASSIGNED_AGENT'">Assigned Agent</a>
                                                    <a class="dropdown-item" (click)="dataForm.from = 'COMPANY'">Company</a>
                                                </div>
                                            </div>
                                        </div>
        
                                        <!-- To - Select -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="to_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">To</span>
                                                    {{dataForm.to | filterReplace}}
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" (click)="dataForm.to = 'CUSTOMER'">Customer</a>
                                                    <a class="dropdown-item" (click)="dataForm.to = 'COMPANY_EMPLOYEES'">Company Employees</a>
                                                    <a class="dropdown-item" (click)="dataForm.to = 'CUSTOM'">Custom</a>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Employee - Select -->
                                        <div class="input-auto-filter-group my-4" *ngIf="dataForm.to == 'COMPANY_EMPLOYEES'">
                                            <div id="employee_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">Employee</span>
                                                    {{employeeName | titlecase}}
                                                </button>
                                                <div class="dropdown-menu employees-dropdown" aria-labelledby="dropdownMenuButton">
                                                    <a *ngFor="let employee of employees" class="dropdown-item" (click)="dataForm.to_employee_id = employee.id; employeeName = employee.name">
                                                        {{employee.name | titlecase}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Custom Email - Input -->
                                        <div class="input-group input-auto email my-4" *ngIf="dataForm.to == 'CUSTOM'">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon7">Email</span>
                                            </div>
                                            <input autocomplete="off" type="text" id="to_custom_email" class="form-control" name="to_custom_email" placeholder="Email@address.com" [(ngModel)]="dataForm.to_custom_email" required>
                                        </div>

                                        <!-- Template - Select -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="template_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">Template</span>
                                                    {{emailTemplateAction | titlecase}}
                                                </button>
                                                <div class="dropdown-menu employees-dropdown" aria-labelledby="dropdownMenuButton">
                                                    <ng-container *ngFor="let emailTemplate of emailTemplates">
                                                        <a *ngIf="emailTemplate.usage_context == 'AUTOMATION' || emailTemplate.usage_context == 'BOTH'" class="dropdown-item" (click)="dataForm.email_template_id = emailTemplate.id; emailTemplateAction = emailTemplate.subject">
                                                            {{emailTemplate.subject | titlecase}}
                                                        </a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Template - View -->
                                        <div class="section-template" *ngIf="dataForm.email_template_id">
                                            <ng-container *ngFor="let emailTemplate of emailTemplates">
                                                <div [innerHTML]="emailTemplate.body" *ngIf="emailTemplate.id == dataForm.email_template_id">
                                                </div>
                                            </ng-container>                                            
                                        </div>
                                        
                                    </div>                                   
                                                                        
                                    <!-- Text SMS Section -->
                                    <div class="section-text-sms" *ngIf="dataForm.action == 'TEXT'">

                                        <!-- Title Section -->
                                        <div class="title-action-section">
                                            <i class="fa-solid fa-comment-dots"></i>
                                            <div class="title">Send Text SMS</div>
                                        </div>

                                        <!-- From - Select -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="from_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">From</span>
                                                    {{dataForm.from | filterReplace}}
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" (click)="dataForm.from = 'ASSIGNED_AGENT'">Assigned Agent</a>
                                                    <a class="dropdown-item" (click)="dataForm.from = 'COMPANY'">Company</a>
                                                </div>
                                            </div>
                                        </div>
        
                                        <!-- To - Select -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="to_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">To</span>
                                                    {{dataForm.to | filterReplace}}
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a class="dropdown-item" (click)="dataForm.to = 'CUSTOMER'">Customer</a>
                                                    <a class="dropdown-item" (click)="dataForm.to = 'COMPANY_EMPLOYEES'">Company Employees</a>
                                                    <a class="dropdown-item" (click)="dataForm.to = 'CUSTOM'">Custom</a>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Employee - Select -->
                                        <div class="input-auto-filter-group my-4" *ngIf="dataForm.to == 'COMPANY_EMPLOYEES'">
                                            <div id="employee_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">Employee</span>
                                                    {{employeeName | titlecase}}
                                                </button>
                                                <div class="dropdown-menu employees-dropdown" aria-labelledby="dropdownMenuButton">
                                                    <a *ngFor="let employee of employees" class="dropdown-item" (click)="dataForm.to_employee_id = employee.id; employeeName = employee.name">
                                                        {{employee.name | titlecase}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Custom Phone - Input -->
                                        <div class="input-group input-auto phone my-4" *ngIf="dataForm.to == 'CUSTOM'">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon7">Phone</span>
                                            </div>
                                            <input autocomplete="off" type="text" class="form-control" name="to_custom_phone" placeholder="(555) 555 5555" [(ngModel)]="dataForm.to_custom_phone" required>
                                        </div>

                                        <!-- Template - Select -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="template_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">Template</span>
                                                    {{smsTemplateAction | titlecase}}
                                                </button>
                                                <div class="dropdown-menu employees-dropdown" aria-labelledby="dropdownMenuButton">
                                                    <a *ngFor="let smsTemplate of smsTemplates" class="dropdown-item" (click)="dataForm.sms_template_id = smsTemplate.id; smsTemplateAction = smsTemplate.name">
                                                        {{smsTemplate.name | titlecase}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Template - View -->
                                        <div class="section-template" *ngIf="dataForm.sms_template_id">
                                            <ng-container *ngFor="let smsTemplate of smsTemplates">
                                                <div [innerHTML]="smsTemplate.message" *ngIf="smsTemplate.id == dataForm.sms_template_id">                                                        
                                                </div>
                                            </ng-container>
                                        </div>

                                    </div>

                                    <!-- Create Ticket Section -->
                                    <div class="section-create-ticket" *ngIf="dataForm.action == 'CREATE_TICKET'">

                                        <!-- Title Section -->
                                        <div class="title-action-section">
                                            <i class="fa-solid fa-tags"></i>
                                            <div class="title">Create Ticket</div>
                                        </div>

                                        <!-- Ticket Subject -->
                                        <div class="input-auto-filter-group my-4">
                                            <div id="ticket_subject_dropdown" class="dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <span class="label">Ticket Subject</span>
                                                    {{ticketSubject | titlecase}}
                                                </button>
                                                <div class="dropdown-menu employees-dropdown" aria-labelledby="dropdownMenuButton">
                                                    <a *ngFor="let tickesType of ticketsTypes" class="dropdown-item" (click)="dataForm.ticket.ticket_type_id = tickesType.id; ticketSubject = tickesType.name">
                                                        {{tickesType.name | titlecase}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Ticket Description -->
                                        <div id="ticket_desc_container" class="texarea-auto-filter-group">
                                            <textarea type="text" class="form-control" id="ticket_desc" name="ticket_desc" rows="4"
                                                placeholder="Ticket Description" [(ngModel)]="dataForm.ticket.description" required>                                                
                                            </textarea>
                                        </div>

                                    </div>
                                    
                                </div>

                            </div>
                        </div>

                        <!-- TRIGGER EVENT -->
                        <div class="tab tab-trigger-event">
                            <div class="tab-title">TRIGGER EVENT</div>
                            
                            <div class="row">
                                
                                <!-- Automation Options -->
                                <div class="col col-12 te-p0">

                                    <div class="section-trigger-event">

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'ADDRESS_CHANGED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'ADDRESS_CHANGED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Address Changed</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'BECOMES_ESTIMATE'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'BECOMES_ESTIMATE'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Becomes Estimate</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'CANCEL_JOB'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'CANCEL_JOB'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Cancel Job</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>                                            

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'CLICK_BOOK'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'CLICK_BOOK'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Click Book</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'DELIVERY_IN_TRANSIT'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'DELIVERY_IN_TRANSIT'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Delivery In Transit</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'FADD'; typeTriggerEvent = 'TIME'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'FADD'}">
                                            <div class="time-icon">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">FADD</div>
                                                <div class="">Select this action time</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'FADD_DATE_CHANGED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'FADD_DATE_CHANGED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">FADD Date Changed</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>                                            

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JAF_NOT_SIGNED'; typeTriggerEvent = 'TIME'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JAF_NOT_SIGNED'}">
                                            <div class="time-icon">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">JAF Not Signed</div>
                                                <div class="">Select this action time</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JOB_DELIVERED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JOB_DELIVERED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Job Delivered</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JOB_DELIVERED_BOXES'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JOB_DELIVERED_BOXES'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Job Delivered Boxes</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JOB_IN_TRANSIT'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JOB_IN_TRANSIT'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Job In Transit</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JOB_IN_STORAGE'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JOB_IN_STORAGE'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Job In Storage</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JOB_PACKED_MOVE'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JOB_PACKED_MOVE'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Job Packed Move</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'JOB_PICKED_UP'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'JOB_PICKED_UP'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Job Picked Up</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'LEAD_ASSIGNED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'LEAD_ASSIGNED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Lead Assigned</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'LEAD_CREATED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'LEAD_CREATED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Lead Created</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'MOVE_DATE'; typeTriggerEvent = 'TIME'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'MOVE_DATE'}">
                                            <div class="time-icon">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Move Date</div>
                                                <div class="">Select this action time</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'PAYMENT_UPLOADED_IN_CUSTOMER_PORTAL'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'PAYMENT_UPLOADED_IN_CUSTOMER_PORTAL'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Payment Uploaded In Customer Portal</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'PICKUP_DATE_CHANGED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'PICKUP_DATE_CHANGED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Pickup Date Changed</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'PICKUP_DATE'; typeTriggerEvent = 'TIME'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'PICKUP_DATE'}">
                                            <div class="time-icon">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Pickup Date</div>
                                                <div class="">Select this action time</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'PUT_ON_DRIVER_INVOICE'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'PUT_ON_DRIVER_INVOICE'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Put On Driver Invoice</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'QA_RELEASE'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'QA_RELEASE'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">QA Release</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'RELEASE_JOB'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'RELEASE_JOB'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Release Job</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'STORAGE_INVOICE_DUE'; typeTriggerEvent = 'TIME'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'STORAGE_INVOICE_DUE'}">
                                            <div class="time-icon">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Storage Invoice Due</div>
                                                <div class="">Select this action time</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'STORAGE_PAYMENT_PAST'; typeTriggerEvent = 'TIME'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'STORAGE_PAYMENT_PAST'}">
                                            <div class="time-icon">
                                                <i class="fa-solid fa-hourglass-half"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Storage Payment Past</div>
                                                <div class="">Select this action time</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'TICKET_CREATED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'TICKET_CREATED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Ticket Created</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'TICKET_NOT_RESOLVED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'TICKET_NOT_RESOLVED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Ticket Not Resolved</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'TICKET_RESPONSE_CREATED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'TICKET_RESPONSE_CREATED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Ticket Response Created</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>

                                        <div class="action trigger-event" (click)="dataForm.trigger_event = 'VOLUME_CHANGED'; typeTriggerEvent = 'CLICK'; defaultAttr();" [ngClass]="{'active': dataForm.trigger_event == 'VOLUME_CHANGED'}">
                                            <div class="icon">
                                                <i class="fa-solid fa-bolt"></i>
                                            </div>
                                            <div class="content-action">
                                                <div class="action-name">Volume Changed</div>
                                                <div class="">Select this action click</div>
                                            </div>                                                
                                        </div>
                                    
                                    </div>

                                </div>                                    

                            </div>
                        </div>

                        <!-- SET TIME -->
                        <div class="tab mw-775 _set-time">
                            <div class="tab-title">SET TIME</div>

                            <div class="set-time-container" [ngClass]="{'action-time-set': dataForm.action_time != 'SET_TIME', 'time-set': dataForm.time != 'SET_TIME'}">
                            
                                <!-- Action Time -->
                                <div class="row col-md-12 mb-4" *ngIf="typeTriggerEvent == 'TIME'" [ngClass]="{'action-set-time': dataForm.action_time != 'SET_TIME', 'set-time': dataForm.time != 'SET_TIME'}">                                   

                                    <div class="col-md-6 input-auto-filter-group mb-4">
                                        <label for="action_time">Action Time</label>
                                        <div id="set_action_time_dropdown" class="dropdown">
                                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{dataForm.action_time | filterReplace}}
                                                <span *ngIf="dataForm.action_time == null">Choose an option</span>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" (click)="dataForm.action_time = 'AT_TRIGGER_EVENT'">At Trigger Event</a>
                                                <a class="dropdown-item" (click)="dataForm.action_time = 'SET_TIME'">Set Time</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <!-- Space -->
                                    </div>     

                                    <div class="col-md-6 input-auto-filter-group mb-5" *ngIf="dataForm.action_time == 'SET_TIME'">
                                        <label for="action_time_set_start">Action Time Set Start</label>
                                        <div class="input-auto-filter">                                      
                                            <input type="time" class="form-control" name="action_time_set_start" placeholder="Enter a Value" [(ngModel)]="dataForm.action_time_set_start" required>
                                        </div>                                    
                                    </div>    
                                    
                                    <div class="col-md-6 input-auto-filter-group mb-5" *ngIf="dataForm.action_time == 'SET_TIME'">
                                        <label for="action_time_set_end">Action Time Set End</label>
                                        <div class="input-auto-filter">
                                            <input type="time" class="form-control" name="action_time_set_end" placeholder="Enter a Value" [(ngModel)]="dataForm.action_time_set_end" required>
                                        </div>                                    
                                    </div> 

                                </div>
                            
                                <!-- Action Click -->
                                <div class="row col-md-12" *ngIf="typeTriggerEvent == 'CLICK' || typeTriggerEvent == 'TIME'" [ngClass]="{'action-set-time': dataForm.action_time != 'SET_TIME', 'set-time': dataForm.time != 'SET_TIME'}">

                                    <div class="col-md-6 input-auto-filter-group mb-4">
                                        <label for="time">Time</label>
                                        <div id="set_time_dropdown" class="dropdown">
                                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{dataForm.time | filterReplace}}
                                                <span *ngIf="dataForm.time == null">Choose an option</span>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" (click)="dataForm.time = 'WHEN'">When</a>
                                                <a class="dropdown-item" (click)="dataForm.time = 'SET_TIME'">Set Time</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <!-- Space -->
                                    </div>

                                    <div class="col-md-6 input-auto-filter-group mb-4" *ngIf="dataForm.time == 'SET_TIME'">
                                        <label for="time_set">Time Set</label>
                                        <div id="time_set_dropdown" class="dropdown">
                                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{dataForm.time_set | filterReplace}}
                                                <span *ngIf="dataForm.time_set == null">Choose an option</span>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" (click)="dataForm.time_set = 'BEFORE'">Before</a>
                                                <a class="dropdown-item" (click)="dataForm.time_set = 'AFTER'">After</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 input-auto-filter-group mb-4" *ngIf="dataForm.time == 'SET_TIME'">
                                        <label for="time_set">Time Interval</label>
                                        <div id="time_interval_dropdown" class="dropdown">
                                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{dataForm.time_interval | filterReplace}}
                                                <span *ngIf="dataForm.time_interval == null">Choose an option</span>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" (click)="dataForm.time_interval = 'SECONDS'">Seconds</a>
                                                <a class="dropdown-item" (click)="dataForm.time_interval = 'MINUTES'">Minutes</a>
                                                <a class="dropdown-item" (click)="dataForm.time_interval = 'HOURS'">Hours</a>
                                                <a class="dropdown-item" (click)="dataForm.time_interval = 'DAYS'">Days</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 input-auto-filter-group mb-5" *ngIf="dataForm.time == 'SET_TIME'">
                                        <label for="time_quantity">Time Quantity</label>
                                        <div class="input-auto-filter">                                        
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                            <input type="number" class="form-control" name="time_quantity" placeholder="Enter a Value" [(ngModel)]="dataForm.time_quantity" required>
                                        </div>                                    
                                    </div>

                                    <div class="col-md-6 input-auto-filter-group mb-4" *ngIf="dataForm.time == 'SET_TIME'">
                                        <label for="action_time_set_zone">Action Time Set Zone</label>
                                        <div id="action_time_set_zone_dropdown" class="dropdown">
                                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{dataForm.action_time_set_zone | filterReplace}}
                                                <span *ngIf="dataForm.action_time_set_zone == null">Choose an option</span>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" (click)="dataForm.action_time_set_zone = 'EASTERN'">Eastern</a>
                                                <a class="dropdown-item" (click)="dataForm.action_time_set_zone = 'CENTRAL'">Central</a>
                                                <a class="dropdown-item" (click)="dataForm.action_time_set_zone = 'MOUNTAIN'">Mountain</a>
                                                <a class="dropdown-item" (click)="dataForm.action_time_set_zone = 'PACIFIC'">Pacific</a>
                                                <a class="dropdown-item" (click)="dataForm.action_time_set_zone = 'HAWAIIAN'">Hawaiian</a>
                                                <a class="dropdown-item" (click)="dataForm.action_time_set_zone = 'ALASKAN'">Alaskan</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                                   
                            </div>                                
                        </div>

                        <!-- STATUS & CATEGORY -->
                        <div class="tab _status-and-category" *ngIf="showClassAndCategory">
                            <div class="tab-title">STATUS & CATEGORY</div>

                            <div class="row">

                                <!-- Status  -->
                                <div class="col-4">

                                    <div *ngIf="showLeadStatus" class="action lead" (click)="dataForm.status = 'LEAD'; dataForm.category = null" [ngClass]="{'active': dataForm.status == 'LEAD'}">
                                        <div class="icon">
                                            <i class="fa-solid fa-users-viewfinder"></i>
                                        </div>
                                        <div class="content-action">
                                            <div class="action-name">Lead</div>
                                            <div class="">Select this Status</div>
                                        </div>                                                
                                    </div>

                                    <div *ngIf="showEstimateStatus" class="action estimate" (click)="dataForm.status = 'ESTIMATE'; dataForm.category = null" [ngClass]="{'active': dataForm.status == 'ESTIMATE'}">
                                        <div class="icon">
                                            <i class="fa-solid fa-file-lines"></i>
                                        </div>
                                        <div class="content-action">
                                            <div class="action-name">Estimate</div>
                                            <div class="">Select this Status</div>
                                        </div>                                                
                                    </div>

                                    <div *ngIf="showJobStatus" class="action job" (click)="dataForm.status = 'JOB'; dataForm.category = null" [ngClass]="{'active': dataForm.status == 'JOB'}">
                                        <div class="icon">
                                            <i class="fa-solid fa-briefcase"></i>
                                        </div>
                                        <div class="content-action">
                                            <div class="action-name">Job</div>
                                            <div class="">Select this Status</div>
                                        </div>                                                
                                    </div>

                                </div>

                                <div class="col-1 status-arrow">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>

                                <!-- Category  -->
                                <div class="col-7" *ngIf="showCategories">

                                    <div class="section-category" [ngClass]="{'selected-lead': dataForm.status == 'LEAD', 'selected-estimate': dataForm.status == 'ESTIMATE', 'selected-job': dataForm.status == 'JOB'}">                                        

                                        <div class="row" [ngClass]="{'row-category': dataForm.status == null}">
                                            <div class="action category" (click)="dataForm.category = 'ALL'" [ngClass]="{'active': dataForm.category == 'ALL'}">
                                                <div class="icon">
                                                    <i class="fa-solid fa-tag"></i>
                                                </div>
                                                <div class="content-action">
                                                    <div class="action-name">All</div>
                                                    <div class="">Select this {{dataForm.status | titlecase}} Category</div>
                                                </div>                                                
                                            </div>
                                            <div class="action category" (click)="dataForm.category = 'NEW'" [ngClass]="{'active': dataForm.category == 'NEW'}">
                                                <div class="icon">
                                                    <i class="fa-solid fa-tag"></i>
                                                </div>
                                                <div class="content-action">
                                                    <div class="action-name">New</div>
                                                    <div class="">Select this {{dataForm.status | titlecase}} Category</div>
                                                </div>                                                
                                            </div>                                             
                                        </div>
                                    
                                        <div class="row" [ngClass]="{'row-category': dataForm.status == null}">
                                            <div class="action category" (click)="dataForm.category = 'HOT'" [ngClass]="{'active': dataForm.category == 'HOT'}">
                                                <div class="icon">
                                                    <i class="fa-solid fa-tag"></i>
                                                </div>
                                                <div class="content-action">
                                                    <div class="action-name">Hot</div>
                                                    <div class="">Select this {{dataForm.status | titlecase}} Category</div>
                                                </div>  
                                                <div class="disabled-category"></div>                                              
                                            </div> 
                                            <div class="action category" (click)="dataForm.category = 'OPEN'" [ngClass]="{'active': dataForm.category == 'OPEN'}">
                                                <div class="icon">
                                                    <i class="fa-solid fa-tag"></i>
                                                </div>
                                                <div class="content-action">
                                                    <div class="action-name">Open</div>
                                                    <div class="">Select this {{dataForm.status | titlecase}} Category</div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    
                                        <div class="row" [ngClass]="{'row-category': dataForm.status == null}">
                                            <div class="action category" (click)="dataForm.category = 'COLD'" [ngClass]="{'active': dataForm.category == 'COLD'}">
                                                <div class="icon">
                                                    <i class="fa-solid fa-tag"></i>
                                                </div>
                                                <div class="content-action">
                                                    <div class="action-name">Cold</div>
                                                    <div class="">Select this {{dataForm.status | titlecase}} Category</div>
                                                </div>                                                
                                            </div>
                                            <div class="action category" (click)="dataForm.category = 'BAD'" [ngClass]="{'active': dataForm.category == 'BAD'}">
                                                <div class="icon">
                                                    <i class="fa-solid fa-tag"></i>
                                                </div>
                                                <div class="content-action">
                                                    <div class="action-name">Bad</div>
                                                    <div class="">Select this {{dataForm.status | titlecase}} Category</div>
                                                </div>                                                
                                            </div>                                            
                                        </div> 
                                    
                                    </div>                                       

                                </div>

                            </div>
                            
                        </div>

                        <!-- RANGE FILTER -->
                        <div class="tab mw-775 _range-filter">
                            <div class="tab-title">RANGE FILTER</div>

                            <div class="row" [ngClass]="{'price-range-set': dataForm.filters.price_range != 'SET', 'size-range-set': dataForm.filters.size_range != 'SET'}">

                                <!-- Price Range -->
                                <div class="col-md-6 input-auto-filter-group mb-4">
                                    <label for="price_range_start">Price Range</label>
                                    <div id="price_range_dropdown" class="dropdown">
                                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{dataForm.filters.price_range | filterReplace}}
                                            <span *ngIf="dataForm.filters.price_range == null">Choose an option</span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="dataForm.filters.price_range = 'ALL'">All</a>
                                            <a class="dropdown-item" (click)="dataForm.filters.price_range = 'SET'">Set</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-6"><!-- Espacio --></div> 
                                
                                <div class="col-md-6 input-auto-filter-group mb-5" *ngIf="dataForm.filters.price_range == 'SET'">
                                    <label for="price_range_start">Price Range Start</label>
                                    <div class="input-auto-filter">                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                        <input type="number" class="form-control" name="price_range_start"  placeholder="Enter a Value"   [(ngModel)]="dataForm.filters.price_range_start" required>
                                    </div>                                    
                                </div>   

                                <div class="col-md-6 input-auto-filter-group mb-5" *ngIf="dataForm.filters.price_range == 'SET'">
                                    <label for="">Price Range End</label>
                                    <div class="input-auto-filter">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                        <input type="number" class="form-control" name="price_range_end" placeholder="Enter a Value" [(ngModel)]="dataForm.filters.price_range_end" required>
                                    </div>                                    
                                </div>
                            
                                <!-- Size Range -->
                                <div class="col-md-6 input-auto-filter-group mb-4 mt-4">
                                    <label for="">Size Range</label>
                                    <div id="size_range_dropdown" class="dropdown">
                                        <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{dataForm.filters.size_range | filterReplace}}
                                            <span *ngIf="dataForm.filters.size_range == null">Choose an option</span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="dataForm.filters.size_range = 'ALL'">All</a>
                                            <a class="dropdown-item" (click)="dataForm.filters.size_range = 'SET'">Set</a>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-6"><!-- Espacio --></div>

                                <div class="col-md-6 input-auto-filter-group mb-4" *ngIf="dataForm.filters.size_range == 'SET'">
                                    <label for="">Size Range Start</label>
                                    <div class="input-auto-filter">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                        <input type="number" class="form-control" name="price_range_end" placeholder="Enter a Value" [(ngModel)]="dataForm.filters.size_range_start" required>
                                    </div>                                    
                                </div>

                                <div class="col-md-6 input-auto-filter-group mb-4" *ngIf="dataForm.filters.size_range == 'SET'">
                                    <label for="">Size Range End</label>
                                    <div class="input-auto-filter">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                        <input type="number" class="form-control" name="price_range_end" placeholder="Enter a Value" [(ngModel)]="dataForm.filters.size_range_end" required>
                                    </div>                                    
                                </div>
                                                                            
                            </div>      

                        </div>

                        <!-- STATE FILTER -->
                        <div class="tab _state-filter">
                            <div class="tab-title">STATE FILTER</div>

                            <div class="col-12">
                                <div class="row">

                                    <div class="form-group col-md-6 mb-4">
                                        <label for="pickup_state" class="pickup-state-title">Pickup State</label>
                                        <div class="pickup-state-info">
                                            No selection on Pickup means no filter for Pickup states.
                                        </div>

                                        <div class="pickup-state-list">                                            

                                            <!-- <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="All" class="new-control-input" (click)="addFilterPickupState('All')">
                                                    <span class="new-control-indicator"></span>All
                                                </label>
                                            </div> -->

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="AK" class="new-control-input" (click)="addFilterPickupState('AK')">
                                                    <span class="new-control-indicator"></span>Alaska
                                                </label>
                                            </div>
                                            
                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="AL" class="new-control-input" (click)="addFilterPickupState('AL')">
                                                    <span class="new-control-indicator"></span>Alabama
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="AR" class="new-control-input" (click)="addFilterPickupState('AR')">
                                                    <span class="new-control-indicator"></span>Arkansas
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="AZ" class="new-control-input" (click)="addFilterPickupState('AZ')">
                                                    <span class="new-control-indicator"></span>Arizona
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="CA" class="new-control-input" (click)="addFilterPickupState('CA')">
                                                    <span class="new-control-indicator"></span>California
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="CO" class="new-control-input" (click)="addFilterPickupState('CO')">
                                                    <span class="new-control-indicator"></span>Colorado
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="CT" class="new-control-input" (click)="addFilterPickupState('CT')">
                                                    <span class="new-control-indicator"></span>Connecticut
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="DE" class="new-control-input" (click)="addFilterPickupState('DE')">
                                                    <span class="new-control-indicator"></span>Delaware
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="FL" class="new-control-input" (click)="addFilterPickupState('FL')">
                                                    <span class="new-control-indicator"></span>Florida
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="GA" class="new-control-input" (click)="addFilterPickupState('GA')">
                                                    <span class="new-control-indicator"></span>Georgia
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="HI" class="new-control-input" (click)="addFilterPickupState('HI')">
                                                    <span class="new-control-indicator"></span>Hawaii
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="IA" class="new-control-input" (click)="addFilterPickupState('IA')">
                                                    <span class="new-control-indicator"></span>Iowa
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="ID" class="new-control-input" (click)="addFilterPickupState('ID')">
                                                    <span class="new-control-indicator"></span>Idaho
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="IL" class="new-control-input" (click)="addFilterPickupState('IL')">
                                                    <span class="new-control-indicator"></span>Illinois
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="IN" class="new-control-input" (click)="addFilterPickupState('IN')">
                                                    <span class="new-control-indicator"></span>Indiana
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="KS" class="new-control-input" (click)="addFilterPickupState('KS')">
                                                    <span class="new-control-indicator"></span>Kansas
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="KY" class="new-control-input" (click)="addFilterPickupState('KY')">
                                                    <span class="new-control-indicator"></span>Kentucky
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="LA" class="new-control-input" (click)="addFilterPickupState('LA')">
                                                    <span class="new-control-indicator"></span>Louisiana
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="MA" class="new-control-input" (click)="addFilterPickupState('MA')">
                                                    <span class="new-control-indicator"></span>Massachusetts
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MD" class="new-control-input" (click)="addFilterPickupState('MD')">
                                                    <span class="new-control-indicator"></span>Maryland
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="ME" class="new-control-input" (click)="addFilterPickupState('ME')">
                                                    <span class="new-control-indicator"></span>Maine
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="MI" class="new-control-input" (click)="addFilterPickupState('MI')">
                                                    <span class="new-control-indicator"></span>Michigan
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MN" class="new-control-input" (click)="addFilterPickupState('MN')">
                                                    <span class="new-control-indicator"></span>Minnesota
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MO" class="new-control-input" (click)="addFilterPickupState('MO')">
                                                    <span class="new-control-indicator"></span>Missouri
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="MS" class="new-control-input" (click)="addFilterPickupState('MS')">
                                                    <span class="new-control-indicator"></span>Mississippi
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MT" class="new-control-input" (click)="addFilterPickupState('MT')">
                                                    <span class="new-control-indicator"></span>Montana
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NC" class="new-control-input" (click)="addFilterPickupState('NC')">
                                                    <span class="new-control-indicator"></span>North-carolina
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="ND" class="new-control-input" (click)="addFilterPickupState('ND')">
                                                    <span class="new-control-indicator"></span>North-dakota
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="NE" class="new-control-input" (click)="addFilterPickupState('NE')">
                                                    <span class="new-control-indicator"></span>Nebraska
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NH" class="new-control-input" (click)="addFilterPickupState('NH')">
                                                    <span class="new-control-indicator"></span>New-hampshire
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="NJ" class="new-control-input" (click)="addFilterPickupState('NJ')">
                                                    <span class="new-control-indicator"></span>New-jersey
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NM" class="new-control-input" (click)="addFilterPickupState('NM')">
                                                    <span class="new-control-indicator"></span>New-mexico
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NV" class="new-control-input" (click)="addFilterPickupState('NV')">
                                                    <span class="new-control-indicator"></span>Nevada
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="NY" class="new-control-input" (click)="addFilterPickupState('NY')">
                                                    <span class="new-control-indicator"></span>New-york
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="OH" class="new-control-input" (click)="addFilterPickupState('OH')">
                                                    <span class="new-control-indicator"></span>Ohio
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="OK" class="new-control-input" (click)="addFilterPickupState('OK')">
                                                    <span class="new-control-indicator"></span>Oklahoma
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="OR" class="new-control-input" (click)="addFilterPickupState('OR')">
                                                    <span class="new-control-indicator"></span>Oregon
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="PA" class="new-control-input" (click)="addFilterPickupState('PA')">
                                                    <span class="new-control-indicator"></span>Pennsylvania
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="RI" class="new-control-input" (click)="addFilterPickupState('RI')">
                                                    <span class="new-control-indicator"></span>Rhode-island
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="SC" class="new-control-input" (click)="addFilterPickupState('SC')">
                                                    <span class="new-control-indicator"></span>South-carolina
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="SD" class="new-control-input" (click)="addFilterPickupState('SD')">
                                                    <span class="new-control-indicator"></span>South-dakota
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="TN" class="new-control-input" (click)="addFilterPickupState('TN')">
                                                    <span class="new-control-indicator"></span>Tennessee
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="TX" class="new-control-input" (click)="addFilterPickupState('TX')">
                                                    <span class="new-control-indicator"></span>Texas
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="UT" class="new-control-input" (click)="addFilterPickupState('UT')">
                                                    <span class="new-control-indicator"></span>Utah
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="VA" class="new-control-input" (click)="addFilterPickupState('VA')">
                                                    <span class="new-control-indicator"></span>Virginia
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="VT" class="new-control-input" (click)="addFilterPickupState('VT')">
                                                    <span class="new-control-indicator"></span>Vermont
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="WI" class="new-control-input" (click)="addFilterPickupState('WI')">
                                                    <span class="new-control-indicator"></span>Washington
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NM" class="new-control-input" (click)="addFilterPickupState('NM')">
                                                    <span class="new-control-indicator"></span>Wisconsin
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="WV" class="new-control-input" (click)="addFilterPickupState('WV')">
                                                    <span class="new-control-indicator"></span>West-virginia
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="WY" class="new-control-input" (click)="addFilterPickupState('WY')">
                                                    <span class="new-control-indicator"></span>Wyoming
                                                </label>
                                            </div>

                                        </div>
                                        
                                    </div>
    
                                    <div class="form-group col-md-6 mb-4">
                                        <label for="delivery_state" class="delivery-state-title">Delivery State</label>
                                        <div class="delivery-state-info">
                                            No selection on Delivery means no filter for Delivery states.
                                        </div>

                                        <div class="delivery-state-list">

                                            <!-- <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="All" class="new-control-input" (click)="addFilterPickupState('All')">
                                                    <span class="new-control-indicator"></span>All
                                                </label>
                                            </div> -->

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="AK" class="new-control-input" (click)="addFilterDeliveryState('AK')">
                                                    <span class="new-control-indicator"></span>Alaska
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="AL" class="new-control-input" (click)="addFilterDeliveryState('AL')">
                                                    <span class="new-control-indicator"></span>Alabama
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="AR" class="new-control-input" (click)="addFilterDeliveryState('AR')">
                                                    <span class="new-control-indicator"></span>Arkansas
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="AZ" class="new-control-input" (click)="addFilterDeliveryState('AZ')">
                                                    <span class="new-control-indicator"></span>Arizona
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="CA" class="new-control-input" (click)="addFilterDeliveryState('CA')">
                                                    <span class="new-control-indicator"></span>California
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="CO" class="new-control-input" (click)="addFilterDeliveryState('CO')">
                                                    <span class="new-control-indicator"></span>Colorado
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="CT" class="new-control-input" (click)="addFilterDeliveryState('CT')">
                                                    <span class="new-control-indicator"></span>Connecticut
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="DE" class="new-control-input" (click)="addFilterDeliveryState('DE')">
                                                    <span class="new-control-indicator"></span>Delaware
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="FL" class="new-control-input" (click)="addFilterDeliveryState('FL')">
                                                    <span class="new-control-indicator"></span>Florida
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="GA" class="new-control-input" (click)="addFilterDeliveryState('GA')">
                                                    <span class="new-control-indicator"></span>Georgia
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="HI" class="new-control-input" (click)="addFilterDeliveryState('HI')">
                                                    <span class="new-control-indicator"></span>Hawaii
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="IA" class="new-control-input" (click)="addFilterDeliveryState('IA')">
                                                    <span class="new-control-indicator"></span>Iowa
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="ID" class="new-control-input" (click)="addFilterDeliveryState('ID')">
                                                    <span class="new-control-indicator"></span>Idaho
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="IL" class="new-control-input" (click)="addFilterDeliveryState('IL')">
                                                    <span class="new-control-indicator"></span>Illinois
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="IN" class="new-control-input" (click)="addFilterDeliveryState('IN')">
                                                    <span class="new-control-indicator"></span>Indiana
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="KS" class="new-control-input" (click)="addFilterDeliveryState('KS')">
                                                    <span class="new-control-indicator"></span>Kansas
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="KY" class="new-control-input" (click)="addFilterDeliveryState('KY')">
                                                    <span class="new-control-indicator"></span>Kentucky
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="LA" class="new-control-input" (click)="addFilterDeliveryState('LA')">
                                                    <span class="new-control-indicator"></span>Louisiana
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="MA" class="new-control-input" (click)="addFilterDeliveryState('MA')">
                                                    <span class="new-control-indicator"></span>Massachusetts
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MD" class="new-control-input" (click)="addFilterDeliveryState('MD')">
                                                    <span class="new-control-indicator"></span>Maryland
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="ME" class="new-control-input" (click)="addFilterDeliveryState('ME')">
                                                    <span class="new-control-indicator"></span>Maine
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="MI" class="new-control-input" (click)="addFilterDeliveryState('MI')">
                                                    <span class="new-control-indicator"></span>Michigan
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MN" class="new-control-input" (click)="addFilterDeliveryState('MN')">
                                                    <span class="new-control-indicator"></span>Minnesota
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MO" class="new-control-input" (click)="addFilterDeliveryState('MO')">
                                                    <span class="new-control-indicator"></span>Missouri
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="MS" class="new-control-input" (click)="addFilterDeliveryState('MS')">
                                                    <span class="new-control-indicator"></span>Mississippi
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="MT" class="new-control-input" (click)="addFilterDeliveryState('MT')">
                                                    <span class="new-control-indicator"></span>Montana
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NC" class="new-control-input" (click)="addFilterDeliveryState('NC')">
                                                    <span class="new-control-indicator"></span>North-carolina
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="ND" class="new-control-input" (click)="addFilterDeliveryState('ND')">
                                                    <span class="new-control-indicator"></span>North-dakota
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="NE" class="new-control-input" (click)="addFilterDeliveryState('NE')">
                                                    <span class="new-control-indicator"></span>Nebraska
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NH" class="new-control-input" (click)="addFilterDeliveryState('NH')">
                                                    <span class="new-control-indicator"></span>New-hampshire
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="NJ" class="new-control-input" (click)="addFilterDeliveryState('NJ')">
                                                    <span class="new-control-indicator"></span>New-jersey
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NM" class="new-control-input" (click)="addFilterDeliveryState('NM')">
                                                    <span class="new-control-indicator"></span>New-mexico
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NV" class="new-control-input" (click)="addFilterDeliveryState('NV')">
                                                    <span class="new-control-indicator"></span>Nevada
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="NY" class="new-control-input" (click)="addFilterDeliveryState('NY')">
                                                    <span class="new-control-indicator"></span>New-york
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="OH" class="new-control-input" (click)="addFilterDeliveryState('OH')">
                                                    <span class="new-control-indicator"></span>Ohio
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="OK" class="new-control-input" (click)="addFilterDeliveryState('OK')">
                                                    <span class="new-control-indicator"></span>Oklahoma
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="OR" class="new-control-input" (click)="addFilterDeliveryState('OR')">
                                                    <span class="new-control-indicator"></span>Oregon
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="PA" class="new-control-input" (click)="addFilterDeliveryState('PA')">
                                                    <span class="new-control-indicator"></span>Pennsylvania
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="RI" class="new-control-input" (click)="addFilterDeliveryState('RI')">
                                                    <span class="new-control-indicator"></span>Rhode-island
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="SC" class="new-control-input" (click)="addFilterDeliveryState('SC')">
                                                    <span class="new-control-indicator"></span>South-carolina
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="SD" class="new-control-input" (click)="addFilterDeliveryState('SD')">
                                                    <span class="new-control-indicator"></span>South-dakota
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="TN" class="new-control-input" (click)="addFilterDeliveryState('TN')">
                                                    <span class="new-control-indicator"></span>Tennessee
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="TX" class="new-control-input" (click)="addFilterDeliveryState('TX')">
                                                    <span class="new-control-indicator"></span>Texas
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="UT" class="new-control-input" (click)="addFilterDeliveryState('UT')">
                                                    <span class="new-control-indicator"></span>Utah
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="VA" class="new-control-input" (click)="addFilterDeliveryState('VA')">
                                                    <span class="new-control-indicator"></span>Virginia
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success"> 
                                                    <input type="checkbox" name="VT" class="new-control-input" (click)="addFilterDeliveryState('VT')">
                                                    <span class="new-control-indicator"></span>Vermont
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="WI" class="new-control-input" (click)="addFilterDeliveryState('WI')">
                                                    <span class="new-control-indicator"></span>Washington
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="NM" class="new-control-input" (click)="addFilterDeliveryState('NM')">
                                                    <span class="new-control-indicator"></span>Wisconsin
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="WV" class="new-control-input" (click)="addFilterDeliveryState('WV')">
                                                    <span class="new-control-indicator"></span>West-virginia
                                                </label>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-checkbox checkbox-success">
                                                    <input type="checkbox" name="WY" class="new-control-input" (click)="addFilterDeliveryState('WY')">
                                                    <span class="new-control-indicator"></span>Wyoming
                                                </label>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div> 
                            
                        </div>

                        <!-- Buttons - Previous/Next -->
                        <div class="actions-buttons">
                            <button type="button" id="prevBtn" (click)="nextPrev(-1)">Previous</button>
                            <button type="button" id="nextBtn" (click)="nextPrev(1)">Next</button>
                        </div>

                        <!-- Circles which indicates the steps of the form -->
                        <div style="text-align:center;margin-top:40px;">
                            <span class="step"></span>
                            <span class="step"></span>
                            <span class="step"></span>
                            <span class="step"></span>
                            <span class="step"></span>
                            <span class="step" *ngIf="showClassAndCategory"></span>
                        </div>
                          
                    </form>

                </div>
            </div>
        </div>
 
    </div>
</div>