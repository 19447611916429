export class JobItemClaimInformation {
    public id: string;
    public damaged_description: string;
    public pre_existing_damages: boolean;
    public is_damaged: boolean;

    constructor() {
        this.id = null;
        this.damaged_description = null;
        this.pre_existing_damages = null;
        this.is_damaged = null;
    }
}
