import { Meta } from "../global/meta";

export class CarrierSetting {

    public id: number;
    public use_carrier_bol_terms: boolean;
    public use_payment_terms: boolean;
    public use_optional_accesorial_services: boolean;
    public bol_terms_metas: Array<Meta>;

    constructor() {
        this.id = null;
        this.use_carrier_bol_terms = false;
        this.use_payment_terms = false;
        this.use_optional_accesorial_services = false;
        this.bol_terms_metas = null;
    }

}
