import { Component, OnInit } from '@angular/core';
import { DeductibleLevel } from 'src/app/entities/workspace/deductible-level';
import { HelperService } from 'src/app/services/helper.service';
import { DeductibleLevelsService } from 'src/app/services/workspaces/deductible-levels.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-deductible-levels',
  templateUrl: './deductible-levels.component.html',
  styleUrls: ['./deductible-levels.component.scss']
})
export class DeductibleLevelsComponent implements OnInit {

    rows: Array<DeductibleLevel>;

    constructor(
        private deductibleLevelsService: DeductibleLevelsService,
        private helperService: HelperService
    ) {
        this.rows = [];
    }

    actualPage = 1;
    filterSearch = '';

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.load();
    }
    
    private load() {
        this.helperService.showLoadingMxpro360();
        this.deductibleLevelsService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
        .then((result) => {
            if (result.value) {
                this.helperService.showLoadingMxpro360();
                this.deductibleLevelsService
                    .remove(id)
                    .then((response) => {
                        this.load();
                        swal(
                            'Deleted!',
                            'The record has been deleted',
                            'success'
                        );
                    })
                    .catch((error) => {

                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            }
        });
    }
}
