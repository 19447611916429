import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PaymentMethod } from '../../entities/workspace/payment-method';

@Injectable({
  providedIn: 'root'
})
export class PaymentsMethodsService {

  constructor(
    private http: HttpClient
  ) {

  }

  getAll(): Promise<Array<PaymentMethod>> {
    return this.http.get<Array<PaymentMethod>>(environment.api.company + '/payment-methods').toPromise();
  }

  getById(id: string) {
    return this.http.get<PaymentMethod>(environment.api.company + '/payment-methods/' + id).toPromise();
  }

  save(dataForm: PaymentMethod): Promise<any> {
    if (dataForm.id === null) {
      return this.add(dataForm);
    }
    return this.edit(dataForm);
  }

  add(dataForm: PaymentMethod) {
    return this.http.post(environment.api.company + '/payment-methods', dataForm).toPromise();
  }

  edit(dataForm: PaymentMethod) {
    return this.http.put(environment.api.company + '/payment-methods/' + dataForm.id, dataForm).toPromise();
  }

  remove(id: string) {
    return this.http.delete(environment.api.company + '/payment-methods/' + id).toPromise();
  }
}
