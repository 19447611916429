export class ListItemSelection<T> {

    selected: boolean
    data: T;

    constructor() {
        this.selected = false;
        this.data = null;
    }

}
