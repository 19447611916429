export const ConstantsMessages = {
    CHANGE_COMPANY_ERROR: 'Company has not switched',
    CHANGE_COMPANY_SUCCESS: 'Company switched successfully',
    COPIED: 'Text copied successfully',
    DELETED: 'Has been successfully removed',
    ERROR_DOWNLOAD: 'Download failed',
    ERROR_DELETED: 'Has not been removed',
    ERROR_PRICE_DEPOSIT: 'The deposit cannot be higher than the price',
    ERROR_SAVED: 'The changes have not been saved',
    SAVED: 'Has been successfully saved',
    SEND_CAMPAIGN: 'The campaign has been sent successfully',
    EMAIL_SENT: 'The email has been sent successfully',
    UPDATE: 'The registration has been updated successfully',
    REDIRECTING_TO_LOGIN: 'Updated successfully, Redirecting to login',
    EMPTY_EMAIL: 'Email cannot be empty.',
    CATEGORY_MANDATORY: 'The category is mandatory'
};
