<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Payment Gateway
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/settings">Settings</a></li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/payment-gateway-settings">Payment Gateway</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">

                    <!--
                            <a appCompanyRouterLink="/work-departments/add" class="btn btn-primary">
                                <i class="fas fa-plus-square"></i> &nbsp; New Row
                            </a>
                        -->

                    <!--
                            <div class="switch align-self-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-list view-list active-view">
                                    <line x1="8" y1="6" x2="21" y2="6"></line>
                                    <line x1="8" y1="12" x2="21" y2="12"></line>
                                    <line x1="8" y1="18" x2="21" y2="18"></line>
                                    <line x1="3" y1="6" x2="3" y2="6"></line>
                                    <line x1="3" y1="12" x2="3" y2="12"></line>
                                    <line x1="3" y1="18" x2="3" y2="18"></line>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-grid view-grid">
                                    <rect x="3" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="14" width="7" height="7"></rect>
                                    <rect x="3" y="14" width="7" height="7"></rect>
                                </svg>
                            </div>
                        -->
                </div>


            </div>
        </div>
        <!-- header -->






        <div class="statbox widget box box-shadow">

            <!-- <div class="widget-header">
                <h4>
                    Form
                </h4>
            </div> -->

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label">Gateway:</label>
                                <select (ngModelChange)="changeGateway()" id="inputState" [(ngModel)]="dataForm.name"  name="pg_name"  class="form-control" >
                                    <option value="AUTHORIZE">Authorize</option>
                                    <!-- <option value="STRIPE">Stripe</option>
                                    <option value="PAYPAL">Paypal</option>
                                    <option value="MOLLIE">Mollie</option>
                                    <option value="INGENICO">Ingenico</option> -->
                                    <option value="SWIPEPORT">Swipeport</option>
                                    <option value="SQUARE">Square</option>
                                    <option value="CARDKNOX">Cardknox</option>
                                </select>
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Name: </label>
                                <input type="text" [(ngModel)]="dataForm.nickname" name="nickname" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Production: </label>
                                <input type="checkbox" [(ngModel)]="dataForm.production" name="production">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">CC Fee %: </label>
                                <input type="text" [(ngModel)]="dataForm.cc_fee" name="cc_fee" class="form-control">
                            </div>
                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'AUTHORIZE'">
                            <h3>Authorize</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">Api login id:</label>
                                <input type="text" [(ngModel)]="dataForm.authorize_setting.api_login_id" name="pg_api_login_id" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Transaction Key:</label>
                                <input type="text" [(ngModel)]="dataForm.authorize_setting.transaction_key" name="pg_transaction_key" class="form-control">
                            </div>

                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'STRIPE'">
                            <h3>Stripe</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">Secret key:</label>
                                <input type="text" [(ngModel)]="dataForm.stripe_setting.secret_key" name="pg_secret_key" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Publishable Key: </label>
                                <input type="text" [(ngModel)]="dataForm.stripe_setting.publishable_key" name="pg_publishable" class="form-control">
                            </div>


                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'PAYPAL'">
                            <h3>PayPal</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">Acount:</label>
                                <input type="text" [(ngModel)]="dataForm.paypal_setting.account" name="pg_account" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Client ID: </label>
                                <input type="text" [(ngModel)]="dataForm.paypal_setting.client_id" name="pg_client_id" class="form-control">
                            </div>
                            <div class="form-group mb-4">
                               <div class="custom-control custom-checkbox checkbox-info">
                                    <input type="checkbox" [(ngModel)]="dataForm.paypal_setting.production" name="pg_production" class="custom-control-input" id="sChkboxs">
                                    <label class="custom-control-label" for="sChkboxs">Production</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'MOLLIE'">
                            <h3>Mollie</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">Api key:</label>
                                <input type="text" [(ngModel)]="dataForm.mollie_setting.api_key" name="pg_api_key" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Partner ID: </label>
                                <input type="text" [(ngModel)]="dataForm.mollie_setting.partner_id" name="pg_partner_id" class="form-control">
                            </div>
                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'INGENICO'">
                            <h3>Ingenico</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">Psp ID:</label>
                                <input type="text" [(ngModel)]="dataForm.ingenico_setting.psp_id" name="pg_psp_id" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Sha in: </label>
                                <input type="text" [(ngModel)]="dataForm.ingenico_setting.sha_in" name="pg_sha_in" class="form-control">
                            </div>
                            <div class="form-group mb-4">
                                <label class="control-label">Store home url: </label>
                                <input type="text" [(ngModel)]="dataForm.ingenico_setting.store_home_url" name="pg_store_home_url" class="form-control">
                            </div>
                            <div class="form-group mb-4">
                                <label class="control-label">Store catalog Url: </label>
                                <input type="text" [(ngModel)]="dataForm.ingenico_setting.store_catalog_url" name="pg_store_catalog_url" class="form-control">
                            </div>
                        </div>

                        <div class="col-6"  *ngIf="dataForm.name == 'SWIPEPORT'">
                            <h3>Swipeport</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">Security Key:</label>
                                <input type="text" [(ngModel)]="dataForm.swipeport_setting.security_key" name="sp_st_sk" class="form-control">
                            </div>
                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'SQUARE'">
                            <h3>Square</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">App Id:</label>
                                <input type="text" [(ngModel)]="dataForm.square_setting.app_id" name="appId" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Location Id:</label>
                                <input type="text" [(ngModel)]="dataForm.square_setting.location_id" name="locationId" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Access Token:</label>
                                <input type="text" [(ngModel)]="dataForm.square_setting.access_token" name="access_token" class="form-control">
                            </div>

                        </div>
                        <div class="col-6"  *ngIf="dataForm.name == 'CARDKNOX'">
                            <h3>Cardknox</h3>

                            <div class="form-group mb-4">
                                <label class="control-label">xSoftwareName:</label>
                                <input type="text" [(ngModel)]="dataForm.cardknox_setting.x_software_name" name="x_software_name" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">xSoftwareVersion:</label>
                                <input type="text" [(ngModel)]="dataForm.cardknox_setting.x_software_version" name="x_software_version" class="form-control">
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">xKey:</label>
                                <input type="text" [(ngModel)]="dataForm.cardknox_setting.x_key" name="x_key" class="form-control">
                            </div>

                        </div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-warning ml-1 mt-3" type="submit">
                            <i class="far fa-save" style="height: 24px;"></i> &nbsp; Submit
                        </button>
                    </div>

                </form>


            </div>
        </div>


    </div>
</div>
