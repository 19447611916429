<header class="header navbar navbar-expand-sm">

    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
    </a>

    <ul class="navbar-item flex-row" >
        <li class="nav-item align-self-center search-animated">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-search toggle-search">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <form class="form-inline search-full form-inline search" role="search" (ngSubmit)="doSearchJob()">
                <div class="search-bar">
                    <input type="text" class="form-control search-form-control  ml-lg-auto" placeholder="Search..."
                        name="search_job" [(ngModel)]="searchJob">
                </div>
            </form>
        </li>
    </ul>

    <ul class="navbar-item flex-row current-company">

        <li class="nav-item dropdown language-dropdown">
            <div class="dropdown custom-dropdown-icon" *ngIf="panelType == 'WORKSPACE'">
                <button class="dropdown-toggle workspace-name" href="#" role="button" id="companiesDropdown"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="workspace-name" *ngIf="workspace !== null">
                        <i class="fa-brands fa-hubspot"></i>
                        <span>{{workspace.name | titlecase}}</span>
                    </span>
                    <span class="space"></span>
                    <span class="employee-name" *ngIf="employee !== null">
                        <i class="fa-regular fa-user"></i>
                        <span>{{employee.name | titlecase}}</span>
                    </span>
                </button>
            </div>
            <div class="dropdown custom-dropdown-icon" *ngIf="panelType == 'COMPANY'">
                <button class="dropdown-toggle company-name" role="button" id="companiesDropdown"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="company-name" *ngIf="company !== null">
                        <i class="fa-solid fa-building"></i>
                        <span>{{company.name | titlecase}}</span>
                        <i class="fa-solid fa-chevron-down"></i>
                    </span>
                    <span class="space"></span>
                    <span class="employee-name" *ngIf="employee !== null">
                        <i class="fa-regular fa-user"></i>
                        <span>{{employee.name | titlecase}}</span>
                    </span>
                </button>
                <div class="dropdown-menu dropdown-menu-right companiesDropdown" aria-labelledby="companiesDropdown"
                    *ngIf="companies.length > 1">
                    <a class="dropdown-item" *ngFor="let company of companies" (click)="changeCompany(company)">
                        {{company.name | titlecase}}
                    </a>
                </div>
            </div>
        </li>
    </ul>

    <ul class="navbar-item flex-row navbar-dropdown">

        <li class="nav-item dropdown language-dropdown more-dropdown">
            <div class="dropdown  custom-dropdown-icon">
                <!-- <a class="dropdown-toggle btn" href="#" role="button" id="customDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"> -->
                <a class="dropdown-toggle btn">
                    <img src="assets/img/usa.png" class="flag-width" alt="flag">
                    <span>English</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-down">
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </a>
                <!-- <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customDropdown">
                    <a class="dropdown-item" data-img-value="de" data-value="German" href="javascript:void(0);">
                        <img src="assets/img/de.png" class="flag-width" alt="flag"> German</a>
                    <a class="dropdown-item" data-img-value="jp" data-value="Japanese" href="javascript:void(0);">
                        <img src="assets/img/jp.png" class="flag-width" alt="flag"> Japanese</a>
                    <a class="dropdown-item" data-img-value="fr" data-value="French" href="javascript:void(0);">
                        <img src="assets/img/fr.png" class="flag-width" alt="flag"> French</a>
                    <a class="dropdown-item" data-img-value="usa" data-value="English" href="javascript:void(0);">
                        <img src="assets/img/usa.png" class="flag-width" alt="flag"> English</a>
                </div> -->
            </div>
        </li>

        <!-- Payroll Time Clocks -->
        <li class="nav-item dropdown payroll-time-clock" *ngIf="this.panelType == 'COMPANY' && JSON.stringify(clockEmployees).includes(employee.id)">
            <a href="javascript:void(0);" class="nav-link" (click)="modalPayrollTimeClocks()" [ngClass]="clockIn ? 'check-in-icon' : 'check-out-icon'">
                <i class="fa-solid fa-fingerprint"></i>
            </a>
        </li>

        <li class="nav-item dropdown message-dropdown">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="messageDropdown" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-message-circle">
                    <path
                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                    </path>
                </svg><span class="badge badge-primary">{{message.length}}</span>
            </a>
            <div *ngIf="message != []"  class="dropdown-menu dropdown-menu-right" aria-labelledby="customDropdown">
              <a class="sms dropdown-item" data-img-value="de" data-value="message" href="javascript:void(0);" *ngFor="let messaje of message"  (click)="openCompanyDialer();readConversation(messaje)">
               <span>Phone: {{messaje.from}}</span> <br> <span> Message : {{messaje.message}}</span> </a>

          </div>
        </li>

        <li class="nav-item dropdown notification-dropdown">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="notificationDropdown"
                (click)="readNotifications()"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg *ngIf="!newNotification" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-bell">
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                </svg>
                <svg *ngIf="newNotification" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 19 19">
                    <g class="too-big-actually">
                        <g class="bell-whole">
                            <path class="bell-part bell-part--ringer" d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"/>
                            <path class="bell-part bell-part--main"d="M13.73 21a2 2 0 0 1-3.46 0"/>
                        </g>
                    </g>
                </svg>
                <span class="notification-bell" *ngIf="companyNotifications.length > 0">{{companyNotifications.length}}</span>
            </a>

            <div class="dropdown-menu position-absolute dropdown-menu-notification-follow-up" aria-labelledby="notificationDropdown" *ngIf="companyNotifications.length > 0">
                <div class="container-notification-follow-up">
                    <div *ngFor="let notification of companyNotifications">
                        <div class="dropdown-item" *ngIf="panelType == 'COMPANY'">
                            <a class="notification-follow-up">
                                <div class="noti-header">
                                    <i class="fa-solid fa-calendar-day mr-2"></i>
                                    <h3>
                                        {{notification.type | filterReplace}}
                                        <span class="new" *ngIf="notification.status == 'NEW'"> NEW!</span>
                                    </h3>
                                </div>
                                <div class="noti-body">
                                    <div class="noti-row">
                                        <span class="key">
                                            Job:
                                        </span>
                                        <span class="value">
                                            {{notification.job_code}}
                                        </span>
                                    </div>
                                    <div class="noti-row">
                                        <span class="key">
                                            Customer:
                                        </span>
                                        <span class="value">
                                            {{notification.customer_name}} {{notification.customer_last_name}}
                                        </span>
                                    </div>
                                    <div class="noti-row">
                                        <span class="key">
                                            Move:
                                        </span>
                                        <span class="value move">
                                            <i class="fa-solid fa-location-dot mr-1 pickup"></i>
                                            <span>{{notification.pickup_state}}</span>
                                            <i class="fa-solid fa-location-dot ml-3 mr-1 delivery"></i>
                                            <span>{{notification.delivery_state}}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="noti-btns">
                                    <button class="btn btn-success" (click)="goToEstimate(notification.id, notification.job_id)">Go</button>
                                    <button class="btn btn-danger" (click)="clearNotification(notification.id)">Clear</button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                </svg>
            </a>
            <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">

                <div>
                    <div class="dropdown-item" *ngIf="panelType == 'COMPANY' && authService.session.workspaces.length > 0 && authService.workspaceSession.employee_workspace_permissions?.role?.capabilities?.length > 0">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fa-brands fa-hubspot"></i>
                            Workspace Manager
                        </a>
                    </div>
                </div>
                <div>
                    <div class="dropdown-item" *ngIf="panelType == 'WORKSPACE'">
                        <a (click)="opencompanyInformation()" class="cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-cpu">
                                <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                                <rect x="9" y="9" width="6" height="6"></rect>
                                <line x1="9" y1="1" x2="9" y2="4"></line>
                                <line x1="15" y1="1" x2="15" y2="4"></line>
                                <line x1="9" y1="20" x2="9" y2="23"></line>
                                <line x1="15" y1="20" x2="15" y2="23"></line>
                                <line x1="20" y1="9" x2="23" y2="9"></line>
                                <line x1="20" y1="14" x2="23" y2="14"></line>
                                <line x1="1" y1="9" x2="4" y2="9"></line>
                                <line x1="1" y1="14" x2="4" y2="14"></line>
                            </svg>
                            Workspace Information
                        </a>
                    </div>
                </div>
                <div>
                    <div class="dropdown-item" *ngIf="panelType == 'WORKSPACE'">
                        <a class="cursor-pointer" routerLink="/my-profile/workspaces/{{workspace?.id}}/billing">
                            <i class="fa-solid fa-dollar-sign"></i>
                            Workspace Billing
                        </a>
                    </div>
                </div>

                <div>
                    <hr class="dropdown-divider">
                </div>


                <div>
                    <div class="dropdown-item">
                        <a routerLink="/my-profile/update-profile">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            Update Profile
                        </a>
                    </div>
                </div>
                <div>
                    <div class="dropdown-item">
                        <a href="/#/my-profile/workspaces">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-share-2">
                                <circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle>
                                <circle cx="18" cy="19" r="3"></circle>
                                <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                                <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                            </svg>
                            My Workspaces
                        </a>
                    </div>
                </div>
                <div>
                    <div class="dropdown-item">
                        <a class="cursor-pointer" (click)="logout()">
                            <i class="fa-solid fa-door-open"></i>
                            Log Out
                        </a>
                    </div>
                </div>

            </div>
        </li>

    </ul>


    <div class="incoming-call-pop-up" id="incoming_call_pop-up">
        <!-- <button class="close-incoming-call-modal" (click)="toggleModalIncomingCall('')">
            <i class="fa-solid fa-xmark"></i>
        </button> -->
        <div class="row d-flex align-items-center" (click)="openCompanyDialer()">
            <div class="col-3">
                <div class="avatar">
                    <i class="fa-solid fa-user"></i>
                </div>
            </div>
            <div class="col-6 d-flex p-0">
                <div class="incoming-call-info">
                    <div class="incoming-call-txt">
                        {{name}}
                    </div>
                    <div class="incoming-call-number">
                        {{phoneNumber}}
                    </div>
                    <div class="incoming-call-loading">
                        Incoming Call
                        <div class="loading">
                            <span class="loading__dot"></span>
                            <span class="loading__dot"></span>
                            <span class="loading__dot"></span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-3">
                <div class="d-flex justify-content-between">
                    <button class="btn btn-accept-call">
                        <i class="fa-solid fa-phone"></i>
                    </button>
                     <button class="btn btn-decline-call">
                        <i class="fa-solid fa-phone-slash"></i>
                    </button>
                </div>
            </div> -->

            <div class="col-3">
                <div class="arrow">
                    <i class="fa-solid fa-arrow-right"></i>
                </div>
            </div>

        </div>
    </div>
</header>


<!-- Modal for show the basic information about the workspace -->
<div class="modal fade" id="companyInformation" #companyInformation tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document"
        style="max-width: 660px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Workspace Information
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content" id="addListsModalTitle">
                        <div class="row">

                            <div class="col-12">

                                <div class="container-workspace pb-3">
                                    <label for="company">My Workspace</label>
                                    <div class="d-flex">
                                        <div class="workspace-id">
                                            <div class="container-title" *ngIf="workspace != null">
                                                {{workspace.name}}
                                            </div>
                                        </div>
                                        <i class="fas fa-chevron-right"></i>
                                        <div class="workspace">
                                            <div>
                                                <i class="fas fa-globe-asia"></i>
                                                <span>ID My Workspace</span>
                                            </div>
                                            <div *ngIf="workspace != null">
                                                {{workspace.id}}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="container-company">
                                    <label for="company">Company</label>
                                    <div class="d-flex company-selector-container">
                                        <div class="company-id">
                                            <select name="company" id="company" [(ngModel)]="selectedCompany"
                                                class="form-control">
                                                <option value="No Company Selected" selected disabled hidden>
                                                    Choose a Company
                                                </option>
                                                <option *ngFor="let company of companies" value="{{company.id}}">
                                                    {{company.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <i class="fas fa-chevron-right"></i>
                                        <div class="company">
                                            <div>
                                                <i class="fas fa-building"></i>
                                                <span>ID Selected Company</span>
                                            </div>
                                            <div>{{selectedCompany}}</div>
                                        </div>
                                    </div>
                                    <small>Choose a Company</small><br><br>
                                </div>

                                <div class="container-company">
                                    <label><i class="far fa-copy copy-text-action" (click)="copyText('/#/public-load-board/', 'text');$event.stopPropagation()"></i>
                                        Public Board: <a class="font-public-board" href="{{urlCarrier}}/#/public-load-board/{{workspace?.id}}" target="_blank"> {{urlCarrier}}/public-load-board/{{workspace?.id}}</a>
                                    </label><br><br>
                                </div>

                                <div class="container-company">
                                    <label> <i class="far fa-copy copy-text-action" (click)="copyText('/#/vip-load-board/', 'text');$event.stopPropagation()"></i>
                                        Private Board: <a class="font-public-board" href="{{urlCarrier}}/#/vip-load-board/{{workspace?.id}}" target="_blank" >{{urlCarrier}}/vip-load-board/{{workspace?.id}}</a></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closecompanyInformation()" class="btn btn-cancel" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal for show the basic information about the workspace -->
<div class="modal fade" id="payrollTimeClocks" #payrollTimeClocks tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="close-modal" (click)="closePayrollTimeClocks()">
        <i class="fa-solid fa-xmark"></i>
    </div>
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="max-width: 660px;">
        <div class="modal-content">
            <div class="modal-header">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                <h5 class="modal-title" id="newConceptTitle">
                    Payroll Time Clock
                </h5>
            </div>
            <div class="modal-body">
                <div class="real-time-clock">
                    {{PayrollClock}}
                </div>
                <div class="current-date">
                    <span>{{currentDate | date}}</span>
                </div>
                <div class="payroll-clock-info">
                    <div class="employee">
                        <i class="fa-solid fa-user"></i>
                        <div class="key">
                            <span>Employee</span>
                            <span>:</span>
                        </div>
                        <span>{{employee.name | titlecase}}</span>
                    </div>
                    <div class="job-position">
                        <i class="fa-solid fa-briefcase"></i>
                        <div class="key">
                            <span>Job Position</span>
                            <span>:</span>
                        </div>
                        <span>{{employee.job_position | filterReplace}}</span>
                    </div>

                    <div class="check-status">
                        <div class="icon-clock">
                            <div class="clock"></div>
                        </div>
                        <div class="key">
                            <span>Status</span>
                            <span>:</span>
                        </div>
                        <span class="badge badge-success" *ngIf="clockIn">Check In</span>
                        <span class="badge badge-danger" *ngIf="!clockIn">Check Out</span>
                    </div>
                    <div class="check-in-time">
                        <div class="icon-clock">
                            <div class="clock"></div>
                        </div>
                        <div class="key">
                            <span>Check In Time</span>
                            <span>:</span>
                        </div>
                        <span *ngIf="timeClockEmployee.time_in">{{timeClockEmployee.time_in}}</span>
                        <span *ngIf="!timeClockEmployee.time_in">-- : --</span>
                    </div>
                    <div class="check-out-time">
                        <div class="icon-clock">
                            <div class="clock"></div>
                        </div>
                        <div class="key">
                            <span>Check Out Time</span>
                            <span>:</span>
                        </div>
                        <span *ngIf="timeClockEmployee.time_out">{{timeClockEmployee.time_out}}</span>
                        <span *ngIf="!timeClockEmployee.time_out">-- : --</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" [disabled]="clockIn" (click)="clock()">
                    Clock In
                </button>
                <button class="btn btn-danger" [disabled]="!clockIn" (click)="clock()">
                    Clock Out
                </button>
            </div>
        </div>
    </div>
    <div class="copyright">
        <span>MoverXpro 360 LLC</span>
    </div>
</div>
