import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { InventoryCategory } from 'src/app/entities/workspace/inventory-category';

@Injectable({
    providedIn: 'root'
})
export class InventoryCategoriesService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<InventoryCategory>> {
        return this.http.get<Array<InventoryCategory>>(environment.api.workspace + '/inventory-categories').toPromise();
    }

    getAllNotVoid(id): Promise<Array<InventoryCategory>> {
        return this.http.get<Array<InventoryCategory>>(environment.api.workspace + '/inventory-categories', { params: { 'not-void': '1', 'job-id': id } }).toPromise();
    }

    getById(id: string) {
        return this.http.get<InventoryCategory>(environment.api.workspace + '/inventory-categories/' + id).toPromise();
    }

    save(dataForm: InventoryCategory): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: InventoryCategory) {
        return this.http.post(environment.api.workspace + '/inventory-categories', dataForm).toPromise();
    }

    edit(dataForm: InventoryCategory) {
        return this.http.put(environment.api.workspace + '/inventory-categories/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/inventory-categories/' + id).toPromise();
    }
}
