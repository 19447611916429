import { Component, OnInit } from '@angular/core';
import { PhoneCall } from 'src/app/entities/companies/phone-call';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { AuthService } from 'src/app/services/auth.service';
import { PhoneCallsService } from 'src/app/services/companies/phone-calls.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phone-cdr',
  templateUrl: './phone-cdr.component.html',
  styleUrls: ['./phone-cdr.component.scss']
})
export class PhoneCdrComponent implements OnInit {

  readonly environment = environment;
  public rows: Array<PhoneCall>
  public dataForm: PhoneCall | any;
  public paginator: Paginator;
  public filterFor: 'All' | 'Outgoing' | 'Incoming' | 'Missed';
  public filterSearch: string;
  public phone: string;
  public betweenDatesStart: any;
  public betweenDatesEnd: any;
  public data:any;
  public nums: PhoneLine[];
  public targets: any[];
  public employees:EmployeeView[];



  constructor(
    private helperService: HelperService,
    private phoneCallService: PhoneCallsService,
    public authServices: AuthService,
    public phoneLineService: PhoneLinesService,
    private companiesService: CompaniesService,
    private employeesService: EmployeesService,
  ) {
    this.paginator = new Paginator();
    this.filterFor = 'All';
    this.filterSearch = '';
    this.phone = '';
    this.nums = [];
    }

  ngOnInit(): void {

  this.getAllLines();

  }

  public getFilter() {
    this.helperService.showLoadingMxpro360();
    this.phoneCallService
      .getAllTotalWorkspace(this.phone,  this.betweenDatesStart, this.betweenDatesEnd)
      .then((response) => {
        this.data = response;
        this.data.sort((a, b) => b.created - a.created);
        console.log('this.data!!',this.data);


      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  };


  getFilterDates(date: any): void {

      this.betweenDatesStart = date.dateStart;
      this.betweenDatesEnd = date.dateEnd;
}

public getAllLines(): void {
  this.helperService.showLoadingMxpro360();
  this.phoneCallService.getPhoneAll().then((response) => {
    this.nums = response;

    console.log('desde cdr this.nums',this.nums);

    this.changeTarget();

  }
  ).finally(() => {
    this.helperService.hideLoadingMxpro360();
  });
};

changeTarget() {

    this.targets = this.nums.filter(item => item.destination.target != 'RING_GROUP' && item.destination.target != 'QUEUE' && item.destination.target != 'IVR');
    console.log('aqui tarjets',this.targets);

  }

  private getAllEmployees(): void {
    this.helperService.showLoadingMxpro360();
    this.employeesService.getAll().then((response) => {
      this.employees = response;
      console.log('employes :',this.employees);

    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };



}
