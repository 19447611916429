import { DateText } from "src/app/entities/global/date-text";

export class Period {
    id: string;
    tariff_version_id: string;
    name: string;
    from: DateText;
    to: DateText;
    created: number;

    constructor() {
        this.id = null;
        this.tariff_version_id = null;
        this.name = null;
        this.from = new DateText();
        this.to = new DateText();
        this.created = null;
    }
}
