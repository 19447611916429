import { AuthService } from 'src/app/services/auth.service';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { JobsService } from 'src/app/services/workspaces/jobs.service';
import { JobView } from 'src/app/entities/workspace/job-view';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
declare const jQuery: any;
declare const moment: any;

@Component({
  selector: 'app-pickup-documents-report',
  templateUrl: './pickup-documents-report.component.html',
  styleUrls: ['./pickup-documents-report.component.scss']
})
export class PickupDocumentsReportComponent implements OnInit {

  public sort: number;
  public actualPage = 1;
  public filterSearch = '';
  public rows: JobView[];
  public paginator: Paginator;
  public betweenDates: BetweenDates;

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private helperService: HelperService,
    private jobsService: JobsService
  ) {
    this.paginator = new Paginator();
    this.betweenDates = new BetweenDates();
    this.betweenDates.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;;
    this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    this.sort = null;
    this.rows = [];
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getPickupReport(this.paginator, "$ne")
      .then((response: any) => {
        this.rows = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  search(): void {

    if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
      this.betweenDates.dateStart = 1609459200;
      this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }

    // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
    this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

    this.paginator.paginator.start =  this.betweenDates.dateStart;
    this.paginator.paginator.end =  this.betweenDates.dateEnd;
    // const data: any = {
    //   start: this.betweenDates.dateStart,
    //   end: this.betweenDates.dateEnd
    // };

    this.helperService.showLoadingMxpro360();
    this.jobsService
      .searchPickudDocuments(this.paginator, this.betweenDates, '', "$ne")
      .then((response) => {
        this.rows = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  getFilterDates(date: BetweenDates): void {
    this.betweenDates = date;
  }

  sortBy() {
    switch (Number(this.sort)) {
      case 1:
        this.paginator.paginator.order_by = 'estimate.document.code';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 2:
        this.paginator.paginator.order_by = 'estimate.document.code';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 3:
        this.paginator.paginator.order_by = 'customer.name';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 4:
        this.paginator.paginator.order_by = 'customer.name';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 5:
        this.paginator.paginator.order_by = 'estimate.pickup.range_start';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 6:
        this.paginator.paginator.order_by = 'estimate.pickup.range_start';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 7:
        // this.paginator.paginator.order_by = 'estimate.booked_date';
        this.paginator.paginator.order_by = 'created';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 8:
        // this.paginator.paginator.order_by = 'estimate.booked_date';
        this.paginator.paginator.order_by = 'created';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 9:
        this.paginator.paginator.order_by = 'estimate.delivery.address.state';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 10:
        this.paginator.paginator.order_by = 'estimate.delivery.address.state';
        this.paginator.paginator.order_type = 'DESC';
        break;
    
      default:
        break;
    }
    this.load();
  }

  /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
  openNewTab(estimateId) {
    // Redirige a la ventana de estmate
    this.companiesService.getCompanyByJobId(estimateId)
    .then((company) => {

      this.authService.setCompanySession(company);
      this.helperService.openNewTab(estimateId, 'operations');
    });
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.load();
    }
  }

}
