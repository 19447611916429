import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFromDecimal'
})

export class TimeFromDecimalPipe implements PipeTransform {

  transform(hours: number): string {
    if(hours == null){
      return null;
    }
    
    const hour = Math.floor(hours / 1);
    const minute = Math.round((hours - hour) * 60);

    const hh = hour.toString().padStart(2, '0');
    const mm = minute.toString().padEnd(2, '0');

    return `${hh}:${mm}`;

  }

}
