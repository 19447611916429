export class LiabilityDeductibleRate {
  public id: string;
  public liability_amount_id: string;
  public created: number;
  public updated: number;

  constructor() {
      this.id = null;
      this.liability_amount_id = null;
      this.created = null;
      this.updated = null;

  }
}
