import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Vehicle } from 'src/app/entities/workspace/vehicle';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {

    constructor(private http: HttpClient) {

    }
  
    getAll(): Promise<Array<Vehicle>> {
        return this.http.get<Array<Vehicle>>(environment.api.workspace + '/vehicles').toPromise();
    }
  
    getById(id: string) {
        return this.http.get<Vehicle>(environment.api.workspace + '/vehicles/' + id).toPromise();
    }
  
    save(dataForm: Vehicle): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }
  
    add(dataForm: Vehicle) {
        return this.http.post(environment.api.workspace + '/vehicles', dataForm).toPromise();
    }
  
    edit(dataForm: Vehicle) {
        return this.http.put(environment.api.workspace + '/vehicles/' + dataForm.id, dataForm).toPromise();
    }
  
    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/vehicles/' + id).toPromise();
    }
}
