import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss']
})
export class CustomPaginatorComponent implements OnInit {

  @Input() currentPage: number;
  @Input() rowsQuantity: number;
  @Input() itemsPerPage: number;
  @Output() pageChange: EventEmitter<any>;

  public buttonsPages: number[];
  private buttonQty: number;


  constructor() {
    this.pageChange = new EventEmitter();
    this.buttonsPages = [];
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.validateButtonsQty();
  }

  validateButtonsQty() {
    // obtiene la cantidad entera de botones (int) ej 3.89 = 3
    this.buttonQty = Math.trunc(this.rowsQuantity / this.itemsPerPage);
    // si hay un decimal entonces se suma un boton para mostrar los items restantes
    if ((this.buttonQty * this.itemsPerPage) < this.rowsQuantity) {
      this.buttonQty += 1;
    }
  
    this.setButtonsQty();
  }

  setButtonsQty() {
    this.buttonsPages = [];
    // primer boton
    if ((this.currentPage - 2) > 0) {
      this.buttonsPages.push(this.currentPage - 2);
    }
    // segundo boton
    if ((this.currentPage - 1) > 0) {
      this.buttonsPages.push(this.currentPage - 1);
    }
    // boton central
    this.buttonsPages.push(this.currentPage);
    // cuarto boton
    if ((this.currentPage + 1) <= this.buttonQty) {
      this.buttonsPages.push(this.currentPage + 1);
    }
    // quinto boton
    if ((this.currentPage + 2) <= this.buttonQty) {
      this.buttonsPages.push(this.currentPage + 2);
    }

  }

  goPrevious() {
    this.currentPage = Number(this.currentPage) - 1;
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }
    this.pageChange.emit(this.currentPage);
  }

  goNext() {
    this.currentPage = Number(this.currentPage) + 1;
    if (this.currentPage > this.buttonQty) {
      this.currentPage = this.buttonQty;
    }
    this.pageChange.emit(this.currentPage);
  }
  
  goPage(page: number): void {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }

}
