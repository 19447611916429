import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'src/app/entities/workspace/company';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {

    constructor(private http: HttpClient) {

    }

    /**
     * permite obtener todas las compañias del workspace, si el primer argumento se pasa en true,
     * solo devuelve las compañias en las que el usuario(empleeado) tiene acceso
     * @param validateAccessEmployee 
     * @returns 
     */
    getAll(validateAccessEmployee: boolean = false): Promise<Array<any>> {
        if (validateAccessEmployee) {
            return this.http.get<Array<any>>(environment.api.workspace + '/companies?validateAccessEmployee=1').toPromise();
        }
        return this.http.get<Array<any>>(environment.api.workspace + '/companies').toPromise();
    }

    add(data: Company): Promise<Array<any>> {
        return this.http.post<Array<any>>(environment.api.workspace + '/companies', data).toPromise();
    }

    disabled(id: string) {
        return this.http.delete(environment.api.workspace + '/companies/' + id).toPromise();
    }

    getActiveCompaniesToCover() {
        return this.http.get(environment.api.workspace + '/active-companies').toPromise();
    }

    getCompanyByJobId(estimateId: string) {
        return this.http.get<Company>(environment.api.workspace + '/companies-by-estimate/' + estimateId).toPromise();
    }

}
