
<div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
    <div class="widget widget-table-two" *ngFor="let jobCompletedInTheLast7Days of jobsCompletedInTheLast7Days">

        <div class="widget-heading">
            <h5 class="text-warning">Jobs completed in the last 7 days {{jobCompletedInTheLast7Days.work_department}}</h5>
        </div>

        <div class="widget-content">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="bg-warning"><div class="th-content text-light">Job</div></th>
                            <th class="bg-warning"><div class="th-content text-light">Employee</div></th>
                            <th class="bg-warning"><div class="th-content text-light">Date</div></th>
                            <!-- <th class="bg-warning"><div class="th-content text-light">Options</div></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let job of jobCompletedInTheLast7Days.jobs">
                            <td><div class="td-content customer-name">{{job.job}}</div></td>
                            <td><div class="td-content product-brand">{{job.employee}}</div></td>
                            <td><div class="td-content">{{job.date | dateTime}}</div></td>
                            <!-- <td><div class="td-content">Go To</div></td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
