import { BalancesService } from 'src/app/services/companies/balances.service';
import { PaymentsService } from 'src/app/services/companies/payments.service';
import { Component, OnInit } from '@angular/core';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { HelperService } from 'src/app/services/helper.service';
import { PaymentMethod } from 'src/app/entities/workspace/payment-method';
import { PaymentsMethodsService } from 'src/app/services/companies/payments-methods.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

    balances: Array<BalanceView>;
    copyBalances: Array<BalanceView>;
    actualPage: number;
    filterSearch = '';
    checkPending: boolean;
    checkPaid: boolean;
    checkPendingReview: boolean;
    checkProcessing: boolean;
    concept: string;
    date_start: string;
    date_end: string;
    // datos para paginador
    public paginator: Paginator;
    public paymentMethods: PaymentMethod[];
    public payment_method: string;

    constructor(
        private paymentsService: PaymentsService,
        private balancesService: BalancesService,
        private helperService: HelperService,
        private paymentsMethodsService: PaymentsMethodsService
    ) {
        this.actualPage = 1;
        this.balances = [];
        this.copyBalances = [];
        this.checkPaid = true;
        this.checkPending = true;
        this.checkPendingReview = true;
        this.checkProcessing = true;
        this.concept = '';
        this.date_start = '';
        this.date_end = '';
        this.paginator = new Paginator();
        this.paymentMethods = [];
        this.payment_method = '';
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void{
        this.load();
        this.loadPaymentsMethods();
    }

    load() {
        this.helperService.showLoadingMxpro360();
        this.balancesService
            .getAllApp(
                this.checkPending,
                this.checkPaid,
                this.checkPendingReview,
                this.checkProcessing,
                this.concept,
                this.payment_method,
                this.date_start,
                this.date_end,
                this.paginator
            )
            .then((response) => {
                this.paginator.paginator.total = response.paginator.total;
                this.balances = response.data;
                this.copyBalances = response.data;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.paymentsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    setCurrentPage(currentpage: number): void {
        if (this.paginator.paginator.page != currentpage) {
            this.paginator.paginator.page = currentpage;
            this.load();
        }
    }

    setDates(dates){
        this.date_start = dates[0];
        this.date_end = dates[1];
        if(dates[1]){
            this.load();
        }
    }

    private loadPaymentsMethods(): void {
        this.paymentsMethodsService
          .getAll()
          .then((response) => {
            this.paymentMethods = response;
          })
          .catch((error) => {
            console.error('Error: ', error);
          })
      }
}
