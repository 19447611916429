export class JobAppoinment {
    public id: string;
    public date: number;
    public date_text: string;
    public range_start: string;
    public range_end: string;
    public confirm: boolean;
    public dispatched: boolean;
    public pay_hours: number;
    public remarks: string;
    public workers_id: Array<string>;
    public travel_days: number;
    public total_cf: number;
    public total_lbs: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.date = null;
        this.date_text = null;
        this.range_start = null;
        this.range_end = null;
        this.confirm = null;
        this.dispatched = null;
        this.pay_hours = null;
        this.remarks = null;
        this.workers_id = [];
        this.travel_days = null;
        this.total_cf = null;
        this.total_lbs = null;
    }
}
