export class EmailPerson {
    id: string;
    name: string;
    email: string;

    constructor() {
        this.id = null;
        this.name = null;
        this.email = null;
    }
}
