import { File } from '../global/file';
import { DocumentEsignature } from './document-esignature';
export class Document {
    id: string;
    prefix: string;
    consecutive: string;
    code: string;
    usage_context: 'AUTO_TRANSPORT' | 'BILL_OF_LADING' | 'CARRIER_CONTRACT' | 'CARRIER_INTERLINE' | 'CARRIER_JOB_ACCEPTANCE' | 'CREDIT_CARD_AUTHORIZATION_CARRIER' | 'CREDIT_CARD_AUTHORIZATION' | 'CUSTOMER_JOB_CONTRACT' | 'DRIVER_INVOICES' | 'ESTIMATES' | 'NEW_BINDING_ESTIMATE' | 'FREIGHT_ESTIMATE' | 'FREIGHT_RATE_CONFIRMATION_SHEET' | 'ONLINE_PAYMENTS_VOUCHER' | 'SPECIAL_POWER_ATTORNEY' | 'STORAGE_INVOICE' | 'TICKETS' | 'PPW_PACKET';
    document_type_id: string;
    is_amended_bol: boolean;
    text: string; // pre_pdf
    file: File; // pdf_file
    esignatures: DocumentEsignature;

    constructor(){
        this.id = null;
        this.prefix = null;
        this.consecutive = null;
        this.code = null;
        this.usage_context = null;
        this.document_type_id = null;
        this.is_amended_bol = false;
        this.text = null;
        this.file = new File();
        this.esignatures = new DocumentEsignature();
    }
}
