<div class="row layout-top-spacing" id="cancel-row">
    <div class="col-xl-12 col-lg-12 col-md-12" [hidden]="hiddenCalendar">
        
        <div class="row mb-3">

            <div class="col-4 align-self-center layout-spacing">
                <h3>
                    <span>Freight Calendar</span>
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard">
                                <i class="fas fa-home"></i> Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Freight Calendar</li>
                    </ol>
                </nav>
            </div>

            <div class="col-4 align-self-center zip-code-section">
                <div class="zip-code-distance">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                    <input type="text" [(ngModel)]="zipCodePoint" (ngModelChange)="getDistances()" class="form-control" placeholder="Zip Code To Get Distance"/>
                </div>
                <div class="info-icon">
                    <div class="d-flex justify-content-center">
                        <i class="fas fa-calendar-check mr-3 tags calendar" titles="Moving date confirmed"></i>
                        <i class="fas fa-file-signature mr-3 tags signature" titles="Customer`s electronic signature accepted "></i>
                        <i class="fas fa-credit-card mr-3 tags payment" titles="Customer`s first payment accepted "></i>
                        <i class="fas fa-car mr-3 tags car" titles="Vehicle including on the estimate"></i>
                        <!-- <i class="fas fa-plane mr-3 tags interstate" titles="Interstate move"></i>
                        <i class="fas fa-truck mr-3 tags carrier" titles="Job took by a carrier"></i> -->
                    </div>
                </div>
            </div>
    
            <div class="col-4 text-sm-right text-center align-self-center layout-spacing pl-0">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                        <i class="fa-solid fa-filter mr-2"></i>
                        <span>Hide Filters</span> 
                    </a>
                    <button class="btn btn btn-info full-screen-info" id="buttonFull" title="Full Screen Calendar">
                        <i class="fas fa-expand"></i>
                    </button>
                </div>
            </div>

        </div>
        <!-- header -->

        <!-- filter -->
        <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">

            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-12 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Carrier</label>
                    <select name="carrierFilter_id" [(ngModel)]="carrierFilter" id="carrierFilter_id" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                        <option value="">All</option>
                        <option value="IN_HOUSE">In-House</option>
                        <option value="OUT_SOURCED">Out-Sourced</option>
                        <ng-container *ngFor="let cr of carriers">
                            <option value="{{cr.id}}">
                                {{cr.name | lowercase}}
                            </option>
                        </ng-container>
                    </select>
                </div>                  
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Salesman</label>
                    <select name="operator_user_id" [(ngModel)]="userId" id="operator_user_id" class="form-control"  [appSelect2] [theme]="'mxp360-form'">
                        <option value="">All</option>
                        <ng-container *ngFor="let user of employees">
                            <option value="{{user.id}}">
                                {{user.nickname | filterReplace}}
                            </option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Status Operation</label>
                    <select name="state" id="state" [(ngModel)]="state" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                        <option value="">All</option>
                        <option value="NEW">New</option>
                        <option value="DELIVERED_BOXES">Delivered Boxes</option>
                        <option value="PACKED_MOVE">Packed Move</option>
                        <option value="PICKED_UP">Picked Up</option>
                        <option value="IN_STORAGE">In Storage</option>
                        <option value="IN_TRANSIT">In Transit</option>
                        <option value="DELIVERED">Delivered</option>
                        <option value="CLOSING_REVIEW">Closing Review</option>
                    </select>
                </div>                
            </div>

            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <!-- <label>Pickup States</label> -->
                    <label>States</label>
                    <select name="pickup_states" id="pickup_states" [(ngModel)]="stateSelected" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                        <option value="">All</option>
                        <option [value]="state.code" *ngFor="let state of zonesStates">{{ state.name }} {{ state.code }}</option>
                    </select>
                </div>
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Delivery States</label>
                    <select name="delivery_states" id="delivery_states" [(ngModel)]="stateSelected" class="form-control" [appSelect2] [theme]="'mxp360-form'" disabled>
                        <option value="">All</option>
                        <option [value]="state.code" *ngFor="let state of zonesStates">{{ state.name }} {{ state.code }}</option>
                    </select>
                </div>
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Job Status</label>
                    <select name="statuses" id="statuses" [(ngModel)]="jobStatus" (change)="initializeCalendar()" class="form-control">
                        <option value="">Show All Jobs</option>
                        <option value="DISPLAY_CONFIRMED">Display Confirmed</option>
                        <option value="DISPLAY_ASSIGNED_DATE_NOT_CONFIRM">Display Assigned to Date Not Confirm</option>
                        <option value="DISPLAY_UNASSIGNED_JOBS">Display Unassigned Jobs</option>                                
                    </select>
                </div>
            </div>

            <div class="row col-lg-12 col-md-12 block-filter mb-3">
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">

                </div>
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">

                </div>        
                <div class="col-12 col-lg-4 col-xl-3 pl-0 mt-3 text-right">
                    <button class="btn btn-danger btn-search" (click)="resetSelect2()">
                        Reset
                    </button>
                    <button class="btn btn-secondary btn-search" (click)="getFilter()">
                        Filter
                    </button>
                </div>
            </div>

        </div>

        <div class="widget-content widget-content-area calendar-mt-36">
            <div class="calendar-upper-section" id="status_operation_filter" [ngClass]="{'fixed': isFixed}">
                <div class="row">
                    
                    <div class="col-12 checkbox-section">                   
                        <div class="row labels n-chk d-flex justify-content-center">
                            
                            <label class="new-control new-checkbox checkbox-primary checkbox-boxes-delivery" >
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkBoxesDelivery" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Boxes Delivery</span>
                                <div class="total-jobs total-boxes-delivery" *ngIf="fullCalendarData">
                                    {{fullCalendarData['boxes_delivery_day'].length}}
                                </div>
                            </label>

                            <label class="new-control new-checkbox checkbox-warning checkbox-pack-day">
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkPackDays" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Pack Days</span>
                                <div class="total-jobs total-pack-days" *ngIf="fullCalendarData">
                                    {{fullCalendarData['packing_day'].length}}
                                </div>
                            </label>

                            <label class="new-control new-checkbox checkbox-success checkbox-pick-up">
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkPickUps" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Pick Ups</span>
                                <div class="total-jobs total-pickup" *ngIf="fullCalendarData">
                                    {{fullCalendarData['pickups'].length}}
                                </div>
                            </label>

                            <label class="new-control new-checkbox checkbox-danger checkbox-delivery">
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkDelivery" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Delivery</span>
                                <div class="total-jobs total-delivery" *ngIf="fullCalendarData">
                                    {{fullCalendarData['deliveries'].length}}
                                </div>
                            </label>
                            
                            <label class="new-control new-checkbox indicator-box-crew checkbox-crew">
                                <div class="row">                    
                                    <div class="indicator-box crew-color"></div>
                                    <span>Crew</span> 
                                </div>
                            </label>

                            <label class="new-control new-checkbox checkbox-classic-mode checkbox-pick-up" *ngIf="jobStatus == ''">
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkClassicMode" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Classic Mode</span>
                            </label>
                            
                        </div>                     
                    </div>

                </div>
            </div>

            <div [hidden]="hiddenCalendar" #divCalendar id="calendar">
                <div class="press-esc">
                    Press ESC to go back
                </div> 
            </div>
        </div>
        
    </div>

    <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="!hiddenCalendar">

        <div class="row col-xl-4 col-lg-5 col-md-8 col-sm-12 align-self-center mb-3 ml-2">
            
            <div class="row col-12">
                <button class="btn btn-outline-success backDate m-0" (click)="backDate()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                </button> 
                <h3 class="m-1 mx-3">                 
                    {{calendarSelectedDay | date}}                  
                </h3>
                <button class="btn btn-outline-success nextDate m-0" (click)="nextDate()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </button>
            </div>

            <div class="row col-12 mt-2">
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item">
                            <a (click)="backCalendar()" style="cursor: pointer;">
                                <i class="far fa-calendar-alt"></i>
                                Calendar
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Day Details
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="row col-xl-5 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
    
            <div class="row mr-5" >                    
                <div class="indicator-box boxDelivery-color"></div>
                <span class="ml-2">Boxes Delivery</span> 
            </div>
            <div class="row mr-5">
                <div class="indicator-box packDays-color"></div>
                <span class="ml-2">Pack Days</span> 
            </div>
            <div class="row mr-5">
                <span class="indicator-box pickup-color"></span>
                <span class="ml-2">Pick Ups</span> 
            </div>
            <div class="row mr-5">
                <span class="indicator-box delivery-color"></span>
                <span class="ml-2">Delivery</span> 
            </div>
            
        </div>

        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <button class=" btn btn-danger back-calendar" (click)="backCalendar()">Go Back</button>
                <button class=" btn btn-success" (click)="openModalAddDailyCrew(true);"> Add Daily Crew </button>
            </div>
        </div>  
  
    </div>

    <div class="col-12 widget-content searchable-container list" [hidden]="!hiddenCalendar">

        <div class="searchable-items list">
            <div class="items items-header-section">
                <div class="item-content" style="position: initial!important;">
                    
                        <div class="">
                            <h4>
                                Job Details
                            </h4>
                        </div>
                        <div class="">
                            <h4>
                                Date
                            </h4>
                        </div>
                        <div class="">
                            <h4>
                                Service
                            </h4>
                        </div>

                        <div class="user-location">
                            <h4>
                                Move Details
                            </h4>
                        </div>
                        <div class="">
                            <h4>
                                Carrier/Agent
                            </h4>
                        </div>
                        <div class="user-location">
                            <h4 class="mr-5">
                                Option
                            </h4>
                        </div>

                        <div class="user-location">
                            <h4 class="mr-5">
                                Assign
                            </h4>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12" [hidden]="!hiddenCalendar">


        <!-- Sección de Crew -->
        <div class="content-priority" style="overflow: hidden!important;">
            <!-- Contenedor Crew -->
            <div  *ngFor="let jobsAssigned of dayCalendar['today_assigned']" class="layout-px-spacing" style="padding: 0px!important;">              
                <div class="row scrumboard" id="cancel-row">
                    <div class="col-lg-12">    
                        <div class="task-list-section overflow-unset"> 

                            <div data-section="s-new" class="col-12 task-list-container" data-connect="sorting">
                                <div class="connect-sorting">

                                    <!-- Nombre del Crew -->
                                    <div class="task-container-header">
                                        
                                        <div class="col-3 d-flex align-items-center">
                                            <h6 class="s-heading" data-listTitle="In Progress">{{jobsAssigned.name}}</h6>    
                                        </div>

                                        <div class="col-2 d-flex align-items-center font13">
                                            <span class="employees pr-1">Start Time</span>
                                            <ng-container class="row" *ngIf="jobsAssigned.start_time">
                                                <span>
                                                    <strong> {{jobsAssigned.start_time}}</strong>
                                                </span>   
                                            </ng-container> 
                                        </div>

                                        <div class="col-6 font13">
                                            <span class="employees">Foreman</span>
                                                <ng-container class="row" *ngFor="let assignedForeman of jobsAssigned.foreman">
                                                    <span>
                                                        {{assignedForeman.name | lowercase}}
                                                    </span>
                                                </ng-container>
                                            <br>
                                            <span class="employees">Drivers</span>
                                                <ng-container class="row" *ngFor="let assignedDrivers of jobsAssigned.drivers">
                                                    <span>
                                                        {{assignedDrivers.name | lowercase}}
                                                    </span>
                                                </ng-container>
                                            <br>
                                            <span class="employees">Workers</span>
                                                <ng-container class="row" *ngFor="let assignedWorkers of jobsAssigned.workers">
                                                    <span>
                                                        {{assignedWorkers.name | lowercase}}
                                                    </span>
                                                </ng-container>  
                                                <br>
                                            <span class="employees">Vehicle</span>
                                            <ng-container *ngFor="let crewVehicle of truncks">
                                                <span *ngIf="crewVehicle.id == jobsAssigned.vehicle_id">
                                                    {{crewVehicle.type}} - {{ crewVehicle.number}}
                                                </span>
                                            </ng-container> 
                                        </div>
                                        
                                        <div class="dropdown">
                                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-horizontal"><circle cx="12" cy="12" r="1"></circle><circle cx="19" cy="12" r="1"></circle><circle cx="5" cy="12" r="1"></circle></svg>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right dropdown-assign" aria-labelledby="dropdownMenuLink-1">
                                                <a class="dropdown-item list-edit" href="javascript:void(0);" (click)="openModalAddDailyCrew(false); crew = jobsAssigned">Edit</a>
                                                <a class="dropdown-item list-delete" href="javascript:void(0);" (click)="dailyCrewDelete(jobsAssigned.id)">Delete</a>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                
                                    <!-- Job dentro del Crew -->
                                    <div  *ngFor="let jobAssigned of jobsAssigned.jobs" class="row connect-sorting-content overflow-unset job-assigned-crew px-2" data-sortable="true">    
                                                                    
                                            <div data-draggable="true"
                                                [ngClass]="{'pickup-job': jobAssigned.crew_work_type == 'pickups', 'delivery-job': jobAssigned.crew_work_type == 'deliveries', 'boxDelivery-job': jobAssigned.crew_work_type == 'boxes_delivery_day', 'packDays-job': jobAssigned.crew_work_type == 'packing_day',
                                                'day-confirm': jobAssigned.box_delivery_day.confirm || jobAssigned.delivery_day.confirm || jobAssigned.pack_day.confirm || jobAssigned.pickup_day.confirm}"
                                                (click)="openNewTab(jobAssigned.estimate.id, jobAssigned.estimate.company_id)" class="col-12 items card simple-title-task" style="padding: 12px;">
                                                
                                                <div class="row card-body cursor-pointer item-content crew-job" style="align-items: center;">

                                                    <div class="col-1 user-profile">
                                                        <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                                            <p class="user-name"><strong>{{jobAssigned.estimate.document.code}}</strong></p>
                                                            <p class="user-work">{{jobAssigned.estimate.customer.name}}
                                                                {{jobAssigned.estimate.customer.last_name}}</p>
                                                            <p class="user-work">{{jobAssigned.estimate.customer.phone}}</p>
                                                        </div>
                                                    </div> 

                                                    <div class="col-2 user-profile">
                                                        <div class="user-meta-info">
                                                            <p class="user-profile mb-0">
                                                                <strong>Move Date: </strong>{{jobAssigned.pickup_day.date_text | dateTime}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>Start Date: </strong>{{jobAssigned.estimate.pickup.range_start | date}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>End Date: </strong>{{jobAssigned.estimate.pickup.range_end | date}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-2 user-profile">
                                                        <div class="user-meta-info">
                                                            <p class="usr-email-addr">
                                                                <strong>From: </strong>{{jobAssigned.estimate.pickup.address.state}}
                                                                {{jobAssigned.estimate.pickup.address.zip_code}}
                                                            </p>
                                                            <p class="usr-email-addr">
                                                                <strong>To: </strong>{{jobAssigned.estimate.delivery.address.state}}
                                                                {{jobAssigned.estimate.delivery.address.zip_code}}
                                                            </p>
                                                            <p class="usr-email-addr">
                                                                <strong>Service: </strong> {{jobAssigned.estimate.service}}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="col-2 user-email">
                                                        <p class="usr-location">
                                                            <strong *ngIf="jobAssigned.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                                            <strong *ngIf="jobAssigned.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                                            {{jobAssigned.estimate.volume.cubic_feets | number}}
                                                        </p>
                                                        <p class="usr-location">
                                                            <strong>Miles: </strong>{{jobAssigned.estimate.volume.miles | number}}
                                                        </p>                            
                                                        <p class="usr-ph-no">
                                                            <strong>Estimate: </strong> {{jobAssigned.estimate.total | currency}}
                                                        </p>                            
                                                    </div>  

                                                    <div class="col-2 user-profile" style="width: 150px;">
                                                        <div class="user-meta-info">
                                                            <p *ngIf="jobAssigned.job_outsourcing && jobAssigned.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{jobAssigned.carrier.name}}</span></p> 
                                                            <p *ngIf="jobAssigned.job_outsourcing && jobAssigned.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                                            <p *ngIf="jobAssigned.job_outsourcing && jobAssigned.job_outsourcing?.status== 'WORKING_ON' ">{{jobAssigned.carrier.name}}</p>
                                                        </div>
                                                    </div>  
                                                                                               
                                                    <div class="col-2 user-profile">
                                                        <div class="user-meta-info" *ngIf="jobAssigned.crew_work_type == 'pickups'">
                                                            <p class="user-work">
                                                                <strong>Start Time: </strong>{{jobAssigned.pickup_day.range_start}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>End Time: </strong>{{jobAssigned.pickup_day.range_end}}
                                                            </p>
                                                        </div>
                                                        <div class="user-meta-info" *ngIf="jobAssigned.crew_work_type == 'packing_day'">
                                                            <p class="user-work">
                                                                <strong>Start Time: </strong>{{jobAssigned.pack_day.range_start}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>End Time: </strong>{{jobAssigned.pack_day.range_end}}
                                                            </p>
                                                        </div>
                                                        <div class="user-meta-info" *ngIf="jobAssigned.crew_work_type == 'boxes_delivery_day'">
                                                            <p class="user-work">
                                                                <strong>Start Time: </strong>{{jobAssigned.box_delivery_day.range_start}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>End Time: </strong>{{jobAssigned.box_delivery_day.range_end}}
                                                            </p>
                                                        </div>
                                                        <div class="user-meta-info" *ngIf="jobAssigned.crew_work_type == 'deliveries'">
                                                            <p class="user-work">
                                                                <strong>Start Time: </strong>{{jobAssigned.box_delivery_day.range_start}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>End Time: </strong>{{jobAssigned.box_delivery_day.range_end}}
                                                            </p>
                                                        </div>
                                                      
                                                    </div> 
                                                    
                                                    
                                                    <div class="row col-12 mt-2">
                                                        <div class="row col-6 line-break-text font12 user-profile m-0 font12" *ngIf="jobAssigned.estimate.operation_details">                  
                                                            <div class="color-remark">
                                                                <strong>Direction / Operation Details:</strong>
                                                            </div>
                                                            <div class="ml-2">
                                                                <span>{{jobAssigned.estimate.operation_details}}</span> 
                                                            </div>        
                                                        </div>

                                                        <!-- pickups - remark -->
                                                        <div class="col-6 row line-break-text font12 user-profile m-0 font12" *ngIf="jobAssigned.crew_work_type == 'pickups' &&  jobAssigned.pickup_day.remarks && jobAssigned.pickup_day.remarks != '' ">                  
                                                            <div class="color-remark">
                                                                <strong>Remarks:</strong>
                                                            </div>
                                                            <div class="ml-2">
                                                                <span *ngIf="jobAssigned.crew_work_type == 'pickups'">{{jobAssigned.pickup_day.remarks}}</span> 
                                                            </div>        
                                                        </div>

                                                        <!-- deliveries - remark -->
                                                        <div class="col-6 row line-break-text font12 user-profile m-0 font12" *ngIf="jobAssigned.crew_work_type == 'deliveries' &&  jobAssigned.delivery_day.remarks && jobAssigned.delivery_day.remarks != '' ">                  
                                                            <div class="color-remark">
                                                                <strong>Remarks:</strong>
                                                            </div>
                                                            <div class="ml-2">
                                                                <span *ngIf="jobAssigned.crew_work_type == 'deliveries'">{{jobAssigned.delivery_day.remarks}}</span> 
                                                            </div>        
                                                        </div>

                                                        <!-- boxes_delivery_day - remark -->
                                                        <div class="col-6 row line-break-text font12 user-profile m-0 font12" *ngIf="jobAssigned.crew_work_type == 'boxes_delivery_day' &&  jobAssigned.box_delivery_day.remarks && jobAssigned.box_delivery_day.remarks != '' ">                  
                                                            <div class="color-remark">
                                                                <strong>Remarks:</strong>
                                                            </div>
                                                            <div class="ml-2">
                                                                <span *ngIf="jobAssigned.crew_work_type == 'boxes_delivery_day'">{{jobAssigned.box_delivery_day.remarks}}</span>
                                                            </div>        
                                                        </div>

                                                        <!-- packing_day - remark -->
                                                        <div class="col-6 row line-break-text font12 user-profile m-0 font12" *ngIf="jobAssigned.crew_work_type == 'packing_day' &&  jobAssigned.pack_day.remarks && jobAssigned.pack_day.remarks != '' ">                  
                                                            <div class="color-remark">
                                                                <strong>Remarks:</strong>
                                                            </div>
                                                            <div class="ml-2">
                                                                <span *ngIf="jobAssigned.crew_work_type == 'packing_day'">{{jobAssigned.pack_day.remarks}}</span> 
                                                            </div>        
                                                        </div>
                                                    </div>

                                                </div>                                                
                                                
                                            </div> 
                                            
                                            <div class="col-12 action-job-crew">
                                                <button type="button" title="Time Arrive" id="" class="btn button-time-arrive unassign tooltipmxp"                                                    
                                                    (click)="setAppintmentTime(jobAssigned)">
                                                    <i class="fas fa-clock"></i>
                                                </button>

                                                <!--Button Remark - Pickups -->
                                                <button *ngIf="jobAssigned.crew_work_type == 'pickups'" type="button" title="Remark" id="" class="btn btn-info tooltipmxp button-remark" (click)="openModalRemark(jobAssigned.id,  jobAssigned.pickup_day.date, jobAssigned.pickup_day.dispatched, jobAssigned.pickup_day.remarks, 'pickups' , jobAssigned.pickup_day.confirm, 'Appointments')">
                                                    <i class="fas fa-comment-alt icon-remark"></i>
                                                </button>
                                                <!-- Button Remark - Delivery -->
                                                <button *ngIf="jobAssigned.crew_work_type == 'deliveries'" type="button" title="Remark" id="" class="btn btn-info tooltipmxp button-remark" (click)="openModalRemark(jobAssigned.id,  jobAssigned.delivery_day.date, jobAssigned.delivery_day.dispatched, jobAssigned.delivery_day.remarks,'deliveries', jobAssigned.delivery_day.confirm, 'Appointments')">
                                                    <i class="fas fa-comment-alt icon-remark"></i>
                                                </button>
                                                <!-- Button Remark - Boxes Delivery Day -->
                                                <button *ngIf="jobAssigned.crew_work_type == 'boxes_delivery_day'" type="button" title="Remark" id="" class="btn btn-info tooltipmxp button-remark" (click)="openModalRemark(jobAssigned.id,  jobAssigned.box_delivery_day.date, jobAssigned.box_delivery_day.dispatched, jobAssigned.box_delivery_day.remarks,'boxes_delivery_day', jobAssigned.box_delivery_day.confirm, 'Appointments')">
                                                    <i class="fas fa-comment-alt icon-remark"></i>
                                                </button>
                                                <!-- Button Remark - Pack Day -->
                                                <button *ngIf="jobAssigned.crew_work_type == 'packing_day'" type="button" title="Remark" id="" class="btn btn-info tooltipmxp button-remark" (click)="openModalRemark(jobAssigned.id,  jobAssigned.pack_day.date, jobAssigned.pack_day.dispatched, jobAssigned.pack_day.remarks,'pack_day', jobAssigned.pack_day.confirm, 'Appointments')">
                                                    <i class="fas fa-comment-alt icon-remark"></i>
                                                </button>
                                                
                                                <button type="button" title="Release from Crew" id="" class="btn button-release-crew unassign tooltipmxp"                                                    
                                                    (click)="dailyCrewUnassign(jobAssigned.id,jobsAssigned)">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>
                                                                                   
                                    </div>                                                                   
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>    
            </div>   
        </div>

        <div class="widget-content searchable-container list">
            <div class="searchable-items list">

                <!-- Jobs confirmados para ese día -->
                <ng-container *ngIf="dayCalendar['today_unassigned']" >

                    <!-- Pickups -->
                    <div class="items pickup-color" [hidden]="!checkPickUps" *ngFor="let estimate of dayCalendar['today_unassigned']['pickups']">
                        <div class="item-content d-block job-calendar-day" *ngIf="estimate.id">
                            <div class="row col-12 d-flex justify-content-between ml-0 px-0 align-items-center">
                                <div class="user-profile">
                                    <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                        <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                        <p class="user-work">{{estimate.estimate.customer.name}}
                                            {{estimate.estimate.customer.last_name}}</p>
                                        <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                                    </div>
                                </div>                        
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-profile mb-0">
                                            <strong>Move Date: </strong>{{estimate.pickup_day.date_text | dateTime}}
                                        </p>
                                        <p class="user-work">
                                            <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="usr-email-addr">
                                            <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                            {{estimate.estimate.pickup.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                            {{estimate.estimate.delivery.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>Service: </strong> {{estimate.estimate.service}}
                                        </p>
                                    </div>
                                </div>
                                
                                <div class="user-email">

                                    <p class="info-title">Volume: </p>
                                    <p class="usr-location">
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                        {{estimate.estimate.volume.cubic_feets | number}}
                                    </p>
                                    <p class="usr-location">
                                        <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                                    </p>

                                    <p class="usr-ph-no">
                                        <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                                    </p>

                                </div>                        

                                <div class="user-profile" style="width: 150px;">
                                    <div class="user-meta-info">
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>                                              
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-work">
                                            <strong>Start Time: </strong>{{estimate.pickup_day.range_start}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Time: </strong>{{estimate.pickup_day.range_end}}
                                        </p>
                                    </div>
                                </div>

                                <div class="action-btn confirm-date">
                                    <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                        type="button" title="Manage Carrier" id="add-list__" 
                                        class="btn btn-primary tooltipmxp">
                                        <i class="fas fa-truck"></i>
                                    </a>                              
                                    <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                        (click)="setJob(estimate.id, estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments', true)">
                                        <i class="fas fa-calendar-check"></i>
                                    </button>

                                    <button type="button" title="Remark" id="add-list__" class="btn btn-info back-remark tooltipmxp" *ngIf="!estimate.carrier"
                                        (click)="openModalRemark(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments')">
                                        <i class="fas fa-comment-alt icon-remark"></i>
                                    </button>   
                                </div>  
                                                                
                                
                                <div class="user-profile">
                                    <div class="dropleft dropdown">
                                        <button [disabled]="!(dayCalendar['today_assigned'].length > 0)" class="btn btn-success dropdown-toggle tooltipmxp" title="Assign Job to a Crew" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            Assign
                                        </button> 
                                        <div class="dropdown-menu dropdown-menu-right dropdown-assign" aria-labelledby="dropdownMenuLink-1">
                                            <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                                <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, 'pickups', jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                            </ng-container>
                                        </div>
                                    </div>                                            
                                </div>
                          
                            </div>

                            <div class="col-12 row line-break-text user-profile mt-2 font13" *ngIf="estimate.pickup_day.remarks">                  
                                <div class="color-remark">
                                    <strong>Remarks: </strong>
                                </div>
                                <div class="ml-2">
                                    <span>{{estimate.pickup_day.remarks}}</span> 
                                </div>        
                            </div>

                        </div>
                        
                    </div>

                    <!-- Deliveries -->
                    <div class="items delivery-color" [hidden]="!checkDelivery" *ngFor="let estimate of dayCalendar['today_unassigned']['deliveries']">
                        <div class="item-content d-block job-calendar-day" *ngIf="estimate.id">
                            <div class="row col-12 d-flex justify-content-between ml-0 px-0 align-items-center">
                                <div class="user-profile">
                                    <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                        <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                        <p class="user-work">{{estimate.estimate.customer.name}}
                                            {{estimate.estimate.customer.last_name}}</p>
                                        <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-profile mb-0">
                                            <strong>Move Date: </strong>{{estimate.delivery_day.date_text | dateTime}}
                                        </p>
                                        <p class="user-work">
                                            <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="usr-email-addr">
                                            <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                            {{estimate.estimate.pickup.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                            {{estimate.estimate.delivery.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>Service: </strong> {{estimate.estimate.service}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-email">

                                    <p class="info-title">Volume: </p>
                                    <p class="usr-location">
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                        {{estimate.estimate.volume.cubic_feets | number}}
                                    </p>
                                    <p class="usr-location">
                                        <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                                    </p>

                                    <p class="usr-ph-no">
                                        <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                                    </p>

                                </div>
                                <div class="user-profile" style="width: 150px;">
                                    <div class="user-meta-info">                                                                                      
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-work">
                                            <strong>Start Time: </strong>{{estimate.delivery_day.range_start}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Time: </strong>{{estimate.delivery_day.range_end}}
                                        </p>
                                    </div>
                                </div>
                                <div class="action-btn confirm-date">
                                    <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                        type="button" title="Manage Carrier" id="add-list__" 
                                        class="btn btn-primary tooltipmxp">
                                        <i class="fas fa-truck"></i>
                                    </a>     
                                    <button type="button" title="Appointments" id="add-list__" class="btn tooltipmxp button-appointments" *ngIf="!estimate.carrier"                                        
                                        (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments', true)">
                                        <i class="fas fa-calendar-check"></i>
                                    </button>
                                    <button type="button" title="Remark" id="add-list__" class="btn btn-info back-remark tooltipmxp" *ngIf="!estimate.carrier"
                                        (click)="openModalRemark(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments')">
                                        <i class="fas fa-comment-alt icon-remark"></i>
                                    </button>
                                </div>                       

                                <div class="user-profile">
                                    <div class="dropleft dropdown">                                        
                                        <button [disabled]="!(dayCalendar['today_assigned'].length > 0)" class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            Assign
                                        </button> 
                                        <div class="dropdown-menu dropdown-menu-right dropdown-assign" aria-labelledby="dropdownMenuLink-1" >
                                            <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                                <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, 'deliveries', jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                            </ng-container>
                                        </div>
                                    </div>                                            
                                </div>
                            </div>

                            <div class="col-12 row line-break-text user-profile mt-2 font13" *ngIf="estimate.delivery_day.remarks">                  
                                <div class="color-remark">
                                    <strong>Remarks: </strong>
                                </div>
                                <div class="ml-2">
                                    <span>{{estimate.delivery_day.remarks}}</span> 
                                </div>        
                            </div>

                        </div>
                    </div>

                    <!-- Boxes Delivery Day -->
                    <div class="items boxDelivery-color" [hidden]="!checkBoxesDelivery" *ngFor="let estimate of dayCalendar['today_unassigned']['boxes_delivery_day']">
                        <div class="item-content d-block job-calendar-day" *ngIf="estimate.id">
                            <div class="row col-12 d-flex justify-content-between ml-0 px-0 align-items-center">   
                                <div class="user-profile">
                                    <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                        <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                        <p class="user-work">{{estimate.estimate.customer.name}}
                                            {{estimate.estimate.customer.last_name}}</p>
                                        <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-profile mb-0">
                                            <strong>Move Date: </strong>{{estimate.box_delivery_day.date_text | dateTime}}
                                        </p>
                                        <p class="user-work">
                                            <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="usr-email-addr">
                                            <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                            {{estimate.estimate.pickup.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                            {{estimate.estimate.delivery.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>Service: </strong> {{estimate.estimate.service}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-email">

                                    <p class="info-title">Volume: </p>
                                    <p class="usr-location">
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                        {{estimate.estimate.volume.cubic_feets | number}}
                                    </p>
                                    <p class="usr-location">
                                        <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                                    </p>

                                    <p class="usr-ph-no">
                                        <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                                    </p>

                                </div>

                                <div class="user-profile" style="width: 150px;">
                                    <div class="user-meta-info">
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p> 
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-work">
                                            <strong>Start Time: </strong>{{estimate.box_delivery_day.range_start}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Time: </strong>{{estimate.box_delivery_day.range_end}}
                                        </p>
                                    </div>
                                </div>
                                <div class="action-btn confirm-date">
                                    <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                        type="button" title="Manage Carrier" id="add-list__" 
                                        class="btn btn-primary tooltipmxp">
                                        <i class="fas fa-truck"></i>
                                    </a>    
                                    <button type="button" title="Appointments" id="add-list__" class="btn tooltipmxp button-appointments" *ngIf="!estimate.carrier"                                        
                                        (click)="setJob(estimate.id, estimate.box_delivery_day.date, estimate.box_delivery_day.dispatched, estimate.box_delivery_day.remarks, 'box_delivery_day', estimate.box_delivery_day.confirm, 'Appointments', true)">
                                        <i class="fas fa-calendar-check"></i>
                                    </button>
                                    <button type="button" title="Remark" id="add-list__" class="btn btn-info back-remark tooltipmxp" *ngIf="!estimate.carrier"
                                        (click)="openModalRemark(estimate.id, estimate.box_delivery_day.date, estimate.box_delivery_day.dispatched, estimate.box_delivery_day.remarks, 'boxes_delivery_day', estimate.box_delivery_day.confirm, 'Appointments')">
                                        <i class="fas fa-comment-alt icon-remark"></i>
                                    </button>
                                </div>

                                <div class="user-profile">
                                    <div class="dropleft dropdown"> 
                                        <button [disabled]="!(dayCalendar['today_assigned'].length > 0)" class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            Assign
                                        </button> 
                                        <div class="dropdown-menu dropdown-menu-right dropdown-assign" aria-labelledby="dropdownMenuLink-1">
                                            <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                                <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, 'boxes_delivery_day', jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                            </ng-container>
                                        </div>
                                    </div>                                            
                                </div>
                            </div>

                            <div class="col-12 row line-break-text user-profile mt-2 font13" *ngIf="estimate.box_delivery_day.remarks">                  
                                <div class="color-remark">
                                    <strong>Remarks: </strong>
                                </div>
                                <div class="ml-2">
                                    <span>{{estimate.box_delivery_day.remarks}}</span> 
                                </div>        
                            </div>

                        </div>
                    </div>

                    <!-- Packing Day -->
                    <div class="items packDays-color" [hidden]="!checkPackDays" *ngFor="let estimate of dayCalendar['today_unassigned']['packing_day']">
                        <div class="item-content d-block job-calendar-day" *ngIf="estimate.id">
                            <div class="row col-12 d-flex justify-content-between ml-0 px-0 align-items-center"> 
                                <div class="user-profile">
                                    <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                        <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                        <p class="user-work">{{estimate.estimate.customer.name}}
                                            {{estimate.estimate.customer.last_name}}</p>
                                        <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-profile mb-0">
                                            <strong>Move Date: </strong>{{estimate.pack_day.date_text | dateTime}}
                                        </p>
                                        <p class="user-work">
                                            <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="usr-email-addr">
                                            <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                            {{estimate.estimate.pickup.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                            {{estimate.estimate.delivery.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>Service: </strong> {{estimate.estimate.service}}
                                        </p>
                                    </div>
                                </div>
                                <div class="user-email">

                                    <p class="info-title">Volume: </p>
                                    <p class="usr-location">
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                        <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                        {{estimate.estimate.volume.cubic_feets | number}}
                                    </p>
                                    <p class="usr-location">
                                        <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                                    </p>

                                    <p class="usr-ph-no">
                                        <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                                    </p>

                                </div>

                                <div class="user-profile" style="width: 150px;">
                                    <div class="user-meta-info">
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                        <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p> 
                                    </div>
                                </div>
                                <div class="user-profile">
                                    <div class="user-meta-info">
                                        <p class="user-work">
                                            <strong>Start Time: </strong>{{estimate.pack_day.range_start}}
                                        </p>
                                        <p class="user-work">
                                            <strong>End Time: </strong>{{estimate.pack_day.range_end}}
                                        </p>
                                    </div>
                                </div>
                                <div class="action-btn confirm-date">
                                    <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                        type="button" title="Manage Carrier" id="add-list__" 
                                        class="btn btn-primary tooltipmxp">
                                        <i class="fas fa-truck"></i>
                                    </a>    
                                    <button type="button" title="Appointments" id="add-list__" class="btn btn-outline-danger tooltipmxp button-appointments" *ngIf="!estimate.carrier"                                        
                                        (click)="setJob(estimate.id, estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments', true)">
                                        <i class="fas fa-calendar-check"></i>
                                    </button>
                                    <button type="button" title="Remark" id="add-list__" class="btn btn-info back-remark tooltipmxp" *ngIf="!estimate.carrier"
                                        (click)="openModalRemark(estimate.id, estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments')">
                                        <i class="fas fa-comment-alt icon-remark"></i>
                                    </button> 
                                </div>                                

                                <div class="user-profile">
                                    <div class="dropleft dropdown">
                                        <button [disabled]="!(dayCalendar['today_assigned'].length > 0)" class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                            Assign
                                        </button> 
                                        <div class="dropdown-menu dropdown-menu-right dropdown-assign" aria-labelledby="dropdownMenuLink-1">
                                            <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                                <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, 'packing_day', jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                            </ng-container>
                                        </div>
                                    </div>                                            
                                </div>
                            </div> 
                            
                            <div class="col-12 row line-break-text user-profile mt-2 font13" *ngIf="estimate.pack_day.remarks">                  
                                <div class="color-remark">
                                    <strong>Remarks: </strong>
                                </div>
                                <div class="ml-2">
                                    <span>{{estimate.pack_day.remarks}}</span> 
                                </div>        
                            </div>

                        </div>
                    </div>
                </ng-container>
            </div>
        </div>


        <div class="widget-content searchable-container list">
            <div class="searchable-items list">
                
            <!-- ---- separator per date ---- -->
            <div class="col-12 widget-content searchable-container list d-flex justify-content-center" [hidden]="!hiddenCalendar">
                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content item-separator" style="position: initial!important;">
                            
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-arrow-circle-up icon-confirmed-jobs"></i>
                                    <h4 class="confirmed-jobs">
                                        Jobs with a confirmed date for this day
                                    </h4>
                                </div>
                                <div class="d-flex align-items-center">
                                    <h4 class="related-jobs">
                                        Jobs with date related to this day
                                    </h4>
                                    <i class="fas fa-arrow-circle-down icon-related-jobs"></i>
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            <!-- ---- separator per date ---- -->


            <!-- Jobs Relacionados a ese día No Asignados -->
            <ng-container *ngIf="dayCalendar['related_unassigned']" >
                <div class="items pickup-color" [hidden]="!checkPickUps" *ngFor="let estimate of dayCalendar['related_unassigned']['pickups']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>

                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"                                  
                                (click)="setJob(estimate.id, estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button>
                        </div>                                                

                        <div class="user-profile">                                                                       
                        </div>

                    </div>
                </div>
                <div class="items delivery-color" [hidden]="!checkDelivery" *ngFor="let estimate of dayCalendar['related_unassigned']['deliveries']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>
                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>
                            <div class="action-btn confirm-date">
                                <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                    (click)="setJob(estimate.id, estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments', false)">
                                    <i class="fas fa-calendar-check"></i>
                                </button>                                 
                            </div>                             
                        </div>                      

                        <div class="user-profile">                                        
                        </div>

                    </div>
                </div>

                <div class="items boxDelivery-color" [hidden]="!checkBoxesDelivery" *ngFor="let estimate of dayCalendar['related_unassigned']['boxes_delivery_day']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>

                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                (click)="setJob(estimate.id, estimate.estimate.pickup.boxes_delivery_day.date, estimate.estimate.pickup.boxes_delivery_day.dispatched, estimate.estimate.pickup.boxes_delivery_day.remarks, 'boxes_delivery_day', estimate.box_delivery_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button>
                        </div>

                        <div class="user-profile">                                         
                        </div>

                    </div>
                </div>

                <div class="items packDays-color" [hidden]="!checkPackDays" *ngFor="let estimate of dayCalendar['related_unassigned']['packing_day']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>

                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                (click)="setJob(estimate.id,  estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button>
                        </div>

                        <div class="user-profile">                                          
                        </div>
                    </div>
                </div>

            </ng-container>

            <!-- ---- separator per date ---- -->
            <div class="col-12 widget-content searchable-container list d-flex justify-content-center" [hidden]="!hiddenCalendar">

                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content item-separator justify-content-center" style="position: initial!important;">
 
                            <div class="d-flex align-items-center">
                                <i class="fas fa-arrow-circle-down icon-related-jobs"></i>
                                <h4 class="related-jobs">
                                    Jobs with date related to this day with a confirmed date
                                </h4>
                                <i class="fas fa-arrow-circle-down icon-related-jobs"></i>
                            </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            <!-- ---- separator per date ---- -->

            <!-- Jobs Relacionados a ese día Asignados a otro día-->
            <ng-container *ngIf="dayCalendar['related_assigned']" >
                <div class="items pickup-color" [hidden]="!checkPickUps" *ngFor="let estimate of dayCalendar['related_assigned']['pickups']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>
                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a> 
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button>                         
                        </div>                                                        

                        <div class="user-profile">                                          
                        </div>

                    </div>
                </div>
                <div class="items delivery-color" [hidden]="!checkDelivery" *ngFor="let estimate of dayCalendar['related_assigned']['deliveries']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong>Cf: </strong>{{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>
                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>  
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button> 
                        </div>
                        <div class="user-profile">                                       
                        </div>

                    </div>
                </div>

                <div class="items boxDelivery-color" [hidden]="!checkBoxesDelivery" *ngFor="let estimate of dayCalendar['related_assigned']['boxes_delivery_day']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>

                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>     
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                (click)="setJob(estimate.id, estimate.estimate.pickup.boxes_delivery_day.date, estimate.estimate.pickup.boxes_delivery_day.dispatched, estimate.estimate.pickup.boxes_delivery_day.remarks, 'boxes_delivery_day', estimate.box_delivery_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button>
                        </div>


                        <div class="user-profile">                                      
                        </div>

                    </div>
                </div>

                <div class="items packDays-color" [hidden]="!checkPackDays" *ngFor="let estimate of dayCalendar['related_assigned']['packing_day']">
                    <div class="item-content job-calendar-day" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style=" white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appWorkspaceRouterLink="/companyspace/{{estimate.company_id}}/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'PENDING_SIGNATURE' "><span class="text-red">{{estimate.carrier.name}}</span></p> 
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status == 'WAITING_FOR' ">PUBLISHED ON LOAD BOARD</p>
                                <p *ngIf="estimate.job_outsourcing && estimate.job_outsourcing?.status== 'WORKING_ON' ">{{estimate.carrier.name}}</p>
                            </div>
                        </div>

                        <div class="action-btn confirm-date d-flex">
                            <a (click)="openOutsourcingNewTab(estimate.company_id, estimate.estimate.id); $event.stopPropagation()"
                                type="button" title="Manage Carrier" id="add-list__" 
                                class="btn btn-primary tooltipmxp">
                                <i class="fas fa-truck"></i>
                            </a>
                            <button type="button" title="Appointments" id="add-list__" class="btn button-appointments tooltipmxp" *ngIf="!estimate.carrier"
                                (click)="setJob(estimate.id,  estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments', false)">
                                <i class="fas fa-calendar-check"></i>
                            </button> 
                        </div>

                        <div class="user-profile">                                         
                        </div>
                    </div>
                </div>
            </ng-container>
            </div>
        </div>
    </div>

    <!-- The Modal -->
    <div id="addEventsModal" class="modal animated fadeIn">

        <div class="modal-dialog modal-dialog-centered">

            <!-- Modal content -->
            <div class="modal-content">

                <div class="modal-body">

                    <span class="close">&times;</span>

                    <div class="add-edit-event-box">
                        <div class="add-edit-event-content">
                            <h5 class="add-event-title modal-title">Add Events</h5>
                            <h5 class="edit-event-title modal-title">Edit Events</h5>

                            <form class="">

                                <div class="row">

                                    <div class="col-md-12">
                                        <label for="start-date" class="">Event Title</label>
                                        <div class="d-flex event-title">
                                            <input id="write-e" type="text" placeholder="Enter Title"
                                                class="form-control" name="task">
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-12">
                                        <div class="form-group start-date">
                                            <label for="start-date" class="">From</label>
                                            <div class="d-flex">
                                                <input id="start-date" placeholder="Start Date" class="form-control"
                                                    type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-12">
                                        <div class="form-group end-date">
                                            <label for="end-date" class="">To</label>
                                            <div class="d-flex">
                                                <input id="end-date" placeholder="End Date" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="start-date" class="">Event Description</label>
                                        <div class="d-flex event-description">
                                            <textarea id="taskdescription" placeholder="Enter Description" rows="3"
                                                class="form-control" name="taskdescription"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="event-badge">
                                            <p class="">Badge:</p>

                                            <div class="d-sm-flex d-block">
                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-primary">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-primary">
                                                        <span class="new-control-indicator"></span>Work
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-warning">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-warning">
                                                        <span class="new-control-indicator"></span>Travel
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-success">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-success">
                                                        <span class="new-control-indicator"></span>Personal
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-danger">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-danger">
                                                        <span class="new-control-indicator"></span>Important
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button id="discard" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                    <button id="add-e" class="btn">Add Task</button>
                    <button id="edit-event" class="btn btn-save">Save</button>
                </div>

            </div>

        </div>

    </div>

</div>


<!-- Modal add edit extra-->
<div class="modal fade" #modalExtra tabindex="-1" role="dialog" aria-labelledby="extraTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Appointments'">
                    Appointments
                </h5>
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Workers'">
                    Workers
                </h5>
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Trucks'">
                    Trucks
                </h5>
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Agent'">
                    {{titleModalCarrier}}
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content" id="extraTitle">
                        <ng-container *ngIf="typeModal == 'Appointments'">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="inputState">Date &nbsp; </label>
                                    <app-date-picker [value]="selectedDay" [enableTime]="false"
                                        [placeholder]="'Not Assigned'" (onChange)="changeDate($event)">
                                    </app-date-picker>
                                </div>
                            </div>                         
                        </ng-container>


                        <ng-container *ngIf="typeModal == 'Workers'">
                            <div class="form-group mb-4">
                                <label class="control-label">Employees</label>
                                <div class="n-chk" *ngFor="let employee of employeesView">
                                    <label class="new-control new-checkbox checkbox-primary">
                                        <input type="checkbox" class="new-control-input"
                                            (change)="getEmployeesTeam(employee.id)" [checked]="employee.checked">
                                        <span class="new-control-indicator"></span>{{employee.name}}
                                    </label>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="typeModal == 'Trucks'">
                            <div class="form-group mb-4">
                                <label class="control-label">Truncks</label>
                                <div class="n-chk" *ngFor="let trunck of truncks">
                                    <label class="new-control new-checkbox checkbox-primary">
                                        <input type="checkbox" class="new-control-input"
                                            (change)="getTrucksTeam(trunck.id)" [checked]="typeService">
                                        <span class="new-control-indicator"></span>{{trunck.type}}
                                    </label>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="typeModal == 'Agent'">
                            <div class="compose-content content-select">

                                <div class="col-xl-6 col-lg-8 col-md-8 overflow-content scroll-default">

                                    <table class="table table-bordered table-hover mb-4 size-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Name
                                                </th>
                                                <th class="text-center">
                                                    Phone
                                                </th>
                                                <th class="text-center">
                                                    Email
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let carrier of carriers">
                                                <tr (click)="selectCarrier(carrier, true)">
                                                    <td>
                                                        {{carrier.name}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{carrier.phone}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{carrier.email}}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button *ngIf="typeModal == 'Appointments' && isAssigned"
                    class="btn btn-danger" (click)="update(true)">
                    Cancel Appointment
                </button>
                <button *ngIf="typeModal == 'Appointments' || typeModal == 'Workers' || typeModal == 'Trucks'"
                    class="btn btn-save" (click)="update(false)">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal Remark-->
<div class="modal fade" #modalRemark tabindex="-1" role="dialog" aria-labelledby="extraTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Remarks
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content" id="extraTitle">
                        
                        <div class="row">
                            <div class="col-md-12">
                                <label for="inputState">Remark</label>
                                <div class="list-title">
                                    <textarea name="Remark" id="carrier_notes" class="form-control"
                                        [(ngModel)]="remark" placeholder="Remark" rows="5"></textarea>
                                </div>
                            </div>
                        </div>                                         

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" (click)="update(false)">
                    Save
                </button>

            </div>
        </div>
    </div>
</div>


<div class="modal fade modalCarriers" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #entityFormItem="ngForm" ngNativeValidate>

                <div class="modal-body">
                    <div class="compose-box">

                        <div class="compose-content content-select">
                            <h5 class="add-task-title">Select {{titleModalCarrier}}</h5>

                            <div class="col-xl-6 col-lg-8 col-md-8 overflow-content scroll-default">

                                <table class="table table-bordered table-hover mb-4 size-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                            <th class="text-center">
                                                Phone
                                            </th>
                                            <th class="text-center">
                                                Email
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let carrier of carriers">
                                            <tr (click)="selectCarrier(carrier, true)">
                                                <td>
                                                    {{carrier.name}}
                                                </td>
                                                <td class="text-center">
                                                    {{carrier.phone}}
                                                </td>
                                                <td class="text-center">
                                                    {{carrier.email}}
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" (click)="closeModalCarriers()">
                        Cancel
                    </button>
                    <button data-btnfn="addTask" class="btn btn-save add-tsk" (click)="saveCarrierAndAgent()" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>

<!-- Modal add  Add Daily Crew -->
<div class="modal fade modalCarriers" #modalAddDailyCrew tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #entityFormItem="ngForm" ngNativeValidate>

                <div class="modal-body">
                    <div class="compose-box">

                        <div class="col-md-12 compose-content content-select ">
                            <h5 class="modal-crew-title">Add Daily Crew</h5>                          
                        </div>
                      
                        <div class="row col-md-12">

                            <div class="col-md-12 mt-3">
                                <label class="modal-crew-subtitle">Name</label>
                                <input id="" type="text" placeholder="Enter Name" class="form-control" name="cname" [(ngModel)]="crew.name" required>
                            </div>

                            <div class="col-md-12 mt-5">
                                <label class="modal-crew-subtitle">Employees</label>
                            </div>

                            <div class="row col-md-12">

                                <div class="col-md-6">
                                    <label class="modal-crew-employees">Drivers</label>
                                    <select class="form-control" name="crew_driver" id="" [(ngModel)]="crew.drivers_id" multiple style="width: 230px;">
                                        <ng-container *ngFor="let cDriver of crewEmployees">
                                            <option value="{{cDriver.id}}">
                                                {{cDriver.name | lowercase}} - {{cDriver.job_position | lowercase}}
                                            </option>
                                        </ng-container>
                                    </select>   
                                </div>

                                <div class="col-md-6">
                                    <label class="modal-crew-employees">Foreman</label>
                                    <select class="form-control" name="crew_foreman" id="" [(ngModel)]="crew.foreman_id" [appSelect2]  [place]="'Not Assigned'" style="width: 230px;">
                                        <ng-container *ngFor="let cForeman of crewEmployees">
                                            <option value="{{cForeman.id}}">
                                                {{cForeman.name | lowercase}} - {{cForeman.job_position | lowercase}}
                                            </option>
                                        </ng-container>
                                    </select>   
                                </div>

                                <div class="col-md-6">
                                    <label class="modal-crew-employees">Workers</label>
                                    <select class="form-control" name="crew_worker" id="" [(ngModel)]="crew.workers_id" multiple style="width: 230px;">
                                        <ng-container *ngFor="let cWorkers of crewEmployees">
                                            <option value="{{cWorkers.id}}">
                                                {{cWorkers.name | lowercase}} - {{cWorkers.job_position | lowercase}}
                                            </option>
                                        </ng-container>
                                    </select>   
                                </div>
                                <div class="col-md-6">
                                    <label class="modal-crew-employees">Vehicle</label>
                                    <select class="form-control" name="crew_vehicle" id="" [(ngModel)]="crew.vehicle_id" style="width: 230px;">
                                        <ng-container *ngFor="let cVehicle of truncks">
                                            <option value="{{cVehicle.id}}">
                                                {{cVehicle.type | lowercase}}-{{ cVehicle.number}}
                                            </option>
                                        </ng-container>
                                    </select>   
                                </div>
                                <div class="col-md-6">
                                    <label class="modal-crew-employees">Start Time</label>
                                    <input type="time" name="time" [(ngModel)]="crew.start_time" class="form-control">

                                </div>
                            </div>
    
                        </div>

                    </div>
                </div>

                <div class="modal-footer p-4">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button data-btnfn="addTask" (click)="dailyCrewCreate()" class="btn btn-save" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- Modal map routes -->
<div class="modal fade size-modal" id="modalMap" #modalMap tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog size-modal max-width-modal-dialog" role="document">
        <div class="modal-content size-modal">

            <div class="modal-header header-map">
                <h5 class="modal-title" id="exampleModalLabel">Route Delivery</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body header-map">
                <div id="map" #mapView class="map"></div>
            </div>

        </div>
    </div>
</div>

<!-- Modal map routes -->
<div class="modal  fade " id="modalTimeCrew" #modalTimeCrew tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content size-modal">

            <div class="modal-header header-map">
                <h5 class="modal-title" id="exampleModalLabel">Time Arrive</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body header-map">
                <div class="row" style="    margin-top: 12px;">
                    <div class="col-md-6">
                        <label for="inputState">Time Arrive Start</label>
                        <select name="rangeStart" id="" class="form-control"  [(ngModel)]="rangeStart">
                            <option value="01:00">01:00 am</option>
                            <option value="01:30">01:30 am</option>
                            <option value="02:00">02:00 am</option>
                            <option value="02:30">02:30 am</option>
                            <option value="03:00">03:00 am</option>
                            <option value="03:30">03:30 am</option>
                            <option value="04:00">04:00 am</option>
                            <option value="04:30">04:30 am</option>
                            <option value="05:00">05:00 am</option>
                            <option value="05:30">05:30 am</option>
                            <option value="06:00">06:00 am</option>
                            <option value="06:30">06:30 am</option>
                            <option value="07:00">07:00 am</option>
                            <option value="07:30">07:30 am</option>
                            <option value="08:00">08:00 am</option>
                            <option value="08:30">08:30 am</option>
                            <option value="09:00">09:00 am</option>
                            <option value="09:30">09:30 am</option>
                            <option value="10:00">10:00 am</option>
                            <option value="10:30">10:30 am</option>
                            <option value="11:00">11:00 am</option>
                            <option value="11:30">11:30 am</option>
                            <option value="12:00">12:00 pm</option>
                            <option value="12:30">12:30 pm</option>
                            <option value="13:00">01:00 pm</option>
                            <option value="13:30">01:30 pm</option>
                            <option value="14:00">02:00 pm</option>
                            <option value="14:30">02:30 pm</option>
                            <option value="15:00">03:00 pm</option>
                            <option value="15:30">03:30 pm</option>
                            <option value="16:00">04:00 pm</option>
                            <option value="16:30">04:30 pm</option>
                            <option value="17:00">05:00 pm</option>
                            <option value="17:30">05:30 pm</option>
                            <option value="18:00">06:00 pm</option>
                            <option value="18:30">06:30 pm</option>
                            <option value="19:00">07:00 pm</option>
                            <option value="19:30">07:30 pm</option>
                            <option value="20:00">08:00 pm</option>
                            <option value="20:30">08:30 pm</option>
                            <option value="21:00">09:00 pm</option>
                            <option value="21:30">09:30 pm</option>
                            <option value="22:00">10:00 pm</option>
                            <option value="22:30">10:30 pm</option>
                            <option value="23:00">11:00 pm</option>
                            <option value="23:30">11:30 pm</option>
                            <option value="00:00">12:00 am</option>
                            <option value="00:30">12:30 am</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label for="inputState">Time Arrive End</label>
                        <select name="rangeEnd" id="" class="form-control"  [(ngModel)]="rangeEnd">
                            <option value="01:00">01:00 am</option>
                            <option value="01:30">01:30 am</option>
                            <option value="02:00">02:00 am</option>
                            <option value="02:30">02:30 am</option>
                            <option value="03:00">03:00 am</option>
                            <option value="03:30">03:30 am</option>
                            <option value="04:00">04:00 am</option>
                            <option value="04:30">04:30 am</option>
                            <option value="05:00">05:00 am</option>
                            <option value="05:30">05:30 am</option>
                            <option value="06:00">06:00 am</option>
                            <option value="06:30">06:30 am</option>
                            <option value="07:00">07:00 am</option>
                            <option value="07:30">07:30 am</option>
                            <option value="08:00">08:00 am</option>
                            <option value="08:30">08:30 am</option>
                            <option value="09:00">09:00 am</option>
                            <option value="09:30">09:30 am</option>
                            <option value="10:00">10:00 am</option>
                            <option value="10:30">10:30 am</option>
                            <option value="11:00">11:00 am</option>
                            <option value="11:30">11:30 am</option>
                            <option value="12:00">12:00 pm</option>
                            <option value="12:30">12:30 pm</option>
                            <option value="13:00">01:00 pm</option>
                            <option value="13:30">01:30 pm</option>
                            <option value="14:00">02:00 pm</option>
                            <option value="14:30">02:30 pm</option>
                            <option value="15:00">03:00 pm</option>
                            <option value="15:30">03:30 pm</option>
                            <option value="16:00">04:00 pm</option>
                            <option value="16:30">04:30 pm</option>
                            <option value="17:00">05:00 pm</option>
                            <option value="17:30">05:30 pm</option>
                            <option value="18:00">06:00 pm</option>
                            <option value="18:30">06:30 pm</option>
                            <option value="19:00">07:00 pm</option>
                            <option value="19:30">07:30 pm</option>
                            <option value="20:00">08:00 pm</option>
                            <option value="20:30">08:30 pm</option>
                            <option value="21:00">09:00 pm</option>
                            <option value="21:30">09:30 pm</option>
                            <option value="22:00">10:00 pm</option>
                            <option value="22:30">10:30 pm</option>
                            <option value="23:00">11:00 pm</option>
                            <option value="23:30">11:30 pm</option>
                            <option value="00:00">12:00 am</option>
                            <option value="00:30">12:30 am</option>
                        </select>
                    </div>
                    <div class="col-md-12 mt-5">
                        <span class="usr-ph-no">
                            <label for="inputState">Date confirmation</label>
                            <input type="checkbox" [(ngModel)]="confirm">
                        </span>
                    </div>
                    <div class="col-md-12">
                        <span class="usr-ph-no">
                            <label for="inputState">Dispatched</label>
                            <input type="checkbox" [(ngModel)]="dispatched">
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>        
                <button class="btn btn-save" (click)="editTime()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade modalCarriers" #modalInformationCarriers tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="compose-box">

                    <div class="compose-content content-select">

                        <h3 class="add-task-title text-center" style="color: #e44f00;">
                            {{titleModalCarrier}} assignment
                        </h3>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="content-request-carrier">
                                    <label class="label-text" for="offer">Carrier Balance Offer</label>
                                    <input id="offer" type="text" placeholder="Carrier Balance Offer"
                                        class="form-control" name="offer" [(ngModel)]="carrierBalanceOffer"
                                        (keyup)="changeCarrierBalanceOffer()">

                                    <label class="label-text" for="percentage">Carrier Balance Percentage</label>
                                    <input id="percentage" type="text" placeholder="Carrier Balance Percentage"
                                        disabled="true" class="form-control" name="percentage"
                                        [(ngModel)]="carrierBalancePercentage">
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="content-request-carrier">
                                    <label class="label-text" for="comment">Special Conditions</label>
                                    <textarea *ngIf="titleModalCarrier == 'Carrier'" type="textarea"
                                        class="form-control" rows="6" cols="10" name="comments"
                                        [(ngModel)]="specialConditions">
                                    </textarea>
                                    <textarea *ngIf="titleModalCarrier == 'Agent'" type="textarea" class="form-control"
                                        rows="6" cols="10" name="comments" [(ngModel)]="specialConditions">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <span *ngIf="carrierBalanceOffer >= jobSelected.estimate.total" style="color: red;">
                    Maximum Carrier Balance Offer
                </span>
                <button class="btn" (click)="closeModalInformationCarriers()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                    Discard
                </button>
                <button class="btn btn-warning" [disabled]="carrierBalanceOffer >= jobSelected.estimate.total"
                    (click)="setCarriersModal('Carrier')" type="submit">
                    Next
                </button>
            </div>

        </div>
    </div>
</div>

<div class="modal fade modalCarriers" #modalCarriers tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content size-modal-carrier">
            <form #entityFormItem="ngForm" ngNativeValidate>

                <div class="modal-body">
                    <div class="compose-box">

                        <div class="compose-content content-select">

                            <h3 class="add-task-title text-center" style="padding: 20px 0; color: #e44f00;">
                                {{titleModalCarrier}} assignment
                            </h3>

                            <div class="content-request-carrier" *ngIf="viewSectionModalCarrier == 'INTRO'">
                                <button type="button" class="btn btn-danger"
                                    (click)="viewSectionModalCarrier = 'POST_REQUEST_CARRIER'">
                                    Post on load board
                                </button>
                                <br>
                                <button type="button" class="btn btn-secondary "
                                    (click)="viewSectionModalCarrier = 'ASSIGN_CARRIER'">
                                    Manual carrier assignment
                                </button>
                            </div>

                            <div class="content-request-carrier"
                                *ngIf="viewSectionModalCarrier == 'POST_REQUEST_CARRIER'">
                                <label for="">
                                    Show on Public load board?
                                    <input type="checkbox" [(ngModel)]="isPublicJobOutsourcing"
                                        name="isPublicJobOutsourcing">
                                </label>
                                <button class="btn btn-success" (click)="postOnLoadBoard(titleModalCarrier)">
                                    Request a Carrier
                                </button>
                            </div>

                            <div class="row content-assign-carrier" *ngIf="viewSectionModalCarrier == 'ASSIGN_CARRIER'">
                                <div class="col-12" style="text-align: center; padding-top: 15px;">
                                    <div class="form-inline" style="display: inline-block;">
                                        <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                                            style="width: 300px;" class="form-control product-search" id="input-search"
                                            placeholder="Filter...">
                                    </div>
                                </div>
                                <div class="widget-content searchable-container list col-12 overflow-content">
                                    <div class="searchable-items list">
                                        <div class="items items-header-section">
                                            <div class="item-content col-12">

                                                <div class="col-3">
                                                    <h4>
                                                        Company
                                                    </h4>
                                                </div>
                                                <div class="user-email col-3">
                                                    <h4>
                                                        DOT
                                                    </h4>
                                                </div>
                                                <div class="user-location col-3">
                                                    <h4 style="margin-left: 0;">
                                                        Insurance Due Date
                                                    </h4>
                                                </div>
                                                <div class="user-phone col-3">
                                                    <h4 style="margin-left: 3px;">
                                                        Option
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="widget-content searchable-container list col-12 overflow-content scroll-default">
                                    <div class="searchable-items list">
                                        <div class="items"
                                            *ngFor="let carrier of carriers| search:filterSearch | async; index as i">
                                            <div class="item-content col-12" style="text-align: center;">
                                                <div class="user-profile col-3">
                                                    <div class="user-meta-info">
                                                        <p class="user-name">{{carrier.name}}</p>
                                                        <p *ngIf="carrier.address.full_address" class="usr-ph-no">
                                                            {{carrier.address.full_address}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="user-email col-3">
                                                    <p class="usr-email-addr">
                                                        {{carrier.dot}}
                                                    </p>
                                                </div>
                                                <div class="user-location col-3">
                                                    <p class="usr-location">
                                                        {{carrier.insurance_due_date | date}}
                                                    </p>
                                                </div>
                                                <div class="user-phone col-3">
                                                    <button *ngIf="titleModalCarrier == 'Agent'" class="btn btn-warning"
                                                        (click)="selectAgent(carrier)" type="submit">
                                                        Assign
                                                    </button>
                                                    <button *ngIf="titleModalCarrier == 'Carrier'"
                                                        class="btn btn-warning" (click)="selectCarrier(carrier, true)"
                                                        type="submit">
                                                        Assign
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" (click)="closeModalCarriers()">
                        Cancel
                    </button>
                    <button class="btn btn-save" (click)="saveCarrierAndAgent()" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
