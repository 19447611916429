import { Component, OnInit } from '@angular/core';
import { Warehouse } from 'src/app/entities/workspace/warehouse';
import { HelperService } from 'src/app/services/helper.service';
import { WarehousesService } from 'src/app/services/workspaces/warehouses.service';

declare var jQuery;
declare var swal;
@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.scss']
})
export class WarehousesComponent implements OnInit {

    rows: Array<Warehouse>;
    public actualPage: number;
    public filterSearch: string;

    constructor(
        private WarehousesService: WarehousesService,
        private helperService: HelperService
    ) {
        this.actualPage = 1;
        this.filterSearch = '';
    }

    ngOnInit(): void {
    }

    
    ngAfterViewInit() {
        this.load();
    }


    private load() {
        this.helperService.showLoadingMxpro360();
        this.WarehousesService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.WarehousesService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }
}
