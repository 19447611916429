import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { log } from 'util';

@Injectable({
    providedIn: 'root'
})
export class BroadcastChannelService {
    private channelPopUp = new BroadcastChannel('chanel_call_incomming');
    private channelInfoNumber = new BroadcastChannel('chanel_info_call_number');
    private channelInfoName = new BroadcastChannel('chanel_info_call_name');
    private channelInfoSms = new BroadcastChannel('chanel_info_sms');
    private channelPhoneSelect = new BroadcastChannel('chanel_info_sms');
    private channelRead = new BroadcastChannel('chanel_read');
    private channelPhoneSmsFrom = new BroadcastChannel('chanel_number_sms_from');
    private channelPhoneHide = new BroadcastChannel('channel_phone_hide');




    activeChannelPhoneHide(phone: any,) {
        this.channelPhoneHide.postMessage(phone);
    }

    activeIncomingCalPopUp(mensaje: any,) {
        this.channelPopUp.postMessage(mensaje);
    }

    emitMensajeNumber(mensaje: any,) {
        this.channelInfoNumber.postMessage(mensaje);
    }

    emitMensajeName(mensaje: any,) {
        this.channelInfoName.postMessage(mensaje);
    }

    emitMensajeSms(mensaje: any) {
        this.channelInfoSms.postMessage(mensaje);
    }

    emitNumberSms(numberPhoneSelected: any) {
        this.channelPhoneSelect.postMessage(numberPhoneSelected);
    }

    emitNumberSmsFrom(numberPhoneSelected: any) {
        this.channelPhoneSmsFrom.postMessage(numberPhoneSelected);
    }

    emitSmsRead(message: any) {
        this.channelRead.postMessage(message);
    }


    getPhoneHiden(callback: (phone: any) => void) {
        console.log('getPhoneHiden');

        this.channelPhoneHide.onmessage = (event) => {
            console.log('getPhoneHiden event.data', event.data);
            callback(event.data);
        };
    }

    getBodyNumber(callback: (mensaje: any) => void) {
        this.channelInfoNumber.onmessage = (event) => {
            callback(event.data);
        };
    }

    getNameCustomer(callback: (mensaje: any) => void) {
        this.channelInfoName.onmessage = (event) => {
            callback(event.data);
        };
    }

    getMenssage(callback: (mensaje: any) => void) {
        this.channelPopUp.onmessage = (event) => {
            callback(event.data);
        };
    }


    getMenssageSms(callback: (mensaje: any) => void) {
        this.channelInfoSms.onmessage = (event) => {
            callback(event.data);
        };
    }

    readMenssageSms(callback: (mensaje: any) => void) {
        this.channelRead.onmessage = (event) => {
            callback(event.data);
        };
    }

    getNumberPhoneSelected(callback: (mensaje: any) => void) {
        this.channelPhoneSelect.onmessage = (event) => {
            callback(event.data);
        };
    }


    getNumberPhoneSmsFrom(callback: (mensaje: any) => void) {
        this.channelPhoneSmsFrom.onmessage = (event) => {
            callback(event.data);
        };
    }

}
