import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { ClaimView } from 'src/app/entities/workspace/claim-view';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(private http: HttpClient) { }

  getAll(paginator: Paginator, data): Promise<Paginator> {
    const params: any = {...data,
      "use_paginator": 1,
      "page": paginator.paginator.page,
      "per_page": paginator.paginator.per_page,
      "start": paginator.paginator.start,
      "end": paginator.paginator.end,
      "order_by": paginator.paginator.order_by,
      "order_type": paginator.paginator.order_type
    };
    return this.http.get<Paginator>(environment.api.company + '/claims', {params}).toPromise();
  }

  getByEstimate(id: string): Promise<ClaimView> {
    return this.http.get<ClaimView>(environment.api.company + '/claims/' + id, {}).toPromise();
  }

  saveClaimEstimate(id: string, claimData) {
    return this.http.post(environment.api.company + '/claims/' + id, claimData).toPromise();
  }
}
