export class JobStatus {
    public id: number;
    public date_job_done: number;
    public general: 'BROKER_CANCELED' | 'CARRIER_CANCELED' | 'WORK_CARRIED_OUT' | 'CUSTOMER_CANCELED' | 'CARRIER_PAPERWORK_ON' | 'WORKING_ON';
    public operation: 'NEW' | 'DELIVERED_BOXES' | 'PACKED_MOVE' | 'PICKED_UP' | 'IN_TRANSIT' | 'IN_STORAGE' | 'DELIVERED' | 'CLOSING_REVIEW';
    public picked_docs: boolean;
    public delivered_docs: boolean;
    public archived_picked_docs: boolean;
    public date_archived_picked_docs: number;
    public comment_archived_picked_docs: string;
    public archived_delivered_docs: boolean;
    public date_archived_delivered_docs: number;
    public comment_archived_delivered_docs: string;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.date_job_done = null;
        this.general = 'WORKING_ON';
        this.operation = 'NEW';
        this.picked_docs = false;
        this.delivered_docs = false;
        this.archived_picked_docs = false;
        this.date_archived_picked_docs = null;
        this.comment_archived_picked_docs = null;
        this.archived_delivered_docs = false;
        this.date_archived_delivered_docs = null;
        this.comment_archived_delivered_docs = null;
    }
}
