import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { AuthService } from 'src/app/services/auth.service';

declare const jQuery;

@Directive({
    selector: '[appCompanyRouterLink]'
})
export class CompanyRouterLinkDirective {

    @Input()
    appCompanyRouterLink: string;

    @Input()
    appRouterLinkActive: string;

    // contiene la url final a establecer en el elemento
    private url: string;

    constructor(
        private authService: AuthService,
        private element: ElementRef,
        private helperService: HelperService,
        private router: Router,
    ) {
        this.appRouterLinkActive = null;
    }

    ngOnChanges(changes: SimpleChanges) {

        // construimos la url
        this.buildLink();

        // verificamos si se recibio un nuevo link para el elemento
        if (changes.appCompanyRouterLink.currentValue !== null && changes.appCompanyRouterLink.currentValue !== undefined) {
            // cargamos la url sobre el element
            this.setLink();
        }

        // damos la orden de actualizar la clase css en el elemento
        this.updateClassLinkActive();

    }

    ngOnDestroy() {
        if (!jQuery(this.element.nativeElement).is('a')) {
            jQuery(this.element.nativeElement).unbind();
        }
    }

    ngOnInit() {

        // Se crea el escucha del emitter para actualizar las urls
        this.authService
            .onChange
            .subscribe((isLogged: boolean) => {
                if(isLogged) {
                    this.refreshUrl();
                }
            });


        // creamos un listen para los eventos de cambio de pagina,
        this.router.events
            .subscribe((event: RouterEvent) => {
                // trabajamos con el evento que indica que ha cargado la nueva página
                if (event instanceof NavigationEnd) {
                    // actualizamos la clase css sobre el elemento
                    this.updateClassLinkActive();
                }
            });
    }

    ngAfterViewInit() {
        this.refreshUrl();
    }

    /**
     * Actualiza las urls de la app
     */
    private refreshUrl() {

        // construimos la url
        this.buildLink();

        // establecemos la url
        this.setLink();
    }

    /**
     * Permite construir el link que se va asignar
     */
    private buildLink() {
        // construirmos la url para usar posteriormente
        this.url = this.helperService.buildCompanyRouterLink(this.appCompanyRouterLink);
    }

    /**
     * Permite establecer el link en el elemeneot a operar
     */
    private setLink() {

        // se valida el tipo del elemento con el que se esta tratando para saber si se le asigna
        // el enlace o si se opera por el area del onclick
        if (jQuery(this.element.nativeElement).is('a')) {
            jQuery(this.element.nativeElement).attr('href', '/#' + this.url);
        } else {
            jQuery(this.element.nativeElement).bind('click', () => {
                this.router.navigate([this.url]);
            });
        }
    }

    /**
     * Permite actualizar la clase css en el elemento, esto para cuando estemos en la url que el elmento mismo esta definiendo
     */
    private updateClassLinkActive() {

        // validamos que se haya recibio una clase css para establecerla
        if (this.appRouterLinkActive == null) {
            // removemos la clase
            jQuery(this.element.nativeElement).removeClass(this.appRouterLinkActive);
            return;
        }

        // verificamos si estamos en la misma url
        if (this.router.url === this.url) {

            // verificamos que el elemento no tenga ya la clasae que se tiene pensado adicionar
            if (!jQuery(this.element.nativeElement).hasClass(this.appRouterLinkActive)) {

                // se adiciona la clase al elemento
                jQuery(this.element.nativeElement).addClass(this.appRouterLinkActive);
            }
        } else {

            // verificamos que tenga la clase a retirar
            if (jQuery(this.element.nativeElement).hasClass(this.appRouterLinkActive)) {
                jQuery(this.element.nativeElement).removeClass(this.appRouterLinkActive);
            }
        }
    }

}
