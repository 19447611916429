import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastChannelService } from 'src/app/services/broadcast-channel.service';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { SmsService } from 'src/app/services/companies/sms.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeadsService } from 'src/app/services/leads.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';

declare var jQuery;

@Component({
    selector: 'app-sms',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss']
})
export class SMSComponent implements OnInit {


    // contiene la extension con la que debe trabajar el dialer
    @Input()
    phoneExtension: PhoneExtension;

    // indica si el telefono esta encendido
    @Input()
    power: boolean;



    @ViewChild('newChatModal') newChatModal: ElementRef;
    @ViewChild('addContactModal') addContactModal: ElementRef;
    @ViewChild('messagesBox') private messagesBox!: ElementRef;

    public filterSearch: string;
    public contacts: Array<any>
    public chats: Array<any>
    public sms: Array<any>
    public darkChat: boolean;

    // numero con el que se va a enviar y recibir sms
    public numberPhoneSelected: string;
    public contactSelected: string;
    public dataForm: any;
    public selectedContact: string;
    public newMessage: string;
    public sms_phone_line_id: string;
    public fromSms: string;
    public formPhoneHiden: boolean;


    constructor(
        private leadsService: LeadsService,
        private clientSocketsService: ClientSocketsService,
        private smsService: SmsService,
        public helperService: HelperService,
        public authService: AuthService,
        private channelService: BroadcastChannelService,
        public phoneExtensionsService: PhoneExtensionsService,
        private phoneLineService: PhoneLinesService,
        private CDRef: ChangeDetectorRef
    ) {

        this.phoneExtension = null;
        this.power = false;

        this.filterSearch = '';
        this.darkChat = false;
        this.numberPhoneSelected = null;
        this.contacts = [];
        this.chats = [];
        this.contactSelected = '';
        this.dataForm = {
            phone_number: '',
            message: ''
        }
        this.selectedContact = '';
        this.newMessage = '';
        this.sms = [];
        this.fromSms = '';
        this.formPhoneHiden = false;

    }

    ngOnInit(): void {

        // configuramos los sockets a trabajar
        this.listenSocketConnection();

        // configuramos los canales a trabajar
        this.listenChannelConnections();
    }

    ngAfterViewInit(): void {



    }


    /**
     * METODO que detecta los cambios en los atributos @INPUT
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);

        // verificamos el estado de poder(si esta encendios) el telefono
        if (!!changes.power) {

            // verificamos si estan encendiendo el telefono
            if (changes.power.currentValue &&  this.phoneExtension != null) {

                // cargamos el sistema de mensajeria (sms)
                this.setUpPhoneNumber();
            } 
        }

        // verificamos si estan reportando un campo para la extension
        if (!!changes.phoneExtension) {

            // verificamos si hay algun valor trabajable
            if (!!changes.phoneExtension.currentValue && this.power) {

                // cargamos el sistema de mensajeria (sms)
                this.setUpPhoneNumber();
            }
        }
    }


    /**
     * permite escuchar los eventos basicos del socket para poder que el componente pueda trabajar
     */
    private listenSocketConnection() {

        console.log('cmprobando conexion de socket');

        // creamos un escucha para cuando el socket se conecte, se despliegue la configuracion
        this.clientSocketsService
            .onConnect
            .subscribe(() => {

                // desplegamos la configuracion de comunicacion via SOCKET
                this.setupSockets();
            });

        // veerificamos si el socket ya esta conectado al servidor
        if (this.clientSocketsService.isConnected()) {

            // desplegamos la configuracion de comunicacion via SOCKET
            this.setupSockets();
        }

    }

    /**
     * permite configurar los eventos de sockets a trabajar en el component 
     */
    private setupSockets() {

        // obtenemos el socket a trabajar
        const socket = this.clientSocketsService.getSocket();

        socket.on('sms:notify', (response) => {
            console.log("response notificacion sms: ", response);
        });

        socket.on('sms_reciver', () => {

            console.log("nuevo mensaje recibido ");

            this.loadContact();
            this.loadChat(this.selectedContact);
        });

    }

    /**
     * permite escuchar las conexiones de los canales usados entre las pestaañas de los navegadores
     */
    private listenChannelConnections() {


        this.channelService
            .readMenssageSms((mensaje) => {
                if (mensaje) {
                    this.loadContact();
                    this.loadChat(this.fromSms);

                    let sectionSms;
                    let sectionPhone;
                    let sectionhomeTab;
                    let sectionPhone1;

                    sectionSms = document.getElementById('text_sms_section');
                    sectionSms.classList.add('active');
                    sectionSms.classList.add('show');

                    sectionPhone = document.getElementById('calling_section');
                    sectionPhone1 = document.getElementById('home-tab');

                    sectionPhone1.classList.remove('active');
                    sectionPhone1.classList.remove('show');
                    sectionPhone.classList.remove('active');
                    sectionPhone.classList.remove('show');

                    sectionhomeTab = document.getElementById('contact-tab');
                    sectionhomeTab.classList.add('active');
                }
            });
    }


    /**
     * Permite cargar la configuracion del sms a trabajar 
     */
    private setUpPhoneNumber() {
        this.helperService.showLoadingMxpro360();
        this.phoneLineService
            .getById(this.phoneExtension.sms_phone_line_id)
            .then((response) => {

                // asignamos el de telefono a trabajar
                this.numberPhoneSelected = response.phone_number;

                // cargamos todos los contactos
                this.loadContact();
            })
            .catch((error) => {
                console.log('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    /**
     * permite cargar todos los mensajes trabajados con el numero de telefono
     */
    private loadContact() {

        this.smsService
            .getAllContacts(this.numberPhoneSelected)
            .then((response) => {

                // asignamos los contactatos cargados                
                this.contacts = response;

                console.log("resultado cargar contactos: ", response);


                if(this.contacts.length > 0){
                    this.fromSms = response[0].from;
                    this.sms = response;
                    this.channelService.emitMensajeSms(this.sms);
                }
                
                this.channelService.emitNumberSms(this.phoneExtension.sms_phone_line_id);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    };


    loadChat(phone) {

        this.selectedContact = phone;
        this.toggleActiveContact(phone);
        this.smsService
            .getChatByContact(this.numberPhoneSelected, phone)
            .then((response) => {

                this.chats = response;
                this.scrollToBottom();

                setTimeout(() => {
                    this.CDRef.detectChanges();
                },500);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    };

    sendMessage(newMessage: boolean) {

        if (!this.clientSocketsService.isConnected()) {
            return;
        }

        if (newMessage) {
            this.dataForm.phone_number = this.selectedContact;
            this.dataForm.message = this.newMessage;
        }

        const form = {
            "to": [this.dataForm.phone_number],
            "from": this.numberPhoneSelected,
            "text": this.dataForm.message,
            "applicationId": "dad36d15-88e5-4ca9-9407-c22193d6cafb",
            "tag": "test message",
            "priority": "default"
        }

        const dataForm = { workspace_id: this.authService.workspaceSession.workspace.id, form: form, leadCompanyId: null };

        let that = this;
        const callBack = function (response) {
            console.log("mensaje enviado: ", response);
            that.loadContact();
            that.loadChat(that.selectedContact);
        }

        this.clientSocketsService.getSocket().emit("sms:send", dataForm, callBack);

        this.dataForm = {
            phone_number: '',
            message: ''
        }
        this.newMessage = '';
    }



    readConversation(contact) {
        this.smsService
            .updateConversation(contact, this.phoneExtension.sms_phone_line_id)
            .then((response) => {
                this.loadContact();
                this.loadChat(this.selectedContact);
                this.scrollToBottom();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    }


    newChat() {
        jQuery(this.newChatModal.nativeElement).modal('show');
    }

    addContact() {
        jQuery(this.addContactModal.nativeElement).modal('show');
    }

    deleteConversation(contact) {
        this.helperService.showLoadingMxpro360();
        this.smsService
            .deleteConversation(this.numberPhoneSelected, contact['end_point'])
            .then((response) => {
                this.selectedContact = '';
                this.loadContact();
                this.loadChat(this.selectedContact);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    // Identificamos visualmente cual contacto se encuentra activo
    toggleActiveContact(phoneContact) {

        // Borramos la clase .active-contact del contacto que se encuentre activo
        const contactActiveClass = document.querySelectorAll('.active-contact');

        if (contactActiveClass.length > 0) {

            contactActiveClass.forEach(contact => {
                contact.classList.remove('active-contact');
            });

            // Agregamos la clase .active-contact al contacto activo
            document.getElementById(phoneContact).classList.add('active-contact');
        }

    }

    private scrollToBottom(): void {
        try {
            setTimeout(() => {
                this.messagesBox.nativeElement.scrollTop = this.messagesBox.nativeElement.scrollHeight;
            }, 0);
        } catch (err) {
            console.error('Error while scrolling:', err);
        }
    }
}
