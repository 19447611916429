import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/entities/workspace/company';
import { Employee } from 'src/app/entities/workspace/employee';
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { AuthService } from 'src/app/services/auth.service';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { HelperService } from 'src/app/services/helper.service';
import { PhoneSystemService } from 'src/app/services/phone-system.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';



declare const jQuery;
@Component({
    selector: 'app-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

    company: Company;
    employee: Employee;
    showDialer: boolean;
    extensions: Array<PhoneExtension>;
    extensionSelected: PhoneExtension;
    dialerIsOn: boolean;
    smsPhoneLine: PhoneLine;

    constructor(
        private authService: AuthService,
        private phoneExtensionsService: PhoneExtensionsService,
        private helperService: HelperService,
        private phoneSystemService: PhoneSystemService,
        private clientSocketsService: ClientSocketsService,
    ) {
        this.showDialer = false;
        this.employee = null;
        this.company = null;
        this.extensions = [];
        this.extensionSelected = null;
        this.dialerIsOn = false;
        this.smsPhoneLine = null;
    }

    ngOnInit(): void {

        this.company = this.authService.workspaceSession.company;
        this.employee = this.authService.workspaceSession.employee;

        // cargamos la informacion de las extensiones
        this.loadExtensions();
    }

    ngAfterViewInit(): void {

        // ponemos el modo pantalla completa
        this.modeFullScreen();
    }


    /**
     * permite cargar las extensiones del usuario logueado
     */
    private loadExtensions() {
        this.helperService.showLoadingMxpro360();
        this.phoneExtensionsService
            .getAllExtensions()
            .then((response) => {
                this.extensions = [];
                for (const extension of response) {
                    if (this.employee.id === extension.employee_id) {
                        this.extensions.push(extension);
                    }
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    };

    /**
     * Permite ocultar la barra lateral y el header
     */
    private modeFullScreen() {
      jQuery('app-header').hide();
      jQuery('app-sidebar').hide();
      jQuery('app-panel-layout > #container > #content').css({ margin: 0 });
    }
  
    /**
     * permite mostrar o ocultar el dialer
     */
    toogleDialer(): void {
        this.showDialer = !this.showDialer;
    }

    /**
     * permite establecer una extension de telefono
     * @param tmpExtension 
     */
    setExtension(tmpExtension: PhoneExtension) {
        this.extensionSelected = tmpExtension;
    }

    /**
     * permite encender el dialer con la extension seleccionada
     */
    async switchDialer() {
        // validamos que haya extension seleccianda
        if (!!this.extensionSelected) {

            // mostramos un loading aviso
            this.helperService.showLoadingMxpro360();

            // trabajamos con el valor contrario, esto para que no se actualize el valor 
            // y no se encienda el telefono hasta que se tenga la informacion necesaria para trabajar
            if (!this.dialerIsOn) {

                // obtenemos el id del socket actual y lo enviamos al sistema de telefonía
                const socket_id = this.clientSocketsService.getSocket().id;

                // inicializamos el dialer con el id del socket y la extension seleccionada
                const phoneExtensionView = await this.phoneSystemService.initDialer(this.extensionSelected.id, socket_id);

                // asignamos el numero de telefono para los sms
                this.smsPhoneLine = phoneExtensionView.sms_phone_line;
            } else {

                // cerramos el dialer
                await this.phoneSystemService.closeDialer(this.extensionSelected.id);
            }
            
            // indicamos el cambio de estado
            this.dialerIsOn = !this.dialerIsOn;


            // ocultamos el loading aviso
            this.helperService.hideLoadingMxpro360();
        }
    }


}
