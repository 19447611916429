import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneMessage } from 'src/app/entities/workspace/phone-message';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  constructor(private http: HttpClient) {

  }

  getAllContacts(agentNumber): Promise<Array<PhoneMessage>>{
     return this.http.get<Array<PhoneMessage>>(environment.api.company + '/phone-messages/' + agentNumber).toPromise();
  }

  getChatByContact(agentNumber, numberContact): Promise<Array<PhoneMessage>>{
     return this.http.get<Array<PhoneMessage>>(environment.api.company + '/phone-messages/' + agentNumber + '/chat/' + numberContact).toPromise();
  }

  getPortalLink(dataForm): Promise<any> {
    return this.http.post(environment.api.company + '/get-customer-link', dataForm).toPromise();
  }

  updateConversation(contact ,sms_phone_line_id) {
    return this.http.put(environment.api.apiRtcPrivate + '/read-conversation', { contact: contact , sms_phone_line_id : sms_phone_line_id}).toPromise();
}

  deleteConversation(agentNumber, numberContact){

    return this.http.put<Array<PhoneMessage>>(environment.api.company + '/phone-messages/' + agentNumber + '/chat/' + numberContact, {}).toPromise();


  }




}
