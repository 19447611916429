export class EstimateVolume {
    public id: string;
    public move_size: "STUDIO" | "LARGE_STUDIO" | "ONE_BEDROOM" | "TWO_BEDROOM" | "THREE_BEDROOM" | "FOUR_BEDROOM" | "FIVE_BEDROOM" | "SIX_BEDROOM";
    public libs: number;
    public cubic_feets: number;
    public cubic_feets_selled: number;
    public miles: number;
    public travel_time: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.move_size = null;
        this.libs = null;
        this.cubic_feets = null;
        this.cubic_feets_selled = null;
        this.miles = null;
        this.travel_time = null;
    }
}
