<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing">
            <h3>
                Custom Labels
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Custom Labels
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">

        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-new" (click)="openModalGenerateLabels()">
                    <i class="fas fa-plus-square mr-2"></i> Create New Lot
                </a>
            </div>
        </div> 

    </div>
    <!-- header -->


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Custom Lot #</th>
                                <th>Unassigned</th>
                                <th>Assigned</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>                                
                                    Custom Lot 1
                                </td>
                                <td>
                                    Unassigned: 50
                                </td>
                                <td>
                                    Assigned: 0
                                </td>
                                <td class="text-right">
                                    <a appCompanyRouterLink="/custom-labels/:id/view" class="btn btn-info">
                                        <i class="fa-solid fa-eye"></i>
                                    </a>
                                    <button class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>


<!-- Modal Generate Labels-->
<div class="modal fade generate-labels-modal" #generateLabels tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Generate Labels
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-4">
                            <label class="control-label">Lot Name</label>
                            <input name="quantity" type="number" class="form-control" step="1" value="Custom Lote 1" disabled/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group mb-4">
                            <label class="control-label">Quantity</label>
                            <input name="quantity" type="number" class="form-control" step="1"/>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group mb-4">
                            <label class="control-label">Color Code</label>
                            <input name="dataForm_color_hex" type="color" class="form-control label-color"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button data-dismiss="modal" class="btn btn-save">
                    Generate
                </button>
            </div>
        </div>

    </div>
</div>