<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Periods
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                       
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/settings/periods">Periods</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav> 
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">

                </div>

            </div>
        </div>
        <!-- header -->






        <div class="statbox widget box box-shadow">

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">

                        <div class="col-12">

                            <div class="form-group mb-1">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control" required>
                                </div>

                                <small id="nameHelp1" class="form-text text-muted mb-4">Enter Name.</small>
                            </div>

                        </div>

                        <div class="col-6">

                            <h4 class="from"> From </h4>

                            <div class="form-group mb-4">
                                <label class="control-label">Month</label>
                                <select [(ngModel)]="dataForm.from.month" (ngModelChange)="changeSelectDay('from')" name="wp_tomonth" class="form-control" required>
                                    <option selected value='1'>Janaury</option>
                                    <option value='2'>February</option>
                                    <option value='3'>March</option>
                                    <option value='4'>April</option>
                                    <option value='5'>May</option>
                                    <option value='6'>June</option>
                                    <option value='7'>July</option>
                                    <option value='8'>August</option>
                                    <option value='9'>September</option>
                                    <option value='10'>October</option>
                                    <option value='11'>November</option>
                                    <option value='12'>December</option>
                                </select>
                                <small id="monthHelp1" class="form-text text-muted mb-4">Select the Month of Departure.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label">From Day</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg></span>
                                    </div>
                                    <select [(ngModel)]="dataForm.from.day" name="from_day" class="form-control" required>
                                        <option value="{{ix + 1}}" *ngFor="let fromDay of ','.repeat(fromTotalDays -1).split(','); index as ix">{{ix + 1}}</option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select the Day of the Month</small>
                            </div>

                        </div>

                        <div class="col-6">

                            <h4 class="to"> To </h4>

                            <div class="form-group mb-4">
                                <label class="control-label">Month</label>
                                <select [(ngModel)]="dataForm.to.month" (ngModelChange)="changeSelectDay('to')" name="wp_month" class="form-control" required>
                                    <option selected value='1'>Janaury</option>
                                    <option value='2'>February</option>
                                    <option value='3'>March</option>
                                    <option value='4'>April</option>
                                    <option value='5'>May</option>
                                    <option value='6'>June</option>
                                    <option value='7'>July</option>
                                    <option value='8'>August</option>
                                    <option value='9'>September</option>
                                    <option value='10'>October</option>
                                    <option value='11'>November</option>
                                    <option value='12'>December</option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select the Month of Arrival.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label">To Day</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg></span>
                                    </div>
                                    <select [(ngModel)]="dataForm.to.day" name="to_day" class="form-control" required>
                                        <option value="{{ixTo + 1}}" *ngFor="let toDay of ','.repeat(toTotalDays -1).split(','); index as ixTo">{{ixTo + 1}}</option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select the Day of the Month</small>
                            </div>
                            
                        </div>

                    </div>

                    <div class="text-center">
                        <button class="btn btn-main-action ml-1 mt-3" type="submit">
                            Save
                        </button>
                    </div>

                </form>


            </div>
        </div>


    </div>
</div>
