export class Vehicle {
    public id: string;
    public name: string;
    public type: "BOX_TRUCK" | "PICKUP_TRUCK" | "CARGO_VAN" | "FREIGTHLINERS" | "CAR_CARRIER_TRAILER";
    public description: string;
    //public number: number; CAMPO ELIMINADO
    public model: string;
    public vin: string;
    public plate: string;
    public year: number;
    public tire_size: string;
    public active: boolean;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.type = null;
        this.description = null;
        //this.number = null; CAMPO ELIMINADO
        this.model = null;
        this.vin = null;
        this.plate = null;
        this.year = null;
        this.tire_size = null;
        this.active = true;
        this.created = null;
        this.updated = null;
    }
}

