<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>Settings
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/local-tariff-settings">
                            Local Tariff Settings
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">{{ localTariff.id == null ? 'Add': 'Edit'}}</li>
                </ol>
            </nav>
        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-12">
            <div class="statbox widget box box-shadow">

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
                        <div class="row">
                            
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="form-group mb-1">
                                    <label class="control-label">Active</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-power">
                                                    <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                                    <line x1="12" y1="2" x2="12" y2="12"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="active" [(ngModel)]="active" id="active" class="form-control">
                                            <option value="YES">Yes</option>
                                            <option value="NO">No</option>
                                        </select>
                                    </div>
                                    <small id="setting_duplicate_tx" class="form-text text-muted mb-4">Select Whether the Local Tariff is Active or Inactive.</small>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="form-group mb-1">
                                    <label class="control-label">Number of Workers</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-users">
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <input name="workers" [(ngModel)]="localTariff.workers" id="workers" class="form-control" placeholder='Workers...'>
                                    </div>
                                    <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Enter the Number of Workers.</small>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="form-group mb-1">
                                    <label class="control-label">Number of Trucks</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-truck">
                                                    <rect x="1" y="3" width="15" height="13"></rect>
                                                    <polygon
                                                        points="16 8 20 8 23 11 23 16 16 16 16 8">
                                                    </polygon>
                                                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" name="trucks" [(ngModel)]="localTariff.trucks"
                                            id="max_cf_per_worker" class="form-control"/>
                                    </div>
                                    <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Enter the Number of Trucks.</small>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="form-group mb-1">
                                    <label class="control-label">Price per Hour</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-dollar-sign">
                                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" name="price_per_hour" [(ngModel)]="localTariff.price_per_hour"
                                            id="price_per_hour" step="0.01" class="form-control"/>
                                    </div>
                                    <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Enter the Price per Hour.</small>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="form-group mb-1">
                                    <label class="control-label">Max Hours</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-clock">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <polyline points="12 6 12 12 16 14"></polyline>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" name="max_hours" [(ngModel)]="localTariff.max_hours"
                                            id="how_many_cf_per_hour" class="form-control"/>
                                    </div>
                                    <small id="cover_stop_fees_tx" class="form-text text-muted mb-4">Enter How Many Cf per Hour.</small>
                                </div>
                            </div>

                        </div>
                        <div class="text-center mt-5">
                            <button class="btn btn-main-action" type="submit">
                                Save
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>