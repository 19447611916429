import { ConstantsMessages } from 'src/app/constants-messages';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { LeadView } from 'src/app/entities/workspace/lead-view';
import { AuthService } from 'src/app/services/auth.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeadsService } from 'src/app/services/leads.service';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { Company } from 'src/app/entities/workspace/company';

declare const jQuery;
declare const swal;
@Component({
    selector: 'app-leads-esimates-results',
    templateUrl: './leads-esimates-results.component.html',
    styleUrls: ['./leads-esimates-results.component.scss']
})
export class LeadsEsimatesResultsComponent implements OnInit {
    constantsMessages = ConstantsMessages;
    dataResult: { estimates: Array<EstimateView>, leads: Array<LeadView>, balances: Array<any>, search: string };
    deadLeadSelected: string;
    idDeadLead: string;
    actualPage: number;

    companies: Company[];

    @ViewChild('deadLeadModal') deadLeadModal: ElementRef;

    public isVisibleCategory: boolean;

    public isEditableCategory: boolean;

    public generalSettings: GeneralSettings;

    public isWorkspace: boolean;

    constructor(
        private authService: AuthService,
        private estimatesService: EstimatesService,
        private helperService: HelperService,
        private leadsService: LeadsService,
        private router: Router,
        private generalSettingsService: GeneralSettingsService,
        public tariffVersionFactory: TariffVersionService,
        public companiesService: CompaniesService
    ) {
        this.actualPage = 1;

        this.isVisibleCategory = false;
        this.isEditableCategory = false;
        this.generalSettings = new GeneralSettings();
        this.isWorkspace = false;
        this.companies = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.load();
        this.helperService.onReSearch.subscribe(() => {
            this.load();
        });
        if (this.router.url.includes('companyspace')) {
            this.isWorkspace = false;
        } else {
            this.isWorkspace = true;
        }

        this.loadCompanies();
       
    }

    public load() {
        this.helperService.showLoadingMxpro360();
        this.dataResult = this.helperService.resultsearch;
        this.loadData();
        this.helperService.hideLoadingMxpro360();
    }


    /**
     * Carga las Companies
     */
    private loadCompanies() {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll()
            .then((response) => {
                this.companies = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    toEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .toCreateByLead(id)
            .then((response) => {
                this.helperService.showLoadingMxpro360();
                swal(
                    'Done',
                    'The record has been created .',
                    'success'
                );
                this.helperService.goToCompanyRouterLink('/moving/' + response['row_id'] + '/estimate');
            })
            .catch((error) => {
                console.error('Error: ', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    reSearch() {
        this.helperService.hideLoadingMxpro360();
        this.estimatesService
            .getByEstimateCode(this.dataResult.search).then((response) => {
                this.helperService.resultsearch = response;
                this.loadData();
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeCategory(id: string, category: string) {
        this.leadsService
            .changeCategory(id, category)
            .then((response) => {
                this.helperService.showLoadingMxpro360();
                jQuery(this.deadLeadModal.nativeElement).modal('hide');
                swal(
                    'Done',
                    'Blocked lead',
                    'success'
                );
                this.load();

            })
            .catch((error) => {
                console.error('Error: ', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeCategoryEstimate(estimate, category: string) {
        this.helperService.showLoadingMxpro360();
        const categoryEstimate = { category };
        this.estimatesService
            .patchEntity(estimate.id, categoryEstimate)
            .then(() => {
                this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    deadLead() {
        this.leadsService
            .deadLead(this.idDeadLead, this.deadLeadSelected)
            .then((response) => {
                this.helperService.showLoadingMxpro360();
                jQuery(this.deadLeadModal.nativeElement).modal('hide');
                swal(
                    'Done',
                    'Blocked lead',
                    'success'
                );
                this.reSearch();

            })
            .catch((error) => {
                console.error('Error: ', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    notContacted(lead) {
        lead.status = 'NOT_CONTACTED';
        this.leadsService
            .save(lead)
            .then((response) => {
                this.helperService.showLoadingMxpro360();
                swal(
                    'Done',
                    'Lead Not Contacted',
                    'success'
                );
                this.reSearch();

            })
            .catch((error) => {
                console.error('Error: ', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    modalDeadLead(id) {
        this.idDeadLead = id;
        jQuery(this.deadLeadModal.nativeElement).modal('show');
    }

    goToDetailBalance(balanceId: string) {
        this.helperService.goToCompanyRouterLink('/payments/' + balanceId + '/view');
    }

    public openNewTab(estimate) {
        if (this.isWorkspace) {
            window.open(window.location.origin + '/#/workspace/' + this.authService.workspaceSession.id + '/companyspace/' + estimate.company_id + '/moving/' + estimate.id + '/estimate');
        } else {
            this.helperService.openNewTab(estimate.id, 'estimate');
        }
    }


    async loadData() {
        this.isVisibleCategory = false;
        this.isEditableCategory = false;

        this.helperService.showLoadingMxpro360();
        await this.generalSettingsService
            .get()
            .then((response) => {
                this.generalSettings = response;
                
                if (this.generalSettings.settings.marketing_setting.agent_lead_mode === 'POOL') {
                    this.isVisibleCategory = false;
                } else {
                    this.isVisibleCategory = true;
                }

                if(this.authService.hasPermissionCompany('ASSIGN_LEADS')){
                    this.isEditableCategory = true;
                }

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    copyText(text: string, type: string) {

        if (type === 'phone') {
            text = text.replace(/-/g, '');
            text = text.substr(-10, 10);
        }

        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(this.constantsMessages.COPIED);
    }

}
