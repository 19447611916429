import { PayrollBalance } from 'src/app/entities/workspace/payroll-balance';
import { PayrollBalancesService } from 'src/app/services/companies/payroll-balances.service';

import { Component, OnInit } from '@angular/core';

import { PayrollBalanceView } from '../../../entities/workspace/payroll-balance-view';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-payroll-balances',
    templateUrl: './payroll-balances.component.html',
    styleUrls: ['./payroll-balances.component.scss']
  })
  export class PayrollBalancesComponent implements OnInit {
  
    public payrollBalance: PayrollBalance;  
    public payrollBalances: Array<PayrollBalanceView>;  
    public actualPage;
  
    constructor(
      private payrollBalancesService: PayrollBalancesService,
      private helperService: HelperService
    ) {
      this.payrollBalance = new PayrollBalance();
      this.actualPage = 1;
    }
  
    ngOnInit(): void {
    }

    ngAfterViewInit(): void{
      this.loadPayrollBalances();
    }
  
    remove(id: string) {
      swal({
        title: 'Are you sure?',
        text: 'Do you want to delete the record?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!'
      })
        .then((result) => {
          if (result.value) {
            this.helperService.showLoadingMxpro360();
            this.payrollBalancesService
              .remove(id)
              .then(() => {
                this.loadPayrollBalances();
                swal(
                  'Deleted!',
                  'The record has been deleted.',
                  'success'
                );
              })
              .catch((error) => {
                console.error('Error: ', error);
              })
              .finally(() => {
                this.helperService.hideLoadingMxpro360();
              });
          }
        });
    }
  
    private loadPayrollBalances(): void {
      this.helperService.showLoadingMxpro360();
      this.payrollBalancesService
        .getAll()
        .then((response) => {
          this.payrollBalances = response;
        })
        .catch((error) => {
          console.error('Error: ', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }

    public payRollDone(payrollId: string): void {
        this.payrollBalancesService.saveStatus(payrollId).then(() => {
            swal(
                'Updated!',
                'The record has been updated.',
                'success'
            );
            this.loadPayrollBalances();
        });
    }

}
