import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-salesman-performance-team',
  templateUrl: './salesman-performance-team.component.html',
  styleUrls: ['./salesman-performance-team.component.scss']
})
export class SalesmanPerformanceTeamComponent implements OnInit {
    jobsSeeAllSalesmanPerformanceOnHisTeam: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) { 
    this.jobsSeeAllSalesmanPerformanceOnHisTeam = [];
  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadSeeAllSalesmanPerformanceOnHisTeamCompany();
    } else {
        this.loadSeeAllSalesmanPerformanceOnHisTeamWorkspace();
    }
  }

  
  loadSeeAllSalesmanPerformanceOnHisTeamWorkspace(){
        
    this.dashboardWorkspaceService
        .getSeeAllSalesmanPerformanceOnHisTeam()
        .then((response) => {
            this.jobsSeeAllSalesmanPerformanceOnHisTeam = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

  
loadSeeAllSalesmanPerformanceOnHisTeamCompany(){
        
    this.dashboardComopanyService
        .getSeeAllSalesmanPerformanceOnHisTeam()
        .then((response) => {
            this.jobsSeeAllSalesmanPerformanceOnHisTeam = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


}
