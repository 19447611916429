import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileUploadModalComponent } from '../modals/file-upload-modal/file-upload-modal.component';
import { File as FileEntity } from './../entities/global/file';
import { HelperService } from './helper.service';

declare var jQuery;

@Injectable({
    providedIn: 'root'
})
export class UploadFilesService {


    // creamos un referenciador para la modal
    private modalComponentRef: ComponentRef<FileUploadModalComponent>;

    /**
    * variable a tratar las suscripciones con la modales para cuando se cierre,
    * se crea esta variable ya que cuando se cierra la modal, ya no va a existir mas,
    * por lo que se debe desuscribir (asi permite liberar recursos)
    */
    private onCloseSuscribe: Subscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
        private helperService: HelperService
    ) {
        this.onCloseSuscribe = null;
    }

    /**
     * Permite abrir la modal, 
     * @returns 
     */
    openModal() {
        this.helperService.showLoadingMxpro360();
        return new Promise((resolve, reject) => {

            // le damos vida en "angular" al componente recibido
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FileUploadModalComponent);
            this.modalComponentRef = componentFactory.create(this.injector);

            // indicamos que anexe el view
            this.appRef.attachView(this.modalComponentRef.hostView);

            // agregamos la vista al documento
            const domElem = (this.modalComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            document.body.appendChild(domElem);

            // creamos un archivo
            this.onCloseSuscribe = this.modalComponentRef.instance.onClose
                .subscribe((files: Array<string>) => {
                    const arrayResponse = [];
                    for (const fileName of files) {
                        // obtener extencion de archivos
                        const type = this.getMimeType(fileName);
                        const newFile = new FileEntity();
                        newFile.name = fileName;
                        newFile.display_name = fileName;
                        newFile.mime_type = type;
                        arrayResponse.push(newFile);
                    }
                    resolve(arrayResponse);
                    this.onCloseSuscribe.unsubscribe();
                    this.destroyModal();
                });


            this.modalComponentRef.instance.onReady
                .subscribe(() => {
                    // abrimos la modal
                    this.modalComponentRef.instance.open();
                    this.helperService.hideLoadingMxpro360();
                });

        });
    }


    /**
     * permote destruir la modal
     */
    private destroyModal() {
        this.appRef.detachView(this.modalComponentRef.hostView);
        this.modalComponentRef.destroy();
    }

    /**
     * permite subir archivos al servidor, deu
     * 
     * @param files 
     */
    send(files: File[]) {

        // creamos objeto formulario
        const dataForm = new FormData();

        // iteramos los archivos
        for (const file of files) {

            // agregamos los archivos al objeto formulario
            dataForm.append('files[]', file);
        }

        // enviamos al servidor y envolvemos en una promesa
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: environment.api.base + '/files',
                type: 'post',
                data: dataForm,
                contentType: false,
                processData: false,
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }

            });
        });
    }

    /**
     * permite subir archivos al servidor
     * 
     * @param dataForm 
     */
    uploapImage(dataForm: FormData) {

        // enviamos al servidor y envolvemos en una promesa
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: environment.api.base + '/files',
                type: 'post',
                data: dataForm,
                contentType: false,
                processData: false,
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }

            });
        });
    }

    getMimeType(fileName: string) {
        // obtiene la extension del archivo
        const ext = fileName.split('.').pop();
        // obtiene mimetype
        let mime = '';
        switch (ext) {
            case 'html':
            case 'htm':
            case 'shtml': {
                mime = 'text/html';
                break;
            }
            case 'css': {
                mime = 'text/css';
                break;
            }
            case 'xml': {
                mime = 'text/xml';
                break;
            }
            case 'gif': {
                mime = 'image/gif';
                break;
            }
            case 'jpeg':
            case 'jpg': {
                mime = 'image/jpeg';
                break;
            }
            case 'js': {
                mime = 'application/x-javascript';
                break;
            }
            case 'atom': {
                mime = 'application/atom+xml';
                break;
            }
            case 'rss': {
                mime = 'application/rss+xml';
                break;
            }
            case 'mml': {
                mime = 'text/mathml';
                break;
            }
            case 'txt': {
                mime = 'text/plain';
                break;
            }
            case 'jad': {
                mime = 'text/vnd.sun.j2me.app-descriptor';
                break;
            }
            case 'wml': {
                mime = 'text/vnd.wap.wml';
                break;
            }
            case 'htc': {
                mime = 'text/x-component';
                break;
            }
            case 'png': {
                mime = 'image/png';
                break;
            }
            case 'tif':
            case 'tiff': {
                mime = 'image/tiff';
                break;
            }
            case 'wbmp': {
                mime = 'image/vnd.wap.wbmp';
                break;
            }
            case 'ico': {
                mime = 'image/x-icon';
                break;
            }
            case 'jng': {
                mime = 'image/x-jng';
                break;
            }
            case 'bmp': {
                mime = 'image/x-ms-bmp';
                break;
            }
            case 'svg': {
                mime = 'image/svg+xml';
                break;
            }
            case 'webp': {
                mime = 'image/webp';
                break;
            }
            case 'jar':
            case 'war':
            case 'ear': {
                mime = 'application/java-archive';
                break;
            }
            case 'hqx': {
                mime = 'application/mac-binhex40';
                break;
            }
            case 'doc': {
                mime = 'application/msword';
                break;
            }
            case 'pdf': {
                mime = 'application/pdf';
                break;
            }
            case 'ps':
            case 'eps':
            case 'ai': {
                mime = 'application/postscript';
                break;
            }
            case 'rtf': {
                mime = 'application/rtf';
                break;
            }
            case 'xls': {
                mime = 'application/vnd.ms-excel';
                break;
            }
            case 'ppt': {
                mime = 'application/vnd.ms-powerpoint';
                break;
            }
            case 'wmlc': {
                mime = 'application/vnd.wap.wmlc';
                break;
            }
            case 'kml': {
                mime = 'application/vnd.google-earth.kml+xml';
                break;
            }
            case 'kmz': {
                mime = 'application/vnd.google-earth.kmz';
                break;
            }
            case '7z': {
                mime = 'application/x-7z-compressed';
                break;
            }
            case 'cco': {
                mime = 'application/x-cocoa';
                break;
            }
            case 'jardiff': {
                mime = 'application/x-java-archive-diff';
                break;
            }
            case 'jnlp': {
                mime = 'application/x-java-jnlp-file';
                break;
            }
            case 'run': {
                mime = 'application/x-makeself';
                break;
            }
            case 'pl':
            case 'pm': {
                mime = 'application/x-perl';
                break;
            }
            case 'prc':
            case 'pdb': {
                mime = 'application/x-pilot';
                break;
            }
            case 'rar': {
                mime = 'application/x-rar-compressed';
                break;
            }
            case 'rpm': {
                mime = 'application/x-redhat-package-manager';
                break;
            }
            case 'sea': {
                mime = 'application/x-sea';
                break;
            }
            case 'swf': {
                mime = 'application/x-shockwave-flash';
                break;
            }
            case 'sit': {
                mime = 'application/x-stuffit';
                break;
            }
            case 'tcl':
            case 'tk': {
                mime = 'application/x-tcl';
                break;
            }
            case 'der':
            case 'pem':
            case 'crt': {
                mime = 'application/x-x509-ca-cert';
                break;
            }
            case 'xpi': {
                mime = 'application/x-xpinstall';
                break;
            }
            case 'xhtml': {
                mime = 'application/xhtml+xml';
                break;
            }
            case 'zip': {
                mime = 'application/zip';
                break;
            }
            case 'bin':
            case 'exe':
            case 'dll': {
                mime = 'application/octet-stream';
                break;
            }
            case 'deb': {
                mime = 'application/octet-stream';
                break;
            }
            case 'dmg': {
                mime = 'application/octet-stream';
                break;
            }
            case 'eot': {
                mime = 'application/octet-stream';
                break;
            }
            case 'iso':
            case 'img': {
                mime = 'application/octet-stream';
                break;
            }
            case 'msi':
            case 'msp':
            case 'msm': {
                mime = 'application/octet-stream';
                break;
            }
            case 'mid':
            case 'midi':
            case 'kar': {
                mime = 'audio/midi';
                break;
            }
            case 'mp3': {
                mime = 'audio/mpeg';
                break;
            }
            case 'ogg': {
                mime = 'audio/ogg';
                break;
            }
            case 'ra': {
                mime = 'audio/x-realaudio';
                break;
            }
            case '3gpp':
            case '3gp': {
                mime = 'video/3gpp';
                break;
            }
            case 'mpeg':
            case 'mpg': {
                mime = 'video/mpeg';
                break;
            }
            case 'mov': {
                mime = 'video/quicktime';
                break;
            }
            case 'flv': {
                mime = 'video/x-flv';
                break;
            }
            case 'mng': {
                mime = 'video/x-mng';
                break;
            }
            case 'asx':
            case 'asf': {
                mime = 'video/x-ms-asf';
                break;
            }
            case 'wmv': {
                mime = 'video/x-ms-wmv';
                break;
            }
            case 'avi': {
                mime = 'video/x-msvideo';
                break;
            }
            case 'm4v':
            case 'mp4': {
                mime = 'video/mp4';
                break;
            }
            default: {
                mime = ext;
            }
        }
        return mime;
    }
}
