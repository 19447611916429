import { Pipe, PipeTransform } from '@angular/core';
import { InventoryCategory } from '../entities/workspace/inventory-category';
import { InventoryItem } from '../entities/workspace/inventory-item';

@Pipe({
  name: 'usedInventoryCategories'
})
export class UsedInventoryCategoriesPipe implements PipeTransform {

  transform(categories: Array<InventoryCategory>, items: Array<InventoryItem>): Array<InventoryCategory> {

    const tmpCategories: Array<InventoryCategory> = [];
    const tmpCategoriesId: Array<string> = [];

    for (const item of items) {
      if (!tmpCategoriesId.includes(item.inventory_category_id)) {
        tmpCategoriesId.push(item.inventory_category_id);
      }
    }

    for (const category of categories) {
      if (tmpCategoriesId.includes(category.id)) {
        tmpCategories.push(category);
      }
    }
    return tmpCategories;
  }
}
