import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { HelperService } from 'src/app/services/helper.service';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { PayrollBreakdownService } from 'src/app/services/companies/payroll-breakdown.service';
import { PayrollBreakdownView } from 'src/app/entities/workspace/payroll-breakdown-view';
import { PayrollPayment } from 'src/app/entities/workspace/payroll-payment';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { Payroll } from 'src/app/entities/companies/payroll';

declare const swal;
declare const moment;
@Component({
  selector: 'app-payroll-payments',
  templateUrl: './payroll-payments.component.html',
  styleUrls: ['./payroll-payments.component.scss']
})
export class PayrollPaymentsComponent implements OnInit {

  public betweenDates: BetweenDates;
  public employeeId: string;
  public employees: Employee[];
  public paginator: Paginator;
  public payrollList: PayrollBreakdownView[];
  public payrollDates: Payroll[];
  public swichEmployeeWD: boolean;
  public workDepartments: WorkDepartment[];
  public workDepartmentSelected: string;
  public selectedPeyroll: string;

  // totales
  public totalCommisionOwed: number;
  public totalBaseAmountOwed: number;
  public totalHourlyPayOwed: number;
  public totalBonusesOwed: number;
  public totalDeductions: number;
  public totalDraw: number;
  public totalOwed: number;
  // public totalBaseAmountOwed: number;

  constructor(
    private employeesService: EmployeesService,
    private helperService: HelperService,
    private payrollBreakdownService: PayrollBreakdownService,
    private workDepartmentService: WorkDepartmentsService
  ) {
    this.betweenDates = new BetweenDates();
    this.employeeId = 'ALL';
    this.employees = [];
    this.paginator = new Paginator();
    this.payrollList = [];
    this.swichEmployeeWD = false;
    this.workDepartments = [];
    this.workDepartmentSelected = 'ALL';
    this.payrollDates = [];
    this.selectedPeyroll = '';

    // totales
    this.totalCommisionOwed = 0;
    this.totalBaseAmountOwed = 0;
    this.totalHourlyPayOwed = 0;
    this.totalBonusesOwed = 0;
    this.totalDeductions = 0;
    this.totalDraw = 0;
    this.totalOwed = 0;
  }

  ngOnInit(): void {
    this.loadEmployees();
    this.loadWorkDepartments();
  }

  /**
     * recibe y asigna fechas para filtro
     * @param dataReturn
  */
  getFilterDates(dataReturn: BetweenDates) {
    this.betweenDates = dataReturn;
  }


  /**
     * Metodo que realiza los filtros de busqueda
     */
  search(): void {
    this.load();
  }

  load() {
    const data = {
      payroll_id: this.selectedPeyroll,
      id_from: this.swichEmployeeWD ? 'WD': 'E'
    };
    const idForFilter = this.swichEmployeeWD ? this.workDepartmentSelected: this.employeeId
    this.helperService.showLoadingMxpro360();
    this.payrollBreakdownService.getPyrollPayment(data, idForFilter)
    .then((response) => {
      this.payrollList = response;
      this.calculateTotals();
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });

    this.payrollBreakdownService.getPayrolls()
    .then((response) => {
      this.payrollDates = response;
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  calculateTotals() {
    this.totalCommisionOwed = 0;
    this.totalBaseAmountOwed = 0;
    this.totalHourlyPayOwed = 0;
    this.totalBonusesOwed = 0;
    this.totalDeductions = 0;
    this.totalDraw = 0;
    this.totalOwed = 0;
    for(const payroll of this.payrollList) {
      this.totalCommisionOwed += payroll.total_commissions;
      this.totalBaseAmountOwed += payroll.base_pay;
      this.totalHourlyPayOwed += payroll.total_hourly;
      this.totalBonusesOwed += payroll.total_bonuses;
      this.totalDeductions += payroll.total_deductions;
      this.totalDraw += payroll.total_draw;
      this.totalOwed += payroll.total;
    }
  }

  loadEmployees() {
    this.helperService.showLoadingMxpro360();
    this.employeesService
    .getAll()
    .then((response) => {
        this.employees = response;
        this.load();
    })
    .catch((error) => {
        console.error('error', error);
    }); 
  }

  loadWorkDepartments() {
    this.workDepartmentService
    .getAll()
    .then((response) => {
        this.workDepartments = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
  }

  payBreakdown(id){
    swal({
      title: 'Are you sure?',
      text: 'Do you want to Pay the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Pay!'
  })
      .then((result) => {
          if (result.value) {
            
          this.helperService.showLoadingMxpro360();

          this.payrollBreakdownService
            .pay(id)
            .then(() => {
              this.load();
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });

            
          }
      });
  }

  formatDate(date: number): string {
    return moment(date).format('MMM D');
  }
}
