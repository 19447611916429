import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';
import { LocalRoomClosets } from 'src/app/entities/workspace/local-room-closets';
import { HelperService } from 'src/app/services/helper.service';
import { LocalRoomClosetsService } from 'src/app/services/workspaces/local-room-closets-service.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';

declare const swal;

@Component({
  selector: 'app-local-room-closets',
  templateUrl: './local-room-closets.component.html',
  styleUrls: ['./local-room-closets.component.scss']
})
export class LocalRoomClosetsComponent implements OnInit {

  private constantsMessages = ConstantsMessages;
  public localRoomCategories: LocalRoomClosets[];
  public filterSearch: string;
  public workspaceSettings: WorkspaceSetting;
  public thereIsCustom: LocalRoomClosets[];

  constructor(
    private localRoomClosetsService: LocalRoomClosetsService,
    private helperService: HelperService,
    private settingsService: SettingsService,

  ) {
    this.localRoomCategories = [];
    this.filterSearch = '';
    this.workspaceSettings = new WorkspaceSetting();
    this.thereIsCustom = [];
  }

  ngOnInit(): void {
    this.load();
    this.loadWorkspaceSetting();

  }

  private load() {
    this.helperService.showLoadingMxpro360();
    this.localRoomClosetsService
        .getAll()
        .then((response) => {
            this.localRoomCategories = response;
            console.log('localRoomCategories => ', this.localRoomCategories);
            this.thereIsCustom = this.localRoomCategories.filter( x => x.is_custom);
            console.log('this.thereIsCustom ---->', this.thereIsCustom);
            
        })
        .catch((error) => {
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    }

  loadWorkspaceSetting() {
    this.settingsService
    .get()
    .then((responseSettings) => {
        this.workspaceSettings = responseSettings;
    });
  }

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.localRoomClosetsService
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Deleted!',
                'The record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('Error: ', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

  saveExtra() {
    this.helperService.showLoadingMxpro360();
    this.settingsService.save(this.workspaceSettings)
      .then(() => {

      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  isActiveChanged(e, row) {
    row.is_active = e;    
    console.log('this.localRoomCategory => ', row);
    this.helperService.showLoadingMxpro360();
    this.localRoomClosetsService
      .save(row)
      .then(response => {
        console.log('response => ', response);        
        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        this.load();
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
}
