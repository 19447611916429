<div class="layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Add Record
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/time-clock">
                                    Clock Time
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page"> Add Record </li>
                        </ol>
                    </nav>
                    <br>
                </div>
            </div>
        </div>
        
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">

                        <div class="col-md-6 col-sm-12">
                            <div class="form-group mb-3">
                                <label class="control-label"> Date </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                    </div>
                                    <input type="date" class="form-control" name="date" [(ngModel)]="dataForm.date" onfocus="this.showPicker()" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Date.
                                </small>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12">

                        </div>

                        <div class="col-md-6 col-sm-12">
                            <div class="form-group mb-3">
                                <label class="control-label"> Clock In </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                        </span>
                                    </div>
                                    <input type="time" class="form-control" name="time_in" [(ngModel)]="dataForm.time_in" onfocus="this.showPicker()" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select Clock In.
                                </small>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12">

                            <div class="form-group mb-3">
                                <label class="control-label">Clock Out</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                        </span>
                                    </div>
                                    <input type="time" class="form-control" name="time_in" [(ngModel)]="dataForm.time_out" onfocus="this.showPicker()" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select Clock Out.
                                </small>
                            </div>

                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-save ml-1 mt-3" type="submit">
                            Save
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>

</div>