import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { LocalRoomCategory } from 'src/app/entities/workspace/local-room-category';
import { HelperService } from 'src/app/services/helper.service';
import { LocalRoomCategoriesService } from 'src/app/services/workspaces/local-room-categories.service';

declare const swal;
@Component({
  selector: 'app-local-room-categories-form',
  templateUrl: './local-room-categories-form.component.html',
  styleUrls: ['./local-room-categories-form.component.scss']
})
export class LocalRoomCategoriesFormComponent implements OnInit {

  public localRoomCategory: LocalRoomCategory;
  public minimalTimeType: boolean;
  public moderateTimeType: boolean;
  public fullTimeType: boolean;
  public thereIsCustom: LocalRoomCategory[];

  constructor(
    private localRoomCategoriesService: LocalRoomCategoriesService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService
  ) {
    this.localRoomCategory = new LocalRoomCategory();
    this.minimalTimeType = true;
    this.moderateTimeType = true;
    this.fullTimeType = true;
    this.thereIsCustom = [];
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    // verficamos si se esta editando
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.load(params.id);
      }
    });
  }

  private load(id: string) {
    this.helperService.showLoadingMxpro360();
    this.localRoomCategoriesService
      .getById(id)
      .then((response) => {
        this.localRoomCategory = response;
        console.log('this.localRoomCategory => ', this.localRoomCategory);
        
        this.minimalTimeType = this.localRoomCategory.minimal_time_type == 'HOURS' ? true : false;
        this.moderateTimeType = this.localRoomCategory.moderate_time_type == 'HOURS' ? true : false;
        this.fullTimeType = this.localRoomCategory.full_time_type == 'HOURS' ? true : false;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  save() {
    console.log('this.minimalTimeType => ', this.minimalTimeType);

    this.localRoomCategory.minimal_time_type = this.minimalTimeType ? 'HOURS' : 'MINUTES';
    this.localRoomCategory.moderate_time_type = this.moderateTimeType ? 'HOURS' : 'MINUTES';
    this.localRoomCategory.full_time_type = this.fullTimeType ? 'HOURS' : 'MINUTES';

    
    this.helperService.showLoadingMxpro360();
    this.localRoomCategoriesService
      .save(this.localRoomCategory)
      .then(response => {
        console.log('response => ', response);
        
        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        this.helperService.goToWorkspaceRouterLink('/local-room-categories');
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  isCustom(e) {
    this.loadRoomCategory(true);
  }

  private loadRoomCategory(edit) {
    this.helperService.showLoadingMxpro360();
    this.localRoomCategoriesService
      .getAll()
      .then((response) => {
        console.log('response => ', response);
        this.thereIsCustom = response.filter(x => x.is_custom);
        console.log('this.thereIsCustom ---->', this.thereIsCustom);
        if (edit && this.thereIsCustom.length > 0) {
          if (this.localRoomCategory.id !== this.thereIsCustom[0].id) {
            console.log(' ***** there is already a custom ****;**');
            this.localRoomCategory.is_custom = false;
            swal(
              'Attention!',
              'There is already a custom',
              'warning'
            );
          }
        }   

      })
      .catch((error) => {

      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
