<div class=" layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="d-flex justify-content-between widget-heading">

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center">
                <h3 class="title-content-area m-0">
                    Inventory Items
                </h3>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center inventory-items-total">
                
                    <h4 class="border-total">
                        <i class="fa-solid fa-couch mr-2"></i>
                        <strong>Total Items:</strong>
                        <span>{{totalItems}}</span>
                    </h4>
                
                    <h4>
                        <i class="fa-solid fa-layer-group mr-2"></i>
                        <strong>
                            Total
    
                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                <span>CF:</span>
                            </ng-container>
    
                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">
                                <span>LBS:</span>
                            </ng-container>   
    
                        </strong> 
    
                        <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">{{totalCF}}</span>
                        <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">{{totalCF * cfLbsRatio}}</span>
                    </h4>
                
                
            </div>
           

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-sm-right text-center align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">

                    <div class="btn-group dropleft" role="group">
                        <button id="btnOutline" type="button" class="btn btn-success btn-add-room dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Add Room
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-chevron-down">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </button>
                        <div class="dropdown-menu overflow-default" aria-labelledby="btnOutline">
                            <a href="javascript:void(0);" class="dropdown-item" (click)="addCustomContainer()">
                                <i class="flaticon-dots mr-1"></i>
                                Custom Room
                            </a>
                            <div>
                                <hr class="dropdown-divider">
                            </div>
                            <a href="javascript:void(0);" class="dropdown-item" *ngFor="let room of inventoryCategories"
                                (click)="addContainer(room)">
                                <i class="flaticon-home-fill-1 mr-1"></i>
                                {{room.name}}
                            </a>
                        </div>
                    </div>
    
                    <button class="btn btn-save" (click)="saveInventory()">
                        Save
                    </button>

                </div>
            </div>

        </div>
        
    </div>
</div>
<!-- header -->



<div class=" layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="task-list-section">
            <div class="task-list-container" data-section="s-in-progress" data-connect="sorting">

                <ng-container *ngFor="let container of containers; let i = index">
                    <div class="connect-sorting">
                        <div class="task-container-header">
                            <h6 class="s-heading">
                                {{container.name | titlecase}}
                            </h6>
                            <div class="dropdown">
                                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink-1"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-more-horizontal">
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <a class="dropdown-item list-edit cursor-pointer" (click)="editContainer(i)">Edit</a>
                                    <a class="dropdown-item list-delete cursor-pointer"
                                        (click)="deleteContainer(i)">Delete</a>
                                    <a class="dropdown-item list-clear-all cursor-pointer" (click)="clearContainer(i)">Clear
                                        All</a>
                                </div>
                            </div>
                        </div>
                        <ul id="sortable_{{i}}" class="connectedSortable" attr.container="{{i}}">
                            <ng-container>
                                <li class="ui-state-default" attr.indexContainer="{{i}}" attr.idItem="{{item.id}}"
                                    *ngFor="let item of container.items ; let j = index">
                                    <div class="card-body">

                                        <div class="task-header">
                                            <h4 data-taskTitle="Creating a new Portfolio on Dribble">
                                                {{item.inventory_item.name}}                                            
                                            </h4>
                                            <div class="item-manual" *ngIf="item.inventory_item.customized">
                                                <i class="fa-solid fa-star"></i>
                                                <span>Customized Item</span>
                                            </div>
                                        </div>
                                        <div class="task-body">

                                            <div class="task-bottom color-text-inventory">
                                                <div class="tb-section-1">

                                                    <div class="cf-items-added">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-layers">
                                                            <polygon _ngcontent-yhr-c84=""
                                                                points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                            <polyline _ngcontent-yhr-c84="" points="2 17 12 22 22 17">
                                                            </polyline>
                                                            <polyline _ngcontent-yhr-c84="" points="2 12 12 17 22 12">
                                                            </polyline>
                                                        </svg>
                                                        <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">CF: {{item.inventory_item.cubic_feet}}</span>
                                                        <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: {{item.inventory_item.cubic_feet * cfLbsRatio}}</span>
                                                    </div>

                                                    <div class="ml-2 mr-3"> | </div>

                                                    <div class="qty-items-added">

                                                        <div class="mr-2">QTY:</div>

                                                        <span class="btn-minus" (click)="lessQty(i,j)">
                                                            <i class="fa-solid fa-circle-minus"></i>
                                                        </span>

                                                        <div class="quantity-item">{{item.quantity}}</div>

                                                        <span class="btn-plus" (click)="moreQty(i,j)">
                                                            <i class="fa-solid fa-circle-plus"></i>
                                                        </span>
                                                    
                                                    </div>

                                                </div>
                                                <div class="tb-section-2">
                                                    <svg (click)="editItemQuote(i, j)" xmlns="http://www.w3.org/2000/svg"
                                                        width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" class="feather feather-edit-2 s-task-edit">
                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z">
                                                        </path>
                                                    </svg>
                                                    <svg (click)="deleteItemQuote(i, j)"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-trash-2 s-task-delete">
                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                        <path
                                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                        </path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                        <div class="add-s-task">
                            <a class="add-item" (click)="addItemInventory(i)">
                                <i class="fa-solid fa-circle-plus"></i>
                                <span class="pl-2">Add Item</span>                                
                            </a>
                        </div>
                    </div>

                </ng-container>

            </div>
        </div>
    </div>
</div>
<!-- body -->



<!-- Modales -->
<div class="modal fade" id="deleteConformation" tabindex="-1" role="dialog" aria-labelledby="deleteConformationLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" id="deleteConformationLabel">
            <div class="modal-header">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-trash-2">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                        </path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                </div>
                <h5 class="modal-title" id="exampleModalLabel">Delete the task?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>If you delete the task it will be gone forever. Are you sure you want to proceed?</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" data-remove="task">Delete</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade inventoryItemModal" id="inventoryItemModal" #inventoryItemModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div>

                <div class="modal-body">
                    <div class="compose-box">

                        <div class="compose-content content-select" *ngIf="viewInventoryItemModal == 'SELECT'">
                            <h5 class="add-task-title">Select Item</h5>

                            <div class="row">
                                <div class="col-xl-9 col-lg-10 col-md-8">
                                    <div class="search-input-group-style input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-search">
                                                    <circle cx="11" cy="11" r="8"></circle>
                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="text" #searchField class="form-control" name="search_item"
                                            [(ngModel)]="searchItemByName" (click)="onKeydownSearch()" placeholder="Search here your item inventory"
                                            aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-2 col-md-4">
                                    <button type="button" class="btn btn-main-action mb-2 mt-1 btn-block"
                                        (click)="setCustomItem()">
                                        Add Custom Item
                                    </button>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-xl-3 col-lg-2 col-md-2 overflow-content category-list">

                                    <h5 class="sub-title">
                                        Category
                                    </h5>

                                    <button type="button" class="btn mb-2 btn-block"
                                        (click)="filterItemsByCategory(null)"
                                        [ngClass]="{'btn-success':searchItemByCategory == null, 'btn-outline-success':searchItemByCategory != null}">
                                        All
                                    </button>

                                    <button type="button" *ngFor="let category of inventoryCategories "
                                        class="btn mb-2 btn-block" (click)="filterItemsByCategory(category.id)"
                                        [ngClass]="{'btn-success':searchItemByCategory == category.id, 'btn-outline-success':searchItemByCategory != category.id}">
                                        {{category.name}}
                                    </button>

                                </div>

                                <div class="col-xl-6 col-lg-8 col-md-8 overflow-content scroll-default">

                                    <h5 class="sub-title">
                                        Items
                                    </h5>

                                    <table class="table table-bordered table-hover mb-4">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Item
                                                </th>
                                                <th class="text-center">
                                                    Volume 
                                                    <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">CF</span>
                                                    <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container
                                                *ngFor="let item of inventoryItemsFiltered | search:searchItemByName | async">
                                                <!-- [hidden]="!(searchItemByCategory == item.inventory_category_id  || searchItemByCategory== null)" -->
                                                <tr (click)="setItemTemp(item)">
                                                    <td>
                                                        {{item.name}}
                                                    </td>
                                                    <td class="text-center">
                                                        <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">{{item.cubic_feet}}</span>
                                                        <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">{{item.cubic_feet * cfLbsRatio}}</span>
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>
                                </div>

                                <div class="col-xl-3 col-lg-2 col-md-2 overflow-content container-list" #itemsContainer>

                                    <h5 class="sub-title" *ngIf="containers[itemContainerPath.container_index]">
                                        {{containers[itemContainerPath.container_index].name}}
                                    </h5>

                                    <table class="table table-bordered table-hover mb-4">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Item
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="containers[itemContainerPath.container_index]">
                                            <ng-container
                                                *ngFor="let item of containers[itemContainerPath.container_index].items; let i = index">
                                                <!-- [hidden]="!(searchItemByCategory == item.inventory_category_id  || searchItemByCategory== null)" -->
                                                <tr>
                                                    <td>
                                                        <div class="item-name">
                                                            {{item.inventory_item.name}}
                                                        </div>
                                                    
                                                        <div class="d-flex align-items-center mt-1">
                                                            <span class="btn-minus" (click)="lessQty(itemContainerPath.container_index,i)">
                                                                <i class="fa-solid fa-circle-minus"></i>
                                                            </span>
    
                                                            <input type="number" class="number-input quantity-item" name="quantityss{{item.id}}{{i}}" [(ngModel)]="item.quantity">
                                                            
                                                            <span class="btn-plus" (click)="moreQty(itemContainerPath.container_index,i)">
                                                                <i class="fa-solid fa-circle-plus"></i>
                                                            </span>

                                                            <button class="btn btn-danger btn-remove-item" (click)="deleteItemQuote(itemContainerPath.container_index,i)">
                                                                <i class="fa-solid fa-trash-can"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                        <div class="compose-content content-quantity" *ngIf="viewInventoryItemModal == 'QTY'">

                            <h5 class="add-task-title">Update Inventory Item</h5>

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="start-date" class="">Item</label>
                                    <div class="d-flex event-title">
                                        <input type="text" placeholder="Name item" class="form-control"
                                            name="item_name_qty" [(ngModel)]="dataFormItem.inventory_item.name"
                                            readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group start-date">
                                        <label for="start-date" class="">Volume 
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">CF</ng-container>
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">LBS</ng-container>
                                        </label>
                                        <div class="d-flex">                                           
                                            <input placeholder="Volume in CF" class="form-control" type="number"
                                                name="item_cf_qty" [value]="dataFormItem.inventory_item.cubic_feet"
                                                readonly *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                           
                                            <input placeholder="Volume in CF" class="form-control" type="number"
                                                name="item_cf_qty" [value]="dataFormItem.inventory_item.cubic_feet * cfLbsRatio"
                                                readonly *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Quantity</label>
                                        <div class="d-flex">
                                            <input placeholder="Quantity from items" type="number" class="form-control"
                                                [(ngModel)]="dataFormItem.quantity" name="item_quantity_qty" min="1"
                                                required>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12">
                                    &nbsp;
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Packed By</label>
                                        <div class="d-flex">
                                            <select id="type" [(ngModel)]="dataFormItem.inventory_item.packed_by" name="pg_type" class="form-control">
                                                <option value="CUSTOMER">Customer</option>
                                                <option value="CARRIER">Carrier</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <strong>Total Volume 
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">CF</ng-container>
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">LBS</ng-container>
                                            : </strong>
                                        <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                            {{dataFormItem.inventory_item.cubic_feet * dataFormItem.quantity}}
                                        </ng-container>
                                        
                                        <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">
                                            {{(dataFormItem.inventory_item.cubic_feet * cfLbsRatio) * dataFormItem.quantity}}
                                        </ng-container>
                                        
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div class="compose-content content-quantity" *ngIf="viewInventoryItemModal == 'CUSTOM_ITEM'">

                            <h5 class="add-task-title">Custom Item</h5>

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="start-date" class="">Item</label>
                                    <div class="d-flex event-title">
                                        <input type="text" placeholder="Name item" class="form-control"
                                            name="item_name_qty" [(ngModel)]="dataFormItem.inventory_item.name">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group start-date">
                                        <label for="start-date" class="">Volume 
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">CF</ng-container>
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">LBS</ng-container>
                                        </label>
                                        <div class="d-flex">
                                            <input placeholder="Volume in CF" class="form-control" type="number"
                                                name="item_cf_qty" [(ngModel)]="dataFormItem.inventory_item.cubic_feet">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Quantity</label>
                                        <div class="d-flex">
                                            <input placeholder="Quantity from items" type="number" class="form-control"
                                                [(ngModel)]="dataFormItem.quantity" name="item_quantity_qty" min="1"
                                                required>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <p>
                                        <strong>Total Volume 
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">CF</ng-container>
                                            <ng-container *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">LBS</ng-container>
                                            : </strong>
                                        {{dataFormItem.inventory_item.cubic_feet * dataFormItem.quantity}}
                                    </p>
                                </div>

                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Packed By</label>
                                        <div class="d-flex">
                                            <select id="type" [(ngModel)]="dataFormItem.inventory_item.packed_by" name="pg_type" class="form-control">
                                                <option value="CUSTOMER">Customer</option>
                                                <option value="CARRIER">Carrier</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" (click)="closeModalInventory()">
                        Cancel
                    </button>
                    <button data-btnfn="addTask" class="btn btn-save" *ngIf="viewInventoryItemModal != 'SELECT'"
                        (click)="saveItemQuote(true)" type="submit">
                        Save Item
                    </button>
                    <div data-btnfn="addTask" class="btn btn-save" *ngIf="viewInventoryItemModal == 'SELECT'"
                        (click)="saveAll()">
                        Save
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<div class="modal fade customContainerModal" #customContainerModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #entityFormContainer="ngForm" (ngSubmit)="saveContainer()" ngNativeValidate>

                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">

                            <h5 *ngIf="dataFormContainer.id == null">
                                Add Custom Room
                            </h5>
                            <h5 *ngIf="dataFormContainer.id != null">
                                Edit Room
                            </h5>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="list-title">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-list">
                                            <line x1="8" y1="6" x2="21" y2="6"></line>
                                            <line x1="8" y1="12" x2="21" y2="12"></line>
                                            <line x1="8" y1="18" x2="21" y2="18"></line>
                                            <line x1="3" y1="6" x2="3" y2="6"></line>
                                            <line x1="3" y1="12" x2="3" y2="12"></line>
                                            <line x1="3" y1="18" x2="3" y2="18"></line>
                                        </svg>
                                        <input id="s-list-name" type="text" [(ngModel)]="dataFormContainer.name"
                                            placeholder="Name here" class="form-control" name="name_container"
                                            autocomplete="off" required>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal" type="button">
                        Cancel
                    </button>
                    <button class="btn btn-save" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>