<div class="layout-top-spacing">

    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="!clockEmployees.id"> New </span> 
                    <span *ngIf="clockEmployees.id"> Update </span> 
                    Users
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a appCompanyRouterLink="/payroll-clock-users">Clock Users</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="!clockEmployees.id">New</li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="clockEmployees.id">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button class="btn btn-main-action ml-1 mt-2" type="submit">
                        Save
                    </button>
                </div>
            </div>

        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
                <div class="row">
                    
                    <div class="col-6">
                        <div class="form-group mb-3">
                            <label class="control-label">User</label>
                            <select name="employee_id" [(ngModel)]="clockEmployees.employee_id" id="employee_id" class="form-control" required>
                                <option value="">All</option>
                                <ng-container *ngFor="let user of employees">
                                    <option value="{{user.id}}">
                                        {{user.name | titlecase}}
                                    </option>
                                </ng-container>
                            </select>
                            <small class="form-text text-muted mb-4">Select an User.</small>
                        </div>
                        <div class="form-group mb-3">
                            <label for="hourly_rate">Hourly Rate</label>
                            <div class="input-group mb-1">
                                <input type="number" class="form-control" name="hourly_rate" placeholder="Enter Hourly Rate" [(ngModel)]="clockEmployees.hourly_rate" required>
                            </div>
                            <small class="form-text text-muted mb-4">Enter Hourly Rate.</small>
                        </div>
                        <div class="form-group mb-3">
                            <label for="hourly_rate">Overtime Hourly Rate</label>
                            <div class="input-group mb-1">
                                <input type="number" class="form-control" step="0.01" name="overtime_hourly_rate" placeholder="Enter Hourly Rate" [(ngModel)]="clockEmployees.overtime_hourly_rate" required>
                            </div>
                            <small class="form-text text-muted mb-4">Enter Hourly Rate.</small>
                        </div>
                    </div>

                    <div class="col-6">

                        <div class="form-group col-md-6">
                            <label>Off Site Allowed</label>
                            <div class="toggle-switch mt-2">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="off_site_allowed" name="offside_allowed" [(ngModel)]="clockEmployees.offside_allowed">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="off_site_allowed" class="checkbox-label">
                                    <span *ngIf="clockEmployees.offside_allowed" class="active">Active</span>
                                    <span *ngIf="!clockEmployees.offside_allowed" class="disabled">Disabled</span>
                                </label>
                            </div>
                            <small class="form-text text-muted mb-4 mt-3">Active/Disabled Off Site Allowed.</small>
                        </div>

                        <div class="form-group col-md-6 mt-26">
                            <label>GEO</label>
                            <div class="toggle-switch mt-2">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="geo_requerid" name="geo_requerid" [(ngModel)]="clockEmployees.geo_requerid">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="geo_requerid" class="checkbox-label">
                                    <span *ngIf="clockEmployees.geo_requerid" class="active">Active</span>
                                    <span *ngIf="!clockEmployees.geo_requerid" class="disabled">Disabled</span>
                                </label>
                            </div>
                            <small class="form-text text-muted mb-4 mt-3">Active/Disabled GEO.</small>
                        </div>

                        <div class="form-group col-md-6 mt-26">
                            <label>Allow Overtime</label>
                            <div class="toggle-switch mt-2">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="allow_overtime" name="allow_overtime" [(ngModel)]="clockEmployees.allow_overtime">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="allow_overtime" class="checkbox-label">
                                    <span *ngIf="clockEmployees.allow_overtime" class="active">Active</span>
                                    <span *ngIf="!clockEmployees.allow_overtime" class="disabled">Disabled</span>
                                </label>
                            </div>
                            <small class="form-text text-muted mb-4 mt-3">Active/Disabled Allow Overtime.</small>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    </form>

</div>