import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TariffVersion } from 'src/app/entities/workspace/tariff-version';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TariffVersionsService {

  constructor(private http: HttpClient) { }

    getAll(): Promise<Array<TariffVersion>> {
        return this.http.get<Array<TariffVersion>>(environment.api.workspace + '/tariff-versions').toPromise();
    }

    getById(id: string): Promise<TariffVersion> {
        return this.http.get<TariffVersion>(environment.api.workspace + '/tariff-versions/' + id).toPromise();
    }

    save(dataForm: TariffVersion) {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: TariffVersion) {
        return this.http.post(environment.api.workspace + '/tariff-versions', dataForm).toPromise();
    }

    edit(dataForm: TariffVersion) {
        return this.http.put(environment.api.workspace + '/tariff-versions/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/tariff-versions/' + id).toPromise();
    }
}
