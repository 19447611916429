export class File {
    id: string;
    name: string;
    display_name: string;
    mime_type: string;
    created: number;

    constructor(){
        this.id = null;
        this.name = null;
        this.display_name = null;
        this.mime_type = null;
        this.created = null;
    }
}
