import { ConstantsMessages } from 'src/app/constants-messages';
import { SmsTemplate } from 'src/app/entities/workspace/sms-template';
import { SmsTemplatesService } from 'src/app/services/workspaces/sms-templates.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-sms-templates-form',
    templateUrl: './sms-templates-form.component.html',
    styleUrls: ['./sms-templates-form.component.scss']
})
export class SmsTemplatesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: SmsTemplate;

    constructor(
        private smsTemplatesService: SmsTemplatesService,
        private currentRoute: ActivatedRoute,
        private router: Router,
        private helperService: HelperService
    ) {
        this.dataForm = new SmsTemplate();
    }

    ngOnInit(): void {
       
    }

    ngAfterViewInit(): void{
         // verficamos si se esta editando
         this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.smsTemplatesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }
    save() {

        this.helperService.showLoadingMxpro360();
        this.smsTemplatesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/sms-templates');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
