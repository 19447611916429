import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
// https://gitlab.com/catamphetamine/libphonenumber-js

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {

    transform(phoneValue: number | string, country: string = "US"): any {
        try {
            const phoneNumber = parsePhoneNumber(String(phoneValue), country as CountryCode);
            return phoneNumber.formatNational();
        } catch (error) {
            //console.error(error);
            return phoneValue;
        }
    }
}
