import { AccountWorkspacesComponent } from './pages/accounts/account-workspaces/account-workspaces.component';
import { ActiveAgentsComponent } from './pages/companies/active-agents/active-agents.component';
import { AdditionalStopRulesComponent } from './pages/workspaces/additional-stop-rules/additional-stop-rules.component';
import { AdditionalStopRulesFormComponent } from './pages/workspaces/additional-stop-rules-form/additional-stop-rules-form.component';
import { AdvertisersComponent } from './pages/workspaces/advertisers/advertisers.component';
import { AdvertisersFormComponent } from './pages/workspaces/advertisers-form/advertisers-form.component';
import { AdvertisersViewComponent } from './pages/workspaces/advertisers-view/advertisers-view.component';
import { ArchiveDeliveryDocumentsReportComponent } from './pages/workspaces/archive-delivery-documents-report/archive-delivery-documents-report.component';
import { ArchivePickupDocumentsReportComponent } from './pages/workspaces/archive-pickup-documents-report/archive-pickup-documents-report.component';
import { AutomationsComponent } from './pages/companies/automations/automations.component';
import { AutomationsFormComponent } from './pages/companies/automations-form/automations-form.component';
import { BadLeadsReportComponent } from './pages/workspaces/bad-leads-report/bad-leads-report.component';
import { BlackoutMoveDatesComponent } from './pages/workspaces/blackout-move-dates/blackout-move-dates.component';
import { BlackoutMoveDatesFormComponent } from './pages/workspaces/blackout-move-dates-form/blackout-move-dates-form.component';
import { BoardJobsComponent } from './pages/workspaces/board-jobs/board-jobs.component';
import { BoardJobsFormComponent } from './pages/workspaces/board-jobs-form/board-jobs-form.component';
import { BoardJobsViewComponent } from './pages/workspaces/board-jobs-view/board-jobs-view.component';
import { BuildPostingLeadsComponent } from './pages/workspaces/build-posting-leads/build-posting-leads.component';
import { BuyNumbersComponent } from './pages/workspaces/buy-numbers/buy-numbers.component';
import { CalendarComponent } from './pages/workspaces/calendar/calendar.component';
import { CalendarFreightComponent } from './pages/workspaces/calendar-freight/calendar-freight.component';
import { CanceledJobsListComponent } from './pages/workspaces/canceled-jobs-list/canceled-jobs-list.component';
import { CarrierJobsReportComponent } from './pages/workspaces/carrier-jobs-report/carrier-jobs-report.component';
import { CarriersComponent } from './pages/workspaces/carriers/carriers.component';
import { CarriersCoverageMapComponent } from './pages/workspaces/carriers-coverage-map/carriers-coverage-map.component';
import { CarriersFormComponent } from './pages/workspaces/carriers-form/carriers-form.component';
import { CarriersViewComponent } from './pages/workspaces/carriers-view/carriers-view.component';
import { CcProcessingComponent } from './pages/companies/cc-processing/cc-processing.component';
import { CommissionSettingsComponent } from './pages/companies/commission-settings/commission-settings.component';
import { CommissionSettingsFormComponent } from './pages/companies/commission-settings-form/commission-settings-form.component';
import { CommissionSettingsViewComponent } from './pages/companies/commission-settings-view/commission-settings-view.component';
import { CompaniesComponent } from './pages/workspaces/companies/companies.component';
import { CompaniesFormComponent } from './pages/workspaces/companies-form/companies-form.component';
import { CompanySalesPerformenceComponent } from './pages/workspaces/company-sales-performence/company-sales-performence.component';
import { CreditCardComponent } from './pages/public/credit-card/credit-card.component';
import { CustomersComponent } from './pages/companies/customers/customers.component';
import { CustomersFormComponent } from './pages/companies/customers-form/customers-form.component';
import { CustomersViewComponent } from './pages/companies/customers-view/customers-view.component';
import { CustomLabelsComponent } from './pages/companies/custom-labels/custom-labels.component';
import { CustomLabelsViewComponent } from './pages/companies/custom-labels-view/custom-labels-view.component';
import { DashboardComponent } from './pages/companies/dashboard/dashboard.component';
import { DashboardWorkspaceComponent } from './pages/workspaces/dashboard-workspace/dashboard-workspace.component';
import { DeductibleLevelsComponent } from './pages/workspaces/deductible-levels/deductible-levels.component';
import { DeductibleLevelsFormComponent } from './pages/workspaces/deductible-levels-form/deductible-levels-form.component';
import { DeliveryDocumentsReportComponent } from './pages/workspaces/delivery-documents-report/delivery-documents-report.component';
import { OldDialerComponent } from './pages/companies/dialer/dialer.component';
import { DispatchContactsComponent } from './pages/workspaces/dispatch-contacts/dispatch-contacts.component';
import { DispatchContactsFormComponent } from './pages/workspaces/dispatch-contacts-form/dispatch-contacts-form.component';
import { DocumentsTypesComponent } from './pages/companies/documents-types/documents-types.component';
import { DocumentsTypesFormComponent } from './pages/companies/documents-types-form/documents-types-form.component';
import { DocumentsTypesViewComponent } from './pages/companies/documents-types-view/documents-types-view.component';
import { DriverInvoicesComponent } from './pages/workspaces/driver-invoices/driver-invoices.component';
import { DriverInvoicesFormComponent } from './pages/workspaces/driver-invoices-form/driver-invoices-form.component';
import { DriverInvoicesViewComponent } from './pages/workspaces/driver-invoices-view/driver-invoices-view.component';
import { EmailCampaignsComponent } from './pages/companies/email-campaigns/email-campaigns.component';
import { EmailCampaignsFormComponent } from './pages/companies/email-campaigns-form/email-campaigns-form.component';
import { EmailTemplatesComponent } from './pages/companies/email-templates/email-templates.component';
import { EmployeesCompaniesComponent } from './pages/companies/employees-companies/employees-companies.component';
import { EmployeesComponent } from './pages/workspaces/employees/employees.component';
import { EmployeesFormComponent } from './pages/workspaces/employees-form/employees-form.component';
import { EmployeesViewComponent } from './pages/workspaces/employees-view/employees-view.component';
import { EstimatesComponent } from './pages/companies/estimates/estimates.component';
import { EstimatesDetailsComponent } from './pages/companies/estimates-details/estimates-details.component';
import { EstimatesEmailComponent } from './pages/companies/estimates-email/estimates-email.component';
import { EstimatesEmailTemplatesFormComponent } from './pages/companies/email-templates-form/email-templates-form.component';
import { EstimatesEmailTemplatesViewComponent } from './pages/companies/email-templates-view/email-templates-view.component';
import { EstimatesFormComponent } from './pages/companies/estimates-form/estimates-form.component';
import { EstimatesGuard } from './guards/estimates.guard';
import { EstimatesInventoryItemsComponent } from './pages/companies/estimates-inventory-items/estimates-inventory-items.component';
import { EstimatesLogsComponent } from './pages/companies/estimates-logs/estimates-logs.component';
import { EstimatesOperationsComponent } from './pages/companies/estimates-operations/estimates-operations.component';
import { EstimatesPackingMaterialsComponent } from './pages/companies/estimates-packing-materials/estimates-packing-materials.component';
import { EstimatesPaymentsComponent } from './pages/companies/estimates-payments/estimates-payments.component';
import { EstimatesReportComponent } from './pages/companies/estimates-report/estimates-report.component';
import { EstimatesSignaturesComponent } from './pages/companies/estimates-signatures/estimates-signatures.component';
import { EstimatesStorageComponent } from './pages/companies/estimates-storage/estimates-storage.component';
import { EstimatesTicketsComponent } from './pages/companies/estimates-tickets/estimates-tickets.component';
import { EstimatesTicketsFormComponent } from './pages/companies/estimates-tickets-form/estimates-tickets-form.component';
import { EstimatesViewComponent } from './pages/companies/estimates-view/estimates-view.component';
import { ExportLeadsComponent } from './pages/companies/export-leads/export-leads.component';
import { FollowUpRemindersComponent } from './pages/companies/follow-up-reminders/follow-up-reminders.component';
import { GeneralSettingLeadRotationComponent } from './pages/companies/general-setting-lead-rotation/general-setting-lead-rotation.component';
import { GeneralSettingsComponent } from './pages/companies/general-settings/general-settings.component';
import { InventoryCategoriesComponent } from './pages/workspaces/inventory-categories/inventory-categories.component';
import { InventoryCategoriesFormComponent } from './pages/workspaces/inventory-categories-form/inventory-categories-form.component';
import { InventoryItemsComponent } from './pages/workspaces/inventory-items/inventory-items.component';
import { InventoryItemsFormComponent } from './pages/workspaces/inventory-items-form/inventory-items-form.component';
import { InvoicesComponent } from './pages/companies/invoices/invoices.component';
import { JobsCalendarComponent } from './pages/companies/jobs-calendar/jobs-calendar.component';
import { JobsComponent } from './pages/companies/jobs/jobs.component';
import { JobsReportComponent } from './pages/companies/jobs-report/jobs-report.component';
import { LeadForwardingLinksComponent } from './pages/workspaces/lead-forwarding-links/lead-forwarding-links.component';
import { LeadForwardingLinksFormComponent } from './pages/workspaces/lead-forwarding-links-form/lead-forwarding-links-form.component';
import { LeadsAdvertisersReportComponent } from './pages/workspaces/leads-advertisers-report/leads-advertisers-report.component';
import { LeadsComponent } from './pages/companies/leads/leads.component';
import { LeadsEsimatesResultsComponent } from './pages/companies/leads-esimates-results/leads-esimates-results.component';
import { LeadsFormComponent } from './pages/companies/leads-form/leads-form.component';
import { LeadsReportComponent } from './pages/workspaces/leads-report/leads-report.component';
import { LeadsViewComponent } from './pages/companies/leads-view/leads-view.component';
import { LiabilityAmountsComponent } from './pages/workspaces/liability-amounts/liability-amounts.component';
import { LiabilityAmountsFormComponent } from './pages/workspaces/liability-amounts-form/liability-amounts-form.component';
import { LiabilityDeductibleRatesComponent } from './pages/workspaces/liability-deductible-rates/liability-deductible-rates.component';
import { LoadLeadsByExcelComponent } from './pages/workspaces/load-leads-by-excel/load-leads-by-excel.component';
import { LocalRoomCategoriesComponent } from './pages/workspaces/local-room-categories/local-room-categories.component';
import { LocalRoomCategoriesFormComponent } from './pages/workspaces/local-room-categories/local-room-categories-form/local-room-categories-form.component';
import { LocalRoomClosetsComponent } from './pages/workspaces/local-room-closets/local-room-closets.component';
import { LocalRoomClosetsFormComponent } from './pages/workspaces/local-room-closets/local-room-closets-form/local-room-closets-form.component';
import { LocalTariffSettingsComponent } from './pages/workspaces/local-tariff-settings/local-tariff-settings.component';
import { LocalTariffSettingsFormComponent } from './pages/workspaces/local-tariff-settings-form/local-tariff-settings-form.component';
import { LoginComponent } from './pages/public/login/login.component';
import { MovingClaimsComponent } from './pages/companies/moving-claims/moving-claims.component';
import { MovingCompletionsComponent } from './pages/companies/moving-completions/moving-completions.component';
import { MovingItemsComponent } from './pages/companies/moving-items/moving-items.component';
import { MovingItemsFormComponent } from './pages/companies/moving-items-form/moving-items-form.component';
import { MovingLabelsComponent } from './pages/companies/moving-labels/moving-labels.component';
import { MovingMediaFilesComponent } from './pages/companies/moving-media-files/moving-media-files.component';
import { MovingOutsourcingsComponent } from './pages/companies/moving-outsourcings/moving-outsourcings.component';
import { MyLeadsComponent } from './pages/companies/my-leads/my-leads.component';
import { MyPerformsComponent } from './pages/companies/my-performs/my-performs.component';
import { NgModule } from '@angular/core';
import { PackingMaterialsComponent } from './pages/workspaces/packing-materials/packing-materials.component';
import { PackingMaterialsFormComponent } from './pages/workspaces/packing-materials-form/packing-materials-form.component';
import { PageNotFoundComponent } from './pages/public/page-not-found/page-not-found.component';
import { PanelLayoutComponent } from './components/panel-layout/panel-layout.component';
import { PasswordRecoveryComponent } from './pages/public/password-recovery/password-recovery.component';
import { PaymentGatewaySettingsComponent } from './pages/companies/payment-gateway-settings/payment-gateway-settings.component';
import { PaymentGatewaySettingsFormComponent } from './pages/companies/payment-gateway-settings-form/payment-gateway-settings-form.component';
import { PaymentsComponent } from './pages/companies/payments/payments.component';
import { PaymentsMethodsComponent } from './pages/companies/payments-methods/payments-methods.component';
import { PaymentsMethodsFormComponent } from './pages/companies/payments-methods-form/payments-methods-form.component';
import { PaymentsViewComponent } from './pages/companies/payments-view/payments-view.component';
import { PayrollBalancesComponent } from './pages/companies/payroll-balances/payroll-balances.component';
import { PayrollBalancesFormComponent } from './pages/companies/payroll-balances-form/payroll-balances-form.component';
import { PayrollClockLocationsComponent } from './pages/companies/payroll-clock-locations/payroll-clock-locations.component';
import { PayrollClockLocationsFormComponent } from './pages/companies/payroll-clock-locations-form/payroll-clock-locations-form.component';
import { PayrollClockUsersComponent } from './pages/companies/payroll-clock-users/payroll-clock-users.component';
import { PayrollClockUsersFormComponent } from './pages/companies/payroll-clock-users-form/payroll-clock-users-form.component';
import { PayrollPaymentsComponent } from './pages/companies/payroll-payments/payroll-payments.component';
import { PayrollPaymentsFormComponent } from './pages/companies/payroll-payments-form/payroll-payments-form.component';
import { PayrollPaymentsViewComponent } from './pages/companies/payroll-payments-view/payroll-payments-view.component';
import { PayrollSettingsComponent } from './pages/companies/payroll-settings/payroll-settings.component';
import { PayrollSettingsFormComponent } from './pages/companies/payroll-settings-form/payroll-settings-form.component';
import { PayrollTimeClockComponent } from './pages/companies/payroll-time-clock/payroll-time-clock.component';
import { PayrollTimeClockManualComponent } from './pages/companies/payroll-time-clock-manual/payroll-time-clock-manual.component';
import { PendingQAJobsComponent } from './pages/companies/pending-qajobs/pending-qajobs.component';
import { PeriodsComponent } from './pages/workspaces/periods/periods.component';
import { PeriodsFormComponent } from './pages/workspaces/periods-form/periods-form.component';
import { PhoneCdrComponent } from './pages/workspaces/phone-cdr/phone-cdr.component';
import { PhoneIvrsComponent } from './pages/workspaces/phone-ivrs/phone-ivrs.component';
import { PhoneIvrsFormComponent } from './pages/workspaces/phone-ivrs-form/phone-ivrs-form.component';
import { PhoneLinesComponent } from './pages/workspaces/phone-lines/phone-lines.component';
import { PhoneLinesFormComponent } from './pages/workspaces/phone-lines-form/phone-lines-form.component';
import { PhoneQueuesComponent } from './pages/workspaces/phone-queues/phone-queues.component';
import { PhoneQueuesFormComponent } from './pages/workspaces/phone-queues-form/phone-queues-form.component';
import { PhoneRingGroupsComponent } from './pages/workspaces/phone-ring-groups/phone-ring-groups.component';
import { PhoneRingGroupsFormComponent } from './pages/workspaces/phone-ring-groups-form/phone-ring-groups-form.component';
import { PickupDocumentsReportComponent } from './pages/workspaces/pickup-documents-report/pickup-documents-report.component';
import { ProfitLossComponent } from './pages/companies/profit-loss/profit-loss.component';
import { QuickInventoryEstimateComponent } from './pages/companies/quick-inventory-estimate/quick-inventory-estimate.component';
import { RecurringCustomersComponent } from './pages/companies/recurring-customers/recurring-customers.component';
import { RegisterAccountCompanyComponent } from './pages/public/register-account-company/register-account-company.component';
import { RegisterComponent } from './pages/public/register/register.component';
import { ReportQAComponent } from './pages/companies/report-qa/report-qa.component';
import { ReportQAComponent as ReportQAComponentWS} from './pages/workspaces/report-qa/report-qa.component';
import { ReportSalesCommissionsComponent } from './pages/companies/report-sales-commissions/report-sales-commissions.component';
import { RolesComponent } from './pages/workspaces/roles/roles.component';
import { RolesFormComponent } from './pages/workspaces/roles-form/roles-form.component';
import { RouteRatesComponent } from './pages/workspaces/route-rates/route-rates.component';
import { RouteRatesMapComponent } from './pages/workspaces/route-rates-map/route-rates-map.component';
import { RouterModule, Routes } from '@angular/router';
import { SalesmanPerformsComponent } from './pages/companies/salesman-performs/salesman-performs.component';
import { SettingsComponent } from './pages/workspaces/settings/settings.component';
import { SmsCampaignsComponent } from './pages/companies/sms-campaigns/sms-campaigns.component';
import { SmsCampaignsFormComponent } from './pages/companies/sms-campaigns-form/sms-campaigns-form.component';
import { SmsTemplatesComponent } from './pages/companies/sms-templates/sms-templates.component';
import { SmsTemplatesFormComponent } from './pages/companies/sms-templates-form/sms-templates-form.component';
import { StorageCompaniesComponent } from './pages/workspaces/storage-companies/storage-companies.component';
import { StorageCompaniesFormComponent } from './pages/workspaces/storage-companies-form/storage-companies-form.component';
import { StorageCompaniesViewComponent } from './pages/workspaces/storage-companies-view/storage-companies-view.component';
import { StorageInvoicesComponent } from './pages/companies/storage-invoices/storage-invoices.component';
import { StorageInvoicesWorkdpaceComponent } from './pages/workspaces/storage-invoices-workdpace/storage-invoices-workdpace.component';
import { StorageManagerComponent } from './pages/workspaces/storage-manager/storage-manager.component';
import { SuppliersComponent } from './pages/workspaces/suppliers/suppliers.component';
import { SuppliersFormComponent } from './pages/workspaces/suppliers-form/suppliers-form.component';
import { SuppliersViewComponent } from './pages/workspaces/suppliers-view/suppliers-view.component';
import { TariffAdditionalConceptsComponent } from './pages/workspaces/tariff-additional-concepts/tariff-additional-concepts.component';
import { TariffAdditionalConceptsFormComponent } from './pages/workspaces/tariff-additional-concepts-form/tariff-additional-concepts-form.component';
import { TariffVersionsComponent } from './pages/workspaces/tariff-versions/tariff-versions.component';
import { TariffVersionsFormComponent } from './pages/workspaces/tariff-versions-form/tariff-versions-form.component';
import { TariffVersionsSettingsComponent } from './pages/workspaces/tariff-versions-settings/tariff-versions-settings.component';
import { TicketAssignmentRulesComponent } from './pages/companies/ticket-assignment-rules/ticket-assignment-rules.component';
import { TicketAssignmentRulesFormComponent } from './pages/companies/ticket-assignment-rules-form/ticket-assignment-rules-form.component';
import { TicketsComponent } from './pages/companies/tickets/tickets.component';
import { TicketsViewComponent } from './pages/companies/tickets-view/tickets-view.component';
import { TicketsWorkspaceComponent } from './pages/workspaces/tickets-workspace/tickets-workspace.component';
import { TicketTypeComponent } from './pages/companies/ticket-type/ticket-type.component';
import { TicketTypeFormComponent } from './pages/companies/ticket-type-form/ticket-type-form.component';
import { UnsubscribeComponent } from './pages/public/unsubscribe/unsubscribe.component';
import { UpdateProfileComponent } from './pages/accounts/update-profile/update-profile.component';
import { VehiclesComponent } from './pages/workspaces/vehicles/vehicles.component';
import { VehiclesFormComponent } from './pages/workspaces/vehicles-form/vehicles-form.component';
import { VolumesComponent } from './pages/workspaces/volumes/volumes.component';
import { VolumesFormComponent } from './pages/workspaces/volumes-form/volumes-form.component';
import { WarehousesComponent } from './pages/workspaces/warehouses/warehouses.component';
import { WarehousesFaddJobsComponent } from './pages/workspaces/warehouses-fadd-jobs/warehouses-fadd-jobs.component';
import { WarehousesFormComponent } from './pages/workspaces/warehouses-form/warehouses-form.component';
import { WorkDepartmentsComponent } from './pages/companies/work-departments/work-departments.component';
import { WorkDepartmentsFormComponent } from './pages/companies/work-departments-form/work-departments-form.component';
import { WorkDepartmentsViewComponent } from './pages/companies/work-departments-view/work-departments-view.component';
import { WorkspacesBillingComponent } from './pages/accounts/workspaces-billing/workspaces-billing.component';
import { WorkspacesBillingInvoicesComponent } from './pages/accounts/workspaces-billing-invoices/workspaces-billing-invoices.component';
import { WorkspacesBillingProcessPaymentComponent } from './pages/accounts/workspaces-billing-process-payment/workspaces-billing-process-payment.component';
import { WorkspacesUnauthorizedOutstandingInvoicesComponent } from './pages/accounts/workspaces-unauthorized-outstanding-invoices/workspaces-unauthorized-outstanding-invoices.component';
import { ZonesComponent } from './pages/workspaces/zones/zones.component';
import { ZonesFormComponent } from './pages/workspaces/zones-form/zones-form.component';
import { ZoneStatesComponent } from './pages/workspaces/zone-states/zone-states.component';
import { ZoneStatesFormComponent } from './pages/workspaces/zone-states-form/zone-states-form.component';
import { EstimateClaimsComponent } from './pages/companies/estimate-claims/estimate-claims.component';
import { PhoneExtensionsComponent } from './pages/workspaces/phone-extensions/phone-extensions.component';
import { PhoneExtensionsFormComponent } from './pages/workspaces/phone-extensions-form/phone-extensions-form.component';
import { ClaimsListComponent } from './pages/companies/claims-list/claims-list.component';
import { PhoneComponent } from './pages/companies/phone/phone.component';


const accountPanelRoutes: Routes = [
    { path: 'update-profile', component: UpdateProfileComponent },
    { path: 'workspaces', component: AccountWorkspacesComponent },
    {
        path: 'workspaces/:id/billing', component: WorkspacesBillingComponent, children: [
            { path: 'invoices', component: WorkspacesBillingInvoicesComponent },
            { path: 'process-payment/:id', component: WorkspacesBillingProcessPaymentComponent },
            { path: '', redirectTo: 'invoices', pathMatch: 'full' },
        ]
    },

    { path: '', redirectTo: 'workspaces', pathMatch: 'full' },
];

const workspacePanelRoutes: Routes = [
    { path: 'companies', component: CompaniesComponent },
    { path: 'companies/add', component: CompaniesFormComponent },

    { path: 'settings', component: SettingsComponent },

    { path: 'dashboard', component: DashboardWorkspaceComponent },
    { path: 'calendar', component: CalendarComponent },
    { path: 'calendar/:type', component: CalendarComponent },
    { path: 'calendar-freight', component: CalendarFreightComponent },
    { path: 'calendar-freight/:type', component: CalendarFreightComponent },

    { path: 'advertisers', component: AdvertisersComponent },
    { path: 'advertisers/add', component: AdvertisersFormComponent },
    { path: 'advertisers/:id/edit', component: AdvertisersFormComponent },
    { path: 'advertisers/:id/view', component: AdvertisersViewComponent },

    { path: 'carriers', component: CarriersComponent },
    { path: 'carriers/add', component: CarriersFormComponent },
    { path: 'carriers/:id/edit', component: CarriersFormComponent },
    { path: 'carriers/:id/view', component: CarriersViewComponent },

    { path: 'carriers-coverages-map', component: CarriersCoverageMapComponent },

    { path: 'employees', component: EmployeesComponent },
    { path: 'employees/add', component: EmployeesFormComponent, data: { panelType: 'WORKSPACE' } },
    { path: 'employees/:id/edit', component: EmployeesFormComponent, data: { panelType: 'WORKSPACE' } },
    { path: 'employees/:id/view', component: EmployeesViewComponent },

    { path: 'buy-numbers', component: BuyNumbersComponent },


    { path: 'phone-lines', component: PhoneLinesComponent },
    { path: 'phone-lines/add', component: PhoneLinesFormComponent },
    { path: 'phone-lines/:id/edit', component: PhoneLinesFormComponent },


    { path: 'phone-extensions', component: PhoneExtensionsComponent },
    { path: 'phone-extensions/add', component: PhoneExtensionsFormComponent },
    { path: 'phone-extensions/:id/edit', component: PhoneExtensionsFormComponent },

    { path: 'phone-cdr', component: PhoneCdrComponent },

    { path: 'active-agents', component: ActiveAgentsComponent },



    { path: 'phone-ring-groups', component: PhoneRingGroupsComponent },
    { path: 'phone-ring-groups/add', component: PhoneRingGroupsFormComponent },
    { path: 'phone-ring-groups/:id/edit', component: PhoneRingGroupsFormComponent },

    { path: 'phone-queues', component: PhoneQueuesComponent },
    { path: 'phone-queues/add', component: PhoneQueuesFormComponent },
    { path: 'phone-queues/:id/edit', component: PhoneQueuesFormComponent },

    { path: 'phone-ivrs', component: PhoneIvrsComponent },
    { path: 'phone-ivrs/add', component: PhoneIvrsFormComponent },
    { path: 'phone-ivrs/:id/edit', component: PhoneIvrsFormComponent },


    { path: 'customers', component: CustomersComponent },
    { path: 'customers/add', component: CustomersFormComponent },
    { path: 'customers/:id/edit', component: CustomersFormComponent },
    { path: 'customers/:id/view', component: CustomersViewComponent },

    { path: 'inventory-categories', component: InventoryCategoriesComponent },
    { path: 'inventory-categories/add', component: InventoryCategoriesFormComponent },
    { path: 'inventory-categories/:id/edit', component: InventoryCategoriesFormComponent },

    { path: 'leads-estimates-results', component: LeadsEsimatesResultsComponent },

    { path: 'local-room-categories', component: LocalRoomCategoriesComponent },
    { path: 'local-room-categories/add', component: LocalRoomCategoriesFormComponent },
    { path: 'local-room-categories/:id/edit', component: LocalRoomCategoriesFormComponent },

    { path: 'local-room-closets', component: LocalRoomClosetsComponent },
    { path: 'local-room-closets/add', component: LocalRoomClosetsFormComponent },
    { path: 'local-room-closets/:id/edit', component: LocalRoomClosetsFormComponent },

    { path: 'inventory-items', component: InventoryItemsComponent },
    { path: 'inventory-items/add', component: InventoryItemsFormComponent },
    { path: 'inventory-items/:id/edit', component: InventoryItemsFormComponent },

    { path: 'bad-leads-report', component: BadLeadsReportComponent },
    { path: 'leads-report', component: LeadsReportComponent },
    { path: 'leads-advertisers-report', component: LeadsAdvertisersReportComponent },
    { path: 'carrier-jobs-report', component: CarrierJobsReportComponent },
    { path: 'pickup-documents-report', component: PickupDocumentsReportComponent },
    { path: 'delivery-documents-report', component: DeliveryDocumentsReportComponent },
    { path: 'archive-pickup-documents-report', component: ArchivePickupDocumentsReportComponent },
    { path: 'archive-delivery-documents-report', component: ArchiveDeliveryDocumentsReportComponent },
    { path: 'canceled-jobs-list', component: CanceledJobsListComponent },
    { path: 'qa-report', component: ReportQAComponentWS },


    { path: 'roles', component: RolesComponent },
    { path: 'roles/add', component: RolesFormComponent },
    { path: 'roles/:id/edit', component: RolesFormComponent },

    { path: 'packing-materials', component: PackingMaterialsComponent },
    { path: 'packing-materials/add', component: PackingMaterialsFormComponent },
    { path: 'packing-materials/:id/edit', component: PackingMaterialsFormComponent },

    { path: 'volumes/add', component: VolumesFormComponent },
    { path: 'volumes/:id/edit', component: VolumesFormComponent },

    { path: 'periods/add', component: PeriodsFormComponent },
    { path: 'periods/:id/edit', component: PeriodsFormComponent },

    { path: 'deductible-levels', component: DeductibleLevelsComponent },
    { path: 'deductible-levels/add', component: DeductibleLevelsFormComponent },
    { path: 'deductible-levels/:id/edit', component: DeductibleLevelsFormComponent },

    { path: 'liability-amounts', component: LiabilityAmountsComponent },
    { path: 'liability-amounts/add', component: LiabilityAmountsFormComponent },
    { path: 'liability-amounts/:id/edit', component: LiabilityAmountsFormComponent },

    { path: 'liability-deductible-rates', component: LiabilityDeductibleRatesComponent },


    // { path: 'settings', component: TariffVersionsSettingsComponent },
    {
        path: 'settings',
        component: TariffVersionsSettingsComponent,
        children: [
            { path: '', redirectTo: 'estimate', pathMatch: 'full' },
            { path: 'route-rates', component: RouteRatesComponent },
            { path: 'zones', component: ZonesComponent },
            { path: 'zone-states', component: ZoneStatesComponent },
            { path: 'volumes', component: VolumesComponent },
            { path: 'periods', component: PeriodsComponent },
            { path: 'route-rates-map', component: RouteRatesMapComponent },
            { path: 'additional-stop-rules', component: AdditionalStopRulesComponent },
        ]
    },
    { path: 'local-tariff-settings', component: LocalTariffSettingsComponent},
    { path: 'local-tariff-settings/add', component: LocalTariffSettingsFormComponent},
    { path: 'local-tariff-settings/:id/edit', component: LocalTariffSettingsFormComponent},

    { path: 'tariff-additional-concepts', component: TariffAdditionalConceptsComponent },
    { path: 'tariff-additional-concept/add', component: TariffAdditionalConceptsFormComponent },
    { path: 'tariff-additional-concept/:id/edit', component: TariffAdditionalConceptsFormComponent },

    { path: 'tariff-versions', component: TariffVersionsComponent },
    { path: 'tariff-versions/add', component: TariffVersionsFormComponent },
    { path: 'tariff-versions/:id/edit', component: TariffVersionsFormComponent },

    { path: 'storage-companies', component: StorageCompaniesComponent },
    { path: 'storage-companies/add', component: StorageCompaniesFormComponent },
    { path: 'storage-companies/:id/edit', component: StorageCompaniesFormComponent },
    { path: 'storage-companies/:id/view', component: StorageCompaniesViewComponent },

    { path: 'suppliers', component: SuppliersComponent },
    { path: 'suppliers/add', component: SuppliersFormComponent },
    { path: 'suppliers/:id/edit', component: SuppliersFormComponent },
    { path: 'suppliers/:id/view', component: SuppliersViewComponent },

    { path: 'zones/add', component: ZonesFormComponent },
    { path: 'zones/:id/edit', component: ZonesFormComponent },

    { path: 'zone-states/add', component: ZoneStatesFormComponent },
    { path: 'zone-states/:id/edit', component: ZoneStatesFormComponent },

    { path: 'tickets', component:  TicketsWorkspaceComponent },

    { path: 'storage-invoices', component:  StorageInvoicesWorkdpaceComponent },

    { path: 'storage-manager', component:  StorageManagerComponent },

    { path: 'dispatch-contacts', component: DispatchContactsComponent },
    { path: 'dispatch-contacts/add', component: DispatchContactsFormComponent },
    { path: 'dispatch-contacts/:id/edit', component: DispatchContactsFormComponent },

    { path: 'load-leads-by-excel', component: LoadLeadsByExcelComponent},
    { path: 'build-posting-leads', component: BuildPostingLeadsComponent },

    { path: 'lead-forwarding-links', component: LeadForwardingLinksComponent },
    { path: 'lead-forwarding-links/add', component: LeadForwardingLinksFormComponent },
    { path: 'lead-forwarding-links/:id/edit', component: LeadForwardingLinksFormComponent },

    { path: 'vehicles', component: VehiclesComponent },
    { path: 'vehicles/add', component: VehiclesFormComponent },
    { path: 'vehicles/:id/edit', component: VehiclesFormComponent },

    { path: 'company-sales-performance', component: CompanySalesPerformenceComponent },

    { path: 'driver-invoices', component: DriverInvoicesComponent },
    { path: 'driver-invoices/add', component: DriverInvoicesFormComponent },
    { path: 'driver-invoices/:id/edit', component: DriverInvoicesFormComponent },
    { path: 'driver-invoices/:id/view', component: DriverInvoicesViewComponent },

    { path: 'board-jobs', component: BoardJobsComponent },
    { path: 'board-jobs/add', component: BoardJobsFormComponent },
    { path: 'board-jobs/:id/edit', component: BoardJobsFormComponent },
    { path: 'board-jobs/:id/view', component: BoardJobsViewComponent },

    { path: 'blackout-move-dates', component: BlackoutMoveDatesComponent },
    { path: 'blackout-move-dates/add', component: BlackoutMoveDatesFormComponent },
    { path: 'blackout-move-dates/:id/edit', component: BlackoutMoveDatesFormComponent },

    { path: 'additional-stop-rules', component: AdditionalStopRulesComponent },
    { path: 'additional-stop-rules/add', component: AdditionalStopRulesFormComponent },
    { path: 'additional-stop-rules/:id/edit', component: AdditionalStopRulesFormComponent },

    { path: 'warehouses', component: WarehousesComponent },
    { path: 'warehouses/add', component: WarehousesFormComponent },
    { path: 'warehouses/:id/edit', component: WarehousesFormComponent },

    { path: 'warehouses-fadd-jobs', component: WarehousesFaddJobsComponent},


    { path: '', redirectTo: '/login', pathMatch: 'full' },
];

const companyPanelRoutes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'dashboard', component: DashboardComponent },

    { path: 'dialer', component: OldDialerComponent },
    { path: 'phone', component: PhoneComponent },

    { path: 'commission-settings', component: CommissionSettingsComponent },
    { path: 'commission-settings/:id/edit', component: CommissionSettingsFormComponent },
    { path: 'commission-settings/:id/view', component: CommissionSettingsViewComponent },
    { path: 'commission-settings/add', component: CommissionSettingsFormComponent },

    { path: 'customers', component: CustomersComponent },
    { path: 'customers/:id/edit', component: CustomersFormComponent },
    { path: 'customers/:id/view', component: CustomersViewComponent },
    { path: 'customers/add', component: CustomersFormComponent },

    { path: 'custom-labels', component: CustomLabelsComponent },
    { path: 'custom-labels/:id/view', component: CustomLabelsViewComponent },

    { path: 'documents-types', component: DocumentsTypesComponent },
    { path: 'documents-types/:id/edit', component: DocumentsTypesFormComponent },
    { path: 'documents-types/:id/view', component: DocumentsTypesViewComponent },
    { path: 'documents-types/add', component: DocumentsTypesFormComponent },

    { path: 'email-campaigns', component: EmailCampaignsComponent },
    { path: 'email-campaigns/:id/edit', component: EmailCampaignsFormComponent },
    { path: 'email-campaigns/add', component: EmailCampaignsFormComponent },

    { path: 'email-templates', component: EmailTemplatesComponent },
    { path: 'email-templates/:id/edit', component: EstimatesEmailTemplatesFormComponent },
    { path: 'email-templates/:id/view', component: EstimatesEmailTemplatesViewComponent },
    { path: 'email-templates/add', component: EstimatesEmailTemplatesFormComponent },

    { path: 'employees', component: EmployeesCompaniesComponent },

    { path: 'estimates-report', component: EstimatesReportComponent },

    { path: 'qa-report', component: ReportQAComponent },

    { path: 'profit-loss-report', component: ProfitLossComponent },
    { path: 'cc-processing-report', component: CcProcessingComponent },



    { path: 'estimates', component: EstimatesComponent, canActivate: [EstimatesGuard] },
    { path: 'estimates/add', component: EstimatesFormComponent },

    { path: 'export-leads', component: ExportLeadsComponent },

    { path: 'follow-ups-reminders', component: FollowUpRemindersComponent },

    { path: 'invoices', component: InvoicesComponent },

    { path: 'jobs-calendar', component: JobsCalendarComponent },
    { path: 'jobs-calendar/:type', component: JobsCalendarComponent },

    { path: 'jobs-report', component: JobsReportComponent },

    { path: 'jobs', component: JobsComponent },
    { path: 'pending-qa-jobs', component: PendingQAJobsComponent },

    { path: 'payroll-settings', component: PayrollSettingsComponent },
    { path: 'payroll-settings/add', component: PayrollSettingsFormComponent },
    { path: 'payroll-settings/:id/edit', component: PayrollSettingsFormComponent },

    { path: 'leads-estimates-results', component: LeadsEsimatesResultsComponent },

    { path: 'leads', component: LeadsComponent },
    { path: 'leads/:id/edit', component: LeadsFormComponent },
    { path: 'leads/:id/view', component: LeadsViewComponent },
    { path: 'leads/add', component: LeadsFormComponent },

    { path: 'my-leads', component: MyLeadsComponent },
    { path: 'automations', component: AutomationsComponent },
    { path: 'automations/add', component: AutomationsFormComponent },
    { path: 'automations/:id/edit', component: AutomationsFormComponent },

    {
        path: 'moving/:id',
        component: EstimatesViewComponent,
        children: [
            { path: '', redirectTo: 'estimate', pathMatch: 'full' },
            { path: 'claims', component: EstimateClaimsComponent },
            // { path: 'claims', component: MovingClaimsComponent },
            { path: 'completions', component: MovingCompletionsComponent },
            { path: 'emails', component: EstimatesEmailComponent },
            { path: 'estimate', component: EstimatesDetailsComponent },
            { path: 'estimates-signatures', component: EstimatesSignaturesComponent },
            { path: 'inventory-items', component:  EstimatesInventoryItemsComponent },
            { path: 'items', component: MovingItemsComponent },
            { path: 'items/add', component: MovingItemsFormComponent },
            { path: 'items/edit/:value', component: MovingItemsFormComponent },
            { path: 'labels', component: MovingLabelsComponent },
            { path: 'logs', component: EstimatesLogsComponent },
            { path: 'media-files', component: MovingMediaFilesComponent },
            { path: 'operations', component: EstimatesOperationsComponent },
            { path: 'outsourcings', component: MovingOutsourcingsComponent },
            { path: 'packing-materials', component: EstimatesPackingMaterialsComponent },
            { path: 'payments', component: EstimatesPaymentsComponent },
            { path: 'quick-inventory', component: QuickInventoryEstimateComponent },
            { path: 'storage-invoices', component: StorageInvoicesComponent },
            { path: 'storage', component: EstimatesStorageComponent },
            { path: 'tickets', component: EstimatesTicketsComponent },
            { path: 'tickets/add', component: EstimatesTicketsFormComponent },
        ]
    },
    { path: 'payment-gateway-settings', component: PaymentGatewaySettingsComponent },
    { path: 'payment-gateway-settings/:id/edit', component: PaymentGatewaySettingsFormComponent },
    { path: 'payment-gateway-settings/add', component: PaymentGatewaySettingsFormComponent },

    { path: 'payment-methods', component: PaymentsMethodsComponent },
    { path: 'payment-methods/:id/edit', component: PaymentsMethodsFormComponent },
    { path: 'payment-methods/add', component: PaymentsMethodsFormComponent },

    { path: 'payments', component: PaymentsComponent },
    { path: 'payments/:id/view', component: PaymentsViewComponent },

    { path: 'payroll-balances', component: PayrollBalancesComponent },
    { path: 'payroll-balances/:id/edit', component: PayrollBalancesFormComponent },
    { path: 'payroll-balances/add', component: PayrollBalancesFormComponent },

    { path: 'payroll-clock-users', component: PayrollClockUsersComponent },
    { path: 'payroll-clock-users/add', component: PayrollClockUsersFormComponent },
    { path: 'payroll-clock-users/:id/edit', component: PayrollClockUsersFormComponent },

    { path: 'payroll-clock-locations', component: PayrollClockLocationsComponent },
    { path: 'payroll-clock-locations/add', component: PayrollClockLocationsFormComponent },
    { path: 'payroll-clock-locations/:id/edit', component: PayrollClockLocationsFormComponent },
    { path: 'payroll-clock-manual/:id/add', component : PayrollTimeClockManualComponent },


    { path: 'recurring-customers', component: RecurringCustomersComponent },

    { path: 'sales-commissions', component: ReportSalesCommissionsComponent },

    { path: 'salesman-performs', component: SalesmanPerformsComponent },
    { path: 'my-performs', component: MyPerformsComponent },

    { path: 'settings', component: GeneralSettingsComponent },
    { path: 'settings-lead-rotation/:id', component: GeneralSettingLeadRotationComponent },

    { path: 'sms-campaigns', component: SmsCampaignsComponent },
    { path: 'sms-campaigns/:id/edit', component: SmsCampaignsFormComponent },
    { path: 'sms-campaigns/add', component: SmsCampaignsFormComponent },

    { path: 'sms-templates', component: SmsTemplatesComponent },
    { path: 'sms-templates/:id/edit', component: SmsTemplatesFormComponent },
    { path: 'sms-templates/add', component: SmsTemplatesFormComponent },

    { path: 'ticket-assignment-rules', component: TicketAssignmentRulesComponent },
    { path: 'ticket-assignment-rules/:id/edit', component: TicketAssignmentRulesFormComponent },
    { path: 'ticket-assignment-rules/add', component: TicketAssignmentRulesFormComponent },

    { path: 'claims-list', component:  ClaimsListComponent },

    { path: 'tickets', component:  TicketsComponent },
    { path: 'tickets/:id', component: TicketsViewComponent },

    { path: 'tickets-type', component:  TicketTypeComponent },
    { path: 'tickets-type/add', component: TicketTypeFormComponent },
    { path: 'tickets-type/:id/edit', component: TicketTypeFormComponent },

    { path: 'time-clock', component: PayrollTimeClockComponent },

    { path: 'work-departments', component: WorkDepartmentsComponent },
    { path: 'work-departments/:id/edit', component: WorkDepartmentsFormComponent },
    { path: 'work-departments/:id/view', component: WorkDepartmentsViewComponent },
    { path: 'work-departments/add', component: WorkDepartmentsFormComponent },


    { path: 'payroll-payments', component: PayrollPaymentsComponent },
    { path: 'payroll-payments/add', component: PayrollPaymentsFormComponent },
    { path: 'payroll-payments/:id/view', component: PayrollPaymentsViewComponent },
];


const routes: Routes = [

    { path: 'login', component: LoginComponent },
    { path: 'password-recovery', component: PasswordRecoveryComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'register-account-company', component: RegisterAccountCompanyComponent },
    { path: 'register-account-company/credit-card/:id', component: CreditCardComponent },
    { path: 'workspaces/:id/unauthorized-outstanding-invoices', component: WorkspacesUnauthorizedOutstandingInvoicesComponent },

    { path: 'unsubscribe/:id_workspace/:id_company/:email', component: UnsubscribeComponent },

    // PANEL para WORKSPACE
    { path: 'workspace/:id', component: PanelLayoutComponent, children: workspacePanelRoutes, data: { panelType: 'WORKSPACE' } },

    // PANEL para COMPANY
    { path: 'workspace/:workspace_id/companyspace/:company_id', component: PanelLayoutComponent, children: companyPanelRoutes, data: { panelType: 'COMPANY' } },

    // PANEL para ACCOUNT
    { path: 'my-profile', children: accountPanelRoutes, data: { panelType: 'ACCOUNT' } },

    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
