import { HelperService } from 'src/app/services/helper.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConstantsMessages } from '../../../constants-messages';
import { PaymentMethod } from '../../../entities/workspace/payment-method';
import { PaymentsMethodsService } from '../../../services/companies/payments-methods.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-payments-methods-form',
    templateUrl: './payments-methods-form.component.html',
    styleUrls: ['./payments-methods-form.component.scss']
})
export class PaymentsMethodsFormComponent implements OnInit {

    /*
    Metodo de pago a crear
    */
    public paymentMethod: PaymentMethod;

    /*
    Metodos de pago creados
    */
    public paymentsMethods: Array<PaymentMethod>;

    constructor(
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private paymentsMethodsService: PaymentsMethodsService,
        private router: Router
    ) {
        this.paymentMethod = new PaymentMethod();
        this.paymentsMethods = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadMethodPayment(params.id);
            }
        });
    }

    /**
     * Se guarda el metodo de pago
     */
    public async save(): Promise<void> {

        // Se verifica si el metodo de pago no existe para crearse
        const existMethodPayment = await this.verifyMethodsPayments();

        // Si no existe, se crea el metodo de pago
        if (!existMethodPayment) {
            this.paymentsMethodsService.save(this.paymentMethod)
                .then(() => {
                    this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
                    this.helperService.goToCompanyRouterLink('/payment-methods');
                })
                .catch((error) => {
                    this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }

    }

    /**
     * Se carga el metodo de pago, si existe un id
     *
     * @param idPaymentMethod Id del metodo de pago
     */
    private loadMethodPayment(idPaymentMethod: string): void {
        this.helperService.showLoadingMxpro360();
        this.paymentsMethodsService
            .getById(idPaymentMethod)
            .then((response) => {
                this.paymentMethod = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Verifica si un metodo de pago de CREDIT_CARD ya existe en bd para no ser creado
     */
    private async verifyMethodsPayments() {
        let existMethodPayment = false;

        // Se verifica el metodo de pago que se esta creando
        if (this.paymentMethod.method === 'CREDIT_CARD') {

            // cargamos los metodos de pago
            this.paymentsMethods = await this.loadAllMethodsPayments();

            // iteramos los metodos
            for (const iterator of this.paymentsMethods) {

                // Se verifica si el metodo de pago que se esta creando ya existe en bd de la company
                if (iterator.method === 'CREDIT_CARD' && this.paymentMethod.id !== iterator.id) {

                    // Si existe, se devuelve un mensaje informativo indicando que no se puede crear el metodo
                    // de pago
                    swal(
                        'Record Not Valid!',
                        'This record cannot be created because this payment method already exists.',
                        'warning'
                    );

                    // Se retorna que no se puede crear el metodo de pago
                    existMethodPayment = true;
                    break;
                }
            }
        }

        // devolvemos el resultado
        return existMethodPayment;
    }

    /**
     * Carga todos los metodos de pago asociados a una company
     */
    private loadAllMethodsPayments(): Promise<Array<PaymentMethod>> {
        return new Promise((resolve, reject) => {
            this.paymentsMethodsService
                .getAll()
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.error('error', error);
                    reject();
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

}
