import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { WorkspaceBillingProfile } from 'src/app/entities/admin/workspace-billing-profile';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceBillingProfilesService {

    onChange: EventEmitter<string>;

    constructor(private http: HttpClient) {
        this.onChange = new EventEmitter();
    }

    /**
     * permite obtener el billing profile de un workspace
     * @param id 
     * @returns 
     */
    get(id: string): Promise<WorkspaceBillingProfile> {
        return this.http.get<WorkspaceBillingProfile>(environment.api.account + '/workspaces/' + id + '/billing-profile').toPromise();
    }

    /**
     * permite actualizar el billing profile de un workspace
     * @param dataForm 
     * @returns 
     */
    put(dataForm: WorkspaceBillingProfile) {
        return this.http.put(environment.api.account + '/workspaces/' + dataForm.id + '/billing-profile', dataForm).toPromise();
    }
}
