<div class="widget widget-table-two">

    <h4 class="font-weight-bold">Closing Ratio Per Estimate (Last 3 Months) <span class="float-right">{{closingRatioPerEstimate.closing_ratio}}%</span></h4>
    <div class="progress mb-4">
        <div class="progress-bar" role="progressbar"  [ngStyle]="{'width.%': closingRatioPerEstimate.closing_ratio}"  attr.aria-valuenow="{{closingRatioPerEstimate.closing_ratio}}" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="row">
        <div class="col-md-5 text-right total">
            <h5>{{closingRatioPerEstimate.total_estimates}}</h5>
            <span>Sales Proposals</span> 
        </div>
        <div class="col-md-2 text-center arrow">➭</div>
        <div class="col-md-5 text-left total">
            <h5>{{closingRatioPerEstimate.total_bookeds}}</h5>
            <span>Closed Sales</span> 
        </div>
    </div>
</div>