import { CampaignFilter } from './campaign-filter';

export class EmailCampaign {
    public id: string;
    public name: string;
    public subject: string;
    public body_file: string;
    public campaign_filter: CampaignFilter;
    public total_send: number;
    public is_run: boolean;
    public company_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.subject = null;
        this.body_file = null;
        this.campaign_filter = null;
        this.total_send = null;
        this.is_run = false;
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
