
import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from '../../../constants-messages';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { EstimateMaterial } from 'src/app/entities/workspace/estimate-material';
import { HelperService } from 'src/app/services/helper.service';
import { Location } from '@angular/common';
import { PackingMaterial } from 'src/app/entities/workspace/packing-material';
import { PackingMaterialsService } from 'src/app/services/companies/packing-materials.service';

declare const jQuery;

@Component({
    selector: 'app-estimates-packing-materials',
    templateUrl: './estimates-packing-materials.component.html',
    styleUrls: ['./estimates-packing-materials.component.scss']
})
export class EstimatesPackingMaterialsComponent implements OnInit, OnDestroy {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    rowsPackingMaterials: Array<PackingMaterial>;
    rowsEstimate: Estimate;
    estimateId: any;
    public rowEstimateMaterials: EstimateMaterial[];
    public formCustomMaterial: EstimateMaterial;

    @ViewChild('customItem') customItem: ElementRef;

    constructor(
        private currentRoute: ActivatedRoute,
        private _location: Location,
        private estimateMaterialsService: PackingMaterialsService,
        private helperService: HelperService
    ) {
        this.rowsEstimate = new Estimate();
        this.rowEstimateMaterials = [];
        this.formCustomMaterial = new EstimateMaterial();
    }

    ngOnInit(): void {
      

    }

    ngAfterViewInit(): void{
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
                this.estimateId = params.id;
            }
        });
    }

    ngOnDestroy(){
        this.rowsEstimate = undefined; // unset
        delete(this.rowsEstimate); // this removes the variable completely

        this.rowEstimateMaterials = undefined; // unset
        delete(this.rowEstimateMaterials); // this removes the variable completely
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimateMaterialsService
            .getMaterialsByEstimateId(id)
            .then((response) => {
                this.rowEstimateMaterials = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        const readySave = this.rowEstimateMaterials.filter(x => x.quantity > 0);
        this.estimateMaterialsService
            .save(readySave, this.estimateId)
            .then(() => {
                this._location.back();
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openModalCustomMaterial() {
        this.formCustomMaterial = new EstimateMaterial();
        jQuery(this.customItem.nativeElement).modal('show');
    }
    closeModalCustomMaterial() {
        jQuery(this.customItem.nativeElement).modal('hide');
    }

    saveCustomMaterial() {
        console.log(this.formCustomMaterial);
        // se usa metodo de asignacion para evitar edicion involuntaria del objeto
        let newMaterial = new EstimateMaterial();
        newMaterial.estimate_id = this.estimateId;
        newMaterial.quantity = this.formCustomMaterial.quantity;
        newMaterial.packing_material = {... this.formCustomMaterial.packing_material};
        this.rowEstimateMaterials.push(this.formCustomMaterial);
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this.closeModalCustomMaterial();
    }

}
