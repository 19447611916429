import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';
declare var ApexCharts;
declare var jQuery;
@Component({
  selector: 'app-tickets-by-priority',
  templateUrl: './tickets-by-priority.component.html',
  styleUrls: ['./tickets-by-priority.component.scss']
})
export class TicketsByPriorityComponent implements OnInit {
    ticketsByPriority: any;
    @Input()
    companyId: boolean = false;
  constructor(
    private dashboardWorkspaceService: DashboardWorkspaceService,
    private dashboardComopanyService: DashboardComopanyService,
  ) { 
    this.ticketsByPriority = [];

  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadTicketsByPriorityCompany();
    } else {
        this.loadTicketsByPriorityWorkspace();
    }
  }

  
  loadTicketsByPriorityWorkspace(){
        
    this.dashboardWorkspaceService
        .getTicketsByPriority()
        .then((response) => {
            this.ticketsByPriority = response;
            this.loadChart();
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadTicketsByPriorityCompany(){
        
    this.dashboardComopanyService
        .getTicketsByPriority()
        .then((response) => {
            this.ticketsByPriority = response;
            this.loadChart();
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

loadChart(){

    let series = [];
    let labels = [];
    let colors = [];

    for (const ticketByPriority of this.ticketsByPriority) {
        series.push(ticketByPriority.total);
        labels.push(ticketByPriority._id);
        if(ticketByPriority._id == 'URGENT'){
            colors.push('#FF4560');
        }
        if(ticketByPriority._id == 'HIGH'){
            colors.push('#008FFB');
        }
        if(ticketByPriority._id == 'LOW'){
            colors.push('#00E396');
        }
        if(ticketByPriority._id == 'MID'){
            colors.push('#FEB019');
        }
    }

    var options = {
        series: series,
        chart: {
        width: 380,
        type: 'polarArea',
      },
      labels: labels,
      colors: colors,
      stroke: {
        colors: colors
      },
      fill: {
        opacity: 0.8
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 100
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
      };

      var chart = new ApexCharts(document.querySelector("#chart-45"), options);
      chart.render();

      
    setTimeout(() => {
        jQuery('#chart-45 .apexcharts-legend-text').css('color','white');
        jQuery('#chart-45 .apexcharts-text').attr('fill','#fff');
    }, 500);
    
}

}
