export class CommissionCondition {
    id: string;
    type: string;
    threshold_start: string;
    threshold_end: string;

    constructor() {
        this.id = null;
        this.type = null;
        this.threshold_start = null;
        this.threshold_end = null;
    }
}
