<div class="layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="customer.id == null">
                    New
                </span>
                <span *ngIf="customer.id != null">
                    Update
                </span> Customer
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/customers">Customers</a></li>
                    <li *ngIf="customer.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="customer.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <!--
                            <a appCompanyRouterLink="/work-departments/add" class="btn btn-primary">
                                <i class="fas fa-plus-square"></i> &nbsp; New Row
                            </a>
                        -->

                <!--
                            <div class="switch align-self-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-list view-list active-view">
                                    <line x1="8" y1="6" x2="21" y2="6"></line>
                                    <line x1="8" y1="12" x2="21" y2="12"></line>
                                    <line x1="8" y1="18" x2="21" y2="18"></line>
                                    <line x1="3" y1="6" x2="3" y2="6"></line>
                                    <line x1="3" y1="12" x2="3" y2="12"></line>
                                    <line x1="3" y1="18" x2="3" y2="18"></line>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-grid view-grid">
                                    <rect x="3" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="3" width="7" height="7"></rect>
                                    <rect x="14" y="14" width="7" height="7"></rect>
                                    <rect x="3" y="14" width="7" height="7"></rect>
                                </svg>
                            </div>
                        -->
            </div>


        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">
                <!-- <div class="widget-header">
                    <h4>
                        Form
                    </h4>
                </div> -->
                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate autocomplete="off">

                        <div class="form-group mb-4">
                            <label class="control-label">Name:</label>
                            <input type="text" [(ngModel)]="customer.name" name="wd_name" class="form-control">
                            <small id="emailHelp1" class="form-text text-muted mb-4">We'll never share your email
                                with anyone else.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label class="control-label">Last Name:</label>
                            <input type="text" [(ngModel)]="customer.last_name" name="wd_last_name"
                                class="form-control">
                            <small id="emailHelp1" class="form-text text-muted mb-4">We'll never share your email
                                with anyone else.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label class="control-label">Email:</label>
                            <input type="text" [(ngModel)]="customer.email" name="wd_email" class="form-control">
                            <small id="emailHelp1" class="form-text text-muted mb-4">We'll never share your email
                                with anyone else.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label class="control-label">Phone:</label>
                            <input type="text" [(ngModel)]="customer.phone" name="wd_phone" class="form-control">
                            <small id="emailHelp1" class="form-text text-muted mb-4">We'll never share your email
                                with anyone else.</small>
                        </div>


                        <div class="text-center">
                            <button class="btn btn-warning ml-1 mt-3" type="submit">
                                <i class="far fa-save" style="height: 24px;"></i> &nbsp; Submit
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>
