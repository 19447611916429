import { DateText } from "src/app/entities/global/date-text";

export class CreditCard {
    public id: string;
    public holder_name: string;
    public franchise: string;
    public number: number;
    public security_code: number;
    public expiration_date: DateText;

    constructor() {
        this.id = null;
        this.holder_name = null;
        this.franchise = null;
        this.number = null;
        this.security_code = null;
        this.expiration_date = new DateText();
    }
}
