import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { JobView } from 'src/app/entities/workspace/job-view';
import { Job } from 'src/app/entities/workspace/job';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { Estimate } from 'src/app/entities/workspace/estimate';


@Injectable({
    providedIn: 'root'
})
export class JobsService {

    constructor(private http: HttpClient) { }

    getAllWithFullView(data: any): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/jobs', { params: data }).toPromise();
    }

    getJobsToday(data: any): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/jobs-today', { params: data }).toPromise();
    }

    getById(id: string) {
        return this.http.get<JobView>(environment.api.workspace + '/jobs/' + id).toPromise();
    }

    getAll() {
        return this.http.get<any>(environment.api.workspace + '/jobs').toPromise();
    }

    getAllOutsourcing(paginator: Paginator, betweenDates: BetweenDates, carrierId: string, filterData: FilterData){
        const params: any = {
          "use_paginator": 1,
          "page": paginator.paginator.page,
          "per_page": paginator.paginator.per_page,
          "start": paginator.paginator.start,
          "end": paginator.paginator.end,
          "order_by": paginator.paginator.order_by,
          "order_type": paginator.paginator.order_type,
          "dateStart": betweenDates.dateStart,
          "dateEnd": betweenDates.dateEnd,
          carrierId,
          "pickup_state": filterData.pickup_state,
          "delivery_state": filterData.delivery_state,
          "service": filterData.service
      };
        return this.http.get<Paginator>(environment.api.workspace + '/jobs-outsourcing', { params: params }).toPromise();
      }
    
    getAllCancelled(paginator: Paginator, betweenDates: BetweenDates, filterData: FilterData, foreman: string, salesman: string){
        const params: any = {
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "order_by": paginator.paginator.order_by,
            "order_type": paginator.paginator.order_type,
            "dateStart": betweenDates.dateStart,
            "dateEnd": betweenDates.dateEnd,
            "pickup_state": filterData.pickup_state,
            "delivery_state": filterData.delivery_state,
            "service": filterData.service,
            salesman,
            foreman
        };
        return this.http.get<Paginator>(environment.api.workspace + '/jobs-cancelations', { params: params }).toPromise();
    }

    searchPickudDocuments(paginator: Paginator, betweenDates: BetweenDates, carrierId: string, filter: string) {
        const params: any = {
          "use_paginator": 1,
          "page": paginator.paginator.page,
          "per_page": paginator.paginator.per_page,
          "start": paginator.paginator.start,
          "end": paginator.paginator.end,
          "order_by": paginator.paginator.order_by,
          "order_type": paginator.paginator.order_type,
          "dateStart": betweenDates.dateStart,
          "dateEnd": betweenDates.dateEnd,
          carrierId,
          "archived": filter
      };
        return this.http.get<Paginator>(environment.api.workspace + '/search-jobs-pickup-report', { params: params }).toPromise();
    }

    searchDeliveryDocuments(paginator: Paginator, betweenDates: BetweenDates, carrierId: string, filter: string) {
        const params: any = {
          "use_paginator": 1,
          "page": paginator.paginator.page,
          "per_page": paginator.paginator.per_page,
          "start": paginator.paginator.start,
          "end": paginator.paginator.end,
          "order_by": paginator.paginator.order_by,
          "order_type": paginator.paginator.order_type,
          "dateStart": betweenDates.dateStart,
          "dateEnd": betweenDates.dateEnd,
          carrierId,
          "archived": filter
      };
        return this.http.get<Paginator>(environment.api.workspace + '/search-jobs-delivery-report', { params: params }).toPromise();
    }

    getJobsDelivered(data) {
        return this.http.get<any>(environment.api.workspace + '/jobs', { params: data }).toPromise();
    }

    getJobsDeliveredById(data, id) {
        return this.http.get<any>(environment.api.workspace + '/jobs/' + id, { params: data }).toPromise();
    }

    patchEntity(id: string, dataForm: any) {
        return this.http.patch(environment.api.workspace + '/jobs/' + id, dataForm).toPromise();
    }

    saveDocument(id: string, doc_type: string, dataForm: any) {
        return this.http.put<Job>(environment.api.workspace + '/jobs/' + id + '/media-files/' + doc_type, dataForm).toPromise();
    }


    getAllUnassigned(): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/board-jobs-unassigned').toPromise();
    }

    getPickupReport(paginator: Paginator, filter: string) {
        const params: any = {
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start": paginator.paginator.start,
            "end": paginator.paginator.end,
            "order_by": paginator.paginator.order_by,
            "order_type": paginator.paginator.order_type,
            "archived": filter
        };
        return this.http.get<Array<any>>(environment.api.workspace + '/jobs-pickup-report', { params }).toPromise();
    }

    getDeliveryReport(paginator: Paginator, filter: string) {
        const params: any = {
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start": paginator.paginator.start,
            "end": paginator.paginator.end,
            "order_by": paginator.paginator.order_by,
            "order_type": paginator.paginator.order_type,
            "archived": filter
        };
        return this.http.get<Array<any>>(environment.api.workspace + '/jobs-delivery-report', { params }).toPromise();
    }

    getByEstimateCode(code: string) {
        return this.http.get<Array<Estimate | {message: string}>>(environment.api.workspace + '/jobs/search/' + code).toPromise();
    }
}
