export class AccountRecovery {
    
    public email: string;
    public token: string;

    constructor() {
        this.email = null;
        this.token = null;
    }
}
