import { File } from '../global/file';


export class CarrierFile {
    public id: number;
    public description: string;
    public carrier_id: string;
    public file: File;
    public created: string;
    public updated: string;

    constructor() {
        this.id = null;
        this.description = null;
        this.carrier_id = null;
        this.file = new File();
        this.created = null;
        this.updated = null;
    }
}
