import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from '../../../services/companies/balances.service';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from '../../../constants-messages';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { environment } from 'src/environments/environment';
import { EstimateAdditionalCharge } from 'src/app/entities/workspace/estimate-additional-charge';
import { EstimateInventoryContainer } from 'src/app/entities/workspace/estimate-inventory-container';
import { EstimateMaterialView } from 'src/app/entities/workspace/estimate-material-view';
import { EstimatesAdditionalChargesService } from 'src/app/services/companies/estimates-additional-charges.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { EstimateStopPoint } from 'src/app/entities/workspace/estimate-stop-point';
import { EstimateStopPointsService } from 'src/app/services/companies/estimate-stop-points.service';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { Expense } from 'src/app/entities/workspace/expense';
import { ExpenseService } from 'src/app/services/companies/expense.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from '../../../services/helper.service';
import { JobAdditionalCharge } from '../../../entities/workspace/job-additional-charge';
import { JobDeliveryFee } from '../../../entities/workspace/job-delivery-fee';
import { JobLocalTariffLog } from 'src/app/entities/workspace/job-local-tariff-log';
import { JobLocalTariffLogsService } from 'src/app/services/workspace/job-local-tariff-logs.service';
import { JobsAdditionalChargesService } from '../../../services/companies/jobs-additional-charges.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { JobView } from 'src/app/entities/workspace/job-view';
import { LocalTariffSetting } from 'src/app/entities/admin/local-tariff-setting';
import { LocalTariffSettingsService } from 'src/app/services/workspaces/local-tariff-settings.service';
import { PackingMaterialsService } from 'src/app/services/companies/packing-materials.service';
import { QuotesItemsContainersService } from 'src/app/services/companies/quotes-items-containers.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';
import { StorageInvoicesService } from 'src/app/services/companies/storage-invoices.service';
import { StorageService } from 'src/app/entities/workspace/storage-service';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';

declare const jQuery;
declare const swal;
@Component({
  selector: 'app-moving-completions',
  templateUrl: './moving-completions.component.html',
  styleUrls: ['./moving-completions.component.scss']
})
export class MovingCompletionsComponent implements OnInit {

  readonly environment = environment;

  public additionalCharge: JobAdditionalCharge;
  public additionalCharges: Array<JobAdditionalCharge>;
  public additionals: EstimateAdditionalCharge[];
  public additionalsDiscounts: Array<JobAdditionalCharge>;
  public additionalsServices: Array<JobAdditionalCharge>;
  public balanceAmount: number;
  public bulkyFee: Array<any>;
  public companyThemeDocument = { "firstColor": "#ef8400", "secondColor": "#ef8400", "thirdColor": "#ef8400" };
  public dateToday: number;
  public deliveryAdditionals: JobAdditionalCharge[];
  public discounts: EstimateAdditionalCharge[];
  public emailCompanyPermision: string;
  public estimate: EstimateView;
  public expenses: Array<Expense>;
  public itemsContainerInventory: Array<EstimateInventoryContainer>;
  public job: JobView;
  public jobDeliveryFee: JobDeliveryFee;
  public jobLocalTariffLog: JobLocalTariffLog;
  public jobLocalTariffLogsDP: JobLocalTariffLog[];
  public jobLocalTariffLogsJC: JobLocalTariffLog[];
  public jobLocalTariffLogsTravelJC: JobLocalTariffLog[];
  public jobTravelLocalTariffLog: JobLocalTariffLog;
  public localTariffs: LocalTariffSetting[];
  public localTariffSettingId: string;
  public paidBalance: Array<BalanceView>;
  public rateCf: number;
  public rowEstimateMaterials: Array<EstimateMaterialView>;
  public sending: boolean;
  public stopPoints: Array<EstimateStopPoint>;
  public storageServices: Array<StorageService>;
  public totalBalance: number;
  public totalExpenses: number;
  public totalOvertimeTravel: number;
  public totalService: number;
  public typeModal: string;
  public workspaceSettings: WorkspaceSetting;

  @ViewChild('modalAditionalService') modalAditionalService: ElementRef;
  @ViewChild('modalChangeAddress') modalChangeAddress: ElementRef;
  @ViewChild('modalMaterialsAddress') modalMaterialsAddress: ElementRef;

  constructor(
    private balancesService: BalancesService,
    private currentRoute: ActivatedRoute,
    private estimateMaterialsService: PackingMaterialsService,
    private estimatesAdditionalChargesService: EstimatesAdditionalChargesService,
    private estimatesService: EstimatesService,
    private estimateStopPointsService: EstimateStopPointsService,
    private expenseService: ExpenseService,
    private generalSettingsService: GeneralSettingsService,
    private helperService: HelperService,
    private jobsAdditionalChargesService: JobsAdditionalChargesService,
    private jobsService: JobsService,
    private localTariffSettingsService: LocalTariffSettingsService,
    private quotesItemsContainersService: QuotesItemsContainersService,
    private settingsService: SettingsService,
    private storageInvoicesService: StorageInvoicesService,
    public authServices: AuthService,
    public employeesCompanyService: EmployeesCompanyService,
    public jobLocalTariffLogsService: JobLocalTariffLogsService
  ) {
    this.additionalCharge = new JobAdditionalCharge();
    this.additionalCharges = [];
    this.additionals = [];
    this.additionalsDiscounts = [];
    this.additionalsServices = [];
    this.balanceAmount = 0;
    this.bulkyFee = [];
    this.dateToday = Date.now();
    this.deliveryAdditionals = [];
    this.discounts = [];
    this.emailCompanyPermision = '';
    this.estimate = new EstimateView();
    this.expenses = [];
    this.itemsContainerInventory = [];
    this.job = new JobView();
    this.jobDeliveryFee = new JobDeliveryFee();
    this.jobLocalTariffLog = new JobLocalTariffLog();
    this.jobLocalTariffLogsDP = [];
    this.jobLocalTariffLogsJC = [];
    this.jobLocalTariffLogsTravelJC = [];
    this.jobTravelLocalTariffLog = new JobLocalTariffLog();
    this.localTariffs = [];
    this.localTariffSettingId = '';
    this.paidBalance = [];
    this.rateCf = 0;
    this.rowEstimateMaterials = [];
    this.sending = false;
    this.stopPoints = [];
    this.storageServices = [];
    this.totalBalance = 0;
    this.totalExpenses = 0;
    this.totalOvertimeTravel = 0;
    this.totalService = 0;
    this.typeModal = '';
    this.workspaceSettings = new WorkspaceSetting();
  }

  ngOnInit(): void {
    this.themeDocumentColor();
    this.loadWorkspaceSetting();
  }

  ngAfterViewInit(): void {
    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.load(params.id);
      }
    });
  }


  private themeDocumentColor() {

    this.generalSettingsService
      .get()
      .then((response) => {
        this.themeDocument(response.settings.theme_document);
      }
      )
  }

  private loadStorageInvoice(jobId: string) {
    this.storageInvoicesService.getByBOLEstiamteId(jobId)
      .then(response => {
        this.storageServices = response;
      })
  }

  private load(id: string) {
    this.helperService.showLoadingMxpro360();

    this.estimatesService
      .getById(id)
      .then((response) => {
        this.estimate = response;
        this.loadChargeById();
        this.loadStopPoints();
        this.loadPakingMaterials(id);
        this.loadExpenses();
        this.loadAdditionalConcept(id);
        this.loadItemsContainerInventory(id);
        this.loadStorageInvoice(id);
        if (this.estimate.service == 'LOCAL') {
          this.loadJobLocalTariffLogsDP();
          this.loadJobLocalTariffLogsJC();
          this.loadLocalTariffs();

          this.jobTravelLocalTariffLog = new JobLocalTariffLog();
          this.jobTravelLocalTariffLog.origin = 'JOB_COMPLETION';
          this.jobTravelLocalTariffLog.local_tariff_setting = null;
          this.jobTravelLocalTariffLog.hours = this.workspaceSettings.auto_local_tariff.minimum_travel_hours;
          this.jobTravelLocalTariffLog.cost_per_hours = this.workspaceSettings.auto_local_tariff.cost_per_hour;
          this.jobTravelLocalTariffLog.cost_per_miles = this.workspaceSettings.auto_local_tariff.cost_per_mile;
          this.jobTravelLocalTariffLog.miles = this.workspaceSettings.auto_local_tariff.minimum_travel_miles;
          this.jobTravelLocalTariffLog.type = 'TRAVEL';
          this.jobTravelLocalTariffLog.travel_mode = this.workspaceSettings.auto_local_tariff.travel_charge_base_on
          this.jobTravelLocalTariffLog.total = this.workspaceSettings.auto_local_tariff.travel_charge_base_on == 'MILES' ?
            this.jobTravelLocalTariffLog.miles * this.jobTravelLocalTariffLog.cost_per_miles : this.jobTravelLocalTariffLog.hours * this.jobTravelLocalTariffLog.cost_per_hours;
          this.jobTravelLocalTariffLog.job_id = this.estimate.id;
        }
        this.jobsService
          .getById(id)
          .then((response) => {
            this.job = response;
            if (this.job.charges.overload_rate_cf == 0 || this.job.charges.overload_rate_cf == null) {
              this.job.charges.overload_rate_cf = this.estimate.estimate_route_tariff.cf_cost;
            }
            if (this.job.charges.fuel_surcharge_percentage == null || this.job.charges.fuel_surcharge_percentage < 0) {
              // carga el porcentaje de combustible de la api
              this.generalSettingsService
                .get()
                .then((settings) => {
                  this.job.charges.fuel_surcharge_percentage = settings.settings.price_setting.percentage_fuel_surcharge;
                  this.job.charges.fuel_surcharge = this.job.charges.fuel_surcharge_percentage * this.estimate.volume.miles;
                });

            }
            if (this.job.charges.fuel_surcharge == null || this.job.charges.fuel_surcharge < 0) {
              this.generalSettingsService.get().then((settings) => {
                this.job.charges.fuel_surcharge = this.job.charges.fuel_surcharge_percentage * this.estimate.volume.miles;
              });
            }
            this.helperService.calculateTotalDueBalance.emit(this.estimate.id);
          })
          .catch((error) => {
            console.error('Error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
            this.loadBalances();
          });
      })
      .catch((error) => {
        console.error('Error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });

  }

  loadWorkspaceSetting() {
    this.settingsService
      .get()
      .then((responseSettings) => {
        this.workspaceSettings = responseSettings;
      });
  }

  private loadItemsContainerInventory(id: string) {

    this.quotesItemsContainersService
      .get(id)
      .then((response) => {
        this.itemsContainerInventory = response;
        this.chargeBulkyFee();

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  chargeBulkyFee() {
    this.bulkyFee = [];
    const that = this;
    for (let container of this.itemsContainerInventory) {
      for (let item of container['items']) {
        if (item.inventory_item.bulky_fee) {
          if (item.inventory_item.bulky_fee.name) {
            that.bulkyFee.push(item);
          }
        }
      }
    }
  }

  private loadAdditionalConcept(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimatesAdditionalChargesService
      .getAll(id)
      .then((response) => {
        this.getAdditionalAndDiscount(response);
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private getAdditionalAndDiscount(response) {
    this.discounts = [];
    this.additionals = [];
    for (const item of response) {
      if (item.amount < 0) {
        this.discounts.push(item);

      } else {
        this.additionals.push(item);
      }
    }

  }

  private loadExpenses() {
    this.helperService.showLoadingMxpro360();
    this.expenseService
      .getAllExpenses(this.estimate.id)
      .then((response) => {
        this.expenses = response;

        this.totalExpenses = 0;
        for (let expens of this.expenses) {

          this.totalExpenses = this.totalExpenses + expens.amount;

        }
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private themeDocument(themeColor) {

    if (themeColor == "ORANGE") {
      this.companyThemeDocument.firstColor = "#4B4B4D";
      this.companyThemeDocument.secondColor = "#FCB700";
      this.companyThemeDocument.thirdColor = "#F58634";
    }
    else if (themeColor == "TEAL") {
      this.companyThemeDocument.firstColor = "#003649";
      this.companyThemeDocument.secondColor = "#69C795";
      this.companyThemeDocument.thirdColor = "#01B0B5";
    }
    else if (themeColor == "PINK") {
      this.companyThemeDocument.firstColor = "#12273A";
      this.companyThemeDocument.secondColor = "#EF4C70";
      this.companyThemeDocument.thirdColor = "#E13346";
    }
    else if (themeColor == "RED") {
      this.companyThemeDocument.firstColor = "#123056";
      this.companyThemeDocument.secondColor = "#E61938";
      this.companyThemeDocument.thirdColor = "#94061B";
    }
    else if (themeColor == "GREEN") {
      this.companyThemeDocument.firstColor = "#4D4D4D";
      this.companyThemeDocument.secondColor = "#81BF40";
      this.companyThemeDocument.thirdColor = "#3F6B10";
    }
    else if (themeColor == "PURPLE") {
      this.companyThemeDocument.firstColor = "#200924";
      this.companyThemeDocument.secondColor = "#C198C2";
      this.companyThemeDocument.thirdColor = "#792D85";
    }
    else if (themeColor == "DARKSLATEGRAY") {
      this.companyThemeDocument.firstColor = "#0B4757";
      this.companyThemeDocument.secondColor = "#D6DADD";
      this.companyThemeDocument.thirdColor = "#94A7AE";
    }
    else if (themeColor == "BLUELLOW") {
      this.companyThemeDocument.firstColor = "#002346";
      this.companyThemeDocument.secondColor = "#dfb000";
      this.companyThemeDocument.thirdColor = "#d16a01";
    }
    else if (themeColor == "CANDY") {
      this.companyThemeDocument.firstColor = "#069fe9";
      this.companyThemeDocument.secondColor = "#FB3A91";
      this.companyThemeDocument.thirdColor = "#8F1F5A";
    }
    else if (themeColor == "TOXICITY") {
      this.companyThemeDocument.firstColor = "#350455";
      this.companyThemeDocument.secondColor = "#87c900";
      this.companyThemeDocument.thirdColor = "#437600";
    }
    else if (themeColor == "WARM") {
      this.companyThemeDocument.firstColor = "#000e20";
      this.companyThemeDocument.secondColor = "#ff7f45";
      this.companyThemeDocument.thirdColor = "#F12036";
    }
    else if (themeColor == "BLUE") {
      this.companyThemeDocument.firstColor = "#001b38";
      this.companyThemeDocument.secondColor = "#00b3ff";
      this.companyThemeDocument.thirdColor = "#00487a";
    }
    else if (themeColor == "DARKCYAN") {
      this.companyThemeDocument.firstColor = "#012020";
      this.companyThemeDocument.secondColor = "#2f9393";
      this.companyThemeDocument.thirdColor = "#007070";
    }
    else if (themeColor == "LOLLIPOP") {
      this.companyThemeDocument.firstColor = "#001a26";
      this.companyThemeDocument.secondColor = "#7a1759";
      this.companyThemeDocument.thirdColor = "#1884a7";
    }
    else if (themeColor == "NATURA") {
      this.companyThemeDocument.firstColor = "#0086b9";
      this.companyThemeDocument.secondColor = "#78B300";
      this.companyThemeDocument.thirdColor = "#588108";
    }

  }

  public openModalAdditionalCharges(typeModal: string): void {
    this.typeModal = typeModal === 'SERVICE' ? 'Additional Service' : 'Discount';
    jQuery(this.modalAditionalService.nativeElement).modal('show');
  }

  public openModalMaterials() {
    jQuery(this.modalMaterialsAddress.nativeElement).modal('show');
  }

  public closeModalAdditionalCharges(): void {
    this.additionalCharge = new JobAdditionalCharge();
    jQuery(this.modalAditionalService.nativeElement).modal('hide');
  }

  public saveAdditionalCharges(): void {

    if (this.additionalCharge.amount === 0) {
      this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
      return;
    }

    if (this.typeModal === 'Discount') {
      if (this.additionalCharge.amount > 0) {
        this.additionalCharge.amount = this.additionalCharge.amount * -1;
      }
    }

    this.additionalCharge.job_id = this.job.id;
    this.jobsAdditionalChargesService
      .save(this.estimate.id, this.additionalCharge)
      .then(() => {
        this.loadChargeById();
        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        this.load(this.job.id);
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('Error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public loadChargeById(): void {
    this.jobsAdditionalChargesService
      .getAll(this.estimate.id)
      .then((response) => {
        this.additionalCharges = response.filter(a => { return a.type !== 'DELIVERY' || (a.type == 'DELIVERY' && a.amount < 0) });;
        this.deliveryAdditionals = response.filter(a => { return a.type == 'DELIVERY' && a.amount >= 0 });
      })
      .catch((error) => {
        console.error('Error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadBalances() {
    this.balanceAmount = 0;
    this.totalBalance = 0;
    this.paidBalance = [];
    this.helperService.showLoadingMxpro360();
    this.balancesService
      .getForCompletionById(this.estimate.id, {})
      .then((response) => {
        for (const balance of response) {
          if (balance.status === 'PAID') {
            this.balanceAmount = this.balanceAmount + balance.amount;
            this.paidBalance.push(balance);
          }

          if (balance.status === 'PAID' && balance.target !== 'STORAGE') {
            this.totalBalance = this.totalBalance + balance.amount;
          }
        }

        this.totalBalance = this.job.total - this.totalBalance;

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  updateJob(): void {
    const data = {
      slip_remarks: this.job.slip_remarks,
      internal_remarks: this.job.internal_remarks,
      cubic_feets: this.job.cubic_feets,
      charges: {
        rate_cf: this.job.charges.rate_cf,
        overload_rate_cf: this.job.charges.overload_rate_cf,
        fuel_surcharge_percentage: this.job.charges.fuel_surcharge_percentage,
      }
    }
    this.job.overload_cf = this.job.cubic_feets - this.estimate.volume.cubic_feets_selled;

    this.jobsService
      .patchEntity(this.job.id, data)
      .then((response) => {
        this.load(this.job.id);
        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('Error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public editAdditionalCharges(service, typeModal) {
    this.typeModal = typeModal === 'SERVICE' ? 'Additional Service' : 'Discount';
    this.additionalCharge = service;
    jQuery(this.modalAditionalService.nativeElement).modal('show');
  }

  public deleteAdditionalCharges(idService) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    }).then((result) => {
      if (result.value) {
        this.helperService.showLoadingMxpro360();
        this.jobsAdditionalChargesService
          .remove(this.job.id, idService)
          .then((response) => {
            this.load(this.job.id);
            this.helperService.showMessageSnackbar(ConstantsMessages.DELETED);
          })
          .catch((error) => {
            this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_DELETED);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
    });
  }

  public openModalChangeAddress(): void {
    jQuery(this.modalChangeAddress.nativeElement).modal('show');
  }
  setTo(to) {
    this.jobDeliveryFee.address = to;
  }

  saveMoving() {
    const jobDeliveryFee = { job_delivery_fee: this.jobDeliveryFee };
    this.helperService.showLoadingMxpro360();
    this.jobsAdditionalChargesService
      .updateAddress(this.job.id, jobDeliveryFee)
      .then((response) => {
        this.load(this.job.id);
        this.helperService.showMessageSnackbar(ConstantsMessages.DELETED);
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_DELETED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  loadStopPoints() {
    const jobDeliveryFee = { job_delivery_fee: this.jobDeliveryFee };
    this.helperService.showLoadingMxpro360();
    this.estimateStopPointsService
      .getAll(this.estimate.id)
      .then((response) => {
        this.stopPoints = response;
      })
      .catch((error) => {
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  private loadPakingMaterials(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimateMaterialsService
      .getMaterialsByJobId(id)
      .then((response) => {
        this.rowEstimateMaterials = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  savePakingMaterials() {
    this.helperService.showLoadingMxpro360();
    const readySave = this.rowEstimateMaterials.filter(x => x.quantity > 0);
    this.estimateMaterialsService
      .saveJob(readySave, this.job.id)
      .then(() => {
        this.load(this.job.id);
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }



  modalInvoice() {
    this.employeesCompanyService
      .getCompanyPermisions(this.estimate.employee.id)
      .then((response) => {
        if (response) {
          this.emailCompanyPermision = response.email.address;
        } else {
          this.emailCompanyPermision = 'No Associated Email';
        }


      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });

    this.additionalsServices = [];
    this.additionalsDiscounts = [];

    for (let additionalCharge of this.additionalCharges) {

      if (additionalCharge.amount > 0) {
        this.additionalsServices.push(additionalCharge);
      }
      else {
        this.additionalsDiscounts.push(additionalCharge);
      }

    }

  }

  downloadInvoiceCompletions() {
    this.helperService.downloadReport('INVOICE_COMPLETIONS', this.estimate.id);
  }

  sendEmailInvoiceCompletions() {
    this.sending = true;
    this.jobsService.sendInvoiceByEmail(this.estimate.id)
      .then(() => {
        // mostrar snakbar de envío exitoso
        this.helperService.showMessageSnackbar(ConstantsMessages.EMAIL_SENT);
      })
      .catch(() => {
        // muestra mensaje de error
        this.helperService.showMessageSnackbar('Email was not sent');
      }).finally(() => {
        this.sending = false;
      });
  }


  loadJobLocalTariffLogsDP() {
    this.helperService.showLoadingMxpro360();
    this.jobLocalTariffLogsService
      .getDigitalJobLogsByEstimateId(this.estimate.id)
      .then((response) => {
        this.jobLocalTariffLogsDP = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  loadJobLocalTariffLogsJC() {
    this.helperService.showLoadingMxpro360();
    this.jobLocalTariffLogsService
      .getJobCompletionJobLogsByEstimateId(this.estimate.id)
      .then((response) => {
        this.jobLocalTariffLogsJC = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    this.jobLocalTariffLogsService
    .getJobCompletionJobTravelLogsByEstimateId(this.estimate.id)
    .then((response) => {
      this.jobLocalTariffLogsTravelJC = response;
      this.calculateTotalOvertimeTravel();
    })
    .catch((error) => {
      console.error('error', error);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }
  
  calculateTotalOvertimeTravel() {
    this.totalOvertimeTravel = 0;
    for (let localTravelLog of this.jobLocalTariffLogsTravelJC) {
      this.totalOvertimeTravel += localTravelLog.total;
    }
  }

  loadLocalTariffs() {
    this.helperService.showLoadingMxpro360();
    this.localTariffSettingsService.getAll()
      .then((result) => {
        this.localTariffs = result.filter(x => x.active);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  setLocalTariffSetting(itemIndex) {
    if (itemIndex == null) {
      const localTariffsSelected = this.localTariffs.find(item => item.id = this.localTariffSettingId);
      this.jobLocalTariffLog = new JobLocalTariffLog();
      this.jobLocalTariffLog.origin = 'JOB_COMPLETION';
      this.jobLocalTariffLog.local_tariff_setting = localTariffsSelected;
      this.jobLocalTariffLog.hours = localTariffsSelected.max_hours;
      this.jobLocalTariffLog.cost_per_hours = localTariffsSelected.price_per_hour;
      this.jobLocalTariffLog.total = localTariffsSelected.max_hours * localTariffsSelected.price_per_hour;
      this.jobLocalTariffLog.job_id = this.estimate.id;
    } else {
      const localTariffsSelected = this.localTariffs.find(item => item.id = this.jobLocalTariffLogsJC[itemIndex].local_tariff_setting.id);
      this.jobLocalTariffLogsJC[itemIndex].local_tariff_setting = localTariffsSelected;
      this.jobLocalTariffLogsJC[itemIndex].hours = localTariffsSelected.max_hours;
      this.jobLocalTariffLogsJC[itemIndex].cost_per_hours = localTariffsSelected.price_per_hour;
      this.jobLocalTariffLogsJC[itemIndex].total = localTariffsSelected.max_hours * localTariffsSelected.price_per_hour;
    }
  }

  setLocalTravelTariffSetting(itemIndex) {
    const localTariffsSelected = this.localTariffs.find(item => item.id = this.jobLocalTariffLogsJC[itemIndex].local_tariff_setting.id);
    this.jobLocalTariffLogsJC[itemIndex].local_tariff_setting = localTariffsSelected;
    this.jobLocalTariffLogsJC[itemIndex].hours = localTariffsSelected.max_hours;
    this.jobLocalTariffLogsJC[itemIndex].cost_per_hours = localTariffsSelected.price_per_hour;
    this.jobLocalTariffLogsJC[itemIndex].total = localTariffsSelected.max_hours * localTariffsSelected.price_per_hour;
  }

  setTotalTariff(index) {
    if (index == null) {
      this.jobLocalTariffLog.total = this.jobLocalTariffLog.hours * this.jobLocalTariffLog.cost_per_hours;
    } else {
      this.jobLocalTariffLogsJC[index].total = this.jobLocalTariffLogsJC[index].hours * this.jobLocalTariffLogsJC[index].cost_per_hours;
    }
  }
  setTravelTotalTariff(index) {
    if (index == null) {
      if (this.workspaceSettings.auto_local_tariff.travel_charge_base_on == 'TIMES') {
        this.jobTravelLocalTariffLog.total = this.jobTravelLocalTariffLog.hours * this.jobTravelLocalTariffLog.cost_per_hours;
      } else {
        this.jobTravelLocalTariffLog.total = this.jobTravelLocalTariffLog.miles * this.jobTravelLocalTariffLog.cost_per_miles;
      }
    } else {
      if (this.workspaceSettings.auto_local_tariff.travel_charge_base_on == 'TIMES') {
        this.jobLocalTariffLogsTravelJC[index].total = this.jobLocalTariffLogsTravelJC[index].hours * this.jobLocalTariffLogsTravelJC[index].cost_per_hours;
      } else {
        this.jobLocalTariffLogsTravelJC[index].total = this.jobLocalTariffLogsTravelJC[index].miles * this.jobLocalTariffLogsTravelJC[index].cost_per_miles;
      }
    }
  }

  saveLocalTariffLog(laborType: 'TRAVEL' | 'LABOR') {
    const data = laborType == 'LABOR' ? this.jobLocalTariffLog : this.jobTravelLocalTariffLog;
    this.helperService.showLoadingMxpro360();
    this.jobLocalTariffLogsService.saveJobLocalTariffLog(this.estimate.id, data, laborType)
      .then(() => {
        this.load(this.job.id);
        this.jobLocalTariffLog = new JobLocalTariffLog();
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  }
  editLog(itemIndex) {
    this.helperService.showLoadingMxpro360();
    this.jobLocalTariffLogsService.updateJobLocalTariffLog(this.jobLocalTariffLogsJC[itemIndex])
      .then(() => {
        this.load(this.job.id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  }
  editTravelLog(itemIndex) {
    this.helperService.showLoadingMxpro360();
    this.jobLocalTariffLogsService.updateJobLocalTariffLog(this.jobLocalTariffLogsTravelJC[itemIndex])
      .then(() => {
        this.load(this.job.id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  }
  removeLog(itemIndex, travel) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          let idToDelete: string;
          if (travel) {
            idToDelete = this.jobLocalTariffLogsTravelJC[itemIndex].id;
          } else {
            idToDelete = this.jobLocalTariffLogsJC[itemIndex].id;
          }
          this.jobLocalTariffLogsService.deleteJobLocalTariffLog(idToDelete)
            .then(() => {
              this.load(this.job.id);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }
}
