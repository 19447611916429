<div class="row layout-top-spacing">
  <div class="col-lg-6">
    
    <div class="statbox widget box box-shadow center">

      <div class="widget-content widget-content-area">

        <div class="widget-header">
          <h3>New Ticket</h3>
        </div>

        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
          <div class="row">
            <div class="col-12">
                  
              <!-- <div class="form-group mb-1">
                  <label class="control-label ">Subject:</label>
                  <div class="input-group mb-1">
                      <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon5"><svg
                                  xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                  stroke-linecap="round" stroke-linejoin="round"
                                  class="feather feather-user">
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="12" cy="7" r="4"></circle>
                              </svg></span>
                      </div>
                      <input type="text " [(ngModel)]="dataForm.subject" name="subject" class="form-control">
                  </div>

                  <small id="emailHelp1" class="form-text text-muted mb-4">Write Subject Ticket</small>
              </div> -->     


              <div class="form-group mb-4 ">
                <label class="control-label">Subject</label>
                <select id="inputState" [(ngModel)]="dataForm.ticket_type_id"  name="pg_type"  class="form-control"  (change)="changePriority($event.target.value)">
                  <option *ngFor="let tickesType of ticketsTypes" [value]="tickesType.id">{{tickesType.name | titlecase}}</option>
                </select>
                <small id="emailHelp1" class="form-text text-muted mb-4">Select Subject of Ticket.</small>
              </div>

              <div class="form-group mb-4">
                <label class="control-label ">Status</label>
                <select name="status" class="form-control" [(ngModel)]="dataForm.status">
                  <option value="OPEN">
                    Open
                  </option>
                  <option value="PENDING">
                    Pending
                  </option>
                  <option value="RESOLVED">
                    Resolved
                  </option>
                  <option value="CLOSED">
                    Closed
                  </option>
                  <option value="WAITING_COSTUMER">
                    Waiting Customer
                  </option>
                </select>
                <small id="emailHelp1" class="form-text text-muted mb-4">Select Status of Ticket.</small>
              </div>
              <div class="form-group mb-6 ">
                <label class="control-label ">Priority</label>
                <select name="priority" disabled="disabled" class="form-control" [(ngModel)]="dataForm.priority">
                  <option value="LOW">
                    LOW
                  </option>
                  <option value="MID">
                    MID
                  </option>
                  <option value="HIGH">
                    HIGH
                  </option>
                  <option value="URGENT">
                    URGENT
                  </option>
                </select>
                <small id="emailHelp1" class="form-text text-muted mb-4">Select Priority of Ticket</small>
              </div>
              <div class="form-group mb-4">
                <label class="control-label">Description</label>
                <textarea name="description_ticket" id="description_ticket" [(ngModel)]="dataForm.description"
                  class="form-control"></textarea>
                  <small id="emailHelp1" class="form-text text-muted mb-4">Write Description Ticket.</small>
              </div>
            </div>
          </div>
          <div class="text-center ">
            <button class="btn btn-save ml-1 mt-3" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>


  </div>
</div>