<div class="layout-top-spacing">
    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span> Deductible Level
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/deductible-levels">Deductible Level</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div>
    <!-- header -->


    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="row">
                            <div class="col-12">


                                <div class="form-group mb-4">
                                    <label class="control-label">Level</label>
                                    <input type="number" step="0.01" [(ngModel)]="dataForm.level" name="wd_level" class="form-control">
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Write Level.</small>
                                </div>

                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-save ml-1 mt-3" type="submit">
                                Submit
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>
    </div>
</div>