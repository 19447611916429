<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 align-self-center mb-4">
            <h3>
                Buy Phone Numbers
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Buy Phone Numbers
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search align-self-center mb-3">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right top-action-btn">
                <!-- <a appWorkspaceRouterLink="/advertisers/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Advertiser
                </a> -->
                <button class="btn btn-success" (click)="openBuyNumber()">
                    <i class="fa-solid fa-cart-shopping mr-2"></i>
                    Buy Regulars Numbers
                </button>
                <button class="btn btn-info" (click)="openBuyNumberTollFree()">
                    <i class="fa-solid fa-cart-shopping mr-2"></i>
                    Buy Toll Free Numbers
                </button>
            </div>
        </div>
    </div>
    <!-- header -->


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <h3 class="title-content-area mb-5">
                    My Phone Numbers
                </h3>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Phone Number</th>
                                <th class="text-center">Assigned</th>
                                <th>Company</th>
                                <th>User</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="numbersPhones">
                            <tr *ngFor="let phones of numbersPhones['assigned'] | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>
                                    {{i + 1}}
                                </td>
                                <td>
                                    <div>
                                        {{phones.phone_number | phone}}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span *ngIf="phones.phone_number" class="number-assigned">
                                        <i class="fa-solid fa-square-check"></i>
                                    </span>
                                    <span *ngIf="!phones.phone_number" class="unassigned-number">
                                        <i class="fa-solid fa-square-minus"></i>
                                    </span>

                                </td>
                                <td>
                                    <span *ngIf="phones.phone_number">
                                        Moverxpro360 Moving LLC
                                    </span>
                                    <span *ngIf="!phones.phone_number" class="unassigned-company">
                                        Unassigned
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="!phones.employee_name && phones.name">
                                        {{phones.name | titlecase}}
                                    </span>
                                    <span *ngIf="phones.employee_name">
                                      {{phones.employee_name | titlecase}}
                                  </span>
                                    <span *ngIf="!phones.employee_name && !phones.name" class="unassigned-user">
                                        Unassigned
                                    </span>
                                </td>
                                <td>
                                    <div class="block-btn-options">
                                        <button class="btn btn-danger" (click)="disconnectNumbers(phones)">
                                            <i class="fa-solid fa-phone-slash"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let phones of numbersPhones['not_assigned'] | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>
                                    {{i + 1}}
                                </td>
                                <td>
                                    <div>
                                        {{phones | phone}}
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span *ngIf="phones.phone_number" class="number-assigned">
                                        <i class="fa-solid fa-square-check"></i>
                                    </span>
                                    <span *ngIf="!phones.phone_number" class="unassigned-number">
                                        <i class="fa-solid fa-square-minus"></i>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="phones.phone_number">
                                        Moverxpro360 Moving LLC
                                    </span>
                                    <span *ngIf="!phones.phone_number" class="unassigned-company">
                                        Unassigned
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="phones.employee_name">
                                        {{phones.employee_name | titlecase}}
                                    </span>
                                    <span *ngIf="!phones.employee_name" class="unassigned-user">
                                        Unassigned
                                    </span>
                                </td>
                                <td>
                                    <div class="block-btn-options">
                                        <button class="btn btn-danger" (click)="removeNumbers(phones)">
                                            <i class="fa-solid  fa-trash-can"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>

                </div>
            </div>
        </div>
    </div>

</div>

<!-- Modal - Buy Numbers Toll Free -->
<div class="modal fade" #buyNumbersTollFreeModal id="buynumber" tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">Buy Numbers Toll Free</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <p class="text-center butoons"> <strong>   you sure you want to buy the selected numbers ?</strong></p>
                    <div class="col-md-5 panel-signature">
                    <button data-dismiss="modal" class="btn btn-warning butoons" (click)="buyNumbersTollFree()" >Yes</button>
                    <button data-dismiss="modal" class="btn btn-danger butoons" (click)="buyDecline()" >No</button>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>




<!-- Modal Disconnect and Delete Numbers  -->
<div class="modal fade" #disconnectNumbersModal id="buynumber" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">Disconnect Numbers</h5>
            </div>
            <div class="modal-body">
                <div>
                    Are you sure you want to disconnect your number?
                </div>
            </div>
            <div class="modal-footer">
                <button data-dismiss="modal" class="btn btn-cancel" (click)="disconnectDeleteNumber()">
                    Cancel
                </button>
                <!-- <button data-dismiss="modal" class="btn btn-save" (click)="disconnectNumbers()">
                    Yes
                </button> -->
            </div>
        </div>
    </div>
</div>

<!-- Modal - Compra de Números Regulares-->
<div class="modal fade" id="buynumber" #buynumber tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-buy-numbers" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Buy Regular Phone Numbers
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-5">
                            <label class="control-label">Code Area</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg viewBox="0 0 24 24" width="24"
                                            height="24" stroke="currentColor" stroke-width="2" fill="none"
                                            stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                            <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                            <line x1="8" y1="2" x2="8" y2="18"></line>
                                            <line x1="16" y1="6" x2="16" y2="22"></line>
                                        </svg>
                                    </span>
                                </div>
                                <input type="text" minlength="1" maxlength="3" [(ngModel)]="codeArea"
                                    placeholder="Code Area" name="codeArea" mask="000" class="form-control">
                            </div>
                            <small id="areacode" class="form-text text-muted mb-4">Enter Code Area.</small>
                        </div>

                        <div class="col-5">
                            <label class="control-label">Quantity</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                    </span>
                                </div>
                                <input type="text" minlength="1" maxlength="6" [(ngModel)]="quantity" mask="0000000"  placeholder="Quantity"
                                    name="quantyti" class="form-control">
                            </div>
                            <small id="areacode" class="form-text text-muted mb-4">Enter the quantity of numbers to search for.</small>
                        </div>

                        <div class="col-2">
                            <button class="btn btn-info btn-search-numbers" (click)="searchNumber()">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </button>
                        </div>

                        <div *ngIf="buyNumbersDisable" class="col-12 container-numbers-available-buy">
                            <div class="row numbers-available-buy">
                                <ng-container *ngFor="let phone of numbers">
                                    <div class="form-group available-number">
                                        <input type="checkbox" id="{{phone.number}}" (click)="aTNumbers(phone)">
                                        <label for="{{phone.number}}">
                                            <i class="fa-solid fa-phone mr-2"></i>
                                            <span>{{phone.number | phone}}</span>
                                        </label>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="numbers.length == 0">
                                    <span style="color:red;">*No numbers currently available with {{codeArea}}*</span>
                                </ng-container>
                            </div>
                            <span class="label-select-number">*Select the phone numbers you want to buy.</span>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="closeBuyNumber()" class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button class="btn btn-save" *ngIf="buyNumbersDisable" (click)="buyNumbers()">
                        Buy Number<span *ngIf="phones.length > 1">s</span>
                    </button>
                </div>
            </div>
        </div>
</div>

<!-- Modal - Compra de Números Toll Free-->
<div class="modal fade" id="buynumber" #modalBuyNumbersTollFree tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-buy-numbers" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Buy Toll Free Phone Numbers
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-5">
                        <label class="control-label">Toll Free Number</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg viewBox="0 0 24 24" width="24"
                                        height="24" stroke="currentColor" stroke-width="2" fill="none"
                                        stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                                        <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                        <line x1="8" y1="2" x2="8" y2="18"></line>
                                        <line x1="16" y1="6" x2="16" y2="22"></line>
                                    </svg>
                                </span>
                            </div>

                            <select name="ring_target" class="form-control" [(ngModel)]="codeAreaTollFreewildcardPattern">
                                <option value="80">800</option>
                                <option value="83">833</option>
                                <option value="84">844</option>
                                <option value="85">855</option>
                                <option value="86">866</option>
                                <option value="87">877</option>
                                <option value="88">888</option>

                            </select>
                        </div>
                        <small id="areacode" class="form-text text-muted mb-4">Enter Toll Free Number.</small>
                    </div>

                    <div class="col-5">
                        <label class="control-label">Quantity</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
                                </span>
                            </div>
                            <input type="text" minlength="1" maxlength="6" placeholder="Quantity" [(ngModel)]="quantityTollFree"
                                name="quantityTollFree" mask="0000000" class="form-control">
                        </div>
                        <small id="areacode" class="form-text text-muted mb-4">Enter the quantity of numbers to search for.</small>
                    </div>

                    <div class="col-2">
                        <button class="btn btn-info btn-search-numbers" (click)="searchNumberTollFreewildcardPattern()">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                    </div>

                    <div *ngIf="buyNumbersTollFreeDisable" class="col-12 container-numbers-available-buy">
                        <div class="row numbers-available-buy">
                            <ng-container *ngFor="let phones of numberTollFree">
                                <div class="form-group available-number">
                                    <input type="checkbox" id="{{phones.number}}" (click)="aTNumbersTollFree(phones)">
                                    <label for="{{phones.number}}">
                                        <i class="fa-solid fa-phone mr-2"></i>
                                        <span>{{phones.number | phone}}</span>
                                    </label>
                                </div>
                            </ng-container>
                        </div>
                        <span class="label-select-number">*Select the phone numbers you want to buy.</span>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeBuyNumber()" class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" *ngIf="buyNumbersTollFreeDisable" (click)="successfulPurchaseTollFree()">
                    Buy Number<span *ngIf="phonesTollFree.length > 1">s</span>
                </button>
            </div>
        </div>
    </div>
</div>


