import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { EmailTemplatesService } from 'src/app/services/companies/email-templates.service';
import { HelperService } from 'src/app/services/helper.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { DocumentsTypesService } from 'src/app/services/companies/documents-types.service';



declare const jQuery;
declare const swal;

@Component({
    selector: 'app-email-templates-form',
    templateUrl: './email-templates-form.component.html',
    styleUrls: ['./email-templates-form.component.scss']
})
export class EstimatesEmailTemplatesFormComponent implements OnInit {

    public dataForm: EmailTemplate;
    public workDepartments: WorkDepartment[];
    public documentsPPW: Array<any>;

    constructor(
        private currentRoute: ActivatedRoute,
        private estimateEmailTemplatesService: EmailTemplatesService,
        private workDepartmentsService: WorkDepartmentsService,
        public helperService: HelperService,
        private documentsTypes: DocumentsTypesService,
    ) {
        this.dataForm = new EmailTemplate();
        this.workDepartments = [];
        this.documentsPPW = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        const that = this;
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
        this.helperService.ckeditor = 'documents';
    }

    setFileType(position, type: string) {
        const fileType = position.id.split(':')[1].trim().replace(/'/g, '');
        if (this.dataForm.attach_file_types.length > 0) {
            const indexJobPosition = this.dataForm.attach_file_types.indexOf(fileType);
            if (type === 'select') {
                if (indexJobPosition === -1) {
                    this.dataForm.attach_file_types.push(fileType);
                }
            }

            if (type === 'unselect') {
                if (indexJobPosition >= 0) {
                    this.dataForm.attach_file_types.splice(fileType, 1);
                }
            }
        } else {
            if (type === 'select') {
                this.dataForm.attach_file_types.push(fileType);
            }
        }
    }

    setMethodsEnabled(position, type: string) {
        const nameJobPosition = position.id.split(':')[1].trim().replace(/'/g, '');
        if (this.dataForm.work_department_ids.length > 0) {
            const indexJobPosition = this.dataForm.work_department_ids.indexOf(nameJobPosition);
            if (type === 'select') {
                if (indexJobPosition === -1) {
                    this.dataForm.work_department_ids.push(nameJobPosition);
                }
            }

            if (type === 'unselect') {
                if (indexJobPosition >= 0) {
                    this.dataForm.work_department_ids.splice(nameJobPosition, 1);
                }
            }
        } else {
            if (type === 'select') {
                this.dataForm.work_department_ids.push(nameJobPosition);
            }
        }
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimateEmailTemplatesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                this.loadDocuments();
                this.loadWorkDeparments();
                const that = this;
                jQuery('.file-type').css('width', '460px');
                const select_filter = jQuery('.file-type');
                let idTimeOut = null;

                select_filter.select2({
                    tags: true
                })
                .on('select2:select', (e) => {
                    that.setFileType(e.params.data, 'select');
                })
                .on('select2:unselect', (e) => {
                    that.setFileType(e.params.data, 'unselect');
                });

                // si hay cambios en el dom refrescamos el plugin
                select_filter.bind('DOMSubtreeModified', () => {

                    if (idTimeOut !== null) {
                        clearTimeout(idTimeOut);
                    }

                    idTimeOut = setTimeout(() => {
                        select_filter.trigger('change');
                    }, 1000);
                });

                // forzar refresco
                setTimeout(() => {
                    const select = jQuery('.file-type');
                    select.trigger('change');
                }, 2000);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.estimateEmailTemplatesService
            .save(this.dataForm)
            .then(() => {
                this.helperService.goToCompanyRouterLink('/email-templates');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadWorkDeparments() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.workDepartments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private loadDocuments() {
        this.helperService.showLoadingMxpro360();
        this.documentsTypes
          .getAll()
          .then((response) => {
            this.documentsPPW = response;
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
}
