import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';
declare var ApexCharts;

@Component({
  selector: 'app-new-estimates-last7-days',
  templateUrl: './new-estimates-last7-days.component.html',
  styleUrls: ['./new-estimates-last7-days.component.scss']
})
export class NewEstimatesLast7DaysComponent implements OnInit {
    jobsHowManyNewEstimatesTheySentInTheLast7Days: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    )  { 
    this.jobsHowManyNewEstimatesTheySentInTheLast7Days = {
        "total_estimates": 0
    };
  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadHowManyNewEstimatesTheySentInTheLast7DaysCompany();
    } else {
        this.loadHowManyNewEstimatesTheySentInTheLast7DaysWorkspace();
    }
  }

  
  loadHowManyNewEstimatesTheySentInTheLast7DaysWorkspace(){
    this.dashboardWorkspaceService
        .getHowManyNewEstimatesTheySentInTheLast7Days()
        .then((response) => {
            this.jobsHowManyNewEstimatesTheySentInTheLast7Days = response;
            setTimeout(() => {
                this.totalIncomingLeads();
            }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadHowManyNewEstimatesTheySentInTheLast7DaysCompany(){
    this.dashboardComopanyService
        .getHowManyNewEstimatesTheySentInTheLast7Days()
        .then((response) => {
            this.jobsHowManyNewEstimatesTheySentInTheLast7Days = response;
            setTimeout(() => {
                this.totalIncomingLeads();
            }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}



totalIncomingLeads() {

    const that = this;
  
    var options = {
      chart: {
        id: 'sparkline1',
        group: 'sparklines',
        type: 'area',
        height: 190,
        sparkline: {
          enabled: true
        },
      },
      stroke: {
          curve: 'smooth',
          width: 2
      },
      fill: {
        type:"gradient",
        gradient: {
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: !1,
            opacityFrom: .80,
            opacityTo: .5,
            stops: [100, 100]
        }
      },
      series: [{
        name: 'Sales',
        data: [28, 20, 36, 52, 38, 60, 18, 52, 36, 40]
      }],
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      yaxis: {
        min: 0
      },
      grid: {
        padding: {
          top: 125,
          right: 0,
          bottom: 0,
          left: 0
        }, 
      },
      tooltip: {
        x: {
          show: false,
        },
        theme: 'dark'
      },
      colors: ['#ffffff']
    }
  
  
    var chart = new ApexCharts(
        document.querySelector("#new-estimates-last7-days"),
        options
    );
  
    chart.render();
  }
  

}
