<div class="layout-top-spacing">
    <div class="widget-content widget-content-area">
        <div class="widget-heading d-flex justify-content-between">
            <h3 class="title-content-area">
                Signatures
            </h3>
        </div>
    </div>
</div>

<div class="layout-spacing layout-top-spacing">
    <div class="widget-content widget-content-area">
        <div class="table-responsive">
            <table class="table table-bordered table-estimate-details-signatures mb-4">
                <thead>
                    <tr>
                        <th class="text-center">Signature</th>
                        <th class="text-center">Type</th>
                        <th class="text-center">Estimate Sent by</th>
                        <th class="text-center">Date Signed</th>                                
                        <th>Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let signature of signatures | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                        <td class="text-center">
                            <span class="signatures-size" *ngIf="signature.document != null">
                                <ng-container *ngIf="signature.document.esignatures.main != null && signature.document.esignatures.second == null && signature.document.esignatures.third == null">
                                    <img appCdnLink="/e-signatures/{{signature.document.esignatures.main.esignature_file.display_name}}">
                                </ng-container>
                                <ng-container *ngIf="signature.document.esignatures.second != null && signature.document.esignatures.third == null">
                                    <img appCdnLink="/e-signatures/{{signature.document.esignatures.second.esignature_file.display_name}}">
                                </ng-container>
                                <ng-container *ngIf="signature.document.esignatures.third != null">
                                    <img appCdnLink="/e-signatures/{{signature.document.esignatures.third.esignature_file.display_name}}">
                                </ng-container>
                                <ng-container *ngIf="signature.document.esignatures.main == null">
                                    <span class="badge badge-danger">
                                        None
                                    </span>
                                </ng-container>
                            </span>

                            <div *ngIf="signature.document == null" class="text-center">
                                <span class="badge badge-danger">
                                    None
                                </span>
                            </div>
                        </td>
                        <td class="text-center">
                            <div *ngFor="let dt of documentTypes">
                                <span *ngIf="dt.id == signature.document.document_type_id">
                                    {{dt.name | titlecase}}
                                </span>
                            </div>
                        </td>
                        <td class="text-center">
                            <span *ngIf="signature.estimate_email != null">
                                {{signature.estimate_email.from.name | titlecase}}
                            </span>

                            <div *ngIf="signature.estimate_email == null">
                                <span class="badge badge-danger">
                                    None
                                </span>
                            </div>
                        </td>
                        <td class="text-center">
                            <div *ngIf="signature.document; else noneSignature">
                                <div *ngIf="signature.document.esignatures.main && signature.document.esignatures.second == null && signature.document.esignatures.third == null">
                                    <span>
                                        {{signature.document.esignatures.main.created | date}}
                                    </span>
                                </div>
                                <div *ngIf="signature.document.esignatures.second && signature.document.esignatures.third == null">
                                    <span>
                                        {{signature.document.esignatures.second.created | date}}
                                    </span>
                                </div>
                                <div *ngIf="signature.document.esignatures.third">
                                    <span>
                                        {{signature.document.esignatures.third.created | date}}
                                    </span>
                                </div>
                            </div>
                            
                            <ng-template #noneSignature>
                                <span class="badge badge-danger">
                                    None
                                </span>
                            </ng-template>
                        </td>                                
                        <td>
                            <span>
                                {{signature.created | dateTime}}
                            </span>
                        </td>
                        <td>
                            <button *ngIf="signature.document != null" class="btn btn-info button-space" type="submit" (click)="openSignatureModal(signature)">
                                <i class="fa-solid fa-eye"></i>
                            </button>
                            <button class="btn btn-secondary button-space btn-download" (click)="downloadPdf(signature)">
                                <i class="fa-solid fa-file-arrow-down"></i>
                            </button>                                    
                            <button *ngIf="signature.version_id != null && !estimate.booked" class="btn btn-danger button-space" type="submit" (click)="revert(signature.version_id)">
                                <span>Revert</span>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls class="paginating-container pagination-solid"
                (pageChange)="actualPage = $event"></pagination-controls>
        </div>
    </div>
</div>

<!-- Modal Detail Signature-->
<div class="modal fade modal-detail-signature" #signatureModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Detail Signature
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">

                            <div class="form-group">
                                <div class="user-info-list">

                                    <table class="table table-bordered mb-4">
                                        <thead>
                                            <tr>
                                                <th>Signature</th>
                                                <th>IP</th>
                                                <th>Internet Browser</th>
                                                <th>Date Signed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span *ngIf="electronicSignature && electronicSignature.esignature_file != null && (electronicSignature.esignature_file.display_name != null)">
                                                        <img class="modal-carrier-documents-detail-signature" [appCdnLink]="'/e-signatures/' + electronicSignature.esignature_file.display_name">
                                                    </span>

                                                    <span *ngIf="!electronicSignature">
                                                        <span class="badge outline-badge-danger">
                                                            None
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    {{electronicSignature.ip_address}}
                                                </td>
                                                <td>
                                                    {{electronicSignature.internet_browser}}
                                                </td>
                                                <td>
                                                    {{electronicSignature.created | dateTime}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button data-dismiss="modal" type="button" class="btn btn btn-info">
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
