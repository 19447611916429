import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Employee } from 'src/app/entities/workspace/employee';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { Payroll } from 'src/app/entities/companies/payroll';
import { PayrollBalance } from 'src/app/entities/workspace/payroll-balance';
import { PayrollBreakdownService } from 'src/app/services/companies/payroll-breakdown.service';
import { PayrollPayment } from 'src/app/entities/workspace/payroll-payment';
import { PayrollSetting } from 'src/app/entities/workspace/payroll-setting';
import { TimeClock } from 'src/app/entities/companies/time-clock';

declare const jQuery: any;
declare const moment: any;
declare const swal: any;
@Component({
  selector: 'app-payroll-payments-form',
  templateUrl: './payroll-payments-form.component.html',
  styleUrls: ['./payroll-payments-form.component.scss']
})
export class PayrollPaymentsFormComponent implements OnInit {

  @ViewChild('payrollBreakdownModal') payrollBreakdownModal: ElementRef;

  public arrayReponseAll: any[];
  public cancelations: any[];
  public clockEmployees: ClockEmployees;
  public commissionError: number;
  public commissionErrorEmployee: string;
  public commissions: any[];
  public employees: Employee[];
  public hoursConditions: number;
  public payPeriod: string;
  public payroll: Payroll;
  public payrollBalances: PayrollBalance[];
  public payrollPayment: PayrollPayment;
  public payrollPayments: PayrollPayment[];
  public payrollSetting: PayrollSetting;
  public payrollStartDay: any;
  public previusPayroll: Payroll;
  public timeClock: TimeClock[];
  public totalExtras: number;

  constructor(
    private generalSettingsService: GeneralSettingsService,
    private helperService: HelperService,
    private payrollBreakdownService: PayrollBreakdownService,

  ) {
    this.arrayReponseAll = [];
    this.clockEmployees = new ClockEmployees();
    this.commissionError = null;
    this.commissionErrorEmployee = null;
    this.commissions = []
    this.employees = [];
    this.hoursConditions = 0;
    this.payPeriod = null;
    this.payroll = new Payroll();
    this.payroll = new Payroll();
    this.payrollPayment = new PayrollPayment();
    this.payrollPayments = [];
    this.payrollSetting = new PayrollSetting();
    this.payrollStartDay = null;
    this.previusPayroll = new Payroll();
    this.timeClock = [];
    this.totalExtras = 0;
  }

  ngOnInit(): void {
    this.loadEmployees();
    this.loadPreviusPayrollPayment();
  }
  
  private loadCompanySettings(calculate) {
      this.generalSettingsService
          .get()
          .then((response) => {
            this.payrollStartDay = response.settings.payroll_start_day;
            this.payPeriod = response.settings.pay_period;
            this.setEndPeriod(calculate);
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

  setEndPeriod(setting){
    if (setting) {
      if(this.payrollStartDay){
        this.payroll.start_period = moment(this.payrollStartDay).second(0).minute(0).hour(0).unix() * 1000;
      } else {
          this.payroll.start_period = moment().second(0).minute(0).hour(0).unix() * 1000;
      }
    }
    
    this.payroll.end_period = moment(this.payroll.start_period).add(1, 'weeks').subtract(1,'d').second(59).minute(59).hour(23).unix() * 1000;

    if (this.payPeriod == 'WEEKLY'){
      this.hoursConditions = 40;
      this.payroll.end_period = moment(this.payroll.start_period).add(1, 'weeks').subtract(1,'d').second(59).minute(59).hour(23).unix() * 1000;
    }

    if (this.payPeriod == 'MONTHLY'){
      this.hoursConditions = 160;
      this.payroll.end_period = moment(this.payroll.start_period).add(1, 'months').subtract(1,'d').second(59).minute(59).hour(23).unix() * 1000;
    }

    if (this.payPeriod == 'BI_WEEKLY'){
      this.hoursConditions = 80;
      this.payroll.end_period = moment(this.payroll.start_period).add(2, 'weeks').subtract(1,'d').second(59).minute(59).hour(23).unix() * 1000;
    }

    if (this.payPeriod == 'BI_MONTHLY') { // dos quincenas
      this.hoursConditions = 80;
      // obtener el ultimo dia que se pago para saber si se finaliza periodo el 15 o el ultimo dia del mes
      const currentDay =  Number(moment(this.payroll.start_period).format('D'));
      // valida si se toma fin de la mitad de mes o del final
      if (currentDay < 15) {
        this.payroll.end_period = moment(this.payroll.start_period).startOf('month').add(14, 'days').endOf('day').unix() * 1000;
      } else {
        this.payroll.end_period = moment(this.payroll.start_period).endOf('month').unix() * 1000
      }
    }
    this.totalExtras = null;

    for (const employee of this.employees) {
      this.loadPayrollPayment(employee.id);
    }
    
    this.calculateTotals();
    
  }

  
  loadEmployees() {
    this.helperService.showLoadingMxpro360();
    this.payrollBreakdownService
    .getEmployees()
    .then((response) => {
        this.employees = response;
    })
    .catch((error) => {
        console.error('error', error);
    }); 
  }

  
  loadPayrollPayment(id) {
    this.helperService.showLoadingMxpro360();
    
    this.payrollBreakdownService
    .getPayrollPayment(id, this.payroll.end_period)
    .then((response) => {
        this.arrayReponseAll.push(response);
        this.payrollPayment = new PayrollPayment();
        this.payrollPayment.employee_id = id;
        this.loadCommissions(response['sales_commisions']);
        this.loadPayrollBalances(response['payroll_balances']);
        this.loadTimeClock(response['time_clocks']);
        this.loadPayrollSettings(response['payroll_settings']);
        this.loadClockEmployees(response['clock_employees']);
        this.generateSubTotal();
    })
    .catch((error) => {
        console.error('error', error);
    }); 
  }

  private loadCommissions(commissions) {
    this.commissions = commissions;
    this.payrollPayment.total_commissions = 0;
    this.cancelations = [];
    for (const cms of this.commissions) {
      // clona documentos para luego hacer filtros sobre el
      const clonedArrayDocuments = [...cms.documents];
      // filtra comisiones no canceladas
      const commission = clonedArrayDocuments.filter(document => document.job_cancelation_date == null);
      // calcula total de las comisiones no canceladas
      for (const com of commission) {
        this.payrollPayment.total_commissions += com.total_commission;
      }
      // llena array de comisiones canceladas
      this.cancelations = this.cancelations.concat(clonedArrayDocuments.filter(document => document.job_cancelation_date != null));
      // vuelve a setear el arreglo de documentos dejando solo los que si son comisiones
      // cms.documents = commission;
    }
  }

  private loadPayrollBalances(payrollBalances) {
    this.payrollBalances = payrollBalances;
    this.payrollPayment.total_bonuses = 0;
    this.payrollPayment.total_deductions = 0;
    for (const pb of this.payrollBalances) {
      if (pb.type == 'DEDUCTION'){
        this.payrollPayment.total_deductions += pb.amount;
      } else {
        this.payrollPayment.total_bonuses += pb.amount;
      }
    }
  }

  private loadTimeClock(timeClock) {
    this.timeClock = timeClock;
    this.payrollPayment.total_hours = 0;
    for (const tc of this.timeClock) {
      this.payrollPayment.total_hours += tc.duration;
    }
    this.payrollPayment.total_hours = Number((this.payrollPayment.total_hours / 60).toFixed(2));
    if (this.payrollPayment.total_hours > this.hoursConditions) {
      this.totalExtras =  this.payrollPayment.total_hours - this.hoursConditions;
      this.payrollPayment.total_hours = this.payrollPayment.total_hours - this.totalExtras;
      this.payrollPayment.total_overtime = this.totalExtras;
      this.payrollPayment.overtime_rate = this.clockEmployees.overtime_hourly_rate ? this.clockEmployees.overtime_hourly_rate : 0;
    }
  }

  private loadClockEmployees(clockEmployees) {
   
    this.clockEmployees = clockEmployees;
    if(clockEmployees) {
      this.payrollPayment.hourly_pay_rate = Number(this.clockEmployees.hourly_rate);
    } else {
      this.payrollPayment.hourly_pay_rate = 0;
    }
  }

  private loadPayrollSettings(payrollSetting) {
    this.payrollSetting = payrollSetting;
    if(payrollSetting) {
      this.payrollPayment.base_pay = Number(this.payrollSetting.base_per_week);
      this.payrollPayment.vs_commission = this.payrollSetting.vs_commission;
    } else {
      this.payrollPayment.base_pay = 0;
    }
  }

  private loadPreviusPayrollPayment() {
    this.helperService.showLoadingMxpro360();

    this.payrollBreakdownService
      .getPreviusPayrollPayment(this.payrollPayment.employee_id)
      .then((response) => {
        this.previusPayroll = response;
        if(this.previusPayroll){
          // this.payrollPayment.previous_payroll_payment_id = this.previusPayrollPayment.id;
          // this.payrollPayment.previous_total_draw = this.previusPayrollPayment.total_draw;
          this.payroll.start_period = moment(this.previusPayroll.end_period).add(1,'d').second(0).minute(0).hour(0).unix() * 1000;
          this.loadCompanySettings(false);
        } else {
          this.payroll.start_period = moment().second(0).minute(0).hour(0).unix() * 1000;
          // this.payrollPayment.previous_total_draw = 0;
          this.loadCompanySettings(true);
        }
       
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  generateSubTotal(){

    this.payrollPayment.total_hourly = this.payrollPayment.total_hours * this.payrollPayment.hourly_pay_rate;
    if (this.payrollPayment.total_hours > this.hoursConditions) {
      this.payrollPayment.total_hourly += this.payrollPayment.total_overtime * this.payrollPayment.overtime_rate; 
    }
    
    this.payrollPayment.total_draw = 0;
    if (this.payrollPayment.vs_commission) {
      this.payrollPayment.sub_total = this.payrollPayment.total_hourly + this.payrollPayment.total_commissions + this.payrollPayment.total_bonuses - this.payrollPayment.total_deductions;
    } else {
      this.payrollPayment.sub_total = this.payrollPayment.base_pay + (this.payrollPayment.total_overtime * this.payrollPayment.overtime_rate) + this.payrollPayment.total_commissions + this.payrollPayment.total_bonuses - this.payrollPayment.total_deductions;
    }

    if (this.payrollPayment.sub_total > this.payrollPayment.base_pay ) {
      const excedente = this.payrollPayment.sub_total - this.payrollPayment.base_pay;

      if (this.payrollPayment.previous_total_draw > 0) {

        // si esta apagado el vs commission entonces, el total draw queda en 0
        this.payrollPayment.total_draw = this.payrollPayment.vs_commission ? this.payrollPayment.previous_total_draw - excedente : 0;

        if (this.payrollPayment.total_draw < 0){
          this.payrollPayment.total_draw = 0;
          this.payrollPayment.total = this.payrollPayment.sub_total - this.payrollPayment.previous_total_draw;
        } else {
          this.payrollPayment.total = this.payrollPayment.base_pay; 
        }
      } else {
        this.payrollPayment.total_draw = 0;
        this.payrollPayment.total = this.payrollPayment.sub_total;

      }
    } else {
      this.payrollPayment.total = this.payrollPayment.base_pay;
      this.payrollPayment.total_draw = this.payrollPayment.base_pay - this.payrollPayment.sub_total + this.payrollPayment.previous_total_draw;
    }

    this.payrollPayments.push(this.payrollPayment);
    this.calculateTotals();

  }

  savePayrollBreakdown(){
    if(this.commissionError){
      swal(
        'Cannot Continue',
        this.commissionErrorEmployee + ' has a Commission Pending Decision for $' + Number(this.commissionError.toFixed(2)),
        'warning'
      );
      return;
    }
    
    this.helperService.showLoadingMxpro360();

    this.payrollBreakdownService
      .add(this.payrollPayments, this.payroll, this.arrayReponseAll)
      .then((response) => {
        this.helperService.goToCompanyRouterLink('/payroll-payments');
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  removePaymentBalances(index){
    this.payrollBalances.splice(index,1);
    this.payrollPayment.total_bonuses = 0;
    this.payrollPayment.total_deductions = 0;
    for (const pb of this.payrollBalances) {
      if (pb.type == 'DEDUCTION'){
        this.payrollPayment.total_deductions += pb.amount;
      } else {
        this.payrollPayment.total_bonuses += pb.amount;
      }
    }
  }

  openPayrollBreakdownModal(i) {
    this.payrollPayment = this.payrollPayments[i];

    this.loadCommissions(this.arrayReponseAll[i].sales_commisions);
    this.loadPayrollBalances(this.arrayReponseAll[i].payroll_balances);
    this.loadTimeClock(this.arrayReponseAll[i].time_clocks);
    this.loadPayrollSettings(this.arrayReponseAll[i].payroll_settings);
    this.loadClockEmployees(this.arrayReponseAll[i].clock_employees);

    jQuery(this.payrollBreakdownModal.nativeElement).modal('show');
  }

  calculateTotals() {
    this.payroll.total_commissions = 0;
    this.payroll.total_hourly = 0;
    this.payroll.total_overtime = 0;
    this.payroll.total_draw = 0;
    this.payroll.previous_total_draw = 0;
    this.payroll.total_bonuses = 0;
    this.payroll.total_deductions = 0;
    this.payroll.total = 0;
    this.payroll.total_base = 0;
    for(const pp of this.payrollPayments) {
      this.payroll.total_commissions += pp.total_commissions;
      this.payroll.total_hourly += pp.total_hourly;
      this.payroll.total_overtime += pp.total_overtime;
      this.payroll.total_draw += pp.total_draw;
      this.payroll.previous_total_draw += pp.previous_total_draw;
      this.payroll.total_bonuses += pp.total_bonuses;
      this.payroll.total_deductions += pp.total_deductions;
      this.payroll.total += pp.total;
      this.payroll.total_base += pp.base_pay;
    }

    
  }

  closeModal(){
    jQuery(this.payrollBreakdownModal.nativeElement).modal('hide');
  }

}
