import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastChannelService } from 'src/app/services/broadcast-channel.service';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { SmsService } from 'src/app/services/companies/sms.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeadsService } from 'src/app/services/leads.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';

declare var jQuery;
@Component({
    selector: 'app-oldsms',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss']
})
export class OldSmsComponent implements OnInit {

    @ViewChild('newChatModal') newChatModal: ElementRef;
    @ViewChild('addContactModal') addContactModal: ElementRef;
    @ViewChild('messagesBox') private messagesBox!: ElementRef;
    @Output() isReady: EventEmitter<string>;

    public filterSearch: string;
    public contacts: Array<any>
    public chats: Array<any>
    public sms: Array<any>
    public darkChat: boolean;
    public numberPhoneSelected: string;
    public contactSelected: string;
    public dataForm: any;
    public selectedContact: string;
    public newMessage: string;
    public sms_phone_line_id: string;
    public fromSms: string;
    public formPhoneHiden: boolean;


    constructor(
        private leadsService: LeadsService,
        private clientSocketsService: ClientSocketsService,
        private smsService: SmsService,
        public helperService: HelperService,
        public authService: AuthService,
        private channelService: BroadcastChannelService,
        public phoneExtensionsService: PhoneExtensionsService,
        private phoneLineService: PhoneLinesService,

    ) {
        this.filterSearch = '';
        this.darkChat = false;
        this.numberPhoneSelected = null;
        this.contacts = [];
        this.chats = [];
        this.contactSelected = '';
        this.dataForm = {
            phone_number: '',
            message: ''
        }
        this.selectedContact = '';
        this.newMessage = '';
        this.sms = [];
        this.fromSms = '';
        this.isReady = new EventEmitter();
        this.formPhoneHiden = false;

    }

    ngOnInit(): void {
        if (this.clientSocketsService.isConnected()) {
            this.listenNotificationsSMS();
        } else {
            this.clientSocketsService
                .onConnect
                .subscribe({
                    next: () => {
                        this.listenNotificationsSMS();
                    }
                });
        }



        this.channelService.readMenssageSms((mensaje) => {
            if (mensaje) {
                setTimeout(() => {
                    this.loadContact();
                    this.loadChat(this.fromSms);

                    let sectionSms;
                    let sectionPhone;
                    let sectionhomeTab;
                    let messageTab;
                    let sectionPhone1;
                    sectionSms = document.getElementById('text_sms_section');
                    sectionSms.classList.add('active');
                    sectionSms.classList.add('show');

                    sectionPhone = document.getElementById('calling_section');
                    sectionPhone1 = document.getElementById('home-tab');

                    sectionPhone1.classList.remove('active');
                    sectionPhone1.classList.remove('show');
                    sectionPhone.classList.remove('active');
                    sectionPhone.classList.remove('show');

                    sectionhomeTab = document.getElementById('contact-tab');
                    sectionhomeTab.classList.add('active');

                }, 500);
            }
        });


    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            let socket = this.clientSocketsService.getSocket();
            socket.on('sms_reciver', () => {
                this.loadContact();
                this.listenNotificationsSMS();
                setTimeout(() => {
                    this.loadChat(this.selectedContact);
                }, 2000);
            });
        }, 3000);

        this.helperService
            .setNumberDialer
            .subscribe(async (lineId) => {

                this.helperService.showLoadingMxpro360();
                this.phoneExtensionsService
                    .getById(lineId)
                    .then((response) => {

                        this.sms_phone_line_id = response.sms_phone_line_id;

                        this.helperService.showLoadingMxpro360();
                        this.phoneLineService
                            .getById(response.sms_phone_line_id)
                            .then((response) => {

                                this.numberPhoneSelected = response.phone_number;
                                if (response.phone_number) {
                                    this.loadContact();
                                } else {

                                }

                            })
                            .catch((error) => {
                                console.log('error', error);
                            })
                            .finally(() => {
                                this.helperService.hideLoadingMxpro360();
                            });

                    })
                    .catch((error) => {
                        console.log('error', error);
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            });
    }

    private listenNotificationsSMS() {
        this.clientSocketsService
            .getSocket()
            .on('sms:notify', (response) => {
                console.log("response notificacion sms: ", response);
            });
    }

    sendMessage(newMessage: boolean) {

        if (!this.clientSocketsService.isConnected()) {
            return;
        }

        if (newMessage) {
            this.dataForm.phone_number = this.selectedContact;
            this.dataForm.message = this.newMessage;
        }

        const form = {
            "to": [this.dataForm.phone_number],
            "from": this.numberPhoneSelected,
            "text": this.dataForm.message,
            "applicationId": "dad36d15-88e5-4ca9-9407-c22193d6cafb",
            "tag": "test message",
            "priority": "default"
        }

        const dataForm = { workspace_id: this.authService.workspaceSession.workspace.id, form: form, leadCompanyId: null };

        let that = this;
        const callBack = function (response) {
            console.log(response);
            that.loadContact();
            that.loadChat(that.selectedContact);
        }

        this.clientSocketsService.getSocket().emit("sms:send", dataForm, callBack);

        this.dataForm = {
            phone_number: '',
            message: ''
        }
        this.newMessage = '';
    }

    private loadContact() {

        this.smsService
            .getAllContacts(this.numberPhoneSelected)
            .then((response) => {

                for (const lead of response) {
                    this.leadsService.getById(lead.lead_company_id)
                        .then((response) => {

                        })
                        .catch((error) => {
                            console.log('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }


                this.contacts = response;
                this.fromSms = response[0].from;
                this.sms = response;
                this.channelService.emitMensajeSms(this.sms);
                this.channelService.emitNumberSms(this.sms_phone_line_id);

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    };


    readConversation(contact) {

        this.smsService
            .updateConversation(contact, this.sms_phone_line_id)
            .then((response) => {
                this.loadContact();
                this.loadChat(this.selectedContact);
                this.scrollToBottom();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });


    }

    public loadChat(phone) {

        this.selectedContact = phone;
        this.toggleActiveContact(phone);
        this.smsService
            .getChatByContact(this.numberPhoneSelected, phone)
            .then((response) => {

                this.chats = response;
                this.scrollToBottom();

                //this.channelService.emitMensajeSms(this.chats);

                // Scroll to Bottom
                /*  jQuery("#messages_box").animate({ scrollTop: jQuery(document).height()}, 10); */
                /*   this.loadChat(this.selectedContact); */
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
            });
    };

    public newChat() {
        jQuery(this.newChatModal.nativeElement).modal('show');
    }

    public addContact() {
        jQuery(this.addContactModal.nativeElement).modal('show');
    }

    public deleteConversation(contact) {
        this.helperService.showLoadingMxpro360();
        this.smsService
            .deleteConversation(this.numberPhoneSelected, contact['end_point'])
            .then((response) => {
                this.selectedContact = '';
                this.loadContact();
                this.loadChat(this.selectedContact);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    // Identificamos visualmente cual contacto se encuentra activo
    public toggleActiveContact(phoneContact) {
        // avisa al componente padre que ya esta listo
        this.isReady.emit('ready');

        // Borramos la clase .active-contact del contacto que se encuentre activo
        const contactActiveClass = document.querySelectorAll('.active-contact');

        if (contactActiveClass.length > 0) {

            contactActiveClass.forEach(contact => {
                contact.classList.remove('active-contact');
            });

            // Agregamos la clase .active-contact al contacto activo
            document.getElementById(phoneContact).classList.add('active-contact');
        }

    }


    private scrollToBottom(): void {
        try {
            setTimeout(() => {
                this.messagesBox.nativeElement.scrollTop = this.messagesBox.nativeElement.scrollHeight;
            }, 0);
        } catch (err) {
            console.error('Error while scrolling:', err);
        }
    }


}
