<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing">
            <h3>
                Custom Lot 1
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Custom Lot 1</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">

        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appCompanyRouterLink="/custom-labels" class="btn btn-new">
                    <i class="fa-solid fa-circle-chevron-left mr-2"></i> Back
                </a>
            </div>
        </div> 

    </div>
    <!-- header -->


    <div class="widget-content widget-content-area">

        <div class="widget-heading d-flex justify-content-between">
            <h3 class="title-content-area">
                Labels
            </h3>
            <div class="btns-options">                
                <button class="btn btn-success">
                    <i class="fa-solid fa-print mr-2"></i>
                    <span>Print</span>
                </button>
            </div>                    
        </div>

    </div>

    <div class="layout-spacing layout-top-spacing">
        <div class="widget-content widget-content-area mh-150">
    
            <div class="label-columns">
                <label for="" class="title-label-columns">Label Columns</label>
                <div class="n-chk">
                    <label class="new-control new-radio radio-warning">
                        <input type="radio" class="new-control-input" name="two_columns" [value]="false" [(ngModel)]="labelColumns">
                        <span class="new-control-indicator"></span>
                        <span class="option-label">1" x 2-5/8" 3 Columns</span>
                    </label>
                </div>
                <div class="n-chk">
                    <label class="new-control new-radio radio-warning">
                        <input type="radio" class="new-control-input" name="two_columns" [value]="true" [(ngModel)]="labelColumns">
                        <span class="new-control-indicator"></span>
                        <span class="option-label">1" x 2-5/8" 2 Columns</span>
                    </label>
                </div>                        
            </div>

            <div class='qr_page page_1'>
                <!-- Hoja de Impresión -->
                <div id='print'>      
                    
                    <div id="page-header" class="page-header">

                    </div>

                    <div class="print-estimate-label labels-not-assigned" *ngIf="itemsQrNotAssigned.length > 0" [ngClass]="{'two-columns': labelColumns}">
                        <h3 id="title-unassigned-labels" class="text-center">Unassigned Labels</h3>

                        <div class="row labels-container">
                            <ng-container *ngFor="let item of itemsQrNotAssigned">
                                <div class="page-break">
                                    <div class="item-label">
                                        <app-qr-labels>

                                        </app-qr-labels>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>

</div>