import { Address } from '../global/address';

export class EstimateStopPoint {
    public id: string;
    public estimate_id: string;
    public type: 'PICKUP' | 'DELIVERY';
    public fee: number;
    public address: Address;
    public created: Date;
    public updated: Date;

    constructor() {
        this.id = null;
        this.estimate_id = null;
        this.type = null;
        this.fee = null;
        this.address = new Address();
        this.created = null;
        this.updated = null;
    }
}
