export class Role {

    public id: string;
    public name: string;
    public scope: 'WORKSPACE' | 'COMPANY';
    public capabilities: Array<string>;
    public created: Date;
    public updated: Date;

    constructor() {
        this.id = null;
        this.name = null;
        this.scope = null;
        this.capabilities = [];
        this.created = null;
        this.updated = null;
    }
}
