<div class="layout-spacing layout-top-spacing">
  <div class="row">

      <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center layout-spacing">
          <h3>
              Phone Extensions
          </h3>
          <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                      <a appWorkspaceRouterLink="/dashboard">
                          <i class="fas fa-home"></i>
                          Dashboard
                      </a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Phone Extensions</li>
              </ol>
          </nav>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center layout-spacing">
          <div class="d-flex justify-content-sm-end justify-content-center float-right">
              <a appWorkspaceRouterLink="/phone-extensions/add" class="btn btn-new">
                  <i class="fas fa-plus-square"></i> &nbsp; New Phone Extensions
              </a>
          </div>
      </div>

  </div>


  <div class="row">
      <div class="col-lg-12">
          <div class="widget-content widget-content-area">

               <div class="table-responsive">
                  <table class="table table-bordered table-hover mb-4">
                      <thead>
                          <tr>
                              <th>Number Extension</th>
                              <th>Name</th>
                              <th>Caller Id</th>
                              <th>Main</th>
                              <th>Ips_Allowed</th>
                              <th>Ring Duration</th>
                              <th>Destination</th>
                              <th>VoiceMail Announcement</th>
                              <th>Company Assignment</th>
                              <th>Outgoing Phone Line</th>
                              <th>Sms Phone Line</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let extension of extensions">
                              <td>{{extension.extension}} </td>
                              <td>{{extension.name}}</td>
                              <td>{{extension.caller_id}}</td>

                              <td class="text-center">
                                <span class="checked" *ngIf="extension.main">
                                    <i class="fa-solid fa-square-check"></i>
                                </span>
                                <span class="not-checked" *ngIf="!extension.main">
                                    <i class="fa-solid fa-rectangle-xmark"></i>
                                </span>
                              </td>
                              <td>{{extension.ip_allowed}}</td>
                              <td>{{extension.ring_duration}}</td>
                              <td>{{extension.destination.target}}: {{extension.destination.target_id}}</td>


                              <td><audio src="{{environment.az_api.cdn}}/{{workspaceId}}/dialer/{{extension.voicemail_announcement.display_name}}" controls style="width: 200px;"></audio></td>

                              <ng-container *ngFor="let companie of companies">
                              <td *ngIf="extension.company_id === companie.id">
                                {{companie.name}}
                              </td>
                              </ng-container>

                              <ng-container *ngFor="let line of nums">
                                <td *ngIf="extension.outgoing_phone_line_id === line.id">
                                  {{line.name}}<br>{{line.phone_number}}
                                </td>
                              </ng-container>

                              <ng-container *ngFor="let line of nums">
                                <td *ngIf="extension.sms_phone_line_id === line.id">
                                  {{line.name}}<br>{{line.phone_number}}
                                </td>
                              </ng-container>

                              <td>
                                <a appWorkspaceRouterLink ="/phone-extensions/{{extension.id}}/edit" class="btn btn-success">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </a>
                                <button  class="btn btn-danger button-space" (click)="remove(extension.id)">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>
                            </td>

                          </tr>
                      </tbody>
                  </table>

              </div>
          </div>
      </div>
  </div>
</div>
