import { Estimate } from 'src/app/entities/workspace/estimate';
import { JobMaterialView } from 'src/app/entities/workspace/job-material-view';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { JobMaterialsService } from 'src/app/services/companies/job-materials.service';

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConstantsMessages } from '../../../constants-messages';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
@Component({
  selector: 'app-job-materials',
  templateUrl: './job-materials.component.html',
  styleUrls: ['./job-materials.component.scss']
})
export class JobMaterialsComponent implements OnInit {

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  private estimateId: any;
  public rowJobMaterials: Array<JobMaterialView>;
  public estimate: Estimate;
  constructor(
    private currentRoute: ActivatedRoute,
    private jobMaterialsService: JobMaterialsService,
    private estimatesService: EstimatesService,
    private _location: Location,
    private helperService: HelperService
  ) {
    this.estimate = new Estimate();
    this.rowJobMaterials = [];
  }

  ngOnInit(): void {
 
  }

  ngAfterViewInit(): void{
      this.currentRoute.parent.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
            this.load(params.id);
            this.estimateId = params.id;
        }
        });
  }

  private load(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimatesService.getById(id).then((response) => {
      this.estimate = response;
    });
    this.jobMaterialsService
      .getMaterialsByJobId(id)
      .then((response) => {
        this.rowJobMaterials = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });

  }

  save() {
    this.helperService.showLoadingMxpro360();
    const readySave = this.rowJobMaterials.filter(x => x.quantity > 0);
    this.jobMaterialsService
      .save(readySave, this.estimateId)
      .then(() => {
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this._location.back();
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
