import { EmailCampaign } from './../../entities/workspace/email-campaign';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailCampaignsService {

  constructor(
    private http: HttpClient
  ) { }

  saveCampaign(dataForm: any) {
    return this.http.post(environment.api.company + '/email-campaigns', dataForm).toPromise();
  }

  updateCampaign(dataForm: any) {
    return this.http.put(environment.api.company + '/email-campaigns/' + dataForm.email_campaign.id, dataForm).toPromise();
  }

  getAllCampaigns(): Promise<Array<EmailCampaign>> {
    return this.http.get<Array<EmailCampaign>>(environment.api.company + '/email-campaigns').toPromise();
  }

  getCampaign(idCampaign: string): Promise<EmailCampaign> {
    return this.http.get<EmailCampaign>(environment.api.company + '/email-campaigns/' + idCampaign).toPromise();
  }

  sendEmails(campaign: EmailCampaign) {
    return this.http.post(environment.api.company + '/send-email-campaigns/' + campaign.id, campaign).toPromise();
  }
}
