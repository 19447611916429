
import { Workspace } from "../admin/workspace";
import { Company } from "../workspace/company";
import { Employee } from "../workspace/employee";
import { EmployeeCompanyPermission } from "../workspace/employee-company-permission";
import { EmployeeWorkspacePermission } from "../workspace/employee-workspace-permission";

export class WorkspaceSession {

    id: string;
    is_owner: boolean;
    workspace: Workspace;
    employees: Employee[];
    companies: Company[];
    employee_companies_permissions: EmployeeCompanyPermission[]
    company: Company;
    employee: Employee;
    employee_workspace_permissions: EmployeeWorkspacePermission;
    employee_company_permissions: EmployeeCompanyPermission;

    constructor() {
        this.id = null;
        this.is_owner = false;
        this.workspace = null;
        this.employees = [];
        this.companies = [];
        this.employee_companies_permissions = []
        this.company = null;
        this.employee = null;
        this.employee_workspace_permissions = null;
        this.employee_company_permissions = null;
    }
}
