import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Period } from 'src/app/entities/workspace/period';
import { Volume } from 'src/app/entities/workspace/volume';
import { Zone } from 'src/app/entities/workspace/zone';
import { PeriodsService } from 'src/app/services/workspaces/periods.service';
import { ZonesRatesMapService } from 'src/app/services/workspaces/zones-rates-map.service';
import { Geospatial } from 'src/app/entities/global/geospatial';
import { ZoneState } from 'src/app/entities/workspace/zone-state';
import { HelperService } from 'src/app/services/helper.service';
import { RouteRatesService } from 'src/app/services/workspaces/route-rates.service';
import { VolumesService } from 'src/app/services/workspaces/volumes.service';
import { ZoneStatesService } from 'src/app/services/workspaces/zone-states.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { TariffVersionsSettingsService } from 'src/app/services/workspaces/tariff-versions-settings.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';



declare const jQuery;
declare const google;
declare const swal;

@Component({
    selector: 'app-route-rates-map',
    templateUrl: './route-rates-map.component.html',
    styleUrls: ['./route-rates-map.component.scss']
})
export class RouteRatesMapComponent implements OnInit, AfterViewInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: ZoneState;
    zipcode: string;
    zipcodes;
    routesRates;
    /*
    Arreglo usado para comparar las routes rates entre dos zonas
    */
    routesRatesToCompare;
    periodId: string;

    coordinates;

    volumes: Array<Volume>;

    /*
    Arreglo que contiene las figuras que se dibujan, para cambiarles el color al dar click derecho cuando se
    ha realizado mas de 3 clicks en figuras
    */
    private drawnAreas;

    /*
    Variable que contiene el zipcode a buscar en la vista
    */
    searchZipCode: string;

    /*
    Arreglo que contiene las longitudes de las figuras dibujadas en el mapa
    */
    private longitudes;

    /*
    Arreglo que contiene las latitudes de las figuras dibujadas en el mapa
    */
    private latitudes;

    /*
    Variable que contiene el mapa
    */
    private map;

    /*
    Variable usada para abrir un InfoWindow
    */
    private openedInfo;

    /*
    Variable que cuenta el numero de click en las figuras dibujadas
    */
    private eventCounter: number;

    private counterInfoWindow: number;

    private infowindow;

    private zones;
    public zonesSelect;
    periods: Array<Period>;
    public zoneOut: any;
    public zoneOutId: string;
    private deliveryZoneId: string;
    private statesPoligons: any;
    public sizeMap: number;
    private compactSizeMap: number;
    @ViewChild('mapView') mapView: ElementRef;
    @ViewChild('mapView') entityForm: ElementRef;
    constructor(
        private zonesStatesService: ZoneStatesService,
        private zonesRatesMapService: ZonesRatesMapService,
        private routeRatesService: RouteRatesService,
        private periodsService: PeriodsService,
        private volumesService: VolumesService,
        private helperService: HelperService,
        private carrierService: CarriersService,
        public tariffVersionsSettingsService: TariffVersionsSettingsService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.zoneOut = null;
        this.zoneOutId = '';
        this.deliveryZoneId = null;
        this.openedInfo = new google.maps.InfoWindow();
        this.zipcodes = [];
        this.dataForm = new ZoneState();
        this.searchZipCode = '';
        this.zipcode = '';
        this.longitudes = [];
        this.latitudes = [];
        this.eventCounter = 0;
        this.volumes = [];
        this.routesRates = [];
        this.routesRatesToCompare = [];
        this.drawnAreas = [];
        this.periods = [];
        this.counterInfoWindow = 0;
        this.zones = [];
        this.zonesSelect = [];
        this.periodId = null;
        this.sizeMap = 12;
        this.compactSizeMap = 9;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.loadData(false);
        this.initMap();
    }

    loadData(filterFromClick: boolean) {
        const tariffVersionId = this.tariffVersionFactory.getTariffVersionId();
        if (filterFromClick) {
            this.sizeMap = 12;
        }
        this.helperService.showLoadingMxpro360();
        this.periodsService
            .getAllPeriodsByTariffVersionId(tariffVersionId)
            .then((response) => {
                console.log('loadData  response =>',response);
                
                this.periods = response;
                if (this.periodId == null && response.length > 0) {
                    this.periodId = response[0].id;
                }
            })
            .catch((error) => {
                console.error('error', error);
            });

            this.getAllVolumesByTariffVersionId(tariffVersionId);
            this.getAllZonesRatesByTariffVersionId(tariffVersionId, filterFromClick);

    }

    getAllVolumesByTariffVersionId(tariffVersionId){
        this.volumesService
        .getAllByTariffVersionId(tariffVersionId)
        .then((response) => {
            this.volumes = response;
        })
        .catch((error) => {
            console.error('error', error);
        });
    }

    getAllZonesRatesByTariffVersionId(tariffVersionId, filterFromClick){
        this.zonesRatesMapService
        .getAllByTariffVersionId(tariffVersionId).then(response => {
            this.zonesSelect = response;
            if (filterFromClick) {
                this.loadDataPoligons();
            }
        });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.zonesStatesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/zone-states');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /* Dado un zip code en la vista, busca la direccion para ver si existe. Falta buscar en el arreglo de zipcodes
    */
    searchAddressByZipCode() {
        const THIS = this;
        this.sizeMap = this.compactSizeMap;
        for (let index = 0; index < this.zipcodes.length; index++) {
            if (this.zipcodes[index] === THIS.searchZipCode) {
                let geocoder;
                geocoder = new google.maps.Geocoder();
                geocoder.geocode({
                    address: this.zipcodes[index]
                }, (results, status) => {
                });
            }
        }

    }

    searchWithLongLan(long, lat) {
        const THIS = this;
        return new Promise((resolve) => {

            const latlng = new google.maps.LatLng(long, lat);
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ latLng: latlng }, (results, status) => {

                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        for (let j = 0; j < results[0].address_components.length; j++) {
                            if (results[0].address_components[j].types[0] === 'postal_code') {
                                THIS.zipcodes.push(results[0].address_components[j].short_name);
                            }
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            }, () => {
                resolve(false);
            });
        });
    }

    initMap(): void {

        const THIS = this;

        this.map = new google.maps.Map(
            this.mapView.nativeElement as HTMLElement,
            {
                zoom: 4,
                center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: false,
                zoomControl: true
            });

        const input = document.getElementById('pac-input');
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: false,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT,
                drawingModes: [
                    // google.maps.drawing.OverlayType.MARKER,
                    google.maps.drawing.OverlayType.POLYGON
                ],
            },
            circleOptions: {
                strokeColor: '#e44f00',
                strokeOpacity: 0.5,
                fillColor: '#e44f00',
                fillOpacity: 0.4,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                zIndex: 1,
            }
        });

        /*
        Metodo que busca el zipcode por longitud y latitud
        */
        function searchZipCodeByCoordinates() {
            return new Promise((resolve) => {
                const latlng = new google.maps.LatLng('4.535000', '-75.675690');
                const geocoder = new google.maps.Geocoder();

                geocoder.geocode({ latLng: latlng }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            for (let j = 0; j < results[0].address_components.length; j++) {
                                if (results[0].address_components[j].types[0] === 'postal_code') {
                                    THIS.zipcode = results[0].address_components[j].short_name;
                                    resolve(results[0].address_components[j].short_name);
                                }
                            }
                        }
                    } else {
                        resolve(false);
                    }
                });
            });
        }

        async function saveCoordinates(polygon) {
            const tariffVersion = this.tariffVersionFactory.getTariffVersionId();
            const geospatial = new Geospatial();
            const zone = new Zone();
            const coordinatesJson = [];

            // recorre las coordenadas del poligono para almacenarlas en el objeto que se envia a bd
            const pathCoordenates = polygon.getPath().getArray();
            for (const pathCoordenate of pathCoordenates) {
                coordinatesJson.push([pathCoordenate.lng(), pathCoordenate.lat()]);
                await THIS.searchWithLongLan(pathCoordenate.lat(), pathCoordenate.lng());
            }
            coordinatesJson.push([pathCoordenates[0].lng(), pathCoordenates[0].lat()]);
            let volumesTable = '';
            let routesTable = '';
            let selectedShape;
            zone.name = 'Void';
            let contentString = '';
            geospatial.type = 'Polygon';
            geospatial.coordinates = [];
            // llena los datos necesarios
            geospatial.coordinates.push(coordinatesJson);
            zone.geospatial = geospatial;

            THIS.zonesRatesMapService
                .save(zone)
                .then(response => {
                    // aqui recibe id de
                    zone.id = response.row_id;
                    this.routeRatesService
                        .getAll(zone.id, THIS.periodId)
                        .then((response) => {
                            console.log('getAll - response =>', response);
                            
                            this.routesRates = response;
                            THIS.setZoneOut();
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                    THIS.loadData(false);
                })
                .catch((error) => {
                })
                .finally(() => {
                });

            const marker = new google.maps.Marker({
                position: { lat: pathCoordenates[0].lat(), lng: pathCoordenates[0].lng() },
                map: THIS.map,
                label: zone.name,
                icon: '-'
            });

            google.maps.event.addListener(polygon, 'rightclick', (event) => {

                // Aumenta el numero de figuras clickeadas
                THIS.eventCounter++;

                // Si has mas de dos figuras clickeadas, se procede a despintar la figura inicialmente clickeada
                if (THIS.eventCounter > 2) {
                    // Se captura el primer poligono dibujado,
                    // para que al realizar click en una 3 figura, se restablesca el color
                    const firstPolygon = THIS.drawnAreas[0];
                    for (let index = 0; index < THIS.drawnAreas.length - 1; index++) {
                        // Se mueven todas las figuras de posicion en el arreglo, para dejar en la posicion 0
                        // el primer poligono clickeado
                        THIS.drawnAreas[index] = THIS.drawnAreas[index + 1];
                    }
                    // Se pinta el primer poligono
                    firstPolygon.set('strokeColor', '#003649');
                    firstPolygon.set('fillColor', '#003649');
                    // Se elimina la ultima posicion del arreglo que es innecesaria
                    THIS.drawnAreas.pop();
                }
                THIS.routeRatesService
                    .getRouteRate(THIS.zoneOutId, zone.id, THIS.periodId, tariffVersion)
                    .then(async (response) => {
                        if (THIS.zones.length >= 2) {
                            THIS.zones[0] = zone.id;
                        } else {
                            THIS.zones.push(zone.id);
                        }
                        // Se agregan las tarifas de las zonas a comparar
                        if (response.length > 0 && THIS.routesRatesToCompare.length < 2) {
                            THIS.routesRatesToCompare.push(response);
                        }
                        // Si hay una figura clickeada, se procede a reemplazar la posicion 0 de routesRatesToCompare
                        // Para actualizarse en la tabla
                        if (THIS.routesRatesToCompare.length === 2 && THIS.eventCounter > 2) {
                            if (response.length > 0) {
                                THIS.routesRatesToCompare[0] = response;
                            } else {
                                THIS.routesRatesToCompare[0] = [];
                            }

                        }
                        // Si hay informacion de las tarifas entre dos zonas, se copia a routesRates
                        // para mostrarse en la tabla de comparacion de tarifas
                        if (THIS.routesRatesToCompare.length === 2) {
                            THIS.routesRates = THIS.routesRatesToCompare;
                            for (let index = 0; index < THIS.volumes.length; index++) {
                                volumesTable += '<th >' + THIS.volumes[index].up_to_cf + '</th>';
                            }

                            for (let index = 0; index < THIS.routesRates.length; index++) {

                                const variable = THIS.routesRates[index];
                                for (let indexJ = 0; indexJ < variable.length; indexJ++) {
                                    if (THIS.zones[0] === variable[indexJ].zone_in_id && THIS.zones[0] === variable[indexJ].zone_out_id
                                        || THIS.zones[1] === variable[indexJ].zone_in_id && THIS.zones[1] === variable[indexJ].zone_out_id) {
                                        routesTable += '<tr >';
                                        for (let indexK = 0; indexK < variable[indexJ].volume_rates.length; indexK++) {

                                            if (variable[indexJ].volume_rates[indexK].cf_cost !== null) {
                                                routesTable += '<td ><input type="number" step="0.01" value="' +
                                                    variable[indexJ].volume_rates[indexK].cf_cost + '"' + 'class="form-control input-padding-info" disabled>' +
                                                    '</td>';
                                            }
                                        }
                                        routesTable += '<td ><input type="number" step="0.01" value="' + variable[indexJ].cf_discount + '"' + 'class="form-control input-padding-info" disabled>' +
                                            '</td>' + '<td ><input type="number" step="0.01" value="' + variable[indexJ].packing_cost + '"' + 'class="form-control input-padding-info" disabled>' +
                                            '</td>' + '<td ><input type="number" step="0.01" value="' + variable[indexJ].packing_discount + '"' + 'class="form-control input-padding-info" disabled>' +
                                            '</td>' + '</tr >';
                                    }
                                }
                            }
                            // openInfoWindow();
                            volumesTable = '';
                            routesTable = '';

                        }
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });

                // openInfoWindow();
                // Se cambia el color al poligono clickeado
                polygon.set('strokeColor', '#e44f00');
                polygon.set('fillColor', '#e44f00');
                // polygon.setMap(THIS.map);
                // Se agrega al arreglo de figuras pintadas
                THIS.drawnAreas.push(polygon);
            });

            google.maps.event.addListener(polygon, 'click', async () => {

                // openInfoWindow();
                THIS.routeRatesService
                    .getRouteRate(THIS.zoneOutId, zone.id, THIS.periodId, tariffVersion)
                    .then((response) => {
                        THIS.routesRates = response;
                        THIS.setZoneOut();
                    });

                for (let index = 0; index < THIS.volumes.length; index++) {
                    volumesTable += '<th >' + THIS.volumes[index].up_to_cf + '</th>';
                }
                for (let index = 0; index < THIS.routesRates.length; index++) {
                    if (zone.id === THIS.routesRates[index].zone_in_id && zone.id === THIS.routesRates[index].zone_out_id) {
                        routesTable += '<tr >';
                        for (let indexJ = 0; indexJ < THIS.routesRates[index].volume_rates.length; indexJ++) {
                            if (THIS.routesRates[index].volume_rates[indexJ].cf_cost !== null) {
                                routesTable += '<td ><input type="number" step="0.01" value="' +
                                    THIS.routesRates[index].volume_rates[indexJ].cf_cost + '"' + 'class="form-control input-padding-info" disabled>' +
                                    '</td>';
                            }
                        }
                        routesTable += '<td ><input type="number" step="0.01" value="' + THIS.routesRates[index].cf_discount + '"' + 'class="form-control input-padding-info" disabled>' +
                            '</td>' + '<td ><input type="number" step="0.01" value="' + THIS.routesRates[index].packing_cost + '"' + 'class="form-control input-padding-info" disabled>' +
                            '</td>' + '<td ><input type="number" step="0.01" value="' + THIS.routesRates[index].packing_discount + '"' + 'class="form-control input-padding-info" disabled>' +
                            '</td>' + '</tr >';
                    }

                }
                // openInfoWindow();
                volumesTable = '';
                routesTable = '';
            });

            // actualiza datos al cambiar posicion del circulo
            google.maps.event.addListener(polygon.getPath(), 'set_at', () => {
                setSelection(polygon, true);
            });
            google.maps.event.addListener(polygon.getPath(), 'insert_at', () => {
                setSelection(polygon, true);
            });

            THIS.map.addListener('click', (e) => {
                // Se reinician los valores para el filtrado en la tabla
                THIS.routesRates = [];
                THIS.routesRatesToCompare = [];
                THIS.eventCounter = 0;
                polygon.set('strokeColor', '#003649');
                polygon.set('fillColor', '#003649');
                polygon.setMap(THIS.map);
            });

            async function setSelection(shape, editing) {
                selectedShape = shape;
                if (editing) {
                    // editar coordenadas
                    zone.geospatial.coordinates = [];
                    THIS.zipcodes = [];
                    const coordinatesJson = [];
                    // recorre las coordenadas del poligono para almacenarlas en el objeto que se envia a bd
                    const pathCoordenates = selectedShape.getPath().getArray();
                    for (const pathCoordenate of pathCoordenates) {
                        coordinatesJson.push([pathCoordenate.lng(), pathCoordenate.lat()]);
                        await THIS.searchWithLongLan(pathCoordenate.lat(), pathCoordenate.lng());
                    }
                    coordinatesJson.push([pathCoordenates[0].lng(), pathCoordenates[0].lat()]);
                    // llena los datos necesarios
                    zone.geospatial.coordinates.push(coordinatesJson);
                    THIS.zonesRatesMapService.edit(zone).then(() => { });
                }
            }

            async function clearSelection() {
                if (selectedShape) {
                    if (typeof selectedShape.setEditable == 'function') {
                        selectedShape.setEditable(false);

                    }
                    selectedShape = null;
                }
            }

            function deleteSelectedShape() {
                if (selectedShape) {
                    selectedShape.setMap(null);
                }
            }

            function openInfoWindow() {

                if (THIS.infowindow) {
                    THIS.infowindow.close();
                }

                contentString =
                    '<div id="content">' +
                    '<div id="siteNotice">' +
                    '</div>' +
                    '<h1 id="firstHeading" class="firstHeading">' + zone.name + '</h1>' +
                    '<div id="bodyContent">' +
                    '<label for="fname">Edit name:</label>' +
                    '<input type="text" id="fname" name="fname"><br><br>' +
                    '<button id="inputButton" class="btn-modal-info" data-id="info">Edit Info</button>' +
                    '<button id="inputButtonShape" class="btn-modal-info" data-id="edit">Edit Shape</button>' +
                    '<button id="inputButtonDelete" class="btn-modal-info" data-id="delete">Delete Shape</button>' +
                    '<br>' +
                    '<br>' +
                    '<div class="content-table-info">' +
                    '<table  class="table table-bordered mb-4 table-zone-tariffs">' +
                    '<thead >' +
                    '<tr >' +
                    volumesTable +
                    '<th >Cf Discount</th>' +
                    '<th >Packing $ Cost</th>' +
                    '<th >Packing Discount</th>' +
                    '</tr>' +
                    '</thead>' +
                    '<tbody >' +
                    routesTable +
                    '</tbody>' +
                    '</table>' +
                    '</div>' +
                    '</div>' +
                    '</div>';

                THIS.infowindow = new google.maps.InfoWindow({
                    content: contentString,
                });
                // THIS.infowindow.content = contentString;pathCoordenate.lng(), pathCoordenate.lat()
                THIS.infowindow.setPosition({ lat: pathCoordenates[0].lat(), lng: pathCoordenates[0].lng() });
                THIS.infowindow.open(THIS.map);
                google.maps.event.addListener(THIS.infowindow, 'domready', () => {
                    THIS.routeRatesService
                        .getRouteRate(THIS.zoneOutId, zone.id, THIS.periodId, tariffVersion)
                        .then((response) => {
                            THIS.routesRates = response;
                            THIS.setZoneOut();
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                    const inputFname = document.getElementById('fname') as HTMLInputElement;
                    const button = document.getElementById('inputButton');
                    const buttonShape = document.getElementById('inputButtonShape');
                    const buttonDelete = document.getElementById('inputButtonDelete');
                    button.addEventListener('click', () => {
                        zone.name = inputFname.value;
                        document.getElementById('firstHeading').innerHTML = inputFname.value;
                        THIS.zonesRatesMapService.edit(zone).then(() => {
                            THIS.infowindow.close();
                        });
                    });
                    buttonShape.onclick = () => {
                        THIS.infowindow.close();
                        polygon.setEditable(true);
                        setSelection(polygon, false);
                    };
                    buttonDelete.onclick = () => {
                        THIS.infowindow.close();
                        swal({
                            title: 'Are you sure?',
                            text: 'Are you sure do you want to delete this zone?',
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, delete it!'
                        })
                            .then((result) => {
                                if (result.value) {
                                    this.helperService.showLoadingMxpro360();
                                    THIS.zonesRatesMapService
                                        .remove(zone.id)
                                        .then(() => {
                                            polygon.setVisible(false);
                                            marker.setVisible(false);
                                            deleteSelectedShape();
                                            swal(
                                                'Deleted!',
                                                'Record has been deleted.',
                                                'success'
                                            );
                                        })
                                        .catch((error) => {
                                            console.error('error', error);
                                        })
                                        .finally(() => {
                                            this.helperService.hideLoadingMxpro360();
                                        });
                                }
                            });
                    };
                });

            }

            THIS.zipcodes = [];
        }

        // google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', deleteSelectedShape);
        google.maps.event.addListener(drawingManager, 'overlaycomplete', async (e) => {
            // Switch back to non-drawing mode after drawing a shape.
            drawingManager.setDrawingMode(null);
            // Add an event listener that selects the newly-drawn shape when the user
            // mouses down on it.
            const newShape = e.overlay;
            newShape.type = e.type;
            newShape.set('strokeColor', '#003649');
            newShape.set('fillColor', '#003649');

            await searchZipCodeByCoordinates();

            saveCoordinates(newShape);

            THIS.searchZipcodes();

        });
        // render map
        drawingManager.setMap(THIS.map);
        
        this.helperService.hideLoadingMxpro360();
    }

    async searchZipcodes() {
        for (let i = 0; i < this.longitudes.length; i++) {
            await this.searchWithLongLan(this.longitudes[i], this.latitudes[i]);
        }
    }

    /**
     * obtiene corrdenadas de un zipcode
     * @param zipcode
     */
    getLatLang(zipcode) {
        return new Promise((resolve, reject) => {
            let lat: string;
            let lng: string;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: 'zipcode ' + zipcode }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    resolve({ lat, lng });
                } else {
                    reject("Request failed.");
                }
            });
        });
    }

    loadDataPoligons() {
        this.helperService.showLoadingMxpro360();
        this.carrierService.getPoligonsStates()
          .then(states => {
            console.log('loadDataPoligons states =>',states);
            
            this.statesPoligons = states;
            this.drawShapes();
        }).catch((error) => {
            console.error('error', error);
        }).finally(() => {
            this.helperService.hideLoadingMxpro360()
        });
    }

    // obtiene los datos de coordenadas de un estado especifico
    selectDataPolygonFromZone(zone) {
        return new Promise((resolve, reject) => {
            this.statesPoligons.forEach((state) => {
                // asigna coordenadas de poiligono cuando encuentra el estado correspondiente
                if (state.prefix === zone.code) {
                    const zoneShape = {
                        lat: [],
                        lng: []
                    }
                    for (let coordenate of state.polygon) {
                        zoneShape.lat.push(coordenate.lat);
                        zoneShape.lng.push(coordenate.lng);
                    }
                    resolve(zoneShape);
                }
            });
        });
    }

    /**
     * Metodo que dibuja las figuras guardadas en base de datos
     */
    async drawShapes() {
        this.helperService.showLoadingMxpro360();
        
        // Se cargan todas las figuras desde db
        this.zonesRatesMapService
            .getAllByTariffVersionId(this.tariffVersionFactory.getTariffVersionId()).then(response => {
                this.zonesSelect = response;                
                this.setShapesOnMap(null);
            });
    }

    setShapesOnMap(deliveryZoneId) {
        this.initMap();
        this.deliveryZoneId = deliveryZoneId;
        const THIS = this;
        THIS.infowindow = new google.maps.InfoWindow();
        this.zonesSelect.forEach(async zone => {
            const firstCoordenate = {
                lat: 0,
                lng: 0,
                setted: false
            };
            let zindex = 1;
            for (let zoneState of zone.zones_states) {
                if (THIS.deliveryZoneId == null || zone.id == THIS.zoneOutId || zone.id == THIS.deliveryZoneId) {
                    // setea color de zonas
                    let strokecolor = '#ff9100';
                    if (THIS.zoneOutId == zoneState.zone_id) {
                        strokecolor = '#003649';
                    }                   
                    // Se crea un arreglo para guardarsen las coordenadas de una figura y ser dibujadas
                    const arrayPaths = [];
                    const bounds = new google.maps.LatLngBounds();
                    let shape: any;
                    let marker: any;
                    // determina si es circulo o poligono
                    let shapeType: string;
                    // valida que fitura se debe dibujar
                    if (zoneState.radius) {
                        shapeType = 'radius';
                        if (!firstCoordenate.setted) {
                            firstCoordenate.setted = true;
                            firstCoordenate.lat = zoneState.geospatial.coordinates[1];
                            firstCoordenate.lng = zoneState.geospatial.coordinates[0];
                        }
                        // dibujar radio
                        arrayPaths.push({
                            lat: Number(zoneState.geospatial.coordinates[1]),
                            lng: Number(zoneState.geospatial.coordinates[0])
                        });
                        bounds.extend(arrayPaths[0]);
    
                        const lati = bounds.getCenter().lat();
                        const lngi = bounds.getCenter().lng();
                        marker = new google.maps.Marker({
                            position: { lat: lati, lng: lngi },
                            map: THIS.map,
                            label: zone.name,
                            icon: '-'
                        });
                        shape = new google.maps.Circle({
                            strokeColor: strokecolor,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: strokecolor,
                            fillOpacity: 0.35,
                            marker: marker,
                            center: {
                              lat: Number(lati),
                              lng: Number(lngi)
                            },
                            radius: zoneState.radius / 0.000621371,
                            zIndex: zindex++
                          });
                    } else {
                        shapeType = 'polygon';
                        const zoneShape: any = await this.selectDataPolygonFromZone(zoneState);
                        for (let index = 0; index < zoneShape.lat.length; index++) {
                            if (!firstCoordenate.setted) {
                                firstCoordenate.setted = true;
                                firstCoordenate.lat = zoneShape.lat[index];
                                firstCoordenate.lng = zoneShape.lng[index];
                            }
                            arrayPaths.push({
                                lat: Number(zoneShape.lat[index]),
                                lng: Number(zoneShape.lng[index])
                            });
    
                        }
                        for (let i = 0; i < arrayPaths.length; i++) {
                            bounds.extend(arrayPaths[i]);
                        }
    
                        const lati = bounds.getCenter().lat();
                        const lngi = bounds.getCenter().lng();
                        marker = new google.maps.Marker({
                            position: { lat: lati, lng: lngi },
                            map: THIS.map,
                            label: zone.name,
                            icon: '-'
                        });
    
                        // Se dibujan las figuras
                        shape = new google.maps.Polygon({
                            paths: arrayPaths,
                            strokeColor: strokecolor,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: strokecolor,
                            fillOpacity: 0.35,
                            infowindow: THIS.infowindow,
                            marker: marker
                        });
                    }
                    
                    let selectedShape;
                    // await THIS.searchZipCodeByCoordinates();
    
                    this.openedInfo = new google.maps.InfoWindow();
    
                    THIS.map.addListener('click', (e) => {
                        // Se reinician los valores para el filtrado en la tabla
                        THIS.routesRates = [];
                        THIS.routesRatesToCompare = [];
                        THIS.eventCounter = 0;
                        shape.set('strokeColor', '#003649');
                        shape.set('fillColor', '#003649');
                        shape.setMap(THIS.map);
                    });
    
                    // actualiza seleccion al dar click en el poligono
                    google.maps.event.addListener(shape, 'click', async () => {
                        await THIS.routeRatesService
                            .getRouteRate(THIS.zoneOutId, zone.id, THIS.periodId, this.tariffVersionFactory.getTariffVersionId())
                            .then((response) => {
                                THIS.routesRates = response;
                                THIS.sizeMap = this.compactSizeMap;
                                THIS.setZoneOut();
                            });
                        // openInfoWindow();
                        THIS.orderingRates();
                        THIS.setShapesOnMap(zone.id);
                    });
                    // eventos del poligonos
                    this.setMapRoutes(shape, shapeType, zone);
                }
            }
        });
    }

    setMapRoutes(shape, shapeType, zone) {
        const THIS = this;
        // eventos del poligonos
        if (shapeType == 'polygon') {
            google.maps.event.addListener(shape.getPath(), 'set_at', () => {
                setSelection(shape, true);
            });
            google.maps.event.addListener(shape.getPath(), 'insert_at', () => {
                setSelection(shape, true);
            });
        }

        this.searchZipcodes();

        shape.setMap(this.map);

        this.helperService.hideLoadingMxpro360();

        async function setSelection(shape, editing) {
            const selectedShape = shape;
            if (editing) {
                // editar coordenadas
                zone.geospatial.coordinates = [];
                THIS.zipcodes = [];
                const coordinatesJson = [];
                // recorre las coordenadas del poligono para almacenarlas en el objeto que se envia a bd
                const pathCoordenates = selectedShape.getPath().getArray();
                for (const pathCoordenate of pathCoordenates) {
                    coordinatesJson.push([pathCoordenate.lng(), pathCoordenate.lat()]);
                    await THIS.searchWithLongLan(pathCoordenate.lat(), pathCoordenate.lng());
                }
                coordinatesJson.push([pathCoordenates[0].lng(), pathCoordenates[0].lat()]);
                // llena los datos necesarios
                zone.geospatial.coordinates.push(coordinatesJson);
                THIS.zonesRatesMapService.edit(zone).then(() => { });
            }
        }
    }

    // setea datos de la zona saliente o pickup
    setZoneOut() {
        for (let zone of this.zonesSelect) {
            if (zone.id === this.zoneOutId) {
                this.zoneOut = zone;
                break;
            }
        }
    }

    // ordena la tarifas de acuerdo a los volumenes
    orderingRates() {
        console.log('orderingRates this.routesRates', this.routesRates);
        
        if (this.routesRates.length > 0){
            const volumeRouteRates = [];
            const volumeRates = this.routesRates[0].volume_rates;
            for (let volume of this.volumes) {
                for (let volumeRate of volumeRates) {
                    if (volume.id === volumeRate.volume_id) {
                        volumeRate.name = volume.up_to_cf;
                        volumeRouteRates.push(volumeRate);
                    }
                }
            }
            this.routesRates[0].volume_rates = volumeRouteRates;
        }

    }
    /*
      Metodo que busca el zipcode por longitud y latitud
      */
    searchZipCodeByCoordinates() {
        const THIS = this;
        return new Promise((resolve) => {
            const latlng = new google.maps.LatLng('4.535000', '-75.675690');
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ latLng: latlng }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        for (let j = 0; j < results[0].address_components.length; j++) {
                            if (results[0].address_components[j].types[0] === 'postal_code') {
                                THIS.zipcode = results[0].address_components[j].short_name;
                                // alert("Zip Code: " + results[0].address_components[j].short_name);
                                resolve(results[0].address_components[j].short_name);
                            }
                        }
                        resolve(true);
                    }
                } else {
                    resolve(false);
                    // alert("Geocoder failed due to: " + status);
                }
            });
        });
    }

}
