
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { Company } from 'src/app/entities/workspace/company';
import { ConstantsMessages } from 'src/app/constants-messages';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { LeadForwardingLinksService } from 'src/app/services/workspaces/lead-forwarding-links.service';
import { LeadForwardingLink } from 'src/app/entities/workspace/lead-forwarding-link';

declare const jQuery;

@Component({
    selector: 'app-advertisers-form',
    templateUrl: './advertisers-form.component.html',
    styleUrls: ['./advertisers-form.component.scss']
})
export class AdvertisersFormComponent implements OnInit {

    dataForm: Advertiser;
    companies: Array<Company>;
    leadForwardingLinks: Array<LeadForwardingLink>;

    companiesLoaded: boolean;
    linksLoaded: boolean;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    @ViewChild("companySelector")
    companySelectorElem: ElementRef;

    @ViewChild("linkSelector")
    linkSelectorElem: ElementRef

    constructor(
        private leadForwardingLinksService: LeadForwardingLinksService,
        private advertisersService: AdvertisersService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private companiesService: CompaniesService
    ) {
        this.companies = [];
        this.leadForwardingLinks = [];
        this.dataForm = new Advertiser();

        this.companiesLoaded = false;
        this.linksLoaded = false;
    }

    ngOnInit(): void {

        this.loadCompanies();
        this.loadLinks();

        this.currentRoute
            .params
            .subscribe(params => {
                if (typeof params.id !== 'undefined') {
                    this.load(params.id);
                }
            });
    }

    ngAfterViewInit(): void {
        
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.advertisersService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                this.listenChangesCompany();
                this.listenChangesLink();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadCompanies(): void {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll(false)
            .then((response) => {
                this.companies = [];
                for (const company of response) {
                    if (company.status === "ACTIVE") {
                        this.companies.push(company);
                    }
                }

                this.listenChangesCompany();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadLinks() {
        this.leadForwardingLinksService
            .getAll()
            .then((response) => {
                this.leadForwardingLinks = response;
                this.listenChangesLink();
            })
            .catch((error) => {
                console.error('error', error);
            })
    }

    private listenChangesCompany() {

        if (this.companiesLoaded == false) {
            this.companiesLoaded = true;
            return;
        }

        setTimeout(() => {

            const THIS = this;
            const select = jQuery(this.companySelectorElem.nativeElement);

            select
                .select2({
                    tags: true
                })
                .on('select2:select', (e) => {
                    THIS.setLeadCompany(e.params.data, 'select');
                })
                .on('select2:unselect', (e) => {
                    THIS.setLeadCompany(e.params.data, 'unselect');
                });

            // Usar MutationObserver para detectar cambios en el DOM
            const observer = new MutationObserver(() => {
                select.trigger('change');
                select.trigger('change.select2');
            });

            // Observar cambios en el nodo de Select
            observer.observe(select[0], { childList: true, subtree: true });

        }, 1000);
    }


    setLeadCompany(position, type: string) {

        const rowID = position.id.split(':')[1].trim().replace(/'/g, '');

        if (type === 'select') {
            this.dataForm.lead_companies_id.push(rowID);
        }

        if (type === 'unselect') {
            const arrayIndex = this.dataForm.lead_companies_id.indexOf(rowID);
            if (arrayIndex >= 0) {
                this.dataForm.lead_companies_id.splice(rowID, 1);
            }
        }
    }


    private listenChangesLink() {

        if (this.linksLoaded == false) {
            this.linksLoaded = true;
            return;
        }

        setTimeout(() => {

            const THIS = this;
            const select = jQuery(this.linkSelectorElem.nativeElement);

            select
                .select2({
                    tags: true
                })
                .on('select2:select', (e) => {
                    THIS.setLinkForwarding(e.params.data, 'select');
                })
                .on('select2:unselect', (e) => {
                    THIS.setLinkForwarding(e.params.data, 'unselect');
                });


            // Usar MutationObserver para detectar cambios en el DOM
            const observer = new MutationObserver(() => {
                select.trigger('change');
                select.trigger('change.select2');
            });

            // Observar cambios en el nodo de Select
            observer.observe(select[0], { childList: true, subtree: true });

        }, 1000);

    }

    setLinkForwarding(position, type: string) {

        const tmpID = position.id.split(':')[1].trim().replace(/'/g, '');

        if (type === 'select') {
            this.dataForm.lead_forwarding_links_id.push(tmpID);
        }

        if (type === 'unselect') {
            const arrayIndex = this.dataForm.lead_forwarding_links_id.indexOf(tmpID);
            if (arrayIndex >= 0) {
                this.dataForm.lead_forwarding_links_id.splice(tmpID, 1);
            }
        }
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.advertisersService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/advertisers');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
