<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Suppliers
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                                    Dashboard</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Suppliers</li>
                        </ol>
                    </nav>
                    <br>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input type="text" name="filterSearch" [(ngModel)]="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <a appWorkspaceRouterLink="/suppliers/add" class="btn btn-warning">
                    <i class="fas fa-plus-square"></i> &nbsp; New Supplier
                </a>

            </div>

        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Email</th>
                                <th>Created</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.phone}}</td>
                                <td>{{row.email}}</td>
                                <td>{{row.created | dateTime}}</td>
                                <td>
                                    <a appWorkspaceRouterLink="/suppliers/{{row.id}}/view" class="btn btn-outline-dark">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zoom-in"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line><line x1="11" y1="8" x2="11" y2="14"></line><line x1="8" y1="11" x2="14" y2="11"></line></svg>
                                    </a>
                                    <a appWorkspaceRouterLink="/suppliers/{{row.id}}/edit" class="btn btn-outline-dark">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
