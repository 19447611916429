import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoardJobView } from 'src/app/entities/workspace/board-job-view';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierCandidateView } from 'src/app/entities/workspace/carrier-candidate-view';
import { JobOutsourcingCancelledView } from 'src/app/entities/workspace/job-outsourcing-cancelled-view';
import { JobView } from 'src/app/entities/workspace/job-view';
import { HelperService } from 'src/app/services/helper.service';
import { BoardJobsService } from 'src/app/services/workspaces/board-jobs.service';
import { JobsService } from 'src/app/services/workspaces/jobs.service';



declare const jQuery;
declare const swal;

@Component({
    selector: 'app-board-jobs-view',
    templateUrl: './board-jobs-view.component.html',
    styleUrls: ['./board-jobs-view.component.scss']
})
export class BoardJobsViewComponent implements OnInit {

    public candidates: Array<CarrierCandidateView>;
    public boardJob: BoardJobView;
    public addCarriers: boolean;
    public jobOutsourcingCancelleds: Array<JobOutsourcingCancelledView>;
    public candidate: CarrierCandidateView;
    public offerCarrier: number;
    public job: JobView;
    public carrier: Carrier;

    @ViewChild('offerModal')
    offerModal: ElementRef;

    constructor(
        private boardJobsService: BoardJobsService,
        private currentRoute: ActivatedRoute,
        private jobsService: JobsService,
        private helperService: HelperService

    ) {
        this.addCarriers = true;
        this.jobOutsourcingCancelleds = [];
        this.boardJob = new BoardJobView();
        this.candidate = new CarrierCandidateView();
        this.offerCarrier = 0;
        this.job = new JobView();
        this.carrier = new Carrier();

    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.boardJobsService
            .getById(id)
            .then((response) => {
                this.boardJob = response;
                // this.loadJob(this.boardJob.job_id);
                this.loadAllCandidates();
                this.loadHistoryJobOutSourcing();
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private async loadJob(id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getById(id)
            .then((response) => {
                this.job = response;
                this.carrier = this.job.carrier;
                this.loadAllCandidates();
                this.loadHistoryJobOutSourcing();

                // if (this.carrier) {
                //     this.loadContactsByCarrier();
                // }

                if (this.job.job_outsourcing) {
                    if (this.job.job_outsourcing.id && this.job.job_outsourcing.status === 'WAITING_FOR') {
                        // this.acceptJob = true;
                    }
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private loadAllCandidates(): void {

        this.helperService.showLoadingMxpro360();
        this.addCarriers = this.job.job_outsourcing.carrier_id ? true: false;
        this.boardJobsService
            .getAllCandidates(this.boardJob.id)
            .then((response) => {
                this.candidates = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private loadHistoryJobOutSourcing() {
        this.helperService.showLoadingMxpro360();/* 
        this.boardJobsService
            .getHistoryOutsourcing(this.boardJob.id)
            .then((response) => {
                this.jobOutsourcingCancelleds = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            }); */
    }

    openOfferModal(candidate: CarrierCandidateView): void {
        jQuery(this.offerModal.nativeElement).modal('show');
        this.candidate = candidate;
    }


    closeOffersModal(): void {
        jQuery(this.offerModal.nativeElement).modal('hide');
        this.candidate = new CarrierCandidateView();
    }


    public updateStatusCandidate() {

        if (this.offerCarrier <= 0) {
            swal({
                title: 'Enter a value greater than zero',
                padding: '2em'
            });
            return;
        }

        this.helperService.showLoadingMxpro360();

        const dataForm = {
            type: 'CARRIER',
            id_candidate: this.candidate.id,
            carrier_pay: this.offerCarrier,
            status: 'FINISHED'
        };


        this.boardJobsService
            .acceptJob(dataForm, this.boardJob.id)
            .then(() => {
                // this.openEmailsModal();
                // this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.load(this.boardJob.id);
                this.candidate = new CarrierCandidateView();
                ;
            })
            .catch((error) => {
                // this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    deleteJobOutsourcing(idJob) {
        this.helperService.showLoadingMxpro360();

        this.boardJobsService
            .deleteOutsourcingWorspace(idJob)
            .then((response) => {
                this.load(this.boardJob.id);
                // this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                // this.load(this.job.id);

            })
            .catch((error) => {
                // this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }


    /**
     * Descarga el pdf del estimate
     */
    downloadPdf(): void {
        this.helperService.downloadReport('CONTRACT_INFORMATION', null, this.job.job_outsourcing.job_acceptance_doc);
    }



}
