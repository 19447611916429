import { Meta } from "../global/meta";
export class DocumentType {
    id: string;
    name: string
    prefix: string;
    initial_consecutive: number;
    usage_context: 'AUTO_TRANSPORT' | 'BILL_OF_LADING' | 'CARRIER_CONTRACT' | 'CARRIER_INTERLINE' | 'CARRIER_JOB_ACCEPTANCE' | 'CREDIT_CARD_AUTHORIZATION_CARRIER' | 'CREDIT_CARD_AUTHORIZATION' | 'CUSTOMER_JOB_CONTRACT' | 'DRIVER_INVOICES' | 'ESTIMATES' | 'NEW_BINDING_ESTIMATE' | 'FREIGHT_ESTIMATE' | 'FREIGHT_RATE_CONFIRMATION_SHEET' | 'ONLINE_PAYMENTS_VOUCHER' | 'SPECIAL_POWER_ATTORNEY' | 'STORAGE_INVOICE' | 'TICKETS' | 'PPW_PACKET' | 'ACH_AUTHORIZATION_FORM' | 'LOCAL_BOL';
    default: boolean;
    use_payment_terms: boolean;
    use_optional_accesorial_services: boolean;
    company_id: string;
    template: string;
    created: string;
    updated: string;
    metas: Array<Meta>;
    request_on_pickup: boolean;

    constructor() {
        this.id = null;
        this.name = null;
        this.prefix = null;
        this.initial_consecutive = null;
        this.usage_context = null;
        this.default = null;
        this.use_payment_terms = null;
        this.use_optional_accesorial_services = null;
        this.company_id = null;
        this.template = null;
        this.created = null;
        this.updated = null;
        this.metas = [];
        this.request_on_pickup = false;
    }

}
