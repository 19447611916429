<div class="text-center mt-4 mb-5">
    <h5>{{dateToday | dateTime}}</h5>
    <h2 class="welcome-text" *ngIf="employee != null">
        <strong>Welcome, {{employee.name | titlecase}}</strong>
    </h2>
    <h4 class="text-center" *ngIf="employee != null">
        {{employee.job_position | filterReplace}}
    </h4>
</div>


<div class="row">


    <ng-container  *ngIf="jobPosition == 'OPERATOR'">



        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-today [companyId]="false"> </app-jobs-picked-today>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-tomorrow [companyId]="false"> </app-jobs-picked-tomorrow>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked2-days [companyId]="false"> </app-jobs-picked2-days>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="false"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="false"> </app-jobs-by-operation-status>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="false"> </app-recurring-customer>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-in-window [companyId]="false"> </app-jobs-picked-in-window>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="false"> </app-last5-payments>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="false"> </app-tickets-by-priority>
        </div>

    </ng-container>



    <ng-container  *ngIf="jobPosition == 'CUSTOMER_SERVICE'">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="false"> </app-last5-payments>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="false"> </app-jobs-by-operation-status>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="false"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="false"> </app-recurring-customer>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-assigned [companyId]="false"> </app-tickets-assigned>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="false"> </app-tickets-by-priority>
        </div>
    </ng-container>


    <ng-container  *ngIf="jobPosition == 'ADMIN'">

        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-incoming-leads [companyId]="false"> </app-total-incoming-leads>
        </div>
        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-bad-leads [companyId]="false"> </app-total-bad-leads>
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-sales-by-company> </app-sales-by-company>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="false"> </app-last5-payments>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-payment-methods-used [companyId]="false"> </app-payment-methods-used>
        </div>

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-today [companyId]="false"> </app-jobs-picked-today>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-tomorrow [companyId]="false"> </app-jobs-picked-tomorrow>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked2-days [companyId]="false"> </app-jobs-picked2-days>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-closing-ratio-per-lead [companyId]="false"> </app-closing-ratio-per-lead>
            <app-closing-ratio-per-estimate [companyId]="false"> </app-closing-ratio-per-estimate>
            <app-estimate-ratio-per-lead [companyId]="false"> </app-estimate-ratio-per-lead>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="false"> </app-jobs-by-operation-status>
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-booked-by-company> </app-booked-by-company>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="false"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-sales-leader [companyId]="false"> </app-sales-leader>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="false"> </app-tickets-by-priority>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-in-window [companyId]="false"> </app-jobs-picked-in-window>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-groupal-log-viewed-estimate [companyId]="false"> </app-groupal-log-viewed-estimate>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="false"> </app-recurring-customer>
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-lead-providers-timelines [companyId]="false"> </app-lead-providers-timelines>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-tickets-global [companyId]="false"> </app-last5-tickets-global>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">

        </div>

    </ng-container>


</div>