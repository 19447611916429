import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SalesmanPerformView } from 'src/app/entities/workspace/salesman-perform-view';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class SalesmanPerformsService {

    constructor(
        private http: HttpClient
    ) { 
        
    }

    getSalesPerforms(data) {
        return this.http.get<Array<SalesmanPerformView>>(environment.api.workspace + '/sellers-performance', { params: data }).toPromise();
    }

    getSalesPerformsCustom(data, type, id) {
        return this.http.get<Array<SalesmanPerformView>>(environment.api.workspace + '/sellers-performance/' + type + '/' + id, { params: data }).toPromise();
    }

    getSalesPerformsTotals(data, type) {
        return this.http.get<Array<SalesmanPerformView>>(environment.api.workspace + '/sellers-performance/' + type, { params: data }).toPromise();
    }
}
