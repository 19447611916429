export class CardknoxSetting {
    public x_key: string;
    public x_software_name: string;
    public x_software_version: string;

    constructor() {
        this.x_key = null; // moverx360comdevc51fd6fd15544292ab790485f08385
        this.x_software_name = null; // MoverX360
        this.x_software_version = null; // 1.0.0
    }
}

