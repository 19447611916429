
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Supplier } from 'src/app/entities/workspace/supplier';
import { HelperService } from 'src/app/services/helper.service';
import { SuppliersService } from 'src/app/services/workspaces/suppliers.service';

declare const jQuery;

@Component({
    selector: 'app-suppliers-form',
    templateUrl: './suppliers-form.component.html',
    styleUrls: ['./suppliers-form.component.scss']
})
export class SuppliersFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: Supplier;

    constructor(
        private suppliersService: SuppliersService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.dataForm = new Supplier();
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.suppliersService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {

        this.helperService.showLoadingMxpro360();
        this.suppliersService
            .save(this.dataForm)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/suppliers');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
