import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneNumber } from 'src/app/entities/admin/phone-number';
import { environment } from 'src/environments/environment';


declare let jQuery

@Injectable({
    providedIn: 'root'
})
export class CallService {


    constructor(private http: HttpClient) {
    }

    send(body): Promise<PhoneNumber> {
        return this.http.post<PhoneNumber>(environment.api.apiRtcPrivate + '/connectionInfo', body).toPromise();
    }

    number(number, sockedId) {
        return this.http.post(environment.api.apiRtcPrivate + '/initialize-dialer', { phone_extension_id: number, socket_id: sockedId }).toPromise();
    }

    disconnectNumber(sockedId, phone_extension_id) {
        return this.http.post(environment.api.apiRtcPrivate + '/close-dialer', { sockedId: sockedId, phone_extension_id: phone_extension_id }).toPromise();
    }

    callphone(phoneNumber: string, extensionId): Promise<PhoneNumber> {
        return this.http.post<PhoneNumber>(environment.api.apiRtcPrivate + '/make-call', { PhoneNumber: phoneNumber, phone_extension_id: extensionId }).toPromise();
    }


    saveCalls
        (file) {

        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: environment.api.apiRtcPublic + '/saveCalls',
                type: 'post',
                data: file,
                contentType: false,
                processData: false,
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }
            })

        })
    }

    incomingCall() {
        return this.http.post(environment.api.apiRtcPublic + '/incoming-call', { headers: { "Content-Type": "aplication/xml", } }).toPromise();
    }

    callAnswered(numbers) {
        return this.http.post(environment.api.apiRtcPublic + '/outgoing-call-answered', { headers: { "Content-Type": "aplication/xml", } }).toPromise();
    }
    callStatus(numbers) {
        return this.http.post(environment.api.apiRtcPublic + '/call-disconnected', { headers: { "Content-Type": "aplication/xml", } }).toPromise();
    }
    transferCall(numberUser, callId, custonPhone) {
        return this.http.post(environment.api.apiRtcPrivate + '/transfer-call', { phone: '+1' + numberUser, callId: callId, custonPhone: custonPhone }).toPromise();
    }
    ivr(numbers) {
        return this.http.post(environment.api.apiRtcPrivate + '/ivr', { headers: { "Content-Type": "aplication/xml", } }).toPromise();
    }
    voicemail(body) {
        return this.http.post(environment.api.apiRtcPublic + '/voicemail', body).toPromise();
    }

    hangup(callid) {
        return this.http.put(environment.api.apiRtcPrivate + '/hangup', { callId: callid }).toPromise();
    }

    rejectCall(callid) {
        return this.http.post(environment.api.apiRtcPrivate + '/reject-incoming-call', callid).toPromise();
    }

    forward(phone, callId) {
        return this.http.post(environment.api.apiRtcPrivate + '/forward', {
            phone: phone,
            callId: callId
        }).toPromise();
    }

    getidRecording() {
        return this.http.get(environment.api.apiRtcPublic + '/record').toPromise();
    }

    holdCall(callId) {
        return this.http.post(environment.api.apiRtcPublic + '/hold-call', {
            callId: callId
        }).toPromise();
    }

    holdCallResume(callId) {
        return this.http.post(environment.api.apiRtcPrivate + '/resume-hold-call', {
            callId: callId
        }).toPromise();
    }

    conferences(callids, phone_extension_id, conference_id) {
        return this.http.post(environment.api.apiRtcPrivate + '/conferences', {
            callIds: callids, phone_extension_id: phone_extension_id, conference_id: conference_id
        }).toPromise();
    }


    silenceConference(idConference, mute, callIdsConferences) {
        return this.http.post(environment.api.apiRtcPrivate + '/silence-conference', {
            idConference: idConference, mute: mute, callIdsConferences: callIdsConferences
        }).toPromise();
    }


}
