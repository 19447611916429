<div class="row layout-top-spacing">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    Build Posting Link
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item active">Build Posting Link</li>
                    </ol>
                </nav>
            </div>

        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
                <form class="form">
                    <div class="row">
                        <div class="col-12">

                            <div class="form-group mb-4">
                                <label class="control-label">Advertiser</label>
                                <select [appSelect2] [theme]="'mxp360-form'" [(ngModel)]="advertiser" name="advertiser_name" class="form-control">
                                    <option value="">
                                        Select
                                    </option>
                                    <option *ngFor="let allAdvertiser of allAdvertisers" value="{{allAdvertiser.id}}">
                                        {{allAdvertiser.name}}
                                    </option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select Advertiser.</small>
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Company</label>
                                <select [(ngModel)]="company" name="company_name" class="form-control">
                                    <option value="ALL">
                                        All
                                    </option>
                                    <option *ngFor="let company of companies" value="{{company.id}}">
                                        {{company.name}}
                                    </option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select Company.</small>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="mt-2 mb-5">
                    <span>
                        <strong>Request<span class="asterisk">*</span></strong>
                    </span>
                    <span>- Select at least one company to use the URL</span>
                </div>

                <div class="form-group">
                    <div class="toggle-switch">                
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="isAngi" name="isAngi"
                            [(ngModel)]="isAngiLead">
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="isAngi" class="checkbox-label" [ngClass]="{'filter-disabled': !isAngiLead}">
                            Check the box if you are building a URL for Angi's Leads
                        </label>
                    </div>
                </div>

                <h4 style="margin-top: 60px;"> <strong> URL POSTING LEADS</strong></h4>
                <div class=" mb-4 adjust-text-api ">
                    <span *ngIf="!isAngiLead">https://webhooks.moverxpro360.com/leads?</span>
                    <span *ngIf="isAngiLead">https://webhooks.moverxpro360.com/angi-leads?</span>
                    <span *ngIf="workspace != ''">workspace={{workspace}}</span>
                    <span *ngIf="company != ''">&company={{company}}</span>
                    <span *ngIf="advertiser != ''">&advertiser={{advertiser}}</span>
                </div>

                <br>

                <div class="text-center">
                    <a href="https://developer.moverxpro360.com/" class="btn btn-success mt-5" target="_blank">
                        Go To Posting Instructions
                    </a>
                </div>                
                <br>
            </div>
        </div>
    </div>
</div>