<div class="row layout-top-spacing">
    <div class="col-lg-12">
        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

            <div class="row">

                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                    <h3>
                        New Payment Method
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>
                                    Dashboard</a>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Payments</a></li>
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/payment-methods">Payment Methods</a>
                            </li>
                        </ol>
                    </nav>
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button class="btn btn-main-action" type="submit">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <!-- header -->

            <div class="statbox widget box box-shadow">          
                <div class="widget-content widget-content-area">
                    <div class="row">
                        <div class="col-12">

                            <div class="form-group mb-1">
                                <label for="company_dot">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" name="company_dot"
                                    placeholder="Enter here the name" [(ngModel)]="paymentMethod.name">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Write Name.</small>
                            </div>
    

                            <div class="form-group mb-4">
                                <label for="method">Method</label>
                                <select [(ngModel)]="paymentMethod.method" name="method" class="form-control">
                                    <option value="CREDIT_CARD">Credit Card</option>
                                    <option value="BANK_TRANSFER_ONLINE">Bank Transfer Online</option>
                                    <option value="BANK_TRANSFER_OFFLINE">Bank Transfer Offline</option>
                                    <option value="CHECK">Check</option>
                                    <option value="MONEY_ORDER">Money Order</option>
                                    <option value="CASH">Cash</option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select Methods Payment.</small>
                            </div>

                            <div class="form-group mb-4">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="available_for_storage_payment"
                                        [(ngModel)]="paymentMethod.available_for_storage_payment"
                                        name="available_for_storage_payment">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="available_for_storage_payment" class="checkbox-label" [ngClass]="{'filter-disabled': !paymentMethod.available_for_storage_payment}">
                                        Available For Storage Payments
                                    </label>
                                </div>
                            </div>

                            

                            <div class="row">                                
                                <div class="col-md-12">    
                                    <label>Description</label>                                
                                    <app-ck-editor 
                                        [(ngModel)]="paymentMethod.description"
                                        name="description" ngDefaultControl>
                                    </app-ck-editor>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>