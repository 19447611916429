<!--NEW-->
<div class="row">
    <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12">

        <div class="user-profile layout-spacing pb-18" *ngIf="customer != null">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >Customer</h3>
                </div>
                <div class="text-center user-info mt-20">
                    <p>
                        {{customer.name}}
                    </p>
                </div>
                <div class="user-info-list">

                    <div >
                        <ul class="contacts-block list-unstyled m-21-auto">
                            <li class="contacts-block__item">
                                <a href="mailto:{{customer.email}}"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    {{customer.email}}
                                </a>
                            </li>
                            <li class="contacts-block__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                                {{customer.phone | phone}}
                            </li>
                        </ul>
                    </div>

                    <div  *ngIf="job.estimate.contacts.length > 0">
    
                        <hr>

                        <ul class="contacts-block list-unstyled" *ngFor="let contact of job.estimate.contacts">
                            <li class="contacts-block__item" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                            {{contact.name}}

                            </li>
                            <li class="contacts-block__item">
                                <a href="mailto:example@mail.com"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    {{contact.email}}
                                    
                                </a>
                            </li>
                            <li class="contacts-block__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                                {{contact.phone}}

                            </li>
                        </ul>
                    </div>


                </div>
            </div>
        </div>

        <div class="user-profile layout-spacing" *ngIf="customer != null">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >ID Or Driver's License</h3>
                </div>
                
                <div *ngIf="jobDriversLicense.length > 0" class="col-12 container-carousel-driver-license">
                    <div id="btn-nav-previous">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                            viewBox="0 0 24 24">
                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>                
                    <div class="carousel-driver-license">
                        <div class="driver-license" *ngFor="let item of jobDriversLicense">
                            <img src="{{imgItem}}{{item.image.display_name}}" (click)="openModalShowDriverLicense(imgItem + item.image.display_name)">
                        </div>
                    </div>
                    <div id="btn-nav-next">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                            viewBox="0 0 24 24">
                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>
                </div>

            </div>
        </div>

    </div>
    <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Route
                    </h3>
                </div>

                <app-google-maps class="map" [from]="job.estimate.pickup.address" [to]="job.estimate.delivery.address"  ></app-google-maps>
            </div>
        </div>
    </div>
    
</div>

<div class="row">
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">

        <div class="education layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 *ngIf="job.estimate.service !== 'LOCAL'">Operating Status History</h3>
                    <h3 *ngIf="job.estimate.service == 'LOCAL'">Time Stamps</h3>&nbsp;
                    <h3 *ngIf="job.estimate.service == 'LOCAL'">{{laborTotalHours | timeFromDecimal}} Hours</h3>
                </div>

                <div class="timeline-alter time-stamps">
                    <div class="item-timeline" *ngFor="let jobOSH of jobOperatingStatusHistory">
                        <ng-container *ngIf="job.estimate.service !== 'LOCAL'">
                            <div class="t-meta-date">
                                <p >{{jobOSH.created | dateTime}}</p>
                            </div>
                            <div class="t-dot">
                            </div>
                            <div class="t-text">
                                <p><strong>
                                    <span *ngIf="jobOSH.status == 'NEW'">
                                        New
                                    </span>
                                    <span *ngIf="jobOSH.status == 'DELIVERED_BOXES'">
                                        Delivered Boxes
                                    </span>
                                    <span *ngIf="jobOSH.status == 'PACKED_MOVE'">
                                        Packed Move
                                    </span>
                                    <span *ngIf="jobOSH.status == 'PICKED_UP'">
                                        Picked Up
                                    </span>
                                    <span *ngIf="jobOSH.status == 'IN_TRANSIT'">
                                        In Transit
                                    </span>
                                    <span *ngIf="jobOSH.status == 'IN_STORAGE'">
                                        In Storage
                                    </span>
                                    <span *ngIf="jobOSH.status == 'DELIVERED'">
                                        Delivered
                                    </span>
                                </strong></p>
                                <p>{{jobOSH.comment}}</p>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="job.estimate.service === 'LOCAL'">
                            <div class="t-meta-date">
                                {{ jobOSH.status}} <p >{{jobOSH.created | dateTime}}</p>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
        
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">

        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Status
                    </h3>

                    <div style="width: 90px; display: flex;">
                        <a (click)="openModalArchive('archive-delivered')" class="option-widget cursor-pointer btn-archive-delivery tooltip">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path><line x1="9" y1="14" x2="15" y2="14"></line></svg>
                            <span class="tooltiptext"> Archive Without Delivery Documents </span>
                        </a>
                        &nbsp;&nbsp;
                        <a (click)="openModalArchive('archive-picked')" class="option-widget cursor-pointer btn-archive-pickup tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline _ngcontent-ool-c203="" points="21 8 21 21 3 21 3 8"></polyline><rect _ngcontent-ool-c203="" x="1" y="3" width="22" height="5"></rect><line _ngcontent-ool-c203="" x1="10" y1="12" x2="14" y2="12"></line></svg>
                            <span class="tooltiptext"> Archive Without Pickup Documents </span>
                        </a>
                        &nbsp;&nbsp;
                        <a (click)="openModalStatusHistory()" class="option-widget btn-add">
                            <i class="fa-solid fa-plus"></i>
                        </a>
                        &nbsp;&nbsp;
                        <a (click)="openModalEditStatus()"  class="option-widget cursor-pointer btn-edit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-edit-3">
                                <path d="M12 20h9"></path>
                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                            </svg>
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> -->
                        </a>

                    </div>

                </div>

                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th>
                                General state:
                            </th>
                            <td>
                                <span *ngIf="job.statuses.general == 'BROKER_CANCELED'">
                                    Broker Canceled
                                </span>
                                <span *ngIf="job.statuses.general == 'CARRIER_CANCELED'">
                                    Carrier Canceled
                                </span>
                                <span *ngIf="job.statuses.general == 'WORK_CARRIED_OUT'">
                                    Work Carried Out
                                </span>
                                <span *ngIf="job.statuses.general == 'CUSTOMER_CANCELED'">
                                    Customer Canceled
                                </span>
                                <span *ngIf="job.statuses.general == 'CARRIER_PAPERWORK_ON'">
                                    Carrier Paperwork On
                                </span>
                                <span *ngIf="job.statuses.general == 'WORKING_ON'">
                                    Working On
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Operation:
                            </th>
                            <td>
                                <span *ngIf="job.statuses.operation == 'NEW'">
                                    New
                                </span>
                                <span *ngIf="job.statuses.operation == 'DELIVERED_BOXES'">
                                    Delivered Boxes
                                </span>
                                <span *ngIf="job.statuses.operation == 'PACKED_MOVE'">
                                    Packed Move
                                </span>
                                <span *ngIf="job.statuses.operation == 'PICKED_UP'">
                                    Picked Up
                                </span>
                                <span *ngIf="job.statuses.operation == 'IN_TRANSIT'">
                                    In Transit
                                </span>
                                <span *ngIf="job.statuses.operation == 'IN_STORAGE'">
                                    In Storage
                                </span>
                                <span *ngIf="job.statuses.operation == 'DELIVERED'">
                                    Delivered
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="!job.statuses.archived_picked_docs">
                            <th>
                                Picked Docs:
                            </th>
                            <td>
                                <ng-container *ngIf="job.statuses.picked_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.statuses.picked_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr *ngIf="!job.statuses.archived_picked_docs">
                            <th>
                                Delivered Docs:
                            </th>
                            <td>
                                <ng-container *ngIf="job.statuses.delivered_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.statuses.delivered_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                                
                            </td>
                        </tr>
                        <tr *ngIf="job.statuses.archived_picked_docs">
                            <th>
                                Archived pickup documents:
                            </th>
                            <td>
                                <ng-container *ngIf="job.statuses.archived_picked_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.statuses.archived_picked_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                                
                            </td>
                        </tr>
                        <tr *ngIf="job.statuses.archived_picked_docs">
                            <th>
                                Archived pickup documents note:
                            </th>
                            <td>
                                {{ job.statuses.comment_archived_picked_docs }} 
                                <br> Date: {{ job.statuses.date_archived_picked_docs | date}}
                            </td>
                        </tr>
                        <tr *ngIf="job.statuses.archived_delivered_docs">
                            <th>
                                Archived delivery documents:
                            </th>
                            <td>
                                <ng-container *ngIf="job.statuses.archived_delivered_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.statuses.archived_delivered_docs">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                                
                            </td>
                        </tr>
                        <tr *ngIf="job.statuses.archived_delivered_docs">
                            <th>
                                Archived delivery documents note:
                            </th>
                            <td>
                                {{ job.statuses.comment_archived_delivered_docs }} 
                                <br> Date: {{ job.statuses.date_archived_delivered_docs | date}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button  *ngIf="job.statuses.general !== 'WORK_CARRIED_OUT'" (click)="finishJob()" class="btn btn-success btn-block btn-rounded mb-4 mr-2">
                                    <i class="far fa-check-circle"></i> 
                                    Job Done
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 ">

        <div class="education layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Notes
                    </h3>
                    <a (click)="openModalNotes()" class="option-widget btn-add"  [hidden]="!job.job_outsourcing?.belongs_broker && job.job_outsourcing?.carrier_job_id">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                </div>

                <div class="timeline-alter" style="min-height: 210px;" >
                    <div class="item-timeline" *ngFor="let nt of jobNotes">
                        <div class="t-meta-date">
                            <p >{{nt.created | dateTime}}</p>
                        </div>
                        <div class="t-dot">
                        </div>
                        <div class="t-text">
                            <p>
                                <strong *ngIf="!nt.employee">Carrier</strong>
                                <strong *ngIf="nt.employee">{{nt.employee.name}}</strong>
                            </p>
                            <p>{{nt.remarks}}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 "  *ngIf="job.box_delivery_day.date">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Box Delivery
                    </h3>
                    <a (click)="openEditDay('boxes_delivery_day', job.box_delivery_day)" class="option-widget cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-edit-3">
                            <path d="M12 20h9"></path>
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> -->
                    </a>
                </div>


                <table class="table table-striped" *ngIf="job.box_delivery_day.date">
                    <tbody>
                        <tr>
                            <th>
                                Date:
                            </th>
                            <td>
                                {{job.box_delivery_day.date | date}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Confirm:
                            </th>
                            <td>
                                <ng-container *ngIf="job.box_delivery_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.box_delivery_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Dispached:
                            </th>
                            <td>
                                <ng-container *ngIf="job.box_delivery_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.box_delivery_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Travel Days:
                            </th>
                            <td>
                                {{job.box_delivery_day.travel_days}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total cf:
                            </th>
                            <td>
                                {{job.box_delivery_day.total_cf}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total lbs:
                            </th>
                            <td>
                                {{job.box_delivery_day.total_lbs}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Pay Hours:
                            </th>
                            <td>
                                {{job.box_delivery_day.pay_hours}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr>

                <h4>
                    Crew
                </h4>
                <table class="table table-striped" *ngIf="job.box_delivery_day.crew">
                    <tbody>
                        <tr>
                            <th>
                                Name
                            </th>
                            <td>
                                {{job.box_delivery_day.crew.name}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Driver
                            </th>
                            <td>
                                <p *ngFor="let driver of job.box_delivery_day.crew.drivers">
                                    {{driver.name}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman
                            </th>
                            <td>
                                <span *ngIf="job.box_delivery_day.crew.foreman">
                                    {{job.box_delivery_day.crew.foreman.name}}
                                </span>
                                
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman Phone
                            </th>
                            <td>
                                <span>
                                    {{job.box_delivery_day.crew?.foreman_phone}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Workers
                            </th>
                            <td>
                                <p *ngFor="let work of job.box_delivery_day.crew.workers">
                                    {{work.name}}
                                </p>
                               
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Vehicle
                            </th>
                            <td>
                                Truck - QRP 334
                            </td>
                        </tr>
                    </tbody>
                </table>

                <ng-container *ngIf="job.box_delivery_day.remarks">
                    <hr>
                    <h4>
                        Comment
                    </h4>
                    <p>
                        {{job.box_delivery_day.remarks}}
                    </p>
                </ng-container>

            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 " *ngIf="job.pack_day.date">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">


                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Pack Day
                    </h3>
                    <a (click)="openEditDay('packing_day', job.pack_day)" class="option-widget cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-edit-3">
                            <path d="M12 20h9"></path>
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> -->
                    </a>
                </div>

                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th>
                                Date:
                            </th>
                            <td>
                                {{job.pack_day.date | date}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Confirm:
                            </th>
                            <td>
                                <ng-container *ngIf="job.pack_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.pack_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Dispached:
                            </th>
                            <td>
                                <ng-container *ngIf="job.pack_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.pack_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Travel Days:
                            </th>
                            <td>
                                {{job.pack_day.travel_days}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total cf:
                            </th>
                            <td>
                                {{job.pack_day.total_cf}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total lbs:
                            </th>
                            <td>
                                {{job.pack_day.total_lbs}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Pay Hours:
                            </th>
                            <td>
                                {{job.pack_day.pay_hours}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <hr>

                <h4>
                    Crew
                </h4>
                <table class="table table-striped" *ngIf="job.pack_day.crew">
                    <tbody>
                        <tr>
                            <th>
                                Name
                            </th>
                            <td>
                                {{job.pickup_day.crew.name}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Driver
                            </th>
                            <td>
                                <p *ngFor="let driver of job.pack_day.crew.drivers">
                                    {{driver.name}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman
                            </th>
                            <td>
                                <span *ngIf="job.pack_day.crew.foreman">
                                    {{job.pack_day.crew.foreman.name}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman Phone
                            </th>
                            <td>
                                <span>
                                    {{job.pack_day.crew?.foreman_phone}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Workers
                            </th>
                            <td>
                                <p *ngFor="let work of job.pack_day.crew.workers">
                                    {{work.name}}
                                </p>
                               
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Vehicle
                            </th>
                            <td>
                                Truck - QRP 334
                            </td>
                        </tr>
                    </tbody>
                </table>

                
                <ng-container *ngIf="job.pack_day.remarks">
                    <hr>
                    <h4>
                        Comment
                    </h4>
                    <p>
                        {{job.pack_day.remarks}}
                    </p>
                </ng-container>


            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 " *ngIf="job.pickup_day.date">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Pickup Day
                    </h3>
                    <a (click)="openEditDay('pickups', job.pickup_day)"  class="option-widget cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-edit-3">
                            <path d="M12 20h9"></path>
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> -->
                    </a>
                </div>

                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th>
                                Date:
                            </th>
                            <td>
                                {{job.pickup_day.date | date}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Confirm:
                            </th>
                            <td>
                                <ng-container *ngIf="job.pickup_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.pickup_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Dispached:
                            </th>
                            <td>
                                <ng-container *ngIf="job.pickup_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.pickup_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Travel Days:
                            </th>
                            <td>
                                {{job.pickup_day.travel_days}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total cf:
                            </th>
                            <td>
                                {{job.pickup_day.total_cf}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total lbs:
                            </th>
                            <td>
                                {{job.pickup_day.total_lbs}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Pay Hours:
                            </th>
                            <td>
                                {{job.pickup_day.pay_hours}}
                            </td>
                        </tr>
                    </tbody>
                </table>


                <hr>
                <h4>
                    Crew
                </h4>
                <table class="table table-striped" *ngIf="job.pickup_day.crew">
                    <tbody>
                        <tr>
                            <th>
                                Name
                            </th>
                            <td>
                                {{job.pickup_day.crew.name}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Driver
                            </th>
                            <td>
                                <p *ngFor="let driver of job.pickup_day.crew.drivers">
                                    {{driver.name}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman
                            </th>
                            <td>
                                <span *ngIf="job.pickup_day.crew.foreman">
                                    {{job.pickup_day.crew.foreman.name}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman Phone
                            </th>
                            <td>
                                <span>
                                    {{job.pickup_day.crew?.foreman_phone}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Workers
                            </th>
                            <td>
                                <p *ngFor="let work of job.pickup_day.crew.workers">
                                    {{work.name}}
                                </p>
                               
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Vehicle
                            </th>
                            <td>
                                Truck - QRP 334
                            </td>
                        </tr>
                    </tbody>
                </table>



                <ng-container *ngIf="job.pickup_day.remarks">
                    <hr>
                    <h4>
                        Comment
                    </h4>
                    <p>
                        {{job.pickup_day.remarks}}
                    </p>
                </ng-container>


            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 " *ngIf="job.delivery_day.date">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Delivery Day
                    </h3>
                    <a (click)="openEditDay('deliveries', job.delivery_day)" class="option-widget cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-edit-3">
                            <path d="M12 20h9"></path>
                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> -->
                    </a>
                </div>


                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th>
                                Date:
                            </th>
                            <td>
                                {{job.delivery_day.date | date}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Confirm:
                            </th>
                            <td>
                                <ng-container *ngIf="job.delivery_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.delivery_day.confirm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Dispached:
                            </th>
                            <td>
                                <ng-container *ngIf="job.delivery_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>
                                </ng-container>
                                <ng-container *ngIf="!job.delivery_day.dispatched">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Travel Days:
                            </th>
                            <td>
                                {{job.delivery_day.travel_days}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total cf:
                            </th>
                            <td>
                                {{job.delivery_day.total_cf}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Total lbs:
                            </th>
                            <td>
                                {{job.delivery_day.total_lbs}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Pay Hours:
                            </th>
                            <td>
                                {{job.delivery_day.pay_hours}}
                            </td>
                        </tr>
                    </tbody>
                </table>

                
                <hr>
                <h4>
                    Crew
                </h4>
                <table class="table table-striped" *ngIf="job.delivery_day.crew">
                    <tbody>
                        <tr>
                            <th>
                                Name
                            </th>
                            <td>
                                {{job.delivery_day.crew.name}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Driver
                            </th>
                            <td>
                                <p *ngFor="let driver of job.delivery_day.crew.drivers">
                                    {{driver.name}}
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman
                            </th>
                            <td>
                                <span *ngIf="job.delivery_day.crew.foreman">
                                    {{job.delivery_day.crew.foreman.name}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Foreman Phone
                            </th>
                            <td>
                                <span>
                                    {{job.delivery_day.crew?.foreman_phone}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Workers
                            </th>
                            <td>
                                <p *ngFor="let work of job.delivery_day.crew.workers">
                                    {{work.name}}
                                </p>
                               
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Vehicle
                            </th>
                            <td>
                                Truck - QRP 334
                            </td>
                        </tr>
                    </tbody>
                </table>


                <ng-container *ngIf="job.delivery_day.remarks">
                    <hr>
                    <h4>
                        Comment
                    </h4>
                    <p>
                        {{job.delivery_day.remarks}}
                    </p>
                </ng-container>


            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Pick Up Documents
                    </h3>
                    <a (click)="openUploadModal('pickup_files')" class="option-widget btn-add">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                </div>

                <div class="row">
                    <ng-container *ngFor="let jobFile of job.files.pickup_files">
                        <div class="col-sm-6 text-center">
                            <div class="delete-button-file" appCanUserCompany="'owner'" (click)="deleteImage(jobFile,'pickup_files')">
                                x
                            </div>
                            <app-file-previewer path="/moving-files"  [file]="jobFile"></app-file-previewer>
                        </div>
                    </ng-container>
                </div>
                
            </div>
        </div>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Delivery Documents
                    </h3>
                    <a (click)="openUploadModal('delivery_files')"  class="option-widget btn-add">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                </div>

                
                <div class="row">
                    <ng-container *ngFor="let jobFile of job.files.delivery_files">
                        <div class="col-sm-6 text-center">
                            <div class="delete-button-file" [appCanUserCompany]="'owner'" (click)="deleteImage(jobFile,'delivery_files')">
                                x
                            </div>
                            <app-file-previewer path="/moving-files"  [file]="jobFile"></app-file-previewer>
                            <!-- <img alt="avatar" src="https://picsum.photos/200" class="rounded img-fluid img-thumbnail mt-2" /> -->
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
    
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Digital Signs
                    </h3>
                    <a (click)="openUploadModal('digital_signatures')" class="option-widget btn-add">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                </div>

                
                <div class="row">
                    <ng-container *ngFor="let jobFile of job.files.digital_signatures">
                        <div class="col-sm-6 text-center">
                            <div class="delete-button-file" [appCanUserCompany]="'owner'" (click)="deleteImage(jobFile,'digital_signatures')">
                                x
                            </div>
                            <app-file-previewer path="/moving-files"  [file]="jobFile"></app-file-previewer>
                            <!-- <img alt="avatar" src="https://picsum.photos/200" class="rounded img-fluid img-thumbnail mt-2" /> -->
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between widget-header">
                    <h3 >
                        Video Walkthroughs
                    </h3>
                    <a (click)="openUploadModal('video_walkthroughs')" class="option-widget btn-add">
                        <i class="fa-solid fa-plus"></i>
                    </a>
                </div>

                <div class="row">
                    <ng-container *ngFor="let jobFile of job.files.video_walkthroughs">
                        <div class="col-sm-6 text-center">
                            <div class="delete-button-file" [appCanUserCompany]="'owner'" (click)="deleteImage(jobFile,'video_walkthroughs')">
                                x
                            </div>
                            <app-file-previewer path="/moving-files"  [file]="jobFile"></app-file-previewer>
                            <!-- <img alt="avatar" src="https://picsum.photos/200" class="rounded img-fluid img-thumbnail mt-2" /> -->
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 layout-spacing">
        <div class="widget-content widget-content-area">
            <div class=" widget-heading">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-warning" (click)="sendNewBindingEstimate()">Send New Binding Estimate</button>
                    <button type="button" class="btn btn-warning" (click)="sendAmendedBOL()">Send Amended BOL</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="user-profile info-moving layout-spacing ">
    <div class="widget-content widget-content-area">

        <div class=" widget-heading">
            <h3 class="title-content-area">
                Moving
            </h3>

            <a title="Adding stop point" class="tooltipmxp btn-call-action" [hidden]="job.estimate.booked && job.estimate.qa_status !== 'RELEASE'"
                data-toggle="modal" (click)="openStopPointModal()" appCanUserCompany="SET_ADDITIONAL_STOPS" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-map-pin">
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                </svg>
            </a>

            <a title="Show route on map" class="tooltipmxp btn-call-action" data-toggle="modal"
                data-target="#modalMap" (click)="initializeMap()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-map">
                    <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                    <line x1="8" y1="2" x2="8" y2="18"></line>
                    <line x1="16" y1="6" x2="16" y2="22"></line>
                </svg>
            </a>

            <a title="Edit Moving From and To" href="#" appCanUserCompany="ESTIMATE_UPDATE_ADDRESSES" [hidden]="job.estimate.booked && job.estimate.qa_status !== 'RELEASE'"
                class="tooltipmxp btn-call-action" data-toggle="modal" (click)="openModalChangeAddress()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-edit-3">
                    <path d="M12 20h9"></path>
                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                </svg>
            </a>

        </div>

        <br>

        <div class="row">
            <div class="col col-from">
                <h5 class="text-center">From</h5>
                <p *ngIf="job.estimate.pickup.address.street">
                    <strong>Street:</strong> {{job.estimate.pickup.address.street | titlecase}}
                </p>
                <p *ngIf="job.estimate.pickup.address.city">
                    <strong>City:</strong> {{job.estimate.pickup.address.city | titlecase}}
                </p>
                <p *ngIf="job.estimate.pickup.address.state">
                    <strong>State:</strong> {{job.estimate.pickup.address.state | convertState | async}}
                </p>
                <p *ngIf="job.estimate.pickup.address.country">
                    <strong>Country:</strong>
                    <ng-container *ngIf="job.estimate.pickup.address.country == 'Estados Unidos'">
                        United States
                    </ng-container>
                    <ng-container *ngIf="job.estimate.pickup.address.country != 'Estados Unidos'">
                        {{job.estimate.pickup.address.country | titlecase}}
                    </ng-container>
                </p>
                <p *ngIf="job.estimate.pickup.address.zip_code">
                    <strong>Zip Code:</strong> {{job.estimate.pickup.address.zip_code}}
                </p>
                <p *ngIf="job.estimate.pickup.address.property_type">
                    <strong>Property Type:</strong> {{job.estimate.pickup.address.property_type}}
                </p>
                <p *ngIf="job.estimate.pickup.address.level">
                    <strong>Level:</strong> {{job.estimate.pickup.address.level}}
                </p>
                <p *ngIf="job.estimate.pickup.address.floor">
                    <strong>Floor:</strong> {{job.estimate.pickup.address.floor}}
                </p>
                <p *ngIf="job.estimate.pickup.address.apartment_number">
                    <strong>Apartment #:</strong> {{job.estimate.pickup.address.apartment_number}}
                </p>
                <p *ngIf="job.estimate.pickup.address.storage_unit_number">
                    <strong>Unit Number:</strong> {{job.estimate.pickup.address.storage_unit_number}}
                </p>
            </div>
            <div class="col col-to">
                <h5 class="text-center">To</h5>
                <p *ngIf="job.estimate.delivery.address.street">
                    <strong>Street:</strong> {{job.estimate.delivery.address.street | titlecase}}
                </p>
                <p *ngIf="job.estimate.delivery.address.city">
                    <strong>City:</strong> {{job.estimate.delivery.address.city | titlecase}}
                </p>
                <p *ngIf="job.estimate.delivery.address.state">
                    <strong>State:</strong> {{job.estimate.delivery.address.state | convertState | async}}
                </p>
                <p *ngIf="job.estimate.delivery.address.country">
                    <strong>Country:</strong>
                    <ng-container *ngIf="job.estimate.delivery.address.country == 'Estados Unidos'">United
                        States</ng-container>
                    <ng-container *ngIf="job.estimate.delivery.address.country != 'Estados Unidos'">
                        {{job.estimate.delivery.address.country | titlecase}}</ng-container>
                </p>
                <p *ngIf="job.estimate.delivery.address.zip_code">
                    <strong>Zip Code:</strong> {{job.estimate.delivery.address.zip_code}}
                </p>
                <p *ngIf="job.estimate.delivery.address.property_type">
                    <strong>Property Type:</strong> {{job.estimate.delivery.address.property_type}}
                </p>
                <p *ngIf="job.estimate.delivery.address.level">
                    <strong>Level:</strong> {{job.estimate.delivery.address.level}}
                </p>
                <p *ngIf="job.estimate.delivery.address.floor">
                    <strong>Floor:</strong> {{job.estimate.delivery.address.floor}}
                </p>
                <p *ngIf="job.estimate.delivery.address.apartment_number">
                    <strong>Apartment #:</strong> {{job.estimate.delivery.address.apartment_number}}
                </p>
                <p *ngIf="job.estimate.delivery.address.storage_unit_number">
                    <strong>Unit Number:</strong> {{job.estimate.delivery.address.storage_unit_number}}
                </p>
            </div>
        </div>

        <br *ngIf="stopPoints.length > 0">

        <div class=" widget-heading" *ngIf="stopPoints.length > 0">
            <h3 class="title-content-area">
                Stop Points
            </h3>
        </div>

        <br>

        <div class="row" *ngIf="stopPoints.length > 0">
            <div class="col col-pickup" *ngFor="let stopPoint of stopPoints">
                <div class="row">
                    <h6 class="text-center m-auto"><strong>{{stopPoint.type}}</strong></h6>
                    <a class="btn-call-action delete-btn tooltipmxp" title="Delete Stop"
                        (click)="removeStopPoint(stopPoint)" appCanUserCompany="SET_ADDITIONAL_STOPS" type="button"
                        [hidden]="job.estimate.booked && job.estimate.qa_status !== 'RELEASE'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-trash-2">
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path
                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                            </path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                    </a>
                </div>

                <p *ngIf="stopPoint.address.street">
                    <strong>Street: </strong> {{stopPoint.address.street}}
                </p>
                <p *ngIf="stopPoint.address.city">
                    <strong>City: </strong> {{stopPoint.address.city}}
                </p>
                <p *ngIf="stopPoint.address.state">
                    <strong>State: </strong> {{stopPoint.address.state | convertState | async}}
                </p>
                <p *ngIf="stopPoint.address.zip_code">
                    <strong>Zipcode: </strong> {{stopPoint.address.zip_code}}
                </p>
                <p *ngIf="stopPoint.address.property_type">
                    <strong>Property type: </strong> {{stopPoint.address.property_type}}
                </p>
                <p *ngIf="stopPoint.address.level">
                    <strong>Level: </strong> {{stopPoint.address.level}}
                </p>
                <p *ngIf="stopPoint.address.floor">
                    <strong>Floor: </strong> {{stopPoint.address.floor}}
                </p>
                <p *ngIf="stopPoint.address.apartment_number">
                    <strong># Apartment: </strong> {{stopPoint.address.apartment_number}}
                </p>
                <br>
                <div class="row col-12">

                </div>
            </div>
        </div>


    </div>
</div>

<!-- Additional Items -->
<div class="bio layout-spacing ">
    <div class="widget-content widget-content-area">

        <div class="d-flex justify-content-between widget-header">
            <h3 class="mt-2">
                Additional Items
            </h3>
            <a class="btn btn-new" (click)="openSearchOperatingItems(true)">
                <i class="fas fa-plus-square"></i> &nbsp; Add Additional Items
            </a>
        </div>

        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn" [ngClass]="filterJobInventoryItems == 'all' ? 'btn-success' : 'btn-light'" (click)="loadJobInventoryItems(jobId)">All</button>
            <button type="button" class="btn" *ngFor="let inventoryCategory of curentInventoryCategories" [ngClass]="filterJobInventoryItems == inventoryCategory.name ? 'btn-success' : 'btn-light'" (click)="getJobInventoryItems(inventoryCategory)">{{ inventoryCategory.name }}</button>
        </div>

        <div class="wrapper-inventory-items">

            <div class="additional-inventory-item mt-4" *ngFor="let item of jobInventoryItems">
                <div class="col-12 check-marks" *ngIf="item.scaning_items.length > 0">
                    <div class="row">
                        <div class="col">
                            <!-- Empty -->
                        </div>
                        <div class="col check-marks-height">
                            <div class="marks">P</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'P'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                                
                            </ng-container>
                            
                            <!-- <i class="fa-solid fa-circle-check" [ngClass]="{
                                'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status',
                                'orange':   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'blue'  :   marks.check == false,
                                'red'   :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'purple':   marks.check == false,
                                'gray'  :   marks.check == false
                            }" ></i> -->
                            
                        </div>
                        <div class="col">
                            <div class="marks">IW</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'IW'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col">
                            <div class="marks">OW</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'OW'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col">
                            <div class="marks">D</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'D'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col">
                            <!-- Empty -->
                        </div>
                    </div>
                </div>
                <div class="col-12 check-marks" *ngIf="item.scaning_items.length == 0">
                    <div class="row">
                        <div class="col">
                            <!-- Empty -->
                        </div>
                        <div class="col check-marks-height">
                            <div class="marks">P</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                            
                            <!-- <i class="fa-solid fa-circle-check" [ngClass]="{
                                'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status',
                                'orange':   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'blue'  :   marks.check == false,
                                'red'   :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'purple':   marks.check == false,
                                'gray'  :   marks.check == false
                            }" ></i> -->
                            
                        </div>
                        <div class="col">
                            <div class="marks">IW</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                        </div>
                        <div class="col">
                            <div class="marks">OW</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                        </div>
                        <div class="col">
                            <div class="marks">D</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                        </div>
                        <div class="col">
                            <!-- Empty -->
                        </div>
                    </div>
                </div>

                <div class="card component-card_9">                    

                    <a *ngIf="item.images.length > 0" (click)="openModalPreviewImages(item.images)">
                        <img src="{{imgItem}}{{item.images[item.images.length - 1].display_name}}" width="350" height="200" style="object-fit: cover;">
                    </a>
                    <img *ngIf="item.images.length == 0" src="assets/img/box.png" width="350" height="200" style="object-fit: contain;">
                    <div class="card-body info-additional-items">
                        <h5 class="card-title">{{item.name}}</h5>
                        <p>
                            <span class="key">Cubic Feet:</span>
                            <span class="inventory-item-text">{{ item.cubic_feets }}</span>
                        </p>   
                        <p>
                            <span class="key">Packed By:</span>
                            <span class="inventory-item-text" *ngIf="item.packed_by ==='CARRIER'">CP</span>
                            <span class="inventory-item-text" *ngIf="item.packed_by !=='CARRIER'">PBO</span>
                        </p>
                        <p>
                            <span class="key">Weight:</span>
                            <span class="inventory-item-text">{{ item.weight }} </span><br>
                        </p>
                        <p>
                            <span class="key">Value:</span>
                            <span class="inventory-item-text">{{ item.value }} </span><br>
                        </p>
                        <p>
                            <span class="key">Pads:</span>
                            <span class="inventory-item-text">{{ item.pads }} </span><br>
                        </p>
                        <p>
                            <span class="key">Is Box:</span>
                            <span class="inventory-item-text">{{ item.is_box }} </span><br>
                        </p>
                        <p>
                            <span class="key">Disassembled:</span>
                            <span class="inventory-item-text">{{ item.disassembled }} </span><br>
                        </p>
                        <p>
                            <span class="key">Comments:</span>
                            <span class="inventory-item-text">{{ item.comments }} </span>
                        </p>
                        <p>
                            <span class="key">Special Handling:</span>
                            <span class="inventory-item-text">{{ item.special_handling }} </span><br>
                        </p>
                        
                        <div class="">                        
                            <div class="added-by-employee">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                {{item.added_by_employee | titlecase}}
                            </div>
                            <div class="measurements-info">
                                <div class="cf">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                    <span>{{ item.cubic_feets }} CF</span>
                                </div>                            
                                <div class="weight">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                    <span>{{ item.weight }} Weight</span>
                                </div>                            
                            </div>
                        </div>                                            

                        <div class="btn-actions-additional-items">
                            <button class="btn btn-success" (click)="editAdditionalItem(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-edit-3">
                                    <path d="M12 20h9"></path>
                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                </svg>
                                <span class="pl-2">Edit</span>                                
                            </button>
                            <button class="btn btn-danger" (click)="deleteAdditionalItem(item.id, item.job_item_qr_code)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-trash-2">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                    </path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg> 
                                <span class="pl-2">Delete</span>                             
                            </button>
                        </div>

                        <div class="btn-actions-additional-items btn-add-qr mt-3" *ngIf="!item.job_item_qr_code">
                            <button class="btn btn-new" (click)="openSelectQr(true, item.id)">
                                <i class="fa-solid fa-qrcode"></i>
                                <span class="pl-2">Add QR</span>
                            </button>
                            <!-- <div _ngcontent-sku-c163="" class="meta-view btn btn-delete" (click)="deleteQrAdditionalItem(item)">
                                <svg _ngcontent-sku-c163="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                    <path _ngcontent-sku-c163="" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline _ngcontent-sku-c163="" points="7 10 12 15 17 10"></polyline>
                                    <line _ngcontent-sku-c163="" x1="12" y1="15" x2="12" y2="3"></line>
                                </svg> 
                                Delete QR
                            </div> -->
                        </div>

                        <div class="qr-label">
                            <ng-container *ngFor="let qr of allQr">
                                <ng-container *ngIf="qr.code == item.job_item_qr_code">
                                    <app-qr-labels                                
                                        [selectionBehavior]="true"
                                        [infoEstimateLabelQr]="infoEstimateLabelQr" 
                                        [itemQr]="qr">
                                    </app-qr-labels>
                                </ng-container>
                            </ng-container>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>
</div>

<!-- Items Summary -->
<div class="bio layout-spacing ">
    <div class="widget-content widget-content-area">

        <div class="d-flex justify-content-between widget-header">
            <h3 class="mt-2">
                Items Summary
            </h3>
            <a class="btn btn-new" (click)="openSearchOperatingItems(false)">
                <i class="fas fa-plus-square"></i> &nbsp; Add Items Summary
            </a>
        </div>
        
        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn" [ngClass]="filterJobInventorSummaryItems == 'all' ? 'btn-success' : 'btn-light'" (click)="loadJobInventorySummaryItems(jobId)">All</button>
            <button type="button" class="btn" *ngFor="let inventoryCategory of curentInventoryCategoriesSummaryItems" [ngClass]="filterJobInventorSummaryItems == inventoryCategory.name ? 'btn-success' : 'btn-light'" (click)="getJobInventorySummaryItems(inventoryCategory)">{{ inventoryCategory.name }}</button>
        </div>
        <div class="wrapper-inventory-items">

            <div class="additional-inventory-item mt-4" *ngFor="let item of jobInventorySummaryItems">
                <div class="col-12 check-marks" *ngIf="item.scaning_items.length > 0">
                    <div class="row">
                        <div class="col">
                            <!-- Empty -->
                        </div>
                        <div class="col check-marks-height">
                            <div class="marks">P</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'P'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                                
                            </ng-container>
                            
                            <!-- <i class="fa-solid fa-circle-check" [ngClass]="{
                                'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status',
                                'orange':   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'blue'  :   marks.check == false,
                                'red'   :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'purple':   marks.check == false,
                                'gray'  :   marks.check == false
                            }" ></i> -->
                            
                        </div>
                        <div class="col">
                            <div class="marks">IW</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'IW'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col">
                            <div class="marks">OW</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'OW'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col">
                            <div class="marks">D</div>
                            <i class="fa-solid fa-circle-check gray" ></i>
                            <ng-container *ngFor="let scaningIitems of item.scaning_items">
                                <ng-container *ngIf="scaningIitems.inventoy_scaning.code == 'D'">
                                    <i class="fa-solid fa-circle-check" [ngClass]="{
                                        'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.status,
                                        'orange':   scaningIitems.inventoy_scaning.scaned_date == null && scaningIitems.status,
                                        'red'   :   scaningIitems.inventoy_scaning.scaned_date == null && !scaningIitems.status
                                    }" ></i>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col">
                            <!-- Empty -->
                        </div>
                    </div>
                </div>
                <div class="col-12 check-marks" *ngIf="item.scaning_items.length == 0">
                    <div class="row">
                        <div class="col">
                            <!-- Empty -->
                        </div>
                        <div class="col check-marks-height">
                            <div class="marks">P</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                            
                            <!-- <i class="fa-solid fa-circle-check" [ngClass]="{
                                'green' :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status',
                                'orange':   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'blue'  :   marks.check == false,
                                'red'   :   scaningIitems.inventoy_scaning.scaned_date && scaningIitems.inventoy_scaning.status,
                                'purple':   marks.check == false,
                                'gray'  :   marks.check == false
                            }" ></i> -->
                            
                        </div>
                        <div class="col">
                            <div class="marks">IW</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                        </div>
                        <div class="col">
                            <div class="marks">OW</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                        </div>
                        <div class="col">
                            <div class="marks">D</div>
                            <i class="fa-solid fa-circle-check gray"></i>
                        </div>
                        <div class="col">
                            <!-- Empty -->
                        </div>
                    </div>
                </div>

                <div class="card component-card_9">                    

                    <a *ngIf="item.images.length > 0" (click)="openModalPreviewImages(item.images)">
                        <img src="{{imgItem}}{{item.images[item.images.length - 1].display_name}}" width="350" height="200" style="object-fit: cover;">
                    </a>
                    <img *ngIf="item.images.length == 0" src="assets/img/box.png" width="350" height="200" style="object-fit: contain;">
                    <div class="card-body info-additional-items">
                        <h5 class="card-title">{{item.name}}</h5>
                        <p>
                            <span class="key">Cubic Feet:</span>
                            <span class="inventory-item-text">{{ item.cubic_feets }}</span>
                        </p>   
                        <p>
                            <span class="key">Packed By:</span>
                            <span class="inventory-item-text" *ngIf="item.packed_by ==='CARRIER'">CP</span>
                            <span class="inventory-item-text" *ngIf="item.packed_by !=='CARRIER'">PBO</span>
                        </p>
                        <p>
                            <span class="key">Weight:</span>
                            <span class="inventory-item-text">{{ item.weight }} </span><br>
                        </p>
                        <p>
                            <span class="key">Value:</span>
                            <span class="inventory-item-text">{{ item.value }} </span><br>
                        </p>
                        <p>
                            <span class="key">Pads:</span>
                            <span class="inventory-item-text">{{ item.pads }} </span><br>
                        </p>
                        <p>
                            <span class="key">Is Box:</span>
                            <span class="inventory-item-text">{{ item.is_box }} </span><br>
                        </p>
                        <p>
                            <span class="key">Disassembled:</span>
                            <span class="inventory-item-text">{{ item.disassembled }} </span><br>
                        </p>
                        <p>
                            <span class="key">Comments:</span>
                            <span class="inventory-item-text">{{ item.comments }} </span>
                        </p>
                        <p>
                            <span class="key">Special Handling:</span>
                            <span class="inventory-item-text">{{ item.special_handling }} </span><br>
                        </p>
                        
                        <div class="">                        
                            <div class="added-by-employee">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                {{item.added_by_employee | titlecase}}
                            </div>
                            <div class="measurements-info">
                                <div class="cf">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                    <span>{{ item.cubic_feets }} CF</span>
                                </div>                            
                                <div class="weight">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                    <span>{{ item.weight }} Weight</span>
                                </div>                            
                            </div>
                        </div>                                            

                        <div class="btn-actions-additional-items">
                            <button class="btn btn-success" (click)="editAdditionalItem(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-edit-3">
                                    <path d="M12 20h9"></path>
                                    <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                </svg>
                                <span class="pl-2">Edit</span>                                
                            </button>
                            <button class="btn btn-danger" (click)="deleteAdditionalItem(item.id, item.job_item_qr_code)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-trash-2">
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path
                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                    </path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg> 
                                <span class="pl-2">Delete</span>                             
                            </button>
                        </div>

                        <div class="btn-actions-additional-items btn-add-qr mt-3" *ngIf="!item.job_item_qr_code">
                            <button class="btn btn-new" (click)="openSelectQr(true, item.id)">
                                <i class="fa-solid fa-qrcode"></i>
                                <span class="pl-2">Add QR</span>
                            </button>
                            <!-- <div _ngcontent-sku-c163="" class="meta-view btn btn-delete" (click)="deleteQrAdditionalItem(item)">
                                <svg _ngcontent-sku-c163="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                    <path _ngcontent-sku-c163="" d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline _ngcontent-sku-c163="" points="7 10 12 15 17 10"></polyline>
                                    <line _ngcontent-sku-c163="" x1="12" y1="15" x2="12" y2="3"></line>
                                </svg> 
                                Delete QR
                            </div> -->
                        </div>

                        <div class="qr-label">
                            <ng-container *ngFor="let qr of allQr">
                                <ng-container *ngIf="qr.code == item.job_item_qr_code">
                                    <app-qr-labels                                
                                        [selectionBehavior]="true"
                                        [infoEstimateLabelQr]="infoEstimateLabelQr" 
                                        [itemQr]="qr">
                                    </app-qr-labels>
                                </ng-container>
                            </ng-container>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    </div>
</div>

<!-- Removed Items -->
<div class="bio ">
    <div class="widget-content widget-content-area">

        <div class="d-flex justify-content-between widget-header">
            <h3>
                Removed Items
            </h3>           
        </div>
        
        <div class="btn-group" role="group" aria-label="Basic example">
            
        </div>

        <div class="wrapper-inventory-items">
            <div class="card component-card_9" *ngFor="let item of jobInventoryRemovedItems">
                <a *ngIf="item.images.length > 0" (click)="openModalPreviewImages(item.images)">
                    <img src="{{imgItem}}{{item.images[item.images.length - 1].display_name}}" width="350" height="200" style="object-fit: cover;">
                </a>
                <img *ngIf="item.images.length == 0" src="assets/img/box.png" width="350" height="200" style="object-fit: contain;">
                <div class="card-body info-additional-items">
                    <h5 class="card-title">{{item.name}}</h5>
                    <p>
                        <span class="key">Cubic Feet:</span>
                         <span class="inventory-item-text">{{ item.cubic_feets }}</span>
                    </p>   
                    <p>
                        <span class="key">Packed By:</span>
                        <span class="inventory-item-text" *ngIf="item.packed_by ==='CARRIER'">CP</span>
                        <span class="inventory-item-text" *ngIf="item.packed_by !=='CARRIER'">PBO</span>

                    </p>
                    <p>
                        <span class="key">Weight:</span>
                        <span class="inventory-item-text">{{ item.weight }} </span><br>
                    </p>
                    <p>
                        <span class="key">Value:</span>
                        <span class="inventory-item-text">{{ item.value }} </span><br>
                    </p>
                    <p>
                        <span class="key">Pads:</span>
                        <span class="inventory-item-text">{{ item.pads }} </span><br>
                    </p>
                    <p>
                        <span class="key">Is Box:</span>
                        <span class="inventory-item-text">{{ item.is_box }} </span><br>
                    </p>
                    <p>
                        <span class="key">Disassembled:</span>
                        <span class="inventory-item-text">{{ item.disassembled }} </span><br>
                    </p>
                    <p>
                        <span class="key">Comments:</span>
                        <span class="inventory-item-text">{{ item.comments }} </span>
                    </p>
                    <p>
                        <span class="key">Special Handling:</span>
                        <span class="inventory-item-text">{{ item.special_handling }} </span><br>
                    </p>
    
                    <div class="">                      
                        <div class="added-by-employee">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            {{item.added_by_employee | titlecase}}
                        </div>
                        <div class="measurements-info">
                            <div class="cf">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                <span>{{ item.cubic_feets }} CF</span>
                            </div>                            
                            <div class="weight">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                <span>{{ item.weight }} Weight</span>
                            </div>                            
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>

    </div>
</div>

<!-- Modal modalImagesPreview -->
<div class="modal fade" #modalImagesPreview tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-center" style="width: 100%;">
                    <strong>
                        Item Images
                    </strong>
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                    <svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>

            <div class="modal-body">

                <div class="form-group">
                    <a href="{{imgItem}}{{previewImage.display_name}}" target="_blank" *ngFor="let previewImage of previewImages">
                        <img class="card-img-top" src="{{imgItem}}{{previewImage.display_name}}" height="200" style="object-fit: contain;">
                    </a>
                </div>
            </div>

            <div class="modal-footer pt-3">
                <div class="text-center" style="width: 100%; display: inline-block;">
                    <button class="btn" data-dismiss="modal">
                        <i class="flaticon-cancel-12"></i>
                        Ok
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal modalImagesPreview -->

<!-- Modal OperatingStatusHistory -->
<div class="modal fade" #modalOperatingStatusHistory tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md max-width-modal-dialog" role="document">
        <div class="modal-content">
                    
            <form class="form" #operatingStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong>
                            Updates the Operating Status
                        </strong>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">

                    <div class="form-group">
                        <label class="control-label">Operation Status</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line></svg>
                                </span>
                            </div>
                            <select [(ngModel)]="jobStatus.operation" name="jobStatusoperation" class="form-control">
                                <option value="DELIVERED_BOXES">Delivered Boxes</option>
                                <option value="PACKED_MOVE">Packed Move</option>
                                <option value="PICKED_UP">Picked Up</option>
                                <option value="IN_STORAGE">In Storage</option>
                                <option value="IN_TRANSIT">In Transit</option>
                                <option value="DELIVERED">Delivered</option>
                                <option value="CLOSING_REVIEW">Closing Review</option>
                            </select>
                        </div>
                        <small  class="form-text text-muted mb-4">
                            Select the current status of the work with the dispatch team.
                        </small>
                    </div>

                    <div class="form-group ">
                        <label class="control-label">Comments</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                </span>
                            </div>
                            <textarea name="comment" class="form-control" [(ngModel)]="jobOperatingStatus.comment" rows="5"></textarea>
                        </div>
                        <small  class="form-text text-muted mb-4">
                            Please... if you have any news about this new status, please indicate such news in this field.
                        </small>
                    </div>

                </div>

                <div class="modal-footer pt-3">
                    <div class="d-flex text-right">
                        <button class="btn btn-cancel" data-dismiss="modal">
                            <i class="flaticon-cancel-12"></i>
                            Cancel
                        </button>
                        <button (click)="saveOperatingStatus(true)" data-dismiss="modal" type="button" class="btn btn-save">
                            Save
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal OperatingStatusHistory -->

<!-- Modal EditStatus -->
<div class="modal fade" id="modalEditStatus" #modalEditStatus tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md max-width-modal-dialog" role="document">
        <div class="modal-content">

                    
            <form class="form" #generalStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong>
                            Updates the Status
                        </strong>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">

                    <div class="form-group">
                        <label class="control-label">General Status</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line></svg>
                                </span>
                            </div>
                            <select [(ngModel)]="job.statuses.general" name="jobStatusesGeneral" class="form-control">
                                <option value="WORK_CARRIED_OUT">Work Carried Out</option>
                                <option value="CARRIER_PAPERWORK_ON">Carrier Paperwork On</option>
                                <option value="WORKING_ON">Working On</option>
                            </select>
                        </div>
                        <small  class="form-text text-muted mb-4">
                            Updates the general status of the job, this does not mean that it is the same as the operating status, this refers to administrative terms
                        </small>
                    </div>

                    <div class="form-group">
                        <label class="control-label">Picked Docs</label>
                        <input type="checkbox" name="picked_docs" class="margin-checkbox" [(ngModel)]="job.statuses.picked_docs">
                        <small  class="form-text text-muted mb-4">
                            Indicate if the pick up docs are uploaded
                        </small>
                    </div>
                    
                    <div class="form-group">
                        <label class="control-label">Delivered Docs</label>
                        <input type="checkbox" name="delivered_docs" class="margin-checkbox" [(ngModel)]="job.statuses.delivered_docs">
                        <small  class="form-text text-muted mb-4">
                            Indicate if the delivery docs are uploaded
                        </small>
                    </div>

                </div>

                <div class="modal-footer pt-3">
                    <div class="d-flex text-right">
                        <button class="btn btn-cancel" data-dismiss="modal">
                            Cancel
                        </button>
                        <button (click)="saveOperatingStatus(false)" data-dismiss="modal" type="button" class="btn btn-save">
                            Save
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal EditStatus -->


<!-- Modal new notes -->
<div class="modal fade" #modalNotes tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md max-width-modal-dialog" role="document">
        <div class="modal-content">

            <form class="form" #generalStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong>
                            Add a New Note
                        </strong>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    
                    <div class="form-group ">
                        <label class="control-label">Note</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                </span>
                            </div>
                            <textarea name="comment" class="form-control" [(ngModel)]="jobNote.remarks" rows="5"  ></textarea>
                        </div>
                        <small  class="form-text text-muted mb-4">
                            If you have any news to report about this work, you can do it by writing this news here
                        </small>
                    </div>
                

                </div>

                <div class="modal-footer pt-3">
                    <div class="d-flex text-right">
                        <button class="btn" data-dismiss="modal">
                            Cancel
                        </button>
                        <button (click)="saveNotes()" data-dismiss="modal" type="button" class="btn btn-save">
                            Save
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal EditStatus -->

<!-- Modal jobAppoinment -->
<div class="modal fade" #modalEditDay tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">

            <form class="form" #jobAppointmentForm="ngForm" ngNativeValidate>

                <div class="modal-header">

                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong>
                            Update Appointment
                        </strong>
                    </h4>
                    
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">

                    
                    <div class="row">
                        <div class="col-md-6">
                            
                            <div class="form-group">
                                <label class="control-label">is it Confirmed:?</label> &nbsp;&nbsp;
                                <input type="checkbox" name="appoinment_confirmed" class="margin-checkbox" [(ngModel)]="editDays.confirm">
                                <small  class="form-text text-muted mb-4">
                                    if the job is already confirmed with the customer to be done, please check the box
                                </small>
                            </div>

                        </div>    
                        <div class="col-md-6">

                            <div class="form-group">
                                <label class="control-label">is it dispatched:?</label> &nbsp;&nbsp;
                                <input type="checkbox" name="appoinment_dispatched" class="margin-checkbox" [(ngModel)]="editDays.dispatched">
                                <small  class="form-text text-muted mb-4">
                                    if the crew has already been dispatched to the job site, please check the box
                                </small>
                            </div>

                        </div>    
                    </div>

                    <hr>

                    <div class="row">
                        <div class="col-md-6">
                            
                            <div class="form-group">
                                <label class="control-label">Travel Days:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sun"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>    
                                        </span>
                                    </div>
                                    <input type="number" name="appoinment_travel_days"  class="form-control" [(ngModel)]="editDays.travel_days" />
                                </div>
                                <small  class="form-text text-muted mb-4">
                                    indicate the number of days spent doing the work here
                                </small>
                            </div>
        
                            <div class="form-group">
                                <label class="control-label">Pay Hours:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg>
                                        </span>
                                    </div>
                                    <input type="number" name="appointment_pay_hours"  class="form-control" [(ngModel)]="editDays.pay_hours" />
                                </div>
                                <small  class="form-text text-muted mb-4">
                                    indicates the number of hours spent doing the work, this is to be kept in mind at the time of payment
                                </small>
                            </div>

                        </div>    
                        <div class="col-md-6">

                            <div class="form-group">
                                <label class="control-label">Total cf:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
                                        </span>
                                    </div>
                                    <input type="number" name="appointment_total_cf"  class="form-control" [(ngModel)]="editDays.total_cf" />
                                </div>
                                <small  class="form-text text-muted mb-4">
                                    indicate the total number of cubic feet worked here
                                </small>
                            </div>
        
                            <div class="form-group">
                                <label class="control-label">Total lbs:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                                        </span>
                                    </div>
                                    <input type="number" name="appointment_total_lbs"  class="form-control"  [(ngModel)]="editDays.total_lbs" />
                                </div>
                                <small  class="form-text text-muted mb-4">
                                    indicates the total pounds worked
                                </small>
                            </div>

                        </div>    
                    </div>
                
                    <hr>
                    
                    <div class="form-group ">
                        <label class="control-label">Comments:</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                </span>
                            </div>
                            <textarea name="appointiment_comments" class="form-control" [(ngModel)]="editDays.remarks" rows="3" ></textarea>
                        </div>
                        <small  class="form-text text-muted mb-4">
                            if you have any comments on the performance of the work, please indicate them here....
                        </small>
                    </div>
                

                </div>

                <div class="modal-footer pt-3">
                    <div class="text-center" style="width: 100%;">
                        <button class="btn" data-dismiss="modal">
                            <i class="flaticon-cancel-12"></i>
                            Cancel
                        </button>
                        <button (click)="saveDay()" data-dismiss="modal" type="button" class="btn btn-danger">
                            Save
                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>
<!-- fin Modal EditStatus -->

<!-- Modal modalSearchOperatingItems -->
<div class="modal fade" #modalSearchOperatingItems tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md max-width-modal-dialog" role="document">
        <div class="modal-content">
                    
            <form class="form" #operatingStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h5 class="modal-title">
                        <strong *ngIf="showModalJobInventoryItems">
                            Add Additional Items to Inventory
                        </strong>
                        <strong *ngIf="showModalJobInventorySummaryItems">
                            Add Inventory Items
                        </strong>
                    </h5>
                </div>

                <div class="modal-body">
                    <div class="form-group">
                        <label class="control-label">Search Label</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <i class="fa-solid fa-qrcode"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" name="label_qr" placeholder="Type the Code Label Here" [(ngModel)]="jobItemQrByCode.code">
                        </div>
                        <br>
                        <div class="labels-container" *ngIf="itemsQrNotAssigned.length > 0">
                            <ng-container *ngFor="let item of itemsQrNotAssigned | search : jobItemQrByCode.code | async  ">
                                <app-qr-labels
                                    (click)="addQrAdditionalItem(item)"
                                    [selectionBehavior]="true"
                                    [infoEstimateLabelQr]="infoEstimateLabelQr" 
                                    [itemQr]="item"
                                    ></app-qr-labels>
                            </ng-container>
                        </div>
                        <p *ngIf="itemsQrNotAssigned.length == 0">
                            There are no labels available, You can generate labels on the label tab
                        </p>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" (click)="dataDismiss()">
                        Cancel
                    </button>
                    <button (click)="searchJobItemQrByCode()" data-dismiss="modal" type="button" class="btn btn-new">
                        Search
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal modalSearchOperatingItems -->

<!-- Modal modalJobInventoryItems -->
<div class="modal fade" id="modalJobInventoryItems" #modalJobInventoryItems role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">
                    
            <form class="form" #operatingStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h5 class="modal-title">
                        Add Additional Items
                    </h5>
                </div>

                <div class="modal-body">

                    <!-- Inventory --> 
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">

                            <div class="form-group select2-add-items-summary" *ngIf="!customInventoryItem">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <select [appSelect2] name="globalInventoryItems" id="globalInventoryItems" [(ngModel)]="jobInventoryItem.name" class="form-control" (change)="listenEventInventoryItem(jobInventoryItem.name)" style="width: 100%">
                                        <option [value]="null">
                                            SELECT
                                        </option>
                                        <ng-container *ngFor="let item of inventoryItems ">
                                            <option [value]="item.name">
                                                {{item.name}}
                                            </option>
                                        </ng-container>
                                        <option [value]="'CUSTOM'">
                                            CUSTOM
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select the Name of Additional Item.</small>
                            </div>

                            <div class="form-group" *ngIf="customInventoryItem">
                                <label class="control-label">{{ jobInventoryItem.name }}</label> 
                                <button class="btn btn-secondary btn-database" (click)="dataBase()">
                                    <i class="fa-solid fa-database pr-1"></i>
                                    Inventory
                                </button>
                            </div>                            

                        </div>
                                     
                        <div class="col-md-6 col-sm-6 col-12">
                    
                            <div class="form-group select2-add-items-summary">
                                <ng-container *ngFor="let inventoryItem of inventoryItems">
                                    <ng-container *ngIf="inventoryItem.name == jobInventoryItem.name">
                                        <ng-container *ngFor="let inventoryCategory of inventoryCategories">
                                            <ng-container *ngIf="inventoryCategory.id == inventoryItem.inventory_category_id">
                                                <label class="control-label">Inventory Category</label>
                                                <div class="input-group mb-1">
                                                    <input type="text" [value]="inventoryCategory.name" class="form-control" disabled style="background-color: #ffffff!important;"> 
                                                </div>
                                                <small id="emailHelp1" class="form-text text-muted mb-4">Inventory Category of Additional Item.</small>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                            
                        </div>

                    </div>

                    <!-- Cubic Feets -->
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">

                            <div class="form-group">
                                <label class="control-label">Cubic Feets</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="cubic_feets" placeholder="Cubic Feets" [(ngModel)]="jobInventoryItem.cubic_feets">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Cubic Feets of Additional Item.</small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Value</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="value" placeholder="Value" [(ngModel)]="jobInventoryItem.value">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Value of Additional Item.</small>
                            </div>

                        </div>

                        <div class="col-md-6 col-sm-6 col-12">

                            <div class="form-group">
                                <label class="control-label">Weight</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="weight" placeholder="Weight" [(ngModel)]="jobInventoryItem.weight">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Weight of Additional Item.</small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Pads</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="pads" placeholder="Pads" [(ngModel)]="jobInventoryItem.pads">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Pads of Additional Item.</small>
                            </div>

                        </div>

                    </div>
                                     
                    <!-- Checkbox -->
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">
                    
                            <div class="form-group">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="is_box" name="is_box" [(ngModel)]="jobInventoryItem.is_box">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="is_box" class="checkbox-label" [ngClass]="{'filter-disabled': !jobInventoryItem.is_box}">
                                        Is Box
                                    </label>
                                </div>
                            </div>
                    
                            <div class="form-group">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="disassembled" name="disassembled" [(ngModel)]="jobInventoryItem.disassembled">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="disassembled" class="checkbox-label" [ngClass]="{'filter-disabled': !jobInventoryItem.disassembled}">
                                        Is Disassembled
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                    
                    <!-- Comments -->
                    <div class="row">
                        
                        <div class="col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="control-label">Comments</label>
                                <textarea class="form-control" name="comments" [(ngModel)]="jobInventoryItem.comments"></textarea>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Comments of Additional Item.</small>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="control-label">Special Handling</label>
                                <textarea class="form-control" name="special_handling" [(ngModel)]="jobInventoryItem.special_handling"></textarea>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Special Handling of Additional Item.</small>
                            </div>
                        </div>

                    </div>

                    <!-- Upload an Images -->  
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-12">
                            <a (click)="openUploadModalItemsFiles()" class="option-widget" >
                                <button class="btn btn-success btn-upload-images">    
                                    <i class="fa-solid fa-image"></i>                      
                                    Upload an Images      
                                </button>
                            </a>

                            <br/>
                            <br/>
                            
                            <div class="row">
                                <ng-container *ngFor="let previewImg of jobInventoryItem.images">
                                    <div class="col-sm-2 text-center">
                                        <div class="delete-button-file" (click)="deleteImageInventoryItem(previewImg)">
                                            x
                                        </div>
                                        <img height="50px" *ngIf="previewImg.display_name" src="{{urlBase}}/tmp/{{previewImg.display_name}}"/>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>                        
                    
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="saveAdditionalItems()" type="button" class="btn btn-save">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal modalJobInventoryItems -->

<!-- Modal modalJobInventorySummaryItems -->
<div class="modal fade" id="modalJobInventorySummaryItems" #modalJobInventorySummaryItems role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">
                    
            <form class="form" #operatingStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h5 class="modal-title text-center">
                        Add Items Summary
                    </h5>
                </div>

                <div class="modal-body">

                    <!-- Inventory --> 
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">

                            <div class="form-group select2-add-items-summary" *ngIf="!customInventoryItem">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <select [appSelect2] name="itemSummary" id="itemSummary" [(ngModel)]="jobInventoryItem.name" class="form-control" (change)="listenEventInventoryItem(jobInventoryItem.name)"  style="width: 100%">
                                        <option [value]="null">
                                            SELECT
                                        </option>
                                        <ng-container *ngFor="let item of movingInventoryItems">
                                            <option [value]="item.name">
                                                {{item.name}}
                                            </option>
                                        </ng-container>
                                        <option [value]="'CUSTOM'">
                                            CUSTOM
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select the Name of Additional Item.</small>
                            </div>                           
                    
                            <div class="form-group" *ngIf="customInventoryItem">
                                <label class="control-label">{{ jobInventoryItem.name }}</label>
                                <button class="btn btn-secondary btn-database" (click)="dataBase()">
                                    <i class="fa-solid fa-database pr-1"></i>
                                    Inventory
                                </button>
                            </div>

                        </div>

                        <div class="col-md-6 col-sm-6 col-12">

                            <div class="form-group select2-add-items-summary">
                                <ng-container *ngFor="let inventoryItem of inventoryItems">
                                    <ng-container *ngIf="inventoryItem.name == jobInventoryItem.name">
                                        <ng-container *ngFor="let inventoryCategory of inventoryCategories  | usedInventoryCategories : movingInventoryItems">
                                            <ng-container *ngIf="inventoryCategory.id == inventoryItem.inventory_category_id">
                                                <label class="control-label">Inventory Category</label>
                                                <div class="input-group mb-1">
                                                    <input type="text" [value]="inventoryCategory.name" class="form-control" disabled style="background-color: white!important;"> 
                                                </div>
                                                <small id="emailHelp1" class="form-text text-muted mb-4">Inventory Category of Additional Item.</small>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>

                        </div>
                        
                    </div>

                    <!-- Cubic Feets -->
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">
                
                            <div class="form-group">
                                <label class="control-label">Cubic Feets</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="cubic_feets" placeholder="Cubic Feets" [(ngModel)]="jobInventoryItem.cubic_feets">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Cubic Feets of Additional Item.</small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Value</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="value" placeholder="Value" [(ngModel)]="jobInventoryItem.value">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Value of Additional Item.</small>
                            </div>

                        </div>

                        <div class="col-md-6 col-sm-6 col-12">

                            <div class="form-group">
                                <label class="control-label">Weight</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="weight" placeholder="Weight" [(ngModel)]="jobInventoryItem.weight">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Weight of Additional Item.</small>
                            </div>
                            
                            <div class="form-group">
                                <label class="control-label">Pads</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="pads" placeholder="Pads" [(ngModel)]="jobInventoryItem.pads">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Pads of Additional Item.</small>
                            </div>

                        </div>

                    </div>

                    <!-- Checkbox -->
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">
                            
                            <div class="form-group">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="is_box" name="is_box" [(ngModel)]="jobInventoryItem.is_box">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="is_box" class="checkbox-label" [ngClass]="{'filter-disabled': !jobInventoryItem.is_box}">
                                        Is Box
                                    </label>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="disassembled" name="disassembled" [(ngModel)]="jobInventoryItem.disassembled">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="disassembled" class="checkbox-label" [ngClass]="{'filter-disabled': !jobInventoryItem.disassembled}">
                                        Is Disassembled
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>
                
                    <!-- Comments -->
                    <div class="row">

                        <div class="col-md-6 col-sm-6 col-12">        
                            <div class="form-group">
                                <label class="control-label">Comments</label>
                                <textarea class="form-control" name="comments" [(ngModel)]="jobInventoryItem.comments"></textarea>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Comments of Additional Item.</small>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-12"> 
                            <div class="form-group">
                                <label class="control-label">Special Handling</label>
                                <textarea class="form-control" name="special_handling" [(ngModel)]="jobInventoryItem.special_handling"></textarea>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter the Special Handling of Additional Item.</small>
                            </div>
                        </div>

                    </div>

                    <!-- Upload an Images -->  
                    <div class="row">

                        <div class="col-md-12 col-sm-12 col-12">

                            <a (click)="openUploadModalItemsFiles()" class="option-widget" >
                                <button class="btn btn-success btn-upload-images">    
                                    <i class="fa-solid fa-image"></i>                      
                                    Upload an Images      
                                </button>
                            </a>

                            <br/>
                            <br/>
                            
                            <div class="row">
                                <div class="form-group">
                                    <ng-container *ngFor="let previewImg of jobInventoryItem.images">
                                        <div class="col-sm-2 text-center">
                                            <div class="delete-button-file" (click)="deleteImageInventoryItem(previewImg)">
                                                x
                                            </div>
                                            <img height="50px" *ngIf="previewImg.display_name" src="{{urlBase}}/tmp/{{previewImg.display_name}}"/>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>

                    </div> 

                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="saveAdditionalSummaryItems()" type="button" class="btn btn-save">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal modalJobInventorySummaryItems -->

<!-- Modal new notes -->
<div class="modal fade" #modalArchive tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md max-width-modal-dialog" role="document">
        <div class="modal-content">

            <form class="form" #generalStatusForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong>
                            {{ titleArchiveDocuments }}
                        </strong>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    
                    <div class="form-group ">
                        <label class="control-label">Note:</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                </span>
                            </div>
                            <textarea name="comment" class="form-control" [(ngModel)]="jobStatusesComment" rows="5"  ></textarea>
                        </div>
                        <small  class="form-text text-muted mb-4">
                            if you have any news to report about this work, you can do it by writing this news here
                        </small>
                    </div>
                

                </div>

                <div class="modal-footer pt-3">
                    <div class="text-center" style="width: 100%;">
                        <button class="btn" data-dismiss="modal">
                            <i class="flaticon-cancel-12"></i>
                            Cancel
                        </button>
                        <button (click)="saveArchive()" data-dismiss="modal" type="button" class="btn btn-danger">
                            Save
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<!-- fin Modal EditStatus -->
<div class="modal fade size-modal" id="modalMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog size-modal max-width-modal-dialog" role="document">
        <div class="modal-content size-modal">

            <div class="modal-header header-map">
                <h5 class="modal-title" id="exampleModalLabel">Route Delivery</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body header-map">
                <div id="map" #mapView class="map"></div>
            </div>

        </div>
    </div>
</div>
<!-- Modal form estimate stop points -->
<div class="modal fade" id="stopPointModal" #stopPointModal tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add Stop Point
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content" id="addListsModalTitle">
                        <div class="row">

                            <div class="col-12">
                                <div class="form-group mb-4">
                                    <label class="control-label">Type:</label>
                                    <select (onChange)="validateEnableButtonSaveStopPoint()"
                                        name="estimateStopPoint.type" class="form-control"
                                        [(ngModel)]="estimateStopPoint.type">
                                        <option value="PICKUP">
                                            Pickup
                                        </option>
                                        <option value="DELIVERY">
                                            Delivery
                                        </option>
                                    </select>
                                </div>

                                <div class="form-group mb-4">
                                    <app-search-address (fullAddress)="estimateStopPoint.address.full_address"
                                        [(ngModel)]="estimateStopPoint.address.full_address"
                                        [isNotAutoTransport]="job.estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                                        [oldAddress]="estimateStopPoint.address" (onChange)="addAddressStopPoint($event)"></app-search-address>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeStopPointModal()" class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button [disabled]="disableButtonSaveStopPoint" data-dismiss="modal" class="btn btn-save"
                    (click)="saveStopPoint()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Modal moving-->
<div #movingModal class="modal fade" id="moving" tabindex="-1" role="dialog" aria-labelledby="movingTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="movingTitle">Moving</h5>
            </div>
            <div class="modal-body row">
                <div class="col-md-6">
                    <h4>
                        From Address
                    </h4>
                    <app-search-address (fullAddress)="pickupToEdit.address.full_address"
                        [(ngModel)]="pickupToEdit.address.full_address"
                        [isNotAutoTransport]="job.estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                        [oldAddress]="pickupToEdit.address" (onChange)="setFrom($event)"></app-search-address>
                </div>
                <div class="col-md-6">
                    <h4>
                        To Address
                    </h4>
                    <app-search-address (fullAddress)="deliveryToEdit.address.full_address"
                        [(ngModel)]="deliveryToEdit.address.full_address"
                        [isNotAutoTransport]="job.estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                        [oldAddress]="deliveryToEdit.address" (onChange)="setTo($event)"></app-search-address>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveMoving()" [hidden]="job.estimate.booked && job.estimate.qa_status !== 'RELEASE'" data-dismiss="modal" type="button"
                    class="btn btn-save">Save</button>
            </div>
        </div>
    </div>
</div>

<!-- Show Driver License-->
<div #modalShowDriverLicense class="modal fade" id="modalShowDriverLicense" tabindex="-1" role="dialog" aria-labelledby="movingTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <img [src]="showDriverLicense" alt="">
        </div>
    </div>
</div>