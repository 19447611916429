<div class="layout-top-spacing">

    <form class="form" #entityForm="ngForm"  (ngSubmit)="save()"  ngNativeValidate>

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span>
                IVR
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/phone-ivrs">IVR</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-12 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <button class="btn btn-main-action ml-1 mt-3" type="submit" >
                    Save
                </button>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="widget-heading d-flex justify-content-between mb-4">
                    <h3 class="title-content-area">
                        IVR Information
                    </h3>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-12">

                        <div class="form-group mb-3">
                            <label class="control-label">Name</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                    </span>
                                </div>
                                <!-- <select name="phone_number" class="form-control" [(ngModel)]="dataForm.name">
                                    <option value="null" disabled>Select an Option</option>
                                    <option *ngIf="dataForm.id" value="{{dataForm.phone_number}}" disabled>{{dataForm.phone_number}}</option>
                                    <option *ngFor="let num of nums" [value]="num.number">
                                        {{num.number}}
                                    </option>
                                </select> -->
                                <input type="text" name="name" class="form-control" [(ngModel)]="dataForm.name" placeholder="Enter an name">
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Select a Phone Name.
                            </small>
                        </div>

                        <div class="form-group mb-3">
                            <label class="control-label">Description</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                    </span>
                                </div>
                                <input type="text" name="Description" class="form-control" [(ngModel)]="dataForm.description" placeholder="Enter an Description">
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Enter an Description.
                            </small>
                        </div>

                        <div class="form-group mb-3">
                            <label class="control-label">Company</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    </span>
                                </div>
                                <select name="company_id" class="form-control" [(ngModel)]="dataForm.company_id" (ngModelChange)="changeCompany()">
                                    <option value="null" disabled>Select a Company</option>
                                    <option *ngFor="let company of companies" [value]="company.id">
                                        {{company.name | titlecase}}
                                    </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Select a Company.
                            </small>
                        </div>

                        <!-- <div class="form-group mb-3">
                            <label class="control-label">Target Destination</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-pull-request"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                                    </span>
                                </div>
                                <select name="destination.target" class="form-control" [(ngModel)]="dataForm.destination.target" (ngModelChange)="changeTarget()">

                                    <option value="RING_GROUP">
                                        Ring Group
                                    </option>
                                    <option value="EXTERNAL_FORWARD" >
                                        External Forward
                                    </option>
                                    <option value="VOICEMAIL">
                                        Voicemail
                                    </option>
                                    <option value="PHONE_LINE">
                                        Phone Line
                                    </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Select a Target.
                            </small>
                        </div>

                        <div class="form-group mb-3">
                            <label class="control-label">Target Destination ID</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    </span>
                                </div>

                                <input *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'" type="text" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id" placeholder="Enter an name">

                                <select *ngIf="this.dataForm.destination.target != 'EXTERNAL_FORWARD'" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id">
                                    <option value="null" disabled>Select an Employee</option>
                                    <ng-container *ngFor="let target of targets">
                                        <option [value]="target.id">
                                            {{target.name | titlecase}}
                                        </option>
                                    </ng-container>

                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'RING_GROUP'">
                                Select a Ring Group.
                            </small>
                            <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'">
                                Enter a Destination Phone.
                            </small>
                            <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'VOICEMAIL'">
                                Select a Phone Line With Voicemail.
                            </small>
                            <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'PHONE_LINE'">
                                Select a Option.
                            </small>
                        </div> -->

                    </div>

                    <div class="col-md-6 col-sm-12">

                        <!-- <div class="form-group mb-3">
                            <label class="control-label">Phone Lines</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                                    </span>
                                </div>
                                <select name="phone_number" class="form-control phone-lines" [(ngModel)]="dataForm.phone_lines_id" multiple>
                                    <option value="null" disabled>Select an Option</option>
                                    <option *ngFor="let num of nums" [value]="num.phone_number">
                                        {{num.phone_number}}
                                    </option>
                                </select>ĸł
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Enter Phone Lines.
                            </small>
                        </div> -->

                        <div class="form-group">
                            <label class="control-label">Announcement</label>
                            <div class="upload-audio">
                                <input type="file"
                                    id="input-file-max-fs"
                                    [value]="dataForm.announcement.display_name"
                                    [appDropify]
                                    (onChange)="onChangeAnnouncement($event)"
                                    class="dropify"
                                    data-max-file-size="3M" />
                            </div>
                             <div  *ngFor="let row of rows">
                               <div *ngIf="dataForm.announcement.display_name && dataForm.name === row.name"><audio src="{{environment.az_api.cdn}}/{{workspaceId}}/dialer/{{dataForm.announcement.display_name}}" controls style="width: 200px;"></audio></div>
                             </div>
                          </div>

                        <!-- <div class="form-group mb-3 mt-86">
                            <label class="control-label">Play Music On Hold</label>
                            <div class="upload-audio">
                                <input type="file"
                                    id="input-file-max-fs"
                                    [value]="dataForm.play_music_on_hold.display_name"
                                    [appDropify]
                                    (onChange)="onChangePlayMusicOnHold($event)"
                                    class="dropify"
                                    data-max-file-size="3M" />
                            </div>
                        </div>                                -->

                        <div class="form-group mt-86">
                            <label class="control-label">Timeout Duration</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-merge"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M6 21V9a9 9 0 0 0 9 9"></path></svg>
                                    </span>
                                </div>
                                <input type="number" name="timeout_duration" class="form-control" [(ngModel)]="dataForm.timeout_duration">
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Select Timeout Duration.
                            </small>
                        </div>



                    </div>


                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12 layout-top-spacing">
            <div class="widget-content widget-content-area">

                <div class="widget-heading d-flex justify-content-between mb-4">
                    <h3 class="title-content-area">
                        IVR Rules
                    </h3>
                </div>

                <div class="row">

                    <div class="col-md-3">
                        <label class="control-label">Phone Digit</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                </span>
                            </div>
                            <select name="digit" class="form-control" [(ngModel)]="digit">
                                <ng-container *ngFor="let dg of digits">
                                    <option  [value]="dg">{{ dg }}</option>
                                </ng-container>

                            </select>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Select a Phone Digit.
                        </small>
                    </div>

                    <div class="col-md-3">
                        <label class="control-label">Target Destination</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-pull-request"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                                </span>
                            </div>
                            <select name="target" class="form-control" [(ngModel)]="target" (ngModelChange)="changeTarget()" [disabled]="!digit">

                                <option value="QUEUE">
                                    Queue
                                </option>
                                <option value="RING_GROUP">
                                    Ring Group
                                </option>
                                <option value="EXTERNAL_FORWARD" >
                                    External Forward
                                </option>
                                <option value="VOICEMAIL">
                                    Voicemail
                                </option>
                                <option value="EXTENSION">
                                    Phone Extension
                                </option>
                                <option value="IVR">
                                  Ivr
                              </option>
                              <option value="CALL_FLOW_CONTROL" disabled>
                                Call Flow Control
                            </option>
                            </select>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Select a Target Destination.
                        </small>
                    </div>

                    <div class="col-md-3">
                        <label class="control-label">Target Destination ID</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                </span>
                            </div>

                            <input *ngIf="target == 'EXTERNAL_FORWARD'" type="text" name="target_id" class="form-control" [(ngModel)]="target_id" placeholder="Enter An Phone External">

                            <select *ngIf="target != 'EXTERNAL_FORWARD'" name="target_id" class="form-control" [(ngModel)]="target_id" [disabled]="!target">
                                <option value="null" disabled>Select an Option</option>
                                <ng-container *ngFor="let target of targets">
                                    <option [value]="target.id">
                                        {{target.name | titlecase}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'QUEUE'">
                            Select a Queue.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'RING_GROUP'">
                            Select a Ring Group.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'EXTERNAL_FORWARD'">
                            Enter a Destination Phone.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'VOICEMAIL'">
                            Select a Phone Extension Voicemail.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'EMPLOYEE'">
                            Select an Employee.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'IVR'">
                            Select a IVR.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'EXTENSION'">
                            Select a Extension.
                        </small>
                        <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="target == 'CALL_FLOW_CONTROL'">
                            Select an Option.
                        </small>
                    </div>

                    <div class="col-md-3 btn-add-rule">
                        <div class="btn btn-save" (click)="addEntry()"  *ngIf="digit && target && target_id">
                            Add Rule
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Phone Digit</th>
                                <th>Target Destination</th>
                                <th>Target Destination ID</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of dataForm.entries; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{row.digit}}</td>
                                <td>{{row.destination.target}}</td>
                                <ng-container *ngIf="row.destination.target == 'RING_GROUP'">
                                    <ng-container *ngFor="let ring of ringGroups">
                                        <ng-container *ngIf="ring.id == row.destination.target_id">
                                            <td>{{ring.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'EXTERNAL_FORWARD'">
                                    <td>{{row.destination.target_id}}</td>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'VOICEMAIL'">
                                    <ng-container *ngFor="let num of extensions">
                                        <ng-container *ngIf="num.id == row.destination.target_id">
                                            <td>{{num.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'EXTENSION'">
                                  <ng-container *ngFor="let extension of extensions">
                                      <ng-container *ngIf="extension.id == row.destination.target_id">
                                          <td>{{extension.name}}:{{extension.extension}}</td>
                                      </ng-container>
                                  </ng-container>
                              </ng-container>

                                <ng-container *ngIf="row.destination.target == 'QUEUE'">
                                    <ng-container *ngFor="let queu of queues">
                                        <ng-container *ngIf="queu.id == row.destination.target_id">
                                            <td>{{queu.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'IVR'">
                                  <ng-container *ngFor="let ivr of ivrs">
                                      <ng-container *ngIf="ivr.id == row.destination.target_id">
                                          <td>{{ivr.name}}</td>
                                      </ng-container>
                                  </ng-container>
                              </ng-container>

                                <td>
                                    <button  class="btn btn-danger button-space" (click)="remove(i)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

    </form>

</div>
