<div class="layout-spacing layout-top-spacing">

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <div class="row">
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                        <h3>
                            Estimates Email Templates
                        </h3>
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a appCompanyRouterLink="/dashboard">
                                        <i class="fas fa-home"></i>
                                        Dashboard
                                    </a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">Estimates Email Templates</li>
                            </ol>
                        </nav>
                        <br>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center float-right">
                    <a appCompanyRouterLink="/email-templates/add" class="btn btn-new">
                        <i class="fas fa-plus-square"></i> &nbsp; New Email
                    </a>
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="row">
            <div class="col-lg-12">
                <div class="widget-content widget-content-area">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Subject</th>
                                    <th>Usage Context</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of rows; index as i;">
                                    <td>{{i+1}}</td>
                                    <td>{{row.subject | titlecase}}</td>
                                    <td *ngIf="row.usage_context == 'BOTH'">{{'ALL' | filterReplace}}</td>
                                    <td *ngIf="row.usage_context != 'BOTH'">{{row.usage_context | filterReplace}}</td>
                                    <td>{{row.created | date}}</td>
                                    <td>
                                        <a appCompanyRouterLink="/email-templates/{{row.id}}/edit" class="btn btn-success">
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </a>
                                        <button (click)="remove(row.id)" class="btn btn-danger button-space">
                                            <i class="fa-solid fa-trash-can"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- body -->

</div>
