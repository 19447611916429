<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Payment Gateway
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Payment Gateway</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appCompanyRouterLink="/payment-gateway-settings/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Setting
                </a>
            </div>
        </div>
        
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Gateway</th>
                                <th>Name</th>
                                <th class="text-center">Active CC</th>
                                <th class="text-center">Storage CC</th>
                                <th class="text-center">Active ACH</th>
                                <th>Created</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.nickname | titlecase}}</td>
                                <td class="text-center">
                                    <div class="n-chk">
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" name="chek-{{row.id}}" class="new-control-input"
                                                    [(ngModel)]="row.active_cc" (click)="toggle(row.id, 'CC')">
                                            <span class="new-control-indicator"></span>&nbsp;                                            
                                        </label>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="n-chk">
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" name="storage-{{row.id}}" class="new-control-input"
                                                    [(ngModel)]="row.storage_cc" (ngModelChange)="toggleStorage(row.id)">
                                            <span class="new-control-indicator"></span>&nbsp;                                            
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <span *ngIf="row.active_ach">Activate</span>
                                    <span *ngIf="!row.active_ach">Disabled</span>
                                </td>
                                <td>{{row.created | dateTime}}</td>
                                <td class="text-center">
                                    <a (click)="toggle(row.id, 'ACH')"  class="btn btn-success" *ngIf="!row.active_ach">
                                        Activate ACH
                                    </a>
                                    <a (click)="disabledAch(row.id, 'ACH')"  class="btn btn-danger" *ngIf="row.active_ach">
                                        Disable ACH
                                    </a>
                                    <a appCompanyRouterLink="/payment-gateway-settings/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
