import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CommissionSetting } from 'src/app/entities/workspace/commission-setting';
import { Employee } from 'src/app/entities/workspace/employee';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { CommissionSettingsService } from 'src/app/services/companies/commission-settings.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-commission-settings-form',
    templateUrl: './commission-settings-form.component.html',
    styleUrls: ['./commission-settings-form.component.scss']
})
export class CommissionSettingsFormComponent implements OnInit {

    dataForm: CommissionSetting;
    employees: Array<Employee>;
    work_departments: Array<WorkDepartment>;
    allRow: Array<any>;
    commissionCondition: boolean;
    public sold_by_id: string;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    constructor(
        private commissionSettingsService: CommissionSettingsService,
        private workDepartmentService: WorkDepartmentsService,
        private router: Router,
        private employeesService: EmployeesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.dataForm = new CommissionSetting();
        this.employees = [];
        this.work_departments = [];
        this.allRow = [];
        this.commissionCondition = false;
        this.sold_by_id = null;
        this.dataForm.payment_threshold = 0;
        
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.loadEmployees();
        this.loadTeam();
        // verficamos si se esta editando
        console.log(' a ve que es pues')
        this.currentRoute.params.subscribe(params => {
            console.log('params', params)
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }
    private load(id: string) {
        console.log('viene a cargar')
        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .getById(id)
            .then((response) => {
                // this.dataForm = response;
                // if (this.dataForm.condition) {
                //     this.commissionCondition = true;
                // }
                // this.changeSoldBy();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {

        if (!this.commissionCondition) {
            this.dataForm.condition = null;
        }
        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/commission-settings');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadTeam() {
        this.helperService.showLoadingMxpro360();

        this.workDepartmentService
            .getAll()
            .then((response) => {
                this.work_departments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeSoldBy() {
        if (this.dataForm.sold_by == "WORK_DEPARTMENT") {
            this.allRow = this.work_departments;
        } else {
            this.allRow = this.employees;
        }
    }

    setSoldById() {
        if (this.dataForm.sold_by == "WORK_DEPARTMENT") {
            this.dataForm.sold_by_work_department_id = this.sold_by_id;
            this.dataForm.sold_by_employee_id = '';
        } else {
            this.dataForm.sold_by_work_department_id = '';
            this.dataForm.sold_by_employee_id = this.sold_by_id;
        }
    }

}
