import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralSettingsleadRotation } from 'src/app/entities/workspace/general-settings-lead-rotation';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingLeadRotationService {

  constructor(private http: HttpClient) {

  }

  get(): Promise<Array<GeneralSettingsleadRotation>> {
      return this.http.get<Array<GeneralSettingsleadRotation>>(environment.api.company + '/general-settings-lead-rotation').toPromise();
  }

  set(dataForm: GeneralSettingsleadRotation) {
      return this.http.post(environment.api.company + '/general-settings-lead-rotation', dataForm).toPromise();
  }
}
