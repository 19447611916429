import { Company } from './company';
import { CompanySettings } from './company-settings';

export class GeneralSettings {

    public company: Company;
    public settings: CompanySettings;

    constructor() {
        this.company = new Company();
        this.settings = new CompanySettings();
    }

}
