export class JobNote {

    public id: string;
    public remarks: string;
    public job_id: string;
    public employee_id: string;
    public created: number;

    constructor() {
        this.id = null;
        this.remarks = null;
        this.job_id = null;
        this.employee_id = null;
        this.created = null;
    }
}
