import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeductibleLevel } from 'src/app/entities/workspace/deductible-level';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeductibleLevelsService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<DeductibleLevel>> {
        return this.http.get<Array<DeductibleLevel>>(environment.api.workspace + '/deductible-levels').toPromise();
    }

    getById(id: string) {
        return this.http.get<DeductibleLevel>(environment.api.workspace + '/deductible-levels/' + id).toPromise();
    }

    save(dataForm: DeductibleLevel): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: DeductibleLevel) {
        return this.http.post(environment.api.workspace + '/deductible-levels', dataForm).toPromise();
    }

    edit(dataForm: DeductibleLevel) {
        return this.http.put(environment.api.workspace + '/deductible-levels/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/deductible-levels/' + id).toPromise();
    }
}
