<div class="form-container">
    <div class="form-form padd-form">
        <div class="form-form-wrap">
            <div class="form-container p-0">
                <div class="form-content">

                    <!-- <h2>Log In to</h2> -->
                       <!--  <span class="brand-name">Moverxpro360</span> -->
                    <div class="text-center">
                        <img src="assets/img/logo_moverxpro360.png" alt="">
                    </div>

                    <ng-container *ngIf="section =='LOGIN_FORM'" >

                        <button class="boton-google mt-4" (click)="loginWithGoogle()">
                            <div class="boton-google-alto">
                                <svg width="40" height="40"><g id="Google-Button" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="40" height="40" rx="2" style="fill: rgb(255, 255, 255);"></rect><g id="logo_googleg_48dp" transform="translate(10.5, 10.5) scale(1.1)"><path d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727  17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z" id="Shape" fill="#4285F4"></path><path d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727  3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182  C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z" id="Shape" fill="#34A853"></path><path d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9  C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182  L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727  0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z" id="Shape" fill="#FBBC05"></path><path d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455  L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0  2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727  6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z" id="Shape" fill="#EA4335"></path><path d="M0,0 L18,0 L18,18 L0,18 L0,0 Z" id="Shape"></path></g></g></svg>
                                <div class="boton-google-texto">
                                    Log in with Google
                                </div>
                            </div>
                        </button>
                        <div class="area-division">
                            <span>or</span>
                        </div>
                        <form (ngSubmit)="onLogin()" class="text-left">
                            <div class="form" >

                                <div id="username-field" class="field-wrapper input">
                                    <i class="fas fa-user i-user"></i>
                                    <input [(ngModel)]="dataForm.username" id="username" name="username" type="text" class="form-control" placeholder="Username">
                                </div>

                                <div id="password-field" class="field-wrapper input">
                                    <i class="fas fa-lock i-lock"></i>
                                    <input [(ngModel)]="dataForm.password" id="inputPassword" minlength="6" maxlength="20" name="password" type="password" class="form-control" placeholder="Password" autofocus="autofocus">
                                    <button id="show_password" class="" type="button" (click)="showPassword()"> <span class="fa fa-eye icon"></span> </button>
                                </div>

                                <div class="field-wrapper text-center keep-logged-in">
                                    <div class="n-chk new-checkbox checkbox-outline-primary">
                                        <label class="new-control new-checkbox checkbox-outline-warning">
                                        <input type="checkbox" class="new-control-input" [(ngModel)]="keepSession" name="keepSession">
                                        <span class="new-control-indicator"></span>Keep me logged in
                                        </label>
                                    </div>
                                    <div class="field-wrapper">
                                        <a routerLink="/password-recovery" class="forgot-pass-link">Forgot Password?</a>
                                    </div>
                                </div>

                                <div class="field-wrapper text-center mt-4">
                                    <button class="btn btn-warning btn-login" type="submit" >
                                        Log In
                                    </button>
                                </div>

                            </div>
                        </form>

                        <p class="signup-link text-center">
                            New Here?
                            <a routerLink="/register-account-company">
                                Create an account
                            </a>
                        </p>

                    </ng-container>



                    <ng-container *ngIf="section == 'TOKEN_FORM'">

                        <div class="my-5 container d-flex justify-content-center align-items-center">
                            <div class="position-relative">
                                <div class="p-2 text-center">
                                    <h6 class="title-code">Please enter the one time code <br> to verify your account</h6>
                                    <div>
                                        <span>A verification code has been sent your email address and <br> a text message to your phone</span>
                                    </div>
                                    <div id="otp" class="inputs d-flex flex-row justify-content-center mt-4 mb-5">
                                        <input class="m-2 text-center form-control rounded" type="number" id="first" maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="second" maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="third" maxlength="1" />
                                        <div class="dash"> - </div>
                                        <input class="m-2 text-center form-control rounded" type="number" id="fourth" maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="fifth" maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="sixth" maxlength="1" />
                                    </div>
                                    <div class="mt-4">
                                        <button class="btn btn-danger px-4 validate" (click)="verifyCodeEntered()">Validate</button>
                                    </div>
                                    <div class="mt-4 resent-tocken">
                                        It may take a minute to receive your code <br>
                                        Haven't received it? <a (click)="onLogin()">Resend a new code</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <p class="terms-conditions text-center">© 2023 All Rights Reserved
                        <a href="https://www.moverxpro360.com">Moverxpro360</a> is a product of <a href="https://moverxpro360.com" target="_blank">MoverXpro 360 LLC</a> <br>
                        <!-- <a href="javascript:void(0);">Cookie Preferences</a>, -->
                        <a href="https://moverxpro360.com/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://moverxpro360.com/term-and-conditions" target="_blank">Term and Conditions</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="form-image">
        <div class="l-image">
        </div>
    </div>
</div>

