import { CompanyEmailSetting } from './company-email-setting';
import { CompanyPolicySetting } from './company-policy-setting';
import { CompanyPriceSetting } from './company-price-setting';
import { CompanyTicketSetting } from './company-ticket-setting';
import { CompanyMarketingSetting } from './company-marketing-setting';
import { CompanyCustomTheme } from '../companies/company-custom-theme';

export class CompanySettings {

    public id: string;
    public email_setting: CompanyEmailSetting;
    public price_setting: CompanyPriceSetting;
    public ticket_setting: CompanyTicketSetting;
    public policy_setting: CompanyPolicySetting;
    public marketing_setting: CompanyMarketingSetting;
    public custom_theme: CompanyCustomTheme;
    public theme_document: string;
    public layout_document: string;
    public time_zone: string;
    public cf_display_on_estimate: boolean;
    public cf_display_on_bol: boolean;
    public logo_display_on_bol: boolean;
    public default_payment_gateway_id: string;
    public default_binding_type: 'BINDING' | 'NO_BINDING' | 'NO_EXCEED';
    public when_to_comission: 'JOB_BOOKED' | 'PICKUP_DATE';
    public payroll_start_day: Date;
    public pay_period: 'WEEKLY' | 'MONTHLY' | 'BI_WEEKLY' | 'BI_MONTHLY';
    public enable_cover_page: boolean;
    public require_cardholder_name: boolean;
    public customer_birthday_for_cc: boolean;
    public require_customer_id: boolean;
    public visual_type: boolean;

    constructor() {
        this.id = null;
        this.email_setting = null;
        this.price_setting = null;
        this.ticket_setting = null;
        this.policy_setting = null;
        this.marketing_setting = null;
        this.custom_theme = null;
        this.theme_document = null;
        this.layout_document = null;
        this.time_zone = null;
        this.cf_display_on_estimate = false;
        this.cf_display_on_bol = true;
        this.logo_display_on_bol = false; 
        this.default_payment_gateway_id = null;
        this.default_binding_type = 'BINDING';
        this.when_to_comission = 'JOB_BOOKED';
        this.payroll_start_day = null;
        this.pay_period = 'WEEKLY';
        this.enable_cover_page = true;
        this.require_cardholder_name = false;
        this.customer_birthday_for_cc = true;
        this.require_customer_id = false;
        this.visual_type = false;
    }

}
