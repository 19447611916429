import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare const jQuery;

@Component({
  selector: 'app-custom-labels',
  templateUrl: './custom-labels.component.html',
  styleUrls: ['./custom-labels.component.scss']
})
export class CustomLabelsComponent implements OnInit {

  @ViewChild('generateLabels') generateLabels: ElementRef;
  actualPage: number;

  constructor() {
    this.actualPage = 1;
  }

  ngOnInit(): void {
  }

  openModalGenerateLabels() {
    jQuery(this.generateLabels.nativeElement).modal('show');
  }

}
