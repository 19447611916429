import { ConstantsMessages } from 'src/app/constants-messages';
import { DeductibleLevel } from 'src/app/entities/workspace/deductible-level';
import { DeductibleRate } from 'src/app/entities/workspace/deductible-rate';
import { LiabilityAmount } from 'src/app/entities/workspace/liability-amount';
import { LiabilityDeductibleRateView } from 'src/app/entities/workspace/liability-deductible-rate-view';
import { DeductibleLevelsService } from 'src/app/services/workspaces/deductible-levels.service';
import { LiabilityAmountsService } from 'src/app/services/workspaces/liability-amounts.service';
import { LiabilityDeductibleRatesService } from 'src/app/services/workspaces/liability-deductible-rates.service';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-liability-deductible-rates',
    templateUrl: './liability-deductible-rates.component.html',
    styleUrls: ['./liability-deductible-rates.component.scss']
})
export class LiabilityDeductibleRatesComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    deductibleLevels: Array<DeductibleLevel>;
    liabilityAmounts: Array<LiabilityAmount>;

    liabilityDeductibleRates: Array<LiabilityDeductibleRateView>;

    constructor(
        private deductibleLevelsService: DeductibleLevelsService,
        private liabilityAmountsService: LiabilityAmountsService,
        private liabilityDeductibleRatesService: LiabilityDeductibleRatesService,
        private helperService: HelperService
    ) {
        this.deductibleLevels = [];
        this.liabilityAmounts = [];
        this.liabilityDeductibleRates = [];
    }

    ngOnInit(): void {
        
    }

    ngAfterViewInit() {
        this.helperService.showLoadingMxpro360();
        this.deductibleLevelsService
            .getAll()
            .then((response) => {
                this.deductibleLevels = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.liabilityAmountsService
            .getAll()
            .then((response) => {
                this.liabilityAmounts = response;
            })
            .catch((error) => {

            });
        this.load();
  }

    load() {
        this.helperService.showLoadingMxpro360();
        this.liabilityDeductibleRatesService
            .getAll()
            .then((response) => {
                this.liabilityDeductibleRates = response;
                this.checkLA();
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.liabilityDeductibleRatesService
            .save(this.liabilityDeductibleRates)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private checkDeductiblesRates(index) {

        const DLNoExists = [];

        for (const dl of this.deductibleLevels) {
            let existDL = false;
            for (let deductibleRate of this.liabilityDeductibleRates[index].deductible_rates) {
                if (deductibleRate.deductible_level_id === dl.id) {
                    existDL = true;
                    break;
                }
            }

            if (!existDL) {
                DLNoExists.push(dl.id);
            }
        }

        // recorremos los que no existen, le creamos la entidad y lo agergamos a la lista del route Rate
        for (let dl_id of DLNoExists) {

            const tmpDR = new DeductibleRate();
            tmpDR.deductible_level_id = dl_id;
            tmpDR.liability_deductible_rate_id = this.liabilityDeductibleRates[index].id;
            tmpDR.rate = null;

            this.liabilityDeductibleRates[index].deductible_rates.push(tmpDR);
        }


    }

    /**
     * permite revisar si estan todas las zonas existentes con la zona saliente
     */
    private checkLA() {
        const laNoExists: Array<LiabilityAmount> = [];

        for (let lb of this.liabilityAmounts) {
            let existLB = false;
            for (let index in this.liabilityDeductibleRates) {
                if (this.liabilityDeductibleRates[index].liability_amount_id === lb.id) {
                    existLB = true;
                    this.checkDeductiblesRates(index);
                    break;
                }
            }

            if (!existLB) {
                laNoExists.push(lb);
            }
        }

        for (const lb of laNoExists) {

            const tmpLDRV = new LiabilityDeductibleRateView();
            tmpLDRV.liability_amount_id = lb.id;
            tmpLDRV.liability_amount = lb;


            for (const dl of this.deductibleLevels) {
                const tmpDR = new DeductibleRate();
                tmpDR.deductible_level_id = dl.id;
                tmpDR.rate = null;
                tmpLDRV.deductible_rates.push(tmpDR);
            }

            this.liabilityDeductibleRates.push(tmpLDRV);
        }

        setTimeout(() => {
            this.sortDeductibleRates();
        }, 1000);

    }


    private sortDeductibleRates() {
        for (let index in this.liabilityDeductibleRates) {
            const tmpDRs = [];
            for (const dl of this.deductibleLevels) {
                for (const dr of this.liabilityDeductibleRates[index].deductible_rates) {
                    if (dr.deductible_level_id === dl.id) {
                        tmpDRs.push(dr);
                        break;
                    }
                }
            }
            this.liabilityDeductibleRates[index].deductible_rates = tmpDRs;
        }
    }


}
