<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        FADD Dispatch
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appWorkspaceRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                           
                            <li class="breadcrumb-item active" aria-current="page">FADD Dispatch</li>
                        </ol>
                    </nav>
                    <br>
                </div>
            
            </div>
        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="row mb-5">

                    <div class="container-fadd-date col-3">
                        <label>FADD Date</label> 
                        <div class="fadd-date">
                            <i class="fas fa-calendar-alt"></i>                            
                            <app-date-picker id="select-fadd-date" [value]="" [placeholder]="'Not Assigned'" (onChange)="changeDate($event)" theme="WIDGET">
                            </app-date-picker> 
                        </div>
                    </div>

                    <div class="col-3">
                        <label class="">Storage State</label>
                        <select name="pickup_state" id="pickup_state" [(ngModel)]="filterStorageState" (ngModelChange)="filterListByState()"
                            class="form-control">
                            <option value="" selected="selected">All</option>
                            <option value="AK">Alaska</option>
                            <option value="AL">Alabama</option>
                            <option value="AR">Arkansas</option>
                            <option value="AZ">Arizona</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="IA">Iowa</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MD">Maryland</option>
                            <option value="ME">Maine</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MO">Missouri</option>
                            <option value="MS">Mississippi</option>
                            <option value="MT">Montana</option>
                            <option value="NC">North-carolina</option>
                            <option value="ND">North-dakota</option>
                            <option value="NE">Nebraska</option>
                            <option value="NH">New-hampshire</option>
                            <option value="NJ">New-jersey</option>
                            <option value="NM">New-mexico</option>
                            <option value="NV">Nevada</option>
                            <option value="NY">New-york</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode-island</option>
                            <option value="SC">South-carolina</option>
                            <option value="SD">South-dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VA">Virginia</option>
                            <option value="VT">Vermont</option>
                            <option value="WA">Washington</option>
                            <option value="NM">Wisconsin</option>
                            <option value="WV">West-virginia</option>
                            <option value="WY">Wyoming</option>
                        </select>
                    </div>
                                  
                    <div class="col-3" *ngIf="viewDriverInvoice">
                        <label>Select Company</label> 
                        <select name="pickup_state" id="pickup_state" [(ngModel)]="companyId" class="form-control">
                            <option  *ngFor="let company of companies" value="{{company.id}}" >{{company.name}}</option>
                            
                        </select>
                    </div>

                    <div class="col-3 text-right" *ngIf="viewDriverInvoice"> 
                        <button class="btn btn-danger" (click)="viewDriverInvoice = false">
                            Cancel
                        </button>
                        <button class="btn btn-success" (click)="createDriverInvoice()">
                            Create Driver Invoice
                        </button>                      
                    </div>         
                    
                    <div class="col-6 text-right" *ngIf="!viewDriverInvoice">
                        <button class="btn btn-new" (click)="viewDriverInvoice = true">
                            Driver Invoice
                       </button>
                    </div>

                </div>

                <div class="table-responsive">
                                        
                    <table class="table-bordered filter-bar col-12 mb-4">
                        <thead>
                            <tr>
                                <th class="blue" (click)="loadByColor('blue')">
                                    <h3> {{rows.blue?.length}} Jobs Shown</h3> 
                                    <span>Plus 4 Day and More Befor FADD</span>
                                    <i *ngIf="colorFiltered == 'blue'" class="fas fa-eye"></i>
                                </th>
                                <th class="green" (click)="loadByColor('green')">
                                    <h3>{{rows.green?.length}} Jobs Shown</h3> 
                                    <span>3 P + or FADD</span>
                                    <i *ngIf="colorFiltered == 'green'" class="fas fa-eye"></i>
                                </th>
                                <th class="yellow" (click)="loadByColor('yellow')">
                                    <h3>{{rows.yellow?.length}} Jobs Shown</h3> 
                                    <span>4 - 7 Days Past FADD</span>
                                    <i *ngIf="colorFiltered == 'yellow'" class="fas fa-eye"></i>
                                </th>
                                <th class="orange" (click)="loadByColor('orange')">
                                    <h3>{{rows.orange?.length}} Jobs Shown</h3> 
                                    <span>8 - 14 Days Past FADD</span>
                                    <i *ngIf="colorFiltered == 'orange'" class="fas fa-eye"></i>
                                </th>
                                <th class="red" (click)="loadByColor('red')">
                                    <h3>{{rows.red?.length}} Jobs Shown</h3> 
                                    <span>More Than 14 Days Pass FADD</span>
                                    <i *ngIf="colorFiltered == 'red'" class="fas fa-eye"></i>
                                </th>
                            </tr>
                        </thead>
                    </table>                    
                 
                    <table class="table table-bordered widget-table-fadd mb-4" 
                            [ngClass]="{'red': rows.red?.length > 0, 'blue' : rows.blue?.length > 0, 'green' : rows.green?.length > 0, 'orange' : rows.orange?.length > 0, 'yellow' : rows.yellow?.length > 0}">
                        <thead>
                            <tr>
                                <th class="th-select" *ngIf="viewDriverInvoice">Select</th>
                                <th>Job Number</th>
                                <th>Fadd</th>
                                <th>Customer Name</th>
                                <th>Storage Type</th>
                                <th>Storage Location</th>
                                <th>Storage Balance</th>
                                <th>Pickup State, City</th>
                                <th>Delivery State, City</th>
                                <th>Cubic Feet</th>
                                <th>Customer Balance</th>
                            </tr>
                        </thead>

                        <br>

                        <tbody>
                            <tr *ngFor="let row of rows.red ; let i = index" style="background: #ff002524;">
                                <ng-container *ngIf="row">
                                    <td class="text-center" *ngIf="viewDriverInvoice">
                                        <input type="checkbox" name="item{{i}}" (click)="addRowDriverInvoice(row.estimate_id)">
                                    </td>
                                    <td>                                        
                                        <a target="_blank" appWorkspaceRouterLink="/companyspace/{{row.company_id}}/moving/{{row.estimate_id}}/estimate" >{{row.job_number}}</a>
                                    </td>
                                    <td>{{row.date_fadd | date}}</td>
                                    <td>{{row.name}}</td>
                                    <td><span *ngIf="row.storage_type">{{row.storage_type}}</span><span *ngIf="!row.storage_type">None,</span></td>
                                    <td><span *ngFor="let name of row.interlalWarehouseName">{{name}},</span><span *ngFor="let eName of row.externalWarehouseName">{{eName}},</span></td>
                                    <td>{{row.storage_balance | currency}}</td>
                                    <td>{{row.pickup_state}}, {{row.pickup_city}}</td>
                                    <td>{{row.delivery_state}}, {{row.delivery_city}}</td>
                                    <td>{{row.cubic_feet}}</td>
                                    <td>{{row.customer_balance | currency}}</td>
                                </ng-container>                                
                            </tr>
                            <tr *ngFor="let row of rows.orange; let i = index" style="background: #ff6a0030;">
                                <ng-container *ngIf="row">
                                    <td class="text-center" *ngIf="viewDriverInvoice">
                                        <input type="checkbox" name="item{{i}}" (click)="addRowDriverInvoice(row.estimate_id)">
                                    </td>
                                    <td>                                        
                                        <a target="_blank" appWorkspaceRouterLink="/companyspace/{{row.company_id}}/moving/{{row.estimate_id}}/estimate" >{{row.job_number}}</a>
                                    </td>
                                    <td>{{row.date_fadd | date}}</td>
                                    <td>{{row.name}}</td>
                                    <td><span *ngIf="row.storage_type">{{row.storage_type}}</span><span *ngIf="!row.storage_type">None,</span></td>
                                    <td><span *ngFor="let name of row.interlalWarehouseName">{{name}},</span><span *ngFor="let eName of row.externalWarehouseName">{{eName}},</span></td>
                                    <td>{{row.storage_balance | currency}}</td>
                                    <td>{{row.pickup_state}}, {{row.pickup_city}}</td>
                                    <td>{{row.delivery_state}}, {{row.delivery_city}}</td>
                                    <td>{{row.cubic_feet}}</td>
                                    <td>{{row.customer_balance | currency}}</td>
                                </ng-container>                                    
                            </tr>
                            <tr *ngFor="let row of rows.blue; let i = index" style="background: #0066ff26;">
                                <ng-container *ngIf="row">
                                    <td class="text-center" *ngIf="viewDriverInvoice">
                                        <input type="checkbox" name="item{{i}}" (click)="addRowDriverInvoice(row.estimate_id)">
                                    </td>
                                    <td>                                        
                                        <a target="_blank" appWorkspaceRouterLink="/companyspace/{{row.company_id}}/moving/{{row.estimate_id}}/estimate" >{{row.job_number}}</a>
                                    </td>
                                    <td>{{row.date_fadd | date}}</td>
                                    <td>{{row.name}}</td>
                                    <td><span *ngIf="row.storage_type">{{row.storage_type}}</span><span *ngIf="!row.storage_type">None,</span></td>
                                    <td><span *ngFor="let name of row.interlalWarehouseName">{{name}},</span><span *ngFor="let eName of row.externalWarehouseName">{{eName}},</span></td>
                                    <td>{{row.storage_balance | currency}}</td>
                                    <td>{{row.pickup_state}}, {{row.pickup_city}}</td>
                                    <td>{{row.delivery_state}}, {{row.delivery_city}}</td>
                                    <td>{{row.cubic_feet}}</td>
                                    <td>{{row.customer_balance | currency}}</td>
                                </ng-container>                                    
                            </tr>
                            <tr *ngFor="let row of rows.yellow; let i = index" style="background: #dbe90026;">
                                <ng-container *ngIf="row">
                                    <td class="text-center" *ngIf="viewDriverInvoice">
                                        <input type="checkbox" name="item{{i}}" (click)="addRowDriverInvoice(row.estimate_id)">
                                    </td>
                                    <td>                                        
                                        <a target="_blank" appWorkspaceRouterLink="/companyspace/{{row.company_id}}/moving/{{row.estimate_id}}/estimate" >{{row.job_number}}</a>
                                    </td>
                                    <td>{{row.date_fadd | date}}</td>
                                    <td>{{row.name}}</td>
                                    <td><span *ngIf="row.storage_type">{{row.storage_type}}</span><span *ngIf="!row.storage_type">None,</span></td>
                                    <td><span *ngFor="let name of row.interlalWarehouseName">{{name}},</span><span *ngFor="let eName of row.externalWarehouseName">{{eName}},</span></td>
                                    <td>{{row.storage_balance | currency}}</td>
                                    <td>{{row.pickup_state}}, {{row.pickup_city}}</td>
                                    <td>{{row.delivery_state}}, {{row.delivery_city}}</td>
                                    <td>{{row.cubic_feet}}</td>
                                    <td>{{row.customer_balance | currency}}</td>
                                </ng-container>                                     
                            </tr>
                            <tr *ngFor="let row of rows.green; let i = index" style="background: #007c001f;">
                                <ng-container *ngIf="row">
                                    <td class="text-center" *ngIf="viewDriverInvoice">
                                        <input type="checkbox" name="item{{i}}" (click)="addRowDriverInvoice(row.estimate_id)">
                                    </td>
                                    <td>                                        
                                        <a target="_blank" appWorkspaceRouterLink="/companyspace/{{row.company_id}}/moving/{{row.estimate_id}}/estimate" >{{row.job_number}}</a>
                                    </td>
                                    <td>{{row.date_fadd | date}}</td>
                                    <td>{{row.name}}</td>
                                    <td><span *ngIf="row.storage_type">{{row.storage_type}}</span><span *ngIf="!row.storage_type">None,</span></td>
                                    <td><span *ngFor="let name of row.interlalWarehouseName">{{name}},</span><span *ngFor="let eName of row.externalWarehouseName">{{eName}},</span></td>
                                    <td>{{row.storage_balance | currency}}</td>
                                    <td>{{row.pickup_state}}, {{row.pickup_city}}</td>
                                    <td>{{row.delivery_state}}, {{row.delivery_city}}</td>
                                    <td>{{row.cubic_feet}}</td>
                                    <td>{{row.customer_balance | currency}}</td>
                                </ng-container>                             
                            </tr>
                            <tr *ngIf="rows.red?.length == 0 && rows.blue?.length == 0 && rows.green?.length == 0 && rows.orange?.length == 0 && rows.yellow?.length == 0">
                                <td colspan="5">
                                    No Data To Show
                                </td>
                            </tr>
                        </tbody>
                    </table>
                                    
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>