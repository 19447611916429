import { RouteVolumeRate } from "src/app/entities/workspace/route-volume-rate";
import { RouteRate } from "src/app/entities/workspace/route-rate";
import { Zone } from './zone';

export class RouteRateView extends RouteRate {
    public volume_rates: Array<RouteVolumeRate>;
    public zone_in: Zone;

    constructor() {
        super();
        this.volume_rates = [];
        this.zone_in = new Zone();
     }
}
