<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Local Tariff Settings
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Local Tariff Settings</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appWorkspaceRouterLink="/local-tariff-settings/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Local Tariff
                </a>
            </div>
        </div>

    </div>
    <!-- header -->
   
    <div class="row mb-4">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th class="text-center">Status</th>
                                <th class="text-center">Workers</th>
                                <th class="text-center">Trucks</th>
                                <th class="text-center">Price per Hour</th>
                                <th class="text-center">Max Hours</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let localTariff of localTariffs |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage} index as i;">
                                <td class="text-center">
                                    <span *ngIf="localTariff.active == true" class="badge badge-success">Active</span>
                                    <span *ngIf="localTariff.active == false" class="badge badge-danger">Inactive</span>
                                </td>
                                <td class="text-center">{{localTariff.workers}}</td>
                                <td class="text-center">{{localTariff.trucks}}</td>
                                <td class="text-center">{{localTariff.price_per_hour}}</td>
                                <td class="text-center">{{localTariff.max_hours}}</td>
                                <td>
                                    <a appWorkspaceRouterLink="/local-tariff-settings/{{localTariff.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button *ngIf="localTariff.active == true" (click)="toggleStatus(localTariff.id)" class="btn btn-danger">
                                        Disable
                                    </button>
                                    <button *ngIf="localTariff.active == false" (click)="toggleStatus(localTariff.id)" class="btn btn-success">
                                        Enable
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <!-- <div class="d-flex justify-content-sm-end justify-content-center" (click)="saveExtra()">
                <a class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; Save Extra Hour
                </a>
            </div> -->
            <div class="widget-content widget-content-area">

                <div class="widget-heading d-flex justify-content-between mb-4">
                    <h3 class="title-content-area">Extra Hours Tariff</h3>
                    <a class="btn btn-new" (click)="saveExtra()">
                        Save Extra Hour
                    </a>
                </div>

                <div class="row">
                    <div class="col-md-6 col-xl-4">
                        <div class="form-group mb-1">
                            <label class="control-label">Extra Labor Hours Fee</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="12 6 12 12 16 14"></polyline>
                                        </svg>
                                    </span>
                                </div>
                                <input type="number" name="extra_labor_hours" [(ngModel)]="workspaceSettings.auto_local_tariff.extra_labor_hours_fee"
                                    id="price_per_hour" step="0.01" class="form-control"/>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-md-6 col-xl-4">
                        <div class="form-group mb-1">
                            <label class="control-label">Extra Truck Hours Fee</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                    </span>
                                </div>
                                <input type="number" name="extra_labor_hours" [(ngModel)]="workspaceSettings.auto_local_tariff.extra_truck_hours_fee"
                                    id="how_many_cf_per_hour" class="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- body -->

</div>