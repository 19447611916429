<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3 style="font-style: italic;">
                        Materials Sale
                    </h3>
                </div>
            </div>
        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
                        <table class="table table-bordered mb-4">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Qty</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let rjpm of rowJobMaterials; let i = index">
                                    <td>{{rjpm.packing_material.name}}</td>
                                    <td><input type="number" id="quantity_{{i}}" name="data" min="0"  [(ngModel)]="rjpm.quantity" name="quantity_{{i}}"></td>
                                    <td *ngIf="estimate.service == 'LOCAL'">{{rjpm.packing_material.price_local | currency}}</td>
                                    <td *ngIf="estimate.service == 'LONG'">{{rjpm.packing_material.price_long | currency}}</td>
                                    <td *ngIf="estimate.service != 'LOCAL' && estimate.service != 'LONG'">{{rjpm.packing_material.price_local | currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-center">
                            <button class="btn btn-warning ml-1 mt-3" type="submit">
                                    <i class="far fa-save" style="height: 24px;"></i> &nbsp; Save
                             </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->