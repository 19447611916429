import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EstimateInventoryContainer } from '../../entities/workspace/estimate-inventory-container';

@Injectable({
    providedIn: 'root'
})
export class QuotesItemsContainersService {

    constructor(private http: HttpClient) {

    }

    get(estimate_id: string): Promise<Array<EstimateInventoryContainer>> {
        return this.http.get<Array<EstimateInventoryContainer>>(environment.api.company +
            '/estimates/' + estimate_id + '/inventory-containers').toPromise();
    }

    set(dataForm: Array<EstimateInventoryContainer>, estimate_id: string) {
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/inventory-containers', dataForm).toPromise();
    }

}
