import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdditionalStopRule } from 'src/app/entities/workspace/additional-stop-rule';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdditionalStopRulesService {


  constructor(private http: HttpClient) {

  }

  getAll(): Promise<Array<AdditionalStopRule>> {
      return this.http.get<Array<AdditionalStopRule>>(environment.api.workspace + '/additional-stop-rules').toPromise();
  }

  getById(id: string) {
      return this.http.get<AdditionalStopRule>(environment.api.workspace + '/additional-stop-rules/' + id).toPromise();
  }

  save(dataForm: AdditionalStopRule): Promise<any> {
      if (dataForm.id === null) {
          return this.add(dataForm);
      }
      return this.edit(dataForm);
  }

  add(dataForm: AdditionalStopRule) {
      return this.http.post(environment.api.workspace + '/additional-stop-rules', dataForm).toPromise();
  }

  edit(dataForm: AdditionalStopRule) {
      return this.http.put(environment.api.workspace + '/additional-stop-rules/' + dataForm.id, dataForm).toPromise();
  }

  remove(id: string) {
      return this.http.delete(environment.api.workspace + '/additional-stop-rules/' + id).toPromise();
  }
}