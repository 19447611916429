export class StorageService {
    public id: string;
    public job_id: string;
    public company_id: string;
    public storage_invoice_id: string;
    public from_bol: boolean;
    public status: 'PENDING' | 'PAID' |  'PARTIAL_PAID';
    public start_date: number;
    public end_date: number;
    public due_date: number;
    public cubic_feet: number;
    public rate_per_cf: number;
    public total_amount_due: number;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.job_id = null;
        this.storage_invoice_id = null;
        this.from_bol = false;
        this.company_id = null;
        this.status = 'PENDING';
        this.start_date = null;
        this.end_date = null;
        this.due_date = null;
        this.cubic_feet = null;
        this.rate_per_cf = null;
        this.total_amount_due = null;
        this.created = null;
        this.updated = null;
    }
}
