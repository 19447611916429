<div class="layout-spacing layout-top-spacing">

    <!-- Header - Storage Invoice -->
    <div class="col-12 mb-4">
        <div class="widget-content widget-content-area">
            <div class="d-flex justify-content-between widget-header m-0">
                <h3>Storage Invoice</h3>
                <div class="btn-actions">                    
                    
                    <!-- Mostrar cuando exista el Storage Invoice -->
                    <a class="btn btn-success mr-3" *ngIf="storageServices.length > 0" (click)="exportPdf(storageInvoice.id)">
                        <i class="fa-solid fa-print mr-2"></i> Print
                    </a>
                    <a (click)="openAddInvoiceModal()" class="btn btn-new">
                        <i class="fas fa-plus-square mr-2"></i> Add Storage Term
                    </a>

                </div> 
            </div>
        </div>
    </div> 


    <!-- Alert - Mostrar cuando NO exista el Storage Invoice -->
    <div class="alert-container" *ngIf="storageServices.length == 0">
        <div class="alert-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
        </div>
        <div class="desc">
            <span class="txt-one">Uncreated Storage Invoice</span>
            <span class="txt-two">You want to create a storage invoice?</span>            
        </div>
        <!-- <div>
            <a class="btn btn-new">
                Create Storage Invoice
            </a>
        </div> -->
    </div>

    
    <!-- Storage Invoice - Mostrar cuando exista el Storage Invoice -->
    <div class="container-storage-invoice" *ngIf="storageServices.length > 0">
        <div class="widget-content widget-content-area">
            
            <div class="storage-invoice">

                <!-- <div class="page-header">
                    <div class="d-flex justify-content-between">
                        <div class="company-logo">
                            <img src="https://moverxpro360.com/wp-content/themes/moverxpro360/assets/img/logo_moverxpro360.png" alt="">
                        </div>
                        <div class="company-info">
                            <div>MOVERXPRO360 xxx</div>
                            <div>800 800 8888 xxx</div>
                            <div>info@moverxpro360.com xxx</div>
                            <div>14244 SW - Miami Florida, 454545 xxx</div>
                            <div>US DOT: 123456 xxx</div>
                            <div>ICC MC: 123456 xxx</div>
                            <div>www.moverxpro360.com xxx</div>
                        </div>   
                    </div>                      
                    <div class="date-info">
                        <div>
                            <span>Due Date:</span>
                            <span>06/03/2023 xxx</span>                            
                        </div>  
                    </div>
                     
                </div> -->

                <div class="page-body">

                    <div class="title-bar-invoice" [ngStyle]="{'background': companyThemeDocument.firstColor}">
                        Storage Invoice - {{storageInvoice.document.code}}
                    </div>

                    <div class="invoice-info-section">
                        <!-- <div class="col-6 info-box">
                            <div class="key">Customer Name:</div>
                            <div class="info">Pascual Ramon xxx</div>
                        </div> -->
                        <!-- <div class="col-6 info-box">
                            <div class="key">Customer Rep:</div>
                            <div class="info">{{ estimate.employee.name }}</div>
                        </div> -->
                        <!-- <div class="col-6 info-box">
                            <div class="key">Contact Email:</div>
                            <div class="info">pascual@email.com xxx</div>
                        </div> -->
                        <!-- <div class="col-6 info-box">
                            <div class="key">Rep Email:</div>
                            <div class="info">salesman@email.com xxx</div>
                        </div> -->
                        <div class="col-6 info-box">
                            <div class="key">From Address:</div>
                            <div class="info">{{ estimate.pickup.address.city }} {{ estimate.pickup.address.state }}, {{ estimate.pickup.address.zip_code }}</div>
                        </div>
                        <!-- <div class="col-6 info-box">
                            <div class="key">Total Job Volume:</div>
                            <div class="info">608 CF xxx</div>
                        </div> -->
                        <div class="col-6 info-box">
                            <div class="key">To Address:</div>
                            <div class="info">{{ estimate.delivery.address.city }} {{ estimate.delivery.address.state }}, {{ estimate.delivery.address.zip_code }}</div>
                        </div>
                        <!-- <div class="col-6 info-box">
                            <div class="key">Move Date:</div>
                            <div class="info">Feb 1st 2023 12:00 PM xxx</div>
                        </div> -->
                    </div>      
                    
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Term</th>
                                <th>Rate</th>
                                <th>Charges</th>
                                <th [ngStyle]="{'background': companyThemeDocument.thirdColor}">Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Mostrar Storage - BD -->
                            <tr *ngFor="let service of storageServices">
                                <td>{{service.start_date | date}} - {{service.end_date | date}}</td>
                                <td>
                                    <span *ngIf="service.total_amount_due == 0">
                                        Free
                                    </span>
                                    <span *ngIf="service.total_amount_due > 0">
                                        @ {{service.rate_per_cf | currency}} Per CF per Mo
                                    </span>
                                </td>
                                <td>{{service.total_amount_due | currency}}</td>
                                <td>
                                    <ng-container  *ngIf="service.total_amount_due == 0">
                                        <span>
                                            Paid
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="service.total_amount_due > 0">
                                        <span *ngIf="service.status == 'PAID'">
                                            Paid
                                        </span>
                                        <span *ngIf="service.status == 'PENDING'">
                                            Due
                                        </span>
                                        <span *ngIf="service.status == 'PARTIAL_PAID'">
                                            Partial Paid
                                        </span>
                                    </ng-container>
                                    
                                </td>
                                <td>
                                    <button *ngIf="(service.status == 'PENDING' && !service.from_bol) || (service.total_amount_due == 0 && !service.from_bol)" 
                                        class="btn btn-danger" (click)="removeService(service.id)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>                    
                    </table>

                    <div class="total-section">                    

                        <div class="row">
                            <div class="col-8 key text-right">Total Due:</div>
                            <div class="col-4">{{ storageInvoice.total | currency }}</div>
                        </div>
                        <div class="row">
                            <div class="col-8 key text-right">Total Customer Payments:</div>
                            <div class="col-4">{{ customerTotalPayments | currency }}</div>
                        </div>
                        <div class="row">
                            <div class="col-8 key text-right">Remaining Balance Due:</div>
                            <div class="col-4">{{ totalDue | currency }}</div>
                        </div>

                    </div>


                    <table class="table">
                        <thead>
                            <tr>
                                <th [ngStyle]="{'background': companyThemeDocument.thirdColor}">Paid Date</th>
                                <th [ngStyle]="{'background': companyThemeDocument.thirdColor}">Payment Method</th>
                                <th [ngStyle]="{'background': companyThemeDocument.thirdColor}">Payment</th>
                                <th [ngStyle]="{'background': companyThemeDocument.thirdColor}">Confirmation #</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of customerPayments">
                                <td>{{ payment.payment.date | date }}</td>
                                <td>{{ payment.payment_method?.name }}</td>
                                <td>{{ payment.payment.amount | currency }}</td>
                                <td>{{ payment.payment_transaction?.code }}-{{ payment.payment_transaction?.auth_code }}</td>
                            </tr>
                        </tbody>                    
                    </table>


                </div>

                <div class="page-footer">

                </div>

            </div>

        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" #addInvoiceModal id="addInvoiceModal" tabindex="-1" role="dialog"
    aria-labelledby="addInvoiceModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <form>
                <div class="col-md-12 modal-header">
                    <h5 class="modal-title">
                        Add Storage Term
                    </h5>
                </div>

                <div class="modal-body">
                    <div class="row">

                        <div class="form-group col-6 mb-4">
                            <label for="inputState">Term Start Date</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </span>
                                </div>
                                <app-date-picker id="term_start_date" 
                                    [value]="storageService.start_date" 
                                    [enableTime]="false" 
                                    [placeholder]="'Not Assigned'"
                                    (onChange)="setStartBillDate($event)">
                                </app-date-picker>
                            </div>        
                            <small id="emailHelp1" class="form-text text-muted">Select term start date.</small>
                        </div>

                        <div class="form-group col-6 mb-4">
                            <label for="inputState">Term End Date</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </span>
                                </div>
                                <app-date-picker id="term_end_date" 
                                    [value]="storageService.end_date" 
                                    [enableTime]="false" 
                                    [placeholder]="'Not Assigned'"
                                    (onChange)="setNextBillDate($event)">
                                </app-date-picker>
                            </div>        
                            <small id="emailHelp1" class="form-text text-muted">Select term end date.</small>
                        </div>

                        <div class="form-group col-6 mb-4">
                            <label for="inputState">Cubic Feet</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                    </span>
                                </div>
                                <input type="number" 
                                    [(ngModel)]="storageService.cubic_feet" 
                                    class="form-control" name="cubic_feet">
                            </div>        
                            <small id="emailHelp1" class="form-text text-muted">Enter cubic feet.</small>
                        </div>

                        <div class="form-group col-6 mb-4">
                            <label for="inputState">Rate per Cubic Foot</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                    </span>
                                </div>
                                <input type="number" 
                                    [(ngModel)]="storageService.rate_per_cf" 
                                    class="form-control" name="rate_per_cf">
                            </div>        
                            <small id="emailHelp1" class="form-text text-muted">Enter rate per cubic foot.</small>
                        </div>
                        
                        <div class="form-group col-6 mt-3">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="prorate_last_month" name="prorate_last_month"
                                        [(ngModel)]="storageServiceProrate">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="prorate_last_month" class="checkbox-label">
                                    Prorate Last Month
                                </label>
                            </div>
                        </div>

                        <div class="form-group col-6 mt-3">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="first_month_free" name="first_month_free"
                                        [(ngModel)]="storageServiceFistMondFree">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="first_month_free" class="checkbox-label">
                                    First Month Free
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-save" data-dismiss="modal" (click)="addTerm()">
                        Add Term
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>