import { Email } from "./email";
import { Role } from "./role";

export class EmployeeWorkspacePermission {

    public id: string;
    public role: Role;
    public created: number;
    public updated: number;;

    public constructor () { 
        this.id = null;
        this.role = null;
        this.created = null;
        this.updated = null;
    }

}
