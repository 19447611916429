<div class="widget widget-table-two widget-container">

    <div class="widget-heading">
        <h5>Sales Leader</h5> 
        <div class="w-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
        </div>               
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <div class="widget-three">
                <div class="widget-content">

                    <div class="order-summary" *ngIf="jobsSalesLeader.length > 0">

                        <div *ngFor="let jobsSaleLeader of jobsSalesLeader ; index as key" class="summary-list">
                            <div class="w-icon">
                                {{key + 1}}
                            </div>
                            <div class="w-summary-details">

                                <div class="w-summary-info">
                                    <h6>{{jobsSaleLeader.employee}}</h6>
                                    <p class="summary-count">
                                        
                                        <span>
                                            {{jobsSaleLeader.total_sales | currency}}
                                        </span> 

                                        <span class="up-arrow" *ngIf="jobsSaleLeader.total_sales > jobsSaleLeader.total_sales_last_month">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
                                        </span>

                                        <span class="down-arrow" *ngIf="jobsSaleLeader.total_sales < jobsSaleLeader.total_sales_last_month">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
                                        </span>

                                    </p>
                                </div>

                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div [ngStyle]="{'background': colorGradient[key] }"
                                            class="progress-bar bg-gradient-secondary" role="progressbar"
                                            style="width: 90%;" aria-valuenow="90" aria-valuemin="0"
                                            aria-valuemax="100">
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                    </div>

                </div>
            </div>
            <!-- <table class="table">
                <thead>
                    <tr>
                        <th class="bg-success">
                            <div class="th-content text-light">Sale Name</div>
                        </th>
                        <th class="bg-success text-center">
                            <div class="th-content text-light">Total Sales</div>
                        </th>
                        <th class="bg-success text-center">
                            <div class="th-content text-light"></div>
                        </th>
                      
                    </tr>
                </thead>
                <tbody *ngIf="jobsSalesLeader.length > 0">
                    <tr *ngFor="let jobsSaleLeader of jobsSalesLeader">
                        <td>
                            <div class="td-content customer-name d-flex-center">
                                <div class="avatar-table avatar-success text-success">
                                    <span
                                        class="text-avatar-table">{{jobsSaleLeader.employee | avatarTitle}}</span>
                                </div>
                                {{jobsSaleLeader.employee}}
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="badge badge-success">{{jobsSaleLeader.total_sales | currency}}</div>
                        </td>
                        <td class="text-center">
                            <div style="font-size: 20px;" [ngStyle]="{'color':jobsSaleLeader.total_sales > jobsSaleLeader.total_sales_last_month ? '#8dbf42' : 'red' }">▲</div>
                        </td>
                        
                    </tr>


                </tbody>
            </table> -->
        </div>
    </div>
</div>