import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Customer } from 'src/app/entities/workspace/customer';

@Injectable({
    providedIn: 'root'
})
export class CustomersService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<Customer>> {
        return this.http.get<Array<Customer>>(environment.api.workspace + '/customers').toPromise();
    }

    getById(id: string) {
        return this.http.get<Customer>(environment.api.workspace + '/customers/' + id).toPromise();
    }

    getEstimatesCustomer(id: string) {
        return this.http.get<any>(environment.api.company + '/customers/' + id + '/estimates').toPromise();
    }

    save(customer: Customer): Promise<any> {
        if (customer.id === null) {
            return this.add(customer);
        }
        return this.edit(customer);
    }

    add(customer: Customer) {
        return this.http.post(environment.api.workspace + '/customers', customer).toPromise();
    }

    edit(customer: Customer) {
        return this.http.put(environment.api.workspace + '/customers/' + customer.id, customer).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/customers/' + id).toPromise();
    }

    getFiltered(search: string) {
        return this.http.get<Array<Customer>>(environment.api.workspace + '/customers/' + search + '/filtered').toPromise();
    }
}
