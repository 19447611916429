import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { HelperService } from 'src/app/services/helper.service';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';

declare const jQuery;

@Component({
    selector: 'app-carriers-form',
    templateUrl: './carriers-form.component.html',
    styleUrls: ['./carriers-form.component.scss']
})
export class CarriersFormComponent implements OnInit {

    dataForm: Carrier;
    CarrierContact: CarrierContact;

    @ViewChild('modalCarriers')
    modalCarriers: ElementRef;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute,
        private carrierService: CarriersService,
        private helperService: HelperService
    ) {
        this.dataForm = new Carrier();
        this.CarrierContact = new CarrierContact();
    }

    ngOnInit(): void {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.carrierService
            .save(this.dataForm)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                if (this.dataForm.id === null) {
                    this.helperService.goToWorkspaceRouterLink('/carriers/' + response.row_id + '/view');
                } else {
                    this.helperService.goToWorkspaceRouterLink('/carriers/' + this.dataForm.id + '/view');
                }
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private async load(id: string) {
        this.helperService.showLoadingMxpro360();
        await this.carrierService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                // if (!this.dataForm.main_contact) {
                //     this.dataForm.main_contact = new CarrierContact();
                // }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    addressCarriers(address) {
        this.dataForm.address = address;
    }

    expirationDate(Date) {
        // sumamor 12 horas (43200000ms)para que se guarde a las 12pm
        Date += 43200000;
        this.dataForm.insurance_due_date = Date;
    }


    public openModalCarriers(): void {
        jQuery(this.modalCarriers.nativeElement).modal('show');
    }

    addCarrierContact() {
        // this.dataForm.contacts.push(this.CarrierContact);
        this.CarrierContact = new CarrierContact();
    }
}
