<div class=" layout-top-spacing">
    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
        <div class="row">
            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Estimates Email Templates
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>Dashboard</a></li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/email-templates">Estimates Email Templates</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button class="btn btn-main-action" type="submit">
                        Save
                    </button>
                </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="row">
            <div class="col-md-9">              
                <div class="widget-content widget-content-area">                  
                    <div class="form-group mb-1">
                        <label class="control-label">Subject</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </span>
                            </div>
                            <input type="text" [(ngModel)]="dataForm.subject" name="subject" class="form-control">
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Subject Name.</small>
                    </div>

                    <div class="form-group mb-4">
                        <label for="inputState">Usage Context</label>
                        <select [(ngModel)]="dataForm.usage_context" name="dataForm.context" class="form-control">
                            <option value="BOTH">ALL</option>
                            <option value="AUTOMATION">AUTOMATION</option>
                            <option value="AUTO_TRANSPORT">AUTO TRANSPORT</option>
                            <option value="MOVING">MOVING</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Select Context.</small>
                    </div>

                    <div class="form-group select-work-departments-email-templates mb-4">
                        <label for="inputState">Work Departments</label>
                        <select [(ngModel)]="dataForm.work_department_ids" multiple name="workdepartments" class="form-control method select-methods">
                            <option *ngFor="let workDepartment of workDepartments" [value]="workDepartment.id">{{workDepartment.name}}</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Select Work Departments.</small>
                    </div>

                    <div class="form-group mb-4">
                        <app-ck-editor [(ngModel)]="dataForm.body" name="content" ngDefaultControl>
                        </app-ck-editor>
                    </div>

                    <div class="col-md-12 mt-5">
                        <div class="form-group">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="attach_inventory" name="attach_inventory"
                                    [(ngModel)]="dataForm.attach_inventory">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="attach_inventory" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.attach_inventory}">
                                    Attach Portal Link
                                </label>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_esignature"
                                    [(ngModel)]="dataForm.request_esignature" name="request_esignature">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="request_esignature" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_esignature}">
                                    Request electronic signature
                                </label>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_poa"
                                    [(ngModel)]="dataForm.request_poa" name="request_poa">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="request_poa" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_poa}">
                                    Request POA
                                </label>
                            </div>
                        </div>
                        
                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_inventory_update"
                                    [(ngModel)]="dataForm.request_inventory_update" name="request_inventory_update">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="request_inventory_update" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_inventory_update}">
                                    Inventory editable by customer
                                </label>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_bol"
                                    [(ngModel)]="dataForm.request_bol" name="request_bol">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="request_bol" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_bol}">
                                    Request BOL
                                </label>
                            </div>
                        </div>

                        <div class="d-flex" *ngIf="dataForm.attach_inventory && dataForm.request_bol">
                            <label class="signature-type">
                                Signature Type
                            </label>
                            <select id="inputState" [(ngModel)]="dataForm.bol_signature_requested" class="form-control"
                                name="wd_bol_signature_requested" style="width: 250px;">
                                <option value="PRE_PICKUP">Pre Pickup</option>
                                <option value="ON_SITE">On Site</option>
                                <option value="DELIVERY">Delivery</option>
                            </select>                                
                        </div>

                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_token"
                                    [(ngModel)]="dataForm.request_token" name="request_token">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="request_token" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_token}">
                                    Request TOKEN
                                </label>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_ppw"
                                    [(ngModel)]="dataForm.request_ppw" name="request_ppw">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="request_ppw" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_ppw}">
                                    Request PPW
                                </label>
                            </div>
                        </div>

                        <div class="d-flex" *ngIf="dataForm.attach_inventory && dataForm.request_ppw">
                            <label class="signature-type">
                                PPW Type
                            </label>
                            <select id="inputState" [(ngModel)]="dataForm.ppw_document_type_id" class="form-control"
                                name="wd_ppw_document_type_id" style="width: 250px;">
                                <ng-container  *ngFor="let documentPPW of documentsPPW" >
                                    <option *ngIf="documentPPW.usage_context == 'PPW_PACKET'" [value]="documentPPW.id">{{documentPPW.name}}</option>
                                </ng-container>
                            </select>                                
                        </div>

                        <div class="form-group" *ngIf="dataForm.attach_inventory">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="request_ppw"
                                    [(ngModel)]="dataForm.attach_files" name="attach_files">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="attach_files" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.attach_files}">
                                    Attach Files
                                </label>
                            </div>
                        </div>

                        <div [hidden]="!dataForm.attach_inventory || !dataForm.attach_files">
                            <div class="d-flex">
                                <label class="signature-type">
                                    File Types
                                </label>
                                <select id="inputState" [(ngModel)]="dataForm.attach_file_types" class="form-control file-type"
                                    name="wd_attach_file_types" style="width: 250px;" multiple="multiple">
                                    <option value="PICKUP">Pickup Docs</option>
                                    <option value="DELIVERY">Delivery Docs</option>
                                    <option value="MEDIA_FILES">Media Files</option>
                                </select>                                
                            </div>
                        </div>
                    </div>    
                </div>           
            </div>
            <div class="col-md-3">
                <div class="keyword-section">

                    <div class="widget-content widget-content-area mb-2 py-2 keyword-section-title">
                        <h4 class="m-0">
                            Keywords
                        </h4>
                    </div>

                    <div class="widget-content widget-content-area keywords mb-2">
                        @COMPANY_NAME <br>
                        @COMPANY_PHONE <br>
                        @COMPANY_EMAIL <br>
                        @COMPANY_WEB <br>
                        @COMPANY_DOT <br>
                        @COMPANY_LICENSE <br>
                    </div>

                    <div class="widget-content widget-content-area keywords mb-2">
                        @REPRESENTATIVE_NAME <br>
                        @REPRESENTATIVE_PHONE <br>
                        @REPRESENTATIVE_EMAIL <br>
                    </div>

                    <div class="widget-content widget-content-area keywords mb-2">
                        @CUSTOMER_FULL_NAME <br>
                        @CUSTOMER_NAME <br>
                        @CUSTOMER_PHONE <br>
                        @CUSTOMER_EMAIL <br>
                        @CUSTOMER_SIGNATURE <br>
                        @CUSTOMER_DATE_SIGNATURE <br>
                        @SIGNATURE_IP <br>
                        @PU_TIME <br>
                        @CUSTOMER_DEPOSIT <br>
                    </div>

                    <div class="widget-content widget-content-area keywords mb-2">
                        @FROM_CITY <br>
                        @FROM_STATE <br>
                        @FROM_COUNTRY <br>
                        @TO_CITY <br>
                        @TO_STATE <br>
                        @TO_COUNTRY <br>
                    </div>

                    <div class="widget-content widget-content-area keywords mb-2">
                        @JOB_NUMBER <br>
                        @JOB_PICKUP_START <br>
                        @VEHICLE <br>
                        @MOVE_TYPE <br>
                        @MOVE_DATE <br>
                        @MOVE_SIZE <br>
                        @TOTAL_JOB <br>
                        @TOTAL_CF <br>
                        @TOTAL_LBS <br>
                        @TOTAL_MILES <br>                        
                        @MOVING_ESTIMATE_SIGNATURE_TEXT <br>
                        @JOB_FADD <br>
                        @DATE_OUT <br>
                    </div>                    

                    <div class="widget-content widget-content-area keywords mb-2">
                        @CARRIER <br>
                        @CARRIER_DOT <br>
                        @JOB_RATE <br>
                        @DEPOSIT <br>
                        @INITIAL_DEPOSIT <br>
                        @JOB_PICKUP_END <br>
                        @CUSTOMER_BALANCE <br>
                    </div>                    

                </div>
            </div>
        </div>
    </form>
</div>
