
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Zone } from 'src/app/entities/workspace/zone';
import { ZoneState } from 'src/app/entities/workspace/zone-state';
import { ZoneStatesService } from 'src/app/services/workspaces/zone-states.service';
import { ZonesService } from 'src/app/services/workspaces/zones.service';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare const jQuery;
declare const google;
declare const Snackbar;

@Component({
    selector: 'app-zone-states-form',
    templateUrl: './zone-states-form.component.html',
    styleUrls: ['./zone-states-form.component.scss']
})
export class ZoneStatesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: ZoneState;
    zoneStates: Array<Zone>;
    zipcode: string;
    public typeView: string;
    private position: {
        coords: {
            latitude: number;
            longitude: number
        }
    };

    @ViewChild('mapView')
    mapView: ElementRef;
    @ViewChild('mapView')
    entityForm: ElementRef;
    
    constructor(
        private zonesStatesService: ZoneStatesService,
        private currentRoute: ActivatedRoute,
        private zonesService: ZonesService,
        private helperService: HelperService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.dataForm = new ZoneState();
        this.typeView = 'form';
        this.position = {
            coords: {
                latitude: null,
                longitude: null
            }
        }
        this.zipcode = '';
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        const remove = (sel) => document.querySelectorAll(sel).forEach(el => el.remove());
        remove(".select2-container.select2-container--mxp360-form.select2-container--open") 
    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
        this.helperService.showLoadingMxpro360();
        this.zonesService
            .getAll(this.tariffVersionFactory.getTariffVersionId())
            .then((response) => {
                this.zoneStates = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.zonesStatesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.dataForm.tariff_version_id = this.tariffVersionFactory.getTariffVersionId();
        
        this.zonesStatesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/settings/zone-states');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeTypeView() {
        this.typeView = this.typeView == 'map' ? 'form' : 'map';
        if (this.typeView == 'map') {
            this.initMap();
        }
    }

    initMap(): void {

        const THIS = this;

        const map = new google.maps.Map(
            this.mapView.nativeElement as HTMLElement,
            {
                zoom: 5,//10,
                center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),//(22.344, 114.048),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: false,
                zoomControl: true
            });

        const drawingManager = new google.maps.drawing.DrawingManager({
            // drawingMode: google.maps.drawing.OverlayType.CIRCLE,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_LEFT,
                drawingModes: [
                    google.maps.drawing.OverlayType.CIRCLE,
                ],
            },
            circleOptions: {
                strokeColor: "#e44f00",
                strokeOpacity: 0.5,
                fillColor: "#e44f00",
                fillOpacity: 0.4,
                strokeWeight: 1,
                clickable: true,
                editable: true,
                zIndex: 1,
            }
        });
        let selectedShape;
        /**
         * 
         * @param circle obtiene el circulo para calcular la posicion y el radio
         */
        function setSelection(circle) {
            selectedShape = circle;
            circle.setEditable(true);
            // seteamos los datos recibidos para buscar la estado y zipcode
            THIS.dataForm.radius = Number((Number(circle.getRadius()) * 0.000621371).toFixed(4));
            THIS.position.coords.latitude = circle.getCenter().lat();
            THIS.position.coords.longitude = circle.getCenter().lng();
            THIS.getCenterAddress(THIS.position);
        }
        function deleteSelectedShape() {
            if (selectedShape) {
                selectedShape.setMap(null);
            }
        }
        function clearSelection() {
            if (selectedShape) {
                if (typeof selectedShape.setEditable == 'function') {
                    selectedShape.setEditable(false);
                }
                selectedShape = null;
            }
        }
        google.maps.event.addDomListener(document.getElementById('delete-button'), 'click', deleteSelectedShape);
        google.maps.event.addListener(drawingManager, 'overlaycomplete', function (e) {
            // Switch back to non-drawing mode after drawing a shape.
            drawingManager.setDrawingMode(null);
            // Add an event listener that selects the newly-drawn shape when the user
            // mouses down on it.
            const newShape = e.overlay;
            newShape.type = e.type;
            // actualiza seleccion al dar click en el circulo
            google.maps.event.addListener(newShape, 'click', function () {
                setSelection(newShape);
            });
            // actualiza datos al cambiar tamanio del radio
            google.maps.event.addListener(newShape, 'radius_changed', function () {
                setSelection(newShape);
            });
            // actualiza datos al cambiar posicion del circulo
            google.maps.event.addListener(newShape, 'center_changed', function () {
                setSelection(newShape);
            });
            setSelection(newShape);
        });
        google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
        google.maps.event.addListener(map, 'click', clearSelection);
        // render map
        drawingManager.setMap(map);

    }


    //////////////////
    /// geolocation///
    //////////////////
    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.getCenterAddress);
        }
    }
    /**
     * obtiene datos de direccion del sitio central del circulo
     * @param position 
     */
    getCenterAddress(position: any) {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
        );
        const THIS = this;
        geocoder.geocode({ latLng: latlng }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                    // recorre parametros de direccion para asignarla al objeto formData
                    for (var i = 0; i < results[0].address_components.length; i++) {
                        const location = results[0].address_components[i];
                        if (location.types[0] == "postal_code") {
                            THIS.dataForm.zip_code = location.long_name;
                        }
                        if (location.types[0] == "administrative_area_level_1") {
                            THIS.dataForm.name = location.long_name;
                            THIS.dataForm.code = location.short_name;
                        }
                    }
                }
            }
        });
    }

    /**
     * valida si un zipcode esta en un radio especifico
     */
    async validateZipCodeInRadius() {
        // si no hay zip code para comparar no hacemos nada
        if (this.zipcode == '') {
            return;
        }
        // si no hay zip code en el formulario mostramos mensaje y no hacemos nada mas
        if (this.dataForm.zip_code === null || this.dataForm.zip_code === '') {
            this.showMessage('First you have to set a zip code to your zone', 'error');
            return;
        }

        // si existen ambos zip code procedemos al calculo
        const checkPount = await this.getLatLang(this.zipcode);
        const centerPoint = await this.getLatLang(this.dataForm.zip_code);

        const inRadius = this.arePointsNear(checkPount, centerPoint, this.dataForm.radius);
        if (inRadius) {
            this.showMessage('Zipcode is in radius', 'success');
        } else {
            this.showMessage('Zipcode is not in radius', 'error');
        }
    }
    /**
     * obtiene corrdenadas de un zipcode
     * @param zipcode 
     */
    getLatLang(zipcode) {
        return new Promise((resolve, reject) => {
            let lat: string;
            let lng: string;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'address': 'zipcode ' + zipcode }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    resolve({ lat, lng });
                } else {
                    reject("Request failed.");
                }
            });
        })
    }
    // valida distancia entre dos puntos dentro de un rango de millas
    arePointsNear(checkPoint, centerPoint, ml) {
        const km = ml * 1.60934;
        const ky = 40000 / 360;
        const kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
        const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
        const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
        return Math.sqrt(dx * dx + dy * dy) <= km;
    }
    /**
     * muestra mensajes Snackbar
     * @param declineMessage 
     * @param type 
     */
    showMessage(declineMessage: string, type: string) {
        let backgroundColor: string;
        if (type == 'error') {
            backgroundColor = '#e7515a';
        } else {
            backgroundColor = '#40CA4D';
        }
        Snackbar.show({
            text: declineMessage,
            actionTextColor: '#fff',
            backgroundColor
        });
    }
}
