import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/entities/workspace/company';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { WarehousesFaddJobsService } from 'src/app/services/workspaces/warehouses-fadd-jobs.service';
import { environment } from 'src/environments/environment';

declare const swal;
@Component({
  selector: 'app-warehouses-fadd-jobs',
  templateUrl: './warehouses-fadd-jobs.component.html',
  styleUrls: ['./warehouses-fadd-jobs.component.scss']
})
export class WarehousesFaddJobsComponent implements OnInit {

  dateReport;
  rows:any;
  public colorFiltered: string;
  public filterStorageState: string;
  private lastFilterUsed: 'COLOR' | 'DATE';
  public viewDriverInvoice: boolean;
  driverInvoices: string[];
  companies: Company[];
  companyId: string;

  constructor(
    private warehousesFaddJobsService: WarehousesFaddJobsService,
    public authService: AuthService,
    private helperService: HelperService,
    private companiesService: CompaniesService,
    ) {
    this.rows= [];
    this.lastFilterUsed = 'COLOR';
    this.colorFiltered = 'red';
    this.filterStorageState = '';
    this.viewDriverInvoice = false;
    this.driverInvoices = [];
    this.companies = [];
    this.companyId = null;
   }

  ngOnInit(): void {
    this.loadCompanies();
  }

  ngAfterViewInit() {
    this.loadByColor('red');
  }

  changeDate($event){
    this.dateReport = $event ; 
    this.load();
    
  }

  private clearColorInfo(colorArrayInfo) {
    const returnArray = [];
    for (let data of colorArrayInfo) {
      if (data && data.hasOwnProperty('date_fadd')) {
        data.date_fadd = Number(data.date_fadd.$date.$numberLong);
        returnArray.push(data);
      }
    }
    return returnArray;
  }

  private sanatizeRows() {
    this.rows.blue = this.clearColorInfo([...this.rows.blue]);
    this.rows.green = this.clearColorInfo([... this.rows.green]);
    this.rows.yellow = this.clearColorInfo([...this.rows.yellow]);
    this.rows.orange = this.clearColorInfo([...this.rows.orange]);
    this.rows.red = this.clearColorInfo([...this.rows.red]);
  }

  private load() {
    this.lastFilterUsed = 'DATE';
    this.helperService.showLoadingMxpro360();
    this.warehousesFaddJobsService
        .get(this.dateReport, this.filterStorageState)
        .then((response) => {
          this.rows = response;
          this.sanatizeRows();
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }
  public loadByColor(color) {
    this.lastFilterUsed = 'COLOR';
    this.colorFiltered = color;
    this.helperService.showLoadingMxpro360();
    this.warehousesFaddJobsService
        .getByColor(color, this.filterStorageState)
        .then((response) => {
          this.rows = response;
          this.driverInvoices = [];
          this.companyId = null;
          this.viewDriverInvoice = false;
          this.sanatizeRows();
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }

  public filterListByState() {
    if (this.lastFilterUsed == 'COLOR') {
      this.loadByColor(this.colorFiltered);
    } else {
      this.load();
    }
  }

  addRowDriverInvoice(job) {
   
    for (let i = this.driverInvoices.length - 1; i >= 0; i--) {
      if (this.driverInvoices[i] === job) {
          this.driverInvoices.splice(i, 1);
          return;
      }
    }

    this.driverInvoices.push(job);
    
  }

  createDriverInvoice(){
    if(!this.companyId){
      swal({
        title: 'Attention!',
        text: "Select Company Please",
        type: 'warning',
        padding: '2em'
      });
      return;
    }
    let dataForm= {
      items:this.driverInvoices, 
      company_id: this.companyId
    };
    this.helperService.showLoadingMxpro360();

    this.warehousesFaddJobsService
    .driverInvoicesFADD(dataForm)
    .then((response) => {
      window.open(`${window.location.origin}/#/workspace/${this.authService.workspaceSession.id}/driver-invoices/${response['row_id']}/edit`);
      this.driverInvoices = [];
      this.companyId = null;
      this.viewDriverInvoice = false;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }


    /**
     * Carga las Companies
     */
    private loadCompanies() {
      this.helperService.showLoadingMxpro360();
      this.companiesService
          .getAll()
          .then((response) => {
              this.companies = response;
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }


}
