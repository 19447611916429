<div class="layout-spacing layout-top-spacing">
  <div class="row">
    <div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 filtered-list-search layout-spacing align-self-center">
      <h3>Load Board</h3>
      <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a appWorkspaceRouterLink="/dashboard">
              <i class="fas fa-home"></i> Dashboard
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Load Board</li>
        </ol>
      </nav>
    </div>

    <div id="ct" class="note-container note-grid">

      <div class="note-item all-notes note-personal cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Meeting with Kelly">
              3596840
            </p>
            <p class="meta-time">11/25/2021</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes note-fav cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Receive Package">
              5596740
            </p>
            <p class="meta-time">11/26/2021</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes note-work cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Imporant Infomation">
              8596300
            </p>
            <p class="meta-time">11/04/2019</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Meeting Scheduled at 4:50pm">
              9396810
            </p>
            <p class="meta-time">11/08/2019</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Backup Files EOD">
              6596240
            </p>
            <p class="meta-time">11/09/2019</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes note-social cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Download Server Logs">
              5594940
            </p>
            <p class="meta-time">11/09/2019</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Team meet at Starbucks">
              1590340
            </p>
            <p class="meta-time">11/10/2019</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="note-item all-notes note-important cursor-pointer" (click)="showModalJob()">
        <div class="note-inner-content">
          <div class="note-content">
            <p class="note-title text-center" data-noteTitle="Create new users Profile">
              7597740
            </p>
            <p class="meta-time">11/10/2019</p>
            <div class="note-description-content">
              <div class="note-description"
                data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus.">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Origin: </strong>
                  CA, 93611
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"/><circle cx="12" cy="10" r="3"/></svg>
                  <strong class="ml-2">Destination: </strong>
                  AR, 71913
                </p>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"/><rect x="1" y="3" width="22" height="5"/><line x1="10" y1="12" x2="14" y2="12"/></svg>
                  <strong class="ml-2">cf: </strong>
                  831
                </p>
                <div class="text-right">
                  <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"/><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"/></svg>
                    3,097.03
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<!-- Modal Add Customer-->
<div class="modal fade register-modal" #jobModal tabindex="-1" role="dialog" aria-labelledby="companyTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="companyTitle">
                        Terms of Service
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg></button>
                </div>
                <div class="modal-body ">
                  <div class="term">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.

                  </div>
                  <br>
                  <div>Signature <strong color="danger">*</strong> </div>

                  <canvas canvas #sigPad width="450" height="150" class="borderB" (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"></canvas>


                </div>
                <div class="modal-footer text-center">

                </div>
            </form>
        </div>
    </div>
</div>
