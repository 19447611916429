import { Component, OnInit } from '@angular/core';
import { Automation } from 'src/app/entities/companies/automation';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { SmsTemplate } from 'src/app/entities/workspace/sms-template';
import { TicketType } from 'src/app/entities/workspace/ticket-type';
import { AutomationsService } from 'src/app/services/companies/automations.service';
import { EmailTemplatesService } from 'src/app/services/companies/email-templates.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { HelperService } from 'src/app/services/helper.service';
import { SmsTemplatesService } from 'src/app/services/workspaces/sms-templates.service';
declare var swal;
@Component({
  selector: 'app-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.scss']
})
export class AutomationsComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */

    automations: Array<Automation>;
    filterSearch: string;
    actualPage: number;
    emailTemplates: Array<EmailTemplate>;
    smsTemplates: Array<SmsTemplate>;
    ticketsTypes: Array<TicketType>;
   

    constructor(
        private automationsService: AutomationsService,
        private helperService: HelperService,
        private estimateEmailTemplatesService: EmailTemplatesService,
        private smsTemplatesService: SmsTemplatesService,
        private ticketsTypesService: TicketsTypesService
      ) {
       
        this.automations = [];
        this.filterSearch = '';
        this.actualPage = 1;
        this.emailTemplates = [];
        this.smsTemplates = [];
        this.ticketsTypes = [];
    }

    ngOnInit(): void {
      
    }

    ngAfterViewInit(): void{
        this.loadAutomations();
    }

    /**
     * Metodo que carga los tickets para mostrarlos en el select
     */
    private loadAutomations() {
        this.helperService.showLoadingMxpro360();
       
        this.automationsService
            .getAll()
            .then((response) => {
                this.automations = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.smsTemplatesService
            .getAll()
            .then((response) => {
                this.smsTemplates = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.estimateEmailTemplatesService
            .getAll()
            .then((response) => {
                this.emailTemplates = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.ticketsTypesService
            .getAll()
            .then((response) => {
                this.ticketsTypes = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

  
    
    deleteAutomation(id){
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.automationsService
                        .remove(id)
                        .then((response) => {
                            this.loadAutomations();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            }); 
    }
  

}
