import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { EstimateCustomerRemark } from "src/app/entities/workspace/estimate-customer-remark";
import { EstimateCustomerRemarkView } from "src/app/entities/workspace/estimate-customer-remark-view";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EstimatesCustomerRemarksService {


    constructor(private http: HttpClient) { }

    getAllWithFullView(idEstimate): Promise<Array<EstimateCustomerRemarkView>> {
        return this.http.get<Array<EstimateCustomerRemarkView>>(environment.api.company +
            '/estimates/' + idEstimate + '/customer-remarks', { params: { 'full-view': '1' } }).toPromise();
    }

    getAll(id: string): Promise<Array<EstimateCustomerRemark>> {
        return this.http.get<Array<EstimateCustomerRemark>>(environment.api.company + '/estimates/' + id + '/customer-remarks').toPromise();
    }

    add(id: string, dataForm: EstimateCustomerRemark) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/customer-remarks', dataForm).toPromise();
    }

    getAllSectionCustomer(id: string) {
        return this.http.get<Array<EstimateCustomerRemark>>(environment.api.customers +
            '/estimates/' + id + '/customer-remarks').toPromise();
    }

    addSectionCustomer(id: string, dataForm: EstimateCustomerRemark) {
        return this.http.post(environment.api.customers + '/estimates/' + id + '/customer-remarks', dataForm).toPromise();
    }

    getAllWithFullViewSectionCustomer(idEstimate): Promise<Array<EstimateCustomerRemarkView>> {
        return this.http.get<Array<EstimateCustomerRemarkView>>(environment.api.customers +
            '/estimates/' + idEstimate + '/customer-remarks', { params: { 'full-view': '1' } }).toPromise();
    }
}
