import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class WarehousesFaddJobsService {

    constructor(private http: HttpClient) { }

    get(report_date: string, filterStorageState = '') {
        let params:any = {params: { report_date }}
        if (filterStorageState !== '') {
            params =  {params: {report_date, storageState: filterStorageState}};
        }
        return this.http.get(environment.api.workspace + '/warehouses-fadd-jobs', params).toPromise();
    }

    getByColor(color: string, filterStorageState = '') {
        let params = {}
        if (filterStorageState !== '') {
            params =  {params: {storageState: filterStorageState}};
        }
        return this.http.get(environment.api.workspace + '/warehouses-fadd-jobs/' + color, params).toPromise();
    }

    
    driverInvoicesFADD(dataForm: any) {
        return this.http.post(environment.api.workspace + '/driver-invoices-fadd', dataForm).toPromise();
    }
}
