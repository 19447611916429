import { ConstantsMessages } from './../../../constants-messages';
import { Component, OnInit } from '@angular/core';
import { EmailCampaign } from '../../../entities/workspace/email-campaign';
import { EmailCampaignsService } from '../../../services/companies/email-campaigns.service';
import { HelperService } from '../../../services/helper.service';



declare const jQuery;

@Component({
  selector: 'app-email-campaigns',
  templateUrl: './email-campaigns.component.html',
  styleUrls: ['./email-campaigns.component.scss']
})
export class EmailCampaignsComponent implements OnInit {

  public actualPage;

  public campaigns: Array<EmailCampaign>;

  constructor(
    private emailCampaignsService: EmailCampaignsService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
  
  }

  ngAfterViewInit(): void{
    this.loadAllCampaigns();
    this.actualPage = 1;
  }

  public loadAllCampaigns(): void {
    this.helperService.showLoadingMxpro360();
    this.emailCampaignsService
      .getAllCampaigns()
      .then((response) => {
        this.campaigns = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public sendEmails(campaign: EmailCampaign): void {
    this.helperService.showLoadingMxpro360();
    this.emailCampaignsService
      .sendEmails(campaign)
      .then((response) => {
        this.helperService.showMessageSnackbar(response['message']);
        this.loadAllCampaigns();
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
