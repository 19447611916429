<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <h3>
                Export Leads
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Export Leads
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
            <div class="widget">
                <div class="widget-content">

                    <form>

                        <div class="form-group mb-4">
                            <label for="lead-service">Service</label>
                            <select class="form-control mb-2" name="lead-status" [(ngModel)]="filterData.service"
                                id="lead-service">
                                <option value="" selected="selected">Select All</option>
                                <option value="AUTO_TRANSPORT">Auto Transport</option>
                                <option value="MOVING">Moving</option>
                                <option value="LONG">Long Distance Moving</option>
                                <option value="LOCAL">Local Distance Moving</option>

                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Selecte Service.</small>
                        </div>
                        <div class="form-group mb-4">
                            <label for="lead-status">Status</label>
                            <select (ngModelChange)="listenChangeStatus()" class="form-control mb-2" name="lead-status" [(ngModel)]="filterData.status"
                                id="lead-status">
                                <option value="" selected="selected">Select All</option>
                                <option value="NEW">New</option>
                                <option value="IN_ESTIMATION">In Estimation</option>
                                <option value="DEAD_LEAD">Dead Lead</option>
                                <option value="BOOKED">Booked</option>
                                <option value="NOT_CONTACTED">Not Contacted</option>
                                <option value="UNSUCCESSFUL_SALE">Unsuccessful Sale</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Selecte Status.</small>
                        </div>
                        <div class="form-group mb-4" *ngIf="filterData.status == 'IN_ESTIMATION'">
                            <label for="lead-category">Category</label>
                            <select class="form-control mb-2" name="lead-category" [(ngModel)]="filterData.category"
                                id="lead-category">
                                <option value="" selected="selected">Select All</option>
                                <!-- <option value="SCHEDULED">Scheduled</option> -->
                                <option value="COLD">Cold</option>
                                <option value="HOT">Hot</option>
                                <option value="NEW">New</option>
                                <option value="OPEN">Open</option>
                                <option value="WARM">Warm</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Selecte category.</small>
                        </div>
                        <div class="form-group mb-4">
                            <label for="lead-status">From State</label>
                            <select class="form-control mb-2" name="from-state" [(ngModel)]="filterData.fromState"
                                id="from-state">
                                <option value="" selected="selected">Select All</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="NC">Carolina del Norte</option>
                                <option value="SC">Carolina del Sur</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="ND">Dakota del Norte</option>
                                <option value="SD">Dakota del Sur</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawái​</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Luisiana​</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Míchigan​</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Misisipi​</option>
                                <option value="MO">Misuri​</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NJ">Nueva Jersey​</option>
                                <option value="NY">Nueva York​</option>
                                <option value="NH">Nuevo Hampshire​</option>
                                <option value="NM">Nuevo México​</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregón</option>
                                <option value="PA">Pensilvania​</option>
                                <option value="RI">Rhode Island</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas​</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WV">Virginia Occidental</option>
                                <option value="WA">Washington</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Selecte Status.</small>
                        </div>
                        <div class="form-group mb-4">
                            <label for="lead-status">To State</label>
                            <select class="form-control mb-2" name="to-state" [(ngModel)]="filterData.toState"
                                id="to-state">
                                <option value="" selected="selected">Select All</option>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="NC">Carolina del Norte</option>
                                <option value="SC">Carolina del Sur</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="ND">Dakota del Norte</option>
                                <option value="SD">Dakota del Sur</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawái​</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Luisiana​</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Míchigan​</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Misisipi​</option>
                                <option value="MO">Misuri​</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NJ">Nueva Jersey​</option>
                                <option value="NY">Nueva York​</option>
                                <option value="NH">Nuevo Hampshire​</option>
                                <option value="NM">Nuevo México​</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregón</option>
                                <option value="PA">Pensilvania​</option>
                                <option value="RI">Rhode Island</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas​</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WV">Virginia Occidental</option>
                                <option value="WA">Washington</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Selecte Status.</small>
                        </div>
                        <div class="form-group mb-4" *ngIf="filterData.status !== 'NEW' ">
                            <label for="inlineFormInputGroup">
                                Employee
                            </label>
                            <select class="form-control mb-2" id="inlineFormInputGroup" placeholder="User"
                                name="employeeId" [(ngModel)]="filterData.employeeId">
                                <option value="" selected="selected">Select All</option>
                                <option *ngFor="let employee of employees" value="{{employee.id}}">
                                    {{employee.name}}
                                </option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Selecte Employee.</small>
                        </div>

                        <div class="form-group mb-4">
                            <div class="custom-control custom-radio classic-radio-info">
                                <input type="radio" id="hRadio1" name="classicRadio" class="custom-control-input"
                                    [(ngModel)]="filterData.selectDateType" value="OPEN">
                                <label class="custom-control-label" for="hRadio1">Open Date</label>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <div class="custom-control custom-radio classic-radio-info">
                                <input type="radio" id="hRadio2" name="classicRadio" class="custom-control-input"
                                    [(ngModel)]="filterData.selectDateType" value="MOVE">
                                <label class="custom-control-label" for="hRadio2">Move Date</label>
                            </div>
                        </div>
                        <div class="form-group mb-4 row">
                            <div class="col-md-6">
                                <label>
                                    Select date range Start
                                </label>
                                <app-date-picker [placeholder]="'Date Not Assigned'" [enableTime]="true"
                                    (onChange)="onChangeStart($event)" [value]="filterData.betweenFilter.dateStart">
                                </app-date-picker>
                            </div>
                            <div class="col-md-6">
                                <label>
                                    Select date range End
                                </label>
                                <app-date-picker [placeholder]="'Date Not Assigned'" [enableTime]="true"
                                    (onChange)="onChangeEnd($event)" [value]="filterData.betweenFilter.dateEnd">
                                </app-date-picker>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <div class="custom-control custom-checkbox classic-checkbox-info">
                                <input type="checkbox" id="check_export" name="check_export"
                                    class="custom-control-input" [(ngModel)]="filterData.export">
                                <label class="custom-control-label" for="check_export"> Download?</label>
                            </div>
                        </div>
                        <button type="button" (click)="validateFilter()" class="btn btn-success mt-3">
                            <i class="fas fa-download"></i> &nbsp; Submit
                        </button>
                    </form>

                </div>
            </div>
        </div>
    </div>
    
    <br>
    
    <div class="row" *ngIf="allEstimates.length > 0">
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>
    </div>


    <div class="row" *ngIf="allEstimates.length > 0">
        <div class="col-lg-12">
            <div class="widget-content searchable-container list">
                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content">
                            <div class="">
                                <h4>
                                    Customer
                                </h4>
                            </div>
                            <div class="user-email">
                                <h4>
                                    Service
                                </h4>
                            </div>
                            <div class="user-location">
                                <h4 style="margin-left: 0;">
                                    Description
                                </h4>
                            </div>
                            <div class="user-phone">
                                <h4 style="margin-left: 3px;">
                                    Options
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="items cursor-pointer"
                        *ngFor="let estimate of allEstimates |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                        <div class="item-content" [ngClass]="{'booked-active': estimate.booked}">
                            <div class="user-profile">
                                <div class="user-meta-info">
                                    <p class="user-name">{{estimate.customer.last_name}}</p>
                                    <p class="user-work">{{estimate.customer.name}}</p>
                                    <p class="user-work">{{estimate.customer.phone}}</p>
                                    <p class="user-work">{{estimate.customer.email}}</p>
                                </div>
                            </div>
                            <div class="user-email">
                                <p class="info-title">Service: </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.service}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Move Date: </strong>{{estimate.move_date | date}}
                                </p>
                                <p class="usr-email-addr" *ngIf="estimate.to.address">
                                    <strong>From: </strong>{{estimate.to.address.state}}
                                    {{estimate.to.address.zip_code}}
                                </p>
                                <p class="usr-email-addr" *ngIf="estimate.from.address">
                                    <strong>To: </strong>{{estimate.from.address.state}}
                                    {{estimate.from.address.zip_code}}
                                </p>
                            </div>
                            <div class="user-location">



                                <p class="usr-location" *ngIf="estimate.employee">
                                    <strong>Salesman: </strong>{{estimate.employee.name}}
                                </p>
                                <p class="usr-location">
                                    <strong>Estimated Date: </strong>{{estimate.created | date}}
                                </p>

                            </div>
                            <div class="user-phone">
                                <div *ngIf="estimate.type == 'LEAD'">
                                    Without to Quote
                                </div>
                                <div *ngIf="estimate.type == 'ESTIMATE'" (click)="openNewTab(estimate.id)">
                                    view Estimate
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>

                </div>
            </div>
        </div>
    </div>
</div>