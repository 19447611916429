import { DailyCrew } from './daily-crew';
import { Employee } from './employee';
import { JobView } from './job-view';

export class DailyCrewView extends DailyCrew{

    drivers: Array<Employee>;
    foreman: Employee;
    foreman_phone: string;
    workers: Array<Employee>;
    jobs: Array<JobView>;

    constructor() {
        super();
        this.drivers = [];
        this.foreman = null;
        this.foreman_phone = null
        this.workers = [];
        this.jobs = [];
    }

}

