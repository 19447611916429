<ng-container *ngIf="estimate.service == 'LOCAL' || estimate.service == 'LONG'">
    <!-- (ngSubmit)="save()" -->
    <form class="form" #estimateForm="ngForm" ngNativeValidate>

        <div class="row">

            <!-- Content -->
            <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 pr-0">

                <div class="user-profile info-lead layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class="justify-content-between widget-heading">
                            <h3 class="title-content-area">
                                Contacts
                            </h3>

                            <a title="Edit Contact" appCanUserCompany="ESTIMATE_CONTACT_EDIT" class="tooltipmxp btn-call-action btn-g-action"
                                (click)='openModalLead()' [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>

                            <a title="Add New Contact" appCanUserCompany="ESTIMATE_CONTACT_CREATE" class="tooltipmxp btn-call-action btn-b-action"
                                (click)='openNewContactsModal()' [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-plus"></i>
                            </a>
                        </div>
                        <div class="text-center user-info" style="margin: 0;">
                            <!-- <img src="assets/img/90x90.jpg" alt="avatar"> -->
                            <p>
                                {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                            </p>
                        </div>
                        <div class="user-info-list">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="contacts-block list-unstyled">

                                            <li class="contacts-block__item contact-info-block">
                                                <i class="far fa-copy p-2 copy-text-action" (click)="copyText(customer.phone, 'text');$event.stopPropagation()"></i>
                                                <a href="tel:{{customer.phone}}" class="c-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-phone">
                                                        <path
                                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                        </path>
                                                    </svg>
                                                    <span>{{customer.phone | phone:'US'}}</span>
                                                </a>
                                                <div class="btn-sms" (click)="openModalSendTextMessage()">
                                                    <i class="fa-solid fa-comment-sms"></i>
                                                </div>
                                            </li>

                                            <li class="contacts-block__item contact-info-block">
                                                <i class="far fa-copy p-2 copy-text-action" (click)="copyText(customer.email, 'text');$event.stopPropagation()"></i>
                                                <a class="c-pointer" appCompanyRouterLink="/moving/{{estimate.id}}/emails">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-mail">
                                                        <path
                                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                        </path>
                                                        <polyline points="22,6 12,13 2,6"></polyline>
                                                    </svg>
                                                    <span>{{customer.email}}</span>
                                                </a>
                                            </li>

                                            <li class="contacts-block__item remark-block" *ngIf="customer.remarks">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-message-circle">
                                                    <path
                                                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                                                    </path>
                                                </svg>
                                                <span>{{customer.remarks}}</span>
                                            </li>

                                            <li class="contacts-block__item" *ngIf="advertiser.name">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-rss">
                                                    <path d="M4 11a9 9 0 0 1 9 9"></path>
                                                    <path d="M4 4a16 16 0 0 1 16 16"></path>
                                                    <circle cx="5" cy="19" r="1"></circle>
                                                </svg>
                                                <span>{{advertiser.name}}</span>
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                                <ng-container *ngFor="let contact of estimate.contacts; let i = index">

                                    <hr>

                                    <div class="col-md-12">
                                        <ul class="contacts-block list-unstyled">

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-users">
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                                {{contact.name | titlecase}} {{contact.last_name | titlecase}}
                                            </li>

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-mail">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                    </path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg>
                                                {{contact.email | titlecase}}
                                            </li>

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-phone">
                                                    <path
                                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                    </path>
                                                </svg>
                                                {{contact.phone | phone:'US' }}
                                            </li>

                                            <a class="btn-call-action delete-btn tooltipmxp" title="Delete Contact"
                                                (click)='removeContact(contact)' appCanUserCompany="ESTIMATE_CONTACT_CREATE"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-trash"></i>
                                            </a>

                                        </ul>
                                    </div>

                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Fees Information
                            </h3>
                            <a title="Save Fees Information" (click)="saveInfoFees()" appCanUserCompany="ESTIMATE_SET_TARIFF" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <ng-container *ngIf="estimate.service === 'LONG'">
                                        <li class="contacts-block__item only-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-download">
                                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                <polyline points="7 10 12 15 17 10"></polyline>
                                                <line x1="12" y1="15" x2="12" y2="3"></line>
                                            </svg>
                                            <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Lbs: </strong>
                                            <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Cubic Feet: </strong>
                                            <input class="form-control estimate-details-input-fees-info" type="number" [ngClass]="selledFeets"
                                                [(ngModel)]="estimate.volume.libs" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                name="wp_cu_lbs" />
                                            <label *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">/ {{ (estimate.volume.cubic_feets * 7) | number }}</label>
                                            <label *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">/ {{ (estimate.volume.cubic_feets / 7) | number }}</label>
                                        </li>
                                    </ng-container>


                                    <ng-container *ngIf="estimate.service === 'LONG' || estimate.service === 'LOCAL'">
                                        <li class="contacts-block__item only-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-layers">
                                                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                <polyline points="2 17 12 22 22 17"></polyline>
                                                <polyline points="2 12 12 17 22 12"></polyline>
                                            </svg>
                                            <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cubic Feet: </strong>
                                            <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                            <input class="form-control estimate-details-input-fees-info" type="number" [ngClass]="selledFeets"
                                                [(ngModel)]="estimate.volume.cubic_feets_selled"
                                                [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" name="wp_cu_fee" />
                                            <label>/ {{estimate.volume.cubic_feets}}</label>
                                        </li>

                                        <li class="contacts-block__item only-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-layers">
                                                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                <polyline points="2 17 12 22 22 17"></polyline>
                                                <polyline points="2 12 12 17 22 12"></polyline>
                                            </svg>
                                            <strong>Min
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                                Cubic Feet
                                            </span>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs</span>
                                            : </strong>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                                {{estimate.estimate_route_tariff.min_cf | number : '1.2-2'}}
                                            </span>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">
                                                {{(estimate.estimate_route_tariff.min_cf * cfLbsRatio) | number : '1.2-2'}}
                                            </span>

                                        </li>

                                    </ng-container>

                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-git-pull-request">
                                            <circle cx="18" cy="18" r="3"></circle>
                                            <circle cx="6" cy="6" r="3"></circle>
                                            <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
                                            <line x1="6" y1="9" x2="6" y2="21"></line>
                                        </svg>
                                        <strong>Miles:</strong>
                                        {{estimate.volume.miles | number : '1.2-2'}}
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-clock">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="12 6 12 12 16 14"></polyline>
                                        </svg>
                                        <strong>Duration:</strong>
                                        <span *ngIf="estimate.volume.travel_time >= 1">{{estimate.volume.travel_time | number : '1.2-2'}} Hours</span>
                                        <span *ngIf="estimate.volume.travel_time < 1">{{estimate.volume.travel_time * 60 | number : '1.2-2'}} Minutes</span>
                                    </li>
                                    <li class="contacts-block__item only-text" *ngIf="workspaceSettings.show_weight_local_estimate_pdf && estimate.service === 'LOCAL'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-package">
                                            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2
                                            2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <strong>Estimated Weight:</strong>
                                        <span *ngIf="estimate.volume.libs > 0">{{estimate.volume.libs | number : '1.2-2'}} Lbs</span>
                                        <span *ngIf="estimate.volume.libs == 0">{{estimate.volume.cubic_feets * 7 | number : '1.2-2'}} Lbs</span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Basic Information
                            </h3>
                            <a (click)="saveInfoBasic()" title="Save Basic Information" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item has-checkbox only-text" *ngIf="estimate.service === 'LONG'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-clipboard">
                                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                        </svg>
                                        <strong>Inventory Editable By Customer:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" class="new-control-input"
                                                (ngModelChange)="adviceChanges()" name="inventory_editable_by_customer"
                                                [(ngModel)]="estimate.inventory_editable_by_customer" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                        <strong>Service Type:</strong>
                                        <select class="form-control estimate-details-select" name="service_type" id="service_type" [(ngModel)]="estimate.service"
                                            (ngModelChange)="adviceChanges()" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option value="LOCAL">Local</option>
                                            <option value="LONG">Long</option>
                                        </select>
                                    </li>

                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-link">
                                            <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71">
                                            </path>
                                            <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71">
                                            </path>
                                        </svg>
                                        <strong>Binding Type: </strong>
                                        <select class="form-control estimate-details-select" name="binding_type" id="binding_type" (ngModelChange)="adviceChanges()"
                                            [(ngModel)]="estimate.binding_type" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option value="NO_EXCEED">Not to Exceed</option>
                                            <option value="BINDING">Binding</option>
                                            <option value="NO_BINDING">Non-Binding</option>
                                        </select>
                                    </li>

                                    <li class="contacts-block__item has-checkbox only-text mt-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-check-square">
                                            <polyline points="9 11 12 14 22 4"></polyline>
                                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                        </svg>
                                        <strong>Pre Pickup:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" class="new-control-input" id="pre_pickup" name="pre_pickup" (click)="checkboxPrePickup()"
                                                [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"/>
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li>

                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail" *ngIf="prePickup">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Box's Deld:</strong>
                                        <app-date-picker [value]="estimate.pickup.boxes_delivery_day"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" [placeholder]="'Not Assigned'" theme="WIDGET"
                                            (onChange)="changeBoxsDeld($event)">
                                        </app-date-picker>
                                    </li>
                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail" *ngIf="prePickup">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Pack Day:</strong>
                                        <app-date-picker [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" [value]="estimate.pickup.pack_day"
                                            [placeholder]="'Not Assigned'" theme="WIDGET"
                                            (onChange)="changePackDay($event)">
                                        </app-date-picker>
                                    </li>

                                </ul>

                                <hr>

                                <ul class="contacts-block list-unstyled">

                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail" *ngIf="enableblackoutMoveDate">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Pick Up:</strong>
                                        <app-date-range-picker [placeholder]="'Not Assigned'"
                                            [disabledDates]="blackoutMoveDates"
                                            (onChange)="changePickUp($event)" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                            [valueStart]="estimate.pickup.range_start"
                                            [valueEnd]="estimate.pickup.range_end">
                                        </app-date-range-picker>
                                    </li>
                                    <li class="contacts-block__item has-checkbox only-text mt-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-check-square">
                                            <polyline points="9 11 12 14 22 4"></polyline>
                                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                        </svg>
                                        <strong>Use Time Frame:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" (ngModelChange)="adviceChanges()"
                                                class="new-control-input" name="deliverUseTimeFrame" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [(ngModel)]="estimate.pickup.use_time_frame" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li>
                                    <li class="contacts-block__item only-text" *ngIf="estimate.pickup.use_time_frame">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-clock">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="12 6 12 12 16 14"></polyline>
                                        </svg>
                                        <strong>Time Frame:</strong>
                                        <div class="input-time-frame">
                                            <input type="time" (ngModelChange)="adviceChanges()" onfocus="this.showPicker()"
                                                class="form-control estimate-details-input c-pointer" name="deliverTimeFrameStart" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [(ngModel)]="estimate.pickup.time_frame_start" />

                                            <input type="time" (ngModelChange)="adviceChanges()" onfocus="this.showPicker()"
                                                class="form-control estimate-details-input mt-2 c-pointer" name="deliverTimeFrameEnd" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [(ngModel)]="estimate.pickup.time_frame_end" />
                                        </div>
                                    </li>

                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>First Day Available for Delivery:</strong>
                                        <div *ngIf="rangeDate">
                                            <app-date-range-picker [placeholder]="'Not Assigned'"
                                                (onChange)="changeRequestDelivery($event)" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [valueStart]="estimate.delivery.range_start"
                                                [valueEnd]="estimate.delivery.range_end">
                                            </app-date-range-picker>
                                        </div>
                                        <ng-container *ngIf="!rangeDate">
                                            <app-date-picker [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [value]="estimate.delivery.range_start" [placeholder]="'Not Assigned'"
                                                theme="WIDGET" (onChange)="changeDeliveryDate($event)">
                                            </app-date-picker>
                                        </ng-container>
                                    </li>

                                </ul>

                                <hr>

                                <ul class="contacts-block list-unstyled ">
                                    <!-- <li class="contacts-block__item has-checkbox only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-package">
                                            <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                                            <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2
                                            2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                                            <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                            <line x1="12" y1="22.08" x2="12" y2="12"></line>
                                        </svg>
                                        <strong>Deliver Immediately:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" (ngModelChange)="adviceChanges()"
                                                class="new-control-input" name="deliverImmediately" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [(ngModel)]="estimate.delivery.deliver_immediately" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li> -->
                                    <!-- <li class="contacts-block__item only-text has-checkbox">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-check-square">
                                            <polyline points="9 11 12 14 22 4"></polyline>
                                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                        </svg>
                                        <strong>Use Date Range for FADD:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" (ngModelChange)="adviceChanges()"
                                                class="new-control-input" name="rangeDate" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [(ngModel)]="rangeDate" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li> -->
                                </ul>

                                <ul class="contacts-block list-unstyled">
                                    <!-- <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-briefcase">
                                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                        </svg>
                                        <strong>Job Number:</strong> {{estimate.document.code}}
                                    </li> -->
                                    <!-- <li class="contacts-block__item only-text position-relative" *ngIf="estimate.broker_id == null">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-award">
                                            <circle cx="12" cy="8" r="7"></circle>
                                            <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                        </svg>
                                        <strong>Reference:</strong>
                                        <input class="sin-borde color-reference form-control estimate-details-input" placeholder="Enter Reference."
                                            name="wp_reference_nb" type="text" [(ngModel)]="estimate.reference" disabled/>
                                        <a (click)="editEstimateReference()" class="btn btn-success btn-edit-reference tooltipmxp" title="Edit Reference">
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </a>
                                    </li> -->

                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-archive">
                                            <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                            <rect x="1" y="3" width="22" height="5"></rect>
                                            <line x1="10" y1="12" x2="14" y2="12"></line>
                                        </svg>
                                        <strong>Move Size: </strong>
                                        <select class="form-control estimate-details-select" name="move_size" (ngModelChange)="adviceChanges()" id="move_size" [(ngModel)]="estimate.volume.move_size"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" Required>
                                            <option value="STUDIO">Studio</option>
                                            <option value="LARGE_STUDIO">Large Studio</option>
                                            <option value="ONE_BEDROOM">One Bedroom</option>
                                            <option value="TWO_BEDROOM">Two Bedrooms</option>
                                            <option value="THREE_BEDROOM">Three Bedrooms</option>
                                            <option value="FOUR_BEDROOM">Four Bedrooms</option>
                                            <option value="FIVE_BEDROOM">Five Bedrooms</option>
                                            <option value="SIX_BEDROOM">Six Bedrooms</option>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                        <strong>Move Type:</strong>
                                        <select class="form-control estimate-details-select" name="move_type" id="move_type" [(ngModel)]="estimate.move_type"
                                            (ngModelChange)="adviceChanges()" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option value="RESIDENTIAL">Residential</option>
                                            <option value="COMMERCIAL">Commercial</option>
                                            <option value="MILITARY">Military</option>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-clipboard">
                                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                        <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                                    </svg>
                                        <strong>Visual Type::</strong>
                                        <select class="form-control estimate-details-select" name="visual_type" id="visual_type" [(ngModel)]="estimate.visual_type"
                                            (ngModelChange)="adviceChanges()" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option value="ON_SITE">On-Site</option>
                                            <option value="VIRTUAL">Virtual</option>
                                            <option value="WAIVED">Waived</option>
                                            <option value="PHOTOS">Photos</option>
                                        </select>
                                    </li>

                                    <!-- <li class="contacts-block__item has-checkbox only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-zap">
                                            <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                                        </svg>
                                        <strong>Urgent: </strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" class="new-control-input" name="urgent"
                                                [(ngModel)]="estimate.urgent" (ngModelChange)="adviceChanges()"
                                                [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li> -->

                                    <!-- <li class="contacts-block__item has-checkbox only-text" *ngIf="estimate.booked">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-check-square">
                                            <polyline points="9 11 12 14 22 4"></polyline>
                                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                        </svg>
                                        <strong>Confirmed: </strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" (ngModelChange)="adviceChanges()"
                                                class="new-control-input" name="confirmed"
                                                [(ngModel)]="estimate.confirmed" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li> -->

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            <div class="user-profile info-basic layout-spacing" *ngIf="estimate.broker_id == null">
                <div class="widget-content widget-content-area">
                    <div class=" widget-heading">
                        <h3 class="title-content-area">
                            Max Discount Information
                        </h3>
                        <a title="Save Max Discount Information" (click)="saveCustomDiscount()" [appCanUserCompany]="['ESTIMATE_SET_DISCOUNTS','ESTIMATE_MANAGE_DISCOUNTS']" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                            class="tooltipmxp btn-call-action">
                            <i class="fa-solid fa-floppy-disk"></i>
                        </a>
                    </div>
                    <div class="user-info-list">
                        <div class="marg-t30">
                            <ul class="contacts-block list-unstyled">
                                <ng-container>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-percent">
                                            <line x1="19" y1="5" x2="5" y2="19"></line>
                                            <circle cx="6.5" cy="6.5" r="2.5"></circle>
                                            <circle cx="17.5" cy="17.5" r="2.5"></circle>
                                        </svg>
                                        <strong>Max. Discount:</strong> ${{maxDiscount | number : '1.2-2'}}
                                        <a (click)="changeMaxDiscount()" class="btn btn-success btn-max-discount cursor-pointer tooltipmxp"
                                            title="Edit Max Discount" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                            appCanUserCompany="ESTIMATE_MANAGE_DISCOUNTS">
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

                <div class="user-profile layout-spacing">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Electronic Signature
                            </h3>
                        </div>

                        <div class="electronic-signature">

                            <div class="signature-image" *ngIf="estimate.esignature">
                                <img [appCdnLink]="'/e-signatures/' + estimate.esignature.esignature_file.display_name"
                                    alt="esignature" class="e-signature-img">
                            </div>
                            <div class="signature-image" *ngIf="!estimate.esignature">
                                <img src="/assets/img/unsigned.png" alt="esignature" width="170px">
                            </div>

                            <div class="user-info-list signature-info" *ngIf="estimate.esignature">
                                <ul class="contacts-block list-unstyled m-0">
                                    <li class="contacts-block__item">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="2" y1="12" x2="22" y2="12"></line>
                                            <path
                                                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                            </path>
                                        </svg>{{estimate.esignature.internet_browser}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>{{estimate.esignature.created | dateTime}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                                        </svg>{{estimate.esignature.ip_address}}
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing"  >
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Employees
                            </h3>
                            <a (click)="saveUsers()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Save Employees" appCanUserCompany="ESTIMATE_SET_EMPLOYEES"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <!-- <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-trello">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <rect x="7" y="7" width="3" height="9"></rect>
                                            <rect x="14" y="7" width="3" height="5"></rect>
                                        </svg>
                                        <strong>Work Depart:</strong>
                                        <select name="operator_work_department_id" id="operator_work_department_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.work_department_id"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let workDepartment of workDepartments">
                                                <option value="{{workDepartment.id}}">
                                                    {{workDepartment.name | titlecase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li> -->
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <i class="fas fa-user-tie salesman-icon" width="23" height="23"></i>
                                        <strong>Salesman:</strong>
                                        <select name="operator_representative_id" id="operator_representative_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.salesman_id" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let user of employees">
                                                <option value="{{user.id}}">
                                                    {{user.name | filterReplace}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <strong>Operator:</strong>
                                        <select name="operator_user_id" id="operator_user_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.operator_id" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let user of employees">
                                                <option value="{{user.id}}">
                                                    {{user.name | lowercase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="user-profile info-basic layout-spacing" *ngIf="estimate.broker_id == null">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Category
                            </h3>
                            <a *ngIf="estimate.category != 'NEW'" (click)="saveCategory()"
                                title="Save Basic Information" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-save">
                                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                                    <polyline points="17 21 17 13 7 13 7 21"></polyline>
                                    <polyline points="7 3 7 8 15 8"></polyline>
                                </svg>
                            </a>
                            <div *ngIf="estimate.category == 'NEW'"
                                class="open-category-alert tooltipCategory tooltipmxp"
                                title="You must to assign a new category to this job">
                                <button class="button">!</button>
                            </div>


                        </div>
                        <div class="user-info-list">
                            <div>
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-book">
                                            <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                                            <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z">
                                            </path>
                                        </svg>
                                        <strong>Category:</strong>
                                        <select name="category" class="form-control estimate-details-select" id="category" [(ngModel)]="estimate.category"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option [selected]="estimate.category == 'NEW'" value="NEW">New</option>
                                            <option [selected]="estimate.category == 'HOT'" value="HOT">Hot</option>
                                            <option [selected]="estimate.category == 'WARM'" value="WARM">Warm</option>
                                            <option [selected]="estimate.category == 'COLD'" value="COLD">Cold</option>
                                            <option [selected]="estimate.category == 'OPEN'" value="OPEN">Open</option>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-tag">
                                            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                            <line x1="7" y1="7" x2="7.01" y2="7"></line>
                                        </svg>
                                        <strong>Status:</strong>
                                        <span [ngSwitch]="estimate.status">
                                            <ng-container *ngSwitchCase="'FOLLOW_UP'">
                                                Follow Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'UNSUCCESSFUL_SALE'">
                                                Unsuccessful Sale
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_OPERATION'">
                                                In Operation
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LOADED_FOR_STORAGE'">
                                                Loaded For Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LOADED_FOR_DELIVERY'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{estimate.status}}
                                            </ng-container>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Lead
                            </h3>
                            <a (click)="saveLeads()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Save Employees"
                            class="tooltipmxp btn-call-action">
                            <i class="fa-solid fa-floppy-disk"></i>
                        </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled lead-info">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Date Lead Arrived: </strong>
                                        <span *ngIf="estimate.lead_company">
                                            {{ estimate.lead_company.created | dateTime }}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Creation Date In Estimate: </strong>
                                        <span>
                                            {{ estimate.created | dateTime }}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-cloud">
                                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                                        </svg>
                                        <strong>Lead Provider: </strong>
                                        <select id="advertiser"  [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                            [(ngModel)]="advertiser_id" name="advertiser_id"
                                            class="form-control estimate-details-select">
                                            <option value="">Select...</option>
                                            <option *ngFor="let rowAdvertiser of advertisers" [value]="rowAdvertiser.id">{{rowAdvertiser.name}}</option>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text booked-date" *ngIf="estimate.booked_date">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-airplay">
                                            <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                            <polygon points="12 15 17 21 7 21 12 15"></polygon>
                                        </svg>
                                        <strong>Booked Date</strong>
                                        <span>{{estimate.booked_date | date}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Direction / Operation Details -->
                <div class="layout-spacing ">
                    <div class="widget widget-text">
                        <div class="widget-content">

                            <div class=" widget-heading">
                                <h3 class="title-content-area">
                                    Direction / Operation Details
                                </h3>
                                <a (click)="saveOperationDetails()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                    title="Direction / Operation Details" class="tooltipmxp btn-call-action">
                                    <i class="fa-solid fa-floppy-disk"></i>
                                </a>
                            </div>

                            <div class="widget-body">
                                <textarea name="operation_details" id="operation_details"
                                    [(ngModel)]="estimate.operation_details" placeholder="Write an Update"></textarea>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12">

                <div class="user-profile info-moving layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Moving
                            </h3>
                            <div class="moving-from-to">
                                <span>
                                    <strong>From: </strong>
                                    {{from}}
                                </span>
                                <span>
                                    <strong>To:</strong>
                                    {{to}}
                                </span>
                            </div>
                            <a title="Adding Stop Point" class="tooltipmxp btn-call-action btn-p-action" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                data-toggle="modal" (click)="openStopPointModal()" appCanUserCompany="SET_ADDITIONAL_STOPS" >
                                <i class="fa-solid fa-location-dot"></i>
                            </a>

                            <a title="Show Route on Map" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal"
                                data-target="#modalMap" (click)="initializeMap()">
                                <i class="fa-solid fa-map"></i>
                            </a>

                            <a title="Edit Moving From and To" href="#" appCanUserCompany="ESTIMATE_UPDATE_ADDRESSES" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action btn-g-action" data-toggle="modal" (click)="openModalChangeAddress()">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>
                        </div>
                        <br>
                        <div class="row">

                            <div class="col-12 col-xl-6 col-lg-12">
                                <h5 class="title-moving-from">
                                    From
                                </h5>

                                <div class="row moving-from">

                                    <!-- <div class="col-12 col-28k-4" *ngIf="estimate.pickup.address.street">
                                        <strong>Street:</strong> {{estimate.pickup.address.street | titlecase}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-6 col-2000k-6" *ngIf="estimate.pickup.address.city">
                                        <strong>City:</strong> {{estimate.pickup.address.city | titlecase}}
                                    </div>

                                    <div class="col-12 col-28k-2 col-2k-6 col-24k-3 col-2000k-6" *ngIf="estimate.pickup.address.state">
                                        <strong>State:</strong> {{estimate.pickup.address.state | convertState | async}}
                                    </div>

                                    <div class="col-12 col-2k-12 col-28k-3 col-24k-3" *ngIf="estimate.pickup.address.zip_code">
                                        <strong>Zip Code:</strong> {{estimate.pickup.address.zip_code}}
                                    </div>

                                    <div class="col-12 col-28k-4 col-2k-6 col-24k-6" *ngIf="estimate.pickup.address.property_type">
                                        <strong>Property Type:</strong> {{estimate.pickup.address.property_type}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-3" *ngIf="estimate.pickup.address.level">
                                        <strong>Level:</strong> {{estimate.pickup.address.level}}
                                    </div>

                                    <div class="col-12 col-28k-2 col-2k-6 col-24k-3" *ngIf="estimate.pickup.address.floor">
                                        <strong>Floor:</strong> {{estimate.pickup.address.floor}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-6" *ngIf="estimate.pickup.address.apartment_number">
                                        <strong>Apartment #:</strong> {{estimate.pickup.address.apartment_number}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-6" *ngIf="estimate.pickup.address.storage_unit_number">
                                        <strong>Unit Number:</strong> {{estimate.pickup.address.storage_unit_number}}
                                    </div> -->

                                    <div class="col-12">
                                        <span *ngIf="estimate.pickup.address.street">
                                            {{estimate.pickup.address.street | titlecase}}
                                        </span>
                                        <span *ngIf="estimate.pickup.address.city">
                                            {{estimate.pickup.address.city | titlecase}},
                                        </span>
                                        <span *ngIf="estimate.pickup.address.state">
                                            {{estimate.pickup.address.state | convertState | async}},
                                        </span>
                                        <span *ngIf="estimate.pickup.address.zip_code">
                                            {{estimate.pickup.address.zip_code}}
                                        </span>
                                        <span *ngIf="estimate.pickup.address.property_type">
                                            {{estimate.pickup.address.property_type}}
                                        </span>
                                        <span *ngIf="estimate.pickup.address.level">
                                            {{estimate.pickup.address.level}}
                                        </span>
                                        <span *ngIf="estimate.pickup.address.floor">
                                            <strong>Floor:</strong> {{estimate.pickup.address.floor}}
                                        </span>
                                        <span *ngIf="estimate.pickup.address.apartment_number">
                                            <strong>Apartment #:</strong> {{estimate.pickup.address.apartment_number}}
                                        </span>
                                        <span *ngIf="estimate.pickup.address.storage_unit_number">
                                            <strong>Unit Number:</strong> {{estimate.pickup.address.storage_unit_number}}
                                        </span>
                                    </div>

                                </div>

                            </div>

                            <div class="col-12 col-xl-6 col-lg-12">
                                <h5 class="title-moving-to">
                                    To
                                </h5>

                                <div class="row moving-to">

                                    <!-- <div class="col-12 col-28k-4" *ngIf="estimate.delivery.address.street">
                                        <strong>Street:</strong> {{estimate.delivery.address.street | titlecase}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-6 col-2000k-6" *ngIf="estimate.delivery.address.city">
                                        <strong>City:</strong> {{estimate.delivery.address.city | titlecase}}
                                    </div>

                                    <div class="col-12 col-28k-2 col-2k-6 col-24k-3 col-2000k-6" *ngIf="estimate.delivery.address.state">
                                        <strong>State:</strong> {{estimate.delivery.address.state | convertState | async}}
                                    </div>

                                    <div class="col-12 col-2k-12 col-28k-3 col-24k-3" *ngIf="estimate.delivery.address.zip_code">
                                        <strong>Zip Code:</strong> {{estimate.delivery.address.zip_code}}
                                    </div>

                                    <div class="col-12 col-28k-4 col-2k-6 col-24k-6" *ngIf="estimate.delivery.address.property_type">
                                        <strong>Property Type:</strong> {{estimate.delivery.address.property_type}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-3" *ngIf="estimate.delivery.address.level">
                                        <strong>Level:</strong> {{estimate.delivery.address.level}}
                                    </div>

                                    <div class="col-12 col-28k-2 col-2k-6 col-24k-3" *ngIf="estimate.delivery.address.floor">
                                        <strong>Floor:</strong> {{estimate.delivery.address.floor}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-6" *ngIf="estimate.delivery.address.apartment_number">
                                        <strong>Apartment #:</strong> {{estimate.delivery.address.apartment_number}}
                                    </div>

                                    <div class="col-12 col-28k-3 col-2k-6 col-24k-6" *ngIf="estimate.delivery.address.storage_unit_number">
                                        <strong>Unit Number:</strong> {{estimate.delivery.address.storage_unit_number}}
                                    </div> -->

                                    <div class="col-12">
                                        <span *ngIf="estimate.delivery.address.street">
                                            {{estimate.delivery.address.street | titlecase}}
                                        </span>
                                        <span *ngIf="estimate.delivery.address.city">
                                            {{estimate.delivery.address.city | titlecase}},
                                        </span>
                                        <span *ngIf="estimate.delivery.address.state">
                                            {{estimate.delivery.address.state | convertState | async}},
                                        </span>
                                        <span *ngIf="estimate.delivery.address.zip_code">
                                            {{estimate.delivery.address.zip_code}}
                                        </span>
                                        <span *ngIf="estimate.delivery.address.property_type">
                                            {{estimate.delivery.address.property_type}}
                                        </span>
                                        <span *ngIf="estimate.delivery.address.level">
                                            {{estimate.delivery.address.level}}
                                        </span>
                                        <span *ngIf="estimate.delivery.address.floor">
                                            <strong>Floor:</strong> {{estimate.delivery.address.floor}}
                                        </span>
                                        <span *ngIf="estimate.delivery.address.apartment_number">
                                            <strong>Apartment #:</strong> {{estimate.delivery.address.apartment_number}}
                                        </span>
                                        <span *ngIf="estimate.delivery.address.storage_unit_number">
                                            <strong>Unit Number:</strong> {{estimate.delivery.address.storage_unit_number}}
                                        </span>
                                    </div>

                                </div>


                            </div>

                        </div>

                        <br *ngIf="stopPoints.length > 0">

                        <div class=" widget-heading" *ngIf="stopPoints.length > 0">
                            <h3 class="title-content-area">
                                Stop Points
                            </h3>
                        </div>

                        <br>

                        <div class="row" *ngIf="stopPoints.length > 0">
                            <div class="col-12 col-xl-6 col-lg-12" *ngFor="let stopPoint of stopPoints">

                                <div class="row mb-3">
                                    <h6 class="text-center m-auto"><strong>{{stopPoint.type}}</strong></h6>
                                    <a class="btn-call-action delete-btn tooltipmxp" title="Delete Stop"
                                        (click)="removeStopPoint(stopPoint)" appCanUserCompany="SET_ADDITIONAL_STOPS" type="button"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                        <i class="fa-solid fa-trash"></i>
                                    </a>
                                </div>

                                <div class="row moving-stop-point">

                                    <div class="col-12" *ngIf="stopPoint.address.street">
                                        <strong>Street: </strong> {{stopPoint.address.street}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.city">
                                        <strong>City: </strong> {{stopPoint.address.city}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.state">
                                        <strong>State: </strong> {{stopPoint.address.state | convertState | async}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.zip_code">
                                        <strong>Zipcode: </strong> {{stopPoint.address.zip_code}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.property_type">
                                        <strong>Property type: </strong> {{stopPoint.address.property_type}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.level">
                                        <strong>Level: </strong> {{stopPoint.address.level}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.floor">
                                        <strong>Floor: </strong> {{stopPoint.address.floor}}
                                    </div>

                                    <div class="col-12 col-2k-6 col-24k-4" *ngIf="stopPoint.address.apartment_number">
                                        <strong># Apartment: </strong> {{stopPoint.address.apartment_number}}
                                    </div>

                                </div>

                                <br>

                                <div class="row col-12">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="skills layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Charges
                            </h3>
                            <div class="btn-group dropleft height-dropleft btn-dropdown-options" role="group" style="float: right;">
                                <button id="btnOutline" type="button" class="btn btn-secondary btn-options btn-lg dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-down">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                    &nbsp; Options
                                </button>
                                <div class="dropdown-menu" aria-labelledby="btnOutline" style="will-change: transform;">

                                    <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_SERVICES" class="dropdown-item" data-toggle="modal"
                                        (click)='openModalAdditionalService("CHARGE")' *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                        <i class="fa-solid fa-gear"></i> Service
                                    </a>

                                    <a href="javascript:void(0);" [appCanUserCompany]="['ESTIMATE_SET_DISCOUNTS', 'ESTIMATE_MANAGE_DISCOUNTS']"
                                        class="dropdown-item" data-toggle="modal" (click)='openModalAdditionalService("DISCOUNT")' *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                        <i class="fa-solid fa-circle-minus"></i> Discount
                                    </a>

                                    <a href="javascript:void(0);"
                                        appCanUserCompany="ESTIMATE_SET_TARIFF" class="dropdown-item" (click)='openModalSetTariffDiscount()'
                                        *ngIf="(!estimate.booked || estimate.qa_status === 'RELEASE') && estimate.service != 'LOCAL'">
                                        <i class="fa-solid fa-tags"></i> Tariff Discount
                                    </a>

                                    <a appCanUserCompany="ESTIMATE_SET_MATERIALS" appCompanyRouterLink="/moving/{{estimate.id}}/packing-materials"
                                        *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item"  >
                                        <i class="fa-solid fa-cube"></i> Materials
                                    </a>

                                    <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_PACKERS" data-toggle="modal" data-target="#storage"
                                        *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item"  >
                                        <i class="fa-solid fa-warehouse"></i> Storage
                                    </a>

                                    <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_HGG_SET_AUTOTRANSPORT" (click)="openAutoTransportModal(false)"
                                        *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item"  >
                                        <i class="fa-solid fa-truck"></i> Auto Transport
                                    </a>

                                    <a href="javascript:void(0);" (click)="downloadPdf()" class="dropdown-item">
                                        <i class="fas fa-file-pdf"></i> View Estimate PDF
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" (click)="openDuplicateLeadModal()" appCanUserCompany="ESTIMATE_DUPLICATE" >
                                        <i class="fa-solid fa-copy"></i> Duplicate
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)="unsuccessfulSale()">
                                        <i class="fas fa-handshake-slash"></i> Unsuccessful Sale
                                    </a>

                                    <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_SERVICES" class="dropdown-item" data-toggle="modal"
                                        *ngIf="(!estimate.booked || estimate.qa_status === 'RELEASE') && estimate.service == 'LOCAL'" (click)="openLocalWarehouseModal('PICKUP')">
                                        <i class="fas fa-plus-circle"></i> Add Start WH
                                    </a>

                                    <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_SERVICES" class="dropdown-item" data-toggle="modal"
                                        *ngIf="(!estimate.booked || estimate.qa_status === 'RELEASE') && estimate.service == 'LOCAL'" (click)="openLocalWarehouseModal('DELIVERY')">
                                        <i class="fas fa-plus-circle"></i> Add End WH
                                    </a>



                                    <!-- <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_TARIFF"
                                        class="dropdown-item" data-toggle="modal"
                                        data-target="#extra" *ngIf="(!estimate.booked || estimate.qa_status === 'RELEASE')  && estimate.service != 'LOCAL'"  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-settings">
                                            <circle cx="12" cy="12" r="3"></circle>
                                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83
                                            2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2
                                            2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2
                                            2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2
                                            2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2
                                            0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2
                                            2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2
                                            2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51
                                            1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                                        </svg> &nbsp; Set Tariff
                                    </a> -->

                                    <!-- <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_AS_AUTOTRANSPORT" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item"
                                        (click)="setAsAutoTransport()" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg> &nbsp; Set As Auto Transport
                                    </a> -->

                                    <!-- <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_PACKERS"(click)="openPackersPackingModal()"
                                        *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item"  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-settings">
                                            <circle cx="12" cy="12" r="3"></circle>
                                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83
                                            2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2
                                            2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2
                                            2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2
                                            2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2
                                            0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2
                                            2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2
                                            2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51
                                            1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                                        </svg> &nbsp; Set Packers Packing
                                    </a> -->

                                    <!-- <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_PACKERS" (click)="openPackersUnpackingModal()"
                                        *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item"  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-settings">
                                            <circle cx="12" cy="12" r="3"></circle>
                                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83
                                            2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2
                                            2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2
                                            2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2
                                            2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2
                                            0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2
                                            2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2
                                            2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51
                                            1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                                        </svg> &nbsp; Set Packers Unpacking
                                    </a> -->

                                </div>
                            </div>

                        </div>

                        <div class="table-responsive overflow-unset">

                            <table class="table table-bordered table-striped mb-4">
                                <thead>
                                    <tr *ngIf="estimate.service !== 'LOCAL'">
                                        <th colspan="2">Items</th>
                                        <th>Extra</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngIf="estimate.service !== 'LOCAL'">
                                        <td>
                                            <a appCompanyRouterLink="/moving/{{estimate.id}}/inventory-items" appCanUserCompany="ESTIMATE_SET_INVENTORY_ITEMS"
                                                *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" title="Inventory Items"
                                                class="btn-call-inventory cursor-pointer tooltipmxp">
                                                <i class="fas fa-couch"></i>
                                            </a>
                                        </td>
                                        <td>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cubic Feet</span>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs</span> &nbsp; | &nbsp; <span
                                                [ngClass]="selledFeets">{{estimate.volume.cubic_feets_selled}}</span>
                                        </td>
                                        <td *ngIf="estimate.tariff_discount.total_discount == 0" class="position-relative" [ngStyle]="{'color': colorPerCf, 'font-weight': '700' }">

                                            <!-- Button - Tariff Discount -->
                                            <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_TARIFF"
                                            class="btn btn-success btn-set-tariff tooltipmxp" title="Set Tariff" data-toggle="modal"
                                            data-target="#extra" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>

                                            {{estimate.estimate_route_tariff.cf_cost | currency}} per <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">cf</span>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">lb</span>
                                            <span *ngIf="estimate.estimate_route_tariff.lock_the_rate" class="tariff-locked"><i class="fa-solid fa-lock"></i></span>
                                        </td>
                                        <td *ngIf="estimate.tariff_discount.total_discount > 0" class="position-relative" [ngStyle]="{'color': colorPerCf, 'font-weight': '700' }">

                                            <!-- Button - Tariff Discount -->
                                            <a href="javascript:void(0);" appCanUserCompany="ESTIMATE_SET_TARIFF"
                                            class="btn btn-success btn-set-tariff tooltipmxp" title="Set Tariff" data-toggle="modal"
                                            data-target="#extra" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>

                                            <span>{{(estimate.estimate_route_tariff.cf_cost * (100 - estimate.tariff_discount.total_discount_percentage) / 100)| currency}}</span>
                                            per
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">cf</span>
                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">lb</span>
                                            <span *ngIf="estimate.estimate_route_tariff.lock_the_rate" class="tariff-locked"><i class="fa-solid fa-lock"></i></span>
                                        </td>
                                        <td>
                                            {{estimate.charges.cubic_feet_total_price | currency}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="estimate.service !== 'LOCAL' && estimate.tariff_discount.total_discount > 0">
                                        <td>&nbsp;</td>
                                        <td>Tariff Discount</td>
                                        <td>{{estimate.tariff_discount.total_discount_percentage | number}}%</td>
                                        <td class="pl-1">- {{estimate.tariff_discount.total_discount | currency}}</td>
                                    </tr>

                                    <tr *ngIf="estimate.service !== 'LOCAL'">
                                        <td>

                                        </td>
                                        <td>
                                            Fuel Surcharge
                                        </td>
                                        <td>
                                            {{estimate.charges.fuel_surcharge_percentage | number}}%
                                        </td>
                                        <td>
                                            {{estimate.charges.fuel_surcharge | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.service !== 'LOCAL'">
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td>
                                            Sub Total
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td>
                                            {{estimate.sub_total | currency}}
                                        </td>
                                    </tr>


                                    <tr *ngIf="estimate.service == 'LOCAL'">
                                        <td style="width: 50px;">
                                            <a (click)="openLocalJobModal()" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'"  appCanUserCompany="ESTIMATE_SET_PACKERS"
                                                class="btn-call-inventory cursor-pointer tooltipmxp" title="Local Job Data">
                                                <i class="fa-solid fa-truck"></i>
                                            </a>
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active" class="table-local-job" colspan="3">
                                            Labor
                                        </td>
                                        <td *ngIf="estimate.estimate_local_tariff?.flat_rate_active" class="table-local-job" colspan="2">
                                            Labor
                                        </td>
                                        <td *ngIf="estimate.estimate_local_tariff?.flat_rate_active">
                                            {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}} van
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.local_tariff_setting?.workers}} workers
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">
                                            {{estimate.estimate_local_tariff?.labor_workers_tariff.hours}} hours
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">
                                            {{estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour | currency}} per hrs
                                        </td>
                                        <td colspan="2" *ngIf="estimate.estimate_local_tariff?.flat_rate_active">
                                            Flat  Rate
                                        </td>
                                        <td>
                                            <span *ngIf="estimate.estimate_local_tariff?.flat_rate_active"> {{ estimate.estimate_local_tariff?.labor_workers_tariff.flat_rate_cost | currency}} </span>
                                            <span *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">{{(estimate.estimate_local_tariff?.labor_workers_tariff.total) | currency}}</span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.service == 'LOCAL' && estimate.estimate_local_tariff?.quote_mode === 'AUTO'">
                                        <td style="width: 50px;"> </td>
                                        <td *ngIf="estimate.estimate_local_tariff?.flat_rate_active" class="table-local-job" colspan="4">
                                            Travel
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active" class="table-local-job" colspan="3">
                                            Travel
                                            <span *ngIf="estimate.pickup.local_estimate_storage && !estimate.estimate_local_tariff?.flat_rate_active"> (From</span>
                                            <span *ngIf="estimate.delivery.local_estimate_storage && !estimate.estimate_local_tariff?.flat_rate_active"> ,To)</span>
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">
                                            {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}} van
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">
                                            <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'"> {{estimate.estimate_local_tariff?.travel_workers_tariff.hours}} hours</span>
                                            <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'"> {{estimate.estimate_local_tariff?.travel_workers_tariff.miles}} Mi </span>
                                        </td>
                                        <td *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">
                                            <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'"> {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour | currency}} per hrs </span>
                                            <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'"> {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile | currency}} per Mi </span>
                                        </td>
                                        <td colspan="2" *ngIf="estimate.estimate_local_tariff?.flat_rate_active">
                                            Flat  Rate
                                        </td>
                                        <td>
                                            <span *ngIf="estimate.estimate_local_tariff?.flat_rate_active">{{ estimate.estimate_local_tariff?.travel_workers_tariff.flat_rate_cost | currency}} </span>
                                            <span *ngIf="!estimate.estimate_local_tariff?.flat_rate_active">{{ estimate.estimate_local_tariff?.travel_workers_tariff.total | currency}}</span>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="estimate.service == 'LOCAL' && estimate.estimate_local_tariff?.quote_mode === 'AUTO' && !estimate.estimate_local_tariff?.flat_rate_active">
                                        <!-- local local_estimate_storage pickup -->
                                        <tr *ngIf="estimate.pickup.local_estimate_storage">
                                            <td style="width: 50px;">
                                                <button type="button" class="btn btn-danger rounded-circle btn-delete-wh-service" (click)="editLocalWarehousePickup('PICKUP')">
                                                    <i class="fa-solid fa-trash"></i>
                                                </button>
                                            </td>
                                            <td class="table-local-job position-relative" colspan="3">
                                                <div class="ml-4 pickup-local-storage">
                                                    Main Warehouse To <br> "Moving From"
                                                </div>
                                            </td>
                                            <td>
                                                {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}} van
                                            </td>
                                            <td>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'">{{ estimate.pickup.local_estimate_storage.miles | number:'1.2-2' }} Mi</span>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'">{{ estimate.pickup.local_estimate_storage.travel_time | currency }} Hrs</span>
                                            </td>
                                            <td>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'">{{ estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile | currency }} Per Mi</span>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'">{{ estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour | currency }} Per Hrs</span>
                                            </td>
                                            <td>
                                                <!-- {{estimate.pickup.local_estimate_storage.total | currency}} -->
                                            </td>
                                        </tr>

                                        <!-- local local_estimate_storage delivery -->
                                        <tr *ngIf="estimate.delivery.local_estimate_storage">
                                            <td style="width: 50px;">
                                                <button type="button" class="btn btn-danger rounded-circle btn-delete-wh-service" (click)="editLocalWarehousePickup('DELIVERY')">
                                                    <i class="fa-solid fa-trash"></i>
                                                </button>
                                            </td>
                                            <td class="table-local-job position-relative" colspan="3">
                                                <div class="ml-4 delivery-local-storage">
                                                    "Moving From" To <br> Main Warehouse
                                                </div>
                                            </td>
                                            <td>
                                                {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}} van
                                            </td>
                                            <td>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'">{{ estimate.delivery.local_estimate_storage.miles | number:'1.2-2' }} Mi</span>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'">{{ estimate.delivery.local_estimate_storage.travel_time | currency }} Hrs</span>
                                            </td>
                                            <td>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'">{{ estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile | currency }} Per Mi</span>
                                                <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'">{{ estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour | currency }} Per Hrs</span>
                                            </td>
                                            <td>
                                                <!-- {{ estimate.delivery.local_estimate_storage.total | currency }} -->
                                            </td>
                                        </tr>
                                    </ng-container>

                                    <tr *ngIf="estimate.service == 'LOCAL' && estimate.estimate_local_tariff?.quote_mode === 'AUTO' && !estimate.estimate_local_tariff?.flat_rate_active">
                                        <td style="width: 50px;"> </td>
                                        <td colspan="6" class="table-local-job">
                                            Subtotal
                                        </td>
                                        <td class="table-local-job">
                                            {{estimate.sub_total | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.service == 'LOCAL' && estimate.estimate_local_tariff?.quote_mode === 'MANUAL'">
                                        <td style="width: 50px;">
                                            <a (click)="openLocalJobModal()" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'"  appCanUserCompany="ESTIMATE_SET_PACKERS"
                                                class="btn-call-inventory cursor-pointer tooltipmxp" title="Local Job Data">
                                                <i class="fa-solid fa-truck"></i>
                                            </a>
                                        </td>
                                        <td class="table-local-job">
                                            Labor
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.labor_workers_tariff.trucks }} van
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.labor_workers_tariff.workers}} workers
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.labor_workers_tariff.hours}} hours
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour | currency}} per hrs
                                        </td>
                                        <td>
                                            {{ (estimate.estimate_local_tariff?.labor_workers_tariff.hours * estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour) | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.service == 'LOCAL' && estimate.estimate_local_tariff?.quote_mode === 'MANUAL'">
                                        <td style="width: 50px;">
                                        </td>
                                        <td class="table-local-job">
                                            Travel
                                        </td>
                                        <td>
                                            {{ estimate.estimate_local_tariff?.travel_workers_tariff.trucks }} van
                                        </td>
                                        <td>
                                            {{ estimate.estimate_local_tariff?.travel_workers_tariff.workers }} workers
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.travel_workers_tariff.hours}} hours
                                        </td>
                                        <td>
                                            {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour | currency}} per hrs
                                        </td>
                                        <td>
                                            {{ (estimate.estimate_local_tariff?.travel_workers_tariff.hours * estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour) | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.service == 'LOCAL' && estimate.service == 'LOCAL' && estimate.estimate_local_tariff?.quote_mode === 'MANUAL'">
                                        <td style="width: 50px;">
                                        </td>
                                        <td colspan="5" class="table-local-job">
                                            Fuel & Mileage
                                        </td>
                                        <td>
                                            {{estimate.charges.fuel_surcharge | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.service == 'LOCAL'">
                                        <td style="width: 50px;">
                                            <!-- <a appCompanyRouterLink="/moving/{{estimate.id}}/inventory-items" appCanUserCompany="ESTIMATE_SET_INVENTORY_ITEMS"
                                                *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="btn-call-inventory cursor-pointer tooltipmxp" title="Inventory Items">
                                                <i class="fas fa-couch"></i>
                                            </a> -->
                                            <a appCanUserCompany="ESTIMATE_SET_INVENTORY_ITEMS" class="btn-call-inventory cursor-pointer tooltipmxp" (click)="openInventoryItemsLocalModal()"
                                                title="Inventory Items" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                                <i class="fas fa-couch"></i>
                                            </a>
                                        </td>
                                        <td colspan="7" class="table-local-job">
                                            Inventory Items
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.charges.packing_materials > 0">
                                        <td style="width: 50px;">
                                            <a appCompanyRouterLink="/moving/{{estimate.id}}/packing-materials" appCanUserCompany="ESTIMATE_SET_MATERIALS"
                                                title="Packing Materials" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'"
                                                class="btn-call-inventory cursor-pointer tooltipmxp">
                                                <i class="fas fa-box-open"></i>
                                            </a>
                                        </td>
                                        <td colspan="6" class="table-local-job" *ngIf="estimate.service == 'LOCAL'">
                                            Materials
                                        </td>
                                        <td colspan="2" class="table-local-job" *ngIf="estimate.service !== 'LOCAL'">
                                            Materials
                                        </td>
                                        <td>
                                            {{estimate.charges.packing_materials | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.charges.packers_packing > 0">
                                        <td style="width: 50px;">
                                            <a href="#" data-toggle="modal" title="Packers Packing" (click)="openPackersPackingModal()" appCanUserCompany="ESTIMATE_SET_PACKERS"
                                                *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="cursor-pointer btn-call-inventory tooltipmxp">
                                                <i class="fa-solid fa-dolly pt-1"></i>
                                            </a>
                                        </td>
                                        <td>
                                            Packers Packing
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            {{estimate.charges.packers_packing | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.charges.packers_unpacking > 0">
                                        <td style="width: 50px;">
                                            <a href="#" title="Packers Unpacking" data-toggle="modal" (click)="openPackersUnpackingModal()" appCanUserCompany="ESTIMATE_SET_PACKERS"
                                                *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="cursor-pointer btn-call-inventory tooltipmxp">
                                                <i class="fa-solid fa-truck-ramp-box"></i>
                                            </a>
                                        </td>
                                        <td>
                                            Packers Unpacking
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            {{estimate.charges.packers_unpacking | currency}}
                                        </td>
                                    </tr>

                                    <tr *ngIf="estimate.charges.auto_transport > 0">
                                        <td>

                                        </td>
                                        <td>
                                            Auto Transport
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            {{estimate.charges.auto_transport | currency}}
                                        </td>
                                    </tr>

                                    <ng-container *ngIf="estimate.storages">
                                        <tr *ngIf="estimate.storages.first_month_free">
                                            <td>

                                            </td>
                                            <td class="table-local-job">
                                                <div class="d-flex">
                                                    first Month Free
                                                    <div class="label-first-month-free">
                                                        {{estimate.storages.label_first_month_free}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                $0.00
                                            </td>
                                            <td>
                                                $0.00
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>

                                            </td>
                                            <!-- Local -->
                                            <td class="table-local-job" colspan="3" *ngIf="estimate.service == 'LOCAL'">
                                                Storage
                                            </td>
                                            <!-- !Local -->
                                            <!-- <td class="table-local-job" *ngIf="estimate.service !== 'LOCAL'">
                                                Storage
                                            </td> -->
                                            <!-- Local -->
                                            <td *ngIf="estimate.storages.first_month_free" [ngClass]="{'td-united-storage': estimate.service !== 'LOCAL'}">

                                                <div class="storage-title" *ngIf="estimate.service !== 'LOCAL'">
                                                    Storage
                                                </div>
                                                <div>
                                                    Months: {{estimate.storages.storage_months - 1}} |
                                                    <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">cf:</span>
                                                    <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">lb:</span>
                                                    {{estimate.volume.cubic_feets_selled}}
                                                </div>

                                            </td>
                                            <td *ngIf="!estimate.storages.first_month_free" [ngClass]="{'td-united-storage': estimate.service !== 'LOCAL'}">

                                                <div class="storage-title" *ngIf="estimate.service !== 'LOCAL'">
                                                    Storage
                                                </div>
                                                <div>
                                                    Months: {{estimate.storages.storage_months}} |
                                                    <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">cf:</span>
                                                    <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">lb:</span>
                                                    {{estimate.volume.cubic_feets_selled}}
                                                </div>

                                            </td>
                                            <!-- !Local -->

                                            <td>
                                                {{estimate.storages.monthly_fee_by_cf | currency}}
                                                per
                                                <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">cf</span>
                                                <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">lb</span>
                                            </td>
                                            <td *ngIf="estimate.storages.first_month_free">
                                                {{((estimate.storages.storage_months - 1) * (estimate.storages.monthly_fee_by_cf * estimate.volume.cubic_feets_selled)) | currency}}
                                            </td>
                                            <td *ngIf="!estimate.storages.first_month_free">
                                                {{(estimate.storages.storage_months * (estimate.storages.monthly_fee_by_cf * estimate.volume.cubic_feets_selled)) | currency}}
                                            </td>
                                            <!-- Local -->
                                            <td *ngIf="estimate.service == 'LOCAL'">

                                            </td>
                                        </tr>
                                    </ng-container>

                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4" *ngIf="additionals?.length > 0 || stopPoints.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-30 pl-5">Actions</th>
                                        <th>Additionals</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of additionals; index as i;" [hidden]="con.amount < 0">
                                        <td class="pl-5">
                                            <ng-container *ngIf="con.tariff_additional_concepts.editable_by_user || permissionManageServicesInAnEstimate">
                                                <a class="btn btn-success rounded-circle edit-btn" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_SERVICES"
                                                    [hidden]="(estimate.booked && estimate.qa_status !== 'RELEASE') || con.metas?.length > 0" (click)='openModalAdditional(con)'>
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </a>

                                                <button (click)="deleteConcept(con.id)" appCanUserCompany="ESTIMATE_SET_SERVICES"
                                                    [hidden]="(estimate.booked && estimate.qa_status !== 'RELEASE')  || con.metas?.length > 0"
                                                    type="button" class="btn btn-danger rounded-circle">
                                                    <i class="fa-solid fa-trash"></i>
                                                </button>
                                            </ng-container>
                                        </td>
                                        <td>
                                            {{con.name | titlecase}}
                                        </td>
                                        <td>
                                            {{con.amount | currency}}
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="workspaceSettings.cover_stop_fees && estimate.service != 'LOCAL'">
                                        <tr *ngFor="let stopPoint of stopPoints">
                                            <td>
                                               <span *ngIf="stopPoint.type === 'PICKUP'"> Additional Pickup Stop </span>
                                               <span *ngIf="stopPoint.type === 'DELIVERY'"> Additional Delivery Stop </span>
                                            </td>
                                            <td></td>
                                            <td>
                                                {{stopPoint.fee | currency}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4" *ngIf="discounts?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-30 pl-5">Actions</th>
                                        <th>Discounts</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of discounts; index as i;" [hidden]="con.amount >= 0">
                                        <td class="pl-5">
                                            <ng-container *ngIf="con.tariff_additional_concepts">
                                                <a *ngIf="con.tariff_additional_concepts.editable_by_user" class="btn btn-success rounded-circle" [appCanUserCompany]="['ESTIMATE_SET_DISCOUNTS', 'ESTIMATE_MANAGE_DISCOUNTS']"
                                                    [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" (click)="openModalAdditional(con)">
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </a>
                                            </ng-container>

                                            <button (click)="deleteConcept(con.id)" [appCanUserCompany]="['ESTIMATE_SET_DISCOUNTS', 'ESTIMATE_MANAGE_DISCOUNTS']" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                type="button" class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>
                                            {{con.name | titlecase}}
                                        </td>
                                        <td>
                                            {{con.amount | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4" *ngIf="bulkyFee?.length > 0">
                                <thead>
                                    <tr>
                                        <th>Inventory Bulky Fee</th>
                                        <th>Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of bulkyFee ">
                                        <tr>
                                            <td>
                                                {{item.inventory_item.bulky_fee.name | titlecase}}
                                            </td>

                                            <td>
                                                {{(item.inventory_item.bulky_fee.cost * item.quantity) | currency}}
                                            </td>
                                        </tr>

                                    </ng-container>

                                </tbody>
                            </table>

                            <!-- Quick Quote Additionals -->
                            <table class="table table-bordered table-striped mb-4" *ngIf="quickQuoteAdditionals?.length > 0 ">
                                <thead>
                                    <tr>
                                        <th style="width: 40%;">Quick Quote Additionals</th>
                                        <th>Quantity</th>
                                        <th style="width: 180px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of quickQuoteAdditionals; index as i;">
                                        <td> {{ con.name }} </td>
                                        <td>
                                           <span *ngIf="!con.edit" > {{ con.metas[1].value | yesNo}} </span>
                                           <div *ngIf="con.edit">
                                                <div *ngIf="con.tariff_additional_concepts.behavior ==='OPEN_QUICK_QUOTE_QUESTION'">
                                                    <input class="form-control" type="number" [(ngModel)]="con.metas[1].value" id="{{ con.id }}" name="{{ con.id }}"/>
                                                </div>
                                                <div *ngIf="con.tariff_additional_concepts.behavior ==='CLOSED_QUICK_QUOTE_QUESTION'">
                                                    <select class="form-control estimate-details-select" id="{{ con.id }}" name="{{ con.id }}" [(ngModel)]="con.metas[1].value"
                                                    [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                           </div>
                                        </td>
                                        <td>
                                            <a *ngIf="!con.edit" class="btn btn-success rounded-circle edit-btn" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_SERVICES"
                                                [hidden]="(estimate.booked && estimate.qa_status !== 'RELEASE')" (click)='editQuickQuote(con)'>
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>
                                            <a *ngIf="con.edit" class="btn btn-success rounded-circle btn-quick-quote-save" appCanUserCompany="ESTIMATE_SET_SERVICES" (click)='updateQuickQuote(con)'>
                                                <i class="fa-regular fa-floppy-disk"></i>
                                            </a>
                                            <button (click)="deleteQuickQuote(con)" appCanUserCompany="ESTIMATE_SET_SERVICES"
                                                [hidden]="(estimate.booked && estimate.qa_status !== 'RELEASE')"
                                                type="button" class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="workspaceSettings.cover_stop_fees && estimate.service != 'LOCAL'">
                                        <tr *ngFor="let stopPoint of stopPoints">
                                            <td>
                                               <span *ngIf="stopPoint.type === 'PICKUP'"> Additional Pickup Stop </span>
                                               <span *ngIf="stopPoint.type === 'DELIVERY'"> Additional Delivery Stop </span>
                                            </td>
                                            <td></td>
                                            <td>
                                                {{stopPoint.fee | currency}}
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4 text-center"
                                *ngIf="autoTransportList.length > 0">
                                <thead>
                                    <tr>
                                        <th colspan="3">
                                            Auto Transport
                                        </th>
                                        <th>
                                            <button type="button" data-toggle="modal" appCanUserCompany="ESTIMATE_HGG_SET_AUTOTRANSPORT"
                                                (click)="openAutoTransportModal(false)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                class="btn add-auto-transport">
                                                Add
                                            </button>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Model</th>
                                        <th>Type</th>
                                        <th>Actions</th>
                                        <th class="column-total">Total Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transport of autoTransportList">
                                        <td>{{transport.brand}}</td>
                                        <td>{{transport.model}}</td>
                                        <td>{{transport.type}}</td>
                                        <td>
                                            <button (click)="editAutoTransport(transport)" appCanUserCompany="ESTIMATE_HGG_SET_AUTOTRANSPORT"
                                                class="btn btn-success rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>

                                            <button (click)="removeAutoTransport(transport.id)" appCanUserCompany="ESTIMATE_HGG_SET_AUTOTRANSPORT"
                                                class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>{{transport.price | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4">
                                <thead>
                                    <tr>
                                        <th>
                                            <a title="Level of Liability" href="javascript:void" appCanUserCompany="ESTIMATE_SET_FULL_VALUE_PROTECTION"
                                                (click)="openModalLevelOfLiability()" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'"
                                                class="tooltipmxp btn-level-liability cursor-pointer">
                                                <i class="fas fa-shield-alt"></i>
                                            </a>
                                        </th>
                                        <th>Level of Liability</th>
                                        <th>Amount of Liability</th>
                                        <th>Deductible Amount</th>
                                        <th>Valuation Charge</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!-- <tr *ngFor="let con of rowConcept; index as i;"> -->
                                        <td>
                                            <svg *ngIf="estimate.insurance?.type == 'FULL_VALUE_PROTECTION'"
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-check">
                                                <polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>
                                        </td>
                                        <td>
                                            Full Value Protection
                                        </td>
                                        <td>
                                            {{estimate.insurance?.liability_amount | currency}}
                                        </td>
                                        <td>
                                            {{estimate.insurance?.deductible_amount | currency}}
                                        </td>
                                        <td>
                                            {{estimate.insurance?.valuation_charge | currency}}
                                        </td>
                                        <td>
                                            {{estimate.insurance?.valuation_charge | currency}}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <svg *ngIf="estimate.insurance?.type == 'RELEASE_VALUE'"
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-check">
                                                <polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>
                                        </td>
                                        <td>
                                            Release Value
                                        </td>
                                        <td colspan="3" class="text-center">
                                            (60-cent option)
                                        </td>
                                        <td>
                                            $0.00
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4 style-totals">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-right">

                                            <strong>
                                                Totals
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                {{estimate.total | currency}}
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">
                                            <strong>
                                                Customer Payments
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                {{balanceAmount | currency}}
                                            </strong>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div><!-- Special Conditions - Agreements -->

                <!-- <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Follow-ups
                            </h3>
                            <a title="Follow-ups" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                (click)="openModalFollowUpReminders()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-plus-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a>
                        </div>

                        <br>

                        <div class="timeline-alter" *ngIf="followUpReminders.length > 0">
                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of followUpReminders; index as i;">
                                            <td>{{i+1}}</td>
                                            <td>{{row.date | dateTime}}</td>
                                            <td><p [innerHTML]="row.remarks"></p></td>
                                            <td>
                                                <button (click)="editFollowUpReminders(row)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                    class="btn btn-success rounded-circle edit-btn"
                                                    [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </button>
                                                <button (click)="deleteFollowUpReminders(row.id)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                    class="btn btn-danger rounded-circle">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-trash-2">
                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                        <path
                                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                        </path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="work-experience layout-spacing " [ngClass]="divCompleteClass">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Notes
                            </h3>
                            <a title="Add Estimate Notes" class="tooltipmxp btn-call-action btn-b-action btn-notes-section" data-toggle="modal"
                                data-target="#followupsremarks" appCanUserCompany="ESTIMATE_SET_NOTES" >
                                <i class="fa-solid fa-plus"></i>
                            </a>
                            <a *ngIf="divCompleteClass == 'overflowTrue'" title="Show All Notes"
                                class="tooltipmxp btn-call-action btn-p-action btn-notes-section" (click)="togleScroll()">
                                <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                            </a>
                            <a *ngIf="divCompleteClass == 'overflowFalse'" title="Show Scroll Notes"
                                class="tooltipmxp btn-call-action btn-p-action btn-notes-section" (click)="togleScroll()">
                                <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
                            </a>
                        </div>

                        <br>

                        <div class="timeline-alter" *ngIf="rowfollowupsremarks.length > 0" [ngClass]="divCompleteClass">
                            <div *ngFor="let follow of rowfollowupsremarks; index as i;" class="item-timeline">
                                <div class="t-meta-date">
                                    <p *ngIf="follow.employee">
                                        {{follow.created | dateTime}} - {{follow.employee.name}}
                                    </p>
                                    <p *ngIf="!follow.employee">
                                        {{follow.created | dateTime}} - MoverXpro360
                                    </p>
                                </div>
                                <div class="t-dot">
                                </div>
                                <div class="t-text follow-remarks">
                                    <pre [innerHTML]="follow.remarks"></pre>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Comments from Customer
                            </h3>
                            <!--
                                SE COMENTA POR QUE ESTA SECCION VA A SER PARA QUE EL MISMO CLIENTE REGISTRE EL COMENTARIO DE EL ACA, DESDE EL AREA DE CUSTOMERS
                            <a title="Add Comments from Customer" class="tooltipmxp btn-call-action" data-toggle="modal"
                                data-target="#customerremarks" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-plus-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a> -->
                        </div>

                        <br>

                        <div class="timeline-alter" *ngIf="rowCustomerRemarks.length > 0">
                            <div *ngFor="let rcr of rowCustomerRemarks; index as i;" class="item-timeline">
                                <div class="t-meta-date">
                                    <p *ngIf="rcr.employee">
                                        {{ rcr.created | dateTime}} - {{rcr.employee.name}}
                                    </p>
                                    <p *ngIf="rcr.customer">
                                        {{ rcr.created | dateTime}} - {{rcr.customer.name}}
                                    </p>
                                </div>
                                <div class="t-dot">
                                </div>
                                <div class="t-text">
                                    <p>{{rcr.remarks}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Broker Carrier Shared Notes -->
                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Broker Carrier Shared Notes
                            </h3>

                            <a title="Add Carrier Notes" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal"
                                (click)="openModalCarrierNotes(null)">
                                <i class="fa-solid fa-plus"></i>
                            </a>

                            <ng-container *ngIf="carrier">

                                <div class="col-12 mt-4 ml-4">
                                    <div class="row mt-1 brockerKey-color-notes">
                                        <i class="fas fa-truck"></i>
                                        <div class="pl-20"><strong>Carrier:</strong></div>
                                        <span class="pl-86 brockerDesc-color-notes">{{carrier.name}}</span>
                                    </div>
                                </div>

                                <div class="col-12 ml-4">
                                    <div class="row brockerKey-color-notes">
                                        <i class="fas fa-map-marker-alt padl-3"></i>
                                        <div class="pl-24"><strong>Address:</strong></div>
                                        <span
                                            class="pl-76 brockerDesc-color-notes" *ngIf="carrier.address">{{carrier.address.full_address}}</span>
                                    </div>
                                </div>

                                <div class="col-12 ml-4 mt-1">
                                    <div class="row brockerKey-color-notes" *ngFor="let carriers of carrierContact">

                                        <ng-container *ngIf="carriers.main">
                                            <div class="icons-main-contact d-flex">
                                                <i class="fas fa-phone-alt icon-main-contact padl-3"></i>
                                                <div class="pl-20"><strong>Main Contact:</strong></div>
                                            </div>

                                            <div class="pl-56">
                                                <div class="row brockerDesc-color-notes d-flex">
                                                    <div class="width-contact-carrier">
                                                        <i class="fas fa-user-circle icons-main-contact"></i>
                                                        <span class="pl-3">{{carriers.name}}</span>
                                                    </div>
                                                    <div class="">
                                                        <i class="fas fa-phone-square-alt icons-main-contact"></i>
                                                        <span class="pl-3">{{carriers.phone}}</span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div
                                                        class="row brockerDesc-color-notes padl-1 d-flex border-bottom-contact">
                                                        <div class="width-contact-carrier">
                                                            <i class="fas fa-envelope-square icons-main-contact"></i>
                                                            <span class="pl-3">{{carriers.email}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="!carriers.main">
                                            <div class="d-flex">
                                                <i class="fas fa-phone-alt padl-3"></i>
                                                <div class="pl-21"><strong>Phone:</strong></div>
                                            </div>

                                            <div class="pl-88">
                                                <div class="brockerDesc-color-notes d-flex">
                                                    <div class="width-contact-carrier">
                                                        <i class="fas fa-user-circle icon-phone-contact"></i>
                                                        <span class="pl-3">{{carriers.name}}</span>
                                                    </div>
                                                    <div class="">
                                                        <i class="fas fa-phone-square-alt icon-phone-contact"></i>
                                                        <span class="pl-3">{{carriers.phone}}</span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div
                                                        class="brockerDesc-color-notes padl-1 d-flex border-bottom-contact">
                                                        <div class="width-contact-carrier">
                                                            <i class="fas fa-envelope-square icon-phone-contact"></i>
                                                            <span class="pl-3">{{carriers.email}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>

                            </ng-container>

                        </div>

                        <br>

                        <div class="timeline-alter" *ngIf="carrierNotes.length > 0">
                            <div class="table-responsive overflow-unset">
                                <table class="table table-bordered mb-4">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Note</th>
                                            <th>Visibility</th>
                                            <!-- <th>Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of carrierNotes; index as i;">
                                            <td>{{i+1}}</td>
                                            <td>{{row.created | dateTime}}</td>
                                            <td>
                                                <div [innerHTML]="row.note"></div>
                                            </td>
                                            <td class="d-flex justify-content-between align-items-center">
                                                {{row.visibility}}
                                                <button class="btn btn-secondary change-visibility tooltipmxp"
                                                    title="Change Note Visibility" (click)="changeNoteVisibility(row)">
                                                    <i class="fas fa-redo d-flex justify-content-center"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Special Conditions - Agreements -->
                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Special Conditions - Agreements
                            </h3>
                            <a title="Save Special Conditions - Agreements" (click)="saveAdditionalAgreement()"
                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="widget-body pt-4">
                            <app-ck-editor [(ngModel)]="estimate.additional_agreement" *ngIf="!estimate.booked" name="content" ngDefaultControl>
                            </app-ck-editor>
                            <p *ngIf="estimate.booked" [innerHTML]="estimate.additional_agreement"></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row" *ngIf="itemsContainerInventory.length > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div class="education layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h3 class="title-content-area">
                                        Inventory
                                    </h3>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h4>
                                        <strong>Total Items: </strong>
                                        <span>{{totalItems}}</span>
                                    </h4>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <a appCompanyRouterLink="/moving/{{estimate.id}}/inventory-items"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" class="btn-call-action btn-g-action">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                </div>

                                <!-- Items Inventory -->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                    <div id="ct" class="note-container note-grid">

                                        <div class="note-item all-notes" [ngClass]="notesColors[i]" *ngFor="let container of itemsContainerInventory; let i = index">
                                            <div class="note-inner-content">
                                                <div class="note-content">
                                                    <h4 class="note-title">
                                                        {{container.name}}
                                                    </h4>
                                                    <!-- <p class="meta-time">11/01/2019</p> -->
                                                    <div class="note-description-content" *ngFor="let item of container.items ">
                                                        <p class="note-description">
                                                            {{item.inventory_item.name}} <span *ngIf="item.inventory_item.packed_by == 'CARRIER'" style="color:red">| CP</span>
                                                        </p>
                                                        <div class="note-action">
                                                            Qty: {{item.quantity}} |
                                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cubic Feet:
                                                                {{item.inventory_item.cubic_feet}}</span>
                                                            <span *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs:
                                                                {{item.inventory_item.cubic_feet * cfLbsRatio}}</span>
                                                        </div>
                                                        <div class="item-manual" *ngIf="item.inventory_item.customized">
                                                            <i class="fa-solid fa-star"></i>
                                                            <span>Customized Item</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Quickquote Inventory -->
        <div class="row" *ngIf="localInventoryRooms.length > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div class="education layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h3 class="title-content-area">
                                        Quickquote Inventory
                                    </h3>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <h4>
                                        <!-- <strong>Total Items: </strong>
                                        <span>{{totalItems}}</span> -->
                                    </h4>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <a appCompanyRouterLink="/moving/{{estimate.id}}/quick-inventory"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" class="btn-call-action btn-g-action">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                </div>

                                <!-- Items Inventory -->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
                                    <div id="ct" class="note-container note-grid">

                                        <div class="note-item all-notes" [ngClass]="notesColors[i]" *ngFor="let inventoryRoom of localInventoryRooms; let i = index">
                                            <div class="note-inner-content">
                                                <div class="note-content">
                                                    <h4 class="note-title">
                                                        {{inventoryRoom.local_room_category.name | titlecase }} ({{inventoryRoom.furniture_level | titlecase}})<span *ngIf="inventoryRoom.is_packing">CP</span>
                                                    </h4>
                                                    <ng-container  *ngFor="let closets of inventoryRoom.closets">
                                                        <div class="note-description-content">
                                                            {{closets.local_room_closet.name}} | <span class="pl-3">{{closets.capacity_level | titlecase}}</span> <span *ngIf="inventoryRoom.closets.is_packing">CP</span>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</ng-container>

<ng-container *ngIf="estimate.service == 'AUTO_TRANSPORT'">
    <!-- (ngSubmit)="save()" -->
    <form class="form" #estimateForm="ngForm" ngNativeValidate>

        <div class="row">

            <!-- Content -->
            <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 pr-0">

                <div class="user-profile info-lead layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class="justify-content-between widget-heading">
                            <h3 class="title-content-area">
                                Contacts
                            </h3>

                            <a title="Edit Contact" appCanUserCompany="ESTIMATE_CONTACT_EDIT" class="tooltipmxp btn-call-action btn-g-action"
                                (click)='openModalLead()' [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>

                            <a title="Add New Contact" appCanUserCompany="ESTIMATE_CONTACT_CREATE" class="tooltipmxp btn-call-action btn-b-action"
                                (click)='openNewContactsModal()' [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-plus"></i>
                            </a>
                        </div>
                        <div class="text-center user-info" style="margin: 0;">
                            <!-- <img src="assets/img/90x90.jpg" alt="avatar"> -->
                            <p>
                                {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                            </p>
                        </div>
                        <div class="user-info-list">
                            <div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="contacts-block list-unstyled">

                                            <li class="contacts-block__item contact-info-block">
                                                <i class="far fa-copy p-2 copy-text-action" (click)="copyText(customer.phone, 'text');$event.stopPropagation()"></i>
                                                <a href="tel:{{customer.phone}}" class="c-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-phone">
                                                        <path
                                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                        </path>
                                                    </svg>
                                                    <span>{{customer.phone | phone:'US'}}</span>
                                                </a>
                                                <div class="btn-sms" (click)="openModalSendTextMessage()">
                                                    <i class="fa-solid fa-comment-sms"></i>
                                                </div>
                                            </li>

                                            <li class="contacts-block__item contact-info-block">
                                                <i class="far fa-copy p-2 copy-text-action" (click)="copyText(customer.email, 'text');$event.stopPropagation()"></i>
                                                <a class="c-pointer" appCompanyRouterLink="/moving/{{estimate.id}}/emails">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-mail">
                                                        <path
                                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                        </path>
                                                        <polyline points="22,6 12,13 2,6"></polyline>
                                                    </svg>
                                                    <span>{{customer.email}}</span>
                                                </a>
                                            </li>

                                            <li class="contacts-block__item remark-block" *ngIf="customer.remarks">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-message-circle">
                                                    <path
                                                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                                                    </path>
                                                </svg>
                                                <span>{{customer.remarks}}</span>
                                            </li>

                                            <li class="contacts-block__item" *ngIf="advertiser.name">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-rss">
                                                    <path d="M4 11a9 9 0 0 1 9 9"></path>
                                                    <path d="M4 4a16 16 0 0 1 16 16"></path>
                                                    <circle cx="5" cy="19" r="1"></circle>
                                                </svg>
                                                <span>{{advertiser.name}}</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <ng-container *ngFor="let contact of estimate.contacts; let i = index">

                                    <hr>

                                    <div class="col-md-12">
                                        <ul class="contacts-block list-unstyled">

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-users">
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                                {{contact.name | titlecase}} {{contact.last_name | titlecase}}
                                            </li>

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-mail">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                    </path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg>
                                                {{contact.email | titlecase}}
                                            </li>

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-phone">
                                                    <path
                                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                    </path>
                                                </svg>
                                                {{contact.phone | phone:'US' }}
                                            </li>

                                            <a class="btn-call-action delete-btn tooltipmxp" title="Delete Contact"
                                                (click)='removeContact(contact)' appCanUserCompany="ESTIMATE_CONTACT_CREATE"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-trash"></i>
                                            </a>

                                        </ul>
                                    </div>

                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Fees Information
                            </h3>
                            <a title="Save Fees Information" (click)="saveInfoFees()" appCanUserCompany="ESTIMATE_SET_TARIFF" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-git-pull-request">
                                            <circle cx="18" cy="18" r="3"></circle>
                                            <circle cx="6" cy="6" r="3"></circle>
                                            <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
                                            <line x1="6" y1="9" x2="6" y2="21"></line>
                                        </svg>
                                        <strong>Miles: </strong> {{estimate.volume.miles | number : '1.2-2'}}
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-clock">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="12 6 12 12 16 14"></polyline>
                                        </svg>
                                        <strong>Duration:</strong>
                                        <span *ngIf="estimate.volume.travel_time >= 1">{{estimate.volume.travel_time | number : '1.2-2'}} Hours</span>
                                        <span *ngIf="estimate.volume.travel_time < 1">{{estimate.volume.travel_time * 60 | number : '1.2-2'}} Minutes</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Basic Information
                            </h3>
                            <a (click)="saveInfoBasic()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Save  Basic Information"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <!-- <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-briefcase">
                                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                        </svg>
                                        <strong>Job Number:</strong> {{estimate.document.code}}
                                    </li> -->
                                    <!-- <li class="contacts-block__item only-text position-relative" *ngIf="estimate.broker_id == null">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-award">
                                            <circle cx="12" cy="8" r="7"></circle>
                                            <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                        </svg>
                                        <strong>Reference:</strong>
                                        <input class="form-control estimate-details-input sin-borde color-reference" type="text" placeholder="Enter Reference."
                                        [(ngModel)]="estimate.reference" name="wp_reference_at" disabled/>
                                        <a (click)="editEstimateReference()" class="btn btn-success btn-edit-reference tooltipmxp" title="Edit Reference">
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </a>
                                    </li> -->

                                    <ul class="contacts-block list-unstyled">
                                        <li class="contacts-block__item only-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-truck">
                                                <rect x="1" y="3" width="15" height="13"></rect>
                                                <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                                <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                                <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                            </svg>
                                            <strong>Service Type:</strong> {{estimate.service | filterReplace}}
                                        </li>
                                    </ul>

                                    <!-- <li class="contacts-block__item has-checkbox only-text" *ngIf="estimate.booked">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-check-square">
                                            <polyline points="9 11 12 14 22 4"></polyline>
                                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                        </svg>
                                        <strong>Confirmed:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" class="new-control-input" name="confirmed"
                                                (ngModelChange)="adviceChanges()" [(ngModel)]="estimate.confirmed"
                                                [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li> -->

                                </ul>

                                <hr>

                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail" *ngIf="enableblackoutMoveDate">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Pick Up:</strong>
                                        <app-date-range-picker [placeholder]="'Not Assigned'"
                                            (onChange)="changePickUp($event)" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                            [disabledDates]="blackoutMoveDates"
                                            [valueStart]="estimate.pickup.range_start"
                                            [valueEnd]="estimate.pickup.range_end">
                                        </app-date-range-picker>
                                    </li>

                                    <!-- <li class="contacts-block__item has-checkbox only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-check-square">
                                            <polyline points="9 11 12 14 22 4"></polyline>
                                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                                        </svg>
                                        <strong>Use Date Range for FADD:</strong>
                                        <label class="new-control new-checkbox checkbox-success">
                                            <input type="checkbox" (ngModelChange)="adviceChanges()"
                                                class="new-control-input" name="has_docs" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [(ngModel)]="rangeDate" />
                                            <span class="new-control-indicator"></span> &nbsp;
                                        </label>
                                    </li> -->
                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>First Day Available for Delivery: </strong>
                                        <div *ngIf="rangeDate">
                                            <app-date-range-picker [placeholder]="'Not Assigned'"
                                                (onChange)="changeRequestDelivery($event)" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [valueStart]="estimate.delivery.range_start"
                                                [valueEnd]="estimate.delivery.range_end">
                                            </app-date-range-picker>
                                        </div>
                                        <ng-container *ngIf="!rangeDate">
                                            <app-date-picker [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [value]="estimate.delivery.range_start" [placeholder]="'Not Assigned'"
                                                theme="WIDGET" (onChange)="changeDeliveryDate($event)">
                                            </app-date-picker>
                                        </ng-container>
                                    </li>
                                </ul>

                                <hr>

                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                        <strong>Move Type:</strong>
                                        <select class="form-control estimate-details-select" name="move_type" id="move_type" (ngModelChange)="adviceChanges()"
                                            [(ngModel)]="estimate.move_type" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option value="RESIDENTIAL">Residential</option>
                                            <option value="COMMERCIAL">Commercial</option>
                                            <option value="MILITARY">Military</option>
                                        </select>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile layout-spacing">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Electronic Signature
                            </h3>
                        </div>

                        <div class="electronic-signature">

                            <div class="signature-image" *ngIf="estimate.esignature">
                                <img [appCdnLink]="'/e-signatures/' + estimate.esignature.esignature_file.display_name"
                                    alt="esignature" class="e-signature-img">
                            </div>
                            <div class="signature-image" *ngIf="!estimate.esignature">
                                <img src="/assets/img/unsigned.png" alt="esignature" width="170px">
                            </div>

                            <div class="user-info-list signature-info" *ngIf="estimate.esignature">
                                <ul class="contacts-block list-unstyled m-0">
                                    <li class="contacts-block__item">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="2" y1="12" x2="22" y2="12"></line>
                                            <path
                                                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                            </path>
                                        </svg>{{estimate.esignature.internet_browser}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>{{estimate.esignature.created | dateTime}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                                        </svg>{{estimate.esignature.ip_address}}
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Employees
                            </h3>
                            <a (click)="saveUsers()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Save Employees" appCanUserCompany="ESTIMATE_SET_EMPLOYEES"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <!-- <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-trello">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <rect x="7" y="7" width="3" height="9"></rect>
                                            <rect x="14" y="7" width="3" height="5"></rect>
                                        </svg>
                                        <strong>Work Depart:</strong>
                                        <select name="operator_work_department_id" id="operator_work_department_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.work_department_id"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let workDepartment of workDepartments">
                                                <option value="{{workDepartment.id}}">
                                                    {{workDepartment.name | titlecase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li> -->
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <i class="fas fa-user-tie salesman-icon" width="23" height="23"></i>
                                        <strong>Salesman:</strong>
                                        <select name="operator_representative_id" id="operator_representative_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.salesman_id" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let user of employees">
                                                <option value="{{user.id}}">
                                                    {{user.name | filterReplace}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <strong>Operator:</strong>
                                        <select name="operator_user_id" id="operator_user_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.operator_id" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let user of employees">
                                                <option value="{{user.id}}">
                                                    {{user.name | lowercase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="user-profile info-basic layout-spacing" *ngIf="estimate.broker_id == null">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Category
                            </h3>
                            <a *ngIf="estimate.category != 'NEW'" (click)="saveInfoBasic()"
                                title="Save Basic Information" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-save">
                                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                                    <polyline points="17 21 17 13 7 13 7 21"></polyline>
                                    <polyline points="7 3 7 8 15 8"></polyline>
                                </svg>
                            </a>
                            <div *ngIf="estimate.category == 'NEW'"
                                class="open-category-alert tooltipCategory tooltipmxp"
                                title="You must to assign a new category to this job">
                                <button class="button">!</button>
                            </div>

                        </div>
                        <div class="user-info-list">
                            <div>
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-book">
                                            <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                                            <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z">
                                            </path>
                                        </svg>
                                        <strong>Category:</strong>
                                        <select name="category" id="category" class="form-control estimate-details-select" [(ngModel)]="estimate.category"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option [selected]="estimate.category == 'NEW'" value="NEW">New</option>
                                            <option [selected]="estimate.category == 'HOT'" value="HOT">Hot</option>
                                            <option [selected]="estimate.category == 'WARM'" value="WARM">Warm</option>
                                            <option [selected]="estimate.category == 'COLD'" value="COLD">Cold</option>
                                            <option [selected]="estimate.category == 'OPEN'" value="OPEN">Open</option>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-tag">
                                            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                            <line x1="7" y1="7" x2="7.01" y2="7"></line>
                                        </svg>
                                        <strong>Status:</strong>
                                        <span [ngSwitch]="estimate.status">
                                            <ng-container *ngSwitchCase="'FOLLOW_UP'">
                                                Follow Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'UNSUCCESSFUL_SALE'">
                                                Unsuccessful Sale
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_OPERATION'">
                                                In Operation
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LOADED_FOR_STORAGE'">
                                                Loaded For Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LOADED_FOR_DELIVERY'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{estimate.status}}
                                            </ng-container>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Lead
                            </h3>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled marg-t30 lead-info">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Date Lead Arrived: </strong>
                                        <span *ngIf="estimate.lead_company">
                                            {{ estimate.lead_company.created | dateTime }}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Creation Date In Estimate: </strong>
                                        <span>
                                            {{ estimate.created | dateTime }}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-cloud">
                                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                                        </svg>
                                        <strong>Lead Provider: </strong>
                                        <span *ngIf="estimate.advertiser">
                                            {{ estimate.advertiser.name}}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text booked-date" *ngIf="estimate.booked_date">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-airplay">
                                            <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                            <polygon points="12 15 17 21 7 21 12 15"></polygon>
                                        </svg>
                                        <strong>Booked Date</strong>
                                        <span>{{estimate.booked_date | date}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Direction / Operation Details -->
                <div class="layout-spacing ">
                    <div class="widget widget-text">
                        <div class="widget-content">

                            <div class=" widget-heading">
                                <h3 class="title-content-area">
                                    Direction / Operation Details
                                </h3>
                                <a title="Save Direction / Operation Details" (click)="saveOperationDetails()"
                                    [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" class="tooltipmxp btn-call-action">
                                    <i class="fa-solid fa-floppy-disk"></i>
                                </a>
                            </div>

                            <div class="widget-body">
                                <textarea name="operation_details" id="operation_details"
                                    [(ngModel)]="estimate.operation_details" placeholder="Write an Update"></textarea>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12">
                <div class="user-profile info-moving layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Moving - AutoTransport
                            </h3>
                            <a title="View Route Delivery" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal"
                                data-target="#modalMap" (click)="initializeMap()">
                                <i class="fa-solid fa-map"></i>
                            </a>
                            <a href="#" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Edit Moving From and To" appCanUserCompany="ESTIMATE_UPDATE_ADDRESSES"
                                class="tooltipmxp btn-call-action btn-g-action" data-toggle="modal" data-target="#moving">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-12 col-xl-6 col-lg-12  ">
                                <h5 class="title-moving-from">
                                    From
                                </h5>
                                <p *ngIf="estimate.pickup.address.street">
                                    <strong>Street:</strong> {{estimate.pickup.address.street | titlecase}}
                                </p>
                                <p *ngIf="estimate.pickup.address.city">
                                    <strong>City:</strong> {{estimate.pickup.address.city | titlecase}}
                                </p>
                                <p *ngIf="estimate.pickup.address.state">
                                    <strong>State:</strong> {{estimate.pickup.address.state | convertState | async}}
                                </p>
                                <p *ngIf="estimate.pickup.address.country">
                                    <strong>Country:</strong>
                                    <ng-container *ngIf="estimate.pickup.address.country == 'Estados Unidos'">United
                                        States</ng-container>
                                    <ng-container *ngIf="estimate.pickup.address.country != 'Estados Unidos'">
                                        {{estimate.pickup.address.country | titlecase}}</ng-container>
                                </p>
                                <p *ngIf="estimate.pickup.address.zip_code">
                                    <strong>Zip Code:</strong> {{estimate.pickup.address.zip_code}}
                                </p>
                            </div>
                            <div class="col-12 col-xl-6 col-lg-12 ">
                                <h5 class="title-moving-to">
                                    To
                                </h5>
                                <p *ngIf="estimate.delivery.address.street">
                                    <strong>Street:</strong> {{estimate.delivery.address.street | titlecase}}
                                </p>
                                <p *ngIf="estimate.delivery.address.city">
                                    <strong>City:</strong> {{estimate.delivery.address.city | titlecase}}
                                </p>
                                <p *ngIf="estimate.delivery.address.state">
                                    <strong>State:</strong> {{estimate.delivery.address.state | convertState | async}}
                                </p>
                                <p *ngIf="estimate.delivery.address.country">
                                    <strong>Country:</strong>
                                    <ng-container *ngIf="estimate.delivery.address.country == 'Estados Unidos'">United
                                        States</ng-container>
                                    <ng-container *ngIf="estimate.delivery.address.country != 'Estados Unidos'">
                                        {{estimate.delivery.address.country | titlecase}}</ng-container>
                                </p>
                                <p *ngIf="estimate.delivery.address.zip_code">
                                    <strong>Zip Code:</strong> {{estimate.delivery.address.zip_code}}
                                </p>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="skills layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Charges
                            </h3>
                            <div class="btn-group dropleft height-dropleft btn-dropdown-options" role="group" style="float: right;">
                                <button id="btnOutline" type="button" class="btn btn-secondary btn-options btn-lg dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-down">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                    &nbsp; Options
                                </button>
                                <div class="dropdown-menu" aria-labelledby="btnOutline" style="will-change: transform;">

                                    <a href="javascript:void(0);" (click)="openAutoTransportModal(false)"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item">
                                        <i class="fas fa-plus-circle"></i> Auto Transport
                                    </a>

                                    <a href="javascript:void(0);" (click)="downloadPdf()" class="dropdown-item">
                                        <i class="fas fa-file-pdf"></i> View Estimate PDF
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" (click)="openDuplicateLeadModal()" appCanUserCompany="ESTIMATE_DUPLICATE"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                        <i class="fa-solid fa-copy"></i> Duplicate
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)="unsuccessfulSale()">
                                        <i class="fas fa-handshake-slash"></i> Unsuccessful Sale
                                    </a>

                                    <a href="javascript:void(0);" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item" (click)="setAsMoving()" appCanUserCompany="ESTIMATE_SET_AS_MOVING">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-git-branch">
                                            <line x1="6" y1="3" x2="6" y2="15"></line>
                                            <circle cx="18" cy="6" r="3"></circle>
                                            <circle cx="6" cy="18" r="3"></circle>
                                            <path d="M18 9a9 9 0 0 1-9 9"></path>
                                        </svg> Set As Moving
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal"
                                        (click)='openModalAdditional(null)' *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                        <i class="fas fa-plus-circle"></i> Add Additional
                                    </a>



                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)='openModalAdditional(null)'>
                                        <i class="fas fa-plus-circle"></i> Add Additional Concept
                                    </a> -->

                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)='openModalAdditionalService("DISCOUNT")'>
                                        <i class="fas fa-plus-circle"></i> Add Discount
                                    </a> -->



                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" (click)='openModalAdditionalService("CHARGE")'>
                                        <i class="fas fa-plus-circle"></i> Add Additional Service
                                    </a> -->

                                </div>
                            </div>

                        </div>

                        <div class="table-responsive">


                            <table class="table table-bordered table-striped mb-4 text-center"
                                *ngIf="autoTransportList.length > 0">
                                <thead>
                                    <tr>
                                        <th colspan="5">
                                            Auto Transport
                                        </th>
                                        <th>
                                            <button type="button" data-toggle="modal"
                                                (click)="openAutoTransportModal(false)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                class="btn btn-warning">
                                                <i class="fas fa-plus-circle"></i> Add
                                            </button>
                                        </th>
                                    </tr>
                                    <tr>

                                        <th>Brand</th>
                                        <th>Model</th>
                                        <th>Type</th>
                                        <th>Year</th>
                                        <th>Price</th>
                                        <th>Deposit</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let transport of autoTransportList">
                                        <td>{{transport.brand}}</td>
                                        <td>{{transport.model}}</td>
                                        <td>{{transport.type}}</td>
                                        <td>{{transport.year }}</td>
                                        <td>{{transport.price | currency}}</td>
                                        <td>{{transport.deposit | currency}}</td>
                                        <td>
                                            <button (click)="editAutoTransport(transport)"
                                                class="btn btn-success rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button (click)="removeAutoTransport(transport.id)"
                                                class="btn btn-danger rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <table class="table table-bordered table-striped mb-4" *ngIf="additionals?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-30 pl-5">Actions</th>
                                        <th>Additionals</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of additionals; index as i;" [hidden]="con.amount < 0">
                                        <td class="pl-5">
                                            <a class="btn btn-success rounded-circle edit-btn" data-toggle="modal"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" (click)='openModalAdditional(con)'>
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>
                                            <button (click)="deleteConcept(con.id)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                type="button" class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>
                                            {{con.name | titlecase}}
                                        </td>
                                        <td>
                                            {{con.amount | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4" *ngIf="discounts?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-30 pl-5">Actions</th>
                                        <th>Discounts</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of discounts; index as i;" [hidden]="con.amount >= 0">
                                        <td class="pl-5">
                                            <a class="btn btn-success rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>
                                            <button (click)="deleteConcept(con.id)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                type="button" class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>
                                            {{con.name | titlecase}}
                                        </td>
                                        <td>
                                            {{con.amount | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-right">

                                            <strong>
                                                Totals
                                            </strong>
                                        </td>
                                        <td>
                                            {{estimate.total | currency}}
                                        </td>
                                    </tr>
                                    <!--<tr>
                                            <td class="text-right">
                                                Customer Payments
                                            </td>
                                            <td>
                                                $519
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">
                                                Total
                                            </td>
                                            <td>
                                                $1050
                                            </td>
                                        </tr>-->
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <!-- <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Follow-ups
                            </h3>
                            <a title="Add Follow Up" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                (click)="openModalFollowUpReminders()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-plus-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a>
                        </div>

                        <div class="timeline-alter" *ngIf="followUpReminders.length > 0">

                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of followUpReminders; index as i;">
                                            <td>{{i+1}}</td>
                                            <td>{{row.date | date}}</td>
                                            <td><p [innerHTML]="row.remarks"></p></td>
                                            <td>
                                                <button (click)="editFollowUpReminders(row)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                    class="btn btn-success rounded-circle edit-btn">
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </button>
                                                <button (click)="deleteFollowUpReminders(row.id)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                    class="btn btn-danger rounded-circle">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-trash-2">
                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                        <path
                                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                        </path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div> -->

                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">


                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Notes
                            </h3>
                            <a title="Add New Notes" class="tooltipmxp btn-call-action" data-toggle="modal"
                                data-target="#followupsremarks" appCanUserCompany="ESTIMATE_SET_NOTES" >
                                <i class="fa-solid fa-plus"></i>
                            </a>
                        </div>

                        <div class="timeline-alter" *ngIf="rowfollowupsremarks.length > 0">

                            <div *ngFor="let follow of rowfollowupsremarks; index as i;" class="item-timeline">
                                <div class="t-meta-date">
                                    <p *ngIf="follow.employee">
                                        {{follow.created | dateTime}} - {{follow.employee.name}}
                                    </p>
                                    <p *ngIf="!follow.employee">
                                        {{follow.created | dateTime}} - Broker
                                    </p>
                                </div>
                                <div class="t-dot">
                                </div>
                                <div class="t-text">
                                    <p [innerHTML]="follow.remarks"></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Comments from Customer -->
                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Comments from Customer
                            </h3>
                            <!--
                            SE COMENTA POR QUE ESTA SECCION VA A SER PARA QUE EL MISMO CLIENTE REGISTRE EL COMENTARIO DE EL ACA, DESDE EL AREA DE CUSTOMERS
                            <a title="Add Customer Comment" class="tooltipmxp btn-call-action" data-toggle="modal"
                                data-target="#customerremarks" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-plus-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a> -->
                        </div>

                        <div class="timeline-alter" *ngIf="rowCustomerRemarks.length > 0">
                            <div *ngFor="let rcr of rowCustomerRemarks; index as i;" class="item-timeline">
                                <div class="t-meta-date">
                                    <p *ngIf="rcr.employee">
                                        {{ rcr.created | dateTime}} - {{rcr.employee.name}}
                                    </p>
                                    <p *ngIf="!rcr.employee">
                                        {{ rcr.created | dateTime}}
                                    </p>
                                </div>
                                <div class="t-dot">
                                </div>
                                <div class="t-text">
                                    <p>{{rcr.remarks}}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <!-- Special Conditions - Agreements -->
                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Special Conditions - Agreements
                            </h3>
                            <a title="Save Special Conditions - Agreements" (click)="saveAdditionalAgreement()"
                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="widget-body pt-4">
                            <app-ck-editor [(ngModel)]="estimate.additional_agreement" name="content" *ngIf="!estimate.booked" ngDefaultControl>
                            </app-ck-editor>
                            <p *ngIf="estimate.booked" [innerHTML]="estimate.additional_agreement"></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </form>
</ng-container>

<ng-container *ngIf="estimate.service == 'FREIGHT'">
    <!-- (ngSubmit)="save()" -->
    <form class="form" #estimateForm="ngForm" ngNativeValidate>

        <div class="row">

            <!-- Content -->
            <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 pr-0">

                <div class="user-profile info-lead layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class="justify-content-between widget-heading">
                            <h3 class="title-content-area">
                                Contacts
                            </h3>

                            <a title="Edit Contact" appCanUserCompany="ESTIMATE_CONTACT_EDIT" class="tooltipmxp btn-call-action btn-g-action"
                                (click)='openModalLead()' [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>

                            <a title="Add New Contact" appCanUserCompany="ESTIMATE_CONTACT_CREATE" class="tooltipmxp btn-call-action btn-b-action"
                                (click)='openNewContactsModal()' [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <i class="fa-solid fa-plus"></i>
                            </a>
                        </div>
                        <div class="text-center user-info" style="margin: 0;">
                            <!-- <img src="assets/img/90x90.jpg" alt="avatar"> -->
                            <p>
                                {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                            </p>
                        </div>
                        <div class="user-info-list">
                            <div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <ul class="contacts-block list-unstyled">

                                            <li class="contacts-block__item contact-info-block">
                                                <i class="far fa-copy p-2 copy-text-action" (click)="copyText(customer.phone, 'text');$event.stopPropagation()"></i>
                                                <a href="tel:{{customer.phone}}" class="c-pointer">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-phone">
                                                        <path
                                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                        </path>
                                                    </svg>
                                                    <span>{{customer.phone | phone:'US'}}</span>
                                                </a>
                                                <div class="btn-sms" (click)="openModalSendTextMessage()">
                                                    <i class="fa-solid fa-comment-sms"></i>
                                                </div>
                                            </li>

                                            <li class="contacts-block__item contact-info-block">
                                                <i class="far fa-copy p-2 copy-text-action" (click)="copyText(customer.email, 'text');$event.stopPropagation()"></i>
                                                <a class="c-pointer" appCompanyRouterLink="/moving/{{estimate.id}}/emails">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-mail">
                                                        <path
                                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                        </path>
                                                        <polyline points="22,6 12,13 2,6"></polyline>
                                                    </svg>
                                                    <span>{{customer.email}}</span>
                                                </a>
                                            </li>

                                            <li class="contacts-block__item remark-block" *ngIf="customer.remarks">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-message-circle">
                                                    <path
                                                        d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z">
                                                    </path>
                                                </svg>
                                                <span>{{customer.remarks}}</span>
                                            </li>

                                            <li class="contacts-block__item" *ngIf="advertiser.name">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-rss">
                                                    <path d="M4 11a9 9 0 0 1 9 9"></path>
                                                    <path d="M4 4a16 16 0 0 1 16 16"></path>
                                                    <circle cx="5" cy="19" r="1"></circle>
                                                </svg>
                                                <span>{{advertiser.name}}</span>
                                            </li>

                                        </ul>

                                    </div>
                                </div>

                                <ng-container *ngFor="let contact of estimate.contacts; let i = index">

                                    <hr>

                                    <div class="col-md-12">
                                        <ul class="contacts-block list-unstyled">
                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-users">
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                                {{contact.name | titlecase}} {{contact.last_name | titlecase}}
                                            </li>

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-mail">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                    </path>
                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                </svg>
                                                {{contact.email | titlecase}}
                                            </li>

                                            <li class="contacts-block__item">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-phone">
                                                    <path
                                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                    </path>
                                                </svg>
                                                {{contact.phone | phone:'US' }}
                                            </li>

                                            <a class="btn-call-action delete-btn tooltipmxp" title="Delete Contact"
                                                (click)='removeContact(contact)' appCanUserCompany="ESTIMATE_CONTACT_CREATE"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-trash"></i>
                                            </a>

                                        </ul>
                                    </div>

                                </ng-container>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Fees Information
                            </h3>
                            <a title="Save Fees Information" (click)="saveInfoFees()" appCanUserCompany="ESTIMATE_SET_TARIFF" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-git-pull-request">
                                            <circle cx="18" cy="18" r="3"></circle>
                                            <circle cx="6" cy="6" r="3"></circle>
                                            <path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
                                            <line x1="6" y1="9" x2="6" y2="21"></line>
                                        </svg>
                                        <strong>Miles: </strong> {{estimate.volume.miles | number : '1.2-2'}}
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-clock">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="12 6 12 12 16 14"></polyline>
                                        </svg>
                                        <strong>Duration:</strong>
                                        <span *ngIf="estimate.volume.travel_time >= 1">{{estimate.volume.travel_time | number : '1.2-2'}} Hours</span>
                                        <span *ngIf="estimate.volume.travel_time < 1">{{estimate.volume.travel_time * 60 | number : '1.2-2'}} Minutes</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Basic Information
                            </h3>
                            <a (click)="saveInfoBasic()" title="Save Basic Information" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                        <strong>Service Type:</strong> {{estimate.service | filterReplace}}
                                    </li>
                                </ul>

                                <hr>

                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail" *ngIf="enableblackoutMoveDate">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Pick Up:</strong>
                                        <app-date-range-picker [placeholder]="'Not Assigned'"
                                            [disabledDates]="blackoutMoveDates"
                                            (onChange)="changePickUp($event)" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                            [valueStart]="estimate.pickup.range_start"
                                            [valueEnd]="estimate.pickup.range_end">
                                        </app-date-range-picker>
                                    </li>

                                    <li class="contacts-block__item only-text app-date-picker-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>First day available for delivery: </strong>
                                        <ng-container *ngIf="rangeDate">
                                            <app-date-range-picker [placeholder]="'Not Assigned'"
                                                (onChange)="changeRequestDelivery($event)" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [valueStart]="estimate.delivery.range_start"
                                                [valueEnd]="estimate.delivery.range_end">
                                            </app-date-range-picker>
                                        </ng-container>
                                        <ng-container *ngIf="!rangeDate">
                                            <app-date-picker [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                [value]="estimate.delivery.range_start" [placeholder]="'Not Assigned'"
                                                theme="WIDGET" (onChange)="changeDeliveryDate($event)">
                                            </app-date-picker>
                                        </ng-container>
                                    </li>
                                </ul>

                                <!-- <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-briefcase">
                                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                        </svg>
                                        <strong>Job Number: </strong> {{estimate.document.code}}
                                    </li>
                                </ul> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing" *ngIf="estimate.broker_id == null">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Max Discount Information
                            </h3>
                            <a title="Save Max Discount Information" (click)="saveCustomDiscount()" [appCanUserCompany]="['ESTIMATE_SET_DISCOUNTS','ESTIMATE_MANAGE_DISCOUNTS']" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <ng-container>

                                        <li class="contacts-block__item only-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-percent">
                                                <line x1="19" y1="5" x2="5" y2="19"></line>
                                                <circle cx="6.5" cy="6.5" r="2.5"></circle>
                                                <circle cx="17.5" cy="17.5" r="2.5"></circle>
                                            </svg>
                                            <strong>Max. Discount: </strong> ${{maxDiscount | number : '1.2-2'}}
                                            <label
                                                (click)="changeMaxDiscount()"
                                                class="cursor-pointer"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                appCanUserCompany="ESTIMATE_MANAGE_DISCOUNTS"
                                                >
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </label>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="user-profile layout-spacing">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Electronic Signature
                            </h3>
                        </div>

                        <div class="electronic-signature">

                            <div class="signature-image" *ngIf="estimate.esignature">
                                <img [appCdnLink]="'/e-signatures/' + estimate.esignature.esignature_file.display_name"
                                    alt="esignature" class="e-signature-img">
                            </div>
                            <div class="signature-image" *ngIf="!estimate.esignature">
                                <img src="/assets/img/unsigned.png" alt="esignature" width="170px">
                            </div>

                            <div class="user-info-list signature-info" *ngIf="estimate.esignature">
                                <ul class="contacts-block list-unstyled m-0">
                                    <li class="contacts-block__item">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="2" y1="12" x2="22" y2="12"></line>
                                            <path
                                                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                            </path>
                                        </svg>{{estimate.esignature.internet_browser}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>{{estimate.esignature.created | dateTime}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                                        </svg>{{estimate.esignature.ip_address}}
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="user-profile info-basic layout-spacing"  >
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Employees
                            </h3>
                            <a (click)="saveUsers()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Save Employees" appCanUserCompany="ESTIMATE_SET_EMPLOYEES"
                                class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled">
                                    <!-- <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-trello">
                                            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                            <rect x="7" y="7" width="3" height="9"></rect>
                                            <rect x="14" y="7" width="3" height="5"></rect>
                                        </svg>
                                        <strong>Work Depart: </strong>
                                        <select name="operator_work_department_id" id="operator_work_department_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.work_department_id"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let workDepartment of workDepartments">
                                                <option value="{{workDepartment.id}}">
                                                    {{workDepartment.name | titlecase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li> -->
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <i class="fas fa-user-tie salesman-icon" width="23" height="23"></i>
                                        <strong>Salesman: </strong>
                                        <select name="operator_representative_id" id="operator_representative_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.salesman_id" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let user of employees">
                                                <option value="{{user.id}}">
                                                    {{user.name | filterReplace}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text select2-estimate-detail">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <strong>Operator: </strong>
                                        <select name="operator_user_id" id="operator_user_id"
                                            [appSelect2] [place]="'Not Assigned'"
                                            [(ngModel)]="estimate.operator.operator_id" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <ng-container *ngFor="let user of employees">
                                                <option value="{{user.id}}">
                                                    {{user.name | lowercase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="user-profile info-basic layout-spacing" *ngIf="estimate.broker_id == null">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Category
                            </h3>
                            <a *ngIf="estimate.category != 'NEW'" (click)="saveInfoBasic()"
                                title="Save Basic Information" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                class="tooltipmxp btn-call-action">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-save">
                                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                                    <polyline points="17 21 17 13 7 13 7 21"></polyline>
                                    <polyline points="7 3 7 8 15 8"></polyline>
                                </svg>
                            </a>
                            <div *ngIf="estimate.category == 'NEW'"
                                class="open-category-alert tooltipCategory tooltipmxp"
                                title="You must to assign a new category to this job">
                                <button class="button">!</button>
                            </div>


                        </div>
                        <div class="user-info-list">
                            <div>
                                <ul class="contacts-block list-unstyled">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-book">
                                            <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                                            <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z">
                                            </path>
                                        </svg>
                                        <strong>Category:</strong>
                                        <select name="category" class="form-control estimate-details-select" id="category" [(ngModel)]="estimate.category"
                                            [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option [selected]="estimate.category == 'NEW'" value="NEW">New</option>
                                            <option [selected]="estimate.category == 'HOT'" value="HOT">Hot</option>
                                            <option [selected]="estimate.category == 'WARM'" value="WARM">Warm</option>
                                            <option [selected]="estimate.category == 'COLD'" value="COLD">Cold</option>
                                            <option [selected]="estimate.category == 'OPEN'" value="OPEN">Open</option>
                                        </select>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-tag">
                                            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                                            <line x1="7" y1="7" x2="7.01" y2="7"></line>
                                        </svg>
                                        <strong>Status:</strong>
                                        <span [ngSwitch]="estimate.status">
                                            <ng-container *ngSwitchCase="'FOLLOW_UP'">
                                                Follow Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'UNSUCCESSFUL_SALE'">
                                                Unsuccessful Sale
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_OPERATION'">
                                                In Operation
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LOADED_FOR_STORAGE'">
                                                Loaded For Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'LOADED_FOR_DELIVERY'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                {{estimate.status}}
                                            </ng-container>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="user-profile info-basic layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Lead
                            </h3>
                        </div>
                        <div class="user-info-list">
                            <div class="marg-t30">
                                <ul class="contacts-block list-unstyled lead-info">
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Date Lead Arrived: </strong>
                                        <span *ngIf="estimate.lead_company">
                                            {{ estimate.lead_company.created | dateTime }}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        <strong>Creation Date In Estimate: </strong>
                                        <span>
                                            {{ estimate.created | dateTime }}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-cloud">
                                            <path d="M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"></path>
                                        </svg>
                                        <strong>Lead Provider: </strong>
                                        <span *ngIf="estimate.advertiser">
                                            {{ estimate.advertiser.name}}
                                        </span>
                                    </li>
                                    <li class="contacts-block__item only-text booked-date" *ngIf="estimate.booked_date">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-airplay">
                                            <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                            <polygon points="12 15 17 21 7 21 12 15"></polygon>
                                        </svg>
                                        <strong>Booked Date</strong>
                                        <span>{{estimate.booked_date | date}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12">

                <div class="user-profile info-moving layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">

                            <h3 class="title-content-area">
                                Moving - Freights
                            </h3>

                            <a title="Adding stop point" class="tooltipmxp btn-call-action btn-p-action" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                data-toggle="modal" (click)="openStopPointModal()" appCanUserCompany="SET_ADDITIONAL_STOPS" >
                                <i class="fa-solid fa-location-dot"></i>
                            </a>

                            <a title="View Route Delivery" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal"
                                data-target="#modalMap" (click)="initializeMap()">
                                <i class="fa-solid fa-map"></i>
                            </a>

                            <a href="#" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" title="Edit Moving From and To" appCanUserCompany="ESTIMATE_UPDATE_ADDRESSES"
                                class="tooltipmxp btn-call-action btn-g-action" data-toggle="modal" data-target="#moving">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </a>

                        </div>
                        <br>

                        <div class="row">
                            <div class="col-12 col-xl-6 col-lg-12">
                                <h5 class="title-moving-from">
                                    From
                                </h5>
                                <p *ngIf="estimate.pickup.address.street">
                                    <strong>Street:</strong> {{estimate.pickup.address.street | titlecase}}
                                </p>
                                <p *ngIf="estimate.pickup.address.city">
                                    <strong>City:</strong> {{estimate.pickup.address.city | titlecase}}
                                </p>
                                <p *ngIf="estimate.pickup.address.state">
                                    <strong>State:</strong> {{estimate.pickup.address.state | convertState | async}}
                                </p>
                                <p *ngIf="estimate.pickup.address.country">
                                    <strong>Country:</strong>
                                    <ng-container *ngIf="estimate.pickup.address.country == 'Estados Unidos'">United
                                        States</ng-container>
                                    <ng-container *ngIf="estimate.pickup.address.country != 'Estados Unidos'">
                                        {{estimate.pickup.address.country | titlecase}}</ng-container>
                                </p>
                                <p *ngIf="estimate.pickup.address.zip_code">
                                    <strong>Zip Code:</strong> {{estimate.pickup.address.zip_code}}
                                </p>
                            </div>
                            <div class="col-12 col-xl-6 col-lg-12 ">
                                <h5 class="title-moving-to">
                                    To
                                </h5>
                                <p *ngIf="estimate.delivery.address.street">
                                    <strong>Street:</strong> {{estimate.delivery.address.street | titlecase}}
                                </p>
                                <p *ngIf="estimate.delivery.address.city">
                                    <strong>City:</strong> {{estimate.delivery.address.city | titlecase}}
                                </p>
                                <p *ngIf="estimate.delivery.address.state">
                                    <strong>State:</strong> {{estimate.delivery.address.state | convertState | async}}
                                </p>
                                <p *ngIf="estimate.delivery.address.country">
                                    <strong>Country:</strong>
                                    <ng-container *ngIf="estimate.delivery.address.country == 'Estados Unidos'">United
                                        States</ng-container>
                                    <ng-container *ngIf="estimate.delivery.address.country != 'Estados Unidos'">
                                        {{estimate.delivery.address.country | titlecase}}</ng-container>
                                </p>
                                <p *ngIf="estimate.delivery.address.zip_code">
                                    <strong>Zip Code:</strong> {{estimate.delivery.address.zip_code}}
                                </p>

                            </div>
                        </div>


                        <br *ngIf="stopPoints.length > 0">

                        <div class=" widget-heading" *ngIf="stopPoints.length > 0">
                            <h3 class="title-content-area">
                                Stop Points
                            </h3>
                        </div>

                        <br>

                        <div class="row" *ngIf="stopPoints.length > 0">
                            <div class="col-12 col-xl-6 col-lg-12" *ngFor="let stopPoint of stopPoints">
                                <div class="row">
                                    <h6 class="text-center m-auto"><strong>{{stopPoint.type}}</strong></h6>
                                    <a class="btn-call-action delete-btn tooltipmxp" title="Delete Stop"
                                        (click)="removeStopPoint(stopPoint)" appCanUserCompany="SET_ADDITIONAL_STOPS" type="button"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                        <i class="fa-solid fa-trash"></i>
                                    </a>
                                </div>

                                <p *ngIf="stopPoint.address.street">
                                    <strong>Street: </strong> {{stopPoint.address.street}}
                                </p>
                                <p *ngIf="stopPoint.address.city">
                                    <strong>City: </strong> {{stopPoint.address.city}}
                                </p>
                                <p *ngIf="stopPoint.address.state">
                                    <strong>State: </strong> {{stopPoint.address.state | convertState | async}}
                                </p>
                                <p *ngIf="stopPoint.address.zip_code">
                                    <strong>Zipcode: </strong> {{stopPoint.address.zip_code}}
                                </p>
                                <p *ngIf="stopPoint.address.property_type">
                                    <strong>Property type: </strong> {{stopPoint.address.property_type}}
                                </p>
                                <p *ngIf="stopPoint.address.level">
                                    <strong>Level: </strong> {{stopPoint.address.level}}
                                </p>
                                <p *ngIf="stopPoint.address.floor">
                                    <strong>Floor: </strong> {{stopPoint.address.floor}}
                                </p>
                                <p *ngIf="stopPoint.address.apartment_number">
                                    <strong># Apartment: </strong> {{stopPoint.address.apartment_number}}
                                </p>
                                <br>
                                <div class="row col-12">

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="skills layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Charges
                            </h3>
                            <div class="btn-group dropleft height-dropleft btn-dropdown-options" role="group" style="float: right;">
                                <button id="btnOutline" type="button" class="btn btn-secondary btn-options btn-lg dropdown-toggle"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-chevron-down">
                                        <polyline points="6 9 12 15 18 9"></polyline>
                                    </svg>
                                    &nbsp; Options
                                </button>
                                <div class="dropdown-menu" aria-labelledby="btnOutline" style="will-change: transform;">

                                    <a href="javascript:void(0);" class="dropdown-item" (click)="openDuplicateLeadModal()" appCanUserCompany="ESTIMATE_DUPLICATE"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                        <i class="fa-solid fa-copy"></i> Duplicate
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)="unsuccessfulSale()">
                                        <i class="fas fa-handshake-slash"></i> Unsuccessful Sale
                                    </a>

                                    <a href="javascript:void(0);" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item" (click)="setAsMoving()" appCanUserCompany="ESTIMATE_SET_AS_MOVING">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-git-branch">
                                            <line x1="6" y1="3" x2="6" y2="15"></line>
                                            <circle cx="18" cy="6" r="3"></circle>
                                            <circle cx="6" cy="18" r="3"></circle>
                                            <path d="M18 9a9 9 0 0 1-9 9"></path>
                                        </svg> Set As Moving
                                    </a>

                                    <a href="javascript:void(0);" (click)="openMovingFreightModal(false)"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'" class="dropdown-item">
                                        <i class="fas fa-plus-circle"></i> Add Freights
                                    </a>

                                    <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal"
                                        (click)='openModalAdditional(null)' *ngIf="!estimate.booked || estimate.qa_status === 'RELEASE'">
                                        <i class="fas fa-plus-circle"></i> Add Additional
                                    </a>

                                    <a href="javascript:void(0);" (click)="downloadPdf()" class="dropdown-item">
                                        <i class="fas fa-file-pdf"></i> View Estimate PDF
                                    </a>

                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)='openModalAdditional(null)'>
                                        <i class="fas fa-plus-circle"></i> Add Additional Concept
                                    </a> -->

                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        (click)='openModalAdditionalService("DISCOUNT")'>
                                        <i class="fas fa-plus-circle"></i> Add Discount
                                    </a> -->

                                    <!-- <a href="javascript:void(0);" class="dropdown-item" data-toggle="modal"
                                        [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" (click)='openModalAdditionalService("CHARGE")'>
                                        <i class="fas fa-plus-circle"></i> Add Additional Service
                                    </a> -->

                                </div>
                            </div>

                        </div>

                        <div class="table-responsive">


                            <table class="table table-bordered table-striped mb-4 text-center"
                                *ngIf="freightsList.length > 0">
                                <thead>
                                    <tr>
                                        <th colspan="5">
                                            Freight
                                        </th>
                                        <th>
                                            <button type="button" data-toggle="modal"
                                                (click)="openMovingFreightModal(false)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                class="btn btn-warning">
                                                <i class="fas fa-plus-circle"></i> Add
                                            </button>
                                        </th>
                                    </tr>
                                    <tr>

                                        <th>Equipment</th>
                                        <th>Mode</th>
                                        <th>All In Rate</th>
                                        <th>Cubic Feet</th>
                                        <th>Commodity</th>
                                        <th>Pallets</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let freightList of freightsList">
                                        <td>
                                            <span *ngIf="freightList.equipment == 'V'">Van</span>
                                            <span *ngIf="freightList.equipment == 'F'">Flatbed</span>
                                            <span *ngIf="freightList.equipment == 'R'">Refigerated (Reefer)</span>
                                            <span *ngIf="freightList.equipment == 'VR'">Van or Reefer</span>
                                            <span *ngIf="freightList.equipment == 'VRF'">Flatbed, Van or Reefer</span>
                                        </td>
                                        <td>{{freightList.mode}}</td>
                                        <td>{{freightList.all_in_rate | currency}}</td>
                                        <td>{{freightList.cubic_feet }}</td>
                                        <td>{{freightList.commodity}}</td>
                                        <td>{{freightList.pallets}}</td>
                                        <td>
                                            <button (click)="editFreight(freightList)"
                                                class="btn btn-success rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button (click)="removeFreight(freightList.id)"
                                                class="btn btn-danger rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                            <table class="table table-bordered table-striped mb-4" *ngIf="additionals?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-30 pl-5">Actions</th>
                                        <th>Additionals</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of additionals; index as i;" [hidden]="con.amount < 0">
                                        <td class="pl-5">
                                            <a class="btn btn-success rounded-circle edit-btn" data-toggle="modal"
                                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" (click)='openModalAdditional(con)'>
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>
                                            <button (click)="deleteConcept(con.id)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                type="button" class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>
                                            {{con.name | titlecase}}
                                        </td>
                                        <td>
                                            {{con.amount | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4" *ngIf="discounts?.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-30 pl-5">Actions</th>
                                        <th>Discounts</th>
                                        <th class="column-total">Sale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let con of discounts; index as i;" [hidden]="con.amount >= 0">
                                        <td class="pl-5">
                                            <a class="btn btn-success rounded-circle" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </a>
                                            <button (click)="deleteConcept(con.id)" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                                type="button" class="btn btn-danger rounded-circle">
                                                <i class="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>
                                            {{con.name | titlecase}}
                                        </td>
                                        <td>
                                            {{con.amount | currency}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-bordered table-striped mb-4">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            Total
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-right">

                                            <strong>
                                                Totals
                                            </strong>
                                        </td>
                                        <td>
                                            {{estimate.total | currency}}
                                        </td>
                                    </tr>
                                    <!--<tr>
                                            <td class="text-right">
                                                Customer Payments
                                            </td>
                                            <td>
                                                $519
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-right">
                                                Total
                                            </td>
                                            <td>
                                                $1050
                                            </td>
                                        </tr>-->
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <!-- <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Follow-ups
                            </h3>
                            <a title="Add Follow Up" class="tooltipmxp btn-call-action btn-b-action" data-toggle="modal" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                (click)="openModalFollowUpReminders()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-plus-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a>
                        </div>

                        <div class="timeline-alter" *ngIf="followUpReminders.length > 0">

                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of followUpReminders; index as i;">
                                            <td>{{i+1}}</td>
                                            <td>{{row.date | date}}</td>
                                            <td><p [innerHTML]="row.remarks"></p></td>
                                            <td>
                                                <button (click)="editFollowUpReminders(row)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                    class="btn btn-success rounded-circle edit-btn">
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </button>
                                                <button (click)="deleteFollowUpReminders(row.id)" appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS"
                                                    class="btn btn-danger rounded-circle">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-trash-2">
                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                        <path
                                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                        </path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div> -->

                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">


                        <div class=" widget-heading">
                            <h3 class="title-content-area">
                                Notes
                            </h3>
                            <a title="Add New Notes" class="tooltipmxp btn-call-action" data-toggle="modal"
                                data-target="#followupsremarks" appCanUserCompany="ESTIMATE_SET_NOTES" >
                                <i class="fa-solid fa-plus"></i>
                            </a>
                        </div>

                        <div class="timeline-alter" *ngIf="rowfollowupsremarks.length > 0">

                            <div *ngFor="let follow of rowfollowupsremarks; index as i;" class="item-timeline">
                                <div class="t-meta-date">
                                    <p *ngIf="follow.employee">
                                        {{follow.created | dateTime}} - {{follow.employee.name}}
                                    </p>
                                    <p *ngIf="!follow.employee">
                                        {{follow.created | dateTime}} - Broker
                                    </p>
                                </div>
                                <div class="t-dot">
                                </div>
                                <div class="t-text">
                                    <p [innerHTML]="follow.remarks"></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Comments from Customer -->
                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">

                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Comments from Customer
                            </h3>
                            <!--
                            SE COMENTA POR QUE ESTA SECCION VA A SER PARA QUE EL MISMO CLIENTE REGISTRE EL COMENTARIO DE EL ACA, DESDE EL AREA DE CUSTOMERS
                            <a title="Add Customer Comment" class="tooltipmxp btn-call-action" data-toggle="modal"
                                data-target="#customerremarks" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-plus-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                </svg>
                            </a> -->
                        </div>

                        <div class="timeline-alter" *ngIf="rowCustomerRemarks.length > 0">
                            <div *ngFor="let rcr of rowCustomerRemarks; index as i;" class="item-timeline">
                                <div class="t-meta-date">
                                    <p *ngIf="rcr.employee">
                                        {{ rcr.created | dateTime}} - {{rcr.employee.name}}
                                    </p>
                                    <p *ngIf="!rcr.employee">
                                        {{ rcr.created | dateTime}}
                                    </p>
                                </div>
                                <div class="t-dot">
                                </div>
                                <div class="t-text">
                                    <p>{{rcr.remarks}}</p>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <!-- Special Conditions - Agreements -->
                <div class="work-experience layout-spacing ">
                    <div class="widget-content widget-content-area">
                        <div class="widget-heading">
                            <h3 class="title-content-area">
                                Special Conditions - Agreements
                            </h3>
                            <a title="Save Special Conditions - Agreements" (click)="saveAdditionalAgreement()"
                                [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'" class="tooltipmxp btn-call-action">
                                <i class="fa-solid fa-floppy-disk"></i>
                            </a>
                        </div>
                        <div class="widget-body pt-4">
                            <app-ck-editor [(ngModel)]="estimate.additional_agreement" *ngIf="!estimate.booked" name="content" ngDefaultControl>
                            </app-ck-editor>
                            <p *ngIf="estimate.booked" [innerHTML]="estimate.additional_agreement"></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </form>
</ng-container>

<!-- Modal Add New Estimate Notes -->
<div class="modal fade" id="followupsremarks" tabindex="-1" role="dialog" aria-labelledby="followupsremarksTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followupsremarksTitle">
                    Add New Estimate Notes
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="inputState">
                        Note
                    </label>
                    <div class="list-title">
                        <textarea class="form-control textarea-force" name="estimate_followup_remarks"
                            id="estimate_followup_remarks" [(ngModel)]="followupsremarks.remarks"
                            placeholder="Write the note here" cols="30" rows="5">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addfollowupsremarks()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Duplicate Moving Options -->
<div class="modal fade" #duplicateLeadModal tabindex="-1" role="dialog" aria-labelledby="duplicateLeadModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-duplicate-moving" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followupsremarksTitle">
                    Duplicate Moving Options
                </h5>
            </div>
            <div class="modal-body">
                <div>
                    <label for="inputState">
                        Moving Type
                    </label>
                    <div class="list-title">
                        <!-- [(ngModel)]="autoTransport.type" -->
                        <!-- [selected]="typeVehicle.type == autoTransport.type"> -->
                        <select id="autotransport_type"
                            placeholder="Type" class="form-control" [(ngModel)]="movingDuplicateType">
                            <option value="">Select a Moving Type</option>
                            <option value="LONG">Long</option>
                            <option value="LOCAL">Local</option>
                            <option value="AUTO_TRANSPORT">Auto Transport</option>
                            <option value="FREIGHT">Freight</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="duplicateLead()" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Comment from Customer -->
<div class="modal fade" id="customerremarks" tabindex="-1" role="dialog" aria-labelledby="customerremarksTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="customerremarksTitle">Add Customer Comment</h5>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <label for="inputState">
                        Comment from Customer
                    </label>
                    <div class="list-title">
                        <textarea class="form-control" name="estimate_customer_remarks" id="estimate_customer_remarks"
                            [(ngModel)]="customerRemarks.remarks" placeholder="write the customer comment here"
                            cols="30" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addCustomerRemarks()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Auto Local Tariff Data -->
<div class="modal fade" #autoLocalTariffModal id="autoLocalTariffModal" tabindex="-1" role="dialog"
    aria-labelledby="autoLocalTariffModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <form>

                <div class="row modal-header">
                    <div class="col-md-6">
                        <h5 class="modal-title" id="autoLocalTariffModalTitle">
                            Auto Local Tariff Data
                        </h5>
                    </div>
                    <!-- <div class="col-md-6">
                        <h5 class="modal-title" id="estimateLocalTariff.flat_rate_active">
                            Active Flat Rate
                            <div class="toggle-switch mt-2">
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="estimateLocalTariff.flat_rate_active" name="offside_allowed" [(ngModel)]="estimateLocalTariff.flat_rate_active">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="estimateLocalTariff.flat_rate_active" class="checkbox-label">
                                    <span *ngIf="estimateLocalTariff.flat_rate_active" class="active">Active</span>
                                    <span *ngIf="!estimateLocalTariff.flat_rate_active" class="disabled">Disabled</span>
                                </label>
                            </div>
                        </h5>
                    </div> -->
                </div>

                <div class="modal-body">

                    <!-- Active Flat Rate -->
                    <div class="mt-2 local-job-title"> Active Flat Rate </div>
                    <div class="row">
                        <div class="col-md-12 mt-3">
                            <label class="m-0">Flat Rate</label>
                            <div class="form-group col-md-6 p-0">
                                <div class="toggle-switch mt-2">
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="estimateLocalTariff.flat_rate_active" name="offside_allowed" [(ngModel)]="estimateLocalTariff.flat_rate_active">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="estimateLocalTariff.flat_rate_active" class="checkbox-label">
                                        <span *ngIf="estimateLocalTariff.flat_rate_active" class="active">Active</span>
                                        <span *ngIf="!estimateLocalTariff.flat_rate_active" class="disabled">Disabled</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Labor -->
                    <div class="mt-4 local-job-title"> Labor </div>
                    <div class="row">
                        <div class="col-md-4 mt-3">
                            <label>Truck(s)/Workers</label>
                            <select [(ngModel)]="localTariffSetting" name="localTariffSetting" (ngModelChange)="setLocalTariffSetting()"
                                class="form-control">
                                <option value="" disabled>Select an Option</option>
                                <option *ngFor="let localTariff of localTariffs" value="{{localTariff.id}}">{{localTariff.trucks}} Trucks + {{localTariff.workers}} Workers</option>
                            </select>
                        </div>
                        <div class="col-md-4 mt-3">
                            <label>Hours</label>
                            <input id="estimateLocalTariff_hours" [(ngModel)]="estimateLocalTariff.labor_workers_tariff.hours" type="number"
                                min="0" placeholder="Labor Hours " class="form-control" name="estimateLocalTariff_hours"
                                required>
                        </div>
                        <div class="col-md-4 mt-3">
                            <label>Cost per hours</label>
                            <input id="estimateLocalTariff_cost_cost_per_hour" [(ngModel)]="estimateLocalTariff.labor_workers_tariff.cost_per_hour"
                                type="number" min="0" placeholder="Labor Cost per Hours" class="form-control"
                                name="estimateLocalTariff_cost_cost_per_hour" required>
                        </div>
                        <div class="col-md-4 mt-3" *ngIf="estimateLocalTariff.flat_rate_active">
                            <label>Flat Rate Cost</label>
                            <input id="estimateLocalTariff_cost_flat_rate_cost" [(ngModel)]="estimateLocalTariff.labor_workers_tariff.flat_rate_cost"
                                type="number" min="0" placeholder="Labor cost per hours" class="form-control"
                                name="estimateLocalTariff_cost_flat_rate_cost" required>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label class="m-0">Lock Labor Data</label>
                            <div class="form-group col-md-6 p-0">
                                <div class="toggle-switch mt-2">
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="look_labor_data" name="offside_allowed" [(ngModel)]="estimateLocalTariff.labor_workers_tariff.look_data">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="look_labor_data" class="checkbox-label">
                                        <span *ngIf="estimateLocalTariff.labor_workers_tariff.look_data" class="active">Active</span>
                                        <span *ngIf="!estimateLocalTariff.labor_workers_tariff.look_data" class="disabled">Disabled</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Travel -->
                    <div class="mt-4 local-job-title"> Travel </div>
                    <div class="row">

                        <ng-container *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'; then content else otherContent"></ng-container>

                        <ng-template #content>
                            <div class="col-md-4 mt-3">
                                <label>Hours</label>
                                <input id="estimateLocalTariff.travel_workers_tariff.hours" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.hours"
                                    (ngModelChange)="setManualHours()"
                                    type="number" min="0" placeholder="Travel Hours" class="form-control"
                                    name="estimateLocalTariff.travel_workers_tariff.hours" required>
                            </div>

                            <div class="col-md-4 mt-3">
                                <label>Cost per Hours</label>
                                <input id="estimateLocalTariff.travel_workers_tariff.cost_per_hour" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.cost_per_hour"
                                    type="number" min="0" placeholder="Labor Cost per Hours" class="form-control"
                                    name="estimateLocalTariff.travel_workers_tariff.cost_per_hour" required>
                            </div>
                        </ng-template>

                        <ng-template #otherContent>
                            <div class="col-md-4 mt-3">
                                <label>Miles</label>
                                <input id="estimateLocalTariff.travel_workers_tariff.miles" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.miles"
                                    (ngModelChange)="setManualMiles()"
                                    type="number" min="0" placeholder="Travel miles " class="form-control"
                                    name="estimateLocalTariff.travel_workers_tariff.miles" required>
                            </div>

                            <div class="col-md-4 mt-3">
                                <label>Cost per Miles</label>
                                <input id="estimateLocalTariff.travel_workers_tariff.cost_per_mile" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.cost_per_mile"
                                    type="number" min="0" placeholder="Labor cost per miles" class="form-control"
                                    name="estimateLocalTariff.travel_workers_tariff.cost_per_mile" required>
                            </div>
                        </ng-template>

                        <div class="col-md-4 mt-3" *ngIf="estimateLocalTariff.flat_rate_active" >
                            <label>Flat Rate Travel Cost</label>
                            <input id="estimateLocalTariff_flat_rate_travel_cost" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.flat_rate_cost" type="number"
                                min="0" placeholder="Flat rate travel cost " class="form-control" name="estimateLocalTariff_flat_rate_travel_cost"
                                required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-3">
                            <label class="m-0">Lock Travel Data</label>
                            <div class="form-group col-md-6 p-0">
                                <div class="toggle-switch mt-2">
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="look_travel_data" name="estimateLocalTariff.travel_workers_tariff.look_data" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.look_data">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="look_travel_data" class="checkbox-label">
                                        <span *ngIf="estimateLocalTariff.travel_workers_tariff.look_data" class="active">Active</span>
                                        <span *ngIf="!estimateLocalTariff.travel_workers_tariff.look_data" class="disabled">Disabled</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer pt-2">
                    <button type="button" data-dismiss="modal" class="btn btn-cancel">
                        Cancel
                    </button>
                    <button type="button" (click)="saveLocalJobData()" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>

<!-- Modal Local Job Data -->
<div class="modal fade" #localJobModal id="localJobModal" tabindex="-1" role="dialog"
    aria-labelledby="localJobModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <form>
                <div class="col-md-12 modal-header">
                    <h5 class="modal-title" id="localJobModalTitle">
                        Local Job Data
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="local-job-title"> Labor </div>
                    <div class="row">
                        <div class="col-md-6 mt-3">
                            <label>Vans</label>
                            <input id="estimateLocalTariff.labor_workers_tariff._trucks" [(ngModel)]="estimateLocalTariff?.labor_workers_tariff.trucks"
                                type="number" min="0" placeholder="Labor Vans " class="form-control"
                                name="estimateLocalTariff.labor_workers_tariff._trucks" required>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label>Workers</label>
                            <input id="estimateLocalTariff.labor_workers_tariff._workers"
                                [(ngModel)]="estimateLocalTariff.labor_workers_tariff.workers" type="number" min="0"
                                placeholder="Labor quantity workers" class="form-control"
                                name="estimateLocalTariff.labor_workers_tariff._workers" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-3">
                            <label>Hours</label>
                            <input id="estimateLocalTariff.labor_workers_tariff._hours" [(ngModel)]="estimateLocalTariff.labor_workers_tariff.hours" type="number"
                                min="0" placeholder="Labor Hours " class="form-control" name="estimateLocalTariff.labor_workers_tariff._hours"
                                required>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label>Cost per hours</label>
                            <input id="estimateLocalTariff.labor_workers_tariff._cost_per_hour" [(ngModel)]="estimateLocalTariff.labor_workers_tariff.cost_per_hour"
                                type="number" min="0" placeholder="Labor cost per hours" class="form-control"
                                name="estimateLocalTariff.labor_workers_tariff._cost_per_hour" required>
                        </div>
                    </div>
                    <div class="mt-5 local-job-title"> Travel </div>
                    <div class="row">
                        <div class="col-md-6 mt-3">
                            <label>Vans</label>
                            <input id="estimateLocalTariff.travel_workers_tariff._trucks" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.trucks"
                                type="number" min="0" placeholder="Travel Vans " class="form-control"
                                name="estimateLocalTariff.travel_workers_tariff._trucks" required>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label>Workers</label>
                            <input id="estimateLocalTariff.travel_workers_tariff._workers"
                                [(ngModel)]="estimateLocalTariff.travel_workers_tariff.workers" type="number" min="0"
                                placeholder="Travel quantity workers" class="form-control"
                                name="estimateLocalTariff.travel_workers_tariff._workers" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mt-3">
                            <label>Hours</label>
                            <input id="estimateLocalTariff.travel_workers_tariff._hours" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.hours" type="number"
                                (ngModelChange)="setManualHours()"
                                min="0" placeholder="Travel Hours " class="form-control" name="estimateLocalTariff.travel_workers_tariff._hours"
                                required>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label>Cost per hours</label>
                            <input id="estimateLocalTariff.travel_workers_tariff._cost_per_hour" [(ngModel)]="estimateLocalTariff.travel_workers_tariff.cost_per_hour"
                                type="number" min="0" placeholder="Travel cost per hours" class="form-control"
                                name="estimateLocalTariff.travel_workers_tariff._cost_per_hour" required>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" data-dismiss="modal" class="btn btn-cancel">
                        Cancel
                    </button>
                    <button type="button" (click)="saveLocalJobData()" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal Packers Packing -->
<div class="modal fade" #packerspacking tabindex="-1" role="dialog"
    aria-labelledby="packerspackingTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-packers-packing" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="packerspackingTitle">
                        Packers Packing
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerPacking_quantity">Packers Number</label>
                                <input id="estimatePackerPacking_quantity"
                                    [(ngModel)]="estimatePackerPacking.workers" type="number" min="0" class="form-control"
                                    placeholder="Packers Number" name="estimatePackerPacking_quantity" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerPacking_hours">Working Hours</label>
                                <input id="estimatePackerPacking_hours" [(ngModel)]="estimatePackerPacking.hours"
                                    type="number" min="0" placeholder="Working Hours" class="form-control"
                                    name="estimatePackerPacking_hours" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerPacking_cost_per_hour">Pay per Hours</label>
                                <input id="estimatePackerPacking_cost_per_hour" [(ngModel)]="estimatePackerPacking.cost_per_hour"
                                    type="number" min="0" step="0.01" placeholder="Pay per hours" class="form-control"
                                    name="estimatePackerPacking_cost_per_hour" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="savePackersPacking()" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal Packers Unpacking -->
<div class="modal fade" #packersunpacking tabindex="-1" role="dialog"
    aria-labelledby="packersunpackingTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-packers-unpacking" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="packersunpackingTitle">
                        Packers Unpacking
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerUnPacking_quantity">Packers Number</label>
                                <input id="estimatePackerUnPacking_quantity"
                                    [(ngModel)]="estimatePackerUnPacking.workers" type="number" min="0"
                                    placeholder="Packers Number" class="form-control"
                                    name="estimatePackerUnPacking_quantity" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerUnPacking_hours">Working Hours</label>
                                <input id="estimatePackerUnPacking_hours" [(ngModel)]="estimatePackerUnPacking.hours"
                                    type="number" min="0" placeholder="Working Hours" class="form-control"
                                    name="estimatePackerUnPacking_hours" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerUnPacking_cost_per_hour">Pay per Hours</label>
                                <input id="estimatePackerUnPacking_cost_per_hour" [(ngModel)]="estimatePackerUnPacking.cost_per_hour"
                                    placeholder="Pay per Hours" class="form-control" type="number" min="0"
                                    name="estimatePackerUnPacking_cost_per_hour" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="savePackersUnPacking()" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal Packers Unpacking -->
<div class="modal fade" id="storage" tabindex="-1" role="dialog"
    aria-labelledby="packersunpackingTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="packersunpackingTitle">
                        Storage Configuration
                    </h5>
                    <button class="btn btn-danger" data-dismiss="modal" (click)="removeStorage()">
                        <i class="fa-solid fa-trash-can"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="msfpcpm_quantity">Monthly Storage Fee per CF per Mo</label>
                                <input id="msfpcpm_quantity"
                                    [(ngModel)]="estimateStorages.monthly_fee_by_cf" type="number" min="0"
                                    placeholder="Enter fee" class="form-control"
                                    name="msfpcpm_quantity" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="estimatePackerUnPacking_hours">Storage Months</label>
                                <input id="storage_mounths" [(ngModel)]="estimateStorages.storage_months"
                                    type="number" min="0" placeholder="Enter storage months" class="form-control"
                                    name="storage_mounths" required>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="label">Label First Month Free</label>
                                <input id="label_first_month_free" [(ngModel)]="estimateStorages.label_first_month_free"
                                    placeholder="Enter first month free" class="form-control" type="text"
                                    name="label_first_month_free" required>
                            </div>
                        </div>
                        <!-- <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="firs_month_free">First Month Free</label>
                                <input id="firs_month_free" [(ngModel)]="estimateStorages.first_month_free"
                                class="form-control" type="checkbox" name="firs_month_free" required>
                            </div>
                        </div> -->
                        <div class="form-group col-md-6 mt-4">
                            <div class="toggle-switch mt-3">
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="firs_month_free" name="firs_month_free" [(ngModel)]="estimateStorages.first_month_free">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="firs_month_free" class="checkbox-label" [ngClass]="{'filter-disabled': !estimateStorages.first_month_free}">
                                    First Month Free
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="saveStorages()" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal Moving-->
<div #movingModal class="modal fade" id="moving" tabindex="-1" role="dialog" aria-labelledby="movingTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="movingTitle">Moving</h5>
            </div>
            <div class="modal-body row">
                <div class="col-6">
                    <h4 class="title-moving-from">
                        <strong>From</strong>
                    </h4>
                    <app-search-address (fullAddress)="pickupToEdit.address.full_address"
                        [(ngModel)]="pickupToEdit.address.full_address"
                        [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                        [oldAddress]="pickupToEdit.address" (onChange)="setFrom($event)"></app-search-address>
                </div>
                <div class="col-6">
                    <h4 class="title-moving-to">
                        <strong>To</strong>
                    </h4>
                    <app-search-address (fullAddress)="deliveryToEdit.address.full_address"
                        [(ngModel)]="deliveryToEdit.address.full_address"
                        [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                        [oldAddress]="deliveryToEdit.address" (onChange)="setTo($event)"></app-search-address>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveMoving()" [hidden]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                    data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add additional concept-->
<div class="modal fade" #additionalsModal tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-additional-concept" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        <span *ngIf="concept.id == null">Add</span>
                        <span *ngIf="concept.id !== null">Edit</span>
                        Concept - {{estimate.volume.cubic_feets_selled}}CF
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="concept_name">Concept</label>
                                <input [(ngModel)]="concept.name" type="text" placeholder="Concept" class="form-control"
                                    name="concept_name" id="concept_name">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="concept_amount">Amount</label>
                                <input #conceptAmount [(ngModel)]="concept.amount" type="number"
                                placeholder="Positive or Negative Amount" step="0.01" class="form-control"
                                name="concept_amount" id="concept_amount">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="concept_type">Apply</label>
                                <select name="concept_type" id="concept_type" [(ngModel)]="concept.type" class="form-control">
                                    <option value="PICKUP"> Pickup </option>
                                    <option value="DELIVERY"> Delivery </option>
                                    <option value="NOT_APPLY"> Not Apply </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="setDataConcept(true, 'CHARGE')" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal Add additional service-->
<div class="modal fade" #additionalsModalService tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-additionals" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle" *ngIf="tariffType == 'DISCOUNT'">
                        Add Discount - {{estimate.volume.cubic_feets}}CF
                    </h5>
                    <h5 class="modal-title" id="newConceptTitle" *ngIf="tariffType == 'CHARGE'">
                        Add Charge - {{estimate.volume.cubic_feets}}CF
                    </h5>
                </div>
                <div class="modal-body">
                    <ul class="list-group ">
                        <ng-container *ngFor="let option of dataTariffService">
                            <li class="list-group-item item" *ngIf="estimate.service != 'LOCAL'"
                                (click)="selectTariffAdditional(option)">
                                {{option.name}}
                            </li>
                            <ng-container *ngIf="estimate.service == 'LOCAL'">
                                <li class="list-group-item item" *ngIf="!option.by_cubic_feet"
                                    (click)="selectTariffAdditional(option)">
                                    {{option.name}}
                                </li>
                            </ng-container>

                        </ng-container>

                        <!-- <li class="list-group-item cursor-pointer"
                            *ngFor="let option of additionalServicesOptions; let i = index" value="{{i}}"
                            (click)="selectAdditionalService(i)">
                            {{option}}
                        </li> -->
                    </ul>
                </div>
                <div class="modal-footer">
                    <button (click)="closeModalAdditionalServices()" data-dismiss="modal" type="button" class="btn btn-cancel">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal Tariff-->
<div class="modal fade" id="extra" tabindex="-1" role="dialog" aria-labelledby="extraTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-tariff" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Tariff
                </h5>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="estimate.service != 'LOCAL'">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="typeTariff">Type Tariff</label>
                                <select name="typeTariff" [(ngModel)]="estimate.estimate_route_tariff.tariff_version.measure_unit" id="typeTariff"
                                    class="form-control" (change)="changeCostCfToLbs()">
                                    <option value="CUBIC_FEET">CUBIC FEET</option>
                                    <option value="POUNDS">POUNDS</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="price" *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cubic Feet Price</label>
                                <label class="control-label" for="price" *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs Price</label>
                                <input [(ngModel)]="estimate.estimate_route_tariff.cf_cost" type="number" class="form-control" id="price"
                                    placeholder="Write Here the Positive or Negative Amount" step="0.01" name="price">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="fuelSurchargePercentage">Fuel surcharge percentage</label>
                                <input [(ngModel)]="estimate.charges.fuel_surcharge_percentage" type="number"
                                    placeholder="Write Here the Positive or Negative Amount" step="0.01" id="fuelSurchargePercentage"
                                    class="form-control" name="fuelSurchargePercentage">
                            </div>
                        </div>
                        <div class="col-6" *ngIf="workspaceSettings.lock_the_rate">
                            <div class="form-group mb-4">
                                <label class="control-label">Lock the CF/LBS Rate</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="feather feather-lock">
                                                <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                                <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <select name="lock_the_rate" [(ngModel)]="lock_the_rate"
                                        id="lock_the_rate" class="form-control">
                                        <option value="YES">Yes</option>
                                        <option value="NO">No</option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Define If to Lock the Cubic Feet or Weight Tariff After it is Set in an Estimate.</small>
                            </div>
                        </div>

                    </div>
                </ng-container>
                <ng-container *ngIf="estimate.service == 'LOCAL'">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-4">
                                <label class="control-label" for="localFuelSurcharge">Local Fuel Surcharge</label>
                                <input [(ngModel)]="localFuelSurcharge" type="number" placeholder="Concept"
                                    class="form-control" placeholder="write here the positive amount" step="0.01"
                                    name="localFuelSurcharge" id="localFuelSurcharge">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveExtra()" data-dismiss="modal" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Tariff-->
<div class="modal fade" id="modalSetTariffDiscount" #modalSetTariffDiscount tabindex="-1" role="dialog" aria-labelledby="modalSetTariffDiscountTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-tariff" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Set Tariff Discount
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <div class="form-group mb-4">
                            <label class="control-label" for="typeTariff">Type Tariff Discount</label>
                            <select name="typeTariffDiscount" [(ngModel)]="estimateTariffDiscount.type" id="typeTariffDiscount"
                                class="form-control">
                                <option value="">Select Discount Type</option>
                                <option value="PERCENTAGE">By Percentage</option>
                                <option value="VALUE">By Value</option>
                                <option value="RATE">By Rate</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group mb-4">
                            <label class="control-label" for="tariff_discount_value">Tariff Discount Value</label>
                            <input [(ngModel)]="estimateTariffDiscount.value" type="number" class="form-control" id="tariff_discount_value"
                                placeholder="Write Here the Percentage or Value Amount" step="0.01" name="tariff_discount_value">
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveTariffDiscount()" data-dismiss="modal" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Auto Transport -->
<div class="modal fade" #autoTransportModal tabindex="-1" role="dialog" aria-labelledby="autoTransportModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-auto-transport" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Auto Transport
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" id="select-reorder">
                            <label for="autotransport_type">Type</label>
                            <div class="list-title">
                                <select id="autotransport_type" [(ngModel)]="autoTransport.type"
                                    placeholder="Type" class="form-control" name="autotransport_type"
                                    [theme]="'mxp360-form'" [appSelect2] [reorder]="true"
                                    [theme]="'mxp360-form'">
                                    <option *ngFor="let typeVehicle of vechicleType"
                                        value="{{typeVehicle.type}}"
                                        [selected]="typeVehicle.type == autoTransport.type">
                                        {{typeVehicle.type}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="autotransport_model">Model</label>
                            <div class="list-title">
                                <input id="autotransport_model" [(ngModel)]="autoTransport.model" type="text"
                                    placeholder="Model" class="form-control" name="autotransport_model">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="autotransport_price">Price</label>
                            <div class="list-title">
                                <input id="autotransport_price" [(ngModel)]="autoTransport.price" type="number"
                                    step="0.01" placeholder="Price" class="form-control"
                                    name="autotransport_price">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="autotransport_deposit">Deposit</label>
                            <div class="list-title">
                                <input id="autotransport_deposit" [(ngModel)]="autoTransport.deposit"
                                    type="number" step="0.01" placeholder="Deposit" class="form-control"
                                    name="autotransport_deposit">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="autotransport_car_transporter_type">Trailer Type</label>
                            <div class="list-title">
                                <select id="autotransport_car_transporter_type"
                                    [(ngModel)]="autoTransport.car_transporter_type" placeholder="Type"
                                    class="form-control" name="autotransport_car_transporter_type">
                                    <option value="OPEN_TRAILER"
                                        [selected]="'OPEN_TRAILER' == autoTransport.car_transporter_type">
                                        Open trailer</option>
                                    <option value="ENCLOSED_TRAILER"
                                        [selected]="'ENCLOSED_TRAILER' == autoTransport.car_transporter_type">
                                        Enclosed trailer</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="autotransport_brand">Brand</label>
                            <div class="list-title">
                                <input id="autotransport_brand" [(ngModel)]="autoTransport.brand" type="text"
                                    placeholder="Brand" class="form-control" name="autotransport_brand">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="autotransport_year">Year</label>
                            <div class="list-title">
                                <input id="autotransport_year" [(ngModel)]="autoTransport.year" type="text"
                                    placeholder="Year" class="form-control" name="autotransport_year">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="autotransport_year">VIN</label>
                            <div class="list-title">
                                <input id="autotransport_vin" [(ngModel)]="autoTransport.vin" type="text"
                                    placeholder="VIN" class="form-control" name="autotransport_vin">
                            </div>
                        </div>
                        <div class="form-group mt-5">
                            <div class="list-title">
                                <label class="new-control new-checkbox checkbox-warning" style="width: 100px;">
                                    <input type="checkbox" class="new-control-input"
                                        [(ngModel)]="autoTransport.can_run">
                                    <span class="new-control-indicator"></span>
                                    Can Run
                                </label>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label for="autotransport_total_price">Total price</label>
                            <div class="list-title">
                                <input id="autotransport_total_price" [(ngModel)]="autoTransport.total_price" type="number" readonly placeholder="Total price" class="form-control" name="autotransport_total_price">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveAutoTransport()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Level Of Liability -->
<div class="modal fade" #levelOfLiabilityModal tabindex="-1" role="dialog" aria-labelledby="levelOfLiability"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" id="big-modal" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Level of Liability
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content" id="levelOfLiabilityTitle">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label">Estimate</label>
                                    <div class="output-box">
                                        {{estimate.document.code}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>Customer Name</label>
                                <div class="output-box">
                                    {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                                </div>
                            </div>
                        </div>


                        <!-- Full Value Protection -->
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="full-value-protection">
                                    <div class="n-chk">
                                        <label class="new-control new-radio new-radio-text radio-level-liability">
                                            <input type="radio" class="new-control-input" name="insurance" id="full_value_protection"
                                            (click)="setInsuranceType('FULL_VALUE_PROTECTION')"
                                            [checked]="estimate.insurance?.type == 'FULL_VALUE_PROTECTION'">
                                            <span class="new-control-indicator"></span>
                                            <span class="new-radio-content">Full Value Protection</span>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="section-full-value-protection" [ngClass]="{'selected': currentInsuranse.type == 'FULL_VALUE_PROTECTION'}">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group mb-4">
                                        <label for="" class="control-label">Minimum Amount of Protection</label>
                                        <div class="output-box">
                                            {{calculatedLiabilityAmount | currency}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-4">
                                        <label for="" class="control-label">Calculation Minimum Amount of Protection</label>
                                        <div class="calculation-box">
                                            {{estimate.volume.libs}} lbs X ${{defaultAmountLiability}} = {{calculatedLiabilityAmount | currency}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group mb-0 declared-amount-protection">
                                        <label class="control-label">Declared Amount of Protection</label>
                                        <input class="form-control" type="number"
                                            [(ngModel)]="manualInsuranse" (keyup)="setCurrentInsurance()"
                                            [disabled]="currentInsuranse.type != 'FULL_VALUE_PROTECTION'">
                                        <small id="emailHelp1" class="form-text text-muted">Enter the Declared Amount of Protection.</small>
                                    </div>

                                    <button class="btn btn-success button-calculate" (click)="getInsuranses(false)"
                                            [disabled]="currentInsuranse.type != 'FULL_VALUE_PROTECTION'"
                                            *ngIf="arrayFullProtection.length > 0">
                                        Calculate
                                    </button>
                                </div>
                                <div class="col-md-6">

                                        <!-- Example for a Deductible -->
                                        <div *ngIf="arrayFullProtection.length == 0">
                                            <!-- Example for a Deductible of $2000 : <strong>{{estimate.total}} X 110.00 / 1000 =
                                                {{estimate.total * 110 / 1000}}</strong> -->

                                            <label for="" class="control-label">Example for a Deductible of $2000</label>
                                            <div class="calculation-box">
                                                <div>
                                                    {{estimate.total}} X 110.00 / 1000 = {{estimate.total * 110 / 1000}}
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Calculation for Deductibles -->
                                        <div *ngIf="arrayFullProtection.length > 0">
                                            <label for="" class="control-label">Calculation for Deductibles</label>
                                            <div class="calculation-deductibles-box">
                                                <div *ngFor="let fullP of arrayFullProtection">
                                                    {{fullP.deductible_amount | currency}}: {{fullP.rate}} X {{fullP.liability_amount}} / 1000 =
                                                    <span class="total-calculation">{{fullP.valuation_charge | currency}}</span>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                            </div>
                            <br>
                            <div class="row">

                                <div class="col-12">
                                    <div class="table-deductible-liability">
                                        <table class="table table-bordered table-striped mb-0 text-center">
                                            <thead>
                                                <tr>
                                                    <th>Deductible Liability</th>
                                                    <th *ngFor="let deductibleLevel of arrayFullProtection">
                                                        {{deductibleLevel.deductible_amount | currency}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Valuation Charge
                                                    </td>
                                                    <td *ngFor="let fullProtection of arrayFullProtection">
                                                        {{fullProtection.valuation_charge | currency}}
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>
                                                        Select Deductible
                                                    </td>
                                                    <td *ngFor="let fullProtectionCheck of arrayFullProtection">
                                                        <div class="n-chk">
                                                            <label class="new-control new-radio radio-level-liability">
                                                                <input type="radio" class="new-control-input" name="deductible"
                                                                (click)="selectLiabilityAmount(fullProtectionCheck)"
                                                                [checked]="estimate.insurance?.deductible_amount == fullProtectionCheck.deductible_amount"
                                                                [disabled]="currentInsuranse.type != 'FULL_VALUE_PROTECTION'">
                                                                <span class="new-control-indicator"></span> &nbsp;
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="alert-select-option col-12" *ngIf="currentInsuranse.type == 'FULL_VALUE_PROTECTION' && currentInsuranse.liability_amount == 0">
                                    <span class="badge badge-danger">* Choose an Option Deductible</span>
                                </div>

                            </div>
                        </div>

                        <!-- Release Value -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="release-value">
                                    <div class="n-chk">
                                        <label class="new-control new-radio new-radio-text radio-level-liability">
                                            <input type="radio" class="new-control-input" name="insurance" id="release_value"
                                            (click)="setInsuranceType('RELEASE_VALUE')"
                                            [checked]="estimate.insurance?.type == 'RELEASE_VALUE'">
                                            <span class="new-control-indicator"></span>
                                            <span class="new-radio-content">Release Value</span>
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="section-release-value" [ngClass]="{'selected': currentInsuranse.type == 'RELEASE_VALUE'}">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="output-box">
                                        (60-cents protection)
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer text-center ">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveInsurance()"
                    [disabled]="!(currentInsuranse.liability_amount > 0 || currentInsuranse.type != 'FULL_VALUE_PROTECTION') "
                    data-dismiss="modal" class="btn btn-save">
                    Set Insurance
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add Follow Up -->
<div class="modal fade" #modalfollowUpsReminders id="modalfollowUpsReminders" tabindex="-1" role="dialog"
    aria-labelledby="customerremarksTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content mt-140">
            <div class="modal-header">
                <h5 class="modal-title" id="customerremarksTitle">
                    Add Follow Up
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <div class="form-group col-md-6 p-0">
                        <label for="inputState">
                            Date
                        </label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                </span>
                            </div>
                            <app-date-picker class="width-date-picker follow-up-date-picker" [enableTime]="true"
                                [value]="followUpReminder.date" [placeholder]="'Not Assigned'"
                                (onChange)="changeDate($event)">
                            </app-date-picker>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">select Date.</small>
                    </div>
                </div>
                <div class="">
                    <label for="inputState">
                        Comment
                    </label>
                    <div class="input-group mb-1">
                        <textarea class="form-control" name="estimate_customer_remarks" id="estimate_customer_remarks"
                            [(ngModel)]="followUpReminder.remarks" placeholder="Write your comment here" cols="30"
                            rows="5">
                        </textarea>
                    </div>
                    <small id="emailHelp1" class="form-text text-muted mb-4">Write Comment.</small>
                </div>
                <div class="">
                    <div class="toggle-switch mt-3">
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="qa_appointment" name="qa_appointment" [(ngModel)]="followUpReminder.qa_appointment">
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="qa_appointment" class="checkbox-label" [ngClass]="{'filter-disabled': !followUpReminder.qa_appointment}">
                            Set as QA Appointment
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addFollowUpReminder()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Edit Contact -->
<div class="modal" #modalLead id="modalLead" tabindex="-1" role="dialog" aria-labelledby="customerremarksTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <form class="form" #entityForm="ngForm" (ngSubmit)="saveChangesLead()" ngNativeValidate>
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="customerremarksTitle">Edit Contact</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-1">
                                <label for="inputState">
                                    Name
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input id="edit_name_customer" [(ngModel)]="customerToEdit.name" type="text" placeholder="Brand"
                                        class="form-control" name="edit_name_customer" pattern="[A-Za-z ]+" required>
                                </div>
                                <small class="form-text text-muted mb-4">Edit Name Customer (Only Letters)</small>
                            </div>
                        </div>
                        <div class="col-6 ">
                            <div class="form-group mb-1">
                                <label for="inputState">
                                    Last Name
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input id="edit_last_name_customer" [(ngModel)]="customerToEdit.last_name" type="text" required
                                        placeholder="Brand" class="form-control" name="edit_last_name_customer" pattern="[A-Za-z ]+">
                                </div>
                                <small class="form-text text-muted mb-4">Edit LastName Customer (Only Letters)</small>
                            </div>
                        </div>
                        <div class="col-6 ">
                            <div class="form-group mb-1">
                                <label for="inputState">
                                    Email
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-mail">
                                                <path
                                                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                </path>
                                                <polyline points="22,6 12,13 2,6"></polyline>
                                            </svg></span>
                                    </div>
                                    <input id="edit_email_customer" [(ngModel)]="customerToEdit.email" type="email" placeholder="Brand"
                                        class="form-control" name="edit_email_customer" required>
                                </div>
                                <small class="form-text text-muted mb-4">Edit Email Customer</small>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-1">
                                <label for="inputState">
                                    Phone
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-phone">
                                                <path
                                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                </path>
                                            </svg></span>
                                    </div>
                                    <input (ngModelChange)="validateForm()" type="text" maxlength="10" id="edit_phone_customer"
                                        [(ngModel)]="customerToEdit.phone" onlyNumbers type="text" placeholder="Brand" class="form-control"
                                        name="edit_phone_customer" required>
                                </div>
                                <small class="form-text text-muted mb-4">Edit Phone Customer</small>
                            </div>

                        </div>
                        <div class="col-12">
                            <div>
                                <label for="inputState">
                                    Remarks
                                </label>
                                <div class="list-title">
                                    <textarea id="edit_remarks_customer" placeholder="Enter Description" rows="3"
                                        class="form-control" name="edit_remarks_customer"
                                        [(ngModel)]="customerToEdit.remarks"></textarea>
                                    <small class="form-text text-muted mb-4">Edit Remarks Customer</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-save">
                        Save
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Modal add additional discount-->
<div class="modal fade" #discountModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-add-discount" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Add Discount - {{estimate.volume.cubic_feets_selled}}CF
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="concept_name">Concept</label>
                                <input [(ngModel)]="concept.name" type="text" placeholder="Concept" class="form-control"
                                    name="concept_name" id="concept_name">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="concept_amount">Amount</label>
                                <input #conceptAmount [(ngModel)]="concept.amount" type="number"
                                    placeholder="Positive or Negative Amount" step="0.01" class="form-control"
                                    name="concept_amount" id="concept_amount">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group mb-4">
                                <label class="control-label" for="concept_type">Apply</label>
                                <select name="concept_type" id="concept_type" [(ngModel)]="concept.type" class="form-control">
                                    <option value="PICKUP"> Pickup </option>
                                    <option value="DELIVERY"> Delivery </option>
                                    <option value="NOT_APPLY"> Not Apply </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" data-dismiss="modal">
                        Cancel
                    </button>
                    <button (click)="setDataConcept(true, 'DISCOUNT')" data-dismiss="modal" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal add additional floor-->
<div class="modal fade register-modal" #floorModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle" *ngIf="typeFloor == 'pickUp'">
                        Add Floor at Pickup
                    </h5>
                    <h5 class="modal-title" id="newConceptTitle" *ngIf="typeFloor == 'delivery'">
                        Add Floor at Delivery
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg></button>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">
                            <div class="row">
                                <div class="col-md-3">
                                    <h4> <br> Stair fee Flight #</h4>
                                </div>
                                <div class="col-md-9">
                                    <input #conceptAmount [(ngModel)]="concept.amount" type="number"
                                        placeholder="Stair fee Flight" class="form-control" name="concept.amount">
                                    <span>Cost concept from the 3rd floor onwards</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <br>
                                <br>
                                <h4
                                    *ngIf="(stairFeeByFloor * concept.amount) - (stairFeeByFloor + stairFeeByFloor) > 0">
                                    Stair Fee on the {{concept.amount}}th floor $
                                    {{(stairFeeByFloor * concept.amount) - (stairFeeByFloor + stairFeeByFloor)}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footerr">
                    <button (click)="floor(concept.amount)" data-dismiss="modal" type="button"
                        class="btn btn-save mt-2 mb-2 btn-block">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal form estimate stop points -->
<div class="modal fade" id="stopPointModal" #stopPointModal tabindex="-1" role="dialog"
    aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add Stop Point
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-12">
                        <div class="form-group mb-4">
                            <label class="control-label">Type</label>
                            <select (onChange)="validateEnableButtonSaveStopPoint()"
                                name="estimateStopPoint.type" class="form-control"
                                [(ngModel)]="estimateStopPoint.type">
                                <option value="PICKUP">
                                    Pickup
                                </option>
                                <option value="DELIVERY">
                                    Delivery
                                </option>
                            </select>
                        </div>

                        <div class="form-group mb-4">
                            <app-search-address (fullAddress)="estimateStopPoint.address.full_address"
                                [(ngModel)]="estimateStopPoint.address.full_address"
                                [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                                [oldAddress]="estimateStopPoint.address" (onChange)="addAddressStopPoint($event)"></app-search-address>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeStopPointModal()" class="btn btn-cancel" data-dismiss="modal">
                    Discard
                </button>
                <button [disabled]="disableButtonSaveStopPoint" data-dismiss="modal" class="btn btn-primary"
                    (click)="saveStopPoint()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- modal de contacts -->
<div class="modal fade contactsModal" #contactsModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="compose-box">

                    <div class="compose-content content-select">
                        <h5 class="add-task-title">Set new Contact</h5>

                        <div class="row">
                            <div class="col-xl-9 col-lg-10 col-md-8">
                                <div class="search-input-group-style input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-search">
                                                <circle cx="11" cy="11" r="8"></circle>
                                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" #searchField class="form-control" name="search_item"
                                        [(ngModel)]="searchCustomer" (keyup)="loadCustomers()"
                                        placeholder="Search here your customer" aria-label="Username"
                                        aria-describedby="basic-addon1">
                                </div>
                            </div>
                        </div>


                        <h5 class="sub-title">
                            Customers
                        </h5>

                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>
                                        Full Name
                                    </th>
                                    <th class="text-center">
                                        Email
                                    </th>
                                    <th class="text-center">
                                        Phone
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of customers | search:searchCustomer | async">
                                    <tr (click)="setNewContct(item)">
                                        <td>
                                            {{item.name | titlecase}} {{item.last_name | titlecase}}
                                        </td>
                                        <td class="text-center">
                                            {{item.email}}
                                        </td>
                                        <td class="text-center">
                                            {{item.phone | phone:'US'}}
                                        </td>
                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" (click)="closeContactsModal()">
                    Discard
                </button>
            </div>
        </div>
    </div>
</div>

<!-- modal de contacts -->
<div class="modal fade newContactsModal" #newContactsModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <form class="form" #entityForm="ngForm" (ngSubmit)="saveNewCustomer()" ngNativeValidate>
                <div class="modal-header">
                    <h5 class="add-task-title">New Contact</h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content content-select">
                            <div class="row">
                                <div class="col-xl-12 col-lg-6 col-md-6 overflow-content">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label>Contact Name: (Only Letters)</label>
                                                <div class="d-flex">
                                                    <input id="cus_name" pattern="[A-Za-z ]+" [(ngModel)]="newCustomer.name" type="text"
                                                        placeholder="Name" class="form-control" name="cus_name" required>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Contact Lastname: (Only Letters)</label>
                                                <div class="d-flex">
                                                    <input id="cus_last_name" pattern="[A-Za-z ]+" [(ngModel)]="newCustomer.last_name"
                                                        type="text" placeholder="Lastname" class="form-control" required
                                                        name="cus_last_name">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label>Contact email</label>
                                                <div class="d-flex">
                                                    <input id="cus_email" [(ngModel)]="newCustomer.email" type="email" required
                                                        placeholder="email" class="form-control" name="cus_email"
                                                        (blur)="validateContact('EMAIL')">
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Contact Phone</label>
                                                <div class="d-flex">
                                                    <input id="cus_phone" [(ngModel)]="newCustomer.phone" type="text"
                                                        placeholder="Phone" class="form-control" name="cus_phone"
                                                        (blur)="validateContact('PHONE')" required onlyNumbers maxlength="10">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label>Remarks</label>
                                                <textarea class="form-control" id="cus_remarks"
                                                    [(ngModel)]="newCustomer.remarks" type="text" placeholder="Remarks"
                                                    class="form-control" name="cus_remarks" remarks>
                                                </textarea>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <button type="button" class="btn btn-info btn-block btn-search-contact"
                                                    (click)="openContactsModal()">
                                                    Search for a Contact
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-cancel" (click)="closeNewContactsModal()">
                        Cancel
                    </button>
                    <button class="btn btn-save" type="submit">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal map routes -->
<div class="modal fade size-modal" id="modalMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog size-modal max-width-modal-dialog" role="document">
        <div class="modal-content size-modal">

            <div class="modal-header header-map">
                <h5 class="modal-title" id="exampleModalLabel">Route Delivery</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body header-map">
                <div id="map" #mapView class="map"></div>
            </div>

        </div>
    </div>
</div>

<!-- Modal Add New Carrier Notes -->
<div class="modal fade" #modalCarrierNotes tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add New Carrier Notes
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="inputState">
                        Note
                    </label>
                    <div class="list-title">
                        <app-ck-editor [(ngModel)]="carrierNote.note" name="content" ngDefaultControl>
                        </app-ck-editor>
                    </div>
                </div>
                <div class="mt-4 carrier--type-notes">
                    <label for="autotransport_type">Type</label>
                    <div class="list-title">
                        <select id="carrier-note-visualization" [(ngModel)]="carrierNote.visibility"
                            placeholder="Type" class="form-control" name="autotransport_type" [theme]="'mxp360-form'"
                            [appSelect2] [reorder]="true" [theme]="'mxp360-form'">
                            <option value="PUBLIC" [selected]="carrierNote.visibility == 'PUBLIC'">
                                Public
                            </option>
                            <option value="PRIVATE" [selected]="carrierNote.visibility == 'PRIVATE'">
                                Private
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveCarrierNote()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Adjust Max Discount -->
<div class="modal fade" #maxDiscountModal tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Adjust Max Discount
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="inputState">
                        Max Discount
                    </label>
                    <div class="list-title">
                        <input type="text" name="estimate_route_tariff-cf_discount" class="form-control"
                            [(ngModel)]="estimate.estimate_route_tariff.cf_discount">
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveCustomDiscount()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Freight -->
<div class="modal fade" #movingFreightModal tabindex="-1" role="dialog" aria-labelledby="autoTransportModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Freight
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_type">Equipament</label>
                                    <div class="list-title">
                                        <select [(ngModel)]="estimateFreight.equipment"
                                            placeholder="Type" class="form-control" name="freights_equipament"
                                            [theme]="'mxp360-form'" [appSelect2]  [reorder]="true"
                                            [theme]="'mxp360-form'">
                                            <option value="V">Van</option>
                                            <option value="F">Flatbed</option>
                                            <option value="R">Refigerated (Reefer)</option>
                                            <option value="VR">Van or Reefer</option>
                                            <option value="VRF">Flatbed, Van or Reefer</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_type">Mode</label>
                                    <div class="list-title">
                                        <select  [(ngModel)]="estimateFreight.mode"
                                            placeholder="Type" class="form-control" name="mode"
                                            [theme]="'mxp360-form'" [appSelect2] [reorder]="true"
                                            [theme]="'mxp360-form'">
                                            <option value="Truck Load">Truck Load</option>
                                            <option value="Less Than Truck Load">Less Than Truck Load</option>
                                            <option value="Intermodal">Intermodal</option>
                                            <option value="Partial">Partial</option>
                                            <option value="Drayage">Drayage</option>
                                            <option value="Parcel">Parcel</option>
                                            <option value="Air">Air</option>
                                            <option value="Water">Water</option>
                                            <option value="Ocean">Ocean</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="autotransport_model">Length</label>
                                        <div class="list-title">
                                            <input id="autotransport_model" [(ngModel)]="estimateFreight.length" type="text"
                                                placeholder="ft" class="form-control" name="length">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="autotransport_model">Width</label>
                                        <div class="list-title">
                                            <input id="autotransport_model" [(ngModel)]="estimateFreight.width" type="text"
                                                placeholder="ft" class="form-control" name="width">
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">All-in Rate</label>
                                    <div class="list-title">
                                        <input id="autotransport_brand" [(ngModel)]="estimateFreight.all_in_rate" type="text"
                                            placeholder="$" class="form-control" name="all_in_rate">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_year">Weight</label>
                                    <div class="list-title">
                                        <input id="autotransport_year" [(ngModel)]="estimateFreight.weight" type="text"
                                            placeholder="lbs" class="form-control" name="freight.weight">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="autotransport_height">Height</label>
                                        <div class="list-title">
                                            <input id="autotransport_height" [(ngModel)]="estimateFreight.height" type="text"
                                                placeholder="ft" class="form-control" name="height">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="autotransport_model">Cube</label>
                                        <div class="list-title">
                                            <input id="autotransport_cubic_feet" [(ngModel)]="estimateFreight.cubic_feet" type="text"
                                                placeholder="cu.Ft" class="form-control" name="cube">
                                        </div>
                                    </div>

                                </div>

                                <!-- <div class="form-group">
                                    <label for="autotransport_total_price">Total price</label>
                                    <div class="list-title">
                                        <input id="autotransport_total_price" [(ngModel)]="autoTransport.total_price" type="number" readonly placeholder="Total price" class="form-control" name="autotransport_total_price">
                                    </div>
                                </div> -->
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">Pieces</label>
                                    <div class="list-title">
                                        <input [(ngModel)]="estimateFreight.pieces" type="text"
                                            placeholder="" class="form-control" name="pieces">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">Pallets</label>
                                    <div class="list-title">
                                        <input [(ngModel)]="estimateFreight.pallets" type="text"
                                            placeholder="" class="form-control" name="pallets">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Load Options</label>
                                    <div class="list-title">
                                        <select  [(ngModel)]="estimateFreight.load_options"
                                            placeholder="Type" class="form-control" name="load_options"
                                            [theme]="'mxp360-form'" [appSelect2] [reorder]="true"
                                            [theme]="'mxp360-form'">
                                            <option value="Tarps">Tarps</option>
                                            <option value="Hazardous">Hazardous</option>
                                            <option value="Intermodal">Intermodal</option>
                                            <option value="PalletExchange">PalletExchange</option>
                                            <option value="Team">Team</option>
                                            <option value="Expedited">Expedited</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="autotransport_brand">Other Equipment Needs</label>
                                    <div class="list-title">
                                        <input [(ngModel)]="estimateFreight.other_equipment_needs" type="text"
                                            placeholder="" class="form-control" name="other_equipment_needs">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="autotransport_brand">Special Information</label>
                                    <div class="list-title">
                                        <textarea name="special_information" class="form-control"
                                            [(ngModel)]="estimateFreight.special_information"
                                            placeholder="Write an Update"></textarea>
                                    </div>
                                </div>
                            </div>
                                <div class="col-md-8">
                                        <div class="form-group">
                                            <label for="autotransport_brand">Commodity</label>
                                            <div class="list-title">
                                                <input [(ngModel)]="estimateFreight.commodity" type="text"
                                                    placeholder="" class="form-control" name="commodity">
                                            </div>
                                        </div>
                                </div>
                                <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="autotransport_brand">Load Number</label>
                                            <div class="list-title">
                                                <input [(ngModel)]="estimateFreight.load_number" type="text"
                                                    placeholder="" class="form-control" name="load_number">
                                            </div>
                                        </div>
                                </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveFreight()" data-dismiss="modal" type="button" class="btn btn-primary">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Adjust Max Discount -->
<div class="modal fade" #editEstimateReferenceModal tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Set Estimate Reference
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="reference">
                        Reference
                    </label>
                    <div class="list-title">
                        <input type="text" name="reference" class="form-control" [(ngModel)]="editReference">
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveEstimateReference()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Inventory Items Local -->
<div class="modal fade" id="inventoryItemsLocalModal" #inventoryItemsLocalModal tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <button class="close-btn" data-dismiss="modal">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
            <div class="modal-body">
                <div class="body-content">
                    <div class="title-icon">
                        <i class="fa-solid fa-couch"></i>
                    </div>
                    <h5>
                        How Would You Like To Perform This Operation?
                    </h5>
                    <div class="option-btns">
                        <a (click)="openInventoryItems()" class="btn btn-info" data-dismiss="modal">
                            By Item
                        </a>
                        <a (click)="openQuickQuoteFormModal()" class="btn btn-success">
                            Quick Estimate
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal Quick Quote Form -->
<div class="modal fade" id="quickQuoteFormModal" #quickQuoteFormModal tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="body-content">
                    <div class="title-icon">
                        <i class="fa-solid fa-couch"></i>
                    </div>
                    <h5>
                        Quick Estimate Form
                    </h5>
                    <div class="col-12 marg-t50">
                        <div class="quick-quote-form">
                            <div class="row align-items-center mb-3" *ngFor="let localEstimateCharge of localEstimateCharges">
                                <div class="col-md-6">
                                    <div class="form-group m-0" *ngIf="localEstimateCharge.type == 'OPEN'">
                                        <input class="form-control" type="number" [ngClass]="selledFeets"
                                        [(ngModel)]="localEstimateCharge.assiged_data" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'"
                                        name="assiged_data_{{localEstimateCharge.id}}" />
                                    </div>
                                    <div class="form-group m-0" *ngIf="localEstimateCharge.type == 'CLOSED'">
                                        <select class="form-control" name="assiged_data_{{localEstimateCharge.id}}" [(ngModel)]="localEstimateCharge.assiged_data"
                                        (ngModelChange)="adviceChanges()" [disabled]="estimate.booked && estimate.qa_status !== 'RELEASE'">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group m-0">
                                        <span>{{localEstimateCharge.name | titlecase}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btns-quick-quote-form">
                        <a class="btn btn-cancel" (click)="closeQuickQuoteFormModal()">
                            Cancel
                        </a>
                        <a class="btn btn-success" (click)="saveQuickQuoteForm()">
                            Quick Estimate
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal local warehouse -->
<div class="modal fade" #localWarehouseModal tabindex="-1" role="dialog" aria-labelledby="localWarehouseModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Set a <span>{{warehouseSideType == 'DELIVERY' ? 'End' : 'Start'}}</span> Warehouse
                </h5>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <div class="list-title">
                        <label for="reference">
                            Warehouse Type
                        </label>
                        <select  [(ngModel)]="localWarehouseType"
                            placeholder="Type" class="form-control" name="load_options">
                            <option value="INTERNAL">Internal</option>
                            <option value="EXTERNAL">External</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3" *ngIf="localWarehouseType == 'INTERNAL'">
                    <label for="wharehouse_warehouse">
                        Warehouse
                    </label>
                    <select [(ngModel)]="localWarehouseWarehouseId"
                        placeholder="Warehouse" class="form-control" name="wharehouse_warehouse">
                        <option value="">Select</option>
                        <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="localWarehouseType == 'EXTERNAL'">
                    <label for="warehouse_external">
                        External Company
                    </label>
                    <select [(ngModel)]="localWarehouseCompanyId"
                        placeholder="External Company" class="form-control" name="warehouse_external">
                        <option value="">Select</option>
                        <option *ngFor="let storageCompany of storageCompanies" [value]="storageCompany.id">{{storageCompany.name}}</option>
                    </select>
                </div>
                <div class="mb-3" *ngIf="estimate.estimate_local_tariff?.quote_mode === 'MANUAL'">
                    <label for="total">
                        Total
                    </label>
                    <input type="number" name="total" class="form-control" [(ngModel)]="total" placeholder="Enter an Extension" value="total">
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveLocalWarehouse()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal Send Text Message -->
<div class="modal fade" #modalSendTextMessage id="modalSendTextMessage" tabindex="-1" role="dialog" aria-labelledby="modalSendTextMessage" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 600px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    New SMS Text
                </h5>
            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="col-md-12">
                        <div class="d-flex form-box">
                            <div class="icon">
                                <i class="fa-solid fa-phone-volume"></i>
                            </div>
                            <div class="form-group mb-4">
                                <label for="">Origin Number</label>
                                <select class="form-control" name="dataFormPhone_phoneNumberEmployee" placeholder="Type"  [(ngModel)]="dataFormPhone.phoneNumberEmployee" >
                                    <ng-container *ngFor="let employeeLine of extensions">
                                        <option value="{{employeeLine.numberSms}}">{{employeeLine.extension}}:{{employeeLine.numberSms| phone:'US'}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mb-4 pl-62">
                        <label for="">Custom Destination Number?</label>
                        <div class="toggle-switch custom-phone mt-1">
                            <label class="switch s-icons s-outline s-outline-secondary">
                                <input type="checkbox" id="customSmsDestinationNumber" name="offside_allowed" [(ngModel)]="customSmsDestinationNumber">
                                <span class="slider round">
                                    <i class="fa-solid fa-check icon-left"></i>
                                    <i class="fa-solid fa-xmark icon-right"></i>
                                </span>
                            </label>
                            <label for="customSmsDestinationNumber" class="checkbox-label checkbox-label-destination-phone">
                                <span *ngIf="customSmsDestinationNumber" class="active">Enabled</span>
                                <span *ngIf="!customSmsDestinationNumber" class="disabled filter-disabled">Disabled</span>
                            </label>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="customSmsDestinationNumber">
                        <div class="d-flex form-box" [ngClass]="{'phone-destination': customSmsDestinationNumber}">
                            <div class="icon">
                                <i class="fa-solid fa-phone"></i>
                            </div>
                            <div class="form-group mb-4">
                                <label for="">Destination Number Custom</label>
                                <div class="list-title">
                                    <input type="text" class="form-control" name="dataFormPhone_phoneNumberCustomer"  placeholder="Enter Custom Phone Number"  [(ngModel)]="dataFormPhone.phoneNumberCustomer">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" *ngIf="!customSmsDestinationNumber">
                        <div class="d-flex form-box">
                            <div class="icon">
                                <i class="fa-solid fa-phone"></i>
                            </div>
                            <div class="form-group mb-4">
                                <label for="">Destination Number Contact List</label>
                                <select class="form-control" name="" placeholder="Type"  name="dataFormPhone_phoneNumberCustomer"  placeholder="Enter Number Phone"  [(ngModel)]="dataFormPhone.phoneNumberCustomer">
                                    <option value="+1{{customer.phone}}">{{customer.name}}</option>
                                    <ng-container *ngFor="let estimateContact of estimate.contacts">
                                        <option value="+1{{estimateContact.phone}}">{{estimateContact.name}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="d-flex form-box">
                            <div class="icon">
                                <i class="fa-solid fa-comment-sms"></i>
                            </div>
                            <div class="form-group mb-4">
                                <label for="">SMS Template</label>
                                <select class="form-control" name="changeSmsTemplate" placeholder="Type"  (change)="changeSmsTemplate($event.target.value)">
                                    <option value="">Select an Option</option>
                                    <ng-container *ngFor="let smsTemplate of smsTemplates">
                                        <option value="{{smsTemplate.message}}">{{smsTemplate.name}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Message</label>
                            <textarea class="form-control" name="dataFormPhone_message" id="" cols="10" rows="5" [(ngModel)]="dataFormPhone.message"></textarea>
                        </div>
                    </div>

                    <!-- <div class="col-md-12">
                        <button class="btn-portal-link" (click)="changeAttachPortalLink()"></button>
                    </div> -->

                    <div class="col-md-12 mt-3">
                        <div class="toggle-switch">
                            <label class="switch s-icons s-outline s-outline-secondary">
                                <input type="checkbox" id="attach_estimate_link" name="attach_estimate_link"  value="true" [(ngModel)]="attachPortalLink" (change)="changeAttachPortalLink()">
                                <span class="slider round">
                                    <i class="fa-solid fa-check icon-left"></i>
                                    <i class="fa-solid fa-xmark icon-right"></i>
                                </span>
                            </label>
                            <label for="attach_estimate_link" class="checkbox-label">
                                <span class="active" [ngClass]="{'filter-disabled': !attachPortalLink}">Attach Portal Link</span>
                            </label>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button data-dismiss="modal" type="button" class="btn btn-save" (click)="sendMessage()">
                    Send
                </button>
            </div>
        </div>
    </div>
</div>
