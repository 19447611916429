import { Component, OnInit } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { Company } from 'src/app/entities/workspace/company';
import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { Employee } from 'src/app/entities/workspace/employee';
import { AuthService } from 'src/app/services/auth.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { DriverInvoicesService } from 'src/app/services/workspaces/driver-invoices.service';

declare var jQuery;
declare var moment;
@Component({
    selector: 'app-driver-invoices',
    templateUrl: './driver-invoices.component.html',
    styleUrls: ['./driver-invoices.component.scss']
})
export class DriverInvoicesComponent implements OnInit {
    
    public filterData: FilterData;
    public betweenDates: BetweenDates;
    public btnActived: 'ALL' | 'HOT' | 'WARM' | 'COLD' | 'NEW' | 'OPEN' | 'BOOKED' | 'WITHOUTFOLLOWUPS' | 'UNSUCCESSFUL_SALES';
    public paginator: Paginator;
    employees: Array<Employee>;
    public companies: Company[];
    public company: string;
    public fromStates: string[];
    public toStates: string[];
    public delivered: string;
    public paid: string;
    public filterDateFor: string;
    public start: number;
    public end: number;
    public searchJob: string;

    rows: Array<DriverInvoice>;
    actualPage = 1;
    filterSearch = '';
    constructor(
        private driverInvoicesService: DriverInvoicesService,
        private estimatesService: EstimatesService,
        private companiesService: CompaniesService,
        public authService: AuthService,
        private helperService: HelperService
    ) {
        this.filterData = new FilterData();
        this.filterData.filter_date_for = '';
        this.companies = [];
        this.company = '';
        this.fromStates = [];
        this.toStates = [];
        this.delivered = '';
        this.paid = '';
        this.filterDateFor = 'created_dated';
        this.start = 0;
        this.end = 0;
        this.searchJob = '';
    }

    ngOnInit(): void {
        this.loadCompanies();
    }

    ngAfterViewInit() {
        const that = this;

        // select2 multiple from states
        jQuery('.from-state').css('width', '100%');
        const selectFrom = jQuery('.from');
        let idTimeOut = null;

        selectFrom.select2({
            tags: true
        })
            .on('select2:select', (e) => {
                that.setFromStatesEnabled(e.params.data, 'select');
            })
            .on('select2:unselect', (e) => {
                that.setFromStatesEnabled(e.params.data, 'unselect');
            });

        // si hay cambios en el dom refrescamos el plugin
        selectFrom.bind('DOMSubtreeModified', () => {

            if (idTimeOut !== null) {
                clearTimeout(idTimeOut);
            }

            idTimeOut = setTimeout(() => {
                selectFrom.trigger('change');
            }, 1000);
        });

        // select2 multiple to states
        jQuery('.to-state').css('width', '100%');
        const selectTo = jQuery('.to');
        let idTimeOutTo = null;

        selectTo.select2({
            tags: true
        })
            .on('select2:select', (e) => {
                that.setToStatesEnabled(e.params.data, 'select');
            })
            .on('select2:unselect', (e) => {
                that.setToStatesEnabled(e.params.data, 'unselect');
            });

        // si hay cambios en el dom refrescamos el plugin
        selectTo.bind('DOMSubtreeModified', () => {

            if (idTimeOut !== null) {
                clearTimeout(idTimeOut);
            }

            idTimeOut = setTimeout(() => {
                idTimeOutTo.trigger('change');
            }, 1000);
        });
    }

    setToStatesEnabled(position, type: string) {
        
        const stateNamePosition = position.id.split(':')[1].trim().replace(/'/g, '');

        let exist = false;

        for (let index = 0; index < this.toStates.length; index++) {
          
            if(this.toStates[index] == stateNamePosition) {
                exist = true;
                this.toStates.splice(index, 1);
            }
        }

        if (!exist) {
            this.toStates.push(stateNamePosition);
        }
        
    }

    setFromStatesEnabled(position, type: string) {
        const stateNamePosition = position.id.split(':')[1].trim().replace(/'/g, '');
        
        let exist = false;

        for (let index = 0; index < this.fromStates.length; index++) {
          
            if(this.fromStates[index] == stateNamePosition) {
                exist = true;
                this.fromStates.splice(index, 1);
            }
        }

        if (!exist) {
            this.fromStates.push(stateNamePosition);
        }
    }


    remove(id) {
        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
            .remove(id)
            .then((response) => {
                this.getFilter();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    getFilterDates(dates){
        this.start = dates.dateStart;
        this.end = dates.dateEnd;
        this.getFilter();
    }

    getFilter() {

        const data: any = {
            'full-view': '1',
        };
       
        
        if (this.company) {
            data.company= this.company;
        }
        if (this.fromStates) {
            data.from_states = this.fromStates.toString();
        }
        if (this.toStates) {
            data.to_states = this.toStates.toString();
        }
        if (this.delivered) {
            data.delivered = this.delivered;
        }

        if (this.paid) {
            data.paid = this.paid;
        }
        if (this.filterDateFor) {
            data.filter_date_for = this.filterDateFor;
        }
        if (this.start) {
            data.start = this.start;
        }
        if (this.end) {
            data.end = this.end;
        }
       

        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
            .getFullView(data)
            .then((response) => {
                // this.rows = this.arraysToFrom(response);
                this.rows = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    filterByCategory(filter){

    }


    /**
     * Carga las Companies
     */
    private loadCompanies() {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll()
            .then((response) => {
                this.companies = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    validateDelivery(arr: any[]): boolean {
        if (arr.length == 0) {
            return false;
        }
        return arr.every(obj => obj.delivery_status === 'DELIVERED');
    }


  doSearchJob() {
    this.helperService.showLoadingMxpro360();
      this.driverInvoicesService
        .getByEstimateCode(this.searchJob)
        .then((response: any) => {
            this.rows = response;
        })
        .catch((error) => {
            this.helperService.showMessageSnackbar(error.error.message);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    arraysToFrom(arr){
        return arr.map(obj => {
            const uniqueItems = obj.items.reduce((acc, item) => {
                const exists = acc.find(i => i.from === item.from && i.to === item.to);
                if (!exists) {
                    acc.push(item);
                }
                return acc;
            }, []);
    
            return {
                ...obj,
                items: uniqueItems
            };
        });
        
    }

    filterSubItems(items: any[]): any[] {
        return items.reduce((acc, item) => {
          const exists = acc.find(i => i.from === item.from && i.to === item.to);
          if (!exists) {
            acc.push(item);
          }
          return acc;
        }, []);
      }

}
