<div class="layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="widget-heading d-flex justify-content-between">
            <h3 class="title-content-area">
                Completions
            </h3>
            <button class="btn btn-success btn-invoice ml-2" (click)="modalInvoice()" data-toggle="modal" data-target="#modalInvoice">
                <i class="fa-solid fa-file-invoice-dollar"></i>
                <span class="ml-2">Invoice</span> 
            </button>                   
        </div>

    </div>
</div>

<div class="row layout-spacing">

    <!-- Content -->
    <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 layout-top-spacing pr-0">

        <!-- Route -->
        <div class="bio pb-3">
            <div class="widget-content widget-content-area">

                <h3>
                    Route
                </h3>

                <!-- when the delivery address has not change-->
                <div class="row" *ngIf="!job.delivery_fee">
                    <div class="col-md-6 col-sm-12 ">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center">
                                        From
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="estimate.pickup.address.street">
                                    <th>
                                        Street:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.street | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.city">
                                    <th>
                                        City:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.city | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.state">
                                    <th>
                                        State:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.state | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.zip_code">
                                    <th>
                                        Zip code:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.zip_code}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.country">
                                    <th>
                                        Country:
                                    </th>
                                    <td>
                                        <ng-container *ngIf="estimate.pickup.address.country == 'Estados Unidos'">United
                                            States
                                        </ng-container>
                                        <ng-container *ngIf="estimate.pickup.address.country != 'Estados Unidos'">
                                            {{estimate.pickup.address.country | titlecase}}
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.property_type">
                                    <th>
                                        Property type:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.property_type | filterReplace}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.level | filterReplace">
                                    <th>
                                        Level:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.level}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.floor">
                                    <th>
                                        Floor:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.floor}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.apartment_number">
                                    <th>
                                        Apartment Number:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.apartment_number}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.storage_unit_number">
                                    <th>
                                        Storage Unit Number:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.storage_unit_number}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                    <div class="col-md-6 col-sm-12">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center" style="position: relative;">
                                        To
                                        <a title="Edit Moving From and To" href="#" class="btn btn-success btn-address" data-toggle="modal" data-target="#modalChangeAddress">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-3">
                                                <path d="M12 20h9"></path>
                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z">
                                                </path>
                                            </svg>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="estimate.delivery.address.street">
                                    <th>
                                        Street:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.street | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.city">
                                    <th>
                                        City:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.city | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.state">
                                    <th>
                                        State:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.state | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.zip_code">
                                    <th>
                                        Zip code:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.zip_code}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.country">
                                    <th>
                                        Country:
                                    </th>
                                    <td>
                                        <ng-container *ngIf="estimate.delivery.address.country == 'Estados Unidos'">
                                            United
                                            States
                                        </ng-container>
                                        <ng-container *ngIf="estimate.delivery.address.country != 'Estados Unidos'">
                                            {{estimate.delivery.address.country | titlecase}}
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.property_type">
                                    <th>
                                        Property type:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.property_type}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.level">
                                    <th>
                                        Level:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.level}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.floor">
                                    <th>
                                        Floor:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.floor}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.apartment_number">
                                    <th>
                                        Apartment Number:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.apartment_number}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.storage_unit_number">
                                    <th>
                                        Storage Unit Number:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.storage_unit_number}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
                <!-- END when the delivery address has not change-->

                <!-- when the delivery address has change-->
                <div class="row" *ngIf="job.delivery_fee">
                    
                    <div class="col-md-6 col-sm-12 ">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center">
                                        From
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="estimate.pickup.address.street">
                                    <th>
                                        Street:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.street | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.city">
                                    <th>
                                        City:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.city | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.state">
                                    <th>
                                        State:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.state | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.zip_code">
                                    <th>
                                        Zip code:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.zip_code}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.country">
                                    <th>
                                        Country:
                                    </th>
                                    <td>
                                        <ng-container *ngIf="estimate.pickup.address.country == 'Estados Unidos'">United
                                            States
                                        </ng-container>
                                        <ng-container *ngIf="estimate.pickup.address.country != 'Estados Unidos'">
                                            {{estimate.pickup.address.country | titlecase}}
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.property_type">
                                    <th>
                                        Property type:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.property_type}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.level">
                                    <th>
                                        Level:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.level}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.floor">
                                    <th>
                                        Floor:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.floor}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.apartment_number">
                                    <th>
                                        Apartment Number:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.apartment_number}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.pickup.address.storage_unit_number">
                                    <th>
                                        Storage Unit Number:
                                    </th>
                                    <td>
                                        {{estimate.pickup.address.storage_unit_number}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-md-6 col-sm-12">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center" style="position: relative;">
                                        Estimate delivery
                                        <a *ngIf="!job.delivery_fee || !job.delivery_fee.address" title="Edit Moving From and To" href="#" class="btn btn-success btn-address" data-toggle="modal"
                                            data-target="#modalChangeAddress">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-3">
                                                <path d="M12 20h9"></path>
                                                <path
                                                    d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z">
                                                </path>
                                            </svg>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="estimate.delivery.address.street">
                                    <th>
                                        Street:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.street | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.city">
                                    <th>
                                        City:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.city | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.state">
                                    <th>
                                        State:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.state | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.zip_code">
                                    <th>
                                        Zip code:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.zip_code}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.country">
                                    <th>
                                        Country:
                                    </th>
                                    <td>
                                        <ng-container
                                            *ngIf="estimate.delivery.address.country == 'Estados Unidos'">
                                            United
                                            States
                                        </ng-container>
                                        <ng-container
                                            *ngIf="estimate.delivery.address.country != 'Estados Unidos'">
                                            {{estimate.delivery.address.country | titlecase}}
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.property_type">
                                    <th>
                                        Property type:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.property_type}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.level">
                                    <th>
                                        Level:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.level}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.floor">
                                    <th>
                                        Floor:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.floor}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.apartment_number">
                                    <th>
                                        Apartment Number:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.apartment_number}}
                                    </td>
                                </tr>
                                <tr *ngIf="estimate.delivery.address.storage_unit_number">
                                    <th>
                                        Storage Unit Number:
                                    </th>
                                    <td>
                                        {{estimate.delivery.address.storage_unit_number}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                    <div class="col-md-6 col-sm-12" *ngIf="job.delivery_fee && job.delivery_fee.address">

                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center" style="position: relative;">
                                        Real delivery
                                        <a title="Edit Moving From and To" href="#" class="btn btn-success btn-address" data-toggle="modal" data-target="#modalChangeAddress">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-edit-3">
                                                <path d="M12 20h9"></path>
                                                <path
                                                    d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z">
                                                </path>
                                            </svg>
                                        </a>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="job.delivery_fee.address.street">
                                    <th>
                                        Street:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.street | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.city">
                                    <th>
                                        City:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.city | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.state">
                                    <th>
                                        State:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.state | titlecase}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.zip_code">
                                    <th>
                                        Zip code:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.zip_code}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.country">
                                    <th>
                                        Country:
                                    </th>
                                    <td>
                                        <ng-container
                                            *ngIf="job.delivery_fee.address.country == 'Estados Unidos'">
                                            United
                                            States
                                        </ng-container>
                                        <ng-container
                                            *ngIf="job.delivery_fee.address.country != 'Estados Unidos'">
                                            {{job.delivery_fee.address.country | titlecase}}
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.property_type">
                                    <th>
                                        Property type:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.property_type}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.level">
                                    <th>
                                        Level:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.level}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.floor">
                                    <th>
                                        Floor:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.floor}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.apartment_number">
                                    <th>
                                        Apartment Number:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.apartment_number}}
                                    </td>
                                </tr>
                                <tr *ngIf="job.delivery_fee.address.storage_unit_number">
                                    <th>
                                        Storage Unit Number:
                                    </th>
                                    <td>
                                        {{job.delivery_fee.address.storage_unit_number}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>

                </div>
                <!-- END when the delivery address has change-->



            </div>
        </div>
        <!-- END Routes -->

        <!-- Additional Stops -->
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <h3>
                    Additional Stops
                </h3>

                <div class="row">
                    <div class="col-md-4 col-sm-12 layout-top-spacing">

                        <table class="table table-striped" *ngFor="let stopPoint of stopPoints">
                            <thead>
                                <tr>
                                    <th colspan="2" class="text-center">
                                        {{stopPoint.type}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="stopPoint.address.street">
                                    <th>
                                        Street:
                                    </th>
                                    <td>
                                        {{stopPoint.address.street}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.city">
                                    <th>
                                        City:
                                    </th>
                                    <td>
                                        {{stopPoint.address.city}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.state">
                                    <th>
                                        State:
                                    </th>
                                    <td>
                                        {{stopPoint.address.state}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.zip_code">
                                    <th>
                                        Zip code:
                                    </th>
                                    <td>
                                        {{stopPoint.address.zip_code}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.country">
                                    <th>
                                        Country:
                                    </th>
                                    <td>
                                        {{stopPoint.address.country}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.property_type">
                                    <th>
                                        Property type:
                                    </th>
                                    <td>
                                        {{stopPoint.address.property_type | filterReplace}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.level">
                                    <th>
                                        Level:
                                    </th>
                                    <td>
                                        {{stopPoint.address.level | filterReplace}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.floor">
                                    <th>
                                        Floor:
                                    </th>
                                    <td>
                                        {{stopPoint.address.floor}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.apartment_number">
                                    <th>
                                        Apartment Number:
                                    </th>
                                    <td>
                                        {{stopPoint.address.apartment_number}}
                                    </td>
                                </tr>
                                <tr *ngIf="stopPoint.address.storage_unit_number">
                                    <th>
                                        Storage Unit Number:
                                    </th>
                                    <td>
                                        {{stopPoint.address.storage_unit_number}}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
        <!-- END Additional Stops -->       

    </div>
    <!-- END Content -->


    <!-- sidebar -->
    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 layout-top-spacing">

        <div class="bio pb-3">
            <div class="widget-content widget-content-area">

                <h3>
                    Estimate
                </h3>

                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <th>
                                Job Number:
                            </th>
                            <td>
                                {{estimate.document.code}}
                            </td>
                        </tr>
                        <tr *ngIf="job.pickup_day.date">
                            <th>
                                Move Date
                            </th>
                            <td>
                                {{estimate.pickup.range_start | dateTime}} - {{estimate.pickup.range_end | dateTime}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Cubic feet:
                            </th>
                            <td>
                                {{job.cubic_feets}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Lbs:
                            </th>
                            <td>
                                {{estimate.volume.libs}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Miles:
                            </th>
                            <td>
                                {{estimate.volume.miles | number : '1.2-2'}}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Salesman:
                            </th>
                            <td>
                                {{estimate.employee.name | filterReplace}}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Created:
                            </th>
                            <td>
                                {{estimate.created | dateTime}}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        <div class="bio pb-3">
            <div class="widget-content widget-content-area">

                <h3>
                    Special Conditions
                </h3>

                <!-- 
                    <button class="btn btn-warning  btn-sm" (click)="updateJob()">
                        <i class="fas fa-save"></i>
                    </button>
                -->

                <textarea rows="3" class="form-control" [(ngModel)]="job.slip_remarks"></textarea>

            </div>
        </div>

        <div class="bio pb-3">
            <div class="widget-content widget-content-area">

                <h3>
                    Internal Office Comments
                </h3>
                <!--
                    <button class="btn btn-warning  btn-sm" (click)="updateJob()">
                        <i class="fas fa-save"></i>
                    </button>
                -->

                <textarea rows="3" class="form-control" [(ngModel)]="job.internal_remarks"></textarea>
            </div>
        </div>
    </div>
    <!-- END sidebar -->


    <!-- Totals -->
    <div class="col-12 layout-top-spacing">        
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">

                <h3>
                    Totals
                </h3>

                <ng-container *ngIf="estimate.service == 'LOCAL'">

                    <div class="title-total-section mb-3">Labor</div>

                    <!-- Labor Estimate -->
                    <div class="labor-estimate mb-4">
                        <div class="title-total-section mb-3">Labor Estimate</div>
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Worker(s)</label>
                                    <div type="text" class="form-control output-box" placeholder="0" disabled>
                                        {{estimate.estimate_local_tariff?.local_tariff_setting?.workers}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Hours</label>
                                    <div type="text" class="form-control output-box" placeholder="0" disabled>
                                        {{estimate.estimate_local_tariff?.labor_workers_tariff.hours}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Cost per Hours</label>
                                    <div type="text" class="form-control output-box" placeholder="0" disabled>
                                        {{estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour | currency}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0" disabled>
                                        {{(estimate.estimate_local_tariff?.labor_workers_tariff.hours * estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour) | currency}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="overtime-estimate mb-4">                        
                        <div class="d-flex justify-content-between">
                            <div class="title-total-section">Overtime</div>
                            <button class="btn btn-save" (click)="saveLocalTariffLog('LABOR')" [disabled]="localTariffSettingId == '' || localTariffSettingId == null">Save</button>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Truck(s)/Worker(s)</label>
                                    <select [(ngModel)]="localTariffSettingId" name="localTariffSetting" (ngModelChange)="setLocalTariffSetting(null)"
                                        class="form-control">
                                        <option value="" selected disabled>Select an Option</option>
                                        <option *ngFor="let localTariff of localTariffs" [value]="localTariff.id">
                                            {{localTariff.trucks}} Trucks + {{localTariff.workers}} Workers
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Hours</label>
                                    <input type="number" [(ngModel)]="jobLocalTariffLog.hours" 
                                        class="form-control" placeholder="0"
                                        (ngModelChange)="setTotalTariff(null)">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Cost per Hours</label>
                                    <input type="number" [(ngModel)]="jobLocalTariffLog.cost_per_hours" 
                                        class="form-control" placeholder="0"
                                        (ngModelChange)="setTotalTariff(null)">
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0" disabled>
                                        {{this.jobLocalTariffLog.total | currency}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pickup-tariff-logs mb-5">
                        <div class="title-total-section mb-3">Overload Tariff Logs</div>
                        
                        <div class="row log" *ngFor="let jobLocalTariffLog of jobLocalTariffLogsJC; let i = index">
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Truck(s)/Worker(s)</label>
                                    <select [(ngModel)]="jobLocalTariffLog.local_tariff_setting.id" name="localTariffSetting_{{i}}" (ngModelChange)="setLocalTariffSetting(i)"
                                        class="form-control">
                                        <option value="" selected disabled>Select an Option</option>
                                        <option *ngFor="let localTariff of localTariffs" value="{{localTariff.id}}">{{localTariff.trucks}} Trucks + {{localTariff.workers}} Workers</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Hours</label>
                                    <input type="number" [(ngModel)]="jobLocalTariffLog.hours" 
                                        class="form-control" placeholder="0"
                                        (ngModelChange)="setTotalTariff(i)">
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Cost per Hours</label>
                                    <input type="number" [(ngModel)]="jobLocalTariffLog.cost_per_hours" 
                                        class="form-control" placeholder="0"
                                        (ngModelChange)="setTotalTariff(i)">
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{jobLocalTariffLog.total | currency}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <div class="form-group">
                                    <label for="">Edit</label>
                                    <div>
                                        <button type="button" class="btn btn-secondary" 
                                            (click)="editLog(i)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="feather feather-edit-3">
                                                <path d="M12 20h9"></path>
                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                            </svg>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Delete</label>
                                    <div>
                                        <button class="btn btn-danger" [disabled]="estimate.status == 'WORK_CARRIED_OUT'" 
                                                (click)="removeLog(i, false)" appCanUserCompany="ESTIMATE_BALANCE_DELETE">
                                            <i class="fa-solid fa-trash-can"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="pickup-tariff-logs mb-5">
                        <div class="title-total-section mb-3">Pickup Tariff Logs</div>
                        
                        <div class="row log" *ngFor="let jobLocalTariffLog of jobLocalTariffLogsDP">
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Truck(s)/Worker(s)</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{jobLocalTariffLog.local_tariff_setting.trucks}} Truck + {{jobLocalTariffLog.local_tariff_setting.workers}} Workers
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Hours</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{jobLocalTariffLog.hours | number : '1.0-4'}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Cost per Hours</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{jobLocalTariffLog.cost_per_hours | currency}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{jobLocalTariffLog.total | currency}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- <hr class="hr-total"> -->
                    
                    <div class="title-total-section mb-3">Travel</div>

                    <!-- Travel Estimate -->
                    <div class="travel-estimate mb-4">
                        <div class="title-total-section mb-3">Travel Estimate</div>
                        <div class="row">

                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Truck(s)</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}}
                                    </div>
                                </div>
                            </div>

                            <!-- Miles -->
                            <div class="col-3" *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'">
                                <div class="form-group">
                                    <label for="">Miles</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{estimate.estimate_local_tariff?.travel_workers_tariff.miles | number:'1.0-2'}}
                                    </div>
                                </div>
                            </div>
                            <!-- Hours -->
                            <div class="col-3" *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'">
                                <div class="form-group">
                                    <label for="">Hours</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{estimate.estimate_local_tariff?.travel_workers_tariff.hours}}
                                    </div>
                                </div>
                            </div>

                            <!-- Cost per Miles -->
                            <div class="col-3" *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'MILES'">
                                <div class="form-group">
                                    <label for="">Cost per Miles</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile | currency}}
                                    </div>
                                </div>
                            </div>
                            <!-- Cost per Hours -->
                            <div class="col-3" *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on === 'TIMES'">
                                <div class="form-group">
                                    <label for="">Cost per Miles</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour | currency}}
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Total -->
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0">

                                        <!-- Total (Miles) -->
                                        <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on == 'MILES'">
                                            {{(estimate.estimate_local_tariff?.travel_workers_tariff.miles * estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile) | currency}}                                        
                                        </span>

                                        <!-- Total (Hours) -->
                                        <span *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on == 'TIMES'">
                                            {{(estimate.estimate_local_tariff?.travel_workers_tariff.hours * estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour) | currency}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div class="overtime-estimate mb-4">                        
                        <div class="d-flex justify-content-between">
                            <div class="title-total-section">Add Travel</div>
                            <button class="btn btn-save" (click)="saveLocalTariffLog('TRAVEL')">Save</button>
                        </div>
                        <div class="row">
                            <ng-container *ngIf="jobTravelLocalTariffLog.travel_mode == 'TIMES'">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="">Hours</label>
                                        <input type="number" [(ngModel)]="jobTravelLocalTariffLog.hours" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(null)">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="">Cost per Hour</label>
                                        <input type="number" [(ngModel)]="jobTravelLocalTariffLog.cost_per_hours" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(null)">
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="jobTravelLocalTariffLog.travel_mode == 'MILES'">
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="">Miles</label>
                                        <input type="number" [(ngModel)]="jobTravelLocalTariffLog.miles" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(null)">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="">Cost per Mile</label>
                                        <input type="number" [(ngModel)]="jobTravelLocalTariffLog.cost_per_miles" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(null)">
                                    </div>
                                </div>
                            </ng-container>
                            <div class="col-3">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0" disabled>
                                        {{this.jobTravelLocalTariffLog.total | currency}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pickup-tariff-logs mb-5">
                        <div class="title-total-section mb-3">Travel Tariff Logs</div>
                        
                        <div class="row log" *ngFor="let jobLocalTariffLog of jobLocalTariffLogsTravelJC; let i = index">
                            <ng-container *ngIf="jobLocalTariffLog.travel_mode == 'TIMES'">
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="">Hours</label>
                                        <input type="number" [(ngModel)]="jobLocalTariffLog.hours" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(i)">
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="">Cost per Hours</label>
                                        <input type="number" [(ngModel)]="jobLocalTariffLog.cost_per_hours" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(i)">
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="jobLocalTariffLog.travel_mode == 'MILES'">
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="">Hours</label>
                                        <input type="number" [(ngModel)]="jobLocalTariffLog.miles" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(i)">
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="form-group">
                                        <label for="">Cost per Hours</label>
                                        <input type="number" [(ngModel)]="jobLocalTariffLog.cost_per_miles" 
                                            class="form-control" placeholder="0"
                                            (ngModelChange)="setTravelTotalTariff(i)">
                                    </div>
                                </div>
                            </ng-container>
                            
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Total</label>
                                    <div type="text" class="form-control output-box" placeholder="0">
                                        {{jobLocalTariffLog.total | currency}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <div class="form-group">
                                    <label for="">Edit</label>
                                    <div>
                                        <button type="button" class="btn btn-secondary" 
                                            (click)="editTravelLog(i)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="feather feather-edit-3">
                                                <path d="M12 20h9"></path>
                                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                            </svg>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="form-group">
                                    <label for="">Delete</label>
                                    <div>
                                        <button class="btn btn-danger" [disabled]="estimate.status == 'WORK_CARRIED_OUT'" 
                                                (click)="removeLog(i, true)" appCanUserCompany="ESTIMATE_BALANCE_DELETE">
                                            <i class="fa-solid fa-trash-can"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </ng-container>

                <ng-container>
                
                    <div class="row" *ngIf="estimate.service !== 'LOCAL'">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                            <div class="form-group mb-3">
                                <label for="company_email">
                                    Actual (Line Haul):
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-archive">
                                                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                <rect x="1" y="3" width="22" height="5"></rect>
                                                <line x1="10" y1="12" x2="14" y2="12"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" class="form-control " name="cubic_feets"
                                        [(ngModel)]="job.cubic_feets">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Enter here the volume that was finally moved in the job.
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 text-right">
                            <br>
                            <button (click)="updateJob()" class="btn btn-addtional-concepts btn-main-action">
                                Save
                            </button>
                        </div>
                    </div>

                    <!-- Prices -->
                    <table class="table table-striped layout-top-spacing">
                        <thead>
                            <tr>
                                <th colspan="4" class="text-center">
                                    Base
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr class="text-success" *ngIf="job.delivery_fee">
                                <th class="text-success">
                                    Real delivery Address :
                                </th>
                                <td class="text-success" colspan="2">
                                    {{job.delivery_fee.total_price_cf}} cf. &nbsp;&nbsp;&nbsp;
                                    {{job.delivery_fee.rate_cf | currency}} per cf &nbsp;&nbsp;&nbsp;
                                </td>
                                <td class="text-success text-right">
                                    {{job.delivery_fee.total_price_cf * job.delivery_fee.rate_cf | currency}}
                                </td>
                            </tr> -->
                            <tr *ngIf="estimate.service !== 'LOCAL'">
                                <th>
                                    Estimate:
                                </th>
                                <td colspan="2">
                                    {{estimate.volume.cubic_feets_selled}} cf.
                                    {{estimate.estimate_route_tariff.cf_cost | currency}} per cf
                                </td>
                                <td class="text-right">
                                    {{job.charges.total_price_cf | currency}}
                                </td>
                            </tr>
                            <tr *ngIf="estimate.tariff_discount.total_discount > 0">
                                <th>
                                    Tariff Discount:
                                </th>
                                <td colspan="2">
                                    {{estimate.tariff_discount.total_discount_percentage | number}}%
                                </td>
                                <td class="text-right">
                                    {{estimate.tariff_discount.total_discount | currency}}
                                </td>
                            </tr>
                            <!-- <tr>
                                <th>
                                    Actual (Line Haul):
                                </th>
                                <td>
                                    <input type="number" class="form-control" [(ngModel)]="job.cubic_feets"
                                        name="actual_value_job">
                                    cf.
                                </td>
                                <td>
                                    <strong>
                                        Rate:
                                    </strong>
                                    <input type="number" class="form-control" [(ngModel)]="job.charges.rate_cf">
                                    per cf.
                                </td>
                                <td class="text-right">
                                    {{job.cubic_feets ? (job.cubic_feets * job.charges.rate_cf | currency) : 0 | currency}}
                                </td>
                            </tr> -->
                            <tr *ngIf="job.overload_cf != 0">
                                <th>
                                    Overload:
                                </th>
                                <td>
                                    <strong>
                                        {{job.overload_cf}}
                                    </strong>
                                    cf.
                                </td>
                                <td>
                                    <strong>
                                        rate:
                                    </strong>
                                    <input type="number" class="form-control" [(ngModel)]="job.charges.overload_rate_cf"
                                        name="overload_cf_job">
                                    per cf.
                                </td>
                                <td class="text-right">
                                    {{job.charges.overload_total_price_cf | currency}}
                                </td>
                            </tr>
                            <tr *ngIf="estimate.service !== 'LOCAL'">
                                <th>
                                    Diesel / Fuel Surcharge:
                                </th>
                                <td colspan="2">
                                    <input type="number" class="form-control"
                                        [(ngModel)]="job.charges.fuel_surcharge_percentage">
                                    %
                                </td>
                                <td class="text-right">
                                    {{job.charges.fuel_surcharge| currency}}
                                </td>
                            </tr>
                            <tr>
                                <th class="cursor-pointer" (click)="openModalMaterials()">
                                    Materials:
                                </th>
                                <td class="text-right" colspan="3">
                                    {{job.charges.materials | currency}}

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- storage services -->
                    <ng-container *ngIf="storageServices.length > 0">
                        <table class="table table-striped layout-top-spacing">
                            <thead>
                                <tr>
                                    <th colspan="4" class="text-center">
                                        Storage In Transit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let service of storageServices">
                                    <th>
                                        1 Month <span *ngIf="service.total_amount_due == 0">Free</span><span *ngIf="service.total_amount_due > 0">@{{ service.rate_per_cf| currency}} per CF per Mo</span>
                                    </th>
                                    <td class="text-right" colspan="3">
                                        {{ service.total_amount_due| currency}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <!-- END Prices -->

                    <!-- cargos y descuentos del estimate -->
                    <table class="table table-striped layout-top-spacing">
                        <ng-container *ngIf="bulkyFee.length > 0">
                            <tr>
                                <td class="title-additionals" colspan="2" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                    Inventory Bulky Fee
                                </td>
                            </tr>
                            <tr *ngFor="let item of bulkyFee">
                                <td>
                                    {{item.inventory_item.bulky_fee.name | titlecase}}
                                </td>

                                <td class="text-right">
                                    {{(item.inventory_item.bulky_fee.cost * item.quantity) | currency}}
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="additionals.length > 0 || (workspaceSettings.cover_stop_fees && stopPoints.length > 0)">
                            <tr>
                                <td class="title-additionals" colspan="2" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                    Estimate additionals
                                </td>
                            </tr>
                            <tr *ngFor="let additional of additionals">
                                <td>{{additional.name}}</td>
                                <td class="text-right">{{additional.amount | currency}}</td>
                            </tr>
                            <ng-container *ngIf="workspaceSettings.cover_stop_fees">
                                <tr *ngFor="let stopPoint of stopPoints">
                                    <td>
                                    <span *ngIf="stopPoint.type === 'PICKUP'"> Additional Pickup Stop </span>
                                    <span *ngIf="stopPoint.type === 'DELIVERY'"> Additional Delivery Stop </span>
                                    </td>
                                    <td class="text-right">{{stopPoint.fee | currency}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="estimate.charges.packers_packing > 0">
                            <tr>
                                <td class="title-additionals">
                                    Packers unpacking:
                                </td>
                                <td class="text-right">{{ estimate.charges.packers_packing | currency}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="estimate.charges.packers_unpacking > 0">
                            <tr>
                                <td class="title-additionals">
                                    Packers unpacking:
                                </td>
                                <td class="text-right">{{ estimate.charges.packers_unpacking | currency}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="job.insurance.valuation_charge > 0">
                            <tr>
                                <td class="title-additionals">
                                    Insurance:
                                </td>
                                <td class="text-right">{{ job.insurance.valuation_charge | currency}}</td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="discounts.length > 0">
                            <tr>
                                <td class="title-additionals" colspan="2" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                    Estimate Discounts
                                </td>
                            </tr>
                            <tr *ngFor="let discount of discounts">
                                <td>{{discount.name}}</td>
                                <td class="text-right">{{discount.amount | currency}}</td>
                            </tr>
                        </ng-container>
                    </table>

                </ng-container>


                <!-- Additionals -->
                <div class="d-flex px-1">
                    <div class="col-10">
                        <span class="additional-title">
                            Additionals Services
                        </span>
                    </div>
                    <div class="col-2 text-right">
                        <button class="btn btn-secondary" (click)="openModalAdditionalCharges('SERVICE')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-plus-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                        </button>
                    </div>
                </div>

                <table class="table table-striped layout-top-spacing additional-table mb-5">
                    <thead>
                        <tr>
                            <th>Name</th>   
                            <th class="text-right">Amount</th> 
                            <th class="text-right">Actions</th> 
                        </tr> 
                    </thead>
                    <tbody>
                        <tr *ngFor="let service of additionalCharges">
                            <ng-container *ngIf="service.amount > 0">
                                <td>
                                    {{service.name}}
                                </td>
                                <td class="text-right">
                                    {{service.amount | currency}}
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-success" (click)="editAdditionalCharges(service, 'SERVICE')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-edit-3">
                                            <path d="M12 20h9"></path>
                                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                        </svg>
                                    </button>
                                    <button class="btn btn-danger m-0" (click)="deleteAdditionalCharges(service.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-trash-2">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                            </path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </button>
                                </td>                                
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <!-- END Addtionals -->

                <!-- Addtionals at destintation -->
                <table class="table table-striped layout-top-spacing additional-table mb-5">
                    <thead>
                        <tr>
                            <th class="text-left">
                                <div class="row">
                                    <div class="col-12">
                                        Services At Destination
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let service of deliveryAdditionals">
                            <ng-container *ngIf="service.amount > 0">                               
                                <td>
                                    {{service.name}}
                                </td>
                                <td class="text-right">
                                    {{service.amount | currency}}
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-success" (click)="editAdditionalCharges(service, 'SERVICE')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-edit-3">
                                            <path d="M12 20h9"></path>
                                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                        </svg>
                                    </button>
                                    <button class="btn btn-danger m-0" (click)="deleteAdditionalCharges(service.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-trash-2">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                            </path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </button>
                                </td>                               
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <!-- END Addtionals at destintation-->

                <!-- Discounts -->
                <div class="d-flex px-1">
                    <div class="col-10">
                        <span class="additional-title">
                            Discounts
                        </span>
                    </div>
                    <div class="col-2 text-right">
                        <button class="btn btn-secondary" (click)="openModalAdditionalCharges('DISCOUNT')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-plus-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                        </button>
                    </div>
                </div>

                <table class="table table-striped layout-top-spacing additional-table mb-5">
                    <thead>
                        <tr>
                            <th>Name</th>   
                            <th class="text-right">Amount</th> 
                            <th class="text-right">Actions</th> 
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let service of additionalCharges">
                            <ng-container *ngIf="service.amount < 0">                                
                                <td>
                                    {{service.name}}
                                </td>
                                <td class="text-right">
                                    {{service.amount | currency}}
                                </td>
                                <td class="text-right">
                                    <button class="btn btn-success" (click)="editAdditionalCharges(service, 'DISCOUNT')">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-edit-3">
                                            <path d="M12 20h9"></path>
                                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                        </svg>
                                    </button>
                                    <button class="btn btn-danger m-0" (click)="deleteAdditionalCharges(service.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-trash-2">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                            </path>
                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                        </svg>
                                    </button>
                                </td>                                
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
                <!-- END Discounts -->

                <!-- Totals -->
                <table class="table table-striped layout-top-spacing total-table mt-100">
                    <tbody>
                        <tr>
                            <th>
                                Total Job:
                            </th>
                            <td class="text-right">
                                {{ job.total | currency}}
                            </td>
                        </tr>
                        <tr class="table-success">
                            <th>
                                Total Customer Payments:
                            </th>
                            <td class="text-right">
                                {{balanceAmount | currency}}
                            </td>
                        </tr>
                        <tr class="table-danger">
                            <th>
                                Total Balance:
                            </th>
                            <td class="text-right">
                                {{totalBalance | currency}}
                            </td>
                        </tr>
                        <tr class="table-warning">
                            <th>
                                Total Expense:
                            </th>
                            <td class="text-right">
                                {{totalExpenses | currency}}
                            </td>
                        </tr>
                        <tr class="table-info">
                            <th>
                                Profit:
                            </th>
                            <td class="text-right">
                                {{balanceAmount - totalExpenses | currency}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- END Totals -->
            </div>
        </div>        
    </div>
    <!-- END Totals-->

</div>

<div class="modal fade" #modalAditionalService id="modalAditionalService" tabindex="-1" role="dialog" aria-labelledby="customerremarksTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="customerremarksTitle">
                    <ng-container *ngIf="!additionalCharge.id">
                        Add
                    </ng-container>
                    <ng-container *ngIf="additionalCharge.id">
                        Edit
                    </ng-container> {{typeModal}}
                </h5>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="inputState">
                            Name
                        </label>
                        <div class="input-group mb-1">
                            <input type="text" class="form-control" [(ngModel)]="additionalCharge.name" name="ad_ch_name">
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">{{typeModal}} Name.</small>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group ">
                        <label for="inputState">
                            Amount
                        </label>
                        <div class="input-group mb-1">
                            <input type="number" class="form-control" [(ngModel)]="additionalCharge.amount" name="ad_ch_amount">
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">{{typeModal}} Amount.</small>
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="inputState">
                            Type
                        </label>
                        <div class="input-group mb-1">
                            <select name="additionalCharge.type" id="additionalCharge.type" [(ngModel)]="additionalCharge.type" class="form-control">
                                <option value="NOT_APPLY">
                                    Not Apply
                                </option>
                                <option value="PICKUP">
                                    Pickup
                                </option>
                                <option value="DELIVERY">
                                    Delivery
                                </option>
                            </select>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">{{typeModal}} Type.</small>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button (click)="closeModalAdditionalCharges()" class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveAdditionalCharges()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal moving-->
<div #modalChangeAddress class="modal fade" id="modalChangeAddress" tabindex="-1" role="dialog" aria-labelledby="movingTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered size-form-modal-single" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="movingTitle">Moving</h5>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <h4>
                        To Address
                    </h4>
                    <app-search-address (fullAddress)="jobDeliveryFee.address.full_address"
                        [(ngModel)]="jobDeliveryFee.address.full_address"
                        [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'" [addressFull]="true"
                        [oldAddress]="estimate.delivery.address" (onChange)="setTo($event)"></app-search-address>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveMoving()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal moving-->
<div #modalMaterialsAddress class="modal fade" id="modalMaterialsAddress" tabindex="-1" role="dialog" aria-labelledby="movingTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered size-form-modal-single" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="movingTitle">Materials</h5>
            </div>
            <div class="modal-body">              
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                            <form class="form" #entityForm="ngForm" ngNativeValidate>
                                <table class="table table-bordered mb-4">
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th class="text-center">Qty</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rjpm of rowEstimateMaterials; let i = index">
                                            <td>{{rjpm.packing_material.name}}</td>
                                            <td class="text-center"><input type="number" class="form-control input-qty" id="quantity_{{i}}" name="data" min="0" [(ngModel)]="rjpm.quantity" name="quantity_{{i}}"></td>
                                            <td>{{rjpm.packing_material.price | currency}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="savePakingMaterials()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal Invoice -->
<div #modalChangeAddress class="modal fade" id="modalInvoice" tabindex="-1" role="dialog" aria-labelledby="movingTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered size-form-modal-single size-modal-invoice" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="movingTitle">Invoice {{estimate.document.code}}</h5>
            </div>
            <div class="modal-body">
                <div class="row invoice pb-4">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        
                        <div class="doc-container">

                            <div class="row">

                                <div class="col-xl-12">

                                    <div class="invoice-container">
                                        <div class="invoice-inbox">
                                            
                                            <div id="ct" class="">
                                                
                                                <div class="invoice-00001">
                                                    <div class="content-section">
    
                                                        <div class="inv--head-section inv--detail-section mb-2">
                                                        
                                                            <div class="row">
                                                            
                                                                <div class="col-sm-6">
                                                                    <img class="company-logo" src="{{environment.az_api.cdn}}/{{authServices.workspaceSession.id}}/media-files/{{authServices.workspaceSession.company.logo}}" alt="company">
                                                                    <p class="inv-title-comp date-generate" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                                                        Date Generated: 
                                                                        <span class="inv-detail-comp">
                                                                            {{dateToday | date}}
                                                                        </span>
                                                                    </p>
                                                                    <!-- <p class="inv-detail-comp">4/4/2022</p> -->
                                                                </div>

                                                                <div class="col-sm-6 col-12 text-sm-right">
                                                                    <p class="inv-info-company">{{authServices.workspaceSession.company.name}}</p>
                                                                    <p class="inv-info-company">{{authServices.workspaceSession.company.phone}}</p>
                                                                    <p class="inv-info-company">{{authServices.workspaceSession.company.email}}</p>
                                                                    <p class="inv-info-company">{{authServices.workspaceSession.company.address.street | titlecase}} {{authServices.workspaceSession.company.address.city | titlecase}} {{authServices.workspaceSession.company.address.state | titlecase}}, {{authServices.workspaceSession.company.address.zip_code}}</p>
                                                                    <p class="inv-info-company">US DOT: {{authServices.workspaceSession.company.dot}}</p>
                                                                    <p class="inv-info-company">ICC MC: {{authServices.workspaceSession.company.license}}</p>
                                                                    <p class="inv-info-company">{{authServices.workspaceSession.company.website}}</p>
                                                                </div>

                                                            </div>
                                                            
                                                        </div>

                                                        <div class="inv--detail-section inv--customer-detail-section company-invoice " [ngStyle]="{'background': companyThemeDocument.firstColor}">

                                                            <div class="col-xl-12 col-lg-7 col-md-6 col-sm-4 align-self-center text-center">
                                                                <p class="inv-to">COMPANY INVOICE - {{estimate.document.code}}</p>
                                                            </div>
                                                          
                                                        </div>
    
                                                        <div class="inv--detail-section inv--customer-detail-section pb-0">

                                                            <div class="row">
                                                                
                                                                <div class="col-xl-7 col-lg-7 col-md-6 col-sm-4">
                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Customer Name:</p>
                                                                    <p class="inv-detail-comp">{{job.estimate.customer.name}} {{job.estimate.customer.last_name}}</p>
                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Contact Email:</p>
                                                                    <p class="inv-detail-comp">{{job.estimate.customer.email}}</p>
                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">From Address:</p>
                                                                    <p class="inv-detail-comp">{{estimate.pickup.address.street | titlecase}} {{estimate.pickup.address.city | titlecase}} {{estimate.pickup.address.state | titlecase}}, {{estimate.pickup.address.zip_code}}</p>
                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">To Address:</p>
                                                                    <p class="inv-detail-comp">{{estimate.delivery.address.street | titlecase}} {{estimate.delivery.address.city | titlecase}} {{estimate.delivery.address.state | titlecase}}, {{estimate.delivery.address.zip_code}}</p>
                                                                </div>
                                                                
                                                                <div class="col-xl-5 col-lg-7 col-md-6 col-sm-4">
                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Customer Rep:</p>
                                                                    <p class="inv-detail-comp">{{estimate.employee.name | filterReplace}}</p>
                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Rep Email:</p>
                                                                    <p class="inv-detail-comp">{{emailCompanyPermision}}</p>

                                                                    <p *ngIf="estimate.service == 'LONG'" class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Total Job Volume:</p>
                                                                    <p *ngIf="estimate.service == 'LONG'" class="inv-detail-comp">{{job.cubic_feets}} cf</p>

                                                                    <p class="inv-title-comp" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Move Date:</p>
                                                                    <p class="inv-detail-comp">{{estimate.pickup.range_start | dateTime}} - {{estimate.pickup.range_end | dateTime}}</p>
                                                                </div>

                                                            </div>
                                                            
                                                        </div>

                                                        <div class="inv--product-table-section">
                                                            <div class="table-responsive">
                                                                <table class="table">
                                                                    <thead class="">
                                                                        <tr>
                                                                            <th class="text-center" scope="col" [ngStyle]="{'background': companyThemeDocument.firstColor}">Charges</th>
                                                                            <th class="text-center" scope="col" [ngStyle]="{'background': companyThemeDocument.thirdColor}">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <br>

                                                                        <ng-container *ngIf="estimate.service == 'LOCAL'">
                                                                            <tr>
                                                                                <td>Estimate Labor: {{estimate.estimate_local_tariff?.local_tariff_setting?.workers}} Workers for {{estimate.estimate_local_tariff?.labor_workers_tariff.hours}} hours @ {{estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour | currency}}/Hr</td>
                                                                                <td class="text-right"> {{(estimate.estimate_local_tariff?.labor_workers_tariff.hours * estimate.estimate_local_tariff?.labor_workers_tariff.cost_per_hour) | currency}} </td>
                                                                            </tr>
                                                                            <tr *ngFor="let jobLocalTariffLogJC of jobLocalTariffLogsJC">
                                                                                <td>Overtime Labor: {{jobLocalTariffLogJC.local_tariff_setting?.workers}}  Workers  for {{jobLocalTariffLogJC.hours}} Hours @ {{jobLocalTariffLogJC.cost_per_hours | currency}}/Hr</td>
                                                                                <td class="text-right"> {{jobLocalTariffLogJC.total | currency}} </td>
                                                                            </tr>

                                                                            <tr *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on == 'TIMES'">
                                                                                <td>
                                                                                    Estimate Travel: {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}} Van  for {{estimate.estimate_local_tariff?.travel_workers_tariff.hours}} hours @ {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour | currency}}/Hr 
                                                                                </td>
                                                                                <td class="text-right"> 

                                                                                    <!-- Total (Hours) -->
                                                                                    <span>
                                                                                        {{(estimate.estimate_local_tariff?.travel_workers_tariff.hours * estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_hour) | currency}}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            
                                                                            <tr *ngIf="estimate.estimate_local_tariff?.travel_charge_base_on == 'MILES'">
                                                                                <td>
                                                                                    Estimate Travel: {{estimate.estimate_local_tariff?.local_tariff_setting?.trucks}} Van  for  {{estimate.estimate_local_tariff?.travel_workers_tariff.miles | number:'1.0-2'}} miles @ {{estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile | currency}}/mi
                                                                                </td>
                                                                                <td class="text-right"> 
                                                                                    <!-- Total (Miles) -->
                                                                                    <span>
                                                                                        {{(estimate.estimate_local_tariff?.travel_workers_tariff.miles * estimate.estimate_local_tariff?.travel_workers_tariff.cost_per_mile) | currency}}                                        
                                                                                    </span>
                                                                                </td>
                                                                            </tr>


                                                                            <tr>
                                                                                <td>Overtime Travel: </td>
                                                                                <td class="text-right">{{totalOvertimeTravel | currency}}</td>
                                                                            </tr>
                                                                        </ng-container>

                                                                        <ng-container *ngIf="estimate.service == 'LONG'">

                                                                            <tr>
                                                                                <td>Job Volume: {{estimate.volume.cubic_feets_selled}} cf. {{estimate.estimate_route_tariff.cf_cost | currency}} per cf.</td>
                                                                                <td class="text-right">{{estimate.charges.cubic_feet_total_price | currency}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Overload: {{job.overload_cf}} cf. {{job.charges.overload_rate_cf}} per cf.</td>
                                                                                <td class="text-right">{{job.charges.overload_total_price_cf | currency}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Diesel / Fuel Surcharge: {{job.charges.fuel_surcharge_percentage}}%</td>
                                                                                <td class="text-right">{{job.charges.fuel_surcharge| currency}}</td>
                                                                            </tr>

                                                                        </ng-container>

                                                                        <tr>
                                                                            <td>Materials:</td>
                                                                            <td class="text-right">{{job.charges.materials | currency}}</td>
                                                                        </tr>

                                                                        <ng-container *ngIf="bulkyFee.length > 0">
                                                                            <tr>
                                                                                <td class="title-additionals" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                                                                    Inventory Bulky Fee
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngFor="let item of bulkyFee">
                                                                                <td>
                                                                                    {{item.inventory_item.bulky_fee.name | titlecase}}
                                                                                </td>
                                    
                                                                                <td class="text-right">
                                                                                    {{(item.inventory_item.bulky_fee.cost * item.quantity) | currency}}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="additionals.length > 0 || (workspaceSettings.cover_stop_fees && stopPoints.length > 0)">
                                                                            <tr>
                                                                                <td class="title-additionals" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                                                                    Estimate additionals
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngFor="let additional of additionals">
                                                                                <td>{{additional.name}}</td>
                                                                                <td class="text-right">{{additional.amount | currency}}</td>
                                                                            </tr>
                                                                            <tr *ngFor="let stopPoint of stopPoints">
                                                                                <td>
                                                                                   <span *ngIf="stopPoint.type === 'PICKUP'"> Additional Pickup Stop </span>
                                                                                   <span *ngIf="stopPoint.type === 'DELIVERY'"> Additional Delivery Stop </span>
                                                                                </td>
                                                                                <td class="text-right">{{stopPoint.fee | currency}}</td>
                                                                            </tr>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="discounts.length > 0">
                                                                            <tr>
                                                                                <td class="title-additionals" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                                                                    Estimate Discounts
                                                                                </td>
                                                                            </tr>
                                                                            <tr *ngFor="let discount of discounts">
                                                                                <td>{{discount.name}}</td>
                                                                                <td class="text-right">{{discount.amount | currency}}</td>
                                                                            </tr>
                                                                        </ng-container>

                                                                        <tr *ngIf="additionalsServices.length > 0">
                                                                            <td class="title-additionals" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                                                                Additionals Services
                                                                            </td>
                                                                        </tr>

                                                                        <tr *ngFor="let service of additionalsServices">
                                                                            <td>{{service.name}}</td>
                                                                            <td class="text-right">{{service.amount | currency}}</td>
                                                                        </tr>

                                                                        <tr *ngIf="additionalsDiscounts.length > 0">
                                                                            <td class="title-additionals" [ngStyle]="{'color': companyThemeDocument.thirdColor}">
                                                                                Additionals Discounts
                                                                            </td>
                                                                        </tr>

                                                                        <tr *ngFor="let discount of additionalsDiscounts">
                                                                            <td>{{discount.name}}</td>
                                                                            <td class="text-right">{{discount.amount | currency}}</td>
                                                                        </tr>                                                                       
                                                                     
                                                                    </tbody>
                                                                </table>
                                                                <ng-container *ngIf="storageServices.length > 0">
                                                                    <table class="table table-striped layout-top-spacing">
                                                                        <thead>
                                                                            <tr>
                                                                                <th colspan="4" class="text-center">
                                                                                    Storage In Transit
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let service of storageServices">
                                                                                <td>
                                                                                    1 Month <span *ngIf="service.total_amount_due == 0">Free</span><span *ngIf="service.total_amount_due > 0">@{{ service.rate_per_cf| currency}} per CF per Mo</span>
                                                                                </td>
                                                                                <td class="text-right" colspan="3">
                                                                                    {{ service.total_amount_due| currency}}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="inv--total-amounts">
                                                        
                                                            <div class="row mt-4">
                                                                <div class="col-sm-5 col-12 order-sm-0 order-1">
                                                                </div>
                                                                <div class="col-sm-7 col-12 order-sm-1 order-0">
                                                                    <div class="text-sm-right">
                                                                        <div class="row">
                                                                            <div class="col-sm-8 col-7">
                                                                                <p class="total-txt" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Job Total:</p>
                                                                            </div>
                                                                            <div class="col-sm-4 col-5">
                                                                                <p class="total-number">{{job.total | currency}}</p>
                                                                            </div>
                                                                            <div class="col-sm-8 col-7">
                                                                                <p class="total-txt" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Total Customer Payments:</p>
                                                                            </div>
                                                                            <div class="col-sm-4 col-5">
                                                                                <p class="total-number">{{balanceAmount | currency}}</p>
                                                                            </div>
                                                                            <div class="col-sm-8 col-7 grand-total-title">
                                                                                <p class="total-txt" [ngStyle]="{'color': companyThemeDocument.thirdColor}">Remaining Balance Due:</p>
                                                                            </div>
                                                                            <div class="col-sm-4 col-5 grand-total-amount">
                                                                                <p class="total-number">{{totalBalance | currency}}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div class="inv--product-table-section">
                                                            <div class="table-responsive">
                                                                <table class="table">
                                                                    <thead class="">
                                                                        <tr>
                                                                            <th class="text-center" scope="col" [ngStyle]="{'background': companyThemeDocument.thirdColor}">Paid Date</th>
                                                                            <th class="text-center" scope="col" [ngStyle]="{'background': companyThemeDocument.thirdColor}">Payment Method</th>
                                                                            <th class="text-center" scope="col" [ngStyle]="{'background': companyThemeDocument.thirdColor}">Payment</th>
                                                                            <th class="text-center" scope="col" [ngStyle]="{'background': companyThemeDocument.thirdColor}">Confirmation #</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <br>
                                                                        <tr *ngFor="let payment of paidBalance">
                                                                            <td class="text-center">{{payment.created | date}}</td>
                                                                            <td class="text-center" *ngIf="payment.payment_method">{{payment.payment_method.name}}</td>
                                                                            <td class="text-center" *ngIf="!payment.payment_method">No Payment Method</td>
                                                                            <td class="text-center">{{payment.amount | currency}}</td>
                                                                            <td class="text-center"></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

    
                                                    </div>
                                                </div> 
                                                
                                            </div>
    
    
                                        </div>
    
                                    </div>

                                </div>

                            </div>
                            
                            
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="sendEmailInvoiceCompletions()" type="button" class="btn btn-info">
                    <i class="fa-solid fa-envelope"></i>
                    <span *ngIf="!sending">Send</span>
                    <span *ngIf="sending">Sending...</span>
                </button>
                <button (click)="downloadInvoiceCompletions()" type="button" class="btn btn-secondary mr-4">
                    <i class="fa-solid fa-file-arrow-down"></i>
                    <span>Download</span>
                </button>
                <button data-dismiss="modal" type="button" class="btn btn-success btn-ok">Done</button>
            </div>
        </div>
    </div>
</div>