import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TariffVersion } from 'src/app/entities/workspace/tariff-version';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { TariffVersionsSettingsService } from 'src/app/services/workspaces/tariff-versions-settings.service';
import { TariffVersionsService } from 'src/app/services/workspaces/tariff-versions.service';

declare const swal: any;
declare const jQuery;
@Component({
  selector: 'app-tariff-versions-settings',
  templateUrl: './tariff-versions-settings.component.html',
  styleUrls: ['./tariff-versions-settings.component.scss']
})
export class TariffVersionsSettingsComponent implements OnInit {

  public tariffVersions: TariffVersion[];
  public tariffVersionId: string;
  public btnActived: string;
  public sectionName: string;
  
  constructor(
    public helperService: HelperService,
    public tariffVersionFactory: TariffVersionService,
    public tariffVersionsService: TariffVersionsService,
    private router: Router,
    public tariffVersionsSettingsService: TariffVersionsSettingsService,
  ) { 
    this.tariffVersions = [];
    this.tariffVersionId = '';
    this.btnActived = '';
    this.sectionName = '';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
     this.load();    
  }

  getRouteUrl(){
    if(this.router.url.includes('zones')){
      this.zones();
    }

    if(this.router.url.includes('zone-states')){
      this.zonesStates();
    }

    if(this.router.url.includes('volumes')){
      this.volumes();
    }

    if(this.router.url.includes('periods')){
      this.periods();
    }

    if(this.router.url.includes('route-rates')){
      this.routeRates();
    }

    if(this.router.url.includes('route-rates-map')){
      this.zonesStates();
    }    

    if(this.router.url.includes('additional-stop-rules')){
      this.ldAdditionalStopRules();
    }    
  }
  
  load() {
    this.helperService.showLoadingMxpro360();
    this.tariffVersionId = this.tariffVersionFactory.getTariffVersionId();
    this.tariffVersionsService.getAll().then(
      (response) => {
        this.tariffVersions = response        
        if(response.length > 0) {
          for (const version of response) {
            if (version.is_active ) {
              if (this.tariffVersionId == null) {
                this.tariffVersionId = version.id
                this.tariffVersionFactory.setTariffVersionId(this.tariffVersionId);
              }
              this.getRouteUrl();
              break;
            } 
          }
        } else {
          swal({
            text: 'First you must create a new tariff version, like this you can set up the tariff',
            type: 'warning',
            confirmButtonText: 'Ok!'
          }).then(() => {
            this.helperService.goToWorkspaceRouterLink('/tariff-versions');
          });
        }
      }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  tariffVersion(e){
    this.tariffVersionId = e;
    this.tariffVersionFactory.setTariffVersionId(this.tariffVersionId);
    
    switch(this.btnActived) { 
      case 'routeRates': { 
        this.routeRates();
         break; 
      } 
      case 'zones': { 
         this.zones(); 
         break; 
      } 
      case 'zonesStates': { 
        this.zonesStates(); 
        break; 
     } 
     case 'volumes': { 
      this.volumes(); 
      break; 
    } 
    case 'periods': { 
      this.periods(); 
      break; 
    } 
    case 'ldAdditionalStopRules': { 
      this.ldAdditionalStopRules(); 
      break; 
    } 
      default: { 
         break; 
      } 
   } 

  }

  zones(){
    this.setActiveMenu();
    this.tariffVersionsSettingsService.getZones();
    this.btnActived = 'zones';
    this.sectionName = 'Zones';
    this.helperService.goToWorkspaceRouterLink('/settings/zones');
  }
  
  zonesStates(){
    this.setActiveMenu();
    this.tariffVersionsSettingsService.getAllWithFullView(this.tariffVersionId);
    this.btnActived = 'zonesStates';
    this.sectionName = 'Zones States';
    this.helperService.goToWorkspaceRouterLink('/settings/zone-states');    
  }

  volumes(){
    this.setActiveMenu();
    this.tariffVersionsSettingsService.getAllVolumesByTariffVersionId(this.tariffVersionId);
    this.btnActived = 'volumes';
    this.sectionName = 'Volumes';
    this.helperService.goToWorkspaceRouterLink('/settings/volumes');    
  }

  periods(){
    this.setActiveMenu();
    this.tariffVersionsSettingsService.getAllPeriodsByTariffVersionId(this.tariffVersionId);    
    this.btnActived = 'periods';
    this.sectionName = 'Periods';
    this.helperService.goToWorkspaceRouterLink('/settings/periods');
  }

  routeRates(){
    this.setActiveMenu();
    this.tariffVersionsSettingsService.getZones();
    this.btnActived = 'routeRates';
    this.sectionName = 'Route Rates';
    this.tariffVersionsSettingsService.getAllPeriodsByTariffVersionId(this.tariffVersionId);
    this.tariffVersionsSettingsService.getZones();
    this.helperService.goToWorkspaceRouterLink('/settings/route-rates');
  }

  routeRatesMap(){
    this.setActiveMenu();
    this.btnActived = 'routeRatesMap';
    this.sectionName = 'Route Rates Map';
    this.helperService.goToWorkspaceRouterLink('/settings/route-rates-map');
  }

  ldAdditionalStopRules(){
    this.setActiveMenu();
    this.btnActived = 'ldAdditionalStopRules';
    this.sectionName = 'LD Additional Stop Rules';
    this.helperService.goToWorkspaceRouterLink('/settings/additional-stop-rules');
  }

  setActiveMenu(){
    setTimeout(() => {
      jQuery("#sidebar-setting-section").addClass("active"); 
    }, 1000);
  }
}
