import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierCandidate } from './carrier-candidate';
import { CarrierContact } from './carrier-contact';

export class CarrierCandidateView extends CarrierCandidate{

    carrier: Carrier;
    carrier_contacts: Array<CarrierContact>;

    constructor() {
        super();
        this.carrier = new Carrier();
        this.carrier_contacts = [];
    }
    
}
