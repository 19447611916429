import { Period } from 'src/app/entities/workspace/period';
import { PeriodsService } from 'src/app/services/workspaces/periods.service';

import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionsSettingsService } from 'src/app/services/workspaces/tariff-versions-settings.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare const jQuery;
declare const swal;
@Component({
    selector: 'app-periods',
    templateUrl: './periods.component.html',
    styleUrls: ['./periods.component.scss']
})
export class PeriodsComponent implements OnInit {
    rows: Array<Period>;
    actualPage = 1;

    constructor(
        private periodsService: PeriodsService,
        private helperService: HelperService,
        public tariffVersionsSettingsService: TariffVersionsSettingsService,
        public tariffVersionFactory: TariffVersionService
        ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.load();
    }
   
    private load() {
        this.helperService.showLoadingMxpro360();
        this.tariffVersionsSettingsService.getAllPeriodsByTariffVersionId(this.tariffVersionFactory.getTariffVersionId());
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.periodsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }


}
