import { AuthService } from 'src/app/services/auth.service';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Estimate } from '../../../entities/workspace/estimate';
import { HelperService } from '../../../services/helper.service';
import { LeadCompany } from '../../../entities/workspace/lead-company';
import { LeadsService } from 'src/app/services/leads.service';
import { LeadView } from 'src/app/entities/workspace/lead-view';
import { SalesmanPerformsService } from '../../../services/companies/salesman-performs.service';
import { SalesmanPerformView } from '../../../entities/workspace/salesman-perform-view';

declare const jQuery;
declare const moment;
@Component({
  selector: 'app-salesman-performs',
  templateUrl: './salesman-performs.component.html',
  styleUrls: ['./salesman-performs.component.scss']
})
export class SalesmanPerformsComponent implements OnInit {

  @ViewChild('estimatesModal') estimatesModal: ElementRef;
  @ViewChild('modal') modal: ElementRef;
  @ViewChild('modalLeads') modalLeads: ElementRef;

  public betweenDates: BetweenDates;
  public estimates: Array<Estimate>;
  public leads: LeadView[];
  public salesmanPerforms: Array<SalesmanPerformView>;
  public salesmanPerformsCustom: Array<any>;
  public titleModal: string;
  public totalAmountQuotesBooked: number;
  public totalCustomerPayments: number;
  public totalEmailsSent: number;
  public totalLeads: number;
  public totalQuotes: number;
  public totalQuotesBooked: number;
  public totalQuotesSent: number;
  public typeService: string;

  constructor(
    private helperService: HelperService,
    private leadsService: LeadsService,
    private salesmanPerformsService: SalesmanPerformsService,
    public authService: AuthService
  ) {
    this.betweenDates = new BetweenDates();
    this.estimates = [];
    this.leads = [];
    this.salesmanPerforms = [];
    this.salesmanPerformsCustom = [];
    this.titleModal = '';
    this.totalAmountQuotesBooked = 0;
    this.totalCustomerPayments = 0;
    this.totalEmailsSent = 0;
    this.totalLeads = 0;
    this.totalQuotes = 0;
    this.totalQuotesBooked = 0;
    this.totalQuotesSent = 0;
    this.typeService = 'MOVING';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
      this.search();
  }

  /**
   * recibe y asigna fechas para filtro
   *
   * @param date Filtro de fechas a buscar
   */
  getFilterDates(date: BetweenDates): void {
    this.betweenDates = date;
  }

  /**
   * Busca los datos por el filtro de fecha
   */
  search(): void {

    if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
      this.betweenDates.dateStart = 1609459200;
      this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }

    // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
    this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

    const data: any = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      type_service: this.typeService
    };

    this.helperService.showLoadingMxpro360();
    this.salesmanPerformsService
      .getSalesPerforms(data)
      .then((response) => {
        this.clearVar();
        this.salesmanPerforms = response;

        for (const totals of this.salesmanPerforms) {
          this.totalQuotes = this.totalQuotes + Number(totals.total_quotes);
          this.totalLeads = this.totalLeads + Number(totals.total_leads);
          this.totalEmailsSent = this.totalEmailsSent + Number(totals.emails_sent);
          this.totalQuotesSent = this.totalQuotesSent + Number(totals.quotes_sent);
          this.totalQuotesBooked = this.totalQuotesBooked + Number(totals.quotes_booked);
          this.totalAmountQuotesBooked = this.totalAmountQuotesBooked + Number(totals.amount_quotes_booked);
          this.totalCustomerPayments = this.totalCustomerPayments + Number(totals.customer_payments);
        }

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  clearVar() {
    this.totalLeads = 0;
    this.totalQuotes = 0;
    this.totalEmailsSent = 0;
    this.totalQuotesSent = 0;
    this.totalQuotesBooked = 0;
    this.totalAmountQuotesBooked = 0;
    this.totalCustomerPayments = 0;
  }

  /**
   * Retorna el numero de jobs de un salesman
   *
   * @param estimates Arreglo de estimates asociados a un employee
   */
  getNumberJobs(estimates: Array<Estimate>): number {

    let countJobs = 0;
    for (const estimate of estimates) {
      countJobs = estimate.booked_date === null ? countJobs : countJobs + 1;
    }
    return countJobs;
  }

  /**
   * Retorna el numero de leads que tienen el status dead lead asociados a un salesman
   *
   * @param leadCompany Arreglo de lead asociados a un employee
   */
  getNumberDeadLeads(leadCompany: Array<LeadCompany>): number {

    let countDeadLead = 0;
    for (const lead of leadCompany) {
      countDeadLead = lead.status === 'DEAD_LEAD' ? countDeadLead + 1 : countDeadLead;
    }
    return countDeadLead;
  }

  //   /**
  //    * Retorna el customer del estimate
  //    *
  //    * @param idCustomer Id del customer a listar
  //    */
  //   getCustomer(idCustomer: string): Customer {

  //     let copyCustomer: Customer = null;
  //     let isCustomer = false;
  //     for (const salesmanPerform of this.salesmanPerforms) {
  //       for (const customer of salesmanPerform.customers) {
  //         if (idCustomer === customer.id) {
  //           copyCustomer = customer;
  //           isCustomer = true;
  //           break;
  //         }
  //       }

  //       if (isCustomer) {
  //         break;
  //       }
  //     }
  //     return copyCustomer;
  //   }

  /**
   * Metodo que abre un nuevo tab al dar click en un estimate
   *
   * @param estimateId Id del estimate a abrir
   */
  openNewTab(estimateId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(estimateId, 'estimate');
  }

  openModalEstimates(estimates: Array<Estimate>): void {
    this.estimates = estimates;
    setTimeout(() => {
      jQuery(this.estimatesModal.nativeElement).modal('show');
    }, 10);
  }

  closeModalEstimates(): void {
    jQuery(this.estimatesModal.nativeElement).modal('hide');
  }

  getLeads(id) {
    const data = {
      date_end: this.betweenDates.dateEnd,
      date_start: this.betweenDates.dateStart,
      date_type: 'OPEN'
  };
  this.helperService.showLoadingMxpro360();
  this.leadsService
      .getMyLeads(data, id)
      .then((response: any) => {
        console.log(response);
        this.leads = response;
      })
      .catch((error) => {
          console.error('Error: ', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
          jQuery(this.modalLeads.nativeElement).modal('show');
      });
  }

  getTotalQuotes(id, totals) {
    this.titleModal = 'TOTAL ESTIMATES';
    if (totals) {
      this.filterSearchTotals('TOTAL_QUOTES');
    } else {
      this.filterSearch('TOTAL_QUOTES', id);
    };
    jQuery(this.modal.nativeElement).modal('show');
  }

  getEmailSent(id, totals) {
    this.titleModal = 'EMAILS SENT';
    if (totals) {
      this.filterSearchTotals('EMAILS_SENT');
    } else {
      this.filterSearch('EMAILS_SENT', id);
    }
    jQuery(this.modal.nativeElement).modal('show');

  }

  getQuotesSent(id, totals) {
    this.titleModal = 'ESTIMATES SENT';
    if (totals) {
      this.filterSearchTotals('QUOTES_SENT');
    } else {
      this.filterSearch('QUOTES_SENT', id);
    }
    jQuery(this.modal.nativeElement).modal('show');

  }

  getQuotesBooked(id, totals) {
    this.titleModal = 'ESTIMATES BOOKED';
    if (totals) {
      this.filterSearchTotals('QUOTES_BOOKED');
    } else {
      this.filterSearch('QUOTES_BOOKED', id);
    }

    jQuery(this.modal.nativeElement).modal('show');

  }

  getCustomerPayments(id, totals) {
    this.titleModal = 'CUSTOMER PAYMENTS';
    if (totals) {
      this.filterSearchTotals('CUSTOMER_PAYMENTS');
    } else {
      this.filterSearch('CUSTOMER_PAYMENTS', id);
    }

    jQuery(this.modal.nativeElement).modal('show');

  }


  /**
   * Busca los datos por el filtro de fecha
   */
  filterSearch(type, id): void {

    const data: any = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      type_service: this.typeService
    };

    this.helperService.showLoadingMxpro360();
    this.salesmanPerformsService
      .getSalesPerformsCustom(data, type, id)
      .then((response) => {

        this.salesmanPerformsCustom = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  /**
   * Busca los datos por el filtro de fecha
   */
  filterSearchTotals(type): void {

    const data: any = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      type_service: this.typeService
    };

    this.helperService.showLoadingMxpro360();
    this.salesmanPerformsService
      .getSalesPerformsTotals(data, type)
      .then((response) => {

        this.salesmanPerformsCustom = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
