export class Employee {

    id: string;
    name: string;
    job_position: 'DRIVER' | 'FOREMAN' | 'OPERATOR' | 'SALES_MANAGER' | 'SALESMAN' | 'WORKER' | 'DISPATCH_SERVICE' | 'CUSTOMER_SERVICE' | 'MANAGER' | 'ADMIN';
    status: 'ACTIVE' | 'DISABLED' | 'DELETED';
    online: boolean;
    last_connection: number;
    ips_allowed: string;
    account_id: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.job_position = null;
        this.status = 'ACTIVE';
        this.online = false;
        this.last_connection = null;
        this.ips_allowed = null;
        this.account_id = null;
        this.created = null;
        this.updated = null;
    }
}
