<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-3 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Additional Concepts
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Additional Concepts
                    </li>
                </ol>
            </nav>
        </div>

        <div class="row col-xl-6">
            <div class="col-lg-5">
                <label for="Behavior">Behavior</label>
                <select id="Behavior" placeholder="Enter here the behavior" name="Behavior" class="form-control" [(ngModel)]="behavior"
                    required>
                    <option selected value="">All</option>
                    <option selected value="DISCOUNT">Discount</option>
                    <option value="ADDITIONAL_STOP_FEE">Additional Stop Fee</option>
                    <option value="BINDING_FEE"> Binding Fee </option>
                    <option value="CHARGE">Charge</option>
                    <option value="DISCOUNT_FULL_PACKING_SERVICE">Discount full packing service</option>
                    <option value="DISCOUNT_PER_MILE">Discount Per Mile</option>
                    <option value="FULL_PACKING_SERVICE">Full Packing Service</option>
                    <option value="LONG_CARRY_DELIVERY">Long Carry on Delivery</option>
                    <option value="LONG_CARRY_PICKUP">Long Carry on Pickup</option>
                    <option value="ELEVATOR_DELIVERY">Stairs/Elevator on Delivery</option>
                    <option value="ELEVATOR_PICKUP">Stairs/Elevator on Pickup</option>
                    <option value="SHUTTLE_DELIVERY">Shuttle on Delivery</option>
                    <option value="SHUTTLE_PICKUP">Shuttle on Pickup</option>
                    <option value="SPLIT_DELIVERY">Split Delivery</option>
                    <option value="SPLIT_PICKUP">Split Pickup</option>
                    <option value="OPEN_QUICK_QUOTE_QUESTION">Quick Quote Question (Enter/Value)</option>
                    <option value="CLOSED_QUICK_QUOTE_QUESTION">Quick Quote Question (Yes/No)</option>
                </select>
            </div>
            <div class="col-lg-5">
                <label>Service Type</label> <br>
                <select name="month" class="form-control" style="padding-left: 40px;" [(ngModel)]="serviceType">
                    <option value=""> All </option>
                    <!-- <option value="PICKUP"> Pickup </option>
                    <option value="DELIVERY"> Delivery </option>
                    <option value="NOT_APPLY"> Not Apply </option> -->
                    <option value="LONG_DISTANCE"> Long Distance </option>
                    <option value="LOCAL_DISTANCE"> Local Distance </option>
                    <option value="AUTO_TRANSPORT"> Auto Transport </option>
                    <option value="FREIGHT"> Freight </option>
                </select>
            </div>

            <div class="col-lg-2">
                <label>&nbsp;</label>
                <button class="btn btn-warning btn-search" (click)="search()"> Search </button>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/tariff-additional-concept/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Addition Concept
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">

            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Feature</th>
                                <th>Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let tariff of additionalConsepts | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{tariff.name}}</td>
                                <td>
                                    <strong>Behavior:</strong>

                                    <ng-container
                                        *ngIf="!['DISCOUNT', 'CHARGE', 'CLOSED_QUICK_QUOTE_QUESTION', 'OPEN_QUICK_QUOTE_QUESTION'].includes(tariff.behavior)">
                                        {{ tariff.behavior | filterReplace }}
                                    </ng-container>

                                    <ng-container *ngIf="tariff.behavior == 'DISCOUNT' || tariff.behavior == 'CHARGE'">
                                        {{ tariff.behavior | filterReplace }}

                                        <br>

                                        <ng-container *ngIf="tariff.value == null">
                                            <strong>Value:</strong> Request <br>
                                        </ng-container>

                                        <ng-container *ngIf="tariff.value != null">
                                            <strong>Value:</strong> {{tariff.value}} <br>
                                        </ng-container>

                                        <strong
                                            *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'CUBIC_FEET'">By
                                            Cf: </strong>
                                        <strong *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'POUNDS'">By
                                            Lbs:</strong> {{tariff.by_cubic_feet}}

                                    </ng-container><br>

                                    <ng-container
                                        *ngIf="tariff.behavior == 'CLOSED_QUICK_QUOTE_QUESTION' || tariff.behavior == 'OPEN_QUICK_QUOTE_QUESTION'">
                                        <span *ngIf="tariff.behavior == 'CLOSED_QUICK_QUOTE_QUESTION'">
                                            Quick Quote Question (Yes/No)
                                        </span>

                                        <span *ngIf="tariff.behavior == 'OPEN_QUICK_QUOTE_QUESTION'">
                                            Quick Quote Question (Enter/Value)
                                        </span>
                                        <br>
                                        <strong>Value:</strong> {{tariff.value}} min <br>
                                    </ng-container><br>

                                    <strong>By Percentage: </strong>{{tariff.by_percentage}}

                                    <ng-container *ngIf="tariff.behavior == 'DISCOUNT_PER_MILE'">
                                        Discount Per Mile
                                    </ng-container>
                                    <ng-container *ngIf="tariff.behavior == 'ADDITIONAL_STOP_FEE'">
                                        Additional Stop Fee
                                    </ng-container>
                                    <br>
                                    <strong>Stop Type:</strong> {{tariff.stop_type | filterReplace}}
                                </td>

                                <td>
                                    <a appWorkspaceRouterLink="/tariff-additional-concept/{{tariff.id}}/edit"
                                        class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(tariff.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>