import { Component, OnInit } from '@angular/core';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-phone-extensions',
  templateUrl: './phone-extensions.component.html',
  styleUrls: ['./phone-extensions.component.scss']
})
export class PhoneExtensionsComponent implements OnInit {

  public extensions: any[];
  public companies: any[];
  public workspaceId: string;
  readonly environment = environment;
  public nums: PhoneLine[];


  constructor(
    private helperService: HelperService,
    private phoneExtensionsService: PhoneExtensionsService,
    private companiesService: CompaniesService,
    private authService: AuthService,
    private phoneLineService: PhoneLinesService,
  ) {

    this.extensions = [];
    this.companies = [];

  }

  ngOnInit(): void {

    this.loadCompany();
    this.loadExtensions();
    this.loadLines();
  }

  private loadCompany() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
      .getAll()
      .then((response) => {


        this.companies = response;
        this.workspaceId = this.authService.workspaceSession.workspace.id;

      })
      .catch((error) => {
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }



  private loadExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
      .getAllExtensions()
      .then((response) => {
        this.extensions = response;
        console.log('this.extensions!!',this.extensions);


      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  };

  private loadLines() {
    this.helperService.showLoadingMxpro360();
    this.phoneLineService.getAll().then((response) => {

      this.nums = response;

      console.log('this.nums', this.nums);


    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };



  public remove(id) {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService.remove(id).then((response) => {
      console.log('removido', response);
      this.loadExtensions();
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })

  }

}
