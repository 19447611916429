import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/entities/workspace/company';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneIvrsService } from 'src/app/services/workspaces/phone-ivrs.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';
import { environment } from 'src/environments/environment';


declare var swal;

@Component({
  selector: 'app-phone-extensions-form',
  templateUrl: './phone-extensions-form.component.html',
  styleUrls: ['./phone-extensions-form.component.scss']
})
export class PhoneExtensionsFormComponent implements OnInit {


  public dataForm: PhoneExtension;
  public targets: any[];
  public queues: any;

  public nums: PhoneLine[];
  public companies: Array<Company>;
  public employees: EmployeeView[];
  public ringGroups: PhoneRingGroup[];
  public ivrs: PhoneIvr[];
  public workspaceId: string;
  readonly environment = environment;

  JSON;
  public extensions: any[];

  constructor(
    private employeesCompanyService:EmployeesCompanyService,
    private helperService: HelperService,
    private phoneExtensionsService : PhoneExtensionsService,
    private phoneIvrsService: PhoneIvrsService,
    private phoneQueuesService : PhoneQueuesService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private companiesService: CompaniesService,
    private authService: AuthService,
    private currentRoute: ActivatedRoute,
    private phoneLineService: PhoneLinesService,

  ) {

    this.dataForm = new PhoneExtension();
    this.extensions = [];

   }

  ngOnInit(): void {

    this.loadRingGroups();
    this.loadIvrs();
    this.loadCompany();
    this.loadExtensions();
    this.loadLines();
    this.queue();

  }


  ngAfterViewInit(){




    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.loadExtensionsId(params.id);
      }
    })

  }

  private loadExtensionsId(id: any) {
    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getById(id)
        .then((response) => {
          this.dataForm = response;

          setTimeout(() => {
    this.changeCompanie();


          }, 500);

        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };



  save(): void {
    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService.save(this.dataForm)
    .then( (response)=> {
      console.log('response => ', response);
      this.helperService.goToWorkspaceRouterLink('/phone-extensions')
    }).catch((error) => {
      console.log('error save ', error);


        swal(
          'Error',
          'Error The extension has already been created',
          'error'
        );




    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();

    });
  }

  private loadRingGroups() {

    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService
        .getAll()
        .then((response) => {
          this.ringGroups = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private loadExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllExtensions()
        .then((response) => {
          this.extensions = response;

          console.log('this.extensions!°°;',this.extensions);

          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private loadListNums() {

    /* this.dataForm.company_id */
    this.employeesCompanyService
      .getAllCompanies(this.dataForm.company_id)
      .then((response) => {

        console.log('log de employes por companie',response);


        this.employees = response;

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      });
  };


  private loadIvrs() {

    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService
        .getAll()
        .then((response) => {
          this.ivrs = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private loadCompany() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
        .getAll()
        .then((response) => {
            this.companies = response;
            this.workspaceId = this.authService.workspaceSession.workspace.id;
            this.loadExtensions();

        })
        .catch((error) => {
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }


  private loadLines(){
    this.helperService.showLoadingMxpro360();
        this.phoneLineService.getAll().then((response) => {
        console.log('response',response);
        this.nums = response;


    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };


  onChangeAnnouncement(files: Array<string>) {

    let extencion = files[0].split('.');

    if (files.length > 0) {

    if ( extencion[1] === 'mp3'|| extencion[1] === 'mpeg'|| extencion[1] === 'ogg'|| extencion[1] === 'wav') {

     let mime = files[0].split('.');
     this.dataForm.voicemail_announcement.display_name = files[0];
     this.dataForm.voicemail_announcement.name = files[0];
     this.dataForm.voicemail_announcement.mime_type = 'audio/' + mime[1];

    }else{
     swal(
       'Error',
       'error the audio extension must be               mp3 mpeg ogg or wav',
       'error'
     );
    }

 }

  }


  private queue(){
    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService.getAllCompany().then((response) => {

      console.log('queue',response);

      this.queues = response;
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };

  changeCompanie(){

    if (this.dataForm.company_id) {

      this.loadListNums();

    }

  }




  changeTarget(){

    if (this.dataForm.destination.target == 'VOICEMAIL') {
      // this.targets = this.extensions.filter(item => item.destination.target != 'RING_GROUP' && item.destination.target != 'QUEUE' && item.destination.target != 'IVR');
      this.targets = this.extensions;
    }

    if (this.dataForm.destination.target == 'PHONE_LINE') {
      this.targets = this.nums;
    }

    if(this.dataForm.destination.target == 'RING_GROUP'){
      this.targets = this.ringGroups;
    }

    if(this.dataForm.destination.target == 'EXTERNAL_FORWARD'){
      this.dataForm.destination.target_id = '';
    }

    if(this.dataForm.destination.target == 'IVR'){
      this.targets = this.ivrs;
    }
    if (this.dataForm.destination.target =='EXTENSION') {

      this.targets = this.extensions;

    }
    if (this.dataForm.destination.target =='QUEUE') {

      this.targets =  this.queues;



    }
    console.log(this.targets);
  }





}
