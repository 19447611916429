import { PackingMaterial } from "./packing-material";

export class EstimateMaterial {
    public id: string;
    public packing_material: PackingMaterial;
    public estimate_id: string;
    public quantity: number;

    constructor() {
        this.id = null;
        this.packing_material = new PackingMaterial();
        this.estimate_id = null;
        this.quantity = 0;
    }
}
