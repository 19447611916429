<div class="layout-spacing layout-top-spacing">
    <div class="row">

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <h3>
                Estimates
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Estimates
                    </li>
                </ol>
            </nav>
            
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-left top-actions-btns">
                <a class="btn btn-new" role="button" *ngIf="!activeQuickAssign" (click)="openQuickAssignModal()" [appCanUserCompany]="['REASSIGN_ESTIMATE']">
                    <span>Mass Re-Assign</span> 
                </a>
                <a class="btn btn-new" role="button" *ngIf="!activeQuickAssign" (click)="setQuickAssign(true)" [appCanUserCompany]="['REASSIGN_ESTIMATE']">
                    <span>Activate Quick Assign</span> 
                </a>
                <a class="btn btn-dark" role="button" *ngIf="activeQuickAssign" (click)="setQuickAssign(false)">
                    <span>Deactivate Quick Assign</span> 
                </a>
                <div class="col col-21 pr-0" *ngIf="activeQuickAssign">
                    <select name="wd_employees_id" 
                        class="form-control"
                        [(ngModel)]="employeeSelected">
                        <option value="" selected="selected">Select User...</option>
                        <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right top-actions-btns">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter"
                    role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
                <a appCompanyRouterLink="/estimates/add"
                    [appCanUserCompany]="['ESTIMATE_CREATE_MOVING','ESTIMATE_CREATE_AUTOTRANSPORT', 'ESTIMATE_UPSALES']"
                    class="btn btn-new">
                    <i class="fas fa-plus-square"></i>
                    New Estimate
                </a>
            </div>
        </div>

    </div>


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12 align-self-center">

            <app-date-filter class="estimates-date-filter" (onChange)="getFilterDates($event)"></app-date-filter>

            <div class="row col-lg-12 col-md-12 block-filter">

                <div class="col-12 col-lg-4 col-xl-2 pl-0 mt-3">
                    <label>Pickup State</label>
                    <select name="pickup_state" id="pickup_state" [(ngModel)]="filterData.pickup_state" class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>  

                <div class="col-6 col-lg-4 col-xl-2 pl-0 mt-3">
                    <label>Delivery State</label>
                    <select name="delivery_state" id="delivery_state" [(ngModel)]="filterData.delivery_state" class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>

                <div class="col-6 col-lg-4 col-xl-2 pl-0 mt-3">
                    <label>Filter Date For</label>
                    <select name="filter_date_for" id="filter_date_for" [(ngModel)]="filterData.filter_date_for"
                        class="form-control">
                        <option value="" selected="selected">Created Date</option>
                        <option value="PICKUP">Pickup Date</option>
                        <option value="BOOKING">Booking Date</option>
                    </select>
                </div>

                <div class="col-6 col-lg-4 col-xl-2 pl-0 mt-3">
                    <label>Service</label>
                    <select name="service" id="service" [(ngModel)]="filterData.service" class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="LOCAL">Local Distance</option>
                        <option selected value="LONG">Long Distance</option>
                        <option value="AUTO_TRANSPORT">Auto Transport</option>
                        <option value="FREIGHT">Freight</option>
                    </select>
                </div>

                <div class="col-6 col-lg-4 col-xl-2 pl-0 mt-3" *ngIf="authService.workspaceSession.employee.job_position == 'ADMIN' || authService.workspaceSession.employee.job_position == 'MANAGER'">
                    <label>Agent</label>
                    <select [(ngModel)]="filterData.agent" name="wd_employees_id" class="form-control" [appSelect2]
                        [theme]="'mxp360-form'">
                        <option value="" selected="selected">Select All</option>
                        <option *ngFor="let employe of employees" value="{{employe.id}}">
                            {{employe.name}}</option>
                    </select>
                </div>

                <div class="col-6 col-lg-4 col-xl-2 pl-0 mt-3">
                    <label>Sort</label>
                    <select [(ngModel)]="filterData.sort" name="wd_employees_id" class="form-control">
                        <option value="2">Desc</option>
                        <option value="1">Asc</option>
                    </select>
                </div>

                <div class="col-6 col-lg-4 col-xl-1 pl-0 mt-3">
                    <button class="btn btn-secondary btn-filter" (click)="getAllWithFullView()">
                        Search
                    </button>
                </div> 

            </div>

        </div>

        <div class="col-lg-12 col-md-12 text-center align-self-center container-filter-by-category">
            <div class="btn-group btn-group-justified filter-by-category">
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'ALL', 'btn-dark': btnActived != 'ALL'}"
                        (click)="filterByCategory('ALL')">All</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'OPEN', 'btn-dark': btnActived != 'OPEN'}"
                        (click)="filterByCategory('OPEN')">Open</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'HOT', 'btn-dark': btnActived != 'HOT'}"
                        (click)="filterByCategory('HOT')">Hot</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'WARM', 'btn-dark': btnActived != 'WARM'}"
                        (click)="filterByCategory('WARM')">Warm</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'COLD', 'btn-dark': btnActived != 'COLD'}"
                        (click)="filterByCategory('COLD')">Cold</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'NEW', 'btn-dark': btnActived != 'NEW'}"
                        (click)="filterByCategory('NEW')">New</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'BOOKED', 'btn-dark': btnActived != 'BOOKED'}"
                        (click)="filterByCategory('BOOKED')">Booked</button>
                </div>
                <div class="btn-group">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'WITHOUTFOLLOWUPS', 'btn-dark': btnActived != 'WITHOUTFOLLOWUPS'}"
                        (click)="filterByCategory('WITHOUTFOLLOWUPS')">Without Follow Ups</button>
                </div>
                <div class="btn-group" [appCanUserCompany]="['UNSUCCESSFUL_SALES_TAB']">
                    <button class="btn" type="button"
                        [ngClass]="{'btn-secondary': btnActived == 'UNSUCCESSFUL_SALES', 'btn-dark': btnActived != 'UNSUCCESSFUL_SALES'}"
                        (click)="filterByCategory('UNSUCCESSFUL_SALES')">Unsuccessful sales</button>
                </div>
            </div>
        </div>
    </div>
    <!-- header -->

    <!-- Help Bar -->
    <div class="col-lg-12 col-md-12 label-section">
        <div class="label-block">
            <div class="box booked-estimate-box"></div>
            <div>Booked Estimate</div>        
        </div>        
        <div class="label-block">
            <div class="box cancelled-estimate-box"></div>
            <div>Cancelled Estimate</div>        
        </div>        
    </div>

    <!-- Estimates -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content searchable-container list">
                <div class="searchable-items list">

                    <div class="items items-header-section">
                        <div class="item-content">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <h4>
                                    Customer
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4 style="margin-left: 3px;">
                                    Service
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4 style="margin-left: 3px;">
                                    Description
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4 style="margin-left: 3px;">
                                    Date
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4 style="margin-left: 3px;">
                                    Estimate
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="" *ngFor="let estimate of paginator.data; index as indexEstimate;">
                        <div class="col-md-12 items item-estimate" [ngClass]="{'has-follow-up': estimate.follow_up_reminder != null}">
                            
                            <!-- Estimate Notes Button -->
                            <a class="btn-estimate-notes tooltipmxp tooltipmxp-right" title="Estimate Notes" data-toggle="collapse"
                                 href="#collapse_note_{{estimate.id}}" *ngIf="estimate.notes.length > 0"
                                 role="button" aria-expanded="false" aria-controls="collapse">
                                <i class="fa-solid fa-message"></i>
                                <span class="note-alert" *ngIf="estimate.notes.length > 0"></span>
                            </a> 

                            <div class="btn-estimate-notes tooltipmxp tooltipmxp-right" id="load_follow_ups_{{estimate.id}}"
                                (click)="addFollowUps(estimate.id)" title="Add a New Note" *ngIf="!estimate.notes.length">
                                <i class="fa-solid fa-message"></i>
                            </div>                                                       
                            
                            <!-- Estimate -->
                            <div class="item-content m-0" [ngClass]="{'booked-active': estimate.booked && estimate.cancellation == null, 'cancelled-job': estimate.cancellation != null}" (click)="openNewTab(estimate.id);$event.stopPropagation()">
                                                                
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">

                                    <div>
                                        <p class="word-break-customer">
                                            <strong>
                                                {{estimate.customer.last_name?.toUpperCase()}}
                                            </strong>
                                            <span *ngIf="estimate.customer.name">
                                                , {{estimate.customer.name | filterReplace}}
                                            </span>
                                        </p>
                                    </div>

                                    <div class="customer-info">
                                        <p class="word-break-customer">
                                            <i class="far fa-copy pr-2" (click)="copyText(estimate.customer.phone, 'text');$event.stopPropagation()"></i>
                                            <span class="customer-phone" (click)="copyText(estimate.customer.phone, 'text');$event.stopPropagation()">
                                                {{estimate.customer.phone | phone:'US'}}
                                            </span>
                                        </p>
                                    </div>

                                    <div class="customer-info">
                                        <p class="word-break-customer">
                                            <i class="far fa-copy pr-2" (click)="copyText(estimate.customer.email, 'text');$event.stopPropagation()"></i>
                                            <span class="customer-email" (click)="copyText(estimate.customer.email, 'text');$event.stopPropagation()">
                                                {{estimate.customer.email}}
                                            </span>
                                        </p>
                                    </div>

                                    <div class="user-meta-info" *ngIf="!estimate.customer">
                                        <p class="user-name">Pending Signature</p>
                                    </div>
                                </div>

                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">

                                    <div *ngIf="estimate.service == 'AUTO_TRANSPORT'; else noneAuto">
                                        <strong [ngClass]="{'estimate-booked': estimate.booked, 'estimate-no-booked': !estimate.booked}">
                                            Type:
                                        </strong>
                                        <span style="margin-left: 10px;" class="badge" [ngClass]="{'badge-danger': estimate.service == 'FREIGHT', 'badge-info': estimate.service !== 'FREIGHT'}">
                                            <span *ngIf="estimate.service == 'LONG'">Long Distance</span>
                                            <span *ngIf="estimate.service == 'LOCAL'">Local Distance</span>
                                            <span *ngIf="estimate.service == 'FREIGHT'">Freight</span>
                                            <span *ngIf="estimate.service == 'AUTO_TRANSPORT'">Auto Transport</span>
                                        </span>
                                    </div>

                                    <ng-template #noneAuto>
                                        <div *ngIf="estimate.service == 'LONG'; else noneLong">
                                            <strong [ngClass]="{'estimate-booked': estimate.booked, 'estimate-no-booked': !estimate.booked}">
                                                Type:
                                            </strong>
                                            <span style="margin-left: 10px;" class="badge" [ngClass]="{'badge-danger': estimate.service == 'FREIGHT', 'badge-secondary': estimate.service !== 'FREIGHT'}">
                                                <span *ngIf="estimate.service == 'LONG'">Long Distance</span>
                                                <span *ngIf="estimate.service == 'LOCAL'">Local Distance</span>
                                                <span *ngIf="estimate.service == 'FREIGHT'">Freight</span>
                                                <span *ngIf="estimate.service == 'AUTO_TRANSPORT'">Auto Transport</span>
                                            </span>
                                        </div>
                                    </ng-template>

                                    <ng-template #noneLong>
                                        <div>
                                            <strong [ngClass]="{'estimate-booked': estimate.booked, 'estimate-no-booked': !estimate.booked}">
                                                Type:
                                            </strong>
                                            <span style="margin-left: 10px;" class="badge" [ngClass]="{'badge-danger': estimate.service == 'FREIGHT', 'badge-dark': estimate.service !== 'FREIGHT'}">
                                                <span *ngIf="estimate.service == 'LONG'">Long Distance</span>
                                                <span *ngIf="estimate.service == 'LOCAL'">Local Distance</span>
                                                <span *ngIf="estimate.service == 'FREIGHT'">Freight</span>
                                                <span *ngIf="estimate.service == 'AUTO_TRANSPORT'">Auto Transport</span>
                                            </span>
                                        </div>
                                    </ng-template>

                                    <p class="d-flex">
                                        <strong class="w-52">From:</strong>
                                        <span>
                                            {{estimate.pickup.address.state}}
                                            {{estimate.pickup.address.city}}
                                            {{estimate.pickup.address.zip_code}}
                                        </span>
                                    </p>

                                    <p class="d-flex">
                                        <strong class="w-52">To:</strong>
                                        <span>
                                            {{estimate.delivery.address.state}}
                                            {{estimate.delivery.address.city}}
                                            {{estimate.delivery.address.zip_code}}
                                        </span>
                                        
                                    </p>

                                </div>

                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">

                                    <div *ngIf="estimate.service != 'AUTO_TRANSPORT'">
                                        <p class="usr-location">
                                            <strong class="w-46" *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                                Lbs:
                                            </strong>
                                            <strong class="w-46" *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">
                                                Cf:
                                            </strong>
                                            <span>{{estimate.volume.libs | number}}</span>
                                        </p>
                                        <p class="usr-location">
                                            <strong class="w-46" *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">
                                                <span>Cf:</span>
                                            </strong>
                                            <strong class="w-46" *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">
                                                Lbs:
                                            </strong>
                                            <span>{{estimate.volume.cubic_feets_selled | number}}</span>
                                        </p>
                                    </div>

                                    <div *ngIf="estimate.service == 'AUTO_TRANSPORT'">
                                        <p class="usr-location">
                                            <strong># Vehicles:</strong> {{estimate.number_vehicles | number}}
                                        </p>
                                    </div>

                                    <p class="usr-location">
                                        <strong class="w-46">Miles:</strong>
                                        <span>{{estimate.volume.miles | number : '1.0-0'}}</span>
                                    </p>
                                    <p class="usr-location" *ngIf="estimate.employee && estimate.employee.name">
                                        <strong>Salesman:</strong> {{estimate.employee.name | titlecase}}
                                    </p>
                                </div>

                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                    <p class="usr-email-addr" *ngIf="estimate.pickup.range_start">
                                        <span style="margin-right: 10px;" class="badge badge-danger">Move Date:</span>
                                        {{estimate.pickup.range_start | date}} to
                                        {{estimate.pickup.range_end | date}}
                                    </p>
                                    <p class="usr-location">
                                        <strong>Estimated Date: </strong>{{estimate.created | date}}
                                    </p>
                                    <p *ngIf="estimate.booked" class="usr-location">
                                        <strong>Booked Date: </strong>{{estimate.booked_date | date}}
                                    </p>
                                </div>

                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                    <p class="usr-ph-no">
                                        <strong class="w-66">Number:</strong>
                                        <span>{{estimate.document.code}}</span>
                                    </p>
                                    <p class="usr-ph-no">
                                        <strong class="w-66">Estimate:</strong>
                                        <span>{{estimate.total | currency}}</span>
                                    </p>
                                    <p class="usr-ph-no">
                                        <strong class="w-66">Category:</strong>
                                        <span>{{estimate.category}}</span>
                                    </p>
                                </div>

                                <i class="fas fa-calendar calendar-follow-up"></i>
                            </div>

                            <div class="btn-assign-employee tooltipmxp tooltipmxp" id="load_follow_ups_{{estimate.id}}"
                                (click)="toAssign(estimate.id, indexEstimate)" title="Assign Employee" *ngIf="activeQuickAssign && employeeSelected !== ''">
                                <i class="fa-solid fa-user"></i>
                            </div>   

                        </div>

                        <!-- Notes -->
                        <div class="col-md-12 item-estimate-note collapse" id="collapse_note_{{estimate.id}}" >
                            <div class="items items-header-section">
                                <div class="item-content block-note">

                                    <a title="Add a New Note" class="tooltipmxp tooltipmxp-right btn-call-action btn-new-note" (click)="addFollowUps(estimate.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-plus-circle">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" y1="8" x2="12" y2="16"></line>
                                            <line x1="8" y1="12" x2="16" y2="12"></line>
                                        </svg>
                                    </a>

                                    <div class="col-12 padd-l70">                                        
                                        <div class="timeline-alter" *ngIf="estimate.notes.length > 0" id="all_notes_show_{{ estimate.id }}">
                                            <div *ngFor="let follow of estimate.notes; index as i;" class="item-timeline">
                                                <div class="t-meta-date">
                                                    <p *ngIf="follow.employee">
                                                        {{follow.created | dateTime}}
                                                    </p>
                                                    <p *ngIf="follow.employee">
                                                        {{follow.employee.name}}
                                                    </p>
                                                    <p *ngIf="!follow.employee">
                                                        {{follow.created | dateTime}} - MoverXpro360
                                                    </p>
                                                </div>
                                                <div class="t-dot">
                                                </div>
                                                <div class="t-text follow-remarks">
                                                    <pre [innerHTML]="follow.remarks"></pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <app-custom-paginator [currentPage]="paginator.paginator.page"
                        [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page"
                        (pageChange)="setCurrentPage($event)">
                    </app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal follow -->
<div class="modal fade register-modal" #modalFollowupsremarks tabindex="-1" role="dialog" aria-labelledby="companyTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followupsremarksTitle">
                    Add New Estimate Notes
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="inputState">
                        Note
                    </label>
                    <div class="list-title">
                        <textarea class="form-control textarea-force" name="estimate_followup_remarks"
                            id="estimate_followup_remarks" [(ngModel)]="followupsremarks.remarks"
                            placeholder="Write the note here" cols="30" rows="5">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addfollowupsremarks(estimateId)" data-dismiss="modal" type="button"
                    class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal follow -->
<div class="modal fade register-modal" #modalQuickAssign tabindex="-1" role="dialog" aria-labelledby="companyTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalQuickAssign">
                    Mass Re-Assign Estimate
                </h5>
            </div>
            <div class="modal-body">
                <div class="mb-4">
                    <label for="inputState">
                        Select "From" User
                    </label>
                    <select name="mass_from_user" 
                        class="form-control"
                        [(ngModel)]="fromEmployeeSelected">
                        <option value="" selected="selected">Select User...</option>
                        <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="inputState">
                        Select "To" User
                    </label>
                    <select name="mass_to_user" 
                        class="form-control"
                        [(ngModel)]="toEmployeeSelected">
                        <option value="" selected="selected">Select User...</option>
                        <option *ngFor="let employee of employees" value="{{employee.id}}">{{employee.name}}</option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="reAssignEmployee()" data-dismiss="modal" type="button" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>