<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
            <button (click)="validateRates()" class="btn btn-success">
                <i class="fas fa-plus-square"></i> &nbsp; Validate
            </button>
            <button (click)="save()" class="btn btn-main-action">
                Save Rates
            </button>
        </div>
    </div>
    <!-- header -->

    <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">



            <div class="row select2-route-rates">
                <div class="col-5">
                    <div class="form-group md-4">
                        <label for="zone_out_field">Zone Out</label>
                        <select id="zone_out_field" [(ngModel)]="zoneOutId" name="wp_zone_id" class="form-control"
                            [appSelect2] [theme]="'mxp360-form'">
                            <option *ngFor="let zoneOut of tariffVersionsSettingsService.zones" value="{{zoneOut.id}}">{{zoneOut.name}}</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Select Zone Out</small>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-group md-4">
                        <label for="period_field">Period</label>
                        <select id="period_field" [(ngModel)]="periodId" name="wp_period" class="form-control"
                            [appSelect2] [theme]="'mxp360-form'">
                            <option *ngFor="let period of tariffVersionsSettingsService.periods" value="{{period.id}}">{{period.name}}</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Select Period</small>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group md-4">
                        <br>
                        <button class="btn btn-main-action mt-3" (click)="load()" type="submit">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
            <!-- <div class="row" > -->
                <div class="col-lg-12">

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover mb-4 table-zone-tariffs">
                            <thead>
                                <tr>
                                    <th>Up To</th>
                                    <th>Min 
                                        <span *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'CUBIC_FEET'"> cf </span>
                                        <span *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'POUNDS'">Lbs</span>
                                    </th>
                                    <th *ngFor="let volume of volumes">{{volume.up_to_cf}}</th>
                                    <th> 
                                        <span *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'CUBIC_FEET'"> Cf </span>
                                        <span *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'POUNDS'">Lbs</span>
                                        Discount
                                    </th>
                                    <th>Packing $ Cost</th>
                                    <th>Packing Discount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rr of routesRates">
                                    <td class="zone-name">
                                        {{rr.zone_in.name}}
                                    </td>
                                    <td>
                                        <input type="number" [(ngModel)]="rr.min_cf" step="1" name="{{rr.id}}_min_cf" class="form-control">
                                    </td>
                                    <td *ngFor="let vr of rr.volume_rates">
                                        <input type="number" [(ngModel)]="vr.cf_cost" step="0.01" id="{{rr.id}}_{{vr.id}}" name="{{rr.id}}_{{vr.id}}" class="form-control">
                                    </td>
                                    <td>
                                        <input type="number" [(ngModel)]="rr.cf_discount" step="0.01" name="{{rr.id}}_cf_discount" class="form-control">
                                    </td>
                                    <td>
                                        <input type="number" [(ngModel)]="rr.packing_cost" step="0.01" name="{{rr.id}}_packing_cost" class="form-control">
                                    </td>
                                    <td>
                                        <input type="number" [(ngModel)]="rr.packing_discount" step="0.01" name="{{rr.id}}_packing_discount" class="form-control">
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>


        </div>
    </div>


    <!-- body -->

</div>
