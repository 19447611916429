<div class="widget widget-table-one">
    <div class="widget-heading">
        <h5 class="">My Performance</h5>
    </div>

    <div class="widget-content mt-4">

        <div class="transactions-list py-1">
            <div class="t-item">
                <div class="t-performance-info">
                    
                    <div class="t-amount">
                        <span class="pr-2">$</span>
                        <span>{{jobsOwnPerformance.amount_quotes_booked}}</span>
                    </div>
                    <div class="t-desc">
                        <span>Amount Quotes Booked</span>
                    </div>
                    
                </div>
           
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                </div>
               
            </div>
        </div>
        <div class="transactions-list py-1">
            <div class="t-item">
                <div class="t-performance-info">
                    
                    <div class="t-amount">
                        <span>{{jobsOwnPerformance.customer_payments}}</span>
                    </div>
                    <div class="t-desc">
                        <span>Customer Payments</span>
                    </div>
                    
                </div>

                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>                   
                </div>
            </div>
        </div>
        <div class="transactions-list py-1">
            <div class="t-item">
                <div class="t-performance-info">
                    
                    <div class="t-amount">
                        <span>{{jobsOwnPerformance.total_quotes}}</span>
                    </div>
                    <div class="t-desc">
                        <span>Total Quotes</span>
                    </div>
                    
                </div>

                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                </div>
            </div>
        </div>
        <div class="transactions-list py-1">
            <div class="t-item">
                <div class="t-performance-info">
                    
                    <div class="t-amount">
                        <span>{{jobsOwnPerformance.emails_sent}}</span>
                    </div>
                    <div class="t-desc">
                        <span>Emails Sent</span>
                    </div>
                    
                </div>

                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                </div>
            </div>
        </div>
        <div class="transactions-list py-1">
            <div class="t-item">
                <div class="t-performance-info">
                    
                    <div class="t-amount">
                        <span>{{jobsOwnPerformance.quotes_sent}}</span>
                    </div>
                    <div class="t-desc">
                        <span>Quotes Sent</span>
                    </div>
                    
                </div>

                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                </div>
            </div>
        </div>
        <div class="transactions-list py-1">
            <div class="t-item">
                <div class="t-performance-info">
                    
                    <div class="t-amount">
                        <span>{{jobsOwnPerformance.quotes_booked}}</span>
                    </div>
                    <div class="t-desc">
                        <span>Quotes Booked</span>
                    </div>
                    
                </div>

                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                </div>
            </div>
        </div>        
        
    </div>
</div>