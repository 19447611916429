import { CompanyNotificationView } from '../entities/workspace/company-notification-view';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyNotificationService {

  public notificationTimer: any;

  constructor(private http: HttpClient) {
    this.notificationTimer = null;
  }

  getMyNotifications(userID: string): Promise<Array<CompanyNotificationView>> {
    return this.http.get<Array<CompanyNotificationView>>(`${environment.api.company}/company-notifications/${userID}/follow-ups`).toPromise();
  }

  updateMyNotifications(notificationId: string, status: string) {
    const data = {
      status
    };
    return this.http.put(`${environment.api.company}/company-notifications/${notificationId}`, data).toPromise();
  }
  readMyNotifications(userID: string): Promise<Array<CompanyNotificationView>> {
    return this.http.put<Array<CompanyNotificationView>>(`${environment.api.company}/company-notifications/${userID}/read-notifications`, {}).toPromise();
  }

  setNotificationInterval() {
    this.notificationTimer = Math.random()*1000;
  }

  getNotificationInterval() {
    return this.notificationTimer;
  }
}
