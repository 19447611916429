<div class="layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="widget-heading d-flex justify-content-between">
            <h3 class="title-content-area">
                Tickets
            </h3>
            <a *ngIf="estimate.status !== 'WORK_CARRIED_OUT'" appCompanyRouterLink="/moving/{{estimateId}}/tickets/add" class="btn btn-new" style="height:37px">
                <i class="fas fa-plus-circle mr-2"></i> Add Ticket
            </a>                
        </div>

    </div>
</div>

<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <ul class="nav nav-tabs mb-3 mt-3" id="borderTop" role="tablist">
                    <li class="nav-item open-tab">
                        <a class="nav-link active" id="border-top-open-tab" data-toggle="tab" href="#border-top-open"
                            role="tab" aria-controls="border-top-open" aria-selected="true">
                            <i class="fa-solid fa-square-up-right"></i>
                            <span>Open</span>
                        </a>
                    </li>
                    <li class="nav-item closed-tab">
                        <a class="nav-link" id="border-top-closed-tab" data-toggle="tab" href="#border-top-closed"
                            role="tab" aria-controls="border-top-closed" aria-selected="false">
                            <i class="fa-solid fa-square-check"></i>
                            <span>Closed</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="borderTopContent">
                    <div class="tab-pane fade show active" id="border-top-open" role="tabpanel"
                        aria-labelledby="border-top-open-tab">
                        <div class="table-responsive">
                            <table class="table table-bordered mb-4">
                                <thead>
                                    <tr>
                                        <th>Priority Level</th>
                                        <th>Ticket #</th>
                                        <th>Subject</th>
                                        <th>Status</th>
                                        <th>Last Activity</th>
                                        <th>created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let ticket of rows | paginate: {itemsPerPage: 25, currentPage: actualPage}">

                                        <td>
                                            <div class="priority-level-urgent" *ngIf="ticket.priority === 'URGENT'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                            <div class="priority-level-high" *ngIf="ticket.priority === 'HIGH'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                            <div class="priority-level-mid" *ngIf="ticket.priority === 'MID'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                            <div class="priority-level-low" *ngIf="ticket.priority === 'LOW'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                        </td>

                                        <td>{{ticket.document.code}}</td>
                                        <td>
                                            <ng-container *ngFor="let typeTicket of typeTickets">
                                                <ng-container *ngIf="typeTicket.id == ticket.ticket_type_id">
                                                    {{typeTicket.name}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <span *ngIf="ticket.status === 'OPEN'">
                                                <span class="badge badge-info">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'PENDING'">
                                                <span name="pending" class="badge badge-danger">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'RESOLVED'">
                                                <span name="resolved" class="badge badge-success">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'WAITING_COSTUMER'">
                                                <span name="waiting_customer" class="badge badge-warning">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'CLOSED'">
                                                <span name="closed" class="badge badge-secondary">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                        </td>
                                        <td>{{ticket.updated | dateTime}}</td>
                                        <td>{{ticket.created | dateTime}}</td>
                                        <td>
                                            <a appCompanyRouterLink="/tickets/{{ticket.id}}" class="btn btn-info">
                                                <i class="fa-solid fa-eye"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="paginating-container pagination-solid"
                                (pageChange)="actualPage = $event"></pagination-controls>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="border-top-closed" role="tabpanel"
                        aria-labelledby="border-top-closed-tab">
                        <div class="table-responsive">
                            <table class="table table-bordered mb-4">
                                <thead>
                                    <tr>
                                        <th>Priority Level</th>
                                        <th>Ticket #</th>
                                        <th>Subject</th>
                                        <th>Status</th>
                                        <th>Type</th>
                                        <th>Last Activity</th>
                                        <th>created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let ticket of rowsClosed | paginate: {itemsPerPage: 25, currentPage: actualPage}">

                                        <td>
                                            <div class="priority-level-urgent" *ngIf="ticket.priority === 'URGENT'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                            <div class="priority-level-high" *ngIf="ticket.priority === 'HIGH'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                            <div class="priority-level-mid" *ngIf="ticket.priority === 'MID'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                            <div class="priority-level-low" *ngIf="ticket.priority === 'LOW'">
                                                <i class="fa-solid fa-circle-exclamation"></i>
                                                <span>{{ticket.priority}}</span>
                                            </div>
                                        </td>

                                        <td>{{ticket.document.code}}</td>
                                        <td>
                                            <ng-container *ngFor="let typeTicket of typeTickets">
                                                <ng-container *ngIf="typeTicket.id == ticket.ticket_type_id">
                                                    {{typeTicket.name}}
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <span *ngIf="ticket.status === 'OPEN'">
                                                <span class="badge badge-info">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'PENDING'">
                                                <span name="pending" class="badge badge-danger">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'RESOLVED'">
                                                <span name="resolved" class="badge badge-success">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'WAITING_COSTUMER'">
                                                <span name="waiting_customer" class="badge badge-warning">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                            <span *ngIf="ticket.status === 'CLOSED'">
                                                <span name="closed" class="badge badge-secondary">
                                                    {{ticket.status}}
                                                </span>
                                            </span>
                                        </td>
                                        <td>{{ticket.type}}</td>
                                        <td>{{ticket.updated | dateTime}}</td>
                                        <td>{{ticket.created | dateTime}}</td>
                                        <td>
                                            <a appCompanyRouterLink="/tickets/{{ticket.id}}" class="btn btn-info">
                                                <i class="fa-solid fa-eye"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="paginating-container pagination-solid"
                                (pageChange)="actualPage = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>