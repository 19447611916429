import { Injectable } from '@angular/core';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmailTemplatesService {

    constructor(private http: HttpClient) {
        
    }

    getAll(): Promise<Array<EmailTemplate>> {
        return this.http.get<Array<EmailTemplate>>(environment.api.company + '/email-templates').toPromise();
    }

    getById(id: string) {
        return this.http.get<EmailTemplate>(environment.api.company + '/email-templates/' + id).toPromise();
    }

    save(dataForm: EmailTemplate): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: EmailTemplate) {
        return this.http.post(environment.api.company + '/email-templates', dataForm).toPromise();
    }

    edit(dataForm: EmailTemplate) {
        return this.http.put(environment.api.company + '/email-templates/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/email-templates/' + id).toPromise();
    }
}
