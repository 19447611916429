import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from 'src/app/entities/admin/account';
import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { AuthService } from 'src/app/services/auth.service';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { Location } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';


declare var jQuery;
declare const swal;

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  dataForm: Account;
  public confirmPassword: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private accountsService: AccountsService,
    private _location: Location,
    public googleAuthService: GoogleAuthService,
    private helperService: HelperService
  ) { 
    this.dataForm = this.authService.session.account;
    this.dataForm.password = '';
    this.confirmPassword = '';
  }

  ngOnInit(): void {
  }


  save(){
    let msg = '';
    if (this.dataForm.name.trim() === '') {
        msg += "**You Must Provide a Name**<br>";
    }
    if (this.dataForm.password !== this.confirmPassword) {
        msg = `Password and Confirm don't Match`;
    }
    if (msg !== '') {
        swal(
            'Attention',
            msg,
            'warning'
        );
        return;
    }
    this.helperService.showLoadingMxpro360();

    if (this.dataForm.password !== this.confirmPassword) {
      swal({
        title: 'Attention!',
        text: "Password and confirmation do not match!",
        type: 'warning',
        padding: '2em'
      });
      this.helperService.hideLoadingMxpro360();
      return;
    }

    this.accountsService
        .edit(this.dataForm)
        .then((response) => {
          swal({
            title: 'Successful!',
            text: "Your data has been updated",
            type: 'success',
            padding: '2em'
        })
            .then((result) => {
                if (result.value) {
                  this.logout()
                }
            });
          
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }

  async logout() {
    const validateLogout = await this.authService.logout();
    if (validateLogout) {
        this.googleAuthService.disconnect();
        this.router.navigateByUrl('/login');
    } else {
        console.error('Error al cerrar la sesion');
    }
  }

  backTo(){
    this._location.back();
  }


}
