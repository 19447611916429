import { Component, OnInit } from '@angular/core';
import { retry } from 'rxjs/operators';
import { ZoneState } from "src/app/entities/workspace/zone-state";
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { TariffVersionsSettingsService } from 'src/app/services/workspaces/tariff-versions-settings.service';
import { ZoneStatesService } from 'src/app/services/workspaces/zone-states.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-zone-states',
    templateUrl: './zone-states.component.html',
    styleUrls: ['./zone-states.component.scss']
})
export class ZoneStatesComponent implements OnInit {
    rows: Array<ZoneState>;
    actualPage = 1;
    filterSearch = '';
    tariffVersionId: string;
    constructor(
        private zoneStatesService: ZoneStatesService,
        private helperService: HelperService,
        public tariffVersionsSettingsService: TariffVersionsSettingsService,
        public tariffVersionFactory: TariffVersionService
        ) { 
            this.tariffVersionId = '';
        }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.load();
    }

    private load() {
        this.tariffVersionId = this.tariffVersionFactory.getTariffVersionId();
        this.tariffVersionsSettingsService.getAllWithFullView(this.tariffVersionId);
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.zoneStatesService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }


}
