import { Carrier } from 'src/app/entities/workspace/carrier';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { JobAppoinment } from 'src/app/entities/workspace/job-appoinment';
import { ScheduleCalendar } from 'src/app/entities/workspace/schedule-calendar';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { AuthService } from 'src/app/services/auth.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { VehiclesService } from 'src/app/services/workspaces/vehicles.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Job } from '../../../entities/workspace/job';
import { Vehicle } from '../../../entities/workspace/vehicle';
import { CarriersService } from '../../../services/workspaces/carriers.service';
import { DailyCrewService } from 'src/app/services/companies/daily-crew.service';
import { DailyCrew } from 'src/app/entities/workspace/daily-crew';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare var jQuery;
declare const moment;
declare const swal;

@Component({
    selector: 'app-jobs-calendar',
    templateUrl: './jobs-calendar.component.html',
    styleUrls: ['./jobs-calendar.component.scss']
})
export class JobsCalendarComponent implements OnInit, AfterViewInit {

    @ViewChild('divCalendar') divCalendar: ElementRef;

    calendar: any;
    arrayCalendar: Array<ScheduleCalendar>;
    calendario: ScheduleCalendar;
    employees: Array<Employee>;
    employeesView: Array<EmployeeView>;
    allWorkDepartments: Array<WorkDepartment>;
    userId: string;
    workDepartmentId: string;
    state: string;
    moveType: string;
    service: string;
    startDate: string;
    endDate: string;
    startDay: string;
    endDay: string;
    checkBoxesDelivery: boolean;
    checkPackDays: boolean;
    checkPickUps: boolean;
    checkDelivery: boolean;
    detailDay: boolean;
    dayCalendar: Array<any>;
    remark: string;
    dispatched: boolean;
    date: number;
    jobId: string;
    type: string;
    hiddenCalendar: boolean;
    confirm: boolean;
    workers;
    checkWindowDays: boolean;
    crew: DailyCrew;

    typeService: boolean;
    rangeStart: string;
    rangeEnd: string;

    // checkedTrucks: boolean;

    /*
    Guarda los ids de los vehiculos seleccionados
    */
    public idsTrucks: Array<string>;
    public job: Job;
    public titleModalCarrier: string;
    typeModal: string;
    truncks: Array<Vehicle>;

    // variable que muestra el dia seleccionados
    selectedDay: number;

    @ViewChild('modalCarriers')
    modalCarriers: ElementRef;

    @ViewChild('modalExtra')
    modalExtra: ElementRef;

    public carriers: Array<Carrier>;
    calendarAT: boolean;
    currentDate;

    constructor(
        private jobsService: JobsService,
        private workDepartmentsService: WorkDepartmentsService,
        private employeesService: EmployeesService,
        private vehiclesService: VehiclesService,
        private carrierService: CarriersService,
        private helperService: HelperService,
        private currentRoute: ActivatedRoute,
        private dailyCrewService: DailyCrewService,
        public authService: AuthService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.arrayCalendar = [];
        this.calendario = new ScheduleCalendar();
        this.employees = [];
        this.allWorkDepartments = [];
        this.userId = '';
        this.workDepartmentId = '';
        this.state = '';
        this.moveType = '';
        this.service = '';
        this.startDay = '';
        this.endDay = '';
        this.checkBoxesDelivery = true;
        this.checkPackDays = true;
        this.checkPickUps = true;
        this.checkDelivery = true;
        this.detailDay = true;
        this.dayCalendar = [];
        this.remark = '';
        this.dispatched = false;
        this.date = 0;
        this.jobId = '';
        this.type = '';
        this.hiddenCalendar = false;
        this.workers = [];
        this.idsTrucks = [];
        this.typeModal = '';
        this.truncks = [];
        this.typeService = false;
        this.selectedDay = 0;
        this.checkWindowDays = false;
        this.calendarAT = false;
        this.crew = new DailyCrew();
        this.currentDate = new Date().getTime();
        this.rangeStart = '06:00';
        this.rangeEnd = '06:00';
        this.getDates();
    }

    /**
     * Metodo que obtiene las fechas actuales del mes en el calendario
     */
    getDates() {
        const date = new Date();
        // Obtiene el primer dia del mes
        const firsDay = new Date(date.getFullYear(), date.getMonth(), 1);
        
        // Obtiene el ultimo dia del mes
        const mounth = date.getMonth() + 1;
        let lastDay = new Date(date.getFullYear(), mounth, 0);
        const lastDayD  = lastDay.getDate() + 1;
        lastDay = new Date(lastDay.setDate(lastDayD));
        // Se agregan las fechas en formato numerico
        this.startDate = firsDay.getTime() + '';
        this.endDate = (lastDay.getTime() - 1) + '';
    }

    ngOnInit(): void {
       
    }

    ngAfterViewInit(): void {
        this.loadCarriers();
        this.checkedTrucks();
        this.currentRoute.params.subscribe(params => {
            if (params.type == 'auto-transport') {
                this.calendarAT = true;
            }
        });
        
        /*
        =========================================
        |                                       |
        |               Tooltips                |
        |                                       |
        =========================================
        */

        // jQuery('[data-toggle="tooltip"]').tooltip();

        const that = this;
        setTimeout(() => {
            jQuery('.fc-next-button, .fc-prev-button, .fc-today-button').click(() => {
                jQuery(this.divCalendar.nativeElement).fullCalendar('removeEvents');
                that.startDate = '' + moment(jQuery(this.divCalendar.nativeElement).fullCalendar('getView').start._d, moment.HTML5_FMT.DATE).unix() * 1000;
                that.endDate = '' + moment(jQuery(this.divCalendar.nativeElement).fullCalendar('getView').end._d, moment.HTML5_FMT.DATE).unix() * 1000;

                that.loadCalendar();
            });
        }, 2000);
        this.loadCalendar();

        jQuery(this.divCalendar.nativeElement).fullCalendar({
            header: {
                left: '',
                rigth: 'prev,next,today',
                center: 'title',
            },
            eventRender: (eventObj, $el) => {
                $el.find('span.fc-title').html(eventObj.description);
                $el.find('span.fc-time').remove();
                // $el.popover({
                //     title: eventObj.title,
                //     content: eventObj.description,
                //     trigger: 'hover',
                //     html: true,
                //     placement: 'top',
                //     container: 'body'
                // });
            },
            events: this.arrayCalendar,
            editable: false,
            eventLimit: false,
            eventClick: function(event) {
                if (event.url) {
                    window.open(event.url, "_blank");
                    return false;
                }
            },
            eventMouseover: (event, jsEvent, view) => {

                jQuery(this).attr('id', event.id);

                jQuery('#' + event.id).popover({
                    template: '<div class="popover popover-primary" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                    title: event.title,
                    content: event.description,
                    placement: 'top',
                });

                jQuery('#' + event.id).popover('show');
            },
            eventMouseout: (event, jsEvent, view) => {

                jQuery('#' + event.id).popover('hide');
            },
            dayClick: (date, allDay, jsEvent, view) => {
                // this.selectedDay = moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
                // that.startDay = '' + moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
                // that.endDay = '' + moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(59).minute(59).hour(23).unix() * 1000;
                // that.loadDataDay();
            }
        });

        this.load();
    }

    /**
     * Carga los datos al entrar en un dia del calendario
     */
    loadDataDay() {
        const typeEstimate = this.calendarAT ? 'AUTO_TRANSPORT' : 'MOVING';
        this.helperService.showLoadingMxpro360();
        const data = {
            'full-view': '1',
            start: this.startDay,
            end: this.endDay,
            type_estimate: typeEstimate
        };

        this.jobsService
            .getJobsToday(data)
            .then((response) => {

                // Asigna el titulo dependiendo si hay pickups y deliveries
                if (response['related_unassigned']['pickups'].length > 0) {
                    this.titleModalCarrier = 'Carrier';
                } else if (response['related_unassigned']['deliveries'].length > 0) {
                    this.titleModalCarrier = 'Agent';
                }

                this.dayCalendar = response;

                this.hiddenCalendar = true;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    createTable(calendarDay) {
        //  alert('Clicked on the entire day: ' + date);
        let texto = `<table id="table" class="table table-bordered" border=1>
        <thead>
            <tr>
                <th>Customer</th>
                <th>Service</th>
                <th>Volume</th>
                <th>Estimate</th>
                <th>Edit</th>
            </tr>
        </thead>
        <tbody>
           `;

        for (let cal of calendarDay['pickups']) {
            texto += ` <tr>
                <td>
                    <p class="user-name">` + cal.customer.last_name + `</p>
                    <p class="user-work">` + cal.customer.name + `</p>
                    <p class="user-work">` + cal.customer.phone + `</p>
                    <p class="user-work">` + cal.customer.email + `</p>
                    </td>
                <td>
                    <p class="usr-email-addr">
                        <strong>Service: </strong> ` + cal.service + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>Move Date: </strong>` + moment(cal.move_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>From: </strong>` + cal.pickup.address.state + ` ` + cal.pickup.address.zip_code + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>To: </strong>` + cal.delivery.address.state + ` ` + cal.delivery.address.zip_code + `
                    </p>
                </td>
                <td>
                    <p class="usr-location">
                        <strong>Libs: </strong> ` + cal.volume.libs + `
                    </p>
                    <p class="usr-location">
                        <strong>Cf: </strong>` + cal.volume.cubic_feets + `
                    </p>
                    <p class="usr-location">
                        <strong>Miles: </strong>` + cal.volume.miles + `
                    </p>
                </td>
                <td>
                  <p class="usr-ph-no">
                        <strong>Number: </strong> ` + cal.document.prefix + `` + String(cal.document.consecutive).padStart(5, '0') + `
                    </p>
                    <p class="usr-ph-no">
                        <strong>Estimate: </strong> ` + cal.total + `
                    </p>
                </td>
                <td>
                    <a href="/#/moving/` + cal.id + `/estimate" onclick="swal.close();">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    </a>
                </td>
            </tr>`;
        }

        for (let cal of calendarDay['packing_day']) {
            texto += ` <tr>
                    <td>
                        <p class="user-name">` + cal.customer.last_name + `</p>
                        <p class="user-work">` + cal.customer.name + `</p>
                        <p class="user-work">` + cal.customer.phone + `</p>
                        <p class="user-work">` + cal.customer.email + `</p>
                        </td>
                    <td>
                        <p class="usr-email-addr">
                            <strong>Service: </strong> ` + cal.service + `
                        </p>
                        <p class="usr-email-addr">
                            <strong>Move Date: </strong>` + moment(cal.move_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + `
                        </p>
                        <p class="usr-email-addr">
                            <strong>From: </strong>` + cal.pickup.address.state + ` ` + cal.pickup.address.zip_code + `
                        </p>
                        <p class="usr-email-addr">
                            <strong>To: </strong>` + cal.delivery.address.state + ` ` + cal.delivery.address.zip_code + `
                        </p>
                    </td>
                    <td>
                        <p class="usr-location">
                            <strong>Libs: </strong> ` + cal.volume.libs + `
                        </p>
                        <p class="usr-location">
                            <strong>Cf: </strong>` + cal.volume.cubic_feets + `
                        </p>
                        <p class="usr-location">
                            <strong>Miles: </strong>` + cal.volume.miles + `
                        </p>
                    </td>
                    <td>
                    <p class="usr-ph-no">
                            <strong>Number: </strong> ` + cal.document.prefix + `` + String(cal.document.consecutive).padStart(5, '0') + `
                        </p>
                        <p class="usr-ph-no">
                            <strong>Estimate: </strong> ` + cal.total + `
                        </p>
                    </td>
                    <td>
                        <a href="/#/moving/` + cal.id + `/estimate" onclick="swal.close();">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                        </a>
                    </td>
                </tr>`;
        }

        for (let cal of calendarDay['boxes_delivered']) {
            texto += ` <tr>
                    <td>
                        <p class="user-name">` + cal.customer.last_name + `</p>
                        <p class="user-work">` + cal.customer.name + `</p>
                        <p class="user-work">` + cal.customer.phone + `</p>
                        <p class="user-work">` + cal.customer.email + `</p>
                        </td>
                    <td>
                        <p class="usr-email-addr">
                            <strong>Service: </strong> ` + cal.service + `
                        </p>
                        <p class="usr-email-addr">
                            <strong>Move Date: </strong>` + moment(cal.move_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + `
                        </p>
                        <p class="usr-email-addr">
                            <strong>From: </strong>` + cal.pickup.address.state + ` ` + cal.pickup.address.zip_code + `
                        </p>
                        <p class="usr-email-addr">
                            <strong>To: </strong>` + cal.delivery.address.state + ` ` + cal.delivery.address.zip_code + `
                        </p>
                    </td>
                    <td>
                        <p class="usr-location">
                            <strong>Libs: </strong> ` + cal.volume.libs + `
                        </p>
                        <p class="usr-location">
                            <strong>Cf: </strong>` + cal.volume.cubic_feets + `
                        </p>
                        <p class="usr-location">
                            <strong>Miles: </strong>` + cal.volume.miles + `
                        </p>
                    </td>
                    <td>
                    <p class="usr-ph-no">
                            <strong>Number: </strong> ` + cal.document.prefix + `` + String(cal.document.consecutive).padStart(5, '0') + `
                        </p>
                        <p class="usr-ph-no">
                            <strong>Estimate: </strong> ` + cal.total + `
                        </p>
                    </td>
                    <td>
                        <a href="/#/moving/` + cal.id + `/estimate" onclick="swal.close();">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                        </a>
                    </td>
                </tr>`;
        }
        texto += `
            </tbody>
        </table>
        `;
        swal({
            html: texto
        });
        jQuery('.swal2-popup').css('width', 'auto');
    }


    private load() {

        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.allWorkDepartments = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });

        this.loadEmployees();
        this.loadTruncks();
    }

    loadCalendar() {
        const data: any = {
            'full-view': '1',
            start: this.startDate,
            end: this.endDate,
            type_estimate: this.calendarAT ? 'AUTO_TRANSPORT' : 'MOVING',
            user_id: '',
            work_department_id: '',
            status: '',
            move_type: '',
            statuses: '',
            service: '',
            carrier: ''
        };

        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getAllWithFullView(data)
            .then((response) => {

                this.calendar = response;
                this.initializeCalendar();
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    initializeCalendar() {
        this.arrayCalendar = [];

        // await this.filterConfirmed();
        jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
        if (!this.calendarAT) {
            if (this.checkPickUps && this.calendar['pickups']) {
                
                for (const cal of this.calendar['pickups']) {

                    let distanceToZipCode = '';
                    if (!cal.box_delivery_day.confirm && cal.estimate.service !== 'AUTO_TRANSPORT') {
                       
                        this.calendario.id = cal.id + 'Pick_Up';

                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';
                        let carrierSelect = '';
                        let timeFrame = '';

                        if (cal.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!cal.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (cal.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }

                        if (cal.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (cal.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                        }

                        if (cal.carrier) {
                            carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                             if (cal.job_outsourcing.job_acceptance_doc == null) {
                                carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                            }
                        }

                        if (cal.estimate.pickup.use_time_frame) {
                            timeFrame = '   <svg style="width: 16px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg> <span > Time: ' + cal.estimate.pickup.time_frame_start + ' - ' + cal.estimate.pickup.time_frame_end + '</span>  <br> ';
                        }

                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a> <br> '
                            + '<i class="fas fa-user"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + ', ' + cal.estimate.pickup.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state + ', ' + cal.estimate.delivery.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + carrierSelect
                            + timeFrame
                            + ' <br> '
                            + '<div class="color-icon-job">' + ' '
                            + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' ' + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                            + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                            + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' ' + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                            + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar '  + carrier + ' "></i>' + ' '
                            + '</div>';

                        this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                        
                            if (cal.pickup_day && cal.pickup_day.date !== null) {
                            this.calendario.start = moment(cal.pickup_day.date).format(moment.HTML5_FMT.DATE);
                            this.calendario.end = moment(cal.pickup_day.date).format(moment.HTML5_FMT.DATE);
                            } else {
                                this.calendario.start = moment(cal.estimate.pickup.range_start).format(moment.HTML5_FMT.DATE);
                                this.calendario.end = moment(cal.estimate.pickup.range_end).format(moment.HTML5_FMT.DATE);
                            }
                      

                        // this.calendario.url = '/#/company/'+cal.estimate.company_id+'/moving/'+cal.estimate.id+'/estimate';
                        this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-pickup' + ' ' + cal.estimate.id;
                        this.calendario.estimate_id = cal.estimate.id;
                        if(this.calendario.start){
                            this.arrayCalendar.push(this.calendario);
                        }

                        this.calendario = new ScheduleCalendar();

                    }
                }
            }
            if (this.checkPackDays && this.calendar['packing_day']) {
                for (const cal of this.calendar['packing_day']) {
                    let distanceToZipCode = '';
                    if (!cal.box_delivery_day.confirm && cal.estimate.service != 'AUTO_TRANSPORT') {
                       
                        this.calendario.id = cal.id + 'Pack_days';

                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';
                        let carrierSelect = '';

                        if (cal.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!cal.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (cal.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }

                        if (cal.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (cal.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                        }

                        if (cal.carrier) {
                            carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                            if (cal.job_outsourcing.job_acceptance_doc == null) {
                                carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                            }
                        }
                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a> <br> '
                            + '<i class="fas fa-user"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + ', ' + cal.estimate.pickup.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state + ', ' + cal.estimate.delivery.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + carrierSelect
                            + ' <br> '
                            + '<div class="color-icon-job">' + ' '
                            + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' ' + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                            + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                            + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' ' + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                            + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar '  + carrier + ' "></i>' + ' '
                            + '</div>';

                        this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                        if (cal.pack_day && cal.pack_day.date !== null) {
                            this.calendario.start = moment(cal.pack_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.pack_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        } else {
                            this.calendario.start = moment(cal.estimate.pickup.pack_day).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.estimate.pickup.pack_day).format(moment.HTML5_FMT.DATE);
                        }
                        
                        this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-packDay';
                        this.calendario.estimate_id = cal.estimate.id;
                        if(this.calendario.start){
                            this.arrayCalendar.push(this.calendario);
                        }
                        this.calendario = new ScheduleCalendar();
                    }
                }
            }

            if (this.checkBoxesDelivery && this.calendar['boxes_delivery_day']) {
                for (const cal of this.calendar['boxes_delivery_day']) {
                    let distanceToZipCode = '';
                    if (!cal.box_delivery_day.confirm && cal.estimate.service != 'AUTO_TRANSPORT') {
                        
                        this.calendario.id = cal.id + 'Boxes_Delivery';

                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';
                        let carrierSelect = '';

                        if (cal.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!cal.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (cal.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }

                        if (cal.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (cal.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                        }

                        if (cal.carrier) {
                            carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                            if (cal.job_outsourcing.job_acceptance_doc == null) {
                                carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                            }
                        }
                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a> <br> '
                            + '<i class="fas fa-user"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + ', ' + cal.estimate.pickup.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state + ', ' + cal.estimate.delivery.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + carrierSelect
                            + ' <br> '
                            + '<div class="color-icon-job">' + ' '
                            + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' ' + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                            + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                            + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' ' + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                            + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar '  + carrier + ' "></i>' + ' '
                            + '</div>';

                        this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                        if (cal.estimate.pickup && cal.estimate.pickup.boxes_delivery_day !== null) {
                            this.calendario.start = moment(cal.estimate.pickup.boxes_delivery_day)
                                .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.estimate.pickup.boxes_delivery_day)
                                .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        } else {
                            this.calendario.start = moment(cal.estimate.boxes_delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.estimate.boxes_delivery_day.date).format(moment.HTML5_FMT.DATE);
                        }
                        
                        this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-boxDelivery';
                        this.calendario.estimate_id = cal.estimate.id;
                        if(this.calendario.start){
                            this.arrayCalendar.push(this.calendario);
                        }
                        this.calendario = new ScheduleCalendar();
                    }
                }
            }

            if (this.checkDelivery && this.calendar['deliveries']) {
                for (const cal of this.calendar['deliveries']) {
                    let distanceToZipCode = '';
                    if (!cal.box_delivery_day.confirm && cal.estimate.service != 'AUTO_TRANSPORT') {
                      
                        this.calendario.id = cal.id + 'Delivery';

                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';
                        let carrierSelect = '';

                        if (cal.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!cal.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (cal.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }
                        if (cal.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (cal.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                        }

                        if (cal.carrier) {
                            carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                            if (cal.job_outsourcing.job_acceptance_doc == null) {
                                carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                            }
                        }
                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a> <br> '
                            + '<i class="fas fa-user"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + ', ' + cal.estimate.pickup.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state + ', ' + cal.estimate.delivery.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + carrierSelect
                            + ' <br> '
                            + '<div class="color-icon-job">' + ' '
                            + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' ' + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                            + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                            + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' ' + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                            + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar '  + carrier + ' "></i>' + ' '
                            + '</div>';

                        this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                        if (cal.delivery_day && cal.delivery_day.date !== null ) {
                            this.calendario.start = moment(cal.delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        } else {
                            this.calendario.start = moment(cal.estimate.delivery.range_start).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.estimate.delivery.range_end).format(moment.HTML5_FMT.DATE);
                        }
                        
                        this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-delivery';
                        this.calendario.estimate_id = cal.estimate.id;
                        if(this.calendario.start){
                            this.arrayCalendar.push(this.calendario);
                        }
                        this.calendario = new ScheduleCalendar();
                    }
                }
            }
            if (this.calendar['crews']) {
                for (const crew of this.calendar['crews']){
                   let childJob = '';
                   let childJobs = '';
                   
                   
                    this.calendario.id = crew.id + 'crew';
                    for (const crewJobs of crew.jobs){
                        let backgroundColor = '';
                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';
                        let carrierSelect = '';
                        let distanceToZipCode = '';
                       
                        if (crewJobs.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!crewJobs.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (crewJobs.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }
                        if (crewJobs.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (crewJobs.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + crewJobs.distance_to_zip + ' <br> ';
                        }

                        if (crewJobs.carrier) {
                            carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + crewJobs.carrier.name + ' <br> ';
                            if (crewJobs.job_outsourcing.job_acceptance_doc == null) {
                                carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + crewJobs.carrier.name + '</span>  <br> ';
                            }
                        }
                        
                        if (crewJobs.crew_work_type == 'pickups') {
                            backgroundColor = 'calendar-color-pickup';
                        } else if (crewJobs.crew_work_type == 'deliveries') {
                            backgroundColor = 'calendar-color-delivery';
                        } else if (crewJobs.crew_work_type == 'boxes_delivery_day') {
                            backgroundColor = 'calendar-color-boxDelivery';
                        } else if (crewJobs.crew_work_type == 'packing_day') {
                            backgroundColor = 'calendar-color-packDay';
                        }
                        
                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + crewJobs.estimate.company_id + '/moving/' + crewJobs.estimate.id + '/estimate';
                        childJob =  '<div class="'+backgroundColor+'" style="border-radius: 5px; padding: 2px; margin-top: 3px; color: black;">'
                        + '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + crewJobs.estimate.document.code + '<a> <br> '
                        + '<i class="fas fa-user"></i> ' + crewJobs.estimate.customer.name + ' ' + crewJobs.estimate.customer.last_name + ' <br> '
                        + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + crewJobs.estimate.pickup.address.state + '" pickup-zip="' + crewJobs.estimate.pickup.address.zip_code + '" delivery-state="' + crewJobs.estimate.delivery.address.state + '" delivery-zip="' + crewJobs.estimate.delivery.address.zip_code + '">' + crewJobs.estimate.pickup.address.state + ', ' + crewJobs.estimate.pickup.address.zip_code + '</a><br> '
                        + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + crewJobs.estimate.pickup.address.state + '" pickup-zip="' + crewJobs.estimate.pickup.address.zip_code + '" delivery-state="' + crewJobs.estimate.delivery.address.state + '" delivery-zip="' + crewJobs.estimate.delivery.address.zip_code + '">' + crewJobs.estimate.delivery.address.state + ', ' + crewJobs.estimate.delivery.address.zip_code + '</a><br> '
                        + '<i class="fab fa-buffer"></i> ' + crewJobs.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(crewJobs.estimate.volume.miles) + ' miles ' + ' <br> '
                      
                        + ' <br> '
                        + '<div class="color-icon-job">' + ' '
                        + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' ' + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                        + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                        + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' ' + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                        + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar '  + carrier + ' "></i>' + ' '
                        + '</div>'
                        + '</div>';

                        childJobs += childJob

                    }


                    
                    this.calendario.description =  '<div class="color-icon-job">'
                        + '<div style="text-align: center; color: #ffffff; font-size: 13px"> ' + crew.name + '</div> '  
                        + childJobs
                        + '</div>';

                  
                        this.calendario.start = moment(crew.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        this.calendario.end = moment(crew.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                  
                    this.calendario.className = 'calendar-color-crew';
                    this.calendario.estimate_id = crew.id;
                    if(this.calendario.start){
                        this.arrayCalendar.push(this.calendario);
                    }
                    this.calendario = new ScheduleCalendar();
                }
            }
        } else {
            if (this.checkPickUps && this.calendar['pickups']) {
                for (const cal of this.calendar['pickups']) {
                    let distanceToZipCode = '';
                    if (!cal.box_delivery_day.confirm && cal.estimate.service == 'AUTO_TRANSPORT') {
                     
                        this.calendario.id = cal.id + 'Pick_Up';

                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';

                        if (cal.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!cal.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (cal.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }

                        if (cal.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (cal.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                        }

                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a> <br> '
                            + '<i class="fas fa-user"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + ', ' + cal.estimate.pickup.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state + ', ' + cal.estimate.delivery.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + ' <br> '
                            + '<div class="color-icon-job">' + ' '
                            + '<i class="fas fa-calendar-check ' + confirmed + '"></i>' + ' ' + '<i class="fas fa-file-signature ' + signature + '"></i>' + ' '
                            + '<i class="fas fa-credit-card"></i>' + ' '
                            + '<i class="fas fa-plane ' + interstate + '"></i>' + ' '
                            + '<i class="fas fa-truck ' + carrier + '"></i>' + ' '
                            + '</div>';

                        this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                        if (cal.pickup_day && cal.pickup_day.date !== null) {
                            this.calendario.start = moment(cal.pickup_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.pickup_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        } else {
                            this.calendario.start = moment(cal.estimate.pickup.range_start).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.estimate.pickup.range_end).format(moment.HTML5_FMT.DATE);
                        }
                        this.calendario.className = 'calendar-color-pickup' + ' ' + cal.estimate.id;
                        this.calendario.estimate_id = cal.estimate.id;
                        this.arrayCalendar.push(this.calendario);
                        this.calendario = new ScheduleCalendar();

                    }
                }
            }

            if (this.checkDelivery && this.calendar['deliveries']) {
                for (const cal of this.calendar['deliveries']) {
                    let distanceToZipCode = '';
                    if (!cal.box_delivery_day.confirm && cal.estimate.service == 'AUTO_TRANSPORT') {
                        
                        this.calendario.id = cal.id + 'Delivery';

                        let confirmed = '';
                        let signature = '';
                        let interstate = '';
                        let carrier = '';

                        if (cal.pickup_day.confirm) {
                            confirmed = 'calendar-icon-active';
                        }

                        if (!cal.estimate.esignature) {
                            signature = 'calendar-icon-active';
                        }

                        if (cal.estimate.service == 'LONG') {
                            interstate = 'calendar-icon-active';
                        }

                        if (cal.carrier) {
                            carrier = 'calendar-icon-active';
                        }

                        if (cal.distance_to_zip) {
                            distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                        }

                        const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a> <br> '
                            + '<i class="fas fa-user"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + ', ' + cal.estimate.pickup.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state + ', ' + cal.estimate.delivery.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + ' <br> '
                            + '<div class="color-icon-job">' + ' '
                            + '<i class="fas fa-calendar-check ' + confirmed + '"></i>' + ' ' + '<i class="fas fa-file-signature ' + signature + '"></i>' + ' '
                            + '<i class="fas fa-credit-card"></i>' + ' '
                            + '<i class="fas fa-plane ' + interstate + '"></i>' + ' '
                            + '<i class="fas fa-truck ' + carrier + '"></i>' + ' '
                            + '</div>';

                        this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                        if (cal.delivery_day && cal.delivery_day.date !== null) {
                            this.calendario.start = moment(cal.delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        } else {
                            this.calendario.start = moment(cal.estimate.delivery.range_start).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                            this.calendario.end = moment(cal.estimate.delivery.range_end).format(moment.HTML5_FMT.DATE);
                        }
                        this.calendario.className = 'calendar-color-delivery';
                        this.calendario.estimate_id = cal.estimate.id;
                        this.arrayCalendar.push(this.calendario);
                        this.calendario = new ScheduleCalendar();
                    }
                }
            }
        }

        /* if (!jQuery('.icon-calendar')[0]) {
            jQuery('.fc-day-top').append(
                '<button class="button-highlight"> <svg xmlns="http://www.w3.org/2000/svg" width="24" class="icon-calendar" style="margin-right: 6px; color:#c15e17;" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-crosshair"><circle cx="12" cy="12" r="10"></circle><line x1="22" y1="12" x2="18" y2="12"></line><line x1="6" y1="12" x2="2" y2="12"></line><line x1="12" y1="6" x2="12" y2="2"></line><line x1="12" y1="22" x2="12" y2="18"></line></svg></button>');
        } */

        jQuery(this.divCalendar.nativeElement).fullCalendar('addEventSource', this.arrayCalendar);
        this.helperService.hideLoadingMxpro360();
    }


    getFilter() {

        const data: any = {
            'full-view': '1',
            start: this.startDate,
            end: this.endDate,
        };

        if (this.userId) {
            data.user_id = this.userId;
        }
        if (this.workDepartmentId) {
            data.work_department_id = this.workDepartmentId;
        }
        if (this.state) {
            data.status = this.state;
        }
        if (this.moveType) {
            data.move_type = this.moveType;
        }
        if (this.service) {
            data.service = this.service;
        }

        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getAllWithFullView(data)
            .then((response) => {
                this.calendar = response;
                this.hiddenCalendar = false;
                this.initializeCalendar();
            })
            .catch((error) => {
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    changeDate(event) {
        this.date = event;
    }

    setJob(id, date, dispatched, remark, type, confirm, typeModal) {

        jQuery(this.modalExtra.nativeElement).modal('show');
        this.typeModal = typeModal;
        this.type = type;
        this.date = date;
        this.dispatched = dispatched;
        this.remark = remark;
        this.jobId = id;
        this.confirm = confirm;

        this.getChecked(id);
        this.loadJob(id);
        // this.loadCheckedTrucks();
    }

    /**
     * Carga el job para asignar el agent o carrier
     */
    private async loadJob(id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getById(id)
            .then((response) => {
                this.job = response;
                this.typeService = false;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    update() {
        let moving: any;

        if (this.type === 'pickups') {
            // Se crea el objeto pickup
            const pickup = new JobAppoinment();
            pickup.date = this.date;
            pickup.dispatched = this.dispatched;
            pickup.remarks = this.remark;
            pickup.confirm = this.confirm;
            pickup.workers_id = this.workers;
            pickup.range_start = this.rangeStart;
            pickup.range_end = this.rangeEnd;
            moving = {
                pickup_day: pickup
            };
        }

        if (this.type === 'deliveries') {
            const delivery = new JobAppoinment();
            delivery.date = this.date;
            delivery.dispatched = this.dispatched;
            delivery.remarks = this.remark;
            delivery.confirm = this.confirm;
            delivery.workers_id = this.workers;
            delivery.range_start = this.rangeStart;
            delivery.range_end = this.rangeEnd;
            moving = {
                delivery_day: delivery
            };
        }

        if (this.type === 'boxes_delivery_day') {
            const box_delivery_day = new JobAppoinment();
            box_delivery_day.date = this.date;
            box_delivery_day.dispatched = this.dispatched;
            box_delivery_day.remarks = this.remark;
            box_delivery_day.confirm = this.confirm;
            box_delivery_day.workers_id = this.workers;
            box_delivery_day.range_start = this.rangeStart;
            box_delivery_day.range_end = this.rangeEnd;
            moving = {
                box_delivery_day
            };
        }

        if (this.type === 'pack_day') {
            const pack_day = new JobAppoinment();
            pack_day.date = this.date;
            pack_day.dispatched = this.dispatched;
            pack_day.remarks = this.remark;
            pack_day.confirm = this.confirm;
            pack_day.workers_id = this.workers;
            pack_day.range_start = this.rangeStart;
            pack_day.range_end = this.rangeEnd;
            moving = {
                pack_day
            };
        }

        this.helperService.showLoadingMxpro360();

        this.jobsService
            .patchEntity(this.jobId, moving)
            .then((response) => {

                this.jobId = '';
                this.date = 0;
                this.dispatched = false;
                this.remark = '';
                this.type = '';
                this.loadCalendar();
                this.loadDataDay();
                jQuery(this.modalExtra.nativeElement).modal('hide');

            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    backCalendar() {
        this.hiddenCalendar = false;
        this.loadCalendar();
    }

    /**
     * Carga la informacion de los empleados
     */
    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employeesView = response;
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Carga la informacion de los trunck
     */
    private loadTruncks() {
        this.helperService.showLoadingMxpro360();

        this.vehiclesService
            .getAll()
            .then((response) => {
                // this.employeesView = response;
                this.truncks = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Agrega los ids de los empleados
     */
    getEmployeesTeam(employeeId: string) {
        this.workers.push(employeeId);
    }

    public getTrucksTeam(truckId: string) {
        this.typeService = !this.typeService;
        if (this.typeService) {
            this.idsTrucks.push(truckId);
            // this.checkedTrucks();
        } else {
            for (let index = 0; index < this.idsTrucks.length; index++) {
                if (this.idsTrucks[index] === truckId) {
                    this.idsTrucks.splice(index, 1);
                    break;
                }
            }
        }
    }

    public checkedTrucks(): void {
        for (let index = 0; index < this.idsTrucks.length; index++) {
            for (let i = 0; i < this.truncks.length; i++) {
                if (this.truncks[i].id === this.idsTrucks[index]) {
                    this.typeService = true;
                } else {
                    this.typeService = false;
                }
            }
        }
    }

    /**
     * Checkea o des checkea un item del listado de empleados
     * @param employeeId Id del empleado
     */
    getChecked(employeeId: string) {
        this.jobsService.getById(employeeId).then((response) => {
            for (const employee of this.employeesView) {
                employee.checked = response.pickup_day.workers_id.indexOf(employee.id) >= 0 ||
                    response.delivery_day.workers_id.indexOf(employee.id) >= 0 ||
                    response.pack_day.workers_id.indexOf(employee.id) >= 0 ||
                    response.box_delivery_day.workers_id.indexOf(employee.id) >= 0;
                if (employee.checked) {
                    if (this.type === 'boxes_delivery_day') {
                        for (let index = 0; index < response.box_delivery_day.workers_id.length; index++) {
                            this.workers.push(response.box_delivery_day.workers_id[index]);
                        }

                    } else if (this.type === 'pickups') {
                        for (let index = 0; index < response.pickup_day.workers_id.length; index++) {
                            this.workers.push(response.pickup_day.workers_id[index]);
                        }
                    } else if (this.type === 'deliveries') {
                        for (let index = 0; index < response.delivery_day.workers_id.length; index++) {
                            this.workers.push(response.delivery_day.workers_id[index]);
                        }
                    } else if (this.type === 'pack_day') {
                        for (let index = 0; index < response.pack_day.workers_id.length; index++) {
                            this.workers.push(response.pack_day.workers_id[index]);
                        }
                    }
                }
            }
        });
    }

    /**
     * Abre el modal de carriers o agentes
     *
     * @param title Titulo del modal
     */
    public openModalCarriers(title: string, idJob: string): void {
        this.loadJob(idJob);
        this.titleModalCarrier = title;
        jQuery(this.modalCarriers.nativeElement).modal('show');
    }

    /**
     * Carga los carriers
     */
    private loadCarriers() {
        this.helperService.showLoadingMxpro360();
        this.carrierService
            .getAll()
            .then((response) => {
                this.carriers = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Cierra el modal de carriers
     */
    public closeModalCarriers(): void {
        jQuery(this.modalCarriers.nativeElement).modal('hide');
    }

    /**
     * Dependiendo si es carrier o agente, se procede aguardar
     */
    public saveCarrierAndAgent(): void {
        if (this.titleModalCarrier === 'Agent') {
            this.saveAgent();
        } else if (this.titleModalCarrier === 'Carrier') {
            this.saveCarrier();
        }
    }

    /**
     * Realiza el guardado del carrier
     */
    private saveCarrier(): void {
        // this.helperService.showLoadingMxpro360();
        // this.jobsService
        //     .patchEntity(this.job.id, { carrier: this.job.carrier })
        //     .then((response) => {
        //         jQuery(this.modalCarriers.nativeElement).modal('hide');
        //         this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        //     })
        //     .catch((error) => {
        //         this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        //         console.error('error', error);
        //     })
        //     .finally(() => {
        //         //this.helperService.hideLoadingMxpro360();
        //     });
    }

    /**
     * Realiza el guardado del agente
     */
    private async saveAgent(): Promise<void> {

        // this.helperService.showLoadingMxpro360();

        // // Se verifica que exista un carrier antes de guardar el agente
        // await this.validateCarrier();

        // this.jobsService
        //     .patchEntity(this.job.id, { agent: this.job.agent })
        //     .then((response) => {
        //         jQuery(this.modalCarriers.nativeElement).modal('hide');
        //         this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        //     })
        //     .catch((error) => {
        //         this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        //         console.error('error', error);
        //     })
        //     .finally(() => {
        //         //this.helperService.hideLoadingMxpro360();
        //     });
    }

    /**
     * Valida que exista el carrier un carrier y lo guarda en caso de no existir
     */
    private validateCarrier(): Promise<any> {
        return new Promise((resolve) => {
        //     if (this.job.carrier.name === null &&
        //         this.job.carrier.dot === null && this.job.carrier.address.zip_code === null &&
        //         this.job.carrier.address.country === null) {

        //         this.job.carrier.name = this.authService.workspaceSession.company.name;
        //         this.job.carrier.address = this.authService.workspaceSession.company.address;
        //         this.job.carrier.dot = this.authService.workspaceSession.company.dot;
        //         this.job.carrier.website = this.authService.workspaceSession.company.website;

        //         resolve(this.saveCarrier());
        //     } else {
        //         
        //     }
       resolve(true); 
    });

    }

    /**
     * Asigna el agente o el carrier en el job
     *
     * @param carrier es el carrier o agente
     */
    public selectCarrier(carrier: Carrier): void {
        // if (this.titleModalCarrier === 'Agent') {
        //     this.job.agent = carrier;
        // } else if (this.titleModalCarrier === 'Carrier') {
        //     this.job.carrier = carrier;
        // }
    }



    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimateId) {
        // Redirige a la ventana de estmate
        this.helperService.openNewTab(estimateId, 'estimate');
    }

    dailyCrewAssign(id, crew){
        crew.jobs_id.push(id);
        this.dailyCrewService
            .save(crew)
            .then((response) => {
                this.loadCalendar();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }
    
    dailyCreweEdit(){
        this.dailyCrewService
            .save(this.crew)
            .then((response) => {
                this.loadCalendar();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    dailyCrewDelete(id){
        
        this.dailyCrewService
            .remove(id)
            .then((response) => {
                this.loadCalendar();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                //this.helperService.hideLoadingMxpro360();
            });
    }

    nextDate(){
        this.selectedDay = moment(this.selectedDay).add(1, 'd').unix() * 1000;
        this.startDay = '' + moment(Number(this.startDay)).add(1, 'd').unix() * 1000;
        this.endDay = '' + moment(Number(this.endDay)).add(1, 'd').unix() * 1000;
        this.loadDataDay();
    }
    backDate(){
        this.selectedDay = moment(this.selectedDay).subtract(1, 'd').unix() * 1000;
        this.startDay = '' + moment(Number(this.startDay)).subtract(1, 'd').unix() * 1000;
        this.endDay = '' + moment(Number(this.endDay)).subtract(1, 'd').unix() * 1000;
        this.loadDataDay();
    }
}
