<div class="text-center mt-4 mb-5" >
    <h5>{{dateToday | dateTime}}</h5>
    <h2 class="welcome-text" *ngIf="employee != null">
        <strong>Welcome, {{employee.name | titlecase}}</strong>
    </h2>
    <h4 class="text-center" *ngIf="employee != null">
        {{jobPositionName | titlecase}}
    </h4>
</div>


<div class="row">

    <ng-container  *ngIf="jobPosition == 'SALESMAN'">

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-bookings-last7-days [companyId]="true"> </app-bookings-last7-days>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-new-estimates-last7-days [companyId]="true"> </app-new-estimates-last7-days>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-emails-sent-last7-days [companyId]="true"> </app-emails-sent-last7-days>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="true"> </app-log-viewed-estimate>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-closing-ratio-per-lead [companyId]="true"> </app-closing-ratio-per-lead>
            <app-closing-ratio-per-estimate [companyId]="true"> </app-closing-ratio-per-estimate>
            <app-estimate-ratio-per-lead [companyId]="true"> </app-estimate-ratio-per-lead>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-deposits [companyId]="true"> </app-last5-deposits>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last10-recurrings [companyId]="true"> </app-last10-recurrings>
        </div>

    </ng-container>

    <ng-container  *ngIf="jobPosition == 'SALES_MANAGER'">

        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-incoming-leads [companyId]="true"> </app-total-incoming-leads>
        </div>
        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-bad-leads [companyId]="true"> </app-total-bad-leads>
        </div>


        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-today [companyId]="true"> </app-jobs-picked-today>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-tomorrow [companyId]="true"> </app-jobs-picked-tomorrow>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked2-days [companyId]="true"> </app-jobs-picked2-days>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-own-performance [companyId]="true"> </app-own-performance>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-payment-methods-used [companyId]="true"> </app-payment-methods-used>
        </div>
    
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customersby-teams [companyId]="true"> </app-recurring-customersby-teams>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="true"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-sales-leader-manager [companyId]="true"> </app-sales-leader-manager>
        </div>
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-in-window [companyId]="true"> </app-jobs-picked-in-window>
        </div>
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-booked-last7-days [companyId]="true"> </app-jobs-booked-last7-days>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-completed [companyId]="true"> </app-jobs-completed>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-salesman-performance-team [companyId]="true"> </app-salesman-performance-team>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-estimates-by-salesman [companyId]="true"> </app-total-estimates-by-salesman>
        </div>

    </ng-container>

    <ng-container  *ngIf="jobPosition == 'OPERATOR'" >

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="true"> </app-last5-payments>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        </div>

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-today [companyId]="true"> </app-jobs-picked-today>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-tomorrow [companyId]="true"> </app-jobs-picked-tomorrow>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked2-days [companyId]="true"> </app-jobs-picked2-days>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="true"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="true"> </app-jobs-by-operation-status>
        </div>        
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="true"> </app-recurring-customer>
        </div>       
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-in-window [companyId]="true"> </app-jobs-picked-in-window>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-tickets [companyId]="true"> </app-last5-tickets>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-assigned [companyId]="true"> </app-tickets-assigned>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="true"> </app-tickets-by-priority>
        </div>
        
    </ng-container>
    
    <ng-container  *ngIf="jobPosition == 'CUSTOMER_SERVICE'" >
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="true"> </app-last5-payments>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="true"> </app-jobs-by-operation-status>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="true"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="true"> </app-recurring-customer>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-assigned [companyId]="true"> </app-tickets-assigned>
        </div>
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="true"> </app-tickets-by-priority>
        </div>
    </ng-container>
    
    <ng-container  *ngIf="jobPosition == 'MANAGER'" >
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="true"> </app-last5-payments>
        </div>
      
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-sales-leader [companyId]="true"> </app-sales-leader>
        </div>
        

        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-today [companyId]="true"> </app-jobs-picked-today>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-tomorrow [companyId]="true"> </app-jobs-picked-tomorrow>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked2-days [companyId]="true"> </app-jobs-picked2-days>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-in-window [companyId]="true"> </app-jobs-picked-in-window>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="true"> </app-jobs-by-operation-status>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="true"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="true"> </app-recurring-customer>
        </div>
    
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-tickets-global [companyId]="true"> </app-last5-tickets-global>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="true"> </app-tickets-by-priority>
        </div>
    </ng-container>
      
    <ng-container   *ngIf="jobPosition == 'ADMIN'" >
    <!-- <ng-container> -->
        
        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-incoming-leads [companyId]="true"> </app-total-incoming-leads>
        </div>
        <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-total-bad-leads [companyId]="true"> </app-total-bad-leads>
        </div>
        
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-today [companyId]="true"> </app-jobs-picked-today>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-tomorrow [companyId]="true"> </app-jobs-picked-tomorrow>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked2-days [companyId]="true"> </app-jobs-picked2-days>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-payments [companyId]="true"> </app-last5-payments>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-payment-methods-used [companyId]="true"> </app-payment-methods-used>
        </div>


        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-by-operation-status [companyId]="true"> </app-jobs-by-operation-status>
        </div>       
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-closing-ratio-per-lead [companyId]="true"> </app-closing-ratio-per-lead>
            <app-closing-ratio-per-estimate [companyId]="true"> </app-closing-ratio-per-estimate>
            <app-estimate-ratio-per-lead [companyId]="true"> </app-estimate-ratio-per-lead>
        </div> 



        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-log-viewed-estimate [companyId]="true"> </app-log-viewed-estimate>
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-tickets-by-priority [companyId]="true"> </app-tickets-by-priority>
        </div>
       
   
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-jobs-picked-in-window [companyId]="true"> </app-jobs-picked-in-window>
        </div>
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-sales-leader [companyId]="true"> </app-sales-leader>
        </div>
        
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-groupal-log-viewed-estimate [companyId]="true"> </app-groupal-log-viewed-estimate>
        </div>

        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-recurring-customer [companyId]="true"> </app-recurring-customer>
        </div>  


        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-monthly-closing-ratio-change  [companyId]="true"> </app-monthly-closing-ratio-change>
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-lead-providers-timelines [companyId]="true"> </app-lead-providers-timelines>
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <app-last5-tickets-global [companyId]="true"> </app-last5-tickets-global>
        </div>
        
        
    </ng-container>

</div>