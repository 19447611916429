import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { PhoneCallsService } from 'src/app/services/companies/phone-calls.service';
import { PhoneCall } from 'src/app/entities/companies/phone-call';
import { CallService } from 'src/app/services/workspaces/calls.service';
import { HttpClient } from '@angular/common/http';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-oldphone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class OldPhoneComponent implements OnInit {

  readonly environment = environment;
  public rows: Array<PhoneCall>
  public dataForm: PhoneCall | any;
  public paginator: Paginator;
  public filterFor: 'All' | 'Outgoing' | 'Incoming' | 'Missed';
  public filterSearch: string;
  public outboundCalls: number;
  public andsweredCalls: number;
  public missedCalls: number;
  public phoneCall;

  @Input() phone: string;


  constructor(
    private helperService: HelperService,
    private phoneCallService: PhoneCallsService,
    public authServices: AuthService,

  ) {
    this.paginator = new Paginator();
    this.filterFor = 'All';
    this.filterSearch = '';
    this.andsweredCalls;
    this.missedCalls;
    this.phoneCall;
    this.paginator.paginator.page = 1;

  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.load();
    this.loadCallsAnsweredTotal();
    this.loadCallsMissedTotal();
    this.loadOutboundsAllTotal();
  }

  filterCalls(filterFor: 'All' | 'Outgoing' | 'Incoming' | 'Missed') {
    this.filterFor = filterFor;
    this.paginator.paginator.page = 1;
    this.load();
  }
  private load() {

    console.log('this.paginator aqui aqui aqui :!',this.paginator);

    this.helperService.showLoadingMxpro360();
    this.phoneCallService
      .getAll(this.filterFor, this.phone, this.paginator)
      .then((response) => {
        this.paginator.data = response.data;
        this.paginator.paginator.total = response.paginator.total;

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  };

  private loadOutboundsTotal() {
    this.helperService.showLoadingMxpro360();
    this.phoneCallService
      .getAll(this.filterFor , this.phone,  this.paginator)
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  };

  private loadOutboundsAllTotal() {

    this.helperService.showLoadingMxpro360();

    this.phoneCallService
      .getAllTotalOutboundToday(this.phone)
      .then((response) => {

      console.log('llamadas salientes qsadas',response);
      this.outboundCalls = response.length;

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  };

  public numberCall(numberCall){

    this.phoneCall = numberCall;
    this.helperService.phoneNumber.emit(this.phoneCall);

  }


  private loadCallsAnsweredTotal() {
    this.helperService.showLoadingMxpro360();
    this.phoneCallService
      .getCallsAnsweredTotal(this.phone)
      .then((response) => {

      this.andsweredCalls = response.length;

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  };


  private loadCallsMissedTotal() {
    this.helperService.showLoadingMxpro360();
    this.phoneCallService
      .getCallsMissed(this.phone)
      .then((response) => {

        console.log('missed', response);

      this.missedCalls = response.length;

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  };



  /* Animación Contador de Cantidad de Llamadas */
  phoneCounterWidget() {
    document.querySelectorAll('.phone-count').forEach((element: Element) => {

      let counter = 0;
      const target = +element.textContent;
      const startTime = performance.now();

      // Función de actualización del contador
      const updateCounter = () => {

        const currentTime = performance.now();
        const elapsedTime = currentTime - startTime;

        // Verifica si aún no ha pasado el tiempo total de la animación (1500 ms)
        if (elapsedTime < 1500) {
          const progress = Math.min(1, elapsedTime / 1500);
          counter = target * progress;
          element.textContent = `${Math.ceil(counter)}`;
          requestAnimationFrame(updateCounter);
        } else {
          element.textContent = `${target}`;
        }
      };

      updateCounter();
    });
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page !== currentpage) {
      this.paginator.paginator.page = currentpage;
      this.load();
    }
  }

  filterVoiceMail() {

    this.helperService.showLoadingMxpro360();
    this.phoneCallService
      .getAllVoiceMail(this.paginator, this.filterFor)
      .then((response) => {
        this.paginator.data = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  }

}
