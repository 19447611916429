import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';
declare var ApexCharts;
@Component({
    selector: 'app-jobs-by-operation-status',
    templateUrl: './jobs-by-operation-status.component.html',
    styleUrls: ['./jobs-by-operation-status.component.scss']
})
export class JobsByOperationStatusComponent implements OnInit {

    jobFadd: any;
    @Input()
    companyId: boolean = false;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.jobFadd = {
            BLUE: 30,
            GREEN: 30,
            YELLOW: 30,
            ORANGE: 30,
            RED: 30,
        };
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.loadFaddCompany();
        } else {
            this.loadFaddWorkspace();
        }
    }


    loadFaddWorkspace() {
        this.dashboardWorkspaceService
            .getHowManyJobsAndWhatFaddStatusAreTheyIn()
            .then((response) => {
                this.jobFadd = response;
                setTimeout(() => {
                    this.charFadd();
                }, 300);

            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    loadFaddCompany() {
        this.dashboardComopanyService
            .getHowManyJobsAndWhatFaddStatusAreTheyIn()
            .then((response) => {
                this.jobFadd = response;
                setTimeout(() => {
                    this.charFadd();
                }, 300);

            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


    charFadd() {
      
    var colors = [
        '#008FFB',
        '#00E396',
        '#ffff0a',
        '#f7a207',
        '#FF4560'
      ]

      var data = [this.jobFadd.BLUE, this.jobFadd.GREEN, this.jobFadd.YELLOW, this.jobFadd.ORANGE, this.jobFadd.RED];
        var options = {
            series: [{
                name: 'Fadd',
            data: data
          }],
            chart: {
            height: 350,
            type: 'bar',
            events: {
              click: function(chart, w, e) {
                  
              }
            }
          },
          colors: colors,
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: [
              ['Blue'],
              ['Green'],
              ['Yellow'],
              ['Orange'],
              ['Red'],
            ],
            labels: {
              style: {
                colors: colors,
                fontSize: '12px'
              }
            }
          }
          };
  
          var chart = new ApexCharts(document.querySelector("#fadd"), options);
          chart.render();
        
    }


}
