import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ZoneState } from 'src/app/entities/workspace/zone-state';



@Injectable({
    providedIn: 'root'
})
export class ZoneStatesService {

    constructor(private http: HttpClient) { 

    }

    getAllWithFullView(): Promise<Array<ZoneState>> {
        return this.http.get<Array<ZoneState>>(environment.api.workspace + '/zone-states', { params: { "full-view": "1" } }).toPromise();
    }

    getAllWithFullViewByTariffVersionId(tariffVersionId: string): Promise<Array<ZoneState>> {
        return this.http.get<Array<ZoneState>>(environment.api.workspace + '/zone-states/tariff-version-id/' + tariffVersionId, { params: { "full-view": "1"} }).toPromise();
    }

    getAll(): Promise<Array<ZoneState>> {
        return this.http.get<Array<ZoneState>>(environment.api.workspace + '/zone-states').toPromise();
    }

    getById(id: string) {
        return this.http.get<ZoneState>(environment.api.workspace + '/zone-states/' + id).toPromise();
    }

    save(dataForm: ZoneState): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: ZoneState) {
        return this.http.post(environment.api.workspace + '/zone-states', dataForm).toPromise();
    }

    edit(dataForm: ZoneState) {
        return this.http.put(environment.api.workspace + '/zone-states/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/zone-states/' + id).toPromise();
    }
}
