export class BulkyFee {
    id: string;
    name: string;
    cost: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.cost = null;
    }
}
