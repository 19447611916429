import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierDriver } from 'src/app/entities/workspace/carrier-driver';
import { DispatchContacts } from 'src/app/entities/workspace/dispatch-contacts';
import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { Warehouse } from 'src/app/entities/workspace/warehouse';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { CarriersDriversService } from 'src/app/services/workspaces/carriers-drivers.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { DispatchContactsService } from 'src/app/services/workspaces/dispatch-contacts.service';
import { DriverInvoicesService } from 'src/app/services/workspaces/driver-invoices.service';
import { StorageCompaniesService } from 'src/app/services/workspaces/storage-companies.service';
import { WarehousesService } from 'src/app/services/workspaces/warehouses.service';

declare const jQuery: any;
declare const swal: any;

@Component({
  selector: 'app-driver-invoices-view',
  templateUrl: './driver-invoices-view.component.html',
  styleUrls: ['./driver-invoices-view.component.scss']
})
export class DriverInvoicesViewComponent implements OnInit {

    @ViewChild('paymentModal') paymentModal: ElementRef;
    
    public email: string;
    driverInvoice: DriverInvoice;
    carriers: Array<Carrier>;
    carriersDrivers: Array<CarrierDriver>;
    carrier: Carrier;
    searchJob: string;
    padsJob: number;
    companyName: string;
    public rowWarehouse: Array<Warehouse>;
    public rowStorageCompanies: Array<StorageCompany>;
    public employees: any[];
    public delivered: boolean;
    public dispatchers: DispatchContacts[];

    cfJob: number;
    rateJob: number;
    eta_start: number;
    eta_end: number;
    deliveryStatus: 'PENDING';
    comments: string;

    constructor(
        private carrierService: CarriersService,     
        private dispatchContactsService: DispatchContactsService,
        private carriersDriversService: CarriersDriversService,
        private currentRoute: ActivatedRoute,
        private driverInvoicesService: DriverInvoicesService,
        private companiesService: CompaniesService,
        public tariffVersionFactory: TariffVersionService,
        private warehousesService: WarehousesService,
        private storageCompaniesService: StorageCompaniesService,
        public helperService: HelperService
    ) {
        this.driverInvoice = new DriverInvoice();
        this.carriers = [];
        this.carrier = new Carrier();
        this.searchJob = '';
        this.padsJob = null;
        this.email = '';
        this.companyName = '';
        this.rowWarehouse = [];
        this.rowStorageCompanies = [];
        this.employees = [];
        this.delivered = true;
        this.dispatchers = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
                this.loadWarehouse();
                this.loadStorageCompanies();
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
            .getById(id)
            .then((response) => {
                this.driverInvoice = response;
                this.loadCarriers();
                this.loadDispatchers();
                this.getCompany();
                this.loadEmployees();
                
                let flagIsPaid = true;

                for (const dI of this.driverInvoice.items) {
                    if (dI.delivery_status != 'DELIVERED'){
                        flagIsPaid = false;
                    }
                }
        
                if (flagIsPaid){
                    this.delivered = true;
                } else {
                    this.delivered = false;
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    loadEmployees() {
        this.driverInvoicesService.getDriverEmployees()
        .then(response => {
            this.employees = response
        })
    }

    private loadCarriers() {
        this.helperService.showLoadingMxpro360();
        this.carrierService
            .getAll()
            .then((response) => {
                this.carriers = response;
                for (const cs of this.carriers) {
                    if (cs.id == this.driverInvoice.carrier_id) {
                        this.carrier = cs;
                    }
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    
    changeDate(event) {
        this.driverInvoice.date = event;
    }

    sendByEmail(type) {

            if (type) {
                jQuery(this.paymentModal.nativeElement).modal('show');
                return false;
            }
            
            if (this.email === '') {
                this.helperService.showMessageSnackbar(ConstantsMessages.EMPTY_EMAIL);
                return
            }

            this.helperService.showLoadingMxpro360();
            this.carrierService
              .sendEmails(this.driverInvoice.id, this.carrier.id, this.email)
              .then((response) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.EMAIL_SENT);
                jQuery(this.paymentModal.nativeElement).modal('hide');
              })
              .catch((error) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('Error: ', error);
              })
              .finally(() => {
                this.helperService.hideLoadingMxpro360();
              });
          }

    printInvoice() {
        const params = {
            driverInvoice: this.driverInvoice,
            carrier: this.carrier,
            email: this.email
        }
        this.helperService.downloadReport('DRIVER_INVOICE', this.driverInvoice.id, params);
    }

    getCompany(){
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll(false)
            .then((response) => {
                 for (const companies of response) {
                    if (companies.id == this.driverInvoice.company_id) {
                       this.companyName = companies.name;
                    }
                }
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    loadWarehouse() {
        this.helperService.showLoadingMxpro360();
        this.warehousesService
            .getAll()
            .then((response) => {
                this.rowWarehouse = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    loadStorageCompanies() {
        this.helperService.showLoadingMxpro360();
        this.storageCompaniesService
            .getAll()
            .then((response) => {
                this.rowStorageCompanies = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    edit(item, position){
        // this.positionArrayDriverInvoice = position;
        this.searchJob = item.estimate.document.code;
        this.padsJob = item.pads;
        this.cfJob = item.cf;
        this.rateJob = item.rate_cf;
        this.eta_start = item.eta_start;
        this.eta_end = item.eta_end;
        this.deliveryStatus = item.delivery_status;
        this.comments = item.comments;
    }


    remove(index){
        this.driverInvoice.items.splice(index, 1);
        this.refresh();
    }


    refresh() {
        this.driverInvoice.pads_pickup = 0;
        this.driverInvoice.total_cf = 0;
        this.driverInvoice.total_cf_charges = 0;
        this.driverInvoice.total_amount = 0;
        this.driverInvoice.total = 0;
        for (const dI of this.driverInvoice.items) {
            this.driverInvoice.pads_pickup = this.driverInvoice.pads_pickup + Number(dI.pads);
            this.driverInvoice.total_cf = this.driverInvoice.total_cf + Number(dI.cf);
            this.driverInvoice.total_cf_charges = this.driverInvoice.total_cf_charges + Number(dI.amount);
            this.driverInvoice.total_amount = this.driverInvoice.total_amount + Number(dI.amount);
            this.driverInvoice.total = this.driverInvoice.total + Number(dI.moving_balance);
        }
    }

    changePaid(){
        console.log(this.driverInvoice);
        setTimeout(() => {
            let flagIsPaid = true;
            for (const dI of this.driverInvoice.items) {
                if (!dI.is_paid){
                    flagIsPaid = false;
                }
            }

            if (flagIsPaid){
                this.driverInvoice.is_paid = true;
            } else {
                this.driverInvoice.is_paid = false;
            }
            this.helperService.showLoadingMxpro360();
            this.driverInvoicesService
                .edit(this.driverInvoice)
                .then((response) => {
                    // this.helperService.goToWorkspaceRouterLink('/driver-invoices');
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });  
        }, 200);
      
    }

    driverInvoiceChangeIsPaid(){
        setTimeout(() => {
            for (const dI of this.driverInvoice.items) {
                dI.is_paid = this.driverInvoice.is_paid;
            }

            this.changePaid();
        }, 200);
    }

    changeDelivered(item, i){

        this.driverInvoice.items[i].delivery_status = item == 'DELIVERED' ? 'PENDING' : 'DELIVERED';
        console.log(this.driverInvoice);
        
        let flagIsPaid = true;
        
        for (const dI of this.driverInvoice.items) {
            if (dI.delivery_status != 'DELIVERED'){
                flagIsPaid = false;
            }
        }

        if (flagIsPaid){
            this.delivered = true;
        } else {
            this.delivered = false;
        }

        this.saveDriverInoiceChange();
    }
    
    driverInvoiceChangeDelivered(){
        setTimeout(() => {
            for (const dI of this.driverInvoice.items) {
                if (this.delivered) {
                    dI.delivery_status = 'DELIVERED';
                } else {
                    dI.delivery_status = 'PENDING';
                }
            }
            this.saveDriverInoiceChange();
        }, 200);
    }

    saveDriverInoiceChange(){
        this.helperService.showLoadingMxpro360();
            this.driverInvoicesService
                .edit(this.driverInvoice)
                .then((response) => {
                    // this.helperService.goToWorkspaceRouterLink('/driver-invoices');
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });  
    }


    private loadDispatchers() {
        this.helperService.showLoadingMxpro360();
        this.dispatchContactsService
            .getAll()
            .then((response) => {
                this.dispatchers = response;
               
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveComments() {
        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
        .edit(this.driverInvoice)
        .then((response) => {
            swal({
                title: 'Attention!',
                text: "The comment was saved successfully",
                type: 'success',
                padding: '2em'
              });
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });  
    }

}
