import { Address } from '../global/address';

export class Warehouse {
    id: string;
    name: string;
    address: Address;
    created: number;
    updated: number;

     constructor(){
        this.id = null;
        this.name = null;
        this.address = new Address();
        this.created = null;
        this.updated = null;
     }
}
