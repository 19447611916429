import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/entities/workspace/company';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneNumber } from 'src/app/entities/workspace/phone-number';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { PhoneQueue } from '../../../entities/workspace/phone-queue';
import { PhoneCallsService } from 'src/app/services/companies/phone-calls.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { PhoneIvrsService } from 'src/app/services/workspaces/phone-ivrs.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';


declare var swal;

@Component({
  selector: 'app-phone-lines-form',
  templateUrl: './phone-lines-form.component.html',
  styleUrls: ['./phone-lines-form.component.scss']
})
export class PhoneLinesFormComponent implements OnInit {

  public dataForm: PhoneLine;
  public employees: EmployeeView[];
  public phoneRingGroups: PhoneRingGroup[];
  public phoneIvrs: PhoneIvr[];
  public nums: PhoneNumber[];
  companies: Array<Company>;
  public targets: any[];
  public employeeNums: any;
  JSON;
  public phoneQueue: PhoneQueue[];
  readonly environment = environment;
  public workspaceId: string;
  public extensions: any[];


  constructor(
    private phoneLineService: PhoneLinesService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private phoneIvrsService: PhoneIvrsService,
    private phoneQueuesService: PhoneQueuesService,
    private helperService: HelperService,
    private employeesService: EmployeesService,
    private currentRoute: ActivatedRoute,
    private companiesService: CompaniesService,
    private phoneCallService: PhoneCallsService,
    private authService: AuthService,
    private phoneExtensionsService: PhoneExtensionsService,
    ) {
    this.dataForm = new PhoneLine();
    this.JSON = JSON;
    this.phoneRingGroups = [];
    this.employees = [];
    this.phoneQueue = [];
    this.phoneIvrs = [];
    this.extensions = [];

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    //this.getAllEmployees();
    this.getAllRingGroups();
    this.getAllQueue();
    this.getAllIvrs();
    this.getAllNums();
    this.loadCompany();
   // this.loadExtensions();
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.load(params.id);

      }
    })

   setTimeout(() => {
    this.loadListNums()
   }, 1000);
  }


  loadCompany() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
        .getAll()
        .then((response) => {
            this.companies = response;
            this.workspaceId = this.authService.workspaceSession.workspace.id;
          setTimeout(() => {
            this.loadExtensions();


          }, 2000);


        })
        .catch((error) => {
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }


  /**
     * carga un nuevo phoneLine o carga un vehiculo padra ser editado
     * @param id
     */
  private load(id: any) {
    console.log("load", id);

    this.helperService.showLoadingMxpro360();
    this.phoneLineService
        .getById(id)
        .then((response) => {
          this.dataForm = response;

          console.log('this.dataform',this.dataForm);


          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };




  private loadListNums() {
    this.phoneCallService
      .getListPhoneWorkspace()
      .then((response) => {
        this.employeeNums = response;
        console.log('employee num',this.employeeNums);

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      });
  };


  save(): void {

    console.log('aqui numeros phone lines',this.employeeNums);

//main bolean
    /* for (const phoneline of this.employeeNums) {
      if (phoneline.main === true && this.dataForm.main === true) {
        phoneline.main = false;
        this.phoneLineService.save(phoneline)
        .then( (response)=> {
          console.log('response => ', response);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
          this.helperService.goToWorkspaceRouterLink('/phone-lines')
        });
      }

    } */

    this.helperService.showLoadingMxpro360();

    console.log('dataform',this.dataForm);

    this.phoneLineService.save(this.dataForm)
    .then( (response)=> {
      console.log('response => ', response);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
      this.helperService.goToWorkspaceRouterLink('/phone-lines')
    });
  }

  private getAllEmployees(): void{
      this.helperService.showLoadingMxpro360();
    this.employeesService.getAll().then((response) => {
      this.employees = response;
      this.changeTarget();

    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };

  private getAllRingGroups(): void{
      this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService.getAll().then((response) => {
      this.phoneRingGroups = response;
    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };

  private getAllQueue(): void{
    this.helperService.showLoadingMxpro360();
  this.phoneQueuesService.getAll().then((response) => {
    this.phoneQueue = response;
  }
  ).finally(() => {
    this.helperService.hideLoadingMxpro360();
  });
};

  private getAllIvrs(): void{
    this.helperService.showLoadingMxpro360();
  this.phoneIvrsService.getAll().then((response) => {
    this.phoneIvrs = response;
  }
  ).finally(() => {
    this.helperService.hideLoadingMxpro360();
  });
};


  private getAllNums(): void{
    this.helperService.showLoadingMxpro360();
    this.phoneLineService.getAllNums().then((response) => {
      this.nums = response;
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

/* voice mail
  onChangeAnnouncement(files: Array<string>) {

    let extencion = files[0].split('.');

    if (files.length > 0) {

    if ( extencion[1] === 'mp3'|| extencion[1] === 'mpeg'|| extencion[1] === 'ogg'|| extencion[1] === 'wav') {

     let mime = files[0].split('.');
     this.dataForm.voicemail_announcement.display_name = files[0];
     this.dataForm.voicemail_announcement.name = files[0];
     this.dataForm.voicemail_announcement.mime_type = 'audio/' + mime[1];

    }else{
     swal(
       'Error',
       'error the audio extension must be               mp3 mpeg ogg or wav',
       'error'
     );
    }

 }

  } */


  public loadExtensions() {


    console.log('test dentro de ', this.dataForm);

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllByCompany(this.dataForm.company_id)
        .then((response) => {

          console.log('response de extensions',response);

          this.extensions = response;

        this.changeTarget();

        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  changeTarget(){


    if(this.dataForm.destination.target == 'EXTENSION'){

      console.log('extensions',this.extensions);

      this.targets = this.extensions;

    }

    if(this.dataForm.destination.target == 'RING_GROUP'){
      this.targets = this.phoneRingGroups;
    }

    if(this.dataForm.destination.target == 'QUEUE'){
      this.targets = this.phoneQueue;
    }

    if(this.dataForm.destination.target == 'IVR'){
      console.log('IVR', this.targets);
      console.log('phoneIvrs', this.phoneIvrs);

      this.targets = this.phoneIvrs;
    }
    console.log('target',this.targets);
  }
}
