<div class=" layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                General Settings Load Rotation
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                        <a appCompanyRouterLink="/settings">
                            Settings
                        </a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">Load Rotation</li>
                </ol>
            </nav>
        </div>
        <div *ngIf="activeOption == 'NEW'" class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <button class="btn btn-warning mb-2" (click)="save()">
                    Save
                </button>
            </div>
        </div>

        <div *ngIf="activeOption == 'LIST'" class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning" (click)="new()">
                    <i class="fas fa-plus-square"></i> &nbsp; New Load Rotation
                </a>
            </div>
        </div>
    </div>

    <!-- header -->

    <form  *ngIf="activeOption == 'NEW'" #GeneralSettingsForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

        <!-- Company info -->
        <section>
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">

                    <h3> New Load Rotation</h3>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group mb-3">
                                <ng-container *ngIf="isEdit">
                                    <label for="company_name">Employee Name</label>
                                    <div class="input-group mb-1">
                                        <input class="form-control" [(ngModel)]="employee" disabled>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!isEdit">
                                    <label for="company_name">Employee Name</label>
                                    <div class="input-group mb-1">
                                        <select id="employee" [(ngModel)]="GeneralSettingsleadRotation.employee_id"
                                            name="employee_id" class="form-control" [appSelect2] [theme]="'mxp360-form'" required>
                                            <option *ngFor="let employee of employees" value="{{employee.id}}"> {{employee.name}}</option>
                                        </select>
                                    </div>
                                    <small class="form-text text-muted mb-1">
                                        Employee name
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-3">
                                <label for="company_name">Quantity</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="quantity" [(ngModel)]="GeneralSettingsleadRotation.quantity"
                                        placeholder="Enter here the quantity">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Quantity
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group mb-3">
                                <label for="company_name">Start Time</label>
                                <div class="input-group mb-1">
                                    <input type="time" class="form-control" [(ngModel)]="GeneralSettingsleadRotation.start_time" name="start_time" placeholder="Enter here the start time">
                                </div>
                                <small class="form-text text-muted mb-1"> Start Time </small>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group mb-3">
                                <label for="company_name">End Time</label>
                                <div class="input-group mb-1">
                                    <input type="time" class="form-control" [(ngModel)]="GeneralSettingsleadRotation.end_time" name="end_time" placeholder="Enter here the end time">
                                </div>
                                <small class="form-text text-muted mb-1"> End Time </small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- END Company info -->


    </form>

    <section *ngIf="activeOption == 'LIST'">
        <div class="bio layout-spacing ">
            <div class="widget-content widget-content-area">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="widget-content widget-content-area">
                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead>
                                        <tr >
                                            <th>Employee</th>
                                            <th>Quantity</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of listGeneralSettingsleadRotation">
                                            <td>{{ row.employee.name }} </td>
                                            <td>{{ row.quantity }} </td>
                                            <td>{{ row.start_time }} </td>
                                            <td>{{ row.end_time }} </td>
                                            <td> <a (click)="edit(row)" class="btn btn-outline-dark">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                            </a></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>