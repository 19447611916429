import { Pipe, PipeTransform } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';




/***
 * Permite crear un contador o temporizador pasandole la fecha desde el momento en el que debio haber empezado a contar los segundos minutos
 */
@Pipe({
    name: 'callTimer'
})
export class CallTimerPipe implements PipeTransform {

    transform(startDate: number): Observable<string> {

        // validamos que hayamos recibido algun valor
        if(!!startDate){

            // parseamos la informacion, esto porque la informacion viaja en formato unix milliseconds
            const start = new Date(startDate)

            return interval(1000)
                .pipe(
                    startWith(0),
                    map(() => this.calculateElapsedTime(start))
                );
        }

        // devolvemos null en caso de que no se haya recibido valor
        return null;
    }

    /**
     * algoritmo para calcular el tiempo del intervalo entre la fecha recibida y el actual momento
     * @param startDate 
     * @returns 
     */
    private calculateElapsedTime(startDate: Date): string {

        const currentTime = new Date().getTime();
        const startTime = new Date(startDate).getTime();

        // si la fecha de inicio es mayor que la actual, devuelve 00:00:00
        if(currentTime <= startTime){
            return '00:00:00';  
        }

        const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
        const hours = Math.floor(elapsedSeconds / 3600);
        const minutes = Math.floor((elapsedSeconds % 3600) / 60);
        const seconds = elapsedSeconds % 60;

        return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    }

    /**
     * si el numero es menor a 10 le agrega un cero a la izquierda
     * 
     * @param num 
     * @returns 
     */
    private pad(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }

}
