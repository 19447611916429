import { File } from '../global/file';
import { Document } from './document';

export class Ticket {

    id: string;
    document: Document;
    customer_id: string;
    estimate_id: string;
    work_department_id: string;
    status: "OPEN" | "PENDING" | "RESOLVED" | "CLOSED" | "WAITING_COSTUMER";
    priority: "LOW" | "MID" | "HIGH" | "URGENT";
    type: "PICKUP_DISPUTE" | "DELIVERY_QUESTION" | "PICKUP_QUESTION" | "DAMAGE_CLAIMS" | "CANCELLATION_REQUEST" | "ESTIMATE_CHANGE" | "DATE_CHANGE_REQUEST" | "PAYMENT_QUESTION";
    ticket_type_id: string;
    subject: string;
    description: string;
    files: Array<File>;
    company_id: string;
    created_by_employee_id: string;
    assigned_to_employee: string;
    work_department: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.document = new Document();
        this.customer_id = null;
        this.estimate_id = null;
        this.work_department_id = null;
        this.status = 'OPEN';
        this.priority = 'LOW';
        this.type = 'DELIVERY_QUESTION';
        this.ticket_type_id = null;
        this.subject = null;
        this.description = null;
        this.files = [];
        this.company_id = '';
        this.created_by_employee_id = null;
        this.assigned_to_employee = null;
        this.work_department = null;
        this.created = null;
        this.updated = null;
    }

}
