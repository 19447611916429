import { Component, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
declare var ApexCharts;
@Component({
    selector: 'app-booked-by-company',
    templateUrl: './booked-by-company.component.html',
    styleUrls: ['./booked-by-company.component.scss']
})
export class BookedByCompanyComponent implements OnInit {
    jobsCompanySalesLast6Month: any;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
    ) {
        this.jobsCompanySalesLast6Month = [];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        this.loadCompanySalesLast6Month();
    }

    
    loadCompanySalesLast6Month(){
        this.dashboardWorkspaceService
            .getCompanySalesLast6Month()
            .then((response: any) => {
                this.jobsCompanySalesLast6Month = response;
                if (response.total > 0) {
                    setTimeout(() => {
                        this.companySalesLast6Month();
                    }, 300);
                }

            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
                });
    }

    companySalesLast6Month() {
        var sLineArea = {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3,
                curve: 'smooth'
            },
            series: this.jobsCompanySalesLast6Month.series,

            xaxis: {
                categories: this.jobsCompanySalesLast6Month.labels,
            },

        }

        var chart = new ApexCharts(
            document.querySelector("#otra"),
            sLineArea
        );

        chart.render();
    }

}
