import { Customer } from 'src/app/entities/workspace/customer';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { EstimateTransportVehicles } from 'src/app/entities/workspace/estimate-transport-vehicles';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Paginator } from 'src/app/entities/helpers/paginator';
import { LeadExport } from 'src/app/entities/workspace/lead-export';
import { EstimateFreights } from 'src/app/entities/workspace/estimate-freights';
import { LogView } from 'src/app/entities/global/log-view';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { EstimateStorage } from 'src/app/entities/workspace/estimate-storage';
import { EstimateLocalTariff } from 'src/app/entities/workspace/estimate-local-tariff';
import { EstimateWorker } from 'src/app/entities/workspace/estimate-worker';

@Injectable({
    providedIn: 'root'
})
export class EstimatesService {

    constructor(private http: HttpClient) {

    }
    getAllWithFullView(paginator: Paginator, categoryEstimate, filterData: FilterData): Promise<Paginator> {
        const params: any = {
            "full-view": "1",
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start_time": paginator.paginator.start,
            "end_time": paginator.paginator.end,
            "order_by": "created",
            "order_type": paginator.paginator.order_type,
            "category": categoryEstimate,
            "pickup_state": filterData.pickup_state,
            "delivery_state": filterData.delivery_state,
            "service": filterData.service,
            "filter_date_for": filterData.filter_date_for,
            "agent": filterData.agent
        };
        return this.http.get<Paginator>(environment.api.company + '/estimates', { params: params }).toPromise();
    }

    getAllByCompany(employeeId, category, orderBy): Promise<any> {
        const params: any = {
            "full-view": "1",
            "employee_id": employeeId,
            "category": category,
            "order_by": orderBy,
            "booked": false
        };

        return this.http.get<Paginator>(environment.api.company + '/estimates', { params: params }).toPromise();
    }

    getAllAutoWithFullView(): Promise<Array<EstimateView>> {
        return this.http.get<Array<EstimateView>>(environment.api.company +
            '/auto-estimates', { params: { 'full-view': '1' } }).toPromise();
    }

    getAll(): Promise<Array<Estimate>> {
        return this.http.get<Array<Estimate>>(environment.api.company + '/estimates').toPromise();
    }

    getById(id: string) {
        return this.http.get<EstimateView>(environment.api.company + '/estimates/' + id).toPromise();
    }

    getEstimateById(id: string) {
        return this.http.get<any>(environment.api.company + '/estimate/' + id).toPromise();
    }

    getAllWithFullViewById(data: any, id): Promise<Array<any>> {
        return this.http.get<any>(environment.api.company + '/jobs/' + id).toPromise();
    }

    getByEstimateCode(code: string) {
        return this.http.get<Array<Estimate | {message: string}>>(environment.api.company + '/estimates/search/' + code).toPromise();
    }

    toCreateByLead(id: string) {
        return this.http.post(environment.api.company + '/leads/' + id + '/estimates', {}).toPromise();
    }
    
    toAssignLead(id: string, employeeId: string) {
        return this.http.post(environment.api.company + '/leads/' + id + '/assign', {employeeId}).toPromise();
    }

    duplicateLead(id: string, movingDuplicateType: string) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/duplicate', {movingDuplicateType}).toPromise();
    }

    save(dataForm: Estimate): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Estimate) {
        return this.http.post(environment.api.company + '/estimates', dataForm).toPromise();
    }

    edit(dataForm: Estimate) {
        return this.http.put(environment.api.company + '/estimates/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/estimates/' + id).toPromise();
    }

    patchEntity(id: string, dataForm: any, calculateTotal: boolean = true) {
        let path = environment.api.company + '/estimates/' + id;
        if (!calculateTotal) {
            path += '?no_total=1';
        }
        return this.http.patch(path , dataForm).toPromise();
    }

    patchEntityByCalendar(id: string, dataForm: any, company) {
        let path = environment.api.workspace + '/companies/' + company + '/estimates/' + id;
      
        return this.http.patch(path , dataForm).toPromise();
    }

    updatePickupAndDeliveryAddress(id: string, dataForm: any) {
        return this.http.put(environment.api.company + '/estimates/' + id + '/update-pickup-and-delivery-address', dataForm).toPromise();
    }

    CreateEstimate(estimate: Estimate, customer: Customer, advertiser: string) {
        return this.http.post(environment.api.company + '/estimates', {estimate, customer, advertiser}).toPromise();
    }

    getAutoTransport(id: string): Promise<Array<EstimateTransportVehicles>> {
        return this.http.get<Array<EstimateTransportVehicles>>(
            environment.api.company + '/estimates/' + id + '/transport-vehicles').toPromise();
    }

    getFreight(id: string): Promise<Array<EstimateFreights>> {
        return this.http.get<Array<EstimateFreights>>(
            environment.api.company + '/estimates/' + id + '/freights').toPromise();
    }

    saveAutoTransport(estimate_id: string, estimateTransportVehicle: EstimateTransportVehicles) {
        return this.http.post<Array<EstimateTransportVehicles>>(environment.api.company + '/estimates/' + estimate_id + '/transport-vehicles', estimateTransportVehicle).toPromise();
    }

    saveFreight(estimate_id: string, estimateFreight: EstimateFreights) {
        return this.http.post<Array<EstimateFreights>>(environment.api.company + '/estimates/' + estimate_id + '/freights', estimateFreight).toPromise();
    }

    editAutoTransport(estimate_id: string, estimateTransportVehicle: EstimateTransportVehicles) {
        const autoTransportId = estimateTransportVehicle.id;
        return this.http.put(environment.api.company + '/estimates/' + estimate_id +
        '/transport-vehicles/' + autoTransportId, estimateTransportVehicle).toPromise();
    }

    editFreight(estimate_id: string, estimateFreight: EstimateFreights) {
        const autoTransportId = estimateFreight.id;
        return this.http.put(environment.api.company + '/estimates/' + estimate_id +
        '/freights/' + autoTransportId, estimateFreight).toPromise();
    }
    removeAutoTransport(estimate_id: string, autoTransportId: string) {
        return this.http.delete(environment.api.company + '/estimates/' +
        estimate_id + '/transport-vehicles/' + autoTransportId).toPromise();
    }
    removeFreight(estimate_id: string, estimateFreightId: string) {
        return this.http.delete(environment.api.company + '/estimates/' +
        estimate_id + '/freights/' + estimateFreightId).toPromise();
    }

    bookEstimate(id: string) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/toggle-book', {}).toPromise();
    }

    QAStatusEstimate(id: string, status: string) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/qa-status', { 'qa_status': status }).toPromise();
    }

    calculateLiabilityAmount(estimate_id: string) {
        return this.http.get(environment.api.company + '/estimates/' + estimate_id + '/calculate-liability-amount').toPromise();
    }

    createNewContact(estimate_id: string, contact: Customer) {
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/create-contact', contact).toPromise();
    }
    
    validateContact(estimate_id: string, contact: any) {
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/validate-contact', contact).toPromise();
    }
    
    getDataExportExcel(dataFilter, companyId): Promise<Array<LeadExport>> {
        return this.http.get<Array<LeadExport>>(environment.api.companies + companyId + '/export-leads', { params: { 'report_data': JSON.stringify(dataFilter) } }).toPromise();
    }
    
    addDicountByCf(estimate_id: string, tariff_additional_concept_id: string) {
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/discount-by-cf', {tariff_additional_concept_id}).toPromise();
    }

    getLogs(estimate_id: string): Promise<Array<LogView>> {
        return this.http.get<Array<LogView>>(environment.api.company + '/estimates/' + estimate_id + '/logs', {}).toPromise();
    }

    sendNewBindingEstimate (estimate_id: string) {
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/new-binding/send-email', {}).toPromise();
    }

    sendAmendedBOL (estimate_id: string) {
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/amended-estimate/send-email', {}).toPromise();
    }

    editStorage(estimate_id: string, estimateStorage: EstimateStorage) {
        return this.http.put(environment.api.company + '/estimates/' + estimate_id +
        '/storages', estimateStorage).toPromise();
    }

    removeStorage(estimate_id: string, estimateStorage: EstimateStorage) {
        return this.http.put(environment.api.company + '/estimates/' + estimate_id +
        '/storages-remove', estimateStorage).toPromise();
    }

    editProvider(estimate_id: string, lead_id, estimate_advertiser_id){
        return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/edit-provider', {lead_id, estimate_advertiser_id}).toPromise();
    }

    getAmountFromFormula(id: string) {
        return this.http.get<number>(`${environment.api.company}/estimates/${id}/get-amount-from-formule`).toPromise();
    }

    setLocalWarehouse (id: string, data) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/local-warehouse', {data}).toPromise();
    }

    editLocalWarehouse (id: string, type: string) {
        return this.http.put(environment.api.company + '/estimates/' + id + '/local-warehouse', {params : type}).toPromise();
    }

    updateEstimateLocalTariff(id: string, estimateLocalTariff: EstimateLocalTariff) {
        return this.http.put(environment.api.company + '/estimates/' + id + '/estimate-local-tariff', estimateLocalTariff).toPromise();
    }

    savePackersPacking(id: string, estimatePackerPacking: EstimateWorker) {
        return this.http.put(environment.api.company + '/estimates/' + id + '/estimate-packer-packing', estimatePackerPacking).toPromise();
    }

    saveUnackersPacking(id: string, estimateUnpackerPacking: EstimateWorker) {
        return this.http.put(environment.api.company + '/estimates/' + id + '/estimate-packer-unpacking', estimateUnpackerPacking).toPromise();
    }

    reAssignEstimate(fromEmployeeSelected: string, toEmployeeSelected: string) {

        const data = {fromEmployeeSelected, toEmployeeSelected};
        return this.http.put(environment.api.company + '/estimates-re-assign', data).toPromise();
    }
}
