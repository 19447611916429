import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { environment } from 'src/environments/environment';
import { WorkDepartmentView } from 'src/app/entities/workspace/work-department-view';

@Injectable({
  providedIn: 'root'
})
export class WorkDepartmentsService {

    constructor(private http: HttpClient) {

    }

    getAllWithFullView(): Promise<Array<WorkDepartmentView>> {
        return this.http.get<Array<WorkDepartmentView>>(environment.api.company + '/work-departments', { params: { "full-view": "1" } }).toPromise();
    }

    getAll(): Promise<Array<WorkDepartment>> {
        return this.http.get<Array<WorkDepartment>>(environment.api.company + '/work-departments').toPromise();
    }

    getAllByCompany(idCompany: string): Promise<Array<WorkDepartment>> {
        return this.http.get<Array<WorkDepartment>>(environment.api.company + '/work-departments?company_id=' + idCompany ).toPromise();
    }

    getById(id: string) {
        return this.http.get<WorkDepartment>(environment.api.company + '/work-departments/' + id).toPromise();
    }

    save(dataForm: WorkDepartment): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: WorkDepartment) {
        return this.http.post(environment.api.company + '/work-departments', dataForm).toPromise();
    }

    edit(dataForm: WorkDepartment) {
        return this.http.put(environment.api.company + '/work-departments/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/work-departments/' + id).toPromise();
    }
}
