import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';
declare var ApexCharts;
declare var jQuery;
@Component({
  selector: 'app-payment-methods-used',
  templateUrl: './payment-methods-used.component.html',
  styleUrls: ['./payment-methods-used.component.scss']
})
export class PaymentMethodsUsedComponent implements OnInit {
    jobsPaymentMethodUsed: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ){
    this.jobsPaymentMethodUsed = [];

   }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId) {
        this.loadPaymentMethodUsedCompany();
    } else {
        this.loadPaymentMethodUsedWorkspace();
    }
  }

  
  loadPaymentMethodUsedWorkspace(){
        
    this.dashboardWorkspaceService
        .getPaymentMethodUsed()
        .then((response) => {
            this.jobsPaymentMethodUsed = response;
            setTimeout(() => {
                this.paymentMethods();
            }, 600);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadPaymentMethodUsedCompany(){
        
    this.dashboardComopanyService
        .getPaymentMethodUsed()
        .then((response) => {
            this.jobsPaymentMethodUsed = response;
            setTimeout(() => {
                this.paymentMethods();
            }, 600);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


  
  paymentMethods(){
    const that = this;
    // var radialChart = {
    //     chart: {
    //         height: 350,
    //         type: 'radialBar',
    //         toolbar: {
    //           show: false,
    //         }
    //     },
    //     plotOptions: {
    //         radialBar: {
    //             dataLabels: {
    //                 name: {
    //                     fontSize: '22px',
    //                 },
    //                 value: {
    //                     fontSize: '16px',
    //                 },
    //                 total: {
    //                     show: true,
    //                     label: 'Total',
    //                     formatter: function (w) {
    //                         // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
    //                         return that.jobsPaymentMethodUsed.total
    //                     }
    //                 }
    //             }
    //         }
    //     },
    //     series: that.jobsPaymentMethodUsed.series,
    //     labels:  that.jobsPaymentMethodUsed.labels,    
    // }

    var options = {
        series: that.jobsPaymentMethodUsed.series,
        chart: {
        width: 380,
        type: 'pie',
      },
      labels: that.jobsPaymentMethodUsed.labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
      };

    
    var chart = new ApexCharts(
        document.querySelector("#radial-chart"),
        options
    );
    
    chart.render();

    setTimeout(() => {
        jQuery('#radial-chart .apexcharts-legend-text').css('color','white')
    }, 500);
}

}
