import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-last10-recurrings',
  templateUrl: './last10-recurrings.component.html',
  styleUrls: ['./last10-recurrings.component.scss']
})
export class Last10RecurringsComponent implements OnInit {
    jobsRecurringLast10: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) {
    this.jobsRecurringLast10 = [];

   }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadRecurringLast10Company();
    } else {
        this.loadRecurringLast10Workspace();
    }
  }
  
  loadRecurringLast10Workspace(){
        
    this.dashboardWorkspaceService
        .getRecurringLast10()
        .then((response) => {
            this.jobsRecurringLast10 = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadRecurringLast10Company(){
        
    this.dashboardComopanyService
        .getRecurringLast10()
        .then((response) => {
            this.jobsRecurringLast10 = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

}
