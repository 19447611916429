<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Lead Forwarding
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Lead Forwarding</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/lead-forwarding-links/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Lead Forwarding
                </a>
            </div>
        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Link</th>
                                <th>Auth Token</th>
                                <th class="text-center">List ID</th>
                                <th class="text-center">hopper</th>
                                <th>created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}} </td>
                                <td>{{row.name}}</td>
                                <td>{{row.link}}</td>
                                <td>{{row.auth_token}}</td>
                                <td class="text-center">{{row.list_id}}</td>
                                <td class="text-center">{{row.hopper}}</td>
                                <td>{{row.created | date}}</td>
                                <td>
                                    <a appWorkspaceRouterLink ="/lead-forwarding-links/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger button-space">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->


</div>
