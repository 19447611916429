import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';


declare var ApexCharts;

@Component({
  selector: 'app-total-incoming-leads',
  templateUrl: './total-incoming-leads.component.html',
  styleUrls: ['./total-incoming-leads.component.scss']
})
export class TotalIncomingLeadsComponent implements OnInit {
    jobsTotalIncomingLeads: any;
    @Input()
    companyId: boolean = false;
    percentaje: any;
  constructor(
    private dashboardWorkspaceService: DashboardWorkspaceService,
    private dashboardComopanyService: DashboardComopanyService,
  ) { 
    this.jobsTotalIncomingLeads = {
        "total": 0
    };
    this.percentaje = 0;
  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId) {
        this.loadTotalIncomingLeadsCompany();
    } else {
        this.loadTotalIncomingLeadsWorkspace();
    }
  }

setTotalLeads(response) {
  let index = 1;
  if (this.companyId) {
    index = 8;
  }
  this.jobsTotalIncomingLeads = response;
  if (this.jobsTotalIncomingLeads > 0) {
    this.percentaje = (this.jobsTotalIncomingLeads.total / this.jobsTotalIncomingLeads['arrayTotals'][index]) * 100;
  }
  this.percentaje = this.percentaje.toFixed(2);
  setTimeout(() => {
    this.totalIncomingLeads();
  }, 600);
}


loadTotalIncomingLeadsWorkspace(){
  this.dashboardWorkspaceService
      .getTotalIncomingLeads()
      .then((response) => {
          this.setTotalLeads(response);
      })
      .catch((error) => {
          console.error('Error', error);
      })
      .finally(() => {
      });
}

loadTotalIncomingLeadsCompany(){
    this.dashboardComopanyService
        .getTotalIncomingLeads()
        .then((response) => {
          this.setTotalLeads(response);
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


totalIncomingLeads() {

  const that = this;

  var options = {
    chart: {
      id: 'sparkline1',
      group: 'sparklines',
      type: 'area',
      height: 190,
      sparkline: {
        enabled: true
      },
    },
    stroke: {
        curve: 'smooth',
        width: 2
    },
    fill: {
      type:"gradient",
      gradient: {
          type: "vertical",
          shadeIntensity: 1,
          inverseColors: !1,
          opacityFrom: .80,
          opacityTo: .5,
          stops: [100, 100]
      }
    },
    series: [{
      name: 'Leads',
      data: this.jobsTotalIncomingLeads['arrayTotals']
    }],
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    yaxis: {
      min: 0
    },
    grid: {
      padding: {
        top: 125,
        right: 0,
        bottom: 0,
        left: 0
      }, 
    },
    tooltip: {
      x: {
        show: false,
      },
      theme: 'dark'
    },
    colors: ['#ffffff']
  }


  var chart = new ApexCharts(
      document.querySelector("#total-incoming-leads"),
      options
  );

  chart.render();
}

}
