
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InventoryItem } from 'src/app/entities/workspace/inventory-item';
import { ConstantsMessages } from 'src/app/constants-messages';
import { BulkyFee } from 'src/app/entities/workspace/bulky-fee';
import { InventoryCategory } from 'src/app/entities/workspace/inventory-category';
import { InventoryCategoriesService } from 'src/app/services/workspaces/inventory-categories.service';
import { HelperService } from 'src/app/services/helper.service';
import { InventoryItemsService } from 'src/app/services/companies/inventory-items.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-inventory-items-form',
    templateUrl: './inventory-items-form.component.html',
    styleUrls: ['./inventory-items-form.component.scss']
})
export class InventoryItemsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: InventoryItem;
    rowsInventoryCategory: Array<InventoryCategory>;

    @ViewChild('toggleBulkyFee', { static: true })
    toggleBulkyFee: ElementRef<HTMLInputElement>;

    constructor(
        private inventoryCategoriesService: InventoryCategoriesService,
        private currentRoute: ActivatedRoute,
        private inventoryItemsQuote: InventoryItemsService,
        private helperService: HelperService
    ) {
        this.dataForm = new InventoryItem();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);

            }
        });
        this.helperService.showLoadingMxpro360();
        this.inventoryCategoriesService
            .getAll()
            .then((response) => {
                this.rowsInventoryCategory = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.inventoryItemsQuote
            .getById(id)
            .then((response) => {
                if (response.bulky_fee !== null) {
                    jQuery(this.toggleBulkyFee.nativeElement).prop('checked', true);
                }
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.inventoryItemsQuote
            .save(this.dataForm)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/inventory-items');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setBulkyFee($event) {
        if (jQuery(this.toggleBulkyFee.nativeElement).is(':checked')) {
            this.dataForm.bulky_fee = new BulkyFee();
        } else {
            this.dataForm.bulky_fee = null;
        }
    }

    validateCategory() {
        if (this.dataForm.inventory_category_id == null || this.dataForm.inventory_category_id == '') {
            this.helperService.showMessageSnackbar(this.constantsMessages.CATEGORY_MANDATORY);
        }
    }

}
