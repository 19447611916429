import { EstimateAdditionalCharge } from 'src/app/entities/workspace/estimate-additional-charge';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstimatesAdditionalChargesService {

  constructor(private http: HttpClient) { }

    getAll(id: string): Promise<Array<EstimateAdditionalCharge>> {
        return this.http.get<Array<EstimateAdditionalCharge>>(environment.api.company +
          '/estimates/' + id + '/additional-charges').toPromise();
    }

    add(id: string, dataForm: EstimateAdditionalCharge) {
      if (dataForm.id === null) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/additional-charges', dataForm).toPromise();
      } else  {
        return this.http.put(environment.api.company + '/estimates/' + id + '/additional-charges/' + dataForm.id, dataForm).toPromise();
      }

    }

    remove(id: string, idDelete: string) {
        return this.http.delete(environment.api.company + '/estimates/' + id + '/additional-charges/'
        + idDelete).toPromise();
    }

}
