<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Payroll Balances
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Payroll Balances</li>
                        </ol>
                    </nav>
                    <br>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appCompanyRouterLink="/payroll-balances/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; Add Payroll Balance
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Remarks</th>
                                <th class="text-center">Status</th>
                                <th>Employee</th>
                                <th>Done Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of payrollBalances | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.type}}</td>
                                <td>{{row.amount}}</td>
                                <td>{{row.remarks}}</td>
                                <td class="text-center">
                                    <span *ngIf="row.status === 'PENDING'">
                                        <span name="pending" class="badge badge-warning">
                                            {{row.status}}
                                        </span>
                                    </span>
                                    <span *ngIf="row.status === 'DONE'">
                                        <span name="resolved" class="badge badge-success">
                                            {{row.status}}
                                        </span>
                                    </span>
                                </td>                               
                                <td>
                                    <span *ngIf="row.employee && row.employee.name != null">
                                        {{row.employee.name | titlecase}}
                                    </span>

                                    <span *ngIf="!row.employee || row.employee.name == null">
                                        <span class="badge outline-badge-danger">
                                            None
                                        </span>
                                    </span>
                                </td>
                                <td>{{row.done_date | date}}</td>
                                <td>
                                    <button *ngIf="row.status !== 'DONE'" (click)="payRollDone(row.id)" class="btn btn-secondary">
                                        Done
                                    </button>
                                    <a *ngIf="row.status !== 'DONE'" appCompanyRouterLink="/payroll-balances/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button *ngIf="row.status !== 'DONE'" (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>
