import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CounterService } from 'src/app/services/companies/counter-phone.service';
import { PhoneCallsService } from 'src/app/services/companies/phone-calls.service';
import { HelperService } from 'src/app/services/helper.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';


@Component({
  selector: 'app-active-agents',
  templateUrl: './active-agents.component.html',
  styleUrls: ['./active-agents.component.scss']
})
export class ActiveAgentsComponent implements OnInit {


  public phoneLines: any[];
  public phoneLineActive: any[];
  public phoneLineActiveCalls: any[];
  public totalsCalls : any[];
  public outboundCalls : any[];
  public inboundCalls : any[];
  public minutes: number;
  public seconds: number;
  public callId;
  public timeM: any[];
  public timeS: any[];
  public refreshTime:number;

  public times: any[];


  constructor(
    public phoneLinesService: PhoneLinesService,
    public authService: AuthService,
    public helperService: HelperService,
    public phoneCallService: PhoneCallsService,


  ) {

this.phoneLines = [];
this.phoneLineActive = [];
this.phoneLineActiveCalls = [];
this.timeM = [];
this.timeS = [];
this.minutes = 0;
this.seconds = 0;
this.callId = 0;
this.refreshTime = 30000;
this.outboundCalls = [];
this.totalsCalls = [];
this.inboundCalls = [];
this.times = [];



   }

  ngOnInit(): void {
    this.getPhoneLines();
    setTimeout(() => {
      this.loadAllTotal();
    }, 2000);
 /*    setInterval(() => {
      this.phoneLines = [];
      this.phoneLineActive = [];
      this.phoneLineActiveCalls = [];
      this.getPhoneLines();
          setTimeout(() => {
      this.loadAllTotal();
    }, 2000);

        }, this.refreshTime); */

  }
  private getPhoneLines() {
    this.phoneLinesService
      .activeAgents(this.callId)
      .then((lines) => {

        this.timeM = [];
        this.timeS = [];


        for (const line of lines['phoneLine']) {

          this.phoneLines.push(line);
        }
        for (const active of lines['phoneLineActive']) {

          this.phoneLineActive.push(active);
        }

        for (const calls of lines['phoneLineActiveCalls']) {

          this.phoneLineActiveCalls.push(calls);

        }

        for (const time of lines['phoneTime']) {

        let times1 = new Date(time.created);
        let times2 = new Date().getTime();
        let time1Second = times1.getTime();

        let resultTime = times2 - time1Second;

        let segundos = Math.floor(resultTime / 1000);

        let minutos = Math.floor(segundos / 60);
        let segundosRestantes = segundos % 60;

        console.log('time de times;', time);

          this.times.push(time);


        for (const phoneLine of this.phoneLines) {

          console.log('test phone line',phoneLine);

          console.log('this.times!!',this.times);


        if (phoneLine._id == time.phone_line_id ){
            phoneLine.second =  segundosRestantes;
            phoneLine.minutes =  minutos;


          }
/*
          if(phoneLine.second2 || phoneLine.minutes2){
            let times12 = new Date(time.created);
            let times22 = new Date().getTime();
            let time1Second2 = times12.getTime();

          let resultTime2 = times22 - time1Second2;
          let segundos2 = Math.floor(resultTime2 / 1000);
          let minutos2 = Math.floor(segundos2 / 60);
          let segundosRestantes2 = segundos2 % 60;

          phoneLine.second2 =  segundosRestantes2;
          phoneLine.minutes2 =  minutos2;

         } */

        }

        }

    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  };



  private loadAllTotal() {
    //this.helperService.showLoadingMxpro360();

    for (let i = 0; i < this.phoneLines.length; i++) {

    this.phoneCallService
      .getAllTotalToday( this.phoneLines[i].phone_number)
      .then((response) => {

        console.log('response total ',response );
        this.totalsCalls.push(response.length);

        for (const phoneLine of this.phoneLines) {

                  let inbound = 0;
          let outbound = 0;
          for (const calls of response) {
            if (calls.direction == "INCOMING") {
              inbound++;
            } else {
              outbound++;
            }
            if (phoneLine._id == calls.phone_line_id ){
            phoneLine.inbound = inbound;
            phoneLine.outbound = outbound;
            phoneLine.totalCall = response.length;
            }
          }
        }


         })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      })

    }

  };




}
