<div class="text-sms-section">
    <div class="widget-content widget-content-area">

        <div class="row">

            <div class="contacts-section">

                <div class="col-12 top-message-bar">
                    <div class="new-conversation">
                        <span>New Conversation</span>
                    </div>
                    <button class="btn rounded-circle btn-add-number-sms" (click)="newChat()" [disabled]="!numberPhoneSelected">
                        <i class="fa-solid fa-comment"></i>
                    </button>
                </div>

                <div class="contact-list">

                    <!-- Listado de contactos con mensajes activos -->
                    <ng-container *ngFor="let contact of contacts">
                        <div class="position-relative">
                          <div id="{{contact['end_point']}}" [ngClass]="contact.read ? 'contact' : 'contact unread-contact'"  (click)="loadChat(contact['end_point']);readConversation(contact)">
                              <div class="customer-img">
                                  <!-- <span class="">PP</span> -->
                                  <i class="fa-solid fa-user"></i>
                              </div>
                              <div class="customer-info">
                                  <div class="d-flex justify-content-between">
                                      <div class="customer">
                                          <span class="customer-name" >{{contact['end_point'] | phone:'US'}}</span>


                                      </div>
                                      <div class="date-last-message">
                                          <span>{{contact.created | date}}</span>
                                      </div>
                                  </div>
                                  <div class="d-flex justify-content-between">
                                      <div class="last-message">
                                          <span class="message"> {{contact.message}} </span>
                                      </div>
                                      <div class="d-flex">

                                          <!-- Número de mensajes pendientes por leer -->
                                          <!-- <div class="pending-messages">
                                              <span class="number">4</span>
                                          </div> -->


                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="option">
                            <div class="dropdown">
                                <a class="dropdown-toggle" type="button" id="menu1" data-toggle="dropdown">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                </a>
                                <ul class="dropdown-menu" role="menu" aria-labelledby="menu">
                                    <li class="dropdown-item">
                                        <a (click)="addContact()">Add Contact</a>
                                    </li>
                                    <!-- <li class="dropdown-item">
                                        <a href="#">Pin Chat</a>
                                    </li> -->
                                    <li class="dropdown-item">
                                        <a (click)="deleteConversation(contact)" >Delete Chat</a>
                                    </li>
                                </ul>
                            </div>
                          </div>
                        </div>
                    </ng-container>


                </div>

            </div>

            <div class="chat-section">

                <div class="top-chat-bar">

                    <div class="active-chat">
                        <ng-container *ngIf="selectedContact">
                            <div class="customer-img">
                                <!-- <span>PP</span> -->
                                <i class="fa-solid fa-user"></i>
                            </div>
                            <div class="customer-info">
                                <div class="customer-phone">Contact</div>
                                <div class="customer-name">{{selectedContact | phone:'US'}}</div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="filtered-list-search layout-spacing align-self-center px-0">
                        <form class="form-inline my-2 my-lg-0">
                            <div class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                                <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                                    class="form-control filter-search" id="input-search" placeholder="Search..." [disabled]="!numberPhoneSelected">
                            </div>
                        </form>
                    </div>

                    <div class="toggle-dark">
                        <label class="switch">
                            <input id="toggleDarkChat" type="checkbox" (click)="darkChat = !darkChat; $event.stopPropagation()">
                            <span class="slider"></span>
                        </label>
                    </div>

                </div>

                <div class="chat-section-messages" [ngClass]="darkChat ? 'dark-chat' : 'light-chat'">

                    <div id="messages_box" class="messages-box" #messagesBox>

                        <ng-container *ngFor="let chat of chats">

                            <div [ngClass]="{'incoming-message' : chat.direction == 'INCOMING', 'outgoing-message' : chat.direction == 'OUTGOING'}">
                                <div class="sender">
                                    <span >{{chat.from | phone:'US'}}
                                    </span>

                                </div>
                                <div class="message">
                                    <span>
                                        {{chat.message}}
                                    </span>
                                </div>
                                <div class="date-time">
                                    <span>{{chat.created | dateTime}}</span>
                                </div>
                            </div>

                        </ng-container>

                    </div>

                    <div class="message-sending-bar">
                        <input type="text" class="input-message" placeholder="Type your message" name="newMessage" [(ngModel)]="newMessage" [disabled]="!numberPhoneSelected">
                        <button class="btn-send" [disabled]="!numberPhoneSelected" (click)="sendMessage(true)">
                            <svg viewBox="0 0 28 28">
                                <path d="M3.78963301,2.77233335 L24.8609339,12.8499121 C25.4837277,13.1477699 25.7471402,13.8941055 25.4492823,14.5168992 C25.326107,14.7744476 25.1184823,14.9820723 24.8609339,15.1052476 L3.78963301,25.1828263 C3.16683929,25.4806842 2.42050372,25.2172716 2.12264586,24.5944779 C1.99321184,24.3238431 1.96542524,24.015685 2.04435886,23.7262618 L4.15190935,15.9983421 C4.204709,15.8047375 4.36814355,15.6614577 4.56699265,15.634447 L14.7775879,14.2474874 C14.8655834,14.2349166 14.938494,14.177091 14.9721837,14.0981464 L14.9897199,14.0353553 C15.0064567,13.9181981 14.9390703,13.8084248 14.8334007,13.7671556 L14.7775879,13.7525126 L4.57894108,12.3655968 C4.38011873,12.3385589 4.21671819,12.1952832 4.16392965,12.0016992 L2.04435886,4.22889788 C1.8627142,3.56286745 2.25538645,2.87569101 2.92141688,2.69404635 C3.21084015,2.61511273 3.51899823,2.64289932 3.78963301,2.77233335 Z"></path>
                            </svg>
                        </button>
                    </div>

                </div>

            </div>

        </div>

    </div>
</div>


<!-- Modal - New Chat -->
<div class="modal fade pickup-documents-modal" #newChatModal tabindex="-1" role="dialog" aria-labelledby="modal-company" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">

        <div class="modal-content modal-role-form">

            <div class="modal-header justify-content-center">
                <h5 class="modal-title">
                    New Conversation
                </h5>
            </div>

            <div class="modal-body">

                <div class="col-12">
                    <div class="form-group mb-4">
                        <label for="inputState">
                            Phone Number
                        </label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-phone">
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <input type="text" maxlength="12" id="edit_phone_customer" placeholder="(000) 000-0000"
                            class="form-control" name="edit_phone_customer" [(ngModel)]="dataForm.phone_number" required>
                        </div>
                        <small class="form-text text-muted mb-4">Enter phone number.</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group mb-4">
                        <label for="customer_name">
                            Message
                        </label>
                        <div class="input-group mb-1">
                            <textarea class="form-control" name="new-message" id="new_message" cols="30" rows="4" placeholder="Messages..."  [(ngModel)]="dataForm.message"></textarea>
                        </div>
                        <small class="form-text text-muted mb-4">Enter new messages.</small>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-success btn-send-new-conversation" type="button"  data-dismiss="modal" (click)="sendMessage(false)">
                    <span>Send</span>
                    <svg viewBox="0 0 28 28">
                        <path d="M3.78963301,2.77233335 L24.8609339,12.8499121 C25.4837277,13.1477699 25.7471402,13.8941055 25.4492823,14.5168992 C25.326107,14.7744476 25.1184823,14.9820723 24.8609339,15.1052476 L3.78963301,25.1828263 C3.16683929,25.4806842 2.42050372,25.2172716 2.12264586,24.5944779 C1.99321184,24.3238431 1.96542524,24.015685 2.04435886,23.7262618 L4.15190935,15.9983421 C4.204709,15.8047375 4.36814355,15.6614577 4.56699265,15.634447 L14.7775879,14.2474874 C14.8655834,14.2349166 14.938494,14.177091 14.9721837,14.0981464 L14.9897199,14.0353553 C15.0064567,13.9181981 14.9390703,13.8084248 14.8334007,13.7671556 L14.7775879,13.7525126 L4.57894108,12.3655968 C4.38011873,12.3385589 4.21671819,12.1952832 4.16392965,12.0016992 L2.04435886,4.22889788 C1.8627142,3.56286745 2.25538645,2.87569101 2.92141688,2.69404635 C3.21084015,2.61511273 3.51899823,2.64289932 3.78963301,2.77233335 Z"></path>
                    </svg>
                </button>
            </div>

        </div>

    </div>
</div>

<!-- Modal - Add Contact -->
<div class="modal fade pickup-documents-modal" #addContactModal tabindex="-1" role="dialog" aria-labelledby="modal-company" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">

        <div class="modal-content modal-role-form">

            <div class="modal-header justify-content-center">
                <h5 class="modal-title">
                    Add Contact
                </h5>
            </div>

            <div class="modal-body">
                <div class="col-12">
                    <div class="form-group mb-4">
                        <label for="customer_name">
                            Customer Name
                        </label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </span>
                            </div>
                            <input id="customer_name" type="text" placeholder="Enter Customer Name"
                                class="form-control" name="customer_name" pattern="[A-Za-z ]+" required>
                        </div>
                        <small class="form-text text-muted mb-4">Enter customer name</small>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group mb-4">
                        <label for="inputState">
                            Customer Phone
                        </label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-phone">
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <input type="text" maxlength="10" id="edit_phone_customer" placeholder="Enter Customer Phone"
                            class="form-control" name="edit_phone_customer" [(ngModel)]="dataForm.phone_number" required>
                        </div>
                        <small class="form-text text-muted mb-4">Enter customer phone</small>
                    </div>

                </div>
            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" type="button">
                    Save
                </button>
            </div>

        </div>

    </div>
</div>
