import { Component, OnInit } from '@angular/core';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { PhoneIvrsService } from 'src/app/services/workspaces/phone-ivrs.service';
import { environment } from 'src/environments/environment';
declare const swal;
@Component({
  selector: 'app-phone-ivrs',
  templateUrl: './phone-ivrs.component.html',
  styleUrls: ['./phone-ivrs.component.scss']
})
export class PhoneIvrsComponent implements OnInit {

  public rows: Array<PhoneIvr>;
  public actualPage: number;
  public workspaceId: string;
  readonly environment = environment;

  constructor(
    private helperService: HelperService,
    private authService: AuthService,
    private phoneIvrsService: PhoneIvrsService,
  ) { }

  ngOnInit(): void {
    this.load();
    this.workspaceId = '';
  }


  private load(){
    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService.getAllCompany().then((response) => {
      this.rows = response;

      console.log('rows',this.rows);

      this.workspaceId = this.authService.workspaceSession.workspace.id
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };

  remove(id: string): void{
    swal({
      title: 'Are you sure?',
      text: "Are you sure do you want to delete this record?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result: any) => {
      if (result.value) {
        this.helperService.showLoadingMxpro360();
        this.phoneIvrsService
          .remove(id)
          .then(() => {
            this.load();
            swal(
              'Deleted!',
              'Record has been deleted.',
              'success'
            );
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
    })
  }


}
