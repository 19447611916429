import { EmailPerson } from './email-person';

export class EstimateEmail {

    id: string;
    from: EmailPerson;
    to: Array<EmailPerson>;
    cc: Array<EmailPerson>;
    subject: string;
    content: string;
    attach_quote_link: boolean;
    request_inventory_update: boolean;
    request_poa: boolean;
    request_esignature: boolean;
    token: boolean;
    employee_id: string;
    files: Array<File>;
    estimate_id: string;
    privider_id: string;
    thread_id: string;
    snippet: string;
    direction: "IN" | "OUT";
    attach_inventory: boolean;
    request_bol: boolean;
    request_token: boolean;
    request_ppw: boolean;
    ppw_document_type_id: string;
    bol_signature_requested: string;
    attach_files: boolean;
    attach_file_types: string[];
    created: number;

    constructor() {
        this.id = null;
        this.from = null;
        this.to = [];
        this.cc = [];
        this.subject = '';
        this.content = '';
        this.attach_quote_link = false;
        this.request_poa = false;
        this.request_inventory_update = false;
        this.request_esignature = false;
        this.token = false;
        this.employee_id = null;
        this.files = [];
        this.estimate_id = null;
        this.privider_id = null;
        this.thread_id = null;
        this.snippet = null;
        this.direction = 'OUT';
        this.attach_inventory = false;
        this.request_bol = false;
        this.request_token = false;
        this.request_ppw = false;
        this.ppw_document_type_id = null;
        this.bol_signature_requested = null;
        this.attach_files = null;
        this.attach_file_types = [];
        this.created = null;
    }

}
