<div class="widget widget-chart-one">
    <div class="widget-heading">
        <h5 class="">Booked By Company</h5>

    </div>

    <div class="widget-content">
        <div class="tabs tab-content">
            <div id="content_1" class="tabcontent">
                <div id="otra"></div>
            </div>
        </div>
    </div>
</div>