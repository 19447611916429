<div class="layout-spacing layout-top-spacing">
    <!-- <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center layout-spacing">
            <h3>
                CDR
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">CDR</li>
                </ol>
            </nav>
        </div>

    </div> -->




    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <h3>
                Call Detail Record
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Call Detail Record
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <!-- <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form> -->
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center top-actions-btns">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter"
                    role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span>
                </a>
            </div>
        </div>
    </div>






    <!-- filter -->
    <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">

        <div class="col-lg-12 col-md-12">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="type-filter-date text-center color-text">
                        <app-date-filter class="date-filter-top-section" [useAll]="false"
                            (onChange)="getFilterDates($event)">
                        </app-date-filter>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row col-lg-12 col-md-12 block-filter">

          <div class="col-md-6">
              <button class="btn btn-warning btn-search" (click)="load()">
                  Search
              </button>
              <div class="form-group mb-3">
                <label class="control-label"> Search Phone Number</label>
                <div class="input-group mb-1">

                  <input class="btn btn-search" type="text" placeholder="Number Phone"  [(ngModel)]="phone">
                </div>
                <small id="emailHelp1" class="form-text text-muted mb-4">
                    Phone Number
                </small>
            </div>
          </div>


          <div class="form-group col-md-4">
            <select  class="form-control" [(ngModel)]="phone">
              <option value="" disabled>Select a Phone Line</option>
              <ng-container *ngFor="let target of targets">
                  <option [value]="target.phone_number">
                      {{target.name+'  PhoneNumber: '+target.phone_number | titlecase}}
                  </option>
              </ng-container>
          </select>
        </div>


      </div> -->



        <div class="row col-lg-12 col-md-12 block-filter mb-3">
            <div class="col-12 col-md-0 col-xl-2 pl-0">

            </div>
            <div class="col-12 col-md-5 col-xl-3 pl-0">
                <label>Phone Line</label>
                <select name="phone" [(ngModel)]="phone" id="phone" class="form-control">
                    <option value="" disabled>Select a Phone Line</option>
                    <ng-container *ngFor="let target of targets">
                        <option [value]="target.phone_number">
                             {{target.name+'  PhoneNumber: '+target.phone_number | titlecase}}
                        <!--     {{target.name | titlecase}} -->
                        </option>
                    </ng-container>
                </select>
            </div>
            <div class="col-12 col-md-5 col-xl-3 pl-0">
                <label>Phone Number</label>
                <input type="text" class="form-control" placeholder="Enter Number Phone" [(ngModel)]="phone">
            </div>
            <div class="col-12 col-md-1 col-xl-3 pl-0">
                <button class=" btn btn-secondary btn-search" (click)="getFilter()">
                    Search
                </button>
            </div>
        </div>




    </div>




    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="table-responsive cdr-table">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Call Destination</th>
                                <th>Call Origin</th>
                                <th>Duration</th>
                                <th class="text-center">Recording</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let calls of data">
                                <td class="call-from">
                                    <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                        <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.02839,16.02839,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.95139,15.95139,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791ZM160,104a7.97772,7.97772,0,0,0,5.65723-2.34277L200,67.31445V88a8,8,0,0,0,16,0V48.00488c0-.26562-.01367-.53174-.04-.79687-.01074-.10742-.03125-.21094-.0459-.3169-.021-.15283-.03955-.30566-.06933-.45752-.02491-.125-.05957-.24609-.09034-.36914-.03271-.13037-.06152-.26123-.10058-.39013-.03662-.12061-.082-.23682-.124-.355-.04541-.12891-.08838-.25782-.14063-.38477-.04492-.10742-.09766-.21094-.147-.31641-.06153-.13183-.12061-.26464-.18994-.39355-.05176-.09717-.11133-.189-.167-.2832-.07666-.13037-.15087-.26172-.23584-.38819-.0664-.09863-.14062-.1914-.21093-.28662-.084-.11474-.16407-.231-.25537-.3418-.11622-.14111-.2417-.27343-.36719-.40625-.0542-.0581-.10205-.11914-.15869-.17578-.05323-.05322-.11133-.09863-.166-.1499-.13623-.12891-.27246-.25781-.417-.377-.10449-.08545-.21436-.16065-.32178-.24024-.10254-.07617-.20166-.15527-.3081-.22656-.11817-.0791-.24073-.148-.36231-.22021-.10351-.06153-.2041-.12647-.31055-.1836-.11865-.063-.24023-.1167-.36084-.17334-.11669-.05517-.23144-.11328-.35107-.16308-.1123-.04639-.22754-.084-.3418-.125-.13281-.04834-.26367-.09864-.39941-.13965-.10938-.0332-.2207-.05713-.33106-.08545-.14257-.03613-.2832-.07568-.42822-.10449-.12158-.02393-.24414-.0376-.3667-.05567-.13574-.02051-.27-.04541-.4082-.05908-.20215-.01953-.40479-.02637-.60742-.03076C208.11768,40.00781,208.05957,40,208,40H168a8,8,0,0,0,0,16h20.68555L154.34277,90.34277A8,8,0,0,0,160,104Z" />
                                    </svg>
                                    <span [ngClass]="{'selected-phone-line' : calls.from == phone}">
                                        {{calls.from}}
                                    </span>
                                </td>
                                <td class="call-to">
                                    <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                                        <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.036,16.036,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.94874,15.94874,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791ZM152.08594,97.10889c.021.15283.03955.30566.06933.45752.02491.125.05957.24609.09034.36914.03271.13037.06152.26123.10058.39013.03662.12061.082.23682.124.355.04541.12891.08838.25782.14063.38477.04492.10742.09766.21094.147.31641.06153.13183.12061.26464.18994.39355.05176.09717.11133.189.167.2832.07666.13037.15087.26172.23584.38819.0664.09863.14062.1914.21093.28662.084.11474.16407.231.25537.3418.11622.14111.2417.27343.36719.40625.0542.0581.10205.11914.15869.17578.0542.0542.11377.10058.16895.15283.13525.12744.27.25537.41406.37353.10645.08789.21826.16455.32813.24561.10009.07422.19726.15185.30078.22119.12207.08154.248.15234.37353.22656.09912.05909.19629.12159.29834.17627.124.0669.25147.12305.37842.18262.11035.05176.21875.10693.332.15381.1211.05029.24463.09082.36768.13476.12353.04444.2456.0918.37207.13038.12158.03662.24512.06347.36816.09472.13037.03272.25879.06885.3916.09522.14063.02832.28321.04492.42481.06494.1167.0166.231.03857.34912.05029.26221.02588.52588.04.78955.04h40a8,8,0,0,0,0-16H179.31445l34.34278-34.34277a8.00053,8.00053,0,0,0-11.31446-11.31446L168,76.68555V56a8,8,0,0,0-16,0V95.99512c0,.26562.01367.53174.04.79687C152.05078,96.89941,152.07129,97.00293,152.08594,97.10889Z" />
                                    </svg>
                                    <span [ngClass]="{'selected-phone-line' : calls.to == phone}">
                                        {{calls.to}}
                                    </span>
                                </td>
                                <td class="call-duration">
                                    <i class="fa-solid fa-clock"></i>
                                    <span *ngIf="calls.duration">{{calls.duration | time}}</span>
                                    <span *ngIf="!calls.duration">00:00:00</span>
                                </td>
                                <td class="text-center">
                                    <ng-container *ngIf="calls.recording">
                                        <ng-container>
                                            <audio
                                                src="{{environment.az_api.cdn}}/{{authServices.workspaceSession.id}}/dialer/recording/{{calls.recording.display_name}}"
                                                controls>
                                            </audio>
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{calls.created| dateTime }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
