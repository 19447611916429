import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CapabilityResponse } from 'src/app/entities/helpers/capability-response';
import { ListItemSelection } from 'src/app/entities/helpers/list-item-selection';
import { Capability } from 'src/app/entities/workspace/capability';
import { SearchPipe } from 'src/app/pipes/search.pipe';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { CapabilitiesService } from 'src/app/services/workspaces/capabilities.service';


@Component({
    selector: 'app-selectable-capacities-list',
    templateUrl: './selectable-capacities-list.component.html',
    styleUrls: ['./selectable-capacities-list.component.scss'],
    providers: [
        SearchPipe,
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SelectableCapacitiesListComponent
        }
    ]
})
export class SelectableCapacitiesListComponent implements OnInit, ControlValueAccessor {

    @Input() scope: "WORKSPACE" | 'COMPANY';

    private _selectedCaps: Array<String>;
    public companyCapabilities: Array<ListItemSelection<Capability>>;
    public deniedMessage: string;
    public isSelectedAll: boolean;
    public listCapabilitiesBS: BehaviorSubject<Array<ListItemSelection<Capability>>>;
    public readonly: boolean;
    public searchText: string;
    public workspaceCapabilities: Array<ListItemSelection<Capability>>;

    constructor (
        private helperService: HelperService,
        private capabilitiesService: CapabilitiesService,
        private searchPipe: SearchPipe,
        public authService: AuthService
    ) {
        this._selectedCaps = [];
        this.companyCapabilities = [];
        this.deniedMessage = 'Contact your MoverXpro 360 representative to unlock this feature!';
        this.isSelectedAll = false;
        this.listCapabilitiesBS = new BehaviorSubject<Array<ListItemSelection<Capability>>>([]);
        this.readonly = false;
        this.scope = null;
        this.searchText = null;
        this.workspaceCapabilities = [];
    }

    get selectedCaps(): Array<String> {
        return this._selectedCaps;
    }

    set selectedCaps(value: Array<String>) {
        this._selectedCaps = value;
        this.onChange(value);
        this.onTouched();
    }

    ngOnInit(): void {
        this.load();
    }

    /**
     * cuando hayan cambios sobre los atributos que recibe el componente, se ejecuta este metodo
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges) {

        // verificamos si los cambios son del scope
        if (changes.scope) {
            this.refreshView();
        }
    }

    /**
     * Carga todos los capabilities
     */
    private load(): void {
        this.helperService.showLoadingMxpro360();
        this.capabilitiesService
            .loadCapabilities()
            .then((response: CapabilityResponse) => {

                // llenamos los Capibilities de la compañia     
                this.companyCapabilities = this.getSelectableList(response.company);

                // llenamos los Capibilities del workspace   
                this.workspaceCapabilities = this.getSelectableList(response.workspace);

                // indicamos que cargue los registros que han sido seleccionados
                this.setSelectedList();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite adapatar la lista de capacidades a la lista de seleccion de capacidades
     * @param capabilities 
     * @returns 
     */
    private getSelectableList(capabilities: Array<Capability>): Array<ListItemSelection<Capability>> {

        const tmpCaps = [];

        // llenamos las de la compañia                
        for (const cap of capabilities) {
            const itemSelection = new ListItemSelection<Capability>();
            itemSelection.data = cap;
            itemSelection.selected = false;
            tmpCaps.push(itemSelection);
        }

        return tmpCaps;
    }

    /**
     * Permite obtener las capacidades (permisos) seleccionados
     * @returns 
     */
    private getSelectedList(): Array<String> {

        let caps = [];
        let capsSelected = [];

        if (this.scope == 'WORKSPACE') {
            caps = this.workspaceCapabilities;
        }

        if (this.scope == 'COMPANY') {
            caps = this.companyCapabilities;
        }

        for (const cap of caps) {
            if (cap.selected) {
                capsSelected.push(cap.data.id);
            }
        }

        return capsSelected;
    }

    /**
     * seleccionamos los que estan seleccionados
     */
    private setSelectedList() {

        // recorremos los items y establecemos en seleccionados los que estan seleccionados
        if (this.scope == 'WORKSPACE') {
            for (const index in this.workspaceCapabilities) {
                this.workspaceCapabilities[index].selected = this._selectedCaps.includes(this.workspaceCapabilities[index].data.id);
            }
        }

        // recorremos los items y establecemos en seleccionados los que estan seleccionados
        if (this.scope == 'COMPANY') {
            for (const index in this.companyCapabilities) {
                this.companyCapabilities[index].selected = this._selectedCaps.includes(this.companyCapabilities[index].data.id);
            }
        }
        
        this.refreshView(false);
    }

    onChange = (date: Array<String>) => { };

    onTouched = () => { };

    writeValue(capabilities: Array<String>): void {

        // si viene null no hacemos nada
        if (capabilities == null) {
            return;
        }

        this._selectedCaps = capabilities;
        this.setSelectedList();
    }

    registerOnChange(fn: (date: Array<String>) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.readonly = isDisabled;
    }

    notifyChanges() {
        this._selectedCaps = this.getSelectedList();
        this.onChange(this._selectedCaps);
    }

    /**
     * Permite refrescar la lista de capacidades, 
     * filstrando a partir del texto ingresado en la caja de texto de busqueda,
     * se hace por aca por que el async no dejaba refrescar la pagina cuando se hace 
     * click en una fila y a su vez hay un texto en busqueda
     */
    async refreshView(notify: boolean = true) {

        let capabilities = [];

        if (this.scope == 'WORKSPACE') {
            capabilities = this.workspaceCapabilities;
        }

        if (this.scope == 'COMPANY') {
            capabilities = this.companyCapabilities;
        }

        // actualizamos la vista en la lista
        capabilities = await this.searchPipe.transform(capabilities, this.searchText);
        this.listCapabilitiesBS.next(capabilities);

        if (notify) {
            // indicamos al componente padre que hay cambios
            this.notifyChanges();
        }

    }

    /**
     * permite escuchar cuando hagan click en un permiso de la lista, 
     * internamente busca cual es el permiso y lo checkea o lo descheckea dependiendo del estado
     * @param id 
     */
    onSelect(id: string, disabled: boolean) {

        // si es de solo lectura omitimos el evento
        if (this.readonly) {
            return;
        }

        if (disabled) {
            this.helperService.showMessageSnackbar(this.deniedMessage, 'WARNING', 'LONG');
            return;
        }

        if (this.scope == 'WORKSPACE') {
            for (const index in this.workspaceCapabilities) {
                if (this.workspaceCapabilities[index].data.id == id) {
                    this.workspaceCapabilities[index].selected = !this.workspaceCapabilities[index].selected;
                    break;
                }
            }
        }

        if (this.scope == 'COMPANY') {
            for (const index in this.companyCapabilities) {
                if (this.companyCapabilities[index].data.id == id) {
                    this.companyCapabilities[index].selected = !this.companyCapabilities[index].selected;
                    break;
                }
            }
        }


        this.refreshView();
    }

    /**
     * Permite seleccionar todos los elementos 
     */
    toggleAll() {

        // si es de solo lectura omitimos el evento
        if (this.readonly) {
            return;
        }

        // actualizamos el valor del estado
        this.isSelectedAll = !this.isSelectedAll;

        // recorremos los items y establecemos en seleccionados los items
        if (this.scope === 'WORKSPACE') {
            for (const index in this.workspaceCapabilities) {
                this.workspaceCapabilities[index].selected = this.isSelectedAll;
            }
        }

        if (this.scope === 'COMPANY') {
            for (const index in this.companyCapabilities) {
                this.companyCapabilities[index].selected = this.isSelectedAll;
            }
        }

        this.refreshView();
    }

}
