export class Payroll {

    public id: string;
    public start_period: number;
    public end_period: number;
    public total_commissions: number;
    public total_hourly: number;
    public total_overtime: number;
    public total_draw: number;
    public previous_total_draw: number;
    public total_bonuses: number;
    public total_deductions: number;
    public total_base: number;
    public total: number;
    public previous_payroll_id: string;
    public created: number;
    public updated: number;

    constructor() {    
        this.id = null;
        this.start_period = null;
        this.end_period = null;
        this.total_commissions = null;
        this.total_hourly = null;
        this.total_overtime = null;
        this.total_draw = null;
        this.previous_total_draw = null;
        this.total_bonuses = null;
        this.total_deductions = null;
        this.total_base = null;
        this.total = null;
        this.previous_payroll_id = null;
        this.created = null;
        this.updated = null;
    }
}
