import { Document } from './document';

export class StorageInvoice {
    public id: string;
    public job_id: string;
    public document: Document;
    public company_id: string;
    public total: number;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.job_id = null;
        this.document = new Document();
        this.company_id = null;
        this.total = null;
        this.created = null;
        this.updated = null;
    }
}
