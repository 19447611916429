import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { BlackoutMoveDatesService } from 'src/app/services/workspaces/blackout-move-dates.service';

declare var jQuery;
declare var swal;

@Component({
  selector: 'app-blackout-move-dates',
  templateUrl: './blackout-move-dates.component.html',
  styleUrls: ['./blackout-move-dates.component.scss']
})
export class BlackoutMoveDatesComponent implements OnInit {
  rows: any;

  constructor(
    private blackoutMoveDatesService: BlackoutMoveDatesService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
 
  }

  ngAfterViewInit(): void {
    this.load();
  }

  load(): void {
    this.helperService.showLoadingMxpro360();
    this.blackoutMoveDatesService
        .getAll()
        .then((response) => {
            this.rows = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

remove(id) {
  swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
  })
      .then((result) => {
          if (result.value) {
              this.helperService.showLoadingMxpro360();
              this.blackoutMoveDatesService
                  .remove(id)
                  .then((response) => {
                      this.load();
                      swal(
                          'Deleted!',
                          'The record has been deleted.',
                          'success'
                      );
                  })
                  .catch((error) => {
                      console.error('Error: ', error);
                  })
                  .finally(() => {
                      this.helperService.hideLoadingMxpro360();
                  });
          }
      });
}

}
