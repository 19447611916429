import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { EstimateStopPoint } from 'src/app/entities/workspace/estimate-stop-point';

@Injectable({
  providedIn: 'root'
})
export class EstimateStopPointsService {

  constructor(private http: HttpClient) {

  }

  getAll(idEstimate: string): Promise<Array<EstimateStopPoint>> {
    return this.http.get<Array<EstimateStopPoint>>(environment.api.company + '/estimates/'
    + idEstimate + '/stop-points').toPromise();
  }

  getById(idEstimate: string, idStopPoint: string) {
    return this.http.get<EstimateStopPoint>(environment.api.company + '/estimates/'
    + idEstimate + '/stop-points/' + idStopPoint).toPromise();
  }

  save(estimateStopPoint: EstimateStopPoint): Promise<any> {
    if (estimateStopPoint.id === null) {
      return this.add(estimateStopPoint);
    }
    return this.edit(estimateStopPoint);
  }

  add(estimateStopPoint: EstimateStopPoint) {
    return this.http.post(environment.api.company + '/estimates/' +
      estimateStopPoint.estimate_id + '/stop-points/by-rules', estimateStopPoint).toPromise();
  }

  edit(estimateStopPoint: EstimateStopPoint) {
    return this.http.put(environment.api.company + '/estimates/' + estimateStopPoint.estimate_id +
      '/stop-points/' + estimateStopPoint.id, estimateStopPoint).toPromise();
  }

  remove(idEstimate: string, idStopPoint: string) {
    return this.http.delete(environment.api.company + '/estimates/' +
    idEstimate + '/stop-points/' + idStopPoint).toPromise();
  }
}
