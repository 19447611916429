import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-panel-layout',
    templateUrl: './panel-layout.component.html',
    styleUrls: ['./panel-layout.component.scss']
})
export class PanelLayoutComponent implements OnInit {

    panelType: 'WORKSPACE' | 'COMPANY' | 'ACCOUNT';

    constructor(
        private activatedRoute: ActivatedRoute
    ) {
        this.panelType = null;
    }

    ngOnInit(): void {
        this.activatedRoute.data.subscribe(data => {
            this.panelType = data['panelType'];
        });
    }

    ngAfterViewInit(): void {
        window['Tawk_API'].showWidget();
    }

}
