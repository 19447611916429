import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-viewed-estimate-by-teams',
  templateUrl: './log-viewed-estimate-by-teams.component.html',
  styleUrls: ['./log-viewed-estimate-by-teams.component.scss']
})
export class LogViewedEstimateByTeamsComponent implements OnInit {

  constructor() { }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
  }

}
