import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierSetting } from 'src/app/entities/workspace/carrier-setting';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarriersSettingsService {

  constructor(private http: HttpClient) { }

  get(id): Promise<CarrierSetting> {
      return this.http.get<CarrierSetting>(environment.api.workspace + '/carriers/' + id + '/settings').toPromise();
  }

  save(dataForm: CarrierSetting, id: string): Promise<any> {
      if (dataForm.id === null) {
          return this.add(dataForm, id);
      }
      return this.add(dataForm, id);
  }

  add(dataForm: CarrierSetting, id: string) {
      return this.http.post(environment.api.workspace + '/carriers/' + id + '/settings', dataForm).toPromise();
  }

  edit(dataForm: CarrierSetting, id: string) {
      return this.http.put(environment.api.workspace + '/carriers/' + id + '/settings/', dataForm).toPromise();
  }

  remove(id: string, subId: string) {
      return this.http.delete(environment.api.workspace + '/carriers/' + id + '/settings/' + subId).toPromise();
  }
}
