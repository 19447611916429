<!-- <div class="widget widget-table-two">

    <div class="widget-heading">
        <h5 class="text-danger">Jobs Viewed by Employees</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-danger"><div class="th-content text-light">Job Code</div></th>
                        <th class="bg-danger"><div class="th-content th-heading text-light">Employee</div></th>
                        <th class="bg-danger"><div class="th-content th-heading text-light">Created</div></th>
                        <th class="bg-danger"><div class="th-content text-light">Link</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let theLastTenTobsTheyViewedWithaLink of theLastTenTobsTheyViewedWithaLinks">
                        <td><div class="td-content customer-name">{{theLastTenTobsTheyViewedWithaLink.job}}</div></td>
                        <td><div class="td-content customer-name">{{theLastTenTobsTheyViewedWithaLink.employee}}</div></td>
                        <td><div class="td-content product-brand">{{theLastTenTobsTheyViewedWithaLink.created | date}}</div></td>
                        <td><div class="td-content badge badge-danger text-light cursor-pointer">Go to</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->


<div class="widget widget-activity-five">

    <div class="widget-heading">
        <h5 class="">Jobs Viewed by Employees</h5>
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
    </div>

    <div class="widget-content">

        <div class="w-shadow-top"></div>

        <div class="mt-container mx-auto">

            
            <div class="timeline-line" *ngFor="let theLastTenTobsTheyViewedWithaLink of theLastTenTobsTheyViewedWithaLinks">
                
                <div class="item-timeline timeline-new">
                    <div class="t-dot">
                        <div class="t-secondary">
                            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                        </div>
                    </div>
                    <div class="t-content t-job-info">
                        <div class="t-uppercontent">
                            <h5>{{theLastTenTobsTheyViewedWithaLink.job}}</h5>
                        </div>
                        <p>{{theLastTenTobsTheyViewedWithaLink.employee}}</p>
                    </div>
                    <div class="t-date d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        <p>{{theLastTenTobsTheyViewedWithaLink.created | date}}</p>
                    </div>
                </div>

                                  
            </div>    

        </div>

        <div class="w-shadow-bottom"></div>
    </div>
</div>
