import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import {
    EmailTemplatesService
} from 'src/app/services/companies/email-templates.service';
import { HelperService } from 'src/app/services/helper.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare const jQuery;

@Component({
    selector: 'app-email-templates-view',
    templateUrl: './email-templates-view.component.html',
    styleUrls: ['./email-templates-view.component.scss']
})
export class EstimatesEmailTemplatesViewComponent implements OnInit {

    row: EmailTemplate;

    constructor(
        private estimatesEmailTemplatesService: EmailTemplatesService,
        private currentRoute: ActivatedRoute,
        public helperService: HelperService
    ) {
        this.row = new EmailTemplate();
    }

    ngOnInit(): void {
      
    }

    
    ngAfterViewInit(): void{
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });   
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesEmailTemplatesService
            .getById(id)
            .then((response) => {
                this.row = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
