import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Component, OnInit } from '@angular/core';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { HelperService } from 'src/app/services/helper.service';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { ProfitReportService } from 'src/app/services/companies/profit-report.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profit-loss',
  templateUrl: './profit-loss.component.html',
  styleUrls: ['./profit-loss.component.scss']
})
export class ProfitLossComponent implements OnInit {

  public betweenFilter: BetweenDates;
  public filterData: FilterData;
  public paginator: Paginator;
  public totalAgentPay: number;
  public totalCommLabor: number;
  public totalDeliveryCost: number;
  public totalExpenses: number;
  public totalJob: number;
  public totalJPAP: number;
  public totalMoniesCollected: number;
  public totalMoniesDue: number;
  public totalProitLoss: number;
  public totalRefunds: number;
  public totalRows: number;

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private profitReportService: ProfitReportService
  ) {
    this.betweenFilter = new BetweenDates();
    this.filterData = new FilterData();
    this.paginator = new Paginator();
    this.totalAgentPay = 0;
    this.totalCommLabor = 0;
    this.totalDeliveryCost = 0;
    this.totalExpenses = 0;
    this.totalJob = 0;
    this.totalJPAP = 0;
    this.totalMoniesCollected = 0;
    this.totalMoniesDue = 0;
    this.totalProitLoss = 0;
    this.totalRefunds = 0;
    this.totalRows = 0;
  }

  ngOnInit(): void {
    
  }
  
  getFilterDates(dataReturn: BetweenDates) {
    this.betweenFilter = dataReturn;
    this.loadData(true);
  }

  loadData(firstPage: boolean) {
    this.helperService.showLoadingMxpro360();
    this.paginator.paginator.start = this.betweenFilter.dateStart;
    this.paginator.paginator.end = this.betweenFilter.dateEnd;
    if (firstPage) {
      this.paginator.paginator.page = 1;
    }
    this.profitReportService.get(this.paginator, this.filterData)
    .then(response => {
      this.paginator.data = response.data;
      this.paginator.paginator.total = response.paginator.total;
      this.setTotals();
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  setTotals() {
    this.totalRows = 0;
    this.totalJob = 0;
    this.totalAgentPay = 0;
    this.totalJPAP = 0;
    this.totalExpenses = 0;
    this.totalCommLabor = 0;
    this.totalDeliveryCost = 0;
    this.totalRefunds = 0;
    this.totalProitLoss = 0;
    this.totalMoniesCollected = 0;
    this.totalMoniesDue = 0;
    for (let row of this.paginator.data) {
      this.totalRows += 1;
      this.totalJob += row.total_job;
      this.totalAgentPay += row.total_agent_pay;
      this.totalJPAP += row.jpap;
      this.totalExpenses += row.total_expenses;
      this.totalCommLabor += row.total_commissions_labor;
      this.totalDeliveryCost += row.total_delivery_cost;
      this.totalRefunds += row.total_refunds;
      this.totalProitLoss += row.profit_loss;
      this.totalMoniesCollected += row.total_monies_collected;
      this.totalMoniesDue += row.total_monies_due;
    }
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.loadData(false);
    }
  }

  openNewTab(jobId: string) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(jobId, 'estimate');
  }

  exportCSV() {
    const params = "Authorization=" + this.authService.session.token +
      "&use_paginator=1" +
      '&export_data=1' +
      "&page=" + this.paginator.paginator.page +
      "&per_page=" + this.paginator.paginator.per_page +
      "&start=" + this.paginator.paginator.start +
      "&end=" + this.paginator.paginator.end +
      "&order_by=" + this.paginator.paginator.order_by +
      "&order_type=" + this.paginator.paginator.order_type +
      "&pickup_state=" + this.filterData.pickup_state +
      "&delivery_state=" + this.filterData.delivery_state +
      "&service=" + this.filterData.service +
      "&filter_date_for=" + this.filterData.filter_date_for +
      "&allocation=" + this.filterData.allocation +
      "&carrier=" + this.filterData.carrier + 
      '&workspace_id=' + this.authService.workspaceSession.workspace.id +
      '&auth_employee_id=' + this.authService.workspaceSession.employee.id;
    const exportUrl = environment.api.companies + this.authService.workspaceSession.company.id + '/profit-report?' + params;
        
    window.open(exportUrl, '_blank');
  }
}
