import { Component, OnInit } from '@angular/core';
import { ClockLocations } from 'src/app/entities/companies/clock-locations';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-payroll-clock-locations',
  templateUrl: './payroll-clock-locations.component.html',
  styleUrls: ['./payroll-clock-locations.component.scss']
})
export class PayrollClockLocationsComponent implements OnInit {

  public clockLocations: ClockLocations[];
  constructor(
    private helperService: HelperService,
    private payrollTimeClockService: PayrollTimeClockService
  ) { 
    this.clockLocations = [];
  }

  ngOnInit(): void {
    this.getClockLocations();
  }

  private getClockLocations() {
    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService
      .getAllClockLocations()
      .then((response) => {
        this.clockLocations = response;
        console.log('getClockLocations this.clockLocations => ', this.clockLocations);
        
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
