export class EstimateFreights {
    public id: string;
    public equipment: string;
    public all_in_rate: number;
    public mode: string;
    public weight: number;
    public length: number;
    public width: number;
    public height: number;
    public pieces: number;
    public pallets: number;
    public cubic_feet: number;
    public load_options: string;
    public other_equipment_needs: string;
    public special_information: string;
    public commodity: string;
    public load_number: string;
    public load_tracking_required: boolean;
    public estimate_id;

    /**
     * permite instanciar la clase
     */
     constructor() {
        this.id = null;
        this.equipment = null;
        this.all_in_rate = null;
        this.mode = null;
        this.weight = null;
        this.length = null;
        this.width = null;
        this.height = null;
        this.pieces = null;
        this.pallets = null;
        this.cubic_feet = null;
        this.load_options = null;
        this.other_equipment_needs = null;
        this.special_information = null;
        this.commodity = null;
        this.load_number = null;
        this.load_tracking_required = false;
        this.estimate_id = null;
    }
}
