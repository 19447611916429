import { ConstantsMessages } from 'src/app/constants-messages';
import { TicketView } from 'src/app/entities/workspace/ticket-view';
import { TicketsService } from 'src/app/services/companies/tickets.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EstimateTicketsService } from 'src/app/services/companies/estimate-tickets.service';
import { HelperService } from 'src/app/services/helper.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';

declare const jQuery;

@Component({
  selector: 'app-estimates-tickets',
  templateUrl: './estimates-tickets.component.html',
  styleUrls: ['./estimates-tickets.component.scss']
})
export class EstimatesTicketsComponent implements OnInit {

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  rows: Array<TicketView>;
  rowsClosed: Array<TicketView>;
  actualPage = 1;
  estimateId: string;
  estimate: Estimate;
  typeTickets;

  constructor(
    private estimateTicketsService: EstimateTicketsService,
    private ticketService: TicketsService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private estimatesService: EstimatesService,
    private ticketsTypesService: TicketsTypesService,
    ) {
    this.rows = [];
    this.rowsClosed = [];
    this.estimateId = '';
    this.estimate = new Estimate();
    this.typeTickets = [];
  }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void{
     // verficamos si se esta editando
     this.currentRoute.parent.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
          this.estimateId = params.id;
          this.loadTickets(params.id);
          this.loadClosedTickets(params.id);
          this.loadEstimate(params.id);
          this.loadTicketsType();
        }
      });  
  }

  loadEstimate(estimateId: string): void {
    this.estimatesService.getById(estimateId).then((estimate) => {
      this.estimate = estimate;
    })
  }

  loadTickets(id) {
    this.helperService.showLoadingMxpro360();
    this.estimateTicketsService
      .getAllByEstimate(id)
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
          console.error('error', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
  }

  loadClosedTickets(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimateTicketsService
      .getAllClosedByEstimate(id)
      .then((response) => {
        this.rowsClosed = response;
      })
      .catch((error) => {
          console.error('error', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
  }

  removeTickets(id) {
    this.helperService.showLoadingMxpro360();
    this.ticketService
      .remove(id)
      .then(() => {
        this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
        this.loadTickets(this.estimateId);
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        console.error(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  private loadTicketsType() {
    this.helperService.showLoadingMxpro360();
    this.ticketsTypesService
      .getAll()
      .then((response) => {
        this.typeTickets = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
