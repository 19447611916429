<div class="layout-top-spacing">

    <!-- header -->
    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                New Estimate
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/estimates">
                            Estimates
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        New
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    
    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate autocomplete="off">
        <div class="row layout-spacing">

            <!-- Content -->
            <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">            
                <div class="work-experience layout-spacing ">                        
                    <div class="widget-content widget-content-area">            

                        <div class="justify-content-between widget-heading">
                            <h3 class="title-content-area">
                                Customer
                            </h3>
                            <a title="Search Customer" appCanUserCompany="ESTIMATE_CONTACT_CREATE" class="tooltipmxp btn-call-action"
                            (click)='openContactsModal()'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            </a>
                        </div>

                        <div class="form-group mb-1">
                            <span class="asterisk">*</span>
                            <label class="control-label">Name</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg></span>
                                </div>
                                <input (ngModelChange)="validateForm()" type="text" [(ngModel)]="customer.name"
                                    name="wd_name" class="form-control" required pattern="^[a-zA-Z ]+">
                            </div>

                            <small id="nameCustomer" class="form-text text-muted mb-4">
                                Customer Name (Only Letters).
                            </small>
                        </div>

                        <div class="form-group mb-1">
                            <span class="asterisk">*</span>
                            <label class="control-label">Last Name</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg></span>
                                </div>
                                <input type="text" [(ngModel)]="customer.last_name" name="wd_last_name"
                                    class="form-control" required pattern="^[a-zA-Z ]+">
                            </div>

                            <small id="nameCustomer" class="form-text text-muted mb-4">
                                Customer Last Name (Only Letters).
                            </small>
                        </div>

                        <div class="form-group mb-1">
                            <span class="asterisk">*</span>
                            <label class="control-label">Email</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-mail">
                                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                            <polyline points="22,6 12,13 2,6"></polyline>
                                        </svg>
                                    </span>
                                </div>
                                <input (ngModelChange)="validateForm()" type="email"
                                    [(ngModel)]="customer.email" name="wd_email" class="form-control" required>
                            </div>

                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Customer Email.
                            </small>
                        </div>

                        <div class="form-group mb-1">
                            <span class="asterisk">*</span>
                            <label class="control-label">Phone</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-phone">
                                            <path
                                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                            </path>
                                        </svg></span>
                                </div>
                                <input (ngModelChange)="validateForm()" type="text" maxlength="10" onlyNumbers
                                    [(ngModel)]="customer.phone" name="wd_phone" class="form-control" required>
                            </div>

                            <small id="emailHelp1" class="form-text text-muted mb-1">
                                Customer Phone.
                            </small>
                        </div>
                        
                    </div>            
                </div>            
            </div>
        
            <div class="col-xl-6 col-lg-6 col-md-7 col-sm-12 layout-top-spacing">            
                <div class="work-experience layout-spacing" style="height: 100%;">                        
                    <div class="widget-content widget-content-area" style="height: 100%;">   

                        <h3 class="">
                            Moving Info
                        </h3>

                        <div class="row">

                            <div class="col-6">

                                <div class="form-group mb-1">
                                    <span class="asterisk">*</span>
                                    <label class="control-label">Service</label>
                                    <select name="wp_service" [(ngModel)]="estimate.service" name="estimate.service"
                                        class="form-control" required>
                                        <option selected value="LONG">Long Distance</option>
                                        <option value="LOCAL">Local Distance</option>
                                        <option value="AUTO_TRANSPORT">Auto Transport</option>
                                        <option value="FREIGHT">Freight</option>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select a Type of Service.</small>
                                </div>
        
                                <div class="form-group mb-1" *ngIf="estimate.service != 'AUTO_TRANSPORT' && estimate.service != 'FREIGHT'">
                                    <span class="asterisk">*</span>
                                    <label for="inputState">Move Size</label>
                                    <select (ngModelChange)="validateForm()" id="inputState"
                                        [(ngModel)]="estimate.volume.move_size" name="dataForm.lead.move_size"
                                        class="form-control" required>
                                        <option selected value="STUDIO">STUDIO</option>
                                        <option value="LARGE_STUDIO">LARGE STUDIO</option>
                                        <option value="ONE_BEDROOM">ONE BEDROOM</option>
                                        <option value="TWO_BEDROOM">TWO BEDROOM</option>
                                        <option value="THREE_BEDROOM">THREE BEDROOM</option>
                                        <option value="FOUR_BEDROOM">FOUR BEDROOM</option>
                                        <option value="FIVE_BEDROOM">FIVE BEDROOM</option>
                                        <option value="SIX_BEDROOM">SIX BEDROOM</option>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Select a Move Size.
                                    </small>
                                </div>
        
                                <div class="form-group mb-1">
                                    <label for="inputState">Category</label>
                                    <select (ngModelChange)="validateForm()" id="inputState"
                                        [(ngModel)]="estimate.category" name="category_estimate"
                                        class="form-control" required>
                                        <option selected value="OPEN">Open</option>
                                        <option value="HOT">Hot</option>
                                        <option value="WARM">Warm</option>
                                        <option value="COLD">Cold</option>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select a Category Type.</small>
                                </div>
        
                                <div class="form-group mb-1">
                                    <label for="inputState">Estimate Origin</label>
                                    <select (ngModelChange)="validateForm()" id="inputState"
                                        [(ngModel)]="advertiser" name="advertiser"
                                        class="form-control">
                                        <option value="">Select...</option>
                                        <option *ngFor="let rowAdvertiser of advertisers" [value]="rowAdvertiser.id">{{rowAdvertiser.name}}</option>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-1">Select the Advertiser of This Estimate.</small>
                                </div>
    
                            </div>
    
                            <div class="col-6">
    
                                <div class="form-group pickup-date-new-estimate mb-1">
                                    <span class="asterisk">*</span>
                                    <label for="inputState">Pickup Date</label>
                                    <app-date-range-picker [placeholder]="'Not Assigned'"
                                        (onChange)="changePickUp($event)"
                                        [valueStart]="estimate.pickup.range_start"
                                        [valueEnd]="estimate.pickup.range_end">
                                    </app-date-range-picker>

                                    <small id="nameCustomer" class="form-text text-muted mb-4">
                                        Select a Date.
                                    </small>
                                </div>
                              
                                <div class="form-group mb-1">
                                    <label class="control-label">Set Ref Number</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg _ngcontent-ipq-c161="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle _ngcontent-ipq-c161="" cx="12" cy="8" r="7"></circle><polyline _ngcontent-ipq-c161="" points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                            </span>
                                        </div>
                                        <input (ngModelChange)="validateForm()" type="text"
                                            [(ngModel)]="estimate.reference" name="reference" class="form-control">
                                    </div>
        
                                    <small id="emailHelp1" class="form-text text-muted mb-4">
                                        Set Ref Number.
                                    </small>
                                </div>
        
                                <div class="form-group mb-1">
                                    <label for="inputState">Broker</label>
                                    <select
                                        [(ngModel)]="estimate.broker_id" name="broker_id"
                                        class="form-control">
                                        <ng-container *ngFor="let rowBroker of brokers" >
                                            <option  *ngIf="rowBroker.broker" [value]="rowBroker.id">{{rowBroker.name}}</option>
                                        </ng-container>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select the Broker of This Estimate.</small>
                                </div>
        
                                <div class="form-group mb-1">
                                    <label class="control-label">Broker Pay</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg _ngcontent-ipq-c161="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle _ngcontent-ipq-c161="" cx="12" cy="8" r="7"></circle><polyline _ngcontent-ipq-c161="" points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                            </span>
                                        </div>
                                        <input (ngModelChange)="validateForm()" type="number"
                                            [(ngModel)]="estimate.broker_pay" name="broker_pay" class="form-control">
                                    </div>
        
                                    <small id="emailHelp1" class="form-text text-muted mb-1">
                                        Set Broker Pay.
                                    </small>
                                </div>
    
                            </div>

                        </div>

                    </div>            
                </div>            
            </div>

            <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">            
                <div class="work-experience layout-spacing ">                        
                    <div class="widget-content widget-content-area container-search-address">
        
                        <h3 class="">
                            From Address
                        </h3>

                        <app-search-address [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'"
                            [addressFull]="true" (onChange)="setFrom($event)">
                        </app-search-address>

                    </div>            
                </div>            
            </div>

            <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">            
                <div class="work-experience layout-spacing ">                        
                    <div class="widget-content widget-content-area">
        
                        <h3 class="">
                            To Address
                        </h3>

                        <app-search-address [isNotAutoTransport]="estimate.service != 'AUTO_TRANSPORT'"
                            [addressFull]="true" (onChange)="setTo($event)">
                        </app-search-address>

                    </div>            
                </div>            
            </div>

            <div class="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing mb-4">  
                <div class="create-estimate">
                    <button class="btn btn-create-estimate" [disabled]="!validForm" type="submit">
                        <div class="svg-wrapper-1">
                            <div class="svg-wrapper">                                
                                <i class="fas fa-paste"></i>
                                <span>Create Estimate</span>
                            </div>
                        </div>                       
                    </button>
                </div>
            </div>

        </div>
    </form>
</div>

<!-- modal de contacts -->
<div class="modal fade contactsModal" #contactsModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title ml-3">Search a Customer</h5>
            </div>

            <div class="modal-body">
                <div class="compose-box">

                    <div class="compose-content content-select">
                        

                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 filtered-list-search layout-spacing align-self-center">
                                <form class="form-inline my-2 my-lg-0">
                                    <div class="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                        <input type="text" name="filterSearch" [(ngModel)]="searchCustomer" (keyup)="loadCustomers()"
                                            class="form-control product-search" id="input-search" placeholder="Filter...">
                                    </div>
                                </form>
                            </div>
                        </div>

                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>
                                        Full Name
                                    </th>
                                    <th class="text-center">
                                        Email
                                    </th>
                                    <th class="text-center">
                                        Phone
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let item of customers | search:searchCustomer | async">
                                    <tr (click)="setNewContct(item)">
                                        <td>
                                            {{item.name | titlecase}} {{item.last_name | titlecase}}
                                        </td>
                                        <td class="text-center">
                                            {{item.email}}
                                        </td>
                                        <td class="text-center">
                                            {{item.phone | phone:'US'}}
                                        </td>
                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn" (click)="closeContactsModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                    Discard
                </button>
            </div>
        </div>
    </div>
</div>
