export class PaypalSetting {
    id: string;
    account: string;
    client_id: string;
    production: boolean;

    constructor() {
        this.id = null;
        this.account = null;
        this.client_id = null;
        this.production = false;
    }
}
