import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CommissionSetting } from "src/app/entities/workspace/commission-setting";

@Injectable({
  providedIn: 'root'
})
export class CommissionSettingsService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<CommissionSetting>> {
        return this.http.get<Array<CommissionSetting>>(environment.api.company + '/commissions-settings',  { params: { "full-view": "1" } }).toPromise();
    }

    getById(id: string) {
        return this.http.get<Array<CommissionSetting>>(environment.api.company + '/commissions-settings/' + id).toPromise();
    }

    save(dataForm: CommissionSetting): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: CommissionSetting) {
        return this.http.post(environment.api.company + '/commissions-settings', dataForm).toPromise();
    }

    edit(dataForm: CommissionSetting) {
        return this.http.put(environment.api.company + '/commissions-settings/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/commissions-settings/' + id).toPromise();
    }
}
