export class infoEstimateLabelQrView {
    public companyName: string;
    public companyPhone: string;
    public jobCode: string;
    public fromState: string;
    public fromCity: string;
    public fromZipCode: string;
    public toState: string;
    public toCity: string;
    public toZipCode: string;
    public to: string;

    constructor() {
        this.companyName = null;
        this.companyPhone = null;
        this.jobCode = null;
        this.fromState = null;
        this.fromCity = null;
        this.fromZipCode = null;
        this.toState = null;
        this.toCity = null;
        this.toZipCode = null;
        this.to = null;
    }
}
