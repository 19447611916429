import { JobAdditionalCharge } from 'src/app/entities/workspace/job-additional-charge';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class JobsAdditionalChargesService {

    constructor(private http: HttpClient) { }

    getAll(id: string): Promise<Array<JobAdditionalCharge>> {
        return this.http.get<Array<JobAdditionalCharge>>(environment.api.company + '/jobs/' + id + '/additional-charges').toPromise();
    }

    save(estimateId: string, dataForm: JobAdditionalCharge) {
        if (dataForm.id) {
            return this.edit(estimateId, dataForm);
        }else {
            return this.add(estimateId, dataForm);
        }
    }

    add(id: string, dataForm: JobAdditionalCharge) {
        return this.http.post(environment.api.company + '/jobs/' + id + '/additional-charges', dataForm).toPromise();
    }

    edit(id: string, dataForm: JobAdditionalCharge) {
        return this.http.put(environment.api.company + '/jobs/' + id + '/additional-charges/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string, idDelete: string) {
        return this.http.delete(environment.api.company + '/jobs/' + id + '/additional-charges/' + idDelete).toPromise();
    }

    updateAddress(idJob: string, dataForm) {
        return this.http.put(environment.api.company + '/jobs/' + idJob + '/additional-charges-delivery', dataForm).toPromise();
    }

}
