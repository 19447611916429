import { SaleCommission } from 'src/app/entities/workspace/sale-commission';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SalesCommissionsService {

  constructor(
    private http: HttpClient
  ) { }

  createPayment(id: string) {
    return this.http.put(environment.api.company + '/sales-commissions/' + id + '/pay', {}).toPromise();
  }

  createVoid(id: string, remarks: string) {
    return this.http.put(environment.api.company + '/sales-commissions/' + id + '/void', {remarks}).toPromise();
  }

  createPayrollBalances(id: string, remark:string) {
    return this.http.post(environment.api.company + '/sales-commissions/' + id + '/deduction', {remark}).toPromise();
  }

  waiveDeduction(id: string) {
    return this.http.post(environment.api.company + '/sales-commissions/' + id + '/waive-deduction', {}).toPromise();
  }

  applyCancellation(id: string) {
    return this.http.post(environment.api.company + '/sales-commissions/' + id + '/apply-cancellation', {}).toPromise();
  }

  createRefund(id: string, remarks: string) {
    return this.http.put(environment.api.company + '/sales-commissions/' + id + '/refund', {remarks}).toPromise();
  }

  createPendingRefund(id: string, remarks: string) {
    return this.http.put(environment.api.company + '/sales-commissions/' + id + '/start-refund', {remarks}).toPromise();
  }

  getSaleCommision(id: string){
    return this.http.get<SaleCommission>(environment.api.company + '/sales-commissions/' + id).toPromise();
  }

  getSalesCommissionByPaymentId(payment_id: string) {
    return this.http.get<Array<SaleCommission>>(environment.api.company + '/sales-commissions/' + payment_id + '/list').toPromise();
  }

  addSalesCommissionByPaymentId(payment_id: string) {
    return this.http.post(environment.api.company + '/sales-commissions', {payment_id}).toPromise();
  }
  deleteSalesCommissionByPaymentId(sale_commission_id: string) {
    return this.http.delete(environment.api.company + '/sales-commissions/' + sale_commission_id + '/delete').toPromise();
  }

  getSaleCommisionByEstimate(id: string){
    return this.http.get<Array<SaleCommission>>(environment.api.company + '/sales-commissions/' + id).toPromise();
  }
}
