import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GoogleAccount } from '../entities/admin/google-account';
import { HelperService } from './helper.service';

declare const jQuery;
declare const gapi;

@Injectable({
    providedIn: 'root'
})
export class GoogleAuthService {

    // permite disparar cambios en la session
    onChange: EventEmitter<null>;

    // variable para guardar en ella la informacion del usuario proveniente de google
    googleAccount: GoogleAccount;

    // guardamos la instancia de la sesion de google aca
    public googleAuth;


    constructor(
        private helperService: HelperService
    ) {
        this.onChange = new EventEmitter();
        this.googleAuth = null;
        this.googleAccount = null;
    }

    /**
     * Verifica si la libreria ya esta lista para usar
     * @returns Boolean
     */
    isLoaded() {
        return this.googleAuth !== null;
    }

    /**
     * Metodo que queda en espera a la carga de la libreria de google
     */
    load() {
        console.log('este es el que carga el gapi')
        this.helperService.showLoadingMxpro360();
        const that = this;
        setTimeout(() => {
            do {
                if (typeof gapi !== "undefined") {
                    // inicializamos el Auth de google
                    that.internalLoad();
                    that.helperService.hideLoadingMxpro360();
                    break;
                }
            } while (typeof gapi == "undefined")
        }, 2000);
    }

    /**
     * Permite a nuestro usuario iniciar session con google
     */
    authenticate() {
        this.helperService.showLoadingMxpro360();
        return new Promise((resolve, reject) => {
            this.googleAuth
                .grantOfflineAccess()
                .then((response) => {
                    // obtenemos los datos del usuario logueado y los procesamos adentro del metodo
                    this.onLoginSuccess(response['code']);
                    resolve(true);
                },
                    /**
                     * Callback que se ejecuta cuando un usuario inicia sesion en gsuite y presenta un error
                     * @param error 
                     */
                    (error) => {
                        this.helperService.hideLoadingMxpro360();
                        reject(error);
                    })
                .catch((error) => {
                    console.error('Error : ', error);
                    this.helperService.hideLoadingMxpro360();
                    reject(error);
                });
        })
    }

    /**
     * Permite cerrar session al usuario de google
     */
    disconnect() {
        if (this.googleAuth) {
            this.googleAuth.disconnect();
        }
    }

    /**
     * Permite inicializar la instancia de google que nos permitira loguear al usuario con nuestro sistema
     */
    private internalLoad() {
        gapi.load('auth2', () => {
            gapi.auth2
                .init(
                    {
                        client_id: environment.google_api.client_id,
                        scope: "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly email profile"
                    }
                )
                .then(auth => {
                    
                    this.googleAuth = auth;

                    // Listen for sign-in state changes.
                    this.googleAuth.isSignedIn.listen((val) => {
                        
                    });

                    // Listen for changes to current user.
                    this.googleAuth.currentUser.listen((user) => {
                        // si ya hay un usuario logueado, cargamos sus datos
                        // this.loadCurrentUser();
                    });

                    // si ya hay un usuario logueado, cargamos sus datos
                    // this.loadCurrentUser();

                    // oculatmos el loading, esto es temporalmente
                    this.helperService.hideLoadingMxpro360();
                })
                .catch((error) => {
                    console.error('Error : ', error);
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

    /**
     * Verifica si el usuario esta logueado y carga la informacion de este
     */
    private loadCurrentUser() {

        // si ya hay un usuario logueado, cargamos sus datos
        if (this.googleAuth.isSignedIn.get() == true) {

            // cagamos la informacion del usuario logueado
            this.onLoginSuccess();
        } else {

            // reseteamos la variable
            this.googleAccount = null;

            // oculatmos el loading
            this.helperService.hideLoadingMxpro360();
        }
    }

    public refresAuthSessions(): void {

        // cargamos la instancia
        const googleCurrentUser = this.googleAuth.currentUser.get();

        // colectamos datos del usuario y los agregamos al dato que se enviará al web service
        const BasicProfile = googleCurrentUser.getBasicProfile();
        googleCurrentUser.reloadAuthResponse();
        const AuthResponse = googleCurrentUser.getAuthResponse();

        // establecemos las datos de la session obtenida en google 
        this.googleAccount = new GoogleAccount();
        this.googleAccount.name = BasicProfile.getName();
        this.googleAccount.photo = BasicProfile.getImageUrl();
        this.googleAccount.email = BasicProfile.getEmail();
        this.googleAccount.g_user_id = BasicProfile.getId();
        
        //this.googleAccount.token.authResponse = AuthResponse;
        //this.googleAccount.token.id_token = AuthResponse['id_token'];


        this.onChange.emit();
    }

    /**
     * Procesamos la respuesta de inicio de session suceful de google 
     * @param googleUser 
     */
    private onLoginSuccess(codeOfflineAccess: string = null) {

        // cargamos la instancia
        const googleCurrentUser = this.googleAuth.currentUser.get();

        // colectamos datos del usuario y los agregamos al dato que se enviará al web service
        const BasicProfile = googleCurrentUser.getBasicProfile();
        const AuthResponse = googleCurrentUser.getAuthResponse();

        // establecemos las datos de la session obtenida en google 
        this.googleAccount = new GoogleAccount();
        this.googleAccount.name = BasicProfile.getName();
        this.googleAccount.photo = BasicProfile.getImageUrl();
        this.googleAccount.email = BasicProfile.getEmail();
        this.googleAccount.g_user_id = BasicProfile.getId();
        
        /*
            this.googleAccount.token = {
                'authResponse': AuthResponse,
                'id_token': AuthResponse['id_token'],
                'code': codeOfflineAccess // se establece el code para poderlo reusar en el backend y asi usar los servicios de gmail api
            };
        */

        // notificamos el cambio en la sessión
        this.onChange.emit();

        this.helperService.hideLoadingMxpro360();
    }


}
