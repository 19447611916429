import { AutoLocalTariff } from "./auto-local-tariff";

export class WorkspaceSetting {
    public id: string;
    public auto_local_tariff: AutoLocalTariff;
    public block_duplicate_leads: boolean;
    public cover_stop_fees: boolean;
    public lock_the_rate: boolean;
    public reset_discount_after_inventory_changes: boolean;
    public delivery_date_required_to_book: boolean;
    public show_pricing_local_estimate_pdf: boolean;
    public show_weight_local_estimate_pdf: boolean;

    constructor() {
        this.id = null;
        this.auto_local_tariff = new AutoLocalTariff();
        this.block_duplicate_leads = true;
        this.cover_stop_fees = true;
        this.lock_the_rate = false;
        this.reset_discount_after_inventory_changes = false;
        this.delivery_date_required_to_book = false;
        this.show_pricing_local_estimate_pdf = true;
        this.show_weight_local_estimate_pdf = true;
    }
}
