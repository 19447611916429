<div class="row layout-top-spacing">
    <div class="col-lg-12">
        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

            <div class="row">

                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                    <h3>
                        <span *ngIf="dataForm.id == null">
                            New
                        </span>
                        <span *ngIf="dataForm.id != null">
                            Update
                        </span> SMS Templates
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>Dashboard</a></li>
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/sms-templates">SMS Templates</a></li>
                            <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                            <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button class="btn btn-main-action" type="submit">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <!-- header -->

            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">
                    <div class="row">
                        <div class="col-12">

                            <div class="form-group mb-1">
                                <label class="control-label">Template Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">SMS Templates Name.</small>
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Message</label>
                                <textarea type="text" [(ngModel)]="dataForm.message" name="message" class="form-control"></textarea>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Write your message.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>
</div>
