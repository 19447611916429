export class EstimateFollowUp {

    public id: string;
    public estimate_id: string;
    public employee_id: string;
    public qa_appointment
    public date: number;
    public remarks: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.estimate_id = null;
        this.employee_id = null;
        this.qa_appointment = false;
        this.date = null;
        this.remarks = null;
        this.created = null;
        this.updated = null;
    }
}
