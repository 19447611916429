import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PackingMaterial } from 'src/app/entities/workspace/packing-material';


@Injectable({
    providedIn: 'root'
})
export class MaterialsService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<PackingMaterial>> {
        return this.http.get<Array<PackingMaterial>>(environment.api.workspace + '/packing-materials').toPromise();
    }

    getById(id: string) {
        return this.http.get<PackingMaterial>(environment.api.workspace + '/packing-materials/' + id).toPromise();
    }

    save(material: PackingMaterial): Promise<any> {
        if (material.id === null) {
            return this.add(material);
        }
        return this.edit(material);
    }

    add(material: PackingMaterial) {
        return this.http.post(environment.api.workspace + '/packing-materials', material).toPromise();
    }

    edit(material: PackingMaterial) {
        return this.http.put(environment.api.workspace + '/packing-materials/' + material.id, material).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/packing-materials/' + id).toPromise();
    }
}
