import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageNotes } from 'src/app/entities/companies/storage-notes';
import { JobStorage } from 'src/app/entities/workspace/job-storage';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobStorageService {

    constructor(private http: HttpClient) { }

    getById(id: string) {
        return this.http.get<Array<JobStorage>>(environment.api.company + '/job-storages/' + id).toPromise();
    }

    getDateOut(jobId: string) {
        return this.http.get<any>(environment.api.company + '/job-storages/' + jobId + '/storage-date-out').toPromise();
    }

    save(dataForm: JobStorage, storage_company: StorageCompany): Promise<any> {
        const data = {...dataForm, storage_company};
        if (dataForm.id === null) {
            return this.add(data);
        }
        return this.edit(data);
    }

    add(dataForm: any) {
        return this.http.post(environment.api.company + '/job-storages', dataForm).toPromise();
    }

    edit(dataForm: any) {
        let id = dataForm.id;
        if (dataForm._id) {
            id = dataForm._id.$oid;
        }
        return this.http.put(environment.api.company + '/job-storages/' + id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/job-storages/' + id).toPromise();
    }

    getStorageNotesById(id: string) {
        return this.http.get<Array<StorageNotes>>(environment.api.company + '/job-storages/' + id + '/storage-notes').toPromise();
    }

    addStorageNotes(id: string, dataForm: StorageNotes) {
        return this.http.post(environment.api.company + '/job-storages/' + id + '/storage-notes', dataForm).toPromise();
    }

}
