import { ConstantsMessages } from 'src/app/constants-messages';
import { DocumentType } from 'src/app/entities/workspace/document-type';
import { DocumentsTypesService } from 'src/app/services/companies/documents-types.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HelperService } from 'src/app/services/helper.service';
import { AuthService } from 'src/app/services/auth.service';
import { Meta } from 'src/app/entities/global/meta';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-documents-types-form',
    templateUrl: './documents-types-form.component.html',
    styleUrls: ['./documents-types-form.component.scss']
})
export class DocumentsTypesFormComponent implements OnInit {
    dataForm: DocumentType;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    arrayMetasBillOFLanding: Array<Meta>;
    arrayMetasEstimate: Array<Meta>;
    arrayNameMetasBillOFLanding: Array<string>;
    arrayMetasLocalBillOFLanding: Array<Meta>;
    arrayNameMetasLocalBillOFLanding: Array<string>;
    public request_on_pickup: 'YES' | 'NO';

    constructor(
        private documentsTypes: DocumentsTypesService,
        private currentRoute: ActivatedRoute,
        public authService: AuthService,
        private helperService: HelperService,
    ) {
        this.dataForm = new DocumentType();
        this.dataForm.usage_context = 'ESTIMATES';
        this.request_on_pickup = 'NO';
        this.arrayMetasBillOFLanding = [
            {
                id: null,
                key: 'PAYMENT_TERMS',
                value: ''
            },
            {
                id: null,
                key: 'TOTAL_MINIMUM_CHARGES',
                value: ''
            },
            {
                id: null,
                key: 'DISCLOSURES_AGREED_TERMS_DELIVERY',
                value: ''
            },
            {
                id: null,
                key: 'AGREED_PICKUP_DELIVERY_PERIODS_NOTICE',
                value: ''
            },
            {
                id: null,
                key: '3_DAYS_BEFORE_PICKUP_SIGN',
                value: ''
            },
            {
                id: null,
                key: 'STORAGE_CHARGES',
                value: ''
            },
            {
                id: null,
                key: 'DELIVERY_ACKNOWLEDGEMENT',
                value: ''
            }
        ];
        this.arrayMetasLocalBillOFLanding = [
            {
                id: null,
                key: 'DECLARATION_OF_VALUE',
                value: ''
            },
            {
                id: null,
                key: 'RELEASED_VALUE_PROTECTION',
                value: ''
            },
            {
                id: null,
                key: 'REPLACEMENT_COST_PROTECTION',
                value: ''
            },
            {
                id: null,
                key: 'MANDATORY_RECEIPT',
                value: ''
            }
        ];
        this.arrayMetasEstimate = [
            {
                id: null,
                key: 'INSURANCE_TERMS',
                value: ''
            },
            {
                id: null,
                key: 'LOCAL_MOVING_TERMS',
                value: ''
            },
            {
                id: null,
                key: 'NO_BINDING',
                value: ''
            },
            {
                id: null,
                key: 'NO_EXCEED',
                value: ''
            }
        ];

        this.arrayNameMetasBillOFLanding = [
            'FORM / TERMS OF PAYMENT',
            'TOTAL ESTIMATED CHARGES AND MINIMUM CHARGES',
            'DISCLOSURES AND AGREED TERMS FOR DELIVERY',
            'NOTICE OF AGREED PICK UP AND DELIVERY PERIODS',
            'SIGN AT LEAST 3 DAYS BEFORE PICKUP DATE',
            'STORAGE CHARGES',
            'DELIVERY ACKNOWLEDGEMENT'
        ];

        this.arrayNameMetasLocalBillOFLanding = [
            'DECLARATION OF VALUE',
            'RELEASED VALUE PROTECTION',
            'REPLACEMENT COST PROTECTION',
            'MANDATORY RECEIPT'
        ];

    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void{
         // verficamos si se esta editando
         this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });

        this.helperService.ckeditor = 'documentsTypesForm';
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.documentsTypes
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                this.request_on_pickup = this.dataForm.request_on_pickup == true ? 'YES' : 'NO';
                if (this.dataForm.metas) {
                    if (this.dataForm.usage_context == 'BILL_OF_LADING'){
                        this.arrayMetasBillOFLanding = this.dataForm.metas;
                    } else if (this.dataForm.usage_context == 'LOCAL_BOL'){
                        this.arrayMetasLocalBillOFLanding = this.dataForm.metas;
                    } else if (this.dataForm.usage_context == 'ESTIMATES') {
                        this.arrayMetasEstimate = this.dataForm.metas;
                    }
                }

                this.changeContext();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    save() {
        this.helperService.showLoadingMxpro360();
        this.dataForm.request_on_pickup = this.request_on_pickup == 'YES';
        this.documentsTypes
            .save(this.dataForm)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/documents-types');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeContext(){
        if (this.dataForm.usage_context == 'BILL_OF_LADING'){
            this.dataForm.metas = this.arrayMetasBillOFLanding;
        } else if (this.dataForm.usage_context == 'ESTIMATES') {
            this.dataForm.metas = this.arrayMetasEstimate;
        } else if (this.dataForm.usage_context == 'LOCAL_BOL') {
            this.dataForm.metas = this.arrayMetasLocalBillOFLanding;
        } else {
            this.dataForm.metas = [];
        }
        console.log(this.dataForm.metas);
    }

    showPreBol(){
        window.open(environment.api.workspace + '/companies/'+ this.authService.workspaceSession.company.id +'/documents-types/' + this.dataForm.id + '/document-bol-preview?Authorization=' +
        this.authService.session.token + '&workspace_id=' +  this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
    }

}
