import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TicketType } from 'src/app/entities/workspace/ticket-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketsTypesService {
  constructor(private http: HttpClient) {

  }

  getAll(): Promise<Array<TicketType>> {
      return this.http.get<Array<TicketType>>(environment.api.company + '/tickets-types').toPromise();
  }

  getById(id: string) {
      return this.http.get<TicketType>(environment.api.company + '/tickets-types/' + id).toPromise();
  }

  save(dataForm: TicketType): Promise<any> {
      if (dataForm.id === null) {
          return this.add(dataForm);
      }
      return this.edit(dataForm);
  }

  add(dataForm: TicketType) {
      return this.http.post(environment.api.company + '/tickets-types', dataForm).toPromise();
  }

  edit(dataForm: TicketType) {
      return this.http.put(environment.api.company + '/tickets-types/' + dataForm.id, dataForm).toPromise();
  }

  remove(id: string) {
      return this.http.delete(environment.api.company + '/tickets-types/' + id).toPromise();
  }
}
