<div class="widget widget-table-two widget-container">

    <div class="widget-heading">
        <h5>Open Tickets by Priority</h5>
    </div>

    <div class="widget-content" style="margin-top: 60px; margin-bottom: 38px;">
       
        <div class="widget-content">
            <div id="chart-45" class=""></div>
        </div>
        
    </div>
</div>