import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EstimateEmail } from 'src/app/entities/workspace/estimate-email';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailsService {

    constructor(private http: HttpClient) { }

    /**getAll(): Promise<Array<EstimateEmail>> {
        return this.http.get<Array<EstimateEmail>>(environment.api.company + '/email-templates').toPromise();
    }*/

    getById(estimateId: string, id: string): Promise<Array<EstimateEmail>> {
        return this.http.get<Array<EstimateEmail>>(environment.api.company + '/estimates/' + estimateId + '/emails/' + id).toPromise();
    }

    getByEstimateId(estimateId: string): Promise<Array<EstimateEmail>> {
        return this.http.get<Array<EstimateEmail>>(environment.api.company + '/estimates/' + estimateId + '/emails').toPromise();
    }

    send(dataForm: EstimateEmail) {

        // creamos la información del post
        const postData = {
            estimateEmail: dataForm
        };
        return this.http.post(environment.api.company + '/estimates/' + dataForm.estimate_id + '/emails', postData).toPromise();
    }
    sendInfoMessage(dataForm: EstimateEmail) {

        // creamos la información del post
        const postData = {
            estimateEmail: dataForm,
            sendFromInfo: 1
        };
        return this.http.post(environment.api.company + '/estimates/' + dataForm.estimate_id + '/emails', postData).toPromise();
    }
    
    emails_by_company(dataForm: EstimateEmail, id: string) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/emails_by_company', dataForm).toPromise();
    }

    getTread(googleUser: any, threadId: string) {
        return this.http.post(environment.api.company + '/estimates/emails_gmail/thread/' + threadId, googleUser).toPromise();
    }

    
    // edit(dataForm: EmailTemplate) {
    //     return this.http.put(environment.api.company + '/email-templates/' + dataForm.id, dataForm).toPromise();
    // }

    // remove(id: string) {
    //     return this.http.delete(environment.api.company + '/email-templates/' + id).toPromise();
    // }
}
