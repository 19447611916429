
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Volume } from 'src/app/entities/workspace/volume';
import { VolumesService } from 'src/app/services/workspaces/volumes.service';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';



declare const jQuery;

@Component({
    selector: 'app-volumes-form',
    templateUrl: './volumes-form.component.html',
    styleUrls: ['./volumes-form.component.scss']
})
export class VolumesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: Volume;

    constructor(
        private volumesService: VolumesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.dataForm = new Volume();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }


    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.volumesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.dataForm.tariff_version_id = this.tariffVersionFactory.getTariffVersionId();
        this.volumesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/settings/volumes');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }



}
