import { Component, OnInit } from '@angular/core';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneQueue } from 'src/app/entities/workspace/phone-queue';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneIvrsService } from 'src/app/services/workspaces/phone-ivrs.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';

declare const swal;


@Component({
  selector: 'app-phone-lines',
  templateUrl: './phone-lines.component.html',
  styleUrls: ['./phone-lines.component.scss']
})
export class PhoneLinesComponent implements OnInit {

  public rows: Array<PhoneLine>;
  public actualPage: number;
  public ringGroups: PhoneRingGroup[];
  public queues: PhoneQueue[];
  public ivrs: PhoneIvr[];
  public employees: EmployeeView[];
  public targets;

  constructor(
    private helperService: HelperService,
    private phoneLineService: PhoneLinesService,
    private phoneIvrsService: PhoneIvrsService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private employeesService: EmployeesService,
    private phoneQueuesService: PhoneQueuesService,
    private phoneExtensionsService : PhoneExtensionsService,

    ) {
    this.actualPage = 1;
   }

  ngOnInit(): void {
    this.load();
    this.loadRingGroups();
    this.loadIvrs();
    this.getAllEmployees();
    this.loadQueues();
  }

  ngAfterViewInit() {
  }

  private load(){
    this.helperService.showLoadingMxpro360();
        this.phoneLineService.getAll().then((response) => {
        console.log('response',response);
        this.rows = response;
for (const line of this.rows) {

  if (line.destination.target === 'EXTENSION') {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getById(line.destination.target_id)
        .then((response) => {
          this.targets = response;
          (line as any).targets = this.targets;
          console.log('line testeossss',line);
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });

   };

}

    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };




  remove(id: string): void{
    swal({
      title: 'Are you sure?',
      text: "Are you sure do you want to delete this record?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result: any) => {
      if (result.value) {
        this.helperService.showLoadingMxpro360();
        this.phoneLineService
          .remove(id)
          .then(() => {
            this.load();
            swal(
              'Deleted!',
              'Record has been deleted.',
              'success'
            );
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
    })
  }


  private loadRingGroups() {

    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService
        .getAll()
        .then((response) => {
          this.ringGroups = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  private loadQueues() {

    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService
        .getAll()
        .then((response) => {
          this.queues = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  private getAllEmployees(): void{
    this.helperService.showLoadingMxpro360();
    this.employeesService.getAll().then((response) => {
      this.employees = response;
    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };


  private loadIvrs(){
    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService.getAllCompany().then((response) => {
      this.ivrs = response;
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };


}
