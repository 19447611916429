import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PhoneExtensionView } from '../entities/workspace/phone-extension-view';
import { PhoneSystemCallDTO } from '../entities/workspace/phone-system-call-dto';

@Injectable({
    providedIn: 'root'
})
export class PhoneSystemService {



    constructor(private http: HttpClient) {

    }

    /**
     * permite inicializar el dialer
     * 
     * @param phone_extension_id 
     * @param sockedId 
     * @returns 
     */
    initDialer(phone_extension_id: string, sockedId: string) {
        return this.http.post<PhoneExtensionView>(environment.api.apiRtcPrivate + '/initialize-dialer', { phone_extension_id: phone_extension_id, socket_id: sockedId }).toPromise();
    }


    /**
     * permite cerrar el dialer
     * @param phone_extension_id 
     * @returns 
     */
    closeDialer(phone_extension_id: string) {
        return this.http.post(environment.api.apiRtcPrivate + '/close-dialer', { phone_extension_id: phone_extension_id }).toPromise();
    }

    /**
     * permite colgar una llamada
     * 
     * @param callid 
     * @returns 
     */
    hangup(callid) {
        return this.http.put(environment.api.apiRtcPrivate + '/hangup', { callId: callid }).toPromise();
    }

    /**
     * permite hacer una llamada
     * 
     * @param phoneNumber 
     * @param extensionId 
     * @returns 
     */
    makeCall(phoneNumber: string, extensionId: string): Promise<PhoneSystemCallDTO> {
        return this.http.post<PhoneSystemCallDTO>(environment.api.apiRtcPrivate + '/make-call', { PhoneNumber: phoneNumber, phone_extension_id: extensionId }).toPromise();
    }


    /**
     * permite poner una llamada en espera
     * 
     * @param callId 
     * @returns 
     */
    holdCall(callId) {
        return this.http.post(environment.api.apiRtcPublic + '/hold-call', { callId: callId }).toPromise();
    }

    /**
     * permite reanudar una llamada que estaba en espera
     *  
     * @param callId 
     * @returns 
     */
    holdCallResume(callId) {
        return this.http.post(environment.api.apiRtcPrivate + '/resume-hold-call', { callId: callId }).toPromise();
    }

    /**
     * permite transferir una llamada a otra numero de telefono
     * 
     * @param callId 
     * @param phoneNumberCurrent 
     * @param phoneNumberTo 
     * @returns 
     */
    transferCall(callId: string, phoneNumberCurrent: string, phoneNumberTo: string) {
        return this.http.post(environment.api.apiRtcPrivate + '/transfer-call', { callId: callId, phoneNumberCurrent: phoneNumberCurrent, phoneNumberTo: '+1' + phoneNumberTo }).toPromise();
    }

    /**
     * permite transferir una llamada al voicemail
     * 
     * @param callid 
     * @returns 
     */
    transferCallToVoicemail(callid: string) {
        return this.http.post(environment.api.apiRtcPublic + '/voicemail', { callId:  callid}).toPromise();
    }
}
