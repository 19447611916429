import { File } from '../global/file';

export class TicketRemark {
    id: string;
    private: boolean;
    ticket_id: string;
    employee_id: string;
    customer_id: string;
    remarks: string;
    files: Array<File>;
    created: number;
    update: number;

    constructor() {
        this.id = null;
        this.private = false;
        this.ticket_id = null;
        this.employee_id = null;
        this.customer_id = null;
        this.remarks = null;
        this.files = [];
        this.created = null;
        this.update = null;
    }

}
