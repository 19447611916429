<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Follow Ups
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Follow Ups
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">
                    <div class="row">
                        <div class="col" [appCanUserCompany]="['FOLLOW_UPS_EMPLOYEE_FILTER']">
                            <strong>Employee: </strong>
                            <select name="move_type" id="move_type" [(ngModel)]="employee_id" class="form-control">
                                <option value="ALL" selected="selected">All</option>
                                <option *ngFor="let employe of employees" value="{{employe.id}}">
                                    {{employe.name}}</option>
                            </select>
                        </div>

                        <div class="col">

                            <strong>Category: </strong>
                            <select name="category" id="category" [(ngModel)]="category" class="form-control">
                                <option value="ALL">All</option>
                                <option value="NEW">New</option>
                                <option value="HOT">Hot</option>
                                <option value="WARM">Warm</option>
                                <option value="COLD">Cold</option>
                                <option value="OPEN">Open</option>
                            </select>
                        </div>

                        <div class="col">

                            <strong>Filter and Sort By: </strong>
                            <select name="order_by" id="order_by" [(ngModel)]="orderBy" class="form-control">
                                <option value="FOLLOWUPDATE">Follow Up Date</option>
                                <option value="MOVEDATE">Move Date</option>
                            </select>
                        </div>
                        <div class="col">

                            <strong>Appointment Type: </strong>
                            <select name="appointment_type" id="appointment_type" [(ngModel)]="appointmentType" class="form-control">
                                <option value="ALL">All Types</option>
                                <option value="FOLLOW_UP">Follow Up</option>
                                <option value="QA_APPOINTMENT">QA Appointment</option>
                            </select>
                        </div>
                        <div class="col margin-button">
                            <button class="btn btn-warning" (click)="showEventsCalendar()">
                                Filter
                            </button>
                        </div>
                    </div>
                    <div class="text-center margin-buttons">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" [ngClass]="{'active-filter' : numberTab == 1 }"
                                class="btn btn-light btn-lg" (click)="changeTab(1)">
                                Calendar
                            </button>
                            <button type="button" [ngClass]="{'active-filter' : numberTab == 2 }"
                                class="btn btn-light btn-lg" (click)="changeTab(2)">
                                List
                            </button>
                        </div>
                    </div>
                    <div class="tab-content" id="simpletabContent">
                        <div class="tab-pane fade show active" id="home" [hidden]="numberTab == 2">
                            <div #divCalendar id="calendar" class="margin-calendar"></div>
                        </div>

                        <div class="tab-pane fade show active" id="contact" [hidden]="numberTab == 1">
                            <div class="row" *ngIf="followUpReminders.length == 0">
                                <h4 style="color: red;text-align: center;width: 100%;">
                                    Select a Category please
                                </h4>
                            </div>
                            <div class="row" *ngIf="followUpReminders.length > 0">

                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                                    <div class="user-profile layout-spacing">
                                        <div class="widget-content widget-content-area">

                                            <div class="row padding-table">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <div class="widget-content searchable-container list">
                                                        <div class="searchable-items list">
                                                            <div class="items items-header-section">
                                                                <div class="item-content">
                                                                    <div
                                                                        class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                                        <h4>
                                                                            Customer
                                                                        </h4>
                                                                    </div>
                                                                    <div
                                                                        class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <h4 style="margin-left: 3px;">
                                                                            Service
                                                                        </h4>
                                                                    </div>
                                                                    <div
                                                                        class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <h4 style="margin-left: 3px;">
                                                                            Description
                                                                        </h4>
                                                                    </div>
                                                                    <div
                                                                        class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <h4 style="margin-left: 3px;">
                                                                            Estimate
                                                                        </h4>
                                                                    </div>
                                                                    <div
                                                                        class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <h4 style="margin-left: 3px;">
                                                                            Date
                                                                        </h4>
                                                                    </div>
                                                                    <div
                                                                        class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                                                        <h4 style="margin-left: 3px;">
                                                                            Category
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="items cursor-pointer"
                                                                *ngFor="let followUpReminder of followUpReminders | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                                                <div class="item-content information-col" role="menu">
                                                                    <div class="user-profile col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                                        <div class="user-meta-info info">
                                                                            <p class="user-name">
                                                                                {{followUpReminder.customer.last_name | filterReplace}}
                                                                            </p>
                                                                            <p class="user-work" style="padding-left: 30px;">
                                                                                {{followUpReminder.customer.name | filterReplace}}
                                                                            </p>
                                                                            <p class="user-work cursor-pointer" (click)="copyText(followUpReminder.customer.phone, 'text')">
                                                                                <i class="far fa-copy p-2"></i> {{followUpReminder.customer.phone | phone:'US'}}
                                                                            </p>
                                                                            <p class="user-work cursor-pointer" (click)="copyText(followUpReminder.customer.email, 'text')">
                                                                                <i class="far fa-copy p-2"></i> {{followUpReminder.customer.email}}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div (click)="openNewTab(followUpReminder.estimate.id)"
                                                                        class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <div class="user-meta-info info">
                                                                            <p class="usr-email-addr">
                                                                                <strong>Type: </strong>
                                                                                {{followUpReminder.estimate.service | filterReplace}}
                                                                            </p>
                                                                            <p class="usr-email-addr">
                                                                                <strong>From:
                                                                                </strong>{{followUpReminder.estimate.pickup.address.state}}
                                                                                {{followUpReminder.estimate.pickup.address.zip_code}}
                                                                            </p>
                                                                            <p class="usr-email-addr">
                                                                                <strong>To: </strong>
                                                                                {{followUpReminder.estimate.delivery.address.state}}
                                                                                {{followUpReminder.estimate.delivery.address.zip_code}}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div (click)="openNewTab(followUpReminder.estimate.id)"
                                                                        class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <div class="user-meta-info info">
                                                                            <div
                                                                                *ngIf="followUpReminder.estimate.service != 'AUTO_TRANSPORT'">
                                                                                <p class="usr-location">
                                                                                    <strong>Lbs: </strong>
                                                                                    {{followUpReminder.estimate.volume.libs | number}}
                                                                                </p>
                                                                                <p class="usr-location">
                                                                                    <strong *ngIf="followUpReminder.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                                                                    <strong *ngIf="followUpReminder.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                                                                    {{followUpReminder.estimate.volume.cubic_feets_selled | number}}
                                                                                </p>
                                                                            </div>

                                                                            <p class="usr-location">
                                                                                <strong>Miles:
                                                                                </strong>{{followUpReminder.estimate.volume.miles | number}}
                                                                            </p>
                                                                            <p class="usr-location">
                                                                                <strong>Estimated Date:
                                                                                </strong>{{followUpReminder.estimate.created | date}}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div (click)="openNewTab(followUpReminder.estimate.id)"
                                                                        class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <div class="user-meta-info info">
                                                                            <p class="usr-ph-no">
                                                                                <strong>Number: </strong>
                                                                                {{followUpReminder.estimate.document.code}}
                                                                            </p>
                                                                            <p class="usr-ph-no">
                                                                                <strong>Estimate: </strong>
                                                                                {{followUpReminder.estimate.total | currency}}
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div (click)="openNewTab(followUpReminder.estimate.id)"
                                                                        class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                                        <div class="user-meta-info info">
                                                                            <p class="usr-email-addr"
                                                                                *ngIf="followUpReminder.estimate.pickup.range_start">
                                                                                <strong class="badge badge-danger"
                                                                                    style="margin-right: 10px;"> Move
                                                                                    Date:
                                                                                </strong>{{followUpReminder.estimate.pickup.range_start | date}}
                                                                                to
                                                                                {{followUpReminder.estimate.pickup.range_end | date}}
                                                                            </p>
                                                                            <p class="usr-location">
                                                                                <strong class="badge badge-success"
                                                                                    style="margin-right: 10px;">Estimated
                                                                                    Date:
                                                                                </strong>{{followUpReminder.estimate.created | date}}
                                                                            </p>
                                                                            <p class="usr-location" *ngIf="followUpReminder.follow_up_reminder">
                                                                                <strong class="badge badge-primary"
                                                                                    style="margin-right: 10px;">Follow
                                                                                    Date:
                                                                                </strong>{{followUpReminder.follow_up_reminder.date | date}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                                                        <ng-container>
                                                                            <select name="deadModal"
                                                                                [(ngModel)]="followUpReminder.estimate.category"
                                                                                class="form-control select-modal select-category"
                                                                                (change)="changeCategory(followUpReminder.estimate.id, $event.target.value)">
                                                                                <option
                                                                                    [selected]="followUpReminder.estimate.category == 'NEW'"
                                                                                    value="NEW">New</option>
                                                                                <option
                                                                                    [selected]="followUpReminder.estimate.category == 'OPEN'"
                                                                                    value="OPEN">Open</option>
                                                                                <option
                                                                                    [selected]="followUpReminder.estimate.category == 'HOT'"
                                                                                    value="HOT">Hot</option>
                                                                                <option
                                                                                    [selected]="followUpReminder.estimate.category == 'WARM'"
                                                                                    value="WARM">Warm</option>
                                                                                <option
                                                                                    [selected]="followUpReminder.estimate.category == 'COLD'"
                                                                                    value="COLD">Cold</option>
                                                                            </select>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <pagination-controls
                                                                class="paginating-container pagination-solid"
                                                                (pageChange)="actualPage = $event">
                                                            </pagination-controls>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- The Modal -->
    <div id="addEventsModal" class="modal animated fadeIn">

        <div class="modal-dialog modal-dialog-centered">

            <!-- Modal content -->
            <div class="modal-content">

                <div class="modal-body">

                    <span class="close">&times;</span>

                    <div class="add-edit-event-box">
                        <div class="add-edit-event-content">
                            <h5 class="add-event-title modal-title">Add Events</h5>
                            <h5 class="edit-event-title modal-title">Edit Events</h5>

                            <form class="">

                                <div class="row">

                                    <div class="col-md-12">
                                        <label for="start-date" class="">Event Title:</label>
                                        <div class="d-flex event-title">
                                            <input id="write-e" type="text" placeholder="Enter Title"
                                                class="form-control" name="task">
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-12">
                                        <div class="form-group start-date">
                                            <label for="start-date" class="">From:</label>
                                            <div class="d-flex">
                                                <input id="start-date" placeholder="Start Date" class="form-control"
                                                    type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-12">
                                        <div class="form-group end-date">
                                            <label for="end-date" class="">To:</label>
                                            <div class="d-flex">
                                                <input id="end-date" placeholder="End Date" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="start-date" class="">Event Description:</label>
                                        <div class="d-flex event-description">
                                            <textarea id="taskdescription" placeholder="Enter Description" rows="3"
                                                class="form-control" name="taskdescription"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="event-badge">
                                            <p class="">Badge:</p>

                                            <div class="d-sm-flex d-block">
                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-primary">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-primary">
                                                        <span class="new-control-indicator"></span>Work
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-warning">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-warning">
                                                        <span class="new-control-indicator"></span>Travel
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-success">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-success">
                                                        <span class="new-control-indicator"></span>Personal
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-danger">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-danger">
                                                        <span class="new-control-indicator"></span>Important
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button id="discard" class="btn" data-dismiss="modal">Discard</button>
                    <button id="add-e" class="btn">Add Task</button>
                    <button id="edit-event" class="btn">Save</button>
                </div>

            </div>

        </div>

    </div>

</div>