import { LocalTariffSetting } from "../admin/local-tariff-setting";
import { EstimateWorker } from "./estimate-worker";

export class EstimateLocalTariff {
    public id: string;
    public local_tariff_setting: LocalTariffSetting;
    public flat_rate_active: boolean;
    public labor_workers_tariff: EstimateWorker;
    public travel_workers_tariff: EstimateWorker;
    public calculated_labor_hours: number;
    public travel_charge_base_on: 'MILES' | 'TIMES';
    public quote_mode: 'MANUAL' | 'AUTO';

    constructor() {
        this.id = null;
        this.local_tariff_setting = new LocalTariffSetting();
        this.flat_rate_active = false;
        this.labor_workers_tariff = new EstimateWorker();
        this.travel_workers_tariff = new EstimateWorker();
        this.calculated_labor_hours = null;
        this.travel_charge_base_on = null;
        this.quote_mode = 'AUTO';
    }
}
