<div class="container">
    <div class="navbar row">
        <div class="col py-2">
            <img src="/assets/img/logo_moverxpro360.png" class="logo-brand">
        </div>
        <div class="col">

        </div>

        <ul class="container-account navbar-item">
            <li class="nav-item">
                <div class="account m-a">
                    <span class="account-email" *ngIf="account !== null">
                        {{account.email}}
                    </span>
                </div>
            </li>
            <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38px"
                        version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 53 53"
                        style="enable-background:new 0 0 53 53;" xml:space="preserve">
                        <path
                            style="fill:#E7ECED;"
                            d="M18.613,41.552l-7.907,4.313c-0.464,0.253-0.881,0.564-1.269,0.903C14.047,50.655,19.998,53,26.5,53  c6.454,0,12.367-2.31,16.964-6.144c-0.424-0.358-0.884-0.68-1.394-0.934l-8.467-4.233c-1.094-0.547-1.785-1.665-1.785-2.888v-3.322  c0.238-0.271,0.51-0.619,0.801-1.03c1.154-1.63,2.027-3.423,2.632-5.304c1.086-0.335,1.886-1.338,1.886-2.53v-3.546  c0-0.78-0.347-1.477-0.886-1.965v-5.126c0,0,1.053-7.977-9.75-7.977s-9.75,7.977-9.75,7.977v5.126  c-0.54,0.488-0.886,1.185-0.886,1.965v3.546c0,0.934,0.491,1.756,1.226,2.231c0.886,3.857,3.206,6.633,3.206,6.633v3.24  C20.296,39.899,19.65,40.986,18.613,41.552z" />
                            <g>
                                <path
                                    style="fill: #27697d;"
                                    d="M26.953,0.004C12.32-0.246,0.254,11.414,0.004,26.047C-0.138,34.344,3.56,41.801,9.448,46.76   c0.385-0.336,0.798-0.644,1.257-0.894l7.907-4.313c1.037-0.566,1.683-1.653,1.683-2.835v-3.24c0,0-2.321-2.776-3.206-6.633   c-0.734-0.475-1.226-1.296-1.226-2.231v-3.546c0-0.78,0.347-1.477,0.886-1.965v-5.126c0,0-1.053-7.977,9.75-7.977   s9.75,7.977,9.75,7.977v5.126c0.54,0.488,0.886,1.185,0.886,1.965v3.546c0,1.192-0.8,2.195-1.886,2.53   c-0.605,1.881-1.478,3.674-2.632,5.304c-0.291,0.411-0.563,0.759-0.801,1.03V38.8c0,1.223,0.691,2.342,1.785,2.888l8.467,4.233   c0.508,0.254,0.967,0.575,1.39,0.932c5.71-4.762,9.399-11.882,9.536-19.9C53.246,12.32,41.587,0.254,26.953,0.004z" />
                            </g>
                    </svg>
                </a>
                <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                    <div class="dropdown-item">
                        <a routerLink="/my-profile/update-profile">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            {{'UPDATE_PROFILE' | translate}}
                        </a>
                    </div>
                    <div class="dropdown-item">
                        <a class="cursor-pointer" (click)="logout()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-log-out">
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            {{'LOG_OUT' | translate}}
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="container main-container">
    <div class="row">
        <div class="col">
            <h1 class="title-workspace">
                <strong translate>
                    WHAT_IS_A_WORKSPACE
                </strong>
            </h1>
            <br>
            <p class="text-workspace" translate>
                WORKSPACE_DESCRIPTION
            </p>
            <br>
            <br>
            <p class="text-workspace">
                Start creating your own work space now!
            </p>
            <br>
            <button type="button" class="btn button-create button-create btn-warning" data-toggle="modal" (click)="openModalWorkspace()" >
                Create New Workspace
            </button>
        </div>
        <div class="col text-center">
            <img class="space-man" src="/assets/img/spaceman.png">
        </div>
        <div class="col">

            <h3 class="text-center myworkspace" translate>
                MY_WORKSPACES
            </h3>

            <div class="row m-0 wrapper-workspaces">
                <div class="col-md-12" *ngFor="let ac of workspaces" (click)="onSelectWorkspace(ac)">
                    <div class="item-workspace mb-2">
                        <div class="logo-workspace">
                            <span class="text-logo-wordspace">{{ac.workspace.name | avatarTitle}}</span>
                        </div>
                        <div class="space-item-workspace">
                            <div class="title-item-workspace">{{ac.workspace.name }}</div>
                            <div class="description-item-workspace">{{'CREATED' | translate}}: {{ac.workspace.created | date}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="footer">
    <img src="/assets/img/rocket.png">
    <p class="text-center">
        Copyright © 2024 All Rights Reserved | Developed by <a style="color: #ffffff;" href="https://moverxpro360.com"
            target="_blank"> MoverXpro 360 LLC </a>
    </p>
</div>

<!-- Modal New Workspace-->
<div class="modal fade" id="new-workspace" #ModalWorkspaceForm tabindex="-1" role="dialog" aria-labelledby="new-workspace"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h5 class="modal-title" id="new-workspace">New Workspace</h5>
            </div>
            <div class="modal-body">

                <div class="col-md-12">
                    <label for="inputState">
                        Workspace
                    </label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-book">
                                    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                                    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                                </svg></span>
                        </div>
                        <input type="text" class="form-control" name="workspace_name" placeholder="Name"
                            [(ngModel)]="workspace.name">
                    </div>

                    <small id="emailHelp1" class="form-text text-muted mb-4">Workspace Name</small>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn button-cancel" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button type="submit" data-dismiss="modal" class="btn btn-warning button-save"
                    (click)="saveWorkspace()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal Select Emplooyee-->
<div class="modal fade" id="select-employee" #ModalSelectEmployee tabindex="-1" role="dialog" aria-labelledby="select-employee-title"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h5 class="modal-title" id="select-employee-title">
                    <ng-container *ngIf="employees.length > 0">
                        Select an Employee of
                    </ng-container>

                    <ng-container *ngIf="employees.length == 0">
                        No Employee Assigned for
                    </ng-container>

                    <ng-container *ngIf="workspace.id !== null">
                        <strong> {{workspace.name}} </strong>
                    </ng-container>
                </h5>
            </div>
            <div class="modal-body">

                <div class="card card-employees" style="width: 100%;" *ngIf="employees.length > 0">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item cursor-pointer" *ngFor="let user of employees" (click)="onSelectUser(user)">
                            <span [ngClass]="{'employee-disabled': user.ip_allowed === false}">{{user.name}}</span>
                        </li>
                    </ul>
                </div>

                <div *ngIf="employees.length == 0">
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" style="color: black; fill: orange; margin: 20px;" width="96" height="96" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                    </div>
                    <p>
                        you currently do not have an employee profile assigned, contact the workspace administrator to complete the workspace access by assigning you an employee profile.
                    </p>
                </div>

            </div>
            <!--
                <div class="modal-footer ">
                    <div class="text-center">
                        <button class="btn button-cancel" data-dismiss="modal">
                            <i class="flaticon-cancel-12"></i>
                            Cancel
                        </button>
                    </div>

                </div>
            -->
        </div>
    </div>
</div>


<!-- Modal Select Emplooyee-->
<div class="modal fade" id="modal-terms-conditions"  #ModalConditions tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="select-employee-title"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style=" max-width: 740px;">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h5 class="modal-title" id="select-employee-title">
                    Our Terms and Conditions have changed
                </h5>
            </div>
            <div class="modal-body">
                <div class="conditions-modal">
                    <div [innerHTML]="termsConditions"></div>
                </div>



            </div>

                <div class="modal-footer ">
                    <div class="text-center">
                        <div class="text-instruction">
                            By clicking on the yellow button below, you accept the new terms and conditions
                            <br>
                            expressed for the use of moverxpro360
                            <br>
                        </div>
                        <button class="btn button-save" (click)="acceptTerms()">
                            <i class="flaticon-cancel-12"></i>
                            I Accept The New Terms And Conditions
                        </button>
                    </div>

                </div>

        </div>
    </div>
</div>
