import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneCall } from 'src/app/entities/companies/phone-call';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneCallsService {

  constructor(private http: HttpClient) {

  }

  getAll(filterFor: any, phone: string, paginator?: Paginator): Promise<Paginator> {
    const params: any = {
      "full-view": "1",
      "use_paginator": 1,
      "page": paginator.paginator?.page,
      "per_page": paginator.paginator?.per_page,
      "start_time": paginator.paginator?.start,
      "end_time": paginator.paginator?.end,
      "order_by": "created",
      "order_type": paginator.paginator?.order_type,
      "filter_for": filterFor,
      "phone": phone,
    };
    return this.http.get<Paginator>(environment.api.company + '/phone-calls', { params: params }).toPromise();
  }


  getAllVoiceMail(paginator: Paginator, filterFor: any): Promise<Paginator> {
    const params: any = {
      "full-view": "1",
      "use_paginator": 1,
      "page": paginator.paginator.page,
      "per_page": paginator.paginator.per_page,
      "start_time": paginator.paginator.start,
      "end_time": paginator.paginator.end,
      "order_by": "created",
      "order_type": paginator.paginator.order_type,
      "filter_for": filterFor
    };
    return this.http.get<Paginator>(environment.api.company + '/voice-mail', { params: params }).toPromise();
  }

  add(dataForm: PhoneCall): Promise<Array<PhoneCall>> {
    return this.http.post<Array<PhoneCall>>(environment.api.company + '/phone-calls', dataForm).toPromise();
  };

  addRecording(dataForm: PhoneCall) {
    return this.http.put(environment.api.company + '/phone-calls', dataForm).toPromise();
  };

  getListPhone(): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-lines').toPromise();
  }

  getListPhoneWorkspace(): Promise<any> {

    return this.http.get(environment.api.workspace + '/phone-calls-lines').toPromise();
  }

  getPhones(): Promise<any> {
    return this.http.get(environment.api.company + '/phone-lines').toPromise();
  }

  getPhoneAll(): Promise<any> {
    return this.http.get(environment.api.workspace + '/phone-lines').toPromise();
  }


  getAllTotalToday(agentNumber): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-all-today', { params: { phone: agentNumber } }).toPromise();
  }


  getAllTotalInboundToday(agentNumber): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-inbound-today', { params: { phone: agentNumber } }).toPromise();
  }


  getAllTotalOutboundToday(agentNumber): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-outbound-today', { params: { phone: agentNumber } }).toPromise();
  }



  getAllTotal(agentNumber,betweenDatesStart, betweenDatesEnd): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-all', { params: { phone: agentNumber, dateStart : betweenDatesStart, dateEnd : betweenDatesEnd } }).toPromise();
  }

  getAllTotalWorkspace(agentNumber,betweenDatesStart, betweenDatesEnd): Promise<any> {
    return this.http.get(environment.api.workspace + '/phone-calls-all', { params: { phone: agentNumber, dateStart : betweenDatesStart, dateEnd : betweenDatesEnd } }).toPromise();
  }

  getCallsAnsweredTotal(agentNumber): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-answered', { params: { phone: agentNumber } }).toPromise();
  }


  getCallsMissed(agentNumber): Promise<any> {
    return this.http.get(environment.api.company + '/phone-calls-missed', { params: { phone: agentNumber } }).toPromise();
  }


}
