<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                Carrier Jobs
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Carrier Jobs
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
            
            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label class="control-label">Pickup State</label>
                    <select name="pickup_state" id="pickup_state" [(ngModel)]="filterData.pickup_state"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label class="control-label">Delivery State</label>
                    <select name="delivery_state" id="delivery_state" [(ngModel)]="filterData.delivery_state"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label class="control-label">Service</label>
                    <select name="service" id="service" [(ngModel)]="filterData.service" class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="LOCAL">Local Distance</option>
                        <option selected value="LONG">Long Distance</option>
                        <option value="AUTO_TRANSPORT">Auto Transport</option>
                        <option value="FREIGHT">Freight</option>
                    </select>
                </div>
            </div> 
            
            <div class="row col-lg-12 col-md-12 block-filter"> 
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label>Carrier</label>
                    <select [appSelect2] [theme]="'mxp360-form'" name="carrier" [(ngModel)]="carrierId" class="form-control">
                        <option value="">All Carriers</option>
                        <option value="{{carrier.id}}" *ngFor="let carrier of carriers">{{carrier.name}}</option>
                    </select>
                </div>               
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label>Sort By</label>
                    <select name="sort_by" [(ngModel)]="sort" (change)="sortBy()" class="form-control">
                        <option value="1">Number Asc</option>
                        <option value="2">Number Desc</option>
                        <option value="3">Customer Asc</option>
                        <option value="4">Customer Desc</option>
                        <option value="5">Pickup Date Asc</option>
                        <option value="6">Pickup Date Desc</option>
                        <option value="7">Created Date Asc</option>
                        <option value="8">Created Date Desc</option>
                        <option value="9">Destination Asc</option>
                        <option value="10">Destination Desc</option>
                    </select>
                </div>
                <div class="col-lg-4 col-xl-3 pl-0">
                    <button class="btn btn-warning btn-search" (click)="search()"> Search </button>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="block-total-jobs">
                    <span class="txt-total-jobs">TOTAL JOBS</span>
                    <span class="total-jobs">{{paginator.paginator.total}}</span> 
                </div>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Carrier</th>
                                <th>Customer</th>
                                <th>Service</th>
                                <th>Status</th>
                                <th>Pickup Date</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [ngClass]="{'pending-signature':row.job_outsourcing.status == 'PENDING_SIGNATURE'}"
                                *ngFor="let row of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;"
                                [ngStyle]="row.statuses.general != 'CUSTOMER_CANCELED' ? {'background-color': 'white'} : {'background-color': '#ffa7a7'}">
                                <td>
                                    {{row.estimate.document.code}}
                                </td>
                                <td class="text-center">
                                    <strong *ngIf="row.carrier">
                                        {{row.carrier.name}}
                                    </strong>
                                    <span *ngIf="row.carrier == null">
                                        --
                                    </span>
                                </td>
                                <td>
                                    {{row.estimate.customer.name}} {{row.estimate.customer.last_name}}
                                </td>
                                <td>
                                    <span [ngSwitch]="row.estimate.service">
                                        <ng-container *ngSwitchCase="'LONG'">
                                            Long
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'LOCAL'">
                                            Local
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'AUTO_TRANSPORT'">
                                            Auto Transport
                                        </ng-container>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <span [ngSwitch]="row.statuses.general">
                                            <ng-container *ngSwitchCase="'NEW'">
                                                New
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED_BOXES'">
                                                Delivered Boxes
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PACKED_MOVE'">
                                                Packed Move
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PICKED_UP'">
                                                Picked Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_TRANSIT'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'CUSTOMER_CANCELED'">
                                                Canceled
                                            </ng-container>

                                            <ng-container *ngSwitchDefault>
                                                {{row.estimate.status}}
                                            </ng-container>
                                        </span>
                                    </span>
                                </td>
                                <td>{{row.estimate.pickup.range_start | date}} to {{row.estimate.pickup.range_start |
                                    date}}</td>
                                <td *ngIf="row.estimate.pickup.address.full_address">
                                    {{row.estimate.pickup.address.full_address}} - {{row.estimate.pickup.address.state}}
                                    {{row.estimate.delivery.address.zip_code}}</td>
                                <td *ngIf="!row.estimate.pickup.address.full_address">
                                    {{row.estimate.pickup.address.state}} {{row.estimate.delivery.address.zip_code}}
                                </td>
                                <td *ngIf="row.estimate.delivery.address.full_address">
                                    {{row.estimate.delivery.address.full_address}} -
                                    {{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}}
                                </td>
                                <td *ngIf="!row.estimate.delivery.address.full_address">
                                    {{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}}
                                </td>
                                <td>
                                    <button class="btn btn-info" (click)="openNewTab(row.id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page"
                        [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page"
                        (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
</div>