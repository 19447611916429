import { EventEmitter, Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClientSocketsService {

    /**
     * creamos un socket statico para que solo se pueda
     * instanciar una sola vez en toda la aplicacion
     */
    private static socket: Socket = null;

    // evento para indicar cuando haya conexion con el sockect
    onConnect: EventEmitter<Socket> = null;

    constructor() {
        this.onConnect = new EventEmitter();
    }

    /**
     * Permite obtener la instancia del socket
     * @returns Socket
     */
    getSocket(): Socket {
        return ClientSocketsService.socket;
    }

    /**
     * Permite verificar si hay conexion via socket
     * @returns boolean
     */
    isConnected(): boolean {

        // verificamos que haya instancia socket
        if (ClientSocketsService.socket == null) {
            return false;
        }

        // cuando haya instancia verificamos que se este conectado
        return ClientSocketsService.socket.connected;
    }

    /**
     * Permite inicializar el socket
     *
     * @param token
     * @param employee_id
     * @returns
     */
    connect(token: string, workspace_id: string, employee_id: string = null): void {

        // verificamos que no estemos conectados para crear una nueva conexion
        if (this.isConnected()) {
            return;
        }

        /**
         * creamos la instancia unica del socket
         * @see https://socket.io/docs/v4/client-initialization/
         */
        ClientSocketsService.socket = io(environment.api.sockets, {
            path: "/websockets",
            auth: {
                Authorization: token,
                workspace_id: workspace_id,
                employee_id: employee_id
            },
            transports: ['websocket', 'polling'],
        });


        /**
         * esperamos a que se conecte el socket
         * @see https://socket.io/docs/v4/client-socket-instance/#connect
         */

        ClientSocketsService.socket.on("connect", () => {
            // mandamos evento para que los demas sepan que ya hay conexoin con el socket
            this.onConnect.emit(ClientSocketsService.socket);

        });

        /**
         * creamos un listener por si se presenta un error cuando se conecte
         * @see https://socket.io/docs/v4/client-socket-instance/#connect_error
         */
        ClientSocketsService.socket.on("connect_error", (error) => {
            console.error("SOCKET CONNECT ERROR", error);
            //ClientSocketsService.socket.connect();

        });

        /**
         * creamos un listener por si se presenta un error cuando se conecte
         *
         * @see https://socket.io/docs/v4/client-socket-instance/#disconnect
         */
        ClientSocketsService.socket.on("disconnect", (_reason) => {
            console.log("SOCKET DISCONECT", _reason);
        });

    }

    /**
     * Permite cerrar la sesion del socket
     * @see https://socket.io/docs/v4/client-api/#socketdisconnect
     */
    disconnect() {
        ClientSocketsService.socket.disconnect();
    }


}
