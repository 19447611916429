import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Job } from 'src/app/entities/workspace/job';
import { JobView } from 'src/app/entities/workspace/job-view';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { JobOperationStatus } from 'src/app/entities/workspace/job-operation-status';
import { JobInventoryItem } from 'src/app/entities/workspace/job-inventory-item';
import { JobItemQrCode } from 'src/app/entities/job-item-qr-code';
import { FilterData } from 'src/app/entities/helpers/filter-data';


@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private http: HttpClient) { }

  getAllWithFullView(data: any): Promise<Array<any>> {
    return this.http.get<Array<any>>(environment.api.company + '/jobs-calendar', { params: data }).toPromise();
  }

  getJobsToday(data: any): Promise<Array<any>> {
    return this.http.get<Array<any>>(environment.api.company + '/jobs-today', { params: data }).toPromise();
  }

  getById(id: string) {
    return this.http.get<JobView>(environment.api.company + '/jobs/' + id, { params: { 'full-view': '1' } }).toPromise();
  }

  getAll(paginator: Paginator, filterData: FilterData) {
    
    const params: any = {
      "use_paginator": 1,
      "page": paginator.paginator.page,
      "per_page": paginator.paginator.per_page,
      "start": paginator.paginator.start,
      "end": paginator.paginator.end,
      "order_by": paginator.paginator.order_by,
      "order_type": paginator.paginator.order_type,
      "pickup_state": filterData.pickup_state,
      "delivery_state": filterData.delivery_state,
      "service": filterData.service,
      "filter_date_for": filterData.filter_date_for,
      "allocation": filterData.allocation,
      "carrier": filterData.carrier
    };

    return this.http.get<Paginator>(environment.api.company + '/jobs', { params: params }).toPromise();
  }

  getPendingQA(paginator: Paginator, days: number) {
    const params: any = {
      "use_paginator": 1,
      "page": paginator.paginator.page,
      "per_page": paginator.paginator.per_page,
      "start": paginator.paginator.start,
      "end": paginator.paginator.end,
      "order_by": paginator.paginator.order_by,
      "order_type": paginator.paginator.order_type,
      days
    };
    return this.http.get<Paginator>(environment.api.company + '/jobs', { params: params }).toPromise();
  }

  getJobsDelivered(data) {
    return this.http.get<any>(environment.api.company + '/jobs', { params: data }).toPromise();
  }

  getJobsDeliveredById(data, id) {
    return this.http.get<any>(environment.api.company + '/jobs/' + id, { params: data }).toPromise();
  }

  patchEntity(id: string, dataForm: any) {
    return this.http.patch(environment.api.company + '/jobs/' + id, dataForm).toPromise();
  }

  saveDocument(id: string, doc_type: string, dataForm: any) {
    return this.http.put<Job>(environment.api.company + '/jobs/' + id + '/media-files/' + doc_type, dataForm).toPromise();
  }

  saveMovingFile(id: string, doc_type: string, data: any) {
    return this.http.put<Job>(environment.api.company + '/jobs/' + id + '/moving-files/' + doc_type, { files: data }).toPromise();
  }

  deleteMovingFile(id: string, doc_type: string, idFile: any) {
    return this.http.delete<Job>(environment.api.company + '/jobs/' + id + '/moving-files/' + doc_type + '/' + idFile).toPromise();
  }

  forceStatusCharge(id, data) {
    return this.http.put<Job>(environment.api.company + '/jobs/' + id + '/force-status', data).toPromise();
  }

  getStatusHistoryCharge(id) {
    return this.http.get<Array<any>>(environment.api.company + '/jobs/' + id + '/history-job-operation-statuses').toPromise();
  }

  getJobById(id: string) {
    return this.http.get<JobView>(environment.api.company + '/job/' + id + '/detail', { params: { 'full-view': '1' } }).toPromise();
  }

  loadJobInventoryItems(jobId: string): Promise<JobInventoryItem> {
    const params: any = { jobId };
    return this.http.get<JobInventoryItem>(environment.api.workspace + '/job-inventory-items', { params: params }).toPromise();
  }

  searchJobItemQrByCode(code: string): Promise<Array<JobItemQrCode>> {
    const params: any = { code };
    return this.http.get<Array<JobItemQrCode>>(environment.api.workspace + '/search-job-item-qr-by-code', { params: params }).toPromise();
  }


  saveJobInventoryItem(dataForm: JobInventoryItem): Promise<any> {
      if (dataForm.id === null) {
          return this.addJobInventoryItem(dataForm);
      }
      return this.editJobInventoryItem(dataForm);
  }

  addJobInventoryItem(dataForm: JobInventoryItem) {
    return this.http.post<any>(environment.api.workspace + '/save-job-inventory-item', dataForm).toPromise();
  }

  editJobInventoryItem(dataForm: JobInventoryItem) {
    return this.http.put<any>(environment.api.workspace + '/edit-job-inventory-item', dataForm).toPromise();
  }

  deleteJobInventoryItem(id: string, qrCode: string) {
    return this.http.delete<any>(environment.api.workspace + '/delete-job-inventory-item/'+ id + '/' + qrCode).toPromise();
  }

  addQrJobInventoryItem(dataForm: any) {
    return this.http.post<any>(environment.api.workspace + '/add-qr-job-inventory-item', dataForm).toPromise();
  }

  deleteQrJobInventoryItem(dataForm: any) {
    return this.http.post<any>(environment.api.workspace + '/delete-qr-job-inventory-item', dataForm).toPromise();
  }

  loadJobInventorySummaryItems(jobId: string): Promise<JobInventoryItem> {
    const params: any = { jobId };
    return this.http.get<JobInventoryItem>(environment.api.workspace + '/job-inventory-summary-items', { params: params }).toPromise();
  }

  loadJobInventoryRemovedItems(jobId: string): Promise<JobInventoryItem> {
    const params: any = { jobId };
    return this.http.get<JobInventoryItem>(environment.api.workspace + '/job-inventory-removed-items', { params: params }).toPromise();
  }

  loadInventoryCategories(): Promise<any> {
    return this.http.get<any>(environment.api.workspace + '/inventory_categories').toPromise();
  }

  loadJobInventoryItemsByCategory(jobId: string, categorybId: string): Promise<JobInventoryItem> {
    const params: any = { jobId, categorybId };
    return this.http.get<JobInventoryItem>(environment.api.workspace + '/job-inventory-items-category', { params: params }).toPromise();
  }

  loadJobInventorySummaryItemsByCategory(jobId: string, categorybId: string): Promise<JobInventoryItem> {
    const params: any = { jobId, categorybId };
    return this.http.get<JobInventoryItem>(environment.api.workspace + '/job-inventory-items-summary-category', { params: params }).toPromise();
  }

  sendInvoiceByEmail(jobId: string) {
    return this.http.get(environment.api.company + '/jobs/' + jobId + '/invoice/true', {}).toPromise();
  }

  loadDriversLicense(id: string) {
    return this.http.get<JobView>(environment.api.company + '/save-job-driver-identities/' + id).toPromise();
  }
}
