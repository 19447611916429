import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { ReportLeadAdvertiserView } from 'src/app/entities/workspace/report-lead-advertiser-view';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdvertisersService {

    constructor(private http: HttpClient) {
    }

    getAll(): Promise<Array<Advertiser>> {
        return this.http.get<Array<Advertiser>>(environment.api.workspace + '/advertisers').toPromise();

    }

    getAllByCompany(idCompany: string): Promise<Array<Advertiser>> {
        return this.http.get<Array<Advertiser>>(environment.api.workspace + '/advertisers?company_id=' + idCompany).toPromise();

    }

    getById(id: string) {
        return this.http.get<Advertiser>(environment.api.workspace + '/advertisers/' + id).toPromise();
    }


    save(dataForm: Advertiser): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Advertiser) {
        return this.http.post(environment.api.workspace + '/advertisers', dataForm).toPromise();
    }

    edit(dataForm: Advertiser) {
        return this.http.put(environment.api.workspace + '/advertisers/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/advertisers/' + id).toPromise();
    }

    getReport(data): Promise<Array<ReportLeadAdvertiserView>> {
        return this.http.get<Array<ReportLeadAdvertiserView>>(environment.api.workspace + '/report-leads-advertisers',
            { params: data }).toPromise();
    }

    getLeadsAdvertisers(data, paginatorData: Paginator): Promise<Paginator> {
        data.use_paginator = 1;
        data.page = paginatorData.paginator.page;
        data.per_page = paginatorData.paginator.per_page;
        data.order_by = "created";
        data.order_type = "DESC";
        return this.http.get<Paginator>(environment.api.workspace + '/leads-advertisers', { params: data }).toPromise();
    }


}
