import { PhoneRingDestination } from "./phone-ring-destination";
import { File } from '../global/file';

export class PhoneQueue {
    public id: number;
    public name: string;
    public description: string;
    public phone_extensions_id: string[];
    public play_music_on_hold: File;
    public ring_duration: number;
    public destination: PhoneRingDestination;
    public company_id: string;
    public agents_offline_strategy: "DESTINATION" | "REMAIN_ON_HOLD";
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.description = null;
        this.phone_extensions_id = [];
        this.play_music_on_hold = new File();
        this.ring_duration = null;
        this.destination = new PhoneRingDestination();
        this.company_id = null;
        this.agents_offline_strategy = "REMAIN_ON_HOLD";
        this.created = null;
        this.updated = null;
    }
}
