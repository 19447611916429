import { ElectronicSignature } from "./workspace/electronic-signature";
import { PaymentProfile } from "./workspace/payment-profile";

export class CustomerPayment {
    public id: string;
    public amount: number;
    public esignature: ElectronicSignature;
    public payment_profile: PaymentProfile;
    public reference: string;
    public vouchers: Array<File>;
    public source_id: string;

    constructor() {
        this.id = null;
        this.amount = null;
        this.esignature = new ElectronicSignature();
        this.payment_profile = new PaymentProfile();
        this.reference = null;
        this.vouchers = [];
        this.source_id = null;
    }
}
