import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-last5-tickets',
  templateUrl: './last5-tickets.component.html',
  styleUrls: ['./last5-tickets.component.scss']
})
export class Last5TicketsComponent implements OnInit {
    lastTickets: any;

    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.lastTickets = [];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.getLastTicketsCompany();
        } else {
            this.getLastTicketsWorkspace();
        }
    }


    getLastTicketsWorkspace() {

        this.dashboardWorkspaceService
            .getLastTickets()
            .then((response) => {
                this.lastTickets = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


    getLastTicketsCompany() {

        this.dashboardComopanyService
            .getLastTickets()
            .then((response) => {
                this.lastTickets = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }



}
