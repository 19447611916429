export class LeadCompany {

    public id: string;
    public lead_id: string;
    public company_id: string;
    public work_department_id: string;
    public category: 'NEW' | 'SCHEDULED' | 'HOT' | 'WARM' | 'COLD' | 'BAD';
    public status: 'NEW' | 'IN_ESTIMATION' | 'DEAD_LEAD';
    public reason_bad_lead: string;
    public employee_id: string;
    public assignment_date: number;
    public opportunity_lead_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.lead_id = null;
        this.company_id = null;
        this.work_department_id = null;
        this.category = 'NEW';
        this.status = null;
        this.reason_bad_lead = null;
        this.employee_id = null;
        this.assignment_date = null;
        this.opportunity_lead_id = null;
        this.created = null;
        this.updated = null;
    }
}
