export class CarrierContact {
    public id: string;
    public name: string;
    public phone: string;
    public email: string;
    public carrier_id: string;
    public main: boolean;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.carrier_id = null;
        this.main = false;
        this.created = null;
        this.updated = null;
    }
}
