<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 layout-spacing align-self-center">
            <h3>
                My Leads
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">My Leads</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-success btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span>
                </a>
            </div>
        </div>

    </div>


    <div class="row padding-table">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="widget-content searchable-container list">

                <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">

                    <div class="row col-lg-12 col-md-12 block-filter">
                        <div class="col-md-7 col-6 col-50 d-flex pr-0" *ngIf="searchByDates">
                            <div class="col-md-4 col-4 pad0">
                                <label>Date Type</label>
                                <select name="selectDateType" id="selectDateType" [(ngModel)]="selectDateType" class="form-control">
                                    <option value="OPEN" Selected>Open Date</option>
                                    <option value="MOVE">Move Date</option>
                                    <option value="LAST_UPDATED">Last Updated</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-4">
                                <label>Asc / Dsc</label>
                                <select name="selectDateType" id="selectDateType" [(ngModel)]="paginator.paginator.order_type" class="form-control">
                                    <option value="ASC" >Ascending</option>
                                    <option value="DESC">Descending</option>
                                </select>
                            </div>
                            <div class="col-md-4 col-4 select-date-range-bg-w p-0">
                                <label>Date Range</label>
                                <app-date-range-picker [placeholder]="'Not Assigned'" (onChange)="onChangeDateRange($event)"
                                    [valueStart]="betweenFilter.dateStart" [valueEnd]="betweenFilter.dateEnd">
                                </app-date-range-picker>
                            </div>
                        </div>
                        <div class="col-md-2 col-2 col-20">
                            <label>Pickup</label>
                            <select name="state" id="state" [(ngModel)]="filterPickup" class="form-control">
                                <option value="">All</option>
                                <option value="AK">Alaska</option>
                                <option value="AL">Alabama</option>
                                <option value="AR">Arkansas</option>
                                <option value="AZ">Arizona</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="IA">Iowa</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MD">Maryland</option>
                                <option value="ME">Maine</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MO">Missouri</option>
                                <option value="MS">Mississippi</option>
                                <option value="MT">Montana</option>
                                <option value="NC">North carolina</option>
                                <option value="ND">North dakota</option>
                                <option value="NE">Nebraska</option>
                                <option value="NH">New hampshire</option>
                                <option value="NJ">New jersey</option>
                                <option value="NM">New mexico</option>
                                <option value="NV">Nevada</option>
                                <option value="NY">New york</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode island</option>
                                <option value="SC">South carolina</option>
                                <option value="SD">South dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VA">Virginia</option>
                                <option value="VT">Vermont</option>
                                <option value="WA">Washington</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WV">West virginia</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>

                        <div class="col-md-2 col-2 col-20 pl-0">
                            <label>Delivery</label>
                            <select name="order_by" id="order_by" [(ngModel)]="filterDelivery" class="form-control">
                                <option value="">All</option>
                                <option value="AK">Alaska</option>
                                <option value="AL">Alabama</option>
                                <option value="AR">Arkansas</option>
                                <option value="AZ">Arizona</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="IA">Iowa</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MD">Maryland</option>
                                <option value="ME">Maine</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MO">Missouri</option>
                                <option value="MS">Mississippi</option>
                                <option value="MT">Montana</option>
                                <option value="NC">North carolina</option>
                                <option value="ND">North dakota</option>
                                <option value="NE">Nebraska</option>
                                <option value="NH">New hampshire</option>
                                <option value="NJ">New jersey</option>
                                <option value="NM">New mexico</option>
                                <option value="NV">Nevada</option>
                                <option value="NY">New york</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode island</option>
                                <option value="SC">South carolina</option>
                                <option value="SD">South dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VA">Virginia</option>
                                <option value="VT">Vermont</option>
                                <option value="WA">Washington</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WV">West virginia</option>
                                <option value="WY">Wyoming</option>
                            </select>
                        </div>

                        <div class="col-md-1 col-1 pl-0">
                            <button class="btn btn-success btn-filter" (click)="load()">
                                Search
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 text-center align-self-center">
                        <div class="btn-group btn-group-justified filter-by-category">
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'ALL', 'btn-dark': btnActived != 'ALL'}" (click)="filterByCategory('ALL')">All</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'NEW', 'btn-dark': btnActived != 'NEW'}" (click)="filterByCategory('NEW')">New</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'HOT', 'btn-dark': btnActived != 'HOT'}" (click)="filterByCategory('HOT')">Hot</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'WARM', 'btn-dark': btnActived != 'WARM'}" (click)="filterByCategory('WARM')">Warm</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'COLD', 'btn-dark': btnActived != 'COLD'}" (click)="filterByCategory('COLD')">Cold</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'BAD', 'btn-dark': btnActived != 'BAD'}" (click)="filterByCategory('BAD')">Bad Lead</button>
                            </div>
                            <div class="btn-group">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'UNASSIGNED', 'btn-dark': btnActived != 'UNASSIGNED'}" (click)="filterByCategory('UNASSIGNED')">Unassigned Leads</button>
                            </div>
                            <div class="btn-group" [appCanUserCompany]="['LEAD_OPPORTUNITIES']">
                                <button class="btn" type="button" [ngClass]="{'btn-success': btnActived == 'OPPORTUNITY', 'btn-dark': btnActived != 'OPPORTUNITY'}" (click)="filterByCategory('OPPORTUNITY')">Opportunity</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <h4>
                                    Customer
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4 style="margin-left: 3px;">
                                    Service
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4 style="margin-left: 3px;">
                                    Date
                                </h4>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <h4 style="margin-left: 3px;">
                                    Info
                                </h4>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <h4 style="margin-left: 3px;">
                                    Category
                                </h4>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <h4 style="margin-left: 3px;">
                                    Status
                                </h4>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <h4 style="margin-left: 3px;">
                                    Actions
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="items" *ngFor="let row of paginator.data">
                        <div class="item-content information-col items item-lead" role="menu" [ngClass]="{'opportunity-lead': row.opportunity_lead_id}">
                            <!-- Lead Notes Button -->
                            <a class="btn-estimate-notes tooltipmxp tooltipmxp-right" title="Lead Notes" data-toggle="collapse"
                                 href="#collapse_note_{{row.id}}" *ngIf="row.notes.length > 0"
                                 role="button" aria-expanded="false" aria-controls="collapse">
                                <i class="fa-solid fa-message"></i>
                                <span class="note-alert" *ngIf="row.notes.length > 0"></span>
                            </a>

                            <div class="btn-estimate-notes tooltipmxp tooltipmxp-right" id="load_follow_ups_{{row.id}}"
                                (click)="addFollowUps(row.id)" title="Add a New Note" *ngIf="!row.notes.length">
                                <i class="fa-solid fa-message"></i>
                            </div>

                            <div class="user-profile col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div class="user-meta-info info">
                                    <div class="row" style="padding-left: 20px; margin-bottom: 5px;">
                                        <p class="user-name">
                                            {{row.lead.customer.last_name.toUpperCase()}},</p>
                                        <p class="user-work" style="margin-left: 5px; font-size: 15px;">
                                            {{row.lead.customer.name | filterReplace}}</p>
                                    </div>

                                    <div class="customer-info-container" (click)="copyText(row.lead.customer.phone, 'text', row.opportunity_lead_id)">
                                        <p class="word-break-customer">
                                            <!--
                                                <i class="far fa-copy p-2" *ngIf="row.opportunity_lead_id == null"></i>
                                                <span class="customer-info" *ngIf="row.opportunity_lead_id !== null">xxx xxx xxxx</span>
                                                <span class="customer-info" *ngIf="row.opportunity_lead_id == null">{{row.lead.customer.phone | phone: 'US'}}</span>
                                            -->

                                            <i class="far fa-copy p-2"></i>
                                            <span class="customer-info">{{row.lead.customer.phone | phone: 'US'}}</span>

                                        </p>
                                    </div>
                                    <br>
                                    <div class="customer-info-container" (click)="copyText(row.lead.customer.email, 'text', row.opportunity_lead_id)">
                                        <p class="word-break-customer">
                                            <!-- 
                                                <i class="far fa-copy p-2" *ngIf="row.opportunity_lead_id == null"></i>
                                                <span class="customer-info" *ngIf="row.opportunity_lead_id !== null">xxxxx@xxxxx.xxx</span>
                                                <span class="customer-info" *ngIf="row.opportunity_lead_id == null">{{row.lead.customer.email}}</span>
                                            -->
                                            <i class="far fa-copy p-2"></i>
                                            <span class="customer-info">{{row.lead.customer.email}}</span>

                                        </p>
                                    </div>

                                    <div class="contact-action-btns">
                                        <button class="btn btn-info btn-call" (click)="toCall(row.lead.customer)">
                                            Call
                                        </button>

                                        <button class="btn btn-success btn-sms" (click)="openModalSendTextMessage();destinationNumber(row);">
                                            SMS
                                        </button>

                                        <button class="btn btn-warning btn-email" (click)="openModalEmail(row.lead.customer)">
                                            Email
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 service-col">

                                <div class="mb-1" *ngIf="row.lead.service == 'AUTO_TRANSPORT'; else noneAuto">
                                    <strong style="color:#515365;">Type:</strong>
                                    <span class="badge badge-info">
                                        {{row.lead.service | filterReplace}}
                                    </span>
                                </div>

                                <ng-template #noneAuto>
                                    <div class="mb-1" *ngIf="row.lead.service == 'LONG'; else noneLong">
                                        <strong style="color:#515365;">Type:</strong>
                                        <span class="badge badge-secondary">
                                            {{row.lead.service | filterReplace}}
                                        </span>
                                    </div>
                                </ng-template>

                                <ng-template #noneLong>
                                    <div class="mb-1">
                                        <strong style="color:#515365;">Type:</strong>
                                        <span class="badge badge-dark">
                                            {{row.lead.service | filterReplace}}
                                        </span>
                                    </div>
                                </ng-template>

                                <p class="usr-email-addr">
                                    <strong>From: </strong>
                                    {{row.lead.from_address.state}}
                                    {{row.lead.from_address.city}}
                                    {{row.lead.from_address.zip_code}}
                                </p>

                                <p class="usr-email-addr">
                                    <strong>To: </strong>
                                    {{row.lead.to_address.state}}
                                    {{row.lead.to_address.city}}
                                    {{row.lead.to_address.zip_code}}
                                </p>

                                <!-- <p *ngIf="row.lead.advertiser !== null " class="usr-ph-no">
                                    <strong>Advertiser:</strong>
                                    {{row.lead.advertiser.name}}
                                </p> -->

                                <div class="lead-category" *ngIf="row.opportunity_lead_id !== null">
                                    <span class="badge badge-secondary">
                                        Opportunity
                                        <span *ngIf="row.lead.opportunity_type == 'PREMIUM'"> P</span>
                                    </span>
                                </div>

                            </div>

                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="user-meta-info info">

                                    <p class="usr-ph-no"><span style="margin-right: 10px;"
                                            class="badge badge-danger">Move Date:</span>
                                        {{row.lead.move_date | date}} <ng-container
                                            *ngIf="row.lead.endWindowDate">-
                                            {{row.lead.endWindowDate | date}}</ng-container>
                                    </p>
                                    <p class="usr-ph-no"><strong>Created:</strong>
                                        {{row.created | dateTime}}
                                    </p>
                                    <p class="usr-ph-no"><strong>Last Updated:</strong>
                                        {{row.updated | dateTime}}
                                    </p>

                                </div>
                            </div>

                            <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <div class="user-meta-info info">
                                    <p class="user-work" *ngIf="row.vehicles"> <strong>Type:</strong>
                                        {{row.vehicles.type}}
                                    </p>
                                    <p class="user-work" *ngIf="row.vehicles"><strong>Brand:</strong>
                                        {{row.vehicles.brand}}
                                    </p>
                                    <p class="user-work" *ngIf="row.vehicles"><strong>Model:</strong>
                                        {{row.vehicles.model}}
                                    </p>
                                    <p class="user-work" *ngIf="row.vehicles"><strong>Year:</strong>
                                        {{row.vehicles.year}}
                                    </p>
                                    <p class="user-work" *ngIf="row.lead.move_size"><strong>Move Size:</strong>
                                        {{row.lead.move_size | filterReplace}}
                                    </p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <div class="user-meta-info info">
                                    <select name="deadModal" [(ngModel)]="row.category"
                                        class="form-control select-modal select-category"
                                        (change)="changeCategory(row.id, $event.target.value)">
                                        <option [selected]="row.category == 'NEW'" value="NEW">
                                            New
                                        </option>
                                        <option [selected]="row.category == 'SCHEDULED'" value="SCHEDULED">
                                            Scheduled
                                        </option>
                                        <option [selected]="row.category == 'HOT'" value="HOT">
                                            Hot
                                        </option>
                                        <option [selected]="row.category == 'WARM'" value="WARM">
                                            Warm
                                        </option>
                                        <option [selected]="row.category == 'COLD'" value="COLD">
                                            Cold
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <div class="user-meta-info info">
                                    <p *ngIf="row.status">{{row.status}}</p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <div class="user-meta-info info">
                                    <button id="btnOutline"
                                        *ngIf="row.status == 'NEW' || row.status == 'IN_ESTIMATION' || row.status == 'DEAD_LEAD'"
                                        type="button"
                                        class="btn btn-secondary btn-full margin-bottom dropdown-toggle btn-options"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-cog"></i>
                                    </button>
                                    <div class="dropdown-menu leads-options" aria-labelledby="btnOutline"
                                        style="will-change: transform;">
                                        <a class="dropdown-item"
                                            *ngIf="row.status == 'NEW' || row.status == 'DEAD_LEAD'"
                                            (click)="goEstimate(row.id)">
                                            <i class="fas fa-file-contract"></i> &nbsp; To Estimate
                                        </a>
                                        <a appCompanyRouterLink="/customers/{{row.lead.customer_id}}/view"
                                            class="dropdown-item" *ngIf="row.status == 'IN_ESTIMATION'">
                                            <i class="fas fa-file-invoice-dollar"></i> &nbsp; View Customer
                                        </a>
                                        <a data-target="#modalMap" (click)="initializeMap(row)"
                                            data-toggle="modal" class="dropdown-item">
                                            <i class="fas fa-map"></i> &nbsp; View Map
                                        </a>
                                        <a class="dropdown-item" *ngIf="row.status == 'NEW'"
                                            (click)="modalDeadLead(row.id)">
                                            <i class="fas fa-user-slash"></i> &nbsp; Bad Lead
                                        </a>
                                        <!-- <a class="dropdown-item" *ngIf="row.status == 'NEW'"
                                            (click)="notContacted(row)">
                                            <i class="fas fa-phone-slash"></i> &nbsp; Not Contacted
                                        </a> -->
                                        <a (click)="openModalChangeCategory(row.id)" class="dropdown-item"><i class="fas fa-edit"></i> &nbsp;Change Category</a>
                                        <a appCompanyRouterLink="/leads/{{row.id}}/edit" class="dropdown-item"
                                            *ngIf="row.status == 'NEW'">
                                            <i class="fas fa-edit"></i> &nbsp; Edit
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Notes -->
                        <div class="col-md-12 item-lead-note collapse" id="collapse_note_{{row.id}}" >
                            <div class="items items-header-section">
                                <div class="item-content block-note">

                                    <a title="Add a New Note" class="tooltipmxp tooltipmxp-right btn-call-action btn-new-note" (click)="addFollowUps(row.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-plus-circle">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" y1="8" x2="12" y2="16"></line>
                                            <line x1="8" y1="12" x2="16" y2="12"></line>
                                        </svg>
                                    </a>

                                    <div class="col-12 padd-l70">
                                        <div class="timeline-alter" *ngIf="row.notes.length > 0" id="all_notes_show_{{ row.id }}">
                                            <div *ngFor="let follow of row.notes; index as i;" class="item-timeline">
                                                <div class="t-meta-date">
                                                    <p *ngIf="follow.employee">
                                                        {{follow.created | dateTime}}
                                                    </p>
                                                    <p *ngIf="follow.employee">
                                                        {{follow.employee.name}}
                                                    </p>
                                                    <p *ngIf="!follow.employee">
                                                        {{follow.created | dateTime}} - MoverXpro360
                                                    </p>
                                                </div>
                                                <div class="t-dot">
                                                </div>
                                                <div class="t-text follow-remarks">
                                                    <pre [innerHTML]="follow.remarks"></pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <app-custom-paginator [currentPage]="paginator.paginator.page"
                        [rowsQuantity]="paginator.paginator.total"
                        [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)">
                    </app-custom-paginator>
                </div>
            </div>
        </div>
    </div>

</div>


<!--Modal: modalPush-->
<div class="modal fade" #deadLeadModal tabindex="-1" role="dialog" aria-labelledby="modalDeadLead" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Header-->
            <div class="modal-header d-flex justify-content-center back-header">
                <p class="heading text-heading">Bad Lead</p>
            </div>

            <!--Body-->
            <div class="modal-body">

                <div class="mt-5">
                    <i class="fas fa-user-slash fa-4x animated rotateIn mb-4"></i>
                    <p>What is the reason you want to block this Lead?</p>
                </div>

                <div class="mt-3 mb-4">
                    <select name="deadModal" class="form-control select-modal" [(ngModel)]="deadLeadSelected">
                        <option value="ALREADY_BOOKED">ALREADY BOOKED</option>
                        <option value="ALREADY_MOVED">ALREADY MOVED</option>
                        <option value="BAD_REVIEWS">BAD REVIEWS</option>
                        <option value="BOOKED_TO_CAPACITY">BOOKED TO CAPACITY</option>
                        <option value="CAR_SHIPPING_ONLY">CAR SHIPPING ONLY</option>
                        <option value="CONTRACT_WITH_ANOTHER_COMPANY">CONTRACT WITH ANOTHER COMPANY</option>
                        <option value="CUSTOMER_IS_NOT_MOVING">CUSTOMER IS NOT MOVING</option>
                        <option value="DISCONNECTED_PHONE_NO">DISCONNECTED_PHONE_NO.</option>
                        <option value="DUPLICATE_(DIFFERENT_LEAD_PROVIDER)">DUPLICATE (DIFFERENT LEAD PROVIDER)</option>
                        <option value="DUPLICATE_(SAME_LEAD_PROVIDER)">DUPLICATE (SAME LEAD PROVIDER)</option>
                        <option value="FAKE_EMAIL">FAKE EMAIL</option>
                        <option value="FAKE_NAME">FAKE NAME</option>
                        <option value="FAKE_PHONE">FAKE PHONE</option>
                        <option value="FAKE/WRONG_NAME">FAKE/WRONG_NAME</option>
                        <option value="LANGUAGE_BARRIER">LANGUAGE BARRIER</option>
                        <option value="LOCAL_MOVE">LOCAL MOVE</option>
                        <option value="LOOKING_FOR_APARTMENT">LOOKING FOR APARTMENT</option>
                        <option value="MOVE_DATE_ALREADY_PASSED">MOVE DATE ALREADY PASSED</option>
                        <option value="MOVE_DATE_IS_6_MONTHS_AWAY">MOVE DATE IS 6 MONTHS AWAY</option>
                        <option value="MOVING_ONLY_1_ITEM">MOVING ONLY 1 ITEM</option>
                        <option value="NO_RESPONSE/UNABLE_TO_MAKE_CONTACT">NO RESPONSE/UNABLE TO MAKE CONTACT</option>
                        <option value="NOT_INTERESTED_IN_OUR_COMPANY">NOT INTERESTED IN OUR COMPANY</option>
                        <option value="NOT_LOOKING_FOR_A_MOVING_COMPANY">NOT LOOKING FOR A MOVING COMPANY</option>
                        <option value="OUTSIDE_OF_THE_DELIVERY_RADIUS">OUTSIDE OF THE DELIVERY RADIUS</option>
                        <option value="OUTSIDE_OF_THE_PICK-UP_RADIUS">OUTSIDE OF THE PICK-UP RADIUS</option>
                        <option value="PHONE_NUMBER_IS_A_FAX">PHONE NUMBER IS A FAX</option>
                        <option value="POD_RENTAL">POD RENTAL</option>
                        <option value="REMOVED_FROM_MAILING_LIST">REMOVED FROM MAILING LIST</option>
                        <option value="SELF_MOVE">SELF MOVE</option>
                        <option value="SHIPPING_NON-HOUSEHOLD_GOODS">SHIPPING NON-HOUSEHOLD GOODS</option>
                        <option value="TRUCK_RENTAL">TRUCK RENTAL</option>
                        <option value="UNBEATABLE_PRICE">UNBEATABLE PRICE</option>
                        <option value="WRONG_PHONE_NO">WRONG PHONE NO.</option>
                    </select>
                </div>

            </div>

            <!--Footer-->
            <div class="modal-footer flex-center justify-content-center mt-2">
                <a type="button" class="btn btn-danger button-size" (click)="deadLead()">Yes</a>
                <a type="button" class="btn btn-cancel waves-effect button-size" data-dismiss="modal">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!-- Modal map routes -->
<div class="modal fade size-modal" id="modalMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog size-modal" role="document">
        <div class="modal-content size-modal">

            <div class="modal-header header-map">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 90%;">Route Delivery <span
                        style="float: right;">Distance: {{miles}}</span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body header-map">
                <div id="map" #mapView class="map"></div>
            </div>

        </div>
    </div>
</div>
<!-- modal change category -->
<div class="modal fade" #modalChangeCategory tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="modal">
        <div class="modal-content">

            <div class="modal-header header-map">
                <h5 class="modal-title" id="exampleModalLabel" style="width: 90%;">Select a new category</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <select name="deadModal" [(ngModel)]="newCategory"
                    class="form-control select-modal select-category"
                    (change)="changeCategory(leadSelected, $event.target.value)">
                    <option value="NEW">New</option>
                    <option value="SCHEDULED">Scheduled</option>
                    <option value="HOT">Hot</option>
                    <option value="WARM">Warm</option>
                    <option value="COLD">Cold</option>
                </select>
            </div>

        </div>
    </div>
</div>

<!-- Modal follow -->
<div class="modal fade register-modal" #modalFollowupsremarks tabindex="-1" role="dialog" aria-labelledby="companyTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="followupsremarksTitle">
                    Add a New Lead Note
                </h5>
            </div>
            <div class="modal-body">
                <div class="">
                    <label for="inputState">
                        Note
                    </label>
                    <div class="list-title">
                        <textarea class="form-control textarea-force" name="lead_followup_remarks"
                            id="lead_followup_remarks" [(ngModel)]="followupsremarks.remarks"
                            placeholder="Write the note here" cols="30" rows="5">
                        </textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="addfollowupsremarks()" data-dismiss="modal" type="button"
                    class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Send Text Message -->
<div class="modal fade" #modalSendTextMessage id="modalSendTextMessage" tabindex="-1" role="dialog" aria-labelledby="modalSendTextMessage" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document" style="width: 600px;">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="newConceptTitle">
                  New SMS Text
              </h5>
          </div>
          <div class="modal-body">
              <div class="row">

                  <div class="col-md-12">
                      <div class="d-flex form-box">
                          <div class="icon">
                              <i class="fa-solid fa-phone-volume"></i>
                          </div>
                          <div class="form-group mb-4">
                              <label for="">Origin Number</label>
                              <select class="form-control" name="dataFormPhone_phoneNumberEmployee" placeholder="Type" [(ngModel)]="dataFormPhone.phoneNumberEmployee">

                                <ng-container *ngFor="let employeeLine of extensions">
                                  <option value="{{employeeLine.numberSms}}">{{employeeLine.extension}}:{{employeeLine.numberSms| phone:'US'}}</option>
                              </ng-container>

                              </select>
                          </div>
                      </div>
                  </div>

               <!--    *ngFor="let row of paginator.data" -->
                  <div class="col-md-12">
                    <div class="d-flex form-box">
                        <div class="icon">
                            <i class="fa-solid fa-phone"></i>
                        </div>
                        <div class="form-group mb-4">
                            <label for="">Destination Number Contact List</label>

                            <input type="text" class="form-control" id="customerSms" name="customerSms"  value="customerSms" [(ngModel)]="customerSms"  disabled>

                        </div>
                    </div>
                </div>


                <div class="col-md-12">
                  <div class="d-flex form-box">
                      <div class="icon">
                          <i class="fa-solid fa-comment-sms"></i>
                      </div>
                      <div class="form-group mb-4">
                          <label for="">SMS Template</label>
                          <select class="form-control" name="changeSmsTemplate" placeholder="Type"  (change)="changeSmsTemplate($event.target.value)">
                              <option value="">Select an Option</option>
                              <ng-container *ngFor="let smsTemplate of smsTemplates">
                                  <option value="{{smsTemplate.message}}">{{smsTemplate.name}}</option>
                              </ng-container>
                          </select>
                      </div>
                  </div>
              </div>

                  <div class="col-md-12">
                      <div class="form-group">
                          <label for="">Message</label>
                          <textarea class="form-control" name="dataFormPhone_message" id="" cols="10" rows="5" [(ngModel)]="dataFormPhone.message"></textarea>
                      </div>
                  </div>


              </div>
          </div>
          <div class="modal-footer pt-3">
              <button class="btn btn-cancel" data-dismiss="modal">
                  Cancel
              </button>
              <button data-dismiss="modal" type="button" class="btn btn-save" (click)="sendMessage()">
                  Send
              </button>
          </div>
      </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" #composeMailModal id="composeMailModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">
                            <h3>
                                <strong>
                                    New Email
                                </strong>
                            </h3>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex mb-3 mail-template">
                                        <i class="fa-solid fa-envelope-open-text"></i>
                                        <div class="w-100">
                                            <select class="select-template form-control" id="m-form" (change)="setTemplate($event)">
                                                <option value="-1" selected>Blank Email</option>
                                                <ng-container *ngFor="let emailTemp of emailTemplates; let i = index">
                                                    <option value="{{i}}" *ngIf="!emailTemp.attach_inventory && !emailTemp.request_esignature && !emailTemp.request_poa && !emailTemp.request_inventory_update && !emailTemp.request_bol && !emailTemp.request_ppw && !emailTemp.request_token">{{emailTemp.subject}}</option>
                                                </ng-container>
                                            </select>
                                            <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Select a Template.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row" *ngFor="let to of estimateEmail.to; let i = index">
                                <div class="col-md-5">
                                    <div class="d-flex mb-3 mail-to">
                                        <i class="fa-solid fa-envelope"></i>
                                        <div>
                                            <input required type="email" placeholder="Email" class="form-control" [disabled]="estimateEmail.thread_id != null" [(ngModel)]="to.email" name="to_email_{{i}}" required>
                                            <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Enter the Recipient Email.</small>
                                            <small *ngIf="disableButton" id="emailHelp1" class="form-text text-muted mb-2 insert-direction color-error">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-5 pl-5">
                                    <div class="d-flex mb-3 mail-cc">
                                        <i class="fa-solid fa-user"></i>
                                        <div>
                                            <input required type="text" placeholder="Name" class="form-control" [disabled]="estimateEmail.thread_id != null" [(ngModel)]="to.name" name="to_name_{{i}}" required>
                                            <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Enter the Recipient Name.</small>
                                            <small *ngIf="disableButton" id="emailHelp1" class="form-text text-muted mb-2 insert-direction color-error">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                            </div> -->

                            <div class="d-flex mb-4 mail-subject">
                                <i class="fa-solid fa-paper-plane"></i>
                                <div class="w-100">
                                    <input required type="text" placeholder="Subject" class="form-control" [disabled]="estimateEmail.thread_id != null" [(ngModel)]="estimateEmail.subject" name="subject" required>
                                    <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Enter the Subject of the Message.</small>
                                    <small *ngIf="disableButton" id="emailHelp1" class="form-text text-muted mb-2 insert-direction color-error">This field is required.</small>
                                </div>
                            </div>

                            <app-ck-editor [(ngModel)]="estimateEmail.content" name="content" ngDefaultControl>
                            </app-ck-editor>


                            <!-- <div class="col-md-12 mt-5">

                                <div class="form-group">
                                    <div class="toggle-switch">
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="attach_inventory" name="attach_inventory"
                                            [(ngModel)]="estimateEmail.attach_inventory">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="attach_inventory" class="checkbox-label" [ngClass]="{'filter-disabled': !estimateEmail.attach_inventory}">
                                            Attach Portal Link
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="estimateEmail.attach_inventory">
                                    <div class="toggle-switch">
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_inventory_update"
                                            [(ngModel)]="estimateEmail.request_inventory_update" name="request_inventory_update">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_inventory_update" class="checkbox-label" [ngClass]="{'filter-disabled': !estimateEmail.request_inventory_update}">
                                            Inventory editable by customer
                                        </label>
                                    </div>
                                </div>


                            </div>                             -->

                        </div>
                    </div>
                </div>

                <!-- send or cancel -->
                <div class="modal-footer">
                    <button class="btn btn-close-modal" type="button" (click)="toCancelCompose()">
                        Cancel
                    </button>
                    <button id="btn-send" class="btn" type="submit" (click)="validateSendEmail()">
                        Send
                    </button>
                </div>
            </div>

    </div>
</div>
