<!-- <div class="widget widget-one_hybrid widget-followers" *ngIf="howManyEmailstheySentInTheLast7Days">
    <div class="widget-heading">
        <h5>
            Emails Sent (Last 7 Days)
        </h5>
    </div>
    <div class="widget-content pt-4 pb-4">    
        <div class="text-center">
            <h1>
                {{howManyEmailstheySentInTheLast7Days.total_emails}}
            </h1>                           
        </div>
    </div>
</div> -->


<div class="widget-one widget counter">
    <div class="widget-content">
        <div class="w-numeric-value">
            <div class="col-12 w-content">
                <!-- <span class="w-value">{{jobsTotalIncomingLeads.total}}</span> -->
                <div class="w-text margin-b4">Emails Sent (Last 7 Days)</div>
            </div>
            <div class="col-12 w-content-center">
                <span class="w-numeric-quantity">{{howManyEmailstheySentInTheLast7Days.total_emails}}</span>
            </div>
        </div>
        <div class="w-chart">
            <div id="email-sent-last7-days"></div>
        </div>
    </div>
</div>
