

import { ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/entities/global/address';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from 'src/app/services/companies/balances.service';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { BlackoutMoveDatesService } from 'src/app/services/companies/blackout-move-dates.service';
import { Carrier } from '../../../entities/workspace/carrier';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';
import { CarriersCompanyService } from '../../../services/companies/carriers-company.service';
import { CarriersContactsCompanyService } from 'src/app/services/companies/carriers-contacts-company.service';
import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Customer } from 'src/app/entities/workspace/customer';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { DeductibleLevel } from 'src/app/entities/workspace/deductible-level';
import { DeductibleLevelsService } from 'src/app/services/workspaces/deductible-levels.service';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesCompanyService } from '../../../services/companies/employees-company.service';
import { EstimateAdditionalCharge } from 'src/app/entities/workspace/estimate-additional-charge';
import { EstimateCarrierNote } from 'src/app/entities/workspace/estimate-carrier-note';
import { EstimateCarrierNotesService } from 'src/app/services/workspaces/estimate-carrier-notes.service';
import { EstimateCustomerRemark } from 'src/app/entities/workspace/estimate-customer-remark';
import { EstimateCustomerRemarkView } from 'src/app/entities/workspace/estimate-customer-remark-view';
import { EstimateDelivery } from 'src/app/entities/workspace/estimate-delivery';
import { EstimateFollowUp } from 'src/app/entities/workspace/estimate-follow-up';
import { EstimateFollowUpView } from 'src/app/entities/workspace/estimate-follow-up-view';
import { EstimateFreights } from 'src/app/entities/workspace/estimate-freights';
import { EstimateInsurance } from 'src/app/entities/workspace/estimate-insurance';
import { EstimateInventoryContainer } from '../../../entities/workspace/estimate-inventory-container';
import { EstimateLocalTariff } from 'src/app/entities/workspace/estimate-local-tariff';
import { EstimateNote } from '../../../entities/workspace/estimate-note';
import { EstimateNoteView } from '../../../entities/workspace/estimate-note-view';
import { EstimatePickup } from 'src/app/entities/workspace/estimate-pickup';
import { EstimatesAdditionalChargesService } from 'src/app/services/companies/estimates-additional-charges.service';
import { EstimatesCustomerRemarksService } from 'src/app/services/companies/estimates-customer-remarks.service';
import { EstimatesEmailTemplatesService } from 'src/app/services/companies/estimates-email-templates.service';
import { EstimatesFollowUpsService } from 'src/app/services/companies/estimates-follow-ups.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { EstimateStopPoint } from '../../../entities/workspace/estimate-stop-point';
import { EstimateStopPointsService } from 'src/app/services/companies/estimate-stop-points.service';
import { EstimateStorage } from 'src/app/entities/workspace/estimate-storage';
import { EstimateTariffDiscount } from 'src/app/entities/workspace/estimate-tariff-discount';
import { EstimateTransportVehicles } from 'src/app/entities/workspace/estimate-transport-vehicles';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { EstimateWorker } from 'src/app/entities/workspace/estimate-worker';
import { FeesInformationService } from '../../../services/companies/fees-information.service';
import { FollowUpsRemindersService } from '../../../services/companies/follow-ups-reminders.service';
import { GeneralSettingsService } from '../../../services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { InsurancesService } from '../../../services/workspaces/insurances.service';
import { JobCarrierNote } from 'src/app/entities/workspace/job-carrier-note';
import { LocalInventoryRoom } from 'src/app/entities/companies/local-inventory-room';
import { LocalTariffSetting } from 'src/app/entities/admin/local-tariff-setting';
import { LocalTariffSettingsService } from 'src/app/services/workspaces/local-tariff-settings.service';
import { QuickInventoryEstimatesService } from 'src/app/services/companies/quick-inventory-estimates.service';
import { QuotesItemsContainersService } from '../../../services/companies/quotes-items-containers.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';
import { StorageCompaniesService } from 'src/app/services/workspaces/storage-companies.service';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { TariffAdditionalConcept } from 'src/app/entities/workspace/tariff-additional-concept';
import { TariffAdditionalConceptsService } from 'src/app/services/workspaces/tariff-additional-concepts.service';
import { TariffVersion } from 'src/app/entities/workspace/tariff-version';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { Title } from '@angular/platform-browser';
import { Warehouse } from 'src/app/entities/workspace/warehouse';
import { WarehousesService } from 'src/app/services/workspaces/warehouses.service';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { PhoneCallsService } from 'src/app/services/companies/phone-calls.service';
import { SmsTemplatesService } from 'src/app/services/workspaces/sms-templates.service';
import { SmsTemplate } from 'src/app/entities/workspace/sms-template';
import { SmsService } from 'src/app/services/companies/sms.service';
import { PhoneExtensionsService } from 'src/app/services/companies/phone-extensions.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';

declare const google;
declare const jQuery;
declare const swal;

@Component({
    selector: 'app-estimates-details',
    templateUrl: './estimates-details.component.html',
    styleUrls: ['./estimates-details.component.scss']
})
export class EstimatesDetailsComponent implements OnInit, OnDestroy {

    @ViewChild('additionalsModal') additionalsModal: ElementRef;
    @ViewChild('additionalsModalService') additionalsModalService: ElementRef;
    @ViewChild('autoLocalTariffModal') autoLocalTariffModal: ElementRef;
    @ViewChild('autoTransportModal') autoTransportModal: ElementRef;
    @ViewChild('conceptAmount') conceptAmount: ElementRef;
    @ViewChild('contactsModal') contactsModal: ElementRef;
    @ViewChild('discountModal') discountModal: ElementRef;
    @ViewChild('duplicateLeadModal') duplicateLeadModal: ElementRef;
    @ViewChild('editEstimateReferenceModal') editEstimateReferenceModal: ElementRef;
    @ViewChild('editQuickQuoteFormModal') editQuickQuoteFormModal: ElementRef;
    @ViewChild('floorModal') floorModal: ElementRef;
    @ViewChild('inventoryItemsLocalModal') inventoryItemsLocalModal: ElementRef;
    @ViewChild('levelOfLiabilityModal') levelOfLiabilityModal: ElementRef;
    @ViewChild('localJobModal') localJobModal: ElementRef;
    @ViewChild('localWarehouseModal') localWarehouseModal: ElementRef;
    @ViewChild('mapView') entityForm: ElementRef;
    @ViewChild('mapView') mapView: ElementRef;
    @ViewChild('maxDiscountModal') maxDiscountModal: ElementRef;
    @ViewChild('modalCarrierNotes') modalCarrierNotes: ElementRef;
    @ViewChild('modalfollowUpsReminders') modalfollowUpsReminders: ElementRef;
    @ViewChild('modalLead') modalLead: ElementRef;
    @ViewChild('modalSetTariffDiscount') modalSetTariffDiscount: ElementRef;
    @ViewChild('movingFreightModal') movingFreightModal: ElementRef;
    @ViewChild('movingModal') movingModal: ElementRef;
    @ViewChild('newContactsModal') newContactsModal: ElementRef;
    @ViewChild('packerspacking') packerspacking: ElementRef;
    @ViewChild('packersunpacking') packersunpacking: ElementRef;
    @ViewChild('quickQuoteFormModal') quickQuoteFormModal: ElementRef;
    @ViewChild('stopPointModal') stopPointModal: ElementRef;
    @ViewChild('modalSendTextMessage') modalSendTextMessage: ElementRef;

    localInventoryRooms: LocalInventoryRoom[];
    private estimateId: string;
    private estimateMeasureUnit: string;
    private map;
    private tarrifAdditionalCharges: TariffAdditionalConcept[];
    private tarrifAdditionalDiscounts: TariffAdditionalConcept[];
    public additionals: EstimateAdditionalCharge[];
    public additionalServicesOptions: Array<string>;
    public advertiser_id: string;
    public advertiser: Advertiser;
    public advertisers: Advertiser[];
    public arrayFullProtection: EstimateInsurance[];
    public autoTransport: EstimateTransportVehicles;
    public autoTransportList: Array<EstimateTransportVehicles>;
    public balanceAmount: number;
    public balances: Array<BalanceView>;
    public blackoutMoveDates: Array<any>;
    public bulkyFee: Array<any>;
    public calculatedLiabilityAmount: number;
    public carrier: Carrier;
    public carrierContact: Array<CarrierContact>;
    public carrierNote: JobCarrierNote;
    public carrierNotes: Array<JobCarrierNote>;
    public cfLbsRatio: number;
    public colorPerCf: string;
    public concept: EstimateAdditionalCharge;
    public constantsMessages = ConstantsMessages;
    public currentInsuranse: EstimateInsurance;
    public customer: Customer;
    public customerRemarks: EstimateCustomerRemark;
    public customers: Array<Customer>;
    public customerToEdit: Customer;
    public dataTariffService: TariffAdditionalConcept[];
    public deductibleLevels: Array<DeductibleLevel>;
    public defaultAmountLiability: number;
    public deliveryToEdit: EstimateDelivery;
    public disableButtonSaveStopPoint: boolean;
    public discounts: EstimateAdditionalCharge[];
    public divCompleteClass: 'overflowTrue' | 'overflowFalse';
    public editlocalEstimateCharge: EstimateAdditionalCharge;
    public editReference: string;
    public emailTemplate: EmailTemplate;
    public employees: Array<Employee>;
    public enableblackoutMoveDate: boolean;
    public estimate: EstimateView;
    public estimateFreight: EstimateFreights;
    public estimateLocalTariff: EstimateLocalTariff;
    public estimatePackerPacking: EstimateWorker;
    public estimatePackerUnPacking: EstimateWorker;
    public estimateStopPoint: EstimateStopPoint;
    public estimateStorages: EstimateStorage;
    public estimateTariffDiscount: EstimateTariffDiscount;
    public followUpReminder: EstimateFollowUp;
    public followUpReminders: Array<EstimateFollowUpView>;
    public followupsremarks: EstimateNote;
    public freightsList: Array<EstimateFreights>;
    public from: any;
    public fromAddress: Address;
    public fromSecondAddress: Address;
    public fullAddress: any;
    public infowindow;
    public itemsContainerInventory: Array<EstimateInventoryContainer>;
    public localEstimateCharges: any[];
    public localFuelSurcharge: number;
    public localTariff: LocalTariffSetting;
    public localTariffs: LocalTariffSetting[];
    public localTariffSetting: string;
    public localWarehouseCompanyId: string;
    public localWarehouseType: 'INTERNAL' | 'EXTERNAL' | any;
    public localWarehouseWarehouseId: string;
    public lock_the_rate: 'YES' | 'NO';
    public manualInsuranse;
    public maxDiscount: number;
    public miles: any;
    public movingDuplicateType: string;
    public newCustomer: Customer;
    public notesColors: Array<string>;
    public option: TariffAdditionalConcept;
    public permissionManageServicesInAnEstimate: boolean;
    public pickupToEdit: EstimatePickup;
    public quickQuoteAdditionals: EstimateAdditionalCharge[];
    public rangeDate: boolean;
    public rowCustomerRemarks: Array<EstimateCustomerRemarkView>;
    public rowEstimatesEmailTemplates: Array<EmailTemplate>;
    public rowfollowupsremarks: Array<EstimateNoteView>;
    public searchCustomer: string;
    public selledFeets: 'equal' | 'lower' | 'higher';
    public stairFeeByFloor: number;
    public stopPoints: Array<EstimateStopPoint>;
    public storageCompanies: StorageCompany[];
    public tariffType: string;
    public tariffVersion: TariffVersion;
    public to: any;
    public toAddress: Address;
    public toSecondAddress: Address;
    public total: number;
    public totalBalance: number;
    public totalItems: number;
    public totalTimeDistance: any;
    public typeFloor: string;
    public vechicleType;
    public warehouses: Warehouse[];
    public warehouseSideType: 'PICKUP' | 'DELIVERY';
    public whType: 'PICKUP' | 'DELIVERY';
    public workDepartments: Array<WorkDepartment>;
    public workspaceSettings: WorkspaceSetting;
    public dataFormPhone: any;
    public customSmsDestinationNumber: boolean;
    public employeeNums: any;
    public smsTemplates: Array<SmsTemplate>;
    public attachPortalLink: boolean;
    public prePickup: boolean;
    public extensions:any[];
    public line:any[];
    public sms_line: any[];


    constructor(
        private advertisersService: AdvertisersService,
        private balancesService: BalancesService,
        private blackoutMoveDatesService: BlackoutMoveDatesService,
        private carriersCompanyService: CarriersCompanyService,
        private carriersContactsCompanyService: CarriersContactsCompanyService,
        private currentRoute: ActivatedRoute,
        private customersService: CustomersService,
        private deductibleLevelsService: DeductibleLevelsService,
        private employeesCompanyService: EmployeesCompanyService,
        private estimateCarrierNotesService: EstimateCarrierNotesService,
        private estimatesAdditionalChargesService: EstimatesAdditionalChargesService,
        private estimatesCustomerRemarksService: EstimatesCustomerRemarksService,
        private estimatesEmailTemplatesService: EstimatesEmailTemplatesService,
        private estimatesFollowUpsService: EstimatesFollowUpsService,
        private estimatesService: EstimatesService,
        private estimateStopPointsService: EstimateStopPointsService,
        private feesInformationService: FeesInformationService,
        private followUpsRemindersService: FollowUpsRemindersService,
        private generalSettingsService: GeneralSettingsService,
        private insurancesService: InsurancesService,
        private localTariffSettingsService: LocalTariffSettingsService,
        private quickInventoryEstimatesService: QuickInventoryEstimatesService,
        private quotesItemsContainersService: QuotesItemsContainersService,
        private ref: ChangeDetectorRef,
        private settingsService: SettingsService,
        private storageCompaniesService: StorageCompaniesService,
        private tariffAdditionalConceptsService: TariffAdditionalConceptsService,
        private titleService: Title,
        private warehousesService: WarehousesService,
        private workDepartmentsService: WorkDepartmentsService,
        public authService: AuthService,
        public helperService: HelperService,
        public tariffVersionFactory: TariffVersionService,
        private clientSocketsService: ClientSocketsService,
        private phoneCallService: PhoneCallsService,
        private smsTemplatesService: SmsTemplatesService,
        private smsService: SmsService,
        private phoneExtensionsService: PhoneExtensionsService,
        private phoneLinesService: PhoneLinesService,


        @Inject(LOCALE_ID) private locale: string
    ) {
        this.additionals = [];
        this.quickQuoteAdditionals = [];
        this.additionalServicesOptions = [
            'ATV Bulky Fee',
            'Baby Grand Piano Bulky Fee',
            'Bulky Item Fee',
            'Delivery on Floor X',
            'Elevator Fee',
            'Full Packing Service',
            'Grand Piano Bulky Fee',
            'Long Carry For Delivery',
            'Long Carry For Pick-up',
            'Motor Cycle Bulky Fee',
            'Pickup on Floor X',
            'Riding Lawn Mower Bulky Fee',
            'Shuttle Service for delivery',
            'Stairs fee for pick up',
            'Upright Piano Bulky Fee'
        ];
        this.advertiser = new Advertiser();
        this.arrayFullProtection = [];
        this.autoTransport = new EstimateTransportVehicles();
        this.autoTransportList = [];
        this.balanceAmount = 0;
        this.blackoutMoveDates = [];
        this.bulkyFee = [];
        this.calculatedLiabilityAmount = 0;
        this.carrier = new Carrier();
        this.carrierContact = [];
        this.carrierNote = new JobCarrierNote();
        this.carrierNotes = [];
        this.cfLbsRatio = 0;
        this.defaultAmountLiability = 0;
        this.colorPerCf = 'black';
        this.concept = new EstimateAdditionalCharge();
        this.concept.amount = 0;
        this.currentInsuranse = new EstimateInsurance();
        this.customer = new Customer();
        this.customerRemarks = new EstimateCustomerRemark();
        this.customerToEdit = new Customer();
        this.deductibleLevels = [];
        this.deliveryToEdit = new EstimateDelivery();
        this.disableButtonSaveStopPoint = true;
        this.discounts = [];
        this.divCompleteClass = 'overflowTrue';
        this.emailTemplate = new EmailTemplate();
        this.employees = [];
        this.enableblackoutMoveDate = false;
        this.estimate = new EstimateView();
        this.estimateFreight = new EstimateFreights();
        this.estimatePackerPacking = new EstimateWorker();
        this.estimatePackerUnPacking = new EstimateWorker();
        this.estimateStopPoint = new EstimateStopPoint();
        this.followUpReminder = new EstimateFollowUp();
        this.followUpReminders = [];
        this.followupsremarks = new EstimateNote();
        this.freightsList = [];
        this.freightsList = [];
        this.fromAddress = new Address();
        this.fromSecondAddress = new Address();
        this.helperService.changesBeforeSave = false;
        this.itemsContainerInventory = [];
        this.localFuelSurcharge = 0;
        this.localWarehouseType = 'INTERNAL';
        this.total = 0;
        this.lock_the_rate = 'NO';
        this.maxDiscount = 0;
        this.movingDuplicateType = '';
        this.newCustomer = new Customer();
        this.notesColors = ['note-personal', 'note-fav', 'note-work', 'note-social', 'note-important', 'note-dafault'];
        this.pickupToEdit = new EstimatePickup();
        this.rowCustomerRemarks = [];
        this.rowEstimatesEmailTemplates = [];
        this.rowfollowupsremarks = [];
        this.searchCustomer = '';
        this.selledFeets = 'equal';
        this.stairFeeByFloor = 0;
        this.stopPoints = [];
        this.tariffVersion = new TariffVersion();
        this.toAddress = new Address();
        this.toSecondAddress = new Address();
        this.totalBalance = 0;
        this.totalItems = 0;
        this.typeFloor = '';
        this.vechicleType = [
            { type: 'Ambulance' },
            { type: 'Armored' },
            { type: 'ATV' },
            { type: 'Camper Van' },
            { type: 'Classic' },
            { type: 'Convertible' },
            { type: 'Coupe' },
            { type: 'Crossover' },
            { type: 'Exta LG SUV ' },
            { type: 'Extra Large Van' },
            { type: 'Extra LG Pickup' },
            { type: 'Golf Cart' },
            { type: 'Heavy Equ.' },
            { type: 'Jet Ski' },
            { type: 'Large Pickup' },
            { type: 'Large Sedan' },
            { type: 'Large SUV' },
            { type: 'Mini-Van' },
            { type: 'Motorcycle' },
            { type: 'Pickup' },
            { type: 'Power Boat' },
            { type: 'RV' },
            { type: 'S. Wagon' },
            { type: 'Sail Boat' },
            { type: 'Sedan' },
            { type: 'Small Pickup' },
            { type: 'Small SUV' },
            { type: 'Speed Boat' },
            { type: 'SUV' },
            { type: 'Tall Van' },
            { type: 'Travel Trailer' },
            { type: 'Trike (3 wheel)' },
            { type: 'Truck' },
            { type: 'UTV' },
            { type: 'Van' },
            { type: 'Other' }
        ];
        this.warehouseSideType = 'PICKUP';
        this.workDepartments = [];
        this.estimateMeasureUnit = '';
        this.workspaceSettings = new WorkspaceSetting();
        this.editReference = "";
        this.estimateStorages = new EstimateStorage();
        this.estimateTariffDiscount = new EstimateTariffDiscount();
        this.advertiser_id = '';
        this.localTariffs = [];
        this.localTariff = new LocalTariffSetting()
        this.estimateLocalTariff = new EstimateLocalTariff();
        this.localEstimateCharges = [];

        this.storageCompanies = [];
        this.warehouses = [];
        this.localWarehouseWarehouseId = '';
        this.localWarehouseCompanyId = '';
        this.editlocalEstimateCharge = new EstimateAdditionalCharge;
        this.localTariffSetting = '';
        this.localInventoryRooms = [];
        this.permissionManageServicesInAnEstimate = false;
        this.dataFormPhone = {
            phoneNumberCustomer: '',
            phoneNumberEmployee: '',
            message: ''
        };
        this.customSmsDestinationNumber = false;
        this.smsTemplates = [];
        this.attachPortalLink = false;
        this.prePickup = false;
        this.extensions =[];
        this.line =[];
        this.sms_line = [];

    }

    ngOnInit(): void {
        this.advertisersService.getAll()
            .then((advertisers) => {
                this.advertisers = advertisers;
            });
        this.permissionManageServicesInAnEstimate = this.authService.hasPermissionsCompany(['MANAGE_SERVICES_IN_AN_ESTIMATE']);

    }

    ionViewDidEnter() {
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadAllData(params.id);
            }
        });
    }

    ngAfterContentInit(): void {
        // verficamos si se esta editando


        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadAllData(params.id);

            }
        });

        this.tariffVersion = this.tariffVersionFactory.getTariffVersionActive();
        this.loadGeneralSetting();
        this.loadEmployees();
        this.loadWorkDeparments();
        this.loadEstimateEmailTemplates();
        this.loadDeductibleLevels();
        this.loadFollowUpsReminders();
        this.loadStopPoints();
        this.loadBlackoutMoveDates();
        this.loadWorkspaceSetting();
        this.rangeDate = false;
        jQuery('.popover').remove();
        this.loadLocalTariffs();
    }

    loadAllData(estimateId) {
        this.estimateId = estimateId;
        this.load(estimateId);
        this.loadFollowUps(estimateId);
        this.loadCustomerRemarks(estimateId);
        this.loadCarrierNotes(estimateId);
        this.loadListNums();
        this.loadExtensions();
        this.loadSmsTemplate();
    }

    loadWorkspaceSetting() {
        this.settingsService
            .get()
            .then((responseSettings) => {
                this.workspaceSettings = responseSettings;
            });
    }

    ngOnDestroy(): void {
        this.titleService.setTitle('Moverxpro360');
        this.estimate = undefined; // unset
        delete (this.estimate); // this removes the variable completely

        this.customer = undefined; // unset
        delete (this.customer); // this removes the variable completely

        this.advertiser = undefined; // unset
        delete (this.advertiser); // this removes the variable completely

        this.concept = undefined; // unset
        delete (this.concept); // this removes the variable completely

        this.rowCustomerRemarks = undefined; // unset
        delete (this.rowCustomerRemarks); // this removes the variable completely

        this.customerRemarks = undefined; // unset
        delete (this.customerRemarks); // this removes the variable completely

        this.rowfollowupsremarks = undefined; // unset
        delete (this.rowfollowupsremarks); // this removes the variable completely

        this.followupsremarks = undefined; // unset
        delete (this.followupsremarks); // this removes the variable completely

        this.employees = undefined; // unset
        delete (this.employees); // this removes the variable completely

        this.workDepartments = undefined; // unset
        delete (this.workDepartments); // this removes the variable completely

        this.estimatePackerPacking = undefined; // unset
        delete (this.estimatePackerPacking); // this removes the variable completely

        this.estimatePackerUnPacking = undefined; // unset
        delete (this.estimatePackerUnPacking); // this removes the variable completely

        this.toAddress = undefined; // unset
        delete (this.toAddress); // this removes the variable completely

        this.fromAddress = undefined; // unset
        delete (this.fromAddress); // this removes the variable completely

        this.itemsContainerInventory = undefined; // unset
        delete (this.itemsContainerInventory); // this removes the variable completely

        this.rowEstimatesEmailTemplates = undefined; // unset
        delete (this.rowEstimatesEmailTemplates); // this removes the variable completely

        this.emailTemplate = undefined; // unset
        delete (this.emailTemplate); // this removes the variable completely

    }

    togleScroll() {
        if (this.divCompleteClass == 'overflowTrue') {
            this.divCompleteClass = 'overflowFalse';
        } else {
            this.divCompleteClass = 'overflowTrue';
        }
    }

    private loadBalances() {
        this.balanceAmount = 0;
        this.totalBalance = 0;
        this.helperService.showLoadingMxpro360();
        this.balancesService
            .getById(this.estimate.id, {})
            .then((response) => {
                this.balances = response;
                for (const balance of this.balances) {
                    if (balance.status === 'PAID') {
                        this.balanceAmount = this.balanceAmount + balance.amount;
                    }

                    if (balance.status === 'PAID' && balance.target !== 'STORAGE') {
                        this.totalBalance = this.totalBalance + balance.amount;
                    }
                }

                this.totalBalance = this.estimate.total - this.totalBalance;

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }



    deleteConcept(id) {
        this.helperService.showLoadingMxpro360();
        this.estimatesAdditionalChargesService
            .remove(this.estimate.id, id)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                this.load(this.estimate.id);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.concept = new EstimateAdditionalCharge();
    }

    addCustomerRemarks() {
        if (!this.customerRemarks.remarks) {
            return;
        }
        this.helperService.showLoadingMxpro360();
        this.estimatesCustomerRemarksService
            .add(this.estimate.id, this.customerRemarks)
            .then((response) => {
                this.loadCustomerRemarks(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.customerRemarks = new EstimateCustomerRemark();
    }

    addfollowupsremarks() {
        if (!this.followupsremarks.remarks) {
            return;
        }
        this.helperService.showLoadingMxpro360();
        this.estimatesFollowUpsService
            .add(this.estimate.id, this.followupsremarks)
            .then((response) => {
                this.loadFollowUps(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.followupsremarks = new EstimateNote();
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();
        this.employeesCompanyService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadWorkDeparments() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.workDepartments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadFollowUps(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesFollowUpsService
            .getAllWithFullView(id)
            .then((response) => {
                this.rowfollowupsremarks = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadCustomerRemarks(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesCustomerRemarksService
            .getAllWithFullView(id)
            .then((response) => {
                this.rowCustomerRemarks = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private getAdditionalAndDiscount(response) {
        this.discounts = [];
        this.additionals = [];
        this.quickQuoteAdditionals = [];
        for (const item of response) {
            if (item.amount < 0) {
                this.discounts.push(item);
            } else {
                if (['OPEN_QUICK_QUOTE_QUESTION', 'CLOSED_QUICK_QUOTE_QUESTION'].includes(item.tariff_additional_concepts.behavior)) {
                    this.quickQuoteAdditionals.push(item);
                } else {
                    this.additionals.push(item);
                }
            }
        }


    }

    private loadAdditionalConcept(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesAdditionalChargesService
            .getAll(id)
            .then((response) => {
                this.getAdditionalAndDiscount(response);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadEstimateEmailTemplates() {
        this.helperService.showLoadingMxpro360();
        this.estimatesEmailTemplatesService
            .getAll()
            .then((response) => {
                this.rowEstimatesEmailTemplates = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveMoving() {
        const moving = {
            delivery: {
                address: this.toAddress,
                second_address: this.toSecondAddress
            },
            pickup: {
                address: this.fromAddress,
                second_address: this.fromSecondAddress
            }
        };
        this.helperService.showLoadingMxpro360();

        this.estimatesService
            .updatePickupAndDeliveryAddress(this.estimate.id, moving)
            .then((response) => {
                this.load(this.estimate.id);
                this.loadStopPoints();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    savePackersPacking() {
        this.estimatePackerPacking.total = (this.estimatePackerPacking.workers * this.estimatePackerPacking.hours)
            * this.estimatePackerPacking.cost_per_hour;
        this.estimatesService
            .savePackersPacking(this.estimate.id, this.estimatePackerPacking)
            .then(() => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    savePackersUnPacking() {
        this.estimatePackerUnPacking.total = (this.estimatePackerUnPacking.workers *
            this.estimatePackerUnPacking.hours) * this.estimatePackerUnPacking.cost_per_hour;
        this.estimatesService
            .saveUnackersPacking(this.estimate.id, this.estimatePackerUnPacking)
            .then(() => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveOperationDetails() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, { operation_details: this.estimate.operation_details })
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveAdditionalAgreement() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, { additional_agreement: this.estimate.additional_agreement }, false)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveUsers() {
        const operator = {
            operator: {
                operator_id: this.estimate.operator.operator_id,
                salesman_id: this.estimate.operator.salesman_id,
                work_department_id: this.estimate.operator.work_department_id
            }
        };

        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, operator)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    saveCategory() {
        this.helperService.changesBeforeSave = false;
        const infoBasic = {
            category: this.estimate.category
        }

        this.saveBasicOnDB(infoBasic);
    }

    saveInfoBasic() {
        this.helperService.changesBeforeSave = false;
        const infoBasic = {
            status: this.estimate.status,
            service: this.estimate.service,
            move_type: this.estimate.move_type,
            binding_type: this.estimate.binding_type,
            urgent: this.estimate.urgent,
            confirmed: this.estimate.confirmed,
            inventory_editable_by_customer: this.estimate.inventory_editable_by_customer,
            reference: this.estimate.reference,
            pickup: {
                boxes_delivery_day: this.estimate.pickup.boxes_delivery_day,
                pack_day: this.estimate.pickup.pack_day,
                range_start: this.estimate.pickup.range_start,
                range_end: this.estimate.pickup.range_end,
                use_time_frame: this.estimate.pickup.use_time_frame,
                time_frame_start: this.estimate.pickup.time_frame_start,
                time_frame_end: this.estimate.pickup.time_frame_end
            },
            delivery: {
                deliver_immediately: this.estimate.delivery.deliver_immediately,
                range_end: this.estimate.delivery.range_end,
                range_start: this.estimate.delivery.range_start,
            },
            volume: {
                move_size: this.estimate.volume.move_size,
                //     libs: this.estimate.volume.libs,
                //     cubic_feets: this.estimate.volume.cubic_feets,
                //     miles: this.estimate.volume.miles,
            },
            category: this.estimate.category,
            visual_type: this.estimate.visual_type
        };

        this.saveBasicOnDB(infoBasic);
    }

    saveBasicOnDB(infoToSabe) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, infoToSabe, false)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveCustomDiscount() {
        const data = {
            cf_discount: this.estimate.estimate_route_tariff.cf_discount
        };
        this.feesInformationService
            .setManualDiscountCf(this.estimate.id, data)
            .then(() => {
                this.load(this.estimateId);
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadTariffAdditionalConcept() {
        this.tariffAdditionalConceptsService.behavior('DISCOUNT', this.estimate.service).then((response) => {
            this.tarrifAdditionalDiscounts = response;
        });
        this.tariffAdditionalConceptsService.behavior('CHARGE', this.estimate.service).then((response) => {
            this.tarrifAdditionalCharges = response;
        });
    }

    loadBlackoutMoveDates() {
        this.helperService.showLoadingMxpro360();
        this.blackoutMoveDatesService
            .getAll()
            .then((response) => {
                for (const blackoutMoveDate of response) {
                    this.blackoutMoveDates.push({ from: blackoutMoveDate.date, to: blackoutMoveDate.date });
                }
                this.enableblackoutMoveDate = true;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadCustomers() {
        if (this.searchCustomer.length >= 3) {
            this.customersService.getFiltered(this.searchCustomer).then(responseCustomer => {
                this.customers = responseCustomer;
            });
        }
    }

    private loadGeneralSetting() {
        this.helperService.showLoadingMxpro360();
        this.generalSettingsService
            .get()
            .then((response) => {
                this.localFuelSurcharge = 0;
                this.defaultAmountLiability = response.settings.price_setting.default_amount_liability;
                this.cfLbsRatio = response.settings.price_setting.cf_lbs_ratio == null ? 7: response.settings.price_setting.cf_lbs_ratio;
                this.stairFeeByFloor = 0;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    calculateMaxDiscount() {
        this.maxDiscount = Number(this.estimate.volume.cubic_feets_selled) * Number(this.estimate.estimate_route_tariff.cf_discount);
    }

    openLocalJobModal() {

        if (this.estimate.estimate_local_tariff.quote_mode === 'AUTO') {
            jQuery(this.autoLocalTariffModal.nativeElement).modal('show');
        } else {
            jQuery(this.localJobModal.nativeElement).modal('show');
        }

    }

    setLocalTariffSetting() {
        this.estimateLocalTariff.local_tariff_setting = this.localTariffs.find(tariff => tariff.id === this.localTariffSetting);
        const hours = this.estimate.estimate_local_tariff.calculated_labor_hours / this.estimateLocalTariff.local_tariff_setting.workers;
        this.estimateLocalTariff.labor_workers_tariff.hours = this.roundTime(hours, this.workspaceSettings.auto_local_tariff.round_up_time);
        this.estimateLocalTariff.labor_workers_tariff.cost_per_hour = this.estimateLocalTariff.local_tariff_setting.price_per_hour;
    }

    roundTime(time: number, roundUpTime: string): number {
        const whole = Math.floor(time);
        const fraction = time - whole;
        switch (roundUpTime) {
            case 'ONE_HOUR':
                if (fraction > 0) {
                    time = whole + 1;
                }
                break;
            case 'THIRTY_MINUTES':
                if (fraction > 0 && fraction < 0.5) {
                    time = whole + 0.5;
                }
                if (fraction > 0.5 && fraction <= 0.99) {
                    time = whole + 1;
                }
                break;
            case 'FIFTEEN_MINUTES':
                if (fraction > 0 && fraction < 0.25) {
                    time = whole + 0.25;
                }
                if (fraction > 0.25 && fraction < 0.5) {
                    time = whole + 0.5;
                }
                if (fraction > 0.5 && fraction < 0.75) {
                    time = whole + 0.75;
                }
                if (fraction > 0.75 && fraction <= 0.99) {
                    time = whole + 1;
                }
                break;
        }
        return time;
    }

    saveLocalJobData() {
        this.helperService.showLoadingMxpro360();

        if (this.localTariffSetting) {
            this.estimateLocalTariff.local_tariff_setting = this.localTariffs.find(tariff => tariff.id === this.localTariffSetting);
        }

        // if (this.estimate.estimate_local_tariff.quote_mode === 'AUTO') {
        //     this.estimateLocalTariff.labor_workers_tariff.hours = this.estimateLocalTariff.local_tariff_setting.max_hours;
        //     this.estimateLocalTariff.labor_workers_tariff.workers = this.estimateLocalTariff.local_tariff_setting.workers;
        //     this.estimateLocalTariff.labor_workers_tariff.trucks = this.estimateLocalTariff.local_tariff_setting.trucks;
        //     this.estimateLocalTariff.travel_workers_tariff.workers = this.estimateLocalTariff.local_tariff_setting.workers;
        //     this.estimateLocalTariff.travel_workers_tariff.trucks = this.estimateLocalTariff.local_tariff_setting.trucks;
        // }


        this.estimatesService
            .updateEstimateLocalTariff(this.estimate.id, this.estimateLocalTariff)
            .then(() => {
                this.load(this.estimate.id);
                swal(
                    'Done!',
                    'The estimate has been updated.',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setSelledFeetsClass() {
        this.selledFeets = 'equal';
        if (this.estimate.volume.cubic_feets_selled > this.estimate.volume.cubic_feets) {
            this.selledFeets = 'higher';
        } else if (this.estimate.volume.cubic_feets_selled < this.estimate.volume.cubic_feets) {
            this.selledFeets = 'lower';
        }
    }

    private load(id: string) {
        // this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response) => {

                this.estimate = response;

                if (this.estimate.service === 'LOCAL') {
                    this.estimateLocalTariff = new EstimateLocalTariff();

                    // consulta las configuraciones de redondeo de tiempo
                    this.localTariffSetting = this.estimate.estimate_local_tariff.local_tariff_setting ? this.estimate.estimate_local_tariff.local_tariff_setting.id : null;
                    this.estimateLocalTariff = this.estimate.estimate_local_tariff;
                    if (this.estimateLocalTariff.travel_workers_tariff == undefined) {
                        this.estimateLocalTariff.travel_workers_tariff = new EstimateWorker();
                    }
                    if (this.estimateLocalTariff.labor_workers_tariff == undefined) {
                        this.estimateLocalTariff.labor_workers_tariff = new EstimateWorker();
                    }
                    this.estimateLocalTariff.travel_workers_tariff.miles = this.estimateLocalTariff.travel_workers_tariff.miles ? Number(this.estimateLocalTariff.travel_workers_tariff.miles.toFixed(2)) : null;
                    // valida un valor inicial para manual miles
                    if (this.estimateLocalTariff.travel_workers_tariff.manual_miles == null) {
                        this.estimateLocalTariff.travel_workers_tariff.manual_miles = this.estimateLocalTariff.travel_workers_tariff.miles;
                    }
                    // valida un valor inicial para manual hours
                    if (this.estimateLocalTariff.travel_workers_tariff.manual_hours == null) {
                        this.estimateLocalTariff.travel_workers_tariff.manual_hours = this.estimateLocalTariff.travel_workers_tariff.hours;
                    }
                }

                this.estimateMeasureUnit = this.estimate.estimate_route_tariff.tariff_version.measure_unit;
                this.lock_the_rate = this.estimate.estimate_route_tariff.lock_the_rate ? 'YES' : 'NO';
                this.estimate.volume.cubic_feets_selled = Math.round(this.estimate.volume.cubic_feets_selled * 100) / 100
                this.titleService.setTitle(this.estimate.document.code);
                this.loadBalances();
                this.calculateDiferenceRangeDays();
                this.loadCustomer(this.estimate.customer_id);
                this.loadAutoTransport(id);
                this.loadFreights(id);
                this.calculateLiabilityAmount(id);
                this.calculateMaxDiscount();
                this.loadAdditionalConcept(id);
                this.loadCarrierCandidate();
                this.loadTariffAdditionalConcept();

                this.toAddress = { ... this.estimate.delivery.address };
                this.fromAddress = { ... this.estimate.pickup.address };

                this.to = this.estimate.delivery.address.street;
                this.from = this.estimate.pickup.address.street;
                this.miles = this.estimate.volume.miles.toFixed(2);

                this.totalTimeDistance = Math.round(this.estimate.volume.travel_time * 100) / 100;

                if (this.totalTimeDistance < 1) {
                    const minutes = Math.round(this.totalTimeDistance * 60);
                    this.totalTimeDistance = `${minutes} minutes`;
                } else {
                    this.totalTimeDistance = `${this.totalTimeDistance} hours`;
                }



                this.setSelledFeetsClass();
                this.helperService.calculateTotalDueBalance.emit(this.estimate.id);

                if (this.estimate.status === 'UNSUCCESSFUL_SALE') {
                    this.showAlertChangeEstimateStatus();
                }
                this.validateChangeCubicFeet();

                this.ref.detectChanges();

                this.estimate.estimate_route_tariff.mode = this.estimate.estimate_route_tariff.mode ? this.estimate.estimate_route_tariff.mode : this.estimate.estimate_route_tariff.tariff_version.measure_unit;

                if (this.estimate.storages) {
                    this.estimateStorages = this.estimate.storages
                }

                if (this.estimate.advertiser) {
                    this.advertiser_id = this.estimate.advertiser.id
                }

                if (this.estimate.service === 'LOCAL') {
                    this.loadquickInventoryEstimate(id);
                    this.storageCompaniesService
                        .getAll()
                        .then((response) => {
                            this.storageCompanies = response;
                        });
                    this.warehousesService
                        .getAll()
                        .then((response) => {
                            this.warehouses = response;
                        });
                }

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.loadItemsContainerInventory(id);

    }

    /**
     * Metodo que carga el mapa de rutas
     */
    public async initializeMap(): Promise<void> {
        const THIS = this;
        // Punto inicial del recorrido
        let pointA;
        // Punto final del recorrido
        let pointB;

        // Se valida que existan coordenadas en el geospatial donde se indica que el usuario ha guardado sus direcciones
        if (this.estimate.pickup.address.geospatial.coordinates.length > 0) {
            pointA = new google.maps.LatLng(this.estimate.pickup.address.geospatial.coordinates[1],
                this.estimate.pickup.address.geospatial.coordinates[0]);
            pointB = new google.maps.LatLng(this.estimate.delivery.address.geospatial.coordinates[1],
                this.estimate.delivery.address.geospatial.coordinates[0]);
        } else {
            // Si no hay direcciones guardadas, se busca por el zipcode guardado para indicar el estado de la route delivery
            let pointsPickup;
            let pointsDelivery;
            pointsPickup = await this.getLatLang(this.estimate.pickup.address.zip_code);
            pointsDelivery = await this.getLatLang(this.estimate.delivery.address.zip_code);
            pointA = new google.maps.LatLng(pointsPickup.lat, pointsPickup.lng);
            pointB = new google.maps.LatLng(pointsDelivery.lat, pointsDelivery.lng);
        }

        this.map = new google.maps.Map(
            this.mapView.nativeElement as HTMLElement,
            {
                zoom: 3,
                center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                zoomControl: true
            });

        // Instantiate a directions service.
        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
            map: this.map
        });

        // Se crean los marcadores
        const markerA = new google.maps.Marker({
            position: pointA,
            map: this.map
        });

        const markerB = new google.maps.Marker({
            position: pointB,
            map: this.map
        });

        // Se inicializa el arreglo que dibuja la ruta en el mapa de acuerdo a los puntos
        // establecidos por los puntos de parada
        const waypoints = [];
        let markerStopPoint;
        // Se verifica si hay puntos de parada agregados para ser dibujados y dibujar la ruta
        if (this.stopPoints.length > 0) {
            for (let index = 0; index < this.stopPoints.length; index++) {
                // Se agrega el punto de parada
                const pointRute = new google.maps.LatLng(this.stopPoints[index].address.geospatial.coordinates[0],
                    this.stopPoints[index].address.geospatial.coordinates[1]);

                // Se crea el marcador del punto de parada
                markerStopPoint = new google.maps.Marker({
                    icon: { url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' },
                    position: pointRute,
                    map: this.map
                });

                waypoints.push({
                    location: pointRute,
                    stopover: true
                });

                // Se agrega el evento del marcador de delivery y pickup
                google.maps.event.addListener(markerStopPoint, 'click', async () => {

                    // Se obtiene la direccion del servicio
                    address = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');

                    if (THIS.infowindow) {
                        THIS.infowindow.close();
                    }

                    // Se dibuja en el infowindow la informacion
                    const contentString =
                        '<div id="content">' +
                        '<b class="text-center">' + this.stopPoints[index].type + '</b>' +
                        '<p> Street: ' + this.stopPoints[index].address.street + '</p>' +
                        '<p> City: ' + this.stopPoints[index].address.city + '</p>' +
                        '<p> State: ' + this.stopPoints[index].address.state + '</p>' +
                        '<p> Country: ' + this.stopPoints[index].address.country + '</p>' +
                        '</div>';

                    THIS.infowindow = new google.maps.InfoWindow({
                        content: contentString,
                    });

                    THIS.infowindow.setPosition({
                        lat: this.stopPoints[index].address.geospatial.coordinates[0],
                        lng: this.stopPoints[index].address.geospatial.coordinates[1]
                    });
                    THIS.infowindow.open(THIS.map);
                });
            }
        }

        // Se obtiene la ruta entre los puntos inciales, de parada y finales
        await this.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints);

        let address = '';
        let addressFrom = '';
        let addressTo = '';

        // Se obtienen las direcciones de recogida y entrega
        addressFrom = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');
        addressTo = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');
        this.to = this.estimate.delivery.address.street;
        this.from = this.estimate.pickup.address.street;
        // Se crea la informacion de las direcciones para mostrarlas en el mapa
        const card = document.createElement('div');
        const container = document.createElement('div');
        const titleFrom = document.createTextNode('* From: ');
        const brTitleFrom = document.createElement('br');
        const textFrom = document.createTextNode('Street: ' + this.from + ', City: ' + this.estimate.pickup.address.city + ', State: ' + this.estimate.pickup.address.state);

        const titleTo = document.createTextNode('* To: ');
        const brTitleTo = document.createElement('br');
        const textTo = document.createTextNode('Street: ' + this.to + ', City: ' + this.estimate.delivery.address.city + ', State: ' + this.estimate.delivery.address.state);

        card.setAttribute('id', 'pac-card');
        container.setAttribute('id', 'pac-container');
        container.appendChild(titleFrom);
        container.appendChild(brTitleFrom);
        container.appendChild(textFrom);

        container.appendChild(document.createElement('br'));
        // Se verifica si hay puntos de parada agregados para ser dibujados y dibujar la ruta
        if (this.stopPoints.length > 0) {
            for (let index = 0; index < this.stopPoints.length; index++) {
                // Se agrega la informacion del stop point para mostarse en el detalle de la ruta
                const street = this.stopPoints[index].address.street === null ? '' : 'Street: ' + this.stopPoints[index].address.street;
                const city = this.stopPoints[index].address.city === null ? '' : ' City: ' + this.stopPoints[index].address.city;
                const state = this.stopPoints[index].address.state === null ? '' : ' State: ' + this.stopPoints[index].address.state;

                container.appendChild(document.createElement('br'));
                container.appendChild(document.createTextNode(this.stopPoints[index].type === 'PICKUP' ? '* Pickup: ' : '* Delivery: '));
                container.appendChild(document.createElement('br'));
                container.appendChild(document.createTextNode(street + city + state));
            }
        }
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createElement('br'));
        container.appendChild(titleTo);
        container.appendChild(brTitleTo);
        container.appendChild(textTo);
        container.appendChild(document.createElement('br'));
        card.appendChild(container);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(card);
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createTextNode('* Miles: '));
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createTextNode(this.miles));
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createTextNode('* Time: '));
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createTextNode(this.totalTimeDistance));

        // Se valida si hay un infowindow abierto, este sea cerrado
        function showAddresses() {
            if (THIS.infowindow) {
                THIS.infowindow.close();
            }
        }

        showAddresses();
        // Evento del click en el mapa
        THIS.map.addListener('click', (e) => {
            showAddresses();
        });

        google.maps.event.addListener(markerA, 'click', async () => {

            if (THIS.infowindow) {
                THIS.infowindow.close();
            }

            // Se dibuja en el infowindow la informacion
            const contentString =
                '<div id="content">' +
                '<b class="text-center">FROM</b>' +
                '<p> Street: ' + this.from + '</p>' +
                '<p> City: ' + this.estimate.pickup.address.city + '</p>' +
                '<p> State: ' + this.estimate.pickup.address.state + '</p>' +
                '<p> Country: ' + this.estimate.pickup.address.country + '</p>' +
                '</div>';

            THIS.infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            THIS.infowindow.setPosition({
                lat: pointA.lat(),
                lng: pointA.lng()
            });
            THIS.infowindow.open(THIS.map);
        });

        google.maps.event.addListener(markerB, 'click', async () => {
            if (THIS.infowindow) {
                THIS.infowindow.close();
            }
            const contentString =
                '<div id="content">' +
                '<b class="text-center">TO</b>' +
                '<p> Street: ' + this.to + '</p>' +
                '<p> City: ' + this.estimate.delivery.address.city + '</p>' +
                '<p> State: ' + this.estimate.delivery.address.state + '</p>' +
                '<p> Country: ' + this.estimate.delivery.address.country + '</p>' +
                '</div>';

            THIS.infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            THIS.infowindow.setPosition({
                lat: pointB.lat(),
                lng: pointB.lng()
            });
            THIS.infowindow.open(THIS.map);
        });

    }

    /**
     * Obtiene la ruta con los puntos de origen, puntos de parada (si hay) y el destino
     */
    private calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints): Promise<any> {

        return new Promise((resolve) => {
            directionsService.route({
                origin: pointA,
                destination: pointB,
                waypoints,
                travelMode: google.maps.TravelMode.DRIVING
            }, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setOptions({ suppressMarkers: true });
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    /**
     * Obtiene corrdenadas de un zipcode
     *
     * @param zipcode Zipcode a buscar
     */
    private getLatLang(zipcode) {
        return new Promise((resolve, reject) => {
            let lat: string;
            let lng: string;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: 'zipcode ' + zipcode }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    resolve({ lat, lng });
                } else {
                    reject('Request failed.');
                }
            });
        });
    }

    /**
     * calcula y asigna el valor asegurable de un estimado
     * @param estimateId
    */
    calculateLiabilityAmount(estimateId: string) {
        this.estimatesService.calculateLiabilityAmount(estimateId).then((response: { calculate_liability_amount: number }) => {
            this.calculatedLiabilityAmount = response.calculate_liability_amount;
            this.manualInsuranse = response.calculate_liability_amount;
        })
            .catch((error) => {
                console.error('error', error);
            });
    }

    private loadAutoTransport(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService.getAutoTransport(id).then((response) => {
            this.autoTransportList = response;
        })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadFreights(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService.getFreight(id).then((response) => {
            this.freightsList = response;
        })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadItemsContainerInventory(id: string) {

        this.totalItems = 0;

        this.quotesItemsContainersService
            .get(id)
            .then((response) => {
                const tmpColors = ['note-personal', 'note-fav', 'note-work', 'note-social', 'note-important', 'note-dafault'];
                if (response.length > tmpColors.length) {
                    this.notesColors = [];
                    for (let i = 0; i < response.length; i++) {
                        this.notesColors.push(tmpColors[Math.random() * tmpColors.length]);

                    }
                }

                for (const key of response) {
                    for (const item of key.items) {
                        this.totalItems = this.totalItems + item.quantity;
                    }
                }

                this.itemsContainerInventory = response;
                this.chargeBulkyFee();

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    chargeBulkyFee() {
        this.bulkyFee = [];
        const that = this;
        for (let container of this.itemsContainerInventory) {
            for (let item of container['items']) {
                if (item.inventory_item.bulky_fee) {
                    if (item.inventory_item.bulky_fee.name) {
                        that.bulkyFee.push(item);
                    }
                }
            }
        }
    }

    private loadCustomer(id: string) {
        this.helperService.showLoadingMxpro360();
        this.customersService
            .getById(id)
            .then((response) => {
                this.customer = response;
                this.dataFormPhone.phoneNumberCustomer = this.customer.phone
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    validateDiscountAmount() {
        return new Promise((resolve) => {
            const maxDiscountByCf = Number(this.estimate.estimate_route_tariff.cf_discount);
            // si no hay un tope establecido se permite el descuento

            if (maxDiscountByCf === 0 || this.authService.hasPermissionCompany('ESTIMATE_MANAGE_DISCOUNTS')) {
                resolve(true);
            } else {
                let currentDiscount = Math.abs(this.concept.amount);

                this.discounts.forEach(concept => {

                    // consulta tipo de additional
                    const discountFullPackingService = this.checkDiscountFullPacking(concept);

                    if (concept.amount < 0 && !discountFullPackingService && this.concept.id !== concept.id) {
                        currentDiscount += Math.abs(concept.amount);
                    }
                });

                // retorna valor booleano validando la posibilidad de hacer descuento
                resolve(currentDiscount <= this.maxDiscount);
            }
        });
    }

    checkDiscountFullPacking(additionalCharge: EstimateAdditionalCharge) {
        let isFullPacking = false;
        for (let tarrifAdditionalCharges of this.tarrifAdditionalDiscounts) {
            if (additionalCharge.tariff_additional_concept_id == tarrifAdditionalCharges.id &&
                tarrifAdditionalCharges.behavior == 'DISCOUNT_FULL_PACKING_SERVICE') {
                isFullPacking = true;
                break;
            }
        };
        return isFullPacking;
    }


    async addConcept() {
        this.concept.amount = this.concept.amount === null || this.concept.amount === undefined ? 0 : this.concept.amount;

        if (this.concept.name === null || this.concept.name === undefined || this.concept.name === '') {
            swal({
                title: 'you must indicate the name of the concept',
                padding: '2em'
            });
            return;
        }
        /*
        Se elimina la validacion que se ingrese un valor diferente a cero, porque por defecto en el
        viejo sistema se crea un detalle de la estimacion, el cual no tiene un precio y se va a usar como
        concepto adicional
        if (this.concept.amount === 0) {
          swal({
            title: 'you must indicate the value of the concept',
            padding: '2em'
          });
          return;
        }
        */

        // el amount negativo representa un descuento
        if (this.concept.amount < 0) {
            const validDiscount = await this.validateDiscountAmount();
            if (!validDiscount) {
                swal({
                    title: 'The total discount is higher than the maximum discount allowed $' + this.maxDiscount.toFixed(2),
                    padding: '2em'
                });
                return;
            }
        }

        this.helperService.showLoadingMxpro360();
        this.estimatesAdditionalChargesService
            .add(this.estimate.id, this.concept)
            .then((response) => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.concept = new EstimateAdditionalCharge();
    }

    private loadDeductibleLevels() {
        this.helperService.showLoadingMxpro360();
        this.deductibleLevelsService
            .getAll()
            .then((response) => {
                this.deductibleLevels = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    public saveInfoFees(): void {
        this.helperService.showLoadingMxpro360();

        this.estimatesService
            .getById(this.estimate.id)
            .then(async (response: any) => {
                const CFInventoryBD = Number(response.volume.cubic_feets);
                const CFInventoryEstimate = Number(this.estimate.volume.cubic_feets);

                if (CFInventoryBD !== CFInventoryEstimate) {
                    this.helperService.hideLoadingMxpro360();
                    swal({
                        title: 'The CF calculated by the inventory on this job is different from what is entered in the fees information.',
                        text: 'Do you want to save this info and overwrite the CF calculated by the inventory on this job?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, Overwrite this!'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.continueSavingFeesInfo(response);
                            }
                        });
                } else {
                    this.continueSavingFeesInfo(response);
                }
            });

    }

    async continueSavingFeesInfo(response: any) {
        let CFSelled = Number(this.estimate.volume.cubic_feets_selled);
        if (response.volume.libs !== this.estimate.volume.libs) {
            await this.generalSettingsService
                .get()
                .then((response) => {
                    if (this.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS') {
                        CFSelled = Number((this.estimate.volume.libs * (response.settings.price_setting.cf_lbs_ratio)).toFixed(2));
                    } else {
                        CFSelled = Number((this.estimate.volume.libs / (response.settings.price_setting.cf_lbs_ratio)).toFixed(2));
                    }
                });
        }

        if (this.estimate.estimate_route_tariff.min_cf > CFSelled) {
            CFSelled = Number(this.estimate.estimate_route_tariff.min_cf);
        }

        const data = {
            cubic_feets_selled: Number(CFSelled.toFixed(2)),
            cf_discount: this.estimate.estimate_route_tariff.cf_discount
        };

        this.feesInformationService
            .setCubicFeets(this.estimate.id, data)
            .then(() => {
                this.load(this.estimateId);
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeBoxsDeld(date: number) {
        this.helperService.changesBeforeSave = true;
        this.estimate.pickup.boxes_delivery_day = date;
    }

    changePackDay(date) {
        this.helperService.changesBeforeSave = true;
        this.estimate.pickup.pack_day = date;
    }

    changeDeliveryDate(date) {
        this.helperService.changesBeforeSave = true;
        this.estimate.delivery.range_start = date;
        this.estimate.delivery.range_end = date;
    }

    changePickUp(date) {
        this.helperService.changesBeforeSave = true;
        this.estimate.pickup.range_start = date[0];
        this.estimate.pickup.range_end = date[1];
    }

    changeRequestDelivery(date) {
        this.helperService.changesBeforeSave = true;
        this.estimate.delivery.range_start = date[0];
        this.estimate.delivery.range_end = date[1];
    }

    saveInsurance() {
        this.helperService.showLoadingMxpro360();

        this.estimatesService
            .patchEntity(this.estimate.id, { insurance: this.currentInsuranse }, true)
            .then((response) => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveExtra() {
        // indica si el valor inicial de la medida fue alterado o no
        const altered = this.estimate.estimate_route_tariff.tariff_version.measure_unit !== this.estimateMeasureUnit;

        const data: any = {
            charges: {
                fuel_surcharge_percentage: this.estimate.charges.fuel_surcharge_percentage
            },
            estimate_route_tariff: {
                cf_cost: this.estimate.estimate_route_tariff.cf_cost,
                manual_rate: true,
                tariff_version: {
                    alterned: altered,
                    measure_unit: this.estimate.estimate_route_tariff.tariff_version.measure_unit

                },
                lock_the_rate: this.lock_the_rate == 'YES'
            }
        };

        if (this.estimate.service === 'LOCAL') {
            if (this.localFuelSurcharge < 0) {
                swal({
                    title: 'Error',
                    text: 'Enter a value equal to or greater than zero',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'yes duplicate'
                });

                this.loadGeneralSetting();

                return;
            }

            data.charges.fuel_surcharge_percentage = this.localFuelSurcharge;
        }

        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, data, true)
            .then((response) => {
                this.load(this.estimate.id);
                this.localFuelSurcharge = 0;
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeCostCfToLbs() {
        if (this.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET') {
            this.estimate.estimate_route_tariff.cf_cost = Number((this.estimate.estimate_route_tariff.cf_cost * 7).toFixed(2));
        } else {
            this.estimate.estimate_route_tariff.cf_cost = Number((this.estimate.estimate_route_tariff.cf_cost / 7).toFixed(2));
        }
    }

    /**
     * calcula el valor del full packing de acuerdo a la ruta
     */
    fullPacking(option: TariffAdditionalConcept, type: string) {
        this.concept.tariff_additional_concept_id = option.id
        this.concept.name = option.name;
        this.concept.type = 'PICKUP';

        if (type === 'CHARGE') {
            // agrega el full packing
            this.concept.amount = this.estimate.estimate_route_tariff.packing_cost * this.estimate.volume.cubic_feets_selled;
            this.addConcept();
        } else {
            // agrega el descuento del full packing
            this.concept.amount = this.estimate.estimate_route_tariff.packing_discount * this.estimate.volume.cubic_feets_selled;
            this.addDiscount();
        }
    }

    showOnFloor(typeFloor: string) {
        this.typeFloor = typeFloor;
        jQuery(this.floorModal.nativeElement).modal('show');
    }

    floor(qty: number) {

        if (qty < 3) {
            swal(
                'Warning!',
                'The floor must be greater than 2',
                'warning'
            );
            return;
        }
        this.concept.amount = (qty * this.stairFeeByFloor) - (this.stairFeeByFloor + this.stairFeeByFloor);
        if (this.typeFloor == 'pickUp') {
            this.concept.name = 'PICKUP ON FLOOR ' + qty;
        } else {
            this.concept.name = 'DELIVERY ON FLOOR ' + qty;
        }
        this.addConcept();
    }

    addFollowUpReminder() {
        this.helperService.showLoadingMxpro360();
        this.followUpReminder.estimate_id = this.estimate.id;
        this.followUpReminder.employee_id = this.authService.workspaceSession.employee.id;
        this.followUpsRemindersService.saveChanges(this.followUpReminder.id, this.followUpReminder).then(() => {
            this.loadFollowUpsReminders();
        })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeDate(event) {
        this.followUpReminder.date = event;
    }

    loadFollowUpsReminders() {
        this.followUpsRemindersService.loadFollowUpByEstimateId(this.estimateId).then((response) => {
            this.followUpReminders = response;
        })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    editFollowUpReminders(followUpReminder) {
        jQuery(this.modalfollowUpsReminders.nativeElement).modal('show');
        if (followUpReminder !== null) {
            this.followUpReminder = followUpReminder;
        }
    }

    /**
     * Abre el modal para agregar un follow up reminder
     */
    openModalFollowUpReminders() {
        this.followUpReminder = new EstimateFollowUp();
        // Se obtiene la fecha y la hora actual
        this.getDates();
        jQuery(this.modalfollowUpsReminders.nativeElement).modal('show');
    }

    /**
     * Metodo que obtiene las fechas actuales del mes en el calendario
     */
    getDates(): void {
        const date = new Date();
        // Obtiene el primer dia del mes
        const firsDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // Obtiene el ultimo dia del mes
        const mounth = date.getMonth() + 1;
        const hours = date.getHours() - 12;
        const minutes = date.getMinutes();
        const milliseconds = date.getMilliseconds();
        // date.get
        // Se convierte el primer dia en cadena para agregar un 0 al inicio
        const firstCalendarDay = date.getDate().toString();
        // Se convierte el mes en cadena para agregar un 0 al inicio
        const calendarMonth = mounth.toString();

        // Si el dia contiene un solo digito, se agrega un 0 al inicio
        const firstDayConverted = firstCalendarDay.length === 1 ? '0' + firstCalendarDay : firstCalendarDay;
        // Si el mes contiene un solo digito, se agrega un 0 al inicio
        const monthCalendarConverted = calendarMonth.length === 1 ? '0' + calendarMonth : calendarMonth;

        this.followUpReminder.date = new Date().getTime();
    }

    deleteFollowUpReminders(id: string) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.followUpsRemindersService
                        .deleteFollowUp(id)
                        .then((response) => {
                            this.loadFollowUpsReminders();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    /**
     * guarda datos de auto transport
     */
    saveAutoTransport() {
        if (this.autoTransport.deposit > this.autoTransport.price) {
            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_PRICE_DEPOSIT);
            return;
        }
        this.helperService.showLoadingMxpro360();
        if (this.autoTransport.id === null) {
            this.estimatesService
                .saveAutoTransport(this.estimate.id, this.autoTransport)
                .then(() => {
                    this.load(this.estimate.id);
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        } else {
            this.estimatesService
                .editAutoTransport(this.estimate.id, this.autoTransport)
                .then(() => {
                    this.load(this.estimate.id);
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }

    }

    /**
     * guarda datos de auto transport
     */
    saveFreight() {

        this.helperService.showLoadingMxpro360();
        if (this.estimateFreight.id === null) {
            this.estimatesService
                .saveFreight(this.estimate.id, this.estimateFreight)
                .then(() => {
                    this.load(this.estimate.id);
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        } else {
            this.estimatesService
                .editFreight(this.estimate.id, this.estimateFreight)
                .then(() => {
                    this.load(this.estimate.id);
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }
    }
    /**
     * edita un registro de auto transport
     * @param transport
     */
    editAutoTransport(transport: EstimateTransportVehicles) {
        this.autoTransport = transport;
        this.openAutoTransportModal(true);
    }
    /**
     * pregunta si se desea eliminar un auto transport
     * @param id
     */
    removeAutoTransport(id: string) {
        swal({
            title: 'Are you sure?',
            text: "Are you sure do you want to delete this record?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.estimatesService
                        .removeAutoTransport(this.estimate.id, id)
                        .then(() => {
                            this.load(this.estimate.id);
                            swal(
                                'Deleted!',
                                'Record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }
    /**
     * edita un registro de  freight
     * @param freight
     */
    editFreight(freight: EstimateFreights) {
        this.estimateFreight = freight;
        this.openMovingFreightModal(true);
    }
    /**
     * pregunta si se desea eliminar un freight
     * @param id
     */
    removeFreight(id: string) {
        swal({
            title: 'Are you sure?',
            text: "Are you sure do you want to delete this record?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.estimatesService
                        .removeFreight(this.estimate.id, id)
                        .then(() => {
                            this.load(this.estimate.id);
                            swal(
                                'Deleted!',
                                'Record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    /**
     *
     * @param type guarda el tipo de insurance seleccionado
     */
    setInsuranceType(type: string) {
        if (type == "FULL_VALUE_PROTECTION") {
            this.currentInsuranse.type = "FULL_VALUE_PROTECTION";

        } else {
            this.currentInsuranse.type = "RELEASE_VALUE";
            this.currentInsuranse.liability_amount = 0;
            this.currentInsuranse.valuation_charge = 0;
            this.currentInsuranse.deductible_amount = 0;
        }
    }

    /**
     * guarda los datos de un liability_amount cuando se selecciona un check box
     * @param selectedInsuranse
     */
    selectLiabilityAmount(selectedInsuranse: EstimateInsurance) {
        this.currentInsuranse.type = "FULL_VALUE_PROTECTION";
        this.currentInsuranse.liability_amount = selectedInsuranse.liability_amount;
        this.currentInsuranse.valuation_charge = selectedInsuranse.valuation_charge;
        this.currentInsuranse.deductible_amount = selectedInsuranse.deductible_amount;
    }

    /**
     * asigna valor manual a liability_amount
     */
    setCurrentInsurance() {
        this.currentInsuranse.liability_amount = this.manualInsuranse;
    }

    /**
     * obitiene lista de insuranses
     */
    getInsuranses(isFromOpen: boolean) {
        // /insurances/{la}
        this.helperService.showLoadingMxpro360();
        if (isFromOpen) {
            this.manualInsuranse = Number(this.estimate.insurance?.liability_amount) > 0
                ? this.estimate.insurance?.liability_amount : this.calculatedLiabilityAmount;
        }
        this.insurancesService
            .getInsuranses(this.manualInsuranse)
            .then((response: any) => {
                this.arrayFullProtection = response;
                // asigna el valor digitado por el usuario
                this.manualInsuranse = response[0].liability_amount;
            })
            .catch((error) => {
                if (error.error.message) {
                    this.showWarningMessage('Attention', error.error.message);
                }
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Muestra mensajes de alerta
     * @param title
     * @param message
     */
    showWarningMessage(title: string, message: string) {
        swal({
            title: title,
            text: message
        });
    }

    openModalAdditional(concept) {
        this.concept = concept !== null ? { ...concept } : new EstimateAdditionalCharge();
        jQuery(this.additionalsModal.nativeElement).modal('show');
        setTimeout(() => {
            this.conceptAmount.nativeElement.focus();
        }, 1000);
    }

    openModalDiscount(option: TariffAdditionalConcept) {
        jQuery(this.discountModal.nativeElement).modal('show');
        this.option = option;
        setTimeout(() => {
            this.conceptAmount.nativeElement.focus();
        }, 1000);
    }

    async addDiscount() {
        // this.concept.amount = this.concept.amount > 0 ? this.concept.amount * (-1) : this.concept.amount;
        this.helperService.showLoadingMxpro360();
        this.estimatesAdditionalChargesService
            .add(this.estimate.id, this.concept)
            .then((response) => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.concept = new EstimateAdditionalCharge();
    }

    openModalAdditionalService(type: string) {
        this.tariffType = type
        if (type === 'DISCOUNT') {
            this.dataTariffService = this.tarrifAdditionalDiscounts;
        }
        if (type === 'CHARGE') {
            this.dataTariffService = this.tarrifAdditionalCharges;
        }
        jQuery(this.additionalsModalService.nativeElement).modal('show');
    }

    discountPerMile(tarrifId: string) {

        this.estimatesService.addDicountByCf(this.estimate.id, tarrifId).then((success) => {

            this.loadAllData(this.estimateId)
        }, (error) => {

            this.showErrorSwal(error)
        });
    }

    async selectTariffAdditional(option: TariffAdditionalConcept) {
        this.option = option;
        this.concept.name = option.name;
        this.concept.tariff_additional_concepts = { ...option };
        this.concept.tariff_additional_concept_id = option.id;
        this.concept.type = option.stop_type;

        switch (option.behavior) {
            case 'FULL_PACKING_SERVICE':
            // this.fullPacking(option, 'CHARGE');
            // break;
            case "CHARGE":
            case "ELEVATOR_DELIVERY":
            case "ELEVATOR_PICKUP":
            case "LONG_CARRY_DELIVERY":
            case "LONG_CARRY_PICKUP":
            case "SHUTTLE_DELIVERY":
            case "SHUTTLE_PICKUP":
            case "SPLIT_DELIVERY":
            case "SPLIT_PICKUP":
            case "BINDING_FEE":
                // codigo para los cargos

                if (Number(option.value) == 0) {
                    this.openModalAdditional(this.concept);
                } else {
                    this.concept.type = option.stop_type;
                    this.concept.amount = option.value;
                    this.setDataConcept(false, 'CHARGE');
                }
                break;
            case "DISCOUNT":
            case 'DISCOUNT_FULL_PACKING_SERVICE':
            // this.fullPacking(option, 'DISCOUNT');
            // break;
            case 'DISCOUNT_PER_MILE':
                // this.discountPerMile(option.id);
                // break;

                if (Number(option.value) == 0) {
                    // si no hay valor configurado abre popup para preguntar valor de descuento
                    this.openModalDiscount(option);
                } else {
                    this.concept.type = option.stop_type;
                    this.setDataConcept(false, 'DISCOUNT');
                }
                break;
        }
        this.closeModalAdditionalServices()
    }

    async setDataConcept(manual: boolean, type: string) {
        if (type === 'DISCOUNT') {
            const canMakeDiscount = await this.validateDiscountAmount();
            if (canMakeDiscount) {
                this.addDiscount();
            } else {
                swal({
                    title: 'The total discount is higher than the maximum discount allowed $' + this.maxDiscount.toFixed(2),
                    padding: '2em'
                });
            }
        } else {
            this.addConcept();
        }
    }

    closeModalAdditionalServices() {
        jQuery(this.additionalsModalService.nativeElement).modal('hide');
    }

    openModalLead() {
        this.customerToEdit = { ...this.customer }
        jQuery(this.modalLead.nativeElement).modal('show');
    }

    selectAdditionalService(index: number) {
        this.closeModalAdditionalServices();
        switch (index) {
            case 0: {
                // this.fullPacking();
                break;
            }
            case 1: {
                this.showOnFloor('pickUp');
                break;
            }
            case 2: {
                this.showOnFloor('delivery');
                break;
            }
            default: {
                this.concept.name = this.additionalServicesOptions[index];
                this.concept.amount = null;
                this.openModalAdditional(null);
            }
        }
    }

    openModalLevelOfLiability() {
        jQuery(this.levelOfLiabilityModal.nativeElement).modal('show');
        this.getInsuranses(true);
        this.currentInsuranse = Object.assign({}, this.estimate.insurance);
    }
    /**
     * abre el modal de autotransport
     */
    openAutoTransportModal(isEdition: boolean) {
        if (!isEdition) {
            this.autoTransport = new EstimateTransportVehicles();
        }
        jQuery(this.autoTransportModal.nativeElement).modal('show');

        // Organiza los estilos el select2
        jQuery('.select2').css('width', '100%');
        jQuery('#select-reorder').click(() => {
            setTimeout(() => {
                jQuery('.select2-search__field').css('display', 'none');
                jQuery('.select2-results__options').css('height', '170px');
                jQuery('.select2-results__options').css('overflow', 'auto');
            }, 100);
        });
    }

    /**
     * abre el modal de autotransport
     */
    openMovingFreightModal(isEdition: boolean) {
        if (!isEdition) {
            this.estimateFreight = new EstimateFreights();
        }
        jQuery(this.movingFreightModal.nativeElement).modal('show');

        // Organiza los estilos el select2
        jQuery('.select2').css('width', '100%');
        jQuery('#select-reorder').click(() => {
            setTimeout(() => {
                jQuery('.select2-search__field').css('display', 'none');
                jQuery('.select2-results__options').css('height', '170px');
                jQuery('.select2-results__options').css('overflow', 'auto');
            }, 100);
        });
    }

    showErrorSwal(error) {
        swal(
            'Error!',
            error.error.message,
            'error'
        );
    }

    openDuplicateLeadModal() {
        jQuery(this.duplicateLeadModal.nativeElement).modal('show');
    }

    duplicateLead() {
        if (this.movingDuplicateType == '') {
            swal(
                'Attention!',
                'Select a Moving Type.',
                'warning'
            );
            return;
        }
        jQuery(this.duplicateLeadModal.nativeElement).modal('hide');
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .duplicateLead(this.estimate.id, this.movingDuplicateType)
            .then((response: any) => {
                // Redirige a la ventana de estmate
                this.helperService.openNewTab(response.estimate.id, 'estimate');
                swal(
                    'Done!',
                    'The estimate has been duplicated.',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setFrom(from) {
        this.fromAddress = from;
    }

    setTo(to) {
        this.toAddress = to;
    }

    setSecondFrom(from) {
        this.fromSecondAddress = from;
    }

    setSecondTo(to) {
        this.toSecondAddress = to;
    }

    downloadPdf(): void {
        if (this.estimate.service === 'AUTO_TRANSPORT') {
            this.helperService.downloadReport('AUTO_TRANSPORT_ESTIMATES', this.estimate.id);
        }

        if (this.estimate.service === 'LONG') {
            this.helperService.downloadReport('LONG_ESTIMATES', this.estimate.id);
        }

        if (this.estimate.service === 'LOCAL') {
            this.helperService.downloadReport('LOCAL_ESTIMATES', this.estimate.id);
        }

        if (this.estimate.service === 'FREIGHT') {
            this.helperService.downloadReport('FREIGHT', this.estimate.id);
        }
    }

    saveChangesLead() {

        const customer: any = { ...this.customerToEdit }
        customer.estimate_id = this.estimate.id;
        customer.company_id = this.estimate.company_id;
        this.helperService.showLoadingMxpro360();
        this.customersService.edit(customer)
            .then(() => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error("error", error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                jQuery(this.modalLead.nativeElement).modal('hide');
            });
    }

    openContactsModal() {
        // segundo
        jQuery(this.newContactsModal.nativeElement).modal('hide');
        jQuery(this.contactsModal.nativeElement).modal('show');
    }

    closeContactsModal() {
        // segundo
        jQuery(this.contactsModal.nativeElement).modal('hide');
    }

    validateContact(target: 'PHONE' | 'EMAIL') {
        this.helperService.showLoadingMxpro360();
        const data = {
            phone: this.newCustomer.phone,
            email: this.newCustomer.email,
            target
        }
        this.estimatesService.validateContact(this.estimate.id, data)
            .then((responseCustomer: Customer) => {
                this.helperService.hideLoadingMxpro360();
                if (responseCustomer.hasOwnProperty('id')) {
                    const contactFound = "<br/><strong>Name:</strong> " + responseCustomer.name + "<br/>" +
                        '<strong>Email:</strong> ' + responseCustomer.email + "<br/>" +
                        '<strong>Phone:</strong> ' + responseCustomer.phone + "<br/>";
                    swal({
                        title: 'Attention',
                        html: 'we have found a contact with the given information:' + contactFound + '<strong>Do you want to match this contact?</strong>',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, match it!'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.setNewContct(responseCustomer);
                                this.closeNewContactsModal();
                            }
                        });
                }
            })
    }

    setNewContct(customer: Customer) {
        if (this.estimate.contacts_id.indexOf(customer.id) === -1) {
            this.helperService.showLoadingMxpro360();
            this.estimate.contacts_id.push(customer.id);
            this.estimatesService
                .patchEntity(this.estimate.id, { contacts_id: this.estimate.contacts_id }, false)
                .then(() => {
                    swal('Success', 'Contact added successfully', 'success');
                    this.closeContactsModal();
                    this.load(this.estimate.id);
                })
                .catch((error) => {
                    console.error('error', error);
                    this.showErrorSwal(error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }
    }

    openNewContactsModal() {
        this.newCustomer = new Customer();
        this.closeContactsModal();
        jQuery(this.newContactsModal.nativeElement).modal('show');
    }
    saveNewCustomer(): void {
        if (this.newCustomer.name == null || this.newCustomer.email == null || this.newCustomer.phone == null) {
            swal('Error', 'You need to give a name, an email and a phone number', 'error');
            return;
        }

        this.estimatesService.createNewContact(this.estimate.id, this.newCustomer).then(() => {
            swal('Success', 'Contact added successfully', 'success');
            this.closeNewContactsModal();
            this.load(this.estimate.id);
        });
    }

    closeNewContactsModal() {
        jQuery(this.newContactsModal.nativeElement).modal('hide');
    }

    async addAddressStopPoint(event: Address) {
        this.estimateStopPoint.address = event;
        await this.searchWithLongLan(event.geospatial.coordinates[0], event.geospatial.coordinates[1], event.manually_typed);
        this.validateEnableButtonSaveStopPoint();
    }

    saveStopPoint(): void {
        this.estimateStopPoint.estimate_id = this.estimateId;
        this.helperService.showLoadingMxpro360();
        this.estimateStopPointsService
            .save(this.estimateStopPoint)
            .then(() => {
                this.load(this.estimate.id);
                this.loadStopPoints();
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                if (error['error']['message']) {
                    swal('Error', error['error']['message'], 'error');
                } else {
                    this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                }
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadStopPoints(): void {
        this.helperService.showLoadingMxpro360();
        this.estimateStopPointsService
            .getAll(this.estimateId)
            .then(response => {
                this.stopPoints = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    /**
     * Metodo que elimina los stop points listados en el detalle de un estimate
     */
    removeStopPoint(stopPoint: EstimateStopPoint): void {
        this.helperService.showLoadingMxpro360();
        this.estimateStopPointsService
            .remove(stopPoint.estimate_id, stopPoint.id)
            .then(() => {
                this.loadStopPoints();
                this.load(this.estimate.id);
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    validateEnableButtonSaveStopPoint() {
        this.disableButtonSaveStopPoint =
            this.estimateStopPoint.type == null &&
            this.estimateStopPoint.address.zip_code == null;
    }

    private searchWithLongLan(long, lat, manual) {
        return new Promise((resolve) => {

            const latlng = new google.maps.LatLng(long, lat);
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ latLng: latlng }, (results, status) => {

                // Se verifica si la busqueda de la direccion es correcta
                if (status === google.maps.GeocoderStatus.OK) {

                    if (results[0]) {
                        for (let j = 0; j < results[0].address_components.length; j++) {
                            if (results[0].address_components[j].types[0] === 'postal_code') {
                                // Se agrega el zipcode
                                this.estimateStopPoint.address.zip_code = results[0].address_components[j].short_name;
                                if (results[0].address_components[1] && results[0].address_components[2] && !manual) {
                                    this.estimateStopPoint.address.street = results[0].address_components[0].short_name
                                        + ' ' + results[0].address_components[1].short_name + ' ' + results[0].address_components[2].short_name;
                                }
                                resolve(true);
                            }
                        }
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            }, () => {
                resolve(false);
            });
        });
    }

    /**
     * setea variable que avisa quye existen cambios no guardados en informac basica
     */
    public adviceChanges() {
        this.helperService.changesBeforeSave = true;
    }

    openStopPointModal() {
        this.estimateStopPoint = new EstimateStopPoint();
        this.estimateStopPoint.address = new Address();
        this.closeStopPointModal();
        jQuery(this.stopPointModal.nativeElement).modal('show');
    }

    closeStopPointModal() {
        jQuery(this.stopPointModal.nativeElement).modal('hide');
    }

    unsuccessfulSale(): void {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to change the status of your estimate?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Change it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.estimatesService
                        .patchEntity(this.estimate.id, { status: 'UNSUCCESSFUL_SALE' }, false)
                        .then(() => {
                            this.helperService.goToCompanyRouterLink("/estimates");
                        })
                        .catch((error) => {
                            console.error('error', error);
                            this.showErrorSwal(error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    /**
     * Calcular la diferencia en horas de un dia en un delivery para determinar si es un rango o no
     */
    private calculateDiferenceRangeDays(): void {
        if (this.estimate.delivery.range_start && this.estimate.delivery.range_end) {
            const diference = (this.estimate.delivery.range_end - this.estimate.delivery.range_start) / 1000;
            this.rangeDate = diference >= 86400 ? true : false;
        }
    }
    loadCarrierNotes(estimateId: string) {
        this.estimateCarrierNotesService.getAll(estimateId)
            .then(result => {
                this.carrierNotes = result;
            });
    }
    /**
     * abre modal de carriers seteando la informacion inicial
     */
    openModalCarrierNotes(carrierNote: EstimateCarrierNote) {
        if (carrierNote) {
            this.carrierNote = { ...this.carrierNote };
        } else {
            this.carrierNote = new JobCarrierNote();
            this.carrierNote.employee_id = this.authService.workspaceSession.employee.id;
        }
        jQuery(this.modalCarrierNotes.nativeElement).modal('show');
    }
    /**
     * guarda las notas
     */
    saveCarrierNote() {
        this.helperService.showLoadingMxpro360();
        if (this.carrierNote.note !== null && this.carrierNote.note !== '') {
            this.estimateCarrierNotesService.add(this.estimate.id, this.carrierNote)
                .then(() => {
                    this.loadCarrierNotes(this.estimate.id);
                    swal(
                        'Done!',
                        'The carrier note has been saved.',
                        'success'
                    );
                })
                .catch((error) => {
                    console.error('error', error);
                    this.showErrorSwal(error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }
    }

    public removeContact(contact) {

        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete this contact?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    const index = this.estimate.contacts_id.indexOf(contact.id);
                    if (index >= 0) {
                        this.estimate.contacts_id.splice(index, 1);
                        this.estimatesService
                            .patchEntity(this.estimate.id, { contacts_id: this.estimate.contacts_id }, false)
                            .then(() => {
                                swal('Success', 'Contact deleted successfully', 'success');

                                this.load(this.estimate.id);
                            })
                            .catch((error) => {
                                console.error('error', error);
                                this.showErrorSwal(error);
                            })
                            .finally(() => {
                                this.helperService.hideLoadingMxpro360();
                            });
                    }
                }
            });
    }

    /**
     * inicia el cambio de tipo de servicio para auto transport
     */
    setAsAutoTransport() {
        this.setMovingType('AUTO_TRANSPORT');
    }
    /**
     * inicia el cambio de tipo de servicio para mudanza
     */
    setAsMoving() {
        this.setMovingType('LONG');
    }

    /**
     * cambia el tipo de servicio entre autotransport y mudanza
     * @param type 'AUTO_TRANSPORT' | 'LONG'
     */
    setMovingType(type: string) {
        this.helperService.showLoadingMxpro360();
        const data = {
            service: type
        }
        this.estimatesService
            .patchEntity(this.estimate.id, data, true)
            .then(() => {
                this.load(this.estimate.id);
                swal(
                    'Done!',
                    'The estimate has been updated.',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    showAlertChangeEstimateStatus() {
        swal({
            title: 'Unsuccessful Job',
            text: 'Do you want to reopen this job?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'yes'
        }).then((reopen) => {
            if (reopen.value) {
                this.reopenJob();
            }
        });
    }

    reopenJob() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, { status: 'FOLLOW_UP' }, false)
            .then(() => {
                swal('Success', 'Reopened successfully', 'success');

                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    validateForm() {
        // Valida que en el campo de phone solo se ingresen numeros
        setTimeout(() => {
            this.customer.phone = this.customer.phone === null ? '' : this.customer.phone.replace(/\D */g, '');
        },
            1);
    }

    private loadCarrierCandidate(): void {
        this.helperService.showLoadingMxpro360();
        this.carriersCompanyService
            .getCarrierByEstimateId(this.estimate.id)
            .then((response) => {

                this.carrier = response.carrier;

                if (this.carrier) {
                    this.loadContacts();
                }

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Obtiene todos los contactos de un carrier
     */
    private loadContacts(): void {
        this.helperService.showLoadingMxpro360();
        this.carriersContactsCompanyService
            .getFullContactsByCarrier(this.carrier.id)
            .then((response) => {
                this.carrierContact = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * changeNoteVisibilit
     */

    changeNoteVisibility(carrierNote) {
        if (carrierNote.visualization == "PUBLIC") {
            carrierNote.visualization = "PRIVATE";
        } else {
            carrierNote.visualization = "PUBLIC";
        }

        this.estimateCarrierNotesService.edit(this.estimate.id, carrierNote)
            .then(() => {
                this.loadCarrierNotes(this.estimate.id);
                swal(
                    'Done!',
                    'Carrier\'s note visibility has been updated',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    changeMaxDiscount() {
        jQuery(this.maxDiscountModal.nativeElement).modal('show');

    }

    openModalChangeAddress() {
        this.clonePickup();
        this.cloneDelivery();
        jQuery(this.movingModal.nativeElement).modal('show');
    }

    clonePickup() {
        this.pickupToEdit.id = this.estimate.pickup.id;
        this.pickupToEdit.boxes_delivery_day = this.estimate.pickup.boxes_delivery_day;
        this.pickupToEdit.pack_day = this.estimate.pickup.pack_day;
        this.pickupToEdit.range_start = this.estimate.pickup.range_start;
        this.pickupToEdit.range_end = this.estimate.pickup.range_end;
        this.pickupToEdit.pickup_day = this.estimate.pickup.pickup_day;
        this.pickupToEdit.address = { ...this.estimate.pickup.address }
    }

    cloneDelivery() {
        this.deliveryToEdit.id = this.estimate.delivery.id;
        this.deliveryToEdit.deliver_immediately = this.estimate.delivery.deliver_immediately;
        this.deliveryToEdit.range_start = this.estimate.delivery.range_start;
        this.deliveryToEdit.range_end = this.estimate.delivery.range_end;
        this.deliveryToEdit.address = { ...this.estimate.delivery.address }
    }

    copyText(text: string, type: string) {

        if (type === 'phone') {
            text = text.replace(/-/g, '');
            text = text.substr(-10, 10);
        }

        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
    }

    validateChangeCubicFeet() {
        let upTo = 0;
        for (let cf of this.estimate.estimate_route_tariff.cf_fees) {
            if (this.estimate.volume.cubic_feets > cf.up_to) {
                upTo = cf.cost;
            }
        }
        this.colorPerCf = '#515365';
        if (upTo == this.estimate.estimate_route_tariff.cf_cost) {
            return;
        }
        this.colorPerCf = upTo < this.estimate.estimate_route_tariff.cf_cost ? '#0bc17f' : '#e7515a';
    }

    editEstimateReference() {
        jQuery(this.editEstimateReferenceModal.nativeElement).modal('show');
        this.editReference = this.estimate.reference;
    }

    saveEstimateReference() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, { reference: this.editReference }, true)
            .then((response) => {
                this.estimate.reference = this.editReference;
                this.helperService.editEstimateReference.emit(this.editReference);
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveStorages() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .editStorage(this.estimate.id, this.estimateStorages)
            .then((response) => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    removeStorage() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .removeStorage(this.estimate.id, this.estimateStorages)
            .then((response) => {
                this.load(this.estimate.id);
                this.estimateStorages = new EstimateStorage();
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveLeads() {

        this.estimatesService
            .editProvider(this.estimate.id, this.estimate.lead_company ? this.estimate.lead_company.lead_id : '', this.advertiser_id)
            .then(() => {
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openModalSetTariffDiscount() {
        this.estimateTariffDiscount.type = this.estimate.tariff_discount.type;
        this.estimateTariffDiscount.value = this.estimate.tariff_discount.value;
        jQuery(this.modalSetTariffDiscount.nativeElement).modal('show');
    }

    saveTariffDiscount() {

        switch(this.estimateTariffDiscount.type){
            case 'PERCENTAGE':
                this.estimateTariffDiscount.total_discount = this.helperService.roundDecimals(this.estimateTariffDiscount.value * this.estimate.charges.cubic_feet_total_price / 100, 2);
                this.estimateTariffDiscount.total_discount_percentage = this.helperService.roundDecimals(this.estimateTariffDiscount.value, 2);
                break;
            case 'RATE':
                const rateDiscount = this.estimate.estimate_route_tariff.cf_cost - this.estimateTariffDiscount.value;
                const totalRateDiscount = rateDiscount * this.estimate.volume.cubic_feets_selled;

                this.estimateTariffDiscount.total_discount = this.helperService.roundDecimals(totalRateDiscount, 2);
                this.estimateTariffDiscount.total_discount_percentage = this.helperService.roundDecimals((totalRateDiscount / this.estimate.charges.cubic_feet_total_price * 100), 2);
                break;
            default:
                this.estimateTariffDiscount.total_discount = this.helperService.roundDecimals(this.estimateTariffDiscount.value, 2);
                this.estimateTariffDiscount.total_discount_percentage = this.helperService.roundDecimals(this.estimateTariffDiscount.value * 100 / this.estimate.charges.cubic_feet_total_price, 2);
                break;
        }

        // asigna valores al estimate para evitar hacer un reload
        this.estimate.tariff_discount.type = this.estimateTariffDiscount.type;
        this.estimate.tariff_discount.value = this.estimateTariffDiscount.value;
        this.estimate.tariff_discount.total_discount = this.estimateTariffDiscount.total_discount;
        this.estimate.tariff_discount.total_discount_percentage = this.estimateTariffDiscount.total_discount_percentage;

        const data = {
            tariff_discount: this.estimate.tariff_discount
        };
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, data, true)
            .then((response) => {
                this.load(this.estimate.id);
                this.localFuelSurcharge = 0;
            })
            .catch((error) => {
                this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openInventoryItemsLocalModal() {
        jQuery(this.inventoryItemsLocalModal.nativeElement).modal('show');
    }

    loadLocalTariffs() {
        this.helperService.showLoadingMxpro360();
        this.localTariffSettingsService.getAll()
            .then((result) => {
                this.localTariffs = result.filter(x => x.active);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * abre modal de Quick Quotes
     */
    openQuickQuoteFormModal() {

        this.helperService.showLoadingMxpro360();
        this.localTariffSettingsService.getQuikQuotes(this.estimate.id)
            .then((result) => {
                this.localEstimateCharges = result;
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        jQuery(this.quickQuoteFormModal.nativeElement).modal('show');
    }

    /**
     * cerrar modal de Quick Quotes
     */
    closeQuickQuoteFormModal() {
        jQuery(this.inventoryItemsLocalModal.nativeElement).modal('hide');
        setTimeout(() => {
            jQuery(this.quickQuoteFormModal.nativeElement).modal('hide');
        }, 100);
    }

    /**
     * guarda la informacion de Quick Quotes
     */
    saveQuickQuoteForm() {
        this.helperService.showLoadingMxpro360();
        this.localTariffSettingsService.saveQuikQuotes(this.estimate.id, this.localEstimateCharges)
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                jQuery(this.quickQuoteFormModal.nativeElement).modal('hide');
                this.helperService.goToCompanyRouterLink(`/moving/${this.estimate.id}/quick-inventory`);

            });

        jQuery(this.inventoryItemsLocalModal.nativeElement).modal('hide');
        jQuery(this.quickQuoteFormModal.nativeElement).modal('hide');

    }

    openLocalWarehouseModal(type) {
        this.warehouseSideType = type;

        if (this.warehouseSideType == 'DELIVERY') {
            if (this.estimate.delivery.local_estimate_storage) {
                this.localWarehouseType = this.estimate.delivery.local_estimate_storage ? this.estimate.delivery.local_estimate_storage.storage_type : null;
                if (this.estimate.delivery.local_estimate_storage.warehouse_id) {
                    let whName = this.warehouses.filter((data) => data.id === this.estimate.delivery.local_estimate_storage.warehouse_id);
                    this.localWarehouseWarehouseId = whName[0].id;
                } else {
                    let whName: any = this.storageCompanies.filter((data: any) => data.id == this.estimate.delivery.local_estimate_storage.storage_company_id);
                    this.localWarehouseCompanyId = whName[0].id;
                }
                if (this.estimate.estimate_local_tariff.quote_mode === 'MANUAL') {
                    this.total = this.estimate.delivery.local_estimate_storage.total;
                }
            }

        }
        else {
            if (this.estimate.pickup.local_estimate_storage) {
                this.localWarehouseType = this.estimate.pickup.local_estimate_storage ? this.estimate.pickup.local_estimate_storage.storage_type : null;
                if (this.estimate.pickup.local_estimate_storage.warehouse_id) {
                    let whName = this.warehouses.filter((data) => data.id === this.estimate.pickup.local_estimate_storage.warehouse_id);
                    this.localWarehouseWarehouseId = whName[0].id;
                } else {
                    let whName: any = this.storageCompanies.filter((data: any) => data.id == this.estimate.pickup.local_estimate_storage.storage_company_id);
                    this.localWarehouseCompanyId = whName[0].id;
                }
                if (this.estimate.estimate_local_tariff.quote_mode === 'MANUAL') {
                    this.total = this.estimate.pickup.local_estimate_storage.total;
                }
            }
        }


        jQuery(this.localWarehouseModal.nativeElement).modal('show');
    }

    saveLocalWarehouse() {
        this.helperService.showLoadingMxpro360();
        const data = {
            warehouseSideType: this.warehouseSideType,
            localWarehouseType: this.localWarehouseType,
            localWarehouseWarehouseId: this.localWarehouseWarehouseId,
            localWarehouseCompanyId: this.localWarehouseCompanyId,
            total: this.total
        }

        this.estimatesService.setLocalWarehouse(this.estimate.id, data)
            .then(async () => {
                this.load(this.estimate.id);
            });
    }

    editLocalWarehousePickup(type) {
        this.helperService.showLoadingMxpro360();

        this.estimatesService.editLocalWarehouse(this.estimate.id, type)
            .then(async () => {
                this.load(this.estimate.id);
            });
    }

    editQuickQuote(item) {
        item.edit = true;
    }

    updateQuickQuote(item) {
        const cadena = item.tariff_additional_concepts.behavior;
        const start = 0;
        const end = cadena.indexOf('_'); // Esto encontrará la posición del primer '_'
        const word = cadena.substring(start, end);
        item.type = word;

        this.localTariffSettingsService
            .updateQuikQuotes(this.estimate.id, item)
            .then((response) => {
                this.loadAdditionalConcept(this.estimate.id);
                item.edit = false;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    deleteQuickQuote(item) {
        item.metas[1].value = 0;
        this.localTariffSettingsService
            .updateQuikQuotes(this.estimate.id, item)
            .then((response) => {
                this.loadAdditionalConcept(this.estimate.id);
                item.edit = false;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openInventoryItems() {
        this.helperService.goToCompanyRouterLink(`/moving/${this.estimate.id}/inventory-items`);
        jQuery(this.inventoryItemsLocalModal.nativeElement).modal('hide');
    }

    openPackersUnpackingModal() {
        this.estimatePackerUnPacking = { ...this.estimate.packers_unpacking };
        jQuery(this.packersunpacking.nativeElement).modal('show');
    }

    openPackersPackingModal() {
        this.estimatePackerPacking = { ...this.estimate.packers_packing };
        jQuery(this.packerspacking.nativeElement).modal('show');
    }

    loadquickInventoryEstimate(id) {
        this.helperService.showLoadingMxpro360();
        this.quickInventoryEstimatesService
            .get(id)
            .then((response) => {
                this.localInventoryRooms = response;
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    masonry() {
        setTimeout(() => {
            jQuery('.task-list-section').masonry({
                // options
                itemSelector: '.connect-sorting',
                columnWidth: 400
            });
        }, 10);
        setTimeout(() => {
            jQuery('.task-list-section').masonry('reloadItems');
            jQuery('.task-list-section').masonry('layout');
        }, 100);
    }

    deleteRoom(index) {
        this.localInventoryRooms.splice(index, 1);
        this.masonry();
    }

    /**
     * actualiza el valor
     */
    setManualMiles() {
        this.estimateLocalTariff.travel_workers_tariff.manual_miles = this.estimateLocalTariff.travel_workers_tariff.miles;
    }

    setManualHours() {
        this.estimateLocalTariff.travel_workers_tariff.manual_hours = this.estimateLocalTariff.travel_workers_tariff.hours;
    }


    openModalSendTextMessage() {
        jQuery(this.modalSendTextMessage.nativeElement).modal('show');
    }


    sendMessage() {

        if (!this.clientSocketsService.isConnected()) {
            return;
        }


        console.log('this.dataFormPhone',this.dataFormPhone);



        const form = {
          "to"            : [this.dataFormPhone.phoneNumberCustomer],
          "from"          : this.dataFormPhone.phoneNumberEmployee,
          "text"          : this.dataFormPhone.message,
          "applicationId" : "dad36d15-88e5-4ca9-9407-c22193d6cafb",
          "tag"           : "test message",
          "priority"      : "default"
        }


        const dataForm =  {workspace_id: this.authService.workspaceSession.workspace.id, form: form};

        let that = this;
        const callBack = function(response){
            swal(
                'Done!',
                'The SMS text has been sent successfully',
                'success'
            );
        }

        this.clientSocketsService.getSocket().emit("sms:send", dataForm, callBack);

        this.dataFormPhone = {
            phoneNumberEmployee: '',
            phoneNumberCustomer: '',
            message: ''
        }
    }


  private loadListNums() {
    this.phoneCallService
      .getListPhone()
      .then((response) => {


        this.employeeNums = response;
        //aqui
        for (const phoneline of this.employeeNums) {
          if (phoneline.main === true) {
            this.dataFormPhone.phoneNumberEmployee = phoneline.phone_number;
          }
        }


      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      });
  };


  private loadExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
      .getExtensionsSms()
      .then((response) => {


        this.extensions = response;

        console.log('this.extensions',this.extensions);



      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });



  };

  private loadSmsTemplate() {

    this.smsTemplatesService
    .getAll()
    .then((response) => {
        this.smsTemplates = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  };

  changeSmsTemplate(tmp){
    console.log(tmp);

    this.dataFormPhone.message = tmp;
  }

  changeAttachPortalLink(){

    if(this.attachPortalLink){
        const dataForm = {
            'workspace_id': this.authService.workspaceSession.workspace.id,
            'company_id': this.estimate.company_id,
            'customer_id': this.customer.id,
            'estimate_id': this.estimate.id,
        }

        this.smsService
            .getPortalLink(dataForm)
            .then((response) => {
                this.dataFormPhone.message += '  ' + response['url'];
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
  }


  checkboxPrePickup() {
    let checkboxPrePickup = document.getElementById('pre_pickup') as HTMLInputElement;
    if(checkboxPrePickup.checked) {
        this.prePickup = true;
      } else {
        this.prePickup = false;
    }
  }

}
