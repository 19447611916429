import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageInvoice } from 'src/app/entities/workspace/storage-invoice';
import { StorageService } from 'src/app/entities/workspace/storage-service';

@Injectable({
  providedIn: 'root'
})
export class StorageInvoicesService {

  constructor(private http: HttpClient) { }

  save(dataForm: StorageInvoice): Promise<any> {
    if (dataForm.id === null) {
        return this.add(dataForm);
    }
    return this.edit(dataForm);
  }

  add(dataForm: StorageInvoice): Promise<any> {
    return this.http.post(environment.api.company + '/estimates/' + dataForm.job_id + '/storage-invoices', dataForm).toPromise();
  }

  edit(dataForm: StorageInvoice): Promise<any> {
    return this.http.put(environment.api.company + '/estimates/' + dataForm.job_id + '/storage-invoices/' + dataForm.id, dataForm).toPromise();
  }

  getByEstimateId(estimateId: string, only_pendings: boolean): Promise<any> {
    const params = {
      only_pendings
    }
    return this.http.get(environment.api.company + '/estimates/' + estimateId + '/storage-invoices', { params: params }).toPromise();
  }

  getCompleteByEstimateId(estimateId: string, only_pendings: boolean): Promise<any> {
    const params = {
      only_pendings
    }
    return this.http.get(environment.api.company + '/estimates/' + estimateId + '/storage-invoices-complete', { params: params }).toPromise();
  }

  getByBOLEstiamteId(estimateId: string): Promise<any> {
    const params = {
      from_BOL: true
    }
    return this.http.get(environment.api.company + '/estimates/' + estimateId + '/storage-invoices', { params: params }).toPromise();
  }

  getAll(): Promise<any> {
    return this.http.get(environment.api.workspace + '/storage-invoices').toPromise();
  }

  addTerms(estimateId: string, storageService: StorageService, storageServiceFistMondFree: boolean, storageServiceProrate: boolean) {
    const data = {
      storageService,
      storageServiceFistMondFree,storageServiceProrate
    };
    return this.http.post(environment.api.company + '/estimates/' + estimateId + '/storage-invoices-complete', data).toPromise();
  }
  removeTerms( estimateId: string, storageServiceId: string) {
    return this.http.delete(environment.api.company + '/estimates/' + estimateId + '/storage-invoices-complete/' + storageServiceId + '/remove').toPromise();
  }
}
