export class PayrollBalance {
    public id: string;
    public employee_id: string;
    public type: string;
    public amount: number;
    public remarks: string;
    public status: 'CANCELLED' | 'PENDING_PAYMENT' | 'PAID' | 'PENDING_DEDUCTION' | 'DEDUCTED';
    public sales_commission_id: string;
    public payroll_payment_id: string;
    public company_id: Date;
    public created: Date;
    public updated: Date;

    constructor() {
        this.id = null;
        this.employee_id = null;
        this.type = null;
        this.amount = null;
        this.remarks = null;
        this.status = 'PENDING_PAYMENT';
        this.sales_commission_id = null;
        this.payroll_payment_id = null;
        this.company_id = null;
        this.created = null;
        this.updated = null; 
    }
}
