import { ElectronicSignature } from 'src/app/entities/workspace/electronic-signature';
import { EstimateDocument } from 'src/app/entities/workspace/estimate-document';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { EstimatesDocumentsService } from 'src/app/services/companies/estimates-documents.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { HelperService } from 'src/app/services/helper.service';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EstimateDocumentView } from '../../../entities/workspace/estimate-document-view';
import { DocumentsTypesService } from 'src/app/services/companies/documents-types.service';
import { DocumentType } from 'src/app/entities/workspace/document-type';
import { EstimateVersionsService } from 'src/app/services/companies/estimate-versions.service';

declare var jQuery;
declare var swal;

@Component({
  selector: 'app-estimates-signatures',
  templateUrl: './estimates-signatures.component.html',
  styleUrls: ['./estimates-signatures.component.scss']
})
export class EstimatesSignaturesComponent implements OnInit {

  /*
  Signatures del estimate
  */
  public signatures: EstimateDocumentView[];

  /*
  Control de pagina
  */
  public actualPage: number;

  public estimate: EstimateView;

  public electronicSignature: ElectronicSignature;

  public documentTypes: DocumentType[];
  private documentTypesIds: string[];
  /*
  Modal del detalle del signature
  */
  @ViewChild('signatureModal') signatureModal: ElementRef;

  @ViewChild('pickupDocumentsModal') pickupDocumentsModal: ElementRef;

  constructor(
    private currentRoute: ActivatedRoute,
    private estimatesService: EstimatesService,
    private documentsTypesService: DocumentsTypesService,
    private estimatesDocumentsService: EstimatesDocumentsService,
    private estimateVersionsService: EstimateVersionsService,
    private helperService: HelperService

  ) {
    this.signatures = [];
    this.actualPage = 1;
    this.documentTypes = [];
    this.electronicSignature = new ElectronicSignature();
    this.documentTypesIds = [];
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.loadDocumentTypes();
    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.loadEstimate(params.id);
        this.loadSignatures(params.id);
      }
    });
  }

  /**
   * Descarga el pdf del estimate
   */
  downloadPdf(signature: EstimateDocument): void {
    this.helperService.downloadReport('SIGNATURE', null, signature);
  }

  /**
   * Carga el estimate
   *
   * @param idEstimate Id del estimate
   */
  private loadEstimate(idEstimate: string): void {
    this.helperService.showLoadingMxpro360();
    this.estimatesService
      .getById(idEstimate)
      .then((response) => {
        this.estimate = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Carga los signatures del estimatesService
   *
   * @param idEstimate Id del estimate
   */
  private loadSignatures(idEstimate: string): void {
    this.helperService.showLoadingMxpro360();
    this.estimatesDocumentsService
      .getSignatures(idEstimate)
      .then((response) => {
        this.signatures = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadDocumentTypes() {
    this.helperService.showLoadingMxpro360();
    this.documentsTypesService
      .getAll()
      .then((response) => {
        this.documentTypes = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  /**
   * Abre el modal del detalle de la firma
   *
   * @param signature Objeto que contiene el detalle de la firma
   */
  openSignatureModal(signature: EstimateDocument): void {
    this.electronicSignature = new ElectronicSignature();
    if (signature.document.esignatures.main) {
      this.electronicSignature = signature.document.esignatures.main;
    }
    jQuery(this.signatureModal.nativeElement).modal('show');
  }

  revert(idDocument) {
    swal({
      title: 'Are you sure?',
      text: "The actual changes will be lost if they are not versioned...",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, revert it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.estimateVersionsService
            .revert(this.estimate.id, idDocument)
            .then((response) => {
              window.location.reload();
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });

  }


}
