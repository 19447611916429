export class ClockEmployees {

    public id: string;
    public company_id: number;
    public employee_id: number;
    public geo_requerid: boolean;
    public offside_allowed: boolean;
    public hourly_rate: number;
    public overtime_hourly_rate: number;
    public allow_overtime: boolean;


    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.company_id = null;
        this.employee_id = null;
        this.geo_requerid = false;
        this.offside_allowed = false;
        this.hourly_rate = 0;
        this.overtime_hourly_rate = 0;
        this.allow_overtime = false;
    }
}
