import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { InventoryItem } from 'src/app/entities/workspace/inventory-item';
import { InventoryItemView } from 'src/app/entities/workspace/inventory-item-view';

@Injectable({
    providedIn: 'root'
})
export class InventoryItemsService {

    constructor(private http: HttpClient) {

    }

    getAllWithFullView(): Promise<Array<InventoryItemView>> {
        return this.http.get<Array<InventoryItemView>>(environment.api.workspace + '/inventory-items', { params: { "full-view": "1" } }).toPromise();
    }

    getAll(): Promise<Array<InventoryItem>> {
        return this.http.get<Array<InventoryItem>>(environment.api.workspace + '/inventory-items').toPromise();
    }

    getById(id: string) {
        return this.http.get<InventoryItem>(environment.api.workspace + '/inventory-items/' + id).toPromise();
    }

    save(dataForm: InventoryItem): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: InventoryItem) {
        return this.http.post(environment.api.workspace + '/inventory-items', dataForm).toPromise();
    }

    edit(dataForm: InventoryItem) {
        return this.http.put(environment.api.workspace + '/inventory-items/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/inventory-items/' + id).toPromise();
    }

}
