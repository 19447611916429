<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <h3>
                Customers
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Customers</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appCompanyRouterLink="/customers/add" class="btn btn-warning">
                    <i class="fas fa-plus-square"></i> &nbsp; New Customer
                </a>

                <!--
                    <div class="switch align-self-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-list view-list active-view">
                            <line x1="8" y1="6" x2="21" y2="6"></line>
                            <line x1="8" y1="12" x2="21" y2="12"></line>
                            <line x1="8" y1="18" x2="21" y2="18"></line>
                            <line x1="3" y1="6" x2="3" y2="6"></line>
                            <line x1="3" y1="12" x2="3" y2="12"></line>
                            <line x1="3" y1="18" x2="3" y2="18"></line>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-grid view-grid">
                            <rect x="3" y="3" width="7" height="7"></rect>
                            <rect x="14" y="3" width="7" height="7"></rect>
                            <rect x="14" y="14" width="7" height="7"></rect>
                            <rect x="3" y="14" width="7" height="7"></rect>
                        </svg>
                    </div>
                -->
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">

            <div class="widget-content searchable-container list">
                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content">
                            <div class="">
                                <h4>Name</h4>
                            </div>
                            <div class="user-email">
                                <h4>Email</h4>
                            </div>
                            <div class="user-phone">
                                <h4 style="margin-left: 3px;">Phone</h4>
                            </div>
                            <div class="user-location">
                                <h4 style="margin-left: 0;"> Created</h4>
                            </div>

                            <div class="action-btn">
                                <h4 style="margin-left: 3px;">
                                    Options
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="items" *ngFor="let row of rows|search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                        <div class="item-content">
                            <div class="user-profile">
                                <div class="user-meta-info">
                                    <p class="user-name">{{row.last_name}}</p>
                                    <p class="user-work">{{row.name}}</p>
                                </div>
                            </div>
                            <div class="user-email">
                                <p class="info-title">Email: </p>
                                <p class="usr-email-addr">{{row.email}}</p>
                            </div>
                            <div class="user-phone">
                                <p class="info-title">Phone: </p>
                                <p class="usr-ph-no">{{row.phone}}</p>
                            </div>
                            <div class="user-location">
                                <p class="info-title">Created: </p>
                                <p class="usr-location">
                                    {{row.created | dateTime}}
                                </p>
                            </div>
                            <div class="action-btn">
                                <a appCompanyRouterLink="/customers/{{row.id}}/view" class="btn btn-outline-dark mb-2 mr-2">
                                    <svg _ngcontent-men-c79="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-zoom-in"><circle _ngcontent-men-c79="" cx="11" cy="11" r="8"></circle><line _ngcontent-men-c79="" x1="21" y1="21" x2="16.65" y2="16.65"></line><line _ngcontent-men-c79="" x1="11" y1="8" x2="11" y2="14"></line><line _ngcontent-men-c79="" x1="8" y1="11" x2="14" y2="11"></line></svg>
                                </a>
                                <a appCompanyRouterLink="/customers/{{row.id}}/edit" class="btn btn-outline-dark mb-2 mr-2">
                                    <i class="fas fa-user-edit"></i>
                                </a>

                                <button class="btn btn-outline-danger mb-2 mr-2" (click)="remove(row.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event">
                    </pagination-controls>
                </div>
            </div>

        </div>
    </div>

</div>
