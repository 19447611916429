
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EstimateDocumentView } from '../../entities/workspace/estimate-document-view';

@Injectable({
  providedIn: 'root'
})
export class EstimatesDocumentsService {

  constructor(
    private http: HttpClient
  ) {

  }

  getSignatures(idEstimate: string): Promise<Array<EstimateDocumentView>> {
    return this.http.get<Array<EstimateDocumentView>>(environment.api.company + '/estimates/' + idEstimate + '/documents').toPromise();
  }
}
