<div class="row layout-spacing layout-top-spacing">
    <div class="col-lg-12">
        <div class="widget-content searchable-container list">

            <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <h3>
                        Employees
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>
                                    Dashboard</a></li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Employees
                            </li>
                        </ol>
                    </nav>
                </div>

                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input type="text" name="filterSearch" [(ngModel)]="filterSearch"
                                class="form-control product-search" id="input-search" placeholder="Filter...">
                        </div>
                    </form>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="widget-content widget-content-area">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover mb-4">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th class="text-center">Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let employee of employees |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; let account of this.accounts" >
                                        <td>
                                            {{employee.name | titlecase}}
                                        </td>
                                        <td>
                                            {{employee.email  | titlecase}}
                                        </td>
                                        <td>
                                            {{employee.phone  | phone:'US'}}
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="employee.status === 'ACTIVE'" class="badge badge-success">
                                                Active
                                            </span>
                                            <span *ngIf="employee.status === 'DELETED'" class="badge badge-danger">
                                                Disabled
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
