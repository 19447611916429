import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountCompany } from 'src/app/entities/workspace/account-company';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-register-account-company',
  templateUrl: './register-account-company.component.html',
  styleUrls: ['./register-account-company.component.scss']
})
export class RegisterAccountCompanyComponent implements OnInit {

  public dataForm: AccountCompany;
  public confirmPassword: string;
  public section: "ACCOUNT_FORM" | "ACCOUNT_TOKEN_FORM" | "ACCOUNT_COMPANY_TOKEN_FORM";
  public token: string;
  public newPhone: string;

  @ViewChild('changePhoneModal') changePhoneModal: ElementRef;

  constructor(
    public authService: AuthService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.dataForm = new AccountCompany();
    this.confirmPassword = null;
    this.section = "ACCOUNT_FORM";
    this.token = '';
    this.newPhone = '';
  }

  ngOnInit(): void {
  }

  /**
   * Permite aplicar comportamiento a las cajas o imputs de texto
   */
  runBehaviorofCodeFields() {
    let that = this;
    const inputs: any = document.querySelectorAll('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', function (event) {
        if (event.key === "Enter") {
          that.verifyCodeEntered();
          return;
        }
        if (event.key === "Backspace") {
          inputs[i].value = '';
          if (i !== 0) inputs[i - 1].focus();
        }
        else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            inputs[i].value = event.key;
            event.preventDefault();
          }
          else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 95 && event.keyCode < 106) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode == 17 || event.keyCode == 86) {

          } else {
            inputs[i].value = '';
            event.preventDefault();
          }
        }
        setTimeout(() => {
          if (inputs[0].value.length > 1) {
            let str = inputs[0].value;
            for (let j = 0; j < inputs.length; j++) {
              inputs[j].value = str[j];
            }
          }
        }, 100);
      });
    }
  }

  sigin() {
    let msg = '';
    if (this.dataForm.account_name.trim() === '') {
      msg += "**You Must Provide a Name**<br>";
    }
    if (this.dataForm.account_password.trim() === '') {
      msg += "**You Must Provide a Password**<br>";
    }
    if (this.dataForm.account_password !== this.confirmPassword) {
      msg = `Password and Confirm don't Match`;
    }
    if (msg !== '') {
      swal(
        'Attention',
        msg,
        'warning'
      );
      return;
    }
    this.helperService.showLoadingMxpro360();

    this.authService
      .signinAccountCompany(this.dataForm)
      .then((res: any) => {
        this.dataForm.id = res.row_id
        this.section = "ACCOUNT_TOKEN_FORM";
        setTimeout(() => {
          this.runBehaviorofCodeFields();
      }, 100);
      })
      .catch((error) => {
        swal({
          type: 'error',
          title: 'Oops...',
          text: error.error ? error.error.message : error.message
        });
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  updateAccountCompany() {
    if (this.dataForm.company_address.zip_code === null) { return; }
    this.helperService.showLoadingMxpro360();
    this.authService
      .updateAccountCompany(this.dataForm)
      .then((res: any) => {
        // this.router.navigateByUrl('/register-account-company/credit-card/' + this.dataForm.id);
        window.location.href = "https://moverxpro360.com/thank-you-page";
      })
      .catch((error) => {
        swal({
          type: 'error',
          title: 'Oops...',
          text: error.error ? error.error.message : error.message
        });
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  setFrom(from) {
    this.dataForm.company_address = from;
  }

  ngOnDestroy() {
    this.dataForm = null;
  }

  /**
   * Permite verificar el codigo ingresado
   * 
   * @returns 
   */
  verifyCodeEntered() {
    console.log('this.dataForm.id =>', this.dataForm.id);
    
    this.token = '';
    const inputs: any = document.querySelectorAll('#otp > *[id]');
    let that = this;
    jQuery('#otp').children('input').each(function (key) {
      if (this.value === '') {
        inputs[key].focus();
        return false;
      }
      that.token = that.token + this.value; // "this" is the current element in the loop

    });

    if (this.token.length != 6) {
      return;
    }
    // Genera el nuevo token
    this.helperService.showLoadingMxpro360();
    this.authService
      .verifyTokenAccountCompany(this.token, this.dataForm.id )
      .then(() => {
        this.section = "ACCOUNT_COMPANY_TOKEN_FORM";
      }, (error) => {
        let errorMessage = error.message;
        // valida la existencia de un mensaje de error personalizado
        if (error.hasOwnProperty('error')) {
          if (error.error.hasOwnProperty('message')) {
            errorMessage = error.error.message;
          }
        }
        swal({
          type: 'error',
          title: 'Oops...',
          text: errorMessage
        });
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  resendaNewCode() {
    console.log('resendaNewCode', this.dataForm); 
    
        // Genera el nuevo token
        this.helperService.showLoadingMxpro360();
        this.authService
          .resendVerifyTokenAccountCompany(this.dataForm.id )
          .then(() => {
            this.section = "ACCOUNT_TOKEN_FORM";
          }, (error) => {
            let errorMessage = error.message;
            // valida la existencia de un mensaje de error personalizado
            if (error.hasOwnProperty('error')) {
              if (error.error.hasOwnProperty('message')) {
                errorMessage = error.error.message;
              }
            }
            swal({
              type: 'error',
              title: 'Oops...',
              text: errorMessage
            });
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
  }

  showPassword() {
    jQuery("#show_password svg").css('display', 'none');
    if (jQuery("#account_password").attr('type') == "password") {
      jQuery("#account_password").prop('type', 'text');
      jQuery('.icon.password').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
    } else {
      jQuery("#account_password").prop('type', 'password');
      jQuery('.icon.password').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
    }
  }

  showConfirmPassword() {
    jQuery("#show_confirm_password svg").css('display', 'none');
    if (jQuery("#inputConfirmPassword").attr('type') == "password") {
      jQuery("#inputConfirmPassword").prop('type', 'text');
      jQuery('.icon.confirm-password').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
    } else {
      jQuery("#inputConfirmPassword").prop('type', 'password');
      jQuery('.icon.confirm-password').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
    }
  }

  // abre popup para cambiar numero telefonico
  openChangePhoneModal() {
    jQuery(this.changePhoneModal.nativeElement).modal('show');
  }
  
  // guarda un nuevo telefono y re envia el codigo por mensaje
  saveNewPhone() {
    if (this.newPhone.length < 10) {
      this.helperService.showMessageSnackbar('Please enter a valid phone number');
    } else {
      this.dataForm.account_phone = this.newPhone;
      this.helperService.showLoadingMxpro360();
      this.authService
        .updateAccountCompany(this.dataForm)
        .then(() => {
          this.resendaNewCode();
        })
        .catch((error) => {
          swal({
            icon: 'error',
            title: 'Oops...',
            text: error.error ? error.error.message : error.message
          });
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
          jQuery(this.changePhoneModal.nativeElement).modal('hide');
        });
    }
  }

  // cierra modal de cambio de telefono
  closeModal() {
    jQuery(this.changePhoneModal.nativeElement).modal('hide');
  }

}
