import { Carrier } from './../../entities/workspace/carrier';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarriersCompanyService {


  constructor(
    private http: HttpClient
) { }

    /**
     * Metodo que obtiene los balances por id de jobs
     *
     * @param id Id del job
     */
    getCarrierByEstimateId(idCarrier: string): Promise<{carrier: Carrier}> {
      return this.http.get<{carrier: Carrier}>(environment.api.company + '/carrier-by-estimate/' + idCarrier).toPromise();
  }
}
