
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';
import { HttpCancelService } from '../http-cancel.service';
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';

declare const jQuery;

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService {

    static authService: AuthService = null;
    static router: Router = null;

    constructor(
        private cancelService: HttpCancelService,
        private helperService: HelperService,
        private injector: Injector
    ) {

    }

    /**
     * Permite verificar si se esta haciendo una peticion a la api del proyecto
     * @param urlRequest
     */
    private isRequestToApi(urlRequest: string): boolean {
        if (urlRequest.indexOf('i18n') > 0) {
            return false
        }
        const apiUrl = new URL(environment.api.base);
        const apRtciUrl = new URL(environment.api.apiRtcPrivate);
        const urlReq = new URL(urlRequest);

        // verificamos si el host es el mismo del host de la api
        if (apiUrl.hostname === urlReq.hostname || apRtciUrl.hostname === urlReq.hostname) {

            // verificamos si se esta haciendo uso de la api como tal
            if (urlRequest.includes(apiUrl.href) || urlRequest.includes(apRtciUrl.href)) {

                return true;
            }
        }
        return false;
    }

    /**
     * Revisa si la url necesita inyectarle el identificador del company
     *
     * @param url
     * @param companyId
     * @returns
     */
    private completeCompanyUrl(url: string, companyId: string): string {

        // verifica si la ulr contiene company id para reemplzarlo
        if (url.includes('{company_id}')) {

            // reemplaza company_id por el id de comania en la ventana del navegador
            url = url.replace('{company_id}', companyId);
        }

        return url;
    }


    /**
     * Permite organizar el request que va dirigido a la api para adicionarle el access token
     * @param req
     */
    private cloneRequestToApi(req: HttpRequest<any>): HttpRequest<any> {

        // obtiene url de la api a la que se desea hacer request
        let url = req.url;

        // en caso de que no haya sesion, no asignamos valores
        if (HttpInterceptorService.authService.session === null) {
            return req;
        }

        // inicializamos los parametros con la Authorization
        let params: any = {
            'Authorization': HttpInterceptorService.authService.session.token
        };

        // si hay workspace asignamos valores de este
        if(HttpInterceptorService.authService.workspaceSession !== null) {

            // asignamos valores del workspace id
            params.workspace_id = HttpInterceptorService.authService.workspaceSession.workspace.id;

            // si hay empleado lo asignamos
            if(HttpInterceptorService.authService.workspaceSession.employee !== null){
                params.auth_employee_id = HttpInterceptorService.authService.workspaceSession.employee.id;
            }

            // si hay company lo asignamos
            if(HttpInterceptorService.authService.workspaceSession.company !== null){
                url = this.completeCompanyUrl(url, HttpInterceptorService.authService.workspaceSession.company.id);
            }
        }

        // clonamos el request y le adicionamos el access token
        const clonReq = req.clone({
            url: url,
            setHeaders: {
                'Content-Type': 'application/json',
            },
            setParams: params
        });

        return clonReq;
    }


    /**
     * Metodo principal que se ejecuta por cada llamado de un http Client
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // en caso de que no sea una peticion al servidor api dejamos la solicitud tal cual
        if (!this.isRequestToApi(req.url)) {
            return next.handle(req);
        }

        // si no se ha cargado el authService lo cargamos
        if(HttpInterceptorService.authService == null) {
            HttpInterceptorService.authService = this.injector.get(AuthService);
        }

        // si no se ha cargado el Router lo cargamos
        if(HttpInterceptorService.router == null) {
            HttpInterceptorService.router = this.injector.get(Router);
        }

        // como la peticion es para el servidor api, se hace un clon de la solicitud
        // y se le adiciona el access token para las credenciales
        return next.handle(this.cloneRequestToApi(req))
            .pipe(
                tap(
                    event => {
                        // logging the http response to browser's console in case of a success
                        if (event instanceof HttpResponse) {
                            // console.log('api call success : ', event);
                        }
                    },
                    error => {
                        // logging the http response to browser's console in case of a failuer
                        if (event instanceof HttpResponse) {
                            // console.log('api call error : ', event);
                        } else {
                            // The backend returned an unsuccessful response code.
                            // The response body may contain clues as to what went wrong,
                            console.error(`Backend returned code ${error.status}, ` + `body was:`, error);

                            if (error.status === 401 || error.status === 403) {
                                const authService = this.injector.get(AuthService);
                                const router = this.injector.get(Router);

                                HttpInterceptorService.authService.closeSession();
                                this.helperService.hideLoadingMxpro360();
                                HttpInterceptorService.router.navigate(['/login']);
                            }
                        }
                    }
                )
            ).pipe(takeUntil(this.cancelService.cancelPendingRequest$
                .pipe(tap(()=> {
                    throw new Error('Request is canceled')
                }))
                )
            );
    }
}
