import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CodeJar } from 'codejar';
import { withLineNumbers } from 'codejar-linenumbers';



declare const hljs: any;

@Component({
    selector: 'app-html-editor',
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnInit {

    @Input()
    data: string;

    @Input()
    disabled: boolean;

    @Output()
    change: EventEmitter<string>;

    // acceso al elemento html que contiene el editor de html
    @ViewChild('HtmlEditor')
    htmlEditorElement: ElementRef;

    // instancia del editor de html
    private jar: CodeJar;


    constructor() {
        this.data = " ";
        this.disabled = false;
        this.change = new EventEmitter<string>();
        this.htmlEditorElement = null;
        this.jar = null;
    }

    /**
     * Se ejecuta despues del constructor
     */
    ngOnInit(): void {

    }

    /**
     * Permite escuchar los cambios en los @inputs que hay en el component
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {

        if (!!changes.data) {
            if (changes.data.firstChange == false) {
                if (!!this.jar) {
                    this.jar.updateCode(this.data);
                } else {
                    this.htmlEditorElement.nativeElement.textContent = this.data;
                    hljs.highlightElement(this.htmlEditorElement.nativeElement);
                }
            }
        }

        if (!!changes.disabled) {
            if (changes.disabled.firstChange == false) {
                if (changes.disabled.currentValue) {
                    this.jar.destroy();
                }
                this.ngAfterViewInit();
            }
        }
    }


    /**
     * Se ejecuta cuando la vista del component se ha renderizado
     */
    ngAfterViewInit() {
        if (this.disabled) {
            this.htmlEditorElement.nativeElement.textContent = this.data;
            hljs.highlightElement(this.htmlEditorElement.nativeElement);
        } else {
            this.jar = CodeJar(this.htmlEditorElement.nativeElement, withLineNumbers(hljs.highlightElement));
            this.jar.updateCode(this.data);
            this.jar.onUpdate(code => {
                this.change.emit(code);
            });
        }
    }

    ngOnDestroy() {
        if (!!this.jar) {
            this.jar.destroy();
        }
    }


}

