import { PhoneRingDestination } from "./phone-ring-destination";
import { File } from '../global/file';

export class PhoneExtension {

  public id: string;
  public extension: number;
  public name: string;
  public caller_id: string;
  public main: boolean;
  public ip_allowed: number;
  public ring_duration: number;
  public destination: PhoneRingDestination;
  public password: string;
  public voicemail_announcement: File;
  public employee_id: string;
  public company_id: string;
  public outgoing_phone_line_id: string;
  public sms_phone_line_id: string;
  public created: number;
  public updated: number;

  constructor() {
      this.id = null;
      this.extension = null;
      this.name = null;
      this.caller_id = null;
      this.main = null;
      this.ip_allowed = null;
      this.ring_duration = null;
      this.destination =  new PhoneRingDestination();
      this.password = null;
      this.voicemail_announcement = new File();
      this.employee_id = null;
      this.company_id = null;
      this.outgoing_phone_line_id = null;
      this.sms_phone_line_id = null;
      this.created = null;
      this.updated = null;
  }




}
