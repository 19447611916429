<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">

            <h3>
                Documents Types
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Documents Types</li>
                </ol>
            </nav>

        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">

                <a appCompanyRouterLink="/documents-types/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Document Type
                </a>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Prefix</th>
                                <th>Initial Consecutive</th>
                                <th>Module</th>
                                <th>Default</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let row of rows | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.name | titlecase}}</td>
                                <td>{{row.prefix}}</td>
                                <td class="text-center">{{row.initial_consecutive}}</td>
                                <td>{{row.usage_context | filterReplace}}</td>
                                <td>
                                    <div class="n-chk">
                                        <label class="new-control new-checkbox checkbox-success">
                                        <input type="checkbox" class="new-control-input"
                                                [(ngModel)]="row.default"
                                                (change)="setDefault(row)"
                                                [checked]="row.default">
                                        <span class="new-control-indicator"></span>&nbsp;
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <a appCompanyRouterLink="/documents-types/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger button-space">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
