import { Estimate } from 'src/app/entities/workspace/estimate';
import { InventoryCategory } from 'src/app/entities/workspace/inventory-category';
import { InventoryContainer } from 'src/app/entities/workspace/inventory-container';
import { InventoryItem } from 'src/app/entities/workspace/inventory-item';
import { ItemContainerPath } from 'src/app/entities/helpers/item-container-path';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { InventoryItemsService } from 'src/app/services/companies/inventory-items.service';
import { QuotesItemsContainersService } from 'src/app/services/companies/quotes-items-containers.service';
import { HelperService } from 'src/app/services/helper.service';
import { InventoryCategoriesService } from 'src/app/services/workspaces/inventory-categories.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { ConstantsMessages } from '../../../constants-messages';
import { EstimateInventoryContainer } from '../../../entities/workspace/estimate-inventory-container';
import { EstimateInventoryContainerItem } from '../../../entities/workspace/estimate-inventory-container-item';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-estimates-inventory-items',
    templateUrl: './estimates-inventory-items.component.html',
    styleUrls: ['./estimates-inventory-items.component.scss']
})
export class EstimatesInventoryItemsComponent implements OnInit, OnDestroy {

    @ViewChild('customContainerModal') customContainerModal: ElementRef;
    @ViewChild('inventoryItemModal') inventoryItemModal: ElementRef;
    @ViewChild('itemsContainer') itemsContainer: ElementRef;
    @ViewChild('searchField') searchField: ElementRef;
    public containers: Array<EstimateInventoryContainer>;
    public dataFormContainer: EstimateInventoryContainer;
    public dataFormItem: EstimateInventoryContainerItem;
    public estimate: Estimate;
    public inventoryCategories: Array<InventoryCategory>;
    public inventoryItems: Array<InventoryItem>;
    public itemContainerPath: ItemContainerPath;
    public totalCF: number;
    public totalItems: number;
    public searchItemByCategory: string;
    public searchItemByName: string;
    public viewInventoryItemModal: 'SELECT' | 'CUSTOM_ITEM' | 'QTY';
    public cfLbsRatio: number;
    public inventoryItemsFiltered: Array<InventoryItem>;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    constructor(
        private currentRoute: ActivatedRoute,
        private estimatesService: EstimatesService,
        private generalSettingsService: GeneralSettingsService,
        private helperService: HelperService,
        private inventoryCategoriesService: InventoryCategoriesService,
        private inventoryItemsQuotesService: InventoryItemsService,
        private quoteItemContainersService: QuotesItemsContainersService,
        private ref: ChangeDetectorRef
    ) {
        this.estimate = new Estimate();
        this.dataFormContainer = new EstimateInventoryContainer();
        this.dataFormItem = new EstimateInventoryContainerItem();

        this.inventoryItems = [];
        this.inventoryItemsFiltered = [];
        this.inventoryCategories = [];
        this.containers = [];

        this.searchItemByName = null;
        this.searchItemByCategory = null;
        this.viewInventoryItemModal = 'SELECT';
        this.itemContainerPath = new ItemContainerPath();
        this.totalItems = 0;
        this.totalCF = 0;
        this.cfLbsRatio = 1;
    }

    ngOnInit(): void {
       
    }

    ngAfterViewInit(): void{
        this.loadInventoryItems();
        this.loadInventoryCategories();
        this.loadCompanySettings();
        // verficamos si se esta editando
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadEstimate(params.id);
                this.loadInventory(params.id);
                this.enableSortable();
            }
        });
    }

    ngOnDestroy() {
        if ( localStorage.getItem('inventory_' + this.estimate.id)){
            swal({
                title: 'You have a current inventory',
                text: 'Would you like to save?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            })
                .then((result) => {
                    if (result.value) {
                      this.saveInventory();
                    }
                });
        } else {
                this.containers = undefined; // unset
                delete (this.containers); // this removes the variable completely
                this.estimate = undefined; // unset
                delete (this.estimate); // this removes the variable completely
        }
       
        this.dataFormContainer = undefined; // unset
        delete (this.dataFormContainer); // this removes the variable completely

        this.dataFormItem = undefined; // unset
        delete (this.dataFormItem); // this removes the variable completely

        this.inventoryItems = undefined; // unset
        delete (this.inventoryItems); // this removes the variable completely

        this.inventoryCategories = undefined; // unset
        delete (this.inventoryCategories); // this removes the variable completely

        this.searchItemByName = undefined; // unset
        delete (this.searchItemByName); // this removes the variable completely

        this.searchItemByCategory = undefined; // unset
        delete (this.searchItemByCategory); // this removes the variable completely

        this.viewInventoryItemModal = undefined; // unset
        delete (this.viewInventoryItemModal); // this removes the variable completely

        this.itemContainerPath = undefined; // unset
        delete (this.itemContainerPath); // this removes the variable completely
    }

    private loadCompanySettings() {
        this.generalSettingsService
            .get()
            .then((response) => {
                this.cfLbsRatio = response.settings.price_setting.cf_lbs_ratio == null ? 7: response.settings.price_setting.cf_lbs_ratio;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response) => {
                this.estimate = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    loadInventory(estimate_id: string) {

        this.totalItems = 0;
        let autoSaveInventory = JSON.parse(localStorage.getItem('inventory_' + estimate_id));
        this.helperService.showLoadingMxpro360();
        this.quoteItemContainersService
            .get(estimate_id)
            .then((response) => {

                if(autoSaveInventory){
                    swal({
                        title: 'You have a recent inventory for this job',
                        text: 'Would you like to continue?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes'
                    })
                        .then((result) => {
                            if (result.value) {
                                this.containers = autoSaveInventory;
                                this.calculateTotals();
                                this.enableSortable();
                                this.reloadMasonry();
                            
                            } else {
                                localStorage.removeItem('inventory_' + this.estimate.id);
                                this.containers = response;
                                this.calculateTotals();
                                this.enableSortable();
                                this.reloadMasonry();
                            }
                        });
                } else{
                    this.containers = response;
                    this.calculateTotals();
                    this.enableSortable();
                    this.reloadMasonry();
                }
               
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private enableSortable() {
        const that = this;
        let idContainers = '';

        // Se agregan todos los ids de los containers
        // for (const iterator of this.containers) {
            for (let index in this.containers) {
            idContainers += '#sortable_' + index + ',';
        }

        // Se elimina la ultima coma de la cadena de ids de los containers
        idContainers = idContainers.substring(0, idContainers.length - 1);
        setTimeout(() => {

            jQuery(idContainers).sortable({
                connectWith: '.connectedSortable',
                stop: (event, ui) => {
                    const indexContainer = ui.item[0].attributes.indexContainer.value;
                    const idItem = ui.item[0].attributes.idItem.value;

                    const positionReciverContainer = ui.item[0].parentNode.attributes.container.value;

                    for (const item of that.containers[indexContainer].items) {
                        if (item.id === idItem && indexContainer !== positionReciverContainer) {
                            that.containers[positionReciverContainer].items.push(item);
                            ui.item.remove();
                            that.containers[indexContainer].items = that.containers[indexContainer].items.filter(item =>
                                item.id !== idItem);
                        }
                    }
                    that.ref.detectChanges();
                    that.reloadMasonry();
                    // that.saveInventory();
                    that.saveTmpInventory();
                }
            }).disableSelection();

            jQuery('.task-list-section').masonry({
                // options
                itemSelector: '.connect-sorting',
                columnWidth: 400
            });
        }, 50);
    }

    private loadInventoryItems() {
        this.helperService.showLoadingMxpro360();
        this.inventoryItemsQuotesService
            .getAll()
            .then((response) => {
                // clona items para conservar su integridad aun cuando se seleccionen categorias
                this.inventoryItems = [...response];
                this.inventoryItemsFiltered = [...response]
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadInventoryCategories() {
        this.helperService.showLoadingMxpro360();
        this.inventoryCategoriesService
            .getAll()
            .then((response) => {
                this.inventoryCategories = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    reloadMasonry() {
        setTimeout(() => {
            jQuery('.task-list-section').masonry('reloadItems');
            jQuery('.task-list-section').masonry('layout');
        }, 100);
    }

    addContainer(defaultContainer: InventoryContainer) {
        const container = new EstimateInventoryContainer();
        container.name = defaultContainer.name;
        this.containers.push(container);
        this.saveTmpInventory();
    }

    addCustomContainer() {
        this.dataFormContainer.id = null;
        this.dataFormContainer.name = null;
        jQuery(this.customContainerModal.nativeElement).modal('show');
    }

    editContainer(index: string) {
        this.dataFormContainer.id = index;
        this.dataFormContainer.name = this.containers[index].name;
        jQuery(this.customContainerModal.nativeElement).modal('show');
    }

    saveContainer() {
        if (this.dataFormContainer.id == null) {
            const container = new EstimateInventoryContainer();
            container.name = this.dataFormContainer.name;
            this.containers.push(container);
        } else {
            this.containers[this.dataFormContainer.id].name = this.dataFormContainer.name;
        }
        this.saveTmpInventory();
        jQuery(this.customContainerModal.nativeElement).modal('hide');
    }

    deleteContainer(index: number) {
        this.containers.splice(index, 1);
        this.saveTmpInventory();
    }

    clearContainer(index: number) {
        this.containers[index].items = [];
        this.saveTmpInventory();
    }

    addItemInventory(containerIndex: number) {
        this.itemContainerPath.container_index = containerIndex;
        this.itemContainerPath.item_index = null;
        this.dataFormItem = new EstimateInventoryContainerItem();
        this.viewInventoryItemModal = 'SELECT';
        this.searchItemByName = null;
        this.searchItemByCategory = null;

        // Realiza la busqueda el container el cual se esta abriendo para filtrar
        // los items por su categoria
        for (const iterator of this.inventoryCategories) {
            if (iterator.name === this.containers[containerIndex].name) {
                this.searchItemByCategory = iterator.id;
                this.filterItemsByCategory(this.searchItemByCategory)
            }
        }

        jQuery(this.inventoryItemModal.nativeElement).modal('show');
        this.enableSortable();
        setTimeout(() => {
            this.searchField.nativeElement.focus();
        }, 700);
    }

    filterItemsByCategory(category: string) {
        const getScrollContainer = document.querySelector('.scroll-default');
        getScrollContainer.scrollTop = 0;
        if (category == null) {
            this.inventoryItemsFiltered = [...this.inventoryItems];
        } else {
            this.inventoryItemsFiltered = this.inventoryItems.filter(item => {
                return item.inventory_category_id === category;
            });
        }
        this.searchItemByCategory = category;
    }

    setItemTemp(item: InventoryItem) {
        this.dataFormItem.inventory_item = item;
        this.dataFormItem.quantity = 1;
        this.saveItemQuote(false);
    }

    setCustomItem() {
        this.viewInventoryItemModal = 'CUSTOM_ITEM';
        this.dataFormItem = new EstimateInventoryContainerItem();
        this.dataFormItem.quantity = 1;
        this.dataFormItem.inventory_item.customized = true;
    }

    saveItemQuote(update) {

        if(this.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS' && this.viewInventoryItemModal == "CUSTOM_ITEM"){
            this.dataFormItem.inventory_item.cubic_feet = this.dataFormItem.inventory_item.cubic_feet / this.cfLbsRatio;
        }

        if (!update) {
            for (let item of this.containers[this.itemContainerPath.container_index].items) {
                if (item.inventory_item.name == this.dataFormItem.inventory_item.name) {
                    item.quantity++;
                    this.dataFormItem = new EstimateInventoryContainerItem();
                    return;
                }
            }
        }

        if (this.itemContainerPath.item_index == null) {

            // No se agrega un inventory container item si viene nulo
            if (this.dataFormItem.inventory_item.name !== null && this.dataFormItem.inventory_item.cubic_feet > 0) {
                this.containers[this.itemContainerPath.container_index].items.push(this.dataFormItem);
            }
        } else {
            this.containers[this.itemContainerPath.container_index].items[this.itemContainerPath.item_index] = this.dataFormItem;
        }

        // Se verifica si se va a actualizar un item
        if (update) {

            // Se verifica el tipo de modal
            if (this.viewInventoryItemModal === 'CUSTOM_ITEM') {
                this.viewInventoryItemModal = 'SELECT';
                jQuery(this.inventoryItemModal.nativeElement).modal('show');
            }
            else {
                jQuery(this.inventoryItemModal.nativeElement).modal('hide');
            }
            // Se actualiza el inventario
            this.saveTmpInventory();
        }

        // Se inicializa de nuevo los items
        this.dataFormItem = new EstimateInventoryContainerItem();
        const THIS = this;
        setTimeout(() => {
            if (THIS.itemsContainer) {
                THIS.itemsContainer.nativeElement.scrollTop = 9999;
            }
        }, 50);

    }

    deleteItemQuote(containerIndex: number, itemIndex: number) {
        this.containers[containerIndex].items.splice(itemIndex, 1);       
        this.saveTmpInventory();
    }

    editItemQuote(containerIndex: number, itemIndex: number) {
        this.viewInventoryItemModal = 'QTY';
        this.itemContainerPath.container_index = containerIndex;
        this.itemContainerPath.item_index = itemIndex;
        this.dataFormItem = this.containers[containerIndex].items[itemIndex];
        jQuery(this.inventoryItemModal.nativeElement).modal('show');
        this.searchItemByName = undefined;
    }

    saveTmpInventory(){
        localStorage.setItem('inventory_' + this.estimate.id, JSON.stringify(this.containers));
        this.enableSortable();
        this.calculateTotals();
        this.reloadMasonry();
        
    }

    saveInventory() {
        this.helperService.showLoadingMxpro360();
        this.quoteItemContainersService
            .set(this.containers, this.estimate.id)
            .then((response) => {
                localStorage.removeItem('inventory_' + this.estimate.id);
                
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/moving/' + this.estimate.id + '/estimate');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    moreQty(Container, Qi) {
        this.containers[Container].items[Qi].quantity++;
        this.calculateTotals();
        this.saveTmpInventory();
    }

    lessQty(Container, Qi) {
        if (this.containers[Container].items[Qi].quantity > 1) {
            this.containers[Container].items[Qi].quantity--;
            this.calculateTotals();
            this.saveTmpInventory();
        }
    }

    closeModalInventory() {
        // Se verifica el tipo de modal
        if (this.viewInventoryItemModal === 'CUSTOM_ITEM') {
            this.viewInventoryItemModal = 'SELECT';
            this.searchItemByName = undefined;
            jQuery(this.inventoryItemModal.nativeElement).modal('show');
        }
        else {
            jQuery(this.inventoryItemModal.nativeElement).modal('hide');
            this.loadInventory(this.estimate.id);
        }
    }

    saveAll() {
        this.saveItemQuote(true);
        jQuery(this.inventoryItemModal.nativeElement).modal('hide');
    }

    onKeydownSearch() {
        this.searchItemByCategory = null;
        this.searchItemByName = null;
        this.inventoryItemsFiltered = [...this.inventoryItems];
    }

    public calculateTotals(): void {
        this.totalCF = 0;
        this.totalItems = 0;
        for (const container of this.containers) {
            for (const item of container.items) {
                this.totalCF = this.totalCF + (item.inventory_item.cubic_feet * item.quantity);
                this.totalItems = this.totalItems + item.quantity;
            }
        }
        // determina si se debe sumar redondear la cantidad de pies cubicos
        const whole = Math.floor(this.totalCF);
        const fraction = this.totalCF - whole;
        if (fraction > 0) {
            // siempre redondeo por encima
            this.totalCF = whole + 1;
        }     
    }

}
