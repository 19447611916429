import { AuthorizeSetting } from 'src/app/entities/workspace/authorize-setting';
import { CardknoxSetting } from './cardknox-setting';
import { IngenicoSetting } from 'src/app/entities/workspace/ingenico-setting';
import { MollieSetting } from 'src/app/entities/workspace/mollie-setting';
import { PaypalSetting } from 'src/app/entities/workspace/paypal-setting';
import { SquareSetting } from './square-setting';
import { StripeSetting } from 'src/app/entities/workspace/stripe-setting';
import { SwipeportSetting } from './swipeport-setting';

export class PaymentGatewaySetting {

    public id: string;
    public name: 'AUTHORIZE' | 'STRIPE' | 'PAYPAL' | 'MOLLIE' | 'INGENICO' | 'SWIPEPORT' | 'SQUARE' | 'CARDKNOX';
    public nickname: string;
    public active_cc: boolean;
    public storage_cc: boolean;
    public active_ach: boolean;
    public cc_fee: number;
    public production: boolean;
    public authorize_setting: AuthorizeSetting;
    public stripe_setting: StripeSetting;
    public paypal_setting: PaypalSetting;
    public mollie_setting: MollieSetting;
    public ingenico_setting: IngenicoSetting;
    public swipeport_setting: SwipeportSetting;
    public square_setting: SquareSetting;
    public cardknox_setting: CardknoxSetting;
    public company_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.nickname = null;
        this.active_cc = false;
        this.cc_fee = null;
        this.storage_cc = false;
        this.active_ach = false;
        this.production = false;
        this.authorize_setting = new AuthorizeSetting();
        this.stripe_setting = new StripeSetting();
        this.paypal_setting = new PaypalSetting();
        this.mollie_setting = new MollieSetting();
        this.ingenico_setting = new IngenicoSetting();
        this.swipeport_setting = new SwipeportSetting();
        this.square_setting = new SquareSetting();
        this.cardknox_setting = new CardknoxSetting();
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
