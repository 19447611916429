<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
            <a appWorkspaceRouterLink="/volumes/add" class="btn btn-main-action">
                <i class="fas fa-plus-square"></i> &nbsp; New Volume
            </a>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Up To 
                                    <strong *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'CUBIC_FEET'">Cf </strong>
                                    <strong *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'POUNDS'">Lbs </strong>
                                </th>
                                <!-- <th>weight</th> -->
                                <th>created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of tariffVersionsSettingsService.volumes | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.up_to_cf}}</td>
                                <!-- <td>{{row.weight}}</td> -->
                                <td>{{row.created | dateTime}}</td>
                                <td>
                                    <a appWorkspaceRouterLink="/volumes/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>