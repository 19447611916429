<div class="borde-search">
    <label class="control-label" [ngClass]="{'txt-search-noGoogle': !isGoogle, 'txt-search-google':isGoogle}">{{label}}</label>

        <div class="input-group">

            <input #inputSearch type="text" [disabled]="isGoogle" size="50" placeholder="Enter a location" class="form-control" autocomplete="on" runat="server" [(ngModel)]="fullAddress" (input)="doInput()"/>

            <div *ngIf="useCustomAddress" class="btn btn-info location-search-button"  (click)="isGoogle = !isGoogle" >
                <i class="fa-solid" [ngClass]="{'fa-chevron-down': !isGoogle, 'fa-chevron-up':isGoogle}"></i>
            </div>

        </div>

        <ng-container *ngIf="isGoogle">

            <div class="form-group mb-1 mt-4">
                <label class="control-label">Zip Code</label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map"><polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon><line x1="8" y1="2" x2="8" y2="18"></line><line x1="16" y1="6" x2="16" y2="22"></line></svg></span>
                    </div>
                    <input type="text" maxlength="10" pattern="[0-9]*" inputmode="numeric" (blur)="setAddress()" placeholder="Zip code" class="form-control" name="zip_code" [(ngModel)]="address.zip_code"/>
                </div>
                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Zip Code.</small>
            </div>
               
            <div class="form-group mb-1">
                <label class="control-label">State</label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                    </div>
                    <input type="text" size="50" min="1" (blur)="setAddress()" placeholder="State" class="form-control" name="state" [(ngModel)]="address.state"/>
                </div>
                <small id="emailHelp1" class="form-text text-muted mb-4">Enter State.</small>
            </div>


            <div class="form-group mb-1">
                <label class="control-label">City</label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                    </div>
                    <input type="text" size="50" min="1" (blur)="setAddress()" placeholder="City" class="form-control" name="city" [(ngModel)]="address.city"/>
                </div>
                <small id="emailHelp1" class="form-text text-muted mb-4">Enter City.</small>
            </div>


            <div class="form-group mb-1">
                <label class="control-label">Street</label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                    </div>
                    <input type="text" size="50" min="1" (blur)="setAddress()" placeholder="Street" class="form-control" name="flstreetoor" [(ngModel)]="address.street"/>
                </div>
                <small id="emailHelp1" class="form-text text-muted mb-2">Enter Street.</small>
            </div>

        </ng-container>
    <small *ngIf="insertAddress" id="emailHelp1" class="form-text text-muted mb-4 alert-no-address">Please Select an Address</small>
</div>

<div [hidden]="!addressFull">

    <hr> 

    <div #parentDiv class="row parent-div">   

        <div class="col-12 form-group mb-1">
            <label class="control-label">Property Type</label>
            <select name="property_type" id="state" (change)="changeInfo()" [(ngModel)]="address.property_type" class="form-control">
                <option *ngFor="let optionPropertyType of optionsPropertyType" value="{{optionPropertyType}}">{{optionPropertyType | filterReplace}}</option>
            </select>
            <small class="form-text text-muted mb-4">Select a Property Type.</small>
        </div>
        
        <ng-container *ngIf="isNotAutoTransport">

            <div class="col-12 form-group mb-1">
                <label class="control-label">Level</label>
                <select name="level" id="state" (change)="changeInfo()" [(ngModel)]="address.level" class="form-control">
                    <option *ngFor="let optionLevelType of optionsLevelType" value="{{optionLevelType}}">{{optionLevelType | filterReplace}}</option>
                </select>
                <small class="form-text text-muted mb-4">Select a Level.</small>
            </div>

            <div #childDiv [ngClass]="childClass" class="form-group mb-1">
                
                <div class="form-group mb-1">
                    <label class="control-label">Floor</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg></span>
                        </div>
                        <input type="text" onlyNumbers size="50" min="1" (change)="changeInfo()" placeholder="Type a Floor" class="form-control" name="floor" [(ngModel)]="address.floor"/>
                    </div>

                    <small class="form-text text-muted mb-4">Enter a Floor Number.</small>
                </div>

            </div>

            <div #childDiv [ngClass]="childClass" class="form-group mb-1">

                <div class="form-group mb-1">
                    <label class="control-label">Apartment Number</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg></span>
                        </div>
                        <input type="text" size="50" (change)="changeInfo()" alphaNumeric placeholder="Type a Apartment Number" class="form-control" name="apartment_number" [(ngModel)]="address.apartment_number"/>
                    </div>

                    <small class="form-text text-muted mb-4">Enter the Apartment Number.</small>
                </div>          
            
            </div>

            <div #childDiv [ngClass]="childClass" class="form-group mb-1">

                <div class="form-group mb-1">
                    <label class="control-label">Unit Number</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trello"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><rect x="7" y="7" width="3" height="9"></rect><rect x="14" y="7" width="3" height="5"></rect></svg></span>
                        </div>
                        <input type="text" alphaNumeric size="50" (change)="changeInfo()" placeholder="Type a Unit Number" class="form-control" name="storage_unit_number" [(ngModel)]="address.storage_unit_number"/>
                    </div>

                    <small class="form-text text-muted mb-4">Enter the Number of the Unit.</small>
                </div>  
            </div>
        </ng-container>
    </div>
<div>
