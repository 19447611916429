<nav id="sidebar" [ngClass]="{'workspace': menuType == 'WORKSPACE', 'company': menuType == 'COMPANY' }">

    <ul class="navbar-nav theme-brand flex-row  text-center">
        <li class="nav-item theme-logo">
            <a appWorkspaceRouterLink="/dashboard">
                <img src="/assets/img/logotipo_moverxpro360.png" height="70px" class="navbar-logo" alt="logo">
            </a>
        </li>
        <li class="nav-item theme-text">
            <a appWorkspaceRouterLink="/dashboard" class="nav-link">
                <img src="/assets/img/logotipo_text_moverxpro360.png" width="105px" class="navbar-logo logo-white" alt="logo">
            </a>
        </li>
        <li class="nav-item toggle-sidebar">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-left sidebarCollapse">
                <a class="fas fa-compress-alt"></a>
            </svg>
        </li>
    </ul>

    <div class="shadow-bottom"></div>

    <ng-container *ngIf="menuType == 'WORKSPACE'">
        <ul class="list-unstyled menu-categories" id="accordionExample">

            <!-- Dashboard -->
            <li class="menu">
                <a appWorkspaceRouterLink="/dashboard" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fas fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </div>
                </a>
            </li>

            <!-- Companies -->
            <li class="menu" appCanUserWorkspace="COMPANIES_VIEW">
                <a appWorkspaceRouterLink="/companies" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fas fa-city"></i>
                        <span>Companies</span>
                    </div>
                </a>
            </li>

            <!-- Calendars -->
            <li class="menu">
                <a href="#submenuCalendars" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="far fa-calendar-alt"></i>
                        <span>Calendars</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuCalendars" data-parent="#accordionExample">
                    <li [appCanUserWorkspace]="['CALENDAR_HHG_VIEW', 'CALENDAR_HHG_MANAGER']">
                        <a appWorkspaceRouterLink="/calendar" appRouterLinkActive="active">
                            HHG
                        </a>
                    </li>
                    <li [appCanUserWorkspace]="['FREIGHT_CALENDAR_AT_VIEW', 'FREIGHT_CALENDAR_AT_MANAGER']">
                        <a appWorkspaceRouterLink="/calendar-freight" appRouterLinkActive="active">
                            Freight
                        </a>
                    </li>
                    <li [appCanUserWorkspace]="['CALENDAR_AT_VIEW', 'CALENDAR_AT_MANAGER']">
                        <a appWorkspaceRouterLink="/calendar/auto-transport" appRouterLinkActive="active">
                            Auto Transport
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Tickets -->
            <li class="menu" [appCanUserWorkspace]="['TICKETS_VIEW', 'TICKETS_MANAGER']" >
                <a appWorkspaceRouterLink="/tickets" appRouterLinkActive="active" aria-expanded="false"
                    class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-person-circle-question"></i>
                        <span>Tickets</span>
                    </div>
                </a>
            </li>

            <!-- Dispatch -->
            <li class="menu">
                <a href="#submenuDispatch" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-truck"></i>
                        <span>Dispatch</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuDispatch" data-parent="#accordionExample">

                    <li [appCanUserWorkspace]="['DRIVER_INVOICES_VIEW', 'DRIVER_INVOICE_MANAGER']" >
                        <a appWorkspaceRouterLink="/driver-invoices" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                            <div>
                                <!-- <i class="fas fa-dolly"></i> -->
                                <span>Driver Invoices</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserWorkspace="WAREHOUSE_FADD_JOBS">
                        <a appWorkspaceRouterLink="/warehouses-fadd-jobs" appRouterLinkActive="active" aria-expanded="false"
                            class="dropdown-toggle">
                            <div>
                                <!-- <i class="fa-solid fa-timeline"></i> -->
                                <span>
                                    FADD Dispatch
                                </span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserWorkspace="STORAGE_INVOICES_VIEW">
                        <a appWorkspaceRouterLink="/storage-invoices" appRouterLinkActive="active" aria-expanded="false"
                            class="dropdown-toggle">
                            <div>
                                <!-- <i class="fa-solid fa-file-invoice-dollar"></i> -->
                                <span>Storage Invoices</span>
                            </div>
                        </a>
                    </li>

                    <li [appCanUserWorkspace]="['CARRIERS_VIEW', 'CARRIERS_MANAGER']" >
                        <a appWorkspaceRouterLink="/carriers" appRouterLinkActive="active" aria-expanded="false"
                            class="dropdown-toggle">
                            <div>
                                <!-- <i class="fa-solid fa-truck-ramp-box"></i> -->
                                <span>Carriers/Brokers</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserWorkspace="CARRIERS_COVERAGES">
                        <a appWorkspaceRouterLink="/carriers-coverages-map" appRouterLinkActive="active" aria-expanded="false"
                            class="dropdown-toggle">
                            <div>
                                <!-- <i class="fa-solid fa-location-dot"></i> -->
                                <span>Carriers Coverages</span>
                            </div>
                        </a>
                    </li>

                    <li [appCanUserWorkspace]="['STORAGE_MANAGER_VIEW', 'STORAGE_MANAGER_MANAGE']">
                        <a appWorkspaceRouterLink="/storage-manager" appRouterLinkActive="active" aria-expanded="false"
                            class="dropdown-toggle">
                            <div>
                                <!-- <i class="fa-solid fa-file-invoice-dollar"></i> -->
                                <span>Storage Manager</span>
                            </div>
                        </a>
                    </li>

                </ul>
            </li>

            <!-- Reports -->
            <li class="menu" [appCanUserWorkspace]="['LEADS_REPORT', 'LEAD_ADVERTISERS_REPORT', 'CANCEL_JOB_LIST']">
                <a href="#submenuReport" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fas fa-chart-pie"></i>
                        <span>Reports</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuReport" data-parent="#accordionExample">
                    <li appCanUserWorkspace="LEADS_REPORT">
                        <a appWorkspaceRouterLink="/leads-report" appRouterLinkActive="active">
                            Leads
                        </a>
                    </li>
                    <li appCanUserWorkspace="LEAD_ADVERTISERS_REPORT">
                        <a appWorkspaceRouterLink="/leads-advertisers-report" appRouterLinkActive="active">
                            Leads Advertisers
                        </a>
                    </li>
                    <li appCanUserWorkspace="LEADS_REPORT">
                        <a appWorkspaceRouterLink="/bad-leads-report" appRouterLinkActive="active">
                            Bad Leads
                        </a>
                    </li>
                    <li>
                        <a appWorkspaceRouterLink="/company-sales-performance" appRouterLinkActive="active">
                            Sales Performance
                        </a>
                    </li>
                    <li appCanUserWorkspace="QA_REPORT">
                        <a appWorkspaceRouterLink="/qa-report" appRouterLinkActive="active">
                            QA Report
                        </a>
                    </li>
                    <li>
                        <a appWorkspaceRouterLink="/carrier-jobs-report" appRouterLinkActive="active">
                            Carrier Jobs
                        </a>
                    </li>
                    <li>
                        <a appWorkspaceRouterLink="/pickup-documents-report" appRouterLinkActive="active">
                            Pickup Docs
                        </a>
                    </li>
                    <!-- Acceso agregado al interior de la sección "Pickup Docs" -->
                    <!-- <li>
                        <a appWorkspaceRouterLink="/archive-pickup-documents-report" appRouterLinkActive="active">
                            Archived Pickup Docs
                        </a>
                    </li> -->
                    <li>
                        <a appWorkspaceRouterLink="/delivery-documents-report" appRouterLinkActive="active">
                            Delivery Docs
                        </a>
                    </li>
                    <!-- Acceso agregado al interior de la sección "Delivery Docs" -->
                    <!-- <li>
                        <a appWorkspaceRouterLink="/archive-delivery-documents-report" appRouterLinkActive="active">
                            Archived Delivery Docs
                        </a>
                    </li> -->

                    <li appCanUserWorkspace="CANCEL_JOB_LIST">
                      <a appWorkspaceRouterLink="/canceled-jobs-list" appRouterLinkActive="active">
                          Cancelled Jobs
                      </a>
                  </li>
<!--    active agents reportes en vivo
                  <li >
                    <a appWorkspaceRouterLink="/active-agents" appRouterLinkActive="active">
                        Active Agents
                    </a>
                </li> -->
                </ul>
            </li>

            <!-- Set Up -->
            <li class="menu" appCanUserWorkspace="TARIFFS">

                <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <div>
                        <i class="fa-solid fa-gear"></i>
                        <span>Set Up</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>

                <div class="accordion" id="accordionSetup">
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">

                        <!-- Tariff Center -->
                        <div class="accordion accordion-submenu">

                            <div id="headingTwo">
                                <ul class="submenu list-unstyled">
                                    <li>
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div>
                                                <span>Tariff Center </span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSetup">
                                <ul class="submenu submenu2 list-unstyled">
                                    <!-- <li>
                                        <a appWorkspaceRouterLink="/tariff-versions" appRouterLinkActive="active"> Tariff Versions </a>
                                    </li> -->
                                    <li>
                                        <a id="sidebar-setting-section" appWorkspaceRouterLink="/settings/zones" appRouterLinkActive="active"> LD Tariff </a>
                                    </li>
                                    <!-- <li>
                                        <a appWorkspaceRouterLink="/additional-stop-rules" appRouterLinkActive="active"> LD Additional Stop Rules </a>
                                    </li> -->
                                    <li>
                                        <a appWorkspaceRouterLink="/local-tariff-settings" appRouterLinkActive="active"> Local Tariff</a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/tariff-additional-concepts" appRouterLinkActive="active"> Additional Concepts </a>
                                    </li>
                                    <li [appCanUserWorkspace]="['INVENTORY_CATEGORIES_VIEW', 'INVENTORY_CATEGORIES_MANAGER']"  >
                                        <a appWorkspaceRouterLink="/inventory-categories" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-clipboard-list"></i> -->
                                            Inventory Categories
                                        </a>
                                    </li>

                                    <li [appCanUserWorkspace]="['INVENTORY_ITEMS_VIEW', 'INVENTORY_ITEMS_MANAGER']" >
                                        <a appWorkspaceRouterLink="/inventory-items" appRouterLinkActive="active">
                                            <!-- <i class="fas fa-cubes"></i> -->
                                            Inventory Items
                                        </a>
                                    </li>

                                    <li>
                                        <a appWorkspaceRouterLink="/local-room-categories" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-clipboard-list"></i> -->
                                            Local Room Categories
                                        </a>
                                    </li>

                                    <li>
                                        <a appWorkspaceRouterLink="/local-room-closets" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-clipboard-list"></i> -->
                                            Local Room Closets
                                        </a>
                                    </li>

                                    <li [appCanUserWorkspace]="['PACKING_MATERIALS_VIEW', 'PACKING_MATERIALS_MANAGER']" >
                                        <a appWorkspaceRouterLink="/packing-materials" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-boxes-packing"></i> -->
                                            Packing Materials
                                        </a>
                                    </li>

                                    <li appCanUserWorkspace="BLACKOUT_MOVE_DATES">
                                        <a appWorkspaceRouterLink="/blackout-move-dates" appRouterLinkActive="active">
                                            <!-- <i class="fa-regular fa-calendar-xmark"></i> -->
                                            Blackout Move Dates
                                        </a>
                                    </li>

                                    <li appCanUserWorkspace="COMPANIES_VIEW">
                                        <a #settingsLink appWorkspaceRouterLink="/settings" appRouterLinkActive="active">
                                            Settings
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Insurance -->
                        <div class="accordion accordion-submenu">

                            <div id="headingThree">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div>
                                                <span>Insurance</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionSetup">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li>
                                        <a appWorkspaceRouterLink="/liability-deductible-rates" appRouterLinkActive="active"> Premium Variables </a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/deductible-levels" appRouterLinkActive="active"> Deductible Levels </a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/liability-amounts" appRouterLinkActive="active"> Liability Amounts </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Dispatch -->
                        <div class="accordion accordion-submenu">

                            <div id="headingFour">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <div>
                                                <span>Dispatch</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionSetup">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li [appCanUserWorkspace]="['WAREHOUSES_VIEW', 'WAREHOUSES_MANAGER']">
                                        <a appWorkspaceRouterLink="/warehouses" appRouterLinkActive="active">
                                            <!-- <i class="fas fa-warehouse"></i> -->
                                            Warehouses
                                        </a>
                                    </li>

                                    <li [appCanUserWorkspace]="['COMPANY_VEHICLES_VIEW', 'COMPANY_VEHICLES_MANAGER']">
                                        <a appWorkspaceRouterLink="/vehicles" appRouterLinkActive="active">
                                            <!-- <i class="fas fa-truck-moving"></i> -->
                                            Vehicles
                                        </a>
                                    </li>

                                    <li [appCanUserWorkspace]="['STORAGE_COMPANIES_VIEW', 'STORAGE_COMPANIES_MANAGER']" >
                                        <a appWorkspaceRouterLink="/storage-companies" appRouterLinkActive="active">
                                            <!-- <i class="fas fa-building"></i> -->
                                            External Storages
                                        </a>
                                    </li>

                                    <li>
                                        <a appWorkspaceRouterLink="/dispatch-contacts" appRouterLinkActive="active">
                                            <!-- <i class="fa-regular fa-address-book"></i> -->
                                            Dispatch Contacts
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Leads -->
                        <div class="accordion accordion-submenu">

                            <div id="headingFive">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            <div>
                                                <span>Leads</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionSetup">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li [appCanUserWorkspace]="['ADVERTISERS_VIEW', 'ADVERTISERS_MANAGER']">
                                        <a appWorkspaceRouterLink="/advertisers" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-sitemap"></i> -->
                                            Advertisers
                                        </a>
                                    </li>

                                    <li appCanUserWorkspace="BUILD_POSTING_LEADS">
                                        <a appWorkspaceRouterLink="/build-posting-leads" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-link"></i> -->
                                            Build Posting Link
                                        </a>
                                    </li>

                                    <li [appCanUserWorkspace]="['ADVERTISERS_VIEW', 'ADVERTISERS_MANAGER']">
                                        <a appWorkspaceRouterLink="/load-leads-by-excel" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-cloud-arrow-up"></i> -->
                                            Upload Leads
                                        </a>
                                    </li>

                                    <li appCanUserWorkspace="BUILD_POSTING_LEADS">
                                        <a appWorkspaceRouterLink="/lead-forwarding-links" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-link"></i> -->
                                            Leads Forwarding
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Users -->
                        <div class="accordion accordion-submenu">

                            <div id="headingSix">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            <div>
                                                <span>Users</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionSetup">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li [appCanUserWorkspace]="['ROLES_VIEW', 'ROLES_MANAGER']" >
                                        <a appWorkspaceRouterLink="/roles" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-person-circle-exclamation"></i> -->
                                            Roles
                                        </a>
                                    </li>

                                    <li [appCanUserWorkspace]="['EMPLOYEES_VIEW', 'EMPLOYEES_VIEW']">
                                        <a appWorkspaceRouterLink="/employees" appRouterLinkActive="active">
                                            <!-- <i class="fa-solid fa-universal-access"></i> -->
                                            Employees
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- PBX -->
                        <div class="accordion accordion-submenu" [appCanUserWorkspace]="['BUY_NUMBERS','BUY_NUMBERS']">

                            <div id="headingSeven">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <div>
                                                <span>PBX</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionSetup">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li>
                                        <a appWorkspaceRouterLink="/phone-lines" appRouterLinkActive="active">
                                            Phone Line
                                        </a>
                                    </li>
                                    <li>
                                      <a appWorkspaceRouterLink="/phone-extensions" appRouterLinkActive="active">
                                          Phone Extension
                                      </a>
                                  </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/buy-numbers" appRouterLinkActive="active">
                                            Buy Number
                                        </a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/phone-ring-groups" appRouterLinkActive="active">
                                            Ring Groups
                                        </a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/phone-queues" appRouterLinkActive="active">
                                            Phone Queues
                                        </a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/phone-ivrs" appRouterLinkActive="active">
                                            IVR
                                        </a>
                                    </li>
                                    <li>
                                        <a appWorkspaceRouterLink="/phone-cdr" appRouterLinkActive="active">
                                            Call Records
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

            </li>

            <!-- Help -->
            <li class="menu" [appCanUserWorkspace]="['LEADS_REPORT', 'LEAD_ADVERTISERS_REPORT', 'CANCEL_JOB_LIST']">
                <a href="#submenuHelp" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-suitcase-medical"></i>
                        <span>Help</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuHelp" data-parent="#accordionExample">
                    <li>
                        <a (click)="chatWhatsapp()" aria-expanded="false" class="dropdown-toggle">
                            <!-- <i class="fab fa-whatsapp"></i> -->
                            <span>Live Chat</span>
                        </a>
                    </li>                    
                    <li>
                        <a href="https://moverxpro360.com/guide" target="_blank" aria-expanded="false">
                            <!-- <i class="fab fa-youtube"></i> -->
                            <span>Tutorials</span>
                        </a>
                    </li>                    
                </ul>
            </li>




            <!-- <div class="section-heading">
                <span>LEADS</span>
            </div> -->

            <!-- <li class="menu" [appCanUserWorkspace]="['BOARD_JOBS_VIEW', 'BOARD_JOB_MANAGER']" >
                <a appWorkspaceRouterLink="/board-jobs" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-chalkboard"></i>
                        <span>Jobs Board</span>
                    </div>
                </a>
            </li> -->



            <!-- <div class="section-heading">
                <span>LEGAL</span>
            </div>

            <li class="menu">
                <a href="https://moverxpro360.com/term-and-conditions" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-book-open">
                            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" /></svg>
                        <span>Terms</span>
                    </div>
                </a>
            </li>

            <li class="menu">
                <a href="https://moverxpro360.com/privacy-policy" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-file-text">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                            <polyline points="14 2 14 8 20 8" />
                            <line x1="16" y1="13" x2="8" y2="13" />
                            <line x1="16" y1="17" x2="8" y2="17" />
                            <polyline points="10 9 9 9 8 9" /></svg>
                        <span>Privacy Policy</span>
                    </div>
                </a>
            </li> -->


        </ul>
    </ng-container>

    <ng-container *ngIf="menuType == 'COMPANY'">
        <ul class="list-unstyled menu-categories" id="accordionExample">

            <!-- Dashboard -->
            <li class="menu">
                <a appCompanyRouterLink="/dashboard" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fas fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </div>
                </a>
            </li>

            <!-- Company Calendar -->
            <li class="menu" [appCanUserCompany]="['JOBS_CALENDAR_HHG_VIEW', 'JOBS_CALENDAR_AT_VIEW']">
                <a href="#submenuCompanyCalendars" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="far fa-calendar-alt"></i>
                        <span>Company Calendar</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuCompanyCalendars" data-parent="#accordionExample">
                    <li appCanUserCompany="JOBS_CALENDAR_HHG_VIEW" >
                        <a appCompanyRouterLink="/jobs-calendar" appRouterLinkActive="active" aria-expanded="false">
                            <span>Calendar HHG</span>
                        </a>
                    </li>
                    <!-- 
                    <li class="menu">
                        <a appRouterLinkActive="active" aria-expanded="false">
                            <span>Calendar Freight</span>
                        </a>
                    </li>  
                    -->
                    <li appCanUserCompany="JOBS_CALENDAR_AT_VIEW">
                        <a appCompanyRouterLink="/jobs-calendar/auto-transport" appRouterLinkActive="active" aria-expanded="false">
                            <span>Calendar AT</span>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Tickets -->
            <li class="menu" appCanUserCompany="TICKETS_VIEW">
                <a appCompanyRouterLink="/tickets" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-check-to-slot"></i>
                        <span>Tickets</span>
                    </div>
                </a>
            </li>

            <!-- Phone (Dialer) 
                <li class="menu" appCanUserCompany="SMS_CHAT">
                    <a (click)='openCompanyDialer()' appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <a appCompanyRouterLink="/dialer" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                        <div>
                            <i class="fas fa-headset"></i>
                            <span>Dialer</span>
                        </div>
                    </a>
                </li>
            -->

            <li class="menu" appCanUserCompany="SMS_CHAT">
                <!-- <a (click)='openCompanyDialer()' appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle"> -->
                <a appCompanyRouterLink="/phone" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fas fa-headset"></i>
                        <span>Phone</span>
                    </div>
                </a>
            </li>

            <!-- Sales -->
            <li class="menu" [appCanUserCompany]="['LEADS','ESTIMATES_VIEW', 'FOLLOW_UPS', 'JOBS_VIEW', 'EMAIL_CAMPAIGNS_VIEW', 'EMAIL_CAMPAIGNS_MANAGER', 'SMS_CAMPAIGNS_VIEW', 'SMS_CAMPAIGNS_MANAGER', 'EXPORT_LEADS']">

                <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseOneSales" aria-expanded="false" aria-controls="collapseOneSales">
                    <div>
                        <i class="fa-solid fa-file-invoice-dollar"></i>
                        <span>Sales</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>

                <div class="accordion no-submenu">
                    <div id="collapseOneSales" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">

                        <!-- My Leads -->                       
                        <li class="menu" appCanUserCompany="LEADS" [hidden]="agetLeadMode == 'POOL'">
                            <a appCompanyRouterLink="/my-leads" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <span>My Leads</span>
                                </div>
                            </a>
                        </li>

                        <!-- Leads -->
                        <li class="menu" [hidden]="!displayLeadSection"> 
                            <a appCompanyRouterLink="/leads" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <span>Leads</span>
                                </div>
                            </a>
                        </li>

                        <!-- Estimates -->
                        <li class="menu" appCanUserCompany="ESTIMATES_VIEW">
                            <a appCompanyRouterLink="/estimates" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <span>Estimates</span>
                                </div>
                            </a>
                        </li>

                        <!-- Follow Ups -->
                        <li class="menu" appCanUserCompany="FOLLOW_UPS">
                            <a appCompanyRouterLink="/follow-ups-reminders" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <span>Follow Ups</span>
                                </div>
                            </a>
                        </li>

                        <!-- Pending QA -->
                        <li class="menu" appCanUserCompany="JOBS_VIEW" >
                            <a appCompanyRouterLink="/pending-qa-jobs" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <span>Pending QA</span>
                                </div>
                            </a>
                        </li> 

                        <!-- Campaigns -->
                        <div class="accordion accordion-submenu" id="accordionExample2" [appCanUserCompany]="['EMAIL_CAMPAIGNS_VIEW', 'EMAIL_CAMPAIGNS_MANAGER', 'SMS_CAMPAIGNS_VIEW', 'SMS_CAMPAIGNS_MANAGER']">

                            <div id="headingTwo">
                                <ul class="submenu list-unstyled">
                                    <li>
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div>
                                                <span>Campaigns</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample2">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li [appCanUserCompany]="['EMAIL_CAMPAIGNS_VIEW', 'EMAIL_CAMPAIGNS_MANAGER']">
                                        <a appCompanyRouterLink="/email-campaigns" appRouterLinkActive="active" aria-expanded="false">Email</a>
                                    </li>
                                    <li [appCanUserCompany]="['SMS_CAMPAIGNS_VIEW', 'SMS_CAMPAIGNS_MANAGER']">
                                        <a appCompanyRouterLink="/sms-campaigns" appRouterLinkActive="active" aria-expanded="false">Text</a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Export Leads -->
                        <li class="menu" appCanUserCompany="EXPORT_LEADS">
                            <a appCompanyRouterLink="/export-leads" appRouterLinkActive="active" aria-expanded="false" class="dropdown-toggle">
                                <div>
                                    <span>Export Leads</span>
                                </div>
                            </a>
                        </li>

                    </div>
                </div>



            </li>

            <!-- Operations -->
            <li class="menu" [appCanUserCompany]="['JOBS_VIEW', 'CUSTOM_LABELS', 'CREATE_A_CLAIM', 'EMPLOYEES', 'ACTIVE_AGENTS']">
                <a href="#submenuOperations" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-truck-ramp-box"></i>
                        <span>Operations</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuOperations" data-parent="#accordionExample">
                    <li appCanUserCompany="JOBS_VIEW" >
                        <a appCompanyRouterLink="/jobs" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Jobs</span>
                            </div>
                        </a>
                    </li>
                    <li appCanUserCompany="CUSTOM_LABELS">
                        <a appCompanyRouterLink="/custom-labels" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Custom Labels</span>
                            </div>
                        </a>
                    </li>
                    <li appCanUserCompany="CREATE_A_CLAIM">
                        <a appCompanyRouterLink="/claims-list" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Claims</span>
                            </div>
                        </a>
                    </li>
                    <li appCanUserCompany="EMPLOYEES">
                        <a appCompanyRouterLink="/employees" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Employees</span>
                            </div>
                        </a>
                    </li>
                    <li appCanUserCompany="ACTIVE_AGENTS">
                        <a appCompanyRouterLink="/active-agents" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Active Agents</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>

            <!-- Reports -->
            <li class="menu" [appCanUserCompany]="['RECURRING_CUSTOMERS', 'PERFORMANCE_REPORTS', 'QA_REPORT', 'PROFIT_AND_LOSS', 'CC_PROCESSING']">
                <a href="#submenuReports" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-chart-pie"></i>
                        <span>Reports</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuReports" data-parent="#accordionExample">

                    <li appCanUserCompany="RECURRING_CUSTOMERS">
                        <a appCompanyRouterLink="/recurring-customers" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Recurring Customers</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="PERFORMANCE_REPORTS" >
                        <a appCompanyRouterLink="/my-performs" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>My Performance</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="PERFORMANCE_REPORTS" >
                        <a appCompanyRouterLink="/salesman-performs" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Sale Performance</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="QA_REPORT">
                        <a appCompanyRouterLink="/qa-report" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>QA (Quality Assurance)</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="PROFIT_AND_LOSS">
                        <a appCompanyRouterLink="/profit-loss-report" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>Job Profit & Loss</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="CC_PROCESSING">
                        <a appCompanyRouterLink="/cc-processing-report" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <span>CC Fee</span>
                            </div>
                        </a>
                    </li>

                </ul>
            </li>

            <!-- Accounting -->
            <li class="menu" [appCanUserCompany]="['PAYROLL_BALANCES_VIEW', 'PAYROLL_BALANCE_MANAGER', 'ACCOUNTING_PAYROLL', 'ACCOUNTING_TIME_CLOCK', 'BALANCES', 'ACCOUNTING_TREASURY']">

                <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseOneAccounting" aria-expanded="false" aria-controls="collapseOneAccounting">
                    <div>
                        <i class="fa-solid fa-circle-dollar-to-slot"></i>
                        <span>Accounting</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>

                <div class="accordion" id="accordionAccounting" appCanUserCompany="ACCOUNTING_TREASURY">
                    <div id="collapseOneAccounting" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">

                        <!-- Treasury -->
                        <div class="accordion accordion-submenu">

                            <div id="headingTwo">
                                <ul class="submenu list-unstyled">
                                    <li>
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <div>
                                                <span>Treasury</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionAccounting">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li appCanUserCompany="BALANCES">
                                        <a appCompanyRouterLink="/payments" appRouterLinkActive="active" aria-expanded="false">
                                            Balances
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Payroll -->
                        <div class="accordion accordion-submenu" [appCanUserCompany]="['ACCOUNTING_PAYROLL']">

                            <div id="headingThree">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <div>
                                                <span>Payroll</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionAccounting">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li [appCanUserCompany]="['PAYROLL_BALANCES_VIEW', 'PAYROLL_BALANCE_MANAGER']">
                                        <a appCompanyRouterLink="/payroll-balances" appRouterLinkActive="active" aria-expanded="false">
                                            Payroll Balances
                                        </a>
                                    </li>

                                    <li [appCanUserCompany]="['SALES_COMMISSIONS_VIEW', 'SALES_COMMISSIONS_MANAGER']" >
                                        <a appCompanyRouterLink="/sales-commissions" appRouterLinkActive="active" aria-expanded="false">
                                            Sales Commissions
                                        </a>
                                    </li>

                                    <li>
                                        <a appCompanyRouterLink="/payroll-payments" appRouterLinkActive="active" aria-expanded="false">
                                            Payroll Payments
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <!-- Time Clock -->
                        <div class="accordion accordion-submenu" [appCanUserCompany]="['ACCOUNTING_TIME_CLOCK']">

                            <div id="headingFour">
                                <ul class="submenu list-unstyled">
                                    <li class="submenu list-unstyled">
                                        <a class="dropdown-toggle" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <div>
                                                <span>Time Clock</span>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-right">
                                                    <polyline points="9 18 15 12 9 6"></polyline>
                                                </svg>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionAccounting">
                                <ul class="submenu submenu2 list-unstyled">
                                    <li [appCanUserCompany]="['SALES_COMMISSIONS_VIEW', 'SALES_COMMISSIONS_MANAGER']">
                                        <a appCompanyRouterLink="/payroll-clock-users" appRouterLinkActive="active" aria-expanded="false">
                                            Clock Users
                                        </a>
                                    </li>
                                    <li [appCanUserCompany]="['SALES_COMMISSIONS_VIEW', 'SALES_COMMISSIONS_MANAGER']">
                                        <a appCompanyRouterLink="/payroll-clock-locations" appRouterLinkActive="active" aria-expanded="false">
                                            Clock Locations
                                        </a>
                                    </li>
                                    <li [appCanUserCompany]="['SALES_COMMISSIONS_VIEW', 'SALES_COMMISSIONS_MANAGER']">
                                        <a appCompanyRouterLink="/time-clock" appRouterLinkActive="active" aria-expanded="false">
                                            Time Clock
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>

            </li>

            <!-- Settings -->
            <li class="menu" [appCanUserCompany]="['GENERAL_SETTINGS', 'EMAIL_TEMPLATES', 'SMS_TEMPLATE', 'PAYROLL_SETTINGS', 'AUTOMATION_SETTINGS', 'WORK_DEPARTMENTS_VIEW', 'WORK_DEPARTMENT_MANAGER', 'AUTOMATION_TICKETS_SUBJECT', 'TICKET_ASSIGNMENT_RULES', 'PAYMENT_GATEWAY_SETTINGS', 'PAYMENT_METHODS']">
                <a href="#submenuSettings" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-gear"></i>
                        <span>Settings</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuSettings" data-parent="#accordionExample">

                    <li appCanUserCompany="GENERAL_SETTINGS">
                        <a appCompanyRouterLink="/settings" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fas fa-cogs"></i> -->
                                <span>Generals</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="DOCUMENTS_TYPES">
                        <a appCompanyRouterLink="/documents-types" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="far fa-file-alt"></i> -->
                                <span>Document Types</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="EMAIL_TEMPLATES" >
                        <a appCompanyRouterLink="/email-templates" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-envelopes-bulk"></i> -->
                                <span>Email Templates</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="SMS_TEMPLATE" >
                        <a appCompanyRouterLink="/sms-templates" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fas fa-sms"></i> -->
                                <span>SMS Templates</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="PAYROLL_SETTINGS">
                        <a appCompanyRouterLink="/payroll-settings" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-users-gear"></i> -->
                                <span>Payroll</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="AUTOMATION_SETTINGS">
                        <a appCompanyRouterLink="/automations" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-robot"></i> -->
                                <span>Automations</span>
                            </div>
                        </a>
                    </li>

                    <li [appCanUserCompany]="['WORK_DEPARTMENTS_VIEW', 'WORK_DEPARTMENT_MANAGER']">
                        <a appCompanyRouterLink="/work-departments" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-people-line"></i> -->
                                <span>Work Departments</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="AUTOMATION_TICKETS_SUBJECT">
                        <a appCompanyRouterLink="/tickets-type" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-ticket"></i> -->
                                <span>Tickets Subject</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="TICKET_ASSIGNMENT_RULES">
                        <a appCompanyRouterLink="/ticket-assignment-rules" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fas fa-traffic-light"></i> -->
                                <span>Ticket Rules</span>
                            </div>
                        </a>
                    </li>

                    <li appCanUserCompany="PAYMENT_GATEWAY_SETTINGS">
                        <a appCompanyRouterLink="/payment-gateway-settings" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-cash-register"></i> -->
                                <span>Payment Gateway</span>
                            </div>
                        </a>
                    </li>


                    <li appCanUserCompany="PAYMENT_METHODS">
                        <a appCompanyRouterLink="/payment-methods" appRouterLinkActive="active" aria-expanded="false">
                            <div>
                                <!-- <i class="fa-solid fa-wallet"></i> -->
                                <span>Payment Methods</span>
                            </div>
                        </a>
                    </li>

                </ul>
            </li>

            <!-- Help -->
            <li class="menu">
                <a href="#submenuHelp" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fa-solid fa-suitcase-medical"></i>
                        <span>Help</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-right">
                            <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                    </div>
                </a>
                <ul class="collapse submenu list-unstyled" id="submenuHelp" data-parent="#accordionExample">

                    <li (click)="chatWhatsapp()">
                        <a aria-expanded="false">
                            <div>
                                <span>Live Chat</span>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a href="https://moverxpro360.com/guide" target="_blank" aria-expanded="false">
                            <div>
                                <span>Tutorials</span>
                            </div>
                        </a>
                    </li>

                </ul>
            </li>



            <!--
                <li class="menu" appCanUserCompany="COMMISSION_SETTING">
                    <a appCompanyRouterLink="/commission-settings" appRouterLinkActive="active" aria-expanded="false"
                        class="dropdown-toggle">
                        <div>
                            <i class="fa-solid fa-sliders"></i>
                            <span>Commission Settings</span>
                        </div>
                    </a>
                </li>
            -->


            <!-- <div class="section-heading">
                <span>LEGAL</span>
            </div>

            <li class="menu">
                <a href="https://moverxpro360.com/term-and-conditions" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-book-open">
                            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" /></svg>
                        <span>Terms</span>
                    </div>
                </a>
            </li>

            <li class="menu">
                <a href="https://moverxpro360.com/privacy-policy" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-file-text">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                            <polyline points="14 2 14 8 20 8" />
                            <line x1="16" y1="13" x2="8" y2="13" />
                            <line x1="16" y1="17" x2="8" y2="17" />
                            <polyline points="10 9 9 9 8 9" /></svg>
                        <span>Privacy Policy</span>
                    </div>
                </a>
            </li> -->

        </ul>
    </ng-container>

    <ng-container *ngIf="menuType == 'ACCOUNT'">
        <ul class="list-unstyled menu-categories" id="accordionExample">
            <li class="menu">
                <a routerLink="/dashboard" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fas fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </div>
                </a>
            </li>

            <div class="section-heading">
                <span>HELP</span>
            </div>

            <li class="menu">
                <a (click)="chatWhatsapp()" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fab fa-whatsapp" width="24" height="24"></i>
                        <span>Live Chat</span>
                    </div>
                </a>
            </li>

            <li class="menu">
                <a href="https://moverxpro360.com/guide" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <i class="fab fa-youtube" width="24" height="24"></i>
                        <span>Tutorials</span>
                    </div>
                </a>
            </li>

            <div class="section-heading">
                <span>LEGAL</span>
            </div>

            <li class="menu">
                <a href="https://moverxpro360.com/term-and-conditions" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-book-open">
                            <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                            <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" /></svg>
                        <span>Terms</span>
                    </div>
                </a>
            </li>

            <li class="menu">
                <a href="https://moverxpro360.com/privacy-policy" target="_blank" aria-expanded="false" class="dropdown-toggle">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-file-text">
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                            <polyline points="14 2 14 8 20 8" />
                            <line x1="16" y1="13" x2="8" y2="13" />
                            <line x1="16" y1="17" x2="8" y2="17" />
                            <polyline points="10 9 9 9 8 9" /></svg>
                        <span>Privacy Policy</span>
                    </div>
                </a>
            </li>

        </ul>
    </ng-container>


</nav>
