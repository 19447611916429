import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Paginator } from '../entities/helpers/paginator';
import { EstimateEmail } from '../entities/workspace/estimate-email';
import { Lead } from '../entities/workspace/lead';
import { LeadCompanyView } from '../entities/workspace/lead-company-view';
import { LeadView } from '../entities/workspace/lead-view';


@Injectable({
    providedIn: 'root'
})
export class LeadsService {

    constructor(private http: HttpClient) {

    }

    getAllWithFullView(data, paginatorData: Paginator): Promise<Paginator> {
        data.use_paginator = 1;
        data.page = paginatorData.paginator.page;
        data.per_page = paginatorData.paginator.per_page;
        data.start = paginatorData.paginator.start;
        data.end = paginatorData.paginator.end;
        data.order_by = paginatorData.paginator.order_by;
        data.order_type = paginatorData.paginator.order_type;
        return this.http.get<Paginator>(environment.api.company + '/leads', { params: data }).toPromise();
    }

    getAllAutoWithFullView(data): Promise<Array<LeadView>> {
        return this.http.get<Array<LeadView>>(environment.api.company + '/auto-leads', { params: data }).toPromise();
    }

    getAll(): Promise<Array<Lead>> {
        return this.http.get<Array<Lead>>(environment.api.company + '/leads').toPromise();
    }

    getById(id: string) {
        return this.http.get<LeadCompanyView>(environment.api.company + '/leads/' + id).toPromise();
    }

    save(dataForm: Lead): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Lead) {
        return this.http.post(environment.api.company + '/leads', dataForm).toPromise();
    }

    edit(dataForm: Lead) {
        return this.http.put(environment.api.company + '/lead-companies/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/leads/' + id).toPromise();
    }

    deadLead(id: string, reasonBadLead: string) {
        return this.http.post(environment.api.company + '/lead-companies/' + id + '/dead-lead', { reason_bad_lead: reasonBadLead }).toPromise();
    }

    changeCategory(id: string, category: string) {
        return this.http.put(environment.api.company + '/lead-companies/' + id + '/category', { category }).toPromise();
    }

    loadLeadsByExcel(fileName: string, companiesReceiver: string[]) {
        const data = {
            fileName,
            companiesReceiver
        }
        return this.http.post(environment.api.workspace + '/load-leads-from-excel', data).toPromise();
    }

    getMyLeads(data, employee_id) {
        return this.http.get<Paginator>(environment.api.company + '/my-leads-report/' + employee_id, { params: data }).toPromise();
    }

    addNote(dataForm) {
        return this.http.post(environment.api.company + '/leads/' + dataForm.company_lead_id + '/notes', dataForm).toPromise();
    }

    reAssignLead(from_employee: string, to_employee: string, company_id: string) {
        const data = {from_employee, to_employee, company_id};
        return this.http.post(environment.api.company + '/leads-re-assign', data).toPromise();
    }


    sendEmail(dataForm: EstimateEmail) {
        return this.http.post(environment.api.company + '/send-email-leads', dataForm).toPromise();
    }
}
