<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                My Sales Performance
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        My Sales Performance
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>                  
        <div class="row col-lg-12 col-md-12 block-search">
            <div class="col-lg-3 col-xl-4">

            </div>
            <div class="col-lg-4 col-xl-3">
                <label>Advertiser</label>
                <select name="month" [(ngModel)]="typeService" class="form-control">
                    <option value="AUTO_TRANSPORT"> Auto Transport</option>
                    <option value="MOVING"> Moving </option>
                </select>
            </div>                         
            <div class="col-4">
                <button class="btn btn-warning btn-search" (click)="search()">
                    Search
                </button>
            </div>
            <div class="col-1">

            </div>  
        </div>  
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered mb-4">
                        <thead>
                            <tr>
                                <th>Activity</th>
                                <th>Performance</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>                            
                            <tr>
                                <td>Total Leads</td>
                                <td>{{totalLeads}}</td>
                                <td><button class="btn btn-info btn-search" (click)="getLeads()">Details</button></td>
                            </tr>
                            <tr>
                                <td>Total Estimates</td>
                                <td>{{totalQuotes}}</td>
                                <td><button class="btn btn-info btn-search" (click)="filterSearch('TOTAL_QUOTES')">Details</button></td>
                            </tr>
                            <tr>
                                <td>Emails Sent</td>
                                <td>{{totalEmailsSent}}</td>
                                <td><button class="btn btn-info btn-search" (click)="filterSearch('EMAILS_SENT')">Details</button></td>
                            </tr>
                            <tr>
                                <td>Estimates Sent</td>
                                <td>{{totalQuotesSent}}</td>
                                <td><button class="btn btn-info btn-search" (click)="filterSearch('QUOTES_SENT')">Details</button></td>
                            </tr>
                            <tr>
                                <td>Estimates Booked</td>
                                <td>{{totalQuotesBooked}}</td>
                                <td><button class="btn btn-info btn-search" (click)="filterSearch('QUOTES_BOOKED')">Details</button></td>
                            </tr>
                            <tr>
                                <td>Total Booking Value</td>
                                <td>{{totalAmountQuotesBooked | currency}}</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Customer Payments</td>
                                <td>{{totalCustomerPayments | currency}}</td>
                                <td><button class="btn btn-info btn-search" (click)="filterSearch('CUSTOMER_PAYMENTS')">Details</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" #modal tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    {{titleModal}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">

                <div class="col-md-12 mt-4 carrier--type-notes">
                    <div class="list-title">
                        <table class="table table-bordered mb-4">
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Pickup Date</th>
                                    <th>Created</th>
                                    <th>From</th>
                                    <th>To</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of salesmanPerformsCustom">
                                    <td>{{row.document.code}}</td>

                                    <td>{{row.pickup.range_start | date}} to {{row.pickup.range_start | date}}</td>
                                    <td>{{row.created | date}}</td>
                                    <td>{{row.pickup.address.state}} {{row.pickup.address.zip_code}}</td>
                                    <td>{{row.delivery.address.state}} {{row.delivery.address.zip_code}}</td>
                                    <td>
                                        <!-- <a appCompanyRouterLink="/moving/{{row.id}}/estimate" aria-expanded="false" class="dropdown-toggle cursor-pointer"> -->
                                        <i class="far fa-eye"
                                            style="color:rgb(10, 10, 73);font-size: 20px;    width: 30px; cursor: pointer;"
                                            (click)="openNewTab(row.id)"></i>
                                        <!-- </a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal -->

<!-- Modal leads -->
<div class="modal fade" #modalLeads tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Leads
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">

                <div class="col-md-12 mt-4 carrier--type-notes">
                    <div class="list-title">
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Customer</th>
                                    <th>Move Date</th>
                                    <th>From</th>
                                    <th>Destination</th>
                                    <th>Service</th>
                                    <th>Advertiser</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Assigned</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let lead of leads; index as i;">
                                    <td>{{i + 1}}</td>
                                    <td>
                                        <div>
                                            {{lead.lead.customer.name}} {{lead.lead.customer.last_name}}
                                        </div>
                                        <div>
                                            {{lead.lead.customer.email}}
                                        </div>
                                        <div>
                                            {{lead.lead.customer.phone | phone:'US'}}
                                        </div>
                                    </td>
                                    <td>{{lead.lead.move_date | date}}</td>
                                    <td>{{lead.lead.from_address.state}} {{lead.lead.from_address.zip_code}}</td>
                                    <td>{{lead.lead.to_address.state}}  {{lead.lead.to_address.zip_code}}</td>
                                    <td>{{lead.lead.service | filterReplace}}</td>
                                    <td>{{lead.lead.advertiser?.name | filterReplace}}</td>
                                    <td class="p-0">
                                        <div class="row-company-lead">
                                            <span class="p-2" *ngIf="lead.status == 'BOOKED'">BOOKED</span>
                                            <span class="p-2" *ngIf="lead.status == 'DEAD_LEAD'">DEAD LEAD</span>
                                            <span class="p-2" *ngIf="lead.status == 'DELIVERED'">DELIVERED</span>
                                            <span class="p-2" *ngIf="lead.status == 'IN_ESTIMATION'">IN ESTIMATION</span>
                                            <span class="p-2" *ngIf="lead.status == 'IN_STORAGE'">IN STORAGE</span>
                                            <span class="p-2" *ngIf="lead.status == 'IN_TRANSIT'">IN TRANSIT</span>
                                            <span class="p-2" *ngIf="lead.status == 'NEW'">NEW</span>
                                            <span class="p-2" *ngIf="lead.status == 'NOT_CONTACTED'">NOT CONTACTED</span>
                                            <span class="p-2" *ngIf="lead.status == 'PICKED_UP'">PICKED UP</span>
                                        </div>
                                    </td>
                                    <td>{{lead.created | date}}</td>
                                    <td>{{lead.assignment_date | date}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal leads-->