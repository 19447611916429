import { DocumentType } from 'src/app/entities/workspace/document-type';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DocumentsTypesService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<DocumentType>> {
        return this.http.get<Array<DocumentType>>(environment.api.company + '/documents-types').toPromise();
    }

    getById(id: string) {
        return this.http.get<DocumentType>(environment.api.company + '/documents-types/' + id).toPromise();
    }

    save(dataForm: DocumentType): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: DocumentType) {
        return this.http.post(environment.api.company + '/documents-types', dataForm).toPromise();
    }

    edit(dataForm: DocumentType) {
        return this.http.put(environment.api.company + '/documents-types/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/documents-types/' + id).toPromise();
    }

    setDefault(dataDefault: { id: string, module: string }) {
        return this.http.put(environment.api.company + '/documents-types/set-default/', dataDefault).toPromise();
    }

    removeDefault(id: string) {
        const data = { id }
        return this.http.put(environment.api.company + '/documents-types/remove-default/', data).toPromise();
    }

    saveDocumentsTypes(id: string, documentTypesIds: string[]) {
        const data = {documentTypesIds}
        return this.http.post(environment.api.company + `estimates/${id}/documents-types/pickup-documents`, data).toPromise();
    }
}
