import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents-types-view',
  templateUrl: './documents-types-view.component.html',
  styleUrls: ['./documents-types-view.component.scss']
})
export class DocumentsTypesViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
