import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DispatchContacts } from 'src/app/entities/workspace/dispatch-contacts';
import { HelperService } from 'src/app/services/helper.service';
import { DispatchContactsService } from 'src/app/services/workspaces/dispatch-contacts.service';

declare var jQuery;

@Component({
  selector: 'app-dispatch-contacts-form',
  templateUrl: './dispatch-contacts-form.component.html',
  styleUrls: ['./dispatch-contacts-form.component.scss']
})
export class DispatchContactsFormComponent implements OnInit {

  dataForm: DispatchContacts;

  constructor(
      private currentRoute: ActivatedRoute,
      private dispatchContactsService: DispatchContactsService,
      private helperService: HelperService,
  ) {
      this.dataForm = new DispatchContacts();
  }


  ngOnInit(): void {

  }


  ngAfterViewInit() {
      // verficamos si se esta editando
      this.currentRoute.params.subscribe(params => {
          if (typeof params.id !== 'undefined') {
              this.load(params.id);
          }
      });
  }


  private load(id: string) {
      this.helperService.showLoadingMxpro360();
      this.dispatchContactsService
          .getById(id)
          .then((response) => {
              this.dataForm = response;
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

  save() {
      this.helperService.showLoadingMxpro360();
      this.dispatchContactsService
          .save(this.dataForm)
          .then(response => {
              this.helperService.goToWorkspaceRouterLink('/dispatch-contacts');
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

}
