import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhoneNumber } from 'src/app/entities/admin/phone-number';
import { Company } from 'src/app/entities/workspace/company';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneIvrsService } from 'src/app/services/workspaces/phone-ivrs.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';
declare const swal;
@Component({
  selector: 'app-phone-ring-groups-form',
  templateUrl: './phone-ring-groups-form.component.html',
  styleUrls: ['./phone-ring-groups-form.component.scss']
})
export class PhoneRingGroupsFormComponent implements OnInit {

  public dataForm: PhoneRingGroup;
  public nums: PhoneLine[];
  public companies: Array<Company>;
  public employees: EmployeeView[];
  public ringGroups: PhoneRingGroup[];
  public ivrs: PhoneIvr[];
  public targets: any[];
  public extensions: any[];
  public fullExtensions: any[];
  public phones: any[];
  public extensionsCopy: any[];
  public queues: any;


  JSON;

  constructor(
    private phoneLineService: PhoneLinesService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private helperService: HelperService,
    private employeesService: EmployeesService,
    private currentRoute: ActivatedRoute,
    private companiesService: CompaniesService,
    private phoneIvrsService: PhoneIvrsService,
    private phoneExtensionsService: PhoneExtensionsService,
    private phoneQueuesService: PhoneQueuesService,
  ) {
    this.dataForm = new PhoneRingGroup();
    this.nums = [];
    this.companies = [];
    this.JSON = JSON;
    this.fullExtensions= [];
    this.extensions= [];

    this.phones = [];
    this.extensionsCopy = [];


  }

  ngOnInit(): void {
    this.loadCompany();
    this.getAllEmployees();
    this.loadRingGroups();
    this.loadIvrs();
    this.loadExtensionsCopy();

    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.load(params.id);
      }
    })
  }


 // aqui pasando el mismo error del ivr para seleccionar el target
  private getAllNums(): void{
    this.helperService.showLoadingMxpro360();
    this.phoneLineService.getAllByCompany(this.dataForm.company_id).then((response) => {

      this.nums = response;
      this.changeTarget();
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }


  loadCompany() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
        .getAll()
        .then((response) => {
            this.companies = response;

        })
        .catch((error) => {
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }


  private getAllEmployees(): void{
    this.helperService.showLoadingMxpro360();
    this.employeesService.getAll().then((response) => {
      this.employees = response;
      this.changeTarget();
    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };


  onChangeAnnouncement(files: Array<string>) {


    let extencion = files[0].split('.');

    if (files.length > 0) {

    if ( extencion[1] === 'mp3'|| extencion[1] === 'mpeg'|| extencion[1] === 'ogg'|| extencion[1] === 'wav') {

     let mime = files[0].split('.');
     this.dataForm.announcement.display_name = files[0];
     this.dataForm.announcement.name = files[0];
     this.dataForm.announcement.mime_type = 'audio/' + mime[1];

    }else{
     swal(
       'Error',
       'error the audio extension must be               mp3 mpeg ogg or wav',
       'error'
     );
    }

 }

  }


  onChangePlayMusicOnHold(files: Array<string>) {


    let extencion = files[0].split('.');

    if (files.length > 0) {

    if ( extencion[1] === 'mp3'|| extencion[1] === 'mpeg'|| extencion[1] === 'ogg'|| extencion[1] === 'wav') {

     let mime = files[0].split('.');
     this.dataForm.play_music_on_hold.display_name = files[0];
     this.dataForm.play_music_on_hold.name = files[0];
     this.dataForm.play_music_on_hold.mime_type = 'audio/' + mime[1];

    }else{
     swal(
       'Error',
       'error the audio extension must be               mp3 mpeg ogg or wav',
       'error'
     );
    }
  }
}



  save(): void {
    this.helperService.showLoadingMxpro360();
    this.dataForm.phone_extensions_id = this.phones;
    this.phoneRingGroupsService.save(this.dataForm)
    .then( (response)=> {
      console.log('response => ', response);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
      this.helperService.goToWorkspaceRouterLink('/phone-ring-groups')
    });
  }


  /**
     * carga un nuevo phoneLine o carga un vehiculo padra ser editado
     * @param id
     */
  private load(id: any) {
    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService
        .getById(id)
        .then(async (response) => {
          this.dataForm = response;

          this.phones = this.dataForm.phone_extensions_id;

          await this.loadExtensions()
          this.loadFullExtensions();

          //this.getAllNums();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private loadRingGroups() {

    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService
        .getAll()
        .then((response) => {
          this.ringGroups = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  changeCompany(){
    this.loadFullExtensions();
    this.loadExtensions();
  }

  private loadIvrs() {

    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService
        .getAll()
        .then((response) => {
          this.ivrs = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  private loadFullExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllByCompany(this.dataForm.company_id)
        .then( (response) => {
          this.fullExtensions = response;
          this.changeTarget();

        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private loadExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllByCompany(this.dataForm.company_id)
        .then( (response) => {

          this.extensions = response;


          for (let i =  this.extensions.length - 1; i >= 0; i--) {
            if (this.phones.includes(this.extensions[i].id)) {
               this.extensions.splice(i, 1);
            }

          }

        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };



  public loadExtensionsCopy() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllExtensions()
        .then((response) => {
          this.extensionsCopy = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private queue(){
    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService.getAllCompany().then((response) => {

      console.log('queue',response);

      this.queues = response;

    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };






  changeTarget(){

    if (this.dataForm.destination.target == 'VOICEMAIL') {
      this.targets = this.fullExtensions.filter(item => item.destination.target != 'RING_GROUP' && item.destination.target != 'QUEUE' && item.destination.target != 'IVR');
    }

    if (this.dataForm.destination.target == 'EXTENSION') {


      this.targets = this.fullExtensions;
    }

    if(this.dataForm.destination.target == 'RING_GROUP'){
      this.targets = this.ringGroups;
    }

    if(this.dataForm.destination.target == 'EXTERNAL_FORWARD'){
      this.dataForm.destination.target_id = '';
    }

    if(this.dataForm.destination.target == 'IVR'){
      this.targets = this.ivrs;
    }
       if (this.dataForm.destination.target =='QUEUE') {

      this.targets =  this.queues;
    }

    console.log('target!!!!!! aqui fueque ',this.targets);

  }



  addPhone(phone) {
    for (let index = 0; index < this.extensions.length; index++) {
      if (this.extensions[index].id == phone) {
        this.extensions.splice(index, 1);
        this.phones.push(phone);
      }
    }

  }

  removePhone(phone) {
    for (let index = 0; index < this.phones.length; index++) {
      if (this.phones[index] == phone) {
        this.phones.splice(index, 1);
      }
    }
    for (let index = 0; index < this.extensionsCopy.length; index++) {
      if (this.extensionsCopy[index].id == phone) {
        this.extensions.push(this.extensionsCopy[index]);
      }
    }
  }


  validateExtension(phone: string): string {

    for (const ext of this.extensionsCopy) {
        if (ext.id == phone){
          return ext.extension;
        }
    }
    return phone;
  }

}
