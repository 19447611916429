<div class="widget widget-table-two widget-container">

    <div class="widget-heading">
        <h5 class="text-success">Sales Leader</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <div class="widget-three">
                <div class="widget-content">

                    <div class="order-summary" *ngIf="jobsSalesLeader.length > 0">

                        <div *ngFor="let jobsSaleLeader of jobsSalesLeader ; index as key" class="summary-list">
                            <div class="w-icon">
                                {{key + 1}}
                            </div>
                            <div class="w-summary-details">

                                <div class="w-summary-info">
                                    <h6>Kymberlee Bradley</h6>
                                    <p class="summary-count">$92,600</p>
                                </div>

                                <div class="w-summary-stats">
                                    <div class="progress">
                                        <div [ngStyle]="{'background': colorGradient[key] }"
                                            class="progress-bar bg-gradient-secondary" role="progressbar"
                                            style="width: 90%;" aria-valuenow="90" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                    </div>

                </div>
            </div>
            <!-- <table class="table">
                <thead>
                    <tr>
                        <th class="bg-success"><div class="th-content text-light">Name</div></th>
                        <th class="bg-success"><div class="th-content text-light">Total</div></th>
                        <th class="bg-success"><div class="th-content text-light">Company</div></th>
                    </tr>
                </thead>
                <tbody *ngIf="jobsSalesLeader.length > 0">
                    <tr *ngFor="let jobsSaleLeader of jobsSalesLeader">
                        <td>
                            <div class="td-content customer-name d-flex-center">
                                <div class="avatar-table avatar-success text-success">
                                    <span class="text-avatar-table">{{jobsSaleLeader.fromData.name | avatarTitle}}</span>
                                </div>
                                {{jobsSaleLeader.fromData.name}}
                            </div>
                        </td>
                        <td class="text-center"><div class="badge badge-success">{{jobsSaleLeader.sumTotal | currency}}</div></td>
                        <td><div class="td-content text-success">{{jobsSaleLeader.fromData.company}}</div></td>
                    </tr>  
                </tbody>
            </table> -->
        </div>
    </div>
</div>