<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 align-self-center mb-3">
            <h3>
                View Driver Invoice
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/driver-invoices">Driver Invoices</a></li>
                    <li class="breadcrumb-item active" aria-current="page">View</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-secondary" (click)="printInvoice()">
                    <i class="fa-solid fa-print mr-2"></i>
                    <span>Print</span>
                </a>
                <a class="btn btn-success" (click)="sendByEmail(true)">
                    <i class="fa-solid fa-paper-plane mr-2"></i>
                    <span>Send By Email</span>
                </a>
            </div>
        </div>  
    </div>

        <div class="widget-content widget-content-area mb-4">        
            <div class="row">

                <div class="form-group col-md-3">
                    <label class="control-label">Date</label>
                    <div class="output-box">{{driverInvoice.date | date}}</div>                
                </div>

                <div class="form-group col-md-3">
                    <label class="control-label">Company Name</label>
                    <div class="output-box">{{companyName}}</div>
                </div>

                <div class="form-group col-3 mb-4">
                    <label class="control-label">Dispatcher</label>
                    <div class="input-group">
                        <select class="form-control" [(ngModel)]="driverInvoice.dispatcher_id"
                            (ngModelChange)="saveDriverInoiceChange()" name="driver_invoice_dispatcher" id="driver_invoice_dispatcher" >
                            <option *ngFor="let dispatcher of dispatchers" value="{{dispatcher.id}}">
                                {{dispatcher.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <ng-container *ngIf="driverInvoice.allocation == 'CARRIER'">
                    <div class="form-group col-md-3">
                        <label class="control-label">Carrier Name</label>
                        <div class="output-box">{{carrier.name}}</div>
                    </div>
    
                    <div class="form-group col-md-3">
                        <label class="control-label">Carrier Driver</label>
                        <div class="output-box">{{driverInvoice['carrier_driver']['name']}}</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="driverInvoice.allocation == 'IN_HOUSE'">
                    <div class="form-group col-md-3">
                        <label class="control-label">Employee Name</label>
                        <ng-container *ngFor="let employee of employees">
                            <ng-container *ngIf="employee.id == driverInvoice.employee_driver_id">
                                <div class="output-box">{{employee.name}}</div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>

                <div class="form-group toggle-switch-passed-due col-md-3 mt-4">
                    <div class="toggle-switch">                
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="delivered" name="delivered" [(ngModel)]="delivered" (click)="driverInvoiceChangeDelivered()">
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="delivered" class="checkbox-label" [ngClass]="{'filter-disabled': !delivered}">
                            Is Delivered
                        </label>
                    </div>
                </div>

                <div class="form-group toggle-switch-passed-due col-md-3 mt-4">
                    <div class="toggle-switch">                
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="is_paid" name="is_paid" [(ngModel)]="driverInvoice.is_paid" (click)="driverInvoiceChangeIsPaid()">
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="is_paid" class="checkbox-label" [ngClass]="{'filter-disabled': !driverInvoice.is_paid}">
                            Is Paid
                        </label>
                    </div>
                </div>

                <div class="form-group toggle-switch-passed-due col-md-3 mt-4">
                    <div class="toggle-switch">                
                        <label class="switch s-icons s-outline s-outline-secondary">
                            <input type="checkbox" id="display_storage_location" name="display_storage_location" [(ngModel)]="driverInvoice.display_storage_location" disabled>
                            <span class="slider round">
                                <i class="fa-solid fa-check icon-left"></i>
                                <i class="fa-solid fa-xmark icon-right"></i>
                            </span>
                        </label>
                        <label for="display_storage_location" class="checkbox-label" [ngClass]="{'filter-disabled': !driverInvoice.display_storage_location}">
                            Display Storage Location
                        </label>
                    </div>
                </div>

            </div>
        </div>

        <!-- Table -->
        <div class="widget-content widget-content-area mb-4">                  
            <div class="table-responsive">
                <table class="table table-bordered table-hover mb-4">
                    <thead>
                        <tr class="text-left">
                            <th>Job No</th>
                            <th>Pads</th>
                            <th>From</th>
                            <th>To</th>
                            <th>CF</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Job Total</th>
                            <th>Eta</th>
                            <th>Comment</th>
                            <th>Is Delivered</th>
                            <th>Is Paid</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of driverInvoice.items; let i = index">
                            <tr>
                                <td>{{item.estimate.document.code}}</td>
                                <td>{{item.pads}}</td>
                                <td>{{item.estimate.pickup.address.state}} {{item.estimate.pickup.address.zip_code}}</td>
                                <td>{{item.estimate.delivery.address.state}} {{item.estimate.delivery.address.zip_code}}</td>
                                <td>{{item.cf}}</td>
                                <td>{{item.rate_cf}}</td>
                                <td>{{item.amount.toFixed(2)}}</td>
                                <td>{{item.moving_balance.toFixed(2)}}</td>
                                <td>
                                    {{item.eta_start | date}} <br>
                                    {{item.eta_end | date}}
                                </td>
                                <td>{{item.comments}}</td>
                                <td>

                                    <div class="form-group toggle-switch-passed-due col-md-3 mt-4">
                                        <div class="toggle-switch">                
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="delivered" name="delivered" [checked]="item.delivery_status == 'DELIVERED'" (click)="changeDelivered(item.delivery_status, i)">
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="delivered" class="checkbox-label" [ngClass]="{'filter-disabled': !delivered}">
                                            </label>
                                        </div>
                                    </div>

                                </td>
                                <td>
                                    
                                    <div class="form-group toggle-switch-passed-due col-md-3 mt-4">
                                        <div class="toggle-switch">                
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="item_is_paid" name="item_is_paid" [(ngModel)]="item.is_paid" (click)="changePaid()">
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="item_is_paid" class="checkbox-label" [ngClass]="{'filter-disabled': !item.is_paid}">
                                            </label>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button (click)="edit(item, i)" data-toggle="modal" data-target="#assign-new-account" class="btn btn-success rounded-circle">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button (click)="remove(i)" type="button" class="btn btn-danger rounded-circle">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                            <ng-container *ngIf="item.job_storages">
                                <ng-container *ngFor="let jobStorages of item.job_storages">
                                    <tr class="tr-job-storage" *ngIf="driverInvoice.display_storage_location" [ngClass]="{'tr-internal': jobStorages.type == 'INTERNAL', 'tr-external': jobStorages.type == 'EXTERNAL'}">

                                        <td colspan="10" style="text-align: left;" *ngIf="jobStorages.type == 'INTERNAL'">
                                            <div class="d-flex">                                                
                                                <div class="storage-info">

                                                    <div class="job-storage">

                                                        <div class="icon-warehouse">
                                                            <i class="fa-solid fa-warehouse"></i>
                                                        </div>

                                                        <strong>Storage Type: </strong>
                                                        <div class="storage-type">
                                                            <span class="badge badge-success">INTERNAL</span>
                                                        </div>                                                    

                                                        <strong class="pl-3 mr-4">Storage Address: </strong>
                                                        <ng-container *ngFor="let warehouse of rowWarehouse">
                                                            <ng-container *ngIf="warehouse.id == jobStorages.warehouse_id">
                                                            <span>{{warehouse.address.full_address}}</span>
                                                            </ng-container>
                                                        </ng-container>  
                                                        
                                                    </div>
                                                   
                                                    <ng-container *ngFor="let joStorageInternal of jobStorages.job_storage_internal">
                                                        <div class="job-storage-internals">
                                                            
                                                            <div class="icon-internal">
                                                                <i class="fa-solid fa-layer-group"></i>
                                                            </div>

                                                            <strong>Lot Number: </strong>
                                                            <span class="lot-number">{{joStorageInternal.lot}}</span>

                                                            <strong class="pl-3 mr-4">Location: </strong>
                                                            <span>{{joStorageInternal.location_in_storage}}</span>

                                                        </div>
                                                    </ng-container>
                                                </div>                                                
                                            </div>
                                        </td>

                                        <td colspan="10" style="text-align: left;" *ngIf="jobStorages.type == 'EXTERNAL'">
                                            <div class="d-flex">
                                                <div class="storage-info">

                                                    <div class="job-storage">

                                                        <div class="icon-warehouse">
                                                            <i class="fa-solid fa-warehouse"></i>
                                                        </div>

                                                        <ng-container *ngFor="let storageCompanies of rowStorageCompanies">
                                                            <ng-container *ngIf="storageCompanies.id == jobStorages.storage_company_id">

                                                                <strong>Storage Type: </strong>
                                                                <div class="storage-type">
                                                                    <span class="badge badge-info">EXTERNAL</span>
                                                                </div>                                                            

                                                                <strong class="pl-3 mr-4">Storage Address: </strong>                                                            
                                                                <span>{{storageCompanies.address.full_address}}</span>                                                                                                                                                                                 

                                                            </ng-container>
                                                        </ng-container>

                                                    </div>

                                                    <ng-container *ngFor="let joStorageExternal of jobStorages.job_storage_external">
                                                        <div class="job-storage-external">
                                                            
                                                            <div class="icon-external">
                                                                <i class="fa-solid fa-layer-group"></i>
                                                            </div>
                                                            
                                                            <strong>Crates: </strong>
                                                            <span class="unit-number">{{joStorageExternal.storage_unit_number}}</span>
                                                            
                                                            <strong class="pl-3 mr-4">Entrance Code: </strong>
                                                            <span>{{joStorageExternal.entrance_code}} </span>

                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                </ng-container>
                            </ng-container>
                            
                        </ng-container>
                        
                    
                    </tbody>
                </table>
            </div>
        </div>

        <div class="widget-content widget-content-area">            
            <div class="row">

                <ng-container *ngIf="driverInvoice.items.length > 0">
                    <div class="form-group col-3">
                        <label class="control-label">Pads Pickup</label>
                        <div class="output-box">{{driverInvoice.pads_pickup}}</div>
                    </div>

                    <div class="form-group col-3">
                        <label class="control-label">Total CF</label>
                        <div class="output-box">{{driverInvoice.total_cf}}</div>
                    </div>

                    <div class="form-group col-3">
                        <label class="block-item">Charges</label>
                        <div class="output-box">{{driverInvoice.total_cf_charges}}</div>
                    </div>

                    <div class="form-group col-3">
                        <label class="control-label">Total</label>
                        <div class="output-box">{{driverInvoice.total?.toFixed(2)}}</div>
                    </div>

                </ng-container>

                <div class="form-group col-3">
                    <label class="control-label">Due From Agent</label>
                    <div class="output-box">{{ (driverInvoice.total - driverInvoice.total_cf_charges).toFixed(2)}}</div>
                </div>
                
                <div class="form-group col-6">
                    <label class="control-label">Comments</label>
                    <div class="input-group mt-1">
                        <textarea name="driverInvoice_comments" id="driverInvoice_comments" class="form-control" rows="3"
                            [(ngModel)]="driverInvoice.comments" placeholder="Comments">
                        </textarea>
                    </div>
                </div>
                <div class="form-group col-3">
                    <label class="control-label">&nbsp;</label>
                    <div>
                        <button class="btn btn-success" (click)="saveComments()">Save comments</button>
                    </div>
                </div>

            </div>          
        </div>

    </div>


    <!-- Modal Add Payment -->
    <div class="modal fade" id="addEmail" #paymentModal tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modalAddBalance" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Send Email
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">
                            <div class="form-group mb-1">
                                <label for="exampleFormControlSelect1">Email:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></span>
                                    </div>
                                    <input type="email" [(ngModel)]="email" name="email" class="form-control" >                            
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Email</small>
                            </div>                       

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-dark btn-outline-dark" data-dismiss="modal">
                        Discard
                    </button>
                    <button (click)="sendByEmail(false)" class="btn btn-warning">
                        Send
                    </button>
                </div>
            </div>
        </div>
    </div>


<!-- Modal Assign New Account -->
<div class="modal fade" id="assign-new-account" tabindex="-1" role="dialog" aria-labelledby="assign-new-account"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header text-center pl-4">
                <h5 class="modal-title" id="assign-new-account">Assign New Job</h5>
            </div>

            <div class="modal-body">
                <div class="form-group mb-3">
                    <label class="control-label">Job Number</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="Job Number" name="searchJob" [(ngModel)]="searchJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label class="control-label">Pads</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-columns"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="Pads" name="padsJob" [(ngModel)]="padsJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label class="control-label">CF</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="cf" name="cfJob" [(ngModel)]="cfJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label class="control-label">Rate</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="rate" name="rateJob" [(ngModel)]="rateJob" class="form-control">
                    </div>
                </div>
                <!-- <div class="form-group mb-4">
                    <label for="" class="control-label">Eta Start - End</label>
                    <div class="input-group mb-1 date-picker">
                        <div class="icon-calendar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </div>
                        <app-date-range-picker placeholder="Date: mm-dd / mm-dd" (onChange)="changeDriverDate($event)"></app-date-range-picker>
                    </div>
                </div> -->
                <div class="form-group mb-4">
                    <label class="control-label">Delivery Status</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            </span>
                        </div>
                        <select name="deliveryStatus" [(ngModel)]="deliveryStatus" class="form-control">
                            <option value="PENDING">
                                PENDING
                            </option>
                            <option value="DELIVERED">
                                DELIVERED
                            </option>
                            <option value="REFUSED">
                                REFUSED
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="comments" class="control-label">Comments</label>
                    <textarea name="comments" id="comments" class="form-control" rows="4" [(ngModel)]="comments" placeholder="Type here..."></textarea>
                </div>            
            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <!-- <button type="submit" class="btn btn-save" (click)="verify(true)">
                    Add Job
                </button> -->
            </div>
        </div>
    </div>
</div>
