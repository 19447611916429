<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Payroll Breakdown
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/payroll-payments">Payroll Payments</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Payroll Breakdown</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center top-actions-btns">
                
            </div>
        </div>

    </div>
    <!-- header -->


    <!-- Payroll Info -->
    <div class="widget-content widget-content-area">

        <div class="widget-heading mb-4">
            <h3 class="title-content-area">
                Payroll Info
            </h3>
        </div>
       
        <div class="row">

            <div class="col-4 form-group mb-3">
                <label class="control-label">Employee</label>
                <div class="output-box">
                    {{employee.name}}
                </div>
            </div>

            <div class="col-4 payroll-date mb-3">
                <div class="row">
                    
                    <div class="col-6 form-group">
                        <label for="pay_roll">Payroll Start Date</label>
                        <div class="output-box">{{payroll.start_period | date}}</div>
                    </div>
                    
                    <div class="date-line">-</div>
    
                    <div class="col-6 form-group">
                        <label for="pay_roll">Payroll End Date</label>
                        <div class="output-box">{{payroll.end_period | date}}</div>
                    </div>
 
                </div>                
            </div>
            
            <div class="col-4 form-group total-pay mb-3">
                <label for="pay_roll">Total Pay</label>
                <div class="output-box">{{dataForm.total | currency}}</div>
            </div>  

        </div>

    </div>

    <!-- Payroll Totals -->
    <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">

        <div class="widget-heading mb-4">
            <h3 class="title-content-area">
                Payroll Totals
            </h3>
        </div>

        <div class="row">        

            <div class="col-8">
                <div class="row">                

                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Commissions</label>
                        <div class="output-box">{{dataForm.total_commissions | currency}}</div>
                    </div>
        
                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Hourly</label>
                        <div class="output-box">{{dataForm.total_hourly | currency}}</div>
                    </div>                
        
                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Bonuses</label>
                        <div class="output-box">{{dataForm.total_bonuses | currency}}</div>
                    </div>
        
                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Deductions</label>
                        <div class="output-box">- {{dataForm.total_deductions | currency}}</div>
                    </div>
        
                </div>
            </div>
            
            <div class="col-4">
                <div class="row">

                    <div class="col-12 form-group previous-total-draw total-draw mb-3">
                        <label for="pay_roll">Previous Total Draw</label>
                        <div class="output-box">{{dataForm.previous_total_draw | currency}}</div>
                    </div>
        
                    <div class="col-12 form-group current-total-draw mb-3">
                        <label for="pay_roll">Current Total Draw</label>
                        <div class="output-box">{{dataForm.total_draw | currency}}</div>
                    </div>   
        
                </div>
            </div>
          
        </div>

    </div>
    
    <!-- Commission -->
    <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">

        <div class="widget-heading mb-3">
            <h3 class="title-content-area">
                Commission
            </h3>
        </div>

        <div class="col-12">
            <div class="row">
                <ng-container *ngFor="let commission of commissions; index as key">
                    <div class="table-responsive" *ngIf="commission.documents?.length > 0">
    
                        <div class="d-flex justify-content-between align-items-center table-heading">
                            <h3 class="title-content-area">
                                Rule: {{commission.name | filterReplace}}
                            </h3>
                            
                        </div>
    
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Job Number</th>
                                    <th>Total Job</th>
                                    <th>Payments</th>
                                    <th>Total Amount W/Filter</th>
                                    <th>Comm</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  *ngFor="let document of commission.documents; index as key">
                                    <td>{{key + 1}}</td>
                                    <td>{{document.job_number}}</td>
                                    <td>{{document.total_job | currency}}</td>
                                    <td>{{document.total_payments | currency}}</td>
                                    <td>{{document.total_filter_amount | currency}}</td>
                                    <td>{{document.total_commission | currency}}</td>
                                    <td>
                                        <span class="badge badge-success">{{document.status | filterReplace}}</span>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div> 
    </div>

    <!-- Hourly -->
    <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">
        
        <div class="d-flex justify-content-between widget-heading mb-3">
            <h3 class="title-content-area">
                Hourly
            </h3>
            
        </div>

        <div class="table-responsive">
            <table class="table table-bordered table-hover mb-4">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Clock In</th>
                        <th>Clock Out</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let time_clock of timeClock; index as key">
                        <td>{{key+1}}</td>
                        <td>{{ time_clock.date | date }}</td>
                        <td class="clock-in">
                            <i class="fa-solid fa-clock mr-2"></i>
                            {{ time_clock.clock_in | timeFromDate }}
                        </td>
                        <td class="clock-out">
                            <i class="fa-solid fa-clock mr-2"></i>
                            {{ time_clock.clock_out | timeFromDate }}
                        </td>
                    </tr>
                    
                </tbody>
            </table>

            <div class="col-12 mt-5">
                <div class="row">
    
                    <div class="col-4 form-group mb-3">
                        <label for="employee">Total Hours</label>
                        <div class="output-box">{{this.dataForm.total_hours}}</div>
                    </div>
    
                    <div class="col-4 form-group mb-3">
                        <label for="pay_roll">Hourly Pay Rate</label>
                        <div class="output-box">{{this.dataForm.hourly_pay_rate}}</div>
                    </div>
                    
                    <div class="col-4 form-group mb-3">
                        <label for="pay_roll">Total Hourly Pay</label>
                        <div class="output-box">{{this.dataForm.total_hourly | currency}}</div>
                    </div>
    
                </div>           
            </div> 

        </div> 
             
    </div>

    <!-- Bonuses -->
    <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">
        
        <div class="widget-heading mb-3"> 
            <h3 class="title-content-area">
                Bonuses
            </h3>
        </div>

        <div class="table-responsive">

            <div class="table-heading">
                <h3 class="title-content-area">
                    Remarks
                </h3>
            </div>

            <table class="table table-bordered table-hover mb-4">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Remarks</th>
                        <th>Bonus</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let payrollBalance of payrollBalances; index as key">
                        <tr *ngIf="payrollBalance.type == 'BONUS'">
                            <td>{{key}}</td>
                            <td>{{payrollBalance.remarks}}</td>
                            <td>{{payrollBalance.amount | currency}}</td>
                            <td>{{payrollBalance.created | date}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div> 
             
    </div>

    <!-- Deductions -->
    <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">
        
        <div class="widget-heading mb-3">
            <h3 class="title-content-area">
                Deductions
            </h3>
        </div>

        <div class="table-responsive">

            <div class="table-heading">
                <h3 class="title-content-area">
                    Cancellations
                </h3>
            </div>

            <table class="table table-bordered table-hover mb-4">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Job Number</th>
                        <th>Total Job</th>
                        <th>Payments</th>
                        <th>Total Amount W/Filter</th>
                        <th>Comm</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let cancelation of cancelations; index as key">
                        <tr>
                            <td>{{key+1}}</td>
                            <td>{{cancelation.job_number}}</td>
                            <td class="text-center">{{cancelation.total_job | currency}}</td>
                            <td class="text-center">{{cancelation.total_payments | currency}}</td>
                            <td class="text-center">{{cancelation.total_filter_amount | currency}}</td>
                            <td class="text-center">
                                <span *ngIf="cancelation.status !== 'PENDING_PAYMENT'">
                                    {{cancelation.total_commission | currency}}
                                </span>
                                <span *ngIf="cancelation.status === 'PENDING_PAYMENT'" class="badge badge-warning">
                                    N/A
                                </span>
                            </td>
                            <td class="text-center">
                                <span class="badge" [ngClass]="{'badge-warning': cancelation.status === 'PENDING_PAYMENT', 'badge-success': cancelation.status === 'PAID', 'badge-danger': cancelation.status === 'CANCELLED', 'badge-info': cancelation.status === 'PENDING_DEDUCTION', 'badge-secondary': cancelation.status === 'DEDUCTED'}">
                                    {{cancelation.status | filterReplace}}
                                </span>
                            </td>
                            
                        </tr>
                    </ng-container>
                    <!-- <tr>
                        <td class="cancellation-remark" colspan="8">
                            <strong>Remark:</strong> Lorem ipsum dolor sit amet consectetur adipiscing elit lacus orci, molestie at feugiat facilisis tristique praesent luctus vehicula gravida, ligula facilisi sodales habitasse vivamus dui taciti volutpat. Vehicula iaculis fames integer natoque platea nascetur montes pharetra eleifend quam
                        </td>
                    </tr> -->
                </tbody>
            </table>

        </div> 

        <div class="table-responsive">

            <div class="table-heading">
                <h3 class="title-content-area">
                    Deductions
                </h3>
            </div>

            <table class="table table-bordered table-hover mb-4">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Remarks</th>
                        <th>Deduction</th>
                        <th>Created</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let payrollBalance of payrollBalances; index as key">

                        <tr *ngIf="payrollBalance.type == 'DEDUCTION'" [ngClass]="{'no-commission': !payrollBalance.sales_commission_id}">
                            <td>{{key+1}}</td>
                            <td>{{payrollBalance.remarks}}</td>
                            <td>{{payrollBalance.amount | currency}}</td>
                            <td>{{payrollBalance.created | date}}</td>
                           
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div> 
             
    </div>

</div>