import { File } from '../global/file';

export class Claim {
    id: string;
    assigned_to_employee: string;
    created_by_employee_id: string;
    description: string;
    files: Array<File>;
    status: "OPEN" | "PENDING" | "RESOLVED" | "CLOSED" | "WAITING_COSTUMER";
    subject: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.assigned_to_employee = null;
        this.created_by_employee_id = null;
        this.description = null;
        this.files = [];
        this.status = null;
        this.subject = null;
        this.created = null;
        this.updated = null;
    }
}
