import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
    selector: 'app-jobs-picked-tomorrow',
    templateUrl: './jobs-picked-tomorrow.component.html',
    styleUrls: ['./jobs-picked-tomorrow.component.scss']
})
export class JobsPickedTomorrowComponent implements OnInit {
    @Input()
    companyId: boolean = false;
    jobsPickedUpTomorrow: any;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.jobsPickedUpTomorrow = [];

    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.loadPickedUpTomorrowCompany();
        } else {
            this.loadPickedUpTomorrowWorkspace();
        }
    }


    loadPickedUpTomorrowWorkspace() {

        this.dashboardWorkspaceService
            .getPickedUpTomorrow()
            .then((response) => {
                this.jobsPickedUpTomorrow = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }
    loadPickedUpTomorrowCompany() {

        this.dashboardComopanyService
            .getPickedUpTomorrow()
            .then((response) => {
                this.jobsPickedUpTomorrow = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

}
