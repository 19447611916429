import { DriverInvoiceItem } from './driver-invoice-item';
import { Estimate } from './estimate';
import { Job } from './job';
import { JobStorage } from './job-storage';

export class DriverInvoiceItemView extends DriverInvoiceItem{
    public estimate: Estimate;
    public job: Job;
    public job_storages: JobStorage;

    constructor() {
        super();
        this.estimate = new Estimate();
        this.job = new Job();
        this.job_storages = new JobStorage();
    }
}
