import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';
declare var jQuery;
@Component({
  selector: 'app-last5-payments',
  templateUrl: './last5-payments.component.html',
  styleUrls: ['./last5-payments.component.scss']
})
export class Last5PaymentsComponent implements OnInit {
    jobsLastPayments: any;

    @Input()
    companyId: boolean = false;

  constructor(
    private dashboardWorkspaceService: DashboardWorkspaceService,
    private dashboardComopanyService: DashboardComopanyService,
  ) { 
    this.jobsLastPayments = [];

  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadLastPaymentCompany();
    } else {
        this.loadLastPaymentWorkspace();
    }
  }

  
  loadLastPaymentWorkspace(){
        
    this.dashboardWorkspaceService
        .getLast5Payment()
        .then((response) => {
            this.jobsLastPayments = response;
         })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
  loadLastPaymentCompany(){
        
    this.dashboardComopanyService
        .getLast5Payment()
        .then((response) => {
            this.jobsLastPayments = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

}
