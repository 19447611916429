import { File } from '../global/file';

export class CarrierDriver {

    id: string;
    name: string;
    phone: string;
    email: string;
    license: File;
    carrier_id: string;
    account_id: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.license = new File();
        this.carrier_id = null;
        this.account_id = null;
        this.created = null;
        this.updated = null;
    }
}
