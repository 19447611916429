<div class="layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                New SMS Campaign
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/sms-campaigns">
                            SMS Campaigns
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        New
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right align-self-center mb-5">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <button class="btn btn-main-action" (click)="saveCampaign()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12 layout-spacing">
            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area ">

                    <form class="form" #entityForm="ngForm" ngNativeValidate autocomplete="off">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <h3>
                                    Campaign
                                </h3>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group mb-4">
                                    <label class="control-label">Name Campaign</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-send">
                                                    <line x1="22" y1="2" x2="11" y2="13"></line>
                                                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="text" name="wd_name" class="form-control"
                                            [(ngModel)]="smsCampaing.name" required>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Enter Name Campaign.</small>
                                </div>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">                            
                                <div class="form-group mb-1">
                                    <label class="control-label">Subject</label>
                                    <div class="input-group mb-1">
                                        <textarea class="form-control" name="additional_agreement" id="additional_agreement"
                                            [(ngModel)]="smsCampaing.message" placeholder="Write an message"
                                            required cols="190" rows="6">
                                        </textarea>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Enter Message Campaign.</small>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12 layout-spacing">
            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area ">

                    <form class="form" #entityForm="ngForm" ngNativeValidate autocomplete="off">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <h3>
                                    Filter
                                </h3>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                                <div class="form-group mb-4">
                                    <label class="control-label">From state</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-map-pin">
                                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                    <circle cx="12" cy="10" r="3"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="from_state" [(ngModel)]="campaignFilter.from_state" id="to_state"
                                            class="form-control">
                                            <option value="AK">Alaska</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="IA">Iowa</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MD">Maryland</option>
                                            <option value="ME">Maine</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MT">Montana</option>
                                            <option value="NC">North carolina</option>
                                            <option value="ND">North dakota</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NH">New hampshire</option>
                                            <option value="NJ">New jersey</option>
                                            <option value="NM">New mexico</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NY">New york</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode island</option>
                                            <option value="SC">South carolina</option>
                                            <option value="SD">South dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VA">Virginia</option>
                                            <option value="VT">Vermont</option>
                                            <option value="WA">Washington</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WV">West virginia</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select From State.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">To state</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-map-pin">
                                                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                                    <circle cx="12" cy="10" r="3"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="to_state" [(ngModel)]="campaignFilter.to_state" id="to_state"
                                            class="form-control">
                                            <option value="AK">Alaska</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="IA">Iowa</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MD">Maryland</option>
                                            <option value="ME">Maine</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MT">Montana</option>
                                            <option value="NC">North carolina</option>
                                            <option value="ND">North dakota</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NH">New hampshire</option>
                                            <option value="NJ">New jersey</option>
                                            <option value="NM">New mexico</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NY">New york</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode island</option>
                                            <option value="SC">South carolina</option>
                                            <option value="SD">South dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VA">Virginia</option>
                                            <option value="VT">Vermont</option>
                                            <option value="WA">Washington</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WV">West virginia</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select To State.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">Service</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck">
                                                    <rect x="1" y="3" width="15" height="13"></rect>
                                                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="service" [(ngModel)]="campaignFilter.service" id="service"
                                            class="form-control">
                                            <option value="LOCAL">Local</option>
                                            <option value="LONG">Long</option>
                                            <option value="AUTO_TRANSPORT">Auto Transport</option>
                                        </select>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Service.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">Status</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-bookmark">
                                                    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="status" [(ngModel)]="campaignFilter.status" id="status"
                                            class="form-control">
                                            <option value="NEW">New</option>
                                            <option value="IN_ESTIMATION">In Estimation</option>
                                            <option value="NOT_CONTACTED">Not Contacted</option>
                                            <option value="DEAD_LEAD">Dead Lead</option>
                                            <option value="BOOKED">Booked</option>
                                        </select>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Status.</small>
                                </div>

                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group mb-4"
                                    *ngIf="campaignFilter.status == 'NEW' || campaignFilter.status == 'IN_ESTIMATION'">
                                    <label class="control-label">Category</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-award">
                                                    <circle cx="12" cy="8" r="7"></circle>
                                                    <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="category" [(ngModel)]="campaignFilter.category" id="category"
                                            class="form-control">
                                            <option *ngIf="campaignFilter.status == 'IN_ESTIMATION'" value="OPEN">Open</option>
                                            <option value="HOT">Hot</option>
                                            <option value="WARM">Warm</option>
                                            <option value="COLD">Cold</option>
                                            <option *ngIf="campaignFilter.status == 'NEW'" value="NEW">New</option>
                                            <option *ngIf="campaignFilter.status == 'NEW'" value="SCHEDULED">Scheduled</option>
                                            <option *ngIf="campaignFilter.status == 'NEW'" value="BAD">Bad</option>
                                        </select>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Category.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">Range Start</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text color-svg" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-calendar">
                                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                                </svg>
                                            </span>                                            
                                        </div>
                                        <app-date-picker [enableTime]="true"
                                            [value]="campaignFilter.range_start" [placeholder]="'Not Assigned'"
                                            (onChange)="changeDateStart($event)">
                                        </app-date-picker>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Range Start.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">Range End</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text color-svg" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-calendar">
                                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                                </svg>
                                            </span>                                            
                                        </div>
                                        <app-date-picker [enableTime]="true"
                                            [placeholder]="'Not Assigned'" [value]="campaignFilter.range_end"
                                            (onChange)="changeDateEnd($event)">
                                        </app-date-picker>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Range End.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">Range Target</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text color-svg" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-crosshair">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <line x1="22" y1="12" x2="18" y2="12"></line>
                                                    <line x1="6" y1="12" x2="2" y2="12"></line>
                                                    <line x1="12" y1="6" x2="12" y2="2"></line>
                                                    <line x1="12" y1="22" x2="12" y2="18"></line>
                                                </svg>
                                            </span>                                            
                                        </div>
                                        <select id="inputState" name="pg_sold_by_name" class="form-control" [(ngModel)]="campaignFilter.range_target">
                                            <option value="MOVE_DATE">Move Date</option>
                                            <option value="OPEN">Open</option>
                                        </select>
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Range Target.</small>
                                </div>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>    

</div>