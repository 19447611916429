import { Address } from "../global/address";

export class TimeClock {

    public id: string;
    public employee_id: string;
    public date: string;
    public clock_in: number;
    public time_in: string;
    public time_out: string;
    public clock_out: number;
    public company_id: string;
    public duration: number;
    public payroll_payment_id: string;
    public location: Address;
    public created: number;
    public updated: number;


    constructor() {
        this.id = null;
        this.employee_id = null;
        this.date = null;
        this.clock_in = null;
        this.time_in = null;
        this.time_out = null;
        this.clock_out = null;
        this.company_id = null;
        this.duration = null;
        this.payroll_payment_id = null;
        this.location = new Address();
        this.created = null;
        this.updated = null;
    }
}
