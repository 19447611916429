import { DeductibleRate } from './deductible-rate';
import { LiabilityAmount } from './liability-amount';
import { LiabilityDeductibleRate } from './liability-deductible-rate';

export class LiabilityDeductibleRateView extends LiabilityDeductibleRate {
    public deductible_rates: Array<DeductibleRate>;
    public liability_amount: LiabilityAmount 
    constructor() {
        super();
        this.deductible_rates = [];
        this.liability_amount = new LiabilityAmount();
    }
}
