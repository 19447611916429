export class JobStorageInternal {
    public id: string;
    public vaults: number;
    public lot: string;
    public color: string;
    public sticker: string;
    public location_in_storage: string;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.vaults = null;
        this.lot = null;
        this.color = null;
        this.sticker = null;
        this.location_in_storage = null;
    }
}
