
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { Company } from '../../entities/workspace/company';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { Socket } from 'socket.io-client';
import { Employee } from 'src/app/entities/workspace/employee';
import { Workspace } from 'src/app/entities/admin/workspace';
import { environment } from 'src/environments/environment';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { TimeClock } from 'src/app/entities/companies/time-clock';
import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { ConstantsMessages } from 'src/app/constants-messages';
import { BroadcastChannelService } from 'src/app/services/broadcast-channel.service';
import { CompanyNotificationService } from 'src/app/app/company-notification.service';
import { CompanyNotificationView } from 'src/app/entities/workspace/company-notification-view';
import { JobsService } from 'src/app/services/workspaces/jobs.service';
import { SmsService } from 'src/app/services/companies/sms.service';

declare const jQuery;
declare const moment;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Input()
  panelType: 'WORKSPACE' | 'COMPANY' | 'ACCOUNT';


  @ViewChild('companyInformation') companyInformation: ElementRef;
  @ViewChild('payrollTimeClocks') payrollTimeClocks: ElementRef;
  selectedCompany: string;

  public searchJob: string;
  public companies: Array<Company>;
  public employee: Employee;
  public company: Company;
  public workspace: Workspace;
  private latitude: number;
  private longitude: number;
  private publicIP: string;
  public urlCarrier: string;
  public currentDate;
  public PayrollClock: string;
  public clockIn: boolean;
  public idClock: string;
  public timeClockEmployee: TimeClock;
  public clockEmployees: ClockEmployees[];
  JSON;
  public phoneNumber: string;
  public name: string;
  private static notificationTimer: any;
  public companyNotifications: CompanyNotificationView[];
  public newNotification: boolean;
  public message: any[];
  public counter: number;
  public sms: any[];
  public number: any[];
  public phoneSms;
  public fromSms;



  constructor(
    private channelService: BroadcastChannelService,
    private clientSocketsService: ClientSocketsService,
    private companyNotificationService: CompanyNotificationService,
    private estimatesService: EstimatesService,
    private helperService: HelperService,
    private payrollTimeClockService: PayrollTimeClockService,
    private router: Router,
    public authService: AuthService,
    public googleAuthService: GoogleAuthService,
    private smsService: SmsService,
    private jobsService : JobsService,
  ) {
    this.clockEmployees = [];
    this.clockIn = false;
    this.companies = [];
    this.company = null;
    this.companyNotifications = [];
    this.currentDate = new Date();
    this.employee = null;
    this.idClock = '';
    this.JSON = JSON;
    this.latitude = 0;
    this.longitude = 0;
    this.name = '';
    // this.notificationTimer = null;
    this.panelType = null;
    this.PayrollClock = "";
    this.phoneNumber = '';
    this.publicIP = '';
    this.searchJob = null;
    this.selectedCompany = 'No Company Selected';
    this.timeClockEmployee = new TimeClock();
    this.urlCarrier = "";
    this.workspace = null;
    this.newNotification = false;
    this.message = [];
    this.counter = 0;
    this.sms = [];
    this.number = [];
    this.phoneSms = '';
    this.fromSms = '';
  }

  ngOnInit(): void {

    this.urlCarrier = environment.app.carriers;
    this.getAllClockEmployees();

    this.channelService.getMenssage((mensaje) => {
      this.toggleModalIncomingCall(mensaje);

    });

    this.channelService.getMenssageSms((mensaje) => {
      this.message = [];

    setTimeout(() => {
      for (const sms of mensaje) {

        this.fromSms = sms.from;

        if (sms.from !=  sms.owner && sms.read === false) {
          this.message.push(sms);

        }

       }
    }, 500);

    console.log('this.message::°!!!',this.message);

    });

    this.channelService.getNumberPhoneSelected((phone) => {

      this.phoneSms = phone


    })

    this.channelService.getBodyNumber((body) => {

      this.phoneNumber = body.from;


    })

    this.channelService.getNameCustomer((name) => {

      this.name = name;

    });



  }

  ngAfterViewInit(): void {

    /* Real Time Clock */
    this.realTimeClock();

    // en caso de que el usuario cambie de compañia o se cierre la session
    this.authService
      .onChange
      .subscribe((isLogged: boolean) => {
        if (isLogged) {
          this.loadDataSession();
        }
      });




/*
    if (this.panelType == 'COMPANY') {
      // consulta notificaciones por primera vez
      this.getUserNotifications();
      // se asegura de eliminar el ingervalo
      if (this.notificationTimer !== null) {
        clearInterval(this.notificationTimer);
      }
      // consulta notificaciones cada minuto
      this.notificationTimer = setInterval(() => {
        this.getUserNotifications();
      }, 60 * 1000);
    } else if (this.notificationTimer !== null) {
      // elimina el intervalo si esta en otro panel diferente al de compania
      clearInterval(this.notificationTimer);
    }
*/
    /*   const INTERVAL_TIME = 60 * 1000; // 60 segundos

      if (this.panelType === 'COMPANY') {
        // Consulta notificaciones por primera vez
        this.getUserNotifications();

        // Se asegura de eliminar el intervalo existente
        if (HeaderComponent.notificationTimer !== null) {
          console.log('Clearing existing interval:', HeaderComponent.notificationTimer);
          clearInterval(HeaderComponent.notificationTimer);
          HeaderComponent.notificationTimer = null; // Asegúrate de restablecerlo a null
        }

        // Configura el intervalo para consultar notificaciones cada minuto
        HeaderComponent.notificationTimer = setInterval(() => {
          this.getUserNotifications();
        }, INTERVAL_TIME);

      } else if (HeaderComponent.notificationTimer !== null) {
        // Elimina el intervalo si está en otro panel diferente al de COMPANY
        clearInterval(HeaderComponent.notificationTimer);
        HeaderComponent.notificationTimer = null; // Asegúrate de restablecerlo a null
      } */


  }

  ngAfterContentInit(): void {

    this.clientSocketsService.onConnect.subscribe((socket: Socket) => {

      socket.on('notifications', (value) => {
        this.authService.session.socket_id = value.id;
        console.log('notification socketId', value.id);

        this.authService.save();
        this.requestAndShowPermission(value);
      });

      socket.on('general', (value) => {
        this.requestAndShowPermission(value);
      });

    });

    let THAT = this;

    jQuery('#sidebar').click(function () {
      if (jQuery('#companyInformation.show').length > 0) {
        THAT.closecompanyInformation();
      }
    });


    // cargamos la informacion del usuario logueado
    this.loadDataSession();

    /* Payroll Time Clocks */
    setInterval((): void => {
      this.realTimeClock();
    }, 1000);
  }



  readConversation(contact) {

    this.smsService.updateConversation(contact, this.phoneSms)
      .then((response) => {

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {

      });

  }



  getUserNotifications() {
    this.companyNotificationService.getMyNotifications(this.authService.workspaceSession.employee.id)
      .then(response => {
        this.companyNotifications = response;
        for (let notificacion of this.companyNotifications) {
          if (notificacion.status == 'NEW') {
            this.newNotification = true;
          }
        }
      })
  }

  /**
  * Permite abrir la pestaña del dialer, pero evitando que se abra mas de una vez,
  * es decir, se abre la primera vez, pero para la segunda si ya esta abierta,
  * activa la pestaña que ya esta abierta
  *
  * @param url
  */
  openCompanyDialer() {

    setTimeout(() => {


    this.channelService.emitSmsRead(true);


    }, 1000);



    //this.message = [];

    this.helperService.showLoadingMxpro360();

    // construirmos la url para usar posteriormente
    const urlComplete = window.origin + "/#" + this.helperService.buildCompanyRouterLink("/dialer");

    /**
     * AQUI VAMOS A EMPLEAR UNA ESTRATEGIA QUE NOS PERMITA VERIFICAR
     * SI LA PESTAÑA DEL DIALER QUE SE ESTA SOLICITANDO YA ESTA ABIERTA,
     * SI YA ESTA ABIERTA, EL NAVEGADOR DEBE PONER ESA PESTAÑA COMO ACTIVA,
     * DE LO CONTRARIO SE ABRE UNA PESTAÑA NUEVA
     */

    // obtenemos las ulrs abiertas guardadas del localstorage
    const storageDialerURLS = localStorage.getItem('DIALER_URLS');
    let dialerURLS = [];


    if (storageDialerURLS !== null) {
      dialerURLS = JSON.parse(storageDialerURLS) as Array<string>;
    }

    let isFound = false;

    // recorremos las urls almacenadas
    for (const url of dialerURLS) {
      if (url === urlComplete) {
        isFound = true;
        break;
      }
    }

    // creamos un broadcastchannel con las pestañas abiertas de app.moverxpro360.com
    const bccDIALER = new BroadcastChannel('DIALER');

    // creamos un nombre para la ventana
    const dialerWindowName = "dialer_" + this.authService.workspaceSession.company.id;

    // verificamos si ya ha sido abierta
    if (isFound) {

      // creamos un identificador aleatorio para que no se vaya a presentar errores
      const customID = Math.floor(Math.random() * 9999);

      // emitimos una solicitud a la pestaña que esta abierta para la url que se solicitó se active
      bccDIALER.postMessage({ action: 'REQUEST_ACTIVATE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });

      // escuchamos mensajes que se envien desde otras pestañas (tab) del mismo origen: app.moverxpro360.com
      bccDIALER.onmessage = (event) => {

        const data = event.data as { action: string, url: string, name: string, requestID: number, isActive: boolean, hasValue: boolean, };

        // verificamos si la accion es la respuesta a la solicitud arriba
        if (data.action == "RESPONSE_ACTIVATE_TAB" && data.url == urlComplete) {

          // si devuelve que es activo no se hace nada mas.. pero si no es activo, cerramos la pestaña, y abrimos una nueva
          if (data.isActive) {
            this.helperService.hideLoadingMxpro360();
          } else {

            // enviamos una solicitud a las otras pestañas para verificar si la pestaña que estaba abierta del dialer, esta abierta correctamente
            bccDIALER.postMessage({ action: 'REQUEST_TAB_VERIFICATION', url: urlComplete, name: dialerWindowName, requestID: customID });
          }
        }

        // verificamos si la accion es la respuesta a la solicitud arriba
        if (data.action == "RESPONSE_TAB_VERIFICATION" && data.url == urlComplete && data.requestID == customID) {

          // si tiene valor es porque fue abierto correctamente, entonces abrimos la pestaña con enlace
          if (data.hasValue) {

            // como ya se cerro la otra pestaña, abrimos una nueva pestaña
            setTimeout(() => {
              const popup = window.open(urlComplete, dialerWindowName);
              popup.focus();

              this.helperService.hideLoadingMxpro360();
            }, 1000);

          } else {

            // solicitamos que se cierre la otra pestaña
            // bccDIALER.postMessage({ action: 'REQUEST_CLOSE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });

            // como no se puede cerrar la otra pestaña en la mayoria de los casos, esto por temas de seguridad en los navegadores, entonces vamos a indicar que ya hay una pestaña abierta
            alert("There is currently a browser tab open for this link");
            this.helperService.hideLoadingMxpro360();
          }


        }
      }
    } else {

      // como no hay pestañas abiertas con la misma url, abrimos una nueva pestaña
      const popup = window.open(urlComplete, dialerWindowName);
      popup.focus();

      this.helperService.hideLoadingMxpro360();
    }
  }

  /**
   * Permite cargar informacion de la session en el componenente, para poder procesarlos
   */
  private loadDataSession() {
    // verificamo si se esta cargando una compañia de algun workspace
    if (this.panelType !== 'COMPANY') {
      this.workspace = this.authService.workspaceSession.workspace;
    }
    this.companies = this.authService.workspaceSession.companies;
    this.company = this.authService.workspaceSession.company;
    this.employee = this.authService.workspaceSession.employee;
    this.getClock();
  }

  /**
   * Permite actualiza la informacion de la sesion y
   * posteriormente cambiar a la aseccion de la compañia
   *
   * @param company
   */
  changeCompany(company: Company) {
    this.authService.setCompanySession(company);
    this.helperService.goToWorkspaceRouterLink('/companyspace/' + company.id + '/dashboard');
  }

  async logout() {
    this.helperService.showLoadingMxpro360();
    const validateLogout = await this.authService.logout();
    localStorage.removeItem('customer_estimate');
    if (validateLogout) {
      this.googleAuthService.disconnect();
      this.router.navigateByUrl('/login');
      this.helperService.hideLoadingMxpro360();
    } else {
      console.error('Error al cerrar la sesion');
    }
  }

  doSearchJob() {
    this.helperService.showLoadingMxpro360();
    if (this.panelType == 'COMPANY') {
        this.estimatesService
        .getByEstimateCode(this.searchJob)
        .then((response: any) => {
            if (response.message) {
                // mensaje de no encntrado
                this.helperService.showMessageSnackbar(response.message);
            } else {
                this.helperService.resultsearch = response;
                this.helperService.goToCompanyRouterLink('/leads-estimates-results');
            }
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    } else {
        this.jobsService
        .getByEstimateCode(this.searchJob)
        .then((response: any) => {
            if (response.message) {
                // mensaje de no encntrado
                this.helperService.showMessageSnackbar(response.message);
            } else {
                this.helperService.resultsearch = response;
                this.helperService.goToWorkspaceRouterLink('/leads-estimates-results');
            }
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

}

  requestAndShowPermission(value) {
    let that = this;
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        that.showNotification(value);
      }
    });
  }

  opencompanyInformation() {
    jQuery(this.companyInformation.nativeElement).modal('show');
    setTimeout(() => {
      jQuery('#companyInformation').css('background', '#00000069')
      jQuery('.modal-backdrop').remove();
    }, 200);
  }

  closecompanyInformation() {
    jQuery(this.companyInformation.nativeElement).modal('hide');
  }

  showNotification(value) {
    // sin esta activa la pantalla no muestra nada
    // if (document.visibilityState === "visible") {
    //     return;
    // }
    const title = 'General';
    const icon = 'assets/img/logotipo_moverxpro360.png';
    const body = value.message;
    const notification = new Notification(title, { body, icon });
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    };
  }

  /* Open Modal Payroll Time Clock */
  modalPayrollTimeClocks() {
    this.authService
      .getPublicIp()
      .then((response: any) => {
        if (response.ip) {
          this.publicIP = response.ip;
        }
      });

    navigator.geolocation.getCurrentPosition((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    });
    jQuery(this.payrollTimeClocks.nativeElement).modal('show');
    document.getElementById('app-sidebar').style.filter = 'brightness(0.1)';
    document.getElementById('app-sidebar').style.pointerEvents = 'none';

    setTimeout(() => {
      document.getElementById('payrollTimeClocks').style.background = '#000000ed';
      jQuery('.modal-backdrop').remove();
    }, 200);
  }

  /* Close Modal Payroll Time Clock */
  closePayrollTimeClocks() {
    jQuery(this.payrollTimeClocks.nativeElement).modal('hide');
    document.getElementById('app-sidebar').style.filter = 'brightness(1)';
    document.getElementById('app-sidebar').style.pointerEvents = 'auto';
  }

  /* Payroll Time Clock */
  realTimeClock() {
    this.PayrollClock = new Date().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  getClock() {
    this.helperService.showLoadingMxpro360();

    this.payrollTimeClockService
      .getClock(this.employee.id)
      .then((response) => {

        this.timeClockEmployee = response;

        if (response.id) {
          this.idClock = response.id;
        }

        if (!response.clock_out) {
          this.clockIn = true;
        }

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  clock() {
    const clockIn = !this.clockIn;
    if (clockIn) {
      this.helperService.showLoadingMxpro360();
      const timeClock = new TimeClock();
      timeClock.employee_id = this.employee.id;
      timeClock.date = moment().format('MM/DD/YYYY');
      timeClock.time_in = moment().format('HH:mm');
      timeClock.company_id = this.company.id;
      timeClock.location.geospatial.type = 'point',
        timeClock.location.geospatial.coordinates[0] = this.longitude,
        timeClock.location.geospatial.coordinates[1] = this.latitude,

        this.payrollTimeClockService
          .setClock(timeClock, this.publicIP)
          .then((response) => {
            this.idClock = response.id;
            this.timeClockEmployee = response;
            this.clockIn = true;
          })
          .catch((error) => {
            this.helperService.showMessageSnackbar(error.error.message, 'ERROR');
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
    } else {
      this.helperService.showLoadingMxpro360();
      const timeClock = new TimeClock();
      timeClock.id = this.idClock;
      timeClock.time_out = moment().format('HH:mm');
      timeClock.company_id = this.company.id;

      this.payrollTimeClockService
        .putClock(timeClock)
        .then((response) => {
          this.timeClockEmployee = response;
          this.clockIn = false;
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }

  }


  private getAllClockEmployees() {
    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService
      .getAll()
      .then((response) => {
        this.clockEmployees = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  copyText(text: string, type: string) {

    const url = this.urlCarrier + text + this.workspace.id;
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (url));
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
  }

  /* Boton de pruebas para modal de llamada entrante */
  toggleModalIncomingCall(active) {

    let modal;
    if (active) {
      modal = document.getElementById('incoming_call_pop-up');
      modal.classList.toggle('active')
    }
    else {
      modal = document.getElementById('incoming_call_pop-up');

      modal.classList.remove('active')
    }
  }

  goToEstimate(notificationId: string, jobId: string) {
    // cambia estado de la notificacion y va al estimate
    this.companyNotificationService.updateMyNotifications(notificationId, 'CLEAR')
      .then(() => {
        this.helperService.goToCompanyRouterLink(`/moving/${jobId}/estimate`);
      });
  }
  clearNotification(notificationId: string) {
    // cambia el estado de la notificacion
    this.companyNotificationService.updateMyNotifications(notificationId, 'CLEAR')
      .then(() => {
        this.getUserNotifications();
      });
  }
  /**
   * marca como leidas en base de datos las notificaciones en estado nuevo.
   */
  readNotifications() {
    // se da espera de 5 segundos para marcar como pending las nuevas notificaciones
    setTimeout(() => {
      // busca notificaciones nuevas para evitar que se intente actualizar sin necesidad
      for (let notificacion of this.companyNotifications) {
        if (notificacion.status == 'NEW') {
          this.companyNotificationService.readMyNotifications(this.authService.session.account.id)
            .then((response) => {
              this.companyNotifications = response;
              this.newNotification = false;
            });
          break;
        }
      }
    }, 5000);
  }
}
