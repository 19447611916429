<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span> Additional Stop Rules
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a>
                    </li>                    
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/additional-stop-rules">Additional Stop Rules</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="row">
                            <div class="col-md-6 col-sm-12">

                                <div class="form-group mb-1">
                                    <label class="control-label">Type</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-truck">
                                                    <rect x="1" y="3" width="15" height="13"></rect>
                                                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                                </svg>
                                            </span>
                                        </div>                                     
                                        <select name="type"  [(ngModel)]="dataForm.type" class="form-control">
                                            <option value="PICKUP"> Pickup</option>
                                            <option value="DELIVERY"> Delivery </option>
                                        </select>
                                    </div>
                                    <small id="" class="form-text text-muted mb-4">Select Type Additional Stop Rules.</small>
                                </div>  
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">Maxed Distance Traveled</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" [(ngModel)]="dataForm.maxed_distance_traveled" name="maxed_distance_traveled"
                                            class="form-control">
                                    </div>
                                    <small id="" class="form-text text-muted mb-4">Define Maxed Distance Traveled.</small>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">Price per Stop</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="number" [(ngModel)]="dataForm.price_per_stop" step="0.01" name="price_per_stop"
                                            class="form-control">
                                    </div>
                                    <small id="" class="form-text text-muted mb-4">Define Price per Stop.</small>
                                </div> 
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">Price per Mile</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="number" step="0.01" [(ngModel)]="dataForm.price_per_mile" name="price_per_mile"
                                            class="form-control">
                                    </div>
                                    <small id="" class="form-text text-muted mb-4">Define Price per Mile.</small>
                                </div> 
                            </div>

                            <div class="col-md-6 col-sm-12">
                                <div class="form-group mb-1">
                                    <label class="control-label">Max Miles Free</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-delete">
                                                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                                                    <line x1="18" y1="9" x2="12" y2="15"></line>
                                                    <line x1="12" y1="9" x2="18" y2="15"></line>
                                                </svg></span>
                                        </div>
                                        <input type="number" [(ngModel)]="dataForm.max_miles_free" name="max_miles_free"
                                            class="form-control">
                                    </div>
                                    <small id="" class="form-text text-muted mb-4">Define Max Miles Free.</small>
                                </div> 
                            </div>

                        </div>
                        <div class="text-center"><br>
                            <button class="btn btn-main-action ml-1 mt-5" type="submit">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>