import { Component, OnInit } from '@angular/core';
import { JobItemQrCode } from 'src/app/entities/job-item-qr-code';

@Component({
  selector: 'app-custom-labels-view',
  templateUrl: './custom-labels-view.component.html',
  styleUrls: ['./custom-labels-view.component.scss']
})
export class CustomLabelsViewComponent implements OnInit {

  public itemsQrNotAssigned: JobItemQrCode[];
  public labelColumns: boolean; // true = Two Columns | false = Three Columns
  
  constructor() {
    this.itemsQrNotAssigned = [];
  }

  ngOnInit(): void {
  }

}
