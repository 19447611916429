import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClockLocations } from 'src/app/entities/companies/clock-locations';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-payroll-clock-locations-form',
  templateUrl: './payroll-clock-locations-form.component.html',
  styleUrls: ['./payroll-clock-locations-form.component.scss']
})
export class PayrollClockLocationsFormComponent implements OnInit {

  public clockLocation: ClockLocations;
  public allowedIPAddresses: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private payrollTimeClockService: PayrollTimeClockService
  ) {
    this.clockLocation = new ClockLocations();
    this.allowedIPAddresses = '';
   }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        console.log('params ', params);
        this.loadClockLocation(params.id);        
      }
    });
  }

  loadClockLocation(id: string): void {
    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService
      .getClockLocationById(id)
      .then(response => {
        this.allowedIPAddresses = '';
        this.clockLocation = response;
        for(const allowedIp of this.clockLocation.allowed_ip_address) {
          // 190.85.21.69
          if (this.allowedIPAddresses.length > 0) {
            this.allowedIPAddresses += ',';  
          }
          this.allowedIPAddresses += allowedIp;
        }
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  save() {
    this.helperService.showLoadingMxpro360();
    this.clockLocation.allowed_ip_address = this.allowedIPAddresses.split(',');
    this.payrollTimeClockService.addClockLocation(this.clockLocation)
      .then((response) => {
        console.log(' response ', response);
        this.clockLocation = new ClockLocations();
        this.helperService.goToCompanyRouterLink('/payroll-clock-locations');
      })
      .catch((error) => {
        console.log(error.error.message);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  saveLocation(location) {
    console.log(' saveLocation e', location);
    this.clockLocation.location = location;    
  }

}
