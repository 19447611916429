<div class="layout-top-spacing">
    <form class="form" #employeesForm="ngForm" (ngSubmit)="save(employeesForm)" ngNativeValidate novalidate autocomplete="off">

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span>
                    Employee
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/employees">Employee</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    
                </div>
            </div>

        </div><!-- header -->

        <div class="widget-content widget-content-area">

            <div class="row">
                <div class="col-9">
                    <div class="form-group mb-1">
                        <label class="control-label">
                            Email - <strong> Account Assignment </strong>
                        </label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                </span>
                            </div>
                            <input type="email" [(ngModel)]="accountEmail" name="account_email" class="form-control" #employeeEmail="ngModel" required email>
                        </div>
                        <ng-container *ngIf="employeeEmail.control.errors != null && (employeesForm.submitted || employeeEmail.control.touched )">
                            <div class="invalid-feedback"  *ngIf="employeeEmail.control.errors.required">
                                It is obligatory to indicate an e-mail address
                            </div>
                            <div class="invalid-feedback"  *ngIf="employeeEmail.control.errors.email">
                                the mail must be formatted correctly 
                            </div>
                        </ng-container>
                        <small  class="form-text text-muted">
                            Enter the new employee's email address in the text field and then click on the verify button so that the system can assign it.
                        </small>
                    </div>
                </div>
                <div class="col-3 text-center">
                    <button (click)="searchAccout()" class="btn btn-danger btn-verify-email" type="button" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        &nbsp; verify email
                    </button>
                </div>
            </div>

            <hr>

            <div class="form-group mb-1">
                <label class="control-label">Name</label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5">
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </span>
                    </div>
                    <input type="text" [(ngModel)]="dataForm.name" name="employee_name" class="form-control" required #employeeName="ngModel">
                </div>
                <ng-container *ngIf="employeeName.control.errors != null && (employeesForm.submitted || employeeName.control.touched )">
                    <div class="invalid-feedback"  *ngIf="employeeName.control.errors.required">
                        It is mandatory to indicate a name to the employee
                    </div>
                </ng-container>
                <small  class="form-text text-muted mb-4">Enter the employee's name here</small>
            </div>

            <div class="form-group mb-1">
                <label class="control-label">Job Position</label>
                <div class="input-group mb-1">
                    <select [(ngModel)]="dataForm.job_position" name="job_position" class="form-control position" #jobPosition="ngModel" required >
                        <option [ngValue]="null" selected> Select Option </option>
                        <option value="DRIVER"> DRIVER </option>
                        <option value="FOREMAN"> FOREMAN </option>
                        <option value="FOREMAN"> OPERATOR </option>
                        <option value="SALES_MANAGER"> SALES MANAGER </option>
                        <option value="SALESMAN"> SALESMAN </option>
                        <option value="WORKER"> WORKER </option>
                        <option value="DISPATCH_SERVICE"> DISPATCH SERVICE </option>
                        <option value="CUSTOMER_SERVICE"> CUSTOMER SERVICE </option>
                        <option value="MANAGER"> MANAGER </option>
                        <option value="ADMIN"> ADMIN </option>
                    </select>
                </div>
                <ng-container *ngIf="jobPosition.control.errors != null && (employeesForm.submitted || jobPosition.control.touched )">
                    <div class="invalid-feedback"  *ngIf="jobPosition.control.errors.required">
                        It is mandatory to select a job position for the employee
                    </div>
                </ng-container>
                <small  class="form-text text-muted mb-4">
                    Select the position for the employee using the selector
                </small>
            </div>

            <div class="form-group mb-1" *ngIf="requestPassword">
                <label class="control-label">Phone Number to OTP:</label>
                <div class="input-group mb-1">
                    <input id="account_phone" name="account_phone" onlyNumbers type="tel" minlength="10" autocomplete="new" [(ngModel)]="accountPhone" maxlength="14" class="form-control" required #phoneNumber="ngModel">
                </div>
                <ng-container *ngIf="phoneNumber.control.errors != null && (employeesForm.submitted || phoneNumber.control.touched )">
                    <div class="invalid-feedback"  *ngIf="phoneNumber.control.errors.required">
                        it is obligatory to indicate the phone number for the user to be able to log in
                    </div>
                    <div class="invalid-feedback"  *ngIf="phoneNumber.control.errors.minlength">
                        the phone number must be at least 10 digits long
                    </div>
                </ng-container>
                <small  class="form-text text-muted mb-4">
                    Enter the phone number where they will receive the verification code each time the employee logs in.
                </small>
            </div>

            <div class="form-group mb-1" *ngIf="requestPassword">
                <label class="control-label">Password:</label>
                <div class="input-group mb-1">
                    <input type="text" [(ngModel)]="accountPassword"  minlength="6" maxlength="20" name="account_password" class="form-control" required #passField="ngModel">
                </div>
                <ng-container *ngIf="passField.control.errors != null && (employeesForm.submitted || passField.control.touched )">
                    <div class="invalid-feedback"  *ngIf="passField.control.errors.required">
                        how can you think of adding an employee without a password?...
                        it is mandatory to assign the password to the employee's record, so that he/she can log in.... 
                    </div>
                    <div class="invalid-feedback"  *ngIf="passField.control.errors.minlength">
                        we consider that a good password should be at least 6 characters long... we do not do it out of distrust, but as a precaution
                    </div>
                </ng-container>
                <small class="form-text text-muted mb-4">
                    Enter a secure password for the account, keep in mind that this will prevent malicious users from gaining access to the employee's new account
                </small>
            </div>

            <hr>

            <div class="text-center">
                <a appWorkspaceRouterLink="/employees" class="btn btn-ligth">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                    &nbsp; Cancel
                </a>
                &nbsp;
                <button class="btn btn-save" type="submit" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                    &nbsp; Save Employee
                </button> 
            </div>

        </div>
    </form>
</div>