<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 layout-spacing align-self-center">
            <h3>
                Search Results
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Search Results</li>
                </ol>
            </nav>
        </div>
    </div>
    
    <!-- Lista de Estimates -->
    <ng-container  *ngIf="dataResult">
        <div class="widget-content searchable-container list " *ngIf="dataResult.estimates?.length > 0">
            <h3 class="title-content-area title">
                Estimates
            </h3>

            <!-- Help Bar -->
            <div class="col-lg-12 col-md-12 label-section">
                <div class="label booked-estimate">
                    <div class="box"></div>
                    <div>Booked Estimate</div>        
                </div>   
                <div class="label unsuccessful-sale">
                    <div class="box"></div>
                    <div>Unsuccessful Sale</div>   
                </div>       
            </div>

            <div class="row padding-table">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="widget-content searchable-container list">
                        <div class="searchable-items list">
                            <div class="items items-header-section">
                                <div class="item-content">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                        <h4>
                                            Customer
                                        </h4>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <h4 style="margin-left: 3px;">
                                            Service
                                        </h4>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <h4 style="margin-left: 3px;">
                                            Description
                                        </h4>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <h4 style="margin-left: 3px;">
                                            Date
                                        </h4>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <h4 style="margin-left: 3px;">
                                            Estimate
                                        </h4>
                                    </div>
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" *ngIf="isWorkspace">
                                        <h4 style="margin-left: 3px;">
                                            Company
                                        </h4>
                                    </div>
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1" *ngIf="!isWorkspace">
                                        <h4 style="margin-left: 3px;">
                                            Category
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div class="items cursor-pointer" *ngFor="let estimate of dataResult.estimates | paginate: {itemsPerPage: 25, currentPage: actualPage }">
                                <div class="item-content" [ngClass]="{'booked-active': estimate.booked && estimate.cancellation == null, 'UNSUCCESSFUL_SALE': estimate.status == 'UNSUCCESSFUL_SALE' && estimate.cancellation == null, 'cancelled-job': estimate.cancellation != null}" (click)="openNewTab(estimate);$event.stopPropagation()">                                

                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 position-absolute t-45p">
                                        <div class="customer-info-container" (click)="copyText(estimate.customer.phone, 'text');$event.stopPropagation()">
                                            <p class="word-break-customer">
                                                <i class="far fa-copy p-2"></i>
                                                <span class="customer-info">{{estimate.customer.phone | phone:'US'}}</span>
                                            </p>
                                        </div>
                                        <br>
                                        <div class="customer-info-container" (click)="copyText(estimate.customer.email, 'text');$event.stopPropagation()">
                                            <p class="word-break-customer">                                        
                                                <i class="far fa-copy p-2"></i>
                                                <span class="customer-info">{{estimate.customer.email}}</span>
                                            </p>                                   
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 marg-40t">
                                        <div class="row" style="padding-left: 20px; margin-bottom: 5px;">
                                            <div>
                                                <p class="user-name">
                                                    <strong>{{estimate.customer.last_name?.toUpperCase()}}</strong>,
                                                </p>
                                            </div>
                                            <div>
                                                <p class="user-work" style="margin-left: 5px; font-size: 15px;">
                                                    {{estimate.customer.name | filterReplace}}
                                                </p>
                                            </div>
                                            <!-- <div>
                                                <p class="user-work">
                                                    {{estimate.customer.phone | phone:'US'}}
                                                </p>
                                            </div>
                                            <div>
                                                <p class="user-work">
                                                    {{estimate.customer.email}}
                                                </p>
                                            </div -->
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <div *ngIf="estimate.service == 'AUTO_TRANSPORT'; else noneAuto">
                                            <strong
                                                [ngClass]="{'estimate-booked': estimate.booked && estimate.cancellation == null, 'estimate-no-booked': !estimate.booked && estimate.cancellation == null, 'cancelled-job': estimate.cancellation != null}">Type:</strong>
                                            <span style="margin-left: 10px;"
                                                class="badge badge-info">{{estimate.service | filterReplace}}
                                            </span>
                                        </div>

                                        <ng-template #noneAuto>
                                            <div *ngIf="estimate.service == 'LONG'; else noneLong">
                                                <strong
                                                    [ngClass]="{'estimate-booked': estimate.booked && estimate.cancellation == null, 'estimate-no-booked': !estimate.booked && estimate.cancellation == null, 'cancelled-job': estimate.cancellation != null}">Type:</strong>
                                                <span style="margin-left: 10px;"
                                                    class="badge badge-secondary">{{estimate.service | filterReplace}}
                                                </span>
                                            </div>
                                        </ng-template>

                                        <ng-template #noneLong>
                                            <div>
                                                <strong
                                                    [ngClass]="{'estimate-booked': estimate.booked && estimate.cancellation == null, 'estimate-no-booked': !estimate.booked && estimate.cancellation == null, 'cancelled-job': estimate.cancellation != null}">Type:</strong>
                                                <span style="margin-left: 10px;"
                                                    class="badge badge-dark">{{estimate.service | filterReplace}}
                                                </span>
                                            </div>
                                        </ng-template>
                                        <p class="usr-email-addr">
                                            <strong>From: </strong>{{estimate.pickup.address.state}}
                                            {{estimate.pickup.address.zip_code}}
                                        </p>
                                        <p class="usr-email-addr">
                                            <strong>To: </strong>{{estimate.delivery.address.state}}
                                            {{estimate.delivery.address.zip_code}}
                                        </p>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">

                                        <div *ngIf="estimate.service != 'AUTO_TRANSPORT'">
                                            <p class="usr-location">
                                                <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                                <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong> 
                                                {{estimate.volume.libs | number}}
                                            </p>
                                            <p class="usr-location">
                                                <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                                <strong *ngIf="estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                                {{estimate.volume.cubic_feets_selled | number}}
                                            </p>
                                        </div>

                                        <div *ngIf="estimate.service == 'AUTO_TRANSPORT'">
                                            <p class="usr-location">
                                                <strong># Vehicles: </strong> {{estimate.number_vehicles | number}}
                                            </p>

                                        </div>

                                        <p class="usr-location">
                                            <strong>Miles: </strong>{{estimate.volume.miles | number}}
                                        </p>
                                        <p class="usr-location" *ngIf="estimate.employee && estimate.employee.name">
                                            <strong>Salesman: </strong>{{estimate.employee.name}}
                                        </p>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <p class="usr-email-addr" *ngIf="estimate.pickup.range_start">
                                            <span style="margin-right: 10px;" class="badge badge-danger">Move Date:</span>
                                            {{estimate.pickup.range_start | date}} to
                                            {{estimate.pickup.range_end | date}}
                                        </p>
                                        <p class="usr-location">
                                            <strong>Estimated Date: </strong>{{estimate.created | date}}
                                        </p>
                                        <p *ngIf="estimate.booked" class="usr-location">
                                            <strong>Booked Date: </strong>{{estimate.booked_date | date}}
                                        </p>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                        <p class="usr-ph-no">
                                            <strong>Number: </strong>
                                            {{estimate.document.code}}
                                        </p>
                                        <p class="usr-ph-no">
                                            <strong>Estimate: </strong> {{estimate.total | currency}}
                                        </p>
                                    </div>
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"  *ngIf="isWorkspace">
                                        <ng-container *ngFor="let company of companies">
                                            <p class="usr-ph-no" *ngIf="company.id == estimate.company_id">
                                                {{company.name}}
                                            </p>
                                        </ng-container>
                                        
                                        
                                    </div>
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"  *ngIf="!isWorkspace">
                                        <select name="deadModal" [(ngModel)]="estimate.category" (click)="$event.stopPropagation()"
                                            class="form-control select-modal-estimate select-category cursor-pointer"
                                            (change)="changeCategoryEstimate(estimate, $event.target.value)">
                                            <option [selected]="estimate.category == 'NEW'" value="NEW">New</option>
                                            <option [selected]="estimate.category == 'OPEN'" value="OPEN">Open</option>
                                            <option [selected]="estimate.category == 'HOT'" value="HOT">Hot</option>
                                            <option [selected]="estimate.category == 'WARM'" value="WARM">Warm</option>
                                            <option [selected]="estimate.category == 'COLD'" value="COLD">Cold</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <pagination-controls class="paginating-container pagination-solid"
                                (pageChange)="actualPage = $event">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- fin lista de estimates -->
    </ng-container>
    <!-- End - Lista de Estimates -->

    <!-- Lista de Leads -->
    <ng-container *ngIf="dataResult">    
        <ng-container *ngIf="dataResult.estimates?.length == 0 && dataResult.leads?.length > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">

                <h3 class="title-content-area title">
                    Leads
                </h3>
                <div class="row padding-table">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="widget-content searchable-container list">
                            <div class="searchable-items list">
                                <div class="items items-header-section">
                                    <div class="item-content">
                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                            <h4>
                                                Customer
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Service
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Date
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Info
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Category
                                            </h4>
                                        </div>
                                        <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                            <h4 style="margin-left: 3px;">
                                                Options
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="items" *ngFor="let row of dataResult.leads | paginate: {itemsPerPage: 25, currentPage: actualPage }">
                                    <div class="item-content information-col" role="menu">

                                        <div class="user-profile col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                            <div class="user-meta-info info">
                                                <div class="row" style="padding-left: 20px; margin-bottom: 5px;">
                                                    <p class="user-name">
                                                        {{row.lead.customer.last_name.toUpperCase()}},</p>
                                                    <p class="user-work" style="margin-left: 5px; font-size: 15px;">
                                                        {{row.lead.customer.name | filterReplace}}</p>
                                                </div>                                   
            
                                                <div class="customer-info-container" (click)="copyText(row.lead.customer.phone, 'text')">
                                                    <p class="word-break-customer">                                        
                                                        <i class="far fa-copy p-2"></i>
                                                        <span class="customer-info">{{row.lead.customer.phone | phone: 'US'}}</span>
                                                    </p>                                   
                                                </div>  
                                                <br>
                                                <div class="customer-info-container" (click)="copyText(row.lead.customer.email, 'text')">
                                                    <p class="word-break-customer">
                                                        <i class="far fa-copy p-2"></i>
                                                        <span class="customer-info">{{row.lead.customer.email}}</span>
                                                    </p>
                                                </div>                                                    
                                            </div>
                                        </div>

                                        <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                            
                                            <div *ngIf="row.lead.service == 'AUTO_TRANSPORT'; else noneAuto">
                                                <strong style="color:#515365;">Type:</strong>
                                                <span style="margin-left: 10px;"
                                                    class="badge badge-info">{{row.lead.service | filterReplace}}
                                                </span>
                                            </div>
            
                                            <ng-template #noneAuto>
                                                <div *ngIf="row.lead.service == 'LONG'; else noneLong">
                                                    <strong style="color:#515365;">Type:</strong>
                                                    <span style="margin-left: 10px;"
                                                        class="badge badge-secondary">{{row.lead.service | filterReplace}}
                                                    </span>
                                                </div>
                                            </ng-template>
            
                                            <ng-template #noneLong>
                                                <div>
                                                    <strong style="color:#515365;">Type:</strong>
                                                    <span style="margin-left: 10px;"
                                                        class="badge badge-dark">{{row.lead.service | filterReplace}}
                                                    </span>
                                                </div>
                                            </ng-template>
            
                                            <p class="usr-email-addr">
                                                <strong>From: </strong>{{row.lead.from_address.state}}
                                                {{row.lead.from_address.zip_code}}
                                            </p>
            
                                            <p class="usr-email-addr">
                                                <strong>To: </strong>{{row.lead.to_address.state}}
                                                {{row.lead.to_address.zip_code}}
                                            </p>
            
                                            <p *ngIf="row.lead.advertiser !== null " class="usr-ph-no">
                                                <strong>Advertiser:</strong>
                                                {{row.lead.advertiser.name}}
                                            </p>
                                            
                                        </div>

                                        <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div class="user-meta-info info">
                                                
                                                <p class="usr-ph-no"><span style="margin-right: 10px;"
                                                        class="badge badge-danger">Move Date:</span>
                                                    {{row.lead.move_date | date}}
                                                </p>
                                                <p class="usr-ph-no"><strong>Created:</strong>
                                                    {{row.lead.created | dateTime}}
                                                </p>

                                            </div>
                                        </div>

                                        <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div class="user-meta-info info"
                                                *ngIf="row.lead.service == 'AUTO_TRANSPORT'">
                                                <p class="user-work" *ngIf="row.vehicles"> <strong>Type:</strong>
                                                    {{row.vehicles.type}}</p>
                                                <p class="user-work" *ngIf="row.vehicles"><strong>Brand:</strong>
                                                    {{row.vehicles.brand}}</p>
                                                <p class="user-work" *ngIf="row.vehicles"><strong>Model:</strong>
                                                    {{row.vehicles.model}}</p>
                                                <p class="user-work" *ngIf="row.vehicles"><strong>Year:</strong>
                                                    {{row.vehicles.year}}</p>
                                            </div>
                                        </div>

                                        <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div class="user-meta-info info">
                                                <ng-container *ngIf="isVisibleCategory">
                                                    <select name="deadModal" [(ngModel)]="row.category"
                                                        class="form-control select-modal-estimate select-category cursor-pointer"
                                                        (change)="changeCategory(row.id, $event.target.value)"
                                                        [disabled]="!isEditableCategory">
                                                        <option [selected]="row.category == 'NEW'" value="NEW">New</option>
                                                        <option [selected]="row.category == 'SCHEDULED'" value="SCHEDULED">Scheduled</option>
                                                        <option [selected]="row.category == 'HOT'" value="HOT">Hot</option>
                                                        <option [selected]="row.category == 'WARM'" value="WARM">Warm</option>
                                                        <option [selected]="row.category == 'COLD'" value="COLD">Cold</option>
                                                    </select>
                                                </ng-container>
                                            </div>
                                        </div>

                                        <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                            <div class="user-meta-info info">
                                                <button id="btnOutline"
                                                    *ngIf="row.status == 'NEW' || row.status == 'IN_ESTIMATION' || row.status == 'DEAD_LEAD'"
                                                    type="button"
                                                    class="btn btn-secondary btn-full margin-bottom dropdown-toggle btn-options"
                                                    data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <i class="fas fa-cog"></i>
                                                </button>
                                                <div class="dropdown-menu leads-options cursor-pointer"
                                                    aria-labelledby="btnOutline" style="will-change: transform;">
                                                    <a class="dropdown-item"
                                                        *ngIf="row.status == 'NEW' || row.status == 'DEAD_LEAD'"
                                                        (click)="toEstimate(row.id)">
                                                        <i class="fas fa-file-contract"></i>
                                                        <span>To Estimate</span>
                                                    </a>
                                                    <a appCompanyRouterLink="/customers/{{row.lead.customer_id}}/view"
                                                        class="dropdown-item" *ngIf="row.status == 'IN_ESTIMATION'">
                                                        <i class="fas fa-file-invoice-dollar"></i>
                                                        <span>View Customer</span>
                                                    </a>
                                                    <a class="dropdown-item" *ngIf="row.status == 'NEW'"
                                                        (click)="modalDeadLead(row.id)">
                                                        <i class="fas fa-user-slash"></i>
                                                        <span>Dead Lead</span>
                                                    </a>
                                                    <a class="dropdown-item" *ngIf="row.status == 'NEW'"
                                                        (click)="notContacted(row)">
                                                        <i class="fas fa-phone-slash"></i>
                                                        <span>Not Contacted</span>
                                                    </a>
                                                    <a appCompanyRouterLink="/leads/{{row.id}}/edit" class="dropdown-item"
                                                        *ngIf="row.status == 'NEW'">
                                                        <i class="fas fa-edit"></i>
                                                        <span>Edit</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <pagination-controls class="paginating-container pagination-solid"
                                    (pageChange)="actualPage = $event">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </ng-container>
    </ng-container>
    <!-- End - Lista de Leads -->

    <!-- Listado de Balances -->
    <ng-container *ngIf="dataResult">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 layout-top-spacing" *ngFor="let balance of dataResult.balances">
            <div class="user-profile info-lead layout-spacing" (click)="goToDetailBalance(balance.id)">
                <div class="widget-content widget-content-area">
                    <div class=" justify-content-between widget-heading">
                        <h3 class="title-content-area title">
                            Balance
                        </h3>
                    </div>

                    <div class="user-info-list">
                        <div>

                            <ul class="contacts-block list-unstyled margin-balance">

                                <div class="row">
                                    <div class="col-md-6">
                                        <li class="contacts-block__item phone">
                                            <span class="text-label">Last Change:</span>
                                            <span class="phone"> {{balance.updated | dateTime}} </span>
                                        </li>

                                        <li class="contacts-block__item phone">
                                            <span class="text-label">Send On:</span>
                                            <span> {{balance.created | dateTime}} </span>
                                        </li>

                                        <li class="contacts-block__item phone" *ngIf="balance.payment.gateway">
                                            <span class="text-label">Payment Gateway:</span>
                                            <span *ngIf="balance.payment"> {{balance.payment.gateway | filterReplace}}
                                            </span>
                                        </li>

                                        <li class="contacts-block__item phone" *ngIf="balance.payment.remarks">
                                            <span class="text-label">Remarks:</span>
                                            <span *ngIf="balance.payment"> {{balance.payment.remarks}} </span>
                                        </li>
                                    </div>

                                    <div class="col-md-6">
                                        <li class="contacts-block__item phone">
                                            <span class="text-label">Amount:</span>
                                            <span> {{balance.amount | currency}} </span>
                                        </li>

                                        <li class="contacts-block__item phone">
                                            <span class="text-label">Status:</span>
                                            <span *ngIf="balance.status === 'PROCESSING'"
                                                class="badge outline-badge-warning margin-status">
                                                {{balance.status | filterReplace}}
                                            </span>
                                            <span *ngIf="balance.status === 'PAID'"
                                                class="badge outline-badge-success margin-status">
                                                {{balance.status | filterReplace}}
                                            </span>
                                            <span *ngIf="balance.status === 'PENDING'"
                                                class="badge outline-badge-danger margin-status">
                                                {{balance.status | filterReplace}}
                                            </span>
                                            <span *ngIf="balance.status === 'PENDING_REVIEW'"
                                                class="badge outline-badge-info margin-status">
                                                {{balance.status | filterReplace}}
                                            </span>
                                        </li>

                                        <li class="contacts-block__item phone">
                                            <span class="text-label">Updated:</span>
                                            <span> {{balance.updated | dateTime}} </span>
                                        </li>

                                        <li class="contacts-block__item phone" *ngIf="balance.method">
                                            <span class="text-label">Method:</span>
                                            <span> {{balance.method.method |filterReplace}} </span>
                                        </li>

                                        <li class="contacts-block__item phone" *ngIf="balance.payment">
                                            <ng-container *ngIf="balance.payment.date">
                                                <strong>Transaction Day: </strong>
                                                <span> {{balance.payment.date | dateTime}} </span>
                                            </ng-container>
                                        </li>
                                    </div>

                                </div>

                            </ul>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    <!-- fin - Listado de Balances -->
    </ng-container>
    <!-- End - Listado de Balances -->

</div>

<!--Modal: modalPush-->
<div class="modal fade" #deadLeadModal tabindex="-1" role="dialog" aria-labelledby="modalDeadLead" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <!--Content-->
        <div class="modal-content text-center">
            <!--Header-->
            <div class="modal-header d-flex justify-content-center back-header">
                <p class="heading text-heading">Dead Lead</p>
            </div>

            <!--Body-->
            <div class="modal-body">

                <i class="fas fa-user-slash fa-4x animated rotateIn mb-4"></i>
                <br><br>
                <p>what is the reason you want to block this Lead?</p>

                <select name="deadModal" class="form-control select-modal cursor-pointer" [(ngModel)]="deadLeadSelected">
                    <option value="ALREADY_BOOKED">ALREADY BOOKED</option>
                    <option value="ALREADY_MOVED">ALREADY MOVED</option>
                    <option value="BAD_REVIEWS">BAD REVIEWS</option>
                    <option value="BOOKED_TO_CAPACITY">BOOKED TO CAPACITY</option>
                    <option value="CAR_SHIPPING_ONLY">CAR SHIPPING ONLY</option>
                    <option value="CONTRACT_WITH_ANOTHER_COMPANY">CONTRACT WITH ANOTHER COMPANY</option>
                    <option value="CUSTOMER_IS_NOT_MOVING">CUSTOMER IS NOT MOVING</option>
                    <option value="DISCONNECTED_PHONE_NO">DISCONNECTED_PHONE_NO.</option>
                    <option value="DUPLICATE_(DIFFERENT_LEAD_PROVIDER)">DUPLICATE (DIFFERENT LEAD PROVIDER)</option>
                    <option value="DUPLICATE_(SAME_LEAD_PROVIDER)">DUPLICATE (SAME LEAD PROVIDER)</option>
                    <option value="FAKE_EMAIL">FAKE EMAIL</option>
                    <option value="FAKE_NAME">FAKE NAME</option>
                    <option value="FAKE_PHONE">FAKE PHONE</option>
                    <option value="FAKE/WRONG_NAME">FAKE/WRONG_NAME</option>
                    <option value="LANGUAGE_BARRIER">LANGUAGE BARRIER</option>
                    <option value="LOCAL_MOVE">LOCAL MOVE</option>
                    <option value="LOOKING_FOR_APARTMENT">LOOKING FOR APARTMENT</option>
                    <option value="MOVE_DATE_ALREADY_PASSED">MOVE DATE ALREADY PASSED</option>
                    <option value="MOVE_DATE_IS_6_MONTHS_AWAY">MOVE DATE IS 6 MONTHS AWAY</option>
                    <option value="MOVING_ONLY_1_ITEM">MOVING ONLY 1 ITEM</option>
                    <option value="NO_RESPONSE/UNABLE_TO_MAKE_CONTACT">NO RESPONSE/UNABLE TO MAKE CONTACT</option>
                    <option value="NOT_INTERESTED_IN_OUR_COMPANY">NOT INTERESTED IN OUR COMPANY</option>
                    <option value="NOT_LOOKING_FOR_A_MOVING_COMPANY">NOT LOOKING FOR A MOVING COMPANY</option>
                    <option value="OUTSIDE_OF_THE_DELIVERY_RADIUS">OUTSIDE OF THE DELIVERY RADIUS</option>
                    <option value="OUTSIDE_OF_THE_PICK-UP_RADIUS">OUTSIDE OF THE PICK-UP RADIUS</option>
                    <option value="PHONE_NUMBER_IS_A_FAX">PHONE NUMBER IS A FAX</option>
                    <option value="POD_RENTAL">POD RENTAL</option>
                    <option value="REMOVED_FROM_MAILING_LIST">REMOVED FROM MAILING LIST</option>
                    <option value="SELF_MOVE">SELF MOVE</option>
                    <option value="SHIPPING_NON-HOUSEHOLD_GOODS">SHIPPING NON-HOUSEHOLD GOODS</option>
                    <option value="TRUCK_RENTAL">TRUCK RENTAL</option>
                    <option value="UNBEATABLE_PRICE">UNBEATABLE PRICE</option>
                    <option value="WRONG_PHONE_NO">WRONG PHONE NO.</option>
                </select>

            </div>

            <!--Footer-->
            <div class="modal-footer flex-center justify-content-center mt-2">
                <a type="button" class="btn btn-danger button-size" (click)="deadLead()">Yes</a>
                <a type="button" class="btn btn-outline-danger waves-effect button-size" data-dismiss="modal">No</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div>
<!--Modal: modalPush-->