import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Credential } from 'src/app/entities/helpers/credential';
import { environment } from 'src/environments/environment';
import { ClientSocketsService } from './client-sockets.service';
import { Session } from '../entities/helpers/session';
import { WorkspaceSession } from '../entities/helpers/workspace-session';
import { AccountRecovery } from '../entities/helpers/account-recovery';
import { Account } from '../entities/admin/account';
import { Company } from '../entities/workspace/company';
import { EmployeeCompanyPermission } from '../entities/workspace/employee-company-permission';
import { PaymentProfile } from '../entities/workspace/payment-profile';
import { AccountCompany } from '../entities/workspace/account-company';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    // variables principales
    session: Session;
    workspaceSession: WorkspaceSession;

    // evento creado para emitir un cambio en la sesion,
    // si envia true es por que el usuario sigue logueado
    onChange: EventEmitter<boolean>;


    constructor(
        private http: HttpClient,
        private clientSocketsService: ClientSocketsService
    ) {
        this.session = null;
        this.workspaceSession = null;
        this.onChange = new EventEmitter(true);
        this.load();
    }

    /**
     * permite cargar la informacion de la session guardada localmente
     */
    load() {

        // obtenemos informacion del storage
        const session = localStorage.getItem('session');

        // si devuelve algo lo procesamos
        if (session !== null) {

            // se carga la session
            this.session = JSON.parse(session);

            // indicamos que se cambio la sesion
            this.onChange.emit(true);
        }
    }

    /**
     * Permite cargar informacion sobre la sesion de un workspace
     * @param workspaceSessionId
     */
    loadWorkspaceSession(workspaceSessionId) {
        const workspaceSession = localStorage.getItem(`session_${workspaceSessionId}`);
        if (workspaceSession !== null) {
            this.workspaceSession = JSON.parse(workspaceSession);

            // creamos el vinculo en tiempo real con un canal websocket
            this.initSocket();
        }
    }

    /**
     * permite guardar la sesion localmente
     */
    save() {

        if (this.session !== null) {
            localStorage.setItem('session', JSON.stringify(this.session));
        }

        if (this.workspaceSession !== null && this.workspaceSession.id !== null) {
            localStorage.setItem(`session_${this.workspaceSession.id}`, JSON.stringify(this.workspaceSession));

            // creamos el vinculo en tiempo real con un canal websocket
            this.initSocket();
        }

        // indicamos que se cambio la sesion
        this.onChange.emit(true);
    }

    /**
     * Permite crear un canal socket con el servidor correspondiente
     */
    private initSocket() {
        // inicializamos el socket para inidicar que estamos en linea
        this.clientSocketsService.connect(this.session.token, this.workspaceSession.workspace.id,  this.workspaceSession.employee.id);
    }

    /**
     * Permite Iniciar Session en el Backend
     *
     * @param dataForm
     * @returns
     */
    login(dataForm: Credential) {
        const data = {
            username: dataForm.username,
            password: dataForm.password
        };
        return this.http.post(environment.api.base + '/login', data).toPromise();
    }

    createSessionInLogin(response) {
        this.session = new Session();
        this.session.token = response['token'];
        this.session.context = response['context'];
        this.session.account = response['account'];

        // guardamos la sesion
        this.save();
    }

    verifyToken(token: string, publicIP) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/login/account/verify-token?ip=' + publicIP, { token: token })
                .subscribe((response) => {

                    // creamos la sesion local
                    this.createSessionInLogin(response);

                    // indicamos que ya se ejcuto la respuesta
                    resolve(true);

                }, (error) => {
                    if (error.status == 404) {
                        reject(error.error);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    verifyTokenAccountCompany(token: string, id) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/accounts-companies/' + id + '/verify', { token: token })
                .subscribe((response) => {
                    // indicamos que ya se ejcuto la respuesta
                    resolve(true);

                }, (error) => {
                    if (error.status == 404) {
                        reject(error.error);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    resendVerifyTokenAccountCompany(id: string) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/accounts-companies/' + id + '/re-send-token', {})
                .subscribe(() => {
                    // indicamos que ya se ejcuto la respuesta
                    resolve(true);
                }, (error) => {
                    if (error.status == 404) {
                        reject(error.error);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    loginGoogle(id_token: string) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/login/google', { id_token })
                .subscribe((response) => {
                    this.createSessionInLogin(response);
                    // indicamos que ya se ejcuto la respuesta
                    resolve(true);

                }, (error) => {
                    console.error("error login: ", error);
                    reject(error.error);
                });
        });
    }

    validateToken() {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/login/token', {})
                .subscribe((response) => {

                    this.createSessionInLogin(response);

                    // creamos el vinculo en tiempo real con un canal websocket
                    // no se inicializa socket ya que esto redirecciona a una parte donde aun no existira el workspace
                    // this.initSocket();

                    // indicamos que ya se ejcuto la respuesta
                    resolve(true);
                }, (error) => {

                });
        });
    }



    /***************************************
     * CODIGO para Recuperacion de Cuenta **
     ***************************************/

    /**
     * permite enviar un codigo al celular del cliente,
     * esto es para poder verificar que el cliente si
     * es el usuario que esta intentando ingresar al sistema
     *
     * @param employee Empleado
     */
    public sendToken(accountRecovery: AccountRecovery) {
        return this.http.post(environment.api.base + '/password-recovery-token', accountRecovery).toPromise();
    }

    public verifyTokenOfAccountRecovery(accountRecovery: AccountRecovery) {
        return this.http.post(environment.api.base + '/password-recovery-verify-token', accountRecovery).toPromise();
    }


    /***********************************
     * CODIGO para creacion de cuenta **
     ***********************************/

    /**
     * Metodo para registrar una cuenta
     *
     * @param dataForm
     * @returns
     */
    signin(dataForm: Account) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/sign-in', dataForm)
                .subscribe((response) => {

                    // inicializamos la sesion
                    this.session = new Session();
                    this.session.token = response['token'];
                    this.session.account = response['account'];

                    // guardamos la sesion
                    this.save();

                    resolve(true);
                }, (error) => {
                    console.error("error sign-in: ", error);
                    reject(error.error);
                });
        });
    }

    /**
     * Metodo para registrar una cuenta de empresa
     *
     * @param dataForm
     * @returns
     */
    signinAccountCompany(dataForm: AccountCompany) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/accounts-companies', dataForm)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    console.error("error sign-in: ", error);
                    reject(error.error);
                });
        });
    }

    /**
     * Metodo para registrar una cuenta de empresa
     *
     * @param dataForm
     * @returns
     */
    updateAccountCompany(dataForm: AccountCompany) {
        return new Promise((resolve, reject) => {
            this.http
                .put(environment.api.base + '/accounts-companies/' + dataForm.id, dataForm)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    console.error("error sign-in: ", error);
                    reject(error.error);
                });
        });
    }


    /**
     * Metodo para registrar una cuenta de empresa
     *
     * @param dataForm
     * @returns
     */
    creditcardAccountCompany(dataForm: PaymentProfile) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/accounts-companies/' + dataForm.account_company_id + '/account', dataForm)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    console.error("error sign-in: ", error);
                    reject(error.error);
                });
        });
    }



    /**********************************
     * CODIGO para cierre de session **
     **********************************/

    /**
     * Permite cerrar sesion en el backend
     * @returns
     */
    logout() {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/logout', {})
                .subscribe((response) => {
                    this.closeSession();
                    resolve(true);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

    /**
     * Permite cerrar sesion en el frontend
     */
    closeSession() {

        localStorage.clear();
        this.session = null;
        this.workspaceSession = null;

        // validamos que este conectado de lo contrario rompera el funcionamiento del logout
        if(this.clientSocketsService.isConnected()){
            this.clientSocketsService.disconnect();
        }

        this.onChange.emit(false);

    }





    /*****************************************
     * CODIGO para verificacion de permisos **
     *****************************************/

    /**
     * Permite verificar si el usuario logueado contiene una capacidad en workspace
     *
     * @param permision
     * @returns
     */
    hasPermissionWorkspace(permission: string): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            return this.workspaceSession.workspace.plan == null || this.workspaceSession.workspace.plan.workspace_capabilities.includes(permission);
        }

        // verificamos que ya tenga acceso al workspace
        if (this.workspaceSession.employee_workspace_permissions == null || this.workspaceSession.employee_workspace_permissions.role.capabilities.length == 0) {
            return false;
        }

        // verificamos si dentro de las capacidades asignadas esta la capacidad a verificar
        return this.workspaceSession.employee_workspace_permissions.role.capabilities.includes(permission) &&
        (this.workspaceSession.workspace.plan == null || this.workspaceSession.workspace.plan.workspace_capabilities.includes(permission));
    }

    /**
     * Permite verificar si el usuario logueado contiene uno de las capacidades pasadas en el arguemento
     *
     * @param permissions
     * @returns
     */
    hasPermissionsWorkspace(permissions: Array<string>): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            if (this.workspaceSession.workspace.plan == null) {
                return true;
            } else {
                for (const capabilityToCheck of permissions) {

                    // verificamos si el permiso existe
                    const hasPermission = this.workspaceSession.workspace.plan.workspace_capabilities.includes(capabilityToCheck);

                    // comprobamos
                    if (hasPermission) {
                        return true;
                    }
                }
            }
        }

        // verificamos que ya tenga acceso al workspace
        if (this.workspaceSession.employee_workspace_permissions == null || this.workspaceSession.employee_workspace_permissions.role.capabilities.length == 0) {
            return false;
        }

        // recorremos los registros y verificamos
        for (const capabilityToCheck of permissions) {

            // verificamos si el permiso existe
            const hasPermission = this.workspaceSession.employee_workspace_permissions.role.capabilities.includes(capabilityToCheck) &&
            (this.workspaceSession.workspace.plan == null || this.workspaceSession.workspace.plan.workspace_capabilities.includes(capabilityToCheck));;

            // comprobamos
            if (hasPermission) {
                return true;
            }
        }

        return false;
    }

    /**
     * Permite verificar si el usuario logueado contiene una capacidad en la compañia
     * @param permission
     * @returns
     */
    hasPermissionCompany(permission: string): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            return this.workspaceSession.workspace.plan == null || this.workspaceSession.workspace.plan.company_capabilities.includes(permission);
        }

        // verificamos que ya tenga acceso al workspace
        if (this.workspaceSession.employee_company_permissions == null || this.workspaceSession.employee_company_permissions.role.capabilities.length == 0) {
            return false;
        }


        // verificamos si dentro de las capacidades asignadas esta la capacidad a verificar
        return this.workspaceSession.employee_company_permissions.role.capabilities.includes(permission)
            && (this.workspaceSession.workspace.plan == null || this.workspaceSession.workspace.plan.company_capabilities.includes(permission));
    }

    /**
     * Permite verificar si el usuario logueado contiene uno de las capacidades pasadas en el arguemento
     *
     * @param permissions
     * @returns
     */
    hasPermissionsCompany(permissions: Array<string>): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            if (this.workspaceSession.workspace.plan == null) {
                return true;
            } else {
                // recorremos los registros y verificamos
                for (const capabilityToCheck of permissions) {

                    // verificamos si el permiso existe
                    const hasPermission = this.workspaceSession.workspace.plan.company_capabilities.includes(capabilityToCheck);

                    // comprobamos
                    if (hasPermission) {
                        return true;
                    }
                }
            }
        }

        // verificamos que ya tenga acceso al workspace
        if (this.workspaceSession.employee_company_permissions == null || this.workspaceSession.employee_company_permissions.role.capabilities.length == 0) {
            return false;
        }

        // recorremos los registros y verificamos
        for (const capabilityToCheck of permissions) {

            // verificamos si el permiso existe
            const hasPermission = this.workspaceSession.employee_company_permissions.role.capabilities.includes(capabilityToCheck) &&
            (this.workspaceSession.workspace.plan == null || this.workspaceSession.workspace.plan.company_capabilities.includes(capabilityToCheck));

            // comprobamos
            if (hasPermission) {
                return true;
            }
        }

        return false;
    }

    /**
     * Permite verificar si el usuario logueado contiene una capacidad en workspace
     *
     * @param permision
     * @returns
     */
    hasJobPosition(jobPosition: string): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            return true;
        }

        // verificamos que ya tenga acceso al workspace
        if (this.workspaceSession.employee.job_position == null) {
            return false;
        }

        // verificamos si dentro de las capacidades asignadas esta la capacidad a verificar
        return jobPosition == this.workspaceSession.employee.job_position;
    }

    /**
     * Permite verificar si el usuario logueado contiene uno de las capacidades pasadas en el arguemento
     *
     * @param permissions
     * @returns
     */
    hasJobPositions(jobPositions: Array<string>): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            return true;
        }

        // verificamos que ya tenga acceso al workspace
        if (this.workspaceSession.employee.job_position == null) {
            return false;
        }

        // hacemos la ultima validacion de revisar si dentro del arreglo recibido
        // se encuentra el job positino que tiene el empleado loguead
        return jobPositions.includes(this.workspaceSession.employee.job_position);
    }

    /**
     * permite verificar si un usuario logueado tiene acceso a una compañia
     * @param companyId
     */
    hasAccessToCompany(companyId: string): boolean {

        // verificamos si la variable es null, esto por que ella es la que contiene la informacion del empleado sobre el workspace en la session
        if (this.workspaceSession === null) {
            return false;
        }

        // verificamos si es el duseño del workspace
        if (this.workspaceSession.is_owner) {
            return true;
        }

        // verificamos que el area de compañias este asignado
        if (this.workspaceSession.employee_companies_permissions.length == 0) {
            return false;
        }

        // iteramos sobre las compañias que tiene acceso
        for (const permision of this.workspaceSession.employee_companies_permissions) {

            // comparamos compañia a compañia si tiene acceso a la compañia solicitada
            if(permision.company_id == companyId){
                return true;
            }
        }

        // si llega aca es por que no tiene acceso a la compañia solicitada
        return false;
    }

    /**
     * Permite establecer a la sesion actual la informacion y los permisos de una compañia, que tenga el usuario
     * @param company
     * @returns
     */
    setCompanySession(company: Company): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // creamos variable
        let found: EmployeeCompanyPermission = null;

        // iteramos para dejar seleccionado los permisos de la compañia
        for (const companyPermission of this.workspaceSession.employee_companies_permissions) {

            // verificamos que sean los permisos de la compañia
            if (company.id === companyPermission.company_id) {

                // asignamos los permisos equivalentes
                found = companyPermission;
                break;
            }
        }

        // en caso de que la session actual no sea owner y no se haya encontrado permissions
        // devolvemos un false
        if (found == null && !this.workspaceSession.is_owner) {
            return false;
        }

        // asignamos la compañia
        this.workspaceSession.company = company;

        // si no fue encontrado los permisos para la compañia
        if (found !== null) {
            this.workspaceSession.employee_company_permissions = found;
        }

        // guardamos cambios
        this.save();

        return true;
    }

    /**
     * Permite establecer en la session una compañia, pasandole el id de la compañia
     *
     * @param companyId
     * @returns
     */
    setCompanySessionByCompanyId(companyId: string): boolean {

        // verificamos si la variable es null
        if (this.workspaceSession === null) {
            return false;
        }

        // si la commpañia que se deasea cargar en la sesion, ya esta cargada no se hace nada y se indica que se hizo
        if (this.workspaceSession.company !== null) {
            if (this.workspaceSession.company.id === companyId) {
                return true;
            }
        }

        // variable para establecer en ella la compañia que se esta recibiendo por parametro despues de ser encontrada
        let found: Company;

        // iteramos todas las compañias a las que puede acceder el usuario
        for (const company of this.workspaceSession.companies) {

            // comparamos ocn la recibida
            if (company.id === companyId) {

                // indicamo su que la compañia fue encontrada
                found = company;
                break;
            }
        }

        // si no fue encontrada es por que la compañia no hace parte de las que tiene el permiso logueado para ingresar en ella
        if (found == null) {
            return false;
        }

        // establecemos la compañia y devolvemos el resultado
        return this.setCompanySession(found);
    }




    /**********************
     * CODIGO DAVIVIENDA **
     **********************/

    /**
     * Permite obtner la direccion  IP, con un tercero
     * @returns
     */
    getPublicIp() {
        // return this.http.get('https://ipapi.co/json/').toPromise();
        // return this.http.get('http://checkip.amazonaws.com/').toPromise();
        // return this.http.get('https://www.cloudflare.com/cdn-cgi/trace').toPromise();
        return this.http.get('https://api.ipify.org?format=json&callback=?').toPromise();
    }

    getWorkspaceSession() {
        return this.workspaceSession;
    }
}
