import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storage-invoices-form',
  templateUrl: './storage-invoices-form.component.html',
  styleUrls: ['./storage-invoices-form.component.scss']
})
export class StorageInvoicesFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
