import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PaymentGatewaySetting } from "src/app/entities/workspace/payment-gateway-setting";

@Injectable({
    providedIn: 'root'
})
export class PaymentGatewaySettingsService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<PaymentGatewaySetting>> {
        return this.http.get<Array<PaymentGatewaySetting>>(environment.api.company + '/payments-gateway-settings').toPromise();
    }

    getById(id: string) {
        return this.http.get<PaymentGatewaySetting>(environment.api.company + '/payments-gateway-settings/' + id).toPromise();
    }

    toggle(id: string, paymentType: string) {
        return this.http.post(environment.api.company + '/payments-gateway-settings/' + id + '/toggle/' + paymentType, {}).toPromise();
    }

    disabledAch(id: string, paymentType: string) {
        return this.http.post(environment.api.company + '/payments-gateway-settings/' + id + '/disabled/' + paymentType, {}).toPromise();
    }
    
    setStrageCC(id: string, storage_cc: boolean) {
        const status = storage_cc ? 1:0;
        return this.http.post(environment.api.company + '/payments-gateway-settings/' + id + '/storage_cc/' + status, {}).toPromise();
    }

    save(dataForm: PaymentGatewaySetting): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: PaymentGatewaySetting) {
        return this.http.post(environment.api.company + '/payments-gateway-settings', dataForm).toPromise();
    }

    edit(dataForm: PaymentGatewaySetting) {
        return this.http.put(environment.api.company + '/payments-gateway-settings/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/payments-gateway-settings/' + id).toPromise();
    }
}
