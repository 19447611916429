import { environment } from 'src/environments/environment';
import { EstimateMaterialView } from 'src/app/entities/workspace/estimate-material-view';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PackingMaterialsService {

    constructor(private http: HttpClient) { }

    getMaterialsByEstimateId(estimateId: string): Promise<Array<EstimateMaterialView>> {
        return this.http.get<Array<EstimateMaterialView>>(environment.api.company + '/estimates/' +
            estimateId + '/materials').toPromise();
    }

    save(dataForm, estimateId: string) {
        return this.http.post<Array<EstimateMaterialView>>(environment.api.company +
            '/estimates/' + estimateId + '/materials', dataForm).toPromise();
    }

    getMaterialsByJobId(estimateId: string): Promise<Array<EstimateMaterialView>> {
        return this.http.get<Array<EstimateMaterialView>>(environment.api.company + '/jobs/' +
            estimateId + '/materials').toPromise();
    }

    saveJob(dataForm, estimateId: string) {
        return this.http.post<Array<EstimateMaterialView>>(environment.api.company +
            '/jobs/' + estimateId + '/materials', dataForm).toPromise();
    }
}
