import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Volume } from 'src/app/entities/workspace/volume';




@Injectable({
    providedIn: 'root'
})
export class VolumesService {

    constructor(private http: HttpClient) { 
        
    }

    getAll(tariffVersionId: string): Promise<Array<Volume>> {
        return this.http.get<Array<Volume>>(environment.api.workspace + '/volumes-by-tariff-version/' + tariffVersionId).toPromise();
    }

    getAllByTariffVersionId(tariffVersionId): Promise<Array<Volume>> {
        return this.http.get<Array<Volume>>(environment.api.workspace + '/volumes/tariff-version-id/' + tariffVersionId).toPromise();
    }

    getById(id: string) {
        return this.http.get<Volume>(environment.api.workspace + '/volumes/' + id).toPromise();
    }

    save(dataForm: Volume): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Volume) {
        return this.http.post(environment.api.workspace + '/volumes', dataForm).toPromise();
    }

    edit(dataForm: Volume) {
        return this.http.put(environment.api.workspace + '/volumes/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/volumes/' + id).toPromise();
    }
}
