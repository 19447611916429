import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { TariffVersion } from 'src/app/entities/workspace/tariff-version';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionsService } from 'src/app/services/workspaces/tariff-versions.service';
declare const jQuery: any;
declare const swal: any;

@Component({
  selector: 'app-tariff-versions-form',
  templateUrl: './tariff-versions-form.component.html',
  styleUrls: ['./tariff-versions-form.component.scss']
})
export class TariffVersionsFormComponent implements OnInit {

  public dataForm: TariffVersion;
  private constantsMessages = ConstantsMessages;
  constructor(
    private tariffVersionsService: TariffVersionsService,
    private helperService: HelperService,
    private currentRoute: ActivatedRoute
  ) {
    this.dataForm = new TariffVersion();
    this.dataForm.is_active = false;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.currentRoute.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
            this.load(params.id);
        }
    });
  }

  load(id: string): void {
    this.helperService.showLoadingMxpro360();
    this.tariffVersionsService.getById(id)
    .then(version => {
      this.dataForm = version
    })
    .catch(error => {
      const message = error.error ? error.error.message : error.message;
      swal(
        'ups!',
        message,
        'error'
    );
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  save(): void {
    
    this.helperService.showLoadingMxpro360();
      this.tariffVersionsService
      .save(this.dataForm)
      .then(() => {
          this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
          this.helperService.goToWorkspaceRouterLink('/tariff-versions');
      })
      .catch((error) => {
          this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
          console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
}

  setDefault() {
    this.dataForm.is_active = !this.dataForm.is_active;
  }

}
