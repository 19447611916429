import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from '../../../constants-messages';
import { Customer } from 'src/app/entities/workspace/customer';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-estimates-form',
    templateUrl: './estimates-form.component.html',
    styleUrls: ['./estimates-form.component.scss']
})
export class EstimatesFormComponent implements OnInit {

    public advertiser: string;
    public advertisers: Advertiser[];
    public brokers: Carrier[];
    public blockButton: boolean;
    public customer: Customer;
    public customers: Customer[];
    public estimate: Estimate;
    public searchCustomer: string;
    public searchField: string;
    public validForm: boolean;
    
    @ViewChild('contactsModal') contactsModal: ElementRef;

    constructor(
        private advertisersService: AdvertisersService,
        private carriersService: CarriersService,
        private customersService: CustomersService,
        private estimatesService: EstimatesService,
        private settingsService: SettingsService,
        public helperService: HelperService,
        public tariffVersionFactory: TariffVersionService,
    ) {
        this.advertiser = '';
        this.advertisers = [];
        this.brokers = [];
        this.blockButton = false;
        this.customer = new Customer();
        this.customers = [];
        this.estimate = new Estimate();
        this.searchCustomer = '';
        this.searchField = '';
        this.validForm = false;
    }

    ngOnInit(): void {
        this.tariffVersionFactory.getTariffVersionActive();
        this.advertisersService.getAll()
        .then((advertisers) => {
            this.advertisers = advertisers;
        })
        this.carriersService.getAll()
        .then((response) => {
            this.brokers = response;
        });
    }

    save() {
        console.log('this.estimate => ', this.estimate);
        if (this.blockButton) {
            return;
        }
        this.blockButton = true;
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .CreateEstimate(this.estimate, this.customer, this.advertiser)
            .then(response => {
                this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/moving/' + response['row_id'] + '/estimate');
            })
            .catch((error) => {
                //this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                swal(
                    'Atention',
                    error.error ? error.error.message: error.message,
                    'error'
                );
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.validForm = true;
                this.blockButton = false;
            });
    }

    changeMoveDate(date) {
        this.validateForm();
    }

    setFrom(from) {
        this.estimate.pickup.address = from;
        this.validateForm();
    }
    setTo(to) {

        this.estimate.delivery.address = to;
        this.validateForm();
    }

    validateForm() {
        // Valida que en el campo de phone solo se ingresen numeros
        setTimeout(() => {
            this.customer.phone = this.customer.phone === null ? '' : this.customer.phone.replace(/\D */g, '');
        },
            1);
        this.validForm = !(this.customer.name == null ||
            this.customer.email == null ||
            this.customer.phone == null ||
            this.estimate.delivery.address.zip_code == null);
    }

    openContactsModal() {
        // segundo
        jQuery(this.contactsModal.nativeElement).modal('show');
    }

    closeContactsModal() {
        jQuery(this.contactsModal.nativeElement).modal('hide');
    }

    loadCustomers() {
        if (this.searchCustomer.length >= 3) {
            this.customersService.getFiltered(this.searchCustomer).then(responseCustomer => {
                this.customers = responseCustomer;
            });
        }
    }

    setNewContct(item) {
        jQuery(this.contactsModal.nativeElement).modal('hide');
        this.customer = item;
    }

    changePickUp(date) {
        this.helperService.changesBeforeSave = true;
        this.estimate.pickup.range_start = date[0];
        this.estimate.pickup.range_end = date[1];
    }
}
