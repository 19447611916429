<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="company.id == null">
                        New
                    </span>
                    <span *ngIf="company.id != null">
                        Update
                    </span> Company
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                                Dashboard</a>
                        </li>
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/companies">Companies</a></li>
                        <li *ngIf="company.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="company.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
                <form class="form" #entityForm="ngForm" (ngSubmit)="saveCompany()" ngNativeValidate>
                    <div class="row">
                        <div class="col-12">


                            <div class="form-group mb-1">
                                <label class="control-label">Name:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="company.name" name="wd_name" class="form-control" required>
                                </div>

                                <small id="nameHelp" class="form-text text-muted mb-4">Company Name.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label">Email:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg></span>
                                    </div>
                                    <input type="email" [(ngModel)]="company.email" name="wd_email" class="form-control" required>
                                </div>

                                <small id="emailHelp" class="form-text text-muted mb-4">Company Email.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label">Phone:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></span>
                                    </div>
                                    <input type="tel" onlyNumbers minlength="10" maxlength="10" required
                                    [(ngModel)]="company.phone" name="wd_phone" class="form-control">
                                </div>

                                <small id="phoneHelp1" class="form-text text-muted mb-4">Company Phone.</small>
                            </div>


                            <div class="form-group mb-1">
                                <label class="control-label">DOT:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg></span>
                                    </div>
                                    <input type="text" onlyNumbers maxlength="10" [(ngModel)]="company.dot" name="wd_dot" class="form-control">
                                </div>

                                <small id="dotHelp1" class="form-text text-muted mb-4">Company DOT.</small>
                            </div>
                            
                            <div class="form-group mb-1">
                                <label class="control-label">Website:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg></span>
                                    </div>
                                    <input type="url" [(ngModel)]="company.website" name="wd_website" class="form-control">
                                </div>

                                <small id="websiteHelp1" class="form-text text-muted mb-4">Company Website.</small>
                            </div>
                            <div class="form-group mb-1">
                                <label class="control-label">license:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg></span>
                                    </div>
                                    <input type="text" onlyNumbers maxlength="10" [(ngModel)]="company.license" name="wd_license" class="form-control" required>
                                </div>

                                <small id="licenseHelp1" class="form-text text-muted mb-4">Company License.</small>
                            </div>

                        </div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-warning ml-1 mt-3" type="submit" appCanUserWorkspace="COMPANIES_CREATE">
                            <i class="far fa-save" style="height: 24px;"></i> &nbsp; Submit
                        </button>
                    </div>

                </form>
            </div>
        </div>

    </div>
</div>