import { Document } from './document';
import { CarrierDriver } from './carrier-driver';
import { DriverInvoiceItemView } from './driver-invoice-item-view';
export class DriverInvoice {

    id: string;
    date: number;
    company_id: number;
    document: Document;
    allocation: 'CARRIER' | 'IN_HOUSE';
    carrier_id: string;
    carrier_driver: CarrierDriver;
    dispatcher_id: string;
    employee_driver_id: string;
    total_amount: number;
    total_job_balance: number;
    pads_pickup: number;
    pads_dropped: number;
    remaining_pads: number;
    total_cf: number;
    total_cf_charges: number;
    total: number;
    comments: string;
    is_paid: boolean;
    load_date: number;
    items: Array<DriverInvoiceItemView>;
    display_storage_location: boolean;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.date = null;
        this.company_id = null;
        this.document = null;
        this.allocation = null;
        this.carrier_id = null;
        this.carrier_driver = new CarrierDriver();
        this.dispatcher_id = null;
        this.employee_driver_id = null;
        this.total_amount = null;
        this.total_job_balance = null;
        this.pads_pickup = null;
        this.pads_dropped = null;
        this.remaining_pads = null;
        this.total_cf = null;
        this.total_cf_charges = null;
        this.total = null;
        this.comments = null;
        this.is_paid = null;
        this.load_date = null;
        this.items = [];
        this.display_storage_location = false;
        this.created = null;
        this.updated = null;
    }
}
