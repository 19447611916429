export class StorageCompanyContact {
    id: number;
    name: string;
    phone: string;
    email: string;
    storage_company_id: string;
    main: boolean;
    created: Date;
    updated: Date;

    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.storage_company_id = null;
        this.main = false;
        this.created = null;
        this.updated = null;
    }
}
