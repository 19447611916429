import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';


declare const jQuery;

@Directive({
    selector: '[appCanUserWorkspace]'
})
export class CanUserWorkspaceDirective implements AfterViewInit {


    @Input()
    appCanUserWorkspace: string | Array<string>;

    // variable que indica si se esta mostrando o ocultando el elemento
    status: "VISIBLE" | "HIDDEN";


    constructor(
        private authService: AuthService,
        private element: ElementRef
    ) {
        this.appCanUserWorkspace = null;
        this.status = "VISIBLE";
    }

    /**
     * Metodo proveniente del ciclo de de vida de los elemento(componentes) en angular
     */
    ngAfterViewInit(): void {

        // ejecutamos inicialmente el estado de los permisos
        this.checkPermissions();

        // creamos un listen para cuando cambie la sesion
        this.authService
            .onChange
            .subscribe((isLogged: boolean) => {
                if(isLogged) {
                    this.checkPermissions();
                }else{
                    this.hideElement();
                }
            });

    }


    /**
     * Oculta el elemento del HTML
     */
    private hideElement(): void {

        // verificamos el estado del elemnto,
        // si ya esta oculto no hacemos nada
        if (this.status === "HIDDEN") {
            return;
        }

        // actualizamos el estado
        this.status = "HIDDEN";

        // ocultamos con jquery, esto deberiamos mirar como lo podemos ocultar usando un extend de *ngIf
        jQuery(this.element.nativeElement).css({
            display: 'none'
        });
    }

    /**
     * Muestra el elemento del HTML
     */
    private showElement(): void {

        // verificamos el estado del elemnto,
        // si ya esta oculto no hacemos nada
        if (this.status === "VISIBLE") {
            return;
        }

        // actualizamos el estado
        this.status = "VISIBLE";

        jQuery(this.element.nativeElement).css({
            display: 'block'
        });
    }

    /**
     * Permite verificar los permisos recibidos en la sesion del usuario,
     * y dependiendo de ello oculta o muestra el elemento actualizamos
     * 
     * @returns 
     */
    private checkPermissions(): void {

        // si no se ha recibido permisos paramos el proceso
        if (this.appCanUserWorkspace === null) {
            return;
        }

        // verificamos si se recibio un arreglo
        if (Array.isArray(this.appCanUserWorkspace)) {

            // si tiene el permiso solicitado dejamos visible el elemento
            if (this.authService.hasPermissionsWorkspace(this.appCanUserWorkspace)) {
                this.showElement();
            } else {
                this.hideElement();
            }

        } else {
            // si tiene el permiso solicitado dejamos visible el elemento
            if (this.authService.hasPermissionWorkspace(this.appCanUserWorkspace)) {
                this.showElement();
            } else {
                this.hideElement();
            }
        }

    }



}
