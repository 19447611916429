export class CampaignFilter {
    public id: string;
    public from_state: string;
    public to_state: string;
    public from_email: string; // id del empleado que enviara el email, vacio es company
    public employee_id: string;
    public service: '' | 'LOCAL' | 'LONG' | 'AUTO_TRANSPORT';
    public status: '' | 'FOLLOW_UP' | 'UNSUCCESSFUL_SALE' | 'IN_OPERATION' | 'WORK_CARRIED_OUT' | 'CUSTOMER_CANCELED' | 'NEW' | 'IN_ESTIMATION' | 'DEAD_LEAD';
    public category: '' | 'OPEN' | 'HOT' | 'WARM' | 'COLD' | 'NEW' | 'SCHEDULED' | 'BAD';
    public range_start: number;
    public range_end: number;
    public range_target: 'MOVE_DATE' | 'OPEN';

    constructor() {
        this.id = null;
        this.from_state = '';
        this.to_state = '';
        this.from_email = '';
        this.employee_id = '';
        this.service = '';
        this.status = '';
        this.category = '';
        this.range_start = null;
        this.range_end = null;
        this.range_target = null;
    }
}
