import { Meta } from "../global/meta";
import { TariffAdditionalConcept } from "./tariff-additional-concept";

export class EstimateAdditionalCharge {
    id: string;
    name: string;
    amount: number;
    type: 'PICKUP' | 'DELIVERY' | 'NOT_APPLY';
    tariff_additional_concept_id: string;
    tariff_additional_concepts: TariffAdditionalConcept;
    estimate_id: string;
    metas: Array<Meta>;
    edit?: boolean;

    constructor() {
        this.id = null;
        this.name = null;
        this.amount = null;
        this.type = 'NOT_APPLY';
        this.tariff_additional_concept_id = null;
        this.tariff_additional_concepts = new TariffAdditionalConcept();
        this.estimate_id = null;
        this.metas = [];
        this.edit = false;
    }
}
