import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

declare var ApexCharts;
@Component({
  selector: 'app-total-estimates-by-salesman',
  templateUrl: './total-estimates-by-salesman.component.html',
  styleUrls: ['./total-estimates-by-salesman.component.scss']
})
export class TotalEstimatesBySalesmanComponent implements OnInit {
    jobsTotalEstimateBySales: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) {
    this.jobsTotalEstimateBySales = [];

   }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId) {
        this.loadTotalEstimateBySalesCompany();
    } else {
        this.loadTotalEstimateBySalesWorkspace();
    }
  }

  
  loadTotalEstimateBySalesWorkspace(){
        
    this.dashboardWorkspaceService
        .getTotalEstimateBySales()
        .then((response) => {
            this.jobsTotalEstimateBySales = response;
            setTimeout(() => {
                this.charTotalEstimateBySales();
            }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadTotalEstimateBySalesCompany(){
        
    this.dashboardComopanyService
        .getTotalEstimateBySales()
        .then((response) => {
            this.jobsTotalEstimateBySales = response;
            setTimeout(() => {
                this.charTotalEstimateBySales();
            }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


charTotalEstimateBySales() {
    for (let index = 0; index < this.jobsTotalEstimateBySales.length; index++) {
    const sBar = {
        chart: {
            height: 250,
            type: 'bar',
            toolbar: {
              show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        series: this.jobsTotalEstimateBySales[index].reportData.series,
        xaxis: this.jobsTotalEstimateBySales[index].reportData.xaxis
    }
    
    const chart = new ApexCharts(
        document.querySelector("#s-bar" + index),
        sBar
    );
    
    chart.render();
    }
}

}
