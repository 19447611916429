export class GeneralSettingsleadRotation {
    public id: string;
    public employee_id: number;
    public quantity: number;
    public start_time: number;
    public end_time: number;
    public company_id: number;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.employee_id = null;
        this.quantity = null;
        this.start_time = null;
        this.end_time = null;
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
