import { Component, OnInit } from '@angular/core';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';
import { environment } from 'src/environments/environment';
declare const swal;

@Component({
  selector: 'app-phone-ring-groups',
  templateUrl: './phone-ring-groups.component.html',
  styleUrls: ['./phone-ring-groups.component.scss']
})
export class PhoneRingGroupsComponent implements OnInit {


  public rows: Array<PhoneRingGroup>;
  public actualPage: number;
  public workspaceId: string;
  readonly environment = environment;
  public extensionsCopy: any[];

  constructor(
    private helperService: HelperService,
    private authService: AuthService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private phoneExtensionsService: PhoneExtensionsService,
    ) {
    this.extensionsCopy = [];

   }

  ngOnInit(): void {
    this.load();
    this.loadExtensionsCopy();
    this.workspaceId = '';
  }


  private load(){
    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService.getAllCompany().then((response) => {
      this.rows = response;
      this.workspaceId = this.authService.workspaceSession.workspace.id
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };

  remove(id: string): void{
    swal({
      title: 'Are you sure?',
      text: "Are you sure do you want to delete this record?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result: any) => {
      if (result.value) {
        this.helperService.showLoadingMxpro360();
        this.phoneRingGroupsService
          .remove(id)
          .then((response) => {

            console.log('aqui eliminando ', response);

            this.load();
            swal(
              'Deleted!',
              'Record has been deleted.',
              'success'
            );
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
    })
  }


  public loadExtensionsCopy() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllExtensions()
        .then((response) => {
          this.extensionsCopy = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  validateExtension(phone: string): string {

    for (const ext of this.extensionsCopy) {
        if (ext.id == phone){
          return ext.extension;
        }
    }
    return phone;
  }

}
