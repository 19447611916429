import { File } from "../global/file";

export class Expense {
    id: string;
    amount: number;
    comments: string;
    company_id: string;
    concept: string;
    employee_id: string;
    is_delivery_cost: boolean;
    job_id: string;
    payment_date: number;
    payment_method: string;
    status: 'PENDING' | 'PAID';
    target: 'WORKERS' | 'CARRIER' | 'WAREHOUSE' | 'FUEL' | 'DELIVERY_AGENT';
    vouchers: Array<File>;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.amount = null;
        this.comments = null;
        this.company_id = null;
        this.concept = null;
        this.employee_id = null;
        this.is_delivery_cost = null;
        this.job_id = null;
        this.payment_date = null;
        this.payment_method = null;
        this.status = null;
        this.target = null;
        this.vouchers = [];
        this.created = null;
        this.updated = null;
    }
}

