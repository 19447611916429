<div class=" layout-top-spacing">
    <div class="widget-content widget-content-area">

        <div class="d-flex justify-content-between widget-heading">

            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center">
                <h3 class="title-content-area m-0">
                    Quick Estimate
                </h3>
            </div>

            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 text-sm-right text-center align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">

                    <div class="btn-group dropleft" role="group">
                        <div class="toggle-switch">
                            <label class="switch s-icons s-outline s-outline-secondary">
                                <input type="checkbox" id="check_full_packing" name="check_full_packing"
                                [(ngModel)]="fullPackingOn" (click)="toggleFullPacking()">
                                <span class="slider round">
                                    <i class="fa-solid fa-check icon-left"></i>
                                    <i class="fa-solid fa-xmark icon-right"></i>
                                </span>
                            </label>
                            <label for="check_full_packing" class="checkbox-label" [ngClass]="{'filter-disabled': !fullPackingOn}">
                                Full Packing
                            </label>
                        </div>
                        <button id="btnOutline" type="button" class="btn btn-success btn-add-room dropdown-toggle" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Add Room
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-chevron-down">
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </button>
                        <div class="dropdown-menu dropdown-menu-add-room overflow-default" aria-labelledby="btnOutline">
                            <ng-container  *ngFor="let roomCategory of roomCategories">
                                <a href="javascript:void(0);" class="dropdown-item" (click)="openAddRoomModal(roomCategory)" *ngIf="roomCategory.is_custom">
                                    {{roomCategory.name}}
                                </a>
                            </ng-container>
                            <div>
                                <hr class="dropdown-divider">
                            </div>
                            <ng-container  *ngFor="let roomCategory of roomCategories">
                                <a href="javascript:void(0);" class="dropdown-item" (click)="openAddRoomModal(roomCategory)" *ngIf="!roomCategory.is_custom">
                                    {{roomCategory.name}}
                                </a>
                            </ng-container>
                        </div>
                    </div>

                    <button class="btn btn-save" (click)="save()">
                        Save
                    </button>

                </div>
            </div>

        </div>
        
    </div>
</div>
<!-- header -->



<div class=" layout-top-spacing" *ngIf="localInventoryRooms.length > 0">
    <div class="widget-content widget-content-area">

        <div class="task-list-section">
            <div class="task-list-container" data-section="s-in-progress" data-connect="sorting">

                <ng-container *ngFor="let inventoryRoom of localInventoryRooms; let i = index">

                    <div class="connect-sorting">

                        <div class="task-container-header">

                            <h6 class="s-heading">
                                {{inventoryRoom.local_room_category.name | titlecase}} ({{inventoryRoom.furniture_level | titlecase}})
                            
                                <ng-container *ngIf="inventoryRoom.is_packing">
                                    CP
                                </ng-container>
                            </h6>

                            <div class="dropdown">
                                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink-1"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-more-horizontal">
                                        <circle cx="12" cy="12" r="1"></circle>
                                        <circle cx="19" cy="12" r="1"></circle>
                                        <circle cx="5" cy="12" r="1"></circle>
                                    </svg>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <a class="dropdown-item list-delete remove-room cursor-pointer" (click)="deleteRoom(i)">
                                        Delete
                                    </a>
                                </div>
                            </div>

                        </div>

                        <ul id="sortable_i" class="connectedSortable" attr.container="i">

                            <ng-container  *ngFor="let closets of inventoryRoom.closets">

                                <li class="ui-state-default" attr.indexContainer="i" attr.idItem="i">                                 
                                    <div class="card-body">

                                        <div class="task-header">
                                            <h4>
                                                {{closets.local_room_closet.name}}   
                                                <ng-container *ngIf="closets.is_packing">
                                                    CP
                                                </ng-container>                                
                                            </h4>
                                        </div>
                                        <div class="task-body">
                                            <div class="task-bottom color-text-inventory">
                                                <div class="tb-section-1 d-flex">
                                                    <span>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-layers">
                                                            <polygon _ngcontent-yhr-c84=""
                                                                points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                                            <polyline _ngcontent-yhr-c84="" points="2 17 12 22 22 17">
                                                            </polyline>
                                                            <polyline _ngcontent-yhr-c84="" points="2 12 12 17 22 12">
                                                            </polyline>
                                                        </svg>

                                                        <span class="closet-capacity-level pl-3">{{closets.capacity_level | titlecase}}</span>

                                                        <span *ngIf="closets.capacity_level == 'MINIMAL'">
                                                            ({{closets.local_room_closet.minimal}})
                                                        </span>
                                                        <span *ngIf="closets.capacity_level == 'MODERATE'">
                                                            ({{closets.local_room_closet.moderate}})
                                                        </span>
                                                        <span *ngIf="closets.capacity_level == 'FULL'">
                                                            ({{closets.local_room_closet.full}})
                                                        </span>

                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>                                

                            </ng-container>

                        </ul>

                        <div class="add-s-task" (click)="editRoom(inventoryRoom)">
                            <a class="edit-room">
                                <i class="fa-solid fa-pen-to-square"></i>
                                <span class="pl-2">Edit Room</span>                                
                            </a>
                        </div>

                    </div>

                </ng-container>

            </div>
        </div>
    </div>
</div>
<!-- body -->



<!-- Modal Inventory Items Local -->
<div class="modal fade" id="addRoomModal" #addRoomModal tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">Add Room</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        
                        <div class="form-group mb-4">
                            <label class="control-label" for="">Room Name</label>
                            <input id="" class="form-control" placeholder="Room Name" name="categoryName" [(ngModel)]="localInventoryRoom.local_room_category.name" disabled>     
                            <small id="emailHelp1" class="form-text text-muted mb-4">Room Name.</small>                        
                        </div>
                    </div>
                    <div class="col-6">
                       
                        <div class="form-group mb-4">
                            <label for="">Furniture Level</label>
                            <select name="" class="form-control"  name="categoryFurnitureLevel" [(ngModel)]="localInventoryRoom.furniture_level">
                                <option value="MINIMAL">Minimal</option>
                                <option value="MODERATE">Moderate</option>
                                <option value="FULL">Full</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Furniture Level.</small>
                        </div>                                   
                    </div>
                    <div class="col-6" *ngIf="localInventoryRoom.local_room_category.is_custom">
                        <div class="form-group mb-4" >
                            <label class="control-label" for="">Room Square Feet</label>
                            <input type="number" class="form-control" placeholder="Room Square Feet" name="categorySquareFeet" [(ngModel)]="localInventoryRoom.square_feet">     
                            <small id="emailHelp1" class="form-text text-muted mb-4">Enter The Square Feet of the Room.</small>                        
                        </div>
                    </div>
                    <div class="col-6">
                        <label for="">Is Packing</label>
                        <div class="toggle-switch mt-2">                
                            <label class="switch s-icons s-outline s-outline-secondary">
                                <input type="checkbox" id="localInventoryRoom_is_packing" name="localInventoryRoom_is_packing" [(ngModel)]="localInventoryRoom.is_packing">
                                <span class="slider round">
                                    <i class="fa-solid fa-check icon-left"></i>
                                    <i class="fa-solid fa-xmark icon-right"></i>
                                </span>
                            </label>
                        </div>  
                    </div>
                </div>

                <!-- Add Closet -->
                <div class="row add-new-closet">
                    <div class="col-10"> 
                        <div class="form-group mb-2">                            
                            <label for="">Add Closet</label>
                            <select name="closetSelected" class="form-control select-add-closet" [(ngModel)]="closetSelected">
                                <option value="" disabled>Select an Option</option>
                                <ng-container  *ngFor="let closet of roomClosets" >
                                    <option  *ngIf="closet.is_custom" value="{{closet.id}}">{{closet.name}}</option>
                                </ng-container>
                                <ng-container  *ngFor="let closet of roomClosets" >
                                    <option  *ngIf="!closet.is_custom" value="{{closet.id}}">{{closet.name}}</option>
                                </ng-container>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted">Select a Closet.</small> 
                        </div>                      
                    </div>

                    <div class="col-2 d-flex justify-content-end align-self-center">
                        <button class="btn btn-info btn-add-closet" (click)="addCloset()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </button>
                    </div>                   
                </div>
                
                <div class="added-closets">
                    <div class="row" *ngFor="let closets of localInventoryRoom.closets; index as key">

                        <div class="col-4">                        
                           
                            <div class="form-group mb-4">
                                <label class="control-label" for="">Closet Name</label>
                                <input id="" class="form-control" placeholder="Closet Name"  name="localRoomClosetscustom" [(ngModel)]="closets.local_room_closet.name" disabled>     
                                <small id="emailHelp1" class="form-text text-muted">Closet Name.</small>                        
                            </div> 
                        </div>

                        <div class="col-4">
                                                         
                            <div class="form-group mb-4">
                                <label for="">Capacity Level</label>
                                <select name="" class="form-control"  name="capacityLevel" [(ngModel)]="closets.capacity_level">
                                    <option value="null" disabled>Select an Option</option>
                                    <option value="MINIMAL">Minimal</option>
                                    <option value="MODERATE">Moderate</option>
                                    <option value="FULL">Full</option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select Capacity Level.</small>
                            </div>                                                         
                        </div>

                        <div class="col-2">
                            <label for="">Is Packing</label>        
                            <div class="toggle-switch mt-2">
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="movinEstimateSignatureTerms" name="movinEstimateSignatureTerms" [(ngModel)]="closets.is_packing">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                            </div>                                             
                        </div>

                        <div class="col-2 d-flex justify-content-end align-self-center">
                            <button class="btn btn-danger button-space btn-remove-closet" (click)="deleteCloset(key)">
                                <i class="fa-solid fa-trash-can"></i>
                            </button> 
                        </div>

                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" type="button" (click)="saveRoom()">
                    Add
                </button>
            </div>
        </div>
    </div>
</div>
