import { Address } from '../global/address';

export class Lead {
    public id: string;
    public advertiser_id: string;
    public customer_id: string;
    public from_address: Address;
    public to_address: Address;
    public service: 'LOCAL' |  'LONG' | 'AUTO_TRANSPORT';
    public move_size: 'STUDIO' |  'LARGE_STUDIO' | 'ONE_BEDROOM' | 'TWO_BEDROOM' | 'THREE_BEDROOM' | 'FOUR_BEDROOM' | 'FIVE_BEDROOM' | 'SIX_BEDROOM';
    public move_date: number;
    public cost: number;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.advertiser_id = null;
        this.customer_id = null;
        this.from_address = new Address();
        this.to_address = new Address();
        this.service = 'LONG';
        this.move_size = null;
        this.move_date = null;
        this.cost = null;
        this.created = null;
        this.updated = null;
    }
}

