import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DailyCrew } from 'src/app/entities/workspace/daily-crew';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DailyCrewService {

    constructor(private http: HttpClient) {

    }

    save(dataForm: DailyCrew): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: DailyCrew) {
        return this.http.post(environment.api.workspace + '/daily-crew', dataForm).toPromise();
    }

    edit(dataForm: DailyCrew) {
        return this.http.put(environment.api.workspace + '/daily-crew/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/daily-crew/' + id).toPromise();
    }
}
