import { Component, OnInit } from '@angular/core';
import { PackingMaterial } from 'src/app/entities/workspace/packing-material';
import { HelperService } from 'src/app/services/helper.service';
import { MaterialsService } from 'src/app/services/workspaces/materials.service';

declare const swal: any;

@Component({
    selector: 'app-packing-materials',
    templateUrl: './packing-materials.component.html',
    styleUrls: ['./packing-materials.component.scss']
})
export class PackingMaterialsComponent implements OnInit {

    public actualPage: number;
    public data: PackingMaterial[];
    public filterSearch: string;
    public optionsSelect: string[];
    public rows: PackingMaterial[];
    public selectedFilter: string; 

    constructor(
        private helperService: HelperService,
        private materialsService: MaterialsService
    ) {
        this.actualPage = 1;
        this.filterSearch = '';
        this.optionsSelect = ['All...', 'AUTO_TRANSPORT', 'FREIGHT_TRANSPORT', 'LOCAL_DISTANCE', 'LONG_DISTANCE'];
        this.rows = [];
        this.selectedFilter = '';
    }

    ngOnInit() {
        this.load();
    }

    ngAfterViewInit() {
        this.load();
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.materialsService
            .getAll()
            .then((response) => {
                this.rows = response;
                this.dataFilter(); // Aplica el filtrado inicial
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
    

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.materialsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    dataFilter() {
        if (this.selectedFilter === 'All...') {
            this.data = this.rows; // Mostrar todos los datos
        } else {
            this.data = this.rows.filter(row => row.service_type.indexOf(this.selectedFilter) > -1);
        }
    }



}
