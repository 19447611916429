import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EstimateMediaFile } from 'src/app/entities/workspace/estimate-media-file';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstimatesMediaFilesService {

  constructor(private http: HttpClient) { }

  getAll(id: string): Promise<Array<EstimateMediaFile>> {
      return this.http.get<Array<EstimateMediaFile>>(environment.api.company + '/estimates/' + id + '/media-files').toPromise();
  }

  add(id: string, dataForm: Array<File>, pdf) {
    if(pdf){
      return this.http.post(environment.api.company + '/estimates/' + id + '/media-files?pdf=true', dataForm).toPromise();
    }
    return this.http.post(environment.api.company + '/estimates/' + id + '/media-files', dataForm).toPromise();
  }
}
