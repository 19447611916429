<div class="widget widget-one_hybrid widget-followers">
    <div class="widget-heading">
        <h5>
            Total Sales in the Last 7 Day
        </h5>            
    </div>
    <div class="widget-content">    
        <div class="text-center">
            <h1>
                {{jobsTotalSalesInTheLast7Day.total_amount |currency}}
            </h1>
            <h4>
                {{jobsTotalSalesInTheLast7Day.total_jobs}} Jobs
            </h4>
        </div>
    </div>
</div>