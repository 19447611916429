<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Document Type
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appCompanyRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/documents-types">Documents Types</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button class="btn btn-main-action" type="button" (click)="save()">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">

            <div class="widget-content widget-content-area">

                <div class="form">

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-1">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Document Type Name.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label">Prefix</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-italic">
                                                <line x1="19" y1="4" x2="10" y2="4"></line>
                                                <line x1="14" y1="20" x2="5" y2="20"></line>
                                                <line x1="15" y1="4" x2="9" y2="20"></line>
                                            </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.prefix" name="wd_prefix" class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Document Type Prefix.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label">Initial Consecutive</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-hash">
                                                <line x1="4" y1="9" x2="20" y2="9"></line>
                                                <line x1="4" y1="15" x2="20" y2="15"></line>
                                                <line x1="10" y1="3" x2="8" y2="21"></line>
                                                <line x1="16" y1="3" x2="14" y2="21"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" [(ngModel)]="dataForm.initial_consecutive" name="wd_initial_consecutive" class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Document Type Initial Consecutive.</small>
                            </div>

                            <div class="form-group md-4">
                                <label for="inputState">Usage Context</label>
                                <select id="inputState" [(ngModel)]="dataForm.usage_context" class="form-control"
                                    name="wd_usage_context" (change)="changeContext()">
                                    <option value="AUTO_TRANSPORT">Auto Transport</option>
                                    <option value="BILL_OF_LADING">Bill Of Lading</option>
                                    <option value="LOCAL_BOL">Local Bill Of Lading</option>
                                    <option value="CARRIER_CONTRACT">Carrier Contract</option>
                                    <option value="CARRIER_INTERLINE">Carrier Interline</option>
                                    <option value="CARRIER_JOB_ACCEPTANCE">Carrier Job Acceptance</option>
                                    <option value="CREDIT_CARD_AUTHORIZATION_CARRIER">Credit Card Authorization Carrier</option>
                                    <option value="CREDIT_CARD_AUTHORIZATION">Credit Card Authorization</option>
                                    <option value="CUSTOMER_JOB_CONTRACT">Customer Job Context</option>
                                    <option value="DRIVER_INVOICE">Driver Invoice</option>
                                    <option value="ESTIMATES">Estimates</option>
                                    <option value="FREIGHT_ESTIMATE">Freight Estimate</option>
                                    <option value="FREIGHT_RATE_CONFIRMATION_SHEET">Freight Rate Confirmation Sheet</option>
                                    <option value="NEW_BINDING_ESTIMATE">New Binding Estimate</option>
                                    <option value="ONLINE_PAYMENTS_VOUCHER">Online Payment Voucher</option>
                                    <option value="SPECIAL_POWER_ATTORNEY">Special Power Attorney</option>
                                    <option value="STORAGE_INVOICE">Storage Invoice</option>
                                    <option value="TICKETS">Tickets</option>
                                    <option value="PPW_PACKET">PPW Packet</option>
                                    <option value="ACH_AUTHORIZATION_FORM">ACH Authorization form</option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select Context.</small>
                            </div>

                            <div class="form-group md-4">
                              <label for="inputState">Request on Digital Pickup</label>
                              <select id="inputState" [(ngModel)]="request_on_pickup" class="form-control" name="request_on_pickup">
                                <option value="YES">Yes</option>
                                <option value="NO">No</option>
                              </select>
                              <small id="emailHelp1" class="form-text text-muted mb-4">Select Request on Digital Pickup.</small>
                            </div>

                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group md-4 terms-conditions-bol dark-bg-document">
                                        <label class="label-bol" for="inputTemplate"
                                            *ngIf="dataForm.usage_context == 'BILL_OF_LADING' || dataForm.usage_context == 'LOCAL_BOL'">TERMS AND CONDITIONS</label>
                                        <label class="label-template" for="inputTemplate"
                                            *ngIf="dataForm.usage_context != 'BILL_OF_LADING' && dataForm.usage_context != 'LOCAL_BOL'">TEMPLATE</label>
                                        <app-ck-editor ckEditorTheme="DOCUMENT" [(ngModel)]="dataForm.template" ngDefaultControl>
                                        </app-ck-editor>
                                    </div>

                                    <div class="row terms-bol" *ngIf="dataForm.metas.length > 0">

                                        <ng-container *ngIf="dataForm.usage_context == 'BILL_OF_LADING'">

                                            <div class="row col-6 col-md-6 block-toggle-sections">

                                                <div class="title-toggle-terms"> Show / Hide Terms</div>

                                                <div class="col-2 col-md-2 text-right pr-0">
                                                    <div class="checkbox check-section-a">
                                                        <input type="checkbox" [(ngModel)]="dataForm.use_payment_terms">
                                                        <label for="click" class="text"></label>
                                                    </div>
                                                </div>

                                                <div class="col-2 col-md-2 text-right pr-0">
                                                    <div class="checkbox check-section-c">
                                                        <input type="checkbox"
                                                            [(ngModel)]="dataForm.use_optional_accesorial_services">
                                                        <label for="click" class="text"></label>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-6 col-md-6 pt-2 block-show-bol">
                                                <button class="btn btn-info" (click)="showPreBol()" *ngIf="dataForm.id">
                                                    <i class="fa-solid fa-file-contract pr-2"></i>
                                                    <span>Show Pre BOL</span>
                                                </button>

                                            </div>

                                        </ng-container>

                                        <ng-container  *ngFor="let meta of dataForm.metas; index as i">

                                            <div class="form-group col-md-12 block-terms">

                                                <ng-container *ngIf="meta.key == 'PAYMENT_TERMS'">
                                                    <ng-container *ngIf="dataForm.usage_context == 'BILL_OF_LADING'">
                                                        <label *ngIf="dataForm.metas.length > 5"
                                                        [ngClass]="{'terms-payments': !dataForm.use_payment_terms}">{{this.arrayNameMetasBillOFLanding[i]}}</label>
                                                    </ng-container>
                                                    <ng-container *ngIf="dataForm.usage_context == 'LOCAL_BOL'">
                                                        <label
                                                        [ngClass]="{'terms-payments': !dataForm.use_payment_terms}">{{this.arrayMetasLocalBillOFLanding[i]}}</label>
                                                    </ng-container>
                                                    <ng-container *ngIf="dataForm.usage_context == 'ESTIMATES'">
                                                        <label *ngIf="dataForm.metas.length < 5"
                                                            [ngClass]="{'terms-payments': !dataForm.use_payment_terms}">
                                                            <ng-container *ngIf="meta.key == 'NO_BINDING'">
                                                                Non-Binding
                                                            </ng-container>

                                                            <ng-container *ngIf="meta.key == 'NO_EXCEED'">
                                                                Not to Exceed
                                                            </ng-container>

                                                            <ng-container *ngIf="meta.key != 'NO_EXCEED' && meta.key != 'NO_BINDING'">
                                                                {{meta.key | filterReplace}}
                                                            </ng-container>
                                                        </label>
                                                    </ng-container>
                                                    <app-ck-editor [(ngModel)]="meta.value" [disabled]="!dataForm.use_payment_terms" ngDefaultControl>
                                                    </app-ck-editor>
                                                </ng-container>

                                                <ng-container *ngIf="meta.key != 'PAYMENT_TERMS'">
                                                    <ng-container *ngIf="dataForm.usage_context == 'BILL_OF_LADING'">
                                                    <label *ngIf="dataForm.metas.length > 5">{{this.arrayNameMetasBillOFLanding[i]}}</label>
                                                    </ng-container>
                                                    <ng-container *ngIf="dataForm.usage_context == 'LOCAL_BOL'">
                                                        <label>{{this.arrayNameMetasLocalBillOFLanding[i]}}</label>
                                                    </ng-container>
                                                    <ng-container *ngIf="dataForm.usage_context == 'ESTIMATES'">
                                                        <label *ngIf="dataForm.metas.length < 5">
                                                            <ng-container *ngIf="meta.key == 'NO_BINDING'">
                                                                Non-Binding
                                                            </ng-container>

                                                            <ng-container *ngIf="meta.key == 'NO_EXCEED'">
                                                                Not to Exceed
                                                            </ng-container>

                                                            <ng-container *ngIf="meta.key != 'NO_EXCEED' && meta.key != 'NO_BINDING'">
                                                                {{meta.key | filterReplace}}
                                                            </ng-container>
                                                        </label>
                                                    </ng-container>
                                                    <app-ck-editor [(ngModel)]="meta.value" ngDefaultControl>
                                                    </app-ck-editor>
                                                </ng-container>

                                            </div>

                                        </ng-container>

                                    </div>

                                </div>
                                <div class="col-md-4">

                                    <br>
                                    <br>
                                    <br>
                                    <div class="keyword-section">

                                        <div class="widget-content widget-content-area mb-2 py-2 keyword-section-title">
                                            <h4 class="m-0">
                                                Keywords
                                            </h4>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @COMPANY_NAME <br>
                                            @COMPANY_PHONE <br>
                                            @COMPANY_EMAIL <br>
                                            @COMPANY_WEB <br>
                                            @COMPANY_DOT <br>
                                            @COMPANY_LICENSE <br>
                                            @COMPANY_ADDRESS <br>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @REPRESENTATIVE_NAME <br>
                                            @REPRESENTATIVE_PHONE <br>
                                            @REPRESENTATIVE_EMAIL <br>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @CUSTOMER_FULL_NAME <br>
                                            @CUSTOMER_NAME <br>
                                            @CUSTOMER_PHONE <br>
                                            @CUSTOMER_EMAIL <br>
                                            @CUSTOMER_SIGNATURE <br>
                                            @CUSTOMER_DATE_SIGNATURE <br>
                                            @SIGNATURE_IP <br>
                                            @PU_TIME <br>
                                            @CUSTOMER_DEPOSIT <br>
                                            @CUSTOMER_BALANCE <br>
                                            @CC_ID_NUMBER<br>
                                            @CC_ID_EXP<br>
                                            @CC_ID_INFO<br>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @FROM_CITY <br>
                                            @FROM_STATE <br>
                                            @FROM_COUNTRY <br>
                                            @TO_CITY <br>
                                            @TO_STATE <br>
                                            @TO_COUNTRY <br>
                                            @FROM_ADDRESS <br>
                                            @TO_ADDRESS <br>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @JOB_NUMBER <br>
                                            @JOB_PICKUP_START <br>
                                            @VEHICLE <br>
                                            @MOVE_TYPE <br>
                                            @MOVE_DATE <br>
                                            @MOVE_SIZE <br>
                                            @TOTAL_JOB <br>
                                            @TOTAL_CF <br>
                                            @TOTAL_LBS <br>
                                            @TOTAL_MILES <br>
                                            @MOVING_ESTIMATE_SIGNATURE_TEXT <br>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @AMOUNT_BALANCE<br>
                                            @DEPOSIT_CC<br>
                                            @CC_FEE<br>
                                            @CC_PERCENT<br>
                                            @CREDIT_CARD_NUMBER<br>
                                            @HOLDER_BIRTHDAY<br>
                                            @ESTIMATE_PICKUP_DATE<br>
                                            @CREDIT_CARD_HOLDER_NAME<br>
                                            @CC_ID_NUMBER<br>
                                            @CC_ID_EXP<br>
                                            @CC_ID_INFO
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @CARRIER <br>
                                            @CARRIER_DOT <br>
                                            @JOB_RATE <br>
                                            @DEPOSIT <br>
                                            @TOTAL_PAYMENTS <br>
                                            @INITIAL_DEPOSIT <br>
                                            @BROKER_BALANCE <br>
                                            @JOB_PICKUP_END <br>
                                            @SPECIAL_CONDITIONS_JAF <br>
                                            @JOB_FADD <br>
                                            @DATE_OUT <br>
                                        </div>

                                        <div class="widget-content widget-content-area keywords mb-2">
                                            @ACH_ROUTING <br>
                                            @ACH_ACCOUNT <br>
                                        </div>

                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
