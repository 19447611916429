<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-5 col-lg-2 col-md-2 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <h3>
                Blackout Move Jobs
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Blackout Move Jobs
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-7 col-md-7 col-sm-12 filtered-list-search layout-spacing align-self-center text-center">
            <!-- <app-date-filter (onChange)="getFilterDates($event)"></app-date-filter>
            <br>
            <button class="btn btn-warning" (click)="search()">
                Search
            </button> -->
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <a appWorkspaceRouterLink="/blackout-move-dates/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Blackout Move Jobs
                </a>
            </div>


        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="info-tip">
                <span class="asterisk">*</span>
                Date on which you will not be allowed to book pick-up work.
             </div>
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    
                    <br>
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>date</th>
                                <th>Employee</th>
                                <th>created</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.date | date}}</td>
                                <td>{{row.employee_name}}</td>
                                <td>{{row.created | date}}</td>
                                <td>
                                    <a appWorkspaceRouterLink="/blackout-move-dates/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="(remove(row.id))" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>                                    
                                <td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls> -->
                    <!-- <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator> -->
                </div>
            </div>
        </div>
    </div>
</div>
