import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';
import { LocalTariffSetting } from 'src/app/entities/admin/local-tariff-setting';
import { LocalTariffSettingsService } from 'src/app/services/workspaces/local-tariff-settings.service';
import { ActivatedRoute } from '@angular/router';

declare const jQuery;
@Component({
  selector: 'app-local-tariff-settings-form',
  templateUrl: './local-tariff-settings-form.component.html',
  styleUrls: ['./local-tariff-settings-form.component.scss']
})
export class LocalTariffSettingsFormComponent implements OnInit {

  public active: 'YES' | 'NO';
  private constantsMessages = ConstantsMessages;
  public localTariff: LocalTariffSetting;
  public selectOptions: any[];
  private currentNumber: number;
  constructor(
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private localTariffSettingsService: LocalTariffSettingsService
  ) {
    this.active = 'NO';
    this.currentNumber = 0;
    this.localTariff = new LocalTariffSetting();
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
          this.load(params.id);
      } else {
        this.loadAllTariff();
      }
    });
    this.selectOptions = [];
  }

  // carga todas las tarifas para poder compara el numero de worksers
  loadAllTariff(): void {
    this.localTariffSettingsService.getAll()
    .then((result) => {
      this.loadAbailableNumbers(result);
    });
  }

  /**
   * carga arreglo de numeros usados
   * @param localTariffs 
   */
  loadAbailableNumbers(localTariffs) {
    let usedNumbers = [];
    for(let tariff of localTariffs) {
      usedNumbers.push(Number(tariff.workers));
    }
    // invoca seteo de opciones disponibles
    this.setSelectOptions(usedNumbers);
  }

  /**
   * arma opsiones con numeros disponibles
   * @param usedNumbers 
   */
  setSelectOptions(usedNumbers) {
    this.selectOptions = [];
    // recorre los numeros del 1 al 20
    for (let i = 1; i< 21; i++) {
      // en caso de que el numero actual no este en los numeros usados
      // se agrega al arreglo de opciones
      if (usedNumbers.indexOf(i) == -1 || i  == this.currentNumber) {
        this.selectOptions.push({"number": i});
      };
    }
  }

  /**
   * carga la tarifa cuando se va a editar
   * @param localTariffId 
   */
  load(localTariffId: string): void {
    this.localTariffSettingsService.get(localTariffId)
    .then((result) => {
      this.localTariff = result;
      this.active = this.localTariff.active ? 'YES': 'NO';
      // el numero actual debe estar disponible en la edicion
      this.currentNumber = result.workers;
      // carga tarifas para conoces numeros usados
      this.loadAllTariff();
    })
    .catch((error) => {
      console.error('error', error);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  /**
   * envía a guardar los datos
   */
  save() {
    this.helperService.showLoadingMxpro360();
    this.localTariff.active = this.active == 'YES';
    this.localTariff.workers = Number(this.localTariff.workers);
    this.localTariffSettingsService.save(this.localTariff)
    .then((result) => {
      // muestra mensaje de exito y redirige al listado
      this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
      this.helperService.goToWorkspaceRouterLink('/local-tariff-settings');
    })
    .catch((error) => {
      this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      console.error('error', error);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }
}

