export class SmsTemplate {
    id: string;
    name: string;
    message: string;
    created: string;
    updated: string;

    constructor() {
        this.id = null;
        this.name = null;
        this.message = null;
        this.created = null;
        this.updated = null;
    }
}
