<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-3 mb-4">
            <a appWorkspaceRouterLink="/zone-states/add" class="btn btn-main-action">
                <i class="fas fa-plus-square"></i> &nbsp; New Zone States
            </a>  
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 mb-4 filtered-list-search">
            <form class="form-inline my-2 my-lg-0">
                <div class="block-filter">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-3 col-sm-3 mb-4">

        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Zone</th>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Zip Code</th>
                                <th>Radius</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of tariffVersionsSettingsService.zoneState |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.zone.name}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.code}}</td>
                                <td>{{row.zip_code}}</td>
                                <td>{{row.radius}}</td>
                                <td>
                                    <a appWorkspaceRouterLink="/zone-states/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
