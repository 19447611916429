import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, SimpleChanges } from '@angular/core';

declare const moment;
declare var flatpickr;

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

    @Input()
    placeholder: string;

    @Input()
    disabled: boolean;

    @Input()
    valueStart: number;

    @Input()
    valueEnd: number;

    @Output()
    onChange: EventEmitter<Array<Number>>;

    @ViewChild('fieldDatePicker', { static: true })
    inputField: ElementRef<HTMLInputElement>;

    render: string;

    state: "RENDER" | "INPUT";

    @Input()
    disabledDates: Array<any>;

    private flatPicker;

    constructor() {
        this.onChange = new EventEmitter();
        this.disabled = false;
        this.state = "RENDER";
        this.placeholder = null;
        this.valueStart = null;
        this.valueEnd = null;
        this.flatPicker = null;
        this.disabledDates = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
            this.flatPicker = flatpickr(this.inputField.nativeElement, {
                enableTime: false,
                allowInput: false,
                disable: this.disabledDates,
                mode: "range",
                // defaultDate: [moment(this.valueStart).toDate(), moment(this.valueEnd).toDate()],
                onChange: (selectedDates: Array<Date>, dateStr, instance) => {
    
                    this.valueStart = moment(selectedDates[0], moment.HTML5_FMT.DATE).second(0).minute(0).hour(5).unix() * 1000;
                    this.valueEnd = moment(selectedDates[1], moment.HTML5_FMT.DATE).second(59).minute(59).hour(17).unix() * 1000;
                    this.render = moment(selectedDates[0]).format("MMMM Do") + " to " + moment(selectedDates[1]).format("MMMM Do");
    
                    // notificamos el nuevo valor
                    this.onChange.emit([this.valueStart, this.valueEnd]);
                },
                onClose: () => {
                    this.state = "RENDER";
                }
            });
       
    }

    ngOnChanges(changes: SimpleChanges) {

        // si el cambio es sobre el placeholder lo hacemos primero
        if (changes.placeholder) {
            this.render = changes.placeholder.currentValue;
        }

        // si el cambio es sobre el value lo ejecutamos de ultimo
        if (this.valueStart || this.valueEnd) {
            this.render = moment(this.valueStart).format("MMMM Do") + " to " + moment(this.valueEnd).format("MMMM Do");
        }

    }

    /**
     * Se ejecuta cuando el usuario hace clik para cambiar una fecha
     */
    onClickRender() {

        if (this.state == "INPUT" || this.disabled) {
            return;
        }
        
        if(this.valueStart != null && this.valueEnd != null) {
            this.flatPicker.setDate([moment(this.valueStart).toDate(), moment(this.valueEnd).toDate()]);
        }

        this.flatPicker.redraw();
        this.state = "INPUT";

        setTimeout(() => {
            this.inputField.nativeElement.focus();
        }, 100);
    }

}
