import { LeadAdvertiserView } from '../../../entities/workspace/lead-advertiser-view';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Paginator } from '../../../entities/helpers/paginator';
import { ReportLeadAdvertiserView } from '../../../entities/workspace/report-lead-advertiser-view';
import { HttpCancelService } from 'src/app/http-cancel.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const moment;
@Component({
  selector: 'app-leads-advertisers-report',
  templateUrl: './leads-advertisers-report.component.html',
  styleUrls: ['./leads-advertisers-report.component.scss']
})
export class LeadsAdvertisersReportComponent implements OnInit {
  public leadAdvertisers: Array<ReportLeadAdvertiserView>;
  public advertisers: Advertiser[];
  public advertiserId: string;
  public betweenDates: BetweenDates;
  public actualPage: number;
  public advertiserSelected: Advertiser;
  public totalLeads: number;
  public paginator: Paginator;
  public leads: Array<LeadAdvertiserView>;

  @ViewChild('leadsModal') leadsModal: ElementRef;

  constructor(
    private advertisersService: AdvertisersService,
    private htttpCancelService: HttpCancelService,
    private helperService: HelperService
  ) {
    this.betweenDates = new BetweenDates();
    this.advertiserId = '';
    this.actualPage = 1;
    this.paginator = new Paginator();
    this.leads = [];
    this.advertiserSelected = new Advertiser();
    this.leadAdvertisers = [];
    this.totalLeads = 0;
  }

  load() {
    this.helperService.showLoadingMxpro360();

    this.betweenDates.dateStart = this.betweenDates.dateStart === null ?
      moment().second(0).minute(0).hour(0).unix() * 1000 : this.betweenDates.dateStart;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd === null ?
      moment().second(0).minute(0).hour(23).unix() * 1000 : this.betweenDates.dateEnd;

    const data = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      advertiser: this.advertiserId
    };
    this.advertisersService.getReport(data).then((response) => {
      this.leadAdvertisers = response;
      this.totalLeads = 0;
      for (const leadAdvertiser of this.leadAdvertisers) {
        this.totalLeads +=  leadAdvertiser.total_leads
      }
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  loadLeadByAdvertiser() {

  }

  loadAdvertisers() {
    this.advertisersService.getAll().then((response) => {
      this.advertisers = response;
    })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  ngOnInit(): void {
   
  }

  ngAfterViewInit() {
    this.load();
    this.loadAdvertisers();
    this.loadLeadByAdvertiser();
  }

  getFilterDates(dates) {
    this.betweenDates = dates;
  }

  search() {
    this.htttpCancelService.cancelPendingRequest();
    this.load();
  }

  openModalLeadsByAdvertiser(advertiser: Advertiser): void {
    this.htttpCancelService.cancelPendingRequest();
    this.advertiserSelected = advertiser;
    setTimeout(() => {
      jQuery(this.leadsModal.nativeElement).modal('show');
    }, 10);
    this.paginator.paginator.page = 1;
    this.loadLeadsByAdvertiser();
  }

  loadLeadsByAdvertiser() {
    this.helperService.showLoadingMxpro360();
    this.betweenDates.dateStart = this.betweenDates.dateStart === null ?
      moment().second(0).minute(0).hour(0).unix() * 1000 : this.betweenDates.dateStart;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd === null ?
      moment().second(0).minute(0).hour(23).unix() * 1000 : this.betweenDates.dateEnd;

    const data = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      advertiser: this.advertiserSelected.id
    };

    this.paginator.paginator.per_page = 5;

    this.advertisersService.getLeadsAdvertisers(data, this.paginator).then((response) => {
      this.paginator.data = response.data;
      this.paginator.paginator.total = response.paginator.total;

    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  closeModalEstimates(): void {
    this.htttpCancelService.cancelPendingRequest();
    jQuery(this.leadsModal.nativeElement).modal('hide');
  }

  setCurrentPage(currentpage: number): void {
    this.htttpCancelService.cancelPendingRequest();
    if (this.paginator.paginator.page !== currentpage) {
      this.paginator.paginator.page = currentpage;
      this.loadLeadsByAdvertiser();
    }
  }

}
