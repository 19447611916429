import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { TicketAssignmentRule } from 'src/app/entities/workspace/ticket-assignment-rule';
import { TicketType } from 'src/app/entities/workspace/ticket-type';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { TicketAssignmentRulesService } from 'src/app/services/companies/ticket-assignment-rules.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-ticket-assignment-rules-form',
    templateUrl: './ticket-assignment-rules-form.component.html',
    styleUrls: ['./ticket-assignment-rules-form.component.scss']
})
export class TicketAssignmentRulesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: TicketAssignmentRule;
    tickesTypes: Array<TicketType>;
    ticketTypeId: string;
    workDepartments: Array<WorkDepartment>;

    constructor(
        private ticketAssignmentRulesService: TicketAssignmentRulesService,
        private workDepartmentsService: WorkDepartmentsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private ticketsTypesService: TicketsTypesService
    ) {
        this.workDepartments = [];
        this.dataForm = new TicketAssignmentRule();
        this.tickesTypes = [];
        this.ticketTypeId = "";
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
        this.loadWorkDeparments();
        this.loadsTickesTypes();
    }

    loadsTickesTypes() {
        this.helperService.showLoadingMxpro360();
        this.ticketsTypesService
        .getAll()
        .then((response) => {
            this.tickesTypes = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.ticketAssignmentRulesService
            .getById(id)
            .then((response: any) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();        
        this.ticketAssignmentRulesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/ticket-assignment-rules');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadWorkDeparments() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.workDepartments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
