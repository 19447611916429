<div class="bio layout-spacing ">
    <div class="widget-content widget-content-area">
        <div class="d-flex justify-content-between widget-header">
            <h3>
                Jobs Board
            </h3>

            <a *ngIf="job.job_outsourcing == null" (click)="openBoardJobModal()" class="option-widget">

                <!-- SI NO HAY UN BOARD JOB ASIGNADO, MOSTRAMOS EL ICONO DE AGREGAR -->
                <svg *ngIf="boardJob == null" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-plus-circle">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>

                <!-- SI YA HAY UN BOARD JOB ASIGNADO, MOSTRAMOS EL ICONO DE EDITAR -->
                <svg *ngIf="boardJob  !== null" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-edit">
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>

            </a>
        </div>


        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">

                <table class="table table-striped">
                    <tbody>

                        <tr>
                            <th>
                                VIP board
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    <svg *ngIf="boardJob.is_vip" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>

                                    <svg *ngIf="!boardJob.is_vip" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>

                            </td>
                        </tr>
                        <tr>
                            <th>
                                Private board
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    <svg *ngIf="boardJob.is_private" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>

                                    <svg *ngIf="!boardJob.is_private" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Public board
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    <svg *ngIf="boardJob.is_public" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-check">
                                        <polyline points="20 6 9 17 4 12"></polyline>
                                    </svg>

                                    <svg *ngIf="!boardJob.is_public" xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                    </svg>
                                </ng-container>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Offer
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    {{boardJob.offer | currency}}
                                </ng-container>

                            </td>
                        </tr>

                        <tr>
                            <th>
                                Special Conditions
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    <p>
                                        {{boardJob.special_conditions}}
                                    </p>
                                </ng-container>

                            </td>
                        </tr>

                        <tr>
                            <th>
                                Status
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    {{boardJob.status}}
                                </ng-container>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Publication date
                            </th>
                            <td>
                                <ng-container *ngIf="boardJob  !== null">
                                    {{boardJob.created | date}}
                                </ng-container>
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
            <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">

                <table class="table table-striped text-center">
                    <thead>
                        <tr>
                            <th colspan="6">
                                Candidates <button class="btn btn-success" *ngIf="boardJob" style="float: right;" (click)="openModalCreateCarrierCandidate()">Add Candidate</button>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Carrier
                            </th>
                            <th>
                                Comments
                            </th>
                            <th>
                                Bid
                            </th>
                            <th>
                                Postulated
                            </th>
                            <th>
                                Options
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let candidate of carrierCandidates">
                            <tr *ngIf="candidate.carrier">
                                <th>
                                    {{candidate.carrier.name}}
                                </th>
                                <td>
                                    <p>
                                        {{candidate.comments}}
                                    </p>
                                </td>
                                <td>
                                    {{candidate.bid | currency}}
                                </td>
                                <td>
                                    {{candidate.created | date}}
                                </td>
                                <td>
                                    <ng-container *ngIf="job.job_outsourcing == null">
                                        <button
                                            (click)="openCandidateCarrierAssignmentModal(candidate)"
                                            class="btn btn-secondary btn-sm mb-2">
                                            Assign
                                        </button>
    
                                        <button 
                                            (click)="deleteCreateCarrierCandidate(candidate.id)"
                                            class="btn btn-danger btn-sm mb-2">
                                            Delete
                                        </button>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

            </div>
        </div>


    </div>
</div>

<div class="bio layout-spacing ">
    <div class="widget-content widget-content-area">

        <div class="d-flex justify-content-between widget-header">

            <h3>
                Outsourcing
            </h3>
            <div>
                <a *ngIf="job.job_outsourcing == null" (click)="showModalInformationCarriers('ASSIGN_CARRIER')"
                    class="option-widget">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-plus-circle">
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                </a>
                <a *ngIf="job.job_outsourcing && job.job_outsourcing?.belongs_broker" class="btn btn-danger">
                    Request Cancellation
                </a>
                <a *ngIf="job.job_outsourcing && !job.job_outsourcing?.belongs_broker"
                    (click)="openCancellationReasonModal()" class="btn btn-danger">
                    <span *ngIf="job.estimate.service !== 'FREIGHT'">Cancel Job Acceptance</span>
                    <span *ngIf="job.estimate.service === 'FREIGHT'">Cancel Rate Sheet</span>
                </a>

                <a *ngIf="job.job_outsourcing?.job_acceptance_doc?.esignature && !job.job_outsourcing?.belongs_broker"
                    (click)="loadContactsByCarrier()" class="btn btn-warning">
                    <span *ngIf="job.estimate.service !== 'FREIGHT'">Resend Job Acceptance By Email</span>
                    <span *ngIf="job.estimate.service === 'FREIGHT'">Resend Rate Sheet By Email</span>
                </a>
                <a *ngIf="job.job_outsourcing && !job.job_outsourcing?.job_acceptance_doc?.esignature"
                    (click)="loadContactsByCarrier()" class="btn btn-warning">
                    <span *ngIf="job.estimate.service !== 'FREIGHT'">Send Job Acceptance By Email</span>
                    <span *ngIf="job.estimate.service === 'FREIGHT'">Send Rate Sheet By Email</span>
                </a>
                <a *ngIf="job.job_outsourcing && !job.job_outsourcing?.belongs_broker" (click)="copyUrlCarrier()"
                    class="btn btn-warning">
                    <span *ngIf="job.estimate.service !== 'FREIGHT'">Link To Sign Job Acceptance</span>
                    <span *ngIf="job.estimate.service === 'FREIGHT'">Link To Sign Rate Sheet</span>
                </a>
                <span *ngIf="job.estimate.service !== 'FREIGHT'">
                    <a *ngIf="job.job_outsourcing && !job.job_outsourcing?.belongs_broker" (click)="sendBOLDocumentByEmail()"
                    class="btn btn-warning">
                    Send BOL Document By Email
                </a>
                 </span>
                <a *ngIf="job.job_outsourcing && !job.job_outsourcing?.belongs_broker"
                    (click)="sendNewJobAcceptance(carrier)" class="btn btn-info">
                    Send New Job Acceptance
                </a>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-7 col-lg-6 col-md-6 col-sm-12">

                <table class="table table-striped">
                    <tbody>

                        <tr>
                            <th>
                                Type:
                            </th>
                            <td>
                                <span *ngIf="job.job_outsourcing">
                                    {{job.job_outsourcing.type}}
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Status:
                            </th>
                            <td>
                                <span *ngIf="job.job_outsourcing">
                                    <ng-container *ngIf="job.job_outsourcing.status == 'PENDING_SIGNATURE' ">
                                        PENDING SIGNATURE
                                    </ng-container>
                                    <ng-container *ngIf="job.job_outsourcing.status == 'PENDING_TO_SEND_THE_JOB_ACCEPTANCE'">
                                        PENDING TO SEND THE JOB ACCEPTANCE
                                    </ng-container>
                                    <ng-container *ngIf="job.job_outsourcing.status == 'WAITING_FOR' ">
                                        PUBLISHED ON LOAD BOARD
                                    </ng-container>
                                    <ng-container *ngIf="job.job_outsourcing.status == 'WORKING_ON' ">
                                        WORKING ON
                                    </ng-container>
                                </span>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Special Conditions:
                            </th>
                            <td>
                                <p *ngIf="job.job_outsourcing">
                                    {{job.job_outsourcing.special_conditions}}
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Sent by:
                            </th>
                            <td>
                                <span *ngIf="job.employee_outsourcing">
                                    {{job.employee_outsourcing.name}}
                                </span>
                            </td>
                        </tr>


                        <tr>
                            <th>
                                Created:
                            </th>
                            <td>
                                <span *ngIf="job.job_outsourcing">
                                    {{job.job_outsourcing.created | date}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12">

                <table class="table table-striped">
                    <tbody>

                        <tr *ngIf="job.job_outsourcing && job.job_outsourcing.carrier_pay">
                            <th>
                                Carrier Pay:
                            </th>
                            <td>
                                {{job.job_outsourcing.carrier_pay | currency}}
                            </td>
                        </tr>

                        <tr
                            *ngIf="job.job_outsourcing && job.job_outsourcing.status != 'PENDING_SIGNATURE' && job.job_outsourcing?.job_acceptance_doc">
                            <th>
                                Job Acceptance
                            </th>
                            <td>
                                <button class="btn btn-success"
                                    (click)="downloadPdf(job.job_outsourcing.job_acceptance_doc)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-download">
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="7 10 12 15 17 10"></polyline>
                                        <line x1="12" y1="15" x2="12" y2="3"></line>
                                    </svg>
                                    Download
                                </button>
                            </td>
                        </tr>

                        <tr
                            *ngIf="job.job_outsourcing && job.job_outsourcing.status != 'PENDING_SIGNATURE' && job.job_outsourcing.job_acceptance_doc">
                            <th>
                                Signature
                            </th>
                            <td>
                                <img width="185px"
                                    appCdnLink="/e-signatures/{{job.job_outsourcing.job_acceptance_doc.esignatures.main.esignature_file.display_name}}">
                            </td>
                        </tr>

                        <tr
                            *ngIf="job.job_outsourcing && job.job_outsourcing.status != 'PENDING_SIGNATURE' && job.job_outsourcing.job_acceptance_doc">
                            <th>
                                Signed At:
                            </th>
                            <td>
                                {{job.job_outsourcing.job_acceptance_doc.esignatures.main.esignature_file.created | dateTime}}
                            </td>
                        </tr>

                        <tr
                            *ngIf="job.job_outsourcing && job.job_outsourcing.status != 'PENDING_SIGNATURE' && job.job_outsourcing.job_acceptance_doc">
                            <th>
                                IP Address:
                            </th>
                            <td>
                                {{job.job_outsourcing.job_acceptance_doc.esignatures.main.ip_address}}
                            </td>
                        </tr>


                    </tbody>
                </table>


            </div>
        </div>

        <hr>

        <h2 class="text-center" *ngIf="job.carrier">
            <span *ngIf="job.job_outsourcing?.belongs_broker">
                Broker
            </span>
            <span *ngIf="!job.job_outsourcing?.belongs_broker">
                Carrier
            </span>
            Info
        </h2>

        <div class="row" *ngIf="job.carrier">
            <div class="col-xl-6 col-lg-5 col-md-6 col-sm-12">

                <table class="table table-striped">
                    <tbody>

                        <tr>
                            <th>
                                Name
                            </th>
                            <td>
                                {{job.carrier.name}}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Address
                            </th>
                            <td>
                                3730 N 55th Ave, Hollywood, FL 33021, USA
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Dot
                            </th>
                            <td>
                                {{job.carrier.dot}}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Has Moverxpro360
                            </th>
                            <td>

                                <svg *ngIf="job.carrier.has_moverxpro360" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>

                                <svg *ngIf="!job.carrier.has_moverxpro360" xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>

                            </td>
                        </tr>

                        <tr>
                            <th>
                                VIP
                            </th>
                            <td>

                                <svg *ngIf="job.carrier.vip" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-check">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>

                                <svg *ngIf="!job.carrier.vip" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>

                            </td>
                        </tr>


                        <tr>
                            <th>
                                Created
                            </th>
                            <td *ngIf="job.job_outsourcing">
                                {{job.job_outsourcing.created | date}}
                            </td>

                        </tr>

                    </tbody>
                </table>

            </div>
            <div class="col-xl-6 col-lg-7 col-md-6 col-sm-12">

                <table class="table table-striped text-center">
                    <thead>
                        <tr>
                            <th colspan="3">
                                Contacts
                            </th>
                        </tr>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Phone
                            </th>
                            <th>
                                Email
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngFor="let contact of carriersContacts">
                            <th>
                                {{contact.name}}
                            </th>
                            <td>
                                {{contact.phone}}
                            </td>
                            <td>
                                {{contact.email}}
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>

    </div>
</div>

<div class="bio layout-spacing ">
    <div class="widget-content widget-content-area">
        <div class="d-flex justify-content-between widget-header">

            <h3>
                Outsourcing Cancelled
            </h3>

            <!--
            <a href="user_account_setting.html" class="option-widget">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-plus-circle">
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
            </a>
            -->

        </div>


        <table class="table table-striped">
            <thead>
                <tr>
                    <th>
                        Carrier
                    </th>
                    <th>
                        Created
                    </th>
                    <th>
                        Carrier Pay
                    </th>
                    <th>
                        Special Conditions
                    </th>
                    <th>
                        Rate Sheet
                    </th>
                    <th>
                        Employee
                    </th>
                    <th>
                        reason for cancellation
                    </th>
                    <th>
                        cancellation date
                    </th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let jobOutsourcing of JobOutsourcingCancelleds">
                    <th>
                        {{jobOutsourcing.carrier?.name}}
                    </th>
                    <td>
                        {{jobOutsourcing.created | calendarDateTime}}
                    </td>
                    <td>
                        {{jobOutsourcing.carrier_pay | currency}}
                    </td>
                    <td>
                        <p>
                            {{jobOutsourcing.special_conditions}}
                        </p>
                    </td>
                    <td>
                        <ng-container *ngIf="jobOutsourcing.job_acceptance_doc">
                            <button class="btn btn-success" (click)="downloadPdf(jobOutsourcing.job_acceptance_doc)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-download">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="7 10 12 15 17 10"></polyline>
                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                </svg>
                                Download
                            </button>
                        </ng-container>

                    </td>
                    <td>
                        {{jobOutsourcing.employee.name}}
                    </td>
                    <td>
                        {{jobOutsourcing.reason | filterReplace}}
                    </td>
                    <td>
                        {{jobOutsourcing.created | calendarDateTime}}
                    </td>
                </tr>

            </tbody>
        </table>



    </div>
</div>



<!-- ------------------------- OLD MODALS --------------------------------------------------- -->


<!-- MODAL PARA ESTABLECER VALORES A OFRECER AL CARRIER -->
<div class="modal fade modalCarriers" #modalInformationCarriers tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">

            <form class="form" #assignmentCarrierForm="ngForm" ngNativeValidate>

                <div class="modal-header">

                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong style="color: #e44f00;">
                            Carrier Assignment
                        </strong>
                    </h4>

                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                            <div class="form-group">
                                <label class="control-label">Carrier Balance Offer</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01" placeholder="Carrier Balance Offer"
                                        class="form-control" name="offer" [(ngModel)]="carrierBalanceOffer"
                                        (keyup)="updateOfferPercentage()">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    enter the value to be offered to the carrier so that it can do the job
                                </small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Carrier Balance Percentage</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input id="percentage" type="number" step="0.01"
                                        placeholder="Carrier Balance Percentage" disabled="true" class="form-control"
                                        name="percentage" [(ngModel)]="carrierBalancePercentage">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    this field indicates the percentage of the work that is being given to the carrier
                                </small>
                            </div>

                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                            <div class="form-group ">
                                <label class="control-label">Special Conditions:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-message-square">
                                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <textarea name="carrier_special_conditions" class="form-control"
                                        [(ngModel)]="specialConditions" rows="7"></textarea>
                                </div>
                                <small class="form-text text-muted mb-4">
                                    if you have something to add to the contract... you can add it here
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="text-center" style="width: 100%;">
                        <span *ngIf="carrierBalanceOffer >= job.estimate.total" style="color: red;">
                            Maximum Carrier Balance Offer
                        </span>
                        <button class="btn" (click)="closeModalInformationCarriers()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Discard
                        </button>
                        <button class="btn btn-warning" [disabled]="carrierBalanceOffer >= job.estimate.total"
                            (click)="setCarriersModal()" type="submit">
                            Next
                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>

<!-- modal para seleccionar carrier -->
<div class="modal fade modalCarriers" #modalCarriers tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content size-modal-carrier">

            <form #selectingCarrierForm="ngForm" ngNativeValidate>


                <div class="modal-header">

                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong style="color: #e44f00;">
                            Carrier Assignment
                        </strong>
                    </h4>

                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">

                    <!-- BLOQUE PARA ESTABLECER EL VALOR A OFRECER Y LAS CONDICIONES DEL TRABAJO-->
                    <div class="content-request-carrier" *ngIf="viewSectionModalCarrier == 'INTRO'">
                        <button type="button" class="btn btn-danger"
                            (click)="viewSectionModalCarrier = 'POST_REQUEST_CARRIER'">
                            Post on load board
                        </button>
                        <br>
                        <button type="button" class="btn btn-secondary "
                            (click)="viewSectionModalCarrier = 'ASSIGN_CARRIER'">
                            Manual carrier assignment
                        </button>
                    </div>

                    <!--BLOQUE PARA CONFIRMAR LA PUBLIACION DEL CARRIER EN EL TABLERO-->
                    <div class="content-request-carrier" *ngIf="viewSectionModalCarrier == 'POST_REQUEST_CARRIER'">
                        <label for="">
                            Show on Public load board?
                            <input type="checkbox" [(ngModel)]="isPublicJobOutsourcing" name="isPublicJobOutsourcing" />
                        </label>
                        <small style="line-height: 18px; margin-bottom: 10px; font-style: italic;">
                            if the box is checked, the system will display the offer on the public board.
                        </small>
                        <button class="btn btn-secondary" (click)="postOnLoadBoard()">
                            Request a Carrier
                        </button>
                    </div>

                    <!--BLOQUE PARA BUSCAR Y SELECCIONAR UN CARRIER MANUALMENTE-->
                    <div class="content-assign-carrier" *ngIf="viewSectionModalCarrier == 'ASSIGN_CARRIER'">

                        <!-- caja de texto para la busqueda -->
                        <div class="form-group " style="width: 400px; margin: 0 auto;">
                            <!-- <label class="control-label">Search:</label> -->
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-search">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        </svg>
                                    </span>
                                </div>
                                <input type="search" name="filterSearch" [(ngModel)]="filterSearch"
                                    class="form-control product-search" id="input-search" placeholder="search...">
                            </div>
                            <small class="form-text text-muted mb-4">
                                enter in the text box the name or other characteristic of the carriers to be filtered
                            </small>
                        </div>

                        <div class="table-responsive" style="max-height: 400px;">
                            <table class="table table-bordered table-striped mb-4">
                                <thead>
                                    <tr>
                                        <th class="text-center">Carrier</th>
                                        <th class="text-center">Address</th>
                                        <th class="text-center">Dot</th>
                                        <th class="text-center">Insurance due date</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let carrier of carriers| search:filterSearch | async; index as i">
                                        <th>
                                            {{carrier.name}}
                                        </th>
                                        <td>
                                            <span *ngIf="carrier.address.full_address">
                                                {{carrier.address.full_address}}
                                            </span>
                                        </td>
                                        <td>
                                            {{carrier.dot}}
                                        </td>
                                        <td>
                                            {{carrier.insurance_due_date | date}}
                                        </td>
                                        <td>
                                            <button class="btn btn-warning btn-sm" (click)="selectCarrier(carrier, false)"
                                                type="button">
                                                Only Assign
                                            </button>
                                            <button class="btn btn-danger btn-sm" (click)="selectCarrier(carrier, true)"
                                                type="button">
                                                <span *ngIf="job.estimate.service !== 'FREIGHT'">Assign & Send Job Acceptance</span>
                                                <span *ngIf="job.estimate.service === 'FREIGHT'">Assign & Send Rate Sheet</span>
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

                <div class="modal-footer">
                    <div class="text-center" style="width: 100%; display: inline-block">
                        <button class="btn" (click)="closeModalCarriers()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Discard
                        </button>
                        <button class="btn btn-warning" (click)="saveCarrierAndAgent()" type="submit">
                            Save
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>

<!-- MODAL PARA seleccionar contactos del CARRIER -->
<div class="modal fade register-modal" #emailsModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-signature">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Send Emails
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">

                            <div class="form-group">
                                <div class="user-info-list">

                                    <table class="table table-bordered mb-4">
                                        <thead>
                                            <tr>
                                                <th>Contact</th>
                                                <th>Email</th>
                                                <th>Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let carrierContact of carrierContacts">
                                                <td>
                                                    {{carrierContact.name}}
                                                </td>
                                                <td>
                                                    {{carrierContact.email}}
                                                </td>
                                                <td>
                                                    <input type="checkbox" (change)="setDefault(carrierContact.id)"
                                                        [checked]="carrierContact.main"
                                                        [disabled]="carrierContact.main" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button data-dismiss="modal" type="button" class="btn btn-danger mt-2 mb-2 btn-block">
                        Close
                    </button>
                    <button (click)="closeEmailsModal()" data-dismiss="modal" type="button"
                        [disabled]="job.estimate.status == 'WORK_CARRIED_OUT'"
                        class="btn btn-success mt-2 mb-2 btn-block">
                        Send
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- MODAL PARA seleccionar la rason de la cancelacion de un carrier -->
<div class="modal fade register-modal" #reasonCancellationModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xs modal-dialog-centered" role="document">
        <div class="modal-content modal-signature">

            <form class="form" #reasonCancelModalForm="ngForm" ngNativeValidate>

                <div class="modal-header">
                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong>
                            Reason for cancellation
                        </strong>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>



                <div class="modal-body">


                    <div class="form-group" *ngIf="job.job_outsourcing != null">
                        <label class="control-label">Select the reason:</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-alert-triangle">
                                        <path
                                            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                                        </path>
                                        <line x1="12" y1="9" x2="12" y2="13"></line>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                </span>
                            </div>

                            <select name="jobStatusoperation" class="form-control"
                                [(ngModel)]="job.job_outsourcing.reason">
                                <option value="CARRIER_CANCELED">CARRIER CANCELED</option>
                                <option value="PRICE_DIDNT_MATCH">PRICE DIDNT MATCH</option>
                                <option value="DATES_CHANGE">DATES CHANGE</option>
                                <option value="DOT_COMPLAINT">DOT COMPLAINT</option>
                                <option value="NOT_SIGN">NOT SIGN</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                        </div>
                        <small class="form-text text-muted mb-4">
                            select the reason for which you want to cancel the carrier's assignment to the job
                        </small>
                    </div>

                </div>
                <div class="modal-footer text-center">
                    <div class="text-center" style="width: 100%; display: inline-block">
                        <button (click)="closeCancellationReasonModal()"
                            [disabled]="job.estimate.status == 'WORK_CARRIED_OUT'" data-dismiss="modal" type="button"
                            class="btn btn-danger mt-2 mb-2 ">
                            Do not cancel
                        </button>
                        <button (click)="cancelJobOutsourcing()" data-dismiss="modal" type="button"
                            [disabled]="job.estimate.status == 'WORK_CARRIED_OUT'" class="btn btn-success mt-2 mb-2 ">
                            Submit Cancellation
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>



<!-- ------------------------- NEW  MODALS --------------------------------------------------- -->

<div class="modal fade modalCarriers" #boardJobModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content" *ngIf="boardJobForm !== null">

            <form class="form" #boardJobModalForm="ngForm" ngNativeValidate>

                <div class="modal-header">

                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong style="color: #e44f00;" *ngIf="boardJobForm.id == null">
                            Post job on boards
                        </strong>
                        <strong style="color: #e44f00;" *ngIf="boardJobForm.id != null">
                            Update job posting on the board
                        </strong>
                    </h4>

                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                            <div class="form-group">
                                <label class="control-label">Carrier Balance Offer</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01" placeholder="Carrier Balance Offer"
                                        class="form-control" name="offer" [(ngModel)]="boardJobForm.offer"
                                        (keyup)="updateOfferPercentage('BOARD_JOB')">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    enter the value to be offered to the carrier so that it can do the job
                                </small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Carrier Balance Percentage</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input id="percentage" type="number" step="0.01"
                                        placeholder="Carrier Balance Percentage" disabled="true" class="form-control"
                                        name="percentage" value="{{boardJobPercentage}}">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    this field indicates the percentage of the work that is being given to the carrier
                                </small>
                            </div>

                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                            <div class="form-group ">
                                <label class="control-label">Special Conditions:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-message-square">
                                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <textarea name="carrier_special_conditions" class="form-control"
                                        [(ngModel)]="boardJobForm.special_conditions" rows="7"></textarea>
                                </div>
                                <small class="form-text text-muted mb-4">
                                    if you have something to add to the contract... you can add it here
                                </small>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <div class="row">

                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Vip board</label> &nbsp;&nbsp;
                                <input type="checkbox" name="board_job_vip" class="margin-checkbox"
                                    [(ngModel)]="boardJobForm.is_vip">
                                <small class="form-text text-muted mb-4">
                                    indicates whether it should be displayed in the workspace's VIP private workspace
                                    table
                                </small>
                            </div>
                        </div>


                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Private board</label>&nbsp;&nbsp;
                                <input type="checkbox" name="board_job_private" class="margin-checkbox"
                                    [(ngModel)]="boardJobForm.is_private">
                                <small class="form-text text-muted mb-4">
                                    indicates whether it should be displayed in the private workspace table
                                </small>
                            </div>
                        </div>


                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="control-label">Public board</label>&nbsp;&nbsp;
                                <input type="checkbox" name="board_job_public" class="margin-checkbox"
                                    [(ngModel)]="boardJobForm.is_public">
                                <small class="form-text text-muted mb-4">
                                    indicates if it should be displayed in the public dashboard that moverxpro360 has
                                    for all jobs in all workspaces
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="text-center" style="width: 100%;">
                        <span *ngIf="boardJobForm.offer >= job.estimate.total" style="color: red;">
                            Maximum Carrier Balance Offer
                        </span>
                        <button class="btn" (click)="closeBoardJobModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Close
                        </button>
                        <button class="btn btn-warning" [disabled]="boardJobForm.offer >= job.estimate.total"
                            (click)="saveBoardJob()" type="submit">
                            <span *ngIf="boardJobForm.id == null">
                                Save
                            </span>
                            <span *ngIf="boardJobForm.id != null">
                                Update
                            </span>

                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>



<div class="modal fade modalCarriers" #candidateCarrierAssignmentModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content" *ngIf="jobOutsourcingForm !== null">

            <form class="form" #candidateCarrierAssignmentForm="ngForm" ngNativeValidate>

                <div class="modal-header">

                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong style="color: #e44f00;">
                            candidate carrier assignment
                        </strong>
                    </h4>

                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                            <div class="form-group">
                                <label class="control-label">Carrier Balance Offer</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01" placeholder="Carrier Balance Offer"
                                        class="form-control" name="offer" [(ngModel)]="jobOutsourcingForm.carrier_pay"
                                        (keyup)="updateOutsourcingOfferPercentage()">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    enter the value to be offered to the carrier so that it can do the job
                                </small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Carrier Balance Percentage</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input id="percentage" type="number" step="0.01"
                                        placeholder="Carrier Balance Percentage" disabled="true" class="form-control"
                                        name="percentage" value="{{boardJobPercentage}}">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    this field indicates the percentage of the work that is being given to the carrier
                                </small>
                            </div>

                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                            <div class="form-group ">
                                <label class="control-label">Special Conditions:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-message-square">
                                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <textarea name="carrier_special_conditions" class="form-control"
                                        [(ngModel)]="jobOutsourcingForm.special_conditions" rows="7"></textarea>
                                </div>
                                <small class="form-text text-muted mb-4">
                                    if you have something to add to the contract... you can add it here
                                </small>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-12">
                            <div class="form-group " *ngIf="carrierCandidateContact.length">
                                <label class="control-label text-success">Selected Contacts To Notify:</label>
                                <div class="col-md-4 cursor-pointer" *ngFor="let contact of carrierCandidateContact"  [ngClass]="{'checkContact': carrierCandidateSelectContact.includes(contact)}" (click)="toogleContacts(contact)">
                                    {{contact.name}}
                                </div>
                               
                            </div>
                            <div class="form-group " *ngIf="!carrierCandidateContact.length">
                                <h4 class="control-label text-danger" >Not Contact Carrier</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="text-center" style="width: 100%;">
                        <span *ngIf="jobOutsourcingForm.carrier_pay >= job.estimate.total" style="color: red;">
                            Maximum Carrier Balance Offer
                        </span>
                        <button class="btn" (click)="closeCandidateCarrierAssignmentModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Close
                        </button>
                        <button class="btn btn-warning" [disabled]="jobOutsourcingForm.carrier_pay >= job.estimate.total"
                            (click)="assignCarrierCandidateToJob()" type="submit">
                            <span >
                                Save
                            </span>
                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>

<div class="modal fade modalCarriers" #modalCreateCarrierCandidate tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">

            <form class="form" #candidateCarrierAssignmentForm="ngForm" ngNativeValidate>

                <div class="modal-header">

                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong style="color: #e44f00;">
                            Add carrier Candidate
                        </strong>
                    </h4>

                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                            <div class="form-group">
                                <label class="control-label">Select Carrier</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <select [(ngModel)]="createCarrierCandidate.carrier_id" name="createCarrierCandidatecarrierid" class="form-control method select-methods"  >
                                        <option value="">

                                        </option>
                                        <option *ngFor="let carrier of carriers"  value="{{carrier.id}}">
                                            {{carrier.name}}
                                        </option>
                                    </select>
                                  
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="control-label">Carrier Bid</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input id="percentage" type="number" class="form-control" name="createCarrierCandidatebid" [(ngModel)]="createCarrierCandidate.bid" >
                                </div>
                            </div>

                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                            <div class="form-group ">
                                <label class="control-label">Comments:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-message-square">
                                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <textarea name="carrier_special_conditions" class="form-control"
                                        [(ngModel)]="createCarrierCandidate.comments" rows="7"></textarea>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="text-center" style="width: 100%;">
                        <button class="btn" (click)="closeModalCreateCarrierCandidate()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Close
                        </button>
                        <button class="btn btn-warning" 
                            (click)="saveCreateCarrierCandidate()" type="submit">
                            <span >
                                Save
                            </span>
                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>