<div class="page-container">
  <div class="billing-container" id="container">

        <div class='stars'></div>
        <div class='twinkling'></div>
        <div class='clouds'></div>

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content content">
        <div class="logo">
            <img src="/assets/img/logo_moverxpro360_w.png" class="logo-brand">
        </div>
        <div class="col-8">
            <div class="title">
                Oops! You don't currently have access to MoverXpro 360
            </div>
            <div class="subtitle">
                You are not authorized to manage payment methods of financial account. Please contact finance manager or the owner.
            </div>
        </div>
        <div class="col-4">
            <img class="block" src="/assets/img/block.png" alt="">
        </div>
      </div>
  </div>
</div>
