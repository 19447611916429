export class StripeSetting {
    id: string;
    secret_key: string;
    publishable_key: string;

    constructor() {
        this.id = null;
        this.secret_key = null;
        this.publishable_key = null;
    }
}
