import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneRingGroupsService {

  constructor(private http: HttpClient) {

  };

  getAll(): Promise<Array<PhoneRingGroup>> {
    return this.http.get<Array<PhoneRingGroup>>(environment.api.workspace + '/phone-ring-groups').toPromise();
  }

  getAllCompany(): Promise<Array<PhoneRingGroup>> {
    return this.http.get<Array<PhoneRingGroup>>(environment.api.workspace + '/phone-ring-groups',
    { params: { 'company-name': '1'} }).toPromise();
  }

  // getAllNums(): Promise<Array<PhoneNumber>> {
  //   return this.http.get<Array<PhoneNumber>>(environment.api.workspace + '/pbx-phone-lines-nums').toPromise();
  // };

  getById(id: string) {
    return this.http.get<PhoneRingGroup>(environment.api.workspace + '/phone-ring-groups/' + id).toPromise();
  }

  save(dataForm: PhoneRingGroup): Promise<any> {
    if (dataForm.id === null) {
        return this.add(dataForm);
    }
    return this.edit(dataForm);
};

  add(dataForm: PhoneRingGroup) {
    return this.http.post(environment.api.workspace + '/phone-ring-groups', dataForm).toPromise();
  };

  edit(dataForm:PhoneRingGroup){
    return this.http.put(environment.api.workspace + '/phone-ring-groups/' + dataForm.id, dataForm).toPromise();

  };

  remove(id: string): Promise<Array<PhoneRingGroup>> {
    return this.http.delete<Array<PhoneRingGroup>>(environment.api.workspace + '/phone-ring-groups/' + id).toPromise();
  };

}
