import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlackoutMoveDate } from 'src/app/entities/workspace/blackout-move-date';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlackoutMoveDatesService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<BlackoutMoveDate>> {
        return this.http.get<Array<BlackoutMoveDate>>(environment.api.company + '/blackout-move-dates').toPromise();
    }
    
}
