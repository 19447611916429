<div class="layout-top-spacing">
  
    <div class="row">

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                LD Tariff {{sectionName}}
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                
                    <li class="breadcrumb-item active" aria-current="page">
                        LD Tariff {{sectionName}}
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 select-tariff-center">
            <label>Version</label>
            <select [(ngModel)]="tariffVersionId" (ngModelChange)="tariffVersion($event)" name="tariff_version" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                <option *ngFor="let tariffVersion of tariffVersions" value="{{tariffVersion.id}}">{{ tariffVersion.name }} - {{ tariffVersion.measure_unit }}</option>
            </select>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
        </div>

    </div>
<!-- header -->

    <div class="tab-menu-section">

        <!-- MENU TAB -->
        <div class="tab-menu">
            <div class="btn-group block-general-settings-sections">
                <button type="button" [ngClass]="{'opt-active': btnActived == 'zones'}" class="btn opt-disabled btn-lg" (click)="zones()">
                    Zones
                </button>           
                <button type="button" [ngClass]="{'opt-active': btnActived == 'zonesStates'}" class="btn opt-disabled btn-lg" (click)="zonesStates()">
                    Zones States
                </button>       
                <button type="button" [ngClass]="{'opt-active': btnActived == 'volumes'}" class="btn opt-disabled btn-lg" (click)="volumes()">
                    Volumes
                </button>         
                <button type="button" [ngClass]="{'opt-active': btnActived == 'periods'}" class="btn opt-disabled btn-lg" (click)="periods()">
                    Periods
                </button>        
                <button type="button" [ngClass]="{'opt-active': btnActived == 'routeRates'}" class="btn opt-disabled btn-lg" (click)="routeRates()">
                    Route Rates
                </button>          
                <button type="button" [ngClass]="{'opt-active': btnActived == 'routeRatesMap'}" class="btn opt-disabled btn-lg" (click)="routeRatesMap()">
                    Route Rates Map
                </button>
                <button type="button" [ngClass]="{'opt-active': btnActived == 'ldAdditionalStopRules'}" class="btn opt-disabled btn-lg" (click)="ldAdditionalStopRules()">
                    LD Additional Stop Rules
                </button>
                </div>
        </div>

        <!-- <div class="row select-tariff-center">
            <div class="col-3"></div>
            <div class="col-6">
                <label>Version</label>
                <select [(ngModel)]="tariffVersionId" (ngModelChange)="tariffVersion($event)" name="tariff_version" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                    <option *ngFor="let tariffVersion of tariffVersions" value="{{tariffVersion.id}}">{{ tariffVersion.name }} - {{ tariffVersion.measure_unit }}</option>
                </select>
            </div>
            <div class="col-3"></div>
        </div> -->

    </div>

</div>

<router-outlet></router-outlet>
