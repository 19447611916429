import { CommissionSetting } from 'src/app/entities/workspace/commission-setting';
import { Employee } from 'src/app/entities/workspace/employee';
import { CommissionSettingsService } from 'src/app/services/companies/commission-settings.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-commission-settings-view',
    templateUrl: './commission-settings-view.component.html',
    styleUrls: ['./commission-settings-view.component.scss']
})
export class CommissionSettingsViewComponent implements OnInit {

    row: CommissionSetting;
    employee: Employee;
    soldEmployee: Employee;
    soldWorkDepartment: WorkDepartment;
    actualPage = 1;


    constructor(
        private commissionSettingsService: CommissionSettingsService,
        private employeesService: EmployeesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.row = new CommissionSetting();
        this.employee = new Employee();
        this.soldEmployee = new Employee();
        this.soldWorkDepartment = new WorkDepartment();

    }

    ngOnInit() {

    }


    ngAfterViewInit(): void {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });

    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .getById(id)
            .then((response) => {
                // this.row = response;
                // this.loadEmployee(this.row.employee_id);
                // if (this.row.sold_by == 'EMPLOYEE') {
                //     this.loadSoldEmployee(this.row.sold_by_employee_id);
                // } else {

                // }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    private loadEmployee(id) {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getById(id)
            .then((response) => {
                this.employee = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadSoldEmployee(id) {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getById(id)
            .then((response) => {
                this.soldEmployee = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
