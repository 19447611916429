<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Payroll Payments
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Payroll Payments</li>
                </ol>
            </nav>
        </div>


        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right top-actions-btns">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
                <a appCompanyRouterLink="/payroll-payments/add" class="btn btn-new" appCanUserWorkspace="COMPANIES_CREATE">
                    <i class="fas fa-plus-square mr-2"></i>
                    <span>New Payroll Payments</span>
                </a>
            </div>
        </div>

    </div>
    <!-- header -->


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <!-- <div class="col-lg-12 col-md-12">
            <app-date-filter class="estimates-date-filter text-center" (onChange)="getFilterDates($event)"></app-date-filter>
        </div> -->
        <div class="col-md-6 offset-md-3 block-search">
            <div class="form-group">
                <label class="control-label">Select period:</label>
                <select [(ngModel)]="selectedPeyroll" name="selectedPeriod" class="form-control">
                    <option value="">
                        All
                    </option>
                    <option *ngFor="let payrollDate of payrollDates" [ngValue]="payrollDate.id">
                        {{ formatDate(payrollDate.start_period) }} - {{ formatDate(payrollDate.end_period) }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row col-lg-12 col-md-12 block-search">

            <div class="col-1">
                
            </div>

            <div class="col-2 commission-breakdown-switch">
                <div class="toggle-switch">
                    <label class="switch s-icons s-outline  s-outline-secondary">
                        <input type="checkbox" [(ngModel)]="swichEmployeeWD" name="swichEmployeeWD">
                        <span class="slider round">
                            <i class="fa-solid fa-user icon-employee"></i>
                            <i class="fa-solid fa-briefcase icon-work-department"></i>
                        </span>
                    </label>
                </div>
            </div>      
            
            <div class="col-5" *ngIf="swichEmployeeWD">
                <label class="control-label">Work Department</label>
                <select name="wd_workDepartmen_id" class="form-control" [(ngModel)]="workDepartmentSelected">
                    <option value="ALL">
                        All Work Departments
                    </option>
                    <option *ngFor="let workDepartment of workDepartments" [value]="workDepartment.id">{{ workDepartment.name | titlecase}}</option>
                </select>
            </div>

            <div class="col-5" *ngIf="!swichEmployeeWD">
                <label class="control-label">Employee</label>
                <select name="wd_employees_id" class="form-control" [(ngModel)]="employeeId">
                    <option value="ALL">
                        All Employees
                    </option>
                    <option *ngFor="let employee of employees" [value]="employee.id">{{ employee.name | titlecase}}</option>
                </select>
            </div>            
            
            <div class="col-2 block-btn-search">
                <button class="btn btn-secondary btn-search" (click)="search()">
                    Search
                </button>
            </div>  
        </div>  
    </div>

    <!-- Payroll Payments Info -->
    <div class="widget-content widget-content-area">

        <div class="widget-heading mb-4">
            <h3 class="title-content-area">
                Totals Payroll Payments
            </h3>
        </div>
       
        <div class="row">

            <div class="col-3 form-group mb-3">
                <label for="employee">Total Commision Owed</label>
                <div class="output-box">{{ totalCommisionOwed | currency }}</div>
            </div>

            <div class="col-3 form-group mb-3">
                <label for="pay_roll">Total Base Amount Owed</label>
                <div class="output-box">{{ totalBaseAmountOwed | currency }}</div>
            </div>
            
            <div class="col-3 form-group total-pay mb-3">
                <label for="pay_roll">Total Hourly Pay Owed</label>
                <div class="output-box">{{ totalHourlyPayOwed | currency }}</div>
            </div>  

            <div class="col-3 form-group mb-3">
                <label for="employee">Total Bonuses Owed</label>
                <div class="output-box">{{ totalBonusesOwed | currency }}</div>
            </div>

            <div class="col-3 form-group mb-3">
                <label for="employee">Total Deductions</label>
                <div class="output-box">{{ totalDeductions | currency }}</div>
            </div>

            <div class="col-3 form-group mb-3">
                <label for="pay_roll">Total Draw</label>
                <div class="output-box">{{ totalDraw | currency }}</div>
            </div>
            
            <div class="col-3 form-group total-pay mb-3">
                <label for="pay_roll">Total Owed</label>
                <div class="output-box">{{ totalOwed | currency }}</div>
            </div>  
            
             

        </div>

    </div>
  
    <div class="widget-content searchable-container list mt-4">
        <div class="searchable-items list">
            <div class="items items-header-section">
                <div class="item-content">
                    <div class="col-customer">
                        <h4>
                            CUSTOMER
                        </h4>
                    </div>
                    <div class="col-summary">
                        <h4>
                            SUMMARY
                        </h4>
                    </div>
                    <div class="col-status">
                        <h4 class="m-0">
                            STATUS
                        </h4>
                    </div>
                    <div class="col-actions">
                        <h4>
                            ACTIONS
                        </h4>
                    </div>
                </div>
            </div>
            <div class="items cursor-pointer" *ngFor="let payrollPayment of payrollList">
                <div class="item-content">
                    <div class="col-customer">
                        <p>
                            <strong>Employee: </strong>
                            <span>{{ payrollPayment.employee?.name | titlecase}}</span> 
                        </p>
                        <p class="mt-1">
                            <strong>Position: </strong>
                            <span>{{ payrollPayment.employee?.job_position | filterReplace}}</span>
                        </p>
                        <p class="mt-1">
                            <strong>Base Pay: </strong>
                            <span>{{ payrollPayment.base_pay | currency }}</span>
                        </p>  
                        <p class="d-flex align-items-center mt-1" *ngIf="payrollPayment.total_draw">
                            <strong>Outstanding Draw: </strong>
                            <span>{{ payrollPayment.total_draw | currency }}</span>
                        </p>
                    </div>
                    <div class="col-summary">
                        <p class="">
                            <strong>Period: </strong> 
                            <span>{{ payrollPayment.payroll.start_period | date }} - {{ payrollPayment.payroll.end_period | date }} </span>
                        </p>
                        <p class="">
                            <strong>Previous Total Draw: </strong> 
                            <span>{{ payrollPayment.previous_total_draw | currency }} </span>
                        </p>
                        <p class="">
                            <strong>Commission: </strong>
                            <span>{{ payrollPayment.total_commissions | currency }}</span>
                        </p>
                        <p class="">
                            <strong>Total Hourly: </strong>
                            <span>{{ payrollPayment.total_hourly | currency }}</span>
                        </p>                    
                        <p class="">
                            <strong>Bonuses: </strong>
                            <span>{{ payrollPayment.total_bonuses | currency }}</span>
                        </p>                    
                        <p class="">
                            <strong>Deductions: </strong>
                            <span>-{{ payrollPayment.total_deductions | currency }}</span>
                        </p>                                
                        <p class="">
                            <strong>Total Pay: </strong>
                            <span>{{ payrollPayment.total | currency }}</span>
                        </p>                    
                    </div>
                    <div class="col-status">
                        <div class="td-content">
                            <span filterReplace [ngClass]="{'badge-warning': payrollPayment.status == 'PENDING_PAYMENT' || payrollPayment.status == 'PENDING_REFUND', 'badge-success': payrollPayment.status == 'PAID', 'badge-danger': payrollPayment.status == 'VOID' || payrollPayment.status == 'REFUNDED'}" class="badge">
                                {{ payrollPayment.status | filterReplace}}
                            </span>
                        </div>
                    </div>
                    <div class="col-actions text-right">               
                        <a appCompanyRouterLink="/payroll-payments/{{payrollPayment.id}}/view" class="btn btn-info button-space">
                            Review Breakdown
                        </a>
                        <a class="btn btn-success button-space" (click)="payBreakdown(payrollPayment.id)" *ngIf="payrollPayment.status != 'PAID'">
                            Pay
                        </a>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
    <!-- body -->
</div>