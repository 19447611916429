export class EstimateInsurance {
    public id: string;
    public type: string;
    public liability_amount: number;
    public deductible_amount: number;
    public valuation_charge: number;


    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.type = null;
        this.liability_amount = null;
        this.deductible_amount = null;
        this.valuation_charge = null;
    }

}
