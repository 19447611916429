<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>                        
                        Payroll
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                             <li class="breadcrumb-item active" aria-current="page">Payroll</li>
                        </ol>
                    </nav>
                    <br>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search align-self-center mb-3">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input type="text" name="filterSearch" [(ngModel)]="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-new" appCompanyRouterLink="/payroll-settings/add">
                    <i class="fas fa-plus-square"></i> &nbsp; New Employee to Payroll
                </a>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">

        <div class="col-12 mb-4" *ngFor="let payrollSetting of payrollSettings" >
            <div class="widget-content widget-content-area">

                <div class="row justify-content-between justify-content-center mb-4">
                    <div class="col-12">

                        <div class="row">
                        
                            <div class="col-5 form-group mb-3">
                                <label for="hourly_rate">Employee</label>
                                <div class="input-group mb-1">
                                    <div class="output-box">
                                        <ng-container  *ngFor="let employe of employees">
                                            <ng-container *ngIf="employe.id == payrollSetting.employee_id">
                                            {{employe.name | titlecase}}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2 form-group mb-3">
                                <label for="hourly_rate">Base Amount</label>
                                <div class="input-group mb-1">
                                    <div class="output-box">{{payrollSetting.base_per_week}}</div>
                                </div>
                            </div>

                            <div class="col-5 form-group mb-3">

                                <label for="" class="mb-3">Base Options</label>

                                <div class="d-flex">

                                    <div class="n-chk mr-2">
                                        <label class="new-control new-radio radio-success">
                                            <input type="radio" class="new-control-input" name="marker{{payrollSetting.id}}" value="bg-warning" [(ngModel)]="payrollSetting.pay_mode" value="HOURLY" disabled>
                                            <div class="new-control-indicator"></div> Hours
                                        </label>
                                    </div>

                                    <div class="n-chk mr-2">
                                        <label class="new-control new-radio radio-success">
                                            <input type="radio" class="new-control-input" name="marker1{{payrollSetting.id}}" value="bg-warning" [(ngModel)]="payrollSetting.pay_mode" value="WEEKLY" disabled>
                                            <div class="new-control-indicator"></div> Week
                                        </label>
                                    </div>

                                    <div class="form-group">
                                        <div class="toggle-switch m-0">                
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="vs_commission" name="vs_commission" [(ngModel)]="payrollSetting.vs_commission" disabled>
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="vs_commission" class="checkbox-label" [ngClass]="{'filter-disabled': !payrollSetting.vs_commission}">
                                                Vs Commission
                                            </label>
                                        </div>
                                    </div>  

                                </div>        

                            </div> 

                            <button class="btn btn-success edit-payroll-settings" appCompanyRouterLink="/payroll-settings/{{payrollSetting.id}}/edit">
                                <i class="fa-solid fa-pen-to-square mr-2"></i>
                                Edit
                            </button>

                        </div>

                    </div>
                    
                </div>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Job Type</th>
                                <th>Value</th>
                                <th>Based On</th>
                                <th>Sold By</th>
                                <th>Condition</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="payrollSetting.commissions_settings">
                            <tr *ngFor="let rowCommissionSetting of payrollSetting.commissions_settings">
                                <td>{{rowCommissionSetting.job_type | filterReplace}}</td>
                                <td>
                                    <ng-container *ngIf="rowCommissionSetting.type != 'PERCENTAGE'">
                                        $
                                    </ng-container>
                                    {{rowCommissionSetting.value}}
                                    <ng-container *ngIf="rowCommissionSetting.type == 'PERCENTAGE'">
                                        %
                                    </ng-container>
                                    </td>
                                <td>{{rowCommissionSetting.value_base_on | filterReplace}}</td>
                                <ng-container *ngIf="rowCommissionSetting.sold_by == 'EMPLOYEE'">
                                    <td>
                                        <ng-container  *ngFor="let employe1 of employees">
                                                <ng-container *ngIf="employe1.id == rowCommissionSetting.sold_by_employee_id">
                                                    {{employe1.name | titlecase}}
                                                </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="rowCommissionSetting.sold_by == 'WORK_DEPARTMENT'">
                                    <td>
                                        <ng-container *ngFor="let workDepartment1 of work_departments">
                                                <ng-container *ngIf="workDepartment1.id == rowCommissionSetting.sold_by_work_department_id">
                                                    {{workDepartment1.name | titlecase}}
                                                </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <td *ngIf="rowCommissionSetting.condition">{{rowCommissionSetting.condition.type}} ({{rowCommissionSetting.condition.threshold_start}}<ng-container *ngIf="rowCommissionSetting.condition.type == 'BETWEEN'"> - </ng-container>{{rowCommissionSetting.condition.threshold_end}})</td>
                                <td *ngIf="!rowCommissionSetting.condition"></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls> -->
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
