import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { Employee } from 'src/app/entities/workspace/employee';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { SaleCommission } from 'src/app/entities/workspace/sale-commission';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from 'src/app/services/companies/balances.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { PaymentsService } from 'src/app/services/companies/payments.service';
import { SalesCommissionsService } from 'src/app/services/companies/sales-commissions.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { environment } from 'src/environments/environment';
import { ReportPaymentView } from '../../../entities/workspace/report-payment-view';

declare const jQuery;
declare const swal;
@Component({
    selector: 'app-payments-view',
    templateUrl: './payments-view.component.html',
    styleUrls: ['./payments-view.component.scss']
})
export class PaymentsViewComponent implements OnInit {

    balance: BalanceView;
    estimate: Estimate;
    employee: Employee;
    public payment: ReportPaymentView;
    public indexSelected: number;
    public commissionsList: SaleCommission[];
    readonly environment = environment;

    /*
    Pagos asociados a un balance
    */
    public payments: Array<ReportPaymentView>;

    public actualPage: number;
    constructor(
        private balancesService: BalancesService,
        private currentRoute: ActivatedRoute,
        private employeesService: EmployeesService,
        private estimatesService: EstimatesService,
        private helperService: HelperService,
        private paymentsService: PaymentsService,
        private salesCommissionsService: SalesCommissionsService,
        public authService: AuthService,
    ) {
        this.balance = new BalanceView();
        this.estimate = new Estimate();
        this.employee = new Employee();
        this.payments = [];
        this.payment = new ReportPaymentView();
        this.indexSelected = 0;
        this.commissionsList = [];
        this.actualPage = 1;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            jQuery('.btn-voucher').click(function () {
                jQuery('#panel-voucher-' + jQuery(this).attr('id')).toggle();
            });

            jQuery('.btn-transaction').click(function () {
                jQuery('#panel-transaction-' + jQuery(this).attr('id')).toggle();
            });
        }, 1000);

        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadData(params.id);
            }
        });

        document.addEventListener('mousemove', function (event) {
            let card_x: any = getTransformValue(event.clientX, window.innerWidth, 56);
            let card_y: any = getTransformValue(event.clientY, window.innerHeight, 56);
            let shadow_x: any = getTransformValue(event.clientX, window.innerWidth, 20);
            let shadow_y: any = getTransformValue(event.clientY, window.innerHeight, 20);
            let text_shadow_x: any = getTransformValue(event.clientX, window.innerWidth, 28);
            let text_shadow_y: any = getTransformValue(event.clientY, window.innerHeight, 28);
            jQuery(".floating").css("transform", "rotateX(" + card_y / 1 + "deg) rotateY(" + card_x + "deg)");
            jQuery(".floating").css("box-shadow", -card_x + "px " + card_y / 1 + "px 55px rgba(0, 0, 0, .55)");
            jQuery(".svg").css("filter", "drop-shadow(" + -shadow_x + "px " + shadow_y / 1 + "px 4px rgba(0, 0, 0, .6))");
            jQuery(".text").css("text-shadow", -text_shadow_x + "px " + text_shadow_y / 1 + "px 6px rgba(0, 0, 0, .8)");
        });

        function getTransformValue(v1, v2, value) {
            return ((v1 / v2 * value - value / 2) * 1).toFixed(1);
        }

        jQuery(function () {
            setTimeout(function () {
                jQuery("body").removeClass("active");
            }, 2200);
        });
    }

    private loadData(idBalance) {
        this.load(idBalance);
        this.loadPayments(idBalance);
    }

    private loadCommissions(id: string) {
        this.salesCommissionsService
            .getSalesCommissionByPaymentId(id)
            .then((response) => {
                this.commissionsList = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            });
    }

    assignNewCommissions() {
        swal({
            title: 'Are You Sure??',
            text: "Do you really want to add commissions to the register?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, add it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.salesCommissionsService
                        .addSalesCommissionByPaymentId(this.payment.id)
                        .then(() => {
                            this.loadCommissions(this.payment.id);
                            swal(
                                'Added!',
                                'The register was added.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.balancesService
            .getDetailBalanceById(id)
            .then((response) => {
                this.balance = response;
                let urlPdf = null;

                if (this.balance.estimate_document) {
                    if (this.balance.estimate_document[0] && this.balance.estimate_document[0].file) {
                        urlPdf = this.balance.estimate_document[0].file.name.replace('estimate_', '');
                    }
                }

                this.loadEstimate(this.balance.job_id);

                if (!urlPdf) {
                    return;
                }
                setTimeout(() => {
                    jQuery('#enbebido').append(' <embed src="' + environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/documents/' + urlPdf + '" width="100%" height="244" type="application/pdf">');

                }, 2000);
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadEmployee(id: string) {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getById(id)
            .then((response) => {
                this.employee = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response) => {
                this.estimate = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Carga los payments de un balance
     */
    loadPayments(idBalance: string) {
        this.helperService.showLoadingMxpro360();
        this.paymentsService
            .getPaymentsByBalance(idBalance)
            .then((response) => {
                this.payments = response;
                if (this.payments.length > 0) {
                    this.selectPayment(0);
                    this.loadCommissions(this.payment.id);
                }
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    selectPayment(index: number) {
        this.payment = this.payments[index];
        this.indexSelected = index;
    }

    getStatusBalance(isPayment) {
        return new Promise(resolve => {
            let status = this.payment.is_partial_payment ? (isPayment ? 'PARTIAL_PAID' : 'PARTIAL_PENDING_REVIEW') : (isPayment ? 'PAID' : 'PENDING');
            if (isPayment) {
                if (status == 'PARTIAL_PAID') {
                    let totalPaid = 0;
                    for (let payment of this.payments) {
                        // si es un pago aceptado o es el pago actual se suma el total para luego ser comparado
                        if (payment.status == 'ACCEPTED' || this.payment.id == payment.id) {
                            totalPaid += payment.amount;
                        }
                        if (payment.status == 'PARTIAL_PENDING_REVIEW' && this.payment.id !== payment.id) {
                            status = 'PARTIAL_PENDING_REVIEW';
                            break;
                        }
                    }
                    // si el total pagado suma el mismo valor del balance entonces es estatus pagado
                    if (totalPaid == this.balance.amount) {
                        status = 'PAID';
                    }
                }
            }
            return resolve(status);
        });

    }

    /**
     * Procesa el estado del pago
     *
     * @param isPayment Se determina si ya se encuentra pago el balance y el payment asociado
     */
    processPayment(isPayment: boolean) {

        this.helperService.showLoadingMxpro360();
        this.paymentsService
            .existPaymentInBalance(this.balance.id)
            .then(async (response) => {
                if (response.payment) {
                    swal(
                        'There is an approved payment',
                        'Please reload the page',
                        'warning'
                    );

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                } else {
                    const statusPayment = isPayment ? 'ACCEPTED' : 'DECLINED';
                    const statusBalance = await this.getStatusBalance(isPayment);
                    const dataPatch: any = {
                        status: statusPayment,
                        employee_id: this.authService.workspaceSession.employee.id
                    };

                    if (this.payment.payment_method) {

                        if (this.payment.payment_method.method === 'BANK_TRANSFER_OFFLINE' || this.payment.payment_method.method === 'CHECK' ||
                            this.payment.payment_method.method === 'MONEY_ORDER' || this.payment.payment_method.method === 'CASH') {
                            if (this.payment.date !== null) {
                                dataPatch.date = this.payment.date;
                            } else {
                                swal(
                                    'Please select the day of the transaction according to the voucher',
                                    'There is not a selected date',
                                    'warning'
                                );
                                return;
                            }
                        }

                    }

                    this.helperService.showLoadingMxpro360();
                    this.paymentsService
                        .patchEntity(this.payment.id, dataPatch)
                        .then(() => {
                            this.balancesService
                                .patchEntity(this.balance.id, { status: statusBalance })
                                .then(() => {
                                    this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
                                    this.load(this.balance.id);
                                    this.loadPayments(this.balance.id);
                                })
                                .catch((error) => {
                                    this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                                    console.error('error', error);
                                })
                                .finally(() => {
                                    this.helperService.hideLoadingMxpro360();
                                });
                        })
                        .catch((error) => {
                            this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Abre la imagen del voucher
     *
     * @param nameImage Nombre de la imagen en el servidor
     */
    openImage(nameImage: string) {
        window.open(environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/payment-vouchers/' + nameImage, '_blank');
    }

    public changeTransactionDay(event) {
        this.payment.date = event;
    }

    deleteCommission(commissionId: string) {
        swal({
            title: 'Are You Sure??',
            text: "Do you really want to delete this commission?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.salesCommissionsService
                        .deleteSalesCommissionByPaymentId(commissionId)
                        .then(() => {
                            this.loadCommissions(this.payment.id);
                            swal(
                                'Deleted!',
                                'The register was deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimateId) {
        // Redirige a la ventana de estmate
        this.helperService.openNewTab(estimateId, 'estimate');
    }

    removePaymentAttempt(payment: ReportPaymentView) {
        swal({
            title: 'Are You Sure??',
            text: 'Do you really want to delete this payment?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.paymentsService
                        .remove(payment.id)
                        .then(() => {
                            this.loadData(payment.balance_id);
                            swal(
                                'Deleted!',
                                'The register was deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    public collapseVouchers(event): void {
        const a = jQuery(event.toElement.parentElement.parentElement.parentElement.parentElement);
        const b = jQuery(event.toElement.parentElement.parentElement.parentElement);
        const c = jQuery(event.toElement.parentElement.parentElement);
        const d = jQuery(event.toElement.parentElement);
        // event.toElement.parentElement.parentElement.parentElement.parentElement.accordion({
        //    animate: 200
        // });


        /*
         jQuery('vouchers').on('click', () => {
             jQuery('.information-col').slideToggle();
         });
         
         jQuery(event.toElement.parentElement.parentElement.parentElement.parentElement).accordion({
             collapsible: true
         });
         */

        // jQuery('#accordion .collapse').on('show.bs.collapse', function (e) {
        // jQuery('#accordion .in').collapse('hide');
        // });


        /*
        jQuery(event.toElement.parentElement.parentElement.parentElement.parentElement).on("hide.bs.collapse", function () {
            
        });

        jQuery(event.toElement.parentElement.parentElement.parentElement.parentElement).on("show.bs.collapse", function () {
            
        });
        */
        // jQuery(event.toElement.parentElement.parentElement.parentElement.parentElement).collapse('hide')

    }
}
