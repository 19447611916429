<div class="section-title">
    Invoices
</div>

<div class="searchable-items list">
    <div class="items items-header-section">
        <div class="item-content">
            <div class="col col-20-invoice">
                <h4>
                    Invoice Number
                </h4>
            </div>
            <div class="col col-10-invoice">
                <h4 style="margin-left: 3px;">
                    Issue Date
                </h4>
            </div>
            <div class="col col-20-invoice">
                <h4 style="margin-left: 3px;">
                    Billing Period
                </h4>
            </div>
            <div class="col col-10-invoice">
                <h4 style="margin-left: 3px;">
                    Total Accounts
                </h4>
            </div>
            <div class="col col-10-invoice">
                <h4 style="margin-left: 3px;">
                    Total Companies
                </h4>
            </div>
            <div class="col col-10-invoice">
                <h4 style="margin-left: 3px;">
                    Total Amount
                </h4>
            </div>
            <div class="col col-8-invoice">
                <h4 style="margin-left: 3px;">
                    Status
                </h4>
            </div>

            <div class="col col-10-invoice">
                <h4 style="margin-left: 3px;">
                    Actions
                </h4>
            </div>
        </div>
    </div>

    <div class="items items-content-section cursor-pointer" *ngFor="let invoice of invoices">
        <div class="item-content" role="menu">

            <div class="user-profile col col-20-invoice text-center">
                <div class="user-meta-info info">
                    <p class="user-name">
                        {{ invoice.id}}
                    </p>
                </div>
            </div>

            <div class="user-profile col col-10-invoice text-center">
                <div class="user-meta-info info">
                    <p class="user-name">
                        {{ invoice.created | date }}
                    </p>
                </div>
            </div>

            <div class="user-profile col col-20-invoice text-center">
                <div class="user-meta-info info">
                    <p class="user-name">
                        {{ invoice.start_period | date }} -> {{ invoice.end_period | date }}
                    </p>
                </div>
            </div>

            <div class="user-profile col col-10-invoice text-center">
                <div class="user-meta-info info">
                    <p class="user-name">
                        <ng-container *ngIf="invoice.total_users != null">
                            {{invoice.total_users}}
                        </ng-container>
                        <ng-container *ngIf="invoice.total_users == null">
                            --
                        </ng-container>
                    </p>
                </div>
            </div>

            <div class="user-profile col col-10-invoice text-center">
                <div class="user-meta-info info">
                    <p class="user-name">
                        <ng-container *ngIf="invoice.total_companies != null">
                            {{invoice.total_companies}}
                        </ng-container>
                        <ng-container *ngIf="invoice.total_companies == null">
                            --
                        </ng-container>
                    </p>
                </div>
            </div>

            <div class="user-profile col col-10-invoice text-center">
                <div class="user-meta-info info">
                    <p class="user-name">
                        {{ invoice.total_amount | currency }}
                    </p>
                </div>
            </div>

            <div class="user-profile col col-8-invoice text-center">
                <div class="user-meta-info info">
                    <span *ngIf="invoice.total_amount > 0"
                        [ngClass]="{'badge-danger': invoice.status == 'PENDING', 'badge-success': invoice.status == 'PAID'}"
                        class="badge">
                        {{ invoice.status}}
                    </span>
                    <span *ngIf="invoice.total_amount == 0" class="badge badge-warning">
                        FREE
                    </span>
                </div>
            </div>

            <div class="user-profile col col-10-invoice btn-actions text-center">

                <a routerLink="/my-profile/workspaces/{{workspace_id}}/billing/process-payment/{{invoice.id}}" *ngIf="invoice.status == 'PENDING' && invoice.total_amount > 0" class="btn btn-success mb-1">
                    Pay
                </a>

                <button *ngIf="invoice.status == 'PAID'" class="btn btn-secondary mb-1">
                    <i class="fa-solid fa-file-arrow-down"></i>
                </button>
                
                <a routerLink="/my-profile/workspaces/{{workspace_id}}/billing/process-payment/{{invoice.id}}" *ngIf="invoice.status == 'PAID'" class="btn btn-info mb-1">
                    <i class="fa-solid fa-eye"></i>
                </a>

            </div>
        </div>
    </div>
</div>

