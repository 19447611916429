import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { CommissionSetting } from 'src/app/entities/workspace/commission-setting';
import { Employee } from 'src/app/entities/workspace/employee';
import { PayrollSetting } from 'src/app/entities/workspace/payroll-setting';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { CommissionSettingsService } from 'src/app/services/companies/commission-settings.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PayrollSettingsService } from 'src/app/services/workspaces/payroll-settings.service';

declare var jQuery;

@Component({
    selector: 'app-payroll-settings-form',
    templateUrl: './payroll-settings-form.component.html',
    styleUrls: ['./payroll-settings-form.component.scss']
})
export class PayrollSettingsFormComponent implements OnInit {

    @ViewChild('newRuleModal') newRuleModal: ElementRef;


    public dataForm: CommissionSetting;
    public dataFormPayrollSettings: PayrollSetting;
    public actualPage: number;
    public employees: Array<Employee>;
    public work_departments: Array<WorkDepartment>;
    public allRow: Array<any>;
    public commissionCondition: boolean;
    public sold_by_id: string;
    public commissionSettings: Array<CommissionSetting>;
    public vsCommission: string;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    constructor(
        private commissionSettingsService: CommissionSettingsService,
        private workDepartmentService: WorkDepartmentsService,
        private router: Router,
        private employeesService: EmployeesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private payrollSettingsService: PayrollSettingsService
    ) {
        this.dataForm = new CommissionSetting();
        this.dataFormPayrollSettings = new PayrollSetting();
        this.employees = [];
        this.work_departments = [];
        this.allRow = [];
        this.commissionCondition = false;
        this.sold_by_id = null;
        this.actualPage = 1;
        this.commissionSettings = [];
        this.vsCommission = '';
    }

    ngOnInit(): void {

    }


    ngAfterViewInit(): void {
        this.loadEmployees();
        this.loadTeam();
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadPayrollSettings(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .getById(id)
            .then((response) => {
                this.commissionSettings = response;
                //   if (this.dataForm.condition) {
                //       this.commissionCondition = true;
                //   }
                //   this.changeSoldBy();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadPayrollSettings(id: string) {
        this.helperService.showLoadingMxpro360();
        this.payrollSettingsService
            .getById(id)
            .then((response) => {
                this.dataFormPayrollSettings = response;
                this.load(this.dataFormPayrollSettings.employee_id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {

        if (!this.commissionCondition) {
            this.dataForm.condition = null;
        }
        this.dataForm.employee_id = this.dataFormPayrollSettings.employee_id;
        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.dataForm = new CommissionSetting();
                this.load(this.dataFormPayrollSettings.employee_id);
                this.commissionCondition = false;
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    savePayrollSettings() {

        this.helperService.showLoadingMxpro360();
        this.payrollSettingsService
            .save(this.dataFormPayrollSettings)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                if (this.dataFormPayrollSettings.id) {
                    this.helperService.goToCompanyRouterLink('/payroll-settings');
                } else {
                    this.helperService.goToCompanyRouterLink('/payroll-settings/' + response['row_id'] + '/edit');
                }
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadTeam() {
        this.helperService.showLoadingMxpro360();

        this.workDepartmentService
            .getAll()
            .then((response) => {
                this.work_departments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeSoldBy() {
        if (this.dataForm.sold_by == "WORK_DEPARTMENT") {
            this.allRow = this.work_departments;
        } else {
            this.allRow = this.employees;
        }
    }

    setSoldById() {
        if (this.dataForm.sold_by == "WORK_DEPARTMENT") {
            this.dataForm.sold_by_work_department_id = this.sold_by_id;
            this.dataForm.sold_by_employee_id = '';
        } else {
            this.dataForm.sold_by_work_department_id = '';
            this.dataForm.sold_by_employee_id = this.sold_by_id;
        }
    }


    public openNewRuleModal(dataForm) {
        jQuery(this.newRuleModal.nativeElement).modal('show');
        if (dataForm) {
            if (dataForm.condition) {
                this.commissionCondition = true;
            } else {
                this.commissionCondition = false;
            }
            this.dataForm = dataForm;
        } else {
            this.dataForm = new CommissionSetting();
            this.commissionCondition = false;
        }
    }

    deleteComissionSetting(id) {

        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .remove(id)
            .then((response) => {
                this.load(this.dataFormPayrollSettings.employee_id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
