
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobOutsourcing } from 'src/app/entities/workspace/job-outsourcing';
import { environment } from 'src/environments/environment';
import { CarrierCandidateView } from '../../entities/workspace/carrier-candidate-view';
import { BoardJob } from 'src/app/entities/workspace/board-job';
import { JobOutsourcingCancelledView } from 'src/app/entities/workspace/job-outsourcing-cancelled-view';

@Injectable({
    providedIn: 'root'
})
export class JobOutsourcingsService {

    constructor(
        private http: HttpClient
    ) {

    }
    add(dataForm: JobOutsourcing, id) {
        return this.http.post(environment.api.company + '/jobs/' + id + '/outsourcings', dataForm).toPromise();
    }

    put(dataForm: any, id) {
        return this.http.put(environment.api.company + '/jobs/' + id + '/outsourcings', dataForm).toPromise();
    }

    putWorspace(dataForm: any, id, companyId) {
        return this.http.put(environment.api.workspace + '/companies/' + companyId + '/jobs/' + id + '/outsourcings', dataForm).toPromise();
    }

    getAllCandidates(id) {
        return this.http.get<Array<CarrierCandidateView>>(environment.api.company + '/outsourcings/' + id + '/candidates').toPromise();
    }

    acceptJob(dataForm: any) {
        return this.http.put(environment.api.company + '/outsourcings/' + dataForm.id_job + '/candidates/' + dataForm.id_candidate + '/select-candidate', dataForm).toPromise();
    }

    createCandidate(dataForm: any) {
        return this.http.post(environment.api.company + '/outsourcings/' + dataForm.id_job + '/candidates', dataForm).toPromise();
    }

    /**
     * Asigna un job a un carrier
     */
    assignOutsourcing(carrierCandidateId: string, outsourcing: any) {
        return this.http.post(environment.api.company + '/outsourcings/' + carrierCandidateId + '/assignment', outsourcing).toPromise();
    }

    /**
     * Asigna un job a un carrier
     */
    assignOutsourcingWorkspace(idJob: string, outsourcing: any, sendAcceptance) {
        // return this.http.put(environment.api.workspace + '/companies/' + companyId + '/jobs/' + idJob +
        //     '/assing-outsourcing', outsourcing).toPromise();
        if(sendAcceptance){
            return this.http.post(environment.api.company + '/outsourcings/'+idJob+'/manual-assignment?send-acceptance=1', outsourcing).toPromise();
        } else {
            return this.http.post(environment.api.company + '/outsourcings/'+idJob+'/manual-assignment', outsourcing).toPromise();
        }
    }

    /**
     * Asigna un job a un carrier
     */
    deleteOutsourcing(idJob: string, outsourcing: any) {
        return this.http.put(environment.api.company + '/jobs/' + idJob + '/delete-outsourcing', outsourcing).toPromise();
    }

    /**
     * Asigna un job a un carrier
     */
    getOutsourcing(idJob: string): Promise<{ exist_job_outsourcing_carrier: boolean, job_outsourcing: JobOutsourcing }> {
        return this.http.get<{ exist_job_outsourcing_carrier: boolean, job_outsourcing: JobOutsourcing }>(environment.api.company + '/job/' + idJob + '/outsourcing').toPromise();
    }

    deleteOutsourcingWorspace(idJob: string, outsourcing: any, companyId) {
        return this.http.put(environment.api.workspace + '/companies/' + companyId + '/jobs/' + idJob + '/delete-outsourcing',
            outsourcing).toPromise();
    }

    sendJobAcceptance(idJob: string, data: any) {
        return this.http.post(environment.api.company + '/outsourcings/' + idJob + '/send-job-acceptance', data).toPromise();
    }

    sendBol(idJob: string, data: any) {
        return this.http.post(environment.api.company + '/outsourcings/' + idJob + '/send-bol', data).toPromise();
    }

    getJobBoard(id: string): Promise<BoardJob> {
        return this.http.get<BoardJob>(environment.api.company + '/jobs-board/job/' + id).toPromise();
    }

    sendJobAcceptanceEmail(outsourcing_id: string, data: { contacts: string[] }) {
        return this.http.post<any>(environment.api.company + '/outsourcings/' + outsourcing_id + '/send-email', data).toPromise();
    }

    reSendJobAcceptanceEmail(idJob: string, data: { contacts: string[], job_outsourcing: JobOutsourcing }) {
        return this.http.post<any>(environment.api.company + '/outsourcings/' + idJob + '/resend-email', data).toPromise();
    }

    downloadEstimate(idEstimate: string) {
        return this.http.get(environment.api.carrier + '/download-estimate/' + idEstimate).toPromise();
    }


    //------------------------------------------------


    /**
     * Permite obtener todos los registros de job outsourcing cancelados de un trabajo
     * 
     * @param job_id 
     * @returns 
     */
    getCancelleds(job_id: string): Promise<Array<JobOutsourcingCancelledView>> {
        return this.http.get<Array<JobOutsourcingCancelledView>>(environment.api.company + '/outsourcings/' + job_id + '/cancelled').toPromise();
    }

    /**
     * Permite cancelar la asignacion de un carrier a un trabajo 
     * 
     * @param jobOutsourcing 
     * @returns 
     */
    toCancel(jobOutsourcing: JobOutsourcing) {
        return this.http.post<any>(environment.api.company + '/outsourcing/' + jobOutsourcing.id + '/cancel', jobOutsourcing).toPromise();
    }
}
