import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { Job } from 'src/app/entities/workspace/job';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { HelperService } from 'src/app/services/helper.service';
import { AuthService } from '../../../services/auth.service';
import { BalancesService } from '../../../services/companies/balances.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';
import { EstimatesCancellationsService } from 'src/app/services/companies/estimates-cancellations.service';
import { ConstantsMessages } from 'src/app/constants-messages';
import { EstimateFollowUpView } from 'src/app/entities/workspace/estimate-follow-up-view';
import { FollowUpsRemindersService } from 'src/app/services/companies/follow-ups-reminders.service';
import { EstimateFollowUp } from 'src/app/entities/workspace/estimate-follow-up';
import { EstimatesFollowUpsService } from 'src/app/services/companies/estimates-follow-ups.service';
import { EstimateNoteView } from 'src/app/entities/workspace/estimate-note-view';
import { EstimateNote } from 'src/app/entities/workspace/estimate-note';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-estimates-view',
    templateUrl: './estimates-view.component.html',
    styleUrls: ['./estimates-view.component.scss']
})
export class EstimatesViewComponent implements OnInit {

    public cancelReason: string;
    public delivery_date_required_to_book: boolean;
    public estimate: Estimate;
    public job: Job;
    public makeRefund: boolean;
    public showEstimateButtons: boolean;
    public targetOther: boolean;
    public targetStorage: boolean;
    public totalBalance: number;
    public movingDuplicateType: string;
    public editReference: string;
    private estimateId: string;
    public followUpReminder: EstimateFollowUp;
    public followUpReminders: Array<EstimateFollowUpView>;
    public nextFollowUps: EstimateFollowUpView;
    public rowfollowupsremarks: Array<EstimateNoteView>;
    public followupsremarks: EstimateNote;
    public constantsMessages = ConstantsMessages;

    @ViewChild('cancelJobModal') cancelJobModal: ElementRef;
    @ViewChild('duplicateLeadModal') duplicateLeadModal: ElementRef;
    @ViewChild('editEstimateReferenceModal') editEstimateReferenceModal: ElementRef;
    @ViewChild('modalfollowUpsReminders') modalfollowUpsReminders: ElementRef;
    @ViewChild('modalfollowUpsRemindersView') modalfollowUpsRemindersView: ElementRef;

    constructor(
        private authService: AuthService,
        private balancesService: BalancesService,
        private currentRoute: ActivatedRoute,
        private estimateCancellationService: EstimatesCancellationsService,
        private estimatesService: EstimatesService,
        private jobsService: JobsService,
        private router: Router,
        private settingsService: SettingsService,
        public helperService: HelperService,
        private followUpsRemindersService: FollowUpsRemindersService,
        private estimatesFollowUpsService: EstimatesFollowUpsService,
        private elementRef: ElementRef
    ) {
        this.cancelReason = '';
        this.delivery_date_required_to_book = false;
        this.estimate = new Estimate();
        this.job = new Job();
        this.makeRefund = false;
        this.showEstimateButtons = false;
        this.targetOther = false;
        this.targetStorage = false;
        this.totalBalance = 0;
        this.movingDuplicateType = '';
        this.followUpReminder = new EstimateFollowUp();
        this.followUpReminders = [];
        this.nextFollowUps = new EstimateFollowUpView;
        this.rowfollowupsremarks = [];
        this.followupsremarks = new EstimateNote();
    }

    ngOnInit(): void {
        this.settingsService.get()
        .then((settingResponse) => {
          this.delivery_date_required_to_book = settingResponse.delivery_date_required_to_book;
        });

        this.ajustarLayout(); // Llama a la función al iniciar el componente
    }

    ngAfterViewInit(): void {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(async params => {
            if (typeof params.id !== 'undefined') {

                await this.saveLogViewedEstimates(params.id);
                this.load(params.id);
            }
        });

        this.helperService.calculateTotalDueBalance.subscribe(async (idEstimate) => {
            this.load(idEstimate);
        });

        this.helperService.editEstimateReference.subscribe(async (estimateReference) => {
            this.estimate.reference = estimateReference;
        });

        /* Resize - Menú Superior - Estimate Details  */
        let that = this;
        jQuery('#sidebar .toggle-sidebar .sidebarCollapse').on('click', function(){
            that.ajustarLayout();
            console.log("Click Sidebar")
        });
    }

    /* Resize - Menú Superior - Estimate Details  */
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.ajustarLayout(); //
    }

    ajustarLayout() {        
        setTimeout(() => {
            const contenedor = this.elementRef.nativeElement.querySelector('.top-container-estimate');
            const contenedorWidth = contenedor.offsetWidth;
    
            if (contenedorWidth >= 1350) {
                contenedor.classList.add('two-columns');
              } else {
                contenedor.classList.remove('two-columns');
              }  
        }, 200);      
    }

    private saveLogViewedEstimates(idEstimate) {
        const data = {
            'employee_id': this.authService.session.account.id,
            'estimate_id': idEstimate,
            'company_id': this.authService.workspaceSession.company.id
        };
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
            this.estimatesService
                .getById(id)
                .then((response) => {
                    this.estimate = response;
                    this.showEstimateButtons = true;
                    this.loadBalances(this.estimate.id);
                    if (this.estimate.booked){
                        this.loadJob(this.estimate.id);
                    }
                     this.loadFollowUpsReminders();

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
    }

    private loadJob(id: string) {
        this.helperService.showLoadingMxpro360();
            this.jobsService
                .getById(id)
                .then((response) => {
                    this.job = response;
                    this.loadBalances(this.estimate.id);

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
    }

    loadAllData(estimateId) {
        this.estimateId = estimateId;
        this.loadFollowUps(estimateId);
    }

    ngOnDestroy(): void {
        this.followupsremarks = undefined; // unset
        delete (this.followupsremarks); // this removes the variable completely
    }

    /**
     * Bookea un estimate
     */
    public async bookLead() {

        // Carga la informacion guardada en el estimate
        await this.load(this.estimate.id);

        // verifica que no hayan cambios sin guardad en basic information
        if (this.helperService.changesBeforeSave) {
            swal({
                type: 'error',
                title: 'Please save the basic informacion',
                text: 'We detect changes in the basic informacion, please save it before to book an estimate',
                padding: '2em'
            }
            );
        } else {

            // Valida que exista la fecha de pickup para bookear el estimate
            if (!(this.estimate.pickup.range_end && this.estimate.pickup.range_start) ||
                (isNaN(this.estimate.pickup.range_start) && isNaN(this.estimate.pickup.range_end))) {
                swal({
                    type: 'error',
                    title: 'Please Select',
                    text: 'Pick Up Date',
                    padding: '2em'
                }
                );
                return;
            }
            // Valida que exista la fecha de deliver para bookear el estimate

            if (this.delivery_date_required_to_book){
                if (this.estimate.service !== 'LOCAL' && !(this.estimate.delivery.range_end && this.estimate.delivery.range_start) || (isNaN(this.estimate.delivery.range_start) && isNaN(this.estimate.delivery.range_end))) {
                    swal({
                        type: 'error',
                        title: 'Please Select',
                        text: 'Delivery Date',
                        padding: '2em'
                    }
                    );
                    return;
                }
            }

            // Valida que exista la fecha de delivery para bookear el estimate
            if (this.delivery_date_required_to_book){
                if (this.estimate.service === 'AUTO_TRANSPORT' && !(this.estimate.delivery.range_end &&
                    this.estimate.delivery.range_start) || (isNaN(this.estimate.delivery.range_start)
                        && isNaN(this.estimate.delivery.range_end))) {
                    swal({
                        type: 'error',
                        title: 'Please Select',
                        text: 'Delivery Date',
                        padding: '2em'
                    }
                    );
                    return;
                }
            }

            this.bookOrReleaseLead();
        }
    }

    public bookOrReleaseLead(): void {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .bookEstimate(this.estimate.id)
            .then(() => {
                if (this.router.url.includes('moving') && this.router.url.includes('estimate')) {
                    window.location.reload();
                } else {
                    this.helperService.goToCompanyRouterLink('/moving/' + this.estimate.id + '/estimate');
                }
            })
            .catch((error) => {
                swal(
                    {
                        title: 'Attention',
                        text: error.error.message,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    }
                );
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    public QAStatus(status: string): void {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .QAStatusEstimate(this.estimate.id, status)
            .then(() => {
                if (this.router.url.includes('moving') && this.router.url.includes('estimate')) {
                    window.location.reload();
                } else {
                    this.helperService.goToCompanyRouterLink('/moving/' + this.estimate.id + '/estimate');
                }
            })
            .catch((error) => {
                swal(
                    {
                        title: 'Attention',
                        text: error.error.message,
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                    }
                );
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadBalances(idEstimate: string) {
            this.targetStorage = false;
            this.targetOther = false;
            this.helperService.showLoadingMxpro360();
            this.balancesService
                .getById(idEstimate, {})
                .then((response) => {
                    this.totalBalance = 0;
                    let storagBalance = 0;
                    let paidBalance = 0;
                    for (const balance of response) {
                        if (balance.status === 'PAID') {
                            paidBalance += balance.amount;
                        }

                        if (balance.target == 'STORAGE') {
                            storagBalance += balance.amount;
                        }
                        if (balance.status != 'PAID') {
                            if (balance.target == 'STORAGE') {
                                this.targetStorage = true;
                            }
                            if (balance.target != 'STORAGE') {
                                this.targetOther = true;
                            }
                        }
                    }
                    if (this.estimate.booked) {
                        this.totalBalance = this.job.total + storagBalance - paidBalance;
                    } else {
                        this.totalBalance = this.estimate.total + storagBalance - paidBalance;
                    }

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
    }

    openCancelJobModal() {
        this.cancelReason = '';
        this.makeRefund = false;
        jQuery(this.cancelJobModal.nativeElement).modal('show');
    }

    saveCancelation() {
        const data = {
            'make_refund_deposit': this.makeRefund,
            'reason': this.cancelReason
        };
        this.helperService.showLoadingMxpro360();
        this.estimateCancellationService
            .cancelJob(this.estimate.id, data)
            .then(() => {
                swal(
                    {
                        title: 'Attention',
                        text: 'The Job Was Cancelled Succesfully',
                        type: 'warning',
                        confirmButtonText: 'Ok'
                    }
                );
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    openDuplicateLeadModal() {
        jQuery(this.duplicateLeadModal.nativeElement).modal('show');
    }

    duplicateLead() {
        if (this.movingDuplicateType == '') {
            swal(
                'Attention!',
                'Select a Moving Type.',
                'warning'
            );
            return;
        }
        jQuery(this.duplicateLeadModal.nativeElement).modal('hide');
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .duplicateLead(this.estimate.id, this.movingDuplicateType)
            .then((response: any) => {
                // Redirige a la ventana de estimate
                this.helperService.openNewTab(response.estimate.id, 'estimate');
                swal(
                    'Done!',
                    'The estimate has been duplicated.',
                    'success'
                );
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    copyText(text: string) {
        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
    }



    editEstimateReference() {
        jQuery(this.editEstimateReferenceModal.nativeElement).modal('show');
        this.editReference = this.estimate.reference;
    }

    saveEstimateReference() {
        this.estimate.reference = this.editReference;
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, { reference: this.editReference }, true)
            .then((response) => {
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }








    private loadFollowUps(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesFollowUpsService
            .getAllWithFullView(id)
            .then((response) => {
                this.rowfollowupsremarks = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    addfollowupsremarks() {
        if (!this.followupsremarks.remarks) {
            return;
        }
        this.helperService.showLoadingMxpro360();
        this.estimatesFollowUpsService
            .add(this.estimate.id, this.followupsremarks)
            .then((response) => {
                this.loadFollowUps(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        this.followupsremarks = new EstimateNote();
    }


    addFollowUpReminder() {
        this.helperService.showLoadingMxpro360();
        this.followUpReminder.estimate_id = this.estimate.id;
        this.followUpReminder.employee_id = this.authService.workspaceSession.employee.id;
        this.followUpsRemindersService.saveChanges(this.followUpReminder.id, this.followUpReminder).then(() => {
            this.loadFollowUpsReminders();
        })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeDate(event) {
        this.followUpReminder.date = event;
    }


    /**
     * calcula y asigna el valor asegurable de un estimado
     * @param estimateId
    */

    loadFollowUpsReminders() {
        this.followUpsRemindersService.loadFollowUpByEstimateId(this.estimate.id)
        .then((response) => {
            this.followUpReminders = response;
            this.getNextFollowUp();
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    getNextFollowUp() {
        this.nextFollowUps = this.followUpReminders.find(followUpReminder => {
            const now = new Date().getTime();
            return followUpReminder.date > now;
        });
        if (this.nextFollowUps == undefined) {
            this.nextFollowUps = new EstimateFollowUpView();
        }
    }

    editFollowUpReminders(followUpReminder) {
        jQuery(this.modalfollowUpsReminders.nativeElement).modal('show');
        if (followUpReminder !== null) {
            this.followUpReminder = followUpReminder;
        }
    }

    deleteFollowUpReminders(id: string) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.followUpsRemindersService
                        .deleteFollowUp(id)
                        .then((response) => {
                            this.loadFollowUpsReminders();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    /**
     * Abre el modal para agregar un follow up reminder
     */
    openModalFollowUpReminders() {
        this.followUpReminder = new EstimateFollowUp();
        // Se obtiene la fecha y la hora actual
        this.getDates();
        jQuery(this.modalfollowUpsReminders.nativeElement).modal('show');
    }

    /**
     * Metodo que obtiene las fechas actuales del mes en el calendario
     */
    getDates(): void {
        const date = new Date();
        // Obtiene el primer dia del mes
        const firsDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // Obtiene el ultimo dia del mes
        const mounth = date.getMonth() + 1;
        const hours = date.getHours() - 12;
        const minutes = date.getMinutes();
        const milliseconds = date.getMilliseconds();
        // date.get
        // Se convierte el primer dia en cadena para agregar un 0 al inicio
        const firstCalendarDay = date.getDate().toString();
        // Se convierte el mes en cadena para agregar un 0 al inicio
        const calendarMonth = mounth.toString();

        // Si el dia contiene un solo digito, se agrega un 0 al inicio
        const firstDayConverted = firstCalendarDay.length === 1 ? '0' + firstCalendarDay : firstCalendarDay;
        // Si el mes contiene un solo digito, se agrega un 0 al inicio
        const monthCalendarConverted = calendarMonth.length === 1 ? '0' + calendarMonth : calendarMonth;

        this.followUpReminder.date = new Date().getTime();
    }

    openModalFollowUpRemindersView() {
        jQuery(this.modalfollowUpsRemindersView.nativeElement).modal('show');
    }

    saveCategory() {
        this.helperService.changesBeforeSave = false;
        const infoBasic = {
            category: this.estimate.category
        }

        this.saveBasicOnDB(infoBasic);
    }

    saveBasicOnDB(infoToSabe) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .patchEntity(this.estimate.id, infoToSabe, false)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.load(this.estimate.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
