import {
    AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild
} from '@angular/core';

declare const jQuery;

@Component({
  selector: 'app-load-board',
  templateUrl: './load-board.component.html',
  styleUrls: ['./load-board.component.scss']
})
export class LoadBoardComponent implements OnInit, AfterViewInit{

  @ViewChild('jobModal') jobModal: ElementRef;
  @ViewChild('sigPad') sigPad;

  context;
  sigPadElement;
  isDrawing = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#3742fa';
}

@HostListener('document:mouseup', ['$event'])
onMouseUp(e) {
    this.isDrawing = false;
}

onMouseDown(e) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
}

onMouseMove(e) {
    if (this.isDrawing) {
        const coords = this.relativeCoords(e);
        this.context.lineTo(coords.x, coords.y);
        this.context.stroke();
    }
}

private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
}

clear() {
        this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
        this.context.beginPath();
}

  showModalJob() {
    this.clear();
    jQuery(this.jobModal.nativeElement).modal('show');
  }

}
