import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdditionalStopRule } from 'src/app/entities/workspace/additional-stop-rule';
import { HelperService } from 'src/app/services/helper.service';
import { AdditionalStopRulesService } from 'src/app/services/workspaces/additional-stop-rules.service';

declare var jQuery;
declare var swal;
@Component({
  selector: 'app-additional-stop-rules-form',
  templateUrl: './additional-stop-rules-form.component.html',
  styleUrls: ['./additional-stop-rules-form.component.scss']
})
export class AdditionalStopRulesFormComponent implements OnInit {
  dataForm: AdditionalStopRule;
  additionalStopRules: AdditionalStopRule[];

  constructor(
      private currentRoute: ActivatedRoute,
      private additionalStopRulesService: AdditionalStopRulesService,
      private helperService: HelperService,
  ) {
      this.dataForm = new AdditionalStopRule();
      this.additionalStopRules = [];
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.loadTypes();

      // verficamos si se esta editando
      this.currentRoute.params.subscribe(params => {
          if (typeof params.id !== 'undefined') {
              this.load(params.id);
          }
      });
  }

  private loadTypes() {
    this.helperService.showLoadingMxpro360();
    this.additionalStopRulesService
        .getAll()
        .then((response) => {
            this.additionalStopRules = response;
            console.log('this.additionalStopRules ', this.additionalStopRules);            
        })
        .catch((error) => {
            console.error('Error: ', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}


  private load(id: string) {
      this.helperService.showLoadingMxpro360();
      this.additionalStopRulesService
          .getById(id)
          .then((response) => {
              this.dataForm = response;
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

  save() {

      if (this.dataForm.id === null) {
        for (const iterator of this.additionalStopRules) {
          console.log('iterator.type ', iterator.type);
          if(this.dataForm.type === iterator.type){
            console.log('Only one type of record can be made per type');
            swal({
              type: 'error',
              title: 'Oops...',
              text: 'Only one type of record can be made per type'
            });
            return;
          }        
        }
    }

      this.helperService.showLoadingMxpro360();

      this.additionalStopRulesService
          .save(this.dataForm)
          .then(() => {
              this.helperService.goToWorkspaceRouterLink('/additional-stop-rules');
          })
          .catch((error) => {
              swal({
                  type: 'error',
                  title: 'Oops...',
                  text: error.error.message
              });
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

}
