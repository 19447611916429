import { JobInventoryItem } from 'src/app/entities/workspace/job-inventory-item';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobInventoryItemService } from 'src/app/services/companies/job-inventory-item.service';
import { HelperService } from 'src/app/services/helper.service';

declare var jQuery;
declare const swal;

@Component({
  selector: 'app-moving-items',
  templateUrl: './moving-items.component.html',
  styleUrls: ['./moving-items.component.scss']
})
export class MovingItemsComponent implements OnInit {

  estimateId: string;

  jobInventoryItem: JobInventoryItem;

  items;

  constructor(
    private currentRoute: ActivatedRoute,
    private jobInventoryItemService: JobInventoryItemService,
    private helperService: HelperService
  ) {
    this.jobInventoryItem = new JobInventoryItem();
   }

  ngOnInit(): void {
   
  }

  
  ngAfterViewInit(): void{
    this.currentRoute.parent.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
          this.estimateId = params.id;
          this.jobInventoryItem.job_id = params.id;
          this.loadItems();
        }
      });
  }

  /**
   * Metodo que carga todos los empleados
   */
  private loadItems() {
    this.helperService.showLoadingMxpro360();
    this.jobInventoryItemService
        .getById(this.estimateId)
        .then(response => {
          this.items = response;
          this.items.forEach(element => {
            if (element.images === null) {
              element.images = '../../../assets/img/image_default.png';
            }
          });
        })
        .catch((error) => {
            console.error('Error: ', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

remove(id) {
  swal({
    title: 'Are you sure?',
    text: 'Do you want to delete the record?',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, Delete it!'
  })
    .then((result) => {
      if (result.value) {
        this.helperService.showLoadingMxpro360();
        this.jobInventoryItemService
          .remove(this.estimateId, id)
          .then((response) => {
            this.loadItems();
            swal(
              'Deleted!',
              'Record has been deleted.',
              'success'
            );
          })
          .catch((error) => {
            console.error('Error: ', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
    });
}
}
