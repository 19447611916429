import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Workspace } from 'src/app/entities/admin/workspace';
import { Account } from 'src/app/entities/admin/account';
import { InventoryItem } from 'src/app/entities/workspace/inventory-item';
import { Employee } from 'src/app/entities/workspace/employee';
import { AccountWorkspaceView } from 'src/app/entities/admin/account-workspace-view';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';

@Injectable({
    providedIn: 'root'
})
export class AccountWorkspacesService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<AccountWorkspaceView>> {
        return this.http.get<Array<AccountWorkspaceView>>(environment.api.account + '/workspaces').toPromise();
    }

    save(dataForm: Workspace): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Workspace) {
        return this.http.post(environment.api.account + '/workspaces', dataForm).toPromise();
    }

    edit(dataForm: Workspace) {
        return this.http.put(environment.api.account + '/workspaces/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.account + '/workspaces/' + id).toPromise();
    }

    changeAtSession(workspace_id: string, employee_id: string) {
        return this.http.post(environment.api.account + '/sessions/workspace/' + workspace_id, { employee_id }).toPromise();
    }

    updateProfile(dataForm) {
        return this.http.put(environment.api.account + '/workspaces/' + dataForm.id, dataForm).toPromise();
    }

    
    /**
     * Permite obtener todos los invoices
     * 
     * @author Angel Andres Diaz - 11-01-22
     * @version 1.0.0
     */
    getEmployeesByWorkspaceId(workspace_id: string): Promise<Array<EmployeeView>> {
        return this.http.get<Array<EmployeeView>>(environment.api.account + '/workspaces/' + workspace_id + '/employees').toPromise();
    }

}
