export class TariffAdditionalConcept {
    public id: string;
    public name: string;
    public stop_type: 'PICKUP' | 'DELIVERY' | 'NOT_APPLY';
    public behavior: 'DISCOUNT' | 'CHARGE' | 'FULL_PACKING_SERVICE' | 'DISCOUNT_FULL_PACKING_SERVICE' | 'DISCOUNT_PER_MILE' | 'ELEVATOR_DELIVERY' | 'ELEVATOR_PICKUP' | 'LONG_CARRY_DELIVERY' | 'LONG_CARRY_PICKUP' | 'SHUTTLE_DELIVERY' | 'SHUTTLE_PICKUP' | 'SPLIT_DELIVERY' | 'SPLIT_PICKUP' | 'BINDING_FEE' | 'OPEN_QUICK_QUOTE_QUESTION' | 'CLOSED_QUICK_QUOTE_QUESTION'
    public full_packing_mode: 'TARIFF' | 'CUSTOM';
    
    public type: "FIXED" | "PERCENTAGE";
    public value: number;
    public by_percentage: boolean;
    public apply_percentage_to: 'LINEHAUL' | 'SUBTOTAL';
    public make_maximum: boolean;
    public add_upon_estimate_creation: boolean;
    public by_cubic_feet: boolean;
    public editable_by_user: boolean;
    public display_on_long_distance: boolean;
    public display_on_local_distance: boolean;
    public display_on_auto_transport: boolean;
    public display_on_freight: boolean;
    public created: number;
    public updated: number;
    
    constructor() {
        this.id = null;
        this.name = null;
        this.stop_type = 'NOT_APPLY';
        this.behavior = 'DISCOUNT';
        this.full_packing_mode = null;
        
        this.type = null;
        this.value = null;

        this.by_percentage = false;
        this.apply_percentage_to = 'LINEHAUL';
        this.make_maximum = false;
        this.add_upon_estimate_creation = false;
        this.by_cubic_feet = false;
        this.editable_by_user = false;
        this.display_on_long_distance = false;
        this.display_on_local_distance = false;
        this.display_on_auto_transport = false;
        this.display_on_freight = false;
        this.created = null;
        this.updated = null;
    }
}
