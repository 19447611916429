import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PayrollBalanceView } from '../../entities/workspace/payroll-balance-view';

@Injectable({
    providedIn: 'root'
})
export class PayrollBalancesService {

    constructor(
        private http: HttpClient
    ) {

    }

    getAll(): Promise<Array<PayrollBalanceView>> {
        return this.http.get<Array<PayrollBalanceView>>(environment.api.company + '/payroll-balances').toPromise();
    }

    getById(id: string) {
        return this.http.get<any>(environment.api.company + '/payroll-balances/' + id).toPromise();
    }

    save(dataForm): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm) {
        return this.http.post(environment.api.company + '/payroll-balances', dataForm).toPromise();
    }

    edit(dataForm) {
        return this.http.put(environment.api.company + '/payroll-balances/' + dataForm.id, dataForm).toPromise();
    }

    saveStatus(id: string) {
        return this.http.put(environment.api.company + '/payroll-balances/' + id + '/done', {}).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/payroll-balances/' + id).toPromise();

    }
}
