import { Component, OnInit } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { Employee } from 'src/app/entities/workspace/employee';
import { EstimateCancelationReport } from 'src/app/entities/workspace/estimate-cancelation-report';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { JobsService } from 'src/app/services/workspaces/jobs.service';

declare const moment: any;
@Component({
  selector: 'app-canceled-jobs-list',
  templateUrl: './canceled-jobs-list.component.html',
  styleUrls: ['./canceled-jobs-list.component.scss']
})
export class CanceledJobsListComponent implements OnInit {

  public actualPage: number;
  public betweenDates: BetweenDates;
  public filterData: FilterData;
  public filterSearch: string;
  public foreman: string;
  public foremen: Employee[];
  public paginator: Paginator;
  public rows: EstimateCancelationReport[];
  public salesman: string;
  public salesmen: Employee[];
  public sort: number;

  constructor(
    private employeesService: EmployeesService,
    private helperService: HelperService,
    private jobsService: JobsService
  ) {
    this.actualPage = 1;
    this.betweenDates = new BetweenDates();
    this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    this.betweenDates.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
    this.filterData = new FilterData();
    this.filterSearch = '';
    this.foreman = '';
    this.foremen = [];
    this.paginator = new Paginator();
    this.paginator.paginator.order_by = 'cancellation.created';
    this.paginator.paginator.order_type = 'DESC';
    this.rows = [];
    this.salesman = '';
    this.salesmen = [];
    this.sort = null;
  }

  ngOnInit(): void {
    this.load();
    this.loadEmployees();
  }

  getFilterDates(date: BetweenDates): void {
    this.betweenDates = date;
  }

  load(): void {
    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getAllCancelled(this.paginator, this.betweenDates, this.filterData, this.foreman, this.salesman)
      .then((response) => {
        this.rows = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  loadEmployees() {
    this.employeesService.getAll()
    .then((employees) => {
      this.foremen = employees.filter((employee) =>
        employee.job_position === "FOREMAN" || employee.job_position === "ADMIN"
      );
      this.salesmen = employees;
    });
  }

  search(): void {

    if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
      this.betweenDates.dateStart = 1609459200;
      this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }

    // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
    this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

    this.paginator.paginator.start =  this.betweenDates.dateStart;
    this.paginator.paginator.end =  this.betweenDates.dateEnd;

    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getAllCancelled(this.paginator, this.betweenDates, this.filterData, this.foreman, this.salesman)
      .then((response) => {
        this.rows = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  openNewTab(estimateId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(estimateId, 'estimate');
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.load();
    }
  }
}
