import { ConstantsMessages } from 'src/app/constants-messages';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-work-departments-form',
    templateUrl: './work-departments-form.component.html',
    styleUrls: ['./work-departments-form.component.scss']
})
export class WorkDepartmentsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    dataForm: WorkDepartment;
    employees: Array<EmployeeView>;

    constructor(
        private workDepartmentsService: WorkDepartmentsService,
        private router: Router,
        private currentRoute: ActivatedRoute,
        private employeesService: EmployeesService,
        private helperService: HelperService
    ) {
        this.dataForm = new WorkDepartment();
        this.employees = [];
    }

    ngOnInit() {
       
    }

    ngAfterViewInit(): void{
        this.loadEmployees();
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });  
    }

    /**
     * setea el checked de la lista de empleados
     */
    private setEmployeeChecked() {
        // recorre arreglo de empeados para validar si están checkeados
        for (let employee of this.employees) {
                if (this.dataForm.employees_id.indexOf(employee.id) >= 0) {
                employee.checked = true;
            }
        };
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                // setear el checked del listado de empleados
                this.setEmployeeChecked();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
                this.setEmployeeChecked();

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {

        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/work-departments');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * checkea o des checkea un item del listado de empleados
     * @param employeeId 
     */
    getEmployeesTeam(employeeId: string) {
        const indexEmployeeId = this.dataForm.employees_id.indexOf(employeeId)
        // verifica si se debe agregar o quitar el employeeId al arreglo de ids
        if (indexEmployeeId >= 0) {
            // removemos el item des checkeado
            this.dataForm.employees_id.splice(indexEmployeeId, 1);
        } else {
            // agregamos el item checkeado
            this.dataForm.employees_id.push(employeeId);
        }

    }


}
