import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Address } from 'src/app/entities/global/address';

declare const google;

@Component({
    selector: 'app-google-maps',
    templateUrl: './google-maps.component.html',
    styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {

    /**
     * indica el eleento que renderizara el map
     */
    @ViewChild('mapView')
    mapView: ElementRef;

    /**
     * Contiene el address desde donde se renderizara la ruta
     */
    @Input()
    from: Address;

    /**
     * Contiene el objeto latitud y longitud de la direccion FROM 
     */
    latlngFrom: any;

    /**
     * Contiene el address hacia donde se renderizara la ruta
     */
    @Input()
    to: Address;

    /**
     * Contiene el objeto latitud y longitud de la direccion FROM 
     */
    latlngTo: any;

    /**
     * Contiene los puntos a renderizar en medio de la ruta
     */
    @Input()
    stopPoints: Array<Address>;

    /**
     * contiene la instancia del mapa de google
     */
    map: any;


    /**
     * Es para manejar el infowindow de google
     */
    infowindow: any;


    constructor() {
        this.mapView = null;
        this.from = null;
        this.to = null;
        this.stopPoints = [];
        this.map = null;
        this.infowindow = null;
    }


    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.displayMap();
    }

    // detecta cambios en los INPUTS
    ngOnChanges(changes: SimpleChanges) {

        // mostramos los elementos faltantes en el map
        this.displayAllElements(changes.from.currentValue, changes.to.currentValue);
    }

    /**
     * Metodo que inicializa toda la logica para mostrar el mapa
     */
    private displayMap() {

        // mas adelante toca mirar como resetear el mapa o destruirlo antes de cargar los cambios nuevos
        if (this.map !== null) {
            return;
        }

        // creamos las opciones
        const optionsMap = {
            zoom: 4,
            center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true
        };

        // instanciamos el mapa
        this.map = new google.maps.Map(this.mapView.nativeElement as HTMLElement, optionsMap);

        // Evento del click en el mapa
        this.map.addListener('click', (e) => {
            this.closeIfOpen();
        });

        // mostramos los elementos faltantes en el map
        this.displayAllElements(this.from, this.to);

    }

    /**
     * Permite construir la ruta a mostrar en el mapa
     */
    private displayRoadRoute() {

        // Instantiate a directions service.
        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
            map: this.map
        });

        // get route from A to B
        directionsService.route({
            origin: this.latlngFrom,
            destination: this.latlngTo,
            travelMode: google.maps.TravelMode.DRIVING
        }, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setOptions({ suppressMarkers: true });
            }
        });
    }

    /**
     * Permite establecer un panel sobre el mapa, 
     * en este caso para mostrar las direcciones
     * 
     * @param addressFrom 
     * @param addressTo 
     */
    private displayInfoPanel(addressFrom: Address, addressTo: Address) {

        // Se crea la informacion de las direcciones para mostrarlas en el mapa
        const card = document.createElement('div');
        const container = document.createElement('div');
        const titleFrom = document.createTextNode('From: ');
        const brTitleFrom = document.createElement('br');
        const textFrom = document.createTextNode('Street: ' + addressFrom.street + ', City: ' + addressFrom.city + ', State: ' + addressFrom.state); // + ', Country: ' + addressFrom[3]);
        const brTextFrom = document.createElement('br');
        const titleTo = document.createTextNode('To: ');
        const brTitleTo = document.createElement('br');
        const textTo = document.createTextNode('Street: ' + addressTo.street + ', City: ' + addressTo.city + ', State: ' + addressTo.state); // + ', Country: ' + addressTo[3]);

        card.setAttribute('id', 'pac-card');
        container.setAttribute('id', 'pac-container');
        container.appendChild(titleFrom);
        container.appendChild(brTitleFrom);
        container.appendChild(textFrom);
        container.appendChild(brTextFrom);
        container.appendChild(titleTo);
        container.appendChild(brTitleTo);
        container.appendChild(textTo);
        card.appendChild(container);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(card);
    }

    /**
     * Permite establecer un marcador
     * @param point 
     * @param address 
     */
    private displayMarker(point, address: Address, label) {

        // creamos el marker
        const marker = new google.maps.Marker({
            position: point,
            map: this.map
        });

        // creamos un evento para el marcador, esto para cuando hagan click sobre el
        google.maps.event.addListener(marker, 'click', () => {

            this.closeIfOpen();

            // Se dibuja en el infowindow la informacion
            const contentString =
                '<div id="content">' +
                '<b class="text-center">' + label + '</b>' +
                '<p> Street: ' + address.street + '</p>' +
                '<p> City: ' + address.city + '</p>' +
                '<p> State: ' + address.state + '</p>' +
                '<p> Country: ' + address.country + '</p>' +
                '</div>';

            this.infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            this.infowindow.setPosition({
                lat: point.lat(),
                lng: point.lng()
            });

            this.infowindow.open(this.map);
        });
    }

    /**
     * Se valida si hay un infowindow abierto, este sea cerrado
     */
    private closeIfOpen() {
        if (this.infowindow) {
            this.infowindow.close();
        }
    }

    /**
     * Permite obtener un punto de ubicacion
     */
    private async getLatLngFromAddress(address: Address) {

        // Se valida que existan coordenadas en el geospatial donde se indica que el usuario ha guardado sus direcciones
        if (address.geospatial.coordinates.length > 0) {
            return new google.maps.LatLng(address.geospatial.coordinates[1], address.geospatial.coordinates[0]);
        }

        // Si no hay direcciones guardadas, se busca por el zipcode guardado para indicar el estado de la route delivery
        const pointsPickup = await this.getLatLangFromZipCode(address.zip_code);
        return new google.maps.LatLng(pointsPickup['lat'], pointsPickup['lng']);
    }

    /**
     * Obtiene corrdenadas de un zipcode
     *
     * @param zipcode Zipcode a buscar
     */
    private getLatLangFromZipCode(zipcode) {
        return new Promise((resolve, reject) => {

            const geocoder = new google.maps.Geocoder();

            geocoder
                .geocode({ address: 'zipcode ' + zipcode }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        resolve({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        });
                    } else {
                        reject('Request failed.');
                    }
                });
        });
    }


    
    /**
     * Permite verificar si la informacion del FROM y TO ya estan cargados 
     * para mostrar los elementos respectivamente
     * 
     * @param from 
     * @param to 
     */
     private async displayAllElements(from: Address, to: Address){

        // validamos que haya informacion de la direccion FROM
        if (from !== null) {
            if (from.id !== null) {
                this.latlngFrom = await this.getLatLngFromAddress(from);
                this.displayMarker(this.latlngFrom, from, "From");
            }
        }

        // validamos que haya informacion de la direccion TO
        if (to !== null) {
            if (to.id !== null) {
                this.latlngTo = await this.getLatLngFromAddress(to);
                this.displayMarker(this.latlngTo, to, "To");
            }
        }

        // validamos que haya informacion de AMBAS direcciones 
        if (from !== null && to !== null) {
            if (from.id !== null && to.id !== null) {

                // mostramos las direcciones en un panel
                this.displayInfoPanel(from, to);

                // mostramos la ruta
                this.displayRoadRoute();
            }
        }

    }
}
