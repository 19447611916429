<div class="row layout-spacing">
    <div class="col-lg-12">
        <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
                
                <div style="margin: 20px 21px 20px 21px;">
                    <div class="row">
                        <div class="col-12 title-activity-logs">
                            <i class="fa-solid fa-clock-rotate-left mr-2"></i>
                            <span>Activity Logs</span> 
                        </div>
                        <div class="col-12 mt-3 mb-3" style="display: flex; justify-content: space-between;">

                            <div id="style-3_filter" class="dataTables_filter">
                                <label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                                        <circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    <input type="search" class="form-control" placeholder="Search..." aria-controls="style-3"  name="filterSearch" [(ngModel)]="filterSearch">
                                </label>
                            </div>
                            
                            <div class="block-type-event">
                                <div class="box-type-event">
                                    <i class="fa-solid fa-circle-info text-info"></i>
                                    <span>Event:</span>
                                    <span class="type-event read">Read</span>
                                </div>
                                
                                <div class="box-type-event">
                                    <i class="fa-solid fa-circle-info text-warning"></i>
                                    <span>Event:</span>
                                    <span class="type-event create">Create</span>
                                </div>
                                                
                                <div class="box-type-event">
                                    <i class="fa-solid fa-circle-info text-success"></i>
                                    <span>Event:</span>
                                    <span class="type-event update">Update</span>
                                </div>
                                        
                                <div class="box-type-event">
                                    <i class="fa-solid fa-circle-info text-danger"></i> 
                                    <span>Event:</span>
                                    <span class="type-event delete">Delete</span>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>

                <table id="style-3" class="table style-3">
                    <thead>
                        <tr>
                            <th class="text-center t-index">No.</th>
                            <th>Date</th>
                            <th class="text-center t-event">Event</th>
                            <th>Log</th>
                            <th>Module</th>
                            <th>User</th>
                            <th>User Role</th>
                            <th>Field</th>
                            <th>Old Value</th>
                            <th>New Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of logs |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i"  >
                            <td class="text-center">{{((actualPage - 1) * 25) + (i+1)}}</td>
                            <td>{{log.created | dateTime}}</td>
                            <td class="text-center td-event">
                                <ng-container *ngIf='log.event == "READ"'>
                                    <i class="fa-solid fa-circle-info text-info" title="READ"></i>
                                </ng-container>
                                <ng-container *ngIf='log.event == "CREATE"'>
                                    <i class="fa-solid fa-circle-info text-warning" title="CREATE"></i>
                                </ng-container>
                                <ng-container *ngIf='log.event == "UPDATE"'>
                                    <i class="fa-solid fa-circle-info text-success" title="UPDATE"></i>
                                </ng-container>
                                <ng-container *ngIf='log.event == "DELETE"'>
                                    <i class="fa-solid fa-circle-info text-danger" title="DELETE"></i>
                                </ng-container>
                            </td>
                            <td>{{log.log}}</td>
                            <td>{{log.module}}</td>
                            <td>{{log.employee.name}}</td>
                            <td>{{log.employee.job_position}}</td>
                            <td>
                                <ng-container *ngFor="let changes of log.activity_log_changes_id">
                                    {{changes.field_name }}
                                </ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let changes of log.activity_log_changes_id">
                                    {{changes.old_value }}
                                </ng-container>                              
                            </td>
                            <td>
                                <ng-container *ngFor="let changes of log.activity_log_changes_id">
                                    
                                    <span *ngIf='!changes.new_value.id' >                                        
                                        {{changes.new_value}}
                                    </span>                                    

                                    <button class="btn btn-info" *ngIf='changes.new_value.id' (click)="newValueJson = changes.new_value" data-toggle="modal" data-target="#showJson">                                     
                                        Show JSON
                                    </button>

                                </ng-container>                                
                            </td>
                        </tr>                                      
                    </tbody>
                </table>
                <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
            </div>
        </div>
    </div>
</div>


<!-- Modal Show JSON -->
<div class="modal fade" id="showJson" #showJson tabindex="-1" role="dialog" aria-labelledby="showJson"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header text-center">
                <h5 class="modal-title" id="showJson">JSON</h5>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    {{newValueJson | json}}                
                </div>
            </div>
            <div class="modal-footer">
                <button data-dismiss="modal" class="btn btn-warning">
                    OK
                </button>
            </div>
        </div>
    </div>
</div>