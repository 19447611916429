export class Email {

    public id: string;
    public provider: "GMAIL" | "OFFICE365";
    public address: string;
    public password: string;

    public constructor()
    { 
        this.id = null;
        this.provider = null;
        this.address = null;
        this.password = null;
    }
}
