<div class="layout-top-spacing pb-3">

    <div class="widget-content widget-content-area p2030 widget-active-phone">
        <div class="row align-items-center">

            <div class="col-4">
                <div class="agent">

                    <!--[ngClass]="{'status-available': agentStatus == 'available', 'status-away': agentStatus == 'away', 'status-busy': agentStatus == 'busy', 'disconnected': agentStatus == ''}"-->

                    <div class="agent-icon">
                        <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 612 612" xml:space="preserve">
                            <g>
                                <path d="M257.938,336.072c0,17.355-14.068,31.424-31.423,31.424c-17.354,0-31.422-14.068-31.422-31.424
                                    c0-17.354,14.068-31.423,31.422-31.423C243.87,304.65,257.938,318.719,257.938,336.072z M385.485,304.65
                                    c-17.354,0-31.423,14.068-31.423,31.424c0,17.354,14.069,31.422,31.423,31.422c17.354,0,31.424-14.068,31.424-31.422
                                    C416.908,318.719,402.84,304.65,385.485,304.65z M612,318.557v59.719c0,29.982-24.305,54.287-54.288,54.287h-39.394
                                    C479.283,540.947,379.604,606.412,306,606.412s-173.283-65.465-212.318-173.85H54.288C24.305,432.562,0,408.258,0,378.275v-59.719
                                    c0-20.631,11.511-38.573,28.46-47.758c0.569-84.785,25.28-151.002,73.553-196.779C149.895,28.613,218.526,5.588,306,5.588
                                    c87.474,0,156.105,23.025,203.987,68.43c48.272,45.777,72.982,111.995,73.553,196.779C600.489,279.983,612,297.925,612,318.557z
                                    M497.099,336.271c0-13.969-0.715-27.094-1.771-39.812c-24.093-22.043-67.832-38.769-123.033-44.984
                                    c7.248,8.15,13.509,18.871,17.306,32.983c-33.812-26.637-100.181-20.297-150.382-79.905c-2.878-3.329-5.367-6.51-7.519-9.417
                                    c-0.025-0.035-0.053-0.062-0.078-0.096l0.006,0.002c-8.931-12.078-11.976-19.262-12.146-11.31
                                    c-1.473,68.513-50.034,121.925-103.958,129.46c-0.341,7.535-0.62,15.143-0.62,23.08c0,28.959,4.729,55.352,12.769,79.137
                                    c30.29,36.537,80.312,46.854,124.586,49.59c8.219-13.076,26.66-22.205,48.136-22.205c29.117,0,52.72,16.754,52.72,37.424
                                    c0,20.668-23.604,37.422-52.72,37.422c-22.397,0-41.483-9.93-49.122-23.912c-30.943-1.799-64.959-7.074-95.276-21.391
                                    C198.631,535.18,264.725,568.41,306,568.41C370.859,568.41,497.099,486.475,497.099,336.271z M550.855,264.269
                                    C547.4,116.318,462.951,38.162,306,38.162S64.601,116.318,61.145,264.269h20.887c7.637-49.867,23.778-90.878,48.285-122.412
                                    C169.37,91.609,228.478,66.13,306,66.13c77.522,0,136.63,25.479,175.685,75.727c24.505,31.533,40.647,72.545,48.284,122.412
                                    H550.855L550.855,264.269z" />
                            </g>
                        </svg>
                    </div>
                    <div class="agent-info">
                        <div class="employee-name">
                            {{employee?.name | titlecase}} - {{employee?.job_position}}
                        </div>
                        <div class="company-name">
                            {{company?.name | titlecase}}
                        </div>
                        <!-- <div class="agent-name">{{agentStatus | titlecase}}</div> -->
                        <!-- <select class="agent-status form-control" id="agent_status" name="agent_status" [(ngModel)]="agentStatus"
                                [disabled]="!activePhone">
                                <option value="available">
                                    Available
                                </option>
                                <option value="away">
                                    Away
                                </option>
                                <option value="busy">
                                    Busy
                                </option>
                                <option value="">
                                    Disconnected
                                </option>
                                </select> -->
                    </div>
                </div>
            </div>

            <div class="col-4 d-flex justify-content-center" *ngIf="extensions.length > 0">
                <div class="tabs">
                    <ul class="nav nav-tabs  mb-3 mt-3" id="simpletab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link nav-link-phone" id="home-tab" data-toggle="tab" href="#calling_section" role="tab" aria-controls="calling-section" aria-selected="true">
                                <i class="fa-solid fa-phone"></i> &nbsp;
                                <span>Call History</span>
                            </a>
                        </li>
                        <li class="nav-item"> 
                            <a class="nav-link nav-link-sms-text" id="contact-tab" data-toggle="tab" href="#text_sms_section" role="tab" aria-controls="text-sms-section" aria-selected="false">
                                <i class="fa-solid fa-comment"></i> &nbsp;
                                <span>SMS Text</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-4 d-flex justify-content-end">

                <!-- Active Phone Line - Select -->
                <div class="active-phone-line">
                    <div id="from_dropdown" class="dropdown">
                        <button class="btn dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]="dialerIsOn" [ngClass]="{'active-phone' : dialerIsOn }" >
                            <span class="label">
                                <i class="fa-solid fa-phone"></i>
                            </span>
                            <span *ngIf="!extensionSelected">Extension NOT selected</span>
                            <span *ngIf="!!extensionSelected">{{ extensionSelected.extension }} - {{ extensionSelected.name }}</span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <ng-container *ngFor="let item of extensions">
                                <a class="dropdown-item" (click)="setExtension(item)">
                                    {{item.extension}} - {{item.name}}
                                </a>
                            </ng-container>                            
                        </div>
                    </div>
                </div>

                <!-- Boton para encender línea telefónica ON/OFF-->
                <div id="phone_power_btn" class="phone-power-btn" *ngIf="extensions.length > 0" [ngClass]="{'on' : dialerIsOn, 'off': !dialerIsOn }">
                    <label for="active_phone" id="phone_click" class="btn-power" (click)="switchDialer()">
                        <i class="fa-solid fa-power-off"></i>
                    </label>
                    <input type="checkbox" id="active_phone" name="active_phone">
                    <div class="phone-disabled" [hidden]="extensionSelected"></div>
                </div>

            </div>

        </div>
    </div>

    <app-sms  [phoneExtension]="extensionSelected" [power]="dialerIsOn" ></app-sms>

</div>


<!-- Wrapper - Dialer -->
<div class="wrapper-dialer" [ngClass]="{'open' : showDialer }" >

    <!-- Toogle - Dialer -->
    <div class="toogle-dialer" (click)="toogleDialer()">
        <div class="nav flex-column nac-pills" role="tablist" aria-orientation="vertical"> 
            <a class="nav-link active" data-bs-toggle="pill"  role="tab" aria-controls="c-pills-layouts" aria-selected="true" data-original-title="swicth-dialer" title="Switch Dialer"> 
                <div class="settings">
                    <i class="fa-solid fa-phone"></i>
                </div>
            </a>
        </div>
    </div>

    <!-- Dialer -->
    <app-dialer [phoneExtension]="extensionSelected" [power]="dialerIsOn"></app-dialer>
</div>


<!-- Modal - No Internet Connection -->
<div class="modal fade" #modalNoInternetConnection id="modalNoInternetConnection" tabindex="-1" role="dialog"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-center pb-0">

                <div class="icon">
                    <i class="fa-solid fa-globe world-icon"></i>

                    <div class="cancel-icon">
                        <i class="fa-solid fa-xmark"></i>
                    </div>
                </div>

            </div>
            <div class="modal-body text-center p-0">

                <div class="col-12 title">
                    No Internet Connection
                </div>

                <div class="col-12 text">
                    Looks like there is no connection to the internet. Please check cable or wifi connections
                </div>

            </div>
            <div class="modal-footer justify-content-center">

            </div>
        </div>
    </div>
</div>