<div class="layout-spacing layout-top-spacing">
    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Commission Setting
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>
                                Dashboard</a></li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/commission-settings">Commission
                                Settings</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button class="btn btn-main-action" type="submit">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
                <div class="row">
                    <div class="col-12">


                        <div class="form-group mb-4">
                            <label class="control-label">Employees</label>
                            <select [(ngModel)]="dataForm.employee_id" name="wd_employees_id" class="form-control"
                                [appSelect2] [theme]="'mxp360-form'">
                                <option *ngFor="let employe of employees" value="{{employe.id}}">
                                    {{employe.name}}</option>
                            </select>
                            <small id="help1" class="form-text text-muted mb-4">Select employee.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label class="control-label">Type</label>
                            <select id="type" [(ngModel)]="dataForm.type" name="pg_type" class="form-control">
                                <option value="PERCENTAGE">Percentage</option>
                                <option value="FIXED">Fixed Value</option>
                            </select>
                            <small id="help1" class="form-text text-muted mb-4">Select type.</small>
                        </div>
                        <div class="form-group mb-1">
                            <label class="control-label">Value</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-dollar-sign">
                                            <line x1="12" y1="1" x2="12" y2="23"></line>
                                            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                        </svg></span>
                                </div>
                                <input type="number" step="0.01" [(ngModel)]="dataForm.value" name="wd_value"
                                    class="form-control">
                            </div>
                            <small id="help1" class="form-text text-muted mb-4">To write value commision.</small>
                        </div>

                        <div class="form-group mb-1">
                            <label class="control-label">Value Based On</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-dollar-sign">
                                            <line x1="12" y1="1" x2="12" y2="23"></line>
                                            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                        </svg></span>
                                </div>
                                <select [(ngModel)]="dataForm.value_base_on" name="wd_value_base_on"
                                    class="form-control">
                                    <option value="ADDITIONAL_STOP_FEE"> Additional Stop Fee </option>
                                    <option value="BINDING_FEE"> Binding Fee </option>
                                    <option value="DISCOUNT"> Discount </option>
                                    <option value="DISCOUNT_FULL_PACKING_SERVICE"> Discount Full Packing Service </option>
                                    <option value="DISCOUNT_PER_MILE"> Discount Per Mile </option>
                                    <option value="ELEVATOR_DELIVERY"> Elevator Deliviry </option>
                                    <option value="ELEVATOR_PICKUP"> Elevator Pickup </option>
                                    <option value="FULL_PACKING_SERVICE"> Full Packing Service </option>
                                    <option value="LONG_CARRY_DELIVERY"> Long Carry Deliviry </option>
                                    <option value="LONG_CARRY_PICKUP"> Long Carry Pickup </option>
                                    <option value="SHUTTLE_DELIVERY"> Shuttle Delivery </option>
                                    <option value="SHUTTLE_PICKUP"> Shuttle Pickup </option>
                                    <option value="SPLIT_DELIVERY"> Split Delivery </option>
                                    <option value="SPLIT_PICKUP"> Split Pickup </option>
                                    <option value="JOB_DEPOSIT">Job Deposit </option>
                                    <option value="JOB_TOTAL"> Job Total </option>
                                    <option value="JOB_SUB_TOTAL"> Job Subtotal </option>
                                    <option value="TOTAL_BOL"> Total BOL </option>
                                    <option value="ADDITIONAL_BOL"> Additional BOL </option>
                                    <option value="TOTAL_BOL_AFTER_DISCOUNTS"> Total BOL  After Discounts </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select an option.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label class="control-label">Sold By Name</label>
                            <select id="inputState" [(ngModel)]="dataForm.sold_by" (change)="changeSoldBy()"
                                name="pg_sold_by_name" class="form-control">
                                <option value="EMPLOYEE">Employee</option>
                                <option value="WORK_DEPARTMENT">Work Department</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select an option.</small>
                        </div> 

                        <div class="form-group mb-4" *ngIf="dataForm.sold_by == 'EMPLOYEE'">
                            <label class="control-label">Sold By Employee</label>
                            <select id="inputState" [(ngModel)]="dataForm.sold_by_employee_id" name="wd_sold_by_id"
                                class="form-control" [appSelect2] [theme]="'mxp360-form'">
                                <option *ngFor="let employee of employees" value="{{employee.id}}">
                                    {{employee.name}}
                                </option>
                            </select>
                        </div>

                        <div class="form-group mb-1">
                            <label class="control-label"> Filter For </label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><i class="fa fa-filter" aria-hidden="true"></i></span>
                                </div>
                                <select [(ngModel)]="dataForm.filter_for" name="wd_filter_for"
                                    class="form-control">
                                    <option value="ADDITIONAL_STOP_FEE"> Additional Stop Fee </option>
                                    <option value="BINDING_FEE"> Binding Fee </option>
                                    <option value="DISCOUNT"> Discount </option>
                                    <option value="DISCOUNT_FULL_PACKING_SERVICE"> Discount Full Packing Service </option>
                                    <option value="DISCOUNT_PER_MILE"> Discount Per Mile </option>
                                    <option value="ELEVATOR_DELIVERY"> Elevator Deliviry </option>
                                    <option value="ELEVATOR_PICKUP"> Elevator Pickup </option>
                                    <option value="FULL_PACKING_SERVICE"> Full Packing Service </option>
                                    <option value="LONG_CARRY_DELIVERY"> Long Carry Deliviry </option>
                                    <option value="LONG_CARRY_PICKUP"> Long Carry Pickup </option>
                                    <option value="SHUTTLE_DELIVERY"> Shuttle Delivery </option>
                                    <option value="SHUTTLE_PICKUP"> Shuttle Pickup </option>
                                    <option value="SPLIT_DELIVERY"> Split Delivery </option>
                                    <option value="SPLIT_PICKUP"> Split Pickup </option>
                                    <option value="JOB_DEPOSIT">Job Deposit </option>
                                    <option value="JOB_TOTAL"> Job Total </option>
                                    <option value="JOB_SUB_TOTAL"> Job Subtotal </option>
                                    <option value="TOTAL_BOL"> Total BOL </option>
                                    <option value="ADDITIONAL_BOL"> Additional BOL </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select an option.</small>
                        </div>

                        <div class="form-group mb-1">
                            <label class="control-label"> Job Type </label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><i class="fa fa-tasks" aria-hidden="true"></i></span>
                                </div>
                                <select [(ngModel)]="dataForm.job_type" name="wd_job_type"
                                    class="form-control">
                                    <option value="LONG_DISTANCE"> Long Distance </option>
                                    <option value="LOCAL_DISTANCE"> Local Distance </option>
                                    <option value="AUTO_TRANSPORT"> Auto Transport </option>
                                    <option value="FREIGHT"> Freight </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select an option.</small>
                        </div>

                        <div class="form-group mb-4" *ngIf="dataForm.sold_by == 'WORK_DEPARTMENT'">
                            <label class="control-label">Sold By Work Deparment:</label>
                            <select id="inputState" [(ngModel)]="dataForm.sold_by_work_department_id"
                                name="wd_sold_by_id" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                                <option *ngFor="let workDepartment of work_departments" value="{{workDepartment.id}}">
                                    {{workDepartment.name}}
                                </option>
                            </select>
                        </div>

                        <!-- <div class="form-group mb-4">
                            <div class="custom-control custom-checkbox checkbox-info">
                                <input type="checkbox" [(ngModel)]="commissionCondition"
                                    name="pg_commissionCondition" class="custom-control-input" id="sChkbox">
                                <label class="custom-control-label" for="sChkbox">Commission Condition</label>
                            </div>
                        </div> -->
                    </div>

                    <div class="col-12 mt-5 mb-3">
                        <div class="form-group">
                            <div class="toggle-switch">
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="sChkbox" name="pg_commissionCondition"
                                        [(ngModel)]="commissionCondition">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="autoEmail" class="checkbox-label"
                                    [ngClass]="{'filter-disabled': !commissionCondition}">
                                    Commission Condition
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-4" *ngIf="commissionCondition">

                        <div class="row p-0 m-0">
                            <div class="col-11 pl-0 form-group mb-4">
                                <label class="control-label">Type:</label>
                                <select id="inputState" [(ngModel)]="dataForm.condition.type" name="pg_condition.type"
                                    class="form-control">
                                    <option value="GTE">GTE</option>
                                    <option value="LTE">LTE</option>
                                    <option value="BETWEEN">BETWEEN</option>
                                </select>
                            </div>

                            <div class="col-1 help-commission-type">
                                <a class="btn btn-circle tooltip">
                                    <i class="fas fa-question"></i>
                                    <span class="tooltiptext">
                                        <strong>GTE </strong>greater than or equal to (i.e. >=) <br>
                                        <strong>LTE </strong>less than or equal to (i.e. <=) </span>
                                </a>
                            </div>
                        </div>

                        <div class="form-group mb-4"
                            *ngIf="dataForm.condition.type == 'GTE' || dataForm.condition.type == 'LTE' || dataForm.condition.type == 'BETWEEN'">
                            <label class="control-label">Threshold Start:</label>
                            <input type="number" step="0.01" [(ngModel)]="dataForm.condition.threshold_start"
                                name="wd_address_street" class="form-control">
                            <small id="emailHelp1" class="form-text text-muted mb-4"></small>
                        </div>

                        <div class="form-group mb-4" *ngIf="dataForm.condition.type == 'BETWEEN'">
                            <label class="control-label">Threshold End:</label>
                            <input type="number" step="0.01" [(ngModel)]="dataForm.condition.threshold_end"
                                name="wd_address_zip_code" class="form-control">
                            <small id="emailHelp1" class="form-text text-muted mb-4"></small>
                        </div>

                    </div>



                </div>
                <div class="form-group mb-4">
                    <label class="control-label">Payment Threshold</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-dollar-sign">
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg></span>
                        </div>
                        <input type="number" step="0.01" [(ngModel)]="dataForm.payment_threshold" name="wd_payment_threshold" class="form-control" value=0>
                    </div>
                    <small id="help1" class="form-text text-muted mb-4">To write value payment threshold.</small>
                </div>
            </div>
            
        </div>

    </form>
</div>