import { ActivatedRoute } from '@angular/router';
import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { Component, OnInit } from '@angular/core';
import { Employee } from 'src/app/entities/workspace/employee';
import { HelperService } from 'src/app/services/helper.service';
import { Payroll } from 'src/app/entities/companies/payroll';
import { PayrollBalance } from 'src/app/entities/workspace/payroll-balance';
import { PayrollBreakdownService } from 'src/app/services/companies/payroll-breakdown.service';
import { PayrollPayment } from 'src/app/entities/workspace/payroll-payment';
import { PayrollSetting } from 'src/app/entities/workspace/payroll-setting';
import { TimeClock } from 'src/app/entities/companies/time-clock';

@Component({
  selector: 'app-payroll-payments-view',
  templateUrl: './payroll-payments-view.component.html',
  styleUrls: ['./payroll-payments-view.component.scss']
})
export class PayrollPaymentsViewComponent implements OnInit {

  public cancelations: any[]
  public clockEmployees: ClockEmployees;
  public commissions: any[];
  public dataForm: PayrollPayment;
  public employee: Employee;
  public employees: Employee[];
  public payroll: Payroll;
  public payrollBalances: PayrollBalance[];
  public payrollSetting: PayrollSetting;
  public previusPayrollPayment: PayrollPayment;
  public timeClock: TimeClock[];

  constructor(
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private payrollBreakdownService: PayrollBreakdownService,

  ) {
    this.cancelations = [];
    this.clockEmployees = new ClockEmployees();
    this.commissions = [];
    this.dataForm = new PayrollPayment();
    this.employee = new Employee();
    this.employees = [];
    this.payroll = new Payroll();
    this.payrollSetting = new PayrollSetting();
    this.previusPayrollPayment = new PayrollPayment();
    this.timeClock = [];
  }

  ngOnInit(): void {
    // verficamos si se esta editando
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
          this.load(params.id);
      }
    });
  }


  private load(id: string) {
    this.helperService.showLoadingMxpro360();
    this.payrollBreakdownService
        .getById(id)
        .then((response) => {
            this.dataForm = response['payroll_payment'];
            this.payrollBalances = response['payroll_balances'];
            this.timeClock = response['time_clocks'];
            this.commissions = response['sales_commisions'];
            this.cancelations = [];
            for (const cms of this.commissions) {
              // clona documentos para luego hacer filtros sobre el
              const clonedArrayDocuments = [...cms.documents];
              // comisiones son las que no han sido canceladas (job_cancel)
              cms.documents = clonedArrayDocuments.filter(document => document.status == 'PAID');
              // cancelaciones son las que tienen fecha de cancelacion del trabajo
              this.cancelations = this.cancelations.concat(clonedArrayDocuments.filter(document => document.status !== 'PAID'));
            }
            this.employee = response['employee'];
            this.payroll = response['payroll'];


        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

  // selectEmploye(){
  //   console.log(this.dataForm.employee_id);
  //   this.dataForm.end_period = null;
  //   this.dataForm.start_period = null;
  //   this.dataForm.total = null;
  //   this.loadPreviusPayrollPayment();
  // }


  // private loadEmployees() {
  //   this.helperService.showLoadingMxpro360();

  //   this.employeesService
  //     .getAll()
  //     .then((response) => {
  //       this.employees = response;
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // private loadCommissions() {
  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .getCommissions(this.dataForm.employee_id, this.dataForm.end_period)
  //     .then((response) => {
  //       this.commissions = response;
  //       this.dataForm.total_commissions = 0;

  //       for (const cms of this.commissions) {
  //         for (const dct of cms.documents) {
  //           this.dataForm.total_commissions += dct.total_commission;
  //         }
  //       }
  //       this.generateSubTotal();
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // private loadPayrollBalances() {
  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .getPayrollBalances(this.dataForm.employee_id, this.dataForm.end_period)
  //     .then((response) => {
  //       this.payrollBalances = response;
  //       this.dataForm.total_bonuses = 0;
  //       this.dataForm.total_deductions = 0;
  //       for (const pb of this.payrollBalances) {
  //         if (pb.type == 'DEDUCTION'){
  //           this.dataForm.total_deductions += pb.amount;
  //         } else {
  //           this.dataForm.total_bonuses += pb.amount;
  //         }
  //       }
  //       this.generateSubTotal();
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // private loadTimeClock() {
  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .getTimeClock(this.dataForm.employee_id, this.dataForm.end_period)
  //     .then((response) => {
  //       this.timeClock = response;
  //       this.dataForm.total_hours = 0;
  //       for (const tc of this.timeClock) {
  //         this.dataForm.total_hours += tc.duration;
  //       }
  //       this.dataForm.total_hours = Number((this.dataForm.total_hours / 60).toFixed(2));
       
  //       this.generateSubTotal();
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // private loadClockEmployees() {
  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .getClockEmployee(this.dataForm.employee_id)
  //     .then((response) => {
  //       this.clockEmployees = response;
  //       this.dataForm.hourly_pay_rate = Number(this.clockEmployees.hourly_rate);
  //       this.generateSubTotal();
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // private loadPayrollSettings() {
  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .getPayrollSettings(this.dataForm.employee_id)
  //     .then((response) => {
  //       this.payrollSetting = response;
  //       this.dataForm.base_pay = Number(this.payrollSetting.base_per_week);
  //       this.generateSubTotal();
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // private loadPreviusPayrollPayment() {
  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .getPreviusPayrollPayment(this.dataForm.employee_id)
  //     .then((response) => {
  //       this.previusPayrollPayment = response;
  //       if(this.previusPayrollPayment){
  //         this.dataForm.previous_payroll_payment_id = this.previusPayrollPayment.id;
  //         this.dataForm.previous_total_draw = this.previusPayrollPayment.total_draw;
  //         this.dataForm.start_period = this.previusPayrollPayment.end_period;
  //       } else {
  //         this.dataForm.previous_total_draw = 0;
  //       }
       
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // generateSubTotal(){

  //   this.dataForm.total_hourly = this.dataForm.total_hours * this.dataForm.hourly_pay_rate;

  //   this.dataForm.sub_total = 0;
  //   this.dataForm.total_draw = 0;
  //   this.dataForm.sub_total = this.dataForm.total_hourly + this.dataForm.total_commissions + this.dataForm.total_bonuses - this.dataForm.total_deductions;

  //   if (this.dataForm.sub_total > this.dataForm.base_pay ){
  //     const excedente = this.dataForm.sub_total - this.dataForm.base_pay;

  //     if(this.dataForm.previous_total_draw > 0){

  //       this.dataForm.total_draw = this.dataForm.previous_total_draw - excedente;

  //       if (this.dataForm.total_draw < 0){
  //         this.dataForm.total_draw = 0;
  //         this.dataForm.total = this.dataForm.sub_total - this.dataForm.previous_total_draw;
  //       } else {
  //         this.dataForm.total = this.dataForm.base_pay; 
  //       }
  //     } else {
  //       this.dataForm.total_draw = 0;
  //       this.dataForm.total = this.dataForm.sub_total;

  //     }
  //   } else {
  //     this.dataForm.total = this.dataForm.base_pay;
  //     this.dataForm.total_draw = this.dataForm.base_pay - this.dataForm.sub_total + this.dataForm.previous_total_draw;
  //   }

  // }

  // savePayrollBreakdown(){
  //   console.log('payrollpayment : ', this.dataForm);

  //   this.helperService.showLoadingMxpro360();

  //   this.payrollBreakdownService
  //     .add(this.dataForm, this.commissions, this.payrollBalances, this.timeClock)
  //     .then((response) => {
  //       this.helperService.goToCompanyRouterLink('/payroll-payments');
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //     })
  //     .finally(() => {
  //       this.helperService.hideLoadingMxpro360();
  //     });
  // }

  // removePaymentBalances(index){
  //   this.payrollBalances.splice(index,1);
  //   this.dataForm.total_bonuses = 0;
  //   this.dataForm.total_deductions = 0;
  //   for (const pb of this.payrollBalances) {
  //     if (pb.type == 'DEDUCTION'){
  //       this.dataForm.total_deductions += pb.amount;
  //     } else {
  //       this.dataForm.total_bonuses += pb.amount;
  //     }
  //   }
  //   this.generateSubTotal();
  // }

  // changePayrollDate(date) {
  //   this.dataForm.end_period = date;
  //   this.loadCommissions();
  //   this.loadPayrollBalances();
  //   this.loadTimeClock();
  //   this.loadPayrollSettings();
  //   this.loadClockEmployees();
  // }

}
