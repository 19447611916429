import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { JobStorageListView } from 'src/app/entities/workspace/job-storage-list-view';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { Warehouse } from 'src/app/entities/workspace/warehouse';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { StorageCompaniesService } from 'src/app/services/workspaces/storage-companies.service';
import { StorageManagerService } from 'src/app/services/workspaces/storage-manager.service';
import { WarehousesService } from 'src/app/services/workspaces/warehouses.service';

@Component({
  selector: 'app-storage-manager',
  templateUrl: './storage-manager.component.html',
  styleUrls: ['./storage-manager.component.scss']
})
export class StorageManagerComponent implements OnInit {
  public actualPage: number;
  public dataFilter: any;
  public filterSearch: string
  public rowStorageCompanies: Array<StorageCompany>;
  public rowWarehouse: Array<Warehouse>;
  public storageManager: JobStorageListView[];

  constructor(
    private authService: AuthService,
    private helperService: HelperService,
    private storageCompaniesService: StorageCompaniesService,
    private storageManagerService: StorageManagerService,
    private warehousesService: WarehousesService,
  ) {
    this.actualPage = 1;
    this.dataFilter = {
      FADD_start_date: '',
      FADD_end_date: '',
      warehouse_id: '',
      storage_company_id: '',
      due: 'ALL',
      start_date: '',
      end_date: '',
      volume: 'ALL',
      start_range: '',
      end_range: '',
      storage_type: 'ALL'
    };
    this.filterSearch = '';
    this.rowStorageCompanies = [];
    this.rowWarehouse = [];
    this.storageManager = [];
  }

  ngOnInit(): void {
    this.loadWarehouse();
    this.loadStorageCompanies();
    this.storageManagerService.getAll(this.dataFilter)
      .then((response) => {
        this.storageManager = response;
      })
  }

  search() {
    this.helperService.showLoadingMxpro360();
    this.storageManagerService.getAll(this.dataFilter)
      .then((response) => {
        this.storageManager = response;
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  datesFADD(dates) {
    this.dataFilter.FADD_start_date = dates[0];
    this.dataFilter.FADD_end_date = dates[1];
  }

  datesDue(dates) {
    this.dataFilter.start_date = dates[0];
    this.dataFilter.end_date = dates[1];
  }

  resetStorageCompany() {
    this.dataFilter.storage_company_id = "";
  }

  resetWarehouse() {
    this.dataFilter.warehouse_id = "";
  }

  loadWarehouse() {
    this.helperService.showLoadingMxpro360();
    this.warehousesService
      .getAll()
      .then((response) => {
        this.rowWarehouse = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  loadStorageCompanies() {
    this.helperService.showLoadingMxpro360();
    this.storageCompaniesService
      .getAll()
      .then((response) => {
        this.rowStorageCompanies = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  openJob(companyId, jobId) {
    window.open(window.location.origin + '/#/workspace/' + this.authService.workspaceSession.id + '/companyspace/' + companyId
      + '/moving/' + jobId + '/estimate');
  }
  openJobStorage(companyId: string, jobId: string) {
    window.open(window.location.origin + '/#/workspace/' + this.authService.workspaceSession.id + '/companyspace/' + companyId
      + '/moving/' + jobId + '/storage');
  }
  sendEmailJobStorage(companyId: string, jobId: string) {
    const data = {
      company_id: companyId,
      job_id: jobId
    }

    this.helperService.showLoadingMxpro360();
    this.storageManagerService.sendEmail(data)
      .then(() => {
          this.helperService.showMessageSnackbar(ConstantsMessages.EMAIL_SENT);
      })
      .catch((error) => {
          this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
          console.error('Error: ', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
  }
}
