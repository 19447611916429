import { Role } from 'src/app/entities/workspace/role';
import { RolesService } from 'src/app/services/workspaces/roles.service';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  public roles: Array<Role>;

  public actualPage;
  constructor(
    private rolesService: RolesService,
    private helperService: HelperService
  ) {
    this.roles = [];
    this.actualPage = 1;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadRoles();
    }

  private loadRoles() {
    this.helperService.showLoadingMxpro360();
    this.rolesService
      .getAll()
      .then((response) => {
        this.roles = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.rolesService
            .remove(id)
            .then((response) => {
              this.loadRoles();
              swal(
                'Deleted!',
                'The record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }
}
