<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                Leads Advertisers Report
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Leads Advertisers Report
                    </li>
                </ol>
            </nav>
            <br>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>
    
                
    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" [useAll]="false" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>                  
        <div class="row col-lg-12 col-md-12 block-search">
            <div class="col-lg-3 col-xl-4">

            </div>
            <div class="col-lg-4 col-xl-3">
                <label>Advertiser</label>
                <select name="advertiser" id="state" [(ngModel)]="advertiserId" class="form-control">
                    <option value="" selected="selected">Select All</option>
                    <option *ngFor="let advertiser of advertisers" value="{{advertiser.id}}"> {{advertiser.name | filterReplace}}</option>
                </select>
            </div>                         
            <div class="col-4">
                <button class="btn btn-warning btn-search" (click)="search()">
                    Search
                </button>
            </div>
            <div class="col-1">

            </div>  
        </div>  
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Advertiser</th>
                                <th>Leads #</th>
                                <th>Booked Jobs #</th>
                                <th>Estimated #</th>
                                <th>Booked %</th>
                                <th>Leads Cost</th>
                                <th>Estimate Total Amount</th>
                                <th>Avg. Estimate</th>
                                <th>Booked Total Amount</th>
                                <th>Avg. Booked Job</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let leadAdvertiser of leadAdvertisers | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <tr (click)="openModalLeadsByAdvertiser(leadAdvertiser.advertiser)" class="cursor-pointer">
                                    <td>{{i + 1}}</td>
                                    <td>{{leadAdvertiser.advertiser.name}}</td>
                                    <td>{{leadAdvertiser.total_leads}}</td>
                                    <td>{{leadAdvertiser.total_leads_booked}}</td>
                                    <td>{{leadAdvertiser.total_leads_estimated}}</td>
                                    <td>{{leadAdvertiser.percentage_booked}}%</td>
                                    <td>{{leadAdvertiser.charges_leads | currency}}</td>
                                    <td>{{leadAdvertiser.total_amount_leads_estimates | currency}}</td>
                                    <td>{{leadAdvertiser.average_amount_estimate | currency}}</td>
                                    <td>{{leadAdvertiser.total_amount_leads_jobs | currency}}</td>
                                    <td>{{leadAdvertiser.average_amount_job | currency}}</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td></td>
                                <td class="td-total">
                                    <span>TOTAL</span>
                                </td>
                                <td class="td-total-leads">
                                    <span>{{ totalLeads }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- Modal Add Payment -->
<div class="modal fade" #leadsModal id="signatureFull" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-leads">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Leads by {{advertiserSelected.name}}
                </h5>
            </div>
            <div class="modal-body">

                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Service</th>
                                <th>Info</th>
                                <th>Company / Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lead of paginator.data; index as i">
                                <td>
                                    <div>
                                        <strong>{{lead.customer.name}} {{lead.customer.last_name}}</strong>
                                    </div>
                                    <div>{{lead.customer.email}}</div>
                                    <div>{{lead.customer.phone | phone: 'US'}}</div>
                                </td>
                                <td>
                                    <div>{{lead.from_address.street}}</div>
                                    <div>{{lead.from_address.city}} {{lead.from_address.state}}</div>
                                    <div>{{lead.from_address.zip_code}}, {{lead.from_address.country}}</div>
                                </td>
                                <td>
                                    <div>{{lead.to_address.street}}</div>
                                    <div>{{lead.to_address.city}} {{lead.to_address.state}}</div>
                                    <div>{{lead.to_address.zip_code}}, {{lead.to_address.country}}</div>
                                </td>
                                <td>
                                    <div>
                                        <strong>Service:</strong>
                                        {{lead.service | filterReplace}}
                                    </div>
                                    <div *ngIf="lead.service != 'AUTO_TRANSPORT'">
                                        <strong>Move  Size:</strong>
                                        {{lead.move_size}}
                                    </div>
                                    <div>
                                        <strong>Move date:</strong>
                                        {{lead.move_date | date}}
                                    </div>
                                    <div *ngIf="lead.advertiser !== null">
                                        <strong>Advertiser:</strong>
                                        {{lead.advertiser.name}}
                                    </div>
                                    <div>
                                        <strong>Created:</strong>
                                        {{lead.created | dateTime}}
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="lead.vehicle; else noneVehicle">
                                        <div>
                                            <strong>Type:</strong>
                                            {{lead.vehicle.type}}
                                        </div>
                                        <div>
                                            <strong>Brand:</strong>
                                            {{lead.vehicle.brand}}
                                        </div>
                                        <div>
                                            <strong>Model:</strong>
                                            {{lead.vehicle.model}}
                                        </div>
                                        <div>
                                            <strong>Year:</strong>
                                            {{lead.vehicle.year}}
                                        </div>
                                    </div>
                                    <ng-template #noneVehicle>
                                        <span class="badge badge-danger">
                                            None
                                        </span>
                                    </ng-template>
                                </td>                            
                                <td>
                                    <div class="company-status" *ngFor="let leadCompany of lead.leads_company">
                                        <div>
                                            <strong>{{leadCompany.company.name}}</strong>
                                        </div>
                                        <div>{{leadCompany.status}}</div>                                    
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>                   

            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" (click)="closeModalEstimates()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>



