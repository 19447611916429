import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Document } from '../../entities/workspace/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private http: HttpClient) { }

  getDocumentById(idDocument): Promise<Document> {
    return this.http.get<Document>(environment.api.company + '/document/' + idDocument).toPromise();
  }
}
