<ng-container *ngIf="src != null">

    <a href="{{src}}" target="_blank" *ngIf="disableStyles == false">
        <figure class="figure">
                
            <img alt="file-preview" *ngIf="fileType === 'IMAGE'" src="{{src}}" class="rounded img-fluid img-thumbnail {{innerClass}}" />

            <!-- video -->
            <svg *ngIf="fileType === 'VIDEO'" class="mt-2 {{innerClass}}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-play-circle" >
                <circle cx="12" cy="12" r="10"></circle>
                <polygon points="10 8 16 12 10 16 10 8"></polygon>
            </svg>

            <!-- audio -->
            <svg *ngIf="fileType === 'AUDIO'" class="mt-2 {{innerClass}}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-music"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg>

            <!-- otros -->
            <svg *ngIf="fileType === 'OTHER'" class="mt-2 {{innerClass}}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>

            <figcaption *ngIf="file !== null" class="figure-caption text-truncate">
                {{file.name}}
            </figcaption>

        </figure>
    </a>

    <ng-container *ngIf="disableStyles">

        <img alt="file-preview" *ngIf="fileType === 'IMAGE'" src="{{src}}" class="{{innerClass}}" />

        <!-- video -->
        <svg *ngIf="fileType === 'VIDEO'" class="{{innerClass}}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play-circle" >
            <circle cx="12" cy="12" r="10"></circle>
            <polygon points="10 8 16 12 10 16 10 8"></polygon>
        </svg>

        <!-- audio -->
        <svg *ngIf="fileType === 'AUDIO'" class="{{innerClass}}" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-music"><path d="M9 18V5l12-2v13"></path><circle cx="6" cy="18" r="3"></circle><circle cx="18" cy="16" r="3"></circle></svg>

        <!-- otros -->
        <svg *ngIf="fileType === 'OTHER'" class="{{innerClass}}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>

    </ng-container>
    
</ng-container>