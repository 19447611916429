<div class="row main-content d-flex justify-content-center mt-5">
    <div class="col-12 justify-content-center">
        
        <!-- <div class="widget-header">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex justify-content-between">
                    <h4>
                        Payment with Credit Card
                    </h4> 
                    <span class="blue-big">
                        {{invoice?.total_amount | currency}}
                    </span>
                </div>
            </div>
        </div> -->

        <div class="section-title">
            Payment with Credit Card
        </div>

        <div class="widget-content widget-content-area" >
            <!-- steps -->
            <ul>
                <li [ngClass]="{'active' : viewSection == 'CARD_INFO'}">
                    <!-- Circulo e icono -->
                    <span class="marker-number">
                        <i class="far fa-credit-card fa-2x icon"></i>
                    </span>
                </li>
                <!-- Linea -->
                <span class="marker-line"></span>
                <li [ngClass]="{'active' : viewSection == 'RESULT_INFO'}">
                    <!-- Circulo e icono -->
                    <span class="marker-number">
                        <i class="fa-solid fa-file-circle-check fa-2x icon pl-2"></i>
                    </span>
                </li>
            </ul>
            <!-- fin de steps -->



            <!-- Seccion de pago con tarjeta de credito CARD_INFO-->
            <ng-container *ngIf="viewSection == 'CARD_INFO'">

                <div class="title-method-payment">
                    <span>How do you want to pay?</span>
                </div>

                <div class="accordion" id="accordionCreditCard">

                    <div class="card" *ngIf="billingProfile?.gateway_customer_profile_id !== null && billingProfile?.gateway_payment_profile_id != null">
                        <div class="card-header" id="headingOne" [ngClass]="{'active' : !paymentRequestForm.is_new_payment_profile}">
                            <h2 class="mb-0">
                                <div class="n-chk">
                                    <label class="new-control new-radio radio-success">
                                        <input type="radio" class="new-control-input" name="custom-radio-1" (click)="paymentRequestForm.is_new_payment_profile = false"
                                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <span class="new-control-indicator"></span>
                                        <span class="option-label">

                                            <i class="fa-regular fa-credit-card mr-2"></i>

                                            <ng-container *ngIf="billingProfile.credit_card == null">
                                                There is no previously processed credit card
                                            </ng-container>

                                            <ng-container *ngIf="billingProfile.credit_card?.franchise !== null">
                                                {{billingProfile.credit_card.franchise | titlecase}}
                                            </ng-container>
                                            
                                            <ng-container *ngIf="billingProfile.credit_card?.number !== null">
                                                **** {{billingProfile.credit_card.number}}
                                            </ng-container>
                                            
                                            <ng-container *ngIf="billingProfile.credit_card?.expiration_date !== null">
                                                - {{billingProfile.credit_card.expiration_date}}
                                            </ng-container>

                                        </span>
                                    </label>
                                </div>                                            
                            </h2>
                        </div>                              
                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionCreditCard">
                            <div class="card-body">
                                <!-- Text -->
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingTwo" [ngClass]="{'active' : paymentRequestForm.is_new_payment_profile}">
                            <h2 class="mb-0">
                                <div class="n-chk">
                                    <label class="new-control new-radio radio-success">
                                        <input type="radio" class="new-control-input" name="custom-radio-1" (click)="paymentRequestForm.is_new_payment_profile = true"
                                        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <span class="new-control-indicator"></span>
                                        <span class="option-label">
                                            <i class="fa-regular fa-credit-card mr-2"></i>
                                            <span> New Credit Card</span>
                                        </span>
                                    </label>
                                </div>
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionCreditCard" [ngClass]="{'show' : (billingProfile?.gateway_customer_profile_id == null || billingProfile?.gateway_payment_profile_id == null)}">
                            <div class="card-body">

                                <h3 class="text-center mt-4">
                                    Credit Card Information
                                </h3>
                                <div class="mb-3 text-center">
                                    <span>Fill out the following fields with the required information</span>
                                </div>
                                <br>
                                <section class="row credit-card-form">
                                    <div class="col-6">
    
                                        <div class="tab-content" id="borderTopContent">
                                            <!-- inicio seccion credit card -->
                                            <div class="tab-pane fade show active" id="border-top-credit-card"
                                                role="tabpanel" aria-labelledby="border-top-credit-card-tab">
                                                <div class="credit-card-information">
                                                    <div class="container-card preload">
                                                        <div class="creditcard">
                                                            <div class="front">
                                                                <div id="ccsingle"></div>
                                                                <svg version="1.1" id="cardfront"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 750 471"
                                                                    style="enable-background:new 0 0 750 471;"
                                                                    xml:space="preserve">
                                                                    <g id="Front">
                                                                        <g id="CardBackground">
                                                                            <g id="Page-1_1_">
                                                                                <g id="amex_1_">
                                                                                    <path id="Rectangle-1_1_"
                                                                                        class="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                                        C0,17.9,17.9,0,40,0z" />
                                                                                </g>
                                                                            </g>
                                                                            <path class="darkcolor greydark"
                                                                                d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
                                                                        </g>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 60.106 295.0121)"
                                                                            id="svgnumber" class="st2 st3 st4">0123
                                                                            4567 8910 1112</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 54.1064 428.1723)"
                                                                            id="svgname" class="st2 st5 st6">JOHN
                                                                            DOE</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 54.1074 389.8793)"
                                                                            class="st7 st5 st8">cardholder
                                                                            name</text>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 479.7754 388.8793)"
                                                                            class="st7 st5 st8">expiration</text>
                                                                        <text transform="matrix(1 0 0 1 65.1054 241.5)"
                                                                            class="st7 st5 st8">card number</text>
                                                                        <g>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 574.4219 433.8095)"
                                                                                id="svgexpire"
                                                                                class="st2 st5 st9">01/23</text>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 479.3848 417.0097)"
                                                                                class="st2 st10 st11">VALID</text>
                                                                            <text
                                                                                transform="matrix(1 0 0 1 479.3848 435.6762)"
                                                                                class="st2 st10 st11">THRU</text>
                                                                            <polygon class="st2"
                                                                                points="554.5,421 540.4,414.2 540.4,427.9 		" />
                                                                        </g>
                                                                        <g id="cchip">
                                                                            <g>
                                                                                <path class="st2"
                                                                                    d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                                                                    c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
                                                                            </g>
                                                                            <g>
                                                                                <g>
                                                                                    <rect x="82" y="70" class="st12"
                                                                                        width="1.5" height="60" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="167.4" y="70" class="st12"
                                                                                        width="1.5" height="60" />
                                                                                </g>
                                                                                <g>
                                                                                    <path class="st12"
                                                                                        d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                                                        c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                                                        C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                                                        c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                                                        c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="82.8" y="82.1" class="st12"
                                                                                        width="25.8" height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="82.8" y="117.9"
                                                                                        class="st12" width="26.1"
                                                                                        height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="142.4" y="82.1"
                                                                                        class="st12" width="25.8"
                                                                                        height="1.5" />
                                                                                </g>
                                                                                <g>
                                                                                    <rect x="142" y="117.9" class="st12"
                                                                                        width="26.2" height="1.5" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Back">
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="back">
                                                                <svg version="1.1" id="cardback"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 750 471"
                                                                    style="enable-background:new 0 0 750 471;"
                                                                    xml:space="preserve">
                                                                    <g id="Front">
                                                                        <line class="st0" x1="35.3" y1="10.4" x2="36.7"
                                                                            y2="11" />
                                                                    </g>
                                                                    <g id="Back">
                                                                        <g id="Page-1_2_">
                                                                            <g id="amex_2_">
                                                                                <path id="Rectangle-1_2_"
                                                                                    class="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                                                    C0,17.9,17.9,0,40,0z" />
                                                                            </g>
                                                                        </g>
                                                                        <rect y="61.6" class="st2" width="750"
                                                                            height="78" />
                                                                        <g>
                                                                            <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                                                                C707.1,246.4,704.4,249.1,701.1,249.1z" />
                                                                            <rect x="42.9" y="198.6" class="st4"
                                                                                width="664.1" height="10.5" />
                                                                            <rect x="42.9" y="224.5" class="st4"
                                                                                width="664.1" height="10.5" />
                                                                            <path class="st5"
                                                                                d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
                                                                        </g>
                                                                        <text
                                                                            transform="matrix(1 0 0 1 621.999 227.2734)"
                                                                            id="svgsecurity" class="st6 st7">985</text>
                                                                        <g class="st8">
                                                                            <text
                                                                                transform="matrix(1 0 0 1 518.083 280.0879)"
                                                                                class="st9 st6 st10">security
                                                                                code</text>
                                                                        </g>
                                                                        <rect x="58.1" y="378.6" class="st11"
                                                                            width="375.5" height="13.5" />
                                                                        <rect x="58.1" y="405.6" class="st11"
                                                                            width="421.7" height="13.5" />
                                                                        <text
                                                                            transform="matrix(1 0 0 1 59.5073 228.6099)"
                                                                            id="svgnameback" class="st12 st13">John
                                                                            Doe</text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- fin seccion credit card -->
                                        </div>
    
                                    </div>
    
    
                                    <div class="col-6 ">
                                        <form>
                                            <div class="form-group mb-3">
                                                <label for="name">Name on Card</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="paymentRequestForm.credit_card.holder_name"
                                                    id="name_credit_card" placeholder="Name" name="name_credit_card">
                                            </div>
    
                                            <div class="form-group mb-3" style="position: relative;">
                                                <label for="cardNumber">Card Number (no spaces or dashes)</label>
                                                <input type="text" class="form-control"
                                                    [(ngModel)]="paymentRequestForm.credit_card.number" id="cardNumber"
                                                    placeholder="0000 0000 0000 0000" maxlength="19" name="cardNumber">
                                                <svg id="ccicon" class="ccicon" width="750" height="471"
                                                    viewBox="0 0 750 471" version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                </svg>
                                            </div>
    
                                            <div class="row">
    
                                                <div class="col-8">
                                                    <div class="row">
                                                        <div class="col-5 form-group">
                                                            <label for="">Expiration</label>
                                                            <input type="text" class="form-control" id="expiration_date"
                                                                placeholder="MM" maxlength="2" name="expiration_date"
                                                                [(ngModel)]="paymentRequestForm.credit_card.expiration_date.month">
                                                        </div>
    
                                                        <div class="col-2 p-0 marg-yy text-center">
                                                            <label for="">&nbsp;</label>
                                                            <div>/</div>
                                                        </div>
    
                                                        <div class="col-5 form-group">
                                                            <label for="">&nbsp;</label>
                                                            <input type="text" class="form-control"
                                                                id="expiration_date_yy" placeholder="YY" maxlength="2"
                                                                name="expiration_date_yy"
                                                                [(ngModel)]="paymentRequestForm.credit_card.expiration_date.year">
                                                        </div>
                                                    </div>
                                                </div>
    
    
                                                <div class="col-4 form-group">
                                                    <label for="securitycode">CVV</label>
                                                    <input type="text" class="form-control" pattern="[0-9]*"
                                                        inputmode="numeric"
                                                        [(ngModel)]="paymentRequestForm.credit_card.security_code"
                                                        id="securitycode" placeholder="123" maxlength="4"
                                                        name="securitycode">
                                                </div>
                                            </div>
    
                                            <div class="form-group m-0">
                                                <hr class="separator-credit-card">
                                            </div>
    
                                            <div class="row">
                                                <div class="col-8 form-group mb-3">
                                                    <label for="">Street</label>
                                                    <input type="text"
                                                        [(ngModel)]="paymentRequestForm.billing_address.street"
                                                        class="form-control" id="address" placeholder="address"
                                                        name="address">
                                                </div>
    
                                                <div class="col-4 form-group mb-3">
                                                    <label class="control-label">State</label>
                                                    <select name="state" id="state"
                                                        [(ngModel)]="paymentRequestForm.billing_address.state"
                                                        class="form-control">
                                                        <option value="AK">Alaska</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>
                                                        <option value="HI">Hawaii</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NC">North-carolina</option>
                                                        <option value="ND">North-dakota</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NH">New-hampshire</option>
                                                        <option value="NJ">New-jersey</option>
                                                        <option value="NM">New-mexico</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NY">New-york</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode-island</option>
                                                        <option value="SC">South-carolina</option>
                                                        <option value="SD">South-dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="NM">Wisconsin</option>
                                                        <option value="WV">West-virginia</option>
                                                        <option value="WY">Wyoming</option>
                                                    </select>
                                                </div>
                                            </div>
    
                                            <div class="row">
                                                <div class="col-8 form-group mb-3">
                                                    <label for="city">City</label>
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="paymentRequestForm.billing_address.city" id="city"
                                                        placeholder="city" name="city">
                                                </div>
    
                                                <div class="col-4 form-group mb-3">
                                                    <label for="zipCode">Zip Code</label>
                                                    <input type="text" class="form-control" id="zip_code"
                                                        [(ngModel)]="paymentRequestForm.billing_address.zip_code"
                                                        name="zip_code" placeholder="zip code">
                                                </div>
                                            </div>
    
                                        </form>
                                    </div>
    
                                </section>
                            
                            </div>
                        </div>
                    </div>

                </div>

            </ng-container>
            <!-- Fin de seccion de pago con tarjeta de credito -->

            <hr>

            <!-- Seccion de informacion del pago -->
            <ng-container *ngIf="viewSection == 'RESULT_INFO'">

                <h3 class="text-center">
                    Payment Transaction Result
                </h3>

                <br>

                <section class="justify-content-center payment-transaction-result">

                    <ng-container *ngIf="payment !== null" >

                        <!-- Payment Successful -->
                        <div class="custom-alert" *ngIf="payment.status == 'ACCEPTED'" >
                            <div class="alert-successful">
                                <div class="row align-items-center">
                                    <div class="col-2 pr-0 text-center">
                                        <i class="far fa-check-circle icon-payment"></i>
                                    </div>
                                    <div class="col-10 pl-0">
                                        <h4 class="m-0 text-payment">
                                            Successful Payment 
                                        </h4>
                                        <span class="desc-payment">
                                            {{payment.payment_transaction.message}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Payment Failed -->
                        <div class="custom-alert" *ngIf="payment.status == 'ERROR' || payment.status == 'DECLINED'">
                            <!-- *ngIf="responseTransaction.transaction.status == 'DECLINED' || responseTransaction.transaction.status == 'ERROR'; else noneTransaction" -->
                            <div class="alert-failed">
                                <div class="row align-items-center">
                                    <div class="col-2 pr-0 text-center">
                                        <i class="far fa-times-circle icon-payment"></i>
                                    </div>
                                    <div class="col-10 pl-0">
                                        <h4 class="m-0 text-payment">
                                            Failed Payment
                                        </h4>
                                        <span class="desc-payment">
                                            {{payment.payment_transaction.message}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Payment held for review -->
                        <div class="custom-alert" *ngIf="payment.status == 'HELD_FOR_REVIEW'">
                            
                            <!-- *ngIf="responseTransaction.transaction.status == 'HELD_FOR_REVIEW'" -->
                            <div class="alert-review">
                                <div class="row align-items-center">
                                    <div class="col-2 pr-0 text-center">
                                        <i class="fa-solid fa-triangle-exclamation icon-payment"></i>
                                    </div>
                                    <div class="col-10 pl-0">
                                        <h4 class="m-0 text-payment">
                                            Payment held for review
                                        </h4>
                                        <span class="desc-payment">
                                            {{payment.payment_transaction.message}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <br>

                    <div class="payment-receipt" *ngIf="invoice.status == 'PAID'" >

                        <div class="header-receipt">
                            <div class="logo">
                                <!--
                                    <img src="/assets/img/logo_moverxpro360.png" alt="logo moverxpro360">
                                -->
                                <img src="/assets/img/logotipo_moverxpro360.png" alt="logo moverxpro360">
                            </div>
                            <h3 class="text-center">
                                MoverXpro360
                            </h3>
                            <p class="text-center">
                                info@moverxpro360.com
                            </p>
                            <p class="text-center">
                                888-444-4988
                            </p>
                        </div>

                        <br>
                        <div class="separator">
                            **********************************************************************
                        </div>
                        <h4 class="text-center">
                            PAYMENT RECEIPT
                        </h4>
                        <div class="separator">
                            **********************************************************************
                        </div>
                        <br>

                        <div class="body-receipt">

                            <p>
                                Date:     <strong>{{ invoice.payment_date | dateTime }}</strong>  
                            </p>
                            <p>
                                Invoice #:     <strong>{{ invoice.id }}</strong>  
                            </p>
                            <p>
                                Billing Period:     <strong>{{ invoice.start_period | date }} - {{ invoice.end_period | date }}</strong>  
                            </p>
                            <p>
                                Invoice To:     <strong>{{ invoice.billing_profile?.company}}</strong>  
                            </p>
                            <p>
                                Address:     <strong>{{ invoice.billing_profile?.address?.full_address}}</strong>  
                            </p>

                            <br>
                            <div class="separator">
                                **********************************************************************
                            </div>
                            <br>

                            <p>
                                Accounts:   <span>{{ invoice.total_users }}</span>  <strong>{{ invoice.total_amount_users | currency }}</strong>  
                            </p>
                            <p>
                                Companies:  <span>{{ invoice.total_companies }}</span>   <strong>{{ invoice.total_amount_companies | currency }}</strong>  
                            </p>
                            <p class="total-amount">
                                Total Payment:   <strong>{{ invoice.total_amount | currency }}</strong>
                            </p>


                            <br>
                            <div class="separator">
                                **********************************************************************
                            </div>
                            <br>
                            
                            <p>
                                Payment method:     <strong>Credit Card</strong>  
                            </p>
                            <p>
                                Card Ending:     <strong>XXXX XXXX XXXX {{ payment?.payment_transaction.bank_account_number }}</strong>  
                            </p>
                            <p>
                                Card Issuer:     <strong>{{ payment?.payment_transaction.bank_account_type }}</strong>  
                            </p>
                            <p>
                                Transaction ID:     <strong>{{ payment?.payment_transaction.transaction_id }}</strong>  
                            </p>
                            <p>
                                AuthCode:     <strong>{{ payment?.payment_transaction.auth_code }}</strong>  
                            </p>
                            
                            
                        </div>

                        <br>

                        <div class="separator">
                            **********************************************************************
                        </div>

                        <div class="footer-receipt">
                            <p class="text-center">
                                Thank you for choosing us!
                            </p>
                            <br>
                            <img  class="barcode"  src="/assets/img/barcode-moverxpro360.png" alt="barcode-moverxpro360">
                        </div>

                    </div>
                    
                    <br>

                </section>

            </ng-container>
            <!-- Fin de la seccion de informacion del pago -->

            <br>

            <!-- Seccion de botones next and preview -->
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-12 next-and-preview text-center">
                <button class="btn btn-primary" *ngIf="viewSection == 'RESULT_INFO' && invoice.status == 'PENDING' " (click)="changeSection('previous')">
                    Preview
                </button>
                <button class="btn btn-success" *ngIf="viewSection == 'CARD_INFO'" (click)="changeSection('process-payment')">
                    Make payment
                </button>
            </div>
            <!-- Fin de seccion de botones next and preview -->
        </div>
                
    </div>
</div>