<div class="layout-spacing layout-top-spacing">

    <!-- Balance Due On Deposit -->
    <div class="widget-content widget-content-area">
        <div class="widget-heading header-section">
            <h3 class="title-content-area">
                Balance Due On Deposit
            </h3>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-col-5">
                <thead>
                    <tr>
                        <th>Base set %</th>
                        <th>Base amount due</th>
                        <th>Current amount due</th>
                        <th>Altered amount due</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{estimate.deposit_due_balance.base_set}}
                        </td>
                        <td>
                            {{ estimate.deposit_due_balance.base_amount_due | currency }}
                        </td>
                        <td>
                            {{ estimate.deposit_due_balance.current_amount_due | currency }}
                        </td>
                        <td>
                            {{ estimate.deposit_due_balance.altered_amount_due | currency }}
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-secondary" (click)="openChangeAmountDueModal()">
                                Change Amount Due
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Companies Checks & Balances -->
    <div class="widget-content widget-content-area">
        <div class="widget-heading header-section">
            <h3 class="title-content-area pb-1">
                Companies Checks & Balances
            </h3>
            <a (click)="addExpenses()" class="btn btn-new" appCanUserCompany="ESTIMATE_BALANCE_ADD" >
                <i class="fas fa-plus-square mr-2"></i> Add Expense
            </a>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Job Total</th>
                        <th>Agent Pay</th>
                        <th>JPAP</th>
                        <th>Paid to Agent</th>
                        <th>Owed to in-House</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <ng-container *ngFor="let rowBroker of brokers" >
                                <ng-container *ngIf="rowBroker.id == estimate.broker_id">
                                    {{rowBroker.name}}
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            {{estimate.total | currency}}
                        </td>
                        <td>
                            {{estimate.broker_pay | currency}}
                        </td>
                        <td>
                            {{(estimate.total - estimate.broker_pay) | currency}}
                        </td>
                        <td>
                            {{0 | currency}}
                        </td>
                        <td>
                            {{0 | currency}}
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-secondary" (click)="openChangeCompaniesChecksBalancesModal()">
                                Set Broker
                            </button>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- estimate Balance -->
    <div class="widget-content widget-content-area">
        <div class="widget-heading header-section">
            <h3 class="title-content-area">
                Job Balances
            </h3>
            <a (click)="addPayment()" class="btn btn-new btn-add-balance" appCanUserCompany="ESTIMATE_BALANCE_ADD">
                <i class="fas fa-plus-square mr-2"></i> Add Balance
            </a>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Concept</th>
                        <th>Target</th>
                        <th>Amount</th>
                        <th>Paid To</th>
                        <th>Job</th>
                        <th>Storage</th>
                        <th>Status</th>
                        <th>Signed</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of estimateBalances">
                        <td>
                            {{row.concept}}
                        </td>
                        <td>
                            {{row.target | filterReplace}}
                        </td>
                        <td> {{row.amount | currency}} </td>
                        <td> {{row.paid_to}} </td>
                        <td> {{row.job_amount | currency}} </td>
                        <td> {{row.allocated_to_payments_storage | currency}} </td>
                        <td>
                            <span class="badge" [ngClass]="{'badge-warning': row.status === 'PENDING' || row.status === 'REFUNDED' || row.status === 'CERTIFIED_FOUNDS', 'badge-info': row.status === 'PROCESSING', 'badge-success': row.status === 'PAID'}">
                                <span *ngIf="row.status === 'CERTIFIED_FOUNDS'">Certified Funds</span>
                                <span *ngIf="row.status !== 'CERTIFIED_FOUNDS'">{{row.status | filterReplace}}</span>
                            </span>
                        </td>
                        <td>
                            <span *ngIf="row.status == 'PAID' && row.payment_method?.method == 'CREDIT_CARD'">
                                <span *ngIf="row.is_signed" class="badge badge-success">
                                    SIGNED
                                </span>
                                <span *ngIf="!row.is_signed" class="badge badge-danger">
                                    NONE
                                </span>
                            </span>

                            <span *ngIf="row.status == 'PAID' && row.payment_method?.method != 'CREDIT_CARD'">  <b>N/A</b> </span>
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-warning btn-confirm-payment" *ngIf="row.status == 'PAID'"
                                (click)="payTo(row)" >
                                <i class="fa-solid fa-money-bill"></i>
                            </button>
                            <a *ngIf="row.status === 'PAID'" appCompanyRouterLink="/payments/{{row.id}}/view" class="btn btn-info btn-view-payment">
                                <i class="fa-solid fa-eye"></i>
                            </a>
                            <a *ngIf="row.status === 'PENDING_REVIEW'" appCompanyRouterLink="/payments/{{row.id}}/view" class="btn btn-info btn-view-payment">
                                <i class="fa-solid fa-eye"></i>
                            </a>
                            <button type="button" class="btn btn-success btn-confirm-payment" *ngIf="row.status == 'PENDING'"
                                (click)="addBalance(row)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                Confirm Payment
                            </button>
                            <button type="button" class="btn btn-success btn-confirm-payment" *ngIf="row.status == 'CERTIFIED_FOUNDS'"
                                (click)="confirmCertifierFounds(row.id)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                Confirm Payment
                            </button>
                            <button *ngIf="row.status == 'PAID' && row.payment_method?.method == 'CREDIT_CARD'"
                                    class="btn btn-warning btn-refund-payment" (click)="refundCreditCardPayment(row)">
                                Refund Payment
                            </button>
                            <button *ngIf="!row.is_signed && row.status == 'PAID' && row.payment_method?.method == 'CREDIT_CARD'" type="button" class="btn btn-secondary"
                                (click)="sendEmail(row)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                <i class="fa-solid fa-paper-plane mr-2"></i>
                                Send Email
                            </button>
                            <button class="btn btn-danger" [disabled]="estimate.status == 'WORK_CARRIED_OUT'"
                                    (click)="removeBalance(row)" appCanUserCompany="ESTIMATE_BALANCE_DELETE">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- storage balances -->
    <div class="widget-content widget-content-area">
        <div class="widget-heading header-section">
            <h3 class="title-content-area">
                Storage Balances
            </h3>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-col-5">
                <thead>
                    <tr>
                        <th>Concept</th>
                        <th>Amount</th>
                        <th>Total Paid</th>
                        <th>Status</th>
                        <th>Signed</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of storageBalances">
                        <td>
                            {{row.concept}}
                        </td>
                        <td>
                            {{row.amount | currency}}
                        </td>
                        <td>
                            {{row.amount_paid | currency}}
                        </td>
                        <td>
                            <span class="badge" [ngClass]="{'badge-warning': row.status === 'PENDING' || row.status === 'PARTIAL_PAID' || row.status === 'REFUNDED' || row.status === 'CERTIFIED_FOUNDS', 'badge-info': row.status === 'PROCESSING', 'badge-success': row.status === 'PAID'}">
                                {{row.status | filterReplace}}
                            </span>
                        </td>
                        <td>
                            <span *ngIf="row.status == 'PAID' && row.payment_method?.method == 'CREDIT_CARD'">
                                <span *ngIf="row.is_signed" class="badge badge-success">
                                    SIGNED
                                </span>
                                <span *ngIf="!row.is_signed" class="badge badge-danger">
                                    NONE
                                </span>
                            </span>

                            <span *ngIf="row.status == 'PAID' && row.payment_method?.method != 'CREDIT_CARD'">  <b>N/A</b> </span>
                        </td>
                        <td class="text-right btn-action-sb">
                            <a *ngIf="row.status === 'PARTIAL_PAID' || row.status === 'PARTIAL_PENDING_REVIEW' || row.status === 'PAID'" appCompanyRouterLink="/payments/{{row.id}}/view" class="btn btn-info">
                                <i class="fa-solid fa-eye"></i>
                            </a>
                            <a *ngIf="row.status === 'PENDING_REVIEW'" appCompanyRouterLink="/payments/{{row.id}}/view" class="btn btn-info">
                                <i class="fa-solid fa-eye"></i>
                            </a>
                            <button type="button" class="btn btn-secondary" *ngIf="row.status == 'PENDING' || row.status == 'PARTIAL_PAID'"
                                (click)="openModalPartialPayment(row, false)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                Partial Payment
                            </button>
                            <button type="button" class="btn btn-success" *ngIf="row.status == 'PARTIAL_PAID'"
                                (click)="openModalPartialPayment(row, true)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                Confirm Remainder
                            </button>
                            <button type="button" class="btn btn-success" *ngIf="row.status == 'PENDING'"
                                (click)="addBalance(row)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                Confirm Payment
                            </button>
                            <button *ngIf="row.status == 'PAID' && row.payment_method?.method == 'CREDIT_CARD'"
                                    class="btn btn-warning" (click)="refundCreditCardPayment(row)">
                                Refund Payment
                            </button>
                            <button *ngIf="!row.is_signed && row.status == 'PAID' && row.payment_method?.method == 'CREDIT_CARD'" type="button" class="btn btn-secondary"
                                (click)="sendEmail(row)" [disabled]="estimate.status == 'WORK_CARRIED_OUT'">
                                <i class="fa-solid fa-paper-plane mr-2"></i>
                                Send Email
                            </button>
                            <button class="btn btn-danger" [disabled]="estimate.status == 'WORK_CARRIED_OUT'"
                                    (click)="removeBalance(row)" appCanUserCompany="ESTIMATE_BALANCE_DELETE">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                            <button *ngIf="row.status == 'PAID'" class="btn btn-danger" [disabled]="estimate.status == 'WORK_CARRIED_OUT'"
                                    (click)="rejectBalance(row)" appCanUserCompany="ESTIMATE_BALANCE_DELETE">
                                <i class="fa-solid fa-money-bill"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Expenses -->
    <div class="widget-content widget-content-area" *ngIf="estimate.service != 'AUTO_TRANSPORT'">
        <div class="widget-heading header-section">
            <h3 class="title-content-area">
                Expenses
            </h3>
            <a (click)="addExpenses()" class="btn btn-new" appCanUserCompany="ESTIMATE_BALANCE_ADD" >
                <i class="fas fa-plus-square mr-2"></i> Add Expense
            </a>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-col-5">
                <thead>
                    <tr>
                        <th>Concept</th>
                        <th>Target</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of expenses">
                        <td>
                            {{row.concept}}
                        </td>
                        <td>
                            {{row.target | filterReplace}}
                        </td>
                        <td>
                            {{row.amount | currency}}
                        </td>
                        <td>
                            <span *ngIf="row.status === 'PROCESSING'" class="badge badge-secondary">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PAID'" class="badge badge-success">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING'" class="badge badge-warning">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING_REVIEW'" class="badge badge-info">
                                {{row.status}}
                            </span>
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-info" (click)="editExpense(row)">
                                <i class="fa-solid fa-eye"></i>
                            </button>
                            <button class="btn btn-danger" (click)="removeExpense(row.id)" appCanUserCompany="ESTIMATE_BALANCE_DELETE" >
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Commissions -->
    <div class="widget-content widget-content-area" *ngIf="estimate.service != 'AUTO_TRANSPORT'">
        <div class="widget-heading header-section">
            <h3 class="title-content-area">
                Commissions
            </h3>
            <!-- <a (click)="addExpenses()" class="btn btn-new" appCanUserCompany="ESTIMATE_BALANCE_ADD" >
                <i class="fas fa-plus-square mr-2"></i> Add Expense
            </a> -->
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-col-5">
                <thead>
                    <tr>
                        <th>Total</th>
                        <th>Payment</th>
                        <th>Commission</th>
                        <th>Name</th>
                        <th class="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of commissions">
                        <td>
                            {{row.total_job | currency}}
                        </td>
                        <td>
                            {{row.total_payments | currency}}
                        </td>
                        <td>
                            {{row.total_commission | currency}}
                        </td>

                        <td>
                            {{row.employee_id }}
                        </td>
                        <td class="text-center">
                            <span *ngIf="row.status === 'PROCESSING'" class="badge badge-secondary">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PAID'" class="badge badge-success">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING'" class="badge badge-warning">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING_REVIEW'" class="badge badge-info">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING_PAYMENT'" class="badge badge-info">
                                {{row.status}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <!-- Delivery Cost -->
    <div class="widget-content widget-content-area" *ngIf="estimate.service != 'AUTO_TRANSPORT'">
        <div class="widget-heading header-section">
            <h3 class="title-content-area">
                Delivery Costs
            </h3>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-col-5">
                <thead>
                    <tr>
                        <th>Concept</th>
                        <th>Target</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of deliveryCost">
                        <td>
                            {{row.concept}}
                        </td>
                        <td>
                            {{row.target | filterReplace}}
                        </td>
                        <td>
                            {{row.amount | currency}}
                        </td>
                        <td>
                            <span *ngIf="row.status === 'PROCESSING'" class="badge badge-secondary">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PAID'" class="badge badge-success">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING'" class="badge badge-warning">
                                {{row.status}}
                            </span>
                            <span *ngIf="row.status === 'PENDING_REVIEW'" class="badge badge-info">
                                {{row.status}}
                            </span>
                        </td>
                        <td class="text-right">
                            <button type="button" class="btn btn-info" (click)="editExpense(row)">
                                <i class="fa-solid fa-eye"></i>
                            </button>
                            <button class="btn btn-danger" (click)="removeExpense(row.id)"
                                appCanUserCompany="ESTIMATE_BALANCE_DELETE">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<!-- Modal Add Payment -->
<div class="modal fade" id="addEmail" #paymentModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add Balance
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="form-group mb-4" *ngIf="estimate.service !== 'AUTO_TRANSPORT'">
                            <label for="balance_target">Target</label>
                            <select name="balance_target" [(ngModel)]="balance.target"
                                (change)='validateAmountToBalance()' class="form-control">
                                <!-- <option value="STORAGE">Storage</option> -->
                                <option value="OPERATIONS">Operations</option>
                                <option value="NEW_BOOKING">New Booking</option>
                                <option value="QUALITY_ASSURANCE">Quality Assurance</option>
                                <option *ngIf="job.id && job.job_outsourcing && job.job_outsourcing.status == 'WORKING_ON'" value="CARRIER_TO_BROKER">Carrier To Broker</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Target.</small>
                        </div>
                        <div class="form-group mb-4" *ngIf="showOptionsStorage">
                            <label for="balance_target">Warehouse Invoice</label>
                            <select [(ngModel)]="storageInvociceSelected" name="wd_employees_id" class="form-control" (change)='setInvoiceValue()'>
                                <option value="">Select an Invoice</option>
                                <option *ngFor="let storageInvocice of storageInvocices" value="{{storageInvocice.id}}">
                                    {{storageInvocice.document.code}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group mb-4" *ngIf="balance.target == 'QUALITY_ASSURANCE'">
                            <label for="balance_target">Comissioning Employee</label>
                            <select [(ngModel)]="balance.comissioning_employee_id" name="wd_employees_id"
                                class="form-control" (change)='validateDisableSaveBalance(false)'>
                                <option *ngFor="let employe of employees" value="{{employe.id}}">
                                    {{employe.name}}</option>
                            </select>
                        </div>

                        <div class="form-group mb-4" *ngIf="estimate.service !== 'AUTO_TRANSPORT'">
                            <label for="balance_target">Concept</label>
                            <select name="balance_target" [(ngModel)]="balance.concept" (change)='validateAmountToBalance()' class="form-control">
                                <option value="Deposit">Deposit</option>
                                <option value="Pick up payment">Pick up payment</option>
                                <option value="Delivery payment">Delivery payment</option>
                                <option value="Storage payment">Storage payment</option>
                                <option value="Refund">Refund</option>
                                <option value="Misc payment">Misc payment</option>
                                <option *ngIf="job.id && job.job_outsourcing && job.job_outsourcing.status == 'WORKING_ON'" value="CARRIER_TO_BROKER">Carrier To Broker</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Concept.</small>
                        </div>

                        <div class="form-group mb-1">
                            <label for="exampleFormControlSelect1">Amount</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                </div>
                                <input type="number" (keyup)="validateDisableSaveBalance(true)" [(ngModel)]="balance.amount"
                                name="wd_amount" class="form-control" [ngClass]="{'manual-balance-ammount': manualBalanceAmmount}">
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Enter Amount.</small>
                        </div>

                        <div class="form-group mb-1">
                            <label>Payment Methods Enabled</label>
                            <div class="input-group mb-1 flex-nowrap payment-methods-enabled">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                </div>
                                <select [(ngModel)]="balance.payment_methods_enabled_id" name="payment_methods_enabled"
                                        class="form-control method select-methods" multiple="multiple">
                                    <option *ngFor="let methodPayment of methodsPayment" [value]="methodPayment.id">
                                        {{methodPayment.name | filterReplace}}
                                    </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Payment Methods.</small>
                        </div>

                        <div class="form-group mb-1" *ngIf="balance.concept == 'Pick up payment' || balance.concept == 'Delivery payment'">
                            <div class="n-chk">
                                <label class="new-control new-checkbox checkbox-warning">
                                <input type="checkbox" class="new-control-input" [checked]="balance.certified_founds" [(ngModel)]="balance.certified_founds" name="certified_founds">
                                <span class="new-control-indicator"></span>Certified Funds.
                                </label>
                            </div>
                        </div>

                        <div class="form-group mb-1" *ngIf="showGateways">
                            <label>Select a Credit Card Gateway</label>
                            <div class="input-group mb-1 flex-nowrap gateways">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text" id="basic-addon6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                </div>
                                <select [(ngModel)]="balance.payment_gateway_setting_id" name="payment_gateway_setting_id"
                                    (ngModelChange)="selectCCFee()"
                                    class="form-control method select-methods">
                                    <ng-container *ngFor="let gateway of gateways" >
                                        <option [value]="gateway.id" *ngIf="gateway.active_cc">
                                            {{gateway.nickname}} - {{gateway.name}}
                                        </option>
                                    </ng-container>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Payment Methods.</small>
                        </div>

                        <div class="form-group mb-1" *ngIf="showGateways">
                            <label>CC Fee {{paymentGatewaySetting.cc_fee}}%</label>
                            <div class="input-group mb-1 flex-nowrap gateways">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text" id="basic-addon6">
                                        <i class="fa-solid fa-percent"></i>
                                    </span>
                                </div>
                                <input [(ngModel)]="balance.cc_fee" name="cc_fee" class="form-control" disabled>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Payment Methods.</small>
                        </div>

                        <div class="form-group mb-4">
                            <label for="balance_target">Payment Allocation</label>
                            <select name="balance_target" [(ngModel)]="balance.payment_allocation" class="form-control">
                                <option value="AGENT">Agent</option>
                                <option value="IN_HOUSE">In House</option>
                            </select>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Select if in-house our out-source.</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button [disabled]="disableSaveBalance" (click)="validateBalance()" data-dismiss="modal" class="btn btn-save">
                    Add Balance
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add Expense -->
<div class="modal fade" id="expensesModal" #expensesModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="width: 860px; max-width: 1000px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add Expense
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label for="balance_target">Target</label>
                                    <select name="balance_target" [(ngModel)]="expense.target" class="form-control">
                                        <option value="WORKERS">WORKERS</option>
                                        <option value="CARRIER">CARRIER</option>
                                        <option value="WAREHOUSE">WAREHOUSE</option>
                                        <option value="FUEL">FUEL</option>
                                        <option value="DELIVERY_AGENT">Paid to Agent</option>
                                        <option value="STORE_COST">Store Cost</option>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select Target.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label for="balance_target">Status</label>
                                    <select [(ngModel)]="expense.status" name="expense_status" class="form-control">
                                        <option value="PAID">PAID</option>
                                        <option value="PENDING">PENDING</option>
                                    </select>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select Status.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label>Payment Methods Enabled</label>
                                    <div class="input-group mb-1 flex-nowrap payment-methods-enabled">
                                        <div class="input-group-prepend ">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                        </div>
                                        <select name="balance_payment_methods" [(ngModel)]="expense.payment_method"  class="form-control">
                                            <option value="Credit card">Credit card</option>
                                            <option value="Zelle">Zelle</option>
                                            <option value="Wire">Wire</option>
                                            <option value="PayPal">PayPal</option>
                                        </select>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select Payment Methods.</small>
                                </div>

                                <div class="form-group mb-4" >
                                    <label for="balance_target">Comments</label>
                                    <textarea class="form-control" name="wd_concept" rows="5" [(ngModel)]="expense.comments"></textarea>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Comments.</small>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-group mb-4" >
                                    <label for="balance_target">Concept</label>
                                    <input type="text" [(ngModel)]="expense.concept" name="wd_concept" class="form-control">
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Concept.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label for="exampleFormControlSelect1">Amount</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                        </div>
                                        <input type="number" [(ngModel)]="expense.amount" name="wd_amount" class="form-control">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Amount.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label for="inputState">Payment Date</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg></span>
                                        </div>
                                        <app-date-picker [value]="expense.payment_date" [enableTime]="false" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDateExpense($event)" style="width: 84%;"></app-date-picker>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select Payment Date.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <div class="form-group">
                                        <div class="toggle-switch">
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="is_delivery_cost" name="is_delivery_cost" [(ngModel)]="expense.is_delivery_cost">
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="is_delivery_cost" class="checkbox-label" [ngClass]="{'filter-disabled': !expense.is_delivery_cost}">
                                                Is Delivery Cost
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group mb-1">
                                    <label for="">Voucher</label>
                                    <div>
                                        <button class="btn btn-success" (click)="openUploadModal()">
                                            <i class="fa-solid fa-image mr-2"></i> Upload File
                                        </button>
                                    </div>
                                    <div class="row ">
                                        <ng-container *ngFor="let voucher of expense.vouchers; let i = index">
                                            <div class="voucher-previewer">
                                                <a class="delete-button-file" (click)="deleteImage(i)" appCanUserCompany="'owner'">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </a>
                                                <img src="{{environment.api.base}}/tmp/{{voucher.display_name}}" alt="">
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveExpense()" data-dismiss="modal" class="btn btn-save">
                    Add Expense
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Confirm Payment -->
<div class="modal fade" id="addPaymentModal" #addPaymentModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="width: 800px; max-width: 1000px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Confirm Payment
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group mb-4">
                                    <label class="control-label">Amount</label>
                                    <input type="number" [(ngModel)]="payment.amount" name="wd_amount"
                                        class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group mb-4">
                                    <label class="control-label">Date</label>
                                    <app-date-picker [value]="payment.date" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDate($event)">
                                    </app-date-picker>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mb-4">
                                    <label for="inputState">
                                        Remarks
                                    </label>
                                    <div class="list-title">
                                        <textarea class="form-control" name="estimate_followup_remarks"
                                            id="estimate_followup_remarks" [(ngModel)]="payment.remarks"
                                            placeholder="Write the follow-up here" cols="40" rows="6">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mb-4">
                                    <label class="control-label">Payment Method Used</label>
                                    <select name="operator_dataForm.amount" class="form-control"
                                        [(ngModel)]="payment.method_id" (ngModelChange)="selectPaymentMethod($event)">
                                        <option *ngFor="let methodsPayment of methodsConfirmPayments" value="{{methodsPayment.id}}">
                                            {{methodsPayment.name | filterReplace}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="(paymentMethod.method == 'BANK_TRANSFER_OFFLINE' || paymentMethod.method == 'CHECK' || paymentMethod.method == 'MONEY_ORDER' || paymentMethod.method == 'CASH')">
                            <div class="col-12 col-xl-12 col-lg-12 upload-file">
                                <app-file-upload [multiple]="true" (onChange)="uploadDocument($event, 'digital_signs', 'PAID')">
                                </app-file-upload>
                            </div>
                        </div>

                        <div class="row mt-4 upload-file-transfer">
                            <ng-container *ngFor="let previewImg of payment.vouchers">
                                <div class="file">
                                    <div class="delete-button-file" (click)="deleteImageInventoryItem(previewImg)">
                                        x
                                    </div>
                                    <img height="50px" *ngIf="previewImg.display_name" src="{{urlBase}}/tmp/{{previewImg.display_name}}"/>
                                </div>
                            </ng-container>
                        </div>

                        <div class="row" [hidden]="paymentMethod.method !== 'CREDIT_CARD'">
                            <div class="col-12 col-xl-12 col-lg-12">
                                <div class="widget-content widget-content-area container-form-credit-card">
                                        <!-- <h3>Credit Card Information</h3> -->
                                    <section class="row">
                                        <div class="col-12">
                                            <form>
                                                <div class="row form-credit-card form-padd">

                                                    <!--
                                                    <div class="col-12 form-group mb-3">
                                                        <label for="name">Customer Name</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.card_holder.name" id="name_card_holder"
                                                            placeholder="Name">
                                                    </div>
                                                    <div class="col-12 form-group mb-3">
                                                        <label for="name">Customer Last Name</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.card_holder.last_name"
                                                            id="name_card_holder" placeholder="Last Name">
                                                    </div>
                                                    -->

                                                    <div class="col-12 form-group mb-3">
                                                        <label for="name">Name on Card</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.credit_card.holder_name"
                                                            id="name_credit_card" placeholder="Name"
                                                            name="name_credit_card">
                                                    </div>

                                                    <div class="method-square" [hidden]="paymentGatewaySetting.name !== 'SQUARE'">
                                                        <div class="col-12 form-group mb-3">
                                                            <form id="payment-form">
                                                                <div id="card-container"></div>
                                                                <button id="card-button" type="button" style="display: none;">Pay $1.00</button>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 form-group mb-3" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for="cardNumber">Card Number (no spaces or dashes)</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.credit_card.number" id="cardNumber"
                                                            inputmode="numeric"
                                                            placeholder="0000 0000 0000 0000" maxlength="19"
                                                            name="cardNumber">
                                                    </div>
                                                    <div class="col-4 form-group mb-3 pr-3" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for="">Expiration</label>
                                                        <input type="text" class="form-control" id="expiration_date"
                                                            inputmode="numeric"
                                                            placeholder="MM" maxlength="2" name="expiration_date"
                                                            [(ngModel)]="paymentProfile.credit_card.expiration_date.month">
                                                    </div>
                                                    <div class="col-4 form-group mb-3 pl-3 expiration-date-yy" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for=""></label>
                                                        <input type="text" class="form-control" id="expiration_date_yy"
                                                            inputmode="numeric"
                                                            placeholder="YY" maxlength="2" name="expiration_date_yy"
                                                            [(ngModel)]="paymentProfile.credit_card.expiration_date.year">
                                                    </div>

                                                    <div class="col-4 form-group mb-3" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for="securitycode">CVV</label>
                                                        <input type="text" class="form-control" pattern="[0-9]*"
                                                            inputmode="numeric"
                                                            [(ngModel)]="paymentProfile.credit_card.security_code"
                                                            id="securitycode" placeholder="123" maxlength="4"
                                                            name="securitycode">
                                                    </div>

                                                    <div class="col-12 form-group m-0">
                                                        <hr class="separator-credit-card">
                                                    </div>

                                                    <div class="col-12 col-sm-8 form-group mb-3">
                                                        <label for="">Street</label>
                                                        <input type="text"
                                                            [(ngModel)]="paymentProfile.billing_address.street"
                                                            class="form-control" id="address" placeholder="address"
                                                            name="address">
                                                    </div>

                                                    <div class="col-12 col-sm-4 form-group mb-3">
                                                        <label class="control-label">State</label>
                                                        <select name="state" id="state"
                                                            [(ngModel)]="paymentProfile.billing_address.state"
                                                            class="form-control">
                                                            <option value="AK">Alaska</option>
                                                            <option value="AL">Alabama</option>
                                                            <option value="AR">Arkansas</option>
                                                            <option value="AZ">Arizona</option>
                                                            <option value="CA">California</option>
                                                            <option value="CO">Colorado</option>
                                                            <option value="CT">Connecticut</option>
                                                            <option value="DE">Delaware</option>
                                                            <option value="FL">Florida</option>
                                                            <option value="GA">Georgia</option>
                                                            <option value="HI">Hawaii</option>
                                                            <option value="IA">Iowa</option>
                                                            <option value="ID">Idaho</option>
                                                            <option value="IL">Illinois</option>
                                                            <option value="IN">Indiana</option>
                                                            <option value="KS">Kansas</option>
                                                            <option value="KY">Kentucky</option>
                                                            <option value="LA">Louisiana</option>
                                                            <option value="MA">Massachusetts</option>
                                                            <option value="MD">Maryland</option>
                                                            <option value="ME">Maine</option>
                                                            <option value="MI">Michigan</option>
                                                            <option value="MN">Minnesota</option>
                                                            <option value="MO">Missouri</option>
                                                            <option value="MS">Mississippi</option>
                                                            <option value="MT">Montana</option>
                                                            <option value="NC">North-carolina</option>
                                                            <option value="ND">North-dakota</option>
                                                            <option value="NE">Nebraska</option>
                                                            <option value="NH">New-hampshire</option>
                                                            <option value="NJ">New-jersey</option>
                                                            <option value="NM">New-mexico</option>
                                                            <option value="NV">Nevada</option>
                                                            <option value="NY">New-york</option>
                                                            <option value="OH">Ohio</option>
                                                            <option value="OK">Oklahoma</option>
                                                            <option value="OR">Oregon</option>
                                                            <option value="PA">Pennsylvania</option>
                                                            <option value="RI">Rhode-island</option>
                                                            <option value="SC">South-carolina</option>
                                                            <option value="SD">South-dakota</option>
                                                            <option value="TN">Tennessee</option>
                                                            <option value="TX">Texas</option>
                                                            <option value="UT">Utah</option>
                                                            <option value="VA">Virginia</option>
                                                            <option value="VT">Vermont</option>
                                                            <option value="WA">Washington</option>
                                                            <option value="NM">Wisconsin</option>
                                                            <option value="WV">West-virginia</option>
                                                            <option value="WY">Wyoming</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-12 col-sm-8 form-group mb-3">
                                                        <label for="city">City</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.billing_address.city" id="city"
                                                            placeholder="city" name="city">
                                                    </div>

                                                    <div class="col-12 col-sm-4 form-group mb-3">
                                                        <label for="zipCode">Zip Code</label>
                                                        <input type="text" class="form-control" id="zip_code"
                                                            [(ngModel)]="paymentProfile.billing_address.zip_code"
                                                            inputmode="numeric" pattern="[0-9]*"
                                                            name="zip_code" placeholder="zip code">
                                                    </div>

                                                </div>
                                            </form>
                                        </div>

                                    </section>
                                    <!-- Fin de seccion de pago con tarjeta de credito -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button *ngIf="payment.vouchers.length == 0 && paymentMethod.method !== 'CREDIT_CARD'" (click)="confirmSavePayment()" class="btn btn-save">
                    Confirm Payment
                </button>
                <button *ngIf="payment.vouchers.length > 0 || (paymentMethod.method == 'CREDIT_CARD' && cardValidated)" (click)="savePayment()" class="btn btn-save">
                    Confirm Payment
                </button>
                <button *ngIf="paymentGatewaySetting.name === 'SQUARE' && paymentMethod.method == 'CREDIT_CARD' && !cardValidated" (click)="validateCard()" class="btn btn-danger">
                    Validate Card
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Cange Charge Amount Due-->
<div class="modal fade" id="changeAmountDueModal" #changeAmountDueModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Change Amount Due
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-6">

                                <div class="form-group">
                                    <label class="control-label">Amount</label>
                                    <input type="text" onlyNumbers [(ngModel)]="changedAmount" name="wd_amount"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveAlteredAmountDue()" class="btn btn-save">
                    Change
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Cange Charge Amount Due-->
<div class="modal fade" id="changeCompaniesChecksBalancesModal" #changeCompaniesChecksBalancesModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Change Companies Checks & Balances
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-6">

                                <div class="form-group">
                                    <select
                                        [(ngModel)]="estimateBrokerId" name="estimateBrokerId"
                                        class="form-control">
                                        <ng-container *ngFor="let rowBroker of brokers" >
                                            <option  *ngIf="rowBroker.broker" [value]="rowBroker.id">{{rowBroker.name}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Broker Pay</label>
                                    <input type="text" onlyNumbers [(ngModel)]="brokerPay" name="brokerPay"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveBrokerBalances()" class="btn btn-save">
                    Change
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Cange Charge Amount Due-->
<div class="modal fade" id="paidToModal" #paidToModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Paid To
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="balance_target">Paid To:</label>
                                    <select name="balance_target" [(ngModel)]="balance.paid_to" class="form-control">
                                        <option value="AGENT">Agent</option>
                                        <option value="IN_HOUSE">In House</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="control-label">Allocated to Storage Payments:</label>
                                    <input type="number" onlyNumbers [(ngModel)]="balance.allocated_to_payments_storage"
                                        name="wd_amount" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" (click)="savePayTo()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal Add Partial Payment -->
<div class="modal fade" id="partialPaymentModal" #partialPaymentModal tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="width: 860px; max-width: 1000px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Partial Payment
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label for="exampleFormControlSelect1">Amount <span class="text-red">(up to {{upToBalance | currency}})</span></label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                                        </div>
                                        <input type="number" [(ngModel)]="payment.amount" name="wd_amount" class="form-control" [disabled]="isConfirm">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Amount.</small>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group mb-4">
                                    <label for="inputState">Payment Date</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg></span>
                                        </div>
                                        <app-date-picker [value]="payment.date" [enableTime]="false" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDate($event)" style="width: 84%;"></app-date-picker>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Select Payment Date.</small>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-4">
                                    <label for="balance_target">Remarks</label>
                                    <textarea class="form-control" name="wd_concept" rows="5" [(ngModel)]="payment.remarks"></textarea>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter your remarks.</small>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mb-4">
                                    <label class="control-label">Payment Method Used</label>
                                    <select name="operator_dataForm.amount" class="form-control"
                                        [(ngModel)]="payment.method_id" (ngModelChange)="selectPaymentMethod($event)">
                                        <option *ngFor="let methodsPayment of methodsConfirmPayments" value="{{methodsPayment.id}}">
                                            {{methodsPayment.name | filterReplace}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="(paymentMethod.method == 'BANK_TRANSFER_OFFLINE' || paymentMethod.method == 'CHECK' || paymentMethod.method == 'MONEY_ORDER' || paymentMethod.method == 'CASH')">
                            <div class="col-12 col-xl-12 col-lg-12 upload-file">
                                <app-file-upload [multiple]="true" (onChange)="uploadDocument($event, 'digital_signs', 'PARTIAL_PAID')">
                                </app-file-upload>
                            </div>
                        </div>

                        <div class="row mt-4 upload-file-transfer">
                            <ng-container *ngFor="let previewImg of payment.vouchers">
                                <div class="file">
                                    <div class="delete-button-file" (click)="deleteImageInventoryItem(previewImg)">
                                        x
                                    </div>
                                    <img height="50px" *ngIf="previewImg.display_name" src="{{urlBase}}/tmp/{{previewImg.display_name}}"/>
                                </div>
                            </ng-container>
                        </div>

                        <div class="row" [hidden]="paymentMethod.method !== 'CREDIT_CARD'">
                            <div class="col-12 col-xl-12 col-lg-12">
                                <div class="widget-content widget-content-area container-form-credit-card">
                                        <!-- <h3>Credit Card Information</h3> -->
                                    <section class="row">
                                        <div class="col-12">
                                            <form>
                                                <div class="row form-credit-card form-padd">
                                                    <div class="col-12 form-group mb-3">
                                                        <label for="name">Name on Card</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.credit_card.holder_name"
                                                            id="name_credit_card" placeholder="Name"
                                                            name="name_credit_card">
                                                    </div>

                                                    <div class="method-square" [hidden]="paymentGatewaySetting.name !== 'SQUARE'">
                                                        <div class="col-12 form-group mb-3">
                                                            <form id="payment-form">
                                                                <div id="card-container"></div>
                                                                <button id="card-button" type="button" style="display: none;">Pay $1.00</button>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 form-group mb-3" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for="cardNumber">Card Number (no spaces or dashes)</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.credit_card.number" id="cardNumber"
                                                            inputmode="numeric"
                                                            placeholder="0000 0000 0000 0000" maxlength="19"
                                                            name="cardNumber">
                                                    </div>
                                                    <div class="col-4 form-group mb-3 pr-3" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for="">Expiration</label>
                                                        <input type="text" class="form-control" id="expiration_date"
                                                            inputmode="numeric"
                                                            placeholder="MM" maxlength="2" name="expiration_date"
                                                            [(ngModel)]="paymentProfile.credit_card.expiration_date.month">
                                                    </div>
                                                    <div class="col-4 form-group mb-3 pl-3 expiration-date-yy" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for=""></label>
                                                        <input type="text" class="form-control" id="expiration_date_yy"
                                                            inputmode="numeric"
                                                            placeholder="YY" maxlength="2" name="expiration_date_yy"
                                                            [(ngModel)]="paymentProfile.credit_card.expiration_date.year">
                                                    </div>

                                                    <div class="col-4 form-group mb-3" [hidden]="paymentGatewaySetting.name === 'SQUARE'">
                                                        <label for="securitycode">CVV</label>
                                                        <input type="text" class="form-control" pattern="[0-9]*"
                                                            inputmode="numeric"
                                                            [(ngModel)]="paymentProfile.credit_card.security_code"
                                                            id="securitycode" placeholder="123" maxlength="4"
                                                            name="securitycode">
                                                    </div>

                                                    <div class="col-12 form-group m-0">
                                                        <hr class="separator-credit-card">
                                                    </div>

                                                    <div class="col-12 col-sm-8 form-group mb-3">
                                                        <label for="">Street</label>
                                                        <input type="text"
                                                            [(ngModel)]="paymentProfile.billing_address.street"
                                                            class="form-control" id="address" placeholder="address"
                                                            name="address">
                                                    </div>

                                                    <div class="col-12 col-sm-4 form-group mb-3">
                                                        <label class="control-label">State</label>
                                                        <select name="state" id="state"
                                                            [(ngModel)]="paymentProfile.billing_address.state"
                                                            class="form-control">
                                                            <option value="AK">Alaska</option>
                                                            <option value="AL">Alabama</option>
                                                            <option value="AR">Arkansas</option>
                                                            <option value="AZ">Arizona</option>
                                                            <option value="CA">California</option>
                                                            <option value="CO">Colorado</option>
                                                            <option value="CT">Connecticut</option>
                                                            <option value="DE">Delaware</option>
                                                            <option value="FL">Florida</option>
                                                            <option value="GA">Georgia</option>
                                                            <option value="HI">Hawaii</option>
                                                            <option value="IA">Iowa</option>
                                                            <option value="ID">Idaho</option>
                                                            <option value="IL">Illinois</option>
                                                            <option value="IN">Indiana</option>
                                                            <option value="KS">Kansas</option>
                                                            <option value="KY">Kentucky</option>
                                                            <option value="LA">Louisiana</option>
                                                            <option value="MA">Massachusetts</option>
                                                            <option value="MD">Maryland</option>
                                                            <option value="ME">Maine</option>
                                                            <option value="MI">Michigan</option>
                                                            <option value="MN">Minnesota</option>
                                                            <option value="MO">Missouri</option>
                                                            <option value="MS">Mississippi</option>
                                                            <option value="MT">Montana</option>
                                                            <option value="NC">North-carolina</option>
                                                            <option value="ND">North-dakota</option>
                                                            <option value="NE">Nebraska</option>
                                                            <option value="NH">New-hampshire</option>
                                                            <option value="NJ">New-jersey</option>
                                                            <option value="NM">New-mexico</option>
                                                            <option value="NV">Nevada</option>
                                                            <option value="NY">New-york</option>
                                                            <option value="OH">Ohio</option>
                                                            <option value="OK">Oklahoma</option>
                                                            <option value="OR">Oregon</option>
                                                            <option value="PA">Pennsylvania</option>
                                                            <option value="RI">Rhode-island</option>
                                                            <option value="SC">South-carolina</option>
                                                            <option value="SD">South-dakota</option>
                                                            <option value="TN">Tennessee</option>
                                                            <option value="TX">Texas</option>
                                                            <option value="UT">Utah</option>
                                                            <option value="VA">Virginia</option>
                                                            <option value="VT">Vermont</option>
                                                            <option value="WA">Washington</option>
                                                            <option value="NM">Wisconsin</option>
                                                            <option value="WV">West-virginia</option>
                                                            <option value="WY">Wyoming</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-12 col-sm-8 form-group mb-3">
                                                        <label for="city">City</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="paymentProfile.billing_address.city" id="city"
                                                            placeholder="city" name="city">
                                                    </div>

                                                    <div class="col-12 col-sm-4 form-group mb-3">
                                                        <label for="zipCode">Zip Code</label>
                                                        <input type="text" class="form-control" id="zip_code"
                                                            [(ngModel)]="paymentProfile.billing_address.zip_code"
                                                            inputmode="numeric" pattern="[0-9]*"
                                                            name="zip_code" placeholder="zip code">
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="savePartialPayment()" data-dismiss="modal" class="btn btn-save">
                    Save Partial Payment
                </button>
            </div>
        </div>
    </div>
</div>
