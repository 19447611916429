<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

            <div class="row">

                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                    <h3>
                        <span *ngIf="payrollBalance.id == null">
                            New
                        </span>
                        <span *ngIf="payrollBalance.id != null">
                            Update
                        </span> Payroll Balances
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>Dashboard</a></li>
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/payroll-balances">Payroll Balances</a></li>
                            <li *ngIf="payrollBalance.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                            <li *ngIf="payrollBalance.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button class="btn btn-main-action ml-1 mt-3" type="submit">
                            Save
                        </button>
                    </div>
                </div>

            </div>
            <!-- header -->

            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">
                    <div class="row">
                        <div class="col-6">

                            <div class="form-group">
                                <label class="control-label">Type</label>
                                <select [(ngModel)]="payrollBalance.type" name="workDepartment_name"
                                    class="form-control" required>
                                    <option value="BONUS">
                                        Bonus
                                    </option>
                                    <option value="DEDUCTION">
                                        Deduction
                                    </option>
                                </select>
                                <small class="form-text text-muted mb-4">Select type of commission</small>
                            </div>

                            <div class="form-group">
                                <label>Employee</label>
                                <br>
                                <select name="operator_user_id" [(ngModel)]="payrollBalance.employee_id"
                                    id="employee_id" class="form-control">
                                    <option value="">All</option>
                                    <ng-container *ngFor="let user of employees">
                                        <option value="{{user.id}}">
                                            {{user.name | titlecase}}
                                        </option>
                                    </ng-container>
                                </select>
                                <small id="nameCustomer" class="form-text text-muted mb-4">Select an Employee.</small>
                            </div>                                                    

                        </div>
                        <div class="col-6">

                            <div class="form-group mb-1">
                                <label for="amount">Amount</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                            </svg></span>
                                    </div>
                                    <input type="number" class="form-control" name="amount"
                                        placeholder="Enter here the amount" [(ngModel)]="payrollBalance.amount">
                                </div>
                                <small id="nameCustomer" class="form-text text-muted mb-4">Enter the amount of the voucher or deduction here.</small>
                            </div>
                        
                            <div class="form-group mb-1">
                                <label>Remarks</label>
                                <div class="input-group mb-1">
                                <textarea [(ngModel)]="payrollBalance.remarks" name="remarks" class="form-control"
                                    cols="30" rows="5">
                                </textarea>
                                </div>
                                <small id="nameCustomer" class="form-text text-muted mb-1"> Write your bonus or deduction remark.</small>
                            </div>   

                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>
</div>