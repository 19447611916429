import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CounterService {


  callTimeS = 0;
  callTimeM = 0;
  intervalCallTime: any;

  private tiempoSubject = new BehaviorSubject<{ minutes: number, seconds: number }>({ minutes: 0, seconds: 0 });
  // Start the counter

  startCounter() {

    this.intervalCallTime = setInterval(() => {
      this.callTimeS++;
      if (this.callTimeS === 60) {
        this.callTimeS = 0;
        this.callTimeM++;
      }
      this.tiempoSubject.next({ minutes: this.callTimeM, seconds: this.callTimeS });
    }, 1000);
  }

  stopCounter(){
    clearInterval(this.intervalCallTime);
    this.callTimeS = 0;
    this.callTimeM = 0;
  }

  // Get the current time
  getTime(): Observable<{ minutes: number, seconds: number }> {
    return this.tiempoSubject.asObservable();
  }

}
