
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Employee } from 'src/app/entities/workspace/employee';
import { HttpCancelService } from 'src/app/http-cancel.service';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy{

    public dateToday: number;
    public employee: Employee;
    public jobPosition: string;
    public jobPositionName: string;

    constructor(
        public authService: AuthService,
        private htttpCancelService: HttpCancelService
    ) {
        this.dateToday = Date.now();
        this.employee = null;
        this.jobPosition = null;
        this.jobPositionName = null;
    }

    ngOnInit(): void {
        
        let workspace = this.authService.getWorkspaceSession();
        this.jobPosition = workspace.employee.job_position;
        this.jobPositionName = workspace.employee_company_permissions.role.name;
    }

    ngAfterContentInit(){
        this.employee = this.authService.workspaceSession.employee;
    }

    ngOnDestroy() {
        this.htttpCancelService.cancelPendingRequest();
    }

}
