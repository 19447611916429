import { Directive, Input, ElementRef, EventEmitter, Output } from '@angular/core';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare var jQuery;

@Directive({
    selector: '[appDropify]'
})
export class DropifyDirective {

    @Input()
    appDropify: string;

    @Output()
    onChange: EventEmitter<Array<String>>;

    private dropify;

    constructor(
        private element: ElementRef,
        private uploadFilesService: UploadFilesService) {
        this.dropify = null;
        this.onChange = new EventEmitter();
    }

    ngAfterViewInit() {

        setTimeout(() => {
            jQuery(this.element.nativeElement).dropify({
                messages: {
                    'default': 'Click to Upload or Drag & Drop',
                    'remove': '<i class="flaticon-close-fill"></i>',
                    'replace': 'Upload or Drag & Drop'
                }
            });
        },1000);

        this.element.nativeElement.addEventListener('change', (evt) => {
            var files = evt.target.files; // FileList object
            this.uploadFilesService.send(files)
                .then((response) => {
                    this.onChange.emit(response['files']);
                })
                .catch((error) => {
                    console.error("error: ", error);
                });
        }, false);

    }

}
