<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Storage Manager
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Storage Manager
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div>
    </div>
    <!-- header -->

    <!-- Filter -->
    <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12 align-self-center">
            
            <div class="row block-filter">

                <div class="col-lg-3 select-date-range-bg-w">
                    <label class="control-label">FADD</label>                        
                    <app-date-range-picker [placeholder]="'Not Assigned'" (onChange)="datesFADD($event)"></app-date-range-picker>                   
                </div>

                <div class="col-lg-3">
                    <label class="control-label">Volume</label>
                    <select name="volume" id="volume" [(ngModel)]="dataFilter.volume" class="form-control">
                        <option value="ALL" selected="selected">All</option>
                        <option value="SET_RANGE">Set Range</option>
                    </select>
                </div>

                <div class="col-lg-3" *ngIf="dataFilter.volume == 'SET_RANGE'">
                    <label class="control-label">Start Range</label>
                    <input class="form-control" type="number" name="start_range" placeholder="Enter a Value" [(ngModel)]="dataFilter.start_range">                    
                </div>

                <div class="col-lg-3" *ngIf="dataFilter.volume == 'SET_RANGE'">
                    <label class="control-label">End Range</label>
                    <input class="form-control" type="number" name="end_range" placeholder="Enter a Value" [(ngModel)]="dataFilter.end_range">                    
                </div>    
                
                <div class="col-lg-3">
                    <label class="control-label">Due</label>
                    <select name="due" id="due" [(ngModel)]="dataFilter.due" class="form-control">
                        <option value="ALL">All</option>
                        <option value="PASSED_DUE">Passed Due</option>
                        <option value="BETWEEN_DATES">Between Dates</option>                        
                    </select>
                </div>

                <div class="col-lg-3 select-date-range-bg-w" *ngIf="dataFilter.due == 'BETWEEN_DATES'">
                    <label class="control-label">Due Between Dates</label>
                    <app-date-range-picker [placeholder]="'Not Assigned'" (onChange)="datesDue($event)"></app-date-range-picker>
                </div>
                
                <div class="col-lg-3">
                    <label class="control-label">Warehouse</label>
                    <select name="warehouse_id" id="warehouse_id"  class="form-control" [(ngModel)]="dataFilter.warehouse_id"  (change)="resetStorageCompany()">
                        <option value="">All</option>
                        <option *ngFor="let rowWarehous of rowWarehouse" value="{{rowWarehous.id}}">
                            {{rowWarehous.name}}
                        </option>
                    </select>
                </div>

                <!-- workspace.employee.job_position -->
                <div class="col-md-3">
                    <label class="control-label">Storage Company</label>
                    <select name="storage_company_id" class="form-control" [(ngModel)]="dataFilter.storage_company_id" (change)="resetWarehouse()">
                        <option value="">All</option>
                        <option *ngFor="let storageCompanies of rowStorageCompanies" value="{{storageCompanies.id}}">
                            {{storageCompanies.name}}
                        </option>
                    </select>
                </div>
                <div class="col-md-3">
                    <label class="control-label">Storage Type</label>
                    <select name="storage_type" class="form-control" [(ngModel)]="dataFilter.storage_type" (change)="resetWarehouse()">
                        <option value="ALL">All</option>
                        <option value="INTERNAL">Internal</option>
                        <option value="EXTERNAL">External</option>
                    </select>
                </div>
                <div class="col-md-3" [ngClass]="{'volume-set': dataFilter.volume == 'SET_RANGE', 'due-set': dataFilter.due == 'BETWEEN_DATES'}"> 
                    <button class="btn btn-secondary btn-filter" (click)="search()">
                        Search
                    </button>
                </div>

            </div>

        </div>

       
    </div>

    <!-- Table -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Job #</th>
                                <th>Date IN</th>
                                <th>Volume</th>
                                <th>FADD</th>
                                <th>Invoice Status</th>
                                <th>Next Bill Date</th>
                                <th>Storage Type</th>
                                <th>Location</th>
                                <th>Ext Storage</th>
                                <th>Operation Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let manager of storageManager | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td (click)="openJob(manager.company_id, manager.job_id)" style="cursor:pointer;">{{manager.job_code}}</td>
                                <td>{{manager.date_in | dateTime}}</td>
                                <td>{{manager.total_cf}} 
                                    <ng-container *ngIf="manager.total_cf">
                                        CF
                                    </ng-container>
                                </td>
                                <td>{{manager.date_out | date}}</td>
                                
                                <ng-container *ngIf="manager.storage_invoice">
                                    <td>
                                        <!-- <ng-container *ngIf="manager.passed_due">
                                            Due
                                        </ng-container>
                                        <ng-container *ngIf="!manager.passed_due">
                                            Current
                                        </ng-container> -->
                                        {{manager.bill_status_text | filterReplace}}
                                    </td>
                                    <td>{{manager.next_bill_date | date}}</td>
                                </ng-container>
                                <ng-container *ngIf="!manager.storage_invoice">
                                    <td>
                                        N/A
                                    </td>
                                    <td>
                                        N/A
                                    </td>
                                </ng-container>
                                <td>
                                    <span>{{ manager.type | filterReplace }}</span>
                                </td>
                                <td>
                                    <ng-container *ngIf="manager.type == 'INTERNAL'">
                                        {{manager.warehouse_address}} <br>
                                        <ng-container *ngFor="let jobStorageInternal of manager.job_storage_internal; index as key">
                                            {{jobStorageInternal.location_in_storage}} 
                                            <ng-container *ngIf="key < manager.job_storage_internal.length - 1 ">
                                                ,
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="manager.type == 'EXTERNAL'">
                                        {{manager.storage_company_address}} <br>
                                        <ng-container *ngFor="let jobStorageExternal of manager.job_storage_external; index as key">
                                            {{jobStorageExternal.entrance_code}} 
                                            <ng-container *ngIf="key < manager.job_storage_internal.length - 1 ">
                                                ,
                                            </ng-container> 
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    <!-- Paid o N/A pendiente de ver esta info-->

                                </td>
                                <td>
                                    {{manager.job_status}}
                                </td>
                                
                                <td>
                                    <button class="btn btn-info" (click)="openJobStorage(manager.company_id, manager.job_id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                    <button class="btn btn-secondary"  appCanUserWorkspace="DRIVER_INVOICE_MANAGER"
                                        *ngIf="manager.storage_invoice"
                                        (click)="sendEmailJobStorage(manager.company_id, manager.job_id)">
                                        <i class="fa-solid fa-paper-plane mr-2"></i>
                                        Send Invoice
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->
</div>