import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlackoutMoveDate } from 'src/app/entities/workspace/blackout-move-date';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BlackoutMoveDatesService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<BlackoutMoveDate>> {
        return this.http.get<Array<BlackoutMoveDate>>(environment.api.workspace + '/blackout-move-dates').toPromise();
    }
    getById(id: string) {
        return this.http.get<BlackoutMoveDate>(environment.api.workspace + '/blackout-move-dates/' + id).toPromise();
    }

    edit(dataForm: any) {
        return this.http.put(environment.api.workspace + '/blackout-move-dates/' + dataForm.id, dataForm).toPromise();
    }
    add(dataForm: any) {
        return this.http.post(environment.api.workspace + '/blackout-move-dates', dataForm).toPromise();
    }

    save(dataForm: any): Promise<any> {

        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/blackout-move-dates/' + id).toPromise();
    }

}
