<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="formData.id == null">
                    New
                </span>
                <span *ngIf="formData.id != null">
                    Update
                </span>
                Roles
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/roles">
                            Roles
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <span *ngIf="formData.id == null">
                            Add
                        </span>
                        <span *ngIf="formData.id != null">
                            Edit
                        </span>
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="widget-content widget-content-area">
        <form class="form" #rolesForm="ngForm" (ngSubmit)="save(rolesForm)" novalidate>

            <div class="form-group mb-4">
                <label for="rol_name">
                    Role Name
                </label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                        </span>
                    </div>
                    <input type="text" class="form-control" name="rol_name" placeholder="Enter here the name" [(ngModel)]="formData.name" #roleName="ngModel" required>
                    <ng-container *ngIf="roleName.control.errors != null && (rolesForm.submitted || roleName.control.touched )">
                        <div class="invalid-feedback"  *ngIf="roleName.control.errors.required">
                            It is mandatory to provide a name for the role
                        </div>
                    </ng-container>
                </div>
                <small id="nameCustomer" class="form-text text-muted mb-4">Enter Role Name.</small>
            </div>

            <div class="form-group">
                <label for="role">
                    Scope
                </label>
                <div class="input-group mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-award">
                                <circle cx="12" cy="8" r="7"></circle>
                                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                            </svg>
                        </span>
                    </div>
                    <select name="rol_context" id="rol_context" class="form-control" [(ngModel)]="formData.scope" required #roleScope="ngModel">
                        <option value="WORKSPACE">Workspace</option>
                        <option value="COMPANY">Company</option>
                    </select>
                    <ng-container *ngIf="roleScope.control.errors != null && (rolesForm.submitted || roleScope.control.touched )">
                        <div class="invalid-feedback"  *ngIf="roleScope.control.errors.required">
                            You must select the context in which to work role
                        </div>
                    </ng-container>
                </div>
                <small id="nameCustomer" class="form-text text-muted mb-4">Select the Role Scope.</small>
            </div>

            <hr>
            <br>

            <h3 class="text-center" *ngIf="formData.scope == 'WORKSPACE'">
                Workspace Capabilities
            </h3>
            <h3 class="text-center" *ngIf="formData.scope == 'COMPANY'">
                Company Capabilities
            </h3>        
            
            <br>

            <app-selectable-capacities-list
                name="caps"
                [scope]="formData.scope"
                [(ngModel)]="formData.capabilities"
                >
            </app-selectable-capacities-list>

            <div class="text-center">
                <a appWorkspaceRouterLink="/roles" class="btn btn-ligth">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-left"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                    &nbsp; Cancel
                </a>
                &nbsp;
                <button class="btn btn-save" type="submit" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-save"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg>
                    &nbsp; Save Role
                </button> 
            </div>

        </form>
    </div>

</div>