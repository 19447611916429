import { Paginator } from 'src/app/entities/helpers/paginator';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { RecurringCustomerView } from 'src/app/entities/workspace/recurring-customer-view';
import { RecurringCustomersService } from 'src/app/services/companies/recurring-customers.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

declare var jQuery;
@Component({
  selector: 'app-recurring-customers',
  templateUrl: './recurring-customers.component.html',
  styleUrls: ['./recurring-customers.component.scss']
})
export class RecurringCustomersComponent implements OnInit {

  public recurringCustomers: Array<RecurringCustomerView>;

  /*
  Variable que indica la pagina actual en el paginator
  */
  public actualPage: number;
  public betweenDates: BetweenDates;
  // datos para paginador
  public paginator: Paginator;

  constructor(
    private authService: AuthService,
    private recurringCustomersService: RecurringCustomersService,
    private helperService: HelperService
  ) {
    this.actualPage = 1;
    this.paginator = new Paginator();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
    this.loadData();
  }

  loadData() {
    this.helperService.showLoadingMxpro360();
    this.recurringCustomersService
      .getAll(this.authService.workspaceSession.company.id, this.paginator)
      .then((response) => {
        this.paginator.paginator.total = response.paginator.total;
        this.recurringCustomers = response.data;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * recibe y asigna fechas para filtro
   * @param dataReturn
   */
  getFilterDates(dataReturn: BetweenDates) {
    this.betweenDates = dataReturn;
  }

  /**
   * Metodo que realiza los filtros de busqueda
   */
  search(): void {
    const data: any = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      company_id: this.authService.workspaceSession.company.id
    };

    this.paginator.paginator.start = this.betweenDates.dateStart;
    this.paginator.paginator.end = this.betweenDates.dateEnd;

    // Si no hay datos en el filtro, se realiza la busqueda de todos los datos
    if (data.start === 0 && data.end === 0) {
      this.loadData();
    } else {
      // Si hay datos de filtro de busqueda, se envia la consulta
      this.helperService.showLoadingMxpro360();
      this.recurringCustomersService
        .filterByDates(this.authService.workspaceSession.company.id, this.paginator)
        .then((response) => {
          this.recurringCustomers = response;
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page !== currentpage) {
        this.paginator.paginator.page = currentpage;
        this.loadData();
    }
}
}
