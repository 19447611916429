export class WorkDepartment {
    public id: string;
    public name: string;
    public manager_id: string;
    public employees_id: string[];
    public company_id: string;
    public created: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.manager_id = null;
        this.employees_id = [];
        this.company_id = null;
        this.created = null;
    }
}
