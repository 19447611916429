import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
declare const moment: any;

interface Period {
  start: number;
  end: number;
}

@Component({
  selector: 'app-periods-filter',
  templateUrl: './periods-filter.component.html',
  styleUrls: ['./periods-filter.component.scss']
})
export class PeriodsFilterComponent implements OnInit {

  @Output() onChange: EventEmitter<BetweenDates> = new EventEmitter();
  betweenFilter: BetweenDates = new BetweenDates();
  payPeriod: string = 'WEEKLY';
  payPeriodOptions = ['WEEKLY', 'MONTHLY', 'BI_WEEKLY', 'BI_MONTHLY'];
  payrollStartDay: any;
  periods: Period[] = [];
  selectedPeriod: Period;

  constructor(private generalSettingsService: GeneralSettingsService) {}

  ngOnInit(): void {
    this.loadCompanySettings();
  }

  private loadCompanySettings() {
    this.generalSettingsService.get()
      .then(response => {
        // moment(date).format('MMM D Y');
        // indica el primer dia del pay roll, puede ser un anio anterior
        this.payrollStartDay = response.settings.payroll_start_day;
        this.payPeriod = response.settings.pay_period;
        this.updateDates();
      })
      .catch(error => console.error('error', error))
      .finally(() => {});
  }

  updateDates() {
    this.periods = [];
    // obtiene la hora de inicio del dia que esta en base de datos ej: 2023-05-05 12:00:00
    const startPeriod = this.payrollStartDay
      ? moment(this.payrollStartDay).startOf('day')
      : moment().startOf('day');
    switch (this.payPeriod) {
      case 'WEEKLY':
        this.calculateWeeksAroundCurrent(startPeriod);
        break;
      case 'MONTHLY':
        this.calculateMonthsInYear(startPeriod);
        break;
      case 'BI_WEEKLY':
        this.calculateBiWeeklyPeriods(startPeriod);
        break;
      case 'BI_MONTHLY':
        this.calculateBiMonthlyPeriods(startPeriod);
        break;
      default:
        // Manejar caso no reconocido
        break;
    }
  }

  private calculateWeeksAroundCurrent(startPeriod) {
    // calcula fechas para hacer un select desde 8 semanas antes y hasta 8 semanas despues.
    const currentWeekStart = startPeriod.clone().startOf('week');
    const eightWeeksAgo = currentWeekStart.clone().subtract(8, 'weeks');
    const eightWeeksLater = moment().add(8, 'weeks');
  
    let currentWeek = eightWeeksAgo.clone();
  
    while (currentWeek.isSameOrBefore(eightWeeksLater)) {
      this.periods.push({
        start: currentWeek.clone().unix() * 1000,
        end: currentWeek.clone().endOf('week').unix() * 1000
      });
  
      currentWeek.add(1, 'week');
    }
  }

  private calculateMonthsInYear(startPeriod) {
    const firstDayOfYear = startPeriod.clone().startOf('year');
    const lastDayOfYear =  moment().endOf('year');

    let currentMonthStart = firstDayOfYear.clone().startOf('month');

    while (currentMonthStart.isBefore(lastDayOfYear)) {
      this.periods.push({
        start: currentMonthStart.unix() * 1000,
        end: currentMonthStart.clone().endOf('month').unix() * 1000
      });

      currentMonthStart.add(1, 'month');
    }
  }

  private calculateBiWeeklyPeriods(startPeriod) {
    const currentBiWeekStart = startPeriod.clone().startOf('week');
    const eightBiWeeksAgo = currentBiWeekStart.clone().subtract(16, 'weeks');
    const eightBiWeeksLater =  moment().add(16, 'weeks');
  
    let currentBiWeek = eightBiWeeksAgo.clone();
  
    while (currentBiWeek.isSameOrBefore(eightBiWeeksLater)) {
      this.periods.push({
        start: currentBiWeek.clone().unix() * 1000,
        end: currentBiWeek.clone().add(2, 'weeks').endOf('week').unix() * 1000
      });
  
      currentBiWeek.add(2, 'weeks');
    }
  }

  private calculateBiMonthlyPeriods(startPeriod) {
    const firstDayOfYear = startPeriod.clone().startOf('year');
    const lastDayOfYear =  moment().endOf('year');

    let currentMonthStart = firstDayOfYear.clone().startOf('month');

    while (currentMonthStart.isBefore(lastDayOfYear)) {

      this.periods.push({
        start: currentMonthStart.unix() * 1000,
        end: currentMonthStart.clone().startOf('month').add(14, 'days').endOf('day').unix() * 1000
      });
  
      this.periods.push({
        start: currentMonthStart.clone().startOf('month').add(15, 'days').unix() * 1000,
        end: currentMonthStart.clone().endOf('month').unix() * 1000
      });
  
      currentMonthStart.add(1, 'month');
    }
  }

  formatDate(date: number): string {
    return moment(date).format('MMM D');
  }

  formatFullDate(date: number): string {
    return moment(date).format('MMM D Y');
  }

  listenChangesSelectedPeriod() {
      this.betweenFilter.dateStart = this.selectedPeriod.start;
      this.betweenFilter.dateEnd = this.selectedPeriod.end;
      this.onChange.emit(this.betweenFilter);
  }
}
