<div class="row layout-spacing layout-top-spacing">
    <div class="col-lg-12">

        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
            
            <div class="row">

                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                    <h3>
                        <span *ngIf="dataForm.id == null">
                            New
                        </span>
                        <span *ngIf="dataForm.id != null">
                            Update
                        </span> Work Departments
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a></li>
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/work-departments">Work Departments</a></li>
                            <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                            <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button class="btn btn-main-action ml-1 mt-3" type="submit">
                            Save
                        </button>
                    </div>
                </div>

            </div>
            <!-- header -->

            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">                

                    <div class="row">
                        <div class="col-12">
                            
                            <div class="form-group mb-1">
                                <label class="control-label">Name Work Department</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control">
                                </div>

                                <small class="form-text text-muted mb-4">Enter Name Work Department</small>
                            </div>                           

                            <div class="form-group mb-1">
                                <label class="control-label">Manager</label>
                                    <select  [(ngModel)]="dataForm.manager_id" name="wd_employees_id" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                                    <option *ngFor="let employe of employees" value="{{employe.id}}">
                                        {{employe.name | titlecase}}
                                    </option>
                                </select>
                                <small class="form-text text-muted mb-4">Select Manager Work Department</small>
                            </div>

                            <div class="form-group mb-1">
                                <label class="control-label mb-4">Employees</label>
                                <div class="employee-block">
                                    <div class="n-chk" *ngFor="let employee of employees">
                                        <label class="new-control new-checkbox checkbox-success" *ngIf="employee.status == 'ACTIVE'">
                                        <input type="checkbox" class="new-control-input"
                                                (change)="getEmployeesTeam(employee.id)"
                                                [checked]="employee.checked">
                                        <span class="new-control-indicator"></span>
                                        {{employee.name | titlecase}}
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>              

                </div>
            </div>

        </form>

    </div>
</div>
