
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient) {

    }

    public getTotalCostEstimates(data) {
        return this.http.get<any>(environment.api.company + '/dashboard/jobs/estimates', { params: data }).toPromise();
    }

    public getTotalCostEstimatesBookeds(data) {
        return this.http.get<any>(environment.api.company + '/dashboard/jobs/bookeds', { params: data }).toPromise();
    }

    public getMovingBookedByCompany(data) {
        return this.http.get(environment.api.company + '/dashboard/jobs/booked-by-company/moving', { params: data }).toPromise();
    }

    public getAutoTransportBookedByCompany(data) {
        return this.http.get(environment.api.company + '/dashboard/jobs/booked-by-company/auto-transport', { params: data }).toPromise();
    }

    public getBadLeadByCompany(data) {
        return this.http.get(environment.api.company + '/dashboard/jobs/bad-lead-by-company', { params: data }).toPromise();
    }

    public getSalesCommissionsByCompany(data) {
        return this.http.get(environment.api.company + '/dashboard/jobs/sales-commissions-by-company', { params: data }).toPromise();
    }







    public getPriorityTikects() {
        return this.http.get(environment.api.company + '/dashboard/priority/tickets').toPromise();
    }

    public getHowManyJobsAndWhatFaddStatusAreTheyIn() {
        return this.http.get(environment.api.company + '/dashboard/global/how-many-jobs-and-what-fadd-status-are-they-in').toPromise();
    }

    public getPickedUpInTwoDays() {
        return this.http.get(environment.api.company + '/dashboard/global/picked-up-in-two-days').toPromise();
    }

    public getpickedUpTodays() {
        return this.http.get(environment.api.company + '/dashboard/global/picked-up-today').toPromise();
    }

    public getJobsInPickUpWindow() {
        return this.http.get(environment.api.company + '/dashboard/global/jobs-in-pick-up-window').toPromise();
    }

    public getPickedUpTomorrow() {
        return this.http.get(environment.api.company + '/dashboard/global/picked-up-tomorrow').toPromise();
    }

    public getLast5Payment() {
        return this.http.get(environment.api.company + '/dashboard/global/last-5-payments').toPromise();
    }

    public getRecurringCustomerTheySendEmailsToo() {
        return this.http.get(environment.api.company + '/dashboard/personal/recurring-customers-they-sent-emails-too').toPromise();
    }

    public getSalesLeader() {
        return this.http.get(environment.api.company + '/dashboard/global/sales-leader').toPromise();
    }

    public getLastTicket() {
        return this.http.get(environment.api.company + '/dashboard/jobs/last-ticket').toPromise();
    }

    public getTicketAssignedToThem() {
        return this.http.get(environment.api.company + '/dashboard/customer-services/ticket-assigned-to-them').toPromise();
    }

    public getDepositsLast5() {
        return this.http.get(environment.api.company + '/dashboard/salesman/last-5-deposits').toPromise();
    }

    public getRecurringLast10() {
        return this.http.get(environment.api.company + '/dashboard/salesman/last-10-recurring').toPromise();
    }

    public getHowManyNewEstimatesTheySentInTheLast7Days() {
        return this.http.get(environment.api.company + '/dashboard/salesman/how-many-new-estimates-they-sent-in-the-last-7-days').toPromise();
    }

    public getHowManyBookingsTheyGotInTheLast7Days() {
        return this.http.get(environment.api.company + '/dashboard/salesman/how-many-bookings-they-got-in-the-last-7-days').toPromise();
    }

    public getSeeAllSalesmanPerformanceOnHisTeam() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/see-all-salesman-performance-on-his-team').toPromise();
    }

    public getOwnPerformance() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/own-performance').toPromise();
    }

    public getAllRecurringForHisTeamThisWeek() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/all-recurring-for-his-team-this-week').toPromise();
    }

    public getTotalIncomingLeads() {
        return this.http.get(environment.api.company + '/dashboard/global/total-incoming-leads').toPromise();
    }

    public getTotalBadLeads() {
        return this.http.get(environment.api.company + '/dashboard/global/total-bad-leads').toPromise();
    }

    public getTotalEstimateBySales() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/total-estimated-by-salesman').toPromise();
    }

    public getPaymentMethodUsed() {
        return this.http.get(environment.api.company + '/dashboard/global/payment-method-used').toPromise();
    }

    public getTotalSalesInTheLast7Day() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/total-sales-in-the-last-7-days').toPromise();
    }


    public getJobsBooked() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/jobs-booked').toPromise();
    }

    public getJobsCompletedInTheLast7Days() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/jobs-completed-in-the-last-7-days').toPromise();
    }

    public getTotalEstimatesVsBookedInTheLast120Days(date) {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/total-estimates-vs-booked-in-the-last-120-days', { params: date }).toPromise();
    }

    public getTheLastTenTobsTheyViewedWithaLink() {
        return this.http.get(environment.api.company + '/dashboard/personal/the-last-10-jobs-they-viewed-with-a-link-to-see-all-jobs-they-viewed').toPromise();
    }

    public getTheLastTenTobsTheyViewedWithaLinkToSeeThem() {
        return this.http.get(environment.api.company + '/dashboard/global/the-last-10-jobs-they-viewed-with-a-link-to-see-them').toPromise();
    }

    public gethowManyEmailstheySentInTheLast7Days() {
        return this.http.get(environment.api.company + '/dashboard/salesman/how-many-emails-they-sent-in-the-last-7-days').toPromise();
    }

    public getThereLast5Tickets() {
        return this.http.get(environment.api.company + '/dashboard/salesman/there-last-5-tickets').toPromise();
    }

    public getClosingRatioPercentageInTheLast3Months() {
        return this.http.get(environment.api.company + '/dashboard/personal/estimate-ratio-percentage-per-lead-in-the-last-3-months').toPromise();
    }

    public getClosingRatioPerEstimate() {
        return this.http.get(environment.api.company + '/dashboard/personal/closing-ratio-percentage-per-estimate-in-the-last-3-months').toPromise();
    }

    public getClosingRatioPerLead() {
        return this.http.get(environment.api.company + '/dashboard/personal/closing-ratio-percentage-per-lead-in-the-last-3-months').toPromise();
    }

    public getMonthlyClosing() {
        return this.http.get(environment.api.company + '/dashboard/global/monthly-closing-ratio-change-the-last-6-months').toPromise();
    }

    public getOnlineEmployees() {
        return this.http.get(environment.api.company + '/dashboard/jobs/online-employees').toPromise();
    }

    public getTicketsThisTeamCreatedThisWeek(date) {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/tickets-his-team-created-this-week', { params: date }).toPromise();
    }

    public getSalesLeaderManager() {
        return this.http.get(environment.api.company + '/dashboard/sales-manager/sales-leader').toPromise();
    }

    public getCompanySalesLast6Month(date) {
        return this.http.get(environment.api.company + '/dashboard/admin/company-sales-last-6-month', { params: date }).toPromise();
    }

    public getLeadProvidersTimelines() {
        return this.http.get(environment.api.company + '/dashboard/global/timeline-of-lead-providers-in-the-last-15-days').toPromise();
    }
    
    public getTicketsAssigned() {
        return this.http.get(environment.api.company + '/dashboard/personal/tickets-assigned-to-them').toPromise();
    }
    
    public getLastTickets() {
        return this.http.get(environment.api.company + '/dashboard/personal/last-5-tickets').toPromise();
    }
    
    public getLastTicketsGlobal() {
        return this.http.get(environment.api.company + '/dashboard/global/last-5-tickets').toPromise();
    }
    
    public getTicketsByPriority() {
        return this.http.get(environment.api.company + '/dashboard/global/how-many-open-tickets-and-priority').toPromise();
    }
}
