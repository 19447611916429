import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TimeClock } from 'src/app/entities/companies/time-clock';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Employee } from 'src/app/entities/workspace/employee';
import { AuthService } from 'src/app/services/auth.service';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { environment } from 'src/environments/environment';

declare var jQuery;
declare const moment;
@Component({
  selector: 'app-payroll-time-clock',
  templateUrl: './payroll-time-clock.component.html',
  styleUrls: ['./payroll-time-clock.component.scss']
})
export class PayrollTimeClockComponent implements OnInit {

  @ViewChild('editClockTimeModal') editClockTimeModal: ElementRef;

  public listTimeClocks: TimeClock[];
  public employees: Employee[];
  public employee: Employee;
  public timeClock: TimeClock;
  public nameEmployee: string;
  // variable indicar donde se almacenará las fechas seleccionadas
  public betweenFilter: BetweenDates;

  constructor(
    private helperService: HelperService,
    private employeesService: EmployeesService,
    private payrollTimeClockService: PayrollTimeClockService,
    private authService: AuthService
  ) {
    this.listTimeClocks = [];
    this.employees = [];
    this.employee = new Employee();
    this.betweenFilter = new BetweenDates();
    this.timeClock = new TimeClock;
    this.nameEmployee = '';
  }

  ngOnInit(): void {
    this.loadEmployees();
  }

  ngAfterViewInit() {
    this.betweenFilter.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    this.betweenFilter.dateStart = moment().second(0).minute(0).hour(0).subtract(1, 'month').unix() * 1000;
    this.getAllTimeCocks();
  }

  private getAllTimeCocks() {

    const data = {
      date_end: this.betweenFilter.dateEnd,
      date_start: this.betweenFilter.dateStart,
      employee_id: this.employee.id
    };

    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService
      .getAllTimeCocks(data)
      .then((response) => {
        this.listTimeClocks = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadEmployees() {
    this.helperService.showLoadingMxpro360();
    this.employeesService
      .getAll()
      .then((response) => {
        this.employees = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  onRange(arrayDates: number[]) {
    // seteamos las fechas obtenidas del data picker
    if (arrayDates[1] > 0 && arrayDates[0] > 0) {
      this.betweenFilter.dateStart = moment(arrayDates[0]).second(0).minute(0).hour(0).unix() * 1000;
      this.betweenFilter.dateEnd = moment(arrayDates[1]).second(59).minute(59).hour(23).unix() * 1000;
    }
  }

  search() {
    this.getAllTimeCocks();
  }

  printReportClockTime(employeeId) {

    window.open(environment.api.companies + this.authService.workspaceSession.company.id + 
      '/time-clock/report-clock-time/export-pdf?Authorization=' + 
      this.authService.session.token + '&employee_id=' + employeeId + '&start=' + 
      this.betweenFilter.dateStart + '&end=' + this.betweenFilter.dateEnd + '&workspace_id=' + 
      this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id);
  }

  edit() {
   
    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService
      .editTime(this.timeClock)
      .then((response) => {
        this.getAllTimeCocks();
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public openEditClockTimeModal(timeClock, nameEmployee) {
    this.nameEmployee = nameEmployee;
    this.timeClock = timeClock;
    jQuery(this.editClockTimeModal.nativeElement).modal('show');
  }

  saveTimeClock(){
    
    const timeClockDate = this.timeClock.date.split("/");
    const date = timeClockDate[0].split("-");
    
    const timeIn = this.timeClock.time_in.split(":");
    const timeOut = this.timeClock.time_out.split(":");

    const clockIn = moment();
    const clockOut = moment();

    clockIn.set({
      year: date[0],     // Establece el año
      month: Number(date[1])-1,       // Establece el mes (0-11, donde 5 representa junio)
      date: date[2],       // Establece el día del mes
      hour: timeIn[0],       // Establece la hora
      minute: timeIn[1],     // Establece los minutos
    });

    clockOut.set({
      year: date[0],     // Establece el año
      month: Number(date[1])-1,       // Establece el mes (0-11, donde 5 representa junio)
      date: date[2],       // Establece el día del mes
      hour: timeOut[0],       // Establece la hora
      minute: timeOut[1],     // Establece los minutos
    });

    this.timeClock.clock_in = moment(clockIn, moment.HTML5_FMT.DATE).unix() * 1000;
    this.timeClock.clock_out = moment(clockOut, moment.HTML5_FMT.DATE).unix() * 1000;

    this.edit();
    
  }

}
