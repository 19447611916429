import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { Ticket } from 'src/app/entities/workspace/ticket';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { TicketsService } from 'src/app/services/companies/tickets.service';
import { ConstantsMessages } from '../../../constants-messages';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { TicketType } from 'src/app/entities/workspace/ticket-type';

declare const jQuery;
@Component({
    selector: 'app-estimates-tickets-form',
    templateUrl: './estimates-tickets-form.component.html',
    styleUrls: ['./estimates-tickets-form.component.scss']
})
export class EstimatesTicketsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: Ticket;
    ticketsTypes: TicketType[];

    constructor(
        private ticketsService: TicketsService,
        private router: Router,
        private currentRoute: ActivatedRoute,
        private authService: AuthService,
        private estimatesService: EstimatesService,
        public helperService: HelperService,
        private ticketsTypesService: TicketsTypesService
    ) {
        this.dataForm = new Ticket();
        this.dataForm.created_by_employee_id = this.authService.workspaceSession.employee.id;
        this.ticketsTypes = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.dataForm.estimate_id = params.id;
                this.loadEstimate(params.id);
            }
        });
        this.loadTickets();
    }

    /**
   * Metodo que carga los tickets para mostrarlos en el select
   */
    private loadTickets() {
        this.helperService.showLoadingMxpro360();
        this.ticketsTypesService
            .getAll()
            .then((response) => {
                console.log('response =>', response);    
                this.ticketsTypes = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {        
        this.helperService.showLoadingMxpro360();
        this.ticketsService
            .add(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToCompanyRouterLink('/moving/' + this.dataForm.estimate_id + '/tickets');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response: Estimate) => {
                this.dataForm.customer_id = response.customer_id;
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changePriority(priority){
        for (const ticketsType of this.ticketsTypes) {
            if(ticketsType.id == priority){
                this.dataForm.priority = ticketsType.priority;
            }
        }
    }


}
