import { Log } from './entities/global/log';
// import { Component, OnDestroy, OnInit} from '@angular/core';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { TariffVersionsService } from './services/workspaces/tariff-versions.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Invoice } from './entities/admin/invoice';
import { WorkspaceInvoicesService } from './services/accounts/workspace-invoices.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {


    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private tariffVersionsService: TariffVersionsService,
        private translate: TranslateService,
        public authServices: AuthService,
        public router: Router,
        public tariffVersionFactory: TariffVersionService,
        private workspaceInvoicesService: WorkspaceInvoicesService
    ) {
        this.checkMainParamsFromURL();
    }

    ngOnDestroy(): void {

    }

    ngOnInit(): void {
        setTimeout(()=> {
            if (this.router.url !== '/register-account-company') {
                if (!this.tariffVersionFactory.getTariffVersionActive()) {
                    this.tariffVersionsService.getAll().then(
                        (response) => {
                            for (const tariffVersion of response) {
                                if (tariffVersion.is_active) {
                                    this.tariffVersionFactory.setTariffVersionActive(tariffVersion);
                                    break;
                                } else {
                                    this.tariffVersionFactory.removeTariffVersionActive();
                                }
                            }
                        }
                    ).finally(() => {
                    });
                }
            }
        }, 1000);
    }


    ngAfterContentInit() {

        const apiGoogleSRC =`https://maps.googleapis.com/maps/api/js?key=${environment.google_api.key}&libraries=drawing,places`;
        const googleSRC =`https://apis.google.com/js/api.js?key=${environment.google_api.key}`;
        this.loadJsScript(this.renderer, apiGoogleSRC);
        this.loadJsScript(this.renderer, googleSRC);
        this.translate.setDefaultLang('en');
        this.translate.addLangs(['en', 'es']);
    }

    public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
        const script = renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        renderer.appendChild(this.document.body, script);
        return script;
    }

    /**
     * Permite verificar los parametros de la URL para cargalos en la sesion antes de que se cargue todo
     */
    private checkMainParamsFromURL(){

        const fullUrl = window.location.href.split('#');

        if (fullUrl.length >= 2) {
            const path = fullUrl[1].split('/');

            let workspace_id = null;
            let company_id = null;

            if (path.length >= 2) {
                if (path[1] === "workspace") {
                    workspace_id = path[2];
                }
            }

            if (path.length >= 4) {
                if (path[3] === "companyspace") {
                    company_id = path[4];
                }
            }

            // cargamos la base de la sesion
            this.loadBasicAuthInfo(workspace_id, company_id);

        }
    }

    /**
     * permite cargar en la sesion el workspace y la compañia
     * @param workspace_id
     * @param company_id
     */
    private loadBasicAuthInfo(workspace_id: string, company_id: string = null) {

      this.workspaceInvoicesService
          .getAllByPending(workspace_id)
          .then((unPaidInvoices: Array<Invoice>) => {

            if (unPaidInvoices.length >= 2 && this.authServices.hasJobPosition('ADMIN')) {
              this.router.navigateByUrl('/my-profile/workspaces/' + workspace_id + '/billing');
            } else if (unPaidInvoices.length >= 2 && !this.authServices.hasJobPosition('ADMIN')) {
              this.router.navigateByUrl('/workspaces/' + workspace_id + '/unauthorized-outstanding-invoices');
            }
          }, (error) => {
            console.log('error => ', error);
          });

        // cargamos la informacion de la session
        this.authServices.loadWorkspaceSession(workspace_id);

        // verificamos que se haya recibido el comopany tambien
        if(company_id !== null) {
            // cargamos la informacion de la compañia
            this.authServices.setCompanySessionByCompanyId(company_id);
        }
    }
}
