import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Company } from 'src/app/entities/workspace/company';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { ListItemSelection } from 'src/app/entities/helpers/list-item-selection';

declare const swal: any;

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit, AfterViewInit {

    actualPage: number;
    // agregamos el arreglo con una estructura de seleccion 
    // porque la misma estructura nos servira para indicar 
    // si el usuario logueado tiene acceso o no a esa compañia
    companies: Array<ListItemSelection<Company>>;
    maxCompaniesDone: boolean;

    constructor(
        public authService: AuthService,
        private companiesService: CompaniesService,
        private helperService: HelperService
    ) {
        this.maxCompaniesDone = false;
        this.companies = [];
        this.actualPage = 1;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.loadCompanies();
    }

    /**
     * Carga las companies, el arguemento enviado es para uqe desde el backend 
     * me devuelva las compañias a las que el empleado puede ingresar
     */
    private loadCompanies() {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll()
            .then((response: Array<Company>) => {
                // valida si ya se completo el maximo de companias
                this.maxCompaniesDone = this.authService.workspaceSession.workspace.max_companies !== null && response.length >= this.authService.workspaceSession.workspace.max_companies;
                // reseteamos la lista
                this.companies = [];

                // iteramos las compañias recibidas
                for (let company of response) {

                    // creamos una instancia de la estructura
                    const tmpSelection = new ListItemSelection<Company>();

                    // agregamos la compañia a la estructura
                    tmpSelection.data = company;

                    // indicamos si el usuario logueado tiene acceso a la compañia
                    tmpSelection.selected = this.authService.hasAccessToCompany(company.id);

                    // agregamos la compañia a la lista
                    this.companies.push(tmpSelection);
                }
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    showMaxCompaniesMessage() {
        swal({
            title: 'Max Companies',
            text: "Please contact your MoverXpro 360 representative to upgrade to additional Companies (Max Companies " + this.authService.workspaceSession.workspace.max_companies + ")",
            type: 'warning',
            confirmButtonText: 'OK'
        });
    }

    /**
     * Permite establecer la informacion de una compañia en el localstorage
     * @param compnay 
     */
    openModuleCompany(compnay: Company): void {
        this.authService.setCompanySession(compnay);
        this.helperService.goToWorkspaceRouterLink('/companyspace/' + compnay.id + '/dashboard');
    }

    /**
     * Permite deshabilitar una compañia
     * @param index 
     */
    async disableCompany(index: number): Promise<void> {

        const result = await swal({
            title: this.companies[index].data.name,
            text: "Do you want to disable the company?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        });

        if (!result.value) {
            return;
        }

        this.helperService.showLoadingMxpro360();

        this.companiesService
            .disabled(this.companies[index].data.id)
            .then((response: any) => {

                // eliminamos la compañia recibida
                this.companies.splice(index, 1);

                // mostramos el aviso que devuelve el backend
                this.helperService.showMessageSnackbar(response.message, "SUCESS");

            })
            .catch((httpError) => {

                console.error('Error', httpError);

                try {
                    swal({
                        type: 'error',
                        title: 'Oops...',
                        text: httpError.error.message
                    });
                } catch (error) {
                    
                }
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
}

