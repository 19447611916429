import { Component, OnInit } from '@angular/core';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { EmailTemplatesService } from 'src/app/services/companies/email-templates.service';
import { HelperService } from 'src/app/services/helper.service';


declare const jQuery;
declare const swal;

@Component({
    selector: 'app-email-templates',
    templateUrl: './email-templates.component.html',
    styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit {

    rows: Array<EmailTemplate>;

    constructor(
        private estimateEmailTemplatesService: EmailTemplatesService,
        public helperService: HelperService
    ) {
        this.rows = [];
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void{
        this.load();
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.estimateEmailTemplatesService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.estimateEmailTemplatesService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }
}
