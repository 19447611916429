<div class="layout-top-spacing">

    <form class="form" #employeesForm="ngForm" (ngSubmit)="save(employeesForm)" novalidate autocomplete="off">

        <!-- header -->
        <div class="row">
            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    Employee Details
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>

                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/employees">
                                Employees
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Employee Details
                        </li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button class="btn btn-save" type="submit" [disabled]="isSending" >
                        Save Employee
                    </button>
                </div>
            </div>

        </div><!-- END header -->

        <div class="row layout-spacing">

            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12">

                <div class="user-profile layout-spacing">
                    <div class="widget-content widget-content-area">
                        <div class="d-flex justify-content-between widget-header access-account-header">
                            <h3>
                                Access Account
                            </h3>
                            <div class="btn-action-groups">
                                <button class="btn-update-access-account" type="button" title="Update Information" *ngIf="account != null && (isWorkspacesAccount || isAccountLoggedIn)" (click)="openAccountFormModal()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-edit-3">
                                        <path d="M12 20h9"></path>
                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="text-center user-info" >
                            <p *ngIf="account != null">
                                {{account.name | titlecase}}
                            </p>

                            <p *ngIf="account == null">
                                Account Not Assigned
                            </p>
                            <br *ngIf="account == null">
                        </div>
                        <div class="user-info-list" *ngIf="account != null">
                            <div>
                                <ul class="contacts-block list-unstyled">

                                <!--
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-coffee">
                                            <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                                            <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                                            <line x1="6" y1="1" x2="6" y2="4"></line>
                                            <line x1="10" y1="1" x2="10" y2="4"></line>
                                            <line x1="14" y1="1" x2="14" y2="4"></line>
                                        </svg>
                                        Web Developer
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-calendar">
                                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                        Jan 20, 1989
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-map-pin">
                                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                            <circle cx="12" cy="10" r="3"></circle>
                                        </svg>
                                        New York, USA
                                    </li>
                                -->

                                    <li class="contacts-block__item active" *ngIf="account != null && !isWorkspacesAccount">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                                        <strong>
                                            Was not created in this workspace
                                        </strong>
                                    </li>

                                    <li class="contacts-block__item active" *ngIf="isAccountsOwner">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                        <strong>
                                            Workspace owner
                                        </strong>
                                    </li>

                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-mail">
                                            <path
                                                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                            </path>
                                            <polyline points="22,6 12,13 2,6"></polyline>
                                        </svg>
                                        {{account.email}}
                                    </li>
                                    <li class="contacts-block__item">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-phone">
                                            <path
                                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                            </path>
                                        </svg>
                                        {{account.phone | phone}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="descrip-account-info">
                            <p>
                                An <strong>account</strong> represents a user who logs into a workspace to operate as an employee. <br>
                            </p>
                            <p>
                                We have conceptually separated accounts and employees for the following reasons:
                            </p>
                            <p>
                                <strong>1)</strong> This allows us to charge per account and not per employee.
                            </p>
                            <p>
                                <strong>2)</strong> It allows users operating within a workspace to revoke access to an account and assign another account to the same employee whenever necessary.
                            </p>
                            <p>
                                <strong>3)</strong> Allows an account to have multiple employees assigned to it.
                            </p>
                            <p>
                                <strong>4)</strong> This allows one account to access multiple workspaces. Therefore, when adding an account, we check if it already exists in our system before adding it.
                            </p>
                            <p>
                                <strong>5)</strong> For security reasons, you can only modify accounts that were created within your own workspace.
                            </p>
                            <p>
                                <strong>6)</strong> It is worth mentioning that accounts that own the workspace cannot be deleted.
                            </p>
                            <p>
                                <strong>7)</strong> If you are the user of the account being displayed in this <strong>PANEL</strong>, you can edit the account right here regardless of whether you are the current workspace owner.
                            </p>
                        </div>
                        <div class="text-center">
                            <button class="mt-2 btn btn-danger" type="button" title="Delete Access" *ngIf="account != null && !isAccountsOwner" (click)="deleteAccessAccount()">
                                Delete Access Account
                            </button>
                            <button class="mt-2 btn btn-save" type="button" title="Assign Account" *ngIf="account == null" (click)="openAccountFormModal()">
                                Assign Access Account
                            </button>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                <div class="bio layout-spacing">
                    <div class="widget-content">

                            <!-- START SECTION EMPLOYEE INFORMATION -->
                            <section class="widget-content-area mb-36">

                                <div class="widget-header">
                                    <h3>
                                        Employee Information
                                    </h3>
                                </div>

                                <div class="row">
                                    <div class="col-6">

                                        <div class="form-group mb-1">
                                            <label class="control-label">Name</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" >
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-user">
                                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="12" cy="7" r="4"></circle>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text" name="first_name" class="form-control" required [(ngModel)]="formData.employee.name" #employeeName="ngModel">
                                                <ng-container *ngIf="employeeName.control.errors != null && (employeesForm.submitted || employeeName.control.touched )">
                                                    <div class="invalid-feedback"  *ngIf="employeeName.control.errors.required">
                                                        It is mandatory to indicate a name for the Employee
                                                    </div>
                                                </ng-container>
                                            </div>

                                            <small class="form-text text-muted mb-4">
                                                Write Employee Name.
                                            </small>
                                        </div>

                                    </div>
                                    <div class="col-6">

                                        <div class="form-group mb-1">
                                            <label class="control-label">Allowed IPs</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" >
                                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                                            class="css-i6dzq1">
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                            <line x1="2" y1="12" x2="22" y2="12"></line>
                                                            <path
                                                                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                            </path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text"  name="ips_allowed" class="form-control" [(ngModel)]="formData.employee.ips_allowed">
                                            </div>
                                            <small  class="form-text text-muted mb-4">Write Allowed IP Addresses.</small>
                                        </div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-6">

                                        <div  class="form-group mb-4">
                                            <label for="role" class="control-label">
                                                Job Position
                                            </label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span  class="input-group-text">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                                    </span>
                                                </div>
                                                <select name="employee_job_position" required class="form-control" [(ngModel)]="formData.employee.job_position" #employeeJP="ngModel">
                                                    <option [ngValue]="null" disabled> Select Option </option>
                                                    <option value="DRIVER"> Driver </option>
                                                    <option value="FOREMAN"> Foreman</option>
                                                    <option value="OPERATOR"> Operator </option>
                                                    <option value="SALES_MANAGER"> Sales Manager</option>
                                                    <option value="SALESMAN"> Salesman</option>
                                                    <option value="WORKER"> Worker</option>
                                                    <option value="DISPATCH_SERVICE"> Dispatch Service </option>
                                                    <option value="CUSTOMER_SERVICE"> Customer Service</option>
                                                    <option value="MANAGER"> Manager </option>
                                                    <option value="ADMIN"> Admin </option>
                                                </select>
                                                <ng-container *ngIf="employeeJP.control.errors != null && (employeesForm.submitted || employeeJP.control.touched )">
                                                    <div class="invalid-feedback"  *ngIf="employeeJP.control.errors.required">
                                                        It is mandatory to indicate the job position held by the employee
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <small  class="form-text text-muted mb-4">
                                                Select the Employee's Position.
                                            </small>
                                        </div>

                                    </div>
                                    <div class="col-6">

                                        <div  class="form-group mb-4">
                                            <label for="role" class="control-label">
                                                Status
                                            </label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span  class="input-group-text">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                                    </span>
                                                </div>
                                                <select name="employee_status" required class="form-control" [(ngModel)]="formData.employee.status" #employeeStatus="ngModel">
                                                    <option [ngValue]="null" disabled> Select Option </option>
                                                    <option value="ACTIVE"> Active </option>
                                                    <option value="DISABLED"> Disabled </option>
                                                </select>
                                                <ng-container *ngIf="employeeStatus.control.errors != null && (employeesForm.submitted || employeeStatus.control.touched )">
                                                    <div class="invalid-feedback"  *ngIf="employeeStatus.control.errors.required">
                                                        It is mandatory to indicate the status for the employee
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <small  class="form-text text-muted mb-4">
                                                If disabled, the employee will not be able to log in to the system, The employee will also not be available in the employee drop down menu to select it.
                                            </small>
                                        </div>

                                    </div>
                                </div>

                            </section>
                            <!-- end START SECTION EMPLOYEE INFORMATION -->

                            <!-- START SECTION WORSKPACE ROLE -->
                            <section class="widget-content-area mb-36">

                                <div class="widget-header">
                                    <h3>
                                        Workspace Capabilities
                                    </h3>
                                </div>

                                <div class="row">
                                    <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12">

                                        <div  class="form-group mb-5">
                                            <label for="role" class="control-label">
                                                Workspace Role
                                            </label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span  class="input-group-text">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                                    </span>
                                                </div>
                                                <select name="employee_workspace_role" class="form-control" [(ngModel)]="formData.employeeWorkspacePermissions.role" #employeeWorkspaceRole="ngModel" [compareWith]="compareRoles">
                                                    <option [ngValue]="null"> Do not provide Access </option>
                                                    <ng-container *ngFor="let role of roles">
                                                        <option [ngValue]="role" *ngIf="role.scope == 'WORKSPACE'"> {{role.name}} </option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <small  class="form-text text-muted mb-4">
                                                Select the role the employee will work with, if you need to customize the employee's Capabilities, click on the red button next to the selector.
                                            </small>
                                        </div>

                                    </div>
                                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 text-center">
                                        <button type="button" class="btn btn-new btn-assign-capabilities" *ngIf="formData.employeeWorkspacePermissions.role != null" (click)="openModalRoleFormData(formData.employeeWorkspacePermissions.role)">
                                            <i class="fa-regular fa-hand-point-down fa-flip-vertical"></i>
                                            &nbsp; Assign Manually
                                        </button>
                                    </div>
                                </div>

                                <app-selectable-capacities-list
                                    *ngIf="formData.employeeWorkspacePermissions.role != null"
                                    name="ws_caps"
                                    scope="WORKSPACE"
                                    [disabled]="true"
                                    [(ngModel)]="formData.employeeWorkspacePermissions.role.capabilities"
                                    >
                                </app-selectable-capacities-list>

                            </section>
                            <!-- end START SECTION WORSKPACE ROLE -->

                            <!-- START SECTION COMPANIES ROLE -->
                            <section class="widget-content-area mb-36">

                                <div class="widget-header">
                                    <h3>
                                        Companies Capabilities
                                    </h3>
                                </div>

                                <div class="row">
                                    <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                                        <p class="desc-companies-capabilities">
                                            In this section you can assign companies so that the employee can enter them, then in each company you can assign the capabilities or permissions that the employee will have in the company and finally you can set the data to send emails and receive calls.
                                        </p>
                                    </div>
                                    <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 text-center">
                                        <button class="mt-2 btn btn-new" type="button" title="Assign Company" (click)="openModalCompanySelection()" *ngIf="companySelectionAvailable.length > 0">
                                            <i class="fa-regular fa-building"></i>
                                            &nbsp;
                                            Assign Company
                                        </button>
                                    </div>
                                </div>

                                <br>
                                <br>

                                <div class="company-info-wrapper" *ngFor="let cr of formData.employeeCompanyPermissions; let i = index">

                                    <div class="form-wrapper">
                                        <div class="company-name">
                                            <h4>
                                                {{cr.company?.name}}
                                            </h4>
                                            <div class="company">Company</div>
                                        </div>

                                        <button class="btn btn-remove rounded-circle" type="button" title="Remove Company" (click)="removeCompany(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                        </button>

                                        <div class="row">
                                            <div class="col-6">
                                                <div  class="form-group">
                                                    <label class="control-label">
                                                        Email Provider
                                                    </label>
                                                    <div  class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline  points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path  d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>
                                                            </span>
                                                        </div>
                                                        <select name="employee_{{i}}_email_provider"  required class="form-control " [(ngModel)]="cr.email.provider" #employeeEmailProvider="ngModel">
                                                            <option [ngValue]="null" disabled> Select Option </option>
                                                            <option value="GMAIL"> GMAIL </option>
                                                            <option value="OFFICE365"> OFFICE365 </option>
                                                            <option value="GODADDY"> GODADDY </option>
                                                            <option value="YAHOO"> YAHOO </option>
                                                        </select>
                                                        <ng-container *ngIf="employeeEmailProvider.control.errors != null && (employeesForm.submitted || employeeEmailProvider.control.touched )">
                                                            <div class="invalid-feedback"  *ngIf="employeeEmailProvider.control.errors.required">
                                                                required field
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <small  class="form-text text-muted mb-4">
                                                        Select the mail provider.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div  class="form-group ">
                                                    <label  class="control-label">
                                                        Job Email
                                                    </label>
                                                    <div  class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                                    class="feather feather-mail">
                                                                    <path
                                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                                    </path>
                                                                    <polyline points="22,6 12,13 2,6"></polyline>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <input name="employee_{{i}}_job_email" type="tel" required email class="form-control " autocomplete="off" [(ngModel)]="cr.email.address" #employeeEmailAddress="ngModel">
                                                        <ng-container *ngIf="employeeEmailAddress.control.errors != null && (employeesForm.submitted || employeeEmailAddress.control.touched )">
                                                            <div class="invalid-feedback"  *ngIf="employeeEmailAddress.control.errors.required">
                                                                required field
                                                            </div>
                                                            <div class="invalid-feedback"  *ngIf="employeeEmailAddress.control.errors.email">
                                                                email address must have a valid format
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <small  class="form-text text-muted mb-4">
                                                        Enter the email address the employee will be working with.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label  class="control-label">
                                                        Email Department Access
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline  points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path  d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>
                                                            </span>
                                                        </div>
                                                        <select [(ngModel)]="cr.email_work_departments_id" 
                                                                multiple #employeeEmailWorkDepartment="ngModel"
                                                                name="workdepartments_{{i}}" 
                                                                class="form-control method select-methods">
                                                            <option *ngFor="let workDepartment of workDepartments[cr.company_id]" title="{{i}}" [value]="workDepartment.id">{{workDepartment.name}}</option>
                                                        </select>
                                                        <ng-container *ngIf="employeeEmailWorkDepartment.control.errors != null && (employeesForm.submitted || employeeEmailWorkDepartment.control.touched )">
                                                            <div class="invalid-feedback"  *ngIf="employeeEmailWorkDepartment.control.errors.required">
                                                                required field
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <small  class="form-text text-muted mb-4">
                                                        Select the mail provider.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <div  class="form-group ">
                                                    <label  class="control-label">
                                                        Email Password
                                                    </label>
                                                    <div  class="input-group ">
                                                        <div  class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg  viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path  d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                            </span>
                                                        </div>
                                                        <button id="" name="btn-show-password" type="button" class="btn_show_{{i}}_password btn-show-password" (click)="showPassword(i)"> <span class="fa fa-eye icon"></span> </button>
                                                        <input id="" name="input_email_password_{{i}}" type="password" class="form-control input_email_{{i}}_password" autocomplete="off" [(ngModel)]="cr.email.password" #employeeEmailPass="ngModel" required>
                                                    </div>
                                                    <small class="form-text text-muted mb-4">
                                                        Enter the password you use to log in to the email address previously entered.
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div  class="form-group ">
                                                    <label class="control-label">
                                                        Phone
                                                    </label>
                                                    <div class="input-group ">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path  d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                                            </span>
                                                        </div>
                                                        <input name="employee_{{i}}_job_phone" type="tel" required class="form-control " autocomplete="off" [(ngModel)]="cr.phone_number" #employeePhone="ngModel">
                                                        <ng-container *ngIf="employeePhone.control.errors != null && (employeesForm.submitted || employeePhone.control.touched )">
                                                            <div class="invalid-feedback"  *ngIf="employeePhone.control.errors.required">
                                                                required field
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <small  class="form-text text-muted mb-4">
                                                        Enter the phone number the agent uses commercially.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-xl-9 col-lg-8 col-md-7 col-sm-12">

                                                <div  class="form-group mb-4">
                                                    <label for="role" class="control-label">
                                                        Company Role
                                                    </label>
                                                    <div class="input-group ">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
                                                            </span>
                                                        </div>
                                                        <select name="employee_{{i}}_company_role" class="form-control" [(ngModel)]="cr.role" #employeeCompanyRole="ngModel" required [compareWith]="compareRoles">
                                                            <option [ngValue]="null" disabled> Select Option </option>
                                                            <ng-container *ngFor="let role of roles">
                                                                <option [ngValue]="role" *ngIf="role.scope == 'COMPANY'"> {{role.name}} </option>
                                                            </ng-container>
                                                        </select>
                                                        <ng-container *ngIf="employeeCompanyRole.control.errors != null && (employeesForm.submitted || employeeCompanyRole.control.touched )">
                                                            <div class="invalid-feedback"  *ngIf="employeeCompanyRole.control.errors.required">
                                                                required field
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <small  class="form-text text-muted mb-4">
                                                        Select the role the employee will work with, if you need to customize the employee's capabilities, click on the "Assign Manually" button.
                                                    </small>
                                                </div>

                                            </div>
                                            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-12 text-center">
                                                <button type="button" class="btn btn-new btn-assign-capabilities" *ngIf="cr.role != null" (click)="openModalRoleFormData(cr.role, i)">
                                                    <i class="fa-regular fa-hand-point-down fa-flip-vertical"></i>
                                                    &nbsp; Assign Manually
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <app-selectable-capacities-list
                                        *ngIf="cr.role != null"
                                        name="employee_{{i}}_company_caps"
                                        scope="COMPANY"
                                        [disabled]="true"
                                        [(ngModel)]="cr.role.capabilities"
                                        >
                                    </app-selectable-capacities-list>

                                </div>

                            </section>
                            <!-- end SECTION COMPANIES ROLE -->

                            <!-- START SECTION NOTE -->
                            <section class="widget-content-area" *ngIf="companySelectionAvailable.length == companies.length && formData.employeeWorkspacePermissions.role == null">
                                <p class="note">
                                    <strong>Note</strong>
                                    <br>
                                    If you do not assign permissions to the employee, the employee when logging in will not be able to access the workspace.
                                </p>
                            </section>
                            <!-- end SECTION NOTE -->

                    </div>
                </div>
            </div>
        </div>

    </form>

</div>


<!-- Modal - assign capabilities manually -->
<div class="modal fade"  #roleFormModal tabindex="-1" role="dialog" aria-labelledby="modal-role" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">

            <div class="modal-content modal-assign-permissions">
                <form class="form" #roleForm="ngForm"  novalidate autocomplete="off" (ngSubmit)="saveModalRoleFormData()">
                    <div class="modal-header ">
                        <h5 class="modal-title">
                            Assign Permissions
                        </h5>
                    </div>
                    <div class="modal-body">
                        <app-selectable-capacities-list
                            name="temporal_caps"
                            [scope]="roleFormData.scope"
                            [(ngModel)]="roleFormData.capabilities"
                            >
                        </app-selectable-capacities-list>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-cancel" data-dismiss="modal">
                            Cancel
                        </button>
                        <button class="btn btn-save" type="submit" [disabled]="isSending">
                            Assign
                        </button>
                    </div>
                </form>
            </div>

    </div>
</div>

<!-- Modal - company selection -->
<div class="modal fade"  #companySelectionModal tabindex="-1" role="dialog" aria-labelledby="modal-company" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered " role="document">

            <div class="modal-content modal-role-form ">
                <form class="form company-selection-form" #companySelectionForm="ngForm"  novalidate autocomplete="off" (ngSubmit)="saveModalCompanySelection()">
                    <div class="modal-header ">
                        <h5 class="modal-title">
                            Providing Access to Company
                        </h5>
                    </div>
                    <div class="modal-body">

                        <p>
                            Select the companies that you want the employee to be able to getin
                        </p>

                        <!-- Task -->
                        <ul class="list-group task-list-group">

                            <ng-container *ngFor="let company of companySelectionAvailable; let i = index">

                                <li class="list-group-item list-group-item-action" [ngClass]="{'active': company.selected}">
                                    <div class="n-chk">
                                        <label class="new-control new-checkbox checkbox-warning w-100 justify-content-between">
                                        <input type="checkbox" class="new-control-input" name="company_select_{{i}}" [(ngModel)]="company.selected">
                                        <span class="new-control-indicator"></span>
                                            <span class="ml-2">
                                                {{company.data.name | titlecase }}
                                            </span>
                                        </label>
                                    </div>
                                </li>

                            </ng-container>

                        </ul>

                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-cancel" data-dismiss="modal">
                            Cancel
                        </button>
                        <button class="btn btn-save" type="submit" [disabled]="isSending">
                            Assign
                        </button>
                    </div>
                </form>
            </div>

    </div>
</div>

<!-- Modal Edit Employee -->
<div class="modal fade"  #accountFormModal tabindex="-1" role="dialog" aria-labelledby="account_Form" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <form class="form" #accountForm="ngForm"  novalidate autocomplete="off" (ngSubmit)="saveAccountFormModal(accountForm)">
                    <div class="modal-header">
                        <h5 class="modal-title" *ngIf="accountFormData.id != null">
                            Update Access Account
                        </h5>
                        <h5 class="modal-title" *ngIf="accountFormData.id == null">
                            Assign Access Account
                        </h5>
                    </div>

                    <div class="modal-body">

                        <div class="form-group mb-1" *ngIf="viewAccountFormData == 'ENTIRE_FORM'">
                            <label class="control-label">Name</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-user">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </span>
                                </div>
                                <input type="text" name="account_name" class="form-control" required  [(ngModel)]="accountFormData.name" #accountName="ngModel" >
                                <ng-container *ngIf="accountName.control.errors != null && (accountForm.submitted || accountName.control.touched )">
                                    <div class="invalid-feedback"  *ngIf="accountName.control.errors.required">
                                        Required field
                                    </div>
                                </ng-container>
                            </div>
                            <small class="form-text text-muted mb-4">
                                Enter the name of the user who will operate the account.
                            </small>
                        </div>

                        <div class="form-group mb-1">
                            <label class="control-label">Email</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                    </span>
                                </div>
                                <input type="text"  name="account_email" class="form-control" required email  [(ngModel)]="accountFormData.email" #accountEmail="ngModel">
                                <ng-container *ngIf="accountEmail.control.errors != null && (accountForm.submitted || accountEmail.control.touched )">
                                    <div class="invalid-feedback"  *ngIf="accountEmail.control.errors.required">
                                        Required field
                                    </div>
                                    <div class="invalid-feedback"  *ngIf="accountEmail.control.errors.email">
                                        Invalid email address format
                                    </div>
                                </ng-container>
                            </div>
                            <small  class="form-text text-muted mb-4" *ngIf="viewAccountFormData == 'ENTIRE_FORM'">
                                Enter the email address of the user who will operate the account, Here the user will receive the login token when the user log in.
                            </small>
                            <small  class="form-text text-muted mb-4" *ngIf="viewAccountFormData == 'SEARCH'">
                                Enter the email address to verify if the account is already created.
                            </small>
                        </div>

                        <div class="form-group mb-1" *ngIf="viewAccountFormData == 'ENTIRE_FORM'">
                            <label class="control-label">Phone</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-phone">
                                            <path
                                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                                <input type="tel"  name="account_phone" class="form-control" required [(ngModel)]="accountFormData.phone" #accountPhone="ngModel">
                                <ng-container *ngIf="accountPhone.control.errors != null && (accountForm.submitted || accountPhone.control.touched )">
                                    <div class="invalid-feedback"  *ngIf="accountPhone.control.errors.required">
                                        Required field
                                    </div>
                                </ng-container>
                            </div>
                            <small  class="form-text text-muted mb-4">
                                Enter the phone number of the user who will operate the account, Here the user will receive the login token when the user log in.
                            </small>
                        </div>

                        <div class="form-group mb-1" *ngIf="viewAccountFormData == 'ENTIRE_FORM'">
                            <label class="control-label">New Password</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                    </span>
                                </div>
                                <input type="text" name="account_password" class="form-control" [(ngModel)]="accountFormData.password" #accountPassword="ngModel">
                            </div>

                            <small  class="form-text text-muted mb-4">
                                Fill out this field if you want to change the current password.
                            </small>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-cancel" data-dismiss="modal">
                            Cancel
                        </button>
                        &nbsp;
                        <button class="btn btn-save" type="submit" [disabled]="isSending">
                            <ng-container *ngIf="viewAccountFormData == 'ENTIRE_FORM'">
                                Save Changes
                            </ng-container>
                            <ng-container *ngIf="viewAccountFormData == 'SEARCH'">
                                Verify
                            </ng-container>
                        </button>
                    </div>
                </form>
            </div>
    </div>
</div>
