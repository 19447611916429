import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Equation } from 'src/app/entities/global/equation';



declare const jQuery;
declare const swal;

@Component({
  selector: 'app-equations-form',
  templateUrl: './equations-form.component.html',
  styleUrls: ['./equations-form.component.scss']
})
export class EquationsFormComponent implements OnInit {

  @ViewChild('equationFormModal')
  equationFormModal: ElementRef;

  private resolve: CallableFunction;
  private reject: CallableFunction;

  public keyNumberInput: number;
  public viewText: boolean;
  public equations: string;
  public selectedOptions: { value: string, id: string }[] = [];   // Almacena las opciones seleccionadas para evitar duplicados
  public nextId: number;                                          // Identificador único inicial
  public equationFormKeys;
  public equationForm;

  constructor() {
    this.equations = '';
    this.nextId = 1;
    this.viewText = false;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

    // Agrega un listener para el evento 'click' para manejar las selecciones
    this.equationFormKeys = document.getElementById('equationFormKeys');
    this.equationFormKeys.addEventListener('click', this.handleSelection);
    this.equationForm = document.getElementById('equationForm');

  }

  // Función para traer la ecuación y abrir la modal de ecuaciones
  open(equation: Equation): Promise<Equation> {

    // Se inicializa el array vacio y se eliminan los elementos del front que contienen la clase selected-key
    this.selectedOptions = [];
    document.querySelectorAll('.selected-key').forEach(e => e.remove());

    //Se valida si existe una formula
    if(equation !== null && !(equation.formula == undefined || equation.formula == null) ){      

      // Obtenemos la equación como string y se divide en un array
      const equationObj = equation.formula.split(' ');

      // Se recorre cada string para ser renderizado en el visor de la ecuación
      for (const equationElement of equationObj){
        this.renderSelectedOption(equationElement);
      }

    }    

    jQuery(this.equationFormModal.nativeElement).modal('show');

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    
  } 

  // Función para manejar la selección
  handleSelection = (event) => {
    const clickedKey = event.target as HTMLDivElement;

    // Verifica si el elemento click es un div dentro de equationFormKeys y contiene la clase "btn-key"
    if (clickedKey && clickedKey.classList.contains('btn-key')) {
      const selectedOption = clickedKey.dataset.value;

      // Agrega la opción al contenedor y al array de opciones seleccionadas
      this.renderSelectedOption(selectedOption);
    }
  }

  // Función para renderizar una opción seleccionada
  renderSelectedOption(option: string) {

    // Crea un identificador único para la opción seleccionada
    const optionId = `key_${this.nextId++}`;

    // Agrega la opción al array de opciones seleccionadas
    this.selectedOptions.push({ value: option, id: optionId });

    // Crea elemento para la opción seleccionada y agregarlo al contenedor
    const optionElement = document.createElement('div');
    optionElement.classList.add('selected-key');
    optionElement.textContent = option;
    optionElement.dataset.id = optionId; // Agrega el identificador único

    // Agregar un botón para eliminar la opción
    const removeButton = document.createElement('button');
    removeButton.textContent = 'x';
    removeButton.addEventListener('click', () => this.removeOption(optionId));

    // Agregar elementos al contenedor
    optionElement.appendChild(removeButton);
    this.equationForm.appendChild(optionElement);

  }

  // Función para eliminar una opción seleccionada
  removeOption(optionId: string) {

    // Buscar y quitar el elemento seleccionado por su identificador único
    const selectedOptionIndex = this.selectedOptions.findIndex(option => option.id === optionId);

    if (selectedOptionIndex !== -1) {
      this.selectedOptions.splice(selectedOptionIndex, 1);
    }

    // Quitar el elemento del contenedor
    const selectedOptionElement = this.equationForm.querySelector(`.selected-key[data-id="${optionId}"]`);
    if (selectedOptionElement) {
      this.equationForm.removeChild(selectedOptionElement);
    }

  }

  // Función que muestra la ecuación generada
  generateEquation() {

    let isAllowed = true;
    const tmpEquation = new Equation();

    // Variable que almacena la ecuación
    let equation = "";

    for (const equationArray of this.selectedOptions) {

      equation += equationArray.value + " ";

      if (!(this.isVariable(String(equationArray.value)) || this.isOperator(String(equationArray.value)) || this.isNumero(String(equationArray.value)) || this.isParentheses(String(equationArray.value)))) {
        console.log('falla 1', equationArray.value)
        isAllowed = false;
        break;
      }

      if (this.isVariable(String(equationArray.value))) {
        tmpEquation.variables.push(equationArray.value);
      }
    }

    if (isAllowed == false) {
      return null;
    }

    tmpEquation.formula = equation.trim();
    return tmpEquation;

  }

  // Función para insertar el número que se ha escrito en el input
  keyNumberBtnClick() {
    const keyNumberInputValue = (document.getElementById('keyNumberInput') as HTMLInputElement).value;
    const keyNumberBtn = document.getElementById('keyNumberBtn');

    keyNumberBtn.setAttribute('data-value', keyNumberInputValue);
  }

  save() {
    
    const tmpResultEquation = this.generateEquation();
console.log(this.selectedOptions, tmpResultEquation);
    // si existe ecuacion y la generacion devolvio null, es porque la ecuacion no esta correcta
    if(tmpResultEquation == null && this.selectedOptions.length > 0){
      swal({
        title: 'Attention!',
        text: "The equation include characters not allowed!",
        type: 'warning',
        padding: '2em'
      });
      return;
    }

    // si no hay caracteres indicamos que esta vacia
    if(this.selectedOptions.length == 0){
      swal({
        title: 'Attention!',
        text: "The equation is empty!",
        type: 'warning',
        padding: '2em'
      });
      return;
    }

    this.resolve(tmpResultEquation);
    jQuery(this.equationFormModal.nativeElement).modal('hide');

  }

  private isVariable(cadena: string) {

    const VARIABLES_ALLOWED = [
      "ADDITIONAL_BOL",
      "BINDING_FEE",
      "DISCOUNT",
      "DISCOUNT_FULL_PACKING_SERVICE",
      "DISCOUNT_PER_MILE",
      "ADDITIONAL_STOP_FEE",
      "ELEVATOR_DELIVERY",
      "ELEVATOR_PICKUP",
      "FULL_PACKING_SERVICE",
      "JOB_DEPOSIT",
      "JOB_SUB_TOTAL",
      "JOB_TOTAL",
      "LONG_CARRY_DELIVERY",
      "LONG_CARRY_PICKUP",
      "SHUTTLE_DELIVERY",
      "SHUTTLE_PICKUP",
      "SPLIT_DELIVERY",
      "SPLIT_PICKUP",
      "TOTAL_BOL"
    ];

    return VARIABLES_ALLOWED.includes(cadena);

  }

  private isOperator(cadena: string) {
    const OPERATORS = ["+", "-", "*", "/", "%"];
    return OPERATORS.includes(cadena);
  }

  private isParentheses(cadena: string) {
    const OPERATORS = ["(", ")"];
    return OPERATORS.includes(cadena);
  }

  private isNumero(cadena: string) {
    return /^\d+$/.test(cadena);
  }
  
}