import { ConstantsMessages } from 'src/app/constants-messages';
import { PayrollBalance } from 'src/app/entities/workspace/payroll-balance';
import { AuthService } from 'src/app/services/auth.service';
import { PayrollBalancesService } from 'src/app/services/companies/payroll-balances.service';
import { HelperService } from 'src/app/services/helper.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Employee } from '../../../entities/workspace/employee';
import { EmployeesService } from '../../../services/workspaces/employees.service';

declare var jQuery;
declare const swal;

@Component({
    selector: 'app-payroll-balances-form',
    templateUrl: './payroll-balances-form.component.html',
    styleUrls: ['./payroll-balances-form.component.scss']
})
export class PayrollBalancesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    payrollBalance: PayrollBalance;
    status: boolean;

    employees: Array<Employee>;

    constructor(
        public authService: AuthService,
        private currentRoute: ActivatedRoute,
        private payrollBalancesService: PayrollBalancesService,
        private router: Router,
        private helperService: HelperService,
        private employeesService: EmployeesService
    ) {
        this.payrollBalance = new PayrollBalance();
        this.status = false;
        this.employees = [];
    }

    ngOnInit(): void {
       
    }

    ngAfterViewInit(): void{
        this.loadEmployees();
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadPayrollBalance(params.id);
            }
        });
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadPayrollBalance(id: string): void {
        this.helperService.showLoadingMxpro360();
        this.payrollBalancesService
            .getById(id)
            .then(response => {
                this.payrollBalance = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeStatus(): void {
        this.payrollBalance.status = 'PAID';
        // this.payrollBalance.done_date = this.payrollBalance.status ? new Date() : null;
        swal(
            'Updated!',
            'The record has been updated.',
            'success'
        );
    }

    save(): void {
        const url = this.helperService.buildCompanyRouterLink('/payroll-balances');
        this.helperService.showLoadingMxpro360();
        this.payrollBalancesService
            .save(this.payrollBalance)
            .then((response) => {
                if (this.payrollBalance.status === 'PAID') {
                    const idPayrollBalance = response['row_id'] ? response['row_id'] : this.payrollBalance.id;
                    this.payrollBalancesService
                        .saveStatus(idPayrollBalance)
                        .then(() => {
                            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                            this.router.navigateByUrl(url);
                        })
                        .catch((error) => {
                            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                } else {
                    this.router.navigateByUrl(url);
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.showLoadingMxpro360();
            });
    }

}
