export class AdditionalStopRule {

    id: string;
    type: 'PICKUP' | 'DELIVERY';
    price_per_stop: number;
    price_per_mile: number;
    maxed_distance_traveled: number;
    max_miles_free: number;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.type = null;
        this.price_per_stop = null;
        this.price_per_mile = null;
        this.maxed_distance_traveled = null;
        this.max_miles_free = null;
        this.created = null;
        this.updated = null;
    }
}
