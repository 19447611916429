import { Component, OnInit } from '@angular/core';
import { TariffAdditionalConcept } from 'src/app/entities/workspace/tariff-additional-concept';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { TariffAdditionalConceptsService } from 'src/app/services/workspaces/tariff-additional-concepts.service';

declare const jQuery;
declare const swal;
@Component({
  selector: 'app-tariff-additional-concepts',
  templateUrl: './tariff-additional-concepts.component.html',
  styleUrls: ['./tariff-additional-concepts.component.scss']
})
export class TariffAdditionalConceptsComponent implements OnInit {

  public actualPage: number;
  public currentPage: number;
  public additionalConsepts: TariffAdditionalConcept[];
  public behavior: string;
  public serviceType: string;

  constructor(
    private tariffAdditionalConceptsService: TariffAdditionalConceptsService,
    public helperService: HelperService,
    public tariffVersionFactory: TariffVersionService
  ) {
    this.actualPage = 1;
    this.currentPage = 1;
    this.additionalConsepts = [];
    this.behavior = '';
    this.serviceType = '';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadTarriffConcepts();
  }

  async loadTarriffConcepts() {

    this.tariffAdditionalConceptsService.getAll()
      .then((response) => {
        this.additionalConsepts = response;
        if (this.behavior !== '' && this.serviceType === '') {
          this.additionalConsepts = this.additionalConsepts.filter(item => item.behavior === this.behavior);
        } else if (this.serviceType !== '' && this.behavior === '') {
          switch(this.serviceType) {
            case 'LONG_DISTANCE':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.display_on_long_distance === true);
              break;
            case 'LOCAL_DISTANCE':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.display_on_local_distance === true);
              break;
            case 'AUTO_TRANSPORT':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.display_on_auto_transport === true);
              break;
            case 'FREIGHT':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.display_on_freight === true);
              break;
          }
        } else if (this.behavior !== '' && this.serviceType !== '') {
          switch(this.serviceType) {
            case 'LONG_DISTANCE':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.behavior === this.behavior && item.display_on_long_distance === true);
              break;
            case 'LOCAL_DISTANCE':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.behavior === this.behavior && item.display_on_local_distance === true);
              break;
            case 'AUTO_TRANSPORT':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.behavior === this.behavior && item.display_on_auto_transport === true);
              break;
            case 'FREIGHT':
              this.additionalConsepts = this.additionalConsepts.filter(item => item.behavior === this.behavior && item.display_on_freight === true);
              break;
          }
        }
      });
  }

  async search() {
    this.loadTarriffConcepts();
  }

  remove(id: string) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.tariffAdditionalConceptsService
            .remove(id)
            .then((response) => {
              this.loadTarriffConcepts();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

}
