import { LocalRoomCategory } from "../workspace/local-room-category";
import { LocalInventoryCloset } from "./local-inventory-closet";

export class LocalInventoryRoom {
    public id: string;
    public estimate_id: string;
    public local_room_category: LocalRoomCategory;
    public furniture_level: string;
    public closets: LocalInventoryCloset[];
    public is_packing: boolean;
    public square_feet: number;

    constructor() {
        this.id = null;
        this.estimate_id = null;
        this.local_room_category = new LocalRoomCategory();
        this.furniture_level = null;
        this.closets = [];
        this.is_packing = false;
        this.square_feet = null;
    }
}
