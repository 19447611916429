import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const jQuery;
declare const feather;
declare const App;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

    @Input()
    menuType: 'WORKSPACE' | 'COMPANY' | 'ACCOUNT';

    @ViewChild('settingsLink', { static: true }) settingsLink: ElementRef;

    public sessionStoraged;
    public companyId;
    public agetLeadMode: 'POOL' | 'ASSIGNMENT' | 'LEAD_ROTATION';
    public displayLeadSection: boolean;

    constructor(
        private helperService: HelperService,
        private setting: GeneralSettingsService,
        public authService: AuthService,

        private router: Router,
        private renderer: Renderer2
    ) {
        this.menuType = null;
        this.agetLeadMode = 'POOL';
        this.displayLeadSection = false;

    }

    ngOnInit(): void {

        this.checkActiveRoute(); // Verificar la ruta activa al cargar el componente

        this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
        this.checkActiveRoute(); // Verificar la ruta activa después de cada cambio de ruta
        });
    }

    ngAfterViewInit(): void {

      this.setting
      .get()
      .then((response) => {
          this.agetLeadMode = response.settings.marketing_setting.agent_lead_mode;
          console.log(' ****  this.agetLeadMode ***** ',  this.agetLeadMode);


          // indicamos si el usuario logueado puede la seccion de leads
          this.displayLeadSection = this.CanISeeLeadsSection();
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });

        setTimeout(() => {
            feather.replace();
            App.init();

        }, 1000);


        /* *** */
        /* *** */// Active Sidebar Menu - RouterLinkActive - Se resalta la sección donde se encuentra el usuario (al recargar la página)
        /* *** */
        jQuery("a.active").parents('li.menu').addClass("selected-opt");
        jQuery("a.active").parents('.accordion-submenu').addClass("selected-opt");

        /* menu */
        jQuery("li.menu.selected-opt > .collapse").addClass("show");
        jQuery("li.menu.selected-opt > .accordion > .collapse").addClass("show");

        /* submenu */
        jQuery(".accordion-submenu.selected-opt > .collapse").addClass("show");

        jQuery("#sidebar .accordion-submenu a").attr("aria-expanded", "false");
        jQuery("#sidebar .accordion-submenu.selected-opt a").attr("aria-expanded", "true");



        // Evento click (Submenu) para ocultar y mostrar el indicador de posición en el sidebar
        jQuery(document).on('click', '.menu-categories .submenu a', function () {

            /* Remueve la clase "selected-opt" del elemento que tenga esa clase */
            jQuery(".menu-categories").find(".selected-opt").removeClass("selected-opt")

            setTimeout(() => {
                jQuery("a.active").parents('li.menu').addClass("selected-opt");
                jQuery("a.active").parents('.accordion-submenu').addClass("selected-opt");
            }, 300);

        });



        // Evento click (Menu) para ocultar y mostrar el indicador de posición en el sidebar
        jQuery(document).on('click', '.menu-categories > li.menu > a', function () {

            /* Remueve la clase "selected-opt" del elemento que tenga esa clase */
            jQuery(".menu-categories").find(".selected-opt").removeClass("selected-opt");

            /* Menu */
            jQuery("li.menu a").attr("aria-expanded", "false");
            jQuery("li.menu > .accordion > .collapse").removeClass("show");

            /* Submenu */
            jQuery("li.menu .accordion-submenu > .collapse").removeClass("show");

            /* Add Class "selected-opt" */
            setTimeout(() => {
                jQuery("a.active").parents('li.menu').addClass("selected-opt");
                jQuery("a.active").parents('.accordion-submenu').addClass("selected-opt");
            }, 300);

        });

        // Evento click (Menu) (No Submenu) para ocultar y mostrar el indicador de posición en el sidebar
        jQuery(document).on('click', '.menu-categories > li.menu > .no-submenu li.menu a', function () {

            console.log("Hice Click");

            /* Remueve la clase "selected-opt" del elemento que tenga esa clase */
            jQuery(".menu-categories").find(".selected-opt").removeClass("selected-opt");

            /* Submenu */
            jQuery("li.menu .accordion-submenu a").attr("aria-expanded", "false");
            jQuery("li.menu .accordion-submenu > .collapse").removeClass("show");

            setTimeout(() => {
                jQuery("a.active").parents('li.menu').addClass("selected-opt");
            }, 300);

        });

        // Se cambia el texto del boton "Filter" dependiendo del estado del Collapse - All Filters Sections
        jQuery(document).on('click', 'a.btn-filter-collapse', function () {
            var $this = jQuery(this);
            jQuery("span", this).text($this.hasClass("collapsed") ? "Show Filters" : "Hide Filters");
        });
        /* *** */
        /* *** */// Active Sidebar Menu - RouterLinkActive - Se resalta la sección donde se encuentra el usuario (al recargar la página)
        /* *** */

    }

    chatWhatsapp() {
        jQuery('.floating-wpp-button').click();
        jQuery('.floating-wpp-popup.active textarea').first().focus();
    }

    /**
     * Permite verificar si el usuario logueado puede ver la seccion de leads
     * @returns
     */
    private CanISeeLeadsSection() {
      console.log('agetLeadMode ', this.agetLeadMode);
        // si esta en modo pisicina
        if (this.agetLeadMode == 'POOL') {
            // y tiene uno de estos permisos: asignar leads, ver leads
            return this.authService.hasPermissionsCompany(['ASSIGN_LEADS', 'LEADS']);
        }

        // si esta en modo asignacion
        if (this.agetLeadMode == 'ASSIGNMENT') {
            // y tiene uno de estos permisos: asignar leads
            return this.authService.hasPermissionsCompany(['ASSIGN_LEADS']);
        }

        // si esta en modo asignacion
        if (this.agetLeadMode == 'LEAD_ROTATION') {
            return this.authService.hasPermissionsCompany(['ASSIGN_LEADS']);
        }

        return false;
    }

    /**
     * Permite abrir la pestaña del dialer, pero evitando que se abra mas de una vez,
     * es decir, se abre la primera vez, pero para la segunda si ya esta abierta,
     * activa la pestaña que ya esta abierta
     *
     * @param url
     */
    openCompanyDialer() {

        this.helperService.showLoadingMxpro360();

        // construirmos la url para usar posteriormente
        const urlComplete = window.origin + "/#" + this.helperService.buildCompanyRouterLink("/dialer");

        /**
         * AQUI VAMOS A EMPLEAR UNA ESTRATEGIA QUE NOS PERMITA VERIFICAR
         * SI LA PESTAÑA DEL DIALER QUE SE ESTA SOLICITANDO YA ESTA ABIERTA,
         * SI YA ESTA ABIERTA, EL NAVEGADOR DEBE PONER ESA PESTAÑA COMO ACTIVA,
         * DE LO CONTRARIO SE ABRE UNA PESTAÑA NUEVA
         */

        // obtenemos las ulrs abiertas guardadas del localstorage
        const storageDialerURLS = localStorage.getItem('DIALER_URLS');
        let dialerURLS = [];

        if (storageDialerURLS !== null) {
            dialerURLS = JSON.parse(storageDialerURLS) as Array<string>;
        }

        let isFound = false;

        // recorremos las urls almacenadas
        for (const url of dialerURLS) {
            if (url === urlComplete) {
                isFound = true;
                break;
            }
        }

        // creamos un broadcastchannel con las pestañas abiertas de app.moverxpro360.com
        const bccDIALER = new BroadcastChannel('DIALER');

        // creamos un nombre para la ventana
        const dialerWindowName = "dialer_" + this.authService.workspaceSession.company.id;

        // verificamos si ya ha sido abierta
        if (isFound) {

            // creamos un identificador aleatorio para que no se vaya a presentar errores
            const customID = Math.floor(Math.random() * 9999);

            // emitimos una solicitud a la pestaña que esta abierta para la url que se solicitó se active
            bccDIALER.postMessage({ action: 'REQUEST_ACTIVATE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });

            // escuchamos mensajes que se envien desde otras pestañas (tab) del mismo origen: app.moverxpro360.com
            bccDIALER.onmessage = (event) => {

                const data = event.data as { action: string, url: string, name: string , requestID: number, isActive: boolean, hasValue: boolean,};

                // verificamos si la accion es la respuesta a la solicitud arriba
                if(data.action == "RESPONSE_ACTIVATE_TAB" && data.url == urlComplete){

                    // si devuelve que es activo no se hace nada mas.. pero si no es activo, cerramos la pestaña, y abrimos una nueva
                    if(data.isActive){
                        this.helperService.hideLoadingMxpro360();
                    }else{

                        // enviamos una solicitud a las otras pestañas para verificar si la pestaña que estaba abierta del dialer, esta abierta correctamente
                        bccDIALER.postMessage({ action: 'REQUEST_TAB_VERIFICATION', url: urlComplete, name: dialerWindowName, requestID: customID });
                    }
                }

                // verificamos si la accion es la respuesta a la solicitud arriba
                if(data.action == "RESPONSE_TAB_VERIFICATION" && data.url == urlComplete && data.requestID == customID){

                    // si tiene valor es porque fue abierto correctamente, entonces abrimos la pestaña con enlace
                    if(data.hasValue){

                        // como ya se cerro la otra pestaña, abrimos una nueva pestaña
                        setTimeout(() => {
                            const popup = window.open(urlComplete, dialerWindowName);
                            popup.focus();

                            this.helperService.hideLoadingMxpro360();
                        }, 1000);

                    }else{

                        // solicitamos que se cierre la otra pestaña
                        // bccDIALER.postMessage({ action: 'REQUEST_CLOSE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });

                        // como no se puede cerrar la otra pestaña en la mayoria de los casos, esto por temas de seguridad en los navegadores, entonces vamos a indicar que ya hay una pestaña abierta
                        alert("There is currently a browser tab open for this link");
                        this.helperService.hideLoadingMxpro360();
                    }


                }
            }
        } else {

            // como no hay pestañas abiertas con la misma url, abrimos una nueva pestaña
            const popup = window.open(urlComplete, dialerWindowName);
            popup.focus();

            this.helperService.hideLoadingMxpro360();
        }
    }



    checkActiveRoute() {
        const activePatterns = [
          /\/settings$/,
          /\/tariff-versions\/add$/,
          /\/tariff-versions\/[a-f0-9]+\/edit$/  // Patrón para editar versiones de tarifa específicas
        ];
        const currentUrl = this.router.url;
        console.log('Current URL:', currentUrl); // Debugging

        const isActive = activePatterns.some(pattern => pattern.test(currentUrl));
        if (isActive) {
          this.renderer.addClass(this.settingsLink.nativeElement, 'active');
          console.log('Added active class to Settings link');
        } else {
          this.renderer.removeClass(this.settingsLink.nativeElement, 'active');
          console.log('Removed active class from Settings link');
        }
      }


}
