import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EstimateCarrierNote } from 'src/app/entities/workspace/estimate-carrier-note';
import { JobCarrierNote } from 'src/app/entities/workspace/job-carrier-note';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class EstimateCarrierNotesService {

    constructor(private http: HttpClient) {

    }


    getAll(estimateId: string): Promise<Array<JobCarrierNote>> {
        return this.http.get<Array<JobCarrierNote>>(environment.api.company + '/estimates/' + estimateId + '/carrier-notes').toPromise();
    }

    getById(estimateId: string, carrierNoteId: string): Promise<EstimateCarrierNote> {
        return this.http.get<EstimateCarrierNote>(environment.api.company + '/estimates/' + estimateId + '/carrier-notes/' + carrierNoteId).toPromise();
    }

    save(estimateId: string, dataForm: JobCarrierNote): Promise<any> {
        if (dataForm.id === null) {
            return this.add(estimateId, dataForm);
        }
        return this.edit(estimateId, dataForm);
    }

    add(estimateId: string, dataForm: JobCarrierNote) {
        return this.http.post(environment.api.company + '/estimates/' + estimateId + '/carrier-notes', dataForm).toPromise();
    }

    edit(estimateId: string, dataForm: JobCarrierNote) {
        return this.http.put(environment.api.company + '/estimates/' + estimateId + '/carrier-notes/' + dataForm.id, dataForm).toPromise();
    }

    remove(estimateId: string, carrierNoteId: string) {
        return this.http.delete(environment.api.company + '/estimates/' + estimateId + '/carrier-notes/' + carrierNoteId).toPromise();
    }
}
