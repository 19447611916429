import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Automation } from 'src/app/entities/companies/automation';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { Employee } from 'src/app/entities/workspace/employee';
import { SmsTemplate } from 'src/app/entities/workspace/sms-template';
import { TicketType } from 'src/app/entities/workspace/ticket-type';
import { AutomationsService } from 'src/app/services/companies/automations.service';
import { EmailTemplatesService } from 'src/app/services/companies/email-templates.service';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { HelperService } from 'src/app/services/helper.service';
import { SmsTemplatesService } from 'src/app/services/workspaces/sms-templates.service';

declare const jQuery;
declare const swal;
@Component({
  selector: 'app-automations-form',
  templateUrl: './automations-form.component.html',
  styleUrls: ['./automations-form.component.scss']
})
export class AutomationsFormComponent implements OnInit {

  constantsMessages = ConstantsMessages;
  currentTab: number; // Current tab is set to be the first tab (0)
  dataForm: Automation;
  emailTemplateAction: string;
  emailTemplates: Array<EmailTemplate>;
  employeeName: string;
  employees: Array<Employee>;
  filterDeliveryState: string[];
  filterPickupState: string[];
  showCategories: boolean;
  showClassAndCategory: boolean;
  showEstimateStatus: boolean;
  showJobStatus: boolean;
  showLeadStatus: boolean;
  smsTemplateAction: string;
  smsTemplates: Array<SmsTemplate>;
  ticketsTypes: TicketType[];
  ticketSubject: string;
  typeTriggerEvent: string;

  constructor(
      private automationsService: AutomationsService,
      private currentRoute: ActivatedRoute,
      private employeesCompanyService: EmployeesCompanyService,
      private estimateEmailTemplatesService: EmailTemplatesService,
      private helperService: HelperService,
      private smsTemplatesService: SmsTemplatesService,
      private ticketsTypesService: TicketsTypesService
        ) {
      this.currentTab = 0;
      this.dataForm = new Automation();
      this.dataForm.action = 'EMAIL';
      this.emailTemplateAction = '';
      this.emailTemplates = [];
      this.employeeName = '';
      this.employees = [];
      this.filterDeliveryState = [];
      this.filterPickupState = [];
      this.showCategories = true;
      this.showClassAndCategory = true;
      this.showEstimateStatus = true;
      this.showJobStatus = true;
      this.showLeadStatus = true;
      this.smsTemplateAction = '';
      this.smsTemplates = [];
      this.ticketsTypes = [];
      this.ticketSubject = ''
      this.typeTriggerEvent = '';
    }

  ngOnInit(): void {
    this.load(null);
    this.loadTicketsTypes();
  }

  ngAfterViewInit() {
      this.currentRoute.params.subscribe(params => {
          if (typeof params.id !== 'undefined') {
              this.load(params.id);
          }
      });

      this.showTab(this.currentTab); // Display the current tab

  }

  load(id: string): void {
      this.automationsService.getById(id).then((response) => {
          this.dataForm = response;
          this.validateSectionsToShow();
          let minutes = '';
          let qtyCharacters = 0;
          
          if(this.dataForm.action_time_set_start){
            qtyCharacters = this.dataForm.action_time_set_start.toString().length;
            minutes = this.dataForm.action_time_set_start.toString().slice(qtyCharacters-2);
            this.dataForm.action_time_set_start = this.dataForm.action_time_set_start.toString().replace(minutes,'');
            
            if (qtyCharacters == 3) {
                this.dataForm.action_time_set_start = '0' + this.dataForm.action_time_set_start + ':' + minutes;
            }
            
            if (qtyCharacters == 4) {
              this.dataForm.action_time_set_start = this.dataForm.action_time_set_start + ':' + minutes;
            }
            
          }
          
          if(this.dataForm.action_time_set_end){
            qtyCharacters = this.dataForm.action_time_set_end.toString().length;
            minutes = this.dataForm.action_time_set_end.toString().slice(qtyCharacters-2);
            this.dataForm.action_time_set_end = this.dataForm.action_time_set_end.toString().replace(minutes,'');
            
            if (qtyCharacters == 3) {
                this.dataForm.action_time_set_end = '0' + this.dataForm.action_time_set_end + ':' + minutes;
            }
            
            if (qtyCharacters == 4) {
              this.dataForm.action_time_set_end = this.dataForm.action_time_set_end + ':' + minutes;
            }
            
          }

          this.filterPickupState = this.dataForm.filters.pickup_state;
          this.filterDeliveryState = this.dataForm.filters.delivery_state;

          let trigger = ['STORAGE_INVOICE_DUE', 'STORAGE_PAYMENT_PAST', 'FADD', 'JAF_NOT_SIGNED', 'MOVE_DATE', 'PICKUP_DATE' ]
          if (trigger.includes(this.dataForm.trigger_event)){
            this.typeTriggerEvent ='TIME';
          } else {
            this.typeTriggerEvent ='CLICK';
          }

          for (const filPickState of this.filterPickupState){
            jQuery(jQuery(".tab")[5]).find(".pickup-state-list .new-control-input").each(function() {
              var checkboxName = jQuery(this).attr("name");
              if (checkboxName === filPickState) {
                jQuery(this).prop("checked", true);
              }
            
            });

          }

          for (const filDelState of this.filterDeliveryState){
            jQuery(jQuery(".tab")[5]).find(".delivery-state-list .new-control-input").each(function() {
              var checkboxName = jQuery(this).attr("name");
              if (checkboxName === filDelState) {
                jQuery(this).prop("checked", true);
              }
            
            });

          }

      });
      this.estimateEmailTemplatesService
            .getAll()
            .then((response) => {
                this.emailTemplates = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

      this.smsTemplatesService
            .getAll()
            .then((response) => {
                this.smsTemplates = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

      this.employeesCompanyService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });



  }

  // deschulea el by_cubic_feet
  changeByPercentage(checkValue: boolean) {        
  //     if (checkValue) {
  //         this.dataForm.by_cubic_feet = false;
  //     }
  }

  save() {
   if(this.dataForm.action_time_set_start) {
      this.dataForm.action_time_set_start = Number(parseFloat(this.dataForm.action_time_set_start.replace(":","")));
   }

   if(this.dataForm.action_time_set_end) {
      this.dataForm.action_time_set_end = Number(parseFloat(this.dataForm.action_time_set_end.replace(":","")));
   }

   if(this.dataForm.action_time == null) {
      this.dataForm.action_time = 'AT_TRIGGER_EVENT';
   }

   this.dataForm.filters.pickup_state = this.filterPickupState;
   this.dataForm.filters.delivery_state = this.filterDeliveryState;
  
    this.helperService.showLoadingMxpro360();
    this.automationsService.save(this.dataForm)
    .then(() => {
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this.helperService.goToCompanyRouterLink('/automations');
    })
    .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }


    /**
   * Metodo que carga los tickets para mostrarlos en el select
   */
  private loadTicketsTypes() {
    this.helperService.showLoadingMxpro360();
    this.ticketsTypesService
        .getAll()
        .then((response) => {
            this.ticketsTypes = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }

  /* Agregar estados al filtro de Pickup State */
  addFilterPickupState(pickupState) {
    if (this.filterPickupState.includes(pickupState)) {
      this.filterPickupState.splice(this.filterPickupState.indexOf(pickupState), 1);
    
      // if(this.filterPickupState.length == 0){
      //   jQuery(jQuery(".tab")[5]).find(".pickup-state-list .new-control-input").prop("checked", true);
      // }

      return;
    }


    this.filterPickupState.push(pickupState);


  }

  /* Agregar estados al filtro de Delivery State */
  addFilterDeliveryState(deliveryState) {
    if (this.filterDeliveryState.includes(deliveryState)) {
      this.filterDeliveryState.splice(this.filterDeliveryState.indexOf(deliveryState), 1);
      return;
    }

    this.filterDeliveryState.push(deliveryState);

  }

 
  showTab(tabNumber: number) {
    // Se muestra la pestaña especificada del formulario
    var tabs = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;
    tabs[tabNumber].style.display = "block";
    // si el tab es 0 no muestra el botón previus
    if (tabNumber == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }
    // si el tab es el último muestra la palabra submit en el boton next
    if (tabNumber == (tabs.length - 1)) {
      document.getElementById("nextBtn").innerHTML = "Submit";
    } else {
      document.getElementById("nextBtn").innerHTML = "Next";
    }
  }
  
  nextPrev(tabIndex: number) {
    // Esta función averiguará qué pestaña mostrar
    const tabs = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;
    // Salir de la función si algún campo de la pestaña actual no es válido
    if (tabIndex == 1 && !this.validateForm()) return false;
    // Ocultar la pestaña actual
    tabs[this.currentTab].style.display = "none";
    // Aumenta o disminuye la pestaña actual en 1
    this.currentTab = this.currentTab + tabIndex;
    // Si ha llegado al final del formulario
    if (this.currentTab >= tabs.length) {
      // ... el formulario se envía
      this.save();
      return false;
    }
    // En caso contrario, muestre la ficha correcta
    this.showTab(this.currentTab);
  }
  
  validateForm() {

    // Se elimina la clase "invalid" de los elementos que la tienen
    const elementsInvalidClass = document.querySelectorAll('.invalid');
    let valid: boolean= true;

    elementsInvalidClass.forEach((element) => {
      element.classList.remove('invalid');
    });

    // Se validan los campos del formulario
    const tabs = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;

    let checkEmptyInputs = false;

    // ACTION
    if (this.currentTab == 0) {

      if (this.dataForm.action == 'EMAIL' || this.dataForm.action == 'TEXT') {      
              
        if (this.dataForm.from == null) {
          document.getElementById('from_dropdown').classList.add('invalid');
          valid = false
        }
        if (this.dataForm.to == null) {
          document.getElementById('to_dropdown').classList.add('invalid');
          valid = false
        }
        if (this.dataForm.to == 'COMPANY_EMPLOYEES' && this.dataForm.to_employee_id == null) {
          document.getElementById('employee_dropdown').classList.add('invalid');
          valid = false
        }
        if (this.dataForm.action == 'EMAIL' && this.dataForm.email_template_id == null) {
          document.getElementById('template_dropdown').classList.add('invalid');
          valid = false
        }
        if (this.dataForm.action == 'TEXT' && this.dataForm.sms_template_id == null) {
          document.getElementById('template_dropdown').classList.add('invalid');
          valid = false
        }

      }

      if (this.dataForm.action == 'EMAIL' && !this.dataForm.to_custom_email) {    
        checkEmptyInputs = true;
        /* valid = this.validateEmail(); */
      }

      if(this.dataForm.action == 'TEXT' && !this.dataForm.to_custom_phone) {
        checkEmptyInputs = true;
      }

      if (this.dataForm.action == 'CREATE_TICKET') {
        if (this.dataForm.ticket.ticket_type_id == null) {
          document.getElementById('ticket_subject_dropdown').classList.add('invalid');
          valid = false
        }
        if (!this.dataForm.ticket.description) {
          document.getElementById('ticket_desc_container').classList.add('invalid')          
          valid = false
        }
      }
      
    }

    // TRIGGER EVENT
    if (this.currentTab == 1) {
      if (this.dataForm.trigger_event == null) {
        valid = false
      }
    }

    // SET TIME
    if (this.currentTab == 2) {
      if (this.typeTriggerEvent == 'TIME' && this.dataForm.action_time == null) {
        document.getElementById('set_action_time_dropdown').classList.add('invalid');
        valid = false
      }
      if (this.dataForm.time == null) {
        document.getElementById('set_time_dropdown').classList.add('invalid');
        valid = false
      } 
      if (this.dataForm.time == 'SET_TIME' && this.dataForm.time_set == null) {
        document.getElementById('time_set_dropdown').classList.add('invalid');
        valid = false
      }
      if (this.dataForm.time == 'SET_TIME' && this.dataForm.time_interval == null) {
        document.getElementById('time_interval_dropdown').classList.add('invalid');
        valid = false
      }
      if (this.dataForm.time == 'SET_TIME' && this.dataForm.action_time_set_zone == null) {
        document.getElementById('action_time_set_zone_dropdown').classList.add('invalid');
        valid = false
      }
      checkEmptyInputs = true;
    }

    // STATUS & CATEGORY
    if (tabs[this.currentTab].className.indexOf('_status-and-category') >= 0) {
      if ((this.dataForm.status == null && (this.showEstimateStatus || this.showJobStatus || this.showLeadStatus)) || (this.dataForm.category == null && this.showCategories)) {
        valid = false
      }
    } 

    // RANGE FILTER
    if (tabs[this.currentTab].className.indexOf('_range-filter') >= 0) {
      if (this.dataForm.filters.price_range == null) {
        document.getElementById('price_range_dropdown').classList.add('invalid');        
        valid = false
      }
      if (this.dataForm.filters.size_range == null) {
        document.getElementById('size_range_dropdown').classList.add('invalid');
        valid = false
      }
      checkEmptyInputs = true;
    }  
           
    
    if (checkEmptyInputs) {
      const inputs = tabs[this.currentTab].getElementsByTagName("input");
      // Se comprueban todos los input de la pestaña actual
      for (let i = 0; i < inputs.length; i++) {
        // Si un campo está vacío...
        if (inputs[i].value == "") {
          // Se añade la clase "invalid"
          // inputs[i].parentNode.className += " invalid";
          // Se establece en 'false' el estado de 'valid'
          valid = false;
          break;
        }
      }
    }
    
    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
      document.getElementsByClassName("step")[this.currentTab].className += " finish";
    } else {
      swal(
          'Warning!',
          'Please fill in the form fields and/or select an option',
          'warning'
      );
    }
    return valid; // return the valid status
  }
  
  fixStepIndicator(stepIndex: number) {
    // Se elimina la clase 'active' de todos los step
    const steps = document.getElementsByClassName("step");
    for (let i = 0; i < steps.length; i++) {
      steps[i].className = steps[i].className.replace(" active", "");
    }
    // y se añade la clase "active" en el step actual
    steps[stepIndex].className += " active";
  }

  defaultAttr(){
    this.dataForm.action_time = null;
    this.dataForm.time = null;
    this.validateSectionsToShow();
  }

  validateSectionsToShow() {
    this.showClassAndCategory = ['ADDRESS_CHANGED', 'BECOMES_ESTIMATE', 'CLICK_BOOK', 'FADD_DATE_CHANGED', 'MOVE_DATE','MOVE_DATE_CHANGED', 'PAYMENT_UPLOADED_IN_CUSTOMER_PORTAL', 'PICKUP_DATE_CHANGED', 'VOLUME_CHANGED'].indexOf(this.dataForm.trigger_event) >= 0;
    this.showLeadStatus = ['BECOMES_ESTIMATE', 'MOVE_DATE','MOVE_DATE_CHANGED'].indexOf(this.dataForm.trigger_event) >= 0;
    this.showEstimateStatus = ['ADDRESS_CHANGED', 'BECOMES_ESTIMATE', 'FADD_DATE_CHANGED',  'MOVE_DATE', 'MOVE_DATE_CHANGED', 'PAYMENT_UPLOADED_IN_CUSTOMER_PORTAL', 'PICKUP_DATE_CHANGED', 'VOLUME_CHANGED'].indexOf(this.dataForm.trigger_event) >= 0;
    this.showJobStatus = ['ADDRESS_CHANGED', 'CLICK_BOOK', 'FADD_DATE_CHANGED',  'MOVE_DATE', 'MOVE_DATE_CHANGED', 'PAYMENT_UPLOADED_IN_CUSTOMER_PORTAL', 'PICKUP_DATE_CHANGED', 'VOLUME_CHANGED'].indexOf(this.dataForm.trigger_event) >= 0;
    this.showCategories = ['CLICK_BOOK'].indexOf(this.dataForm.trigger_event) >= 0;
  }

  validateEmail(){
                
    // Input email
    var emailField = document.getElementById('to_custom_email') as HTMLInputElement;
    
    // Definir nuestra expresión regular.
    var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
  
    // Usando test podemos comprobar si el texto coincide con el patrón
    if(validEmail.test(emailField.value) ){
      return true;
    } else{      
      return false;
    }
  } 

  resetActionForm() {
    this.dataForm.from = null;
    this.dataForm.to = null;
    this.dataForm.to_employee_id = null;
    this.dataForm.to_custom_email = null;
    this.dataForm.to_custom_phone = null;
    this.dataForm.email_template_id = null;
    this.dataForm.sms_template_id = null;
    this.employeeName = null;
    this.emailTemplateAction = null;
    this.smsTemplateAction = null;
    this.dataForm.ticket.id = null;
    this.dataForm.ticket.ticket_type_id = null;
    this.dataForm.ticket.description = null;
    this.ticketSubject = null
  }

}
