import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { Company } from 'src/app/entities/workspace/company';
import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';
import { LeadsService } from 'src/app/services/leads.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare const jQuery: any;

@Component({
  selector: 'app-load-leads-by-excel',
  templateUrl: './load-leads-by-excel.component.html',
  styleUrls: ['./load-leads-by-excel.component.scss']
})
export class LoadLeadsByExcelComponent implements OnInit, AfterViewInit {

  private constantsMessages = ConstantsMessages;
  public fileName: string;
  public companies: Company[];
  public companiesLeads: string[];
  public companiesLeadsName: string[];

  constructor(
    private companiesService: CompaniesService,
    private helperService: HelperService,
    private leadsService: LeadsService,
    private uploadFilesService: UploadFilesService
  ) {
    this.companies = [];
    this.companiesLeads = [];
    this.companiesLeadsName = [];
    this.fileName = '';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.loadCompanies();
    jQuery('.basic').select2({
      tags: true,
    });
  }

  private loadCompanies() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
        .getAll(true)
        .then((response) => {
            this.companies = response;
            this.toggleCompaniesSelected();
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }

  toggleCompaniesSelected() {

    const THIS = this;

    // otorga propiedades a 
    setTimeout(() => {

        const select = jQuery('.tagging');
        let idTimeOut = null;

        select.select2({
            tags: true
        })
            .on('select2:select', (e) => {
                THIS.setEmployeeCompanies(e.params.data, 'select');
            })
            .on('select2:unselect', (e) => {
                THIS.setEmployeeCompanies(e.params.data, 'unselect');
            });

        // si hay cambios en el dom refrescamos el plugin
        select.bind('DOMSubtreeModified', () => {

            if (idTimeOut !== null) {
                clearTimeout(idTimeOut);
            }

            idTimeOut = setTimeout(() => {
                select.trigger('change');
            }, 1000);
        });
    }, 200);
  }

  setEmployeeCompanies(textId, type: string) {

    const id = textId.id.split(':')[1].trim().replace(/'/g, '');
    const text = textId.text;
    const indexId = this.companiesLeads.indexOf(id);
    if (type === 'select') {
        if (indexId === -1) {

            this.companiesLeads.push(id);
            this.companiesLeadsName.push(text.trim());

        }
    }
    if (type === 'unselect') {
        if (indexId >= 0) {
            this.companiesLeads.splice(indexId, 1);
            this.companiesLeadsName.splice(indexId, 1);
        }
    }
  }

  public openUploadModal(): void {
    this.uploadFilesService
        .openModal()
        .then((response: Array<File>) => {
            if (response.length == 0) {
                this.fileName = '';
                return;
            } else {
              this.fileName = response[0].name;
            }
        });
  }


  save(): void { 
    if (this.fileName == '') {
      this.helperService.showMessageSnackbar("Please Upload a File");
    }
    if (this.companiesLeads.length == 0) {
      this.helperService.showMessageSnackbar("Please Select a Company");
    }

    if (this.companiesLeads.length > 0 && this.fileName != '') {
      // cargar archivo
      this.helperService.showLoadingMxpro360();
      this.leadsService.loadLeadsByExcel(this.fileName, this.companiesLeads)
      .then((response) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED)
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    }

  }

}
