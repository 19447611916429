<div class="layout-spacing layout-top-spacing">
    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center layout-spacing">
            <h3>
                Phone Lines
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Phone Lines</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center layout-spacing">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/phone-lines/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; Assign New Number
                </a>
            </div>
        </div>

    </div>

    <!-- Help Bar -->
    <div class="col-lg-12 col-md-12 label-section">
        <div class="label label-phone-number-deleted">
            <div class="box"></div>
            <div>Assignment with phone number deleted</div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                 <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th class="pl-4">Phone Number</th>
                                <th>Company</th>
                                <th>Destination</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;" [ngClass]="{'phone-number-deleted': !row.number}">
                                <td>{{i+1}} </td>
                                <td>{{row.name}}</td>
                                <td class="position-relative pl-4 text-center">
                                    <span *ngIf="!row.number" class="deleted-phone-number-alert tooltipmxp" title="This phone number has been deleted">
                                        <i class="fa-solid fa-triangle-exclamation ml-3"></i>
                                    </span>
                                    <span class="mr-3">{{row.phone_number | phone}}</span>
                                </td>

                                <td>{{row.company_id | titlecase}}</td>

                                <td *ngIf="row.destination.target == 'EXTENSION'" >{{row.destination.target | filterReplace}}<br>{{row.targets.name | titlecase}} <span *ngIf="row.targets.extension">{{ row.targets.extension }}</span> </td>

                                <ng-container *ngIf="row.destination.target == 'RING_GROUP'">
                                    <ng-container *ngFor="let ring of ringGroups">
                                        <ng-container *ngIf="ring.id == row.destination.target_id">
                                            <td>{{ring.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'EXTERNAL_FORWARD'">
                                    <td>{{row.destination.target_id}}</td>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'EXTENSION'">
                                  <ng-container *ngFor="let num of rows">
                                      <ng-container *ngIf="num.id == row.destination.target_id">
                                          <td>{{num.name}} : {{num.extension}}</td>
                                      </ng-container>
                                  </ng-container>
                              </ng-container>

                                <ng-container *ngIf="row.destination.target == 'VOICEMAIL'">
                                    <ng-container *ngFor="let num of rows">
                                        <ng-container *ngIf="num.id == row.destination.target_id">
                                            <td>{{num.name}} : {{num.extension}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'QUEUE'">
                                    <ng-container *ngFor="let queu of queues">
                                        <ng-container *ngIf="queu.id == row.destination.target_id">
                                            <td>{{queu.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'EMPLOYEE'">
                                    <ng-container *ngFor="let employee of employees">
                                        <ng-container *ngIf="employee.id == row.destination.target_id">
                                            <td>{{employee.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="row.destination.target == 'IVR'">
                                    <ng-container *ngFor="let ivr of ivrs">
                                        <ng-container *ngIf="ivr.id == row.destination.target_id">
                                            <td>{{ivr.name}}</td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>

                                <td>
                                    <a appWorkspaceRouterLink ="/phone-lines/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button  class="btn btn-danger button-space" (click)="remove(row.id)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
