export class DepositDueBalance {
    public id: string;
    public base_set: number;
    public base_amount_due: number;
    public current_amount_due: number;
    public altered_amount_due: number;

    constructor() {
        this.id = null;
        this.base_set = 0;
        this.base_amount_due = 0;
        this.current_amount_due = 0;
        this.altered_amount_due = 0;
    }
}
