<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    Balance Details
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appCompanyRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/payments">Balance</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Details</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">


                </div>
            </div>
        </div>
        <!-- header -->


    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 layout-top-spacing">
        <div class="user-profile info-lead layout-spacing">
            <div class="widget-content widget-content-area">
                <div class=" justify-content-between widget-heading">
                    <h3 class="title-content-area title">
                        Customer
                    </h3>
                </div>

                <div class="text-center user-info" style="margin: 0;">
                    <p class="name">
                        {{balance.customer.name}} {{balance.customer.last_name | titlecase}}
                    </p>
                </div>
                <div class="user-info-list">
                    <div>
                        <ul class="contacts-block list-unstyled">

                            <li class="contacts-block__item email">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg> {{balance.customer.phone | phone:'US'}}
                            </li>

                            <li class="contacts-block__item email">
                                <a appCompanyRouterLink="/moving/{{estimate.id}}/emails">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg> {{balance.customer.email}}
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 layout-top-spacing">
        <div class="user-profile info-lead layout-spacing">
            <div class="widget-content widget-content-area">
                <div class=" justify-content-between widget-heading">
                    <h3 class="title-content-area title">
                        Employee
                    </h3>
                </div>

                <div class="text-center user-info" style="margin: 0;">
                    <p class="name">
                        {{balance.employee.name | titlecase}}
                    </p>
                </div>
                <div class="user-info-list">
                    <div>
                        <ul class="contacts-block list-unstyled">

                            <li class="contacts-block__item email">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                                <!-- TODO:
                                {{balance.employee.phone | phone:'US'}} -->
                            </li>

                            <li class="contacts-block__item email">
                                <a appCompanyRouterLink="/moving/{{estimate.id}}/emails">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    <!-- TODO:
                                    {{balance.employee.email}} -->
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 layout-top-spacing">
        <div class="user-profile info-lead layout-spacing">
            <div class="widget-content widget-content-area">
                <div class=" justify-content-between widget-heading">
                    <h3 class="title-content-area title">
                        Balance
                    </h3>
                </div>

                <div class="user-info-list">
                    <div>

                        <ul class="contacts-block list-unstyled margin-balance">

                            <div class="row">
                                <div class="col-md-6">
                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Last Change:</span>
                                        <span class="phone"> {{balance.updated | dateTime}} </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Send On:</span>
                                        <span> {{balance.created | dateTime}} </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Target:</span>
                                        <span> {{balance.target | filterReplace}} </span>
                                    </li>

                                    <li class="contacts-block__item phone"
                                        *ngIf="payment && payment.payments_transactions.length > 0">
                                        <span class="text-label">Authorization ID:</span>
                                        <span> {{payment.payments_transactions[0].code}} </span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="payment && payment.gateway">
                                        <span class="text-label">Payment Gateway:</span>
                                        <span *ngIf="payment"> {{payment.gateway | filterReplace}} </span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="payment && payment.remarks">
                                        <span class="text-label">Remarks:</span>
                                        <span *ngIf="payment"> {{payment.remarks}} </span>
                                    </li>
                                </div>

                                <div class="col-md-6">
                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Amount:</span>
                                        <span> {{balance.amount | currency}} </span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="payment.is_partial_payment && payment.status !== 'PARTIAL_PENDING_REVIEW'">
                                        <span class="text-label">Payment Amount:</span>
                                        <span> {{payment.amount | currency}} </span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="payment.status == 'PARTIAL_PENDING_REVIEW'">
                                        <span class="text-label">Pending Review Amount:</span>
                                        <span> {{payment.amount | currency}} </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Status:</span>
                                        <span class="badge margin-status"
                                            [ngClass]="{'badge-warning': balance.status === 'PROCESSING', 'badge-success': balance.status === 'PAID', 'badge-danger': balance.status === 'PENDING', 'badge-info': (balance.status === 'PENDING_REVIEW' || balance.status === 'PARTIAL_PENDING_REVIEW')}">
                                            {{balance.status | filterReplace}}
                                        </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Employee:</span>
                                        <span> {{balance.employee.name}} </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Updated:</span>
                                        <span> {{balance.updated | dateTime}} </span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="payment && payment.payment_method">
                                        <span class="text-label">Method:</span>
                                        <span> {{payment.payment_method.method |filterReplace}} - <b>{{ payment.payment_method.name |filterReplace }}</b></span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="payment">
                                        <ng-container *ngIf="!payment.date && (payment.status == 'PENDING_REVIEW' || payment.status == 'PARTIAL_PENDING_REVIEW')">
                                            <strong>Transaction Day:</strong>
                                            <app-date-picker [value]="payment.date" [placeholder]="'Not Assigned'"
                                                theme="WIDGET" (onChange)="changeTransactionDay($event)">
                                            </app-date-picker>
                                            <small id="emailHelp1"
                                                class="form-text text-muted mb-4 insert-direction">Please select the day
                                                of the transaction according to the voucher</small>
                                        </ng-container>
                                        <ng-container *ngIf="payment.date">
                                            <strong>Transaction Day: 2</strong>
                                            <span> {{payment.date | dateTime}} </span>
                                        </ng-container>
                                    </li>
                                </div>

                            </div>

                        </ul>

                    </div>
                </div>

                <ng-container *ngIf="payment && payment.vouchers.length > 0">
                    <div class=" justify-content-between widget-heading">
                        <h3 class="title-content-area title">
                            Vouchers
                        </h3>
                    </div>
                    <div class="user-info-list">
                        <ng-container *ngFor="let voucher of payment.vouchers">
                            <div class="size-img" *ngIf="voucher.mime_type == 'application/pdf'">
                                <iframe width="175" height="200"
                                    [appCdnLink]="'/payment-vouchers/' + voucher.display_name" type="application/pdf"
                                    title=""></iframe>
                                <div class="info-created">
                                    {{voucher.created | dateTime}}
                                </div>
                                <div class="info-created">
                                    <button class="btn btn-success" (click)="openImage(voucher.display_name)">
                                        Download
                                    </button>
                                </div>
                            </div>
                            <div (click)="openImage(voucher.display_name)" class="image-voucher"
                                *ngIf="voucher.mime_type != 'application/pdf'">
                                <img class="size-img" width="100%" *ngIf='voucher.display_name'
                                    [appCdnLink]="'/payment-vouchers/' + voucher.display_name" alt="itemImage">
                                <div class="info-created">
                                    {{voucher.created | dateTime}}
                                </div>
                                <div class="info-created">
                                    <button class="btn btn-success" (click)="openImage(voucher.display_name)">
                                        Download
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>


                <ng-container *ngIf="(balance.status == 'PENDING_REVIEW' || payment.status == 'PARTIAL_PENDING_REVIEW') && payments.length > 0">
                    <div class=" justify-content-between widget-heading" appCanUserCompany="ESTIMATE_BALANCE_CONFIRM">
                        <h3 class="title-content-area title">
                            Confirm payment
                        </h3>
                    </div>

                    <div class="buttons-payment" appCanUserCompany="ESTIMATE_BALANCE_CONFIRM">
                        <button class="btn btn-danger" (click)="processPayment(false)">Discard</button>
                        <button class="btn btn-success" (click)="processPayment(true)">Confirm</button>
                    </div>
                </ng-container>

            </div>
        </div>

    </div>


    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 layout-top-spacing">
        <div class="user-profile info-lead layout-spacing">
            <div class="widget-content widget-content-area">
                <div class=" justify-content-between widget-heading">
                    <h3 class="title-content-area title">
                        Estimate
                    </h3>
                </div>

                <div class="text-center user-info" style="margin: 0;">
                    <p class="name cursor-pointer" (click)="openNewTab(estimate.id)">
                        {{estimate.document.code}}
                    </p>
                </div>

                <div class="user-info-list">
                    <div>
                        <ul class="contacts-block list-unstyled">

                            <div class="row">
                                <div class="col-md-6">
                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Total:</span>
                                        <span class="phone"> {{estimate.total | currency}} </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Service:</span>
                                        <span class="phone"> {{estimate.service | filterReplace}} </span>
                                    </li>

                                    <li class="contacts-block__item phone" *ngIf="estimate.booked_date">
                                        <span class="text-label">Booked:</span>
                                        <span class="phone"> {{estimate.booked_date | dateTime}} </span>
                                    </li>
                                </div>

                                <div class="col-md-6">
                                    <li class="contacts-block__item phone">
                                        <span class="text-label">Status:</span>
                                        <span class="phone"> {{estimate.status | filterReplace}}
                                        </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">From:</span>
                                        <span class="phone"> {{estimate.pickup.address.state}}
                                            {{estimate.pickup.address.zip_code}} </span>
                                    </li>

                                    <li class="contacts-block__item phone">
                                        <span class="text-label">To:</span>
                                        <span class="phone"> {{estimate.delivery.address.state}}
                                            {{estimate.delivery.address.zip_code}} </span>
                                    </li>
                                </div>
                            </div>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- lista de attemps -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
        <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area">
                <div class=" justify-content-between widget-heading">
                    <h3 class="title-content-area title">
                        Payment Attempts
                    </h3>
                </div>

                <div class="row padding-table">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="widget-content searchable-container list">
                            <div class="searchable-items list">
                                <div class="items items-header-section">
                                    <div class="item-content">
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4>
                                                Payment Information
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Employee
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Information
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Detail
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Remarks
                                            </h4>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <h4 style="margin-left: 3px;">
                                                Option
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <div class="items cursor-pointer" (click)="selectPayment(i)"
                                    *ngFor="let payment of payments | paginate: {itemsPerPage: 5, currentPage: actualPage}; index as i;">
                                    <div class="item-content information-col" [ngClass]="{'row-selected': i == indexSelected}" role="menu">

                                        <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div class="user-meta-info info">
                                                <p class="user-name">Amount: {{payment.amount | currency}}</p>
                                                <p class="user-work">Date: {{payment.date | date}}</p>
                                                <p class="user-work">Method:
                                                    <span *ngIf="payment.payment_method">
                                                        {{payment.payment_method.method | filterReplace}}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="user-email col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div *ngIf="payment.employee; else noneEmployee">
                                                <p class="user-name">Name: {{payment.employee.name}}</p>
                                                <p class="usr-email-addr">
                                                    <strong>Email: </strong> {{payment.employee.email | lowercase}}
                                                </p>
                                                <p class="usr-email-addr">
                                                    <strong>Phone: </strong> {{payment.employee.phone | phone:'US'}}
                                                </p>
                                            </div>
                                            <ng-template #noneEmployee>
                                                <span class="badge badge-danger">
                                                    None
                                                </span>
                                            </ng-template>
                                        </div>

                                        <div class="user-location col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <p class="usr-location">
                                                <strong>Status:</strong>
                                                <span class="badge"
                                                    [ngClass]="{'badge-warning': payment.status === 'PROCESSING', 'badge-success': payment.status === 'PAID', 'badge-danger': payment.status === 'PENDING', 'badge-info': (payment.status === 'PENDING_REVIEW' || payment.status === 'PARTIAL_PENDING_REVIEW')}">
                                                    {{payment.status | filterReplace}}
                                                </span>
                                            </p>
                                            <p class="usr-location" *ngIf="payment.gateway">
                                                <strong>Gateway: </strong> {{payment.gateway | filterReplace}}
                                            </p>
                                            <p class="user-work">
                                                <strong>Updated: </strong>
                                                <span *ngIf="payment.updated">
                                                    {{payment.updated | date}}
                                                </span>
                                            </p>
                                        </div>

                                        <div class="user-phone col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div *ngIf="payment.payments_transactions.length > 0; else noneTransaction">
                                                <p class="user-name"
                                                    *ngIf="payment.payments_transactions[0].code != '0'">
                                                    <strong>Auth Code: </strong>
                                                    {{payment.payments_transactions[0].code}}</p>
                                                <p class="user-name"
                                                    *ngIf="payment.payments_transactions[0].code == '0'">
                                                    <strong>Reference: </strong>
                                                    {{payment.payments_transactions[0].reference}}</p>
                                                <p class="usr-email-addr">
                                                    <strong>Status: </strong>
                                                    {{payment.payments_transactions[0].status | filterReplace}}
                                                </p>
                                                <p class="usr-email-addr">
                                                    <strong>Card Number: </strong>
                                                    {{payment.payments_transactions[0].card_number}}
                                                </p>
                                                <p *ngIf="payment.payments_transactions[0].card_type"
                                                    class="usr-email-addr">
                                                    <strong>Card Type: </strong>
                                                    {{payment.payments_transactions[0].card_type}}
                                                </p>

                                            </div>

                                            <ng-template #noneTransaction>
                                                <span class="badge badge-danger">
                                                    None
                                                </span>
                                            </ng-template>

                                        </div>

                                        <div class="user-phone col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div
                                                *ngIf="payment.remarks != '' && payment.remarks != null; else noneRemarks">
                                                <p class="user-name">{{payment.remarks}}</p>
                                            </div>
                                            <ng-template #noneRemarks>
                                                <span class="badge badge-danger">
                                                    None
                                                </span>
                                            </ng-template>
                                        </div>

                                        <div class="user-phone col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                            <a *ngIf="payment.vouchers.length > 0" class="btn btn-primary btn-voucher"
                                                id='{{payment.id}}'><i class="fas fa-images"></i>
                                            </a>
                                            <a *ngIf="payment.payments_transactions.length > 1" id='{{payment.id}}'
                                                class="btn btn-primary btn-transaction"><i
                                                    class="fas fa-file-invoice-dollar"></i>
                                            </a>
                                        </div>
                                    </div>

                                    <!-- Panel para ver los vouchers en cada transaccion -->
                                    <div class="panel-voucher-col" style='display: none;'
                                        id='panel-voucher-{{payment.id}}'>
                                        <div class="card-body">
                                            <ng-container *ngFor="let voucher of payment.vouchers">
                                                <div class="size-img" *ngIf="voucher.mime_type == 'application/pdf'">
                                                    <iframe width="175" height="200"
                                                        [appCdnLink]="'/payment-vouchers/' + voucher.display_name"
                                                        type="application/pdf" title=""></iframe>
                                                    <div class="info-created">
                                                        {{voucher.created | dateTime}}
                                                    </div>
                                                    <div class="info-created">
                                                        <button class="btn btn-success"
                                                            (click)="openImage(voucher.display_name)">
                                                            Download
                                                        </button>
                                                    </div>
                                                </div>
                                                <div (click)="openImage(voucher.display_name)" class="image-voucher"
                                                    *ngIf="voucher.mime_type != 'application/pdf'">
                                                    <img class="size-img" width="100%" *ngIf='voucher.display_name'
                                                        [appCdnLink]="'/payment-vouchers/' + voucher.display_name"
                                                        alt="itemImage">
                                                    <div class="info-created">
                                                        {{voucher.created | dateTime}}
                                                    </div>
                                                    <div class="info-created">
                                                        <button class="btn btn-success"
                                                            (click)="openImage(voucher.display_name)">
                                                            Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- Panel para ver las transacciones -->
                                    <div class="panel-transacion-col" style='display: none;'
                                        id='panel-transaction-{{payment.id}}'>
                                        <div class="card-body" *ngIf="payment.payments_transactions.length > 1">
                                            <!-- Recorre las transacciones para listarlas -->
                                            <div class="items cursor-pointer" *ngFor="let transaction of payment.payments_transactions; index as
                                                j;">

                                                <!-- Se lista desde la segunda transaccion en adelante -->
                                                <ng-container *ngIf="j>0">

                                                    <div class="item-content information-col"
                                                        [ngClass]="{'transaction-active': transaction.created}"
                                                        role="menu">

                                                        <div
                                                            class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                            <div class="user-meta-info info">
                                                                <p class="user-name">Amount:
                                                                    {{payment.amount | currency}}
                                                                </p>
                                                                <p class="user-work">Date: {{payment.date | date}}</p>
                                                                <p class="user-work">Method:
                                                                    <span *ngIf="payment.payment_method">
                                                                        {{payment.payment_method.method | filterReplace}}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="user-email col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                            <div *ngIf="payment.employee; else noneEmployee">
                                                                <p class="user-name">Name: {{payment.employee.name}}</p>
                                                                <p class="usr-email-addr">
                                                                    <strong>Email: </strong>
                                                                    {{payment.employee.email | lowercase}}
                                                                </p>
                                                                <p class="usr-email-addr">
                                                                    <strong>Phone: </strong>
                                                                    {{payment.employee.phone | phone:'US'}}
                                                                </p>
                                                            </div>
                                                            <ng-template #noneEmployee>
                                                                <span class="badge badge-danger">
                                                                    None
                                                                </span>
                                                            </ng-template>
                                                        </div>

                                                        <div
                                                            class="user-location col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                            <p class="usr-location">
                                                                <strong>Status: </strong>
                                                                <span *ngIf="payment.status === 'PROCESSING'"
                                                                    class="badge badge-warning">
                                                                    {{payment.status | filterReplace}}
                                                                </span>
                                                                <span
                                                                    *ngIf="payment.status === 'ACCEPTED' || payment.status === 'PAID'"
                                                                    class="badge badge-success">
                                                                    {{payment.status | filterReplace}}
                                                                </span>
                                                                <span
                                                                    *ngIf="payment.status === 'DECLINED' || payment.status === 'VOIDED' || payment.status === 'REFUNDED'"
                                                                    class="badge badge-danger">
                                                                    {{payment.status | filterReplace}}
                                                                </span>
                                                                <span *ngIf="payment.status === 'PENDING_REVIEW'"
                                                                    class="badge badge-info">
                                                                    {{payment.status | filterReplace}}
                                                                </span>
                                                            </p>
                                                            <p class="usr-location" *ngIf="payment.gateway">
                                                                <strong>Gateway: </strong>
                                                                {{payment.gateway | filterReplace}}
                                                            </p>
                                                            <p class="user-work">
                                                                <strong>Updated: </strong>
                                                                <span *ngIf="payment.updated">
                                                                    {{payment.updated | date}}
                                                                </span>
                                                            </p>
                                                        </div>

                                                        <div
                                                            class="user-phone col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                            <p class="user-name" *ngIf="transaction.code != '0'">
                                                                <strong>Auth Code: </strong>
                                                                {{transaction.code}}</p>
                                                            <p class="user-name" *ngIf="transaction.code == '0'">
                                                                <strong>Reference: </strong>
                                                                {{transaction.reference}}</p>
                                                            <p class="usr-email-addr">
                                                                <strong>Status: </strong>
                                                                {{transaction.status | filterReplace}}
                                                            </p>
                                                            <p class="usr-email-addr">
                                                                <strong>Card Number: </strong>
                                                                {{transaction.card_number}}
                                                            </p>
                                                            <p *ngIf="transaction.card_type" class="usr-email-addr">
                                                                <strong>Card Type: </strong>
                                                                {{transaction.card_type}}
                                                            </p>

                                                        </div>

                                                        <div
                                                            class="user-phone col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                                            <div
                                                                *ngIf="payment.remarks != '' && payment.remarks != null; else noneRemarks">
                                                                <p class="user-name">{{payment.remarks}}</p>
                                                            </div>
                                                            <ng-template #noneRemarks>
                                                                <span class="badge badge-danger">
                                                                    None
                                                                </span>
                                                            </ng-template>
                                                        </div>

                                                        <div
                                                            class="user-phone col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">

                                                        </div>
                                                    </div>
                                                </ng-container>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <pagination-controls class="paginating-container pagination-solid"
                                    (pageChange)="actualPage = $event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- fin lista de attemps -->

<!-- lista de comisiones -->
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing"
    *ngIf="balance.target == 'QUALITY_ASSURANCE' || balance.target == 'NEW_BOOKING'">
    <div class="user-profile layout-spacing">
        <div class="widget-content widget-content-area">
            <div class=" justify-content-between widget-heading">
                <div class="row">
                    <div class="col-md-9">
                        <h3 class="title-content-area title">
                            Commissions list
                        </h3>
                    </div>
                    <div class="col-md-3">
                        <a (click)="assignNewCommissions()" class="btn btn-warning">
                            <i _ngcontent-ceo-c19="" class="" data-fa-i2svg="">
                                <svg _ngcontent-ceo-c19="" class="svg-inline--fa fa-plus-square fa-w-14"
                                    aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus-square"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    data-fa-i2svg="">
                                    <path fill="currentColor"
                                        d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z">
                                    </path>
                                </svg>
                            </i> &nbsp; Generate respective commissions
                        </a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <div class="widget-content widget-content-area margin-table">
                        <div class="table-responsive table-display">
                            <table class="table table-bordered mb-4">
                                <thead>
                                    <tr>
                                        <th>Job #</th>
                                        <th>Total Estimate</th>
                                        <th>Payment</th>
                                        <th>Commission</th>
                                        <th>Employee</th>
                                        <th>Status</th>
                                        <th>Remarks</th>
                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let commission of commissionsList; index as i;">
                                        <td>
                                            {{commission.estimate_document.code}}
                                        </td>
                                        <td>
                                            {{commission.estimate_total | currency}}
                                        </td>
                                        <td>{{commission.payment.amount | currency}}</td>
                                        <td>{{commission.value | currency}}</td>
                                        <td>{{commission.employee.name  }}</td>
                                        <td>
                                            <div class="td-content">
                                                <span *ngIf="commission.status === 'PENDING_PAYMENT'"
                                                    class="badge badge-warning">
                                                    Pending Payment
                                                </span>
                                                <span *ngIf="commission.status === 'PAID'"
                                                    class="badge badge-success">
                                                    Paid
                                                </span>
                                                <span *ngIf="commission.status === 'REFUNDED'"
                                                    class="badge badge-danger">
                                                    Refunded
                                                </span>
                                                <span *ngIf="commission.status === 'PENDING_REFUND'"
                                                    class="badge badge-warning">
                                                    Pending Refund
                                                </span>
                                                <span *ngIf="commission.status === 'VOID'"
                                                    class="badge badge-danger">
                                                    Void
                                                </span>
                                            </div>
                                        </td>
                                        <td>{{commission.remarks}}</td>
                                        <td>{{commission.created | dateTime}}</td>
                                        <td>
                                            <a class="btn btn-warning-dark" (click)="deleteCommission(commission.id)"
                                                *ngIf="commission.status==='PENDING_PAYMENT'">
                                                Delete
                                            </a>
                                            <!-- <a appCompanyRouterLink="/advertisers/{{commission.id}}/view" class="btn btn-dark">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-zoom-in">
                                                        <circle cx="11" cy="11" r="8"></circle>
                                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                        <line x1="11" y1="8" x2="11" y2="14"></line>
                                                        <line x1="8" y1="11" x2="14" y2="11"></line>
                                                    </svg>
                                                </a>

                                                <a class="btn btn-warning-dark" (click)="payment(row)"
                                                    *ngIf="commission.status==='PENDING_PAYMENT'">
                                                    Pay
                                                </a>

                                                <a (click)="openModalRemark('void', row)" class="btn btn-dark"
                                                    *ngIf="commission.status==='PENDING_PAYMENT'">
                                                    Void
                                                </a>

                                                <a (click)="openModalRemark('pending_refund', row)" class="btn btn-danger-dark"
                                                    *ngIf="commission.status==='PAID'">
                                                    Pending Refund
                                                </a>

                                                <a (click)="openModalRemark('refund', row)" class="btn btn-danger-dark"
                                                    *ngIf="commission.status==='PENDING_REFUND'">
                                                    Refund
                                                </a> -->

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- fin lista de comisiones -->