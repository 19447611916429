import { Address } from 'src/app/entities/global/address';


export class StorageCompany {
    id: number;
    name: string;
    phone: string;
    address: Address;
    is_custom: boolean;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.address = new Address();
        this.is_custom = false;
        this.created = null;
        this.updated = null;
    }
}