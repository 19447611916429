import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JobInventoryItem } from 'src/app/entities/workspace/job-inventory-item';

@Injectable({
    providedIn: 'root'
})
export class JobInventoryItemService {

    constructor(private http: HttpClient) {

    }

    getById(id: string) {
        return this.http.get<Array<JobInventoryItem>>(environment.api.company + '/jobs/' + id + '/inventory-items').toPromise();
    }

    getItemById(idJob: string, idItem: string) {
        return this.http.get<JobInventoryItem>(environment.api.company + '/jobs/' + idJob + '/inventory-items/' + idItem).toPromise();
    }

    save(dataForm: JobInventoryItem, id: string): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm, id);
        }
        return this.edit(id, dataForm);
    }

    add(dataForm: JobInventoryItem, id: string) {
        return this.http.post(environment.api.company + '/jobs/' + id + '/inventory-items', dataForm).toPromise();
    }

    edit(idJob: string, dataForm: JobInventoryItem) {
        return this.http.put<JobInventoryItem>(environment.api.company + '/jobs/' + idJob +
        '/inventory-items/' + dataForm.id, dataForm).toPromise();
    }

    remove(idJob: string, idItem: string) {
        return this.http.delete<JobInventoryItem>(environment.api.company + '/jobs/' + idJob + '/inventory-items/' + idItem).toPromise();
    }

}
