import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EstimateVersionsService {

    constructor(private http: HttpClient) { }

    revert(id: string, idDocument) {
        return this.http.put(environment.api.company + '/estimates/' + id + '/versions/' + idDocument, {}).toPromise();
    }
}
