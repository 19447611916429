<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-8 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <h3>
                Storage Invoices
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Storage Invoices
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>
       
    </div>
    <!-- header -->
 
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <!-- <div class="row">
                    <div class="col-md-2 col-12">
                        <br>
                        <strong>Companies: </strong>
                        <br>
                        <select name="companyId" [(ngModel)]="companyId" id="companyId" class="form-control">
                            <option value="">All</option>
                            <option *ngFor="let company of companies" value="{{company.id}}">
                                {{company.name | lowercase}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-12">
                        <br>
                        <strong>Employee: </strong>
                        <br>
                        <select name="employee_id" [(ngModel)]="employeeId" id="employee_id" class="form-control">
                            <option value="">All</option>
                            <option *ngFor="let employee of employees" value="{{employee.id}}">
                                {{employee.name | lowercase}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-12">
                        <br>
                        <strong>Type: </strong>
                        <br>
                        <select name="type_storageInvoice_id" [(ngModel)]="typestorageInvoice" id="type_storageInvoice_id" class="form-control">
                            <option value="">All</option>
                            <option *ngFor="let storageInvoice of typestorageInvoices" value="{{storageInvoice}}">
                                <span [ngSwitch]="storageInvoice">
                                    <ng-container *ngSwitchCase="'INCIDENT'">
                                        Incident
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'FEATURE_REQUEST'">
                                        Feature Request
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'QUESTION'">
                                        Question
                                    </ng-container>
                                </span>
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-12">
                        <br>
                        <strong>Status: </strong>
                        <br>
                        <select name="status_id" [(ngModel)]="statusstorageInvoice" id="status_id" class="form-control">
                            <option value="">All</option>
                            <option *ngFor="let status of statusstorageInvoices" value="{{status}}">
                                {{status | lowercase}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-12">
                        <br>
                        <strong>Priority: </strong>
                        <br>
                        <select name="prority_id" [(ngModel)]="prioritystorageInvoice" id="prority_id" class="form-control">
                            <option value="">All</option>
                            <option *ngFor="let priority of prioritystorageInvoices" value="{{priority}}">
                                {{priority | lowercase}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-12">
                        <br>
                        <br>
                        <button class=" btn btn-warning" (click)="getFilter()"> Filter </button>
                    </div>
                </div> -->
                <br>
                <br>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Job #</th>
                                <th>Document #</th>
                                <th>Customer</th>
                                <th>Company</th>
                                <th>Total Amount Due</th>
                                <th>Payment Method</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let storageInvoice of storageInvoices | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td class="text-center">{{storageInvoice.estimate.document.code}}</td>
                                <td>{{storageInvoice.estimate.document.code}}</td>
                                <td>{{storageInvoice.customer.name}} {{storageInvoice.customer.last_name}}</td>
                                <td>{{storageInvoice.company.name}}</td>
                                <td>{{storageInvoice.total_amount_due}}</td>
                                <td>{{storageInvoice.payment_method | date}}</td>
                                <td>{{storageInvoice.created | date}}</td>
                                <td>{{storageInvoice.updated | date}}</td>
                                <td>
                                    <button class="btn btn-info" (click)="exportPdf(storageInvoice.id, storageInvoice.estimate.id)"
                                        appCanUserCompany="ESTIMATE_SET_FOLLOW_UPS">
                                        <i class="fa-solid fa-file"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->
</div>