import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { infoEstimateLabelQrView } from 'src/app/entities/info-estimate-label-qr-view';
import { JobItemQrCode } from 'src/app/entities/job-item-qr-code';
import { JobItemQrCodesService } from 'src/app/services/companies/job-item-qr-codes.service';
import { HelperService } from 'src/app/services/helper.service';
declare const html2canvas;
declare const jQuery;
declare const QRCode;
declare const jsPDF;
declare const swal;

@Component({
  selector: 'app-moving-labels',
  templateUrl: './moving-labels.component.html',
  styleUrls: ['./moving-labels.component.scss']
})
export class MovingLabelsComponent implements OnInit {

  @ViewChild('addQr') addQr: ElementRef;

  public labelStartNumber: number;
  public quantity: number;
  public labelColor: string
  public qrLabels: any[];
  public printEnable: boolean = false;
  public dataForm: JobItemQrCode;
  public estimateId: string;
  public itemsQrAssigned: Array<JobItemQrCode>;
  public itemsQrNotAssigned: JobItemQrCode[];
  public infoEstimateLabelQr: infoEstimateLabelQrView;
  public labelColumns: boolean; // true = Two Columns | false = Three Columns


  constructor(
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private jobItemQrCodesService: JobItemQrCodesService,
  ) {
    this.labelColor = '#030303';
    this.qrLabels = [];
    this.dataForm = new JobItemQrCode();
    this.estimateId = '';
    this.itemsQrAssigned = [];
    this.itemsQrNotAssigned = [];
    this.infoEstimateLabelQr = new infoEstimateLabelQrView();
    this.labelColumns = false;
  }

  ngOnInit(): void {
    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.estimateId = params.id;
        this.load();
        this.getInfoEstimateLabelQr();
      }
    });
  }

  load() {
    const THIS = this;
    this.helperService.showLoadingMxpro360();
    this.jobItemQrCodesService
      .getAll(this.estimateId)
      .then(response => {
        for (const iterator of response) {
          if (iterator.job_inventory_item_id === null) {
            setTimeout(() => {
              this.helperService.generateQRcode(85, 82, iterator.code, "qrcode" + iterator.id);
            }, 500);
            this.itemsQrNotAssigned.push(iterator);
          }
          if (iterator.job_inventory_item_id !== null) {
            setTimeout(() => {
              this.helperService.generateQRcode(85, 82, iterator.code, "qrcode" + iterator.id);
            }, 500);
            this.itemsQrAssigned.push(iterator);
          }
        }
      })
      .catch((error) => {

        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Trae la informacion necesaria para el sticker del qr
   */
  getInfoEstimateLabelQr() {
    this.helperService.showLoadingMxpro360();
    this.helperService.getInfoEstimateLabelQr(this.estimateId)
      .then(response => {
        this.infoEstimateLabelQr = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * genera los divs de los label con la info necesaria
   */
  generateLabels() {
    this.helperService.showLoadingMxpro360();
    this.jobItemQrCodesService
      .save(this.dataForm, this.estimateId, this.quantity)
      .then(response => {
        window.location.reload();
      })
      .catch((error) => {

        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  openModalAddQr() {
    jQuery(this.addQr.nativeElement).modal('show');
  }

  /**
   * genera segmentos de codigos para un uuid
   * @param parts cantidad de bloques que deseo que tenga el uuid
   */
  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  imprSelec() {
    jQuery("#print").css({ "width":"1200px"});
    jQuery("#page-header").css({ "height":"16mm"});
    jQuery(".print-estimate-label").css({ "margin":"0 0 0 4mm"});
    jQuery("#title-unassigned-labels").css({ "display":"none"});
    jQuery(".item-label").css({ "border":"2px dashed transparent"});
    jQuery(".print-estimate-label.two-columns").css({ "margin":"6mm 0 0 0"});
    html2canvas([document.getElementById('print')], {
      onrendered: (canvas) => {
        let img = canvas.toDataURL('image/png'); //o por 'image/jpeg' 
        //display 64bit imag
        let content = ('<img src="' + img + '"/>');
        document.body.innerHTML = content;
        window.addEventListener("afterprint", (event) => {
          location.reload()
        });
        setTimeout(() => {
          window.print();
        }, 2000);
      }
    });
  }

  getPDF() {

    let HTML_Width = jQuery("#canvas_div_pdf").width();
    let HTML_Height = jQuery("#canvas_div_pdf").height();
    let top_left_margin = 15;
    let PDF_Width = HTML_Width + (top_left_margin * 2);
    let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    let canvas_image_width = HTML_Width;
    let canvas_image_height = HTML_Height;

    let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;


    html2canvas(jQuery("#canvas_div_pdf")[0], { allowTaint: true }).then(function (canvas) {
      canvas.getContext('2d');

      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);


      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }

      pdf.save("labels.pdf");
    });
  };

  removeQr() {

    swal({
        title: 'Are you sure?',
        text: 'You want to remove the labels from this job?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
          if (result.value) {
            this.helperService.showLoadingMxpro360();
            this.jobItemQrCodesService
              .remove(this.estimateId)
              .then(response => {
                window.location.reload();
              })
              .catch((error) => {

                console.error('error', error);
              })
              .finally(() => {
                this.helperService.hideLoadingMxpro360();
              });
            }
          });
        }

}
