import { Component, OnInit } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { ReportQAView } from 'src/app/entities/companies/report-qa-view';
import { ReportQAService } from 'src/app/services/companies/report-qa.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery: any;
@Component({
  selector: 'app-report-qa',
  templateUrl: './report-qa.component.html',
  styleUrls: ['./report-qa.component.scss']
})
export class ReportQAComponent implements OnInit {

  private granTotal: number;
  public betweenFilter: BetweenDates;
  public dataReport: ReportQAView[];
  public totalAverage: number;
  public totalDepositIncrease: number;
  public totalIncrease: number;
  public totalQAs: number;

  constructor(
    private reportQAService: ReportQAService,
    private helperService: HelperService
  ) {
    this.betweenFilter = new BetweenDates();
    this.dataReport = [];
    this.granTotal = 0;
    this.totalAverage = 0;
    this.totalDepositIncrease = 0;
    this.totalIncrease = 0;
    this.totalQAs = 0;
  }

  ngOnInit(): void {
    this.loadData();
  }

  search() {
    this.loadData();
  }

  getFilterDates(dataReturn: BetweenDates) {
      this.betweenFilter = dataReturn;
  }

  loadData() {
    if (this.betweenFilter.dateEnd == null) {
      this.loadData();
    } else {
      this.helperService.showLoadingMxpro360();
      this.reportQAService.get(this.betweenFilter)
      .then((dataReport) => {
      this.helperService.hideLoadingMxpro360();
        this.dataReport = dataReport;
        this.calculateTotals();
      });
    }
  }

  calculateTotals() {
    this.totalQAs = 0;
    this.totalIncrease = 0;
    this.totalDepositIncrease = 0;
    this.granTotal = 0;
    this.totalAverage = 0;
    for(let row of this.dataReport) {
      this.totalQAs += row.did;
      this.totalIncrease += row.increase;
      this.totalDepositIncrease += row.deposit_increase;
      this.granTotal += row.gran_total;
    }
    this.totalAverage = this.totalIncrease * 100 / this.granTotal;
  }

}
