import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BoardJob } from 'src/app/entities/workspace/board-job';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class BoardJobsService {

    constructor(
        private http: HttpClient
    ) {

    }

    /**
     * Permite obtener todos los board jobs
     * @param job_id 
     * @returns 
     */
    getAll(): Promise<BoardJob> {
        return this.http.get<BoardJob>(environment.api.company + '/board-jobs').toPromise();
    }

    /**
     * Permite obtener un boardjob por el identificador
     */
    getById(board_job_id: string) {
        return this.http.get<BoardJob>(environment.api.company + '/board-jobs/' + board_job_id).toPromise();
    }

    /**
     * Permite obtener el boardJob de un trabajo
     * 
     * @param job_id 
     * @returns 
     */
    getByJobId(job_id: string): Promise<BoardJob> {
        return this.http.get<BoardJob>(environment.api.company + '/jobs/' + job_id + '/board-jobs').toPromise();
    }

    /**
     * Permite guardar los datos de un board job
     * 
     * @param dataForm 
     * @returns 
     */
    save(dataForm: BoardJob, jobId: string) {
        const data:any = {...dataForm};
        data.job_id = jobId;
        return this.http.post(environment.api.company + '/board-jobs', data).toPromise();
    }

    /**
     * permite agregar un board Job
     * @param dataForm 
     * @returns 
     */
    add(dataForm: BoardJob) {
        return this.http.post(environment.api.company + '/board-jobs', dataForm).toPromise();
    }

    /**
     * Permite actualizar un board job
     * @param dataForm 
     * @returns 
     */
    edit(dataForm: BoardJob) {
        return this.http.put(environment.api.company + '/board-jobs/' + dataForm.id, dataForm).toPromise();
    }

    createCarrierCandidate(dataForm, id){
        return this.http.post(environment.api.company + '/board-jobs/' + id + '/carrier-candidates', dataForm).toPromise();
    }

    deleteCarrierCandidate(id){
        return this.http.delete(environment.api.company + '/carrier-candidates/' + id).toPromise();
    }

}
