import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { Employee } from 'src/app/entities/workspace/employee';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';

@Component({
  selector: 'app-payroll-clock-users-form',
  templateUrl: './payroll-clock-users-form.component.html',
  styleUrls: ['./payroll-clock-users-form.component.scss']
})
export class PayrollClockUsersFormComponent implements OnInit {

  public employees: Array<Employee>;
  public clockEmployees: ClockEmployees;

  constructor(
    public helperService: HelperService,
    public employeesService: EmployeesService,
    private payrollTimeClockService: PayrollTimeClockService,
    private currentRoute: ActivatedRoute,
  ) {
    this.employees = [];
    this.clockEmployees = new ClockEmployees();
  }

  ngOnInit(): void {
    this.loadEmployees();
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.loadClockUsers(params.id);
      }
    });
  }

  loadClockUsers(id: string): void {
    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService
      .getById(id)
      .then(response => {
        this.clockEmployees = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  save() {
    this.helperService.showLoadingMxpro360();
    this.payrollTimeClockService.add(this.clockEmployees)
      .then((response) => {
        this.clockEmployees = new ClockEmployees();
        this.helperService.goToCompanyRouterLink('/payroll-clock-users');
      })
      .catch()
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadEmployees() {
    this.helperService.showLoadingMxpro360();
    this.employeesService
      .getAll()
      .then((response) => {
        this.employees = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
