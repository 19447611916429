import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DispatchContacts } from 'src/app/entities/workspace/dispatch-contacts';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispatchContactsService {
  constructor(private http: HttpClient) { }

  getAll(): Promise<Array<DispatchContacts>> {
      return this.http.get<Array<DispatchContacts>>(environment.api.workspace + '/dispatch-contacts').toPromise();
  }

  getById(id: string) {
      return this.http.get<DispatchContacts>(environment.api.workspace + '/dispatch-contacts/' + id).toPromise();
  }

  save(dataForm: DispatchContacts): Promise<any> {
      if (dataForm.id === null) {
          return this.add(dataForm);
      }
      return this.edit(dataForm);
  }

  add(dataForm: DispatchContacts) {
      return this.http.post(environment.api.workspace + '/dispatch-contacts', dataForm).toPromise();
  }

  edit(dataForm: DispatchContacts) {
      return this.http.put(environment.api.workspace + '/dispatch-contacts/' + dataForm.id, dataForm).toPromise();
  }

  remove(id: string) {
      return this.http.delete(environment.api.workspace + '/dispatch-contacts/' + id).toPromise();
  }


}
