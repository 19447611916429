<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New 
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Supplier
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/suppliers">Suppliers</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                </div>


            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">

            <div class="widget-header">
                <h4>
                    Form
                </h4>
            </div>

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">
                        <div class="col-6">

                            <div class="form-group mb-4">
                                <label class="control-label">Name:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier name.</small>
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Email:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">@</span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.email" name="wd_email" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier Email.</small>
                            </div>

                            
                            <div class="form-group mb-4">
                                <label class="control-label">Phone:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.phone" name="wd_phone" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier Number Phone.</small>
                            </div>

                            <div class="form-group mb-4">
                                <label class="control-label">Country:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.address.country" name="wd_address_country" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier Country.</small>
                            </div>


                            <div class="form-group mb-4">
                                <label class="control-label">State:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.address.state" name="wd_address_state" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier State.</small>
                            </div>


                        </div>
                        <div class="col-6">


                            <div class="form-group mb-4">
                                <label class="control-label">City:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.address.city" name="wd_address_city" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier City.</small>
                            </div>


                            <div class="form-group mb-4">
                                <label class="control-label">Street:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.address.city" name="wd_address_city" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier Street.</small>
                            </div>

                            
                            <div class="form-group mb-4">
                                <label class="control-label">Zip Code:</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.address.zip_code" name="wd_address_zip_code" class="form-control">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Supplier Street.</small>
                            </div>

                        </div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-primary ml-1 mt-3" type="submit">
                            <i class="far fa-save" style="height: 24px;"></i> &nbsp; Submit
                        </button>
                    </div>

                </form>


            </div>
        </div>


    </div>
</div>