<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Driver Invoices
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Driver Invoices</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <form class="form-inline search-full form-inline search" role="search" (ngSubmit)="doSearchJob()">
                        <div class="search-bar">
                            <input type="text" class="form-control search-form-control  ml-lg-auto" placeholder="Search..."
                                name="search_job" [(ngModel)]="searchJob">
                        </div>
                    </form>
                </div>
            </form>
        </div>


        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <a appWorkspaceRouterLink="/driver-invoices/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Driver
                </a>
            </div>
        </div>
    </div>

      <!-- filter -->
      <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12 align-self-center">
            <app-date-filter class="estimates-date-filter" (onChange)="getFilterDates($event)"></app-date-filter>






            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-12 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Company</label>
                    <select name="company" id="company" [(ngModel)]="company" class="form-control">
                        <option value="">All Companies</option>
                        <option *ngFor="let company of companies" [value]="company.id">{{company.name}}</option>
                        
                    </select>
                </div>  
                <div class="col-12 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>From State</label>
                    <select name="fromStates" id="fromStates" [(ngModel)]="fromStates" 
                        class="form-control from-state from"  multiple>
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>  
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>To State</label>
                    <select name="toStates" id="toStates" [(ngModel)]="toStates" class="form-control to-state to" multiple>
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>
                           
            </div>


            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Delivered</label>
                    <select name="delivered" id="delivered" [(ngModel)]="delivered" class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="PENDING">Pending</option>
                        <option value="DELIVERED">Delivered</option>
                    </select>
                </div>
                <!-- workspace.employee.job_position -->    
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Paid</label>
                    <select [(ngModel)]="paid" name="paid" class="form-control">
                        <option value="">All</option>
                        <option value="false">Pending</option>
                        <option value="true">is Paid</option>
                    </select>
                </div>                
                <div class="col-6 col-lg-4 col-xl-3 pl-0 mt-3">
                    <label>Filter Date For</label>
                    <select name="filterDateFor" id="filterDateFor" [(ngModel)]="filterDateFor"
                        class="form-control">
                        <option value="created_dated" selected="selected">Created Date</option>
                        <option value="load_date">Load Date</option>
                    </select>
                </div>                 
            </div>

            <div class="row col-lg-12 col-md-12 block-filter mx-5 mb-4">
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-2 text-right">
                    <button class="btn btn-secondary btn-filter" (click)="getFilter()">
                        Search
                    </button>
                </div>                
                <div class="col-2"></div>
            </div>

        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Date Created</th>
                                <th>Load Date</th>
                                <th>COMPANY</th>
                                <th># DRIVER INVOICE</th>
                                <th>From States</th>
                                <th>To States</th>
                                <th>TOTAL</th>
                                <th>DUE FROM AGENT</th>
                                <th>Is Delivered</th>
                                <th>Is Paid</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | search:filterSearch  | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td>{{row.date | date}}</td>
                                <td>{{row.load_date | date}}</td>
                                <td *ngIf="row['company']">{{row['company']['name']}}</td>
                                <td *ngIf="!row['company']">No company</td>
                                <td *ngIf="row.document">{{row.document.code}}</td>
                                <td *ngIf="!row.document">No Code</td>
                                
                                
                                <td>
                                    <ng-container *ngFor="let job of filterSubItems(row.items)">
                                        {{job.from }}
                                    </ng-container>
                                </td>

                                <td>
                                    <ng-container *ngFor="let job of filterSubItems(row.items)">
                                        {{job.to}}
                                    </ng-container>
                                </td>


                                <td>{{row.total | currency}}</td>
                                <td>{{ (row.total - row.total_cf_charges).toFixed(2) | currency}}</td>
                                <td *ngIf="!validateDelivery(row.items)" class="text-center"><input type="checkbox" name="" id="" disabled></td>
                                <td *ngIf="validateDelivery(row.items)" class="text-center"><input type="checkbox" name="" id="" checked disabled></td>
                                <td *ngIf="!row.is_paid" class="text-center"><input type="checkbox" name="" id="" disabled></td>
                                <td *ngIf="row.is_paid" class="text-center"><input type="checkbox" name="" id="" checked disabled></td>
                                <td>
                                    <a appWorkspaceRouterLink="/driver-invoices/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fas fa-edit"></i>
                                    </a>
                                    <a appWorkspaceRouterLink="/driver-invoices/{{row.id}}/view" class="btn btn-info">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>

                </div>
            </div>
        </div>
    </div>
    <!-- body -->
</div>
