import { Address } from 'src/app/entities/global/address';

import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit,
    Output, SimpleChanges, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Geospatial } from 'src/app/entities/global/geospatial';

declare var google;

@Component({
    selector: 'app-search-address',
    templateUrl: './search-address.component.html',
    styleUrls: ['./search-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchAddressComponent),
            multi: true
        }
    ]

})
export class SearchAddressComponent implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {

    @Input() addressFull: boolean;
    @Input() fullAddress: string;
    @Input() isNotAutoTransport: boolean;
    @Input() label: string;
    @Input() oldAddress: string;
    @Input() useCustomAddress: boolean;
    @Output() onChange: EventEmitter<any>;
    @ViewChild('childDiv', { static: false }) childDiv!: ElementRef;
    @ViewChild('inputSearch') inputSearch: ElementRef;
    @ViewChild('parentDiv', { static: false }) parentDiv!: ElementRef;
    public address: Address;
    public childClass = '';
    // Variable usada para activar o desactivar el campo de aparment number
    public disableAparmentNumber: boolean;
    // Variable usada para activar o desactivar el campo de floor
    public disableFloor: boolean;
    // Variable usada para activar o desactivar el campo de level
    public disableLevel: boolean;
    // Variable usada para activar o desactivar el campo de unit number
    public disableUnitNumber: boolean;
    // Variable para indicar que se debe ingresar la direccion
    public insertAddress: boolean;
    public isGoogle: boolean;
    // Variable usada para los tipos de niveles segun la propiedad
    public optionsLevelType: Array<string>;
    // Variable usada los tipos de propiedades
    public optionsPropertyType: Array<string>;


    constructor(
        private cdr: ChangeDetectorRef
    ) {
        this.address = new Address();
        this.addressFull = false;
        this.disableLevel = false;
        this.insertAddress = true;
        this.isGoogle = false;
        this.isNotAutoTransport = false;
        this.label = "Search";
        this.onChange = new EventEmitter();
        // Se inicializan las opciones
        this.optionsPropertyType = [
            'HOUSE',
            'TOWNHOUSE',
            'APARTMENT',
            'CONDO',
            'GARAGE',
            'MOBILE_HOME',
            'OFFICE',
            'STORAGE',
            'WAREHOUSE',
            'RESIDENCE',
            'BUSINESS',
            'DEALERSHIP',
            'PORT',
            'TERMINAL',
            'AUCTION',
            'TRAILER_PARK'
        ];
        // Se inicializan los niveles
        this.optionsLevelType = [
            'GROUND',
            'ELEVATOR',
            'STAIRS',
            'BASEMENT'
        ];
        this.useCustomAddress = true;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {

        // Ejecutar la detección de cambios para asegurar que los elementos estén disponibles
        this.cdr.detectChanges();
        this.adjustChildDivClass();

        const resizeObserver = new ResizeObserver(() => {
            this.adjustChildDivClass();
        });

        resizeObserver.observe(this.parentDiv.nativeElement);



        if (this.isNotAutoTransport === null) {
            this.isNotAutoTransport = true;
        }

        const that = this;
        const input = this.inputSearch.nativeElement;
        setTimeout(() => {
            const autocomplete = new google.maps.places.Autocomplete(input);

            this.insertAddress = true;

            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                const place = autocomplete.getPlace();
                that.fillInAddress(place);
                // retorna el valor asignado
            });
        }, 1000);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.oldAddress) {
            this.address = changes.oldAddress.currentValue;
            this.isGoogle = this.address.manually_typed;
        }
        if (changes.isNotAutoTransport) {
            this.isNotAutoTransport = changes.isNotAutoTransport.currentValue;
        }
    }

    private change(value: string) { }

    async fillInAddress(place) {
        // this.address = new Address();
        this.address.street = null
        this.address.city = null
        this.address.country = null
        this.address.zip_code = null
        this.address.state = null
        this.address.full_address = null
        this.address.geospatial = new Geospatial();

        // Se agregan las longitudes y latitudes de la direccion buscada
        if (place.geometry.location.lat() && place.geometry.location.lng()) {
            this.address.geospatial.coordinates.push(place.geometry.location.lat(), place.geometry.location.lng());
        }

        for (const component of place.address_components) {
            const addressType = component.types[0];

            if (addressType === 'street_number') {
                this.address.street = component.short_name;
            }

            if (addressType === 'route') {
                this.address.street = this.address.street ? this.address.street + ' ' + component.long_name : component.long_name;
            }

            if (addressType === 'locality') {
                this.address.city = component.long_name;
            }

            if (addressType === 'country') {
                let country = component.long_name;
                if (component.long_name == 'Estados Unidos') {
                    country = 'United States';
                }
                this.address.country = country;
            }

            if (addressType === 'postal_code') {
                this.address.zip_code = component.short_name;
            }

            if (addressType === 'administrative_area_level_1') {
                this.address.state = component.short_name;
            }


        }

        this.address.manually_typed = this.isGoogle;

        this.insertAddress = false;

        this.address.full_address = place.formatted_address;
        // Se verifica si no existe un zipcode, para realizarse la busqueda del zipcode por coordenadas
        if (this.address.zip_code === null && this.address.geospatial.coordinates.length > 1) {
            // Se asigna el zipcode
            this.address.zip_code = await this.searchZipCodeByCoordinates(this.address.geospatial.coordinates[1],
                this.address.geospatial.coordinates[0]);
        }

        this.onChange.emit(this.address);
    }


    setAddress() {
        if (this.address.zip_code && this.address.city && this.address.street && this.address.state) {
            this.insertAddress = false;
            this.address.zip_code = this.address.zip_code.toString();


            // Moving address - manually typed
            this.address.manually_typed = this.isGoogle;

            this.onChange.emit(this.address);
        } else {
            this.insertAddress = true;
        }
    }

    changeInfo() {
        this.disableOptions();
        this.onChange.emit(this.address);
    }

    doInput() {

        // Moving address - manually typed
        this.address.manually_typed = this.isGoogle;
        this.change(this.fullAddress);
    }

    writeValue(value: any) {
        this.fullAddress = value;
    }

    registerOnChange(change: (value: string) => void) {
        this.change = change;
    }
    registerOnTouched() { }

    /**
     * Metodo que oculta opciones del address en la vista 
     */
    private disableOptions(): void {
        this.disableLevel = false;
        this.disableAparmentNumber = false;
        this.disableFloor = false;
        this.disableUnitNumber = true;

        if (this.address.property_type === 'MOBILE_HOME' || this.address.property_type === 'WAREHOUSE'
            || this.address.property_type === 'PORT' || this.address.property_type === 'AUCTION') {
            this.disableLevel = true;
        }

        if (this.address.property_type !== 'APARTMENT') {
            this.disableAparmentNumber = true;
        }

        if (this.address.property_type === 'AUCTION' || this.address.property_type === 'MOBILE_HOME'
            || this.address.property_type === 'PORT') {
            this.disableFloor = true;
        }

        if (this.address.property_type === 'STORAGE') {
            this.disableUnitNumber = false;
        }
    }

    /**
     * Metodo que busca el zipcode por longitud y latitud
     */
    private searchZipCodeByCoordinates(latitude: number, longitude: number): Promise<string> {
        return new Promise((resolve) => {
            const latlng = new google.maps.LatLng(longitude, latitude);
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ latLng: latlng }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        for (let j = 0; j < results[0].address_components.length; j++) {
                            if (results[0].address_components[j].types[0] === 'postal_code') {
                                resolve(results[0].address_components[j].short_name);
                            }
                        }
                    }
                } else {
                    resolve(null);
                }
            });
        });
    }


    private adjustChildDivClass() {
        if (!this.parentDiv || !this.parentDiv.nativeElement) {
            console.warn('Parent div not available');
            return;
        }

        const parentWidth = this.parentDiv.nativeElement.clientWidth;
        if (parentWidth < 576) {
            this.childClass = 'child-div small';
        } else if (parentWidth > 577) {
            this.childClass = 'child-div medium';
        } /* else if (parentWidth < 992) {
          this.childClass = 'child-div large';
        } else {
          this.childClass = 'child-div extra-large';
        } */
    }

}
