<div class="form-container">
    <div class="form-form padd-form">
        <div class="form-form-wrap">
            <div class="form-container p-0">
                <div class="form-content text-center">

                    <!-- <h2>Log In to</h2> -->
                       <!--  <span class="brand-name">Moverxpro360</span> -->
                    <div class="text-center">
                        <img src="assets/img/logo_moverxpro360.png" alt="">
                    </div>

                    <ng-container *ngIf="section =='FORM'" >

                        <br>
                        <br>
                        <h3>
                            You have Been Unsubscribed
                        </h3>
<br>
                        <h4>
                            you have been unsuscribed from these emails. <br>
                            please take a moment and let  us know why you unsubscribed
                        </h4>
                        <br>
                        <form (ngSubmit)="unsuscribed()" class="text-left">
                            <div class="form" >
                                <div class="form-group ">
                                    <div class="custom-control custom-radio classic-radio-info">
                                        <input type="radio" id="hRadio1" name="classicRadio" class="custom-control-input"
                                            [(ngModel)]="dataForm.reason" value="PRICE_IS_TOO_HIGH">
                                        <label class="custom-control-label" for="hRadio1">Price is to high </label>
                                    </div>
                                </div>
                                <div class="form-group ">
                                    <div class="custom-control custom-radio classic-radio-info">
                                        <input type="radio" id="hRadio2" name="classicRadio" class="custom-control-input"
                                            [(ngModel)]="dataForm.reason" value="I_BOOKED_WITH_SOMEBODY_ELSE">
                                        <label class="custom-control-label" for="hRadio2">I booked with somebody else</label>
                                    </div>
                                </div>

                                <div class="form-group ">
                                    <div class="custom-control custom-radio classic-radio-info">
                                        <input type="radio" id="hRadio3" name="classicRadio" class="custom-control-input"
                                            [(ngModel)]="dataForm.reason" value="I_DO_NOT_NEED_MOVING_SERVICES">
                                        <label class="custom-control-label" for="hRadio3">I do not need moving services</label>
                                    </div>
                                </div>

                                <div class="form-group ">
                                    <div class="custom-control custom-radio classic-radio-info">
                                        <input type="radio" id="hRadio4" name="classicRadio" class="custom-control-input"
                                            [(ngModel)]="dataForm.reason" value="I_DECIDED_TO_MOVE_MYSELF">
                                        <label class="custom-control-label" for="hRadio4">I decided to move myself</label>
                                    </div>
                                </div>

                                <div class="form-group ">
                                    <div class="custom-control custom-radio classic-radio-info">
                                        <input type="radio" id="hRadio5" name="classicRadio" class="custom-control-input"
                                            [(ngModel)]="dataForm.reason" value="OTHER">
                                        <label class="custom-control-label" for="hRadio5">Other</label>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="dataForm.reason == 'OTHER'">
                                    <input type="text" name="other" class="form-control"
                                    [(ngModel)]="dataForm.comments" style="margin-left: 50px;">
                                </div>

                                <div class="form-group ">
                                    <div class="custom-control custom-radio classic-radio-info">
                                        <input type="radio" id="hRadio6" name="classicRadio" class="custom-control-input"
                                            [(ngModel)]="dataForm.reason" value="I_UNSUBSCRIBED_BY_ACCIDENT__PLEASE_RESUBSCRIBE_ME">
                                        <label class="custom-control-label" for="hRadio6">I unsubscribed by accident please resubscribe me</label>
                                    </div>
                                </div>


                                <div class="field-wrapper text-center mt-4">
                                    <button class="btn btn-warning btn-login" type="submit" >
                                        Continue
                                    </button>
                                </div>

                            </div>
                        </form>

                        <p class="signup-link text-center">
                            New Here?
                            <a routerLink="/register-account-company">
                                Create an account
                            </a>
                        </p>

                    </ng-container>



                    <ng-container *ngIf="section == 'READY'">

                        <div class="my-5 container d-flex justify-content-center align-items-center">
                            <div class="position-relative">
                                <div class="p-2 text-center">
                                    <h6 class="title-code">You have been unsubscribed<br> Successfully</h6>

                                    <div class="mt-4">
                                        <button class="btn btn-danger px-4 validate" (click)="closeTab()">Exit</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <p class="terms-conditions text-center">© 2023 All Rights Reserved
                        <a href="https://www.moverxpro360.com">Moverxpro360</a> is a product of <a href="https://moverxpro360.com" target="_blank">MoverXpro 360 LLC</a> <br>
                        <!-- <a href="javascript:void(0);">Cookie Preferences</a>, -->
                        <a href="https://moverxpro360.com/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://moverxpro360.com/term-and-conditions" target="_blank">Term and Conditions</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="form-image">
        <div class="l-image">
        </div>
    </div>
</div>

