import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-own-performance',
  templateUrl: './own-performance.component.html',
  styleUrls: ['./own-performance.component.scss']
})
export class OwnPerformanceComponent implements OnInit {
    jobsOwnPerformance: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) { 
    this.jobsOwnPerformance = {
        "total_quotes": 0,
        "emails_sent": 0,
        "quotes_sent": 0,
        "quotes_booked": 0,
        "amount_quotes_booked": 0,
        "customer_payments": 0
    };
  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadOwnPerformanceCompany();
    } else {
        this.loadOwnPerformanceWorkspace();
    }
  }

  
  loadOwnPerformanceWorkspace(){
    this.dashboardWorkspaceService
        .getOwnPerformance()
        .then((response) => {
            this.jobsOwnPerformance = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadOwnPerformanceCompany(){
    this.dashboardComopanyService
        .getOwnPerformance()
        .then((response) => {
            this.jobsOwnPerformance = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


}
