<div class="layout-spacing layout-top-spacing">

    <!-- HEADER -->
    <div class="row">
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <h3>
                Employees
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Employees
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch" (ngModelChange)="actualPage=1"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/employees/add" class="btn btn-save" *ngIf="!maxAccountsDone">
                    <i class="fa-solid fa-user-plus"></i> &nbsp; Add Employee
                </a>
                <button (click)="showMaxAccountsMessage()" class="btn btn-save disabled" *ngIf="maxAccountsDone">
                    <i class="fa-solid fa-user-plus"></i> &nbsp; Add Employee
                </button>
            </div>
        </div>

    </div>
    <!-- END HEADER -->

    <!-- BODY -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Account</th>
                                <th class="employee-status">Status</th>
                                <th>Company Emails</th>
                                <th class="employee-connection">Connection</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let employee of employees |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td>
                                    {{employee.name | titlecase}}
                                </td>
                                <td>
                                    <span *ngIf="employee.account">
                                        {{employee.account.email}}
                                    </span>
                                    <span *ngIf="!employee.account" class="badge badge-danger">
                                        Unallocated
                                    </span>
                                </td>
                                <td class="employee-status">
                                    <span *ngIf="employee.status == 'ACTIVE'" class="badge badge-success">
                                        ACTIVE 
                                    </span>
                                    <span *ngIf="employee.status == 'DISABLED'" class="badge badge-danger">
                                        DISABLED 
                                    </span>
                                </td>
                                <td class="employee-email">
                                    <span *ngIf="!employee.employeeCompanyPermissions || employee.employeeCompanyPermissions?.length == 0" class="badge badge-danger">
                                        Not Company Assigned 
                                    </span>
                                    <ng-container *ngIf="employee.employeeCompanyPermissions && employee.employeeCompanyPermissions?.length > 0">
                                        <div class="company-email" *ngFor="let employeeCompanyPermission of employee.employeeCompanyPermissions">
                                            <div class="company-name">{{companyName(employeeCompanyPermission.company_id)}}</div>
                                            <div>{{employeeCompanyPermission.email.address}}</div>
                                        </div>
                                    </ng-container>
                                </td>      
                                <td class="employee-connection">
                                    <div class="connection">

                                        <!-- STATUS - ONLINE -->
                                        <div class="status online" *ngIf="employee.online"> <!-- ngIf - Condicionar Online -->
                                            <div class="sonar-wrapper">
                                                <div class="sonar-emitter">
                                                    <div class="sonar-wave sonar-wave1"></div>
                                                    <div class="sonar-wave sonar-wave2"></div>
                                                    <div class="sonar-wave sonar-wave3"></div>
                                                    <div class="sonar-wave sonar-wave4"></div>
                                                </div>
                                            </div>
                                            <span class="">
                                                ONLINE
                                            </span>           
                                        </div>

                                        <!-- STATUS - OFFLINE -->
                                        <div *ngIf="!employee.online"> <!-- ngIf - Condicionar Offline -->
                                            <div class="status offline">   
                                                <!-- <i class="fa-solid fa-circle"></i> -->
                                                <span class="badge badge-danger">
                                                    OFFLINE
                                                </span>
                                            </div>                                            
                                            <div class="latest-connection" *ngIf="employee.last_connection != null">
                                                <div class="txt-latest-connection">Latest Connection</div> 
                                                <div class="date-time">
                                                    {{employee.last_connection | dateTime}}
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td>
                                    <a appWorkspaceRouterLink="/employees/{{employee.id}}/view" class="btn btn-info mb-2 mr-2">
                                        <i class="fas fa-eye"></i>
                                    </a>
                                    <button *ngIf="employee.status == 'DISABLED'" class="btn btn-success mb-2 mr-2" (click)="togleStatus(employee.id)">
                                        <i class="fas fa-user"></i>
                                    </button>
                                    <button *ngIf="employee.status == 'ACTIVE'" class="btn btn-disable-employee mb-2 mr-2" (click)="togleStatus(employee.id)">
                                        <i class="fas fa-user-slash"></i>
                                    </button>
                                    <button *ngIf="employee.status == 'DISABLED'" class="btn btn-danger mb-2 mr-2" (click)="deleteEmployee(employee.id)">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>                                  
                            </tr>
                        </tbody>
                    </table> 
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

</div>