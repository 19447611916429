<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

            <div class="row">

                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                    <h3>
                        <span *ngIf="!clockLocation.id"> New </span>
                        <span *ngIf="clockLocation.id"> New </span>
                        Clock Location
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i> Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/payroll-clock-locations">Clock Location</a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page" *ngIf="!clockLocation.id">New</li>
                            <li class="breadcrumb-item active" aria-current="page" *ngIf="clockLocation.id">Update</li>
                        </ol>
                    </nav>
                </div>

                <div
                    class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button class="btn btn-main-action ml-1 mt-3" type="submit">
                            Save
                        </button>
                    </div>
                </div>

            </div>
            <!-- header -->

            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group mb-1">
                                <label for="name">Name</label>
                                <div class="input-group mb-1">
                                    <input type="text" class="form-control" name="name" placeholder="Enter name"
                                        [(ngModel)]="clockLocation.name" required>
                                </div>
                                <small id="nameLocation" class="form-text text-muted mb-4">Enter Name.</small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="location">Location</label>
                                <app-search-address [addressFull]="false" (onChange)="saveLocation($event)" 
                                [(ngModel)]="clockLocation.location.full_address"></app-search-address>
                            </div>
                        </div>
                        <div class="col-6">

                            <div class="form-group mb-1">
                                <label for="radius">Radius</label>
                                <div class="input-group mb-1">
                                    <input type="number" step="0.01" class="form-control" name="radius" placeholder="Enter radius"
                                        [(ngModel)]="clockLocation.radius" required>
                                </div>
                                <small id="nameLocation" class="form-text text-muted mb-4">Enter Radius.</small>
                            </div>
                            <div class="form-group mb-1">
                                <label for="radius">Allowed IP Addresses</label>
                                <div class="input-group mb-1">
                                    <input type="text" class="form-control" name="allowedIPAddresses" placeholder="Allowed IP Addresses"
                                        [(ngModel)]="allowedIPAddresses">
                                </div>
                                <small id="nameLocation" class="form-text text-muted mb-4">Enter the allowed IP addresses separated by ,.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>

    </div>
</div>