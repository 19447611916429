import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { PayrollTimeClockService } from 'src/app/services/companies/clock-employee.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-payroll-clock-users',
  templateUrl: './payroll-clock-users.component.html',
  styleUrls: ['./payroll-clock-users.component.scss']
})
export class PayrollClockUsersComponent implements OnInit {

  public clockEmployees: ClockEmployees[];
  public showHourlyRate: boolean[];

  constructor(    
    public helperService: HelperService,
    private payrollTimeClockService: PayrollTimeClockService
  ) {
    this.clockEmployees = [];
    this.showHourlyRate = [];
  }

  ngOnInit(): void {
    this.getAllClockEmployees();
  }

  private getAllClockEmployees() {
    this.helperService.showLoadingMxpro360();
    this.showHourlyRate = [];
    this.payrollTimeClockService
      .getAll()
      .then((response) => {
        this.clockEmployees = response;
        for (let i = 0; i < this.clockEmployees.length; i++) {
          this.showHourlyRate.push(false);
        }
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  setHourlyRate(index) {
    this.showHourlyRate[index] = !this.showHourlyRate[index];
    console.log(' setHourlyRate ');
    
  }
}
