import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { LiabilityAmount } from 'src/app/entities/workspace/liability-amount';
import { LiabilityAmountsService } from 'src/app/services/workspaces/liability-amounts.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-liability-amounts-form',
    templateUrl: './liability-amounts-form.component.html',
    styleUrls: ['./liability-amounts-form.component.scss']
})
export class LiabilityAmountsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: LiabilityAmount;

    constructor(
        private liabilityAmountsService: LiabilityAmountsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.dataForm = new LiabilityAmount();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        // se verifica si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.liabilityAmountsService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.liabilityAmountsService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/liability-amounts');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
