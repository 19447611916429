import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

declare var ApexCharts;
@Component({
  selector: 'app-monthly-closing-ratio-change',
  templateUrl: './monthly-closing-ratio-change.component.html',
  styleUrls: ['./monthly-closing-ratio-change.component.scss']
})
export class MonthlyClosingRatioChangeComponent implements OnInit {
    monthlyClosing: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) { 
    this.monthlyClosing = [];

  }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.monthlyClosingCompany();
    } else {
        this.monthlyClosingWorkspace();
    }

  }

  
  monthlyClosingWorkspace(){
    this.dashboardWorkspaceService
        .getMonthlyClosing()
        .then((response) => {
            this.monthlyClosing = response;
            this.montlyClosing();
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

  
monthlyClosingCompany(){
    this.dashboardComopanyService
        .getMonthlyClosing()
        .then((response) => {
            this.monthlyClosing = response;
            this.montlyClosing();
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}


  montlyClosing(){
      let totalBookeds = [];
      let TotalEstimate = [];
      let dates = [];

    for (const monthlyClosing of this.monthlyClosing) {
        totalBookeds.push(monthlyClosing.total_bookeds_percentage);
        TotalEstimate.push(monthlyClosing.total_estimates_percentage);
        dates.push(monthlyClosing.month);

    }

    var options = {
        series: [{
        name: 'Total Estimate',
        type: 'column',
        data: TotalEstimate
      }, {
        name: 'Total Bookeds',
        type: 'line',
        data: totalBookeds
      }],
        chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: ''
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: dates,
      xaxis: {
        type: 'string'
      },
      yaxis: [{
        title: {
          text: 'Lead Answer Rates',
        },
      
      }, {
        opposite: true,
        title: {
          text: 'Conversion Rate Improvement'
        }
      }]
      };

      var chart = new ApexCharts(document.querySelector("#monthly-closing-chart"), options);
      chart.render();
    
    
  }

}
