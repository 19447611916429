import { EstimateNoteView } from './../../entities/workspace/estimate-note-view';
import { EstimateNote } from './../../entities/workspace/estimate-note';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EstimatesFollowUpsService {

    constructor(private http: HttpClient) { }


    getAllWithFullView(idEstimate): Promise<Array<EstimateNoteView>> {
        return this.http.get<Array<EstimateNoteView>>(environment.api.company + '/estimates/' + idEstimate + '/follow-ups', { params: { "full-view": "1" } }).toPromise();
    }

    getAll(id: string): Promise<Array<EstimateNote>> {
        return this.http.get<Array<EstimateNote>>(environment.api.company + '/estimates/' + id + '/follow-ups').toPromise();
    }

    add(id: string, dataForm: EstimateNote) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/follow-ups', dataForm).toPromise();
    }
}
