import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/entities/workspace/company';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneQueue } from 'src/app/entities/workspace/phone-queue';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';

declare const jQuery;
declare const swal;
@Component({
  selector: 'app-phone-queues-form',
  templateUrl: './phone-queues-form.component.html',
  styleUrls: ['./phone-queues-form.component.scss']
})
export class PhoneQueuesFormComponent implements OnInit {

  public dataForm: PhoneQueue;
  public nums: PhoneLine[];
  public companies: Array<Company>;
  public employees: EmployeeView[];
  public ringGroups: PhoneRingGroup[];
  public queues: PhoneQueue[];
  public targets: any[];
  public phones: any[];
  JSON;
  public extensions: any[];
  public extensionsCopy: any[];

  constructor(
    private phoneLineService: PhoneLinesService,
    private phoneQueuesService: PhoneQueuesService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private helperService: HelperService,
    private employeesService: EmployeesService,
    private currentRoute: ActivatedRoute,
    private companiesService: CompaniesService,
    private phoneExtensionsService : PhoneExtensionsService,
  ) {
    this.dataForm = new PhoneQueue();
    this.nums = [];
    this.ringGroups = [];
    this.companies = [];
    this.queues = [];
    this.JSON = JSON;
    this.phones = [];
    this.extensions = [];
    this.extensionsCopy = [];

  }

  ngOnInit(): void {
    this.loadCompany();
    this.getAllEmployees();
    this.loadRingGroups();
    this.loadQueues();
    this.loadExtensions();
    this.loadExtensionsCopy();
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.load(params.id);
      }
    })
  }

  ngAfterViewInit() {

    const phone_queued = document.getElementById('phone_queued');
    if (phone_queued) {
      phone_queued.addEventListener('mousedown', () => {
        phone_queued.style.cursor = 'grabbing';
      });
      phone_queued.addEventListener('mouseup', () => {
        phone_queued.style.cursor = 'grab';
      });
    }

  }

/*   private getAllNums(): void {
    this.helperService.showLoadingMxpro360();
    this.phoneLineService.getAllByCompany(this.dataForm.company_id).then((response) => {
      this.nums = response;

      if (this.dataForm.id === null) {
        this.phones = [];
      } else {
        for (let i = this.nums.length - 1; i >= 0; i--) {
          if (this.phones.includes(this.nums[i].phone_number)) {
            this.nums.splice(i, 1);
          }

        }

        this.enableSortable();
        this.changeTarget();
      }
    })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  } */



  public loadExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllByCompany(this.dataForm.company_id)
        .then((response) => {
          this.extensions = response;
          if (this.dataForm.id === null) {
            this.phones = [];
          } else {
            for (let i =  this.extensions.length - 1; i >= 0; i--) {
              if (this.phones.includes(this.extensions[i].id)) {
                 this.extensions.splice(i, 1);
              }

            }

            this.enableSortable();
            this.changeTarget();
          }

        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  public loadExtensionsCopy() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllExtensions()
        .then((response) => {
          this.extensionsCopy = response;
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  private enableSortable() {
    const that = this;

    // Configura jQuery Sortable
    jQuery("#sortable-list").sortable({
      update: function (event, ui) {
        // Actualiza el arreglo con el nuevo orden
        that.phones = [];
        jQuery("#sortable-list li").each(function (index, element) {

          console.log('test element ',element);


          that.phones.push(jQuery(element).text());
        });
        console.log('testeo phones that',that.phones); // Muestra el arreglo actualizado en la consola (puedes eliminar esta línea)
      }
    });

    // Inicializa jQuery Sortable
    jQuery("#sortable-list").disableSelection();
  }


  loadCompany() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
      .getAll()
      .then((response) => {
        this.companies = response;

        this.loadExtensions();
      })
      .catch((error) => {
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  private getAllEmployees(): void {
    this.helperService.showLoadingMxpro360();
    this.employeesService.getAll().then((response) => {
      this.employees = response;
      this.changeTarget();
    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };


  onChangePlayMusicOnHold(files: Array<string>) {

    let extencion = files[0].split('.');

    if (files.length > 0) {

    if ( extencion[1] === 'mp3'|| extencion[1] === 'mpeg'|| extencion[1] === 'ogg'|| extencion[1] === 'wav') {

     let mime = files[0].split('.');
     this.dataForm.play_music_on_hold.display_name = files[0];
     this.dataForm.play_music_on_hold.name = files[0];
     this.dataForm.play_music_on_hold.mime_type = 'audio/' + mime[1];

    }else{
     swal(
       'Error',
       'error the audio extension must be               mp3 mpeg ogg or wav',
       'error'
     );
    }

 }


  }


  save(): void {
    this.helperService.showLoadingMxpro360();
    this.dataForm.phone_extensions_id = this.phones;

    this.phoneQueuesService.save(this.dataForm)
      .then((response) => {
        console.log('response => ', response);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
        this.helperService.goToWorkspaceRouterLink('/phone-queues')
      });
  }


  /**
     * carga un nuevo phoneLine o carga un vehiculo padra ser editado
     * @param id
     */
  private load(id: any) {
    console.log("load", id);

    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService
      .getById(id)
      .then((response) => {

        this.dataForm = response;
        this.phones = this.dataForm.phone_extensions_id;
        //this.getAllNums();
        this.loadExtensions();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  };

  private loadRingGroups() {

    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService
      .getAll()
      .then((response) => {
        this.ringGroups = response;
        this.changeTarget();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  };

  private loadQueues() {

    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService
      .getAll()
      .then((response) => {
        this.queues = response;
        this.changeTarget();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  };

  changeCompany() {

  //this.getAllNums();
    this.loadExtensions();
  }



  changeTarget() {

    if (this.dataForm.destination.target == 'VOICEMAIL') {
      this.targets = this.extensions.filter(item => item.destination.target != 'RING_GROUP' && item.destination.target != 'QUEUE');
    }

    if (this.dataForm.destination.target == 'EXTENSION') {
      this.targets = this.extensions;
    }

    if (this.dataForm.destination.target == 'RING_GROUP') {
      this.targets = this.ringGroups;
    }

    if (this.dataForm.destination.target == 'QUEUE') {
      this.targets = this.queues;
    }

    if (this.dataForm.destination.target == 'EXTERNAL_FORWARD') {
      this.dataForm.destination.target_id = '';
    }
    console.log(this.targets);
  }

  addPhone(phone) {
    for (let index = 0; index < this.extensions.length; index++) {
      if (this.extensions[index].id == phone) {
        this.extensions.splice(index, 1);
        this.phones.push(phone);
      }
    }

  }

  removePhone(phone) {
    for (let index = 0; index < this.phones.length; index++) {
      if (this.phones[index] == phone) {
        this.phones.splice(index, 1);
      }
    }
    for (let index = 0; index < this.extensionsCopy.length; index++) {
      if (this.extensionsCopy[index].id == phone) {
        this.extensions.push(this.extensionsCopy[index]);
      }
    }
  }



  validateExtension(phone: string): string {

    for (const ext of this.extensionsCopy) {
        if (ext.id == phone){
          return ext.extension;
        }
    }
    return phone;
  }
}
