export class LocalTariffSetting {
    public id: string;
    public active: boolean;
    public trucks: number;
    public workers: number;
    public price_per_hour: number;
    public max_hours: number;
    public created: number;
    public updated: number;
    public deleted: number;

    constructor() {
        this.id = null;
        this.active = false;
        this.trucks = 0;
        this.workers = 0;
        this.price_per_hour = 0;
        this.max_hours = 0;
        this.created = null;
        this.updated = null;
        this.deleted = null;
    }
}
