import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService {

  constructor(private http: HttpClient) { }


  getById(id: string) {
    return this.http.get<any>(environment.api.base + '/my-account/terms-contitions').toPromise();
  }

  getTermsConditions() {
    return this.http.get<any>(environment.api.base + '/my-account/terms-contitions').toPromise(); 
  }

  acceptTermsConditions() {
    return this.http.post(environment.api.base + '/my-account/terms-contitions', {}).toPromise();
  }
}
