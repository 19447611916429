export class JobCarrierNote {
    public id: string;
    public note: string;
    public visibility: 'PUBLIC' | 'PRIVATE';
    public employee_id: string;
    public carrier_id: string;
    public job_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.note = null;
        this.visibility = 'PRIVATE';
        this.employee_id = null;
        this.carrier_id = null;
        this.job_id = null;
        this.created = null;
        this.updated = null;
    }
}
