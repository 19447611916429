import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalInventoryRoom } from 'src/app/entities/companies/local-inventory-room';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuickInventoryEstimatesService {

  constructor(private http: HttpClient) {

  }

  get(estimate_id: string): Promise<Array<LocalInventoryRoom>> {
      return this.http.get<Array<LocalInventoryRoom>>(environment.api.company +
          '/estimates/' + estimate_id + '/local-inventory-rooms').toPromise();
  }

  set(dataForm: Array<LocalInventoryRoom>, estimate_id: string) {
      return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/local-inventory-rooms', dataForm).toPromise();
  }
    
  setTooggle(status: boolean, estimate_id: string) {
    const statusNumber = status? 1: 0;
    return this.http.post(environment.api.company + '/estimates/' + estimate_id + '/local-inventory-rooms/full-packing/' + statusNumber, {}).toPromise();
  }
}