<!-- <div class="widget widget-table-two widget-container">

    <div class="widget-heading">
        <h5 class="text-info">Last 5 Payments</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-info"><div class="th-content text-light">Job</div></th>
                        <th class="bg-info"><div class="th-content text-light">Amount</div></th>
                        <th class="bg-info"><div class="th-content text-light">Payment Method</div></th>
                        <th class="bg-info"><div class="th-content text-light">Date</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let jobLastPayments of jobsLastPayments ">
                        <td><div class="td-content customer-name text-info">{{jobLastPayments.job}}</div></td>
                        <td><div class="td-content product-brand">{{jobLastPayments.amount | currency}}</div></td>
                        <td><div class="td-content product-brand">{{jobLastPayments.payment_method}}</div></td>
                        <td><div class="td-content product-brand">{{jobLastPayments.date | dateTime}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->





<div class="widget widget-table-one">
    <div class="widget-heading">
        <h5 class="">Last 5 Payments</h5>
    </div>

    <div class="widget-content">

        <div class="transactions-list item-payment" *ngFor="let jobLastPayments of jobsLastPayments ">
            <div class="t-item">
                <div class="col-6 t-company-name">
                    <div class="t-icon">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                    </div>
                    <div class="t-name">
                        <h4>{{jobLastPayments.job}}</h4>
                        <p class="meta-date">{{jobLastPayments.date | dateTime}}</p>
                    </div>

                </div>
                <div class="col-3 t-company-name amount">
                    
                    <div class="t-name">
                        <span class="badge-payment">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            {{jobLastPayments.amount | currency}}
                        </span>
                    </div>

                </div>
                <div class="col-3 t-company-name payment-method">
                    
                    <div class="t-name">
                        <span class="badge-payment">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                            {{jobLastPayments.payment_method}}
                        </span>
                    </div>

                </div>
            </div>
        </div>

   
     
    </div>
</div>
