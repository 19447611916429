import { Balance } from 'src/app/entities/workspace/balance';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Paginator } from 'src/app/entities/helpers/paginator';

@Injectable({
    providedIn: 'root'
})
export class BalancesService {

    constructor(
        private http: HttpClient
    ) { }

    saveBalance(dataForm: Balance) {
        return this.http.post(environment.api.company + '/balances', dataForm).toPromise();
    }

    /**
     * Metodo que obtiene todos los balances en la web
     */
    getAllApp(
        checkPending: boolean,
        checkPaid: boolean,
        checkPendingReview: boolean,
        checkProcessing: boolean,
        concept: string,
        payment_method: string,
        date_start:string,
        date_end:string,
        paginator: Paginator
    ) {
        const params: any = {
            checkPending,
            checkPaid,
            checkPendingReview,
            checkProcessing,
            concept,
            payment_method,
            date_start,
            date_end,
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start": paginator.paginator.start,
            "end": paginator.paginator.end,
            "order_by": paginator.paginator.order_by,
            "order_type": "DESC"
        };
        return this.http.get<Paginator>(environment.api.company + '/balances', { params: params }).toPromise();
    }

    /**
     * Metodo que obtiene los balances por id de jobs
     *
     * @param id Id del job
     */
    getById(id, data) {
        return this.http.get<Array<BalanceView>>(environment.api.company + '/estimates/' + id + '/balances', { params: data }).toPromise();
    }

    getForCompletionById(id, data) {
        return this.http.get<Array<BalanceView>>(environment.api.company + '/estimates/' + id + '/balances-completions', { params: data }).toPromise();
    }

    /**
     * Metodo que obtiene el detalle de un balance
     *
     * @param id Id del balance
     */
    getDetailBalanceById(id) {
        return this.http.get<BalanceView>(environment.api.company + '/balances/' + id).toPromise();
    }

    /**
     * Metodo que modifica los balances
     */
    setBalance(balance: Balance | BalanceView) {
        return this.http.put(environment.api.company + '/balances/' + balance.id, balance).toPromise();
    }

    /**
     * Realiza la actualizacion de un balance
     *
     * @param id Id del pago
     * @param dataForm Datos a actualizar
     */
    patchEntity(id: string, dataForm: any) {
        return this.http.patch(environment.api.company + '/balances/' + id, dataForm).toPromise();
    }

    /**
     * Elimina un balance
     *
     * @param dataForm Datos del balance a eliminar
     */
    deleteBalance(dataForm: any) {

        // Opciones HTTP para enviar con la solicitud
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: dataForm,
        };

        return this.http.delete(environment.api.company + '/estimates/' + dataForm.balance_id + '/balances', options).toPromise();
    }

    rejectBalance(dataForm: any) {
        return this.http.put(environment.api.company + '/estimates/' + dataForm.balance_id + '/reject-balances', dataForm).toPromise();
    }
}
