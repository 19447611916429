<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <h3>
                Tickets Details
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/tickets">
                            Tickets
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Details
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <!-- 
                    <a appCompanyRouterLink="/tickets/add" class="btn btn-warning">
                        <i class="fas fa-plus-square"></i> &nbsp; New ticket
                    </a> 
                -->
            </div>
        </div>

    </div>
    <!-- header -->
    
    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12">
            <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area">

                    <div class="timeline-simple">
                        <p class="timeline-title">
                            <ng-container *ngFor="let typeTicket of typeTickets">
                                <ng-container *ngIf="typeTicket.id == ticket.ticket_type_id">
                                    {{typeTicket.name | titlecase }}
                                </ng-container>
                            </ng-container>
                        </p>
    
                        <div class="timeline-list">
    
                            <div class="timeline-post-content post-gallery ">
                                <div class="user-profile ">
                                    <!-- <img src="assets/img/90x90.jpg " alt="avatar "> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                </div>
                                <div >
                                    <h4>
                                        {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                                    </h4>
                                    <p class="meta-time-date ">
                                        {{ticket.created | dateTime}}
                                    </p>
                                    <div >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg> 
                                        &nbsp;
                                        <h6  *ngIf="ticket.created_by_employee_id">Estimate Remark</h6>
                                        <h6  *ngIf="!ticket.created_by_employee_id">Customer Remark</h6>
                                        <p class="post-text ">
                                            {{ticket.description}}
                                        </p>
                                        <div class="post-gallery-img" *ngIf="ticket.files.length > 0">
                                            <ng-container *ngFor="let file of ticket.files">
                                                <a [appCdnLink]="'/tickets/' + file.display_name" target="_blank">
                                                    <img [appCdnLink]="'/tickets/' + file.display_name"  alt="timeline ">
                                                </a>
                                            </ng-container> 
                                        </div>
                                    </div>
                                </div>
                            </div>    
    
                            <div class="timeline-post-content post-gallery" *ngFor="let remark of remarks">
                                <div class="user-profile ">
                                    <!-- <img src="assets/img/90x90.jpg " alt="avatar "> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                </div>
                                <div >
                                    <h4 *ngIf="remark.customer_id">
                                        {{remark.customer.name | titlecase}} {{remark.customer.last_name | titlecase}}
                                        <svg *ngIf="remark.private" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                    </h4>
                                    <h4 *ngIf="remark.created_by_employee_id">
                                        {{remark.employee.name | titlecase}}
                                        <svg *ngIf="remark.private" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                    </h4>
                                    <p class="meta-time-date ">
                                        {{remark.created | dateTime}}
                                    </p>
                                    <div >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg> 
                                        &nbsp;
                                        <h6 *ngIf="remark.private">Internal Remark</h6>
                                        <h6 *ngIf="!remark.private">Reply</h6>
                                        <p class="post-text ">
                                            {{remark.remarks}}
                                        </p>
                                        <div class="post-gallery-img" *ngIf="remark.files.length > 0">
                                            <ng-container *ngFor="let file of remark.files">
                                                <a [appCdnLink]="'/tickets/' + file.display_name" target="_blank">
                                                    <img [appCdnLink]="'/tickets/' + file.display_name" alt="timeline ">
                                                </a>
                                            </ng-container> 
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="text-center btn-actions-tickets">
                        <button class="btn btn-info mr-4" (click)="isPrivate(true)"  data-toggle="modal" data-target="#ticketResponse"> 
                            Internal Remark
                            <i class="fa-solid fa-comment"></i>
                        </button>
                        <ng-container *ngIf="!ticket.created_by_employee_id">
                            <button class="btn btn-success" (click)="isPrivate(false)" data-toggle="modal" data-target="#ticketResponse"> 
                                Reply To Customer
                                <i class="fa-solid fa-reply"></i>
                            </button>
                        </ng-container>
                     
                    </div>
    
                </div>
            </div>
            
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area">
                    <div class="d-flex justify-content-between">
                        <h3 >Info</h3>
                        <a (click)="updateInfo()" href="javascript:void(0);" class="mt-2 edit-profile"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3">
                                <path _ngcontent-lhw-c140="" d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                                <polyline _ngcontent-lhw-c140="" points="17 21 17 13 7 13 7 21"></polyline>
                                <polyline _ngcontent-lhw-c140="" points="7 3 7 8 15 8"></polyline>
                            </svg>
                        </a>
                    </div>
                    <div class="text-center user-info">
                        <p>
                            {{customer.name | titlecase}} {{customer.last_name | titlecase}}
                        </p>
                    </div>
                    <div class="user-info-list">

                        <div>
                            <ul class="contacts-block list-unstyled">

                                <li class="contacts-block__item">
                                    <a href="mailto:{{customer.email}}" class="ticket-info-email">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                        {{customer.email}}
                                    </a>
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg> 
                                    {{customer.phone | phone:'US'}}
                                </li>

                            </ul>
                            <hr>
                            <ul class="contacts-block list-unstyled ticket-info">

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                    <strong class="key">Ticket:</strong>
                                    <span class="ticket-document-code"> 
                                        <strong> {{ticket.document.code}} </strong>
                                    </span>
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                                    <strong class="key">Estimate: </strong> 
                                    <a class="text-center cursor-pointer estimate-code" (click)="goToEstimate()">
                                        {{estimate.document.code}}
                                    </a>
                                </li>

                                <li class="contacts-block__item select-status">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                                    <strong class="key">Status: </strong> 
                                    <select name="status" id="status" [(ngModel)]="ticket.status" class="form-control">
                                        <option value="OPEN">Open</option>
                                        <option value="PENDING">Pending</option>
                                        <option value="RESOLVED">Resolved</option>
                                        <option value="WAITING_COSTUMER">Waiting Customer</option>
                                        <option value="CLOSED">Closed</option>
                                    </select> 
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                                    <strong class="key">Priority: </strong>
                                    {{ticket.priority | titlecase}}
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    <strong class="key">Created: </strong>
                                    {{ticket.created | dateTime}}
                                </li>
                            
                            </ul>
                            <hr>
                            <ul class="contacts-block list-unstyled">
                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                    <strong>Work Depart: </strong> 
                                    <select name="Work_Department" id="Work_Department"  [disabled]="this.authService.workspaceSession.employee_company_permissions.role.capabilities.indexOf('ASSIGN_TICKETS') < 1" [(ngModel)]="ticket.work_department_id" [appSelect2]  style="width: 150px;">
                                        <option *ngFor="let workDepartment of workDepartments" value="{{workDepartment.id}}">
                                            {{workDepartment.name | titlecase}}
                                        </option>
                                    </select> 
                                </li>

                                <li class="contacts-block__item" *ngIf="ticket.work_department_id">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                    <strong class="key">Employee: </strong> 
                                    <select name="employee" id="employee" [disabled]="this.authService.workspaceSession.employee_company_permissions.role.capabilities.indexOf('ASSIGN_TICKETS') < 1" [(ngModel)]="ticket.assigned_to_employee" [appSelect2]  style="width: 170px;">
                                        <ng-container *ngFor="let employee of employees">
                                            <option *ngIf="workDepartmentHasEmployee(employee.id)" value="{{employee.id}}">
                                                {{employee.name | titlecase}}
                                            </option>
                                        </ng-container>
                                    </select> 
                                </li>
                                
                            </ul>
                        </div>                                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->
</div>


<!-- Modal Response Tickets -->
<div class="modal fade" id="ticketResponse" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle" *ngIf="dataForm.private">
                    <strong>Add Internal Remark</strong>
                </h5>
                <h5 class="modal-title" id="exampleModalCenterTitle" *ngIf="!dataForm.private">
                    <strong>Reply to Customer</strong>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group mb-4">
                    <label for="">
                        Remark
                    </label>
                    <textarea type="text" rows="6" [(ngModel)]="dataForm.remarks" name="description" class="form-control"></textarea>
                </div>
                
                <div class="upload mt-4 pr-md-4">
                    <input type="file" id="input-file-max-fs" [appDropify] (onChange)="onChangeLogo($event)" class="dropify" 
                    data-default-file="/assets/img/200x200.jpg"
                    data-max-file-size="2M" />
                    <div class="post-gallery-img" *ngIf="dataForm.files.length > 0">
                        <div *ngFor="let fil of dataForm.files">
                            {{fil.display_name}}
                        </div>
                    </div>
                    <p class="mt-2">
                        <i class="flaticon-cloud-upload mr-1"></i> 
                        Upload Picture 
                    </p>
                </div>
            
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i> Discard</button>
                <button type="button" (click)="addRemarks()" class="btn btn-save mr-2" data-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
</div>