import { TicketType } from "./ticket-type";

export class TicketAssignmentRule {
    id: string;
    type: "QUESTION" | "INCIDENT" | "PROBLEM" | "FEATURE_REQUEST" | "REFUND";
    ticket_type_id: string;
    work_department_id: string;
    company_id: string;

    constructor() {
        this.id = null;
        this.type = null;
        this.ticket_type_id = null;
        this.work_department_id = null;
        this.company_id = null;
    }
}