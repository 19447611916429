<div class="widget widget-table-two">

    <div class="widget-heading">
        <h5>Last 5 Tickets Global</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr class="bar-thead">
                        <th class=""><div class="th-content th-heading text-light">Job</div></th>
                        <th class=""><div class="th-content text-light">Subject</div></th>
                        <th class=""><div class="th-content text-light text-center">Priority</div></th>
                        <th class=""><div class="th-content th-heading text-light text-center">Status</div></th>                        
                        <th class=""><div class="th-content th-heading text-light text-center">Created</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ticketAssigned of lastTicketsGlobal">
                        <td><div class="td-content job">{{ticketAssigned.job}}</div></td>
                        <td><div class="td-content customer-name">{{ticketAssigned.subject}}</div></td>
                        <td class="text-center">
                            <div class="td-content customer-name badge" 
                                [ngClass]="{
                                    'badge-success': ticketAssigned.priority == 'LOW',
                                    'badge-info': ticketAssigned.priority == 'MID',
                                    'badge-warning': ticketAssigned.priority == 'HIGH',
                                    'badge-danger': ticketAssigned.priority == 'URGENT'
                                }"
                            >{{ticketAssigned.priority}}
                            </div>
                        </td>
                        <td class="text-center"><div class="td-content customer-name">{{ticketAssigned.status}}</div></td>                        
                        <td class="text-center"><div class="td-content product-brand">{{ticketAssigned.created | date}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>