import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { BoardJobsService } from 'src/app/services/workspaces/board-jobs.service';




declare var jQuery;
declare var swal;
@Component({
  selector: 'app-board-jobs',
  templateUrl: './board-jobs.component.html',
  styleUrls: ['./board-jobs.component.scss']
})
export class BoardJobsComponent implements OnInit {

    rows: any;
    actualPage = 1;
    filterSearch = '';

    constructor(
          private boardJobsService: BoardJobsService,
          public helperService: HelperService,
          public authService: AuthService,
    ) {
    }
  
    ngOnInit(): void {
    }
    
    ngAfterViewInit(): void {
        this.load();
    }
  
    load(): void {
        this.helperService.showLoadingMxpro360();
        this.boardJobsService
            .getAll()
            .then((response) => {
                this.rows = response
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
  
    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.boardJobsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }
  
  }
  