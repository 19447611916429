import { Component, OnInit } from '@angular/core';
import { AdditionalStopRule } from 'src/app/entities/workspace/additional-stop-rule';
import { HelperService } from 'src/app/services/helper.service';
import { AdditionalStopRulesService } from 'src/app/services/workspaces/additional-stop-rules.service';

declare var jQuery;
declare var swal;

@Component({
  selector: 'app-additional-stop-rules',
  templateUrl: './additional-stop-rules.component.html',
  styleUrls: ['./additional-stop-rules.component.scss']
})
export class AdditionalStopRulesComponent implements OnInit {
  rows: Array<AdditionalStopRule>;

  constructor(
      private additionalStopRulesService: AdditionalStopRulesService,
      private helperService: HelperService
  ) {
      this.rows = [];
  }

  actualPage = 1;
  filterSearch = '';

  ngOnInit() {
  }

  ngAfterViewInit(): void {
      this.load();
  }

  
  private load() {
      this.helperService.showLoadingMxpro360();
      this.additionalStopRulesService
          .getAll()
          .then((response) => {
              this.rows = response;
          })
          .catch((error) => {
              console.error('Error: ', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

  remove(id) {
      swal({
          title: 'Are you sure?',
          text: 'Do you want to delete the record?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Delete!'
      })
          .then((result) => {
              if (result.value) {
                  this.helperService.showLoadingMxpro360();
                  this.additionalStopRulesService
                      .remove(id)
                      .then((response) => {
                          this.load();
                          swal(
                              'Deleted!',
                              'The record has been deleted.',
                              'success'
                          );
                      })
                      .catch((error) => {
                          console.error('Error', error);
                      })
                      .finally(() => {
                          this.helperService.hideLoadingMxpro360();
                      });
              }
          });
  }

}
