<!--Por aca empieza el formulario de listado-->

<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Recurring Customer
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="workspace/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Recurring Customer
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>
        <div class="col-lg-12 col-md-12 block-search text-center">
            <button class="btn btn-warning btn-search" (click)="search()">
                Search
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Customer</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Ip</th>
                                <th>Seen</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let recurringCustomer of recurringCustomers | paginate: {itemsPerPage: 25, currentPage: actualPage} index as i;">
                                <td>{{recurringCustomer.estimate.document.code}}</td>
                                <td>{{recurringCustomer.customer.name}} {{recurringCustomer.customer.last_name}}</td>
                                <td>{{recurringCustomer.customer.phone | phone}}</td>
                                <td *ngIf="recurringCustomer.estimate.status=='FOLLOW_UP'">Follow Up</td>
                                <td *ngIf="recurringCustomer.estimate.status=='UNSUCCESSFUL_SALE'">Unsuccessful Sale
                                </td>
                                <td *ngIf="recurringCustomer.estimate.status=='IN_OPERATION'">In Operation</td>
                                <td *ngIf="recurringCustomer.estimate.status=='LOADED_FOR_STORAGE'">Loaded For Storage
                                </td>
                                <td *ngIf="recurringCustomer.estimate.status=='IN_STORAGE'">In Storage</td>
                                <td *ngIf="recurringCustomer.estimate.status=='LOADED_FOR_DELIVERY'">Loaded For Delivery
                                </td>
                                <td *ngIf="recurringCustomer.estimate.status=='DELIVERED'">Delivered</td>

                                <td>{{recurringCustomer.estimate.pickup.address.state}},
                                    {{recurringCustomer.estimate.pickup.address.zip_code}}</td>
                                <td>{{recurringCustomer.estimate.delivery.address.state}},
                                    {{recurringCustomer.estimate.delivery.address.zip_code}}</td>
                                <td>{{recurringCustomer.ip}}</td>
                                <td>{{recurringCustomer.created | dateTime}}</td>
                                <td>
                                    <button class="btn btn-info mb-2" appCompanyRouterLink="/moving/{{recurringCustomer.estimate.id}}/estimate">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!--
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                -->
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>

</div>