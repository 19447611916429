import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierDriver } from 'src/app/entities/workspace/carrier-driver';
import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { DriverInvoiceItemView } from 'src/app/entities/workspace/driver-invoice-item-view';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { BalancesWorkspaceService } from 'src/app/services/workspaces/balances-workspace.service';
import { CarriersDriversService } from 'src/app/services/workspaces/carriers-drivers.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { DriverInvoicesService } from 'src/app/services/workspaces/driver-invoices.service';
import { ConstantsMessages } from 'src/app/constants-messages';

declare var swal;
declare var jQuery;
declare const moment;

@Component({
    selector: 'app-driver-invoices-form',
    templateUrl: './driver-invoices-form.component.html',
    styleUrls: ['./driver-invoices-form.component.scss']
})
export class DriverInvoicesFormComponent implements OnInit {
    @ViewChild('assignNewJobModal') assignNewJobModal: ElementRef;
    constantsMessages = ConstantsMessages;
    driverInvoice: DriverInvoice;
    carriers: Array<Carrier>;
    carrierDriver: String;
    carriersDrivers: Array<CarrierDriver>;
    carrier: Carrier;
    searchJob: string;
    padsJob: number;
    cfJob: number;
    rateJob: number;
    eta_start: number;
    eta_end: number;
    deliveryStatus: 'PENDING';
    comments: string;
    companies: Array<any>;
    positionArrayDriverInvoice: number;
    public employees: any[];
    public driverInvoice_load_date: number;

    constructor(
        private carrierService: CarriersService,
        private carriersDriversService: CarriersDriversService,
        private currentRoute: ActivatedRoute,
        private driverInvoicesService: DriverInvoicesService,
        private helperService: HelperService,
        private balancesService: BalancesWorkspaceService,
        public tariffVersionFactory: TariffVersionService,
        private companiesService: CompaniesService,

    ) {
        this.driverInvoice = new DriverInvoice();
        // this.driverInvoice.allocation = 'IN_HOUSE';
        this.carriers = [];
        this.carrierDriver = '';
        this.carrier = new Carrier();
        this.searchJob = '';
        this.padsJob = null;
        this.cfJob = null;
        this.rateJob = null;
        this.eta_start = null;
        this.eta_end = null;
        this.comments = null;
        this.companies = [];
        this.positionArrayDriverInvoice = null;
        this.employees = [];
        this.driverInvoice_load_date = 0;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
        this.loadCarriers();
        this.loadCompanies();
        this.loadEmployees();
        this.driverInvoice.date = moment(moment(), moment.HTML5_FMT.DATE).unix() * 1000;
    }

    loadEmployees() {
        this.driverInvoicesService.getDriverEmployees()
        .then(response => {
            this.employees = response
        })
    }

      /**
     * Carga las companies, el arguemento enviado es para uqe desde el backend 
     * me devuelva las compañias a las que el empleado puede ingresar
     */
       private loadCompanies() {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll(true)
            .then((response) => {
                this.companies = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
            .getById(id)
            .then((response) => {
                this.driverInvoice = response;
                if (this.driverInvoice.allocation == 'CARRIER') {
                    this.changeCarrier();
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadCarriers() {
        this.helperService.showLoadingMxpro360();
        this.carrierService
            .getAll()
            .then((response) => {
                this.carriers = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeDate(event) {
        this.driverInvoice.date = event;
    }

    changeCarrier() {
        this.helperService.showLoadingMxpro360();
        this.carriersDriversService
            .getAll(this.driverInvoice.carrier_id)
            .then((response) => {
                this.carriersDrivers = response;
                this.carrierDriver = this.driverInvoice.carrier_driver ? this.driverInvoice.carrier_driver.id : null;
                this.setCarrierDriver();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    setCarrierDriver() {
        for (const cD of this.carriersDrivers) {
            if (cD.id == this.carrierDriver) {
                this.driverInvoice.carrier_driver = cD;
            }
        }
    }

    save() {
        this.helperService.showLoadingMxpro360();
        if (this.driverInvoice.id) {
            this.driverInvoicesService
                .edit(this.driverInvoice)
                .then((response) => {
                    this.helperService.goToWorkspaceRouterLink('/driver-invoices');
                })
                .catch((error) => {
                    console.error('error', error);
                    this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        } else {
            this.driverInvoicesService
                .add(this.driverInvoice)
                .then((response) => {
                    this.helperService.goToWorkspaceRouterLink('/driver-invoices');
                })
                .catch((error) => {
                    console.error('error', error);
                    this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }

    }

    changeDriverDate(date) {
        this.eta_start = date[0];
        this.eta_end = date[1];
    }

    refresh() {
        this.driverInvoice.pads_pickup = 0;
        this.driverInvoice.total_cf = 0;
        this.driverInvoice.total_cf_charges = 0;
        this.driverInvoice.total_amount = 0;
        this.driverInvoice.total = 0;
        for (const dI of this.driverInvoice.items) {
            this.driverInvoice.pads_pickup = this.driverInvoice.pads_pickup + Number(dI.pads);
            this.driverInvoice.total_cf = this.driverInvoice.total_cf + Number(dI.cf);
            this.driverInvoice.total_cf_charges = this.driverInvoice.total_cf_charges + Number(dI.amount);
            this.driverInvoice.total_amount = this.driverInvoice.total_amount + Number(dI.amount);
            this.driverInvoice.total = this.driverInvoice.total + Number(dI.moving_balance);
        }
    }

    verify(loadData: boolean) {
        this.driverInvoice.load_date = this.driverInvoice_load_date;
        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
            .verify(this.searchJob)
            .then((response) => {
                if (response['error']) {
                    swal(
                        'Error!',
                        'This record not exist.'
                    );
                } else {
                    if (loadData) {
                        let driverInvoice = new DriverInvoiceItemView
                        driverInvoice.estimate = response;
                        driverInvoice.job_id = response.id
                        driverInvoice.amount = this.cfJob * this.rateJob;
                        driverInvoice.cf = this.cfJob;
                        driverInvoice.rate_cf = this.rateJob;
                        driverInvoice.pads = this.padsJob;
                        driverInvoice.eta_start = this.eta_start;
                        driverInvoice.eta_end = this.eta_end;
                        driverInvoice.delivery_status = this.deliveryStatus;
                        driverInvoice.comments = this.comments;
                        driverInvoice.from = response.pickup.address.state;
                        driverInvoice.to = response.delivery.address.state;
                        this.searchJob = '';
                        this.padsJob = null;
                        this.cfJob = null;
                        this.rateJob = null;
                        this.eta_start = null;
                        this.eta_end = null;
                        this.comments = null;
                        this.deliveryStatus = 'PENDING';
                        jQuery('.modal').modal('hide');
                        this.loadBalances(driverInvoice);
                    } else {
                        this.cfJob = response.volume.cubic_feets_selled;
                    }
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    
  private loadBalances(driverInvoice) {
    let totalBalance = 0;
    this.helperService.showLoadingMxpro360();
    this.balancesService
      .getById(driverInvoice.estimate.id, {})
      .then((response) => {
        for (const balance of response) {
          if (balance.status === 'PAID' && balance.target !== 'STORAGE') {
            totalBalance = totalBalance + balance.amount;
          }
        }

        totalBalance = driverInvoice.estimate.total - totalBalance;
        driverInvoice.moving_balance = totalBalance;
        
        if(this.positionArrayDriverInvoice != null){
            this.driverInvoice.items[this.positionArrayDriverInvoice] = driverInvoice;
            this.positionArrayDriverInvoice = null;
        } else {
            this.driverInvoice.items.push(driverInvoice);
        }
        jQuery('.modal').modal('hide');
        this.refresh();
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
}

    remove(index){
        this.driverInvoice.items.splice(index, 1);
        this.refresh();
    }

    edit(item, position) {
        this.positionArrayDriverInvoice = position;
        this.searchJob = item.estimate.document.code;
        this.padsJob = item.pads;
        this.cfJob = item.cf;
        this.rateJob = item.rate_cf;
        this.eta_start = item.eta_start;
        this.eta_end = item.eta_end;
        this.deliveryStatus = item.delivery_status;
        this.comments = item.comments;
        this.openModalAssignNewJob();
    }
    
    changeLoadDate(date) {
        this.driverInvoice_load_date = date;
    }
    
    openModalAssignNewJob() {
        this.eta_start = null;
        this.eta_end = null;
        this.driverInvoice_load_date = this.driverInvoice.load_date;
        jQuery(this.assignNewJobModal.nativeElement).modal('show');
    }

    getCfJob() {
        this.verify(false);
    }
}
