<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 align-self-center mb-3">
            <h3>
                <span *ngIf="driverInvoice.id == null">
                    New
                </span>
                <span *ngIf="driverInvoice.id != null">
                    Update
                </span> Driver Invoice
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/driver-invoices">Driver Invoices</a></li>
                    <li class="breadcrumb-item active" aria-current="page">New</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <button class="btn btn-secondary btn-add-job" data-toggle="modal" (click)="openModalAssignNewJob()">
                    <i class="fas fa-plus-square mr-2"></i>
                    <span>Add Job</span> 
                </button>
                <button class="btn btn-main-action" (click)="save()">
                    Save
                </button>
            </div>
        </div>
    </div>

    <div class="widget-content widget-content-area mb-4">
        
        <div class="row">

            <div class="form-group col-3 mb-4">
                <label class="control-label">Company</label>
                <select [(ngModel)]="driverInvoice.company_id"  name="company_id" id="company_id" class="form-control">
                    <option *ngFor="let companie of companies" value="{{companie.id}}">
                        {{companie.name}}
                    </option>
                </select>
            </div>
            
            <div class="form-group col-3 mb-4">
                <label class="control-label">Date</label>        
                <app-date-picker [enableTime]="false" [value]="driverInvoice.date" [placeholder]="'Not Assigned'" (onChange)="changeDate($event)">
                </app-date-picker>        
            </div>

            <div class="form-group col-3 mb-4">
                <label class="control-label">Allocation</label>      
                <div class="input-group">
                    <select [(ngModel)]="driverInvoice.allocation" name="allocation" id="allocation" class="form-control">
                        <option value="IN_HOUSE">In House</option>
                        <option value="CARRIER">Carrier</option>
                    </select>
                </div>
            </div>                     

            <div class="form-group col-3 mb-4" [hidden]="driverInvoice.allocation == 'CARRIER'">
                <label class="control-label">Employee Name</label>
                <div class="input-group">
                    <select [appSelect2] [theme]="'mxp360-form'" [(ngModel)]="driverInvoice.employee_driver_id"
                        class="form-control" name="driver_invoice_employees" id="driver_invoice_employees">
                        <option *ngFor="let employee of employees" value="{{employee.id}}">
                            {{employee.name | titlecase}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group col-3 mb-4" [hidden]="driverInvoice.allocation == 'IN_HOUSE'">
                <label class="control-label">Carrier Name</label>
                <div class="input-group">
                    <select [appSelect2] [theme]="'mxp360-form'" class="form-control" [(ngModel)]="driverInvoice.carrier_id"
                        (ngModelChange)="changeCarrier()" name="driver_invoice_carrier" id="driver_invoice_carrier" >
                        <option *ngFor="let carrier of carriers" value="{{carrier.id}}">
                            {{carrier.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group col-3 mb-4" [hidden]="driverInvoice.allocation == 'IN_HOUSE'">
                <label class="control-label">Carrier Driver</label>
                <div class="input-group">
                    <select [(ngModel)]="carrierDriver" (ngModelChange)="setCarrierDriver()" name="di_carrier_name"
                        id="di_carrier_name" class="form-control">
                        <option *ngFor="let cDriver of carriersDrivers" value="{{cDriver.id}}">
                            {{cDriver.name}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="form-group col-3 mb-4">
                <label class="control-label">Pads Dropped</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-columns"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg>
                        </span>
                    </div>
                    <input type="text" placeholder="Pads Dropped" name="driverInvoice_pads_dropped" [(ngModel)]="driverInvoice.pads_dropped" class="form-control">
                </div>
            </div>

            <div class="col-3" [hidden]="driverInvoice.allocation != null">

            </div>

            <!-- <div class="form-group col-2 mt-36">
                <div class="toggle-switch">                
                    <label class="switch s-icons s-outline s-outline-secondary">
                        <input type="checkbox" id="is_paid" name="is_paid" [(ngModel)]="driverInvoice.is_paid">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="is_paid" class="checkbox-label" [ngClass]="{'filter-disabled': !driverInvoice.is_paid}">
                        Is Paid
                    </label>
                </div>
            </div> -->

            <div class="form-group col-4 mt-36">
                <div class="toggle-switch">                
                    <label class="switch s-icons s-outline s-outline-secondary">
                        <input type="checkbox" id="display_storage_location" name="display_storage_location" [(ngModel)]="driverInvoice.display_storage_location">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="display_storage_location" class="checkbox-label" [ngClass]="{'filter-disabled': !driverInvoice.display_storage_location}">
                        Display Storage Location
                    </label>
                </div>
            </div>          

            <div class="form-group col-6">
                <label class="control-label">Comments</label>
                <div class="input-group">
                    <textarea name="driverInvoice_comments" id="driverInvoice_comments" class="form-control" rows="3"
                        [(ngModel)]="driverInvoice.comments" placeholder="Comments">
                    </textarea>
                </div>
            </div>

        </div>

    </div>                    
              
    <div class="widget-content widget-content-area mb-4" *ngIf="driverInvoice.items.length > 0">

        <div class="table-responsive">
            <table class="table table-bordered table-hover mb-4">
                <thead>
                    <tr>
                        <th>Job No</th>
                        <th>Pads</th>
                        <th>From</th>
                        <th>To</th>
                        <th>CF</th>
                        <th>Rate</th>
                        <th>Agent Pay</th>
                        <th>Cust Balance</th>
                        <th>Eta</th>
                        <th>Comment</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of driverInvoice.items; let i = index">
                        <td>{{item.estimate.document.code}}</td>
                        <td>{{item.pads}}</td>
                        <td>
                            <div>{{item.estimate.pickup.address.state}}</div> 
                            <div>{{item.estimate.pickup.address.zip_code}}</div> 
                        </td>
                        <td>
                            <div>{{item.estimate.delivery.address.state}}</div> 
                            <div>{{item.estimate.delivery.address.zip_code}}</div> 
                        </td>
                        <td>{{item.cf}}</td>
                        <td>{{item.rate_cf}}</td>
                        <td>{{item.amount.toFixed(2)}}</td>
                        <td>{{item.moving_balance.toFixed(2)}}</td>
                        <td>
                            <div>{{item.eta_start | date}}</div>  
                            <div>{{item.eta_end | date}}</div>                                            
                        </td>
                        <td>{{item.comments}}</td>
                        <td>
                            <button (click)="edit(item, i)" data-toggle="modal" class="btn btn-success rounded-circle">
                                <i class="fa-solid fa-pen-to-square"></i>
                            </button>
                            <button (click)="remove(i)" type="button" class="btn btn-danger rounded-circle">
                                <i class="fa-solid fa-trash-can"></i>
                            </button>
                        </td>
                    </tr>
                
                </tbody>
            </table>
        </div>

    </div>               
        
    <div class="widget-content widget-content-area mb-4" *ngIf="driverInvoice.items.length > 0">

        <div class="row">

            <div class="form-group col-3 mb-4">
                <label class="control-label">Pads Pickup</label>
                <div class="output-box">{{driverInvoice.pads_pickup}}</div>
            </div>

            <div class="form-group col-3 mb-4">
                <label class="control-label" *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'CUBIC_FEET'">Total CF</label>
                <label class="control-label" *ngIf="tariffVersionFactory.tariffVersion.measure_unit == 'POUNDS'">Total Lbs</label>
                <div class="output-box">{{driverInvoice.total_cf}}</div>
            </div>

            <div class="form-group col-3 mb-4">
                <label for="" class="control-label">Charges</label>
                <div class="output-box">{{driverInvoice.total_cf_charges.toFixed(2)}}</div>
            </div>

            <div class="form-group col-3 mb-4">
                <label class="control-label">Cust Balance</label>
                <div class="output-box">{{driverInvoice.total.toFixed(2)}}</div>
            </div>
            
            <div class="form-group col-3 mb-4">
                <label class="control-label">Due From Agent</label>
                <div class="output-box">
                    {{ (driverInvoice.total - driverInvoice.total_cf_charges).toFixed(2)}}
                </div>
            </div>  

        </div>

    </div>       
   
    


</div>


<!-- Modal Assign New Account -->
<div class="modal fade" #assignNewJobModal tabindex="-1" role="dialog" aria-labelledby="assign-new-account"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header text-center pl-4">
                <h5 class="modal-title" id="assign-new-account">Assign New Job</h5>
            </div>

            <div class="modal-body">
                <div class="form-group mb-3">
                    <label class="control-label">Job Number</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="Job Number" name="searchJob" (blur)="getCfJob()" [(ngModel)]="searchJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label class="control-label">Pads</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-columns"><path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="Pads" name="padsJob" [(ngModel)]="padsJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label class="control-label">CF</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="cf" name="cfJob" [(ngModel)]="cfJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label class="control-label">Rate</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            </span>
                        </div>
                        <input type="text" placeholder="rate" name="rateJob" [(ngModel)]="rateJob" class="form-control">
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="" class="control-label">Load Date</label>
                    <div class="input-group mb-1 date-picker">
                        <div class="icon-calendar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </div>
                        <app-date-picker placeholder="Date: mm-dd / mm-dd"  [value]="driverInvoice_load_date" (onChange)="changeLoadDate($event)"></app-date-picker>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label for="" class="control-label">Eta Start - End</label>
                    <div class="input-group mb-1 date-picker">
                        <div class="icon-calendar">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </div>
                        <app-date-range-picker 
                            [valueStart]="eta_start"
                            [valueEnd]="eta_end"
                            placeholder="Date: mm-dd / mm-dd" (onChange)="changeDriverDate($event)"></app-date-range-picker>
                    </div>
                </div>
                <div class="form-group mb-4">
                    <label class="control-label">Delivery Status</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            </span>
                        </div>
                        <select name="deliveryStatus" [(ngModel)]="deliveryStatus" class="form-control">
                            <option value="PENDING">
                                PENDING
                            </option>
                            <option value="DELIVERED">
                                DELIVERED
                            </option>
                            <option value="REFUSED">
                                REFUSED
                            </option>
                        </select>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="comments" class="control-label">Comments</label>
                    <textarea name="comments" id="comments" class="form-control" rows="4" [(ngModel)]="comments" placeholder="Type here..."></textarea>
                </div>            
            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button type="submit" class="btn btn-save" (click)="verify(true)">
                    Add Job
                </button>
            </div>
        </div>
    </div>
</div>
