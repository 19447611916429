import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private http: HttpClient
  ) { }

  getAllWithFullView(data: any): Promise<Array<any>> {
    return this.http.get<Array<any>>(environment.api.workspace + '/jobs', { params: data }).toPromise();
  }

  getJobsToday(data: any): Promise<any> {
    return this.http.get<any>(environment.api.workspace + '/jobs-today', { params: data }).toPromise();
  }

  getJobsDistances(data: any): Promise<any> {
    return this.http.post<any>(environment.api.workspace + '/jobs-distances',data ).toPromise();
  }
}
