<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                CC Convenience Report
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        CC Convenience Report
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>

        <div class="row col-lg-12 col-md-12 block-filter mb-3">
            <div class="col-12 col-md-0 col-xl-2 pl-0">

            </div>
            <div class="col-12 col-md-5 col-xl-3 pl-0">
                <label>Between amounts</label>
                <input type="number" name="start_amount" step="0.01" id="start_amount" [(ngModel)]="start_amount"
                    class="form-control">
            </div>
            <div class="col-12 col-md-5 col-xl-3 pl-0">
                <label>To</label>
                <input type="number" name="end_amount" size="0.01" id="end_amount" [(ngModel)]="end_amount"
                    class="form-control">
            </div>
            <div class="col-12 col-md-1 col-xl-3 pl-0">
                <button class=" btn btn-warning btn-search" (click)="loadData(true)">
                    Search
                </button>
            </div>
        </div>
    </div>

    <!-- header -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Job Number</th>
                                <th>Amount Charged</th>
                                <th>Fee %</th>
                                <th>Fee Charged</th>
                                <th>Transaction Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginator.data">
                                <td>
                                    {{row.job_number}}
                                </td>
                                <td>
                                    {{row.amount | currency}}
                                </td>
                                <td>
                                    {{row.cc_fee_percentage | percent: '0.2-2'}}
                                </td>
                                <td>
                                    {{row.cc_fee | currency}}
                                </td>
                                <td>
                                    {{row.transaction_date | date}}
                                </td>
                                <td>
                                    {{row.status | filterReplace}}
                                </td>
                                <td>
                                    <a class="btn btn-primary" (click)="openNewTab(row.job_id)">Go To Estimate</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
</div>