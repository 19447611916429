<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                QA Report
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        QA Report
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>
        <div class="col-lg-12 col-md-12 block-search text-center">
            <button class="btn btn-warning btn-search" (click)="search()">
                Search
            </button>
        </div>
    </div>

    <!-- header -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th># of QAs</th>
                                <th>Avg QA Increase by %</th>
                                <th>QA Total Increase</th>
                                <th>QA Deposit Increase</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of dataReport">
                                <td>
                                    {{row.agent | titlecase}}
                                </td>
                                <td>
                                    {{row.did}}
                                </td>
                                <td>
                                    {{row.increase_percentage | number: '1.2-2'}}%
                                </td>
                                <td>
                                    {{row.increase | currency}}
                                </td>
                                <td>
                                    {{row.deposit_increase | currency}}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>TOTAL</strong></td>
                                <td><strong>{{ totalQAs}}</strong></td>
                                <td><strong>{{ totalAverage | number: '1.2-2'}}%</strong></td>
                                <td><strong>{{ totalIncrease | currency }}</strong></td>
                                <td><strong>{{ totalDepositIncrease | currency }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>