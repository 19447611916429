import { TmplAstTemplate } from '@angular/compiler';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import BandwidthRtc, { RtcStream } from "@bandwidth/webrtc-browser";
import { PhoneExtension } from 'src/app/entities/workspace/phone-extension';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneSystemCallDTO } from 'src/app/entities/workspace/phone-system-call-dto';
import { PhoneSystemConferenceDTO } from 'src/app/entities/workspace/phone-system-conference-dto';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { PhoneSystemService } from 'src/app/services/phone-system.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';


/**
 * Contiene los estados posibles del dialer
 */
enum DialerStatus {
    OFF = "OFF",
    NOT_CONNECTED = "NOT_CONNECTED",
    CONNECTING = "CONNECTING",
    REST = "REST",
    LOADING = "LOADING",
    INCOMING_CALL = "INCOMING_CALL",
    OUTGOING_CALL = "OUTGOING_CALL",
    ACTIVE_CALL = "ACTIVE_CALL",
    CALL_ON_HOLD = "CALL_ON_HOLD",
    ACTIVE_CALL___INCOMING_CALL = "ACTIVE_CALL___INCOMING_CALL",
    CALL_ON_HOLD___OUTGOING_CALL = "CALL_ON_HOLD___OUTGOING_CALL",
    CALL_ON_HOLD___ACTIVE_CALL = "CALL_ON_HOLD___ACTIVE_CALL",
    CALL_ON_HOLD___INCOMING_CALL = "CALL_ON_HOLD___INCOMING_CALL",
    CALL_ON_HOLD___CALL_ON_HOLD = "CALL_ON_HOLD___CALL_ON_HOLD"
}




@Component({
    selector: 'app-dialer',
    templateUrl: './dialer.component.html',
    styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit {

    // contiene la extension con la que debe trabajar el dialer
    @Input()
    phoneExtension: PhoneExtension;

    // indica si el telefono esta encendido
    @Input()
    power: boolean;

    // permite identificar el estado actual el dialer
    status: DialerStatus;

    // permite identificar el estado anterior al estado actual del dialer
    lastStatus: DialerStatus;

    // indica el keypad que se debe mostrar
    keypadEnabled: "NUMERIC" | "ACTIVE_CALL_OPTIONS" | "INCOMING_CALL_OPTIONS" | "OUTGOING_CALL_OPTIONS" | "CONTACTS";

    // contiene el numero de telefono ingresado en el dialer
    phoneNumberEntry: string;

    // contiene el audio para sonar cuando entren o salgan llamadas
    ringtone: HTMLAudioElement;

    // contiene el audio de las llamadas activas, es decir, el de la persona que esta al otro lado del telefono
    speakerphone: HTMLAudioElement;

    // variable del sdk de bandwidht para conectar la voz en los telefonos, https://github.com/Bandwidth-Samples/webrtc-hello-world-ts/blob/main/frontend/src/App.tsx
    bandwidthRtc: BandwidthRtc;

    // contiene el callback para cuando una llamada se acepte, se pueda ejecutar este callback, 
    // tener en cuenta que este callback lo envia el backend
    answerCallCallback: CallableFunction;

    // indica la linea telefonica seleccionada 
    phoneLineSelected: PhoneLine;

    // contiene las lineas telefonicas disponibles para conectar el agente
    phoneLines: Array<PhoneLine>;

    // permite buscar una linea de telefono
    searchPhoneLine: string;


    /*******************************************************************************
     * START - variables para tratar los diferentes tipos de llamadas en el dialer *
     *******************************************************************************/

    // indica la llamada activa con la que el agente conectado esta hablando
    active_call: PhoneSystemCallDTO

    // indica la llamada en espera que el agente puso
    hold_on_call: PhoneSystemCallDTO

    // indica la llamada entrante o saliente 
    io_call: PhoneSystemCallDTO;

    // indica la conferencia en la que esta el agente y hace parte, tambien seria un tipo de llamada activa
    conference: PhoneSystemConferenceDTO;

    /*****************************************************************************
     * END - variables para tratar los diferentes tipos de llamadas en el dialer *
     *****************************************************************************/


    constructor(
        private clientSocketsService: ClientSocketsService,
        private phoneSystemService: PhoneSystemService,
        private phoneLinesService: PhoneLinesService,
    ) {
        this.phoneExtension = null;
        this.power = false;
        this.lastStatus = DialerStatus.OFF;
        this.status = DialerStatus.OFF;
        this.keypadEnabled = null;
        this.phoneNumberEntry = "";
        this.ringtone = null;
        this.speakerphone = new Audio();
        this.bandwidthRtc = new BandwidthRtc();
        this.answerCallCallback = null;

        this.active_call = null;
        this.hold_on_call = null;
        this.io_call = null;
        this.conference = null;

        this.phoneLineSelected = null;
        this.phoneLines = [];
        this.searchPhoneLine = "";

    }

    ngOnInit(): void {

        // solicitamos permisos para usar el microfono
        this.requestMediaPermissions();

        // configura los audios de las llamadas provenientes de los streaming
        this.setupStreamEventListener();

        // configuramos los sockets a trabajar en el component
        this.listenSocketConnection();
    }

    /**
     * METODO que detecta los cambios en los atributos @INPUT
     * @param changes 
     */
    ngOnChanges(changes: SimpleChanges) {
        console.log(changes);

        // verificamos el estado de poder(si esta encendios) el telefono
        if (!!changes.power) {

            // verificamos si estan encendiendo el telefono
            if (changes.power.currentValue) {

                // verificamos si ya esta conectado via SOCKET
                this.setNewStatus(this.clientSocketsService.isConnected() ? DialerStatus.REST : DialerStatus.CONNECTING);
            } else {
                this.setNewStatus(DialerStatus.OFF);
            }
        }

        // verificamos si estan reportando un campo para la extension
        if (!!changes.phoneExtension) {

            // verificamos si hay algun valor trabajable
            if (!!changes.phoneExtension.currentValue) {

                // cargamos las lineas telefonicas para hacerlas opcion a la hora de transferir o reenviar llamada
                this.loadPhoneLines();
            }
        }
    }

    /**
     * permite solicitar permisos para usar el microfono al navegador
     */
    private async requestMediaPermissions() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            console.log("permissions: ", stream);
        } catch (error) {
            console.error('Error al obtener permisos de media:', error);
        }
    }


    /**
     * permite establecer un nuevo estado al dialer 
     * @param status 
     */
    private async setNewStatus(newStatus: DialerStatus) {

        // si hay tono de llamada en ejecucion lo pausamos
        this.pauseRingtone();

        // el pairing call, es para mostrar un mensaje de conectar llamada por lo que lo aislamos
        if (this.status !== DialerStatus.LOADING) {

            // guardamos una copia del estado del telefono
            this.lastStatus = this.status;
        }

        // asignamos el nuevo estado al dialer
        this.status = newStatus;

        // verifica el estado actual para poder mostrar el key y ejecutar el sonido correspondiente
        switch (this.status) {


            case "OFF":

                break;

            case "NOT_CONNECTED":

                break;

            case "CONNECTING":

                break;

            case "REST":
                this.keypadEnabled = "NUMERIC";
                break;

            case "LOADING":
                this.keypadEnabled = null;
                break;

            case "ACTIVE_CALL":

                // verifiaCmos si estan conectando una llamada 
                if (
                    this.lastStatus == DialerStatus.INCOMING_CALL ||
                    this.lastStatus == DialerStatus.OUTGOING_CALL ||
                    this.lastStatus == DialerStatus.CALL_ON_HOLD___OUTGOING_CALL ||
                    this.lastStatus == DialerStatus.CALL_ON_HOLD___INCOMING_CALL ||
                    this.lastStatus == DialerStatus.ACTIVE_CALL___INCOMING_CALL) {
                }



                this.keypadEnabled = "ACTIVE_CALL_OPTIONS";
                break;

            case "CALL_ON_HOLD":
                this.keypadEnabled = "ACTIVE_CALL_OPTIONS";
                break;

            case "CALL_ON_HOLD___ACTIVE_CALL":
                this.keypadEnabled = "ACTIVE_CALL_OPTIONS";
                break;

            case "CALL_ON_HOLD___CALL_ON_HOLD":
                this.keypadEnabled = "ACTIVE_CALL_OPTIONS";
                break;

            case "INCOMING_CALL":
                this.setIncomingCallTone();
                this.keypadEnabled = "INCOMING_CALL_OPTIONS";
                break;

            case "OUTGOING_CALL":
                this.setOutgoingCallTone();
                this.keypadEnabled = "OUTGOING_CALL_OPTIONS";
                break;

            case "ACTIVE_CALL___INCOMING_CALL":
                this.setSecondIncomingCallTone();
                this.keypadEnabled = "INCOMING_CALL_OPTIONS";
                break;

            case "CALL_ON_HOLD___OUTGOING_CALL":
                this.setOutgoingCallTone();
                this.keypadEnabled = "OUTGOING_CALL_OPTIONS";
                break;

            case "CALL_ON_HOLD___INCOMING_CALL":
                this.setIncomingCallTone();
                this.keypadEnabled = "INCOMING_CALL_OPTIONS";
                break;
        }
    }

    /**
     * permite configurar los streaming para los audios de las llamadas
     */
    private setupStreamEventListener() {

        // Este evento se activará cada vez que se nos envíe una nueva transmisión
        this.bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {
            console.log("receiving audio: ", rtcStream);

            // validamos que venga contenido de audio para reproducir
            if (!!rtcStream) {
                if (this.speakerphone.srcObject !== rtcStream.mediaStream) {
                    this.speakerphone.srcObject = rtcStream.mediaStream;
                    this.speakerphone.play();
                }
            }
        });

        // Este evento se activará cada vez que ya no se nos envíe una transmisión
        this.bandwidthRtc.onStreamUnavailable((endpointId: string) => {
            console.log("no longer receiving audio: ", endpointId);

            // como ya no hay mas contenido de audio detenemos el audio
            this.speakerphone.pause();
        });


    }

    /**
     * permite escuchar los eventos basicos del socket para poder que el componente pueda trabajar
     */
    private listenSocketConnection() {

        console.log('cmprobando conexion de socket');

        // creamos un escucha para cuando el socket se conecte, se despliegue la configuracion
        this.clientSocketsService
            .onConnect
            .subscribe(() => {

                // solo necesitamos que se ejecute una sola vez la configuracion
                if (this.lastStatus == DialerStatus.OFF) {

                    // ejecutamos la configuracion
                    this.setupSockets();
                } else {

                    // volvemos al estado anterior del dialer
                    this.status = this.lastStatus;
                }
            });

        // veerificamos si el socket ya esta conectado al servidor
        if (this.clientSocketsService.isConnected()) {

            // desplegamos la configuracion de comunicacion via SOCKET
            this.setupSockets();
        }

    }

    /**
     * permite configurar los eventos de sockets a trabajar en el component 
     */
    private setupSockets() {

        // obtenemos el socket a trabajar
        const socket = this.clientSocketsService.getSocket();

        // socket reporta que se ha desconectado del servidor
        socket.on("disconnect", () => {


            console.log("desconectado: ");


            // verificamos que este encendido
            if (this.power) {

                // estblecemos el estado nuevo al dialer para que el agente sepa que no hay conexion
                this.setNewStatus(DialerStatus.NOT_CONNECTED);
            }

        });

        // socket para reportar llamada entrante
        socket.on('INCOMING_CALL', (calldto: PhoneSystemCallDTO, callback: CallableFunction) => {

            console.log('entrando llamada', calldto);

            // asignamos el callback para ejecutar cuando se conteste la llamada
            this.answerCallCallback = callback;

            // asignamos la llamada en la variable que dice que es entrante o saliente
            this.io_call = calldto;

            // verificamos si el telefono esta en reposo, es decir no tiene otra llamada activa
            if (this.compareDialerStatus(DialerStatus.REST)) {

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.INCOMING_CALL);
            }

            // verificamos si el telefono tiene otra llamada activa
            if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {


                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.ACTIVE_CALL___INCOMING_CALL);
            }

            // verificamos si el telefono tiene otra llamada activa
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD)) {


                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.CALL_ON_HOLD___INCOMING_CALL);
            }

        });

        // socket para reportar que una llamada entrante que no habia sido contestada fue colgada
        socket.on('INCOMING_CALL_CANCELED', (call_id: string) => {


            console.log("llamada entrante cancelada: ", call_id);


            // verificamos si es la unica llamada de momento
            if (this.compareDialerStatus(DialerStatus.INCOMING_CALL)) {

                // validamos que la llamada a cancelar sea la llamada de momento
                if (this.io_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.REST);

                    // borramos la variable que contiene la información de la llamada entrante 
                    this.io_call = null;
                }

            }

            // verificamos si el telefono tiene otra llamada activa, 
            // esto por si depronto le habia alcanzado dar en contestar pero 
            // no se hay asignado correctamente en el backend, 
            // o el otro usuario haya colgado antes de eso
            if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {

                // validamos que la llamada a cancelar sea la llamada de momento
                if (this.active_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.REST);

                    // borramos la variable que contiene la información de la llamada entrante 
                    this.active_call = null;

                }
            }

            // verificamos si el telefono tiene otra llamada activa
            if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL___INCOMING_CALL)) {

                // validamos si es la llamada entrante, es la colgada
                if (this.io_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.ACTIVE_CALL);

                    // borramos la variable que contiene la información de la llamada entrante 
                    this.io_call = null;
                }
            }

            // verificamos si el telefono tiene otra llamada activa
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___INCOMING_CALL)) {

                // validamos si es la llamada entrante, es la colgada
                if (this.io_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD);

                    // borramos la variable que contiene la información de la llamada entrante 
                    this.io_call = null;
                }
            }


        });

        // socket para reportar que una llamada entrante ha sido aceptada
        socket.on('INCOMING_CALL_CONNECTED', (calldto: PhoneSystemCallDTO) => {


            console.log("llamada entrante conectada: ", calldto);


            // verificamos si es la unica llamada entratne que se estaba tratando
            if (this.compareDialerStatus(DialerStatus.INCOMING_CALL)) {

                // asignamos la informacion de la llamada
                this.active_call = calldto;

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.ACTIVE_CALL);

                // borramos la variable que contiene la información de la llamada entrante 
                this.io_call = null;
            }

            // verificamos si habia otra llamada activa
            if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL___INCOMING_CALL)) {

                // pasamos la informacion de la llamada activa a llamada en espera
                this.hold_on_call = this.active_call;

                // asignamos la informacion de la llamada 
                this.active_call = calldto;

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL);

                // borramos la variable que contiene la información de la llamada entrante 
                this.io_call = null;
            }

            // verificamos si el usuario tenia una llamada ya en espera
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___INCOMING_CALL)) {

                // asignamos la informacion de la llamada 
                this.active_call = calldto;

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL);
            }
        });

        // socket para reportar que una llamada saliente ha sido aceptada
        socket.on('OUTCOMING_CALL_CONNECTED', async (calldto: PhoneSystemCallDTO) => {

            console.log("llamada saliente conectada: ", calldto);

            // verificamos si es la unica llamada saliente que se estaba tratando
            if (this.compareDialerStatus(DialerStatus.OUTGOING_CALL)) {

                // asignamos la informacion de la llamada
                this.active_call = calldto;

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.ACTIVE_CALL);
            }

            // verificamos si es una llamada que se esataba haciendo teniendo otra llamada en espera
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___OUTGOING_CALL)) {

                // asignamos la informacion de la llamada
                this.active_call = calldto;

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL);
            }

        });

        // socket para reportar que una llamada la ha finalizado la otra persona o la otra parte
        socket.on('CALL_ENDED', (call_id: string) => {

            console.log("llamada desconectada: ", call_id);


            // verificamos si la llamada que se termino es la que estaba en proceso
            if (this.compareDialerStatus(DialerStatus.OUTGOING_CALL)) {

                // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                this.setNewStatus(DialerStatus.REST);

                // desemparejamos la llamada
                this.unpairingCall();

                // borramos la informacion de la llamada saliente
                this.io_call = null;
            }

            // verificamos si la llamada que se termino es la que estaba en proceso
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___OUTGOING_CALL)) {

                // verificamos que sea la llamada que se esta colgando
                if (this.io_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD);

                    // desemparejamos la llamada
                    this.unpairingCall();

                    // borramos la informacion de la llamada saliente
                    this.io_call = null;
                }

                // validamos si la llamada que se tiene activa es la que finalizo
                if (this.hold_on_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.OUTGOING_CALL);

                    // reseteamos la informacion de la llamada en espera
                    this.hold_on_call = null;
                }


            }


            // verificamos si la llamada que se termino es la que estaba en proceso
            if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {

                // validamos si la llamada que se tiene activa es la que finalizo
                if (this.active_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.REST);

                    // desemparejamos la llamada
                    this.unpairingCall();

                    // borramos la informacion de la llamada activa
                    this.active_call = null;
                }
            }

            // verificamos si la llamada que se termino es la que estaba en espera
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD)) {

                // validamos si la llamada que se tiene activa es la que finalizo
                if (this.hold_on_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.REST);

                    // desemparejamos la llamada
                    this.unpairingCall();

                    // reseteamos la informacion de la llamada en espera
                    this.hold_on_call = null;

                }
            }

            // verificamos si la llamada que se termino es la que estaba activa
            if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL___INCOMING_CALL)) {

                // validamos si es la llamada activa, es la colgada
                if (this.active_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.INCOMING_CALL);

                    // desemparejamos la llamada
                    this.unpairingCall();

                    // borramos la informacion de la llamada activa
                    this.active_call = null;

                }
            }

            // verificamos si ambas llamadas estan en espera o si hay una llamada activa y la otra en espera
            if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL) || this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD)) {


                // validamos si la llamada que se tiene activa es la que finalizo
                if (this.active_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD);

                    // desemparejamos la llamada
                    this.unpairingCall();

                    // borramos la informacion de la llamada activa
                    this.active_call = null;

                }

                // validamos si la llamada que se tenia en espera es la que finalizo
                if (this.hold_on_call.call_id == call_id) {

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.ACTIVE_CALL);

                    // borramos la informacion de la llamada en espera
                    this.hold_on_call = null;
                }
            }



        });
    }


    /**
     * Permite cargar las lineas telefonicas asociadas con la compañaia de la extension cargada
     */
    private loadPhoneLines() {
        this.phoneLinesService
            .getAllByCompany(this.phoneExtension.company_id)
            .then((response: Array<PhoneLine>) => {

                // recorremos cada linea telefonica
                response.forEach(TmpPL => {

                    let addit = true;

                    // validamos si la linea telefonica apunta a la extension en uso, esto para que no se vuelva ciclica en el futuro,
                    if (TmpPL.destination.target == "EXTENSION") {
                        if (TmpPL.destination.target_id == this.phoneExtension.id) {
                            addit = false;
                        }
                    }

                    // si esta en tru es porque es usable
                    if (addit) {
                        // agregamos la linea telefonica al arreglo de lineas
                        this.phoneLines.push(TmpPL);
                    }

                });


            })
            .catch((error) => {
                console.log(error);
            })
    };

    /**
     * Permite establecer el tono al de llamada entrante
     */
    setIncomingCallTone() {
        this.ringtone = new Audio();
        this.ringtone.src = "/assets/sounds/ringing.mp3";
        this.ringtone.loop = true;
        this.ringtone.play();
    }

    /**
     * permite establecer el tono al de llamada saliente
     */
    setOutgoingCallTone() {
        this.ringtone = new Audio();
        this.ringtone.src = "/assets/sounds/calling.mp3";
        this.ringtone.loop = true;
        this.ringtone.play();
    }

    /**
     * permite establecer el tono al de la segunda llamada, es decir, 
     * el agente esta en una llamada activa, este tono debe sonar como para que sepa 
     * que tiene una llamada entrante pero no suene tan duro como la original
     */
    setSecondIncomingCallTone() {
        this.ringtone = new Audio();
        this.ringtone.src = "/assets/sounds/beep3.mp3";
        this.ringtone.loop = true;
        this.ringtone.play();
    }

    /**
     * Permite pausar un tono de llamada
     */
    pauseRingtone() {
        // verificamos si ya hay un tono en marcha
        if (this.ringtone != null) {

            // pausamos el tono
            this.ringtone.pause();
        }
    }







    /*******************************
     * METODOS DE ESTADOS GENERALES*
     *******************************/


    /**
     * permite comparar si el dialer tiene estado deseado
     * 
     * @param status 
     * @param ignoreLoadingStatus 
     * @returns 
     */
    compareDialerStatus(status: string, ignoreLoadingStatus: boolean = true) {

        // sacamos a parte la variable con la que se dedebe trabajar
        let currentStatus = null;

        // verificamos si debemos tener en cuenta o no el estado de cargando
        if (ignoreLoadingStatus && this.status == DialerStatus.LOADING) {

            // si el estado es de cargando y entonces tomamos el estado anterior
            currentStatus = this.lastStatus;
        } else {
            currentStatus = this.status;
        }

        // validamos con cual estado es que se desea hacer la comparacion
        switch (status) {
            case "OFF":
                return currentStatus == DialerStatus.OFF;
                break;
            case "NOT_CONNECTED":
                return currentStatus == DialerStatus.NOT_CONNECTED;
                break;
            case "CONNECTING":
                return currentStatus == DialerStatus.CONNECTING;
                break;
            case "REST":
                return currentStatus == DialerStatus.REST;
                break;
            case "LOADING":
                return currentStatus == DialerStatus.LOADING;
                break;
            case "INCOMING_CALL":
                return currentStatus == DialerStatus.INCOMING_CALL;
                break;
            case "OUTGOING_CALL":
                return currentStatus == DialerStatus.OUTGOING_CALL;
                break;
            case "ACTIVE_CALL":
                return currentStatus == DialerStatus.ACTIVE_CALL;
                break;
            case "CALL_ON_HOLD":
                return currentStatus == DialerStatus.CALL_ON_HOLD;
                break;
            case "ACTIVE_CALL___INCOMING_CALL":
                return currentStatus == DialerStatus.ACTIVE_CALL___INCOMING_CALL;
                break;
            case "CALL_ON_HOLD___OUTGOING_CALL":
                return currentStatus == DialerStatus.CALL_ON_HOLD___OUTGOING_CALL;
                break;
            case "CALL_ON_HOLD___ACTIVE_CALL":
                return currentStatus == DialerStatus.CALL_ON_HOLD___ACTIVE_CALL;
                break;
            case "CALL_ON_HOLD___CALL_ON_HOLD":
                return currentStatus == DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD;
                break;
            case "CALL_ON_HOLD___INCOMING_CALL":
                return currentStatus == DialerStatus.CALL_ON_HOLD___INCOMING_CALL;
                break;
        }

        return false;
    }

    /**
     * permite comparar desde el frontend si el dialer tiene estado deseado
     * @param status 
     */
    compareViewDialerStatus(status: string) {
        // verificamos si el estado del dialer coincide con el deseado
        return this.compareDialerStatus(status, false)
    }


    /**
     * verifica si el estado actual del dialer es cargando o procesando alguna funcion
     * @returns 
     */
    isDialerLoading() {
        return this.status === DialerStatus.LOADING;
    }

    /**
     * permite verificar si el dialer se encuentra en una llamada activa o llamada emparejada
     * @returns 
     */
    isDialerOnActiveCall(): boolean {
        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["ACTIVE_CALL", "CALL_ON_HOLD", "ACTIVE_CALL___INCOMING_CALL", "CALL_ON_HOLD___OUTGOING_CALL", "CALL_ON_HOLD___ACTIVE_CALL", "CALL_ON_HOLD___INCOMING_CALL", "CALL_ON_HOLD___CALL_ON_HOLD"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }

    /**
     * permite verificar si el dialer se encuentra en una llamada
     * @returns 
     */
    isDialerOnCall(): boolean {

        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["INCOMING_CALL", "OUTGOING_CALL", "ACTIVE_CALL", "CALL_ON_HOLD", "ACTIVE_CALL___INCOMING_CALL", "CALL_ON_HOLD___OUTGOING_CALL", "CALL_ON_HOLD___ACTIVE_CALL", "CALL_ON_HOLD___INCOMING_CALL", "CALL_ON_HOLD___CALL_ON_HOLD"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }

    /**
     * Permite verificar si el dialer esta fuera de linea
     * @returns 
     */
    isDialerOffline(): boolean {
        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["OFF", "NOT_CONNECTED", "CONNECTING"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }

    /**
     * Permite verificar si hay llamada entrante 
     * @returns 
     */
    isIncomingCall(): boolean {
        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["INCOMING_CALL", "ACTIVE_CALL___INCOMING_CALL", "CALL_ON_HOLD___INCOMING_CALL"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }

    /**
     * Permite verificar si el dialer tiene las dos llamadas activas
     * @returns 
     */
    haveDialerBothCallsActive(): boolean {
        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["CALL_ON_HOLD___ACTIVE_CALL", "CALL_ON_HOLD___CALL_ON_HOLD"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }

    /**
     * permite verificar si la llamada actual es la que esta en espera
     * @returns 
     */
    IsCallOnHold() {
        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["CALL_ON_HOLD", "CALL_ON_HOLD___CALL_ON_HOLD"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }

    /**
     * permite verificar si la llamada actual en el que se pueda colgar
     */
    isTheCallEnabledToHangUp() {
        // variable que contiene los estados de una llamada activa
        const activeCallStatus = ["OUTGOING_CALL", "CALL_ON_HOLD___OUTGOING_CALL", "ACTIVE_CALL", "CALL_ON_HOLD", "CALL_ON_HOLD___ACTIVE_CALL", "CALL_ON_HOLD___CALL_ON_HOLD"];

        // verificamos si el telefono esta en una llamada activa
        return activeCallStatus.includes(this.status);
    }


    /**
     * permite verificar si el microfono esta habilitado
     * @returns 
     */
    isMicMuted() {
        return this.active_call.mute;
    }

    /**
     * permite verificar si la linea telefonica es la seleccionada
     * @returns 
     */
    isPhoneLineSelected(phoneline: PhoneLine) {
        if (!!this.phoneLineSelected) {
            return this.phoneLineSelected.id == phoneline.id;
        }

        return false;
    }




    /**********************************
     * METODOS PRINCIPALES DEL DIALER *
     **********************************/



    /**
     * permite emparejar la llamada enlazando el navegador a la api de voz de bandwidth
     *
     * @param participant_token 
     * @returns 
     */
    pairingCall(participant_token: string) {
        return new Promise((resolve, reject) => {

            // Connect to Bandwidth WebRTC
            this.bandwidthRtc
                .connect({
                    deviceToken: participant_token,
                })
                .then(async (result) => {

                    console.log("connected to bandwidth webrtc!: ", result);

                    this.bandwidthRtc
                        .publish({
                            audio: true,
                            video: false,
                        })
                        .then((result) => {
                            console.log("browser mic is streaming: ", result);
                            resolve(true);

                            console.log("bandwidthRtc: ", this.bandwidthRtc)
                        })
                        .catch((error) => {
                            console.error("Error publishing browser mic", error);
                            reject(false);
                        });
                })
                .catch((error) => {
                    console.error("Error connecting to Bandwidth WebRTC", error);
                    reject(false);
                });

        });
    }

    /**
     * permite desemparejar la llamada desconectado navegador de bandwidth
     */
    unpairingCall() {
        this.bandwidthRtc.disconnect();
    }


    /**
     * Pemite contestar una llamada entrante
     */
    async answerIncomingCall() {

        // indicamos que el dialer esta conectando la llamada
        this.setNewStatus(DialerStatus.LOADING);

        // si hay tono de llamada en ejecucion lo pausamos
        this.pauseRingtone();

        // ejecutamos el callback para indicar que la llamada fue contestada
        this.answerCallCallback();

        //emparejamos la llamada
        await this.pairingCall(this.io_call.participant_token);

        // habilitamos el microfono
        this.bandwidthRtc.setMicEnabled(true);

        // no cambiamos la vista hasta que se reciba la informacion de la llamada, en el socket INCOMING_CALL_CONNECTED
    }

    /**
     * Permite realizar una llamada
     */
    makeOutgoingCall() {

        // creamos una nueva instancia, para poder asignar el numero de telefono y asi mostrarlo en la vista
        this.io_call = new PhoneSystemCallDTO();
        this.io_call.phone_number = this.phoneNumberEntry;


        // verificamos si el estado del telefono era reposo
        if (this.compareDialerStatus(DialerStatus.REST)) {

            // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
            this.setNewStatus(DialerStatus.OUTGOING_CALL);
        }

        // verificamos si el estado del telefono era es en una llamada
        if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {

            // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
            this.setNewStatus(DialerStatus.CALL_ON_HOLD___OUTGOING_CALL);
        }

        // verificamos si el estado del telefono era llamada en espera
        if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD)) {

            // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
            this.setNewStatus(DialerStatus.CALL_ON_HOLD___OUTGOING_CALL);
        }

        // hacemos la llamada
        this.phoneSystemService
            .makeCall(this.phoneNumberEntry, this.phoneExtension.id)
            .then(async (response: PhoneSystemCallDTO) => {

                // asignamos la informacion de la llamada a la variable para poder que se muestre en la vista
                this.io_call = response;

                //emparejamos la llamada
                await this.pairingCall(this.io_call.participant_token);

                // habilitamos el microfono
                this.bandwidthRtc.setMicEnabled(true);
            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    /**
     * permite colgar la llamada actual
     */
    hangUpCall() {

        // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
        this.setNewStatus(DialerStatus.LOADING);

        // validamos de donde debemos obtener el call_id para saber cual llamada es la que se debe colgar
        let call_id = null;

        if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL) || this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL)) {
            call_id = this.active_call.call_id;
        }

        if (this.compareDialerStatus(DialerStatus.OUTGOING_CALL) || this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___OUTGOING_CALL)) {
            call_id = this.io_call.call_id
        }

        // enviamos la soliciud de colgar llamada
        this.phoneSystemService
            .hangup(call_id)
            .then((response) => {

                // limpiamos los numeros ingresados
                this.phoneNumberEntry = '';

            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    /**
     * Permite poner una llamada en espera
     */
    putCallOnHold() {

        // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
        this.setNewStatus(DialerStatus.LOADING);

        // ejecutamos la accion de poner en espera la llamada
        this.phoneSystemService
            .holdCall(this.active_call.call_id)
            .then(async (response) => {

                // desemparejamos la llamada que se puso en espera
                this.unpairingCall();

                // verificamos si el telefono tenia ya una llamada activa
                if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {

                    // ponermos la llamada como en espera
                    this.hold_on_call = this.active_call;

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD);
                }

                // verificamos si el estado del telefono era llamada en espera con llamada activa
                if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL)) {

                    // falta crear el caso espeial para aca, dado que ambos llamadas pueden estar en espera al mismo tiempo
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD);
                }
            });
    }

    /**
     * permite reanudar una llamada que estaba en espera
     */
    resumeCall() {

        // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
        this.setNewStatus(DialerStatus.LOADING);

        let call_id = null;

        // verificamos el estado actual para poder obtener el call_id de la llamada a reanudar
        if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD)) {
            call_id = this.hold_on_call.call_id;
        }

        // verificamos el estado actual para poder obtener el call_id de la llamada a reanudar
        if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD)) {
            call_id = this.active_call.call_id;
        }

        // ejecutamos la accion de poner en espera la llamada
        this.phoneSystemService
            .holdCallResume(call_id)
            .then(async (response) => {

                // verificamos si tenia la llamada en espera
                if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD)) {

                    // ponemos la informacion de la llamada que estaba en espera en la casilla de la llamada activa
                    this.active_call = this.hold_on_call;

                    // emparejamos la llamada
                    await this.pairingCall(this.active_call.participant_token);

                    // habilitamos el microfono
                    this.bandwidthRtc.setMicEnabled(this.active_call.mute == false);

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.ACTIVE_CALL);
                }

                // verificamos si el estado del telefono era llamada en espera con llamada activa
                if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD)) {

                    // emparejamos la llamada
                    await this.pairingCall(this.active_call.participant_token);

                    // habilitamos el microfono
                    this.bandwidthRtc.setMicEnabled(this.active_call.mute == false);

                    // asignamos un nuevo estado al dialer para que muestre la vista correspondiente
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL);
                }
            });



    }

    /**
     * permite transferir una llamada a otro numero de telefono o extension
     */
    transferCall() {

        // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
        this.setNewStatus(DialerStatus.LOADING);

        // ejecutamos la transferencia de la llamada
        this.phoneSystemService
            .transferCall(this.active_call.call_id, this.active_call.phone_number, this.phoneNumberEntry)
            .then((response) => {

                // limpiamos los numeros ingresados
                this.phoneNumberEntry = "";
                this.phoneLineSelected = null;

                // verificamos el estado en el que empezo la soliciutd de transferencia
                if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {

                    // ponemos el telefono en estado de reposo
                    this.setNewStatus(DialerStatus.REST);

                    // limpiamos la variable que contiene la informacion actual
                    this.active_call = null;

                    // desconectamos el dial
                    this.unpairingCall();
                }


                // verificamos el estado en el que empezo la soliciutd de transferencia
                if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL)) {

                    // ponemos el telefono en estado de reposo
                    this.setNewStatus(DialerStatus.CALL_ON_HOLD);

                    // limpiamos la variable que contiene la informacion actual
                    this.active_call = null;

                    // desconectamos el dial
                    this.unpairingCall();
                }

            });


    }

    /**
     * permite redireccinoar una llamada entrante al voicemail
     */
    transferCallToVoicemail() {

        // mostramos el loading para indicar que estamos procesando la solicitud
        this.setNewStatus(DialerStatus.LOADING);

        // validamos el estado actual para saber de donde obtener la informacion de la llamada
        let call_id = null;

        if (this.compareDialerStatus(DialerStatus.INCOMING_CALL) || this.compareDialerStatus(DialerStatus.ACTIVE_CALL___INCOMING_CALL)) {
            call_id = this.io_call.call_id;
        }

        if (this.compareDialerStatus(DialerStatus.ACTIVE_CALL)) {
            call_id = this.active_call.call_id;
        }

        // ejecutamos la accion
        this.phoneSystemService
            .transferCallToVoicemail(call_id)
            .then((response) => {

                // limpiamos la variable que guarda los digitos numericos ingresados
                this.phoneNumberEntry = "";

            });

    }









    /*************************************************
     * METODOS ESPECIALES SOBRE EL FLUJO DE LLAMADAS *
     *************************************************/


    /**
     * a una llamada entrante y una llamada activa, permite colgar la llamada activa y pasar a contestar la llamada entrante
     */
    endCallAndAnswerIncomingCall() {

        // mostramos la vista de cargando
        this.setNewStatus(DialerStatus.LOADING);

        // enviamos la soliciud de colgar llamada
        this.phoneSystemService
            .hangup(this.active_call.call_id)
            .then(async (response) => {

                // limpiamos los numeros ingresados
                this.phoneNumberEntry = '';

                // desemparejamos la llamada
                this.unpairingCall();

                // si hay tono de llamada en ejecucion lo pausamos
                this.pauseRingtone();

                // ejecutamos el callback para indicar que la llamada fue contestada
                this.answerCallCallback();

                //emparejamos la llamada
                await this.pairingCall(this.io_call.participant_token);

                // habilitamos el microfono
                this.bandwidthRtc.setMicEnabled(true);
            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    /**
     * a una llamada entrante y una llamada activa, permite poner en espera la llamada activa y pasar a contestar la llamada entrante
     */
    holdCurrentCallAndAnswerIncomingCall() {

        // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
        this.setNewStatus(DialerStatus.LOADING);

        // ejecutamos la accion de poner en espera la llamada
        this.phoneSystemService
            .holdCall(this.active_call.call_id)
            .then(async (response) => {

                // limpiamos los numeros ingresados
                this.phoneNumberEntry = '';

                // desemparejamos la llamada que se puso en espera
                this.unpairingCall();

                // si hay tono de llamada en ejecucion lo pausamos
                this.pauseRingtone();

                // ejecutamos el callback para indicar que la llamada fue contestada
                this.answerCallCallback();

                //emparejamos la llamada
                await this.pairingCall(this.io_call.participant_token);

                // habilitamos el microfono
                this.bandwidthRtc.setMicEnabled(true);
            });
    }


    /**
     * permite intercambiar la llamada, es decir, puede tener una llamada en espera y la otra activa, entonces ponerlas al reves
     */
    async callSwap() {

        // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
        this.setNewStatus(DialerStatus.LOADING);

        // verificamos el estado actual para saber de donde obtener la informacion de la llamada
        if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL)) {

            // ejecutamos la accion de poner en espera la llamada
            await this.phoneSystemService.holdCall(this.active_call.call_id);

            // desemparejamos la llamada que se puso en espera
            this.unpairingCall();

            // clonamos la informacion la llamada en espera que va para activa
            const newActiveCall = Object.assign({}, this.hold_on_call);

            // ponemos la informacion de la llamada que estaba activa en espera
            this.hold_on_call = Object.assign({}, this.active_call);

            // ponemos la informacion de la llamada que estaba en hold en la activa
            this.active_call = newActiveCall;

            // conectamos a la llamada
            await this.pairingCall(this.active_call.participant_token);

            // ejecutamos la accion de poner la llamada en espera en activa
            await this.phoneSystemService.holdCallResume(this.active_call.call_id);

            // habilitamos el microfono
            this.bandwidthRtc.setMicEnabled(this.active_call.mute == false);

            // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
            this.setNewStatus(DialerStatus.CALL_ON_HOLD___ACTIVE_CALL);
        }



        // verificamos el estado actual para saber de donde obtener la informacion de la llamada
        if (this.compareDialerStatus(DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD)) {

            // desemparejamos la llamada que esta en espera
            this.unpairingCall();

            // clonamos la informacion la llamada en espera que va para activa
            const newActiveCall = Object.assign({}, this.hold_on_call);

            // ponemos la informacion de la llamada que estaba activa en espera
            this.hold_on_call = Object.assign({}, this.active_call);

            // ponemos la informacion de la llamada que estaba en hold en la activa
            this.active_call = newActiveCall;

            // conectamos a la llamada
            await this.pairingCall(this.active_call.participant_token);

            // habilitamos el microfono
            this.bandwidthRtc.setMicEnabled(this.active_call.mute == false);

            // establecemos la vista de cargando, para indicar que se esta ejecutando un proceso
            this.setNewStatus(DialerStatus.CALL_ON_HOLD___CALL_ON_HOLD);
        }
    }










    /*************************************
     * METODOS ESPECIALES SOBRE LA VISTA *
     *************************************/



    /**
     * permite seleccionar la linea telefonica de la lista
     * @param phoneline 
     */
    doPhoneLineSelect(phoneLine: PhoneLine) {
        this.phoneLineSelected = phoneLine;
        this.phoneNumberEntry = phoneLine.phone_number;
    }

    /**
     * registra la pulsacion de un boton del pad
     * @param simbol 
     */
    dialedDigit(simbol: number | string): void {

        // verificamos si el telefono esta en una llamada
        if (this.isDialerOnActiveCall()) {
            this.bandwidthRtc.sendDtmf(String(simbol));
        }

        // si el telefono no esta en llamada y tiene menos de 10 digitos o si esta en llamada activa, anexamos el valor ingresado al de la variable general
        if ((this.compareDialerStatus("REST") && this.phoneNumberEntry.length < 10) || this.isDialerOnActiveCall()) {
            this.phoneNumberEntry = this.phoneNumberEntry + simbol;
        }
    }

    /**
     * permite borrar el ultimo simbolo ingresado en el pad
     */
    deletedDigit() {
        this.phoneNumberEntry = this.phoneNumberEntry.slice(0, -1);
    }

    /**
     * Permite mostrar el teclado numerico en una llamada emparejada
     */
    toogleNumberKeypad() {

        // verificamos si esta en el estado correspondiente para mostrar el teclado numerico durante una llamada
        if (this.isTheCallEnabledToHangUp()) {

            // cambiamos el estado del teclado numerico
            this.keypadEnabled = (this.keypadEnabled == "ACTIVE_CALL_OPTIONS") ? "NUMERIC" : "ACTIVE_CALL_OPTIONS";
        }
    }

    /**
     * Permite mostrar o ocultar el pad que muestra los contactos para llamar
     */
    toogleContactsKeypad() {

        // verificamos si esta en el estado correspondiente para mostrar el teclado numerico durante una llamada
        if (this.isTheCallEnabledToHangUp()) {

            // cambiamos el estado del teclado numerico
            this.keypadEnabled = (this.keypadEnabled == "ACTIVE_CALL_OPTIONS") ? "CONTACTS" : "ACTIVE_CALL_OPTIONS";
        }
    }

    /**
     * permite habilitar o silenciar el microphone de la llamada actual
     */
    toogleMute() {
        // ponemos el estado contrario del estado del microphone
        this.active_call.mute = !this.active_call.mute;

        // actualizamos ese estado en el microphone
        this.bandwidthRtc.setMicEnabled(this.active_call.mute);
    }


}
