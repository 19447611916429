export class EstimateStorage {
    public id: string;
    public storage_months: number;
    public monthly_fee_by_cf: number;
    public first_month_free: boolean;
    public label_first_month_free: string;
    public total_amount: number;


    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.storage_months = null;
        this.monthly_fee_by_cf = null;
        this.first_month_free = null;
        this.label_first_month_free = null;
        this.total_amount = null;
    }
}
