import { PhoneRingDestination } from "../workspace/phone-ring-destination";
import { File } from '../global/file';

export class PhoneLine {

    public id: number;

    public name: string;
    public phone_number: "";
    public destination: PhoneRingDestination;
    public company_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.phone_number = null;
        this.destination = new PhoneRingDestination();
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }

}
