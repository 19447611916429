<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                Leads Report
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Leads Report
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-secondary btn-download" (click)="search(true)">
                    <i class="fa-solid fa-file-arrow-down"></i> Download
                </a>
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">
      
        <div class="col-lg-12 col-md-12">
            <div class="row">             
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="type-filter-date text-center color-text">
                        <app-date-filter class="date-filter-top-section" [useAll]="false" (onChange)="getFilterDates($event)">
                        </app-date-filter>
                    </div>
                </div>
            </div>
        </div>

        <div class="row col-lg-12 col-md-12 block-filter">

            <div class="col col-22 pr-0">
                <label class="control-label">Date Type</label>
                <select name="selectDateType" id="selectDateType" [(ngModel)]="selectDateType" class="form-control">                                
                    <option value="OPEN" Selected>Open Date</option>
                    <option value="MOVE">Move Date</option>
                </select>
            </div>

            <div class="col col-22 pr-0">                      
                <label class="control-label">Service</label>                     
                <select [(ngModel)]="selectTypeService" name="wd_service" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                    <option value="1">All</option>
                    <option value="2">LOCAL</option>
                    <option value="3">LONG</option>
                    <option value="4">MOVING</option>
                    <option value="5">AUTO</option>
                </select>                       
            </div>    

            <div class="col col-22 pr-0">
                <label class="control-label">Status</label>                       
                <select [(ngModel)]="leadStatus" name="wd_employees_id" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                    <option value="BOOKED">BOOKED</option>
                    <option value="DEAD_LEAD">DEAD LEAD</option>
                    <option value="DELIVERED">DELIVERED</option>
                    <option value="IN_ESTIMATION">IN ESTIMATION</option>
                    <option value="IN_STORAGE">IN STORAGE</option>
                    <option value="IN_TRANSIT">IN TRANSIT</option>
                    <option value="NEW">NEW</option>
                    <option value="NOT_CONTACTED">NOT CONTACTED</option>
                    <option value="PICKED_UP">PICKED UP</option>
                </select>
            </div>

            <div class="col col-22 pr-0">
                <label class="control-label">Advertiser</label>                        
                <select name="property_type" id="state" [(ngModel)]="advertiser" class="form-control">
                    <option *ngFor="let advertiser of advertisers" value="{{advertiser.id}}">
                        {{advertiser.name | filterReplace}}
                    </option>
                </select>
            </div>

            <div class="col">
                <button class="btn btn-warning btn-search" (click)="search(false)">
                    Search
                </button>
            </div>

        </div>     
          
    </div>

    <div class="row">
        <div class="col-lg-12">

            <div class="widget-content widget-content-area">

                <div class="block-total-leads">
                    <span class="txt-total-leads">TOTAL LEADS</span>
                    <span class="total-leads">{{paginator.paginator.total}}</span> 
                </div>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Customer</th>
                                <th>Move Date</th>
                                <th>From</th>
                                <th>Destination</th>
                                <th>Service</th>
                                <th>Advertiser</th>
                                <th>Company</th>
                                <th>Status</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let exportLead of paginator.data; index as i;">
                                <td>{{i + 1}}</td>
                                <td>
                                    <div>
                                        {{exportLead.customer.name}} {{exportLead.customer.last_name}}
                                    </div>
                                    <div>
                                        {{exportLead.customer.email}}
                                    </div>
                                    <div>
                                        {{exportLead.customer.phone | phone:'US'}}
                                    </div>
                                </td>
                                <td>{{exportLead.move_date | date}}</td>
                                <td>{{exportLead.from.state}} {{exportLead.from.zip_code}}</td>
                                <td>{{exportLead.to.state}}  {{exportLead.to.zip_code}}</td>
                                <td>{{exportLead.service | filterReplace}}</td>
                                <td>{{exportLead.advertiser?.name | filterReplace}}</td>
                                <td class="p-0">
                                    <div class="row-company-lead" *ngFor="let company of exportLead.company">
                                        <span class="p-2">{{company}}</span>
                                    </div>
                                </td>
                                <td class="p-0">
                                    <div class="row-company-lead" *ngFor="let status of exportLead.status">
                                        <span class="p-2" *ngIf="status == 'BOOKED'">BOOKED</span>
                                        <span class="p-2" *ngIf="status == 'DEAD_LEAD'">DEAD LEAD</span>
                                        <span class="p-2" *ngIf="status == 'DELIVERED'">DELIVERED</span>
                                        <span class="p-2" *ngIf="status == 'IN_ESTIMATION'">IN ESTIMATION</span>
                                        <span class="p-2" *ngIf="status == 'IN_STORAGE'">IN STORAGE</span>
                                        <span class="p-2" *ngIf="status == 'IN_TRANSIT'">IN TRANSIT</span>
                                        <span class="p-2" *ngIf="status == 'NEW'">NEW</span>
                                        <span class="p-2" *ngIf="status == 'NOT_CONTACTED'">NOT CONTACTED</span>
                                        <span class="p-2" *ngIf="status == 'PICKED_UP'">PICKED UP</span>
                                    </div>
                                </td>
                                <td>{{exportLead.created | date}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event">
                    </pagination-controls> -->
                    <app-custom-paginator [currentPage]="paginator.paginator.page"
                        [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page"
                        (pageChange)="setCurrentPage($event)"></app-custom-paginator>

                </div>
            </div>
        </div>
    </div>

</div>