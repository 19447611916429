<!-- <div class="widget widget-one_hybrid widget-followers">
    <div class="widget-heading">
        <h5>
            Total bad Leads in the Last 7 Days
        </h5>
    </div>
    <div class="widget-content">
        <div class="text-center">
            <h1>
                {{jobsTotalBadLeads.total}}
            </h1>
            <h4>
                &nbsp;
            </h4>

        </div>
    </div>
</div> -->



<div class="widget-one widget counter">
    <div class="widget-content">
        <div class="w-numeric-value">
            <div class="col-8 w-content">
                <!-- <span class="w-value">{{jobsTotalIncomingLeads.total}}</span> -->
                <div class="w-text font-s20 margin-b4">Total Bad Leads</div>
                <div class="w-text font-s18 margin-b4">Last 7 Days</div>
                <div class="w-text d-flex align-items-center font-s18">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-circle"><circle cx="12" cy="12" r="10"></circle><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg>
                    1.21% -->
                </div>
            </div>
            <div class="col-4 w-content-center">
                <span class="w-numeric-quantity">{{jobsTotalBadLeads.total}}</span>
            </div>
        </div>
        <div class="w-chart">
            <div id="total-bad-leads"></div>
        </div>
    </div>
</div>