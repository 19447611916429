import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { CCProcessingService } from 'src/app/services/companies/cc-processing.service';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Paginator } from 'src/app/entities/helpers/paginator';

@Component({
  selector: 'app-cc-processing',
  templateUrl: './cc-processing.component.html',
  styleUrls: ['./cc-processing.component.scss']
})
export class CcProcessingComponent implements OnInit {

  public betweenFilter: BetweenDates;
  public paginator: Paginator;
  public totalAgentPay: number;
  public totalCommLabor: number;
  public totalDeliveryCost: number;
  public totalExpenses: number;
  public totalJob: number;
  public totalJPAP: number;
  public totalMoniesCollected: number;
  public totalMoniesDue: number;
  public totalProitLoss: number;
  public totalRefunds: number;
  public totalRows: number;

  public start_amount: number | '';
  public end_amount: number | '';

  constructor(
    private helperService: HelperService,
    private ccProcessingService: CCProcessingService
  ) {
    this.betweenFilter = new BetweenDates();
    this.paginator = new Paginator();
    this.totalRows = 0;
    this.totalJob = 0;
    this.totalAgentPay = 0;
    this.totalJPAP = 0;
    this.totalExpenses = 0;
    this.totalCommLabor = 0;
    this.totalDeliveryCost = 0;
    this.totalRefunds = 0;
    this.totalProitLoss = 0;
    this.totalMoniesCollected = 0;
    this.totalMoniesDue = 0;

    this.start_amount = '';
    this.end_amount = '';
  }

  ngOnInit(): void {
    this.loadData(true);
  }

  getFilterDates(dataReturn: BetweenDates) {
      this.betweenFilter = dataReturn;
  }

  loadData(firstPage: boolean) {
    this.helperService.showLoadingMxpro360();
    this.paginator.paginator.start = this.betweenFilter.dateStart;
    this.paginator.paginator.end = this.betweenFilter.dateEnd;
    if (firstPage) {
      this.paginator.paginator.page = 1;
    }
    this.ccProcessingService.get(this.paginator, this.start_amount, this.end_amount)
    .then(response => {
      this.paginator.data = response.data;
      this.paginator.paginator.total = response.paginator.total;
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.loadData(false);
    }
  }

  openNewTab(jobId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(jobId, 'payments');
  }

}
