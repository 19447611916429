export class LiabilityAmount {
  public id: string;
  public up_to: number;
  public created: number;
  public updated: number;

  constructor() {
      this.id = null;
      this.up_to = null;
      this.created = null;
      this.updated = null;

  }
}
