import { File } from 'src/app/entities/global/file';

export class JobFile {
    public id: string;
    public pickup_files: Array<File>;
    public delivery_files: Array<File>;
    public video_walkthroughs: Array<File>;
    public digital_signatures: Array<File>;

    constructor() {
        this.id = null;
        this.pickup_files = null;
        this.delivery_files = null;
        this.video_walkthroughs = null;
        this.digital_signatures = null;
    }
}
