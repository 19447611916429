import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlackoutMoveDatesService } from 'src/app/services/workspaces/blackout-move-dates.service';
import { BlackoutMoveDatesView } from 'src/app/entities/workspace/blackout-move-dates-view';
import { BlackoutMoveDate } from 'src/app/entities/workspace/blackout-move-date';
import { HelperService } from 'src/app/services/helper.service';


declare var jQuery;
declare var moment;
@Component({
    selector: 'app-blackout-move-dates-form',
    templateUrl: './blackout-move-dates-form.component.html',
    styleUrls: ['./blackout-move-dates-form.component.scss']
})
export class BlackoutMoveDatesFormComponent implements OnInit {

    dateBlackout;


    public blackoutMoveDatesView: BlackoutMoveDatesView;

    dataForm: BlackoutMoveDate;
    constructor(
        private currentRoute: ActivatedRoute,
        private blackoutMoveDatesService: BlackoutMoveDatesService,
        private helperService: HelperService,
    ) {
        this.dataForm = new BlackoutMoveDate();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.currentRoute.params.subscribe(async params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    load(id: string): void {
        this.helperService.showLoadingMxpro360();
        this.blackoutMoveDatesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;

            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }


    changeDate($event) {
        this.dateBlackout = $event;
        this.dataForm.date_string = this.dateBlackout;
        var newDate = moment(this.dateBlackout).format(moment.HTML5_FMT.DATE);
        this.dataForm.date = newDate;
    }


    save() {
        this.helperService.showLoadingMxpro360();
        this.blackoutMoveDatesService
            .save(this.dataForm)
            .then((response) => {
                this.helperService.goToWorkspaceRouterLink('/blackout-move-dates');
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
