import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BoardJobView } from 'src/app/entities/workspace/board-job-view';
import { HelperService } from 'src/app/services/helper.service';
import { BoardJobsService } from 'src/app/services/workspaces/board-jobs.service';
import { JobsService } from 'src/app/services/workspaces/jobs.service';



declare var jQuery;
@Component({
  selector: 'app-board-jobs-form',
  templateUrl: './board-jobs-form.component.html',
  styleUrls: ['./board-jobs-form.component.scss']
})
export class BoardJobsFormComponent implements OnInit {

    public boardJob: BoardJobView;
    public jobs: Array<any>

    constructor(
      private currentRoute: ActivatedRoute,
        private boardJobsService: BoardJobsService,
        private jobsService: JobsService,
        private helperService: HelperService,
    ) {
      this.boardJob = new BoardJobView();
    }
  
    ngOnInit(): void {
       
    }
  
    ngAfterViewInit(): void {
        this.getJobsUnassigned();
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
      this.helperService.showLoadingMxpro360();
       this.boardJobsService
          .getById(id)
          .then((response) => {
            this.boardJob = response;
          })
          .catch((error) => {
  
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
    }
  
    save() {
      this.helperService.showLoadingMxpro360();
      this.boardJobsService
          .save(this.boardJob)
          .then((response) => {
            this.helperService.goToWorkspaceRouterLink('/board-jobs');
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
    }
  
    getJobsUnassigned(){
        
      this.helperService.showLoadingMxpro360();
      this.jobsService
          .getAllUnassigned()
          .then((response) => {
              this.jobs = response;
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
    }
  

}
