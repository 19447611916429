import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
    selector: 'app-groupal-log-viewed-estimate',
    templateUrl: './groupal-log-viewed-estimate.component.html',
    styleUrls: ['./groupal-log-viewed-estimate.component.scss']
})
export class GroupalLogViewedEstimateComponent implements OnInit {
    theLastTenTobsTheyViewedWithaLinks: any;

    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.theLastTenTobsTheyViewedWithaLinks = [];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.getTheLastTenTobsTheyViewedWithaLinkCompany();
        } else {
            this.getTheLastTenTobsTheyViewedWithaLinkWorkspace();
        }
    }


    getTheLastTenTobsTheyViewedWithaLinkWorkspace() {

        this.dashboardWorkspaceService
            .getTheLastTenTobsTheyViewedWithaLinkToSeeThem()
            .then((response) => {
                this.theLastTenTobsTheyViewedWithaLinks = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


    getTheLastTenTobsTheyViewedWithaLinkCompany() {

        this.dashboardComopanyService
            .getTheLastTenTobsTheyViewedWithaLinkToSeeThem()
            .then((response) => {
                this.theLastTenTobsTheyViewedWithaLinks = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


}
