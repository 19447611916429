import { DOCUMENT } from '@angular/common';
import { Directive, HostBinding, HostListener, Inject } from '@angular/core';

@Directive({
  selector: '[alphaNumeric]'
})
export class AlphaNumericDirective {

  
  @HostBinding('autocomplete') public autocomplete
  
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.autocomplete = 'off'
   }

   @HostListener('keypress', ['$event']) public disableKeys(e: any) {
    this.document.all ? e.keyCode : e.keyCode;
    return  e.keyCode == 8 ||
            e.keyCode == 46 || // punto (.)
            e.keyCode == 32 || // espacio ( )
            (e.keyCode >= 48 && e.keyCode <= 57) || // 0-9
            (e.keyCode >= 97 && e.keyCode <= 90) || // A-Z
            (e.keyCode >= 65 && e.keyCode <= 122) // a-z
  }
}
