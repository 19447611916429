import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Ticket } from 'src/app/entities/workspace/ticket';
import { TicketView } from 'src/app/entities/workspace/ticket-view';

@Injectable({
  providedIn: 'root'
})
export class EstimateTicketsService {

  constructor(private http: HttpClient) { }

  getAllByEstimate(id: string): Promise<Array<TicketView>> {
    return this.http.get<Array<TicketView>>(environment.api.company + '/estimates/' + id +
    '/tickets').toPromise();
  }

  getAllClosedByEstimate(id: string): Promise<Array<TicketView>> {
    return this.http.get<Array<TicketView>>(environment.api.company + '/estimates/' + id +
    '/tickets/closed').toPromise();
  }

  add(dataForm: Ticket) {
    return this.http.post(environment.api.company + '/tickets', dataForm).toPromise();
  }
}
