export class AutomationTicket {
    public id: string;
    public ticket_type_id: string;
    public description: string;
    
    constructor() {
        this.id = null;
        this.ticket_type_id = null;
        this.description = null;
       
    }
}
