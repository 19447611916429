export class Equation {
    
    id: string;
    variables: Array<string>;
    formula: string;

    constructor(){
        this.id = null;
        this.variables = [];
        this.formula = null;
    }

}
