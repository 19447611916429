<form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

    <div class=" layout-top-spacing">
        <div class="widget-content widget-content-area">
                        
            <div class="d-flex justify-content-between widget-heading">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 align-self-center">
                    <h3 class="title-content-area m-0">
                        Packing Materials
                    </h3>
                </div>
                
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 text-sm-right text-center align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <a class="btn btn-info pt-2" (click)="openModalCustomMaterial()">
                            <i class="fas fa-plus-square"></i> &nbsp; Add Custom Material                        
                        </a>
                        <button class="btn btn-main-action" type="submit">
                            Save
                        </button>
                    </div>               
                </div>
            </div>    

        </div>
    </div>
    <!-- header -->

    <div class=" layout-spacing layout-top-spacing">

        <div class="row">
            <div class="col-lg-12">
                <div class="widget-content widget-content-area">
                    
                    <!-- <div class="widget-heading">
                        <h3 class="title-content-area">
                            Packing Materials
                        </h3>
                        <div class="block-btns">
                            <a class="btn btn-info pt-2" (click)="openModalCustomMaterial()">
                                <i class="fas fa-plus-square"></i> &nbsp; Add Custom Material                        
                            </a>
                            <button class="btn btn-main-action" type="submit">
                                Save
                            </button>
                        </div>
                    </div>     -->              
                    
                    <div class="table-responsive">                    
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th class="th-item">Item</th>
                                    <th class="th-qty">Qty</th>
                                    <th class="th-price">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let rjpm of rowEstimateMaterials; let i = index">
                                    <td>
                                        {{rjpm.packing_material.name}}
                                    </td>
                                    <td class="td-qty">
                                        <input type="number" id="quantity_{{i}}" min="0" [(ngModel)]="rjpm.quantity" name="quantity_{{i}}" class="form-control text-center">
                                    </td>
                                    <td class="td-price">
                                        {{rjpm.packing_material.price | currency}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>                   
                    </div> 

                </div>
            </div>
        </div>

    </div>
    <!-- body -->
</form>

<div class="modal fade customItem" #customItem tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #entityForm="ngForm" ngNativeValidate (ngSubmit)="saveCustomMaterial()">

                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Custom Material
                    </h5>
                </div>
            
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content content-quantity">
                            <div class="row">
                                <div class="col-md-12 mb-4">
                                    <label for="start-date" class="">Item</label>
                                    <div class="d-flex event-title">
                                        <input type="text" placeholder="Name Item" class="form-control"
                                            [(ngModel)]="formCustomMaterial.packing_material.name" name="item_name_qty">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group start-date">
                                        <label for="start-date" class="">Price By Unit</label>
                                        <div class="d-flex">
                                            <input placeholder="Unit Price" class="form-control" type="number"
                                                [(ngModel)]="formCustomMaterial.packing_material.price" name="item_cf_qty">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12">
                                    <div class="form-group end-date">
                                        <label for="end-date" class="">Quantity</label>
                                        <div class="d-flex">
                                            <input placeholder="Items quantity" type="number" class="form-control"
                                                [(ngModel)]="formCustomMaterial.quantity" name="item_quantity_qty" min="1"
                                                required>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-close" (click)="closeModalCustomMaterial()">
                        Close
                    </button>
                    <button data-btnfn="addTask" class="btn btn-save mr-0" type="submit">
                        Add Item
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>