import { PhoneRingDestination } from "./phone-ring-destination";

export class PhoneIvrEntry {
    public id: number;
    public digit: number;
    public destination: PhoneRingDestination;

    constructor() {
        this.id = null;
        this.digit = null;
        this.destination = new PhoneRingDestination();
    }
}
