export class AutomationFilter {
    public id: string;
    public price_range: 'ALL' | 'SET';
    public price_range_start: number;
    public price_range_end: number;
    public size_range: 'ALL' | 'SET';
    public size_range_start: number;
    public size_range_end: number;
    public pickup_state: string[];
    public delivery_state: string[];
    
    constructor() {
        this.id = null;
        this.price_range = null;
        this.price_range_start = null;
        this.price_range_end = null;
        this.size_range = null;
        this.size_range_start = null;
        this.size_range_end = null;
        this.pickup_state = [];
        this.delivery_state = [];
        
    }
}
