import { AutomationFilter } from "./automation-filter";
import { AutomationTicket } from "./automation-ticket";

export class Automation {
    public id: string;
    public action: 'EMAIL' | 'TEXT' | 'CREATE_TICKET';
    public email_template_id: number;
    public sms_template_id: number;
    public ticket: AutomationTicket;
    public from: 'ASSIGNED_AGENT' | 'COMPANY';
    public to: 'CUSTOMER' | 'COMPANY_EMPLOYEES' | 'CUSTOM';
    public to_employee_id: number;
    public to_custom_email: number;
    public to_custom_phone: number;
    public time: 'WHEN' | 'SET_TIME';
    public time_set: 'BEFORE' | 'AFTER';
    public time_interval: 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
    public time_quantity: number;
    public action_time: 'AT_TRIGGER_EVENT' | 'SET_TIME';
    public action_time_set_start: any;
    public action_time_set_end: any;
    public action_time_set_zone:  'EASTERN' | 'CENTRAL' | 'MOUNTAIN' | 'PACIFIC' | 'HAWAIIAN' | 'ALASKAN';
    public status: 'LEAD' | 'ESTIMATE' | 'JOB';
    public trigger_event: 'ADDRESS_CHANGED' | 'BECOMES_ESTIMATE' | 'CANCEL_JOB' | 'CLICK_BOOK' | 'DELIVERY_IN_TRANSIT' | 'FADD' | 'FADD_DATE_CHANGED' | 'JAF_NOT_SIGNED' | 'JOB_DELIVERED' | 'JOB_DELIVERED_BOXES' | 'JOB_IN_TRANSIT' | 'JOB_IN_STORAGE' | 'JOB_NEW' | 'JOB_PACKED_MOVE' | 'JOB_PICKED_UP' | 'LEAD_ASSIGNED' | 'LEAD_CREATED' | 'MOVE_DATE' | 'PAYMENT_UPLOADED_IN_CUSTOMER_PORTAL' | 'PICKUP_DATE_CHANGED' | 'PICKUP_DATE' | 'PUT_ON_DRIVER_INVOICE' | 'QA_RELEASE' | 'RELEASE_JOB' | 'STORAGE_INVOICE_DUE' | 'STORAGE_PAYMENT_PAST' | 'TICKET_CREATED' | 'TICKET_NOT_RESOLVED' | 'TICKET_RESPONSE_CREATED' | 'VOLUME_CHANGED';    
    public category: 'HOT' | 'COLD' | 'OPEN' | 'NEW' | 'BAD' | 'ALL';
    public company_id: number;
    public filters: AutomationFilter;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.action = null;
        this.email_template_id = null;
        this.sms_template_id = null;
        this.ticket = new AutomationTicket();
        this.from = null;
        this.to = null;
        this.to_employee_id = null;
        this.to_custom_email = null;
        this.to_custom_phone = null;
        this.time = null;
        this.time_set = null;
        this.time_interval = null;
        this.time_quantity = null;
        this.action_time = null;
        this.action_time_set_start = null;
        this.action_time_set_end = null;
        this.action_time_set_zone = null;
        this.status = null;
        this.trigger_event = null;
        this.category = null;
        this.company_id = null;
        this.filters = new AutomationFilter();
        this.created = null;
        this.updated = null;
    }
}
    
