<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 align-self-center mb-3">
            <h3>
                Carriers/Brokers
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Carriers</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-4 col-sm-6 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" type="text" class="form-control product-search" id="input-search"
                        placeholder="Filter..." [(ngModel)]="filterSearch" (keydown)="actualPage=1">
                </div>
            </form>
        </div>

        <div class="col-xl-2 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appWorkspaceRouterLink="/carriers/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Carrier
                </a>
            </div>
        </div>

    </div>  

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th class="text-center">Balance</th>
                                <th class="text-center">Unsigned</th>
                                <th class="text-center">Open</th>
                                <th class="text-center">Closed</th>
                                <th class="text-center">Future</th>
                                <th class="text-center">Customer Canceled</th>
                                <th class="text-center">Carrier Canceled</th>
                                <th class="text-center">Option</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let carrier of carriers | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;"
                                [ngStyle]="carrier.active ? {'background-color': '#ffffff'} : {'background-color': '#f5a0a5!important'}">
                                <td>
                                    {{carrier.name | titlecase}}
                                </td>
                                <td class="text-center">
                                    {{carrier.balance | currency}}
                                </td>
                                <td class="text-center">
                                    {{carrier.unsigned_jobs}}
                                </td>
                                <td class="text-center">
                                    {{carrier.open_jobs}}
                                </td>
                                <td class="text-center">
                                    {{carrier.closed_jobs}}
                                </td>
                                <td class="text-center">
                                    {{carrier.future_jobs}}
                                </td>
                                <td class="text-center">
                                    {{carrier.customer_canceled}}
                                </td>
                                <td class="text-center">
                                    {{carrier.carrier_canceled}}
                                </td>
                                <td class="text-center" style="min-width: 100px;">
                                    <button class="btn-company" (click)="openCarrierDetail(carrier.id)">
                                        <i class="fa-solid fa-building"></i>                        
                                    </button>                         
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>

                </div>
            </div>
        </div>
    </div>


</div>