import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouteRateView } from 'src/app/entities/workspace/route-rate-view';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class RouteRatesService {

    constructor(private http: HttpClient) {

    }

    getAll(zoneOutId: string, periodId: string, tariff_version_id: string): Promise<Array<RouteRateView>> {
        return this.http.get<Array<RouteRateView>>(environment.api.workspace + '/route-rates', { params: { "period_id": periodId, "zone_out_id": zoneOutId, "tariff_version_id": tariff_version_id } }).toPromise();
    }

    getRouteRate(zone_out_id: string, zone_in_id: string, period_id: string, tariff_version_id: string): Promise<Array<RouteRateView>> {
        return this.http.get<Array<RouteRateView>>(environment.api.workspace + '/route-rates', { params: { period_id, zone_in_id, zone_out_id, tariff_version_id } }).toPromise();
    }

    save(data: Array<RouteRateView>) {
        return this.http.post(environment.api.workspace + '/route-rates', data).toPromise();
    }

}
