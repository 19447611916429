import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SmsCampaign } from '../../entities/workspace/sms-campaign';

@Injectable({
  providedIn: 'root'
})
export class SmsCampaignsService {


  constructor(
    private http: HttpClient
  ) { }

  saveCampaign(dataForm: any) {
    return this.http.post(environment.api.company + '/sms-campaigns', dataForm).toPromise();
  }

  updateCampaign(dataForm: any) {
    return this.http.put(environment.api.company + '/sms-campaigns/' + dataForm.email_campaign.id, dataForm).toPromise();
  }

  getAllCampaigns(): Promise<Array<SmsCampaign>> {
    return this.http.get<Array<SmsCampaign>>(environment.api.company + '/sms-campaigns').toPromise();
  }

  getCampaign(idCampaign: string): Promise<SmsCampaign> {
    return this.http.get<SmsCampaign>(environment.api.company + '/sms-campaigns/' + idCampaign).toPromise();
  }

  sendEmails(campaign: SmsCampaign) {
    return this.http.post(environment.api.company + '/send-sms-campaigns/' + campaign.id, campaign).toPromise();
  }
}
