import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Invoice } from 'src/app/entities/admin/invoice';
import { WorkspaceInvoicesService } from 'src/app/services/accounts/workspace-invoices.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-workspaces-unauthorized-outstanding-invoices',
  templateUrl: './workspaces-unauthorized-outstanding-invoices.component.html',
  styleUrls: ['./workspaces-unauthorized-outstanding-invoices.component.scss']
})
export class WorkspacesUnauthorizedOutstandingInvoicesComponent implements OnInit {

  constructor(
    public authService: AuthService,
    private workspaceInvoicesService: WorkspaceInvoicesService,
    public router: Router,
  ) { }

  ngOnInit(): void {

    const fullUrl = window.location.href.split('#');

    let workspace_id = null;

    if (fullUrl.length >= 2) {
      const path = fullUrl[1].split('/');

      if (path.length >= 2) {
        if (path[1] === "workspaces") {
          workspace_id = path[2];
        }
      }

      this.authService.loadWorkspaceSession(path[2]);

      this.workspaceInvoicesService
        .getAllByPending(workspace_id)
        .then((unPaidInvoices: Array<Invoice>) => {

          if (unPaidInvoices.length < 2) {
            this.router.navigateByUrl(`/workspace/${workspace_id}/dashboard`);
          }
        }, (error) => {
          console.log('error => ', error);
        });
    }
  }

}
