import { LeadVehicle } from './lead-vehicle';
import { LeadCompany } from './lead-company';
import { LeadView } from './lead-view';
import { WorkDepartment } from './work-department';

export class LeadCompanyView extends LeadCompany {

    public lead: LeadView;
    public work_department: WorkDepartment;
    public vehicles: LeadVehicle;
    constructor() {
        super();
        this.lead = new LeadView();
        this.work_department = new WorkDepartment();
        this.vehicles = null;
    }
}
