import { Geospatial } from "../global/geospatial";


export class CarrierCoverage {

    public id: string;
    public type: 'BOTH' | 'PICKUP' | 'DELIVERY';
    public state: string;
    public state_code: string;
    public zip_code: string;
    public radius: number;
    public carrier_id: string;
    public geospatial: Geospatial;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.type = null;
        this.state = null;
        this.state_code = null;
        this.zip_code = null;
        this.radius = null;
        this.carrier_id = null;
        this.geospatial = new Geospatial();
        this.created = null;
        this.updated = null;
    }
}
