import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Employee } from 'src/app/entities/workspace/employee';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { FollowUpsRemindersService } from 'src/app/services/companies/follow-ups-reminders.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { FollowUpReminderCalendar } from '../../../entities/workspace/follow-up-reminder-calendar';
import { ScheduleCalendar } from '../../../entities/workspace/schedule-calendar';

declare const moment;
declare const jQuery;

@Component({
    selector: 'app-follow-up-reminders',
    templateUrl: './follow-up-reminders.component.html',
    styleUrls: ['./follow-up-reminders.component.scss']
})
export class FollowUpRemindersComponent implements OnInit, AfterViewInit {

    @ViewChild('divCalendar')
    divCalendar: ElementRef;

    public followUpReminders: Array<FollowUpReminderCalendar>;
    hiddenCalendar: boolean;
    calendar: any;
    arrayCalendar: Array<ScheduleCalendar>;
    calendario: ScheduleCalendar;
    startDate: string;
    endDate: string;
    startDay: string;
    endDay: string;
    remark: string;
    date: number;
    estimates: Array<any>;
    category: 'ALL' | 'NEW' | 'HOT' | 'WARM' | 'COLD' | 'OPEN';
    orderBy: 'FOLLOWUPDATE' | 'MOVEDATE';
    appointmentType: 'ALL' | 'FOLLOW_UP' | 'QA_APPOINTMENT';

    public employees: Array<Employee>;
    public showFilter: boolean;
    public employee_id: string;
    public actualPage: number;
    public generalSettings: GeneralSettings;
    public numberTab: number;

    constructor(
        public authService: AuthService,
        private followUpsRemindersService: FollowUpsRemindersService,
        private helperService: HelperService,
        private estimatesService: EstimatesService,
        private employeesService: EmployeesCompanyService,
        private generalSettingsService: GeneralSettingsService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.arrayCalendar = [];
        this.calendario = new ScheduleCalendar();
        this.startDay = '';
        this.endDay = '';
        this.remark = '';
        this.date = 0;
        this.hiddenCalendar = false;
        this.getDates();
        this.employees = [];
        this.estimates = [];
        this.employee_id = this.authService.workspaceSession.employee.id;
        this.category = 'ALL';
        this.orderBy = 'FOLLOWUPDATE';
        this.appointmentType = 'ALL';
        this.showFilter = false;
        this.actualPage = 1;
        this.followUpReminders = [];
        this.generalSettings = new GeneralSettings();
        this.numberTab = 1;
    }


    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.loadEmployees();
        this.loadSettings();
        const that = this;

        setTimeout(() => {
            jQuery('.fc-next-button, .fc-prev-button').click(async () => {

                jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
                that.startDate = '' + moment(jQuery(this.divCalendar.nativeElement)
                    .fullCalendar('getView').start._d, moment.HTML5_FMT.DATE).unix() * 1000;
                that.endDate = '' + moment(jQuery(this.divCalendar.nativeElement)
                    .fullCalendar('getView').end._d, moment.HTML5_FMT.DATE).unix() * 1000;
                await this.loadFollowUpsReminders();
            });
            jQuery('.fc-month-button').click(async () => {

                jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
                that.startDate = '' + moment().date(1).second(0).minute(0).hour(0).unix() * 1000;
                that.endDate = '' + moment(new Date()).date(moment(new Date()).daysInMonth()).second(59).minute(59).hour(23).unix() * 1000;
                await this.loadFollowUpsReminders();
            });
            jQuery('.fc-agendaWeek-button').click(async () => {

                jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
                that.startDate = '' + moment().startOf('week').unix() * 1000;
                that.endDate = '' + moment().endOf('week').unix() * 1000;
                await this.loadFollowUpsReminders();
            });
            jQuery('.fc-agendaDay-button').click(async () => {

                jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
                that.startDate = '' + moment().second(0).minute(0).hour(0).unix() * 1000;
                that.endDate = '' + moment().second(59).minute(59).hour(23).unix() * 1000;
                await this.loadFollowUpsReminders();
            });
        }, 2000);

        this.loadCalendar();
    }

    private loadSettings() {
        this.generalSettingsService
            .get()
            .then((responseSettings) => {
                this.generalSettings = responseSettings;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
            });
    }

    async loadCalendar() {
        const that = this;
        jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');

        this.loadFollowUpsReminders();

        jQuery(this.divCalendar.nativeElement).fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay'
            },
            eventRender: (eventObj, $el) => {
                $el.popover({
                    title: eventObj.title,
                    content: eventObj.description,
                    trigger: 'hover',
                    html: true,
                    placement: 'top',
                    container: 'body'
                });
            },
            events: this.arrayCalendar,
            editable: false,
            eventLimit: false,
            eventMouseover: (event, jsEvent, view) => {
                jQuery(this).attr('id', event.id);
                jQuery('#' + event.id).popover({
                    template: '<div class="popover popover-primary" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                    title: event.title,
                    content: event.description,
                    placement: 'top',
                });
                jQuery('#' + event.id).popover('show');
            },
            eventMouseout: (event, jsEvent, view) => {
                jQuery('#' + event.id).popover('hide');
            },
            eventClick: (info) => {
                that.helperService.openNewTab(info.estimate_id, 'estimate');
            }
        });
    }

    /**
     * Metodo para filtrar las categorias, empleados y el tipo de ordenamiento
     */
    async showEventsCalendar() {
        this.arrayCalendar = [];
        this.calendario = new ScheduleCalendar();

        setTimeout(async () => {
            jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
            await this.loadFollowUpsReminders();
        }, 500);
    }

    /**
     * Convvierte la fecha numerica a fecha humana
     *
     * @param dateNumber Fecha numerica
     */
    private convertDateTime(dateNumber): string {
        return moment(dateNumber).format('hh:mm A');
    }

    public loadFollowUpsReminders() {
        this.helperService.showLoadingMxpro360();
        const data = {
            employee_id: this.employee_id,
            category: this.category,
            order_by: this.orderBy,
            appointment_type: this.appointmentType,
            start: this.startDate,
            end: this.endDate
        };

        this.followUpReminders = [];
        return new Promise(async (resolve) => {

            this.followUpsRemindersService.loadCalendarFollowUp(data).then(async (response) => {
                this.followUpReminders = response;

                this.hiddenCalendar = true;
                this.arrayCalendar = [];

                for (const followUpReminder of this.followUpReminders) {

                    this.calendario.title = followUpReminder.estimate.document.code + ' | ' + followUpReminder.customer.name + ' '
                        + followUpReminder.customer.last_name;

                    if (this.orderBy === 'MOVEDATE') {

                        this.calendario.id = followUpReminder.estimate.id + '_day';

                        this.calendario.estimate_id = followUpReminder.estimate.id;

                        this.calendario.description = this.convertDateTime(followUpReminder.estimate.pickup.range_start) +
                            ' | ' + followUpReminder.estimate.service;

                        this.calendario.start = moment(followUpReminder.estimate.pickup.range_start)
                            .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        this.calendario.end = moment(followUpReminder.estimate.pickup.range_start)
                            .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

                        this.calendario.className = 'bg-danger';
                    } else {

                        this.calendario.id = followUpReminder.follow_up_reminder.id + '_day';

                        this.calendario.estimate_id = followUpReminder.follow_up_reminder.estimate_id;

                        this.calendario.description = followUpReminder.follow_up_reminder.remarks !== null ?
                            this.convertDateTime(followUpReminder.follow_up_reminder.date) +
                            ' | ' + followUpReminder.follow_up_reminder.remarks :
                            this.convertDateTime(followUpReminder.follow_up_reminder.date) + ' | No Remark';

                        this.calendario.start = moment(followUpReminder.follow_up_reminder.date)
                            .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        this.calendario.end = moment(followUpReminder.follow_up_reminder.date)
                            .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

                        this.calendario.className = 'bg-success';

                        if (followUpReminder.follow_up_reminder.qa_appointment) {
                            this.calendario.className = 'bg-secondary text-light';
                        }
                    }

                    this.arrayCalendar.push(this.calendario);
                    this.calendario = new ScheduleCalendar();
                }

                jQuery(this.divCalendar.nativeElement).fullCalendar('addEventSource', this.arrayCalendar);

                resolve(true);
            }).catch((error) => {
                console.error('Error', error);
                resolve(false);
            }).finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        });
    }

    private loadEmployees() {
        this.employeesService
            .getAllSellers()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
            });
    }

    /**
     * Metodo que obtiene las fechas actuales del mes en el calendario
     */
    getDates() {
        const date = new Date();
        // Obtiene el primer dia del mes
        const firsDay = new Date(date.getFullYear(), date.getMonth(), 1);
        // Obtiene el ultimo dia del mes
        const mounth = date.getMonth() + 1;
        const lastDay = new Date(date.getFullYear(), mounth, 0);

        // Se convierte el primer dia en cadena para agregar un 0 al inicio
        const firstCalendarDay = firsDay.getDate().toString();
        // Se convierte el mes en cadena para agregar un 0 al inicio
        const calendarMonth = mounth.toString();

        // Si el dia contiene un solo digito, se agrega un 0 al inicio
        const firstDayConverted = firstCalendarDay.length === 1 ? '0' + firstCalendarDay : firstCalendarDay;
        // Si el mes contiene un solo digito, se agrega un 0 al inicio
        const monthCalendarConverted = calendarMonth.length === 1 ? '0' + calendarMonth : calendarMonth;

        // Se obtienen las fechas en formato humano
        const initialHumanDate = date.getFullYear() + '-' + monthCalendarConverted + '-' + firstDayConverted + 'T00:00:00';
        const endHumanDate = date.getFullYear() + '-' + monthCalendarConverted + '-' + lastDay.getDate().toString() + 'T23:59:00';

        // Se inicia el proceso de conversion de la fecha a numerica
        const conversionInitialDate = moment(initialHumanDate).format(moment.HTML5_FMT.DATE);
        const conversionFinalDate = moment(endHumanDate).format(moment.HTML5_FMT.DATE);
        const initialConversion = conversionInitialDate.toString() + 'T00:00:00';
        const endConversion = conversionFinalDate.toString() + 'T20:00:00';

        const newStartDate = new Date(initialConversion);
        const newEndDate = new Date(endConversion);

        const initialNumericConversion = newStartDate.getTime();
        const finalNumericalConversion = newEndDate.getTime();
        // Se agregan las fechas en formato numerico
        this.startDate = initialNumericConversion + '';
        this.endDate = finalNumericalConversion + '';
    }

    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimateId) {
        // Redirige a la ventana de estmate
        this.helperService.openNewTab(estimateId, 'estimate');
    }

    changeCategory(idEstimate, category: string) {
        this.helperService.showLoadingMxpro360();
        const categoryEstimate = { category };
        this.estimatesService
            .patchEntity(idEstimate, categoryEstimate)
            .then(() => {
                this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    public changeTab(numberTab: number): void {
        this.showFilter = !this.showFilter;
        this.numberTab = numberTab;

        if (numberTab === 1) {
            jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
            this.loadCalendar();
        }
    }


    copyText(text: string, type: string) {

        if (type === 'phone') {
            text = text.replace(/-/g, '');
            text = text.substr(-10, 10);
        }

        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
    }

}
