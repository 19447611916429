<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Commission Settings
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Commission Settings</li>
                        </ol>
                    </nav>
                    <br>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input type="text" name="filterSearch" [(ngModel)]="filterSearch"
                                class="form-control product-search" id="input-search" placeholder="Filter...">
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appCompanyRouterLink="/commission-settings/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Commission Setting
                </a>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Type</th>
                                <th>Commission</th>
                                <th>Value Based On</th>
                                <th>Created</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let row of rows |search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>
                                    <span *ngIf="row.employee">
                                        {{row.employee.name | titlecase}}
                                    </span>
                                    <span *ngIf="!row.employee">
                                        nonexistent
                                    </span>
                                </td>
                                <td>{{row.type}}</td>
                                <td>
                                    <span *ngIf="row.type === 'FIXED'">
                                        {{row.value | currency}}
                                    </span>
                                    <span *ngIf="row.type === 'PERCENTAGE'">
                                        {{row.value | number}}%
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="row.value_base_on == 'JOB_DEPOSIT'"> Job Deposit</span>
                                    <span *ngIf="row.value_base_on == 'JOB_TOTAL'"> Job Total</span>
                                    <span *ngIf="row.value_base_on == 'ADDITIONAL_STOP_FEE'"> Additional Stop Fee </span>
                                    <span *ngIf="row.value_base_on == 'DISCOUNT_FULL_PACKING_SERVICE'"> Discount Full Packing Service </span>
                                    <span *ngIf="row.value_base_on == 'DISCOUNT_PER_MILE'"> Discount Per Mile </span>
                                    <span *ngIf="row.value_base_on == 'ELEVATOR_DELIVERY'"> Elevator Deliviry </span>
                                    <span *ngIf="row.value_base_on == 'ELEVATOR_PICKUP'"> Elevator Pickup </span>
                                    <span *ngIf="row.value_base_on == 'FULL_PACKING_SERVICE'"> Full Packing Service </span>
                                    <span *ngIf="row.value_base_on == 'LONG_CARRY_DELIVERY'"> Long Carry Deliviry </span>
                                    <span *ngIf="row.value_base_on == 'LONG_CARRY_PICKUP'"> Long Carry Pickup </span>
                                    <span *ngIf="row.value_base_on == 'SHUTTLE_DELIVERY'"> Shuttle Delivery </span>
                                    <span *ngIf="row.value_base_on == 'SHUTTLE_PICKUP'"> Shuttle Pickup </span>
                                    <span *ngIf="row.value_base_on == 'SPLIT_DELIVERY'"> Split Delivery </span>
                                    <span *ngIf="row.value_base_on == 'SPLIT_PICKUP'"> Split Pickup </span>
                                    <span *ngIf="row.value_base_on == 'JOB_SUB_TOTAL'"> Job Sub Total </span>
                                    <span *ngIf="row.value_base_on == 'SUB_TOTAL'"> Sub Total </span>
                                    <span *ngIf="row.value_base_on == 'DISCOUNT'"> Discount </span>
                                    <span *ngIf="row.value_base_on == 'BINDING_FEE'"> Binding Fee </span>
                                    <span *ngIf="row.value_base_on == 'TOTAL_BOL_AFTER_DISCOUNTS'"> Total BOL After Discounts </span>
                                    <span *ngIf="row.value_base_on == 'TOTAL_BOL'"> Total BOL </span>
                                    <span *ngIf="row.value_base_on == 'ADDITIONAL_BOL'"> Additional BOL </span>
                                </td>
                                <td>{{row.created | dateTime}}</td>
                                <td>
                                    <a appCompanyRouterLink="/commission-settings/{{row.id}}/edit"
                                        class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger button-space">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>