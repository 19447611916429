import { BulkyFee } from 'src/app/entities/workspace/bulky-fee';

export class InventoryItem {

    public id: string;
    public name: string;
    public cubic_feet: number;
    public bulky_fee: BulkyFee;
    public packed_by: 'CUSTOMER' | 'CARRIER';
    public customized: boolean;
    public inventory_category_id: string;

    constructor() {
        this.id = null;
        this.name = null;
        this.cubic_feet = 0;
        this.bulky_fee = null;
        this.packed_by = 'CUSTOMER';
        this.customized = false;
        this.inventory_category_id = null;
    }
}
