<div class=" layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span> Inventory Item Quotes
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
               
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/inventory-items">Inventory Item Quotes</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="row">
                            <div class="col-5">


                                <div class="form-group mb-4">
                                    <label class="control-label">Name</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.name" name="iiq_name" 
                                                class="form-control" required>
                                    </div>
    
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Quotes Name.</small>
                                </div>

                                <div class="form-group mb-4">
                                    <label class="control-label">Cubic feet</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg></span>
                                        </div>
                                        <input type="number" step="0.1" [(ngModel)]="dataForm.cubic_feet" name="iiq_cubic_feet" 
                                                class="form-control" required>
                                    </div>    
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Cubic Feet Number.</small>
                                </div>

                                <div class="form-group md-4 select-inventory-categorys">
                                    <label for="inputState">Inventory Category</label>
                                    <select id="inputState" [(ngModel)]="dataForm.inventory_category_id" 
                                            name="iiq_inventory_category_id" class="form-control" 
                                            required [appSelect2] [theme]="'mxp360-form'">
                                        <option *ngFor="let inventory of rowsInventoryCategory"
                                            value="{{inventory.id}}"> {{inventory.name}}
                                        </option>
                                    </select>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">Select Inventory Category.</small>
                                </div>
                            </div>
                            <div class="col-1">
                            </div>
                            <div class="col-5 pt-3">
                                <!-- <div class="form-group mb-4">
                                    <label class="control-label">
                                        <input type="checkbox" #toggleBulkyFee (change)="setBulkyFee($event)">
                                        Set a bulky fee:
                                    </label>
                                    <small class="form-text text-muted mb-4">
                                        Make the inventory to add a bulky fee
                                    </small>
                                </div> -->

                                <div class="form-group mt-4 mb-4 pb-2">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="setBulkyFee" name="setBulkyFee" #toggleBulkyFee (change)="setBulkyFee($event)">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="setBulkyFee" class="checkbox-label">
                                            Set a Bulky Fee
                                        </label>
                                    </div>
                                    <small class="form-text text-muted mt-2">
                                        Make the Inventory to Add a Bulky Fee.
                                    </small>
                                </div>

                                <div *ngIf="dataForm.bulky_fee">
                                    <div class="form-group mb-4">
                                        <label class="control-label">Title Balance</label>
                                        <input type="text" [(ngModel)]="dataForm.bulky_fee.name" name="iiq_bf_name" class="form-control" required>
                                        <small class="form-text text-muted mb-4">
                                            the title that will show on the estimate under Balance.
                                        </small>
                                    </div>

                                    <div class="form-group mb-4">
                                        <label class="control-label">Amount</label>
                                        <input type="number" step="0.01" [(ngModel)]="dataForm.bulky_fee.cost" name="iiq_bf_cost" class="form-control" required>
                                        <small class="form-text text-muted mb-4">
                                            Amount extra.
                                        </small>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-save ml-1 mt-5" type="submit" (click)="validateCategory()">
                                Submit
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>
    </div>
</div>
