import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from 'src/app/entities/workspace/role';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<any> {
        return this.http.get<any>(environment.api.workspace + '/roles').toPromise();
    }

    getById(id: string): Promise<any> {
        return this.http.get<Role>(environment.api.workspace + '/roles/' + id).toPromise();
    }

    save(dataForm): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm) {
        return this.http.post(environment.api.workspace + '/roles', dataForm).toPromise();
    }

    edit(dataForm) {
        return this.http.put(environment.api.workspace + '/roles/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/roles/' + id).toPromise();
    }
}
