<!-- <div class="widget widget-table-two widget-container">

    <div class="widget-heading">
        <h5 class="text-center">Jobs in Pick-up Window Without Scheduling</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-info"><div class="th-content text-light">Jobs</div></th>
                        <th class="bg-info"><div class="th-content th-heading text-light">Pickup Windows</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let jobsInPickUpWindow of jobsInPickUpWindows ">
                        <td><div class="td-content customer-name text-info">{{jobsInPickUpWindow.job}}</div></td>
                        <td><div class="td-content product-brand">{{jobsInPickUpWindow.pickup_window.range_start | date}} - {{jobsInPickUpWindow.pickup_window.range_end | date}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->




<div class="widget widget-table-two widget-container p-0">

    <div class="widget-heading">
        <h5 class="text-center">Jobs in Pick-up Window Without Scheduling</h5>
    </div>

    <div class="widget-content">
        <!-- <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-info"><div class="th-content text-light">Jobs</div></th>
                        <th class="bg-info"><div class="th-content th-heading text-light">Pickup Windows</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let jobsInPickUpWindow of jobsInPickUpWindows ">
                        <td><div class="td-content customer-name text-info">{{jobsInPickUpWindow.job}}</div></td>
                        <td><div class="td-content product-brand">{{jobsInPickUpWindow.pickup_window.range_start | date}} - {{jobsInPickUpWindow.pickup_window.range_end | date}}</div></td>
                    </tr>
                </tbody>
            </table>
        </div> -->

        <!-- <div class="w-heading d-flex">
            <div class="col-3">
                <span>Job</span>
            </div>
            <div class="col-9">
                <span>Pickup Windows</span>
            </div>
        </div> -->

        <div class="w-content">

            <div class="row-job d-flex" *ngFor="let jobsInPickUpWindow of jobsInPickUpWindows">
     

                    <div class="col-3 w-job">
                        <span>{{jobsInPickUpWindow.job}}</span>
                    </div>
        
                    <div class="col-4 w-date start text-right pr-0">
                        <span> 
                            {{jobsInPickUpWindow.pickup_window.range_start | date}}
                        </span>
                    </div>
                    <div class="col-1 w-icon text-center p-0">
                        <span> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-commit"><circle cx="12" cy="12" r="4"></circle><line x1="1.05" y1="12" x2="7" y2="12"></line><line x1="17.01" y1="12" x2="22.96" y2="12"></line></svg>
                        </span>
                    </div>
                    <div class="col-4 w-date end text-left pl-0">
                        <span>
                            {{jobsInPickUpWindow.pickup_window.range_end | date}}
                        </span>
                    </div>
  
    
            </div>
    

        </div>
        



    </div>
</div>