import { Component, OnInit, ViewChild } from '@angular/core';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { ConstantsMessages } from '../../../constants-messages';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { LeadExport } from 'src/app/entities/workspace/lead-export';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;
declare const moment;

@Component({
    selector: 'app-export-leads',
    templateUrl: './export-leads.component.html',
    styleUrls: ['./export-leads.component.scss']
})
export class ExportLeadsComponent implements OnInit {

    /*
      Constantes que contiene el tipo de mensaje a mostrar
      */
    constantsMessages = ConstantsMessages;
    public itemsPerPage = 25;
    allEstimates: Array<LeadExport>;

    // datos para filtros
    public employees: Array<Employee>;
    public filterData: {
        betweenFilter: BetweenDates;
        employeeId: string;
        export: boolean;
        fromState: string;
        selectDateType: string;
        service: "AUTO_TRANSPORT" | "LONG" | "LOCAL" | "MOVING" | "";
        status: string;
        toState: string;
        category: string;
    }

    public filterSearch: string;
    public actualPage: number;

    /*
    Variable que realiza el filtro del select
    */
    filterSelect: string;
    constructor(
        private estimatesService: EstimatesService,
        private employeesService: EmployeesService,
        public helperService: HelperService,
        private authService: AuthService
    ) {
        this.allEstimates = [];
        this.filterData = {
            betweenFilter: new BetweenDates(),
            employeeId: '',
            export: false,
            fromState: '',
            selectDateType: 'MOVE',
            service: '',
            status: '',
            toState: '',
            category: ''
        };
        this.filterData.betweenFilter.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
        this.filterData.betweenFilter.dateEnd = moment().second(0).minute(0).hour(23).unix() * 1000;
        this.employees = [];

        this.filterSearch = '';
        this.actualPage = 1;
    }
    ngOnInit(): void {
    }

    ngAfterViewInit(): void{
        this.loadEmployees();
    }

    onChangeStart(dates: number) {
        // seteamos las fechas obtenidas del data picker
            this.filterData.betweenFilter.dateStart = moment(dates).unix() * 1000;
    }
    onChangeEnd(dates: number) {
        // seteamos las fechas obtenidas del data picker
            this.filterData.betweenFilter.dateEnd = moment(dates).unix() * 1000;
    }
    loadEmployees() {
        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    // limpia la categoría si no es un estimado
    listenChangeStatus() {
        if (this.filterData.status !== 'IN_ESTIMATION') {
            this.filterData.category = '';
        }
    }

    validateFilter() {
        if (this.filterData.export){
            this.exportCSV();
        } else {
            this.helperService.showLoadingMxpro360();
            this.estimatesService.getDataExportExcel(this.filterData, this.authService.workspaceSession.company.id)
                .then((response) => {
                    this.allEstimates = response;
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }
      
    }

    exportCSV() {
        const exportUrl = environment.api.companies + this.authService.workspaceSession.company.id +
            '/export-leads?Authorization='
            + this.authService.session.token + '&report_data=' + JSON.stringify(this.filterData) +  '&workspace_id=' +  this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id;
        window.open(exportUrl, '_blank');
    }

       /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimateId) {
        // Redirige a la ventana de estmate
        this.helperService.openNewTab(estimateId, 'estimate');
    }
}
