<!-- <div class="widget widget-table-two">
    
    <div class="widget-heading">
        <h5 class="text-info">Last 10 Recurring Estimates</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-info"><div class="th-content text-light">Customer</div></th>
                        <th class="bg-info"><div class="th-content text-light">Job</div></th>
                        <th class="bg-info"><div class="th-content th-heading text-light">Date</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let jobRecurringLast10 of jobsRecurringLast10">
                        <td><div class="td-content customer-name text-info">{{jobRecurringLast10.customer}}</div></td>
                        <td><div class="td-content customer-name">{{jobRecurringLast10.job}}</div></td>
                        <td><div class="td-content pricing"><span class="">{{jobRecurringLast10.created | date}}</span></div></td>
                    </tr>
                    
                   
                </tbody>
            </table>
        </div>
    </div>
</div> -->



<div class="widget widget-activity-five">

    <div class="widget-heading">
        <h5 class="text-info">Last 10 Recurring Estimates</h5>
    </div>

    <div class="widget-content">

        <div class="w-shadow-top"></div>

        <div class="mt-container mx-auto">

            
            <div class="timeline-line" *ngFor="let jobRecurringLast10 of jobsRecurringLast10">
                
                <div class="item-timeline timeline-new">
                    <div class="t-dot">
                        <div class="t-secondary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                    </div>
                    <div class="t-content t-job-info">
                        <div class="t-uppercontent">
                            <h5>{{jobRecurringLast10.job}}</h5>
                        </div>
                        <p>{{jobRecurringLast10.customer}}</p>
                    </div>
                    <div class="t-date d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                        <p>{{jobRecurringLast10.created | date}}</p>
                    </div>
                </div>

                                  
            </div>    

        </div>

        <div class="w-shadow-bottom"></div>
    </div>
</div>
