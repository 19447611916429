import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output,
    SimpleChanges, ViewChild
} from '@angular/core';

declare const moment: any;
declare const flatpickr: any;


@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements AfterViewInit, OnInit, OnChanges {

    @Input() disabled: boolean;
    @Input() disabledDates: Array<any>;
    @Input() enableTime: boolean;
    @Input() placeholder: string;
    @Input() theme: string;
    @Input() value: number;
    @Output() onChange: EventEmitter<Number>;
    @ViewChild('fieldDatePicker', { static: true }) inputField: ElementRef<HTMLInputElement>;
    public render: string;
    public state: 'RENDER' | 'INPUT';

    private flatPicker;

    constructor() {
        this.onChange = new EventEmitter();
        this.disabled = false;
        this.enableTime = false;
        this.state = 'RENDER';
        this.placeholder = null;
        this.value = null;
        this.flatPicker = null;
        this.theme = 'FORM_FIELD'; // WIDGET este es para cuando no se use en un formulario si no en un panel
        this.disabledDates = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {

        this.flatPicker = flatpickr(this.inputField.nativeElement, {
            enableTime: this.enableTime,
            allowInput: false,
            disable: this.disabledDates,
            onChange: (selectedDates: Array<Date>, dateStr, instance) => {

                if (this.enableTime) {
                    this.value = moment(selectedDates[0], moment.HTML5_FMT.DATE).unix() * 1000;
                    this.render = moment(selectedDates[0]).format('MMM Do YYYY, h:mm A');
                } else {
                    this.value = moment(selectedDates[0], moment.HTML5_FMT.DATE).second(59).minute(59).hour(11).unix() * 1000;
                    this.render = moment(selectedDates[0]).format('MMM Do YYYY');
                }

                // notificamos el nuevo valor
                this.onChange.emit(this.value);
            },
            onClose: () => {
                this.state = 'RENDER';
            }
        });

        // agrega boton para cerrar popup de fechas cuando tengo time
        const flatpickrTimeDiv = document.querySelector('.flatpickr-time');
        const btnAdded = document.querySelector('.btn-flatpickr.btn.btn-save');
        const that = this;
        // Crear un nuevo botón
        const newButton = document.createElement('button');
        newButton.textContent = 'Set'; // Establecer el texto del botón
        newButton.className = "btn-flatpickr btn btn-save";
        newButton.onclick = function() {
            that.flatPicker.close();
        }

        // Agregar el botón al DOM después del div 'flatpickr-time'
        if (flatpickrTimeDiv && !btnAdded && this.enableTime) {
            flatpickrTimeDiv.parentNode?.insertBefore(newButton, flatpickrTimeDiv.nextSibling);
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        // si el cambio es sobre el placeholder lo hacemos primero
        if (changes.placeholder) {
            this.render = changes.placeholder.currentValue;
        }

        // si el cambio es sobre el value lo ejecutamos de ultimo
        if (changes.value) {
            if (changes.value.currentValue !== null) {
                if (this.enableTime) {
                    this.render = moment(changes.value.currentValue).format('MMM Do YYYY, h:mm A');
                } else {
                    this.render = moment(changes.value.currentValue).format('MMM Do YYYY');
                }
            }
        }
    }

    /**
     * Se ejecuta cuando el usuario hace clik para cambiar una fecha
     */
    onClickRender() {

        if (this.state === 'INPUT' || this.disabled) {
            return;
        }

        // validamos que la variabel con el que se inicializa para mostrar tenga algun dato
        if (this.value !== null) {
            this.flatPicker.setDate(moment(this.value).toDate());
        }

        this.flatPicker.redraw();
        this.state = 'INPUT';

        setTimeout(() => {
            this.inputField.nativeElement.focus();
        }, 100);
    }

}
