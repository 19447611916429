import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { Employee } from 'src/app/entities/workspace/employee';
import { Account } from 'src/app/entities/admin/account';

@Injectable({
    providedIn: 'root'
})
export class EmployeesService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<EmployeeView>> {
        return this.http.get<Array<EmployeeView>>(environment.api.workspace + '/employees').toPromise();
    }

    getById(id: string) {
        return this.http.get<any>(environment.api.workspace + '/employees/' + id).toPromise();
    }

    save(dataForm): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm) {
        return this.http.post(environment.api.workspace + '/employees', dataForm).toPromise();
    }

    edit(dataForm) {
        return this.http.put(environment.api.workspace + '/employees/' + dataForm.employee.id, dataForm).toPromise();
    }

    togleStatus(id: string) {
        return this.http.patch(environment.api.workspace + '/employees/' + id + '/allow_access', {}).toPromise();
    }

    deleteEmployee(id: string) {
        return this.http.put(environment.api.workspace + '/employees/' + id + '/delete', {}).toPromise();
    }


    /***********************************************************
     * SERVICIOS PARA LAS CUENTAS RELACIONADOS A LOS EMPLEADOS *
     ***********************************************************/
    searchAccouts(email: string) {
        return this.http.get(environment.api.workspace + '/employees/account/search', { params: { email } }).toPromise();
    }

    assignAccessAccount(id: string, account: Account) {
        return this.http.post(environment.api.workspace + '/employees/' + id + '/account/assign', account).toPromise();
    }

    saveAccessAccount(id: string, account: Account) {
        if(account.id === null){
            return this.addAccessAccount(id, account);
        }

        return this.editAccessAccount(id, account);
    }

    addAccessAccount(id: string, account: Account) {
        return this.http.post(environment.api.workspace + '/employees/' + id + '/account', account).toPromise();
    }

    editAccessAccount(id: string, account: Account) {
        return this.http.put(environment.api.workspace + '/employees/' + id + '/account', account).toPromise();
    }

    deleteAccessAccount(id: string) {
        return this.http.delete(environment.api.workspace + '/employees/' + id + '/account', {}).toPromise();
    }

    getByIdCompany(id: string) {
      return this.http.get<any>(environment.api.workspace + '/employees/' + id + '/account').toPromise();
    }



}
