import { JobStorageExternal } from './job-storage-external';
import { JobStorageInternal } from './job-storage-internal';

export class JobStorage {
    public id: string;
    public type: string;
    public warehouse_id: string;
    public storage_company_id: string;
    public date_in: number;
    public date_out: number;
    public total_cf: number;
    public storage_bill_month: boolean;
    public next_bill_date: number;
    public bill_status: 'PENDING' | 'PAID';
    public passed_due: boolean;
    public moving_pads: number;
    public oversize_items: number;
    public job_storage_internal: JobStorageInternal[];
    public job_storage_external: JobStorageExternal[];
    public job_id: string;
    public created: number;
    public updated: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.type = null;
        this.warehouse_id = null;
        this.storage_company_id = null;
        this.date_in = null;
        this.date_out = null;
        this.total_cf = null;
        this.storage_bill_month = null;
        this.next_bill_date = null;
        this.bill_status = 'PENDING';
        this.passed_due = null;
        this.moving_pads = null;
        this.oversize_items = null;
        this.job_storage_external = [];
        this.job_storage_internal = [];
        this.job_id = null;
        this.created = null;
        this.updated = null;
    }
}
