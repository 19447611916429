import { Address } from "../global/address";
import { LocalEstimateStorage } from "./local-estimate-storage";

export class EstimatePickup {

    public id: string;
    public boxes_delivery_day: number;
    public pack_day: number;
    public use_time_frame: boolean;
    public time_frame_start: string;
    public time_frame_end: string;
    public range_start: number;
    public range_end: number;
    public pickup_day: number;
    public address: Address;
    public local_estimate_storage: LocalEstimateStorage;
    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.boxes_delivery_day = null;
        this.pack_day =  null;
        this.use_time_frame = false;
        this.time_frame_start = null;
        this.time_frame_end = null;
        this.range_start = null;
        this.range_end = null;
        this.pickup_day = null;
        this.address = new Address();
        this.local_estimate_storage = null;
    }
}
