import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { StorageCompanyContact } from 'src/app/entities/workspace/storage-company-contact';
import { HelperService } from 'src/app/services/helper.service';
import { StorageCompaniesService } from 'src/app/services/workspaces/storage-companies.service';
import { StorageCompanyContactsService } from 'src/app/services/workspaces/storage-company-contacts.service';


declare var jQuery;
declare var swal;
@Component({
  selector: 'app-storage-companies-view',
  templateUrl: './storage-companies-view.component.html',
  styleUrls: ['./storage-companies-view.component.scss']
})
export class StorageCompaniesViewComponent implements OnInit {

  @ViewChild('addModalContact') addModalContact: ElementRef;

  rowsStorageCompanyContacts: Array<StorageCompanyContact>;
  rowStorageCompanyContact: StorageCompanyContact;
  rowsStorageCompanyJobs: Array<StorageCompanyContact>;
  rowsStorageCompanyJob: StorageCompanyContact;
  storageCompany: StorageCompany;
  selectTap: string;
  storageCompanyId: string;

  public contactAction: string;

  constructor(
    private storageCompanyContactsService: StorageCompanyContactsService,
    private storageCompaniesService: StorageCompaniesService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService
  ) { 
    this.rowsStorageCompanyContacts = [];
    this.rowStorageCompanyContact = new StorageCompanyContact();
    this.rowsStorageCompanyJobs = [];
    this.selectTap = 'contacts';
    this.storageCompany = new StorageCompany();
    this.storageCompanyId = '';
    this.contactAction = '';
}

  ngOnInit(): void {
      
  }

  
  ngAfterViewInit() {
      // verficamos si se esta editando
      this.currentRoute.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
            this.storageCompanyId = params.id;
            this.load();
        }
    });
    this.loadContacts();
  }
  
  private load() {
    this.helperService.showLoadingMxpro360();
    this.storageCompaniesService
      .getById(this.storageCompanyId)
      .then((response) => {
        this.storageCompany = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  
  public loadContacts() {
    this.helperService.showLoadingMxpro360();
    this.storageCompanyContactsService
      .getAll(this.storageCompanyId)
      .then((response) => {
        this.rowsStorageCompanyContacts = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  
  private loadJobs() {
    this.helperService.showLoadingMxpro360();
    this.storageCompanyContactsService
      .getAll(this.storageCompanyId)
      .then((response) => {
        this.rowsStorageCompanyContacts = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  removeContact(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.storageCompanyContactsService
            .remove(this.storageCompanyId, id)
            .then((response) => {
              this.loadContacts();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

  
saveContact() {
    this.rowStorageCompanyContact.storage_company_id = this.storageCompanyId;
   this.storageCompanyContactsService
   .save(this.rowStorageCompanyContact)
     .then(response => {
       this.loadContacts();
       jQuery(this.addModalContact.nativeElement).modal('hide');
     })
     .catch((error) => {
       console.error('error', error);
     })
     .finally(() => {
       this.helperService.hideLoadingMxpro360();
     });

 }

 
 openAddModalContact(contact) {
    this.rowStorageCompanyContact = contact == null ? new StorageCompanyContact(): {...contact};
    this.contactAction = contact == null ? 'New': 'Edit';
    
    jQuery(this.addModalContact.nativeElement).modal('show');
}

editContact(contact) {
    contact.main = true;
    this.helperService.showLoadingMxpro360();
    this.storageCompanyContactsService
        .edit(contact)
        .then((response) => {
          this.loadContacts();
        })
        .catch((error) => {
            console.error('Error:', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

}
