import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Company } from 'src/app/entities/workspace/company';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { PhoneIvrEntry } from 'src/app/entities/workspace/phone-ivr-entry';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneQueue } from 'src/app/entities/workspace/phone-queue';
import { PhoneRingGroup } from 'src/app/entities/workspace/phone-ring-group';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';
import { PhoneIvrsService } from 'src/app/services/workspaces/phone-ivrs.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { PhoneRingGroupsService } from 'src/app/services/workspaces/phone-ring-groups.service';
import { environment } from 'src/environments/environment';
declare const swal;

@Component({
  selector: 'app-phone-ivrs-form',
  templateUrl: './phone-ivrs-form.component.html',
  styleUrls: ['./phone-ivrs-form.component.scss']
})
export class PhoneIvrsFormComponent implements OnInit {

  public dataForm: PhoneIvr;
  public nums: PhoneLine[];
  public companies: Array<Company>;
  public employees: EmployeeView[];
  public ringGroups: PhoneRingGroup[];
  public queues: PhoneQueue[];
  public ivrs: PhoneIvr[];
  public targets: any[];
  public extensions: any[];
  public target: "EMPLOYEE" | "RING_GROUP" | "IVR" | "QUEUE" | "CALL_FLOW_CONTROL" | "EXTERNAL_FORWARD" | "VOICEMAIL" | "PHONE_LINE"|"EXTENSION";
  readonly environment = environment;

  public target_id: string;
  public digit: number;
  public digits: number[];
  public workspaceId: string;
  public rows: Array<PhoneIvr>;
  JSON;

  constructor(
    private phoneLineService: PhoneLinesService,
    private phoneIvrsService: PhoneIvrsService,
    private phoneRingGroupsService: PhoneRingGroupsService,
    private phoneQueuesService: PhoneQueuesService,
    private helperService: HelperService,
    private employeesService: EmployeesService,
    private currentRoute: ActivatedRoute,
    private companiesService: CompaniesService,
    private authService: AuthService,
    private phoneExtensionsService : PhoneExtensionsService,
  ) {
    this.dataForm = new PhoneIvr();
    this.nums = [];
    this.companies = [];
    this.JSON = JSON;
    this.target = null;
    this.target_id = null;
    this.digit = null;
    this.digits = [];
    this.extensions =[];

  }

  ngOnInit(): void {
    this.loadCompany();
    this.getAllEmployees();
    this.loadRingGroups();
    this.loadQueues();
    this.loadIvrs();
    this.digits10();
    this.loadAllCompanies();
    this.loadExtensions();
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.load(params.id);
      }
    })
  }

  private getAllNums(): void{
    this.helperService.showLoadingMxpro360();
    this.phoneLineService.getAllByCompany(this.dataForm.company_id).then((response) => {
      this.nums = response;
      console.log('this.nums => ',this.nums[0].phone_number);

      this.changeTarget();
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }


  loadCompany() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
        .getAll()
        .then((response) => {
            this.companies = response;
            this.workspaceId = this.authService.workspaceSession.workspace.id;
        })
        .catch((error) => {
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }


  private getAllEmployees(): void{
    this.helperService.showLoadingMxpro360();
    this.employeesService.getAll().then((response) => {
      this.employees = response;
      this.changeTarget();
    }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  };


  onChangeAnnouncement(files: Array<string>) {

   // seguir mañana no dejar subir formatos raros de audio solo wav mp3 mpeg

    let extencion = files[0].split('.');

       if (files.length > 0) {

       if ( extencion[1] === 'mp3'|| extencion[1] === 'mpeg'|| extencion[1] === 'ogg'|| extencion[1] === 'wav') {

        let mime = files[0].split('.');
        this.dataForm.announcement.display_name = files[0];
        this.dataForm.announcement.name = files[0];
        this.dataForm.announcement.mime_type = 'audio/' + mime[1];

       }else{
        swal(
          'Error',
          'error the audio extension must be               mp3 mpeg ogg or wav',
          'error'
        );
       }

    }
  }



  save(): void {

    if(this.dataForm.announcement.name == null){
      swal({
        title: 'Attention!',
        text: "Complete Announcement fields",
        type: 'warning',
        padding: '2em'
      });
      return;
    }
    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService.save(this.dataForm)
    .then( (response)=> {
      console.log('response => ', response);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
      this.helperService.goToWorkspaceRouterLink('/phone-ivrs')
    });
  }


  /**
     * carga un nuevo phoneLine o carga un vehiculo padra ser editado
     * @param id
     */
  private load(id: any) {

    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService
        .getById(id)
        .then((response) => {
          this.dataForm = response;
          this.getAllNums();
          this.digits10();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  private loadRingGroups() {

    this.helperService.showLoadingMxpro360();
    this.phoneRingGroupsService
        .getAll()
        .then((response) => {
          this.ringGroups = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  private loadQueues() {

    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService
        .getAll()
        .then((response) => {
          this.queues = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };


  private loadIvrs() {

    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService
        .getAll()
        .then((response) => {
          this.ivrs = response;
          this.changeTarget();
        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };

  changeCompany(){
    this.getAllNums();
  }



  changeTarget(){

    if(this.target == 'VOICEMAIL'){
      this.targets = this.extensions;
    }

    if(this.target == 'EXTENSION'){
      this.targets =  this.extensions;
    }

    if(this.target == 'RING_GROUP'){
      this.targets = this.ringGroups;
    }

    if(this.target == 'QUEUE'){
      this.targets = this.queues;
    }

    if(this.target == 'EXTERNAL_FORWARD'){
      this.target_id = '';
    }
    if(this.target == 'IVR'){
      this.targets = this.ivrs;
    }

  }

  addEntry(){
    console.log(this.target);
    console.log(this.target_id);

    const phoneIvrEntry = new PhoneIvrEntry();
    console.log(phoneIvrEntry);

    phoneIvrEntry.digit = this.digit;
    phoneIvrEntry.destination.target = this.target;
    phoneIvrEntry.destination.target_id = this.target_id;
    this.dataForm.entries.push(phoneIvrEntry);

    this.digits10();
  }

  remove(i){

    this.dataForm.entries.splice(i, 1);
    this.digits10();
  }

  digits10(){
    this.digits = [];
    for (let i = 0; i <= 9; i++) {
      this.digits.push(i);
    }

    for (let i = this.digits.length - 1; i >= 0; i--) {
      for (let et of this.dataForm.entries) {
        if (et.digit == this.digits[i]){
          this.digits.splice(i, 1);
        }
      }
    }

  }


  private loadAllCompanies(){
    this.helperService.showLoadingMxpro360();
    this.phoneIvrsService.getAllCompany().then((response) => {
      this.rows = response;

      console.log('aqui rows',this.rows);
      console.log('this.dataForm ', this.dataForm);


      this.workspaceId = this.authService.workspaceSession.workspace.id
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };

  public loadExtensions() {
    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
        .getAllExtensions()
        .then((response) => {
          this.extensions = response;
          console.log('test extensions', this.extensions);
          this.changeTarget();

        })
        .catch((error) => {
          console.log('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
  };



}
