<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Storage Companies
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/storage-companies">Storage Companies</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">
                        <div class="col-12">

                            <div class="form-group mb-4">
                                <label for="company_dot">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input type="text" class="form-control" name="company_dot" placeholder="Enter here the name" 
                                    [(ngModel)] = "dataForm.name" required pattern="^[a-zA-Z ]+">
                                </div>

                                <small id="nameCustomer" class="form-text text-muted mb-4">Name company (Only letter).</small>
                            </div>

                            <div class="form-group mb-4">
                                <label for="company_name">Phone</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-phone">
                                                <path
                                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                </path>
                                            </svg></span>
                                    </div>
                                    <input type="text" class="form-control" name="company_name" placeholder="Enter here the phone" 
                                    [(ngModel)] = "dataForm.phone" maxlength="10" onlyNumbers required>
                                </div>

                                <small id="emailHelp1" class="form-text text-muted mb-1">Number phone.</small>
                            </div>
                            
                            <div class="form-group">
                                <app-search-address (fullAddress)="address" (onChange)="setFrom($event)" [(ngModel)] = "address" name="address"></app-search-address>
                            </div>

                        </div>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-save ml-1 mt-3" type="submit">
                          Submit
                        </button>
                    </div>

                </form>


            </div>
        </div>

    </div>
</div>