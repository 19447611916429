export class BoardJob {

    public id: string;
    public is_vip: boolean;
    public is_private: boolean;
    public is_public: boolean;
    public offer: number;
    public special_conditions: string;
    public status: "PUBLISHED" | "PAUSED" | "FINISHED";
    public employee_id: string;
    public created: number;
    public updated: number;


    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.is_vip = false;
        this.is_private = false;
        this.is_public = false;
        this.offer = null;
        this.special_conditions = null;
        this.status = null;
        this.employee_id = null;
        this.created = null;
        this.updated = null;
    }
}
