import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Automation } from 'src/app/entities/companies/automation';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutomationsService {

  constructor(private http: HttpClient) {

  }

  getAll(): Promise<Array<Automation>> {
      return this.http.get<Array<Automation>>(environment.api.company + '/automations').toPromise();
  }

  getById(id: string) {
      return this.http.get<Automation>(environment.api.company + '/automations/' + id).toPromise();
  }

  save(dataForm: Automation): Promise<any> {
      if (dataForm.id === null) {
          return this.add(dataForm);
      }
      return this.edit(dataForm);
  }

  add(dataForm: Automation) {
      return this.http.post(environment.api.company + '/automations', dataForm).toPromise();
  }

  edit(dataForm: Automation) {
      return this.http.put(environment.api.company + '/automations/' + dataForm.id, dataForm).toPromise();
  }

  remove(id: string) {
      return this.http.delete(environment.api.company + '/automations/' + id).toPromise();
  }

  getExpiredautomation(companyId) {
      return this.http.get<Array<Automation>>(environment.api.company + '/dashboard/pending-automation',
      { params: { 'full-view': '1', 'company_id': companyId } }).toPromise();
  }
}
