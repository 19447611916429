import { ConstantsMessages } from 'src/app/constants-messages';
import { TicketView } from 'src/app/entities/workspace/ticket-view';
import { TicketsService } from 'src/app/services/workspaces/tickets.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { HelperService } from 'src/app/services/helper.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { AuthService } from 'src/app/services/auth.service';
import { Ticket } from 'src/app/entities/workspace/ticket';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';

declare const jQuery: any;
declare const swal: any;
@Component({
  selector: 'app-tickets-workspace',
  templateUrl: './tickets-workspace.component.html',
  styleUrls: ['./tickets-workspace.component.scss']
})
export class TicketsWorkspaceComponent implements OnInit {

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  rows: Array<TicketView>;
  ticket: TicketView;
  actualPage: number;
  filterSearch: string;

  companyId: string;
  workDepartmentId: string;
  employeeId: string;
  typeTicket: string;
  statusTicket: string;
  priorityTicket: string;

  workDeparments;
  employees: EmployeeView[];
  public employeesToShow: EmployeeView[];
  public employeesCompany: EmployeeView[];
  typeTickets;
  statusTickets;
  priorityTickets;
  companies;
  public workDepartment: WorkDepartment;
  public newTicket: Ticket;
  public ticketJobNumber: string;
  @ViewChild('ticketModal') ticketModal: ElementRef;
  @ViewChild('assingEmployeeModal') assingEmployeeModal: ElementRef;

  constructor(
    private employeesService: EmployeesService,
    private companiesService: CompaniesService,
    private ticketService: TicketsService,
    private workDepartmentsService: WorkDepartmentsService,
    private helperService: HelperService,
    public authService: AuthService,
    private ticketsTypesService: TicketsTypesService
  ) {
    this.workDepartmentId = '';
    this.companyId = '';
    this.employeeId = '';
    this.typeTicket = '';
    this.priorityTicket = '';
    this.statusTicket = '';
    this.filterSearch = '';

    this.rows = [];

    this.employees = [];
    this.employeesToShow = [];
    this.employeesCompany = [];
    this.typeTickets = [];
    this.statusTickets = [];
    this.priorityTickets = [];
    this.workDeparments = [];
    this.companies = [];

    this.actualPage = 1;
    this.newTicket = new Ticket();
    this.ticket = new TicketView();
    this.ticket.work_department_id = '';
    this.ticketJobNumber = null;
    this.workDepartment = new WorkDepartment();
  }

  ngOnInit(): void {
  
  }

  setWorkDepartment() {
    for (let workDepartment of this.workDeparments) {
      if (this.ticket.work_department_id = workDepartment.id) {
        this.workDepartment = workDepartment;
        break;
      }
    }
    const THIS = this;
    setTimeout(() => {
      THIS.filterEmployees();
    }, 500);
  }

  
  ngAfterViewInit() {
    this.loadTickets();
    this.loadWorkDepartmets();
    this.loadEmployees();
    this.loadStatus();
    this.loadPriority();
    this.loadCompanyies();
    this.loadTicketsType();
  }

  /**
   * Carga la informacion de los empleados
   */
  private loadCompanyies() {
    this.helperService.showLoadingMxpro360();

    this.companiesService
      .getAll()
      .then((response) => {
        this.companies = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Carga la informacion de los empleados
   */
  private loadEmployees() {
    this.helperService.showLoadingMxpro360();

    this.employeesService
      .getAll()
      .then((response) => {
        this.employees = response;
        this.filterEmployees();
        this.filterCompanyEmployees();
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  filterEmployees() {
    this.employeesToShow = [];
    for (let employee of this.employees) {
      if (this.ticket.work_department_id == '' || this.workDepartment.employees_id.indexOf(employee.id) >= 0) {
        this.employeesToShow.push(employee);
      }
    }
  }

  filterCompanyEmployees() {
    this.employeesCompany = [];
    for (let employee of this.employees) {
      if (this.companyId !== null && this.companyId !== '') {
        for (let permission of employee.employeeCompanyPermissions) {
          if (this.companyId == permission.company_id) {
            this.employeesCompany.push(employee);
          }
        }
      } else {
        this.employeesCompany.push(employee);
      }
    }
  }

  /**
   * Metodo que carga los work departments para mostrarlos en el select
   */
  private loadWorkDepartmets() {
    this.helperService.showLoadingMxpro360();
    this.workDepartmentsService.getAll().then((response) => {
      this.workDeparments = response;
    })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Metodo que carga los tickets para mostrarlos en el select
   */
  private loadTickets() {
    this.helperService.showLoadingMxpro360();
    const data: any = {
      'full-view': '1'
    };
    this.ticketService
      .getAllWithFullView(data)
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Metodo que carga el status del ticket para mostrarlo en el select
   */
  private loadStatus() {
    this.helperService.showLoadingMxpro360();
    this.ticketService
      .getAll()
      .then((response) => {
        response.forEach(element => {
          if (this.statusTickets.indexOf(element.status) === -1) {
            this.statusTickets.push(element.status);
          }
        });
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Metodo que carga la priority del ticket para mostrarlo en el select
   */
  private loadPriority() {
    this.helperService.showLoadingMxpro360();
    this.ticketService
      .getAll()
      .then((response) => {
        response.forEach(element => {
          if (this.priorityTickets.indexOf(element.priority) === -1) {
            this.priorityTickets.push(element.priority);
          }
        });
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Metodo que filtra la busqueda de los select
   */
  getFilter() {
    const data: any = {
      'full-view': '1'
    };

    if(this.companyId){
        data.company_id = this.companyId;
    }
    if (this.workDepartmentId) {
      data.work_department_id = this.workDepartmentId;
    }
    if (this.employeeId) {
      data.employee_id = this.employeeId;
    }
    if (this.typeTicket) {
      data.type = this.typeTicket;
    }
    if (this.statusTicket) {
      data.status = this.statusTicket;
    }
    if (this.priorityTicket) {
      data.priority = this.priorityTicket;
    }

    this.helperService.showLoadingMxpro360();

    this.ticketService
      .getAllWithFullView(data)
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  removeTickets(id) {
    this.helperService.showLoadingMxpro360();
    this.ticketService
      .remove(id)
      .then(() => {
        this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
        this.loadTickets();
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        console.error(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  saveTicket() {
    let msg: string = '';
    if (this.newTicket.company_id == null || this.newTicket.company_id == '') {
      msg += '**You Must Provide a Company**<br>';
      
    }
    if (this.newTicket.description == null || this.newTicket.description == '') {
      msg += '**You Must Provide a Description**<br>';
    }
    if (this.newTicket.subject == null || this.newTicket.subject == '') {
      msg += '**You Must Provide a Subject**<br>';    }
    if (msg !== '') {
      swal(
        'Attention',
        msg,
        'warning'
      );
      return;
    }
    
    const dataForm:any = {... this.newTicket}
    dataForm.work_department_id = dataForm.work_department_id == '' ? null: dataForm.work_department_id;
    dataForm.job_number = this.ticketJobNumber;
    this.helperService.showLoadingMxpro360();
    this.ticketService
        .add(dataForm)
        .then(response => {
            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            this.loadTickets();
            jQuery(this.ticketModal.nativeElement).modal('hide');
        })
        .catch((error) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            console.error(error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });

  }

  openModalNewTicket() {
    this.newTicket = new Ticket();
    this.ticketJobNumber = null;
    jQuery(this.ticketModal.nativeElement).modal('show');
  }

  openModalAssignEmployee(ticket) {
    this.ticket = {... ticket};
    this.ticket.customer = {... ticket.customer};
    this.ticket.document = {... ticket.document};
    this.ticket.estimate_document = {... ticket.estimate_document};
    this.ticket.files = [... ticket.files];

    jQuery(this.assingEmployeeModal.nativeElement).modal('show');
  }

  saveEmployeeTicket() {
    this.helperService.showLoadingMxpro360();
    this.ticketService.save(this.ticket)
    .then(() => {
      this.loadTickets();
      jQuery(this.assingEmployeeModal.nativeElement).modal('hide');
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  /**
   * Metodo que carga el tipo del ticket para mostrarlo en el select
   */

  private loadTicketsType() {
    this.helperService.showLoadingMxpro360();
    this.ticketsTypesService
      .getAll()
      .then((response) => {
        this.typeTickets = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
