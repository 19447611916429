<div class="modal fade" id="FileUploadModal" #FileUploadModal data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="FileUploadModalLabel" aria-hidden="true" >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="FileUploadModalLabel">
                    Upload Files
                </h5>
            </div>
            <div class="modal-body">

                <div class="dropzone" #dropZoneJs>

                </div>

                <!-- wrapper files to upload -->
                <div class="table table-striped files previews" #previewFile>

                    <!-- template for each file -->
                    <div class="file-row template" #templateFile>
                        <!-- This is used as the file preview template -->
                        <div>
                            <span class="preview"><img data-dz-thumbnail /></span>
                        </div>
                        <div>
                            <p class="name" data-dz-name></p>
                            <strong class="error text-danger" data-dz-errormessage></strong>
                        </div>
                        <div>
                            <p class="size" data-dz-size></p>
                            <div class="progress progress-striped active" role="progressbar" aria-valuemin="0"
                                aria-valuemax="100" aria-valuenow="0">
                                <div class="progress-bar progress-bar-success" style="width:0%;" data-dz-uploadprogress>
                                </div>
                            </div>
                        </div>
                        <div>
                            <!--
                                <button class="btn btn-primary start">
                                    <i class="glyphicon glyphicon-upload"></i>
                                    <span>Start</span>
                                </button>
                            -->
                            <button data-dz-remove class="btn btn-danger cancel">
                                <i class="glyphicon glyphicon-ban-circle"></i>
                                <span>Cancel</span>
                            </button>
                            <!--
                                <button data-dz-remove class="btn btn-danger delete">
                                    <i class="glyphicon glyphicon-trash"></i>
                                    <span>Delete</span>
                                </button>
                            -->
                        </div>
                    </div>

                </div>



            </div>
            <div class="modal-footer">
                <button class="btn" (click)="close()" [disabled]="isUploading">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button type="button" class="btn btn-save" (click)="save()" [disabled]="isUploading">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>