export class PaymentTransaction {
    
    id: string;
    transaction_id: string;
    code: string;
    auth_code: string;
    status:  'PAID' | 'DECLINED' | 'ERROR' | 'HELD_FOR_REVIEW';
    bank_account_number: string;
    bank_account_type: string;
    payment_id: string;
    created: number;

    reference: string;
    card_number: string;
    card_type: string;

    constructor() {
        this.id = null;
        this.transaction_id = null;
        this.code = null;
        this.auth_code = null;
        this.status = null;
        this.bank_account_number = null;
        this.bank_account_type = null;
        this.payment_id = null;
        this.created = null;

        this.reference = null;
        this.card_number = null;
        this.card_type = null;
    }
}
