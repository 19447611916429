import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Claim } from 'src/app/entities/workspace/claim';
import { ClaimRemark } from 'src/app/entities/workspace/claim-remark';
import { Customer } from 'src/app/entities/workspace/customer';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { AuthService } from 'src/app/services/auth.service';
import { ClaimsService } from 'src/app/services/companies/claims.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { environment } from 'src/environments/environment';
import { File } from 'src/app/entities/global/file';
import { ClaimsRemarksService } from 'src/app/services/companies/claims-remarks.service';
import { ClaimView } from 'src/app/entities/workspace/claim-view';

declare const jQuery: any;

@Component({
  selector: 'app-estimate-claims',
  templateUrl: './estimate-claims.component.html',
  styleUrls: ['./estimate-claims.component.scss']
})
export class EstimateClaimsComponent implements OnInit {
  @ViewChild('addClaimModal') addClaimModal: ElementRef;

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  public actualPage: number;
  public claim: Claim;
  public claimRemarks: ClaimRemark[];
  public estimate: Estimate;
  public estimateId: string;
  public claimInfo: ClaimView;
  public urlBase: string;
  public customer: Customer;
  public claimRemark: ClaimRemark;

  constructor(
    private activatedRoute: ActivatedRoute,
    private claimService: ClaimsService,
    private claimsRemarksService: ClaimsRemarksService,
    private customersService: CustomersService,
    private estimatesService: EstimatesService,
    private helperService: HelperService,
    public authService: AuthService,
  ) {
    this.actualPage = 1;
    this.claim = new Claim();
    this.estimate = new Estimate();
    this.estimateId = null;
    this.claimRemarks = [];
    this.claimRemark = new ClaimRemark();
    this.claimInfo = new ClaimView();
    this.customer = new Customer();

    this.urlBase = environment.api.base;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
    // verficamos si se esta editando
    this.activatedRoute.parent.params.subscribe(params => {
       if (typeof params.id !== 'undefined') {
         this.estimateId = params.id;
         this.loadClaims(params.id);
         this.loadRemarks()
         this.loadEstimate(params.id);
       }
     });  
 }

 loadEstimate(estimateId: string): void {
   this.estimatesService.getById(estimateId).then((estimate) => {
     this.estimate = estimate;
     this.loadCustomer(this.estimate.customer_id);
   })
 }

 private loadCustomer(id: string) {
  this.helperService.showLoadingMxpro360();
  this.customersService
      .getById(id)
      .then((response) => {
          this.customer = response;
          // this.claimRemarkPhone.phoneNumberCustomer = '+1' + this.customer.phone
      })
      .catch((error) => {
          console.error('error', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
}

 loadClaims(id: string) {
   this.helperService.showLoadingMxpro360();
   this.claimService
     .getByEstimate(id)
     .then((response) => {
      this.claimInfo = response;
     })
     .catch((error) => {
         console.error('error', error);
     })
     .finally(() => {
         this.helperService.hideLoadingMxpro360();
     });
 }


 openPopupClaim() {
  this.claimInfo = new ClaimView();
  jQuery(this.addClaimModal.nativeElement).modal('show');
 }

 setDataClaim() {
  this.claim.status = 'OPEN';
  this.claim.subject = 'Estimate Claim';
  this.claimService.saveClaimEstimate(this.estimateId, this.claim)
  .then(() => {
    this.loadClaims(this.estimateId);
  });
 }
 uploadDocument(dataImg) {
  this.claim.files = dataImg;
 }

 updateInfo() {
  this.helperService.showLoadingMxpro360();
  this.claimService.saveClaimEstimate(this.estimateId, this.claimInfo).then(() => {
      this.loadRemarks();
  })
      .catch((error) => {
          console.error('error', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
 }

 onChangeLogo(files: Array<string>) {
  if (files.length > 0) {
    const newFile = new File();
    newFile.name = files[0],
    newFile.display_name = files[0],
    newFile.mime_type = 'mime_type',
    this.claimRemark.files.push(newFile);
  }
 }

 addRemarks() {
  this.helperService.showLoadingMxpro360();
        this.claimRemark.employee_id = this.claimInfo.created_by_employee_id;
        this.claimRemark.claim_id = this.claimInfo.id;
        this.claimsRemarksService
            .save(this.claimInfo.id, this.claimRemark)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.customer = response;
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('Error: ', error);
            })
            .finally(() => {
                this.loadRemarks();
                this.helperService.hideLoadingMxpro360();
            });
 }

 loadRemarks() {
  this.helperService.showLoadingMxpro360();
  this.claimsRemarksService
      .getAll(this.estimateId)
      .then((response) => {
          this.claimRemarks = response;
      })
      .catch((error) => {
          console.error('error', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
}
}
