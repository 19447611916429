<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span>
                Phone Line
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/phone-lines">Phone Lines</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">
                        <div class="col-md-6 col-sm-12">

                            <div class="form-group mb-3">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </span>
                                    </div>
                                    <input type="text" alphaNumeric name="name" class="form-control" [(ngModel)]="dataForm.name" placeholder="Enter an name" value="name" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Enter an Name.
                                </small>
                            </div>

                            <div class="form-group mb-3" *ngIf="!dataForm.id">
                                <label class="control-label">Phone Number</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                        </span>
                                    </div>
                                    <select name="phone_number" class="form-control" [(ngModel)]="dataForm.phone_number" required>
                                        <option value="null" disabled>Select an Option</option>
                                        <option *ngIf="dataForm.id" value="{{dataForm.phone_number}}" disabled>{{dataForm.phone_number}}</option>
                                        <option *ngFor="let num of nums" [value]="num.number">
                                            {{num.number}}
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Phone Number
                                </small>
                            </div>

                            <div class="form-group mb-3" *ngIf="dataForm.id">
                                <label class="control-label">Phone Number</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                        </span>
                                    </div>
                                    <input type="text" name="name" class="form-control" disabled value="{{dataForm.phone_number}}" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Phone Number
                                </small>
                            </div>



                            <div class="form-group mb-3">
                                <label class="control-label">Company</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </span>
                                    </div>
                                    <select name="company_id" class="form-control" [(ngModel)]="dataForm.company_id" *ngIf="!dataForm.id">
                                        <option value="null" disabled>Select a Company</option>
                                        <option *ngFor="let company of companies" [value]="company.id">
                                            {{company.name | titlecase}}
                                        </option>
                                    </select>
                                    <select name="company_id" class="form-control blo" [(ngModel)]="dataForm.company_id" [disabled]="dataForm.company_id" *ngIf="dataForm.id" required>
                                        <option *ngFor="let company of companies" [value]="company.id">
                                            {{company.name | titlecase}}
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Company.
                                </small>
                            </div>




                        </div>

                        <div class="col-md-6 col-sm-12">

                            <div class="form-group mb-3 mt-18">
                                <label class="control-label">Target Destination</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-pull-request"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                                        </span>
                                    </div>
                                    <select name="destination.target" class="form-control" [(ngModel)]="dataForm.destination.target" (ngModelChange)="changeTarget()" (click)="loadExtensions();" [disabled]="!dataForm.company_id" required>
                                        <option value="EXTENSION">
                                            Extension
                                        </option>
                                        <option value="RING_GROUP">
                                            Ring Group
                                        </option>
                                        <option value="IVR" >
                                            IVR
                                        </option>
                                        <option value="QUEUE" >
                                            Queue
                                        </option>
                                        <option value="CALL_FLOW_CONTROL" disabled>
                                            Call Flow Control
                                        </option>
                                        <option value="EXTERNAL_FORWARD" >
                                            External Forward
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Target.
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label class="control-label">Target ID</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </span>
                                    </div>

                          <input *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'" type="text" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id" placeholder="Enter An Phone External" required>


                                    <select name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id" *ngIf="this.dataForm.destination.target != 'EXTERNAL_FORWARD'" [disabled]="!dataForm.destination.target" required>
                                        <option value="null" disabled>Select an Option</option>
                                        <ng-container *ngFor="let target of targets">
                                            <option *ngIf="JSON.stringify(target).includes(dataForm.company_id)" [value]="target.id">
                                              {{target.name | titlecase}}:{{ target.extension }}
                                             <span *ngIf="target.extension">{{target.extension | titlecase}}</span>
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'RING_GROUP'">
                                  Select a Ring Group.
                              </small>
                              <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'">
                                  Enter a Destination Phone.
                              </small>
                              <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'VOICEMAIL'">
                                  Select a Phone Line With Voicemail.
                              </small>
                              <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTENSION'">
                                  Select a Option.
                              </small>
                            </div>
                        </div>

                    </div>

                    <div class="text-center mb-3">
                        <button class="btn btn-save ml-1 mt-3" type="submit" >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>
