import { DailyCrewView } from './daily-crew-view';
import { JobAppoinment } from './job-appoinment';

export class JobAppointmentView extends JobAppoinment{

    crew: DailyCrewView;

    constructor() {
        super();
        this.crew = new DailyCrewView();
    }

}
