import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'avatarTitle'
})
export class AvatarTitlePipe implements PipeTransform {
    transform(input: string): string {
        if(input == null){
            return null;
        }
        var matches = input.match(/\b(\w)/g); // ['J','S','O','N']
        return  matches.join('').substring(0,2); // el segundo parametro indica cuantas letras queremos dejar
    }
}
