import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

declare var ApexCharts;
@Component({
    selector: 'app-lead-providers-timelines',
    templateUrl: './lead-providers-timelines.component.html',
    styleUrls: ['./lead-providers-timelines.component.scss']
})
export class LeadProvidersTimelinesComponent implements OnInit {
    jobsLeadProvidersTimelines: any;

    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) { }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.leadProvidersTimelinesCompany();
        } else {
            this.leadProvidersTimelinesWorkspace();
        }
    }


    leadProvidersTimelinesWorkspace() {

        this.dashboardWorkspaceService
            .getLeadProvidersTimelines()
            .then((response) => {
                this.jobsLeadProvidersTimelines = response;
                this.leadProviders();
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    leadProvidersTimelinesCompany() {

        this.dashboardComopanyService
            .getLeadProvidersTimelines()
            .then((response) => {
                this.jobsLeadProvidersTimelines = response;
                this.leadProviders();
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    leadProviders() {
        let series = [];
        let days = [];

        for (const jobLeadProvidersTimelines of this.jobsLeadProvidersTimelines) {
            let leadDay = [];
            for (const day of jobLeadProvidersTimelines.days) {
                leadDay.push(day.total_leads);
            }
            series.push(
                {
                    name: jobLeadProvidersTimelines.advertiser,
                    data: leadDay

                }
            );
        }
            
            days.push('day 0');
            days.push('day 1');
            days.push('day 2');
            days.push('day 3');
            days.push('day 4');
            days.push('day 5');
            days.push('day 6');
            days.push('day 7');
            days.push('day 8');
            days.push('day 9');
            days.push('day 10');
            days.push('day 11');
            days.push('day 12');
            days.push('day 13');
            days.push('day 14');
            days.push('day 15');


        var options = {
            series:series,
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: 3,
                curve: 'straight'
            },
            title: {
                text: '',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: days,
            }
        };

        var chart = new ApexCharts(document.querySelector("#lead-providers"), options);
        chart.render();
    }

}
