export class AutoLocalTariff {
    public id: string;
    public active: boolean;
    public minimum_travel_hours: number;
    public minimum_travel_miles: number;
    public minimum_work_hours: number;
    public round_up_time: 'ONE_HOUR' | 'THIRTY_MINUTES' |'FIFTEEN_MINUTES';
    public travel_charge_base_on: 'TIMES' | 'MILES';
    public time_per_mile: number;
    //public time_per_mile_active: boolean; 
    public cost_per_hour: number;
    public cost_per_mile: number;
    public extra_labor_hours_fee: number;
    public extra_truck_hours_fee: number;
    public packing_closet_increase: number;
    public packing_room_increase: number;

    constructor() {
        this.id = null;
        this.active = null;
        this.minimum_travel_hours = null;
        this.minimum_travel_miles = null;
        this.minimum_work_hours = null;
        this.round_up_time = 'FIFTEEN_MINUTES';
        this.travel_charge_base_on = null;
        this.time_per_mile = null;
        //this.time_per_mile_active = false;
        this.cost_per_hour = null;
        this.cost_per_mile = null;
        this.extra_labor_hours_fee = null;
        this.extra_truck_hours_fee = null;
        this.packing_closet_increase = null;
        this.packing_room_increase = null;
    }

}
