import { Address } from "../global/address";

export class AccountCompany {public id: string;
    public account_name: string;
    public account_email: string;
    public account_phone: string;
    public account_password: string;
    public company_dot_or_license: string;
    public company_name: string;
    public company_phone: string;
    public company_address: Address;
    public company_position: string;
    public validated: boolean;
    public status: string; // 'NEW' | 'PENDING_COMPLETE' | 'CREATED'
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.account_name = null;
        this.account_email = null;
        this.account_phone = null;
        this.account_password = null;
        this.company_dot_or_license = null;
        this.company_name = null;
        this.company_phone = null;
        this.company_address = new Address();
        this.company_position = null;
        this.status = 'NEW';
        this.validated = false;
        this.created = null;
        this.updated = null;
    }
}