<div class="page-container">
    <div class="billing-container" id="container">

        <div class="navbar d-flex">
            <div class="col py-2">
                <img src="/assets/img/logo_moverxpro360.png" class="logo-brand">
            </div>
            <ul class="container-account navbar-item">
                <li class="nav-item">
                    <div class="block-billing m-a">
                        <span class="billing">
                            <span *ngIf="authService.workspaceSession">
                                {{authService.workspaceSession.workspace.name}}
                            </span>
                            Billing
                        </span>
                    </div>
                </li>
                <li class="nav-item dropdown user-profile-dropdown  order-lg-0 order-1">
                    <a href="javascript:void(0);" class="nav-link dropdown-toggle user" id="userProfileDropdown"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa-solid fa-bars"></i>
                    </a>
                    <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                        <div class="dropdown-item">
                            <a (click)="openModalBillingProfile()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                Update the Billing Profile
                            </a>
                        </div>

                        <div>
                            <hr class="dropdown-divider">
                        </div>

                        <div class="dropdown-item" [ngClass]="{'lock-item': hasPendingInvoices == true}">
                          <a [attr.disabled]="hasPendingInvoices ? true : null" appWorkspaceRouterLink="/dashboard">
                              <svg _ngcontent-rer-c215="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layout">
                                  <rect _ngcontent-rer-c215="" x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                  <line _ngcontent-rer-c215="" x1="3" y1="9" x2="21" y2="9"></line>
                                  <line _ngcontent-rer-c215="" x1="9" y1="21" x2="9" y2="9"></line>
                              </svg>
                              Dashboard &nbsp;
                              <svg *ngIf="hasPendingInvoices" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                          </a>
                      </div>


                        <div class="dropdown-item">
                            <a routerLink="/my-profile/workspaces">
                                <i class="fa-brands fa-hubspot"></i>
                                My Workspaces
                            </a>
                        </div>
                        <div class="dropdown-item">
                            <a class="cursor-pointer" (click)="logout()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-log-out">
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                    <polyline points="16 17 21 12 16 7"></polyline>
                                    <line x1="21" y1="12" x2="9" y2="12"></line>
                                </svg>
                                Log Out
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <!--  BEGIN CONTENT AREA  -->
        <div id="content" class="main-content">
            <div class="layout-spacing layout-top-spacing m-0">

                <div class="widget-container searchable-container list">

                    <div class="section-title">
                        Usage Summary
                    </div>

                    <div class="row block-sections p-2">

                        <div class="col-md-6 layout-spacing section-account-info p-1">
                            <div class="widget widget-table-one h-170">

                                <div class="widget-heading">
                                    <div class="title-block">Account Info</div>
                                    <div class="w-icon">
                                        <i class="fas fa-user-lock"></i>
                                    </div>
                                </div>

                                <div class="widget-content">
                                    <div class="box-info">
                                        <i class="fa-brands fa-hubspot"></i>
                                        <span *ngIf="isLoadingSection.account"> <strong>Workspace:</strong> &nbsp; {{ nameWorkspace }} </span>
                                        <span *ngIf="!isLoadingSection.account">Loading...</span>
                                    </div>
                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        <span *ngIf="isLoadingSection.account"> <strong>Owner:</strong> &nbsp; {{ account.name }} </span>
                                        <span *ngIf="!isLoadingSection.account">Loading...</span>
                                    </div>
                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                        <span *ngIf="isLoadingSection.account"> <strong>Owner's email:</strong> &nbsp; {{ account.email }} </span>
                                        <span *ngIf="!isLoadingSection.account">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 layout-spacing section-active-companies p-1">
                            <div class="widget widget-table-one h-170">
                                <div class="widget-heading">
                                    <div class="title-block">Active Companies</div>
                                    <div class="w-icon">
                                        <i class="fa-solid fa-building"></i>
                                    </div>
                                </div>

                                <div class="widget-content">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="key">Total Companies</div>
                                        </div>
                                        <div class="col-md-5 block-amount">
                                            <div class="amount">
                                                {{activeCompanies}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 layout-spacing section-active-users p-1">
                            <div class="widget widget-table-one h-170">
                                <div class="widget-heading">
                                    <div class="title-block">Active Accounts</div>
                                    <div class="w-icon">
                                        <i class="fas fa-user-tie"></i>
                                    </div>
                                </div>

                                <div class="widget-content">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="key">Total Accounts</div>
                                        </div>
                                        <div class="col-md-5 block-amount">
                                            <div class="amount">
                                                {{activeAccounts}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 layout-spacing section-billing-profile p-1">
                            <div class="widget widget-table-one h-200">
                                <div class="widget-heading">
                                    <div class="title-block">Billing Profile</div>
                                    <div class="w-icon">
                                        <i class="fas fa-file-invoice-dollar"></i>
                                    </div>
                                </div>

                                <div class="widget-content">

                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
                                        <span *ngIf="isLoadingSection.billingProfile">
                                            <strong>Billing To:</strong>
                                            &nbsp;
                                            <ng-container *ngIf="billingProfile.company != null">
                                                {{billingProfile.company}}
                                            </ng-container>
                                            <ng-container *ngIf="billingProfile.company == null">
                                                not specified
                                            </ng-container>
                                        </span>
                                        <span *ngIf="!isLoadingSection.billingProfile">Loading...</span>
                                    </div>

                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                        <span *ngIf="isLoadingSection.billingProfile">
                                            <strong>Billing Email:</strong>
                                            &nbsp;
                                            <ng-container *ngIf="billingProfile.email != null">
                                                {{billingProfile.email}}
                                            </ng-container>
                                            <ng-container *ngIf="billingProfile.email == null">
                                                not specified
                                            </ng-container>
                                        </span>
                                        <span *ngIf="!isLoadingSection.billingProfile">Loading...</span>
                                    </div>

                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                        <span *ngIf="isLoadingSection.billingProfile">
                                            <strong>Billing Address:</strong>
                                            &nbsp;
                                            <ng-container *ngIf="billingProfile.address?.full_address !== null">
                                                {{billingProfile.address.full_address}}
                                            </ng-container>
                                            <ng-container *ngIf="(billingProfile.address == null) || (billingProfile.address?.full_address == null)">
                                                not specified
                                            </ng-container>
                                        </span>
                                        <span *ngIf="!isLoadingSection.billingProfile">Loading...</span>
                                    </div>

                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                                        <span *ngIf="isLoadingSection.billingProfile">
                                            <strong>Credit Card:</strong>
                                            &nbsp;
                                            <ng-container *ngIf="billingProfile.credit_card?.number !== null">
                                                XXXX XXXX XXXX {{billingProfile.credit_card.number}}
                                            </ng-container>
                                            <ng-container *ngIf="billingProfile.credit_card == null || billingProfile.credit_card?.number == null">
                                                not specified
                                            </ng-container>
                                        </span>
                                        <span *ngIf="!isLoadingSection.billingProfile">Loading...</span>
                                    </div>

                                    <div class="box-info">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        <span *ngIf="isLoadingSection.billingProfile">
                                            <strong>Credit Card Expire:</strong>
                                            &nbsp;
                                            <ng-container *ngIf="billingProfile.credit_card?.expiration_date !== null">
                                                {{billingProfile.credit_card.expiration_date}}
                                            </ng-container>
                                            <ng-container *ngIf="billingProfile.credit_card == null || billingProfile.credit_card?.expiration_date == null">
                                                not specified
                                            </ng-container>
                                        </span>
                                        <span *ngIf="!isLoadingSection.billingProfile">Loading...</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-3 layout-spacing section-consumed-space p-1">
                            <div class="widget widget-table-one h-200">
                                <div class="widget-heading">
                                    <div class="title-block">Consumed Space</div>
                                    <div class="w-icon">
                                        <i class="fa-solid fa-database"></i>
                                    </div>
                                </div>

                                <div class="widget-content mt-5">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="key col-6">PDF:</div>
                                                <div class="value col-6">{{storageSize.pdfSize}} GiB</div>
                                            </div>
                                            <div class="row">
                                                <div class="key col-6">TXT:</div>
                                                <div class="value col-6">{{storageSize.txtSize}} GiB</div>
                                            </div>
                                            <div class="row">
                                                <div class="key col-6">MEDIA:</div>
                                                <div class="value col-6">{{storageSize.mediaSize}} GiB</div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-5 block-amount">
                                            <div class="amount">
                                                <span *ngIf="!loadingData">
                                                    {{storageSize.totalSize}}GiB
                                                </span>
                                                <span *ngIf="loadingData">
                                                    Loading...
                                                </span>
                                            </div>

                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <router-outlet></router-outlet>

                </div>
            </div>
        </div>
    </div>
</div>



<!-- MODAL para actualizar el PERFIL DE PAGOS -->
<div class="modal fade" #modalBillingProfile tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content" *ngIf="billingProfileForm !== null">

            <form #modalForm="ngForm" (ngSubmit)="saveBillingProfile(modalForm)" ngNativeValidate novalidate autocomplete="off">

                <div class="modal-header">
                    <h5 class="modal-title">
                        Billing Profile
                    </h5>
                </div>

                <div class="modal-body">

                    <div class="form-group mb-4">
                        <label for="billing_profile_company" style="width: 100%;">Billing Company</label>
                        <input type="text" [(ngModel)]="billingProfileForm.company" class="form-control" id="billing_profile_company" placeholder="Enter Billing Company" name="billing_profile_company" #CompanyField="ngModel" required>
                        <ng-container
                            *ngIf="CompanyField.control.errors != null && (modalForm.submitted || CompanyField.control.touched )">
                            <div class="invalid-feedback" *ngIf="CompanyField.control.errors.required">
                                *Required Field.
                            </div>
                        </ng-container>
                    </div>

                    <div class="form-group mb-4">
                        <label for="billing_profile_email" style="width: 100%;">Billing Email</label>
                        <input type="text" [(ngModel)]="billingProfileForm.email" class="form-control" id="billing_profile_email" placeholder="Enter Billing Email" name="billing_profile_email" #EmailField="ngModel" required>
                        <ng-container
                            *ngIf="EmailField.control.errors != null && (modalForm.submitted || EmailField.control.touched )">
                            <div class="invalid-feedback" *ngIf="EmailField.control.errors.required">
                                *Required Field.
                            </div>
                        </ng-container>
                    </div>

                    <div>
                        <h6 class="section-sub-title">
                            Billing Address
                        </h6>
                        <div class="row">

                            <div class="col-8">
                                <div class="form-group mb-4">
                                    <label for="billing_profile_street" style="width: 100%;">Street</label>
                                    <input type="text" [(ngModel)]="billingProfileForm.address.street" class="form-control" id="billing_profile_street" placeholder="Enter Street" name="billing_profile_street" #addressStreetField="ngModel" required>
                                    <ng-container
                                        *ngIf="addressStreetField.control.errors != null && (modalForm.submitted || addressStreetField.control.touched )">
                                        <div class="invalid-feedback" *ngIf="addressStreetField.control.errors.required">
                                            *Required Field.
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="billing_profile_state" style="width: 100%;">State</label>
                                    <select name="billing_profile_state" id="billing_profile_state" [(ngModel)]="billingProfileForm.address.state" class="form-control" #addressStateField="ngModel" required>
                                        <option value="AK">Alaska</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="IA">Iowa</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MD">Maryland</option>
                                        <option value="ME">Maine</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MT">Montana</option>
                                        <option value="NC">North-carolina</option>
                                        <option value="ND">North-dakota</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NH">New-hampshire</option>
                                        <option value="NJ">New-jersey</option>
                                        <option value="NM">New-mexico</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NY">New-York</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode-island</option>
                                        <option value="SC">South-carolina</option>
                                        <option value="SD">South-dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VA">Virginia</option>
                                        <option value="VT">Vermont</option>
                                        <option value="WA">Washington</option>
                                        <option value="NM">Wisconsin</option>
                                        <option value="WV">West-virginia</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                    <ng-container
                                        *ngIf="addressStateField.control.errors != null && (modalForm.submitted || addressStateField.control.touched )">
                                        <div class="invalid-feedback" *ngIf="addressStateField.control.errors.required">
                                            *Required Field.
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-8">
                                <div class="form-group mb-4">
                                    <label for="billing_profile_address_city" style="width: 100%;">City</label>
                                    <input type="text" [(ngModel)]="billingProfileForm.address.city" class="form-control" id="billing_profile_address_city" placeholder="Enter City" name="billing_profile_address_city" #AddressCityField="ngModel" required>
                                    <ng-container
                                        *ngIf="AddressCityField.control.errors != null && (modalForm.submitted || AddressCityField.control.touched )">
                                        <div class="invalid-feedback" *ngIf="AddressCityField.control.errors.required">
                                            *Required Field.
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="col-4">
                                <div class="form-group mb-4">
                                    <label for="billing_profile_address_zipcode" style="width: 100%;">Zip Code</label>
                                    <input type="text" [(ngModel)]="billingProfileForm.address.zip_code" class="form-control" id="billing_profile_address_zipcode" placeholder="Enter Zip Code" name="billing_profile_address_zipcode" #addressZipCodeField="ngModel" required>
                                    <ng-container
                                        *ngIf="addressZipCodeField.control.errors != null && (modalForm.submitted || addressZipCodeField.control.touched )">
                                        <div class="invalid-feedback" *ngIf="addressZipCodeField.control.errors.required">
                                            *Required Field.
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="text-right">
                        <button class="btn btn-cancel" data-dismiss="modal" type="button">
                            Cancel
                        </button>
                        <button class="btn btn-save" type="submit">
                            Save
                        </button>
                    </div>
                </div>

            </form>

        </div>
    </div>
</div>
