import { ConstantsMessages } from 'src/app/constants-messages';
import { Customer } from 'src/app/entities/workspace/customer';
import { Employee } from 'src/app/entities/workspace/employee';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { Ticket } from 'src/app/entities/workspace/ticket';
import { TicketRemark } from 'src/app/entities/workspace/ticket-remark';
import { TicketRemarkView } from 'src/app/entities/workspace/ticket-remark-view';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersService } from 'src/app/services/workspaces/customers.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { TicketsRemarksService } from 'src/app/services/companies/tickets-remarks.service';
import { TicketsService } from 'src/app/services/companies/tickets.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HelperService } from 'src/app/services/helper.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';

declare const jQuery;
@Component({
    selector: 'app-tickets-view',
    templateUrl: './tickets-view.component.html',
    styleUrls: ['./tickets-view.component.scss']
})
export class TicketsViewComponent implements OnInit {

    readonly environment = environment;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    typeTickets;

    ticket: Ticket;
    customer: Customer;
    estimate: Estimate;
    dataForm: TicketRemark;
    remarks: Array<TicketRemarkView>;
    employees: Array<Employee>;
    workDepartments: Array<WorkDepartment>;

    constructor(
        public authService: AuthService,
        private ticketService: TicketsService,
        private currentRoute: ActivatedRoute,
        private estimatesService: EstimatesService,
        private customerService: CustomersService,
        private ticketsRemarksService: TicketsRemarksService,
        private employeesService: EmployeesService,
        private ticketsTypesService: TicketsTypesService,
        private workDepartmentsService: WorkDepartmentsService,
        private helperService: HelperService
    ) {
        this.ticket = new Ticket();
        this.customer = new Customer();
        this.dataForm = new TicketRemark();
        this.estimate = new Estimate();
        this.remarks = [];
        this.employees = [];
        this.workDepartments = [];
        this.typeTickets = [];
    }

    ngOnInit(): void {
     
    }

    ngAfterViewInit(): void{
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.loadTicket(params.id);
            }
        });
        this.loadEmployees();
        this.loadWorkDeparments();
        this.loadTicketsType();
    }

    loadTicket(id: string) {
        this.helperService.showLoadingMxpro360();
        this.ticketService
            .getById(id)
            .then((response) => {
                this.ticket = response;
                this.loadCustomer(this.ticket.customer_id);
                this.loadEstimate(this.ticket.estimate_id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.loadRemarks();
            });
    }

    loadEmployees() {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadWorkDeparments() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAll()
            .then((response) => {
                this.workDepartments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadCustomer(id: string) {
        this.helperService.showLoadingMxpro360();
        this.customerService
            .getById(id)
            .then((response) => {
                this.customer = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    loadEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response) => {
                this.estimate = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadRemarks() {
        this.helperService.showLoadingMxpro360();
        this.ticketsRemarksService
            .getAll(this.ticket.id)
            .then((response) => {
                this.remarks = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    addRemarks() {
        this.helperService.showLoadingMxpro360();
        this.dataForm.customer_id = this.ticket.customer_id;
        this.dataForm.employee_id = this.ticket.created_by_employee_id;
        this.dataForm.ticket_id = this.ticket.id;
        this.ticketsRemarksService
            .save(this.ticket.id, this.dataForm)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.customer = response;
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('Error: ', error);
            })
            .finally(() => {
                this.loadRemarks();
                this.helperService.hideLoadingMxpro360();
            });

    }

    removeRemarks(id) {
        this.helperService.showLoadingMxpro360();
        this.ticketsRemarksService
            .remove(this.ticket.id, id)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
                console.error(error);
            }).finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.loadRemarks();
            });
    }


    onChangeLogo(files: Array<string>) {
        if (files.length > 0) {
            const filess = {
                id: null,
                name: files[0],
                display_name: files[0],
                mime_type: 'mime_type',
                created: null
            };
            this.dataForm.files.push(filess);
        }
    }

    updateInfo() {
        this.helperService.showLoadingMxpro360();
        this.ticketService.edit(this.ticket).then(() => {
            this.loadRemarks();
        })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * permite verificar si un empleado pertenece al word department seleccionado
     * @param employee_id 
     */
    workDepartmentHasEmployee(employee_id: string) {
        for (let index in this.workDepartments) {
            if (this.workDepartments[index].id === this.ticket.work_department_id) {
                return this.workDepartments[index].employees_id.includes(employee_id);
            }
        }
        return false;
    }

    goToEstimate(){
        this.helperService.openNewTab(this.estimate.id, 'estimate');        
    }

    isPrivate(boolean){
        this.dataForm = new TicketRemark();
        this.dataForm.private = boolean;
    }


    private loadTicketsType() {
        this.helperService.showLoadingMxpro360();
        this.ticketsTypesService
          .getAll()
          .then((response) => {
            this.typeTickets = response;
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
}
