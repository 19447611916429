import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Invoice } from 'src/app/entities/admin/invoice';
import { WorkspaceInvoicesService } from 'src/app/services/accounts/workspace-invoices.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
    selector: 'app-workspaces-billing-invoices',
    templateUrl: './workspaces-billing-invoices.component.html',
    styleUrls: ['./workspaces-billing-invoices.component.scss']
})
export class WorkspacesBillingInvoicesComponent implements OnInit {

    invoices: Array<Invoice>;
    workspace_id: string;

    constructor(
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private workspaceInvoicesService: WorkspaceInvoicesService,) {
        this.invoices = [];
        this.workspace_id = null;
    }

    ngOnInit(): void {
        this.currentRoute.parent
            .params
            .subscribe(params => {
                if (typeof params.id !== 'undefined') {
                    this.workspace_id = params.id;
                    this.load(params.id);
                }
            });
    }

    /**
     * Permite cargar las facturas
     */
    load(workspace_id: string) {
        this.helperService.showLoadingMxpro360();
        this.workspaceInvoicesService
            .getAll(workspace_id)
            .then((response: Array<Invoice>) => {
                this.invoices = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
