import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Invoice } from 'src/app/entities/admin/invoice';
import { Payment } from 'src/app/entities/admin/payment';
import { PaymentProcessRequest } from 'src/app/entities/admin/payment-process-request';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceInvoicesService {


    constructor(private http: HttpClient) {

    }

    /**
     * Permite obtener todas las facturas de un workspace
     * @param workspace_id 
     * @returns 
     */
    getAll(workspace_id: string): Promise<Array<Invoice>> {
        return this.http.get<Array<Invoice>>(environment.api.account + '/workspaces/' + workspace_id + '/invoices').toPromise();
    }

    /**
     * Permite obtener un invoice pasandole el id del workspace y el id del invoice
     * @param workspace_id 
     * @param invoice_id 
     * @returns 
     */
    getById(workspace_id: string, invoice_id: string): Promise<Invoice> {
        return this.http.get<Invoice>(environment.api.account + '/workspaces/' + workspace_id + '/invoices/' + invoice_id).toPromise();
    }


    /**
     * permite obtener todas las facturas que no se han pagado
     * 
     * @param workspace_id 
     * @param invoice_id 
     * @returns 
     */
    getAllByPending(workspace_id: string): Promise<Array<Invoice>> {
        return this.http.get<Array<Invoice>>(environment.api.account + '/workspaces/' + workspace_id + '/invoices/pending').toPromise();
    }


    /**
     * permite obtener los pagos
     * @param workspace_id 
     * @param invoice_id 
     * @returns 
     */
    getPaymentsProcessedById(workspace_id: string, invoice_id: string): Promise<Array<Payment>> {
        return this.http.get<Array<Payment>>(environment.api.account + '/workspaces/' + workspace_id + '/invoices/' + invoice_id + '/payments').toPromise();
    }


    /**
     * permite procesar un pago
     * 
     * @param id 
     * @param payment 
     * @returns 
     */
    processPayment(workspace_id: string, invoice_id: string, paymentRequestForm: PaymentProcessRequest): Promise<Payment> {
        return this.http.post<Payment>(environment.api.account + '/workspaces/' + workspace_id + '/invoices/' + invoice_id + '/payments', paymentRequestForm).toPromise();
    }

}
