import { Advertiser } from '../../../entities/workspace/advertiser';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { AuthService } from '../../../services/auth.service';
import { BetweenDates } from '../../../entities/helpers/between-dates';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { LeadExport } from 'src/app/entities/workspace/lead-export';
import { LeadsReportService } from '../../../services/workspaces/leads-report.service';
import { Paginator } from 'src/app/entities/helpers/paginator';

declare const jQuery;
@Component({
  selector: 'app-leads-report',
  templateUrl: './leads-report.component.html',
  styleUrls: ['./leads-report.component.scss']
})
export class LeadsReportComponent implements OnInit {

  public betweenDates: BetweenDates;

  public selectTypeService: number;

  public selectDateType: number;

  public advertisers: Array<Advertiser>;

  public advertiser: string;

  public exportLeads: Array<LeadExport>;

  public actualPage: number;

  public leadStatus: 'NEW' | 'IN_ESTIMATION' | 'DEAD_LEAD' | 'BOOKED' | 'NOT_CONTACTED' |
    'PICKED_UP' | 'IN_TRANSIT' | 'IN_STORAGE' | 'DELIVERED';

  public paginator: Paginator;

  constructor(
    private advertisersService: AdvertisersService,
    private authService: AuthService,
    private helperService: HelperService,
    private leadsReportService: LeadsReportService
  ) {
    this.actualPage = 1;
    this.advertiser = null;
    this.advertisers = [];
    this.betweenDates = new BetweenDates();
    this.exportLeads = [];
    this.paginator = new Paginator();
    this.paginator.paginator.per_page = 100;
    this.paginator.paginator.total = 0;
    this.selectDateType = 1;
    this.selectTypeService = 1;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadAdvertiser();
  }

  loadAdvertiser() {
    this.advertisersService.getAll().then((response) => {
      this.advertisers = response;
    })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  /**
   * recibe y asigna fechas para filtro
   *
   * @param date Filtro de fechas a buscar
   */
  getFilterDates(date: BetweenDates): void {
    this.betweenDates = date;
  }

  /**
   * Busca los datos por el filtro de fecha
   */
  search(exportData: boolean): void {

    const report_data = {
      betweenFilter: {
        dateStart: this.betweenDates.dateStart, dateEnd: this.betweenDates.dateEnd
      },
      export: exportData,
      fromState: '',
      priorityId: '',
      selectDateType: 'OPEN',
      service: '',
      status: this.leadStatus,
      toState: '',
      advertiser_id: this.advertiser
    };

    switch (this.selectDateType) {
      case 1:
        report_data.selectDateType = 'OPEN';
        break;

      case 2:
        report_data.selectDateType = 'MOVE';
        break;
    }

    switch (Number(this.selectTypeService)) {
      case 1:
        report_data.service = '';
        break;

      case 2:
        report_data.service = 'LOCAL';
        break;

      case 3:
        report_data.service = 'LONG';
        break;

      case 4:
        report_data.service = 'MOVING';
        break;

      case 5:
        report_data.service = 'AUTO_TRANSPORT';
        break;
    }

    
    if (exportData) {
      this.helperService.downloadReport('EXPORT_LEADS', null, report_data);
    } else {
      this.helperService.showLoadingMxpro360();
      this.leadsReportService.getLeadReport(report_data, this.paginator)
        .then((response) => {
            this.paginator.data = response.data;
            this.paginator.paginator.total = response.paginator.total;
  
        })
        .catch((error) => {
          console.error('error', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }
    
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page !== currentpage) {
        this.paginator.paginator.page = currentpage;
        this.search(false);
    }
}
}
