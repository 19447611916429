<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-spacing align-self-center">
            <h3>
                Pickup Documents Report
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Pickup Documents Report
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/archive-pickup-documents-report" class="btn btn-info mr-2">
                    Archived Pickup Docs
                </a>
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>
        <div class="col-lg-12 col-md-12 block-search text-center">
            <button class="btn btn-warning btn-search" (click)="search()">
                Search
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="info-tip">
               <span class="asterisk">*</span>
               Displays all jobs to which pickup documents have been added, except those that are archived without pickup documents.
            </div>
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Carrier</th>
                                <th>Customer</th>
                                <th>Service</th>
                                <th>Status</th>
                                <th>Pickup Date</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr [ngClass]="{'pending-signature':row.job_outsourcing?.status == 'PENDING_SIGNATURE'}"
                                *ngFor="let row of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;"  [ngStyle]="row.statuses.general != 'CUSTOMER_CANCELED' ? {'background-color': 'white'} : {'background-color': '#ffa7a7'}">
                                <td>
                                    {{row.estimate.document.code}}
                                </td>
                                <td class="text-center">
                                    <strong *ngIf="row.carrier">
                                        {{row.carrier?.name}}
                                    </strong>
                                    <span *ngIf="row.carrier == null">
                                        --
                                    </span>
                                </td>
                                <td>
                                    {{row.estimate.customer.name}} {{row.estimate.customer.last_name}}
                                </td>
                                <td>
                                    <span [ngSwitch]="row.estimate.service">
                                        <ng-container *ngSwitchCase="'LONG'">
                                            Long
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'LOCAL'">
                                            Local
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'AUTO_TRANSPORT'">
                                            Auto Transport
                                        </ng-container>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <span [ngSwitch]="row.statuses.general">
                                            <ng-container *ngSwitchCase="'NEW'">
                                                New
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED_BOXES'">
                                                Delivered Boxes
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PACKED_MOVE'">
                                                Packed Move
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PICKED_UP'">
                                                Picked Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_TRANSIT'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'CUSTOMER_CANCELED'">
                                                Canceled
                                            </ng-container>

                                            <ng-container *ngSwitchDefault>
                                                {{row.estimate.status}}
                                            </ng-container>
                                        </span>
                                    </span>
                                </td>
                                <td>{{row.estimate.pickup.range_start | date}} to {{row.estimate.pickup.range_start | date}}</td>
                                <td *ngIf="row.estimate.pickup.address.full_address">{{row.estimate.pickup.address.full_address}} - {{row.estimate.pickup.address.state}} {{row.estimate.delivery.address.zip_code}}</td>
                                <td *ngIf="!row.estimate.pickup.address.full_address">{{row.estimate.pickup.address.state}} {{row.estimate.delivery.address.zip_code}}</td>
                                <td *ngIf="row.estimate.delivery.address.full_address">{{row.estimate.delivery.address.full_address}} - {{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}} </td>
                                <td *ngIf="!row.estimate.delivery.address.full_address">{{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}}</td>
                                <td>
                                    <button class="btn btn-info" (click)="openNewTab(row.id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>

</div>
