import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FordwardablePhoneLines } from 'src/app/entities/workspace/fordwardable-phone-lines';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { PhoneNumber } from 'src/app/entities/workspace/phone-number';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PhoneLinesService {

    constructor(private http: HttpClient) {

    };

    getAll(): Promise<Array<PhoneLine>> {
        return this.http.get<Array<PhoneLine>>(environment.api.workspace + '/pbx-phone-lines').toPromise();
    }

    getAllByCompany(companyId): Promise<Array<PhoneLine>> {
        return this.http.get<Array<PhoneLine>>(environment.api.workspace + '/pbx-phone-lines-company/' + companyId).toPromise();
    }

    getAllNums(): Promise<Array<PhoneNumber>> {
        return this.http.get<Array<PhoneNumber>>(environment.api.workspace + '/pbx-phone-lines-nums').toPromise();
    };

    getById(id: string) {
        return this.http.get<PhoneLine>(environment.api.workspace + '/pbx-phone-lines/' + id).toPromise();
    }

    save(dataForm: PhoneLine): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    };

    add(dataForm: PhoneLine) {
        return this.http.post(environment.api.workspace + '/pbx-phone-lines', dataForm).toPromise();
    };

    edit(dataForm: PhoneLine) {
        return this.http.put(environment.api.workspace + '/pbx-phone-lines/' + dataForm.id, dataForm).toPromise();

    };

    remove(id: string): Promise<Array<PhoneLine>> {
        return this.http.delete<Array<PhoneLine>>(environment.api.workspace + '/pbx-phone-lines/' + id).toPromise();
    };

    getFordwardablePhoneLines(companyId): Promise<FordwardablePhoneLines> {
        return this.http.get<FordwardablePhoneLines>(environment.api.workspace + '/fordwardable-phone-lines/' + companyId).toPromise();
    }

    activeAgents(callid) {
        return this.http.get(environment.api.apiRtcPrivate + '/active-agents', callid).toPromise();
    }

};
