  <!-- Menu Top Mobile -->
  <div class="row mb-3 menu-responsive">
      <div class="nav-item">
        <a class="nav-link list-actions" [ngClass]="{'active' : filterSection == 'ALL' }" (click)="toFilterMails('ALL')">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/><polyline points="22,6 12,13 2,6"/></svg>
            <span class="nav-names pl2">All</span>
        </a>
      </div>
  </div>
  <!-- Menu Top Mobile -->


<div class="mail-box-container">
    <div class="mail-overlay"></div>

    <div class="tab-title">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-12 text-center mail-btn-container">

                <div class="box-btn-email">
                    <i class="fa-solid fa-circle-info i-info tooltipmxp" title="Allows you to send an email using the assigned personal email address"></i>
                    <button id="btn-compose-mail" class="btn btn-block btn-compose-person-email rounded mb-2" (click)="toCompose('google')" >
                        <i class="fa-solid fa-envelope"></i>
                    </button>
                    <span>New Email</span>
                </div>

                <div class="box-btn-email" [appCanUserCompany]="['COMPANY_EMAILS']">
                    <i class="fa-solid fa-circle-info i-info tooltipmxp" title="Allows you to send an email using the default email address of the company"></i>
                    <button class="btn btn-block btn-info btn-compose-notify-email rounded mb-2"  (click)="toCompose('company')" >
                        <i class="fa-solid fa-envelope-circle-check"></i>
                    </button>
                    <span class="txt-email-default">New Email Default</span>
                </div>            
                
                <div class="box-btn-email">
                    <i class="fa-solid fa-circle-info i-info tooltipmxp" title="Show all emails"></i>
                    <button class="btn nav-link list-actions btn-email-all" (click)="toFilterMails('ALL')">
                        <i class="fa-solid fa-envelopes-bulk"></i>
                    </button>
                    <span>All</span>
                </div>

            </div>
        </div>
    </div>

    <div id="mailbox-inbox" class="accordion mailbox-inbox">

        <div class="search">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu mail-menu d-lg-none"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            <input type="text" class="form-control input-search" placeholder="Filter..." [(ngModel)]="search" (keyup)="toSearch()">
        </div>

        <div class="action-center">
            <div >
                <div class="n-chk">
                    <h5 class="m-0">
                        Sent emails
                    </h5>
                </div>
            </div>

            <!-- <div >
                <a class="nav-link dropdown-toggle d-icon label-group" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-toggle="tooltip" data-placement="top" data-original-title="Label" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg></a>
                <div class="dropdown-menu dropdown-menu-right d-icon-menu">
                    <a class="label-group-item label-personal dropdown-item position-relative g-dot-primary" href="javascript:void(0);"> Personal</a>
                    <a class="label-group-item label-work dropdown-item position-relative g-dot-warning" href="javascript:void(0);"> Work</a>
                    <a class="label-group-item label-social dropdown-item position-relative g-dot-success" href="javascript:void(0);"> Social</a>
                    <a class="label-group-item label-private dropdown-item position-relative g-dot-danger" href="javascript:void(0);"> Private</a>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" data-toggle="tooltip" data-placement="top" data-original-title="Important" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-star action-important"><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-toggle="tooltip" data-placement="top" data-original-title="Spam" class="feather feather-alert-circle action-spam"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" data-toggle="tooltip" data-placement="top" data-original-title="Revive Mail" stroke-linejoin="round" class="feather feather-activity revive-mail"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-toggle="tooltip" data-placement="top" data-original-title="Delete Permanently" class="feather feather-trash permanent-delete"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                <div class="dropdown d-inline-block more-actions">
                    <a class="nav-link dropdown-toggle" id="more-actions-btns-dropdown" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="more-actions-btns-dropdown">
                        <a class="dropdown-item action-mark_as_read" href="javascript:void(0);">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg> Mark as Read
                        </a>
                        <a class="dropdown-item action-mark_as_unRead" href="javascript:void(0);">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg> Mark as Unread
                        </a>
                        <a class="dropdown-item action-delete" href="javascript:void(0);">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-toggle="tooltip" data-placement="top" data-original-title="Delete" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg> Trash
                        </a>
                    </div>
                </div>

            </div> -->
        </div>

        <div class="message-box">
            <div class="message-box-scroll" id="ct">

                <ng-container *ngFor="let email of emails | search: search | async" >
                    <div class="mail-item mailInbox" *ngIf="filterSection == 'ALL' || filterSection == 'SEARCHING'  || filterSection == email.direction " >
                        <div class="animated animatedFadeInUp fadeInUp" >
                            <div class="mb-0">
                                <div class="mail-item-heading collapsed" (click)="openViewEmail(email)" >
                                    <div class="mail-item-inner">

                                        <div class="d-flex">
                                            <div class="f-head">
                                                <div class="avatar-email">
                                                    <span class="text-avatar-email">{{email.from.name | avatarTitle}}</span>
                                                </div>
                                            </div>
                                            <div class="f-body">
                                                <div class="meta-mail-time">
                                                    <p class="user-email" >
                                                        <strong *ngIf="email.direction == 'IN'">
                                                            {{email.from.name | titlecase}}
                                                            <br>
                                                            <div style="font-size: 10px;">
                                                                {{email.from.email}}
                                                            </div>
                                                        </strong>
                                                        <strong *ngIf="email.direction == 'OUT'">
                                                            {{email?.to[0]?.name | titlecase}}
                                                            <br>
                                                            <div style="font-size: 10px;">
                                                                {{email?.to[0]?.email}}
                                                            </div>
                                                        </strong>
                                                    </p>
                                                </div>
                                                <div class="meta-title-tag">
                                                    <p class="mail-content-excerpt" >
                                                        <svg *ngIf="email.files.length > 0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip attachment-indicator">
                                                            <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                                        </svg>
                                                        <span class="mail-title">
                                                            {{email.subject}} -
                                                        </span>
                                                        <span [innerHTML]="sanitizeHtml(email.snippet)"></span>
                                                    </p>
                                                    <div class="tags">
                                                        <span class="g-dot-warning" *ngIf="email.direction == 'IN'" ></span>
                                                        <span class="g-dot-success" *ngIf="email.direction == 'OUT'" ></span>
                                                    </div>
                                                    <p class="meta-time align-self-center">
                                                        {{email.created | calendarDateTime}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="email.files.length > 0">
                                        <div class="attachments" *ngFor="let file of email.files;">
                                            <a [appCdnLink]="'/emails-attachments/' + file.file"  target="_blank" rel="noopener noreferrer">
                                                <span >
                                                    {{file.name}}
                                                </span>
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="content-box visible-box" *ngIf="viewSection == 'VIEW'">
            <div class="d-flex msg-close">
                <svg (click)="closeViewEmail()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left close-message">
                    <line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline>
                </svg>
                <h2 class="mail-title">
                    {{viewEmail.subject | titlecase}}
                </h2>
            </div>
            <div class="mail-content-container mailInbox">

                <div class="mail-to-info">

                    <div class="d-flex user-info">
                        <div class="f-head">
                            <div class="avatar-email">
                                <span class="text-avatar-email">{{viewEmail.from.name | avatarTitle}}</span>
                            </div>
                        </div>
                        <div class="f-body pl-3 pt-1">
                            <div class="meta-title-tag">

                                <h4 class="mail-usr-name">
                                    <span *ngIf="viewEmail.direction == 'IN'">
                                        {{viewEmail.from.name | titlecase}}
                                       
                                    </span>
                                    <span *ngIf="viewEmail.direction == 'OUT'">
                                        {{viewEmail.to[0]?.name | titlecase}}
                                     
                                    </span>
                                </h4>
                            </div>
                            <div class="meta-mail-time">
                                <p class="user-email">
                                    <span *ngIf="viewEmail.direction == 'IN'">
                                        {{viewEmail.from.email | lowercase}}
                                    </span>
                                    <span *ngIf="viewEmail.direction == 'OUT'">
                                        {{viewEmail.to[0]?.email | lowercase}}
                                    </span>
                                </p>
                                <p class="mail-content-meta-date current-recent-mail">
                                    {{viewEmail.created | calendarDateTime}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="action-btns">
                         <button *ngIf="viewEmail.direction == 'IN'" class="btn btn-secondary btn-rounded" type="button" (click)="toComposeReply()">
                            Reply <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-corner-up-left reply"><polyline points="9 14 4 9 9 4"></polyline><path d="M20 20v-7a4 4 0 0 0-4-4H4"></path></svg>
                         </button>
                    </div>
                </div>

                <div class="mail-content" [innerHTML]="sanitizeHtml(viewEmail.content)"></div>


                <div class="attachments" *ngIf="viewEmail.files.length > 0">

                    <h6 class="attachments-section-title">
                        Attachments
                    </h6>

                    <ng-container *ngFor="let file of viewEmail.files;">
                        <a [appCdnLink]="'/emails-attachments/' + file.file" target="_blank" rel="noopener noreferrer">
                            <div class="attachment file-pdf" >
                                <div class="media">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                        <polyline points="14 2 14 8 20 8"></polyline>
                                        <line x1="16" y1="13" x2="8" y2="13"></line>
                                        <line x1="16" y1="17" x2="8" y2="17"></line>
                                        <polyline points="10 9 9 9 8 9"></polyline>
                                    </svg>
                                    <div class="media-body">
                                        <p class="file-name">{{file.name}}</p>
                                        <!-- <p class="file-size">450kb</p> -->
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-container>

                </div>

            </div>
        </div>

    </div>

</div>


<!-- body -->


<!-- Modal -->
<div class="modal fade" #composeMailModal id="composeMailModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">
                            <h3>
                                <strong>
                                    New Email
                                </strong>
                            </h3>
                            
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex mb-3 mail-template">
                                        <i class="fa-solid fa-envelope-open-text"></i>
                                        <div class="w-100">
                                            <select class="select-template form-control" id="m-form" (change)="setTemplate($event)">
                                                <option value="-1" selected>Blank Email</option>
                                                <ng-container *ngFor="let emailTemp of emailTemplates; let i = index">
                                                    <option value="{{i}}" *ngIf="(emailTemp.usage_context == estimate.service || emailTemp.usage_context == 'BOTH') ||
                                                    ((estimate.service === 'LOCAL' || estimate.service === 'LONG') && emailTemp.usage_context === 'MOVING')">{{emailTemp.subject}}</option>
                                                </ng-container>
                                            </select>
                                            <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Select a Template.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngFor="let to of dataForm.to; let i = index">
                                <div class="col-md-5">
                                    <div class="d-flex mb-3 mail-to">
                                        <i class="fa-solid fa-envelope"></i>
                                        <div>
                                            <input required type="email" placeholder="Email" class="form-control" [disabled]="dataForm.thread_id != null" [(ngModel)]="to.email" name="to_email_{{i}}" required>
                                            <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Enter the Recipient Email.</small>
                                            <small *ngIf="disableButton" id="emailHelp1" class="form-text text-muted mb-2 insert-direction color-error">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-5 pl-5">
                                    <div class="d-flex mb-3 mail-cc">
                                        <i class="fa-solid fa-user"></i>
                                        <div>
                                            <input required type="text" placeholder="Name" class="form-control" [disabled]="dataForm.thread_id != null" [(ngModel)]="to.name" name="to_name_{{i}}" required>
                                            <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Enter the Recipient Name.</small>
                                            <small *ngIf="disableButton" id="emailHelp1" class="form-text text-muted mb-2 insert-direction color-error">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 button-recipient">
                                    <button type="button" class="btn btn-success btn-sm" *ngIf="i == 0" (click)="addRecipient()">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                                    </button>
                                    <button type="button" class="btn btn-danger btn-sm" *ngIf="i > 0" (click)="deleteRecipient(i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                    </button>
                                </div>
                            </div>

                            <div class="d-flex mb-4 mail-subject">
                                <i class="fa-solid fa-paper-plane"></i>
                                <div class="w-100">
                                    <input required type="text" placeholder="Subject" class="form-control" [disabled]="dataForm.thread_id != null" [(ngModel)]="dataForm.subject" name="subject" required>
                                    <small id="emailHelp1" class="form-text text-muted mb-2 ml-2">Enter the Subject of the Message.</small>
                                    <small *ngIf="disableButton" id="emailHelp1" class="form-text text-muted mb-2 insert-direction color-error">This field is required.</small>
                                </div>
                            </div>

                            <app-ck-editor [(ngModel)]="dataForm.content" name="content" ngDefaultControl>
                            </app-ck-editor>

                       
                            <div class="col-md-12 mt-5">

                                <div class="form-group">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="attach_inventory" name="attach_inventory"
                                            [(ngModel)]="dataForm.attach_inventory">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="attach_inventory" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.attach_inventory}">
                                            Attach Portal Link
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_esignature"
                                            [(ngModel)]="dataForm.request_esignature" name="request_esignature">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_esignature" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_esignature}">
                                            Request electronic signature
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="estimate.service != 'AUTO_TRANSPORT' && dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_poa"
                                            [(ngModel)]="dataForm.request_poa" name="request_poa">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_poa" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_poa}">
                                            Request POA
                                        </label>
                                    </div>
                                </div>
                                
                                <div class="form-group" *ngIf="estimate.service != 'AUTO_TRANSPORT' && dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_inventory_update"
                                            [(ngModel)]="dataForm.request_inventory_update" name="request_inventory_update">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_inventory_update" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_inventory_update}">
                                            Inventory editable by customer
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="estimate.service != 'AUTO_TRANSPORT' && dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_bol"
                                            [(ngModel)]="dataForm.request_bol" name="request_bol">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_bol" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_bol}">
                                            Request BOL
                                        </label>
                                    </div>
                                </div>

                                <div class="d-flex" *ngIf="dataForm.attach_inventory && dataForm.request_bol">
                                    <label class="signature-type">
                                        Signature Type
                                    </label>
                                    <select id="inputState" [(ngModel)]="dataForm.bol_signature_requested" class="form-control"
                                        name="wd_bol_signature_requested" style="width: 250px;">
                                        <option value="PRE_PICKUP">Pre Pickup</option>
                                        <option value="ON_SITE" *ngIf="estimate.document_bol?.document.esignatures.main && estimate.document_bol?.document.esignatures.second == null">On Site</option>
                                        <option value="DELIVERY" *ngIf="estimate.document_bol?.document.esignatures.second && estimate.document_bol?.document.esignatures.third == null">Delivery</option>
                                    </select>                                
                                </div>


                                <div class="form-group" *ngIf="estimate.service != 'AUTO_TRANSPORT' && dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_token"
                                            [(ngModel)]="dataForm.request_token" name="request_token">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_token" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_token}">
                                            Request TOKEN
                                        </label>
                                    </div>
                                </div>


                                <div class="form-group" *ngIf="estimate.service != 'AUTO_TRANSPORT' && dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_ppw"
                                            [(ngModel)]="dataForm.request_ppw" name="request_ppw">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="request_ppw" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.request_ppw}">
                                            Request PPW
                                        </label>
                                    </div>
                                </div>

                                <div class="d-flex" *ngIf="dataForm.attach_inventory && dataForm.request_ppw">
                                    <label class="signature-type">
                                        PPW Type
                                    </label>
                                    <select id="inputState" [(ngModel)]="dataForm.ppw_document_type_id" class="form-control"
                                        name="wd_ppw_document_type_id" style="width: 250px;">
                                        <ng-container  *ngFor="let documentPPW of documentsPPW" >
                                            <option *ngIf="documentPPW.usage_context == 'PPW_PACKET'" [value]="documentPPW.id">{{documentPPW.name}}</option>
                                        </ng-container>
                                    </select>                                
                                </div>

                                <div class="form-group" [hidden]="!dataForm.attach_inventory">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="request_ppw"
                                            [(ngModel)]="dataForm.attach_files" name="attach_files">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="attach_files" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.attach_files}">
                                            Attach Files
                                        </label>
                                    </div>
                                </div>
                                <div [hidden]="!dataForm.attach_inventory || !dataForm.attach_files">
                                    <div class="d-flex">
                                        <label class="signature-type">
                                            File Types
                                        </label>
                                        <select id="inputState" multiple="multiple" [(ngModel)]="dataForm.attach_file_types" class="form-control file-type"
                                            name="wd_attach_file_types" style="width: 250px;">
                                            <option value="PICKUP">Pickup Docs</option>
                                            <option value="DELIVERY">Delivery Docs</option>
                                            <option value="MEDIA_FILES">Media Files</option>
                                        </select>                                
                                    </div>
                                </div>
                            </div>                            

                        </div>
                    </div>
                </div>

                <!-- send or cancel -->
                <div class="modal-footer">
                    <button class="btn btn-close-modal" type="button" (click)="toCancelCompose()">
                        Cancel
                    </button>
                    <button id="btn-send" class="btn" type="submit" (click)="validateSendEmail()">
                        Send
                    </button>
                </div>
            </div>

    </div>
</div>
