import { Document } from './document';
export class CarrierDocument {
    public id: string;
    public description: string;
    public document: Document;
    public carrier_id: string;
    public company_id: string;
    public employee_id: string;
    public created: string;
    public updated: string;

    constructor() {
        this.id = null;
        this.description = null;
        this.document = new Document();
        this.carrier_id = null;
        this.company_id = null;
        this.employee_id = null;
        this.created = null;
        this.updated = null;
    }
}
