import { Component, OnInit } from '@angular/core';
import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { DriverInvoicesService } from 'src/app/services/companies/driver-invoices.service';
import { HelperService } from 'src/app/services/helper.service';

declare var jQuery;
@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

    invoices: Array<any>;
    ViewInvoice: any;
    viewInvoice: DriverInvoice;

    driverInvoice;

    constructor(
        private driverInvoicesService: DriverInvoicesService,
        private helperService: HelperService
    ) {
        this.ViewInvoice = {
            number: null,
            name: null,
            date: null
        };

        this.viewInvoice = new DriverInvoice();

        this.invoices = [
            {
                number: 1439,
                name: 'juancho',
                date: '12 Apr 2019'
            },
            {
                number: 1439,
                name: 'juancho',
                date: '12 Apr 2019'
            },
            {
                number: 1439,
                name: 'juancho',
                date: '12 Apr 2019'
            },
            {
                number: 1439,
                name: 'juancho',
                date: '12 Apr 2019'
            },
            {
                number: 1439,
                name: 'juancho',
                date: '12 Apr 2019'
            },
            {
                number: 1439,
                name: 'juancho',
                date: '12 Apr 2019'
            },
        ];
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void{
        this.loadInvoiceDrivers();
    }

    loadInvoiceDrivers() {
        this.helperService.showLoadingMxpro360();
        this.driverInvoicesService
            .get()
            .then((response) => {
                this.driverInvoice = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setInv(invoice) {
        this.viewInvoice = invoice;
    }

}
