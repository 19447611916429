<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span>
                Phone Ring Group
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/phone-ring-groups">Phone Ring Groups</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>



    </div>


    <!-- header -->

    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
        <div class="d-flex justify-content-sm-end justify-content-center">
        </div>
    </div>
      <div class="col-xl-12 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
        <div class="d-flex justify-content-sm-end justify-content-center">
            <button class="btn btn-save ml-8 mt-3" type="submit">
                Save
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">


                    <div class="row">
                        <div class="col-md-6 col-sm-12">

                            <div class="form-group mb-3">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                        </span>
                                    </div>
                                    <!-- <select name="phone_number" class="form-control" [(ngModel)]="dataForm.name">
                                        <option value="null" disabled>Select an Option</option>
                                        <option *ngIf="dataForm.id" value="{{dataForm.phone_number}}" disabled>{{dataForm.phone_number}}</option>
                                        <option *ngFor="let num of nums" [value]="num.number">
                                            {{num.number}}
                                        </option>
                                    </select> -->
                                    <input type="text" name="name" class="form-control" [(ngModel)]="dataForm.name" placeholder="Enter an name" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Phone Name.
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label class="control-label">Description</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                        </span>
                                    </div>
                                    <input type="text" name="Description" class="form-control" [(ngModel)]="dataForm.description" placeholder="Enter an Description">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Enter an Description.
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label class="control-label">Company</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </span>
                                    </div>
                                    <select name="company_id" class="form-control" [(ngModel)]="dataForm.company_id" (ngModelChange)="changeCompany()" >
                                        <option value="null" disabled>Select a Company</option>
                                        <option *ngFor="let company of companies" [value]="company.id">
                                            {{company.name | titlecase}}
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Company.
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label class="control-label">Target Destination</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-pull-request"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                                        </span>
                                    </div>
                                    <select name="destination.target" class="form-control" [(ngModel)]="dataForm.destination.target" [disabled]="!dataForm.company_id" (ngModelChange)="changeTarget()">
                                      <option value="" disabled selected>select an option</option>
                                        <option value="RING_GROUP">
                                            Ring Group
                                        </option>
                                        <option value="EXTERNAL_FORWARD" >
                                            External Forward
                                        </option>
                                        <option value="VOICEMAIL">
                                            Voicemail
                                        </option>
                                        <option value="EXTENSION">
                                            Phone Extension
                                        </option>
                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Select a Target.
                                </small>
                            </div>

                            <div class="form-group mb-3" disabled>
                                <label class="control-label">Target Destination ID</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </span>
                                    </div>

                                    <input *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'" type="text" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id" placeholder="Enter an name">

                                    <select *ngIf="this.dataForm.destination.target != 'EXTERNAL_FORWARD'" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id"  [disabled]="!dataForm.destination.target">
                                        <option value="null" disabled>Select An</option>
                                        <ng-container *ngFor="let target of targets">
                                            <option [value]="target.id">
                                                {{target.name | titlecase}}
                                            </option>
                                        </ng-container>

                                    </select>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'RING_GROUP'">
                                    Select a Ring Group.
                                </small>
                                <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'">
                                    Enter a Destination Phone.
                                </small>
                                <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'VOICEMAIL'">
                                    Select a Phone Line With Voicemail.
                                </small>
                                <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTENSION'">
                                    Select a Option.
                                </small>
                                <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'QUEUE'">
                                  Select a QUEUE.
                              </small>



                            </div>

                        </div>

                        <div class="col-md-6 col-sm-12">

                            <div class="form-group mb-3">
                                <label class="control-label">Announcement</label>
                                <div class="upload-audio">
                                    <input type="file"
                                        id="input-file-max-fs"
                                        [value]="dataForm.announcement.display_name"
                                        [appDropify]
                                        (onChange)="onChangeAnnouncement($event)"
                                        class="dropify"
                                        data-max-file-size="3M" />
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label class="control-label">Play Music On Hold</label>
                                <div class="upload-audio">
                                    <input type="file"
                                        id="input-file-max-fs"
                                        [value]="dataForm.play_music_on_hold.display_name"
                                        [appDropify]
                                        (onChange)="onChangePlayMusicOnHold($event)"
                                        class="dropify"
                                        data-max-file-size="3M" />
                                </div>
                            </div>

                            <div class="form-group mt-3 mb-3">
                                <label class="control-label">Ring Duration</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-merge"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M6 21V9a9 9 0 0 0 9 9"></path></svg>
                                        </span>
                                    </div>
                                    <input type="number" name="ring_duration" class="form-control" [(ngModel)]="dataForm.ring_duration" required>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Enter Ring Duration.
                                </small>
                            </div>

                        </div>
                    </div>

                    <!-- <div class="text-center">
                        <button class="btn btn-save ml-1 mt-3" type="submit" (click)="save()">
                            Submit
                        </button>
                    </div> -->

            </div>
        </div>
    </div>
  </form>


    <div class="row">
        <div class="col-lg-12 layout-top-spacing">
            <div class="widget-content widget-content-area">

                <div class="widget-heading d-flex justify-content-between mb-4">
                    <h3 class="title-content-area">
                        Call Ring Groups
                    </h3>
                </div>

                <div class="row">

                    <div class="col-1">
                    </div>

                    <div class="col-4 mw-300">
                        <div class="form-group active-phone-lines mb-3">
                            <label class="control-label">Active Phone Extension</label>
                            <div class="mb-1">
                                <select name="phone_number" class="form-control select-telephone-lines fa-select" (change)="addPhone($event.target.value)" multiple>
                                    <option *ngFor="let ext of extensions" [value]="ext.id" data-icon="glyphicon-music">
                                        <i class="fa-solid fa-grip-vertical"></i>
                                        <span class="ml-2">{{ext.extension}}</span>
                                    </option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mt-2 mb-4">
                                Select a Phone Extension to Add to the Queue.
                            </small>
                        </div>
                    </div>

                    <div class="col-2 arrows">
                        <i class="fa-solid fa-angles-right"></i>
                    </div>

                    <div class="col-4 mw-360">
                        <div class="form-group phone-queue-order mb-3">
                            <label class="control-label">Phone Extension</label>
                            <div class="content-phone-queues">
                                <ol id="sortable-list">
                                    <li *ngFor="let phone of phones; let i = index" id="phone_queued" attr.indexContainer="{{i}}">
                                        <div class="info-phone-queued">
                                            <div class="drag-icon">
                                                <i class="fa-solid fa-grip-vertical"></i>
                                            </div>
                                            <div class="phone-line">
                                                <i class="fa-solid fa-phone mr-1"></i>
                                                <span>{{validateExtension(phone)}}</span>
                                            </div>
                                            <div class="remove-phone-line" (click)="removePhone(phone)">
                                                <i class="fa-solid fa-trash-can"></i>
                                            </div>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mt-2 mb-4">
                                Sort Order of the Queue.
                            </small>
                        </div>
                    </div>

                    <div class="col-1">
                    </div>

                </div>

            </div>
        </div>
    </div>

</div>
