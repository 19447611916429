import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Period } from 'src/app/entities/workspace/period';

@Injectable({
    providedIn: 'root'
})
export class PeriodsService {

    constructor(private http: HttpClient) { }

    getAll(): Promise<Array<Period>> {
        return this.http.get<Array<Period>>(environment.api.workspace + '/periods').toPromise();
    }

    getAllPeriodsByTariffVersionId(tariffVersionId: string): Promise<Array<Period>> {
        return this.http.get<Array<Period>>(environment.api.workspace + '/periods/tariff-version-id/' + tariffVersionId).toPromise();
    }

    getById(id: string) {
        return this.http.get<Period>(environment.api.workspace + '/periods/' + id).toPromise();
    }

    save(dataForm: Period): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Period) {
        return this.http.post(environment.api.workspace + '/periods', dataForm).toPromise();
    }

    edit(dataForm: Period) {
        return this.http.put(environment.api.workspace + '/periods/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/periods/' + id).toPromise();
    }
}
