import { LocalRoomClosets } from "../workspace/local-room-closets";

export class LocalInventoryCloset {
    public id: string;
    public local_room_closet: LocalRoomClosets;
    public capacity_level: string;
    public is_packing: boolean;

    constructor() {
        this.id = null;
        this.local_room_closet = new LocalRoomClosets();
        this.capacity_level = null;
        this.is_packing = false;
    }
}