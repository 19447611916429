import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierFile } from 'src/app/entities/workspace/carrier-file';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarriersFilesService {

    constructor(private http: HttpClient) { }

    getAll(id): Promise<Array<CarrierFile>> {
        return this.http.get<Array<CarrierFile>>(environment.api.workspace + '/carriers/' + id + '/files').toPromise();
    }

    getById(id: string, subId: string) {
        return this.http.get<CarrierFile>(environment.api.workspace + '/carriers/' + id + '/files/' + subId).toPromise();
    }

    save(dataForm: CarrierFile, id: string, subId: string): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm, id);
        }
        return this.edit(dataForm, id, subId );
    }

    add(dataForm: CarrierFile, id: string) {
        return this.http.post(environment.api.workspace + '/carriers/' + id + '/files', dataForm).toPromise();
    }

    edit(dataForm: CarrierFile, id: string, subId: string) {
        return this.http.put(environment.api.workspace + '/carriers/' + id + '/files/' + subId, dataForm).toPromise();
    }

    remove(id: string, subId: string) {
        return this.http.delete(environment.api.workspace + '/carriers/' + id + '/files/' + subId).toPromise();
    }
}
