import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';

import { Component, OnInit } from '@angular/core';

import { SmsCampaign } from '../../../entities/workspace/sms-campaign';
import { SmsCampaignsService } from '../../../services/companies/sms-campaigns.service';

declare const jQuery;
@Component({
  selector: 'app-sms-campaigns',
  templateUrl: './sms-campaigns.component.html',
  styleUrls: ['./sms-campaigns.component.scss']
})
export class SmsCampaignsComponent implements OnInit {

  public actualPage;

  public campaigns: Array<SmsCampaign>;

  constructor(
    private smsCampaignsService: SmsCampaignsService,
    private helperService: HelperService
  ) { }

  ngOnInit(): void {
  
  }

  ngAfterViewInit(): void{
    this.loadAllCampaigns();
    this.actualPage = 1;  
  }

  public loadAllCampaigns(): void {
    this.helperService.showLoadingMxpro360();
    this.smsCampaignsService
      .getAllCampaigns()
      .then((response) => {
        this.campaigns = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public sendEmails(campaign: SmsCampaign): void {
    this.helperService.showLoadingMxpro360();
    this.smsCampaignsService
      .sendEmails(campaign)
      .then(() => {
        this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        this.loadAllCampaigns();
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
}
