import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DriverInvoiceItem } from 'src/app/entities/workspace/driver-invoice-item';

@Injectable({
  providedIn: 'root'
})
export class DriverInvoicesService {

  constructor(
    private http: HttpClient
  ) {

  }

  add(dataForm) {
    return this.http.post(environment.api.app + '/driver-invoices', dataForm).toPromise();
  }

  get() {
    return this.http.get<any>(environment.api.app + '/driver-invoices').toPromise();
  }

  getById(id: string) {
    return this.http.get<DriverInvoice>(environment.api.app + '/driver-invoices/' + id).toPromise();
  }

  patchEntity(id, dataForm) {
    return this.http.patch<any>(environment.api.app + '/driver-invoices/' + id, dataForm).toPromise();
  }

  addItems(dataForm, id) {
    return this.http.post(environment.api.app + '/driver-invoices/' + id + '/items', dataForm).toPromise();
  }

  getItems(id) {
    return this.http.get<Array<DriverInvoiceItem>>(environment.api.app + '/driver-invoices/' + id + '/items').toPromise();
  }


  patchEntityItem(id, dataForm) {
    return this.http.patch<any>(environment.api.app + '/driver-invoices/' + id + '/items', dataForm).toPromise();
  }
}
