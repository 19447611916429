<div class="form-container">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container">
                <div class="form-content">

                    <div class="text-center">
                        <img src="assets/img/logo_moverxpro360.png" alt="">
                        <h2 class="text-register">Register</h2>
                    </div>

                    <form class="text-left mt-4" #formSignin="ngForm" (ngSubmit)="sigin()" ngNativeValidate >
                        <div class="form">

                            <div id="name-field" class="field-wrapper input">
                                <i class="fas fa-user i-form"></i>
                                <input id="name" name="name" type="text" autocomplete="off" [(ngModel)]="dataForm.name" required value="" placeholder="Name" autofocus="autofocus">
                            </div>
                            <div id="phone-field" class="field-wrapper input">
                                <i class="fas fa-phone-alt i-form"></i>
                                <input id="phone" name="phone" onlyNumbers type="tel" minlength="10" maxlength="10" [(ngModel)]="dataForm.phone" required class="form-control" placeholder="Phone">
                            </div>
                            <div id="email-field" class="field-wrapper input">
                                <i class="fas fa-envelope i-form"></i>
                                <input id="email" name="email" type="email" [(ngModel)]="dataForm.email" required value="" placeholder="Email">
                            </div>
                            <div id="password-field" class="field-wrapper input mb-2">
                                <i class="fas fa-lock i-form"></i>
                                <input id="inputPassword" name="password" type="password" minlength="6" maxlength="20" autocomplete="new-password" [(ngModel)]="dataForm.password" required value="" placeholder="Password">
                                <button id="show_password" class="" type="button" (click)="showPassword()"> <span class="fa fa-eye icon password"></span> </button>
                            </div>
                            <div id="password-field" class="field-wrapper input mb-2">
                                <i class="fas fa-lock i-form"></i>
                                <input id="inputConfirmPassword" name="confirm-password" type="password" minlength="6" maxlength="20" autocomplete="new-password" [(ngModel)]="confirmPassword" required value="" placeholder="Confirm Password">
                                <button id="show_confirm_password" class="" type="button" (click)="showConfirmPassword()"> <span class="fa fa-eye icon confirm-password"></span> </button>
                            </div>
                            <div class="field-wrapper terms_condition">
                                <div class="n-chk new-checkbox checkbox-outline-primary">
                                    <label class="new-control new-checkbox checkbox-outline-warning">
                                      <input type="checkbox" class="new-control-input" required>
                                      <span class="new-control-indicator"></span><span>I agree to the <a href="javascript:void(0);"> <strong> Terms and Conditions </strong> </a></span>
                                    </label>
                                </div>
                            </div>

                            <div class="field-wrapper text-center mt-4">
                                <button type="submit" class="btn btn-warning btn-register" >Get Started</button>
                            </div>


                        </div>
                    </form>

                    <p class="signup-link text-center">
                        Already have an account?
                            <a routerLink="/login">Log in</a>
                        </p>

                    <p class="terms-conditions text-center">© 2023 All Rights Reserved
                        <a href="https://www.moverxpro360.com">Moverxpro360</a> is a product of <a href="https://moverxpro360.com" target="_blank">MoverXpro 360 LLC</a> <br>
                        <!-- <a href="javascript:void(0);">Cookie Preferences</a>, -->
                        <a href="https://moverxpro360.com/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://moverxpro360.com/term-and-conditions" target="_blank">Term and Conditions</a>
                    </p>


                </div>
            </div>
        </div>
    </div>
    <div class="form-image">
        <div class="l-image">
        </div>
    </div>
</div>
