import { File } from '../global/file';

export class CompanyCustomTheme {

    public id: string;
    public first_color: string;
    public second_color: string;
    public third_color: string;
    public cover_image: File;
    public back_cover_image: File;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.first_color = null;
        this.second_color = null;
        this.third_color = null;
        this.cover_image = new File();
        this.back_cover_image = new File();
    }

}
