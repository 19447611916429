import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneQueue } from 'src/app/entities/workspace/phone-queue';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneQueuesService {

  constructor(private http: HttpClient) {

  };

  getAll(): Promise<Array<PhoneQueue>> {
    return this.http.get<Array<PhoneQueue>>(environment.api.workspace + '/phone-queues').toPromise();
  }

  getAllCompany(): Promise<Array<PhoneQueue>> {
    return this.http.get<Array<PhoneQueue>>(environment.api.workspace + '/phone-queues',
    { params: { 'company-name': '1'} }).toPromise();
  }

  // getAllNums(): Promise<Array<PhoneNumber>> {
  //   return this.http.get<Array<PhoneNumber>>(environment.api.workspace + '/pbx-phone-lines-nums').toPromise();
  // };

  getById(id: string) {
    return this.http.get<PhoneQueue>(environment.api.workspace + '/phone-queues/' + id).toPromise();
  }

  save(dataForm: PhoneQueue): Promise<any> {
    if (dataForm.id === null) {
        return this.add(dataForm);
    }
    return this.edit(dataForm);
};

  add(dataForm: PhoneQueue) {
    return this.http.post(environment.api.workspace + '/phone-queues', dataForm).toPromise();
  };

  edit(dataForm:PhoneQueue){
    return this.http.put(environment.api.workspace + '/phone-queues/' + dataForm.id, dataForm).toPromise();

  };

  remove(id: string): Promise<Array<PhoneQueue>> {
    return this.http.delete<Array<PhoneQueue>>(environment.api.workspace + '/phone-queues/' + id).toPromise();
  };

}
