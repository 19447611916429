import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Company } from 'src/app/entities/workspace/company';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';

declare var jQuery;

@Component({
    selector: 'app-companies-form',
    templateUrl: './companies-form.component.html',
    styleUrls: ['./companies-form.component.scss']
})
export class CompaniesFormComponent {

    company: Company;

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    constructor(
        private authService: AuthService,
        private companiesService: CompaniesService,
        private helperService: HelperService
    ) {
        this.company = new Company();
    }

    /**
     * Guarda las companys
     */
    saveCompany(): void {

        // validamos que tenga el permiso para registrar compañias
        if(!this.authService.hasPermissionWorkspace('COMPANIES_CREATE')){
            return;
        }

        this.helperService.showLoadingMxpro360();
        this.companiesService
            .add(this.company)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/companies');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
