export class EmailUnsuscribe {
    public id: string;
    public email: string;
    public reason: string;
    public comments: string;
    public company_id: string;
    public created: number;

    constructor() {
        this.id = null;
        this.email = null;
        this.reason = null;
        this.comments = null;
        this.company_id = null;
        this.created = null;
    }
}
