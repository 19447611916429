export class CardHolder {
    public id: string;
    public name: string;
    public last_name: string;
    public email: string;
    public phone: string;
    public birth_date: string;

    constructor() {
        this.id = null;
        this.name = null;
        this.last_name = null;
        this.email = null;
        this.phone = null;
        this.birth_date = null;
    }
}
