
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { Credential } from 'src/app/entities/helpers/credential';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    dataForm: Credential;
    keepSession: boolean;
    token: string;
    publicIP: string;
    section: "LOGIN_FORM" | "TOKEN_FORM";

    constructor(
        public authService: AuthService,
        public router: Router,
        public helperService: HelperService,
        public googleAuthService: GoogleAuthService
    ) {
        this.dataForm = new Credential();
        this.keepSession = false;
        this.token = '';
        this.section = "LOGIN_FORM";
        this.publicIP = null;
    }


    ngOnInit(): void {

        this
            .authService
            .getPublicIp()
            .then((response: any) => {
                if (response.ip) {
                    this.publicIP = response.ip;
                }
            });

        // permite verificar si hay sesion o no..
        // esto para saltar el paso del login y llevar al usuario a la seccion donde selecciona el workspace con el que va a trabajar
        this.validateToken();
    }

    ngAfterViewInit() {

        // caragmos credenciales si los hay guardados
        this.loadSavedCredentials();

        // si no se ha cargado lo que se necesita 
        if (!this.googleAuthService.isLoaded()) {

            // lo ponemos a cargar
            this.googleAuthService.load();
        }

    }

    ngOnDestroy() {
        this.dataForm = null;
    }

    /**
     * Permite cargar informacion de las credenciales de accesso en el formulario de inicio de sesion
     */
    loadSavedCredentials() {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
            this.dataForm = auth;
        }
    }

    /**
     * Permite aplicar comportamiento a las cajas o imputs de texto
     */
    runBehaviorofCodeFields() {
        let that = this;
        const inputs: any = document.querySelectorAll('#otp > *[id]');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('keydown', function (event) {
                if (event.key === "Enter") {
                    that.verifyCodeEntered();
                    return;
                }
                if (event.key === "Backspace") {
                    inputs[i].value = '';
                    if (i !== 0) inputs[i - 1].focus();
                }
                else {
                    if (i === inputs.length - 1 && inputs[i].value !== '') {
                        inputs[i].value = event.key;
                        event.preventDefault();
                    }
                    else if (event.keyCode > 47 && event.keyCode < 58) {
                        inputs[i].value = event.key;
                        if (i !== inputs.length - 1) inputs[i + 1].focus();
                        event.preventDefault();
                    } else if (event.keyCode > 95 && event.keyCode < 106) {
                        inputs[i].value = event.key;
                        if (i !== inputs.length - 1) inputs[i + 1].focus();
                        event.preventDefault();
                    } else if (event.keyCode == 17 || event.keyCode == 86) {
                      
                    } else{
                        inputs[i].value = '';
                        event.preventDefault();
                    }
                }
                setTimeout(() => {
                    if (inputs[0].value.length > 1) {
                        let str = inputs[0].value;
                        for (let j = 0; j < inputs.length; j++) {
                            inputs[j].value = str[j];
                        }
                    }
                }, 100);
            });
        }
    }

    clearInputsCode() {
        const inputs: any = document.querySelectorAll('#otp > *[id]');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].value = '';
        }
    }

    /**
     * Permite a un usuario que haya ingersado usuario y contraseña inciar el proceso de inicio de sesion
     */
    onLogin() {

        // Genera el nuevo token
        this.helperService.showLoadingMxpro360();
        this.clearInputsCode();
        this.authService
            .login(this.dataForm)
            .then(() => {
                if (this.keepSession) {
                    localStorage.setItem('auth', JSON.stringify(this.dataForm));
                }
                this.section = "TOKEN_FORM";
                setTimeout(() => {
                    this.runBehaviorofCodeFields();
                    jQuery('#first').focus();
                }, 100);
            }, (error) => {
                console.info("error: ", error);
                if (error.hasOwnProperty("error")) {
                    swal({
                        type: 'error',
                        title: 'Oops...',
                        text: error.error.message
                    });
                }

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    /**
     * Permite verificar el codigo ingresado
     * 
     * @returns 
     */
    verifyCodeEntered() {
        this.token = '';
        const inputs: any = document.querySelectorAll('#otp > *[id]');
        let that = this;
        jQuery('#otp').children('input').each(function (key) {
            if (this.value === '') {
                inputs[key].focus();
                return false;
            }
            that.token = that.token + this.value; // "this" is the current element in the loop

        });

        if (this.token.length != 6) {
            return;
        }
        // Genera el nuevo token
        this.helperService.showLoadingMxpro360();
        this.authService
            .verifyToken(this.token, this.publicIP)
            .then(() => {
                this.router.navigateByUrl('/my-profile/workspaces');
            }, (error) => {
                let errorMessage = error.message;
                // valida la existencia de un mensaje de error personalizado
                if (error.hasOwnProperty('error')) {
                    if (error.error.hasOwnProperty('message')) {
                        errorMessage = error.error.message;
                    }
                }
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: errorMessage
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite a nuestro usuario iniciar session con google
     */
    loginWithGoogle() {
        this.googleAuthService
            .authenticate()
            .then(() => {
                // cargamos la instancia
                const googleCurrentUser = this.googleAuthService.googleAuth.currentUser.get();

                // colectamos datos del usuario y los agregamos al dato que se enviará al web service
                // const BasicProfile = googleCurrentUser.getBasicProfile();
                // const email = BasicProfile.getEmail();
                const AuthResponse = googleCurrentUser.getAuthResponse();

                this.authService
                    .loginGoogle(AuthResponse['id_token'])
                    .then(() => {
                        if (this.keepSession) {
                            localStorage.setItem('auth', JSON.stringify(this.dataForm));
                        }
                        this.router.navigateByUrl('/my-profile/workspaces');
                    })
                    .catch((error) => {
                        swal({
                            type: 'error',
                            title: 'Oops...',
                            text: error.error.message
                        });
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            });
    }

    /**
     * permite verificar si tenemos una sesion activa en el sistema.. 
     * ne caso de ser asi.. 
     * redirecciona a la seccion de workspaces
     */
    validateToken() {

        if (this.authService.session == null) {
            return;
        }

        if (this.authService.session.token == null) {
            return;
        }

        this.authService
            .validateToken()
            .then((response) => {
                this.router.navigateByUrl('/my-profile/workspaces');
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });;
    }

    /**
     * Permite mostrar la contrasaseña que 
     * ha ingresado el usuario en el campo contraseña
     * 
     */
    showPassword() {
        jQuery("#show_password svg").css('display', 'none');
        if (jQuery("#inputPassword").attr('type') == "password") {
            jQuery("#inputPassword").prop('type', 'text');
            jQuery('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
        } else {
            jQuery("#inputPassword").prop('type', 'password');
            jQuery('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
        }
    }
}
