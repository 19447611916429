<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing">
            <h3>
                Automation
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Automation
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-new" appCompanyRouterLink="/automations/add">
                    <i class="fas fa-plus-square"></i> &nbsp; New Automation 
                </a>
            </div>
        </div> 

    </div>
    <!-- header -->



    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Template</th>
                                <!-- <th>Estimate #</th> -->
                                <th>From</th>
                                <th>To</th>
                                <th>Time</th>
                                <th class="text-center">Status</th>
                                <th>Trigger Event</th>
                                <th>Category</th>
                                <th>Filter</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let automation of automations | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td>                                
                                    <div class="priority-level-urgent" >
                                      
                                        <span>{{automation.action | filterReplace}}</span>
                                    </div>
                                </td>

                                <td>
                                    <ng-container *ngFor="let emailTemplate of emailTemplates">
                                        <ng-container *ngIf="emailTemplate.id == automation.email_template_id">
                                            {{emailTemplate.subject | filterReplace}}
                                        </ng-container>
                                    </ng-container>

                                    
                                    <ng-container *ngFor="let smsTemplate of smsTemplates">
                                        <ng-container *ngIf="smsTemplate.id == automation.sms_template_id">
                                            {{smsTemplate.name | filterReplace}}
                                        </ng-container>
                                    </ng-container>
                                    
                                    <ng-container *ngFor="let ticketsType of ticketsTypes">
                                        <ng-container *ngIf="ticketsType.id == automation.ticket.ticket_type_id">
                                            {{ticketsType.name | filterReplace}}
                                        </ng-container>
                                    </ng-container>


                                </td>
                                <!-- <td>{{automation.estimate_document.code}}</td> -->
                                <td>{{automation.from | filterReplace}} </td>
                                <td>
                                    {{automation.to | filterReplace}} 
                                </td>
                                <td>{{automation.time_set | filterReplace}}</td>
                                <td class="text-center">
                                    {{automation.status}}
                                </td>
                              
                              
                                <td >
                                    {{automation.trigger_event | filterReplace}}
                                </td>
                                <td>{{automation.category | filterReplace}}</td>
                                <!-- <td>{{automation.created | dateTime}}</td> -->
                                <td>
                                    <!-- {{automation.filter.price_range}} <br>
                                    {{automation.filter.price_range_start}} <br>
                                    {{automation.filter.price_range_end}} <br> -->
                                </td>
                                <td>
                                    <!-- <a class="btn btn-info">
                                        <i class="fa-solid fa-eye"></i>
                                    </a> -->
                                    <a appCompanyRouterLink="/automations/{{automation.id}}/edit" class="btn btn-success button-space">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="deleteAutomation(automation.id)" class="btn btn-danger button-space">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>