export class PackingMaterial {
    public id: string;
    public name: string;
    public price: string;
    public service_type: string[]; //'LONG_DISTANCE' | 'LOCAL_DISTANCE' | 'AUTO_TRANSPORT' | 'FREIGHT_TRANSPORT'
    public created: string;

    constructor() {
        this.id = null;
        this.name = null;
        this.price = null;
        this.service_type = [];
        this.created = null;
    }
}

