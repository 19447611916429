<div class="layout-spacing layout-top-spacing">
    <form class="form" #employeesForm="ngForm" (ngSubmit)="save()" ngNativeValidate autocomplete="off">

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    Load Leads By Excel
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Load Leads By Excel</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    
                </div>
            </div>

        </div>
        <!-- header -->

        <div class="row">
            <div class="col-xl-8 col-12">
       
                <div class="widget-content widget-content-area">
                    <div class="form-group mb-4 select-access-companies-view-employee">
                        <div class="block-upload-file">
                            <div class="icon-excel">
                                <svg clip-rule="evenodd" fill-rule="evenodd" height="2048"
                                    image-rendering="optimizeQuality" shape-rendering="geometricPrecision"
                                    text-rendering="geometricPrecision" viewBox="0 0 2048 2048"
                                    width="2048" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 0h2048v2048h-2048z" fill="none"/>
                                    <path d="m255.999 255.999h1536v1536h-1536z" fill="none"/>
                                    <path d="m1303.19 255.999 328.759 329.158h-269.982c-32.328 0-58.7764-26.4484-58.7764-58.7764v-270.382z" fill="#80cbc4"/>
                                    <path d="m454.851 255.999h848.395v268.747c0 33.228 27.1843 60.4111 60.4111 60.4111h268.344v814.843c0 22.0004-17.9988 39.9992-39.9992 39.9992h-1137.15c-22.0004 0-39.9992-17.9988-39.9992-39.9992v-1104c0-22.0004 17.9988-39.9992 39.9992-39.9992z" fill="#b2dfdb"/><g fill="#00897b">
                                    <path d="m671.999 755.343h736.001v64.001h-736.001z" fill-rule="nonzero"/>
                                    <path d="m671.999 979.338h736.001v64.002h-736.001z" fill-rule="nonzero"/>
                                    <path d="m337.396 1218.19h1373.21c22.3748 0 42.7347 9.23387 57.5103 24.1075 14.7378 14.8358 23.8866 35.2382 23.886657.6189v394.353c0 22.3807-9.15001 42.7819-23.8866 57.6178-14.7768 14.8736-35.1366 24.1087-57.5103 24.1087h-1373.21c-22.3725 0-42.7347-9.23505-57.5115-24.1087-14.7366-14.8335-23.8855-35.2347-23.8855-57.6178v-394.353c0-22.3819 9.14883-42.7831 23.8855-57.6189 14.7744-14.8736 35.1366-24.1075 57.5115-24.1075z"/></g>
                                    <path d="m371.417 1676.06 122.333-186.75-110.832-171.167h84.4158l71.8335 115 70.2508-115h83.7497l-111.333 173.834 122.333 184.083h-87.1666l-79.3335-123.75-79.5827 123.75h-86.667zm371.834 0v-354.999h72.2493v294.666h179.667v60.3331h-251.916zm285.167-116.417 70.3323-6.91654c4.16693 23.6669 12.75 40.9996 25.7504 52.0831 12.9165 10.9996 30.3331 16.5839 52.3335 16.5839 23.25 0 40.8331-4.91693 52.5827-14.8335 11.8335-9.83387 17.7508-21.3331 17.7508-34.5 0-8.5004-2.5004-15.6673-7.50001-21.5835-4.91693-6-13.5839-11.1673-26.0008-15.4996-8.41654-3-27.75-8.16733-57.8327-15.6673-38.7496-9.58347-65.9162-21.4169-81.4997-35.4166-22.0004-19.6666-33-43.6666-33-72.0001 0-18.2504 5.16615-35.25 15.4996-51.1666 10.3335-15.8339 25.2496-27.9166 44.667-36.2504 19.5-8.25001 42.9166-12.4158 70.4162-12.4158 45 0 78.7501 9.83268 101.5 29.4992 22.6666 19.7504 34.5827 46.0004 35.7496 78.9166l-72.2504 3.16654c-3.08268-18.4158-9.75001-31.6666-19.9158-39.6662-10.1669-8.08347-25.4173-12.0839-45.75-12.0839-21 0-37.5 4.25079-49.3335 12.9177-7.66654 5.49922-11.5004 12.9165-11.5004 22.1658 0 8.5004 3.58347 15.75 10.7504 21.75 9.08268 7.66654 31.2496 15.6673 66.417 23.9173 35.1662 8.33268 61.1658 16.9158 77.9989 25.7492 16.8343 8.91733 30 21 39.5835 36.417 9.50079 15.4169 14.25 34.4173 14.25 57 0 20.5004-5.66575 39.75-17.0835 57.5835-11.4165 17.9162-27.4996 31.2496-48.3331 39.9166-20.8335 8.74961-46.8331 13.0831-77.9162 13.0831-45.2504 0-79.9997-10.4161-104.25-31.3335-24.2504-20.9162-38.7496-51.4158-43.4162-91.4162zm315.416 116.417 122.333-186.75-110.832-171.167h84.4158l71.8335 115 70.2508-115h83.7497l-111.333 173.834 122.333 184.083h-87.1666l-79.3335-123.75-79.5827 123.75h-86.667z" fill="#fff" fill-rule="nonzero"/>
                                </svg>
                            </div>
                            <div class="txt-leads-file">Leads File</div>
                            <div>File Supported: .xlsx (Max 1 File)</div>

                            <div *ngIf="fileName != ''" class="box-file-name">                                
                                <i class="fa-solid fa-file icon-file"></i>
                                <div class="file-name">
                                    <span>{{fileName}}</span>
                                </div>
                                <button class="btn-remove-file" (click)="fileName=''">
                                    <i class="fa-solid fa-circle-xmark"></i>
                                </button>
                            </div>

                            <a (click)="openUploadModal()" class="btn btn-success btn-upload-file">
                                Upload File                             
                            </a>
                        </div>
                    </div>
                    <div class="form-group mb-4 mt-5 select-access-companies-view-employee">
                        <label class="control-label">Companies</label>
                        <select [(ngModel)]="companiesLeads" name="wd_employees_id"
                            class="form-control tagging" multiple="multiple">
                            <option *ngFor="let company of companies" value="{{company.id}}">
                                {{company.name}}
                            </option>
                        </select>
                        <small class="control-label">Select the companies that will receive the leads</small>
                    </div>

                    <div class="btn-load-data">
                        <button class="btn btn-main-action" type="submit">
                            <i class="fa-solid fa-file-import"></i>
                            Load Data
                        </button>
                    </div>
                    
                </div>
          
            </div>
        </div>

    </form>
</div>