import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';
import { TariffAdditionalConcept } from 'src/app/entities/workspace/tariff-additional-concept';
import { TariffAdditionalConceptsService } from 'src/app/services/workspaces/tariff-additional-concepts.service';


declare const jQuery;
@Component({
    selector: 'app-tariff-additional-concepts-form',
    templateUrl: './tariff-additional-concepts-form.component.html',
    styleUrls: ['./tariff-additional-concepts-form.component.scss']
})
export class TariffAdditionalConceptsFormComponent implements OnInit {

    dataForm: TariffAdditionalConcept;
    constantsMessages = ConstantsMessages;

    
    constructor(
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private tariffAdditionalConceptsService: TariffAdditionalConceptsService,
    ) {
        this.dataForm = new TariffAdditionalConcept();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    load(id: string): void {
        this.tariffAdditionalConceptsService.getById(id).then((response) => {
            this.dataForm = response;
        });
    }

    // deschulea el by_cubic_feet
    changeByPercentage(checkValue: boolean) {        
        if (checkValue) {
            this.dataForm.by_cubic_feet = false;
        }
    }

    save() {

        console.log("dataform: ", this.dataForm);
        console.log("dataform 2: ", Object.assign({}, this.dataForm))
        
        if(this.dataForm.behavior == 'FULL_PACKING_SERVICE'){
            this.dataForm.by_cubic_feet = false;
            if (this.dataForm.full_packing_mode === 'CUSTOM') {
                this.dataForm.type = "FIXED";
                this.dataForm.stop_type = 'PICKUP';
                this.dataForm.by_percentage = false;
            }else{
                this.dataForm.value = null;
            }
        }

        if (this.dataForm.behavior == 'DISCOUNT_FULL_PACKING_SERVICE') {
            this.dataForm.value = null;
            this.dataForm.by_cubic_feet = false;
        }

        if (this.dataForm.behavior == 'CLOSED_QUICK_QUOTE_QUESTION' || this.dataForm.behavior == 'OPEN_QUICK_QUOTE_QUESTION') {
            this.dataForm.display_on_long_distance = true;
        }

        // if (this.dataForm.behavior == 'DISCOUNT') {
        //   if (Number(this.dataForm.value) <= 0) {
        //     swal({
        //       title: 'Warning!!',
        //       text: 'The percentage value should be greater than zero',
        //       icon: 'warning'
        //     });
        //     return;
        //   }
        // }
        this.helperService.showLoadingMxpro360();
        this.tariffAdditionalConceptsService.save(this.dataForm).then(() => {
            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            this.helperService.goToWorkspaceRouterLink('/tariff-additional-concepts');
        })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
            
    }
}
