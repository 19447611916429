import { Address } from './../../../entities/global/address';
import { CarrierCoverageView } from './../../../entities/workspace/carrier-coverage-view';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { HelperService } from 'src/app/services/helper.service';
import { Period } from 'src/app/entities/workspace/period';
import { Volume } from 'src/app/entities/workspace/volume';
import { ZoneState } from 'src/app/entities/workspace/zone-state';
import { ZoneStatesService } from 'src/app/services/workspaces/zone-states.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';


declare const jQuery;
declare const google;

@Component({
  selector: 'app-carriers-coverage-map',
  templateUrl: './carriers-coverage-map.component.html',
  styleUrls: ['./carriers-coverage-map.component.scss']
})
export class CarriersCoverageMapComponent implements OnInit {

  @ViewChild('carrierInformation') carrierInformation: ElementRef;

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;
  carrier: any;
  dataForm: ZoneState;
  zipcode: string;
  zipcodes;
  routesRates;
  /*
  Arreglo usado para comparar las routes rates entre dos zonas
  */
  routesRatesToCompare;
  periodId: string;

  coordinates;

  volumes: Array<Volume>;


  /*
  Variable que contiene el zipcode a buscar en la vista
  */
  searchZipCode: string;

  incrementLat: number;

  /*
  Variable que contiene el mapa
  */
  private map;

  periods: Array<Period>;
  public carrierCoverageList: Array<CarrierCoverageView>;
  public carrierCoverageSelected: string;
  private states: any;
  public coverageStatePoligon: {
    lat: number,
    lng: number,
  }[];

  @ViewChild('mapView')
  mapView: ElementRef;
  @ViewChild('mapView')
  entityForm: ElementRef;
  public coverageType: string;
  private address: Address;
  constructor(
    private carrierService: CarriersService,
    private helperService: HelperService,
    private router: Router,
    private zonesStatesService: ZoneStatesService,
    private authService: AuthService
  ) {
    this.periods = [];
    this.routesRates = [];
    this.routesRatesToCompare = [];
    this.searchZipCode = '';
    this.volumes = [];
    this.zipcode = '';
    this.zipcodes = [];
    this.carrierCoverageList = [];
    this.carrierCoverageSelected = null;
    this.states = [];
    this.coverageStatePoligon = [];
    this.coverageType = 'PICKUP';
    this.address = null;
    this.incrementLat = 1;
    this.carrier = null;
    
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadData();
    this.setDataDrawShapes();
  }

  ngOnDestroy() {
    // elimina el dato de estados
    delete this.states;
  }

  // consulta carriers coverages que intecepten la direccion seleccionada
  getAddress(address) {
    this.helperService.showLoadingMxpro360();
    this.address = address;
    const data = {
      address: JSON.stringify(address),
      coverageType: this.coverageType
    };
    this.carrierService.getCarrierCoveragesMap(data)
      .then((responseCarriersCoverages) => {
        this.carrierCoverageList = responseCarriersCoverages;
        for (let cCoverage of this.carrierCoverageList) {
          cCoverage.carrier.logo = cCoverage.carrier.logo ? environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + cCoverage.carrier.logo : '/assets/img/200x200.jpg';
        }
        this.setDataDrawShapes();
      }).finally(() => {
        this.helperService.hideLoadingMxpro360();
      })
  }

  // en caso de haber una direccion al cambiar el select de tipo envia request getAddress
  validateAddresToSendRequest() {
    if (this.address) {
      this.getAddress(this.address);
    }
  }

  // carga datos de base de poligonos de estados
  loadData() {
    this.helperService.showLoadingMxpro360();
    this.carrierService.getPoligonsStates()
      .then(states => {
        this.states = states;
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  // obtiene los datos de coordenadas de un estado especifico
  selectDataPolygonFromState(stateName: string) {
    this.states.forEach((state) => {
      // asigna coordenadas de poiligono cuando encuentra el estado correspondiente
      if (state.name === stateName) {
        this.coverageStatePoligon = state.polygon;
        return;
      }
    });
  }

  // inizcializa las mapa zonas y recibe un id de zona para ser resaltada
  setDataDrawShapes(carrierCoverageId?: string) {
    this.initMap();
    this.drawShapes(carrierCoverageId);
  }

  // inicializa mapa
  initMap(): void {

    const THIS = this;

    this.map = new google.maps.Map(
      this.mapView.nativeElement as HTMLElement,
      {
        zoom: 4,
        center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: false,
        zoomControl: true
      });

    const input = document.getElementById('pac-input');
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
  }

  // arma un marcador
  getMarker(bounds, name) {
    const THIS = this;
    let lati = bounds.getCenter().lat();
    const lngi = bounds.getCenter().lng();
    lati = lati + THIS.incrementLat;

    const marker = new google.maps.Marker({
      position: { lat: lati, lng: lngi },
      map: THIS.map,
      label: {
        text: name,
        fontSize: '15px',
        fontWeight: 'bold',
        fontFamily: 'custom-label'
      },
      icon: '-'
    });
    THIS.incrementLat = THIS.incrementLat + 1;
    return marker;
  }

  // resalta el color de una zona
  highlightZone(carrierCoverage) {
    this.carrier = carrierCoverage.carrier;
    jQuery(this.carrierInformation.nativeElement).modal('show');
    // envia un id para ser resaltada una zona
    this.setDataDrawShapes(carrierCoverage.id);
  }

  /**
   * Metodo que dibuja las figuras de radios o estados
   */
  async drawShapes(carrierCoverageId: string) {
    const THIS = this;
    // Se cargan todas las figuras desde db
    let count = 0;
    let opacity = 0;
    this.carrierCoverageList.forEach(carrierCoverage => {
      count++
      if (count > this.carrierCoverageList.length - 1) {
        opacity = 0.645;
      }

      // Se crea un bounds para poder hayar el centro de la fugura y pintar los markers en esa posicion
      const bounds = new google.maps.LatLngBounds();
      const strokecolor = carrierCoverageId == carrierCoverage.id ? '#0ff' : 'rgba(255, 0, 0, ' + opacity + ')';
      // pintar circunferencia
      if (carrierCoverage.geospatial) {

        bounds.extend({
          lat: Number(carrierCoverage.geospatial.coordinates[1]),
          lng: Number(carrierCoverage.geospatial.coordinates[0])
        });

        const marker = this.getMarker(bounds, carrierCoverage.carrier.name);
        // Se dibujan las figuras
        const circle = new google.maps.Circle({
          strokeColor: strokecolor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: strokecolor,
          fillOpacity: 0.35,
          marker: marker,
          center: {
            lat: Number(carrierCoverage.geospatial.coordinates[1]),
            lng: Number(carrierCoverage.geospatial.coordinates[0])
          },
          radius: carrierCoverage.radius / 0.000621371
        });        
        circle.setMap(this.map);
      } else {

        // pintar poligono con la figura del estimate_document
        this.selectDataPolygonFromState(carrierCoverage.state);

        // cerramos el poligono agregando el punto inicial tambien al final
        this.coverageStatePoligon.push(this.coverageStatePoligon[0]);

        // agregamos las coordenadas al bounds para poder obtener el centro y poner el mark
        for (let i = 0; i < this.coverageStatePoligon.length; i++) {
          bounds.extend(this.coverageStatePoligon[i]);
        }

        // genera la marca para mostrar el carrier en la figura
        const marker = this.getMarker(bounds, carrierCoverage.carrier.name);

        // dibuja la figura con la marca
        const polygon = new google.maps.Polygon({
          paths: this.coverageStatePoligon,
          strokeColor: strokecolor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: strokecolor,
          fillOpacity: 0.35,
        });
        
        polygon.setMap(this.map);
      }

    });
    this.incrementLat = 1;
  }

}
