<div class="layout-spacing layout-top-spacing">

  <div class="refresh col-lg-2">
    <label class="title">Auto Refresh</label>
      <select class="refreshTime form-select form-select-sm" id="refreshTime" name="refreshTime" [(ngModel)]="refreshTime">
          <option  value="15000"> 15 </option>
          <option value="30000"> 30 </option>
          <option value="60000"> 60 </option>
          <option> pause </option>
          <i></i>
      </select>

</div>

  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
      <h3>
        Active Agents
      </h3>
      <nav class="breadcrumb-one" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a appWorkspaceRouterLink="/dashboard">
              <i class="fas fa-home"></i>
              Dashboard
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Active-agents
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="widget-content widget-content-area">

        <div class="table-responsive cdr-table">
          <table class="table table-bordered rounded table-hover mb-4">
            <thead>
              <tr>
                <th>Agent Name</th>
                <th>Status</th>
                <th>Duration</th>
                <th>Number Line</th>
                <th>Total Calls Today</th>
                <th>Total Inbound Calls Today</th>
                <th>Total Outbound Calls Today</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let line of phoneLines; let i = index" class="call-from rounded" [ngClass]="{'call-on': phoneLineActive[i],'call-on-incall':phoneLineActiveCalls[i].length === 1,'call-on-incall2':phoneLineActiveCalls[i].length === 2 }">
                <td class="line" >
                  <label>{{line.name}}</label>
                </td>
                <td *ngIf="phoneLineActive[i]">
                  <label>on line</label>
                </td>
                <td  *ngIf="!phoneLineActive[i]">
                  <label>off Line</label>
                </td>
                <td>
                  <label *ngIf="!line.second">00:00</label>
                  <label *ngIf="line.second">{{line.minutes.toString().padStart(2, '0')}}:{{line.second.toString().padStart(2, '0')}}</label>
                  <br>
                  <label *ngIf="line.second2">{{line.minutes2.toString().padStart(2, '0')}}:{{line.second2.toString().padStart(2, '0')}}</label>

                </td>
                <td>
                  <label>{{line.phone_number}}</label>
                </td>
                <td>
                  <label *ngIf="!line.totalCall">0</label>
                  <label *ngIf="line.totalCall">{{line.totalCall}}</label>
                </td>
                <td>
                  <label *ngIf="!line.inbound">0</label>
                  <label *ngIf="line.inbound">{{line.inbound}}</label>

                </td>
                <td>
                  <label *ngIf="!line.outbound">0</label>
                  <label *ngIf="line.outbound">{{line.outbound}}</label>

                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

