import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierDocument } from 'src/app/entities/workspace/carrier-document';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarriersDocumentsService {
  
    constructor(private http: HttpClient) { }

    getAll(id): Promise<Array<CarrierDocument>> {
        return this.http.get<Array<CarrierDocument>>(environment.api.workspace + '/carriers/' + id + '/documents').toPromise();
    }

    getById(id: string, subId: string) {
        return this.http.get<CarrierDocument>(environment.api.workspace + '/carriers/' + id + '/documents/' + subId).toPromise();
    }

    save(dataForm: any, id: string, subId: string, documentType:any): Promise<any> {
        if (dataForm.id === null) {
            dataForm.document_type = documentType;
            return this.add(dataForm, id);
        }
        return this.edit(dataForm, id, subId );
    }

    add(dataForm: any, id: string) {
        return this.http.post(environment.api.workspace + '/carriers/' + id + '/documents', dataForm).toPromise();
    }

    edit(dataForm: CarrierDocument, id: string, subId: string) {
        return this.http.put(environment.api.workspace + '/carriers/' + id + '/documents/' + subId, dataForm).toPromise();
    }

    remove(id: string, subId: string) {
        return this.http.delete(environment.api.workspace + '/carriers/' + id + '/documents/' + subId).toPromise();
    }
}
