export class TariffVersion {
    public id: string;
    public name: string;
    public is_active: boolean;
    public measure_unit: 'CUBIC_FEET' | 'POUNDS';
    public alterned: boolean;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.is_active = true;
        this.measure_unit = null;
        this.alterned = false;
        this.created = null;
        this.updated = null;
    }
}
