<!-- <div class="widget widget-table-two">

    <div class="widget-heading">
        <h5 class="text-danger">Last 10 Jobs Viewed</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-danger">
                            <div class="th-content text-light">Job Code</div>
                        </th>
                        <th class="bg-danger">
                            <div class="th-content th-heading text-light">Created</div>
                        </th>
                        <th class="bg-danger text-center">
                            <div class="th-content text-light">Link</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let theLastTenTobsTheyViewedWithaLink of theLastTenTobsTheyViewedWithaLinks">
                        <td>
                            <div class="td-content customer-name text-danger">{{theLastTenTobsTheyViewedWithaLink.job}}</div>
                        </td>
                        <td>
                            <div class="td-content product-brand">{{theLastTenTobsTheyViewedWithaLink.created | date}}
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="td-content badge badge-danger text-light cursor-pointer"
                              >Go to</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div> -->




<div class="widget widget-activity-five">

    <div class="widget-heading">
        <h5 class="">Last 10 Jobs Viewed</h5>
    </div>

    <div class="widget-content">

        <div class="w-shadow-top"></div>

        <div class="mt-container mx-auto">

            
            <div class="timeline-line" *ngFor="let theLastTenTobsTheyViewedWithaLink of theLastTenTobsTheyViewedWithaLinks">
                
                <div class="item-timeline timeline-new">
                    <div class="t-dot">
                        <div class="t-secondary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                        </div>
                    </div>
                    <div class="t-content t-job-info">
                        <div class="t-uppercontent">
                            <h5>{{theLastTenTobsTheyViewedWithaLink.job}}</h5>
                        </div>
                        <p>{{theLastTenTobsTheyViewedWithaLink.customer.name}}</p>
                    </div>
                    <div class="t-date d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                        <p>{{theLastTenTobsTheyViewedWithaLink.created | date}}</p>
                    </div>
                </div>

                                  
            </div>    

        </div>

        <div class="w-shadow-bottom"></div>
    </div>
</div>
