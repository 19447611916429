import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierCandidate } from 'src/app/entities/workspace/carrier-candidate';
import { CarrierCandidateView } from 'src/app/entities/workspace/carrier-candidate-view';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarrierCandidatesService {

    constructor(
        private http: HttpClient
    ) {

    }

    /**
     * Metodo que obtiene los balances por id de jobs
     *
     * @param id Id del job
     */
    getCarrierCandidate(idJob: string): Promise<CarrierCandidate> {
        return this.http.get<CarrierCandidate>(environment.api.company + '/candidate-approved/' + idJob).toPromise();
    }

    /**
     * Permite obtener todos los carriers postulados para hacer un job a traves del id del board job 
     * 
     * @param board_job_id 
     * @returns 
     */
    getAllByBoardJobId(board_job_id: string) {
        return this.http.get<Array<CarrierCandidateView>>(environment.api.company + '/board-jobs/' + board_job_id + '/carrier-candidates').toPromise();
    }
}
