import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  get(): Promise<WorkspaceSetting> {
    return this.http.get<WorkspaceSetting>(environment.api.workspace + '/settings').toPromise();
  }

  save(setting: WorkspaceSetting) {
    return this.http.post(environment.api.workspace + '/settings', setting).toPromise();
  }
}
