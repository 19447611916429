import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { JobStorage } from 'src/app/entities/workspace/job-storage';
import { JobStorageExternal } from 'src/app/entities/workspace/job-storage-external';
import { JobStorageInternal } from 'src/app/entities/workspace/job-storage-internal';
import { JobStorageService } from 'src/app/services/companies/job-storage.service';
import { StorageCompaniesService } from 'src/app/services/companies/storage-companies.service';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { StorageNotes } from 'src/app/entities/companies/storage-notes';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { Warehouse } from 'src/app/entities/workspace/warehouse';
import { WarehousesService } from 'src/app/services/workspaces/warehouses.service';
import { Address } from 'src/app/entities/global/address';

declare const jQuery: any;
declare const swal: any;

@Component({
    selector: 'app-estimates-storage',
    templateUrl: './estimates-storage.component.html',
    styleUrls: ['./estimates-storage.component.scss']
})
export class EstimatesStorageComponent implements OnInit, AfterViewInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    @ViewChild('modalStorage') modalStorage: ElementRef;
    private constantsMessages = ConstantsMessages;
    public customStorageAddress: Address;
    public divCompleteClass: 'overflowTrue' | 'overflowFalse';
    public estimate: Estimate;
    public job: JobStorage;
    public jobs: Array<JobStorage>;
    public jobStorageId: string;
    public rowStorageCompanies: Array<StorageCompany>;
    public rowWarehouse: Array<Warehouse>;
    public showCustom: boolean;
    public storageCompany: StorageCompany;
    public storageNote: StorageNotes;
    public storageNotes: Array<StorageNotes>;

    constructor(
        private currentRoute: ActivatedRoute,
        private estimatesService: EstimatesService,
        private helperService: HelperService,
        private jobStorageService: JobStorageService,
        private storageCompaniesService: StorageCompaniesService,
        private warehousesService: WarehousesService,
        public tariffVersionFactory: TariffVersionService,
    ) {
        this.customStorageAddress = new Address();
        this.divCompleteClass = 'overflowTrue';
        this.estimate = new Estimate();
        this.job = new JobStorage();
        this.jobs = [];
        this.jobStorageId = '';
        this.rowStorageCompanies = [];
        this.rowWarehouse = [];
        this.showCustom = false
        this.storageCompany = new StorageCompany();
        this.storageNote = new StorageNotes();
        this.storageNotes = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {

        // verficamos si se esta editando
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.jobStorageId = params.id;
                this.loadAllData(params.id);
                this.loadStorageNotes(params.id);
            }
        });
        this.loadWarehouse();
    }

    loadWarehouse() {
        this.helperService.showLoadingMxpro360();
        this.warehousesService
            .getAll()
            .then((response) => {
                this.rowWarehouse = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadAllData(id) {
        this.loadEstimate(id);
        this.loadJobStorage(id);
        this.loadStorageCompanies(id);
    }

    loadStorageCompanies(jobId) {
        this.helperService.showLoadingMxpro360();
        this.storageCompaniesService
            .getByJobId(jobId)
            .then((response) => {
                this.rowStorageCompanies = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private loadEstimate(id: string) {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getById(id)
            .then((response) => {
                this.estimate = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadJobStorage(id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobStorageService
            .getById(id)
            .then((response) => {
                this.jobs = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveInfoJobStorage() {
        this.job.job_id = this.estimate.id;
        this.job.date_out = this.estimate.delivery.range_start;
        this.helperService.showLoadingMxpro360();
        this.jobStorageService
            .save(this.job, this.storageCompany)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.loadAllData(this.estimate.id);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                jQuery(this.modalStorage.nativeElement).modal('hide');
                this.helperService.hideLoadingMxpro360();
            });

    }

    changeDateIn(date) {
        this.job.date_in = date;
    }

    changeDateOut(date) {
        this.job.date_out = date;
    }

    changeDateNextBill(date) {
        this.job.next_bill_date = date;
    }

    changeDateFirstPaymentDate(date) {
        // this.job.job_storage_external.first_payment_date = date;
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.jobStorageService
                        .remove(id)
                        .then(() => {
                            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                            this.loadAllData(this.estimate.id);
                        })
                        .catch((error) => {
                            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                            console.error(error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    removeInternalVoultSticker(index, i) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.jobs[index].job_storage_internal.splice(i, 1);
                    this.job = this.jobs[index];
                    this.saveInfoJobStorage();
                }
            });
    }

    removeExternalVoultSticker(index, i) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.jobs[index].job_storage_external.splice(i, 1);
                    this.job = this.jobs[index];
                    this.saveInfoJobStorage();
                }
            });
    }

    addStorage() {
        this.job = new JobStorage();
        jQuery(this.modalStorage.nativeElement).modal('show');

    }

    editStorage(storage) {
        this.job = storage;
        jQuery(this.modalStorage.nativeElement).modal('show');

    }

    printVoultSticker(idEstiamte, idVoultSticker) {
        this.helperService.downloadReport('PRINT-VOULT-STICKER', idEstiamte, idVoultSticker);
    }

    setJobStorage(storageType) {
        if (storageType == 'INTERNAL') {
            if (this.job.job_storage_internal.length < 1) {
                this.job.job_storage_internal.push(new JobStorageInternal());
            }
        } else {
            if (this.job.job_storage_external.length < 1) {
                this.job.job_storage_external.push(new JobStorageExternal());
            }
        }
    }

    addNewInternal() {
        this.job.job_storage_internal.push(new JobStorageInternal());

    }

    addNewExternal() {
        this.job.job_storage_external.push(new JobStorageExternal());
    }

    private loadStorageNotes(id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobStorageService
            .getStorageNotesById(id)
            .then((response) => {
                this.storageNotes = response;                
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    togleScroll() {
        if (this.divCompleteClass == 'overflowTrue') {
            this.divCompleteClass = 'overflowFalse';
        } else {
            this.divCompleteClass = 'overflowTrue';
        }
    }

    addstorageNotes() {
        if (!this.storageNote.note) {
          return;
        }
        
        this.storageNote.job_id = this.estimate.id;
        this.storageNote.job_id = this.estimate.id;
        this.helperService.showLoadingMxpro360();
        this.jobStorageService
          .addStorageNotes(this.estimate.id, this.storageNote)
          .then((response) => {
            this.loadStorageNotes(this.estimate.id);
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
        this.storageNote = new StorageNotes();
      }


    setFrom(address) {
        // asigna direccion al storage company custom
        this.storageCompany.address = address;
    }

    checkNewCompany() {
        this.showCustom = (this.job.type == 'EXTERNAL' && this.job.storage_company_id == 'CUSTOM') || (this.job.type == 'INTERNAL' && this.job.warehouse_id == 'NEW');
        this.storageCompany = new StorageCompany();
    }
}
