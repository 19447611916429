import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobMaterialView } from 'src/app/entities/workspace/job-material-view';

@Injectable({
  providedIn: 'root'
})
export class JobMaterialsService {

  constructor(private http: HttpClient) { }

  getMaterialsByJobId(estimateId: string): Promise<Array<JobMaterialView>> {
    return this.http.get<Array<JobMaterialView>>(environment.api.app + '/jobs/' + estimateId + '/materials').toPromise();
  }
  
  save(dataForm, estimateId: string) {
    return this.http.post<Array<JobMaterialView>>(environment.api.app + '/jobs/' + estimateId + '/materials', dataForm).toPromise();
  }
}
