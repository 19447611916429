declare const moment: any;
export class BetweenDates {
    
    dateStart: number;
    dateEnd: number;

    constructor() {
        this.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
        this.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }
}
