export class PayrollSetting {
    id: string;
    employee_id: string;
    base_per_week: number;
    pay_mode: string;
    vs_commission: boolean;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.employee_id = null;
        this.base_per_week = null;
        this.pay_mode = null;
        this.vs_commission = false;
        this.created = null;
        this.updated = null;
    }
}