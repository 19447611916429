<div class="phone-system-container">

  <div class="layout-top-spacing pb-3">
    <div class="widget-content widget-content-area p2030" [ngClass]="{'widget-active-phone': activePhone}">
      <div class="row align-items-center">

        <div style="display: none;">
          <audio #audioElement controls autoplay></audio>
        </div>

        <div class="col-4">
          <div class="agent">
            <div class="agent-icon"
              [ngClass]="{'status-available': agentStatus == 'available', 'status-away': agentStatus == 'away', 'status-busy': agentStatus == 'busy', 'disconnected': agentStatus == ''}">
              <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 612 612" xml:space="preserve">
                <g>
                  <path d="M257.938,336.072c0,17.355-14.068,31.424-31.423,31.424c-17.354,0-31.422-14.068-31.422-31.424
                    c0-17.354,14.068-31.423,31.422-31.423C243.87,304.65,257.938,318.719,257.938,336.072z M385.485,304.65
                    c-17.354,0-31.423,14.068-31.423,31.424c0,17.354,14.069,31.422,31.423,31.422c17.354,0,31.424-14.068,31.424-31.422
                    C416.908,318.719,402.84,304.65,385.485,304.65z M612,318.557v59.719c0,29.982-24.305,54.287-54.288,54.287h-39.394
                    C479.283,540.947,379.604,606.412,306,606.412s-173.283-65.465-212.318-173.85H54.288C24.305,432.562,0,408.258,0,378.275v-59.719
                    c0-20.631,11.511-38.573,28.46-47.758c0.569-84.785,25.28-151.002,73.553-196.779C149.895,28.613,218.526,5.588,306,5.588
                    c87.474,0,156.105,23.025,203.987,68.43c48.272,45.777,72.982,111.995,73.553,196.779C600.489,279.983,612,297.925,612,318.557z
                    M497.099,336.271c0-13.969-0.715-27.094-1.771-39.812c-24.093-22.043-67.832-38.769-123.033-44.984
                    c7.248,8.15,13.509,18.871,17.306,32.983c-33.812-26.637-100.181-20.297-150.382-79.905c-2.878-3.329-5.367-6.51-7.519-9.417
                    c-0.025-0.035-0.053-0.062-0.078-0.096l0.006,0.002c-8.931-12.078-11.976-19.262-12.146-11.31
                    c-1.473,68.513-50.034,121.925-103.958,129.46c-0.341,7.535-0.62,15.143-0.62,23.08c0,28.959,4.729,55.352,12.769,79.137
                    c30.29,36.537,80.312,46.854,124.586,49.59c8.219-13.076,26.66-22.205,48.136-22.205c29.117,0,52.72,16.754,52.72,37.424
                    c0,20.668-23.604,37.422-52.72,37.422c-22.397,0-41.483-9.93-49.122-23.912c-30.943-1.799-64.959-7.074-95.276-21.391
                    C198.631,535.18,264.725,568.41,306,568.41C370.859,568.41,497.099,486.475,497.099,336.271z M550.855,264.269
                    C547.4,116.318,462.951,38.162,306,38.162S64.601,116.318,61.145,264.269h20.887c7.637-49.867,23.778-90.878,48.285-122.412
                    C169.37,91.609,228.478,66.13,306,66.13c77.522,0,136.63,25.479,175.685,75.727c24.505,31.533,40.647,72.545,48.284,122.412
                    H550.855L550.855,264.269z" />
                </g>
              </svg>
            </div>
            <div class="agent-info">
              <div class="employee-name">
                {{authService.workspaceSession.employee.name | titlecase}}
              </div>
              <div class="company-name">
                {{authService.workspaceSession.company.name | titlecase}}
              </div>
              <!-- <div class="agent-name">{{agentStatus | titlecase}}</div> -->
              <!-- <select class="agent-status form-control" id="agent_status" name="agent_status" [(ngModel)]="agentStatus"
                [disabled]="!activePhone">
                <option value="available">
                  Available
                </option>
                <option value="away">
                  Away
                </option>
                <option value="busy">
                  Busy
                </option>
                <option value="">
                  Disconnected
                </option>
              </select> -->
            </div>
          </div>
        </div>

        <div class="col-4 d-flex justify-content-center">
          <div class="tabs">
            <ul class="nav nav-tabs  mb-3 mt-3" id="simpletab" role="tablist">
              <li class="nav-item">
                <a class="nav-link nav-link-phone" id="home-tab" data-toggle="tab" href="#calling_section" role="tab"
                  aria-controls="calling-section" aria-selected="true">
                  <i class="fa-solid fa-phone"></i>
                  <span>Phone</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link nav-link-sms-text" id="contact-tab" data-toggle="tab" href="#text_sms_section"
                  role="tab" aria-controls="text-sms-section" aria-selected="false">
                  <i class="fa-solid fa-comment"></i>
                  <span>SMS Text</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-4 d-flex justify-content-end">

          <!-- Active Phone Line - Select -->
          <div class="active-phone-line">
            <div id="from_dropdown" class="dropdown">
              <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" [ngClass]="{'active-phone': agentNumber}"
                [disabled]="activePhone">
                <span class="label">
                  <i class="fa-solid fa-phone"></i>
                </span>
                <span *ngIf="!agentNumber">No number selected</span>
                <span *ngIf="agentNumber">{{extensionNumber}}</span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <ng-container *ngFor="let extensionsLine of extensions">
                  <a class="dropdown-item"
                    (click)="selectPhoneNumber(extensionsLine)">{{extensionsLine.extension}}</a>
                </ng-container>
              </div>
            </div>
          </div>

          <!-- Boton para encender línea telefónica -->
          <div id="phone_power_btn" class="phone-power-btn off">
            <!-- <label for="active_phone" id="phone_click" class="btn-power" (click)="connectionInfo(); switchActivatePhone(); $event.stopPropagation()"> -->
            <label for="active_phone" id="phone_click" class="btn-power"
              (click)="switchActivatePhone();$event.stopPropagation();">
              <i class="fa-solid fa-power-off"></i>
            </label>
            <input type="checkbox" id="active_phone" name="active_phone">
            <div class="phone-disabled" [hidden]="agentNumber"></div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="phone-system-section">

    <div class="tab-content" id="phone_calls_text_sms">

      <div class="d-flex">

        <!-- *** Call Report Section *** -->
        <div class="tab-pane fade" id="calling_section" role="tabpanel" aria-labelledby="calling-section">
          <app-oldphone [phone]="agentNumber"></app-oldphone>
        </div>

        <!-- *** Text SMS Section *** -->
        <div class="tab-pane fade" id="text_sms_section" role="tabpanel" aria-labelledby="text-sms-section">
          <app-oldsms (isReady)="isReady()"></app-oldsms>
        </div>

        <!-- *** Phone Section *** -->
        <div class="phone-section open-phone" id="phone_section">

          <div class="phone-content" id="phone_content">

            <!-- Call Status Indicator -->
            <div class="icon-collapse-phone" (click)="toggleCollapsePhone()">
              <i class="fa-solid fa-down-left-and-up-right-to-center icon-collapse"></i>
              <i class="fa-solid fa-phone icon-phone"></i>
            </div>


            <div class="widget-heading">




              <!-- Call Status Indicator -->
              <div class="call-status" [ngClass]="{'active-phone' : activePhone, 'online-call' : activeCall}">
                <i class="fa-solid fa-circle"></i>
              </div>

              <!-- Input - Outgoing Call -->
              <div *ngIf="!activeCall" class="phone-display-input">
                <span class="country-code">+1</span>
                <input type="text" class="phone-input" maxlength="10" placeholder="(000) 000-0000" [(ngModel)]="phoneNumberCall" *ngIf="!formPhoneHiden">
                <input type="text" class="phone-input" maxlength="10" placeholder="(000) 000-0000" value="(***) ***-****" *ngIf="formPhoneHiden">
                <button class="delete-number" (click)="deleteNumber()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-delete">
                    <path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path>
                    <line x1="18" y1="9" x2="12" y2="15"></line>
                    <line x1="12" y1="9" x2="18" y2="15"></line>
                  </svg>
                </button>
              </div>


              <!-- Display - Status de la Llamada -->
              <div *ngIf="activeCall" class="phone-display-call">
                <div class="message-call-status">

                  <!-- Llamada Saliente -->
                  <div class="caller-info-incoming-call" *ngIf="direction == 'OUTGOING' && !connectedCall">

                    <span class="calling-message" *ngIf="direction == 'OUTGOING' && !connectedCall">Calling...</span>
                    <span class="caller-name" *ngIf="infoCall.length == 0 && infoCallLeads.length == 0 ">Unknown</span>
                    <span class="caller-name" *ngIf="infoCall.length > 0">{{infoCall[0].customer.name}} {{infoCall[0].customer.last_name}}</span>
                    <span class="caller-name" *ngIf="infoCallLeads.length > 0">{{infoCallLeads[0].lead.customer.name}} {{infoCallLeads[0].lead.customer.last_name}}</span>
                    <span class="caller-phone" *ngIf="!formPhoneHiden">{{phoneNumberCall | phone:'US'}}</span>
                    <span class="caller-phone" *ngIf="formPhoneHiden">(***) ***-****</span>

                  </div>

                  <!-- Llamada Entrante -->
                  <div class="caller-info-incoming-call" *ngIf="direction == 'INCOMING' && !connectedCall">

                    <span class="incoming-call-message">Incoming Call...</span>
                    <span class="caller-name" *ngIf="infoCall.length == 0 && infoCallLeads.length == 0 ">Unknown</span>
                    <span class="caller-name" *ngIf="infoCall.length > 0">{{infoCall[0].customer.name}} {{infoCall[0].customer.last_name}}</span>
                    <span class="caller-name" *ngIf="infoCallLeads.length > 0">{{infoCallLeads[0].lead.customer.name}} {{infoCallLeads[0].lead.customer.last_name}}</span>
                    <span class="caller-phone" *ngIf="!formPhoneHiden">{{phoneNumberCall | phone:'US'}}</span>
                    <span class="caller-phone" *ngIf="formPhoneHiden">(***) ***-****</span>

                  </div>

                  <!-- Llamada Conectada -->
                  <div class="caller-info-incoming-call" *ngIf="connectedCall && !incomingCallOnHold && !callOnHold && !counterMerge">

                    <span class="incoming-call-message" *ngIf="conected">Connected</span>
                    <span class="incoming-call-message" *ngIf="!conected">Connecting...</span>
                    <span class="caller-name" *ngIf="infoCall.length == 0 && infoCallLeads.length == 0 ">Unknown</span>
                    <span class="caller-name" *ngIf="infoCall.length > 0">{{infoCall[0].customer.name}} {{infoCall[0].customer.last_name}}</span>
                    <span class="caller-name" *ngIf="infoCallLeads.length > 0">{{infoCallLeads[0].lead.customer.name}} {{infoCallLeads[0].lead.customer.last_name}}</span>
                    <ng-container *ngIf="!formPhoneHiden">
                      <span class="caller-phone" *ngIf="!phoneNumberCallTwo">{{phoneNumberCall | phone:'US'}}</span>
                      <span class="caller-phone" *ngIf="phoneNumberCallTwo">{{phoneNumberCallTwo | phone:'US'}}</span>
                    </ng-container>

                    <ng-container *ngIf="formPhoneHiden">
                      <span class="caller-phone" >(***) ***-****</span>
                    </ng-container>
                  <!--   <span class="call-time"  *ngIf="!callSwap">{{callTimeM.toString().padStart(2, '0')}}:{{callTimeS.toString().padStart(2, '0')}}</span> -->
                  <span class="call-time"  *ngIf="!callSwap">{{ callTimeM.toString().padStart(2, '0') }} : {{ callTimeS.toString().padStart(2, '0') }}</span>
                  <span class="call-time" *ngIf="callSwap">{{call2TimeM.toString().padStart(2, '0')}}:{{call2TimeS.toString().padStart(2, '0')}}</span>




                  </div>


                  <!-- 1 Llamada Conectada y LLamada Entrante 2 -->
                  <div class="caller-info-incoming-call caller-info-incoming-call-on-hold" *ngIf="incomingCallOnHold">

                    <span class="incoming-call-message">
                      Incoming Call...
                    </span>

                    <span class="caller-name" *ngIf="infoCall && infoCall.length == 0 && infoCallLeads.length == 0 ">
                      Unknown
                    </span>

                    <span class="caller-name" *ngIf="infoCall && infoCall.length > 0">
                      {{this.fullName2}}
                    </span>

                    <span class="caller-name" *ngIf="infoCallLeads.length > 0">
                      {{this.fullName2}}
                    </span>

                    <span class="caller-phone" *ngIf="!formPhoneHiden">
                      {{phoneNumberCallTwo | phone:'US'}}
                    </span>

                    <span class="caller-phone" *ngIf="formPhoneHiden">
                      (***) ***-****
                    </span>


                    <!-- Call Options Keypad -->
                    <div class="call-options-keypad">
                      <div class="d-flex">

                        <!-- Search Button -->
                        <div class="call-option">
                          <button class="btn-search" (click)="showModalIncomingCallInfo()">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </button>
                          <div class="call-option-name">Search</div>
                        </div>

                        <!-- Forward Button -->
                        <div class="call-option">
                          <button class="btn-forward" (click)="openModalForward()">
                            <i class="fa-solid fa-share"></i>
                          </button>
                          <div class="call-option-name">Forward</div>
                        </div>

                      </div>
                    </div>

                  </div>

                  <!-- Llamada en Espera -->
                  <div class="caller-info-incoming-call-on-hold" *ngIf="callOnHold">

                    <div class="info-incoming-call-on-hold" [ngClass]="{'call-on-hold' : callSwap}">
                      <!-- Llamada Actual en Espera -->

                      <span class="caller-name">
                        {{fullName}}
                      </span>

                      <ng-container *ngIf="!formPhoneHiden">
                        <span  *ngIf="infoCall && infoCall.length == 0 && infoCallLeads.length == 0 "> {{this.phoneNumberCall | phone:'US'}} </span>
                      </ng-container>

                      <ng-container *ngIf="formPhoneHiden">
                        <span  *ngIf="infoCall && infoCall.length == 0 && infoCallLeads.length == 0 "> (***) ***-**** </span>
                      </ng-container>

                      <span *ngIf="!callSwap">HOLD</span>
                      <span *ngIf="callSwap">{{callTimeM.toString().padStart(2, '0')}}:{{callTimeS.toString().padStart(2, '0')}}</span>
                    </div>

                    <div class="info-incoming-call-on-hold" [ngClass]="{'call-on-hold' : !callSwap}">
                      <!-- Nueva Llamada -->

                      <span class="caller-name">
                        {{fullName2}}
                      </span>

                      <span *ngIf="!phoneNumberCallTwo && infoCall.length == 0 && infoCallLeads.length == 0 ">
                        {{this.phoneNumberConference | phone:'US'}}
                      </span>
                      <span *ngIf="phoneNumberCallTwo && infoCall.length == 0 && infoCallLeads.length == 0 ">
                        {{this.phoneNumberCallTwo | phone:'US'}}
                      </span>
                      <span *ngIf="callSwap">HOLD</span>
                      <span *ngIf="!callSwap">{{call2TimeM.toString().padStart(2, '0')}}:{{call2TimeS.toString().padStart(2, '0')}}</span>
                    </div>

                  </div>

                      <div class="caller-info-incoming-call-on-hold" *ngIf="counterMerge">

                          <span class="caller-name" *ngIf="fullName != 'Unknown'">
                            {{fullName}}
                          </span>
                          <span  *ngIf="infoCall && infoCall.length == 0 && infoCallLeads.length == 0 ">
                            {{this.phoneNumberCall | phone:'US'}}
                          </span>
                          <span class="caller-name" *ngIf="fullName2 != 'Unknown'">
                            {{fullName2}}
                          </span>

                          <span *ngIf="!phoneNumberConference && infoCall.length == 0 && infoCallLeads.length == 0  ">
                            {{this.phoneNumberConference | phone:'US'}}
                          </span>
                          <span *ngIf="phoneNumberCallTwo && infoCall.length == 0 && infoCallLeads.length == 0 ">
                            {{this.phoneNumberCallTwo | phone:'US'}}
                          </span>

                          <span>{{callTimeM.toString().padStart(2, '0')}}:{{callTimeS.toString().padStart(2, '0')}}</span>
                        </div>

                   </div>
              </div>

            </div>

            <div class="widget-body">

              <div class="phone-keypad" [ngClass]="{'active-call' : activeCall, 'inactive-call' : !activeCall, 'incoming-call-on-hold' : incomingCallOnHold, 'call-on-hold' : callOnHold }">
                <div *ngIf="!incomingCallOnHold">
                  <!-- Numeric Keypad -->
                  <div *ngIf="showNumericKeypad" class="numeric-keypad">
                    <div class="col-4 key" (click)="dialedNumber(1)">
                      <div class="number"> 1 </div>
                      <div class="letters">&nbsp;</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(2)">
                      <div class="number"> 2 </div>
                      <div class="letters">ABC</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(3)">
                      <div class="number"> 3 </div>
                      <div class="letters">DEF</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(4)">
                      <div class="number"> 4 </div>
                      <div class="letters">GHI</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(5)">
                      <div class="number"> 5 </div>
                      <div class="letters">JKL</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(6)">
                      <div class="number"> 6 </div>
                      <div class="letters">MNO</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(7)">
                      <div class="number"> 7 </div>
                      <div class="letters">PQRS</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(8)">
                      <div class="number"> 8 </div>
                      <div class="letters">TUV</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(9)">
                      <div class="number"> 9 </div>
                      <div class="letters">WXYZ</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber('*')">
                      <div class="number"> * </div>
                      <div class="letters">&nbsp;</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber(0)">
                      <div class="number"> 0 </div>
                      <div class="letters">+</div>
                    </div>
                    <div class="col-4 key" (click)="dialedNumber('#')">
                      <div class="number"> # </div>
                      <div class="letters">&nbsp;</div>
                    </div>

                    <button *ngIf="connectedCall && showNumericKeypad" class="hide-numeric-keypad"
                      (click)="showNumericKeypad = false; showOptionsKeypad = true">
                      Hide
                    </button>
                  </div>

                  <!-- Call Options Keypad -->
                  <div *ngIf="showOptionsKeypad" class="call-options-keypad">

                    <div class="d-flex mt-5">

                      <!-- Silence Button cuando no esta en conferencia -->
                      <div class="call-option" [ngClass]="{'active' : activeSilence}" *ngIf="!activeSilenceConference">
                        <button class="btn-silence" (click)="silence()">
                          <i class="fa-solid fa-microphone-slash"></i>
                        </button>
                        <div class="call-option-name">Silence</div>
                      </div>

                      <!-- Silence Button cuando esta en conferencia -->
                      <div class="call-option" [ngClass]="{'active' : activeSilence}" *ngIf="activeSilenceConference">
                        <button class="btn-silence" (click)="silenceConference()">
                          <i class="fa-solid fa-microphone-slash"></i>
                        </button>
                        <div class="call-option-name">Silence</div>
                      </div>

                      <!-- hold Button -->
                      <div class="call-option" [ngClass]="{'active' : activePause}">
                        <button class="btn-pause" (click)="holdCall()">
                          <i class="fa-solid fa-pause"></i>
                        </button>
                        <div class="call-option-name">Hold</div>
                      </div>

                      <!-- Keypad Button -->
                      <div class="call-option">
                        <button class="btn-keypad" (click)="showNumericKeypad = true; showOptionsKeypad = false">
                          <svg fill="#ffffff" width="30px" height="30px" viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg">
                            <title>ionicons-v5-g</title>
                            <path d="M256,400a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M256,272a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M256,144a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M256,16a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M384,272a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M384,144a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M384,16a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M128,272a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M128,144a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                            <path d="M128,16a48,48,0,1,0,48,48,48,48,0,0,0-48-48Z" />
                          </svg>
                        </button>
                        <div class="call-option-name">Keypad</div>
                      </div>

                    </div>

                    <div class="d-flex">

                      <!-- Add Call Button -->
                      <div class="call-option">
                        <button class="btn-add-call" (click)="openModalConference();">
                          <i class="fa-solid fa-plus"></i>
                        </button>
                        <div class="call-option-name">Add Call</div>
                      </div>

                      <!-- Call Transfer Button -->
                      <div class="call-option">
                        <button class="btn-add-call" (click)="modalCallTransfer()">
                          <i class="fa-solid fa-arrow-right-arrow-left"></i>
                        </button>
                        <div class="call-option-name">Transfer</div>
                      </div>

                      <!-- Search Button -->
                      <div class="call-option">
                        <button class="btn-search" (click)="showModalIncomingCallInfo()">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                        <div class="call-option-name">Search</div>
                      </div>

                    </div>

                    <div class="d-flex">

                      <!-- Add Call Button -->
                      <div *ngIf="callOnHold" class="call-option">
                        <button class="btn-merge-calls" (click)="conference();">
                          <svg class="svg-icon" style="width: 1em; height: 1em;vertical-align: middle;fill: #ffffff;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M832.984615 866.461538c-110.276923-53.169231-189.046154-147.692308-228.430769-256-15.753846-39.384615-25.6-84.676923-29.538461-124.061538v-68.923077H787.692308c15.753846 0 27.569231-17.723077 15.753846-35.446154l-279.630769-334.769231c-9.846154-11.815385-31.507692-11.815385-39.384616 0l-271.753846 334.769231c-9.846154 11.815385 0 35.446154 15.753846 35.446154h214.646154v68.923077c-5.907692 41.353846-15.753846 86.646154-29.538461 124.061538-39.384615 108.307692-118.153846 202.830769-228.43077 256-15.753846 5.907692-21.661538 25.6-15.753846 39.384616l25.6 61.046154c7.876923 15.753846 25.6 21.661538 41.353846 11.815384 118.153846-57.107692 212.676923-147.692308 269.784616-256 59.076923 108.307692 151.630769 198.892308 271.753846 256 15.753846 7.876923 35.446154 5.907692 41.353846-11.815384l25.6-61.046154c11.815385-13.784615 3.938462-31.507692-11.815385-39.384616z"  /></svg>
                        </button>
                        <div class="call-option-name">Merge Calls</div>
                      </div>

                      <!-- Add Call Button -->
                      <div *ngIf="callOnHold" class="call-option">
                        <button class="btn-swap" (click)="callSwapEvent();  ">
                          <i class="fa-solid fa-shuffle"></i>
                        </button>
                        <div class="call-option-name">Swap</div>
                      </div>

                      <!-- Borrar -->
                      <div class="empty-box"></div>

                    </div>

                  </div>

                  <!-- Options Incoming Call -->
                  <div *ngIf="showIncomingCallOptions" class="incoming-call-options">
                    <div class="d-flex mt-5">

                      <!-- Search Button -->
                      <div class="call-option">
                        <button class="btn-search" (click)="showModalIncomingCallInfo()">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                        <div class="call-option-name">Search</div>
                      </div>

                      <!-- Forward Button -->
                      <div class="call-option">
                        <button class="btn-forward" (click)="openModalForward()">
                          <i class="fa-solid fa-share"></i>
                        </button>
                        <div class="call-option-name">Forward</div>
                      </div>

                    </div>
                  </div>

                  <!-- Options Outgoing Call -->
                  <div *ngIf="showOutgoingCallOptions" class="incoming-call-options">
                    <div class="d-flex mt-5">

                      <!-- Search Button -->
                      <div class="call-option">
                        <button class="btn-search" (click)="showModalIncomingCallInfo()">
                          <i class="fa-solid fa-magnifying-glass"></i>
                        </button>
                        <div class="call-option-name">Search</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="call-actions-btns">

                <!-- Iniciar Llamada - Llamada Inactiva -->
                <div *ngIf="!activeCall" class="call-btn" [ngClass]="{'phone-disconnected' : !activePhone || phoneNumberCall.length < 10}">
                  <div class="outgoing-call-btn">

                    <!-- <button class="btn rounded-circle" [disabled]="!activePhone || phoneNumberCall.length < 10" (click)="outgoingCallEvent();callPhone()"> -->

                    <button class="btn rounded-circle" [disabled]="!activePhone || phoneNumberCall.length < 10" (click)="outgoingCallEvent();callPhone()">
                      <i class="fa-solid fa-phone"></i>
                    </button>

                  </div>
                </div>

                <!-- Contestar Llamada - Llamada Entrante -->
                <div *ngIf="activeCall && direction == 'INCOMING' && !connectedCall" class="call-btn">
                  <div class="outgoing-call-btn">
                    <!-- <button class="btn rounded-circle btn-answer-call" (click)="connectedCallEvent()"> -->

                    <!-- Modal con Menú de Opciones
                    <button class="btn rounded-circle btn-answer-call" (click)="showModalAnswerIncomingCall()">
                      <i class="fa-solid fa-phone"></i>
                    </button> -->

                    <button class="btn rounded-circle btn-answer-call" (click)="answerCall()">
                      <i class="fa-solid fa-phone"></i>
                    </button>

                  </div>
                </div>

                <!-- Finalizar y Contestar - Llamada Conectada - Llamada en Espera -->
                <div *ngIf="incomingCallOnHold" class="call-btn btn-finish-answer-call" (click)="hangupAnswerCall();" >

                  <div *ngIf="connectedCall" class="btn rounded-circle hold-call-btn-end">
                    <i class="fa-solid fa-phone"></i>
                  </div>

                  <div *ngIf="connectedCall" class="btn rounded-circle hold-call-btn-answer ">
                    <i class="fa-solid fa-phone"></i>
                  </div>

                  <div class="desc-btn">
                    End & Accept
                  </div>

                </div>

                <!-- Deterner Llamada - Llamada Conectada -->
                <div *ngIf="activeCall" class="call-btn" [ngClass]="{'btn-reject-incoming-call': activeCall && direction == 'INCOMING' && !connectedCall}">

                  <div class="cancel-call-btn">

                    <!-- Modal con Menú de Opciones
                    <button *ngIf="!connectedCall" class="btn rounded-circle modal-action" (click)="showModalCancelIncomingCall()">
                      <i class="fa-solid fa-phone-slash"></i>
                    </button> -->

                    <!-- Rechazar Llamada y Enviar al Buzon de Voz -->
                    <button *ngIf="!connectedCall&&this.direction==='INCOMING'" class="btn rounded-circle modal-action" (click)="rejectCall();endCall();ringingAudioStop();">
                      <i class="fa-solid fa-phone-slash"></i>
                    </button>

                    <!-- Colgar Llamada 1 Conectada -->
                    <button *ngIf="connectedCall && !callOnHold && !incomingCallOnHold" class="btn rounded-circle hangup-action 1-line" (click)="hangupTwoCall();endCall();">
                      <i class="fa-solid fa-phone-slash"></i>
                    </button>

                     <!-- Colgar Llamada 2 Lineas Conectadas -->
                     <button *ngIf="connectedCall && callOnHold" class="btn rounded-circle hangup-action 2-lines" (click)="hangupcallOnHold()">
                      <i class="fa-solid fa-phone-slash"></i>
                    </button>

                    <!-- Colgar Llamada saliente antes de contestar -->
                    <button *ngIf="!connectedCall&&this.direction==='OUTGOING'" class="btn rounded-circle hangup-action" (click)="hangup();endCall();callingAudioStop();">
                      <i class="fa-solid fa-phone-slash"></i>
                    </button>

                    <!--  Voicemail - Llamada Entrante - 1 Conectada y 1 Llamada Entrante -->
                    <button *ngIf="incomingCallOnHold" class="btn rounded-circle hangup-action" (click)="voiceMail2Call();">
                      <i class="fa-solid fa-voicemail"></i>
                    </button>

                  </div>

                </div>

                <!-- Dejar en Espera y Contestar - Llamada Conectada - Llamada en Espera -->
                <div *ngIf="incomingCallOnHold" class="call-btn btn-hold-answer-call" (click)="answerCallAndHold();">

                  <div class="btn rounded-circle hold-call-btn-hold">
                    <i class="fa-solid fa-pause"></i>
                  </div>

                  <div class="btn rounded-circle hold-call-btn-answer ">
                    <i class="fa-solid fa-phone"></i>
                  </div>

                  <div class="desc-btn">
                    Hold & Accept
                  </div>

                </div>

              </div>

              <div class="widget-footer">
                XPhone 360
              </div>

            </div>

            <!-- Modal Incoming Call-->
            <div class="cancel-modal-incoming-call">
              <button (click)="closeModalIncomingCall()">
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>

        <!--     <div class="modal-incoming-call">

              <div class="btns-answer-call">
                <button class="btn btn-answer option-answer-incoming-call" (click)="answerCall()">
                  Answer
                </button>

                <button class="btn btn-dialer option-answer-incoming-call" (click)="showModalIncomingCallHistory()">
                  Dialer
                </button>

                <button class="btn btn-lookup option-answer-incoming-call" (click)="showModalIncomingCallInfo()">
                  Lookup
                </button>
              </div>

              <div class="btns-cancel-call">
                <button class="btn btn-voice-mail option-cancel-incoming-call" (click)="endCall()">
                  Voice Mail
                </button>

                <button class="btn btn-forward option-cancel-incoming-call" (click)="openModalForward()">
                  Forward
                </button>

                <button class="btn btn-decline option-cancel-incoming-call" (click)="hangup();endCall();">
                  Decline
                </button>
              </div>

            </div> -->

            <div class="modal-backdrop-incoming-call hide-modal" (click)="closeModalIncomingCall()"></div>

          </div>





        </div>

      </div>

    </div>

  </div>

</div>

 <!--  <button (click)="voiceMail()">test voicemail</button> -->

  <!-- Modal Incoming Call History -->
  <div class="modal-content hide-modal" id="modal_incoming_call_history">

    <div class="modal-header" id="incoming_call_history_modal_header">
      <h5 class="modal-title">Incoming Call History</h5>
      <button class="btn-close" (click)="closeModalIncomingCallHistory()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>

    <div class="modal-body">

      <!-- Si el número entrante tiene asignado un Job, se muestra la información del mismo -->
      <div class="incoming-call-history">

        <div class="info-box px-3 pb-2">
          <i class="fa-solid fa-phone"></i>
          <span>(717) 322-6444</span>
        </div>

        <div class="info-box-container">
          <div class="info-box">
            <i class="fa-solid fa-clock"></i>
            <span>00:00:07</span>
          </div>
          <div class="info-box">
            <i class="fa-solid fa-calendar-days"></i>
            <span>Jan 17th 2024 04:49 PM</span>
          </div>
        </div>

        <div class="info-box-container">
          <div class="info-box">
            <i class="fa-solid fa-clock"></i>
            <span>00:00:07</span>
          </div>
          <div class="info-box">
            <i class="fa-solid fa-calendar-days"></i>
            <span>Jan 17th 2024 04:49 PM</span>
          </div>
        </div>

      </div>

      <!-- Si el número entrante no tiene asignado ningun Job, se muestra el mensaje -->
      <div class="unlinked-number">
        *Phone number not related to any job
      </div>

    </div>

  </div>


  <!-- Modal Incoming Call Info -->
  <div class="modal-content hide-modal" id="modal_incoming_call_info">

    <div class="modal-header" id="incoming_call_info_modal_header">
      <h5 class="modal-title">Incoming Call Info</h5>
      <button class="btn-close" (click)="closeModalIncomingCallInfo()">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>

    <div class="modal-body">



        <!-- Si el número entrante tiene asignado un Job, se muestra la información del mismo -->
        <div class="incoming-call-info">

          <div class="info-box px-3 pb-2">
            <!-- <i class="fa-solid fa-phone"></i>
            <span>(717) 322-6444</span> -->
            <span>Related Jobs To: {{phoneNumberCall | phone:'US'}}</span>
          </div>
          <ng-container *ngIf="infoCall">
          <div class="info-box-container" *ngFor="let infoC of infoCall"> <!-- ngFor -->
            <div class="info-box">
              <i class="fa-solid fa-user"></i>
              <span>{{infoC.customer.name}} {{infoC.customer.last_name}}</span>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-phone"></i>
              <span>{{infoC.customer.phone}}</span>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-envelope"></i>
              <span>{{infoC.customer.email}}</span>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-briefcase"></i>
              <a appWorkspaceRouterLink="/companyspace/{{authService.workspaceSession.company.id}}/moving/{{infoC.id}}/estimate"  target="_blank"><span>{{infoC.document.code}}</span></a>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-location-dot"></i>
              <span>{{infoC.pickup.address.full_address}}</span>
              <span class="moving"> (From)</span>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-location-dot"></i>
              <span>{{infoC.delivery.address.full_address}}</span>
              <span class="moving"> (To)</span>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-sack-dollar"></i>
              <span>{{infoC.total}}</span>
            </div>
            <div class="info-box">
              <i class="fa-solid fa-box-open"></i>
              <span>Status</span>
            </div>
          </div>
        </ng-container>
         <!-- leads -->
         <ng-container *ngIf="infoCallLeads">
         <div class="info-box-container" *ngFor="let infoCL of infoCallLeads"> <!-- ngFor -->
          <div class="info-box">
            <i class="fa-solid fa-user"></i>
            <span>{{infoCL.lead.customer.name}} {{infoCL.lead.customer.last_name}}</span>
          </div>
          <div class="info-box">
            <i class="fa-solid fa-phone"></i>
            <span>{{infoCL.lead.customer.phone}}</span>
          </div>
          <div class="info-box">
            <i class="fa-solid fa-envelope"></i>
            <span>{{infoCL.lead.customer.email}}</span>
          </div>

          <div class="info-box">
            <i class="fa-solid fa-location-dot"></i>
            <span>{{infoCL.lead.from_address.city}}, {{infoCL.lead.from_address.state}} </span>
            <span class="moving"> (From)</span>
          </div>
          <div class="info-box">
            <i class="fa-solid fa-location-dot"></i>
            <span>{{infoCL.lead.to_address.city}}, {{infoCL.lead.to_address.state}}</span>
            <span class="moving"> (To)</span>
          </div>

          <div class="info-box">
            <i class="fa-solid fa-box-open"></i>
            <span>Status</span>
          </div>
        </div>
      </ng-container>
        </div>



      <!-- Si el número entrante no tiene asignado ningun Job, se muestra el mensaje -->
      <div class="unlinked-number" *ngIf="!infoCall">
        *Phone number not related to any job
      </div>

    </div>

  </div>

  <!-- Modal - Tranfers Calls -->
  <div class="modal fade" #callTransfer id="callTransfer" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="newConceptTitle">Call Transfer</h5>
        </div>
        <div class="modal-body">
          <div>
            <input type="text" minlength="10" maxlength="10" [(ngModel)]="numberTransferred"
              placeholder="(000) 000-0000" name="transfer" class="form-control">
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-cancel" data-dismiss="modal">
            Cancel
          </button>
          <button class="btn btn-save" type="button" (click)="tranferCall()">
            Transfer
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal - Forward Popup -->
  <div class="modal fade" #modalForward id="modalForward" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <h5 class="modal-title" id="newConceptTitle">Forward </h5>

          <div class="filtered-list-search">
            <form class="form-inline my-2 my-lg-0">
              <div class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-search">
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                      class="form-control product-search" id="input-search" placeholder="Search Contact..." >
              </div>
            </form>
          </div>

        </div>
        <div class="modal-body">

          <div class="col-12 p-0">
            <label for="name">Contacts</label>
          </div>

          <div class="contact-container">

            <div class="contact" *ngFor="let phoneLine of phoneLines[0]">
              <div class="contact-name">
                <i class="fa-solid fa-user"></i>
                <ng-container *ngIf="!formPhoneHiden">
                  <span> {{ phoneLine.name | titlecase}}</span>
                </ng-container>
                <ng-container *ngIf="formPhoneHiden">
                  <span class="caller-phone" >(***) ***-****</span>
                </ng-container>
              </div>
              <div class="contact-phone">
                <i class="fa-solid fa-phone"></i>
                <span (click)="custonPhone(phoneLine.phone_number)"> {{ phoneLine.phone_number  | phone}}</span>
              </div>
            </div>

          </div>

          <hr class="hr">

          <div class="d-flex">
            <div class="custom-phone-number">
              <label for="name">Custom Phone Number</label>
              <input type="text" class="form-control" id="custom_phone_number" maxlength="12" placeholder="(000) 000-0000" name="custom_phone_number"  [(ngModel)]=" this.custonPhoneNumber">
            </div>
            <button class="btn btn-success call-custom-phone" (click)="forward();">
              <i class="fa-solid fa-phone"></i>
            </button>
          </div>


        </div>
        <div class="modal-footer">
          <button class="btn btn-cancel" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>



   <!-- Modal - Conference Popup -->
<div class="modal fade" #modalConference id="modalConference" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <h5 class="modal-title" id="newConceptTitle">Conference </h5>

          <div class="filtered-list-search">
            <form class="form-inline my-2 my-lg-0">
              <div class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-search">
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                      class="form-control product-search" id="input-search" placeholder="Search Contact..." >
              </div>
            </form>
          </div>

        </div>
        <div class="modal-body">

          <div class="col-12 p-0">
            <label for="name">Contacts</label>
          </div>

          <div class="contact-container">

            <div class="contact" *ngFor="let phoneLine of phoneLines[0]">
              <div class="contact-name">
                <i class="fa-solid fa-user"></i>
                <span> {{ phoneLine.name | titlecase}}</span>
              </div>
              <div class="contact-phone">
                <i class="fa-solid fa-phone"></i>
                <span (click)="customPhoneNumberConference(phoneLine.phone_number)"> {{ phoneLine.phone_number  | phone:'US'}}</span>
              </div>
            </div>

          </div>

          <hr class="hr">

          <div class="d-flex">
            <div class="custom-phone-number">
              <label for="name">Custom Phone Number</label>
              <input type="text" class="form-control" id="custom_phone_number" maxlength="12" placeholder="(000) 000-0000" name="custom_phone_number"  [(ngModel)]="this.phoneNumberConference">
            </div>
            <button class="btn btn-success call-custom-phone" data-dismiss="modal" (click)="callConference();callOnHoldEvent();">
              <i class="fa-solid fa-phone"></i>
            </button>
          </div>


        </div>
        <div class="modal-footer">
          <button class="btn btn-cancel" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>







  <!-- Modal - No Internet Connection -->
  <div class="modal fade" #modalNoInternetConnection id="modalNoInternetConnection" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center pb-0">

          <div class="icon">
            <i class="fa-solid fa-globe world-icon"></i>

            <div class="cancel-icon">
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>

        </div>
        <div class="modal-body text-center p-0">

          <div class="col-12 title">
            No Internet Connection
          </div>

          <div class="col-12 text">
            Looks like there is no connection to the internet. Please check cable or wifi connections
          </div>

        </div>
        <div class="modal-footer justify-content-center">

        </div>
      </div>
    </div>
  </div>


