<div class="form-container">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container p-0">
                <div class="form-content">

                    <ng-container *ngIf="!confirmationChangedPassword">

                        <div class="text-center">
                            <img src="assets/img/logo_moverxpro360.png" alt="">
                        </div>

                        <h1 class="title-recovery mt-5 mb-0">Password Recovery</h1>
                        <span class="">Enter your email and instructions will sent to you!</span>
                        <form class="text-left mt-4 mb-5" ngNativeValidate (ngSubmit)="sendTokenVerification()">

                            <div class="form" *ngIf="sendToken">

                                <div id="email-field" class="field-wrapper input">
                                    <i class="fas fa-envelope"></i>
                                    <input id="email" name="email" type="email" placeholder="Email" required
                                        [(ngModel)]="accountRecoveryForm.email" autofocus="autofocus">
                                </div>

                                <div class="text-right">
                                    <button type="submit" class="btn btn-warning btn-reset">Reset Password</button>
                                </div>
                            </div>

                            <div class="form" *ngIf="!sendToken">

                                <div id="email-field" class="field-wrapper input">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-edit-3">
                                        <path d="M12 20h9"></path>
                                        <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                    </svg>
                                    <input id="token" name="token" type="text" [(ngModel)]="accountRecoveryForm.token"
                                        placeholder="code sms" required maxlength="6"
                                        style="letter-spacing: 26px; text-align: center" pattern="[0-9]{6}">
                                </div>
                                <div class="d-sm-flex justify-content-between">
                                    <div class="field-wrapper text-center" style="width: 100%;">
                                        <button type="submit" (click)="verifyToken()" class="btn btn-warning">
                                            Verify
                                        </button>
                                        <button type="button" (click)="changeViewSendToken()"
                                            class="btn btn-warning btn-outline-warning"> New code</button>
                                    </div>
                                </div>

                            </div>

                        </form>

                    </ng-container>

                    <ng-container *ngIf="confirmationChangedPassword">

                        <h1 class="">Your password has been changed</h1>
                        <p class="signup-link">Check your email to see your new password</p>
                        <div class="form">
                            <div class="d-sm-flex justify-content-between">
                                <div class="field-wrapper text-center" style="width: 100%;">
                                    <button type="submit" (click)="redirectToLogin()" class="btn btn-warning">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <p class="terms-conditions text-center mt-5">© 2023 All Rights Reserved
                        <a href="https://www.moverxpro360.com">Moverxpro360</a> is a product of <a href="https://moverxpro360.com" target="_blank">MoverXpro 360 LLC</a> <br>
                        <!-- <a href="javascript:void(0);">Cookie Preferences</a>, -->
                        <a href="https://moverxpro360.com/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://moverxpro360.com/term-and-conditions" target="_blank">Term and Conditions</a>
                    </p>

                </div>
            </div>
        </div>
    </div>
    <div class="form-image">
        <div class="l-image">
        </div>
    </div>
</div>
