export class JobAdditionalCharge {
    id: string;
    name: string;
    amount: number;
    type: 'PICKUP' | 'DELIVERY' | 'NOT_APPLY';
    job_id: string;   

    constructor() {
        this.id = null;
        this.name = null;
        this.amount = null;
        this.type = 'NOT_APPLY';
        this.job_id = null;
    }
}
