

import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CalendarService } from '../../../services/workspaces/calendar.service';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarriersContactsCompanyService } from 'src/app/services/companies/carriers-contacts-company.service';
import { CarriersService } from '../../../services/workspaces/carriers.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { Company } from 'src/app/entities/workspace/company';
import { ConstantsMessages } from 'src/app/constants-messages';
import { DailyCrew } from 'src/app/entities/workspace/daily-crew';
import { DailyCrewService } from 'src/app/services/companies/daily-crew.service';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper.service';
import { Job } from '../../../entities/workspace/job';
import { JobAppoinment } from 'src/app/entities/workspace/job-appoinment';
import { JobOutsourcing } from 'src/app/entities/workspace/job-outsourcing';
import { JobOutsourcingsService } from 'src/app/services/companies/job-outsourcings.service';
import { JobsService } from '../../../services/workspaces/jobs.service';
import { JobView } from 'src/app/entities/workspace/job-view';
import { ScheduleCalendar } from 'src/app/entities/workspace/schedule-calendar';
import { Vehicle } from '../../../entities/workspace/vehicle';
import { VehiclesService } from 'src/app/services/workspaces/vehicles.service';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { ZonesService } from 'src/app/services/workspaces/zones.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare var jQuery;
declare const moment;
declare const swal;
declare const google;
@Component({
  selector: 'app-calendar-freight',
  templateUrl: './calendar-freight.component.html',
  styleUrls: ['./calendar-freight.component.scss']
})
export class CalendarFreightComponent implements AfterViewInit {

  @ViewChild('divCalendar') divCalendar: ElementRef;
    @ViewChild('modalCarriers') modalCarriers: ElementRef;
    @ViewChild('modalExtra') modalExtra: ElementRef;
    @ViewChild('modalAddDailyCrew') modalAddDailyCrew: ElementRef;
    @ViewChild('modalRemark') modalRemark: ElementRef;
    @ViewChild('modalInformationCarriers') modalInformationCarriers: ElementRef;
    @ViewChild('mapView') mapView: ElementRef;
    @ViewChild('modalMap') modalMap: ElementRef;
    @ViewChild('modalTimeCrew') modalTimeCrew: ElementRef;
    
    private map;
    public agent: Carrier;
    public allWorkDepartments: Array<WorkDepartment>;
    public arrayCalendar: Array<ScheduleCalendar>;
    public calendar: any;
    public calendario: ScheduleCalendar;
    public calendarSelectedDay: number;
    public carrier: Carrier;
    public carrierBalanceOffer: number;
    public carrierBalancePercentage: number;
    public carrierFilter: string;
    public carriers: Array<Carrier>;
    public checkBoxesDelivery: boolean;
    public checkCarrier: boolean;
    public checkClassicMode: boolean;
    public checkDelivery: boolean;
    public checkPackDays: boolean;
    public checkPickUps: boolean;
    public companies: Array<Company>;
    public confirm: boolean;
    public constantsMessages = ConstantsMessages;
    public crew: DailyCrew;
    public crewEmployees: Array<Employee>;
    public currentCrewType: string;
    public currentDate;
    public date: number;
    public dayCalendar: any;
    public detailDay: boolean;
    public dispatched: boolean;
    public displayConfirmed: boolean;
    public employees: Array<Employee>;
    public employeesView: Array<EmployeeView>;
    public endDate: string;
    public endDay: string;
    public filterSearch: string;
    public fullCalendarData: any;
    public hiddenCalendar: boolean;
    public idsTrucks: Array<string>;
    public infowindow;
    public isAssigned: boolean;
    public isPublicJobOutsourcing: boolean;
    public job: Job;
    public jobCrewEdited: any;
    public jobId: string;
    public jobSelected: JobView;
    public jobStatus: string;
    public moveType: string;
    public rangeEnd: string;
    public rangeStart: string;
    public remark: string;
    public selectedDay: number;
    public service: string;
    public specialConditions: string;
    public startDate: string;
    public startDay: string;
    public state: string;
    public stateSelected: any;
    public titleModalCarrier: string;
    public truncks: Array<Vehicle>;
    public type: string;
    public typeModal: string;
    public typeService: boolean;
    public userId: string;
    public viewSectionModalCarrier: "INTRO" | "POST_REQUEST_CARRIER" | "ASSIGN_CARRIER";
    public workDepartmentId: string;
    public workers;
    public zipCodePoint: number;
    public zonesStates: any;

    public isFixed: boolean;        // Status Operation Filter - Fixed
    public divTopDistance: number;
    public scrollThreshold: number; // Umbral de desplazamiento

  constructor(
    private calendarService: CalendarService,
    private carriersContactsCompanyService: CarriersContactsCompanyService,
    private carrierService: CarriersService,
    private companiesService: CompaniesService,
    private currentRoute: ActivatedRoute,
    private dailyCrewService: DailyCrewService,
    private employeesService: EmployeesService,
    private helperService: HelperService,
    private jobOutsourcingsService: JobOutsourcingsService,
    private jobsService: JobsService,
    private vehiclesService: VehiclesService,
    private workDepartmentsService: WorkDepartmentsService,
    private zonesService: ZonesService,
    public authService: AuthService,
    public tariffVersionFactory: TariffVersionService,
    private elRef: ElementRef
  ) {
    this.agent = new Carrier();
    this.allWorkDepartments = [];
    this.arrayCalendar = [];
    this.calendario = new ScheduleCalendar();
    this.carrierBalanceOffer = 0;
    this.carrierBalancePercentage = 0;
    this.carrierFilter = null;
    this.checkBoxesDelivery = false;
    this.checkCarrier = false;
    this.checkClassicMode = false;
    this.checkDelivery = false;
    this.checkPackDays = false;
    this.checkPickUps = true;
    this.companies = [];
    this.crew = new DailyCrew();
    this.crewEmployees = [];
    this.currentCrewType = "";
    this.currentDate = new Date().getTime();
    this.date = 0;
    this.dayCalendar = { pickups: null, boxes_delivery_day: null, packing_day: null, deliveries: null };
    this.detailDay = true;
    this.dispatched = false;
    this.displayConfirmed = true;
    this.employees = [];
    this.endDay = '';
    this.filterSearch = '';
    this.getDates();
    this.hiddenCalendar = false;
    this.idsTrucks = [];
    this.isAssigned = false;
    this.isPublicJobOutsourcing = false;
    this.jobCrewEdited = new JobView();
    this.jobId = '';
    this.jobSelected = new JobView();
    this.jobStatus = '';
    this.moveType = '';
    this.rangeEnd = '06:00';
    this.rangeStart = '06:00';
    this.remark = '';
    this.selectedDay = 0;
    this.service = '';
    this.specialConditions = '';
    this.startDay = '';
    this.state = '';
    this.stateSelected = '';
    this.truncks = [];
    this.type = '';
    this.typeModal = '';
    this.typeService = false;
    this.userId = '';
    this.workDepartmentId = '';
    this.workers = [];
    this.zipCodePoint = null;

    this.isFixed = false;       // Status Operation Filter - Fixed
    this.divTopDistance = 0;
    this.scrollThreshold = 100; // Umbral de desplazamiento
  }

  /**
     * Metodo que obtiene las fechas actuales del mes en el calendario
     */
   getDates() {
    const date = new Date();
    // Obtiene el primer dia del mes
    const firsDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // Obtiene el ultimo dia del mes
    const mounth = date.getMonth() + 1;
    const lastDay = new Date(date.getFullYear(), mounth, 0);

    // Se convierte el primer dia en cadena para agregar un 0 al inicio
    const firstCalendarDay = firsDay.getDate().toString();
    // Se convierte el mes en cadena para agregar un 0 al inicio
    const calendarMonth = mounth.toString();

    // Si el dia contiene un solo digito, se agrega un 0 al inicio
    const firstDayConverted = firstCalendarDay.length === 1 ? '0' + firstCalendarDay : firstCalendarDay;
    // Si el mes contiene un solo digito, se agrega un 0 al inicio
    const monthCalendarConverted = calendarMonth.length === 1 ? '0' + calendarMonth : calendarMonth;

    // Se obtienen las fechas en formato humano
    const initialHumanDate = date.getFullYear() + '-' + monthCalendarConverted + '-' + firstDayConverted + 'T00:00:00';
    const endHumanDate = date.getFullYear() + '-' + monthCalendarConverted + '-' + lastDay.getDate().toString() + 'T23:59:00';

    // Se inicia el proceso de conversion de la fecha a numerica
    const conversionInitialDate = moment(initialHumanDate).format(moment.HTML5_FMT.DATE);
    const conversionFinalDate = moment(endHumanDate).format(moment.HTML5_FMT.DATE);
    const initialConversion = conversionInitialDate.toString() + 'T00:00:00';
    const endConversion = conversionFinalDate.toString() + 'T20:00:00';

    const newStartDate = new Date(initialConversion);
    const newEndDate = new Date(endConversion);

    const initialNumericConversion = newStartDate.getTime();
    const finalNumericalConversion = newEndDate.getTime();
    // Se agregan las fechas en formato numerico
    this.startDate = initialNumericConversion + '';
    this.endDate = finalNumericalConversion + '';
}

ngAfterViewInit(): void {
    ////// lo nuevo esta de aca para abajo
    const THIS = this;

    jQuery('#buttonFull').click(function (e) {
        jQuery('#calendar').toggleClass('fullscreen');
    });
    jQuery(document).keyup(function (e) {
        if (e.key === "Escape") { // escape key maps to keycode `27`
            jQuery('#calendar').toggleClass('fullscreen');
        }
    });

    /* jQuery(document).on('click', '.show-map', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        const myelement = jQuery(event.target);

        const pickup_state = jQuery(myelement).attr('pickup-state');
        const pickup_zip_code = jQuery(myelement).attr('pickup-zip');
        const delivery_state = jQuery(myelement).attr('delivery-state');
        const delivery_zip_code = jQuery(myelement).attr('delivery-zip');
        THIS.initializeMap(pickup_state, pickup_zip_code, delivery_state, delivery_zip_code);
    }).on('click', '.button-highlight', async (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        jQuery('.calendar-color-pickup').removeClass('highlight');

        await this.loadDataDay();

        const idsJobs: Array<string> = [];

        for (const day of this.dayCalendar.today_assigned) {
            for (const idJob of day.jobs_id) {
                idsJobs.push(idJob.job_id);
            }
        }

        for (const calendar of this.arrayCalendar) {
            if (idsJobs.indexOf(calendar.estimate_id) > -1) {
                jQuery('.' + calendar.estimate_id).addClass('highlight');
            }
        }

        this.hiddenCalendar = false;
        return;
    }); */

    this.loadCarriers();
    this.checkedTrucks();
    this.loadCompanies();
    this.loadStates();


    //////// lo viejo esta de aca para abajo
    const that = this;
    setTimeout(() => {
        jQuery('.fc-next-button, .fc-prev-button, .fc-today-button').click(() => {
            jQuery(this.divCalendar.nativeElement).fullCalendar('removeEvents');
            that.startDate = '' + moment(jQuery(this.divCalendar.nativeElement)
                .fullCalendar('getView').start._d, moment.HTML5_FMT.DATE).unix() * 1000;
            that.endDate = '' + moment(jQuery(this.divCalendar.nativeElement)
                .fullCalendar('getView').end._d, moment.HTML5_FMT.DATE).unix() * 1000;

            that.loadCalendar();
        });
    }, 2000);
    this.loadCalendar();

    jQuery(this.divCalendar.nativeElement).fullCalendar({
        header: {
            left: 'month,agendaWeek,agendaDay',
            rigth: 'prev,next,today',
            center: 'title',
        },
        eventRender: (eventObj, $el) => {
            $el.find('span.fc-title').html(eventObj.description);
            $el.find('span.fc-time').remove();
        },
        events: this.arrayCalendar,
        editable: false,
        eventLimit: false,
        selectable: true,
        eventClick: (event) => {
            swal({
                html: event.description
            });
            if (event.url) {
                window.open(event.url, '_blank');
                return false;
            }
        },
        eventMouseover: (event, jsEvent, view) => {

            jQuery(this).attr('id', event.id);

            jQuery('#' + event.id).popover({
                template: '<div class="popover popover-primary" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                title: event.title,
                content: event.description,
                placement: 'top',
            });

            jQuery('#' + event.id).popover('show');
        },
        eventMouseout: (event, jsEvent, view) => {

            jQuery('#' + event.id).popover('hide');
        },
        dayClick: (date, allDay, jsEvent, view) => {
            this.selectedDay = moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
            this.calendarSelectedDay = moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
            that.startDay = '' + moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
            that.endDay = '' + moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(59).minute(59).hour(23).unix() * 1000;

            that.loadDataDay();
        }
    });


    window.addEventListener('scroll', this.checkScroll.bind(this));
    this.calculateDivTopDistance();

    this.load();
}

/**
 * Carga los datos al entrar en un dia del calendario
 */
loadDataDay() {

    return new Promise((resolve, reject) => {
        this.helperService.showLoadingMxpro360();
        const data = {
            'full-view': '1',
            start: this.startDay,
            end: this.endDay,
            type_estimate: 'FREIGHT',
            user_id: '',
            work_department_id: '',
            status: '',
            move_type: '',
            statuses: '',
            service: '',
            carrier: ''
        };

        this.calendarService
            .getJobsToday(data)
            .then((response) => {
                // Asigna el titulo dependiendo si hay pickups y deliveries
                if (response['related_unassigned']['pickups'].length > 0) {
                    this.titleModalCarrier = 'Carrier';
                } else if (response['related_unassigned']['deliveries'].length > 0) {
                    this.titleModalCarrier = 'Agent';
                }
                this.hiddenCalendar = true;
                this.dayCalendar = response;
                
                resolve(true);
            })
            .catch((error) => {
                reject(error);
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    });
}

createTable(calendarDay) {
    //  alert('Clicked on the entire day: ' + date);
    let texto = `<table id="table" class="table table-bordered" border=1>
    <thead>
        <tr>
            <th>Customer</th>
            <th>Service</th>
            <th>Volume</th>
            <th>Estimate</th>
            <th>Edit</th>
        </tr>
    </thead>
    <tbody>
       `;

    for (let cal of calendarDay['pickups']) {
        texto += ` <tr>
            <td>
                <p class="user-name">` + cal.customer.last_name + `</p>
                <p class="user-work">` + cal.customer.name + `</p>
                <p class="user-work">` + cal.customer.phone + `</p>
                <p class="user-work">` + cal.customer.email + `</p>
                </td>
            <td>
                <p class="usr-email-addr">
                    <strong>Service: </strong> ` + cal.service + `
                </p>
                <p class="usr-email-addr">
                    <strong>Move Date: </strong>` + moment(cal.move_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + `
                </p>
                <p class="usr-email-addr">
                    <strong>From: </strong>` + cal.pickup.address.state + ` ` + cal.pickup.address.zip_code + `
                </p>
                <p class="usr-email-addr">
                    <strong>To: </strong>` + cal.delivery.address.state + ` ` + cal.delivery.address.zip_code + `
                </p>
            </td>
            <td>
                <p class="usr-location">
                    <strong>Libs: </strong> ` + cal.volume.libs + `
                </p>
                <p class="usr-location">
                    <strong>Cf: </strong>` + cal.volume.cubic_feets_selled + `
                </p>
                <p class="usr-location">
                    <strong>Miles: </strong>` + cal.volume.miles + `
                </p>
            </td>
            <td>
              <p class="usr-ph-no">
                    <strong>Number: </strong> ` + cal.document.prefix + `` + String(cal.document.consecutive).padStart(5, '0') + `
                </p>
                <p class="usr-ph-no">
                    <strong>Estimate: </strong> ` + cal.total + `
                </p>
            </td>
            <td>
                <a href="/#/moving/` + cal.id + `/estimate" onclick="swal.close();">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                </a>
            </td>
        </tr>`;
    }

    for (let cal of calendarDay['packing_day']) {
        texto += ` <tr>
                <td>
                    <p class="user-name">` + cal.customer.last_name + `</p>
                    <p class="user-work">` + cal.customer.name + `</p>
                    <p class="user-work">` + cal.customer.phone + `</p>
                    <p class="user-work">` + cal.customer.email + `</p>
                    </td>
                <td>
                    <p class="usr-email-addr">
                        <strong>Service: </strong> ` + cal.service + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>Move Date: </strong>` + moment(cal.move_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>From: </strong>` + cal.pickup.address.state + ` ` + cal.pickup.address.zip_code + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>To: </strong>` + cal.delivery.address.state + ` ` + cal.delivery.address.zip_code + `
                    </p>
                </td>
                <td>
                    <p class="usr-location">
                        <strong>Libs: </strong> ` + cal.volume.libs + `
                    </p>
                    <p class="usr-location">
                        <strong>Cf: </strong>` + cal.volume.cubic_feets_selled + `
                    </p>
                    <p class="usr-location">
                        <strong>Miles: </strong>` + cal.volume.miles + `
                    </p>
                </td>
                <td>
                <p class="usr-ph-no">
                        <strong>Number: </strong> ` + cal.document.prefix + `` + String(cal.document.consecutive).padStart(5, '0') + `
                    </p>
                    <p class="usr-ph-no">
                        <strong>Estimate: </strong> ` + cal.total + `
                    </p>
                </td>
                <td>
                    <a href="/#/moving/` + cal.id + `/estimate" onclick="swal.close();">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    </a>
                </td>
            </tr>`;
    }

    for (let cal of calendarDay['boxes_delivered']) {
        texto += ` <tr>
                <td>
                    <p class="user-name">` + cal.customer.last_name + `</p>
                    <p class="user-work">` + cal.customer.name + `</p>
                    <p class="user-work">` + cal.customer.phone + `</p>
                    <p class="user-work">` + cal.customer.email + `</p>
                    </td>
                <td>
                    <p class="usr-email-addr">
                        <strong>Service: </strong> ` + cal.service + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>Move Date: </strong>` + moment(cal.move_date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>From: </strong>` + cal.pickup.address.state + ` ` + cal.pickup.address.zip_code + `
                    </p>
                    <p class="usr-email-addr">
                        <strong>To: </strong>` + cal.delivery.address.state + ` ` + cal.delivery.address.zip_code + `
                    </p>
                </td>
                <td>
                    <p class="usr-location">
                        <strong>Libs: </strong> ` + cal.volume.libs + `
                    </p>
                    <p class="usr-location">
                        <strong>Cf: </strong>` + cal.volume.cubic_feets_selled + `
                    </p>
                    <p class="usr-location">
                        <strong>Miles: </strong>` + cal.volume.miles + `
                    </p>
                </td>
                <td>
                <p class="usr-ph-no">
                        <strong>Number: </strong> ` + cal.document.prefix + `` + String(cal.document.consecutive).padStart(5, '0') + `
                    </p>
                    <p class="usr-ph-no">
                        <strong>Estimate: </strong> ` + cal.total + `
                    </p>
                </td>
                <td>
                    <a href="/#/moving/` + cal.id + `/estimate" onclick="swal.close();">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    </a>
                </td>
            </tr>`;
    }
    texto += `
        </tbody>
    </table>
    `;
    swal({
        html: texto
    });
    jQuery('.swal2-popup').css('width', 'auto');
}


private load() {

    this.helperService.showLoadingMxpro360();
    this.workDepartmentsService
        .getAll()
        .then((response) => {
            this.allWorkDepartments = response;
        })
        .catch((error) => {

        })
        .finally(() => {
            //this.helperService.hideLoadingMxpro360();
        });

    this.loadEmployees();
    this.loadTruncks();
}

loadCalendar() {
    const data: any = {
        'full-view': '1',
        start: this.startDate,
        end: this.endDate,
        type_estimate: 'FREIGHT',
        user_id: '',
        work_department_id: '',
        status: '',
        move_type: '',
        statuses: '',
        service: '',
        carrier: '',
        job_status: ''
    };

    // if (this.zipCodePoint !== null && this.zipCodePoint.toString().length >= 5) {
    //     data.zip_code = this.zipCodePoint;
    // }

    this.helperService.showLoadingMxpro360();
    this.calendarService
        .getAllWithFullView(data)
        .then((response) => {
            this.calendar = {...response};
            this.fullCalendarData = {...response};
            this.initializeCalendar();

        })
        .catch((error) => {
            console.error('Error: ', error);
            this.helperService.hideLoadingMxpro360();

        })
        .finally(() => {
        });
}

filterConfirmed() {
    return new Promise((resolve) => {
        if (!this.displayConfirmed) {
            this.calendar = { ...this.fullCalendarData };
            resolve(true);
        } else {
            for (let i = this.calendar['pickups'].length - 1; i >= 0; i--) {
                if (!this.calendar['pickups'][i].pickup_day.confirm) {
                    this.calendar['pickups'].splice(i, 1);
                }
            }
            for (let i = this.calendar['packing_day'].length - 1; i >= 0; i--) {
                if (!this.calendar['packing_day'][i].pickup_day.confirm) {
                    this.calendar['packing_day'].splice(i, 1);
                }
            }
            for (let i = this.calendar['boxes_delivery_day'].length - 1; i >= 0; i--) {
                if (!this.calendar['boxes_delivery_day'][i].pickup_day.confirm) {
                    this.calendar['boxes_delivery_day'].splice(i, 1);
                }
            }
            for (let i = this.calendar['deliveries'].length - 1; i >= 0; i--) {
                if (!this.calendar['deliveries'][i].pickup_day.confirm) {
                    this.calendar['deliveries'].splice(i, 1);
                }
            }
            resolve(true);
        }
    });
}

setDisplayConfirmed() {
    this.displayConfirmed = !this.displayConfirmed;
    this.initializeCalendar();
}

async initializeCalendar() {
    this.arrayCalendar = [];
    this.checkClassicMode = this.jobStatus == '' ? this.checkClassicMode : false;

    // await this.filterConfirmed();
    jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
    if (this.checkPickUps && this.calendar['pickups']) {

        for (const cal of this.calendar['pickups']) {
            // if(cal.carrier && this.jobStatus == 'DISPLAY_UNASSIGNED_JOBS'){
            //     continue;
            // }

            let distanceToZipCode = '';
            if (!cal.box_delivery_day.confirm && cal.estimate.service !== 'AUTO_TRANSPORT') {
                let logo = '';
                
                for (const company of this.companies) {

                    if (cal.estimate.company_id === company.id) {
                        logo = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + company.logo;

                    }
                }
                this.calendario.id = cal.id + 'Pick_Up';

                let confirmed = '';
                let signature = '';
                let interstate = '';
                let carrier = '';
                let carrierSelect = '';

                if (cal.pickup_day.confirm) {
                    confirmed = 'calendar-icon-active';
                }

                if (!cal.estimate.esignature) {
                    signature = 'calendar-icon-active';
                }

                if (cal.estimate.service == 'LONG') {
                    interstate = 'calendar-icon-active';
                }

                if (cal.carrier) {
                    carrier = 'calendar-icon-active';
                }

                if (cal.distance_to_zip) {
                    distanceToZipCode = '<i class="fas fa-road"></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                }

                if (cal.carrier) {
                    carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                    if (cal.job_outsourcing.job_acceptance_doc == null) {
                        carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                    }
                }

                if(!cal.estimate.pickup.address.city){
                    cal.estimate.pickup.address.city = '';
                } else if (!cal.estimate.pickup.address.city.includes(',')) {
                    cal.estimate.pickup.address.city = ', ' + cal.estimate.pickup.address.city;
                }

                if(!cal.estimate.delivery.address.city){
                    cal.estimate.delivery.address.city = '';
                } else if (!cal.estimate.delivery.address.city.includes(',')) {
                    cal.estimate.delivery.address.city = ', ' + cal.estimate.delivery.address.city;
                }

                const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                
                this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a>' + '<i class="fas fa-user ml-2"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                    + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + cal.estimate.pickup.address.city + ', ' + cal.estimate.pickup.address.zip_code +  '</a><br> '
                    + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state +  cal.estimate.delivery.address.city + ', ' + cal.estimate.delivery.address.zip_code +  '</a><br> '
                    + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets_selled + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                    + distanceToZipCode
                    + carrierSelect
                    + ' <br> '
                    + '<div class="color-icon-job">' + ' '
                    + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' '
                    + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                    + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    /* + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                    + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar ' + carrier + ' "></i>' + ' ' */
                    + '<img src="' + logo + '" width="30px" style="margin-left: 42px;">'
                    + '</div>';

                this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;

                // Checkbox Classic Mode
                if (this.checkClassicMode){

                    this.calendario.start = moment(cal.estimate.pickup.range_start).format(moment.HTML5_FMT.DATE);
                    this.calendario.end = moment(cal.estimate.pickup.range_start).format(moment.HTML5_FMT.DATE);
                
                } else {

                    if (cal.pickup_day && cal.pickup_day.date !== null) {
                        this.calendario.start = moment(cal.pickup_day.date).format(moment.HTML5_FMT.DATE);
                        this.calendario.end = moment(cal.pickup_day.date).format(moment.HTML5_FMT.DATE);
                    } else {
                        this.calendario.start = moment(cal.estimate.pickup.range_start).format(moment.HTML5_FMT.DATE);
                        this.calendario.end = moment(cal.estimate.pickup.range_end).format(moment.HTML5_FMT.DATE);
                    }

                }                       

                // this.calendario.url = '/#/company/'+cal.estimate.company_id+'/moving/'+cal.estimate.id+'/estimate';
                this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-pickup' + ' ' + cal.estimate.id;

                this.calendario.estimate_id = cal.estimate.id;
                if (this.calendario.start) {
                    this.arrayCalendar.push(this.calendario);
                }

                this.calendario = new ScheduleCalendar();

            }
        }
    }
    if (this.checkPackDays && this.calendar['packing_day']) {
        for (const cal of this.calendar['packing_day']) {
            if(cal.carrier && !this.checkCarrier){
                continue;
            }

            if(!cal.carrier && this.checkCarrier){
                continue;
            }
            let distanceToZipCode = '';
            if (!cal.box_delivery_day.confirm && cal.estimate.service != 'AUTO_TRANSPORT') {
                let logo = '';
                for (const company of this.companies) {

                    if (cal.estimate.company_id == company.id) {
                        logo = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + company.logo;

                    }
                }
                this.calendario.id = cal.id + 'Pack_days';

                let confirmed = '';
                let signature = '';
                let interstate = '';
                let carrier = '';
                let carrierSelect = '';

                if (cal.pickup_day.confirm) {
                    confirmed = 'calendar-icon-active';
                }

                if (!cal.estimate.esignature) {
                    signature = 'calendar-icon-active';
                }

                if (cal.estimate.service == 'LONG') {
                    interstate = 'calendar-icon-active';
                }

                if (cal.carrier) {
                    carrier = 'calendar-icon-active';
                }

                if (cal.distance_to_zip) {
                    distanceToZipCode = '<i class="fas fa-road"></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                }

                if (cal.carrier) {
                    carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                    if (cal.job_outsourcing.job_acceptance_doc == null) {
                        carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                    }
                }
                
                
                if(!cal.estimate.pickup.address.city){
                    cal.estimate.pickup.address.city = '';
                } else if (!cal.estimate.pickup.address.city.includes(',')) {
                    cal.estimate.pickup.address.city = ', ' + cal.estimate.pickup.address.city;
                }

                if(!cal.estimate.delivery.address.city){
                    cal.estimate.delivery.address.city = '';
                } else if (!cal.estimate.delivery.address.city.includes(',')) {
                    cal.estimate.delivery.address.city = ', ' + cal.estimate.delivery.address.city;
                }

                const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a>' + '<i class="fas fa-user ml-2"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                    + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + cal.estimate.pickup.address.city + ', ' + cal.estimate.pickup.address.zip_code +  '</a><br> '
                    + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state +  cal.estimate.delivery.address.city + ', ' + cal.estimate.delivery.address.zip_code +  '</a><br> '
                    + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets_selled + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                    + distanceToZipCode
                    + carrierSelect
                    + ' <br> '
                    + '<div class="color-icon-job">' + ' '
                    + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' '
                    + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                    + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    /* + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                    + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar ' + carrier + ' "></i>' + ' ' */
                    + '<img src="' + logo + '" width="30px" style="margin-left: 42px;">'
                    + '</div>';

                this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                
                if (cal.pack_day && cal.pack_day.date !== null) {
                    this.calendario.start = moment(cal.pack_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                    this.calendario.end = moment(cal.pack_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                } else {
                    this.calendario.start = moment(cal.estimate.pickup.pack_day).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                    this.calendario.end = moment(cal.estimate.pickup.pack_day).format(moment.HTML5_FMT.DATE);
                }


                this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-packDay';
                
                this.calendario.estimate_id = cal.estimate.id;
                if (this.calendario.start) {
                    this.arrayCalendar.push(this.calendario);
                }
                this.calendario = new ScheduleCalendar();
            }
        }
    }

    if (this.checkBoxesDelivery && this.calendar['boxes_delivery_day']) {
        for (const cal of this.calendar['boxes_delivery_day']) {
            if(cal.carrier && !this.checkCarrier){
                continue;
            }

            if(!cal.carrier && this.checkCarrier){
                continue;
            }
            let distanceToZipCode = '';
            if (!cal.box_delivery_day.confirm && cal.estimate.service != 'AUTO_TRANSPORT') {
                let logo = '';
                for (const company of this.companies) {

                    if (cal.estimate.company_id == company.id) {
                        logo = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + company.logo;

                    }
                }
                this.calendario.id = cal.id + 'Boxes_Delivery';

                let confirmed = '';
                let signature = '';
                let interstate = '';
                let carrier = '';
                let carrierSelect = '';

                if (cal.pickup_day.confirm) {
                    confirmed = 'calendar-icon-active';
                }

                if (!cal.estimate.esignature) {
                    signature = 'calendar-icon-active';
                }

                if (cal.estimate.service == 'LONG') {
                    interstate = 'calendar-icon-active';
                }

                if (cal.carrier) {
                    carrier = 'calendar-icon-active';
                }

                if (cal.distance_to_zip) {
                    distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                }

                if (cal.carrier) {
                    carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                    if (cal.job_outsourcing.job_acceptance_doc == null) {
                        carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                    }
                }

              
                if(!cal.estimate.pickup.address.city){
                    cal.estimate.pickup.address.city = '';
                } else if (!cal.estimate.pickup.address.city.includes(',')) {
                    cal.estimate.pickup.address.city = ', ' + cal.estimate.pickup.address.city;
                }

                if(!cal.estimate.delivery.address.city){
                    cal.estimate.delivery.address.city = '';
                } else if (!cal.estimate.delivery.address.city.includes(',')) {
                    cal.estimate.delivery.address.city = ', ' + cal.estimate.delivery.address.city;
                }

                const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a>' + '<i class="fas fa-user ml-2"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                    + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + cal.estimate.pickup.address.city + ', ' + cal.estimate.pickup.address.zip_code +  '</a><br> '
                    + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state +  cal.estimate.delivery.address.city + ', ' + cal.estimate.delivery.address.zip_code +  '</a><br> '
                    + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets_selled + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                    + distanceToZipCode
                    + carrierSelect
                    + ' <br> '
                    + '<div class="color-icon-job">' + ' '
                    + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' '
                    + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                    + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    /* + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                    + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar ' + carrier + ' "></i>' + ' ' */
                    + '<img src="' + logo + '" width="30px" style="margin-left: 42px;">'
                    + '</div>';

                this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                                        
                if (cal.estimate.pickup && cal.estimate.pickup.boxes_delivery_day !== null) {
                    this.calendario.start = moment(cal.estimate.pickup.boxes_delivery_day)
                        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                    this.calendario.end = moment(cal.estimate.pickup.boxes_delivery_day)
                        .format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                } else {
                    this.calendario.start = moment(cal.estimate.boxes_delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                    this.calendario.end = moment(cal.estimate.boxes_delivery_day.date).format(moment.HTML5_FMT.DATE);
                }


                this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-boxDelivery';

                this.calendario.estimate_id = cal.estimate.id;
                if (this.calendario.start) {
                    this.arrayCalendar.push(this.calendario);
                }
                this.calendario = new ScheduleCalendar();
            }
        }
    }

    if (this.checkDelivery && this.calendar['deliveries']) {
        for (const cal of this.calendar['deliveries']) {
            if(cal.carrier && !this.checkCarrier){
                continue;
            }

            if(!cal.carrier && this.checkCarrier){
                continue;
            }
            let distanceToZipCode = '';
            if (!cal.box_delivery_day.confirm && cal.estimate.service != 'AUTO_TRANSPORT') {
                let logo = '';
                for (const company of this.companies) {

                    if (cal.estimate.company_id == company.id) {
                        logo = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + company.logo;

                    }
                }
                this.calendario.id = cal.id + 'Delivery';

                let confirmed = '';
                let signature = '';
                let interstate = '';
                let carrier = '';
                let carrierSelect = '';

                if (cal.pickup_day.confirm) {
                    confirmed = 'calendar-icon-active';
                }

                if (!cal.estimate.esignature) {
                    signature = 'calendar-icon-active';
                }

                if (cal.estimate.service == 'LONG') {
                    interstate = 'calendar-icon-active';
                }
                if (cal.carrier) {
                    carrier = 'calendar-icon-active';
                }

                if (cal.distance_to_zip) {
                    distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + cal.distance_to_zip + ' <br> ';
                }
                if (cal.carrier) {
                    carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + cal.carrier.name + ' <br> ';
                    if (cal.job_outsourcing.job_acceptance_doc == null) {
                        carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + cal.carrier.name + '</span>  <br> ';
                    }
                }

                if(!cal.estimate.pickup.address.city){
                    cal.estimate.pickup.address.city = '';
                } else if (!cal.estimate.pickup.address.city.includes(',')) {
                    cal.estimate.pickup.address.city = ', ' + cal.estimate.pickup.address.city;
                }

                if(!cal.estimate.delivery.address.city){
                    cal.estimate.delivery.address.city = '';
                } else if (!cal.estimate.delivery.address.city.includes(',')) {
                    cal.estimate.delivery.address.city = ', ' + cal.estimate.delivery.address.city;
                }
                const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + cal.estimate.company_id + '/moving/' + cal.estimate.id + '/estimate';
                this.calendario.description = '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + cal.estimate.document.code + '<a>' + '<i class="fas fa-user ml-2"></i> ' + cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name + ' <br> '
                    + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.pickup.address.state + cal.estimate.pickup.address.city + ', ' + cal.estimate.pickup.address.zip_code +  '</a><br> '
                    + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.estimate.pickup.address.state + '" pickup-zip="' + cal.estimate.pickup.address.zip_code + '" delivery-state="' + cal.estimate.delivery.address.state + '" delivery-zip="' + cal.estimate.delivery.address.zip_code + '">' + cal.estimate.delivery.address.state +  cal.estimate.delivery.address.city + ', ' + cal.estimate.delivery.address.zip_code +  '</a><br> '
                    + '<i class="fab fa-buffer"></i> ' + cal.estimate.volume.cubic_feets_selled + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.estimate.volume.miles) + ' miles ' + ' <br> '
                    + distanceToZipCode
                    + carrierSelect
                    + ' <br> '
                    + '<div class="color-icon-job">' + ' '
                    + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' '
                    + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                    + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    /* + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                    + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar ' + carrier + ' "></i>' + ' ' */
                    + '<img src="' + logo + '" width="30px" style="margin-left: 42px;">'
                    + '</div>';

                this.calendario.title = cal.estimate.customer.name + ' ' + cal.estimate.customer.last_name;
                
                // Checkbox Classic Mode
                if (this.checkClassicMode){
                    this.calendario.start = moment(cal.estimate.delivery.range_start).format(moment.HTML5_FMT.DATE);
                    this.calendario.end = moment(cal.estimate.delivery.range_start).format(moment.HTML5_FMT.DATE);
                }else{
                    if (cal.delivery_day && cal.delivery_day.date !== null && !this.displayConfirmed) {
                        this.calendario.start = moment(cal.delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        this.calendario.end = moment(cal.delivery_day.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                    } else {
                        this.calendario.start = moment(cal.estimate.delivery.range_start).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
                        this.calendario.end = moment(cal.estimate.delivery.range_end).format(moment.HTML5_FMT.DATE);
                    }
                }
                

                this.calendario.className = cal.statuses.general == 'WORK_CARRIED_OUT' ? 'calendar-work-carried-out' : 'calendar-color-delivery';

                this.calendario.estimate_id = cal.estimate.id;
                if (this.calendario.start) {
                    this.arrayCalendar.push(this.calendario);
                }
                this.calendario = new ScheduleCalendar();
            }
        }
    }
    if (this.calendar['crews']) {
        for (const crew of this.calendar['crews']) {
            let childJob = '';
            let childJobs = '';


            this.calendario.id = crew.id + 'crew';
            for (const crewJobs of crew.jobs) {
                let backgroundColor = '';
                let confirmed = '';
                let signature = '';
                let interstate = '';
                let carrier = '';
                let carrierSelect = '';
                let distanceToZipCode = '';
                let logo = '';
                for (const company of this.companies) {

                    if (crewJobs.estimate.company_id == company.id) {
                        logo = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + company.logo;

                    }
                }
                if (crewJobs.pickup_day.confirm) {
                    confirmed = 'calendar-icon-active';
                }

                if (!crewJobs.estimate.esignature) {
                    signature = 'calendar-icon-active';
                }

                if (crewJobs.estimate.service == 'LONG') {
                    interstate = 'calendar-icon-active';
                }
                if (crewJobs.carrier) {
                    carrier = 'calendar-icon-active';
                }

                if (crewJobs.distance_to_zip) {
                    distanceToZipCode = '<i class="fas fa-road"></i></i> Distance of zipcode: ' + crewJobs.distance_to_zip + ' <br> ';
                }

                if (crewJobs.carrier) {
                    carrierSelect = '<i class="fas fa-dolly" style="color: #930099"></i> ' + crewJobs.carrier.name + ' <br> ';
                    if (crewJobs.job_outsourcing.job_acceptance_doc == null) {
                        carrierSelect = '<i class="fas fa-dolly" style="color: red"></i> <span style="color: red"> ' + crewJobs.carrier.name + '</span>  <br> ';
                    }
                }

                if (crewJobs.crew_work_type == 'pickups') {
                    backgroundColor = 'calendar-color-pickup';
                } else if (crewJobs.crew_work_type == 'deliveries') {
                    backgroundColor = 'calendar-color-delivery';
                } else if (crewJobs.crew_work_type == 'boxes_delivery_day') {
                    backgroundColor = 'calendar-color-boxDelivery';
                } else if (crewJobs.crew_work_type == 'packing_day') {
                    backgroundColor = 'calendar-color-packDay';
                }
                
                const url = '/#/workspace/' + this.authService.workspaceSession.workspace.id + '/companyspace/' + crewJobs.estimate.company_id + '/moving/' + crewJobs.estimate.id + '/estimate';
                childJob = '<div class="' + backgroundColor + '" style="border-radius: 5px; padding: 2px; margin-top: 3px; color: black;">'
                    + '<i class="fas fa-briefcase"></i> <a href="' + url + '" target="_blank">' + crewJobs.estimate.document.code + '<a>' + '<i class="fas fa-user ml-2"></i> ' + crewJobs.estimate.customer.name + ' ' + crewJobs.estimate.customer.last_name + ' <br> '
                    + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + crewJobs.estimate.pickup.address.state + '" pickup-zip="' + crewJobs.estimate.pickup.address.zip_code + '" delivery-state="' + crewJobs.estimate.delivery.address.state + '" delivery-zip="' + crewJobs.estimate.delivery.address.zip_code + '">' + crewJobs.estimate.pickup.address.state + ', ' + crewJobs.estimate.pickup.address.zip_code + '</a><br> '
                    + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + crewJobs.estimate.pickup.address.state + '" pickup-zip="' + crewJobs.estimate.pickup.address.zip_code + '" delivery-state="' + crewJobs.estimate.delivery.address.state + '" delivery-zip="' + crewJobs.estimate.delivery.address.zip_code + '">' + crewJobs.estimate.delivery.address.state + ', ' + crewJobs.estimate.delivery.address.zip_code + '</a><br> '
                    + '<i class="fab fa-buffer"></i> ' + crewJobs.estimate.volume.cubic_feets_selled + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(crewJobs.estimate.volume.miles) + ' miles ' + ' <br> '

                    + ' <br> '
                    + '<div class="color-icon-job">' + ' '
                    + '<i title="Moving date confirmed" class="fas fa-calendar-check tooltipmxp tooltipmxp-calendar  ' + confirmed + '"></i>' + ' '
                    + '<i title="Customer`s electronic signature accepted" class="fas fa-file-signature  tooltipmxp tooltipmxp-calendar ' + signature + '"></i>' + ' '
                    + '<i title="Customer`s first payment accepted " class="fas fa-credit-card tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    + '<i title="Vehicle including on the estimate" class="fas fa-car tooltipmxp tooltipmxp-calendar "></i>' + ' '
                    /* + '<i title="Interstate move" class="fas fa-plane tooltipmxp tooltipmxp-calendar  ' + interstate + '"></i>' + ' '
                    + '<i title="Job took by a carrier" class="fas fa-truck tooltipmxp tooltipmxp-calendar ' + carrier + ' "></i>' + ' ' */
                    + '<img src="' + logo + '" width="30px" style="margin-left: 42px;">'
                    + '</div>'
                    + '</div>';

                childJobs += childJob

            }

            this.calendario.description = '<div class="color-icon-job">'
                + '<div style="text-align: center; color: #ffffff; font-size: 13px"> ' + crew.name + '</div> '
                + childJobs
                + '</div>';


            this.calendario.start = moment(crew.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
            this.calendario.end = moment(crew.date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

            this.calendario.className = 'calendar-color-crew';
            this.calendario.estimate_id = crew.id;
            if (this.calendario.start) {
                this.arrayCalendar.push(this.calendario);
            }
            this.calendario = new ScheduleCalendar();
        }
    }

    /* if (!jQuery('.icon-calendar')[0]) {
        jQuery('.fc-day-top').append(
            '<button class="button-highlight"> <svg xmlns="http://www.w3.org/2000/svg" width="24" class="icon-calendar" style="margin-right: 6px; color:#c15e17;" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-crosshair"><circle cx="12" cy="12" r="10"></circle><line x1="22" y1="12" x2="18" y2="12"></line><line x1="6" y1="12" x2="2" y2="12"></line><line x1="12" y1="6" x2="12" y2="2"></line><line x1="12" y1="22" x2="12" y2="18"></line></svg></button>');
    } */

    /* if (this.displayConfirmed) {

        jQuery('.button-highlight').remove();
    } */

    jQuery(this.divCalendar.nativeElement).fullCalendar('addEventSource', this.arrayCalendar);
    this.helperService.hideLoadingMxpro360();
}


getFilter() {
    const data: any = {
        'full-view': '1',
        start: this.startDate,
        end: this.endDate,
        type_estimate: 'FREIGHT',
        salesman_id: '',
        work_department_id: '',
        status: '',
        move_type: '',
        state_selected: '',
        statuses: '',
        service: '',
        carrier: ''
    };

    if (this.userId) {
        data.salesman_id = this.userId;
    }
    if (this.workDepartmentId) {
        data.work_department_id = this.workDepartmentId;
    }
    if (this.state) {
        data.status = this.state;
    }
    if (this.moveType) {
        data.move_type = this.moveType;
    }
    
    if (this.stateSelected) {
        data.state_selected = this.stateSelected;
    }
    if (this.jobStatus) {
        data.statuses = this.jobStatus;
    }
    if (this.service) {
        data.service = this.service;
    }
    if (this.carrierFilter) {
        data.carrier = this.carrierFilter;
    }

    this.helperService.showLoadingMxpro360();
    this.calendarService
        .getAllWithFullView(data)
        .then((response) => {
            
            this.calendar = response;
            this.fullCalendarData = response;
            this.hiddenCalendar = false;
            this.initializeCalendar();
        })
        .catch((error) => {
        })
        .finally(() => {
            //this.helperService.hideLoadingMxpro360();
        });
}

changeDate(event) {
    this.date = event;
}

setJob(id, date, dispatched, remark, type, confirm, typeModal, isAssigned: boolean) {
    // valida que siempre exista un dato en this.date;
    if (date == null) {
        this.date = moment(this.selectedDay).set("hour", 12).set("minute", 0).unix() * 1000;
    } else {
        this.selectedDay = date;
        this.date = date;
    }
    this.isAssigned = isAssigned;
    this.typeModal = typeModal;
    this.type = type;
    this.dispatched = dispatched;
    this.remark = remark;
    this.jobId = id;
    this.confirm = confirm;
    this.getChecked(id);
    this.loadJob(id);
    jQuery(this.modalExtra.nativeElement).modal('show');
    // this.loadCheckedTrucks();
}

/**
 * Carga el job para asignar el agent o carrier
 */
private async loadJob(id: string) {
    this.helperService.showLoadingMxpro360();
    this.jobsService
        .getById(id)
        .then((response) => {
            this.job = response;
            this.typeService = false;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

editTime() {
    let moving;
    switch (this.jobCrewEdited.crew_work_type) {
        case 'pickups':
            this.jobCrewEdited.pickup_day.range_start = this.rangeStart;
            this.jobCrewEdited.pickup_day.range_end = this.rangeEnd;
            const pickup_day = new JobAppoinment();
            pickup_day.confirm = this.jobCrewEdited.pickup_day.confirm;
            pickup_day.date = this.jobCrewEdited.pickup_day.date;
            pickup_day.dispatched = this.jobCrewEdited.pickup_day.dispatched;
            pickup_day.range_end = this.jobCrewEdited.pickup_day.range_end;
            pickup_day.range_start = this.jobCrewEdited.pickup_day.range_start;
            pickup_day.remarks = this.jobCrewEdited.pickup_day.remarks;
            pickup_day.workers_id = this.jobCrewEdited.pickup_day.workers_id;
            moving = {
                pickup_day
            };
            break;
        case 'packing_day':
            this.jobCrewEdited.pack_day.range_start = this.rangeStart;
            this.jobCrewEdited.pack_day.range_end = this.rangeEnd;

            const pack_day = new JobAppoinment();
            pack_day.confirm = this.jobCrewEdited.pack_day.confirm;
            pack_day.date = this.jobCrewEdited.pack_day.date;
            pack_day.dispatched = this.jobCrewEdited.pack_day.dispatched;
            pack_day.range_end = this.jobCrewEdited.pack_day.range_end;
            pack_day.range_start = this.jobCrewEdited.pack_day.range_start;
            pack_day.remarks = this.jobCrewEdited.pack_day.remarks;
            pack_day.workers_id = this.jobCrewEdited.pack_day.workers_id;
            moving = {
                pack_day
            };
            break;
        case 'boxes_delivery_day':
            this.jobCrewEdited.box_delivery_day.range_start = this.rangeStart;
            this.jobCrewEdited.box_delivery_day.range_end = this.rangeEnd;

            const box_delivery_day = new JobAppoinment();
            box_delivery_day.confirm = this.jobCrewEdited.box_delivery_day.confirm;
            box_delivery_day.date = this.jobCrewEdited.box_delivery_day.date;
            box_delivery_day.dispatched = this.jobCrewEdited.box_delivery_day.dispatched;
            box_delivery_day.range_end = this.jobCrewEdited.box_delivery_day.range_end;
            box_delivery_day.range_start = this.jobCrewEdited.box_delivery_day.range_start;
            box_delivery_day.remarks = this.jobCrewEdited.box_delivery_day.remarks;
            box_delivery_day.workers_id = this.jobCrewEdited.box_delivery_day.workers_id;
            moving = {
                box_delivery_day
            };
            break;
        case 'deliveries':
            this.jobCrewEdited.delivery_day.range_start = this.rangeStart;
            this.jobCrewEdited.delivery_day.range_end = this.rangeEnd;

            const delivery_day = new JobAppoinment();
            delivery_day.confirm = this.jobCrewEdited.delivery_day.confirm;
            delivery_day.date = this.jobCrewEdited.delivery_day.date;
            delivery_day.dispatched = this.jobCrewEdited.delivery_day.dispatched;
            delivery_day.range_end = this.jobCrewEdited.delivery_day.range_end;
            delivery_day.range_start = this.jobCrewEdited.delivery_day.range_start;
            delivery_day.remarks = this.jobCrewEdited.delivery_day.remarks;
            delivery_day.workers_id = this.jobCrewEdited.delivery_day.workers_id;
            moving = {
                delivery_day
            };
            break;

        default:
            break;
    }

    this.helperService.showLoadingMxpro360();

    this.jobsService
        .patchEntity(this.jobCrewEdited.id, moving)
        .finally(() => {
            jQuery(this.modalTimeCrew.nativeElement).modal('hide');
            this.helperService.hideLoadingMxpro360();
        });
}

update(removeAppointment: boolean) {
    let moving: any;
    let dateText = null
    let dateNumber = null;
    if (!removeAppointment) {
        dateText = moment(this.date).format(moment.HTML5_FMT.DATE);
        dateNumber = moment(dateText).set("hour", 12).set("minute", 0).unix() * 1000;
    }
    if (this.type === 'pickups') {
        // Se crea el objeto pickup
        const pickup = new JobAppoinment();
        pickup.confirm = this.confirm;
        pickup.date_text = dateText;
        pickup.date = dateNumber;
        pickup.dispatched = this.dispatched;
        pickup.range_end = this.rangeEnd;
        pickup.range_start = this.rangeStart;
        pickup.remarks = this.remark;
        pickup.workers_id = this.workers;
        moving = {
            pickup_day: pickup
        };
    }

    if (this.type === 'deliveries') {
        const delivery = new JobAppoinment();
        delivery.date_text = dateText;
        delivery.date = dateNumber;
        delivery.dispatched = this.dispatched;
        delivery.remarks = this.remark;
        delivery.confirm = this.confirm;
        delivery.workers_id = this.workers;
        delivery.range_start = this.rangeStart;
        delivery.range_end = this.rangeEnd;
        moving = {
            delivery_day: delivery
        };
    }

    if (this.type === 'boxes_delivery_day') {
        const box_delivery_day = new JobAppoinment();
        box_delivery_day.date_text = dateText;
        box_delivery_day.date = dateNumber;
        box_delivery_day.dispatched = this.dispatched;
        box_delivery_day.remarks = this.remark;
        box_delivery_day.confirm = this.confirm;
        box_delivery_day.workers_id = this.workers;
        box_delivery_day.range_start = this.rangeStart;
        box_delivery_day.range_end = this.rangeEnd;
        moving = {
            box_delivery_day
        };
    }

    if (this.type === 'pack_day') {
        const pack_day = new JobAppoinment();
        pack_day.date_text = dateText;
        pack_day.date = dateNumber;
        pack_day.dispatched = this.dispatched;
        pack_day.remarks = this.remark;
        pack_day.confirm = this.confirm;
        pack_day.workers_id = this.workers;
        pack_day.range_start = this.rangeStart;
        pack_day.range_end = this.rangeEnd;
        moving = {
            pack_day
        };
    }

    this.helperService.showLoadingMxpro360();

    this.jobsService
        .patchEntity(this.jobId, moving)
        .then((response) => {

            this.jobId = '';
            this.date = 0;
            this.dispatched = false;
            this.isAssigned = false;
            this.remark = '';
            this.type = '';
            this.loadCalendar();
            this.loadDataDay();
            jQuery(this.modalExtra.nativeElement).modal('hide');
            jQuery(this.modalRemark.nativeElement).modal('hide');

        })
        .catch((error) => {
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

backCalendar() {
    this.hiddenCalendar = false;
    this.loadCalendar();
}

/**
 * Carga la informacion de los empleados
 */
private loadEmployees() {
    this.helperService.showLoadingMxpro360();
    let that = this;
    this.employeesService
        .getAll()
        .then((response) => {
            this.employeesView = response;
            this.employees = response;

            for (const employee of this.employees) {
                if (employee.job_position == 'FOREMAN' || employee.job_position == 'DRIVER' || employee.job_position == 'WORKER') {
                    that.crewEmployees.push(employee);
                }
            }
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

/**
 * Carga la informacion de los trunck
 */
private loadTruncks() {
    this.helperService.showLoadingMxpro360();

    this.vehiclesService
        .getAll()
        .then((response) => {
            // this.employeesView = response;
            this.truncks = response;

        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            //this.helperService.hideLoadingMxpro360();
        });
}

/**
 * Agrega los ids de los empleados
 */
getEmployeesTeam(employeeId: string) {
    this.workers.push(employeeId);
}

public getTrucksTeam(truckId: string) {
    this.typeService = !this.typeService;
    if (this.typeService) {
        this.idsTrucks.push(truckId);
        // this.checkedTrucks();
    } else {
        for (let index = 0; index < this.idsTrucks.length; index++) {
            if (this.idsTrucks[index] === truckId) {
                this.idsTrucks.splice(index, 1);
                break;
            }
        }
    }
}

public checkedTrucks(): void {
    for (let index = 0; index < this.idsTrucks.length; index++) {
        for (let i = 0; i < this.truncks.length; i++) {
            if (this.truncks[i].id === this.idsTrucks[index]) {
                this.typeService = true;
            } else {
                this.typeService = false;
            }
        }
    }
}

/**
 * Checkea o des checkea un item del listado de empleados
 * @param employeeId Id del empleado
 */
getChecked(employeeId: string) {
    this.jobsService.getById(employeeId).then((response) => {
        for (const employee of this.employeesView) {
            employee.checked = response.pickup_day.workers_id.indexOf(employee.id) >= 0 ||
                response.delivery_day.workers_id.indexOf(employee.id) >= 0 ||
                response.pack_day.workers_id.indexOf(employee.id) >= 0 ||
                response.box_delivery_day.workers_id.indexOf(employee.id) >= 0;
            if (employee.checked) {
                if (this.type === 'boxes_delivery_day') {
                    for (let index = 0; index < response.box_delivery_day.workers_id.length; index++) {
                        this.workers.push(response.box_delivery_day.workers_id[index]);
                    }

                } else if (this.type === 'pickups') {
                    for (let index = 0; index < response.pickup_day.workers_id.length; index++) {
                        this.workers.push(response.pickup_day.workers_id[index]);
                    }
                } else if (this.type === 'deliveries') {
                    for (let index = 0; index < response.delivery_day.workers_id.length; index++) {
                        this.workers.push(response.delivery_day.workers_id[index]);
                    }
                } else if (this.type === 'pack_day') {
                    for (let index = 0; index < response.pack_day.workers_id.length; index++) {
                        this.workers.push(response.pack_day.workers_id[index]);
                    }
                }
            }
        }
    });
}

/**
 * Abre el modal de carriers o agentes
 *
 * @param title Titulo del modal
 */
public openModalCarriers(title: string, idJob: string): void {
    this.loadJob(idJob);
    this.titleModalCarrier = title;
    jQuery(this.modalCarriers.nativeElement).modal('show');
}

/**
 * Carga los carriers
 */
private loadCarriers() {
    this.helperService.showLoadingMxpro360();
    this.carrierService
        .getAll()
        .then((response) => {
            this.carriers = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

/**
 * Carga los stados
 */
private loadStates() {
    this.zonesService
        .getStates()
        .then((response) => {
            //this.carriers = response;
            this.zonesStates = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
        });
}

/**
 * Carga las Companies
 */
private loadCompanies() {
    this.helperService.showLoadingMxpro360();
    this.companiesService
        .getAll()
        .then((response) => {
            this.companies = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

/**
 * Cierra el modal de carriers
 */
public closeModalCarriers(): void {
    // this.job.carrier = new Carrier();
    // this.job.agent = new Carrier();
    jQuery(this.modalCarriers.nativeElement).modal('hide');
}

/**
 * Dependiendo si es carrier o agente, se procede aguardar
 */
public saveCarrierAndAgent(): void {
    if (this.titleModalCarrier === 'Agent') {
        this.saveAgent();
    } else if (this.titleModalCarrier === 'Carrier') {
        this.saveCarrier();
    }
}

/**
 * Realiza el guardado del carrier
 */
private saveCarrier(): void {
    // this.helperService.showLoadingMxpro360();
    // this.jobsService
    //     .patchEntity(this.job.id, { carrier: this.job.carrier })
    //     .then((response) => {
    //         jQuery(this.modalCarriers.nativeElement).modal('hide');
    //         this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
    //     })
    //     .catch((error) => {
    //         this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
    //         console.error('error', error);
    //     })
    //     .finally(() => {
    //         this.helperService.hideLoadingMxpro360();
    //     });
}

/**
 * Realiza el guardado del agente
 */
private async saveAgent(): Promise<void> {

    // this.helperService.showLoadingMxpro360();

    // // Se verifica que exista un carrier antes de guardar el agente
    // await this.validateCarrier();

    // this.jobsService
    //     .patchEntity(this.job.id, { agent: this.job.agent })
    //     .then((response) => {
    //         jQuery(this.modalCarriers.nativeElement).modal('hide');
    //         this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
    //     })
    //     .catch((error) => {
    //         this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
    //         console.error('error', error);
    //     })
    //     .finally(() => {
    //         this.helperService.hideLoadingMxpro360();
    //     });
}

/**
 * Valida que exista el carrier un carrier y lo guarda en caso de no existir
 */
private validateCarrier(): Promise<any> {
    return new Promise((resolve) => {
        // if (this.job.carrier.name === null && this.job.carrier.dot === null &&
        //     this.job.carrier.address.zip_code === null && this.job.carrier.address.country === null) {

        //     this.job.carrier.name = this.authService.workspaceSession.company.name;
        //     this.job.carrier.address = this.authService.workspaceSession.company.address;
        //     this.job.carrier.dot = this.authService.workspaceSession.company.dot;
        //     this.job.carrier.website = this.authService.workspaceSession.company.website;

        //     resolve(this.saveCarrier());
        // } else {
           
        // }
         resolve(true);
    });

}

/**
 * Asigna el agente o el carrier en el job
 *
 * @param carrier es el carrier o agente
 */
public selectCarrier(carrier: Carrier, sendAcceptance): void {
    this.carrier = carrier;

    jQuery(this.modalCarriers.nativeElement).modal('hide');
    this.openEmailsModal();

    this.jobSelected.job_outsourcing = new JobOutsourcing();
    this.jobSelected.job_outsourcing.carrier_id = '' + carrier.id;
    this.jobSelected.job_outsourcing.type = 'ALL';
    this.jobSelected.job_outsourcing.status = 'PENDING_SIGNATURE';
    this.jobSelected.job_outsourcing.special_conditions = this.specialConditions;

    const data = {
        job_outsourcing: this.jobSelected.job_outsourcing
    };

    this.helperService.showLoadingMxpro360();

    this.jobOutsourcingsService
        .assignOutsourcingWorkspace(this.jobSelected.id, data, sendAcceptance)
        .then((response) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            // this.load(this.job.id);
        })
        .catch((error) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

/**
 * Abre el modal del detalle de la firma
 *
 * @param signature Objeto que contiene el detalle de la firma
 */
openEmailsModal(): void {
    this.helperService.showLoadingMxpro360();
    this.carriersContactsCompanyService
        .getContactsByWorkspaceCarrier(this.carrier.id, this.jobSelected.company_id)
        .then((response) => {

            // this.carrierContacts = response;
            // if (this.carrierContacts.length > 0) {
            //     jQuery(this.emailsModal.nativeElement).modal('show');
            // }
        })
        .catch((error) => {

        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}


public openModalAddDailyCrew(newCrew) {
    if (newCrew) {
        this.crew = new DailyCrew();
    }
    jQuery(this.modalAddDailyCrew.nativeElement).modal('show');
}

dailyCrewAssign(id, type, crew) {
    let jobCrew = {
        job_id: id,
        work_type: type
    };
    crew.jobs_id.push(jobCrew);
    this.helperService.showLoadingMxpro360();
    this.dailyCrewService
        .save(crew)
        .then((response) => {
            this.loadDataDay();
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}


dailyCrewCreate() {
    this.helperService.showLoadingMxpro360();
    this.crew.date_text = moment(this.selectedDay).format('YYYY-MM-DD');
    this.crew.date = moment(this.crew.date_text).set("hour", 12).set("minute", 0).unix() * 1000;
    this.dailyCrewService
        .save(this.crew)
        .then((response) => {
            this.loadDataDay();
            this.crew = new DailyCrew()
            jQuery(this.modalAddDailyCrew.nativeElement).modal('hide');
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

setAppintmentTime(job) {
    this.currentCrewType = job.crew_work_type;
    this.jobCrewEdited = job;
    switch (job.crew_work_type) {
        case 'pickups':
            this.rangeStart = job.pickup_day.range_start;
            this.rangeEnd = job.pickup_day.range_end;
            break;
        case 'packing_day':
            this.rangeStart = job.pack_day.range_start;
            this.rangeEnd = job.pack_day.range_end;
            break;
        case 'boxes_delivery_day':
            this.rangeStart = job.box_delivery_day.range_start;
            this.rangeEnd = job.box_delivery_day.range_end;
            break;
        case 'deliveries':
            this.rangeStart = job.delivery_day.range_start;
            this.rangeEnd = job.delivery_day.range_end;
            break;
        default:
            break;
    }
    jQuery(this.modalTimeCrew.nativeElement).modal('show');
}

dailyCrewUnassign(id, crew) {
    this.helperService.showLoadingMxpro360();
    for (let index = 0; index < crew.jobs.length; index++) {
        if (crew.jobs_id[index].job_id === id) {
            crew.jobs_id.splice(index, 1);
            break;
        }
    }

    this.dailyCrewService
        .save(crew)
        .then((response) => {
            this.loadDataDay();
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            //this.helperService.hideLoadingMxpro360();
        });
}

dailyCrewDelete(id) {
    this.helperService.showLoadingMxpro360();
    this.dailyCrewService
        .remove(id)
        .then((response) => {
            this.loadDataDay();
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            //this.helperService.hideLoadingMxpro360();
        });
}



/**
 * Metodo que abre un nuevo tab al dar click en un estimate
 *
 * @param estimateId Id del estimate a abrir
 */
openNewTab(estimateId, companyId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewEstimateCalendar(estimateId, companyId);
}


nextDate() {
    this.selectedDay = moment(this.calendarSelectedDay).add(1, 'd').unix() * 1000;
    this.calendarSelectedDay = moment(this.calendarSelectedDay).add(1, 'd').unix() * 1000;
    this.startDay = '' + moment(Number(this.startDay)).add(1, 'd').unix() * 1000;
    this.endDay = '' + moment(Number(this.endDay)).add(1, 'd').unix() * 1000;
    this.loadDataDay();
}
backDate() {
    this.selectedDay = moment(this.calendarSelectedDay).subtract(1, 'd').unix() * 1000;
    this.calendarSelectedDay = moment(this.calendarSelectedDay).subtract(1, 'd').unix() * 1000;
    this.startDay = '' + moment(Number(this.startDay)).subtract(1, 'd').unix() * 1000;
    this.endDay = '' + moment(Number(this.endDay)).subtract(1, 'd').unix() * 1000;
    this.loadDataDay();
}

async initializeMap(pickup_state, pickup_zip_code, delivery_state, delivery_zip_code): Promise<void> {
    jQuery(this.modalMap.nativeElement).modal('show');
    const THIS = this;
    // Punto inicial del recorrido
    let pointA;
    // Punto final del recorrido
    let pointB;
    // Si no hay direcciones guardadas, se busca por el zipcode guardado para indicar el estado de la route delivery
    let pointsPickup;
    let pointsDelivery;
    pointsPickup = await this.getLatLang(pickup_zip_code);
    pointsDelivery = await this.getLatLang(delivery_zip_code);
    pointA = new google.maps.LatLng(pointsPickup.lat, pointsPickup.lng);
    pointB = new google.maps.LatLng(pointsDelivery.lat, pointsDelivery.lng);

    this.map = new google.maps.Map(
        this.mapView.nativeElement as HTMLElement,
        {
            zoom: 3,
            center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            zoomControl: true
        });

    // Instantiate a directions service.
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({
        map: this.map
    });

    // Se crean los marcadores
    const markerA = new google.maps.Marker({
        position: pointA,
        map: this.map
    });

    const markerB = new google.maps.Marker({
        position: pointB,
        map: this.map
    });

    // Se inicializa el arreglo que dibuja la ruta en el mapa de acuerdo a los puntos
    // establecidos por los puntos de parada
    const waypoints = [];

    // Se obtiene la ruta entre los puntos inciales, de parada y finales
    await this.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints);

    let address = '';
    let addressFrom = '';
    let addressTo = '';

    // Se obtienen las direcciones de recogida y entrega
    addressFrom = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');
    addressTo = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');

    // Se crea la informacion de las direcciones para mostrarlas en el mapa
    const card = document.createElement('div');
    const container = document.createElement('div');
    const titleFrom = document.createTextNode('* From: ');
    const brTitleFrom = document.createElement('br');
    const textFrom = document.createTextNode('Street: ' + addressFrom[0] + ', City: ' +
        addressFrom[1] + ', State: ' + addressFrom[2]);

    const titleTo = document.createTextNode('* To: ');
    const brTitleTo = document.createElement('br');
    const textTo = document.createTextNode('Street: ' + addressTo[0] + ', City: ' +
        addressTo[1] + ', State: ' + addressTo[2]);

    card.setAttribute('id', 'pac-card');
    container.setAttribute('id', 'pac-container');
    container.appendChild(titleFrom);
    container.appendChild(brTitleFrom);
    container.appendChild(textFrom);

    container.appendChild(document.createElement('br'));

    container.appendChild(document.createElement('br'));
    container.appendChild(document.createElement('br'));
    container.appendChild(titleTo);
    container.appendChild(brTitleTo);
    container.appendChild(textTo);
    card.appendChild(container);
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(card);

    // Se valida si hay un infowindow abierto, este sea cerrado
    function showAddresses() {
        if (THIS.infowindow) {
            THIS.infowindow.close();
        }
    }

    showAddresses();
    // Evento del click en el mapa
    THIS.map.addListener('click', (e) => {
        showAddresses();
    });

    google.maps.event.addListener(markerA, 'click', async () => {
        address = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');

        if (THIS.infowindow) {
            THIS.infowindow.close();
        }

        // Se dibuja en el infowindow la informacion
        const contentString =
            '<div id="content">' +
            '<b class="text-center">FROM</b>' +
            '<p> Street: ' + address[0] + '</p>' +
            '<p> City: ' + address[1] + '</p>' +
            '<p> State: ' + address[2] + '</p>' +
            '<p> Country: ' + address[3] + '</p>' +
            '</div>';

        THIS.infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        THIS.infowindow.setPosition({
            lat: pointA.lat(),
            lng: pointA.lng()
        });
        THIS.infowindow.open(THIS.map);
    });

    google.maps.event.addListener(markerB, 'click', async () => {
        address = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');
        if (THIS.infowindow) {
            THIS.infowindow.close();
        }
        const contentString =
            '<div id="content">' +
            '<b class="text-center">TO</b>' +
            '<p> Street: ' + address[0] + '</p>' +
            '<p> City: ' + address[1] + '</p>' +
            '<p> State: ' + address[2] + '</p>' +
            '<p> Country: ' + address[3] + '</p>' +
            '</div>';

        THIS.infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        THIS.infowindow.setPosition({
            lat: pointB.lat(),
            lng: pointB.lng()
        });
        THIS.infowindow.open(THIS.map);
    });

}
/**
 * Obtiene corrdenadas de un zipcode
 *
 * @param zipcode Zipcode a buscar
 */
private getLatLang(zipcode) {
    return new Promise((resolve, reject) => {
        let lat: string;
        let lng: string;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: 'zipcode ' + zipcode }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                lat = results[0].geometry.location.lat();
                lng = results[0].geometry.location.lng();
                resolve({ lat, lng });
            } else {
                reject('Request failed.');
            }
        });
    });
}

/**
* Obtiene la ruta con los puntos de origen, puntos de parada (si hay) y el destino
*/
private calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints): Promise<any> {

    return new Promise((resolve) => {
        directionsService.route({
            origin: pointA,
            destination: pointB,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING
        }, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
                directionsDisplay.setOptions({ suppressMarkers: true });
                resolve(true);
            } else {
                resolve(false);
            }
        });
    });
}

getDistances() {
    if (this.zipCodePoint.toString().length >= 5) {
        this.helperService.showLoadingMxpro360();
        // this.loadCalendar();  
        const calendarData = this.fullCalendarData;
        const zipcode = this.zipCodePoint;
        const apiUrl = environment.api.workspace;
        const authorization = this.authService.session.token;
        const that = this;
        if (typeof Worker !== 'undefined') {
            // Se crea el worker
            const worker = new Worker(new URL('src/app/workers/calendar.worker', import.meta.url), { type: 'module', name: 'calendar-worker' });
            // Se envian los datos de busqueda al worker
            worker.postMessage({ calendarData, zipcode, apiUrl, authorization });
            // Se recibe el resultado del worker y se resuelve la promesa con la busqueda realizada
            worker.onmessage = (data) => {
                that.calendar = data.data;
                that.fullCalendarData = data.data;
                that.initializeCalendar();
                // Se agrega un temporizador para retornar los resultados en la vista
                setTimeout(() => {
                }, 200);
            };
        }
    }
}

public openModalRemark(id, date, dispatched, remark, type, confirm, typeModal): void {
    jQuery(this.modalRemark.nativeElement).modal('show');
    this.typeModal = typeModal;
    this.type = type;
    this.date = date;
    this.dispatched = dispatched;
    this.remark = remark;
    this.jobId = id;
    this.confirm = confirm;

    this.getChecked(id);
    this.loadJob(id);
}

public setCarriersModal(title: string): void {
    jQuery(this.modalInformationCarriers.nativeElement).modal('hide');

    if (title === 'Carrier') {
        this.viewSectionModalCarrier = 'INTRO';
    }
    this.titleModalCarrier = title;
    jQuery(this.modalCarriers.nativeElement).modal('show');
}

public closeCarriersModal(): void {
    this.carrier = new Carrier();
    jQuery(this.modalCarriers.nativeElement).modal('hide');
    // this.loadAllData(this.job.id);
    this.specialConditions = '';
}


changeCarrierBalanceOffer() {

    this.carrierBalancePercentage = (this.carrierBalanceOffer * 100) / this.jobSelected.estimate.total;
    this.carrierBalancePercentage = Number(this.carrierBalancePercentage.toFixed(2));
}

public showModalInformationCarriers(estimate) {
    this.jobSelected = estimate;
    this.carrierBalancePercentage = this.carrierBalancePercentage ? this.carrierBalancePercentage : 67;
    setTimeout(() => {
        this.carrierBalanceOffer = this.jobSelected.estimate.total * (this.carrierBalancePercentage / 100);
        this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
    }, 100);
    this.carrierBalanceOffer = this.jobSelected.estimate.total * (this.carrierBalancePercentage / 100);
    this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
    this.titleModalCarrier = 'Carrier';
    jQuery(this.modalInformationCarriers.nativeElement).modal('show');
}

public closeModalInformationCarriers(): void {
    jQuery(this.modalInformationCarriers.nativeElement).modal('hide');
    // this.loadAllData(this.job.id);
    this.specialConditions = '';
}


public selectAgent(agent: Carrier): void {

    this.agent = agent;

    jQuery(this.modalCarriers.nativeElement).modal('hide');

    const jobOutsourcing = new JobOutsourcing();
    jobOutsourcing.carrier_id = '' + agent.id;
    jobOutsourcing.type = 'ALL';
    jobOutsourcing.status = 'PENDING_SIGNATURE';
    jobOutsourcing.special_conditions = this.specialConditions;
    this.updateAgentCarrier();

}

updateAgentCarrier() {
    const data = {
        job_outsourcing: this.jobSelected.job_outsourcing
    };

    this.jobOutsourcingsService
        .put(data, this.job.id)
        .then((response) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            // this.load(this.job.id);
        })
        .catch((error) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

/**
 * Metodo que publica el job a los carriers
 */
public postOnLoadBoard(type) {
    const jobOutsourcing = new JobOutsourcing();
    jobOutsourcing.carrier_id = null;
    jobOutsourcing.type = 'ALL';
    jobOutsourcing.status = 'WAITING_FOR';
    jobOutsourcing.special_conditions = this.specialConditions;

    if (type === 'Agent') {
    } else {
        this.jobSelected.job_outsourcing = jobOutsourcing;
    }

    jQuery(this.modalCarriers.nativeElement).modal('hide');

    const data = {
        job_outsourcing: this.jobSelected.job_outsourcing
    };

    this.jobOutsourcingsService
        .putWorspace(data, this.jobSelected.id, this.jobSelected.company_id)
        .then((response) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            // this.load(this.job.id);
        })
        .catch((error) => {
            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
}

public deleteJobOutsourcing(target, estimate) {
    this.jobSelected = estimate;
    this.helperService.showLoadingMxpro360();

    const data = {
        job_outsourcing: this.jobSelected.job_outsourcing
    };

    if (target === 'Agent') {
        this.agent = null;
    } else {
        this.jobOutsourcingsService
            .deleteOutsourcingWorspace(this.jobSelected.id, data, this.jobSelected.company_id)
            .then((response) => {
                
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                // this.load(this.job.id);

            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
}


public openOutsourcingNewTab(company, estimate) {
    this.helperService.openOutsourcingEstimate(estimate, company);
}


/* Status Operation Filter - Fixed - Scroll */
calculateDivTopDistance() {
    const statusOperationFilter = document.getElementById("status_operation_filter");
    if (statusOperationFilter) {
      const boundingBox = statusOperationFilter.getBoundingClientRect();
      this.divTopDistance = boundingBox.top + window.scrollY;
    }
}

checkScroll() {
    const currentScroll = window.scrollY || document.documentElement.scrollTop;
    
    if (!this.isFixed && currentScroll >= this.divTopDistance) {
      this.isFixed = true;
      const statusOperationFilter = document.getElementById("status_operation_filter");
      if (statusOperationFilter) {
        statusOperationFilter.classList.add("fijo");
      }
      window.removeEventListener('scroll', this.checkScroll.bind(this));
    } else if (this.isFixed && currentScroll < this.divTopDistance - this.scrollThreshold) {
      this.isFixed = false;
      const statusOperationFilter = document.getElementById("status_operation_filter");
      if (statusOperationFilter) {
        statusOperationFilter.classList.remove("fijo");
      }
      window.addEventListener('scroll', this.checkScroll.bind(this));
    }
}
/* Status Operation Filter - Fixed - Scroll */


/* Reset Select2 */
resetSelect2() {

    /* Se restable el valor de los select */
    this.zipCodePoint = null;
    this.userId = '';
    this.workDepartmentId = '';
    this.state = '';
    this.moveType = '';
    this.stateSelected = '';
    this.jobStatus = '';
    this.service = '';
    this.carrierFilter = '';

    // Obtenemos el elemento del select2 y restablece su valor
    jQuery(this.elRef.nativeElement).find('.filter-section select').val('').trigger('change');

    // Obtenemos el elemento input y restablece su valor
    jQuery(this.elRef.nativeElement).find('.zip-code-distance input').val('').trigger('change');

    // Llamamos la función "getFilter"
    this.getFilter();
    
}

}
