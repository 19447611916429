<div class="layout-spacing layout-top-spacing">

    <div class="row" [ngClass]="{'pt-3': quickAssign}">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 layout-spacing">
            <h3>
                Jobs
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Jobs
                    </li>
                </ol>
            </nav>
        </div>

        <!-- Quick Assign -->
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-spacing align-self-center block-quick-assign">
            <div class="col-lg-6 col-xl-6 pl-0 align-self-center text-right">
                <button *ngIf="quickAssign" (click)="toggleQuickAssign()" class="btn btn-dark btn-activate-quick-assign-dark" data-toggle="modal">
                    Deactivate Quick Assign
                </button>
                <button *ngIf="!quickAssign" (click)="toggleQuickAssign()" class="btn btn-new" data-toggle="modal">
                    Activate Quick Assign
                </button>
            </div>     
            <div class="col-lg-6 col-xl-6 pl-0 qa-select-carrier" *ngIf="quickAssign">
                <label class="control-label">Select Carrier</label>
                <select [appSelect2] [theme]="'mxp360-form'" name="service" id="service" [(ngModel)]="quickAssignCarrier" class="form-control">
                    <option value="IN_HOUSE">In House</option>
                    <option [value]="carrier.id" *ngFor="let carrier of carriers">{{carrier.name}}</option>
                </select>
            </div>
        </div>

        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- Filter -->
    <div class="row col-lg-12 col-md-12 jobs-date-filter collapse show" id="collapseFilter">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <app-date-filter [defaultSelectDateType]="4" [defaultDateStart]="betweenDates.dateStart" [defaultDateEnd]="betweenDates.dateEnd"
            [isMonthBefore]="false" class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        
            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-lg-3 col-xl-3 pl-0">
                    <label class="control-label">Carrier</label>
                    <select [appSelect2] [theme]="'mxp360-form'" [(ngModel)]="filterData.carrier" name="di_carrier_name" id="di_carrier_name"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option *ngFor="let carrier of carriers" value="{{carrier.id}}">
                            {{carrier.name}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-3 col-xl-3 pl-0">
                    <label class="control-label">Allocation</label>
                    <select name="service" id="service" class="form-control" [(ngModel)]="filterData.allocation">
                        <option value="" selected="selected">Select All</option>
                        <option value="UNASSIGNED" >Unassigned</option>
                        <option value="ASSIGNED_NOT_SIGNED" >Assigned Not Signed</option>
                        <option value="IN_HOUSE" >In House</option>
                    </select>
                </div>
                <div class="col-lg-3 col-xl-3 pl-0">
                    <label class="control-label">Pickup State</label>
                    <select name="pickup_state" id="pickup_state" [(ngModel)]="filterData.pickup_state"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>          
                <div class="col-lg-3 col-xl-3 pl-0">
                    <label class="control-label">Delivery State</label>
                    <select name="delivery_state" id="delivery_state" [(ngModel)]="filterData.delivery_state"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="AK">Alaska</option>
                        <option value="AL">Alabama</option>
                        <option value="AR">Arkansas</option>
                        <option value="AZ">Arizona</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="IA">Iowa</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MD">Maryland</option>
                        <option value="ME">Maine</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MO">Missouri</option>
                        <option value="MS">Mississippi</option>
                        <option value="MT">Montana</option>
                        <option value="NC">North-carolina</option>
                        <option value="ND">North-dakota</option>
                        <option value="NE">Nebraska</option>
                        <option value="NH">New-hampshire</option>
                        <option value="NJ">New-jersey</option>
                        <option value="NM">New-mexico</option>
                        <option value="NV">Nevada</option>
                        <option value="NY">New-york</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode-island</option>
                        <option value="SC">South-carolina</option>
                        <option value="SD">South-dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VA">Virginia</option>
                        <option value="VT">Vermont</option>
                        <option value="WA">Washington</option>
                        <option value="NM">Wisconsin</option>
                        <option value="WV">West-virginia</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>          
            </div>

            <div class="row col-lg-12 col-md-12 block-filter">                    
                <div class="col-lg-3 col-xl-3 pl-0">
                    <label class="control-label">Service</label>
                    <select name="service" id="service" [(ngModel)]="filterData.service" class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option value="LOCAL">Local Distance</option>
                        <option selected value="LONG">Long Distance</option>
                        <option value="AUTO_TRANSPORT">Auto Transport</option>
                        <option value="FREIGHT">Freight</option>
                    </select>
                </div>
                <div class="col-lg-3 col-xl-3 pl-0">
                    <div>
                        <label class="control-label">Sort By</label>
                        <select name="sort_by" [(ngModel)]="sort" (change)="sortBy()" class="form-control">
                            <option value="1">Number Asc</option>
                            <option value="2">Number Desc</option>
                            <option value="3">Customer Asc</option>
                            <option value="4">Customer Desc</option>
                            <option value="5">Pickup Date Asc</option>
                            <option value="6">Pickup Date Desc</option>
                            <option value="7">Created Date Asc</option>
                            <option value="8">Created Date Desc</option>
                            <option value="9">Destination Asc</option>
                            <option value="10">Destination Desc</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-3 col-xl-3 pl-0">
                    <label class="control-label">Filter Date For</label>
                    <select name="filter_date_for" id="filter_date_for" [(ngModel)]="filterData.filter_date_for"
                        class="form-control">
                        <option value="PICKUP">Pickup Date</option>
                        <option value="BOOKING">Booking Date</option>
                    </select>
                </div>
                <div class="col-lg-3 col-xl-3 pl-0">
                    <button class="btn btn-secondary btn-search" (click)="search()">
                        Search
                    </button>
                </div>                
            </div>

        </div>
    </div>
    
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Customer</th>
                                <th>Service</th>
                                <th>Status</th>
                                <th>Pickup Date</th>
                                <th>Booked Date</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>BOL Signed</th>
                                <th>Public Board</th>
                                <th>VIP Board</th>
                                <th>Job Allocation</th>
                                <th>
                                    JAF
                                    <span class="text-nowrap">
                                        E-sign
                                    </span>
                                </th>
                                <th  *ngIf="quickAssign">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;"  
                                [ngStyle]="row.statuses.general != 'CUSTOMER_CANCELED' ? {'background-color': 'white'} : {'background-color': '#ffa7a7'}"
                                [ngClass]="{'cancelled-job': row.estimate.cancellation !== null}">
                                <!-- Number -->
                                <td>
                                    <span class="job-number" (click)="openNewTab(row.estimate.id);$event.stopPropagation()">
                                        {{row.estimate.document.code}}
                                    </span> 
                                </td>
                                <!-- Customer -->
                                <td>
                                    {{row.estimate.customer.name}} {{row.estimate.customer.last_name}}
                                </td>
                                <!-- Service -->
                                <td>
                                    <span [ngSwitch]="row.estimate.service">
                                        <ng-container *ngSwitchCase="'LONG'">
                                            Long
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'LOCAL'">
                                            Local
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'AUTO_TRANSPORT'">
                                            Auto Transport
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'FREIGHT'">
                                            Freight
                                        </ng-container>
                                    </span>
                                </td>
                                <!-- Status -->
                                <td>
                                    <span>
                                        <span [ngSwitch]="row.statuses.general">
                                            <ng-container *ngSwitchCase="'NEW'">
                                                New
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED_BOXES'">
                                                Delivered Boxes
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PACKED_MOVE'">
                                                Packed Move
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PICKED_UP'">
                                                Picked Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_TRANSIT'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'CUSTOMER_CANCELED'">
                                                Canceled
                                            </ng-container>

                                            <!--default case when there are no matches -->
                                            <ng-container *ngSwitchDefault>
                                                {{row.estimate.status}}
                                            </ng-container>
                                        </span>
                                    </span>
                                </td>
                                <!-- Pickup Date -->
                                <td>{{row.estimate.pickup.range_start | date}} to {{row.estimate.pickup.range_end | date}}</td>
                                <!-- Booked Date -->
                                <td>{{row.estimate.booked_date | date}}</td>
                                <!-- Origin -->
                                <td >
                                    <span *ngIf="row.estimate.pickup.address.full_address">
                                        {{row.estimate.pickup.address.full_address}} -
                                    </span>
                                    <span>
                                        {{row.estimate.pickup.address.state}} {{row.estimate.pickup.address.zip_code}}
                                    </span>
                                </td>
                                <!-- Destination -->
                                <td >
                                    <span *ngIf="!row.estimate.delivery.address.full_address">
                                        {{row.estimate.delivery.address.full_address}} -
                                    </span>
                                    <span>
                                        {{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}}
                                    </span>
                                </td>       
                                <!-- BOL Signed -->
                                <td class="check-bol-signed">
                                    <!-- Not Checked  -->
                                    <i *ngIf="!row.estimate.document_bol?.document.esignatures.main" class="fa-regular fa-square not-checked"></i>
                                    <!-- Checked -->
                                    <i *ngIf="row.estimate.document_bol?.document.esignatures.main" class="fa-solid fa-square-check checked"></i>
                                </td>
                                <!-- Public Board -->
                                <td class="check-public-board"> 
                                    <div class="d-center">        
                                        <!-- Not Checked  -->                        
                                        <!-- <div class="checkbox-jobs">
                                            <i class="fa-regular fa-square not-checked"></i>
                                        </div> -->
                                        <!-- Checked -->
                                        <!-- <div class="checkbox-jobs">
                                            <i class="fa-solid fa-square-check checked"></i>
                                        </div> -->
                                        <!-- Checkbox -->
                                        <div class="checkbox-jobs">
                                            <input [disabled]="!quickAssign"
                                                [checked]="row.board_job?.is_private" 
                                                (change)="detectChange('PRIVATE', row, $event)"
                                                [id]="row.id"
                                                type="checkbox">
                                        </div>
                                    </div>  
                                </td>
                                <!-- VIP Board -->
                                <td class="check-vip-board">    
                                    <div class="d-center">     
                                        <!-- Not Checked  -->                          
                                        <!-- <div class="checkbox-jobs">
                                            <i class="fa-regular fa-square not-checked"></i>
                                        </div> -->
                                        <!-- Checked -->
                                        <!-- <div class="checkbox-jobs">
                                            <i class="fa-solid fa-square-check checked"></i>
                                        </div> -->
                                        <!-- Checkbox -->
                                        <div class="checkbox-jobs">
                                            <input [disabled]="!quickAssign"
                                                [checked]="row.board_job?.is_vip"
                                                (change)="detectChange('VIP', row, $event)"
                                                [id]="row.id"
                                                type="checkbox">
                                        </div>
                                    </div>   
                                </td>
                                <!-- Job Allocation -->
                                <td>
                                    <ng-container *ngIf="row.allocation == 'IN_HOUSE'">
                                        In House
                                    </ng-container>
                                    <ng-container *ngIf="row.allocation != 'IN_HOUSE'">
                                        <ng-container *ngIf="row.job_outsourcing">
                                            <ng-container *ngFor="let carrier of carriers">
                                                <ng-container *ngIf="carrier.id == row.job_outsourcing.carrier_id">
                                                    {{carrier.name}}
                                                    <br>
                                                    <strong> Sent </strong>{{row.job_outsourcing.created | dateTime}}
                                                    <br>
                                                    <ng-container *ngFor="let employee of employees">
                                                        <ng-container *ngIf="employee.id == row.job_outsourcing.employee_id">
                                                           <strong> Rep </strong> {{employee.name}}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!row.job_outsourcing">
                                           Unassigned
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <!-- Check Job Allocation -->
                                <td class="check-job-allocation">
                                    <!-- Not Checked  -->
                                    <i class="fa-regular fa-square not-checked" *ngIf="!row.job_outsourcing?.job_acceptance_doc"></i>
                                    <!-- Checked -->
                                    <i class="fa-solid fa-square-check checked" *ngIf="row.job_outsourcing?.job_acceptance_doc"></i>
                                </td>


                                <td *ngIf="quickAssign">
                                    <!-- <a appCompanyRouterLink="/moving/{{row.id}}/estimate" aria-expanded="false" class="dropdown-toggle cursor-pointer"> -->
                                        <!-- <i class="far fa-eye cursor-pointer" style="color:rgb(10, 10, 73);font-size: 20px;" (click)="openNewTab(row.id)"></i> -->
                                    <!-- </a> -->
                                    <ng-container> 
                                        <button id="btnActions" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-cog"></i>
                                        </button>
                                        <div class="dropdown-menu actionsJobs" aria-labelledby="btnOutline" style="will-change: transform;">
                                            <a class="dropdown-item" (click)="cancelJobOutsourcing(row)" *ngIf="row.allocation || row.job_outsourcing">
                                                <i class="fa-solid fa-scissors"></i>&nbsp;
                                                <span>Unassign</span>
                                            </a>
                                            <a class="dropdown-item" (click)="assignCarrier(row)" *ngIf="(!row.allocation && !row.job_outsourcing) && (!row.board_job?.is_private && !row.board_job?.is_vip)">
                                                <i class="fa-solid fa-pen-nib"></i>&nbsp;
                                                <span>Assign</span>
                                            </a>
                                            <a class="dropdown-item">
                                                <i class="fa-solid fa-money-bill-transfer"></i> 
                                                <span class="ml-1">Change Offer</span>
                                            </a>
                                        </div>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>

</div>



<!-- MODAL PARA ESTABLECER VALORES A OFRECER AL CARRIER -->
<div class="modal fade modalCarriers" id="modalInformationCarriers" #modalInformationCarriers tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">

            <form class="form" #assignmentCarrierForm="ngForm" ngNativeValidate (ngSubmit)="saveBoardJob()">

                <div class="modal-header">
                    <h4 class="modal-title text-center" style="width: 100%;">
                        <strong class="text-warning">
                            Carrier Assignment 
                        </strong>
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">
                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                            <div class="form-group">
                                <label class="control-label">
                                    Job Total
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" disabled
                                           [(ngModel)]="jobToAssign.estimate.total"
                                           class="form-control" name="job_total_modal">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    This is the job total froom the most recently signed estimate
                                </small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">
                                    Job Payments
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" disabled
                                           [(ngModel)]="jobPayments" class="form-control" name="job_payments_modal">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    This is the total amunt of payments made on this job
                                </small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">
                                    Carrier Balance Offer
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" 
                                           [(ngModel)]="carrierBalanceOffer"
                                           (keyup)="updateOfferPercentage()"
                                           step="0.01" placeholder="Carrier Balance Offer" class="form-control" name="offer">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    Enter the value to be offered to the carrier so that it can do the job
                                </small>
                            </div>

                            <div class="form-group">
                                <label class="control-label">
                                    Carrier Balance Percentage
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-flag">
                                                <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z">
                                                </path>
                                                <line x1="4" y1="22" x2="4" y2="15"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input id="percentage" [value]="carrierBalancePercentage" type="number" step="0.01"
                                        placeholder="Carrier Balance Percentage" disabled="true" class="form-control"
                                        name="percentage">
                                </div>
                                <small class="form-text text-muted mb-4">
                                    This field indicates the percentage of the work that is being given to the carrier
                                </small>
                            </div>

                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                            <div class="form-group ">
                                <label class="control-label">
                                    Special Conditions
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-message-square">
                                                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <textarea name="carrier_special_conditions" [(ngModel)]="boardJobForm.special_conditions" class="form-control" rows="7"></textarea>
                                </div>
                                <small class="form-text text-muted mb-4">
                                    if you have something to add to the contract... you can add it here
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="text-center" style="width: 100%;">
                        <div *ngIf="boardJobForm.offer >= jobToAssign.estimate.total" class="text-danger mb-3">
                            Maximum Carrier Balance Offer
                        </div>
                        <button class="btn" data-dismiss="modal"  *ngIf="!isManualAssignment" >
                            Discard
                        </button>
                        <button  *ngIf="!isManualAssignment"  [disabled]="boardJobForm.offer >= jobToAssign.estimate.total" class="btn btn-warning" type="submit">
                            Assign
                        </button>
                       
                    </div>
                </div>

            </form>
            <button *ngIf="isManualAssignment"  (click)="selectCarrier()" class="btn btn-warning">
                Assign
            </button>
        </div>
    </div>
</div>



<!-- MODAL PARA seleccionar contactos del CARRIER -->
<div class="modal fade register-modal" #emailsModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-signature">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Send Emails
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">

                            <div class="form-group">
                                <div class="user-info-list">

                                    <table class="table table-bordered table-hover mb-4">
                                        <thead>
                                            <tr>
                                                <th>Contact</th>
                                                <th>Email</th>
                                                <th>Select</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let carrierContact of carrierContacts">
                                                <td>
                                                    {{carrierContact.name}}
                                                </td>
                                                <td>
                                                    {{carrierContact.email}}
                                                </td>
                                                <td>
                                                    <input type="checkbox" (change)="setDefault(carrierContact.id)"
                                                        [checked]="carrierContact.main"
                                                        [disabled]="carrierContact.main" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button data-dismiss="modal" type="button" class="btn btn-danger mt-2 mb-2 btn-block">
                        Close
                    </button>
                    <button (click)="closeEmailsModal()" data-dismiss="modal" type="button"
                    
                        class="btn btn-success mt-2 mb-2 btn-block">
                        Send
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
