<!--Por aca empieza el formulario de listado-->

<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Items
                    </h3>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <a appCompanyRouterLink="add" class="btn btn-warning">
                    <i class="fas fa-plus-square"></i> &nbsp; New item
                </a>

            </div>


        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered mb-4">
                        <thead>
                            <tr>
                                <th>Packed By</th>
                                <th>Dimensions</th>
                                <th>Cubic Feets</th>
                                <th>Weight</th>
                                <th>Pads</th>
                                <th>Remarks</th>
                                <th>Special Handling</th>
                                <th>Images</th>
                                <th>Option</th>

                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr *ngFor="let row of items">
                                <td *ngIf="row.packed_by">{{row.packed_by}}</td>
                                <td *ngIf="row.dimensions">{{row.dimensions}}</td>
                                <td *ngIf="row.cubic_feets">{{row.cubic_feets}}</td>
                                <td *ngIf="row.weight">{{row.weight}}</td>
                                <td *ngIf="row.pads">{{row.pads}}</td>
                                <td *ngIf="row.remarks">{{row.remarks}}</td>
                                <td *ngIf="row.special_handling">{{row.special_handling}}</td>
                                <td *ngIf="row.packed_by"><img src="{{row.images}}" class="image"></td>
                                <td *ngIf="row.packed_by">
                                    <a appCompanyRouterLink="/moving/{{row.job_id}}/items/edit/{{row.id}}" class="btn btn-outline-dark">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                    </a>
                                    <br>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                    </button>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <!--<pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>-->
                </div>
            </div>
        </div>
    </div>

</div>
