import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

declare const jQuery;

@Component({
    selector: 'app-log-viewed-estimate',
    templateUrl: './log-viewed-estimate.component.html',
    styleUrls: ['./log-viewed-estimate.component.scss']
})
export class LogViewedEstimateComponent implements OnInit {

    @Input()
    companyId: boolean = false;
    theLastTenTobsTheyViewedWithaLinks: any;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) { 
        this.theLastTenTobsTheyViewedWithaLinks = [];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
           if (this.companyId){
            this.getTheLastTenTobsTheyViewedWithaLinkCompany();
        } else {
            this.getTheLastTenTobsTheyViewedWithaLinkWorkspace();
        }
    }

    
    getTheLastTenTobsTheyViewedWithaLinkWorkspace(){
        
        this.dashboardWorkspaceService
            .getTheLastTenTobsTheyViewedWithaLink()
            .then((response) => {                
                this.theLastTenTobsTheyViewedWithaLinks = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }
    
    getTheLastTenTobsTheyViewedWithaLinkCompany(){
        
        this.dashboardComopanyService
            .getTheLastTenTobsTheyViewedWithaLink()
            .then((response) => {                
                this.theLastTenTobsTheyViewedWithaLinks = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }



}
