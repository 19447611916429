import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneExtensionsService {

  constructor(private http: HttpClient) {}


  getExtensionsSms(): Promise<any> {

    return this.http.get(environment.api.company + '/phone-extension').toPromise();
  }



}
