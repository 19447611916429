import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from 'src/app/services/companies/balances.service';
import { BalanceView } from 'src/app/entities/workspace/balance-view';
import { BetweenDates } from '../../../entities/helpers/between-dates';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { ConstantsMessages } from '../../../constants-messages';
import { CustomerPayment } from 'src/app/entities/customer-payment';
import { DepositDueBalancesService } from 'src/app/services/companies/deposit-due-balances.service';
import { DOCUMENT } from '@angular/common';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { environment } from 'src/environments/environment';
import { Estimate } from 'src/app/entities/workspace/estimate';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { EstimateTransportVehicles } from 'src/app/entities/workspace/estimate-transport-vehicles';
import { Expense } from 'src/app/entities/workspace/expense';
import { ExpenseService } from 'src/app/services/companies/expense.service';
import { File } from 'src/app/entities/global/file';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { JobView } from 'src/app/entities/workspace/job-view';
import { Payment } from 'src/app/entities/workspace/payment';
import { PaymentGatewaySetting } from 'src/app/entities/workspace/payment-gateway-setting';
import { PaymentGatewaySettingsService } from 'src/app/services/companies/payment-gateway-settings.service';
import { PaymentMethod } from '../../../entities/workspace/payment-method';
import { PaymentProfile } from 'src/app/entities/workspace/payment-profile';
import { PaymentsMethodsService } from '../../../services/companies/payments-methods.service';
import { PaymentsService } from 'src/app/services/companies/payments.service';
import { ResponseTransaction } from 'src/app/entities/helpers/response-transaction';
import { SaleCommission } from 'src/app/entities/workspace/sale-commission';
import { SalesCommissionsService } from 'src/app/services/companies/sales-commissions.service';
import { StorageInvoice } from 'src/app/entities/workspace/storage-invoice';
import { StorageInvoicesService } from 'src/app/services/companies/storage-invoices.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare const jQuery;
declare const swal;
declare const window: any;

@Component({
    selector: 'app-estimates-payments',
    templateUrl: './estimates-payments.component.html',
    styleUrls: ['./estimates-payments.component.scss']
})
export class EstimatesPaymentsComponent implements OnInit, AfterViewInit {
    @ViewChild('addPaymentModal') addPaymentModal: ElementRef;
    @ViewChild('changeAmountDueModal') changeAmountDueModal: ElementRef;
    @ViewChild('changeCompaniesChecksBalancesModal') changeCompaniesChecksBalancesModal: ElementRef;
    @ViewChild('expensesModal') expensesModal: ElementRef;
    @ViewChild('paidToModal') paidToModal: ElementRef;
    @ViewChild('partialPaymentModal') partialPaymentModal: ElementRef;
    @ViewChild('paymentModal') paymentModal: ElementRef;
    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    public amountEmployeeSale: number;
    public amountReadOnly: boolean;
    public autoTransportList: Array<EstimateTransportVehicles>;
    public balance: BalanceView;
    public balances: Array<BalanceView>;
    public cardValidated: boolean;
    public estimateBalances: Array<BalanceView>;
    public storageBalances: Array<BalanceView>;
    public betweenDates: BetweenDates;
    public disableSaveBalance: boolean;
    public employees: Array<Employee>;
    public estimate: Estimate;
    public expense: Expense;
    public expenses: Array<Expense>;
    public commissions: Array<SaleCommission>;
    public deliveryCost: Array<Expense>;
    public idEstimate: string;
    public initialPaymentPercentage: number;
    public job: JobView;
    public manualBalanceAmmount: number;
    /*
    Metodos de pago asociados a la company
    */
    public methodsConfirmPayments: Array<PaymentMethod>;
    public methodsPayment: Array<PaymentMethod>;
    public payment: Payment;
    /*
    Metodo de pago seleccionado en el modal de payments
    */
    public paymentMethod: PaymentMethod;
    public payments: Array<Payment>;
    public showAmountForCommission: boolean;
    public showButtonAddBalance: boolean;
    public showOptionsStorage: boolean;
    public storageInvocices: StorageInvoice[];
    public storageInvociceSelected: string;
    public paymentGatewaySetting: PaymentGatewaySetting;
    public paymentMethodName: string;
    /*
    Variable que contiene el total del porcentaje que se ha pagado en una mudanza
    */
    public totalPercentageBalance: number;

    public uploadFile: boolean;
    private vehicleNumbers: number;

    public tabCard: string;
    public tabCustomer: string;
    public tabSign: string;
    public tabResult: string;

    public paymentProfile: PaymentProfile;
    public responseTransaction: ResponseTransaction;
    public buttonPayDisable: boolean;
    readonly environment = environment;
    public showGateways: boolean;
    public gateways: PaymentGatewaySetting[];
    public changedAmount: number;
    public urlBase: String;
    public generalSettings: GeneralSettings;
    private squareToken: string;
    private flagSquareForm: boolean;
    public showLoading: boolean;
    public brokers: Carrier[];
    public brokerPay: number;
    public estimateBrokerId: string;
    public upToBalance: number;
    public isConfirm: boolean;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private balancesService: BalancesService,
        private carriersService: CarriersService,
        private currentRoute: ActivatedRoute,
        private depositDueBalancesService: DepositDueBalancesService,
        private employeesService: EmployeesCompanyService,
        private estimatesService: EstimatesService,
        private expenseService: ExpenseService,
        private generalSettingsService: GeneralSettingsService,
        private jobsService: JobsService,
        private paymentGatewaySettingsService: PaymentGatewaySettingsService,
        private paymentsMethodsService: PaymentsMethodsService,
        private paymentsService: PaymentsService,
        private renderer: Renderer2,
        private salesCommissionsService: SalesCommissionsService,
        private storageInvoicesService: StorageInvoicesService,
        private uploadFilesService: UploadFilesService,
        public authService: AuthService,
        public helperService: HelperService,
    ) {
        this.amountEmployeeSale = 0;
        this.autoTransportList = [];
        this.balance = new BalanceView();
        this.balances = [];
        this.cardValidated = true;
        this.estimateBalances = [];
        this.storageBalances = [];
        this.betweenDates = new BetweenDates();
        this.disableSaveBalance = true;
        this.employees = [];
        this.estimate = new Estimate();
        this.expense = new Expense();
        this.expenses = [];
        this.commissions = [];
        this.deliveryCost = [];
        this.idEstimate = '';
        this.job = new JobView();
        this.manualBalanceAmmount = null;
        this.methodsPayment = [];
        this.payment = new Payment();
        this.paymentMethod = new PaymentMethod();
        this.paymentMethod.method = null;
        this.payments = [];
        this.showButtonAddBalance = true;
        this.showOptionsStorage = false;
        this.storageInvocices = [];
        this.storageInvociceSelected = '';
        this.totalPercentageBalance = 0;
        this.uploadFile = false;
        this.vehicleNumbers = 0;
        this.initializeVariablesAmount(true, false, null, null);
        this.loadGeneralSetting();

        this.tabCard = 'active';
        this.tabCustomer = 'inactive';
        this.tabSign = 'inactive';
        this.tabResult = 'inactive';
        this.paymentProfile = new PaymentProfile();
        this.responseTransaction = new ResponseTransaction();
        this.showGateways = false;
        this.gateways = [];
        this.changedAmount = 0;
        this.urlBase = environment.api.base;
        this.paymentGatewaySetting = new PaymentGatewaySetting();
        this.squareToken = '';
        this.paymentMethodName = '';
        this.flagSquareForm = false;
        this.showLoading = false;
        this.brokers = [];
        this.brokerPay = 0;
        this.estimateBrokerId = '';
        this.upToBalance = 0;
        this.isConfirm = false;
    }

    ngOnInit(): void {

        this.carriersService.getAll()
        .then((response) => {
            this.brokers = response;
        });

    }

    async ngAfterViewInit() {
        const that = this;

        jQuery('.select-methods').css('width', '460px');
        const select = jQuery('.method');
        let idTimeOut = null;

        select.select2({
            tags: true
        })
            .on('select2:select', (e) => {
                that.setMethodsEnabled(e.params.data, 'select');
            })
            .on('select2:unselect', (e) => {
                that.setMethodsEnabled(e.params.data, 'unselect');
            });

        // si hay cambios en el dom refrescamos el plugin
        select.bind('DOMSubtreeModified', () => {

            if (idTimeOut !== null) {
                clearTimeout(idTimeOut);
            }

            idTimeOut = setTimeout(() => {
                select.trigger('change');
            }, 1000);
        });
        this.currentRoute.parent.params.subscribe(async params => {
            if (typeof params.id !== 'undefined') {
                this.loadAllData(params.id);
            }
        });

        this.helperService.uploadFile.subscribe(async (response) => {
            this.uploadFile = response;
            if (this.uploadFile) {
                if (this.payment.is_partial_payment) {
                    this.savePartialPayment();
                } else {
                    this.savePayment();
                }
                // jQuery(this.addPaymentModal.nativeElement).modal('hide');
            }
        });
    }

    async initSquare(){
        this.flagSquareForm = true;
        
        const that = this;
        // valida existencia de square
        if (!window.Square) {
            this.selectSquareScript();
            throw new Error('Square.js failed to load properly');
        }
        // se inicializa autorizacion de pagos
        const payments = window.Square.payments(this.paymentGatewaySetting.square_setting.app_id, this.paymentGatewaySetting.square_setting.location_id);
        let card;
        try {
            // inicializa modal de tarjetas
            card = await this.initializeCard(payments);
            const cardButton = document.getElementById(
                'card-button'
            );
            // agrega manejador al click del formulario
            cardButton.addEventListener('click', async function (event) {
                await that.handlePaymentMethodSubmission(event, card);
            });
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }
    }
    async initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container'); 
        return card; 
    }

    selectSquareScript() {
        // url de sandbox de square
        let squareScriptURL ="https://sandbox.web.squarecdn.com/v1/square.js";
        if (this.paymentGatewaySetting.production) {
          squareScriptURL ="https://web.squarecdn.com/v1/square.js";
        }
        // llama renderizado del script en el index
        this.loadJsScript(this.renderer, squareScriptURL);
      }
    
      public loadJsScript(renderer: Renderer2, src: string) {
          const script = renderer.createElement('script');
          script.type = 'text/javascript';
          script.src = src;
          renderer.appendChild(this.document.body, script);
          // despues de inyectar el codigo intenta inicializar de nuevo el scirpt
          setTimeout(() => {
            this.initSquare();
          },1000);
      }
    
      async handlePaymentMethodSubmission(event, paymentMethod) {
        event.preventDefault();
     
        const cardButton: any = document.getElementById(
          'card-button'
        );
        try {
          // disable the submit button as we await tokenization and make a
          // payment request.
          cardButton.disabled = true;
          const token = await this.tokenize(paymentMethod);
          this.squareToken = token;
          this.cardValidated = true;
          this.helperService.hideLoadingMxpro360();
          cardButton.disabled = false;
          
        } catch (e) {
          cardButton.disabled = false;
          // displayPaymentResults('FAILURE');
          console.error(e.message);
        }
      }
    
      async tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
          return tokenResult.token;
        } else {
          let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
          if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(
              tokenResult.errors
            )}`;
          }
          throw new Error(errorMessage);
        }
      }

    async loadAllData(estimateId: string) {
        this.loadPayment(estimateId);
        this.idEstimate = estimateId;
        await this.loadEstimate(estimateId);
        await this.loadBalances();
        this.calculatePercentageBalance();
        this.validateAmountToBalance();
        this.loadMethodsPayment();
        this.loadEmployees();
        this.loadExpenses();
        this.loadCommissions();
        this.loadStorageInvoices(estimateId);
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private loadGeneralSetting() {
        this.helperService.showLoadingMxpro360();
        this.generalSettingsService
            .get()
            .then((response) => {
                this.generalSettings = response;
                this.initialPaymentPercentage = response.settings.price_setting.initial_payment_percentage;
                this.initialPaymentPercentage = this.initialPaymentPercentage ? this.initialPaymentPercentage : 33;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.loadGateways();
            });
    }

    // valida si se muestra o no el boton de agregar pagos
    public validateShowButtonAddBalance() {
        this.showButtonAddBalance = true;
        this.balances.forEach(payment => {
            // si algun pago esta pendiente de pago no se debe dejar mostrar boton de agregar pago
            if (payment.status !== 'PAID') {
                this.showButtonAddBalance = false;
            }
        });
    }

    private loadAutoTransport(id: string) {
        return new Promise((resolve, reject) => {
            this.helperService.showLoadingMxpro360();
            this.estimatesService.getAutoTransport(id).then((response) => {
                this.autoTransportList = response;
                this.vehicleNumbers = response.length;
                resolve(true);
            })
                .catch((error) => {
                    reject();
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

    private loadEstimate(id: string) {
        return new Promise((resolve, reject) => {
            this.helperService.showLoadingMxpro360();
            this.estimatesService
                .getById(id)
                .then((response) => {
                    this.estimate = response;
                    this.loadJob();
                    if (this.estimate.service === 'AUTO_TRANSPORT') {
                        this.loadAutoTransport(this.estimate.id);
                    }
                    resolve(true);
                })
                .catch((error) => {
                    console.error('error', error);
                    reject();
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

    private loadPayment(id: string) {
        this.helperService.showLoadingMxpro360();
        this.paymentsService
            .getAllEstimates(id)
            .then((response) => {
                this.payments = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadJob() {
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getJobById(this.estimate.id)
            .then((response) => {
                this.job = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadBalances() {
        return new Promise((resolve) => {
            this.helperService.showLoadingMxpro360();
            this.balancesService
                .getById(this.idEstimate, {})
                .then((response) => {
                    this.balances = response;
                    this.separeStorageBalances();
                    this.calculatePercentageBalance();
                    this.validateShowButtonAddBalance();
                    this.helperService.calculateTotalDueBalance.emit(this.idEstimate);
                    resolve(true);
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    resolve(false);
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

    private separeStorageBalances() {
        this.estimateBalances = [];
        this.storageBalances = [];
        this.balances.forEach(balance => {
            // Se verifica que los balances esten pagos y no sean de tipo STORAGE
            if (balance.target !== 'STORAGE') {
                this.estimateBalances.push(balance);
            } else {
                this.storageBalances.push(balance);
            }
        });
    }

    private loadExpenses() {
        this.helperService.showLoadingMxpro360();
        this.expenseService
            .getAllExpenses(this.idEstimate)
            .then((response) => {
                this.expenses = response.filter(item => !item.is_delivery_cost);
                this.deliveryCost = response.filter(x => x.is_delivery_cost);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    private loadCommissions() {
        this.helperService.showLoadingMxpro360();
        this.salesCommissionsService
            .getSaleCommisionByEstimate(this.idEstimate)
            .then((response) => {
                this.commissions = response;

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    async addBalance(balance: BalanceView) {
        this.balance = balance;
        this.payment.amount = this.balance.amount;
        this.payment.is_partial_payment = false;
        if (this.balance.payment_gateway_setting_id !== null) {
            await this.loadPaymentGatewaySetting(this.balance.payment_gateway_setting_id);
        }
        jQuery(this.addPaymentModal.nativeElement).modal('show');
    }

    async payTo(balance: BalanceView) {
        this.balance = balance;
        jQuery(this.paidToModal.nativeElement).modal('show');
    }

    private loadPaymentGatewaySetting(idMethod: string) {
        this.helperService.showLoadingMxpro360();
        return new Promise(resolve => {
            this.paymentGatewaySettingsService.
                getById(idMethod).
                then(async (response) => {
                    this.paymentGatewaySetting = response;
                    if (this.paymentGatewaySetting.name === 'SQUARE' && this.flagSquareForm === false) {
                        this.cardValidated = false;
                        this.initSquare();
                    }
                    resolve(true);
                })
                .catch((error) => {
                    this.helperService.showMessageError(error, idMethod);
                    resolve(false);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

    addExpenses() {
        this.expense = new Expense();
        jQuery(this.expensesModal.nativeElement).modal('show');
    }

    addPayment() {
        if (this.estimate.status == 'WORK_CARRIED_OUT') {
            swal(
                'Atention',
                'This job is done',
                'warning'
            );
            return;
        }
        if (this.payments.length === 0) {
            this.payment.amount = Number((this.estimate.total * Number(this.initialPaymentPercentage) / 100).toFixed(2));
        }
        if (this.estimate.service === 'AUTO_TRANSPORT') {
            this.balance.target = 'NEW_BOOKING';
        }
        jQuery(this.paymentModal.nativeElement).modal('show');
    }

    /**
     * Valida si hay un metodo de pago habilitado para el customer
     */
    public validateBalance() {

        if (this.balance.payment_methods_enabled_id.length === 0) {
            swal({
                title: 'You have not selected a payment method',
                text: 'Do you want to continue?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue'
            })
                .then(async (result) => {

                    if (result.value) {
                        this.saveBalance();
                    } else {
                        return;
                    }
                });
        } else {
            this.saveBalance();
        }

    }

    /**
     * Guarda el balance
     */
    private async saveBalance() {
        this.helperService.showLoadingMxpro360();
        this.balance.job_id = this.idEstimate;
        this.balance.employee_id = this.balance.target === 'NEW_BOOKING' ?
            this.estimate.operator.salesman_id : this.authService.workspaceSession.employee.id;
        this.balance.customer_id = this.estimate.customer_id;

        if (this.balance.target === 'CARRIER_TO_BROKER') {
            this.balance.carrier_id = this.job.job_outsourcing.carrier_id;
        }

        const data: any = { ...this.balance };
        data.amount_employee_sale = this.amountEmployeeSale;
        data.cubic_feets = this.estimate.volume.cubic_feets;

        // Se verifica si es AUTO_TRANSPORT para asignar el numero de vehiculos
        if (this.estimate.service === 'AUTO_TRANSPORT') {
            await this.loadAutoTransport(this.estimate.id);
            data.vehicle_numbers = this.vehicleNumbers;
        }

        this.balancesService
            .saveBalance(data)
            .then(async () => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                await this.loadBalances();
            })
            .catch((error) => {
                console.error('error', error);
                swal({
                    type: 'error',
                    title: 'Error...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    confirmSavePayment() {
        swal({
            title: 'Confirm Add Payment',
            text: 'Are you sure you want to save before uploading a image?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue'
        })
            .then(async (result) => {

                if (result.value) {
                    this.savePayment();
                } else {
                    return;
                }
            });
    }

    validateCard() {
        this.helperService.showLoadingMxpro360();
        // emite click para generar token
        document.getElementById('card-button').click();
    }

    async savePayment() {
        this.helperService.showLoadingMxpro360();
        const paymentTypes = ['BANK_TRANSFER_OFFLINE', 'CHECK', 'MONEY_ORDER', 'CASH'];
        if (paymentTypes.includes(this.paymentMethod.method) && !this.authService.hasPermissionCompany('ESTIMATE_BALANCE_CONFIRM_WIHTOUT_VOUCHERS')) {

            if (this.payment.vouchers || this.payment.vouchers.length === 0) {
                swal(
                    'Please attach the voucher',
                    'The Voucher is necessary to validate the payment',
                    'warning'
                );
                this.helperService.hideLoadingMxpro360();
                return;
            }
        }

        if (this.paymentMethod.method === 'CREDIT_CARD') {
            if (!this.validateFormTabCard()) {
                this.helperService.hideLoadingMxpro360();
                swal({
                    title: 'Error',
                    text: 'Complete all fields in the form',
                    type: 'error',
                });
                return;
            }
        }

        if (!this.payment.date) {
                this.helperService.hideLoadingMxpro360();
                swal({
                    title: 'Error',
                    text: 'Date No Assigned',
                    type: 'error',
                });
                return;
        }

        this.balance.status = 'PAID';

        this.payment.balance_id = this.balance.id;
        this.payment.customer_id = this.balance.customer_id;
        this.payment.employee_id = this.authService.workspaceSession.employee.id;
        this.payment.is_partial_payment = false;
        // contruye data para enviar
        const data: any = { ...this.payment };
        data.amount_employee_sale = this.amountEmployeeSale;
        data.cubic_feets = this.estimate.volume.cubic_feets;

        // Se verifica si es AUTO_TRANSPORT para asignar el numero de vehiculos
        if (this.estimate.service === 'AUTO_TRANSPORT') {
            await this.loadAutoTransport(this.estimate.id);
            data.vehicle_numbers = this.vehicleNumbers;
        }

        if (this.paymentMethod.method === 'CREDIT_CARD') {
            this.helperService.showLoadingMxpro360();
            this.buttonPayDisable = true;
            // inicializa datos para post de pagos
            const dataPost: CustomerPayment = new CustomerPayment();

            dataPost.reference = this.balance.id.substring(4, 24);
            dataPost.amount = this.balance.amount;
            dataPost.payment_profile.credit_card = this.paymentProfile.credit_card;
            dataPost.payment_profile.billing_address = this.paymentProfile.billing_address;
            dataPost.payment_profile.card_holder = this.paymentProfile.card_holder;

            data.customerPayment = dataPost;
            if (this.paymentGatewaySetting.name == 'SQUARE') {
                dataPost.source_id = this.squareToken;
            }
        }
        this.paymentsService
            .savePayment(this.idEstimate, data)
            .then(async () => {
                const dataBalance: any = { ...this.balance };
                dataBalance.amount_employee_sale = this.amountEmployeeSale;
                dataBalance.cubic_feets = this.estimate.volume.cubic_feets;
                // Se verifica si es AUTO_TRANSPORT para asignar el numero de vehiculos
                if (this.estimate.service === 'AUTO_TRANSPORT') {
                    dataBalance.vehicle_numbers = data.vehicle_numbers;
                }
                this.balancesService
                    .setBalance(dataBalance)
                    .then(async () => {
                        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                        this.balance = new BalanceView();
                        this.loadEstimate(this.estimate.id);
                        await this.loadBalances();
                        this.validateShowButtonAddBalance();
                    })
                    .catch((error) => {
                        console.error('error', error);
                        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                        swal('Declined',
                        error.message,
                        'error');
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.helperService.hideLoadingMxpro360();
                this.loadBalances();
            });
        jQuery(this.addPaymentModal.nativeElement).modal('hide');

    }

    savePayTo(){
        this.helperService.showLoadingMxpro360();

        this.balance.job_amount = this.balance.amount - this.balance.allocated_to_payments_storage;
        const dataBalance: any = { ...this.balance };
        
        this.balancesService
        .setBalance(dataBalance)
        .then(async () => {
            jQuery(this.paidToModal.nativeElement).modal('hide');
            this.balance = new BalanceView();
            this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            this.loadEstimate(this.estimate.id);
            await this.loadBalances();
            this.validateShowButtonAddBalance();
        })
        .catch((error) => {
            console.error('error', error);
            this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            swal('Declined',
            error.message,
            'error');
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    changeDate(event) {
        this.payment.date = event;
    }

    changeDateExpense(event) {
        this.expense.payment_date = event;
    }

    /**
     * Metodo que calcula el porcentaje de pagos que se han realizado en la mudanza
     */
    private calculatePercentageBalance(): void {
        let totalBalances = 0;

        // Se verifica que existan balances y que el estimate contenga un valor mayor a cero,
        // de lo contrario el porcentaje es cero
        if (this.balances.length > 0 && this.estimate.total > 0) {
            // Se recorren todos los balances del estimate
            this.balances.forEach(balance => {
                // Se verifica que los balances esten pagos y no sean de tipo STORAGE
                if (balance.target !== 'STORAGE' && balance.status === 'PAID') {
                    totalBalances = totalBalances + balance.amount;
                }
            });
            // Se asigna el porcentaje
            this.totalPercentageBalance = Number(((totalBalances * 100) / this.estimate.total).toFixed(2));
        } else {
            this.totalPercentageBalance = 0;
        }
    }

    public validateDisableSaveBalance(typed) {
        this.disableSaveBalance = true;
        if (typed) {
            this.manualBalanceAmmount = this.balance.amount;
        }
        if (
            this.balance.amount !== null &&
            (this.balance.concept !== null || this.estimate.service == 'AUTO_TRANSPORT') &&
            (this.balance.target !== null || this.estimate.service == 'AUTO_TRANSPORT')
        ) {
            if (this.balance.target == 'QUALITY_ASSURANCE') {
                if (this.balance.comissioning_employee_id !== null) {
                    this.disableSaveBalance = false;
                }
                return;
            }
            this.balance.comissioning_employee_id = null;
            this.disableSaveBalance = false;
        }
        if (this.balance.payment_gateway_setting_id !== null) {
            this.selectCCFee();
        }
    }

    public validateAmountToBalance() {
        
        this.showOptionsStorage = false;
        this.balance.certified_founds = false;
        switch (this.balance.target) {
            case 'STORAGE': {
                this.showOptionsStorage = true;
                this.initializeVariablesAmount(true, false, null, null);
                break;
            }
            case 'OPERATIONS': {
                this.initializeVariablesAmount(true, false, null, null);
                break;
            }

            case 'NEW_BOOKING': {
                this.calculateAmountFromFormula();
                break;
            }
            case 'QUALITY_ASSURANCE': {
                this.calculateAmountCommissions(false, true);
                break;
            }
        }
        this.validateDisableSaveBalance(false);
    }

    calculateAmountFromFormula() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService
            .getAmountFromFormula(this.estimate.id)
            .then((response) => {
                this.balance.amount = Number(response.toFixed(2));
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Calcula el amount que se registra en la tabla employee sales
     *
     * @param amountReadOnly
     * @param showAmountForCommission
     */
    private calculateAmountCommissions(amountReadOnly: boolean, showAmountForCommission: boolean) {
        let balanceAmount = !amountReadOnly ? this.totalUpsale() : this.totalBooking();

        if (this.estimate.service === 'AUTO_TRANSPORT') {
            balanceAmount = 0;
            for (const autoTransport of this.autoTransportList) {
                balanceAmount += Number(autoTransport.deposit);
            }
            this.initializeVariablesAmount(amountReadOnly, showAmountForCommission, balanceAmount, balanceAmount);
        } else {
            this.initializeVariablesAmount(amountReadOnly, showAmountForCommission, balanceAmount, this.estimate.total);
        }
    }

    totalBooking() {
        return Number((this.estimate.total * Number(this.initialPaymentPercentage) / 100).toFixed(2));
    }

    totalUpsale() {
        let paymentWanted = Number((this.estimate.total * Number(this.initialPaymentPercentage) / 100).toFixed(2));
        for (let currentBalance of this.balances) {
            paymentWanted -= currentBalance.amount;
        }
        return paymentWanted;
    }

    /**
     * Inicializa las variables para agregar un balance
     */
    private initializeVariablesAmount(amountReadOnly: boolean, showAmountForCommission: boolean,
        balanceAmount: number, amountForCommission: number): void {
        this.amountReadOnly = amountReadOnly;
        this.showAmountForCommission = showAmountForCommission;
        // ajusta valor si no se ha digitado manualmente
        this.balance.amount = this.manualBalanceAmmount ? this.manualBalanceAmmount : balanceAmount;
        this.amountEmployeeSale = amountForCommission;
    }

    /**
     * Carga los metodos de pago
     */
    private loadMethodsPayment(): void {
        this.helperService.showLoadingMxpro360();
        this.paymentsMethodsService
            .getAll()
            .then((response) => {
                this.methodsPayment = response;
                this.filterMethodsConfirmPayments(response);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * filtra los pagos que no son online
     * @param methods lectura en base de datos de metodos de pago
     */
    private filterMethodsConfirmPayments(methods: PaymentMethod[]) {
        this.methodsConfirmPayments = [];
        for (const method of methods) {
            // se quita esta condision method.method !== 'CREDIT_CARD' && 
            if (method.method !== 'BANK_TRANSFER_ONLINE') {
                this.methodsConfirmPayments.push(method);
            }
        }
    }

    public selectPaymentMethod(idPaymentMethod) {
        this.helperService.showLoadingMxpro360();
        this.paymentsMethodsService
            .getById(idPaymentMethod)
            .then((response) => {
                this.paymentMethod = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    uploadDocument(img, docType, paymentType) {
        this.payment.vouchers = img;
    }

    /**
     * Metodo para eliminar un balance
     *
     * @param balance Balance a eliminar
     */
    removeBalance(balance: BalanceView) {
        this.helperService.showLoadingMxpro360();

        // Datos necesarios para eliminar un balance
        const dataForm: any = {
            balance_id: balance.id,
            job_id: balance.job_id,
            employee_id: balance.employee_id
        };

        this.balancesService
            .deleteBalance(dataForm)
            .then(async () => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                this.loadEstimate(this.estimate.id);
                await this.loadBalances();
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    rejectBalance(balance: BalanceView) {
        this.helperService.showLoadingMxpro360();

        // Datos necesarios para eliminar un balance
        const dataForm: any = {
            balance_id: balance.id,
            job_id: balance.job_id,
            employee_id: balance.employee_id
        };

        this.balancesService
            .rejectBalance(dataForm)
            .then(async () => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                this.loadEstimate(this.estimate.id);
                await this.loadBalances();
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Metodo para eliminar un balance
     *
     * @param balance Balance a eliminar
     */
    removeExpense(expense: BalanceView) {

        this.expenseService
            .remove(this.idEstimate, expense)
            .then(async () => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                this.loadExpenses();
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * recibe y asigna fechas para filtro
     * @param dataReturn
     */
    getFilterDates(dataReturn: BetweenDates) {
        this.betweenDates = dataReturn;
    }

    search(): void {
        const data: any = {
            start: this.betweenDates.dateStart,
            end: this.betweenDates.dateEnd
        };

        this.helperService.showLoadingMxpro360();
        // Si no hay datos en el filtro, se realiza la busqueda de todos los datos
        if (data.start === 0 && data.end === 0) {
            this.loadBalances();
        } else {
            this.helperService.showLoadingMxpro360();
            this.balancesService
                .getById(this.idEstimate, data)
                .then((response) => {
                    this.balances = response;
                    this.calculatePercentageBalance();
                    this.validateShowButtonAddBalance();
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        }
    }

    setMethodsEnabled(position, type: string) {
        const nameJobPosition = position.id.split(':')[1].trim().replace(/'/g, '');
        if (this.balance.payment_methods_enabled_id.length > 0) {
            const indexJobPosition = this.balance.payment_methods_enabled_id.indexOf(nameJobPosition);
            if (type === 'select') {
                if (indexJobPosition === -1) {
                    this.balance.payment_methods_enabled_id.push(nameJobPosition);
                }
            }

            if (type === 'unselect') {
                if (indexJobPosition >= 0) {
                    this.balance.payment_methods_enabled_id.splice(nameJobPosition, 1);
                }
            }
        } else {
            if (type === 'select') {
                this.balance.payment_methods_enabled_id.push(nameJobPosition);
            }
        }
        const that = this;
        setTimeout(() => {
            that.verifyCreditCard();
        }, 200);

    }

    saveExpense() {

        this.helperService.showLoadingMxpro360();

        this.expenseService
            .save(this.idEstimate, this.expense)
            .then(() => {
                this.expense = new Expense();
                this.loadExpenses();
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        jQuery(this.addPaymentModal.nativeElement).modal('hide');
    }

    editExpense(expense) {
        this.expense = expense;
        jQuery(this.expensesModal.nativeElement).modal('show');
    }


    public openUploadModal(): void {
        const that = this;
        this.uploadFilesService.openModal().then((response: Array<File>) => {
            // guardar datos que se obtienen del modal
            this.helperService.showLoadingMxpro360();
            for (let file of response) {

                this.expense.vouchers.push(file);

            }

            this.helperService.hideLoadingMxpro360();
        });
    }

    deleteImage(position) {
        this.expense.vouchers.splice(position, 1);
    }

    private loadStorageInvoices(jobId: string) {
        this.storageInvoicesService.getByEstimateId(jobId, true)
            .then(response => {
                this.storageInvocices = response;
            })
    }
    /**
     * toma los datos del storage invoice y los relaciona al balance
     */
    setInvoiceValue() {
        if (this.storageInvociceSelected !== '') {
            for (let storageInvoice of this.storageInvocices) {
                if (this.storageInvociceSelected === storageInvoice.id) {
                    this.balance.amount = storageInvoice.total;
                    this.balance.storage_service_id = storageInvoice.id;
                    this.balance.concept = 'Storage payment';
                    this.disableSaveBalance = false;
                    break;
                }
            }
        }
    }

    private validateFormTabCard(): boolean {
        let isValid = true;
        if (this.paymentGatewaySetting.name == 'SQUARE') {
            return this.paymentProfile.credit_card.holder_name !== null;
        } else {

            switch (null) {
                case this.paymentProfile.credit_card.holder_name:
                case this.paymentProfile.credit_card.number:
                case this.paymentProfile.credit_card.security_code:
                case this.paymentProfile.credit_card.expiration_date.month:
                case this.paymentProfile.credit_card.expiration_date.year:
                    //case this.paymentProfile.card_holder.name:
                    //case this.paymentProfile.card_holder.last_name:
                    isValid = false;
            }
            return isValid;
        }
    }

    sendPayment() {
        this.helperService.showLoadingMxpro360();
        this.buttonPayDisable = true;
        // inicializa datos para post de pagos
        const dataPost: CustomerPayment = new CustomerPayment();

        dataPost.reference = this.balance.id.substring(4, 24);
        dataPost.amount = this.balance.amount;
        dataPost.payment_profile.credit_card = this.paymentProfile.credit_card;
        dataPost.payment_profile.billing_address = this.paymentProfile.billing_address;
        dataPost.payment_profile.card_holder = this.paymentProfile.card_holder;

        // envia pago a api
        return new Promise((resolve) => {
            this.paymentsService.
                sendPaymentAuthorization(dataPost, this.balance.company_id).
                then(async (response) => {
                    this.responseTransaction = response;
                    resolve(true);
                })
                .catch((error) => {
                    this.helperService.showMessageError(error, { balance: this.balance.id, idPaymentMethod: this.paymentMethod.id, dataPost: dataPost });
                    resolve(false);
                })
                .finally(() => {
                    this.buttonPayDisable = false;
                    this.helperService.hideLoadingMxpro360();
                });
        })
    }
    
    verifyCreditCard() {
        this.showGateways = false;
        for(let method of this.methodsPayment) {
            if (method.method == 'CREDIT_CARD') {
                if (this.balance.payment_methods_enabled_id.indexOf(method.id) >= 0) {
                    
                    this.showGateways = true;
                    break;
                }
            }
        }
    }

    selectCCFee() {
        const gatewayID = this.balance.payment_gateway_setting_id;
        this.paymentGatewaySetting = this.gateways.find(gateway => gateway.id === gatewayID);
        this.balance.cc_fee = Math.round(this.balance.amount * this.paymentGatewaySetting.cc_fee) / 100;
    }

    loadGateways() {
        this.paymentGatewaySettingsService
        .getAll()
        .then((gateways: PaymentGatewaySetting[]) => {
            this.gateways = gateways;
        })
    }

    sendEmail(row){
        
        const data = {
            company_id: this.estimate.company_id,
            customer_id: this.estimate.customer_id,
            estimate_id: this.estimate.id,
            estimate_document_id: this.estimate.document.id,
            balance_id: row.id,
            customer: row.customer
        }

        this.helperService.showLoadingMxpro360();
        this.paymentsService
            .sendEmails(data)
            .then((response) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.EMAIL_SENT);
                jQuery(this.paymentModal.nativeElement).modal('hide');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openChangeAmountDueModal() {
        this.changedAmount = this.estimate.deposit_due_balance.altered_amount_due;
        jQuery(this.changeAmountDueModal.nativeElement).modal('show');
    }

    openChangeCompaniesChecksBalancesModal() {
        jQuery(this.changeCompaniesChecksBalancesModal.nativeElement).modal('show');
    }
    
    saveAlteredAmountDue() {
        this.helperService.showLoadingMxpro360();
        jQuery(this.changeAmountDueModal.nativeElement).modal('hide');
        this.depositDueBalancesService.saveAlteredAmountDue(this.estimate.id, this.changedAmount)
        .then(() => {
            this.loadEstimate(this.estimate.id);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    refundCreditCardPayment(balance) {
        swal({
            title: 'Are you sure to refund your credit card transaction?',
            text: 'Do you want to continue?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue'
        })
        .then(async (result) => {
            if (result.value) {
                this.helperService.showLoadingMxpro360();
                this.paymentsService
                    .refund(balance.payment.id)
                    .then(() => {
                        this.helperService.showMessageSnackbar(ConstantsMessages.DELETED);
                        this.loadAllData(this.estimate.id);
                    })
                    .catch((error) => {
                        console.error('error', error)
                        this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                        const THIS = this;
                        setTimeout(() => {
                            THIS.helperService.showMessageSnackbar(error.message);
                        }, 3000);
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            }
        });
    }


    deleteImageInventoryItem(image) {
        for (let i = this.payment.vouchers.length - 1; i >= 0; i--) {
            if (this.payment.vouchers[i].display_name === image.display_name) {
                this.payment.vouchers.splice(i, 1);
                break;
            }
        }
    }

    confirmCertifierFounds(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to confirm this payment?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Confirm!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.paymentsService
                        .confirmPayment(id)
                        .then(() => {
                            this.helperService.showMessageSnackbar(ConstantsMessages.DELETED);
                            this.loadAllData(this.estimate.id);
                            swal(
                                'Confirmed!',
                                'The record has been Confirmed.',
                                'success'
                            );
                        })
                        .catch(() => {

                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }


    saveBrokerBalances() {
        this.helperService.showLoadingMxpro360();
        
        this.estimatesService
            .patchEntity(this.estimate.id, { broker_id: this.estimateBrokerId, broker_pay: Number(this.brokerPay) })
            .then(() => {
                this.estimate.broker_id = this.estimateBrokerId;
                this.estimate.broker_pay = this.brokerPay;
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                jQuery(this.changeCompaniesChecksBalancesModal.nativeElement).modal('hide');
                
            })
            .catch((error) => {
                // this.showErrorSwal(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    async openModalPartialPayment(storageBalance, fixedValue: boolean) {
        this.balance = storageBalance;
        this.isConfirm = fixedValue;
        this.balance.amount_paid = this.balance.amount_paid == null ? 0 : this.balance.amount_paid;
        this.balance.amount = parseFloat(this.balance.amount.toFixed(2));
        this.payment.amount = parseFloat(this.balance.amount.toFixed(2)) - parseFloat(this.balance.amount_paid.toFixed(2));
        this.upToBalance = this.payment.amount;
        this.payment.is_partial_payment = true;
        if (this.balance.payment_gateway_setting_id !== null) {
            await this.loadPaymentGatewaySetting(this.balance.payment_gateway_setting_id);
        }
        jQuery(this.partialPaymentModal.nativeElement).modal('show');
    }

    async savePartialPayment() {        
        this.helperService.showLoadingMxpro360();
        const paymentTypes = ['BANK_TRANSFER_OFFLINE', 'CHECK', 'MONEY_ORDER', 'CASH'];
        if (paymentTypes.includes(this.paymentMethod.method) && !this.authService.hasPermissionCompany('ESTIMATE_BALANCE_CONFIRM_WIHTOUT_VOUCHERS')) {

            if (this.payment.vouchers || this.payment.vouchers.length === 0) {
                swal(
                    'Please attach the voucher',
                    'The Voucher is necessary to validate the payment',
                    'warning'
                );
                this.helperService.hideLoadingMxpro360();
                return;
            }
        }

        if (this.paymentMethod.method === 'CREDIT_CARD') {
            if (!this.validateFormTabCard()) {
                this.helperService.hideLoadingMxpro360();
                swal({
                    title: 'Error',
                    text: 'Complete all fields in the form',
                    type: 'error',
                });
                return;
            }
        }

        if (this.payment.amount > this.upToBalance) {
            swal({
                title: 'Error',
                text: 'The maximum payment value is $' + this.upToBalance.toFixed(2),
                type: 'error',
            });
            return;
        }

        if (!this.payment.date) {
                this.helperService.hideLoadingMxpro360();
                swal({
                    title: 'Error',
                    text: 'Date No Assigned',
                    type: 'error',
                });
                return;
        }

        this.payment.balance_id = this.balance.id;
        this.payment.customer_id = this.balance.customer_id;
        this.payment.employee_id = this.authService.workspaceSession.employee.id;
        this.payment.is_partial_payment = true;
        // contruye data para enviar
        const data: any = { ...this.payment };
        data.amount_employee_sale = this.amountEmployeeSale;
        data.cubic_feets = this.estimate.volume.cubic_feets;

        // Se verifica si es AUTO_TRANSPORT para asignar el numero de vehiculos
        if (this.estimate.service === 'AUTO_TRANSPORT') {
            await this.loadAutoTransport(this.estimate.id);
            data.vehicle_numbers = this.vehicleNumbers;
        }

        if (this.paymentMethod.method === 'CREDIT_CARD') {
            this.helperService.showLoadingMxpro360();
            this.buttonPayDisable = true;
            // inicializa datos para post de pagos
            const dataPost: CustomerPayment = new CustomerPayment();

            dataPost.reference = this.balance.id.substring(4, 24);
            dataPost.payment_profile.credit_card = this.paymentProfile.credit_card;
            dataPost.payment_profile.billing_address = this.paymentProfile.billing_address;
            dataPost.payment_profile.card_holder = this.paymentProfile.card_holder;

            data.customerPayment = dataPost;
            if (this.paymentGatewaySetting.name == 'SQUARE') {
                dataPost.source_id = this.squareToken;
            }
        }
        this.paymentsService
            .savePayment(this.idEstimate, data)
            .then(async () => {
                const dataBalance: any = { ...this.balance };
                dataBalance.is_partial = true;
                // garantiza valor numerico
                dataBalance.amount_paid = dataBalance.amount_paid == null? 0: dataBalance.amount_paid;
                // suma el nuevo valor pagado
                dataBalance.amount_paid += this.payment.amount;
                dataBalance.amount_employee_sale = this.amountEmployeeSale;
                dataBalance.cubic_feets = this.estimate.volume.cubic_feets;
                dataBalance.status = 'PARTIAL_PAID';
                // Se verifica si es AUTO_TRANSPORT para asignar el numero de vehiculos
                if (this.estimate.service === 'AUTO_TRANSPORT') {
                    dataBalance.vehicle_numbers = data.vehicle_numbers;
                }
                
                this.balancesService
                    .setBalance(dataBalance)
                    .then(async () => {
                        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                        this.balance = new BalanceView();
                        this.payment = new Payment();
                        this.loadEstimate(this.estimate.id);
                        await this.loadBalances();
                        this.validateShowButtonAddBalance();
                    })
                    .catch((error) => {
                        console.error('error', error);
                        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                        swal('Declined',
                        error.message,
                        'error');
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.loadBalances();
            });
        jQuery(this.addPaymentModal.nativeElement).modal('hide');
        jQuery(this.partialPaymentModal.nativeElement).modal('hide');
    }

}
