import { Component, OnInit } from '@angular/core';
import { LeadsService } from "src/app/services/leads.service";
import { ActivatedRoute } from "@angular/router";
import { LeadCompanyView } from 'src/app/entities/workspace/lead-company-view';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
@Component({
    selector: 'app-leads-view',
    templateUrl: './leads-view.component.html',
    styleUrls: ['./leads-view.component.scss']
})
export class LeadsViewComponent implements OnInit {
    row: LeadCompanyView;
    constructor(
        private leadsService: LeadsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) { }

    ngOnInit(): void {
     
    }

    ngAfterViewInit(): void{
         // verficamos si se esta editando
         this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });  
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.leadsService
            .getById(id)
            .then((response) => {
                this.row = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
