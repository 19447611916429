import { InventoryItemsService } from 'src/app/services/companies/inventory-items.service';
import { Component, OnInit } from '@angular/core';
import { InventoryItemView } from 'src/app/entities/workspace/inventory-item-view';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;
@Component({
    selector: 'app-inventory-items',
    templateUrl: './inventory-items.component.html',
    styleUrls: ['./inventory-items.component.scss']
})
export class InventoryItemsComponent implements OnInit {

    rows: Array<InventoryItemView>;

    constructor(
        private inventoryItemsQuotes: InventoryItemsService,
        private helperService: HelperService
    ) {
        this.rows = [];
    }

    actualPage = 1;
    filterSearch = '';

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.load();
    }
    
    private load() {
        this.helperService.showLoadingMxpro360();
        this.inventoryItemsQuotes
            .getAllWithFullView()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.inventoryItemsQuotes
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

}
