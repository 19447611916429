import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { TicketType } from 'src/app/entities/workspace/ticket-type';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
  selector: 'app-ticket-type',
  templateUrl: './ticket-type.component.html',
  styleUrls: ['./ticket-type.component.scss']
})
export class TicketTypeComponent implements OnInit {
  /*
     Constantes que contiene el tipo de mensaje a mostrar
     */
  constantsMessages = ConstantsMessages;

  rows: Array<TicketType>;
  actualPage: number;
  filterSearch: string;

  workDepartmentId: string;
  employeeId: string;
  typeTicket: string;
  statusTicket: string;
  priorityTicket: string;

  workDeparments;
  employees;
  typeTickets;
  statusTickets;
  priorityTickets;

  constructor(
    private ticketsTypesService: TicketsTypesService,
    private helperService: HelperService
  ) {
    this.workDepartmentId = '';
    this.employeeId = '';
    this.typeTicket = '';
    this.priorityTicket = '';
    this.statusTicket = '';
    this.filterSearch = '';

    this.rows = [];

    this.employees = [];
    this.typeTickets = [];
    this.statusTickets = [];
    this.priorityTickets = [];
    this.workDeparments = [];

    this.actualPage = 1;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.loadTickets();
  }

  /**
   * Metodo que carga los tickets para mostrarlos en el select
   */
  private loadTickets() {
    this.helperService.showLoadingMxpro360();
    this.ticketsTypesService
      .getAll()
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  removeTickets(id) {
    this.helperService.showLoadingMxpro360();
    this.ticketsTypesService
      .remove(id)
      .then(() => {
        this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
        this.loadTickets();
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        console.error(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
