import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import BandwidthRtc, { RtcStream } from '@bandwidth/webrtc-browser';
import { PhoneCall } from 'src/app/entities/companies/phone-call';
import { PhoneLine } from 'src/app/entities/workspace/phone-line';
import { AuthService } from 'src/app/services/auth.service';
import { BroadcastChannelService } from 'src/app/services/broadcast-channel.service';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { PhoneCallsService } from 'src/app/services/companies/phone-calls.service';
import { HelperService } from 'src/app/services/helper.service';
import { CallService } from 'src/app/services/workspaces/calls.service';
import { PhoneLinesService } from 'src/app/services/workspaces/phone-lines.service';
import { Subscription, interval } from 'rxjs';
import { PhoneExtensionsService } from 'src/app/services/workspaces/phone-extensions.service';

declare var jQuery;
declare var swal;

@Component({
    selector: 'app-oldDialer',
    templateUrl: './dialer.component.html',
    styleUrls: ['./dialer.component.scss']
})
export class OldDialerComponent implements OnInit {

    @ViewChild('callingSection', { static: true }) callingSection: ElementRef;

    @ViewChild('audioElement') audioElement: ElementRef;
    @ViewChild('callTransfer') callTransfer: ElementRef;
    @ViewChild('modalForward') modalForward: ElementRef;
    @ViewChild('modalConference') modalConference: ElementRef;
    @ViewChild('modalNoInternetConnection') modalNoInternetConnection: ElementRef;




    private internetConnection: boolean;
    private isTabAllowed: boolean;
    private lastExecution: number;
    public activeCall: boolean;               // Llamada Activa
    public activePause: boolean;              // pausar Llamada con hold
    public activePhone: boolean;              // Teléfono Activo
    public activePhoneLine: string;           // Línea Telefonica Activa
    public activeSilence: boolean             // Silenciar Llamada
    public activeSilenceConference: boolean;  // Silenciar Conferencia
    public agentNumber: string;               // Número de Teléfono Seleccionado por el Agente
    public agentStatus: string;
    public answerCallback: CallableFunction;
    public bandwidthRtc: BandwidthRtc;
    public call2TimeM: number;
    public call2TimeS: number;
    public callid: string;
    public callid1: string;
    public callid2: string;
    public callIdsConferences: any[];
    public calling: any;
    public callOnHold: boolean;               // Llamada en Espera
    public callSwap: boolean;                 // Intercambio de Llamadas: LLamada Conectada / Llamada en Espera
    public callTimeM: number;
    public callTimeS: number;
    public conected: boolean;
    public connectedCall: boolean;            // Llamada en Línea
    public counterMerge: boolean;
    public custonPhoneNumber: any;
    public dataForm: PhoneCall | any;
    public direction: string;                 // OUTGOING | INCOMING  // Dirección de la Llamada
    public disposition: string;
    public employeeId: string;
    public employeeNums: any;
    public extensionNumber: string;           // Número de extension
    public extensions: any;
    public filterSearch: string;
    public fullName: string;
    public fullName2: string;
    public hold: boolean;
    public id_conference: string;
    public inboundCall: boolean;              // Llamada Entrante
    public incomingCallOnHold: boolean;       // Llamada Entrante en Espera
    public infoCall: any;
    public infoCallLeads: any;
    public intervalCall2Time: any;
    public intervalCallTime: any;
    public mediaRecorder: any;
    public mute: boolean;
    public muteConference: boolean;
    public numberTransferred: number;
    public outboundCall: boolean              // Llamada Saliente
    public participant: string;               // Participante voicemail
    public phoneCallActive: boolean;          // Botón de llamada activa
    public phoneLines: PhoneLine[];
    public phoneNumberCall: string;           // Número de Teléfono de la Llamada Entrante / Saliente
    public phoneNumberCallTwo: string;        // numero de telefono de segunda llamada entrante
    public phoneNumberConference: string;
    public recordedChunks: any[] = [];
    public remoteAudio: HTMLAudioElement;
    public ringing: any;
    public ringingTwo: any;
    public rows: Array<PhoneCall>
    public rtcStream: RtcStream;
    public showIncomingCallOptions: boolean;  // Mostrar opciones en Llamada Entrante
    public showKeypad: boolean;               // Mostrar Keypad
    public showNumericKeypad: boolean;        // Mostrar Teclado Númerico
    public showOptionsKeypad: boolean;        // Mostrar Opciones (Silence | Keypad | Add Call | Transfer)
    public showOutgoingCallOptions: boolean;  // Mostrar opciones en Llamada Saliente
    public socketId: string;
    public soundsEnabled: any[];
    public time: number;
    public token: any;
    public url: string;
    public formPhoneHiden: boolean;

    constructor(
        private callService: CallService,
        private channelService: BroadcastChannelService,
        private clientSocketsService: ClientSocketsService,
        private estimatesService: EstimatesService,
        private helperService: HelperService,
        private phoneCallService: PhoneCallsService,
        private phoneExtensionsService: PhoneExtensionsService,
        private phoneLinesService: PhoneLinesService,
        public authService: AuthService,
    ) {
        this.activeCall = false;
        this.activePause = false;
        this.activePhone = false;
        this.activePhoneLine = '';
        this.activeSilence = false;
        this.activeSilenceConference = false;
        this.agentNumber = '';
        this.agentStatus = 'available';
        this.answerCallback = null;
        this.call2TimeM = 0;
        this.call2TimeS = 0;
        this.callid = '';
        this.callid1 = '';
        this.callid2 = '';
        this.callIdsConferences = [];
        this.calling = new Audio();
        this.callOnHold = false;
        this.callSwap = false;
        this.callTimeM = 0;
        this.callTimeS = 0;
        this.conected = false;
        this.connectedCall = false;
        this.counterMerge = false;
        this.custonPhoneNumber = null;
        this.disposition = 'NO_ANSWERED';
        this.extensionNumber = '';
        this.extensions = [];
        this.fullName = 'Unknown';
        this.fullName2 = 'Unknown';
        this.hold = true;
        this.id_conference = null;
        this.incomingCallOnHold = false;
        this.infoCall = [];
        this.infoCallLeads = [];
        this.internetConnection = true;
        this.intervalCallTime = '';
        this.isTabAllowed = false;
        this.lastExecution = 0;
        this.mute = true;
        this.muteConference = false;
        this.participant = '';
        this.phoneCallActive = false;
        this.phoneLines = [];
        this.phoneNumberCall = '';
        this.phoneNumberCallTwo = '';
        this.phoneNumberConference = '';
        this.remoteAudio = new Audio();
        this.ringing = new Audio();
        this.ringingTwo = new Audio();
        this.showIncomingCallOptions = false;
        this.showKeypad = true;
        this.showNumericKeypad = true;
        this.showOptionsKeypad = false;
        this.showOutgoingCallOptions = false;
        this.soundsEnabled = [];
        this.time = 0;
        this.formPhoneHiden = false;
    }

    ngOnDestroy() {

        this.disconnectNumber();

        // si se cierra la pestaña, actualizamos la pagina para que saque la url de la lista de dialers abiertos
        this.listenCloseSection_DialerUrls();
    }



    ngOnInit(): void {

        console.log("window.name: ", window.name);
        // verificamos si es el unico tab o pestaña abierto perteneciente a la compañia seleccionada, sino cerramos el ultimo
        // this.checkIfItWasOpen_DialerUrls();
        this.fullScreenDialer();
        this.channelService.getPhoneHiden((body) => {
            this.phoneNumberCall = body;
            this.formPhoneHiden = true;
        })
    }

    ngAfterViewInit() {

        this.helperService.phoneNumber.subscribe(async (numberPhone) => {
            this.phoneNumberCall = numberPhone;

        });
        // this.callingSection.nativeElement.click();
        let section;
        let sectionhomeTab;
        section = document.getElementById('text_sms_section');
        section.classList.add('active');
        section.classList.add('show');

        sectionhomeTab = document.getElementById('contact-tab');
        sectionhomeTab.classList.add('active');

        /* this.connectedCallEvent(); */

        /* this.load(); Trae historial de llamadas update */

        setTimeout(() => {

            let socket = this.clientSocketsService.getSocket();

            console.log(this.clientSocketsService.isConnected());
            this.socketId = socket.id;

            socket.on('INCOMING_CALL', (callid, from, to, callback) => {

                console.log('incommning 2', this.incomingCallOnHold);



                this.callIdsConferences.push(callid);

                if (this.callIdsConferences.length === 2) {

                    this.phoneNumberCallTwo = from;
                }

                if (this.connectedCall) {
                    this.answerCallback = callback;
                    this.callid = callid;

                    if (this.callIdsConferences.length === 1) {
                        this.phoneNumberCall = from;
                    }
                    this.incomingCallOnHoldEvent()
                    // this.ringingAudio();
                    this.ringingTwoAudio();
                    this.activeIncomingCalPopUp(true);
                } else {
                    let body = { callId: callid, from: from, to: to }
                    this.channelService.emitMensajeNumber(body);
                    this.answerCallback = callback;
                    this.callid = callid;
                    if (this.callIdsConferences.length === 1) {

                        this.phoneNumberCall = from;
                    }
                    this.incomingCallEvent();
                    // this.ringingTwoAudio();
                    // suena el audio en la segunda llamada //
                    this.ringingAudio();
                    this.activeIncomingCalPopUp(true);

                }

                this.getInfoCall(from);
            });


            socket.on('CALL_ENDED', (call) => {

                console.log('call', call);

                this.phoneNumberCall = '';


                let indice = this.callIdsConferences.indexOf(call.call_id);

                console.log('log call call end', call);

                this.counterMerge = false;
                this.conected = false;

                if (indice !== -1) {
                    // Eliminar el elemento del arreglo utilizando splice
                    this.callIdsConferences.splice(indice, 1);

                    if (indice === 0) {
                        this.phoneNumberCall = '';

                    } else {
                        this.phoneNumberCallTwo = '';
                        this.activeSilenceConference = false;
                    }

                }

                console.log('this.callIdsConferences', this.callIdsConferences);

                if (this.callIdsConferences.length === 0) {
                    this.activeIncomingCalPopUp(false);
                    this.ringingAudioStop();
                    this.ringingTwoAudioStop();
                    this.endCall();
                    this.incomingCallOnHold = false;
                    this.callOnHold = false;
                    this.connectedCall = false;
                } else {

                    this.activeIncomingCalPopUp(false);

                    this.callResetEvent();

                }


            });


            socket.on('INCOMING_CALL_CANCELED', (call) => {



                if (!this.incomingCallOnHold) {

                    this.activeIncomingCalPopUp(false);
                    this.ringingAudioStop();
                    this.ringingTwoAudioStop();
                    this.endCall();
                    this.counterMerge = false;
                    this.conected = false;
                    console.log('pase por aqui colgadod e llamda entrante segunda ');

                    this.phoneNumberCallTwo = '';

                    let indice = this.callIdsConferences.indexOf(call.call_id);
                    if (indice !== -1) {
                        // Eliminar el elemento del arreglo utilizando splice
                        this.callIdsConferences.splice(indice, 1);

                        if (indice == 0) {
                            this.phoneNumberCall = '';

                        } else {
                            this.phoneNumberCallTwo = '';
                            this.activeSilenceConference = false;
                        }
                    } else {
                        console.log("no encontro llamada .");
                    }


                } else {

                    this.activeIncomingCalPopUp(false);
                    this.callResetEvent();
                    this.ringingAudioStop();
                    this.ringingTwoAudioStop();
                    this.phoneNumberCallTwo = '';
                    this.incomingCallOnHold = false;

                    let indice = this.callIdsConferences.indexOf(call.call_id);
                    if (indice !== -1) {
                        // Eliminar el elemento del arreglo utilizando splice
                        this.callIdsConferences.splice(indice, 1);
                    }



                }


                console.log('this.callIdsConference breve las vueltaultimo!!!!', this.callIdsConferences);
                console.log('fullname final 1', this.fullName);
                console.log('fullname final 2', this.fullName2);




            });

            socket.on('INCOMING_CALL_CONNECTED', (call_id, participant_id, token) => {

                this.connectedCallEvent();
                this.getMediaStream();
                this.activeIncomingCalPopUp(false);

                this.token = token;
                setTimeout(() => {

                    this.ringingAudioStop();
                    this.ringingTwoAudioStop();

                    this.bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {

                        this.remoteAudio.srcObject = rtcStream.mediaStream;
                        this.audioElement.nativeElement.srcObject = rtcStream.mediaStream;

                        this.soundsEnabled.push(this.remoteAudio.srcObject.id)

                        console.log('aqui mirar id del audio haber cual es :!', this.remoteAudio.srcObject);


                        this.callingAudioStop();
                    });

                    console.log(this.remoteAudio.srcObject);

                    this.remoteAudio.srcObject

                    this.audioElement.nativeElement.srcObject

                }, 100);

                if (this.callIdsConferences.length === 2) {
                    this.counterTwoCall();
                    setTimeout(() => {
                        // This event will fire any time a new stream is sent to us
                        this.bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {

                            this.remoteAudio.srcObject = rtcStream.mediaStream;
                            this.audioElement.nativeElement.srcObject = rtcStream.mediaStream;

                            this.soundsEnabled.push(this.remoteAudio.srcObject.id)

                            console.log('aqui outgoin id del audio haber cual es :!', this.remoteAudio.srcObject);


                        });

                    }, 100);
                }
                else {
                    this.counter();
                    this.disposition = 'ANSWERED';
                    this.conected = true;
                }

            });

            socket.on('OUTCOMING_CALL_CONNECTED', (call_id, participant_id, token) => {


                this.connectedCallEvent();
                this.callingAudioStop();
                this.activeIncomingCalPopUp(false);
                this.getMediaStream();


                setTimeout(() => {
                    // This event will fire any time a new stream is sent to us
                    this.bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {

                        this.remoteAudio.srcObject = rtcStream.mediaStream;
                        this.audioElement.nativeElement.srcObject = rtcStream.mediaStream;

                        this.soundsEnabled.push(this.remoteAudio.srcObject.id)

                        console.log('aqui outgoin id del audio haber cual es :!', this.remoteAudio.srcObject);


                    });

                }, 100);


                if (this.callIdsConferences.length === 2) {
                    this.counterTwoCall();
                }
                else {
                    this.counter();
                    this.disposition = 'ANSWERED';
                    this.conected = true;
                }
            });


            socket.on('ACCEPT_CALL_VOICEMAIL', (entrante) => {

                if (entrante) {

                    this.activeIncomingCalPopUp(false);

                }

            });
            this.loadExtensions();


        }, 2000);


        let session: any = JSON.parse(localStorage.getItem('session'));
        let id = session.account['id'];
        this.employeeId = id;

        this.draggableModal();
        this.toggleDragCursor();
        this.modalOverlay();
        /* this.closeModalBackdropIncomingCall();  */

        const secondsCounter = interval(6000);

        secondsCounter.subscribe(n => {
            this.verificarConexion()
        });
    }


    /**
     * permite verificar si ya esta abierto otro tab con la misma url, si este es el caso, cerramos la ultima pestaña abierta
     */
    checkIfItWasOpen_DialerUrls() {

        const urlComplete = window.location.href;

        // obtenemos las ulrs abiertas guardadas del localstorage
        const storageDialerURLS = localStorage.getItem('DIALER_URLS');
        let dialerURLS = [];

        if (storageDialerURLS !== null) {
            dialerURLS = JSON.parse(storageDialerURLS) as Array<string>;
        }

        let isFound = false;

        // recorremos las urls almacenadas
        for (const url of dialerURLS) {
            if (url === urlComplete) {
                isFound = true;
                break;
            }
        }

        // creamos un nombre para la ventana
        const dialerWindowName = "dialer_" + this.authService.workspaceSession.company.id;

        // verificamos si ya ha sido abierta en otra pestaña
        if (isFound) {


            // si el valor es null, es porque fue abierta directamente, es decir, no se abrio a traves del menu izquierdo de company
            if (window.name == null) {

                // cerramos la ventana, porque ya se encuentra otra abierta
                window.close();

            } else {

                // como tiene valor, verificamos si la otra pestaña que esta abierta... tiene valor,
                // en caso de que no tenga valor cerramos esa otra pestaña y dejamos abierta esta pestaña

                // creamos una canal de comunicacion para tratar los eventos del dialer entre pestañas
                const bccDIALER = new BroadcastChannel('DIALER');

                // creamos un identificador aleatorio para que no se vaya a presentar errores
                const customID = Math.floor(Math.random() * 9999);

                // enviamos una solicitud a las otras pestañas para verificar si la pestaña que estaba abierta del dialer, esta abierta correctamente
                bccDIALER.postMessage({ action: 'REQUEST_TAB_VERIFICATION', url: urlComplete, name: dialerWindowName, requestID: customID });

                // escuchamos mensajes que se envien desde otras pestañas (tab) del mismo origen: app.moverxpro360.com
                bccDIALER.onmessage = (event) => {

                    const data = event.data as { action: string, url: string, name: string, hasValue: boolean, requestID: number };

                    // verificamos si la accion es la respuesta a la solicitud arriba
                    if (data.action == "RESPONSE_TAB_VERIFICATION" && data.url == urlComplete && data.requestID == customID) {

                        // si tiene valor es porque fue abierto correctamente, entonces, cerramos esta pestaña
                        if (data.hasValue) {

                            // cerramos la ventana, porque ya se encuentra otra pestaña abierta cumpliendo las condiciones
                            window.close();
                        } else {

                            // solicitamos que se cierre la otra pestaña
                            bccDIALER.postMessage({ action: 'REQUEST_CLOSE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });


                            setTimeout(() => {

                                // ejecutamos el listen por si intentan abrir otra pestaña de las mismas, que el sistema no los deje,
                                // si no que abra esta misma pestaña, de esta manera no se afecta el funcionamiento del telefono
                                this.listenWhenOpenIt_DialerUrls();

                            }, 500);

                        }
                    }
                }
            }


        } else {

            // ejecutamos el listen por si intentan abrir otra pestaña de las mismas, que el sistema no los deje,
            // si no que abra esta misma pestaña, de esta manera no se afecta el funcionamiento del telefono
            this.listenWhenOpenIt_DialerUrls();
        }
    }


    /**
     * Escucha cuando mandan la orden de abrir la pestaña o ponerla activa para el navegador
     */
    listenWhenOpenIt_DialerUrls() {

        const urlComplete = window.location.href;

        // obtenemos las ulrs abiertas guardadas del localstorage
        const storageDialerURLS = localStorage.getItem('DIALER_URLS');
        let dialerURLS = [];

        if (storageDialerURLS !== null) {
            dialerURLS = JSON.parse(storageDialerURLS) as Array<string>;
        }

        // indicamos que la actual pestaña esta permitida,
        // esta variable se hace con el objetivo de que cuando se cierre la pestaña ejecute otro codigo
        this.isTabAllowed = true;

        // guardamos la url, para indicar que esa url ya esta abierta entre las pestañas
        dialerURLS.push(urlComplete);
        localStorage.setItem('DIALER_URLS', JSON.stringify(dialerURLS));



        // creamos una canal de comunicacion para tratar los eventos del dialer entre pestañas
        const bccDIALER = new BroadcastChannel('DIALER');

        let lastRequestID = null;


        // escuchamos mensajes que se envien desde otras pestañas (tab) del mismo origen: app.moverxpro360.com
        bccDIALER.onmessage = (event) => {

            const data = event.data as { action: string, url: string, name: string, requestID: number };

            // verificamos si la solicitud es para verificar si la pestaña fue abierta correctamente
            if (data.action == "REQUEST_TAB_VERIFICATION" && data.url == urlComplete) {

                // esto se hace como validacion de que si se cierra la pestaña sea por un flujo correcto que tuvo de validacion
                lastRequestID = data.requestID;

                // devolvemos la respuesta a la solicitud de la verificacion
                bccDIALER.postMessage({ action: 'RESPONSE_TAB_VERIFICATION', url: urlComplete, name: data.name, requestID: data.requestID, hasValue: window.opener !== null });
            }

            // verificamos si la solicitud es para que la pestaña se cierre
            if (data.action == "REQUEST_CLOSE_TAB" && data.url == urlComplete && data.requestID == lastRequestID) {

                // cerramos la pestaña
                window.close();
            }

            // verificamos si la solicitud es para activar la pestaña
            if (data.action == "REQUEST_ACTIVATE_TAB" && data.url == urlComplete) {

                // esto se hace como validacion de que si se cierra la pestaña sea por un flujo correcto que tuvo de validacion
                lastRequestID = data.requestID;

                // le decimos al navegador que active esta pestaña
                window.focus();

                setTimeout(() => {

                    // devolvemos la respuesta a la solicitud, indicando si se habilito la ventana
                    bccDIALER.postMessage({ action: 'RESPONSE_ACTIVATE_TAB', url: urlComplete, name: data.name, isActive: !window.document.hidden });

                }, 500)
            }
        }

    }

    /**
     * Permite actualizar la lista de dialers abiertos para que cuando cierren la pestaña actual se pueda volver a abrir la pestaña
     */
    listenCloseSection_DialerUrls() {

        // si la pestaña estaba permitida, ejecutamos el siguiente codigo
        if (this.isTabAllowed) {

            // obtenemos la url actual del dialer
            const urlComplete = window.location.href;

            // obtenemos las ulrs abiertas guardadas del localstorage
            const storageDialerURLS = localStorage.getItem('DIALER_URLS');

            // si esta linea genera error es porque hay un error con la logica de guardado de la urls de los dialers abiertos en el local storage
            // revisar: sidebar.components.ts y los metodos de este archivo: beforeunloadHandler, listenWhenOpenIt_DialerUrls, checkIfItWasOpen_DialerUrls
            const dialerURLS = JSON.parse(storageDialerURLS) as Array<string>;

            // recorremos las urls, para eliminar las url de la pestaña que se esta cerrando
            for (let index = (dialerURLS.length - 1); index >= 0; index--) {
                if (dialerURLS[index] == urlComplete) {
                    dialerURLS.splice(index, 1);
                }
            }

            localStorage.setItem('DIALER_URLS', JSON.stringify(dialerURLS));
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event: Event): void {
        // Realiza tareas antes de que la pestaña se cierre

        // si se cierra la pestaña, actualizamos la pagina para que saque la url de la lista de dialers abiertos
        this.listenCloseSection_DialerUrls();
        this.disconnectNumber();
    }

    callingAudio() {
        this.calling.src = "../../../../assets/sounds/calling.mp3";
        /* this.load(); Trae historial de llamadas update */
        this.calling.play();
        this.calling.loop = true;
    }

    callingAudioStop() {
        this.calling.pause();
    }

    holdCall() {

        /* Toggle Pause */
        this.activePause = !this.activePause;
        if (this.hold) {
            this.helperService.showLoadingMxpro360();
            this.callService
                .holdCall(this.callid)
                .then((response) => {
                    this.hold = false;
                    this.bandwidthRtc.disconnect();
                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });

        } else {

            this.helperService.showLoadingMxpro360();
            this.callService
                .holdCallResume(this.callid)
                .then((response) => {

                    this.hold = true;
                    this.getMediaStream();


                    // Supongamos que `this.remoteAudio.srcObject` es un MediaStream
                    // Primero, asegúrate de tener una transmisión válida
                    if (this.remoteAudio.srcObject instanceof MediaStream && this.audioElement.nativeElement.srcObject instanceof MediaStream) {
                        // Obteniendo todos los tracks de la transmisión
                        const track1 = this.remoteAudio.srcObject.getTracks();
                        const track2 = this.audioElement.nativeElement.srcObject.getTracks();


                        // Activando todos los tracks
                        track1.forEach(track => {
                            track.enabled = true; // Habilita cada track
                            console.log(`Track1 enabled: ${track.enabled}`);
                        });

                        // Activando todos los tracks
                        track2.forEach(track => {
                            track.enabled = true; // Habilita cada track
                            console.log(`Track2 enabled: ${track.enabled}`);
                        });

                        // Después de habilitar los tracks, la transmisión debería estar activa
                        console.log('MediaStream está activa:', this.remoteAudio.srcObject);
                        console.log('MediaStream está activa:', this.audioElement.nativeElement.srcObject);
                        console.log('testeossooss', response);


                    } else {
                        console.error('srcObject no es un MediaStream');
                    }

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });


        }

    }


    holdCalWait() {

        this.helperService.showLoadingMxpro360();
        this.callService
            .holdCall(this.callid)
            .then((response) => {
                this.hold = false;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    holdCalWaitResume() {

        this.helperService.showLoadingMxpro360();
        this.callService
            .holdCallResume(this.callid)
            .then((response) => {

                this.hold = true;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });

    }

    conference() {
        this.helperService.showLoadingMxpro360();

        this.callService
            .conferences(this.callIdsConferences, this.agentNumber, this.id_conference)
            .then((response) => {
                this.counterMerge = true;
                this.callOnHold = false;
                this.id_conference = response['conference_id'];
                this.activeSilenceConference = true;

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });

    }

    silenceConference() {

        if (this.remoteAudio.srcObject instanceof MediaStream) {
            const track1 = this.remoteAudio.srcObject.getTracks();

            track1.forEach(track => {
                track.enabled = !track.enabled; // Habilita cada track
                console.log(`Track1 enabled: ${track.enabled}`);
            });

        }

        // También verifica srcObject en audioElement
        if (this.audioElement.nativeElement.srcObject instanceof MediaStream) {
            const track2 = this.audioElement.nativeElement.srcObject.getTracks();

            // Activando todos los tracks
            track2.forEach(track => {
                track.enabled = !track.enabled; // Habilita cada track
                console.log(`Track2 enabled: ${track.enabled}`);
            });
        }


        this.activeSilence = !this.activeSilence;

        if (!this.muteConference) {

            this.muteConference = true;
            this.helperService.showLoadingMxpro360();

            this.callService
                .silenceConference(this.id_conference, this.muteConference, this.callIdsConferences)
                .then((response) => {

                    console.log('response silence conference:', response);

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });



        } else {
            this.muteConference = false;

            this.helperService.showLoadingMxpro360();

            this.callService
                .silenceConference(this.id_conference, this.muteConference, this.callIdsConferences)
                .then((response) => {

                    console.log('response silence conference:', response);

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });

        }


    }


    silence() {
        /* Toggle Silence*/
        this.activeSilence = !this.activeSilence;


        this.remoteAudio.muted = !this.remoteAudio.muted;

        if (!this.mute) {

            this.mute = true;
            this.bandwidthRtc.setMicEnabled(this.mute);
            this.audioElement.nativeElement.srcObject.muted = true;


        } else {
            this.mute = false;
            this.bandwidthRtc.setMicEnabled(this.mute);
            this.audioElement.nativeElement.srcObject.muted = false;


        }

    }

    ringingAudio() {
        this.ringing.src = "../../../../assets/sounds/ringing.mp3";
        /* this.load(); Trae historial de llamadas update */
        this.ringing.play();
        this.ringing.loop = true;
    }
    ringingAudioStop() {
        this.ringing.loop = false
        this.ringing.pause();

    }

    ringingTwoAudio() {
        this.ringingTwo.src = "../../../../assets/sounds/beep3.mp3";
        /* this.load(); Trae historial de llamadas update */
        this.ringingTwo.play();
        this.ringingTwo.loop = true;
    }

    ringingTwoAudioStop() {
        this.ringingTwo.loop = false
        this.ringingTwo.pause();

    }




    answerCall() {

        const req = {
            body: {
                callId: this.callid,
                to: this.agentNumber,
                from: this.phoneNumberCall
            }
        }

        this.connectedCallEvent();

        if (this.answerCallback !== null) {
            this.answerCallback();
        }

    }


    hangupAnswerCall() {


        this.helperService.showLoadingMxpro360();
        this.callService
            .hangup(this.callIdsConferences[0])
            .then((response) => {

                this.bandwidthRtc.disconnect();

                setTimeout(() => {

                    this.activeIncomingCalPopUp(false);

                    this.callResetEvent();
                    this.connectedCallEvent();
                    const req = {
                        body: {
                            callId: this.callIdsConferences[1],
                            to: this.agentNumber,
                            from: this.phoneNumberCall
                        }
                    }

                    if (this.answerCallback !== null) {
                        this.answerCallback();
                    }
                    let indice = this.callIdsConferences.indexOf(this.callIdsConferences[0]);
                    if (indice !== -1) {
                        // Eliminar el elemento del arreglo utilizando splice
                        this.callIdsConferences.splice(indice, 1);
                    } else {
                        console.log("no encontro llamada .");
                    }

                }, 1000);

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });


    }



    hangup() {

        const currentTime = new Date().getTime();
        if (currentTime - this.lastExecution >= 10000) {
            this.lastExecution = currentTime;
            // this.saveDataForm();
            this.helperService.showLoadingMxpro360();
            this.callService
                .hangup(this.callid)
                .then((response) => {
                    this.callingAudioStop();
                    this.activeIncomingCalPopUp(false);

                    this.phoneNumberCall = '';

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });
            return;

        }


    }

    hangupTwoCall() {

        const currentTime = new Date().getTime();
        if (currentTime - this.lastExecution >= 10000) {
            this.lastExecution = currentTime;
            // this.saveDataForm();
            this.helperService.showLoadingMxpro360();
            this.callService
                .hangup(this.callIdsConferences[0])
                .then((response) => {
                    this.callingAudioStop();
                    this.activeIncomingCalPopUp(false);

                    this.phoneNumberCall = '';

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });
            return;

        }


    }


    connectionInfo() {
        this.helperService.showLoadingMxpro360();

        console.log('connectionInfo this.socketId', this.socketId);


        const body = {

            agentPhoneNumber: this.agentNumber,
            socket_id: this.socketId,
            employe_id: this.employeeId,
            company_id: this.authService.workspaceSession.company.id

        };

        this.callService
            .send(body)
            .then((response) => {

                this.token = response['token'];
                this.phoneCallActive = true;

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });
        return;
    }



    activeIncomingCalPopUp(active) {

        if (active) {
            this.channelService.activeIncomingCalPopUp('active');


        }
        else {
            this.channelService.activeIncomingCalPopUp('');

        }


    }

    connectionNumber(extensionId) {

        this.helperService.showLoadingMxpro360();

        localStorage.setItem('conection numberss !! socketID', this.socketId);

        this.callService
            .number(extensionId, this.socketId)
            .then((response) => {

                //this.token = response['token'];


            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });
        return;
    }



    disconnectNumber() {

        this.helperService.showLoadingMxpro360();

        console.log('disconect number this.socketId', this.socketId);


        this.callService
            .disconnectNumber(this.socketId, this.agentNumber)
            .then((response) => {
                localStorage.setItem('disconnectNumber', JSON.stringify(this.socketId));


            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });
        return;
    }






    voiceMail = async () => {

        this.helperService.showLoadingMxpro360();
        const body = {
            callId: this.callid,
            to: this.agentNumber,
            from: this.phoneNumberCall
        }
        this.callService
            .voicemail(body)
            .then((response) => {
                this.endCall();
                this.ringingAudioStop();
                this.ringingTwoAudioStop();
                this.phoneNumberCall = '';
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });
        return;

    }


    voiceMail2Call = async () => {

        let callId2 = this.callIdsConferences[1];
        this.helperService.showLoadingMxpro360();
        const body = {
            callId: callId2,
            to: this.agentNumber,
            from: this.phoneNumberCallTwo,
        }
        this.callService
            .voicemail(body)
            .then((response) => {
                this.ringingAudioStop();
                this.ringingTwoAudioStop();
                this.callResetEvent();
                this.connectedCallEvent();
                this.phoneNumberCall = '';
                let indice = this.callIdsConferences.indexOf(this.callIdsConferences[1]);
                if (indice !== -1) {
                    // Eliminar el elemento del arreglo utilizando splice
                    this.callIdsConferences.splice(indice, 1);
                    if (indice == 0) {
                        this.phoneNumberCall = '';

                    } else {
                        this.phoneNumberCallTwo = '';

                    }


                } else {
                    console.log("El elemento no se encuentra en el arreglo.");
                }

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });
        return;

    }


    rejectCall = async () => {

        this.helperService.showLoadingMxpro360();
        const body = {
            callId: this.callid,
        }
        this.callService
            .rejectCall(body)
            .then((response) => {


                this.phoneNumberCall = '';

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });
        return;

    }




    modalCallTransfer = async () => {
        jQuery(this.callTransfer.nativeElement).modal('show');
    }

    tranferCall = async () => {

        this.helperService.showLoadingMxpro360();

        this.callService
            .transferCall(this.numberTransferred, this.callid, this.phoneNumberCall)
            .then((response) => {
                this.endCall();

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        return;

    }

    forward = async () => {
        let numeroSinMasUno = this.custonPhoneNumber;
        this.helperService.showLoadingMxpro360();
        if (this.custonPhoneNumber.startsWith("+1")) {
            // Quitar el "+1" y convertir la parte restante a número
            numeroSinMasUno = this.custonPhoneNumber.substring(2);
        }

        this.callService
            .transferCall(numeroSinMasUno, this.callid, this.phoneNumberCall)
            .then((response) => {
                this.activeIncomingCalPopUp(false);
                this.ringingTwoAudioStop();
                this.ringingAudioStop();
                this.endCall();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        return;

    }


    async callPhone() {
        let phoneNumber = this.phoneNumberCall;

        // Eliminar el prefijo "+1" si está presente
        if (phoneNumber.startsWith("+1")) {
            phoneNumber = phoneNumber.slice(2);
        }

        try {
            // Mostrar el indicador de carga
            this.helperService.showLoadingMxpro360();
            // Realizar la llamada
            const response = await this.callService.callphone(phoneNumber, this.agentNumber);
            // Procesar la respuesta de la llamada
            console.log('Respuesta de la llamada saliente:', response);
            // Obtener y almacenar el token de la respuesta
            this.token = response['token'];
            // Iniciar el audio de llamada
            this.callingAudio();
            console.log("Ringing...");

            // Obtener y almacenar el ID de la llamada
            this.callid = response['callId'];
            console.log('Respuesta del ring ring:', response);

            // Agregar el ID de la llamada a la lista de conferencias
            this.callIdsConferences.push(this.callid);

        } catch (error) {
            // Manejar cualquier error que ocurra durante el proceso de la llamada
            console.error('Error:', error);
        } finally {
            // Ocultar el indicador de carga independientemente del resultado de la llamada
            this.helperService.hideLoadingMxpro360();
        }
    }




    callConference = async () => {

        let numeroSinMasUno = this.phoneNumberConference;
        this.helperService.showLoadingMxpro360();
        if (this.phoneNumberConference.startsWith("+1")) {
            // Quitar el "+1" y convertir la parte restante a número
            numeroSinMasUno = this.phoneNumberConference.substring(2);
        }

        this.helperService.showLoadingMxpro360();
        this.callService
            .callphone(numeroSinMasUno, this.agentNumber)
            .then((response) => {


                this.callingAudio();
                console.log("Ringing...");
                this.callid = response['callId'];
                console.log('response del ring ring  :', response);
                this.callIdsConferences.push(this.callid);


            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();

            });

        return;

    }




    getMediaStream = async () => {

        try {
            navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then((stream) => {

                    //suena diaemas
                    this.remoteAudio.srcObject = stream;

                    this.connectBandwodthRtc();
                    // Aquí tienes acceso a tu MediaStream con las pistas de audio y video
                })
                .catch(function (error) {
                    console.log("error de aqui stream", error);
                    // Manejo de errores si el usuario no permite el acceso a la cámara y el micrófono
                });
        } catch (error) {
            console.log("Error al obtener el stream de medios:", error);
        }
    };

    connectBandwodthRtc() {



        this.bandwidthRtc = new BandwidthRtc();

        if (this.token) {

            console.log('entre al token this.token', this.token);

            // Connect to Bandwidth WebRTC
            this.bandwidthRtc
                .connect({
                    deviceToken: this.token,
                })
                .then(async () => {
                    console.log("connected to bandwidth webrtc!");
                    // Publish the browser's microphone
                    await this.bandwidthRtc.publish({
                        audio: true,
                        video: false,
                    });
                    /*
                              this.bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {
                    
                                this.remoteAudio.srcObject = rtcStream.mediaStream;
                                this.audioElement.nativeElement.srcObject = rtcStream.mediaStream;
                    
                                this.soundsEnabled.push(this.remoteAudio.srcObject.id)
                    
                                console.log('aqui mirar id del audio haber cual es :!', this.remoteAudio.srcObject);
                    
                    
                                this.callingAudioStop();
                              }); */


                    // This event will fire any time a stream is no longer being sent to us
                    this.bandwidthRtc.onStreamUnavailable((endpointId: any) => {
                        console.log("no longer receiving audio");
                        // this.endCall();
                        this.ringingAudioStop();
                        this.ringingTwoAudioStop();


                    })
                })
                .catch(function (error) {
                    console.log("error de conected stream", error);
                    // Manejo de errores si el usuario no permite el acceso a la cámara y el micrófono
                });


        }
    }




    // Se muestran los números que se marcan con el teclado del teléfono en el display
    dialedNumber(number) {


        if (this.activeCall === true) {
            this.bandwidthRtc.sendDtmf(number);
        }

        if (this.activePhone) {
            if (this.phoneNumberCall.length < 10) {
                this.phoneNumberCall = this.phoneNumberCall + number;
            }
        }
    }

    // Boton para borrar los números en el display del teléfono
    deleteNumber() {
        this.phoneNumberCall = this.phoneNumberCall.slice(0, -1);
    }


    // Funcion de Llamada Saliente - Frontend
    outgoingCallEvent() {


        this.direction = 'OUTGOING';
        this.activeCall = true;
        this.showNumericKeypad = false;
        this.showOptionsKeypad = false;
        this.showOutgoingCallOptions = true;
    }

    // Funcion de Llamada Entrante - Frontend
    incomingCallEvent() {


        this.direction = 'INCOMING'
        this.activeCall = true;
        this.showNumericKeypad = false;
        this.showOptionsKeypad = false;
        this.showIncomingCallOptions = true;
    }

    // Funcion de Llamada Conectada - Frontend
    connectedCallEvent() {

        this.connectedCall = true;
        this.activeCall = true;
        this.showNumericKeypad = false;
        this.showOptionsKeypad = true;
        this.showIncomingCallOptions = false;
        this.showOutgoingCallOptions = false;
        this.closeModalIncomingCall();
    }

    // Funcion de Finalizar Llamada - Frontend
    endCall() {
        this.connectedCall = false;
        this.activeCall = false;
        this.showNumericKeypad = true;
        this.showOptionsKeypad = false;
        this.showIncomingCallOptions = false;
        this.showOutgoingCallOptions = false;
        this.activeSilence = false;
        this.activePause = false;
        this.audioElement.nativeElement.srcObject = null;
        this.bandwidthRtc.disconnect();
        this.callingAudioStop();
        this.activeIncomingCalPopUp(false);
        this.hold = true;
        this.formPhoneHiden = false;


    }

    // Contador de segundos de la llamada



    counter() {
        clearInterval(this.intervalCallTime);
        this.callTimeS = 0;
        this.callTimeM = 0;
        this.disposition = 'ANSWERED';
        let that = this;
        this.intervalCallTime = setInterval((): void => {
            that.callTimeS++;
            if (this.callTimeS === 60) {
                that.callTimeS = 0;
                this.callTimeM++;
            }
        }, 1000);

    };

    counterTwoCall() {
        clearInterval(this.intervalCall2Time);
        this.call2TimeS = 0;
        this.call2TimeM = 0;
        this.disposition = 'ANSWERED';
        let that = this;
        this.intervalCall2Time = setInterval((): void => {
            that.call2TimeS++;
            if (this.call2TimeS === 60) {
                that.call2TimeS = 0;
                this.call2TimeM++;
            }
        }, 1000);
    };


    private loadListNums() {
        this.phoneCallService
            .getListPhone()
            .then((response) => {
                this.employeeNums = response;
                //aqui
                for (const phoneline of this.employeeNums) {
                    if (phoneline.main === true) {

                        this.agentNumber = phoneline.phone_number;
                        this.switchActivatePhone();

                        const switchActivatePhone = document.getElementById('active_phone') as HTMLInputElement;
                        switchActivatePhone.checked = true;
                    }

                }


            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    };

    private loadExtensions() {

        this.helperService.showLoadingMxpro360();
        this.phoneExtensionsService
            .getAllExtensions()
            .then((response) => {

                for (const extension of response) {

                    if (this.authService.workspaceSession.employee.id === extension.employee_id) {

                        this.extensions.push(extension);

                    }


                }



            })
            .catch((error) => {
                console.log('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    };

    selectPhoneNumber(agentNumberId) {
        swal({
            title: 'Set Up New Number',
            text: 'Do you want to select this phone number?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Select!'
        })
            .then((result) => {
                if (result.value) {
                    this.extensionNumber = agentNumberId.extension;
                    this.agentNumber = agentNumberId.id;

                }
            });
    }

    /* Interruptor para Activar el Teléfono */
    switchActivatePhone() {

        const switchActivatePhone = document.getElementById('active_phone') as HTMLInputElement;
        const phonePowerBtn = document.getElementById("phone_power_btn") as HTMLInputElement;
        console.log('switchActivatePhone.checked', switchActivatePhone.checked);


        if (switchActivatePhone.checked) {
            phonePowerBtn.classList.add("off");
            phonePowerBtn.classList.remove('on');
            this.activePhone = false;
            this.helperService.setNumberDialer.emit(null);
            this.disconnectNumber();
            this.agentStatus = '';

        } else {
            this.helperService.setNumberDialer.emit(this.agentNumber);
            this.connectionNumber(this.agentNumber);
            phonePowerBtn.classList.add("on");
            phonePowerBtn.classList.remove('off');
            this.activePhone = true;
            this.agentStatus = 'available';

        }



    }


    handleDataAvailable = async (event: { data: { size: number } }) => {
        const {
            data: { size },
        } = event;
        if (size > 0) {
            const { data } = event;
            this.recordedChunks.push(data);

        }
    };


    /* Minimizar Teléfono */
    toggleCollapsePhone() {
        const phone_section = document.getElementById('phone_section');
        if (phone_section.classList.contains('open-phone')) {
            phone_section.classList.remove('open-phone');
            phone_section.classList.add('closed-phone');
        } else {
            phone_section.classList.remove('closed-phone');
            phone_section.classList.add('open-phone');
        }
    }


    /* Dialer en Pantalla Completa */
    fullScreenDialer() {
        jQuery('app-header').hide();
        jQuery('app-sidebar').hide();
        jQuery('app-panel-layout > #container > #content').css({ margin: 0 });
    }


    /* Mostrar modal backdrop */
    private showModalBackdrop() {
        const modalBackdropIncomingCall = document.querySelector('.modal-backdrop-incoming-call');
        modalBackdropIncomingCall.classList.remove('hide-modal');
        modalBackdropIncomingCall.classList.add('show-modal');
    }

    /* Mostrar modal - Responder - Llamada entrante */
    showModalAnswerIncomingCall() {
        const modalIncomingCall = document.getElementById('phone_content');
        modalIncomingCall.classList.add('modal-incoming-call', 'answer-call');

        /* cerrar modal backdrop */
        this.showModalBackdrop();
    }

    /* mostrar modal - Cancelar - Llamada entrante */
    showModalCancelIncomingCall() {
        const modalIncomingCall = document.getElementById('phone_content');
        modalIncomingCall.classList.add('modal-incoming-call', 'cancel-call');

        /* cerrar modal backdrop */
        this.showModalBackdrop();
    }

    /* Cerrar Modal Incoming call */
    closeModalIncomingCall() {
        const modalIncomingCallElements = document.getElementById('phone_content');
        modalIncomingCallElements.classList.remove('modal-incoming-call', 'answer-call', 'cancel-call');

        const modalBackdropIncomingCall = document.querySelector('.modal-backdrop-incoming-call');
        modalBackdropIncomingCall.classList.remove('show-modal');
        modalBackdropIncomingCall.classList.add('hide-modal');
    }



    /* Mostar - Modal Forward */
    openModalForward() {
        jQuery(this.modalForward.nativeElement).modal('show');
        this.getFordwardablePhoneLines();
    }

    /* Mostar - Modal conference */
    openModalConference() {
        jQuery(this.modalConference.nativeElement).modal('show');
        this.getFordwardablePhoneLines();
    }

    /* Mostar - Modal Incoming Call Info */
    showModalIncomingCallInfo() {
        const modalIncomingCallInfo = document.getElementById('modal_incoming_call_info');
        modalIncomingCallInfo.classList.add('show-modal');
        modalIncomingCallInfo.classList.remove('hide-modal');
    }

    /* Ocultar - Modal Incoming Call Info */
    closeModalIncomingCallInfo() {
        const modalIncomingCallInfo = document.getElementById('modal_incoming_call_info');
        modalIncomingCallInfo.classList.add('hide-modal');
        modalIncomingCallInfo.classList.remove('show-modal');
        modalIncomingCallInfo.style.left = 'auto';
        modalIncomingCallInfo.style.top = '122px';
    }


    /* Mostar - Modal Incoming Call History */
    showModalIncomingCallHistory() {
        const modalIncomingCallHistory = document.getElementById('modal_incoming_call_history');
        modalIncomingCallHistory.classList.add('show-modal');
        modalIncomingCallHistory.classList.remove('hide-modal');
    }

    /* Ocultar - Modal Incoming Call History */
    closeModalIncomingCallHistory() {
        const modalIncomingCallHistory = document.getElementById('modal_incoming_call_history');
        modalIncomingCallHistory.classList.add('hide-modal');
        modalIncomingCallHistory.classList.remove('show-modal');
        modalIncomingCallHistory.style.left = 'auto';
        modalIncomingCallHistory.style.top = '122px';
    }




    private getFordwardablePhoneLines() {
        this.phoneLinesService
            .getFordwardablePhoneLines(this.authService.workspaceSession.company.id)
            .then((response) => {

                this.phoneLines.push(response.phone_lines);

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });
    };
    public custonPhone(phone) {

        this.custonPhoneNumber = phone;

    }

    public customPhoneNumberConference(phone) {
        this.phoneNumberConference = phone;
    }


    public PhoneCuston() {

        this.callService
            .forward(this.custonPhoneNumber, this.callid)

            .then((response) => {

            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            });



    }


    /* Se convierte la modal en arrastrable */
    private draggableModal() {

        jQuery("#modal_incoming_call_info").draggable({
            handle: "#incoming_call_info_modal_header",
        });

        jQuery("#modal_incoming_call_history").draggable({
            handle: "#incoming_call_history_modal_header",
        });

    }


    /* Alternar el diseño del cursor al arrastrar la modal */
    private toggleDragCursor() {

        const headerInfoElement = document.getElementById('incoming_call_info_modal_header');
        const headerHistoryElement = document.getElementById('incoming_call_history_modal_header');

        if (headerInfoElement) {

            headerInfoElement.addEventListener('mousedown', () => {
                headerInfoElement.style.cursor = 'grabbing';
            });

            headerInfoElement.addEventListener('mouseup', () => {
                headerInfoElement.style.cursor = 'grab';
            });
        }

        if (headerHistoryElement) {

            headerHistoryElement.addEventListener('mousedown', () => {
                headerHistoryElement.style.cursor = 'grabbing';
            });

            headerHistoryElement.addEventListener('mouseup', () => {
                headerHistoryElement.style.cursor = 'grab';
            });

        }

    }

    /* Sobreponer la modal clickeada */
    private modalOverlay() {

        const modalInfoElement = document.getElementById('modal_incoming_call_info');
        const modalHistoryElement = document.getElementById('modal_incoming_call_history');

        if (modalInfoElement) {

            modalInfoElement.addEventListener('mousedown', () => {
                modalInfoElement.style.zIndex = ' 100';
                modalHistoryElement.style.zIndex = '99';
            });

        }

        if (modalHistoryElement) {

            modalHistoryElement.addEventListener('mousedown', () => {
                modalHistoryElement.style.zIndex = '100';
                modalInfoElement.style.zIndex = '99';
            });

        }

    }

    getInfoCall(from) {

        console.log('entre');

        this.estimatesService
            .getByEstimateCode(from.replace("+1", ""))
            .then((response: any) => {
                if (response.message) {
                    // mensaje de no encntrado
                    this.infoCall = [];
                    this.infoCallLeads = [];
                    this.helperService.showMessageSnackbar(response.message);

                } else {

                    this.infoCall = response.estimates;
                    this.infoCallLeads = response.leads;

                    console.log('this.callIdsConferences segus llamdas', this.callIdsConferences);


                    if (this.callIdsConferences.length === 1) {

                        if (this.infoCall.length > 0) {
                            this.fullName = this.infoCall[0].customer.name + ' ' + this.infoCall[0].customer.last_name;
                            console.log('fullname infocall testeo  ', this.fullName);
                        }
                        if (this.infoCallLeads.length > 0) {
                            this.fullName = this.infoCallLeads[0].lead.customer.name + ' ' + this.infoCallLeads[0].lead.customer.last_name;
                            console.log('fullname infocallLeads', this.fullName);
                        }
                    } else if (this.callIdsConferences.length === 2) {

                        if (this.infoCall.length > 0) {
                            this.fullName2 = this.infoCall[0].customer.name + ' ' + this.infoCall[0].customer.last_name;
                            console.log('fullname infocall 2', this.fullName2);

                        }
                        if (this.infoCallLeads.length > 0) {
                            this.fullName2 = this.infoCallLeads[0].lead.customer.name + ' ' + this.infoCallLeads[0].lead.customer.last_name;
                            console.log('fullname infocallLeads 2', this.fullName2);
                        }
                    }

                    this.channelService.emitMensajeName(this.fullName);

                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    verificarConexion() {

        let that = this;

        const init = {
            method: "get",
            headers: {
                "content-type": "application/json;charset=UTF-8",
            },
        }
        fetch('https://api.beta.mxp360.testaproject.com/calculator', init)
            .then(function (response) {
                if (response.status === 200) {
                    if (that.internetConnection == false) {
                        window.location.reload();
                    }
                } else {
                    jQuery(that.modalNoInternetConnection.nativeElement).modal('show');
                    that.internetConnection = false;
                }
            }).catch(function () {
                jQuery(that.modalNoInternetConnection.nativeElement).modal('show');
                that.internetConnection = false;
            });
    }


    incomingCallOnHoldEvent() {
        this.incomingCallOnHold = !this.incomingCallOnHold;

    }

    callOnHoldEvent() {
        this.incomingCallOnHold = false;
        this.callOnHold = true;
    }

    // contestar llamada en espera y poner en hold la llamda en la que estoy
    answerCallAndHold() {

        const req = {
            body: {
                callId: this.callid,
                to: this.agentNumber,
                from: this.phoneNumberCall
            }
        }
        let socket = this.clientSocketsService.getSocket();

        socket.emit('ACCEPT_CALL_INCOMMING', req);

        this.callOnHoldEvent();

        if (this.answerCallback !== null) {
            this.answerCallback();

            //aqui ponemos en hold la primera llamada
            this.callService
                .holdCall(this.callid1)
                .then((response) => {

                    setTimeout(() => {
                        // This event will fire any time a new stream is sent to us
                        this.bandwidthRtc.onStreamAvailable((rtcStream: RtcStream) => {

                            this.remoteAudio.srcObject = rtcStream.mediaStream;
                            this.audioElement.nativeElement.srcObject = rtcStream.mediaStream;

                            this.soundsEnabled.push(this.remoteAudio.srcObject.id)

                            console.log('aqui outgoin id del audio haber cual es :!', this.remoteAudio.srcObject);


                        });

                    }, 1000);

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });

        }

    }



    // metedo utilizado para hacer el intecambio de las lineas cuando tengo dos llamdas

    callSwapEvent() {

        this.callid1 = this.callIdsConferences[0];
        this.callid2 = this.callIdsConferences[1];

        if (this.callSwap) {

            this.helperService.showLoadingMxpro360();

            //aqui ponemos en hold la primera llamada
            this.callService
                .holdCall(this.callid1)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });


            this.helperService.showLoadingMxpro360();

            // aqui enganchamos la segunda llamada con el navegador
            this.callService
                .holdCallResume(this.callid2)
                .then((response) => {
                    this.getMediaStream();

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });


        } else {

            //aqui ponemos en hold la segunda llamada

            this.helperService.showLoadingMxpro360();
            this.callService
                .holdCall(this.callid2)
                .then((response) => {

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });

            // aqui enganchamos la primera llamada con el navegador

            this.helperService.showLoadingMxpro360();
            this.callService
                .holdCallResume(this.callid1)
                .then((response) => {

                    this.getMediaStream();

                })
                .catch((error) => {
                    console.error('error', error);
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();

                });


        }


        this.callSwap = !this.callSwap;


    }

    hangupcallOnHold() {

        this.callid1 = this.callIdsConferences[0];
        this.callid2 = this.callIdsConferences[1];

        if (this.callSwap) {

            const currentTime = new Date().getTime();
            if (currentTime - this.lastExecution >= 10000) {
                this.lastExecution = currentTime;
                this.helperService.showLoadingMxpro360();
                this.callService
                    .holdCallResume(this.callid2)
                    .then((response) => {

                    })
                    .catch((error) => {
                        console.error('error', error);
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();

                    });


                this.helperService.showLoadingMxpro360();
                this.callService
                    .holdCall(this.callid1)
                    .then((response) => {

                        this.helperService.showLoadingMxpro360();
                        this.callService
                            .hangup(this.callid1)
                            .then((response) => {
                                this.activeIncomingCalPopUp(false);

                                this.phoneNumberCall = '';
                                this.callResetEvent();
                                let indice = this.callIdsConferences.indexOf(this.callIdsConferences[0]);
                                if (indice !== -1) {
                                    // Eliminar el elemento del arreglo utilizando splice
                                    this.callIdsConferences.splice(indice, 1);
                                } else {
                                    console.log("no encontro llamada .");
                                }

                            })
                            .catch((error) => {
                                console.error('error', error);
                            })
                            .finally(() => {
                                this.helperService.hideLoadingMxpro360();

                            });


                    })
                    .catch((error) => {
                        console.error('error', error);
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });


            }

        } else {

            const currentTime = new Date().getTime();

            if (currentTime - this.lastExecution >= 10000) {

                this.lastExecution = currentTime;
                this.helperService.showLoadingMxpro360();
                this.callService
                    .holdCallResume(this.callid1)
                    .then((response) => {


                    })
                    .catch((error) => {
                        console.error('error', error);
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();

                    });

                this.helperService.showLoadingMxpro360();
                this.callService
                    .holdCall(this.callid2)
                    .then((response) => {
                        this.helperService.showLoadingMxpro360();
                        this.callService
                            .hangup(this.callid2)
                            .then((response) => {
                                this.activeIncomingCalPopUp(false);

                                this.phoneNumberCall = '';
                                this.callResetEvent();
                                let indice = this.callIdsConferences.indexOf(this.callIdsConferences[1]);
                                if (indice !== -1) {
                                    // Eliminar el elemento del arreglo utilizando splice
                                    this.callIdsConferences.splice(indice, 1);
                                } else {
                                    console.log("El elemento no se encuentra en el arreglo.");
                                }

                            })
                            .catch((error) => {
                                console.error('error', error);
                            })
                            .finally(() => {
                                this.helperService.hideLoadingMxpro360();

                            });


                    })
                    .catch((error) => {
                        console.error('error', error);
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            }
        }


    }

    callResetEvent() {
        this.incomingCallOnHold = false;
        this.callOnHold = false;
    }

    isReady() {
        this.channelService
            .getPhoneHiden((body) => {
                this.phoneNumberCall = body;
                this.formPhoneHiden = true;
            })
    }

};
