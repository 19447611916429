import { TariffVersion } from "./tariff-version";

export class EstimateRouteTariff {
    public id: string;
    public cf_fees: CFFees[];
    public min_cf: number;
    public cf_cost: number;
    public manual_rate: boolean;
    public cf_discount: number;
    public manual_cf_discount: number;
    public packing_cost: number;
    public packing_discount: number;
    public route_rate_id: string;
    public tariff_version: TariffVersion;
    public lock_the_rate: boolean;
    public mode: 'CUBIC_FEET' | 'POUNDS';

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.cf_fees = [new CFFees];
        this.min_cf = null;
        this.cf_cost = null;
        this.manual_rate = null
        this.cf_discount = null;
        this.manual_cf_discount = null;
        this.packing_cost = null;
        this.packing_discount = null;
        this.route_rate_id = null;
        this.tariff_version = new TariffVersion();
        this.lock_the_rate = false;
        this.mode = null;
    }
}
// clase secundaria para validacion de minimo cf en una venta 
export class CFFees {
    public cost: number;
    public id: string;
    public up_to: number;

    constructor() {
        this.cost = 0;
        this.id = '';
        this.up_to = 0;
    }
}
