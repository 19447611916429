<div class="dateFilterContetComponent">
    <div class="radioButtons">
        <div class="radio-in-line">
            <label class="new-control new-radio radio-warning">
                <input type="radio" class="new-control-input" name="custom-radio-1" [checked]="selectDateType == 1" [(ngModel)]="selectDateType" (change)="setTypeDateFilter()" [value]="1">
                <span class="new-control-indicator"></span>TODAY
            </label>

            <label class="new-control new-radio radio-warning">
                <input type="radio" class="new-control-input" name="custom-radio-1" [(ngModel)]="selectDateType" (change)="setTypeDateFilter()" [value]="2">
                <span class="new-control-indicator"></span>THIS WEEK
            </label>

            <label class="new-control new-radio radio-warning">
                <input type="radio" class="new-control-input" name="custom-radio-1" [(ngModel)]="selectDateType" (change)="setTypeDateFilter()" [value]="5">
                <span class="new-control-indicator"></span>LAST WEEK
            </label>

            <label class="new-control new-radio radio-warning">
                <input type="radio" class="new-control-input" name="custom-radio-1" [(ngModel)]="selectDateType" (change)="setTypeDateFilter()" [value]="3">
                <span class="new-control-indicator"></span>THIS MONTH
            </label>

            <div class="month-date-filter" [hidden]="selectDateType != 3">
                <select class="months" name="month" (ngModelChange)="listenChanges()" [(ngModel)]="monthSelected" [appSelect2]>
                    <option value="0"> Janaury </option>
                    <option value="1"> February </option>
                    <option value="2"> March </option>
                    <option value="3"> April </option>
                    <option value="4"> May </option>
                    <option value="5"> June </option>
                    <option value="6"> July </option>
                    <option value="7"> August </option>
                    <option value="8"> September </option>
                    <option value="9"> October </option>
                    <option value="10"> November </option>
                    <option value="11"> December </option>
                </select>
            </div>

            <label class="new-control new-radio radio-warning">
                <input type="radio" class="new-control-input" name="custom-radio-1" [(ngModel)]="selectDateType" (change)="setTypeDateFilter()" [value]="4">
                <span class="new-control-indicator"></span>BETWEEN DATES
            </label>
            
            <div class="selectBetweenDate" [hidden]="selectDateType != 4">
                <app-date-range-picker [placeholder]="'Not Assigned'" (onChange)="onRange($event)" [valueStart]="betweenFilter.dateStart" [valueEnd]="betweenFilter.dateEnd"></app-date-range-picker>
            </div>
        </div>
    </div>

    <!-- <div class="selectMonth" [hidden]="selectDateType != 3">
        <div class="row d-center">
            <div class="col-5 text-right label">
                <strong>
                    Select Month:
                </strong>
            </div>
            <div class="col-6 text-left">
                <select class="months" name="month" (ngModelChange)="listenChanges()" [(ngModel)]="monthSelected" [appSelect2]>
                    <option value="0"> Janaury </option>
                    <option value="1"> February </option>
                    <option value="2"> March </option>
                    <option value="3"> April </option>
                    <option value="4"> May </option>
                    <option value="5"> June </option>
                    <option value="6"> July </option>
                    <option value="7"> August </option>
                    <option value="8"> September </option>
                    <option value="9"> October </option>
                    <option value="10"> November </option>
                    <option value="11"> December </option>
                </select>
            </div>
        </div>
    </div> -->

    <!-- <div class="selectBetweenDate" [hidden]="selectDateType != 4">
        <div class="row d-center">
            <div class="col-5 text-right label">
                <strong>
                    Between Dates: 
                </strong>
            </div>
            <div class="col-6 text-left">
                <app-date-range-picker [placeholder]="'Not Assigned'" (onChange)="onRange($event)" [valueStart]="betweenFilter.dateStart" [valueEnd]="betweenFilter.dateEnd"></app-date-range-picker>
            </div>
        </div>
    </div> -->
</div>