import { Component, OnInit } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { HelperService } from 'src/app/services/helper.service';
import { ConstantsMessages } from 'src/app/constants-messages';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { CarrierReportView } from 'src/app/entities/workspace/carrier-report-view';



declare const jQuery;
declare const swal;
declare const moment;
@Component({
    selector: 'app-carriers',
    templateUrl: './carriers.component.html',
    styleUrls: ['./carriers.component.scss']
})
export class CarriersComponent implements OnInit {

    carriers: Array<CarrierReportView>;
    name: string;
    phone: string;
    constantsMessages = ConstantsMessages;

    // variable indicar donde se almacenará las fechas seleccionadas
    betweenFilter: BetweenDates;

    constructor(
        private carrierService: CarriersService,
        private helperService: HelperService,
        private authService: AuthService,
    ) {
        this.betweenFilter = new BetweenDates();
    }
    actualPage = 1;
    filterSearch = '';

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.load();
    }
    
    private load() {
        this.betweenFilter.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
        this.betweenFilter.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;

        const data = {
            start: this.betweenFilter.dateStart,
            end: this.betweenFilter.dateEnd
        };

        this.helperService.showLoadingMxpro360();
        this.carrierService
            .getReportCarriers(data)
            .then((response) => {
                this.carriers = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setFrom(from) {
        /*
        this.dataForm.address = from;
        */
    }

    remove(id) {

        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.carrierService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'Record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {

                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

    copyText(text: string) {

        let url = environment.app.base + '/#/carriers/login?workspace_id=' + this.authService.workspaceSession.id + '&carrier_id=' + text;

        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (url));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(this.constantsMessages.COPIED);
    }

    public openCarrierDetail(idCarrier): void {
        this.helperService.goToWorkspaceRouterLink('/carriers/' + idCarrier + '/view');
    }

}
