<!-- <div class="widget widget-one_hybrid widget-followers" *ngIf="jobsHowManyBookingsTheyGotInTheLast7Days">
    <div class="widget-heading">
        <h5>
            My Bookings (Last 7 Days)
        </h5>
    </div>
    <div class="widget-content pt-3">
        <div class="text-center">
            <h1>
                {{jobsHowManyBookingsTheyGotInTheLast7Days.total_amount | currency}}
            </h1>
            <h4>
                {{jobsHowManyBookingsTheyGotInTheLast7Days.total_jobs}}
            </h4>
        </div>
    </div>
</div> -->



<div class="widget-one widget counter">
    <div class="widget-content">
        <div class="w-numeric-value">
            <div class="col-12 w-content">
                <!-- <span class="w-value">{{jobsTotalIncomingLeads.total}}</span> -->
                <div class="w-text margin-b10">My Bookings (Last 7 Days)</div>
                <div class="w-text d-flex align-items-center font-s18">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                    {{jobsHowManyBookingsTheyGotInTheLast7Days.total_jobs}}
                </div>
            </div>
            <div class="col-12 w-content-center">
                <span class="w-numeric-quantity"> {{jobsHowManyBookingsTheyGotInTheLast7Days.total_amount | currency}}</span>
            </div>
        </div>
        <div class="w-chart">
            <div id="boking-last7-days"></div>
        </div>
    </div>
</div>
