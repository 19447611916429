import { Component, OnInit } from '@angular/core';
import { Zone } from "src/app/entities/workspace/zone";
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionsSettingsService } from 'src/app/services/workspaces/tariff-versions-settings.service';
import { ZonesService } from "src/app/services/workspaces/zones.service";

declare const jQuery;
declare const swal;
@Component({
    selector: 'app-zones',
    templateUrl: './zones.component.html',
    styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {
    rows: Array<Zone>;
    actualPage = 1;
    tariffVersionId: string;

    constructor(
        private zonesService: ZonesService,
        private helperService: HelperService,
        public tariffVersionsSettingsService: TariffVersionsSettingsService,
        ) { 
            this.tariffVersionId = '';
        }

    ngOnInit(): void {
        
    }
    
    ngAfterViewInit() {
        this.load();
    }
    
    private load() {
        this.tariffVersionsSettingsService.getZones();
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.zonesService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('error', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

}
