<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Volumes
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard">
                                <i class="fas fa-home"></i>
                                Dashboard
                            </a>
                        </li>
                       
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/settings/volumes">Volumes</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">

                </div>

            </div>
        </div>
        <!-- header -->


        <div class="statbox widget box box-shadow">

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">
                        <div class="col-12">

                            <div class="form-group mb-1">
                                <label class="control-label">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                    </div>
                                    <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Volumes Name.</small>
                            </div>


                            <div class="form-group mb-1">
                                <label class="control-label">Up to Cf</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"> <svg _ngcontent-ujs-c6=""
                                                class="feather feather-layers" fill="none" height="24"
                                                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" viewBox="0 0 24 24" width="24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <polygon _ngcontent-ujs-c6="" points="12 2 2 7 12 12 22 7 12 2">
                                                </polygon>
                                                <polyline _ngcontent-ujs-c6="" points="2 17 12 22 22 17"></polyline>
                                                <polyline _ngcontent-ujs-c6="" points="2 12 12 17 22 12"></polyline>
                                            </svg></span>
                                    </div>
                                    <input type="number" [(ngModel)]="dataForm.up_to_cf" name="wd_up_to_cf"
                                        class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Cubic Feets.</small>
                            </div>


                            <div class="form-group mb-1">
                                <label class="control-label">Weight</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-archive">
                                                <polyline points="21 8 21 21 3 21 3 8"></polyline>
                                                <rect x="1" y="3" width="22" height="5"></rect>
                                                <line x1="10" y1="12" x2="14" y2="12"></line>
                                            </svg></span>
                                    </div>
                                    <input type="number" [(ngModel)]="dataForm.weight" name="wd_weight"
                                        class="form-control">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Enter Weight.</small>
                            </div>


                        </div>

                    </div>

                    <div class="text-center">
                        <button class="btn btn-main-action ml-1 mt-3" type="submit">
                            Save
                        </button>
                    </div>

                </form>


            </div>
        </div>


    </div>
</div>