import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConstantsMessages } from 'src/app/constants-messages';
import { JobInventoryItem } from 'src/app/entities/workspace/job-inventory-item';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { JobInventoryItemService } from 'src/app/services/companies/job-inventory-item.service';
import { environment } from 'src/environments/environment';
import { Job } from 'src/app/entities/workspace/job';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { InventoryCategoriesService } from 'src/app/services/workspaces/inventory-categories.service';
import { JobsService } from 'src/app/services/companies/jobs.service';

declare var jQuery;
@Component({
  selector: 'app-moving-items-form',
  templateUrl: './moving-items-form.component.html',
  styleUrls: ['./moving-items-form.component.scss']
})
export class MovingItemsFormComponent implements OnInit {

  readonly environment = environment;

  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  constantsMessages = ConstantsMessages;

  jobInventoryItem: JobInventoryItem;

  estimateId: string;

  idItem: string;

  public inventoryCategories;

  public idInventoryCategory;

  /*
  Arreglo de empleados
  */
  employees;

  /*
  Empleado seleccionado en la visibilityState
  */
  employee;

  job: Job;

  constructor(
    private currentRoute: ActivatedRoute,
    private employeesService: EmployeesService,
    private estimatesService: EstimatesService,
    private jobInventoryItemService: JobInventoryItemService,
    private jobsService: JobsService,
    public authService: AuthService,
    private inventoryCategoriesService: InventoryCategoriesService,
    public helperService: HelperService
  ) {

    this.employees = [];
    this.jobInventoryItem = new JobInventoryItem();
    this.job = new Job();
  }

  uploadDocument(img, docType) {
    this.jobInventoryItem.images = img;
  }

  private loadInventoryCategory() {
    this.inventoryCategoriesService
      .getAll()
      .then((response) => {
        this.inventoryCategories = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private load(id: string) {
    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getById(id)
      .then((response) => {
        this.job = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  loadEstimate() {
    return new Promise((resolve) => {
      this.helperService.showLoadingMxpro360();
      this.jobInventoryItemService
        .getItemById(this.estimateId, this.idItem)
        .then((response) => {
          if (response.job_id) {
            this.jobInventoryItem = response;
            resolve(true);
          }
        })
        .catch((error) => {
          console.error('Error: ', error);
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    });
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void{
    this.currentRoute.parent.params.subscribe(async params => {
        if (typeof params.id !== 'undefined') {
          this.estimateId = params.id;
          this.jobInventoryItem.job_id = params.id;
          this.load(params.id);
        }
      });
      this.currentRoute.params.subscribe(async params => {
        if (typeof params.value !== 'undefined') {
          this.idItem = params.value;
          await this.loadEstimate();
        }
      });
      this.loadEmployees();
      this.loadInventoryCategory();    
  }
  
  save() {
    this.helperService.showLoadingMxpro360();
    this.jobInventoryItem.inventory_category_id = this.idInventoryCategory;
    this.jobInventoryItemService
      .save(this.jobInventoryItem, this.estimateId)
      .then(response => {
        this.loadInventoryCategory();
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Metodo que carga todos los empleados
   */
  private loadEmployees() {
    this.helperService.showLoadingMxpro360();
    this.employeesService
      .getAll()
      .then((response) => {
        this.employees = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
}
