import { Component, OnInit, ViewChild } from '@angular/core';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-build-posting-leads',
    templateUrl: './build-posting-leads.component.html',
    styleUrls: ['./build-posting-leads.component.scss']
})
export class BuildPostingLeadsComponent implements OnInit {

    companies: Array<CompaniesService>;
    allWorkDepartments: Array<WorkDepartment>;
    allAdvertisers: Array<Advertiser>;

    company: string;
    workDepartment: string;
    advertiser: string;
    workspace: string;
    public isAngiLead: boolean;

    constructor(
        private companiesService: CompaniesService,
        private workDepartmentsService: WorkDepartmentsService,
        private advertisersService: AdvertisersService,
        private authService: AuthService,
        private helperService: HelperService
    ) {
        this.companies = [];
        this.allWorkDepartments = [];
        this.allAdvertisers = [];

        this.company = '';
        this.workDepartment = '';
        this.advertiser = '';
        this.workspace = this.authService.workspaceSession.id;
        this.isAngiLead = false;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.loadCompany();
        this.loadAdvertiser();
    }

    loadCompany() {
        this.helperService.showLoadingMxpro360();
        this.companiesService
            .getAll()
            .then((response) => {
                this.companies = response;
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadWorkDepartment() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService
            .getAllByCompany(this.company)
            .then((response) => {
                this.allWorkDepartments = response;
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadAdvertiser() {
        this.helperService.showLoadingMxpro360();
        this.advertisersService
            .getAllByCompany(this.company)
            .then((response) => {
                this.allAdvertisers = response;
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    chargeInfo() {
        this.workDepartment = '';
        this.advertiser = '';
        this.loadAdvertiser();
        this.loadWorkDepartment();
    }
}
