import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { ClockLocations } from 'src/app/entities/companies/clock-locations';
import { TimeClock } from 'src/app/entities/companies/time-clock';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayrollTimeClockService {

  constructor(
    private http: HttpClient
  ) { }

  add(clockEmployees: ClockEmployees) {
    if (clockEmployees.id) {
      return this.http.put(environment.api.company + '/payroll_clock_employee/'  + clockEmployees.id, clockEmployees).toPromise();
    } 
    return this.http.post(environment.api.company + '/payroll_clock_employee', clockEmployees).toPromise();
  }

  getAll(): Promise<Array<ClockEmployees>> {
    return this.http.get<Array<ClockEmployees>>(environment.api.company + '/payroll_clock_employee').toPromise();
  }

  getById(id: string) {
    return this.http.get<any>(environment.api.company + '/payroll_clock_employee/' + id).toPromise();
  }

  getAllClockLocations(): Promise<Array<ClockLocations>> {
    return this.http.get<Array<ClockLocations>>(environment.api.company + '/payroll_clock_locations').toPromise();
  }

  addClockLocation(clockLocations: ClockLocations) {
    if (clockLocations.id) {
      return this.http.put(environment.api.company + '/payroll_clock_locations/'  + clockLocations.id, clockLocations).toPromise();
    } 
    return this.http.post(environment.api.company + '/payroll_clock_locations', clockLocations).toPromise();
  }

  getClockLocationById(id: string) {
    return this.http.get<any>(environment.api.company + '/payroll_clock_locations/' + id).toPromise();
  }

  getAllTimeCocks(data): Promise<Array<TimeClock>> {
    return this.http.get<Array<TimeClock>>(environment.api.company + '/time-clocks-employee', {params: data}).toPromise();
  }

  getClock(id: string) {
    return this.http.get<TimeClock>(environment.api.company + '/time-clocks-employee/' + id).toPromise();
  }
  
  setClock(dataTimeClock: TimeClock, publicIP: string) {
    const dataForm = {...dataTimeClock, publicIP};
    return this.http.post<TimeClock>(environment.api.company + '/time-clocks', dataForm).toPromise();
  }

  putClock(dataForm: TimeClock) {
    return this.http.put<TimeClock>(environment.api.company + '/time-clocks/'  + dataForm.id, dataForm).toPromise();
  }

  editTime(dataForm: TimeClock) {
    return this.http.put<TimeClock>(environment.api.company + '/time-clocks/edit-time/'  + dataForm.id, dataForm).toPromise();
  }

  addManualClock(dataForm: TimeClock) {
    return this.http.post<TimeClock>(environment.api.company + '/time-clocks-employee-manual/' + dataForm.employee_id, dataForm).toPromise();
  }

}
