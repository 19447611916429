import { Geospatial } from "../global/geospatial";

export class Zone {
    id: string;
    tariff_version_id: string;
    name: string;
    geospatial: Geospatial;
    color: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.tariff_version_id = null;
        this.name = null;
        this.geospatial = new Geospatial();
        this.color = '#fd7300';
        this.created = null;
        this.updated = null;
    }
}
