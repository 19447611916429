<form class="form" #estimateForm="ngForm" ngNativeValidate>

    <br>
    <div class="widget-content widget-content-area">
        <div class="row">
            <div class="col-12 col-xl-6 col-lg-6">
                <p>
                    <strong>Packed by:</strong>
                        <select id="inputState" [(ngModel)]="jobInventoryItem.packed_by" name="jobInventoryItem.packed_by" class="form-control" required>
                            <option selected value="OWNER">OWNER</option>
                            <option value="THIRDPARTY">THIRDPARTY</option>
                            <option value="MOVER">MOVER</option>
                        </select>
                </p>
                <p>
                    <strong>Cubic feets:</strong> <input type="number" class="form-control"
                        name="jobInventoryItem.cubic_feets" [(ngModel)]="jobInventoryItem.cubic_feets" />
                </p>
                <p>
                    <strong>Weight:</strong> <input type="number" class="form-control" name="jobInventoryItem.weight"
                        [(ngModel)]="jobInventoryItem.weight" />
                </p>
                <p>
                    <strong>Value:</strong> <input type="number" class="form-control" name="jobInventoryItem.value"
                        [(ngModel)]="jobInventoryItem.value" />
                </p>
            </div>
            <div class="col-12 col-xl-6 col-lg-6">
                <p>
                    <strong>Pads:</strong> <input type="number" class="form-control" name="jobInventoryItem.pads"
                        [(ngModel)]="jobInventoryItem.pads" />
                </p>
                <p>
                    <strong>Remarks:</strong> <textarea type="textarea" class="form-control" rows="2" cols="30" name="jobInventoryItem.remarks"
                        [(ngModel)]="jobInventoryItem.remarks"> </textarea>
                </p>
                <p>
                    <strong>Special Handling:</strong> <textarea type="textarea" class="form-control" rows="2" cols="30"
                        name="jobInventoryItem.special_handling" [(ngModel)]="jobInventoryItem.special_handling"> </textarea>
                </p>
                <p>
                <strong>Inventory Category:</strong>

                        <select name="operator_user_id" [(ngModel)]="idInventoryCategory" id="operator_user_id"
                                class="form-control">
                                <option value="">All</option>
                                <ng-container *ngFor="let user of inventoryCategories">
                                    <option value="{{user.id}}">
                                        {{user.name | lowercase}}
                                    </option>
                                </ng-container>
                            </select>
                </p>
                <p>
                    <strong>Is Box:&nbsp;&nbsp;</strong> <input type="checkbox" name="jobInventoryItem.is_box"
                        [(ngModel)]="jobInventoryItem.is_box" />
                </p>
                <p>
                    <strong>Disassembled:&nbsp;&nbsp;</strong> <input type="checkbox"
                        name="jobInventoryItem.disassembled" [(ngModel)]="jobInventoryItem.disassembled" />
                </p>
            </div>
        </div>

    </div>
    <br>
    <div class="widget-content widget-content-area">
        <div class="row">
            <div class="col-12 col-xl-6 col-lg-6">
                <p>
                    <strong>Damaged description:</strong> <textarea type="textarea" rows="2" cols="30" class="form-control" name="jobInventoryItem.pads"
                        [(ngModel)]="jobInventoryItem.job_item_claim_information.damaged_description"> </textarea>
                </p>
                <p>
                    <strong>Pre Existing Damages:</strong> <textarea type="textarea" rows="2" cols="30" class="form-control" name="jobInventoryItem.remarks"
                        [(ngModel)]="jobInventoryItem.job_item_claim_information.pre_existing_damages"> </textarea>
                </p>
                <p>
                    <strong>Is Damaged:&nbsp;&nbsp;</strong> <input type="checkbox" name="jobInventoryItem.is_box"
                        [(ngModel)]="jobInventoryItem.job_item_claim_information.is_damaged" />
                </p>
            </div>
            <div class="col-12 col-xl-6 col-lg-6">
                <app-file-upload [multiple]="true" (onChange)="uploadDocument($event, 'digital_signs')"></app-file-upload>
            <div class="widget-content widget-content-area">
                <div>
                    <div _ngcontent-mac-c122=""
                        style="background-image: url(&quot;&quot;);">
                        <ng-container *ngFor="let file of jobInventoryItem.images">
                            <div class="custom-file-container__image-multi-preview"
                            style="background-image: url({{environment.az_api.cdn}}/moving-inventory-items/{{file.display_name}}); ">
                            <span class="custom-file-container__image-multi-preview__single-image-clear">
                                <span class="custom-file-container__image-multi-preview__single-image-clear__icon"
                                    data-upload-token="n1keu3e6naaq8i1t19cg9o">×</span>
                            </span>
                        </div>
                        </ng-container>
                
                    </div>
                </div>
             </div>
            </div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col">
                <button class="btn btn-block btn-primary"(click)="save()">
                    Save Info
                </button>
        </div>
    </div>

</form>