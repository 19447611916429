export class JobCharges {

    public id: string;
    public rate_cf : number;
    public total_price_cf: number;
    public overload_total_price_cf: number;
    public overload_rate_cf: number;
    public fuel_surcharge_percentage: number;
    public fuel_surcharge: number;
    public packing: number;
    public unpacking: number;
    public materials: number;
    public insurance: number;
    public stairs_elevator: number;



    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.rate_cf  = 0;
        this.total_price_cf = 0;
        this.overload_total_price_cf = 0;
        this.overload_rate_cf = 0;
        this.fuel_surcharge_percentage = 0;
        this.fuel_surcharge = 0;
        this.packing = 0;
        this.unpacking = 0;
        this.materials = 0;
        this.insurance = 0;
        this.stairs_elevator = 0;
    }
}
