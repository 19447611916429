import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
    selector: 'app-recurring-customersby-teams',
    templateUrl: './recurring-customersby-teams.component.html',
    styleUrls: ['./recurring-customersby-teams.component.scss']
})
export class RecurringCustomersbyTeamsComponent implements OnInit {
    jobsAllRecurringForHisTeamThisWeek: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) {
        this.jobsAllRecurringForHisTeamThisWeek = [];

    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.loadAllRecurringForHisTeamThisWeekCompany();
        } else {
            this.loadAllRecurringForHisTeamThisWeekWorkspace();
        }
    }


    loadAllRecurringForHisTeamThisWeekWorkspace() {

        this.dashboardWorkspaceService
            .getAllRecurringForHisTeamThisWeek()
            .then((response) => {
                this.jobsAllRecurringForHisTeamThisWeek = response;
                // setTimeout(() => {
                //     this.renderReportBookedMovingByCompany();
                // }, 300);

            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    loadAllRecurringForHisTeamThisWeekCompany() {

        this.dashboardComopanyService
            .getAllRecurringForHisTeamThisWeek()
            .then((response) => {
                this.jobsAllRecurringForHisTeamThisWeek = response;
                // setTimeout(() => {
                //     this.renderReportBookedMovingByCompany();
                // }, 300);

            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


}
