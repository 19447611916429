import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AccountsService {

    
    constructor(private http: HttpClient) { 

    }


    getById(id: string) {
        return this.http.get<any>(environment.api.workspace + '/employees/' + id).toPromise();
    }

    edit(dataForm: any) {
        return this.http.put(environment.api.account + '/account', dataForm).toPromise();
    }

    getActiveAccontsToCover() {
        return this.http.get(environment.api.account + '/active-accounts').toPromise();
    }

    getStorageSize() {
        return this.http.get(environment.api.account + '/get-storage-size').toPromise();
    }

    loadAccountInfo() {
        return this.http.get<any>(environment.api.account + '/info/workspace').toPromise();
    }
}


