import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-tickets-assigned',
  templateUrl: './tickets-assigned.component.html',
  styleUrls: ['./tickets-assigned.component.scss']
})
export class TicketsAssignedComponent implements OnInit {
    ticketsAssigned: any;

    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) {
        this.ticketsAssigned = [];
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId) {
            this.getTicketsAssignedCompany();
        } else {
            this.getTicketsAssignedWorkspace();
        }
    }


    getTicketsAssignedWorkspace() {

        this.dashboardWorkspaceService
            .getTicketsAssigned()
            .then((response) => {
                this.ticketsAssigned = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }


    getTicketsAssignedCompany() {

        this.dashboardComopanyService
            .getTicketsAssigned()
            .then((response) => {
                this.ticketsAssigned = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }



}
