import { Address } from "../global/address";
import { LocalEstimateStorage } from "./local-estimate-storage";


export class EstimateDelivery {
    public id: string;
    public deliver_immediately: boolean;
    public range_start: number;
    public range_end: number;
    public address: Address;
    public local_estimate_storage: LocalEstimateStorage;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.deliver_immediately = false;
        this.range_start = null;
        this.range_end = null;
        this.address = new Address();
        this.local_estimate_storage = null;
    }
}
