
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing" *ngFor="let jobTotalEstimateBySales of jobsTotalEstimateBySales ; index as i"> 
    <div class="widget widget-chart-three">
        <div class="widget-heading">
            <div class="">
                <h5 class="title-h5">Jobs Estimated by {{jobTotalEstimateBySales.worksDepartment}}</h5>
            </div>
        </div>

        <div class="widget-content">
            <div id="s-bar{{i}}"></div>
        </div>
    </div>
</div>