import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneNumber } from 'src/app/entities/admin/phone-number';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BuyNumbersService {


    constructor(private http: HttpClient) {
    }

    getAll(codeArea: string, quantity: string): Promise<PhoneNumber> {
        return this.http.get<PhoneNumber>(environment.api.apiRtcPrivate + '/nums', { params: { codeArea, quantity } }).toPromise();

    }
    getAllTollFreewildcardPattern(tollFreeWildCardPattern: string, quantity: string): Promise<PhoneNumber> {
        return this.http.get<PhoneNumber>(environment.api.apiRtcPrivate + '/nums/toll-freewildcard-pattern', { params: { tollFreeWildCardPattern, quantity } }).toPromise();

    }
    getAllPhoneNumbers
        () {
        return this.http.get<PhoneNumber>(environment.api.apiRtcPrivate + '/listOrders').toPromise();

    }
    add(numbers) {
        return this.http.post(environment.api.apiRtcPrivate + '/buyNums', { numbers }).toPromise();
    }

    addTollFree(numbers) {
        return this.http.post(environment.api.apiRtcPrivate + '/buyNums/toll-freewildcard-pattern', { numbers }).toPromise();
    }

    remove(number) {
        return this.http.post(environment.api.apiRtcPrivate + '/disconectNums', number).toPromise();
    }


    saveNumbers(numbers) {
      return this.http.post<any>(environment.api.workspace + '/numbers', numbers).toPromise();
   }

    listNumbers() {
    return this.http.get<any>(environment.api.workspace + '/numbers').toPromise();
   }

   deleteNumbers(id) {
  return this.http.delete<any>(environment.api.workspace + '/removeNumbers/'+ id).toPromise();
   }


   removeNumbers(number) {

      return this.http.put(environment.api.workspace + '/numbers', { number: number}).toPromise();

     }



}
