import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';

declare var jQuery;
declare var moment;

@Component({
  selector: 'app-pending-qajobs',
  templateUrl: './pending-qajobs.component.html',
  styleUrls: ['./pending-qajobs.component.scss']
})
export class PendingQAJobsComponent implements OnInit {

  public rows;
  public actualPage: number;
  public filterSearch: string;
  // datos para paginador
  public paginator: Paginator;
  public sort: number;
  public filterDays: null | 3 | 7 | 14;
  public today: number;
  public betweenDates: BetweenDates;

  @ViewChild('collapseFilter') collapseFilter: ElementRef;
  @ViewChild('showFilterBtn') showFilterBtn: ElementRef;

  constructor(
    private jobsService: JobsService,
    public helperService: HelperService,
    public authService: AuthService
  ) {
    this.actualPage = 1;
    this.filterSearch = '';
    this.paginator = new Paginator();
    this.paginator.paginator.order_by = 'created';
    this.paginator.paginator.order_type = 'DESC';
    this.betweenDates = new BetweenDates();
    this.sort = 8;
    this.filterDays = 7;
    this.getToday();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sortBy();
  }

  getToday() {
    this.today = moment().second(59).minute(59).hour(23).unix() * 1000;
    this.paginator.paginator.start = 1609459200;
    this.paginator.paginator.end = moment().second(59).minute(59).hour(23).unix() * 1000;
  }

  load(): void {

    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getPendingQA(this.paginator, this.filterDays)
      .then((response) => {
        this.rows = response.data;
        this.rows.sort((a, b) => a.estimate.pickup.range_start - b.estimate.pickup.range_start);        
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.load();
    }
  }



  /**
   * Metodo que abre un nuevo tab al dar click en un estimate
   *
   * @param estimateId Id del estimate a abrir
   */
  openNewTab(estimateId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(estimateId, 'estimate');
  }

  sortBy() {
    switch (Number(this.sort)) {
      case 1:
        this.paginator.paginator.order_by = 'estimate.document.code';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 2:
        this.paginator.paginator.order_by = 'estimate.document.code';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 3:
        this.paginator.paginator.order_by = 'customer.name';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 4:
        this.paginator.paginator.order_by = 'customer.name';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 5:
        this.paginator.paginator.order_by = 'estimate.pickup.range_start';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 6:
        this.paginator.paginator.order_by = 'estimate.pickup.range_start';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 7:
        // this.paginator.paginator.order_by = 'estimate.booked_date';
        this.paginator.paginator.order_by = 'created';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 8:
        // this.paginator.paginator.order_by = 'estimate.booked_date';
        this.paginator.paginator.order_by = 'created';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 9:
        this.paginator.paginator.order_by = 'estimate.delivery.address.state';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 10:
        this.paginator.paginator.order_by = 'estimate.delivery.address.state';
        this.paginator.paginator.order_type = 'DESC';
        break;

      default:
        break;
    }
    this.load();
  }

  setFilterDays(days: 3 | 7 | 14) {
    if (this.collapseFilter.nativeElement.classList.contains('show')) {
      this.collapseFilter.nativeElement.classList.remove('show');
      this.showFilterBtn.nativeElement.textContent = 'Show Filter';
    }
    this.filterDays = days;
    this.getToday();
    this.load();
  }

  getFilterDates(dataReturn: BetweenDates) {
    this.betweenDates = dataReturn;
  }


  search(): void {
    this.filterDays = null;
    if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
      this.betweenDates.dateStart = 1609459200;
      this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }

    // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
    this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

    this.paginator.paginator.start = this.betweenDates.dateStart;
    this.paginator.paginator.end = this.betweenDates.dateEnd;

    console.log('paginator =>', this.paginator);

    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getPendingQA(this.paginator, this.filterDays)
      .then((response) => {
        this.rows = response.data;
        this.rows.sort((a, b) => a.estimate.pickup.range_start - b.estimate.pickup.range_start);        
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
}
