export class GoogleAccount {

    public id: string;
    public name: string;
    public email: string;
    public photo: string;
    public g_user_id: string;
    public account_id: string;

    constructor() {
        this.id = null;
        this.name = null;
        this.email = null;
        this.photo = null;
        this.g_user_id = null;
        this.account_id = null;
    }
}
