export class DeductibleLevel {
    id: string;
    level: number;
    created: number;
    updated: number;

    constructor(){
        this.id = null;
        this.level = null;
        this.created = null;
        this.updated = null;
    }
}
