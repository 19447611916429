import { Claim } from "./claim";
import { Employee } from "./employee";
import { EstimateDocument } from "./estimate-document";

export class ClaimView extends Claim {
    public customer;
    public employee;
    public estimate_document;
    public job_allocation;
    public job_total;
    public move_date;
    public refunds;

    constructor() {
        super();
        this.customer = null;
        this.employee = new Employee();
        this.estimate_document = new EstimateDocument();
        this.job_allocation = null;
        this.job_total = null;
        this.move_date = null;
        this.refunds = null;
    }
}
