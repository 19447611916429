<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Profit & Loss
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Profit & Loss
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-secondary" (click)="exportCSV()" data-toggle="collapse" role="button">
                    <i class="fa-solid fa-file-arrow-down"></i>
                    <span>Export CSV</span> 
                </a>
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>

        <div class="row col-lg-12 col-md-12 block-filter mb-3">
            <div class="col-12 col-md-0 col-xl-2 pl-0">

            </div>
            <div class="col-12 col-md-5 col-xl-3 pl-0">
                <label>Filter Date For</label>
                <select name="filter_date_for" id="filter_date_for" [(ngModel)]="filterData.filter_date_for"
                    class="form-control">
                    <option value="PICKUP">Pickup Date</option>
                    <option value="BOOKING">Booking Date</option>
                </select>
            </div>
            <div class="col-12 col-md-5 col-xl-3 pl-0">
                <label>Allocation</label>
                <select name="allocation" id="allocation" [(ngModel)]="filterData.allocation"
                    class="form-control">
                    <option value="">All</option>
                    <option value="AGENT">Agent</option>
                    <option value="IN_HOUSE">In House</option>
                </select>
            </div>
            <div class="col-12 col-md-1 col-xl-3 pl-0">
                <button class=" btn btn-warning btn-search" (click)="loadData(true)">
                    Search
                </button>
            </div>
        </div>
    </div>

    <!-- header -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Preformed By</th>
                                <th>Job Number</th>
                                <th>Total Job</th>
                                <th>Agent Pay</th>
                                <th>JPAP</th>
                                <th>Expenses</th>
                                <th>Commissions/Labor</th>
                                <th>Delivery Costs</th>
                                <th>Refunds</th>
                                <th>Profit/Loss</th>
                                <th>Monies Collected</th>
                                <th>Monies Due</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of paginator.data">
                                <td>
                                    {{row.allocation | filterReplace }}
                                </td>
                                <td>
                                    <span class="job-number" (click)="openNewTab(row.job_id)">{{row.job_number}}</span>
                                </td>
                                <td>
                                    {{row.total_job | currency}}
                                </td>
                                <td>
                                    {{row.total_agent_pay | currency}}
                                </td>
                                <td>
                                    {{row.jpap | currency}}
                                </td>
                                <td>
                                    {{row.total_expenses | currency}}
                                </td>
                                <td>
                                    {{row.total_commissions_labor | currency}}
                                </td>
                                <td>
                                    {{row.total_delivery_cost | currency}}
                                </td>
                                <td>
                                    {{row.total_refunds | currency}}
                                </td>
                                <td>
                                    {{row.profit_loss | currency}}
                                </td>
                                <td>
                                    {{row.total_monies_collected | currency}}
                                </td>
                                <td>
                                    {{row.total_monies_due | currency}}
                                </td>
                            </tr>
                            <tr>
                                <td><strong>TOTAL</strong></td>
                                <td><strong>{{ totalRows}}</strong></td>
                                <td><strong>{{ totalJob | currency }}</strong></td>
                                <td><strong>{{ totalAgentPay | currency }}</strong></td>
                                <td><strong>{{ totalJPAP | currency }}</strong></td>
                                <td><strong>{{ totalExpenses | currency }}</strong></td>
                                <td><strong>{{ totalCommLabor | currency }}</strong></td>
                                <td><strong>{{ totalDeliveryCost | currency }}</strong></td>
                                <td><strong>{{ totalRefunds | currency }}</strong></td>
                                <td><strong>{{ totalProitLoss | currency }}</strong></td>
                                <td><strong>{{ totalMoniesCollected | currency }}</strong></td>
                                <td><strong>{{ totalMoniesDue | currency }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
</div>