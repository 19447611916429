
export class DailyCrew {
    public id: string;
    public name: string;
    public date: number;
    public date_text: string;
    public start_time: string;
    public employees_id: Array<string>;
    public drivers_id: Array<string>;
    public foreman_id: string;
    public workers_id: Array<string>;
    public jobs_id: Array<string>;
    public vehicle_id: string;
    public created: number;
    public updated: number;

    
    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.name = null;
        this.date = null;
        this.date_text = null;
        this.start_time = null;
        this.employees_id = [];
        this.drivers_id = [];
        this.foreman_id = null;
        this.workers_id = [];
        this.jobs_id = [];
        this.vehicle_id = null;
        this.created = null;
        this.updated = null;
    }
}
