<!--Por aca empieza el formulario de listado-->

<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Email Campaigns
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i>
                                    Dashboard</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Email Campaigns</li>
                        </ol>
                    </nav>
                    <br>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input type="text" name="filterSearch" class="form-control product-search" id="input-search"
                                placeholder="Filter...">
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <a appCompanyRouterLink="/email-campaigns/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Campaign
                </a>

            </div>


        </div>
    </div>

    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="widget-content searchable-container list">
                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content">
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4>
                                    Campaign
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4>
                                    Service
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4>
                                    Description
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4>
                                    Date Range
                                </h4>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <h4>
                                    Total Send
                                </h4>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1">
                                <h4>
                                    Created
                                </h4>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <h4>
                                    Options
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div class="items" *ngFor="let campaign of campaigns | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                        <div class="item-content information-col" role="menu">

                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="user-meta-info info">
                                    <p class="user-name">{{campaign.name | filterReplace}}</p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="user-meta-info info">                                    
                                    <p class="user-name"><strong>From: </strong>
                                        <span *ngIf="campaign.campaign_filter.from_state !== ''">
                                            {{ campaign.campaign_filter.from_state | filterReplace }}
                                        </span>
                                        <span *ngIf="campaign.campaign_filter.from_state == ''">
                                            All
                                        </span>
                                    </p>
                                    <p class="user-name"><strong>To: </strong>
                                        <span *ngIf="campaign.campaign_filter.to_state !== ''">
                                            {{campaign.campaign_filter.to_state | filterReplace}}
                                        </span>
                                        <span *ngIf="campaign.campaign_filter.to_state == ''">
                                            All
                                        </span>
                                    </p>
                                    <p class="user-name"><strong>Service: </strong>
                                        <span *ngIf="campaign.campaign_filter.service == 'LONG'" class="badge badge-secondary">
                                            {{campaign.campaign_filter.service | filterReplace}}
                                        </span> 
                                        <span *ngIf="campaign.campaign_filter.service == 'LOCAL'" class="badge badge-success">
                                            {{campaign.campaign_filter.service | filterReplace}}
                                        </span> 
                                        <span *ngIf="campaign.campaign_filter.service == 'AUTO_TRANSPORT'" class="badge badge-info">
                                            {{campaign.campaign_filter.service | filterReplace}}
                                        </span> 
                                    </p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="user-meta-info info">
                                    <p class="user-name">
                                        <strong>Status: </strong>
                                        <span *ngIf="campaign.campaign_filter.status !== ''">
                                            {{campaign.campaign_filter.status | filterReplace}}
                                        </span>
                                        <span *ngIf="campaign.campaign_filter.status == ''">
                                            All
                                        </span>
                                    </p>
                                    <p class="user-name">
                                        <strong>Category: </strong>
                                        <span *ngIf="campaign.campaign_filter.category !== ''">
                                            {{campaign.campaign_filter.category | filterReplace}}
                                        </span>
                                        <span *ngIf="campaign.campaign_filter.category == ''">
                                            All
                                        </span>
                                    </p>
                                    <p class="user-name">
                                        <strong>Target: </strong> {{campaign.campaign_filter.range_target | filterReplace}}
                                    </p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <div class="user-meta-info info">                                                
                                    <p class="user-name">
                                        <strong>Start: </strong> {{campaign.campaign_filter.range_start | dateTime}}
                                    </p>
                                    <p class="user-name">
                                        <strong>End: </strong> {{campaign.campaign_filter.range_end | dateTime}}
                                    </p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 content-center">
                                <div class="user-meta-info info">
                                    <p class="user-name">{{campaign.total_send}}</p>
                                </div>
                            </div>

                            <div class="user-profile col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 pr-0">
                                <div class="user-meta-info info">
                                    <p class="user-name">{{campaign.created | dateTime}}</p>
                                </div>
                            </div>
                            <div class="user-profile col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                                <a class="btn btn-success" appCompanyRouterLink="/email-campaigns/{{campaign.id}}/edit">
                                    <i class="fas fa-edit"></i>
                                </a>
                                <a class="btn btn-secondary" (click)="sendEmails(campaign)">
                                    <i class="fas fa-paper-plane"></i>
                                </a>
                            </div>
                        </div>

                    </div>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event">
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
         
</div>