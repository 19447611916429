import { CommissionSetting } from 'src/app/entities/workspace/commission-setting';
import { CommissionSettingsService } from 'src/app/services/companies/commission-settings.service';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-commission-settings',
    templateUrl: './commission-settings.component.html',
    styleUrls: ['./commission-settings.component.scss']
})
export class CommissionSettingsComponent implements OnInit {

    rows: Array<CommissionSetting>;

    constructor(
        private commissionSettingsService: CommissionSettingsService,
        private helperService: HelperService    
        ) {
        this.rows = [];
    }

    actualPage = 1;
    filterSearch = '';
    ngOnInit() {
    }

    ngAfterViewInit(): void{
        this.load();
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.commissionSettingsService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.commissionSettingsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {

                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

}
