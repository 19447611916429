import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-estimate-ratio-per-lead',
  templateUrl: './estimate-ratio-per-lead.component.html',
  styleUrls: ['./estimate-ratio-per-lead.component.scss']
})
export class EstimateRatioPerLeadComponent implements OnInit {

    @Input()
    companyId: boolean = false;
    closingRatio: any;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) { 
        this.closingRatio = {
            "total_estimates": 0,
            "total_bookeds": 0,
            "closing_ratio": 0
        };
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
           if (this.companyId){
            this.closingRatioCompany();
        } else {
            this.closingRatioWorkspace();
        }
    }

    
    closingRatioWorkspace(){
        
        this.dashboardWorkspaceService
            .getClosingRatioPercentageInTheLast3Months()
            .then((response) => {                
                this.closingRatio = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }
    
    closingRatioCompany(){
        
        this.dashboardComopanyService
            .getClosingRatioPercentageInTheLast3Months()
            .then((response) => {                
                this.closingRatio = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

}
