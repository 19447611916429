import { CampaignFilter } from './campaign-filter';

export class SmsCampaign {
    public id: string;
    public name: string;
    public message: string;
    public campaign_filter: CampaignFilter;
    public total_send: number;
    public is_run: string;
    public company_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.message = null;
        this.campaign_filter = null;
        this.total_send = null;
        this.is_run = null;
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
