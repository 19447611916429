import { ActivatedRoute } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { HelperService } from 'src/app/services/helper.service';
import { StorageInvoice } from 'src/app/entities/workspace/storage-invoice';
import { StorageInvoicesService } from 'src/app/services/companies/storage-invoices.service';
import { StorageService } from 'src/app/entities/workspace/storage-service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { createJSDocCallbackTag } from 'typescript';
import { JobView } from 'src/app/entities/workspace/job-view';

declare const jQuery;
declare const moment;
declare const swal;
declare const google;
@Component({
  selector: 'app-storage-invoices',
  templateUrl: './storage-invoices.component.html',
  styleUrls: ['./storage-invoices.component.scss']
})
export class StorageInvoicesComponent implements OnInit {

  @ViewChild('addInvoiceModal') addInvoiceModal: ElementRef;
  private currentDate: number
  public customerPayments: any[];
  public customerTotalPayments: number;
  public estimate: EstimateView;
  public job: JobView;
  public storageInvoice: StorageInvoice;
  public storageService: StorageService;
  public storageServices: StorageService[];
  public storageServiceFistMondFree: boolean;
  public storageServiceProrate: boolean;
  public totalDue: number;
  public companyThemeDocument = {
    "firstColor": "#ef8400",
    "secondColor": "#ef8400",
    "thirdColor": "#ef8400",
  };

  constructor(
    private authService: AuthService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private jobService: JobsService,
    private storageInvoicesService: StorageInvoicesService,
  ) {
    this.currentDate = moment().unix() * 1000;
    this.customerPayments = [];
    this.customerTotalPayments = 0;
    this.estimate = new EstimateView;
    this.job = new JobView();
    this.storageInvoice = new StorageInvoice();
    this.storageService = new StorageService();
    this.storageServices = [];
    this.totalDue = 0;
    this.storageServiceFistMondFree = false;
    this.storageServiceProrate = false;
  }

  ngOnInit(): void {
    // verficamos si se esta editando
    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
          this.load(params.id);
      }
  });
  }

  private load(id: string) {
    this.helperService.showLoadingMxpro360();
    this.jobService.getById(id)
    .then(response => {
      this.estimate = response.estimate;
      this.job = response;
    });
    this.storageInvoicesService.getCompleteByEstimateId(id, false).then((response) => {
      this.storageInvoice = response.storageInvoice;
      const storageViwed = [];
      this.storageServices = response.storageServices.filter((obj) => {
        const id = obj.id;
        if (!storageViwed[id]) {
          storageViwed[id] = true;
          return true;
        }
        return false;
      });
      this.getCustomerPayments(response.storageServices);
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  private getCustomerPayments(storageServices) {
    this.customerPayments = [];
    this.customerTotalPayments = 0;
    this.totalDue = this.storageInvoice.total;
    for (let service of storageServices) {
      if (['PAID', 'PARTIAL_PAID'].indexOf(service.status) >= 0 && service['payment']) {
        console.log('service', service)
        this.customerTotalPayments += service.payment.amount;
        this.totalDue -= service.payment.amount;
        this.customerPayments.push(service);
      }
    }
  }

  openAddInvoiceModal() {
    this.storageService = new StorageService;
    if (this.job.storage_rate !== null) {
      this.storageService.rate_per_cf = this.job.storage_rate.monthly_fee_by_cf;
    } else if (this.estimate.storages !== null) {
      this.storageService.rate_per_cf = this.estimate.storages.monthly_fee_by_cf;
    }
    this.storageService.cubic_feet = this.job.cubic_feets;
    this.storageService.job_id = this.job.id;
    this.storageInvoice.company_id = this.job.company_id;
    this.storageService.start_date = this.currentDate;
    this.storageService.end_date = moment().add(1, 'months').unix() * 1000;

    jQuery(this.addInvoiceModal.nativeElement).modal('show');
  }

  saveInvoice() {
    // if (this.storageInvoice.end_date !== null && this.storageInvoice.start_date !== null && this.storageInvoice.total_amount_due !== null) {
      jQuery(this.addInvoiceModal.nativeElement).modal('hide');
      this.storageInvoicesService.save(this.storageInvoice)
      .then(() => {
        this.load(this.storageInvoice.job_id);
      })
      .finally(() => {
        this.helperService.showLoadingMxpro360();
      });
    // } else {
    //   this.helperService.showMessageSnackbar('Please Fill Out All The Fields', 'WARNING');
    // }
  }

  setNextBillDate(date: number) {
    this.storageService.end_date = date;
  }

  setDueDate(date: number) {
    this.storageService.due_date = date;
  }

  setStartBillDate(date: number) {
    this.storageService.start_date = date;
  }

  exportPdf(storageInvoiceId: string) {
    window.open(environment.api.companies + this.authService.workspaceSession.company.id
      + '/estimates/' + this.storageInvoice.job_id + '/export-storage-invoice/' + storageInvoiceId + '?Authorization=' +
      this.authService.session.token + '&workspace_id=' +  this.authService.workspaceSession.workspace.id + '&auth_employee_id=' + this.authService.workspaceSession.employee.id, '_blank');
  }

  addTerm() {
    if (this.storageService.end_date !== null && this.storageService.start_date !== null && this.storageService.rate_per_cf !== null) {
      this.helperService.showLoadingMxpro360();
      this.storageInvoicesService.addTerms(this.estimate.id, this.storageService, this.storageServiceFistMondFree, this.storageServiceProrate)
      .then(() => {
        this.load(this.estimate.id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    } else {
      this.helperService.showMessageSnackbar('Please Fill Out All The Fields', 'WARNING');
    }
  }

  removeService(storageServiceId: string) {
    swal({
      title: 'Are you sure?',
      text: 'Are you sure do you want to delete this record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.storageInvoicesService.removeTerms(this.estimate.id, storageServiceId)
          .then(() => {
            this.load(this.estimate.id);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
        }
      });
  }
}
