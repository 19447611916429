<div class="form-container">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container">
                <div class="form-content">

                    <div class="text-center">
                        <img src="assets/img/logo_moverxpro360.png" alt="">
                        <h2 class="text-register">Register</h2>
                    </div>

                    <!-- accoutn -->
                    <ng-container *ngIf="section == 'ACCOUNT_FORM'">
                        <form class="text-left mt-4" #formSignin="ngForm" (ngSubmit)="sigin()" ngNativeValidate>
                            <div class="form">
                                <div id="name-field" class="field-wrapper input">
                                    <i class="fas fa-user i-form"></i>
                                    <input id="account_name" name="account_name" type="text" autocomplete="new"
                                        [(ngModel)]="dataForm.account_name" required value="" placeholder="Name"
                                        autofocus="autofocus">
                                </div>
                                <div id="phone-field" class="field-wrapper input">
                                    <i class="fas fa-phone-alt i-form"></i>
                                    <input id="account_phone" name="account_phone" onlyNumbers type="tel" minlength="10" autocomplete="new"
                                        maxlength="10" [(ngModel)]="dataForm.account_phone" required
                                        class="form-control" placeholder="Phone Number">
                                </div>
                                <div id="email-field" class="field-wrapper input">
                                    <i class="fas fa-envelope i-form"></i>
                                    <input id="account_email" name="account_email" type="email" autocomplete="new"
                                        [(ngModel)]="dataForm.account_email" required value=""
                                        placeholder="Email (Username)">
                                </div>

                                <div id="password-field" class="field-wrapper input mb-2">
                                    <i class="fas fa-lock i-form"></i>
                                    <input id="account_password" name="account_password" type="password" minlength="6" autocomplete="new"
                                        maxlength="20" autocomplete="new-password"
                                        [(ngModel)]="dataForm.account_password" required value=""
                                        placeholder="Password">
                                    <button id="show_password" name="show_password" class="" type="button"
                                        (click)="showPassword()">
                                        <span class="fa fa-eye icon password"></span>
                                    </button>
                                </div>
                                <div id="password-field" class="field-wrapper input mb-2">
                                    <i class="fas fa-lock i-form"></i>
                                    <input id="inputConfirmPassword" name="confirm-password" type="password"
                                        minlength="6" maxlength="20" autocomplete="new-password"
                                        [(ngModel)]="confirmPassword" required value="" placeholder="Confirm Password">
                                    <button id="show_confirm_password" name="show_confirm_password" type="button"
                                        (click)="showConfirmPassword()">
                                        <span class="fa fa-eye icon confirm-password"></span>
                                    </button>
                                </div>
                                <div class="field-wrapper terms_condition">
                                    <div class="n-chk new-checkbox checkbox-outline-primary">
                                        <label class="new-control new-checkbox checkbox-outline-warning">
                                            <input type="checkbox" class="new-control-input" required>
                                            <span class="new-control-indicator"></span>
                                            <span>I agree to the
                                                <a target="_blank" href="https://moverxpro360.com/term-and-conditions">
                                                    <strong> Terms and Conditions</strong>
                                                </a>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="field-wrapper text-center mt-4">
                                    <button type="submit" class="btn btn-warning btn-register">Next</button>
                                </div>
                            </div>
                        </form>
                    </ng-container>

                    <ng-container *ngIf="section == 'ACCOUNT_TOKEN_FORM'">

                        <div class="my-5 container d-flex justify-content-center align-items-center">
                            <div class="position-relative">
                                <div class="p-2 text-center">
                                    <h6 class="title-code">Please enter the one time code <br> to verify your account
                                    </h6>
                                    <div>
                                        <span>A verification code has been sent your email address and <br> a text
                                            message to your phone</span>
                                    </div>
                                    <div id="otp" class="inputs d-flex flex-row justify-content-center mt-4 mb-5">
                                        <input class="m-2 text-center form-control rounded" type="number" id="first"
                                            maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="second"
                                            maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="third"
                                            maxlength="1" />
                                        <div class="dash"> - </div>
                                        <input class="m-2 text-center form-control rounded" type="number" id="fourth"
                                            maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="fifth"
                                            maxlength="1" />
                                        <input class="m-2 text-center form-control rounded" type="number" id="sixth"
                                            maxlength="1" />
                                    </div>
                                    <div class="mt-4">
                                        <button class="btn btn-danger px-4 validate"
                                            (click)="verifyCodeEntered()">Validate</button>
                                    </div>
                                    <div class="mt-4 resent-tocken">
                                        It may take a minute to receive your code <br>
                                        Haven't received it? <a (click)="resendaNewCode()">Resend a new code</a>
                                    </div>
                                    <div class="mt-4 resent-tocken">
                                        <h6 class="change-number">This is the number you submitted<br>
                                        {{dataForm.account_phone}}, if this is not correct<br>
                                        <a (click)="openChangePhoneModal()">Click here to change</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                    <!-- company -->
                    <ng-container *ngIf="section == 'ACCOUNT_COMPANY_TOKEN_FORM'">
                        <form class="text-left mt-4" #formUpdateAccountCompany="ngForm"
                            (ngSubmit)="updateAccountCompany()" ngNativeValidate autocomplete="off" >
                            <input type="hidden" autocomplete="off"/>
                            <div class="form">
                                <div id="email-field" class="field-wrapper input">
                                    <i class="fas fa-building i-form"></i>
                                    <input id="company_position" name="company_position" type="text" autocomplete="new"
                                        [(ngModel)]="dataForm.company_position" required value=""
                                        placeholder="Company Position">
                                </div>
                                <div id="email-field" class="field-wrapper input">
                                    <i class="fas fa-building i-form"></i>
                                    <input id="company_name" name="company_name" type="text" autocomplete="new"
                                        [(ngModel)]="dataForm.company_name" required value=""
                                        placeholder="Company Name">
                                </div>
                                <app-search-address [addressFull]="false" (onChange)="setFrom($event)"></app-search-address>

                                <div id="email-field" class="field-wrapper input">
                                    <i class="fas fa-phone-alt i-form"></i>
                                    <input id="company_phone" name="company_phone" onlyNumbers type="tel" minlength="10" maxlength="10" autocomplete="new"
                                        [(ngModel)]="dataForm.company_phone" required value=""
                                        placeholder="Company Number">
                                </div>

                                <div id="email-field" class="field-wrapper input">
                                    <i class="fas fa-building i-form"></i>
                                    <input id="company_dot_or_license" name="company_dot_or_license" type="text" autocomplete="new"
                                        [(ngModel)]="dataForm.company_dot_or_license" required value=""
                                        placeholder="Dot # or License #">
                                </div>

                                <div class="field-wrapper text-center mt-4">
                                    <button type="submit" class="btn btn-warning btn-register">Get Started</button>
                                </div>
                            </div>
                        </form>
                    </ng-container>

                    <p class="signup-link text-center">Already have an account? <a routerLink="/login">Log in</a></p>

                    <p class="terms-conditions text-center">© 2023 All Rights Reserved
                        <a href="https://www.moverxpro360.com">Moverxpro360</a> is a product of <a
                            href="https://moverxpro360.com" target="_blank">MoverXpro 360 LLC</a> <br>
                        <!-- <a href="javascript:void(0);">Cookie Preferences</a>, -->
                        <a href="https://moverxpro360.com/privacy-policy" target="_blank">Privacy Policy</a> and <a
                            href="https://moverxpro360.com/term-and-conditions" target="_blank">Term and Conditions</a>
                    </p>


                </div>
            </div>
        </div>
    </div>
    <div class="form-image">
        <div class="l-image">
        </div>
    </div>
</div>

<!-- Modal add additional concept-->
<div class="modal fade register-modal" #changePhoneModal tabindex="-1" role="dialog"
    aria-labelledby="changePhoneModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        <span>Change Phone Number</span>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">
                            <div class="form-group"><input [(ngModel)]="newPhone" onlyNumbers type="tel" minlength="10" maxlength="10" placeholder="New Phone" class="form-control" name="concept_name">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button (click)="closeModal()" type="button" class="btn btn-cancel">
                    Cancel
                </button>
                    <button (click)="saveNewPhone()" data-dismiss="modal" type="button" class="btn btn-save">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>