export class Geospatial {

    id: string;
    type: string;
    coordinates: any; // corregir, despues debe estar en Array<Number>

    constructor() {
        this.id = null;
        this.type = null;
        this.coordinates = [];
    }
    
}
