<div class="widget widget-table-two" >

    <div class="widget-heading">
        <h5 class="text-secondary">Jobs Booked</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-secondary"><div class="th-content text-light">Job</div></th>
                        <th class="bg-secondary"><div class="th-content text-light">Employee</div></th>
                        <th class="bg-secondary"><div class="th-content text-light">Date</div></th>
                        <th class="bg-secondary"><div class="th-content text-light">Options</div></th>
                    </tr>
                </thead>
                <tbody *ngIf="JobsBooked.length > 0">
                    <tr *ngFor="let JobBooked of JobsBooked">
                        <td><div class="td-content customer-name">{{JobBooked.job}}</div></td>
                        <td><div class="td-content product-brand">{{JobBooked.employee}}</div></td>
                        <td><div class="td-content">{{JobBooked.created | dateTime}}</div></td>
                        <td><div class="td-content">Go To</div></td>
                    </tr>
                    
                   
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- <div class="widget-content row">
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <h4>
            Job
        </h4>
    </div>
    <div class="col-xl-4 col-lg-2 col-md-2 col-sm-2 col-2">
        <h4 style="margin-left: 3px;">
            Employee
        </h4>
    </div>
    <div class="col-xl-4 col-lg-2 col-md-2 col-sm-2 col-2">
        <h4 style="margin-left: 3px;">
            Date
        </h4>
    </div>
</div>
<div class="row">
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <div>
           NCE7510
        </div>
    </div>
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <div>
           NCE7510
        </div>
    </div>
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <div>
           NCE7510
        </div>
    </div>
</div>
<div>
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <div>
           NCE7510
        </div>
    </div>
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <div>
           NCE7510
        </div>
    </div>
    <div class="col-xl-4 col-lg-3 col-md-3 col-sm-3 col-3">
        <div>
           NCE7510
        </div>
    </div>
</div> -->