import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';

@Injectable({
  providedIn: 'root'
})
export class CCProcessingService {

  constructor(private http: HttpClient) { }

  get(paginator: Paginator, startAmount: number | '', endAmount: number | '') {
    
    const params: any = {
      "use_paginator": 1,
      "page": paginator.paginator.page,
      "per_page": paginator.paginator.per_page,
      "start": paginator.paginator.start,
      "end": paginator.paginator.end,
      "order_by": paginator.paginator.order_by,
      "order_type": paginator.paginator.order_type,
      "start_amount": startAmount,
      "end_amount": endAmount,
    };

    return this.http.get<Paginator>(`${environment.api.company}/cc-processing-report`, { params: params }).toPromise();
  }
}
