<div class="controls" *ngIf="scope != null && readonly == false">
    <div class="col-12 filtered-list-search layout-spacing align-self-center">
        <form class="form-inline my-2 my-lg-0">
            <div class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-search">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input type="text" class="form-control" name="searchText" id="input-search" placeholder="Search..." [(ngModel)]="searchText" (keyup)="refreshView()">
            </div>
        </form>
    </div>
</div>

<h5 class="text-center" *ngIf="readonly">
    Assigned Permissions
</h5>

<div class="table-responsive table-assign-permissions" *ngIf="scope != null" [ngClass]="{'readonly': readonly}">
    <table class="table table-striped" [ngClass]="{'table-hover': (!readonly)}">
        <thead *ngIf="readonly == false">
            <tr>
                <th class="col-select-all">
                    <div class="wrapper-toggle">
                        <button class="btn btn-select-all" type="button" (click)="toggleAll()" title="Select All">
                            <i class="fa-solid fa-square-check" *ngIf="isSelectedAll"></i>
                            <i class="fa-solid fa-square" *ngIf="!isSelectedAll"></i>
                        </button>
                    </div>
                    <span class="select-all">Select All</span>
                </th>
                <th class="text-center">
                    Description
                </th>
            </tr>
        </thead>
        <tbody>

            <ng-container *ngIf="readonly == false">
                <tr *ngFor="let cap of listCapabilitiesBS | async; let i = index" (click)="onSelect(cap.data.id, (authService.workspaceSession.workspace.plan !== null && authService.workspaceSession.workspace?.plan.workspace_capabilities?.indexOf(cap.data.id) == -1 && authService.workspaceSession.workspace.plan?.company_capabilities?.indexOf(cap.data.id) == -1))"
                    [ngClass]="{'disabled': authService.workspaceSession.workspace.plan !== null && authService.workspaceSession.workspace?.plan.workspace_capabilities?.indexOf(cap.data.id) == -1 && authService.workspaceSession.workspace.plan?.company_capabilities?.indexOf(cap.data.id) == -1}"
                    [attr.title]="(authService.workspaceSession.workspace.plan !== null && authService.workspaceSession.workspace?.plan.workspace_capabilities?.indexOf(cap.data.id) == -1 && authService.workspaceSession.workspace.plan?.company_capabilities?.indexOf(cap.data.id) == -1) ? deniedMessage: null">
                    <td *ngIf="readonly == false">
                        <div class="n-chk">
                            <label class="new-control new-checkbox checkbox-warning">
                            <input type="checkbox" class="new-control-input" [checked]="cap.selected && (authService.workspaceSession.workspace.plan == null || authService.workspaceSession.workspace?.plan.workspace_capabilities?.indexOf(cap.data.id) !== -1 || authService.workspaceSession.workspace?.plan.company_capabilities?.indexOf(cap.data.id) !== -1)" name="cap_{{i}}">
                            <span class="new-control-indicator"></span>
                            </label>
                        </div>
                    </td>
                    <td>
                        <h6>
                            <strong>
                                {{ cap.data.name }}
                            </strong>
                        </h6>
                        <p>
                            {{ cap.data.description }}
                        </p>
                    </td>
                </tr>
            </ng-container>
    
            <ng-container *ngIf="readonly">
                <ng-container *ngFor="let cap of listCapabilitiesBS | async">
                    <tr *ngIf="cap.selected && (authService.workspaceSession.workspace.plan == null || authService.workspaceSession.workspace?.plan.workspace_capabilities?.indexOf(cap.data.id) !== -1 || authService.workspaceSession.workspace?.plan.company_capabilities?.indexOf(cap.data.id) !== -1)">
                        <td>
                            <h6>
                                <strong>
                                    {{ cap.data.name }}
                                </strong>
                            </h6>
                            <p>
                                {{ cap.data.description }}
                            </p>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
    
        </tbody>
    </table>
</div>