import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LocalRoomClosets } from 'src/app/entities/workspace/local-room-closets';

@Injectable({
    providedIn: 'root'
})
export class LocalRoomClosetsService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<LocalRoomClosets>> {
        return this.http.get<Array<LocalRoomClosets>>(environment.api.workspace + '/local-room-closets').toPromise();
    }

    getById(id: string) {
        return this.http.get<LocalRoomClosets>(environment.api.workspace + '/local-room-closets/' + id).toPromise();
    }

    save(dataForm: LocalRoomClosets): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: LocalRoomClosets) {
        return this.http.post(environment.api.workspace + '/local-room-closets', dataForm).toPromise();
    }

    edit(dataForm: LocalRoomClosets) {
        return this.http.put(environment.api.workspace + '/local-room-closets/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/local-room-closets/' + id).toPromise();
    }
}
