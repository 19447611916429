import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierCandidateView } from 'src/app/entities/workspace/carrier-candidate-view';
import { Job } from 'src/app/entities/workspace/job';
import { JobOutsourcingCancelledView } from 'src/app/entities/workspace/job-outsourcing-cancelled-view';
import { JobView } from 'src/app/entities/workspace/job-view';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BoardJobsService {

    constructor(private http: HttpClient) { }

    getAllWithFullView(data: any): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.company + '/jobs', { params: data }).toPromise();
    }

    getJobsToday(data: any): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.company + '/jobs-today', { params: data }).toPromise();
    }

    getJobsDelivered(data) {
        return this.http.get<any>(environment.api.company + '/jobs', { params: data }).toPromise();
    }

    getJobsDeliveredById(data, id) {
        return this.http.get<any>(environment.api.company + '/jobs/' + id, { params: data }).toPromise();
    }

    patchEntity(id: string, dataForm: any) {
        return this.http.patch(environment.api.company + '/jobs/' + id, dataForm).toPromise();
    }

    saveDocument(id: string, doc_type: string, dataForm: any) {
        return this.http.put<Job>(environment.api.company + '/jobs/' + id + '/media-files/' + doc_type, dataForm).toPromise();
    }

    forceStatusCharge(id, data) {
        return this.http.put<Job>(environment.api.company + '/jobs/' + id + '/force-status', data).toPromise();
    }

    getJobById(id: string) {
        return this.http.get<JobView>(environment.api.company + '/job/' + id + '/detail', { params: { 'full-view': '1' } }).toPromise();
    }

    getById(id: string) {
        return this.http.get<any>(environment.api.workspace + '/board-jobs/' + id).toPromise();
    }

    edit(dataForm: any) {
        return this.http.put(environment.api.workspace + '/board-jobs/' + dataForm.id, dataForm).toPromise();
    }
    add(dataForm: any) {
        return this.http.post(environment.api.workspace + '/board-jobs', dataForm).toPromise();
    }

    getAll() {
        return this.http.get<any>(environment.api.workspace + '/board-jobs').toPromise();
    }

    save(dataForm: any): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/board-jobs/' + id).toPromise();
    }

    getAllCandidates(id) {
        return this.http.get<Array<CarrierCandidateView>>(environment.api.workspace + '/outsourcings/' + id + '/candidates').toPromise();
    }

    acceptJob(dataForm: any, id) {
        return this.http.put(environment.api.workspace + '/outsourcings/' + id + '/candidates/' + dataForm.id_candidate + '/select-candidate', dataForm).toPromise();
    }

    deleteOutsourcingWorspace(idJob: string) {
        return this.http.delete(environment.api.workspace + '/outsourcings/delete/' + idJob).toPromise();
    }

}
