import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LiabilityAmount } from 'src/app/entities/workspace/liability-amount';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class LiabilityAmountsService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<LiabilityAmount>> {
        return this.http.get<Array<LiabilityAmount>>(environment.api.workspace + '/liability-amounts').toPromise();
    }

    getById(id: string) {
        return this.http.get<LiabilityAmount>(environment.api.workspace + '/liability-amounts/' + id).toPromise();
    }

    save(dataForm: LiabilityAmount): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: LiabilityAmount) {
        return this.http.post(environment.api.workspace + '/liability-amounts', dataForm).toPromise();
    }

    edit(dataForm: LiabilityAmount) {
        return this.http.put(environment.api.workspace + '/liability-amounts/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/liability-amounts/' + id).toPromise();
    }
}
