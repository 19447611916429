import { Document } from './document';
import { File } from 'src/app/entities/global/file';
import { JobItemClaimInformation } from './job-item-claim-information';
import { ScaningItem } from '../companies/scaning-item';

export class JobInventoryItem {
    public id: string;
    public name: string;
    public job_id: string;
    public added_by_employee_id: string;
    public added_by_employee: string;
    public document: Document;
    public packed_by: string;
    public comments: string;
    public cubic_feets: number;
    public weight: number;
    public value: number;
    public pads: number;
    public is_box: boolean;
    public is_additional: boolean;
    public display_in_new_binding: boolean;
    public disassembled: boolean;
    public remarks: string;
    public special_handling: string;
    public images: Array<File>;
    public job_item_claim_information: JobItemClaimInformation;
    public inventory_category_id: string;
    public codeItemQr: string;
    public scaning_items: ScaningItem[];
    public created: number;
    public updated: number;
    public deleted: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.job_id = null;
        this.added_by_employee_id = null;
        this.added_by_employee = null;
        this.document = null;
        this.packed_by = null;
        this.comments = null;
        this.cubic_feets = null;
        this.weight = null;
        this.value = null;
        this.pads = null;
        this.is_box = false;
        this.is_additional = false;
        this.display_in_new_binding = false;
        this.disassembled = false;
        this.remarks = null;
        this.special_handling = null;
        this.images = [];
        this.job_item_claim_information = new JobItemClaimInformation();
        this.inventory_category_id = null;
        this.codeItemQr = null;
        this.scaning_items = [];
        this.created = null;
        this.updated = null;
        this.deleted = null;
    }
}
