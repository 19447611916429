<div class="row layout-top-spacing">
    <div class="col-lg-12">


        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/board-jobs">Storage Companies</a></li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="statbox widget box box-shadow">

            <div class="widget-header">
                <h4>
                    Jobs Board
                </h4>
            </div>

            <div class="widget-content widget-content-area">

                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                    <div class="row">

                            <!-- <div class="col-md-4">
                                <label for="company_name">Date</label>
                                <app-date-range-picker [placeholder]="'Not Assigned'"
                                    (onChange)="searchDate($event)"></app-date-range-picker>
                            </div>
                            <div class="col-md-8">
                            </div> -->
                            <!-- <div class="col-md-6" *ngIf="!boardJob.id">
                                <label for="company_name">Jobs</label>
                                <select id="inputState" [(ngModel)]="boardJob.job_id"  [appSelect2] [theme]="'mxp360-form'"
                                    name="pg_sold_by_name" style="width: 100%;">
                                    <option *ngFor="let job of jobs"
                                    value="{{job.job_id}}">
                                    {{job.job_code}}</option>
                                </select>
                            </div> -->
                            <div class="col-md-6"  *ngIf="boardJob.id">
                                <label for="company_dot">Jobs</label>
                                <input type="text" class="form-control mb-4" name="company_otro" disabled="disabled"
                                    placeholder="Job" [(ngModel)]="boardJob.job_code">
                            </div>
                            <div class="col-md-6">
                                <label for="company_dot">Offer</label>
                                <input type="number" class="form-control mb-4" name="company_dot"
                                    placeholder="Offer" [(ngModel)]="boardJob.offer">
                            </div>
                          

                            <div class="col-md-6">
                                <label for="company_name">Speccial Conditions</label>
                                <textarea name="operation_details" class="form-control" placeholder="Special Conditions"
                                    [(ngModel)]="boardJob.special_conditions"></textarea>
                            </div>

                            <div class="col-md-6">
                                <label class="new-control new-checkbox checkbox-primary">
                                    <input type="checkbox" class="new-control-input" name="vip" [(ngModel)]="boardJob.is_vip">
                                    <span class="new-control-indicator"></span>Vip
                                </label>
                            </div>

                    </div>

                    <div class="text-center">
                        <button class="btn btn-primary ml-1 mt-3" type="submit">
                            <i class="far fa-save" style="height: 24px;"></i> &nbsp; Submit
                        </button>
                    </div>

                </form>


            </div>
        </div>

    </div>
</div>