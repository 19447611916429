import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DriverInvoice } from 'src/app/entities/workspace/driver-invoice';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DriverInvoicesService {

    constructor(
            private http: HttpClient
        ) {
        
        }
    
        add(dataForm) {
            return this.http.post(environment.api.workspace + '/driver-invoices', dataForm).toPromise();
        }
    
        edit(dataForm) {
            return this.http.put(environment.api.workspace + '/driver-invoices/' + dataForm.id, dataForm).toPromise();
        }

      
        getById(id: string) {
            return this.http.get<DriverInvoice>(environment.api.workspace + '/driver-invoices/' + id).toPromise();
        }
      
        get() {
            return this.http.get<any>(environment.api.workspace + '/driver-invoices').toPromise();
        }
      
        getFullView(data) {
            return this.http.get<any>(environment.api.workspace + '/driver-invoices', { params: data }).toPromise();
        }

        
        remove(id: string) {
            return this.http.delete(environment.api.workspace + '/driver-invoices/' + id).toPromise();
        }
        
        verify(id: string) {
            return this.http.get<any>(environment.api.workspace + '/driver-invoices/' + id + '/verify').toPromise();
        }

        getDriverEmployees() {
            return this.http.get<any>(`${environment.api.workspace}/employee-drivers`).toPromise();
        }

        getByEstimateCode(code: string) {
            return this.http.get<Array<any | {message: string}>>(environment.api.workspace + '/driver-invoices/search/' + code).toPromise();
        }


}
