export class PhoneRingDestination {

    public id: string;
    public target: "EMPLOYEE" | "RING_GROUP" | "IVR" | "QUEUE" | "CALL_FLOW_CONTROL" | "EXTERNAL_FORWARD" | "VOICEMAIL" | "PHONE_LINE"|'EXTENSION';
    public target_id: string;

    constructor() {
        this.id = null;
        this.target = null;
        this.target_id = null;
    }

}
