export class BlackoutMoveDate {
    
    id: string;
    date: string;
    date_string: number;
    employee_id: string;
    created: number;
    updated: number;

    constructor() {
        this.id = null;
        this.date = null;
        this.date_string = null;
        this.employee_id = null;
        this.created = null;
        this.updated = null;
    }
}
