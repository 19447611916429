<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Sales Commissions
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Sales Commissions</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center top-actions-btns">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
                <div class="btn-group dropleft height-dropleft btn-export" role="group">
                    <button id="btnOutline" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="ml-2 mr-1">
                            Export
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>                    
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnOutline" style="will-change: transform;">
                        <a href="javascript:void(0);" class="dropdown-item file-pdf" (click)="downloadPDF()">
                            <i class="fas fa-file-pdf"></i>
                            <span>PDF</span>
                        </a>
                        <a href="javascript:void(0);" class="dropdown-item file-excel" (click)="downloadXml()">
                            <i class="fas fa-file-excel"></i>
                            <span>EXCEL</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- header -->


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-md-6 offset-md-3 block-search">
            <label class="control-label">Select period:</label>
            <app-periods-filter (onChange)="getFilterDates($event)"></app-periods-filter>
        </div>
        <div class="row col-lg-12 col-md-12 block-search">
            <div class="col-2 commission-breakdown-switch">
                <div class="toggle-switch">
                    <label class="switch s-icons s-outline  s-outline-secondary">
                        <input type="checkbox" [(ngModel)]="swichEmployeeWD" name="swichEmployeeWD">
                        <span class="slider round">
                            <i class="fa-solid fa-user icon-employee"></i>
                            <i class="fa-solid fa-briefcase icon-work-department"></i>
                        </span>
                    </label>
                </div>
            </div>      
            
            <div class="col-lg-5 col-xl-4" *ngIf="swichEmployeeWD">
                <label class="control-label">Work Department</label>
                <select name="wd_workDepartmen_id" class="form-control" [(ngModel)]="workDepartmentSelected">
                    <option *ngFor="let workDepartmen of workDepartments" value="{{workDepartmen.id}}">
                        {{workDepartmen.name | titlecase}}
                    </option>
                </select>
            </div>

            <div class="col-lg-5 col-xl-4" *ngIf="!swichEmployeeWD">
                <label class="control-label">Employee</label>
                <select name="wd_employees_id" class="form-control" [(ngModel)]="employeeSelected">
                    <option *ngFor="let employe of employees" value="{{employe.id}}">
                        {{employe.name | titlecase}}
                    </option>
                </select>
            </div>            
            
            <div class="col-lg-5 col-xl-4">
                <label class="control-label">Job Number</label>
                <input name="wd_job_number" class="form-control" [(ngModel)]="jobNumber">
            </div> 
        </div>  
        <div class="col-lg-12 col-md-12 block-search text-center">
            <button class="btn btn-warning btn-search" (click)="search()">
                Search
            </button>
        </div>
    </div>
  
    <div class="widget-content searchable-container list">
        <div class="searchable-items list">
            <div class="items items-header-section">
                <div class="item-content">
                    <div class="">
                        <h4>
                            CUSTOMER
                        </h4>
                    </div>
                    <div class="">
                        <h4>
                            SERVICE
                        </h4>
                    </div>
                    <div class="text-center">
                        <h4>
                            STATUS
                        </h4>
                    </div>
                    <div class="thead-action text-center">
                        <h4>
                            ACTIONS
                        </h4>
                    </div>
                </div>
            </div>
            <div class="items cursor-pointer" *ngFor="let row of rows | search:filterSearch | async | paginate: {itemsPerPage:25, currentPage: actualPage}; index as i;">
            <div class="item-content">
                <div class="user-profile">
                    <div class="user-meta-info">
                        <p class="user-name">{{row.estimate_document.code}}</p>
                        <p class="user-name">{{row.employee.name}}</p>
                        <p class="user-work"> <strong>Booked Date: </strong>
                            <ng-container *ngIf="['TOTAL_BOL', 'ADDITIONAL_BOL'].includes(row.commission_setting?.value_base_on)">
                                <span *ngIf="row.estimate_delivery_date">{{row.estimate_delivery_date | dateTime}}</span>
                                <span *ngIf="!row.estimate_delivery_date">Date Not Defined</span>
                            </ng-container>
                            <ng-container *ngIf="!['TOTAL_BOL', 'ADDITIONAL_BOL'].includes(row.commission_setting?.value_base_on)">
                                <span *ngIf="row.estimate_booked_date">{{row.estimate_booked_date | dateTime}}</span>
                                <span *ngIf="!row.estimate_booked_date">Date Not Defined</span>
                            </ng-container>
                         </p>
                        
                    </div>
                </div>
                <div class="col-service">
                    <p class="usr-email-addr">
                        <strong>Total: </strong>
                        <span>{{row.estimate_total | currency}}</span>
                    </p>
                    <p class="usr-email-addr">
                        <strong>Payment: </strong>
                        <span>{{row.total_payments | currency}}</span>
                    </p>
                    <p class="usr-email-addr">
                        <strong>Commission: </strong>
                        <span>{{row.total_commission | currency}}</span>
                    </p>                    
                </div>
                <div class="status text-center">
                    <div class="td-content">
                        <span *ngIf="row.status === 'PENDING_PAYMENT'" class="badge badge-warning">
                            Pending Payment
                        </span>
                        <span *ngIf="row.status === 'PAID'" class="badge badge-success">
                            Paid
                        </span>
                        <span *ngIf="row.status === 'REFUNDED'" class="badge badge-danger">
                            Refunded
                        </span>
                        <span *ngIf="row.status === 'PENDING_REFUND'" class="badge badge-warning">
                            Pending Refund
                        </span>
                        <span *ngIf="row.status === 'VOID'" class="badge badge-danger">9
                            Void
                        </span>
                        <span *ngIf="row.status === 'CANCELLED'" class="badge badge-danger">
                            Cancelled
                        </span>

                        <ng-container *ngIf="row.job_cancelation_date">
                            <br>
                            <span class="badge badge-danger">
                                Job Canceled <br>
                                {{row.job_cancelation_date | date}}
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="btn-actions">               
                    <a class="btn btn-info" (click)="openNewTab(row.estimate_id)">
                        View Estimate
                    </a>

                    <a class="btn btn-danger" (click)="openDeduction(row)" *ngIf="row.status === 'PAID' && !row.walve_deduction_date">
                        Deduction
                    </a>

                    <a class="btn btn-warning" (click)="openWaiveDeduction(row.id)" *ngIf="row.job_cancelation_date && !row.walve_deduction_date">
                        Waive Deduction
                    </a>

                    <a class="btn btn-danger" (click)="openCancelation(row.id)" *ngIf="row.status === 'PENDING_PAYMENT' && row.job_cancelation_date">
                        Apply Cancellation
                    </a>
                </div>
            </div>
        </div>

        <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
        </div>
    </div>
    <!-- body -->
</div>


<div class="modal fade" #modalDeduction id="modalDeduction" tabindex="-1" role="dialog" aria-labelledby="customerremarksTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="customerremarksTitle">Are you sure you want to make this deduction?</h5>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <div>
                        <div class="list-title">
                            <textarea id="edit_remarks_customer" placeholder="Enter Description Deduction" rows="3"
                                class="form-control" name="edit_remarks"
                                [(ngModel)]="remarks"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button (click)="saveRemark()" data-dismiss="modal" type="button" class="btn btn-warning">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>