import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportQAView } from 'src/app/entities/workspace/report-qa-view';

@Injectable({
  providedIn: 'root'
})
export class ReportQAService {

  constructor(private http: HttpClient) { }

  get(betweenDates: BetweenDates) {
    console.log('dates', betweenDates);
    const params: any = {
      "start_time": betweenDates.dateStart,
      "end_time": betweenDates.dateEnd
  };
    return this.http.get<Array<ReportQAView>>(environment.api.workspace + '/qa-jobs', {params}).toPromise();
  }
}
