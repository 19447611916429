import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { TariffVersion } from 'src/app/entities/workspace/tariff-version';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { TariffVersionsService } from 'src/app/services/workspaces/tariff-versions.service';
declare const swal: any;
declare const jQuery: any;
@Component({
  selector: 'app-tariff-versions',
  templateUrl: './tariff-versions.component.html',
  styleUrls: ['./tariff-versions.component.scss']
})
export class TariffVersionsComponent implements OnInit {

  public tariffVersions: TariffVersion[];
  public dataForm: TariffVersion;
  public actualPage: number;
  private constantsMessages = ConstantsMessages;
  constructor(
    private tariffVersionsService: TariffVersionsService,
    public tariffVersionFactory: TariffVersionService,
    private helperService: HelperService
  ) {
    this.tariffVersions = [];
    this.actualPage = 1;
    this.dataForm = new TariffVersion();
  }

  ngOnInit(): void {
    this.load();
  }
  
  load() {
    this.helperService.showLoadingMxpro360();
    this.tariffVersionsService.getAll().then(
      (response) => {
        this.tariffVersions = response
        for (const tariffVersion of this.tariffVersions) {
          if (tariffVersion.is_active) {
            this.tariffVersionFactory.setTariffVersionActive(tariffVersion);
            break;
          } else {
            this.tariffVersionFactory.removeTariffVersionActive();
          }
        }
      }
    ).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  remove(id: string) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.tariffVersionsService
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

  versionIsActive(version){    
      this.helperService.showLoadingMxpro360();
      this.tariffVersionsService
      .save(version)
      .then(() => {
          this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
          this.load();
      })
      .catch((error) => {
          this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
          console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    
  }
}
