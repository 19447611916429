import { AuthService } from 'src/app/services/auth.service';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { BoardJob } from 'src/app/entities/workspace/board-job';
import { BoardJobsService } from 'src/app/services/companies/board-jobs.service';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';
import { CarriersContactsCompanyService } from 'src/app/services/companies/carriers-contacts-company.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { JobOutsourcing } from 'src/app/entities/workspace/job-outsourcing';
import { JobOutsourcingsService } from 'src/app/services/companies/job-outsourcings.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { JobView } from 'src/app/entities/workspace/job-view';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { CompanySettings } from 'src/app/entities/workspace/company-settings';
import { Balance } from 'src/app/entities/workspace/balance';
import { BalancesService } from 'src/app/services/companies/balances.service';


declare var jQuery;
declare var swal;
declare var moment;
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  private constantsMessages = ConstantsMessages;
  private balances: Balance[];
  private generalSettings: any;
  public actualPage: number;
  public betweenDates: BetweenDates;
  public boardJobForm: BoardJob;
  public carrierBalanceOffer: number;
  public carrierBalancePercentage: number;
  public carrierContacts: Array<CarrierContact>;
  public carriers: Carrier[];
  public checkContacts: string[];
  public employees: Array<EmployeeView>;
  public filterData: FilterData;
  public filterSearch: string;
  public isManualAssignment: boolean;
  public jobToAssign: JobView;
  public jobToAssignId: string;
  public paginator: Paginator;
  public quickAssign: boolean;
  public quickAssignCarrier: string;
  public rows;
  public sort: number;
  public specialConditions: string;
  public jobPayments: number;

  @ViewChild('emailsModal') emailsModal: ElementRef;
  @ViewChild("modalInformationCarriers") modalInformationCarriers: ElementRef;

  constructor(
    private balancesService: BalancesService,
    private boardJobsService: BoardJobsService,
    private carriersContactsCompanyService: CarriersContactsCompanyService,
    private carriersService: CarriersService,
    private employeesService: EmployeesService,
    private generalSettingsService: GeneralSettingsService,
    private jobOutsourcingsService: JobOutsourcingsService,
    private jobsService: JobsService,
    public authService: AuthService,
    public helperService: HelperService,
  ) {
    this.actualPage = 1;
    this.balances = [];
    this.betweenDates = new BetweenDates();
    this.betweenDates.dateEnd = moment().add(1, 'months').second(59).minute(59).hour(23).unix() * 1000;
    this.betweenDates.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
    this.boardJobForm = new BoardJob();
    this.carrierBalanceOffer = 0;
    this.carrierBalancePercentage = 0;
    this.carrierContacts = [];
    this.carriers = [];
    this.carriers = [];
    this.checkContacts = [];
    this.employees = [];
    this.filterData = new FilterData();
    this.filterData.filter_date_for = 'PICKUP';
    this.filterSearch = '';
    this.generalSettings = new CompanySettings();
    this.isManualAssignment = false;
    this.jobToAssign = new JobView();
    this.jobToAssignId = '';
    this.paginator = new Paginator();
    this.quickAssign = false;
    this.quickAssignCarrier = 'IN_HOUSE';
    this.sort = 5;
    this.specialConditions = '';
    this.jobPayments = 0;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.sortBy();
    this.loadEmployee();
    this.loadCarriers();
    this.loadCompanySettings();
  }

  loadCompanySettings(): void {
    this.generalSettingsService
    .get()
    .then((response: any) => {
        this.generalSettings = response;
    })
    .catch((error) => {
        console.error('error', error);
    })
    .finally(() => {
        this.helperService.hideLoadingMxpro360();
    });
  }

  load(): void {
    this.helperService.showLoadingMxpro360();
    this.paginator.paginator.start = this.betweenDates.dateStart;
    this.paginator.paginator.end = this.betweenDates.dateEnd;
    this.jobsService
      .getAll(this.paginator, this.filterData)
      .then((response) => {
        this.rows = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
    this.carriersService.getAll()
      .then((carriers) => {
        this.carriers = carriers;
      })
  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.load();
    }
  }

  /**
   * recibe y asigna fechas para filtro
   *
   * @param date Filtro de fechas a buscar
   */
  getFilterDates(date: BetweenDates): void {
    this.betweenDates = date;
  }

  /**
   * Metodo que abre un nuevo tab al dar click en un estimate
   *
   * @param estimateId Id del estimate a abrir
   */
  openNewTab(estimateId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(estimateId, 'estimate');
  }

  sortBy() {
    switch (Number(this.sort)) {
      case 1:
        this.paginator.paginator.order_by = 'estimate.document.code';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 2:
        this.paginator.paginator.order_by = 'estimate.document.code';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 3:
        this.paginator.paginator.order_by = 'customer.name';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 4:
        this.paginator.paginator.order_by = 'customer.name';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 5:
        this.paginator.paginator.order_by = 'estimate.pickup.range_start';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 6:
        this.paginator.paginator.order_by = 'estimate.pickup.range_start';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 7:
        // this.paginator.paginator.order_by = 'estimate.booked_date';
        this.paginator.paginator.order_by = 'created';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 8:
        // this.paginator.paginator.order_by = 'estimate.booked_date';
        this.paginator.paginator.order_by = 'created';
        this.paginator.paginator.order_type = 'DESC';
        break;
      case 9:
        this.paginator.paginator.order_by = 'estimate.delivery.address.state';
        this.paginator.paginator.order_type = 'ASC';
        break;
      case 10:
        this.paginator.paginator.order_by = 'estimate.delivery.address.state';
        this.paginator.paginator.order_type = 'DESC';
        break;

      default:
        break;
    }
    this.load();
  }

  /**
   * Busca los datos por el filtro de fecha
   */
  search(): void {

    if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
      this.betweenDates.dateStart = moment().subtract(1, 'months').second(0).minute(0).hour(0).unix() * 1000;
      this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }

    // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
    this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().subtract(1, 'months').second(0).minute(0).hour(0).unix() * 1000;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

    this.paginator.paginator.start = this.betweenDates.dateStart;
    this.paginator.paginator.end = this.betweenDates.dateEnd;
    // const data: any = {
    //   start: this.betweenDates.dateStart,
    //   end: this.betweenDates.dateEnd
    // };

    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getAll(this.paginator, this.filterData)
      .then((response) => {
        this.rows = response.data;
        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  toggleQuickAssign() {
    this.quickAssign = !this.quickAssign;
  }

  detectChange(type: string, job, event) {
    this.carrierBalanceOffer = 0;
    this.carrierBalancePercentage = 0;
    this.jobToAssign = job;
    this.jobToAssign.estimate.total = Number(this.jobToAssign.estimate.total.toFixed(2));
    this.isManualAssignment = false;
    let saveDirectly = false;

      // averigua el valor porcentual para el offer
      if (this.generalSettings.settings.price_setting.carrier_balance_mode == 'BY_PERCENTAGE') {
        const carrierBalancePercentage = this.generalSettings.settings.price_setting.carrier_balance_percentage;
        this.carrierBalancePercentage = this.carrierBalancePercentage ? this.carrierBalancePercentage : carrierBalancePercentage;
        this.carrierBalanceOffer = (this.carrierBalancePercentage * this.jobToAssign.estimate.total) / 100;
        this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
    } else {
        // averigua el valor restante para pagar
        let paidValue = 0
        for(let balance of this.balances) {
            if (balance.status === 'PAID') {
                paidValue += balance.amount;
            }
        }
        this.carrierBalanceOffer = this.jobToAssign.estimate.total - paidValue;
        this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
        this.carrierBalancePercentage = (this.carrierBalanceOffer * 100 ) / this.jobToAssign.estimate.total;
        this.carrierBalancePercentage = Number(this.carrierBalancePercentage.toFixed(2));
    }


    // if (event.target.checked && job.board_job == null) {
    if (event.target.checked) {
      this.boardJobForm = new BoardJob();
      // se clona en el elemento de la vista para manipular el estado del check
      job.board_job = {...this.boardJobForm};
      job.board_job.is_private = false;
      // Abre modal para guardar un board job
      jQuery(this.modalInformationCarriers.nativeElement).modal('show');
      setTimeout(() => {
        // se vuelve a dejar nulo para evitar mal funcionamiento
        job.board_job = null;
      }, 500);
    } else {
      this.boardJobForm = job.board_job;
      saveDirectly = true;
    }

    if (type == 'PRIVATE') {
      this.boardJobForm.is_private = event.target.checked;
    } else {
      this.boardJobForm.is_vip = event.target.checked;
    }

    if (saveDirectly) {
      // Guardar un board job
      this.saveBoardJob();
    }
  }

  saveJobOutsourcing() {
    this.jobToAssign.job_outsourcing = new JobOutsourcing();
    this.jobToAssign.job_outsourcing.carrier_id = this.quickAssignCarrier;
    this.jobToAssign.job_outsourcing.type = 'ALL';
    this.jobToAssign.job_outsourcing.status = 'PENDING_SIGNATURE';
    this.jobToAssign.job_outsourcing.special_conditions = this.boardJobForm.special_conditions;
    this.jobToAssign.job_outsourcing.carrier_pay = this.boardJobForm.offer;
    const data = {
      job_outsourcing: this.jobToAssign.job_outsourcing,
      carrier_contacts: []
    };

    this.helperService.showLoadingMxpro360();

    this.jobOutsourcingsService
      .assignOutsourcingWorkspace(this.jobToAssign.id, data, true)
      .then(() => {
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this.load();
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  dismissModal() {
    jQuery(this.modalInformationCarriers.nativeElement).modal('hide');    
  }
  
  saveBoardJob() {
    // mostramos el loading
    this.boardJobForm.offer = this.carrierBalanceOffer;
    this.helperService.showLoadingMxpro360();
    this.boardJobsService
      .save(this.boardJobForm, this.jobToAssign.id)
      .then(() => {
        // cerramos modal
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this.load();
        jQuery(this.modalInformationCarriers.nativeElement).modal('hide');
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  updateOfferPercentage() {
    
    this.carrierBalancePercentage = (this.carrierBalanceOffer * 100) / this.jobToAssign.estimate.total;
    this.carrierBalancePercentage = Number(this.carrierBalancePercentage.toFixed(2));
    // this.carrierBalanceOffer = (this.boardJobForm.offer * 100) / this.jobToAssign.estimate.total;
    // this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));

  }

  getCarrierBalancePercentage() {    
    if (this.generalSettings.settings.price_setting.carrier_balance_mode == 'BY_PERCENTAGE') {
      const carrierBalancePercentage = this.generalSettings.settings.price_setting.carrier_balance_percentage;
      this.carrierBalancePercentage = this.carrierBalancePercentage ? this.carrierBalancePercentage : carrierBalancePercentage;
      // obtenemos multiplicacion redondeada para evitar exeso de decimales.
      this.carrierBalanceOffer = Math.round(this.jobToAssign.estimate.total * this.carrierBalancePercentage) / 100;
      this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
    } else {
      // averigua el valor restante para pagar
      this.jobPayments = 0
      this.balancesService
      .getById(this.jobToAssign.estimate.id, {})
      .then((response) => {
          this.balances = response;
          for(let balance of this.balances) {
            if (balance.status === 'PAID') {
              this.jobPayments += balance.amount;
            }
          }

          this.boardJobForm.offer = this.jobToAssign.estimate.total - this.jobPayments;
          this.boardJobForm.offer = Number(this.boardJobForm.offer.toFixed(2));
          this.carrierBalanceOffer = this.boardJobForm.offer;
          this.carrierBalancePercentage = (this.boardJobForm.offer * 100 ) / this.jobToAssign.estimate.total;
          this.carrierBalancePercentage = Number(this.carrierBalancePercentage.toFixed(2));
      });
    }
        
    jQuery(this.modalInformationCarriers.nativeElement).modal('show');
  }

  assignCarrier(job) {
    this.carrierBalanceOffer = 0;
    this.carrierBalancePercentage = 0;
    this.jobToAssign = job;
    this.jobToAssign.estimate.total = Number(this.jobToAssign.estimate.total.toFixed(2));
    this.isManualAssignment = true;
    if (this.quickAssignCarrier !== 'IN_HOUSE') {
      this.getCarrierBalancePercentage();
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Do you want to assign job In-House?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Assign it!'
    })
        .then((result) => {
            if (result.value) {
                this.helperService.showLoadingMxpro360();
                // asignar a mi mismo
                this.jobsService.patchEntity(job.id, {allocation: 'IN_HOUSE'})
                .then(() => {
                  this.helperService.hideLoadingMxpro360();
                  this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                  this.load();
                });
            }
        });
    }
  }

  selectCarrier(): void {

    jQuery(this.modalInformationCarriers.nativeElement).modal('hide');
    // this.openEmailsModal();

    this.jobToAssign.job_outsourcing = new JobOutsourcing();
    this.jobToAssign.job_outsourcing.carrier_id = '' + this.quickAssignCarrier;
    this.jobToAssign.job_outsourcing.type = 'ALL';
    this.jobToAssign.job_outsourcing.status = 'PENDING_SIGNATURE';
    this.jobToAssign.job_outsourcing.special_conditions = this.boardJobForm.special_conditions;
    this.jobToAssign.job_outsourcing.carrier_pay = Number(this.carrierBalanceOffer.toFixed(2));
    const data = {
      job_outsourcing: this.jobToAssign.job_outsourcing,
      carrier_contacts: []
    };

    this.helperService.showLoadingMxpro360();

    this.jobOutsourcingsService
      .assignOutsourcingWorkspace(this.jobToAssign.id, data, false)
      .then((response) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this.jobToAssignId = response['jobOutsourcingId']
        if (this.quickAssignCarrier == 'IN_HOUSE') {
          this.load();
        } else {
          this.loadContactsByCarrier();
        }
        this.isManualAssignment = false;
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  loadContactsByCarrier() {
    this.helperService.showLoadingMxpro360();
    this.carriersContactsCompanyService
      .getFullContactsByCarrier(this.jobToAssign.job_outsourcing.carrier_id)
      .then((response) => {
        this.carrierContacts = response;
        this.checkContacts = [];
        if (this.carrierContacts.length > 0) {
          this.selectDefaultCarrier();
          jQuery(this.emailsModal.nativeElement).modal('show');
        }
      });
  }



  private loadCarriers() {
    this.helperService.showLoadingMxpro360();
    this.carriersService
      .getAll()
      .then((response) => {
        this.carriers = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }



  selectDefaultCarrier() {
    if (this.carrierContacts.length > 0) {
      for (let contact of this.carrierContacts) {
        if (contact.main) {
          this.checkContacts.push(contact.id);
        }
      }
    }

  }


  closeEmailsModal(): void {
    this.sendEmails();

    this.load();
    jQuery(this.emailsModal.nativeElement).modal('hide');
  }


  sendEmails() {
    const data = { contacts: this.checkContacts };
    this.helperService.showLoadingMxpro360();
    this.jobOutsourcingsService.sendJobAcceptanceEmail(this.jobToAssignId, data).then(() => {
      this.helperService.showMessageSnackbar(this.constantsMessages.EMAIL_SENT);
    }).catch((error) => {
      swal(
        'Error',
        error.error.message,
        'error'
      );
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
    // let url = environment.app.carriers + '/#/job-acceptance/' + this.authService.workspaceSession.id + '/sign/' + this.job.job_outsourcing.id;
  }


  setDefault(id) {
    if (this.checkContacts.length > 0) {
      const indexEmployeeId = this.checkContacts.indexOf(id);
      // verifica si se debe agregar o quitar el employeeId al arreglo de ids
      if (indexEmployeeId >= 0) {
        // removemos el item des checkeado
        this.checkContacts.splice(indexEmployeeId, 1);
      } else {
        // agregamos el item checkeado
        this.checkContacts.push(id);
      }
    } else {
      this.checkContacts.push(id);
    }

  }

  loadEmployee() {
    this.helperService.showLoadingMxpro360();
    this.employeesService
      .getAll()
      .then((response) => {
        this.employees = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
     * Permite cancelar un carrier asignado a un trabajo
     */
  cancelJobOutsourcing(job) {
   
    this.helperService.showLoadingMxpro360();
    if (job.job_outsourcing == null) {
      this.jobsService.patchEntity(job.id, {allocation: null})
      .then(() => {
        this.helperService.hideLoadingMxpro360();
        this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
        this.load();
      });
    } else {
      job.job_outsourcing.reason = 'OTHER';
      this.jobOutsourcingsService
        .toCancel(job.job_outsourcing)
        .then(() => {
          this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
          this.load();
        })
        .catch((error) => {
          console.error('error', error);
          this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
  
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        });
    }
  }

}