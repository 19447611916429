<div class="widget widget-table-two" *ngFor="let jobSeeAllSalesmanPerformanceOnHisTeam of jobsSeeAllSalesmanPerformanceOnHisTeam">

    <div class="widget-heading">
        <h5 class="text-success">{{jobSeeAllSalesmanPerformanceOnHisTeam.worksDepartment}} Salesman Performance</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-success"><div class="th-content text-light">SALESMAN</div></th>
                        <th class="bg-success"><div class="th-content text-light">TOTAL QUOTES</div></th>
                        <th class="bg-success"><div class="th-content text-light">EMAILS SENT</div></th>
                        <th class="bg-success"><div class="th-content text-light">QUOTES SENT</div></th>
                        <th class="bg-success"><div class="th-content text-light">QUOTES BOOKED</div></th>
                        <th class="bg-success"><div class="th-content text-light">AMOUNT QUOTES BOOKED</div></th>
                        <th class="bg-success"><div class="th-content text-light">CUSTOMER PAYMENTS</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let reportData of jobSeeAllSalesmanPerformanceOnHisTeam.reportData">
                        <td><div class="td-content customer-name">{{reportData.salesman}}</div></td>
                        <td><div class="td-content product-brand">{{reportData.total_quotes}}</div></td>
                        <td><div class="td-content">{{reportData.emails_sent}}</div></td>
                        <td><div class="td-content "><span class="">{{reportData.quotes_sent}}</span></div></td>
                        <td><div class="td-content "><span class="">{{reportData.quotes_booked}}</span></div></td>
                        <td><div class="td-content "><span class="">{{reportData.amount_quotes_booked}}</span></div></td>
                        <td><div class="td-content "><span class="">{{reportData.customer_payments}}</span></div></td>
                    </tr>
                    
                   
                </tbody>
            </table>
        </div>
    </div>
</div>