<div class="row layout-top-spacing" id="cancel-row">
    <div class="col-xl-12 col-lg-12 col-md-12" [hidden]="hiddenCalendar">

        <div class="row">
            <div class="col-6 align-self-center layout-spacing">
                <h3>
                    <span [hidden]="calendarAT">Jobs HHG Calendar</span>
                    <span [hidden]="!calendarAT">Jobs AT Calendar</span>
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a appWorkspaceRouterLink="/dashboard">
                                <i class="fas fa-home"></i> Dashboard
                            </a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            <span [hidden]="calendarAT">Jobs HHG Calendar</span>
                            <span [hidden]="!calendarAT">Jobs AT Calendar</span>
                        </li>
                    </ol>
                </nav>
            </div>
    
            <div class="col-6 text-sm-right text-center align-self-center layout-spacing pl-0">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                        <i class="fa-solid fa-filter mr-2"></i>
                        <span>Hide Filters</span> 
                    </a>
                </div>
            </div>
        </div>
        <!-- header -->

        <!-- filter -->
        <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">

            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-6 col-lg-6 col-xl-4 pl-0 mt-3">
                    <label>Salesman</label>
                    <select name="operator_user_id" [(ngModel)]="userId" id="operator_user_id" class="form-control">
                        <option value="">All</option>
                        <ng-container *ngFor="let user of employees">
                            <option value="{{user.id}}">
                                {{user.nickname | filterReplace}}
                            </option>
                        </ng-container>
                    </select>
                </div>                  
                <div class="col-6 col-lg-6 col-xl-4 pl-0 mt-3">
                    <label>State</label>
                    <select name="state" id="state" [(ngModel)]="state" class="form-control">
                        <option value="">All</option>
                        <option value="FOLLOW_UP">Follow Up</option>
                        <option value="IN_OPERATION">In Operation</option>
                        <option value="LOADED_FOR_STORAGE">Loaded for Storage</option>
                        <option value="IN_STORAGE">In Storage</option>
                        <option value="LOADED_FOR_DELIVERY">Load for Delivery</option>
                        <option value="DELIVERED">Delivered</option>
                    </select>
                </div>             
            </div>

            <div class="row col-lg-12 col-md-12 block-filter mb-3">
                <div class="col-6 col-lg-6 col-xl-4 pl-0 mt-3">
                    <label>Move Type</label>
                    <select name="move_type" id="move_type" [(ngModel)]="moveType" class="form-control">
                        <option value="">All</option>
                        <option value="RESIDENTIAL">Residential</option>
                        <option value="COMMERCIAL">Commercial</option>
                        <option value="MILITARY">Military</option>
                    </select>
                </div>         
                <div class="col-6 col-lg-6 col-xl-4 pl-0 mt-3">
                    <button class="btn btn-secondary btn-search" (click)="getFilter()">
                        Filter
                    </button>
                </div>
            </div>

        </div>

        <div class="widget-content widget-content-area">
            <div class="calendar-upper-section">
                <div class="row">
                    
                    <div class="col-12 checkbox-section">
                        <div class="row labels n-chk d-flex justify-content-center">

                            <label class="new-control new-checkbox checkbox-primary checkbox-boxes-delivery" [hidden]="calendarAT" >
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkBoxesDelivery" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Boxes Delivery</span>
                            </label>

                            <label class="new-control new-checkbox checkbox-warning checkbox-pack-day" [hidden]="calendarAT" >
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkPackDays" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Pack Days</span>
                            </label>

                            <label class="new-control new-checkbox checkbox-success checkbox-pick-up">
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkPickUps" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Pick Ups</span>
                            </label>

                            <label class="new-control new-checkbox checkbox-danger checkbox-delivery">
                                <input type="checkbox" class="new-control-input" [(ngModel)]="checkDelivery" (change)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Delivery</span>
                            </label>

                            <label class="new-control new-checkbox checkbox-danger checkbox-time-frame">
                                <input type="checkbox" class="new-control-input" name="checkwindowsday" [(ngModel)]="checkWindowDays" (click)="initializeCalendar()">
                                <span class="new-control-indicator"></span>
                                <span class="ml-3">Time Frame</span>
                            </label>

                        </div>
                    </div>

                    <div class="col-12 mt-3 mb-5 info-icon">
                        <div class="d-flex justify-content-center">
                            <i class="fas fa-calendar-check mr-3 tags calendar" titles="Moving date confirmed"></i>
                            <i class="fas fa-file-signature mr-3 tags signature" titles="Customer`s electronic signature accepted "></i>
                            <i class="fas fa-credit-card mr-3 tags payment" titles="Customer`s first payment accepted "></i>
                            <i [hidden]="calendarAT"  class="fas fa-car mr-3 tags car" titles="Vehicle including on the estimate"></i>
                            <i class="fas fa-plane mr-3 tags interstate" titles="Interstate move"></i>
                            <i class="fas fa-truck mr-3 tags carrier" titles="Job took by a carrier"></i>
                        </div>                        
                    </div>

                </div>
            </div>
            
            <div [hidden]="hiddenCalendar" #divCalendar id="calendar"></div>

        </div>
       

    </div>

    <div class="row col-xl-12 col-lg-12 col-md-12 col-sm-12" [hidden]="!hiddenCalendar">

        <div class="row col-xl-4 col-lg-5 col-md-8 col-sm-12 align-self-center mb-3">

            <button class="btn btn-outline-success backDate m-0" (click)="backDate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </button> 
            <h3 class="m-1 mx-2">               
                {{selectedDay | date}}              
            </h3>
            <button class="btn btn-outline-success nextDate m-0" (click)="nextDate()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
            </button>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item">
                        <a (click)="backCalendar()" style="cursor: pointer;">
                            <i class="far fa-calendar-alt"></i>
                            Calendar
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Day Details
                    </li>
                </ol>
            </nav>
        </div>

        <div class="row col-xl-5 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">

            <div class="row mr-5" [hidden]="calendarAT" >                    
                <div class="indicator-box boxDelivery-color"></div>
                <span class="ml-2">Boxes Delivery</span> 
            </div>
            <div class="row mr-5" [hidden]="calendarAT">
                <div class="indicator-box packDays-color"></div>
                <span class="ml-2">Pack Days</span> 
            </div>
            <div class="row mr-5">
                <span class="indicator-box pickup-color"></span>
                <span class="ml-2">Pick Ups</span> 
            </div>
            <div class="row mr-5">
                <span class="indicator-box delivery-color"></span>
                <span class="ml-2">Delivery</span> 
            </div>

        </div>

        <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <button class=" btn btn-secondary back-calendar" (click)="backCalendar()"><i class="far fa-calendar-alt"></i></button>
            </div>
        </div> 
  
    </div>

    <div class="col-12 widget-content searchable-container list" [hidden]="!hiddenCalendar">

        <div class="searchable-items list">
            <div class="items items-header-section">
                <div class="item-content" style="position: initial!important;">
                    
                        <div class="col-2">
                            <h4>
                                Job Details
                            </h4>
                        </div>
                        <div class="col-2">
                            <h4>
                                Date
                            </h4>
                        </div>
                        <div class="col-2">
                            <h4>
                                Service
                            </h4>
                        </div>

                        <div class="col-2">
                            <h4>
                                Move Details
                            </h4>
                        </div>
                        <div class="col-2">
                            <h4>
                                Carrier/Agent
                            </h4>
                        </div>
                        <div class="col-2">
                            <h4>
                                Option
                            </h4>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12" [hidden]="!hiddenCalendar">

        <!-- Sección de Crew -->
        <div class="content-priority" style="overflow: hidden!important;">
            <!-- Contenedor Crew -->
            <div  *ngFor="let jobsAssigned of dayCalendar['today_assigned']" class="layout-px-spacing" style="padding: 0px!important;">              
                <div class="row scrumboard" id="cancel-row">
                    <div class="col-lg-12">    
                        <div class="task-list-section"> 

                            <div data-section="s-new" class="col-12 task-list-container" data-connect="sorting">
                                <div class="connect-sorting">

                                    <!-- Nombre del Crew -->
                                    <div class="task-container-header">

                                        <div class="col-3 d-flex align-items-center">
                                            <h6 class="s-heading" data-listTitle="In Progress">{{jobsAssigned.name}}</h6>
                                        </div>

                                        <div class="col-2 d-flex align-items-center font13">
                                            <span class="employees pr-1">Start Time </span>
                                            <ng-container class="row" *ngIf="jobsAssigned.start_time">
                                                <span>
                                                    <strong> {{jobsAssigned.start_time}}</strong>
                                                </span>   
                                            </ng-container> 
                                        </div>

                                        <div class="col-6 font13">
                                            <span class="employees">Foreman</span>
                                                <ng-container class="row" *ngFor="let assignedForeman of jobsAssigned.foreman">
                                                    <span>
                                                        {{assignedForeman.name | lowercase}}
                                                    </span>
                                                </ng-container>
                                            <br>
                                            <span class="employees">Drivers</span>
                                                <ng-container class="row" *ngFor="let assignedDrivers of jobsAssigned.drivers">
                                                    <span>
                                                        {{assignedDrivers.name | lowercase}}
                                                    </span>
                                                </ng-container>
                                            <br>
                                            <span class="employees">Workers</span>
                                                <ng-container class="row" *ngFor="let assignedWorkers of jobsAssigned.workers">
                                                    <span>
                                                        {{assignedWorkers.name | lowercase}}
                                                    </span>
                                                </ng-container>  
                                        </div>
                                    
                                    </div>
                                    
                                    <!-- Job dentro del Crew -->
                                    <div  *ngFor="let jobAssigned of jobsAssigned.jobs" class="connect-sorting-content" data-sortable="true">    
                                                                                                                      
                                        <div data-draggable="true" [ngClass]="{'pickup-job': jobAssigned.crew_work_type == 'pickups', 'delivery-job': jobAssigned.crew_work_type == 'deliveries', 'boxDelivery-job': jobAssigned.crew_work_type == 'boxes_delivery_day', 'packDays-job': jobAssigned.crew_work_type == 'pack_day' }" (click)="openNewTab(jobAssigned.estimate.id)" class="col-12 items card simple-title-task" style="padding: 12px;">
                                            <div class="row card-body cursor-pointer item-content crew-job" style="align-items: center;">

                                                <div class="col-2 user-profile">
                                                    <div class="user-meta-info" style="width: 171px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                                        <p class="user-name"><a>{{jobAssigned.estimate.document.code}}</a></p>
                                                        <p class="user-work">{{jobAssigned.estimate.customer.name}}
                                                            {{jobAssigned.estimate.customer.last_name}}</p>
                                                        <p class="user-work">{{jobAssigned.estimate.customer.phone}}</p>
                                                    </div>
                                                </div> 

                                                <div class="col-2 user-profile">
                                                    <div class="user-meta-info">
                                                        <p class="user-profile mb-0">
                                                            <strong>Move Date: </strong>{{jobAssigned.pickup_day.date_text | dateTime}}
                                                        </p>
                                                        <p class="user-work">
                                                            <strong>Start Date: </strong>{{jobAssigned.estimate.pickup.range_start | date}}
                                                        </p>
                                                        <p class="user-work">
                                                            <strong>End Date: </strong>{{jobAssigned.estimate.pickup.range_end | date}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-2 user-profile">
                                                    <div class="user-meta-info">
                                                        <p class="usr-email-addr">
                                                            <strong>From: </strong>{{jobAssigned.estimate.pickup.address.state}}
                                                            {{jobAssigned.estimate.pickup.address.zip_code}}
                                                        </p>
                                                        <p class="usr-email-addr">
                                                            <strong>To: </strong>{{jobAssigned.estimate.delivery.address.state}}
                                                            {{jobAssigned.estimate.delivery.address.zip_code}}
                                                        </p>
                                                        <p class="usr-email-addr">
                                                            <strong>Service: </strong> {{jobAssigned.estimate.service}}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="col-2 user-email">
                                                    <p class="usr-location">
                                                        <strong *ngIf="jobAssigned.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                                        <strong *ngIf="jobAssigned.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                                        {{jobAssigned.estimate.volume.cubic_feets | number}}
                                                    </p>
                                                    <p class="usr-location">
                                                        <strong>Miles: </strong>{{jobAssigned.estimate.volume.miles | number}}
                                                    </p>                            
                                                    <p class="usr-ph-no">
                                                        <strong>Estimate: </strong> {{jobAssigned.estimate.total | currency}}
                                                    </p>                            
                                                </div>  

                                                <div class="col-2 user-profile" style="width: 171px;">
                                                    <div class="user-meta-info">
                                                        <p *ngIf="jobAssigned.carrier">{{jobAssigned.carrier.name}}</p>
                                                        <p *ngIf="!jobAssigned.carrier">Carrier Unassigned</p>
                                                    </div>
                                                </div>     
                                                
                                                <div class="col-2 user-profile">
                                                    <div class="user-meta-info">
                                                        <p class="user-work">
                                                            <strong>Start Time: </strong>{{jobAssigned.box_delivery_day.range_start}}
                                                        </p>
                                                        <p class="user-work">
                                                            <strong>End Time: </strong>{{jobAssigned.box_delivery_day.range_end}}
                                                        </p>
                                                    </div>
                                                </div>       
                                                
                                                <div class="col-12 user-profile mt-2" *ngIf="jobAssigned.estimate.operation_details">
                                                    <p class="user-meta-info">
                                                        <strong>Direction / Operation Details: </strong>{{jobAssigned.estimate.operation_details}}
                                                    </p>
                                                </div>                                                                                                           
                                            </div> 
                                        </div>                                          
                                    </div>     
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>    
            </div>    
        </div>

        <div class="widget-content searchable-container list">            
            <div class="searchable-items list">
                    
            <!-- Jobs confirmados para ese día -->
            <ng-container *ngIf="dayCalendar['today_unassigned']" >

                <!-- Pickups -->
                <div class="items pickup-color" *ngFor="let estimate of dayCalendar['today_unassigned']['pickups']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.pickup_day.date_text | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        

                        <!-- <div class="user-location">
                            <p class="usr-ph-no">
                                {{estimate.pack_day.workers_id.length}} Workers
                            </p>
                        </div> -->

                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>

                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.pickup_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.pickup_day.range_end}}
                                </p>
                            </div>
                        </div>

                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                                 

                                <!-- <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #57a595; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Agent')">
                                    <i class="fas fa-dolly"></i>
                                </buttn> -->

                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>  -->                                           
                        </div>

                    </div>
                </div>

                <!-- Deliveries -->
                <div class="items delivery-color" *ngFor="let estimate of dayCalendar['today_unassigned']['deliveries']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                               <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.delivery_day.date_text | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.agent">{{estimate.agent.name}}</p>
                                <p *ngIf="!estimate.agent">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.delivery_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.delivery_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                             

                                <!-- <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #f58634; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Agent')">
                                    <i class="fas fa-user-tie"></i>
                                </button> -->

                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div> -->                                            
                        </div>

                    </div>
                </div>

                <!-- Boxes Delivery Day -->
                <div class="items boxDelivery-color" *ngFor="let estimate of dayCalendar['today_unassigned']['boxes_delivery_day']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                               <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.box_delivery_day.date_text | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.box_delivery_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.box_delivery_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id, estimate.estimate.pickup.boxes_delivery_day.date, estimate.estimate.pickup.boxes_delivery_day.dispatched, estimate.estimate.pickup.boxes_delivery_day.remarks, 'boxes_delivery_day', estimate.box_delivery_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                                
                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>   -->                                          
                        </div>

                    </div>
                </div>

                <!-- Packing Day -->
                <div class="items packDays-color" *ngFor="let estimate of dayCalendar['today_unassigned']['packing_day']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                               <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.pack_day.date_text | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.pack_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.pack_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                               
                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>   -->                                          
                        </div>

                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="widget-content searchable-container list">
        <div class="searchable-items list">

            <!-- ---- separator per day ---- -->
            <div class="col-12 widget-content searchable-container list d-flex justify-content-center" [hidden]="!hiddenCalendar">
                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content item-separator" style="position: initial!important;">
                            
                                <div class="d-flex align-items-center">
                                    <i class="fas fa-arrow-circle-up icon-confirmed-jobs"></i>
                                    <h4 class="confirmed-jobs">
                                        Jobs with a confirmed date for this day
                                    </h4>
                                </div>
                                <div class="d-flex align-items-center">
                                    <h4 class="related-jobs">
                                        Jobs with date related to this day
                                    </h4>
                                    <i class="fas fa-arrow-circle-down icon-related-jobs"></i>
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            <!-- ---- separator per day ---- -->

            <!-- Jobs Relacionados a ese día No Asignados -->
            <ng-container *ngIf="dayCalendar['related_unassigned']" >
                <div class="items pickup-color" *ngFor="let estimate of dayCalendar['related_unassigned']['pickups']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">
                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>
                        </div>
                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>

                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.pickup_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.pickup_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                                 

                                <!-- <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #57a595; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Agent')">
                                    <i class="fas fa-dolly"></i>
                                </button> -->

                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>    -->                                         
                        </div>

                    </div>
                </div>
                <div class="items delivery-color" *ngFor="let estimate of dayCalendar['related_unassigned']['deliveries']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                               <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">
                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>
                        </div>
                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.agent">{{estimate.agent.name}}</p>
                                <p *ngIf="!estimate.agent">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.delivery_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.delivery_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                             

                                <!-- <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #f58634; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Agent')">
                                    <i class="fas fa-user-tie"></i>
                                </button> -->

                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>  -->                                           
                        </div>

                    </div>
                </div>

                <div class="items boxDelivery-color" *ngFor="let estimate of dayCalendar['related_unassigned']['boxes_delivery_day']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                               <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.box_delivery_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.box_delivery_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id, estimate.estimate.pickup.boxes_delivery_day.date, estimate.estimate.pickup.boxes_delivery_day.dispatched, estimate.estimate.pickup.boxes_delivery_day.remarks, 'boxes_delivery_day', estimate.box_delivery_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                                
                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>  -->                                           
                        </div>

                    </div>
                </div>

                <div class="items packDays-color" *ngFor="let estimate of dayCalendar['related_unassigned']['packing_day']">
                    <div class="item-content" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info">
                               <p class="user-name cursor-pointer"  (click)="openNewTab(estimate.estimate.id)">{{estimate.estimate.document.code}}</p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 171px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-work">
                                    <strong>Start Time: </strong>{{estimate.pack_day.range_start}}
                                </p>
                                <p class="user-work">
                                    <strong>End Time: </strong>{{estimate.pack_day.range_end}}
                                </p>
                            </div>
                        </div>
                        <div style="width: 170px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                               
                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropdown">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>  -->                                           
                        </div>

                    </div>
                </div>

            </ng-container>

            <!-- ---- separator per date ---- -->
            <div class="col-12 widget-content searchable-container list d-flex justify-content-center" [hidden]="!hiddenCalendar">

                <div class="searchable-items list">
                    <div class="items items-header-section">
                        <div class="item-content item-separator justify-content-center" style="position: initial!important;">

                                <div class="d-flex align-items-center">
                                    <i class="fas fa-arrow-circle-down icon-related-jobs"></i>
                                    <h4 class="related-jobs">
                                        Jobs with date related to this day with a confirmed date
                                    </h4>
                                    <i class="fas fa-arrow-circle-down icon-related-jobs"></i>
                                </div>
                                
                        </div>
                    </div>
                </div>
            </div>
            <!-- ---- separator per date ---- -->

            <!-- Jobs Relacionados a ese día Asignados a otro día-->
            <ng-container *ngIf="dayCalendar['related_assigned']" >
                <div class="items pickup-color" [hidden]="!checkPickUps" *ngFor="let estimate of dayCalendar['related_assigned']['pickups']">
                    <div class="item-content" style="position: initial!important;" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style="width: 70px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appCompanyRouterLink="/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div style="width: 150px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>

                                <!-- <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #57a595; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pickup_day.date, estimate.pickup_day.dispatched, estimate.pickup_day.remarks, 'pickups' , estimate.pickup_day.confirm, 'Agent')">
                                    <i class="fas fa-dolly"></i>
                                </button> -->

                            </div>
                            
                        </div>                    

                        <div class="user-profile">
                            <!-- <div class="dropleft">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div> -->                                            
                        </div>

                    </div>
                </div>
                <div class="items delivery-color" [hidden]="!checkDelivery" *ngFor="let estimate of dayCalendar['related_assigned']['deliveries']">
                    <div class="item-content" style="position: initial!important;" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style="width: 70px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appCompanyRouterLink="/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>
                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.agent">{{estimate.agent.name}}</p>
                                <p *ngIf="!estimate.agent">Carrier Unassigned</p>
                            </div>
                        </div>
                        <div style="width: 150px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>

                                <!-- <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #f58634; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.delivery_day.date, estimate.delivery_day.dispatched, estimate.delivery_day.remarks,'deliveries', estimate.delivery_day.confirm, 'Agent')">
                                    <i class="fas fa-user-tie"></i>
                                </button> -->
                            </div>

                            
                        </div>                      

                        <div class="user-profile">
                            <!-- <div class="dropleft">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" *ngFor="let jobsAssigned of dayCalendar['today_assigned']" aria-labelledby="dropdownMenuLink-1">
                                    <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                </div>
                            </div>  -->                                           
                        </div>

                    </div>
                </div>

                <div class="items boxDelivery-color" [hidden]="!checkBoxesDelivery" *ngFor="let estimate of dayCalendar['related_assigned']['boxes_delivery_day']">
                    <div class="item-content" style="position: initial!important;" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style="width: 70px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appCompanyRouterLink="/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>

                        <div style="width: 150px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id, estimate.estimate.pickup.boxes_delivery_day.date, estimate.estimate.pickup.boxes_delivery_day.dispatched, estimate.estimate.pickup.boxes_delivery_day.remarks, 'boxes_delivery_day', estimate.box_delivery_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>

                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropleft">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>   -->                                          
                        </div>

                    </div>
                </div>

                <div class="items packDays-color" [hidden]="!checkPackDays" *ngFor="let estimate of dayCalendar['related_assigned']['packing_day']">
                    <div class="item-content" style="position: initial!important;" *ngIf="estimate.id">
                        <div class="user-profile">
                            <div class="user-meta-info" style="width: 70px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                <p class="user-name"><a appCompanyRouterLink="/moving/{{estimate.estimate.id}}/estimate" >{{estimate.estimate.document.code}}</a></p>
                                <p class="user-work">{{estimate.estimate.customer.name}}
                                    {{estimate.estimate.customer.last_name}}</p>
                                <p class="user-work">{{estimate.estimate.customer.phone}}</p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="user-profile mb-0">
                                    <strong>Move Date: </strong>{{estimate.estimate.move_date | dateTime}}
                                </p>
                                <p class="user-work">
                                    <strong>Start Date: </strong>{{estimate.estimate.pickup.range_start | date}}
                                </p>
                                <p class="user-work">
                                    <strong>End Date: </strong>{{estimate.estimate.pickup.range_end | date}}
                                </p>
                            </div>
                        </div>
                        <div class="user-profile">
                            <div class="user-meta-info">
                                <p class="usr-email-addr">
                                    <strong>From: </strong>{{estimate.estimate.pickup.address.state}}
                                    {{estimate.estimate.pickup.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>To: </strong>{{estimate.estimate.delivery.address.state}}
                                    {{estimate.estimate.delivery.address.zip_code}}
                                </p>
                                <p class="usr-email-addr">
                                    <strong>Service: </strong> {{estimate.estimate.service}}
                                </p>
                            </div>
                        </div>
                        <div class="user-email">

                            <p class="info-title">Volume: </p>
                            <p class="usr-location">
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                <strong *ngIf="estimate.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                {{estimate.estimate.volume.cubic_feets | number}}
                            </p>
                            <p class="usr-location">
                                <strong>Miles: </strong>{{estimate.estimate.volume.miles | number}}
                            </p>

                            <p class="usr-ph-no">
                                <strong>Estimate: </strong> {{estimate.estimate.total | currency}}
                            </p>

                        </div>

                        <div class="user-profile" style="width: 150px;">
                            <div class="user-meta-info">
                                <p *ngIf="estimate.carrier">{{estimate.carrier.name}}</p>
                                <p *ngIf="!estimate.carrier">Carrier Unassigned</p>
                            </div>
                        </div>

                        <div style="width: 150px;">
                            <div class="action-btn confirm-date">
                                <button type="button" id="add-list__" class="btn btn-outline-danger"
                                    style="height:37px; background: #d62d80; color: white!important; border: none!important;"
                                    (click)="setJob(estimate.id,  estimate.pack_day.date, estimate.pack_day.dispatched, estimate.pack_day.remarks, 'pack_day', estimate.pack_day.confirm, 'Appointments')">
                                    <i class="fas fa-calendar-check"></i>
                                </button>

                            </div>
                        </div>

                        <div class="user-profile">
                            <!-- <div class="dropleft">
                                <button class="btn btn-success dropdown-toggle" role="button" id="dropdownMenuLink-1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Assign
                                </button> 
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink-1">
                                    <ng-container  *ngFor="let jobsAssigned of dayCalendar['today_assigned']">
                                        <a class="dropdown-item list-edit" (click)="dailyCrewAssign(estimate.id, jobsAssigned)" href="javascript:void(0);">{{jobsAssigned.name}}</a>
                                    </ng-container>
                                </div>
                            </div>  -->                                           
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

    <!-- The Modal -->
    <div id="addEventsModal" class="modal animated fadeIn">

        <div class="modal-dialog modal-dialog-centered">

            <!-- Modal content -->
            <div class="modal-content">

                <div class="modal-body">

                    <span class="close">&times;</span>

                    <div class="add-edit-event-box">
                        <div class="add-edit-event-content">
                            <h5 class="add-event-title modal-title">Add Events</h5>
                            <h5 class="edit-event-title modal-title">Edit Events</h5>

                            <form class="">

                                <div class="row">

                                    <div class="col-md-12">
                                        <label for="start-date" class="">Event Title</label>
                                        <div class="d-flex event-title">
                                            <input id="write-e" type="text" placeholder="Enter Title"
                                                class="form-control" name="task">
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-6 col-12">
                                        <div class="form-group start-date">
                                            <label for="start-date" class="">From</label>
                                            <div class="d-flex">
                                                <input id="start-date" placeholder="Start Date" class="form-control"
                                                    type="text">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 col-12">
                                        <div class="form-group end-date">
                                            <label for="end-date" class="">To</label>
                                            <div class="d-flex">
                                                <input id="end-date" placeholder="End Date" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="start-date" class="">Event Description</label>
                                        <div class="d-flex event-description">
                                            <textarea id="taskdescription" placeholder="Enter Description" rows="3"
                                                class="form-control" name="taskdescription"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="event-badge">
                                            <p class="">Badge:</p>

                                            <div class="d-sm-flex d-block">
                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-primary">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-primary">
                                                        <span class="new-control-indicator"></span>Work
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-warning">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-warning">
                                                        <span class="new-control-indicator"></span>Travel
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-success">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-success">
                                                        <span class="new-control-indicator"></span>Personal
                                                    </label>
                                                </div>

                                                <div class="n-chk">
                                                    <label class="new-control new-radio radio-danger">
                                                        <input type="radio" class="new-control-input" name="marker"
                                                            value="bg-danger">
                                                        <span class="new-control-indicator"></span>Important
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <button id="discard" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
                    <button id="add-e" class="btn">Add Task</button>
                    <button id="edit-event" class="btn btn-save">Save</button>
                </div>

            </div>

        </div>

    </div>

</div>


<!-- Modal add edit extra-->
<div class="modal fade" #modalExtra tabindex="-1" role="dialog" aria-labelledby="extraTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Appointments'">
                    Appointments
                </h5>
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Workers'">
                    Workers
                </h5>
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Trucks'">
                    Trucks
                </h5>
                <h5 class="modal-title" id="newConceptTitle" *ngIf="typeModal == 'Agent'">
                    {{titleModalCarrier}}
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content" id="extraTitle">
                        <ng-container *ngIf="typeModal == 'Appointments'">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="inputState">Date &nbsp; </label>
                                    <app-date-picker [value]="currentDate" [enableTime]="true"
                                        [placeholder]="'Not Assigned'" (onChange)="changeDate($event)">
                                    </app-date-picker>
                                </div>
                            </div>
                            <div class="row" style="    margin-top: 12px;">
                                <div class="col-md-6">
                                    <label for="inputState">Time Arrive Start</label>
                                    <select name="rangeStart" id="" class="form-control"  [(ngModel)]="rangeStart">
                                        <option value="06:00">06:00</option>
                                        <option value="06:30">06:30</option>
                                        <option value="07:00">07:00</option>
                                        <option value="07:30">07:30</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:30">08:30</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:30">09:30</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:30">10:30</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <label for="inputState">Time Arrive End</label>
                                    <select name="rangeEnd" id="" class="form-control"  [(ngModel)]="rangeEnd">
                                        <option value="06:00">06:00</option>
                                        <option value="06:30">06:30</option>
                                        <option value="07:00">07:00</option>
                                        <option value="07:30">07:30</option>
                                        <option value="08:00">08:00</option>
                                        <option value="08:30">08:30</option>
                                        <option value="09:00">09:00</option>
                                        <option value="09:30">09:30</option>
                                        <option value="10:00">10:00</option>
                                        <option value="10:30">10:30</option>
                                    </select>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <br>
                                    <p class="usr-ph-no">
                                        <label for="inputState">Date confirmation</label> <input
                                            type="checkbox" [(ngModel)]="confirm">
                                    </p>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <label for="inputState">Remark</label>
                                    <div class="list-title">
                                        <textarea name="Remark" id="carrier_notes" class="form-control"
                                            [(ngModel)]="remark" placeholder="Remark" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <br>
                                    <p class="usr-ph-no">
                                        <label for="inputState"> Dispatched &nbsp; </label> <input type="checkbox"
                                            [(ngModel)]="dispatched">
                                    </p>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="typeModal == 'Workers'">
                            <div class="form-group mb-4">
                                <label class="control-label">Employees</label>
                                <div class="n-chk" *ngFor="let employee of employeesView">
                                    <label class="new-control new-checkbox checkbox-primary">
                                        <input type="checkbox" class="new-control-input"
                                            (change)="getEmployeesTeam(employee.id)" [checked]="employee.checked">
                                        <span class="new-control-indicator"></span>{{employee.name}}
                                    </label>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="typeModal == 'Trucks'">
                            <div class="form-group mb-4">
                                <label class="control-label">Truncks</label>
                                <div class="n-chk" *ngFor="let trunck of truncks">
                                    <label class="new-control new-checkbox checkbox-primary">
                                        <input type="checkbox" class="new-control-input"
                                            (change)="getTrucksTeam(trunck.id)" [checked]="typeService">
                                        <span class="new-control-indicator"></span>{{trunck.type}}
                                    </label>
                                </div>
                            </div>
                        </ng-container>


                        <ng-container *ngIf="typeModal == 'Agent'">
                            <div class="compose-content content-select">

                                <div class="col-xl-6 col-lg-8 col-md-8 overflow-content scroll-default">

                                    <table class="table table-bordered table-hover mb-4 size-table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Name
                                                </th>
                                                <th class="text-center">
                                                    Phone
                                                </th>
                                                <th class="text-center">
                                                    Email
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let carrier of carriers">
                                                <tr (click)="selectCarrier(carrier)">
                                                    <td>
                                                        {{carrier.name}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{carrier.phone}}
                                                    </td>
                                                    <td class="text-center">
                                                        {{carrier.email}}
                                                    </td>
                                                </tr>
                                            </ng-container>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button *ngIf="typeModal == 'Appointments' || typeModal == 'Workers' || typeModal == 'Trucks'"
                    class="btn btn-save" (click)="update()">
                    Save
                </button>
                <button *ngIf="typeModal == 'Agent' || typeModal == 'Carrier' " class="btn btn-save"
                    (click)="saveCarrierAndAgent()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade modalCarriers" #modalCarriers tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <form #entityFormItem="ngForm" ngNativeValidate>

                <div class="modal-body">
                    <div class="compose-box">

                        <div class="compose-content content-select">
                            <h5 class="add-task-title">Select {{titleModalCarrier}}</h5>

                            <div class="col-xl-6 col-lg-8 col-md-8 overflow-content scroll-default">

                                <table class="table table-bordered table-hover mb-4 size-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Name
                                            </th>
                                            <th class="text-center">
                                                Phone
                                            </th>
                                            <th class="text-center">
                                                Email
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let carrier of carriers">
                                            <tr (click)="selectCarrier(carrier)">
                                                <td>
                                                    {{carrier.name}}
                                                </td>
                                                <td class="text-center">
                                                    {{carrier.phone}}
                                                </td>
                                                <td class="text-center">
                                                    {{carrier.email}}
                                                </td>
                                            </tr>
                                        </ng-container>

                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-cancel" (click)="closeModalCarriers()">
                        Cancel
                    </button>
                    <button data-btnfn="addTask" class="btn btn-save add-tsk" (click)="saveCarrierAndAgent()" type="submit">
                        Save
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
