export class RouteRate {
    id: string;
    period_id: string;
    tariff_version_id: string;
    zone_out_id: string;
    zone_in_id: string;
    cf_discount: number;
    min_cf: number;
    packing_cost: number;
    packing_discount: number;
    created: number;
    updated: number;

     constructor(){
        this.id = null;
        this.tariff_version_id = null;
        this.period_id = null;
        this.zone_out_id = null;
        this.zone_in_id = null;
        this.cf_discount = null;
        this.min_cf = null;
        this.packing_cost = null;
        this.packing_discount = null;
        this.created = null;
        this.updated = null;
     }
}
