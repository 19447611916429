import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { LeadForwardingLink } from 'src/app/entities/workspace/lead-forwarding-link';
import { HelperService } from 'src/app/services/helper.service';
import { LeadForwardingLinksService } from 'src/app/services/workspaces/lead-forwarding-links.service';

@Component({
  selector: 'app-lead-forwarding-links-form',
  templateUrl: './lead-forwarding-links-form.component.html',
  styleUrls: ['./lead-forwarding-links-form.component.scss']
})
export class LeadForwardingLinksFormComponent implements OnInit {

  constantsMessages = ConstantsMessages;

  public dataForm: LeadForwardingLink;

  constructor(
    private leadForwardingLinksService: LeadForwardingLinksService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService
  ) { 
    
    this.dataForm = new LeadForwardingLink();

  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.currentRoute.params.subscribe(params => {
        if (typeof params.id !== 'undefined') {
            this.load(params.id);
        }
    });
  }

  /**
   * carga un nuevo vehiculo o carga un vehiculo padra ser editado
   * @param id
   */
  private load(id: string) {
      this.helperService.showLoadingMxpro360();
      this.leadForwardingLinksService
          .getById(id)
          .then((response) => {
              this.dataForm = response;
          })
          .catch((error) => {
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

  /**
   * guarda la informacion de un vehiculo o uno editado
   */
  save() {
      this.helperService.showLoadingMxpro360();
      this.leadForwardingLinksService
          .save(this.dataForm)
          .then(() => {
              this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
              this.helperService.goToWorkspaceRouterLink('/lead-forwarding-links');
          })
          .catch((error) => {
              this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
              console.error('error', error);
          })
          .finally(() => {
              this.helperService.hideLoadingMxpro360();
          });
  }

}
