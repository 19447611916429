<div class="layout-spacing layout-top-spacing">

    
    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                
                <h3>
                    <span *ngIf="dataForm.id === null">
                        New
                    </span>
                    <span *ngIf="dataForm.id !== null">
                        Update
                    </span>
                    Carrier
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a></li>
                        <li class="breadcrumb-item"><a appWorkspaceRouterLink="/carriers">Carriers</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">
                    <button type="submit" class="btn btn-main-action">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <!-- header -->

        <div class="row">
            <div class="col-lg-12">
                <div class="statbox widget box box-shadow">

                    <!-- <div class="widget-header">
                        <h4 *ngIf="dataForm.id === null">
                            New Carrier
                        </h4>
                        <h4 *ngIf="dataForm.id !== null">
                            Update Carrier
                        </h4>
                    </div> -->

                    <div class="widget-content widget-content-area">

                        <div class="row">
                            <div class="col-md-6">

                                <div class="form-group ">
                                    <label for="company_dot">Name</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg></span>
                                        </div>
                                        <input type="text" class="form-control" name="carrier_name"
                                            placeholder="Enter here the name" [(ngModel)]="dataForm.name">
                                    </div>
                                    <small id="nameCustomer" class="form-text text-muted mb-4">
                                        Enter the name of the carrier... keep in mind that the value entered here is the one that will be used for the documents related to this carrier (job acceptance, BOL...).
                                    </small>
                                </div>
                                
                                <div class="form-group">
                                    <label class="control-label">DBA</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg></span>
                                        </div>
                                        <input type="text" class="form-control" name="carrier_dba"
                                            placeholder="Enter here the DBA" [(ngModel)]="dataForm.dba">
                                    </div>

                                    <small id="nameCustomer" class="form-text text-muted mb-4">
                                        Enter the DBA
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">DOT</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-hash">
                                                    <line x1="4" y1="9" x2="20" y2="9"></line>
                                                    <line x1="4" y1="15" x2="20" y2="15"></line>
                                                    <line x1="10" y1="3" x2="8" y2="21"></line>
                                                    <line x1="16" y1="3" x2="14" y2="21"></line>
                                                </svg></span>
                                        </div>
                                        <input type="number" class="form-control" name="carrier_dot"
                                            placeholder="Enter here the dot" [(ngModel)]="dataForm.dot">
                                    </div>

                                    <small id="nameCustomer" class="form-text text-muted mb-4">
                                        Enter the DOT number of the carrier you wish to register
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">ICC MC</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-hash">
                                                    <line x1="4" y1="9" x2="20" y2="9"></line>
                                                    <line x1="4" y1="15" x2="20" y2="15"></line>
                                                    <line x1="10" y1="3" x2="8" y2="21"></line>
                                                    <line x1="16" y1="3" x2="14" y2="21"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" class="form-control" name="carrier_iccmc"
                                            placeholder="Enter here the iccmc" [(ngModel)]="dataForm.iccmc">
                                    </div>

                                    <small  class="form-text text-muted mb-4">
                                        Enter the ICC MC identifier assigned to the carrier
                                    </small>
                                </div>
                                                                
                                <div class="form-group ">
                                    <label class="control-label">Website</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-globe">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <line x1="2" y1="12" x2="22" y2="12"></line>
                                                    <path
                                                        d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                    </path>
                                                </svg></span>
                                        </div>
                                        <input type="text" class="form-control" name="carrier_website"
                                            placeholder="Enter here the website" [(ngModel)]="dataForm.website">
                                    </div>

                                    <small class="form-text text-muted mb-4">
                                        Enter here the link to access the carrier's website
                                    </small>
                                </div>

                                <div class="form-group ">
                                    <label class="control-label">Phone</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg _ngcontent-gtv-c199="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path _ngcontent-gtv-c199="" d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                            </span>
                                        </div>
                                        <input type="text" onlyNumbers minlength="10" maxlength="10" class="form-control" name="carrier_phone"
                                            placeholder="Enter here the phone" [(ngModel)]="dataForm.phone" required>
                                    </div>

                                    <small class="form-text text-muted mb-4">
                                        Enter here the link to access the carrier's phone
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Comments</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5_x">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-message-square">
                                                    <path
                                                        d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>

                                        <textarea class="form-control" name="dataForm.comment" id="dataForm.comment"
                                            [(ngModel)]="dataForm.comment"
                                            placeholder="Enter here the carrier comment"></textarea>

                                    </div>

                                    <small  class="form-text text-muted mb-4">
                                        If you have any important notes from the carrier or consider that we should be aware of something about this carrier... write it down here...
                                    </small>
                                </div>

                            </div>

                            <div class="col-md-6">
                                
                                <div class="form-group select-insurance-due-date">
                                    <label class="control-label">Insurance Due Date</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-calendar">
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg></span>
                                        </div>
                                        <app-date-picker [value]="" [enableTime]="false" [placeholder]="'Not Assigned'"
                                            (onChange)="expirationDate($event)">
                                        </app-date-picker>
                                    </div>

                                    <small  class="form-text text-muted mb-4">
                                        Enter the expiration date of the carrier's insurance
                                    </small>
                                </div>

                                <div class="form-group pt-3">
                                    <label class="control-label">Fax</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-printer">
                                                    <polyline points="6 9 6 2 18 2 18 9"></polyline>
                                                    <path
                                                        d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2">
                                                    </path>
                                                    <rect x="6" y="14" width="12" height="8"></rect>
                                                </svg></span>
                                        </div>
                                        <input type="text" class="form-control" name="carrier_fax"
                                            placeholder="Enter here the fax" [(ngModel)]="dataForm.fax">
                                    </div>

                                    <small class="form-text text-muted mb-4">
                                        Enter the fax number here    
                                    </small>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Federal Tax</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-hash">
                                                    <line x1="4" y1="9" x2="20" y2="9"></line>
                                                    <line x1="4" y1="15" x2="20" y2="15"></line>
                                                    <line x1="10" y1="3" x2="8" y2="21"></line>
                                                    <line x1="16" y1="3" x2="14" y2="21"></line>
                                                </svg></span>
                                        </div>
                                        <input type="text" class="form-control" name="carrier_ftx"
                                            placeholder="Enter here the federal tax"
                                            [(ngModel)]="dataForm.federal_tax_id">
                                    </div>

                                    <small  class="form-text text-muted mb-4">
                                        Enter the carrier's tax identification number here
                                    </small>
                                </div>
                                

                                <div class="form-group mb-0 pb-1">
                                    <label class="control-label">Email</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg _ngcontent-yfl-c199="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path _ngcontent-yfl-c199="" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline _ngcontent-yfl-c199="" points="22,6 12,13 2,6"></polyline></svg>
                                            </span>
                                        </div>
                                        <input type="email" class="form-control" name="carrier_email"
                                            placeholder="Enter here the email" [(ngModel)]="dataForm.email" required>
                                    </div>

                                    <small class="form-text text-muted mb-4">
                                        Enter here customer support carrier's email
                                    </small>
                                </div>
                                
                                <app-search-address [oldAddress]="dataForm.address"
                                    [(ngModel)]="dataForm.address.full_address" (onChange)="addressCarriers($event)"
                                    [ngModelOptions]="{standalone: true}">
                                </app-search-address>
                                                         
                            
                            </div>
                        </div>

                        <hr>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="active" name="active" [(ngModel)]="dataForm.active">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="active" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.active}">
                                            Active
                                        </label>
                                    </div>
                                    <small  class="form-text text-muted mb-4">
                                        If you consider that the carrier should remain inactive you can leave this check box unchecked.
                                    </small>
                                </div>
                            </div>

                            <ng-container *ngIf="dataForm.active">

                                <div class="col-md-3">
                                    <!-- <div class="form-group">
                                        <label class="control-label">Vip</label>
                                        <input type="checkbox" name="carrier_vip" class="margin-checkbox"
                                            [(ngModel)]="dataForm.vip">
                                        <small  class="form-text text-muted mb-4">
                                            If you consider that this carrier should receive VIP offers, please indicate in the following check field.
                                        </small>
                                    </div> -->

                                    <div class="form-group">
                                        <div class="toggle-switch">                
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="vip" name="vip" [(ngModel)]="dataForm.vip">
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="vip" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.vip}">
                                                Vip
                                            </label>
                                        </div>
                                        <small  class="form-text text-muted mb-4">
                                            If you consider that this carrier should receive VIP offers, please indicate in the following check field.
                                        </small>
                                    </div>

                                </div>
                                
                                <div class="col-md-3">
                                    <!-- <div class="form-group">
                                        <label class="control-label">Broker</label>
                                        <input type="checkbox" name="carrier_broker" class="margin-checkbox"
                                            [(ngModel)]="dataForm.broker">
                                        <small  class="form-text text-muted mb-4">
                                            If the carrier also acts as a broker, please indicate this in this check box 
                                        </small>
                                    </div> -->

                                    <div class="form-group">
                                        <div class="toggle-switch">                
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="broker" name="broker" [(ngModel)]="dataForm.broker">
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="broker" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.broker}">
                                                Broker
                                            </label>
                                        </div>
                                        <small  class="form-text text-muted mb-4">
                                            If the carrier also acts as a broker, please indicate this in this check box.
                                        </small>
                                    </div>

                                </div>
    
                                <!-- 
                                    SE COMENTA POR QUE FALTA REVISAR MINUCIOASAMENTE TODA ESA PARTE DE FUNCIONALIDAD
                                    RELACIONADA CON LA CONTRAPARTE EN CARRIERS -->
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <div class="toggle-switch">                
                                                <label class="switch s-icons s-outline s-outline-secondary">
                                                    <input type="checkbox" id="has_moverxpro360" name="has_moverxpro360" [(ngModel)]="dataForm.has_moverxpro360">
                                                    <span class="slider round">
                                                        <i class="fa-solid fa-check icon-left"></i>
                                                        <i class="fa-solid fa-xmark icon-right"></i>
                                                    </span>
                                                </label>
                                                <label for="has_moverxpro360" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.has_moverxpro360}">
                                                    Has Moverxpro360
                                                </label>
                                            </div>
                                            <small  class="form-text text-muted mb-4">
                                                if you know that the carrier also uses moverxpro, please indicate it here to enable the integration with them, so we can automate outsourcing processes of jobs. 
                                            </small>
                                        </div>
                                    </div> 
                            </ng-container>



                        </div>

                        <hr>

                        <div class="row">
                            <div class="col-md-3" *ngIf="dataForm.active">
                                <div class="form-group">
                                    <label class="control-label">Pin</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-hash">
                                                    <line x1="4" y1="9" x2="20" y2="9"></line>
                                                    <line x1="4" y1="15" x2="20" y2="15"></line>
                                                    <line x1="10" y1="3" x2="8" y2="21"></line>
                                                    <line x1="16" y1="3" x2="14" y2="21"></line>
                                                </svg></span>
                                        </div>
                                        <input type="number" class="form-control" name="pin" placeholder="Pin"
                                            [(ngModel)]="dataForm.pin">
                                        
                                        <small  class="form-text text-muted mb-4">
                                            The pin is like a password so that the carrier can enter the outsourced work area, this way they can enter and record progress, updates, sign documents and so on. 
                                        </small>
                                    </div>

                                </div>
                            </div>

                            <ng-container *ngIf="dataForm.has_moverxpro360">
                                <div class="col-md-3">
                                    <div class="form-group ">
                                        <label class="control-label">Workspace Carrier Id</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-calendar">
                                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                                    </svg></span>
                                            </div>
                                            <input type="text" class="form-control" name="workspace_carrier_id"
                                                placeholder="Enter Workspace Carrier Id"
                                                [(ngModel)]="dataForm.workspace_carrier_id">
                                            <small  class="form-text text-muted mb-4">
                                                The ID of my company as carrier in the carrier workspace.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
    
                                    <div class="form-group ">
                                        <label class="control-label">Carrier Workspace Id</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-calendar">
                                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                                    </svg></span>
                                            </div>
                                            <input type="text" class="form-control" name="carrier_workspace"
                                                placeholder="Enter Workspace Id" [(ngModel)]="dataForm.workspace_id">
                                        </div>
                                    </div>
    
    
                                </div>

                                <div class="col-md-3">
    
                                    <div class="form-group">
                                        <label class="control-label">Carrier Workspace Company Id </label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon5"><svg
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-hash">
                                                        <line x1="4" y1="9" x2="20" y2="9"></line>
                                                        <line x1="4" y1="15" x2="20" y2="15"></line>
                                                        <line x1="10" y1="3" x2="8" y2="21"></line>
                                                        <line x1="16" y1="3" x2="14" y2="21"></line>
                                                    </svg></span>
                                            </div>
                                            <input type="text" class="form-control" name="workspace_company_id"
                                                placeholder="Enter Workspace Company Id"
                                                [(ngModel)]="dataForm.workspace_company_id">

                                            <small  class="form-text text-muted mb-4">
                                                The company who will recive the estimates.
                                            </small>
                                        </div>
    
                                    </div>
    
                                </div>
                            </ng-container>
                            
                        </div>
                            
                    </div>
                </div>
            </div>
        </div>

        
    </form>
</div>