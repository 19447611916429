import { Address } from '../global/address';

export class Carrier {

    public id: string;
    public logo: string;
    public name: string;
    public company: string;
    public address: Address;
    public phone: string;
    public email: string;
    public fax: string;
    public website: string;
    public dot: number;
    public dba: string;
    public iccmc: string;
    public insurance_due_date: number;
    public active: boolean;
    public federal_tax_id: string;
    public pin: number;
    public vip: boolean;
    public comment: string;
    public broker: boolean;
    public has_moverxpro360: boolean;
    public workspace_id: string;
    public workspace_company_id: string;
    public workspace_carrier_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.logo = null;
        this.name = null;
        this.address = new Address();
        this.fax = null;
        this.website = null;
        this.dot = null;
        this.dba = null;
        this.iccmc = null;
        this.insurance_due_date = null;
        this.active = true;
        this.federal_tax_id = null;
        this.company = null;
        this.email = null;
        this.pin = null;
        this.vip = false;
        this.comment = null;
        this.phone = null;
        this.broker = false;
        this.has_moverxpro360 = false;
        this.workspace_id = null;
        this.workspace_company_id = null;
        this.workspace_carrier_id = null;
        this.created = null;
        this.updated = null;
    }
}
