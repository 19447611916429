<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing">
            <h3>
                Tickets
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Tickets
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>
    <!-- header -->


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">  

        <div class="row col-lg-12 col-md-12 block-filter">

            <div class="col-lg-4 col-xl-3 pl-0">
                <label class="control-label">Type</label>
                <select name="type_ticket_id" [(ngModel)]="typeTicket" id="type_ticket_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let ticket of typeTickets" value="{{ticket.id}}">
                        {{ticket.name | titlecase}}
                    </option>
                </select>
            </div>

            <div class="col-lg-4 col-xl-3 pl-0">
                <label class="control-label">Status</label>
                <select name="status_id" [(ngModel)]="statusTicket" id="status_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let status of statusTickets" value="{{status}}">
                        {{status | titlecase}}
                    </option>
                </select>
            </div>

            <div class="col-lg-4 col-xl-3 pl-0">
                <label class="control-label">Priority</label>
                <select name="prority_id" [(ngModel)]="priorityTicket" id="prority_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let priority of priorityTickets" value="{{priority}}">
                        {{priority | titlecase}}
                    </option>
                </select>
            </div>
            
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Work Departments</label>
                <select name="work_department_id" (ngModelChange)="setWorkDepartment($event)" [(ngModel)]="workDepartmentId" id="work_department_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let workdepartment of workDepartments" value="{{workdepartment.id}}">
                        {{workdepartment.name | titlecase}}
                    </option>
                </select>
            </div>             

            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Employee</label>
                <select name="employee_id" [(ngModel)]="employeeId" id="employee_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let employee of employeesToShow" value="{{employee.id}}">
                        {{employee.name | titlecase}}
                    </option>
                </select>
            </div>

            <div class="col-lg-4 col-xl-1 pl-0">
                <button class="btn btn-warning btn-search" (click)="getFilter()"> Search </button>
            </div>

        </div>     
        
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Priority Level</th>
                                <th>Ticket #</th>
                                <!-- <th>Estimate #</th> -->
                                <th>Customer</th>
                                <th>Subject</th>
                                <th>Work Department</th>
                                <th class="text-center">Status</th>
                                <th>Created By</th>
                                <th>Last Activity</th>
                                <!-- <th>created</th> -->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ticket of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td>                                
                                    <div class="priority-level-urgent" *ngIf="ticket.priority === 'URGENT'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>
                                    <div class="priority-level-high" *ngIf="ticket.priority === 'HIGH'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>
                                    <div class="priority-level-mid" *ngIf="ticket.priority === 'MID'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>
                                    <div class="priority-level-low" *ngIf="ticket.priority === 'LOW'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>                                
                                </td>

                                <td>{{ticket.document.code}}</td>
                                <!-- <td>{{ticket.estimate_document.code}}</td> -->
                                <td>{{ticket.customer?.name | titlecase}} {{ticket.customer?.last_name | titlecase}}</td>
                                <td>
                                    <ng-container *ngFor="let typeTicket of typeTickets">
                                        <ng-container *ngIf="typeTicket.id == ticket.ticket_type_id">
                                            {{typeTicket.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{ticket.work_department?.name}}</td>
                                <td class="text-center">
                                    <span *ngIf="ticket.status === 'OPEN'">
                                        <span class="badge badge-info">
                                            {{ticket.status}}
                                        </span>
                                    </span>
                                    <span *ngIf="ticket.status === 'PENDING'">
                                        <span name="pending" class="badge badge-danger">
                                            {{ticket.status}}
                                        </span>
                                    </span>
                                    <span *ngIf="ticket.status === 'RESOLVED'">
                                        <span name="resolved" class="badge badge-success">
                                            {{ticket.status}}
                                        </span>
                                    </span>
                                    <span *ngIf="ticket.status === 'WAITING_COSTUMER'">
                                        <span name="waiting_customer" class="badge badge-warning">
                                            {{ticket.status}}
                                        </span>
                                    </span>
                                    <span *ngIf="ticket.status === 'CLOSED'">
                                        <span name="closed" class="badge badge-secondary">
                                            {{ticket.status}}
                                        </span>
                                    </span>
                                </td>
                              
                                <td  *ngIf="ticket.created_by_employee_id">
                                    <ng-container *ngFor="let employe of employees">
                                        <ng-container *ngIf="employe.id == ticket.created_by_employee_id">
                                            {{employe.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td  *ngIf="!ticket.created_by_employee_id">
                                    Customer
                                </td>
                                <td>{{ticket.updated | dateTime}}</td>
                                <!-- <td>{{ticket.created | dateTime}}</td> -->
                                <td>
                                    <button class="btn btn-info mb-2" appCompanyRouterLink="/tickets/{{ticket.id}}">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>