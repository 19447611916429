import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { SalesmanPerformView } from 'src/app/entities/workspace/salesman-perform-view';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { SalesmanPerformsService } from 'src/app/services/companies/salesman-performs.service';
import { LeadsService } from 'src/app/services/leads.service';
import { LeadView } from 'src/app/entities/workspace/lead-view';

declare const jQuery: any;
declare const moment: any;

@Component({
  selector: 'app-my-performs',
  templateUrl: './my-performs.component.html',
  styleUrls: ['./my-performs.component.scss']
})
export class MyPerformsComponent implements OnInit {

  @ViewChild('modal') modal: ElementRef;
  @ViewChild('modalLeads') modalLeads: ElementRef;
  
  public betweenDates: BetweenDates;
  public salesmanPerforms: SalesmanPerformView[];
  public leads: LeadView[];
  public salesmanPerformsCustom: any[];
  public titleModal: string;
  public totalAmountQuotesBooked: number;
  public totalCustomerPayments: number;
  public totalEmailsSent: number;
  public totalLeads: number;
  public totalQuotes: number;
  public totalQuotesBooked: number;
  public totalQuotesSent: number;
  public typeService: string;

  constructor( 
    private helperService: HelperService,
    private leadsService: LeadsService,
    private salesmanPerformsService: SalesmanPerformsService,
    public authService: AuthService
  ) {
    this.betweenDates = new BetweenDates();
    this.leads = [];
    this.salesmanPerforms = [];
    this.salesmanPerformsCustom = [];
    this.titleModal = '';
    this.typeService = 'MOVING';
    this.clearVar();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
    this.search();
  }

  clearVar() {
    this.totalAmountQuotesBooked = 0;
    this.totalCustomerPayments = 0;
    this.totalEmailsSent = 0;
    this.totalLeads = 0;
    this.totalQuotes = 0;
    this.totalQuotesBooked = 0;
    this.totalQuotesSent = 0;
  }

  getFilterDates(date: BetweenDates): void {
    this.betweenDates = date;
  }

  /**
   * Busca los datos por el filtro de fecha
   */
  search(): void {
    if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
      this.betweenDates.dateStart = 1609459200;
      this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
    }

    // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
    this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
    this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

    const data: any = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      type_service: this.typeService,
      employee_id: this.authService.workspaceSession.employee.id,
    };

    this.helperService.showLoadingMxpro360();
    this.salesmanPerformsService
      .getSalesPerforms(data)
      .then((response) => {
        this.clearVar();
        this.salesmanPerforms = response;

        for (const totals of this.salesmanPerforms) {
          this.totalQuotes = this.totalQuotes + Number(totals.total_quotes);
          this.totalLeads = this.totalLeads + Number(totals.total_leads);
          this.totalEmailsSent = this.totalEmailsSent + Number(totals.emails_sent);
          this.totalQuotesSent = this.totalQuotesSent + Number(totals.quotes_sent);
          this.totalQuotesBooked = this.totalQuotesBooked + Number(totals.quotes_booked);
          this.totalAmountQuotesBooked = this.totalAmountQuotesBooked + Number(totals.amount_quotes_booked);
          this.totalCustomerPayments = this.totalCustomerPayments + Number(totals.customer_payments);
        }

      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  filterSearch(type: string): void {

    switch (type) {
      case 'TOTAL_QUOTES':
        this.titleModal = 'TOTAL QUOTES';
        break;
      case 'EMAILS_SENT':
        this.titleModal = 'EMAILS SENT';
        break;
      case 'QUOTES_SENT':
        this.titleModal = 'QUOTES SENT';
        break;
      case 'QUOTES_BOOKED':
        this.titleModal = 'QUOTES BOOKED';
        break;
      case 'CUSTOMER_PAYMENTS':
        this.titleModal = 'CUSTOMER PAYMENTS';
        break;
      case 'search':
        break;
    }

    const data: any = {
      start: this.betweenDates.dateStart,
      end: this.betweenDates.dateEnd,
      type_service: this.typeService
    };

    this.helperService.showLoadingMxpro360();
    this.salesmanPerformsService
      .getSalesPerformsCustom(data, type, this.salesmanPerforms[0].id)
      .then((response) => {

        this.salesmanPerformsCustom = response;
        jQuery(this.modal.nativeElement).modal('show');
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  getLeads() {
    const data = {
      date_end: this.betweenDates.dateEnd,
      date_start: this.betweenDates.dateStart,
      date_type: 'OPEN'
  };
  this.helperService.showLoadingMxpro360();
  this.leadsService
      .getMyLeads(data, this.salesmanPerforms[0].id)
      .then((response: any) => {
        console.log(response);
        this.leads = response;
      })
      .catch((error) => {
          console.error('Error: ', error);
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
          jQuery(this.modalLeads.nativeElement).modal('show');
      });
  }

  openNewTab(estimateId) {
    // Redirige a la ventana de estmate
    this.helperService.openNewTab(estimateId, 'estimate');
  }
}
