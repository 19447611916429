import { Component, OnInit } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { LocalTariffSetting } from 'src/app/entities/admin/local-tariff-setting';
import { WorkspaceSetting } from 'src/app/entities/admin/workspace-setting';
import { HelperService } from 'src/app/services/helper.service';
import { LocalTariffSettingsService } from 'src/app/services/workspaces/local-tariff-settings.service';
import { SettingsService } from 'src/app/services/workspaces/settings.service';
declare const jQuery;
@Component({
  selector: 'app-local-tariff-settings',
  templateUrl: './local-tariff-settings.component.html',
  styleUrls: ['./local-tariff-settings.component.scss']
})
export class LocalTariffSettingsComponent implements OnInit {

  private constantsMessages = ConstantsMessages;
  public localTariffs: LocalTariffSetting[];
  public workspaceSettings: WorkspaceSetting;
  constructor(
    private localTariffSettingsService: LocalTariffSettingsService,
    private settingsService: SettingsService,
    private helperService: HelperService
  ) {
    this.localTariffs = [];
    this.workspaceSettings = new WorkspaceSetting();
  }

  actualPage = 1;
  filterSearch = '';

  ngOnInit(): void {
    this.load();
    this.loadWorkspaceSetting();
  }

  load() {
    this.helperService.showLoadingMxpro360();
    this.localTariffSettingsService.getAll()
    .then((result) => {
      // muestra mensaje de exito y redirige al listado
      this.localTariffs = result;
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  loadWorkspaceSetting() {
    this.settingsService
    .get()
    .then((responseSettings) => {
        this.workspaceSettings = responseSettings;
    });
  }

  toggleStatus(localTariffId: string) {
    this.helperService.showLoadingMxpro360();
    this.localTariffSettingsService.toggleStatus(localTariffId)
    .then(() => {
      // muestra mensaje de exito y redirige al listado
      this.load();
    })
    .finally(() => {
      this.helperService.hideLoadingMxpro360();
    });
  }

  saveExtra(){
    this.helperService.showLoadingMxpro360();
    this.settingsService.save(this.workspaceSettings)
    .then(() => {

    })
    .catch((error) => {
      this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      console.error('error', error);
  })
  .finally(() => {
      this.helperService.hideLoadingMxpro360();
  });
  }
}

// http://localhost:5200/#/workspace/6046a8ece9fb7d521b57b172/local-tariff-settings