<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <h3>
                Tickets
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Tickets
                    </li>
                </ol>
            </nav>
        </div>    
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div> 
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center top-actions-btns">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
                <a (click)="openModalNewTicket()" class="btn btn-new">
                    <i class="fas fa-plus-square"></i>
                    New Ticket
                </a>
            </div>
        </div>
    </div>


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 align-self-center filter-section collapse show" id="collapseFilter">

        <div class="row col-lg-12 col-md-12 block-filter">
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Type</label>
                <select name="type_ticket_id" [(ngModel)]="typeTicket" id="type_ticket_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let ticket of typeTickets" value="{{ticket.id}}">
                        {{ticket.name | titlecase}}
                    </option>
                </select>
            </div>  
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Status</label>
                <select name="status_id" [(ngModel)]="statusTicket" id="status_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let status of statusTickets" value="{{status}}">
                        {{status | titlecase}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Priority</label>
                <select name="prority_id" [(ngModel)]="priorityTicket" id="prority_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let priority of priorityTickets" value="{{priority}}">
                        {{priority | titlecase}}
                    </option>
                </select>
            </div>                
        </div>

        <div class="row col-lg-12 col-md-12 block-filter mb-3">
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Companies</label>
                <select name="companyId" [(ngModel)]="companyId" (ngModelChange)="filterCompanyEmployees()" id="companyId" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let company of companies" value="{{company.id}}">
                        {{company.name | titlecase}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Employee</label>
                <select name="employee_id" [(ngModel)]="employeeId" id="employee_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let employee of employeesCompany" value="{{employee.id}}">
                        {{employee.name | titlecase}}
                    </option>
                </select>
            </div>            
            <div class="col-lg-4 col-xl-3 pl-0">
                <button class=" btn btn-secondary btn-search" (click)="getFilter()">
                    Search
                </button>
            </div>
        </div>

    </div>

    <!-- Table -->
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Priority Level</th>
                                <th>Ticket #</th>
                                <!-- <th>Estimate #</th> -->
                                <th>Customer</th>
                                <th>Subject</th>
                                <th>Work Department</th>
                                <th>Employee Assigned</th>
                                <th>Status</th>
                                <th>Last Activity</th>
                                <!-- <th>created</th> -->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ticket of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td class="text-center">
                                    <div class="priority-level-urgent" *ngIf="ticket.priority === 'URGENT'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>
                                    <div class="priority-level-high" *ngIf="ticket.priority === 'HIGH'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>
                                    <div class="priority-level-mid" *ngIf="ticket.priority === 'MID'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>
                                    <div class="priority-level-low" *ngIf="ticket.priority === 'LOW'">
                                        <i class="fa-solid fa-circle-exclamation"></i>
                                        <span>{{ticket.priority}}</span>
                                    </div>       
                                </td>

                                <td>{{ticket.document.code}}</td>
                                <!-- <td>{{ticket.estimate_document.code}}</td> -->
                                <td> <span *ngIf="ticket.customer">{{ticket.customer.name}}
                                        {{ticket.customer.last_name}}</span></td>
                                <td>
                                    <ng-container *ngFor="let typeTicket of typeTickets">
                                        <ng-container *ngIf="typeTicket.id == ticket.ticket_type_id">
                                            {{typeTicket.name}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{ticket.work_department?.name}}</td>
                                <td>{{ticket.employee_assigned?.name}}</td>
                                <td class="text-center">
                                    <ng-container *ngIf="ticket.status === 'OPEN'">
                                        <span class="badge badge-info">
                                            {{ticket.status}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="ticket.status === 'PENDING'">
                                        <span name="pending" class="badge badge-danger">
                                            {{ticket.status}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="ticket.status === 'RESOLVED'">
                                        <span name="resolved" class="badge badge-success">
                                            {{ticket.status}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="ticket.status === 'WAITING_COSTUMER'">
                                        <span name="waiting_customer" class="badge badge-warning">
                                            {{ticket.status}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="ticket.status === 'CLOSED'">
                                        <span name="closed" class="badge badge-secondary">
                                            {{ticket.status}}
                                        </span>
                                    </ng-container>
                                </td>
                                <td>{{ticket.updated | dateTime}}</td>
                                <!-- <td>{{ticket.created | dateTime}}</td> -->
                                <td class="d-flex">
                                    <a appWorkspaceRouterLink="/companyspace/{{ticket.company_id}}/tickets/{{ticket.id}}" class="btn btn-info">
                                        <i class="fa-solid fa-eye"></i>
                                    </a>
                                    <button (click)="openModalAssignEmployee(ticket)" class="btn btn-secondary">
                                        <i class="fa-solid fa-user-tag"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid"
                        (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

    <div class="modal fade" id="ticketModal" #ticketModal tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modalAddBalance" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title ml-3" id="newConceptTitle">
                        Add Ticket
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">
                            <form class="form" #entityForm="ngForm" (ngSubmit)="saveTicket()" ngNativeValidate>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-4 ">
                                            <label class="control-label ">Company</label>
                                            <select name="status" class="form-control" [(ngModel)]="newTicket.company_id">
                                                <option *ngFor="let company of companies" value="{{company.id}}">
                                                    {{company.name | titlecase}}
                                                </option>
                                            </select>
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Select a Company</small>
                                        </div>
                                        <div class="form-group mb-4 ">
                                            <label class="control-label ">Job Number</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-briefcase">
                                                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text" [(ngModel)]="ticketJobNumber" name="jobNumber" class="form-control">
                                            </div>      
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Write Job Number</small>                                      
                                        </div>
                                        <div class="form-group mb-1">
                                            <label class="control-label ">Subject</label>
                                            <div class="input-group mb-1">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon5">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                            viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-user">
                                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="12" cy="7" r="4"></circle>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text" [(ngModel)]="newTicket.subject" name="subject" class="form-control">
                                            </div>
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Write Subject Ticket</small>
                                        </div>
                                        <div class="form-group mb-4 ">
                                            <label class="control-label">Type</label>
                                            <select name="type" class="form-control" [(ngModel)]="newTicket.type">
                                                <option value="PICKUP_DISPUTE">
                                                    Pickup Dispute
                                                </option>
                                                <option value="DELIVERY_QUESTION">
                                                    Delivery Question
                                                </option>
                                                <option value="PICKUP_QUESTION">
                                                    Pickup Question
                                                </option>
                                                <option value="DAMAGE_CLAIMS">
                                                    Damage Claims
                                                </option>
                                                <option value="CANCELLATION_REQUEST">
                                                    Cancellation Request
                                                </option>
                                                <option value="ESTIMATE_CHANGE">
                                                    Estimate Change
                                                </option>
                                                <option value="DATE_CHANGE_REQUEST">
                                                    Date Change Request
                                                </option>
                                                <option value="PAYMENT_QUESTION">
                                                    Payment Question
                                                </option>
                                            </select>
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Type of Ticket</small>
                                        </div>

                                        <div class="form-group mb-4">
                                            <label class="control-label ">Status</label>
                                            <select name="status" class="form-control" [(ngModel)]="newTicket.status">
                                                <option value="OPEN">
                                                    Open
                                                </option>
                                                <option value="PENDING">
                                                    Pending
                                                </option>
                                                <option value="RESOLVED">
                                                    Resolved
                                                </option>
                                                <option value="CLOSED">
                                                    Closed
                                                </option>
                                                <option value="WAITING_COSTUMER">
                                                    Waiting Costumer
                                                </option>
                                            </select>
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Status of Ticket</small>
                                        </div>
                                        <div class="form-group mb-6 ">
                                            <label class="control-label ">Priority</label>
                                            <select name="priority" class="form-control" [(ngModel)]="newTicket.priority">
                                                <option value="LOW">
                                                    Low
                                                </option>
                                                <option value="MID">
                                                    Mid
                                                </option>
                                                <option value="HIGH">
                                                    High
                                                </option>
                                                <option value="URGENT">
                                                    Urgent
                                                </option>
                                            </select>
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Select Priority of Ticket</small>
                                        </div>
                                        <div class="form-group mb-4">
                                            <label class="control-label">Description</label>
                                            <textarea name="description_ticket" id="description_ticket" [(ngModel)]="newTicket.description" class="form-control"></textarea>
                                            <small id="emailHelp1" class="form-text text-muted mb-4">Write Description Ticket</small>
                                        </div>
                                    </div>
                                </div>
                                <!--  <p>
                                            upload a file:
                                            <input type="file">
                                        </p> -->
                                <div class="text-center ">
                                    <button class="btn btn-save ml-1 mt-3" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="assingEmployeeModal" #assingEmployeeModal tabindex="-1" role="dialog"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modalAddBalance" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title ml-3" id="newConceptTitle">
                        Assign the ticket <strong class="text-warning">{{ticket.document.code}}</strong> to an employee
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group mb-4">
                                        <label class="control-label ">Work Departments</label>
                                        <select name="work_department_id" (ngModelChange)="setWorkDepartment()" [(ngModel)]="ticket.work_department_id" id="work_department_id" class="form-control">
                                            <option value="" selected="selected">All</option>
                                            <option *ngFor="let workDepartmentRow of workDeparments" [value]="workDepartmentRow.id">
                                                {{workDepartmentRow.name | titlecase}}
                                            </option>
                                        </select>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Select a Work Departments</small>
                                    </div>
                                    <div class="form-group mb-4 ">
                                        <label class="control-label ">Employee</label>
                                        <select name="status" class="form-control" [(ngModel)]="ticket.assigned_to_employee">
                                            <ng-container *ngFor="let employee of employeesToShow">
                                                <option [value]="employee.id">
                                                    {{employee.name | titlecase}} {{employee.job_position | lowercase}}
                                                </option>
                                            </ng-container>
                                        </select>
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Select a Employee</small>
                                    </div>
                                    <div class="text-center ">
                                        <button class="btn btn-save ml-1 mt-3" type="button" (click)="saveEmployeeTicket()">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>