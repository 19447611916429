<div class="layout-spacing layout-top-spacing">
    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center layout-spacing">
            <h3>
                Phone Queues
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Phone Queues</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center layout-spacing">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/phone-queues/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; New Phone Queue
                </a>
            </div>
        </div>

    </div>

    <!-- Help Bar -->
    <!-- <div class="col-lg-12 col-md-12 label-section">
        <div class="label label-phone-number-deleted">
            <div class="box"></div>
            <div>Assignment with phone number deleted</div>
        </div>
    </div> -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                 <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>name</th>
                                <th class="pl-4">Description</th>
                                <th>Company</th>
                                <th>Phone Extensions</th>
                                <th>Play Music On Hold</th>
                                <th class="text-center">Ring Duration</th>
                                <th>Destination</th>
                                <th>Created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;" [ngClass]="{'phone-number-deleted': !row.number}">
                                <td>{{i+1}} </td>
                                <td>{{row.name}}</td>
                                <td class="position-relative pl-4">
                                    {{row.description}}
                                </td>
                                <td>{{row.company_id}}</td>
                                <td>
                                    <ng-container *ngFor="let phone of row.phone_extensions_id">
                                        {{phone}} <br>
                                    </ng-container>
                                </td>
                                <td><audio src="{{environment.az_api.cdn}}/{{workspaceId}}/dialer/{{row.play_music_on_hold.display_name}}" controls style="width: 200px;"></audio></td>
                                <td>{{row.ring_duration}}</td>
                                <td>{{row.destination.target}}</td>
                                <td>{{row.created | date}}</td>
                                <td>
                                    <a appWorkspaceRouterLink ="/phone-queues/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button  class="btn btn-danger button-space" (click)="remove(row.id)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
