import { ConstantsMessages } from 'src/app/constants-messages';
import { Vehicle } from 'src/app/entities/workspace/vehicle';
import { VehiclesService } from 'src/app/services/workspaces/vehicles.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const moment;

@Component({
    selector: 'app-vehicles-form',
    templateUrl: './vehicles-form.component.html',
    styleUrls: ['./vehicles-form.component.scss']
})

export class VehiclesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    public dataForm: Vehicle;

    constructor(
        private vehiclesService: VehiclesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.dataForm = new Vehicle();
    }

    ngOnInit(): void {

    }


    ngAfterViewInit() {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    /**
     * carga un nuevo vehiculo o carga un vehiculo padra ser editado
     * @param id
     */
    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.vehiclesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * guarda la informacion de un vehiculo o uno editado
     */
    save() {
        this.helperService.showLoadingMxpro360();
        this.vehiclesService
            .save(this.dataForm)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/vehicles');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
    /**
     * setea la fecha del datapicker en fecha de compra del vehiculo
     * @param date 
     */
    changeDate(date) {
        this.dataForm.year = date;
    }

}
