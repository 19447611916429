import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarriersContactsService {

    constructor(private http: HttpClient) { }

    getAll(id): Promise<Array<CarrierContact>> {
        return this.http.get<Array<CarrierContact>>(environment.api.workspace + '/carriers/' + id + '/contacts').toPromise();
    }

    getById(id: string, subId: string) {
        return this.http.get<CarrierContact>(environment.api.workspace + '/carriers/' + id + '/contacts/' + subId).toPromise();
    }

    save(dataForm: CarrierContact, id: string, subId: string): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm, id);
        }
        return this.edit(dataForm, id, subId);
    }

    add(dataForm: CarrierContact, id: string) {
        return this.http.post(environment.api.workspace + '/carriers/' + id + '/contacts', dataForm).toPromise();
    }

    edit(dataForm: CarrierContact, id: string, subId: string) {
        return this.http.put(environment.api.workspace + '/carriers/' + id + '/contacts/' + subId, dataForm).toPromise();
    }

    remove(id: string, subId: string) {
        return this.http.delete(environment.api.workspace + '/carriers/' + id + '/contacts/' + subId).toPromise();
    }
}
