import { EstimateInventoryContainerItem } from './estimate-inventory-container-item';

export class EstimateInventoryContainer {

    public id: string;
    public name: string;
    public estimate_id: string;
    public items: Array<EstimateInventoryContainerItem>;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.estimate_id = null;
        this.items = [];
        this.created = null;
        this.updated = null;
    }
}
