import { Account } from "../admin/account";
import { AccountWorkspaceView } from "../admin/account-workspace-view";
import { AdminUser } from "../admin/admin-user";
import { Workspace } from "../admin/workspace";

export class   Session {

    token: string;
    account: Account;
    workspaces: Workspace[];
    socket_id: string;
    adminUser: AdminUser;
    context: 'ADMIN' | 'ACCOUNT' | 'CUSTOMER' | 'CARRIER';
    account_workspaces: Array<AccountWorkspaceView>;

    constructor() {
        this.token = null;
        this.account = null;
        this.socket_id = null;
        this.adminUser = null;
        this.context = null;
        this.workspaces = [];
        this.account_workspaces = [];
    }

}
