import { File } from 'src/app/entities/global/file';
import { Geospatial } from 'src/app/entities/global/geospatial';

export class ElectronicSignature {
    
    id: string;
    esignature_file: File;
    ip_address: string;
    internet_browser: string;
    geospatial: Geospatial;
    created: number;

    constructor() {
        this.id = null;
        this.esignature_file = new File();
        this.ip_address = null;
        this.internet_browser = null;
        this.geospatial = new Geospatial();
        this.created = null;
    }

}
