import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Warehouse } from 'src/app/entities/workspace/warehouse';
import { HelperService } from 'src/app/services/helper.service';
import { WarehousesService } from 'src/app/services/workspaces/warehouses.service';

declare var jQuery;

@Component({
    selector: 'app-warehouses-form',
    templateUrl: './warehouses-form.component.html',
    styleUrls: ['./warehouses-form.component.scss']
})
export class WarehousesFormComponent implements OnInit {

    dataForm: Warehouse;

    constructor(
        private currentRoute: ActivatedRoute,
        private warehousesService: WarehousesService,
        private helperService: HelperService,
    ) {
        this.dataForm = new Warehouse();
    }


    ngOnInit(): void {

    }


    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }


    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.warehousesService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {
        this.helperService.showLoadingMxpro360();
        this.warehousesService
            .save(this.dataForm)
            .then(response => {
                this.helperService.goToWorkspaceRouterLink('/warehouses');
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setAddress(address) {
        this.dataForm.address = address
    }

}
