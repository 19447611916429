<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Companies
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Companies
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-7 col-lg-4 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a appWorkspaceRouterLink="/companies/add" class="btn btn-new" appCanUserWorkspace="COMPANIES_CREATE" *ngIf="!maxCompaniesDone">
                    <i class="fas fa-plus-square"></i> &nbsp; New Company
                </a>
                <button (click)="showMaxCompaniesMessage()" class="btn btn-new disabled" appCanUserWorkspace="COMPANIES_CREATE" *ngIf="maxCompaniesDone">
                    <i class="fas fa-plus-square"></i> &nbsp; New Company
                </button>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Created</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let company of companies | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>
                                    {{company.data.name}}
                                </td>
                                <td>
                                    {{company.data.email}}
                                </td>
                                <td>
                                    {{company.data.phone | phone}}
                                </td>
                                <td>
                                    {{company.data.created | dateTime}}
                                </td>
                                <td>
                                    <div class="block-btn-options">
                                        <button class="btn-enter-company" (click)="openModuleCompany(company.data)" *ngIf="company.selected">
                                            <i class="fa-solid fa-building"></i>
                                        </button>
                                        <button class="btn-disable-company" appCanUserWorkspace="COMPANIES_DISABLE" (click)="disableCompany(i)">
                                            <i class="fa-solid fa-building-circle-xmark"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>

                </div>
            </div>
        </div>
    </div>

</div>
