import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Zone } from 'src/app/entities/workspace/zone';


@Injectable({
    providedIn: 'root'
})
export class ZonesService {

    constructor(private http: HttpClient) { }

    getAll(versionId: string): Promise<Array<Zone>> {
        return this.http.get<Array<Zone>>(environment.api.workspace + '/zones-by-version/' + versionId).toPromise();
    }

    getById(id: string) {
        return this.http.get<Zone>(environment.api.workspace + '/zones/' + id).toPromise();
    }

    getAllByTariffVersionId(tariffVersionId: string): Promise<Array<Zone>> {
        return this.http.get<Array<Zone>>(environment.api.workspace + '/zones/tariff-version-id/' + tariffVersionId).toPromise();
    }

    save(dataForm: Zone): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Zone) {
        return this.http.post(environment.api.workspace + '/zones', dataForm).toPromise();
    }

    edit(dataForm: Zone) {
        return this.http.put(environment.api.workspace + '/zones/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/zones/' + id).toPromise();
    }

    getStates(): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/zone-states').toPromise();
    }
}
