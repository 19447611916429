export class TicketType {
    public id: string;
    public name: string;
    public priority: "LOW" | "MID" | "HIGH" | "URGENT"
    public display_on_customer_portal: boolean;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.priority = null;
        this.display_on_customer_portal = true;
        this.created = null;
        this.updated = null;
    }
}
