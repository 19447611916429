import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobNote } from '../../entities/workspace/job-note';
import { JobNoteView } from '../../entities/workspace/job-note-view';
import { environment } from 'src/environments/environment';
import { JobStatus } from 'src/app/entities/workspace/job-status';

@Injectable({
  providedIn: 'root'
})
export class JobNotesService {

  constructor(private http: HttpClient) { }

  getAllWithFullView(idJob: string): Promise<Array<JobNoteView>> {
    return this.http.get<Array<JobNoteView>>(environment.api.company + '/jobs/' + idJob + '/follow-ups').toPromise();
  }

  saveNoteJob(idJob: string, note: JobNote): Promise<any> {
    return this.http.post<Array<JobNote>>(environment.api.company + '/jobs/' + idJob + '/follow-ups', note).toPromise();
  }

  saveNoteArchiveJob(idJob: string, jobStatus: JobStatus): Promise<any> {
    return this.http.post<Array<JobNote>>(environment.api.company + '/jobs/' + idJob + '/job-status-archive', jobStatus).toPromise();
  }
}
