<div class="widget widget-table-two">

    <div class="widget-heading">
        <h5 class="text-danger">Ticket assigned to them</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-danger"><div class="th-content text-light">Subject</div></th>
                        <th class="bg-danger"><div class="th-content text-light">Priority</div></th>
                        <th class="bg-danger"><div class="th-content th-heading text-light">Status</div></th>
                        <th class="bg-danger"><div class="th-content th-heading text-light">Job</div></th>
                        <th class="bg-danger"><div class="th-content th-heading text-light">Created</div></th>
                        <th class="bg-danger"><div class="th-content text-light">Link</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ticketAssigned of ticketsAssigned">
                        <td><div class="td-content customer-name">{{ticketAssigned.subject}}</div></td>
                        <td><div class="td-content customer-name">{{ticketAssigned.priority}}</div></td>
                        <td><div class="td-content customer-name">{{ticketAssigned.status}}</div></td>
                        <td><div class="td-content customer-name">{{ticketAssigned.job}}</div></td>
                        <td><div class="td-content product-brand">{{ticketAssigned.created | date}}</div></td>
                        <td><div class="td-content badge badge-danger text-light cursor-pointer">Go to</div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>