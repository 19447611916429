import { UnsubscribeService } from './../../../services/unsubscribe.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EmailUnsuscribe } from 'src/app/entities/companies/email-unsuscribe';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  idWorkspace: string;
  section: "FORM" | "READY";
  dataForm: EmailUnsuscribe;


  constructor(
    private currentRoute: ActivatedRoute,
    private unsubscribeService: UnsubscribeService,
    private helperService: HelperService
  ) {
    this.section = "FORM";
    this.idWorkspace = "";
    this.dataForm = new EmailUnsuscribe();
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_workspace !== 'undefined' && typeof params.id_company !== 'undefined' &&
        typeof params.email !== 'undefined') {
          this.idWorkspace = params.id_workspace;
          this.dataForm.company_id = params.id_company;
          this.dataForm.email = params.email;
          
      }
    });
  }

  unsuscribed(): void {
    this.helperService.showLoadingMxpro360();

    if(this.dataForm.reason != 'OTHER'){
      this.dataForm.comments = null;
    }

    this.unsubscribeService.unsubscribeEmails(this.dataForm, this.idWorkspace) 
    .then(() => {
      
          this.section = 'READY'
      })
      .catch((error) => {
          
        
      })
      .finally(() => {
          this.helperService.hideLoadingMxpro360();
      });
  }

  closeTab(){
    window.close();
  }

}
