import { AccountWorkspacesComponent } from './pages/accounts/account-workspaces/account-workspaces.component';
import { AdvertisersComponent } from './pages/workspaces/advertisers/advertisers.component';
import { AdvertisersFormComponent } from './pages/workspaces/advertisers-form/advertisers-form.component';
import { AdvertisersViewComponent } from './pages/workspaces/advertisers-view/advertisers-view.component';
import { AlphaNumericDirective } from './directives/alpha-numeric.directive';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ArchiveDeliveryDocumentsReportComponent } from './pages/workspaces/archive-delivery-documents-report/archive-delivery-documents-report.component';
import { ArchivePickupDocumentsReportComponent } from './pages/workspaces/archive-pickup-documents-report/archive-pickup-documents-report.component';
import { AvatarTitlePipe } from './pipes/avatar-title.pipe';
import { BlackoutMoveDatesComponent } from './pages/workspaces/blackout-move-dates/blackout-move-dates.component';
import { BlackoutMoveDatesFormComponent } from './pages/workspaces/blackout-move-dates-form/blackout-move-dates-form.component';
import { BoardJobsComponent } from './pages/workspaces/board-jobs/board-jobs.component';
import { BoardJobsFormComponent } from './pages/workspaces/board-jobs-form/board-jobs-form.component';
import { BoardJobsViewComponent } from './pages/workspaces/board-jobs-view/board-jobs-view.component';
import { BookedByCompanyComponent } from './components/widgets/sales-manager/booked-by-company/booked-by-company.component';
import { BookingsLast7DaysComponent } from './components/widgets/salesman/bookings-last7-days/bookings-last7-days.component';
import { BrowserModule } from '@angular/platform-browser';
import { BuildPostingLeadsComponent } from './pages/workspaces/build-posting-leads/build-posting-leads.component';
import { BuyNumbersComponent } from './pages/workspaces/buy-numbers/buy-numbers.component';
import { CalendarComponent } from './pages/workspaces/calendar/calendar.component';
import { CalendarDateTimePipe } from './pipes/calendar-date-time.pipe';
import { CalendarDirective } from './directives/calendar.directive';
import { CalendarFreightComponent } from './pages/workspaces/calendar-freight/calendar-freight.component';
import { CanUserCompanyDirective } from './directives/can-user-company.directive';
import { CanUserWorkspaceDirective } from './directives/can-user-workspace.directive';
import { CarrierJobsReportComponent } from './pages/workspaces/carrier-jobs-report/carrier-jobs-report.component';
import { CarriersComponent } from './pages/workspaces/carriers/carriers.component';
import { CarriersCoverageMapComponent } from './pages/workspaces/carriers-coverage-map/carriers-coverage-map.component';
import { CarriersFormComponent } from './pages/workspaces/carriers-form/carriers-form.component';
import { CarriersViewComponent } from './pages/workspaces/carriers-view/carriers-view.component';
import { CdnLinkDirective } from './directives/cdn-link.directive';
import { CkEditorComponent } from './components/ck-editor/ck-editor.component';
import { ClosingRatioPerEstimateComponent } from './components/widgets/personal/closing-ratio-per-estimate/closing-ratio-per-estimate.component';
import { ClosingRatioPerLeadComponent } from './components/widgets/personal/closing-ratio-per-lead/closing-ratio-per-lead.component';
import { CommissionSettingsComponent } from './pages/companies/commission-settings/commission-settings.component';
import { CommissionSettingsFormComponent } from './pages/companies/commission-settings-form/commission-settings-form.component';
import { CommissionSettingsViewComponent } from './pages/companies/commission-settings-view/commission-settings-view.component';
import { CompaniesComponent } from './pages/workspaces/companies/companies.component';
import { CompaniesFormComponent } from './pages/workspaces/companies-form/companies-form.component';
import { CompanyRouterLinkDirective } from './directives/company-router-link.directive';
import { CompanySalesPerformenceComponent } from './pages/workspaces/company-sales-performence/company-sales-performence.component';
import { ConvertStatePipe } from './pipes/convert-state.pipe';
import { CreditCardComponent } from './pages/public/credit-card/credit-card.component';
import { CustomersComponent } from './pages/companies/customers/customers.component';
import { CustomersFormComponent } from './pages/companies/customers-form/customers-form.component';
import { CustomersViewComponent } from './pages/companies/customers-view/customers-view.component';
import { CustomPaginatorComponent } from './components/custom-paginator/custom-paginator.component';
import { DashboardComponent } from './pages/companies/dashboard/dashboard.component';
import { DashboardWorkspaceComponent } from './pages/workspaces/dashboard-workspace/dashboard-workspace.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DatePickerComponent } from './components/form/date-picker/date-picker.component';
import { DatePipe } from './pipes/date.pipe';
import { DateRangePickerComponent } from './components/form/date-range-picker/date-range-picker.component';
import { DateTimePipe } from './pipes/date-time.pipe';
import { DeductibleLevelsComponent } from './pages/workspaces/deductible-levels/deductible-levels.component';
import { DeductibleLevelsFormComponent } from './pages/workspaces/deductible-levels-form/deductible-levels-form.component';
import { DeliveryDocumentsReportComponent } from './pages/workspaces/delivery-documents-report/delivery-documents-report.component';
import { DocumentsTypesComponent } from './pages/companies/documents-types/documents-types.component';
import { DocumentsTypesFormComponent } from './pages/companies/documents-types-form/documents-types-form.component';
import { DocumentsTypesViewComponent } from './pages/companies/documents-types-view/documents-types-view.component';
import { DriverInvoicesComponent } from './pages/workspaces/driver-invoices/driver-invoices.component';
import { DriverInvoicesFormComponent } from './pages/workspaces/driver-invoices-form/driver-invoices-form.component';
import { DriverInvoicesViewComponent } from './pages/workspaces/driver-invoices-view/driver-invoices-view.component';
import { DropifyDirective } from './directives/dropify.directive';
import { EmailCampaignsComponent } from './pages/companies/email-campaigns/email-campaigns.component';
import { EmailCampaignsFormComponent } from './pages/companies/email-campaigns-form/email-campaigns-form.component';
import { EmailsSentLast7DaysComponent } from './components/widgets/salesman/emails-sent-last7-days/emails-sent-last7-days.component';
import { EmailTemplatesComponent } from './pages/companies/email-templates/email-templates.component';
import { EmployeesCompaniesComponent } from './pages/companies/employees-companies/employees-companies.component';
import { EmployeesComponent } from './pages/workspaces/employees/employees.component';
import { EmployeesFormComponent } from './pages/workspaces/employees-form/employees-form.component';
import { EmployeesViewComponent } from './pages/workspaces/employees-view/employees-view.component';
import { EstimateRatioPerLeadComponent } from './components/widgets/personal/estimate-ratio-per-lead/estimate-ratio-per-lead.component';
import { EstimatesComponent } from './pages/companies/estimates/estimates.component';
import { EstimatesDetailsComponent } from './pages/companies/estimates-details/estimates-details.component';
import { EstimatesEmailComponent } from './pages/companies/estimates-email/estimates-email.component';
import { EstimatesEmailTemplatesFormComponent } from './pages/companies/email-templates-form/email-templates-form.component';
import { EstimatesEmailTemplatesViewComponent } from './pages/companies/email-templates-view/email-templates-view.component';
import { EstimatesFormComponent } from './pages/companies/estimates-form/estimates-form.component';
import { EstimatesInventoryItemsComponent } from './pages/companies/estimates-inventory-items/estimates-inventory-items.component';
import { EstimatesLogsComponent } from './pages/companies/estimates-logs/estimates-logs.component';
import { EstimatesOperationsComponent } from './pages/companies/estimates-operations/estimates-operations.component';
import { EstimatesPackingMaterialsComponent } from './pages/companies/estimates-packing-materials/estimates-packing-materials.component';
import { EstimatesPaymentsComponent } from './pages/companies/estimates-payments/estimates-payments.component';
import { EstimatesReportComponent } from './pages/companies/estimates-report/estimates-report.component';
import { EstimatesSignaturesComponent } from './pages/companies/estimates-signatures/estimates-signatures.component';
import { EstimatesStorageComponent } from './pages/companies/estimates-storage/estimates-storage.component';
import { EstimatesTicketsComponent } from './pages/companies/estimates-tickets/estimates-tickets.component';
import { EstimatesTicketsFormComponent } from './pages/companies/estimates-tickets-form/estimates-tickets-form.component';
import { EstimatesViewComponent } from './pages/companies/estimates-view/estimates-view.component';
import { ExportLeadsComponent } from './pages/companies/export-leads/export-leads.component';
import { FilePreviewerComponent } from './components/file-previewer/file-previewer.component';
import { FileUploadComponent } from './components/form/file-upload/file-upload.component';
import { FileUploadModalComponent } from './modals/file-upload-modal/file-upload-modal.component';
import { FilterBetweenPipe } from './pipes/filter-between.pipe';
import { FilterReplacePipe } from './pipes/filter-replace.pipe';
import { FollowUpRemindersComponent } from './pages/companies/follow-up-reminders/follow-up-reminders.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { GeneralSettingLeadRotationComponent } from './pages/companies/general-setting-lead-rotation/general-setting-lead-rotation.component';
import { GeneralSettingsComponent } from './pages/companies/general-settings/general-settings.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { GroupalLogViewedEstimateComponent } from './components/widgets/global/groupal-log-viewed-estimate/groupal-log-viewed-estimate.component';
import { HeaderComponent } from './components/header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { IfEmployeeHasJobPositionDirective } from './directives/if-employee-has-job-position.directive';
import { InventoryCategoriesComponent } from './pages/workspaces/inventory-categories/inventory-categories.component';
import { InventoryCategoriesFormComponent } from './pages/workspaces/inventory-categories-form/inventory-categories-form.component';
import { InventoryItemsComponent } from './pages/workspaces/inventory-items/inventory-items.component';
import { InventoryItemsFormComponent } from './pages/workspaces/inventory-items-form/inventory-items-form.component';
import { InvoicesComponent } from './pages/companies/invoices/invoices.component';
import { JobMaterialsComponent } from './pages/companies/job-materials/job-materials.component';
import { JobsBookedLast7DaysComponent } from './components/widgets/sales-manager/jobs-booked-last7-days/jobs-booked-last7-days.component';
import { JobsByOperationStatusComponent } from './components/widgets/global/jobs-by-operation-status/jobs-by-operation-status.component';
import { JobsCalendarComponent } from './pages/companies/jobs-calendar/jobs-calendar.component';
import { JobsCompletedComponent } from './components/widgets/sales-manager/jobs-completed/jobs-completed.component';
import { JobsComponent } from './pages/companies/jobs/jobs.component';
import { JobsPicked2DaysComponent } from './components/widgets/global/jobs-picked2-days/jobs-picked2-days.component';
import { JobsPickedInWindowComponent } from './components/widgets/global/jobs-picked-in-window/jobs-picked-in-window.component';
import { JobsPickedTodayComponent } from './components/widgets/global/jobs-picked-today/jobs-picked-today.component';
import { JobsPickedTomorrowComponent } from './components/widgets/global/jobs-picked-tomorrow/jobs-picked-tomorrow.component';
import { JobsReportComponent } from './pages/companies/jobs-report/jobs-report.component';
import { Last10RecurringsComponent } from './components/widgets/salesman/last10-recurrings/last10-recurrings.component';
import { Last5DepositsComponent } from './components/widgets/salesman/last5-deposits/last5-deposits.component';
import { Last5PaymentsComponent } from './components/widgets/global/last5-payments/last5-payments.component';
import { Last5TicketsComponent } from './components/widgets/personal/last5-tickets/last5-tickets.component';
import { Last5TicketsGlobalComponent } from './components/widgets/global/last5-tickets-global/last5-tickets-global.component';
import { LeadProvidersTimelinesComponent } from './components/widgets/global/lead-providers-timelines/lead-providers-timelines.component';
import { LeadsAdvertisersReportComponent } from './pages/workspaces/leads-advertisers-report/leads-advertisers-report.component';
import { LeadsComponent } from './pages/companies/leads/leads.component';
import { LeadsEsimatesResultsComponent } from './pages/companies/leads-esimates-results/leads-esimates-results.component';
import { LeadsFormComponent } from './pages/companies/leads-form/leads-form.component';
import { LeadsReportComponent } from './pages/workspaces/leads-report/leads-report.component';
import { LeadsViewComponent } from './pages/companies/leads-view/leads-view.component';
import { LiabilityAmountsComponent } from './pages/workspaces/liability-amounts/liability-amounts.component';
import { LiabilityAmountsFormComponent } from './pages/workspaces/liability-amounts-form/liability-amounts-form.component';
import { LiabilityDeductibleRatesComponent } from './pages/workspaces/liability-deductible-rates/liability-deductible-rates.component';
import { LoadBoardComponent } from './pages/workspaces/load-board/load-board.component';
import { LoginComponent } from './pages/public/login/login.component';
import { LogViewedEstimateByTeamsComponent } from './components/widgets/sales-manager/log-viewed-estimate-by-teams/log-viewed-estimate-by-teams.component';
import { LogViewedEstimateComponent } from './components/widgets/personal/log-viewed-estimate/log-viewed-estimate.component';
import { MonthlyClosingRatioChangeComponent } from './components/widgets/global/monthly-closing-ratio-change/monthly-closing-ratio-change.component';
import { MovingClaimsComponent } from './pages/companies/moving-claims/moving-claims.component';
import { MovingCompletionsComponent } from './pages/companies/moving-completions/moving-completions.component';
import { MovingItemsComponent } from './pages/companies/moving-items/moving-items.component';
import { MovingItemsFormComponent } from './pages/companies/moving-items-form/moving-items-form.component';
import { MovingLabelsComponent } from './pages/companies/moving-labels/moving-labels.component';
import { MovingMediaFilesComponent } from './pages/companies/moving-media-files/moving-media-files.component';
import { MovingOutsourcingsComponent } from './pages/companies/moving-outsourcings/moving-outsourcings.component';
import { MyLeadsComponent } from './pages/companies/my-leads/my-leads.component';
import { NewEstimatesLast7DaysComponent } from './components/widgets/salesman/new-estimates-last7-days/new-estimates-last7-days.component';
import { NgModule } from '@angular/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxPaginationModule } from 'ngx-pagination';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { OwnPerformanceComponent } from './components/widgets/sales-manager/own-performance/own-performance.component';
import { PackingMaterialsComponent } from './pages/workspaces/packing-materials/packing-materials.component';
import { PackingMaterialsFormComponent } from './pages/workspaces/packing-materials-form/packing-materials-form.component';
import { PageNotFoundComponent } from './pages/public/page-not-found/page-not-found.component';
import { PanelLayoutComponent } from './components/panel-layout/panel-layout.component';
import { PasswordRecoveryComponent } from './pages/public/password-recovery/password-recovery.component';
import { PaymentGatewaySettingsComponent } from './pages/companies/payment-gateway-settings/payment-gateway-settings.component';
import { PaymentGatewaySettingsFormComponent } from './pages/companies/payment-gateway-settings-form/payment-gateway-settings-form.component';
import { PaymentMethodsUsedComponent } from './components/widgets/global/payment-methods-used/payment-methods-used.component';
import { PaymentsComponent } from './pages/companies/payments/payments.component';
import { PaymentsMethodsComponent } from './pages/companies/payments-methods/payments-methods.component';
import { PaymentsMethodsFormComponent } from './pages/companies/payments-methods-form/payments-methods-form.component';
import { PaymentsViewComponent } from './pages/companies/payments-view/payments-view.component';
import { PayrollBalancesComponent } from './pages/companies/payroll-balances/payroll-balances.component';
import { PayrollBalancesFormComponent } from './pages/companies/payroll-balances-form/payroll-balances-form.component';
import { PeriodsComponent } from './pages/workspaces/periods/periods.component';
import { PeriodsFormComponent } from './pages/workspaces/periods-form/periods-form.component';
import { PhonePipe } from './pipes/phone.pipe';
import { PickupDocumentsReportComponent } from './pages/workspaces/pickup-documents-report/pickup-documents-report.component';
import { RecurringCustomerComponent } from './components/widgets/personal/recurring-customer/recurring-customer.component';
import { RecurringCustomersbyTeamsComponent } from './components/widgets/sales-manager/recurring-customersby-teams/recurring-customersby-teams.component';
import { RecurringCustomersComponent } from './pages/companies/recurring-customers/recurring-customers.component';
import { RegisterAccountCompanyComponent } from './pages/public/register-account-company/register-account-company.component';
import { RegisterComponent } from './pages/public/register/register.component';
import { ReportSalesCommissionsComponent } from './pages/companies/report-sales-commissions/report-sales-commissions.component';
import { RolesComponent } from './pages/workspaces/roles/roles.component';
import { RolesFormComponent } from './pages/workspaces/roles-form/roles-form.component';
import { RouteRatesComponent } from './pages/workspaces/route-rates/route-rates.component';
import { RouteRatesMapComponent } from './pages/workspaces/route-rates-map/route-rates-map.component';
import { SalesByCompanyComponent } from './components/widgets/sales-manager/sales-by-company/sales-by-company.component';
import { SalesLeaderComponent } from './components/widgets/global/sales-leader/sales-leader.component';
import { SalesLeaderManagerComponent } from './components/widgets/sales-manager/sales-leader-manager/sales-leader-manager.component';
import { SalesmanPerformanceTeamComponent } from './components/widgets/sales-manager/salesman-performance-team/salesman-performance-team.component';
import { SalesmanPerformsComponent } from './pages/companies/salesman-performs/salesman-performs.component';
import { SearchAddressComponent } from './components/search-address/search-address.component';
import { SearchPipe } from './pipes/search.pipe';
import { Select2Directive } from './directives/select2.directive';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SmsCampaignsComponent } from './pages/companies/sms-campaigns/sms-campaigns.component';
import { SmsCampaignsFormComponent } from './pages/companies/sms-campaigns-form/sms-campaigns-form.component';
import { SmsTemplatesComponent } from './pages/companies/sms-templates/sms-templates.component';
import { SmsTemplatesFormComponent } from './pages/companies/sms-templates-form/sms-templates-form.component';
import { StorageCompaniesComponent } from './pages/workspaces/storage-companies/storage-companies.component';
import { StorageCompaniesFormComponent } from './pages/workspaces/storage-companies-form/storage-companies-form.component';
import { StorageCompaniesViewComponent } from './pages/workspaces/storage-companies-view/storage-companies-view.component';
import { StorageInvoicesComponent } from './pages/companies/storage-invoices/storage-invoices.component';
import { StorageInvoicesFormComponent } from './pages/companies/storage-invoices-form/storage-invoices-form.component';
import { StorageInvoicesWorkdpaceComponent } from './pages/workspaces/storage-invoices-workdpace/storage-invoices-workdpace.component';
import { SuppliersComponent } from './pages/workspaces/suppliers/suppliers.component';
import { SuppliersFormComponent } from './pages/workspaces/suppliers-form/suppliers-form.component';
import { SuppliersViewComponent } from './pages/workspaces/suppliers-view/suppliers-view.component';
import { TariffAdditionalConceptsComponent } from './pages/workspaces/tariff-additional-concepts/tariff-additional-concepts.component';
import { TariffAdditionalConceptsFormComponent } from './pages/workspaces/tariff-additional-concepts-form/tariff-additional-concepts-form.component';
import { TariffVersionsComponent } from './pages/workspaces/tariff-versions/tariff-versions.component';
import { TariffVersionsFormComponent } from './pages/workspaces/tariff-versions-form/tariff-versions-form.component';
import { TariffVersionsSettingsComponent } from './pages/workspaces/tariff-versions-settings/tariff-versions-settings.component';
import { TicketAssignmentRulesComponent } from './pages/companies/ticket-assignment-rules/ticket-assignment-rules.component';
import { TicketAssignmentRulesFormComponent } from './pages/companies/ticket-assignment-rules-form/ticket-assignment-rules-form.component';
import { TicketsAssignedComponent } from './components/widgets/personal/tickets-assigned/tickets-assigned.component';
import { TicketsByPriorityComponent } from './components/widgets/global/tickets-by-priority/tickets-by-priority.component';
import { TicketsComponent } from './pages/companies/tickets/tickets.component';
import { TicketsViewComponent } from './pages/companies/tickets-view/tickets-view.component';
import { TicketsWorkspaceComponent } from './pages/workspaces/tickets-workspace/tickets-workspace.component';
import { TicketTypeComponent } from './pages/companies/ticket-type/ticket-type.component';
import { TicketTypeFormComponent } from './pages/companies/ticket-type-form/ticket-type-form.component';
import { TotalBadLeadsComponent } from './components/widgets/global/total-bad-leads/total-bad-leads.component';
import { TotalEstimatesBySalesmanComponent } from './components/widgets/sales-manager/total-estimates-by-salesman/total-estimates-by-salesman.component';
import { TotalIncomingLeadsComponent } from './components/widgets/global/total-incoming-leads/total-incoming-leads.component';
import { TotalSalesLast7DaysComponent } from './components/widgets/sales-manager/total-sales-last7-days/total-sales-last7-days.component';
import { UnsubscribeComponent } from './pages/public/unsubscribe/unsubscribe.component';
import { UpdateProfileComponent } from './pages/accounts/update-profile/update-profile.component';
import { VehiclesComponent } from './pages/workspaces/vehicles/vehicles.component';
import { VehiclesFormComponent } from './pages/workspaces/vehicles-form/vehicles-form.component';
import { VolumesComponent } from './pages/workspaces/volumes/volumes.component';
import { VolumesFormComponent } from './pages/workspaces/volumes-form/volumes-form.component';
import { WarehousesComponent } from './pages/workspaces/warehouses/warehouses.component';
import { WarehousesFaddJobsComponent } from './pages/workspaces/warehouses-fadd-jobs/warehouses-fadd-jobs.component';
import { WarehousesFormComponent } from './pages/workspaces/warehouses-form/warehouses-form.component';
import { WorkDepartmentsComponent } from './pages/companies/work-departments/work-departments.component';
import { WorkDepartmentsFormComponent } from './pages/companies/work-departments-form/work-departments-form.component';
import { WorkDepartmentsViewComponent } from './pages/companies/work-departments-view/work-departments-view.component';
import { WorkspaceRouterLinkDirective } from './directives/workspace-router-link.directive';
import { ZeroFillPipe } from './pipes/zero-fill.pipe';
import { ZonesComponent } from './pages/workspaces/zones/zones.component';
import { ZonesFormComponent } from './pages/workspaces/zones-form/zones-form.component';
import { ZoneStatesComponent } from './pages/workspaces/zone-states/zone-states.component';
import { ZoneStatesFormComponent } from './pages/workspaces/zone-states-form/zone-states-form.component';
import { PendingQAJobsComponent } from './pages/companies/pending-qajobs/pending-qajobs.component';
import { AdditionalStopRulesComponent } from './pages/workspaces/additional-stop-rules/additional-stop-rules.component';
import { AdditionalStopRulesFormComponent } from './pages/workspaces/additional-stop-rules-form/additional-stop-rules-form.component';
import { ReportQAComponent } from './pages/companies/report-qa/report-qa.component';
import { ReportQAComponent as ReportQAComponentWS} from './pages/workspaces/report-qa/report-qa.component';
import { DispatchContactsComponent } from './pages/workspaces/dispatch-contacts/dispatch-contacts.component';
import { DispatchContactsFormComponent } from './pages/workspaces/dispatch-contacts-form/dispatch-contacts-form.component';
import { SettingsComponent } from './pages/workspaces/settings/settings.component';
import { BadLeadsReportComponent } from './pages/workspaces/bad-leads-report/bad-leads-report.component';
import { LocalTariffSettingsComponent } from './pages/workspaces/local-tariff-settings/local-tariff-settings.component';
import { LocalTariffSettingsFormComponent } from './pages/workspaces/local-tariff-settings-form/local-tariff-settings-form.component';
import { QRLabelsComponent } from './components/qr-labels/qr-labels.component';
import { UsedInventoryCategoriesPipe } from './pipes/used-inventory-categories.pipe';
import { LoadLeadsByExcelComponent } from './pages/workspaces/load-leads-by-excel/load-leads-by-excel.component';
import { MyPerformsComponent } from './pages/companies/my-performs/my-performs.component';
import { SelectableCapacitiesListComponent } from './components/form/selectable-capacities-list/selectable-capacities-list.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AutomationsComponent } from './pages/companies/automations/automations.component';
import { AutomationsFormComponent } from './pages/companies/automations-form/automations-form.component';
import { StorageManagerComponent } from './pages/workspaces/storage-manager/storage-manager.component';
import { WorkspacesBillingComponent } from './pages/accounts/workspaces-billing/workspaces-billing.component';
import { WorkspacesBillingInvoicesComponent } from './pages/accounts/workspaces-billing-invoices/workspaces-billing-invoices.component';
import { WorkspacesBillingProcessPaymentComponent } from './pages/accounts/workspaces-billing-process-payment/workspaces-billing-process-payment.component';
import { TimePipe } from './pipes/time.pipe';
import { PhoneLinesComponent } from './pages/workspaces/phone-lines/phone-lines.component';
import { PhoneLinesFormComponent } from './pages/workspaces/phone-lines-form/phone-lines-form.component';
import { LeadForwardingLinksComponent } from './pages/workspaces/lead-forwarding-links/lead-forwarding-links.component';
import { LeadForwardingLinksFormComponent } from './pages/workspaces/lead-forwarding-links-form/lead-forwarding-links-form.component';
import { OldDialerComponent} from './pages/companies/dialer/dialer.component';
import { OldPhoneComponent } from './pages/companies/dialer/phone/phone.component';
import { OldSmsComponent } from './pages/companies/dialer/sms/sms.component';
import { PayrollClockUsersComponent } from './pages/companies/payroll-clock-users/payroll-clock-users.component';
import { PayrollClockUsersFormComponent } from './pages/companies/payroll-clock-users-form/payroll-clock-users-form.component';
import { PayrollClockLocationsComponent } from './pages/companies/payroll-clock-locations/payroll-clock-locations.component';
import { PayrollClockLocationsFormComponent } from './pages/companies/payroll-clock-locations-form/payroll-clock-locations-form.component';
import { PayrollTimeClockComponent } from './pages/companies/payroll-time-clock/payroll-time-clock.component';
import { PayrollSettingsComponent } from './pages/companies/payroll-settings/payroll-settings.component';
import { PayrollSettingsFormComponent } from './pages/companies/payroll-settings-form/payroll-settings-form.component';
import { TimeFromDatePipe } from './pipes/time-from-date.pipe';
import { PayrollPaymentsComponent } from './pages/companies/payroll-payments/payroll-payments.component';
import { PayrollPaymentsFormComponent } from './pages/companies/payroll-payments-form/payroll-payments-form.component';
import { PayrollPaymentsViewComponent } from './pages/companies/payroll-payments-view/payroll-payments-view.component';
import { EquationsFormComponent } from './components/equations-form/equations-form.component';
import { CanceledJobsListComponent } from './pages/workspaces/canceled-jobs-list/canceled-jobs-list.component';
import { QuickInventoryEstimateComponent } from './pages/companies/quick-inventory-estimate/quick-inventory-estimate.component';
import { LocalRoomCategoriesComponent } from './pages/workspaces/local-room-categories/local-room-categories.component';
import { LocalRoomCategoriesFormComponent } from './pages/workspaces/local-room-categories/local-room-categories-form/local-room-categories-form.component';
import { LocalRoomClosetsComponent } from './pages/workspaces/local-room-closets/local-room-closets.component';
import { LocalRoomClosetsFormComponent } from './pages/workspaces/local-room-closets/local-room-closets-form/local-room-closets-form.component';
import { PayrollTimeClockManualComponent } from './pages/companies/payroll-time-clock-manual/payroll-time-clock-manual.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { PeriodsFilterComponent } from './components/periods-filter/periods-filter.component';
import { TimeFromDecimalPipe } from './pipes/time-from-decimal.pipe';
import { CustomLabelsComponent } from './pages/companies/custom-labels/custom-labels.component';
import { CustomLabelsViewComponent } from './pages/companies/custom-labels-view/custom-labels-view.component';
import { PhoneRingGroupsComponent } from './pages/workspaces/phone-ring-groups/phone-ring-groups.component';
import { PhoneRingGroupsFormComponent } from './pages/workspaces/phone-ring-groups-form/phone-ring-groups-form.component';
import { BroadcastChannelService } from './services/broadcast-channel.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PhoneQueuesComponent } from './pages/workspaces/phone-queues/phone-queues.component';
import { PhoneQueuesFormComponent } from './pages/workspaces/phone-queues-form/phone-queues-form.component';
import { PhoneIvrsComponent } from './pages/workspaces/phone-ivrs/phone-ivrs.component';
import { PhoneIvrsFormComponent } from './pages/workspaces/phone-ivrs-form/phone-ivrs-form.component';
import { PhoneCdrComponent } from './pages/workspaces/phone-cdr/phone-cdr.component';
import { ProfitLossComponent } from './pages/companies/profit-loss/profit-loss.component';
import { WorkspacesUnauthorizedOutstandingInvoicesComponent } from './pages/accounts/workspaces-unauthorized-outstanding-invoices/workspaces-unauthorized-outstanding-invoices.component';
import { CcProcessingComponent } from './pages/companies/cc-processing/cc-processing.component';
import { ActiveAgentsComponent } from './pages/companies/active-agents/active-agents.component';
import { CounterService } from './services/companies/counter-phone.service';
import { EstimateClaimsComponent } from './pages/companies/estimate-claims/estimate-claims.component';
import { PhoneExtensionsFormComponent } from './pages/workspaces/phone-extensions-form/phone-extensions-form.component';
import { PhoneExtensionsComponent } from './pages/workspaces/phone-extensions/phone-extensions.component';
import { ClaimsListComponent } from './pages/companies/claims-list/claims-list.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { CallHistoryComponent } from './pages/companies/phone/components/call-history/call-history.component';
import { SMSComponent } from './pages/companies/phone/components/sms/sms.component';
import { DialerComponent } from './pages/companies/phone/components/dialer/dialer.component';
import { PhoneComponent } from './pages/companies/phone/phone.component';
import { CallTimerPipe } from './pipes/call-timer.pipe';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        AccountWorkspacesComponent,
        AdvertisersComponent,
        AdvertisersFormComponent,
        AdvertisersViewComponent,
        AlphaNumericDirective,
        AppComponent,
        ArchiveDeliveryDocumentsReportComponent,
        ArchivePickupDocumentsReportComponent,
        AvatarTitlePipe,
        BlackoutMoveDatesComponent,
        BlackoutMoveDatesFormComponent,
        BoardJobsComponent,
        BoardJobsComponent,
        BoardJobsFormComponent,
        BoardJobsViewComponent,
        BookedByCompanyComponent,
        BookingsLast7DaysComponent,
        BuildPostingLeadsComponent,
        BuyNumbersComponent,
        CalendarComponent,
        CalendarDateTimePipe,
        CalendarDirective,
        CalendarFreightComponent,
        CanUserCompanyDirective,
        CanUserWorkspaceDirective,
        CarrierJobsReportComponent,
        CarriersComponent,
        CarriersCoverageMapComponent,
        CarriersFormComponent,
        CarriersViewComponent,
        CdnLinkDirective,
        CkEditorComponent,
        ClosingRatioPerEstimateComponent,
        ClosingRatioPerLeadComponent,
        CommissionSettingsComponent,
        CommissionSettingsFormComponent,
        CommissionSettingsViewComponent,
        CompaniesComponent,
        CompaniesFormComponent,
        CompanyRouterLinkDirective,
        CompanySalesPerformenceComponent,
        ConvertStatePipe,
        CreditCardComponent,
        CustomersComponent,
        CustomersFormComponent,
        CustomersViewComponent,
        CustomPaginatorComponent,
        DashboardComponent,
        DashboardWorkspaceComponent,
        DateFilterComponent,
        DatePickerComponent,
        DatePipe,
        DateRangePickerComponent,
        DateTimePipe,
        DeductibleLevelsComponent,
        DeductibleLevelsFormComponent,
        DeliveryDocumentsReportComponent,
        DocumentsTypesComponent,
        DocumentsTypesFormComponent,
        DocumentsTypesViewComponent,
        DriverInvoicesComponent,
        DriverInvoicesFormComponent,
        DriverInvoicesViewComponent,
        DropifyDirective,
        EmailCampaignsComponent,
        EmailCampaignsFormComponent,
        EmailsSentLast7DaysComponent,
        EmailTemplatesComponent,
        EmployeesCompaniesComponent,
        EmployeesComponent,
        EmployeesFormComponent,
        EmployeesViewComponent,
        EstimateRatioPerLeadComponent,
        EstimatesComponent,
        EstimatesDetailsComponent,
        EstimatesEmailComponent,
        EstimatesEmailTemplatesFormComponent,
        EstimatesEmailTemplatesViewComponent,
        EstimatesFormComponent,
        EstimatesInventoryItemsComponent,
        EstimatesLogsComponent,
        EstimatesOperationsComponent,
        EstimatesPackingMaterialsComponent,
        EstimatesPaymentsComponent,
        EstimatesReportComponent,
        EstimatesSignaturesComponent,
        EstimatesStorageComponent,
        EstimatesTicketsComponent,
        EstimatesTicketsFormComponent,
        EstimatesViewComponent,
        ExportLeadsComponent,
        FilePreviewerComponent,
        FileUploadComponent,
        FileUploadModalComponent,
        FilterBetweenPipe,
        FilterReplacePipe,
        FollowUpRemindersComponent,
        FooterComponent,
        GeneralSettingsComponent,
        GoogleMapsComponent,
        GroupalLogViewedEstimateComponent,
        HeaderComponent,
        IfEmployeeHasJobPositionDirective,
        InventoryCategoriesComponent,
        InventoryCategoriesFormComponent,
        InventoryItemsComponent,
        InventoryItemsFormComponent,
        InvoicesComponent,
        JobMaterialsComponent,
        JobsBookedLast7DaysComponent,
        JobsByOperationStatusComponent,
        JobsCalendarComponent,
        JobsCompletedComponent,
        JobsComponent,
        JobsPicked2DaysComponent,
        JobsPickedInWindowComponent,
        JobsPickedTodayComponent,
        JobsPickedTomorrowComponent,
        JobsReportComponent,
        Last10RecurringsComponent,
        Last5DepositsComponent,
        Last5PaymentsComponent,
        Last5TicketsComponent,
        Last5TicketsGlobalComponent,
        LeadProvidersTimelinesComponent,
        LeadsAdvertisersReportComponent,
        LeadsComponent,
        LeadsEsimatesResultsComponent,
        LeadsFormComponent,
        LeadsReportComponent,
        LeadsViewComponent,
        LiabilityAmountsComponent,
        LiabilityAmountsFormComponent,
        LiabilityDeductibleRatesComponent,
        LoadBoardComponent,
        LoginComponent,
        LogViewedEstimateByTeamsComponent,
        LogViewedEstimateComponent,
        MonthlyClosingRatioChangeComponent,
        MovingClaimsComponent,
        MovingCompletionsComponent,
        MovingItemsComponent,
        MovingItemsFormComponent,
        MovingLabelsComponent,
        MovingMediaFilesComponent,
        MovingOutsourcingsComponent,
        NewEstimatesLast7DaysComponent,
        OnlyNumbersDirective,
        OwnPerformanceComponent,
        PackingMaterialsComponent,
        PackingMaterialsFormComponent,
        PageNotFoundComponent,
        PanelLayoutComponent,
        PasswordRecoveryComponent,
        PaymentGatewaySettingsComponent,
        PaymentGatewaySettingsFormComponent,
        PaymentMethodsUsedComponent,
        PaymentsComponent,
        PaymentsMethodsComponent,
        PaymentsMethodsFormComponent,
        PaymentsViewComponent,
        PayrollBalancesComponent,
        PayrollBalancesFormComponent,
        PeriodsComponent,
        PeriodsFormComponent,
        PhonePipe,
        PickupDocumentsReportComponent,
        RecurringCustomerComponent,
        RecurringCustomersbyTeamsComponent,
        RecurringCustomersComponent,
        RegisterAccountCompanyComponent,
        RegisterComponent,
        ReportSalesCommissionsComponent,
        RolesComponent,
        RolesFormComponent,
        RouteRatesComponent,
        RouteRatesMapComponent,
        SalesByCompanyComponent,
        SalesLeaderComponent,
        SalesLeaderManagerComponent,
        SalesmanPerformanceTeamComponent,
        SalesmanPerformsComponent,
        SearchAddressComponent,
        SearchPipe,
        Select2Directive,
        ShortDatePipe,
        SidebarComponent,
        SmsCampaignsComponent,
        SmsCampaignsFormComponent,
        SmsTemplatesComponent,
        SmsTemplatesFormComponent,
        StorageCompaniesComponent,
        StorageCompaniesFormComponent,
        StorageCompaniesViewComponent,
        StorageInvoicesComponent,
        StorageInvoicesFormComponent,
        StorageInvoicesWorkdpaceComponent,
        SuppliersComponent,
        SuppliersFormComponent,
        SuppliersViewComponent,
        TariffAdditionalConceptsComponent,
        TariffAdditionalConceptsFormComponent,
        TariffVersionsComponent,
        TariffVersionsFormComponent,
        TariffVersionsSettingsComponent,
        TicketAssignmentRulesComponent,
        TicketAssignmentRulesFormComponent,
        TariffVersionsSettingsComponent,
        TicketsAssignedComponent,
        TicketsByPriorityComponent,
        TicketsComponent,
        TicketsViewComponent,
        TicketsWorkspaceComponent,
        TotalBadLeadsComponent,
        TotalEstimatesBySalesmanComponent,
        TotalIncomingLeadsComponent,
        TotalSalesLast7DaysComponent,
        UnsubscribeComponent,
        UpdateProfileComponent,
        VehiclesComponent,
        VehiclesFormComponent,
        VolumesComponent,
        VolumesFormComponent,
        WarehousesComponent,
        WarehousesFaddJobsComponent,
        WarehousesFormComponent,
        WorkDepartmentsComponent,
        WorkDepartmentsFormComponent,
        WorkDepartmentsViewComponent,
        WorkspaceRouterLinkDirective,
        ZeroFillPipe,
        ZonesComponent,
        ZonesFormComponent,
        ZoneStatesComponent,
        ZoneStatesFormComponent,
        GeneralSettingLeadRotationComponent,
        MyLeadsComponent,
        TicketTypeComponent,
        TicketTypeFormComponent,
        PendingQAJobsComponent,
        AdditionalStopRulesComponent,
        AdditionalStopRulesFormComponent,
        AdditionalStopRulesFormComponent,
        ReportQAComponent,
        ReportQAComponentWS,
        DispatchContactsComponent,
        DispatchContactsFormComponent,
        SettingsComponent,
        BadLeadsReportComponent,
        LocalTariffSettingsComponent,
        LocalTariffSettingsFormComponent,
        QRLabelsComponent,
        UsedInventoryCategoriesPipe,
        LoadLeadsByExcelComponent,
        MyPerformsComponent,
        SelectableCapacitiesListComponent,
        AutomationsComponent,
        AutomationsFormComponent,
        StorageManagerComponent,
        WorkspacesBillingComponent,
        WorkspacesBillingInvoicesComponent,
        WorkspacesBillingProcessPaymentComponent,
        WorkspacesUnauthorizedOutstandingInvoicesComponent,
        TimePipe,
        PhoneLinesComponent,
        PhoneLinesFormComponent,
        LeadForwardingLinksComponent,
        LeadForwardingLinksFormComponent,
        OldDialerComponent,
        OldPhoneComponent,
        OldSmsComponent,
        PayrollClockUsersComponent,
        PayrollClockUsersFormComponent,
        PayrollClockLocationsComponent,
        PayrollClockLocationsFormComponent,
        PayrollTimeClockComponent,
        PayrollSettingsComponent,
        PayrollSettingsFormComponent,
        TimeFromDatePipe,
        PayrollPaymentsComponent,
        PayrollPaymentsFormComponent,
        PayrollPaymentsViewComponent,
        EquationsFormComponent,
        CanceledJobsListComponent,
        QuickInventoryEstimateComponent,
        LocalRoomCategoriesComponent,
        LocalRoomCategoriesFormComponent,
        LocalRoomClosetsComponent,
        LocalRoomClosetsFormComponent,
        PayrollTimeClockManualComponent,
        YesNoPipe,
        PeriodsFilterComponent,
        TimeFromDecimalPipe,
        CustomLabelsComponent,
        CustomLabelsViewComponent,
        PhoneRingGroupsComponent,
        PhoneRingGroupsFormComponent,
        PhoneQueuesComponent,
        PhoneQueuesFormComponent,
        PhoneIvrsComponent,
        PhoneIvrsFormComponent,
        PhoneCdrComponent,
        ProfitLossComponent,
        WorkspacesUnauthorizedOutstandingInvoicesComponent,
        CcProcessingComponent,
        ActiveAgentsComponent,
        EstimateClaimsComponent,
        PhoneExtensionsFormComponent,
        PhoneExtensionsComponent,
        ClaimsListComponent,
        HtmlEditorComponent,
        CallHistoryComponent,
        SMSComponent,
        DialerComponent,
        PhoneComponent,
        CallTimerPipe
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgxDropzoneModule,
        CKEditorModule,
        NgxMaskModule.forRoot(),
        NgxPaginationModule,
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: (createTranslateLoader),
                    // useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                    deps: [HttpClient]
                }
            }
        )
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,

        },
        CounterService,
        BroadcastChannelService,
    ],
    bootstrap: [
        AppComponent,
        FileUploadModalComponent
    ]
})
export class AppModule { }
