export class EmailTemplate {

    public id: string;
    public subject: string;
    public body: string;
    public company_id: string;
    public usage_context: 'AUTO_TRANSPORT' | 'MOVING' | 'BOTH';
    public work_department_ids: string[];
    public attach_inventory: boolean;
    public request_esignature: boolean;
    public request_poa: boolean;
    public request_inventory_update: boolean;
    public request_bol: boolean;
    public request_ppw: boolean;
    public ppw_document_type_id: string;
    public bol_signature_requested: 'PRE_PICKUP' | 'ON_SITE' | 'DELIVERY';
    public request_token: boolean;
    public attach_files: boolean;
    public attach_file_types: string[];
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.subject = null;
        this.body = null;
        this.company_id = null;
        this.usage_context = 'BOTH';
        this.work_department_ids = [];
        this.attach_inventory = false,
        this.request_esignature = false,
        this.request_poa = false,
        this.request_inventory_update = false,
        this.request_bol = false,
        this.request_ppw = false,
        this.ppw_document_type_id = null,
        this.bol_signature_requested = 'PRE_PICKUP',
        this.request_token = false
        this.attach_files = false;
        this.attach_file_types = [];
        this.created = null;
        this.updated = null;
    }
}

