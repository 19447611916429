<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

            <div class="row">
                <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                    <h3>
                        <span *ngIf="dataForm.id == null">
                            New
                        </span>
                        <span *ngIf="dataForm.id != null">
                            Update
                        </span> Zone States
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                            </li>
                            <li class="breadcrumb-item"><a appWorkspaceRouterLink="/settings/zone-states">Zone States</a></li>
                            <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                            <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                        </ol>
                    </nav>
                </div>

                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button class="btn btn-main-action" type="submit">
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <!-- header -->

            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group md-4">
                                <label for="inputState">Zone</label>
                                <select id="inputState" [(ngModel)]="dataForm.zone_id" name="wp_zone_id" class="form-control" [appSelect2] [theme]="'mxp360-form'" required>
                                    <option *ngFor="let zoneState of zoneStates" [selected]="dataForm.zone_id == zoneState.id"  value="{{zoneState.id}}">{{zoneState.name}}</option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted mb-4">Select Zone.</small>
                            </div>
                            
                            <div [hidden]="!(typeView == 'form')">
                                <div class="form-group mb-1">
                                    <label class="control-label">Name</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.name" name="wd_name" class="form-control" required>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Name.</small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Code</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.code" pattern="[A-Z]+" maxlength="2" name="wd_code" class="form-control" required>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Area Code (Only Capital Letters).</small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Zip Code</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.zip_code" name="wd_zip_code" class="form-control" maxlength="6" onlyNumbers>
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Zip Code.</small>
                                </div>                        

                                <div class="form-group mb-1">
                                    <label class="control-label">Radius</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-compass"><circle cx="12" cy="12" r="10"></circle><polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon></svg></span>
                                        </div>
                                        <input type="text" onlyNumbers [(ngModel)]="dataForm.radius" name="wd_radius" class="form-control">
                                    </div>
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter Radius.</small>
                                </div>
                            </div>

                            <div class="map" [hidden]="!(typeView == 'map')">
                                <div id="map" #mapView class="map"></div>
                            </div>                            
                        </div>
                    </div>

                </div>
            </div>

        </form>

    </div>
</div>
