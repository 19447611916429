import { PaymentMethod } from './payment-method';
import { Customer } from 'src/app/entities/workspace/customer';
import { Employee } from 'src/app/entities/workspace/employee';
import { Payment } from 'src/app/entities/workspace/payment';

import { Document } from './document';
import { PaymentTransaction } from './payment-transaction';

export class ReportPaymentView extends Payment {
    public payment_method: PaymentMethod;
    public payments_transactions: Array<PaymentTransaction>;
    public employee: Employee;

    constructor() {
        super();
        this.payment_method = new PaymentMethod();
        this.payments_transactions = [];
        this.employee = new Employee();
    }
}
