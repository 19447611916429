<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing">
            <h3>
                Claims
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Claims
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <!-- <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-search">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                    <input name="filterSearch" [(ngModel)]="filterSearch" type="text"
                        class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form> -->
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-secondary btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div>
    </div>
    <!-- header -->


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">  
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="estimates-date-filter" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>
        <div class="row col-lg-12 col-md-12 block-filter mb-4">
            <div class="col-lg-4 col-xl-3 pl-0">
                <label class="control-label">Allocation</label>
                <select name="type_claim_id" [(ngModel)]="allocation" id="type_claim_id" class="form-control">
                    <option value="ALL">All</option>
                    <option value="IN_HOUSE">In House</option>
                    <option value="CARRIER">Carrier</option>
                </select>
            </div>
            <div class="col-lg-4 col-xl-3 pl-0">
                <label class="control-label">Status</label>
                <select name="status_id" [(ngModel)]="statusclaim" id="status_id" class="form-control">
                    <option *ngFor="let status of claimStatusArray" value="{{status}}">
                        {{status | filterReplace}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4 col-xl-3 pl-0">
                <label class="control-label">Filter Date For</label>
                <select name="prority_id" [(ngModel)]="filterDateFor" id="prority_id" class="form-control">
                    <option value="OPEN_DATE">Open Date</option>
                    <option value="MOVE_DATE">Move Date</option>
                </select>
            </div>
            <div class="col-lg-4 col-xl-3 pl-0">
                <button class="btn btn-secondary btn-search" (click)="loadClaims(true)"> Search </button>
            </div>
        </div> 
        <!--
        <div class="row col-lg-12 col-md-12 block-filter"> 
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Work Departments</label>
                <select name="work_department_id" (ngModelChange)="setWorkDepartment($event)" [(ngModel)]="workDepartmentId" id="work_department_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let workdepartment of workDepartments" value="{{workdepartment.id}}">
                        {{workdepartment.name | titlecase}}
                    </option>
                </select>
            </div>               
            <div class="col-lg-4 col-xl-3 pl-0">
                <label>Employee</label>
                <select name="employee_id" [(ngModel)]="employeeId" id="employee_id" class="form-control">
                    <option value="">All</option>
                    <option *ngFor="let employee of employeesToShow" value="{{employee.id}}">
                        {{employee.name | titlecase}}
                    </option>
                </select>
            </div>
            <div class="col-lg-4 col-xl-3 pl-0">
                <button class="btn btn-warning btn-search" (click)="getFilter()"> Search </button>
            </div>
        </div>      -->
        
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Estimate #</th>
                                <th>Customer Name</th>
                                <th>Move Date</th>
                                <th>Open Date</th>
                                <th>Allocation</th>
                                <th>Refund</th>
                                <th class="text-center">Status</th>
                                <th>Created By</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let claim of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}">
                                <td>{{claim.estimate_document.code}}</td>
                                <!-- <td>{{claim.estimate_document.code}}</td> -->
                                <td>{{claim.customer?.name | titlecase}} {{claim.customer?.last_name | titlecase}}</td>
                                <td>{{claim.move_date | date}}</td>
                                <td>{{claim.created | date}}</td>
                                <td>{{claim.job_allocation | filterReplace}}</td>
                                <td>{{claim.refunds | currency}}</td>
                                <td class="text-center">
                                    <span class="badge" [ngClass]="{'badge-info': claim.status === 'OPEN', 'badge-danger': claim.status === 'PENDING', 'badge-success': claim.status === 'RESOLVED', 'badge-warning': claim.status === 'WAITING_COSTUMER', 'badge-secondary': claim.status === 'CLOSED'}">
                                        {{claim.status}}
                                    </span>
                                </td>
                                <td>
                                    {{claim.employee.name | titlecase}} {{claim.employee.last_name | titlecase}}
                                </td>
                                <td>
                                    <button class="btn btn-info mb-2" (click)="openNewTab(claim.id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator class="paginating-container pagination-solid"
                        [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" 
                        (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>