<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <h3>
                Jobs Board
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Jobs Board
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 filtered-list-search layout-spacing align-self-center text-center">
            <!-- <app-date-filter (onChange)="getFilterDates($event)"></app-date-filter>
            <br>
            <button class="btn btn-warning" (click)="search()">
                Search
            </button> -->
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">

                <a appWorkspaceRouterLink="/board-jobs/add" class="btn btn-warning">
                    <i class="fas fa-plus-square"></i> &nbsp; New Board Job
                </a>
            </div>


        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered mb-4">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Vip</th>
                                <th>Offer</th>
                                <th>Employee</th>
                                <th>Special Conditions</th>
                                <th>created</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="rows">
                            <tr
                                *ngFor="let row of rows">
                                <td>{{row.job_code}}</td>
                                <td>{{row.vip}}</td>
                                <td>{{row.offer}}</td>
                                <td>{{row.employee}}</td>
                                <td>{{row.special_conditions}}</td>
                               
                                <td>{{row.created | date}}</td>
                               <td>
                                    <a appWorkspaceRouterLink="/board-jobs/{{row.id}}/view" class="btn btn-outline-dark">
                                        <svg _ngcontent-sqf-c18="" class="feather feather-zoom-in" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><circle _ngcontent-sqf-c18="" cx="11" cy="11" r="8"></circle><line _ngcontent-sqf-c18="" x1="21" x2="16.65" y1="21" y2="16.65"></line><line _ngcontent-sqf-c18="" x1="11" x2="11" y1="8" y2="14"></line><line _ngcontent-sqf-c18="" x1="8" x2="14" y1="11" y2="11"></line></svg>
                                    </a>
                                    <a appWorkspaceRouterLink="/board-jobs/{{row.id}}/edit" class="btn btn-outline-dark">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls> -->
                    <!-- <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator> -->
                </div>
            </div>
        </div>
    </div>
</div>
