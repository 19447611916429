export class EstimateTransportVehicles {
    public id: string;
    public estimate_id: string;
    public type: string;
    public brand: string;
    public model: string;
    public year: string;
    public vin: string;
    public car_transporter_type: 'OPEN_TRAILER' | 'ENCLOSED_TRAILER';
    public can_run: boolean;
    public price: number;
    public deposit: number;
    public created: number;

    constructor() {
        this.id = null;
        this.estimate_id = null;
        this.type = null;
        this.brand = null;
        this.model = null;
        this.year = null;
        this.vin = null;
        this.car_transporter_type = 'OPEN_TRAILER';
        this.can_run = null;
        this.price = null;
        this.deposit = null;
        this.created = null;
    }
}
