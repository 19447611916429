import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Employee } from 'src/app/entities/workspace/employee';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { LeadCompanyView } from 'src/app/entities/workspace/lead-company-view';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { AuthService } from 'src/app/services/auth.service';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeadsService } from 'src/app/services/leads.service';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { EmployeesCompanyService } from 'src/app/services/companies/employees-company.service';
import { LeadNote } from 'src/app/entities/workspace/lead-note';
import { BroadcastChannelService } from 'src/app/services/broadcast-channel.service';
import { PhoneExtensionsService } from 'src/app/services/companies/phone-extensions.service';
import { ClientSocketsService } from 'src/app/services/client-sockets.service';
import { SmsTemplate } from 'src/app/entities/workspace/sms-template';
import { SmsTemplatesService } from 'src/app/services/workspaces/sms-templates.service';
import { EstimateEmail } from 'src/app/entities/workspace/estimate-email';
import { EmailPerson } from 'src/app/entities/workspace/email-person';
import { EmailTemplate } from 'src/app/entities/workspace/email-template';
import { EstimatesEmailTemplatesService } from 'src/app/services/companies/estimates-email-templates.service';


declare const google: any;
declare const jQuery: any;
declare const moment: any;
declare const swal: any;;
@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {

  private map;
  public actualPage: number;
  public agent_lead_mode: string;
  public betweenFilter: BetweenDates;
  /*
  Constantes que contiene el tipo de mensaje a mostrar
  */
  public constantsMessages = ConstantsMessages;
  public currentPage: number;
  public customerSms: any;
  public dataFormPhone: any;
  public deadLeadSelected: string;
  public employees: Employee[];
  public employeeSelected: string;
  public filterAgent: string;
  public filterDelivery: string;
  public filterPickup: string;
  public filterSearch: string;
  public massAssignEmployee: string;
  public numberSms: any;
  public quickAssignEmployee: string;
  /*
  Variable que realiza el filtro del select
  */

  emailTemplates: Array<EmailTemplate>;
  public disableButton: boolean;
  public estimateEmail: EstimateEmail;
  public extensions: any[];
  public filterSelect: string;
  public followupsremarks: LeadNote;
  public fromEmployeeSelected: string;
  public generalSettings: GeneralSettings;
  public idDeadLead: string;
  public infowindow;
  public isEditableCategory: boolean;
  public isVisibleCategory: boolean;
  public itemsPerPage: number;
  public lead: string;
  public leadId: string;
  public leadSelected: string;
  public massAssign: boolean;
  public miles: string;
  public newCategory: string;
  public paginator: Paginator;
  public quickAssign: boolean;
  public rowsQuantity: number;
  public searchByDates: boolean;
  public selectDateType: string;
  public smsTemplates: Array<SmsTemplate>;
  public status: string;
  public toEmployeeSelected: string;
  public leadCompanyId: string;



  @ViewChild('deadLeadModal') deadLeadModal: ElementRef;
  @ViewChild('mapView') mapView: ElementRef;
  @ViewChild('modalChangeCategory') modalChangeCategory: ElementRef;
  @ViewChild('modalEmployees') modalEmployees: ElementRef;
  @ViewChild('modalFollowupsremarks') modalFollowupsremarks: ElementRef;
  @ViewChild('modalQuickAssign') modalQuickAssign: ElementRef;
  @ViewChild('modalSendTextMessage') modalSendTextMessage: ElementRef;
  @ViewChild('composeMailModal') composerModal: ElementRef;

  public btnActived: 'ALL' | 'NEW' | 'HOT' | 'WARM' | 'COLD' | 'BAD' | 'UNASSIGNED' | 'OPPORTUNITY';

  constructor(
    private authService: AuthService,
    private employeesService: EmployeesCompanyService,
    private estimatesService: EstimatesService,
    private helperService: HelperService,
    private leadsService: LeadsService,
    private setting: GeneralSettingsService,
    private channelService: BroadcastChannelService,
    private phoneExtensionsService: PhoneExtensionsService,
    private clientSocketsService: ClientSocketsService,
    private smsTemplatesService: SmsTemplatesService,
    private estimatesEmailTemplatesService: EstimatesEmailTemplatesService,

  ) {
    this.actualPage = 1;
    this.agent_lead_mode = '';
    this.betweenFilter = new BetweenDates();
    this.betweenFilter.dateStart = moment().subtract(1, 'month').second(0).minute(0).hour(0).unix() * 1000;
    this.btnActived = 'ALL';
    this.currentPage = 1;
    this.deadLeadSelected = 'NOT_MOVING';
    this.employeeSelected = '';
    this.extensions = [];
    this.filterAgent = '';
    this.filterDelivery = '';
    this.filterPickup = '';
    this.filterSearch = '';
    this.filterSelect = 'NEW';
    this.followupsremarks = new LeadNote();
    this.fromEmployeeSelected = '';
    this.generalSettings = new GeneralSettings();
    this.idDeadLead = '';
    this.itemsPerPage = 25;
    this.lead = '';
    this.leadSelected = '';
    this.massAssign = false;
    this.massAssignEmployee = '';
    this.miles = '';
    this.paginator = new Paginator();
    this.paginator.paginator.order_type = 'DESC';
    this.quickAssign = false;
    this.quickAssignEmployee = '';
    this.rowsQuantity = 1000;
    this.searchByDates = true;
    this.selectDateType = 'OPEN';
    this.status = 'NEW';
    this.toEmployeeSelected = '';
    this.dataFormPhone = {
      phoneNumberCustomer: '',
      phoneNumberEmployee: '',
      message: ''
    };
    this.customerSms = '';
    this.numberSms = '';
    this.smsTemplates = [];
    this.estimateEmail = new EstimateEmail();
    this.disableButton = false;
    this.emailTemplates = [];
    this.leadCompanyId = '';



  }
  ngOnInit(): void {
    this.loadExtensions();
    this.loadSmsTemplate();


  }

  ngAfterViewInit(): void {
    this.load();
    this.loadCompanySettings();
    this.employeesService.getAllForLeadAssignment().then((response) => {
      this.employees = response;
    });
  }




  assignTo(leadId) {
    this.leadId = leadId;
    if (!this.quickAssign) {
      jQuery(this.modalEmployees.nativeElement).modal('show');
    } else {
      if (this.quickAssignEmployee == '') {
        this.helperService.showMessageSnackbar('Please select an employee to quick assignment', 'ERROR');
      } else {
        this.employeeSelected = this.quickAssignEmployee;
        this.toquickAssign();
      }
    }
  }

  public load() {
    this.helperService.showLoadingMxpro360();

    const data = {
      'full-view': 1,
      agent: this.filterAgent,
      category: this.btnActived,
      date_end: this.betweenFilter.dateEnd,
      date_start: this.betweenFilter.dateStart,
      date_type: this.selectDateType,
      delivery: this.filterDelivery,
      lead: this.lead,
      pickup: this.filterPickup,
      status: this.filterSelect
    };

    this.paginator.paginator.order_by = this.selectDateType == 'LAST_UPDATED' ? 'updated' : 'created';

    this.leadsService
      .getAllWithFullView(data, this.paginator)
      .then((response) => {
        this.paginator.data = response.data;
        console.log('this.paginator.data ==> ', this.paginator.data);

        this.paginator.paginator.total = response.paginator.total;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  remove(id) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.leadsService
            .remove(id)
            .then((response) => {
              this.load();
              swal(
                'Deleted!',
                'The record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('Error: ', error);
              this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_DELETED);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }
  copyText(text: string, type: string, opportunityId: string) {

    if (opportunityId !== null) {
      return;
    }

    if (type === 'phone') {
      text = text.replace(/-/g, '');
      text = text.substr(-10, 10);
    }

    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (text));
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.helperService.showMessageSnackbar(this.constantsMessages.COPIED);
  }

  toquickAssign() {
    jQuery(this.modalEmployees.nativeElement).modal('hide');
    this.helperService.showLoadingMxpro360();
    this.estimatesService
      .toAssignLead(this.leadId, this.employeeSelected)
      .then(() => {
        this.helperService.showLoadingMxpro360();
        swal(
          'Done',
          'The record has been updated.',
          'success'
        );
        this.employeeSelected = '';
        this.load();
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  toAssign(selectedLeadId) {
    jQuery(this.modalEmployees.nativeElement).modal('hide');
    this.helperService.showLoadingMxpro360();
    this.estimatesService
      .toAssignLead(selectedLeadId, this.employeeSelected)
      .then(() => {
        this.helperService.showLoadingMxpro360();
        swal(
          'Done',
          'The record has been updated.',
          'success'
        );
        this.employeeSelected = '';
        this.load();
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  goEstimate(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimatesService
      .getEstimateById(id)
      .then((response) => {
        if (response.message === 'The registration was not found') {
          this.toEstimate(id);
        } else {
          swal(
            'Done',
            'The record has already been created.',
            'success'
          );
        }
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  toEstimate(id: string) {
    this.helperService.showLoadingMxpro360();
    this.estimatesService
      .toCreateByLead(id)
      .then((response) => {
        swal(
          'Done',
          'The record has been created .',
          'success'
        );
        this.load();
        this.helperService.openNewTab(response['row_id'], 'estimate');
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  changeCategory(id: string, category: string) {
    jQuery(this.modalChangeCategory.nativeElement).modal('hide');
    this.helperService.showLoadingMxpro360();
    this.leadsService
      .changeCategory(id, category)
      .then(() => {
        this.helperService.showLoadingMxpro360();
        jQuery(this.deadLeadModal.nativeElement).modal('hide');
        swal(
          'Done',
          'Category Updated',
          'success'
        );
        this.load();
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  deadLead() {
    this.leadsService
      .deadLead(this.idDeadLead, this.deadLeadSelected)
      .then((response) => {
        this.helperService.showLoadingMxpro360();
        jQuery(this.deadLeadModal.nativeElement).modal('hide');
        swal(
          'Done',
          'Blocked lead',
          'success'
        );
        this.load();

      })
      .catch((error) => {
        console.error('Error: ', error);
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  notContacted(lead) {
    lead.status = 'NEW';
    lead.cagegory = 'COLD';
    this.leadsService
      .save(lead)
      .then((response) => {
        this.helperService.showLoadingMxpro360();
        swal(
          'Done',
          'Lead Not Contacted',
          'success'
        );
        this.load();

      })
      .catch((error) => {
        console.error('Error: ', error);
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  modalDeadLead(id) {
    this.idDeadLead = id;
    jQuery(this.deadLeadModal.nativeElement).modal('show');
  }

  private calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints): Promise<any> {

    return new Promise((resolve) => {
      directionsService.route({
        origin: pointA,
        destination: pointB,
        waypoints,
        travelMode: google.maps.TravelMode.DRIVING
      }, (response, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          directionsDisplay.setOptions({ suppressMarkers: true });
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  /**
   * Obtiene corrdenadas de un zipcode
   *
   * @param zipcode Zipcode a buscar
   */
  private getLatLang(zipcode) {
    return new Promise((resolve, reject) => {
      let lat: string;
      let lng: string;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: 'zipcode ' + zipcode }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          lat = results[0].geometry.location.lat();
          lng = results[0].geometry.location.lng();
          resolve({ lat, lng });
        } else {
          reject('Request failed.');
        }
      });
    });
  }
  public async initializeMap(leadCompany: LeadCompanyView): Promise<void> {




    const lead = leadCompany.lead;
    const THIS = this;

    jQuery.get("https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=" + lead.from_address.zip_code + "&destinations=" + lead.to_address.zip_code + "&key=AIzaSyD1aoQGItxQX8_S-5fIXlTUbvNcv7rpFl8", function (data) {
      THIS.miles = '' + data.rows[0].elements[0].distance.text;
    });

    // Punto inicial del recorrido
    let pointA;
    // Punto final del recorrido
    let pointB;

    // Se valida que existan coordenadas en el geospatial donde se indica que el usuario ha guardado sus direcciones
    if (lead.from_address.geospatial.coordinates.length > 0) {
      pointA = new google.maps.LatLng(lead.from_address.geospatial.coordinates[1],
        lead.from_address.geospatial.coordinates[0]);
      pointB = new google.maps.LatLng(lead.to_address.geospatial.coordinates[1],
        lead.to_address.geospatial.coordinates[0]);
    } else {
      // Si no hay direcciones guardadas, se busca por el zipcode guardado para indicar el estado de la route delivery
      let pointsPickup;
      let pointsDelivery;
      pointsPickup = await this.getLatLang(lead.from_address.zip_code);
      pointsDelivery = await this.getLatLang(lead.to_address.zip_code);
      pointA = new google.maps.LatLng(pointsPickup.lat, pointsPickup.lng);
      pointB = new google.maps.LatLng(pointsDelivery.lat, pointsDelivery.lng);
    }

    this.map = new google.maps.Map(
      this.mapView.nativeElement as HTMLElement,
      {
        zoom: 3,
        center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true
      });

    // Instantiate a directions service.
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({
      map: this.map
    });
    // Se crean los marcadores
    const markerA = new google.maps.Marker({
      position: pointA,
      map: this.map
    });

    const markerB = new google.maps.Marker({
      position: pointB,
      map: this.map
    });

    // Se inicializa el arreglo que dibuja la ruta en el mapa de acuerdo a los puntos
    // establecidos por los puntos de parada
    const waypoints = [];
    let markerStopPoint;

    // Se obtiene la ruta entre los puntos inciales, de parada y finales
    await this.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints);

    let address = '';
    let addressFrom = '';
    let addressTo = '';

    // Se obtienen las direcciones de recogida y entrega
    addressFrom = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');
    addressTo = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');

    // Se crea la informacion de las direcciones para mostrarlas en el mapa
    const card = document.createElement('div');
    const container = document.createElement('div');
    const titleFrom = document.createTextNode('* From: ');
    const brTitleFrom = document.createElement('br');
    const textFrom = document.createTextNode('Street: ' + addressFrom[0] + ', City: ' +
      addressFrom[1] + ', State: ' + addressFrom[2]);

    const titleTo = document.createTextNode('* To: ');
    const brTitleTo = document.createElement('br');
    const textTo = document.createTextNode('Street: ' + addressTo[0] + ', City: ' +
      addressTo[1] + ', State: ' + addressTo[2]);

    card.setAttribute('id', 'pac-card');
    container.setAttribute('id', 'pac-container');
    container.appendChild(titleFrom);
    container.appendChild(brTitleFrom);
    container.appendChild(textFrom);

    container.appendChild(document.createElement('br'));
    // Se verifica si hay puntos de parada agregados para ser dibujados y dibujar la ruta

    container.appendChild(document.createElement('br'));
    container.appendChild(document.createElement('br'));
    container.appendChild(titleTo);
    container.appendChild(brTitleTo);
    container.appendChild(textTo);
    card.appendChild(container);
    this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(card);

    // Se valida si hay un infowindow abierto, este sea cerrado
    function showAddresses() {
      if (THIS.infowindow) {
        THIS.infowindow.close();
      }
    }

    showAddresses();
    // Evento del click en el mapa
    THIS.map.addListener('click', (e) => {
      showAddresses();
    });

    google.maps.event.addListener(markerA, 'click', async () => {
      address = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');

      if (THIS.infowindow) {
        THIS.infowindow.close();
      }

      // Se dibuja en el infowindow la informacion
      const contentString =
        '<div id="content">' +
        '<b class="text-center">FROM</b>' +
        '<p> Street: ' + address[0] + '</p>' +
        '<p> City: ' + address[1] + '</p>' +
        '<p> State: ' + address[2] + '</p>' +
        '<p> Country: ' + address[3] + '</p>' +
        '</div>';

      THIS.infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      THIS.infowindow.setPosition({
        lat: pointA.lat(),
        lng: pointA.lng()
      });
      THIS.infowindow.open(THIS.map);
    });

    google.maps.event.addListener(markerB, 'click', async () => {
      address = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');
      if (THIS.infowindow) {
        THIS.infowindow.close();
      }
      const contentString =
        '<div id="content">' +
        '<b class="text-center">TO</b>' +
        '<p> Street: ' + address[0] + '</p>' +
        '<p> City: ' + address[1] + '</p>' +
        '<p> State: ' + address[2] + '</p>' +
        '<p> Country: ' + address[3] + '</p>' +
        '</div>';

      THIS.infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      THIS.infowindow.setPosition({
        lat: pointB.lat(),
        lng: pointB.lng()
      });
      THIS.infowindow.open(THIS.map);
    });

  }

  setCurrentPage(currentpage: number): void {
    if (this.paginator.paginator.page != currentpage) {
      this.paginator.paginator.page = currentpage;
      this.load();
    }
  }

  loadCompanySettings() {
    this.helperService.showLoadingMxpro360();
    this.isVisibleCategory = false;
    this.isEditableCategory = false;
    this.setting
      .get()
      .then((response) => {

        this.agent_lead_mode = response.settings.marketing_setting.agent_lead_mode;
        this.generalSettings = response;

        this.isVisibleCategory = this.generalSettings.settings.marketing_setting.agent_lead_mode === 'POOL';

        // VALIDAMOS QUE EL USUARIO LOGUEADO TENGA LOS PERMISOS PARA ASIGNAR LEADS
        if (this.authService.hasPermissionCompany('ASSIGN_LEADS')) {
          this.isEditableCategory = true;
        }
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  onChangeDateRange(arrayDates) {
    if (arrayDates[1] > 0 && arrayDates[0] > 0) {
      this.betweenFilter.dateStart = arrayDates[0];
      this.betweenFilter.dateEnd = arrayDates[1];
    }
  }

  openModalChangeCategory(leadId: string) {
    this.leadSelected = leadId;
    jQuery(this.modalChangeCategory.nativeElement).modal('show');
  }

  filterByCategory(btnActive: 'ALL' | 'NEW' | 'HOT' | 'WARM' | 'COLD' | 'BAD' | 'UNASSIGNED' | 'OPPORTUNITY') {
    this.btnActived = btnActive;
    this.load();
  }

  addFollowUps(leadId: string) {
    this.followupsremarks = new LeadNote();
    this.followupsremarks.company_lead_id = leadId;
    jQuery(this.modalFollowupsremarks.nativeElement).modal('show');
  }
  addfollowupsremarks(leadId: string) {
    this.helperService.showLoadingMxpro360();
    this.leadsService
      .addNote(this.followupsremarks)
      .then((response) => {
        this.load();
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  toggleQuickAssign() {
    this.quickAssign = !this.quickAssign;
    this.quickAssignEmployee = '';
  }

  toggleMassAssign() {
    this.massAssign = !this.massAssign;
    this.massAssignEmployee = '';
  }
  openQuickAssignModal() {
    this.fromEmployeeSelected = '';
    this.toEmployeeSelected = '';
    jQuery(this.modalQuickAssign.nativeElement).modal('show');
  }


  reAssignEmployee() {
    this.helperService.showLoadingMxpro360();
    this.leadsService.reAssignLead(this.fromEmployeeSelected, this.toEmployeeSelected, this.authService.workspaceSession.company.id)
      .then((response: any) => {
        this.helperService.showMessageSnackbar(response.message, 'SUCESS');
        this.load();
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  openModalSendTextMessage() {
    jQuery(this.modalSendTextMessage.nativeElement).modal('show');
  }

  changeSmsTemplate(tmp) {
    console.log('tmp holis tmp holis', tmp);

    this.dataFormPhone.message = tmp;
  }

  private loadSmsTemplate() {

    this.smsTemplatesService
      .getAll()
      .then((response) => {
        this.smsTemplates = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  };

  public destinationNumber(customer) {


    this.customerSms = customer.lead.customer.name + ' ' + customer.lead.customer.last_name;
    this.dataFormPhone.phoneNumberCustomer = this.numberSms;
    this.leadCompanyId = customer.id;
    this.numberSms = customer.lead.customer.phone;

  }

  sendMessage() {

    if (!this.clientSocketsService.isConnected()) {
      return;
    }

    console.log('this.dataFormPhone', this.dataFormPhone);

    const form = {
      "to": [this.dataFormPhone.phoneNumberCustomer],
      "from": this.dataFormPhone.phoneNumberEmployee,
      "text": this.dataFormPhone.message,
      "applicationId": "dad36d15-88e5-4ca9-9407-c22193d6cafb",
      "tag": "test message",
      "priority": "default"
    }


    const dataForm = { workspace_id: this.authService.workspaceSession.workspace.id, form: form, leadCompanyId:  this.leadCompanyId };

    let that = this;
    const callBack = function (response) {
      swal(
        'Done!',
        'The SMS text has been sent successfully',
        'success'
      );
    }

    this.clientSocketsService.getSocket().emit("sms:send", dataForm, callBack);

    this.dataFormPhone = {
      phoneNumberEmployee: '',
      phoneNumberCustomer: '',
      message: ''
    }
  }


  private loadExtensions() {

    this.helperService.showLoadingMxpro360();
    this.phoneExtensionsService
      .getExtensionsSms()
      .then((response) => {

        this.extensions = response;

        console.log('this.extensions', this.extensions);


      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });



  };

  toCall(customer) {
    this.channelService.activeChannelPhoneHide(customer.phone);

    setTimeout(() => {
      this.channelService.emitSmsRead(false);
    }, 1000);

    //this.message = [];

    this.helperService.showLoadingMxpro360();

    // construirmos la url para usar posteriormente
    const urlComplete = window.origin + "/#" + this.helperService.buildCompanyRouterLink("/dialer");

    /**
     * AQUI VAMOS A EMPLEAR UNA ESTRATEGIA QUE NOS PERMITA VERIFICAR
     * SI LA PESTAÑA DEL DIALER QUE SE ESTA SOLICITANDO YA ESTA ABIERTA,
     * SI YA ESTA ABIERTA, EL NAVEGADOR DEBE PONER ESA PESTAÑA COMO ACTIVA,
     * DE LO CONTRARIO SE ABRE UNA PESTAÑA NUEVA
     */

    // obtenemos las ulrs abiertas guardadas del localstorage
    const storageDialerURLS = localStorage.getItem('DIALER_URLS');
    let dialerURLS = [];


    if (storageDialerURLS !== null) {
      dialerURLS = JSON.parse(storageDialerURLS) as Array<string>;
    }

    let isFound = false;

    // recorremos las urls almacenadas
    for (const url of dialerURLS) {
      if (url === urlComplete) {
        isFound = true;
        break;
      }
    }

    // creamos un broadcastchannel con las pestañas abiertas de app.moverxpro360.com
    const bccDIALER = new BroadcastChannel('DIALER');

    // creamos un nombre para la ventana
    const dialerWindowName = "dialer_" + this.authService.workspaceSession.company.id;

    // verificamos si ya ha sido abierta
    if (isFound) {

      // creamos un identificador aleatorio para que no se vaya a presentar errores
      const customID = Math.floor(Math.random() * 9999);

      // emitimos una solicitud a la pestaña que esta abierta para la url que se solicitó se active
      bccDIALER.postMessage({ action: 'REQUEST_ACTIVATE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });

      // escuchamos mensajes que se envien desde otras pestañas (tab) del mismo origen: app.moverxpro360.com
      bccDIALER.onmessage = (event) => {

        const data = event.data as { action: string, url: string, name: string, requestID: number, isActive: boolean, hasValue: boolean, };

        // verificamos si la accion es la respuesta a la solicitud arriba
        if (data.action == "RESPONSE_ACTIVATE_TAB" && data.url == urlComplete) {

          // si devuelve que es activo no se hace nada mas.. pero si no es activo, cerramos la pestaña, y abrimos una nueva
          if (data.isActive) {
            this.helperService.hideLoadingMxpro360();
          } else {

            // enviamos una solicitud a las otras pestañas para verificar si la pestaña que estaba abierta del dialer, esta abierta correctamente
            bccDIALER.postMessage({ action: 'REQUEST_TAB_VERIFICATION', url: urlComplete, name: dialerWindowName, requestID: customID });
          }
        }

        // verificamos si la accion es la respuesta a la solicitud arriba
        if (data.action == "RESPONSE_TAB_VERIFICATION" && data.url == urlComplete && data.requestID == customID) {

          // si tiene valor es porque fue abierto correctamente, entonces abrimos la pestaña con enlace
          if (data.hasValue) {

            // como ya se cerro la otra pestaña, abrimos una nueva pestaña
            setTimeout(() => {
              const popup = window.open(urlComplete, dialerWindowName);
              popup.focus();

              this.helperService.hideLoadingMxpro360();
            }, 1000);

          } else {

            // solicitamos que se cierre la otra pestaña
            // bccDIALER.postMessage({ action: 'REQUEST_CLOSE_TAB', url: urlComplete, name: dialerWindowName, requestID: customID });

            // como no se puede cerrar la otra pestaña en la mayoria de los casos, esto por temas de seguridad en los navegadores, entonces vamos a indicar que ya hay una pestaña abierta
            alert("There is currently a browser tab open for this link");
            this.helperService.hideLoadingMxpro360();
          }


        }
      }
    } else {

      // como no hay pestañas abiertas con la misma url, abrimos una nueva pestaña
      const popup = window.open(urlComplete, dialerWindowName);
      popup.focus();

      this.helperService.hideLoadingMxpro360();
    }
  }



  openModalEmail(customer) {
    const emailPerson = new EmailPerson();
    emailPerson.email = customer.email;
    emailPerson.name = customer.name;
    this.estimateEmail = new EstimateEmail();
    this.estimateEmail.to.push(emailPerson);
    jQuery(this.composerModal.nativeElement).modal('show');
  }


  loadEmailTemplates() {
    const data = {
      service: null
    };

    this.estimatesEmailTemplatesService
      .getAll(data)
      .then((response) => {
        this.emailTemplates = response;
      });
  }


  /**
   * Permite establecer una plantilla al correo
   */
  async setTemplate(event) {

    const select = event.target;
    const index = select.options[select.selectedIndex].value;

    this.estimateEmail.subject = '';
    this.estimateEmail.content = '';

    if (index !== '-1') {

      const emailTemplate = this.emailTemplates[index];
      this.estimateEmail.subject = emailTemplate.subject;

      this.estimateEmail.content = emailTemplate.body;
      this.estimateEmail.attach_inventory = emailTemplate.attach_inventory;
      this.estimateEmail.request_esignature = emailTemplate.request_esignature;
      this.estimateEmail.request_poa = emailTemplate.request_poa;
      this.estimateEmail.request_inventory_update = emailTemplate.request_inventory_update;
      this.estimateEmail.request_bol = emailTemplate.request_bol;
      this.estimateEmail.bol_signature_requested = emailTemplate.bol_signature_requested;
      this.estimateEmail.request_token = emailTemplate.request_token;
    }

  }


  /**
   * Metodo que valida si se puede enviar un email
   */
  public validateSendEmail(): void {
    // Se inicializa la variable
    let enableButton = false;
    console.log(this.estimateEmail);


    // Se recorren los campos creados para los correos destinatarios
    for (const iterator of this.estimateEmail.to) {
      // Valida si los campos de nombre y email se encuentan con datos
      if ((iterator.name === null || iterator.name === '') &&
        (iterator.email === null || iterator.email === '')) {

        enableButton = true;
        break;
      } else {
        enableButton = false;
      }
    }

    if (this.estimateEmail.subject === '') {
      enableButton = true;
    }

    this.estimateEmail.content = this.estimateEmail.content ? this.estimateEmail.content : '';

    if (enableButton) {
      this.disableButton = enableButton;
      swal(
        'Please',
        'Complete the information!',
        'warning'
      );
    } else {
      this.sendEmail();
    }

  }


  /**
   * Permite enviar el email elaborado
   */
  sendEmail() {
    // this.googleAuthService.refresAuthSessions();
    this.leadsService
      .sendEmail(this.estimateEmail)
      .then((response) => {
        // notificamos que se envió
        swal(
          'Done!',
          'The Email has been sent successfully',
          'success'
        );

        // cerramos
        jQuery(this.composerModal.nativeElement).modal('hide');
        // this.resetFormComposer()
        // if (this.emailTemplates.length > 0) {
        //     this.dataForm.content = this.emailTemplates[0].body;
        //     this.dataForm.subject = this.emailTemplates[0].subject;
        // }
        this.loadEmailTemplates();
        // volvemos a cargar los emails, junto con el que se acabo de enviar
        // this.load(this.estimate.id);
      })
      .catch((error) => {
        if (error.status == 422) {
          swal(
            'ups!',
            error.error.message,
            'error'
          );
        } else {
          console.error('error email', error);
          swal(
            'ups!',
            'An error has occurred',
            'error'
          );
        }

      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  /**
   * Permite cerrar la modal del fomrulario de correo
   */
  toCancelCompose() {
    jQuery(this.composerModal.nativeElement).modal('hide');
  }

}
