import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LiabilityDeductibleRateView } from 'src/app/entities/workspace/liability-deductible-rate-view';
import { environment } from 'src/environments/environment';



@Injectable({
    providedIn: 'root'
})
export class LiabilityDeductibleRatesService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<LiabilityDeductibleRateView>> {
        return this.http.get<Array<LiabilityDeductibleRateView>>(environment.api.workspace + '/liability-deductible-rates').toPromise();
    }

    save(data: Array<LiabilityDeductibleRateView>) {
        return this.http.post(environment.api.workspace + '/liability-deductible-rates', data).toPromise();
    }

}
