<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="!dataFormPayrollSettings.id"> New </span> 
                <span *ngIf="dataFormPayrollSettings.id"> Update </span> 
                Employee Payroll
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item" aria-current="page">
                        <a appCompanyRouterLink="/payroll-settings">Payroll Settings</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="true">New</li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="false">Update</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-6 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center align-self-center mb-3">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <button class="btn btn-success mr-2" (click)="openNewRuleModal(null)"  *ngIf="dataFormPayrollSettings.id">
                    <i class="fas fa-plus-square mr-2"></i>
                    Add New Rule
                </button>
                <button class="btn btn-main-action" (click)="savePayrollSettings()">
                    Save
                </button>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="row justify-content-between justify-content-center mb-4">
                    <div class="col-12">

                        <div class="row">

                            <div class="col-5 form-group mb-4">
                                <label class="control-label">Employee</label>
                                <select [(ngModel)]="dataFormPayrollSettings.employee_id" name="wd_employees_id" class="form-control" [disabled]="dataFormPayrollSettings.id">
                                    <option *ngFor="let employe of employees" value="{{employe.id}}">
                                        {{employe.name | titlecase}}
                                    </option>
                                </select>
                            </div>                          

                            <div class="col-2 form-group mb-3">
                                <label for="hourly_rate">Base Amount</label>
                                <div class="input-group mb-1">
                                    <input type="number" class="form-control" name="hourly_rate" [(ngModel)]="dataFormPayrollSettings.base_per_week">
                                </div>
                            </div>
    
                            <div class="col-5 form-group mb-3">

                                <label for="" class="mb-3">Base Options</label>

                                <div class="d-flex">
                                    <div class="n-chk mr-2">
                                        <label class="new-control new-radio radio-success">
                                            <input type="radio" class="new-control-input" name="marker" value="bg-warning" [(ngModel)]="dataFormPayrollSettings.pay_mode" value="HOURLY">
                                            <div class="new-control-indicator"></div> Hours
                                        </label>
                                    </div>

                                    <div class="n-chk mr-2">
                                        <label class="new-control new-radio radio-success">
                                            <input type="radio" class="new-control-input" name="marker" value="bg-warning" [(ngModel)]="dataFormPayrollSettings.pay_mode" value="WEEKLY">
                                            <div class="new-control-indicator"></div> Week
                                        </label>
                                    </div>

                                    <div class="form-group">
                                        <div class="toggle-switch m-0">                
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="vs_commission" name="vs_commission" [(ngModel)]="dataFormPayrollSettings.vs_commission">
                                                <span class="slider round">
                                                    <i class="fa-solid fa-check icon-left"></i>
                                                    <i class="fa-solid fa-xmark icon-right"></i>
                                                </span>
                                            </label>
                                            <label for="vs_commission" class="checkbox-label" [ngClass]="{'filter-disabled': !dataFormPayrollSettings.vs_commission}">
                                                Vs Commission
                                            </label>
                                        </div>
                                    </div>   

                                </div>        

                            </div>                       
                        
                        </div>

                    </div>
                </div>

                <div class="table-responsive" *ngIf="dataFormPayrollSettings.id">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Job Type</th>
                                <th>Value</th>
                                <th>Based On</th>
                                <th>Sold By</th>
                                <th>Condition</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let commissionSetting of commissionSettings" >
                                <td>{{commissionSetting.job_type | filterReplace}}</td>
                                <td>
                                    <ng-container *ngIf="commissionSetting.type != 'PERCENTAGE'">
                                        $
                                    </ng-container>
                                    {{commissionSetting.value}}
                                    <ng-container *ngIf="commissionSetting.type == 'PERCENTAGE'">
                                        %
                                    </ng-container>
                                    </td>
                                <td>{{commissionSetting.value_base_on | filterReplace}}</td>
                                <ng-container *ngIf="commissionSetting.sold_by == 'EMPLOYEE'">
                                    <td>
                                        <ng-container  *ngFor="let employe1 of employees">
                                                <ng-container *ngIf="employe1.id == commissionSetting.sold_by_employee_id">
                                                    {{employe1.name | titlecase}}
                                                </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="commissionSetting.sold_by == 'WORK_DEPARTMENT'">
                                    <td>
                                        <ng-container *ngFor="let workDepartment1 of work_departments">
                                                <ng-container *ngIf="workDepartment1.id == commissionSetting.sold_by_work_department_id">
                                                    {{workDepartment1.name | titlecase}}
                                                </ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <td *ngIf="commissionSetting.condition">{{commissionSetting.condition.type}}</td>
                                <td *ngIf="!commissionSetting.condition"></td>
                                <td>
                                    <button class="btn btn-success button-space"  (click)="openNewRuleModal(commissionSetting)">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </button>
                                    <button class="btn btn-danger button-space"  (click)="deleteComissionSetting(commissionSetting.id)">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>


<!-- Modal - New Employee to Payroll -->
<div class="modal fade" #newRuleModal id="newRuleModal" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    New Commission Rules
                </h5>
            </div>
            <div class="modal-body">
                
                <div class="row">

                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Type</label>
                        <select id="type" [(ngModel)]="dataForm.type" name="pg_type" class="form-control">
                            <option value="PERCENTAGE">Percentage</option>
                            <option value="FIXED">Fixed Value</option>
                        </select>
                        <small id="help1" class="form-text text-muted">Select the Commission Type.</small>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Value</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>
                            </div>
                            <input type="number" step="0.01" [(ngModel)]="dataForm.value" name="wd_value" class="form-control">
                        </div>        
                        <small id="help1" class="form-text text-muted">Enter the Commission Value.</small>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Value Based On</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                </span>
                            </div>
                            <select [(ngModel)]="dataForm.value_base_on" name="wd_value_base_on" class="form-control">
                                <option value="ADDITIONAL_BOL"> Additional BOL </option>
                                <option value="ADDITIONAL_STOP_FEE"> Additional Stop Fee </option>
                                <option value="BINDING_FEE"> Binding Fee </option>
                                <option value="DISCOUNT"> Discount </option>
                                <option value="DISCOUNT_FULL_PACKING_SERVICE"> Discount Full Packing Service </option>
                                <option value="DISCOUNT_PER_MILE"> Discount Per Mile </option>
                                <option value="ELEVATOR_DELIVERY"> Elevator Delivery </option>
                                <option value="ELEVATOR_PICKUP"> Elevator Pickup </option>
                                <option value="FULL_PACKING_SERVICE"> Full Packing Service </option>
                                <option value="JOB_DEPOSIT">Job Deposit </option>
                                <option value="JOB_TOTAL"> Job Total </option>
                                <option value="JOB_SUB_TOTAL"> Job Sub Total </option>
                                <option value="LONG_CARRY_DELIVERY"> Long Carry Delivery </option>
                                <option value="LONG_CARRY_PICKUP"> Long Carry Pickup </option>
                                <option value="SHUTTLE_DELIVERY"> Shuttle Delivery </option>
                                <option value="SHUTTLE_PICKUP"> Shuttle Pickup </option>
                                <option value="SPLIT_DELIVERY"> Split Delivery </option>
                                <option value="SPLIT_PICKUP"> Split Pickup </option>
                                <option value="TOTAL_BOL"> Total BOL </option>
                                <option value="TOTAL_BOL_AFTER_DISCOUNTS"> Total BOL After Discounts </option>
                            </select>
                        </div>        
                        <small id="emailHelp1" class="form-text text-muted">Select an Option.</small>
                    </div>
                    
                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Filter For</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                                </span>
                            </div>
                            <select [(ngModel)]="dataForm.filter_for" name="wd_filter_for" class="form-control">
                                <option value="ADDITIONAL_BOL"> Additional BOL </option>
                                <option value="ADDITIONAL_STOP_FEE"> Additional Stop Fee </option>
                                <option value="BINDING_FEE"> Binding Fee </option>
                                <option value="DISCOUNT"> Discount </option>
                                <option value="DISCOUNT_FULL_PACKING_SERVICE"> Discount Full Packing Service </option>
                                <option value="DISCOUNT_PER_MILE"> Discount Per Mile </option>
                                <option value="ELEVATOR_DELIVERY"> Elevator Delivery </option>
                                <option value="ELEVATOR_PICKUP"> Elevator Pickup </option>
                                <option value="FULL_PACKING_SERVICE"> Full Packing Service </option>
                                <option value="JOB_DEPOSIT">Job Deposit </option>
                                <option value="JOB_TOTAL"> Job Total </option>
                                <option value="JOB_SUB_TOTAL"> Job Sub Total </option>
                                <option value="LONG_CARRY_DELIVERY"> Long Carry Delivery </option>
                                <option value="LONG_CARRY_PICKUP"> Long Carry Pickup </option>
                                <option value="SHUTTLE_DELIVERY"> Shuttle Delivery </option>
                                <option value="SHUTTLE_PICKUP"> Shuttle Pickup </option>
                                <option value="SPLIT_DELIVERY"> Split Delivery </option>
                                <option value="SPLIT_PICKUP"> Split Pickup </option>
                                <option value="TOTAL_BOL"> Total BOL </option>
                            </select>
                        </div>        
                        <small id="emailHelp1" class="form-text text-muted">Select an Option.</small>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Sold By Name</label>
                        <select id="inputState" [(ngModel)]="dataForm.sold_by" (change)="changeSoldBy()" name="pg_sold_by_name" class="form-control">
                            <option value="EMPLOYEE">Employee</option>
                            <option value="WORK_DEPARTMENT">Work Department</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted">Select an Option.</small>
                    </div>

                    <div class="col-6 form-group mb-3 select2-w100" *ngIf="dataForm.sold_by == 'EMPLOYEE'">
                        <label class="control-label">Sold By Employee</label>
                        <select id="inputState" [(ngModel)]="dataForm.sold_by_employee_id" name="wd_sold_by_id" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                            <option *ngFor="let employee of employees" value="{{employee.id}}">
                                {{employee.name | titlecase}}
                            </option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted">Select an Employee.</small>
                    </div>

                    <div class="col-6 form-group mb-3" *ngIf="dataForm.sold_by == 'WORK_DEPARTMENT'">
                        <label class="control-label">Sold By Work Deparment</label>
                        <select id="inputState" [(ngModel)]="dataForm.sold_by_work_department_id" name="wd_sold_by_id" class="form-control" [appSelect2] [theme]="'mxp360-form'">
                            <option *ngFor="let workDepartment of work_departments" value="{{workDepartment.id}}">
                                {{workDepartment.name | titlecase}}
                            </option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted">Select a Work Department.</small>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Job Type</label>
                        <select id="job_type" [(ngModel)]="dataForm.job_type" name="pg_job_type" class="form-control">
                            <option value="LONG_DISTANCE">Long Distance</option>
                            <option value="LOCAL_DISTANCE">Local Distance</option>
                            <option value="AUTO_TRANSPORT">Auto Transport</option>
                            <option value="FREIGHT">Freight</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted">Select an Option.</small>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label class="control-label">Trigger Type</label>
                        <select id="trigger_type" [(ngModel)]="dataForm.trigger_type" name="pg_trigger_type" class="form-control">
                            <option value="FOREMAN">Foreman</option>
                            <option value="QA">QA</option>
                            <option value="SALES">Sales</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted">Select an Option.</small>
                    </div>
                </div>

                <div class="row">

                    <div class="col-12 mt-4 mb-2">
                        <div class="form-group">
                            <div class="toggle-switch">                
                                <label class="switch s-icons s-outline s-outline-secondary">
                                    <input type="checkbox" id="pg_commissionCondition" name="pg_commissionCondition" [(ngModel)]="commissionCondition">
                                    <span class="slider round">
                                        <i class="fa-solid fa-check icon-left"></i>
                                        <i class="fa-solid fa-xmark icon-right"></i>
                                    </span>
                                </label>
                                <label for="pg_commissionCondition" class="checkbox-label" [ngClass]="{'filter-disabled': !commissionCondition}">
                                    Commission Condition
                                </label>
                            </div>
                        </div>
                    </div>
    
                    <div class="col-12" *ngIf="commissionCondition">
    
                        <div class="row p-0 m-0">
                            <div class="col-6 pl-0 form-group mb-4">
                                <label class="control-label">Type</label>
                                <select id="pg_condition" [(ngModel)]="dataForm.condition.type" name="pg_condition.type" class="form-control">
                                    <option value="GTE">GTE</option>
                                    <option value="LTE">LTE</option>
                                    <option value="BETWEEN">BETWEEN</option>
                                </select>
                                <small id="emailHelp1" class="form-text text-muted">Select a Condition.</small>
                            </div>
    
                            <div class="col-2 help-commission-type">
                                <a class="help-btn tooltip">
                                    <i class="fa-solid fa-info"></i>
                                    <span class="tooltiptext">
                                        <strong>GTE </strong>greater than or equal to (i.e. >=) <br> 
                                        <strong>LTE </strong>less than or equal to (i.e. <=)
                                    </span>
                                </a>
                            </div>
                        </div>
    
                        <div class="row">
    
                            <div class="col-6 form-group" *ngIf="dataForm.condition.type == 'GTE' || dataForm.condition.type == 'LTE' || dataForm.condition.type == 'BETWEEN'">
                                <label class="control-label">Start Threshold</label>
                                <input type="number" step="0.01" [(ngModel)]="dataForm.condition.threshold_start" name="wd_address_street" class="form-control">
                                <small id="emailHelp1" class="form-text text-muted">Enter the Start Threshold.</small>
                            </div>
        
                            <div class="col-6 form-group" *ngIf="dataForm.condition.type == 'BETWEEN'">
                                <label class="control-label">End Threshold</label>
                                <input type="number" step="0.01" [(ngModel)]="dataForm.condition.threshold_end" name="wd_address_zip_code" class="form-control">
                                <small id="emailHelp1" class="form-text text-muted">Enter the End Threshold.</small>
                            </div>
    
                        </div>                    
    
                    </div>


                    <div class="col-12 mt-4">
                        <div class="form-group mb-0">               
                            <label class="control-label">Payment Threshold</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-dollar-sign">
                                            <line x1="12" y1="1" x2="12" y2="23"></line>
                                            <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                        </svg>
                                    </span>
                                </div>
                                <input type="number" step="0.01" [(ngModel)]="dataForm.payment_threshold" name="wd_payment_threshold" class="form-control" value=0>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted">Enter Payment Threshold.</small>
                        </div>
                    </div>

                </div>                

            </div>
            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" type="button" data-dismiss="modal" (click)="save()">
                    Save
                </button>
            </div>
        </div>
    </div>
 </div>