import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimRemark } from 'src/app/entities/workspace/claim-remark';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClaimsRemarksService {

  constructor(private http: HttpClient) {
  }

  getAll(ticket_id: any): Promise<Array<ClaimRemark>> {
      return this.http.get<Array<ClaimRemark>>(environment.api.company + '/claims/' + ticket_id + '/remarks').toPromise();
  }

  getById(ticket_id: any, id: any) {
      return this.http.get<ClaimRemark>(environment.api.company + '/claims/' + ticket_id + '/remarks' + id).toPromise();
  }

  save(ticket_id: any, dataForm: ClaimRemark): Promise<any> {
      if (dataForm.id === null) {
          return this.add(ticket_id, dataForm);
      }
  }

  add(ticket_id: any, dataForm: ClaimRemark) {
      return this.http.post(environment.api.company + '/claims/' + ticket_id + '/remarks', dataForm).toPromise();
  }

  remove(ticket_id: any, id: any) {
      return this.http.delete(environment.api.company + '/claims/' + ticket_id + '/remarks/' + id).toPromise();
  }
}
