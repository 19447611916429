import { Address } from '../global/address';

export class Supplier {
    id: string;
    name: string;
    email: string;
    phone: string;
    address: Address;
    created: number;
    updated: number;
    
    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.address = new Address();
        this.created = null;
        this.updated = null;
    }
}
