
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/entities/workspace/customer';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { CustomersService } from 'src/app/services/workspaces/customers.service';


declare const jQuery;


@Component({
    selector: 'app-customers-view',
    templateUrl: './customers-view.component.html',
    styleUrls: ['./customers-view.component.scss']
})
export class CustomersViewComponent implements OnInit {

    row: Customer;
    allEstimates: Array<any>;
    constructor(
        private customersService: CustomersService,
        private currentRoute: ActivatedRoute,
        public helperService: HelperService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.row = new Customer();
        this.allEstimates = [];

    }

    actualPage = 1;

    ngOnInit() {
       
    }

    ngAfterViewInit(): void{
        
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.customersService
            .getById(id)
            .then((response) => {
                this.row = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.customersService
            .getEstimatesCustomer(id)
            .then((response) => {
                this.allEstimates = response;
            })
            .catch((error) => {
                console.error('error', error);
            });

    }

}
