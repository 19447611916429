import { Injectable } from '@angular/core';
import { LocalTariffSetting } from 'src/app/entities/admin/local-tariff-setting';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalTariffSettingsService {

  constructor(private http: HttpClient) {}

  getAll () {
    return this.http.get<Array<LocalTariffSetting>>(environment.api.workspace + '/local-tariff-settings').toPromise();
  }

  get (id: string) {
    return this.http.get<LocalTariffSetting>(environment.api.workspace + '/local-tariff-settings/' + id).toPromise();
  }

  save (localTariff: LocalTariffSetting) {
    if (localTariff.id == null) {
      return this.add(localTariff);
    } else {
      return this.edit(localTariff); 
    }
  }

  add(localTariff: LocalTariffSetting) {
    return this.http.post(environment.api.workspace + '/local-tariff-settings', localTariff).toPromise();
  }

  edit(localTariff: LocalTariffSetting) {
    return this.http.put(environment.api.workspace + '/local-tariff-settings/' + localTariff.id, localTariff).toPromise();
  }

  toggleStatus(id: string) {
    return this.http.put(environment.api.workspace + '/local-tariff-settings/' + id + '/toggle-status', {}).toPromise();
  }
  
  getQuikQuotes(id: string) {
    return this.http.get<Array<any>>(environment.api.workspace + '/local-tariff-settings/' + id + '/quick-quotes', {}).toPromise();
  }

  saveQuikQuotes(id: string, data) {
    return this.http.post(environment.api.workspace + '/local-tariff-settings/' + id + '/quick-quotes', data).toPromise();
  }

  updateQuikQuotes(estimate_id: string, data) {
    const additionalConcept = {
      'id': data.id,
      'name': data.name,
      'type': data.type,
      'additional_concept_id': data.tariff_additional_concept_id,
      'mins': data.metas[0].value,
      'assiged_data': data.metas[1].value
    };
    return this.http.put(environment.api.workspace + '/local-tariff-settings/' + estimate_id + '/quick-quotes/' + data.id, additionalConcept).toPromise();
  }

}
