import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EstimateMediaFile } from 'src/app/entities/workspace/estimate-media-file';
import { EstimatesMediaFilesService } from 'src/app/services/companies/estimates-media-files.service';
import { HelperService } from 'src/app/services/helper.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare var jQuery;

@Component({
    selector: 'app-moving-media-files',
    templateUrl: './moving-media-files.component.html',
    styleUrls: ['./moving-media-files.component.scss']
})
export class MovingMediaFilesComponent implements OnInit {

    estimate_id: string;
    mediaFile: Array<EstimateMediaFile>

    constructor(
        private uploadFilesService: UploadFilesService,
        private estimatesMediaFilesService: EstimatesMediaFilesService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) { }

    ngOnInit(): void {
    }


    ngAfterViewInit(): void {
        // verficamos si se esta editando
        this.currentRoute
            .parent
            .params.subscribe(params => {
                if (typeof params.id !== 'undefined') {
                    //this.loadAllData(params.id);
                    this.estimate_id = params.id;
                    this.load();
                }
            });
    }

    load() {
        this.helperService.showLoadingMxpro360();
        this.estimatesMediaFilesService
            .getAll(this.estimate_id)
            .then((response) => {
                this.mediaFile = response;
                // console.info("job: ", this.job);

                // asignamos informacion temporalmente
                // this.customer = this.job.estimate.customer;
                // this.loadStatusHistoryCharge(this.job.id);
                // this.loadNotes();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    public openUploadModal(pdf): void {
        const that = this;
        this.uploadFilesService.openModal().then((response: Array<File>) => {
            // guardar datos que se obtienen del modal
            this.helperService.showLoadingMxpro360();
            this.estimatesMediaFilesService
                .add(this.estimate_id, response, pdf)
                .then(() => {
                    that.load();
                })
                .catch((error) => {
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                });
        });
    }

}
