import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TicketView } from 'src/app/entities/workspace/ticket-view';
import { Ticket } from 'src/app/entities/workspace/ticket';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {

    constructor(private http: HttpClient) {

    }

    getAllWithFullView(data: any): Promise<Array<TicketView>> {
        return this.http.get<Array<TicketView>>(environment.api.company + '/tickets', { params: data }).toPromise();
    }

    getAll(): Promise<Array<Ticket>> {
        return this.http.get<Array<Ticket>>(environment.api.company + '/tickets').toPromise();
    }

    getById(id: string) {
        return this.http.get<Ticket>(environment.api.company + '/tickets/' + id).toPromise();
    }

    save(dataForm: Ticket): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: Ticket) {
        return this.http.post(environment.api.company + '/tickets', dataForm).toPromise();
    }

    edit(dataForm: Ticket) {
        return this.http.put(environment.api.company + '/tickets/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/tickets/' + id).toPromise();
    }

    getExpiredTickets(companyId) {
        return this.http.get<Array<Ticket>>(environment.api.company + '/dashboard/pending-tickets',
        { params: { 'full-view': '1', 'company_id': companyId } }).toPromise();
    }
}
