import { ActivatedRoute } from '@angular/router';
import { SuppliersService } from 'src/app/services/workspaces/suppliers.service';
import { Supplier } from 'src/app/entities/workspace/supplier';
import { Component, OnInit } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
    selector: 'app-suppliers-view',
    templateUrl: './suppliers-view.component.html',
    styleUrls: ['./suppliers-view.component.scss']
})
export class SuppliersViewComponent implements OnInit {

    row: Supplier;

    constructor(
        private suppliersService: SuppliersService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.row = new Supplier();
    }

    ngOnInit() {
      
    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
     }
     
    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.suppliersService
            .getById(id)
            .then((response) => {
                this.row = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
