<div class=" layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-5">
            <h3>
                General Settings
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">General Settings</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right align-self-center mb-5">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <button class="btn btn-main-action mb-2" (click)="save(null)">
                    Save
                </button>
            </div>
        </div>
    </div>
    <!-- header -->

    <form #GeneralSettingsForm="ngForm" (ngSubmit)="save(null)" ngNativeValidate>

        <!-- MENU TAB -->
        <div class="tab-menu">
            <div class="btn-group block-general-settings-sections" role="group" aria-label="Basic example">
                <button type="button" [ngClass]="{'opt-active' : numberTab == 1 }" class="btn opt-disabled btn-lg" (click)="changeTab(1)">
                    Company
                </button>
                <button type="button" [ngClass]="{'opt-active' : numberTab == 2 }" class="btn opt-disabled btn-lg" (click)="changeTab(2)">
                    Address
                </button>
                <button type="button" [ngClass]="{'opt-active' : numberTab == 3 }" class="btn opt-disabled btn-lg" (click)="changeTab(3)">
                    System
                </button>
                <button type="button" [ngClass]="{'opt-active' : numberTab == 4 }" class="btn opt-disabled btn-lg" (click)="changeTab(4)">
                    Prices
                </button>
                <button type="button" [ngClass]="{'opt-active' : numberTab == 5 }" class="btn opt-disabled btn-lg" (click)="changeTab(5)">
                    Policy
                </button>
                <button type="button" [ngClass]="{'opt-active' : numberTab == 6 }" class="btn opt-disabled btn-lg" (click)="changeTab(6)">
                    Marketing
                </button>
                <button type="button" [ngClass]="{'opt-active' : numberTab == 7 }" class="btn opt-disabled btn-lg" (click)="changeTab(7)">
                    Email 
                </button>
            </div>
        </div>
        <!-- END MENU TAB -->

        <!-- Company info -->
        <section *ngIf="numberTab == 1">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">

                    <h3>
                        Company
                    </h3>

                    <div class="row">
                        <div class="col-sm-6">

                            <div class="form-group mb-3">
                                <label for="company_name">Name</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="far fa-building building-icon"></i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" name="company_name"
                                        placeholder="Enter here the name"
                                        [(ngModel)]="dataForm.company.name">
                                </div>

                                <small class="form-text text-muted mb-1">
                                    Company name
                                </small>
                            </div>                            

                            <div class="form-group mb-3">
                                <label>Phone</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-phone">
                                                <path
                                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" name="company_phone"
                                        placeholder="Enter here the phone number"
                                        [(ngModel)]="dataForm.company.phone">
                                </div>

                                <small class="form-text text-muted mb-1">
                                    Company Phone Number
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label for="company_email">Email</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-mail">
                                                <path
                                                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                </path>
                                                <polyline points="22,6 12,13 2,6">
                                                </polyline>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control " name="company_email"
                                        placeholder="Enter here the email"
                                        [(ngModel)]="dataForm.company.email">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company Email
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label for="company_website">Website</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-globe">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                                <path
                                                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                </path>
                                            </svg> 
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" name="company_website"
                                        placeholder="Enter here the WEBSITE"
                                        [(ngModel)]="dataForm.company.website">
                                </div>

                                <small class="form-text text-muted mb-1">
                                    Company Website
                                </small>
                            </div>
                 
                        </div>
                        <div class="col-sm-6">

                            <div class="upload">
                                <label class="company-logo">Company Logo</label>
                                <input type="file" 
                                    id="input-file-max-fs"
                                    [value]="defaultLogo"
                                    [appDropify]
                                    (onChange)="onChangeLogo($event)" 
                                    class="dropify"
                                    data-max-file-size="2M" />                                
                            </div>

                            <div class="form-group mt-5 mb-3">
                                <label for="company_dot">DOT</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-hash">
                                                <line x1="4" y1="9" x2="20" y2="9"></line>
                                                <line x1="4" y1="15" x2="20" y2="15"></line>
                                                <line x1="10" y1="3" x2="8" y2="21"></line>
                                                <line x1="16" y1="3" x2="14" y2="21"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" name="company_dot"
                                        placeholder="Enter here the dot"
                                        [(ngModel)]="dataForm.company.dot">
                                </div>

                                <small class="form-text text-muted mb-1">
                                    Company DOT ID Number
                                </small>
                            </div>
           
                            <div class="form-group mb-3">
                                <label for="company_license">MC</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-file-text">
                                                <path
                                                    d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z">
                                                </path>
                                                <polyline points="14 2 14 8 20 8">
                                                </polyline>
                                                <line x1="16" y1="13" x2="8" y2="13"></line>
                                                <line x1="16" y1="17" x2="8" y2="17"></line>
                                                <polyline points="10 9 9 9 8 9">
                                                </polyline>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" name="company_license"
                                        placeholder="Enter here the license"
                                        [(ngModel)]="dataForm.company.license">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company License Number
                                </small>
                            </div>

 
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- END Company info -->

        <!-- company address -->
        <section *ngIf="numberTab == 2">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">

                    <h3>
                        Address
                    </h3>


                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group mb-3">
                                <label for="company_address_country">Country</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-map-pin">
                                                <path
                                                    d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z">
                                                </path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control"
                                        name="company_address_country"
                                        placeholder="Enter here the country"
                                        [(ngModel)]="dataForm.company.address.country">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company country
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label for="company_address_state">State</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-map-pin">
                                                <path
                                                    d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z">
                                                </path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control"
                                        name="company_address_state"
                                        placeholder="Enter here the state"
                                        [(ngModel)]="dataForm.company.address.state">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company state
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label for="company_address_city">City</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-map-pin">
                                                <path
                                                    d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z">
                                                </path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control"
                                        name="company_address_city"
                                        placeholder="Enter here the city"
                                        [(ngModel)]="dataForm.company.address.city">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company city
                                </small>
                            </div>

                        </div>

                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="company_address_street">Street</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-map-pin">
                                                <path
                                                    d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z">
                                                </path>
                                                <circle cx="12" cy="10" r="3"></circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control"
                                        name="company_address_street"
                                        placeholder="Enter here the street"
                                        [(ngModel)]="dataForm.company.address.street">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company street
                                </small>
                            </div>


                            <div class="form-group mb-3">
                                <label for="company_address_zip_code">Zip Code</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-hash">
                                                <line x1="4" y1="9" x2="20" y2="9"></line>
                                                <line x1="4" y1="15" x2="20" y2="15"></line>
                                                <line x1="10" y1="3" x2="8" y2="21"></line>
                                                <line x1="16" y1="3" x2="14" y2="21"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control"
                                        name="company_address_zip_code"
                                        placeholder="Enter here the zip code"
                                        [(ngModel)]="dataForm.company.address.zip_code">
                                </div>
                                <small class="form-text text-muted mb-1">
                                    Company Zip Code
                                </small>
                            </div>



                        </div>

                    </div>

                </div>
            </div>
        </section>
        <!-- END company address -->

        <!-- System -->
        <section *ngIf="numberTab == 3">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">

                    <h3>
                        System Settings
                    </h3>

                    <div class="row">
                        <div class="col-md-6">

                            <div class="row">

                                <div class="col-8">
                                    <div class="form-group">
                                        <label>Estimate Document Theme</label>
                                        <select name="theme_document_estimate"
                                            [(ngModel)]="dataForm.settings.theme_document"
                                            id="theme_document_estimate" class="form-control"
                                            (change)="selectedThemeDocument()">
                                            <option value="" disabled >Select here your theme</option>
                                            <option value="BLUE">Blue</option>
                                            <option value="BLUELLOW">Bluellow</option>
                                            <option value="CANDY">Candy</option>
                                            <option value="CHERRYPURPLE">Cherry Purple</option>
                                            <option value="DARKCYAN">Dark Cyan</option>
                                            <option value="DARKSLATEGRAY">Dark Slate Gray</option>
                                            <option value="GOLDTEAL">Gold Teal</option>
                                            <option value="GREEN">Green</option>
                                            <option value="LOLLIPOP">Lollipop</option>
                                            <option value="NATURA">Natura</option>
                                            <option value="ORANGE">Orange</option>
                                            <option value="PINK">Pink</option>
                                            <option value="PURPLE">Purple</option>
                                            <option value="RED">Red</option>
                                            <option value="TEAL">Teal</option>
                                            <option value="TOXICITY">Toxicity</option>
                                            <option value="WARM">Warm</option>
                                            <option value="CUSTOM">Custom</option>
                                        </select>
                                        <small class="form-text text-muted mb-1">
                                            Select Estimate Document Theme
                                        </small>
                                    </div>
                                    
                                </div>

                                <div class="col-4 container-color-box">
                                    <div class="cube">
                                        <div class="front" [ngStyle]="{'background': companyThemeDocument.secondColor}"></div>
                                        <div class="side" [ngStyle]="{'background': companyThemeDocument.firstColor}"></div>
                                        <div class="top" [ngStyle]="{'background': companyThemeDocument.thirdColor}"></div>
                                    </div>
                                </div>                                

                            </div>

                            <div class="custom-theme-document mb-3" *ngIf='dataForm.settings.theme_document == "CUSTOM"'>

                                <label for="">
                                    Custom Colors
                                </label>

                                <div class="row mt-2">
                                    <div class="col-3 pr-0">
                                        <div class="custom-color-box">
                                            <label for="">First Color</label>
                                            <div class="color-input">
                                                <input type="color" class="form-control" name="first_custom_color" id="first_custom_color" [(ngModel)]="firstCustomColor" (input)="updateFirstColor()">
                                                <span class="color-hex">{{firstCustomColor}}</span>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <!-- <div class="col-1"></div> -->
                                    <div class="col-3 pr-0">
                                        <div class="custom-color-box">
                                            <label for="">Second Color</label>
                                            <div class="color-input">
                                                <input type="color" class="form-control" name="second_custom_color" id="second_custom_color" [(ngModel)]="secondCustomColor" (input)="updateSecondColor()">
                                                <span class="color-hex">{{secondCustomColor}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-1"></div> -->
                                    <div class="col-3 pr-0">
                                        <div class="custom-color-box">
                                            <label for="">Third Color</label>
                                            <div class="color-input">
                                                <input type="color" class="form-control" name="third_custom_color" id="third_custom_color" [(ngModel)]="thirdCustomColor" (input)="updateThirdColor()">
                                                <span class="color-hex">{{thirdCustomColor}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-1"></div> -->
                                </div>

                            </div>
                            
                            <div class="form-group mb-3">
                                <label>Estimate Document Layout</label>
                                <select name="layout_document_estimate"
                                    [(ngModel)]="dataForm.settings.layout_document"
                                    id="layout_document_estimate" class="form-control">
                                    <option value="" disabled>Select here your Layout</option>
                                    <option value="LAYOUT_A">Layout style A</option>
                                    <option value="LAYOUT_B">Layout style B</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select Estimate Document Layout
                                </small>
                            </div>
                            
                            <div class="form-group mb-3">
                                <label>Enable Cover Page</label>
                                <select name="cover_page" id="cover_page" class="form-control" [(ngModel)]="enableCoverPage"> 
                                    <option value="ACTIVED">Yes</option>
                                    <option value="DISABLED">No</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Enable the Estimate Cover Page?
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label>Default Payment Gateway</label>
                                <select name="default_payment_gateway"
                                    [(ngModel)]="this.dataForm.settings.default_payment_gateway_id"
                                    id="default_payment_gateway" class="form-control">
                                    <option value="">Select a Gateway</option>
                                    <ng-container *ngFor="let gateway of gateways" >
                                        <option [value]="gateway.id" *ngIf="gateway.active_cc">{{gateway.nickname}} - {{gateway.name}}</option>
                                    </ng-container>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select the Default Payment Gateway
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label>Time Zone</label>
                                <select name="company_time_zone"
                                    [(ngModel)]="dataForm.settings.time_zone"
                                    id="company_time_zone" class="form-control">
                                    <option value="America/New_York">(GMT-05:00) Eastern Time - America/New_York</option>
                                    <option value="America/Chicago">(GMT-06:00) Central Time - America/Chicago</option>
                                    <option value="America/Denver">(GMT-07:00) Mountain Time - America/Denver</option>
                                    <option value="America/Phoenix">(GMT-07:00) Mountain no DST - America/Phoenix</option>
                                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time - America/Los_Angeles</option>
                                    <option value="America/Anchorage">(GMT-09:00) Alaska Time - America/Anchorage</option>
                                    <option value="America/Adak">(GMT-10:00) Hawaii Time - America/Adak</option>
                                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii Time no DST - Pacific/Honolulu</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select here the time zone where the company operates
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label for="timer_notify_management">
                                    Timer Notify Management (Hours)
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-bookmark">
                                                <path
                                                    d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="1" class="form-control" name="timer_notify_management"
                                        placeholder="Timer Notify Management" [(ngModel)]="dataForm.settings.ticket_setting.timer_notify_management">
                                </div>        
                                <small id="emailHelp1"
                                    class="form-text text-muted mb-1">
                                    Write hours notify Management
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="timer_notify_management">
                                    Default Binding Type
                                </label>
                                <div class="input-group mb-1">
                                    <select name="carrier_balance_mode" [(ngModel)]="dataForm.settings.default_binding_type"
                                        id="carrier_balance_mode" class="form-control">
                                        <option value="BINDING">Binding</option>
                                        <option value="NO_BINDING">Non-Binding</option>
                                        <option value="NO_EXCEED">Not to Exceed</option>
                                    </select>
                                </div>        
                                <small id="emailHelp1"
                                    class="form-text text-muted mb-1">
                                    Write hours notify Management
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="require_cardholder_name">
                                    Require Cardholder Name To Match
                                </label>
                                <select name="require_cardholder_name" id="require_cardholder_name" class="form-control" [(ngModel)]="require_cardholder_name"> 
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Require cardholder name to match estimate contact name
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="visual_type">
                                    Require Visual Type
                                </label>
                                <select name="visual_type" id="visual_type" class="form-control" [(ngModel)]="visual_type"> 
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Require Visual Type
                                </small>
                            </div>

                        </div>
                        <div class="col-md-6">

                            <div class="form-group mb-3">
                                <label>Add Commissions to Payroll When</label>
                                <select name="when_to_comission"
                                    [(ngModel)]="dataForm.settings.when_to_comission"
                                    id="when_to_comission" class="form-control">
                                    <option value="JOB_BOOKED">Job Booked</option>
                                    <option value="PICKUP_DATE">Pickup Date</option>
                                    
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select Add Commissions to Payroll When
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label for="timer_notify_management">
                                    Payroll Start Day
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-bookmark">
                                                <path
                                                    d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="date" step="1" class="form-control "
                                        name="payroll_start_day"
                                        placeholder="Payroll Start Day"
                                        [(ngModel)]="dataForm.settings.payroll_start_day">
                                </div>
        
                                <small id="emailHelp1"
                                    class="form-text text-muted mb-1">
                                    Write hours notify Management
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label>Set Payroll Period</label>
                                <select name="pay_period"
                                    [(ngModel)]="dataForm.settings.pay_period"
                                    id="pay_period" class="form-control">
                                    <option value="WEEKLY">Weekly</option>
                                    <option value="MONTHLY">Monthly</option>
                                    <option value="BI_WEEKLY">Biweekly</option>
                                    <option value="BI_MONTHLY">Bimonthly</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select Payroll Start Day
                                </small>
                            </div>
                            
                            <div class="form-group mb-3">
                                <label>LBS or CF Display On Estimate (Each Inventory Item)</label>
                                <select name="cfDisplayOnEstimate"
                                    [(ngModel)]="cfDisplayOnEstimate"
                                    id="company_cf_display_on_estimate" class="form-control">
                                    <option value="ACTIVED">Display Cubic Feet / LBS For Each Item</option>
                                    <option value="DISABLED">Do Not Display Cubic Feet / LBS For Each Item</option>                                    
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select here the LBS Or CF where the company operates
                                </small>
                            </div>
                            <div class="form-group mb-3">
                                <label>LBS or CF Display On BOL (Each Inventory Item)</label>
                                <select name="cfDisplayOnBOL"
                                    [(ngModel)]="cfDisplayOnBOL" id="company_cf_display_on_BOL" class="form-control">
                                    <option value="ACTIVED">Display Cubic Feet / LBS For Each Item</option>
                                    <option value="DISABLED">Do Not Display Cubic Feet / LBS For Each Item</option>
                                    
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select here the LBS Or CF where the company operates
                                </small>
                            </div>

                            <div class="form-group mb-3">
                                <label>Carrier Logo Display On BOL</label>
                                <select name="logoDisplayOnBOL"
                                    [(ngModel)]="logoDisplayOnBOL"
                                    id="logoDisplayOnBOL" class="form-control">
                                    <option value="ACTIVED">Display Carrier Logo</option>
                                    <option value="DISABLED">Do Not Display Carrier Logo</option>
                                    
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Select an option
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="customer_birthday_for_cc">
                                    Customer Birthday for CC
                                </label>
                                <select name="customer_birthday_for_cc" id="customer_birthday_for_cc" class="form-control" [(ngModel)]="customer_birthday_for_cc"> 
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Require cardholder birthday for transaction
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="require_customer_id">
                                    Require Customer ID for CC
                                </label>
                                <select name="require_customer_id" id="require_customer_id" class="form-control" [(ngModel)]="require_customer_id"> 
                                    <option value="YES">Yes</option>
                                    <option value="NO">No</option>
                                </select>
                                <small class="form-text text-muted mb-1">
                                    Require customer ID and expiration date
                                </small>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- END System -->

        <!-- Prices -->
        <section *ngIf="numberTab == 4">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">

                    <h3>
                        Prices
                    </h3>

                    <div class="row">
                        <div class="col-md-6">

                            <div class="form-group mb-1">
                                <label for="cf_lbs_ratio">
                                    Cf / Lbs Ratio (Default=7, valid between 4.00-12.00)**
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-layers">
                                                <polygon
                                                    points="12 2 2 7 12 12 22 7 12 2">
                                                </polygon>
                                                <polyline points="2 17 12 22 22 17">
                                                </polyline>
                                                <polyline points="2 12 12 17 22 12">
                                                </polyline>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01"
                                        class="form-control" name="cf_lbs_ratio"
                                        placeholder="Enter here the Cf / Lbs Ratio"
                                        [(ngModel)]="dataForm.settings.price_setting.cf_lbs_ratio">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Cubic Feets
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="default_amount_liability">
                                    Default Liability Amount
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-hash">
                                                <line x1="4" y1="9" x2="20" y2="9">
                                                </line>
                                                <line x1="4" y1="15" x2="20" y2="15">
                                                </line>
                                                <line x1="10" y1="3" x2="8" y2="21">
                                                </line>
                                                <line x1="16" y1="3" x2="14" y2="21">
                                                </line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01"
                                        class="form-control"
                                        name="default_amount_liability"
                                        placeholder="Enter here the default liability amount"
                                        [(ngModel)]="dataForm.settings.price_setting.default_amount_liability">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Liability Amounts
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="default_minimum_cf">
                                    Default Minimum CF
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23">
                                                </line>
                                                <path
                                                    d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01"
                                        class="form-control" name="default_minimum_cf"
                                        placeholder="Enter here the default minimum CF"
                                        [(ngModel)]="dataForm.settings.price_setting.default_minimum_cf">
                                </div>

                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Write Default Minimum CF
                                </small>
                            </div>

                            <!-- Initial Payment Equation (Long Distance) -->
                            <div class="form-group initial-payment-equation mb-1 mt-n13">
                                <div class="initial-payment-label">
                                    <label for="percentage_fuel_surcharge">
                                        Initial Payment Equation (Long Distance)
                                    </label>
                                    <div class="btn btn-info py-1" (click)="setEquationInitPaymentLongDistance()">Create Equation</div>
                                </div>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23">
                                                </line>
                                                <path
                                                    d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text"
                                        class="form-control"
                                        name="initial_payment_long_distance"
                                        placeholder="Initial payment equation"                                        
                                        disabled
                                        value="{{dataForm.settings.price_setting.initial_payment_long_distance?.formula}}">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Initial Payment Equation
                                </small>
                            </div>

                            <!-- Initial Payment Equation (Local Distance) -->
                            <div class="form-group initial-payment-equation mb-1 mt-n13">
                                <div class="initial-payment-label">
                                    <label for="percentage_fuel_surcharge">
                                        Initial Payment Equation (Local Distance)
                                    </label>
                                    <div class="btn btn-info py-1" (click)="setEquationInitPaymentLocalDistance()">Create Equation</div>
                                </div>
                                
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23">
                                                </line>
                                                <path
                                                    d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text"
                                        class="form-control"
                                        name="initial_payment_local_distance"
                                        placeholder="Initial payment equation"
                                        disabled
                                        value="{{dataForm.settings.price_setting.initial_payment_local_distance?.formula}}">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Initial Payment Equation
                                </small>
                            </div>

                            <!-- Initial Payment Equation (Auto Transport) -->
                            <div class="form-group initial-payment-equation mb-1 mt-n13">
                                <div class="initial-payment-label">
                                    <label for="percentage_fuel_surcharge">
                                        Initial Payment Equation (Auto Transport)
                                    </label>
                                    <div class="btn btn-info py-1" (click)="setEquationInitPaymentAutoTransport()">Create Equation</div>
                                </div>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23">
                                                </line>
                                                <path
                                                    d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text"
                                        class="form-control"
                                        name="initial_payment_auto_transport"
                                        placeholder="Initial payment equation"
                                        disabled
                                        value="{{dataForm.settings.price_setting.initial_payment_auto_transport?.formula}}">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Initial Payment Equation
                                </small>
                            </div>

                            <!-- Initial Payment Equation (Freight Transport) -->
                            <div class="form-group initial-payment-equation mb-1 mt-n13">
                                <div class="initial-payment-label">
                                    <label for="percentage_fuel_surcharge">
                                        Initial Payment Equation (Freight Transport)
                                    </label>
                                    <div class="btn btn-info py-1" (click)="setEquationInitPaymentFreightTransport()">Create Equation</div>
                                </div>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23">
                                                </line>
                                                <path
                                                    d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="text"
                                        class="form-control"
                                        name="initial_payment_freight_transport"
                                        placeholder="Initial payment equation"
                                        disabled
                                        value="{{dataForm.settings.price_setting.initial_payment_freight_transport?.formula}}">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Initial Payment Equation
                                </small>
                            </div>                                                                                   

                        </div>

                        <div class="col-md-6">

                            <div class="form-group mb-1">
                                <label for="percentage_fuel_surcharge">
                                    Long Distance: Calculate Fuel Surcharge
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-gas-pump"></i>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01"
                                        class="form-control"
                                        name="percentage_fuel_surcharge"
                                        placeholder="Enter here the calculate fuel surcharge"
                                        [(ngModel)]="dataForm.settings.price_setting.percentage_fuel_surcharge">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Long Distance Fuel Surcharge
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="percentage_fuel_surcharge">
                                    Price by Pad
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-dollar-sign">
                                                <line x1="12" y1="1" x2="12" y2="23">
                                                </line>
                                                <path
                                                    d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01"
                                        class="form-control" name="price_by_pad"
                                        placeholder="Enter here the price by pad"
                                        [(ngModel)]="dataForm.settings.price_setting.price_by_pad">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Price by Pad
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="broker_balance_percentage">
                                    Broker Balance Percentage
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-percent">
                                                <line x1="19" y1="5" x2="5" y2="19">
                                                </line>
                                                <circle cx="6.5" cy="6.5" r="2.5">
                                                </circle>
                                                <circle cx="17.5" cy="17.5" r="2.5">
                                                </circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01" min="1" max="100"
                                        class="form-control"
                                        name="broker_balance_percentage"
                                        placeholder="Enter here the initial payment equation"
                                        [(ngModel)]="dataForm.settings.price_setting.broker_balance_percentage">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Broker Balance Percentage
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="carrier_balance_mode">
                                    Carrier Balance Mode
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-percent">
                                                <line x1="19" y1="5" x2="5" y2="19">
                                                </line>
                                                <circle cx="6.5" cy="6.5" r="2.5">
                                                </circle>
                                                <circle cx="17.5" cy="17.5" r="2.5">
                                                </circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <select name="carrier_balance_mode"
                                        [(ngModel)]="dataForm.settings.price_setting.carrier_balance_mode"
                                        id="carrier_balance_mode" class="form-control">
                                        <option value="">Select Mode</option>
                                        <option value="BY_PERCENTAGE">By Percentage</option>
                                        <option value="BY_REMAINING_BALANCE">By Remaining Balance</option>
                                    </select>
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Select The Broker Balance Mode
                                </small>
                            </div>

                            <div class="form-group mb-1" *ngIf="dataForm.settings.price_setting.carrier_balance_mode == 'BY_PERCENTAGE'">
                                <label for="carrier_balance_percentage">
                                    Carrier Balance Percentage
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24"
                                                viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="feather feather-percent">
                                                <line x1="19" y1="5" x2="5" y2="19">
                                                </line>
                                                <circle cx="6.5" cy="6.5" r="2.5">
                                                </circle>
                                                <circle cx="17.5" cy="17.5" r="2.5">
                                                </circle>
                                            </svg>
                                        </span>
                                    </div>
                                    <input type="number" step="0.01" min="1" max="100"
                                        class="form-control"
                                        name="carrier_balance_percentage"
                                        placeholder="Enter here the initial payment equation"
                                        [(ngModel)]="dataForm.settings.price_setting.carrier_balance_percentage">
                                </div>

                                <small id="emailHelp1"
                                    class="form-text text-muted mb-4">
                                    Write Carrier Balance Percentage
                                </small>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- END Prices -->

        <!-- Company Policy -->
        <section *ngIf="numberTab == 5">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">
                    <h3 class="mb-2">
                        Company Policy
                    </h3>
                    <div class="row">
                        <div class="col-md-11 ml-1">
                            <app-ck-editor 
                                [(ngModel)]="dataForm.settings.policy_setting.ensurance" 
                                [title]="insuranceTermsTitle"
                                name="company_settings_policy_ensurance" 
                                ngDefaultControl>
                            </app-ck-editor>            
                        </div>
                    </div>

                    <div class="row" >
                        <div class="col-md-11 ml-1 mb-4">
                            <div class="form-group marg-t60">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="movinEstimateSignatureTerms" name="movinEstimateSignatureTerms"
                                        [(ngModel)]="dataForm.settings.policy_setting.enable_move_siganture_terms">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="movinEstimateSignatureTerms" class="checkbox-label" [ngClass]="{'filter-disabled': dataForm.settings.policy_setting.enable_move_siganture_terms}">
                                        On/Off Moving Estimate Signature Terms
                                    </label>
                                </div>
                            </div>
                            <app-ck-editor 
                                *ngIf="dataForm.settings.policy_setting.enable_move_siganture_terms"
                                [(ngModel)]="dataForm.settings.policy_setting.enable_move_siganture_text" 
                                [title]="movinEstimateSignatureTerms"
                                name="company_settings_policy_enable_move_siganture_text" ngDefaultControl>
                            </app-ck-editor>                                       
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END Company Policy -->
        
        <!-- Marketing Settings -->
        <section *ngIf="numberTab == 6">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area mb-3">

                    <h3>
                        Marketing Settings
                    </h3>
                    <div class="row">
                        <div class="form-group mb-1 col-md-6" *ngIf="dataForm.settings.marketing_setting.agent_lead_mode">
                            <label for="company_settings_email_welcome_text">
                                Agent Lead Mode
                            </label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon
                                                points="16 8 20 8 23 11 23 16 16 16 16 8">
                                            </polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                    </span>
                                </div>
                                <select name="layout_document_estimate"
                                    [(ngModel)]="dataForm.settings.marketing_setting.agent_lead_mode"
                                    id="layout_document_estimate" class="form-control">
                                    <option value="">
                                        Select Mode
                                    </option>
                                    <option value="POOL">Pool</option>
                                    <option value="ASSIGNMENT">Assignment</option>
                                    <option value="LEAD_ROTATION">Lead Rotation</option>
                                </select>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Select An Agent Lead Mode
                            </small>
                            <a *ngIf="dataForm.settings.marketing_setting.agent_lead_mode == 'LEAD_ROTATION'" (click)="settingsLeadRotation()" class="btn btn-warning" style="height:37px" >
                                <i class="fa-solid fa-gear"></i> &nbsp; Set Up Lead Rotation
                            </a>
                        </div>
                        <div class="form-group mb-1  col-md-6">
                            <label for="company_settings_email_welcome_text">
                                Days Pickups Window
                            </label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                            height="24" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-truck">
                                            <rect x="1" y="3" width="15" height="13"></rect>
                                            <polygon
                                                points="16 8 20 8 23 11 23 16 16 16 16 8">
                                            </polygon>
                                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                        </svg>
                                    </span>
                                </div>
                                <input type="number" name="days_pickups_window"
                                    [(ngModel)]="dataForm.settings.marketing_setting.days_pickups_window"
                                    id="layout_document_estimate" class="form-control">
                                   
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Select Days Pickups Window
                            </small>
                        </div>
                    </div>
                    

                </div>

                <div class="widget-content widget-content-area mb-3">
                    <div class="form-group">
                        <div class="toggle-switch">                
                            <label class="switch s-icons s-outline s-outline-secondary">
                                <input type="checkbox" id="autoEmail" name="autoEmail"
                                [(ngModel)]="dataForm.settings.marketing_setting.auto_email_enable">
                                <span class="slider round">
                                    <i class="fa-solid fa-check icon-left"></i>
                                    <i class="fa-solid fa-xmark icon-right"></i>
                                </span>
                            </label>
                            <label for="autoEmail" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.settings.marketing_setting.auto_email_enable}">
                                Enable Auto Email
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group mb-1  col-md-6">
                            <label>Subject for Welcome Message</label>
                           <textarea class="form-control col-6 mb-4"  [disabled]="!dataForm.settings.marketing_setting.auto_email_enable" name="autoEmailSubject" [(ngModel)]="dataForm.settings.marketing_setting.auto_email_subject"></textarea>
                        </div>
                        <div class="form-group mb-1  col-md-6">
                            <label>Welcome Message</label>
                           <textarea class="form-control col-6 mb-4"  [disabled]="!dataForm.settings.marketing_setting.auto_email_enable" name="autoEmailMessage" [(ngModel)]="dataForm.settings.marketing_setting.auto_email_message"></textarea>
                        </div>
                    </div>
                </div>

                <div class="widget-content widget-content-area mb-3">
                    <!-- <label >Enable auto sms</label>
                    <br>
                    <label class="switch s-icons s-outline s-outline-success mb-2"><input type="checkbox" name="enableAutoSms" [(ngModel)]="dataForm.settings.marketing_setting.auto_sms_enable"><span class="slider round"></span></label> -->
                    
                    <div class="form-group">
                        <div class="toggle-switch">                
                            <label class="switch s-icons s-outline s-outline-secondary">
                                <input type="checkbox" id="autoSMS" name="autoSMS"
                                [(ngModel)]="dataForm.settings.marketing_setting.auto_sms_enable">
                                <span class="slider round">
                                    <i class="fa-solid fa-check icon-left"></i>
                                    <i class="fa-solid fa-xmark icon-right"></i>
                                </span>
                            </label>
                            <label for="autoSMS" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.settings.marketing_setting.auto_sms_enable}">
                                Enable Auto SMS
                            </label>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="form-group mb-1  col-md-6">
                            <label>Subject for Welcome Message</label>
                            <textarea class="form-control col-12 mb-4" rows="5" [disabled]="!dataForm.settings.marketing_setting.auto_sms_enable" name="autoSmsMessage" [(ngModel)]="dataForm.settings.marketing_setting.auto_sms_message"></textarea>
                        </div>
                        <div class="form-group mb-1  col-md-6">
                            <h4 class="m-0">
                                Keywords
                            </h4>
                            <div class="keywords">
                                <br>
                                @CUSTOMER_FULL_NAME<br>
                                @CUSTOMER_NAME<br>
                                @CUSTOMER_PHONE<br>
                                @CUSTOMER_EMAIL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END Marketing Settings -->

        <!-- Email Settings -->
        <section *ngIf="numberTab == 7">
            <div class="bio layout-spacing ">
                <div class="widget-content widget-content-area">

                    <h3>
                        Email Settings
                    </h3>

                    <!-- <div class="row row-input-textarea">
                        <div class="col-md-12 mx-auto">

                            <div class="form-group mb-1">
                                <label for="company_settings_email_welcome_text">
                                    Domain Allowed
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-globe">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                                <path
                                                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input class="form-control"
                                        name="company_settings_email_welcome_text"
                                        placeholder="Write the Welcome Text here"
                                        [(ngModel)]="dataForm.settings.email_setting.domain_allowed"
                                        rows="10">
                                </div>

                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Write Domain Allowed
                                </small>
                            </div>

                        </div>
                    </div> -->

                    <div class="row row-input-textarea">

                        <div class="col-md-6 mx-auto">

                            <div class="form-group mb-1">
                                <label for="email_server">
                                    Email Server
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-globe">
                                                <circle cx="12" cy="12" r="10"></circle>
                                                <line x1="2" y1="12" x2="22" y2="12"></line>
                                                <path
                                                    d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input class="form-control" name="email_server"
                                        [(ngModel)]="dataForm.settings.email_setting.default_email_setting.mail_server"
                                        rows="10">
                                </div>

                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Write Domain Allowed
                                </small>
                            </div>
    
                            <div class="form-group mb-1">
                                <label for="email_address">Email Address</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-at-sign">
                                                <circle cx="12" cy="12" r="4"></circle>
                                                <path
                                                    d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94">
                                                </path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input class="form-control" name="email_address"
                                        [(ngModel)]="dataForm.settings.email_setting.default_email_setting.mail_address"
                                        rows="10">
                                </div>

                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Write Email Address
                                </small>
                            </div>

                            <div class="form-group marg-t60">
                                <div class="toggle-switch">                
                                    <label class="switch s-icons s-outline s-outline-secondary">
                                        <input type="checkbox" id="sendGrid" name="sendGrid"
                                        [(ngModel)]="dataForm.settings.email_setting.default_email_setting.use_send_grid">
                                        <span class="slider round">
                                            <i class="fa-solid fa-check icon-left"></i>
                                            <i class="fa-solid fa-xmark icon-right"></i>
                                        </span>
                                    </label>
                                    <label for="sendGrid" class="checkbox-label" [ngClass]="{'filter-disabled': !dataForm.settings.email_setting.default_email_setting.use_send_grid}">
                                        Use SendGrid
                                    </label>
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4 mt-2">
                                    Check if you are using SendGrid
                                </small>
                            </div>

                        </div>
                        <div class="col-md-6 mx-auto">

                            <div class="form-group mb-1">
                                <label for="email_server_port">
                                    Email Server Port
                                </label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-hash">
                                                <line x1="4" y1="9" x2="20" y2="9"></line>
                                                <line x1="4" y1="15" x2="20" y2="15"></line>
                                                <line x1="10" y1="3" x2="8" y2="21"></line>
                                                <line x1="16" y1="3" x2="14" y2="21"></line>
                                            </svg>
                                        </span>
                                    </div>
                                    <input class="form-control" name="email_server_port" onlyNumbers
                                        [(ngModel)]="dataForm.settings.email_setting.default_email_setting.mail_server_port"
                                        rows="10">
                                </div>
                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Write Server Port
                                </small>
                            </div>

                            <div class="form-group mb-1">
                                <label for="email_password">Email Password</label>
                                <div class="input-group mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none"
                                                stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-unlock">
                                                <rect x="3" y="11" width="18" height="11" rx="2"
                                                    ry="2"></rect>
                                                <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <input class="form-control" autocomplete="off"
                                        type="text" name="email_password"
                                        [(ngModel)]="dataForm.settings.email_setting.default_email_setting.mail_password"
                                        rows="10">
                                </div>

                                <small id="emailHelp1" class="form-text text-muted mb-4">
                                    Write Email Password
                                </small>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- END Email Settings -->
    
    </form>

</div>



<app-equations-form></app-equations-form>

