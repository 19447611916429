import { Ticket } from './ticket';
import { Document } from './document';
import { Customer } from './customer';
import { Employee } from './employee';

export class TicketView extends Ticket {

    public customer: Customer;
    public estimate_document: Document;
    public employee_assigned: Employee

    constructor() {
        super();
        this.customer = new Customer();
        this.estimate_document = new Document();
        this.employee_assigned = new Employee();
    }
}
