<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Premium Variables
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Premium Variables
                    </li>
                </ol>
            </nav>
        </div>


        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <button (click)="save()" class="btn btn-save">
                    Save
                </button>
            </div>
        </div>
    </div>
    <!-- header -->

    <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area">

            <div class="row" >
            <!-- <div class="row" > -->
                <div class="col-lg-12">
                    <div class="info-tip">
                        <span class="asterisk">*</span>
                        Set Multiple per Amount of Liability: (X)(Amount of Liability)/1000
                     </div>
                    <div class="table-responsive">
                        <table class="table table-bordered mb-4 table-rates">
                            <thead>
                                <tr>
                                    <th style="width: 250px">Amount of Liability <br> (Shipment Value)  </th>
                                    <th *ngFor="let deductibleLevel of deductibleLevels">{{deductibleLevel.level | currency}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ldr of liabilityDeductibleRates">
                                    <td class="threshold">
                                        {{ldr.liability_amount.up_to | currency}}
                                    </td>
                                    <td *ngFor="let dr of ldr.deductible_rates">
                                        <input type="number" [(ngModel)]="dr.rate" step="0.01" name="{{ldr.id}}_{{dr.id}}" class="form-control">
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>


        </div>
    </div>


    <!-- body -->

</div>