<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <div class="row">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-3">
                    <h3>
                        Clock Locations
                    </h3>
                    <nav class="breadcrumb-one" aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a appCompanyRouterLink="/dashboard">
                                    <i class="fas fa-home"></i>
                                    Dashboard
                                </a>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">Clock Locations</li>
                        </ol>
                    </nav>
                    <br>
                </div>
            </div>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appCompanyRouterLink="/payroll-clock-locations/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; Add Location
                </a>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Radius</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of clockLocations">
                                <td> {{ row.name }} </td>
                                <td> {{ row.location.full_address }} </td>
                                <td> {{ row.radius }} </td>
                                <td>
                                    <!-- <button class="btn btn-secondary">
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                    </button> -->
                                    <a class="btn btn-success" appCompanyRouterLink="/payroll-clock-locations/{{row.id}}/edit">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>