import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search',
})
export class SearchPipe implements PipeTransform {

    private static _worker: Worker;
 
    constructor() {
        SearchPipe._worker = null;
    }

    /**
     * metodo principal que hara la transformacion de los datos
     * 
     * @param items 
     * @param search 
     * @param counter  // con este parametro evitamos que se cache, por ahora se hace para que pueda trabajar con los cambios en la lista antes de filtrar por busqueda.. EJMPLO: formulario de empleados 
     * @returns  
     */
    transform(items: Array<any> = [], search: string = null, counter: number = null): Promise<any> {
        
        // Se realiza la busqueda por medio de un pormesa, implementado el pipe como un
        // AsyncPipe. En el llamado del pipe en el html se llama el pipe y se llama el async
        // Ejemplo, nombreDelPipe | async
        return this.searchWithWorker(items, search);
    }

    /**
     * Permite iniciar el worker
     */
    private startWorker() {

        // si no se ha iniciado lo iniciamos
        if (SearchPipe._worker == null) {

            // Se crea el worker
            SearchPipe._worker = new Worker(new URL('src/app/workers/search.worker', import.meta.url), { type: 'module', name: 'search-worker' });
        }
    }

    /**
     * Realiza la busqueda con un web worker
     *
     * @param items Items donde se va a seralizar la busqueda
     * @param search Filtro de bisqueda que se va a realizar
     */
    private searchWithWorker(items: Array<any>, search: string = null): Promise<any> {
        return new Promise((resolve, reject) => {

            // si el valor de busqueda es null, devolvemos el mismo
            // si el valor de busqueda es vacio, devolvemos el mismo
            // si el navegador no soporta workers, devolvemos el mismo
            if (search == null || String(search) === '' || typeof Worker === 'undefined') {
                resolve(items);
            }

            // inicializamos el workers si es el caso
            this.startWorker();

            // Se envian los datos de busqueda al worker
            SearchPipe._worker.postMessage({ items, search });

            // Se recibe el resultado del worker y se resuelve la promesa con la busqueda realizada
            SearchPipe._worker.onmessage = (data) => {
                resolve(data.data);
            };
        });
    }

}
