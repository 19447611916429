import { Component, OnInit } from '@angular/core';
import { LiabilityAmount } from 'src/app/entities/workspace/liability-amount';
import { HelperService } from 'src/app/services/helper.service';
import { LiabilityAmountsService } from 'src/app/services/workspaces/liability-amounts.service';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-liability-amounts',
    templateUrl: './liability-amounts.component.html',
    styleUrls: ['./liability-amounts.component.scss']
})
export class LiabilityAmountsComponent implements OnInit {

    rows: Array<LiabilityAmount>;

    constructor(
        private liabilityAmountsService: LiabilityAmountsService,
        private helperService: HelperService    
    ) {
        this.rows = [];
    }

    actualPage = 1;
    filterSearch = '';

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.load();
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.liabilityAmountsService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.liabilityAmountsService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted',
                                'success'
                            );
                        })
                        .catch((error) => {
                            console.error('Error: ', error);
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

}
