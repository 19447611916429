<!-- Modal - Initial Payment Equation -->
<div class="modal fade" id="equationFormModal" #equationFormModal tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered max-width-modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Initial Payment Equation
                </h5>                
            </div>
            <div class="modal-body">

                <div>

                    <!-- Opciones -->
                    <div class="d-flex justify-content-between">
                        <div>
                            
                        </div>
                        <div class="d-flex">
                
                            <button class="btn btn-info" (click)="viewText = !viewText">
                                <span *ngIf="!viewText">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-italic"><line x1="19" y1="4" x2="10" y2="4"></line><line x1="14" y1="20" x2="5" y2="20"></line><line x1="15" y1="4" x2="9" y2="20"></line></svg>
                                </span>
                                <span *ngIf="viewText">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-3"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                </span>            
                            </button>
                
                        </div>
                        
                    </div>
                
                    <!-- Ecuación -->
                    <div class="form-group mb-1">
                        <label class="control-label">Equation</label>
                        <div class="input-group mb-1">
                            <div id="equationForm" class="equation-view" [ngClass]="{'view-text': viewText}">
                                <!-- Equación Ingresada -->
                            </div>
                        </div>
                    </div> 
                
                </div>
                
                <!-- Keys -->
                <div class="col-12 mt-5 mx-2" id="equationFormKeys">
                
                    <div class="row">
                
                        <!-- Operator Keys -->
                        <div class="col-5 opr-list ml-n3 text-center">

                            <label for="">Numbers</label>
                            <div class="row justify-content-center mb-2">
                                <div class="input-group insert-number">
                                    <input type="number" class="form-control" id="keyNumberInput" placeholder="Enter Number" [(ngModel)]="keyNumberInput">
                                    <div class="input-group-append">
                                        <button class="btn btn-success btn-key" id="keyNumberBtn" type="button" (click)="keyNumberBtnClick()" [disabled]="!keyNumberInput">
                                            Insert
                                        </button>
                                    </div>
                                </div>                
                            </div>

                            <label for="" class="mt-4">Operator Keys</label>
                            <div class="row justify-content-center">
                                <div class="btn-key" data-value="+">
                                    +
                                </div>
                                <div class="btn-key" data-value="-">
                                    -
                                </div>
                                <div class="btn-key" data-value="*">
                                    x
                                </div>
                                <div class="btn-key" data-value="/">
                                    /
                                </div>
                                <div class="btn-key" data-value="%">
                                    %
                                </div>
                                <div class="btn-key" data-value="&#40;">
                                    (
                                </div>
                                <div class="btn-key" data-value='&#41;'>
                                    )
                                </div>
                            </div>                            

                        </div>
                
                        <!-- Variables Key -->
                        <div class="col-7 var-list text-center">
                            <label for="">Variables Key</label>
                            <div class="row mr-2">
                                <div class="btn-key" data-value="ADDITIONAL_BOL">
                                    ADDITIONAL_BOL
                                </div>
                                <div class="btn-key" data-value="ADDITIONAL_STOP_FEE">
                                    ADDITIONAL_STOP_FEE
                                </div>  
                                <div class="btn-key" data-value="BINDING_FEE">
                                    BINDING_FEE
                                </div>  
                                <div class="btn-key" data-value="DISCOUNT">
                                    DISCOUNT
                                </div>                                             
                                <div class="btn-key" data-value="DISCOUNT_FULL_PACKING_SERVICE">
                                    DISCOUNT_FULL_PACKING_SERVICE
                                </div>
                                <div class="btn-key" data-value="DISCOUNT_PER_MILE">
                                    DISCOUNT_PER_MILE
                                </div>                
                                <div class="btn-key" data-value="ELEVATOR_DELIVERY">
                                    ELEVATOR_DELIVERY
                                </div>
                                <div class="btn-key" data-value="ELEVATOR_PICKUP">
                                    ELEVATOR_PICKUP
                                </div>
                                <div class="btn-key" data-value="FULL_PACKING_SERVICE">
                                    FULL_PACKING_SERVICE
                                </div>
                                <div class="btn-key" data-value="JOB_DEPOSIT">
                                    JOB_DEPOSIT
                                </div>
                                <div class="btn-key" data-value="JOB_SUB_TOTAL">
                                    JOB_SUB_TOTAL
                                </div>
                                <div class="btn-key" data-value="JOB_TOTAL">
                                    JOB_TOTAL
                                </div>                
                                <div class="btn-key" data-value="LONG_CARRY_DELIVERY">
                                    LONG_CARRY_DELIVERY
                                </div>
                                <div class="btn-key" data-value="LONG_CARRY_PICKUP">
                                    LONG_CARRY_PICKUP
                                </div>
                                <div class="btn-key" data-value="SHUTTLE_DELIVERY">
                                    SHUTTLE_DELIVERY
                                </div>
                                <div class="btn-key" data-value="SHUTTLE_PICKUP">
                                    SHUTTLE_PICKUP
                                </div>
                                <div class="btn-key" data-value="SPLIT_DELIVERY">
                                    SPLIT_DELIVERY
                                </div>
                                <div class="btn-key" data-value="SPLIT_PICKUP">
                                    SPLIT_PICKUP
                                </div>              
                                <div class="btn-key" data-value="TOTAL_BOL">
                                    TOTAL_BOL
                                </div>
                            </div>
                        </div>
                
                    </div>
                
                </div>
                            
            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" (click)="save()"> 
                    Save
                </button>
            </div>
            
        </div>
    </div>
</div>