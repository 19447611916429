export class LocalRoomCategory {
    public id: string;
    public custom: string;
    public full_time_type: string;
    public full: string;
    public is_active: boolean;
    public is_custom: boolean;
    public minimal_time_type: string;
    public minimal: string;
    public moderate_time_type: string;
    public moderate: string;
    public name: string;
    public created: string;
    public update: string;

    constructor() {
        this.id = null;
        this.custom = null;
        this.full = null;
        this.full_time_type = 'HOURS';
        this.is_active = false;
        this.is_custom = false;
        this.minimal = null;
        this.minimal_time_type = 'HOURS';
        this.moderate = null;
        this.moderate_time_type = 'HOURS';
        this.name = null;
        this.created = null;
        this.update = null;
    }
}
