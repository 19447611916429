import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
    selector: 'app-recurring-customer',
    templateUrl: './recurring-customer.component.html',
    styleUrls: ['./recurring-customer.component.scss']
})
export class RecurringCustomerComponent implements OnInit {

    @Input()
    companyId: boolean = false;

    jobsRecurringCustomerTheySendEmailsToo: any;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) {
        this.jobsRecurringCustomerTheySendEmailsToo = [];

    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
        if (this.companyId){
            this.loadRecurringCustomerTheySendEmailsTooCompany();
        } else {
            this.loadRecurringCustomerTheySendEmailsTooWorkspace();
        }
    }

    loadRecurringCustomerTheySendEmailsTooWorkspace() {
        this.dashboardWorkspaceService
            .getRecurringCustomerTheySendEmailsToo()
            .then((response) => {
                this.jobsRecurringCustomerTheySendEmailsToo = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

    loadRecurringCustomerTheySendEmailsTooCompany() {
        this.dashboardComopanyService
            .getRecurringCustomerTheySendEmailsToo()
            .then((response) => {
                this.jobsRecurringCustomerTheySendEmailsToo = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

}
