
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from 'src/app/entities/admin/account';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';


declare const jQuery;
declare const swal;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

    dataForm: Account;
    public confirmPassword: string;

    constructor(
        public authService: AuthService,
        private router: Router,
        private helperService: HelperService
    ) {
        this.dataForm = new Account();
        this.confirmPassword = null;
    }

    ngOnInit(): void {
    }

    sigin() {
        let msg = '';
        if (this.dataForm.name.trim() === '') {
            msg += "**You Must Provide a Name**<br>";
        }
        if (this.dataForm.password.trim() === '') {
            msg += "**You Must Provide a Password**<br>";
        }
        if (this.dataForm.password !== this.confirmPassword) {
            msg = `Password and Confirm don't Match`;
        }
        if (msg !== '') {
            swal(
                'Attention',
                msg,
                'warning'
            );
            return;
        }
        this.helperService.showLoadingMxpro360();

        this.authService
            .signin(this.dataForm)
            .then(() => {
                this.router.navigateByUrl('/my-profile/workspaces');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error ? error.error.message : error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    ngOnDestroy() {
        this.dataForm = null;
    }

    showPassword(){       
        jQuery("#show_password svg").css('display', 'none'); 
		if( jQuery("#inputPassword").attr('type') == "password"){
			jQuery("#inputPassword").prop('type','text');
			jQuery('.icon.password').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}else{
			jQuery("#inputPassword").prop('type','password');
            jQuery('.icon.password').removeClass('fa fa-eye-slash').addClass('fa fa-eye');			
		}
	} 

    showConfirmPassword(){        
        jQuery("#show_confirm_password svg").css('display', 'none'); 
		if( jQuery("#inputConfirmPassword").attr('type') == "password"){
			jQuery("#inputConfirmPassword").prop('type','text');
			jQuery('.icon.confirm-password').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
		}else{
			jQuery("#inputConfirmPassword").prop('type','password');
            jQuery('.icon.confirm-password').removeClass('fa fa-eye-slash').addClass('fa fa-eye');			
		}
	} 

}
