import { Pipe, PipeTransform } from '@angular/core';

declare const moment;

@Pipe({
  name: 'timeFromDate'
})
export class TimeFromDatePipe implements PipeTransform {

  transform(milliseconds: number): string {
    if (milliseconds == null) {
        return null;
    }
    return moment(milliseconds).format('hh:mm A');
}

}
