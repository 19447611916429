export class JobItemQrCode {
    public id: string;
    public consecutive: number;
    public code: string;
    public color_hex: string;
    public job_id: string;
    public job_inventory_item_id: string;
    public assigned_by_employee_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.consecutive = null;
        this.code = null;
        this.color_hex = '#030303';
        this.job_id = null;
        this.job_inventory_item_id = null;
        this.assigned_by_employee_id = null;
        this.created = null;
        this.updated = null;
    }
}
