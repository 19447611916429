import { ConstantsMessages } from 'src/app/constants-messages';
import { TicketView } from 'src/app/entities/workspace/ticket-view';
import { TicketsService } from 'src/app/services/companies/tickets.service';
import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { HelperService } from 'src/app/services/helper.service';
import { WorkDepartmentsService } from 'src/app/services/companies/work-departments.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';

declare const jQuery;

@Component({
    selector: 'app-tickets',
    templateUrl: './tickets.component.html',
    styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;

    rows: Array<TicketView>;
    actualPage: number;
    filterSearch: string;

    workDepartmentId: string;
    employeeId: string;
    typeTicket: string;
    statusTicket: string;
    priorityTicket: string;

    workDepartments;
    employees;
    public employeesToShow: EmployeeView[];
    public workDepartment: WorkDepartment;
    typeTickets;
    statusTickets;
    priorityTickets;

    constructor(
        private employeesService: EmployeesService,
        private ticketService: TicketsService,
        private workDepartmentsService: WorkDepartmentsService,
        private helperService: HelperService,
        private ticketsTypesService: TicketsTypesService,
    ) {
        this.workDepartmentId = '';
        this.employeeId = '';
        this.typeTicket = '';
        this.priorityTicket = '';
        this.statusTicket = '';
        this.filterSearch = '';

        this.rows = [];

        this.employees = [];
        this.employeesToShow = [];
        this.typeTickets = [];
        this.statusTickets = [];
        this.priorityTickets = [];
        this.workDepartments = [];

        this.actualPage = 1;

        this.workDepartment = new WorkDepartment();
    }

    ngOnInit(): void {
      
    }

    ngAfterViewInit(): void{
        this.loadTickets();
        this.loadWorkDepartmets();
        this.loadEmployees();
        /* this.loadType(); */
        this.loadStatus();
        this.loadPriority();
        this.loadTicketsType();
    }

    setWorkDepartment(idWorkDeparment) {
        for (let workDepartment of this.workDepartments) {
            if (idWorkDeparment == workDepartment.id) {
                this.workDepartment = workDepartment;
                break;
            }
        }
        const THIS = this;
        setTimeout(() => {
            THIS.filterEmployees();
        }, 500);
    }

    filterEmployees() {
        this.employeesToShow = [];
        for (let employee of this.employees) {
            if (this.workDepartmentId == '' || this.workDepartment.employees_id.indexOf(employee.id) >= 0) {
                this.employeesToShow.push(employee);
            }
        }
    }

    /**
     * Carga la informacion de los empleados
     */
    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
                this.filterEmployees();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Metodo que carga los work departments para mostrarlos en el select
     */
    private loadWorkDepartmets() {
        this.helperService.showLoadingMxpro360();
        this.workDepartmentsService.getAll().then((response) => {
            this.workDepartments = response;
        })
        .catch((error) => {
            console.error('error', error);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    /**
     * Metodo que carga los tickets para mostrarlos en el select
     */
    private loadTickets() {
        this.helperService.showLoadingMxpro360();
        const data: any = {
            'full-view': '1'
        };
        this.ticketService
            .getAllWithFullView(data)
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Metodo que carga el tipo del ticket para mostrarlo en el select
     */
    /* private loadType() {
        this.helperService.showLoadingMxpro360();
        this.ticketService
            .getAll()
            .then((response) => {
                response.forEach(element => {
                    if (this.typeTickets.indexOf(element.type) === -1) {
                        this.typeTickets.push(element.type);
                    }
                });
                console.log(this.typeTickets);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    } */

    /**
     * Metodo que carga el status del ticket para mostrarlo en el select
     */
    private loadStatus() {
        this.helperService.showLoadingMxpro360();
        this.ticketService
            .getAll()
            .then((response) => {
                response.forEach(element => {
                    if (this.statusTickets.indexOf(element.status) === -1) {
                        this.statusTickets.push(element.status);
                    }
                });
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Metodo que carga la priority del ticket para mostrarlo en el select
     */
    private loadPriority() {
        this.helperService.showLoadingMxpro360();
        this.ticketService
            .getAll()
            .then((response) => {
                response.forEach(element => {
                    if (this.priorityTickets.indexOf(element.priority) === -1) {
                        this.priorityTickets.push(element.priority);
                    }
                });
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Metodo que filtra la busqueda de los select
     */
    getFilter() {
        const data: any = {
            'full-view': '1'
        };

        if (this.workDepartmentId) {
            data.work_department_id = this.workDepartmentId;
        }
        if (this.employeeId) {
            data.employee_id = this.employeeId;
        }
        if (this.typeTicket) {
            data.type = this.typeTicket;
        }
        if (this.statusTicket) {
            data.status = this.statusTicket;
        }
        if (this.priorityTicket) {
            data.priority = this.priorityTicket;
        }

        this.helperService.showLoadingMxpro360();

        this.ticketService
            .getAllWithFullView(data)
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    removeTickets(id) {
        this.helperService.showLoadingMxpro360();
        this.ticketService
            .remove(id)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                this.loadTickets();
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadTicketsType() {
        this.helperService.showLoadingMxpro360();
        this.ticketsTypesService
          .getAll()
          .then((response) => {
            this.typeTickets = response;
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }

}
