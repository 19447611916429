import { Address } from "../global/address";
import { CreditCard } from "../workspace/credit-card";

export class WorkspaceBillingProfile {

    id: string;
    company: string;
    email: string;
    address: Address;
    credit_card: CreditCard;
    billing_plan_id: string;
    gateway_customer_profile_id: string;
    gateway_payment_profile_id: string;
    created: number;
    updated: number;


    constructor() {
        this.id = null;
        this.company = null;
        this.email = null;
        this.address = null;
        this.credit_card = null;
        this.billing_plan_id = null;
        this.gateway_customer_profile_id = null;
        this.gateway_payment_profile_id = null;
        this.created = null;
        this.updated = null;
    }
}
