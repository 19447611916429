<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
            <a appWorkspaceRouterLink="/zones/add" class="btn btn-new">
                <i class="fas fa-plus-square"></i> &nbsp; New Zones
            </a> 
        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>zone</th>
                                <th>created</th>
                                <th>color</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of tariffVersionsSettingsService.zones | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{i+1}}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.created | dateTime}}</td>
                                <td>
                                  <div *ngIf="row.color">
                                    <span>MAP</span>
                                    <button disabled="disabled" class="button-color" [style]="'background:' + row.color"></button>
                                  </div>
                                  <div *ngIf="row.color == null">
                                    <span>Color Not Selected</span>
                                   </div>
                                </td>
                                <td>
                                    <a appWorkspaceRouterLink="/zones/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <!-- body -->

</div>
