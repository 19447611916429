<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Details Estimate Email Template
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/settings">Settings</a></li>
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/email-templates">Estimates Email Templates</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Details</li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>
        </div>
    </div><!-- header -->

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area">
                    <div class="d-flex justify-content-between">
                        <h3 class="">Details</h3>
                        <a appCompanyRouterLink="/email-templates/{{row.id}}/edit" class="mt-2 edit-profile"> <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-edit-3">
                                <path d="M12 20h9"></path>
                                <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                            </svg></a>
                    </div>
                    <!--<div class="text-center user-info">
                        <img src="assets/img/90x90.jpg" alt="avatar">
                        <p class="">
                            {{row.name}}
                        </p>
                    </div> -->
                    <div class="user-info-list">
                        <div class="">
                            <ul class="contacts-block list-unstyled">
                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    {{row.subject}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div class="user-profile layout-spacing">
                <div class="widget-content widget-content-area">
                    <div class="d-flex justify-content-between">
                        <h3 class="">Content</h3>
                    </div>
                    <div class="user-info-list">
                        <div class="">
                            {{row.body}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
