import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TicketAssignmentRule } from 'src/app/entities/workspace/ticket-assignment-rule';
import { TicketAssignmentRuleView } from 'src/app/entities/workspace/ticket-assignment-rule-view';

@Injectable({
    providedIn: 'root'
})
export class TicketAssignmentRulesService {


    constructor(private http: HttpClient) {

    }
    
    getAllWithFullView(): Promise<Array<TicketAssignmentRuleView>> {
        return this.http.get<Array<TicketAssignmentRuleView>>(environment.api.company + '/ticket-assignment-rules', { params: { "full-view": "1" } }).toPromise();
    }

    getAll(): Promise<Array<TicketAssignmentRule>> {
        return this.http.get<Array<TicketAssignmentRule>>(environment.api.company + '/ticket-assignment-rules').toPromise();
    }

    getById(id: string) {
        return this.http.get<TicketAssignmentRule>(environment.api.company + '/ticket-assignment-rules/' + id).toPromise();
    }

    save(dataForm: TicketAssignmentRule): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm: TicketAssignmentRule) {
        return this.http.post(environment.api.company + '/ticket-assignment-rules', dataForm).toPromise();
    }

    edit(dataForm: TicketAssignmentRule) {
        return this.http.put(environment.api.company + '/ticket-assignment-rules/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/ticket-assignment-rules/' + id).toPromise();
    }
}
