import { Component, OnChanges, EventEmitter, Output, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';


declare var jQuery;
declare const moment;

@Component({
    selector: 'app-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {


    @Output()onChange: EventEmitter<BetweenDates>;

    @Input() useAll: boolean;
    @Input() defaultSelectDateType: number;
    @Input() defaultDateStart: number;
    @Input() defaultDateEnd: number;
    @Input() isMonthBefore: boolean; // validamos si es el mes anterior

    // indica el tipo de filtro
    selectDateType: number;

    // variable indicar donde se almacenará las fechas seleccionadas
    betweenFilter: BetweenDates;

    // variable indicar donde se almacenará el mes seleccionado
    monthSelected: string;

    constructor( private ref: ChangeDetectorRef) {
        this.onChange = new EventEmitter();
        this.betweenFilter = new BetweenDates();
        this.selectDateType = 4;
        this.monthSelected = moment().month(); // inicialmente asignamos el mes actual
        this.useAll = true;

        this.defaultSelectDateType = null;
        this.defaultDateStart = null;
        this.defaultDateEnd = null;
        this.isMonthBefore = true;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.listenChanges();
        if (this.useAll === null) {
            this.useAll = true;
        }
        if (this.defaultSelectDateType !== null) {
            this.selectDateType = this.defaultSelectDateType;
        }

        if (this.isMonthBefore) {
            this.betweenFilter.dateStart = moment().second(0).minute(0).hour(0).subtract(1, 'month').unix() * 1000;
            this.betweenFilter.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000
        } else {
            this.betweenFilter.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
            this.betweenFilter.dateEnd = moment().second(59).minute(59).hour(23).add(1, 'month').unix() * 1000
        }

    }

    setTypeDateFilter() {
        switch (this.selectDateType) {
            case 1: {
                // today
                this.betweenFilter.dateStart = moment().second(0).minute(0).hour(0).unix() * 1000;
                this.betweenFilter.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
                break;
            }
            case 2: {
                // 15 days
                // this.betweenFilter.dateStart = moment().subtract(15, 'd').second(0).minute(0).hour(0).unix() * 1000;
                // this.betweenFilter.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
                this.betweenFilter.dateStart = moment().startOf('week').unix() * 1000;
                this.betweenFilter.dateEnd = moment().endOf('week').unix() * 1000;
                break;
            }
            case 3: {
                this.betweenFilter.dateStart = moment().month(this.monthSelected).date(1).second(0).minute(0).hour(0).unix() * 1000;
                this.betweenFilter.dateEnd = moment().month(this.monthSelected).date(moment().month(this.monthSelected).daysInMonth()).second(59).minute(59).hour(23).unix() * 1000;
                break;
            }
            case 4: {
                // between
                // se ejecuta por la funcion selectBetween();
                break;
            }

            case 5 : {
                this.betweenFilter.dateStart = moment().startOf('week').subtract(7, 'd').unix() * 1000;
                this.betweenFilter.dateEnd = moment().endOf('week').subtract(7, 'd').unix() * 1000;
            }
        };
        this.onChange.emit(this.betweenFilter);
        setTimeout(() => {
            this.ref.detectChanges();
        }, 1000);
    }

    onRange(arrayDates: number[]) {

        // seteamos las fechas obtenidas del data picker
        if (arrayDates[1] > 0 && arrayDates[0] > 0) {
            this.betweenFilter.dateStart = moment(arrayDates[0]).second(0).minute(0).hour(0).unix() * 1000;
            this.betweenFilter.dateEnd = moment(arrayDates[1]).second(59).minute(59).hour(23).unix() * 1000;

            // retorna el valor asignado
            this.onChange.emit(this.betweenFilter);
        }

    }

    listenChanges(){
        setTimeout(() => {
            this.setTypeDateFilter();
            // retorna el valor asignado
            this.onChange.emit(this.betweenFilter);
        }, 100);

    }

}
