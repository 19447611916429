import { Geospatial } from '../global/geospatial';

export class ZoneState {
    id: string;
    tariff_version_id: string;
    name: string;
    code: string;
    zip_code: string;
    radius: number;
    geospatial: Geospatial;
    priority: number;
    zone_id: string;
    created: number;

    constructor(){
        this.id = null;
        this.tariff_version_id = null;
        this.name = null;
        this.code = null;
        this.zip_code = null;
        this.radius = null;
        this.geospatial = new Geospatial();
        this.priority = null;
        this.zone_id = null;
        this.created = null;
    }
}
