import { ActivatedRoute } from '@angular/router';
import { Address } from 'src/app/entities/global/address';
import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Customer } from 'src/app/entities/workspace/customer';
import { environment } from 'src/environments/environment';
import { EstimateDelivery } from 'src/app/entities/workspace/estimate-delivery';
import { EstimateInventoryContainer } from 'src/app/entities/workspace/estimate-inventory-container';
import { EstimatePickup } from 'src/app/entities/workspace/estimate-pickup';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { EstimateStopPoint } from 'src/app/entities/workspace/estimate-stop-point';
import { EstimateStopPointsService } from 'src/app/services/companies/estimate-stop-points.service';
import { File } from 'src/app/entities/global/file';
import { HelperService } from 'src/app/services/helper.service';
import { infoEstimateLabelQrView } from 'src/app/entities/info-estimate-label-qr-view';
import { InventoryCategory } from 'src/app/entities/workspace/inventory-category';
import { InventoryItem } from 'src/app/entities/workspace/inventory-item';
import { InventoryItemsService } from 'src/app/services/companies/inventory-items.service';
import { JobAppointmentView } from 'src/app/entities/workspace/job-appointment-view';
import { JobInventoryItem } from 'src/app/entities/workspace/job-inventory-item';
import { JobItemQrCode } from 'src/app/entities/job-item-qr-code';
import { JobItemQrCodesService } from 'src/app/services/companies/job-item-qr-codes.service';
import { JobNote } from 'src/app/entities/workspace/job-note';
import { JobNotesService } from 'src/app/services/companies/job-notes.service';
import { JobOperationStatus } from 'src/app/entities/workspace/job-operation-status';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { JobStatus } from 'src/app/entities/workspace/job-status';
import { JobView } from 'src/app/entities/workspace/job-view';
import { QuotesItemsContainersService } from 'src/app/services/companies/quotes-items-containers.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare const jQuery;
declare const swal;
declare const google;

@Component({
    selector: 'app-estimates-operations',
    templateUrl: './estimates-operations.component.html',
    styleUrls: ['./estimates-operations.component.scss']
})
export class EstimatesOperationsComponent implements OnInit {

    private map;

    public allQr: JobItemQrCode[];
    public constantsMessages = ConstantsMessages;
    public curentInventoryCategories: Array<InventoryCategory>;
    public curentInventoryCategoriesSummaryItems: Array<InventoryCategory>;
    public customer: Customer;
    public customInventoryItem: boolean;
    public deliveryToEdit: EstimateDelivery;
    public disableButtonSaveStopPoint: boolean;
    public editDays: JobAppointmentView;
    public estimateStopPoint: EstimateStopPoint;
    public filterJobInventorSummaryItems: string;
    public filterJobInventoryItems: string;
    public fromAddress: Address;
    public fromSecondAddress: Address;
    public imgItem: string;
    public infoEstimateLabelQr: infoEstimateLabelQrView;
    public infowindow: any;
    public inventoryCategories: Array<InventoryCategory>;
    public inventoryItems: Array<InventoryItem>;
    public itemsQrNotAssigned: JobItemQrCode[];
    public job: JobView;
    public jobDriversLicense: any;
    public jobId: string;
    public jobInventoryItem: JobInventoryItem;
    public jobInventoryItems: any;
    public jobInventoryRemovedItems: any;
    public jobInventorySummaryItems: any;
    public jobItemQrByCode: JobItemQrCode;
    public jobNote: JobNote;
    public jobNotes: Array<JobNote>;
    public jobOperatingStatus: JobOperationStatus;
    public jobOperatingStatusHistory: Array<JobOperationStatus>;
    public jobStatus: JobStatus;
    public jobStatusesComment: string;
    public laborTotalHours: number;
    public movingInventoryItems: Array<InventoryItem>;
    public pickupToEdit: EstimatePickup;
    public previewImages: File[];
    public showDriverLicense: string;
    public showModalJobInventoryItems: boolean
    public showModalJobInventorySummaryItems: boolean
    public stopPoints: Array<EstimateStopPoint>;
    public titleArchiveDocuments: string;
    public toAddress: Address;
    public toSecondAddress: Address;
    public totalItems: number;
    public typeArchive: string | 'archive-delivered' | 'archive-picked';
    public urlBase: string;
    public workType: String;

    @ViewChild('mapView') entityForm: ElementRef;
    @ViewChild('mapView') mapView: ElementRef;
    @ViewChild('modalArchive') modalArchive: ElementRef;
    @ViewChild('modalEditDay') modalEditDay: ElementRef;
    @ViewChild('modalEditStatus') modalEditStatus: ElementRef;
    @ViewChild('modalImagesPreview') modalImagesPreview: ElementRef;
    @ViewChild('modalJobInventoryItems') modalJobInventoryItems: ElementRef;
    @ViewChild('modalJobInventorySummaryItems') modalJobInventorySummaryItems: ElementRef;
    @ViewChild('modalNotes') modalNotes: ElementRef;
    @ViewChild('modalOperatingStatusHistory') modalOperatingStatusHistory: ElementRef;
    @ViewChild('modalSearchOperatingItems') modalSearchOperatingItems: ElementRef;
    @ViewChild('movingModal') movingModal: ElementRef;
    @ViewChild('stopPointModal') stopPointModal: ElementRef;
    @ViewChild('modalShowDriverLicense') modalShowDriverLicense: ElementRef;
    
    public idItemSelected: string;

    constructor(
        private authService: AuthService,
        private currentRoute: ActivatedRoute,
        private estimatesService: EstimatesService,
        private estimateStopPointsService: EstimateStopPointsService,
        private helperService: HelperService,
        private inventoryItemsService: InventoryItemsService,
        private jobItemQrCodesService: JobItemQrCodesService,
        private jobNotesService: JobNotesService,
        private jobsService: JobsService,
        private quotesItemsContainersService: QuotesItemsContainersService,
        private uploadFilesService: UploadFilesService,
    ) {
        this.allQr = [];
        this.curentInventoryCategories = [];
        this.curentInventoryCategoriesSummaryItems = [];
        this.customer = new Customer();
        this.customInventoryItem = true;
        this.deliveryToEdit = new EstimateDelivery();
        this.disableButtonSaveStopPoint = true;
        this.editDays = new JobAppointmentView();
        this.estimateStopPoint = new EstimateStopPoint();
        this.filterJobInventorSummaryItems = 'all';
        this.filterJobInventoryItems = 'all';
        this.fromAddress = new Address();
        this.fromSecondAddress = new Address();
        this.idItemSelected = '';
        this.imgItem = "";
        this.infoEstimateLabelQr = new infoEstimateLabelQrView();
        this.inventoryCategories = [];
        this.inventoryItems = [];
        this.itemsQrNotAssigned = [];
        this.job = new JobView();
        this.jobDriversLicense = [];
        this.jobInventoryItem = new JobInventoryItem();
        this.jobInventoryItems = [];
        this.jobInventoryRemovedItems = [];
        this.jobInventorySummaryItems = [];
        this.jobItemQrByCode = new JobItemQrCode();
        this.jobNote = new JobNote();
        this.jobNotes = [];
        this.jobOperatingStatus = new JobOperationStatus();
        this.jobStatus = new JobStatus();
        this.jobStatusesComment = '';
        this.laborTotalHours = 0;
        this.movingInventoryItems = [];
        this.pickupToEdit = new EstimatePickup();
        this.previewImages = [];
        this.showDriverLicense = '';
        this.showModalJobInventoryItems = false;
        this.showModalJobInventorySummaryItems = false;
        this.stopPoints = [];
        this.titleArchiveDocuments = '';
        this.toAddress = new Address();
        this.toSecondAddress = new Address();
        this.typeArchive = '';
        this.urlBase = environment.api.base;
    }

    ngOnInit(): void {
        this.loadInventoryItems();
    }

    ngAfterViewInit(): void {
        // verficamos si se esta editando
        this.currentRoute
            .parent
            .params.subscribe(params => {
                if (typeof params.id !== 'undefined') {
                    //this.loadAllData(params.id);                    
                    this.jobId = params.id;
                    this.load(params.id);
                    this.loadInventoryItemsFromEstimate(params.id);
                    this.loadStopPoints();
                }
            });

            const that = this;
            jQuery(this.modalJobInventoryItems.nativeElement).on("change", '#globalInventoryItems', function(e) { 
                setTimeout(() => {
                    that.listenEventInventoryItem(that.jobInventoryItem.name);
                }, 100);        
            });
            jQuery(this.modalJobInventorySummaryItems.nativeElement).on("change", '#itemSummary', function(e) { 
                setTimeout(() => {
                    that.listenEventInventoryItem(that.jobInventoryItem.name);
                }, 100);        
            });

            /* Driver's License */
            jQuery('#btn-nav-previous').click(function(){
                jQuery(".carousel-driver-license").animate({scrollLeft: "-=200px"});
            });            
            jQuery('#btn-nav-next').click(function(){
                jQuery(".carousel-driver-license").animate({scrollLeft: "+=200px"});
            });
            
    }

    /**
     * carga los qr
     */
    loadQrLabels(job_id: string): void {
        this.jobItemQrCodesService
            .getAll(job_id)
            .then(response => {
                this.allQr = response;
                this.itemsQrNotAssigned = [];
                for (const iterator of response) {
                    if (iterator.job_inventory_item_id === null) {
                        this.itemsQrNotAssigned.push(iterator);
                    }
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.helperService
            .getInfoEstimateLabelQr(this.jobId)
            .then((info) => {
                this.infoEstimateLabelQr = info;
            })
    }

    loadInventoryCategories() {

        this.helperService.showLoadingMxpro360();
        this.jobsService
            .loadInventoryCategories()
            .then((response) => {
                this.inventoryCategories = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadInventoryItems() {
        this.helperService.showLoadingMxpro360();
        this.inventoryItemsService
            .getAll()
            .then((response: Array<InventoryItem>) => {
                this.inventoryItems = response;
            });
    }

    loadInventoryItemsFromEstimate(job_id: string) {
        this.helperService.showLoadingMxpro360();
        this.quotesItemsContainersService
            .get(job_id)
            .then((rows: Array<EstimateInventoryContainer>) => {
                this.movingInventoryItems = [];
                for (const containers of rows) {
                    for (const item of containers.items) {
                        this.movingInventoryItems.push(item.inventory_item);
                    }
                }
                this.checkInventoryAdded();
            });
    }

    public getCurentInventoryCategories(items, categories, type) {
        if (type === 'additional_items') {
            items.forEach((item) => {
                if (item.inventory_category_id !== null) {
                    categories.forEach((category) => {
                        if (item.inventory_category_id === category.id) {
                            if (this.curentInventoryCategories.indexOf(category) == -1) this.curentInventoryCategories.push(category);
                        }
                    });
                }
            });
        }
        if (type === 'summary_items') {
            items.forEach((item) => {
                if (item.inventory_category_id !== null) {
                    categories.forEach((category) => {
                        if (item.inventory_category_id === category.id) {
                            if (this.curentInventoryCategoriesSummaryItems.indexOf(category) == -1) this.curentInventoryCategoriesSummaryItems.push(category);
                        }
                    });
                }
            });
        }

    }

    public openUploadModal(fileType: string): void {
        this.uploadFilesService
            .openModal()
            .then((response: Array<File>) => {
                if (response.length == 0) {
                    return;
                }
                // guardar datos que se obtienen del modal
                this.helperService.showLoadingMxpro360();
                this.jobsService
                    .saveMovingFile(this.job.id, fileType, response)
                    .then((response) => {
                        this.load(this.job.id);
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
            });
    }

    public openUploadModalItemsFiles(): void {
        this.uploadFilesService
            .openModal()
            .then((response: Array<File>) => {
                if (response.length == 0) {
                    return;
                }
                // guardar datos que se obtienen del modal
                this.helperService.showLoadingMxpro360();
                this.jobInventoryItem.images = [];
                for (let file of response) {
                    this.jobInventoryItem.images.push(file);
                }
                this.helperService.hideLoadingMxpro360();
            });
    }

    onChangeLogo(files: Array<File>) {
        if (files.length > 0) {
            this.jobInventoryItem.images = files;
        }
    }

    private load(id: string) {

        this.helperService.showLoadingMxpro360();
        this.curentInventoryCategories = [];
        this.curentInventoryCategoriesSummaryItems = [];
        this.loadInventoryCategories();
        this.loadJobInventoryItems(id);
        this.loadJobInventorySummaryItems(id);
        this.loadJobInventoryRemovedItems(id);
        this.loadQrLabels(id);

        this.jobsService
            .getById(id)
            .then((response) => {
                this.job = response;
                // asignamos informacion temporalmente
                this.customer = this.job.estimate.customer;
                this.loadStatusHistoryCharge(this.job.id);
                this.loadNotes();
                this.loadDriversLicense();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadDriversLicense(){
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .loadDriversLicense(this.job.id)
            .then((response) => {
                this.jobDriversLicense = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        
    }

    saveOperatingStatus(saveHistory: boolean) {
        if (saveHistory) {
            this.job.statuses.operation = this.jobStatus.operation;
        }
        let data = {
            job_status: this.job.statuses,
            job_operation_status: this.jobOperatingStatus,
            save_history: saveHistory
        }
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .forceStatusCharge(this.job.id, data)
            .then((response) => {
                this.loadStatusHistoryCharge(this.job.id);

            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadStatusHistoryCharge(id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getStatusHistoryCharge(id)
            .then((response: any) => {
                if (response.jobLocalLogs) {
                    this.jobOperatingStatusHistory = response.jobLocalLogs;
                    this.laborTotalHours = response.laborTotalHours
                }
                this.jobOperatingStatusHistory = response.jobLocalLogs? response.jobLocalLogs: response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadNotes() {
        this.helperService.showLoadingMxpro360();
        this.jobNotesService
            .getAllWithFullView(this.job.id)
            .then((response) => {
                this.jobNotes = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveNotes() {
        this.helperService.showLoadingMxpro360();
        this.jobNotesService
            .saveNoteJob(this.job.id, this.jobNote)
            .then((response) => {
                this.loadNotes();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveArchive() {
        this.helperService.showLoadingMxpro360();
        if (this.typeArchive === 'archive-picked') {
            this.jobStatus.archived_picked_docs = true;
            this.jobStatus.comment_archived_picked_docs = this.jobStatusesComment;
        }

        if (this.typeArchive === 'archive-delivered') {
            this.jobStatus.archived_delivered_docs = true;
            this.jobStatus.comment_archived_delivered_docs = this.jobStatusesComment;
        }


        this.jobNotesService
            .saveNoteArchiveJob(this.job.id, this.jobStatus)
            .then((response) => {
                this.load(this.job.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveDay() {
        delete this.editDays.crew;

        let data: any;
        switch (this.workType) {
            case 'pickups':
                data = {
                    pickup_day: this.editDays
                };

                break;
            case 'packing_day':
                data = {
                    pack_day: this.editDays
                };

                break;
            case 'boxes_delivery_day':
                data = {
                    box_delivery_day: this.editDays
                };

                break;
            case 'deliveries':
                data = {
                    delivery_day: this.editDays
                };

                break;
        }
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .patchEntity(this.job.id, data)
            .then((response) => {
                this.load(this.job.id);
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openModalEditStatus() {
        jQuery(this.modalEditStatus.nativeElement).modal('show');
    }

    openModalStatusHistory() {
        jQuery(this.modalOperatingStatusHistory.nativeElement).modal('show');
    }

    openModalArchive(type: string) {
        jQuery(this.modalArchive.nativeElement).modal('show');
        this.jobStatus = new JobStatus();
        this.jobStatusesComment = '';
        this.typeArchive = type;
        if (this.typeArchive === 'archive-picked') {
            this.titleArchiveDocuments = 'Archive Without Pickup Documents';
        } else {
            this.titleArchiveDocuments = 'Archive Without Delivery Documents';
        }
    }

    openSearchOperatingItems(additionalItems: boolean) {
        this.showModalJobInventoryItems = additionalItems;
        this.showModalJobInventorySummaryItems = !additionalItems;
        this.jobItemQrByCode = new JobItemQrCode();
        this.openModalInventoryItem();
    }

    openSelectQr(additionalItems: boolean, idItemSelected) {
        this.idItemSelected = idItemSelected;
        this.showModalJobInventoryItems = additionalItems;
        this.showModalJobInventorySummaryItems = !additionalItems;
        this.jobItemQrByCode = new JobItemQrCode();
        jQuery(this.modalSearchOperatingItems.nativeElement).modal('show');
    }

    openModalNotes() {
        jQuery(this.modalNotes.nativeElement).modal('show');
    }

    openEditDay(work_type, day) {
        this.editDays = day;
        this.workType = work_type;
        jQuery(this.modalEditDay.nativeElement).modal('show');
    }

    deleteImage(file, fileType) {
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .deleteMovingFile(this.job.id, fileType, file.id)
            .then(() => {
                this.load(this.job.id);
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadJobInventoryItems(id: string) {
        this.filterJobInventoryItems = 'all';
        this.helperService.showLoadingMxpro360();
        this.totalItems = 0;

        this.jobsService
            .loadJobInventoryItems(id)
            .then((response) => {
                this.jobInventoryItems = response;
                this.getCurentInventoryCategories(this.jobInventoryItems, this.inventoryCategories, 'additional_items');
                this.imgItem = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/moving-files/';
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    loadJobInventorySummaryItems(id: string) {
        this.helperService.showLoadingMxpro360();
        this.filterJobInventorSummaryItems = 'all';

        this.totalItems = 0;

        this.jobsService
            .loadJobInventorySummaryItems(id)
            .then((response) => {
                this.jobInventorySummaryItems = response;
                this.getCurentInventoryCategories(this.jobInventorySummaryItems, this.inventoryCategories, 'summary_items');
                this.imgItem = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/moving-files/';
                this.checkInventoryAdded();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadJobInventoryRemovedItems(id: string) {
        this.helperService.showLoadingMxpro360();
        this.filterJobInventorSummaryItems = 'all';

        this.totalItems = 0;

        this.jobsService
            .loadJobInventoryRemovedItems(id)
            .then((response) => {
                this.jobInventoryRemovedItems = response;
                this.getCurentInventoryCategories(this.jobInventorySummaryItems, this.inventoryCategories, 'summary_items');
                this.imgItem = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/moving-files/';
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    searchJobItemQrByCode() {

        if (this.jobItemQrByCode.code == '') {
            swal({
                title: 'Attention!',
                text: "The label was not read",
                type: 'warning',
                padding: '2em'
            }); return;
        }

        this.helperService.showLoadingMxpro360();
        this.jobsService
            .searchJobItemQrByCode(this.jobItemQrByCode.code)
            .then((response: JobItemQrCode[]) => {

                if (response.length === 0) {
                    swal({
                        title: 'Attention!',
                        text: "This label does not exist",
                        type: 'warning',
                        padding: '2em'
                    }); return;
                }

                // this.openModalInventoryItem(response[0]);
            })
            .catch((error) => {
                console.error('Error: ', error);
                this.jobItemQrByCode.code = null;
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openModalInventoryItem() {
        jQuery(this.modalSearchOperatingItems.nativeElement).modal('hide');
        if (this.jobItemQrByCode.job_inventory_item_id !== null) {
            swal({
                title: 'Attention!',
                text: "This label is already linked with another item",
                type: 'warning',
                padding: '2em'
            }); return;
        } else {

            // reseteamos el valor
            this.customInventoryItem = false;

            this.jobInventoryItem = new JobInventoryItem();
            if (this.showModalJobInventoryItems) {
                jQuery(this.modalJobInventoryItems.nativeElement).modal('show');
            }
            if (this.showModalJobInventorySummaryItems) {
                jQuery(this.modalJobInventorySummaryItems.nativeElement).modal('show');
            }
          
        }
    }

    dataDismiss() {
        this.jobItemQrByCode.code = null;
        jQuery(this.modalSearchOperatingItems.nativeElement).modal('hide');
    }

    finishJob() {
        this.job.statuses.operation = "DELIVERED";
        this.job.statuses.general = "WORK_CARRIED_OUT";
        let data = {
            job_status: this.job.statuses,
            job_operation_status: this.jobOperatingStatus,
            save_history: false
        }
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .forceStatusCharge(this.job.id, data)
            .then(() => {
                this.loadStatusHistoryCharge(this.job.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    saveAdditionalItems() {
        for (let inventoryItem of this.inventoryItems) {
            if (inventoryItem.name == this.jobInventoryItem.name) {
                for (let inventoryCategory of this.inventoryCategories) {
                   if(inventoryCategory.id == inventoryItem.inventory_category_id){
                    this.jobInventoryItem.inventory_category_id = inventoryCategory.id;
                   }
                }
            }
        
        }
        this.jobInventoryItem.codeItemQr = this.jobItemQrByCode.code;
        this.jobInventoryItem.is_additional = true;
        if (this.jobInventoryItem.name === null) {
            swal({
                title: 'Attention!',
                text: "Name must not be empty.",
                type: 'warning',
                padding: '2em'
            });
            return;
        }
        this.jobInventoryItem.job_id = this.jobId;
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .saveJobInventoryItem(this.jobInventoryItem)
            .then((response) => {
                // revisar porque no carga
                this.loadJobInventoryItems(this.jobId);
                // window.location.reload();
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                jQuery(this.modalJobInventoryItems.nativeElement).modal('hide');
                this.helperService.hideLoadingMxpro360();
            });

    }

    saveAdditionalSummaryItems() {
        for (let inventoryItem of this.inventoryItems) {
            if (inventoryItem.name == this.jobInventoryItem.name) {
                for (let inventoryCategory of this.inventoryCategories) {
                   if(inventoryCategory.id == inventoryItem.inventory_category_id){
                    this.jobInventoryItem.inventory_category_id = inventoryCategory.id;
                   }
                }
            }
        
        }
        this.jobInventoryItem.codeItemQr = this.jobItemQrByCode.code;
        this.jobInventoryItem.is_additional = false;
        if (this.jobInventoryItem.name === null) {
            swal({
                title: 'Attention!',
                text: "Name must not be empty.",
                type: 'warning',
                padding: '2em'
            });
            return;
        }
        this.jobInventoryItem.job_id = this.jobId;
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .saveJobInventoryItem(this.jobInventoryItem)
            .then((response) => {
                // revisar porque no carga
                this.loadJobInventorySummaryItems(this.jobId);
                // window.location.reload();
            })
            .catch((error) => {
                console.error('Error: ', error);
            })
            .finally(() => {
                jQuery(this.modalJobInventorySummaryItems.nativeElement).modal('hide');
                this.helperService.hideLoadingMxpro360();
            });

    }

    /**
     * 
     * @param inventoryCategory 
     */
    getJobInventoryItems(inventoryCategory) {
        this.filterJobInventoryItems = inventoryCategory.name;
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .loadJobInventoryItemsByCategory(this.jobId, inventoryCategory.id)
            .then((response) => {
                this.jobInventoryItems = response;
                this.imgItem = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/moving-files/';
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    /**
     * 
     * @param inventoryCategory 
     */
    getJobInventorySummaryItems(inventoryCategory) {
        if (inventoryCategory === 'all') {
            return
        }
        this.filterJobInventorSummaryItems = inventoryCategory.name;
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .loadJobInventorySummaryItemsByCategory(this.jobId, inventoryCategory.id)
            .then((response) => {
                this.jobInventorySummaryItems = response;                
                this.imgItem = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/moving-files/';
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    openModalPreviewImages(images: File[]) {
        this.previewImages = [];
        for (let image of images) {
            this.previewImages.push(image);
        }
        jQuery(this.modalImagesPreview.nativeElement).modal('show');
    }

    deleteImageInventoryItem(image) {
        for (let i = this.jobInventoryItem.images.length - 1; i >= 0; i--) {
            if (this.jobInventoryItem.images[i].display_name === image.display_name) {
                this.jobInventoryItem.images.splice(i, 1);
                break;
            }
        }
    }

    selectQRLabel(qrLabel: JobItemQrCode) {

        //this.openModalInventoryItem(qrLabel);
    }

    /**
     * permite escuchar el item de inventario seleccionado, esto cuando se agregar un inventem de inventario
     * @param inventoryItemName 
     */
    listenEventInventoryItem(inventoryItemName: string) {
        if (inventoryItemName == "CUSTOM") {
            this.customInventoryItem = true;
            this.jobInventoryItem.name = null;
        } else {
            for (let inventoryItem of this.inventoryItems) {
                if (inventoryItem.name == this.jobInventoryItem.name) {
                    this.jobInventoryItem.cubic_feets = inventoryItem.cubic_feet;
                }
            
            }
        }
        
    }

    openStopPointModal() {
        this.estimateStopPoint = new EstimateStopPoint();
        this.estimateStopPoint.address = new Address();
        this.closeStopPointModal();
        jQuery(this.stopPointModal.nativeElement).modal('show');
    }

    closeStopPointModal() {
        jQuery(this.stopPointModal.nativeElement).modal('hide');
    }
    async addAddressStopPoint(event: Address) {
        this.estimateStopPoint.address = event;
        await this.searchWithLongLan(event.geospatial.coordinates[0], event.geospatial.coordinates[1]);
        this.validateEnableButtonSaveStopPoint();
    }

    saveStopPoint(): void {
        this.estimateStopPoint.estimate_id = this.job.id;
        this.helperService.showLoadingMxpro360();
        this.estimateStopPointsService
            .save(this.estimateStopPoint)
            .then(() => {
                this.load(this.job.id);
                this.loadStopPoints();
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
            })
            .catch((error) => {
                if (error['error']['message']) {
                    swal('Error', error['error']['message'], 'error');
                } else {
                    this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                }
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Metodo que elimina los stop points listados en el detalle de un estimate
     */
    removeStopPoint(stopPoint: EstimateStopPoint): void {
        this.helperService.showLoadingMxpro360();
        this.estimateStopPointsService
            .remove(stopPoint.estimate_id, stopPoint.id)
            .then(() => {
                this.loadStopPoints();
                this.load(this.job.id);
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }

    private loadStopPoints(): void {
        this.helperService.showLoadingMxpro360();
        this.estimateStopPointsService
            .getAll(this.jobId)
            .then(response => {
                this.stopPoints = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }
    validateEnableButtonSaveStopPoint() {
        this.disableButtonSaveStopPoint =
            this.estimateStopPoint.type == null &&
            this.estimateStopPoint.address.zip_code == null;
    }

    private searchWithLongLan(long, lat) {
        return new Promise((resolve) => {

            const latlng = new google.maps.LatLng(long, lat);
            const geocoder = new google.maps.Geocoder();

            geocoder.geocode({ latLng: latlng }, (results, status) => {

                // Se verifica si la busqueda de la direccion es correcta
                if (status === google.maps.GeocoderStatus.OK) {

                    if (results[0]) {
                        for (let j = 0; j < results[0].address_components.length; j++) {
                            if (results[0].address_components[j].types[0] === 'postal_code') {
                                // Se agrega el zipcode
                                this.estimateStopPoint.address.zip_code = results[0].address_components[j].short_name;
                                if (results[0].address_components[1] && results[0].address_components[2]) {
                                    this.estimateStopPoint.address.street = results[0].address_components[0].short_name
                                        + ' ' + results[0].address_components[1].short_name + ' ' + results[0].address_components[2].short_name;
                                }
                                resolve(true);
                            }
                        }
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            }, () => {
                resolve(false);
            });
        });
    }

    openModalChangeAddress() {
        this.clonePickup();
        this.cloneDelivery();
        jQuery(this.movingModal.nativeElement).modal('show');
    }

    clonePickup() {
        this.pickupToEdit.id = this.job.estimate.pickup.id;
        this.pickupToEdit.boxes_delivery_day = this.job.estimate.pickup.boxes_delivery_day;
        this.pickupToEdit.pack_day = this.job.estimate.pickup.pack_day ;
        this.pickupToEdit.range_start = this.job.estimate.pickup.range_start;
        this.pickupToEdit.range_end = this.job.estimate.pickup.range_end;
        this.pickupToEdit.pickup_day = this.job.estimate.pickup.pickup_day;
        this.pickupToEdit.address = { ...this.job.estimate.pickup.address}
    }

    cloneDelivery() {
        this.deliveryToEdit.id = this.job.estimate.delivery.id;
        this.deliveryToEdit.deliver_immediately = this.job.estimate.delivery.deliver_immediately;
        this.deliveryToEdit.range_start = this.job.estimate.delivery.range_start;
        this.deliveryToEdit.range_end = this.job.estimate.delivery.range_end;
        this.deliveryToEdit.address = { ...this.job.estimate.delivery.address}
    }

    /**
     * Obtiene la ruta con los puntos de origen, puntos de parada (si hay) y el destino
     */
    private calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints): Promise<any> {

        return new Promise((resolve) => {
            directionsService.route({
                origin: pointA,
                destination: pointB,
                waypoints,
                travelMode: google.maps.TravelMode.DRIVING
            }, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setOptions({ suppressMarkers: true });
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    /**
     * Obtiene corrdenadas de un zipcode
     *
     * @param zipcode Zipcode a buscar
     */
    private getLatLang(zipcode) {
        return new Promise((resolve, reject) => {
            let lat: string;
            let lng: string;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: 'zipcode ' + zipcode }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    lat = results[0].geometry.location.lat();
                    lng = results[0].geometry.location.lng();
                    resolve({ lat, lng });
                } else {
                    reject('Request failed.');
                }
            });
        });
    }

    public async initializeMap(): Promise<void> {

        const THIS = this;
        // Punto inicial del recorrido
        let pointA;
        // Punto final del recorrido
        let pointB;

        // Se valida que existan coordenadas en el geospatial donde se indica que el usuario ha guardado sus direcciones
        if (this.job.estimate.pickup.address.geospatial.coordinates.length > 0) {
            pointA = new google.maps.LatLng(this.job.estimate.pickup.address.geospatial.coordinates[1],
                this.job.estimate.pickup.address.geospatial.coordinates[0]);
            pointB = new google.maps.LatLng(this.job.estimate.delivery.address.geospatial.coordinates[1],
                this.job.estimate.delivery.address.geospatial.coordinates[0]);
        } else {
            // Si no hay direcciones guardadas, se busca por el zipcode guardado para indicar el estado de la route delivery
            let pointsPickup;
            let pointsDelivery;
            pointsPickup = await this.getLatLang(this.job.estimate.pickup.address.zip_code);
            pointsDelivery = await this.getLatLang(this.job.estimate.delivery.address.zip_code);
            pointA = new google.maps.LatLng(pointsPickup.lat, pointsPickup.lng);
            pointB = new google.maps.LatLng(pointsDelivery.lat, pointsDelivery.lng);
        }

        this.map = new google.maps.Map(
            this.mapView.nativeElement as HTMLElement,
            {
                zoom: 3,
                center: new google.maps.LatLng(40.044389154226444, -98.50174726382909),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                zoomControl: true
            });

        // Instantiate a directions service.
        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
            map: this.map
        });

        // Se crean los marcadores
        const markerA = new google.maps.Marker({
            position: pointA,
            map: this.map
        });

        const markerB = new google.maps.Marker({
            position: pointB,
            map: this.map
        });

        // Se inicializa el arreglo que dibuja la ruta en el mapa de acuerdo a los puntos
        // establecidos por los puntos de parada
        const waypoints = [];
        let markerStopPoint;
        // Se verifica si hay puntos de parada agregados para ser dibujados y dibujar la ruta
        if (this.stopPoints.length > 0) {
            for (let index = 0; index < this.stopPoints.length; index++) {
                // Se agrega el punto de parada
                const pointRute = new google.maps.LatLng(this.stopPoints[index].address.geospatial.coordinates[0],
                    this.stopPoints[index].address.geospatial.coordinates[1]);

                // Se crea el marcador del punto de parada
                markerStopPoint = new google.maps.Marker({
                    icon: { url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png' },
                    position: pointRute,
                    map: this.map
                });

                waypoints.push({
                    location: pointRute,
                    stopover: true
                });

                // Se agrega el evento del marcador de delivery y pickup
                google.maps.event.addListener(markerStopPoint, 'click', async () => {

                    // Se obtiene la direccion del servicio
                    address = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');

                    if (THIS.infowindow) {
                        THIS.infowindow.close();
                    }

                    // Se dibuja en el infowindow la informacion
                    const contentString =
                        '<div id="content">' +
                        '<b class="text-center">' + this.stopPoints[index].type + '</b>' +
                        '<p> Street: ' + this.stopPoints[index].address.street + '</p>' +
                        '<p> City: ' + this.stopPoints[index].address.city + '</p>' +
                        '<p> State: ' + this.stopPoints[index].address.state + '</p>' +
                        '<p> Country: ' + this.stopPoints[index].address.country + '</p>' +
                        '</div>';

                    THIS.infowindow = new google.maps.InfoWindow({
                        content: contentString,
                    });

                    THIS.infowindow.setPosition({
                        lat: this.stopPoints[index].address.geospatial.coordinates[0],
                        lng: this.stopPoints[index].address.geospatial.coordinates[1]
                    });
                    THIS.infowindow.open(THIS.map);
                });
            }
        }

        // Se obtiene la ruta entre los puntos inciales, de parada y finales
        await this.calculateAndDisplayRoute(directionsService, directionsDisplay, pointA, pointB, waypoints);

        let address = '';
        let addressFrom = '';
        let addressTo = '';

        // Se obtienen las direcciones de recogida y entrega
        addressFrom = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');
        addressTo = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');

        // Se crea la informacion de las direcciones para mostrarlas en el mapa
        const card = document.createElement('div');
        const container = document.createElement('div');
        const titleFrom = document.createTextNode('* From: ');
        const brTitleFrom = document.createElement('br');
        const textFrom = document.createTextNode('Street: ' + addressFrom[0] + ', City: ' +
            addressFrom[1] + ', State: ' + addressFrom[2]);

        const titleTo = document.createTextNode('* To: ');
        const brTitleTo = document.createElement('br');
        const textTo = document.createTextNode('Street: ' + addressTo[0] + ', City: ' +
            addressTo[1] + ', State: ' + addressTo[2]);

        card.setAttribute('id', 'pac-card');
        container.setAttribute('id', 'pac-container');
        container.appendChild(titleFrom);
        container.appendChild(brTitleFrom);
        container.appendChild(textFrom);

        container.appendChild(document.createElement('br'));
        // Se verifica si hay puntos de parada agregados para ser dibujados y dibujar la ruta
        if (this.stopPoints.length > 0) {
            for (let index = 0; index < this.stopPoints.length; index++) {
                // Se agrega la informacion del stop point para mostarse en el detalle de la ruta
                const street = this.stopPoints[index].address.street === null ? '' : 'Street: ' + this.stopPoints[index].address.street;
                const city = this.stopPoints[index].address.city === null ? '' : ' City: ' + this.stopPoints[index].address.city;
                const state = this.stopPoints[index].address.state === null ? '' : ' State: ' + this.stopPoints[index].address.state;

                container.appendChild(document.createElement('br'));
                container.appendChild(document.createTextNode(this.stopPoints[index].type === 'PICKUP' ? '* Pickup: ' : '* Delivery: '));
                container.appendChild(document.createElement('br'));
                container.appendChild(document.createTextNode(street + city + state));
            }
        }
        container.appendChild(document.createElement('br'));
        container.appendChild(document.createElement('br'));
        container.appendChild(titleTo);
        container.appendChild(brTitleTo);
        container.appendChild(textTo);
        card.appendChild(container);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(card);

        // Se valida si hay un infowindow abierto, este sea cerrado
        function showAddresses() {
            if (THIS.infowindow) {
                THIS.infowindow.close();
            }
        }

        showAddresses();
        // Evento del click en el mapa
        THIS.map.addListener('click', (e) => {
            showAddresses();
        });

        google.maps.event.addListener(markerA, 'click', async () => {
            address = directionsDisplay.directions.routes[0].legs[0].start_address.split(', ');

            if (THIS.infowindow) {
                THIS.infowindow.close();
            }

            // Se dibuja en el infowindow la informacion
            const contentString =
                '<div id="content">' +
                '<b class="text-center">FROM</b>' +
                '<p> Street: ' + address[0] + '</p>' +
                '<p> City: ' + address[1] + '</p>' +
                '<p> State: ' + address[2] + '</p>' +
                '<p> Country: ' + address[3] + '</p>' +
                '</div>';

            THIS.infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            THIS.infowindow.setPosition({
                lat: pointA.lat(),
                lng: pointA.lng()
            });
            THIS.infowindow.open(THIS.map);
        });

        google.maps.event.addListener(markerB, 'click', async () => {
            address = directionsDisplay.directions.routes[0].legs[0].end_address.split(', ');
            if (THIS.infowindow) {
                THIS.infowindow.close();
            }
            const contentString =
                '<div id="content">' +
                '<b class="text-center">TO</b>' +
                '<p> Street: ' + address[0] + '</p>' +
                '<p> City: ' + address[1] + '</p>' +
                '<p> State: ' + address[2] + '</p>' +
                '<p> Country: ' + address[3] + '</p>' +
                '</div>';

            THIS.infowindow = new google.maps.InfoWindow({
                content: contentString,
            });

            THIS.infowindow.setPosition({
                lat: pointB.lat(),
                lng: pointB.lng()
            });
            THIS.infowindow.open(THIS.map);
        });

    }

    setFrom(from) {
        this.fromAddress = from;
    }
    setTo(to) {
        this.toAddress = to;
    }
    setSecondFrom(from) {
        this.fromSecondAddress = from;
    }
    setSecondTo(to) {
        this.toSecondAddress = to;
    }

    saveMoving() {
        const moving = {
          delivery: {
            address: this.toAddress,
            second_address: this.toSecondAddress
          },
          pickup: {
            address: this.fromAddress,
            second_address: this.fromSecondAddress
          }
        };
        this.helperService.showLoadingMxpro360();
    
        this.estimatesService
          .updatePickupAndDeliveryAddress(this.job.id, moving)
          .then((response) => {
            this.load(this.job.id);
            this.loadStopPoints();
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }

      editAdditionalItem(item){
        this.jobInventoryItem = item;
        jQuery(this.modalJobInventorySummaryItems.nativeElement).modal('show');
      }

      async deleteAdditionalItem(id, qrCode){

        const result = await swal({
            title: 'Detele Item',
            text: "Are you sure you want to delete this inventory item?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        });

        if (!result.value) {
            return;
        }

        this.helperService.showLoadingMxpro360();
        this.jobsService
            .deleteJobInventoryItem(id, qrCode)
            .then(() => {
                this.load(this.job.id);
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
      }

      addQrAdditionalItem(item){
        this.helperService.showLoadingMxpro360();
        const dataForm = {
            qrCode: item.code,
            idItem: this.idItemSelected,
            jobId: this.jobId
        }
        this.jobsService
            .addQrJobInventoryItem(dataForm)
            .then(() => {
                this.load(this.job.id);
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                jQuery(this.modalSearchOperatingItems.nativeElement).modal('hide');
            });
      }

      deleteQrAdditionalItem(item){
        this.helperService.showLoadingMxpro360();
        const dataForm = {
            qrCode: item.job_item_qr_code,
            idItem: item.id,
            jobId: this.jobId
        }
        this.jobsService
            .deleteQrJobInventoryItem(dataForm)
            .then(() => {
                this.load(this.job.id);
            })
            .catch((error) => {
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
      }

      dataBase(){
        this.customInventoryItem = false;
        this.jobInventoryItem.name = null;
      }

      checkInventoryAdded() {
        for (let i = this.movingInventoryItems.length - 1; i >= 0; i--) {
          // recorrer el arreglo de job inventory agregado para quitar
          // del estimateInventoryItems los que ya fueron agregados
          for (let jobInventoryItem of this.jobInventorySummaryItems) {
            // comparar el dato para ver si existe
            if (
              this.movingInventoryItems[i].inventory_category_id == jobInventoryItem.inventory_category_id &&
              this.movingInventoryItems[i].name == jobInventoryItem.name
            ) {
              this.movingInventoryItems.splice(i, 1);
              break;
            }
          }
        }
      }

    openModalShowDriverLicense(urlImg) {
        this.showDriverLicense = urlImg;
        jQuery(this.modalShowDriverLicense.nativeElement).modal('show');
    }
    
    sendNewBindingEstimate() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService.sendNewBindingEstimate(this.job.id)
        .then(() => {
            this.helperService.showMessageSnackbar('Email sent', "SUCESS");
        })
        .catch((error) => {
            console.error(error);
            this.helperService.showMessageSnackbar(error.error ? error.error.message: error.message, "ERROR");
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    sendAmendedBOL() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService.sendAmendedBOL(this.job.id)
        .then(() => {
            this.helperService.showMessageSnackbar('Email sent', "SUCESS");
        })
        .catch((error) => {
            console.error(error);
            this.helperService.showMessageSnackbar(error.error ? error.error.message: error.message, "ERROR");
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }
}
