<div class="layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-4 col-lg-8 col-md-8 col-sm-12 layout-spacing align-self-center">
            <h3>
                Payroll Breakdown
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appCompanyRouterLink="/payroll-payments">Payroll Payments</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Payroll Breakdown</li>
                </ol>
            </nav>          
        </div>

        <div class="col-4">
           
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center top-actions-btns">
                <button class="btn btn-main-action" (click)="savePayrollBreakdown()">
                    Confirm Payroll
                </button>
            </div>
        </div>

    </div>
    <!-- header -->


    <!-- Payroll Info -->
    <div class="widget-content widget-content-area">

        <div class="widget-heading mb-4">
            <h3 class="title-content-area">
                Payroll Info
            </h3>
        </div>
       
        <div class="row">            

            <div class="col-8 payroll-date">
                <div class="row">
                    
                    <div class="col-6 form-group">
                        <label for="pay_roll">Payroll Start Date</label>
                        <div class="output-box">{{payroll.start_period | date}}</div>
                    </div>
                    
                    <div class="date-line">-</div>
    
                    <div class="col-6 form-group">
                        <label for="pay_roll">Payroll End Date</label>
                        <!-- <app-date-picker [placeholder]="'Not Assigned'"
                            [value]="payroll.start_period" theme="WIDGET" (onChange)="changePayrollDate($event)" [disabled]="!payrollPayment.employee_id">
                        </app-date-picker> -->
                        <div class="output-box">{{payroll.end_period | date}}</div>
                    </div>
 
                </div>                
            </div>
            
            <div class="col-4 form-group total-pay">
                <label for="pay_roll">Total Pay</label>
                <div class="output-box">{{payroll.total | currency}}</div>
            </div>  

        </div>

    </div>

    <!-- Payroll Totals -->
    <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">

        <div class="widget-heading mb-4">
            <h3 class="title-content-area">
                Payroll Totals
            </h3>
        </div>

        <div class="row">        

            <div class="col-8">

                <div class="row">
                    <div class="col-6 form-group mb-3"><label>&nbsp;</label></div>
                    <div class="col-6 form-group mb-3"><label>&nbsp;</label></div>
                    <div class="col-6 form-group mb-3"><label>&nbsp;</label></div>
                    <div class="col-6 form-group mb-3"><label>&nbsp;</label></div>

                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Commissions</label>
                        <div class="output-box">{{payroll.total_commissions | currency}}</div>
                    </div>
                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Hourly</label>
                        <div class="output-box">{{payroll.total_hourly | currency}}</div>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Bonuses</label>
                        <div class="output-box">{{payroll.total_bonuses | currency}}</div>
                    </div>

                    <div class="col-6 form-group mb-3">
                        <label for="pay_roll">Total Deductions</label>
                        <div class="output-box">- {{payroll.total_deductions | currency}}</div>
                    </div>
                </div>
            </div>
            
            <div class="col-4">
                <div class="row">

                    <div class="col-12 form-group previous-total-draw total-draw mb-3">
                        <label for="pay_roll">Total base</label>
                        <div class="output-box">{{payroll.total_base | currency}}</div>
                    </div>
                    <div class="col-12 form-group previous-total-draw total-draw mb-3">
                        <label for="pay_roll">Previous Total Draw</label>
                        <div class="output-box">{{payroll.previous_total_draw | currency}}</div>
                    </div>
        
                    <div class="col-12 form-group current-total-draw mb-3">
                        <label for="pay_roll">Current Total Draw</label>
                        <div class="output-box">{{payroll.total_draw | currency}}</div>
                    </div>   
        
                </div>
            </div>
          
        </div>

    </div>
    
    <!-- Employees -->
    <div class="row layout-top-spacing">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="widget-heading mb-4">
                    <h3 class="title-content-area">
                        Employees
                    </h3>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Total Hourly</th>
                                <th>Total Commissions</th>
                                <th>Total Deductions</th>
                                <th>Total Bonuses</th>
                                <th>Base</th>
                                <th>Total</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pp of payrollPayments; index as i">
                                <td>
                                    <ng-container *ngFor="let empl of employees">
                                        <ng-container *ngIf="empl.id == pp.employee_id">
                                            {{empl.name | titlecase}}
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>
                                    {{pp.total_hourly | number:'1.0-2'}}
                                </td> 
                                <td>
                                    {{pp.total_commissions | currency}}
                                </td> 
                                <td> 
                                    - {{pp.total_deductions | currency}}
                                </td>
                                <td> 
                                    {{pp.total_bonuses | currency}}
                                </td>
                                <td> 
                                    {{pp.total | currency}}
                                </td>
                                <td> 
                                    {{pp.base_pay | currency}}
                                </td>
                                <td>
                                    <button class="btn btn-secondary" (click)="openPayrollBreakdownModal(i)">
                                        Payment Breakdown
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>




<!-- Modal - Payroll Breakdown -->
<div class="modal fade" #payrollBreakdownModal id="payrollBreakdownModal" tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    <ng-container *ngFor="let empl of employees">
                        <ng-container *ngIf="empl.id == payrollPayment.employee_id">
                            {{empl.name | titlecase}}
                        </ng-container>
                    </ng-container>
                </h5>
            </div>

            <div class="modal-body">                

                <!-- Payroll Info -->
                <div class="widget-content widget-content-area">

                    <div class="widget-heading mb-4">
                        <h3 class="title-content-area">
                            Payroll Info
                        </h3>
                    </div>
                
                    <div class="row">

                        <div class="col-8 payroll-date">
                            <div class="row">
                                
                                <div class="col-6 form-group">
                                    <label for="pay_roll">Payroll Start Date</label>
                                    <div class="output-box">{{payroll.start_period | date}}</div>
                                </div>
                                
                                <div class="date-line">-</div>
                
                                <div class="col-6 form-group">
                                    <label for="pay_roll">Payroll End Date</label>
                                    <div class="output-box">{{payroll.end_period | date}}</div>
                                </div>
            
                            </div>                
                        </div>
                        
                        <div class="col-4 form-group total-pay">
                            <label for="pay_roll">Total Pay</label>
                            <div class="output-box">{{payrollPayment.total | currency}}</div>
                        </div>  

                    </div>

                </div>

                <!-- Payroll Totals -->
                <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">

                    <div class="widget-heading mb-4">
                        <h3 class="title-content-area">
                            Payroll Totals
                        </h3>
                    </div>

                    <div class="row">        

                        <div class="col-8">
                            <div class="row">                

                                <div class="col-6 form-group mb-3">
                                    <label for="pay_roll">Total Commissions</label>
                                    <div class="output-box">{{payrollPayment.total_commissions | currency}}</div>
                                </div>
                    
                                <div class="col-3 form-group mb-3">
                                    <label for="pay_roll">Total Hourly</label>
                                    <div class="output-box">{{payrollPayment.total_hourly | currency}}</div>
                                </div>     
                    
                                <div class="col-3 form-group mb-3">
                                    <label for="pay_roll">Total Base</label>
                                    <div class="output-box">{{payrollPayment.base_pay | currency}}</div>
                                </div>

                                <div class="col-6 form-group mb-3">
                                    <label for="pay_roll">Total Bonuses</label>
                                    <div class="output-box">{{payrollPayment.total_bonuses | currency}}</div>
                                </div>
                    
                                <div class="col-6 form-group mb-3">
                                    <label for="pay_roll">Total Deductions</label>
                                    <div class="output-box">- {{payrollPayment.total_deductions | currency}}</div>
                                </div>
                    
                            </div>
                        </div>
                        
                        <div class="col-4">
                            <div class="row">

                                <div class="col-12 form-group previous-total-draw total-draw mb-3">
                                    <label for="pay_roll">Previous Total Draw</label>
                                    <div class="output-box">{{payrollPayment.previous_total_draw | currency}}</div>
                                </div>
                    
                                <div class="col-12 form-group current-total-draw mb-3">
                                    <label for="pay_roll">Current Total Draw</label>
                                    <div class="output-box">{{payrollPayment.total_draw | currency}}</div>
                                </div>   
                    
                            </div>
                        </div>
                    
                    </div>

                </div>
                
                <!-- Commission -->
                <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">

                    <div class="widget-heading mb-3">
                        <h3 class="title-content-area">
                            Commission
                        </h3>
                    </div>

                    <div class="col-12">
                        <div class="row">

                            <div class="table-responsive" *ngFor="let commission of commissions; index as key">

                                <div class="d-flex justify-content-between align-items-center table-heading">
                                    <h3 class="title-content-area">
                                        Rule: {{commission.name}}
                                    </h3>
                                    <button  class="btn btn-info"  appCompanyRouterLink="/sales-commissions" (click)="closeModal()">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </div>

                                <table class="table table-bordered table-hover mb-4">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Job Number</th>
                                            <th>Total Job</th>
                                            <th>Payments</th>
                                            <th>Total Amount W/Filter</th>
                                            <th>Comm</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let document of commission.documents; index as key">
                                            <tr *ngIf="document.job_cancelation_date == null">
                                                <td>{{key + 1}}</td>
                                                <td>{{document.job_number}}</td>
                                                <td>{{document.total_job | currency}}</td>
                                                <td>{{document.total_payments | currency}}</td>
                                                <td>{{document.total_filter_amount | currency}}</td>
                                                <td>{{document.total_commission | currency}}</td>
                                                <td>
                                                    <span class="badge badge-success">{{document.status | filterReplace}}</span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> 
                </div>

                <!-- Hourly -->
                <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">
                    
                    <div class="d-flex justify-content-between widget-heading mb-3">
                        <h3 class="title-content-area">
                            Hourly
                        </h3>
                        <button  class="btn btn-info" appCompanyRouterLink="/time-clock"  (click)="closeModal()">
                            <i class="fa-solid fa-eye"></i>
                        </button>
                    </div>

                    <div class="table-responsive">
                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Clock In</th>
                                    <th>Clock Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let time_clock of timeClock; index as key">
                                    <td>{{key+1}}</td>
                                    <td>{{ time_clock.date | date }}</td>
                                    <td class="clock-in">
                                        <i class="fa-solid fa-clock mr-2"></i>
                                        {{ time_clock.clock_in | timeFromDate }}
                                    </td>
                                    <td class="clock-out">
                                        <i class="fa-solid fa-clock mr-2"></i>
                                        {{ time_clock.clock_out | timeFromDate }}
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>

                        <div class="col-12 mt-5">
                            <div class="row">
                                <ng-container *ngIf="!totalExtras">
                                    <div class="col-4 form-group mb-3">
                                        <label for="employee">Total Hours</label>
                                        <div class="output-box">{{payrollPayment.total_hours}}</div>
                                    </div>
                    
                                    <div class="col-4 form-group mb-3">
                                        <label for="pay_roll">Hourly Pay Rate</label>
                                        <div class="output-box">{{payrollPayment.hourly_pay_rate}}</div>
                                    </div>
                                    
                                    <div class="col-4 form-group mb-3">
                                        <label for="pay_roll">Total Hourly Pay</label>
                                        <div class="output-box">{{payrollPayment.total_hourly | currency}}</div>
                                    </div>
                                </ng-container>
                            <ng-container *ngIf="totalExtras">
                                    <div class="col-2 form-group mb-3">
                                        <label for="employee">Total Hours</label>
                                        <div class="output-box">{{payrollPayment.total_hours}}</div>
                                    </div>
                    
                                    <div class="col-2 form-group mb-3">
                                        <label for="pay_roll">Hourly Pay Rate</label>
                                        <div class="output-box">{{payrollPayment.hourly_pay_rate}}</div>
                                    </div>
                                    
                                    <div class="col-2 form-group mb-3">
                                        <label for="pay_roll">Total Hourly Pay</label>
                                        <div class="output-box">{{payrollPayment.total_hourly | currency}}</div>
                                    </div>
                                    <div class="col-2 form-group mb-3">
                                        <label for="employee">Total OT</label>
                                        <div class="output-box">{{payrollPayment.total_overtime | number:'1.0-2'}}</div>
                                    </div>
                    
                                    <div class="col-2 form-group mb-3">
                                        <label for="pay_roll">OT Rate</label>
                                        <div class="output-box">{{payrollPayment.overtime_rate | currency}}</div>
                                    </div>
                                    
                                    <div class="col-2 form-group mb-3">
                                        <label for="pay_roll">Total Hourly Pay</label>
                                        <div class="output-box">{{payrollPayment.total_overtime * payrollPayment.overtime_rate | currency}}</div>
                                    </div>
                            </ng-container>
                
                            </div>           
                        </div> 

                    </div> 
                        
                </div>

                <!-- Bonuses -->
                <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">
                    
                    <div class="widget-heading mb-3">
                        <h3 class="title-content-area">
                            Bonuses
                        </h3>
                    </div>

                    <div class="table-responsive">

                        <div class="table-heading">
                            <h3 class="title-content-area">
                                Remarks
                            </h3>
                        </div>

                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Remarks</th>
                                    <th>Bonus</th>
                                    <th>Created</th>
                                    <th class="w-200">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let payrollBalance of payrollBalances; index as key">
                                    <tr *ngIf="payrollBalance.type == 'BONUS'">
                                        <td>{{key}}</td>
                                        <td>{{payrollBalance.remarks}}</td>
                                        <td>{{payrollBalance.amount}}</td>
                                        <td>{{payrollBalance.created | date}}</td>
                                        <td>
                                            <button class="btn btn-success button-space" appCompanyRouterLink="/payroll-balances/{{payrollBalance.id}}/edit">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button  class="btn btn-secondary button-space"  (click)="removePaymentBalances(key)">
                                                <i class="fa-solid fa-clock-rotate-left"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div> 
                        
                </div>

                <!-- Deductions -->
                <div class="widget-content widget-content-area mt-4" *ngIf="payroll.end_period">
                    
                    <div class="widget-heading mb-3">
                        <h3 class="title-content-area">
                            Deductions
                        </h3>
                    </div>

                    <div class="table-responsive">

                        <div class="table-heading">
                            <h3 class="title-content-area">
                                Cancellations
                            </h3>
                        </div>

                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Job Number</th>
                                    <th class="text-center">Total Job</th>
                                    <th class="text-center">Payments</th>
                                    <th class="text-center">Total Amount W/Filter</th>
                                    <th class="text-center">Comm</th>
                                    <th class="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngFor="let cancelation of cancelations; index as key">

                                    <tr>
                                        <td>{{key+1}}</td>
                                        <td>{{cancelation.job_number}}</td>
                                        <td class="text-center">{{cancelation.total_job | currency}}</td>
                                        <td class="text-center">{{cancelation.total_payments | currency}}</td>
                                        <td class="text-center">{{cancelation.total_filter_amount | currency}}</td>
                                        <td class="text-center">
                                            <span *ngIf="cancelation.status === 'PAID'">{{cancelation.total_commission | currency}}</span>
                                            <span *ngIf="cancelation.status !== 'PAID'">N/A</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge" [ngClass]="{'badge-warning': cancelation.status === 'PENDING_PAYMENT', 'badge-success': cancelation.status === 'PAID', 'badge-danger': cancelation.status === 'CANCELLED', 'badge-info': cancelation.status === 'PENDING_DEDUCTION', 'badge-secondary': cancelation.status === 'DEDUCTED'}">
                                                {{cancelation.status | filterReplace}}
                                            </span>
                                        </td>
                                    </tr>

                                    <!-- <tr *ngIf="payrollBalance.remarks">
                                        <td class="cancellation-remark" colspan="8">
                                            <strong>Remark:</strong> {{payrollBalance.remarks}}
                                        </td>
                                    </tr> -->

                                </ng-container>
                                
                            </tbody>
                        </table>

                    </div> 

                    <div class="table-responsive">

                        <div class="table-heading">
                            <h3 class="title-content-area">
                                Deductions
                            </h3>
                        </div>

                        <table class="table table-bordered table-hover mb-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Remarks</th>
                                    <th>Deduction</th>
                                    <th>Created</th>
                                    <th class="w-200">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let payrollBalance of payrollBalances; index as key">

                                    <tr *ngIf="payrollBalance.type == 'DEDUCTION'">
                                        <td>{{key+1}}</td>
                                        <td>{{payrollBalance.remarks}}</td>
                                        <td>{{payrollBalance.amount}}</td>
                                        <td>{{payrollBalance.created | date}}</td>
                                        <td>
                                            <button class="btn btn-success button-space" appCompanyRouterLink="/payroll-balances/{{payrollBalance.id}}/edit">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </button>
                                            <button  class="btn btn-secondary button-space" (click)="removePaymentBalances(key)">
                                                <i class="fa-solid fa-clock-rotate-left"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div> 
                        
                </div>
                
            </div>

            <div class="modal-footer">
                <button class="btn btn-cancel" data-dismiss="modal">
                    Cancel
                </button>
                <button class="btn btn-save" type="button" data-dismiss="modal">
                    Save
                </button>
            </div>
        </div>
    </div>
 </div>