export class FilterData {

    pickup_state: string;
    delivery_state: string;
    filter_date_for: 'BOOKING' | 'PICKUP' | '';
    service: string;
    carrier: string;
    allocation: string;
    agent: string;
    sort: number;

    constructor() {
        this.pickup_state = '';
        this.delivery_state = '';
        this.filter_date_for = 'BOOKING';
        this.service = '';
        this.carrier = '';
        this.allocation = '';
        this.agent = '';
        this.sort = 2; // descendente
    }
}
