import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobItemQrCode } from 'src/app/entities/job-item-qr-code';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobItemQrCodesService {

    constructor(private http: HttpClient) { }

    getAll( id: string) {
      return this.http.get<Array<JobItemQrCode>>(environment.api.company + '/jobs/' + id + '/item-qr-codes').toPromise();
    }

    save(dataForm, id: string, quantity: number) {
      return this.http.post<Array<JobItemQrCode>>(environment.api.company + '/jobs/' + id + '/item-qr-codes/' + quantity, dataForm).toPromise();
    }

    remove(id: string) {
      return this.http.delete<Array<JobItemQrCode>>(environment.api.company + '/jobs/' + id + '/item-qr-codes').toPromise();
    }

    getInfoEstimateLabelQr( id: string) {      
      return this.http.get<any>(environment.api.company + '/info-estimate-label-qr/' + id).toPromise();
    }
}
