export class SaleCommission {
    public id: string;
    public estimate_id: string;
    public employee_id: string;
    public balance_id: string;
    public job_number: string;
    public commission_settings_id: string;
    public total_job: number;
    public total_payments: number;
    public total_filter_amount: number;
    public total_commission: number;
    public status: 'CANCELLED' | 'PENDING_PAYMENT' | 'PAID' | 'PENDING_DEDUCTION' | 'DEDUCTED';
    // VIEJOS VALORES DE STATUS ==> 'VOID' | 'PENDING_PAYMENT' | 'PAID' | 'PENDING_REFUND' | 'REFUNDED';
    public job_cancelation_date: number;
    public walve_deduction_date: number;
    public remarks: string;
    public company_id: string;
    public payroll_payment_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.estimate_id = null;
        this.employee_id = null;
        this.balance_id = null;
        this.job_number = null;
        this.commission_settings_id = null;
        this.total_job = null;
        this.total_payments = null;
        this.total_filter_amount = null;
        this.total_commission = null;
        this.status = null;
        this.job_cancelation_date = null;
        this.walve_deduction_date = null;
        this.remarks = null;
        this.company_id = null;
        this.payroll_payment_id = null;
        this.created = null;
        this.updated = null;
    }
}
