<div class="widget widget-table-two"  *ngFor="let jobAllRecurringForHisTeamThisWeek of jobsAllRecurringForHisTeamThisWeek">

    <div class="widget-heading">
        <h5 class="text-danger">Recurring For {{jobAllRecurringForHisTeamThisWeek.worksDepartment}}</h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="bg-danger"><div class="th-content text-light">Customer</div></th>
                        <th class="bg-danger"><div class="th-content text-light">Employee</div></th>
                        <th class="bg-danger"><div class="th-content text-light">Job</div></th>
                        <th class="bg-danger"><div class="th-content text-light">Time</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let reportData of jobAllRecurringForHisTeamThisWeek.reportData">
                        <td><div class="td-content customer-name">{{reportData.customer}}</div></td>
                        <td><div class="td-content product-brand">{{reportData.job}}</div></td>
                        <td><div class="td-content">{{reportData.date | dateTime}}</div></td>
                        <td><div class="td-content">{{reportData.employee}}</div></td>
                    </tr>
                    
                   
                </tbody>
            </table>
        </div>
    </div>
</div>