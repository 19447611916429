import { Balance } from './balance';
import { Carrier } from './carrier';
import { Customer } from 'src/app/entities/workspace/customer';
import { Employee } from './employee';
import { EstimateView } from './estimate-view';
import { Job } from 'src/app/entities/workspace/job';
import { JobOutsourcing } from './job-outsourcing';

export class JobView extends Job {

    public balanced_owed_on_job: number;
    public carrier: Carrier;
    public crew_work_type: string;
    public customer: Customer;
    public employee_outsourcing: Employee;
    public estimate: EstimateView;
    public money_owed_to_broker: number;
    public payments: Array<Balance>;
    public job_outsourcing: JobOutsourcing;

    constructor() {
        super();
        this.balanced_owed_on_job = null;
        this.carrier = new Carrier();
        this.crew_work_type = null;
        this.customer = new Customer();
        this.employee_outsourcing = null;
        this.estimate = new EstimateView();
        this.money_owed_to_broker = null;
        this.payments = [];
        this.job_outsourcing = new JobOutsourcing();
    }
}
