import { Customer } from 'src/app/entities/workspace/customer';
import { HelperService } from 'src/app/services/helper.service';
import { CustomersService } from 'src/app/services/workspaces/customers.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {
    rows: Array<Customer>;

    constructor(
        private customersService: CustomersService,
        public helperService: HelperService
    ) {
        this.rows = [];
    }

    filterSearch = '';
    actualPage = 1;

    ngOnInit() {
    }

    ngAfterViewInit(): void{
        
        this.load();

    }

    ngOnDestroy() {
        this.rows = undefined; // unset
        delete (this.rows); // this removes the variable completely
    }

    private load() {
        this.helperService.showLoadingMxpro360();
        this.customersService
            .getAll()
            .then((response) => {
                this.rows = response;
            })
            .catch((error) => {

            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    remove(id) {
        swal({
            title: 'Are you sure?',
            text: 'Do you want to delete the record?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete!'
        })
            .then((result) => {
                if (result.value) {
                    this.helperService.showLoadingMxpro360();
                    this.customersService
                        .remove(id)
                        .then((response) => {
                            this.load();
                            swal(
                                'Deleted!',
                                'The record has been deleted.',
                                'success'
                            );
                        })
                        .catch((error) => {

                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                        });
                }
            });
    }

}
