import { Company } from "../workspace/company";
import { PaymentTransaction } from "../workspace/payment-transaction";

export class ResponseTransaction {
    
    company: Company;
    message: string;
    transaction: PaymentTransaction;

    constructor() {
        this.company = new Company();
        this.message = '';
        this.transaction = new PaymentTransaction();
    }
}
