import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-closing-ratio-per-lead',
  templateUrl: './closing-ratio-per-lead.component.html',
  styleUrls: ['./closing-ratio-per-lead.component.scss']
})
export class ClosingRatioPerLeadComponent implements OnInit {

    @Input()
    companyId: boolean = false;
    closingRatioPerLead: any;

    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,
    ) { 
        this.closingRatioPerLead = {
            "total_leads": 0,
            "total_bookeds": 0,
            "closing_ratio": 0
        };
    }

    
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
           if (this.companyId){
            this.closingRatioPerLeadCompany();
        } else {
            this.closingRatioPerLeadWorkspace();
        }
    }

    
    closingRatioPerLeadWorkspace(){
        
        this.dashboardWorkspaceService
            .getClosingRatioPerLead()
            .then((response) => {                
                this.closingRatioPerLead = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }
    
    closingRatioPerLeadCompany(){
        
        this.dashboardComopanyService
            .getClosingRatioPerLead()
            .then((response) => {                
                this.closingRatioPerLead = response;
            })
            .catch((error) => {
                console.error('Error', error);
            })
            .finally(() => {
            });
    }

}
