export class ScheduleCalendar {
    public id: string;
    public title: string;
    public start: string;
    public end: string;
    public className: string;
    public description: string;
    public estimate_id: string;
    public url: string;

    constructor() {
        this.id = null;
        this.title = null;
        this.start = null;
        this.end = null;
        this.className = null;
        this.description = null;
        this.estimate_id = null;
        this.url = null;

    }
}
