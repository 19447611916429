import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalInventoryCloset } from 'src/app/entities/companies/local-inventory-closet';
import { LocalInventoryRoom } from 'src/app/entities/companies/local-inventory-room';
import { LocalRoomCategory } from 'src/app/entities/workspace/local-room-category';
import { LocalRoomClosets } from 'src/app/entities/workspace/local-room-closets';
import { QuickInventoryEstimatesService } from 'src/app/services/companies/quick-inventory-estimates.service';
import { HelperService } from 'src/app/services/helper.service';
import { LocalRoomCategoriesService } from 'src/app/services/workspaces/local-room-categories.service';
import { LocalRoomClosetsService } from 'src/app/services/workspaces/local-room-closets-service.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-quick-inventory-estimate',
  templateUrl: './quick-inventory-estimate.component.html',
  styleUrls: ['./quick-inventory-estimate.component.scss']
})
export class QuickInventoryEstimateComponent implements OnInit {

  @ViewChild('addRoomModal') addRoomModal: ElementRef;
  localInventoryRoom: LocalInventoryRoom;
  localInventoryRooms: LocalInventoryRoom[];
  roomCategories: LocalRoomCategory[];
  roomClosets: LocalRoomClosets[];
  estimateId: string;
  closetSelected: string;
  editLocalInventoryRoom: boolean;
  public fullPackingOn: boolean;

  constructor(
    private localRoomCategoriesService: LocalRoomCategoriesService,
    private localRoomClosetsService: LocalRoomClosetsService,
    private quickInventoryEstimatesService: QuickInventoryEstimatesService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    ) {
      this.roomCategories = [];
      this.roomClosets = [];
      this.localInventoryRooms = [];
      this.localInventoryRoom = new LocalInventoryRoom();
      this.estimateId = '';
      this.closetSelected = '';
      this.editLocalInventoryRoom = false;
      this.fullPackingOn = false;
    }

  ngOnInit(): void {
    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.estimateId = params.id;
        this.loadRoomCategories();
        this.loadRoomClosets();
        this.load(params.id);
      }
  });
  }


  load(id){
    this.helperService.showLoadingMxpro360();
    this.quickInventoryEstimatesService
        .get(id)
        .then((response) => {
            this.localInventoryRooms = response;
            this.masonry();
            this.setFullPackingToggle();
        })
        .catch((error) => {
            swal({
                type: 'error',
                title: 'Oops...',
                text: error.error.message
            });
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }

  setFullPackingToggle() {
    let toogleOn = true;
    this.fullPackingOn = this.localInventoryRooms.length > 0;
    for (let localInventoryRoom of this.localInventoryRooms) {
      if (!localInventoryRoom.is_packing || !toogleOn) {
        this.fullPackingOn = false;
        break;
      } else {
        for (let closet of localInventoryRoom.closets) {
          if (!closet.is_packing) {
            this.fullPackingOn = false;
            toogleOn = false;
            break;
          }
        }
      }
    }
  }

  toggleFullPacking() {
    this.fullPackingOn = !this.fullPackingOn
    for(let localInventoryRoom of this.localInventoryRooms) {
      localInventoryRoom.is_packing = this.fullPackingOn;
      for(let closet of localInventoryRoom.closets) {
        closet.is_packing = this.fullPackingOn;
      }
    }
    // this.helperService.showLoadingMxpro360();
    // this.quickInventoryEstimatesService
    // .setTooggle(!this.fullPackingOn, this.estimateId)
    // .then(() => {
    //     this.load(this.estimateId);
    // })
    // .catch((error) => {
    //     swal({
    //         type: 'error',
    //         title: 'Oops...',
    //         text: error.error.message
    //     });
    // })
    // .finally(() => {
    //     this.helperService.hideLoadingMxpro360();
    // });
  }

  loadRoomCategories(){
    this.helperService.showLoadingMxpro360();

        this.localRoomCategoriesService
            .getAll()
            .then((response) => {
                this.roomCategories = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
  }

  loadRoomClosets(){
    this.helperService.showLoadingMxpro360();

        this.localRoomClosetsService
            .getAll()
            .then((response) => {
                this.roomClosets = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
  }


  openAddRoomModal(roomCategory) {
    jQuery(this.addRoomModal.nativeElement).modal('show');
    this.localInventoryRoom =  new LocalInventoryRoom();
    this.editLocalInventoryRoom = false;
    this.localInventoryRoom.local_room_category = roomCategory;
  }

  addCloset(){
    let inventoryCloset = new LocalInventoryCloset(); 
    for (const CS of this.roomClosets){
      if(CS.id == this.closetSelected){
        inventoryCloset.local_room_closet = CS;
        this.localInventoryRoom.closets.push(inventoryCloset);
      }
    }
    // this.localInventoryRoom.closets.push(new LocalInventoryCloset())
  }

  saveRoom(){
    this.localInventoryRoom.estimate_id = this.estimateId;
    if(this.editLocalInventoryRoom){
      jQuery(this.addRoomModal.nativeElement).modal('hide');
    } else {
      this.localInventoryRooms.push(this.localInventoryRoom);
      jQuery(this.addRoomModal.nativeElement).modal('hide');
    }
    this.masonry();
    this.setFullPackingToggle();
  }

  save(){
    this.helperService.showLoadingMxpro360();
    this.quickInventoryEstimatesService
        .set(this.localInventoryRooms, this.estimateId)
        .then((response) => {
            this.load(this.estimateId);
        })
        .catch((error) => {
            swal({
                type: 'error',
                title: 'Oops...',
                text: error.error.message
            });
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
            this.helperService.goToCompanyRouterLink(`/moving/${this.estimateId}`);
        });
  }

  editRoom(inventoryRoom){
    this.localInventoryRoom = inventoryRoom;
    this.editLocalInventoryRoom = true;
    jQuery(this.addRoomModal.nativeElement).modal('show');
  }

  deleteCloset(index){
    this.localInventoryRoom.closets.splice(index, 1);
  }

  masonry(){
    setTimeout(() => {
      jQuery('.task-list-section').masonry({
        // options
        itemSelector: '.connect-sorting',
        columnWidth: 400
      });
    }, 10);
    setTimeout(() => {
      jQuery('.task-list-section').masonry('reloadItems');
      jQuery('.task-list-section').masonry('layout');
    }, 100);
  }

  deleteRoom(index){
    this.localInventoryRooms.splice(index, 1);
    this.masonry();
  }
}
