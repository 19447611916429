import { Address } from 'src/app/entities/global/address';
import { AuthService } from 'src/app/services/auth.service';
import { CompanySettings } from 'src/app/entities/workspace/company-settings';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailSetting } from 'src/app/entities/workspace/email-setting';
import { environment } from 'src/environments/environment';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { PaymentGatewaySetting } from 'src/app/entities/workspace/payment-gateway-setting';
import { PaymentGatewaySettingsService } from 'src/app/services/companies/payment-gateway-settings.service';
import { EquationsFormComponent } from 'src/app/components/equations-form/equations-form.component';
import { Equation } from 'src/app/entities/global/equation';
import { CompanyCustomTheme } from 'src/app/entities/companies/company-custom-theme';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-general-settings',
    templateUrl: './general-settings.component.html',
    styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

    @ViewChild(EquationsFormComponent) 
    equationFormModal: EquationsFormComponent;

    public cfDisplayOnEstimate: string;
    public cfDisplayOnBOL: string;
    public dataForm: GeneralSettings;
    public defaultLogo: string;
    public gateways: PaymentGatewaySetting[];
    public logoDisplayOnBOL: string;
    public numberTab: number;
    public insuranceTermsTitle: string;
    public movinEstimateSignatureTerms: string;
    public enableCoverPage: string;
    public require_cardholder_name: 'YES' | 'NO';
    public customer_birthday_for_cc: 'YES' | 'NO';
    public require_customer_id: 'YES' | 'NO';
    public visual_type: 'YES' | 'NO';

    public firstCustomColor:    string;
    public secondCustomColor:   string;
    public thirdCustomColor:    string;

    public companyThemeDocument = {
        "firstColor": "#d1d1d1",
        "secondColor": "#979797",
        "thirdColor": "#3c3c3c",
    };


    constructor(
        private authService: AuthService,
        private generalSettingsService: GeneralSettingsService,
        private helperService: HelperService,
        private paymentGatewaySettingsService: PaymentGatewaySettingsService
    ) {
        this.dataForm = new GeneralSettings();
        this.numberTab = 1;
        this.defaultLogo = "/assets/img/200x200.jpg";
        this.defaultLogo = "/assets/img/200x200.jpg";
        this.cfDisplayOnEstimate = "DISABLED";
        this.cfDisplayOnBOL = 'ACTIVED';
        this.logoDisplayOnBOL = "DISABLED";
        this.enableCoverPage = "ACTIVED";
        this.insuranceTermsTitle = '<span><strong>Insurance Terms</strong> <br/> (Display On Etimates)</span>';
        this.movinEstimateSignatureTerms = "<span><strong>Movin Estimate Signature Terms</strong> <br/> (Enter terms here that you wish to display in the signature box for moving estimates.)</span>";
        this.require_cardholder_name = 'NO';
        this.customer_birthday_for_cc = 'YES';
        this.require_customer_id = 'NO';
        this.visual_type = 'NO';

        this.firstCustomColor   = '#FA5750';
        this.secondCustomColor  = '#FFB200';
        this.thirdCustomColor   = '#FF8B00';
        
    }

    

    ngOnInit(): void {
    }

    ngAfterViewInit(): void{
        this.load();
    }
    
    private load() {
        this.helperService.showLoadingMxpro360();
        this.generalSettingsService
            .get()
            .then((response) => {
                this.dataForm = response;

                if (this.dataForm.settings.theme_document == "CUSTOM") {
                    this.firstCustomColor   = this.dataForm.settings.custom_theme.first_color;
                    this.secondCustomColor  = this.dataForm.settings.custom_theme.second_color;
                    this.thirdCustomColor   = this.dataForm.settings.custom_theme.third_color;
                }

                this.selectedThemeDocument();
                if (this.dataForm.settings == null) {
                    this.dataForm.settings = new CompanySettings();
                }

                if (this.dataForm.settings.default_payment_gateway_id == null) {
                    this.dataForm.settings.default_payment_gateway_id =  '';
                }

                if (this.dataForm.settings.email_setting.default_email_setting == null) {
                    this.dataForm.settings.email_setting.default_email_setting = new EmailSetting();
                }

                if (this.dataForm.company.address == null) {
                    this.dataForm.company.address = new Address();
                }
                
                this.cfDisplayOnEstimate = this.dataForm.settings.cf_display_on_estimate ? "ACTIVED": "DISABLED";
                this.cfDisplayOnBOL = this.dataForm.settings.cf_display_on_bol ? "ACTIVED": "DISABLED";
                this.logoDisplayOnBOL = this.dataForm.settings.logo_display_on_bol ? "ACTIVED": "DISABLED";
                this.enableCoverPage = this.dataForm.settings.enable_cover_page ? "ACTIVED": "DISABLED";
                this.require_cardholder_name = this.dataForm.settings.require_cardholder_name ? 'YES': 'NO';
                this.customer_birthday_for_cc = this.dataForm.settings.customer_birthday_for_cc ? 'YES': 'NO';
                this.require_customer_id = this.dataForm.settings.require_customer_id ? 'YES': 'NO';
                this.visual_type = this.dataForm.settings.visual_type ? 'YES': 'NO';
                
                this.loadGateways();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                if (this.dataForm.company.logo && this.numberTab == 1) {
                    this.defaultLogo = environment.az_api.cdn + '/' + this.authService.workspaceSession.id + '/media-files/' + this.dataForm.company.logo;
                }
            });
    }

    save(type) {
        this.helperService.showLoadingMxpro360();
        this.dataForm.settings.cf_display_on_estimate = this.cfDisplayOnEstimate == 'ACTIVED';
        this.dataForm.settings.logo_display_on_bol =  this.logoDisplayOnBOL == 'ACTIVED';
        this.dataForm.settings.cf_display_on_bol = this.cfDisplayOnBOL == 'ACTIVED';
        this.dataForm.settings.enable_cover_page = this.enableCoverPage == 'ACTIVED';
        this.dataForm.settings.require_cardholder_name = this.require_cardholder_name == 'YES';
        this.dataForm.settings.customer_birthday_for_cc = this.customer_birthday_for_cc == 'YES';
        this.dataForm.settings.require_customer_id = this.require_customer_id == 'YES';
        this.dataForm.settings.visual_type = this.visual_type == 'YES';
        
        if (this.dataForm.settings.default_payment_gateway_id == '') {
            this.dataForm.settings.default_payment_gateway_id =  null;
        }

        if(this.dataForm.settings.theme_document == "CUSTOM") {
            this.dataForm.settings.custom_theme = new CompanyCustomTheme();
            this.dataForm.settings.custom_theme.first_color = this.companyThemeDocument.firstColor;
            this.dataForm.settings.custom_theme.second_color = this.companyThemeDocument.secondColor;
            this.dataForm.settings.custom_theme.third_color = this.companyThemeDocument.thirdColor;
        }
        
        this.generalSettingsService
            .set(this.dataForm)
            .then((response) => {
                if(type === null){
                swal(
                    'Done!',
                    'Changes have been successfully saved',
                    'success'
                );
            }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    onChangeLogo(files: Array<string>) {
        if (files.length > 0) {
            this.dataForm.company.logo = files[0];
        }
    }

    public changeTab(numberTab): void {
        this.numberTab = numberTab;
    }


    public selectedThemeDocument() {

        if (this.dataForm.settings.theme_document == "") {      
            this.companyThemeDocument.firstColor = "#ffffff";
            this.companyThemeDocument.secondColor = "#ffffff";
            this.companyThemeDocument.thirdColor = "#ffffff";
        }
        else if (this.dataForm.settings.theme_document == "ORANGE") {      
            this.companyThemeDocument.firstColor = "#4B4B4D";
            this.companyThemeDocument.secondColor = "#FCB700";
            this.companyThemeDocument.thirdColor = "#F58634";
        }
        else if (this.dataForm.settings.theme_document == "TEAL") {
            this.companyThemeDocument.firstColor = "#003649";
            this.companyThemeDocument.secondColor = "#69C795";
            this.companyThemeDocument.thirdColor = "#01B0B5";
        } 
        else if (this.dataForm.settings.theme_document == "PINK") {
            this.companyThemeDocument.firstColor = "#12273A";
            this.companyThemeDocument.secondColor = "#EF4C70";
            this.companyThemeDocument.thirdColor = "#E13346";
        } 
        else if (this.dataForm.settings.theme_document == "RED") {
            this.companyThemeDocument.firstColor = "#123056";
            this.companyThemeDocument.secondColor = "#E61938";
            this.companyThemeDocument.thirdColor = "#94061B";
        }
        else if (this.dataForm.settings.theme_document == "GREEN") {
            this.companyThemeDocument.firstColor = "#4D4D4D";
            this.companyThemeDocument.secondColor = "#81BF40";
            this.companyThemeDocument.thirdColor = "#3F6B10";
        }
        else if (this.dataForm.settings.theme_document == "PURPLE") {
            this.companyThemeDocument.firstColor = "#200924";
            this.companyThemeDocument.secondColor = "#C198C2";
            this.companyThemeDocument.thirdColor = "#792D85";
        }
        else if (this.dataForm.settings.theme_document == "DARKSLATEGRAY") {
            this.companyThemeDocument.firstColor = "#0B4757";
            this.companyThemeDocument.secondColor = "#D6DADD";
            this.companyThemeDocument.thirdColor = "#94A7AE";
        }
        else if (this.dataForm.settings.theme_document == "BLUELLOW") {
            this.companyThemeDocument.firstColor = "#002346";
            this.companyThemeDocument.secondColor = "#dfb000";
            this.companyThemeDocument.thirdColor = "#d16a01";
        }
        else if (this.dataForm.settings.theme_document == "CANDY") {
            this.companyThemeDocument.firstColor = "#069fe9";
            this.companyThemeDocument.secondColor = "#FB3A91";
            this.companyThemeDocument.thirdColor = "#8F1F5A";
        } 
        else if (this.dataForm.settings.theme_document == "TOXICITY") {
            this.companyThemeDocument.firstColor = "#350455";
            this.companyThemeDocument.secondColor = "#87c900";
            this.companyThemeDocument.thirdColor = "#437600";
        } 
        else if (this.dataForm.settings.theme_document == "WARM") {
            this.companyThemeDocument.firstColor = "#000e20";
            this.companyThemeDocument.secondColor = "#ff7f45";
            this.companyThemeDocument.thirdColor = "#F12036";
        }
        else if (this.dataForm.settings.theme_document == "BLUE") {
            this.companyThemeDocument.firstColor = "#001b38";
            this.companyThemeDocument.secondColor = "#00b3ff";
            this.companyThemeDocument.thirdColor = "#00487a";
        }
        else if (this.dataForm.settings.theme_document == "DARKCYAN") {
            this.companyThemeDocument.firstColor = "#012020";
            this.companyThemeDocument.secondColor = "#2f9393";
            this.companyThemeDocument.thirdColor = "#007070";
        }
        else if (this.dataForm.settings.theme_document == "LOLLIPOP") {
            this.companyThemeDocument.firstColor = "#001a26";
            this.companyThemeDocument.secondColor = "#7a1759";
            this.companyThemeDocument.thirdColor = "#1884a7";
        }
        else if (this.dataForm.settings.theme_document == "NATURA") {
            this.companyThemeDocument.firstColor = "#0086b9";
            this.companyThemeDocument.secondColor = "#78B300";
            this.companyThemeDocument.thirdColor = "#588108";
        }
        else if (this.dataForm.settings.theme_document == "GOLDTEAL") {
            this.companyThemeDocument.firstColor = "#00232a";
            this.companyThemeDocument.secondColor = "#f2b354";
            this.companyThemeDocument.thirdColor = "#bf8835";
        }
        else if (this.dataForm.settings.theme_document == "CHERRYPURPLE") {
            this.companyThemeDocument.firstColor = "#453ace";
            this.companyThemeDocument.secondColor = "#e7868f";
            this.companyThemeDocument.thirdColor = "#c1272d";
        }
        else if (this.dataForm.settings.theme_document == "CUSTOM") {
            this.companyThemeDocument.firstColor = this.firstCustomColor;
            this.companyThemeDocument.secondColor = this.secondCustomColor;
            this.companyThemeDocument.thirdColor = this.thirdCustomColor;
        }
    }

    updateFirstColor() {
        this.companyThemeDocument.firstColor = this.firstCustomColor;
    }
    
    updateSecondColor() {
        this.companyThemeDocument.secondColor = this.secondCustomColor;
    }
    
    updateThirdColor() {
        this.companyThemeDocument.thirdColor = this.thirdCustomColor;
    }


    settingsLeadRotation(){
        this.save('settingsLeadRotation');
        this.helperService.goToWorkspaceRouterLink('/companyspace/' + this.dataForm.company.id + '/settings-lead-rotation/' + this.dataForm.company.id);
    }

    loadGateways() {
        this.paymentGatewaySettingsService
        .getAll()
        .then((gateways: PaymentGatewaySetting[]) => {
            this.gateways = gateways;
        })
    }

    setEquationInitPaymentLongDistance() {
        this.equationFormModal
            .open(this.dataForm.settings.price_setting.initial_payment_long_distance)
            .then((newEquation: Equation)=>{
                if(newEquation != null){
                    this.dataForm.settings.price_setting.initial_payment_long_distance = newEquation;
                }
            })
    }


    setEquationInitPaymentLocalDistance() {
        this.equationFormModal
        .open(this.dataForm.settings.price_setting.initial_payment_local_distance)
        .then((newEquation: Equation)=>{
            if(newEquation != null){
                this.dataForm.settings.price_setting.initial_payment_local_distance = newEquation;
            }
        })
    }


    setEquationInitPaymentAutoTransport() {
        this.equationFormModal
        .open(this.dataForm.settings.price_setting.initial_payment_auto_transport)
        .then((newEquation: Equation)=>{
            if(newEquation != null){
                this.dataForm.settings.price_setting.initial_payment_auto_transport = newEquation;
            }
        })
    }


    setEquationInitPaymentFreightTransport() {
        this.equationFormModal
        .open(this.dataForm.settings.price_setting.initial_payment_freight_transport)
        .then((newEquation: Equation)=>{
            if(newEquation != null){
                this.dataForm.settings.price_setting.initial_payment_freight_transport = newEquation;
            }
        })
    }



}
