<div class="layout-top-spacing">

  <div class="row">
      <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
          <h3>
              <span *ngIf="dataForm.id == null">
                  New
              </span>
              <span *ngIf="dataForm.id != null">
                  Update
              </span>
              Phone Extensions
          </h3>
          <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                      <a appWorkspaceRouterLink="/dashboard">
                          <i class="fas fa-home"></i> Dashboard
                      </a>
                  </li>
                  <li class="breadcrumb-item"><a appWorkspaceRouterLink="/phone-extensions">Phone Extensions</a></li>
                  <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                  <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
              </ol>
          </nav>
      </div>

      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
          <div class="d-flex justify-content-sm-end justify-content-center">
          </div>
      </div>
  </div>
  <!-- header -->

  <div class="row">
      <div class="col-lg-12">
          <div class="widget-content widget-content-area">

              <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                  <div class="row">
                      <div class="col-md-6 col-sm-12">


                          <div class="form-group mb-3">
                              <label class="control-label">Extension</label>
                              <div class="input-group mb-1">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hash"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                      </span>
                                  </div>

                                  <input type="number" name="extension" class="form-control" [(ngModel)]="dataForm.extension" placeholder="Enter an Extension" required>
                              </div>
                              <small id="emailHelp1" class="form-text text-muted mb-4">
                                Read Extension Number.
                              </small>
                          </div>

                          <div class="form-group mb-3">
                            <label class="control-label">Password</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-merge"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M6 21V9a9 9 0 0 0 9 9"></path></svg>
                                    </span>
                                </div>
                                <input type="password" name="password" class="form-control" [(ngModel)]="dataForm.password" placeholder="*****************" required>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                                Enter Password
                            </small>
                        </div>

                          <div class="form-group mb-3">
                            <label class="control-label">Name</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                    </span>
                                </div>

                                <input type="text" name="name" class="form-control" [(ngModel)]="dataForm.name" placeholder="Enter an Name" required>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">
                              Read Name Extension.
                            </small>
                        </div>

                          <div class="form-group mb-3">
                              <label class="control-label">CallerId</label>
                              <div class="input-group mb-1">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                      </span>
                                  </div>
                                  <input type="text" name="caller_id" class="form-control"  [(ngModel)]="dataForm.caller_id" placeholder="Enter an CallerId">
                              </div>
                              <small id="emailHelp1" class="form-text text-muted mb-4">
                                  Enter an CallerId.
                              </small>
                          </div>


                        <div class="form-group mb-3">
                          <label class="control-label">IPs Allowed</label>
                          <div class="input-group mb-1">
                              <div class="input-group-prepend">
                                  <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                                  </span>
                              </div>
                              <input type="text" name="ip_allowed" class="form-control"  [(ngModel)]="dataForm.ip_allowed" placeholder="Enter An IPs Allowed">
                          </div>
                          <small id="emailHelp1" class="form-text text-muted mb-4">
                              Enter an IPs Allowed.
                          </small>
                      </div>


                      <div class="form-group mb-3">
                        <label class="control-label">Ring Duration</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-merge"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M6 21V9a9 9 0 0 0 9 9"></path></svg>
                                </span>
                            </div>
                            <input type="number" name="ring_duration" class="form-control" [(ngModel)]="dataForm.ring_duration" placeholder="Enter An Ring Duration" required>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Enter Ring Duration.
                        </small>
                    </div>





                  <div class="form-group mb-3">
                    <label class="control-label">Outgoing Phone Line</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            </span>
                        </div>
                        <select name="outgoing_phone_line_id" class="form-control" [(ngModel)]="dataForm.outgoing_phone_line_id" required >
                            <option value="null" disabled>Select a Outgoing Phone Line</option>
                            <option *ngFor="let line of nums" [value]="line.id">
                                {{line.name | titlecase}}: {{line.phone_number | titlecase}}
                            </option>
                        </select>
                    </div>
                    <small id="emailHelp1" class="form-text text-muted mb-4">
                        Select a Outgoing Line.
                    </small>
                </div>

                        </div>

                      <div class="col-md-6 col-sm-12">


                       <div class="form-group mb-3">
                        <label class="control-label">Company</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                </span>
                            </div>
                            <select name="company_id" class="form-control" [(ngModel)]="dataForm.company_id" *ngIf="!dataForm.id" required (ngModelChange)="changeCompanie()">
                                <option value="null" disabled>Select a Company</option>
                                <option *ngFor="let company of companies" [value]="company.id">
                                    {{company.name | titlecase}}
                                </option>
                            </select>
                            <select name="company_id" class="form-control blo" [(ngModel)]="dataForm.company_id" [disabled]="dataForm.company_id" *ngIf="dataForm.id" (ngModelChange)="changeCompanie()">
                                <option *ngFor="let company of companies" [value]="company.id">
                                    {{company.name | titlecase}}
                                </option>
                            </select>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Select a Company.
                        </small>
                    </div>

                    <div class="form-group mb-3">
                        <label class="control-label">Employee</label>
                        <div class="input-group mb-1">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                </span>
                            </div>
                            <select name="employee_id" class="form-control" [(ngModel)]="dataForm.employee_id" [disabled]="!dataForm.company_id" required  >
                                <option value="null" disabled>Select a Employee</option>
                                <option *ngFor="let employee of employees" [value]="employee.id">
                                    {{employee.name | titlecase}}
                                </option>
                            </select>
                        </div>
                        <small id="emailHelp1" class="form-text text-muted mb-4">
                            Select a employeed.
                        </small>
                    </div>

                    <div class="form-group mb-3">
                      <label class="control-label">Target Destination</label>
                      <div class="input-group mb-1">
                          <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon5">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-git-pull-request"><circle cx="18" cy="18" r="3"></circle><circle cx="6" cy="6" r="3"></circle><path d="M13 6h3a2 2 0 0 1 2 2v7"></path><line x1="6" y1="9" x2="6" y2="21"></line></svg>
                              </span>
                          </div>
                          <select name="destination.target" class="form-control" [(ngModel)]="dataForm.destination.target" (ngModelChange)="changeTarget()" [disabled]="!dataForm.company_id" required>

                            <option value="null" disabled>Select an Option</option>

                            <option value="EXTENSION">
                              Extension
                            </option>
                            <option value="VOICEMAIL">
                              Voicemail
                            </option>
                            <option value="RING_GROUP">
                              Ring Group
                            </option>
                            <option value="IVR" >
                              Ivr
                            </option>
                            <option value="QUEUE" >
                              Queue
                            </option>
                            <option value="CALL_FLOW_CONTROL" disabled>
                              Call Flow Control
                            </option>
                            <option value="EXTERNAL_FORWARD" >
                                  External Forward
                            </option>

                          </select>
                      </div>
                      <small id="emailHelp1" class="form-text text-muted mb-4">
                          Select a Target.
                      </small>
                  </div>

                  <div class="form-group mb-3">
                      <label class="control-label">Target Destination ID</label>
                      <div class="input-group mb-1">
                          <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon5">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                              </span>
                          </div>

                          <input *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'" type="text" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id" placeholder="Enter An Phone External" >

                          <select *ngIf="this.dataForm.destination.target != 'EXTERNAL_FORWARD'" name="ring_target" class="form-control" [(ngModel)]="dataForm.destination.target_id" [disabled]="!dataForm.destination.target" required>
                              <option value="null" disabled>Select an Option</option>
                              <ng-container *ngFor="let target of targets">
                                  <option [value]="target.id">
                                      {{target.name | titlecase}}
                                  </option>
                              </ng-container>

                          </select>
                      </div>
                      <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'RING_GROUP'">
                          Select a Ring Group.
                      </small>
                      <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTERNAL_FORWARD'">
                          Enter a Destination Phone.
                      </small>
                      <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'VOICEMAIL'">
                          Select a Phone Line With Voicemail.
                      </small>
                      <small id="emailHelp1" class="form-text text-muted mb-4" *ngIf="this.dataForm.destination.target == 'EXTENSION'">
                          Select a Option.
                      </small>
                  </div>





                <div class="form-group mb-3">
                  <label class="control-label">Sms Phone Line</label>
                  <div class="input-group mb-1">
                      <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                          </span>
                      </div>
                      <select name="sms_phone_line_id" class="form-control" [(ngModel)]="dataForm.sms_phone_line_id">
                          <option value="null" disabled>Select a Sms Phone Line</option>
                          <option *ngFor="let line of nums" [value]="line.id">
                              {{line.name | titlecase}}: {{line.phone_number | titlecase}}
                          </option>
                      </select>
                  </div>
                  <small id="emailHelp1" class="form-text text-muted mb-4">
                      Select a Outgoing Line.
                  </small>
              </div>

              <div class="form-group mt-28">
                <label class="control-label mb-3">Main Phone</label>
                <div class="toggle-switch">
                    <label class="switch s-icons s-outline s-outline-secondary">
                        <input type="checkbox" id="main_phone" name="main_phone"
                        [(ngModel)]="dataForm.main">
                        <span class="slider round">
                            <i class="fa-solid fa-check icon-left"></i>
                            <i class="fa-solid fa-xmark icon-right"></i>
                        </span>
                    </label>
                    <label for="main_phone" class="checkbox-label">
                        <span *ngIf="dataForm.main" class="active">Active</span>
                        <span *ngIf="!dataForm.main" class="disabled">Disabled</span>
                    </label>
                </div>
            </div>


                <div class="form-group mb-100">
                  <label class="control-label">Voicemail Announcement</label>
                 <div class="upload-audio phone-line-audio">
                     <input type="file"
                         id="input-file-max-fs"
                         [value]=""
                         [appDropify]
                         (onChange)="onChangeAnnouncement($event)"
                         class="dropify"
                         data-max-file-size="3M" />
                 </div>
                  <div *ngIf="dataForm.voicemail_announcement.name "><audio src="{{environment.az_api.cdn}}/{{workspaceId}}/dialer/{{dataForm.voicemail_announcement.name}}" controls style="width: 200px;"></audio></div>
             </div>




               </div>
                  </div>

                  <div class="text-center">
                      <button class="btn btn-save ml-1 mt-3" type="submit">
                          Submit
                      </button>
                  </div>

              </form>
          </div>
      </div>
  </div>

</div>
