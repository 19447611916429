import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageCompaniesService {

  constructor(private http: HttpClient) {

  }

  getByJobId(jobId: string): Promise<Array<any>> {
    return this.http.get<Array<any>>(environment.api.company + '/storage-companies/' + jobId).toPromise();
  }
}
