import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StorageCompaniesService {

    constructor(private http: HttpClient) {

    }

    getAll(): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.workspace + '/storage-companies').toPromise();
    }

    getById(id: string) {
        return this.http.get<any>(environment.api.workspace + '/storage-companies/' + id).toPromise();
    }

    save(dataForm: any): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }

    add(dataForm) {
        return this.http.post(environment.api.workspace + '/storage-companies', dataForm).toPromise();
    }

    edit(dataForm: any) {
        return this.http.put(environment.api.workspace + '/storage-companies/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.workspace + '/storage-companies/' + id).toPromise();
    }
}
