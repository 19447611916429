<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                Salesman Performance Report
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Salesman Performance
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>                  
        <div class="row col-lg-12 col-md-12 block-search">
            <div class="col-lg-3 col-xl-4">

            </div>
            <div class="col-lg-4 col-xl-3">
                <label>Service Type</label>
                <select name="month" [(ngModel)]="typeService" class="form-control">
                    <option value="AUTO_TRANSPORT"> Auto Transport</option>
                    <option value="MOVING"> Moving </option>
                </select>
            </div>                         
            <div class="col-4">
                <button class="btn btn-warning btn-search" (click)="search()">
                    Search
                </button>
            </div>
            <div class="col-1">

            </div>  
        </div>  
    </div>

    <div class="row">
        <div class="col-lg-12">
            <div class="info-tip">
                <span class="asterisk">*</span>
                Click to show more information (Total Leads, Total Estimates, Emails Sent, Estimates Sent, Estimates Booked, Customer Payments).
             </div>
            <div class="widget-content widget-content-area">                
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Salesman</th>
                                <th class="text-center">Total Leads</th>
                                <th class="text-center">Total Estimates</th>
                                <th class="text-center">Emails Sent</th>
                                <th class="text-center">Estimates Sent</th>
                                <th class="text-center">Estimates Booked</th>
                                <th class="text-center">$ Booked</th>
                                <th class="text-center">Customer Payments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let salesmanPerform of salesmanPerforms">
                                <td>{{salesmanPerform.name | titlecase}}</td>
                                <td class="text-center cursor" (click)="getLeads(salesmanPerform.id)">
                                    {{salesmanPerform.total_leads}}</td>
                                <td class="text-center cursor" (click)="getTotalQuotes(salesmanPerform.id, false)">
                                    {{salesmanPerform.total_quotes}}</td>
                                <td class="text-center cursor" (click)="getEmailSent(salesmanPerform.id, false)">
                                    {{salesmanPerform.emails_sent}}</td>
                                <td class="text-center cursor" (click)="getQuotesSent(salesmanPerform.id, false)">
                                    {{salesmanPerform.quotes_sent}}</td>
                                <td class="text-center cursor" (click)="getQuotesBooked(salesmanPerform.id, false)">
                                    {{salesmanPerform.quotes_booked}}</td>
                                <td  class="text-right">{{salesmanPerform.amount_quotes_booked | currency}}</td>
                                <td class="text-right cursor" (click)="getCustomerPayments(salesmanPerform.id, false)">
                                    {{salesmanPerform.customer_payments | currency}}</td>
                            </tr>
                            <tr class="total" *ngIf="salesmanPerforms.length > 0">
                                <td>TOTAL</td>
                                <td class="text-center">{{totalLeads}}</td>
                                <td class="text-center cursor" (click)="getTotalQuotes(null, true)">{{totalQuotes}}</td>
                                <td class="text-center cursor" (click)="getEmailSent(null, true)">{{totalEmailsSent}}</td>
                                <td class="text-center cursor" (click)="getQuotesSent(null, true)">{{totalQuotesSent}}</td>
                                <td class="text-center cursor" (click)="getQuotesBooked(null, true)">{{totalQuotesBooked}}</td>
                                <td class="text-right">{{totalAmountQuotesBooked | currency}}</td>
                                <td class="text-right">{{totalCustomerPayments | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" #modal tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-salesman-performance-report" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    {{titleModal | titlecase}}
                </h5>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th *ngIf="titleModal == 'ESTIMATES BOOKED'">Customer Name</th>
                                <th *ngIf="titleModal == 'ESTIMATES BOOKED'">Job Total</th>
                                <th *ngIf="titleModal == 'ESTIMATES BOOKED'">Customer Payments</th>
                                <th>Pickup Date</th>
                                <th>Created</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of salesmanPerformsCustom">
                                <td>{{row.document.code}}</td>
                                <td *ngIf="titleModal == 'ESTIMATES BOOKED'">{{row.customer?.name}} {{row.customer?.last_name}}</td>
                                <td *ngIf="titleModal == 'ESTIMATES BOOKED'">{{row.job_total | currency}}</td>
                                <td *ngIf="titleModal == 'ESTIMATES BOOKED'">{{row.customer_payments | currency}}</td>
                                <td>
                                    <span *ngIf="row.pickup.range_start || row.pickup.range_end">
                                        {{row.pickup.range_start | date}} to {{row.pickup.range_end | date}}
                                    </span>
                                </td>
                                <td>{{row.created | date}}</td>
                                <td>{{row.pickup.address.state}} {{row.pickup.address.zip_code}}</td>
                                <td>{{row.delivery.address.state}} {{row.delivery.address.zip_code}}</td>
                                <td>
                                    <button class="btn btn-info" (click)="openNewTab(row.id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal -->

<!-- Modal leads -->
<div class="modal fade" #modalLeads tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-total-leads" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Total Leads
                </h5>
            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Customer</th>
                                <th>Move Date</th>
                                <th>From</th>
                                <th>Destination</th>
                                <th>Service</th>
                                <th>Advertiser</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Assigned</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let lead of leads; index as i;">
                                <td>{{i + 1}}</td>
                                <td>
                                    <div>
                                        {{lead.lead.customer.name}} {{lead.lead.customer.last_name}}
                                    </div>
                                    <div>
                                        {{lead.lead.customer.email}}
                                    </div>
                                    <div>
                                        {{lead.lead.customer.phone | phone:'US'}}
                                    </div>
                                </td>
                                <td>{{lead.lead.move_date | date}}</td>
                                <td>{{lead.lead.from_address.state}} {{lead.lead.from_address.zip_code}}</td>
                                <td>{{lead.lead.to_address.state}}  {{lead.lead.to_address.zip_code}}</td>
                                <td>{{lead.lead.service | filterReplace}}</td>
                                <td>{{lead.lead.advertiser?.name | filterReplace}}</td>
                                <td class="p-0">
                                    <div class="row-company-lead">
                                        <span class="p-2" *ngIf="lead.status == 'BOOKED'">BOOKED</span>
                                        <span class="p-2" *ngIf="lead.status == 'DEAD_LEAD'">DEAD LEAD</span>
                                        <span class="p-2" *ngIf="lead.status == 'DELIVERED'">DELIVERED</span>
                                        <span class="p-2" *ngIf="lead.status == 'IN_ESTIMATION'">IN ESTIMATION</span>
                                        <span class="p-2" *ngIf="lead.status == 'IN_STORAGE'">IN STORAGE</span>
                                        <span class="p-2" *ngIf="lead.status == 'IN_TRANSIT'">IN TRANSIT</span>
                                        <span class="p-2" *ngIf="lead.status == 'NEW'">NEW</span>
                                        <span class="p-2" *ngIf="lead.status == 'NOT_CONTACTED'">NOT CONTACTED</span>
                                        <span class="p-2" *ngIf="lead.status == 'PICKED_UP'">PICKED UP</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn btn-close" data-dismiss="modal">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal leads-->