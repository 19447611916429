import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from 'src/app/entities/workspace/customer';
import { Payment } from 'src/app/entities/workspace/payment';
import { environment } from 'src/environments/environment';
import { disposeEmitNodes } from 'typescript';
import { ReportPaymentView } from '../../entities/workspace/report-payment-view';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService {

    constructor(private http: HttpClient) {

    }

    getAllEstimates(id): Promise<Array<Payment>> {
        return this.http.get<Array<Payment>>(environment.api.company + '/estimates/' + id + '/payments').toPromise();
    }

    getAll(): Promise<Array<any>> {
        return this.http.get<Array<any>>(environment.api.company + '/payments', { params: { 'full-view': '1' } }).toPromise();
    }

    getById(id: string) {
        return this.http.get<Payment>(environment.api.company + '/payments/' + id).toPromise();
    }

    save(dataForm: Payment): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm);
        }
        return this.edit(dataForm);
    }
    savePayment(id, dataForm: any) {
        return this.http.post(environment.api.company + '/estimates/' + id + '/payments', dataForm).toPromise();
    }
    
    add(dataForm: Payment) {
        return this.http.post(environment.api.company + '/payments', dataForm).toPromise();
    }

    edit(dataForm: Payment) {
        return this.http.put(environment.api.company + '/payments/' + dataForm.id, dataForm).toPromise();
    }

    remove(id: string) {
        return this.http.delete(environment.api.company + '/payments/' + id).toPromise();
    }

    refund(id: string) {
        return this.http.post(environment.api.company + '/payments/' + id + '/refund', {}).toPromise();
    }

    confirmPayment(id: string) {
        return this.http.post(environment.api.company + '/payments/' + id + '/confirm', {}).toPromise();
    }
    /**
     * Metodo que obtiene el pago por el id del balance
     */
    getPaymentsByBalance(idBalance: string) {
        return this.http.get<Array<ReportPaymentView>>(environment.api.company + '/payments-by-balance/' + idBalance).toPromise();
    }

    /**
     * Realiza la actualizacion del pago
     *
     * @param id Id del pago
     * @param dataForm Datos a actualizar
     */
    patchEntity(id: string, dataForm: any) {
        return this.http.patch(environment.api.company + '/payments/' + id, dataForm).toPromise();
    }

    existPaymentInBalance(idBalance: string): Promise<{ payment: boolean }> {
        return this.http.get<{ payment: boolean }>(environment.api.company + '/payment-by-balance/' + idBalance).toPromise();
    }

    sendPaymentAuthorization(payment, companyId) {
        return this.http.post<any>(environment.api.company + '/payment-authorization?company_id=' + companyId, payment).toPromise();
    }

    sendEmails(customer: any){
        return this.http.post(environment.api.workspace + '/send-email-sign-balances', customer).toPromise();
    }

}
