<div class="layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span> Advertisers
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i>
                            Dashboard</a>
                    </li>

                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/advertisers">Advertisers</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>

    </div>
    <!-- header -->

    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-12">
            <div class="statbox widget box box-shadow">

                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">
                                <div class="form-group mb-1">
                                    <label class="control-label">Name</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.name" name="wd_name"
                                            class="form-control">
                                    </div>
                                    <small class="form-text text-muted mb-4">Advertiser Name.</small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Email</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-mail">
                                                    <path
                                                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                                    </path>
                                                    <polyline points="22,6 12,13 2,6">
                                                    </polyline>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.email" name="wd_email"
                                            class="form-control">
                                    </div>
                                    <small class="form-text text-muted mb-4">Advertiser Email.</small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">WebSite</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-globe">
                                                    <circle cx="12" cy="12" r="10"></circle>
                                                    <line x1="2" y1="12" x2="22" y2="12"></line>
                                                    <path
                                                        d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z">
                                                    </path>
                                                </svg></span>
                                        </div>
                                        <input type="text" [(ngModel)]="dataForm.website" name="wd_website"
                                            class="form-control">
                                    </div>
                                    <small class="form-text text-muted mb-4">Advertiser Website.</small>
                                </div>

                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">

                                <div class="form-group mb-1">
                                    <label class="control-label">Lead Price</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="feather feather-dollar-sign">
                                                    <line x1="12" y1="1" x2="12" y2="23">
                                                    </line>
                                                    <path
                                                        d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>
                                        <input type="number" step="0.01" [(ngModel)]="dataForm.lead_charge"
                                            name="wd_lead_charge" class="form-control">
                                    </div>
                                    <small class="form-text text-muted mb-4">Advertiser Lead Price.</small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Agreement Company</label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-truck">
                                                    <rect x="1" y="3" width="15" height="13"></rect>
                                                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                                                </svg>
                                            </span>
                                        </div>
                                        <select name="operator_user_id" [(ngModel)]="dataForm.agreement_company_id" required id="operator_user_id" class="form-control">
                                            <option value="">Select</option>
                                            <ng-container *ngFor="let company of companies">
                                                <option value="{{company.id}}">
                                                    {{company.name | filterReplace}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </div>
                                    <small class="form-text text-muted mb-4">Indicate by which company the agreement with the advertiser was made</small>
                                </div>

                                <div class="form-group mb-1">
                                    <label class="control-label">Lead Companies</label>
                                    <div class="input-group mb-1 select-lead-companies">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-airplay">
                                                    <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                                    <polygon points="12 15 17 21 7 21 12 15"></polygon>
                                                </svg>
                                            </span>
                                        </div>
                                        <select [(ngModel)]="dataForm.lead_companies_id" name="leadCompanies" class="form-control" multiple="multiple" #companySelector>
                                            <option *ngFor="let company of companies" [ngValue]="company.id">
                                                {{company.name | filterReplace}}
                                            </option>
                                        </select>
                                    </div>
                                    <small class="form-text text-muted mb-4">Indicate which companies the leads should be entered to when this provider sends leads</small>
                                </div>

                                
                                <div class="form-group mb-1">
                                    <label class="control-label"> Links to forward leads </label>
                                    <div class="input-group mb-1 select-lead-companies">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                            </span>
                                        </div>
                                        <select [(ngModel)]="dataForm.lead_forwarding_links_id" name="ForwardingsLinks" class="form-control position" multiple="multiple" #linkSelector>
                                            <option *ngFor="let row of leadForwardingLinks" [ngValue]="row.id">
                                                {{row.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <small class="form-text text-muted mb-4"> Select the links so that when this provider sends a lead to Moverxpro, Moverxpro can forward that lead to the links specified here </small>
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-save ml-1 mt-5" type="submit">
                                Submit
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>

</div>