import { BoardJob } from './board-job';

export class BoardJobView  extends BoardJob{
    job_code : string;

    constructor() {
        super();
        this.job_code = null;
    }
}
