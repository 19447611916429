import { Component, OnInit } from '@angular/core';
import { AdvertisersService } from 'src/app/services/workspaces/advertisers.service';
import { ActivatedRoute } from '@angular/router';
import { Advertiser } from 'src/app/entities/workspace/advertiser';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
@Component({
    selector: 'app-advertisers-view',
    templateUrl: './advertisers-view.component.html',
    styleUrls: ['./advertisers-view.component.scss']
})
export class AdvertisersViewComponent implements OnInit {

    row: Advertiser;

    constructor(
        private advertisersService: AdvertisersService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService
    ) {
        this.row = new Advertiser();
    }

    ngOnInit(): void {
     
    }

    
    ngAfterViewInit(): void {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });

    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.advertisersService
            .getById(id)
            .then((response) => {
                this.row = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


}
