import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as  DashboardWorkspaceService} from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService} from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-last5-deposits',
  templateUrl: './last5-deposits.component.html',
  styleUrls: ['./last5-deposits.component.scss']
})
export class Last5DepositsComponent implements OnInit {
    jobsDepositsLast5: any;

    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) {
    this.jobsDepositsLast5 = [];
   }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId){
        this.loadDepositsLast5Company();
    } else {
        this.loadDepositsLast5Workspace();
    }
  }

  loadDepositsLast5Workspace(){
    this.dashboardWorkspaceService
        .getDepositsLast5()
        .then((response) => {
            this.jobsDepositsLast5 = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

loadDepositsLast5Company(){
    this.dashboardComopanyService
        .getDepositsLast5()
        .then((response) => {
            this.jobsDepositsLast5 = response;
        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

}
