<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing">
            <h3>
                Pending QA
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Pending QA
                    </li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center block-filter-days">
            <button class="btn" [ngClass]="{'opt-disabled': filterDays !== 14, 'opt-active': filterDays == 14}" (click)="setFilterDays(14)">
                14 Days
            </button>
            <button class="btn" [ngClass]="{'opt-disabled': filterDays !== 7, 'opt-active': filterDays == 7}" (click)="setFilterDays(7)">
                7 Days
            </button>
            <button class="btn" [ngClass]="{'opt-disabled': filterDays !== 3, 'opt-active': filterDays == 3}" (click)="setFilterDays(3)">
                3 Days
            </button>
            <button class="filter-info tooltipmxp" title="Filter Days to Pickup"> 
                <i class="fa-solid fa-circle-info"></i>
            </button>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span #showFilterBtn>Show Filters</span> 
                </a>
            </div>
        </div>
    </div>

    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse" id="collapseFilter" #collapseFilter>
        <div class="col-lg-12 col-md-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
        </div>
        <div class="col-lg-12 col-md-12 block-search text-center">
            <button class="btn btn-warning btn-search" (click)="search()">
                Search
            </button>
        </div>
    </div>
            
    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Carrier</th>
                                <th>Customer</th>
                                <th>Service</th>
                                <th>Status</th>
                                <th>Pickup Date</th>
                                <th>Origin</th>
                                <th>Destination</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let row of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;"  
                                [ngClass]="{'glowing': row.estimate.pickup.range_start < today , 'customer-canceled': row.statuses.general == 'CUSTOMER_CANCELED'}">

                                <td>
                                    {{row.estimate.document.code}}
                                </td>
                                <td class="text-center">
                                    <strong *ngIf="row.carrier">
                                        {{row.carrier.name}}
                                    </strong>
                                    <span *ngIf="row.carrier == null">
                                        --
                                    </span>
                                </td>
                                <td>
                                    {{row.estimate.customer.name}} {{row.estimate.customer.last_name}}
                                </td>
                                <td>
                                    <span [ngSwitch]="row.estimate.service">
                                        <ng-container *ngSwitchCase="'LONG'">
                                            Long
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'LOCAL'">
                                            Local
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'AUTO_TRANSPORT'">
                                            Auto Transport
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'FREIGHT'">
                                            Freight
                                        </ng-container>
                                    </span>
                                </td>
                                <td>
                                    <span>
                                        <span [ngSwitch]="row.statuses.general">
                                            <ng-container *ngSwitchCase="'NEW'">
                                                New
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED_BOXES'">
                                                Delivered Boxes
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PACKED_MOVE'">
                                                Packed Move
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'PICKED_UP'">
                                                Picked Up
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_TRANSIT'">
                                                Loaded For Delivery
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'IN_STORAGE'">
                                                In Storage
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DELIVERED'">
                                                Delivered
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'CUSTOMER_CANCELED'">
                                                Canceled
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'WORKING_ON'">
                                                Working On
                                            </ng-container>

                                            <!--default case when there are no matches -->
                                            <ng-container *ngSwitchDefault>
                                                {{row.statuses.general}}
                                            </ng-container>
                                        </span>
                                    </span>
                                </td>
                                <td>{{row.estimate.pickup.range_start | date}} to {{row.estimate.pickup.range_end | date}}</td>
                                <td *ngIf="row.estimate.pickup.address.full_address">{{row.estimate.pickup.address.full_address}} - {{row.estimate.pickup.address.state}} {{row.estimate.delivery.address.zip_code}}</td>
                                <td *ngIf="!row.estimate.pickup.address.full_address">{{row.estimate.pickup.address.state}} {{row.estimate.delivery.address.zip_code}}</td>
                                <td *ngIf="row.estimate.delivery.address.full_address">{{row.estimate.delivery.address.full_address}} - {{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}} </td>
                                <td *ngIf="!row.estimate.delivery.address.full_address">{{row.estimate.delivery.address.state}} {{row.estimate.delivery.address.zip_code}}</td>
                                <td>
                                    <button class="btn btn-info" (click)="openNewTab(row.id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page" [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page" (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
