<div class="layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-5 col-lg-2 col-md-2 col-sm-12 filtered-list-search layout-spacing align-self-center">
            <h3>
                <span *ngIf="dataForm.id == null">
                    New
                </span>
                <span *ngIf="dataForm.id != null">
                    Update
                </span>
                <span>
                    Blackout Move Dates
                </span>               
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i> Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/blackout-move-dates">Blackout Move Jobs</a></li>
                    <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-7">

        </div>
    </div>
    <!-- header -->

    <div class="statbox widget box box-shadow">

        <div class="widget-content widget-content-area">

            <span>Indicate the date on which you will not be allowed to book pick-up work.</span>

            <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>

                <div class="row">
                    <div class="col-md-6 mt-3">
                        <span>Data:</span>
                        <app-date-picker class="app-date-picker" name="date" [value]="dataForm.date" [placeholder]="'Not Assigned'"
                            (onChange)="changeDate($event)" ngDefaultControl theme="WIDGET" [(ngModel)]="dataForm.date">
                        </app-date-picker>                          
                    </div>
                </div>

                <div class="text-center">
                    <button class="btn btn-save ml-1 mt-3" type="submit">
                        Submit
                    </button>
                </div>

            </form>


        </div>
    </div>

</div>