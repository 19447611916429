import { Address } from "../global/address";
import { CreditCard } from "../workspace/credit-card";

export class PaymentProcessRequest {
    
    is_new_payment_profile: boolean;
    credit_card: CreditCard;
    billing_address: Address;

    constructor(){
        this.is_new_payment_profile = false;
        this.credit_card = new CreditCard();
        this.billing_address = new Address();   
    }
}
