<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 align-self-center mb-3">
            <h3>
                Jobs Board
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Jobs Board</li>
                </ol>
            </nav>
        </div>

    </div>



    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

        

        <div class="skills layout-spacing  ">
            <div class="row section widget-content widget-content-area">


              

                <div class="row col-12 col-md-12">
                    <div class="col-8 col-md-12">
                        <div class="row mb-1 col-12 col-md-12">
                            <div class="name-carrier">{{boardJob.job_code}}</div>
                        </div>

                        <div class="row col-12 col-md-12">
                            <div>
                                <i class="fas fa-key"></i> &nbsp;
                                <span>
                                    <strong>Status:</strong>
                                    {{boardJob.status}}
                                   
                                </span>
                            </div>
                            <div class="ml-5 mr-5">
                                <i class="fas fa-key"></i> &nbsp;
                                <span>
                                    <strong>Offer: </strong>
                                  {{boardJob.offer}}
                                </span>
                            </div>
                            <div class=" mr-5">
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                <span>Special Conditions: </span>
                                {{boardJob.special_conditions}}
                            </div>
                            <div class=" mr-5">
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                <span>Created: </span>
                                {{boardJob.created | date}}
                            </div>
                            <div>
                                <i class="fas fa-map-marker-alt mr-2"></i>
                                <span>Vip: </span>
                                {{boardJob.is_vip}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <hr>
                </div>
            </div>
        </div>

        <div class="work-experience layout-spacing ">
            <div class="section widget-content widget-content-area">

                <div class="timeline-alter">


                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-9 info">
                                    <h6>
                                        Outsourcing Carriers
                                    </h6>
                                </div>
                                <div class="col-md-3 text-right">
                                   
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead  class="table-head">
                                        <tr>
                                            <th>Status</th>
                                            <th>Document</th>
                                            <th>Date Signed</th>
                                            <th>Employee</th>
                                            <th>Signature</th>
                                            <th>Agent</th>
                                            <th>Date Updated</th>
                                            <th>Option</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="job.job_outsourcing">
                                            <td>{{job.job_outsourcing.status | filterReplace}}</td>
                                           
                                            <td>
                                                <ng-container
                                                    *ngIf="job.job_outsourcing.job_acceptance_doc && job.job_outsourcing.job_acceptance_doc.esignatures.main">
                                                    <!-- (click)="downloadPdf('CONTRACT_INFORMATION', job.job_outsourcing.job_acceptance_doc, null, null)" -->
                                                    <button class="btn btn-success"  (click)="downloadPdf()"
                                                       >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-download">
                                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4">
                                                            </path>
                                                            <polyline points="7 10 12 15 17 10"></polyline>
                                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                                        </svg>
                                                        Download
                                                    </button>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="job.job_outsourcing.job_acceptance_doc && job.job_outsourcing.job_acceptance_doc.esignatures.main">
                                                    {{job.job_outsourcing.job_acceptance_doc.esignatures.main.created
                                                    | dateTime}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <span *ngIf="job.employee_outsourcing && job.employee_outsourcing.name">
                                                    {{job.employee_outsourcing.name}}
                                                </span>
                                                <span *ngIf="!job.employee_outsourcing || !job.employee_outsourcing.name"
                                                    class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="job.job_outsourcing.job_acceptance_doc && job.job_outsourcing.job_acceptance_doc.esignatures.main">
                                                    <img appCdnLink="/e-signatures/{{job.job_outsourcing.job_acceptance_doc.esignatures.main.esignature_file.name}}"
                                                        width="185px">
                                                </ng-container>
                                            </td>
                                            <td>
                                                <span *ngIf="carrier && carrier.name">
                                                    {{carrier.name}}
                                                </span>
                                                <span *ngIf="!carrier || !carrier.name"
                                                    class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="job.job_outsourcing">
                                                    {{job.job_outsourcing.updated | date}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container
                                                    *ngIf="job.job_outsourcing.job_acceptance_doc">
                                                    <!-- (click)="openSignatureModal(job.job_outsourcing.job_acceptance_doc.esignature)" -->
                                                    <button class="btn btn-warning" type="submit"
                                                       >
                                                        <svg _ngcontent-bbn-c5=""
                                                            class="svg-inline--fa fa-eye fa-w-18" aria-hidden="true"
                                                            focusable="false" data-prefix="far" data-icon="eye"
                                                            role="img" xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 576 512" data-fa-i2svg="">
                                                            <path fill="currentColor"
                                                                d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z">
                                                            </path>
                                                        </svg>
                                                    </button>
                                                </ng-container>

                                                <button class="btn btn-danger" type="button"
                                                    (click)="deleteJobOutsourcing(job.id)">
                                                    <svg viewBox="0 0 24 24" width="24" height="24"
                                                        stroke="currentColor" stroke-width="2" fill="none"
                                                        stroke-linecap="round" stroke-linejoin="round"
                                                        class="css-i6dzq1">
                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                        <path
                                                            d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                        </path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                </button> 
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-responsive">
                            <div class="row">   
                                
                                <ng-container *ngIf="job.carrier">
                                    <div class="col-md-6">
                                        <div class="col-12 mt-4 ml-4">        
                                            <div class="row mt-1 brockerKey-color-notes">
                                                <i class="fas fa-truck"></i>
                                                <div class="pl-20"><strong>Carrier:</strong></div>
                                                <span class="pl-86 brockerDesc-color-notes">{{job.carrier.name}}</span>
                                            </div>
                                        </div>
        
                                        <div class="col-12 ml-4">        
                                            <div class="row brockerKey-color-notes">
                                                <i class="fas fa-map-marker-alt padl-3"></i>
                                                <div class="pl-24"><strong>Address:</strong></div>
                                                <span class="pl-76 brockerDesc-color-notes">{{job.carrier.address.full_address}}</span>
                                            </div>
                                        </div>
        
                                        
        
                                    </div>
                                    
                                </ng-container>
                                
                            </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="work-experience layout-spacing " >
            <div class="section widget-content widget-content-area">

                <div class="timeline-alter">


                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-9 info">
                                    <h6>
                                        Candidates
                                    </h6>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead class="table-head">
                                        <tr>
                                            <th>Carrier</th>
                                            <th>Comments</th>
                                            <th>Bid Value</th>
                                            <th>Status</th>
                                            <th>Document</th>
                                            <th>Date Updated</th>
                                            <th>Option</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="candidates">
                                        <tr *ngFor="let candidate of candidates">
                                            <td>
                                                <span *ngIf="candidate.carrier">{{candidate.carrier.name}}</span>
                                                <span *ngIf="!candidate.carrier" class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="candidate.comments">{{candidate.comments}}</span>
                                                <span *ngIf="!candidate.comments"
                                                    class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="candidate.bid">{{candidate.bid | currency}}</span>
                                                <span *ngIf="!candidate.bid"
                                                    class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="candidate.status">{{candidate.status | filterReplace}}</span>
                                                <span *ngIf="!candidate.status" class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                <!-- <button *ngIf="candidate.job_acceptance_doc" class="btn btn-success"
                                                    (click)="downloadPdf('CARRIERS', null, candidate, null)">Download
                                                </button> -->
                                                <span *ngIf="!candidate.job_acceptance_doc"
                                                    class="badge outline-badge-danger">
                                                    None
                                                </span>
                                            </td>
                                            <td>
                                                {{candidate.updated | date}}
                                            </td>
                                            <td>
                                                <button
                                                *ngIf="!job.job_outsourcing"
                                                    class="btn btn-success" type="submit"
                                                    (click)="openOfferModal(candidate)">
                                                    Accept
                                                </button>
                                                 <!--<button
                                                    *ngIf="candidate.status == 'PENDING' && job.job_outsourcing.status == 'PENDING_JOB_ACCEPTANCE'
                                                            && !job.job_outsourcing.job_acceptance_doc"
                                                    [disabled]="estimate.status == 'WORK_CARRIED_OUT'"
                                                    class="btn btn-success" type="submit"
                                                    (click)="sendJobAcceptance()">
                                                    Send Acceptance
                                                </button> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="work-experience layout-spacing ">
            <div class="section widget-content widget-content-area">

                <div class="timeline-alter">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-9 info">
                                    <h6>
                                        History Job Outsourcing Deleted
                                    </h6>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered mb-4">
                                    <thead class="table-head">
                                        <tr>
                                            <th>Carrier</th>
                                            <th>Carrier Pay</th>
                                            <th>Document</th>
                                            <th>Special Conditions</th>
                                            <th>Employee</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let jobOutsourcing of jobOutsourcingCancelleds">
                                            <td>
                                                <div *ngIf="jobOutsourcing.carrier; else noneCarrier">
                                                    <p>{{jobOutsourcing.carrier.name | filterReplace}}</p>
                                                </div>
                                                <ng-template #noneCarrier>
                                                    <span class="badge outline-badge-danger">
                                                        None
                                                    </span>
                                                </ng-template>

                                            </td>
                                            <td>
                                                
                                                    <span class="badge outline-badge-danger">
                                                        {{jobOutsourcing.carrier_pay | currency}}
                                                    </span>
                                              
                                            </td>
                                            <td>
                                                <ng-container *ngIf="jobOutsourcing.job_acceptance_doc_id">
                                                    <!-- <button class="btn btn-success"
                                                        (click)="downloadPdf('HISTORY_ACCEPTANCE', null, null, jobOutsourcing.job_acceptance_doc_id)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            class="feather feather-download">
                                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4">
                                                            </path>
                                                            <polyline points="7 10 12 15 17 10"></polyline>
                                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                                        </svg>
                                                        Download
                                                    </button> -->
                                                </ng-container>
                                            </td>
                                           
                                            <td>
                                                {{jobOutsourcing.special_conditions}}
                                            </td>
                                            <td>
                                                {{jobOutsourcing.employee.name}}
                                            </td>
                                            <td>
                                                {{jobOutsourcing.created | dateTime}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    </div>

</div>


<div class="modal fade register-modal" #offerModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 530px;">
        <div class="modal-content modal-signature">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Carrier Payment
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">

                            <div class="form-group">
                                <div class="user-info-list">

                                    <table class="table table-bordered mb-4">
                                        <thead>
                                            <tr>
                                                <th>Offer Value Current</th>
                                                <th>End Pay Of Carrier</th>
                                                <th>Bid Value From Carrier</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               
                                                <td>
                                                    <input type="number" size="50" min="1" placeholder="Carrier Payment"
                                                        class="form-control" name="carrier_payment"
                                                        [(ngModel)]="offerCarrier" />
                                                </td>
                                                <td>
                                                    <div >
                                                        <p>
                                                            {{candidate.bid | currency}}
                                                        </p>
                                                    </div>
                                                   
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button (click)="closeOffersModal()" data-dismiss="modal" type="button"
                        class="btn btn-danger mt-2 mb-2 btn-block">
                        Close
                    </button>
                    <button (click)="updateStatusCandidate()" data-dismiss="modal" type="button"
                        class="btn btn-success mt-2 mb-2 btn-block">
                        Send
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>