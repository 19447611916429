import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { File } from 'src/app/entities/global/file';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-file-previewer',
    templateUrl: './file-previewer.component.html',
    styleUrls: ['./file-previewer.component.scss']
})
export class FilePreviewerComponent implements OnInit {

    /**
     * Indica si se toma como base el dominio de accesso publico a S3
     */
    @Input()
    useS3: boolean;

    /**
     * Contiene el nombre del archivo a mostrar
     */
    @Input()
    filename: string;

    /**
     * Contiene la ruta al archivo, en caso de que useS3 este en falso se debera aca indicar la ruta con dominio
     */
    @Input()
    path: string;

    /**
     * Si se recibe un archivo de entity File, se procesara para saber que mostrar en el previsualizador
     */
    @Input()
    file: File;

    /**
     * Contiene la ruta final incluido el nombre del archivo, esto para  establecerlos en el link
     */
    src: string;

    /**
     * Permite identificar el tipo de archivo
     */
    fileType: "IMAGE" | "VIDEO" | "AUDIO" | "OTHER";

    /**
     * se usa para asignar clases directamente al elemento que renderizara el archivo
     */
    @Input()
    innerClass: string;

    /**
     * bandera para saber si se usa el estilo preview o no 
     */
    @Input()
    disableStyles: boolean;
    


    constructor(private authService: AuthService) {
        this.useS3 = true;
        this.file = null;
        this.path = null;
        this.src = null;
        this.filename = null;
        this.fileType = "OTHER";

        this.innerClass = null;
        this.disableStyles = false;
    }

    ngOnInit(): void {

    }

    // detecta cambios en los INPUTS
    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            this.autoSetSrc();
        }, 500);
    }

    /**
     * permite establecer la imagen a previsualizar
     */
    private autoSetSrc() {

        // si el valor recibido no un objetivo file, indentificamos el tipo de archivo
        if (this.file != null) {

            // Verificamos el tipo de archivos
            this.checkFileType();
        } else {

            // si no se recibe file y tampoco se recibe filename paramos la ejecucion
            if (this.filename == null) {
                return;
            }

        }

        // construimos el link al archivo
        this.buildLinkSrc();
    }

    /**
     * permite verificar el tipo de archivo que se esta cargando
     */
    private checkFileType() {

        if (this.file.mime_type.includes("image")) {
            this.fileType = "IMAGE";
        }

        if (this.file.mime_type.includes("video")) {
            this.fileType = "VIDEO";
        }

        if (this.file.mime_type.includes("audio")) {
            this.fileType = "AUDIO";
        }

    }

    /**
     * permite construir la url
     */
    private buildLinkSrc() {

        // resetamos la url
        this.src = "";

        // si se usa s3 ponemos como base la url de acceso a s3
        if (this.useS3) {
            this.src = environment.az_api.cdn + "/" + this.authService.workspaceSession.id;
        }

        // establecemos la ruta a la imagen
        this.src += this.path + "/";

        // si existe el file establecemos el nombre que venga de alli
        if (this.file != null) {
            this.src += this.file.display_name; // mas adelante se debe cambiar este valor por otro
        } else {
            this.src += this.filename;
        }

    }


}
