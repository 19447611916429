import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EstimateView } from 'src/app/entities/workspace/estimate-view';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { HelperService } from 'src/app/services/helper.service';
import { ConstantsMessages } from '../../../constants-messages';
import { TariffVersionService } from 'src/app/services/tariff-version.service';
import { BetweenDates } from 'src/app/entities/helpers/between-dates';
import { FilterData } from 'src/app/entities/helpers/filter-data';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { AuthService } from 'src/app/services/auth.service';
import { EstimateNote } from 'src/app/entities/workspace/estimate-note';
import { EstimatesFollowUpsService } from 'src/app/services/companies/estimates-follow-ups.service';

declare var moment;
declare const jQuery;
@Component({
    selector: 'app-estimates',
    templateUrl: './estimates.component.html',
    styleUrls: ['./estimates.component.scss']
})
export class EstimatesComponent implements OnInit {

    @ViewChild('modalFollowupsremarks') modalFollowupsremarks: ElementRef;
    @ViewChild('modalQuickAssign') modalQuickAssign: ElementRef;
    allEstimates: Array<EstimateView>;
    appFade: any;
    filterPriority: string;
    desc: boolean;
    sortBy: string;
    employees: Array<Employee>;
    public btnActived: 'ALL' | 'HOT' | 'WARM' | 'COLD' | 'NEW' | 'OPEN' | 'BOOKED' | 'WITHOUTFOLLOWUPS' | 'UNSUCCESSFUL_SALES';
    public betweenDates: BetweenDates;

    public filterData: FilterData;
    public estimateId: string;

    // datos para paginador
    public paginator: Paginator;
    private generalSettings: GeneralSettings;
    public isVisibleCategory: boolean;
    public followupsremarks: EstimateNote;
    public activeQuickAssign: boolean;
    public employeeSelected: string;
    public fromEmployeeSelected: string;
    public toEmployeeSelected: string;

    constructor(
        private estimatesService: EstimatesService,
        public helperService: HelperService,
        private generalSettingsService: GeneralSettingsService,
        public tariffVersionFactory: TariffVersionService,
        private employeesService: EmployeesService,
        public authService: AuthService,
        private estimatesFollowUpsService: EstimatesFollowUpsService,
    ) {
        this.allEstimates = [];
        this.appFade = 0;
        this.filterPriority = '';
        this.desc = true;
        this.sortBy = '';
        this.paginator = new Paginator();
        this.generalSettings = new GeneralSettings();
        this.isVisibleCategory = false;
        this.btnActived = 'ALL';
        this.filterData = new FilterData();
        this.filterData.filter_date_for = '';
        this.employees = [];
        this.followupsremarks = new EstimateNote();
        this.estimateId = '';
        this.activeQuickAssign = false;
        this.employeeSelected = '';
        this.fromEmployeeSelected = '';
        this.toEmployeeSelected = '';
    }

    actualPage = 1;
    filterSearch = '';

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        
        this.load(false);
        this.loadEmployees();
    }

    ngOnDestroy(): void {
        this.followupsremarks = undefined; // unset
        delete (this.followupsremarks); // this removes the variable completely
    }

    addfollowupsremarks(estimateId) {        
        if (!this.followupsremarks.remarks) {
          return;
        }
        this.helperService.showLoadingMxpro360();
        this.estimatesFollowUpsService
          .add(estimateId, this.followupsremarks)
          .then((response) => {
            this.load(false);
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
        this.followupsremarks = new EstimateNote();
      }

    async load(makeScroll: boolean) {
        this.isVisibleCategory = false;

        this.helperService.showLoadingMxpro360();
        await this.generalSettingsService
            .get()
            .then((response) => {
                this.generalSettings = response;
                if (this.generalSettings.settings.marketing_setting.agent_lead_mode === 'POOL') {
                    this.isVisibleCategory = false;
                } else {
                    this.isVisibleCategory = true;
                }
                if (makeScroll) {
                    console.log('metodo de scroll')
                    window.scroll({ 
                        top: 0, 
                        left: 0, 
                        behavior: 'smooth' 
                    });
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

        this.getAllWithFullView();
    }

    async getAllWithFullView() {
        let currentBtnActived = this.btnActived === 'ALL' ? '' : this.btnActived;

        if (this.betweenDates.dateStart === 0 && this.betweenDates.dateEnd === 0) {
            this.betweenDates.dateStart = 1609459200;
            this.betweenDates.dateEnd = moment().second(59).minute(59).hour(23).unix() * 1000;
        }

        // Si las fechas son nulas, se toma el dia actual, de lo contrario se conserva el valor
        this.betweenDates.dateStart = this.betweenDates.dateStart ?? moment().second(0).minute(0).hour(0).unix() * 1000;
        this.betweenDates.dateEnd = this.betweenDates.dateEnd ?? moment().second(59).minute(59).hour(23).unix() * 1000;

        this.paginator.paginator.start = this.betweenDates.dateStart;
        this.paginator.paginator.end = this.betweenDates.dateEnd;
        this.paginator.paginator.order_type = this.filterData.sort == 2 ? 'DESC': 'ASC';

        this.helperService.showLoadingMxpro360();
        await this.estimatesService
            .getAllWithFullView(this.paginator, currentBtnActived, this.filterData)
            .then((response) => {
                this.paginator.data = response.data;
                this.paginator.paginator.total = response.paginator.total;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    fade() {

        if (this.appFade) {
            this.appFade = 0;
            return;
        }

        this.desc = true;
        this.appFade = 1;
    }

    getPriority(ids) {
        this.filterPriority = ids;
    }

    /**
     * @deprecated Funcion que no se usa en el componente
     */
    filters(event) {
        /*
        if (Number(event.target.value) === 1) {
            this.desc = false;
            this.sortBy = 'move_date';
            this.paginator.paginator.order_by = 'move_date';
            this.paginator.paginator.order_type = 'ASC';

        } else if (Number(event.target.value) === 2) {
            this.desc = true;
            this.sortBy = 'move_date';
            this.paginator.paginator.order_by = 'move_date';
            this.paginator.paginator.order_type = 'DESC';

        } else if (Number(event.target.value) === 3) {
            this.desc = false;
            this.sortBy = 'created';
            this.paginator.paginator.order_by = 'created';
            this.paginator.paginator.order_type = 'ASC';

        } else if (Number(event.target.value) === 4) {
            this.desc = true;
            this.sortBy = 'created';
            this.paginator.paginator.order_by = 'created';
            this.paginator.paginator.order_type = 'DESC';
        }
        */
    }

    /**
     * Metodo que abre un nuevo tab al dar click en un estimate
     *
     * @param estimateId Id del estimate a abrir
     */
    openNewTab(estimateId) {
        // Redirige a la ventana de estmate
        this.helperService.openNewTab(estimateId, 'estimate');
    }

    setCurrentPage(currentpage: number): void {
        if (this.paginator.paginator.page !== currentpage) {
            this.paginator.paginator.page = currentpage;
            this.load(true);
        }
    }

    changeCategory(estimate, category: string) {
        this.helperService.showLoadingMxpro360();
        const categoryEstimate = { category };
        this.estimatesService
            .patchEntity(estimate.id, categoryEstimate)
            .then(() => {
                this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    copyText(text: string, type: string) {

        if (type === 'phone') {
            text = text.replace(/-/g, '');
            text = text.substr(-10, 10);
        }

        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (text));
            e.preventDefault();
        };

        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
    }

    filterByCategory(type) {
        this.btnActived = type;
        this.getAllWithFullView();
    }

    /**
     * recibe y asigna fechas para filtro
     *
     * @param date Filtro de fechas a buscar
     */
    getFilterDates(date: BetweenDates): void {
        this.betweenDates = date;
    }

    private loadEmployees() {
        this.helperService.showLoadingMxpro360();

        this.employeesService
            .getAll()
            .then((response) => {
                this.employees = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    addFollowUps(id: string){
        this.estimateId = id;
        jQuery(this.modalFollowupsremarks.nativeElement).modal('show');
        
    }
    
    setQuickAssign(status: boolean) {
        this.activeQuickAssign = status;
    }

    getEmployeeName() {
        const employee = this.employees.find(x => x.id == this.employeeSelected);
        return employee.name;
    }

    toAssign(estimateId: string, indexEstimate) {
        this.helperService.showLoadingMxpro360();
        const data = {
            operator: {
                salesman_id: this.employeeSelected
            }
        }
        this.estimatesService.patchEntity(estimateId, data, false)
        .then(() => {
            // cambiar nombre en vista
            this.paginator.data[indexEstimate].employee.name = this.getEmployeeName();
            this.helperService.showMessageSnackbar('Job Assigned To ' + this.paginator.data[indexEstimate].employee.name, 'SUCESS');
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

    openQuickAssignModal() {
        this.fromEmployeeSelected = '';
        this.toEmployeeSelected = '';
        jQuery(this.modalQuickAssign.nativeElement).modal('show');
    }

    reAssignEmployee() {
        this.helperService.showLoadingMxpro360();
        this.estimatesService.reAssignEstimate(this.fromEmployeeSelected, this.toEmployeeSelected)
        .then((response: any) => {
            this.helperService.showMessageSnackbar(response.message, 'SUCESS');
            this.load(true);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }
}
