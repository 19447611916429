import { LocalTariffSetting } from "../admin/local-tariff-setting";

export class JobLocalTariffLog {
    public id: string;
    public local_tariff_setting: LocalTariffSetting;
    public hours: number;
    public cost_per_hours: number;
    public miles: number
    public cost_per_miles: number;
    public type: 'LABOR' | 'TRAVEL';
    public travel_mode: 'TIMES' | 'MILES';
    public total: number;
    public origin: 'DIGITAL_PICKUP' | 'JOB_COMPLETION';
    public job_id: string;
    public employee_id: string;
    public created: number;

    constructor() {
        this.id = null;
        this.local_tariff_setting = new LocalTariffSetting();
        this.hours = 0;
        this.cost_per_hours = 0
        this.miles = 0;
        this.cost_per_miles = 0;
        this.type = 'LABOR';
        this.travel_mode = 'TIMES';
        this.total = 0;
        this.origin = 'DIGITAL_PICKUP';
        this.job_id = null;
        this.employee_id = null;
        this.created = null;
    }
}
