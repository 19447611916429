import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { TicketType } from 'src/app/entities/workspace/ticket-type';
import { AuthService } from 'src/app/services/auth.service';
import { TicketsTypesService } from 'src/app/services/companies/tickets-types.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;

@Component({
  selector: 'app-ticket-type-form',
  templateUrl: './ticket-type-form.component.html',
  styleUrls: ['./ticket-type-form.component.scss']
})
export class TicketTypeFormComponent implements OnInit {

  /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
  constantsMessages = ConstantsMessages;
  dataForm: TicketType;
  ticketTypeId: string;
  public display_on_customer_portal: 'YES' | 'NO'

  constructor(
    private ticketsTypesService: TicketsTypesService,
    private currentRoute: ActivatedRoute,
    public helperService: HelperService
  ) {
    this.dataForm = new TicketType();
    this.display_on_customer_portal = 'YES';
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.ticketTypeId = params.id;
        this.loadTickets();
      }
    });
  }

  /**
   * Metodo que carga los tickets para mostrarlos en el select
   */
  private loadTickets() {
    this.helperService.showLoadingMxpro360();
    this.ticketsTypesService
      .getById(this.ticketTypeId )
      .then((response) => {
        this.dataForm = response;
        this.display_on_customer_portal = this.dataForm.display_on_customer_portal ? 'YES': 'NO';
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  save() {
    this.helperService.showLoadingMxpro360();
    this.dataForm.display_on_customer_portal = this.display_on_customer_portal == 'YES';

    this.ticketsTypesService
      .save(this.dataForm)
      .then(response => {
        this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
        this.helperService.goToCompanyRouterLink('/tickets-type');
      })
      .catch((error) => {
        this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
        console.error(error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
