export class LeadForwardingLink {

    public id: string;
    public name: string;
    public link: string;
    public auth_token: string;
    public list_id: number;
    public hopper: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.link = null;
        this.auth_token = null;
        this.list_id = null;
        this.hopper = null;
        this.created = null;
        this.updated = null;
    }

    
}
