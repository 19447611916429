<div class="row layout-top-spacing">
    <div class="col-lg-12">

        <div class="row">

            <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
                <h3>
                    <span *ngIf="dataForm.id == null">
                        New
                    </span>
                    <span *ngIf="dataForm.id != null">
                        Update
                    </span> Ticket Rules
                </h3>
                <nav class="breadcrumb-one" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                        </li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/settings">Settings</a></li>
                        <li class="breadcrumb-item"><a appCompanyRouterLink="/ticket-assignment-rules">Ticket Rules</a></li>
                        <li *ngIf="dataForm.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                        <li *ngIf="dataForm.id != null" class="breadcrumb-item active" aria-current="page">Update</li>
                    </ol>
                </nav>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
                <div class="d-flex justify-content-sm-end justify-content-center">

                </div>
            </div>
        </div>
        <!-- header -->


        <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area">
                <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate>
            
                    <div class="form-group mb-4 col-6">
                        <label class="control-label">Department</label>
                        <select name="operator_work_department_id" id="operator_work_department_id" class="form-control"
                        [(ngModel)]="dataForm.work_department_id">
                            <ng-container *ngFor="let workDepartment of workDepartments">
                                <option [value]="workDepartment.id">
                                    {{workDepartment.name | titlecase}}
                                </option>
                            </ng-container>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Select Department.</small>
                    </div>
                    
                    <div class="form-group mb-4 col-6">
                        <label class="control-label">Ticket Type</label>
                        <select id="inputState" [(ngModel)]="dataForm.ticket_type_id"  name="pg_type"  class="form-control" >
                            <option *ngFor="let tickesType of tickesTypes" [value]="tickesType.id">{{tickesType.name}} - {{tickesType.priority}}</option>
                        </select>
                        <small id="emailHelp1" class="form-text text-muted mb-4">Select Type Rules.</small>
                    </div>

                    <div class="text-center">
                        <button class="btn btn-save ml-1 mt-3" type="submit">
                            Submit
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
