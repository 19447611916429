import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { StorageCompany } from 'src/app/entities/workspace/storage-company';
import { HelperService } from 'src/app/services/helper.service';
import { StorageCompaniesService } from 'src/app/services/workspaces/storage-companies.service';

declare var jQuery;

@Component({
    selector: 'app-storage-companies-form',
    templateUrl: './storage-companies-form.component.html',
    styleUrls: ['./storage-companies-form.component.scss']
})
export class StorageCompaniesFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: StorageCompany;
    stageCompanies;
    address: string;

    constructor(
        private currentRoute: ActivatedRoute,
        private storageCompaniesService: StorageCompaniesService,
        private helperService: HelperService
    ) {
        this.dataForm = new StorageCompany();
    }
    
    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });
    }

    save() {
        this.storageCompaniesService.save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/storage-companies');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }
    private async load(id: string) {
        this.helperService.showLoadingMxpro360();
        await this.storageCompaniesService
            .getById(id)
            .then((response) => {
                this.address = response.address.city + ', ' + response.address.state + ', ' + response.address.country + '.';
                this.dataForm = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    setFrom(from) {
        this.dataForm.address = from;
    }

}
