import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClockEmployees } from 'src/app/entities/companies/clock-employee';
import { Payroll } from 'src/app/entities/companies/payroll';
import { TimeClock } from 'src/app/entities/companies/time-clock';
import { Employee } from 'src/app/entities/workspace/employee';
import { PayrollBalance } from 'src/app/entities/workspace/payroll-balance';
import { PayrollBreakdownView } from 'src/app/entities/workspace/payroll-breakdown-view';
import { PayrollPayment } from 'src/app/entities/workspace/payroll-payment';
import { PayrollSetting } from 'src/app/entities/workspace/payroll-setting';
import { SaleCommission } from 'src/app/entities/workspace/sale-commission';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PayrollBreakdownService {

  constructor(private http: HttpClient) {

  }


  getById(id: string) {
    return this.http.get<any>(environment.api.company + '/payroll-breakdown/' + id).toPromise();
  }

  getCommissions(id, date): Promise<Array<SaleCommission>> {
      return this.http.get<Array<SaleCommission>>(environment.api.company + '/payroll-breakdown/' + id + '/commissions', { params: {date: date}}).toPromise();
  }

  getPayrollBalances(id, date): Promise<Array<PayrollBalance>> {
      return this.http.get<Array<PayrollBalance>>(environment.api.company + '/payroll-breakdown/' + id + '/payroll-balances', { params: {date: date}}).toPromise();
  }

  getTimeClock(id, date): Promise<Array<TimeClock>> {
      return this.http.get<Array<TimeClock>>(environment.api.company + '/payroll-breakdown/' + id + '/time-clock', { params: {date: date}}).toPromise();
  }

  getClockEmployee(id): Promise<ClockEmployees> {
      return this.http.get<ClockEmployees>(environment.api.company + '/payroll-breakdown/' + id + '/clock-employee').toPromise();
  }

  getPayrollSettings(id): Promise<PayrollSetting> {
      return this.http.get<PayrollSetting>(environment.api.company + '/payroll-breakdown/' + id + '/payroll-settings').toPromise();
  }

  getPreviusPayrollPayment(id): Promise<Payroll> {
      return this.http.get<Payroll>(environment.api.company + '/payroll-breakdown/' + id + '/previus-payrol').toPromise();
  }
  
  add(payrollPayments, payroll, arrayReponseAll) {
    let data = {
      payrollPayments, 
      payroll,
      arrayReponseAll
    }
      return this.http.post(environment.api.company + '/payroll-breakdown', data).toPromise();
  }
  
  pay(id: string) {   
      return this.http.post(environment.api.company + '/payroll-breakdown/' + id + '/pay', {}).toPromise();
  }

  getPyrollPayment(data, id) {
    return this.http.get<Array<PayrollBreakdownView>>(environment.api.company + '/payroll-breakdown/' + id + '/payroll-payment', { params: data }).toPromise();
  }

  getEmployees() {
    return this.http.get<Array<Employee>>(environment.api.company + '/payroll-breakdown-employees').toPromise();
  }

  getPayrollPayment(id, date) {
    return this.http.get<Array<any>>(environment.api.company + '/payroll-breakdown/' + id + '/next', { params: {date: date} }).toPromise();
  }

  getPayrolls() {
    return this.http.get<Array<any>>(environment.api.company + '/payrolls').toPromise();
  }

}
