
export class JobStorageExternal {
    public id: string;
    public crates: number;
    public entrance_code: string;
    public key_location: string;  
    public cost_to_company: number;
    public first_payment_date: number;
    
    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.crates =  null;
        this.entrance_code = null;
        this.key_location = null;
        this.cost_to_company = null;
        this.first_payment_date = null;
    }
}
