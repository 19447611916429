import { CarriersService } from 'src/app/services/workspaces/carriers.service';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertState'
})
export class ConvertStatePipe implements PipeTransform {

    static states: any = [];
    private static loaded: boolean = false;

    constructor(private carriersService: CarriersService) {
        
        // si no se ha cargado lo cargamos
        if(ConvertStatePipe.loaded == false){

            ConvertStatePipe.loaded = true;
        
            this.carriersService
                .getPoligonsStates()
                .then(responseStates => {
                    ConvertStatePipe.states =  responseStates;
                });
        }
    }

    async transform(value: string): Promise<string> {

        let stateConverted = value;
        
        if (value.length === 2) {
            for (const state of ConvertStatePipe.states) {
                if (state['prefix'].toLowerCase() === value.toLowerCase()) {
                    stateConverted = state.prefix + ' - ' + state.name;
                    break;
                }
            }
        } 
        
        if (value.length > 2) {
            for (const state of ConvertStatePipe.states) {
                if (state['name'].toLowerCase() === value.toLowerCase()) {
                    stateConverted = state.prefix + ' - ' + state.name;
                    break;
                }
            }
        }

        return stateConverted;
    }

}
