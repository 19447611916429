export class PaymentMethod {
    public id: string;
    public available_for_storage_payment: boolean;
    public company_id: string;
    public description: string;
    public method: 'CREDIT_CARD' | 'BANK_TRANSFER_ONLINE' | 'BANK_TRANSFER_OFFLINE' | 'CHECK' | 'MONEY_ORDER' | 'CASH';
    public name: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.available_for_storage_payment = true;
        this.company_id = null;
        this.description = null;
        this.method = 'CREDIT_CARD';
        this.name = null;
        this.created = null;
        this.updated = null;
    }
}
