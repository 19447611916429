<div class="layout-spacing layout-top-spacing">
    <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 layout-spacing">
            <h3>
                Cancelled Jobs
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appCompanyRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Cancelled Jobs
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center float-right">
                <a class="btn btn-warning btn-filter-collapse" data-toggle="collapse" href="#collapseFilter" role="button" aria-expanded="false" aria-controls="collapseFilter">
                    <i class="fa-solid fa-filter"></i>
                    <span>Hide Filters</span> 
                </a>
            </div>
        </div> 

    </div>


    <!-- filter -->
    <div class="row col-lg-12 col-md-12 filter-section collapse show" id="collapseFilter">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <app-date-filter class="date-filter-top-section" (onChange)="getFilterDates($event)"></app-date-filter>
            
            <div class="row col-lg-12 col-md-12 block-filter">
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label class="control-label">Foreman</label>
                    <select name="pickup_state" id="pickup_state" [(ngModel)]="foreman"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option *ngFor="let foreman of foremen" [value]="foreman.id">{{foreman.name}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-xl-3 pl-0">
                    <label class="control-label">Salesman</label>
                    <select name="delivery_state" id="delivery_state" [(ngModel)]="salesman"
                        class="form-control">
                        <option value="" selected="selected">Select All</option>
                        <option *ngFor="let salesman of salesmen" [value]="salesman.id">{{salesman.name}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-xl-3 pl-0">
                    <button class="btn btn-warning btn-search" (click)="search()"> Search </button>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="block-total-jobs">
                    <span class="txt-total-jobs">TOTAL JOBS</span>
                    <span class="total-jobs">{{paginator.paginator.total}}</span> 
                </div>

                <div class="table-responsive">
                    <table class="table table-bordered table-hover mb-4">
                        <thead>
                            <tr>
                                <th>Cancel Date</th>
                                <th>Number</th>
                                <th>Foreman</th>
                                <th>Salesman</th>
                                <th>Job Total</th>
                                <th>Total Payments</th>
                                <th>Reason for cancellation</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | search:filterSearch | async | paginate: {itemsPerPage: 25, currentPage: actualPage}; index as i;">
                                <td>{{row.cancellation_date | date}}</td>
                                <td>
                                    {{row.job_number}}
                                </td>
                                <td class="text-center">
                                    {{row.foreman}}
                                </td>
                                <td>
                                    {{row.salesman}}
                                </td>
                                <td>
                                    {{row.job_total | currency}}
                                </td>
                                <td>
                                    {{row.total_payments | currency}}
                                </td>
                                <td>{{row.reason_for_cancelation | filterReplace }}</td>
                                <td>
                                    <button class="btn btn-info" (click)="openNewTab(row.id)">
                                        <i class="fa-solid fa-eye"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <app-custom-paginator [currentPage]="paginator.paginator.page"
                        [rowsQuantity]="paginator.paginator.total" [itemsPerPage]="paginator.paginator.per_page"
                        (pageChange)="setCurrentPage($event)"></app-custom-paginator>
                </div>
            </div>
        </div>
    </div>
</div>