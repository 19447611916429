import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Account } from 'src/app/entities/admin/account';
import { ListItemSelection } from 'src/app/entities/helpers/list-item-selection';
import { Company } from 'src/app/entities/workspace/company';
import { Employee } from 'src/app/entities/workspace/employee';
import { EmployeeCompanyPermission } from 'src/app/entities/workspace/employee-company-permission';
import { EmployeeWorkspacePermission } from 'src/app/entities/workspace/employee-workspace-permission';
import { Role } from 'src/app/entities/workspace/role';
import { HelperService } from 'src/app/services/helper.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { EmployeesService } from 'src/app/services/workspaces/employees.service';
import { RolesService } from 'src/app/services/workspaces/roles.service';
import { AuthService } from 'src/app/services/auth.service';
import { WorkDepartment } from 'src/app/entities/workspace/work-department';


declare const jQuery: any;
declare const swal: any;

class EmployeeCompanyPermissionView extends EmployeeCompanyPermission {

    employee_id: string;
    company: Company;

    public constructor() {
        super();
        this.employee_id = null;
        this.company = null;
    }

}

/**
 * Se crea clase para manejar el formulario del componente
 */
class EmployeeForm {

    employee: Employee;
    employeeWorkspacePermissions: EmployeeWorkspacePermission;
    employeeCompanyPermissions: Array<EmployeeCompanyPermissionView>;

    constructor() {
        this.employee = new Employee();
        this.employeeWorkspacePermissions = new EmployeeWorkspacePermission();
        this.employeeCompanyPermissions = [];
    }
}


@Component({
    selector: 'app-employees-view',
    templateUrl: './employees-view.component.html',
    styleUrls: ['./employees-view.component.scss']
})
export class EmployeesViewComponent implements OnInit {

    formData: EmployeeForm;
    roleFormData: Role;
    companyIndexForRole: number;
    companySelectionAvailable: Array<ListItemSelection<Company>>;
    accountFormData: Account;

    roles: Array<Role>;
    companies: Array<Company>;
    account: Account;

    // indica si la cuenta es dueño o es el titular del workspace
    isAccountsOwner: boolean;

    // indica si la cuenta fue creada en el workspace
    isWorkspacesAccount: boolean;

    // indica si la cuenta que se esta mostrando en el panel es la misma cuenta que esta en logueada o en session
    isAccountLoggedIn: boolean;

    // indica si se esta enviando algun formulario
    isSending: boolean;

    // indica la vista a mostrar en el formulario de cuentas
    viewAccountFormData: "SEARCH" | "ENTIRE_FORM";
    public workDepartments: WorkDepartment[];

    @ViewChild('roleFormModal') roleFormModal: ElementRef;
    @ViewChild('companySelectionModal') companySelectionModal: ElementRef;
    @ViewChild('accountFormModal') accountFormModal: ElementRef;

    constructor(
        private authService: AuthService,
        private companiesService: CompaniesService,
        private currentRoute: ActivatedRoute,
        private employeesService: EmployeesService,
        private helperService: HelperService,
        private rolesService: RolesService
    ) {
        this.account = null;
        this.accountFormData = new Account();
        this.companies = [];
        this.companyIndexForRole = null;
        this.companySelectionAvailable = [];
        this.formData = new EmployeeForm();
        this.isAccountLoggedIn = false;
        this.isAccountsOwner = false;
        this.isSending = false;
        this.isWorkspacesAccount = false;
        this.roleFormData = new Role();
        this.roles = [];
        this.viewAccountFormData = "SEARCH";
        this.workDepartments = [];
    }

    ngOnInit(): void {

        this.loadRoles();
        this.loadCompanies();

        this.currentRoute
            .params
            .subscribe(async params => {
                if (typeof params.id !== 'undefined') {
                    this.load(params.id);
                }
            });
    }

    ngAfterViewInit() {
        const that = this;

        const select = jQuery('.method');
        let timeOut = null;

        select.select2({
            tags: true
        })
            .on('select2:select', (e) => {
                that.setMethodsEnabled(e.params.data, 'select', e.params.data.title);
            })
            .on('select2:unselect', (e) => {
                that.setMethodsEnabled(e.params.data, 'unselect', e.params.data.title);
            });

        // si hay cambios en el dom refrescamos el plugin
        select.bind('DOMSubtreeModified', () => {

            if (timeOut !== null) {
                clearTimeout(timeOut);
            }

            timeOut = setTimeout(() => {
                select.trigger('change');
            }, 1000);
        });
    }
    /**
     * Permite cargar la informacion del empleado
     * @param id 
     */
    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.employeesService
            .getById(id)
            .then((response) => {

                // verificamos si el empleado tiene asignado cuenta
                if (response.employee.account_id !== null) {

                    // asignamos la informacion de cuenta
                    this.account = response.employee.account;

                    // verificamos si la cuenta fue creada en este workspace.. siendo asi.. indicamos que se pueda modificar si es el caso
                    this.isWorkspacesAccount = this.account.workspace_id == this.authService.workspaceSession.workspace.id;

                    // verificamos si la cuenta que se esta mostrando en el panel es la misma cuenta que esta logueada
                    this.isAccountLoggedIn = this.account.id == this.authService.session.account.id
                }

                // asignamos el valor de si la cuenta es la owner del workspaces
                this.isAccountsOwner = response.employee.is_account_owner;

                // asignamos los datos del empleado
                this.formData.employee.id = response.employee.id;
                this.formData.employee.job_position = response.employee.job_position;
                this.formData.employee.account_id = response.employee.account_id
                this.formData.employee.status = response.employee.status;
                this.formData.employee.ips_allowed = response.employee.ips_allowed;
                this.formData.employee.name = response.employee.name;
                this.formData.employee.online = response.employee.online;
                this.formData.employee.created = response.employee.created;
                this.formData.employee.updated = response.employee.updated;

                // asignamos los permisos del empleado
                this.formData.employeeCompanyPermissions = response.employeeCompanyPermissions;
                this.workDepartments = response.workDepartments;

                // si no viene permisos organizamos la entidad local para poder asignarlos sin que el sistema se rompa
                if (response.employeeWorkspacePermissions !== null) {
                    this.formData.employeeWorkspacePermissions = response.employeeWorkspacePermissions;
                }

                this.crossRolesAndCompanies();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite cargar los roles
     */
    private loadRoles() {
        this.rolesService
            .getAll()
            .then((response) => {
                this.roles = response;
            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    /**
     * Permite cargar las compañias
     */
    private loadCompanies() {
        this.companiesService
            .getAll()
            .then((response) => {

                this.companies = response;

                // cruzamos los registros
                this.crossRolesAndCompanies();
            });
    }

    /**
     * Permite cruzar los roles y las compañias 
     * para cuando se liste los permisos de las compañias, 
     * se pueda ver el nombre de la compañia a la que se refiere
     */
    private crossRolesAndCompanies() {
        for (let company of this.companies) {
            for (let index in this.formData.employeeCompanyPermissions) {
                if (this.formData.employeeCompanyPermissions[index].company_id == company.id) {
                    this.formData.employeeCompanyPermissions[index].company = company;
                    break;
                }
            }
        }

        // actualizamos la lista disponible
        this.crossAvailableCompanies();
    }

    /**
     * usado en los selectores de roles, para poder definir cual es el rol seleccionado cuando se carga los formularios
     * esto por que se esta usando directamente el object para asignarlo en el select con [ngValue]="role"
     * 
     * @param a 
     * @param b 
     * @returns 
     */
    compareRoles(a: Role, b: Role) {

        if (a == null && b == null) {
            return true;
        }

        if (a == null || b == null) {
            return false;
        }

        return a.id === b.id;
    }

    /**
     * Permite actualizar la lista de compañia disponibles para que el usuario agregue a lo spermisos, 
     * cuando se agrega una compañia, esta sale de la lista disponible
     */
    crossAvailableCompanies() {

        // limpiamos
        this.companySelectionAvailable = [];

        for (let company of this.companies) {

            // si la compañia esta desactivada, la ignoramos
            if (company.status == "INACTIVE") {
                continue;
            }

            let isFound = false;

            for (let index in this.formData.employeeCompanyPermissions) {
                // si es la compañia, quiere decir que ya esta agregada por ende fue encontrada
                if (this.formData.employeeCompanyPermissions[index].company_id == company.id) {
                    isFound = true;
                    break;
                }
            }

            // si la compañia no fue encontrada, quiere decir, que esta disponible para ser agregada
            if (!isFound) {
                const selectItem = new ListItemSelection<Company>();
                selectItem.data = company;
                this.companySelectionAvailable.push(selectItem);
            }
        }
        const t = this;
        setTimeout(() => {
            t.ngAfterViewInit();
        });

    }

    /**
     * Permite abrir la modal para seleccionar compañias y agregarselas al empleado
     */
    openModalCompanySelection() {

        // iteramos y decimos que ninguno se ha seleccionado
        for (let index in this.companySelectionAvailable) {
            this.companySelectionAvailable[index].selected = false;
        }

        // abrimos la moodal
        jQuery(this.companySelectionModal.nativeElement).modal('show');
    }

    /**
     * Permite agregar el accesso del empleado a las compañias seleccionadas de la lista
     */
    saveModalCompanySelection() {

        // iteramos y miramos los seleccionados para agregarlos
        for (let index in this.companySelectionAvailable) {
            if (this.companySelectionAvailable[index].selected) {

                // instanciamos el company permissions a partir de la compañia seleccionada
                const tmpComp = new EmployeeCompanyPermissionView();
                tmpComp.company = this.companySelectionAvailable[index].data;
                tmpComp.company_id = this.companySelectionAvailable[index].data.id;
                tmpComp.employee_id = this.formData.employee.id;
                tmpComp.role = null;

                // agegarmos a la lista el company permissions
                this.formData.employeeCompanyPermissions.push(tmpComp);
            }
        }

        // actualizamos la lista de compañias disponibles para actualizar
        this.crossAvailableCompanies();

        // cerramos la modal
        jQuery(this.companySelectionModal.nativeElement).modal('hide');
    }

    /**
     * Permite eliminar el acceso a una compañia
     * 
     * @param index 
     */
    removeCompany(index: number) {

        swal({
            title: this.formData.employeeCompanyPermissions[index].company.name,
            text: "Do you want to remove access to this company?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        })
            .then((reopen) => {

                if (reopen.value) {

                    // eliminamos la compañia recibida
                    this.formData.employeeCompanyPermissions.splice(index, 1);

                    // actualizamos la lista de disponibles
                    this.crossAvailableCompanies();
                }
            });

    }

    /**
     * Permite abrir la modal para asignar permisos manualmente
     */
    openModalRoleFormData(tmpRole: Role, companyIndex: number = null): void {

        // Asignamos una copia
        this.roleFormData = Object.assign({}, tmpRole);

        // indicamos el index de la compañia a la que pertenece el role
        this.companyIndexForRole = companyIndex;

        // abrimos la moodal
        jQuery(this.roleFormModal.nativeElement).modal('show');
    }

    /**
     * permite guardar los permisos asignados dependiendo del role que se este editando sea para workspace o company
     */
    saveModalRoleFormData() {

        // si es null es por que es un role de workspace
        if (this.companyIndexForRole === null) {

            // asignamos los cambios generados al workspace
            this.formData.employeeWorkspacePermissions.role = this.roleFormData;

        } else {

            // asignamos los cambios generados al company
            this.formData.employeeCompanyPermissions[this.companyIndexForRole].role = this.roleFormData;
        }

        // cerramos la modal
        jQuery(this.roleFormModal.nativeElement).modal('hide');
    }

    /**
     * permite guardar la informacion del empleado
     * 
     * @param form 
     * @returns 
     */
    save(form: NgForm) {

        // verificamos que el formulario este validado
        if (form.form.status !== 'VALID') {
            return;
        }

        // indicamos que el formulario se esta enviando
        this.isSending = true;

        // mostramos el cargando
        this.helperService.showLoadingMxpro360();

        // guardamos la informacion del empleado
        this.employeesService
            .edit(this.formData)
            .then((response:any ) => {

                // mostramos el aviso que devuelve el backend
                this.helperService.showMessageSnackbar(response.message, "SUCESS");

                // redireccionamos a lista de empleados
                this.helperService.goToWorkspaceRouterLink('/employees');
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.isSending = false;
            });
    }



    /********************************************
     * CODIGO PARA CUENTAS DE ACCESO (accounts) *
     ********************************************/


    /**
     * Permite abrir la modal para editar la inforamcion de la cuenta
     */
    openAccountFormModal() {

        // reseteamos el formulario antes de abrir la modal
        this.accountFormData = new Account();

        // verificamos si vamos a editar o asignar, esto por que la variable posee la informacion de la cuenta actualmente asignada, si esta en null no tiene
        if (this.account == null) {

            // indicamos la vista a mostrar
            this.viewAccountFormData = 'SEARCH';
        } else {

            // indicamos la vista a mostrar
            this.viewAccountFormData = 'ENTIRE_FORM';

            // Asignamos una copia
            this.accountFormData = Object.assign({}, this.account);
        }

        // abrimos la moodal
        jQuery(this.accountFormModal.nativeElement).modal('show');
    }

    /**
     * Permite procesar la informacion del formulario de cuenta
     * @param form 
     * @returns 
     */
    saveAccountFormModal(form: NgForm) {

        // verificamos que el formulario este validado
        if (form.form.status !== 'VALID') {
            return;
        }

        // indicamos que el formulario se esta enviando
        this.isSending = true;

        // mostramos el cargando
        this.helperService.showLoadingMxpro360();

        // verificamos si estamos busacndo o si debemos guardar la informacion completa del formulario
        if (this.viewAccountFormData == 'SEARCH') {

            this.employeesService
                .searchAccouts(this.accountFormData.email)
                .then((responseAccount: Account) => {

                    this.employeesService
                        .assignAccessAccount(this.formData.employee.id, this.accountFormData)
                        .then((response: any) => {

                            // mostramos el aviso que devuelve el backend
                            this.helperService.showMessageSnackbar(response.message, "SUCESS");

                            // asignamos los datos de la cuenta
                            this.account = responseAccount;

                            // asignamos el identificador de la cuenta en el empleado
                            this.formData.employee.account_id = this.account.id;

                            // verificamos si la cuenta fue creada en este workspace.. siendo asi.. indicamos que se pueda modificar si es el caso
                            this.isWorkspacesAccount = this.account.workspace_id == this.authService.workspaceSession.workspace.id;

                            // la respuesta de asignacion devuelve si la cuena que se esta asignadno es owner o no
                            this.isAccountsOwner = response.is_owner;

                            // verificamos si la cuenta que se esta mostrando en el panel es la misma cuenta que esta logueada
                            this.isAccountLoggedIn = this.account.id == this.authService.session.account.id

                            // cerramos la modal
                            jQuery(this.accountFormModal.nativeElement).modal('hide');
                        })
                        .catch((httpError) => {
                            try {

                                if (httpError.error.message) {
                                    swal({
                                        type: 'error',
                                        title: 'Error',
                                        text: httpError.error.message
                                    });
                                }
                            } catch (error) {
                                console.error(error);
                            }
                        })
                        .finally(() => {
                            this.helperService.hideLoadingMxpro360();
                            this.isSending = false;
                        });

                })
                .catch((httpError) => {

                    try {
                        // verificamos que la respuesta la haya enviado el servidor y no por que se haya caido el internet en el cliente
                        // yo supongo que si existe error.message es por que si respondio el backend o API
                        if (httpError.error.message && httpError.status === 404) {

                            // indicamos a la vista que muestre el formulario completo por que el usuario no existe
                            this.viewAccountFormData = 'ENTIRE_FORM';

                        } else {
                            swal({
                                type: 'error',
                                title: 'Error',
                                text: httpError.error.message
                            });
                        }
                    } catch (error) {
                        console.error(error);
                    }

                    this.helperService.hideLoadingMxpro360();
                    this.isSending = false;
                });

        } else {
            this.employeesService
                .saveAccessAccount(this.formData.employee.id, this.accountFormData)
                .then((response: any) => {

                    // verificamos si se esta creando una cuenta
                    if (this.accountFormData.id == null) {

                        // asignamos identificador
                        this.accountFormData.id = response.account_id;

                        /************************
                        * ESTABLECEMOS VALORES *
                        ************************/

                        // indica si la cuenta es dueño o es el titular del workspace
                        this.isAccountsOwner = false;

                        // indica si la cuenta fue creada en el workspace
                        this.isWorkspacesAccount = true;

                        // indica si la cuenta que se esta mostrando en el panel es la misma cuenta que esta en logueada o en session
                        this.isAccountLoggedIn = false;
                    }

                    // actualizamos los datos
                    this.account = this.accountFormData;

                    // mostramos el aviso que devuelve el backend
                    this.helperService.showMessageSnackbar(response.message, "SUCESS");

                    // cerramos la modal
                    jQuery(this.accountFormModal.nativeElement).modal('hide');

                })
                .catch((error) => {
                    try {
                        swal({
                            type: 'error',
                            title: 'Oops...',
                            text: error.error.message
                        });
                    } catch (error) {
                        console.error(error);
                    }
                })
                .finally(() => {
                    this.helperService.hideLoadingMxpro360();
                    this.isSending = false;
                });
        }
    }

    /**
     * Permite eliminar la cuenta de acceso asignada
     */
    async deleteAccessAccount() {

        // preguntamos si esta seguro
        const response = await swal({
            title: this.account.name,
            text: "Do you want to remove access account to this employee?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'yes'
        });

        // validamos si no dio click en cancelar
        if (!response.value) {
            return;
        }

        // indicamos que el formulario se esta enviando
        this.isSending = true;

        // mostramos el cargando
        this.helperService.showLoadingMxpro360();

        // guardamos la informacion del empleado
        this.employeesService
            .deleteAccessAccount(this.formData.employee.id)
            .then((response: any) => {

                // mostramos el aviso que devuelve el backend
                this.helperService.showMessageSnackbar(response.message, "SUCESS");

                this.account = null;
                this.formData.employee.account_id = null;
            })
            .catch((error) => {
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: error.error.message
                });
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
                this.isSending = false;
            });
    }

    showPassword(i: number) {
        jQuery('.btn_show_' + i + '_password svg').css('display', 'none');
        if (jQuery('.input_email_' + i +'_password').attr('type') == "password") {
            jQuery('.input_email_' + i +'_password').prop('type', 'text');
            jQuery('.btn_show_' + i + '_password .icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
        } else {
            jQuery('.input_email_' + i +'_password').prop('type', 'password');
            jQuery('.btn_show_' + i + '_password .icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
        }
    }

    setMethodsEnabled(position, type: string, index: number) {
        const nameJobPosition = position.id.split(':')[1].trim().replace(/'/g, '');
        if (this.formData.employeeCompanyPermissions[index].email_work_departments_id.length > 0) {
            const indexJobPosition = this.formData.employeeCompanyPermissions[index].email_work_departments_id.indexOf(nameJobPosition);
            if (type === 'select') {
                if (indexJobPosition === -1) {
                    this.formData.employeeCompanyPermissions[index].email_work_departments_id.push(nameJobPosition);
                }
            }

            if (type === 'unselect') {
                if (indexJobPosition >= 0) {
                    this.formData.employeeCompanyPermissions[index].email_work_departments_id.splice(nameJobPosition, 1);
                }
            }
        } else {
            if (type === 'select') {
                this.formData.employeeCompanyPermissions[index].email_work_departments_id.push(nameJobPosition);
            }
        }
    }

}
