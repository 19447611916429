<div class="widget widget-table-two widget-container">

    <div class="widget-heading">
        <h5 class="text-right">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
            All Recurring Customers (Email)
        </h5>
    </div>

    <div class="widget-content">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr class="bar-thead">
                        <th class=""><div class="th-content">Name</div></th>
                        <th class=""><div class="th-content">Job Code</div></th>
                        <th class=""><div class="th-content th-heading2">Date</div></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="email-list" *ngFor="let jobsRecurringCustomerTheySendEmailToo of jobsRecurringCustomerTheySendEmailsToo">
                        <td>
                            <div class="td-content customer-name d-flex-center">
                                {{jobsRecurringCustomerTheySendEmailToo.customer}}
                            </div>
                        </td>
                        <td><div class="td-content text-center">{{jobsRecurringCustomerTheySendEmailToo.job}}</div></td>
                        <td><div class="td-content pricing text-center"><span class="">{{jobsRecurringCustomerTheySendEmailToo.created | date}}</span></div></td>
                    </tr>            
                </tbody>
            </table>
        </div>
    </div>
</div>