<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 align-self-center mb-3">
            <h3>
                Storage Company
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/storage-companies">Storage Companies</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Storage Company</li>
                </ol>
            </nav>
        </div>

    </div>



    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">

        <div class="skills layout-spacing  ">
            <div class="row section widget-content widget-content-area">

                <div class="row col-10 col-md-10">
                    <div class="col-8 col-md-8">
                        <div class="row mb-1 col-10 col-md-10">
                            <div class="name-carrier">{{storageCompany.name}}</div>
                        </div>

                        <br>

                        <div class="row col-12 col-md-12">
                            {{storageCompany.phone}}
                        </div>

                    </div>


                    <br>

                        <div class="col-4 col-md-4 pl-0">
                            <div class="title-start-date">Start Date</div>
                            <div class="value-start-date">{{storageCompany.created | date}}</div>
                        </div>


                </div>




                <div class="col-12 col-md-12">
                    <hr>
                </div>


                <div class="row col-12 col-md-12 menu-top">


                    <div class="row col-2 col-md-2 cursor"
                        (click)="selectTap = 'contacts'; loadContacts()"
                        [ngClass]="{'menu-top-selected': selectTap == 'contacts'}">
                        <div class="col-4 col-md-4 icon-content">
                            <i class="fas fa-user-alt icon-menu-top"></i>
                        </div>
                        <div class="col-8 col-md-8 px-0">
                            <div class="bar-title">Contacts</div>
                            <div class="link-view">View</div>
                        </div>
                    </div>

                    <!-- <div class="row col-2 col-md-2 cursor" (click)="selectTap = 'jobs'; loadJobs()"
                        [ngClass]="{'menu-top-selected': selectTap == 'jobs'}">
                        <div class="col-4 col-md-4 icon-content">
                            <i class="fas fa-truck-loading icon-menu-top"></i>
                        </div>
                        <div class="col-8 col-md-8 px-0">
                            <div class="bar-title">Jobs</div>
                            <div class="link-view">View</div>
                        </div>
                    </div> -->

                </div>



            </div>
        </div>


        <!-- ---- Tabla de Jobs ---- start -->

        <div class="skills layout-spacing" *ngIf="selectTap == 'jobs'">
            <div class="row section widget-content widget-content-area">

                <!-- <div class="row col-12 col-md-12 mb-3">
                    <div class="col-9 col-md-9">
                        <div class="title-table"> Jobs </div>
                        <div> Jobs took by the carrier </div>
                    </div>
                    <div class="col-3 col-md-3">
                        <label>Company</label>
                        <select [(ngModel)]="company" name="cities" class="form-control" [appSelect2]
                            [theme]="'mxp360-form'" (ngModelChange)="filterByCompany()">
                            <option value="{{company.id}}" *ngFor="let company of companies">
                                {{company.name | titlecase}} </option>
                        </select>
                    </div>
                </div> -->

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Customer</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Volume</th>
                            <th scope="col">Balance owed on job</th>
                            <th scope="col">Money owed to broker</th>
                            <th scope="col">Carrier Pay</th>
                            <th scope="col">Status</th>
                            <th scope="col">Signature</th>
                        </tr>
                    </thead>
                    <!-- <tbody>
                        <tr *ngFor="let Job of rowsStorageCompanyContacts">
                            <td>
                                <div><strong>Job: </strong>{{Job.estimate.document.code}}</div>
                                <div><strong>Name: </strong>{{Job.customer.name}}
                                    {{Job.customer.last_name}}</div>
                                <div><strong>Service: </strong>{{Job.estimate.service}}</div>
                            </td>
                            <td>
                                <div>{{Job.estimate.pickup.address.state}}
                                    {{Job.estimate.pickup.address.zip_code}}</div>
                                <div><strong>Start: </strong>{{Job.estimate.pickup.range_start | date}} </div>
                                <div><strong>End: </strong>{{Job.estimate.pickup.range_end | date}}</div>
                            </td>
                            <td>
                                <div>{{Job.estimate.delivery.address.state}}
                                    {{Job.estimate.delivery.address.zip_code}}</div>
                                <div><strong>Start: </strong>{{Job.estimate.delivery.range_start | date}} </div>
                                <div><strong>End: </strong>{{Job.estimate.delivery.range_end | date}}</div>
                            </td>
                            <td>
                                <div><strong>Lbs: </strong>{{Job.estimate.volume.libs}}</div>
                                <div><strong>Cf: </strong>{{Job.estimate.volume.cubic_feets}}</div>
                                <div><strong>Miles: </strong>{{Job.estimate.volume.miles}}</div>
                            </td>
                            <td>
                                <div>{{Job.balanced_owed_on_job | currency }}</div>
                            </td>
                            <td>
                                <div>{{Job.money_owed_to_broker | currency }}</div>
                            </td>
                            <td>
                              
                            </td>
                            <td>
                                <div>{{Job.statuses.general | filterReplace}}</div>
                            </td>
                            <td>
                               
                             
                            </td>
                        </tr>
                    </tbody> -->
                </table>

            </div>
        </div>

        <!-- ---- Tabla de Jobs ---- end -->

        <!-- ---- Tabla de Contacts ---- start -->

        <div class="skills layout-spacing" *ngIf="selectTap == 'contacts'">
            <div class="row section widget-content widget-content-area">

                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-10 col-md-10">
                        <div class="title-table"> Contacts </div>
                        <div> Storage Companies Contact </div>
                    </div>

                    <div class="col-2 col-md-2 text-right">
                        <button type="button" class="btn button-add" data-toggle="modal"
                            (click)="openAddModalContact(null)" >Add</button>
                    </div>
                </div>

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">Main</th>
                            <th scope="col">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contact of rowsStorageCompanyContacts">
                            <td>
                                {{contact.name}}
                            </td>
                            <td>
                                {{contact.phone}}
                            </td>
                            <td>
                                {{contact.email}}
                            </td>
                            <td class="text-center">
                                <input type="checkbox" name="chek-{{contact.id}}" [disabled]="contact.main"
                                    [(ngModel)]="contact.main" (click)="editContact(contact)">
                            </td>
                            <td>
                                <button class="btn btn-success" (click)="openAddModalContact(contact)">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button class="btn btn-danger" (click)="removeContact(contact.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>                                
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

        <!-- ---- Tabla de Contacts ---- end -->

    </div>

</div>



<!-- Inicio Modal add -->
<div class="modal fade" id="addModal" #addModal tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Auto Transport
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Model</label>
                                    <div class="list-title">
                                        <input id="autotransport_model" type="text" placeholder="Model"
                                            class="form-control" name="autotransport_model">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_price">Price</label>
                                    <div class="list-title">
                                        <input id="autotransport_price" type="number" step="0.01" placeholder="Price"
                                            class="form-control" name="autotransport_price">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_deposit">Deposit</label>
                                    <div class="list-title">
                                        <input id="autotransport_deposit" type="number" step="0.01"
                                            placeholder="Deposit" class="form-control" name="autotransport_deposit">
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">Brand</label>
                                    <div class="list-title">
                                        <input id="autotransport_brand" type="text" placeholder="Brand"
                                            class="form-control" name="autotransport_brand">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_year">Year</label>
                                    <div class="list-title">
                                        <input id="autotransport_year" type="text" placeholder="Year"
                                            class="form-control" name="autotransport_year">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_year">VIN</label>
                                    <div class="list-title">
                                        <input id="autotransport_vin" type="text" placeholder="VIN" class="form-control"
                                            name="autotransport_vin">
                                    </div>
                                </div>
                                <div class="form-group mt-5">
                                    <div class="list-title">
                                        <label class="new-control new-checkbox checkbox-warning" style="width: 100px;">
                                            <input type="checkbox" class="new-control-input">
                                            <span class="new-control-indicator"></span>
                                            Can Run
                                        </label>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label for="autotransport_total_price">Total price</label>
                                    <div class="list-title">
                                        <input id="autotransport_total_price" [(ngModel)]="autoTransport.total_price" type="number" readonly placeholder="Total price" class="form-control" name="autotransport_total_price">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button data-dismiss="modal" type="button" class="btn btn-primary">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Fin Modal de add -->

<!-- Inicio Modal Contacts -->
<div class="modal fade" id="addModal" #addModalContact tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <form class="form" #entityForm="ngForm" ngNativeValidate autocomplete="off" (ngSubmit)="saveContact()">
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        {{contactAction}} Storage Company Contacts
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg></button>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content">
                            <div class="row">
    
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="autotransport_model">Name (Only Letters)</label>
                                        <div class="list-title">
                                            <input id="autotransport_model" type="text" placeholder="Name" pattern="[A-Za-z ]+"
                                                class="form-control" name="name" [(ngModel)]="rowStorageCompanyContact.name" required>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="autotransport_price">Phone</label>
                                        <div class="list-title">
                                            <input id="autotransport_price" type="text" mask="(000) 000-0000"
                                                placeholder="(000) 000-0000" class="form-control" name="phone"
                                                [(ngModel)]="rowStorageCompanyContact.phone" required>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="autotransport_brand">Email</label>
                                        <div class="list-title">
                                            <input id="autotransport_brand" type="email" placeholder="Email"
                                                class="form-control" name="email" [(ngModel)]="rowStorageCompanyContact.email" required>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="modal-footer">
                    <button class="btn" data-dismiss="modal">
                        <i class="flaticon-cancel-12"></i>
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-warning">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Fin Modal Contacts -->
