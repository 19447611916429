export class EmailSetting {
    public id: string;
    public mail_server: string;
    public mail_server_port: string;
    public mail_address: string;
    public mail_password: string;
    public use_send_grid: boolean;

    constructor() {
        this.id = null;
        this.mail_server = null;
        this.mail_server_port = null;
        this.mail_address = null;
        this.mail_password = null;
        this.use_send_grid = false;
    }
}
