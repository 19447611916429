import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { BoardJob } from 'src/app/entities/workspace/board-job';
import { Carrier } from 'src/app/entities/workspace/carrier';
import { CarrierCandidateView } from 'src/app/entities/workspace/carrier-candidate-view';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';
import { JobOutsourcing } from 'src/app/entities/workspace/job-outsourcing';
import { JobView } from 'src/app/entities/workspace/job-view';
import { AuthService } from 'src/app/services/auth.service';
import { CarriersContactsCompanyService } from 'src/app/services/companies/carriers-contacts-company.service';
import { JobOutsourcingsService } from 'src/app/services/companies/job-outsourcings.service';
import { JobsService } from 'src/app/services/companies/jobs.service';
import { HelperService } from 'src/app/services/helper.service';
import { CarriersService } from 'src/app/services/workspaces/carriers.service';
import { environment } from 'src/environments/environment';
import { JobOutsourcingCancelledView } from 'src/app/entities/workspace/job-outsourcing-cancelled-view';
import { BoardJobsService } from 'src/app/services/companies/board-jobs.service';
import { CarrierCandidatesService } from 'src/app/services/companies/carrier-candidates.service';
import { CarrierCandidate } from 'src/app/entities/workspace/carrier-candidate';
import { GeneralSettings } from 'src/app/entities/workspace/general-settings';
import { GeneralSettingsService } from 'src/app/services/companies/general-settings.service';
import { BalancesService } from 'src/app/services/companies/balances.service';
import { Balance } from 'src/app/entities/workspace/balance';
import { log } from 'console';


declare var jQuery;
declare var swal;
@Component({
    selector: 'app-moving-outsourcings',
    templateUrl: './moving-outsourcings.component.html',
    styleUrls: ['./moving-outsourcings.component.scss']
})
export class MovingOutsourcingsComponent implements OnInit {

    readonly environment = environment;
    private constantsMessages = ConstantsMessages;
    public generalSettings: GeneralSettings;
    private balances: Balance[];

    @ViewChild('modalInformationCarriers') modalInformationCarriers: ElementRef;
    @ViewChild('modalCarriers') modalCarriers: ElementRef;
    @ViewChild('emailsModal') emailsModal: ElementRef;


    @ViewChild('reasonCancellationModal') reasonCancellationModal: ElementRef;
    @ViewChild('boardJobModal') boardJobModal: ElementRef;
    @ViewChild('candidateCarrierAssignmentModal') candidateCarrierAssignmentModal: ElementRef;
    @ViewChild('modalCreateCarrierCandidate') modalCreateCarrierCandidate: ElementRef;

    /********
     * variables para listar
     */
    carrierCandidates: Array<CarrierCandidateView>;
    JobOutsourcingCancelleds: Array<JobOutsourcingCancelledView>;
    carriersContacts: Array<CarrierContact>;
    carriers: Array<Carrier>;

    /******
     * Variables para operar
     */
    boardJob: BoardJob;
    boardJobForm: BoardJob;
    boardJobPercentage: number;
    jobOutsourcingForm: JobOutsourcing;
    carrierCandidateContact: any;
    carrierCandidateSelectContact: any;

    carrier: Carrier;
    job: JobView;

    carrierBalancePercentage: number;
    carrierBalanceOffer: number;
    specialConditions: string;
    isPublicJobOutsourcing: boolean;
    filterSearch: string;
    checkContacts: string[];
    carrierContacts: Array<CarrierContact>;
    viewSectionModalCarrier: "INTRO" | "POST_REQUEST_CARRIER" | "ASSIGN_CARRIER";
    createCarrierCandidate: CarrierCandidate;

    constructor(
        private authService: AuthService,
        private balancesService: BalancesService,
        private boardJobsService: BoardJobsService,
        private carrierCandidatesService: CarrierCandidatesService,
        private carriersContactsCompanyService: CarriersContactsCompanyService,
        private carrierService: CarriersService,
        private currentRoute: ActivatedRoute,
        private generalSettingsService: GeneralSettingsService,
        private helperService: HelperService,
        private jobOutsourcingsService: JobOutsourcingsService,
        private jobsService: JobsService,
    ) {
        this.job = new JobView();
        this.carriersContacts = [];
        this.boardJob = null;
        this.boardJobForm = null;
        this.carrierBalancePercentage = null;
        this.carrierBalanceOffer = null;
        this.specialConditions = null;
        this.isPublicJobOutsourcing = true;
        this.filterSearch = '';
        this.carriers = [];
        this.carrier = null;
        this.checkContacts = [];
        this.carrierContacts = [];

        //----------------------------------------------------------------

        this.carrierCandidates = [];
        this.jobOutsourcingForm = null;
        this.carrierCandidateContact = [];
        this.carrierCandidateSelectContact = [];
        this.createCarrierCandidate = new CarrierCandidate();
        this.balances = [];
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        // verficamos si se esta editando
        this.currentRoute.parent.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
                this.loadCancelledJobOutsourcings(params.id);
                this.loadBoardJob(params.id);
                this.loadCompanySettings();
                this.loadPayments(params.id);
            }
        });

        this.loadCarriers();
    }

    private loadPayments(estimateId: string) {
        this.balancesService
        .getById(estimateId, {})
        .then((response) => {
            this.balances = response;
        });
    }

    private loadCompanySettings() {
        this.generalSettingsService
            .get()
            .then((response) => {
                this.generalSettings = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobsService
            .getById(id)
            .then((response) => {
                this.job = response;
                console.log(' el job', this.job)
                if (this.job.carrier) {
                    this.loadContacts(this.job.carrier.id);
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    private loadContacts(idCarrier: string) {
        this.helperService.showLoadingMxpro360();
        this.carriersContactsCompanyService
            .getFullContactsByCarrier(idCarrier)
            .then((response) => {
                this.carriersContacts = response;

            })
            .catch((error) => {
                console.error('Error:', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    private loadCancelledJobOutsourcings(job_id: string) {
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService
            .getCancelleds(job_id)
            .then((response) => {
                this.JobOutsourcingCancelleds = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    showModalInformationCarriers(viewSectionModalCarrier) {
        // averigua el valor porcentual para el offer
        if (this.generalSettings.settings.price_setting.carrier_balance_mode == 'BY_PERCENTAGE') {
            const carrierBalancePercentage = this.generalSettings.settings.price_setting.carrier_balance_percentage;
            this.carrierBalancePercentage = this.carrierBalancePercentage ? this.carrierBalancePercentage : carrierBalancePercentage;
            this.carrierBalanceOffer = (this.carrierBalancePercentage * this.job.estimate.total) / 100;
            this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
        } else {
            // averigua el valor restante para pagar
            let paidValue = 0
            for(let balance of this.balances) {
                if (balance.status === 'PAID') {
                    paidValue += balance.amount;
                }
            }
            this.carrierBalanceOffer = this.job.estimate.total - paidValue;
            this.carrierBalanceOffer = Number(this.carrierBalanceOffer.toFixed(2));
            this.carrierBalancePercentage = (this.carrierBalanceOffer * 100 ) / this.job.estimate.total;
            this.carrierBalancePercentage = Number(this.carrierBalancePercentage.toFixed(2));
        }

        this.viewSectionModalCarrier = viewSectionModalCarrier;
        this.specialConditions = '';
        jQuery(this.modalInformationCarriers.nativeElement).modal('show');
    }

    setCarriersModal(): void {
        jQuery(this.modalInformationCarriers.nativeElement).modal('hide');
        // this.viewSectionModalCarrier = 'INTRO';
        jQuery(this.modalCarriers.nativeElement).modal('show');
    }

    selectCarrier(carrier: Carrier, sendAcceptance): void {
        this.carrier = carrier;

        jQuery(this.modalCarriers.nativeElement).modal('hide');
        this.openEmailsModal();

        this.job.job_outsourcing = new JobOutsourcing();
        this.job.job_outsourcing.carrier_id = '' + carrier.id;
        this.job.job_outsourcing.type = 'ALL';
        this.job.job_outsourcing.status = 'PENDING_SIGNATURE';
        this.job.job_outsourcing.special_conditions = this.specialConditions;
        this.job.job_outsourcing.carrier_pay = this.carrierBalanceOffer;
        const data = {
            job_outsourcing: this.job.job_outsourcing,
            carrier_contacts: []
        };

        this.helperService.showLoadingMxpro360();

        this.jobOutsourcingsService
            .assignOutsourcingWorkspace(this.job.id, data, sendAcceptance)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.load(this.job.id);
                this.loadCancelledJobOutsourcings(this.job.id);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    // openEmailsModal(): void {
    //     this.helperService.showLoadingMxpro360();
    //     this.carriersContactsCompanyService
    //         .getContactsByWorkspaceCarrier(this.carrier.id, this.job.company_id)
    //         .then((response) => {

    //             // this.carrierContacts = response;
    //             // if (this.carrierContacts.length > 0) {
    //             //     jQuery(this.emailsModal.nativeElement).modal('show');
    //             // }
    //         })
    //         .catch((error) => {

    //         })
    //         .finally(() => {
    //             this.helperService.hideLoadingMxpro360();
    //         });
    // }


    saveCarrierAndAgent(): void {
        this.saveCarrier();
    }

    private saveCarrier(): void {
        // this.helperService.showLoadingMxpro360();
        // this.jobsService
        //     .patchEntity(this.job.id, { carrier: this.job.carrier })
        //     .then((response) => {
        //         jQuery(this.modalCarriers.nativeElement).modal('hide');
        //         this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
        //     })
        //     .catch((error) => {
        //         this.helperService.showMessageSnackbar(ConstantsMessages.ERROR_SAVED);
        //         console.error('error', error);
        //     })
        //     .finally(() => {
        //         this.helperService.hideLoadingMxpro360();
        //     });
    }

    postOnLoadBoard() {
        const jobOutsourcing = new JobOutsourcing();
        jobOutsourcing.carrier_id = null;
        jobOutsourcing.type = 'ALL';
        jobOutsourcing.status = 'WAITING_FOR';
        jobOutsourcing.special_conditions = this.specialConditions;

        this.job.job_outsourcing = jobOutsourcing;

        jQuery(this.modalCarriers.nativeElement).modal('hide');

        const data = {
            job_outsourcing: this.job.job_outsourcing
        };

        this.jobOutsourcingsService
            .putWorspace(data, this.job.id, this.job.company_id)
            .then((response) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                // this.load(this.job.id);
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    copyUrlCarrier() {

        let url = environment.app.carriers + '/#/job-acceptance/' + this.authService.workspaceSession.id + '/sign/' + this.job.job_outsourcing.id;

        if (this.job.estimate.service === 'FREIGHT'){
            url = environment.app.carriers + '/#/rate-shieet/' + this.authService.workspaceSession.id + '/sign/' + this.job.job_outsourcing.id;
        }

        // http://localhost:4200/#/job-acceptance/6038ffcfd5de8514fb248262/sign/6172b9ddd58bb15e82255217
        let listener = (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (url));
            e.preventDefault();
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
        this.helperService.showMessageSnackbar(ConstantsMessages.COPIED);
    }

    setDefault(id) {
        if (this.checkContacts.length > 0) {
            const indexEmployeeId = this.checkContacts.indexOf(id);
            // verifica si se debe agregar o quitar el employeeId al arreglo de ids
            if (indexEmployeeId >= 0) {
                // removemos el item des checkeado
                this.checkContacts.splice(indexEmployeeId, 1);
            } else {
                // agregamos el item checkeado
                this.checkContacts.push(id);
            }
        } else {
            this.checkContacts.push(id);
        }

    }

    sendEmails() {
        const data = { contacts: this.checkContacts };
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService.sendJobAcceptanceEmail(this.job.job_outsourcing.id, data).then(() => {
            this.helperService.showMessageSnackbar(this.constantsMessages.EMAIL_SENT);
        }).catch((error) => {
            swal(
                'Error',
                error.error.message,
                'error'
            );
        }).finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
        // let url = environment.app.carriers + '/#/job-acceptance/' + this.authService.workspaceSession.id + '/sign/' + this.job.job_outsourcing.id;
    }

    reSendEmails() {
        const data = {
            contacts: this.checkContacts,
            job_outsourcing: this.job.job_outsourcing
        };

        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService.reSendJobAcceptanceEmail(this.job.id, data).then(() => {
            this.helperService.showMessageSnackbar(this.constantsMessages.EMAIL_SENT);
        }).catch((error) => {
            swal(
                'Error',
                error.error.message,
                'error'
            );
        }).finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }


    selectDefaultCarrier() {
        if (this.carrierContacts.length > 0) {
            for (let contact of this.carrierContacts) {
                if (contact.main) {
                    this.checkContacts.push(contact.id);
                }
            }
        }

    }

    loadContactsByCarrier() {
        this.helperService.showLoadingMxpro360();
        this.carriersContactsCompanyService
            .getFullContactsByCarrier(this.job.job_outsourcing.carrier_id)
            .then((response) => {
                this.carrierContacts = response;
                this.checkContacts = [];
                if (this.carrierContacts.length > 0) {
                    this.selectDefaultCarrier();
                    jQuery(this.emailsModal.nativeElement).modal('show');
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }




    /**********************
     ** CODIGOS DE AYUDA **
     *********************/


    /**
     * PERMITE descargar EL JOB ACCEPTANCE
     */
    downloadPdf(jobAcceptanceDoc): void {
        this.helperService.downloadReport('CONTRACT_INFORMATION', null, jobAcceptanceDoc);
    }



    /**
     * Permite calcular el porcentaje equivalente al valor de la oferta
     * @param section 
     */
    updateOfferPercentage(section: "BOARD_JOB" | "OUTSOURCING" = "OUTSOURCING") {
        if (section == "OUTSOURCING") {
            this.carrierBalancePercentage = (this.carrierBalanceOffer * 100) / this.job.estimate.total;
            this.carrierBalancePercentage = Number(this.carrierBalancePercentage.toFixed(2));
        } else {
            this.boardJobPercentage = (this.boardJobForm.offer * 100) / this.job.estimate.total;
            this.boardJobPercentage = Number(this.boardJobPercentage.toFixed(2));
        }
    }


    /**
     * Permite calcular el porcentaje equivalente al valor de la oferta
     * @param section 
     */
    updateOutsourcingOfferPercentage() {
            this.boardJobPercentage = (this.jobOutsourcingForm.carrier_pay * 100) / this.job.estimate.total;
            this.boardJobPercentage = Number(this.boardJobPercentage.toFixed(2));
    }



    /****************************************
     ** CODIGO PARA ABRIR Y CERRAR MODALES **
     ****************************************/


    closeModalInformationCarriers(): void {
        jQuery(this.modalInformationCarriers.nativeElement).modal('hide');
    }

    closeModalCarriers(): void {
        jQuery(this.modalCarriers.nativeElement).modal('hide');
    }

    closeEmailsModal(): void {
        if (this.job.job_outsourcing.job_acceptance_doc && this.job.job_outsourcing.job_acceptance_doc.esignatures.main) {
            this.reSendEmails();
        } else {
            this.sendEmails();
        }
        this.load(this.job.id);
        jQuery(this.emailsModal.nativeElement).modal('hide');
    }

    openEmailsModal(): void {
        if (this.carrierContacts.length > 0) {
            jQuery(this.emailsModal.nativeElement).modal('show');
        }
    }

    // ----------------------------------------------------------------

    openCancellationReasonModal() {
        if (this.job.job_outsourcing !== null) {
            jQuery(this.reasonCancellationModal.nativeElement).modal('show');
        }
    }

    closeCancellationReasonModal() {
        jQuery(this.reasonCancellationModal.nativeElement).modal('hide');
    }

    // -- ------------

    /**
     * Permite abrir la modal para operar las publicaciones en boardjobs
     */
    openBoardJobModal() {
        // Si no tiene asignado carrier dejamos abrir
        if (this.job.job_outsourcing == null) {

            // verificamos si hay un job definido, en este caso se asigna al formulario para editarlo
            if (this.boardJob !== null) {

                // reseteamos los datos
                this.boardJobForm = { ... this.boardJob };

                // calculamos inicialmente el porcentaje registrado
                this.updateOfferPercentage('BOARD_JOB');
            } else {

                // reseteamos los datos
                this.boardJobForm = new BoardJob();

                // averigua el valor porcentual para el offer
                if (this.generalSettings.settings.price_setting.carrier_balance_mode == 'BY_PERCENTAGE') {
                    this.boardJobPercentage = this.generalSettings.settings.price_setting.carrier_balance_percentage;
                    this.boardJobForm.offer = (this.boardJobPercentage * this.job.estimate.total) / 100;
                    this.boardJobForm.offer = Number(this.boardJobForm.offer.toFixed(2));
                } else {
                    // averigua el valor restante para pagar
                    let paidValue = 0
                    for(let balance of this.balances) {
                        if (balance.status === 'PAID') {
                            paidValue += balance.amount;
                        }
                    }
                    this.boardJobForm.offer = this.job.estimate.total - paidValue;
                    this.boardJobForm.offer = Number(this.boardJobForm.offer.toFixed(2));
                    this.boardJobPercentage = (this.boardJobForm.offer * 100 ) / this.job.estimate.total;
                    this.boardJobPercentage = Number(this.boardJobPercentage.toFixed(2));
                }

            }

            // abrimos modal
            jQuery(this.boardJobModal.nativeElement).modal('show');
        }
    }

    /**
     * Permite cerrar la modal de board job
     */
    closeBoardJobModal() {
        jQuery(this.boardJobModal.nativeElement).modal('hide');
    }


    /**
     * permite abrir la modal de asignacion de carriers candidates
     * 
     * @param carrierCandidate 
     */
    openCandidateCarrierAssignmentModal(carrierCandidate: CarrierCandidate) {
        // instanciamos el formulario y asignamos los valores necesarios
        this.jobOutsourcingForm = new JobOutsourcing();
        this.jobOutsourcingForm.type = 'ALL';
        this.jobOutsourcingForm.carrier_pay = carrierCandidate.bid;
        this.jobOutsourcingForm.carrier_id = carrierCandidate.id;
        this.jobOutsourcingForm.special_conditions = carrierCandidate.comments;
        this.jobOutsourcingForm.status = "PENDING_SIGNATURE";
        this.jobOutsourcingForm.employee_id = this.authService.workspaceSession.employee.id;
        this.jobOutsourcingForm.job_id = this.job.id;
        this.carrierCandidateContact = carrierCandidate['carrier_contacts'];
        this.carrierCandidateSelectContact = [];
        this.updateOutsourcingOfferPercentage();
        // abrimos modal
        jQuery(this.candidateCarrierAssignmentModal.nativeElement).modal('show');
    }

    /**
     * Permite cerrar la modal de asignacion de carriers candidates
     */
    closeCandidateCarrierAssignmentModal(){
        // cerramos modal
        jQuery(this.candidateCarrierAssignmentModal.nativeElement).modal('hide');
    }

    /**
     * Permite cerrar la modal de creacion de carriers candidates
     */
     closeModalCreateCarrierCandidate(){
        // cerramos modal
        jQuery(this.modalCreateCarrierCandidate.nativeElement).modal('hide');
    }

    /***************************************
     * CODIGO PARA TRATAR LOS OUTSOURCINGS *
     ***************************************/

    /**
     * permite cargar los carriers
     */
    private loadCarriers() {
        this.carrierService
            .getAll()
            .then((carriers) => {
                this.carriers = carriers;
            });
    }

    /**
     * Permite cancelar un carrier asignado a un trabajo
     */
    cancelJobOutsourcing() {
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService
            .toCancel(this.job.job_outsourcing)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.DELETED);
                const job_id = this.job.id;
                this.load(job_id);
                this.loadCancelledJobOutsourcings(job_id);
                this.loadBoardJob(job_id);
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                this.load(this.job.id);
            });
    }

    /************************************
     * CODIGO PARA TRATAR LOS BOARD JOB*
     ************************************/

    /**
     * Permite cargar la informacion del boardjob actual
     * 
     * @param job_id 
     */
    private loadBoardJob(job_id: string) {
        this.helperService.showLoadingMxpro360();
        this.boardJobsService
            .getByJobId(job_id)
            .then((response) => {
                this.boardJob = response;
                this.loadCarriersCandidates(this.boardJob.id);
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite cargar los carriers candidatso que se han postulado para hacer el trabajo
     * @param board_job_id 
     */
    private loadCarriersCandidates(board_job_id: string) {
        this.helperService.showLoadingMxpro360();
        this.carrierCandidatesService
            .getAllByBoardJobId(board_job_id)
            .then((response) => {
                this.carrierCandidates = response;
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permtie guardar la informacion de boardJob
     */
    saveBoardJob() {
        // mostramos el loading
        this.helperService.showLoadingMxpro360();

        // asignamos el identificador del trabajo al board job
        // this.boardJobForm.job_id = this.job.id;

        this.boardJobsService
            .save(this.boardJobForm, this.job.id)
            .then((response) => {


                // en caso de que el registro sea nuevo
                if (this.boardJobForm.id === null) {

                    // se asigna el registro guardado para no tener que vovler a solicitar la informacion al servidor
                    this.boardJob = response['row'];
                } else {

                    // reseteamos los datos
                    this.boardJob = { ... this.boardJobForm };
                }


                // cerramos modal
                this.closeBoardJobModal();
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite asignar un carrier candidato a un trabjo
     * @param candidate 
     * @returns 
     */
    assignCarrierCandidateToJob() {

        // validamos que haya una oferta por parte del carrier
        if (this.jobOutsourcingForm.carrier_pay <= 0) {
            return;
        }

        this.helperService.showLoadingMxpro360();

        let data = {
            'job_outsourcing' : this.jobOutsourcingForm,
            'carrier_contacts'  : this.carrierCandidateSelectContact
        }

        this.jobOutsourcingsService
            .assignOutsourcing(this.jobOutsourcingForm.carrier_id, data)
            .then(() => {
                this.openEmailsModal();
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.closeCandidateCarrierAssignmentModal();
                this.load(this.job.id);

            })
            .catch((error) => {
                console.error('error', error);
                if (error.error.message == 'The carrier contact was not found') {
                    swal(
                        'Atention',
                        'Carrier was assigned but the email was not sent because of there are not a main contact',
                        'warning'
                    );
                    this.load(this.job.id);
                } else {
                    this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                }
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            }); 
    }

    
    toogleContacts(contact){
        let flagDuplicate = true;
        for (let index = 0; index < this.carrierCandidateSelectContact.length; index++) {
            if (this.carrierCandidateSelectContact[index].id == contact.id){
                flagDuplicate = false;
                this.carrierCandidateSelectContact.splice(index, 1);
            }
        }
        if (flagDuplicate){
            this.carrierCandidateSelectContact.push(contact);
        }
    }

    openModalCreateCarrierCandidate(){
        jQuery(this.modalCreateCarrierCandidate.nativeElement).modal('show');
    }

    saveCreateCarrierCandidate() {
        console.log('boardJobId');
        this.helperService.showLoadingMxpro360();
        this.boardJobsService
            .createCarrierCandidate(this.createCarrierCandidate, this.job.id)
            .then(() => {
                this.createCarrierCandidate = new CarrierCandidate();
                jQuery(this.modalCreateCarrierCandidate.nativeElement).modal('hide');
                this.loadCarriersCandidates(this.job.id);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        
    }

    deleteCreateCarrierCandidate(id){
        this.helperService.showLoadingMxpro360();
        this.boardJobsService
            .deleteCarrierCandidate(id)
            .then((response) => {
                this.createCarrierCandidate = new CarrierCandidate();
                jQuery(this.modalCreateCarrierCandidate.nativeElement).modal('hide');
                this.loadCarriersCandidates(this.boardJob.id);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
        
    }

    sendBOLDocumentByEmail(){
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService
        .sendBol(this.job.id, {})
            .then((response) => {
                swal({
                    title: 'Successful!',
                    text: "Your Bol send",
                    type: 'success',
                    padding: '2em'
                })
            }).catch((error) => {
                swal(
                    'Error',
                    error.error.message,
                    'error'
                );
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    sendNewJobAcceptance(carrier){
        this.carrier = carrier;
        this.job.job_outsourcing.reason = 'RESEND';
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService
            .toCancel(this.job.job_outsourcing)
            .then(() => {                
                this.selectCarrier(this.job.carrier, true);
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                this.load(this.job.id);
            });
    }
}