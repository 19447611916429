import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Role } from 'src/app/entities/workspace/role';
import { HelperService } from 'src/app/services/helper.service';
import { RolesService } from 'src/app/services/workspaces/roles.service';


@Component({
    selector: 'app-roles-form',
    templateUrl: './roles-form.component.html',
    styleUrls: ['./roles-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class RolesFormComponent implements OnInit {

    constantsMessages = ConstantsMessages;
    formData: Role;

    constructor(
        private currentRoute: ActivatedRoute,
        private rolesService: RolesService,
        private helperService: HelperService,
    ) {
        this.formData = new Role();
    }

    ngOnInit(): void {
        
    }

    ngAfterViewInit() {
        this.currentRoute.params
            .subscribe(params => {
                if (typeof params.id !== 'undefined') {
                    this.load(params.id);
                }
            });
    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.rolesService
            .getById(id)
            .then((response) => {
                this.formData = response;
            })
            .catch((error) => {
                this.helperService.hideLoadingMxpro360();
                console.error('error', error);
            })
    }

    /**
     * Guarda el formData
     */
    save(form: NgForm) {

        if(form.form.status !== 'VALID'){
            return;
        }
        
        this.helperService.showLoadingMxpro360();
        this.rolesService
            .save(this.formData)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/roles');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }
}
