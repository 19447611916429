import { InventoryItem } from "./inventory-item";


export class EstimateInventoryContainerItem {

    public id: string;
    public inventory_item: InventoryItem;
    public quantity: number;

    constructor() {
        this.id = null;
        this.inventory_item = new InventoryItem();
        this.quantity = 0;
    }
}
