import { Component, OnInit } from '@angular/core';
import { Vehicle } from 'src/app/entities/workspace/vehicle';
import { HelperService } from 'src/app/services/helper.service';
import { VehiclesService } from 'src/app/services/workspaces/vehicles.service';

declare const swal;
declare const jQuery;

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss']
})
export class VehiclesComponent implements OnInit {

  public rows: Array<Vehicle>;
  public filterSearch: string;
  public actualPage: number;

  constructor(
    private vehiclesService: VehiclesService,
    private helperService: HelperService
  ) {
    this.filterSearch = '';
    this.actualPage = 1;
  }

  ngOnInit() {
  }

  
  ngAfterViewInit() {
    this.load();
  }

  private load() {
    this.helperService.showLoadingMxpro360();
    this.vehiclesService
      .getAll()
      .then((response) => {
        this.rows = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }


  remove(id: string) {
    swal({
      title: 'Are you sure?',
      text: "Are you sure do you want to delete this record?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.vehiclesService
            .remove(id)
            .then(() => {
              this.load();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

}
