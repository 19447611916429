<div class=" layout-spacing layout-top-spacing">

    <div class="row">
        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-4">
            <h3>
                Carriers
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/carriers">Carriers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{carrier.name}}</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right align-self-center mb-4">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <div class="btn-group dropleft mt-2 mr-2" role="group">
                    <button id="btnOutline" type="button" class="btn btn-main-action dropdown-toggle"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Options
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnOutline" style="will-change: transform;">
                        <a appWorkspaceRouterLink="/carriers/{{carrier.id}}/edit" class="dropdown-item">
                            <i class="fa-solid fa-pen-to-square"></i>
                            Edit
                        </a>
                        <a (click)="copyText(carrier.id)" class="dropdown-item cursor">
                            <i class="fa-solid fa-copy"></i>
                            Copy Link
                        </a>
                        <a (click)="changeStatusCarrier()" class="dropdown-item cursor">
                            <span *ngIf="carrier.active">
                                <i class="fa-solid fa-rectangle-xmark"></i>
                                Disable
                            </span>
                            <span *ngIf="!carrier.active">
                                <i class="fa-solid fa-square-check"></i>
                                Activate
                            </span>
                        </a>
                        <div class="dropdown-divider" [hidden]="true"></div>
                        <a (click)="remove(carrier.id)" class="dropdown-item cursor"  [hidden]="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-trash-2">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                </path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            Delete
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

        <!-- ---- Carrier Info ---- start -->
        <div class="row skills layout-spacing pb-26">

            <div class="col-8">
                <div class="row section widget-content widget-content-area block-carrier-name">

                    <div class="col-12">
                        <div class="carrier-name">{{carrier.name}}</div>
                    </div>

                </div>

                <div class="row section widget-content widget-content-area">

                    <div class="col-4 logo-content">
                        <input type="file" id="input-file-max-fs" [appDropify] (onChange)="onChangeLogo($event)"
                            class="dropify" data-max-file-size="2M" />
                    </div>

                    <div class="col-8 p-0">                        
                        <div class="carrier-info">
                            <div class="carrier-info-date">
                                <i class="fa-solid fa-building"></i>
                                <strong>DBA:</strong>
                                <span>
                                    {{carrier.dba}}
                                </span>
                            </div>
                            <div class="carrier-info-date">
                                <i class="fa-solid fa-lightbulb"></i>
                                <strong>Status:</strong>
                                <span *ngIf="carrier.active" class="status-active">
                                    Active
                                </span>
                                <span *ngIf="!carrier.active" class="status-disabled">
                                    Inactive
                                </span>
                            </div>
                            <div class="carrier-info-date">
                                <i class="fa-solid fa-clipboard-check"></i>
                                <strong>Tax ID: </strong>
                                <span *ngIf="carrier.federal_tax_id">
                                    {{carrier.federal_tax_id}}
                                </span>
                                <span *ngIf="!carrier.federal_tax_id" class="tax-id-disabled">
                                    <i class="fa-solid fa-circle-question"></i>
                                </span>
                            </div>
                            <div class="carrier-info-date">
                                <i class="fas fa-map-marker-alt"></i>
                                <strong>Address:</strong>
                                <span>{{carrier.address.full_address}}</span>
                            </div>
                            <div class="d-flex">
                                <div class="carrier-info-date mr-5">
                                    <i class="fa-solid fa-shield-halved"></i>
                                    <strong>DOT: </strong>
                                    <span>{{carrier.dot}}</span>
                                </div>
                                <div class="carrier-info-date ml-3">
                                    <i class="fa-solid fa-lock"></i>
                                    <strong>PIN: </strong>
                                    <span>{{carrier.pin}}</span>
                                </div>
                            </div>                            
                            <div *ngIf="carrier.comment" class="carrier-info-date">
                                <i class="fa-solid fa-comment"></i>
                                <strong>Comment: </strong>
                                <span>{{carrier.comment}}</span>
                            </div>
                        </div>                     
                    </div>
                    
                </div>
            </div>

            <div class="col-4 pl-16 pr-0">
                <div class="section widget-content widget-content-area block-carrier-info-date">                   
                    <div class="d-flex mb-4">
                        <div class="pl-0 mr-5">
                            <div class="title-start-date">Start Date</div>
                            <div class="value-start-date">{{carrier.created | date}}</div>
                        </div>

                        <div class="pl-0">
                            <div class="title-date-insurance">Due Date of Insurance</div>
                            <div class="value-date-insurance" *ngIf="carrier.insurance_due_date">
                                {{carrier.insurance_due_date | date}}</div>
                            <div class="value-date-insurance" *ngIf="!carrier.insurance_due_date">No Date</div>
                        </div>
                    </div>
                    <div class="pl-0">
                        <div class="title-progress-jobs">Progress Jobs</div>
                        <div class="progress progress-jobs br-30 m-auto">
                            <div class="progress-bar bg-success" role="progressbar" aria-valuenow="25"
                                aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-title"><span>Jobs </span>
                                    <span>{{totalPercentageBalance}}%</span> </div>
                            </div>
                        </div>
                    </div>                 
                </div>
            </div>

        </div>
        <!-- ---- Carrier Info ---- end -->

        <!-- ---- Navbar ---- start -->
        <div class="skills layout-spacing pb-26">
            <div class="row section widget-content widget-content-area section-menu-top">
                <div class="col-12 container-menu-top">

                    <div id="btn-nav-previous">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                            viewBox="0 0 24 24">
                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>

                    <div id="btn-nav-next">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                            viewBox="0 0 24 24">
                                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>

                    <nav class="menu-top">                        

                        <div class="menu-option cursor" (click)="selectTap = 'balances'" [ngClass]="{'menu-top-selected': selectTap == 'balances'}">
                            <div class="d-flex">
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fas fa-dollar-sign icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Balances</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>    
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'contacts'; loadCarriersContacts(carrierId)" [ngClass]="{'menu-top-selected': selectTap == 'contacts'}">
                            <div class="d-flex">  
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fas fa-user-alt icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Contacts</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>  
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'jobs'; loadCarriersJobs()" [ngClass]="{'menu-top-selected': selectTap == 'jobs'}">
                            <div class="d-flex">  
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fas fa-truck-loading icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Jobs</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'documents'; loadCarriersDocuments(carrierId)" [ngClass]="{'menu-top-selected': selectTap == 'documents'}">
                            <div class="d-flex">
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fas fa-file-alt icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Documents</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'drivers'; loadCarriersDrivers(carrierId)" [ngClass]="{'menu-top-selected': selectTap == 'drivers'}">
                            <div class="d-flex">
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fas fa-truck icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Drivers</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'coverage'; loadCarriersCoverage()" [ngClass]="{'menu-top-selected': selectTap == 'coverage'}">
                            <div class="d-flex"> 
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fa-solid fa-location-dot icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Coverage</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'files'; loadCarriersFiles(carrierId)" [ngClass]="{'menu-top-selected': selectTap == 'files'}">
                            <div class="d-flex">  
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fas fa-folder-open icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Files</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>
                        </div>

                        <div class="menu-option cursor" (click)="selectTap = 'setting-bol'" [ngClass]="{'menu-top-selected': selectTap == 'setting-bol'}">
                            <div class="d-flex">    
                                <div class="col-4 col-md-4 icon-content">
                                    <i class="fa-solid fa-paste icon-menu-top"></i>
                                </div>
                                <div class="col-8 col-md-8 px-0">
                                    <div class="bar-title">Terms BOL</div>
                                    <div class="link-view">View</div>
                                </div>
                            </div>
                        </div>

                    </nav>

                </div>
            </div>
        </div>
        <!-- ---- Navbar ---- end -->

        <!-- ---- Tabla de Balances ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'balances'">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="section widget-content widget-content-area">

                        <div class="row col-12 col-md-12 mb-3">
                            <div class="col-10 col-md-10">
                                <div class="title-table"> Balances </div>
                                <div> Carrier´s balances </div>
                            </div>

                            <div class="col-2 col-md-2 text-right">
                                <!-- <button type="button" class="btn button-add" data-toggle="modal" *ngIf="carrier.active" >Add</button> -->
                            </div>
                        </div>

                        <table class="table">
                            <thead class="table-head">
                                <tr>
                                    <th scope="col">Job</th>
                                    <th scope="col" class="text-center">Amount</th>
                                    <th scope="col">Concept</th>
                                    <th scope="col" class="text-center">Status</th>
                                    <th scope="col">Employee</th>
                                    <th scope="col">Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let balance of balances | paginate: {itemsPerPage: 10, currentPage: actualPage}">
                                    <td>
                                        {{balance.estimate_document.code}}
                                    </td>
                                    <td class="text-center">
                                        {{balance.amount}}
                                    </td>
                                    <td>
                                        {{balance.concept}}
                                    </td>
                                    <td class="text-center">
                                        <span class="badge" [ngClass]="{'badge-warning': balance.status == 'PENDING', 'badge-success': balance.status == 'PAID'}">
                                            {{balance.status | filterReplace}}
                                        </span>
                                    </td>
                                    <td>
                                        {{balance.employee.name | titlecase}}
                                    </td>
                                    <td>
                                        CARRIER TO BROKER
                                    </td>
                                </tr>
                                <tr *ngFor="let expense of expenses | paginate: {itemsPerPage: 10, currentPage: actualPage}">
                                    <td>
                                        {{expense.jobId}}
                                    </td>
                                    <td class="text-center">
                                        {{expense.amount}}
                                    </td>
                                    <td>
                                        {{expense.concept}}
                                    </td>
                                    <td class="text-center">
                                        <span class="badge" [ngClass]="{'badge-warning': expense.status == 'PENDING', 'badge-success': expense.status == 'PAID'}">
                                            {{expense.status | filterReplace}}
                                        </span>
                                    </td>
                                    <td>
                                        {{expense.employee.name | titlecase}}
                                    </td>
                                    <td>
                                        EXPENSES
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="paginating-container pagination-solid"
                            (pageChange)="actualPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        <!-- ---- Tabla de Balances ---- end -->

        <!-- ---- Tabla de Jobs ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'jobs'">
            <div class="row section widget-content widget-content-area">

                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-9 col-md-9">
                        <div class="title-table"> Jobs </div>
                        <div>Jobs took by the carrier</div>
                    </div>
                    <div class="col-3 col-md-3">
                        <label>Filter Company</label>
                        <select [(ngModel)]="company" name="cities" class="form-control" [appSelect2]
                            [theme]="'mxp360-form'" (ngModelChange)="filterByCompany()">
                            <option value="{{company.id}}" *ngFor="let company of companies">
                                {{company.name | titlecase}} </option>
                        </select>
                    </div>
                </div>

                <table class="table">
                    <tbody>
                        <ng-container *ngFor="let carrierJob of carrierJobs; index as i;">
                            <tr [ngClass]="{'odd-row': i % 2 !== 0}">
                                <td>
                                    <div><strong>Customer:</strong></div>
                                    <div><strong>Name: </strong>{{carrierJob.customer.name}} {{carrierJob.customer.last_name}}</div>
                                    <div><strong>Phone: </strong>{{carrierJob.customer.phone}}</div>
                                    <div><strong>Email: </strong>{{carrierJob.customer.email}}</div>
                                </td>
                                <td>
                                    <div><strong>From:</strong></div>
                                    <div><strong>Address:</strong> {{carrierJob.estimate.pickup.address.state}} {{carrierJob.estimate.pickup.address.zip_code}}</div>
                                    <div><strong>Start: </strong>{{carrierJob.estimate.pickup.range_start | date}} </div>
                                    <div><strong>End: </strong>{{carrierJob.estimate.pickup.range_end | date}}</div>
                                </td>
                                <td>
                                    <div><strong>To:</strong></div>
                                    <div><strong>Address:</strong> {{carrierJob.estimate.delivery.address.state}} {{carrierJob.estimate.delivery.address.zip_code}}</div>
                                    <div><strong>Start: </strong>{{carrierJob.estimate.delivery.range_start | date}} </div>
                                    <div><strong>End: </strong>{{carrierJob.estimate.delivery.range_end | date}}</div>
                                </td>
                                <td>
                                    <div><strong>Job:</strong></div>
                                    <div><strong>Service: </strong>{{carrierJob.estimate.service}}</div>
                                    <div><strong>Job #: </strong>{{carrierJob.estimate.document.code}}</div>
                                    <div><strong>Status: </strong>{{carrierJob.statuses.general | filterReplace}}</div>
                                    <div><strong>Is Signed: </strong>
                                        <span *ngIf="carrierJob.estimate.esignature">Yes</span>
                                        <span *ngIf="!carrierJob.estimate.esignature">No</span>
                                    </div>
                                </td>
                            </tr>
                            <tr [ngClass]="{'odd-row': i % 2 !== 0}">    
                                <td>
                                    <div><strong>Volume:</strong></div>
                                    <div>
                                        <strong *ngIf="carrierJob.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                        <strong *ngIf="carrierJob.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                        {{carrierJob.estimate.volume.libs}}</div>
                                    <div>
                                        <strong *ngIf="carrierJob.estimate.estimate_route_tariff.tariff_version.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                        <strong *ngIf="carrierJob.estimate.estimate_route_tariff.tariff_version.measure_unit == 'POUNDS'">Lbs: </strong>
                                        {{carrierJob.estimate.volume.cubic_feets}}</div>
                                    <div><strong>Miles: </strong>{{carrierJob.estimate.volume.miles}}</div>
                                </td>
                                <td colspan="3">
                                    <div><strong>Balance:</strong></div>
                                    <div><strong>Owed On Job:</strong> {{carrierJob.balanced_owed_on_job | currency }}</div>
                                    <div><strong>Owed On Broker:</strong> {{carrierJob.money_owed_to_broker | currency }}</div>
                                    <div><strong>Carrier Pay:</strong> {{carrierJob.carrier_pay | currency }}</div>
                                    <div><strong>Missing to Carrier Pay:</strong> {{carrierJob.missing_to_carrier_pay | currency}}</div>
                                </td>
                                <!-- <td colspan="2">
                                    <div><strong>Route:</strong></div>
                                    <div><strong>Pickup Address:</strong> {{carrierJob.estimate.pickup.address.state}} {{carrierJob.estimate.pickup.address.zip_code}}</div>
                                    <div><strong>Pickup Window:</strong> {{carrierJob.estimate.pickup.range_start | date}} {{carrierJob.estimate.pickup.range_end | date}}</div>

                                    <div><strong>Delivery Address:</strong> {{carrierJob.estimate.delivery.address.state}} {{carrierJob.estimate.delivery.address.zip_code}}</div>
                                    <div><strong>Delivery Window:</strong> {{carrierJob.estimate.delivery.range_start | date}} {{carrierJob.estimate.delivery.range_end | date}}</div>
                                </td> -->
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

            </div>
        </div>
        <!-- ---- Tabla de Jobs ---- end -->

        <!-- ---- Tabla de coverage ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'coverage'">
            <div class="row section widget-content widget-content-area">

                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-10 col-md-10">
                        <div class="title-table"> Coverage </div>
                        <div> Carrier Coverage </div>
                    </div>
                    <div class="col-2 col-md-2 text-right">
                        <button *ngIf="carrier.active" type="button" class="btn btn-main-action"
                                data-toggle="modal" (click)="openModalCoverage(null)">
                            Add
                        </button>
                    </div>
                </div>

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">State</th>
                            <th scope="col">Zip Code</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let carrierCoverage of carrierCoverageList">
                            <td>
                                <div>{{carrierCoverage.type}}</div>
                            </td>
                            <td>
                                <div>{{carrierCoverage.state}}</div>
                                <div>{{carrierCoverage.state_code}}</div>
                            </td>
                            <td>
                                <div><strong>Zip Code:</strong> {{carrierCoverage.zip_code}}</div>
                                <div><strong>Radius:</strong> {{carrierCoverage.radius | number}}mi</div>
                            </td>
                            <td>
                                <button (click)="openModalCoverage(carrierCoverage)" class="btn btn-success button-space">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button (click)="deleteCarrierCoverage(carrierCoverage.id)" class="btn btn-danger button-space">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <!-- ---- Tabla de Jobs ---- end -->

        <!-- ---- Tabla de Contacts ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'contacts'">
            <div class="row section widget-content widget-content-area">

                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-10 col-md-10">
                        <div class="title-table"> Contacts </div>
                        <div> Carrier´s contact list</div>
                    </div>

                    <div class="col-2 col-md-2 text-right">
                        <button *ngIf="carrier.active" type="button" class="btn btn-main-action"
                                data-toggle="modal" (click)="openAddModalContact(null)">
                            Add
                        </button>
                    </div>
                </div>

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">Main</th>
                            <th scope="col">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let contact of carriersContacts">
                            <td>
                                {{contact.name | titlecase}}
                            </td>
                            <td>
                                {{contact.phone}}
                            </td>
                            <td>
                                {{contact.email}}
                            </td>
                            <td class="text-center">
                                <input type="checkbox" name="chek-{{contact.id}}" [disabled]="contact.main"
                                    [(ngModel)]="contact.main" (click)="editContact(contact)">
                            </td>
                            <td>
                                <button (click)="openAddModalContact(contact)" class="btn btn-success button-space">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button>
                                <button (click)="deleteCarrierContact(contact.id)" class="btn btn-danger button-space">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <!-- ---- Tabla de Contacts ---- end -->

        <!-- ---- Tabla de Documents ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'documents'">
            <div class="row section widget-content widget-content-area">

                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-10 col-md-10">
                        <div class="title-table"> Documents </div>
                        <div> Documents of the carrier </div>
                    </div>

                    <div class="col-2 col-md-2 text-right">
                        <button *ngIf="carrier.active" type="button" class="btn btn-main-action"
                                data-toggle="modal" (click)="openAddModalDocument()">
                            Add
                        </button>
                    </div>
                </div>

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Description</th>
                            <th scope="col">Estimate Sent By</th>
                            <th scope="col">Date Signed</th>
                            <th scope="col">Signature</th>
                            <th scope="col">Company</th>
                            <th scope="col">Created</th>
                            <th scope="col">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let document of carriersDocuments">
                            <td>
                                {{document.description}}
                            </td>
                            <td>
                                <div *ngIf="document.employee">
                                    {{document.employee.name | titlecase}}
                                </div>
                                <div *ngIf="!document.employee">
                                    Not Name Signature
                                </div>
                            </td>
                            <td>
                                <div *ngIf="document.document.esignatures.main">
                                    {{document.document.esignatures.main.esignature_file.created | date}}
                                </div>
                                <div *ngIf="!document.document.esignatures.main">
                                    Not Date Signature
                                </div>

                            </td>
                            <td>
                                <div class="text-center" *ngIf="document.document.esignatures.main">
                                    <img class="carrier-documents-signature" appCdnLink="/e-signatures/{{document.document.esignatures.main.esignature_file.display_name}}">
                                </div>
                                <div *ngIf="!document.document.esignatures.main">
                                    Not Signature
                                </div>
                            </td>
                            <td>
                                {{document.company_document_name}}
                            </td>
                            <td>
                                {{document.created | dateTime}}
                            </td>                            
                            <td>                                
                                <button class="btn btn-info" *ngIf="document.document.esignatures.main"
                                    (click)="openSignatureModal(document)">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <a *ngIf="document.document.file" target="_blank"
                                    (click)="helperService.downloadReport('DOCUMENTS_CARRIERS', null, document.document.file.display_name)">
                                    <button class="btn btn-success">
                                        <i class="fa-solid fa-file-arrow-down"></i>
                                    </button>
                                </a>
                                <button class="btn btn-danger" (click)="deleteCarrierDocument(document.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <!-- ---- Tabla de Documents ---- end -->

        <!-- ---- Tabla de Files ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'files'">
            <div class="row section widget-content widget-content-area">

                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-10 col-md-10">
                        <div class="title-table"> Files </div>
                        <div> Files of the carrier </div>
                    </div>

                    <div class="col-2 col-md-2 text-right">
                        <button *ngIf="carrier.active" type="button" class="btn btn-main-action"
                            data-toggle="modal" (click)="openAddModalFile()">
                            Add
                        </button>
                    </div>
                </div>

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Description</th>
                            <th scope="col">File</th>
                            <th scope="col">Created</th>
                            <th scope="col">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let files of carriersFiles">
                            <td>
                                {{files.description}}
                            </td>
                            <td>
                                <div class="text-center" *ngIf="files.file">                                   
                                    <ng-container *ngIf="files.file.mime_type == 'application/pdf'">
                                        <a class="carrier-files-icon-pdf" [appCdnLink]="'/carrier-files/' + files.file.display_name" target="_blank" rel="noopener noreferrer">
                                            <i class="fa-solid fa-file-pdf"></i>
                                        </a>                                       
                                    </ng-container>
                                    <ng-container *ngIf="files.file.mime_type != 'application/pdf'">
                                        <img class="carrier-files-file" appCdnLink="/carrier-files/{{files.file.display_name}}" alt="">
                                    </ng-container>                                    
                                </div>
                            </td>
                            <td>
                                {{files.created | dateTime}}
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteCarrierFile(files.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <!-- ---- Tabla de Files ---- end -->

        <!-- ---- Tabla de Drivers ---- start -->
        <div class="skills layout-spacing" *ngIf="selectTap == 'drivers'">
            <div class="row section widget-content widget-content-area">
                <div class="row col-12 col-md-12 mb-3">
                    <div class="col-10 col-md-10">
                        <div class="title-table"> Drivers </div>
                        <div> Drivers of the carrier </div>
                    </div>
                    <div class="col-2 col-md-2 text-right">
                        <button *ngIf="carrier.active" type="button" class="btn btn-main-action"
                                data-toggle="modal" (click)="openAddModalDriver(null)">
                            Add
                        </button>
                    </div>
                </div>

                <table class="table">
                    <thead class="table-head">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Email</th>
                            <th scope="col">License</th>
                            <th scope="col">Created</th>
                            <th scope="col">Account</th>
                            <th scope="col">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let driver of carriersDrivers">
                            <td>
                                {{driver.name | titlecase}}
                            </td>
                            <td>
                                {{driver.phone}}
                            </td>
                            <td>
                                {{driver.email}}
                            </td>
                            <td>
                                <div class="text-center" *ngIf="driver.license">
                                    <img class="carrier-drivers-license" appCdnLink="/carrier-files/{{driver.license.display_name}}">
                                </div>
                            </td>
                            <td>
                                {{ driver.created | date}}
                            </td>
                            <td>
                                <ng-container *ngIf="driver.account_id">
                                    {{ driver.account_id}}
                                </ng-container>
                            </td>
                            <td>
                                <!-- <button (click)="openAddModalDriver(driver)" class="btn btn-success button-space">
                                    <i class="fa-solid fa-pen-to-square"></i>
                                </button> -->
                                <button (click)="deleteCarrierDriver(driver.id)" class="btn btn-danger button-space">
                                    <i class="fa-solid fa-trash-can"></i>
                                </button>
                              
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <!-- ---- Tabla de Drivers ---- end -->

        <!-- ---- Tabla de Setting BOL ---- start -->
        <div class="skills layout-spacing section-Terms-bol" *ngIf="selectTap == 'setting-bol'">
            <div class="row section widget-content widget-content-area">

                <div class="d-flex col-12 col-md-12 mb-3 p-0">

                    <div class="col-4 col-md-4">
                        <div class="title-table"> Terms Bill of Lading </div>
                        <div> Setting Terms of the Bill of Lading </div>
                    </div>

                    <div class="col-4 col-md-4 btn-check-all">
                        <div class="checkbox check-all-sections mr-5">
                            <input type="checkbox"  [(ngModel)]="carrierSettings.use_carrier_bol_terms">
                            <label for="click" class="text"></label>
                        </div>
                    </div> 
                    
                    <div class="row col-4 col-md-4 btn-actions">                                       
                        <button class="btn btn-info" (click)="showPreBol()">
                            <i class="fa-solid fa-file-contract pr-2"></i>
                            <span class="">Show Pre BOL</span> 
                        </button>
                        <button class="btn btn-save-bol" (click)="saveCarrierSettings()">
                            Save Changes
                        </button>   
                    </div>       

                </div>

                <div class="row col-12 col-md-12 btn-toggle-terms">

                    <div class="title-toggle-terms"> Show / Hide Terms</div> 

                    <div class="">
                        <div class="checkbox check-section-a">
                            <input type="checkbox"  [(ngModel)]="carrierSettings.use_payment_terms" >
                            <label for="click" class="text"></label>
                        </div>
                    </div>

                    <div class="">
                        <div class="checkbox check-section-c">
                            <input type="checkbox"  [(ngModel)]="carrierSettings.use_optional_accesorial_services" >
                            <label for="click" class="text"></label>
                        </div>
                    </div>                  
                </div>

                <div class="container-terms-bol col-12 col-md-12 mb-3">
                    <div class="col-6 col-md-6">
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms && carrierSettings.use_payment_terms}">
                                FORM / TERMS OF PAYMENT
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[0].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms || !carrierSettings.use_payment_terms"></app-ck-editor>
                            </div>
                        </div>                            
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                                TOTAL ESTIMATED CHARGES AND MINIMUM CHARGES
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[1].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                            </div>
                        </div> 
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                                DISCLOSURES AND AGREED TERMS FOR DELIVERY
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[2].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                            </div>
                        </div> 
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                                NOTICE OF AGREED PICK UP AND DELIVERY PERIODS
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[3].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                            </div>
                        </div> 
                    </div>
                    <div class="col-6 col-md-6">
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                                SIGN AT LEAST 3 DAYS BEFORE PICKUP DATE
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[4].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                            </div>
                        </div>                            
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                                STORAGE CHARGES
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[5].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                            </div>
                        </div> 
                        <div class="block-terms-bol">
                            <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                                DELIVERY ACKNOWLEDGEMENT
                            </div>
                            <div>
                                <app-ck-editor [(ngModel)]="carrierSettings.bol_terms_metas[6].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                            </div>
                        </div>  
                    </div>
                </div>

                <div class="col-12 col-md-12 mb-3">
                    <div class="block-terms-conditions-bol" >
                        <div class="title-terms-bol" [ngClass]="{'enabled': carrierSettings.use_carrier_bol_terms}">
                            TERMS AND CONDITIONS
                        </div>
                        <div>
                            <app-ck-editor ckEditorTheme="DOCUMENT" [(ngModel)]="carrierSettings.bol_terms_metas[7].value" ngDefaultControl [disabled]="!carrierSettings.use_carrier_bol_terms"></app-ck-editor>
                        </div>
                    </div> 
                </div>

            </div>


        </div>
        <!-- ---- Tabla de Setting BOL ---- end -->

    </div>


</div>



<!-- Inicio Modal add -->
<div class="modal fade" id="addModal" #addModal tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Auto Transport
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Model</label>
                                    <div class="list-title">
                                        <input id="autotransport_model" type="text" placeholder="Model"
                                            class="form-control" name="autotransport_model">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_price">Price</label>
                                    <div class="list-title">
                                        <input id="autotransport_price" type="number" step="0.01" placeholder="Price"
                                            class="form-control" name="autotransport_price">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_deposit">Deposit</label>
                                    <div class="list-title">
                                        <input id="autotransport_deposit" type="number" step="0.01"
                                            placeholder="Deposit" class="form-control" name="autotransport_deposit">
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">Brand</label>
                                    <div class="list-title">
                                        <input id="autotransport_brand" type="text" placeholder="Brand"
                                            class="form-control" name="autotransport_brand">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_year">Year</label>
                                    <div class="list-title">
                                        <input id="autotransport_year" type="text" placeholder="Year"
                                            class="form-control" name="autotransport_year">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="autotransport_year">VIN</label>
                                    <div class="list-title">
                                        <input id="autotransport_vin" type="text" placeholder="VIN" class="form-control"
                                            name="autotransport_vin">
                                    </div>
                                </div>
                                <div class="form-group mt-5">
                                    <div class="list-title">
                                        <label class="new-control new-checkbox checkbox-warning" style="width: 100px;">
                                            <input type="checkbox" class="new-control-input">
                                            <span class="new-control-indicator"></span>
                                            Can Run
                                        </label>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label for="autotransport_total_price">Total price</label>
                                    <div class="list-title">
                                        <input id="autotransport_total_price" [(ngModel)]="autoTransport.total_price" type="number" readonly placeholder="Total price" class="form-control" name="autotransport_total_price">
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button data-dismiss="modal" type="button" class="btn btn-primary">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<!-- Fin Modal de add -->

<!-- Inicio Modal edit-add carrier coverage -->
<div class="modal fade" id="modalCarrierCoverage" #modalCarrierCoverage tabindex="-1" role="dialog" aria-labelledby="modalCarrierCoverage"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
        <form #entityForm="ngForm" ngNativeValidate (ngSubmit)="saveCarrierCoverage()">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    {{ actionCarrierCoverage }} Carrier Coverage
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Type</label>
                                    <div class="list-title">
                                        <select name="month" [(ngModel)]="newCarrierCoverage.type" class="form-control" required>
                                            <option value="BOTH">Both</option>
                                            <option value="DELIVERY">Delivery</option>
                                            <option value="PICKUP">Pickup</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_price">State</label>
                                    <div class="list-title">                                            
                                        <select name="state" #selectStates required (change)="getDataTextFromState()"
                                                [(ngModel)]="newCarrierCoverage.state_code" id="state" class="form-control">
                                            <option value="AK">Alaska</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="IA">Iowa</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MD">Maryland</option>
                                            <option value="ME">Maine</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MT">Montana</option>
                                            <option value="NC">North carolina</option>
                                            <option value="ND">North dakota</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NH">New hampshire</option>
                                            <option value="NJ">New jersey</option>
                                            <option value="NM">New mexico</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NY">New york</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode island</option>
                                            <option value="SC">South carolina</option>
                                            <option value="SD">South dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VA">Virginia</option>
                                            <option value="VT">Vermont</option>
                                            <option value="WA">Washington</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WV">West virginia</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mt-4">
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="useZipCode" name="use_zip_code" [(ngModel)]="useZipCode">                                            
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="useZipCode" class="checkbox-label" [ngClass]="{'filter-disabled': !useZipCode}">
                                            Use Zip Code And Radius
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="useZipCode" class="col-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">Zip Code</label>
                                    <div class="list-title">
                                        <input class="form-control" name="zip_code" [(ngModel)]="newCarrierCoverage.zip_code"
                                                onlyNumbers maxlength="6" type="text" placeholder="Zip Code">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="useZipCode" class="col-6">
                                <div class="form-group" *ngIf="useZipCode">
                                    <label for="autotransport_year">Radius</label>
                                    <div class="list-title">
                                        <input class="form-control" name="radius" type="text" placeholder="Radius"
                                             onlyNumbers pattern="^\d*(\.\d{0,2})?$" [(ngModel)]="newCarrierCoverage.radius">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    Cancel
                </button>
                <button type="submit" class="btn btn-save">
                    Save
                </button>
            </div>
        </form>
        </div>
    </div>
</div>
<!-- Fin Modal edit carrier coverage-->

<!-- Inicio Modal Contacts -->
<div class="modal fade" id="addModal" #addModalContact tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    <ng-container *ngIf="!carrierContact.id">
                        Add
                    </ng-container>
                    <ng-container *ngIf="carrierContact.id">
                        Edit
                    </ng-container>
                     Carrier Contacts
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Name</label>
                                    <div class="list-title">
                                        <input id="autotransport_model" type="text" placeholder="Name"
                                            class="form-control" name="name" [(ngModel)]="carrierContact.name">
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="autotransport_price">Phone</label>
                                    <div class="list-title">
                                        <input id="autotransport_price" type="text" mask="(000) 000-0000"
                                            placeholder="(000) 000-0000" class="form-control" name="phone"
                                            [(ngModel)]="carrierContact.phone">
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_brand">Email</label>
                                    <div class="list-title">
                                        <input id="autotransport_brand" type="text" placeholder="Email"
                                            class="form-control" name="email" [(ngModel)]="carrierContact.email">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    Cancel
                </button>
                <button (click)="saveCarrierContact()" type="button" class="btn btn-save">
                    Save
                </button>
            </div>

        </div>
    </div>
</div>
<!-- Fin Modal Contacts -->

<!-- Inicio Modal Document -->
<div class="modal fade" id="addModal" #addModalDocument tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add Carrier Document
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Description</label>
                                    <div class="list-title">
                                        <input type="text" placeholder="Description" class="form-control"
                                            name="Description" [(ngModel)]="carrierDocument.description">
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Document Type</label>
                                    <div class="list-title">
                                        <select name="month" [(ngModel)]="carrierDocumentType" class="form-control">
                                            <option value="CARRIER_CONTRACT"> Broker Carrier Agreement </option>
                                            <option value="CARRIER_INTERLINE"> Carrier Interline </option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Select Company</label>
                                    <div class="list-title">
                                        <select name="companies" [(ngModel)]="carrierDocument.company_id"
                                            class="form-control">
                                            <option *ngFor="let company of companies" value="{{company.id}}">
                                                {{company.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button (click)="saveCarrierDocument()" type="button" class="btn btn-warning">
                    Save
                </button>
            </div>

        </div>
    </div>
</div>
<!-- Fin Modal Document -->

<!-- Inicio Modal Driver -->
<div class="modal fade" id="addModal" #addModalDriver tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    {{carrierAction}} Carrier Driver
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row" style="margin-bottom: 25px;">
                            <div class="col-9">
                                <div class="form-group mb-1">
                                    <label class="control-label">
                                        Email - <strong> Account Assignment </strong>
                                    </label>
                                    <div class="input-group mb-1">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                            </span>
                                        </div>
                                        <input type="email" [(ngModel)]="accountEmail" name="account_email" class="form-control" #employeeEmail="ngModel" required email>
                                    </div>
                                   
                                </div>
                            </div>
                            <div class="col-3 text-center">
                                <br>
                                <button (click)="searchAccout()" class="btn btn-danger btn-verify-email" type="button" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    &nbsp; verify email
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                
                                <div class="form-group">
                                    <label for="autotransport_model">Name</label>
                                    <div class="list-title">
                                        <input type="text" placeholder="Name" class="form-control" name="description"
                                            [(ngModel)]="carrierDriver.name">
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="autotransport_model">Phone</label>
                                    <div class="list-title">
                                        <input type="text" onlyNumbers maxlength="10" placeholder="Phone" class="form-control" name="phone"
                                            [(ngModel)]="carrierDriver.phone">
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="autotransport_model">Email</label>
                                    <div class="list-title">
                                        <input type="email" placeholder="Email" class="form-control" name="Email"
                                            [(ngModel)]="carrierDriver.email" [disabled]="carrierDriver.account_id">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">License</label>
                                    <input type="file" id="input-file-max-fs" [appDropify]
                                        (onChange)="onChangeDriverLicense($event)" class="dropify"
                                        data-max-file-size="2M" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button (click)="saveCarrierDriver()" type="button" class="btn btn-save">
                    Save
                </button>
            </div>

        </div>
    </div>
</div>
<!-- Fin Modal Driver -->

<!-- Inicio Modal File -->
<div class="modal fade" id="addModal" #addModalFile tabindex="-1" role="dialog" aria-labelledby="addModal"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add Carrier File
                </h5>
            </div>
            <div class="modal-body">
                <div class="compose-box">
                    <div class="compose-content">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">Description</label>
                                    <div class="list-title">
                                        <input type="text" placeholder="Description" class="form-control"
                                            name="description" [(ngModel)]="carrierFile.description">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="autotransport_model">File(s)</label>
                                    <input type="file" id="input-file-max-fs" [appDropify]
                                        (onChange)="onChangeDriverFile($event)" class="dropify"
                                        data-max-file-size="4M" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button (click)="saveCarrierFile()" type="button" class="btn btn-save">
                    Save
                </button>
            </div>

        </div>
    </div>
</div>
<!-- Fin Modal File -->

<!-- Modal Detail Signature-->
<div class="modal fade modal-detail-signature" #signatureModal tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Detail Signature
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">

                            <div class="form-group">
                                <div class="user-info-list">

                                    <table class="table table-bordered mb-4">
                                        <thead>
                                            <tr>
                                                <th>Signature</th>
                                                <th>Ip</th>
                                                <th>Internet Browser</th>
                                                <th>Date Signed</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span *ngIf="electronicSignature && (electronicSignature.esignature_file.display_name != null)">
                                                        <img class="modal-carrier-documents-detail-signature" appCdnLink="/e-signatures/{{electronicSignature.esignature_file.display_name}}">
                                                    </span>

                                                    <span *ngIf="!electronicSignature">
                                                        <span class="badge outline-badge-danger">
                                                            None
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    {{electronicSignature.ip_address}}
                                                </td>
                                                <td>
                                                    {{electronicSignature.internet_browser}}
                                                </td>
                                                <td>
                                                    {{electronicSignature.created | dateTime}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-dismiss="modal" type="button" class="btn btn-info">
                        Close
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Modal disable carrier-->
<div class="modal fade" #modalDisableCarrier tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-signature">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="newConceptTitle">
                        Please Write the Reason for Disabling this Carrier.
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="compose-box">
                        <div class="compose-content" id="addListsModalTitle">

                            <div class="form-group">
                                <div class="user-info-list">
                                    <div class="form-group col-md-12">
                                        <label for="company_name">Comments</label>
                                        <div class="input-group mb-1">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text wb" id="basic-addon5_x">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                                </span>
                                            </div>        
                                            <textarea class="form-control" name="carrierModal.comment" id="carrierModal.comment"
                                            [(ngModel)]="carrierModal.comment" placeholder="Enter here the carrier comment"></textarea>        
                                        </div>        
                                        <small id="emailHelp1" class="form-text text-muted mb-4">Carrier Comment.</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer px-4 pb-3">
                    <div class="row">
                        <div class="col-md-6">
                            <button data-dismiss="modal" type="button" class="btn mt-2 mb-2">
                                Close
                            </button>
                        </div>
                        <div class="col-md-6">
                            <button data-dismiss="modal" (click)="continueChangeStatus()" type="button" class="btn btn-danger mt-2 mb-2 btn-block">
                                Disable
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>