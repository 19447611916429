
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paginator } from 'src/app/entities/helpers/paginator';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class LeadsReportService {

    constructor(
        private authService: AuthService,
        private http: HttpClient
    ) { }

    getLeadReport(report_data: any, paginator: Paginator): Promise<Paginator> {
        const params: any = {
            "full-view": "1",
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start": paginator.paginator.start,
            "end": paginator.paginator.end,
            "order_by": "created",
            "order_type": "DESC",
            "report_data": JSON.stringify(report_data)
        };
        return this.http.get<Paginator>(environment.api.workspace + '/export-leads', { params: params }).toPromise();
    }
    
    getBadLeadReport(report_data: any, paginator: Paginator): Promise<Paginator> {
        const params: any = {
            "full-view": "1",
            "use_paginator": 1,
            "page": paginator.paginator.page,
            "per_page": paginator.paginator.per_page,
            "start": paginator.paginator.start,
            "end": paginator.paginator.end,
            "order_by": "created",
            "order_type": "DESC",
            "report_data": JSON.stringify(report_data)
        };
        return this.http.get<Paginator>(environment.api.workspace + '/export-bad-leads', { params: params }).toPromise();
    }
}
