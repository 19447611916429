<!--  BEGIN NAVBAR  -->
<app-header class="header-container fixed-top" [panelType]="panelType" ></app-header>
<!--  END NAVBAR  -->

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container" [ngClass]="{'company-container' : panelType == 'COMPANY'}">

    <div class="overlay"></div>
    <div class="cs-overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN SIDEBAR  -->
    <app-sidebar id="app-sidebar" class="sidebar-wrapper sidebar-theme" [menuType]="panelType" ></app-sidebar>
    <!--  END SIDEBAR  -->

    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">

        <div class="layout-px-spacing">
            <!-- CONTENT AREA -->

            <router-outlet></router-outlet>

            <!-- CONTENT AREA -->
        </div>

        <app-footer class="footer-wrapper"></app-footer>
    </div>
    <!--  END CONTENT AREA  -->

</div>
<!-- END MAIN CONTAINER -->