import { Component, Input, OnInit } from '@angular/core';
import { DashboardService as DashboardWorkspaceService } from 'src/app/services/workspaces/dashboard.service';
import { DashboardService as DashboardComopanyService } from 'src/app/services/companies/dashboard.service';

@Component({
  selector: 'app-total-sales-last7-days',
  templateUrl: './total-sales-last7-days.component.html',
  styleUrls: ['./total-sales-last7-days.component.scss']
})
export class TotalSalesLast7DaysComponent implements OnInit {
    jobsTotalSalesInTheLast7Day: any;
    @Input()
    companyId: boolean = false;
    constructor(
        private dashboardWorkspaceService: DashboardWorkspaceService,
        private dashboardComopanyService: DashboardComopanyService,

    ) {
    this.jobsTotalSalesInTheLast7Day ={
        "total_jobs": 0,
        "total_amount": 0
    };
   }

  
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    if (this.companyId) {
        this.loadTotalSalesInTheLast7DayCompany();
    } else {
        this.loadTotalSalesInTheLast7DayWorkspace();
    }
  }
  
  loadTotalSalesInTheLast7DayWorkspace(){
        
    this.dashboardWorkspaceService
        .getTotalSalesInTheLast7Day()
        .then((response) => {
            this.jobsTotalSalesInTheLast7Day = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}
  
loadTotalSalesInTheLast7DayCompany(){
        
    this.dashboardComopanyService
        .getTotalSalesInTheLast7Day()
        .then((response) => {
            this.jobsTotalSalesInTheLast7Day = response;
            // setTimeout(() => {
            //     this.renderReportBookedMovingByCompany();
            // }, 300);

        })
        .catch((error) => {
            console.error('Error', error);
        })
        .finally(() => {
        });
}

}
