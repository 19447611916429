import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterReplace'
})
export class FilterReplacePipe implements PipeTransform {

  /**
   * Filtro para convertir una cadena o un arreglo de cadenas en minusculas y sin guion
   *
   * @param value Valor a converti
   */
  transform(value: string | Array<string>): string | Array<string> {

    // Se verifica el tipo de variable, si es string se procede a convertir la cadena 
    // en minuscula a excepcion del primer caracter.
    // Ademas, se eliminan caracteres especiales
    if (typeof value === 'string') {

      // Se obtiene el valor a cambiar
      let result = value;

      // Se reemplazan los caracteres especiales
      result = result.replace(/_|#|-|@|<>/g, ' ');

      // Se convierte la cadena en minuscula a excepcion del primer caracter
      result = this.upperCaseFirstLetter(this.lowerCaseAllWordsExceptFirstLetters(result));

      // Se reemplaza en la variable para devolver el resultado
      value = result;
    } else if (Array.isArray(value)) { // Se verifica si es array para convertir todos
      // los caracteres que contenga el arreglo

      // Se inicializa la variable para obtener los resultados finales
      const result = [];

      // Se recorre el arreglo de cadenas
      for (let iterator of value) {
        // Se reemplazan los caracteres especiales
        iterator = iterator.replace(/_|#|-|@|<>/g, ' ');

        // Se convierte la cadena en minuscula a excepcion del primer caracter
        result.push(this.upperCaseFirstLetter(this.lowerCaseAllWordsExceptFirstLetters(iterator)));
      }
      // Se reemplaza en la variable para devolver el resultado
      value = result;
    }
    return value;
  }

  /**
   * Convierte la primera letra en mayuscula
   *
   * @param value Valor a convertir
   */
  private upperCaseFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }


  /**
   * Convierte la cadena en minuscula a excepcion del primer caracter
   *
   * @param value Valor a convertir
   */
  private lowerCaseAllWordsExceptFirstLetters(value) {
    return value.replace(/\S*/g, (word) => {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  }

}
