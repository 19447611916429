<div class="layout-top-spacing">

    <div class="row">

        <div class="col-xl-9 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                <span *ngIf="localRoomCategory.id == null">
                    New
                </span>
                <span *ngIf="localRoomCategory.id != null">
                    Update
                </span> Local Room Categories
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard"><i class="fas fa-home"></i> Dashboard</a>
                    </li>
                    <li class="breadcrumb-item"><a appWorkspaceRouterLink="/local-room-categories">Local Room
                            Categories</a></li>
                    <li *ngIf="localRoomCategory.id == null" class="breadcrumb-item active" aria-current="page">New</li>
                    <li *ngIf="localRoomCategory.id != null" class="breadcrumb-item active" aria-current="page">Update
                    </li>
                </ol>
            </nav>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
            </div>


        </div>
    </div>
    <!-- header -->

    <div class="row">
        <div class="col-lg-12">
            <div class="statbox widget box box-shadow">
                <div class="widget-content widget-content-area">

                    <form class="form" #entityForm="ngForm" (ngSubmit)="save()" ngNativeValidate autocomplete="off">
                        <div class="row local-room-checkbox">

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label class="control-label mb-11">Name</label>
                                    <input type="text" [(ngModel)]="localRoomCategory.name" name="wd_name" class="form-control">
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter a value.</small>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <div class="d-flex-between">
                                        <label class="control-label">Minimal</label>
                                        <div class="toggle-switch">
                                            <label for="minimalTimeType" class="checkbox-label-left" [ngClass]="{'filter-disabled': minimalTimeType}">
                                                Minutes
                                            </label>
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="minimalTimeType" name="minimalTimeType" [(ngModel)]="minimalTimeType">
                                                <span class="slider round">
                                                    <span class="icon-left">H</span>
                                                    <span class="icon-right">M</span>
                                                </span>
                                            </label>
                                            <label for="minimalTimeType" class="checkbox-label" [ngClass]="{'filter-disabled': !minimalTimeType}">
                                                Hours
                                            </label>
                                        </div>
                                    </div>
                                    <input type="number" [(ngModel)]="localRoomCategory.minimal" name="wd_last_name" class="form-control" step="0.05">
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter a value.</small>                                    
                                </div>
                            </div>

                        </div>

                        <div class="row local-room-checkbox">

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <div class="d-flex-between">
                                        <label class="control-label">Moderate</label>
                                        <div class="toggle-switch">
                                            <label for="moderateTimeType" class="checkbox-label-left" [ngClass]="{'filter-disabled': moderateTimeType}">
                                                Minutes
                                            </label>              
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="moderateTimeType" name="moderateTimeType" [(ngModel)]="moderateTimeType">
                                                <span class="slider round">
                                                    <span class="icon-left">H</span>
                                                    <span class="icon-right">M</span>
                                                </span>
                                            </label>
                                            <label for="moderateTimeType" class="checkbox-label" [ngClass]="{'filter-disabled': !moderateTimeType}">
                                                Hours
                                            </label>
                                        </div>
                                    </div>
                                    <input type="number" [(ngModel)]="localRoomCategory.moderate" name="wd_email" class="form-control" step="0.05">
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter a value.</small>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <div class="d-flex-between">
                                        <label class="control-label">Full</label>
                                        <div class="toggle-switch">
                                            <label for="fullTimeType" class="checkbox-label-left" [ngClass]="{'filter-disabled': fullTimeType}">
                                                Minutes
                                            </label>                        
                                            <label class="switch s-icons s-outline s-outline-secondary">
                                                <input type="checkbox" id="fullTimeType" name="fullTimeType" [(ngModel)]="fullTimeType">
                                                <span class="slider round">
                                                    <span class="icon-left">H</span>
                                                    <span class="icon-right">M</span>
                                                </span>
                                            </label>
                                            <label for="fullTimeType" class="checkbox-label" [ngClass]="{'filter-disabled': !fullTimeType}">
                                                Hours
                                            </label>
                                        </div>
                                    </div>
                                    <input type="number" [(ngModel)]="localRoomCategory.full" name="wd_phone" class="form-control" step="0.05">
                                    <small id="emailHelp1" class="form-text text-muted mb-4">Enter a value.</small>
                                </div>
                            </div>                            

                        </div>

                        <div class="row">
                            <div class="col-lg-6" *ngIf="localRoomCategory.id == null">
                                <div class="form-group">
                                    <label class="control-label">Custom</label>
                                    <div class="toggle-switch">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="is_custom" name="is_custom" [(ngModel)]="localRoomCategory.is_custom"  (ngModelChange)="isCustom($event)">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                        <label for="is_custom" class="checkbox-label" *ngIf="!localRoomCategory.is_custom" [ngClass]="{'filter-disabled': !localRoomCategory.is_custom}">
                                            Disabled
                                        </label>
                                        <label for="is_custom" class="checkbox-label checkbox-label-enabled" *ngIf="localRoomCategory.is_custom" [ngClass]="{'filter-disabled': localRoomCategory.is_custom}">
                                            Enabled
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-center mt-5 mb-3">
                            <button class="btn btn-main-action" type="submit">
                                Save
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>