import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobLocalTariffLog } from 'src/app/entities/workspace/job-local-tariff-log';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobLocalTariffLogsService {


  constructor(private http: HttpClient) {}

  getDigitalJobLogsByEstimateId (estimate_id: string) {
    return this.http.get<Array<JobLocalTariffLog>>(environment.api.workspace + '/job-local-tariff-logs/' + estimate_id + '/DIGITAL_PICKUP').toPromise();
  }
  getJobCompletionJobLogsByEstimateId (estimate_id: string) {
    return this.http.get<Array<JobLocalTariffLog>>(environment.api.workspace + '/job-local-tariff-logs/' + estimate_id + '/JOB_COMPLETION').toPromise();
  }
  getJobCompletionJobTravelLogsByEstimateId (estimate_id: string) {
    return this.http.get<Array<JobLocalTariffLog>>(environment.api.workspace + '/job-local-travel-tariff-logs/' + estimate_id + '/JOB_COMPLETION').toPromise();
  }
  saveJobLocalTariffLog(estimate_id: string, localTariffLog: JobLocalTariffLog, labor_type: 'TRAVEL' | 'LABOR') {
    const tariffLog = {...localTariffLog, labor_type};
    return this.http.post(environment.api.workspace + '/job-local-tariff-logs/' + estimate_id, tariffLog).toPromise();
  }
  updateJobLocalTariffLog(localTariffLog: JobLocalTariffLog) {
    return this.http.put(environment.api.workspace + '/job-local-tariff-logs/' + localTariffLog.id, localTariffLog).toPromise();
  }
  deleteJobLocalTariffLog(localTariffLogId: string) {
    return this.http.delete(environment.api.workspace + '/job-local-tariff-logs/' + localTariffLogId).toPromise();
  }
  
}