export class IngenicoSetting {
    id: string;
    psp_id: string;
    sha_in: string;
    store_home_url: string;
    store_catalog_url: string;

    constructor() {
        this.id = null;
        this.psp_id = null;
        this.sha_in = null;
        this.store_home_url = null;
        this.store_catalog_url = null;
    }
}
