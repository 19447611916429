import { Directive, ElementRef, EventEmitter, Input } from '@angular/core';
import { ScheduleCalendar } from "src/app/entities/workspace/schedule-calendar";
declare var jQuery;
declare var moment;

@Directive({
  selector: '[appCalendar]'
})
export class CalendarDirective {

    @Input()
    appCalendar: Array<ScheduleCalendar>;
  constructor(
        private element: ElementRef,
    ) {

        setTimeout(() => {
            this.loadPlugin();
        }, 1800);
    }


    private loadPlugin() {

        /*
            let calendar = jQuery(this.element.nativeElement).css({
            display: 'none'
        */
        
        let calendar = jQuery(this.element.nativeElement).fullCalendar({
            header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek,agendaDay'
        },
        // dayClick: function(date, jsEvent, view, resourceObj) {
        //     jQuery("#calendar").fullCalendar('changeView', 'agendaDay', date);
        // },
        eventRender: (eventObj, $el) => {
            $el.popover({
                title: eventObj.title,
                content: eventObj.description,
                trigger: 'hover',
                html: true,
                placement: 'top',
                container: 'body'
            });
        },
        events: this.appCalendar,
        editable: false,
        eventLimit: true,
        eventMouseover: function(event, jsEvent, view) {
            jQuery(this).attr('id', event.id);

            jQuery('#' + event.id).popover({
                template: '<div class="popover popover-primary" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                title: event.title,
                content: event.description,
                placement: 'top',
            });

            jQuery('#'+event.id).popover('show');
        },
        eventMouseout: function(event, jsEvent, view) {
            jQuery('#'+event.id).popover('hide');
        },
       
        
         });
    }
}
