import { Paginator } from 'src/app/entities/helpers/paginator';
import { RecurringCustomer } from 'src/app/entities/workspace/recurring-customer';
import { RecurringCustomerView } from 'src/app/entities/workspace/recurring-customer-view';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RecurringCustomersService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(companyId: string, paginator: Paginator): Promise<Paginator> {

    const params: any = {
      use_paginator: 1,
      page: paginator.paginator.page,
      per_page: paginator.paginator.per_page,
      start: paginator.paginator.start,
      end: paginator.paginator.end,
      order_by: 'created',
      order_type: 'DESC',
      company_id: companyId
  };

    return this.http.get<Paginator>(environment.api.company + '/recurring-customers',
    { params }).toPromise();
  }

  add(recurringCustomer: RecurringCustomer) {
    return this.http.post(environment.api.customers + '/recurring-customers', recurringCustomer).toPromise();
  }

  filterByDates(companyId: string, paginator: Paginator): Promise<any> {
    const params: any = {
      use_paginator: 1,
      page: paginator.paginator.page,
      per_page: paginator.paginator.per_page,
      start: paginator.paginator.start,
      end: paginator.paginator.end,
      order_by: 'created',
      order_type: 'DESC',
      company_id: companyId
  };
    return this.http.get<Array<RecurringCustomerView>>(environment.api.company + '/recurring-customers/dates', {params}).toPromise();
  }
}
