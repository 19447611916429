
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Period } from 'src/app/entities/workspace/period';
import { PeriodsService } from 'src/app/services/workspaces/periods.service';
import { HelperService } from 'src/app/services/helper.service';
import { TariffVersionService } from 'src/app/services/tariff-version.service';

declare const jQuery;

@Component({
    selector: 'app-periods-form',
    templateUrl: './periods-form.component.html',
    styleUrls: ['./periods-form.component.scss']
})
export class PeriodsFormComponent implements OnInit {

    /*
    Constantes que contiene el tipo de mensaje a mostrar
    */
    constantsMessages = ConstantsMessages;
    dataForm: Period;
    public fromTotalDays: number;
    public toTotalDays: number;

    constructor(
        private periodsService: PeriodsService,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        public tariffVersionFactory: TariffVersionService
    ) {
        this.dataForm = new Period();
        this.fromTotalDays = 1;
        this.toTotalDays = 1;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        // verficamos si se esta editando
        this.currentRoute.params.subscribe(params => {
            if (typeof params.id !== 'undefined') {
                this.load(params.id);
            }
        });

    }

    private load(id: string) {
        this.helperService.showLoadingMxpro360();
        this.periodsService
            .getById(id)
            .then((response) => {
                this.dataForm = response;
                this.changeSelectDay('from');
                this.changeSelectDay('to');
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    save() {

        this.helperService.showLoadingMxpro360();
        this.dataForm.tariff_version_id = this.tariffVersionFactory.getTariffVersionId();
        this.periodsService
            .save(this.dataForm)
            .then(response => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.helperService.goToWorkspaceRouterLink('/settings/periods');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    changeSelectDay(type: string) {
        let month: string;
        let totalDays: number;
        if (type === 'from') {
            month = this.dataForm.from.month;
        } else {
            month = this.dataForm.to.month;
        }

        switch (month) {
            case '1':
            case '3':
            case '5':
            case '7':
            case '8':
            case '10':
            case '12':
                totalDays = 31;
                break;
            case '4':
            case '6':
            case '9':
            case '11':
                totalDays = 30;
                break;
            case '2':
                totalDays = 28;
                break
        }

        if (type === 'from') {
            this.fromTotalDays = totalDays;
        } else {
            this.toTotalDays = totalDays;
        }

    }

}
