import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Expense } from 'src/app/entities/workspace/expense';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(private http: HttpClient) {

  }

  getAllExpenses(id): Promise<Array<any>> {
      return this.http.get<Array<any>>(environment.api.company + '/estimates/' + id + '/expenses').toPromise();
  }


  save(id, dataForm: Expense): Promise<any> {
    if (dataForm.id === null) {
        return this.add(id, dataForm);
    }
    return this.edit(id, dataForm);
}

  add(id, dataForm: any) {
      return this.http.post(environment.api.company + '/estimates/' + id + '/expenses', dataForm).toPromise();
  }


  edit(id,dataForm: Expense) {
    return this.http.put(environment.api.company + '/estimates/' + id + '/expenses/' + dataForm.id, dataForm).toPromise();
  }

  remove(id: string, idExpense) {
      return this.http.delete(environment.api.company + '/estimates/' + id + '/expenses/' + idExpense).toPromise();
  }

}
