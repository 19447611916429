import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { infoEstimateLabelQrView } from 'src/app/entities/info-estimate-label-qr-view';
import { JobItemQrCode } from 'src/app/entities/job-item-qr-code';


declare const QRCode: any;
declare const jQuery: any;

@Component({
  selector: 'app-qr-labels',
  templateUrl: './qr-labels.component.html',
  styleUrls: ['./qr-labels.component.scss']
})
export class QRLabelsComponent implements OnInit {

  @Input()
  itemQr: JobItemQrCode;

  @Input()
  infoEstimateLabelQr: infoEstimateLabelQrView;

  @Input()
  selectionBehavior: boolean;

  @ViewChild('QRCODE')
  QRCodeElem: ElementRef;

  hostLoaded: boolean;

  instanceQR: any;

  constructor() {
    this.selectionBehavior = false;
    this.itemQr = null;
    this.infoEstimateLabelQr = null;
    this.hostLoaded = false;
    this.instanceQR = null;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.itemQr){
      if (changes.itemQr.currentValue !== null) {
        this.loadQRCODE();
      }
    }
  }


  ngAfterViewInit(): void {
    this.hostLoaded = true;
    this.loadQRCODE();
  }

  /**
   * Evalua si ya estan la depencia para cargar el QR, si este es el caso, llama el metodo que muestra el QR
   */
  loadQRCODE(){
    if(this.hostLoaded && this.itemQr !== null){
      this.loadQrCodeImage(this.itemQr.code);
    }
  }


  /**
   * pinta los codigos QR en el div expecificado
   *
   * @param code texto que lleleta el qr como info
   */
  private loadQrCodeImage(code: string) {

    const config = {
      text: code, // Content
      width: 85, // Widht
      height: 82, // Height
      colorDark: "#000000", // Dark color
      colorLight: "#ffffff", // Light color                        
      // quietZone: 15,
      logo: "/assets/img/logotipo_moverxpro360.png",
      correctLevel: QRCode.CorrectLevel.H // L, M, Q, H
    };

    this.instanceQR = new QRCode(this.QRCodeElem.nativeElement, config);
  }

}
