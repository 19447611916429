import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneIvr } from 'src/app/entities/workspace/phone-ivr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PhoneIvrsService {

  constructor(private http: HttpClient) {

  };

  getAll(): Promise<Array<PhoneIvr>> {
    return this.http.get<Array<PhoneIvr>>(environment.api.workspace + '/phone-ivrs').toPromise();
  }

  getAllCompany(): Promise<Array<PhoneIvr>> {
    return this.http.get<Array<PhoneIvr>>(environment.api.workspace + '/phone-ivrs',
    { params: { 'company-name': '1'} }).toPromise();
  }

  // getAllNums(): Promise<Array<PhoneNumber>> {
  //   return this.http.get<Array<PhoneNumber>>(environment.api.workspace + '/pbx-phone-lines-nums').toPromise();
  // };

  getById(id: string) {
    return this.http.get<PhoneIvr>(environment.api.workspace + '/phone-ivrs/' + id).toPromise();
  }

  save(dataForm: PhoneIvr): Promise<any> {
    if (dataForm.id === null) {
        return this.add(dataForm);
    }
    return this.edit(dataForm);
};

  add(dataForm: PhoneIvr) {
    return this.http.post(environment.api.workspace + '/phone-ivrs', dataForm).toPromise();
  };

  edit(dataForm:PhoneIvr){
    return this.http.put(environment.api.workspace + '/phone-ivrs/' + dataForm.id, dataForm).toPromise();

  };

  remove(id: string): Promise<Array<PhoneIvr>> {
    return this.http.delete<Array<PhoneIvr>>(environment.api.workspace + '/phone-ivrs/' + id).toPromise();
  };

};
