import { PaymentMethod } from './../../../entities/workspace/payment-method';
import { Component, OnInit } from '@angular/core';

import { PaymentsMethodsService } from '../../../services/companies/payments-methods.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-payments-methods',
  templateUrl: './payments-methods.component.html',
  styleUrls: ['./payments-methods.component.scss']
})
export class PaymentsMethodsComponent implements OnInit {

  /*
  Pagina actual en el html
  */
  public actualPage: number;

  /*
  Filtro de busqueda
  */
  public filterSearch: string;

  /*
  Arreglo de metodos de pagos
  */
  public paymentMethods: Array<PaymentMethod>;

  constructor(
    private paymentsMethodsService: PaymentsMethodsService,
    private helperService: HelperService
  ) {
    this.actualPage = 1;
    this.filterSearch = '';
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void{
    this.loadPaymentsMethods();
  }

  /**
   * Carga todos los payment methods por company
   */
  private loadPaymentsMethods(): void {
    this.helperService.showLoadingMxpro360();
    this.paymentsMethodsService
      .getAll()
      .then((response) => {
        this.paymentMethods = response;
      })
      .catch((error) => {
        console.error('Error: ', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public removePaymentMethod(idPaymentMethod: string): void {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to delete the record?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!'
    })
      .then((result) => {
        if (result.value) {
          this.helperService.showLoadingMxpro360();
          this.paymentsMethodsService
            .remove(idPaymentMethod)
            .then(() => {
              this.loadPaymentsMethods();
              swal(
                'Deleted!',
                'Record has been deleted.',
                'success'
              );
            })
            .catch((error) => {
              console.error('error', error);
            })
            .finally(() => {
              this.helperService.hideLoadingMxpro360();
            });
        }
      });
  }

}
