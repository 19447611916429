import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierDriver } from 'src/app/entities/workspace/carrier-driver';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CarriersDriversService {

    constructor(private http: HttpClient) { 
        
    }

    getAll(id): Promise<Array<CarrierDriver>> {
        return this.http.get<Array<CarrierDriver>>(environment.api.workspace + '/carriers/' + id + '/drivers').toPromise();
    }

    getById(id: string, subId: string) {
        return this.http.get<CarrierDriver>(environment.api.workspace + '/carriers/' + id + '/drivers/' + subId).toPromise();
    }

    save(dataForm: CarrierDriver): Promise<any> {
        if (dataForm.id === null) {
            return this.add(dataForm, dataForm.carrier_id);
        }
        return this.edit(dataForm, dataForm.carrier_id, dataForm.id);
    }

    add(dataForm: CarrierDriver, id: string) {
        return this.http.post(environment.api.workspace + '/carriers/' + id + '/drivers', dataForm).toPromise();
    }

    edit(dataForm: CarrierDriver, id: string, subId: string) {
        return this.http.put(environment.api.workspace + '/carriers/' + id + '/drivers/' + subId, dataForm).toPromise();
    }

    remove(id: string, subId: string) {
        return this.http.delete(environment.api.workspace + '/carriers/' + id + '/drivers/' + subId).toPromise();
    }

    searchAccouts(email: string) {
        return this.http.get(environment.api.workspace + '/employees/account/search', { params: { email } }).toPromise();
    }
}
