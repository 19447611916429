export class Advertiser {
    public id: string;
    public name: string;
    public email: string;
    public website: string;
    public lead_charge: number;
    public agreement_company_id: string;
    public lead_companies_id: Array<string>;
    public lead_forwarding_links_id: Array<string>;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.name = null;
        this.email = null;
        this.website = null;
        this.lead_charge = 0;
        this.agreement_company_id = null;
        this.lead_companies_id = [];
        this.lead_forwarding_links_id = [];
        this.created = null;
        this.updated = null;
    }
}
