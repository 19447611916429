import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarrierContact } from 'src/app/entities/workspace/carrier-contact';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarriersContactsCompanyService {

  constructor(private http: HttpClient) { }

  getContactsByCarrier(id): Promise<Array<CarrierContact>> {
    return this.http.get<Array<CarrierContact>>(environment.api.company + '/carrier/' + id + '/contacts').toPromise();
  }

  getFullContactsByCarrier(id): Promise<Array<CarrierContact>> {
    return this.http.get<Array<CarrierContact>>(environment.api.company + '/carrier/' + id + '/full-contacts').toPromise();
  }

  getContactsByWorkspaceCarrier(id, companyId): Promise<Array<CarrierContact>> {
    return this.http.get<Array<CarrierContact>>(environment.api.workspace + '/companies/' + companyId + '/carrier/'
      + id + '/contacts').toPromise();
  }

  sendEmailsToContacts(data) {
    return this.http.post(environment.api.company + '/send-email-contacts', data).toPromise();
  }

}
