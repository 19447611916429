import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';



declare const jQuery;

@Directive({
  selector: '[appCdnLink]'
})
export class CdnLinkDirective {

    @Input()
    appCdnLink: string

    constructor(private authService: AuthService, private element: ElementRef) {
        this.appCdnLink = null;
    }

    ngOnChanges(changes: SimpleChanges) {
        // verificamos si se recibio algo
        if (changes.appCdnLink.currentValue !== null && changes.appCdnLink.currentValue !== undefined) {

            // establecemos ese algo
            this.setLink(changes.appCdnLink.currentValue);
        } else {

            // eliminamos el que tenga la html
            this.removeLink();
        }
    }

    /**
     * Permite establecer el enlace en la etiqueta html
     * @param link 
     */
    private setLink(link: string) {

        if (jQuery(this.element.nativeElement).is('a')) {
            jQuery(this.element.nativeElement).attr('href', this.transform(link));
        }

        if (jQuery(this.element.nativeElement).is('img')) {
            jQuery(this.element.nativeElement).attr('src', this.transform(link));
        }

        if (jQuery(this.element.nativeElement).is('iframe')) {
            jQuery(this.element.nativeElement).attr('src', this.transform(link));
        }
    }

    /**
     * Permite retirar el link de la etiqueta html
     */
    private removeLink() {
        if (jQuery(this.element.nativeElement).is('a')) {
            jQuery(this.element.nativeElement).removeAttr('href');
        }

        if (jQuery(this.element.nativeElement).is('img')) {
            jQuery(this.element.nativeElement).removeAttr('src');
        }

        if (jQuery(this.element.nativeElement).is('iframe')) {
            jQuery(this.element.nativeElement).removeAttr('src');
        }
    }


    /**
     * Permite complementar el link a establecer en la etiqueta html
     * @param postLink 
     */
    private transform(postLink: string): string {

        if (postLink == null) {
            return '';
        }

        if (this.authService.workspaceSession == null) {
            return '';
        }

        // armamos el link y lo devolvemos
        return environment.az_api.cdn + '/' + this.authService.workspaceSession.id + postLink;
    }
}
