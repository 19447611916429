export class PayrollPayment {
    public id: string;
    public payroll_id: string;
    public employee_id: string;
    public base_pay: number;
    public total_hours: number;
    public hourly_pay_rate: number;
    public total_overtime: number;
    public overtime_rate: number;
    public total_commissions: number;
    public total_hourly: number;
    public total_draw: number;
    public total_bonuses: number;
    public total_deductions: number;
    public sub_total: number;
    public total: number;
    public status: string;
    public previous_total_draw: number;
    public previous_payroll_payment_id: string;
    public company_id: number;
    public vs_commission: boolean;

    constructor() {
        this.id = null;
        this.payroll_id = null;
        this.employee_id = null;
        this.base_pay = null;
        this.total_hours = null;
        this.hourly_pay_rate = null;
        this.total_overtime = null;
        this.overtime_rate = null;
        this.total_commissions = null;
        this.total_hourly = null;
        this.total_draw = null;
        this.total_bonuses = null;
        this.total_deductions = null;
        this.sub_total = null;
        this.total = null;
        this.status = 'PENDING_PAYMENT';
        this.previous_total_draw = null;
        this.previous_payroll_payment_id = null;
        this.company_id = null;
        this.vs_commission = true;
    }
}
