export class Credential {
    username: string;
    password: string;
    recaptchaToken: string;

    constructor() {
        this.username = null;
        this.password = null;
        this.recaptchaToken = null;
    }
}
