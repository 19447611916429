export class Paginator {
    data: any[];
    paginator: {
        total: number;
        page: number;
        per_page: number;
        searching: string;
        order_by: string;
        order_type: 'ASC' | 'DESC';
        start: number; // date
        end: number; // date
    };

    constructor() {
        this.data = [];
        this.paginator = {
            total: 1,
            page: 1,
            per_page: 25,
            searching: '',
            order_by: '',
            order_type: 'ASC',
            start: null,
            end: null
        }
    }
}
