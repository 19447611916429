import { Component, OnInit } from '@angular/core';
import { PhoneQueue } from 'src/app/entities/workspace/phone-queue';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { PhoneQueuesService } from 'src/app/services/workspaces/phone-queues.service';
import { environment } from 'src/environments/environment';

declare const swal;
@Component({
  selector: 'app-phone-queues',
  templateUrl: './phone-queues.component.html',
  styleUrls: ['./phone-queues.component.scss']
})
export class PhoneQueuesComponent implements OnInit {


  public rows: Array<PhoneQueue>;
  public actualPage: number;
  public workspaceId: string;
  readonly environment = environment;

  constructor(
    private helperService: HelperService,
    private authService: AuthService,
    private phoneQueuesService: PhoneQueuesService,
  ) { }

  ngOnInit(): void {
    this.load();
    this.workspaceId = '';
  }


  private load(){
    this.helperService.showLoadingMxpro360();
    this.phoneQueuesService.getAllCompany().then((response) => {
      this.rows = response;


      this.workspaceId = this.authService.workspaceSession.workspace.id
    }).catch((error) => {
      console.log('error', error);
    }).finally(() => {
      this.helperService.hideLoadingMxpro360();
    })
  };

  remove(id: string): void{
    swal({
      title: 'Are you sure?',
      text: "Are you sure do you want to delete this record?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!'
    }).then((result: any) => {
      if (result.value) {
        this.helperService.showLoadingMxpro360();
        this.phoneQueuesService
          .remove(id)
          .then(() => {
            this.load();
            swal(
              'Deleted!',
              'Record has been deleted.',
              'success'
            );
          })
          .catch((error) => {
            console.error('error', error);
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
      }
    })
  }


}
