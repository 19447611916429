export class LeadNote {
    public id: string;
    public remarks: string;
    public company_lead_id: string;
    public employee_id: string;
    public created: number;

    constructor() {
        this.id = null;
        this.remarks = null;
        this.company_lead_id = null;
        this.employee_id = null;
        this.created = null;
    }
}
