import { ActivatedRoute } from '@angular/router';
import { Component, AfterViewInit } from '@angular/core';
import { EstimatesService } from 'src/app/services/companies/estimates.service';
import { LogView } from 'src/app/entities/global/log-view';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
@Component({
  selector: 'app-estimates-logs',
  templateUrl: './estimates-logs.component.html',
  styleUrls: ['./estimates-logs.component.scss']
})
export class EstimatesLogsComponent implements AfterViewInit {

  public logs: LogView[];
  public newValueJson;

  constructor(
    private currentRoute: ActivatedRoute,
    private estimatesService: EstimatesService,
    private helperService: HelperService
  ) {
    this.logs = [];
    this.newValueJson = {};
  }
  actualPage = 1;
  filterSearch = '';

  ngAfterViewInit(): void {

    this.currentRoute.parent.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
          this.loadLogs(params.id);
      }
    });

  }

  loadLogs(estiamteId: string) {
    this.helperService.showLoadingMxpro360();
    this.estimatesService.getLogs(estiamteId)
      .then((response) => {
        this.logs = response;
      })
      .catch((error) => {
        console.error('error', error);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

}
