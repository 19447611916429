<div class=" layout-spacing layout-top-spacing">

    <div class="row">

        <div class="col-xl-5 col-lg-8 col-md-6 col-sm-8 align-self-center mb-3">
            <h3>
                Local Room Closets
            </h3>
            <nav class="breadcrumb-one" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a appWorkspaceRouterLink="/dashboard">
                            <i class="fas fa-home"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Local Room Closets</li>
                </ol>
            </nav>
        </div>
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
            <form class="form-inline my-2 my-lg-0">
                <div class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
                    <input type="text" name="filterSearch" [(ngModel)]="filterSearch" class="form-control product-search" id="input-search" placeholder="Filter...">
                </div>
            </form>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4 text-sm-right text-center layout-spacing align-self-center">
            <div class="d-flex justify-content-sm-end justify-content-center">
                <a appWorkspaceRouterLink="/local-room-closets/add" class="btn btn-new">
                    <i class="fas fa-plus-square"></i> &nbsp; Local Room Closets
                </a>
            </div>


        </div>
    </div>
    <!-- header -->

    <div class="row  mb-4">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">
                <div class="table-responsive">
                    <table class="table table-bordered mb-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Minimal</th>
                                <th>Moderate</th>
                                <th>Full</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of localRoomCategories; index as i">
                                <td>{{ i + 1 }}</td>
                                <td>{{row.name}}</td>
                                <td>{{row.minimal}}</td>
                                <td>{{row.moderate}}</td>
                                <td>{{row.full}}</td>
                                <td class="btn-actions">
                                    <a appWorkspaceRouterLink="/local-room-closets/{{row.id}}/edit" class="btn btn-success">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                    </a>
                                    <button (click)="remove(row.id)" class="btn btn-danger" *ngIf="!row.is_custom">
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                    <div class="toggle-switch" *ngIf="row.is_custom">                
                                        <label class="switch s-icons s-outline s-outline-secondary">
                                            <input type="checkbox" id="is_custom" name="is_custom" [(ngModel)]="row.is_active" (ngModelChange)="isActiveChanged($event, row)">
                                            <span class="slider round">
                                                <i class="fa-solid fa-check icon-left"></i>
                                                <i class="fa-solid fa-xmark icon-right"></i>
                                            </span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <pagination-controls class="paginating-container pagination-solid" (pageChange)="actualPage = $event"></pagination-controls> -->
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-lg-12">
            <div class="widget-content widget-content-area">

                <div class="widget-heading d-flex justify-content-between mb-4">
                    <h3 class="title-content-area">
                        Local Packing Closet % Increase
                    </h3>
                    <a class="btn btn-new" (click)="saveExtra()">
                        Save
                    </a>
                </div>

                <div class="row">
                    <div class="col-md-6 col-xl-4">
                        <div class="form-group mb-1">
                            <label class="control-label">Pack Time % Increase</label>
                            <div class="input-group mb-1">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle>
                                            <polyline points="12 6 12 12 16 14"></polyline>
                                        </svg>
                                    </span>
                                </div>
                                <input type="number" name="extra_labor_hours" [(ngModel)]="workspaceSettings.auto_local_tariff.packing_closet_increase"
                                    id="price_per_hour" step="0.01" class="form-control"/>
                            </div>
                            <small id="emailHelp1" class="form-text text-muted mb-4">Enter the % Increment for Packing Time.</small>
                        </div>
                    </div>
    
            </div>
        </div>
    </div>
    <!-- body -->
</div>
