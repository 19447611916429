import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { ConstantsMessages } from 'src/app/constants-messages';
import { Workspace } from 'src/app/entities/admin/workspace';
import { AccountWorkspacesService } from 'src/app/services/accounts/account-workspaces.service';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { Employee } from 'src/app/entities/workspace/employee';
import { Account } from 'src/app/entities/admin/account';
import { WorkspaceSession } from 'src/app/entities/helpers/workspace-session';
import { AccountWorkspaceView } from 'src/app/entities/admin/account-workspace-view';
import { EmployeeView } from 'src/app/entities/workspace/employee-view';
import { TermsConditionsService } from 'src/app/services/accounts/terms-conditions.service';
import { WorkspaceInvoicesService } from 'src/app/services/accounts/workspace-invoices.service';
import { Invoice } from 'src/app/entities/admin/invoice';
import { log } from 'console';

declare const jQuery;
declare const swal;

@Component({
    selector: 'app-account-workspaces',
    templateUrl: './account-workspaces.component.html',
    styleUrls: ['./account-workspaces.component.scss']
})
export class AccountWorkspacesComponent implements OnInit, OnDestroy {


    // handle modal
    @ViewChild('ModalSelectEmployee')
    modalSelectEmployee: ElementRef;

    @ViewChild('ModalWorkspaceForm')
    modalWorkspaceForm: ElementRef;

    @ViewChild('ModalConditions')
    modalConditions: ElementRef;


    workspaces: Array<AccountWorkspaceView>;
    constantsMessages = ConstantsMessages;
    workspace: Workspace; // formulario para trabajar el workspace seleccionado o para crear uno nuevo
    employees: EmployeeView[];
    account: Account;
    termsConditions: string;


    constructor(
        public router: Router,
        private accountWorkspacesService: AccountWorkspacesService,
        public authService: AuthService,
        private helperService: HelperService,
        public googleAuthService: GoogleAuthService,
        private termsConditionsService: TermsConditionsService,
        private workspaceInvoicesService: WorkspaceInvoicesService
    ) {
        this.workspaces = [];
        this.workspace = new Workspace();
        this.employees = [];
        this.account = null;
        this.termsConditions = '';
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        setTimeout(() => {
            this.helperService.hideLoadingMxpro360();
        }, 2000)
    }

    ngAfterContentInit(): void {
        /* this.loadWorkspaces(); */
        this.account = this.authService.session.account;
        setTimeout(() => {

        }, 10000);

        this.termsConditionsAcceptances();


    }

    /**
     * Permite cargar todos los workspaces al que el usuario actual tiene ingreso
     */
    loadWorkspaces() {
        this.helperService.showLoadingMxpro360();
        this.accountWorkspacesService
            .getAll()
            .then((response) => {
                this.workspaces = response;
                this.saveWorkspacesInSession();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite pasar la informacino de los workspace a la sesion del account
     */
    saveWorkspacesInSession() {
        const workspaces = [];
        // colecta la info de workspaces
        for (let workspace of this.workspaces) {
            workspaces.push(workspace.workspace);
        }
        // guarda la info de workspaces en session
        this.authService.session.workspaces = workspaces;
        this.authService.save();
    }

    /**
     * permite cargar los usuarios de un workspace
     */
    onSelectWorkspace(accountWorkspace: AccountWorkspaceView) {
        // validmos si el workspace seleccionado ya fue selecioando, para no vovler a cargar los empleados
        if (this.authService.workspaceSession !== null) {
            if (accountWorkspace.id === this.authService.workspaceSession.workspace.id) {
                // abrirmos la modal

                jQuery(this.modalSelectEmployee.nativeElement).modal('show');
                return;
            }
        }

        // msotramos el cargando
        this.helperService.showLoadingMxpro360();

        // asignamos valores a la sesion dedes aca
        this.authService.workspaceSession = new WorkspaceSession();
        this.authService.workspaceSession.id = accountWorkspace.workspace.id;
        this.authService.workspaceSession.workspace = accountWorkspace.workspace;
        this.authService.workspaceSession.is_owner = accountWorkspace.is_owner;

        // asignamos a variable locla para mostrarla el nombre del workspace en caso de que deba seleccoinar empmleado
        this.workspace = accountWorkspace.workspace;

        this.accountWorkspacesService
            .getEmployeesByWorkspaceId(accountWorkspace.workspace_id)
            .then((response: EmployeeView[]) => {

                // se asignan
                this.employees = response;
                this.authService.workspaceSession.employees = this.employees;

                // verificamo si solomanete tiene un user, para asignalos inmediatamente
                if (this.employees.length == 1) {
                    // pasamos el usuario para seleccionarlo de una vvez
                    this.onSelectUser(this.employees[0]);
                } else {
                    // abrirmos la modal
                    jQuery(this.modalSelectEmployee.nativeElement).modal('show');

                    // ocultamos el
                    this.helperService.hideLoadingMxpro360();
                }

            }, () => {
                // ocultamos el
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite seleccionar un workspace para establecerlo en la session
     */
    goToWorkspace() {

        this.helperService.showLoadingMxpro360();
        this.accountWorkspacesService
            .changeAtSession(this.authService.workspaceSession.workspace.id, this.authService.workspaceSession.employee.id)
            .then((response: any) => {

                if (response.employee_workspace_permissions === null &&
                    response.employee_company_permissions.length === 0 &&
                    !this.authService.workspaceSession.is_owner) {
                    swal(
                        'Contact the admin or support',
                        'Your user has no role or associated companies',
                        'warning'
                    );
                } else {

                    this.authService.workspaceSession.workspace = response.workspace;
                    this.authService.workspaceSession.employee = response.employee;
                    this.authService.workspaceSession.employee_companies_permissions = response.employee_company_permissions; // tener presente que en frontend, se guarda todos los registros pero cuando se ingresa a una compañia , se guarda aparte el equivalente a esa compañia
                    this.authService.workspaceSession.employee_workspace_permissions = response.employee_workspace_permissions;
                    this.authService.workspaceSession.companies = response.companies;
                    this.authService.save();

                    this.checkPendingInvoicesByWorspaceId();
                }
            })
            .catch((error) => {
                console.error('error', error);
                this.helperService.hideLoadingMxpro360();
            });
    }


    /**
     * sirve para cuando se seleccione un empleado se pase aca para establecerlo en la sesion y poder continuar con el flujo
     * @param user
     */
    onSelectUser(user: EmployeeView) {
        this.helperService.hideLoadingMxpro360();
        if (!user.ip_allowed) {
            // nemsaje de que los empleados no tienen acceso desde esta ip
            swal(
                'Contact the admin or support',
                'Your user is not allowed from this ip address.',
                'warning'
            );
        } else {
            this.authService.workspaceSession.employee = user;
            // Cerrado de la modal
            jQuery(this.modalSelectEmployee.nativeElement).modal('hide');
            this.goToWorkspace();
        }
    }

    /**
     * valida si hay invoices pendientes de pago y
     * re direcciona al listado de facturas o al workspace
     */
  checkPendingInvoicesByWorspaceId() {
    this.helperService.showLoadingMxpro360();
    this.workspaceInvoicesService
      .getAllByPending(this.authService.workspaceSession.workspace.id)
      .then((unPaidInvoices: Array<Invoice>) => {

        if (unPaidInvoices.length < 1) {
          this.verifyPermissionsEmployee();
        } else if (unPaidInvoices.length === 1) {
          if (this.authService.hasJobPosition('ADMIN')) {
            this.router.navigateByUrl('/my-profile/workspaces/' + this.authService.workspaceSession.workspace.id + '/billing');
          } else {
            this.verifyPermissionsEmployee();
          }
        } else if (unPaidInvoices.length >= 2 && this.authService.hasJobPosition('ADMIN')) {
          this.helperService.hideLoadingMxpro360();
          this.router.navigateByUrl('/my-profile/workspaces/' + this.authService.workspaceSession.workspace.id + '/billing');
        } else if (unPaidInvoices.length >= 2 && !this.authService.hasJobPosition('ADMIN')) {
          this.router.navigateByUrl('/workspaces/' + this.authService.workspaceSession.workspace.id + '/unauthorized-outstanding-invoices');
          this.helperService.hideLoadingMxpro360();
        }  else {
            this.verifyPermissionsEmployee();
        }
      }, (error) => {
        this.helperService.hideLoadingMxpro360();
      });
  }


    /**
     * Metodo encargado de redirigir a un empleado al dashboard de workspace
     * o de company, segun los permisos que posea
     */
    public async verifyPermissionsEmployee(): Promise<void> {

        // si es el dueño del workspace o tiene permisos para estar en el workspace
        if (this.authService.workspaceSession.is_owner || this.authService.workspaceSession.employee_workspace_permissions !== null) {
            if (this.authService.workspaceSession.employee_workspace_permissions?.id !== null) {
                // Si es owner, se redirige al dashboard de workspace
                this.router.navigateByUrl(`/workspace/${this.authService.workspaceSession.id}/dashboard`);
                return;
            }
        }

        // Al estar todos los capabilities en falso, se consultan las companies que tenga el empleado para redirigir
        // al empleado directamente al dashboard de una company
        const companies = await this.authService.workspaceSession.companies;

        // Si ha al menos una company asociada al employee, se hace la redireccion al dashboard de companies
        if (companies.length > 0) {

            // asignamos la compañia a la session
            this.authService.setCompanySession(companies[0]);

            // redireccionamos al usuario a la compañia
            this.router.navigate([`/workspace/${this.authService.workspaceSession.id}/companyspace/` + companies[0].id + '/dashboard']);

            return;
        }

        // Si no hay companies, se informa al usuario que no puede redirigirse a ninguna seccion
        swal(
            'Contact the admin or support',
            'Your user has no role or associated companies',
            'warning'
        );
    }


    /**
     * Permite cerrar session
     */
    async logout() {
        const validateLogout = await this.authService.logout();
        if (validateLogout) {
            this.googleAuthService.disconnect();
            this.router.navigateByUrl('/login');
        } else {
            console.error('Error al cerrar la sesion');
        }
    }

    /**
     * Permite abrir la modal para crear un workspace
     */
    openModalWorkspace() {
        // reseteamos valores del formulario
        this.workspace = new Workspace();

        // abrimos modal
        jQuery(this.modalWorkspaceForm.nativeElement).modal('show');
    }

    /**
     * Metodo para crear un workspace
     */
    saveWorkspace(): void {
        this.helperService.showLoadingMxpro360();
        this.accountWorkspacesService
            .save(this.workspace)
            .then(() => {
                this.helperService.showMessageSnackbar(this.constantsMessages.SAVED);
                this.router.navigateByUrl('/my-profile/workspaces');
                this.loadWorkspaces();
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(this.constantsMessages.ERROR_SAVED);
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    termsConditionsAcceptances() {
        this.helperService.showLoadingMxpro360();
        this.termsConditionsService
            .getTermsConditions()
            .then((response) => {
                if (response['terms_conditions']) {
                    if (response['accepted']) {
                        this.loadWorkspaces();
                    } else {
                        this.termsConditions = response['terms_conditions']['description'];
                        jQuery(this.modalConditions.nativeElement).modal('show');
                    }
                } else {
                    this.loadWorkspaces();
                }
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    acceptTerms() {
        this.helperService.showLoadingMxpro360();
        this.termsConditionsService
            .acceptTermsConditions()
            .then(() => {
                this.loadWorkspaces();
                jQuery(this.modalConditions.nativeElement).modal('hide');
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

}
