<div class="label-qr"  [ngClass]="{'selection-behavior': selectionBehavior }" *ngIf="itemQr !== null && infoEstimateLabelQr !== null">
    <table class="table-fond-size">
        <tbody>

            <tr>
                <td width="270">
                    <div>
                        <strong class="w50">Job #:</strong>
                        {{ infoEstimateLabelQr.jobCode }}
                    </div>
                    <div>
                        <strong class="w50">From:</strong>
                        {{ infoEstimateLabelQr.fromState }} {{ infoEstimateLabelQr.fromCity}} {{ infoEstimateLabelQr.fromZipCode }}
                    </div>
                    <div>
                        <strong class="w50">To:</strong>
                        {{ infoEstimateLabelQr.toState }} {{ infoEstimateLabelQr.toCity }} {{infoEstimateLabelQr.toZipCode }}
                    </div>
                    <div>
                        <strong class="w50">Phone:</strong>
                        {{ infoEstimateLabelQr.companyPhone }}
                    </div>
                </td>
                <td>
                    <div id="qrcodeTpl">
                        <div class="imgblock">
                            <div class="qr" #QRCODE></div>
                        </div>
                    </div>
                </td>
                <td rowspan="2">
                    <img src="assets/img/moverxpro360-vertical.svg" class="img-qr">
                </td>
            </tr>

            <tr>
                <td [ngStyle]="{'border-top': '6px solid ' + itemQr.color_hex }" style="padding-top: 6px;">
                    <div class="company-name">
                        <b>{{ infoEstimateLabelQr.companyName }}</b>
                    </div>
                </td>
                <td [ngStyle]="{'border-top': '6px solid ' + itemQr.color_hex, 'color': 'white', 'background-color': itemQr.color_hex, 'text-align': 'center'}">
                    {{itemQr.code}}
                </td>
            </tr>

        </tbody>
    </table>
</div>