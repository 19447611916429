import { AccountsService } from 'src/app/services/accounts/accounts.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/workspaces/companies.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GoogleAuthService } from 'src/app/services/google-auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from 'src/app/entities/admin/account';
import { NgForm } from '@angular/forms';
import { WorkspaceBillingProfile } from 'src/app/entities/admin/workspace-billing-profile';
import { Address } from 'src/app/entities/global/address';
import { WorkspaceBillingProfilesService } from 'src/app/services/accounts/workspace-billing-profiles.service';
import { Invoice } from 'src/app/entities/admin/invoice';
import { WorkspaceInvoicesService } from 'src/app/services/accounts/workspace-invoices.service';
import { element } from 'protractor';


declare var jQuery: any;


class loadingSections {

    billingProfile: boolean;
    account: boolean;
    metrics: boolean;

    constructor() {
        this.billingProfile = false;
        this.account = false;
        this.metrics = false;
    }
}

@Component({
    selector: 'app-workspaces-billing',
    templateUrl: './workspaces-billing.component.html',
    styleUrls: ['./workspaces-billing.component.scss']
})
export class WorkspacesBillingComponent implements OnInit {

    @ViewChild('modalBillingProfile')
    modalBillingProfile: ElementRef;
    billingProfileForm: WorkspaceBillingProfile;
    billingProfile: WorkspaceBillingProfile;
    workspace_id: string;
    isLoadingSection: loadingSections;

    activeAccounts: number;
    activeCompanies: number;

    storageSize: {
        totalSize: number,
        pdfSize: number,
        txtSize: number,
        mediaSize: number
    }
    account: Account;
    nameWorkspace: string;
    private pendingInvoice: Invoice[];
    public hasPendingInvoices: boolean;

    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute,
        private _location: Location,
        public googleAuthService: GoogleAuthService,
        private accountsService: AccountsService,
        private companiesService: CompaniesService,
        private helperService: HelperService,
        private workspaceBillingProfilesService: WorkspaceBillingProfilesService,
        public authService: AuthService,
        private workspaceInvoicesService: WorkspaceInvoicesService,
    ) {

        this.billingProfileForm = null;
        this.billingProfile = new WorkspaceBillingProfile();
        this.workspace_id = null;
        this.isLoadingSection = new loadingSections();

        this.activeAccounts = 0;
        this.activeCompanies = 0;
        this.storageSize = {
            totalSize: 0,
            pdfSize: 0,
            txtSize: 0,
            mediaSize: 0
        }
        this.account = new Account();
        this.nameWorkspace = "";
        this.pendingInvoice = [];
        this.hasPendingInvoices = false;
    }

    ngOnInit() {
      const that = this;

        this.currentRoute
            .params
            .subscribe(params => {
                if (typeof params.id !== 'undefined') {
                    this.workspace_id =  params.id;
                    this.loadBillingProfile(params.id);

                    that.workspaceInvoicesService
                    .getAll(this.workspace_id)
                    .then((response: Array<Invoice>) => {
                        console.log('this.response *=> ', response);
                        this.pendingInvoice = response.filter(X => X.status === 'PENDING');
                        console.log('this.pendingInvoice => ', this.pendingInvoice);
                        this.hasPendingInvoices = this.pendingInvoice.length > 1;


                    })
                    .catch((error) => {

                    })
                    .finally(() => {
                        this.helperService.hideLoadingMxpro360();
                    });
                }
            });

        this.workspaceBillingProfilesService
            .onChange
            .subscribe({
                next: (id: string) =>{
                    if(this.workspace_id === id){
                        this.loadBillingProfile(this.workspace_id);
                    }
                }
            })

        this.checkMainParamsFromURL();
        this.loadAccountCounter();
        this.loadCompaniesCounter();
        this.loadStorageSize();
        this.loadAccountInfo();
    }

    /**
     * permite cargar el perfil de facturacion de un workspace
     * @param workspace_id
     */
    loadBillingProfile(workspace_id: string) {
        this.workspaceBillingProfilesService
            .get(workspace_id)
            .then((response) => {
                if(response !== null) {
                    this.billingProfile = response;
                }
            })
            .catch(() => {

            })
            .finally(() => {
                this.isLoadingSection.billingProfile = true;
            });
    }

    /**
     * Permite obtener el total de cuentas que tienen acceso al actual workspace
     */
    loadAccountCounter() {
        this.accountsService
            .getActiveAccontsToCover()
            .then((account: any) => {
                this.activeAccounts = account.actives_accounts;
            })
            .finally(() => {
                this.isLoadingSection.account = true;
            });
    }

    /**
     * Permite obtener el valor total de compañias en el actual workspace
     */
    loadCompaniesCounter() {
        this.companiesService
            .getActiveCompaniesToCover()
            .then((account: any) => {
                this.activeCompanies = account.actives_accounts;
            })
            .finally(() => {
                this.isLoadingSection.metrics = true;
            });

    }

    /**
     * Permite cargar la informacion de la cuenta dueña del workspace
     */
    loadAccountInfo() {
        this.accountsService
            .loadAccountInfo()
            .then((response: any) => {
                this.account.name = response.name_account;
                this.account.email = response.email_account;
                this.nameWorkspace = response.name_workspace;
            })
            .finally(() => {
                this.isLoadingSection.metrics = true;
            });
    }

    /**
     * Permite cargar la informacion del storage consumido por el workspace
     */
    loadStorageSize() {
        this.accountsService
            .getStorageSize()
            .then((response: any) => {
                console.log('response =>', response);
            })
            .finally(() => {
                this.isLoadingSection.metrics = true;
            });
    }


    async logout() {
        const validateLogout = await this.authService.logout();
        if (validateLogout) {
            this.googleAuthService.disconnect();
            this.router.navigateByUrl('/login');
        } else {
            console.error('Error al cerrar la sesion');
        }
    }

    backTo() {
        this._location.back();
    }


    /**
     * Permite verificar los parametros de la URL para cargalos en la sesion antes de que se cargue todo
     */
    private checkMainParamsFromURL() {
        const fullUrl = window.location.href.split('#');
        if (fullUrl.length >= 2) {
            const path = fullUrl[1].split('/');
            // cargamos la base de la sesion
            this.authService.loadWorkspaceSession(path[3]);
        }
    }

    /**
     * Permite abrir la modal que permite la edicion de la informacion de facturacion del workspace
     */
    openModalBillingProfile() {

        // verificamos si ya hay datos previamente guardados para que se inicielize el formulario con estos datos
        if (this.billingProfile.id == null) {
            this.billingProfileForm = new WorkspaceBillingProfile();
            this.billingProfileForm.id = this.workspace_id;
        } else {
            this.billingProfileForm = Object.assign({}, this.billingProfile);
        }

        // verificamos si no hay direccion guardada para que se inicilize
        if (this.billingProfileForm.address == null) {
            this.billingProfileForm.address = new Address();
        }

        jQuery(this.modalBillingProfile.nativeElement).modal('show');
    }

    /**
     * permite guardar la informacion sobre el perfil de facturacion del workspace
     *
     * @param form
     * @returns
     */
    saveBillingProfile(form: NgForm) {

        if (form.form.status !== 'VALID') {
            return;
        }

        this.helperService.showLoadingMxpro360();

        // inicialmente, vamos a setear el valor
        this.billingProfileForm.address.country = "EE.UU.";

        // agregamos la direccion completa
        this.billingProfileForm.address.full_address = this.billingProfileForm.address.street + ", " + this.billingProfileForm.address.city + ", " + this.billingProfileForm.address.state + " " + this.billingProfileForm.address.zip_code + ", " + this.billingProfileForm.address.country;

        this.workspaceBillingProfilesService
            .put(this.billingProfileForm)
            .then((reponse): any => {

                // verificamos si viene mensaje
                if (reponse['message']) {
                    this.helperService.showMessageSnackbar(reponse['message'], "SUCESS");
                }

                // actualizamos la informacion local
                this.billingProfile = Object.assign({}, this.billingProfileForm);

                // ocultamos el fomrulario
                jQuery(this.modalBillingProfile.nativeElement).modal('hide');
            })
            .catch((error) => {
                this.helperService.showMessageSnackbar(JSON.stringify(error));
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });

    }
}
