export class Volume {
    id: string;
    tariff_version_id: string;
    name: string;
    up_to_cf: number;
    weight: number;
    created: number;

     constructor(){
        this.id = null;
        this.tariff_version_id = null;
        this.name = null;
        this.up_to_cf = null;
        this.weight = null;
        this.created = null;
     }
}
